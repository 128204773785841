import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { Try } from 'framework/Try';
import { IProductCatalogItem, IQueryResult } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { FilterProductCatalogType } from './FilterProductCatalogType';
import { ICommonParams } from './ICommonParams';
import { SearchProductCatalog } from './SearchProductCatalog';

interface IProps<TParams extends ICommonParams> extends DialogProps {
	style?: React.CSSProperties;
	defaultParams: TParams;
	queryF: (params: TParams) => Promise<Try<IQueryResult<IProductCatalogItem>>>;
	confirm: (item: IProductCatalogItem) => void;
	cancel: VoidFunction;
	filterType: FilterProductCatalogType;
	isSubmitting?: boolean;
	title?: string;
}

export const SearchProductCatalogDialog = <TParams extends ICommonParams>({
	confirm,
	cancel,
	defaultParams,
	queryF,
	filterType,
	isSubmitting = false,
	title,
	...rest
}: IProps<TParams>) => {
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(queryF, defaultParams);
	const { isStacked } = useDialogsContext();

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			fullWidth
			maxWidth='md'>
			<CloseButtonOnAbsolutePosition onClick={cancel} />
			<DialogTitle>{title ? title : strings.selectAnArticleFromProductCatalog}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<SearchProductCatalog<TParams>
					queryResult={queryResult}
					params={params}
					setParams={setParams}
					onAdd={confirm}
					filterType={filterType}
					isSubmitting={isSubmitting}
					mode='select'
				/>
			</DialogContent>
		</Dialog>
	);
};
