import { Paper, PaperProps, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { IInventoryItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps extends PaperProps {
	item: IInventoryItem;
	onClear: VoidFunction;
}

export const SelectedInventoryItemComponent = ({ item, onClear, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();

	return (
		<Paper
			variant='outlined'
			style={{ borderLeft: `4px solid ${theme.palette.primary.main}`, padding: 8, ...style }}
			{...rest}>
			<div className='df-row'>
				<div className='df-col fg1'>
					<LabelledProperty
						label={strings.manufacturer}
						property={item.manufacturer}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.model}
						property={item.model}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.serialNumber}
						property={item.serialNumber}
						withoutMargin
					/>
				</div>
				<div className='df-col-ac jc-c'>
					<ColorOnHoverIconButton
						color='secondary'
						onClick={onClear}
						size='small'>
						<DeleteIcon />
					</ColorOnHoverIconButton>
				</div>
			</div>
		</Paper>
	);
};
