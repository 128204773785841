import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { LabelledChipsList } from 'framework/components/chips/LabelledChipsList';
import { AccountCogOutlineIcon } from 'framework/components/icons/AccountCogOutlineIcon';
import { CubeOutlineIcon } from 'framework/components/icons/CubeOutlineIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { BoolExtendedRadioGroupForm } from 'framework/forms/BoolExtendedRadioGroupForm';
import { StringModelForm } from 'framework/forms/StringModelForm';
import {
	IProductCatalogItem,
	ProductCatalogItemType,
	productCatalogCommand_delete,
	productCatalogCommand_patchCanAlsoPurchase,
	productCatalogCommand_patchInternalReference,
	productCatalogCommand_patchManufacturerReferences,
	productCatalogCommand_patchName,
	productCatalogCommand_restore,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { LabelsForm } from 'shared/LabelsForm';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { boolToYesNo } from 'shared/utils/boolToString';
import { CategoriesForm } from '../forms/CategoriesForm';
import { HardwareDetailsForm } from '../forms/HardwareDetailsForm';
import { SwitchTypeForm } from '../forms/SwitchTypeForm';
import { ProductCatalogItemLabelsForm } from './ProductCatalogItemLabelsForm';

interface IProps {
	item: IProductCatalogItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const ProductCatalogItemDetailPageSidebar = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { productCatalogItemTypeRecord } = useContext(RecordContext);

	const onPatchLabels = () => {
		open(
			<ProductCatalogItemLabelsForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onPatchName = () => {
		open(
			<StringModelForm
				open
				formTitle={strings.changeWhat(strings.name)}
				label={strings.name}
				initialValue={item.name}
				cancel={cancel}
				submitFunction={val => productCatalogCommand_patchName({ productCatalogItemId: item.id, name: val })}
				submitText={strings.update}
				confirm={confirm}
			/>
		);
	};

	const onPatchHardwareDetails = () => {
		open(
			<HardwareDetailsForm
				open
				productCatalogItem={item}
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	const onPatchManufacturerReferences = () => {
		open(
			<LabelsForm
				open
				title={strings.changeWhat(strings.manufacturerReference)}
				label={strings.manufacturerReference}
				labels={item.manufacturerReferences}
				cancel={cancel}
				updateF={val => productCatalogCommand_patchManufacturerReferences({ productCatalogItemId: item.id, manufacturerReferences: val })}
				confirm={confirm}
				autoCompleteLabels={[]}
			/>
		);
	};

	const onPatchInternalReference = () => {
		open(
			<StringModelForm
				open
				formTitle={strings.changeWhat(strings.internalReference)}
				label={strings.internalReference}
				initialValue={item.internalReference}
				cancel={cancel}
				submitFunction={val => productCatalogCommand_patchInternalReference({ productCatalogItemId: item.id, internalReference: val })}
				submitText={strings.update}
				confirm={confirm}
			/>
		);
	};

	const onPatchType = () => {
		open(
			<SwitchTypeForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onPatchCanAlsoPurchase = () => {
		open(
			<BoolExtendedRadioGroupForm
				open
				formTitle={strings.isAlsoPurchased}
				trueLabel={strings.isAlsoPurchased}
				falseLabel={strings.willNotBePurchased}
				trueCaption={strings.thisWhatWillAlsoBePurchasedFromASupplier(strings.service)}
				falseCaption={strings.thisWhatWillNotBePurchasedFromASupplier(strings.service)}
				submitFunction={val => productCatalogCommand_patchCanAlsoPurchase({ productCatalogItemId: item.id, canAlsoPurchase: val })}
				initialValue={item.canAlsoPurchase}
				confirm={confirm}
				cancel={cancel}
				submitText={strings.update}
			/>
		);
	};

	const onPatchCategories = () => {
		open(
			<CategoriesForm
				open
				confirm={confirm}
				cancel={cancel}
				productCatalogItem={item}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title={strings.deleteItemFromProductCatalog}
				content={strings.deleteItemFromProductCatalogContent}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => productCatalogCommand_delete({ productCatalogItemId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onRestore = () => {
		open(
			<AlertDialog
				open
				title={strings.undoDelete}
				content={strings.thisArticleWillBeAvailableForSaleWithNextSale}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => productCatalogCommand_restore({ productCatalogItemId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<div
			className='df-col mh100'
			style={{
				padding: 16,
				paddingRight: 8,
				...style,
			}}>
			<div className='df-col gap-6'>
				{item.isTangible === true && (
					<>
						<CaptionVerticalLabelledPropertyX
							label={strings.manufacturer}
							edit={onPatchHardwareDetails}
							startIcon={<AccountCogOutlineIcon />}>
							{item.manufacturer}
						</CaptionVerticalLabelledPropertyX>
						<CaptionVerticalLabelledPropertyX
							label={strings.model}
							edit={onPatchHardwareDetails}
							startIcon={<CubeOutlineIcon />}>
							{item.model}
						</CaptionVerticalLabelledPropertyX>
					</>
				)}
				{item.isTangible === false && (
					<>
						<CaptionVerticalLabelledPropertyX
							label={strings.name}
							startIcon={<LabelOutlinedIcon />}
							edit={onPatchName}>
							{item.name}
						</CaptionVerticalLabelledPropertyX>
						<CaptionVerticalLabelledPropertyX
							label={strings.isAlsoPurchased}
							edit={onPatchCanAlsoPurchase}>
							{boolToYesNo(item.canAlsoPurchase, strings)}
						</CaptionVerticalLabelledPropertyX>
					</>
				)}

				{(item.isTangible === true || item.canAlsoPurchase === true) && (
					<CaptionVerticalLabelledPropertyX
						label={strings.manufacturerReference}
						edit={onPatchManufacturerReferences}>
						<StringValueOrNotApplicable>{item.manufacturerReferencesCsv}</StringValueOrNotApplicable>
					</CaptionVerticalLabelledPropertyX>
				)}
				<CaptionVerticalLabelledPropertyX
					label={strings.internal}
					edit={onPatchInternalReference}>
					<StringValueOrNotApplicable>{item.internalReference}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<Typography16pxBold>{strings.categories}</Typography16pxBold>
				<CaptionVerticalLabelledPropertyX
					label={strings.type}
					startIcon={<CategoryOutlinedIcon />}
					edit={item.isInterchangeableType ? onPatchType : undefined}>
					{productCatalogItemTypeRecord[item.type as ProductCatalogItemType]}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.category}
					edit={onPatchCategories}>
					<StringValueOrNotApplicable>{item.category}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.subcategories}
					edit={onPatchCategories}>
					<StringValueOrNotApplicable>{item.subcategory}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
			</div>
			<DividerWithMargin marginBottom={12} />
			<div
				className='df-col fg1'
				style={{ overflowY: 'hidden' }}>
				<TextLinkButton
					style={{ fontSize: 12 }}
					color='textSecondary'
					onClick={onPatchLabels}>
					<div>{strings.labels}</div>
					<div className='fg1'></div>
					<SettingsOutlinedIcon style={{ fontSize: 16 }} />
				</TextLinkButton>
				<div
					className='fg1'
					style={{ overflowY: 'auto' }}>
					{item !== undefined && item?.labels.length > 0 && <LabelledChipsList labels={item.labels} />}
					{(item === undefined || item.labels.length === 0) && <NoRecordsFound variant='caption'>{strings.noLabelsAssignedYet}</NoRecordsFound>}
				</div>
			</div>
			<div className='fg1'></div>
			<DividerWithMargin style={{ marginTop: 8, marginBottom: 8 }} />
			<div className='df-col gap-8'>
				{item.isDeleted === false && (
					<TextLinkButton
						startIcon={<DeleteOutlineIcon />}
						color='secondary'
						onClick={onDelete}>
						{strings.delete}
					</TextLinkButton>
				)}
				{item.isDeleted === true && (
					<TextLinkButton
						startIcon={<SettingsBackupRestoreIcon />}
						onClick={onRestore}>
						{strings.repairVerb}
					</TextLinkButton>
				)}
			</div>
		</div>
	);
};
