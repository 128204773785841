import { IInventoryItem } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const inventoryItemToLine = (ii: IInventoryItem): ILine => {
	return {
		isSelected: true,
		id: ii.id,
		description: ii.identifier,
		inventoryItemId: ii.id,
		productCatalogItemId: ii.productCatalogId,
		quantity: 1,
		unitListPrice: ii.salesListPrice,
		unitPrice: ii.salesPrice === 0 ? ii.salesListPrice : ii.salesPrice,
		productCatalogItem: undefined,
		vatPercentage: ii.vatPercentage,
		stockAtLocations: undefined,
		inventoryItem: ii,
	};
};
