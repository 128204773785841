import React from 'react';
import { IPageParams } from '../../types/IPageParams';
import { onResetToPage1 } from '../../types/onResetToPage1';
import { ISearchbarProps } from '../ISearchbarProps';
import { Searchbar } from './Searchbar';

interface IParams {
	searchString: string;
	pageParams: IPageParams;
}

interface IProps<TParams extends IParams> extends ISearchbarProps {
	params: TParams;
	setParams: (params: TParams) => void;
}

export const SearchbarWithParams = <TParams extends IParams>({ params, setParams, ...rest }: IProps<TParams>) => {
	return (
		<Searchbar
			{...rest}
			value={params.searchString}
			onSearch={text => setParams(onResetToPage1({ ...params, searchString: text }))}
		/>
	);
};
