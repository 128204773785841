import { CardProps } from '@material-ui/core';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { IPatientDetail, workflowsQuery_queryPrimaryByPatient } from 'gen/ApiClient';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { WorkflowsByPatientCard } from './WorkflowsByPatientCard';

interface IProps extends IReloadTriggerProps, CardProps {
	item: IPatientDetail;
	reload: VoidFunction;
}

export const WorkflowsByPatientOverviewCard = ({ item, reloadTrigger, reload, ...rest }: IProps) => {
	const [data, reloadWorkflows] = useApiEffect(workflowsQuery_queryPrimaryByPatient, item.patient.id);

	useLazyEffect(() => {
		reloadWorkflows();
	}, [reloadTrigger]);

	if (data === undefined) {
		return <></>;
	}

	return (
		<WorkflowsByPatientCard
			data={data}
			reload={reload}
			{...rest}
		/>
	);
};
