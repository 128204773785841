import { CardProps, Divider } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { IOption } from 'framework/IOption';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ITemplatesQueryParams, LanguageOption, LanguageOptions, LinkViewContextType, LinkViewContextTypes, TemplateTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps extends CardProps {
	params: ITemplatesQueryParams;
	setParams: React.Dispatch<React.SetStateAction<ITemplatesQueryParams>>;
}

const exclusions: (keyof ITemplatesQueryParams)[] = ['alsoIncludeDeleted'];

export const TemplatesListFilterCard = ({ params, setParams, ...rest }: IProps) => {
	const strings = useLocalization();
	const isFilteredX = useMemo(() => isFiltered(params, exclusions), [params]);
	const onFilter = (pars: ITemplatesQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params, exclusions)));
	const { templateTypeRecord: templatePredefinedTypeRecord, localizedLanguageRecord, linkViewContextTypeRecord } = React.useContext(RecordContext);

	return (
		<CardX
			className='df-col gap-8'
			{...rest}>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<FormControlLabelSwitch
				isChecked={params.alsoIncludeDeleted}
				toggle={() => setParams({ ...params, alsoIncludeDeleted: !params.alsoIncludeDeleted, pageParams: { index: 1, size: params.pageParams.size } })}
				label={strings.alsoShowDeleted}
			/>
			<Divider />
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<ListFilterComponent<string>
					options={TemplateTypes.map<IOption<string>>(t => ({ id: t, identifier: templatePredefinedTypeRecord[t] }))}
					selected={params.predefinedTypes}
					setSelected={vals => onFilter({ ...params, predefinedTypes: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.type}
				/>
				<DateFilterItem
					label={strings.dateCreated}
					after={params.createdAfter}
					before={params.createdBefore}
					setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
					icon={<DateRangeIcon />}
				/>
				<RecordListFilterComponent<LanguageOption>
					options={LanguageOptions}
					record={localizedLanguageRecord}
					selected={params.languages as any}
					label={strings.language}
					icon={<HelpOutlineIcon />}
					setSelected={vals => onFilter({ ...params, languages: vals })}
				/>
				<RecordListFilterComponent<LinkViewContextType>
					options={LinkViewContextTypes}
					record={linkViewContextTypeRecord}
					selected={params.mainContexts as any}
					label={strings.subject}
					icon={<HelpOutlineIcon />}
					setSelected={vals => onFilter({ ...params, mainContexts: vals })}
				/>
			</div>
		</CardX>
	);
};
