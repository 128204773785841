import { Typography } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import Replay10OutlinedIcon from '@material-ui/icons/Replay10Outlined';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { useContinuousCountdown } from 'framework/hooks/useContinuousCountdown';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	reload: VoidFunction;
	reloadEveryXSeconds?: number;
	style?: React.CSSProperties;
}

export const PageReloadButtonX = ({ reload, reloadEveryXSeconds, style }: IProps) => {
	const strings = useLocalization();
	const [reloadCounter] = useContinuousCountdown(reloadEveryXSeconds ?? -1);

	useLazyEffect(() => {
		if (reloadCounter === 0) {
			reload();
		}
	}, [reloadCounter]);

	return (
		<TooltipWithSpan title={reloadEveryXSeconds !== undefined ? `Reload in ${reloadCounter} second(s)` : strings.reload}>
			<>
				<ColorOnHoverIconButton
					onClick={reload}
					edge='end'
					size='small'
					color='primary'
					style={style}>
					{reloadEveryXSeconds !== undefined ? <Replay10OutlinedIcon /> : <ReplayIcon />}
					{reloadEveryXSeconds !== undefined && (
						<Typography
							variant='caption'
							style={{ position: 'absolute', top: 34, fontSize: '8px' }}>
							{`AUTO`}
						</Typography>
					)}
				</ColorOnHoverIconButton>
			</>
		</TooltipWithSpan>
	);
};
