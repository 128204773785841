import { CardProps } from '@material-ui/core';
import React from 'react';
import { AnnotationsCard } from 'shared/annotationsX/AnnotationsCard';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends IReloadTriggerProps, CardProps {
	patientId: string;
}

export const PatientFollowUpCard = ({ patientId, reloadTrigger, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<AnnotationsCard
			contextId={patientId}
			contextType='Patient'
			reloadTrigger={reloadTrigger}
			isFollowUp
			icon={<SupervisorAccountOutlinedIcon />}
			title={strings.followUpVerb}
			{...rest}
		/>
	);
};
