import React from 'react';

interface IProps {
	children?: React.ReactNode;
	index: number;
	value: any;
	unmountOnExit?: boolean;
	style?: React.CSSProperties;
}

export const TabPanel = ({ children, index, value, unmountOnExit = false, style }: IProps) => {
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			style={{ height: '100%', ...style }}>
			{unmountOnExit === true && value === index && children}
			{unmountOnExit === false && children}
		</div>
	);
};
