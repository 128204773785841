import React from 'react';
import { Chip } from '@material-ui/core';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';

interface IProps {
	label: string;
	getTagProps: AutocompleteGetTagProps;
	index: number;
}

export const AutocompleteChip = ({ label, getTagProps, index }: IProps) => {
	return (
		<Chip
			label={label}
			{...getTagProps({ index })}
			size='small'
			color='primary'
		/>
	);
};

export const renderTags = <T extends unknown>(selector: (val: T) => string) => {
	return (tagValue: T[], getTagProps: AutocompleteGetTagProps) =>
		tagValue.map((tag, index) => (
			<AutocompleteChip
				label={selector(tag)}
				index={index}
				getTagProps={getTagProps}
			/>
		));
};
