import { StockMovementsRoute } from 'app/main/inventoryManagement/routes';
import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { IStockMovement } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { StockTransactionTabIconRecord } from '../../StockTransactionTabIconRecord';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

interface IProps {
	item: IStockMovement;
	reload: VoidFunction;
}

export const StockMovementDetailPageHeader = ({ item, reload }: IProps) => {
	const strings = useLocalization();

	return (
		<div
			className='df-row-ac fg1'
			style={{ paddingBottom: 16 }}>
			<PageBreadcrumbTitle title={strings.inventoryManagementShortened} />
			<PageBreadcrumbDivider />
			<PageBreadcrumbTitle title={strings.stockTransactions} />
			<PageBreadcrumbDivider />
			<PageBreadcrumb
				text={strings.stockMovements}
				route={StockMovementsRoute}
				icon={StockTransactionTabIconRecord['stock-movements']}
			/>
			<PageBreadcrumbDivider />
			<PageBreadcrumbTitle title={item.reference} />
			<div className='fg1'></div>
			<PageXHeaderActions>
				<PageReloadButtonX reload={reload} />
			</PageXHeaderActions>
		</div>
	);
};
