import { ColorX } from 'framework/components/color/ColorX';
import { ISale } from 'gen/ApiClient';

export const mapSaleToColorX = (item: ISale): ColorX => {
	if (item.isFullyPaid) {
		return 'success';
	} else if (item.isOverDue) {
		return 'warning';
	} else {
		return 'default';
	}
};
