import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, useTheme } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { SortableTable } from 'framework/table/SortableTable';
import { tendersQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { ITenderLineWithSelection } from '../../../../../../financial/tenders/ITenderLineWithSelection';
import { createHeaders } from './createHeaders';

interface IProps extends DialogProps {
	tenderId: string;
	confirm: (lines: ITenderLineWithSelection[]) => Promise<void>;
	cancel: VoidFunction;
}

export const SelectLinesFromTenderForm = ({ tenderId, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [tender] = useApiEffect(tendersQuery_single, tenderId);
	const theme = useTheme();
	const [lines, setLines] = useState<ITenderLineWithSelection[]>([]);
	const headers = useMemo(() => createHeaders(lines, setLines, strings), [lines, setLines, strings]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	useEffect(() => {
		if (tender) {
			setLines(tender.lines.map(t => ({ isSelected: true, ...t })));
		}
	}, [tender]);

	if (tender === undefined) {
		return <div></div>;
	}

	const onConfirm = async () => {
		setIsSubmitting(true);
		await confirm(lines.filter(t => t.isSelected));
		setIsSubmitting(false);
	};

	return (
		<Dialog {...rest}>
			<DialogTitle>{strings.selectWhat(strings.lines)}</DialogTitle>
			<DialogContent
				dividers
				style={{ padding: 0 }}>
				<SortableTable<ITenderLineWithSelection>
					padding={theme.spacing(1)}
					headers={headers}
					getKey={t => t.id}
					values={lines}
					defaultSortParams={{ property: 'indexNumber', direction: 'asc' }}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<LoaderButton
					isLoading={isSubmitting}
					onClick={onConfirm}
					disabled={lines.filter(t => t.isSelected).length === 0}
					color='primary'>{`${strings.select} (${lines.filter(t => t.isSelected).length})`}</LoaderButton>
			</DialogActions>
		</Dialog>
	);
};
