import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IOrderV2, IPlaceOrderWithSupplierRequest, ordersCommand_placeWithSupplier } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { getNow } from 'shared/utils/getNow';
import * as yup from 'yup';

const Now = getNow();

const createSchema = (strings: IStrings) => {
	return yup.object<IPlaceOrderWithSupplierRequest>({
		externalReference: yup.string(),
		orderId: yup.string().required(),
		placedWithSupplierDate: yup.date(),
	});
};

interface IProps extends DialogProps {
	item: IOrderV2;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PlaceOrderWithSupplierRequestForm = ({ item, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [patch, isSubmitting] = useFormSubmit(ordersCommand_placeWithSupplier);

	const handleSubmit = async (values: IPlaceOrderWithSupplierRequest, helpers: FormikHelpers<IPlaceOrderWithSupplierRequest>) => {
		const r = await patch(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.orderPlaced);
			confirm();
		}
	};

	return (
		<Formik<IPlaceOrderWithSupplierRequest>
			validateOnMount
			initialValues={{
				orderId: item.id,
				externalReference: item.externalReference,
				placedWithSupplierDate: item.placedWithSupplierDate ?? Now,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					maxWidth='sm'
					fullWidth>
					<DialogTitle>{strings.placeOrder}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormDatePicker<IPlaceOrderWithSupplierRequest>
							name='placedWithSupplierDate'
							label={strings.date}
							required
						/>
						<FormTextField<IPlaceOrderWithSupplierRequest>
							name='externalReference'
							label={strings.externalReference}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
