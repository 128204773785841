import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { formatDate } from 'framework/utils/date/formatDate';
import {
	IReadOutRule,
	noahDeviceReadOutsCommand_deleteRule,
	noahDeviceReadOutsCommand_ignore,
	noahDeviceReadOutsCommand_restoreRule,
	noahDeviceReadOutsQuery_single,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { RuleForm } from '../forms/RuleForm';
import { NoahDeviceReadOutStatusChip } from '../NoahDeviceReadOutStatusChip';
import { useHistory } from 'react-router';
import { calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';

interface IProps extends DialogProps {
	close: VoidFunction;
	id: string;
}

export const NoahDeviceReadOutDialog = ({ id, close, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [item, reload] = useApiEffect(noahDeviceReadOutsQuery_single, id);
	const [ignore] = useFormSubmit(noahDeviceReadOutsCommand_ignore);
	const [restoreRule] = useFormSubmit(noahDeviceReadOutsCommand_restoreRule);
	const [deleteRule] = useFormSubmit(noahDeviceReadOutsCommand_deleteRule);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);
	const { push } = useHistory();

	if (item === undefined) {
		return <div></div>;
	}

	const onViewPc = (ror: IReadOutRule) => {
		if (ror.productCatalogId) {
			push(calcProductCatalogDetailRoute(ror.productCatalogId));
			close();
		}
	};

	const onRestore = (rule: IReadOutRule) => {
		open(
			<AlertDialog
				open
				title={strings.ruleForWhatRepair(`${item.manufacturer}, ${item.instrumentTypeName}`)}
				content={strings.doYouWantToRepairRuleForWhatQuestion(`${rule.manufacturer}, ${rule.model}`)}
				acceptText={strings.yesWhatExclamation(strings.repairVerb)}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => restoreRule(id, rule.id)}
				accept={confirm}
				fullWidth
			/>
		);
	};

	const onDelete = (rule: IReadOutRule) => {
		open(
			<AlertDialog
				open
				title={strings.ruleForWhatDelete(`${item.manufacturer}, ${item.instrumentTypeName}`)}
				content={strings.doYouWantToDeleteRuleForWhatQuestion(`${rule.manufacturer}, ${rule.model}`)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => deleteRule(id, rule.id)}
				accept={confirm}
				fullWidth
			/>
		);
	};

	const onIgnore = () => {
		open(
			<AlertDialog
				open
				content={`${item.manufacturer}, ${item.instrumentTypeName} ${strings.ignoreVerb}?`}
				acceptText={strings.yesWhatExclamation(strings.ignore)}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => ignore(id)}
				accept={confirm}
				fullWidth
			/>
		);
	};

	const onCreate = () => {
		open(
			<RuleForm
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<HelpOutlineIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{item.manufacturer}, {item.instrumentTypeName}
					</Typography>
					<NoahDeviceReadOutStatusChip
						item={item}
						style={{ marginBottom: theme.spacing(1) }}
					/>
					<LabelledProperty
						label={strings.lastReadOutDate}
						property={formatDate(item.lastReadOutDate)}
						withoutMargin
					/>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								onClick={onCreate}
								startIcon={<AddIcon />}>
								{strings.createNewRule}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={onIgnore}
								startIcon={<NotInterestedIcon />}
								disabled={item.isIgnored}>
								{strings.ignoreThisReadOut}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
				<GridDividerWithCaption caption={`${strings.rules} (${strings.productCatalogItem})`} />
				{item.productCatalogs.map(t => (
					<PropertyInGrid
						key={t.id}
						value={t.manufacturerCommaModel}
						valueStyle={{ textDecoration: t.isDeleted ? 'line-through' : 'inherit' }}
						navigate={() => onViewPc(t)}
						customAction={() => (t.isDeleted ? onRestore(t) : onDelete(t))}
						customActionIcon={t.isDeleted ? <SettingsBackupRestoreIcon /> : <DeleteIcon />}
					/>
				))}
				{item.productCatalogs.length === 0 && <PropertyInGrid value={<NoRecordsFound>{strings.noWhatDefined(strings.rules)}</NoRecordsFound>} />}
			</DialogContentGrid>
		</Dialog>
	);
};
