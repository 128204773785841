import { Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { BatteryType, HearingAidType, IInventoryItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { boolToYesNo } from 'shared/utils/boolToString';

interface IProps {
	item: IInventoryItem;
	style?: React.CSSProperties;
}

export const HearingAidPropertiesCard = ({ item, style }: IProps) => {
	const strings = useLocalization();
	const { hearingAidTypeRecord, batteryTypeRecord } = useContext(RecordContext);

	return (
		<Card
			style={style}
			className='df-col'>
			<CardContent>
				<CardTitleTypography title={`${strings.properties} (${strings.hearingAid})`} />
				<LabelledProperty
					label={strings.type}
					property={hearingAidTypeRecord[item.productCatalogData.hearingAidData?.type as HearingAidType]}
				/>
				<LabelledProperty
					label={strings.batteryType}
					property={batteryTypeRecord[item.productCatalogData.hearingAidData?.batteryType as BatteryType]}
				/>
				{(item.productCatalogData.hearingAidData?.type as HearingAidType) === 'BTE' && (
					<>
						<LabelledProperty
							label={strings.somethingQuestionmark(strings.withSeparateEarmould)}
							property={boolToYesNo(item.productCatalogData.hearingAidData?.hasSeparateEarmould, strings)}
						/>
						<LabelledProperty
							label={strings.somethingQuestionmark(strings.withSeparateReceiver)}
							property={boolToYesNo(item.productCatalogData.hearingAidData?.hasSeparateReceiver, strings)}
						/>
					</>
				)}
			</CardContent>
		</Card>
	);
};
