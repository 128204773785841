import { Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { BoolExtendedRadioGroupForm } from 'framework/forms/BoolExtendedRadioGroupForm';
import { IProductCatalogItem, productCatalogCommand_patchHasBatteries, productCatalogCommand_patchIsElectronics } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { boolToYesNo } from 'shared/utils/boolToString';
import { SelectSuppliersForm } from '../forms/SelectSuppliersForm';
import { BebatDisplay } from './BebatDisplay';
import { DefaultWarranty } from './DefaultWarranty';
import { RecupelDisplay } from './RecupelDisplay';
import { SuppliersChips } from './SuppliersChips';

interface IProps {
	item: IProductCatalogItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const TangiblePropertiesCard = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onUpdateIsElectronics = () => {
		open(
			<BoolExtendedRadioGroupForm
				open
				formTitle={`${strings.isElectronics}?`}
				trueLabel={strings.yes}
				falseLabel={strings.no}
				trueCaption={strings.isElectronics}
				falseCaption={strings.isNotElectronics}
				submitFunction={val => productCatalogCommand_patchIsElectronics({ productCatalogItemId: item.id, isElectronics: val })}
				initialValue={item.isElectronics}
				confirm={confirm}
				cancel={cancel}
				submitText={strings.update}
			/>
		);
	};

	const onUpdateHasBatteries = () => {
		open(
			<BoolExtendedRadioGroupForm
				open
				formTitle={`${strings.hasBatteries}?`}
				trueLabel={strings.yes}
				falseLabel={strings.no}
				trueCaption={strings.hasBatteries}
				falseCaption={strings.hasNoBatteries}
				submitFunction={val => productCatalogCommand_patchHasBatteries({ productCatalogItemId: item.id, hasBatteries: val })}
				initialValue={item.hasBatteries}
				confirm={confirm}
				cancel={cancel}
				submitText={strings.update}
			/>
		);
	};

	// const onUpdateIsTailorMade = () => {
	//     open(<BoolExtendedRadioGroupForm
	//         open
	//         formTitle={`${strings.isTailorMade}?`}
	//         trueLabel={strings.yes}
	//         falseLabel={strings.no}
	//         trueCaption={strings.isTailorMade}
	//         falseCaption={strings.isNotTailorMade}
	//         submitFunction={val => productCatalogCommand_updateIsTailorMade(item.id, val)}
	//         initialValue={item.isTailorMade}
	//         confirm={confirm}
	//         cancel={cancel}
	//         submitText={strings.update} />)
	// }

	// const onUpdateHasSerialNumber = () => {
	//     open(<BoolExtendedRadioGroupForm
	//         open
	//         formTitle={`${strings.hasSerialNumber}?`}
	//         trueLabel={strings.yes}
	//         falseLabel={strings.no}
	//         trueCaption={strings.hasSerialNumber}
	//         falseCaption={strings.hasNoSerialNumber}
	//         submitFunction={val => productCatalogCommand_updateHasSerialNumber(item.id, val)}
	//         initialValue={item.hasSerialNumber}
	//         confirm={confirm}
	//         cancel={cancel}
	//         submitText={strings.update} />);
	// }

	const onSuppliers = () => {
		open(
			<SelectSuppliersForm
				open
				productCatalogItem={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Card
			style={style}
			className='df-col'>
			<CardContent>
				<CardTitleTypography title={`${strings.properties} (${strings.tangibleArticle})`} />
				<LabelledProperty
					label={strings.isTailorMade}
					property={boolToYesNo(item.isTailorMade, strings)}
				/>
				<LabelledProperty
					label={strings.hasSerialNumber}
					property={boolToYesNo(item.hasSerialNumber, strings)}
				/>
				<LabelledProperty
					label={strings.isElectronics}
					property={boolToYesNo(item.isElectronics, strings)}
					edit={onUpdateIsElectronics}
				/>
				<LabelledProperty
					label={strings.hasBatteries}
					property={boolToYesNo(item.hasBatteries, strings)}
					edit={onUpdateHasBatteries}
				/>
				<CardTitleTypography
					title={strings.suppliers}
					withPaddingTop
					edit={onSuppliers}
				/>
				<SuppliersChips suppliers={item.suppliers} />
				{item.suppliers.length === 0 && <NoRecordsFound withPaddingTop>{strings.noWhatSelected(strings.supplierSingularOrPlural)}</NoRecordsFound>}
				<DefaultWarranty
					item={item}
					reload={reload}
				/>
				{item.isElectronics && (
					<RecupelDisplay
						item={item}
						reload={reload}
					/>
				)}
				{item.hasBatteries && (
					<BebatDisplay
						item={item}
						reload={reload}
					/>
				)}
			</CardContent>
		</Card>
	);
};
