import React from 'react';
import { createHeader } from 'framework/table/createHeader';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IContributionLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	lines: IContributionLine[];
}

export const ContributionLinesTable = ({ lines }: IProps) => {
	const strings = useLocalization();

	const headers = [
		createHeader<IContributionLine>(strings.description, t => t.formattedDescription),
		createHeader<IContributionLine>(strings.quantity, t => t.quantity.toString()),
		createHeader<IContributionLine>(strings.priceInVat, t => formatCurrency(t.linePrice)),
	];

	return (
		<TableWithHeadersAndValues<IContributionLine>
			values={lines}
			headers={headers}
			getKey={t => t.id}
			withPaperContainer
		/>
	);
};
