import React from 'react';
import { QueryProvider } from './QueryProvider';
import { ViewProvider } from './ViewProvider';

export const FailedEventsProviders = ({ children }: any) => {
	return (
		<ViewProvider>
			<QueryProvider>{children}</QueryProvider>
		</ViewProvider>
	);
};
