import { ClientClaim } from 'gen/ApiClient';

export const ClientClaimRecord: Record<ClientClaim, string> = {
	ais: 'Article Inquiries',
	CanImportUblPurchase: 'Import purchases via UBL',
	hcr: 'HAMS calendar',
	vfu: 'View Follow Up',
	vsa: 'View Sales Analysis',
	ExactGlobeIntegration: 'Exact Globe Integration',
	ExactOnlineIntegration: 'Exact Online Integration',
};
