import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip, useTheme } from '@material-ui/core';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import React from 'react';

export const SupportButtonAndMenu = () => {
	const [anchor, open, close] = useAnchorElement();
	const theme = useTheme();

	return (
		<>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={close}
				keepMounted
				getContentAnchorEl={null}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
				<MenuItem>
					<ListItemIcon style={{ minWidth: 30 }}>
						<PhoneIcon fontSize='small' />
					</ListItemIcon>
					<a
						href='tel:003233540075'
						style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
						{`+32 3 354 00 75`}
					</a>
				</MenuItem>
				<MenuItem>
					<ListItemIcon style={{ minWidth: 30 }}>
						<MailIcon fontSize='small' />
					</ListItemIcon>
					<a
						href='mailto:support@hams.be'
						style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
						{`support@hams.be`}
					</a>
				</MenuItem>
				<DividerWithMargin />
				<MenuItem>
					<ListItemIcon style={{ minWidth: 30 }}>
						<HeadsetMicIcon fontSize='small' />
					</ListItemIcon>
					<a
						target={'_blank”'}
						href='https://get.teamviewer.com/6j3mfk6'
						style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
						{`TeamViewer QuickSupport`}
					</a>
				</MenuItem>
			</Menu>
			<Tooltip title='Support'>
				<IconButton
					color='inherit'
					onClick={open}
					edge='end'>
					<HeadsetMicIcon />
				</IconButton>
			</Tooltip>
		</>
	);
};
