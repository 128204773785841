import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { useLoggedInLocation } from 'app/auth/useLoggedInLocation';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { Form, Formik, FormikHelpers } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormMaskedTextField } from 'framework/forms/FormMaskedTextField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { isValidBelgianSocialSecurityNumber } from 'framework/utils/isValidBelgianSocialSecurityNumber';
import { IPatientModel, patientsCommand_newFromModel } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { FormGenderSelectField } from 'shared/forms/FormGenderSelectField';
import { FormSelectLanguageField } from 'shared/forms/FormSelectLanguageField';
import * as yup from 'yup';
import { FormSelectLocationField } from '../../settings/locations/forms/FormSelectLocationField';
import { FormSelectAudiologistField } from '../../settings/users/forms/FormSelectAudiologistField';
import { SocialSecurityNumberMask } from './SocialSecurityNumberMask';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPatientModel>({
			salutation: yup.string(),
			firstName: yup.string().required(strings.formRequired(strings.firstName)),
			lastName: yup.string().required(strings.formRequired(strings.lastName)),
			socialSecurityNumber: yup
				.string()
				.test('valid', strings.invalidSocialSecurityNumber, t => (isNullOrUndefined(t) ? true : isValidBelgianSocialSecurityNumber(t ?? ''))),
			gender: yup.string().required(strings.formRequired(strings.gender)),
			dateOfBirth: yup.date(),
			attendingAudiologistId: yup.string().required(strings.formRequired(strings.audiologist)),
			locationId: yup.string().required(strings.formRequired(strings.location)),
			nickName: yup.string(),
			language: yup.string().required(strings.formRequired(strings.language)),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IPatientModel)[]> = {
	0: ['salutation', 'firstName', 'lastName', 'nickName'],
	1: ['gender', 'socialSecurityNumber', 'dateOfBirth'],
	2: ['attendingAudiologistId', 'locationId', 'language'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const PatientModelForm = ({ confirm, cancel, ...rest }: IProps) => {
	const [step, setStep] = useState<number>(0);
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(patientsCommand_newFromModel);
	const strings = useLocalization();
	const user = useLoggedInUser();
	const locationId = useLoggedInLocation();
	const schema = useMemo(() => createSchema(strings), [strings]);
	const currentLanguage = useCurrentLanguage();

	const handleSubmit = async (values: IPatientModel, helpers: FormikHelpers<IPatientModel>) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.patientCreated);
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<IPatientModel>
			validateOnMount
			initialValues={{
				firstName: '',
				lastName: '',
				dateOfBirth: undefined,
				socialSecurityNumber: '',
				salutation: '',
				gender: undefined as any,
				attendingAudiologistId: user.id,
				locationId: locationId ?? '',
				nickName: '',
				language: currentLanguage,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					fullWidth
					disableEnforceFocus>
					<DialogTitleWithFormStepper
						title={strings.personalInformationData}
						step={step}
						labels={[strings.name, strings.details, strings.audiologist]}
					/>
					<DialogContent
						dividers
						className='df-col'>
						{step === 0 && (
							<>
								<FormTextField<IPatientModel>
									name='salutation'
									label={strings.salutation}
								/>
								<FormTextField<IPatientModel>
									name='firstName'
									label={strings.firstName}
									required
								/>
								<FormTextField<IPatientModel>
									name='lastName'
									label={strings.lastName}
									required
								/>
								<FormTextField<IPatientModel>
									name='nickName'
									label={strings.nickName}
								/>
							</>
						)}
						{step === 1 && (
							<>
								<FormGenderSelectField<IPatientModel>
									required
									name='gender'
									label={strings.gender}
								/>
								<FormDatePicker<IPatientModel>
									name='dateOfBirth'
									label={strings.dateOfBirth}
								/>
								<FormMaskedTextField<IPatientModel>
									name='socialSecurityNumber'
									label={strings.socialSecurityNumber}
									mask={SocialSecurityNumberMask}
								/>
							</>
						)}
						{step === 2 && (
							<>
								<FormSelectAudiologistField<IPatientModel>
									name='attendingAudiologistId'
									label={strings.audiologist}
								/>
								<FormSelectLocationField<IPatientModel>
									name='locationId'
									label={strings.location}
								/>
								<FormSelectLanguageField<IPatientModel>
									name='language'
									label={strings.language}
								/>
							</>
						)}
					</DialogContent>
					<PageableFormDialogActions
						cancel={cancel}
						step={step}
						setStep={setStep}
						isSubmitting={isSubmitting}
						submitText={strings.create}
						stepsRecord={stepsRecord}
						schema={schema}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
