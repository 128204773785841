import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		created: strings.createdAt,
		countItems: `# ${strings.items}`,
		amountPaid: strings.amountPaid,
		outstandingBalance: strings.outstandingBalance,
		priceExVat: strings.priceExVat,
		priceInVat: strings.priceInVat,
		recipientName: strings.withRespectToShort,
		reference: strings.reference,
		validUntil: strings.validTo,
		vatAmount: strings.vatAmount,
		type: strings.type,
		externalReference: strings.externalReference,
		createdByUser: strings.user,
	};
};
