import { Dialog, DialogProps } from '@material-ui/core';
import React from 'react';

interface IProps extends DialogProps {
	fullHeight?: boolean;
	minWidth?: number;
}

export const DialogX = ({ fullHeight, PaperProps, minWidth, ...rest }: IProps) => {
	return (
		<Dialog
			{...rest}
			PaperProps={{
				style: {
					height: fullHeight ? '100%' : 'unset',
					maxWidth: 'unset',
					minWidth: minWidth ?? 500,
					borderRadius: 12,
					...PaperProps?.style,
				},
			}}
		/>
	);
};
