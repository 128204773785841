import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';

interface IProps extends ButtonProps {
	text: string;
}

export const ProcessButton = ({ text, ...rest }: IProps) => {
	return (
		<SmallPrimaryTextButton
			style={{ width: 'fit-content', letterSpacing: 'normal' }}
			{...rest}>
			{text}
		</SmallPrimaryTextButton>
	);
};
