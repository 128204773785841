import React from 'react';
import { ApplicationProvider } from './client/ApplicationProvider';
import { ApplicationProvider as EidApplicationProvider } from './eid/ApplicationProvider';
import { ApplicationProvider as ImageScannerApplicatonProvider } from './imageScanner/ApplicationProvider';
import { ApplicationProvider as ThermalPrinterApplicationProvider } from './thermalPrinter/ApplicationProvider';

export const ExternalApplicationsProviders = ({ children }: any) => {
	return (
		<ApplicationProvider>
			<EidApplicationProvider>
				<ImageScannerApplicatonProvider>
					<ThermalPrinterApplicationProvider>{children}</ThermalPrinterApplicationProvider>
				</ImageScannerApplicatonProvider>
			</EidApplicationProvider>
		</ApplicationProvider>
	);
};
