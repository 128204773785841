import { TableRow, useTheme, TableHead } from '@material-ui/core';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import React from 'react';
import { HeaderTableCellX } from './HeaderTableCellX';

interface IProps {
	children: React.ReactNode;
}

export const NoContentTableHeadX = ({ children }: IProps) => {
	const theme = useTheme();

	return (
		<TableHead>
			<TableRow>
				<HeaderTableCellX>
					<div
						className='df-row gap-4'
						style={{ color: theme.palette.primary.main, fontWeight: 'normal' }}>
						<StatusIcon status='info' />
						<em>{children}</em>
					</div>
				</HeaderTableCellX>
			</TableRow>
		</TableHead>
	);
};
