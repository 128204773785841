import { Accordion, AccordionDetails, AccordionSummary, DialogContent, DialogProps, DialogTitle, Divider, IconButton, TextField } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkIcon from '@material-ui/icons/Link';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { ChipX } from 'framework/components/chips/ChipX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { SelectField } from 'framework/components/select/SelectField';
import { DialogX } from 'framework/dialogs/DialogX';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { Trigger } from 'framework/hooks/useTrigger';
import { useAccordionState } from 'framework/utils/useAccordionState';
import {
	exactGlobeCostCenter_all,
	exactGlobeCostCenter_create,
	exactGlobeCostCenter_patch,
	exactGlobeCostUnit_all,
	exactGlobeCostUnit_create,
	exactGlobeLocation_all,
	exactGlobeLocation_link,
	exactGlobeSettingsCommand_patchDivision,
	exactGlobeSettingsCommand_patchFallbackCostUnit,
	exactGlobeSettingsCommand_patchGLAccount,
	exactGlobeSettingsCommand_patchJournal,
	exactGlobeSettingsQuery_settings,
	ICreateExactGlobeCostCenterRequest,
	ICreateExactGlobeCostUnitRequest,
	IExactGlobeCostCenter,
	IExactGlobeCostUnit,
	IExactGlobeLocationDto,
	IExactGlobeStatusDto,
	ILinkExactGlobeLocationRequest,
	IPatchExactGlobeCostCenterRequest,
	IPatchExactGlobeCostUnitRequest,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';

type AccordionType = 'base_settings' | 'cost_centers' | 'cost_units' | 'locations';

interface IExactGlobeSettingsTabProps extends IReloadTriggerProps {
	status: IExactGlobeStatusDto;
	reload: VoidFunction;
}

export const ExactGlobeSettingsTab = ({ status, reloadTrigger, reload }: IExactGlobeSettingsTabProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [settings, reloadSettings] = useApiEffect(exactGlobeSettingsQuery_settings);
	const [expandedAccordion, setExpandedAccordion] = useAccordionState<AccordionType>('base_settings');

	useLazyEffect(() => {
		reloadSettings();
	}, [reloadTrigger]);

	const onPatchDivisionCode = () => {
		open(
			<StringModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.changeSomething(`Divisie`)}
				label={`Divisie`}
				initialValue={settings?.divisionCode ?? ''}
				cancel={cancel}
				submitText={strings.update}
				submitFunction={value => exactGlobeSettingsCommand_patchDivision({ divisionCode: value })}
			/>
		);
	};

	const onPatchJournal = () => {
		open(
			<StringModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.changeSomething(`Dagboek`)}
				label={`Dagboek`}
				initialValue={settings?.journalCode ?? ''}
				cancel={cancel}
				submitText={strings.update}
				submitFunction={value => exactGlobeSettingsCommand_patchJournal({ journalCode: value })}
			/>
		);
	};

	const onPatchGlAccountCode = () => {
		open(
			<StringModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.changeSomething(`GL account`)}
				label={`GL account`}
				initialValue={settings?.glAccountCode ?? ''}
				cancel={cancel}
				submitText={strings.update}
				submitFunction={value => exactGlobeSettingsCommand_patchGLAccount({ glAccountCode: value })}
			/>
		);
	};

	const onPatchFallbackCostUnit = () => {
		open(
			<StringModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.changeSomething(`default kostendrager`)}
				label={`Default kostendrager`}
				initialValue={settings?.fallbackCostUnit ?? ''}
				cancel={cancel}
				submitText={strings.update}
				submitFunction={value => exactGlobeSettingsCommand_patchFallbackCostUnit({ fallbackCostUnit: value })}
			/>
		);
	};

	if (settings === undefined) {
		return <></>;
	}

	return (
		<div
			className='df-col fg1'
			style={{ padding: 16, maxWidth: 1280 }}>
			<Accordion
				expanded={expandedAccordion === 'base_settings'}
				onChange={() => setExpandedAccordion('base_settings')}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<span style={{ fontWeight: 500 }}>{`Basis instellingen`}</span>
				</AccordionSummary>
				<AccordionDetails className='df-col gap-8'>
					<LabelledPropertyX
						label='Divisie'
						icon={<StatusIcon status={Boolean(settings.divisionCode) ? 'success' : 'warning'} />}
						iconPosition='start'
						edit={onPatchDivisionCode}>
						{Boolean(settings.divisionCode) ? settings.divisionCode : <em>{strings.notSet}</em>}
					</LabelledPropertyX>
					<LabelledPropertyX
						label='Dagboek'
						icon={<StatusIcon status={Boolean(settings.journalCode) ? 'success' : 'warning'} />}
						iconPosition='start'
						edit={onPatchJournal}>
						{Boolean(settings.journalCode) ? settings.journalCode : <em>{strings.notSet}</em>}
					</LabelledPropertyX>
					<LabelledPropertyX
						label='GL account'
						icon={<StatusIcon status={Boolean(settings.glAccountCode) ? 'success' : 'warning'} />}
						iconPosition='start'
						edit={onPatchGlAccountCode}>
						{Boolean(settings.glAccountCode) ? settings.glAccountCode : <em>{strings.notSet}</em>}
					</LabelledPropertyX>
					<LabelledPropertyX
						label='Fallback kostendrager'
						icon={<StatusIcon status={Boolean(settings.fallbackCostUnit) ? 'success' : 'warning'} />}
						iconPosition='start'
						edit={onPatchFallbackCostUnit}>
						{Boolean(settings.fallbackCostUnit) ? settings.fallbackCostUnit : <em>{strings.notSet}</em>}
					</LabelledPropertyX>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expandedAccordion === 'cost_centers'}
				onChange={() => setExpandedAccordion('cost_centers')}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<span style={{ fontWeight: 500 }}>{`Kostenplaatsen`}</span>
				</AccordionSummary>
				<AccordionDetails className='df-col gap-8'>
					<CostCentersComponent
						reload={reload}
						reloadTrigger={reloadTrigger}
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expandedAccordion === 'cost_units'}
				onChange={() => setExpandedAccordion('cost_units')}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<span style={{ fontWeight: 500 }}>{`Kostendragers`}</span>
				</AccordionSummary>
				<AccordionDetails className='df-col gap-8'>
					<CostUnitsComponent
						reload={reload}
						reloadTrigger={reloadTrigger}
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expandedAccordion === 'locations'}
				onChange={() => setExpandedAccordion('locations')}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<div className='df-row-ac gap-8'>
						<span style={{ fontWeight: 500 }}>{`Locaties`}</span>
						{status.countUnlinkedLocations > 0 && (
							<BadgeChipX
								value={status.countUnlinkedLocations}
								color='error'
							/>
						)}
					</div>
				</AccordionSummary>
				<AccordionDetails className='df-col gap-8'>
					<LocationsComponent
						reload={reload}
						reloadTrigger={reloadTrigger}
					/>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

type CostCentersComponentProps = {
	reload: VoidFunction;
	reloadTrigger: Trigger;
};

const CostCentersComponent = ({ reload, reloadTrigger }: CostCentersComponentProps) => {
	const [costCenters, reloadCostCenters] = useApiEffect(exactGlobeCostCenter_all);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadCostCenters();
	}, [reloadTrigger]);

	const onNew = () => {
		open(
			<CreateCostCenterDialog
				open
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	if (costCenters === undefined) {
		return <></>;
	}

	return (
		<div className='df-col gap-8'>
			{costCenters.map(t => (
				<React.Fragment key={t.id}>
					<CostCenterListItem
						reload={reload}
						item={t}
					/>
					<Divider />
				</React.Fragment>
			))}
			<TextLinkButton
				startIcon={<AddCircleOutlineIcon />}
				onClick={onNew}>
				{`Nieuwe kostenplaats`}
			</TextLinkButton>
		</div>
	);
};

type CostCenterListItemProps = {
	item: IExactGlobeCostCenter;
	reload: VoidFunction;
};

const CostCenterListItem = ({ item, reload }: CostCenterListItemProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onEdit = () => {
		open(
			<PatchCostCenterDialog
				item={item}
				open
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	return (
		<div className='df-row gap-8'>
			<div className='df-col gap-4'>
				<LabelledPropertyX label='Code'>{item.code}</LabelledPropertyX>
				<LabelledPropertyX label='Naam'>{item.description}</LabelledPropertyX>
			</div>
			<div className='fg1'></div>
			<IconButton
				size='small'
				onClick={onEdit}>
				<EditOutlinedIcon fontSize='small' />
			</IconButton>
		</div>
	);
};

type CreateCostCenterDialogProps = DialogProps & {
	cancel: VoidFunction;
	confirm: VoidFunction;
};

const CreateCostCenterDialog = ({ cancel, confirm, ...rest }: CreateCostCenterDialogProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(exactGlobeCostCenter_create);
	const [state, setState] = useState<ICreateExactGlobeCostCenterRequest>({ code: '', description: '' });
	const notify = useSnackbarNotify();

	return (
		<DialogX
			{...rest}
			open={rest.open}>
			<DialogTitle>{`Nieuwe kostenplaats`} </DialogTitle>
			<DialogContent
				className='df-col gap-8'
				dividers>
				<TextField
					size='small'
					variant='filled'
					label='Code'
					value={state.code}
					onChange={e => setState({ ...state, code: e.target.value })}
				/>
				<TextField
					size='small'
					variant='filled'
					label='Description'
					value={state.description}
					onChange={e => setState({ ...state, description: e.target.value })}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				submitText={strings.create}
				cancel={cancel}
				onSubmit={async () => {
					const r = await submit(state);
					if (r.isSuccess) {
						if (r.result.hasError) {
							notify(r.result.error, 'error');
						} else {
							confirm();
						}
					}
				}}
				isSubmitting={isSubmitting}
			/>
		</DialogX>
	);
};

type PatchCostCenterDialogProps = DialogProps & {
	item: IExactGlobeCostCenter;
	cancel: VoidFunction;
	confirm: VoidFunction;
};

const PatchCostCenterDialog = ({ item, cancel, confirm, ...rest }: PatchCostCenterDialogProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(exactGlobeCostCenter_patch);
	const [state, setState] = useState<IPatchExactGlobeCostCenterRequest>({ code: item.code, description: item.description, id: item.id });
	const notify = useSnackbarNotify();

	return (
		<DialogX
			{...rest}
			open={rest.open}>
			<DialogTitle>{`Editeer kostenplaats`} </DialogTitle>
			<DialogContent
				className='df-col gap-8'
				dividers>
				<TextField
					size='small'
					variant='filled'
					label='Code'
					value={state.code}
					onChange={e => setState({ ...state, code: e.target.value })}
				/>
				<TextField
					size='small'
					variant='filled'
					label='Description'
					value={state.description}
					onChange={e => setState({ ...state, description: e.target.value })}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				submitText={strings.update}
				cancel={cancel}
				onSubmit={async () => {
					const r = await submit(state);
					if (r.isSuccess) {
						if (r.result.hasError) {
							notify(r.result.error, 'error');
						} else {
							confirm();
						}
					}
				}}
				isSubmitting={isSubmitting}
			/>
		</DialogX>
	);
};

type CostUnitsComponentProps = {
	reload: VoidFunction;
	reloadTrigger: Trigger;
};

const CostUnitsComponent = ({ reload, reloadTrigger }: CostUnitsComponentProps) => {
	const [costUnits, reloadCostUnits] = useApiEffect(exactGlobeCostUnit_all);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadCostUnits();
	}, [reloadTrigger]);

	const onNew = () => {
		open(
			<CreateCostUnitDialog
				open
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	if (costUnits === undefined) {
		return <></>;
	}

	return (
		<div className='df-col gap-8'>
			{costUnits.map(t => (
				<React.Fragment key={t.id}>
					<CostUnitListItem
						reload={reload}
						item={t}
					/>
					<Divider />
				</React.Fragment>
			))}
			<TextLinkButton
				startIcon={<AddCircleOutlineIcon />}
				onClick={onNew}>
				{`Nieuwe kostendrager`}
			</TextLinkButton>
		</div>
	);
};

type CostUnitListItemProps = {
	item: IExactGlobeCostUnit;
	reload: VoidFunction;
};

const CostUnitListItem = ({ item, reload }: CostUnitListItemProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onEdit = () => {
		open(
			<PatchCostUnitDialog
				item={item}
				open
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	return (
		<div className='df-row gap-8'>
			<div className='df-col gap-4'>
				<LabelledPropertyX label='Code'>{item.code}</LabelledPropertyX>
				<LabelledPropertyX label='Naam'>{item.description}</LabelledPropertyX>
			</div>
			<div className='fg1'></div>
			<IconButton
				size='small'
				onClick={onEdit}>
				<EditOutlinedIcon fontSize='small' />
			</IconButton>
		</div>
	);
};

type CreateCostUnitDialogProps = DialogProps & {
	cancel: VoidFunction;
	confirm: VoidFunction;
};

const CreateCostUnitDialog = ({ cancel, confirm, ...rest }: CreateCostUnitDialogProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(exactGlobeCostUnit_create);
	const [state, setState] = useState<ICreateExactGlobeCostUnitRequest>({ code: '', description: '' });
	const notify = useSnackbarNotify();

	return (
		<DialogX
			{...rest}
			open={rest.open}>
			<DialogTitle>{`Nieuwe kostendrager`} </DialogTitle>
			<DialogContent
				className='df-col gap-8'
				dividers>
				<TextField
					size='small'
					variant='filled'
					label='Code'
					value={state.code}
					onChange={e => setState({ ...state, code: e.target.value })}
				/>
				<TextField
					size='small'
					variant='filled'
					label='Description'
					value={state.description}
					onChange={e => setState({ ...state, description: e.target.value })}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				submitText={strings.create}
				cancel={cancel}
				onSubmit={async () => {
					const r = await submit(state);
					if (r.isSuccess) {
						if (r.result.hasError) {
							notify(r.result.error, 'error');
						} else {
							confirm();
						}
					}
				}}
				isSubmitting={isSubmitting}
			/>
		</DialogX>
	);
};

type PatchCostUnitDialogProps = DialogProps & {
	item: IExactGlobeCostUnit;
	cancel: VoidFunction;
	confirm: VoidFunction;
};

const PatchCostUnitDialog = ({ item, cancel, confirm, ...rest }: PatchCostUnitDialogProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(exactGlobeCostCenter_patch);
	const [state, setState] = useState<IPatchExactGlobeCostUnitRequest>({ code: item.code, description: item.description, id: item.id });
	const notify = useSnackbarNotify();

	return (
		<DialogX
			{...rest}
			open={rest.open}>
			<DialogTitle>{`Editeer kostendrager`} </DialogTitle>
			<DialogContent
				className='df-col gap-8'
				dividers>
				<TextField
					size='small'
					variant='filled'
					label='Code'
					value={state.code}
					onChange={e => setState({ ...state, code: e.target.value })}
				/>
				<TextField
					size='small'
					variant='filled'
					label='Description'
					value={state.description}
					onChange={e => setState({ ...state, description: e.target.value })}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				submitText={strings.update}
				cancel={cancel}
				onSubmit={async () => {
					const r = await submit(state);
					if (r.isSuccess) {
						if (r.result.hasError) {
							notify(r.result.error, 'error');
						} else {
							confirm();
						}
					}
				}}
				isSubmitting={isSubmitting}
			/>
		</DialogX>
	);
};

type LocationsComponentProps = {
	reload: VoidFunction;
	reloadTrigger: Trigger;
};

const LocationsComponent = ({ reload, reloadTrigger }: LocationsComponentProps) => {
	const [locations, reloadLocations] = useApiEffect(exactGlobeLocation_all);

	useLazyEffect(() => {
		reloadLocations();
	}, [reloadTrigger]);

	if (locations === undefined) {
		return <></>;
	}

	return (
		<div className='df-col gap-8'>
			{locations.map(t => (
				<React.Fragment key={t.id}>
					<LocationListItem
						reload={reload}
						item={t}
					/>
					<Divider />
				</React.Fragment>
			))}
		</div>
	);
};

type LocationListItemProps = {
	item: IExactGlobeLocationDto;
	reload: VoidFunction;
};

const LocationListItem = ({ item, reload }: LocationListItemProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onLink = () => {
		open(
			<LinkExactGlobeLocationDialog
				item={item}
				open
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	return (
		<div className='df-row-ac gap-8'>
			<div className='df-col gap-4'>
				<ChipX
					label={item.isLinked ? strings.linked : strings.notLinked}
					color={item.isLinked ? 'success' : 'error'}
					style={{ marginRight: 'auto' }}
				/>
				<LabelledPropertyX label='Plaats'>{item.identifier}</LabelledPropertyX>
				{item.costCenter && <LabelledPropertyX label='Code kostendrager'>{item.costCenter.code}</LabelledPropertyX>}
			</div>
			<div className='fg1'></div>
			<IconButton
				size='small'
				onClick={onLink}>
				<LinkIcon fontSize='small' />
			</IconButton>
		</div>
	);
};

type LinkExactGlobeLocationDialogProps = DialogProps & {
	item: IExactGlobeLocationDto;
	cancel: VoidFunction;
	confirm: VoidFunction;
};

const LinkExactGlobeLocationDialog = ({ item, cancel, confirm, ...rest }: LinkExactGlobeLocationDialogProps) => {
	const strings = useLocalization();
	const [costCenters] = useApiEffect(exactGlobeCostCenter_all);
	const [submit, isSubmitting] = useFormSubmit(exactGlobeLocation_link);
	const [state, setState] = useState<ILinkExactGlobeLocationRequest>({
		hamsLocationId: item.hamsId,
		exactGlobeCostCenterId: item.exactGlobeCostCenterId ?? '',
	});
	const notify = useSnackbarNotify();

	return (
		<DialogX
			{...rest}
			open={rest.open}>
			<DialogTitle>{`Editeer kostendrager`} </DialogTitle>
			<DialogContent
				className='df-col gap-8'
				dividers>
				<SelectField<IExactGlobeCostCenter>
					options={costCenters ?? []}
					value={costCenters?.find(t => t.id === state.exactGlobeCostCenterId)}
					onChange={t => setState({ ...state, exactGlobeCostCenterId: t?.id ?? '' })}
					getKey={t => t.id}
					label='Kostenplaats'
					renderValue={t => `${t.code} ${t.description}`}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				submitText={strings.update}
				cancel={cancel}
				onSubmit={async () => {
					const r = await submit(state);
					if (r.isSuccess) {
						if (r.result.hasError) {
							notify(r.result.error, 'error');
						} else {
							confirm();
						}
					}
				}}
				isSubmitting={isSubmitting}
			/>
		</DialogX>
	);
};
