import { useTheme } from '@material-ui/core';
import React from 'react';
import { ColorX } from '../color/ColorX';
import { mapColorXToThemeColor } from '../color/mapColorXToThemeColor';
import { StatusIcon } from '../icons/StatusIcon';
import { ILabelledPropertyXProps, LabelledPropertyX } from './LabelledPropertyX';

type Variant = 'error' | 'warning' | 'success' | 'info' | 'help';

const VariantToColorRecord: Record<Variant, ColorX> = {
	error: 'error',
	info: 'info',
	success: 'success',
	warning: 'warning',
	help: 'info',
};

interface IProps extends ILabelledPropertyXProps {
	variant: Variant;
}

export const VariantLabelledPropertyX = ({ variant, children, ...rest }: IProps) => {
	const theme = useTheme();

	return (
		<LabelledPropertyX
			icon={<StatusIcon status={variant} />}
			{...rest}>
			<span style={{ color: mapColorXToThemeColor(VariantToColorRecord[variant], theme, true) }}>{children}</span>
		</LabelledPropertyX>
	);
};
