import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useTrigger } from 'framework/hooks/useTrigger';
import { createType } from 'framework/utils/createType';
import { stockEntriesQuery_current } from 'gen/ApiClient';
import React from 'react';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { StockItemsOrDirtyCheck } from '../stockEntries/detail/stockItems/StockItemsOrDirtyCheck';
import { StockHeader } from './StockHeader';
import { QueryContext } from './context/QueryContext';

const QuickFilters = createType('all', 'hearingInstrument');
export type QuickFilter = typeof QuickFilters[number];

export const Stock = () => {
	const [trigger, hitTrigger] = useTrigger();
	const { params, setParams, queryResult } = useQueryContext(QueryContext, trigger);
	const [item, reload] = useApiEffect(stockEntriesQuery_current);

	useLazyEffect(() => {
		reload();
	}, [trigger]);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='Stock'
			stickyHeader={
				<StockHeader
					reload={hitTrigger}
					item={item}
					style={{ paddingBottom: 16 }}
				/>
			}>
			<StockItemsOrDirtyCheck
				item={item}
				params={params}
				queryResult={queryResult}
				reload={reload}
				setParams={setParams}
			/>
		</RestrictedPageX>
	);
};
