import { IDraggableItem } from 'framework/components/dnd/IDraggableItem';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { LineDescriptionQuantityAndLinePrice } from 'shared/searchAndSelect/lines/LineDescriptionQuantityAndLinePrice';
import { LinePricingLabelledProperties } from 'shared/searchAndSelect/lines/LinePricingLabelledProperties';
import { LineXComponent } from 'shared/searchAndSelect/lines/LineXComponent';
import { PriceOrDiscountForm } from 'shared/searchAndSelect/PriceOrDiscountForm';
import { ILine } from './ILine';

interface IProps extends IDraggableItem {
	line: ILine;
	index: number;
	setLine: (val: ILine) => void;
	onDelete: VoidFunction;
}

export const AdvanceInvoiceLineComponent = ({ line, setLine, ...rest }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext();

	const onEditPrice = () => {
		open(
			<PriceOrDiscountForm
				open
				listPrice={line.unitListPrice}
				unitPrice={line.unitPrice}
				confirm={(up, lp) => {
					confirm();
					setLine({ ...line, unitPrice: up, unitListPrice: lp });
				}}
				cancel={cancel}
				forPurchase={false}
			/>
		);
	};

	return (
		<LineXComponent
			{...rest}
			line={line}
			setLine={setLine}
			errors={line.error}>
			<LineDescriptionQuantityAndLinePrice<ILine>
				line={line}
				setLine={setLine}
				onClickPrice={onEditPrice}
			/>
			<div className='fg1'></div>
			<LinePricingLabelledProperties<ILine>
				line={line}
				onClick={onEditPrice}
				style={{ marginTop: 8 }}
			/>
		</LineXComponent>
	);
};
