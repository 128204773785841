import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import {
	audiogramStandardsQuery_withTtaByPatient,
	IAudiogramStandardWithToneThresholdAudiogramsDto,
	IMonoToneThresholdAudiogramTestModel,
	ITonePoint,
	trialReportsCommand_addToneThresholdLeft,
	trialReportsCommand_addToneThresholdRight,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { SelectAudiogramStandardToToneThresholdAudiogramsField } from './SelectAudiogramStandardToToneThresholdAudiogramsField';
import { ToneThresholdAudiogramPtaComponent } from './ToneThresholdAudiogramPtaComponent';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IMonoToneThresholdAudiogramTestModel>({
			audiogramStandardId: yup.string().required(),
			pointsForPta: yup.mixed(),
			data: yup.mixed(),
		})
		.defined();
};

const emptyValues: IMonoToneThresholdAudiogramTestModel = {
	audiogramStandardId: '',
	pointsForPta: [],
	data: undefined as any,
};

const stepsRecord: Record<number, (keyof IMonoToneThresholdAudiogramTestModel)[]> = {
	0: ['audiogramStandardId'],
	1: ['pointsForPta'],
};

type LeftOrRight = 'left' | 'right';

interface IProps extends DialogProps {
	patientId: string;
	trialId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
	type: LeftOrRight;
}

export const MonoToneThresholdAudiogramTestForm = ({ patientId, trialId, confirm, cancel, type, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(type === 'left' ? trialReportsCommand_addToneThresholdLeft : trialReportsCommand_addToneThresholdRight);
	const [step, setStep] = useState<number>(0);
	const [audiograms] = useApiEffect(audiogramStandardsQuery_withTtaByPatient, patientId);
	const monoAudiograms = useMemo(() => audiograms?.filter(t => (type === 'left' ? t.hasLeftAudiogram : t.hasRightAudiogram)), [audiograms, type]);
	const [ptaPoints, setPtaPoints] = useState<ITonePoint[]>([]);

	if (audiograms === undefined || monoAudiograms === undefined) {
		return <div></div>;
	}

	if (monoAudiograms.length === 0) {
		return (
			<NotificationDialog
				open
				title={strings.insufficientInformation}
				content={strings.thereIsNoTypeTonalAudiogramCreateFirst(` ${type === 'left' ? strings.left : strings.right}`)}
				close={cancel}
				closeText={strings.understood}
			/>
		);
	}

	const handleSubmit = async (values: IMonoToneThresholdAudiogramTestModel, helpers: FormikHelpers<IMonoToneThresholdAudiogramTestModel>) => {
		const r = await create(trialId, { ...values, pointsForPta: ptaPoints.map(t => t.stimulusFrequency) });
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.addedWhat(strings.test));
			confirm();
		}
	};

	return (
		<Formik<IMonoToneThresholdAudiogramTestModel>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					audiograms={monoAudiograms}
					ptaPoints={ptaPoints}
					setPtaPoints={setPtaPoints}
					type={type}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	audiograms: IAudiogramStandardWithToneThresholdAudiogramsDto[];
	ptaPoints: ITonePoint[];
	setPtaPoints: (points: ITonePoint[]) => void;
	type: LeftOrRight;
}

const InnerDialog = ({ step, setStep, cancel, audiograms, isSubmitting, ptaPoints, setPtaPoints, type, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IMonoToneThresholdAudiogramTestModel>();
	const strings = useLocalization();
	const tta = useMemo(
		() =>
			isNullOrUndefined(props.values.audiogramStandardId)
				? []
				: audiograms.find(t => t.id === props.values.audiogramStandardId)!.toneThresholdAudiograms.filter(t => (type === 'left' ? t.isLeft : t.isRight)),
		[props.values.audiogramStandardId, audiograms, type]
	);

	useEffect(() => {
		if (tta.length === 1) {
			setFormValue<IMonoToneThresholdAudiogramTestModel>('data', tta[0], props);
		}
		// eslint-disable-next-line
	}, [tta]);

	return (
		<Dialog {...rest}>
			<DialogTitleWithFormStepper
				title='Tonale test'
				step={step}
				labels={[strings.audiogram, 'PTA']}
			/>
			<DialogContent
				className='df-col'
				dividers>
				{step === 0 && (
					<>
						<SelectAudiogramStandardToToneThresholdAudiogramsField<IMonoToneThresholdAudiogramTestModel>
							audiograms={audiograms}
							type={type}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<ToneThresholdAudiogramPtaComponent<IMonoToneThresholdAudiogramTestModel>
							nameData='data'
							selectedPoints={ptaPoints}
							setSelectedPoints={setPtaPoints}
							toneThresholdAudiograms={tta}
							selectedToneThresholdAudiogram={tta.find(t => t === props.values.data)}
						/>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
