import { FormikProps } from 'formik';
import yup from 'yup';
import { validateStepModel } from './validateStepModel';

export const validateStepModelByRecord = <TModel extends object | null | undefined>(
	props: FormikProps<TModel>,
	schema: yup.ObjectSchema<TModel>,
	currentStep: number,
	record: Record<number, (keyof TModel)[]>
): boolean => {
	return validateStepModel(props, schema, record[currentStep]);
};
