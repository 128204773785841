import { Collapse, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { useStateBoolean } from '../hooks/useStateBool';
import { calculateCellStyle } from './calculateCellStyle';
import { IHeader } from './IHeader';
import { toBorder } from './toBorder';

interface ICollapsableTableRowProps<T> {
	value: T;
	headers: IHeader<T>[];
	padding: number;
	index: number;
	expandableContent?: JSX.Element;
	isExpanded: boolean;
	toggleExpand: VoidFunction;
	isLast: boolean;
}

export const TableRowWithDetails = <T extends unknown>({
	value,
	headers,
	padding,
	index,
	expandableContent,
	isExpanded,
	toggleExpand,
	isLast,
}: ICollapsableTableRowProps<T>) => {
	const [hover, enter, leave] = useStateBoolean(false);

	return (
		<>
			<TableRow
				onMouseEnter={enter}
				onMouseLeave={leave}
				hover={false}
				// key={key}
				onClick={() => {}}
				// role="checkbox"
				tabIndex={-1}
				selected={false}>
				{headers.map(header => (
					<TableCell
						key={header.key}
						align={header.align}
						style={calculateCellStyle<T>(padding, header, isLast || expandableContent !== undefined)}>
						<div style={{ visibility: hover === false && header.onlyShowOnHover ? 'collapse' : 'inherit' }}>
							{header.evaluate(value, index, isExpanded, toggleExpand)}
						</div>
					</TableCell>
				))}
			</TableRow>
			{expandableContent && (
				<TableRow style={{ borderTop: 'unset', borderBottom: 'unset' }}>
					<TableCell
						style={{ padding: padding, paddingBottom: 0, paddingTop: 0, borderTop: 'unset', borderBottom: toBorder(isLast) }}
						colSpan={headers.length}>
						<Collapse
							in={isExpanded}
							timeout='auto'
							unmountOnExit>
							{expandableContent}
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</>
	);
};
