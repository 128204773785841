import React, { useState } from 'react';
import { SelectPatientByIdForm } from 'shared/patientSearch/SelectPatientByIdForm';
import { RegisterLoanForm } from './RegisterLoanForm';

interface IProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

type State = 'patient' | 'model';

export const LoanForm = ({ cancel, confirm }: IProps) => {
	const [state, setState] = useState<State>('patient');
	const [patientId, setPatientId] = useState<string>();

	const confirmPatient = (patientId: string) => {
		setPatientId(patientId);
		setState('model');
	};

	if (state === 'patient') {
		return (
			<SelectPatientByIdForm
				open
				confirm={confirmPatient}
				cancel={cancel}
			/>
		);
	} else if (patientId) {
		return (
			<RegisterLoanForm
				open
				confirm={confirm}
				cancel={cancel}
				patientId={patientId}
			/>
		);
	} else {
		return <div></div>;
	}
};
