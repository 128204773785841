import BlockIcon from '@material-ui/icons/Block';
import BlurCircularOutlinedIcon from '@material-ui/icons/BlurCircularOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { ITender } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: ITender;
	style?: React.CSSProperties;
}

export const TenderListItemStatusIcon = ({ item, style }: IProps) => {
	if (item.isDeleted) {
		return <BlockIcon style={style} />;
	} else if (item.hasSalesTransaction) {
		return <CheckCircleOutlinedIcon style={style} />;
	} else if (item.isLost) {
		return <CancelOutlinedIcon style={style} />;
	} else {
		return <BlurCircularOutlinedIcon style={style} />;
	}
};
