import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IEndRfiaAllTrialsRequest, rfiaTrialsCommand_endAllTrials } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { LocationsPreloadCacheContext } from '../../../../settings/locations/LocationsPreloadCacheContext';
import { useCurrentLocation } from '../../../../settings/locations/useCurrentLocation';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IEndRfiaAllTrialsRequest>({
			returnToLocationId: yup.string().required(strings.formRequired(strings.location)),
			stopDate: yup.date().required(strings.formRequired(strings.stopDate)),
			rfiaId: yup.string().required(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IEndRfiaAllTrialsRequest)[]> = {
	0: ['returnToLocationId'],
	1: ['stopDate'],
};

interface IProps extends DialogProps {
	rfiaId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const EndAllTrialsModelForm = ({ rfiaId, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [submit, isSubmitting] = useFormSubmit(rfiaTrialsCommand_endAllTrials);
	const [step, setStep] = useState<number>(0);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const location = useCurrentLocation();
	const now = useNow();

	const handleSubmit = async (values: IEndRfiaAllTrialsRequest, helpers: FormikHelpers<IEndRfiaAllTrialsRequest>) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.trialEnded);
			confirm();
		}
	};

	if (locations === undefined || location === undefined || now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IEndRfiaAllTrialsRequest>
			validateOnMount
			initialValues={{
				returnToLocationId: location.id,
				stopDate: now,
				rfiaId: rfiaId,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='md'>
					<DialogTitleWithFormStepper
						title={strings.endTrialPositively}
						step={step}
						labels={[strings.location, strings.date]}
					/>
					<DialogContent
						className='df-col'
						dividers>
						{step === 0 && (
							<FormSelectFieldWithOption<IEndRfiaAllTrialsRequest, string>
								name='returnToLocationId'
								options={locations}
								label={strings.location}
								helperText={strings.locationWhereProductsWillBeReceived}
								required
							/>
						)}
						{step === 1 && (
							<FormDatePicker<IEndRfiaAllTrialsRequest>
								name='stopDate'
								label={strings.endDate}
								disablePast
							/>
						)}
					</DialogContent>
					<PageableFormDialogActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.register}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
