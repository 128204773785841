import { quantityAtLocation } from 'app/main/financial/sales/forms/utils/quantityAtLocation';
import { ILocationSummary, IProductCatalogItem, IStockAtLocation } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

interface ILine {
	stockAtLocations: IStockAtLocation[] | undefined;
	productCatalogItem: IProductCatalogItem | undefined;
	quantity: number;
}

export const calculateStockWarning = (line: ILine, location: ILocationSummary, strings: IStrings): string | undefined => {
	if (
		line.productCatalogItem !== undefined &&
		line.productCatalogItem.isUniquelyIdentifiable === false &&
		line.productCatalogItem.isTangible &&
		line.stockAtLocations &&
		line.quantity > quantityAtLocation(line.stockAtLocations, location.id)
	) {
		return strings.qtyMoreThanAvailableAtLocation(quantityAtLocation(line.stockAtLocations, location.id), location.identifier);
	} else {
		return undefined;
	}
};
