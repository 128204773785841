import { CardProps } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { isLast } from 'framework/utils/array/isLast';
import { IPurchase } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { PurchasePaymentListItem } from './PurchasePaymentListItem';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';

interface IProps extends CardProps {
	item: IPurchase;
}

export const PurchaseDetailPaymentsCard = ({ item, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<CardX {...rest}>
			<div
				className='df-row'
				style={{ gap: 8 }}>
				<Typography16pxBold>{strings.payments}</Typography16pxBold>
				<div className='fg1'></div>
			</div>
			{item.payments.map(t => (
				<PurchasePaymentListItem
					key={t.paymentId}
					line={t}
					withoutBorder={isLast(t, item.payments)}
				/>
			))}
			{item.payments.length === 0 && <NoRecordsFound variant='caption'>{strings.noPaymentsRegisteredYet}</NoRecordsFound>}
		</CardX>
	);
};
