import { IOrderV2 } from 'gen/ApiClient';
import { OrderProcessStep } from './OrderProcessStep';

export const calculateActiveOrderProcessStep = (item: IOrderV2): OrderProcessStep | undefined => {
	if (item.isCompleted) {
		return undefined;
	} else if (item.isPlacedWithSupplier) {
		return 'deliver';
	} else {
		return 'place';
	}
};
