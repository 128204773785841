import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISupplier } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (colRecord: Record<SelectableColumn, string>, strings: IStrings): Record<SelectableColumn, IHeader<ISupplier>> => {
	return {
		created: createSortableHeaderWithKey<ISupplier, SelectableColumn>(colRecord, 'created', t => formatDate(t.created)),
		outstandingBalance: createSortableHeaderWithKey<ISupplier, SelectableColumn>(colRecord, 'outstandingBalance', t => formatCurrency(t.outstandingBalance)),
		identifier: createSortableHeaderWithKey<ISupplier, SelectableColumn>(colRecord, 'identifier', t => t.identifier),
		amountToPay: createSortableHeaderWithKey<ISupplier, SelectableColumn>(colRecord, 'amountToPay', t => formatCurrency(t.amountToPay)),
		countPurchases: createSortableHeaderWithKey<ISupplier, SelectableColumn>(colRecord, 'countPurchases', t => t.countPurchases.toString()),
	};
};
