import { formatDateXLocalized } from 'framework/utils/date/formatDateXLocalized';
import { FormatDateXLocalizedType } from 'framework/utils/date/FormatDateXLocalizedType';
import { LanguageOptionToLocaleRecord } from 'localization/LanguageOptionToLocaleRecord';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';

export const useFormatDateXLocalized = () => {
	const language = useCurrentLanguage();
	return (date: Date | undefined | null | string | number, format: FormatDateXLocalizedType = 'dd/MM/yyyy') =>
		formatDateXLocalized(date, LanguageOptionToLocaleRecord[language], format);
};
