import { LabelledChipsList } from 'framework/components/chips/LabelledChipsList';
import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISettlementProposal } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../../sps/SelectableColumn';
import React from 'react';

export const createHeaders = (colRecord: Record<SelectableColumn, string>, strings: IStrings): Record<SelectableColumn, IHeader<ISettlementProposal>> => {
	return {
		amountPaid: createSortableHeaderWithKey<ISettlementProposal, SelectableColumn>(colRecord, 'amountPaid', t => formatCurrency(t.amountPaid), 'right'),
		created: createSortableHeaderWithKey<ISettlementProposal, SelectableColumn>(colRecord, 'created', t => formatDate(t.created)),
		paymentReference: createSortableHeaderWithKey<ISettlementProposal, SelectableColumn>(colRecord, 'paymentReference', t => t.paymentReference),
		reference: createSortableHeaderWithKey<ISettlementProposal, SelectableColumn>(colRecord, 'reference', t => t.reference),
		totalValue: createSortableHeaderWithKey<ISettlementProposal, SelectableColumn>(colRecord, 'totalValue', t => formatCurrency(t.totalValue), 'right'),
		customerAccountIdentifier: createSortableHeaderWithKey<ISettlementProposal, SelectableColumn>(
			colRecord,
			'customerAccountIdentifier',
			t => t.customerAccountIdentifier
		),
		belgianStructuredCreditorReference: createSortableHeaderWithKey<ISettlementProposal, SelectableColumn>(
			colRecord,
			'belgianStructuredCreditorReference',
			t => t.belgianStructuredCreditorReference
		),
		labels: createHeaderWithKey<ISettlementProposal, SelectableColumn>(colRecord, 'labels', t => <LabelledChipsList labels={t.labels} />),
	};
};
