import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from './CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from './FormExtendedRadioGroupField';

interface IModel {
	value: boolean;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			value: yup.boolean().defined(),
		})
		.defined();
};

interface IProps extends DialogProps {
	formTitle: string;
	notifyMessage?: string;
	initialValue: boolean;
	submit: (val: boolean) => void;
	cancel: VoidFunction;
	submitText: string;
	cancelText?: string;
	trueLabel: string;
	falseLabel: string;
	trueCaption?: string;
	falseCaption?: string;
	isSubmitting: boolean;
}

export const BoolExtendedRadioGroupWithExternalSubmitForm = ({
	isSubmitting,
	submit,
	cancel,
	formTitle,
	notifyMessage,
	initialValue,
	submitText,
	cancelText,
	trueLabel,
	falseLabel,
	trueCaption,
	falseCaption,
	...rest
}: IProps) => {
	const strings = useLocalization();

	const handleSubmit = (values: IModel) => {
		submit(values.value);
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{ value: initialValue }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					scroll='paper'
					fullWidth
					{...rest}>
					<DialogTitle>{formTitle}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormExtendedRadioGroupField<IModel, boolean>
							name='value'
							label='x'
							options={[
								{ value: true, label: trueLabel, caption: trueCaption },
								{ value: false, label: falseLabel, caption: falseCaption },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={submitText ?? strings.select}
						cancelText={cancelText}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
