import React, { useMemo, useState } from 'react';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IFile } from 'framework/utils/IFile';
import {
	customFeaturesQuery_download,
	customFeaturesCommand_consignmentFile_initial,
	customFeaturesCommand_consignmentFile_resume,
	IConsignmentFileResponse,
	IWorksheetInfo,
} from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import { UploadFileDialog } from 'shared/dialogs/upload/UploadFileDialog';
import { PasswordForm } from '../patients/forms/PasswordForm';
import { SelectSheetAndColumnDialog } from './SelectSheetAndColumnDialog';

interface IProps {
	close: VoidFunction;
}

type State = 'upload' | 'password' | 'selectSheetAndColumn' | 'download';

export const ProcessCfDialog = ({ close }: IProps) => {
	const strings = useLocalization();
	const [state, setState] = useState<State>('upload');
	const [resume, isResuming] = useFormSubmit(customFeaturesCommand_consignmentFile_resume);
	const [init, isInitialUploading] = useFormSubmit(customFeaturesCommand_consignmentFile_initial);
	const [downloadResult, isDownloadingResult] = useDownload(customFeaturesQuery_download);
	const notify = useSnackbarNotify();
	const [resumeId, setResumeId] = useState<string>();
	const [password, setPassword] = useState<string>();
	const isSubmittingOrDownloading = useMemo(
		() => isResuming || isInitialUploading || isDownloadingResult,
		[isResuming, isInitialUploading, isDownloadingResult]
	);
	const [sheets, setSheets] = useState<IWorksheetInfo[]>();

	const onFileSelected = async (file: IFile) => {
		const r = await init({ contentAsBase64: file.contentAsBase64, fileName: file.fileName, password: '' });
		if (r.isSuccess) {
			handleResponse(r.result);
		}
	};

	const onResumeWithPassword = async (password: string) => {
		setPassword(password);
		const r = await resume(resumeId!, { password: password, serialNumberHeaderIndex: -1, worksheetName: undefined });
		if (r.isSuccess) {
			handleResponse(r.result);
		}
	};

	const onResumeWithSheetAndColumn = async (sheetName: string, column: number) => {
		const r = await resume(resumeId!, { password: password, serialNumberHeaderIndex: column, worksheetName: sheetName });
		if (r.isSuccess) {
			handleResponse(r.result);
		}
	};

	const handleResponse = async (r: IConsignmentFileResponse) => {
		if (r.error) {
			notify(r.error, 'error');
		} else if (r.fileResponseId) {
			// just download the file and close this dialog
			await downloadResult(r.fileResponseId);
			close();
		} else {
			setResumeId(r.resumeId);
			setSheets(r.worksheets);
			setState(r.isIncorrectPassword ? 'password' : 'selectSheetAndColumn');
		}
	};

	if (state === 'upload') {
		return (
			<UploadFileDialog
				open
				cancel={close}
				confirm={onFileSelected}
				accept='excel'
				title='Selecteer consignatie lijst (.xlsx bestand)'
				isUploading={isSubmittingOrDownloading}
			/>
		);
	} else if (state === 'password') {
		return (
			<PasswordForm
				open
				title={strings.providePassword}
				caption={strings.thisFileIsPasswordProtected}
				confirm={onResumeWithPassword}
				isSubmitting={isSubmittingOrDownloading}
				cancel={close}
				hasInvalidPassword={false}
				submitText='Ga verder'
			/>
		);
	} else if (state === 'selectSheetAndColumn') {
		return (
			<SelectSheetAndColumnDialog
				open
				confirm={onResumeWithSheetAndColumn}
				cancel={close}
				sheets={sheets!}
				isSubmitting={isSubmittingOrDownloading}
			/>
		);
	} else {
		return <div></div>;
	}
};
