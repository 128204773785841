import { IInventoryItemsQueryParamsForLoan } from 'gen/ApiClient';

export const DefaultInventoryItemsQueryParamsForLoan: IInventoryItemsQueryParamsForLoan = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	categories: [],
	subcategories: [],
	forProductCatalogId: undefined,
	isShowAll: false,
};
