import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IStrings } from 'localization/IStrings';
import { ILoanLineWithSelection } from '../../../../../loans/ILoanLineWithSelection';

export const createHeaders = (
	lines: ILoanLineWithSelection[],
	setLines: React.Dispatch<React.SetStateAction<ILoanLineWithSelection[]>>,
	strings: IStrings
): IHeader<ILoanLineWithSelection>[] => {
	return [
		createSelectionHeader<ILoanLineWithSelection>(lines, setLines),
		createSortableHeader<ILoanLineWithSelection>(strings.description, 'description', t => t.description),
		createSortableHeader<ILoanLineWithSelection>(strings.quantity, 'quantity', t => t.quantity.toString()),
		createSortableHeader<ILoanLineWithSelection>(`${strings.listPrice}\n${strings.inVatShort}`, 'unitListPriceInVat', t =>
			formatCurrency(t.unitListPriceInVat)
		),
		createSortableHeader<ILoanLineWithSelection>(`${strings.salesPrice}\n${strings.inVatShort}`, 'unitPriceInVat', t => formatCurrency(t.unitPriceInVat)),
	];
};
