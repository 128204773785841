import { ICalendarEventDto, ICalendarEventExport } from 'gen/ApiClient';

export const mapCalendarEventToExport = (event: ICalendarEventDto): ICalendarEventExport => {
	return {
		allDay: event.isAllDay,
		contact: event.contact,
		description: event.description,
		endDate: event.endDate,
		eventTypeIdentifier: event.eventTypeIdentifier,
		formTitle: event.formTitle,
		patientIdentifier: event.patientIdentifier,
		roomIdentifier: event.roomIdentifier,
		startDate: event.startDate,
		userIdentifiersCsv: event.userIdentifiersCsv,
	};
};
