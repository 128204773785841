import { Chip } from '@material-ui/core';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { IArticlePropertyDefinition } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { apdToType } from './utils/apdToType';

interface IProps {
	item: IArticlePropertyDefinition;
	label: string;
}

export const ArticlePropertyDefinitionComponent = ({ item, label }: IProps) => {
	const strings = useLocalization();
	const property = useMemo(() => apdToType(item, strings), [item, strings]);

	return (
		<>
			<LabelledProperty
				label={`${label} [${item.key}]`}
				property={property}
				propertyStyle={{ fontStyle: 'italic' }}
			/>
			{item.hasOptions &&
				item.options.length > 0 &&
				item.options.map(t => (
					<Chip
						key={t}
						size='small'
						label={t}
						style={{ marginBottom: 3, marginRight: 3 }}
					/>
				))}
		</>
	);
};
