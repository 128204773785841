import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import LinkIcon from '@material-ui/icons/Link';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { INoahUserSetup, noahUserSetupsCommand_copy, noahUserSetupsCommand_link } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: INoahUserSetup;
	withoutBorder?: boolean;
	reload: VoidFunction;
}

export const NoahUserSetupListItem = ({ item, withoutBorder, reload }: IProps) => {
	const strings = useLocalization();
	const color = useMemo<ColorX>(() => (item.isLinked ? 'success' : 'error'), [item]);
	const tColor = useThemeColor(color);
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onLink = () => {
		open(
			<StringModelForm
				open
				initialValue={``}
				confirm={confirm}
				cancel={cancel}
				submitFunction={val => noahUserSetupsCommand_link({ clientId: val, noahUserSetupId: item.id })}
				formTitle={`Link to a client id`}
				submitText={strings.update}
				label={`Client id`}
			/>
		);
	};

	const onCopy = () => {
		open(
			<StringModelForm
				open
				initialValue={``}
				confirm={confirm}
				cancel={cancel}
				submitFunction={val => noahUserSetupsCommand_copy({ noahUserSetupId: item.id, toClientId: val })}
				formTitle={`Copy to a client id`}
				submitText={strings.update}
				label={`Client id`}
			/>
		);
	};

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<BlurCircularIcon style={{ fontSize: 22, color: tColor }} />
			</div>
			<div className='df-col gap-4 fg1'>
				<div className='df-row-ac'>
					<span style={{ fontSize: MajorFontSize }}>{item.id}</span>
				</div>
				<ChipX
					label={item.isLinked ? strings.linked : strings.notLinked}
					color={color}
					style={{ marginRight: 'auto' }}
				/>
				<span style={{ fontSize: MinorFontSize }}>{`Module-Id: ${item.moduleId.toString()}`}</span>
				{item.isLinked === true && <span style={{ fontSize: MinorFontSize }}>{`Client-Id: ${item.clientId}`}</span>}
				{item.isLinked === false && (
					<TextLinkButton
						color='primary'
						startIcon={<LinkIcon />}
						onClick={onLink}>
						{strings.link}
					</TextLinkButton>
				)}
				{item.isLinked === true && (
					<TextLinkButton
						color='primary'
						startIcon={<FileCopyOutlinedIcon />}
						onClick={onCopy}>
						{`Copy`}
					</TextLinkButton>
				)}
			</div>
		</div>
	);
};
