import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { IPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PatientContactPersonForm } from '../../forms/PatientContactPersonForm';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';

interface IProps {
	item: IPatient;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const PatientContactPersonCardContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onEdit = () => {
		open(
			<PatientContactPersonForm
				open
				confirm={confirm}
				cancel={cancel}
				patientId={item.id}
				model={item.contactPerson}
			/>
		);
	};

	return (
		<div
			className='df-col'
			style={style}>
			<CardTitleTypography
				title={strings.contactPerson}
				style={{ marginBottom: 8 }}
			/>
			{item.hasContactPerson === false && (
				<div className='df-col gap-6'>
					<em>{strings.noContactPerson}</em>
					<TextLinkButton
						startIcon={<PersonAddOutlinedIcon />}
						onClick={onEdit}>
						{strings.addContactPerson}
					</TextLinkButton>
				</div>
			)}
			{item.hasContactPerson === true && (
				<div className='df-col gap-6'>
					<LabelledPropertyX
						label={strings.name}
						edit={onEdit}>
						{item.contactPerson.name}
					</LabelledPropertyX>
					{item.contactPerson.departmentOrLocation && (
						<LabelledPropertyX
							label={strings.departmentSlashLocation}
							edit={onEdit}>
							{item.contactPerson.departmentOrLocation}
						</LabelledPropertyX>
					)}
					{item.contactPerson.addressFormatted && (
						<LabelledPropertyX
							label={strings.address}
							edit={onEdit}>
							{item.contactPerson.addressFormatted}
						</LabelledPropertyX>
					)}
					{item.contactPerson.phoneNumber && (
						<LabelledPropertyX
							label={strings.phone}
							edit={onEdit}>
							{item.contactPerson.phoneNumber}
						</LabelledPropertyX>
					)}
					{item.contactPerson.email && (
						<LabelledPropertyX
							label={strings.email}
							edit={onEdit}>
							{item.contactPerson.email}
						</LabelledPropertyX>
					)}
				</div>
			)}
		</div>
	);
};
