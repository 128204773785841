import { ChipX } from 'framework/components/chips/ChipX';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IXSale {
	isFullyCredited: boolean;
	isPartlyCredited?: boolean;
}

interface IProps {
	item: IXSale;
	style?: React.CSSProperties;
}

export const SalesCreditChipOrFragment = ({ item, style }: IProps) => {
	const strings = useLocalization();

	return (
		<>
			{item.isFullyCredited === true && (
				<ChipX
					label={strings.statusCredited}
					color='error'
					style={style}
				/>
			)}
			{item.isPartlyCredited === true && (
				<ChipX
					label={strings.statusPartlyCredited}
					color='error'
					style={style}
				/>
			)}
		</>
	);
};
