import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { IExactOnlineSale } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: IExactOnlineSale;
	color: ColorX;
	style?: React.CSSProperties;
}

export const ExactOnlineSaleListItemChipX = ({ item, color, style }: IProps) => {
	const strings = useLocalization();

	return (
		<ChipX
			label={item.isSynced ? strings.synced : item.isIgnored ? strings.ignored : strings.notSynced}
			color={color}
			style={style}
		/>
	);
};
