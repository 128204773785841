import React from 'react';
import { FilterMenuText } from './FilterMenuText';

interface IOptionLocally {
	identifier: string | undefined;
	caption?: string;
}

interface IProps {
	option: IOptionLocally;
}

export const FilterMenuTextFromOption = ({ option }: IProps) => {
	return (
		<FilterMenuText
			identifier={option.identifier}
			caption={option.caption}
		/>
	);
};
