import { StatCardX } from 'framework/components/cards/StatCardX';
import { RelativeChangeFadedRectangularChip } from 'framework/components/chips/RelativeChangeFadedRectangularChip';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISalesQueryMetadata, ISalesQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const Width: number = 250;

interface IProps {
	params: ISalesQueryParams;
	setParams: (params: ISalesQueryParams) => void;
	metadata: ISalesQueryMetadata;
	style?: React.CSSProperties;
}

export const SalesListStats = ({ metadata, style, setParams, params }: IProps) => {
	const strings = useLocalization();
	const onFilter = (pars: ISalesQueryParams) => setParams(onResetToPage1(pars));

	return (
		<div
			className='df-row-ac gap-16'
			style={style}>
			<StatCardX
				style={{ width: Width }}
				title={strings.nothingPaid}
				caption={`# ${strings.nothingPaid}`}
				onClick={() => onFilter({ ...params, prefilter: 'NotPaid' })}>
				<span style={{ fontSize: 24 }}>{metadata.countNotPaid.toString()}</span>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={strings.notPaidAndOverdue}
				caption={`# ${strings.notPaidAndOverdue}`}
				onClick={() => onFilter({ ...params, prefilter: 'Overdue' })}>
				<span style={{ fontSize: 24 }}>{metadata.countNotPaidAndOverdue.toString()}</span>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={strings.outstandingBalance}
				caption={strings.totalAmount}>
				<span style={{ fontSize: 24 }}>{formatCurrency(metadata.totalOutstandingBalance)}</span>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={strings.revenueThisYear}
				caption={`${strings.exVatShort}`}>
				<span style={{ fontSize: 24 }}>
					<div className='df-row-ac'>
						<div>{formatCurrency(metadata.amountInvoicedYtd.current)}</div>
						<RelativeChangeFadedRectangularChip
							change={metadata.amountInvoicedYtd}
							tooltip={strings.comparedWithSamePeriodLastYearBracketsWhat(formatCurrency(metadata.amountInvoicedYtd.previous))}
							style={{ marginLeft: 4 }}
						/>
					</div>
				</span>
			</StatCardX>
		</div>
	);
};
