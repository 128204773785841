import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import {
	IRequestForInsuranceAllowance,
	RfiaHandoverSaleGenerationMode,
	rfiaDocumentsCommand_createHandoverCertificate,
	rfiasQuery_settings,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { SalesDetailDialog } from '../../../../financial/sales/components/SalesDetailDialog';
import { SelectRfiaHandoverCertificateTemplateForm } from '../../detail/documents/SelectRfiaHandoverCertificateTemplateForm';
import { FailureHandoverForm } from './FailureHandoverForm';
import { HandoverMode } from './HandoverMode';
import { HandoverModeForm } from './HandoverModeForm';
import { SingleHandoverForm } from './SingleHandoverForm';
import { SplitHandoverForm } from './SplitHandoverForm';

type State = 'failure' | 'warning' | 'select-mode' | HandoverMode | 'document';

const rfiaToInitialState = (rfia: IRequestForInsuranceAllowance): State => {
	if (rfia.isFailedTrial) {
		return 'failure';
	} else if (rfia.hasAllApprovals === false) {
		return 'warning';
	} else if (rfia.hasThirdPartyPaymentScheme) {
		return 'select-mode';
	} else {
		return 'single';
	}
};

interface IProps {
	rfia: IRequestForInsuranceAllowance;
	isHifExist: boolean;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const HandoverForm = ({ rfia, isHifExist, cancel, confirm }: IProps) => {
	const strings = useLocalization();
	const [state, setState] = useState<State | undefined>(rfiaToInitialState(rfia));
	const [mainSalesId, setMainSalesId] = useState<string>();
	const { templateTypeRecord } = useContext(RecordContext);
	const [handoverDate, setHandoverDate] = useState<Date>();
	const [settings] = useApiEffect(rfiasQuery_settings);
	const mode = useMemo<RfiaHandoverSaleGenerationMode>(
		() => (settings?.handoverSaleGenerationMode ?? ('AskEveryTime' as RfiaHandoverSaleGenerationMode)) as RfiaHandoverSaleGenerationMode,
		[settings]
	);

	useEffect(() => {
		if (state === 'select-mode' && mode !== 'AskEveryTime') {
			setState(mode === 'GenerateSingleInvoice' ? 'single' : 'splits');
		}
	}, [mode, state]);

	const onConfirmHandover = (mainSalesId: string, handoverDate: Date) => {
		setHandoverDate(handoverDate);
		setState(rfia.isFailedTrialOrHasAllApprovals ? 'document' : undefined);
		setMainSalesId(mainSalesId);
	};

	if (settings === undefined) {
		return <></>;
	} else if (rfia.hasMainMedicalTreatmentNomenclatureDefined === false) {
		return (
			<NotificationDialog
				open
				fullWidth
				title={strings.noNomenclaturesSelected}
				content={strings.selectNomenclatureCodeFirst}
				close={cancel}
				closeText={strings.understood}
			/>
		);
	} else if (rfia.hasPseudoMedicalTreatmentNomenclatureDefined === false && rfia.isFailedTrial === false) {
		return (
			<NotificationDialog
				open
				fullWidth
				title={strings.noPseudoNomenclatureSelected}
				content={strings.selectPseudoNomenclatureCodeFirst}
				close={cancel}
				closeText={strings.understood}
			/>
		);
	} else if (isHifExist === false) {
		return (
			<NotificationDialog
				open
				fullWidth
				title={strings.fillInMissingData}
				content={
					rfia.healthInsuranceFundCode ? strings.hifWhatDoesNotExistAnymore(rfia.healthInsuranceFundCode!) : strings.selectWhat(strings.healthInsuranceFund)
				}
				close={cancel}
				closeText={strings.understood}
			/>
		);
	} else if (state === 'failure') {
		return (
			<FailureHandoverForm
				open
				confirm={onConfirmHandover}
				cancel={cancel}
				rfia={rfia}
			/>
		);
	} else if (state === 'warning') {
		return (
			<AlertDialog
				open
				title={strings.warningRegistrationHandoverBeforeApprovals}
				content={<div className='df-col'>{strings.warningRegistrationHandoverBeforeApprovalsCaption}</div>}
				rejectText={strings.goBack}
				acceptText={strings.continue}
				accept={() => setState(rfia.hasThirdPartyPaymentScheme ? 'select-mode' : 'single')}
				reject={cancel}
			/>
		);
	} else if (state === 'select-mode') {
		return (
			<HandoverModeForm
				open
				confirm={setState}
				cancel={cancel}
			/>
		);
	} else if (state === 'single') {
		return (
			<SingleHandoverForm
				open
				confirm={onConfirmHandover}
				cancel={cancel}
				rfia={rfia}
			/>
		);
	} else if (state === 'splits') {
		return (
			<SplitHandoverForm
				open
				confirm={onConfirmHandover}
				cancel={cancel}
				rfia={rfia}
			/>
		);
	} else if (state === 'document') {
		return (
			<SelectRfiaHandoverCertificateTemplateForm
				open
				submitF={values => rfiaDocumentsCommand_createHandoverCertificate(rfia.id, values)}
				confirm={() => setState(undefined)} // -> go to sales Detail dialog!
				cancel={() => setState(undefined)} // -> go to sales Detail dialog!
				cancelText={strings.closeThisWindow}
				submitText={strings.selectWhatAndCreate(strings.template)}
				title={`${strings.createADocumentRightAwayQuestion} ${templateTypeRecord['RfiaHandoverCertificate']}`}
				handoverDate={handoverDate}
			/>
		);
	} else if (mainSalesId !== undefined) {
		return (
			<SalesDetailDialog
				open
				close={confirm}
				id={mainSalesId}
				withCreate
			/>
		);
	} else {
		return <div></div>;
	}
};
