import React from 'react';
import { LanguageOption } from 'gen/ApiClient';
import { AnnotationsProvider } from '../annotations/context/AnnotationsProvider';
import { BebatDefaultCacheProvider } from './BebatDefaultCacheProvider';
import { HealthInsuranceFundsCacheProvider } from './HealthInsuranceFundsCacheProvider';
import { LocalizedHealthInsuranceFundsCacheProvider } from './LocalizedHealthInsuranceFundsCacheProvider';
import { MedicalTreatmentNomenclaturesCacheProvider } from './MedicalTreatmentNomenclaturesCacheProvider';
import { RecupelCacheProvider } from './RecupelCacheProvider';
import { RecupelDefaultCacheProvider } from './RecupelDefaultCacheProvider';
import { RizivHifsFilterDataProvider } from './RizivHifsFilterDataProvider';

interface IProps {
	children: any;
	language: LanguageOption;
}

export const SharedProviders = ({ children, language }: IProps) => {
	return (
		<RizivHifsFilterDataProvider>
			<HealthInsuranceFundsCacheProvider>
				{/* order is important here */}
				<LocalizedHealthInsuranceFundsCacheProvider>
					<MedicalTreatmentNomenclaturesCacheProvider>
						<RecupelDefaultCacheProvider>
							<RecupelCacheProvider language={language}>
								<BebatDefaultCacheProvider>
									<AnnotationsProvider>{children}</AnnotationsProvider>
								</BebatDefaultCacheProvider>
							</RecupelCacheProvider>
						</RecupelDefaultCacheProvider>
					</MedicalTreatmentNomenclaturesCacheProvider>
				</LocalizedHealthInsuranceFundsCacheProvider>
			</HealthInsuranceFundsCacheProvider>
		</RizivHifsFilterDataProvider>
	);
};
