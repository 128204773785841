import { ICommissionPayoutsQueryParams } from 'gen/ApiClient';

export const DefaultParams: ICommissionPayoutsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	userIds: [],
	payoutAfter: undefined,
	payoutBefore: undefined,
};
