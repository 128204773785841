import { CardProps, Typography } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { IEntPhysician } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { EntPhysicianDetailsContent } from './EntPhysicianDetailsContent';

interface IProps extends CardProps {
	item: IEntPhysician;
	reload: VoidFunction;
}

export const EntPhysicianDetailsCard = ({ item, reload, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<CardX {...rest}>
			<Typography
				component='div'
				style={{ fontSize: '16px', fontWeight: 500, marginBottom: 16 }}>
				{`${strings.detailsOfEntPhysician}: ${item.identifier}`}
			</Typography>
			<EntPhysicianDetailsContent
				item={item}
				reload={reload}
			/>
		</CardX>
	);
};
