import { RfiaExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

// TODO translate
export const createExportRecord = (strings: IStrings): Record<RfiaExportProp, string> => {
	return {
		Created: strings.created,
		AdvisoryPhysicianApprovalDate: strings.dateApprovalAdvisoryPhysician,
		CancelReason: strings.cancellationReason,
		EntPhysicianIdentifier: strings.entPhysician,
		EntPhysicianOfficeIdentifier: `${strings.entPhysician} ${strings.office}`,
		EquipmentDeliveryDate: strings.handoverDate,
		HasApprovedFeedbackAdvisoryPhysician: `${strings.approvedByAdvisoryPhysicianShort}?`,
		HasApprovedMedicalPrescription: `${strings.medicalPrescriptionReceived}?`,
		HasCosiQuestionnaire: `${strings.cosiQuestionnaire}?`,
		// HasHandoverCertificate: 'Heeft bijlage 12?',
		HasMissingDocuments: `${strings.missingDocuments}?`,
		HasThirdPartyPaymentScheme: `${strings.thirdPayerArrangement}?`,
		HasTrialStep: `${strings.trial}?`,
		HealthInsuranceFundCode: `${strings.code} ${strings.healthInsuranceFund}`,
		IsCancelled: `${strings.cancelled}?`,
		IsFailedTrial: `${strings.failedTrial}?`,
		IsHandedOver: `${strings.handedOver}`,
		IsHandedOverAndPaid: `${strings.handedOverAndPaid}?`,
		LastUpdated: strings.lastUpdated,
		LocationIdentifier: strings.location,
		MainMedicalTreatmentNomenclatureCode: strings.mainNomenclatureCode,
		PatientName: strings.patient,
		PrescriptionDate: strings.prescriptionDate,
		Reference: strings.reference,
		AudiologistIdentifier: strings.audiologist,
		FeedbackAdvisoryPhysicianApprovalDate: strings.approvalAdvisoryPhysician,
		FeedbackAdvisoryPhysicianSentDate: strings.sentToAdvisoryPhysicianShort,
		MedicalPrescriptionApprovalDate: strings.approvalDate,
		MedicalPrescriptionSentDate: strings.medicalPrescription,
		EntPhysicianId: `ID ${strings.entPhysician}`,
		Id: strings.id,
		PatientId: `ID ${strings.patient}`,
		InvoiceProcessStatus: `${strings.status} (${strings.invoice})`,
		PaymentStatus: strings.paymentStatus,
		Status: strings.status,
		DateNextAppointment: `Datum volgende afspraak`,
		HasNextAppointment: `Heeft volgende afspraak?`,
		HasSentMedicalPrescription: `Documenten opgestuurd?`,
		RosaMotive: `ROSA motief`,
	};
};
