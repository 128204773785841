import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { createHeader } from 'framework/table/createHeader';
import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { IHeader } from 'framework/table/IHeader';
import { updateArray } from 'framework/utils/array/updateArray';
import { LeftRightOrStereoType, LeftRightOrStereoTypes } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SpeechAudiogramNoahInfo } from '../SpeechAudiogramNoahInfo';
import { ISpeechModelWithSelection } from './ISpeechModelWithSelection';
import { SpeechChart } from '../SpeechChart';

export const createHeaders = (
	tests: ISpeechModelWithSelection[],
	setTests: (val: ISpeechModelWithSelection[]) => void,
	lrsRecord: Record<LeftRightOrStereoType, string>,
	strings: IStrings
): IHeader<ISpeechModelWithSelection>[] => {
	return [
		createSelectionHeader<ISpeechModelWithSelection>(tests, setTests),
		createHeader<ISpeechModelWithSelection>(strings.withOrWithoutWhat(strings.hearingAidsShort), t => (
			<Select
				style={{ width: '160px', textAlign: 'left' }}
				value={t.isAided ? 'true' : 'false'}
				onChange={e => setTests(updateArray(t, tests, { ...t, isAided: (e.target.value as string) === 'true' ? true : false }))}>
				<MenuItem
					key={'true'}
					value={'true'}>
					{strings.withWhat(strings.hearingAids)}
				</MenuItem>
				<MenuItem
					key={'false'}
					value={'false'}>
					{strings.withoutWhat(strings.hearingAids)}
				</MenuItem>
			</Select>
		)),
		createHeader<ISpeechModelWithSelection>(strings.withOrWithoutWhat(strings.headPhonesShort), t => (
			<Select
				style={{ width: '160px', textAlign: 'left' }}
				value={t.isHeadphones ? 'true' : 'false'}
				onChange={e => setTests(updateArray(t, tests, { ...t, isHeadphones: (e.target.value as string) === 'true' ? true : false }))}>
				<MenuItem
					key={'true'}
					value={'true'}>
					{strings.withWhat(strings.headPhones)}
				</MenuItem>
				<MenuItem
					key={'false'}
					value={'false'}>
					{strings.withoutWhat(strings.headPhones)}
				</MenuItem>
			</Select>
		)),
		createHeader<ISpeechModelWithSelection>(strings.orientation, t => (
			<Select
				style={{ width: '130px', textAlign: 'left' }}
				value={t.leftRightOrStereo}
				onChange={e => setTests(updateArray(t, tests, { ...t, leftRightOrStereo: e.target.value as any }))}>
				{LeftRightOrStereoTypes.map(x => (
					<MenuItem
						key={x}
						value={x}>
						{lrsRecord[x]}
					</MenuItem>
				))}
			</Select>
		)),
		createHeader<ISpeechModelWithSelection>(`Noah ${strings.data}`, t => (
			<div style={{ whiteSpace: 'pre-line' }}>
				{`${t.speechAudiogram.hearingInstrumentConditionTypeDisplay}\n${t.speechAudiogram.stimulusSignalOutputDisplay}`}
			</div>
		)),
		createEmptyHeader<ISpeechModelWithSelection>('chart', t => (
			<SpeechChart
				audiogram={t.speechAudiogram}
				lrs={t.leftRightOrStereo as LeftRightOrStereoType}
				withoutLabels
				minWidth={100}
				minHeight={40}
				hideAxes
			/>
		)),
		createEmptyHeader<ISpeechModelWithSelection>('info', t => (
			<SpeechAudiogramNoahInfo
				speechAudiogram={t.speechAudiogram}
				lrs={t.leftRightOrStereo as LeftRightOrStereoType}
			/>
		)),
	];
};
