import React from 'react';
import { DateFormatting, NumberFormatting, StringFormatting } from 'gen/ApiClient';

interface IContext {
	stringFormattingRecord: Record<StringFormatting, string>;
	numberFormattingRecord: Record<NumberFormatting, string>;
	dateFormattingRecord: Record<DateFormatting, string>;
}

export const KeysFormattingContext = React.createContext<IContext>(undefined as any);
