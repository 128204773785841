import { Card, CardProps, Divider } from '@material-ui/core';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { PageableTable } from 'framework/table/PageableTable';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IProductCatalogItem, ITypesAndCategoriesProductCatalogQueryParams, productCatalogQuery_queryUniquelyIdentifiable, TangibleType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { createSelectHeader } from 'shared/table/createSelectHeader';
import { FilterCategories } from './FilterCategories';
import { DefaultTypesAndCategoriesProductCatalogQueryParams } from './DefaultTypesAndCategoriesProductCatalogQueryParams';

interface IProps extends CardProps {
	onSelectPc: (pc: IProductCatalogItem) => void;
	type: TangibleType;
	isSubmitting?: boolean;
}

export const SelectUniquelyIdentifiableProductCatalogItemByType = ({ onSelectPc, type, isSubmitting, ...rest }: IProps) => {
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(
		productCatalogQuery_queryUniquelyIdentifiable,
		DefaultTypesAndCategoriesProductCatalogQueryParams
	);

	const onFilter = (params: ITypesAndCategoriesProductCatalogQueryParams) => {
		setParams(onResetToPage1(params));
	};

	const headers = [
		createSortableHeader<IProductCatalogItem>(strings.brandAndModel, 'manufacturerCommaModel', t => t.manufacturerCommaModel),
		createSortableHeader<IProductCatalogItem>(strings.category, 'category', t => t.category),
		createSortableHeader<IProductCatalogItem>(strings.subcategory, 'subcategory', t => t.subcategory),
		createSortableHeader<IProductCatalogItem>(strings.priceInVat, 'salesListPrice', t => formatCurrency(t.salesListPrice), 'right'),
		createSelectHeader<IProductCatalogItem>(
			t => onSelectPc(t),
			() => isSubmitting === true
		),
	];

	return (
		<Card {...rest}>
			<div className='df-row-ac jc-sb'>
				<FilterCategories
					params={params}
					onFilter={onFilter}
				/>
			</div>
			<Divider />
			<SearchbarWithParams
				params={params}
				setParams={setParams}
				placeholder={strings.searchVerb}
				variant='embedded'
			/>
			<Divider />
			{queryResult && queryResult.filteredCount > 0 ? (
				<PageableTable<IProductCatalogItem>
					padding={8}
					headers={headers}
					getKey={t => t.id}
					pageParams={params.pageParams}
					sortParams={params.sortParams}
					queryResult={queryResult}
					setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
				/>
			) : (
				<NoRecordsFound withPadding>{strings.noResultsCommaChangeFilterParams}</NoRecordsFound>
			)}
		</Card>
	);
};
