import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { FilterBar } from 'framework/filter/FilterBar';
import { MoneyRangeFilterComponent } from 'framework/filter/MoneyRangeFilterComponent';
import { isFiltered } from 'framework/utils/isFiltered';
import { ISupplierAccountsQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	params: ISupplierAccountsQueryParams;
	onFilter: (params: ISupplierAccountsQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof ISupplierAccountsQueryParams)[] = ['hasOutstandingBalance'];

export const SupplierAccountsFilter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<MoneyRangeFilterComponent
				label={strings.saldoBetween}
				selected={{ min: params.outstandingBalanceMoreThan, max: params.outstandingBalanceLessThan }}
				setSelected={val => onFilter({ ...params, outstandingBalanceLessThan: val.max, outstandingBalanceMoreThan: val.min })}
				icon={<HelpOutlineIcon />}
			/>
		</FilterBar>
	);
};
