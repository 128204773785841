import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { ThermalPrinterApplicationContext } from 'app/externalApps/thermalPrinter/ThermalPrinterApplicationContext';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import React, { useContext } from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';

export const ThermalPrinterContent = ({ reloadTrigger }: IReloadTriggerProps) => {
	const { isInstalled, isInitialized, installedBuild, latestBuild, hasLatest, check, download, isDownloading } = useContext(ThermalPrinterApplicationContext);

	useLazyEffect(() => {
		check();
	}, [reloadTrigger]);

	return (
		<div className='df-col gap-8'>
			<TextLinkButton
				startIcon={<GetAppOutlinedIcon />}
				disabled={isDownloading}
				onClick={download}
				color='primary'>
				{`Download`}
			</TextLinkButton>
			<CaptionVerticalLabelledPropertyX label='Latest version from server'>
				{latestBuild ? latestBuild.toString() : 'Not loaded yet'}
			</CaptionVerticalLabelledPropertyX>
			{isInitialized && isInstalled === false && (
				<VariantLabelledPropertyX variant='error'>{`Image scanner middleware not installed`}</VariantLabelledPropertyX>
			)}
			{isInitialized && isInstalled && (
				<>
					<CaptionVerticalLabelledPropertyX label='Installed version'>{installedBuild.toString()}</CaptionVerticalLabelledPropertyX>
					<VariantLabelledPropertyX variant={hasLatest ? 'success' : 'warning'}>
						{hasLatest ? 'up-to-date' : 'not running latest version'}
					</VariantLabelledPropertyX>
				</>
			)}
			{isInitialized === false && <span>{`Fetching data`}</span>}
		</div>
	);
};
