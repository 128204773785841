import { IHeader, TableCellAlign } from './IHeader';
import { createSortableHeader } from './createSortableHeader';

export function createSortableHeaderWithKey<T, TLimit extends string>(
	record: Record<TLimit, string>,
	key: keyof T & TLimit,
	evaluate: (inst: T, index: number, expanded?: boolean, toggleExpand?: VoidFunction) => string | JSX.Element | undefined,
	align?: TableCellAlign,
	cellStyle?: React.CSSProperties
): IHeader<T> {
	return createSortableHeader<T>(record[key], key, evaluate, align, cellStyle);
}
