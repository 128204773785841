import {
	addDays,
	addMonths,
	addQuarters,
	addWeeks,
	addYears,
	endOfDay,
	endOfMonth,
	endOfQuarter,
	endOfYear,
	startOfDay,
	startOfMonth,
	startOfQuarter,
	startOfYear,
} from 'date-fns';
import { mapToIso } from '../../utils/date/mapToIso';
import { nlBELocaleEndOfWeek } from '../../utils/date/nlBELocaleEndOfWeek';
import { nlBELocaleStartOfWeek } from '../../utils/date/nlBELocaleStartOfWeek';
import { BrowsableDateRangeType } from './BrowsableDateRangeType';
import { IDateRange } from './IDateRange';

export const calculateNextRange = (range: IDateRange, type: BrowsableDateRangeType): { after: Date; before: Date } => {
	return record[type](new Date(range.after!));
};

const record: Record<BrowsableDateRangeType, (val: Date) => { after: Date; before: Date }> = {
	day: val => ({ after: mapToIso(startOfDay(addDays(val, 1)))!, before: mapToIso(endOfDay(addDays(val, 1)))! }),
	week: val => ({ after: mapToIso(nlBELocaleStartOfWeek(addWeeks(val, 1)))!, before: mapToIso(nlBELocaleEndOfWeek(addWeeks(val, 1)))! }),
	month: val => ({ after: mapToIso(startOfMonth(addMonths(val, 1)))!, before: mapToIso(endOfMonth(addMonths(val, 1)))! }),
	quarter: val => ({ after: mapToIso(startOfQuarter(addQuarters(val, 1)))!, before: mapToIso(endOfQuarter(addQuarters(val, 1)))! }),
	year: val => ({ after: mapToIso(startOfYear(addYears(val, 1)))!, before: mapToIso(endOfYear(addYears(val, 1)))! }),
};
