import { Button, Checkbox, Collapse, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { TendersSettingsRoute, calcTenderDetailRoute } from 'app/main/financial/routes';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { TableCardX } from 'framework/components/cards/TableCardX';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { ITendersFilterParams, TenderExportProp, TenderExportProps, tendersQuery_export } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TextLinkExportButtonMenuAndDialog } from 'shared/export/TextLinkExportButtonMenuAndDialog';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { FinancialsPageBreadcrumbTitle } from '../../FinancialsPageBreadcrumbTitle';
import { TenderForm } from '../forms/TenderForm';
import { TendersListFilterCard } from './TendersListFilterCard';
import { TendersListOrderBySelect } from './TendersListOrderBySelect';
import { TendersListQueryContext } from './TendersListQueryContext';
import { TendersListStats } from './TendersListStats';
import { TendersListViewContext } from './TendersListViewContext';
import { TendersTable } from './TendersTable';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { PageStatsButtonX } from 'framework/components/layout/PageStatsButtonX';

export const TendersListPage = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(TendersListQueryContext);
	const { exportRecord } = React.useContext(TendersListViewContext);
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();
	const [expandedId, setExpandedId] = useAccordionState<string>();
	const [expandAll, toggleExpandAll] = useStateToggleBoolean(false);
	const [showStats, toggleShowStats] = useStateToggleBoolean(true);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<TenderForm
				confirm={id => {
					if (id) {
						push(calcTenderDetailRoute(id));
					}
					confirm();
				}}
				cancel={cancel}
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='Tenders'
			maxWidth={1280}
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<FinancialsPageBreadcrumbTitle />
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={strings.tenders} />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageStatsButtonX
								showStats={showStats}
								toggleShowStats={toggleShowStats}
							/>
							<PageSettingsButtonX route={TendersSettingsRoute} />
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
					<Collapse in={showStats}>
						<TendersListStats
							metadata={queryResult.metadata}
							params={params}
							setParams={setParams}
							style={{ paddingTop: 16 }}
						/>
					</Collapse>
				</div>
			}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<div className='df-col gap-8'>
						<Button
							variant='contained'
							color='primary'
							startIcon={<AddIcon />}
							onClick={onNew}
							style={{ borderRadius: 12 }}>
							{strings.new}
						</Button>
					</div>
					<TendersListFilterCard
						params={params}
						setParams={setParams}
						metadata={queryResult.metadata}
					/>
					<div className='fg1'></div>
					<Divider />
					<TextLinkExportButtonMenuAndDialog<ITendersFilterParams, TenderExportProp>
						params={params}
						filteredCount={queryResult.filteredCount}
						unfilteredCount={queryResult.unfilteredCount}
						allProps={TenderExportProps}
						downloadF={tendersQuery_export}
						fileName={strings.tenders}
						record={exportRecord}
						view='tenders'
					/>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				<SearchbarX
					placeholder={strings.searchVerb}
					value={params.searchString}
					onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
				/>
				<TableCardX
					header={
						<div className='df-col-ae'>
							<Button
								size='small'
								endIcon={
									<Checkbox
										checked={expandAll}
										style={{ padding: 0 }}
									/>
								}
								style={{ marginRight: -2 }}
								onClick={toggleExpandAll}>
								{strings.showAllContents}
							</Button>
							<TendersListOrderBySelect
								sortParams={params.sortParams}
								onFilter={sp => setParams(onResetToPage1({ ...params, sortParams: sp }))}
							/>
						</div>
					}
					table={
						<TendersTable
							queryResult={queryResult}
							sortParams={params.sortParams}
							onFilter={sp => setParams(onResetToPage1({ ...params, sortParams: sp }))}
							expandAll={expandAll}
							expandedId={expandedId}
							toggle={setExpandedId}
						/>
					}
					footer={
						<QueryResultPaginationX
							queryResult={queryResult}
							setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
							flexEnd
						/>
					}
				/>
			</div>
		</RestrictedPageX>
	);
};
