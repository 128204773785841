import { PreloadCacheProvider as FrameworkCacheProvider } from 'framework/context/PreloadCacheProvider';
import { articlePropertyAutocompleteQuery_all, articlePropertyAutocompleteQuery_concurrencyToken, IArticlePropertyAutocomplete } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { PreloadContext } from 'shared/context/PreloadContext';
import { ArticlePropertiesAutocompleteCacheContext } from './ArticlePropertiesAutocompleteCacheContext';

export const ArticlePropertiesAutocompleteCacheProvider = ({ children }: any) => {
	const { articlePropertiesAutocomplete } = useContext(PreloadContext);

	return (
		<FrameworkCacheProvider<IArticlePropertyAutocomplete[]>
			concurrencyF={articlePropertyAutocompleteQuery_concurrencyToken}
			loadF={articlePropertyAutocompleteQuery_all}
			context={ArticlePropertiesAutocompleteCacheContext}
			initialData={articlePropertiesAutocomplete}>
			{children}
		</FrameworkCacheProvider>
	);
};
