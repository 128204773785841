import { IconButton } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import { SpecialZoomLevel } from '@react-pdf-viewer/core';
import { RenderZoomProps, ZoomPlugin } from '@react-pdf-viewer/zoom';
import React from 'react';

interface IProps {
	plugin: ZoomPlugin;
}

export const ZoomComponent = ({ plugin }: IProps) => {
	const { Zoom } = plugin;

	return (
		<Zoom>
			{(props: RenderZoomProps) => (
				<div className='df-row-ac'>
					<IconButton
						color='inherit'
						onClick={() => props.onZoom(props.scale - 0.25)}>
						<ZoomOutIcon />
					</IconButton>
					<IconButton
						color='inherit'
						onClick={() => props.onZoom(props.scale + 0.25)}>
						<ZoomInIcon />
					</IconButton>
					<IconButton
						color='inherit'
						onClick={() => props.onZoom(SpecialZoomLevel.PageWidth)}>
						<ZoomOutMapIcon />
					</IconButton>
				</div>
			)}
		</Zoom>
	);
};
