import { InputAdornment, TextField, useTheme } from '@material-ui/core';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { IObjectWithIdAndIdentifier } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from './dialogs/useDialogsContext';
import { SelectPatientAndLoadSearchForm } from './patientSearch/SelectPatientAndLoadSearchForm';

interface IProps {
	patientId: string | undefined;
	patientIdentifier: string | undefined;
	onPatientSelected: (val: IObjectWithIdAndIdentifier<string>) => void;
	style?: React.CSSProperties;
	disabled?: boolean;
	hideLabel?: boolean;
	isOptional?: boolean;
}

export const TextFieldSelectPatient = ({ patientId, patientIdentifier, onPatientSelected, disabled, hideLabel, style, isOptional }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { open, confirm, cancel } = useDialogsContext();
	const isSelected = useMemo(() => isNullOrUndefined(patientId) === false, [patientId]);
	const hasError = useMemo(() => disabled !== true && isSelected === false && isOptional !== true, [disabled, isSelected, isOptional]);

	const onSelectPatient = () => {
		open(
			<SelectPatientAndLoadSearchForm
				open
				cancel={cancel}
				confirm={search => {
					confirm();
					onPatientSelected(search);
				}}
			/>
		);
	};

	return (
		<TextField
			variant='outlined'
			size='small'
			label={hideLabel === true ? undefined : strings.patient}
			value={patientIdentifier ?? ''}
			error={hasError}
			onClick={disabled === true ? undefined : onSelectPatient}
			style={style}
			disabled={disabled}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						<PersonOutlineOutlinedIcon
							fontSize='small'
							style={{ color: hasError ? theme.palette.error.main : theme.palette.primary.main }}
						/>
					</InputAdornment>
				),
			}}
		/>
	);
};
