import { Card } from '@material-ui/core';
import { NoahDeviceDialog } from 'app/main/inventoryManagement/noahDevices/single/detail/NoahDeviceDialog';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DefaultCardContent } from 'framework/components/DefaultCardContent';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { IInventoryItemDetail } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

interface IProps {
	detail: IInventoryItemDetail;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const LinkedNoahDeviceCard = ({ detail, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm } = useDialogsContext(reload);
	const iiMap = useMemo(() => detail.noahDeviceWithLastLinkedProductCatalogItem?.data, [detail]);

	const onView = () => {
		open(
			<NoahDeviceDialog
				open
				id={detail.data.serialNumber!}
				close={confirm}
				hideNavigateToInventoryItem
			/>
		);
	};

	return (
		<Card
			style={style}
			className='df-col'>
			<DefaultCardContent>
				<CardTitleTypography title={strings.NoahData} />
				{detail.hasLinkedNoahDevice === true && iiMap !== undefined && (
					<>
						<LabelledProperty
							label={strings.readOutAs}
							property={iiMap.hasDifferentReadOuts ? undefined : iiMap.lastReadOut?.uniqueField}
							undefinedText=''
						/>
						{iiMap.hasDifferentReadOuts && iiMap.readOutsUniqueField.map(t => <div key={t}>{t}</div>)}
						<LabelledProperty
							label={strings.serialNumber}
							property={detail.data.serialNumber}
							navigate={onView}
						/>
					</>
				)}
				{detail.hasLinkedNoahDevice === false && <NoRecordsFound>{strings.noNoahDataAvailable}</NoRecordsFound>}
			</DefaultCardContent>
		</Card>
	);
};
