import { Card } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DefaultCardContent } from 'framework/components/DefaultCardContent';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { IInventoryItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SetTailorMadeForPatientForm } from '../forms/SetTailorMadeForPatientForm';

interface IProps {
	item: IInventoryItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const TailorMadeForPatientCard = ({ item, reload, style }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();
	const strings = useLocalization();

	const onSelectPatient = () => {
		open(
			<SetTailorMadeForPatientForm
				open
				inventoryItemId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Card
			style={style}
			className='df-col'>
			<DefaultCardContent>
				<CardTitleTypography
					title={strings.tailorMadeFor}
					style={{ paddingBottom: 0 }}
				/>
				{item.hasTailorMadeForPatient && (
					<LabelledProperty
						label={strings.patient}
						property={item.tailorMadeForPatientIdentifier}
						navigate={() => push(calcPatientDetailRoute(item.tailorMadeForPatientId ?? ''))}
					/>
				)}
				{item.hasTailorMadeForPatient === false && (
					<ErrorLabelledProperty
						error={strings.noPatientSelected}
						edit={onSelectPatient}
						editIcon={<LinkIcon />}
					/>
				)}
			</DefaultCardContent>
		</Card>
	);
};
