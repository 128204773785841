import React from 'react';

interface IProps {
	children: React.ReactNode;
	style?: React.CSSProperties;
}

export const VerticalStepperX = ({ children, style }: IProps) => {
	return (
		<div
			className='df-col gap-16'
			style={style}>
			{children}
		</div>
	);
};
