import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { WizardButton } from './WizardButton';

interface IProps {
	onClick: VoidFunction;
	count: number;
}

export const TenderWizardButton = ({ count, onClick }: IProps) => {
	const strings = useLocalization();

	return (
		<WizardButton
			badge={count}
			disabled={count === 0}
			startIcon={<ReceiptOutlinedIcon />}
			onClick={onClick}>
			{strings.selectFromWhat(strings.tender)}
		</WizardButton>
	);
};
