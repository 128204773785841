import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IPatientsQueryParams, IPatientSummary, IQueryResult, PatientSearchMode, patientsQuery_concurrencyToken, patientsQuery_query } from 'gen/ApiClient';
import { patientsQueryParamsLsKey } from '../../../localStorageKeys';
import { PatientsQueryContext } from './PatientsQueryContext';

const DefaultParams: IPatientsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	gender: undefined,
	statuses: [],
	audiologistIds: [],
	entPhysicianIds: [],
	generalPractitionerIds: [],
	locationIds: [],
	origins: [],
	canRenewAfter: undefined,
	canRenewBefore: undefined,
	createdAfter: undefined,
	createdBefore: undefined,
	lastHandoverDateAfter: undefined,
	lastHandoverDateBefore: undefined,
	keyStatus: undefined,
	searchMode: 'AllFields' as PatientSearchMode,
	lastUpdatedBefore: undefined,
	lastUpdatedAfter: undefined,
	monthsOfBirth: [],
	labels: [],
	zips: [],
};

export const PatientsQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IPatientSummary>, IPatientsQueryParams>
			defaultParams={DefaultParams}
			queryF={patientsQuery_query}
			concurrencyF={patientsQuery_concurrencyToken}
			localStorageKey={patientsQueryParamsLsKey}
			context={PatientsQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
