import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	IPurchase,
	IPurchasesQueryMetadata,
	IPurchasesQueryParams,
	IQueryResultWithMetadata,
	purchasesQuery_concurrencyToken,
	purchasesQuery_query,
} from 'gen/ApiClient';
import React from 'react';
import { purchasesQueryParamsLsKey } from '../../../../localStorageKeys';
import { PurchasesQueryContext } from './PurchasesQueryContext';

const DefaultParams: IPurchasesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	validUntilAfter: undefined,
	validUntilBefore: undefined,
	paymentStatuses: [],
	types: [],
	priceExVatLessThan: undefined,
	priceExVatMoreThan: undefined,
	supplierIds: [],
	creditedStatuses: [],
	purchaseReferenceDateBefore: undefined,
	purchaseReferenceDateAfter: undefined,
	prefilter: 'All',
	labels: [],
};

export const PurchasesQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResultWithMetadata<IPurchase, IPurchasesQueryMetadata>, IPurchasesQueryParams>
			defaultParams={DefaultParams}
			queryF={purchasesQuery_query}
			concurrencyF={purchasesQuery_concurrencyToken}
			localStorageKey={purchasesQueryParamsLsKey}
			context={PurchasesQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
