import { Card, CardContent, CardProps } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { isLast } from 'framework/utils/array/isLast';
import { IRepair } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { AddLineToRepairForm } from '../forms/AddLineToRepairForm';
import { RepairLineListItem } from './RepairLineListItem';

interface IProps extends CardProps, IReloadTriggerProps {
	item: IRepair;
	reload: VoidFunction;
}

export const RepairDetailContentsCard = ({ item, reload, reloadTrigger, ...rest }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onAddLines = () => {
		open(
			<AddLineToRepairForm
				repair={item}
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};
	// const onDeleteLine = (line: IOrderLineV2) => {
	// 	open(
	// 		<AlertDialog
	// 			open
	// 			title={strings.deleteLine}
	// 			content={strings.doYouWantToDeleteThisLineFromThisOrderQuestion}
	// 			acceptText={strings.yesCommaDelete}
	// 			rejectText={strings.noCommaGoBack}
	// 			reject={cancel}
	// 			acceptF={() => ordersCommand_deleteLine({ orderId: item.id, orderLineId: line.id })}
	// 			accept={confirm}
	// 		/>
	// 	);
	// };

	// if (lines === undefined) {
	// 	return <></>;
	// }

	return (
		<Card {...rest}>
			<CardContent>
				<CardTitleTypography
					title={strings.contents}
					style={{ marginBottom: 8 }}
				/>
				<TextLinkButton
					startIcon={<AddCircleOutlineIcon />}
					onClick={onAddLines}
					color='primary'
					disabled={item.canAddLines === false}>
					{strings.addLines}
				</TextLinkButton>
				<DividerWithMargin />
				{item.lines.map(t => (
					<RepairLineListItem
						repair={item}
						line={t}
						key={t.id}
						withoutBorder={isLast(t, item.lines)}
					/>
				))}
				{item.lines.length === 0 && <em>{strings.noLinesDefinedYet}</em>}
			</CardContent>
		</Card>
	);
};
