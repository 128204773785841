import { Dialog, DialogContent, DialogProps, Typography } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	customerAccountLinesQuery_single,
	ICustomerAccountLine,
	IRefundForSaleModel,
	paymentsForSalesCommand_newRefundForCustomerAccountLine,
	paymentsQuery_nextReferenceForSale,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useCallback, useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { FormSelectOrCreatePaymentMethodField } from '../../../../settings/paymentMethods/forms/FormSelectOrCreatePaymentMethodField';

const createSchema = (strings: IStrings, max: number, min?: number) => {
	return yup
		.object<IRefundForSaleModel>({
			referenceDate: yup.date().required(),
			isAutoSelectReference: yup.boolean().defined(),
			methodId: yup.string().required(),
			reference: yup.string(),
			value: yup.number().required(),
		})
		.defined();
};

const EmptyValues: IRefundForSaleModel = {
	referenceDate: undefined as any,
	isAutoSelectReference: true,
	methodId: '',
	reference: '',
	value: 0,
};

const stepsRecord: Record<number, (keyof IRefundForSaleModel)[]> = {
	0: ['isAutoSelectReference', 'reference', 'referenceDate'],
	1: ['methodId', 'value'],
};

interface IProps extends DialogProps {
	customerAccountLineId: string;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const RefundForSaleForm = ({ customerAccountLineId, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [line] = useApiEffect(customerAccountLinesQuery_single, customerAccountLineId);
	const [create, isSubmitting] = useFormSubmit(paymentsForSalesCommand_newRefundForCustomerAccountLine);
	const [step, setStep] = useState<number>(0);
	const now = useNow();

	const handleSubmit = async (values: IRefundForSaleModel, helpers: FormikHelpers<IRefundForSaleModel>) => {
		const r = await create(customerAccountLineId, values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.registeredWhat(strings.payment));
			confirm(r.result.objectId);
		}
	};

	if (line === undefined || now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRefundForSaleModel>
			validateOnMount
			initialValues={{
				...EmptyValues,
				value: line.amountPaid,
				referenceDate: now,
			}}
			validationSchema={createSchema(strings, line.amountPaid)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					line={line}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	line: ICustomerAccountLine;
}

const InnerDialog = ({ step, setStep, cancel, isSubmitting, line, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IRefundForSaleModel>();
	const strings = useLocalization();
	const cb = useCallback(() => paymentsQuery_nextReferenceForSale(line.salesId), [line.salesId]);
	useNextReferenceInterceptor<IRefundForSaleModel>(cb, props);

	return (
		<Dialog {...rest}>
			<DialogTitleWithFormStepper
				title={`${strings.registerWhat(strings.refund)}: ${strings.sale} ${line.salesReference}`}
				step={step}
				labels={[`${strings.date} & ${strings.reference}`, `${strings.method} & ${strings.amountAsValue}`]}>
				<div className='df-col'>
					<Typography
						variant='caption'
						style={{ marginBottom: 8 }}>{`${strings.customer}: ${line.customerAccountIdentifier}`}</Typography>
					<Typography
						variant='caption'
						style={{ marginBottom: 8 }}>{`${strings.reference}: ${line.salesReference}`}</Typography>
				</div>
			</DialogTitleWithFormStepper>
			<DialogContent
				className='df-col'
				dividers>
				{step === 0 && (
					<>
						<FormDatePicker<IRefundForSaleModel>
							name='referenceDate'
							label={strings.referenceDate}
							disableFuture
							required
						/>
						<FormSingleCheckboxField<IRefundForSaleModel>
							name='isAutoSelectReference'
							label={strings.autoSelectPaymentReferenceQuestion}
						/>
						<FormTextField<IRefundForSaleModel>
							name='reference'
							label={strings.paymentReference}
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectOrCreatePaymentMethodField<IRefundForSaleModel>
							name='methodId'
							label={strings.paymentMethod}
							mode='sale'
						/>
						<FormMoneyField<IRefundForSaleModel>
							name='value'
							label={strings.amountAsValue}
						/>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings, line.amountPaid)}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
