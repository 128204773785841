import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { IntangibleType, InterchangeableTangibleType, ProductCatalogItemType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';

type PrimaryTangibleType = 'HearingInstrument' | 'RemoteControl' | 'NoneOfAbove';

interface IProps extends DialogProps {
	cancel: VoidFunction;
	confirm: (type: ProductCatalogItemType) => void;
	disableIntangible: boolean;
}

export const ProductCatalogItemModeForm = ({ cancel, confirm, disableIntangible, ...rest }: IProps) => {
	const strings = useLocalization();
	const [isTangible, setIsTangible] = useState<boolean>(true);
	const [step, setStep] = useState<number>(0);
	const [pcType, setType] = useState<ProductCatalogItemType | PrimaryTangibleType>('Charger');

	const onNext = () => {
		if (step === 0) {
			setType(isTangible ? 'HearingInstrument' : 'Service');
			setStep(1);
		} else if (step === 1 && pcType === 'NoneOfAbove') {
			setType('HearingProtection');
			setStep(2);
		} else if (pcType !== 'NoneOfAbove') {
			confirm(pcType);
		}
	};

	return (
		<Dialog
			{...rest}
			PaperProps={{ style: { minWidth: 500 } }}>
			<DialogTitle>{strings.newItemChoice}</DialogTitle>
			<DialogContent dividers>
				{step === 0 && (
					<ExtendedRadioGroup<boolean>
						selected={isTangible}
						setSelected={setIsTangible}
						options={[
							{ value: true, label: strings.tangible, caption: strings.tangibleCaption },
							{ value: false, label: strings.notTangible, caption: strings.notTangibleCaption, disabled: disableIntangible },
						]}
					/>
				)}
				{step === 1 && isTangible === false && (
					<ExtendedRadioGroup<IntangibleType>
						selected={pcType as any}
						setSelected={setType}
						options={[
							{ value: 'Service', label: strings.service, caption: strings.serviceExplanation },
							{ value: 'Insurance', label: strings.insurance, caption: `${strings.insurance} (${strings.withOrWithoutTerm})` },
							{ value: 'ProlongedWarranty', label: strings.prolongedWarranty, caption: `${strings.prolongedWarranty} (${strings.withOrWithoutTerm})` },
						]}
					/>
				)}
				{step === 1 && isTangible === true && (
					<ExtendedRadioGroup<PrimaryTangibleType>
						selected={pcType as any}
						setSelected={setType}
						options={[
							{ value: 'HearingInstrument', label: strings.hearingAid, caption: strings.aHearingAidCreatefromLimitativeListNoahOrManual },
							{ value: 'RemoteControl', label: strings.remoteDevice, caption: strings.aRemoteDevice },
							{ value: 'NoneOfAbove', label: strings.noneOfAbove, caption: strings.feHearingProtectionChargerEarmouldEtc },
						]}
					/>
				)}
				{step === 2 && (
					<ExtendedRadioGroup<InterchangeableTangibleType>
						selected={pcType as any}
						setSelected={setType}
						options={[
							{ value: 'HearingProtection', label: strings.hearingProtection, caption: strings.hearingProtectionNewArticleCaption },
							{ value: 'Earmould', label: strings.earmould, caption: strings.earMouldNewArticleCaption },
							{ value: 'Dome', label: strings.dome, caption: strings.domeNewArticleCaption },
							{ value: 'Receiver', label: strings.receiver, caption: strings.receiverNewArticleCaption },
							{ value: 'Charger', label: strings.charger, caption: strings.chargerNewArticleCaption },
							{ value: 'Other', label: strings.other, caption: strings.otherNewArticleCaption },
						]}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button
					disabled={step === 0}
					onClick={() => setStep(step - 1)}
					color='primary'>
					{strings.previous}
				</Button>
				<Button
					onClick={() => onNext()}
					color='primary'>
					{strings.next}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
