import { DocumentListItemOrEmpty } from 'documents/DocumentListItemOrEmpty';
import { IDocumentSummary, IRequestForInsuranceAllowance } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { HandoverCertificateDialog } from './HandoverCertificateDialog';

interface IProps {
	rfia: IRequestForInsuranceAllowance;
	isHifExist: boolean;
	document: IDocumentSummary;
	reload: VoidFunction;
}

export const HandoverCertificateListItem = ({ rfia, document, reload, isHifExist }: IProps) => {
	const { documentPredefinedTypeRecord } = useContext(RecordContext);
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onNew = () => {
		open(
			<HandoverCertificateDialog
				rfiaId={rfia.id}
				cancel={cancel}
				confirm={confirm}
				isHifExist={isHifExist}
			/>
		);
	};

	return (
		<DocumentListItemOrEmpty
			onNew={onNew}
			reload={reload}
			document={document}
			title={documentPredefinedTypeRecord['RfiaHandoverCertificate']}
		/>
	);
};
