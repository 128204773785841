import { FilledInput, IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useField } from 'formik';
import React, { useState } from 'react';
import { FormFilledControl } from './common/FormFilledControl';
import { IFormProps } from './common/IFormProps';
import { useFilledInputStyles } from './common/useFilledInputStyles';
import { nameOf } from '../utils/nameOf';

export const FormPasswordField = <TModel extends unknown>({ ...props }: IFormProps<TModel>) => {
	const [field, meta] = useField<string>(nameOf(props.name));
	const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
	const classes = useFilledInputStyles();

	return (
		<FormFilledControl
			meta={meta}
			{...props}>
			<FilledInput
				type={isShowPassword ? 'text' : 'password'}
				className={classes.filledInput}
				{...field}
				endAdornment={
					<InputAdornment position='end'>
						<IconButton
							onClick={() => setIsShowPassword(!isShowPassword)}
							edge='end'>
							{isShowPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormFilledControl>
	);
};
