import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	children: string | null | undefined;
	notApplicableText?: string;
	style?: React.CSSProperties;
}

export const StringValueOrNotApplicable = ({ children, notApplicableText, style }: IProps) => {
	const strings = useLocalization();

	if (isNotNullNorUndefined(children)) {
		return <span style={style}>{children}</span>;
	} else {
		return <em>{notApplicableText ? notApplicableText : strings.notApplicableAbbreviation}</em>;
	}
};
