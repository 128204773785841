import { useTheme } from '@material-ui/core';
import BlurCircularOutlinedIcon from '@material-ui/icons/BlurCircularOutlined';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IMedicalTreatmentFeeDto, LanguageOption } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IMedicalTreatmentFeeDto;
	onSelect: VoidFunction;
	language: LanguageOption;
}

export const MedicalTreatmentNomenclatureListItem = ({ item, onSelect, language }: IProps) => {
	const strings = useLocalization();
	const [hovered, enter, leave] = useStateBoolean(false);
	const theme = useTheme();

	return (
		<div
			className='df-row gap-8 w100'
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={onSelect}
			style={{ cursor: 'pointer' }}>
			<div className='df-col'>
				<BlurCircularOutlinedIcon
					fontSize='small'
					style={{ color: hovered ? theme.palette.success.main : theme.palette.text.secondary }}
				/>
			</div>
			<div className='df-col fg1'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.code}</span>
				<span style={{ fontSize: MinorFontSize }}>{language === 'nl' ? item.nomenDescriptionNl : item.nomenDescriptionFr}</span>
				<span style={{ fontSize: MinorFontSize }}>{`${strings.contribution} ${formatCurrency(item.contribution)}`}</span>
			</div>
		</div>
	);
};
