import { Divider } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import { SupplierDialog } from 'app/main/contacts/suppliers/SupplierDialog';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { LinkUserForm } from 'app/main/settings/users/forms/LinkUserForm';
import { useFindUser } from 'app/main/settings/users/useFindUser';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { formatDate } from 'framework/utils/date/formatDate';
import {
	IRepair,
	repairsCommand_cancel,
	repairsCommand_patchExpectedHandoverDate,
	repairsCommand_patchExpectedRedeliveryDate,
	repairsCommand_patchExternalReference,
	repairsCommand_patchHandlingUser,
	repairsCommand_registerHandover,
	repairsCommand_unlinkFromPatient,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { LinkRepairWithPatientForm } from '../forms/LinkRepairWithPatientForm';
import { RegisterRepairDeliveryRequestForm } from '../forms/RegisterRepairDeliveryRequestForm';
import { RepairProcessStepsForm } from '../forms/RepairProcessStepsForm';
import { SendToSupplierForm } from '../forms/SendToSupplierForm';
import { RepairDetailProcessComponent } from './RepairDetailProcessComponent';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
import { useNow } from 'shared/utils/useNow';
import { StatusIcon } from 'framework/components/icons/StatusIcon';

interface IProps {
	item: IRepair;
	reload: VoidFunction;
}

export const RepairDetailPageSidebar = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const user = useFindUser(item.handlingUserId);
	const { push } = useHistory();
	const now = useNow();

	const onCancel = () => {
		open(
			<AlertDialog
				open
				title={strings.cancelWhat(strings.repair)}
				content={strings.repairCancelContent}
				acceptText={strings.yesWhatExclamation(strings.cancelWhat(strings.repair))}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => repairsCommand_cancel({ repairId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onPatchExternalReference = () => {
		open(
			<StringModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.changeSomething(strings.externalReference)}
				label={strings.externalReference}
				initialValue={item.externalReference}
				cancel={cancel}
				submitText={strings.update}
				submitFunction={value => repairsCommand_patchExternalReference({ repairId: item.id, externalReference: value })}
			/>
		);
	};

	const onPatchExpectedRedeliveryDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				formTitle={strings.changeWhat(strings.expectedDeliveryDate)}
				label={strings.deliveryDate}
				initialValue={item.expectedRedeliveryDate}
				submitFunction={date => repairsCommand_patchExpectedRedeliveryDate({ repairId: item.id, expectedRedeliveryDate: date })}
			/>
		);
	};

	const onPatchExpectedHandoverDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				formTitle={strings.changeWhat(strings.expectedHandoverDate)}
				label={strings.handoverDate}
				initialValue={item.expectedHandoverDate}
				submitFunction={date => repairsCommand_patchExpectedHandoverDate({ repairId: item.id, expectedHandoverDate: date })}
			/>
		);
	};

	const onPatchHandlingUser = () => {
		open(
			<LinkUserForm
				open
				initialValue={item.handlingUserId}
				confirm={confirm}
				cancel={cancel}
				submitFunction={userId => repairsCommand_patchHandlingUser({ repairId: item.id, handlingUserId: userId })}
			/>
		);
	};

	const onUnlinkFromPatient = () => {
		open(
			<AlertDialog
				open
				title={strings.removeLinkWithPatient}
				content={strings.removeRepairLinkWithPatientContent}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => repairsCommand_unlinkFromPatient({ repairId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onLinkWithPatient = () => {
		open(
			<LinkRepairWithPatientForm
				open
				repairId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onSendToSupplier = () => {
		open(
			<SendToSupplierForm
				repair={item}
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onRegisterDelivery = () => {
		open(
			<RegisterRepairDeliveryRequestForm
				repair={item}
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onViewSupplier = () => {
		open(
			<SupplierDialog
				open
				close={cancel}
				id={item.supplierId}
			/>
		);
	};

	const onPatchProcessSteps = () => {
		open(
			<RepairProcessStepsForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	const onHandoverToPatient = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				formTitle={strings.handover}
				label={strings.date}
				initialValue={now}
				submitFunction={date => repairsCommand_registerHandover({ repairId: item.id, handoverDate: date })}
			/>
		);
	};

	return (
		<div
			className='df-col h100'
			style={{
				padding: 16,
				paddingRight: 8,
				// width: 250,
			}}>
			<div className='df-col gap-6'>
				<CaptionVerticalLabelledPropertyX label={strings.reference}>{item.reference}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.externalReference}
					edit={onPatchExternalReference}>
					{item.externalReference ? item.externalReference : <em>{strings.notApplicableAbbreviation}</em>}
				</CaptionVerticalLabelledPropertyX>
				{item.isForPatient && (
					<CaptionVerticalLabelledPropertyX
						label={strings.patient}
						link={() => push(calcPatientDetailRoute(item.forPatientId!))}>
						{item.forPatientIdentifier}
					</CaptionVerticalLabelledPropertyX>
				)}
				<CaptionVerticalLabelledPropertyX
					label={strings.expectedDeliveryDate}
					edit={onPatchExpectedRedeliveryDate}>
					<div className='df-row-ac gap-4'>
						{item.isRedeliveryOverdue && (
							<StatusIcon
								status='warning'
								fontSize='small'
							/>
						)}
						<span>{formatDate(item.expectedRedeliveryDate)}</span>
					</div>
				</CaptionVerticalLabelledPropertyX>
				{item.handoverToPatientStepIncluded === true && (
					<CaptionVerticalLabelledPropertyX
						label={strings.expectedHandoverDate}
						edit={onPatchExpectedHandoverDate}>
						<div className='df-row-ac gap-4'>
							{item.isHandOverOverdue && (
								<StatusIcon
									status='warning'
									fontSize='small'
								/>
							)}
							<span>{item.expectedHandoverDate ? formatDate(item.expectedHandoverDate) : <em>{strings.notApplicableAbbreviation}</em>}</span>
						</div>
					</CaptionVerticalLabelledPropertyX>
				)}
				<CaptionVerticalLabelledPropertyX
					label={strings.user}
					edit={onPatchHandlingUser}>
					{user?.lastNameCommaFirstName}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.supplier}
					link={onViewSupplier}>
					{item.supplierIdentifier}
				</CaptionVerticalLabelledPropertyX>
			</div>
			<Divider style={{ marginTop: 16, marginBottom: 24 }} />
			<RepairDetailProcessComponent item={item} />
			<div className='fg1'></div>
			<DividerWithMargin />
			<div className='df-col gap-8'>
				<TextLinkButton
					startIcon={<SettingsOutlinedIcon />}
					onClick={onPatchProcessSteps}>
					{strings.changeProcessSteps}
				</TextLinkButton>
				{item.isForPatient === false && (
					<TextLinkButton
						startIcon={<LinkIcon />}
						onClick={onLinkWithPatient}
						disabled={item.isCancelled || item.isCompleted}>
						{strings.linkWithPatient}
					</TextLinkButton>
				)}
				{item.isForPatient === true && (
					<TextLinkButton
						startIcon={<LinkOffIcon />}
						onClick={onUnlinkFromPatient}
						disabled={item.isCancelled || item.isCompleted}>
						{strings.removeLinkWithPatient}
					</TextLinkButton>
				)}
				<TextLinkButton
					startIcon={<StorefrontOutlinedIcon />}
					onClick={onSendToSupplier}
					disabled={item.canSendToSupplier === false}>
					{strings.sendRepairToSupplier}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<LocalShippingOutlinedIcon />}
					onClick={onRegisterDelivery}
					disabled={item.canRegisterDelivery === false}>
					{strings.registerDelivery}
				</TextLinkButton>
				{item.handoverToPatientStepIncluded === true && (
					<TextLinkButton
						startIcon={<SyncAltOutlinedIcon />}
						onClick={onHandoverToPatient}
						disabled={item.canRedeliverToPatient === false}>
						{strings.handoverToPatient}
					</TextLinkButton>
				)}
				<TextLinkButton
					startIcon={<DeleteOutlineOutlinedIcon />}
					color='secondary'
					onClick={onCancel}
					disabled={item.isCancelled || item.isCompleted}>
					{strings.cancelWhat(strings.repair)}
				</TextLinkButton>
			</div>
		</div>
	);
};
