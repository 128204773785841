import React from 'react';
import { CommissionsPageProviders } from '../commissions/context/CommissionsPageProviders';
import { CommissionPayoutsPageProviders } from '../payouts/context/CommissionPayoutsPageProviders';
import { CommissionRulesCacheProvider } from './CommissionRulesCacheProvider';

export const CommissionsProviders = ({ children }: any) => {
	return (
		<CommissionsPageProviders>
			<CommissionPayoutsPageProviders>
				<CommissionRulesCacheProvider>{children}</CommissionRulesCacheProvider>
			</CommissionPayoutsPageProviders>
		</CommissionsPageProviders>
	);
};
