import { Divider } from '@material-ui/core';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import { SupplierDialog } from 'app/main/contacts/suppliers/SupplierDialog';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { CubeOutlineIcon } from 'framework/components/icons/CubeOutlineIcon';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { IInventoryItem, IInventoryItemDetail } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { calcProductCatalogDetailRoute } from '../../routes';
import { InventoryItemsBreadcrumbs } from '../InventoryItemsBreadcrumbs';
import { InventoryItemChips } from '../components/InventoryItemChips';
import { InventoryItemDetailTabType, InventoryItemDetailTabTypes } from './InventoryItemDetailTabType';

const TabTypeIconRecord: Record<InventoryItemDetailTabType, React.ReactNode> = {
	overview: <DashboardIcon />,
	transactions: <TimelineIcon />,
	comments: <CommentOutlinedIcon />,
};

const createTabRecord = (strings: IStrings): Record<InventoryItemDetailTabType, string> => {
	return {
		overview: strings.overview,
		transactions: strings.transactions,
		comments: strings.remarks,
	};
};

const createEndIconRecord = (detail: IInventoryItemDetail): Record<InventoryItemDetailTabType, React.ReactNode | undefined> => {
	return {
		comments: detail.countRemarks > 0 && (
			<BadgeChipX
				value={detail.countRemarks}
				color='error'
			/>
		),
		overview: undefined,
		transactions: detail.data.countTransactions > 0 && (
			<BadgeChipX
				value={detail.data.countTransactions}
				color='default'
			/>
		),
	};
};

interface IProps {
	tab: InventoryItemDetailTabType;
	setTab: (tab: InventoryItemDetailTabType) => void;
	item: IInventoryItem;
	detail: IInventoryItemDetail;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const InventoryItemDetailPageHeader = ({ tab, setTab, item, detail, reload, style }: IProps) => {
	const strings = useLocalization();
	const tabRecord = useMemo(() => createTabRecord(strings), [strings]);
	const endIconRecord = useMemo(() => createEndIconRecord(detail), [detail]);
	const { push } = useHistory();
	const { open, cancel } = useDialogsContext();

	const onViewSupplier = () => {
		if (item.supplierId) {
			open(
				<SupplierDialog
					open
					id={item.supplierId}
					close={cancel}
				/>
			);
		}
	};

	return (
		<div
			className='df-col fg1'
			style={style}>
			<div className='df-row-ac'>
				<InventoryItemsBreadcrumbs />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={item.serialNumber ?? strings.inOrder} />
				<InventoryItemChips
					item={item}
					style={{ marginLeft: 4 }}
				/>
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<div
				className='df-row-ac'
				style={{ marginTop: 16, marginLeft: -4 }}>
				<HorizontalTabsX
					tabs={InventoryItemDetailTabTypes}
					selectedTab={tab}
					setSelectedTab={setTab}
					titleRecord={tabRecord}
					startIconRecord={TabTypeIconRecord}
					endIconRecord={endIconRecord}
				/>
				<div className='fg1'></div>
				<div className='df-row-ac gap-4'>
					<TextLinkButton
						startIcon={
							<div className='df-row-ac'>
								<CubeOutlineIcon />
								<OpenInBrowserOutlinedIcon style={{ marginLeft: -2 }} />
							</div>
						}
						color='textSecondary'
						underline
						onClick={() => push(calcProductCatalogDetailRoute(item.productCatalogId))}>
						{strings.productCatalogItem}
					</TextLinkButton>
					<Divider orientation='vertical' />
					<TextLinkButton
						startIcon={
							<div className='df-row-ac'>
								<PersonOutlineOutlinedIcon />
								<OpenInBrowserOutlinedIcon style={{ marginLeft: -2 }} />
							</div>
						}
						color='textSecondary'
						disabled={isNullOrUndefined(item.patientId)}
						underline
						onClick={item.patient ? () => push(calcPatientDetailRoute(item.patientId!)) : undefined}>
						{strings.patient}
					</TextLinkButton>
					<Divider orientation='vertical' />
					<TextLinkButton
						startIcon={
							<div className='df-row-ac'>
								<BusinessCenterOutlinedIcon />
								<InfoOutlinedIcon />
							</div>
						}
						color='textSecondary'
						disabled={item.hasSupplierDefined === false}
						onClick={item.hasSupplierDefined ? onViewSupplier : undefined}>
						{strings.supplier}
					</TextLinkButton>
				</div>
			</div>
		</div>
	);
};
