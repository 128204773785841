import { format as formatDateFns, Locale } from 'date-fns';
import { FormatDateXLocalizedType } from './FormatDateXLocalizedType';

export function formatDateXLocalized(date: Date | undefined | null | string | number, locale: Locale, format: FormatDateXLocalizedType = 'dd/MM/yyyy'): string {
	if (date === undefined || date === null) {
		return '';
	} else {
		return formatDateFns(date instanceof Date ? date : new Date(date), format, { locale: locale });
	}
}
