import { useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim';
import { StatCardX } from 'framework/components/cards/StatCardX';
import { AbsoluteChangeFadedRectangularChip } from 'framework/components/chips/AbsoluteChangeFadedRectangularChip';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IStockEntryDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const Width: number = 250;

interface IProps {
	item: IStockEntryDto;
	style?: React.CSSProperties;
}

export const StockStats = ({ item, style }: IProps) => {
	const strings = useLocalization();
	const hasVeppClaim = useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim();

	return (
		<div
			className='df-row-ac gap-16'
			style={style}>
			<StatCardX
				style={{ width: Width }}
				title={strings.stockValue}
				caption={strings.exVatShort}>
				<div className='df-row-ac gap-8'>
					<span style={{ fontSize: 24 }}>{hasVeppClaim ? formatCurrency(item.stockValueChange.current) : strings.noAccessToThisFunction}</span>
					<AbsoluteChangeFadedRectangularChip
						change={item.stockValueChange}
						isCurrency
						style={{ marginLeft: 8 }}
					/>
				</div>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={strings.inStock}
				caption={strings.amount}>
				<div className='df-row-ac gap-8'>
					<span style={{ fontSize: 24 }}>{item.countInStockChange.current.toString()}</span>
					<AbsoluteChangeFadedRectangularChip
						change={item.countInStockChange}
						style={{ marginLeft: 8 }}
					/>
				</div>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={strings.inOrder}
				caption={strings.amount}>
				<div className='df-row-ac gap-8'>
					<span style={{ fontSize: 24 }}>{item.countInBackOrderChange.current.toString()}</span>
					<AbsoluteChangeFadedRectangularChip
						change={item.countInBackOrderChange}
						style={{ marginLeft: 8 }}
					/>
				</div>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={strings.inRepair}
				caption={strings.amount}>
				<div className='df-row-ac gap-8'>
					<span style={{ fontSize: 24 }}>{item.countInRepairChange.current.toString()}</span>
					<AbsoluteChangeFadedRectangularChip
						change={item.countInRepairChange}
						style={{ marginLeft: 8 }}
					/>
				</div>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={strings.inTest}
				caption={strings.amount}>
				<div className='df-row-ac gap-8'>
					<span style={{ fontSize: 24 }}>{item.countInTestOrLoanChange.current.toString()}</span>
					<AbsoluteChangeFadedRectangularChip
						change={item.countInTestOrLoanChange}
						style={{ marginLeft: 8 }}
					/>
				</div>
			</StatCardX>
		</div>
	);
};
