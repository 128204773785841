import { Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { PaperOutlined } from 'framework/table/PaperOutlined';
import { isLast } from 'framework/utils/array/isLast';
import { ISpeechAudiogram, LeftRightOrStereoType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { SpeechChart } from './SpeechChart';

interface IProps {
	speechAudiogram: ISpeechAudiogram;
	lrs: LeftRightOrStereoType;
}

export const SpeechAudiogramNoahInfo = ({ speechAudiogram, lrs }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [anchor, open, close] = useAnchorElement();

	return (
		<>
			{Boolean(anchor) && (
				<Popover
					open={Boolean(anchor)}
					anchorEl={anchor}
					onClose={close}>
					<div
						className='df-col'
						style={{ padding: 24, minWidth: '400px' }}>
						<DividerWithCaption caption={`NOAH ${strings.data}`} />
						<LabelledProperty
							label='Hearing instrument condition'
							property={speechAudiogram.hearingInstrumentConditionTypeDisplay}
						/>
						<LabelledProperty
							label='Stimulus signal output'
							property={speechAudiogram.stimulusSignalOutputDisplay}
						/>
						<DividerWithCaption caption={strings.points} />
						<TableContainer component={PaperOutlined}>
							<Table size='small'>
								<TableHead>
									<TableRow>
										<TableCell>{`${strings.intensity} (dB)`}</TableCell>
										<TableCell>{`${strings.score} (%)`}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{speechAudiogram.orderedPoints.map(t => (
										<TableRow>
											<TableCell style={{ borderBottom: isLast(t, speechAudiogram.orderedPoints) ? 'none' : '1px solid rgb(224, 224, 224)' }}>{`${Math.round(
												t.intensity
											)} dB`}</TableCell>
											<TableCell style={{ borderBottom: isLast(t, speechAudiogram.orderedPoints) ? 'none' : '1px solid rgb(224, 224, 224)' }}>{`${Math.round(
												t.scorePercentage
											)} %`}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<DividerWithCaption caption={strings.graph} />
						<SpeechChart
							audiogram={speechAudiogram}
							lrs={lrs}
							minHeight={240}
							minWidth={400}
						/>
					</div>
				</Popover>
			)}
			<span onClick={open}>
				<HelpOutlineIcon style={{ cursor: 'pointer', color: theme.palette.primary.main }} />
			</span>
		</>
	);
};
