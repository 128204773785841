import { useMemo } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';

const findNavigationLinkMatch = <T extends string>(path: string, routeRecord: Record<T, string>, fallBack: T): T => {
	const x = (Object.keys(routeRecord) as T[]).filter(t => matchPath(path, { path: routeRecord[t] }));
	return x.length > 0 ? x[0] : fallBack;
};

export const useNavigationLinkRouting = <TNavLink extends string>(
	routeRecord: Record<TNavLink, string>,
	fallBack: TNavLink
): [TNavLink, (link: TNavLink) => void] => {
	const { pathname } = useLocation();
	const link = useMemo(() => findNavigationLinkMatch(pathname, routeRecord, fallBack), [pathname, fallBack, routeRecord]);
	const { push } = useHistory();

	const onSetLink = (link: TNavLink) => {
		push(routeRecord[link]);
	};

	return [link, onSetLink];
};
