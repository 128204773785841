import React from 'react';
import { useContext } from 'react';
import { PreloadCacheProvider as FrameworkCacheProvider } from 'framework/context/PreloadCacheProvider';
import { commissionRulesQuery_all, commissionsQuery_concurrencyToken, ICommissionRulesCache } from 'gen/ApiClient';
import { PreloadContext } from 'shared/context/PreloadContext';
import { CommissionRulesCacheContext } from './CommissionRulesCacheContext';

export const CommissionRulesCacheProvider = ({ children }: any) => {
	const { commissionRules } = useContext(PreloadContext);

	return (
		<FrameworkCacheProvider<ICommissionRulesCache>
			concurrencyF={commissionsQuery_concurrencyToken}
			loadF={commissionRulesQuery_all}
			context={CommissionRulesCacheContext}
			initialData={commissionRules}>
			{children}
		</FrameworkCacheProvider>
	);
};
