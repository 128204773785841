import { TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';
import { NumberFormatProps } from './NumberFormatProps';
import { NumberFormatX } from './NumberFormatX';

type NumberFormatTextFieldProps = TextFieldProps & NumberFormatProps;

export const NumberFormatTextField = ({
	suffix,
	decimalScale,
	allowNegative,
	prefix,
	fixedDecimalScale,
	isAllowed,
	InputProps,
	...props
}: NumberFormatTextFieldProps) => {
	return (
		<TextField
			variant='filled'
			{...props}
			InputProps={{
				...InputProps,
				inputComponent: NumberFormatX as any,
				inputProps: {
					suffix: suffix,
					decimalScale: decimalScale,
					allowNegative: allowNegative,
					prefix: prefix,
					fixedDecimalScale: fixedDecimalScale,
					isAllowed: isAllowed,
				},
			}}
		/>
	);
};
