import { Divider } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { StringModelForm } from 'framework/forms/StringModelForm';
import {
	IInvoiceForwardingSettings,
	invoiceForwardingSettingsCommand_addEmailAddress,
	invoiceForwardingSettingsCommand_disable,
	invoiceForwardingSettingsCommand_enable,
	invoiceForwardingSettingsCommand_removeEmailAddress,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { createInvoiceForwardingModeRecord } from './createInvoiceForwardingModeRecord';
import { PatchInvoiceForwardingModeForm } from './PatchInvoiceForwardingModeForm';

interface IProps {
	settings: IInvoiceForwardingSettings;
	reload: VoidFunction;
}

export const InvoiceForwardingSettingsTab = ({ settings, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const ModeRecord = React.useMemo(() => createInvoiceForwardingModeRecord(strings), [strings]);

	const onActivate = () => {
		open(
			<AlertDialog
				open
				title={strings.activateIntegration}
				content={strings.doYouWantToActivateThisIntegrationQuestion}
				acceptText={strings.yesCommaActivate}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => invoiceForwardingSettingsCommand_enable()}
				accept={confirm}
			/>
		);
	};
	const onDeactivate = () => {
		open(
			<AlertDialog
				open
				title={strings.deactivateIntegration}
				content={strings.doYouWantToDeactivateThisIntegrationQuestion}
				acceptText={strings.yesCommaDeactivate}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => invoiceForwardingSettingsCommand_disable()}
				accept={confirm}
			/>
		);
	};

	const onAddEmailAddress = () => {
		open(
			<StringModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.addEmailAddress}
				label={strings.email}
				initialValue=''
				cancel={cancel}
				submitText={strings.update}
				submitFunction={value => invoiceForwardingSettingsCommand_addEmailAddress({ emailAddress: value })}
			/>
		);
	};

	const onRemoveEmailAddress = (email: string) => {
		open(
			<AlertDialog
				open
				title={strings.deleteEmailAddress}
				content={strings.doYouWantToDeleteThisEmailAddressQuestion}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => invoiceForwardingSettingsCommand_removeEmailAddress({ emailAddress: email })}
				accept={confirm}
			/>
		);
	};

	const onPatchMode = () => {
		open(
			<PatchInvoiceForwardingModeForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<div
			className='df-col gap-16 fg1'
			style={{ padding: 16, maxWidth: 1280 }}>
			<CardX className='df-col gap-16'>
				<Typography16pxBold>{strings.generalSettings}</Typography16pxBold>
				<div className='df-col gap-4'>
					<CaptionVerticalLabelledPropertyX
						label={strings.status}
						startIcon={<StatusIcon status={settings.isEnabled ? 'success' : 'error'} />}>
						{settings.isEnabled ? strings.activated : strings.notActived}
					</CaptionVerticalLabelledPropertyX>
					<TextLinkButton
						startIcon={<CheckBoxOutlinedIcon />}
						onClick={onActivate}
						color='primary'
						disabled={settings.isEnabled}>
						{strings.activateVerb}
					</TextLinkButton>
					<TextLinkButton
						startIcon={<HighlightOffOutlinedIcon />}
						onClick={onDeactivate}
						color='secondary'
						hoverColor='secondary'
						disabled={settings.isEnabled === false}>
						{strings.deactivateVerb}
					</TextLinkButton>
				</div>
				<Divider />
				<div className='df-col gap-4'>
					<CaptionVerticalLabelledPropertyX
						startIcon={<StatusIcon status={settings.emailAddresses.length > 0 ? 'success' : 'error'} />}
						label={strings.emailAddressWhereToInvoicedWillBeSent}>
						{settings.emailAddresses.length === 0 && <NoRecordsFound variant='caption'>{strings.noEmailAddressesDefinedYet}</NoRecordsFound>}
						<div className='df-col gap-2'>
							{settings.emailAddresses.map(t => (
								<div
									className='df-row-ac gap-2'
									key={t}>
									<span>{t}</span>
									<ColorOnHoverIconButton
										onClick={() => onRemoveEmailAddress(t)}
										size='small'
										edge='start'
										color='secondary'>
										<DeleteOutlineOutlinedIcon fontSize='small' />
									</ColorOnHoverIconButton>
								</div>
							))}
						</div>
					</CaptionVerticalLabelledPropertyX>
					<TextLinkButton
						startIcon={<MailOutlineIcon />}
						onClick={onAddEmailAddress}
						color='primary'>
						{strings.addAnEmailAdress}
					</TextLinkButton>
				</div>
				<Divider />
				<CaptionVerticalLabelledPropertyX
					label={strings.mode}
					startIcon={<StatusIcon status='success' />}
					edit={onPatchMode}>
					{ModeRecord[settings.mode]}
				</CaptionVerticalLabelledPropertyX>
			</CardX>
		</div>
	);
};
