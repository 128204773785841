import React from 'react';
import { ILabelledPropertyProps } from './LabelledProperty';
import { VariantLabelledProperty } from './VariantLabelledProperty';

export interface IWarningLabelledPropertyProps extends ILabelledPropertyProps {
	warning: string;
	style?: React.CSSProperties;
}

export const WarningLabelledProperty = ({ warning, ...rest }: IWarningLabelledPropertyProps) => {
	return (
		<VariantLabelledProperty
			variantX='warning'
			property={warning}
			{...rest}
		/>
	);
};
