import { useTheme } from '@material-ui/core';
import { MbscCalendarEventData } from '@mobiscroll/react';
import { IRosaEventDto } from 'gen/ApiClient';
import React from 'react';
import './styling.scss';

interface IProps {
	data: MbscCalendarEventData;
	rEvent: IRosaEventDto | undefined;
}

export const ScheduleEventContent = ({ data, rEvent }: IProps) => {
	const theme = useTheme();

	if (data.allDay) {
		return (
			<div
				style={{ background: data.color }}
				className='md-custom-event-allday-title'>
				{data.title}
			</div>
		);
	} else {
		return (
			<div
				className='md-custom-event-cont'
				style={{
					borderLeft: `5px solid ${rEvent?.calendarHexColor ?? 'white'}`,
					backgroundColor: rEvent?.isAppointment === true ? data.color : '#fff',
				}}>
				<div className='md-custom-event-wrapper'>
					{rEvent !== undefined && rEvent.isAppointment === false && (
						<div
							style={{
								position: 'absolute',
								top: 0,
								bottom: 0,
								left: 0,
								right: 0,
								background: rEvent.calendarHexColor,
								WebkitMaskImage: 'url(assets/bg-leave.svg)',
								maskImage: 'url(assets/bg-leave.svg)',
							}}></div>
					)}
					<div style={{ padding: '0 10px' }}>
						<div
							style={{
								color: rEvent?.isNoShow ? theme.palette.error.main : 'initial',
								textDecoration: rEvent?.isNoShow ? 'line-through' : 'inherit',
							}}>
							{data.title}
						</div>
						<div
							style={{
								fontSize: 10,
								color: '#666',
								paddingTop: 3,
							}}>
							{data.start} - {data.end}
						</div>
					</div>
				</div>
			</div>
		);
	}
};
