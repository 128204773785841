import { FailedEventStatus } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createStatusRecord = (strings: IStrings): Record<FailedEventStatus, string> => {
	return {
		Ignored: 'Genegeerd',
		Open: 'Open',
		Resolved: 'Opgelost',
	};
};
