import {
	calcLoanDetailRoute,
	calcOrderDetailRoute,
	calcRepairDetailRoute,
	calcRequestForInsuranceAllowanceDetailRoute,
	calculateArticlesInquiryDetailRoute,
} from 'app/main/processes/ProcessesRoutes';
import { WorkflowType } from 'gen/ApiClient';

export const WorkflowTypeNavigationRecord: Record<WorkflowType, (id: string) => string> = {
	Loan: calcLoanDetailRoute,
	Rfia: calcRequestForInsuranceAllowanceDetailRoute,
	Order: calcOrderDetailRoute,
	Repair: calcRepairDetailRoute,
	ArticlesInquiry: calculateArticlesInquiryDetailRoute,
};
