import { Typography } from '@material-ui/core';
import { LabelledChipsList } from 'framework/components/chips/LabelledChipsList';
import { IHeader } from 'framework/table/IHeader';
import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { formatDate } from 'framework/utils/date/formatDate';
import { IPatientSummary } from 'gen/ApiClient';
import React from 'react';
import { GenderIcon } from 'shared/GenderIcon';
import { PatientsSelectableColumn } from './PatientsSelectableColumn';

export const createPatientHeaders = (colRecord: Record<PatientsSelectableColumn, string>): Record<PatientsSelectableColumn, IHeader<IPatientSummary>> => {
	return {
		created: createSortableHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(colRecord, 'created', s => formatDate(s.created)),
		lastUpdated: createSortableHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(colRecord, 'lastUpdated', s => formatDate(s.lastUpdated)),
		canRenewAfter: createSortableHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(colRecord, 'canRenewAfter', s => formatDate(s.canRenewAfter)),
		lastHandoverDate: createSortableHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(colRecord, 'lastHandoverDate', s =>
			formatDate(s.lastHandoverDate)
		),
		gender: createSortableHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(colRecord, 'gender', s => (
			<GenderIcon
				gender={s.gender as any}
				style={{ width: '16px', marginLeft: '4px' }}
			/>
		)),
		lastName: createSortableHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(colRecord, 'lastName', t => t.lastName),
		firstName: createSortableHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(colRecord, 'firstName', t => t.firstName),
		dateOfBirth: createSortableHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(colRecord, 'dateOfBirth', s => formatDate(s.dateOfBirth)),
		phoneNumbersDisplay: createSortableHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(colRecord, 'phoneNumbersDisplay', t => t.phoneNumbersDisplay),
		socialSecurityNumber: createSortableHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(
			colRecord,
			'socialSecurityNumber',
			t => t.socialSecurityNumber
		),
		addressFormatted: createSortableHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(colRecord, 'addressFormatted', t => t.addressFormatted),
		lastNameCommaFirstNameBracketsNickName: createSortableHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(
			colRecord,
			'lastNameCommaFirstNameBracketsNickName',
			t => (
				<div className='df-row-ac'>
					<GenderIcon
						gender={t.gender as any}
						style={{ marginRight: '12px' }}
					/>
					<Typography variant='body2'>{t.lastNameCommaFirstNameBracketsNickName}</Typography>
				</div>
			)
		),
		labels: createHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(colRecord, 'labels', t => <LabelledChipsList labels={t.labels} />),
		reference: createSortableHeaderWithKey<IPatientSummary, PatientsSelectableColumn>(colRecord, 'reference', t => t.reference),
	};
};
