import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { FormHelperTextX } from '../../forms/common/FormHelperTextX';
import { IOption } from '../../IOption';

interface IProps<TValue extends IOption<string>> {
	options: TValue[];
	selectedId: string | undefined; // id
	setSelectedId: (id: string | undefined) => void;
	error?: boolean;
	disabled?: boolean;
	label?: string;
	required?: boolean;
	style?: React.CSSProperties;
	helperText?: JSX.Element | string;
}

const renderOption = <TValue extends IOption<string>>(id: string, options: TValue[]): string => {
	return options.find(t => t.id === id)?.identifier ?? '';
};

export const AutocompleteSingle = <TValue extends IOption<string>>({
	options,
	selectedId,
	setSelectedId,
	error,
	disabled = false,
	label,
	required,
	style,
	helperText,
}: IProps<TValue>) => {
	const [inputValue, setInputValue] = useState<string>('');

	return (
		<>
			<Autocomplete
				style={style}
				inputValue={inputValue}
				onInputChange={(_, value) => setInputValue(value)}
				disabled={disabled}
				options={options.map(t => t.id)}
				autoComplete
				autoHighlight
				// autoSelect
				selectOnFocus
				getOptionLabel={id => renderOption(id, options)}
				getOptionSelected={(option, value) => options.findIndex(t => t.id === option) > -1}
				fullWidth
				value={options.find(t => t.id === selectedId) ? selectedId : null}
				onChange={(_, value) => setSelectedId(value ?? undefined)}
				renderInput={params => (
					<>
						<TextField
							variant='filled'
							{...params}
							ref={params.InputProps.ref}
							required={required}
							error={error}
							label={label}
						/>
						{helperText && error === false && <FormHelperTextX>{helperText}</FormHelperTextX>}
					</>
				)}
				renderOption={id => options.find(t => t.id === id)?.identifier}
			/>
		</>
	);
};
