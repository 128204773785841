import { useUserHasPageClaim } from 'claims/useUserHasPageClaim';
import { PageClaim } from 'gen/ApiClient';
import React from 'react';
import { IPageProps } from './IPageProps';
import { NoAccessPage } from './NoAccessPage';
import { Page } from './Page';

interface IProps extends IPageProps {
	pageClaim: PageClaim;
}

export const RestrictedPage = ({ pageClaim, ...props }: IProps) => {
	const hasAccess = useUserHasPageClaim(pageClaim);

	return hasAccess ? <Page {...props} /> : <NoAccessPage />;
};
