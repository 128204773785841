import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { Logo } from './logo/Logo';
import { clearCacheAndReloadApplication } from 'framework/utils/clearCacheAndReloadApplication';

interface IProps {
	serverBuild: number;
	build: number;
}

export const MustUpdateWall = ({ serverBuild, build }: IProps) => {
	const strings = useLocalization();
	return (
		<div className='df-col-ac stretch-ver jc-c'>
			<Logo
				width={400}
				style={{ marginBottom: '20px' }}
			/>
			<Typography variant='h5'>{strings.mustUpdateText}</Typography>
			<Typography
				variant='caption'
				style={{ marginTop: 4, marginBottom: '30px' }}>{`(b: ${serverBuild}/f: ${build})`}</Typography>
			<Button
				variant='contained'
				color='primary'
				onClick={clearCacheAndReloadApplication}>
				{strings.update}
			</Button>
		</div>
	);
};
