import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatient, patientsCommand_linkHealthInsuranceFund } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormSelectHealthInsuranceFundField } from 'shared/forms/FormSelectHealthInsuranceFundField';

interface IModel {
	code: string;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		code: yup.string().required(strings.formRequired(strings.healthInsuranceFund)),
	});
};

interface IProps extends DialogProps {
	patient: IPatient;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LinkHealthInsuranceFundForm = ({ patient, confirm, cancel, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [link, isSubmitting] = useFormSubmit(patientsCommand_linkHealthInsuranceFund);
	const strings = useLocalization();

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await link(patient.id, values.code);
		if (r.isSuccess) {
			notify(strings.patientUpdated);
			confirm();
		}
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{
				code: patient.healthInsuranceFundCode as any,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.selectHealthInsuranceFund}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormSelectHealthInsuranceFundField<IModel>
							name='code'
							label={strings.healthInsuranceFund}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.link}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
