import { Button, IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Eventcalendar, localeEn, localeFr, localeNl, MbscEventcalendarView } from '@mobiscroll/react';
import { endOfMonth, isEqual, startOfMonth } from 'date-fns';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { nlBELocaleEndOfWeek } from 'framework/utils/date/nlBELocaleEndOfWeek';
import { nlBELocaleStartOfWeek } from 'framework/utils/date/nlBELocaleStartOfWeek';
import { agendaQuery_query, IAgendaQueryParams } from 'gen/ApiClient';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { getNow } from 'shared/utils/getNow';
import { AgendaColorScheme } from '../agenda/AgendaColorScheme';
import { AgendaGroupScheme } from '../agenda/AgendaGroupScheme';
import { AgendaViewType } from '../agenda/AgendaViewType';
import { mapAgendaViewTypeToMobiscrollView } from '../agenda/mapAgendaViewTypeToMobiscrollView';
import { mapEventToMobiscrollCalendarEvent } from '../agenda/mapEventToMobiscrollCalendarEvent';
import { mapResourcesToMobiscrollResources } from '../agenda/mapResourcesToMobiscrollResources';
import { calculateNextDate } from '../calculateNextDate';
import { calculatePreviousDate } from '../calculatePreviousDate';
import { ICalendarResource } from '../ICalendarResource';
import { renderDateDisplay } from '../renderDateDisplay';
import { filterEvents } from './filterEvents';

const DefaultParams: IAgendaQueryParams = {
	afterDate: nlBELocaleStartOfWeek(startOfMonth(getNow())),
	beforeDate: nlBELocaleEndOfWeek(endOfMonth(getNow())),
};

interface IProps {
	userResources: ICalendarResource[];
	selectedUserResourceKeys: string[];
}

export const FindTimeComponent = ({ userResources, selectedUserResourceKeys }: IProps) => {
	const strings = useLocalization();
	const language = useCurrentLanguage();
	const mobiscrollLocale = useMemo(() => (language === 'nl' ? localeNl : language === 'fr' ? localeFr : localeEn), [language]);
	const view: AgendaViewType = 'day';
	const mobiscrollAgendaView = useMemo<MbscEventcalendarView>(() => mapAgendaViewTypeToMobiscrollView(view, 60), [view]);
	const groupScheme: AgendaGroupScheme = 'users';
	const colorScheme: AgendaColorScheme = 'types';
	const [date, setDate] = useState<Date>(getNow());
	const [params, setParams, response] = useApiEffectWithParams(agendaQuery_query, DefaultParams);

	const onSetDate = (val: Date) => {
		setDate(val);
		const after = nlBELocaleStartOfWeek(startOfMonth(val));
		const before = nlBELocaleEndOfWeek(endOfMonth(val));
		if (isEqual(after, params.afterDate) === false || isEqual(before, params.beforeDate) === false) {
			setParams({ beforeDate: before, afterDate: after });
		}
	};

	const fEvents = useMemo(() => filterEvents(response?.events ?? [], selectedUserResourceKeys), [response, selectedUserResourceKeys]);
	const mappedEvents = useMemo(() => fEvents.map(t => mapEventToMobiscrollCalendarEvent(t, groupScheme, colorScheme)), [fEvents, groupScheme, colorScheme]);
	const mappedResources = useMemo(
		() =>
			mapResourcesToMobiscrollResources(
				groupScheme,
				userResources.filter(t => selectedUserResourceKeys.indexOf(t.key) !== -1),
				[]
			),
		[groupScheme, userResources, selectedUserResourceKeys]
	);

	return (
		<div className='df-col h100'>
			<div className='df-row-ac fg1'>
				<Button
					variant='outlined'
					onClick={() => onSetDate(getNow())}
					style={{ marginRight: 8 }}>
					{strings.today}
				</Button>
				<IconButton
					size='small'
					onClick={() => onSetDate(calculatePreviousDate(date, view))}>
					<ChevronLeftIcon />
				</IconButton>
				<IconButton
					size='small'
					onClick={() => onSetDate(calculateNextDate(date, view))}>
					<ChevronRightIcon />
				</IconButton>
				<div style={{ fontSize: '22px' }}>{renderDateDisplay(date, view, strings, language)}</div>
			</div>
			<Eventcalendar
				data={mappedEvents}
				showControls={false}
				showEventTooltip={false}
				locale={mobiscrollLocale}
				selectedDate={date}
				theme='material'
				themeVariant='light'
				clickToCreate={false}
				dragToCreate={false}
				dragToMove={false}
				dragToResize={false}
				eventDelete={false}
				resources={mappedResources}
				renderDay={() => <></>}
				view={mobiscrollAgendaView}
			/>
		</div>
	);
};
