import { useTheme } from '@material-ui/core';
import React from 'react';

interface IProps {
	identifier: string | undefined;
	caption?: string;
}

export const FilterMenuText = ({ identifier, caption }: IProps) => {
	const theme = useTheme();

	return (
		<div className='df-col'>
			<div style={{ fontSize: 14 }}>{identifier}</div>
			{caption && <div style={{ fontSize: 12, color: theme.palette.text.secondary }}>{caption}</div>}
		</div>
	);
};
