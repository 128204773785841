import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { useEffect, useState } from 'react';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { IModuleSummaryWithIcon, modules_All } from 'gen/client/HamsClient';

interface IProps {
	moduleIds?: number[];
	onSelect: (module: IModuleSummaryWithIcon) => void;
}

export const Modules = ({ moduleIds, onSelect }: IProps) => {
	const [allModules] = useApiEffect(modules_All);
	const [modules, setModules] = useState<IModuleSummaryWithIcon[]>([]);

	useEffect(() => {
		if (allModules !== undefined && moduleIds !== undefined && moduleIds?.length > 0) {
			setModules(allModules.filter(t => moduleIds.indexOf(t.moduleId ?? 0) > -1));
		} else if (allModules !== undefined) {
			setModules(allModules);
		}
	}, [allModules, moduleIds]);

	if (allModules === undefined) {
		return <div></div>;
	}

	return (
		<>
			{modules.map(t => (
				<PropertyInGrid
					key={t.moduleId}
					label={t.name}
					labelGridSize={6}
					value={
						<div>
							{t.hasLogo === true && (
								<Button
									variant='outlined'
									color='primary'
									onClick={() => onSelect(t)}>
									<img
										src={`data:image/png;base64,${t.base64Logo}`}
										alt='logo'
										style={{ width: 115, height: 33 }}
									/>
								</Button>
							)}
							{t.hasLogo === false && (
								<SmallPrimaryTextButton
									startIcon={<OpenInNewIcon />}
									style={{ textTransform: 'none' }}
									onClick={() => onSelect(t)}>
									{t.name}
								</SmallPrimaryTextButton>
							)}
						</div>
					}
				/>
			))}
		</>
	);
};
