import { Fade, IconButton, InputAdornment, Menu, MenuItem, TextField } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useEffect, useState } from 'react';
import { useRenderTimeUnit } from 'shared/timeUnit/useRenderTimeUnit';
import { ITimeModel, TimeUnit } from 'gen/ApiClient';
import { theme } from '../../theme';
import { CalendarCursorIcon } from '../components/icons/CalendarCursorIcon';
import { useAnchorElement } from '../hooks/useAnchorElement';
import { useStateBoolean } from '../hooks/useStateBool';
import { addUnitsToDate } from '../utils/date/addUnitsToDate';
import { getNow } from 'shared/utils/getNow';

interface IProps {
	units: TimeUnit[];
	defaultUnit?: TimeUnit;
	label: string;
	disabled?: boolean;
	onChangeDate: (date: Date | null) => void;
	showDatePicker: VoidFunction;
	fromDate?: Date;
	defaultValue?: ITimeModel;
}

export const TimeUnitTextField = ({ units, defaultUnit = 'Weeks', label, disabled = false, onChangeDate, showDatePicker, fromDate, defaultValue }: IProps) => {
	const [isHovered, enter, leave] = useStateBoolean(false);
	const [value, setValue] = useState<number | undefined>(defaultValue?.value);
	const [unitValue, setUnitValue] = useState<TimeUnit>(defaultValue ? (defaultValue.unit as any) : defaultUnit);
	const [anchor, open, close] = useAnchorElement();
	const renderTimeUnit = useRenderTimeUnit();

	useEffect(() => {
		if (value !== undefined && unitValue !== undefined) {
			onChangeDate(addUnitsToDate(fromDate ?? getNow(), value ?? 0, unitValue));
		}
		// eslint-disable-next-line
	}, [fromDate, defaultValue, unitValue, value]);

	const clear = () => {
		onChangeDate(null);
		setValue(undefined);
	};

	return (
		<TextField
			disabled={disabled}
			variant='filled'
			label={label}
			onMouseEnter={enter}
			onMouseLeave={leave}
			value={value === undefined ? '' : value}
			onChange={e => setValue(e.target.value as any)}
			inputProps={{ pattern: '[0-9]*' }}
			InputProps={{
				endAdornment: (
					<>
						<Fade in={isHovered && value !== undefined && disabled === false}>
							<CancelIcon
								color='primary'
								style={{ cursor: 'pointer' }}
								onClick={clear}
							/>
						</Fade>
						<InputAdornment position='end'>
							<Menu
								anchorEl={anchor}
								open={Boolean(anchor)}
								onClose={close}>
								{units.map(t => (
									<MenuItem
										key={t}
										value={t}
										onClick={() => {
											setUnitValue(t);
											close();
										}}>
										{renderTimeUnit(value ?? 0, t)}
									</MenuItem>
								))}
							</Menu>
							<div
								className='df-row-ac'
								style={{ color: theme.palette.text.secondary, cursor: 'pointer' }}
								onClick={open}>
								<div style={{ fontSize: '14px' }}>{renderTimeUnit(value ?? 0, unitValue)}</div>
								<ArrowDropDownIcon />
							</div>
							<IconButton
								edge='end'
								disabled={disabled}
								onClick={showDatePicker}>
								<CalendarCursorIcon />
							</IconButton>
						</InputAdornment>
					</>
				),
			}}
		/>
	);
};
