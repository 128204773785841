import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, Typography, useTheme } from '@material-ui/core';
import CakeIcon from '@material-ui/icons/Cake';
import HomeIcon from '@material-ui/icons/Home';
import LinkIcon from '@material-ui/icons/Link';
import PhoneIcon from '@material-ui/icons/Phone';
import { NumericIcon } from 'framework/components/icons/NumericIcon';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { formatDate } from 'framework/utils/date/formatDate';
import { IPatientMatchSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { GenderIcon } from 'shared/GenderIcon';

interface IProps {
	item: IPatientMatchSummary;
	index: number;
	onClick: VoidFunction;
	isLast: boolean;
}

export const HamsMatchedPatientListItem = ({ item, index, onClick, isLast }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();

	return (
		<ListItem
			key={item.id}
			button
			onClick={onClick}
			divider={isLast === false}>
			<ListItemAvatar>
				<Avatar variant='square'>{index + 1}</Avatar>
			</ListItemAvatar>
			<div className='df-col'>
				<div className='df-row-ac'>
					<div>{item.lastNameCommaFirstNameBracketsNickName}</div>
					<GenderIcon
						gender={item.gender as any}
						style={{ width: '15px', marginLeft: '4px' }}
					/>
				</div>
				{item.phoneNumber && (
					<div className='df-row-ac'>
						<PhoneIcon style={{ color: theme.palette.text.secondary, marginRight: 4, fontSize: 12, marginTop: -2 }} />
						<Typography variant='caption'>{item.phoneNumber}</Typography>
					</div>
				)}
				{item.socialSecurityNumber && (
					<div className='df-row-ac'>
						<NumericIcon style={{ color: theme.palette.text.secondary, marginRight: 4, fontSize: 12, marginTop: -2 }} />
						<Typography variant='caption'>{item.socialSecurityNumber}</Typography>
					</div>
				)}
				{item.dateOfBirth && (
					<div className='df-row-ac'>
						<CakeIcon style={{ color: theme.palette.text.secondary, marginRight: 4, fontSize: 12, marginTop: -2 }} />
						<Typography variant='caption'>{formatDate(item.dateOfBirth)}</Typography>
					</div>
				)}
				{item.addressFormatted && (
					<div className='df-row-ac'>
						<HomeIcon style={{ color: theme.palette.text.secondary, marginRight: 4, fontSize: 12, marginTop: -2 }} />
						<Typography variant='caption'>{item.addressFormatted}</Typography>
					</div>
				)}
				{item.isLinkedToARosaPatient && (
					<div className='df-row-ac'>
						<StatusIcon
							status='warning'
							style={{ marginRight: 4, fontSize: 12, marginTop: -2 }}
						/>
						<Typography
							variant='caption'
							style={{ color: theme.palette.warning.main }}>
							{strings.thisPatientAlreadyHasALinkWithAROSAPatient}
						</Typography>
					</div>
				)}
			</div>
			<ListItemSecondaryAction>
				<IconButton edge='end'>
					<LinkIcon />
				</IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	);
};
