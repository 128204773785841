import { ChipX } from 'framework/components/chips/ChipX';
import { IUserDto, UserRole } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	item: IUserDto;
	style?: React.CSSProperties;
}

export const UserRoleStatusChipX = ({ item, style }: IProps) => {
	const { userRoleRecord } = useContext(RecordContext);

	return (
		<ChipX
			style={style}
			label={userRoleRecord[item.role as UserRole]}
			color='info'
		/>
	);
};
