import { Collapse, Divider, List } from '@material-ui/core';
import { useLoggedInLocation } from 'app/auth/useLoggedInLocation';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { toggleSelect } from 'framework/utils/array/toggleSelect';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { CalendarFilterListItemComponent } from './CalendarFilterListItemComponent';
import { CalendarFilterListItemHeaderComponent } from './CalendarFilterListItemHeaderComponent';
import { ICalendarResource } from './ICalendarResource';

interface IProps {
	data: ICalendarResource[];
	selectedKeys: string[];
	setSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>;
	isColorSchemeSelected: boolean;
	selectColorScheme: VoidFunction;
	showSecondaryColors?: boolean;
	isGroupSelected: boolean;
	selectGroup: VoidFunction;
	canGroup: boolean;
	style?: React.CSSProperties;
}

export const CalendarRoomsFilterListComponent = ({
	data,
	selectedKeys,
	setSelectedKeys,
	isColorSchemeSelected,
	selectColorScheme,
	isGroupSelected,
	selectGroup,
	canGroup,
	style,
	showSecondaryColors,
}: IProps) => {
	const strings = useLocalization();
	const [isExpanded, toggle] = useStateToggleBoolean(false);
	const isAllSelected = useMemo(() => data.length === selectedKeys.length, [data, selectedKeys]);
	const isNoneSelected = useMemo(() => selectedKeys.length === 0, [selectedKeys]);
	const loggedInLocationId = useLoggedInLocation();
	const loggedInLocationResource = useMemo(() => data.find(t => t.key === loggedInLocationId), [data, loggedInLocationId]);
	const isOnlyLoggedInLocationSelected = useMemo(() => selectedKeys.length === 1 && selectedKeys[0] === loggedInLocationId, [loggedInLocationId, selectedKeys]);

	return (
		<div
			className='df-col'
			style={style}>
			<CalendarFilterListItemHeaderComponent
				title={strings.rooms}
				isColorSchemeSelected={isColorSchemeSelected}
				isExpanded={isExpanded}
				isGroupSelected={isGroupSelected}
				onClick={toggle}
				selectColorScheme={selectColorScheme}
				selectGroup={selectGroup}
				hideGroup={canGroup === false}
			/>
			<Collapse
				in={isExpanded}
				timeout='auto'
				unmountOnExit>
				{loggedInLocationResource === undefined && (
					<CalendarFilterListItemComponent
						isSelected={isAllSelected}
						indeterminate={isNoneSelected === false && isAllSelected === false}
						text={isAllSelected ? strings.selectNone : strings.selectAll}
						onClick={() => (isAllSelected ? setSelectedKeys([]) : setSelectedKeys(data.map(t => t.key)))}
						color='unset'
						showPrimaryColor={false}
						showSecondaryColor={false}
					/>
				)}
				{loggedInLocationResource !== undefined && (
					<CalendarFilterListItemComponent
						isSelected={isAllSelected}
						indeterminate={isNoneSelected === false && isAllSelected === false}
						text={
							isAllSelected
								? `${strings.showOnlyWhat('')} '${loggedInLocationResource.name}'`
								: isOnlyLoggedInLocationSelected
								? strings.selectNone
								: strings.selectAll
						}
						onClick={() =>
							isAllSelected
								? setSelectedKeys([loggedInLocationResource.key])
								: isOnlyLoggedInLocationSelected
								? setSelectedKeys([])
								: setSelectedKeys(data.map(t => t.key))
						}
						color='unset'
						showPrimaryColor={false}
						showSecondaryColor={false}
					/>
				)}
				<List
					dense
					disablePadding>
					<Divider variant='middle' />
					{data.map(t => (
						<CalendarFilterListItemComponent
							onClick={() => setSelectedKeys(toggleSelect(t.key, selectedKeys))}
							isSelected={selectedKeys.indexOf(t.key) !== -1}
							color={t.color}
							text={t.isEmpty ? <em>{t.name}</em> : t.name}
							key={t.key}
							showPrimaryColor={isColorSchemeSelected}
							showSecondaryColor={showSecondaryColors ?? false}
						/>
					))}
				</List>
			</Collapse>
		</div>
	);
};
