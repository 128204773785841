import { CardProps, Divider } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { useUsersAsFilterOptions } from 'app/main/settings/users/useUsersAsFilterOptions';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ArticlesInquiryStatusType, ArticlesInquiryStatusTypes, IArticlesInquiriesMetadata, IArticlesInquiriesQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { ArticlesInquiriesListPrefilter } from './ArticlesInquiriesListPrefilter';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { LocationsPreloadCacheContext } from 'app/main/settings/locations/LocationsPreloadCacheContext';

interface IProps extends CardProps {
	params: IArticlesInquiriesQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IArticlesInquiriesQueryParams>>;
	metadata: IArticlesInquiriesMetadata;
}

export const ArticlesInquiriesListFilterCard = ({ params, setParams, style, metadata, ...rest }: IProps) => {
	const strings = useLocalization();
	const { articlesInquiryStatusTypeRecord } = React.useContext(RecordContext);
	const filterUsers = useUsersAsFilterOptions();
	const isFilteredX = useMemo(() => isFiltered(params, []), [params]);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);

	const onFilter = (pars: IArticlesInquiriesQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params, [])));

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<ArticlesInquiriesListPrefilter
				selectedPrefilter={params.prefilter}
				setSelectedPrefilter={key => onFilter({ ...params, prefilter: key })}
				metadata={metadata}
			/>
			<Divider />
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<DateFilterItem
					label={strings.dateCreated}
					after={params.createdAfter}
					before={params.createdBefore}
					setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
				/>
				<RecordListFilterComponent<ArticlesInquiryStatusType>
					options={ArticlesInquiryStatusTypes}
					record={articlesInquiryStatusTypeRecord}
					label={strings.status}
					selected={params.statuses as any}
					setSelected={vals => onFilter({ ...params, statuses: vals })}
					icon={<HelpOutlineIcon />}
				/>
				<ListFilterComponent<string>
					options={filterUsers}
					selected={params.handlingUserIds}
					setSelected={vals => onFilter({ ...params, handlingUserIds: vals })}
					label={strings.user}
					icon={<PersonOutlineOutlinedIcon />}
					title={strings.user}
				/>
				<ListFilterComponent<string>
					options={locations}
					selected={params.locationIds}
					setSelected={vals => onFilter({ ...params, locationIds: vals })}
					label={strings.location}
					icon={<HelpOutlineIcon />}
					title={strings.location}
				/>
			</div>
		</CardX>
	);
};
