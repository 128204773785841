import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { SettingsPageViewContext } from './SettingsPageViewContext';
import { SettingsTabType } from './SettingsTabType';
import { createSettingsTabTypeRecord } from './utils';

export const SettingsPageViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [selectedTab, setSelectedTab] = useState<SettingsTabType>('general');
	const SettingsTabRecord = React.useMemo(() => createSettingsTabTypeRecord(strings), [strings]);

	return (
		<SettingsPageViewContext.Provider
			value={{
				selectedTab: selectedTab,
				setSelectedTab: setSelectedTab,
				TabTypeRecord: SettingsTabRecord,
			}}>
			{children}
		</SettingsPageViewContext.Provider>
	);
};
