import { Dialog, DialogContent, DialogProps, DialogTitle, Grid } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { CalendarEventRequiredProperty, agendaQuery_settings } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { CalendarEventRequiredPropertiesForm } from './CalendarEventRequiredPropertiesForm';
import { LocationsList } from './LocationsList';
import { RoomsList } from './RoomsList';
import { SelectTimeScale } from './SelectTimeScale';
import { TypesList } from './TypesList';
import { UsersList } from './UsersList';

interface IProps extends DialogProps {
	close: VoidFunction;
}

export const AgendaSettingsDialog = ({ close, ...rest }: IProps) => {
	const strings = useLocalization();
	const { isStacked } = useDialogsContext();
	const [settings, reload] = useApiEffect(agendaQuery_settings);
	const { calendarEventRequiredPropertiesRecord } = useContext(RecordContext);
	const { open, confirm, cancel } = useDialogsContext(reload);

	if (settings === undefined) {
		return <div></div>;
	}

	const onRequiredProperties = () => {
		open(
			<CalendarEventRequiredPropertiesForm
				open
				confirm={confirm}
				cancel={cancel}
				requiredProperties={settings.requiredProperties}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			fullWidth>
			<CloseButtonOnAbsolutePosition onClick={close} />
			<DialogTitle>{`${strings.settings} ${strings.calendar.toLowerCase()}`}</DialogTitle>
			<DialogContent dividers>
				<Grid
					container
					spacing={2}
					style={{ marginBottom: 8 }}>
					<GridDividerWithCaption
						caption={strings.preferences}
						style={{ marginBottom: 0, paddingBottom: 0 }}
						captionStyle={{ fontWeight: 500, color: 'black' }}
					/>
					<PropertyInGrid
						labelGridSize={6}
						label={strings.timeScale}
						value={<SelectTimeScale />}
					/>
					<GridDividerWithCaption
						caption={strings.settings}
						style={{ marginBottom: 0, paddingBottom: 0 }}
						captionStyle={{ fontWeight: 500, color: 'black' }}
					/>
					<PropertyInGrid
						labelGridSize={6}
						label={strings.requiredProperties}
						value={
							settings.requiredProperties.length === 0 ? (
								strings.none
							) : (
								<div className='df-col'>
									{settings.requiredProperties.map(t => (
										<div>{calendarEventRequiredPropertiesRecord[t as CalendarEventRequiredProperty]}</div>
									))}
								</div>
							)
						}
						edit={onRequiredProperties}
					/>
				</Grid>
				<TypesList />
				<UsersList />
				<LocationsList />
				<RoomsList />
			</DialogContent>
		</Dialog>
	);
};
