import AlgoliaPlaces from 'algolia-places-react';
import { FormikHelpers, useFormikContext } from 'formik';
import { FormTextField } from 'framework/forms/FormTextField';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';

interface ILocalAddressModel {
	addressLine: string | undefined;
	zip: string | undefined;
	city: string | undefined;
	country: string | undefined;
}

interface IProps {
	embed?: boolean;
	setExternalSearchMode?: (val: boolean) => void;
	optional?: boolean;
}

// todo pass language into function & select countries
// Other options from https://community.algolia.com/places/documentation.html#options
// onSuggestions={({ rawAnswer, query, suggestions }: any) => { }}
// onCursorChanged={({ rawAnswer, query, suggestion, suggestonIndex }: any) => { }}
// onClear={() => { }}
// onError={({ message }: any) => { }}
export const AddressInput = <T extends ILocalAddressModel>({ embed = false, setExternalSearchMode, optional }: IProps) => {
	const props = useFormikContext<T>();
	const strings = useLocalization();
	const [isInSearchMode, setIsInSearchMode] = useState<boolean>(false);
	const [rateLimitReached, setRateLimitReached] = useState<boolean>(false);

	useEffect(() => {
		if (setExternalSearchMode) {
			setExternalSearchMode(isInSearchMode);
		}
		// eslint-disable-next-line
	}, [isInSearchMode]);

	useEffect(() => {
		setIsInSearchMode(false);
	}, [rateLimitReached]);

	const setSuggestion = (suggestion: any, helpers: FormikHelpers<T>) => {
		if (suggestion) {
			setFormValue<T>('zip', suggestion.postcode, helpers);
			setFormValue<T>('city', suggestion.city, helpers);
			setFormValue<T>('country', suggestion.country, helpers);
			setFormValue<T>('addressLine', suggestion.name, helpers);
			setIsInSearchMode(false);
		}
	};

	return (
		<>
			{/* <div className="df-row-ac jc-e" style={{ marginTop: embed === true ? '-72px' : '0px', marginBottom: '24px' }}>
                <LocationSearchToggle isInSearchMode={isInSearchMode} rateLimitReached={rateLimitReached} setIsInSearchMode={setIsInSearchMode} />
            </div> */}
			{isInSearchMode === false ? (
				<>
					<FormTextField<T>
						name='addressLine'
						label={strings.addressLine}
						required={optional !== true}
					/>
					<FormTextField<T>
						name='zip'
						label={strings.zip}
						required={optional !== true}
					/>
					<FormTextField<T>
						name='city'
						label={strings.city}
						required={optional !== true}
					/>
					<FormTextField<T>
						name='country'
						label={strings.country}
						required={false}
					/>
				</>
			) : (
				<AlgoliaPlaces
					placeholder={strings.writeAnAddressHere}
					options={{
						appId: 'plIK76CZWFZZ',
						apiKey: 'dde6c0f7f2aad9a5debf11317f070d5a',
						language: 'nl',
						countries: ['be'],
						type: 'address',
					}}
					onChange={({ query, rawAnswer, suggestion, suggestionIndex }: any) => {
						setSuggestion(suggestion, props);
					}}
					onLimit={({ message }: any) => setRateLimitReached(true)}
				/>
			)}
		</>
	);
};
