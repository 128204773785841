import React, { useContext } from 'react';
import { IStatusChipProps, RectangularChip } from 'framework/components/chips/RectangularChip';
import { RecordContext } from './records/RecordContext';

export const PaymentMethodStatusChip = ({ label, ...rest }: IStatusChipProps) => {
	const { translatePaymentMethod } = useContext(RecordContext);

	return (
		<RectangularChip
			{...rest}
			label={translatePaymentMethod(label)}
		/>
	);
};
