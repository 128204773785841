import { IconButton, Typography, useTheme } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import React, { useContext } from 'react';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { SignatureFreehandIcon } from 'framework/components/icons/SignatureFreehandIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { IDocument } from 'gen/ApiClient';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { DocumentNameForm } from './DocumentNameForm';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	document: IDocument;
	reload: VoidFunction;
}

export const DocumentDialogGridTitle = ({ document, reload }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { translateDocumentType } = useContext(RecordContext);
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onEditName = () => {
		open(
			<DocumentNameForm
				open
				data={document}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<DialogGridTitle icon={<DescriptionIcon />}>
			<div className='df-row-ac'>
				<Typography variant='h1'>{translateDocumentType(document)}</Typography>
				<IconButton
					onClick={onEditName}
					style={{ marginLeft: 4, visibility: document.isUserUpload ? 'visible' : 'collapse' }}>
					<EditOutlinedIcon />
				</IconButton>
			</div>
			<LabelledProperty
				label={strings.extension}
				property={document.extension}
				withoutMargin
			/>
			{document.mustSign && document.hasSignature === false && <WarningLabelledProperty warning={strings.needsASignature} />}
			{document.hasSignature && (
				<LabelledProperty
					property={strings.signatureScannedOrUploaded}
					icon={<SignatureFreehandIcon style={{ color: theme.palette.success.main }} />}
					color='success'
				/>
			)}
		</DialogGridTitle>
	);
};
