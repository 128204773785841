import LinkIcon from '@material-ui/icons/Link';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useLocalizedHealthInsuranceFundsCache } from 'shared/context/useLocalizedHealthInsuranceFundsCache';

interface IProps {
	code: string | undefined;
	onLink: VoidFunction | undefined;
}

export const HealthInsuranceFundCardTitleTypography = ({ code, onLink }: IProps) => {
	const strings = useLocalization();
	const data = useLocalizedHealthInsuranceFundsCache();
	const hif = useMemo(() => data?.find(t => t.id === code), [data, code]);

	return (
		<CardTitleTypography
			title={
				<div className='df-row-ac'>
					<div style={{ marginRight: '4px' }}>{strings.healthInsuranceFund}</div>
					<StatusIcon
						status={hif !== undefined ? 'success' : 'error'}
						fontSize='small'
					/>
				</div>
			}
			withPaddingTop
			edit={onLink}
			editIcon={<LinkIcon />}
		/>
	);
};
