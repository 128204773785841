import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectField } from 'framework/forms/FormSelectField';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	PhoneNumberRegion,
	PhoneNumberRegions,
	ContactInfoType,
	IContactInfo,
	IPatchPatientContactInfoRequest,
	patientContactInfosCommand_patchContactInfo,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings, isEmail: boolean) => {
	return yup.object<IPatchPatientContactInfoRequest>({
		value: isEmail ? yup.string().required().email() : yup.string().required(),
		description: yup.string(),
		patientId: yup.string().required(),
		contactInfoId: yup.string().required(),
		phoneNumberRegion: yup.mixed<PhoneNumberRegion>().required(),
	});
};

interface IProps extends DialogProps {
	confirm: () => void;
	cancel: VoidFunction;
	patientId: string;
	contactInfo: IContactInfo;
}

export const PatchPatientContactInfoForm = ({ confirm, cancel, patientId, contactInfo, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(patientContactInfosCommand_patchContactInfo);
	const notify = useSnackbarNotify();
	const schema = useMemo(() => createSchema(strings, contactInfo.isEmail), [strings, contactInfo]);
	const isEmail = useMemo(() => (contactInfo.type as ContactInfoType) === 'Email', [contactInfo]);

	const handleSubmit = async (values: IPatchPatientContactInfoRequest, helpers: FormikHelpers<IPatchPatientContactInfoRequest>) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.contactInfoAdded);
			confirm();
		}
	};

	return (
		<Formik<IPatchPatientContactInfoRequest>
			validateOnMount
			initialValues={{
				value: contactInfo.value ?? '',
				description: contactInfo.description ?? '',
				patientId: patientId,
				contactInfoId: contactInfo.id,
				phoneNumberRegion: contactInfo.phoneNumberRegion,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog {...rest}>
					<DialogTitle>{strings.updateContactInfo}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'
						style={{ minWidth: 400 }}>
						<div className='df-row gap-4'>
							{isEmail === false && (
								<FormSelectField<IPatchPatientContactInfoRequest, PhoneNumberRegion>
									name='phoneNumberRegion'
									label={strings.country}
									options={PhoneNumberRegions}
									renderValue={t => t}
									getKey={t => t}
								/>
							)}
							<FormTextField<IPatchPatientContactInfoRequest>
								className='fg1'
								name='value'
								label={isEmail ? strings.email : strings.phone}
								required
							/>
						</div>
						<FormTextField<IPatchPatientContactInfoRequest>
							name='description'
							label={strings.description}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={strings.update}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
