import { Dialog, DialogProps, Grid, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EuroIcon from '@material-ui/icons/Euro';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { DocumentFromTemplateForm } from 'documents/DocumentFromTemplateForm';
import { DocumentFromTemplateOrStandardDocumentForm } from 'documents/DocumentFromTemplateOrStandardDocumentForm';
import { DocumentHistory } from 'documents/DocumentHistory';
import { DocumentWithRebuildActions } from 'documents/DocumentWithRebuildActions';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { LabelledChipsList } from 'framework/components/chips/LabelledChipsList';
import { DetailDialogGridComponent } from 'framework/components/detailDialog/DetailDialogGridComponent';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { DocumentActionsLeftContent } from 'framework/components/detailDialog/DocumentActionsLeftContent';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { CashPlusIcon } from 'framework/components/icons/CashPlusIcon';
import { FileDocumentOutlineIcon } from 'framework/components/icons/FileDocumentOutlineIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useApiEffectWithDefer } from 'framework/hooks/useApiEffectWithDefer';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import {
	CustomerAccountType,
	documentsQuery_single,
	ISettlementProposal,
	settlementProposalsCommand_delete,
	settlementProposalsQuery_single,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useCallback, useContext, useEffect } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { PaymentFromSettlementProposalForm } from '../../../payments/forms/customerAccountLines/PaymentFromSettlementProposalForm';
import { SpsLabelsForm } from '../../forms/SpsLabelsForm';
import { UpdateSettlementProposalForm } from '../../forms/UpdateSettlementProposalForm';
import { SettlementProposalLinesTable } from './SettlementProposalLinesTable';

interface IProps extends DialogProps {
	id: string;
	close: VoidFunction;
	withCreate?: boolean;
}

export const SettlementProposalDialog = ({ id, close, withCreate = false, ...rest }: IProps) => {
	const [item, reloadProposal] = useApiEffect(settlementProposalsQuery_single, id);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<InnerDialog
			{...rest}
			close={close}
			reloadProposal={reloadProposal}
			item={item}
			withCreate={withCreate}
		/>
	);
};

interface IInnerDialogProps extends DialogProps {
	item: ISettlementProposal;
	reloadProposal: VoidFunction;
	close: VoidFunction;
	withCreate: boolean;
}

export const InnerDialog = ({ item, reloadProposal, close, withCreate, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const [document, reloadDocument] = useApiEffectWithDefer(documentsQuery_single, item?.documentId ?? '', isNotNullNorUndefined);
	const reload = useCallback(() => {
		reloadProposal();
		reloadDocument();
	}, [reloadProposal, reloadDocument]);
	const { customerAccountTypeRecord } = useContext(RecordContext);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);

	useEffect(() => {
		if (withCreate === true) {
			onCreateDocument(true);
		}
		// eslint-disable-next-line
	}, [withCreate]);

	const onUpdate = () => {
		open(
			<UpdateSettlementProposalForm
				open
				proposal={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onPayment = () => {
		open(
			<PaymentFromSettlementProposalForm
				open
				settlementProposalId={item.id}
				customerAccountId={item.customerAccountId}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				fullWidth
				maxWidth='xs'
				content={strings.deleteSettlementProposalWithReferenceQuestion(item.reference ?? '')}
				acceptText={strings.yesWhatExclamation(strings.delete)}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => settlementProposalsCommand_delete(item.id)}
				accept={() => {
					cancel();
					close();
				}}
			/>
		);
	};

	const onCreateDocument = (isCreateRightAway: boolean) => {
		if (item.customerAccount.accountType === ('HealthInsuranceFund' as CustomerAccountType)) {
			open(
				<DocumentFromTemplateOrStandardDocumentForm
					open
					context='SettlementProposal'
					contextId={item.id}
					templatePredefinedType='SettlementProposal'
					confirm={confirm}
					cancel={cancel}
					typeOverrideName='Verzamelstaat'
					submitText={isCreateRightAway ? strings.selectWhatAndCreate(strings.template) : undefined}
					cancelText={isCreateRightAway ? strings.closeThisWindow : undefined}
					title={isCreateRightAway ? `${strings.createADocumentRightAwayQuestion}` : undefined}
				/>
			);
		} else {
			open(
				<DocumentFromTemplateForm
					open
					context='SettlementProposal'
					contextId={item.id}
					templatePredefinedType='SettlementProposal'
					confirm={confirm}
					cancel={cancel}
					submitText={isCreateRightAway ? strings.selectWhatAndCreate(strings.template) : undefined}
					cancelText={isCreateRightAway ? strings.closeThisWindow : undefined}
					title={isCreateRightAway ? `${strings.createADocumentRightAwayQuestion}` : undefined}
				/>
			);
		}
	};

	const onUpdateLabels = () => {
		open(
			<SpsLabelsForm
				cancel={cancel}
				confirm={confirm}
				item={item}
				open
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			fullWidth
			maxWidth='md'
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<EuroIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{item.reference}
					</Typography>
					<LabelledProperty
						label={strings.date}
						property={formatDate(item.created)}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.typePayer}
						property={customerAccountTypeRecord[item.customerAccountType as CustomerAccountType]}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.accountReceivable}
						property={item.customerAccountIdentifier}
						navigate={() => {}}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.total}
						property={formatCurrency(item.totalValue)}
						withoutMargin
					/>
					{item.hasDocument && item.isDocumentInSync === false && <WarningLabelledProperty warning={strings.linesDeletedAndOrAddedAfterDocumentCreation} />}
					<LabelledProperty
						label={strings.structuredReference}
						undefinedText=''
						withoutMargin
					/>
					<div>{item.belgianStructuredCreditorReference}</div>
					<LabelledProperty
						label={strings.labels}
						undefinedText={strings.notFilledIn}
						isUndefined={item.labels.length === 0}
						edit={onUpdateLabels}
					/>
					<LabelledChipsList labels={item.labels} />
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					label=''
					value={
						<div className='df-col-as'>
							{document === undefined && (
								<SmallPrimaryTextButton
									startIcon={<FileDocumentOutlineIcon />}
									onClick={() => onCreateDocument(false)}>
									{strings.createDocument}
								</SmallPrimaryTextButton>
							)}
							<SmallPrimaryTextButton
								startIcon={<PlaylistAddIcon />}
								onClick={onUpdate}
								disabled={item.isHandled}>
								{strings.deleteAndOrAddLines}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								startIcon={<CashPlusIcon />}
								onClick={onPayment}
								disabled={item.isHandled}>
								{strings.registerPayment}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								startIcon={<DeleteOutlineIcon />}
								onClick={onDelete}
								disabled={item.isHandled}
								color='secondary'>
								{strings.deleteWhat(strings.settlementProposal)}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
				<GridDividerWithCaption caption={strings.contents} />
				<Grid
					item
					xs={12}>
					<SettlementProposalLinesTable settlementProposal={item} />
				</Grid>
				{document && (
					<>
						<GridDividerWithCaption caption={strings.document} />
						<DetailDialogGridComponent
							leftContent={<DocumentActionsLeftContent />}
							rightContent={
								<div className='df-col-as'>
									<DocumentWithRebuildActions
										document={document}
										reload={reloadDocument}
										close={close}
										isDocumentInSync={item.isDocumentInSync}
									/>
								</div>
							}
						/>
						<DocumentHistory document={document} />
					</>
				)}
			</DialogContentGrid>
		</Dialog>
	);
};
