import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		serialNumber: strings.serialNumber,
		status: strings.status,
		type: strings.type,
		manufacturerAndModel: strings.brandAndModel,
		patientIdentifier: strings.patient,
		supplierIdentifier: strings.supplier,
	};
};
