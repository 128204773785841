import { DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { DialogX } from 'framework/dialogs/DialogX';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { updateArray } from 'framework/utils/array/updateArray';
import { articlesInquiriesCommand_addToExistingOrder, IAddArticlesInquiriesToExistingOrderRequest, IArticlesInquiryDto, IOrderV2 } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import * as yup from 'yup';
import { IArticlesInquiryLineDtoWithIsSelected } from './IArticlesInquiryLineDtoWithIsSelected';
import { mapArticlesInquiryLineDtoWithIsSelectedToLineRequest } from './mapArticlesInquiryLineDtoWithIsSelectedToLineRequest';
import { OrderArticlesInquiryLineComponent } from './OrderArticlesInquiryLineComponent';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IAddArticlesInquiriesToExistingOrderRequest>({
			lines: yup.mixed(),
			articlesInquiryId: yup.string().required(),
			orderId: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	previous?: VoidFunction;
	articlesInquiry: IArticlesInquiryDto;
	order: IOrderV2;
}

export const AddArticlesInquiryToExistingOrderForm = ({ confirm, cancel, previous, articlesInquiry, order, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, setLines] = useState<IArticlesInquiryLineDtoWithIsSelected[]>(
		articlesInquiry.lines.filter(t => t.canOrder).map(t => ({ isSelected: true, quantityToOrder: t.quantityToAssign, ...t }))
	);
	const [patch, isSubmitting] = useFormSubmit(articlesInquiriesCommand_addToExistingOrder);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IAddArticlesInquiriesToExistingOrderRequest, helpers: FormikHelpers<IAddArticlesInquiriesToExistingOrderRequest>) => {
		const fLines = lines.filter(t => t.isSelected && t.quantityToOrder > 0);
		if (fLines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await patch({ ...values, lines: fLines.map(mapArticlesInquiryLineDtoWithIsSelectedToLineRequest) });
			if (handleFormResponse(r, helpers)) {
				confirm();
			}
		}
	};

	return (
		<Formik<IAddArticlesInquiriesToExistingOrderRequest>
			validateOnMount
			initialValues={{
				articlesInquiryId: articlesInquiry.id,
				orderId: order.id,
				lines: [],
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					isSubmitting={isSubmitting}
					cancel={cancel}
					lines={lines}
					setLines={setLines}
					previous={previous}
					order={order}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	lines: IArticlesInquiryLineDtoWithIsSelected[];
	setLines: React.Dispatch<React.SetStateAction<IArticlesInquiryLineDtoWithIsSelected[]>>;
	isSubmitting: boolean;
	previous: VoidFunction | undefined;
	order: IOrderV2;
}

const InnerDialog = ({ cancel, lines, setLines, isSubmitting, previous, order, ...rest }: IInnerProps) => {
	const strings = useLocalization();

	return (
		<DialogX {...rest}>
			<DialogTitle>{`${strings.addLinesToExistingOrder}: ${order.reference}`}</DialogTitle>
			<DialogContent
				dividers
				className='df-col fg1'>
				<div className='df-col gap-16'>
					{lines.map((t, index) => (
						<OrderArticlesInquiryLineComponent
							key={t.id}
							line={t}
							index={index}
							setLine={line => setLines(updateArray(t, lines, line))}
						/>
					))}
				</div>
			</DialogContent>
			<CancelSubmitFormDialogActions<IAddArticlesInquiriesToExistingOrderRequest>
				cancel={cancel}
				submitText={strings.addLines}
				isSubmitting={isSubmitting}
				previous={previous}
			/>
		</DialogX>
	);
};
