import { RosaCalendarProviders } from 'app/main/rosaCalendar/RosaCalendarProviders';
import React from 'react';
import { RosaPatientRecordsProviders } from './patients/RosaPatientRecordsProviders';

export const RosaProviders = ({ children }: any) => {
	return (
		<RosaPatientRecordsProviders>
			<RosaCalendarProviders>{children}</RosaCalendarProviders>
		</RosaPatientRecordsProviders>
	);
};
