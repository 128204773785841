import { useContext, useEffect, useMemo, useState } from 'react';
import { DialogsContext } from './DialogsContext';

export function useDialogsContext(reload?: VoidFunction): {
	open: (dialog: JSX.Element) => void;
	confirm: VoidFunction;
	cancel: VoidFunction;
	isStacked: boolean;
	isOpen: boolean;
} {
	const { open, confirm, cancel, stackCount, isOpen: isDialogsOpen } = useContext(DialogsContext);
	const [thisPos, setThisPos] = useState<number>(stackCount);
	const isStacked = useMemo(() => stackCount > thisPos, [stackCount, thisPos]);
	const isOpen = useMemo(() => isDialogsOpen && stackCount >= thisPos, [stackCount, thisPos, isDialogsOpen]);

	useEffect(() => {
		setThisPos(stackCount);
		// eslint-disable-next-line
	}, []);

	const onOpen = (dialog: JSX.Element) => {
		open(dialog, reload);
	};

	return { open: onOpen, confirm, cancel, isStacked, isOpen };
}
