import FrameworkNavigationProvider from 'framework/context/NavigationProvider';
import React from 'react';
import { NavigationContext } from './NavigationContext';
import { InventoryItemDetailTabType } from '../detail/InventoryItemDetailTabType';

export const NavigationProvider = ({ children }: any) => {
	return (
		<FrameworkNavigationProvider<InventoryItemDetailTabType>
			context={NavigationContext}
			fallback='overview'>
			{children}
		</FrameworkNavigationProvider>
	);
};
