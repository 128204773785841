import { Card, CardContent, Theme, useTheme } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { PoundIcon } from 'framework/components/icons/PoundIcon';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

interface IData {
	count: number;
	countError: number;
	countWarningNoError: number;
}

interface IPieData {
	key: string;
	name: string;
	color: string;
	value: number;
}

const formatData = (data: IData, theme: Theme, normalColor: 'primary' | 'grey', strings: IStrings): IPieData[] => {
	const x = [
		{ name: strings.fault, key: 'error', value: data.countError, color: theme.palette.error.main },
		{ name: strings.warning, key: 'warning', value: data.countWarningNoError, color: theme.palette.warning.main },
		{
			name: strings.normal,
			key: 'normal',
			value: data.count - data.countError - data.countWarningNoError,
			color: normalColor === 'grey' ? theme.palette.grey[300] : theme.palette.primary.main,
		},
	];
	return x.filter(t => t.value !== 0);
};

const toColor = (data: IData, theme: Theme): string => {
	if (data.countError > 0) {
		return theme.palette.error.main;
	} else if (data.countWarningNoError > 0) {
		return theme.palette.warning.main;
	} else {
		return theme.palette.text.primary;
	}
};

interface IProps {
	data: IData;
	title: string;
	width?: number;
	chartSize?: number;
	style?: React.CSSProperties;
	formatErrors?: (count: number) => string;
	formatWarnings?: (count: number) => string;
	formatTotal?: (count: number) => string;
	normalColor?: 'primary' | 'grey';
	onNavigate: VoidFunction;
}

export const DashboardWorkflowCard = ({
	title,
	data,
	chartSize = 150,
	width = 210,
	style,
	formatErrors,
	formatWarnings,
	formatTotal,
	normalColor = 'grey',
	onNavigate,
}: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const pieData = useMemo(() => formatData(data, theme, normalColor, strings), [data, theme, normalColor, strings]);

	return (
		<Card
			style={{ minWidth: `${width}px`, height: '100%', ...style }}
			className='df-col fg1'>
			<CardTitleTypography
				title={title}
				withPadding
				action={
					<OpenInNewIcon
						style={{ cursor: 'pointer', color: theme.palette.primary.main }}
						onClick={onNavigate}
					/>
				}
			/>
			<CardContent
				style={{ paddingTop: 0, paddingBottom: 16 }}
				className='df-col fg1'>
				<div className='df-row jc-c'>
					<div style={{ width: `${chartSize}px`, height: `${chartSize}px`, position: 'relative' }}>
						<div
							className='df-row-ac jc-c'
							style={{
								position: 'absolute',
								width: `${chartSize - 40}px`,
								height: `${chartSize - 40}px`,
								left: '20px',
								top: '20px',
								borderRadius: '100%',
							}}>
							<div style={{ fontWeight: 500, fontSize: '32px', color: toColor(data, theme) }}>{data.count}</div>
						</div>
						<ResponsiveContainer>
							<PieChart>
								<Pie
									data={pieData}
									dataKey='value'
									paddingAngle={5}
									innerRadius='85%'
									outerRadius='100%'>
									{pieData.map((t, index) => (
										<Cell
											key={`cell-${index}`}
											fill={t.color}
										/>
									))}
								</Pie>
								<Tooltip />
							</PieChart>
						</ResponsiveContainer>
					</div>
				</div>
				<div
					className='fg1'
					style={{ paddingBottom: 16 }}></div>
				<div className='df-col'>
					{data.countError > 0 && (
						<ErrorLabelledProperty
							error={formatErrors ? formatErrors(data.countError) : `${data.countError} ${strings.faultSingularOrPlural}`}
							variant='caption'
							withoutMargin
						/>
					)}
					{data.countWarningNoError > 0 && (
						<WarningLabelledProperty
							warning={formatWarnings ? formatWarnings(data.countWarningNoError) : `${data.countWarningNoError} ${strings.warningSingularOrPlural}`}
							variant='caption'
							withoutMargin
						/>
					)}
					<LabelledProperty
						property={formatTotal ? formatTotal(data.count) : `${data.count} ${strings.total}`}
						icon={<PoundIcon />}
						variant='caption'
						withoutMargin
					/>
				</div>
			</CardContent>
		</Card>
	);
};
