import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { IOrdersQueryMetadata, OrderPrefilter } from 'gen/ApiClient';
import React from 'react';

export const createOrderPrefilterBadgeChipXRecord = (metadata: IOrdersQueryMetadata): Record<OrderPrefilter, React.ReactNode> => {
	return {
		All: metadata.count > 0 && (
			<BadgeChipX
				value={metadata.count}
				color='default'
			/>
		),
		NotDeliveredYet: metadata.countNotDelivered > 0 && (
			<BadgeChipX
				value={metadata.countNotDelivered}
				color='primary'
			/>
		),
		Overdue: metadata.countOverdue > 0 && (
			<BadgeChipX
				value={metadata.countOverdue}
				color='warning'
			/>
		),
		ToPlaceWithSupplier: metadata.countNotPlacedWithSupplier > 0 && (
			<BadgeChipX
				value={metadata.countNotPlacedWithSupplier}
				color='warning'
			/>
		),
	};
};
