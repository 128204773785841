import { ChipX } from 'framework/components/chips/ChipX';
import { IUserDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { formatLastActivity } from './formatLastActivity';

interface IProps {
	item: IUserDto;
	style?: React.CSSProperties;
}

export const LastActivityStatusChipX = ({ item, style }: IProps): JSX.Element => {
	const strings = useLocalization();
	const date = item.lastActivity;

	if (item.hasConfirmed === false) {
		return (
			<ChipX
				fill
				color='error'
				label={strings.notConfirmed}
				style={style}
			/>
		);
	} else if (date === undefined || date === null) {
		return (
			<ChipX
				color='info'
				label={strings.notActive}
				style={style}
			/>
		);
	} else {
		const r = formatLastActivity(date, strings);
		return (
			<ChipX
				fill={r === strings.activeNow}
				color={r === strings.activeNow ? 'success' : 'info'}
				label={r}
				style={style}
			/>
		);
	}
};
