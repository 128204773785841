import { Divider, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useLoggedInUserIsUserById } from 'app/auth/useLoggedInUserIsUserById';
import { useLoggedInUserIsMinimalInRole } from 'app/auth/useLoggedInUserIsMinimalInRole';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { EmailModelForm } from 'framework/forms/EmailModelForm';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { formatDate } from 'framework/utils/date/formatDate';
import { IUserDto, UserRole, auth_endAllUserSessions, usersCommand_patchEmail, usersCommand_resendRegistrationToken } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { boolToYesNo } from 'shared/utils/boolToString';
import { LocationsPreloadCacheContext } from '../locations/LocationsPreloadCacheContext';
import { AuthSessionListItem } from './AuthSessionListItem';
import { UserChips } from './UserChips';
import { UserDetailsActionsContent } from './UserDetailsActionsContent';
import { ChangeUserRoleForm } from './forms/ChangeUserRoleForm';
import { RizivNumberForm } from './forms/RizivNumberForm';
import { UserFunctionalClaimsForm } from './forms/UserFunctionalClaimsForm';
import { UserNameForm } from './forms/UserNameForm';
import { UserPreferredLocationForm } from './forms/UserPreferredLocationForm';
import { UserToggleIsPlannableDialog } from './forms/UserToggleIsPlannableDialog';
import { UserPageClaimsForm } from './forms/pageClaims/UserPageClaimsForm';
import { accessToPagesToSummary } from './utils/accessToPagesToSummary';
import { functionalClaimsToSummary } from './utils/functionalClaimsToSummary';

interface IProps {
	item: IUserDto;
	reload: VoidFunction;
}

export const UserDetailsContent = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const pageClaimsSummary = React.useMemo(() => accessToPagesToSummary(item, strings), [item, strings]);
	const functionalClaimsSummary = React.useMemo(() => functionalClaimsToSummary(item, strings), [item, strings]);
	const { userRoleRecord } = React.useContext(RecordContext);
	const itsMe = useLoggedInUserIsUserById(item.id);
	const canExecuteAdminActions = useLoggedInUserIsMinimalInRole('Admin');
	const itsMeOrCanExecuteAdminActions = React.useMemo(() => itsMe || canExecuteAdminActions, [itsMe, canExecuteAdminActions]);

	const onChangeEmail = () => {
		if (canExecuteAdminActions) {
			open(
				<EmailModelForm
					open
					initialValue={item.email}
					confirm={confirm}
					cancel={cancel}
					submitFunction={val => usersCommand_patchEmail({ userId: item.id, email: val })}
					formTitle={strings.changeWhat(strings.email)}
					submitText={strings.update}
				/>
			);
		}
	};

	const onEditRiziv = () => {
		if (itsMeOrCanExecuteAdminActions) {
			open(
				<RizivNumberForm
					open
					user={item}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	const onEditNames = () => {
		if (itsMeOrCanExecuteAdminActions) {
			open(
				<UserNameForm
					open
					user={item}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	const onTogglePlannable = () => {
		if (canExecuteAdminActions) {
			open(
				<UserToggleIsPlannableDialog
					open
					confirm={confirm}
					cancel={cancel}
					user={item}
				/>
			);
		}
	};

	const onEndAllSessions = () => {
		if (itsMeOrCanExecuteAdminActions) {
			open(
				<AlertDialog
					open
					title={strings.endAllSessionsForUser}
					content={strings.userWillBeLoggedOutOfAllSessions}
					acceptText={strings.yesWhatExclamation(strings.endAllSessions)}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => auth_endAllUserSessions({ userId: item.id })}
					accept={confirm}
				/>
			);
		}
	};

	const onPreferredLocation = () => {
		if (itsMeOrCanExecuteAdminActions) {
			open(
				<UserPreferredLocationForm
					open
					user={item}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	const onEditPageClaims = () => {
		if (canExecuteAdminActions) {
			open(
				<UserPageClaimsForm
					open
					user={item}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	const onEditRole = () => {
		if (canExecuteAdminActions) {
			open(
				<ChangeUserRoleForm
					open
					user={item}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	const onEditFunctionalClaims = () => {
		if (canExecuteAdminActions) {
			open(
				<UserFunctionalClaimsForm
					open
					user={item}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	const onResendRegistrationToken = () => {
		if (canExecuteAdminActions) {
			open(
				<AlertDialog
					open
					title={strings.requestNew}
					content={strings.resendRegistrationTokenQuestion}
					acceptText={strings.yes}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => usersCommand_resendRegistrationToken(item.id)}
					accept={confirm}
				/>
			);
		}
	};

	return (
		<div className='df-col gap-8'>
			<Typography
				component='div'
				style={{ fontSize: '16px', fontWeight: 500, marginBottom: 8 }}>
				{`${strings.user}: ${item.lastNameCommaFirstName}`}
			</Typography>
			<CaptionVerticalLabelledPropertyX label={strings.createdAt}>{formatDate(item.created)}</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.lastLoggedIn}>{formatDate(item.lastLoggedInDate, 'date-and-time')}</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.lastActivity}>{formatDate(item.lastActivity, 'date-and-time')}</CaptionVerticalLabelledPropertyX>
			<UserChips item={item} />
			<Divider />
			{item.hasConfirmed === false && (
				<>
					<Typography16pxBold>{strings.registration}</Typography16pxBold>
					<CaptionVerticalLabelledPropertyX
						startIcon={<StatusIcon status={item.isRegistrationTokenExpired ? 'error' : 'success'} />}
						label={`${strings.expired}?`}>
						{boolToYesNo(item.isRegistrationTokenExpired, strings)}
					</CaptionVerticalLabelledPropertyX>
					<TextLinkButton
						color='secondary'
						startIcon={<MailOutlineIcon />}
						onClick={onResendRegistrationToken}>
						{strings.renewRegistrationToken}
					</TextLinkButton>
					<Divider />
				</>
			)}
			<Typography16pxBold>{strings.details}</Typography16pxBold>
			<CaptionVerticalLabelledPropertyX
				label={strings.email}
				edit={onChangeEmail}>
				{item.email}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.salutation}
				edit={onEditNames}>
				{item.salutation}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.lastNameCommaFirstName}
				edit={onEditNames}>
				{item.lastNameCommaFirstName}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.conventionedRIZIVNumber}
				edit={onEditRiziv}>
				{item.conventionedRizivNumber}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.defaultWhat(strings.location)}
				edit={onPreferredLocation}>
				{locations.find(t => t.id === item.preferredLocationId)?.identifier}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={`${strings.plannable}?`}
				edit={onTogglePlannable}>
				{boolToYesNo(item.isPlannable, strings)}
			</CaptionVerticalLabelledPropertyX>
			<Divider />
			<Typography16pxBold>{strings.restrictions}</Typography16pxBold>
			<CaptionVerticalLabelledPropertyX
				label={strings.accessToPages}
				edit={onEditPageClaims}>
				{pageClaimsSummary}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.role}
				edit={onEditRole}>
				{userRoleRecord[item.role as UserRole]}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.accessToFunctions}
				edit={onEditFunctionalClaims}>
				{functionalClaimsSummary}
			</CaptionVerticalLabelledPropertyX>
			<Divider />
			<UserDetailsActionsContent
				item={item}
				reload={reload}
			/>
			<Divider />
			{item.authData.length > 0 && (
				<>
					<Typography16pxBold>{strings.sessions}</Typography16pxBold>
					<div className='df-col gap-8'>
						<TextLinkButton
							color='secondary'
							onClick={onEndAllSessions}
							startIcon={<ExitToAppIcon />}>
							{strings.logoutAllSessions}
						</TextLinkButton>
						{item.authData.map(t => (
							<AuthSessionListItem
								item={t}
								reload={reload}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
};
