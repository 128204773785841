import { DialogProps } from '@material-ui/core';
import React from 'react';
import { noahLicense_download } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { DownloadAndInstallSoftwareDialog } from 'shared/downloads/DownloadAndInstallSoftwareDialog';

interface IProps extends DialogProps {
	close: VoidFunction;
}

export const NoahLicenseDownloadDialog = ({ ...rest }: IProps) => {
	const [download, isDownloading] = useDownload(noahLicense_download);

	return (
		<DownloadAndInstallSoftwareDialog
			{...rest}
			download={download}
			isDownloading={isDownloading}
			title={`Download en installeer Noah licentie`}
		/>
	);
};
