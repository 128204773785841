import { DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormAutocompleteMultipleFreeSolo } from 'framework/forms/FormAutocompleteMultipleFreeSolo';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IRegisterSupplierRequest, suppliersCommand_new } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { AddressInput } from 'shared/address/AddressInput';
import * as yup from 'yup';
import { SuppliersFilterDataContext } from '../SuppliersFilterDataContext';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterSupplierRequest>({
			name: yup.string().required(strings.formRequired(strings.name)),
			accountNumber: yup.string(),
			brands: yup.array().of(yup.string().defined()).defined(),
			addressLine: yup.string(),
			zip: yup.string(),
			city: yup.string(),
			country: yup.string(),
			email: yup.string().email(),
			vatNumber: yup.string(),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: (id: string, request: IRegisterSupplierRequest) => void;
	cancel: VoidFunction;
	previous?: VoidFunction;
	name?: string;
}

const stepsRecord: Record<number, (keyof IRegisterSupplierRequest)[]> = {
	0: ['name', 'vatNumber', 'accountNumber', 'email'],
	1: ['addressLine', 'zip', 'city', 'country'],
	2: ['brands'],
};

export const RegisterSupplierForm = ({ confirm, cancel, previous, name, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(suppliersCommand_new);
	const [step, setStep] = useState<number>(0);
	const [data] = useCacheContext(SuppliersFilterDataContext);

	const handleSubmit = async (values: IRegisterSupplierRequest, helpers: FormikHelpers<IRegisterSupplierRequest>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.addedWhat(strings.supplier));
			confirm(r.result.objectId, values);
		}
	};

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterSupplierRequest>
			initialValues={{
				name: name ?? '',
				accountNumber: '',
				brands: [],
				addressLine: '',
				city: '',
				country: '',
				email: '',
				vatNumber: '',
				zip: '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<OverflowDialog {...rest}>
					<DialogTitleWithFormStepper
						title={strings.newSupplier}
						step={step}
						labels={[strings.name, strings.address, strings.brands]}
					/>
					<OverflowDialogContent>
						{step === 0 && (
							<>
								<FormTextField<IRegisterSupplierRequest>
									name='name'
									label={strings.name}
									required
								/>
								<FormTextField<IRegisterSupplierRequest>
									name='accountNumber'
									label={strings.accountNumber}
								/>
								<FormTextField<IRegisterSupplierRequest>
									name='vatNumber'
									label={strings.vatNumber}
								/>
								<FormTextField<IRegisterSupplierRequest>
									name='email'
									label={strings.email}
								/>
							</>
						)}
						{step === 1 && <AddressInput<IRegisterSupplierRequest> optional />}
						{step === 2 && (
							<FormAutocompleteMultipleFreeSolo<IRegisterSupplierRequest>
								name='brands'
								label={strings.brands}
								options={data.brands}
							/>
						)}
					</OverflowDialogContent>
					<PageableFormDialogActions
						step={step}
						stepsRecord={stepsRecord}
						cancel={cancel}
						setStep={setStep}
						submitText={strings.create}
						isSubmitting={isSubmitting}
						schema={createSchema(strings)}
						previous={previous}
					/>
				</OverflowDialog>
			</Form>
		</Formik>
	);
};
