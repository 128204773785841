import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	IQueryResultWithMetadata,
	IRequestForInsuranceAllowance,
	IRfiaQueryMetadata,
	IRfiasQueryParams,
	rfiasQuery_concurrencyToken,
	rfiasQuery_query,
} from 'gen/ApiClient';
import React from 'react';
import { rfiasQueryParamsLsKey } from '../../../../localStorageKeys';
import { RfiasQueryContext } from './RfiasQueryContext';

const DefaultParams: IRfiasQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	isCompleted: undefined,
	hasWarning: undefined,
	statuses: [],
	handoverDateAfter: undefined,
	handoverDateBefore: undefined,
	invoiceProcessStatuses: [],
	isFailedTrial: undefined,
	paymentStatuses: [],
	prescriptionForTrialDateAfter: undefined,
	prescriptionForTrialDateBefore: undefined,
	hasMissingDocuments: undefined,
	audiologistIds: [],
	entPhysicianIds: [],
	locationIds: [],
	warnings: [],
	labels: [],
	medicalPrescriptionSentDateAfter: undefined,
	medicalPrescriptionSentDateBefore: undefined,
};

export const RfiasQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResultWithMetadata<IRequestForInsuranceAllowance, IRfiaQueryMetadata>, IRfiasQueryParams>
			defaultParams={DefaultParams}
			queryF={rfiasQuery_query}
			concurrencyF={rfiasQuery_concurrencyToken}
			localStorageKey={rfiasQueryParamsLsKey}
			context={RfiasQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
