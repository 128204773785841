import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISupplier } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	item: ISupplier;
	style?: React.CSSProperties;
}

export const DetailsCard = ({ item, style }: IProps) => {
	const strings = useLocalization();

	return (
		<Card
			style={style}
			className='df-col'>
			<CardContent>
				<CardTitleTypography title={strings.general} />
				<LabelledProperty
					label={strings.date}
					property={formatDate(item.created)}
				/>
				<CardTitleTypography
					title={strings.overview}
					withPaddingTop
				/>
				<LabelledProperty
					label={strings.total}
					property={formatCurrency(item.amountToPay)}
				/>
				{item.amountPaid > 0 && (
					<LabelledProperty
						label={strings.alreadyPaid}
						property={formatCurrency(item.amountPaid)}
					/>
				)}
				{item.hasOutstandingBalance && (
					<LabelledProperty
						label={strings.outstandingBalance}
						property={formatCurrency(item.outstandingBalance)}
					/>
				)}
			</CardContent>
		</Card>
	);
};
