import { Typography, useTheme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { useStateBoolean } from '../hooks/useStateBool';

const calulateStyle = (withPadding: boolean, withPaddingTop: boolean, paddingLeft: number | undefined, style?: React.CSSProperties): React.CSSProperties => {
	const padding = withPadding ? 16 : 0;
	return {
		position: 'relative',
		padding: padding,
		paddingTop: withPadding || withPaddingTop ? 16 : 0,
		paddingLeft: paddingLeft ? paddingLeft : padding,
		...style,
	};
};

interface IProps {
	title: React.ReactNode;
	withPaddingTop?: boolean;
	withPadding?: boolean;
	edit?: VoidFunction;
	children?: React.ReactNode;
	action?: React.ReactNode;
	editIcon?: React.ReactNode;
	paddingLeft?: number;
	innerStyle?: React.CSSProperties;
	style?: React.CSSProperties;
	secondaryTitle?: JSX.Element;
	removeActionPaddingRight?: boolean;
	navigate?: VoidFunction;
	fontWeight?: 500 | 'inherit';
	onClick?: VoidFunction;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
}

export const CardTitleTypography = ({
	navigate,
	title,
	withPaddingTop = false,
	withPadding = false,
	edit,
	children,
	action,
	editIcon,
	paddingLeft,
	innerStyle,
	style,
	secondaryTitle,
	removeActionPaddingRight = false,
	fontWeight = 500,
	onClick,
	startIcon,
	endIcon,
}: IProps) => {
	const [isHover, enter, leave] = useStateBoolean(false);
	const theme = useTheme();

	return (
		<div
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={onClick}
			// style={{ position: 'relative', padding: withPadding ? theme.spacing(2) : '0px', paddingTop: (withPaddingTop || withPadding) ? theme.spacing(2) : '0px' }}
			style={calulateStyle(withPadding, withPaddingTop, paddingLeft, style)}>
			{action && <div style={{ position: 'absolute', top: theme.spacing(1), right: removeActionPaddingRight ? 0 : theme.spacing(1) }}>{action}</div>}
			<div
				className='df-row-ac'
				style={innerStyle}>
				{startIcon && startIcon}
				<Typography
					component='div'
					style={{ fontSize: '16px', fontWeight: fontWeight }}>
					{title}
				</Typography>
				{endIcon && endIcon}
				{secondaryTitle && secondaryTitle}
				{navigate && (
					<OpenInNewIcon
						fontSize='small'
						style={{ cursor: 'pointer', color: theme.palette.primary.main, marginLeft: theme.spacing(1) }}
						onClick={navigate}
					/>
				)}
				{edit && (
					<div
						className='df-col-ac icon-wrapper'
						style={{
							marginLeft: theme.spacing(1),
							fontSize: '20px',
							color: theme.palette.primary.main,
							cursor: 'pointer',
							visibility: isHover ? 'inherit' : 'hidden',
						}}
						onClick={edit}>
						{editIcon ? editIcon : <EditIcon />}
					</div>
				)}
			</div>
			{children}
		</div>
	);
};
