import { IconButton, Typography, useTheme } from '@material-ui/core';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import React from 'react';
import { FileDownloadOutlineIcon } from 'framework/components/icons/FileDownloadOutlineIcon';
import { FileSearchOutlineIcon } from 'framework/components/icons/FileSearchOutlineIcon';
import { isFirst } from 'framework/utils/array/isFirst';
import { isLast } from 'framework/utils/array/isLast';
import { formatDate } from 'framework/utils/date/formatDate';
import { ITemplateHistory, templatesQuery_historyDownload } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useTemplateEditor } from 'shared/dialogs/templates/useTemplateEditor';

interface IProps {
	id: string;
	history: ITemplateHistory[];
	style?: React.CSSProperties;
}

export const TemplateHistoryTimeline = ({ id, history, style }: IProps) => {
	const theme = useTheme();
	const [openEditor] = useTemplateEditor();
	const [download] = useDownload(templatesQuery_historyDownload);

	const onView = async (historyId: string) => {
		openEditor(id, historyId);
	};

	return (
		<>
			{/* <PdfViewerDialog open={isPdfOpen} close={closePdf} id={id} historyId={historyId} fromHistory title="Historiek" /> */}
			<Timeline style={{ padding: 0, margin: 0, ...style }}>
				{history.map(t => (
					<TimelineItem key={t.id}>
						<TimelineOppositeContent>
							<Typography
								color='textSecondary'
								variant='caption'>
								{formatDate(t.date, 'date-and-time')}
							</Typography>
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineDot
								variant='outlined'
								color='primary'
							/>
							{isLast(t, history) === false && <TimelineConnector />}
						</TimelineSeparator>
						<TimelineContent>
							<div
								className='df-row-ac'
								style={{ marginTop: isFirst(t, history) ? 0 : '-5px' }}>
								<div>Aangepast</div>
								{isFirst(t, history) === false && (
									<div
										className='df-row-ac'
										style={{ marginLeft: theme.spacing(1) }}>
										<IconButton
											size='small'
											onClick={() => onView(t.id)}>
											<FileSearchOutlineIcon fontSize='small' />
										</IconButton>
										<IconButton
											size='small'
											onClick={() => download(id, t.id)}>
											<FileDownloadOutlineIcon fontSize='small' />
										</IconButton>
									</div>
								)}
							</div>
						</TimelineContent>
					</TimelineItem>
				))}
			</Timeline>
		</>
	);
};
