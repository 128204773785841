import _ from 'lodash';

export function calculateTotalsFromAmountInVat<TLine extends { unitPrice: number; quantity: number; vatPercentage: number }>(
	lines: TLine[],
	isShiftVat: boolean
): { totalInVat: number; totalExVat: number } {
	const exVat = _.sum(lines.map(t => (t.unitPrice * t.quantity) / (1 + t.vatPercentage / 100)));
	const inVat = _.sum(lines.map(t => t.unitPrice * t.quantity));

	return {
		totalInVat: isShiftVat ? exVat : inVat,
		totalExVat: exVat,
	};
}
