import { Dialog, DialogContent, DialogProps, useTheme } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { FormSelectFieldOnId } from 'framework/forms/FormSelectFieldOnId';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { formatDate } from 'framework/utils/date/formatDate';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import {
	audiogramStandardsQuery_withSpeechByPatient,
	IAudiogramStandardWithSpeechAudiogramsDto,
	ISpeechAudiogram,
	ISpeechImageDto,
	ISpeechImageModel,
	ISpeechModel,
	ISpeechPointModel,
	speechChartsQuery_create,
	trialReportsCommand_addSpeechImages,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { ISpeechModelWithSelection } from './ISpeechModelWithSelection';
import { SelectSpeechAudiogramForImagesTable } from './SelectSpeechAudiogramForImagesTable';
import { SpeechImagesList } from './SpeechImagesList';

interface IModel extends ISpeechImageModel {
	audiogramStandardId: string;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			audiogramStandardId: yup.string().required(),
			tests: yup.mixed(),
		})
		.defined();
};

const emptyValues: IModel = {
	audiogramStandardId: '',
	tests: [],
};

const stepsRecord: Record<number, (keyof IModel)[]> = {
	0: ['audiogramStandardId'],
	1: ['tests'],
};

const mapToModel = (t: ISpeechModelWithSelection): ISpeechModel => {
	return {
		isAided: t.isAided,
		isHeadphones: t.isHeadphones,
		leftRightOrStereo: t.leftRightOrStereo,
		points: t.points,
	};
};

const mapToTest = (t: ISpeechAudiogram): ISpeechModelWithSelection => {
	return {
		isAided: t.isAided,
		isHeadphones: t.isHeadphones,
		isSelected: false,
		leftRightOrStereo: t.leftRightOrStereo,
		points: t.orderedPoints.map<ISpeechPointModel>(x => ({ intensity: x.intensity, scorePercentage: x.scorePercentage })),
		speechAudiogram: t,
	};
};

interface IProps extends DialogProps {
	patientId: string;
	trialId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const SpeechImagesForm = ({ patientId, trialId, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(trialReportsCommand_addSpeechImages);
	const [step, setStep] = useState<number>(0);
	const [audiograms] = useApiEffect(audiogramStandardsQuery_withSpeechByPatient, patientId);
	const [tests, setTests] = useState<ISpeechModelWithSelection[]>([]);

	if (audiograms === undefined) {
		return <div></div>;
	}

	if (audiograms.length === 0) {
		return (
			<NotificationDialog
				open
				title={strings.insufficientInformation}
				content={strings.noSpeechTestRecordedCreateOneFirst}
				close={cancel}
				closeText={strings.understood}
			/>
		);
	}

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await create(trialId, { tests: tests.filter(t => t.isSelected).map(t => mapToModel(t)) });
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.addedWhat(strings.speechGraphs));
			confirm();
		}
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					audiograms={audiograms}
					tests={tests}
					setTests={setTests}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	audiograms: IAudiogramStandardWithSpeechAudiogramsDto[];
	setTests: React.Dispatch<React.SetStateAction<ISpeechModelWithSelection[]>>;
	tests: ISpeechModelWithSelection[];
}

const InnerDialog = ({ step, setStep, cancel, audiograms, isSubmitting, tests, setTests, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IModel>();
	const strings = useLocalization();
	const theme = useTheme();
	const imageCall = useApiCall(speechChartsQuery_create);
	const [images, setImages] = useState<ISpeechImageDto[]>([]);
	const selectedAudiogram = useMemo(
		() => (isNotNullNorUndefined(props.values.audiogramStandardId) ? audiograms.find(t => t.id === props.values.audiogramStandardId) : undefined),
		[audiograms, props.values.audiogramStandardId]
	);

	useEffect(() => {
		// by default ordered -> just select the first by default
		if (audiograms.length > 0) {
			setFormValue<IModel>('audiogramStandardId', audiograms[0].id, props);
		}
		// eslint-disable-next-line
	}, [audiograms]);

	useEffect(() => {
		if (selectedAudiogram) {
			setTests(selectedAudiogram.speechAudiograms.map(t => mapToTest(t)));
		}
		// eslint-disable-next-line
	}, [selectedAudiogram]);

	const validateMore = () => {
		return true;
		// if (selectedAudiogram === null || selectedAudiogram === undefined) {
		//     setFieldError<IModel>('audiogramStandardId', "Selecteer een test", props);
		//     return false;
		// } else if (selectedAudiogram.countSpeechAudiograms === 0) {
		//     setFieldError<IModel>('audiogramStandardId', 'Selecteer een test mét spraakaudiogrammen', props);
		//     return false;
		// } else {
		//     return true;
		// }
	};

	const onLoadImages = async () => {
		const x1 = tests.filter(t => t.isSelected).map(t => mapToModel(t));
		console.log(x1);
		const r = await imageCall({ tests: tests.filter(t => t.isSelected).map(t => mapToModel(t)) });
		if (r.isSuccess) {
			setImages(r.result);
		}
	};

	const onSetTests = (val: ISpeechModelWithSelection[]) => {
		setImages([]);
		setTests(val);
	};

	return (
		<Dialog
			{...rest}
			// maxWidth={step === 1 ? 'md' : 'sm'}
			maxWidth='lg'>
			<DialogTitleWithFormStepper
				title={strings.speechGraphs}
				step={step}
				labels={[strings.audiogram, strings.select]}
			/>
			<DialogContent
				className='df-col'
				dividers>
				{step === 0 && (
					<>
						<FormSelectFieldOnId<IModel, IAudiogramStandardWithSpeechAudiogramsDto>
							name='audiogramStandardId'
							label={strings.audiogram}
							options={audiograms}
							renderValue={t => formatDate(t.noahLastModifiedDate, 'date-and-time')}
							getKey={t => t.id}
							helperText={strings.dateLastChangedInNoah}
						/>
						<DividerWithCaption caption={strings.details} />
						{selectedAudiogram && (
							<>
								<LabelledProperty
									label={`# ${strings.speechAudiograms}`}
									property={selectedAudiogram.countSpeechAudiograms.toString()}
								/>
								<LabelledProperty
									label={`# ${strings.speechAudiograms} (${strings.left})`}
									property={selectedAudiogram.speechAudiograms.filter(t => t.isLeft).length.toString()}
								/>
								<LabelledProperty
									label={`# ${strings.speechAudiograms} (${strings.right})`}
									property={selectedAudiogram.speechAudiograms.filter(t => t.isRight).length.toString()}
								/>
								<LabelledProperty
									label={`# ${strings.speechAudiograms} (${strings.stereo})`}
									property={selectedAudiogram.speechAudiograms.filter(t => t.isStereo).length.toString()}
								/>
							</>
						)}
					</>
				)}
				{step === 1 && selectedAudiogram && (
					<>
						<SelectSpeechAudiogramForImagesTable
							tests={tests}
							setTests={onSetTests}
						/>
						<DividerWithCaption
							caption={strings.graphs}
							style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
							action={
								<SmallPrimaryTextButton
									startIcon={<SyncIcon />}
									onClick={onLoadImages}>
									Genereer grafieken
								</SmallPrimaryTextButton>
							}
						/>
						<SpeechImagesList images={images} />
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
				validateMore={validateMore}
			/>
		</Dialog>
	);
};
