import { MultipleValuesTextField } from 'app/main/inventoryManagement/productCatalog/forms/articlePropertyDefinitions/MultipleValuesTextField';
import React from 'react';
import { AutocompleteMultiple } from './AutocompleteMultiple';
import { AutocompleteSingle } from './AutocompleteSingle';
import { IArticlePropertyLine } from './IArticlePropertyLine';

interface IProps {
	line: IArticlePropertyLine;
	setLine: (val: IArticlePropertyLine) => void;
	style?: React.CSSProperties;
}

export const PropertyField = ({ line, setLine, style }: IProps) => {
	if (line.apd.hasOptions && line.apd.allowMultiple) {
		return (
			<AutocompleteMultiple
				options={line.apd.options}
				values={line.values}
				setValues={vals => setLine({ ...line, values: vals })}
				style={style}
			/>
		);
	} else if (line.apd.hasOptions) {
		return (
			<AutocompleteSingle
				options={line.apd.options}
				value={line.values.length > 0 ? line.values[0] : ''}
				setValue={val => setLine({ ...line, values: [val] })}
				style={style}
			/>
		);
	} else if (line.apd.allowMultiple) {
		return (
			<MultipleValuesTextField
				values={line.values}
				setValues={vals => setLine({ ...line, values: vals })}
				style={style}
				autocompleteOptions={line.autoCompleteOptions}
			/>
		);
	} else {
		return (
			<AutocompleteSingle
				freeSolo
				value={line.values.length > 0 ? line.values[0] : ''}
				setValue={val => setLine({ ...line, values: [val] })}
				style={style}
				options={line.autoCompleteOptions}
			/>
		);
	}
};
