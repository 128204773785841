import React from 'react';
import { TroubleshootTabType } from './TroubleshootTabType';

interface IContext {
	selectedTab: TroubleshootTabType;
	setSelectedTab: React.Dispatch<React.SetStateAction<TroubleshootTabType>>;
	TabTypeRecord: Record<TroubleshootTabType, string>;
}

export const TroubleshootPageViewContext = React.createContext<IContext>(undefined as any);
