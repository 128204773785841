import { IApiErrorStrings } from './IApiErrorString';

export const enApiErrorStrings: IApiErrorStrings = {
	error401: 'You are not (correctly) logged in, please log in and try again.\n[Status code 401]',
	error403: 'You are not authorized to perform this operation.\n[Status code 403]',
	error500: 'An unknown error has occurred.\nPlease try again!\n[Status code 500]',
	errorUnmapped: (statusCode: string) => `An unknown error has occurred.\nPlease try again!\n[Status code ${statusCode}]`,
	errorUnknown: 'An unknown error has occurred.\nPlease try again!',
	errorNoNetwork: 'No connection to the server could be established.\nCheck your network connection.',
};
