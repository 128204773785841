import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { UploadIcon } from 'framework/components/icons/UploadIcon';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IFile } from 'framework/utils/IFile';
import { noahLicense_check, noahLicense_download, noahLicense_upload } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import React from 'react';
import { UploadFileDialog } from 'shared/dialogs/upload/UploadFileDialog';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

export const NoahLicenseDetails = () => {
	const [hasLicense, reload] = useApiEffect(noahLicense_check);
	const [download] = useDownload(noahLicense_download);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [upload, isUploading] = useFormSubmit(noahLicense_upload);

	const onUpload = () => {
		open(
			<UploadFileDialog
				open
				cancel={cancel}
				confirm={onFileSelected}
				accept='exe'
				title='Select noah license file (naam moet eindigen met .exe en het woord license bevatten)'
				isUploading={isUploading}
			/>
		);
	};

	const onFileSelected = async (file: IFile) => {
		await upload({ fileName: file.fileName, contentAsBase64: file.contentAsBase64 });
		confirm();
	};

	return (
		<>
			<Typography16pxBold>{`Noah license`}</Typography16pxBold>
			<VariantLabelledPropertyX variant={hasLicense ? 'success' : 'error'}>
				{hasLicense ? `License available for download` : `No license available for download`}
			</VariantLabelledPropertyX>
			<TextLinkButton
				color='primary'
				startIcon={<UploadIcon />}
				onClick={onUpload}>
				{`Upload license`}
			</TextLinkButton>
			<TextLinkButton
				color='primary'
				startIcon={<GetAppOutlinedIcon />}
				onClick={() => download()}
				disabled={hasLicense === false}>
				{`Download license`}
			</TextLinkButton>
		</>
	);
};
