import { Button, Menu, MenuItem } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { useCurrentLocation } from 'app/main/settings/locations/useCurrentLocation';
import { UserDetailDialog } from 'app/main/settings/users/detail/UserDetailDialog';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { LoginRoute } from 'routes';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

export const UserButtonAndMenu = () => {
	const user = useLoggedInUser();
	const [anchor, openMenu, closeMenu] = useAnchorElement();
	const strings = useLocalization();
	const { push } = useHistory();
	const { open, cancel } = useDialogsContext();
	const location = useCurrentLocation();

	const onUserDetail = (id: string) => {
		open(
			<UserDetailDialog
				open
				id={id}
				close={cancel}
			/>
		);
	};

	return (
		<>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={closeMenu}
				keepMounted
				getContentAnchorEl={null}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
				<MenuItem
					onClick={() => {
						if (user) {
							onUserDetail(user.id);
							closeMenu();
						}
					}}>
					{strings.profile}
				</MenuItem>
				<MenuItem onClick={() => push(LoginRoute)}>{strings.logout}</MenuItem>
			</Menu>
			<Button
				size='large'
				variant='text'
				color='inherit'
				disableElevation
				endIcon={<AccountCircle />}
				onClick={openMenu}>
				<span>
					{user?.firstName} | {location?.identifier}
				</span>
			</Button>
		</>
	);
};
