import { usePdfViewerWithUrl } from 'framework/pdf/context/usePdfViewerWithUrl';
import { useContext } from 'react';
import { RecordContext } from '../records/RecordContext';
import { DocumentationType } from './DocumentationType';
import { DocumentationTypeUrlRecord } from './DocumentationTypeUrlRecord';

export const useViewDocumentation = (file: DocumentationType) => {
	const openPdf = usePdfViewerWithUrl();
	const { documentationTypeRecord } = useContext(RecordContext);

	const onView = async () => {
		openPdf(documentationTypeRecord[file], DocumentationTypeUrlRecord[file]);
	};

	return onView;
};
