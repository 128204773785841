import { Fade, InputAdornment } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';
import { useStateBoolean } from '../../hooks/useStateBool';
import { matchNullOrEmptyToUndefined } from '../../utils/matchNullOrEmptyToUndefined';
import { NumberFormatTextField } from './NumberFormatTextField';

interface IProps {
	label: string;
	value: number | undefined;
	disabled?: boolean;
	onChange: (val: number | undefined) => void;
	style?: React.CSSProperties;
	allowNegative?: boolean;
}

export const MoneyTextField = ({ label, value, onChange, disabled = false, style, allowNegative }: IProps) => {
	const [isFocused, focus, blur] = useStateBoolean(false);
	const [isHovered, enter, leave] = useStateBoolean(false);

	return (
		<NumberFormatTextField
			style={style}
			onFocus={focus}
			onBlur={blur}
			onMouseEnter={enter}
			onMouseLeave={leave}
			variant='filled'
			fullWidth
			value={value !== undefined ? value : null} //trick to render it
			label={label}
			onChange={e => onChange(matchNullOrEmptyToUndefined<number>(e.target.value))}
			InputProps={{
				startAdornment: (isFocused || (value !== undefined && value !== null)) && <InputAdornment position='start'>&euro;</InputAdornment>,
				endAdornment: (
					<InputAdornment position='end'>
						<Fade in={isHovered && value !== undefined && disabled === false}>
							<CancelIcon
								color='primary'
								style={{ cursor: 'pointer' }}
								onClick={() => onChange(undefined)}
							/>
						</Fade>
					</InputAdornment>
				),
			}}
			allowNegative={allowNegative}
			decimalScale={2}
		/>
	);
};
