import { DialogContent, DialogProps } from '@material-ui/core';
import { FormSelectOrCreateCompanyFieldWithSelector } from 'app/main/contacts/companies/forms/FormSelectOrCreateCompanyFieldWithSelector';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setLineErrors } from 'framework/forms/utils/setLineErrors';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	IMedicalTreatmentFeeDto,
	IRegisterRfiaHandoverSingleRequest,
	IRequestForInsuranceAllowance,
	rfiasHandoverCommand_registerHandoverSingle,
	salesQuery_nextInvoiceReference,
	salesQuery_settings,
	ValidUntilTimeUnits,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { FormRecipientField } from 'shared/forms/FormRecipientField';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useWithRecipientInterceptor } from 'shared/searchAndSelect/useWithRecipientInterceptor';
import { calculateMustLinkAnItemToAssure } from 'shared/searchAndSelect/utils/calculateMustLinkAnItemToAssure';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { useCurrentLocation } from '../../../../settings/locations/useCurrentLocation';
import { SearchProvider } from './context/SearchProvider';
import { FeesTable } from './FeesTable';
import { IHandoverLine } from './IHandoverLine';
import { SearchAndSelectProductsForHandover } from './SearchAndSelectProductsForHandover';
import { SingleHandoverConfirmation } from './SingleHandoverConfirmation';
import { calculateHasRizivCodeWarning } from './utils/calculateHasRizivCodeWarning';
import { lineToModel } from './utils/lineToModel';
import { mustLinkAnInventoryItem } from './utils/mustLinkAnInventoryItem';

const createSchema = (strings: IStrings, mustSelectCompany: boolean) => {
	return yup
		.object<IRegisterRfiaHandoverSingleRequest>({
			rfiaId: yup.string().required(),
			lines: yup.mixed(),
			validUntil: yup.date().required(strings.formRequired(strings.validTo)),
			reference: yup.string().required(strings.formRequired(strings.reference)),
			isAutoSelectReference: yup.boolean().defined(),
			zip: yup.string(),
			addressLine: yup.string(),
			city: yup.string(),
			companyId: mustSelectCompany ? yup.string().required(strings.formRequired(strings.vatCustomer)) : yup.string(),
			country: yup.string(),
			recipientName: yup.string().required(strings.formRequired(strings.recipient)),
			handoverDate: yup.date().required(strings.formRequired(strings.handoverDate)),
			fees: yup.mixed(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IRegisterRfiaHandoverSingleRequest)[]> = {
	0: ['handoverDate', 'fees'],
	1: ['reference', 'isAutoSelectReference', 'validUntil'],
	2: ['companyId'],
	3: ['recipientName', 'addressLine', 'city', 'zip', 'country'],
	4: ['lines'],
	5: [],
};

interface IProps extends DialogProps {
	confirm: (salesId: string, handoverDate: Date) => void;
	cancel: VoidFunction;
	rfia: IRequestForInsuranceAllowance;
}

export const SingleHandoverForm = ({ confirm, cancel, rfia, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(rfiasHandoverCommand_registerHandoverSingle);
	const [step, setStep] = useState<number>(0);
	const [lines, setLines] = useState<IHandoverLine[]>([]);
	const [fees, setFees] = useState<IMedicalTreatmentFeeDto[]>([]);
	const [isSelectCompany, setIsSelectCompany] = useState<boolean>(false);
	const schema = useMemo(() => createSchema(strings, isSelectCompany), [strings, isSelectCompany]);
	const { open, confirm: accept, cancel: reject } = useDialogsContext();
	const now = useNow();

	const handleSubmit = async (values: IRegisterRfiaHandoverSingleRequest, helpers: FormikHelpers<IRegisterRfiaHandoverSingleRequest>) => {
		// TODO meer controleren! -> max. value of stockItems will be max value available at location!
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else if (lines.filter(t => mustLinkAnInventoryItem(t)).length > 0) {
			notify(strings.selectSerialNumbersForSelectedProducts, 'error');
		} else if (lines.filter(t => calculateMustLinkAnItemToAssure(t)).length > 0) {
			notify(strings.selectItemsToAssure, 'error');
		} else if (lines.filter(t => calculateHasRizivCodeWarning(t)).length > 0) {
			open(
				<AlertDialog
					open
					title={strings.confirmation}
					content={strings.notAllRIZIVCodesAreSelectedDoYouWantToProceed}
					acceptText={strings.yesCommaContinue}
					rejectText={strings.noCommaGoBack}
					accept={() => {
						accept();
						onSubmit(values, helpers);
					}}
					reject={reject}
				/>
			);
		} else {
			onSubmit(values, helpers);
		}
	};

	const onSubmit = async (values: IRegisterRfiaHandoverSingleRequest, helpers: FormikHelpers<IRegisterRfiaHandoverSingleRequest>) => {
		const r = await create({ ...values, lines: lines.map((t, index) => lineToModel(t, index)), fees: fees });
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.handoverRegistered);
			confirm(r.result.salesId, values.handoverDate);
		} else {
			setLineErrors('lines', r, lines, setLines);
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterRfiaHandoverSingleRequest>
			validateOnMount
			initialValues={{
				rfiaId: rfia.id,
				handoverDate: now,
				lines: [],
				reference: '',
				isAutoSelectReference: true,
				city: '',
				companyId: '',
				addressLine: '',
				zip: '',
				country: '',
				recipientName: '',
				validUntil: undefined as any,
				fees: [],
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider patientId={rfia.patientId}>
					<InnerDialog
						step={step}
						setStep={setStep}
						lines={lines}
						setLines={setLines}
						cancel={cancel}
						isSubmitting={isSubmitting}
						schema={schema}
						isSelectCompany={isSelectCompany}
						setIsSelectCompany={setIsSelectCompany}
						rfia={rfia}
						fees={fees}
						setFees={setFees}
						{...rest}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	lines: IHandoverLine[];
	setLines: React.Dispatch<React.SetStateAction<IHandoverLine[]>>;
	isSubmitting: boolean;
	cancel: VoidFunction;
	schema: yup.ObjectSchema<IRegisterRfiaHandoverSingleRequest>;
	isSelectCompany: boolean;
	setIsSelectCompany: React.Dispatch<React.SetStateAction<boolean>>;
	rfia: IRequestForInsuranceAllowance;
	fees: IMedicalTreatmentFeeDto[];
	setFees: (fees: IMedicalTreatmentFeeDto[]) => void;
}

const InnerDialog = ({
	rfia,
	step,
	lines,
	setLines,
	setStep,
	isSubmitting,
	cancel,
	schema,
	isSelectCompany,
	setIsSelectCompany,
	fees,
	setFees,
	...rest
}: IInnerDialogProps) => {
	const props = useFormikContext<IRegisterRfiaHandoverSingleRequest>();
	const strings = useLocalization();
	const location = useCurrentLocation();
	const [settings] = useApiEffect(salesQuery_settings);
	const [isUseContactPerson, setIsUseContactPerson] = useState<boolean>(false);
	useWithRecipientInterceptor<IRegisterRfiaHandoverSingleRequest>(props, isSelectCompany, isUseContactPerson, rfia.patientId);
	useNextReferenceInterceptor(salesQuery_nextInvoiceReference, props);

	if (location === undefined || settings === undefined) {
		return <div></div>;
	}

	return (
		<FullScreenDialogWithStepper
			{...rest}
			title={strings.registerHandover}
			step={step}
			labels={[strings.date, strings.referenceShort, strings.vatCustomer, strings.withRespectToShort, strings.items, strings.confirmation]}
			fullScreenStep={4}
			maxWidth='lg'>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormDatePicker<IRegisterRfiaHandoverSingleRequest>
							name='handoverDate'
							label={strings.handoverDate}
							helperText={strings.thisDateWillBeUsedToCalculateNomenclaturePrices}
						/>
						{props.values.handoverDate !== undefined && (
							<>
								<DividerWithCaption caption={strings.refunds} />
								<FeesTable
									rfia={rfia}
									handOverDate={props.values.handoverDate}
									fees={fees}
									setFees={setFees}
								/>
							</>
						)}
					</>
				)}
				{step === 1 && (
					<>
						<FormSingleCheckboxField<IRegisterRfiaHandoverSingleRequest>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IRegisterRfiaHandoverSingleRequest>
							name='reference'
							label={strings.invoiceNumber}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormDatePickerWithUnit<IRegisterRfiaHandoverSingleRequest>
							name='validUntil'
							label={strings.validTo}
							required
							units={ValidUntilTimeUnits}
							defaultUnit='Months'
							defaultValue={settings.defaultValidUntil}
						/>
					</>
				)}
				{step === 2 && (
					<FormSelectOrCreateCompanyFieldWithSelector<IRegisterRfiaHandoverSingleRequest>
						isSelectCompany={isSelectCompany}
						setIsSelectCompany={setIsSelectCompany}
						patientId={rfia.patientId}
					/>
				)}
				{step === 3 && (
					<FormRecipientField<IRegisterRfiaHandoverSingleRequest>
						patientId={rfia.patientId}
						isUseContactPerson={isUseContactPerson}
						setIsUseContactPerson={setIsUseContactPerson}
					/>
				)}
				{step === 4 && (
					<SearchAndSelectProductsForHandover
						rfia={rfia}
						lines={lines}
						setLines={setLines}
						location={location}
						fees={fees}
						mode='handover'
						customerAccountId={props.values.companyId ? props.values.companyId : rfia.patientId}
						handoverDate={new Date(props.values.handoverDate!)}
					/>
				)}
				{step === 5 && (
					<SingleHandoverConfirmation
						lines={lines}
						fees={fees}
						rfia={rfia}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
				validateMore={() => (step === 0 ? fees.length === rfia.allMedicalTreatmentNomenclatureCodes.length : true)}
			/>
		</FullScreenDialogWithStepper>
	);
};
