import { Card, useTheme } from '@material-ui/core';
import React from 'react';
import { DefaultCardContent } from 'framework/components/DefaultCardContent';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	title?: string;
	style?: React.CSSProperties;
	children: any;
}

export const DefaultActionsCard = ({ title, style, children }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();

	return (
		<Card
			style={style}
			className='df-col'>
			<DefaultCardContent className='df-col-as'>
				<CardTitleTypography
					title={title ?? strings.actions}
					innerStyle={{ marginBottom: theme.spacing(0.5) }}
				/>
				{children}
			</DefaultCardContent>
		</Card>
	);
};
