import { Card, useTheme } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DefaultCardContent } from 'framework/components/DefaultCardContent';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { IInventoryItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RizivRefundableHearingInstrumentChip } from 'shared/rizivRefundableHearingInstruments/RizivRefundableHearingInstrumentChip';
import { LinkRizivRefundableHearingInstrumentForm } from '../forms/LinkRizivRefundableHearingInstrumentForm';

interface IProps {
	item: IInventoryItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const RizivRefundableDeviceLinkCard = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onLink = () => {
		open(
			<LinkRizivRefundableHearingInstrumentForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	return (
		<Card
			style={style}
			className='df-col'>
			<DefaultCardContent>
				<CardTitleTypography
					title={`${strings.refund} ${strings.RIZIV}`}
					edit={onLink}
					style={{ marginBottom: theme.spacing(1) }}
				/>
				{item.hasRizivHearingAidDefinitionV2CodeSet === true && (
					<RizivRefundableHearingInstrumentChip
						identificationCode={item.rizivHearingAidDefinitionV2IdentificationCode!}
						reload={reload}
					/>
				)}
				{item.hasRizivHearingAidDefinitionV2CodeSet === false && <NoRecordsFound>{strings.noCodesLinkedYet}</NoRecordsFound>}
			</DefaultCardContent>
		</Card>
	);
};
