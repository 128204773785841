import { Divider, Typography, useTheme } from '@material-ui/core';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import { LightTooltip } from 'framework/components/LightTooltip';
import { INoahActionGroupDisplay } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ActionGroupDetailDialog } from '../../noah/ActionGroupDetailDialog';
import { ActionGroupTooltipContent } from '../../noah/ActionGroupTooltipContent';

interface IProps {
	actionGroup: INoahActionGroupDisplay;
	reload: VoidFunction;
	patientIndex: number;
}

export const NoahActionGroup = ({ actionGroup, reload, patientIndex }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { open, confirm } = useDialogsContext(reload);

	const onView = () => {
		open(
			<ActionGroupDetailDialog
				open
				actionGroup={actionGroup}
				patientIndex={patientIndex}
				close={confirm}
			/>
		);
	};

	return (
		<LightTooltip title={<ActionGroupTooltipContent actionGroup={actionGroup} />}>
			<div
				className='df-row-ac'
				style={{
					marginLeft: theme.spacing(3),
					marginTop: theme.spacing(0.5),
					marginBottom: theme.spacing(0.5),
					cursor: 'pointer',
				}}
				onClick={onView}>
				<ChromeReaderModeIcon
					fontSize='small'
					style={{ color: theme.palette.text.secondary }}
				/>
				<div
					style={{
						marginLeft: theme.spacing(1),
						textDecoration: actionGroup.areAllDeleted ? 'line-through' : 'inherit',
						whiteSpace: 'nowrap',
					}}>
					{actionGroup.firstManufacturer}
				</div>
				<Typography
					variant='caption'
					style={{
						marginLeft: 8,
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						color: theme.palette.text.secondary,
						fontWeight: 'bold',
					}}>
					<b>{actionGroup.firstUserInitials}</b>
				</Typography>
				<Divider
					orientation='vertical'
					flexItem
					style={{ margin: 4 }}
				/>
				<Typography
					variant='caption'
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						color: theme.palette.text.secondary,
					}}>
					{actionGroup.actions.length > 1 ? `${actionGroup.actions.length} ${strings.actions.toLowerCase()}` : actionGroup.singleDescriptionOrDefault}
				</Typography>
			</div>
		</LightTooltip>
	);
};
