import { IApiErrorStrings } from './IApiErrorString';

export const frApiErrorStrings: IApiErrorStrings = {
	error401: `Vous n'êtes pas (correctement) connecté, veuillez vous connecter et réessayer.\n[Code d'état 401]`,
	error403: `Vous n'êtes pas autorisé à effectuer cette opération.\n[Code d'état 403]`,
	error500: `Une erreur inconnue s'est produite.\nVeuillez réessayer!\n[Code d'état 500]`,
	errorUnmapped: (statusCode: string) => `Une erreur inconnue s'est produite.\nVeuillez réessayer!\n[Code d'état ${statusCode}]`,
	errorUnknown: `Une erreur inconnue s'est produite.\nVeuillez réessayer!`,
	errorNoNetwork: `Aucune connexion au serveur n'a pu être établie.\nVérifiez votre connexion réseau.`,
};
