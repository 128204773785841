import { Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { SuccessLabelledProperty } from 'framework/components/labelledProperty/SuccessLabelledProperty';
import { VariantLabelledProperty } from 'framework/components/labelledProperty/VariantLabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { formatDateX } from 'framework/utils/date/formatDateX';
import { rosaIntegrationQuery_status } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RosaRoute } from '../integrations/IntegrationsRoutes';

interface IProps extends IReloadTriggerProps {
	className?: string;
	style?: React.CSSProperties;
}

export const RosaDashboardCard = ({ style, className, reloadTrigger }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const [data, reload] = useApiEffect(rosaIntegrationQuery_status);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	const onViewUsers = () => {
		push(RosaRoute);
	};

	const onViewPatients = () => {
		push(RosaRoute);
	};

	if (data === undefined) {
		return <></>;
	}

	return (
		<Card
			className={className}
			style={style}>
			<CardTitleTypography
				title={strings.rosaIntegration}
				withPadding
			/>
			<CardContent style={{ paddingTop: 0, paddingBottom: 16 }}>
				<div
					className='df-col'
					style={style}>
					<SuccessLabelledProperty
						label={strings.lastSynced}
						successMessage={formatDateX(data.lastSyncedDate, 'dd/MM/yyyy HH:mm:ss')}
						spacing={3}
					/>
					<VariantLabelledProperty
						property={data.countUnlinkedUsers > 0 ? strings.countUsersNotLinked(data.countUnlinkedUsers) : strings.countUsersLinked(data.countUsers)}
						variantX={data.countUnlinkedUsers > 0 ? 'warning' : 'success'}
						onClick={onViewUsers}
						spacing={3}
					/>
					<VariantLabelledProperty
						property={
							data.countUnlinkedPatientRecords > 0
								? strings.countPatientsNotImported(data.countUnlinkedPatientRecords)
								: strings.countPatientsImported(data.countPatientRecords)
						}
						variantX={data.countUnlinkedPatientRecords > 0 ? 'warning' : 'success'}
						onClick={onViewPatients}
						spacing={3}
					/>
				</div>
			</CardContent>
		</Card>
	);
};
