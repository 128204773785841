import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	IMedicalTreatmentFeeDto,
	IRegisterRfiaProformaHandoverFailureRequest,
	IRequestForInsuranceAllowance,
	rfiasHandoverCommand_registerHandoverProformaFailure,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { FeesTable } from './FeesTable';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterRfiaProformaHandoverFailureRequest>({
			rfiaId: yup.string().required(),
			handoverDate: yup.date().required(strings.formRequired(strings.handoverDate)),
			fee: yup.mixed(),
			vatPercentage: yup.number().defined(),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: (date: Date | null | undefined) => void;
	cancel: VoidFunction;
	rfia: IRequestForInsuranceAllowance;
}

export const ProformaHandoverFailureModelForm = ({ confirm, cancel, rfia, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(rfiasHandoverCommand_registerHandoverProformaFailure);
	const [fees, setFees] = useState<IMedicalTreatmentFeeDto[]>([]);
	const schema = useMemo(() => createSchema(strings), [strings]);
	const now = useNow();

	const handleSubmit = async (values: IRegisterRfiaProformaHandoverFailureRequest, helpers: FormikHelpers<IRegisterRfiaProformaHandoverFailureRequest>) => {
		// TODO meer controleren! -> max. value of stockItems will be max value available at location!
		if (fees.length > 1) {
			notify(strings.multipleNomenclaturesSelectedThisIsNotPossible, 'error');
		} else {
			const r = await create({ ...values, fee: fees[0] });
			if (handleFormResponse(r, helpers)) {
				notify(strings.handoverRegistered);
				confirm(values.handoverDate);
			}
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterRfiaProformaHandoverFailureRequest>
			validateOnMount
			initialValues={{
				rfiaId: rfia.id,
				fee: undefined as any,
				vatPercentage: 0,
				handoverDate: now,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					cancel={cancel}
					isSubmitting={isSubmitting}
					schema={schema}
					rfia={rfia}
					fees={fees}
					setFees={setFees}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	isSubmitting: boolean;
	cancel: VoidFunction;
	schema: yup.ObjectSchema<IRegisterRfiaProformaHandoverFailureRequest>;
	rfia: IRequestForInsuranceAllowance;
	fees: IMedicalTreatmentFeeDto[];
	setFees: (fees: IMedicalTreatmentFeeDto[]) => void;
}

const InnerDialog = ({ rfia, isSubmitting, cancel, schema, fees, setFees, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IRegisterRfiaProformaHandoverFailureRequest>();
	const strings = useLocalization();

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='md'>
			<DialogTitle>{`${strings.registerLumpSumContribution} (${strings.proforma})`}</DialogTitle>
			<DialogContent
				dividers
				className='df-col fg1'>
				<FormDatePicker<IRegisterRfiaProformaHandoverFailureRequest>
					name='handoverDate'
					label={strings.handoverDate}
					helperText={strings.thisDateWillBeUsedToCalculateNomenclaturePrices}
				/>
				{props.values.handoverDate !== undefined && (
					<>
						<DividerWithCaption caption={strings.refunds} />
						<FeesTable
							rfia={rfia}
							handOverDate={props.values.handoverDate}
							fees={fees}
							setFees={setFees}
						/>
					</>
				)}
			</DialogContent>
			<CancelSubmitFormDialogActions
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
			/>
		</Dialog>
	);
};
