import { Divider } from '@material-ui/core';
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { formatDate } from 'framework/utils/date/formatDate';
import { ILoan, loansCommand_patchStopDate } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { calcRequestForInsuranceAllowanceDetailRoute } from '../../ProcessesRoutes';
import { EndLoanForm } from '../forms/EndLoanForm';
import { LoanDetailProcessComponent } from './LoanDetailProcessComponent';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';

interface IProps {
	item: ILoan;
	reload: VoidFunction;
}

export const LoanDetailPageSidebar = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onPatchStopDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				formTitle={strings.changeWhat(strings.expectedWhat(strings.stopDate))}
				label={strings.expectedWhat(strings.stopDate)}
				initialValue={item.stopDate}
				submitFunction={date => loansCommand_patchStopDate({ trialId: item.id, stopDate: date })}
			/>
		);
	};

	const onEnd = () => {
		open(
			<EndLoanForm
				open
				confirm={confirm}
				cancel={cancel}
				loanId={item.id}
			/>
		);
	};

	return (
		<div
			className='df-col h100'
			style={{
				padding: 16,
				paddingRight: 8,
				// width: 250,
			}}>
			<div className='df-col gap-6'>
				<CaptionVerticalLabelledPropertyX label={strings.reference}>{item.reference}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.startDate}>{formatDate(item.startDate)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.expectedEndDate}
					link={onPatchStopDate}>
					<div className='df-row-ac gap-4'>
						{item.hasEndWarning && (
							<StatusIcon
								status='warning'
								fontSize='small'
							/>
						)}
						<span>{formatDate(item.stopDate)}</span>
					</div>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.actualEndDate}>
					<div className='df-row-ac gap-4'>
						{item.hasEndWarning && (
							<StatusIcon
								status='warning'
								fontSize='small'
							/>
						)}
						{item.hasReturnedItems ? formatDate(item.actualStopDate) : <em>{strings.notApplicableAbbreviation}</em>}
					</div>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.patient}
					link={() => push(calcPatientDetailRoute(item.forPatientId))}>
					{item.forPatientIdentifier}
				</CaptionVerticalLabelledPropertyX>
				{item.isSubProcess && (
					<CaptionVerticalLabelledPropertyX
						label={strings.link}
						link={() => push(calcRequestForInsuranceAllowanceDetailRoute(item.rfiaId!))}>
						{strings.requestForInsuranceAllowanceShort}
					</CaptionVerticalLabelledPropertyX>
				)}
			</div>
			<Divider style={{ marginTop: 16, marginBottom: 24 }} />
			<LoanDetailProcessComponent item={item} />
			<div className='fg1'></div>
			<DividerWithMargin />
			<TextLinkButton
				startIcon={<RotateLeftOutlinedIcon />}
				onClick={onEnd}
				disabled={item.canReturnItems === false}>
				{strings.endLoan}
			</TextLinkButton>
		</div>
	);
};
