import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleFormResponseWithLines } from 'framework/forms/utils/handleFormResponseWithLines';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { Try } from 'framework/Try';
import { IArticleProperty, IArticlePropertyDefinition, IArticlePropertyModel, IUpdateResponse } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import * as yup from 'yup';
import { ArticlePropertiesTable } from './ArticlePropertiesTable';
import { IArticlePropertyLine } from './IArticlePropertyLine';
import { useArticlePropertyKeysAndAutocomplete } from './useArticlePropertyKeysAndAutocomplete';
import { articlePropertyLineToModel } from './utils/articlePropertyLineToModel';
import { mapToArticlePropertyLines } from './utils/mapToArticlePropertyLines';

interface IModel {
	lines: IArticlePropertyModel[];
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			lines: yup.mixed(),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	title: string;
	apds: IArticlePropertyDefinition[];
	aps: IArticleProperty[];
	submitFunction: (values: IModel) => Promise<Try<IUpdateResponse<IModel>>>;
}

export const ArticlePropertiesForm = ({ confirm, cancel, apds, aps, title, submitFunction, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [submit, isSubmitting] = useFormSubmit(submitFunction);
	const [apks, apacs] = useArticlePropertyKeysAndAutocomplete();
	const [lines, setLines] = useState<IArticlePropertyLine[]>([]);
	const { isStacked } = useDialogsContext();

	useEffect(() => {
		setLines(mapToArticlePropertyLines(apds ?? [], aps ?? [], apks, apacs));
	}, [apks, apacs, apds, aps]);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await submit({ lines: lines.map(articlePropertyLineToModel) });
		if (handleFormResponseWithLines(r, helpers, undefined as any, undefined as any, 'lines', lines, setLines)) {
			notify(strings.changedWhat(strings.properties));
			confirm();
		}
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{ lines: [] }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xl'
					open={rest.open && isStacked === false}>
					<DialogTitle>{title}</DialogTitle>
					<DialogContent
						dividers
						style={{ minWidth: 500, padding: 0 }}
						className='df-col'>
						<ArticlePropertiesTable
							lines={lines}
							setLines={setLines}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={strings.update}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
