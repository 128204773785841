import { ColorX } from 'framework/components/color/ColorX';
import React from 'react';
import { useThemeColor } from '../color/useThemeColor';
import './styles.scss';

interface IProps {
	size: number;
	color: ColorX;
	style?: React.CSSProperties;
}

export const RippleCircle = ({ size, color, style }: IProps) => {
	const tColor = useThemeColor(color);

	return (
		<div
			className='circle-ripple-effect'
			style={{
				color: tColor,
				border: `1px solid ${tColor}`,
				backgroundColor: tColor,
				height: size,
				width: size,
				...style,
			}}></div>
	);
};
