import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectFieldOnId } from 'framework/forms/FormSelectFieldOnId';
import { formatDate } from 'framework/utils/date/formatDate';
import { ILoanSummary, IRequestForInsuranceAllowance } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

interface IModel {
	id: string;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		id: yup.string().required(strings.formRequired('proef')),
	});
};

interface IProps extends DialogProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: (trialId: string) => void;
	cancel: VoidFunction;
}

export const SelectLoanSummaryForm = ({ rfia, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();

	const handleSubmit = (values: IModel, helpers: FormikHelpers<IModel>) => {
		confirm(values.id);
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{ id: '' }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			{props => (
				<Form>
					<Dialog
						{...rest}
						fullWidth
						maxWidth='sm'>
						<DialogTitle>{strings.selectWhat(strings.trial)}</DialogTitle>
						<DialogContent
							dividers
							className='df-col'>
							<FormSelectFieldOnId<IModel, ILoanSummary>
								name='id'
								label={strings.trial}
								options={rfia.loanSummaries}
								renderValue={(t, index) => `${formatDate(t.startDate)} (#${index + 1})`}
								getKey={t => t.id}
							/>
						</DialogContent>
						<CancelSubmitFormDialogActions
							cancel={cancel}
							isSubmitting={false}
							submitText={strings.select}
						/>
					</Dialog>
				</Form>
			)}
		</Formik>
	);
};
