import React from 'react';
import { StockMovementsListViewProvider } from './list/StockMovementsListViewProvider';
import { StockMovementsQueryProvider } from './list/StockMovementsQueryProvider';

export const StockMovementProviders = ({ children }: any) => {
	return (
		<StockMovementsQueryProvider>
			<StockMovementsListViewProvider>{children}</StockMovementsListViewProvider>
		</StockMovementsQueryProvider>
	);
};
