import { useTheme } from '@material-ui/core';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import {
	IPaymentMethod,
	paymentMethodsCommand_disable,
	paymentMethodsCommand_disableCashSale,
	paymentMethodsCommand_disablePurchase,
	paymentMethodsCommand_disableSale,
	paymentMethodsCommand_enable,
	paymentMethodsCommand_enableCashSale,
	paymentMethodsCommand_enablePurchase,
	paymentMethodsCommand_enableSale,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { boolToYesNo } from 'shared/utils/boolToString';
import { PaymentMethodNameForm } from './forms/PaymentMethodNameForm';

interface IProps {
	item: IPaymentMethod;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const PaymentMethodDetailContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { translatePaymentMethod } = React.useContext(RecordContext);

	const toggleEnable = () => {
		if (item.isEnabled) {
			open(
				<AlertDialog
					open
					title={strings.reactivatePaymentMethod}
					content={strings.reactivatePaymentMethodContent}
					acceptText={strings.yes}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => paymentMethodsCommand_enable(item.id)}
					accept={confirm}
				/>
			);
		} else {
			open(
				<AlertDialog
					open
					title={strings.deactivePaymentMethod}
					content={strings.deactivePaymentMethodContent}
					acceptText={strings.yes}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => paymentMethodsCommand_disable(item.id)}
					accept={confirm}
				/>
			);
		}
	};

	const toggleEnableCashSale = () => {
		if (item.canUseForCashSale) {
			open(
				<AlertDialog
					open
					title={strings.deactivePaymentMethodForWhatQuestion(strings.cashSales)}
					content={strings.deactivePaymentMethodForWhatContent(strings.cashSales)}
					acceptText={strings.yes}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => paymentMethodsCommand_disableCashSale(item.id)}
					accept={confirm}
				/>
			);
		} else {
			open(
				<AlertDialog
					open
					title={strings.reactivatePaymentMethodForWhatQuestion(strings.cashSales)}
					content={strings.reactivatePaymentMethodForWhatContent(strings.cashSales)}
					acceptText={strings.yes}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => paymentMethodsCommand_enableCashSale(item.id)}
					accept={confirm}
				/>
			);
		}
	};

	const toggleEnableSale = () => {
		if (item.canUseForSale) {
			open(
				<AlertDialog
					open
					title={strings.deactivePaymentMethodForWhatQuestion(strings.sales)}
					content={strings.deactivePaymentMethodForWhatContent(strings.sales)}
					acceptText={strings.yes}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => paymentMethodsCommand_disableSale(item.id)}
					accept={confirm}
				/>
			);
		} else {
			open(
				<AlertDialog
					open
					title={strings.reactivatePaymentMethodForWhatQuestion(strings.sales)}
					content={strings.reactivatePaymentMethodForWhatContent(strings.sales)}
					acceptText={strings.yes}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => paymentMethodsCommand_enableSale(item.id)}
					accept={confirm}
				/>
			);
		}
	};

	const toggleEnablePurchase = () => {
		if (item.canUseForPurchase) {
			open(
				<AlertDialog
					open
					title={strings.deactivePaymentMethodForWhatQuestion(strings.purchases)}
					content={strings.deactivePaymentMethodForWhatContent(strings.purchases)}
					acceptText={strings.yes}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => paymentMethodsCommand_disablePurchase(item.id)}
					accept={confirm}
				/>
			);
		} else {
			open(
				<AlertDialog
					open
					title={strings.reactivatePaymentMethodForWhatQuestion(strings.purchases)}
					content={strings.reactivatePaymentMethodForWhatContent(strings.purchases)}
					acceptText={strings.yes}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => paymentMethodsCommand_enablePurchase(item.id)}
					accept={confirm}
				/>
			);
		}
	};

	const onChangeName = () => {
		open(
			<PaymentMethodNameForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	return (
		<div
			className='df-col gap-8'
			style={{
				paddingLeft: 12,
				borderLeft: `6px solid ${theme.palette.primary.main}`,
				borderRadius: 12,
				...style,
			}}>
			<Typography16pxBold>{`${strings.details} | ${translatePaymentMethod(item.identifier)}`}</Typography16pxBold>
			<CaptionVerticalLabelledPropertyX
				label={strings.name}
				edit={onChangeName}>
				{translatePaymentMethod(item.identifier)}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={`${strings.available}?`}
				edit={toggleEnable}>
				{boolToYesNo(item.isEnabled, strings)}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={`${strings.availableForWhat(strings.sales)}`}
				edit={toggleEnableSale}>
				{boolToYesNo(item.canUseForSale, strings)}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={`${strings.availableForWhat(strings.cashSales)}`}
				edit={toggleEnableCashSale}>
				{boolToYesNo(item.canUseForCashSale, strings)}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={`${strings.availableForWhat(strings.purchases)}`}
				edit={toggleEnablePurchase}>
				{boolToYesNo(item.canUseForPurchase, strings)}
			</CaptionVerticalLabelledPropertyX>
		</div>
	);
};
