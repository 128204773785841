import { frApiErrorStrings } from './apiErrors/frApiErrorStrings';
import { IFrameworkStrings } from './IFrameworkStrings';

export const frFrameworkStrings: IFrameworkStrings = {
	...frApiErrorStrings,
	actions: `Actions`,
	addWhat: (what: string | number) => `Ajouter ${what.toString().toLowerCase()}`,
	allAfter: `Tout après`,
	allBefore: `Tout avant`,
	amount: `Nombre`,
	busyUploadingDocument: `Document en cours de téléchargement`,
	cancel: `Annuler`,
	changeColumns: `Changer colonnes`,
	changeSettings: `Changer paramètres`,
	clearFilter: `Supprimer filtre`,
	close: `Fermer`,
	createNew: `Créer nouveau`,
	day: `Jour`,
	dayOrDays: `Jour(s)`,
	days: `Jours`,
	delete: `Supprimer`,
	details: `Détails`,
	dropDocumentHere: `Déposer document ici`,
	exportAllResultsCountInXlsxFormat: (count: string | number, format: string | number) =>
		`Exporter tous les résultats (${count.toString().toLowerCase()}) en format ${format.toString().toLowerCase()}`,
	exportFilteredResultsCountInFormat: (count: string | number, format: string | number) =>
		`Exporter ${count.toString().toLowerCase()} résultats filtrés en format ${format.toString().toLowerCase()}`,
	exportVerb: `Exporter`,
	filter: `Filtre`,
	formRequired: (field: string | number) => `${field} est un champ obligatoire`,
	from: `De`,
	hour: `Heure`,
	hourOrHours: `Heure(s)`,
	hours: `Heures`,
	invalidDateFormat: `Format de date incorrect`,
	lastMonth: `Le mois dernier`,
	lastQuarter: `Dernier trimestre`,
	lastWeek: `Semaine dernière`,
	lastYear: `Année dernière`,
	minute: `Minute`,
	minuteOrMinutes: `Minute(s)`,
	minutes: `Minutes`,
	month: `Mois`,
	monthOrMonths: `Mois`,
	months: `Mois`,
	moreInformation: `Plus d'information`,
	new: `Nouveau`,
	next: `Suivant`,
	noAccessToThisFunction: `Vous n'avez pas les droits suffisants pour exécuter cette fonction`,
	none: `Aucun`,
	notApplicableAbbreviation: `n/a`,
	previous: `Précédent`,
	releaseLeftMouseButtonToUploadFile: `Relâchez le bouton gauche de la souris pour télécharger le fichier`,
	reload: `Recharger`,
	restrictions: `Restrictions`,
	searchVerb: `Rechercher`,
	second: `Seconde`,
	secondOrSeconds: `Seconde(s)`,
	seconds: `Secondes`,
	select: `Sélectionner`,
	selectAll: `Sélectionner tout`,
	selectAllBelow: `Sélectionner tout ci-dessous`,
	selectColumns: `Sélectionner colonnes`,
	selectColumnsToShowBelow: `Sélectionner les colonnes à afficher ci-dessous`,
	selectFromComputer: `Sélectionner à partir de l'ordinateur`,
	selectMinMax: `Sélectionner min/max`,
	selectNone: `Ne sélectionner rien`,
	selectPriceRange: `Sélectionnez un prix entre min/max`,
	showLess: `Afficher moins`,
	start: `Début`,
	thisFileExtensionIsNotSupported: `Ce format de fichier n'est pas pris en charge`,
	thisMonth: `Ce mois-ci`,
	thisQuarter: `Ce trimestre`,
	thisWeek: `Cette semaine`,
	thisYear: `Cette année`,
	to: `Jusqu'à`,
	today: `Aujourd'hui`,
	unit: `Unité`,
	update: `Mettre à jour`,
	uploadADocument: `Télécharger un document`,
	viewMore: `Voir plus`,
	week: `Semaine`,
	weekOrWeeks: `Semaine(s)`,
	weeks: `Semaines`,
	year: `Année`,
	yearOrYears: `Année(s)`,
	years: `Années`,
	yesterday: `Hier`,
	youCanAlsoDragAFileToHere: `Vous pouvez également faire glisser un fichier ici`,
};
