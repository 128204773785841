import { Button, ButtonProps } from '@material-ui/core';
import { useRosaRedirect } from 'app/main/rosaCalendar/useRosaRedirect';
import { CalendarPlusIcon } from 'framework/components/icons/CalendarPlusIcon';
import { AlertDialogWithCreateResponse } from 'framework/dialogs/AlertDialogWithCreateResponse';
import { rosaPatientsCommand_createInRosa } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

interface IProps extends ButtonProps {
	reload: VoidFunction;
	hamsPatientId: string;
	redirectRosaPatientRecordId: string | undefined;
}

export const RosaPatientRedirectButton = ({ hamsPatientId, redirectRosaPatientRecordId, reload, ...rest }: IProps) => {
	const strings = useLocalization();
	const rRedirect = useRosaRedirect();
	const hasRedirectId = useMemo(() => redirectRosaPatientRecordId !== null && redirectRosaPatientRecordId !== undefined, [redirectRosaPatientRecordId]);
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onNew = () => {
		if (hasRedirectId === false) {
			open(
				<AlertDialogWithCreateResponse
					open
					title={strings.createPatientInRosa}
					content={strings.doYouWantToCreateThisPatientInRosaQuestion}
					acceptText={strings.yesWhatExclamation(strings.continue)}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => rosaPatientsCommand_createInRosa({ hamsPatientId: hamsPatientId! })}
					accept={id => {
						rRedirect({ patientRecordId: id });
						confirm();
					}}
				/>
			);
		} else {
			rRedirect({ patientRecordId: redirectRosaPatientRecordId! });
		}
	};

	return (
		<Button
			{...rest}
			startIcon={<CalendarPlusIcon />}
			onClick={onNew}>
			{strings.newWhat(strings.appointment)}
		</Button>
	);
};
