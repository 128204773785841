import { IAddOrderLineRequest } from 'gen/ApiClient';
import { IOrderLineModel } from '../register/IOrderLineModel';

export const mapOrderLineModelToAddOrderLineRequest = (line: IOrderLineModel): IAddOrderLineRequest => {
	return {
		description: line.description,
		orientation: line.orientation,
		patientId: line.patientId,
		productCatalogItemId: line.productCatalogItemId,
		quantity: line.quantity,
		articleProperties: line.articleProperties,
	};
};
