import { INewLineModel } from 'shared/searchAndSelect/INewLineModel';
import { IHandoverLine } from '../IHandoverLine';

export const newLineToLine = (t: INewLineModel): IHandoverLine => {
	return {
		isSelected: true,
		id: t.id,
		description: t.description,
		quantity: 1,
		unitListPrice: t.unitListPrice,
		unitPrice: t.unitListPrice,
		vatPercentage: t.vatPercentage,
		inventoryItemId: undefined,
		productCatalogItem: undefined,
		inventoryItem: undefined,
		manufacturer: '',
		model: '',
		productCatalogItemId: undefined,
		rizivHearingAidDefinitionV2IdentificationCode: undefined,
		serialNumber: '',
		isIncludeOnHandoverCertificate: true,
		isRefundable: t.unitListPrice < 0 ? true : false,
		assuredInventoryItemId: undefined,
		assuredLineId: undefined,
		assuredProductCatalogItemId: undefined,
		canChangeQuantity: true,
		advanceInvoiceId: undefined,
		stockAtLocations: undefined,
		isCredit: false,
	};
};
