import { Card, CardContent, Divider, IconButton, useTheme } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { AddSmallPrimaryTextButton } from 'framework/components/buttons/AddSmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { TablePaging } from 'framework/table/TablePaging';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { annotationsQuery_query, IAnnotationsQueryParams, LinkViewContextType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { NoRecordsFound } from '../../framework/components/NoRecordsFound';
import { useDialogsContext } from '../dialogs/useDialogsContext';
import { AnnotationForm } from './AnnotationForm';
import { AnnotationsComponent } from './AnnotationsComponent';
import { Filter } from './Filter';
import { OrderBySelect } from './OrderBySelect';
import { AnnotationSettingsDialog } from './types/AnnotationSettingsDialog';

const DefaultParams: IAnnotationsQueryParams = {
	pageParams: { index: 1, size: 50 },
	sortParams: { property: 'created', direction: 'desc' },
	createdAfterDate: undefined,
	createdBeforeDate: undefined,
	types: [],
	alsoIncludeDeleted: false,
};

interface IProps {
	style?: React.CSSProperties;
	contextId: string;
	linkViewContextType: LinkViewContextType;
	title?: string;
	reload: VoidFunction;
	reloadTrigger: Trigger;
	isFollowUp?: boolean;
}

export const AnnotationsCard = ({ contextId, linkViewContextType, style, title, reload, reloadTrigger, isFollowUp: isFollowUpOrDefault }: IProps) => {
	const isFollowUp = useMemo(() => (isFollowUpOrDefault === true ? true : false), [isFollowUpOrDefault]);
	const strings = useLocalization();
	const theme = useTheme();
	const [params, setParams, queryResult, reloadThis] = useApiEffectWithParams(
		pars => annotationsQuery_query(isFollowUp === true ? true : false, contextId, pars),
		DefaultParams
	);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadThis();
	}, [reloadTrigger]);

	const onFilter = (params: IAnnotationsQueryParams) => {
		setParams(onResetToPage1(params));
	};

	const onAdd = () => {
		open(
			<AnnotationForm
				open
				contextId={contextId}
				linkViewContextType={linkViewContextType}
				confirm={confirm}
				cancel={cancel}
				isFollowUp={isFollowUp}
			/>
		);
	};

	const onSettings = () => {
		open(
			<AnnotationSettingsDialog
				open
				close={confirm}
				isFollowUp={isFollowUp}
			/>
		);
	};

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<Card style={{ width: '100%', marginBottom: theme.spacing(2), overflow: 'unset', ...style }}>
			<CardTitleTypography
				withPadding
				title={title ? title : strings.annotations}
				action={
					<div className='df-row-ac'>
						<IconButton
							onClick={onSettings}
							size='small'
							color='primary'>
							<SettingsOutlinedIcon fontSize='small' />
						</IconButton>
						<AddSmallPrimaryTextButton onClick={onAdd}>{strings.newRemark}</AddSmallPrimaryTextButton>
					</div>
				}
			/>
			<CardContent style={{ paddingTop: 0, paddingBottom: queryResult.filteredCount !== 0 ? 0 : 16 }}>
				<Divider />
				<div className='df-row-ac'>
					<Filter
						params={params}
						onFilter={onFilter}
						paddingLeft={8}
					/>
					<div className='fg1'></div>
					<FormControlLabelSwitch
						isChecked={params.alsoIncludeDeleted}
						toggle={() => onFilter({ ...params, alsoIncludeDeleted: !params.alsoIncludeDeleted })}
						label={strings.alsoShowDeleted}
					/>
					<OrderBySelect
						sortParams={params.sortParams}
						onFilter={sp => onFilter({ ...params, sortParams: sp })}
					/>
				</div>
				<Divider />
				{queryResult.unfilteredCount === 0 && (
					<NoRecordsFound style={{ marginTop: 16 }}>{strings.noWhatFoundClickPlusToCreateNew(strings.annotations)}</NoRecordsFound>
				)}
				{queryResult.unfilteredCount > 0 && (
					<>
						{queryResult.filteredCount === 0 && <NoRecordsFound style={{ marginTop: 16 }}>{strings.nothingFoundCommaChangeSearchParams}</NoRecordsFound>}
						{queryResult.filteredCount > 0 && (
							<>
								<AnnotationsComponent
									annotations={queryResult.values}
									reload={reload}
									style={{ marginTop: 24 }}
									alsoIncludeDeleted={params.alsoIncludeDeleted}
								/>
								<Divider style={{ marginTop: 24 }} />
								<TablePaging
									removeBorder
									countResults={queryResult.filteredCount}
									pageSize={queryResult.pageSize}
									page={queryResult.zeroBasedPageIndex}
									pageParams={params.pageParams}
									setPageParams={val => setParams({ ...params, pageParams: val })}
								/>
							</>
						)}
					</>
				)}
			</CardContent>
		</Card>
	);
};
