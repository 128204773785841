import { DragAndDropContainer } from 'framework/components/dnd/DragAndDropContainer';
import { LeftRightDividedContentScrollYRight } from 'framework/components/LeftRightDividedContentScrollYRight';
import { addOrUpdateQuantity } from 'framework/utils/array/addOrUpdateQuantity';
import { updateArray } from 'framework/utils/array/updateArray';
import { IMedicalTreatmentFeeDto, IPatient, IProductCatalogItem, IProductCatalogQueryParamsForTender } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AddLineForm } from 'shared/searchAndSelect/AddLineForm';
import { SearchProductCatalogAndWizardTabsContainer } from 'shared/searchAndSelect/SearchProductCatalogAndWizardTabsContainer';
import { DiscountWizardButton } from 'shared/searchAndSelect/wizard/DiscountWizardButton';
import { SearchContext } from './context/SearchContext';
import { ILine } from './ILine';
import { TenderLineComponent } from './TenderLineComponent';
import { TotalsTable } from './TotalsTable';
import { newLineToLine } from './utils/newLineToLine';
import { productCatalogItemToLine } from './utils/productCatalogItemToLine';
import { SelectMedicalTreatmentAndFeeForm } from 'app/main/processes/requestsForInsuranceAllowance/forms/medicalTreatmentNomenclatures/SelectMedicalTreatmentAndFeeForm';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { mapMedicalTreatmentFeeToTenderModelLine } from './utils/mapMedicalTreatmentFeeToTenderModelLine';
import { WizardButton } from 'shared/searchAndSelect/wizard/WizardButton';
import { useLocalization } from 'localization/useLocalization';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';

interface IProps {
	patient: IPatient | undefined;
	lines: ILine[];
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>;
	fees?: IMedicalTreatmentFeeDto[];
	hideNomenclatures?: boolean;
}

export const SearchAndSelectProductsForTender = ({ patient, lines, setLines, fees = [], hideNomenclatures }: IProps) => {
	const { ...searchContext } = useContext(SearchContext);
	const { open, confirm, cancel } = useDialogsContext();
	const language = useCurrentLanguage();
	const strings = useLocalization();

	const onAdd = (item: IProductCatalogItem) => {
		setLines(addOrUpdateQuantity(item, lines, () => productCatalogItemToLine(item)));
	};

	const onAddLine = () => {
		open(
			<AddLineForm
				open
				confirm={line => {
					confirm();
					setLines([...lines, newLineToLine(line)]);
				}}
				cancel={cancel}
			/>
		);
	};

	const onAddMedicalTreatmentFee = () => {
		open(
			<SelectMedicalTreatmentAndFeeForm
				open
				confirm={val => {
					confirm();
					setLines([...lines, mapMedicalTreatmentFeeToTenderModelLine(val, language)]);
				}}
				patient={patient}
				cancel={cancel}
			/>
		);
	};

	return (
		<LeftRightDividedContentScrollYRight
			leftContent={
				<SearchProductCatalogAndWizardTabsContainer<IProductCatalogQueryParamsForTender>
					{...searchContext}
					productCatalogFilterType='all'
					onAddPc={onAdd}
					wizardContent={
						<div className='df-col'>
							<DiscountWizardButton onClick={onAddLine} />
							{hideNomenclatures !== true && (
								<WizardButton
									startIcon={<LocalHospitalOutlinedIcon />}
									onClick={onAddMedicalTreatmentFee}>
									{strings.addWhat(strings.nomenclature)}
								</WizardButton>
							)}
						</div>
					}
				/>
			}
			rightContent={
				<DragAndDropContainer<ILine>
					lines={lines}
					setLines={setLines}
					getKey={t => t.id}
					render={(line, index, props) => (
						<TenderLineComponent
							line={line}
							index={index + 1}
							dragHandleProps={props}
							setLine={val => setLines(updateArray(line, lines, val))}
							onDelete={() => setLines(lines.filter(t => t !== line))}
						/>
					)}
				/>
			}
			rightBottomFixedContent={
				<div className='df-col-ae'>
					<TotalsTable
						lines={lines}
						fees={fees}
						style={{ width: 'fit-content' }}
					/>
				</div>
			}
		/>
	);
};
