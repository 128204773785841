import { DialogProps } from '@material-ui/core';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { ISettlementProposal, settlementProposalsCommand_updateLabels } from 'gen/ApiClient';
import React from 'react';
import { LabelsForm } from 'shared/LabelsForm';
import { FilterDataContext } from '../FilterDataContext';

interface IProps extends DialogProps {
	item: ISettlementProposal;
	cancel: VoidFunction;
	confirm: VoidFunction;
}

export const SpsLabelsForm = ({ item, ...rest }: IProps) => {
	const [data] = useCacheContext(FilterDataContext);

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<LabelsForm
			{...rest}
			autoCompleteLabels={data.labels}
			labels={item.labels}
			updateF={values => settlementProposalsCommand_updateLabels(item.id, values)}
		/>
	);
};
