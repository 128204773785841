import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	cancel: VoidFunction;
	confirm: (isForPatient: boolean) => void;
	previous: VoidFunction;
}

export const InvoiceForForm = ({ cancel, previous, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const [isForPatient, setIsForPatient] = useState<boolean>(true);

	return (
		<Dialog {...rest}>
			<DialogTitle>{strings.newInvoiceForWhoQuestion}</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<boolean>
					selected={isForPatient}
					setSelected={setIsForPatient}
					options={[
						{ label: strings.customerIsAPatient, value: true, caption: strings.invoiceCustomerIsAPatientCaption },
						{ label: strings.customerIsNotAPatient, value: false, caption: strings.invoiceCustomerIsNotAPatientCaption },
					]}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button
					onClick={previous}
					color='primary'>
					{strings.previous}
				</Button>
				<Button
					onClick={() => confirm(isForPatient)}
					color='primary'>
					{strings.next}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
