import { useTheme } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import React from 'react';

export const DocumentActionsLeftContent = () => {
	const theme = useTheme();
	return (
		<div className='df-col-ac jc-c h100'>
			<DescriptionOutlinedIcon style={{ color: theme.palette.background.default, fontSize: '100px' }} />
		</div>
	);
};
