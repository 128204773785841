import { DialogProps } from '@material-ui/core';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import {
	IInventoryItem,
	IInventoryItemsQueryParams,
	inventoryItemsScriptsCommand_scriptRegisterAsPurchased,
	inventoryItemsScriptsCommand_scriptRegisterReturnedToSupplier,
	IQueryResult,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { Mode } from './Mode';
import { ModeDialog } from './ModeDialog';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	queryResult: IQueryResult<IInventoryItem>;
	params: IInventoryItemsQueryParams;
}

export const InventoryItemsScriptsDialog = ({ confirm, cancel, queryResult, params, ...rest }: IProps) => {
	const strings = useLocalization();
	const [state, setState] = useState<'mode' | Mode>('mode');

	if (state === 'mode') {
		return (
			<ModeDialog
				{...rest}
				confirm={setState}
				cancel={cancel}
			/>
		);
	} else if (state === 'registerAsPurchased') {
		return (
			<AlertDialog
				open
				title={'Execute script (set items as purchased)'}
				content={`Mark ${queryResult.filteredCount.toString()} items as purchased?\nNon purchased items will be excluded from this script`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => inventoryItemsScriptsCommand_scriptRegisterAsPurchased(params)}
				accept={confirm}
			/>
		);
	} else if (state === 'registerAsReturnedToSupplier') {
		return (
			<AlertDialog
				open
				title={'Execute script (set items as returned to supplier)'}
				content={`Mark ${queryResult.filteredCount.toString()} items as returned to supplier?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => inventoryItemsScriptsCommand_scriptRegisterReturnedToSupplier(params)}
				accept={confirm}
			/>
		);
	} else {
		return <div></div>;
	}
};
