import { Dialog, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@material-ui/core';
import React from 'react';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	cancel: VoidFunction;
	goNext: VoidFunction;
}

export const EidInfo = ({ cancel, goNext, ...rest }: IProps) => {
	const strings = useLocalization();
	return (
		<Dialog
			maxWidth='md'
			{...rest}>
			<DialogTitle>{strings.howDoeseIDReadWorks}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<DialogContentText style={{ whiteSpace: 'pre' }}>{strings.eIDReadInfo}</DialogContentText>
			</DialogContent>
			<CancelSubmitDialogActions
				submitText={strings.continue}
				cancel={cancel}
				onSubmit={goNext}
				isSubmitting={false}
			/>
		</Dialog>
	);
};
