import { ExactOnlineSelectedTabKey } from 'app/localStorageKeys';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useLocalStorage } from 'framework/hooks/useLocalStorage';
import { useTrigger } from 'framework/hooks/useTrigger';
import { exactOnlineIntegrationQuery_status, exactOnlineSettingsQuery_settings } from 'gen/ApiClient';
import React, { useEffect } from 'react';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { ExactOnlineIntegrationPageHeader } from './ExactOnlineIntegrationPageHeader';
import { ExactOnlineIntegrationTabType } from './ExactOnlineIntegrationTabType';
import { ExactOnlineCustomerAccountsListTab } from './customerAccounts/ExactOnlineCustomerAccountsListTab';
import { ExactOnlineProductCatalogItemsListTab } from './productCatalog/ExactOnlineProductCatalogItemsListTab';
import { ExactOnlineSalesListTab } from './sales/ExactOnlineSalesListTab';
import { ExactOnlineSettingsTab } from './settings/ExactOnlineSettingsTab';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';

export const ExactOnlineIntegrationPage = () => {
	const [trigger, hitTrigger] = useTrigger();
	const [selectedTab, setSelectedTab] = useLocalStorage<ExactOnlineIntegrationTabType>(ExactOnlineSelectedTabKey, 'settings');
	const [status, reloadStatus] = useApiEffect(exactOnlineIntegrationQuery_status);
	const [settings, reloadSettings] = useApiEffect(exactOnlineSettingsQuery_settings);
	const isSuperUser = useLoggedInUserIsSuperUser();
	const showOnlySettings = React.useMemo(() => (settings?.canPushPull ?? false) === false && isSuperUser === false, [isSuperUser, settings]);

	useEffect(() => {
		if (showOnlySettings === true && selectedTab !== 'settings') {
			setSelectedTab('settings');
		}
		// eslint-disable-next-line
	}, [settings, selectedTab]);

	useLazyEffect(() => {
		reloadStatus();
		reloadSettings();
	}, [trigger]);

	if (status === undefined || settings === undefined) {
		return <></>;
	}

	return (
		<RestrictedPageX
			pageClaim='ExactOnlineIntegration'
			renderRaw
			stickyHeader={
				<ExactOnlineIntegrationPageHeader
					tab={selectedTab}
					setTab={setSelectedTab}
					status={status}
					reload={hitTrigger}
					settings={settings}
					showOnlySettings={showOnlySettings}
				/>
			}>
			{selectedTab === 'sales' && (
				<ExactOnlineSalesListTab
					reload={hitTrigger}
					reloadTrigger={trigger}
				/>
			)}
			{selectedTab === 'customer-accounts' && (
				<ExactOnlineCustomerAccountsListTab
					reloadTrigger={trigger}
					reload={hitTrigger}
				/>
			)}
			{selectedTab === 'product-catalog' && (
				<ExactOnlineProductCatalogItemsListTab
					reloadTrigger={trigger}
					reload={hitTrigger}
				/>
			)}
			{selectedTab === 'settings' && (
				<ExactOnlineSettingsTab
					reloadTrigger={trigger}
					reload={hitTrigger}
					settings={settings}
				/>
			)}
		</RestrictedPageX>
	);
};
