import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

interface IProps {
	onClick: VoidFunction;
}

export const CloseButtonOnAbsolutePosition = ({ onClick }: IProps) => {
	return (
		<IconButton
			onClick={onClick}
			style={{ position: 'absolute', right: '10px', top: '10px' }}>
			<CloseIcon />
		</IconButton>
	);
};
