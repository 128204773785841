import { Dialog, DialogActions, DialogContent, DialogProps, useTheme } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormActions } from 'framework/forms/PageableFormActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	IPaymentForPurchasesModel,
	paymentsForPurchasesCommand_newGroup,
	paymentsQuery_nextReference,
	purchasesQuery_allForSupplier,
	suppliersQuery_single,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useCallback, useEffect, useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { FormSelectOrCreatePaymentMethodField } from '../../../../settings/paymentMethods/forms/FormSelectOrCreatePaymentMethodField';
import { IPurchaseWithSelection } from '../../../purchases/IPurchaseWithSelection';
import { PurchasesTable } from './PurchasesTable';
import { TotalsTable } from './TotalsTable';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPaymentForPurchasesModel>({
			supplierId: yup.string().required(),
			date: yup.date().required(),
			isAutoSelectReference: yup.boolean().defined(),
			methodId: yup.string().required(),
			reference: yup.string(),
			purchaseIds: yup.mixed(),
		})
		.defined();
};

const EmptyValues: IPaymentForPurchasesModel = {
	supplierId: '',
	date: undefined as any,
	isAutoSelectReference: true,
	methodId: '',
	reference: '',
	purchaseIds: [],
};

const stepsRecord: Record<number, (keyof IPaymentForPurchasesModel)[]> = {
	0: ['isAutoSelectReference', 'reference', 'date'],
	1: ['methodId'],
	2: ['purchaseIds'],
};

interface IProps extends DialogProps {
	supplierId: string;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const PaymentForPurchasesForm = ({ confirm, cancel, supplierId, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(paymentsForPurchasesCommand_newGroup);
	const [step, setStep] = useState<number>(0);
	const [supplier] = useApiEffect(suppliersQuery_single, supplierId);
	const [allLines] = useApiEffect(purchasesQuery_allForSupplier, supplierId);
	const [lines, setLines] = useState<IPurchaseWithSelection[]>([]);
	const now = useNow();

	useEffect(() => {
		if (allLines !== undefined) {
			setLines(allLines.map<IPurchaseWithSelection>(t => ({ isSelected: false, ...t })));
		}
	}, [allLines]);

	const handleSubmit = async (values: IPaymentForPurchasesModel, helpers: FormikHelpers<IPaymentForPurchasesModel>) => {
		const r = await create({ ...values, supplierId: supplierId, purchaseIds: lines.filter(t => t.isSelected).map(t => t.id) });
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.registeredWhat(strings.groupPayment));
			confirm(r.result.objectId);
		}
	};

	if (lines === undefined || allLines === undefined || supplier === undefined || now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IPaymentForPurchasesModel>
			validateOnMount
			initialValues={{
				...EmptyValues,
				supplierId: supplierId,
				date: now,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					supplierIdentifier={supplier.identifier}
					lines={lines}
					setLines={setLines}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	supplierIdentifier: string;
	lines: IPurchaseWithSelection[];
	setLines: React.Dispatch<React.SetStateAction<IPurchaseWithSelection[]>>;
}

const InnerDialog = ({ step, setStep, cancel, isSubmitting, supplierIdentifier, lines, setLines, ...rest }: IInnerDialogProps) => {
	const theme = useTheme();
	const props = useFormikContext<IPaymentForPurchasesModel>();
	const strings = useLocalization();
	const cb = useCallback(() => paymentsQuery_nextReference(supplierIdentifier), [supplierIdentifier]);
	useNextReferenceInterceptor(cb, props);

	return (
		<Dialog
			scroll='paper'
			{...rest}>
			<DialogTitleWithFormStepper
				title={strings.registerGroupPaymentForSupplier(supplierIdentifier)}
				labels={[`${strings.date} & ${strings.reference}`, strings.method, strings.lines]}
				step={step}
			/>
			<DialogContent
				className='df-col'
				dividers
				style={{ padding: step === 2 ? '0px' : '8px 24px' }}>
				{step === 0 && (
					<>
						<FormDatePicker<IPaymentForPurchasesModel>
							name='date'
							label={strings.referenceDate}
							disableFuture
							required
						/>
						<FormSingleCheckboxField<IPaymentForPurchasesModel>
							name='isAutoSelectReference'
							label={strings.autoSelectPaymentReferenceQuestion}
						/>
						<FormTextField<IPaymentForPurchasesModel>
							name='reference'
							label={strings.paymentReference}
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectOrCreatePaymentMethodField<IPaymentForPurchasesModel>
							name='methodId'
							label={strings.paymentMethod}
							mode='purchase'
						/>
					</>
				)}
				{step === 2 && (
					<PurchasesTable
						lines={lines}
						setLines={setLines}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<div className='df-col'>
					{step === 2 && (
						<TotalsTable
							lines={lines}
							style={{ marginBottom: theme.spacing(1) }}
						/>
					)}
					<PageableFormActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
					/>
				</div>
			</DialogActions>
		</Dialog>
	);
};
