import { IPurchase } from 'gen/ApiClient';
import React from 'react';
import { PurchaseCreditChipOrFragment } from './PurchaseCreditChipOrFragment';
import { PurchasePaymentStatusChip } from './PurchasePaymentStatusChip';
import { PurchaseTypeChip } from './PurchaseTypeChip';

interface IProps {
	item: IPurchase;
	style?: React.CSSProperties;
}

export const PurchaseChips = ({ item, style }: IProps) => {
	return (
		<div
			className='df-row-ac gap-4'
			style={style}>
			<PurchasePaymentStatusChip
				item={item}
				color={item.isFullyPaid ? 'success' : item.isOverDue ? 'warning' : 'info'}
			/>
			<PurchaseTypeChip
				item={item}
				color={item.isCreditNote ? 'warning' : 'info'}
			/>
			<PurchaseCreditChipOrFragment item={item} />
		</div>
	);
};
