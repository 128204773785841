import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { TabX } from 'framework/components/tabs/TabX';
import { TabsX } from 'framework/components/tabs/TabsX';
import { IInvoiceForwardingSettings, IInvoiceForwardingStatus } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { IntegrationsPageBreadcrumb } from '../IntegrationsPageBreadcrumb';
import { InvoiceForwardingTabType } from './InvoiceForwardingTabType';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

interface IProps {
	reload: VoidFunction;
	tab: InvoiceForwardingTabType;
	setTab: React.Dispatch<React.SetStateAction<InvoiceForwardingTabType>>;
	settings: IInvoiceForwardingSettings;
	status: IInvoiceForwardingStatus;
}

export const InvoiceForwardingIntegrationPageHeader = ({ reload, tab, setTab, status, settings }: IProps) => {
	const strings = useLocalization();

	return (
		<div className='df-col fg1 gap-4'>
			<div className='df-row-ac'>
				<IntegrationsPageBreadcrumb />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={strings.salesInvoicesForwarding} />
				{status.countSalesNotIgnoredNorForwarded > 0 && (
					<StatusIcon
						status='error'
						style={{ marginLeft: 4 }}
					/>
				)}
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<TabsX
				style={{
					marginLeft: -4,
				}}>
				{settings.isEnabled && (
					<TabX
						isSelected={tab === 'records'}
						onClick={() => setTab('records')}
						startIcon={<AttachMoneyOutlinedIcon />}
						endIcon={
							status.countSalesNotIgnoredNorForwarded > 0 && (
								<BadgeChipX
									value={status.countSalesNotIgnoredNorForwarded}
									color='error'
								/>
							)
						}
						value={strings.sales}
					/>
				)}
				<TabX
					isSelected={tab === 'settings'}
					onClick={() => setTab('settings')}
					startIcon={<SettingsOutlinedIcon />}
					value={strings.settings}
				/>
			</TabsX>
		</div>
	);
};
