import React from 'react';
import { ILabelledPropertyProps } from './LabelledProperty';
import { VariantLabelledProperty } from './VariantLabelledProperty';

interface IProps extends ILabelledPropertyProps {
	successMessage: string;
}

export const SuccessLabelledProperty = ({ successMessage, ...rest }: IProps) => {
	return (
		<VariantLabelledProperty
			variantX='success'
			property={successMessage}
			{...rest}
		/>
	);
};
