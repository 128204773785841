import { nlApiErrorStrings } from './apiErrors/nlApiErrorStrings';
import { IFrameworkStrings } from './IFrameworkStrings';

export const nlFrameworkStrings: IFrameworkStrings = {
	...nlApiErrorStrings,
	actions: `Acties`,
	addWhat: (what: string | number) => `${what} toevoegen`,
	allAfter: `Alles ná`,
	allBefore: `Alles vóór`,
	amount: `Aantal`,
	busyUploadingDocument: `Bezig met document opladen`,
	cancel: `Annuleer`,
	changeColumns: `Wijzig kolommen`,
	changeSettings: `Wijzig instellingen`,
	clearFilter: `Verwijder filter`,
	close: `Sluiten`,
	createNew: `Creëer nieuw`,
	day: `Dag`,
	dayOrDays: `Dag(en)`,
	days: `Dagen`,
	delete: `Verwijder`,
	details: `Details`,
	dropDocumentHere: `Los document hier`,
	exportAllResultsCountInXlsxFormat: (count: string | number, format: string | number) =>
		`Exporteer alle resultaten (${count.toString().toLowerCase()}) in ${format.toString().toLowerCase()} formaat`,
	exportFilteredResultsCountInFormat: (count: string | number, format: string | number) =>
		`Exporteer ${count.toString().toLowerCase()} gefilterde resultaten in ${format.toString().toLowerCase()} formaat`,
	exportVerb: `Exporteer`,
	filter: `Filter`,
	formRequired: (field: string | number) => `${field} is een verplicht veld`,
	from: `Van`,
	hour: `Uur`,
	hourOrHours: `U(u)r(en)`,
	hours: `Uren`,
	invalidDateFormat: `Incorrect datum formaat`,
	lastMonth: `Vorige maand`,
	lastQuarter: `Laatste kwartaal`,
	lastWeek: `Vorige week`,
	lastYear: `Vorig jaar`,
	minute: `Minuut`,
	minuteOrMinutes: `Minu(u)t(en)`,
	minutes: `Minuten`,
	month: `Maand`,
	monthOrMonths: `Maand(en)`,
	months: `Maanden`,
	moreInformation: `Meer informatie`,
	new: `Nieuw`,
	next: `Volgende`,
	noAccessToThisFunction: `Je hebt onvoldoende rechten om deze functie uit te voeren`,
	none: `Geen`,
	notApplicableAbbreviation: `nvt`,
	previous: `Vorige`,
	releaseLeftMouseButtonToUploadFile: `Los linkermuisknop om bestand up te loaden`,
	reload: `Herlaad`,
	restrictions: `Restricties`,
	searchVerb: `Zoeken`,
	second: `Seconde`,
	secondOrSeconds: `Second(en)`,
	seconds: `Seconden`,
	select: `Selecteer`,
	selectAll: `Selecteer alles`,
	selectAllBelow: `Selecteer alles hieronder`,
	selectColumns: `Selecteer kolommen`,
	selectColumnsToShowBelow: `Selecteer de te tonen kolommen hieronder`,
	selectFromComputer: `Selecteer van computer`,
	selectMinMax: `Selecteer min/max`,
	selectNone: `Selecteer niets`,
	selectPriceRange: `Selecteer prijs tussen min/max`,
	showLess: `Toon minder`,
	start: `Start`,
	thisFileExtensionIsNotSupported: `Dit bestandsformaat wordt niet ondersteund`,
	thisMonth: `Deze maand`,
	thisQuarter: `Dit kwartaal`,
	thisWeek: `Deze week`,
	thisYear: `Dit jaar`,
	to: `Tot`,
	today: `Vandaag`,
	unit: `Eenheid`,
	update: `Update`,
	uploadADocument: `Upload een document`,
	viewMore: `Bekijk meer`,
	week: `Week`,
	weekOrWeeks: `We(e)k(en)`,
	weeks: `Weken`,
	year: `Jaar`,
	yearOrYears: `Ja(a)r(en)`,
	years: `Jaren`,
	yesterday: `Gisteren`,
	youCanAlsoDragAFileToHere: `Je kan ook een bestand hierheen slepen`,
};
