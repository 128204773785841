import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { CalculatorIcon } from 'framework/components/icons/CalculatorIcon';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IStockEntryDto, stockEntriesCommand_recalculateEverything } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsNavigateHeader } from 'shared/table/createDetailsNavigateHeader';
import { calcStockEntryDetailRoute } from '../routes';
import { Filter } from './Filter';
import { SelectableColumn } from './SelectableColumn';
import { StockEntriesBreadcrumbs } from './StockEntriesBreadcrumbs';
import { StockEntryNotUpToDateChip } from './StockEntryNotUpToDateChip';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';

export const StockEntriesList = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(QueryContext);
	const strings = useLocalization();
	const theme = useTheme();
	const { columns, setColumns, columnsRecord, headers } = useContext(ViewContext);
	const hasVeppClaim = useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim();
	const exclusions: SelectableColumn[] = useMemo(() => (hasVeppClaim ? [] : (['stockValueChange'] as SelectableColumn[])), [hasVeppClaim]);
	const userIsSuperUser = useLoggedInUserIsSuperUser();
	const { open, confirm, cancel } = useDialogsContext(reload);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onRecalculateEverything = () => {
		open(
			<AlertDialog
				open
				title={'Recalc everything'}
				content={`Continue?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => stockEntriesCommand_recalculateEverything()}
				accept={confirm}
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='StockEntries'
			stickyHeader={
				<div
					className='df-row-ac'
					style={{ paddingBottom: 16 }}>
					<StockEntriesBreadcrumbs />
					<div className='fg1'></div>
					<PageXHeaderActions>
						{userIsSuperUser && (
							<TooltipWithSpan title={`Delete and recalculate everything`}>
								<ColorOnHoverIconButton
									onClick={onRecalculateEverything}
									size='small'
									edge='end'>
									<CalculatorIcon />
								</ColorOnHoverIconButton>
							</TooltipWithSpan>
						)}
						<PageReloadButtonX reload={reload} />
					</PageXHeaderActions>
				</div>
			}>
			<div
				className='df-col fg1'
				style={{ padding: 16 }}>
				{queryResult.unfilteredCount > 0 && (
					<>
						<Card>
							<div className='df-row jc-sb'>
								<Filter
									params={params}
									onFilter={params => setParams(onResetToPage1(params))}
									paddingLeft={theme.spacing(1.5)}
								/>
								<SelectColumnsButtonAndDialog
									selectedColumns={columns}
									setSelectedColumns={setColumns}
									record={columnsRecord}
									view='stockEntries'
									exclusions={exclusions}
								/>
							</div>
							<Divider />
							<CardMedia>
								<PageableTableWithColumnSelection<IStockEntryDto, SelectableColumn>
									queryResult={queryResult}
									headers={headers}
									sortParams={params.sortParams}
									pageParams={params.pageParams}
									setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
									getKey={inst => inst.id ?? ''}
									selectedColumns={columns}
									prependColumn={createEmptyHeader<IStockEntryDto>(strings.status, t => (
										<StockEntryNotUpToDateChip item={t} />
									))}
									appendColumn={createDetailsNavigateHeader<IStockEntryDto>(calcStockEntryDetailRoute)}
								/>
							</CardMedia>
						</Card>
					</>
				)}
				{queryResult.unfilteredCount === 0 && <NoRecordsFound>{strings.noWhatRegistered(strings.evolution)}</NoRecordsFound>}
			</div>
		</RestrictedPageX>
	);
};
