import { CardProps, Divider } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffOutlinedIcon from '@material-ui/icons/LinkOffOutlined';
import { ExactGlobePcsQueryParamsLsKey } from 'app/localStorageKeys';
import { calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { InnerPageXWithStickySidebar } from 'framework/components/layout/InnerPageXWithStickySidebar';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { IQueryContext } from 'framework/context/IQueryContext';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import {
	exactGlobeProductCatalogItem_concurrencyToken,
	exactGlobeProductCatalogItem_query,
	exactGlobeProductCatalogItem_unlink,
	ExactGlobeProductCatalogItemPrefilter,
	ExactGlobeProductCatalogItemPrefilters,
	IExactGlobeProductCatalogItemDto,
	IExactGlobeProductCatalogItemsQueryParams,
	IQueryResult,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { LinkExactGlobeProductCatalogItemWithCostUnitDialog } from './link-exact-globe-pc-with-cost-unit.dialog';

export const ExactGlobePcsQueryContext = React.createContext<
	IQueryContext<IQueryResult<IExactGlobeProductCatalogItemDto>, IExactGlobeProductCatalogItemsQueryParams>
>(undefined as any);

const DefaultParams: IExactGlobeProductCatalogItemsQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'identifier' },
	pageParams: { index: 1, size: 10 },
	prefilter: 'NotLinked',
	types: [],
};

export const ExactGlobePcsQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IExactGlobeProductCatalogItemDto>, IExactGlobeProductCatalogItemsQueryParams>
			defaultParams={DefaultParams}
			queryF={exactGlobeProductCatalogItem_query}
			concurrencyF={exactGlobeProductCatalogItem_concurrencyToken}
			localStorageKey={ExactGlobePcsQueryParamsLsKey}
			context={ExactGlobePcsQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};

interface IProps extends IReloadTriggerProps {
	reload: VoidFunction;
}

export const ExactGlobePcsTab = ({ reload, reloadTrigger }: IProps) => {
	const strings = useLocalization();
	const { reload: reloadQuery, queryResult, params, setParams } = useQueryContext(ExactGlobePcsQueryContext);

	useLazyEffect(() => {
		reloadQuery();
	}, [reloadTrigger]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<InnerPageXWithStickySidebar
			maxWidth={1280}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<ExactGlobePcsFilterCard
						params={params}
						setParams={setParams}
						style={{ minWidth: 220 }}
					/>
					<div className='fg1'></div>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16, paddingLeft: 8 }}>
				<SearchbarX
					placeholder={`Zoeken...`}
					value={params.searchString}
					onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
				/>
				<CardX className='w100'>
					<div
						className='df-row'
						style={{ gap: 8 }}>
						<Typography16pxBold>{strings.results}</Typography16pxBold>
						<div className='fg1'></div>
					</div>
					<Divider style={{ marginTop: 8 }} />
					{queryResult.values.map(t => (
						<ExactGlobeProductCatalogItemListItem
							key={t.id}
							item={t}
							reload={reload}
						/>
					))}
					<QueryResultPaginationX
						queryResult={queryResult}
						setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
						style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
					/>
				</CardX>
			</div>
		</InnerPageXWithStickySidebar>
	);
};

interface IExactGlobePcsFilterCardProps extends CardProps {
	params: IExactGlobeProductCatalogItemsQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IExactGlobeProductCatalogItemsQueryParams>>;
}

const ExactGlobeProductCatalogItemPrefilterRecord: Record<ExactGlobeProductCatalogItemPrefilter, string> = {
	All: 'Alles',
	Linked: 'Gelinkt',
	NotLinked: 'Niet gelinkt',
};

const ExactGlobePcsFilterCard = ({ params, setParams, style, ...rest }: IExactGlobePcsFilterCardProps) => {
	const strings = useLocalization();
	const onFilter = (pars: IExactGlobeProductCatalogItemsQueryParams) => setParams(onResetToPage1(pars));

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-2'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<div className='df-col'>
				{ExactGlobeProductCatalogItemPrefilters.map(prefilter => (
					<RadioIsSelectedButton
						key={prefilter}
						isSelected={prefilter === params.prefilter}
						onClick={() => onFilter({ ...params, prefilter: prefilter })}>
						{ExactGlobeProductCatalogItemPrefilterRecord[prefilter]}
					</RadioIsSelectedButton>
				))}
			</div>
			<Divider />
		</CardX>
	);
};

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IExactGlobeProductCatalogItemListItemProps {
	item: IExactGlobeProductCatalogItemDto;
	withoutBorder?: boolean;
	reload: VoidFunction;
}

export const ExactGlobeProductCatalogItemListItem = ({ item, withoutBorder, reload }: IExactGlobeProductCatalogItemListItemProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const color = useMemo<ColorX>(() => (item.isLinked ? 'success' : 'error'), [item]);
	const tColor = useThemeColor(color);
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onUnlink = () => {
		open(
			<AlertDialog
				open
				title={strings.deleteLink}
				content={`Link verwijderen?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactGlobeProductCatalogItem_unlink({ hamsProductCatalogItemId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onLink = () => {
		open(
			<LinkExactGlobeProductCatalogItemWithCostUnitDialog
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<ExactGlobeProductCatalogItemListItemStatusIcon
					item={item}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col gap-4 fg1'>
				<div className='df-row-ac'>
					<TextLinkButton
						onClick={() => push(calcProductCatalogDetailRoute(item.id))}
						style={{ fontSize: MajorFontSize }}>
						{item.identifier}
					</TextLinkButton>
				</div>
				<ChipX
					label={item.isLinked ? strings.linked : strings.notLinked}
					color={color}
					style={{ marginRight: 'auto' }}
				/>
				{item.isLinked && (
					<>
						<div
							className='df-row-ac'
							style={{ fontSize: MinorFontSize, gap: 4 }}>
							<span>{`Kostendrager:`}</span>
							<TextLinkButton
								style={{ fontSize: MinorFontSize }}
								onClick={() => {}}>
								{`${item.costUnit.code} ${item.costUnit.description}`}
							</TextLinkButton>
						</div>
						<TextLinkButton
							hoverColor='secondary'
							startIcon={<LinkOffOutlinedIcon />}
							onClick={onUnlink}>
							{strings.deleteLinkImperative}
						</TextLinkButton>
					</>
				)}
				{item.isLinked === false && (
					<TextLinkButton
						color='primary'
						startIcon={<LinkIcon />}
						onClick={onLink}>
						{strings.link}
					</TextLinkButton>
				)}
			</div>
		</div>
	);
};

interface IExactGlobeProductCatalogItemListItemStatusIconProps {
	item: IExactGlobeProductCatalogItemDto;
	style?: React.CSSProperties;
}

export const ExactGlobeProductCatalogItemListItemStatusIcon = ({ item, style }: IExactGlobeProductCatalogItemListItemStatusIconProps) => {
	if (item.isLinked) {
		return <CheckCircleOutlineIcon style={style} />;
	} else {
		return <BlockIcon style={style} />;
	}
};
