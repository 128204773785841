import { IProductCatalogItem, IStockAtLocation } from 'gen/ApiClient';
import { IInvoiceLine } from '../IInvoiceLine';

export const productCatalogItemToLine = (t: IProductCatalogItem, stockAtLocations: IStockAtLocation[], isCredit: boolean): IInvoiceLine => {
	return {
		isSelected: true,
		id: `${t.id}${isCredit ? '-CREDIT' : ''}`,
		description: t.identifier,
		inventoryItemId: undefined,
		productCatalogItemId: t.id,
		quantity: isCredit ? -1 : 1,
		unitListPrice: t.salesListPrice,
		unitPrice: t.salesListPrice,
		vatPercentage: t.vatPercentage,
		productCatalogItem: t,
		assuredInventoryItemId: undefined,
		assuredLineId: undefined,
		assuredProductCatalogItemId: undefined,
		serialNumber: undefined,
		canChangeQuantity: true,
		stockAtLocations: stockAtLocations,
		inventoryItem: undefined,
		isCredit: isCredit,
	};
};
