import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { companiesQuery_concurrencyToken, companiesQuery_query, ICompaniesQueryParams, ICompany, IQueryResult } from 'gen/ApiClient';
import { companiesQueryParamsLsKey } from '../../../localStorageKeys';
import { CompaniesQueryContext } from './CompaniesQueryContext';

const DefaultParams: ICompaniesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdBefore: undefined,
	createdAfter: undefined,
};

export const CompaniesQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<ICompany>, ICompaniesQueryParams>
			defaultParams={DefaultParams}
			queryF={companiesQuery_query}
			concurrencyF={companiesQuery_concurrencyToken}
			localStorageKey={companiesQueryParamsLsKey}
			context={CompaniesQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
