import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { IRepair, RepairWarning } from 'gen/ApiClient';
import React, { useMemo, useContext } from 'react';
import { RepairProcessStatusChipX } from '../RepairProcessStatusChipX';
import { mapRepairToColorX } from '../list/utils';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	item: IRepair;
	style?: React.CSSProperties;
}

export const RepairChips = ({ item, style }: IProps) => {
	const color = useMemo(() => mapRepairToColorX(item), [item]);
	const { repairWarningRecord } = useContext(RecordContext);

	return (
		<div
			className='df-row-ac gap-4'
			style={style}>
			<RepairProcessStatusChipX
				item={item}
				color={color}
			/>
			{item.hasWarning && <VariantLabelledPropertyX variant='warning'>{repairWarningRecord[item.warning as RepairWarning]}</VariantLabelledPropertyX>}
		</div>
	);
};
