import { Divider, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import { ExcelIcon } from 'framework/components/icons/ExcelIcon';
import { FileDelimitedOutlineIcon } from 'framework/components/icons/FileDelimitedOutlineIcon';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { FilterOutlineIcon } from 'framework/components/icons/FilterOutlineIcon';
import { ExportFormat } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	filteredCount: number;
	unfilteredCount: number;
	onExportClick: (applyFilter: boolean, format: ExportFormat) => void;
	children?: any;
	menuAnchor: HTMLElement | null;
	closeMenu: VoidFunction;
}

export const ExportMenu = ({ filteredCount, unfilteredCount, onExportClick, children, menuAnchor, closeMenu }: IProps) => {
	const strings = useLocalization();

	const onMenuClick = (applyFilter: boolean, format: ExportFormat) => {
		onExportClick(applyFilter, format);
		closeMenu();
	};

	return (
		<Menu
			anchorEl={menuAnchor}
			keepMounted
			open={Boolean(menuAnchor)}
			onClose={closeMenu}>
			{filteredCount !== unfilteredCount && (
				<MenuItem onClick={() => onMenuClick(true, 'Xlsx')}>
					<ListItemIcon>
						<FilterOutlineIcon fontSize='small' />
						<ExcelIcon fontSize='small' />
					</ListItemIcon>
					<Typography variant='inherit'>{strings.exportFilteredResultsCountInFormat(filteredCount, '.xlsx')}</Typography>
				</MenuItem>
			)}
			<MenuItem onClick={() => onMenuClick(false, 'Xlsx')}>
				<ListItemIcon>
					<FilterOffOutlineIcon fontSize='small' />
					<ExcelIcon fontSize='small' />
				</ListItemIcon>
				<Typography variant='inherit'>{strings.exportAllResultsCountInXlsxFormat(unfilteredCount, '.xlsx')}</Typography>
			</MenuItem>
			<Divider />
			{filteredCount !== unfilteredCount && (
				<MenuItem onClick={() => onMenuClick(true, 'Csv')}>
					<ListItemIcon>
						<FilterOutlineIcon fontSize='small' />
						<FileDelimitedOutlineIcon fontSize='small' />
					</ListItemIcon>
					<Typography variant='inherit'>{strings.exportFilteredResultsCountInFormat(filteredCount, '.csv')}</Typography>
				</MenuItem>
			)}
			<MenuItem onClick={() => onMenuClick(false, 'Csv')}>
				<ListItemIcon>
					<FilterOffOutlineIcon fontSize='small' />
					<FileDelimitedOutlineIcon fontSize='small' />
				</ListItemIcon>
				<Typography variant='inherit'>{strings.exportAllResultsCountInXlsxFormat(unfilteredCount, '.csv')}</Typography>
			</MenuItem>
			{children && <Divider />}
			{children && children}
		</Menu>
	);
};
