import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IEndLoanRequest, loansCommand_end } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import * as yup from 'yup';
import { LocationsPreloadCacheContext } from '../../../settings/locations/LocationsPreloadCacheContext';
import { useCurrentLocation } from '../../../settings/locations/useCurrentLocation';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IEndLoanRequest>({
			returnToLocationId: yup.string().required(),
			stopDate: yup.date().required(strings.formRequired(strings.stopDate)),
			loanId: yup.string().required(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IEndLoanRequest)[]> = {
	0: ['returnToLocationId'],
	1: ['stopDate'],
};

interface IProps extends DialogProps {
	loanId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const EndLoanForm = ({ loanId, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(loansCommand_end);
	const [step, setStep] = useState<number>(0);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const location = useCurrentLocation();

	const handleSubmit = async (values: IEndLoanRequest, helpers: FormikHelpers<IEndLoanRequest>) => {
		// TODO meer controleren! -> max. value of stockItems will be max value available at location!
		const r = await update(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.loanEnded);
			confirm();
		}
	};

	if (locations === undefined || location === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IEndLoanRequest>
			validateOnMount
			initialValues={{
				returnToLocationId: location.id,
				stopDate: undefined as any,
				loanId: loanId,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='md'>
					<DialogTitleWithFormStepper
						title={strings.endLoan}
						step={step}
						labels={[strings.location, strings.date]}
					/>
					<DialogContent dividers>
						{step === 0 && (
							<div className='df-col'>
								<FormSelectFieldWithOption<IEndLoanRequest, string>
									name='returnToLocationId'
									options={locations}
									label={strings.location}
									helperText={strings.locationWhereProductsWillBeReceived}
									required
								/>
							</div>
						)}
						{step === 1 && (
							<div className='df-col'>
								<FormDatePicker<IEndLoanRequest>
									name='stopDate'
									label={strings.endDate}
									disablePast
								/>
							</div>
						)}
					</DialogContent>
					<PageableFormDialogActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
