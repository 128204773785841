import React from 'react';
import { ApiTokensQueryProvider } from './list/ApiTokensQueryProvider';
import { ApiTokensListViewProvider } from './list/ApiTokensListViewProvider';

export const ApiTokensProviders = ({ children }: any) => {
	return (
		<ApiTokensQueryProvider>
			<ApiTokensListViewProvider>{children}</ApiTokensListViewProvider>
		</ApiTokensQueryProvider>
	);
};
