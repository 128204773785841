import { Dialog, DialogContent, DialogProps, DialogTitle, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, useTheme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { FilterOffIcon } from 'framework/components/icons/FilterOffIcon';
import { Searchbar } from 'framework/components/search/Searchbar';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { RadioFilterComponent } from 'framework/filter/RadioFilterComponent';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IOption } from 'framework/IOption';
import { isLast } from 'framework/utils/array/isLast';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import {
	IMedicalTreatmentNomenclatureForAudiology,
	IRequestForInsuranceAllowance,
	patientsQuery_filterForMedicalTreatmentNomenclatures,
	rfiasCommand_clearMainMedicalTreatment,
	rfiasCommand_selectMainMedicalTreatment,
} from 'gen/ApiClient';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MedicalTreatmentNomenclaturesCacheContext } from 'shared/context/MedicalTreatmentNomenclaturesCacheContext';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { MedicalTreatmentNomenclatureDialog } from 'shared/medicalTreatmentNomenclatures/MedicalTreatmentNomenclatureDialog';
import { RecordContext } from 'shared/records/RecordContext';
import { AgeRestriction, AgeRestrictions } from './AgeRestriction';
import { StereoMonoContralateralType, StereoMonoContralateralTypes } from './StereoMonoContralateralType';
import { ageToRestriction } from './utils/ageToRestriction';
import { useCacheContext } from 'framework/hooks/useCacheContext';

interface IFilterData {
	isAmbulant: boolean | undefined;
	ageRestriction: AgeRestriction | undefined;
	stereoMonoOrNa: StereoMonoContralateralType | undefined;
	searchString: string;
}

const DefaultFilterData: IFilterData = {
	isAmbulant: true,
	ageRestriction: undefined,
	stereoMonoOrNa: undefined,
	searchString: '',
};

interface IProps extends DialogProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const MainMedicalTreatmentWizardForm = ({ rfia, confirm, cancel, ...rest }: IProps) => {
	const { open, cancel: closeDetail, isStacked } = useDialogsContext();
	const theme = useTheme();
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [selectedCode, setSelectedCode] = useState<string>();
	const [submit, isSubmitting] = useFormSubmit(rfiasCommand_selectMainMedicalTreatment);
	const [data] = useCacheContext(MedicalTreatmentNomenclaturesCacheContext);
	const [params, setParams] = useState<IFilterData>(DefaultFilterData);
	const [filtered, setFiltered] = useState<IMedicalTreatmentNomenclatureForAudiology[]>([]);
	const [preFilter] = useApiEffect(patientsQuery_filterForMedicalTreatmentNomenclatures, rfia.patientId);
	const [clearMainNomenclature] = useFormSubmit(rfiasCommand_clearMainMedicalTreatment);
	const isFiltered = useMemo(() => params.ageRestriction !== undefined || params.stereoMonoOrNa !== undefined, [params]);
	const language = useCurrentLanguage();
	const { stereoMonoContralateralRecord } = useContext(RecordContext);

	useEffect(() => {
		if (preFilter) {
			setParams({
				isAmbulant: preFilter.isAmbulant ?? true,
				stereoMonoOrNa: undefined,
				ageRestriction: ageToRestriction(preFilter.age),
				searchString: '',
			});
		}
	}, [preFilter]);

	useEffect(() => {
		if (data !== undefined) {
			const x = data
				// TODO delete the isNullOrUndefined after 1/10/2021
				.filter(
					t => t.isMain === true && t.data.isCurrentlyValid === true && t.isLumpSum === false && t.isPseudoCode === false && isNullOrUndefined(t.validityEnd)
				)
				.filter(t => params.isAmbulant === undefined || t.data.isAmbulant === params.isAmbulant)
				.filter(
					t =>
						params.stereoMonoOrNa === undefined ||
						(params.stereoMonoOrNa === 'stereo' && t.isStereo) ||
						(params.stereoMonoOrNa === 'mono' && t.isMono) ||
						(params.stereoMonoOrNa === 'contraLateral' && t.isContraLateral)
				)
				.filter(
					t =>
						params.ageRestriction === undefined ||
						(params.ageRestriction === '<18' && t.isAgeRestrictedToLessThan18Years) ||
						(params.ageRestriction === '>18' && t.isAgeRestrictedToOlderThan18Years) ||
						(params.ageRestriction === '>18&<65' && t.isAgeRestrictedBetween18And65Years) ||
						(params.ageRestriction === '>65' && t.isAgeRestrictedToOlderThan65Years)
				)
				.filter(t => `${t.data.code} ${t.data.descriptionNl} ${t.data.descriptionFr}`.toLowerCase().includes(params.searchString.toLowerCase()));
			setFiltered(x);
		}
	}, [params, data]);

	const handleSubmit = async (code: string, beforeConfirm?: VoidFunction) => {
		setSelectedCode(code);
		const r = await submit(rfia.id, code);
		// TODO handle response ico validation error
		if (r.isSuccess) {
			notify(strings.selectedWhat(strings.mainNomenclatureCode));
			if (beforeConfirm) {
				beforeConfirm();
			}
			confirm();
		}
	};

	const handleClear = async () => {
		const r = await clearMainNomenclature(rfia.id);
		if (r.isSuccess) {
			notify(strings.deletedWhat(strings.mainNomenclatureCode));
			closeDetail();
			confirm();
		}
	};

	if (data === undefined || preFilter === undefined) {
		return <div></div>;
	}

	const onClearFilters = () => {
		setParams({ ...params, stereoMonoOrNa: undefined, ageRestriction: undefined });
	};

	const onViewDetail = (code: string) => {
		open(
			<MedicalTreatmentNomenclatureDialog
				code={code}
				open
				cancel={closeDetail}
				select={rfia.mainMedicalTreatmentNomenclatureCode !== code ? async () => await handleSubmit(code, closeDetail) : undefined}
				clear={rfia.mainMedicalTreatmentNomenclatureCode === code ? handleClear : undefined}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			scroll='paper'
			fullWidth
			open={rest.open && isStacked === false}>
			<CloseButtonOnAbsolutePosition onClick={cancel} />
			<DialogTitle>{strings.selectWhat(strings.mainNomenclatureCode)}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<DividerWithCaption
					caption={strings.filter}
					action={
						<SmallPrimaryTextButton
							onClick={onClearFilters}
							disabled={isFiltered === false}
							startIcon={<FilterOffIcon />}
							color='secondary'>
							{strings.clearFilter}
						</SmallPrimaryTextButton>
					}
				/>
				<div className='df-col-as'>
					<BooleanRadioFilterComponent
						style={{ marginBottom: theme.spacing(0.5) }}
						badgeVariant='dot'
						trueText={strings.ambulatory}
						falseText={strings.hospitalized}
						selected={params.isAmbulant}
						setSelected={val => setParams({ ...params, isAmbulant: val })}
						label={params.isAmbulant ? strings.ambulatory : strings.hospitalized}
						icon={<HelpOutlineIcon />}
					/>
					<RadioFilterComponent<AgeRestriction>
						style={{ marginBottom: theme.spacing(0.5) }}
						badgeVariant='dot'
						options={AgeRestrictions.map<IOption<AgeRestriction>>(t => ({ id: t, identifier: t }))}
						selected={params.ageRestriction}
						setSelected={val => setParams({ ...params, ageRestriction: val })}
						label={params.ageRestriction ? params.ageRestriction : strings.age}
						icon={<HelpOutlineIcon />}
					/>
					<RadioFilterComponent<StereoMonoContralateralType>
						style={{ marginBottom: theme.spacing(0.5) }}
						badgeVariant='dot'
						options={StereoMonoContralateralTypes.map<IOption<StereoMonoContralateralType>>(t => ({ id: t, identifier: stereoMonoContralateralRecord[t] }))}
						selected={params.stereoMonoOrNa}
						setSelected={val => setParams({ ...params, stereoMonoOrNa: val })}
						label={
							params.stereoMonoOrNa === undefined
								? `${strings.stereoPhonic}/${strings.monoPhonic}/${strings.contraLateralEquipment}`
								: stereoMonoContralateralRecord[params.stereoMonoOrNa]
						}
						icon={<HelpOutlineIcon />}
					/>
				</div>
				<DividerWithCaption caption={strings.search} />
				<div className='df-row-ac jc-sb'>
					<Searchbar
						value={params.searchString}
						onSearch={text => setParams({ ...params, searchString: text })}
						placeholder={strings.searchVerb}
						style={{ width: '100%' }}
						variant='paper'
					/>
				</div>
				<DividerWithCaption caption={`${strings.filteredResults} (${filtered.length.toString()})`} />
				<List
					dense
					style={{ overflow: 'auto', marginLeft: '-24px', marginRight: '-24px' }}>
					{filtered.map(t => (
						<div
							className='df-col'
							key={t.data.code}>
							<ListItem
								button
								onClick={() => onViewDetail(t.data.code)}
								style={{ paddingLeft: '29px' }}
								disabled={isSubmitting}>
								<ListItemText
									primary={t.data.code}
									secondary={language === 'fr' ? t.data.descriptionFr : t.data.descriptionNl}
									style={{ paddingRight: '40px' }}
								/>
								<ListItemSecondaryAction>
									<LoaderButton
										isLoading={isSubmitting && selectedCode === t.data.code}
										disabled={isSubmitting}
										variant='text'
										size='small'
										color='primary'
										onClick={() => handleSubmit(t.data.code)}>
										{strings.select}
									</LoaderButton>
								</ListItemSecondaryAction>
							</ListItem>
							{isLast(t, filtered) === false && <Divider />}
						</div>
					))}
				</List>
			</DialogContent>
		</Dialog>
	);
};
