import { MbscEventcalendarView } from '@mobiscroll/react';
import { AgendaTimeScale } from './AgendaTimeScale';
import { AgendaViewType } from './AgendaViewType';

export const mapAgendaViewTypeToMobiscrollView = (view: AgendaViewType, scale: AgendaTimeScale): MbscEventcalendarView => {
	if (view === 'day') {
		return { schedule: { type: 'day', timeCellStep: scale, timeLabelStep: scale } };
	} else if (view === 'week') {
		return { schedule: { type: 'week', timeCellStep: scale, timeLabelStep: scale } };
	} else if (view === 'timeline') {
		return { timeline: { type: 'day', timeCellStep: scale, timeLabelStep: scale } };
	} else {
		return AgendaViewTypeToMobiscrollCalendarViewRecord[view];
	}
};

const AgendaViewTypeToMobiscrollCalendarViewRecord: Record<AgendaViewType, MbscEventcalendarView> = {
	day: { schedule: { type: 'day' } },
	week: { schedule: { type: 'week' } },
	month: { calendar: { type: 'month' } },
	year: { calendar: { type: 'year' } },
	list: { agenda: { type: 'month' } },
	timeline: { timeline: { type: 'day' } },
};
