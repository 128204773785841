import { Card, CardContent } from '@material-ui/core';
import { useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { NoAccessToThisFunctionLabelledProperty } from 'framework/components/labelledProperty/NoAccessToThisFunctionLabelledProperty';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IInventoryItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: IInventoryItem;
	style?: React.CSSProperties;
}

export const PricesAndMarginsCard = ({ item, style }: IProps) => {
	const strings = useLocalization();
	const hasVeppClaim = useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim();

	return (
		<Card
			style={style}
			className='df-col'>
			<CardContent>
				<CardTitleTypography title={strings.prices} />
				<LabelledProperty
					label={strings.vat}
					property={`${item.vatPercentage}%`}
				/>
				<LabelledProperty
					label={strings.salesListPriceShort}
					property={formatCurrency(item.salesListPrice)}
				/>
				<NoAccessToThisFunctionLabelledProperty
					hasAccess={hasVeppClaim}
					label={strings.purchaseListPriceShort}
					property={formatCurrency(item.purchaseListPrice)}
				/>
				{item.isSold && (
					<LabelledProperty
						label={strings.salesPrice}
						property={formatCurrency(item.salesPrice)}
					/>
				)}
				{item.isPurchased && (
					<NoAccessToThisFunctionLabelledProperty
						hasAccess={hasVeppClaim}
						label={strings.purchasePrice}
						property={formatCurrency(item.purchasePrice)}
					/>
				)}
				{item.isSold && item.isPurchased && (
					<>
						<LabelledProperty
							label={`${strings.salesPrice} (${strings.exVatShort})`}
							property={formatCurrency(item.salesPriceExVat)}
						/>
						<TooltipWithSpan title={strings.marginTooltip}>
							<NoAccessToThisFunctionLabelledProperty
								hasAccess={hasVeppClaim}
								label={strings.margin}
								property={`${item.margin.toFixed(2)} (${(item.margin * 100).toFixed(2)} %)`}
							/>
						</TooltipWithSpan>
						<TooltipWithSpan title={strings.markupTooltip}>
							<NoAccessToThisFunctionLabelledProperty
								hasAccess={hasVeppClaim}
								label={strings.markup}
								property={`${item.markup.toFixed(2)} (${(item.markup * 100).toFixed(2)} %)`}
							/>
						</TooltipWithSpan>
						<NoAccessToThisFunctionLabelledProperty
							hasAccess={hasVeppClaim}
							label={`${strings.profit} (${strings.exVatShort})`}
							property={formatCurrency(item.profit)}
						/>
					</>
				)}
			</CardContent>
		</Card>
	);
};
