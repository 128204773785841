import { Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { toggleSelect } from 'framework/utils/array/toggleSelect';
import { nameOf } from 'framework/utils/nameOf';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';

interface IProps<TModel, TStep extends string> {
	steps: TStep[];
	name: keyof TModel;
	isOptional: (step: TStep) => boolean;
	record: Record<TStep, string>;
	captionRecord?: Record<TStep, string>;
}

export const StepsField = <TModel extends unknown, TStep extends string>({ steps, name, isOptional, record, captionRecord }: IProps<TModel, TStep>) => {
	const props = useFormikContext<TModel>();
	const [field] = useField<TStep[]>(nameOf(name));

	return (
		<List
			dense
			style={{ marginLeft: '-24px', marginRight: '-24px' }}>
			{steps.map(t => (
				<ListItem
					key={t}
					button
					disabled={isOptional(t) === false}
					onClick={() => setFormValue<TModel>(name, toggleSelect(t, field.value), props)}>
					<ListItemIcon>
						<Checkbox checked={field.value.indexOf(t) !== -1} />
					</ListItemIcon>
					<ListItemText
						primary={record[t]}
						secondary={captionRecord && isNotNullNorUndefined(captionRecord[t]) ? captionRecord[t] : undefined}
					/>
				</ListItem>
			))}
		</List>
	);
};
