import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { useLoggedInLocation } from 'app/auth/useLoggedInLocation';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { ISupplierWithDefaultDeliveryTime } from 'app/main/contacts/suppliers/ISupplierWithDefaultDeliveryTime';
import { FormSelectUserField } from 'app/main/settings/users/forms/FormSelectUserField';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { updateArray } from 'framework/utils/array/updateArray';
import {
	articlesInquiriesCommand_registerNewOrder,
	IArticlesInquiryDto,
	IOrderArticlesInquiryRequest,
	ordersQuery_nextReference,
	ordersQuery_settings,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { FormSelectDeliveryTimeFromSupplierOrManualField } from 'shared/forms/FormSelectDeliveryTimeFromSupplierOrManualField';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { LocationsPreloadCacheContext } from '../../../../settings/locations/LocationsPreloadCacheContext';
import { IArticlesInquiryLineDtoWithIsSelected } from './IArticlesInquiryLineDtoWithIsSelected';
import { mapArticlesInquiryLineDtoWithIsSelectedToLineRequest } from './mapArticlesInquiryLineDtoWithIsSelectedToLineRequest';
import { OrderArticlesInquiryLineComponent } from './OrderArticlesInquiryLineComponent';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IOrderArticlesInquiryRequest>({
			expectedDeliveryDate: yup.date().required(strings.formRequired(strings.expectedDeliveryDate)),
			externalReference: yup.string(),
			lines: yup.mixed(),
			locationId: yup.string().required(strings.formRequired(strings.location)),
			supplierId: yup.string().required(strings.formRequired(strings.supplier)),
			reference: yup.string().required(),
			isAutoSelectReference: yup.boolean().defined(),
			handlingUserId: yup.string().required(),
			placeOrderImmediately: yup.bool().defined(),
			articlesInquiryId: yup.string().required(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IOrderArticlesInquiryRequest)[]> = {
	0: ['reference', 'isAutoSelectReference', 'externalReference'],
	1: ['locationId', 'expectedDeliveryDate', 'handlingUserId'],
	2: ['lines'],
	3: ['placeOrderImmediately'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	supplier: ISupplierWithDefaultDeliveryTime;
	previous?: VoidFunction;
	articlesInquiry: IArticlesInquiryDto;
}

export const OrderArticlesInquiryForm = ({ confirm, cancel, previous, articlesInquiry, supplier, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, setLines] = useState<IArticlesInquiryLineDtoWithIsSelected[]>(
		articlesInquiry.lines.filter(t => t.canOrder).map(t => ({ isSelected: true, quantityToOrder: t.quantityToAssign, ...t }))
	);
	const [step, setStep] = useState<number>(0);
	const [create, isSubmitting] = useFormSubmit(articlesInquiriesCommand_registerNewOrder);
	const user = useLoggedInUser();
	const locationId = useLoggedInLocation();
	const notify = useSnackbarNotify();
	const now = useNow();

	const handleSubmit = async (values: IOrderArticlesInquiryRequest, helpers: FormikHelpers<IOrderArticlesInquiryRequest>) => {
		const fLines = lines.filter(t => t.isSelected && t.quantityToOrder > 0);
		if (fLines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await create({ ...values, lines: fLines.map(mapArticlesInquiryLineDtoWithIsSelectedToLineRequest) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify(strings.orderPlaced);
				confirm(r.result.objectId);
			}
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IOrderArticlesInquiryRequest>
			validateOnMount
			initialValues={{
				externalReference: '',
				lines: [],
				reference: '',
				isAutoSelectReference: true,
				placeOrderImmediately: false,
				expectedDeliveryDate: now,
				handlingUserId: user.id,
				locationId: locationId ?? '',
				supplierId: supplier.id,
				articlesInquiryId: articlesInquiry.id,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					isSubmitting={isSubmitting}
					cancel={cancel}
					lines={lines}
					setLines={setLines}
					step={step}
					setStep={setStep}
					stepsRecord={stepsRecord}
					previous={previous}
					supplier={supplier}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	lines: IArticlesInquiryLineDtoWithIsSelected[];
	setLines: React.Dispatch<React.SetStateAction<IArticlesInquiryLineDtoWithIsSelected[]>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof IOrderArticlesInquiryRequest)[]>;
	previous: VoidFunction | undefined;
	supplier: ISupplierWithDefaultDeliveryTime;
}

const InnerDialog = ({ cancel, lines, setLines, isSubmitting, step, setStep, stepsRecord, previous, supplier, ...rest }: IInnerProps) => {
	const props = useFormikContext<IOrderArticlesInquiryRequest>();
	const strings = useLocalization();
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	useNextReferenceInterceptor(ordersQuery_nextReference, props);
	const [settings] = useApiEffect(ordersQuery_settings);

	useEffect(() => {
		if (locations?.length === 1) {
			setFormValue<IOrderArticlesInquiryRequest>('locationId', locations[0].id, props);
		}
		// eslint-disable-next-line
	}, [locations]);

	if (locations === undefined || settings === undefined) {
		return <div></div>;
	}

	return (
		<Dialog {...rest}>
			<DialogTitleWithFormStepper
				step={step}
				title={strings.newOrderWithWho(supplier.name ?? '')}
				labels={[strings.referenceShort, strings.details, strings.items, strings.confirm]}
			/>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IOrderArticlesInquiryRequest>
							name='isAutoSelectReference'
							label={strings.autoSetOrderNumber}
						/>
						<FormTextField<IOrderArticlesInquiryRequest>
							name='reference'
							label={strings.orderNumber}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormTextField<IOrderArticlesInquiryRequest>
							name='externalReference'
							label={`${strings.externalReference} (${strings.fromSupplier})`}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectFieldWithOption<IOrderArticlesInquiryRequest, string>
							name='locationId'
							options={locations}
							label={strings.location}
							helperText={strings.selectDeliveryLocationHelperText}
						/>
						<FormSelectDeliveryTimeFromSupplierOrManualField<IOrderArticlesInquiryRequest>
							name='expectedDeliveryDate'
							label={strings.expectedDeliveryDate}
							supplier={supplier}
							defaultDeliveryTime={settings.defaultDeliveryTime}
						/>
						<FormSelectUserField<IOrderArticlesInquiryRequest>
							name='handlingUserId'
							label={strings.user}
						/>
					</>
				)}
				{step === 2 && (
					<div className='df-col gap-16'>
						{lines.map((t, index) => (
							<OrderArticlesInquiryLineComponent
								key={t.id}
								line={t}
								index={index}
								setLine={line => setLines(updateArray(t, lines, line))}
							/>
						))}
					</div>
				)}
				{step === 3 && (
					<FormExtendedRadioGroupField<IOrderArticlesInquiryRequest, boolean>
						name='placeOrderImmediately'
						label=''
						options={[
							{ value: true, label: strings.placeOrderImmediatelyWithSupplier, caption: strings.placeOrderImmediatelyWithSupplierContent },
							{ value: false, label: strings.placeOrderWithSupplierLater, caption: strings.placeOrderWithSupplierLaterContent },
						]}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
				previous={previous}
			/>
		</Dialog>
	);
};
