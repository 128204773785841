import { useTheme } from '@material-ui/core';
import { QuantityField } from 'framework/components/textFields/QuantityField';
import { formatCurrency } from 'framework/utils/formatCurrency';
import React, { useMemo } from 'react';
import { EditableDescription } from './EditableDescription';
import { PositiveOrNegativeType } from './PositiveOrNegativeType';

interface ILine {
	quantity: number;
	description: string;
	unitPrice: number;
}

interface IProps<TLine extends ILine> {
	line: TLine;
	setLine: (val: TLine) => void;
	onClickPrice: VoidFunction;
	style?: React.CSSProperties;
	disableQuantity?: boolean;
	mode?: PositiveOrNegativeType;
}

export const LineDescriptionQuantityAndLinePrice = <TLine extends ILine>({ line, setLine, onClickPrice, style, disableQuantity, mode }: IProps<TLine>) => {
	const linePrice = useMemo(() => line.unitPrice * line.quantity, [line]);
	const isDiscount = useMemo(() => line.unitPrice < 0, [line]);
	const theme = useTheme();

	return (
		<div
			className='df-row-ac'
			style={style}>
			<EditableDescription
				text={line.description}
				setText={val => setLine({ ...line, description: val })}
				style={{ marginRight: 8, color: isDiscount ? theme.palette.error.main : 'inherit' }}
			/>
			<QuantityField
				value={line.quantity}
				setValue={val => setLine({ ...line, quantity: val })}
				disabled={disableQuantity}
				mode={mode}
			/>
			<div
				style={{
					fontWeight: 'bold',
					marginLeft: 8,
					minWidth: '10ch',
					textAlign: 'right',
					cursor: 'pointer',
					color: isDiscount ? theme.palette.error.main : 'inherit',
				}}
				onClick={onClickPrice}>
				{formatCurrency(linePrice)}
			</div>
		</div>
	);
};
