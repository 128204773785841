import { Chip } from '@material-ui/core';
import React from 'react';
import { useDialogsContext } from '../dialogs/useDialogsContext';
import { RizivRefundableHearingInstrumentDialog } from './RizivRefundableHearingInstrumentDialog';

interface IProps {
	identificationCode: number | string;
	reload: VoidFunction;
	noMargin?: boolean;
}

export const RizivRefundableHearingInstrumentChip = ({ identificationCode, reload, noMargin = false }: IProps) => {
	const { open, cancel } = useDialogsContext(reload);

	const onViewDetail = () => {
		open(
			<RizivRefundableHearingInstrumentDialog
				open
				identificationCode={identificationCode}
				cancel={cancel}
			/>
		);
	};

	return (
		<Chip
			size='small'
			label={identificationCode}
			onClick={onViewDetail}
			style={{ marginRight: noMargin ? 0 : 4, marginBottom: noMargin ? 0 : 4 }}
		/>
	);
};
