import { enStrings } from './enStrings';
import { frStrings } from './frStrings';
import { IStrings } from './IStrings';
import { LanguageOption } from './LanguageOption';
import { nlStrings } from './nlStrings';

export const localizationRecord: Record<LanguageOption, IStrings> = {
	nl: nlStrings,
	en: enStrings,
	fr: frStrings,
};
