import React from 'react';
import { CacheProvider as FrameworkCacheProvider } from 'framework/context/CacheProvider';
import { companiesQuery_all, companiesQuery_concurrencyToken, ICompanySummary } from 'gen/ApiClient';
import { CompaniesCacheContext } from './CompaniesCacheContext';

export const CompaniesCacheProvider = ({ children }: any) => {
	return (
		<FrameworkCacheProvider<ICompanySummary[]>
			concurrencyF={companiesQuery_concurrencyToken}
			loadF={companiesQuery_all}
			context={CompaniesCacheContext}>
			{children}
		</FrameworkCacheProvider>
	);
};
