import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormPercentageField } from 'framework/forms/FormPercentageField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IOrientationModel, IOrientationTest, trialReportsCommand_addOrientation } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

const createSchema = (strings: IStrings) => {
	return yup.object<IOrientationModel>({
		bothEarsWithDevices: yup.number().defined(),
		bothEarsWithoutDevices: yup.number().defined(),
		left: yup.number().defined(),
		right: yup.number().defined(),
	});
};

interface IProps extends DialogProps {
	trialId: string;
	test: IOrientationTest;
	cancel: VoidFunction;
	confirm: VoidFunction;
}

export const OrientationTestForm = ({ trialId, test, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(trialReportsCommand_addOrientation);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IOrientationModel, helpers: FormikHelpers<IOrientationModel>) => {
		const r = await submit(trialId, values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.addedWhat(strings.test));
			confirm();
		}
	};

	return (
		<Formik<IOrientationModel>
			validateOnMount
			initialValues={
				test ?? {
					bothEarsWithoutDevices: 0,
					bothEarsWithDevices: 0,
					left: 0,
					right: 0,
				}
			}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.orientationTest}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormPercentageField<IOrientationModel>
							name='left'
							label={strings.left}
						/>
						<FormPercentageField<IOrientationModel>
							name='right'
							label={strings.right}
						/>
						<FormPercentageField<IOrientationModel>
							name='bothEarsWithDevices'
							label={strings.stereoWith}
						/>
						<FormPercentageField<IOrientationModel>
							name='bothEarsWithoutDevices'
							label={strings.stereoWithout}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={test ? strings.update : strings.create}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
