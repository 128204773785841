import React from 'react';
import { IPatientSearch } from 'gen/ApiClient';

interface IPatientSearchContext {
	searchString: string;
	setSearchString: React.Dispatch<React.SetStateAction<string>>;
	results: IPatientSearch[];
	setResults: React.Dispatch<React.SetStateAction<IPatientSearch[]>>;
	selected: IPatientSearch | undefined;
	setSelected: React.Dispatch<React.SetStateAction<IPatientSearch | undefined>>;
}

export const PatientSearchContext = React.createContext<IPatientSearchContext>(undefined as any);
