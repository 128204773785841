import { useEffect, useState } from 'react';
import { Trigger, useTrigger } from './useTrigger';

export function useContinuousCountdown(timeoutInSeconds: number): [number, Trigger] {
	const [counter, setCounter] = useState<number>(timeoutInSeconds);
	const [trigger, hitTrigger] = useTrigger();

	useEffect(() => {
		if (timeoutInSeconds > -1) {
			console.log('setting counter to 10');
			setCounter(timeoutInSeconds);
		}
	}, [timeoutInSeconds]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		let cancelled = false;

		if (cancelled === false && counter > 0) {
			timer = setTimeout(() => {
				setCounter(counter - 1);
			}, 1000);
		} else if (cancelled === false && counter === 0) {
			hitTrigger();
			setCounter(timeoutInSeconds);
		}

		return () => {
			clearTimeout(timer);
			cancelled = true;
		};
		// eslint-disable-next-line
	}, [counter]);

	return [counter, trigger];
}
