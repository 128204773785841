import { Color } from '@material-ui/lab';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { IRosaEventDto, RosaEventStatus } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

const toColor = (status: RosaEventStatus): Color => {
	if (status === 'Seen' || status === 'InConsultation' || status === 'InWaitingRoom') {
		return 'success';
	} else if (status === 'NoShow' || status === 'Deleted') {
		return 'error';
	} else {
		return 'info';
	}
};

interface IProps {
	event: IRosaEventDto;
	style?: React.CSSProperties;
}

export const RosaEventStatusChip = ({ event, style }: IProps) => {
	const { rosaEventStatusRecord } = useContext(RecordContext);

	return (
		<RectangularChip
			style={style}
			label={rosaEventStatusRecord[event.status as RosaEventStatus]}
			color={toColor(event.status as RosaEventStatus)}
		/>
	);
};
