import React from 'react';
import { CacheProvider } from 'framework/context/CacheProvider';
import { IPatientsFilterData, patientsQuery_concurrencyToken, patientsQuery_filterData } from 'gen/ApiClient';
import { PatientsFilterDataContext } from './PatientsFilterDataContext';

export const PatientsFilterDataProvider = ({ children }: any) => {
	return (
		<CacheProvider<IPatientsFilterData>
			concurrencyF={patientsQuery_concurrencyToken}
			loadF={patientsQuery_filterData}
			context={PatientsFilterDataContext}>
			{children}
		</CacheProvider>
	);
};
