import { ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { FilterOutlineIcon } from 'framework/components/icons/FilterOutlineIcon';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { IQueryResult } from 'framework/types/IQueryResult';
import {
	DailyReceiptExportProp,
	DailyReceiptExportProps,
	dailyReceiptsQuery_export,
	dailyReceiptsQuery_exportFormatted,
	IDailyReceipt,
	IDailyReceiptsFilterParams,
	IDailyReceiptsQueryParams,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { ViewContext } from './context/ViewContext';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { useDownloadWithLoader } from 'gen/useDownloadWithLoader';

interface IProps {
	params: IDailyReceiptsQueryParams;
	queryResult: IQueryResult<IDailyReceipt>;
}

export const DailyReceiptsExportButtonMenuAndDialog = ({ params, queryResult }: IProps) => {
	const strings = useLocalization();
	const { exportRecord } = useContext(ViewContext);
	const anchor = useAnchorElement();
	const exportFormatted = useDownloadWithLoader(dailyReceiptsQuery_exportFormatted);

	const onExportFormatted = async () => {
		anchor[2]();
		await exportFormatted(params);
	};

	return (
		<ExportButtonMenuAndDialog<IDailyReceiptsFilterParams, DailyReceiptExportProp>
			params={params}
			filteredCount={queryResult.filteredCount}
			unfilteredCount={queryResult.unfilteredCount}
			allProps={DailyReceiptExportProps}
			downloadF={dailyReceiptsQuery_export}
			fileName={strings.dailyReceipts}
			record={exportRecord}
			anchor={anchor}
			view='dailyReceipts'>
			<MenuItem onClick={onExportFormatted}>
				<ListItemIcon>
					<FilterOutlineIcon fontSize='small' />
					<FormatListNumberedIcon fontSize='small' />
				</ListItemIcon>
				<Typography variant='inherit'>{strings.exportDailyReceiptsFormattedAnWithTotals}</Typography>
			</MenuItem>
		</ExportButtonMenuAndDialog>
	);
};
