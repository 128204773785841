import { Dialog, DialogContent, DialogProps, DialogTitle, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, useTheme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { FilterOffIcon } from 'framework/components/icons/FilterOffIcon';
import { Searchbar } from 'framework/components/search/Searchbar';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { isLast } from 'framework/utils/array/isLast';
import {
	IMedicalTreatmentNomenclatureForAudiology,
	IRequestForInsuranceAllowance,
	patientsQuery_filterForMedicalTreatmentNomenclatures,
	rfiasCommand_clearPseudoMedicalTreatment,
	rfiasCommand_selectPseudoMedicalTreatment,
} from 'gen/ApiClient';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';
import { MedicalTreatmentNomenclaturesCacheContext } from 'shared/context/MedicalTreatmentNomenclaturesCacheContext';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { MedicalTreatmentNomenclatureDialog } from 'shared/medicalTreatmentNomenclatures/MedicalTreatmentNomenclatureDialog';

interface IFilterData {
	isAmbulant: boolean | undefined;
	isHearingLossMoreThan40dB: boolean | undefined;
	isSpeechAudiometryPossible: boolean | undefined;
	searchString: string;
}

const defaultFilterData: IFilterData = {
	isAmbulant: true,
	isHearingLossMoreThan40dB: undefined,
	isSpeechAudiometryPossible: undefined,
	searchString: '',
};

interface IProps extends DialogProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PseudoMedicalTreatmentWizardForm = ({ rfia, confirm, cancel, ...rest }: IProps) => {
	const { open, cancel: closeDetail, isStacked } = useDialogsContext();
	const theme = useTheme();
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [submit, isSubmitting] = useFormSubmit(rfiasCommand_selectPseudoMedicalTreatment);
	const [selectedCode, setSelectedCode] = useState<string>();
	const [data] = useCacheContext(MedicalTreatmentNomenclaturesCacheContext);
	const [params, setParams] = useState<IFilterData>(defaultFilterData);
	const [filtered, setFiltered] = useState<IMedicalTreatmentNomenclatureForAudiology[]>([]);
	const [preFilter] = useApiEffect(patientsQuery_filterForMedicalTreatmentNomenclatures, rfia.patientId);
	const [clearPseudoNomenclature] = useFormSubmit(rfiasCommand_clearPseudoMedicalTreatment);
	const isFiltered = useMemo(() => params.isHearingLossMoreThan40dB !== undefined || params.isSpeechAudiometryPossible !== undefined, [params]);
	const language = useCurrentLanguage();

	useEffect(() => {
		if (preFilter) {
			setParams({
				isAmbulant: preFilter.isAmbulant ?? true,
				isHearingLossMoreThan40dB: preFilter.isHearingLossMoreThan40dB,
				isSpeechAudiometryPossible: preFilter.isSpeechPossible,
				searchString: '',
			});
		}
	}, [preFilter]);

	useEffect(() => {
		if (data !== undefined) {
			const x = data
				.filter(t => t.isPseudoCode === true && t.data.isCurrentlyValid)
				.filter(t => params.isAmbulant === undefined || t.data.isAmbulant === params.isAmbulant)
				.filter(t => params.isHearingLossMoreThan40dB === undefined || t.isHearingLossMoreThan40dB === params.isHearingLossMoreThan40dB)
				.filter(t => params.isSpeechAudiometryPossible === undefined || t.isSpeechAudiometryPossible === params.isSpeechAudiometryPossible)
				.filter(t => `${t.data.code} ${t.data.descriptionNl} ${t.data.descriptionFr}`.toLowerCase().includes(params.searchString.toLowerCase()));
			setFiltered(x);
		}
	}, [params, data]);

	const handleSubmit = async (code: string, onSuccess: VoidFunction) => {
		setSelectedCode(code);
		const r = await submit(rfia.id, code);
		// TODO handle response ico validation error
		if (r.isSuccess) {
			onSuccess();
			notify(strings.selectedWhat(strings.pseudoNomenclatureCode));
			confirm();
		}
	};

	const handleClear = async (onSuccess: VoidFunction) => {
		const r = await clearPseudoNomenclature(rfia.id);
		if (r.isSuccess) {
			onSuccess();
			notify(strings.deletedWhat(strings.pseudoNomenclatureCode));
			confirm();
		}
	};

	if (data === undefined || preFilter === undefined) {
		return <div></div>;
	}

	const onClearFilters = () => {
		setParams({ ...params, isHearingLossMoreThan40dB: undefined, isSpeechAudiometryPossible: undefined });
	};

	const onViewDetail = (code: string) => {
		open(
			<MedicalTreatmentNomenclatureDialog
				code={code}
				open
				cancel={closeDetail}
				select={
					rfia.pseudoMedicalTreatmentNomenclatureCode !== code
						? async () => {
								await handleSubmit(code, closeDetail);
						  }
						: undefined
				}
				clear={
					rfia.pseudoMedicalTreatmentNomenclatureCode === code
						? async () => {
								await handleClear(closeDetail);
						  }
						: undefined
				}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			scroll='paper'
			fullWidth
			open={rest.open && isStacked === false}>
			<CloseButtonOnAbsolutePosition onClick={cancel} />
			<DialogTitle>{strings.selectWhat(strings.pseudoNomenclatureCode)}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<DividerWithCaption
					caption={strings.filter}
					action={
						<SmallPrimaryTextButton
							onClick={onClearFilters}
							disabled={isFiltered === false}
							startIcon={<FilterOffIcon />}
							color='secondary'>
							{strings.clearFilter}
						</SmallPrimaryTextButton>
					}
				/>
				<div className='df-col-as'>
					<BooleanRadioFilterComponent
						style={{ marginBottom: theme.spacing(0.5) }}
						badgeVariant='dot'
						trueText={strings.ambulatory}
						falseText={strings.hospitalized}
						selected={params.isAmbulant}
						setSelected={val => setParams({ ...params, isAmbulant: val })}
						label={params.isAmbulant ? strings.ambulatory : strings.hospitalized}
						icon={<HelpOutlineIcon />}
					/>
					<BooleanRadioFilterComponent
						style={{ marginBottom: theme.spacing(0.5) }}
						badgeVariant='dot'
						trueText={strings.hearingLossMoreThan40dB}
						falseText={strings.hearingLossLessThen40dB}
						selected={params.isHearingLossMoreThan40dB}
						setSelected={val => setParams({ ...params, isHearingLossMoreThan40dB: val })}
						label={
							params.isHearingLossMoreThan40dB === undefined
								? strings.hearingLossLessOrMoreThan40dB
								: params.isHearingLossMoreThan40dB === true
								? strings.hearingLossMoreThan40dB
								: strings.hearingLossLessThen40dB
						}
						icon={<HelpOutlineIcon />}
					/>
					<BooleanRadioFilterComponent
						style={{ marginBottom: theme.spacing(0.5) }}
						badgeVariant='dot'
						trueText={strings.speechAudiometryPossible}
						falseText={strings.speechAudiometryNotPossible}
						selected={params.isSpeechAudiometryPossible}
						setSelected={val => setParams({ ...params, isSpeechAudiometryPossible: val })}
						label={
							params.isSpeechAudiometryPossible === undefined
								? strings.speechAudiometryPossibleOrNotPossible
								: params.isSpeechAudiometryPossible === true
								? strings.speechAudiometryPossible
								: strings.speechAudiometryNotPossible
						}
						icon={<HelpOutlineIcon />}
					/>
				</div>
				<DividerWithCaption caption={strings.search} />
				<div className='df-row-ac jc-sb'>
					<Searchbar
						value={params.searchString}
						onSearch={text => setParams({ ...params, searchString: text })}
						placeholder={strings.searchVerb}
						style={{ width: '100%' }}
						variant='paper'
					/>
				</div>
				<DividerWithCaption caption={`${strings.filteredResults} (${filtered.length.toString()})`} />
				<List
					dense
					style={{ overflow: 'auto', marginLeft: '-24px', marginRight: '-24px' }}>
					{filtered.map(t => (
						<div
							className='df-col'
							key={t.data.code}>
							<ListItem
								button
								onClick={() => onViewDetail(t.data.code)}
								style={{ paddingLeft: '29px' }}
								disabled={isSubmitting}>
								<ListItemText
									primary={t.data.code}
									secondary={language === 'fr' ? t.data.descriptionFr : t.data.descriptionNl}
									style={{ paddingRight: '40px' }}
								/>
								<ListItemSecondaryAction>
									<LoaderButton
										variant='text'
										size='small'
										color='primary'
										isLoading={isSubmitting && selectedCode === t.data.code}
										disabled={isSubmitting}
										onClick={() => handleSubmit(t.data.code, () => {})}>
										{strings.select}
									</LoaderButton>
								</ListItemSecondaryAction>
							</ListItem>
							{isLast(t, filtered) === false && <Divider />}
						</div>
					))}
				</List>
			</DialogContent>
		</Dialog>
	);
};
