import { AppBar, createStyles, Drawer, makeStyles } from '@material-ui/core';
import { SpecificLocalizationContext } from 'localization/SpecificLocalizationContext';
import React, { useContext, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as routes from 'routes';
import { PreloadProvider } from 'shared/context/PreloadProvider';
import { UserPreferencesPreloadCacheProvider } from 'shared/context/UserPreferencesPreloadCacheProvider';
import { theme } from '../theme';
import { AppAuthenticatedWall } from './AppAuthenticatedWall';
import { AppBarContent } from './appBar/AppBarContent';
import { AppProviders } from './AppProviders';
import { LoggedInUserProvider } from './auth/LoggedInUserProvider';
import { ExternalApplicationsProviders } from './externalApps/ExternalApplicationsProviders';
import { CalendarModePageSwitch } from './main/calendar/CalendarModePageSwitch';
import { CashRegister } from './main/cashRegister/CashRegister';
import { ContactsSwitch } from './main/contacts/ContactsSwitch';
import { CustomFeatureCfPage } from './main/customFeatures/CustomFeatureCfPage';
import { Dashboard } from './main/dashboard/Dashboard';
import { DocumentsList } from './main/documents/DocumentsList';
import { FinancialsSwitch } from './main/financial/FinancialsSwitch';
import { IntegrationsSwitch } from './main/integrations/IntegrationsSwitch';
import { RosaPatientRedirectToHamsPatientPage } from './main/integrations/rosa/patients/RosaPatientRedirectToHamsPatientPage';
import { InventoryMagementSwitch } from './main/inventoryManagement/InventoryMagementSwitch';
import { NotificationsList } from './main/notifications/Notifications';
import { PatientSwitch } from './main/patients/PatientSwitch';
import { ProcessesSwitch } from './main/processes/ProcessesSwitch';
import { SettingsPage } from './main/settings/SettingsPage';
import { Test } from './main/test/Test';
import { SideNav } from './sideNav/SideNav';
import { TroubleshootPage } from './troubleshooting/TroubleshootPage';

const drawerWidth = 200;

const appBarStyle: React.CSSProperties = {
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
};

const appBarShiftStyle: React.CSSProperties = {
	...appBarStyle,
	width: `calc(100% - ${drawerWidth}px)`,
	marginLeft: drawerWidth,
};

const contentStyle: React.CSSProperties = {
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	height: 'calc(100% - 64px)',
};

const contentShiftStyle: React.CSSProperties = {
	...contentStyle,
	marginLeft: drawerWidth,
};

const useStyles = makeStyles(() =>
	createStyles({
		drawerPaper: {
			width: drawerWidth,
		},
	})
);

export const AppWrapper = () => {
	const classes = useStyles();
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
	const { language } = useContext(SpecificLocalizationContext);

	return (
		<AppAuthenticatedWall>
			<LoggedInUserProvider>
				<PreloadProvider>
					<UserPreferencesPreloadCacheProvider>
						<ExternalApplicationsProviders>
							<AppProviders language={language}>
								<div className='fg1 df-col stretch-ver'>
									<AppBar
										position='sticky'
										style={isDrawerOpen ? appBarShiftStyle : appBarStyle}>
										<AppBarContent
											toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
											isDrawerOpen={isDrawerOpen}
										/>
									</AppBar>
									<Drawer
										anchor='left'
										variant='persistent'
										open={isDrawerOpen}
										style={{ width: drawerWidth }}
										onClose={() => setIsDrawerOpen(false)}
										classes={{ paper: classes.drawerPaper }}>
										<SideNav onLinkClicked={() => {}} />
									</Drawer>
									<main
										style={isDrawerOpen ? contentShiftStyle : contentStyle}
										className='df-col'>
										<Switch>
											<Route
												exact
												path={routes.DashboardRoute}
												component={Dashboard}
											/>
											<Route
												path={routes.CalendarRoute}
												component={CalendarModePageSwitch}
											/>
											<Route
												path={routes.PatientsRoute}
												component={PatientSwitch}
											/>
											<Route
												path={routes.RosaPatientRedirectRoute}
												component={RosaPatientRedirectToHamsPatientPage}
												exact
											/>
											<Route
												path={routes.ProcessesRoute}
												component={ProcessesSwitch}
											/>
											<Route
												path={routes.InventoryManagementRoute}
												component={InventoryMagementSwitch}
											/>
											<Route
												path={routes.FinancialRoute}
												component={FinancialsSwitch}
											/>
											<Route
												exact
												path={routes.CashDeskRoute}
												component={CashRegister}
											/>
											<Route
												path={routes.ContactsRoute}
												component={ContactsSwitch}
											/>
											<Route
												exact
												path={routes.SettingsRoute}
												component={SettingsPage}
											/>
											<Route
												path={routes.IntegrationsRoute}
												component={IntegrationsSwitch}
											/>
											<Route
												exact
												path={routes.NotificationsRoute}
												component={NotificationsList}
											/>
											<Route
												exact
												path='/test'
												component={Test}
											/>
											<Route
												exact
												path={routes.CustomFeaturesCfRoute}
												component={CustomFeatureCfPage}
											/>
											<Route
												exact
												path={routes.TroubleshootRoute}
												component={TroubleshootPage}
											/>
											<Route
												exact
												path={routes.DocumentsRoute}
												component={DocumentsList}
											/>

											<Redirect to={routes.DashboardRoute} />
										</Switch>
									</main>
								</div>
							</AppProviders>
						</ExternalApplicationsProviders>
					</UserPreferencesPreloadCacheProvider>
				</PreloadProvider>
			</LoggedInUserProvider>
		</AppAuthenticatedWall>
	);
};
