import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import LinkIcon from '@material-ui/icons/Link';
import { HifDetailDialog } from 'app/main/contacts/hifs/HifDetailDialog';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { NumericIcon } from 'framework/components/icons/NumericIcon';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { IPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useLocalizedHealthInsuranceFundsCache } from 'shared/context/useLocalizedHealthInsuranceFundsCache';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { LinkHealthInsuranceFundForm } from '../../forms/LinkHealthInsuranceFundForm';

interface IProps {
	item: IPatient;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const PatientHifLinkCardContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const data = useLocalizedHealthInsuranceFundsCache();
	const hif = useMemo(() => data?.find(t => t.id === item.healthInsuranceFundCode), [data, item]);

	const onViewHif = () => {
		if (hif) {
			open(
				<HifDetailDialog
					open
					code={hif.id}
					close={confirm}
				/>
			);
		}
	};

	const onLinkHif = () => {
		open(
			<LinkHealthInsuranceFundForm
				open
				confirm={confirm}
				cancel={cancel}
				patient={item}
			/>
		);
	};

	return (
		<div
			className='df-col'
			style={style}>
			<CardTitleTypography
				title={strings.healthInsuranceFund}
				endIcon={
					<StatusIcon
						status={hif !== undefined ? 'success' : 'error'}
						fontSize='small'
						style={{ marginLeft: 4 }}
					/>
				}
			/>
			{hif !== undefined && (
				<>
					<LabelledPropertyX
						icon={<NumericIcon />}
						link={onViewHif}>
						{hif.id}
					</LabelledPropertyX>
					<LabelledPropertyX
						icon={<HomeOutlinedIcon />}
						link={onViewHif}>
						{hif.identifier}
					</LabelledPropertyX>
				</>
			)}
			{item.hasHealthInsuranceFundSet === true && hif === undefined && (
				<WarningLabelledProperty warning={`${item.healthInsuranceFundCode}\n${strings.thisHifDoesNotExistAnymore}`} />
			)}
			{item.hasHealthInsuranceFundSet === false && <em>{strings.noLinkWithHealthInsuranceFund}</em>}
			<TextLinkButton
				startIcon={<LinkIcon />}
				onClick={onLinkHif}
				color='primary'>
				{item.hasHealthInsuranceFundSet ? strings.linkWithAnotherHealthInsuranceFund : strings.linkWithAHealthInsuranceFund}
			</TextLinkButton>
		</div>
	);
};
