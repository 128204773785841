import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { ApiTokensListViewContext } from './ApiTokensListViewContext';
import { ApiTokensSelectableColumn } from './ApiTokensSelectableColumn';
import { createApiTokensColumnRecord } from './createApiTokensColumnRecord';
import { createApiTokensHeaders } from './createApiTokensHeaders';

const DefaultColumns: ApiTokensSelectableColumn[] = ['created', 'computerName', 'clientId', 'firstFourKeyChars'];

export const ApiTokensListViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const columnRecord = useMemo(() => createApiTokensColumnRecord(strings), [strings]);
	const headers = useMemo(() => createApiTokensHeaders(columnRecord), [columnRecord]);
	const [columns, setColumns] = useState<ApiTokensSelectableColumn[]>(DefaultColumns);

	return (
		<ApiTokensListViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: columnRecord,
				headers: headers,
				setColumns: setColumns,
			}}>
			{children}
		</ApiTokensListViewContext.Provider>
	);
};
