import { ReturnExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createReturnsExportRecord = (strings: IStrings): Record<ReturnExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		CountItems: `# ${strings.items}`,
		ReturnDate: strings.returnDate,
		ExternalReference: strings.shortExternalReference,
		FromLocationIdentifier: strings.location,
		Reference: strings.reference,
		ToSupplierIdentifier: strings.supplier,
	};
};
