import React, { useContext } from 'react';
import { IDocumentSummary, IRequestForInsuranceAllowance, rfiaDocumentsCommand_createLoanSlip } from 'gen/ApiClient';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DocumentListItemOrEmpty } from 'documents/DocumentListItemOrEmpty';
import { SelectTemplateFromPredefinedTypeForm } from 'documents/SelectTemplateFromPredefinedTypeForm';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	rfia: IRequestForInsuranceAllowance;
	lastLoanSlip: IDocumentSummary;
	reload: VoidFunction;
}

export const LoanSlipListItem = ({ rfia, lastLoanSlip, reload }: IProps) => {
	const { documentPredefinedTypeRecord } = useContext(RecordContext);
	const { open, cancel, confirm } = useDialogsContext(reload);

	const onNew = () => {
		if (rfia.lastLoanId === undefined) {
			return;
		}
		open(
			<SelectTemplateFromPredefinedTypeForm
				open
				type='RfiaLoanSlip'
				submitF={templateId => rfiaDocumentsCommand_createLoanSlip(rfia.id, rfia.lastLoanId as any, templateId)}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<DocumentListItemOrEmpty
			onNew={onNew}
			reload={reload}
			document={lastLoanSlip}
			title={documentPredefinedTypeRecord['RfiaLoanSlip']}
			disabled={rfia.canCreateLoanSlip === false}
		/>
	);
};
