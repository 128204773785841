import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		created: strings.createdAt,
		amountPaid: strings.amount,
		cashSaleForPatientIdentifier: `${strings.patient} (${strings.cashSale})`,
		customerOrSupplierAccountIdentifier: 'Klant- of leveranciersrekening',
		paymentDate: strings.paymentDate,
		paymentMethodIdentifier: strings.method,
		reference: strings.referenceShort,
		salesType: strings.salesType,
		comment: strings.remark,
	};
};
