import { IOption } from 'framework/IOption';
import { LanguageOption } from 'localization/LanguageOption';
import { LanguageOptionToLocaleRecord } from 'localization/LanguageOptionToLocaleRecord';
import { getMonthName } from 'shared/utils/getCurrentMonthName';

const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const getMonthOptions = (language: LanguageOption) => {
	return months.map<IOption<number>>(t => ({ id: t + 1, identifier: getMonthName(t, LanguageOptionToLocaleRecord[language]) }));
};
