import { QueryProvider } from 'framework/context/QueryProvider';
import {
	IQueryResultWithMetadata,
	IRepair,
	IRepairsQueryMetadata,
	IRepairsQueryParams,
	repairsQuery_concurrencyToken,
	repairsQuery_query,
} from 'gen/ApiClient';
import React from 'react';
import { repairsQueryParamsLsKey } from '../../../../localStorageKeys';
import { RepairsQueryContext } from './RepairsQueryContext';

const DefaultParams: IRepairsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	forPatientId: undefined,
	statuses: [],
	supplierIds: [],
	warnings: [],
	locationIds: [],
	handlingUserIds: [],
	prefilter: 'All',
};

export const RepairsQueryProvider = ({ children }: { children: any }) => {
	return (
		<QueryProvider<IQueryResultWithMetadata<IRepair, IRepairsQueryMetadata>, IRepairsQueryParams>
			defaultParams={DefaultParams}
			queryF={repairsQuery_query}
			concurrencyF={repairsQuery_concurrencyToken}
			localStorageKey={repairsQueryParamsLsKey}
			context={RepairsQueryContext}>
			{children}
		</QueryProvider>
	);
};
