import { IconButton, useTheme } from '@material-ui/core';
import { TableHeadersEyeIcon } from 'framework/components/icons/TableHeadersEyeIcon';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect } from 'react';
import { useDialogsContext } from '../dialogs/useDialogsContext';
import { DefaultColumnSelectionsContext } from './DefaultColumnSelectionsContext';
import { SelectColumnsDialog } from './SelectColumnsDialog';
import { ViewColumnSelectionType } from './ViewColumnSelectionType';

interface IProps<TSelectableColumn extends string> {
	selectedColumns: TSelectableColumn[];
	setSelectedColumns: React.Dispatch<React.SetStateAction<TSelectableColumn[]>>;
	record: Record<TSelectableColumn, string>;
	columns?: TSelectableColumn[];
	marginRight?: number;
	exclusions?: TSelectableColumn[];
	view: ViewColumnSelectionType;
}

export const SelectColumnsButtonAndDialog = <TSelectableColumn extends string>({
	view,
	selectedColumns,
	setSelectedColumns,
	record,
	columns,
	marginRight = 0,
	exclusions = [],
}: IProps<TSelectableColumn>) => {
	const strings = useLocalization();
	const { open, cancel } = useDialogsContext();
	const theme = useTheme();
	const { defaults, isMounted, setMounted } = useContext(DefaultColumnSelectionsContext);

	useEffect(() => {
		if (isMounted(view) === false) {
			const fd = defaults.find(t => t.view === view)?.columns?.filter(t => record[t as TSelectableColumn] !== undefined);
			if (fd !== undefined) {
				setSelectedColumns(fd as any);
			}
			setMounted(view);
		}
		// eslint-disable-next-line
	}, []);

	const onOpen = () => {
		open(
			<SelectColumnsDialog
				open
				cancel={cancel}
				submit={cols => {
					setSelectedColumns(cols);
					cancel();
				}}
				record={record}
				selected={selectedColumns}
				columns={columns}
				exclusions={exclusions}
				view={view}
			/>
		);
	};

	return (
		<TooltipWithSpan title={strings.changeColumns}>
			<IconButton
				color='primary'
				style={{ marginLeft: theme.spacing(1.5), marginRight: marginRight }}
				onClick={onOpen}>
				<TableHeadersEyeIcon fontSize='small' />
			</IconButton>
		</TooltipWithSpan>
	);
};
