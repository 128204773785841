import { useTheme } from '@material-ui/core';
import React from 'react';

const IconFontSize = 18;

interface IProps {
	icon?: React.ReactNode;
}

export const LabelledPropertyXIcon = ({ icon }: IProps) => {
	const theme = useTheme();

	return (
		<div
			className='icon-wrapper'
			style={{
				fontSize: IconFontSize,
				display: 'flex',
				color: theme.palette.text.secondary,
			}}>
			{icon}
		</div>
	);
};
