import { Divider } from '@material-ui/core';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { AccountCogOutlineIcon } from 'framework/components/icons/AccountCogOutlineIcon';
import { CubeOutlineIcon } from 'framework/components/icons/CubeOutlineIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IInventoryItem, ProductCatalogItemType, TangibleType, inventoryItemsCommand_markDemo, inventoryItemsCommand_unMarkDemo } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { boolToYesNo } from 'shared/utils/boolToString';
import { BebatCaptionVerticallyLabelledPropertyX } from '../../productCatalog/detail/BebatCaptionVerticallyLabelledPropertyX';
import { RecupelCaptionVerticallyLabelledPropertyX } from '../../productCatalog/detail/RecupelCaptionVerticallyLabelledPropertyX';
import { SwitchProductCatalogItemForm } from '../forms/SwitchProductCatalogItemForm';
import { LabelledChipsList } from 'framework/components/chips/LabelledChipsList';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { InventoryItemLabelsForm } from '../forms/InventoryItemLabelsForm';

interface IProps {
	item: IInventoryItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const InventoryItemDetailPageSidebar = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { productCatalogItemTypeRecord } = useContext(RecordContext);

	const onToggleDemo = () => {
		if (item.isDemo === false) {
			open(
				<AlertDialog
					open
					content={strings.markItemAsDemoQuestion}
					acceptText={strings.yes}
					rejectText={strings.noCommaGoBack}
					reject={cancel}
					acceptF={() => inventoryItemsCommand_markDemo({ inventoryItemId: item.id })}
					accept={confirm}
				/>
			);
		} else {
			open(
				<AlertDialog
					open
					content={strings.markItemAsNonDemoQuestion}
					acceptText={strings.yes}
					rejectText={strings.noCommaGoBack}
					reject={cancel}
					acceptF={() => inventoryItemsCommand_unMarkDemo({ inventoryItemId: item.id })}
					accept={confirm}
				/>
			);
		}
	};

	const onSwitch = () => {
		open(
			<SwitchProductCatalogItemForm
				open
				inventoryItemId={item.id}
				confirm={confirm}
				cancel={cancel}
				type={item.type as TangibleType}
			/>
		);
	};

	const onPatchLabels = () => {
		open(
			<InventoryItemLabelsForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<div
			className='df-col mh100'
			style={{
				padding: 16,
				paddingRight: 8,
				...style,
			}}>
			<div className='df-col gap-6'>
				<CaptionVerticalLabelledPropertyX
					label={strings.manufacturer}
					startIcon={<AccountCogOutlineIcon />}>
					{item.manufacturer}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.model}
					startIcon={<CubeOutlineIcon />}>
					{item.model}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.type}
					startIcon={<CategoryOutlinedIcon />}>
					{productCatalogItemTypeRecord[item.type as ProductCatalogItemType]}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.somethingQuestionmark(strings.isTailorMade)}>
					{boolToYesNo(item.isTailorMade, strings)}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.category}>
					<StringValueOrNotApplicable>{item.category}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.subcategory}>
					<StringValueOrNotApplicable>{item.subcategory}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				{item.isElectronics && <RecupelCaptionVerticallyLabelledPropertyX item={item.productCatalogData} />}
				{item.hasBatteries && <BebatCaptionVerticallyLabelledPropertyX item={item.productCatalogData} />}
				<TextLinkButton
					startIcon={<EmojiObjectsIcon />}
					color='warning'
					hoverColor='warning'
					style={{ marginLeft: -4 }}
					onClick={onSwitch}>
					{strings.changeProductCatalog}
				</TextLinkButton>
				<Divider />
				<CaptionVerticalLabelledPropertyX
					label={strings.somethingQuestionmark(strings.demo)}
					edit={onToggleDemo}>
					{boolToYesNo(item.isDemo, strings)}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.somethingQuestionmark(strings.statusSold)}>
					{boolToYesNo(item.isSold, strings)}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.somethingQuestionmark(strings.statusPurchased)}>
					{boolToYesNo(item.isPurchased, strings)}
				</CaptionVerticalLabelledPropertyX>
			</div>
			<DividerWithMargin marginBottom={12} />
			<div
				className='df-col fg1'
				style={{ overflowY: 'hidden' }}>
				<TextLinkButton
					style={{ fontSize: 12 }}
					color='textSecondary'
					onClick={onPatchLabels}>
					<div>{strings.labels}</div>
					<div className='fg1'></div>
					<SettingsOutlinedIcon style={{ fontSize: 16 }} />
				</TextLinkButton>
				<div
					className='fg1'
					style={{ overflowY: 'auto' }}>
					{item !== undefined && item?.labels.length > 0 && <LabelledChipsList labels={item.labels} />}
					{(item === undefined || item.labels.length === 0) && <NoRecordsFound variant='caption'>{strings.noLabelsAssignedYet}</NoRecordsFound>}
				</div>
			</div>
			<div className='fg1'></div>
			{/* <DividerWithMargin style={{ marginTop: 8, marginBottom: 8 }} />
			<div className='df-col gap-8'></div> */}
		</div>
	);
};
