import { formatDateX } from 'framework/utils/date/formatDateX';
import React, { useMemo } from 'react';
import { useFormatDateXLocalized } from 'shared/date/useFormatDateXLocalized';
import { getNow } from 'shared/utils/getNow';
import { IRosaEventsGroup } from './IRosaEventsGroup';
import { RosaEventListComponent } from './RosaEventListComponent';
import { RosaEventListComponentMode } from './RosaEventListComponentMode';

interface IProps {
	group: IRosaEventsGroup;
	mode: RosaEventListComponentMode;
}

export const RosaEventsGroupedByDayComponent = ({ group, mode }: IProps) => {
	const isToday = useMemo(() => formatDateX(group.date) === formatDateX(getNow()), [group]);
	const formatX = useFormatDateXLocalized();

	return (
		<div
			className='df-row w100'
			style={{ marginTop: 8, paddingBottom: 8 }}>
			<div
				className='df-row-ab'
				style={{ width: 150 }}>
				<div
					className='df-col-ac jc-c'
					style={{
						fontSize: '18px',
						letterSpacing: '-1.8px',
						textIndent: '-1.8px',
						width: '30px',
						height: '30px',
						textAlign: 'center',
						backgroundColor: isToday ? '#1a73e8' : 'inherit',
						borderRadius: '100%',
						marginRight: 8,
						color: isToday ? 'white' : 'inherit',
					}}>
					{formatDateX(group.date, 'd')}
				</div>
				<div
					style={{
						fontSize: '11px',
						letterSpacing: '0.8px',
						textAlign: 'left',
						textTransform: 'uppercase',
						color: isToday ? '#1a73e8' : '#70757a',
					}}>
					{formatX(group.date, 'LLL, E')}
				</div>
			</div>
			<div
				className='df-col fg1'
				style={{ paddingRight: 8 }}>
				{group.events.map(event => (
					<RosaEventListComponent
						event={event}
						key={event.id}
						mode={mode}
					/>
				))}
			</div>
		</div>
	);
};
