import { Typography, useTheme } from '@material-ui/core';
import { DragAndDropContainer } from 'framework/components/dnd/DragAndDropContainer';
import { LeftRightDividedContentScrollYRight } from 'framework/components/LeftRightDividedContentScrollYRight';
import { useApiCall } from 'framework/hooks/useApiCall';
import { addOrUpdateQuantity } from 'framework/utils/array/addOrUpdateQuantity';
import { updateArray } from 'framework/utils/array/updateArray';
import { IProductCatalogItem, productCatalogQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { SearchProductCatalog } from '../../../productCatalog/search/SearchProductCatalog';
import { SearchContext } from './context/SearchContext';
import { DeliveryLineComponent } from './DeliveryLineComponent';
import { IRegisterDeliveryLineModel } from './IRegisterDeliveryLineModel';
import { mapProductCatalogItemToRegisterDeliveryLineModel } from './mapProductCatalogItemToRegisterDeliveryLineModel';

interface IProps {
	lines: IRegisterDeliveryLineModel[];
	setLines: (lines: IRegisterDeliveryLineModel[]) => void;
}

export const SearchAndSelectProductsForDelivery = ({ lines, setLines }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const loadPc = useApiCall(productCatalogQuery_single);
	const { params, setParams, queryResult } = useContext(SearchContext);

	const onAddPc = async (item: IProductCatalogItem) => {
		const r = await loadPc(item.id);
		if (r.isSuccess) {
			item = r.result;
			if (item.isUniquelyIdentifiable) {
				setLines([...lines, mapProductCatalogItemToRegisterDeliveryLineModel(item)]);
			} else {
				setLines(addOrUpdateQuantity(item, lines, () => mapProductCatalogItemToRegisterDeliveryLineModel(item)));
			}
		}
	};

	return (
		<LeftRightDividedContentScrollYRight
			leftContent={
				<div className='df-col'>
					<Typography style={{ fontSize: '18px', marginBottom: theme.spacing(1) }}>{strings.searchFromProductCatalog}</Typography>
					<SearchProductCatalog
						params={params}
						setParams={setParams}
						queryResult={queryResult}
						onAdd={onAddPc}
						filterType='stock'
						style={{ width: '500px' }}
					/>
				</div>
			}
			rightContent={
				<DragAndDropContainer<IRegisterDeliveryLineModel>
					lines={lines}
					setLines={setLines}
					getKey={t => t.id}
					render={(line, index, props) => (
						<DeliveryLineComponent
							line={line}
							index={index + 1}
							dragHandleProps={props}
							setLine={val => setLines(updateArray(line, lines, val))}
							onDelete={() => setLines(lines.filter(t => t !== line))}
						/>
					)}
				/>
			}
		/>
	);
};
