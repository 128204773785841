import { Color } from '@material-ui/lab';
import React, { useContext } from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { ISubscriptionSummary, SubscriptionStatus } from 'gen/ApiClient';
import { ViewContext } from './context/ViewContext';

const colorRecord: Record<SubscriptionStatus, Color> = {
	Down: 'error',
	ProcessingBacklog: 'warning',
	Unknown: 'error',
	UpToDate: 'success',
};

interface IProps {
	item: ISubscriptionSummary;
	style?: React.CSSProperties;
}

export const SubscriptionStatusChip = ({ item, style }: IProps) => {
	const { statusRecord } = useContext(ViewContext);

	return (
		<RectangularChip
			style={style}
			label={statusRecord[item.status as SubscriptionStatus]}
			fill
			color={colorRecord[item.status as SubscriptionStatus]}
		/>
	);
};
