import React, { useState } from 'react';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IFile } from 'framework/utils/IFile';
import { INhaxImportResponse, patientsNhaxCommand_updateFromNhax, patientsNhaxCommand_updateResumeWithNhax } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { UploadFileDialog } from 'shared/dialogs/upload/UploadFileDialog';
import { PasswordForm } from './PasswordForm';

interface IProps {
	patientId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const UpdateFromNhaxFileForm = ({ patientId, cancel, confirm }: IProps) => {
	const strings = useLocalization();
	const [upload, isUploading] = useFormSubmit(patientsNhaxCommand_updateFromNhax);
	const [resume] = useFormSubmit(patientsNhaxCommand_updateResumeWithNhax);
	const [importResponse, setNhaxImportResponse] = useState<INhaxImportResponse>();
	const notify = useSnackbarNotify();
	const [file, setFile] = useState<IFile>();
	const [needsPassword, setNeedsPassword] = useState<boolean>(false);
	const [hasInvalidPassword, setHasInvalidPassword] = useState<boolean>(false);

	const onFileSelected = async (file: IFile) => {
		if (file.fileName.indexOf('.enhax') > -1) {
			setFile(file);
			setNeedsPassword(true);
		} else {
			return onUpload(file, '');
		}
	};

	const onUpload = async (file: IFile, password: string) => {
		const r = await upload(patientId, { ...file, password: password });
		if (r.isSuccess) {
			console.log(r.result);
			if (r.result.error) {
				notify(r.result.error, 'error');
				cancel();
			} else if (r.result.invalidPassword) {
				setHasInvalidPassword(true);
			} else if (r.result.warning) {
				setFile(file);
				setNhaxImportResponse(r.result);
			} else {
				confirm();
			}
		}
	};

	const onResume = async (resumeId: string) => {
		const r = await resume(patientId, resumeId);
		if (r.isSuccess) {
			confirm();
		}
		return r;
	};

	if (file === undefined) {
		return (
			<UploadFileDialog
				open
				title={strings.selectNhaxOrEnhaxFile}
				caption={strings.selectNhaxOrEnhaxFileCaption}
				confirm={onFileSelected}
				isUploading={isUploading}
				cancel={cancel}
				accept='nhaxOrEnhax'
			/>
		);
	} else if (needsPassword || hasInvalidPassword) {
		return (
			<PasswordForm
				open
				title={strings.providePassword}
				caption={strings.thisFileIsPasswordProtected}
				confirm={pw => onUpload(file, pw)}
				isSubmitting={isUploading}
				cancel={cancel}
				hasInvalidPassword={hasInvalidPassword}
			/>
		);
	} else if (importResponse !== undefined) {
		return (
			<AlertDialog
				open
				content={importResponse.warning!}
				acceptText={strings.proceed}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => onResume(importResponse.resumeWithFileId)}
				accept={() => {}}
			/>
		);
	} else {
		return <div></div>;
	}
};
