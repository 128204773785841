import React from 'react';
import { CacheProvider } from 'framework/context/CacheProvider';
import { ISalesFilterData, salesQuery_concurrencyToken, salesQuery_filterData } from 'gen/ApiClient';
import { SalesFilterDataContext } from './SalesFilterDataContext';

export const SalesFilterDataProvider = ({ children }: any) => {
	return (
		<CacheProvider<ISalesFilterData>
			concurrencyF={salesQuery_concurrencyToken}
			loadF={salesQuery_filterData}
			context={SalesFilterDataContext}>
			{children}
		</CacheProvider>
	);
};
