import { DialogProps } from '@material-ui/core';
import { ICustomerAccountLineQueryMetadata } from 'gen/ApiClient';
import React, { useState } from 'react';
import { SettlementProposalDialog } from '../sps/detail/SettlementProposalDialog';
import { SelectCustomerAccountDialog } from './SelectCustomerAccountDialog';
import { SettlementProposalModelForm } from './SettlementProposalModelForm';

interface IProps extends DialogProps {
	metadata: ICustomerAccountLineQueryMetadata;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const SettlementProposalForm = ({ metadata, confirm, cancel, ...rest }: IProps) => {
	const [customerAccountId, setCustomerAccountId] = useState<string | undefined>();
	const [spsId, setSpsId] = useState<string>();

	if (spsId !== undefined) {
		return (
			<SettlementProposalDialog
				open
				id={spsId}
				close={confirm}
				withCreate
			/>
		);
	} else if (customerAccountId === undefined) {
		return (
			<SelectCustomerAccountDialog
				{...rest}
				customerAccounts={metadata.customerAccounts}
				confirm={setCustomerAccountId}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<SettlementProposalModelForm
				{...rest}
				customerAccountId={customerAccountId}
				confirm={setSpsId}
				cancel={cancel}
			/>
		);
	}
};
