import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { apiUrl, ClientBuildNumberHeaderKey, LanguageHeaderKey, languageKey } from 'keys';
import { FrameworkDefaultAxiosInstance } from 'framework/xhr/FrameworkDefaultAxiosInstance';
import { build } from 'build';

export const DefaultAxiosInstance = (config?: AxiosRequestConfig): AxiosInstance => {
	const instance = FrameworkDefaultAxiosInstance(apiUrl, config);
	// only in debug
	if (process.env.NODE_ENV === 'development') {
		instance.interceptors.request.use(config => {
			config.withCredentials = true;
			return config;
		});
	}
	const json = window.localStorage.getItem(languageKey);
	if (json) {
		instance.defaults.headers.common[LanguageHeaderKey] = JSON.parse(json);
	}

	//attach buildnumber to header
	instance.defaults.headers.common[ClientBuildNumberHeaderKey] = build;
	return instance;
};
