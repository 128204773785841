import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as routes from './IntegrationsRoutes';
import { ExactGlobePage } from './exactGlobe/ExactGlobePage';
import { ExactOnlineIntegrationPage } from './exactOnline/ExactOnlineIntegrationPage';
import { InvoiceForwardingIntegrationPage } from './invoiceForwarding/InvoiceForwardingIntegrationPage';
import { RosaIntegrationPage } from './rosa/RosaIntegrationPage';

export const IntegrationsSwitch = () => {
	return (
		<Switch>
			<Route
				exact
				path={routes.RosaRoute}
				component={RosaIntegrationPage}
			/>
			<Route
				exact
				path={routes.ExactOnlineRoute}
				component={ExactOnlineIntegrationPage}
			/>
			<Route
				exact
				path={routes.ExactGlobeRoute}
				component={ExactGlobePage}
			/>
			<Route
				exact
				path={routes.InvoiceForwardingRoute}
				component={InvoiceForwardingIntegrationPage}
			/>
			<Redirect to={routes.RosaRoute} />
		</Switch>
	);
};
