import { ILocationSummary, StockItemDtoExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

interface IMap {
	key: string;
	text: string;
}

export const createExportRecord = (strings: IStrings, locations: ILocationSummary[]): Record<string, string> => {
	const xRecord = createExportRecordFromExportProp(strings);
	const xMap = Object.keys(xRecord).map<IMap>(t => ({ key: t, text: xRecord[t as StockItemDtoExportProp] }));
	const yMap = locations.map<IMap>(location => ({ key: location.id, text: `# ${strings.inStock}\n@ ${location.name}` }));
	const xyMap = [...xMap, ...yMap];
	return xyMap.reduce((acc, item) => ({ ...acc, [item.key]: item.text }), {} as Record<string, string>);
};

export const createExportRecordFromExportProp = (strings: IStrings): Record<StockItemDtoExportProp, string> => {
	return {
		ProductCatalogItemId: strings.uniqueReference,
		Category: strings.category,
		ManufacturerCommaModel: `${strings.manufacturer}, ${strings.model}`,
		Manufacturer: strings.manufacturer,
		Model: strings.model,
		Subcategory: strings.subcategory,
		HasSerialNumber: strings.serialNumber,
		InternalReference: strings.internalReference,
		ManufacturerReferencesCsv: strings.manufacturerReference,
		CountInBackOrder: `# ${strings.inBackOrder}`,
		CountInRepair: `# ${strings.inRepair}`,
		CountPurchased: `# ${strings.statusPurchased}`,
		CountSold: `# ${strings.statusSold}`,
		SupplierIdentifierCsv: strings.suppliers,
		Type: strings.type,
		StockValue: strings.stockValue,
		CountInTestOrLoan: `# ${strings.inTest}`,
		VatPercentage: strings.vatPercentage,
		FinancialQuantity: strings.financialQuantity,
		PhysicalQuantity: strings.physicalQuantity,
		DeltaFinancialAndPhysical: strings.deltaFinancialAndPhysical,
		HasDeltaFinancialAndPhysical: strings.hasDeltaFinancialAndPhysical,
		PurchaseListPrice: strings.purchaseListPrice,
	};
};
