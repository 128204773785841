import React from 'react';
import { CacheProvider } from 'framework/context/CacheProvider';
import { IRfiaFilterData, rfiasQuery_concurrencyToken, rfiasQuery_filterData } from 'gen/ApiClient';
import { FilterDataContext } from './FilterDataContext';

export const FilterDataProvider = ({ children }: any) => {
	return (
		<CacheProvider<IRfiaFilterData>
			concurrencyF={rfiasQuery_concurrencyToken}
			loadF={rfiasQuery_filterData}
			context={FilterDataContext}>
			{children}
		</CacheProvider>
	);
};
