import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { ChipWithAvatarList } from 'framework/components/ChipWithAvatarList';
import { IPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PatientOriginsForm } from '../../forms/PatientOriginsForm';

interface IProps {
	item: IPatient;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const PatientOriginsCardContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onEdit = () => {
		open(
			<PatientOriginsForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<div
			className='df-col'
			style={style}>
			<CardTitleTypography
				title={strings.origin}
				style={{ marginBottom: 8 }}
			/>
			{item.origins.length === 0 && <em>{strings.notFilledIn}</em>}
			{item.origins.length > 0 && (
				<div>
					<ChipWithAvatarList
						items={item.origins}
						variant='outlined'
					/>
				</div>
			)}
			<TextLinkButton
				startIcon={<PostAddOutlinedIcon />}
				onClick={onEdit}>
				{strings.edit}
			</TextLinkButton>
		</div>
	);
};
