import React from 'react';
import { annotationTypesCommand_updateColor, IAnnotationType } from 'gen/ApiClient';
import { ColorPickerWrapper as SharedColorPickerWrapper } from 'shared/color/ColorPickerWrapper';

interface IProps {
	annotationType: IAnnotationType;
	reload: VoidFunction;
}

export const AnnotationsColorPickerWrapper = ({ annotationType, reload }: IProps) => {
	return (
		<SharedColorPickerWrapper
			initialColor={annotationType.color}
			submitF={color => annotationTypesCommand_updateColor(annotationType.id, color)}
			reload={reload}
		/>
	);
};
