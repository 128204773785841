import React from 'react';
import { Trigger } from 'framework/hooks/useTrigger';

interface IContext {
	isOpen: boolean;
	open: (templateId: string, historyId?: string) => void;
	trigger: Trigger;
}

export const TemplateEditorDialogContext = React.createContext<IContext>(undefined as any);
