import { Dialog, DialogActions, DialogContent, DialogProps, useTheme } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormActions } from 'framework/forms/PageableFormActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	customerAccountLinesQuery_allForCustomerAccountNoSpsWithOutstandingBalance,
	customerAccountsQuery_single,
	IPaymentForSalesModel,
	paymentsForSalesCommand_newForCustomerAccount,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { FormSelectOrCreatePaymentMethodField } from '../../../../settings/paymentMethods/forms/FormSelectOrCreatePaymentMethodField';
import { CustomerAccountLinesTable } from './CustomerAccountLinesTable';
import { ICustomerAccountLineWithSelection } from './ICustomerAccountLineWithSelection';
import { TotalsTable } from './TotalsTable';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPaymentForSalesModel>({
			date: yup.date().required(),
			isAutoSelectReference: yup.boolean().defined(),
			methodId: yup.string().required(),
			reference: yup.string(),
			customerAccountLineIds: yup.mixed(),
		})
		.defined();
};

const EmptyValues: IPaymentForSalesModel = {
	date: undefined as any,
	isAutoSelectReference: true,
	methodId: '',
	reference: '',
	customerAccountLineIds: [],
};

const stepsRecord: Record<number, (keyof IPaymentForSalesModel)[]> = {
	0: ['isAutoSelectReference', 'reference', 'date'],
	1: ['methodId'],
	2: ['customerAccountLineIds'],
};

interface IProps extends DialogProps {
	customerAccountId: string;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const PaymentForSalesForm = ({ confirm, cancel, customerAccountId, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(paymentsForSalesCommand_newForCustomerAccount);
	const [step, setStep] = useState<number>(0);
	const [customerAccount] = useApiEffect(customerAccountsQuery_single, customerAccountId);
	const [allLines] = useApiEffect(customerAccountLinesQuery_allForCustomerAccountNoSpsWithOutstandingBalance, customerAccountId);
	const [lines, setLines] = useState<ICustomerAccountLineWithSelection[]>([]);
	const now = useNow();

	useEffect(() => {
		if (allLines !== undefined) {
			setLines(allLines.map<ICustomerAccountLineWithSelection>(t => ({ isSelected: false, ...t })));
		}
	}, [allLines]);

	const handleSubmit = async (values: IPaymentForSalesModel, helpers: FormikHelpers<IPaymentForSalesModel>) => {
		const r = await create(customerAccountId, { ...values, customerAccountLineIds: lines.filter(t => t.isSelected).map(t => t.id) });
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.registeredWhat(strings.groupPayment));
			confirm(r.result.objectId);
		}
	};

	if (lines === undefined || allLines === undefined || customerAccount === undefined || now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IPaymentForSalesModel>
			validateOnMount
			initialValues={{
				...EmptyValues,
				date: now,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					customerAccountIdentifier={customerAccount.identifier}
					lines={lines}
					setLines={setLines}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	customerAccountIdentifier: string;
	lines: ICustomerAccountLineWithSelection[];
	setLines: React.Dispatch<React.SetStateAction<ICustomerAccountLineWithSelection[]>>;
}

const InnerDialog = ({ step, setStep, cancel, isSubmitting, customerAccountIdentifier, lines, setLines, ...rest }: IInnerDialogProps) => {
	const theme = useTheme();
	const props = useFormikContext<IPaymentForSalesModel>();
	const strings = useLocalization();

	useEffect(() => {
		if (props.values.isAutoSelectReference) {
			setFormValue<IPaymentForSalesModel>('reference', '', props);
		}
		// eslint-disable-next-line
	}, [props.values.isAutoSelectReference]);

	return (
		<Dialog
			scroll='paper'
			{...rest}>
			<DialogTitleWithFormStepper
				title={`${strings.registerWhat(strings.groupPayment)}: ${customerAccountIdentifier}`}
				labels={[`${strings.date} & ${strings.reference}`, strings.method, strings.lines]}
				step={step}
			/>
			<DialogContent
				className='df-col'
				dividers
				style={{ padding: step === 2 ? '0px' : '8px 24px' }}>
				{step === 0 && (
					<>
						<FormDatePicker<IPaymentForSalesModel>
							name='date'
							label={strings.referenceDate}
							disableFuture
							required
						/>
						<FormSingleCheckboxField<IPaymentForSalesModel>
							name='isAutoSelectReference'
							label={strings.autoSelectPaymentReferenceQuestion}
						/>
						<FormTextField<IPaymentForSalesModel>
							name='reference'
							label={strings.paymentReference}
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectOrCreatePaymentMethodField<IPaymentForSalesModel>
							name='methodId'
							label={strings.paymentMethod}
							mode='sale'
						/>
					</>
				)}
				{step === 2 && (
					<CustomerAccountLinesTable
						lines={lines}
						setLines={setLines}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<div className='df-col'>
					{step === 2 && (
						<TotalsTable
							lines={lines}
							style={{ marginBottom: theme.spacing(1) }}
						/>
					)}
					<PageableFormActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
					/>
				</div>
			</DialogActions>
		</Dialog>
	);
};
