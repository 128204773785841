import { DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IContactPerson, IContactPersonModel, patientsCommand_updateContactPerson } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { AddressInput } from 'shared/address/AddressInput';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IContactPersonModel>({
			name: yup.string().required(),
			addressLine: yup.string(),
			zip: yup.string(),
			city: yup.string(),
			country: yup.string(),
			phoneNumber: yup.string(),
			departmentOrLocation: yup.string(),
			email: yup.string(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IContactPersonModel)[]> = {
	0: ['name', 'departmentOrLocation'],
	1: ['addressLine', 'city', 'country', 'zip'],
	2: ['phoneNumber', 'email'],
};

interface IProps extends DialogProps {
	patientId: string;
	model: IContactPerson;
	cancel: VoidFunction;
	confirm: VoidFunction;
	successMessage?: string;
}

export const PatientContactPersonForm = ({ patientId, model, confirm, cancel, successMessage, ...rest }: IProps) => {
	const strings = useLocalization();
	const [update, isSubmitting] = useFormSubmit(patientsCommand_updateContactPerson);
	const [step, setStep] = useState<number>(0);

	const handleSubmit = async (values: IContactPersonModel, helpers: FormikHelpers<IContactPersonModel>) => {
		const r = await update(patientId, values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	return (
		<Formik<IContactPersonModel>
			validateOnMount
			initialValues={model}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<OverflowDialog {...rest}>
					<DialogTitleWithFormStepper
						step={step}
						title={strings.contactPerson}
						labels={[strings.name, strings.address, strings.contactData]}
					/>
					<OverflowDialogContent>
						{step === 0 && (
							<>
								<FormTextField<IContactPersonModel>
									name='name'
									label={strings.name}
									required
								/>
								<FormTextField<IContactPersonModel>
									name='departmentOrLocation'
									label={`${strings.department}/${strings.location}`}
								/>
							</>
						)}
						{step === 1 && <AddressInput<IContactPersonModel> />}
						{step === 2 && (
							<>
								<FormTextField<IContactPersonModel>
									name='phoneNumber'
									label={strings.phone}
								/>
								<FormTextField<IContactPersonModel>
									name='email'
									label={strings.email}
								/>
							</>
						)}
					</OverflowDialogContent>
					<PageableFormDialogActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
					/>
				</OverflowDialog>
			</Form>
		</Formik>
	);
};
