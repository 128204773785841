import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		identifier: strings.name,
		category: strings.category,
		subcategory: strings.subcategory,
		salesListPrice: `${strings.recommended}\n${strings.salesPrice}`,
		vatPercentage: strings.vat,
		internalReference: strings.shortInternalReference,
		manufacturerReferencesCsv: strings.shortManufacturerReference,
		type: strings.type,
	};
};
