import { Dialog, DialogProps } from '@material-ui/core';
import React from 'react';
import { SlideUpTransition } from '../components/transitions/SlideUpTransition';

export const FullScreenDialog = (props: DialogProps) => {
	return (
		<Dialog
			{...props}
			fullScreen
			TransitionComponent={SlideUpTransition}
			scroll='paper'
		/>
	);
};
