import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IExactOnlineCustomerAccountDto, exactOnlineCustomerAccountsCommand_push } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { LinkExistingExactOnlineCrmAccountDialog } from './LinkExistingExactOnlineCrmAccountDialog';

interface IProps {
	item: IExactOnlineCustomerAccountDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LinkOrPushExactOnlineCustomerAccountDialog = ({ item, confirm, cancel }: IProps) => {
	const strings = useLocalization();
	const [isNew, setIsNew] = useState<boolean>(false);

	return (
		<>
			{isNew === true && (
				<AlertDialog
					open
					title={`CRM account aanmaken in EO`}
					content={`Wil je een nieuwe CRM account (klant) aanmaken in Exact Online?`}
					acceptText={strings.yesCommaContinue}
					rejectText={strings.noCommaGoBack}
					reject={cancel}
					acceptF={() => exactOnlineCustomerAccountsCommand_push({ hamsId: item.hamsId })}
					accept={confirm}
				/>
			)}
			{isNew === false && (
				<LinkExistingExactOnlineCrmAccountDialog
					open
					item={item}
					confirm={confirm}
					cancel={cancel}
					onNew={() => setIsNew(true)}
				/>
			)}
		</>
	);
};
