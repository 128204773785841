import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { AccordionXWithType } from 'framework/components/accordion/AccordionXWithType';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RestrictedContent } from 'shared/layout/RestrictedContent';
import { ApplicationSettingsContent } from './ApplicationSettingsContent';
import { BusinessDataContent } from './businessData/BusinessDataContent';
import { LocationsContent } from './locations/LocationsContent';
import { PaymentMethodsContent } from './paymentMethods/PaymentMethodsContent';
import { useLoggedInUserIsMinimalInRole } from 'app/auth/useLoggedInUserIsMinimalInRole';
import { NoAccessPage } from 'shared/layout/NoAccessPage';
import { HamsAdminSettingsContent } from './HamsAdminSettingsContent';

type AccordionType = 'company' | 'locations' | 'payment-methods' | 'app-settings' | 'hams-app-settings';

interface IProps extends IReloadTriggerProps {}

export const GeneralSettingsTab = ({ reloadTrigger }: IProps) => {
	const strings = useLocalization();
	const isSuperUser = useLoggedInUserIsSuperUser();
	const isAdminUser = useLoggedInUserIsMinimalInRole('Admin');
	const [expandedAccordion, setExpandedAccordion] = useAccordionState<AccordionType>();

	return (
		<>
			<AccordionXWithType<AccordionType>
				type='company'
				expandedAccordion={expandedAccordion}
				toggle={setExpandedAccordion}
				title={strings.companyData}>
				<RestrictedContent pageClaim='BusinessData'>
					<BusinessDataContent reloadTrigger={reloadTrigger} />
				</RestrictedContent>
			</AccordionXWithType>
			<AccordionXWithType<AccordionType>
				type='locations'
				expandedAccordion={expandedAccordion}
				toggle={setExpandedAccordion}
				title={strings.locations}>
				<RestrictedContent pageClaim='Locations'>
					<LocationsContent reloadTrigger={reloadTrigger} />
				</RestrictedContent>
			</AccordionXWithType>
			<AccordionXWithType<AccordionType>
				type='payment-methods'
				expandedAccordion={expandedAccordion}
				toggle={setExpandedAccordion}
				title={strings.paymentMethods}>
				<RestrictedContent pageClaim='PaymentMethods'>
					<PaymentMethodsContent reloadTrigger={reloadTrigger} />
				</RestrictedContent>
			</AccordionXWithType>
			<AccordionXWithType<AccordionType>
				type='hams-app-settings'
				expandedAccordion={expandedAccordion}
				toggle={setExpandedAccordion}
				title={`HAMS ${strings.settings} (only for admins)`}>
				{isAdminUser ? <HamsAdminSettingsContent reloadTrigger={reloadTrigger} /> : <NoAccessPage />}
			</AccordionXWithType>
			{isSuperUser && (
				<AccordionXWithType<AccordionType>
					type='app-settings'
					expandedAccordion={expandedAccordion}
					toggle={setExpandedAccordion}
					title={`App-settings (only for super-users)`}>
					<ApplicationSettingsContent reloadTrigger={reloadTrigger} />
				</AccordionXWithType>
			)}
		</>
	);
};
