import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { createType } from 'framework/utils/createType';
import { INoahDeviceReadOut } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsHeader } from 'shared/table/createDetailsHeader';
import { Filter } from './Filter';
import { NoahDeviceReadOutStatusChip } from './NoahDeviceReadOutStatusChip';
import { SelectableColumn } from './SelectableColumn';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';
import { NoahDeviceReadOutDialog } from './detail/NoahDeviceReadOutDialog';

const QuickFilters = createType('all', 'notLinkedNotIgnored', 'linkedToMultiple');
export type QuickFilter = typeof QuickFilters[number];

interface IProps extends IReloadTriggerProps {}

export const NoahDeviceReadOutsList = ({ reloadTrigger }: IProps) => {
	const { reload, queryResult, params, setParams } = useQueryContext(QueryContext);
	const { open, confirm: close } = useDialogsContext(reload);

	const strings = useLocalization();
	const theme = useTheme();
	// eslint-disable-next-line
	const { columns, setColumns, columnsRecord, headers, quickFilter, setQuickFilter, quickFilterRecord } = useContext(ViewContext);
	const prependColumn = createEmptyHeader<INoahDeviceReadOut>('prepend', t => <NoahDeviceReadOutStatusChip item={t} />);
	const appendColumn = createDetailsHeader<INoahDeviceReadOut>(t => onViewDetail(t.id));

	useEffect(() => {
		reload();
	}, [reloadTrigger, reload]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onViewDetail = (id: string) => {
		open(
			<NoahDeviceReadOutDialog
				open
				id={id}
				close={close}
			/>
		);
	};

	return (
		<>
			<div className='df-row-ac jc-sb'>
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder={strings.searchVerb}
					style={{ width: '400px' }}
					variant='paper'
				/>
			</div>
			{queryResult.unfilteredCount > 0 && (
				<>
					<Card>
						<div
							className='df-row-ac jc-sb'
							style={{ padding: theme.spacing(1) }}>
							<ButtonGroupWithSelection<QuickFilter>
								options={QuickFilters}
								selected={quickFilter}
								setSelected={setQuickFilter}
								renderOption={t => quickFilterRecord[t]}
							/>
							<div>
								<SelectColumnsButtonAndDialog
									selectedColumns={columns}
									setSelectedColumns={setColumns}
									record={columnsRecord}
									view='noahDevicesRules'
								/>
							</div>
						</div>
						<Divider />
						<div className='df-row jc-sb'>
							<Filter
								params={params}
								onFilter={params => setParams(onResetToPage1(params))}
								paddingLeft={theme.spacing(1.5)}
							/>
						</div>
						<Divider />
						<CardMedia>
							<PageableTableWithColumnSelection<INoahDeviceReadOut, SelectableColumn>
								queryResult={queryResult}
								headers={headers}
								sortParams={params.sortParams}
								pageParams={params.pageParams}
								setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
								getKey={inst => inst.id}
								selectedColumns={columns}
								prependColumn={prependColumn}
								appendColumn={appendColumn}
							/>
						</CardMedia>
					</Card>
				</>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound>{strings.noDevicesReadOutByNoahYet}</NoRecordsFound>}
		</>
	);
};
