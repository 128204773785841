import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IFile } from 'framework/utils/IFile';
import { purchasesUblCommand_addToBuffer } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { UploadFilesDialog } from 'shared/dialogs/upload/UploadFilesDialog';

interface IProps {
	confirm: () => void;
	cancel: VoidFunction;
}

export const AddPurchaseUblToBufferDialog = ({ confirm, cancel }: IProps) => {
	const strings = useLocalization();
	const [upload, isUploading] = useFormSubmit(purchasesUblCommand_addToBuffer);
	const notify = useSnackbarNotify();

	const onFileSelected = async (files: IFile[]) => {
		const r = await upload({ files: files });
		if (r.isSuccess) {
			if (r.result.error) {
				notify(r.result.error!, 'error');
			} else {
				confirm();
			}
		}
	};

	return (
		<UploadFilesDialog
			open
			title={strings.selectWhat('UBL')}
			confirm={() => {}}
			confirmF={onFileSelected}
			isUploading={isUploading}
			cancel={cancel}
			accept='xml'
		/>
	);
};
