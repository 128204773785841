import { FormControl } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { FormErrorHelper } from 'framework/forms/common/FormErrorHelper';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { nameOf } from 'framework/utils/nameOf';
import { IPatientSearch } from 'gen/ApiClient';
import { SelectPatient } from './SelectPatient';

interface IProps<TModel> extends IFormProps<TModel> {
	onSelected?: (val: IPatientSearch | undefined) => void;
	canCreateNew?: boolean;
}

export const FormSelectPatientField = <TModel extends unknown>({ onSelected, canCreateNew = false, ...props }: IProps<TModel>) => {
	const [field, meta, helpers] = useField<string | undefined>(nameOf(props.name));

	return (
		<FormControl
			variant='filled'
			fullWidth
			error={meta.error && meta.touched ? true : false}>
			<SelectPatient
				error={meta.error && meta.touched ? true : false}
				style={{ paddingBottom: 0, ...props.style }}
				required={props.required}
				disabled={props.disabled}
				selectedId={field.value}
				label={props.label}
				canCreateNew={canCreateNew}
				onSetSelected={val => {
					helpers.setTouched(true);
					helpers.setValue(val?.id ?? undefined);
					onSelected && onSelected(val);
				}}
			/>
			<FormErrorHelper meta={meta} />
		</FormControl>
	);
};
