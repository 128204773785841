import { useResizeObserver } from 'framework/hooks/useResizeObserver';
import { IRfiaDetail } from 'gen/ApiClient';
import React from 'react';
import { DocumentsCard } from './documents/DocumentsCard';
import { InvoiceProcessCard } from './InvoiceProcessCard';
import { ProcessCard } from './ProcessCard';

interface IProps {
	detail: IRfiaDetail;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const ProcessPage = ({ detail, reload, style }: IProps) => {
	const [ref, width] = useResizeObserver();

	return (
		<div
			ref={ref}
			style={style}>
			{width < 950 && (
				<div className='df-col'>
					<ProcessCard
						item={detail}
						reload={reload}
						width={450}
					/>
					<InvoiceProcessCard
						item={detail.rfia}
						isHifExist={detail.isHifExist}
						reload={reload}
						width={450}
					/>
					<DocumentsCard
						item={detail}
						reload={reload}
						width={450}
					/>
				</div>
			)}
			{width >= 950 && width < 1382 && (
				<div className='df-row'>
					<div className='df-col'>
						<ProcessCard
							item={detail}
							reload={reload}
							width={450}
						/>
						<InvoiceProcessCard
							item={detail.rfia}
							isHifExist={detail.isHifExist}
							reload={reload}
							width={450}
						/>
					</div>
					<div className='df-col'>
						<DocumentsCard
							item={detail}
							reload={reload}
							width={450}
						/>
					</div>
				</div>
			)}
			{width >= 1382 && (
				<div className='df-row'>
					<div className='df-col'>
						<ProcessCard
							item={detail}
							reload={reload}
							width={450}
						/>
					</div>
					<div className='df-col'>
						<InvoiceProcessCard
							item={detail.rfia}
							isHifExist={detail.isHifExist}
							reload={reload}
							width={400}
						/>
					</div>
					<div className='df-col'>
						<DocumentsCard
							item={detail}
							reload={reload}
							width={450}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
