import { Button, Card, CardMedia, IconButton, Popover, useScrollTrigger, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { SelectButtonWithMenu } from 'framework/components/buttons/SelectButtonWithMenu';
import { CalendarPlusIcon } from 'framework/components/icons/CalendarPlusIcon';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { IRosaEventDto, RosaCalendarViewType, rosaIntegrationQuery_countUnlinkedUsers } from 'gen/ApiClient';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RestrictedPage } from 'shared/layout/RestrictedPage';
import { RecordContext } from 'shared/records/RecordContext';
import { getNow } from 'shared/utils/getNow';
import { RosaCalendarDateCalendarsAndMotivesCard } from './RosaCalendarDateCalendarsAndMotivesCard';
import { RosaLinkUsersDialogWithAlertDialog } from './RosaLinkUsersDialogWithAlertDialog';
import { calculateNextDate } from './calculateNextDate';
import { calculatePreviousDate } from './calculatePreviousDate';
import { RosaEventsListComponent } from './list/RosaEventsListComponent';
import { RosaMobiscrollEventCalendar } from './mobiscroll/RosaMobiscrollEventCalendar';
import { RosaEventPopoverContent } from './popover/RosaEventPopoverContent';
import { renderDateDisplay } from './renderDateDisplay';
import { useRosaCalendarContext } from './useRosaCalendarContext';
import { useRosaRedirect } from './useRosaRedirect';
import { RosaViewPreferencesForm } from './viewPreferences/RosaViewPreferencesForm';

export const RosaCalendarPage = () => {
	const theme = useTheme();
	const strings = useLocalization();
	const language = useCurrentLanguage();
	const [scrollTarget, setScrollTarget] = useState<HTMLDivElement | undefined>(undefined);
	const trigger = useScrollTrigger({ target: scrollTarget, disableHysteresis: true, threshold: 0 });
	const [overflowY, setOverflowY] = useState<'hidden' | 'overlay'>('hidden');
	const { view, setView, date, setDate, reload, events, calendars, viewPreferences } = useRosaCalendarContext();
	const [anchor, setAnchor] = React.useState<any>(undefined);
	const [selectedEvent, setSelectedEvent] = useState<IRosaEventDto>();
	const { rosaCalendarViewTypeRecord } = useContext(RecordContext);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const rRedirect = useRosaRedirect();
	const [hasChecked, setHasChecked] = useState<boolean>(false);
	const [countUnlinkedUsers] = useApiEffect(rosaIntegrationQuery_countUnlinkedUsers);

	useEffect(() => {
		if (countUnlinkedUsers !== undefined) {
			setHasChecked(true);
		}
		if (countUnlinkedUsers !== undefined && countUnlinkedUsers > 0 && hasChecked === false) {
			open(
				<RosaLinkUsersDialogWithAlertDialog
					confirm={confirm}
					cancel={cancel}
					countUnlinkedUsers={countUnlinkedUsers}
				/>
			);
		}
		// eslint-disable-next-line
	}, [countUnlinkedUsers, hasChecked]);

	const onNew = () => {
		rRedirect({ date: date });
	};

	const onSettings = () => {
		open(
			<RosaViewPreferencesForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<RestrictedPage
			pageClaim='Calendar'
			breadcrumbs={[{ text: strings.calendar, icon: <TodayOutlinedIcon /> }]}
			breadCrumbsContainerStyle={{ width: '277px' }}
			title={strings.schedule}
			reload={reload}
			settings={onSettings}
			detailsFixedWidth={286}
			fullHeight
			styleDetailsContent={{ height: '100%', paddingLeft: 0, paddingRight: 0 }}
			styleRightContent={{ width: `calc(100% - 286px)` }}
			reloadEveryXSeconds={10}
			header={
				<div className='df-row-ac fg1'>
					<Button
						variant='outlined'
						onClick={() => setDate(getNow())}
						style={{ marginRight: 8 }}>
						{strings.today}
					</Button>
					<IconButton
						size='small'
						onClick={() => setDate(calculatePreviousDate(date, view))}>
						<ChevronLeftIcon />
					</IconButton>
					<IconButton
						size='small'
						onClick={() => setDate(calculateNextDate(date, view))}>
						<ChevronRightIcon />
					</IconButton>
					<div style={{ fontSize: '22px' }}>{renderDateDisplay(date, view, strings, language)}</div>
					<div className='fg1'></div>
					<SelectButtonWithMenu<RosaCalendarViewType>
						options={Object.keys(rosaCalendarViewTypeRecord) as RosaCalendarViewType[]}
						record={rosaCalendarViewTypeRecord}
						state={view}
						setState={setView}
					/>
				</div>
			}
			details={
				<div
					onMouseEnter={() => setOverflowY('overlay')}
					onMouseLeave={() => setOverflowY('hidden')}
					ref={node => setScrollTarget(node ?? undefined)}
					style={{ height: '100%', overflowY: overflowY as any, paddingLeft: 16, paddingRight: 8 }}>
					<div
						style={{
							position: 'sticky',
							top: 0,
							left: 0,
							backgroundColor: theme.palette.background.default,
							zIndex: 3,
							borderBottom: trigger ? `1px solid ${theme.palette.divider}` : 'none',
							marginBottom: 1,
						}}>
						<Button
							variant='contained'
							className='w100'
							color='primary'
							style={{ marginBottom: 16 }}
							startIcon={<CalendarPlusIcon />}
							onClick={onNew}>
							{strings.new}
						</Button>
					</div>
					<RosaCalendarDateCalendarsAndMotivesCard />
				</div>
			}>
			<Card style={{ height: view !== 'Timeline' && view !== 'List' ? '100%' : 'unset' }}>
				{view !== 'List' && (
					<>
						{Boolean(anchor) && selectedEvent && (
							<Popover
								open={Boolean(anchor)}
								anchorEl={anchor}
								onClose={() => setAnchor(undefined)}>
								<RosaEventPopoverContent
									event={selectedEvent}
									close={() => setAnchor(undefined)}
								/>
							</Popover>
						)}
						<RosaMobiscrollEventCalendar
							view={view}
							viewPreferences={viewPreferences}
							calendars={calendars}
							events={events}
							date={date}
							onClickEvent={(event, target) => {
								setSelectedEvent(event);
								setAnchor(target);
							}}
						/>
					</>
				)}
				{view === 'List' && (
					<CardMedia style={{ paddingLeft: 12, paddingRight: 12 }}>
						<RosaEventsListComponent
							events={events}
							mode='calendarList'
						/>
					</CardMedia>
				)}
			</Card>
		</RestrictedPage>
	);
};
