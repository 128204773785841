import { IPurchaseLine } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const purchaseLineToLine = (t: IPurchaseLine): ILine => {
	return {
		isSelected: true,
		quantity: t.quantity - t.creditedQuantity,
		purchaseLine: t,
		error: undefined,
	};
};
