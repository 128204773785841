import { IStrings } from 'localization/IStrings';
import { HandoverLineMode } from '../forms/handover/HandoverLineMode';

export const createHandoverLineModeRecord = (strings: IStrings): Record<HandoverLineMode, string> => {
	return {
		exclude: strings.doNotMentionOnAttachment12,
		refundable: strings.refundable,
		nonRefundable: strings.notRefundable,
	};
};
