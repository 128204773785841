import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IProductCatalogItemSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: IProductCatalogItemSummary;
}

export const BebatCaptionVerticallyLabelledPropertyX = ({ item }: IProps) => {
	const strings = useLocalization();

	return (
		<CaptionVerticalLabelledPropertyX label={strings.bebatRate}>
			<StringValueOrNotApplicable notApplicableText={strings.noRateDefined}>
				{item.hasBebat ? formatCurrency(item.bebatUnitPriceInVat, 3) : undefined}
			</StringValueOrNotApplicable>
		</CaptionVerticalLabelledPropertyX>
	);
};
