import { v1 as uuid } from 'uuid';
import { IProductCatalogItem, ISalesLineForAssuranceDto } from 'gen/ApiClient';
import { IInvoiceLine } from '../IInvoiceLine';

const toDescription = (t: IProductCatalogItem, line: IInvoiceLine | ISalesLineForAssuranceDto): string => {
	if (line.serialNumber) {
		return `${t.name} sn: ${line.serialNumber}`;
	} else {
		return t.name ?? '';
	}
};

export const assuranceToLine = (t: IProductCatalogItem, line: IInvoiceLine | ISalesLineForAssuranceDto): IInvoiceLine => {
	return {
		isSelected: true,
		id: uuid(),
		description: toDescription(t, line),
		inventoryItemId: undefined,
		productCatalogItemId: t.id,
		quantity: 1,
		unitListPrice: t.salesListPrice,
		unitPrice: t.salesListPrice,
		vatPercentage: t.vatPercentage,
		productCatalogItem: t,
		assuredLineId: line.id,
		assuredProductCatalogItemId: line.productCatalogItemId,
		assuredInventoryItemId: line.inventoryItemId,
		serialNumber: undefined,
		canChangeQuantity: line.inventoryItemId === undefined,
		stockAtLocations: undefined,
		inventoryItem: undefined,
		isCredit: false,
	};
};
