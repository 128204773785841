import { ColorX } from 'framework/components/color/ColorX';
import { IWorkflowSummary } from 'gen/ApiClient';

export const mapWorkflowSummaryToColorX = (wfs: IWorkflowSummary): ColorX => {
	if (wfs.isCancelled) {
		return 'error';
	} else if (wfs.hasError) {
		return 'error';
	} else if (wfs.hasWarning) {
		return 'warning';
	} else if (wfs.isCompleted) {
		return 'success';
	} else {
		return 'primary';
	}
};
