import { DocumentationType } from './DocumentationType';

export const DocumentationTypeUrlRecord: Record<DocumentationType, string> = {
	'bebat-tarifs': 'https://hamspublicfiles.blob.core.windows.net/root/bebat_tarieven.pdf',
	'clt-riziv-info': 'https://hamspublicfiles.blob.core.windows.net/root/clt_documentation.pdf',
	'recupel-tarifs': 'https://hamspublicfiles.blob.core.windows.net/root/recupel_tarieven.pdf',
	'riziv-numbering-info': 'https://hamspublicfiles.blob.core.windows.net/root/rizivnumber_documentation.pdf',
	'sin-riziv-info': 'https://hamspublicfiles.blob.core.windows.net/root/sin_documentation.pdf',
	'speech-riziv-info': 'https://hamspublicfiles.blob.core.windows.net/root/speech_documentation.pdf',
};
