import { Card, CardContent } from '@material-ui/core';
import { useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { NoAccessToThisFunctionLabelledProperty } from 'framework/components/labelledProperty/NoAccessToThisFunctionLabelledProperty';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { MoneyModelForm } from 'framework/forms/MoneyModelForm';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IProductCatalogItem, productCatalogCommand_patchPurchaseListPrice, productCatalogCommand_patchSalesListPrice } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { VatPercentageForm } from '../forms/VatPercentageForm';

interface IProps {
	item: IProductCatalogItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const PricesCard = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const hasVeppClaim = useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim();

	const onSalesListPrice = () => {
		open(
			<MoneyModelForm
				open
				confirm={confirm}
				cancel={cancel}
				submitFunction={val => productCatalogCommand_patchSalesListPrice({ productCatalogItem: item.id, listPrice: val })}
				initialValue={item.salesListPrice}
				formTitle={strings.changeWhat(`${strings.salesPrice} (${strings.inVatShort})`)}
				label={strings.salesPrice}
				allowNegative
			/>
		);
	};

	const onPurchaseListPrice = () => {
		if (hasVeppClaim === false) {
			open(
				<NotificationDialog
					open
					title={strings.restrictions}
					content={strings.noAccessToThisFunction}
					close={cancel}
				/>
			);
		} else {
			open(
				<MoneyModelForm
					open
					confirm={confirm}
					cancel={cancel}
					submitFunction={val => productCatalogCommand_patchPurchaseListPrice({ productCatalogItemId: item.id, listPrice: val })}
					initialValue={item.purchaseListPrice}
					formTitle={strings.changeWhat(`${strings.purchasePrice} (${strings.exVatShort})`)}
					label={strings.purchasePrice}
				/>
			);
		}
	};

	const onVatPercentage = () => {
		open(
			<VatPercentageForm
				open
				productCatalogItem={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Card
			style={style}
			className='df-col'>
			<CardContent>
				<CardTitleTypography title={strings.pricing} />
				<LabelledProperty
					label={strings.vat}
					property={`${item.vatPercentage}%`}
					edit={onVatPercentage}
				/>
				<LabelledProperty
					label={strings.salesPrice}
					property={formatCurrency(item.salesListPrice)}
					edit={onSalesListPrice}
				/>
				<LabelledProperty
					label={`${strings.salesPrice}\n(${strings.exVatShort})`}
					property={formatCurrency(item.salesListPriceExVat)}
					edit={onSalesListPrice}
				/>
				{(item.isTangible || item.canAlsoPurchase === true) && (
					<>
						<NoAccessToThisFunctionLabelledProperty
							hasAccess={hasVeppClaim}
							label={strings.purchasePrice}
							property={formatCurrency(item.purchaseListPrice)}
							edit={onPurchaseListPrice}
						/>
						<TooltipWithSpan title={strings.marginTooltip}>
							<NoAccessToThisFunctionLabelledProperty
								hasAccess={hasVeppClaim}
								label={strings.margin}
								property={`${item.listMargin.toFixed(2)} (${(item.listMargin * 100).toFixed(2)} %)`}
							/>
						</TooltipWithSpan>
						<TooltipWithSpan title={strings.markupTooltip}>
							<NoAccessToThisFunctionLabelledProperty
								hasAccess={hasVeppClaim}
								label={strings.markup}
								property={`${item.listMarkup.toFixed(2)} (${(item.listMarkup * 100).toFixed(2)} %)`}
							/>
						</TooltipWithSpan>
						<NoAccessToThisFunctionLabelledProperty
							hasAccess={hasVeppClaim}
							label={`${strings.profit} (${strings.exVatShort})`}
							property={formatCurrency(item.listProfit)}
						/>
					</>
				)}
			</CardContent>
		</Card>
	);
};
