import { Dialog, DialogProps, Typography } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IStockInfo } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { LocationsPreloadCacheContext } from '../../settings/locations/LocationsPreloadCacheContext';

interface IProps extends DialogProps {
	display: string;
	close: VoidFunction;
	item: IStockInfo;
}

export const PerLocationDialog = ({ display, item, close, ...rest }: IProps) => {
	const strings = useLocalization();
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);

	return (
		<Dialog
			{...rest}
			onClose={close}
			fullWidth>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<HelpOutlineIcon />}>
					<Typography variant='h1'>{display}</Typography>
					<Typography
						variant='body2'
						color='textSecondary'>
						{`# ${strings.total} ${strings.inStock}`}: {item.countInStock}
					</Typography>
				</DialogGridTitle>
				<PropertyInGrid
					label={`${strings.name} ${strings.location}`}
					value={strings.quantityInStock}
					labelStyle={{ fontWeight: 'bold' }}
					valueStyle={{ fontWeight: 'bold' }}
				/>
				{item.stockAtLocations.map(t => (
					<PropertyInGrid
						key={t.locationId}
						label={locations.find(x => x.id === t.locationId)?.identifier ?? strings.unknown}
						value={`# ${t.count}`}
					/>
				))}
				{locations
					.filter(t => item.stockAtLocations.find(x => x.locationId === t.id) === undefined)
					.map(t => (
						<PropertyInGrid
							key={t.id}
							label={t.identifier}
							value={`# 0`}
						/>
					))}
			</DialogContentGrid>
		</Dialog>
	);
};
