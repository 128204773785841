import { Button, ButtonProps } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';

export const SelectButton = ({ variant = 'outlined', color = 'default', ...rest }: ButtonProps) => {
	return (
		<Button
			variant={variant}
			color={color}
			endIcon={<ArrowDropDownIcon />}
			{...rest}
		/>
	);
};
