import { Button, Collapse, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useLoggedInUserIsSuperUserOrHasClientClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasClientClaim';
import { PurchasesSettingsRoute, calcPurchaseDetailRoute } from 'app/main/financial/routes';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { TableCardX } from 'framework/components/cards/TableCardX';
import { UploadIcon } from 'framework/components/icons/UploadIcon';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useTrigger } from 'framework/hooks/useTrigger';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IPurchasesFilterParams, PurchaseExportProp, PurchaseExportProps, purchasesQuery_export, purchasesUblQuery_count } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TextLinkExportButtonMenuAndDialog } from 'shared/export/TextLinkExportButtonMenuAndDialog';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { RecordContext } from 'shared/records/RecordContext';
import { FinancialsPageBreadcrumbTitle } from '../../FinancialsPageBreadcrumbTitle';
import { RegisterPurchaseRequestForm } from '../forms/register/RegisterPurchaseRequestForm';
import { UploadPurchaseUblDialog } from '../forms/ubl/UploadPurchaseUblDialog';
import { PurchasesListFilterCard } from './PurchasesListFilterCard';
import { PurchasesListOrderBySelect } from './PurchasesListOrderBySelect';
import { PurchasesListStats } from './PurchasesListStats';
import { PurchasesQueryContext } from './PurchasesQueryContext';
import { PurchasesTable } from './PurchasesTable';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { PageStatsButtonX } from 'framework/components/layout/PageStatsButtonX';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';

export const PurchasesListPage = () => {
	const [trigger, hitTrigger] = useTrigger();
	const { reload, queryResult, params, setParams } = useQueryContext(PurchasesQueryContext);
	const [countBuffer, reloadCountBuffer] = useApiEffect(purchasesUblQuery_count);
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(hitTrigger);
	const { push } = useHistory();
	const { purchaseExportRecord } = useContext(RecordContext);
	const canImportUbl = useLoggedInUserIsSuperUserOrHasClientClaim('CanImportUblPurchase');
	const [showStats, toggleShowStats] = useStateToggleBoolean(true);

	useLazyEffect(() => {
		reload();
		reloadCountBuffer();
	}, [trigger]);

	if (queryResult === undefined || countBuffer === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<RegisterPurchaseRequestForm
				open
				confirm={id => {
					if (id) {
						push(calcPurchaseDetailRoute(id));
					}
					confirm();
				}}
				cancel={cancel}
			/>
		);
	};

	const onImportUbl = () => {
		open(
			<UploadPurchaseUblDialog
				cancel={cancel}
				confirm={id => {
					if (id) {
						push(calcPurchaseDetailRoute(id));
					}
					confirm();
				}}
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='Purchases'
			maxWidth={1280}
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<FinancialsPageBreadcrumbTitle />
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={strings.purchases} />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageStatsButtonX
								showStats={showStats}
								toggleShowStats={toggleShowStats}
							/>
							<PageSettingsButtonX route={PurchasesSettingsRoute} />
							<PageReloadButtonX reload={hitTrigger} />
						</PageXHeaderActions>
					</div>
					<Collapse in={showStats}>
						<PurchasesListStats
							metadata={queryResult.metadata}
							countBuffer={countBuffer}
							style={{ paddingTop: 16 }}
						/>
					</Collapse>
				</div>
			}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<div className='df-col gap-8'>
						<Button
							variant='contained'
							color='primary'
							startIcon={<AddIcon />}
							onClick={onNew}
							style={{ borderRadius: 12 }}>
							{strings.registerNewPurchase}
						</Button>
						{canImportUbl && (
							<Button
								variant='outlined'
								color='primary'
								startIcon={<UploadIcon />}
								onClick={onImportUbl}
								style={{ borderRadius: 12 }}>
								{`Upload UBL`}
							</Button>
						)}
					</div>
					<PurchasesListFilterCard
						params={params}
						setParams={setParams}
						metadata={queryResult.metadata}
					/>
					<div className='fg1'></div>
					<Divider />
					<TextLinkExportButtonMenuAndDialog<IPurchasesFilterParams, PurchaseExportProp>
						params={params}
						filteredCount={queryResult.filteredCount}
						unfilteredCount={queryResult.unfilteredCount}
						downloadF={purchasesQuery_export}
						fileName={strings.purchases}
						record={purchaseExportRecord}
						allProps={PurchaseExportProps}
						view='purchases'
						style={{ paddingBottom: 0 }}
					/>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				<SearchbarX
					placeholder={strings.searchVerb}
					value={params.searchString}
					onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
				/>
				<TableCardX
					header={
						<div className='df-col-ae'>
							<PurchasesListOrderBySelect
								sortParams={params.sortParams}
								onFilter={sp => setParams(onResetToPage1({ ...params, sortParams: sp }))}
							/>
						</div>
					}
					table={
						<PurchasesTable
							queryResult={queryResult}
							onFilter={sp => setParams(onResetToPage1({ ...params, sortParams: sp }))}
							sortParams={params.sortParams}
						/>
					}
					footer={
						<QueryResultPaginationX
							queryResult={queryResult}
							setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
							flexEnd
						/>
					}
				/>
			</div>
		</RestrictedPageX>
	);
};
