import { DialogProps } from '@material-ui/core';
import React, { useContext } from 'react';
import { ThermalPrinterApplicationContext } from 'app/externalApps/thermalPrinter/ThermalPrinterApplicationContext';
import { DownloadAndInstallSoftwareDialog } from './DownloadAndInstallSoftwareDialog';

interface IProps extends DialogProps {
	close: VoidFunction;
}

export const DownloadAndInstallThermalPrinterSoftwareDialog = ({ ...rest }: IProps) => {
	const { download, isDownloading } = useContext(ThermalPrinterApplicationContext);

	return (
		<DownloadAndInstallSoftwareDialog
			{...rest}
			download={download}
			isDownloading={isDownloading}
			title={`Download en installeer thermische printer software`}
		/>
	);
};
