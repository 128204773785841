import { Card, CardContent, CardProps } from '@material-ui/core';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import { DocumentCreateOrUploadForm } from 'documents/DocumentCreateOrUploadForm';
import { DocumentListItemXDefault } from 'documents/DocumentListItemXDefault';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { isLast } from 'framework/utils/array/isLast';
import { documentsQuery_byIdAndContextNotDeleted, IDocumentsByContextAndIdParams, IReturn, LinkViewContextType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';

const ReturnLinkViewContextType: LinkViewContextType = 'Return';

interface IProps extends CardProps, IReloadTriggerProps {
	item: IReturn;
	reload: VoidFunction;
}
export const ReturnDetailDocumentsCard = ({ item, reload, reloadTrigger, ...rest }: IProps) => {
	const strings = useLocalization();
	const params = useMemo<IDocumentsByContextAndIdParams>(() => ({ linkViewContextId: item.id, linkViewContextType: ReturnLinkViewContextType }), [item]);
	const [documents, reloadDocuments] = useApiEffect(documentsQuery_byIdAndContextNotDeleted, params);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadDocuments();
	}, [reloadTrigger]);

	const onNew = () => {
		open(
			<DocumentCreateOrUploadForm
				title={strings.returnSlip}
				context='Return'
				contextId={item.id}
				templatePredefinedType='ReturnSlip'
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	if (documents === undefined) {
		return <></>;
	}

	return (
		<Card {...rest}>
			<CardContent>
				<CardTitleTypography
					title={strings.documents}
					style={{ marginBottom: 8 }}
				/>
				<TextLinkButton
					startIcon={<PostAddOutlinedIcon />}
					onClick={onNew}
					color='primary'>
					{strings.returnSlip}
				</TextLinkButton>
				<DividerWithMargin />
				{documents.map(t => (
					<DocumentListItemXDefault
						key={t.id}
						item={t}
						reload={reloadDocuments}
						withoutBorder={isLast(t, documents)}
					/>
				))}
				{documents.length === 0 && <em>{strings.noDocumentsAddedYet}</em>}
			</CardContent>
		</Card>
	);
};
