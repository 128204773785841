import { IconButton, useTheme } from '@material-ui/core';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import CloseIcon from '@material-ui/icons/Close';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { RoundedSquareX } from 'framework/components/RoundedSquareX';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { StethoscopeIcon } from 'framework/components/icons/StethoscopeIcon';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';
import { IRosaEventDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { calculateRosaPatientRedirectRoute } from 'routes';
import { ColorSquareWithoutStyling } from 'shared/color/ColorSquareWithoutStyling';
import { RosaEventStatusChip } from '../RosaEventStatusChip';
import { formatRosaEventDates } from '../formatRosaEventDates';
import { useRosaRedirect } from '../useRosaRedirect';
import { RosaEventPopoverPropertyComponent } from './RosaEventPopoverPropertyComponent';

interface IProps {
	event: IRosaEventDto;
	close: VoidFunction;
}

export const RosaEventPopoverContent = ({ event, close }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { push } = useHistory();
	const openInNewTab = useOpenInNewTab();
	const rRedirect = useRosaRedirect();
	const patientDetailRoute = useMemo(
		() => (event.hasHamsPatient ? calcPatientDetailRoute(event.hamsPatientId ?? '') : calculateRosaPatientRedirectRoute(event.patientRecordId ?? '')),
		[event]
	);

	const onViewInRosa = () => {
		rRedirect({ date: event.startsAt });
	};

	return (
		<div
			className='df-col'
			style={{ minWidth: 400 }}>
			<div
				className='df-row-ac jc-e'
				style={{ paddingTop: 8, paddingLeft: 6, paddingRight: 6 }}>
				<TooltipWithSpan title={strings.viewInRosa}>
					<ColorOnHoverIconButton
						color='primary'
						onClick={onViewInRosa}>
						<VisibilityOutlinedIcon fontSize='small' />
					</ColorOnHoverIconButton>
				</TooltipWithSpan>
				<IconButton onClick={close}>
					<CloseIcon fontSize='small' />
				</IconButton>
			</div>
			<div style={{ padding: 16, paddingTop: 4, maxWidth: 500 }}>
				<div
					className='df-col'
					style={{ marginBottom: 12 }}>
					<div className='df-row-ac'>
						<RoundedSquareX
							size={40}
							color={event.motiveHexColor!}
						/>
						<div style={{ fontSize: '22px', lineHeight: '28px', fontWeight: 400, color: 'rgb(60, 64, 67)', maxWidth: 350 }}>{event.display}</div>
					</div>
					<div
						className='df-row-ac'
						style={{ marginTop: 3 }}>
						<div style={{ width: '40px' }}></div>
						<div
							style={{
								textDecoration: event.isNoShow ? 'line-through' : 'inherit',
								color: event.isNoShow ? theme.palette.error.main : 'inherit',
							}}>
							{formatRosaEventDates(event)}
						</div>
					</div>
					{event.hasMotive && (
						<div
							className='df-row-ac'
							style={{ marginTop: 3 }}>
							<div style={{ width: '40px' }}></div>
							<div>{event.motiveLabel}</div>
						</div>
					)}
					{event.isActive === false && (
						<div
							className='df-row-ac'
							style={{ marginTop: 4 }}>
							<div style={{ width: '40px' }}></div>
							<RosaEventStatusChip event={event} />
						</div>
					)}
				</div>
				<RosaEventPopoverPropertyComponent
					icon={<PersonOutlineOutlinedIcon fontSize='small' />}
					show={event.hasPatient}
					label={event.patientRecordName}>
					<TooltipWithSpan title={strings.viewWhatInThisTab(strings.patient)}>
						<ColorOnHoverIconButton
							color='primary'
							size='small'
							onClick={() => push(patientDetailRoute)}>
							<OpenInBrowserIcon fontSize='small' />
						</ColorOnHoverIconButton>
					</TooltipWithSpan>
					<TooltipWithSpan title={strings.viewWhatInOtherTab(strings.patient)}>
						<ColorOnHoverIconButton
							color='primary'
							size='small'
							onClick={() => openInNewTab(patientDetailRoute, false)}>
							<OpenInNewOutlinedIcon fontSize='small' />
						</ColorOnHoverIconButton>
					</TooltipWithSpan>
				</RosaEventPopoverPropertyComponent>
				<RosaEventPopoverPropertyComponent
					icon={<TodayOutlinedIcon fontSize='small' />}
					label={
						<div className='df-row-ac'>
							{event.calendarHexColor && (
								<ColorSquareWithoutStyling
									color={event.calendarHexColor}
									style={{ marginRight: 8 }}
								/>
							)}
							<div>{event.calendarLabel}</div>
						</div>
					}
					show={event.hasCalendar}
				/>
				<RosaEventPopoverPropertyComponent
					icon={<LocationOnOutlinedIcon fontSize='small' />}
					show={event.hasSite}
					label={event.siteName}
				/>
				<RosaEventPopoverPropertyComponent
					icon={<StethoscopeIcon fontSize='small' />}
					label={event.hpNameFromCalendarLabel}
					show={event.hasHp}
				/>
				<RosaEventPopoverPropertyComponent
					icon={<PhoneOutlinedIcon fontSize='small' />}
					label={event.patientPrimaryPhoneNumber}
					show={event.hasPhone}
				/>
				<RosaEventPopoverPropertyComponent
					icon={<MailOutlineIcon fontSize='small' />}
					label={event.patientPrimaryEmail}
					show={event.hasEmail}
				/>
				<RosaEventPopoverPropertyComponent
					icon={<ChatOutlinedIcon fontSize='small' />}
					label={event.patientNote}
					show={event.hasPatientNote}
					wrap
				/>
				<RosaEventPopoverPropertyComponent
					icon={<SubjectOutlinedIcon fontSize='small' />}
					label={event.hpNote}
					show={event.hasHpNote}
					wrap
					style={{ maxWidth: '400px', overflowWrap: 'break-word' }}
				/>
				{/* {createdByUser && (
					<div className='df-row-ac'>
						<div style={{ color: DefaultGrayColor, width: '40px', display: 'flex' }}>
							<GroupAddOutlinedIcon />
						</div>
						<div
							className='df-col'
							style={{ paddingTop: 6, paddingBottom: 6 }}>
							<Typography
								variant='caption'
								style={{ marginBottom: -3, fontWeight: 'bold' }}>
								{strings.createdByUser}
							</Typography>
							<div>{createdByUser.identifier}</div>
						</div>
					</div>
				)} */}
			</div>
		</div>
	);
};
