import { useMemo } from 'react';

export const useOpenInNewTab = () => {
	const baseUrl = useMemo(() => window.location.origin, []);
	return (url: string, isAbsolute: boolean) => {
		const xUrl = isAbsolute ? url : `${baseUrl}${url}`;
		const newWindow = window.open(xUrl, '_blank', 'noopener,noreferrer');
		if (newWindow) {
			newWindow.opener = null;
		}
	};
};
