import { IFormProps } from 'framework/forms/common/IFormProps';
import { FormSelectFieldFromRecord } from 'framework/forms/FormSelectFieldFromRecord';
import { LanguageOption } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

export const FormSelectLanguageField = <TModel extends unknown>({ ...props }: IFormProps<TModel>) => {
	const { localizedLanguageRecord } = useContext(RecordContext);

	return (
		<FormSelectFieldFromRecord<TModel, LanguageOption>
			{...props}
			record={localizedLanguageRecord}
		/>
	);
};
