import React, { useContext } from 'react';
import { IDocumentSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DocumentCreateOrUploadForm } from 'documents/DocumentCreateOrUploadForm';
import { DocumentListItemOrEmpty } from 'documents/DocumentListItemOrEmpty';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	document: IDocumentSummary;
	rfiaId: string;
	reload: VoidFunction;
}

export const AnamnesisListItem = ({ rfiaId, document, reload }: IProps) => {
	const { documentPredefinedTypeRecord } = useContext(RecordContext);
	const { open, cancel, confirm } = useDialogsContext(reload);
	const strings = useLocalization();

	const onNew = () => {
		open(
			<DocumentCreateOrUploadForm
				contextId={rfiaId}
				context='Rfia'
				templatePredefinedType='RfiaAnamnesis'
				title={strings.anamnesis}
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	return (
		<DocumentListItemOrEmpty
			document={document}
			reload={reload}
			title={documentPredefinedTypeRecord['RfiaAnamnesis']}
			onNew={onNew}
		/>
	);
};
