import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IQueryResult, IUserDto, IUsersQueryParams, usersQuery_concurrencyToken, usersQuery_query } from 'gen/ApiClient';
import { usersQueryParamsLsKey } from '../../../localStorageKeys';
import { UsersQueryContext } from './UsersQueryContext';

const DefaultParams: IUsersQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	canLogin: undefined,
	isLoggedIn: undefined,
	hasConfirmed: undefined,
	lastLoggedInAfter: undefined,
	lastLoggedInBefore: undefined,
	createdAfter: undefined,
	createdBefore: undefined,
};

export const UsersQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IUserDto>, IUsersQueryParams>
			defaultParams={DefaultParams}
			queryF={usersQuery_query}
			concurrencyF={usersQuery_concurrencyToken}
			localStorageKey={usersQueryParamsLsKey}
			context={UsersQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
