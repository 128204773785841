import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { ISale } from 'gen/ApiClient';
import React from 'react';
import { SalesSettingsRoute } from '../../routes';
import { SalesPageBreadcrumb } from '../SalesPageBreadcrumb';
import { SalesChips } from '../components/SalesChips';

interface IProps {
	item: ISale;
	reload: VoidFunction;
}

export const SalesDetailPageHeader = ({ item, reload }: IProps) => {
	return (
		<div
			className='df-col fg1'
			style={{ paddingBottom: 16 }}>
			<div className='df-row-ac'>
				<SalesPageBreadcrumb />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={item.reference} />
				<SalesChips
					item={item}
					style={{ marginLeft: 4 }}
				/>
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageSettingsButtonX route={SalesSettingsRoute} />
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
		</div>
	);
};
