import { Form, Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { FormPasswordField } from 'framework/forms/FormPasswordField';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { auth_confirm, auth_logout } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { AuthContext } from './AuthContext';
import { EmptyPageWithLogo } from './EmptyPageWithLogo';
import { useHandleLogin } from './useHandleLogin';

interface IModel {
	email: string;
	password: string;
	confirmPassword: string;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		email: yup.string().email().defined(),
		password: yup.string().required(strings.formRequired(strings.password)),
		confirmPassword: yup
			.string()
			.required(strings.formRequired(strings.confirmPassword))
			.oneOf([yup.ref('password')], strings.passwordDoesNotMatch),
	});
};

export const ConfirmUser = () => {
	const authContext = useContext(AuthContext);
	const [confirmUser, isSubmitting, setSubmitting] = useFormSubmit(auth_confirm);
	const [logout] = useFormSubmit(auth_logout, setSubmitting);
	const strings = useLocalization();
	const { token, email } = useParams<{ token: string; email: string }>();
	const handleLogin = useHandleLogin();

	useEffect(() => {
		logout();
		authContext.logout();
		// only happens at mount of this screen
		// eslint-disable-next-line
	}, []);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await confirmUser({ registrationTokenId: token, password: values.password, email: email });
		if (handleFormResponse(r, helpers)) {
			handleLogin(r.result);
		}
	};

	return (
		<EmptyPageWithLogo title={strings.setPassword}>
			<Formik<IModel>
				validateOnMount
				initialValues={{ email: email, password: '', confirmPassword: '' }}
				validationSchema={createSchema(strings)}
				onSubmit={handleSubmit}>
				{props => (
					<Form
						autoComplete='off'
						className='df-col'
						style={{ minWidth: '400px' }}>
						<FormTextField<IModel>
							name='email'
							label={strings.email}
							required
							disabled
						/>
						<FormPasswordField<IModel>
							name='password'
							label={strings.password}
							required
						/>
						<FormPasswordField<IModel>
							name='confirmPassword'
							label={strings.confirmPassword}
							required
						/>
						<LoaderButton
							style={{ marginTop: '20px' }}
							size='large'
							variant='contained'
							color='primary'
							type='submit'
							isLoading={isSubmitting}
							disabled={isSubmitting || !props.isValid}>
							{strings.setPassword}
						</LoaderButton>
					</Form>
				)}
			</Formik>
		</EmptyPageWithLogo>
	);
};
