import React from 'react';

const DefaultGrayColor = '#5f6368';

interface IProps {
	icon: React.ReactNode;
	label: string | undefined | JSX.Element;
	show: boolean;
	style?: React.CSSProperties;
	children?: any;
	wrap?: boolean;
}

export const RosaEventPopoverPropertyComponent = ({ icon, label, show, style, children, wrap }: IProps) => {
	return show === false ? (
		<></>
	) : (
		<div
			className='df-row-ac'
			style={style}>
			<div
				className='df-col jc-c'
				style={{ color: DefaultGrayColor, width: '40px' }}>
				{icon}
			</div>
			<div
				className='df-col'
				style={{ paddingTop: 6, paddingBottom: 6 }}>
				<div style={{ whiteSpace: wrap === true ? 'pre-line' : 'inherit' }}>{label}</div>
			</div>
			{children && children}
		</div>
	);
};
