import { DialogProps, Divider } from '@material-ui/core';
import React, { useContext } from 'react';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { HamsClientApplicationContext } from 'app/externalApps/client/HamsClientApplicationContext';
import { DownloadAndInstallSoftwareDialog } from './DownloadAndInstallSoftwareDialog';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	close: VoidFunction;
}

export const DownloadAndInstallClientSoftwareDialog = ({ ...rest }: IProps) => {
	const strings = useLocalization();
	const { download, isDownloading } = useContext(HamsClientApplicationContext);

	return (
		<DownloadAndInstallSoftwareDialog
			{...rest}
			download={download}
			isDownloading={isDownloading}
			title={strings.downloadAndInstallHamsNoahClientSoftware}
			prependChildren={
				<>
					<LightBulbNotification>{strings.warningHamsNoahClientVersion}</LightBulbNotification>
					<Divider style={{ marginTop: 16, marginBottom: 24 }} />
				</>
			}
		/>
	);
};
