import { PageX } from 'framework/components/layout/PageX';
import { useTrigger } from 'framework/hooks/useTrigger';
import { useNavigationLinkRouting } from 'framework/router/useNavigationLinkRouting';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as routes from '../routes';
import { CommissionsPageHeader } from './CommissionsPageHeader';
import { CommissionsTabType } from './CommissionsTabType';
import { AllCommissionsPage } from './commissions/AllCommissionsPage';
import { MyCommissionsPage } from './commissions/MyCommissionsPage';
import { AllCommissionPayoutsPage } from './payouts/AllCommissionPayoutsPage';
import { MyCommissionPayoutsPage } from './payouts/MyCommissionPayoutsPage';

const RouteRecord: Record<CommissionsTabType, string> = {
	allCommissions: routes.AllCommissionsRoute,
	allPayouts: routes.AllCommissionPayoutsRoute,
	myCommissions: routes.MyCommissionsRoute,
	myPayouts: routes.MyCommissionPayoutsRoute,
};

export const CommissionsPageSwitch = () => {
	const [trigger, hitTrigger] = useTrigger();
	const [tab, setTab] = useNavigationLinkRouting<CommissionsTabType>(RouteRecord, 'myCommissions');

	return (
		<PageX
			stickyHeader={
				<CommissionsPageHeader
					tab={tab}
					setTab={setTab}
					reload={hitTrigger}
				/>
			}>
			<div
				className='df-col'
				style={{ padding: 16 }}>
				<Switch>
					<Route
						path={routes.MyCommissionPayoutsRoute}
						render={() => <MyCommissionPayoutsPage reloadTrigger={trigger} />}
					/>
					<Route
						path={routes.AllCommissionPayoutsRoute}
						render={() => <AllCommissionPayoutsPage reloadTrigger={trigger} />}
					/>
					<Route
						path={routes.AllCommissionsRoute}
						render={() => <AllCommissionsPage reloadTrigger={trigger} />}
					/>
					<Route
						path={routes.MyCommissionsRoute}
						render={() => <MyCommissionsPage reloadTrigger={trigger} />}
					/>
					<Redirect to={routes.MyCommissionsRoute} />
				</Switch>
			</div>
		</PageX>
	);
};
