import React from 'react';
import { createHeader } from 'framework/table/createHeader';
import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IStrings } from 'localization/IStrings';
import { boolToYesNo } from 'shared/utils/boolToString';
import { SalesSummaryPaymentStatusAndCreditChip } from '../../../sales/components/SalesSummaryPaymentStatusAndCreditChip';
import { ICommissionWithSelection } from './ICommissionWithSelection';

export const createHeaders = (
	lines: ICommissionWithSelection[],
	filtered: ICommissionWithSelection[],
	setLines: React.Dispatch<React.SetStateAction<ICommissionWithSelection[]>>,
	strings: IStrings
): IHeader<ICommissionWithSelection>[] => {
	return [
		createSelectionHeader<ICommissionWithSelection>(filtered, newLines => {
			const x = lines.map(t => ({ ...t, isSelected: newLines.find(x => x.id === t.id)?.isSelected === true }));
			setLines(x);
		}),
		createSortableHeader<ICommissionWithSelection>(strings.date, 'created', t => formatDate(t.created)),
		createHeader<ICommissionWithSelection>(`${strings.paymentStatus} (${strings.sale})`, t => <SalesSummaryPaymentStatusAndCreditChip item={t.sale} />),
		createHeader<ICommissionWithSelection>(`Betaald door patiënt?`, t => boolToYesNo(t.sale.isPaidByPatientOrCompany, strings)),
		createHeader<ICommissionWithSelection>(`Betaald door V.I.?`, t => boolToYesNo(t.sale.isPaidByHif, strings)),
		createSortableHeader<ICommissionWithSelection>(strings.date, 'salesDate', t => formatDate(t.salesDate)),
		createSortableHeader<ICommissionWithSelection>(strings.reference, 'salesReference', t => t.salesReference),
		createSortableHeader<ICommissionWithSelection>(strings.withRespectToShort, 'salesRecipientName', t => t.salesRecipientName),
		createSortableHeader<ICommissionWithSelection>(strings.rule, 'ruleName', t => t.ruleName),
		createSortableHeader<ICommissionWithSelection>(strings.rule, 'rulePercentage', t => `${t.rulePercentage} %`),
		createSortableHeader<ICommissionWithSelection>(strings.amountAsValue, 'value', t => formatCurrency(t.value)),
	];
};
