import { DialogProps, DialogTitle, List, useTheme } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { AlertDialogWithCreateResponse } from 'framework/dialogs/AlertDialogWithCreateResponse';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { isLast } from 'framework/utils/array/isLast';
import {
	IPatientSearch,
	IRosaPatientRecord,
	rosaPatientsCommand_createInHams,
	rosaPatientsCommand_link,
	rosaPatientsQuery_findMatches,
	rosaPatientsQuery_isLinked,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PatientSearchProvider } from 'shared/patientSearch/PatientSearchProvider';
import { SelectPatient } from 'shared/patientSearch/SelectPatient';
import { HamsMatchedPatientListItem } from './HamsMatchedPatientListItem';
import { ImportRosaPatientAccordion } from './ImportRosaPatientAccordion';
import { ImportRosaPatientAccordionMode } from './ImportRosaPatientAccordionMode';

interface IProps extends DialogProps {
	item: IRosaPatientRecord;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const ImportRosaPatientRecordDialog = ({ item, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const { open, confirm: localConfirm, cancel: localCancel, isStacked } = useDialogsContext();
	const [mode, setMode] = useState<ImportRosaPatientAccordionMode>('matchOnSsNr');
	const [matchResponse] = useApiEffect(rosaPatientsQuery_findMatches, item.id!);
	const loadIsLinked = useApiCall(rosaPatientsQuery_isLinked);
	// const loadLinkSummary = useApiCall(rosaPatientsQuery_linkSummary);
	const theme = useTheme();

	useEffect(() => {
		if (matchResponse !== undefined) {
			if (matchResponse.hasMatchesOnName === false && matchResponse.hasMatchesOnSocialSecurityNumber === false) {
				setMode('search');
			} else if (matchResponse.hasMatchesOnSocialSecurityNumber === false) {
				setMode('matchOnName');
			} else {
				// don't do anything!
			}
		}
	}, [matchResponse]);

	if (matchResponse === undefined) {
		return <></>;
	}

	const onCreateNew = () => {
		open(
			<AlertDialogWithCreateResponse
				open
				title={strings.createNewPatient}
				content={strings.doYouWantToCreateANewPatientBasedOnRosaPatientRecordData}
				acceptText={strings.yesWhatExclamation(strings.continue)}
				rejectText={strings.cancel}
				reject={localCancel}
				acceptF={() => rosaPatientsCommand_createInHams({ rosaPatientRecordId: item.id })}
				accept={id => {
					localConfirm();
					confirm(id);
				}}
			/>
		);
	};

	const onSelectSearchPatient = async (patient: IPatientSearch | undefined) => {
		if (patient !== undefined) {
			const r = await loadIsLinked(patient.id);
			if (r.isSuccess) {
				onLinkWithExistingPatient(patient, r.result);
			}
		}
	};

	const onLinkWithExistingPatient = (patient: IPatientSearch, isLinkedToARosaPatient: boolean) => {
		open(
			<AlertDialog
				open
				title={strings.linkRosaPatient}
				content={
					<div className='df-col'>
						{isLinkedToARosaPatient && (
							<div
								className='df-row-ac'
								style={{ marginBottom: 8 }}>
								<StatusIcon
									status='warning'
									style={{ marginRight: 8 }}
								/>
								<div style={{ color: theme.palette.warning.main }}>
									{strings.warningThisPatientAlreadyHasALinkWithAnotherRosaRecod(patient.lastNameCommaFirstNameBracketsNickName!)}
								</div>
							</div>
						)}
						<div>{strings.doYouWantToLinkThisRosaPatientWithAnExistingPatientFromHAMS}</div>
					</div>
				}
				acceptText={strings.yesWhatExclamation(strings.continue)}
				rejectText={strings.cancel}
				reject={localCancel}
				acceptF={() => rosaPatientsCommand_link({ rosaPatientRecordId: item.id, hamsPatientId: patient.id })}
				accept={() => {
					localConfirm();
					confirm(patient.id);
				}}
			/>
		);
	};

	return (
		<PatientSearchProvider>
			<OverflowDialog
				fullWidth
				{...rest}
				open={rest.open && isStacked === false}>
				<CloseButtonOnAbsolutePosition onClick={cancel} />
				<DialogTitle>
					<div className='df-row-ac'>
						<LinkIcon
							fontSize='large'
							color='disabled'
							style={{ marginRight: 8 }}
						/>
						<div>{strings.importRosaPatient}</div>
					</div>
					<LabelledProperty
						label={strings.lastNameCommaFirstName}
						property={item.lastNameCommaFirstName}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.socialSecurityNumber}
						property={item.formattedSocialSecurityNumber}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.phone}
						property={item.primaryPhoneNumber}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.email}
						property={item.primaryEmail}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.address}
						property={item.formattedAddress}
						withoutMargin
					/>
				</DialogTitle>
				<OverflowDialogContent className='df-col'>
					<ImportRosaPatientAccordion
						icon={
							<StatusIcon
								status={matchResponse.hasMatchesOnSocialSecurityNumber ? 'success' : 'error'}
								style={{ marginRight: 4 }}
								fontSize='small'
							/>
						}
						title={`1. ${strings.foundPatientsOnSsNr} (${matchResponse.countMatchedOnSocialSecurityNumber})`}
						mode='matchOnSsNr'
						selectedMode={mode}
						setMode={setMode}
						disabled={matchResponse.hasMatchesOnSocialSecurityNumber === false}>
						<List
							disablePadding
							className='fg1'>
							{matchResponse.matchedHamsPatientOnSocialSecurityNumber.map((t, index) => (
								<HamsMatchedPatientListItem
									key={t.id}
									index={index}
									item={t}
									isLast={isLast(t, matchResponse.matchedHamsPatientOnSocialSecurityNumber)}
									onClick={() => onLinkWithExistingPatient(t, t.isLinkedToARosaPatient)}
								/>
							))}
						</List>
					</ImportRosaPatientAccordion>
					<ImportRosaPatientAccordion
						icon={
							<StatusIcon
								status={matchResponse.hasMatchesOnName ? 'success' : 'error'}
								style={{ marginRight: 4 }}
								fontSize='small'
							/>
						}
						title={`2. ${strings.foundPatientsOnName} (${matchResponse.countMatchedOnName})`}
						mode='matchOnName'
						selectedMode={mode}
						setMode={setMode}
						disabled={matchResponse.hasMatchesOnName === false}>
						<List
							disablePadding
							className='fg1'>
							{matchResponse.matchedHamsPatientsOnName.map((t, index) => (
								<HamsMatchedPatientListItem
									key={t.id}
									index={index}
									item={t}
									isLast={isLast(t, matchResponse.matchedHamsPatientsOnName)}
									onClick={() => onLinkWithExistingPatient(t, t.isLinkedToARosaPatient)}
								/>
							))}
						</List>
					</ImportRosaPatientAccordion>
					<ImportRosaPatientAccordion
						icon={
							<SearchOutlinedIcon
								style={{ marginRight: 4 }}
								fontSize='small'
								color='action'
							/>
						}
						title={`3. ${strings.search}...`}
						mode='search'
						selectedMode={mode}
						setMode={setMode}
						withPadding>
						<SelectPatient
							canCreateNew={false}
							onSetSelected={onSelectSearchPatient}
							selectedId={undefined}
							style={{ flexGrow: 1 }}
						/>
					</ImportRosaPatientAccordion>
					<ImportRosaPatientAccordion
						icon={
							<SystemUpdateAltOutlinedIcon
								style={{ marginRight: 4 }}
								fontSize='small'
								color='action'
							/>
						}
						title={`4. ${strings.createANewPatientInHAMS}`}
						mode='new'
						selectedMode={mode}
						setMode={setMode}
						withPadding>
						<SmallPrimaryTextButton
							startIcon={<PersonAddOutlinedIcon />}
							onClick={onCreateNew}>
							{strings.createANewPatientInHAMS}
						</SmallPrimaryTextButton>
					</ImportRosaPatientAccordion>
				</OverflowDialogContent>
			</OverflowDialog>
		</PatientSearchProvider>
	);
};
