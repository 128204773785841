import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ICashRegisterEntry, SalesType } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';
import React from 'react';
import { CashRegisterPaymentReferenceOrDescriptionHeader } from '../CashRegisterPaymentReferenceOrDescriptionHeader';

export const createHeaders = (
	colRecord: Record<SelectableColumn, string>,
	typeRecord: Record<SalesType, string>,
	strings: IStrings
): Record<SelectableColumn, IHeader<ICashRegisterEntry>> => {
	return {
		locationIdentifier: createSortableHeaderWithKey<ICashRegisterEntry, SelectableColumn>(colRecord, 'locationIdentifier', t => t.locationIdentifier),
		value: createSortableHeaderWithKey<ICashRegisterEntry, SelectableColumn>(colRecord, 'value', t => formatCurrency(t.value)),
		globalResultingBalance: createSortableHeaderWithKey<ICashRegisterEntry, SelectableColumn>(colRecord, 'globalResultingBalance', t =>
			formatCurrency(t.globalResultingBalance)
		),
		referenceDate: createSortableHeaderWithKey<ICashRegisterEntry, SelectableColumn>(colRecord, 'referenceDate', t => formatDate(t.referenceDate)),
		index: createSortableHeaderWithKey<ICashRegisterEntry, SelectableColumn>(colRecord, 'index', t => t.index.toString()),
		paymentReferenceOrDescription: createSortableHeaderWithKey<ICashRegisterEntry, SelectableColumn>(colRecord, 'paymentReferenceOrDescription', t => (
			<CashRegisterPaymentReferenceOrDescriptionHeader item={t} />
		)),
	};
};
