import { Form, Formik, FormikHelpers } from 'formik';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { ILocationSummary } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';
import { EmptyPageWithLogo } from './EmptyPageWithLogo';
import { ILocationResponse } from './ILocationResponse';

interface IModel {
	id: string;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			id: yup.string().required(strings.formRequired(strings.location)),
		})
		.defined();
};

interface IProps {
	response: ILocationResponse;
	isSubmitting: boolean;
	confirm: (id: string) => void;
}

export const SelectLocation = ({ response, isSubmitting, confirm }: IProps) => {
	const strings = useLocalization();

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		confirm(values.id);
	};

	return (
		<EmptyPageWithLogo title={strings.selectLocationAtWhereYouAreCurrentlyWorking}>
			<Formik<IModel>
				validateOnMount
				initialValues={{ id: response.preferredLocationId ?? '' }}
				validationSchema={createSchema(strings)}
				onSubmit={handleSubmit}>
				{props => (
					<Form
						autoComplete='off'
						className='df-col'
						style={{ minWidth: '400px' }}>
						<FormAutocompleteField<IModel, ILocationSummary>
							name='id'
							label={strings.location}
							options={response.locations}
							style={{ width: '100%' }}
						/>
						<LoaderButton
							style={{ marginTop: '20px' }}
							size='large'
							variant='contained'
							color='primary'
							type='submit'
							isLoading={isSubmitting}
							disabled={isSubmitting || !props.isValid}>
							{strings.select}
						</LoaderButton>
					</Form>
				)}
			</Formik>
		</EmptyPageWithLogo>
	);
};
