import { ILocationSummary } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { StockItemDtoSelectableColumn } from '../StockItemDtoSelectableColumn';

interface IMap {
	key: string;
	text: string;
}

export const createColumnRecord = (strings: IStrings, locations: ILocationSummary[]): Record<string, string> => {
	const xRecord = createColumnRecordFromSelectableColumn(strings);
	const xMap = Object.keys(xRecord).map<IMap>(t => ({ key: t, text: xRecord[t as StockItemDtoSelectableColumn] }));
	const yMap = locations.map<IMap>(location => ({ key: location.id, text: `# ${strings.inStock}\n@ ${location.name}` }));
	const xyMap = [...xMap, ...yMap];
	return xyMap.reduce((acc, item) => ({ ...acc, [item.key]: item.text }), {} as Record<string, string>);
};

const createColumnRecordFromSelectableColumn = (strings: IStrings): Record<StockItemDtoSelectableColumn, string> => {
	return {
		manufacturer: strings.manufacturer,
		model: strings.model,
		category: strings.category,
		subcategory: strings.subcategory,
		internalReference: strings.internalReference,
		manufacturerReferencesCsv: strings.manufacturerReference,
		hasSerialNumber: strings.withWhat(strings.serialNumber),
		supplierIdentifierCsv: strings.supplierSingularOrPlural,
		manufacturerCommaModel: `${strings.manufacturer}, ${strings.model}`,
		financialQuantity: strings.financialQuantity,
		physicalQuantity: strings.physicalQuantity,
		countInBackOrder: `# ${strings.inBackOrder}`,
		countInRepair: `# ${strings.inRepair}`,
		countPurchased: `# ${strings.statusPurchased}`,
		countSold: `# ${strings.statusSold}`,
		stockValue: strings.stockValue,
	};
};
