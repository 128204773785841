import { getNow } from 'shared/utils/getNow';
import { parseDate } from './parseDate';

export function parseDateOrDefault(date: Date | string | number | undefined | null, defaultValue?: Date): Date {
	if (date === undefined || date === null) {
		return defaultValue ?? getNow();
	} else {
		return parseDate(date);
	}
}
