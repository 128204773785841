import React from 'react';
import { NoahDeviceReadOutsProviders } from './rules/context/NoahDeviceReadOutsProvider';
import { NoahDevicesProviders } from './single/context/NoahDevicesProviders';

export const NoahDevicesWrapperProviders = ({ children }: any) => {
	return (
		<NoahDevicesProviders>
			<NoahDeviceReadOutsProviders>{children}</NoahDeviceReadOutsProviders>
		</NoahDevicesProviders>
	);
};
