import React, { useCallback, useEffect, useState } from 'react';
import { useApi } from '../hooks/useApi';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useTrigger } from '../hooks/useTrigger';
import { Try } from '../Try';
import { IQueryContext } from './IQueryContext';

interface IProps<TResult extends { concurrencyToken: number }, TParams> {
	children: any;
	defaultParams: TParams;
	context: React.Context<IQueryContext<TResult, TParams>>;
	queryF: (pars: TParams) => Promise<Try<TResult>>;
	localStorageKey: string;
	concurrencyF: () => Promise<Try<number>>;
}

export const QueryProvider = <TResult extends { concurrencyToken: number }, TParams>({
	children,
	defaultParams,
	context,
	queryF,
	localStorageKey,
	concurrencyF,
}: IProps<TResult, TParams>) => {
	const [params, setParams] = useLocalStorage<TParams>(localStorageKey, defaultParams);
	const [trigger, reload] = useTrigger();
	const concurrencyToken = React.useRef<number>(-1);
	const canExecute = React.useRef<boolean>(false);
	const [queryResult, setQueryResult] = useState<TResult | undefined>(undefined);
	const [setLoad, clearLoad, handleError] = useApi();

	useEffect(() => {
		let cancelled = false;
		const loadX = async () => {
			await load(params);
		};
		if (cancelled === false && canExecute.current === true) {
			loadX();
		}
		return () => {
			cancelled = true;
		};
		// missing deps queryF.name -> will not change and handleMainError -> is a function
		// eslint-disable-next-line
	}, [trigger, params]);

	const load = useCallback(async (params: TParams) => {
		console.log(`load on QueryProvider ${queryF.name}`);
		const loadId = setLoad();
		const r = await queryF(params);
		if (r.isSuccess) {
			setQueryResult(r.result);
			concurrencyToken.current = r.result.concurrencyToken;
		} else {
			handleError(r.error);
		}
		clearLoad(loadId);
		return new Try<TResult>(r.isSuccess ? r.result : (undefined as any), r.isFailure ? r.error : undefined);
		// eslint-disable-next-line
	}, []);

	const check = useCallback(async () => {
		console.log(`executing check on ${concurrencyF.name}`);
		const loadId = setLoad();
		const r = await concurrencyF();
		clearLoad(loadId);
		if (r.isFailure) {
			handleError(r.error);
		} else if (r.result > concurrencyToken.current || concurrencyToken.current === -1) {
			canExecute.current = true;
			reload();
		}
		// eslint-disable-next-line
	}, []);

	return (
		<context.Provider
			value={{
				queryResult: queryResult,
				reload: reload,
				params: params,
				setParams: setParams,
				// init: useCallback(() => setCanExecute(true), []),
				init: check,
				concurrencyToken: concurrencyToken.current,
			}}>
			{children}
		</context.Provider>
	);
};

// interface IProps<TResult extends { concurrencyToken: number }, TParams> {
//     children: any;
//     defaultParams: TParams;
//     context: React.Context<IQueryContext<TResult, TParams>>;
//     queryF: (pars: TParams) => Promise<Try<TResult>>;
//     preload?: boolean;
//     localStorageKey: string;
//     concurrencyF: () => Promise<Try<number>>
// }

// export const OldQueryProvider = <TResult extends { concurrencyToken: number}, TParams>({ children, preload = false, defaultParams, context, queryF, localStorageKey, concurrencyF }: IProps<TResult, TParams>) => {
//     const [params, setParams] = useLocalStorage<TParams>(localStorageKey, defaultParams);
//     const [canExecute, setCanExecute] = useState<boolean>(preload);
//     const [trigger, reload] = useTrigger();
//     const [concurrencyToken, setConcurrencyToken] = useState<number>(-1);
//     const [queryResult, setQueryResult] = useState<TResult | undefined>(undefined);
//     const [setMainLoading, handleError, isMainLoading] = useApi();
//     const [serverConcurrencyToken, setServerConcurrencyToken] = useState<number>(-1);
//     const [setIsCheckLoading] = useApi();

//     useEffect(() => {
//         let cancelled = false;
//         const load = async () => {
//             console.log(`load on QueryProvider ${queryF.name}`);
//             setMainLoading(true);
//             const r = await queryF(params);
//             if (r.isSuccess && cancelled === false) {
//                 setQueryResult(r.result);
//                 // if (serverConcurrencyToken > r.result.concurrencyToken) {
//                 //     throw new Error(`error on QueryProvider ${queryF.name}, serverConcurrencyToken > concurrencyToken of data.`)
//                 // }
//                 setConcurrencyToken(r.result.concurrencyToken);
//             } else if (r.isFailure) {
//                 console.log('on failure');
//                 // disable the reloads on failure
//                 setServerConcurrencyToken(-1);
//                 handleError(r.error);
//             }
//             // comment: setIsLoading AFTER setData (important!)
//             setMainLoading(false);
//         }
//         if (canExecute === true && cancelled === false) {
//             load();
//         }
//         return () => {
//             cancelled = true;
//         }
//     // missing deps queryF.name -> will not change and handleMainError -> is a function
//     // eslint-disable-next-line
//     }, [trigger, params, canExecute, queryF, setMainLoading]);

//     const check = useCallback(async () => {
//         setIsCheckLoading(true);
//         const r = await concurrencyF();
//         if (r.isFailure) {
//             handleError(r.error);
//         }
//         setServerConcurrencyToken(r.isSuccess ? r.result : -1);
//         setIsCheckLoading(false);
//     // missing deps handleCheckError -> is a function
//     // eslint-disable-next-line
//     }, [concurrencyF, setIsCheckLoading]);

//     useEffect(() => {
//         if (canExecute && isMainLoading === false && serverConcurrencyToken > concurrencyToken) {
//             console.log(`concurrencyToken reload QueryProvider ${queryF.name}`);
//             reload();
//             // console.log('wants to reload')
//             // probleem als het in error gaat blijft deze reloaden
//         }
//     // missing deps queryF.name -> will not change
//     // eslint-disable-next-line
//     }, [concurrencyToken, serverConcurrencyToken, reload, canExecute, isMainLoading]);

//     const init = useCallback(async () => {
//         if (canExecute === false) {
//             console.log(`init QueryProvider: ${queryF.name}`);
//             setCanExecute(true);
//         } else {
//             console.log(`check QueryProvider: ${queryF.name}`);
//             check();
//         }
//     // missing deps queryF.name -> will not change
//     // eslint-disable-next-line
//     }, [check, canExecute]);

//     return (
//         <context.Provider
//             value={{
//                 queryResult: queryResult,
//                 reload: reload,
//                 params: params,
//                 setParams: setParams,
//                 init: init,
//                 // isLoading: isMainLoading || isCheckLoading,
//                 // error: error || error2,
//                 concurrencyToken: concurrencyToken,
//             }}>
//             {children}
//         </context.Provider>
//     );
// }
