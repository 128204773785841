import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	commissionPayoutsQuery_concurrencyToken,
	commissionPayoutsQuery_query,
	ICommissionPayout,
	ICommissionPayoutsQueryParams,
	IQueryResult,
} from 'gen/ApiClient';
import { allCommissionPayoutsQueryParamsLsKey } from '../../../../../localStorageKeys';
import { AllQueryContext } from './AllQueryContext';
import { DefaultParams } from './DefaultParams';

export const AllQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<ICommissionPayout>, ICommissionPayoutsQueryParams>
			defaultParams={DefaultParams}
			queryF={commissionPayoutsQuery_query}
			concurrencyF={commissionPayoutsQuery_concurrencyToken}
			localStorageKey={allCommissionPayoutsQueryParamsLsKey}
			context={AllQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
