import { Divider } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import StarOutlineOutlinedIcon from '@material-ui/icons/StarOutlineOutlined';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ITender, tendersCommand_delete, tendersCommand_markAsLost } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TenderDetailDocumentContent } from './TenderDetailDocumentContent';

interface IProps {
	item: ITender;
	reload: VoidFunction;
}

export const TenderDetailPageSidebar = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title={strings.deleteWhat(strings.tender)}
				content={`${strings.deleteWhat(strings.tender)}?`}
				acceptText={strings.yesWhatExclamation(strings.deleteWhat(strings.tender))}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => tendersCommand_delete({ tenderId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onMarkAsLost = () => {
		open(
			<AlertDialog
				open
				title={strings.markAsLost}
				content={`${strings.markAsLost}?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => tendersCommand_markAsLost({ tenderId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<div
			className='df-col h100'
			style={{
				padding: 16,
				paddingRight: 8,
				// width: 250,
			}}>
			<div className='df-col gap-6'>
				<CaptionVerticalLabelledPropertyX label={strings.reference}>{item.reference}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.date}>{formatDate(item.created)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.payBefore}>{formatDate(item.validUntil)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.totalInVat}>{formatCurrency(item.priceInVat)}</CaptionVerticalLabelledPropertyX>
			</div>
			<Divider style={{ marginTop: 16, marginBottom: 8 }} />
			<TenderDetailDocumentContent
				item={item}
				reload={reload}
			/>
			<Divider style={{ marginTop: 16, marginBottom: 8 }} />
			<div className='fg1'></div>
			<DividerWithMargin />
			<div className='df-col gap-12'>
				<TextLinkButton
					startIcon={<StarOutlineOutlinedIcon />}
					onClick={onMarkAsLost}
					disabled={item.isLost}>
					{strings.markAsLost}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<DeleteOutlineOutlinedIcon />}
					color='secondary'
					onClick={onDelete}
					disabled={item.isDeleted}>
					{strings.deleteWhat(strings.tender)}
				</TextLinkButton>
			</div>
		</div>
	);
};
