import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { GridDivider } from 'framework/components/detailDialog/GridDivider';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { PaperOutlined } from 'framework/table/PaperOutlined';
import { isLast } from 'framework/utils/array/isLast';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IMedicalTreatmentFeeDto, IRegisterRfiaHandoverSingleRequest, IRequestForInsuranceAllowance } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { lineToHandoverLineMode } from '../../utils/lineToHandoverLineMode';
import { IHandoverLine } from './IHandoverLine';
import { ViewContext } from '../../context/ViewContext';
import { boolToYesNo } from 'shared/utils/boolToString';

interface IProps {
	lines: IHandoverLine[];
	fees: IMedicalTreatmentFeeDto[];
	rfia: IRequestForInsuranceAllowance;
}

export const SingleHandoverConfirmation = ({ lines, fees, rfia }: IProps) => {
	const strings = useLocalization();
	const contributions = useMemo(() => _.sum(fees.map(t => t.contribution)), [fees]);
	const totalOnInvoice = useMemo(() => _.sum(lines.map(t => t.unitPrice * t.quantity)), [lines]);
	const totalForPatient = useMemo(() => totalOnInvoice - contributions, [totalOnInvoice, contributions]);
	const toPayPatient = useMemo(() => (rfia.hasThirdPartyPaymentScheme ? totalForPatient : totalOnInvoice), [rfia, totalForPatient, totalOnInvoice]);
	const props = useFormikContext<IRegisterRfiaHandoverSingleRequest>();
	const { handoverLineModeRecord } = useContext(ViewContext);

	return (
		<Grid
			container
			spacing={2}
			style={{ maxWidth: 550 }}>
			<Grid
				item
				xs={12}
				className='df-col-ac'>
				<LightBulbNotification>{strings.thisTransactionCannotBeUndoneText}</LightBulbNotification>
			</Grid>
			<GridDivider />
			<PropertyInGrid
				labelGridSize={6}
				label={strings.invoiceNumber}
				value={props.values.reference}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.withRespectToShort}
				value={props.values.recipientName}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.healthInsuranceFund}
				value={rfia.healthInsuranceFundCode}
			/>
			<GridDividerWithCaption caption={strings.prices} />
			<PropertyInGrid
				labelGridSize={6}
				label={strings.total}
				value={formatCurrency(totalOnInvoice)}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={`${strings.contribution} (${fees.map(t => t.code).join('-')})`}
				value={formatCurrency(contributions)}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.partPatient}
				value={formatCurrency(totalForPatient)}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.thirdPayerArrangement}
				value={boolToYesNo(rfia.hasThirdPartyPaymentScheme, strings)}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.toPayByPatient}
				value={formatCurrency(toPayPatient)}
			/>
			<GridDividerWithCaption caption={strings.splitUpAttachment12} />
			<Grid
				item
				xs={12}
				className='df-col-ac'>
				<TableContainer
					component={PaperOutlined}
					style={{ width: 'fit-content' }}>
					<Table size='small'>
						<TableBody>
							{lines.map(t => (
								<TableRow key={t.id}>
									<TableCell style={{ fontWeight: 500, borderBottom: isLast(t, lines) ? 'none' : '1px solid rgb(224, 224, 224)' }}>
										{handoverLineModeRecord[lineToHandoverLineMode(t)]}
									</TableCell>
									<TableCell
										style={{ borderBottom: isLast(t, lines) ? 'none' : '1px solid rgb(224, 224, 224)' }}>{`${t.quantity} x ${t.description}`}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
};
