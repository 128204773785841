import BlurCircularOutlinedIcon from '@material-ui/icons/BlurCircularOutlined';
import NotInterestedOutlinedIcon from '@material-ui/icons/NotInterestedOutlined';
import { IEntPhysician } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: IEntPhysician;
	style?: React.CSSProperties;
}

export const EntPhysicianListItemStatusIcon = ({ item, style }: IProps) => {
	if (item.isDeleted) {
		return <NotInterestedOutlinedIcon style={style} />;
	} else {
		return <BlurCircularOutlinedIcon style={style} />;
	}
};
