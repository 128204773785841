import { CardProps, Divider, useTheme } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { ExactGlobeSalesQueryParamsLsKey } from 'app/localStorageKeys';
import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { InnerPageXWithStickySidebar } from 'framework/components/layout/InnerPageXWithStickySidebar';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { IQueryContext } from 'framework/context/IQueryContext';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { formatDate } from 'framework/utils/date/formatDate';
import {
	ExactGlobeSalePrefilter,
	ExactGlobeSalePrefilters,
	exactGlobeSales_concurrencyToken,
	exactGlobeSales_exportUnhandled,
	exactGlobeSales_markAllAsHandled,
	exactGlobeSales_query,
	IExactGlobeSaleDto,
	IExactGlobeSalesQueryParams,
	IQueryResult,
} from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { ExactGlobeSaleDetailsCardX } from './exact-globe-sale-details.card';
import { Check } from '@material-ui/icons';

export const ExactGlobeSalesQueryContext = React.createContext<IQueryContext<IQueryResult<IExactGlobeSaleDto>, IExactGlobeSalesQueryParams>>(undefined as any);

const DefaultParams: IExactGlobeSalesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'date' },
	pageParams: { index: 1, size: 10 },
	prefilter: 'Unhandled',
};

export const ExactGlobeSalesQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IExactGlobeSaleDto>, IExactGlobeSalesQueryParams>
			defaultParams={DefaultParams}
			queryF={exactGlobeSales_query}
			concurrencyF={exactGlobeSales_concurrencyToken}
			localStorageKey={ExactGlobeSalesQueryParamsLsKey}
			context={ExactGlobeSalesQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};

interface IExactGlobeSalesTabProps extends IReloadTriggerProps {
	reload: VoidFunction;
}

export const ExactGlobeSalesTab = ({ reload, reloadTrigger }: IExactGlobeSalesTabProps) => {
	const strings = useLocalization();
	const { reload: reloadQuery, queryResult, params, setParams } = useQueryContext(ExactGlobeSalesQueryContext);
	const [selectedId, setSelectedId] = useState<string>();
	const expandedSale = useMemo(() => queryResult?.values.find(t => t.id === selectedId), [queryResult, selectedId]);
	const [download, isDownloading] = useDownload(exactGlobeSales_exportUnhandled);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadQuery();
	}, [reloadTrigger]);

	const onMarkAllAsHandled = () => {
		open(
			<AlertDialog
				open
				title={`Markeren als behandeld`}
				content={`Wil je alle 'geëxporteerde' markeren als behandeld?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactGlobeSales_markAllAsHandled({})}
				accept={confirm}
			/>
		);
	};

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<InnerPageXWithStickySidebar
			maxWidth={1280}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<ExactGlobeSalesListFilterCard
						params={params}
						setParams={setParams}
						style={{ minWidth: 220 }}
					/>
					<Divider />
					<TextLinkButton
						onClick={async () => {
							await download();
							reload();
						}}
						startIcon={<GetAppOutlinedIcon />}
						disabled={isDownloading}>
						{`Exporteer onbehandelde .xml`}
					</TextLinkButton>
					<TextLinkButton
						onClick={onMarkAllAsHandled}
						startIcon={<Check />}
						disabled={isDownloading}>
						{`Alles markeren als behandeld`}
					</TextLinkButton>
				</div>
			}>
			<div
				className='df-row gap-16 h100'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				<div className='df-col gap-16'>
					<SearchbarX
						placeholder={`${strings.searchOnReference}...`}
						value={params.searchString}
						onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
					/>
					<div
						className='df-col'
						style={{ gap: 8 }}>
						{queryResult.values.map(t => (
							<ExactGlobeSaleListItemCardX
								key={t.id}
								item={t}
								isSelected={selectedId === t.id}
								toggle={() => setSelectedId(selectedId === t.id ? undefined : t.id)}
							/>
						))}
						<QueryResultPaginationX
							queryResult={queryResult}
							setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
							style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
						/>
					</div>
				</div>
				{expandedSale !== undefined && expandedSale.id === selectedId && (
					<>
						<Divider orientation='vertical' />
						<ExactGlobeSaleDetailsCardX
							item={expandedSale}
							reload={reload}
							reloadTrigger={reloadTrigger}
							style={{ minWidth: 400 }}
						/>
					</>
				)}
			</div>
		</InnerPageXWithStickySidebar>
	);
};

const ExactGlobeSalePrefilterRecord: Record<ExactGlobeSalePrefilter, string> = {
	All: 'Alles',
	Exported: 'Geëxporteerd',
	Handled: 'Behandeld',
	Unhandled: 'Onbehandeld',
	HasError: 'Fouten',
};

interface IExactGlobeSalesListFilterCardProps extends CardProps {
	params: IExactGlobeSalesQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IExactGlobeSalesQueryParams>>;
}

export const ExactGlobeSalesListFilterCard = ({ params, setParams, style, ...rest }: IExactGlobeSalesListFilterCardProps) => {
	const strings = useLocalization();
	const onFilter = (pars: IExactGlobeSalesQueryParams) => setParams(onResetToPage1(pars));

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<div className='df-col'>
				{ExactGlobeSalePrefilters.map(prefilter => (
					<RadioIsSelectedButton
						key={prefilter}
						isSelected={prefilter === params.prefilter}
						onClick={() => onFilter({ ...params, prefilter: prefilter })}>
						{ExactGlobeSalePrefilterRecord[prefilter]}
					</RadioIsSelectedButton>
				))}
			</div>
		</CardX>
	);
};

export function exactGlobeSaleToColorX(item: IExactGlobeSaleDto) {
	if (item.status === 'Created') {
		return 'error';
	} else if (item.hasError) {
		return 'error';
	} else if (item.status === 'Exported') {
		return 'warning';
	} else {
		return 'success';
	}
}

const MajorFontSize = 16;

interface IExactGlobeSaleListItemCardXProps {
	item: IExactGlobeSaleDto;
	isSelected: boolean;
	toggle: VoidFunction;
	style?: React.CSSProperties;
}

export const ExactGlobeSaleListItemCardX = ({ item, isSelected, toggle, style }: IExactGlobeSaleListItemCardXProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const color = useMemo<ColorX>(() => exactGlobeSaleToColorX(item), [item]);
	const tColor = useThemeColor(color);
	const [hovered, enter, leave] = useStateBoolean(false);

	return (
		<CardX
			raised={isSelected || hovered}
			className='df-row w100'
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={toggle}
			style={{
				borderRight: isSelected ? `6px solid ${theme.palette.primary.main}` : 'unset',
				cursor: 'pointer',
				...style,
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<ExactGlobeSaleListItemStatusIcon
					item={item}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col gap-4 fg1'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.reference}</span>
				<ExactGlobeSaleListItemChipX
					item={item}
					color={color}
					style={{ marginRight: 'auto' }}
				/>
				<LabelledPropertyX label={strings.date}>{formatDate(item.date)}</LabelledPropertyX>
			</div>
		</CardX>
	);
};

interface IExactGlobeSaleListItemStatusIconProps {
	item: IExactGlobeSaleDto;
	style?: React.CSSProperties;
}

export const ExactGlobeSaleListItemStatusIcon = ({ item, style }: IExactGlobeSaleListItemStatusIconProps) => {
	if (item.status === 'Handled') {
		return <CheckCircleOutlineIcon style={style} />;
	} else if (item.status === 'Created' || item.hasError) {
		return <BlockIcon style={style} />;
	} else {
		return <GetAppOutlinedIcon style={style} />;
	}
};

interface IExactGlobeSaleListItemChipXProps {
	item: IExactGlobeSaleDto;
	color: ColorX;
	style?: React.CSSProperties;
}

export const ExactGlobeSaleListItemChipX = ({ item, color, style }: IExactGlobeSaleListItemChipXProps) => {
	return (
		<ChipX
			label={item.status === 'Handled' ? 'Behandeld' : item.hasError === true ? `Fouten` : item.status === 'Exported' ? 'Geëxporteerd' : 'Niet behandeld'}
			color={color}
			style={style}
		/>
	);
};
