import { INewLineModel } from 'shared/searchAndSelect/INewLineModel';
import { ILine } from '../ILine';

export const newLineToLine = (line: INewLineModel): ILine => {
	return {
		description: line.description,
		id: line.id,
		quantity: line.quantity,
		unitListPrice: line.unitListPrice,
		unitPrice: line.unitListPrice,
		vatPercentage: line.vatPercentage,
	};
};
