import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { entPhysiciansCommand_patchPersonalInfo, IEntPhysician, IPatchEntPhysicianPersonalInformationRequest } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormGenderSelectField } from 'shared/forms/FormGenderSelectField';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchEntPhysicianPersonalInformationRequest>({
		firstName: yup.string().required(strings.formRequired(strings.firstName)),
		lastName: yup.string().required(strings.formRequired(strings.lastName)),
		gender: yup.string().required(strings.formRequired(strings.gender)),
		salutation: yup.string(),
		entPhysicianId: yup.string().required(),
	});
};

const toModel = (entPhysician: IEntPhysician): IPatchEntPhysicianPersonalInformationRequest => {
	return {
		firstName: entPhysician.firstName,
		lastName: entPhysician.lastName,
		gender: entPhysician.gender as any,
		salutation: entPhysician.salutation,
		entPhysicianId: entPhysician.id,
	};
};

interface IProps extends DialogProps {
	entPhysician: IEntPhysician;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchEntPhysicianPersonalInformationForm = ({ entPhysician, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(entPhysiciansCommand_patchPersonalInfo);

	const handleSubmit = async (values: IPatchEntPhysicianPersonalInformationRequest, helpers: FormikHelpers<IPatchEntPhysicianPersonalInformationRequest>) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.entPhysicianUpdated);
			confirm();
		}
	};

	return (
		<Formik<IPatchEntPhysicianPersonalInformationRequest>
			validateOnMount
			initialValues={toModel(entPhysician)}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog {...rest}>
					<DialogTitle>{strings.changeDetailsEntPhysician}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<IPatchEntPhysicianPersonalInformationRequest>
							name='salutation'
							label={strings.salutation}
						/>
						<FormTextField<IPatchEntPhysicianPersonalInformationRequest>
							name='firstName'
							label={strings.firstName}
						/>
						<FormTextField<IPatchEntPhysicianPersonalInformationRequest>
							name='lastName'
							label={strings.lastName}
						/>
						<FormGenderSelectField<IPatchEntPhysicianPersonalInformationRequest>
							name='gender'
							label={strings.gender}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
