import { IStrings } from 'localization/IStrings';
import { QuickFilter } from '../PaymentsCard';

export const createQfRecord = (strings: IStrings): Record<QuickFilter, string> => {
	return {
		all: strings.all,
		grouped: strings.groupedPayments,
		single: strings.singlePayments,
	};
};
