import React, { useContext } from 'react';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { ISpeechImageDto, SpeechImageType } from 'gen/ApiClient';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	images: ISpeechImageDto[];
	maxWidth?: number;
	maxHeight?: number;
}

export const SpeechImagesList = ({ images, maxWidth = 400, maxHeight = 400 }: IProps) => {
	const { speechImageTypeRecord } = useContext(RecordContext);

	return (
		<div
			className='df-col-ac'
			style={{ width: '100%' }}>
			{images.map(t => (
				<>
					<DividerWithCaption
						caption={speechImageTypeRecord[t.type as SpeechImageType]}
						style={{ width: '100%', paddingLeft: 24, paddingRight: 24 }}
					/>
					<img
						src={`data:image/jpeg;base64,${t.data}`}
						alt={t.type ?? 'image'}
						style={{ maxWidth: `${maxWidth}px`, maxHeight: `${maxHeight}px` }}
					/>
				</>
			))}
		</div>
	);
};
