import ShopTwoOutlinedIcon from '@material-ui/icons/ShopTwoOutlined';
import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { OrdersRoute } from '../ProcessesRoutes';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { ProcessesPageBreadcrumbTitle } from '../ProcessesPageBreadcrumbTitle';

export const OrdersPageBreadcrumbs = () => {
	const strings = useLocalization();

	return (
		<div className='df-row-ac'>
			<ProcessesPageBreadcrumbTitle />
			<PageBreadcrumbDivider />
			<PageBreadcrumb
				text={strings.orders}
				route={OrdersRoute}
				icon={<ShopTwoOutlinedIcon />}
			/>
		</div>
	);
};
