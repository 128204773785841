//https://react-pdf-viewer.dev/examples/preview-a-pdf-file-from-base-64/
export const base64ToBlobUrl = (data: string): string => {
	// Cut the prefix `data:application/pdf;base64` from the raw base 64
	// const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
	const bytes = atob(data);
	let length = bytes.length;
	let out = new Uint8Array(length);

	while (length--) {
		out[length] = bytes.charCodeAt(length);
	}

	const blob = new Blob([out], { type: 'application/pdf' });
	return URL.createObjectURL(blob);
};
