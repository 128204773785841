import { Card, CardContent, IconButton, LinearProgress, useTheme } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import { HamsClientConfigurationDialog } from 'app/client/HamsClientConfigurationDialog';
import { HamsClientApplicationContext } from 'app/externalApps/client/HamsClientApplicationContext';
import { EidApplicationContext } from 'app/externalApps/eid/EidApplicationContext';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { useLocalization } from 'localization/useLocalization';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DownloadAndInstallClientSoftwareDialog } from 'shared/downloads/DownloadAndInstallClientSoftwareDialog';
import { DownloadAndInstallEidSoftwareDialog } from 'shared/downloads/DownloadAndInstallEidSoftwareDialog';

interface IProps {
	className?: string;
	style?: React.CSSProperties;
}

export const SoftwareCard = ({ className, style }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const {
		isInitialized: isEidInit,
		isInstalled: isEidInstalled,
		check: checkEid,
		hasLatest: hasLatestEid,
		allChecked: allCheckedEid,
	} = useContext(EidApplicationContext);
	const {
		isInitialized: isClientInit,
		isInstalled: isClientInstalled,
		canLaunch,
		check: checkClient,
		hasLatest: hasLatestClient,
		allChecked: allCheckedClient,
	} = useContext(HamsClientApplicationContext);
	const reload = useCallback(() => {
		checkEid();
		checkClient();
	}, [checkEid, checkClient]);
	const { open, confirm } = useDialogsContext(reload);
	const isLoading = useMemo(() => isEidInit === false || isClientInit === false, [isEidInit, isClientInit]);

	useEffect(() => {
		checkEid();
		checkClient();
		// eslint-disable-next-line
	}, []);

	const onTroubleshootClient = () => {
		open(
			<HamsClientConfigurationDialog
				open
				close={confirm}
			/>
		);
	};

	const onInstallEID = () => {
		open(
			<DownloadAndInstallEidSoftwareDialog
				open
				close={confirm}
			/>
		);
	};

	const onInstallClient = () => {
		open(
			<DownloadAndInstallClientSoftwareDialog
				open
				close={confirm}
			/>
		);
	};

	return (
		<Card
			className={className}
			style={style}>
			{isLoading && <LinearProgress />}
			<CardTitleTypography
				title={strings.software}
				withPadding
				action={
					<IconButton
						onClick={reload}
						edge='end'
						size='small'>
						<ReplayIcon />
					</IconButton>
				}
			/>
			<CardContent style={{ paddingTop: 0, paddingBottom: theme.spacing(2) }}>
				{isEidInit && isEidInstalled === false && (
					<WarningLabelledProperty
						warning={strings.whatSoftwareNotInstalled(`'eID'`)}
						onClick={onInstallEID}
					/>
				)}
				{isEidInit && isEidInstalled === true && hasLatestEid === false && (
					<WarningLabelledProperty
						warning={strings.thereIsAnUpdateForWhatSoftware(`'eID'`)}
						onClick={onInstallEID}
					/>
				)}
				{isClientInit && isClientInstalled === false && (
					<WarningLabelledProperty
						warning={strings.whatSoftwareNotInstalled(`'Noah/HAMS Client'`)}
						onClick={onTroubleshootClient}
					/>
				)}
				{isClientInit && isClientInstalled === true && canLaunch === false && (
					<ErrorLabelledProperty
						error={strings.faultStartNoahModules}
						onClick={onTroubleshootClient}
					/>
				)}
				{isClientInit && isClientInstalled === true && canLaunch === true && hasLatestClient === false && (
					<WarningLabelledProperty
						warning={strings.thereIsAnUpdateForWhatSoftware(`'Noah/HAMS Client'`)}
						onClick={onInstallClient}
					/>
				)}
				{allCheckedEid && allCheckedClient && <NoRecordsFound>{strings.noWhat(strings.notifications)}</NoRecordsFound>}
			</CardContent>
		</Card>
	);
};
