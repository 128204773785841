import { IRegisterArticlesInquiryLineRequest } from 'gen/ApiClient';
import { IArticlesInquiryLineModel } from './IArticlesInquiryLineModel';

export const mapArticlesInquiryLineModelToRequest = (line: IArticlesInquiryLineModel, zeroBasedIndex: number): IRegisterArticlesInquiryLineRequest => {
	return {
		description: line.description,
		orientation: line.orientation,
		productCatalogItemId: line.productCatalogItemId,
		quantity: line.quantity,
		articleProperties: line.articleProperties,
		zeroBasedIndex: zeroBasedIndex,
	};
};
