import { IOption } from 'framework/IOption';

export const HoursOptions: IOption<number>[] = [
	{ id: 0, identifier: '00:00' },
	{ id: 1, identifier: '01:00' },
	{ id: 2, identifier: '02:00' },
	{ id: 3, identifier: '03:00' },
	{ id: 4, identifier: '04:00' },
	{ id: 5, identifier: '05:00' },
	{ id: 6, identifier: '06:00' },
	{ id: 7, identifier: '07:00' },
	{ id: 8, identifier: '08:00' },
	{ id: 9, identifier: '09:00' },
	{ id: 10, identifier: '10:00' },
	{ id: 11, identifier: '11:00' },
	{ id: 12, identifier: '12:00' },
	{ id: 13, identifier: '13:00' },
	{ id: 14, identifier: '14:00' },
	{ id: 15, identifier: '15:00' },
	{ id: 16, identifier: '16:00' },
	{ id: 17, identifier: '17:00' },
	{ id: 18, identifier: '18:00' },
	{ id: 19, identifier: '19:00' },
	{ id: 20, identifier: '20:00' },
	{ id: 21, identifier: '21:00' },
	{ id: 22, identifier: '22:00' },
	{ id: 23, identifier: '23:00' },
	{ id: 24, identifier: '24:00' },
];
