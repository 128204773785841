import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { calculateTotalsFromAmountInVat } from './utils/calculateTotalsFromAmountInVat';

interface IProps<TLine extends { unitPrice: number; quantity: number }> {
	lines: TLine[];
	isShiftVat: boolean;
	style?: React.CSSProperties;
}

export const TotalsInVatTable = <TLine extends { unitPrice: number; quantity: number; vatPercentage: number }>({ lines, style, isShiftVat }: IProps<TLine>) => {
	const strings = useLocalization();
	const { totalExVat, totalInVat } = useMemo(() => calculateTotalsFromAmountInVat(lines, isShiftVat), [lines, isShiftVat]);

	return (
		<TableContainer style={{ width: 'auto', ...style }}>
			<Table size='small'>
				<TableBody>
					<TableRow>
						<TableCell>{strings.totalInVat}</TableCell>
						<TableCell style={{ textAlign: 'right' }}>{formatCurrency(totalInVat)}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{strings.totalExVat}</TableCell>
						<TableCell style={{ textAlign: 'right' }}>{formatCurrency(totalExVat)}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};
