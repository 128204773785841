import { LocalStateTextField } from 'framework/components/textFields/LocalStateTextField';
import { createHeader } from 'framework/table/createHeader';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { updateArray } from 'framework/utils/array/updateArray';
import { IMedicalTreatmentFeeModel } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { MedicalTreatmentNomenclatureChip } from '../../detail/components/MedicalTreatmentNomenclatureChip';

interface IProps {
	fees: IMedicalTreatmentFeeModel[];
	setFees: (fees: IMedicalTreatmentFeeModel[]) => void;
}

export const FeesModelTable = ({ fees, setFees }: IProps) => {
	const strings = useLocalization();

	const headers = [
		createHeader<IMedicalTreatmentFeeModel>(strings.code, t => <MedicalTreatmentNomenclatureChip code={t.data.code ?? ''} />),
		createHeader<IMedicalTreatmentFeeModel>(`${strings.description} ${strings.patient}`, t => (
			<LocalStateTextField
				className='w100'
				placeholder={`${strings.description} ${strings.patient}`}
				value={t.patientDescription}
				onChange={e => setFees(updateArray(t, fees, { ...t, patientDescription: e.target.value ?? strings.notApplicableAbbreviation }))}
			/>
		)),
		createHeader<IMedicalTreatmentFeeModel>(`${strings.description} ${strings.healthInsuranceFundShort}`, t => (
			<LocalStateTextField
				className='w100'
				placeholder={`${strings.description} ${strings.healthInsuranceFundShort}`}
				value={t.rizivDescription}
				onChange={e => setFees(updateArray(t, fees, { ...t, rizivDescription: e.target.value ?? strings.notApplicableAbbreviation }))}
			/>
		)),
	];

	if (fees === undefined) {
		return <div></div>;
	}

	return (
		<TableWithHeadersAndValues<IMedicalTreatmentFeeModel>
			values={fees.filter(t => t !== null && t !== undefined)}
			headers={headers}
			getKey={t => t.data.code ?? ''}
			withPaperContainer
			expandAll
			expandMultiple
		/>
	);
};
