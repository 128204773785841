import { IStockMovementLineModel } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const lineToModel = (line: ILine, index: number): IStockMovementLineModel => {
	return {
		description: line.description,
		productCatalogId: line.productCatalogItemId,
		inventoryItemId: line.inventoryItemId,
		quantity: line.quantity,
		zeroBasedIndex: index,
	};
};
