import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { LocalizationProvider as PickerLocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { languageKey } from 'keys';
import { ApiProvider } from 'framework/context/ApiProvider';
import { LoaderProvider } from 'framework/context/LoaderProvider';
import { LocalizationProvider } from 'framework/context/LocalizationProvider';
import { PdfViewerDialogProvider } from 'framework/pdf/context/PdfViewerDialogProvider';
import { ProtectedRoute } from 'framework/ProtectedRoute';
import { LanguageOption } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { localizationRecord } from 'localization/localizationRecord';
import { SpecificLocalizationContext } from 'localization/SpecificLocalizationContext';
import { ConfirmUserRoute, ForgotPasswordRoute, LoginRoute, LoginWithTokenRoute, ResetPasswordRoute } from 'routes';
import { theme } from '../theme';
import { AppUpdateWall } from './AppUpdateWall';
import { AppWrapper } from './AppWrapper';
import { AuthContext } from './auth/AuthContext';
import { AuthProvider } from './auth/AuthProvider';
import { ConfirmUser } from './auth/ConfirmUser';
import { SendResetPasswordLink } from './auth/SendResetPasswordLink';
import { Login } from './auth/Login';
import { LoginProvider } from './auth/LoginProvider';
import { ResetPassword } from './auth/ResetPassword';
import { Loader } from './loader/Loader';
import { SnackbarWrapper } from './SnackbarWrapper';
import { RecordProvider } from 'shared/records/RecordProvider';
import { LoginWithToken } from './auth/LoginWithToken';
import { LanguageOptionToLocaleRecord } from 'localization/LanguageOptionToLocaleRecord';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';

const App: React.FC = () => {
	return (
		<>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<SnackbarWrapper>
					<BrowserRouter>
						<LoaderProvider loader={<Loader />}>
							<PdfViewerDialogProvider>
								<LocalizationProvider<IStrings, LanguageOption>
									map={localizationRecord}
									context={SpecificLocalizationContext}
									localStorageKey={languageKey}
									fallbackValue='nl'>
									<SpecificLocalizationContext.Consumer>
										{({ strings, language }) => (
											<PickerLocalizationProvider
												dateAdapter={DateFnsAdapter}
												locale={LanguageOptionToLocaleRecord[language]}>
												<RecordProvider>
													<AuthProvider>
														<AuthContext.Consumer>
															{({ isAuth, onRedirect }) => (
																<ApiProvider
																	strings={strings}
																	onRedirect={onRedirect}>
																	<LoginProvider>
																		<AppUpdateWall>
																			<Switch>
																				<Route
																					exact
																					path={LoginRoute}
																					component={Login}
																				/>
																				<Route
																					exact
																					path={LoginWithTokenRoute}
																					component={LoginWithToken}
																				/>
																				<Route
																					exact
																					path={ConfirmUserRoute}
																					component={ConfirmUser}
																				/>
																				<Route
																					exact
																					path={ForgotPasswordRoute}
																					component={SendResetPasswordLink}
																				/>
																				<Route
																					exact
																					path={ResetPasswordRoute}
																					component={ResetPassword}
																				/>
																				<ProtectedRoute
																					isAuth={isAuth}
																					redirectRoute={LoginRoute}
																					component={AppWrapper}
																				/>
																				{/* normally won't hit this path */}
																				<Redirect
																					path='/'
																					to={LoginRoute}
																				/>
																			</Switch>
																		</AppUpdateWall>
																	</LoginProvider>
																</ApiProvider>
															)}
														</AuthContext.Consumer>
													</AuthProvider>
												</RecordProvider>
											</PickerLocalizationProvider>
										)}
									</SpecificLocalizationContext.Consumer>
								</LocalizationProvider>
							</PdfViewerDialogProvider>
						</LoaderProvider>
					</BrowserRouter>
				</SnackbarWrapper>
			</ThemeProvider>
		</>
	);
};

export default App;
