import { FilledInput } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { useStateBoolean } from '../hooks/useStateBool';
import { FormFilledControl } from './common/FormFilledControl';
import { IFormProps } from './common/IFormProps';
import { useFilledInputStyles } from './common/useFilledInputStyles';
import { ClearEndAdornment } from './common/ClearEndAdornment';
import { makeANumber } from '../utils/makeANumber';
import { nameOf } from '../utils/nameOf';

export const FormIntegerField = <TModel extends unknown>({ ...props }: IFormProps<TModel>) => {
	const [field, meta, helpers] = useField<number>(nameOf(props.name));
	const [isHovered, enter, leave] = useStateBoolean(false);
	const classes = useFilledInputStyles();

	return (
		<FormFilledControl
			meta={meta}
			enter={enter}
			leave={leave}
			{...props}>
			<FilledInput
				{...field}
				onChange={e => helpers.setValue(makeANumber(e.target.value))}
				className={classes.filledInput}
				type='text'
				inputProps={{ pattern: '[0-9]*' }}
				endAdornment={
					<ClearEndAdornment
						isHovered={isHovered}
						field={field}
						helpers={helpers}
						disabled={props.disabled ?? false}
						defaultValue={0}
					/>
				}
			/>
		</FormFilledControl>
	);
};
