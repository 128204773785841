import { Typography } from '@material-ui/core';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import React, { useMemo } from 'react';
import { ActionCardButton } from 'framework/components/buttons/ActionCardButton';
import { CashPlusIcon } from 'framework/components/icons/CashPlusIcon';
import { CustomerAccountType, ICustomerAccount, ICustomerAccountMetadata } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DefaultActionsCard } from 'shared/DefaultActionsCard';
import { RegisterPaymentForCustomerAccountForm } from '../../payments/forms/customerAccountLines/RegisterPaymentForCustomerAccountForm';

interface IProps {
	item: ICustomerAccount;
	metadata: ICustomerAccountMetadata;
	reload: VoidFunction;
	onSps: VoidFunction;
	style?: React.CSSProperties;
}

export const ActionsCard = ({ item, metadata, reload, style, onSps }: IProps) => {
	const isHif = useMemo(() => (item.accountType as CustomerAccountType) === 'HealthInsuranceFund', [item]);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const strings = useLocalization();

	const onPayment = () => {
		open(
			<RegisterPaymentForCustomerAccountForm
				customerAccountId={item.id}
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	return (
		<DefaultActionsCard style={style}>
			<ActionCardButton
				startIcon={<CashPlusIcon />}
				disabled={metadata.hasLinesWithOutstandingBalance === false}
				onClick={onPayment}>
				{strings.registerPayment}
			</ActionCardButton>
			<ActionCardButton
				startIcon={<ReceiptOutlinedIcon />}
				disabled={metadata.countCustomerAccountLinesWithOutstandingBalanceAndWithoutSettlementProposal === 0}
				onClick={onSps}>
				{strings.settlementProposal}
			</ActionCardButton>
			{isHif && (
				<Typography variant='caption'>
					{strings.settlementProposal} = {strings.verzamelstaat}
				</Typography>
			)}
		</DefaultActionsCard>
	);
};
