import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		isGlobal: `Globaal?`,
		locationIdentifier: strings.location,
		onDate: strings.date,
		priceExVat: strings.priceExVat,
		priceInVat: strings.priceInVat,
		priceInVatAt0: `${strings.priceInVat} @ 0% BTW`,
		priceInVatAt6: `${strings.priceInVat} @ 6% BTW`,
		priceInVatAt12: `${strings.priceInVat} @ 12% BTW`,
		priceInVatAt21: `${strings.priceInVat} @ 21% BTW`,
		vatAmount: strings.vatAmount,
	};
};
