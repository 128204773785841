import React from 'react';
import { IPrescriptionScan } from 'gen/ApiClient';
import { StatusFibers } from './StatusFibers';

interface IProps {
	scan: IPrescriptionScan;
	requiredStep: number;
}

export const ScanStatusFibers = ({ scan, requiredStep }: IProps) => {
	return (
		<StatusFibers
			step={scan.step}
			requiredStep={requiredStep}
			totalSteps={4}
		/>
	);
};
