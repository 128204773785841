import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IQueryResult, ITemplate, ITemplatesQueryParams, templatesQuery_concurrencyToken, templatesQuery_query } from 'gen/ApiClient';
import { TemplatesQueryContext } from './TemplatesQueryContext';
import { templatesQueryParamsLsKey } from '../../../localStorageKeys';

const DefaultParams: ITemplatesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	predefinedTypes: [],
	languages: [],
	mainContexts: [],
	alsoIncludeDeleted: false,
};

export const TemplatesQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<ITemplate>, ITemplatesQueryParams>
			defaultParams={DefaultParams}
			queryF={templatesQuery_query}
			concurrencyF={templatesQuery_concurrencyToken}
			localStorageKey={templatesQueryParamsLsKey}
			context={TemplatesQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
