import { IconButtonProps } from '@material-ui/core';
import React from 'react';
import { ViewBadgeIconButton } from 'framework/components/buttons/ViewBadgeIconButton';
import { useStateBoolean } from 'framework/hooks/useStateBool';

export const XViewBadgeIconButton = ({ style, ...props }: IconButtonProps) => {
	const [hover, enter, leave] = useStateBoolean(false);

	return (
		<ViewBadgeIconButton
			onMouseEnter={enter}
			onMouseLeave={leave}
			color={hover ? 'primary' : 'default'}
			style={{ opacity: hover ? 1 : 0.5, ...style }}
			{...props}
		/>
	);
};
