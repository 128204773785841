import { useTheme } from '@material-ui/core';
import { useMemo } from 'react';
import { ColorX, ColorXs } from './ColorX';
import { mapColorXToThemeColor } from './mapColorXToThemeColor';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';

export const useThemeColorOrHex = (color: ColorX | string) => {
	const theme = useTheme();
	return useMemo(
		() => (ColorXs.find(t => t === color) ? mapColorXToThemeColor(color as ColorX, theme) : isNullOrUndefined(color) ? '#ffffff' : color),
		[color, theme]
	);
};
