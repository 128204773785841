import { linearInterpolateSolveY } from 'framework/utils/math/linearInterpolateSolveY';
import { ISpeechAudiogram } from 'gen/ApiClient';

export const calculateImprovementAtSrtPoint = (aided: ISpeechAudiogram | undefined, unaided: ISpeechAudiogram | undefined) => {
	if (aided === undefined || unaided === undefined) {
		return 0;
	} else {
		const lip = linearInterpolateSolveY(unaided.srtIntensity, aided.calculatedPoints);
		return Math.round(lip - 50);
	}
};
