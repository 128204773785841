import { ListItemIcon, MenuItem, Typography, Divider } from '@material-ui/core';
import { FolderZipOutlineIcon } from 'framework/components/icons/FolderZipOutlineIcon';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useApiCall } from 'framework/hooks/useApiCall';
import {
	ExportFormat,
	IQueryResultWithMetadata,
	ISale,
	ISalesFilterParams,
	ISalesQueryMetadata,
	ISalesQueryParams,
	OriginalOrLatestDocumentExportMode,
	SaleExportProp,
	SaleExportProps,
	SalesLineExportProp,
	SalesLineExportProps,
	salesQuery_checkDocumentsForNonCashSales,
	salesQuery_export,
	salesQuery_exportLines,
	salesQuery_exportNonCashSalesToZip,
} from 'gen/ApiClient';
import { useDownloadWithLoader } from 'gen/useDownloadWithLoader';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TextLinkExportButtonMenuAndDialog } from 'shared/export/TextLinkExportButtonMenuAndDialog';
import { NonCashSalesExportWarningDialog } from './NonCashSalesExportWarningDialog';
import { SalesListViewContext } from './SalesListViewContext';
import { ExcelIcon } from 'framework/components/icons/ExcelIcon';
import { FileDelimitedOutlineIcon } from 'framework/components/icons/FileDelimitedOutlineIcon';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { FilterOutlineIcon } from 'framework/components/icons/FilterOutlineIcon';
import { ExportSelectPropsDialog } from 'shared/export/ExportSelectPropsDialog';

interface IProps {
	params: ISalesQueryParams;
	queryResult: IQueryResultWithMetadata<ISale, ISalesQueryMetadata>;
	style?: React.CSSProperties;
}

export const SalesExportButtonMenuAndDialogX = ({ params, queryResult, style }: IProps) => {
	const strings = useLocalization();
	const { exportRecord, linesExportRecord } = useContext(SalesListViewContext);
	const checkCall = useApiCall(salesQuery_checkDocumentsForNonCashSales);
	const exportToZip = useDownloadWithLoader(salesQuery_exportNonCashSalesToZip);
	const { open, confirm, cancel } = useDialogsContext();
	const anchor = useAnchorElement();

	const onCheckAndExportToZip = async () => {
		const r = await checkCall(params);
		if (r.isSuccess) {
			if (r.result.hasWarning) {
				open(
					<NonCashSalesExportWarningDialog
						open
						confirm={mode => {
							confirm();
							onExportToZip(mode);
						}}
						cancel={cancel}
						documentsResponse={r.result}
					/>
				);
			} else {
				await onExportToZip('OriginalDocument');
			}
		}
	};

	const onExportToZip = async (mode: OriginalOrLatestDocumentExportMode) => {
		await exportToZip(mode, params);
	};

	const onExportLines = async (applyFilter: boolean, format: ExportFormat) => {
		open(
			<ExportSelectPropsDialog<ISalesFilterParams, SalesLineExportProp>
				open
				fileName={`${strings.sales}-${strings.lines}`}
				close={cancel}
				params={params}
				downloadF={salesQuery_exportLines}
				record={linesExportRecord}
				allProps={SalesLineExportProps}
				title={`${strings.sales}-${strings.lines}`}
				format={format}
				mustApplyFilter={applyFilter}
				view='sales-lines'
				necessaryProps={[]}
			/>
		);
	};

	return (
		<TextLinkExportButtonMenuAndDialog<ISalesFilterParams, SaleExportProp>
			params={params}
			filteredCount={queryResult.filteredCount}
			unfilteredCount={queryResult.unfilteredCount}
			downloadF={salesQuery_export}
			fileName={strings.sales}
			record={exportRecord}
			allProps={SaleExportProps}
			view='sales'
			style={style}>
			<MenuItem
				onClick={() => {
					anchor[2]();
					onCheckAndExportToZip();
				}}>
				<ListItemIcon>
					<FilterOutlineIcon fontSize='small' />
					<FolderZipOutlineIcon fontSize='small' />
				</ListItemIcon>
				<Typography variant='inherit'>{strings.exportAllCountNonCashSalesToZipFile(queryResult.metadata.countFilteredNonCashSales)}</Typography>
			</MenuItem>
			<Divider />
			{queryResult.filteredCount !== queryResult.unfilteredCount && (
				<MenuItem
					onClick={() => {
						anchor[2]();
						onExportLines(true, 'Xlsx');
					}}>
					<ListItemIcon>
						<FilterOutlineIcon fontSize='small' />
						<ExcelIcon fontSize='small' />
					</ListItemIcon>
					<Typography variant='inherit'>{strings.exportLinesFilteredResultsInFormat('.xlsx')}</Typography>
				</MenuItem>
			)}
			<MenuItem
				onClick={() => {
					anchor[2]();
					onExportLines(false, 'Xlsx');
				}}>
				<ListItemIcon>
					<FilterOffOutlineIcon fontSize='small' />
					<ExcelIcon fontSize='small' />
				</ListItemIcon>
				<Typography variant='inherit'>{strings.exportLinesAllResultsInFormat('.xlsx')}</Typography>
			</MenuItem>
			<Divider />
			{queryResult.filteredCount !== queryResult.unfilteredCount && (
				<MenuItem
					onClick={() => {
						anchor[2]();
						onExportLines(true, 'Csv');
					}}>
					<ListItemIcon>
						<FilterOutlineIcon fontSize='small' />
						<FileDelimitedOutlineIcon fontSize='small' />
					</ListItemIcon>
					<Typography variant='inherit'>{strings.exportLinesFilteredResultsInFormat('.csv')}</Typography>
				</MenuItem>
			)}
			<MenuItem
				onClick={() => {
					anchor[2]();
					onExportLines(false, 'Csv');
				}}>
				<ListItemIcon>
					<FilterOffOutlineIcon fontSize='small' />
					<FileDelimitedOutlineIcon fontSize='small' />
				</ListItemIcon>
				<Typography variant='inherit'>{strings.exportLinesAllResultsInFormat('.csv')}</Typography>
			</MenuItem>
		</TextLinkExportButtonMenuAndDialog>
	);
};
