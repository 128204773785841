import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { toNumber } from 'lodash';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useLocalization } from 'localization/useLocalization';

export type SignedStepOption = 1 | 2 | 3 | 4;

interface IProps extends DialogProps {
	confirm: (step: SignedStepOption) => void;
	cancel: VoidFunction;
	previous: VoidFunction;
	suggestedStep?: SignedStepOption;
	isSubmitting: boolean;
}

export const SelectFilledInStepDialog = ({ confirm, cancel, suggestedStep = 1, isSubmitting, previous, ...rest }: IProps) => {
	const [signedStep, setSignedStep] = useState<SignedStepOption>(suggestedStep);
	const strings = useLocalization();

	return (
		<Dialog
			{...rest}
			scroll='paper'>
			<DialogTitle>{strings.uploadWhat(strings.prescriptionAttachment17)}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<ExtendedRadioGroup<SignedStepOption>
					selected={signedStep}
					setSelected={val => setSignedStep(toNumber(val) as SignedStepOption)}
					options={[
						{ value: 1, label: strings.filledInWhat(`${strings.step} 1`), caption: strings.prescriptionStep1Caption },
						{ value: 2, label: strings.filledInWhat(`${strings.step} 2`), caption: strings.prescriptionStep2Caption },
						{ value: 3, label: strings.filledInWhat(`${strings.step} 3`), caption: strings.prescriptionStep3Caption },
						{ value: 4, label: strings.filledInWhat(`${strings.step} 4`), caption: strings.prescriptionStep4Caption },
					]}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				onSubmit={() => confirm(signedStep)}
				cancel={cancel}
				previous={previous}
				submitText={strings.upload}
				isSubmitting={isSubmitting}
			/>
		</Dialog>
	);
};
