import { DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { FullScreenDialog } from 'framework/dialogs/FullScreenDialog';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { articlesInquiriesCommand_addLines, IAddArticlesInquiryLinesRequest, IArticlesInquiryDto, IObjectWithIdAndIdentifier } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import * as yup from 'yup';
import { IArticlesInquiryLineModel } from '../register/IArticlesInquiryLineModel';
import { mapArticlesInquiryLineModelToRequest } from '../register/mapArticlesInquiryLineModelToRequest';
import { SearchAndSelectProductsForArticlesInquiry } from '../register/SearchAndSelectProductsForArticlesInquiry';
import { SearchProductCatalogItemsForArticlesInquiryProvider } from '../register/SearchProductCatalogItemsForArticlesInquiryProvider';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IAddArticlesInquiryLinesRequest>({
			lines: yup.mixed(),
			articlesInquiryId: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	previous?: VoidFunction;
	item: IArticlesInquiryDto;
}

export const AddArticlesInquiryLinesRequestForm = ({ confirm, cancel, previous, item, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, setLines] = useState<IArticlesInquiryLineModel[]>([]);
	const [patch, isSubmitting] = useFormSubmit(articlesInquiriesCommand_addLines);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IAddArticlesInquiryLinesRequest, helpers: FormikHelpers<IAddArticlesInquiryLinesRequest>) => {
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await patch({ ...values, lines: lines.map((t, index) => mapArticlesInquiryLineModelToRequest(t, index)) });
			if (handleFormResponse(r, helpers)) {
				confirm();
			}
		}
	};

	return (
		<Formik<IAddArticlesInquiryLinesRequest>
			validateOnMount
			initialValues={{
				articlesInquiryId: item.id,
				lines: [],
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProductCatalogItemsForArticlesInquiryProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						lines={lines}
						setLines={setLines}
						patient={item.patient}
						previous={previous}
					/>
				</SearchProductCatalogItemsForArticlesInquiryProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	lines: IArticlesInquiryLineModel[];
	setLines: React.Dispatch<React.SetStateAction<IArticlesInquiryLineModel[]>>;
	isSubmitting: boolean;
	patient: IObjectWithIdAndIdentifier<string>;
	previous: VoidFunction | undefined;
}

const InnerDialog = ({ cancel, lines, setLines, isSubmitting, patient, previous, ...rest }: IInnerProps) => {
	const strings = useLocalization();

	return (
		<FullScreenDialog {...rest}>
			<DialogTitle>{strings.newArticlesInquiryForWho(patient.identifier)}</DialogTitle>
			<DialogContent
				dividers
				className='df-col fg1'>
				<SearchAndSelectProductsForArticlesInquiry
					lines={lines}
					setLines={setLines}
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions<IAddArticlesInquiryLinesRequest>
				cancel={cancel}
				submitText={strings.addLines}
				isSubmitting={isSubmitting}
				previous={previous}
			/>
		</FullScreenDialog>
	);
};
