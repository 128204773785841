import { DialogProps } from '@material-ui/core';
import React from 'react';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { repairsCommand_linkWithPatient } from 'gen/ApiClient';
import { SelectPatientByIdForm } from 'shared/patientSearch/SelectPatientByIdForm';

interface IProps extends DialogProps {
	repairId: string;
	cancel: VoidFunction;
	confirm: VoidFunction;
}

export const LinkRepairWithPatientForm = ({ repairId, confirm, ...rest }: IProps) => {
	const [link, isSubmitting] = useFormSubmit(repairsCommand_linkWithPatient);

	const onConfirm = async (patientId: string) => {
		const r = await link({ repairId: repairId, patientId: patientId });
		if (r.isSuccess) {
			confirm();
		}
	};

	return (
		<SelectPatientByIdForm
			{...rest}
			confirm={onConfirm}
			isSubmitting={isSubmitting}
		/>
	);
};
