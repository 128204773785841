import { Button, Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import { EventListComponent } from 'app/main/calendar/agenda/list/EventListComponent';
import { CreateDefaultCalendarEventForm } from 'app/main/calendar/forms/CreateDefaultCalendarEventForm';
import { endOfYear, startOfYear } from 'date-fns';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { CalendarPlusIcon } from 'framework/components/icons/CalendarPlusIcon';
import { Searchbar } from 'framework/components/search/Searchbar';
import { BrowsableDateFilterItem } from 'framework/filter/date/BrowsableDateFilterItem';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { combineClassNames } from 'framework/utils/combineClassNames';
import { ICalendarEventsByPatientQueryParams, agendaQuery_queryByPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { getNow } from 'shared/utils/getNow';
import { HamsPatientEventsFilterBar } from './HamsPatientEventsFilterBar';
import { IPatientEventsCardProps } from './IPatientEventsCardProps';

export const DefaultParams: ICalendarEventsByPatientQueryParams = {
	searchString: '',
	afterDate: startOfYear(getNow()),
	beforeDate: endOfYear(getNow()),
	eventTypeIds: [],
	patientId: undefined as any,
	roomGroupKeys: [],
	userGroupKeys: [],
};

export const HamsPatientEventsCard = ({ patient, reloadTrigger, reload, ...rest }: IPatientEventsCardProps) => {
	const strings = useLocalization();
	const [params, setParams, events, reloadEvents] = useApiEffectWithParams(
		agendaQuery_queryByPatient,
		{ ...DefaultParams, patientId: patient.id },
		reloadTrigger
	);
	const theme = useTheme();
	const { open, confirm, cancel } = useDialogsContext(reload);

	useEffect(() => {
		reloadEvents();
		// eslint-disable-next-line
	}, [reloadTrigger]);

	useEffect(() => {
		if (params.patientId !== patient.id) {
			setParams({ ...params, patientId: patient.id });
		}
		// eslint-disable-next-line
	}, [patient]);

	if (events === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<CreateDefaultCalendarEventForm
				open
				confirm={confirm}
				cancel={cancel}
				patient={{ id: patient.id, identifier: patient.identifier }}
			/>
		);
	};

	return (
		<Card
			{...rest}
			className={combineClassNames('fg1', rest.className)}>
			<div
				className='df-row-ac jc-sb'
				style={{ paddingRight: theme.spacing(1.5) }}>
				<CardTitleTypography
					withPadding
					title={strings.appointments}
				/>
				<Button
					variant='contained'
					color='primary'
					startIcon={<CalendarPlusIcon />}
					onClick={onNew}>
					{strings.newWhat(strings.appointment)}
				</Button>
			</div>
			<Divider />
			<div className='df-row-ac jc-sb'>
				<Searchbar
					value={params.searchString}
					onSearch={text => setParams({ ...params, searchString: text })}
					placeholder={strings.searchVerb}
					style={{ width: '400px' }}
					variant='embedded'
				/>
			</div>
			<Divider />
			<div className='df-row jc-sb'>
				<HamsPatientEventsFilterBar
					params={params}
					onFilter={setParams}
					paddingLeft={theme.spacing(1.5)}
				/>
			</div>
			<BrowsableDateFilterItem
				dateRangeType='year'
				after={params.afterDate}
				before={params.beforeDate}
				setSelected={(after, before) => setParams({ ...params, afterDate: after, beforeDate: before })}
				style={{ padding: 10 }}
			/>
			<Divider />
			{events.length > 0 && (
				<CardMedia style={{ paddingLeft: 12, paddingRight: 12 }}>
					<EventListComponent
						afterDate={params.afterDate}
						beforeDate={params.beforeDate}
						events={events}
						reload={reload}
						colorScheme='types'
					/>
				</CardMedia>
			)}
			{events.length === 0 && <NoRecordsFound style={{ padding: theme.spacing(1.5) }}>{strings.noResultsCommaChangeFilterParams}</NoRecordsFound>}
		</Card>
	);
};
