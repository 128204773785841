import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { IRepair, IRepairsQueryMetadata, RepairExportProp, RepairPrefilter, RepairProcessStatus } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import React from 'react';

export const createRepairPrefilterBadgeChipXRecord = (metadata: IRepairsQueryMetadata): Record<RepairPrefilter, React.ReactNode> => {
	return {
		All: undefined,
		NotSentToSupplier: metadata.countNotSentToSupplier > 0 && (
			<BadgeChipX
				value={metadata.countNotSentToSupplier}
				color='warning'
			/>
		),
		InRepair: metadata.countInRepair > 0 && (
			<BadgeChipX
				value={metadata.countInRepair}
				color='primary'
			/>
		),
		ToHandoverToPatient: metadata.countToHandoverToPatient > 0 && (
			<BadgeChipX
				value={metadata.countToHandoverToPatient}
				color='primary'
			/>
		),
		WithWarning: metadata.countWithWarning > 0 && (
			<BadgeChipX
				value={metadata.countWithWarning}
				color='warning'
			/>
		),
	};
};

export const createRepairExportRecord = (strings: IStrings): Record<RepairExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		CountItems: `# ${strings.items}`,
		ExternalReference: strings.externalReference,
		ForPatientIdentifier: strings.patient,
		Reference: strings.reference,
		Status: strings.status,
		SupplierIdentifier: strings.supplier,
		IsCancelled: strings.cancelled,
		IsCompleted: strings.completed,
	};
};

export const mapRepairToColorX = (item: IRepair): ColorX => {
	return RepairProcessStatusToColorXRecord[item.status as RepairProcessStatus];
};

export const RepairProcessStatusToColorXRecord: Record<RepairProcessStatus, ColorX> = {
	CancelledAfterSentToSupplier: 'error',
	CancelledBeforeSentToSupplier: 'error',
	DeliveredToPatient: 'success',
	PartialRedelivered: 'warning',
	Redelivered: 'success',
	Registered: 'default',
	SentToSupplier: 'default',
};
