import {
	calcDeliveryDetailRoute,
	calcInventoryItemDetailRoute,
	calcProductCatalogDetailRoute,
	calcReturnDetailRoute,
	calcStockMovementDetailRoute,
} from 'app/main/inventoryManagement/routes';
import {
	calcLoanDetailRoute,
	calcOrderDetailRoute,
	calcRepairDetailRoute,
	calcRequestForInsuranceAllowanceDetailRoute,
	calculateArticlesInquiryDetailRoute,
} from 'app/main/processes/ProcessesRoutes';
import { LinkViewContextType } from 'gen/ApiClient';
import { calcPurchaseDetailRoute, calcSalesDetailRoute, calcTenderDetailRoute } from 'app/main/financial/routes';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';

export const LinkViewContextNavigationRecord: Record<LinkViewContextType, ((id: string) => string) | undefined> = {
	Cosi: calcRequestForInsuranceAllowanceDetailRoute,
	Delivery: calcDeliveryDetailRoute,
	InventoryItem: calcInventoryItemDetailRoute,
	Loan: calcLoanDetailRoute,
	Order: calcOrderDetailRoute,
	Patient: calcPatientDetailRoute,
	ProductCatalogItem: calcProductCatalogDetailRoute,
	Purchase: calcPurchaseDetailRoute,
	Repair: calcRepairDetailRoute,
	Return: calcReturnDetailRoute,
	Rfia: calcRequestForInsuranceAllowanceDetailRoute,
	RfiaHandover: calcRequestForInsuranceAllowanceDetailRoute,
	Sale: calcSalesDetailRoute,
	SettlementProposal: undefined,
	StockMovement: calcStockMovementDetailRoute,
	Tender: calcTenderDetailRoute,
	TrialReport: undefined,
	ArticlesInquiry: calculateArticlesInquiryDetailRoute,
};
