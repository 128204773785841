import React, { useState } from 'react';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IPatientDetail } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { PatientMissingPropertiesForm } from './PatientMissingPropertiesForm';

interface IProps {
	data: IPatientDetail;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatientMissingPropertiesWithAlertDialog = ({ data, cancel, confirm }: IProps) => {
	const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
	const strings = useLocalization();

	if (isConfirmed === false) {
		return (
			<AlertDialog
				open
				title={strings.fillInMissingDataTitle}
				content={strings.notAllFieldsAreFilledInForThisPatient}
				acceptText={strings.yesFillInNow}
				rejectText={strings.noFillInLater}
				accept={() => setIsConfirmed(true)}
				reject={cancel}
			/>
		);
	} else {
		return (
			<PatientMissingPropertiesForm
				open
				confirm={confirm}
				cancel={cancel}
				item={data.patient}
				missingProperties={data.missingProperties}
			/>
		);
	}
};
