import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { SelectField } from 'framework/components/select/SelectField';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { ICustomerAccountLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';

interface IProps extends DialogProps {
	lines: ICustomerAccountLine[];
	confirm: (line: ICustomerAccountLine) => void;
	cancel: VoidFunction;
}

export const SelectCustomerAccountLineDialog = ({ lines, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const [line, setLine] = useState<ICustomerAccountLine>();

	return (
		<Dialog
			{...rest}
			fullWidth>
			<DialogTitle>
				<div>{strings.selectWhat(strings.accountReceivable)}</div>
			</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<SelectField<ICustomerAccountLine>
					getKey={t => t.id}
					renderValue={t => t.customerAccountIdentifier}
					options={lines}
					value={line}
					onChange={setLine}
					label={strings.accountReceivable}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				submitText={strings.select}
				cancel={cancel}
				onSubmit={() => confirm(line!)}
				isSubmitting={false}
				isValid={line !== undefined}
			/>
		</Dialog>
	);
};
