import { StepContent, StepContentProps } from '@material-ui/core';
import PostAddIcon from '@material-ui/icons/PostAdd';
import React from 'react';
import { EmailReceiveOutlineIcon } from 'framework/components/icons/EmailReceiveOutlineIcon';
import { EmailSendOutlineIcon } from 'framework/components/icons/EmailSendOutlineIcon';
import { IMedicalPrescriptionStep, IRequestForInsuranceAllowance, rfiaDocumentsCommand_createLetterToEnt } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SelectTemplateFromPredefinedTypeForm } from 'documents/SelectTemplateFromPredefinedTypeForm';
import { ProcessButton } from 'shared/process/ProcessButton';
import { ProcessButtonDivider } from 'shared/process/ProcessButtonDivider';
import { ReceiveMedicalPrescriptionForm } from '../../forms/ReceiveMedicalPrescriptionForm';
import { RegisterSendDocumentsToEntPhysicianForm } from '../../forms/RegisterSendDocumentsToEntPhysicianForm';

interface IProps extends StepContentProps {
	rfia: IRequestForInsuranceAllowance;
	step: IMedicalPrescriptionStep;
	style?: React.CSSProperties;
	reload: VoidFunction;
}

export const MedicalPrescriptionStepContent = ({ rfia, step, style, reload, ...rest }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);
	const strings = useLocalization();

	const onCreateLetter = () => {
		open(
			<SelectTemplateFromPredefinedTypeForm
				open
				confirm={confirm}
				cancel={cancel}
				submitF={templateId => rfiaDocumentsCommand_createLetterToEnt(rfia.id, templateId)}
				type='RfiaEntLetter'
			/>
		);
	};

	const onSendLetter = () => {
		open(
			<RegisterSendDocumentsToEntPhysicianForm
				open
				confirm={confirm}
				cancel={cancel}
				rfiaId={rfia.id}
			/>
		);
	};

	const onReceive = () => {
		open(
			<ReceiveMedicalPrescriptionForm
				rfiaId={rfia.id}
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<StepContent {...rest}>
			<div className='df-row-ac'>
				<ProcessButton
					disabled={step.canCreateLetter === false}
					onClick={onCreateLetter}
					startIcon={<PostAddIcon />}
					text={strings.letter}
				/>
				<ProcessButtonDivider />
				<ProcessButton
					disabled={step.hasSent}
					onClick={onSendLetter}
					startIcon={<EmailSendOutlineIcon />}
					text={strings.send}
				/>
				<ProcessButtonDivider />
				<ProcessButton
					disabled={step.hasReceived}
					onClick={onReceive}
					startIcon={<EmailReceiveOutlineIcon />}
					text={strings.receive}
				/>
			</div>
		</StepContent>
	);
};
