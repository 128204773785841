import React from 'react';
import SwipeableViews from 'react-swipeable-views';

interface IProps {
	tab: number;
	setTab: (tab: number) => void;
	children: any;
}

export const SwipeableViewsX = ({ tab, setTab, children }: IProps) => {
	return (
		<SwipeableViews
			index={tab}
			onChangeIndex={(index: number) => setTab(index)}
			style={{ flexGrow: 1 }}
			containerStyle={{ height: '100%' }}>
			{children}
		</SwipeableViews>
	);
};
