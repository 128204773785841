import { useField, useFormikContext } from 'formik';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { nameOf } from 'framework/utils/nameOf';
import React, { useEffect } from 'react';
import { EntPhysiciansCacheContext } from '../EntPhysiciansCacheContext';
import { FormSelectOrCreateEntPhysicianField } from './FormSelectOrCreateEntPhysicianField';
import { FormSelectOrCreateEntPhysicianOfficeField } from './FormSelectOrCreateEntPhysicianOfficeField';

interface IProps<TModel> {
	nameEnt: keyof TModel;
	nameOffice: keyof TModel;
	labelEnt: string;
	labelOffice: string;
	disabled?: boolean;
	requiredEnt?: boolean;
	requiredOffice?: boolean;
	initialEntId?: string;
	initialOfficeId?: string;
}

export const FormSelectOrCreateEntPhysicianWithOfficeField = <TModel extends unknown>({
	nameEnt,
	labelEnt,
	requiredEnt,
	nameOffice,
	labelOffice,
	requiredOffice,
	initialEntId,
	initialOfficeId,
	...rest
}: IProps<TModel>) => {
	const [entField] = useField<string>(nameOf(nameEnt));
	const props = useFormikContext<TModel>();
	const [data] = usePreloadCacheContext(EntPhysiciansCacheContext);

	useEffect(() => {
		if (data === undefined) {
			return;
		}
		var x = data.find(t => t.id === entField.value);
		if (x === undefined || x === null) {
			return;
		}
		var officeId = entField.value === initialEntId && initialOfficeId !== undefined && initialOfficeId !== '' ? initialOfficeId : x.defaultOffice?.id;
		setFormValue<TModel>(nameOffice, officeId, props);
		// eslint-disable-next-line
	}, [initialEntId, initialOfficeId, entField.value, data]);

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<>
			<FormSelectOrCreateEntPhysicianField<TModel>
				{...rest}
				name={nameEnt}
				label={labelEnt}
				required={requiredEnt}
			/>
			<FormSelectOrCreateEntPhysicianOfficeField<TModel>
				{...rest}
				entPhysicianId={entField.value}
				name={nameOffice}
				label={labelOffice}
				required={requiredOffice}
			/>
		</>
	);
};
