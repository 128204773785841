import { DialogProps, Divider } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormAutocompleteFreeSoloTextField } from 'framework/forms/FormAutocompleteFreeSoloTextField';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { FormIntegerFieldWithUnit } from 'framework/forms/FormIntegerFieldWithUnit';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	InsuranceOrProlongedWarrantyTimeUnit,
	InsuranceOrProlongedWarrantyTimeUnits,
	IProductCatalogSearchData,
	IProlongedWarrantyProductCatalogItemModel,
	productCatalogCommand_newProlongedWarranty,
	TimeUnit,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { FormSelectVatPercentageField } from 'shared/forms/FormSelectVatPercentageField';
import { useRenderTimeUnit } from 'shared/timeUnit/useRenderTimeUnit';
import * as yup from 'yup';
import { ProductCatalogFilterDataContext } from '../context/ProductCatalogFilterDataContext';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IProlongedWarrantyProductCatalogItemModel>({
			name: yup.string().required(strings.formRequired(strings.name)),
			listPrice: yup.number().required('Aanbevolen verkoopsprijs'), //TODO
			vatPercentage: yup.number().required(strings.formRequired(strings.vatPercentage)).defined(),
			category: yup.string(),
			subcategory: yup.string(),
			isUnlimitedTerm: yup.boolean(),
			termUnit: yup.string().required(),
			termValue: yup.number().required(),
			canAlsoPurchase: yup.boolean(),
		})
		.defined();
};

const emptyValues: IProlongedWarrantyProductCatalogItemModel = {
	name: '',
	category: '',
	subcategory: '',
	listPrice: undefined as any,
	vatPercentage: 21,
	isUnlimitedTerm: false,
	termUnit: 'Years' as InsuranceOrProlongedWarrantyTimeUnit,
	termValue: 3,
	canAlsoPurchase: false,
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	previous?: VoidFunction;
}

const stepsRecord: Record<number, (keyof IProlongedWarrantyProductCatalogItemModel)[]> = {
	0: ['name', 'isUnlimitedTerm', 'termUnit', 'termValue'],
	1: ['listPrice', 'vatPercentage'],
	2: ['category', 'subcategory'],
};

export const ProlongedWarrantyForm = ({ confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [data] = useCacheContext(ProductCatalogFilterDataContext);
	const [create, isSubmitting] = useFormSubmit(productCatalogCommand_newProlongedWarranty);
	const [step, setStep] = useState<number>(0);

	const handleSubmit = async (values: IProlongedWarrantyProductCatalogItemModel, helpers: FormikHelpers<IProlongedWarrantyProductCatalogItemModel>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.productCreated);
			confirm(r.result.objectId);
		}
	};

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IProlongedWarrantyProductCatalogItemModel>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					isSubmitting={isSubmitting}
					step={step}
					setStep={setStep}
					data={data}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: (val: number) => void;
	data: IProductCatalogSearchData;
	cancel: VoidFunction;
	isSubmitting: boolean;
	previous?: VoidFunction;
}

const InnerDialog = ({ step, setStep, data, cancel, isSubmitting, previous, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const props = useFormikContext<IProlongedWarrantyProductCatalogItemModel>();
	const renderTimeUnit = useRenderTimeUnit();

	useEffect(() => {
		if (props.values.isUnlimitedTerm) {
			setFormValue<IProlongedWarrantyProductCatalogItemModel>('termUnit', 'Years' as InsuranceOrProlongedWarrantyTimeUnit, props);
			setFormValue<IProlongedWarrantyProductCatalogItemModel>('termValue', 3, props);
		}
		// eslint-disable-next-line
	}, [props.values.isUnlimitedTerm]);

	return (
		<OverflowDialog {...rest}>
			<DialogTitleWithFormStepper
				title={strings.newWhat(strings.prolongedWarranty)}
				step={step}
				labels={[strings.name, strings.price, strings.category]}
			/>
			<OverflowDialogContent>
				{step === 0 && (
					<>
						<FormTextField<IProlongedWarrantyProductCatalogItemModel>
							name='name'
							label={strings.name}
							required
						/>
						<FormSingleCheckboxField<IProlongedWarrantyProductCatalogItemModel>
							name='isUnlimitedTerm'
							label={`${strings.withoutTerm} (${strings.unlimited})`}
						/>
						{props.values.isUnlimitedTerm === false && (
							<FormIntegerFieldWithUnit<IProlongedWarrantyProductCatalogItemModel, TimeUnit>
								name='termValue'
								unitName='termUnit'
								units={InsuranceOrProlongedWarrantyTimeUnits}
								label={strings.term}
								defaultUnit='Years'
								renderUnit={(val, unit) => renderTimeUnit(val, unit)}
							/>
						)}
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectVatPercentageField<IProlongedWarrantyProductCatalogItemModel>
							name='vatPercentage'
							label={strings.vatPercentage}
							required
						/>
						<FormMoneyField<IProlongedWarrantyProductCatalogItemModel>
							name='listPrice'
							label={strings.salesPrice}
						/>
						<Divider style={{ marginTop: 16, marginBottom: 16 }} />
						<FormExtendedRadioGroupField<IProlongedWarrantyProductCatalogItemModel, boolean>
							name='canAlsoPurchase'
							label='x'
							options={[
								{ value: true, label: strings.isAlsoPurchased, caption: strings.thisWhatWillAlsoBePurchasedFromASupplier(strings.prolongedWarranty) },
								{ value: false, label: strings.willNotBePurchased, caption: strings.thisWhatWillNotBePurchasedFromASupplier(strings.prolongedWarranty) },
							]}
						/>
					</>
				)}
				{step === 2 && (
					<>
						<FormAutocompleteFreeSoloTextField<IProlongedWarrantyProductCatalogItemModel>
							name='category'
							label={strings.category}
							options={data.categories}
						/>
						<FormAutocompleteFreeSoloTextField<IProlongedWarrantyProductCatalogItemModel>
							name='subcategory'
							label={strings.subcategory}
							options={data.subcategories}
						/>
					</>
				)}
			</OverflowDialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
				previous={previous}
			/>
		</OverflowDialog>
	);
};
