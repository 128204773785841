import { enApiErrorStrings } from 'framework/localization/apiErrors/enApiErrorStrings';
import { IFrameworkStrings } from './IFrameworkStrings';

export const enFrameworkStrings: IFrameworkStrings = {
	...enApiErrorStrings,
	actions: `Actions`,
	addWhat: (what: string | number) => `Add ${what.toString().toLowerCase()}`,
	allAfter: `All after`,
	allBefore: `All before`,
	amount: `Amount`,
	busyUploadingDocument: `Busy uploading document`,
	cancel: `Cancel`,
	changeColumns: `Change columns`,
	changeSettings: `Change settings`,
	clearFilter: `Clear filter`,
	close: `Close`,
	createNew: `Create new`,
	day: `Day`,
	dayOrDays: `Day(s)`,
	days: `Days`,
	delete: `Delete`,
	details: `Details`,
	dropDocumentHere: `Drop document here`,
	exportAllResultsCountInXlsxFormat: (count: string | number, format: string | number) =>
		`Export all results (${count.toString().toLowerCase()}) in ${format.toString().toLowerCase()} format`,
	exportFilteredResultsCountInFormat: (count: string | number, format: string | number) =>
		`Export ${count.toString().toLowerCase()} filtered results in ${format.toString().toLowerCase()} format`,
	exportVerb: `Export`,
	filter: `Filter`,
	formRequired: (field: string | number) => `${field} is a required field`,
	from: `From`,
	hour: `Hour`,
	hourOrHours: `Hour(s)`,
	hours: `Hours`,
	invalidDateFormat: `Invalid date format`,
	lastMonth: `Last month`,
	lastQuarter: `Last quarter`,
	lastWeek: `Last week`,
	lastYear: `Last year`,
	minute: `Minute`,
	minuteOrMinutes: `Minute(s)`,
	minutes: `Minutes`,
	month: `Month`,
	monthOrMonths: `Month(s)`,
	months: `Months`,
	moreInformation: `More information`,
	new: `New`,
	next: `Next`,
	noAccessToThisFunction: `You have insufficient rights to execute this function`,
	none: `None`,
	notApplicableAbbreviation: `n/a`,
	previous: `Previous`,
	releaseLeftMouseButtonToUploadFile: `Release left mouse button to upload file`,
	reload: `Reload`,
	restrictions: `Restrictions`,
	searchVerb: `Search`,
	second: `Second`,
	secondOrSeconds: `Second(s)`,
	seconds: `Seconds`,
	select: `Select`,
	selectAll: `Select all`,
	selectAllBelow: `Select all below`,
	selectColumns: `Select columns`,
	selectColumnsToShowBelow: `Select showable columns below`,
	selectFromComputer: `Select from computer`,
	selectMinMax: `Select min/max`,
	selectNone: `Select none`,
	selectPriceRange: `Select a price range between min/max`,
	showLess: `Show less`,
	start: `Start`,
	thisFileExtensionIsNotSupported: `This file extension is not supported`,
	thisMonth: `This month`,
	thisQuarter: `This quarter`,
	thisWeek: `This week`,
	thisYear: `This year`,
	to: `To`,
	today: `Today`,
	unit: `Unit`,
	update: `Update`,
	uploadADocument: `Upload a document`,
	viewMore: `View more`,
	week: `Week`,
	weekOrWeeks: `Week(s)`,
	weeks: `Weeks`,
	year: `Year`,
	yearOrYears: `Year(s)`,
	years: `Years`,
	yesterday: `Yesterday`,
	youCanAlsoDragAFileToHere: `You can also drag a file here`,
};
