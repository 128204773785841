import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { useLocalization } from 'localization/useLocalization';
import { HandoverMode } from './HandoverMode';

interface IProps extends DialogProps {
	confirm: (mode: HandoverMode) => void;
	cancel: VoidFunction;
}

export const HandoverModeForm = ({ confirm, cancel, ...rest }: IProps) => {
	const [mode, setMode] = useState<HandoverMode>('single'); // TODO should come from settings
	const strings = useLocalization();

	return (
		<Dialog
			{...rest}
			maxWidth='md'
			scroll='paper'>
			<DialogTitle>{`${strings.handover}: ${strings.makeAChoice}`}</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<HandoverMode>
					selected={mode}
					setSelected={setMode}
					options={[
						{ value: 'single', label: strings.registerOneSale, caption: strings.registerOneSaleCaption },
						{ value: 'splits', label: strings.registerMultipleSales, caption: strings.registerMultipleSalesCaption },
					]}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button
					onClick={() => confirm(mode)}
					color='primary'>
					{strings.confirm}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
