import { DialogProps } from '@material-ui/core';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { customerAccountLinesQuery_queryForSale, ICustomerAccountLine, ISale } from 'gen/ApiClient';
import React, { useEffect, useMemo, useState } from 'react';
import { RefundForSaleForm } from './customerAccountLines/RefundForSaleForm';
import { SelectCustomerAccountLineDialog } from './customerAccountLines/SelectCustomerAccountLineDialog';
import { RefundForCashSaleForm } from './RefundForCashSaleForm';

interface IProps extends DialogProps {
	sale: ISale;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const RegisterRefundForSaleForm = ({ sale, cancel, confirm, ...rest }: IProps) => {
	const [cLines] = useApiEffect(customerAccountLinesQuery_queryForSale, sale.id);
	const lines = useMemo(() => (cLines ?? []).filter(t => t.canCreateRefund), [cLines]);
	const [line, setLine] = useState<ICustomerAccountLine>();

	useEffect(() => {
		if (lines.length === 1) {
			setLine(lines[0]);
		}
	}, [lines]);

	if (cLines === undefined) {
		return <></>;
	} else if (sale.isCashSale) {
		return (
			<RefundForCashSaleForm
				open
				sale={sale}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	} else if (line === undefined) {
		return (
			<SelectCustomerAccountLineDialog
				{...rest}
				lines={lines}
				confirm={setLine}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<RefundForSaleForm
				open
				confirm={confirm}
				cancel={cancel}
				customerAccountLineId={line.id}
			/>
		);
	}
};
