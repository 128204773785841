import { formatDateX } from 'framework/utils/date/formatDateX';
import { IRosaEventDto } from 'gen/ApiClient';

export const formatRosaEventDates = (event: IRosaEventDto) => {
	const isStartAndEndOnSameDay = formatDateX(event.startsAt) === formatDateX(event.endsAt);
	if (event.isAllDay && isStartAndEndOnSameDay) {
		return formatDateX(event.startsAt);
	} else if (event.isAllDay) {
		return `${formatDateX(event.startsAt)} - ${formatDateX(event.endsAt)}`;
	} else if (isStartAndEndOnSameDay) {
		return `${formatDateX(event.startsAt, 'dd/MM/yyyy HH:mm')} - ${formatDateX(event.endsAt, 'HH:mm')}`;
	} else {
		return `${formatDateX(event.startsAt, 'dd/MM/yyyy HH:mm')} - ${formatDateX(event.endsAt, 'dd/MM/yyyy HH:mm')}`;
	}
};
