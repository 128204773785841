import React, { useContext, useEffect, useState } from 'react';
import { ILocationSummary } from 'gen/ApiClient';
import { AuthContext } from 'app/auth/AuthContext';
import { CurrentLocationContext } from './CurrentLocationContext';
import { LocationsPreloadCacheContext } from './LocationsPreloadCacheContext';

export const CurrentLocationProvider = ({ children }: any) => {
	const auth = useContext(AuthContext);
	const { data } = useContext(LocationsPreloadCacheContext);
	const [location, setLocation] = useState<ILocationSummary>();

	useEffect(() => {
		if (data && auth.locationId) {
			setLocation(data.find(t => t.id === auth.locationId));
		}
	}, [auth.locationId, data]);

	return (
		<CurrentLocationContext.Provider
			value={{
				location: location,
			}}>
			{children}
		</CurrentLocationContext.Provider>
	);
};
