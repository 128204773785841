import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import React, { useContext, useMemo } from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { FileSearchOutlineIcon } from 'framework/components/icons/FileSearchOutlineIcon';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import {
	ITrialReport,
	LeftRightOrStereoType,
	trialReportsCommand_deleteLeftSpeech,
	trialReportsCommand_deleteRightSpeech,
	trialReportsCommand_deleteStereoSpeech,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { useViewDocumentation } from 'shared/utils/useViewDocumentation';
import { SpeechTestForm } from './SpeechTestForm';

const typeToSubmitCall = (type: LeftRightOrStereoType) => {
	if (type === 'Left') {
		return trialReportsCommand_deleteLeftSpeech;
	} else if (type === 'Right') {
		return trialReportsCommand_deleteRightSpeech;
	} else {
		return trialReportsCommand_deleteStereoSpeech;
	}
};

interface IProps extends DialogProps {
	patientId: string;
	close: VoidFunction;
	type: LeftRightOrStereoType;
	trialReport: ITrialReport;
}

export const SpeechTestDialog = ({ trialReport, patientId, close, type, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const item = useMemo(
		() => (type === 'Left' ? trialReport.leftSpeechTest : type === 'Right' ? trialReport.rightSpeechTest : trialReport.stereoSpeechTest),
		[trialReport, type]
	);
	const { open, confirm, cancel, isStacked } = useDialogsContext(close);
	const { leftRightOrStereoRecord } = useContext(RecordContext);
	const onViewSpeechRizivInfo = useViewDocumentation('speech-riziv-info');

	if (item === undefined) {
		return <div></div>;
	}

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.test)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => typeToSubmitCall(type)(trialReport.id)}
				accept={confirm}
			/>
		);
	};

	const onEdit = () => {
		open(
			<SpeechTestForm
				open
				trialId={trialReport.id}
				patientId={patientId}
				confirm={confirm}
				cancel={cancel}
				type={type}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			maxWidth='sm'
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<GroupWorkIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						Spraak test {leftRightOrStereoRecord[type]}
					</Typography>
				</DialogGridTitle>
				<GridDividerWithCaption caption={`${strings.resultsHearingImprovementIn}...`} />
				<PropertyInGrid
					labelGridSize={6}
					label={`...${strings.dBAgainstVocalIndex}`}
					value={`${Math.round(item.improvementAgainstVocalIndex)} dB`}
				/>
				<PropertyInGrid
					labelGridSize={6}
					label={`...${strings.procentSpeechImprovementAtSrtPoint}`}
					value={`${Math.round(item.improvementAtSrtIntensity)} %`}
				/>
				<PropertyInGrid
					labelGridSize={6}
					label={`...${strings.procentSpeechImprovementAccordingToICAMethod}`}
					value={`${Math.round(item.icaImprovement)} %`}
				/>
				<PropertyInGrid
					labelGridSize={6}
					label={`...${strings.procentSpeechImprovementAccordingToBIAPMethod}`}
					value={`${Math.round(item.biapImprovement)} %`}
				/>
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								onClick={onViewSpeechRizivInfo}
								startIcon={<FileSearchOutlineIcon />}>
								{strings.viewRIZIVDocumentation}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={onEdit}
								startIcon={<EditIcon />}>
								{strings.edit}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={onDelete}
								color='secondary'
								startIcon={<DeleteOutlineIcon />}>
								{strings.delete}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
