import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { ordersQuery_single } from 'gen/ApiClient';
import React from 'react';
import { useParams } from 'react-router-dom';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { OrderDetailDocumentsCard } from './OrderDetailDocumentsCard';
import { OrderDetailPageHeader } from './OrderDetailPageHeader';
import { OrderDetailPageSidebar } from './OrderDetailPageSidebar';
import { OrderDetailContentsCard } from './contents/OrderDetailContentsCard';
import { OrderDetailDeliveriesCard } from './deliveries/OrderDetailDeliveriesCard';

export const OrderDetailPage = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [trigger, reload] = useTrigger();
	const [item, reloadDetail] = useApiEffect(ordersQuery_single, id);

	useLazyEffect(() => {
		reloadDetail();
	}, [trigger]);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='Orders'
			stickyHeader={
				<OrderDetailPageHeader
					item={item}
					reload={reload}
				/>
			}
			stickySidebar={
				<OrderDetailPageSidebar
					item={item}
					reload={reload}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
					maxWidth: 1280,
				}}>
				<div className='df-row-wrap gap-16'>
					<OrderDetailContentsCard
						item={item}
						reload={reload}
						style={{ flexGrow: 2 }}
						className='h-fc'
						reloadTrigger={trigger}
					/>
					<div
						className='df-col gap-16'
						style={{ flexGrow: 1 }}>
						<OrderDetailDeliveriesCard
							item={item}
							reload={reload}
						/>
						<OrderDetailDocumentsCard
							item={item}
							reload={reload}
							reloadTrigger={trigger}
						/>
					</div>
				</div>
			</div>
		</RestrictedPageX>
	);
};
