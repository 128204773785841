import { IProductCatalogItem } from 'gen/ApiClient';

interface ILine {
	productCatalogItem: IProductCatalogItem | undefined;
	assuredLineId: string | undefined;
}

export function calculateMustLinkAnItemToAssure<TLine extends ILine>(line: TLine): boolean {
	if (line.productCatalogItem && line.productCatalogItem.isInsuranceOrProlongedWarranty && line.assuredLineId === undefined) {
		return true;
	} else {
		return false;
	}
}
