import { stockEntriesQueryParamsLsKey } from 'app/localStorageKeys';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IQueryResult, IStockEntriesQueryParams, IStockEntryDto, stockEntriesQuery_concurrencyToken, stockEntriesQuery_query } from 'gen/ApiClient';
import React from 'react';
import { QueryContext } from './QueryContext';

const DefaultParams: IStockEntriesQueryParams = {
	sortParams: { direction: 'desc', property: 'date' },
	pageParams: { index: 1, size: 10 },
	afterDate: undefined,
	beforeDate: undefined,
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IStockEntryDto>, IStockEntriesQueryParams>
			defaultParams={DefaultParams}
			queryF={stockEntriesQuery_query}
			concurrencyF={stockEntriesQuery_concurrencyToken}
			context={QueryContext}
			localStorageKey={stockEntriesQueryParamsLsKey}>
			{children}
		</FrameworkQueryProvider>
	);
};
