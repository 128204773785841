import React, { useContext } from 'react';
import { PreloadCacheProvider as FrameworkCacheProvider } from 'framework/context/PreloadCacheProvider';
import { entPhysiciansQuery_concurrencyToken, entPhysiciansQuery_all, IEntPhysicianWithOfficesSummary } from 'gen/ApiClient';
import { EntPhysiciansCacheContext } from './EntPhysiciansCacheContext';
import { PreloadContext } from 'shared/context/PreloadContext';

export const EntPhysiciansCacheProvider = ({ children }: any) => {
	const { entPhysicians } = useContext(PreloadContext);

	return (
		<FrameworkCacheProvider<IEntPhysicianWithOfficesSummary[]>
			initialData={entPhysicians}
			concurrencyF={entPhysiciansQuery_concurrencyToken}
			loadF={entPhysiciansQuery_all}
			context={EntPhysiciansCacheContext}>
			{children}
		</FrameworkCacheProvider>
	);
};
