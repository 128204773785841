import { Dialog, DialogContent, DialogProps, DialogTitle, Divider } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { v1 as uuid } from 'uuid';
import * as yup from 'yup';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormIntegerField } from 'framework/forms/FormIntegerField';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormTextField } from 'framework/forms/FormTextField';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormSelectVatPercentageField } from '../forms/FormSelectVatPercentageField';
import { VatPercentage } from '../VatPercentages';
import { INewLineModel } from './INewLineModel';

const createSchema = (strings: IStrings, isDiscount: boolean) => {
	return yup.object<INewLineModel>({
		id: yup.string().defined(),
		description: yup.string().required(),
		quantity: yup.number().required(),
		unitListPrice: yup.number().defined(),
		vatPercentage: yup.number().defined(),
	});
};

const emptyValues: INewLineModel = {
	id: uuid(),
	description: '',
	quantity: 1,
	unitListPrice: undefined as any,
	vatPercentage: 0,
};

interface IProps extends DialogProps {
	confirm: (line: INewLineModel) => void;
	cancel: VoidFunction;
	vatPercentage?: VatPercentage;
}

export const AddLineForm = ({ confirm, cancel, vatPercentage = 21, ...rest }: IProps) => {
	const strings = useLocalization();
	const [isDiscount, setIsDiscount] = useState<boolean>(false);

	const handleSubmit = (values: INewLineModel, helpers: FormikHelpers<INewLineModel>) => {
		confirm({ ...values, id: uuid(), unitListPrice: isDiscount && values.unitListPrice > 0 ? values.unitListPrice * -1 : values.unitListPrice });
	};

	return (
		<Formik<INewLineModel>
			validateOnMount
			initialValues={{ ...emptyValues, vatPercentage: vatPercentage }}
			validationSchema={createSchema(strings, isDiscount)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.addALine}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<ExtendedRadioGroup<boolean>
							selected={isDiscount}
							setSelected={setIsDiscount}
							options={[
								{ label: strings.discount, caption: strings.discountCaption, value: true },
								{ label: strings.extraLine, caption: strings.extraLineCaption, value: false },
							]}
						/>
						<Divider style={{ marginTop: 16, marginBottom: 16 }} />
						<FormTextField<INewLineModel>
							name='description'
							label={strings.description}
						/>
						<FormSelectVatPercentageField<INewLineModel>
							name='vatPercentage'
							label={strings.vatPercentage}
						/>
						{isDiscount ? (
							<FormMoneyField<INewLineModel>
								name='unitListPrice'
								label={strings.discount}
								allowNegative
							/>
						) : (
							<FormMoneyField<INewLineModel>
								name='unitListPrice'
								label={strings.listPrice}
								helperText={strings.discountsCanBeAddedAfterwards}
							/>
						)}
						<FormIntegerField<INewLineModel>
							name='quantity'
							label={strings.quantity}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={strings.add}
						isSubmitting={false}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
