import DateRangeIcon from '@material-ui/icons/DateRange';
import { FilterBar } from 'framework/filter/FilterBar';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IApiTokensQueryParams } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	params: IApiTokensQueryParams;
	onFilter: (params: IApiTokensQueryParams) => void;
}

const dateRangeIcon = <DateRangeIcon />;

export const ApiTokensListFilter = ({ params, onFilter }: IProps) => {
	return (
		<FilterBar
			isFiltered={isFiltered(params)}
			onClear={() => onFilter(clearParams(params))}>
			<DateFilterItem
				label='Datum aangemaakt'
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
				icon={dateRangeIcon}
			/>
		</FilterBar>
	);
};
