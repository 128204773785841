import { Dialog, DialogContent, DialogProps, DialogTitle, Typography, useTheme } from '@material-ui/core';
import { Form, Formik, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormPasswordField } from 'framework/forms/FormPasswordField';
import { setFieldError } from 'framework/forms/utils/setFieldError';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

interface IModel {
	password: string;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		password: yup.string().required(strings.formRequired(strings.password)),
	});
};

interface IProps extends DialogProps {
	confirm: (password: string) => void;
	cancel: VoidFunction;
	isSubmitting: boolean;
	caption?: string;
	title: string;
	hasInvalidPassword: boolean;
	submitText?: string;
}

export const PasswordForm = ({ confirm, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<Formik
			validateOnMount
			validationSchema={createSchema(strings)}
			initialValues={{ password: '' }}
			onSubmit={values => confirm(values.password)}>
			<Form>
				<InnerDialog {...rest} />
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	title: string;
	caption?: string;
	isSubmitting: boolean;
	cancel: VoidFunction;
	hasInvalidPassword: boolean;
	submitText?: string;
}

const InnerDialog = ({ title, caption, isSubmitting, cancel, hasInvalidPassword, submitText, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const props = useFormikContext<IModel>();

	useEffect(() => {
		if (hasInvalidPassword) {
			setFieldError<IModel>('password', strings.passwordIncorrect, props);
		}
		// eslint-disable-next-line
	}, [hasInvalidPassword]);

	return (
		<Dialog
			{...rest}
			fullWidth>
			<DialogTitle>{title}</DialogTitle>
			{caption && (
				<Typography
					variant='caption'
					style={{ whiteSpace: 'pre-line', paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3), paddingBottom: theme.spacing(2) }}>
					{caption}
				</Typography>
			)}
			<DialogContent
				dividers
				className='df-col'>
				<FormPasswordField<IModel>
					name='password'
					label={strings.password}
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions
				submitText={submitText ?? strings.update}
				isSubmitting={isSubmitting}
				cancel={cancel}
			/>
		</Dialog>
	);
};
