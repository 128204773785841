import React from 'react';
import { IHeader } from 'framework/table/IHeader';
import { useLocalization } from 'localization/useLocalization';
import { createSmallPrimaryTextButtonHeader } from './createSmallPrimaryTextButtonHeader';

export const createSelectHeader = <T extends unknown>(
	onClick: (val: T) => void,
	disabledF: (val: T) => boolean = (val: T) => false,
	onlyShowOnHover: boolean = false,
	isLoadingF: (val: T) => boolean = (val: T) => false
): IHeader<T> => {
	return createSmallPrimaryTextButtonHeader<T>('select-header', <Content />, onClick, disabledF, onlyShowOnHover, isLoadingF);
};

const Content = () => {
	const strings = useLocalization();
	return <span>{strings.select}</span>;
};
