import { Divider, DividerProps } from '@material-ui/core';
import React from 'react';

interface IProps extends DividerProps {
	marginTop?: number;
	marginBottom?: number;
}

export const DividerWithMargin = ({ marginTop, marginBottom, style, ...rest }: IProps) => {
	return (
		<Divider
			{...rest}
			style={{
				marginTop: marginTop ?? 8,
				marginBottom: marginBottom ?? 8,
				...style,
			}}
		/>
	);
};
