import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	INoahDeviceInventoryItemMap,
	INoahDeviceInventoryItemMapsWithUsagesQueryParams,
	IQueryResult,
	noahDeviceInventoryItemMapsQuery_concurrencyToken,
	noahDeviceInventoryItemMapsQuery_queryWithUsages,
} from 'gen/ApiClient';
import { noahDeviceInventoryItemMapsWithUsagesQueryParamsLsKey } from '../../../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: INoahDeviceInventoryItemMapsWithUsagesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'lastUsageDate' },
	pageParams: { index: 1, size: 10 },
	firstUsageAfter: undefined,
	firstUsageBefore: undefined,
	lastUsageAfter: undefined,
	lastUsageBefore: undefined,
	types: [],
	isLinkedToInventoryItem: undefined,
	hasDifferentReadOuts: undefined,
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<INoahDeviceInventoryItemMap>, INoahDeviceInventoryItemMapsWithUsagesQueryParams>
			defaultParams={DefaultParams}
			queryF={noahDeviceInventoryItemMapsQuery_queryWithUsages}
			concurrencyF={noahDeviceInventoryItemMapsQuery_concurrencyToken}
			localStorageKey={noahDeviceInventoryItemMapsWithUsagesQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
