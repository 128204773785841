import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { StarFavoriteIcon } from 'framework/components/icons/StarFavoriteIcon';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { TypographyBoldCaption } from 'framework/components/typography/TypographyBoldCaption';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { entPhysiciansCommand_patchOfficeEmail, entPhysiciansCommand_patchOfficePhone, IEntPhysician, IOfficeData } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { EntPhysicianOfficeFab } from './EntPhysicianOfficeFab';
import { PatchEntPhysicianOfficeAddressForm } from './forms/PatchEntPhysicianOfficeAddressForm';

interface IProps {
	item: IOfficeData;
	index: number;
	entPhysician: IEntPhysician;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const EntPhysicianOfficeListItem = ({ item, index, entPhysician, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onPatchAddress = () => {
		open(
			<PatchEntPhysicianOfficeAddressForm
				entPhysician={entPhysician}
				office={item}
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onPatchPhone = () => {
		open(
			<StringModelForm
				confirm={confirm}
				formTitle={strings.changePhone}
				label={strings.phone}
				initialValue={item.phone}
				open
				cancel={cancel}
				submitFunction={val => entPhysiciansCommand_patchOfficePhone({ entPhysicianId: entPhysician.id, officeId: item.id, phone: val })}
				submitText={strings.update}
			/>
		);
	};

	const onPatchEmail = () => {
		open(
			<StringModelForm
				confirm={confirm}
				formTitle={strings.changeEmail}
				label={strings.email}
				initialValue={item.email}
				open
				cancel={cancel}
				submitFunction={val => entPhysiciansCommand_patchOfficeEmail({ entPhysicianId: entPhysician.id, officeId: item.id, email: val })}
				submitText={strings.update}
			/>
		);
	};

	return (
		<div
			className='df-col gap-4'
			style={style}>
			<div
				className='df-row-ac'
				style={{ gap: 4 }}>
				<StarFavoriteIcon
					isFavorite={item.isDefault}
					style={{ fontSize: 18 }}
				/>
				<TypographyBoldCaption style={{ marginBottom: 0 }}>{`${strings.location} #${index.toString()}`}</TypographyBoldCaption>
				<EntPhysicianOfficeFab
					item={item}
					entPhysicianId={entPhysician.id}
					reload={reload}
				/>
			</div>
			<LabelledPropertyX
				edit={onPatchAddress}
				icon={<HomeOutlinedIcon />}>
				<span>{item.formattedAddress}</span>
			</LabelledPropertyX>
			<LabelledPropertyX
				edit={onPatchEmail}
				icon={<MailOutlineIcon />}>
				<StringValueOrNotApplicable>{item.email}</StringValueOrNotApplicable>
			</LabelledPropertyX>
			<LabelledPropertyX
				edit={onPatchPhone}
				icon={<LocalPhoneOutlinedIcon />}>
				<StringValueOrNotApplicable>{item.phone}</StringValueOrNotApplicable>
			</LabelledPropertyX>
		</div>
	);
};
