import { useTheme } from '@material-ui/core';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { formatDate } from 'framework/utils/date/formatDate';
import { INotificationWithStatusDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	item: INotificationWithStatusDto;
	style?: React.CSSProperties;
	onView: (id: string) => void;
}

export const NotificationListItem = ({ item, onView, style }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();

	return (
		<div
			className='df-col w100'
			onClick={() => onView(item.id)}
			style={{
				cursor: 'pointer',
				backgroundColor: item.isRead ? theme.palette.background.default : 'inherit',
				padding: 16,
				...style,
			}}>
			<div className='df-row-ac jc-sb'>
				<div className='df-row-ac'>
					{item.isRead === false && (
						<RectangularChip
							label={strings.new}
							color='error'
							fill
							style={{ marginRight: 8 }}
						/>
					)}
					<div style={{ fontWeight: item.isRead ? 'inherit' : 'bold' }}>{item.title}</div>
				</div>
				<div style={{ fontWeight: item.isRead ? 'inherit' : 'bold', marginLeft: 24 }}>{formatDate(item.created)}</div>
			</div>
			<LinesEllipsis
				text={item.description}
				maxLine={1}
				ellipsis='...'
				trimRight
				style={{ whiteSpace: 'pre-line', marginTop: 8 }}
				basedOn='letters'
			/>
			<div style={{ color: theme.palette.text.secondary, marginTop: 2 }}>{strings.clickToViewMore}</div>
		</div>
	);
};
