import { DialogProps } from '@material-ui/core';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IQueryResult, IRequestForInsuranceAllowance, IRfiasQueryParams, rfiasCommand_cancelScript, rfiasCommand_proformaToSale } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { Mode } from './Mode';
import { ModeDialog } from './ModeDialog';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	queryResult: IQueryResult<IRequestForInsuranceAllowance>;
	params: IRfiasQueryParams;
}

export const RfiaScriptsDialog = ({ confirm, cancel, queryResult, params, ...rest }: IProps) => {
	const strings = useLocalization();
	const [state, setState] = useState<'mode' | Mode>('mode');

	if (state === 'mode') {
		return (
			<ModeDialog
				{...rest}
				confirm={setState}
				cancel={cancel}
			/>
		);
	} else if (state === 'cancel') {
		return (
			<AlertDialog
				{...rest}
				title={'Execute script (cancel RFIAS)'}
				content={`Cancel ${queryResult.filteredCount.toString()} RFIAS?\n-Loan will be ended\n-Rfia will be cancelled\nProceed?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => rfiasCommand_cancelScript(params)}
				accept={confirm}
			/>
		);
	} else if (state === 'proforma') {
		return (
			<AlertDialog
				{...rest}
				title={'Execute script (set proforma to handed over RFIA)'}
				content={`Set handed over to ${queryResult.filteredCount.toString()} RFIAS?\n\nProceed?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => rfiasCommand_proformaToSale(params)}
				accept={confirm}
			/>
		);
	} else {
		return <div></div>;
	}
};
