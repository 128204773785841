import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageX } from 'framework/components/layout/PageX';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { useTrigger } from 'framework/hooks/useTrigger';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { GeneralSettingsTab } from './GeneralSettingsTab';
import { SettingsPageViewContext } from './SettingsPageViewContext';
import { SettingsTabTypes } from './SettingsTabType';
import { TemplatesTab } from './templates/TemplatesTab';
import { UsersTab } from './users/UsersTab';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

export const SettingsPage = () => {
	const strings = useLocalization();
	const [trigger, reload] = useTrigger();
	const { selectedTab, setSelectedTab, TabTypeRecord } = useContext(SettingsPageViewContext);

	return (
		<PageX
			renderRaw
			stickyHeader={
				<div className='df-col fg1'>
					<div className='df-row-ac'>
						<PageBreadcrumbTitle
							title={strings.settings}
							icon={<SettingsOutlinedIcon />}
						/>
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={TabTypeRecord[selectedTab]} />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
					<HorizontalTabsX
						tabs={SettingsTabTypes}
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
						titleRecord={TabTypeRecord}
						style={{
							marginTop: 16,
							marginLeft: -4,
						}}
					/>
				</div>
			}>
			<div
				className='df-col fg1'
				style={{
					padding: 16,
					paddingLeft: 16,
					maxWidth: 1280,
				}}>
				{selectedTab === 'general' && <GeneralSettingsTab reloadTrigger={trigger} />}
				{selectedTab === 'templates' && <TemplatesTab reloadTrigger={trigger} />}
				{selectedTab === 'users' && <UsersTab reloadTrigger={trigger} />}
			</div>
		</PageX>
	);
};
