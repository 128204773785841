import React, { useState } from 'react';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { INoahDeviceReadOut, noahDeviceReadOutsCommand_addRule } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { HearingInstrumentForm } from '../../../productCatalog/forms/hearingInstrument/HearingInstrumentForm';
import { SelectProductCatalogItemForm } from '../../single/forms/SelectProductCatalogItemForm';
import { RuleMode } from './RuleMode';

interface IProps {
	item: INoahDeviceReadOut;
	confirm: (id?: string) => void;
	cancel: VoidFunction;
}

export const RuleForm = ({ item, cancel, confirm }: IProps) => {
	const [state, setState] = useState<RuleMode>('select');
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(noahDeviceReadOutsCommand_addRule);
	const notify = useSnackbarNotify();

	const onSelect = async (productCatalogId: string) => {
		const r = await submit(item.id, productCatalogId);
		if (r.isSuccess) {
			notify(strings.addedWhat(strings.rule));
			confirm();
		}
	};

	if (state === 'select') {
		return (
			<SelectProductCatalogItemForm
				open
				confirm={onSelect}
				cancel={cancel}
				initialSearch={item.uniqueField ?? ''}
				jsxTitle={<Title item={item} />}
				isSubmitting={isSubmitting}
				onNew={() => setState('new')}
			/>
		);
	} else {
		return (
			<HearingInstrumentForm
				open
				confirm={onSelect}
				cancel={cancel}
				isHearingInstrument={item.isHearingInstrument}
				manufacturer={item.manufacturer}
				model={item.instrumentTypeName}
			/>
		);
	}
};

const Title = ({ item }: { item: INoahDeviceReadOut }) => {
	const strings = useLocalization();
	return (
		<>
			<div>{strings.addWhat(strings.rule)}</div>
			<div>{item.uniqueField}</div>
		</>
	);
};
