import { CardProps, Typography } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ScannerIcon from '@material-ui/icons/Scanner';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { CollapseX } from 'framework/components/CollapseX';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { ChipX } from 'framework/components/chips/ChipX';
import { FileDocumentEditOutlineIcon } from 'framework/components/icons/FileDocumentEditOutlineIcon';
import { FileDownloadOutlineIcon } from 'framework/components/icons/FileDownloadOutlineIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IFile } from 'framework/utils/IFile';
import {
	ITemplate,
	LanguageOption,
	LinkViewContextType,
	TemplateType,
	templatesCommand_delete,
	templatesCommand_restore,
	templatesCommand_upload,
	templatesQuery_download,
} from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useTemplateEditor } from 'shared/dialogs/templates/useTemplateEditor';
import { UploadFileDialog } from 'shared/dialogs/upload/UploadFileDialog';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { TemplateMetaForm } from './forms/TemplateMetaForm';
import { TemplateNameForm } from './forms/TemplateNameForm';
import { TemplateHistoryTimeline } from './detail/TemplateHistoryTimeline';

interface IProps extends CardProps {
	item: ITemplate;
	reload: VoidFunction;
}

export const TemplateDetailsCard = ({ item, reload, ...rest }: IProps) => {
	const strings = useLocalization();
	const [download] = useDownload(templatesQuery_download);
	const [openEditor] = useTemplateEditor(reload);
	const [upload, isUploading] = useFormSubmit(templatesCommand_upload);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { templateTypeRecord, localizedLanguageRecord, linkViewContextTypeRecord } = React.useContext(RecordContext);

	const onViewOrEdit = async () => {
		openEditor(item.id);
	};

	const onUpload = () => {
		open(
			<UploadFileDialog
				open
				title={strings.uploadWhat(strings.template)}
				cancel={cancel}
				confirmF={onFileSelected}
				confirm={confirm}
				isUploading={isUploading}
				accept='word'
			/>
		);
	};

	const onFileSelected = async (file: IFile) => {
		await upload(item.id, file.contentAsBase64);
	};

	const onChangeMeta = () => {
		open(
			<TemplateMetaForm
				open
				template={item}
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	const onChangeName = () => {
		open(
			<TemplateNameForm
				open
				template={item}
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhat(strings.template)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => templatesCommand_delete(item.id)}
				accept={() => {
					confirm();
				}}
			/>
		);
	};

	const onRestore = () => {
		open(
			<AlertDialog
				open
				title={strings.repairWhatQuestion(strings.template)}
				content={strings.doYouWantToRepairTemplateWithName(item.name ?? '')}
				acceptText={strings.yes}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => templatesCommand_restore(item.id)}
				accept={confirm}
				fullWidth
			/>
		);
	};

	return (
		<CardX {...rest}>
			<Typography
				component='div'
				style={{ fontSize: '16px', fontWeight: 500, marginBottom: 16 }}>
				{strings.details}
			</Typography>
			<div className='df-col gap-8'>
				<CaptionVerticalLabelledPropertyX
					label={strings.name}
					edit={onChangeName}>
					{item.name}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.language}>{localizedLanguageRecord[item.language as LanguageOption]}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.type}>
					<ChipX
						label={templateTypeRecord[item.predefinedType as TemplateType]}
						color='primary'
						className='w-fc'
					/>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.subject}>
					<ChipX
						label={linkViewContextTypeRecord[item.mainContext as LinkViewContextType]}
						color='secondary'
						className='w-fc'
					/>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={`${strings.editable}?`}
					edit={onChangeMeta}>
					{item.canEdit ? strings.editable : strings.notEditable}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={`${strings.signature}?`}
					edit={onChangeMeta}>
					{item.mustSign ? strings.signatureNeeded : strings.noSignatureNeeded}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={`${strings.viewableInApp}?`}
					edit={onChangeMeta}>
					{item.canViewOnline ? strings.viewableInApp : strings.notViewableInApp}
				</CaptionVerticalLabelledPropertyX>
				<Typography16pxBold>{strings.actions}</Typography16pxBold>
				<div className='df-col gap-6'>
					<TextLinkButton
						onClick={onViewOrEdit}
						color='primary'
						startIcon={<FileDocumentEditOutlineIcon />}>
						{item.isDeleted ? strings.view : strings.edit}
					</TextLinkButton>
					<TextLinkButton
						onClick={() => download(item.id)}
						color='primary'
						startIcon={<FileDownloadOutlineIcon />}>
						{strings.download}
					</TextLinkButton>
					{item.isDeleted === false && (
						<TextLinkButton
							color='primary'
							onClick={onUpload}
							startIcon={<ScannerIcon />}>
							{strings.upload}
						</TextLinkButton>
					)}
					{item.isDeleted === false && (
						<TextLinkButton
							onClick={onDelete}
							startIcon={<DeleteOutlineIcon />}
							color='secondary'>
							{strings.delete}
						</TextLinkButton>
					)}
					{item.isDeleted && (
						<TextLinkButton
							onClick={onRestore}
							startIcon={<SettingsBackupRestoreIcon />}
							color='secondary'>
							{strings.repairVerb}
						</TextLinkButton>
					)}
				</div>
				{item.history.length > 0 && (
					<CollapseX
						collapseAfter
						collapseText={strings.hideWhat(strings.history)}
						expandText={strings.showWhat(strings.history)}>
						<TemplateHistoryTimeline
							history={item.historyOrderedDesc}
							id={item.id}
						/>
					</CollapseX>
				)}
			</div>
		</CardX>
	);
};
