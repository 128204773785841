import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { IStockEntryDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: IStockEntryDto;
	style?: React.CSSProperties;
}

export const StockEntryNotUpToDateChip = ({ item, style }: IProps) => {
	const strings = useLocalization();

	return (
		<div className='df-row-ac'>
			{item.isDirty && (
				<TooltipWithSpan title={strings.notUpToDate}>
					<RectangularChip
						label={strings.notUpToDate}
						fill
						color='error'
						style={style}
					/>
				</TooltipWithSpan>
			)}
		</div>
	);
};
