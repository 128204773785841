import { ILocalizedArticlePropertyKey } from 'app/main/inventoryManagement/productCatalog/articlePropertyKeys/context/ILocalizedArticlePropertyKey';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { IArticleProperty } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { ArticlePropertyContent } from './ArticlePropertyContent';

interface IProps {
	item: IArticleProperty;
	localizedKeys: ILocalizedArticlePropertyKey[];
	mismatch: boolean;
	requestedValues: string[];
}

export const ArticlePropertyWithMismatchCaptionVerticalLabelledPropertyX = ({ item, localizedKeys, mismatch, requestedValues }: IProps) => {
	const strings = useLocalization();
	const label = React.useMemo(() => localizedKeys.find(t => t.key === item.key)?.label ?? '', [item.key, localizedKeys]);

	return (
		<CaptionVerticalLabelledPropertyX label={label}>
			<div className='df-col'>
				<div className='df-row gap-4'>
					<StatusIcon
						status={mismatch ? 'error' : 'success'}
						style={{ fontSize: 18 }}
					/>
					<ArticlePropertyContent values={item.values} />
				</div>
				{mismatch === true && (
					<div className='df-row-ab gap-4'>
						<span style={{ fontSize: 13 }}>{`${strings.demanded}:`}</span>
						<ArticlePropertyContent values={requestedValues} />
					</div>
				)}
			</div>
		</CaptionVerticalLabelledPropertyX>
	);
};
