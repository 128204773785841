import { useFormikContext } from 'formik';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { FormAutocompleteFieldWithAdd } from 'framework/forms/FormAutocompleteFieldWithAdd';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IEntPhysicianWithOfficesSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { EntPhysiciansCacheContext } from '../EntPhysiciansCacheContext';
import { RegisterEntPhysicianForm } from './RegisterEntPhysicianForm';

export const FormSelectOrCreateEntPhysicianField = <TModel extends unknown>({ name, label, ...rest }: IFormProps<TModel>) => {
	const props = useFormikContext<TModel>();
	const strings = useLocalization();
	const [data, load] = usePreloadCacheContext(EntPhysiciansCacheContext);
	const { open, confirm, cancel } = useDialogsContext();

	if (data === undefined) {
		return <div></div>;
	}

	const onConfirm = async (id: string) => {
		await load();
		setFormValue<TModel>(name, id, props);
		confirm();
	};

	const onNew = () => {
		open(
			<RegisterEntPhysicianForm
				open
				confirm={onConfirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<FormAutocompleteFieldWithAdd<TModel, IEntPhysicianWithOfficesSummary>
			name={name}
			label={label}
			options={data}
			onCreateNew={onNew}
			renderCreate={str => strings.createStrCommaWhat(str, strings.newEntPhysician)}
			{...rest}
		/>
	);
};
