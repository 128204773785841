import { Checkbox, Paper, useTheme } from '@material-ui/core';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { LocalStateTextField } from 'framework/components/textFields/LocalStateTextField';
import { QuantityField } from 'framework/components/textFields/QuantityField';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { IndexAvatar } from 'shared/searchAndSelect/lines/IndexAvatar';
import { LineWarningsAndErrors } from 'shared/searchAndSelect/lines/LineWarningsAndErrors';
import { IRegisterOrderDeliveryLineModel } from './IRegisterOrderDeliveryLineModel';

interface IProps {
	line: IRegisterOrderDeliveryLineModel;
	index: number;
	setLine: (val: IRegisterOrderDeliveryLineModel) => void;
	style?: React.CSSProperties;
}

export const OrderDeliveryLineComponent = ({ line, index, setLine, style }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();

	return (
		<Paper
			variant='outlined'
			style={{ padding: 8, ...style }}>
			<div className='df-row gap-8'>
				<div
					className='df-col-ac'
					style={{ marginLeft: -6, ...style }}>
					<IndexAvatar
						index={index}
						color='normal'
					/>
					<Checkbox
						checked={line.isSelected}
						tabIndex={-1}
						disableRipple
						onClick={() => setLine({ ...line, isSelected: !line.isSelected })}
					/>
				</div>
				<div className='df-col gap-8 w100'>
					<div className='df-row-ac'>
						<div>{line.productCatalogItem.identifier}</div>
						<div className='fg1'></div>
						<QuantityField
							value={line.quantity}
							setValue={val => setLine({ ...line, quantity: val })}
							error={line.isSelected && line.quantity > line.orderLine.remainingQuantity}
							helperText={line.isSelected && line.quantity > line.orderLine.remainingQuantity && `Max. ${line.orderLine.remainingQuantity}`}
							max={line.orderLine.remainingQuantity}
							disabled={line.orderLine.isUniquelyIdentifiable || line.isSelected === false}
						/>
					</div>
					{line.orderLine.hasPatient &&
						<span style={{
							fontSize: 12,
							color: theme.palette.text.secondary
						}}>
							{`${strings.patient}: ${line.orderLine.patientIdentifier}`}
						</span>
					}
					{line.orderLine.isUniquelyIdentifiable && (
						<>
							<FormControlLabelSwitch
								isChecked={line.autoGenerateSerialNumber}
								toggle={() => setLine({ ...line, autoGenerateSerialNumber: !line.autoGenerateSerialNumber })}
								label={strings.autoGenerateSerialNumberQuestion}
								style={{ marginLeft: 0 }}
							/>
							{line.autoGenerateSerialNumber === false && (
								<LocalStateTextField
									variant='outlined'
									size='small'
									label={strings.serialNumber}
									value={line.serialNumber}
									style={{ width: 'fit-content' }}
									disabled={line.autoGenerateSerialNumber}
									onChange={e => setLine({ ...line, serialNumber: e.target.value })}
								/>
							)}
						</>
					)}
				</div>
			</div>
			{line.isSelected === true && <LineWarningsAndErrors errors={line.error} />}
		</Paper>
	);
};
