import { StatCardX } from 'framework/components/cards/StatCardX';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { CustomerAccountType, ICustomerAccount, ICustomerAccountMetadata } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { CustomerAccountDetailTabType } from './CustomerAccountDetailTabType';

interface IProps {
	item: ICustomerAccount;
	metadata: ICustomerAccountMetadata;
	setTab: (tab: CustomerAccountDetailTabType) => void;
	style?: React.CSSProperties;
}

export const CustomerAccountDetailQuickStats = ({ item, metadata, setTab, style }: IProps) => {
	const strings = useLocalization();

	return (
		<div
			className='df-row-ac gap-16'
			style={style}>
			<StatCardX
				style={{ width: 300 }}
				title={strings.outstandingBalance}
				caption={`= ${strings.total} - ${strings.payments}`}>
				<span style={{ fontSize: 24 }}>{formatCurrency(item.outstandingBalance)}</span>
			</StatCardX>
			<StatCardX
				style={{ width: 300 }}
				onClick={() => setTab('sales')}
				title={strings.sales}
				caption={`${strings.unhandled}/${strings.total}`}>
				<span style={{ fontSize: 24 }}>{`${metadata.countCustomerAccountLinesWithOutstandingBalance}/${metadata.countCustomerAccountLines}`}</span>
			</StatCardX>
			{(item.accountType as CustomerAccountType) === 'HealthInsuranceFund' && (
				<StatCardX
					style={{ width: 300 }}
					onClick={() => setTab('sales')}
					title={strings.unhandled}
					caption={`# verkopen niet op een afrekeningsvoorstel`}>
					<span style={{ fontSize: 24 }}>{metadata.countCustomerAccountLinesWithOutstandingBalanceAndWithoutSettlementProposal.toString()}</span>
				</StatCardX>
			)}
			<StatCardX
				style={{ width: 300 }}
				onClick={() => setTab('settlementProposals')}
				title={strings.settlementProposals}
				caption={`${strings.unhandled}/${strings.total}`}>
				<span style={{ fontSize: 24 }}>{`${metadata.countSettlementProposalsWithOutstandingBalance}/${metadata.countSettlementProposals}`}</span>
			</StatCardX>
			<StatCardX
				style={{ width: 300 }}
				onClick={() => setTab('payments')}
				title={strings.payments}
				caption={`# ${strings.payments}`}>
				<span style={{ fontSize: 24 }}>{`${metadata.countPayments}`}</span>
			</StatCardX>
		</div>
	);
};
