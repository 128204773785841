import { useContext, useEffect } from 'react';
import { AgendaContext } from './AgendaContext';

export function useAgendaContext() {
	const { init, ...rest } = useContext(AgendaContext);

	useEffect(() => {
		console.log('init');
		init();
	}, [init]);

	return { ...rest };
}
