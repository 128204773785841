import { Button, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useTrigger } from 'framework/hooks/useTrigger';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import {
	GeneralPractitionerExportProp,
	GeneralPractitionerExportProps,
	IGeneralPractitionerFilterParams,
	generalPractitionersQuery_export,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TextLinkExportButtonMenuAndDialog } from 'shared/export/TextLinkExportButtonMenuAndDialog';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { GeneralPractitionerDetailsCard } from './GeneralPractitionerDetailsCard';
import { GeneralPractitionerListItemCardX } from './GeneralPractitionerListItemCardX';
import { GeneralPractitionersFilterDataContext } from './GeneralPractitionersFilterDataContext';
import { GeneralPractitionersListFilterCard } from './GeneralPractitionersListFilterCard';
import { GeneralPractitionersQueryContext } from './GeneralPractitionersQueryContext';
import { createGeneralPractitionersExportRecord } from './createGeneralPractitionersExportRecord';
import { RegisterGeneralPractitionerForm } from './forms/RegisterGeneralPractitionerForm';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

export const GeneralPractitionersPage = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(GeneralPractitionersQueryContext);
	const [searchData, reloadSearchData] = useCacheContext(GeneralPractitionersFilterDataContext);
	const strings = useLocalization();
	const [trigger, hitTrigger] = useTrigger();
	const { open, confirm, cancel } = useDialogsContext(hitTrigger);
	const exportRecord = useMemo(() => createGeneralPractitionersExportRecord(strings), [strings]);
	const [selectedId, setSelectedId] = useState<string>();
	const expandedGeneralPractitioner = useMemo(() => queryResult?.values.find(t => t.id === selectedId), [queryResult, selectedId]);

	useLazyEffect(() => {
		reload();
		reloadSearchData();
	}, [trigger]);

	const onCreate = () => {
		open(
			<RegisterGeneralPractitionerForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	if (queryResult === undefined || searchData === undefined) {
		return <></>;
	}

	return (
		<RestrictedPageX
			pageClaim='GeneralPractitioners'
			maxWidth={1280}
			stickyHeader={
				<div
					className='df-col gap-8 fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<PageBreadcrumbTitle title={strings.contacts} />
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={strings.generalPractitioners} />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageReloadButtonX reload={hitTrigger} />
						</PageXHeaderActions>
					</div>
					<SearchbarX
						placeholder={strings.searchVerb}
						value={params.searchString}
						onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
						style={{ width: 500 }}
					/>
				</div>
			}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8 }}>
					<Button
						variant='outlined'
						color='primary'
						startIcon={<AddIcon />}
						onClick={onCreate}
						style={{ borderRadius: 12 }}>
						{strings.new}
					</Button>
					<GeneralPractitionersListFilterCard
						params={params}
						setParams={setParams}
						searchData={searchData}
					/>
					<div className='fg1'></div>
					<Divider />
					<TextLinkExportButtonMenuAndDialog<IGeneralPractitionerFilterParams, GeneralPractitionerExportProp>
						params={params}
						fileName={strings.generalPractitioners}
						downloadF={generalPractitionersQuery_export}
						record={exportRecord}
						filteredCount={queryResult.filteredCount}
						unfilteredCount={queryResult.unfilteredCount}
						allProps={GeneralPractitionerExportProps}
						view='generalPractitioners'
						style={{ paddingBottom: 0 }}
					/>
				</div>
			}>
			<div
				className='df-row gap-16 h100'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				<div
					className='df-col'
					style={{ gap: 8 }}>
					{queryResult.values.map(t => (
						<GeneralPractitionerListItemCardX
							key={t.id}
							item={t}
							isSelected={selectedId === t.id}
							toggle={() => setSelectedId(selectedId === t.id ? undefined : t.id)}
						/>
					))}
					<QueryResultPaginationX
						queryResult={queryResult}
						setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
						style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
					/>
				</div>
				{expandedGeneralPractitioner !== undefined && (
					<>
						<Divider orientation='vertical' />
						<GeneralPractitionerDetailsCard
							item={expandedGeneralPractitioner}
							className='fg1 h-fc'
							reload={hitTrigger}
						/>
					</>
				)}
			</div>
		</RestrictedPageX>
	);
};
