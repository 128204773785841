import { SettlementProposalExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createExportRecord = (strings: IStrings): Record<SettlementProposalExportProp, string> => {
	return {
		AmountPaid: strings.amountPaid,
		Created: strings.dateCreated,
		CustomerAccountIdentifier: strings.accountReceivable,
		IsFullyPaid: `${strings.fullyPaid}?`,
		IsHandled: `${strings.handled}?`,
		IsPartlyPaid: `${strings.partlyPaid}?`,
		LastUpdated: strings.lastUpdated,
		PaymentReference: strings.paymentReference,
		Reference: strings.reference,
		TotalValue: strings.totalAmount,
	};
};
