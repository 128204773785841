import { IInventoryItem } from 'gen/ApiClient';
import React from 'react';
import { HistoryCard } from './HistoryCard';
import { TransactionActionsCard } from './TransactionActionsCard';
import { TransactionsSummaryCard } from './TransactionsSummaryCard';

interface IProps {
	item: IInventoryItem;
	reload: VoidFunction;
}

export const TransactionsPage = ({ item, reload }: IProps) => {
	return (
		<div className='df-row'>
			<div
				className='df-col'
				style={{ width: 350 }}>
				<TransactionActionsCard
					item={item}
					reload={reload}
					style={{ marginBottom: 16, marginRight: 16 }}
				/>
				<TransactionsSummaryCard
					item={item}
					style={{ marginBottom: 16, marginRight: 16 }}
				/>
			</div>
			<div className='df-col'>
				<HistoryCard
					item={item}
					width={540}
				/>
			</div>
		</div>
	);
};
