import { TableCell, TableRow } from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useHeightResizeObserver } from '../hooks/useHeightResizeObserver';
import { useStateBoolean } from '../hooks/useStateBool';
import { calculateCellStyle } from './calculateCellStyle';
import { IHeader } from './IHeader';
import { toBorder } from './toBorder';

interface IProps<T> {
	headers: IHeader<T>[];
	provided: DraggableProvided;
	padding: number;
	isLast: boolean;
	value: T;
	index: number;
	keepDndLeftPadding: boolean;
	expandableContent?: JSX.Element;
}

export const DraggableTableRow = <T extends unknown>({
	headers,
	provided,
	padding,
	isLast,
	value,
	index,
	keepDndLeftPadding,
	expandableContent,
}: IProps<T>) => {
	const [hover, enter, leave] = useStateBoolean(false);
	const [ref, height] = useHeightResizeObserver();

	return (
		<React.Fragment>
			<TableRow
				ref={provided.innerRef}
				{...provided.draggableProps}
				onMouseEnter={enter}
				onMouseLeave={leave}
				hover={false}
				// key={}
				tabIndex={-1}
				selected={false}>
				{headers.map(header => (
					<TableCell
						key={header.key}
						align={header.align}
						style={calculateCellStyle<T>(padding, header, isLast || expandableContent !== undefined)}>
						<div style={{ visibility: hover === false && header.onlyShowOnHover ? 'collapse' : 'inherit' }}>{header.evaluate(value, index)}</div>
					</TableCell>
				))}
				<TableCell
					align='right'
					style={{
						width: '24px',
						padding: padding,
						paddingLeft: keepDndLeftPadding ? padding : 0,
						borderBottom: toBorder(isLast || expandableContent !== undefined),
					}}>
					<div
						className='df-row jc-e'
						{...provided.dragHandleProps}>
						<DragHandleIcon color='action' />
					</div>
				</TableCell>
			</TableRow>
			{expandableContent && (
				<TableRow style={{ borderTop: 'unset', borderBottom: 'unset' }}>
					<TableCell
						style={{ padding: padding, paddingBottom: height > 0 ? padding : 0, paddingTop: 0, borderTop: 'unset', borderBottom: toBorder(isLast) }}
						colSpan={headers.length + 1}>
						<div ref={ref}>{expandableContent}</div>
					</TableCell>
				</TableRow>
			)}
		</React.Fragment>
	);
};
