import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IContactModel } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { ISharedDialogProps } from './ISharedDialogProps';

const createSchema = (strings: IStrings) => {
	return yup.object<IContactModel>({
		salutation: yup.string(),
		firstName: yup.string().required(strings.formRequired(strings.firstName)),
		lastName: yup.string().required(strings.formRequired(strings.lastName)),
	});
};

export const ContactForm = ({ title, model, submitFunction, cancel, confirm, successMessage, ...rest }: ISharedDialogProps<IContactModel>) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(submitFunction);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IContactModel, helpers: FormikHelpers<IContactModel>) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			if (successMessage) {
				notify(successMessage);
			}
			confirm();
		}
	};

	return (
		<Formik<IContactModel>
			validateOnMount
			initialValues={model ?? { firstName: '', lastName: '', salutation: '' }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog {...rest}>
					<DialogTitle>{title ? title : strings.contactPerson}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<IContactModel>
							name='salutation'
							label={strings.salutation}
						/>
						<FormTextField<IContactModel>
							name='firstName'
							label={strings.firstName}
							required
						/>
						<FormTextField<IContactModel>
							name='lastName'
							label={strings.lastName}
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={model ? strings.update : strings.create}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
