import { Button, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { DialogX } from 'framework/dialogs/DialogX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { IOrderV2, ISupplierSummaryWithOrderStats, ordersQuery_queryNotPlacedBySupplier } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { NewOrderCardX } from './NewOrderCardX';
import { OrderNotPlacedWithSupplierListItemCardX } from './OrderNotPlacedWithSupplierListItemCardX';

const filterOrders = (orders: IOrderV2[], search: string | undefined) => {
	if (search === undefined) {
		return orders;
	} else {
		return orders.filter(t => (t.reference ?? '').toLowerCase().includes(search.toLowerCase()));
	}
};

interface IProps extends DialogProps {
	supplier: ISupplierSummaryWithOrderStats;
	confirm: (order: IOrderV2) => void;
	cancel: VoidFunction;
	onNew: VoidFunction;
	previous?: VoidFunction;
}

export const SelectExistingOrderOrNewDialog = ({ cancel, confirm, previous, onNew, supplier, ...rest }: IProps) => {
	const strings = useLocalization();
	const [orders] = useApiEffect(ordersQuery_queryNotPlacedBySupplier, supplier.id);
	const [searchString, setSearchString] = useState<string>('');
	const fOrders = useMemo(() => filterOrders(orders ?? [], searchString), [orders, searchString]);
	const [selectedOrder, setSelectedOrder] = useAccordionState<IOrderV2>();

	return (
		<DialogX
			{...rest}
			open={rest.open}>
			<DialogTitle>
				<div className='df-col gap-8'>
					<span>{strings.selectAnExistingOrderOrRegisterANew}</span>
					<LightBulbNotification>
						{strings.supplierWhoHasCountOrdersToAddLinesTo(supplier.name ?? '', supplier.countOrdersToPlaceWithSupplier)}
					</LightBulbNotification>
					<SearchbarX
						placeholder={strings.searchVerb}
						onSearch={setSearchString}
						value={searchString}
					/>
				</div>
			</DialogTitle>
			<DialogContent
				className='df-col gap-8'
				dividers>
				<NewOrderCardX onClick={onNew} />
				{fOrders.map(t => (
					<OrderNotPlacedWithSupplierListItemCardX
						key={t.id}
						item={t}
						onSelect={() => setSelectedOrder(t)}
						isSelected={t.id === selectedOrder?.id}
					/>
				))}
			</DialogContent>
			<DialogActions>
				{previous && (
					<Button
						onClick={previous}
						color='primary'>
						{strings.previous}
					</Button>
				)}
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button
					onClick={() => confirm(selectedOrder!)}
					color='primary'
					disabled={selectedOrder === undefined}>
					{strings.addLinesToThisOrder}
				</Button>
			</DialogActions>
		</DialogX>
	);
};
