import React, { useMemo } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { KeysFormattingContext } from './KeysFormattingContext';
import { createDateFormattingRecord } from './utils/createDateFormattingRecord';
import { createNumberFormattingRecord } from './utils/createNumberFormattingRecord';
import { createStringFormattingRecord } from './utils/createStringFormattingRecord';

export const KeysFormattingProvider = ({ children }: any) => {
	const strings = useLocalization();
	const stringFormattingRecord = useMemo(() => createStringFormattingRecord(strings), [strings]);
	const numberFormattingRecord = useMemo(() => createNumberFormattingRecord(strings), [strings]);
	const dateFormattingRecord = useMemo(() => createDateFormattingRecord(strings), [strings]);

	return (
		<KeysFormattingContext.Provider
			value={{
				stringFormattingRecord: stringFormattingRecord,
				numberFormattingRecord: numberFormattingRecord,
				dateFormattingRecord: dateFormattingRecord,
			}}>
			{children}
		</KeysFormattingContext.Provider>
	);
};
