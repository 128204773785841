import { DailyReceiptExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createExportRecord = (strings: IStrings): Record<DailyReceiptExportProp, string> => {
	return {
		IsGlobal: `Globaal?`,
		LocationIdentifier: strings.location,
		OnDate: strings.date,
		PriceExVat: strings.priceExVat,
		PriceInVat: strings.priceInVat,
		PriceInVatAt0: `${strings.priceInVat} @ 0% BTW`,
		PriceInVatAt6: `${strings.priceInVat} @ 6% BTW`,
		PriceInVatAt12: `${strings.priceInVat} @ 12% BTW`,
		PriceInVatAt21: `${strings.priceInVat} @ 21% BTW`,
		VatAmount: strings.vatAmount,
	};
};
