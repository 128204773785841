import React from 'react';
import {
	IInventoryItem,
	IInventoryItemsQueryParamsForStockMovement,
	IProductCatalogItem,
	IProductCatalogQueryParamsForStockMovement,
	IQueryResult,
} from 'gen/ApiClient';

export interface IContext {
	pcParams: IProductCatalogQueryParamsForStockMovement;
	setPcParams: React.Dispatch<React.SetStateAction<IProductCatalogQueryParamsForStockMovement>>;
	pcQueryResult: IQueryResult<IProductCatalogItem> | undefined;
	iiParams: IInventoryItemsQueryParamsForStockMovement;
	setIIParams: React.Dispatch<React.SetStateAction<IInventoryItemsQueryParamsForStockMovement>>;
	iiQueryResult: IQueryResult<IInventoryItem> | undefined;
}

export const SearchContext = React.createContext<IContext>(undefined as any);
