import { Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { SuccessLabelledProperty } from 'framework/components/labelledProperty/SuccessLabelledProperty';
import { VariantLabelledProperty } from 'framework/components/labelledProperty/VariantLabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { exactOnlineIntegrationQuery_status } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { ExactOnlineRoute } from '../integrations/IntegrationsRoutes';

interface IProps extends IReloadTriggerProps {
	className?: string;
	style?: React.CSSProperties;
}

export const ExactOnlineDashboardCard = ({ style, className, reloadTrigger }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const [data, reload] = useApiEffect(exactOnlineIntegrationQuery_status);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	const onGotoExactOnline = () => {
		push(ExactOnlineRoute);
	};

	if (data === undefined) {
		return <></>;
	}

	return (
		<Card
			className={className}
			style={style}>
			<CardTitleTypography
				title={strings.exactOnlineIntegration}
				withPadding
			/>

			<CardContent style={{ paddingTop: 0, paddingBottom: 16 }}>
				<div
					className='df-col'
					style={style}>
					{data.hasNotification === false && (
						<SuccessLabelledProperty
							successMessage={strings.exactOnlineUpToDate}
							spacing={3}
						/>
					)}
					<>
						<VariantLabelledProperty
							property={
								data.countUnlinkedProductCatalogItems > 0 ? strings.countArticlesNotLinked(data.countUnlinkedProductCatalogItems) : strings.allArticlesLinked
							}
							variantX={data.countUnlinkedProductCatalogItems > 0 ? 'warning' : 'success'}
							onClick={onGotoExactOnline}
							spacing={3}
						/>
						<VariantLabelledProperty
							property={
								data.countUnprocessedCustomerAccounts > 0 ? strings.countCustomersNotLinked(data.countUnprocessedCustomerAccounts) : strings.allCustomersLinked
							}
							variantX={data.countUnprocessedCustomerAccounts > 0 ? 'warning' : 'success'}
							onClick={onGotoExactOnline}
							spacing={3}
						/>
						<VariantLabelledProperty
							property={data.countUnprocessedSales > 0 ? strings.countSalesNotSynced(data.countUnprocessedSales) : strings.allSalesSynced}
							variantX={data.countUnprocessedSales > 0 ? 'warning' : 'success'}
							onClick={onGotoExactOnline}
							spacing={3}
						/>
					</>
				</div>
			</CardContent>
		</Card>
	);
};
