import { Typography, useTheme } from '@material-ui/core';
import { ColorX } from 'framework/components/color/ColorX';
import { formatDate } from 'framework/utils/date/formatDate';
import React from 'react';
import { StepX } from './StepX';
import CheckIcon from '@material-ui/icons/Check';

interface IProps {
	doneText: string;
	todoText: string;
	date: Date | undefined | null;
	isActive?: boolean;
	isCancelled?: boolean;
	isLast?: boolean;
	children?: React.ReactNode;
}

export const ProcessStepX = ({ doneText, todoText, date, isActive, isCancelled, isLast, children }: IProps) => {
	const theme = useTheme();
	const isDone = React.useMemo(() => date !== undefined && date !== null, [date]);
	const status = React.useMemo<'active' | 'done' | 'todo'>(
		() => (isDone ? 'done' : isActive === true && isCancelled !== true ? 'active' : 'todo'),
		[isDone, isActive, isCancelled]
	);
	const color = React.useMemo<ColorX>(() => (isCancelled ? 'default' : status === 'active' ? 'secondary' : 'primary'), [isCancelled, status]);

	return (
		<StepX
			status={status}
			color={color}
			isLast={isLast}>
			<span style={{ fontWeight: 500 }}>{isDone ? doneText : todoText}</span>
			{isDone && (
				<div className='df-row-ac'>
					<Typography
						variant='caption'
						style={{ marginRight: 4 }}>
						{formatDate(date)}
					</Typography>
					<CheckIcon style={{ fontSize: 16, color: theme.palette.success.main }} />
				</div>
			)}
			{children}
		</StepX>
	);
};
