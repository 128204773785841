import React from 'react';
import { QueryProvider } from './QueryProvider';
import { ViewProvider } from './ViewProvider';
import { ViewProvider as StockItemsViewProvider } from '../detail/stockItems/context/ViewProvider';

export const StockEntriesProviders = ({ children }: any) => {
	return (
		<QueryProvider>
			<ViewProvider>
				<StockItemsViewProvider>{children}</StockItemsViewProvider>
			</ViewProvider>
		</QueryProvider>
	);
};
