import { Collapse, List } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { theme } from '../../theme';
import { NavigationLinkListItemButton } from 'shared/NavigationLinkListItemButton';
import { SecondaryNavLink } from './SecondaryNavLink';
import { PageClaim } from 'gen/ApiClient';

const style: React.CSSProperties = {
	color: theme.palette.text.secondary,
	fontSize: '16px',
};

interface ISubLink {
	route: string;
	text: string;
	pageClaim: PageClaim | undefined;
	hidden?: boolean;
}

interface IProps {
	matchOnRoute: string;
	text: string;
	startIcon: React.ReactNode;
	onClickTitle: VoidFunction;
	// children: any;
	isOpen: boolean;
	subLinks: ISubLink[];
	onNavigate: (route: string) => void;
}

export const NestedNavLink = ({ matchOnRoute, text, startIcon, onClickTitle, isOpen, subLinks, onNavigate }: IProps) => {
	const isMatch = useRouteMatch(matchOnRoute);
	const fSubLinks = React.useMemo(() => subLinks.filter(t => t.hidden !== true), [subLinks]);

	return (
		<>
			<NavigationLinkListItemButton
				onClick={() => onClickTitle()}
				style={{ padding: '0px' }}
				buttonStyle={{
					paddingLeft: '24px',
				}}
				color={isMatch ? 'primary' : 'default'}
				startIcon={startIcon}
				endIcon={isMatch || isOpen ? <ExpandLess style={style} /> : <ExpandMore style={style} />}>
				<div style={{ flexGrow: 1, textAlign: 'left' }}>{text}</div>
			</NavigationLinkListItemButton>
			<Collapse
				in={isOpen || isMatch !== null}
				timeout='auto'
				unmountOnExit>
				<List>
					{fSubLinks.map(t => (
						<SecondaryNavLink
							key={t.route}
							route={t.route}
							text={t.text}
							onNavigate={onNavigate}
							pageClaim={t.pageClaim}
						/>
					))}
				</List>
			</Collapse>
		</>
	);
};
