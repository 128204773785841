import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import EuroIcon from '@material-ui/icons/Euro';
import React, { useContext } from 'react';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { CustomerAccountType, paymentsQuery_single, SalesType, paymentsCommand_updateReferenceDate, paymentsCommand_updateComment } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PaymentMethodStatusChip } from 'shared/PaymentMethodStatusChip';
import { RecordContext } from 'shared/records/RecordContext';
import { PaymentForPurchasesTable } from './PaymentForPurchasesTable';
import { PaymentForSalesTable } from './PaymentForSalesTable';

interface IProps extends DialogProps {
	id: string;
	close: VoidFunction;
}

export const PaymentDialog = ({ id, close, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [payment, reload] = useApiEffect(paymentsQuery_single, id);
	const { saleTypeRecord, customerAccountTypeRecord } = useContext(RecordContext);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);

	if (payment === undefined) {
		return <div></div>;
	}

	const onReferenceDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				formTitle={strings.changeWhat(strings.referenceDate)}
				label={strings.referenceDate}
				initialValue={payment.paymentDate}
				submitFunction={date => paymentsCommand_updateReferenceDate(payment.id, date)}
			/>
		);
	};

	const onEditComment = () => {
		open(
			<StringModelForm
				open
				initialValue={payment.comment}
				confirm={confirm}
				cancel={cancel}
				submitFunction={val => paymentsCommand_updateComment(payment.id, val)}
				formTitle={strings.changeWhat(strings.remark)}
				submitText={strings.update}
				label={strings.remark}
				multiline
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			fullWidth
			scroll='paper'
			open={rest.open && isStacked === false}>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<EuroIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{payment.reference}
					</Typography>
					<PaymentMethodStatusChip
						label={payment.paymentMethodIdentifier ?? ''}
						style={{ marginBottom: theme.spacing(1) }}
					/>
					<LabelledProperty
						label={strings.date}
						property={formatDate(payment.created)}
						spacing={2}
					/>
					<LabelledProperty
						label={strings.referenceDate}
						property={formatDate(payment.paymentDate)}
						spacing={2}
						edit={onReferenceDate}
					/>
					<LabelledProperty
						label={strings.amountAsValue}
						property={formatCurrency(payment.amountPaid)}
						spacing={2}
					/>
					<LabelledProperty
						label={`${strings.type} ${strings.sale}`}
						property={saleTypeRecord[payment.salesType as SalesType]}
						spacing={2}
					/>
					<LabelledProperty
						label={strings.remark}
						property={payment.comment}
						spacing={2}
						edit={onEditComment}
					/>
				</DialogGridTitle>
				{payment.isLinkedToASale && (
					<>
						{payment.hasALink && (
							<>
								<GridDividerWithCaption caption={strings.data} />
								{payment.isCashSale === false && (
									<>
										<PropertyInGrid
											label={strings.type}
											value={customerAccountTypeRecord[payment.customerAccountType as CustomerAccountType]}
										/>
										<PropertyInGrid
											label={strings.accountReceivable}
											value={payment.customerAccountIdentifier}
										/>
									</>
								)}
								{payment.isCashSale === true && (
									<PropertyInGrid
										label={strings.patient}
										value={payment.cashSaleForPatientIdentifier}
									/>
								)}
							</>
						)}
						<GridDividerWithCaption caption={strings.details} />
						{payment.isMultipleLines && <PaymentForSalesTable payment={payment} />}
						{payment.isSingleLine && (
							<>
								<PropertyInGrid
									label={strings.salesDate}
									value={formatDate(payment.singleLine.date)}
								/>
								<PropertyInGrid
									label={strings.reference}
									value={payment.singleLine.saleOrPurchaseReference}
								/>
								<PropertyInGrid
									label={strings.withRespectToShort}
									value={payment.singleLine.recipient}
								/>
							</>
						)}
					</>
				)}
				{payment.isLinkedToAPurchase && (
					<>
						<GridDividerWithCaption caption={strings.details} />
						<PropertyInGrid
							label={strings.accountsPayable}
							value={payment.supplierIdentifier}
						/>
						{payment.isMultipleLines && <PaymentForPurchasesTable payment={payment} />}
						{payment.isSingleLine && (
							<>
								<PropertyInGrid
									label={strings.purchaseDate}
									value={formatDate(payment.singleLine.date)}
								/>
								<PropertyInGrid
									label={strings.purchaseReference}
									value={payment.singleLine.saleOrPurchaseReference}
								/>
							</>
						)}
					</>
				)}
			</DialogContentGrid>
		</Dialog>
	);
};
