import { DialogProps } from '@material-ui/core';
import React from 'react';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { ISupplier, suppliersCommand_patchVatNumber } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	supplier: ISupplier;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const SupplierVatNumberForm = ({ supplier, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<StringModelForm
			label={strings.vatNumber}
			formTitle={strings.changeWhat(strings.vatNumber)}
			notifyMessage={strings.supplierUpdated}
			submitFunction={val => suppliersCommand_patchVatNumber({ supplierId: supplier.id, vatNumber: val })}
			initialValue={supplier.vatNumber ?? ''}
			submitText={strings.update}
			{...rest}
		/>
	);
};
