import { getUnixTime } from 'date-fns';
import { useContext } from 'react';
import { PreloadContext } from 'shared/context/PreloadContext';

interface IProps {
	date?: Date;
	patientRecordId?: string;
}

export const useRosaRedirect = () => {
	const { rosaUrl } = useContext(PreloadContext);

	return (props?: IProps) => {
		const url = calculateUrl(rosaUrl, props);
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		if (newWindow) {
			newWindow.opener = null;
		}
	};
};

const calculateUrl = (rosaUrl: string, props?: IProps) => {
	if (props?.patientRecordId) {
		return `${rosaUrl}?patientId=${props.patientRecordId}`;
	} else if (props?.date) {
		return `${rosaUrl}?date=${getUnixTime(new Date(props.date)) * 1000}`;
	} else {
		return rosaUrl;
	}
};
