import React from 'react';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { IHeader } from 'framework/table/IHeader';
import { DetailsNavigateButton } from '../DetailsNavigateButton';

export const createDetailsNavigateHeader = <T extends { id: string | undefined }>(
	calcRoute: (id: string) => string,
	onlyShowOnHover: boolean = false
): IHeader<T> => {
	return createEmptyHeader<T>('details-header', t => <DetailsNavigateButton route={calcRoute(t.id ?? '')} />, 'right', onlyShowOnHover);
};
