import { CancelledProcessStepX } from 'framework/components/stepper/CancelledProcessStepX';
import { ProcessStepX } from 'framework/components/stepper/ProcessStepX';
import { VerticalStepperX } from 'framework/components/stepper/VerticalStepperX';
import { ILoan } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: ILoan;
	style?: React.CSSProperties;
}

export const LoanDetailProcessComponent = ({ item, style }: IProps) => {
	const strings = useLocalization();

	return (
		<VerticalStepperX style={style}>
			<ProcessStepX
				doneText={strings.started}
				todoText={strings.start}
				date={item.startDate}
				isCancelled={item.isCancelled}
				isActive={false}
			/>
			<ProcessStepX
				doneText={strings.ended}
				todoText={strings.end}
				date={item.actualStopDate}
				isCancelled={item.isCancelled}
				isActive
				isLast={item.isCancelled === false}
			/>
			{item.isCancelled && <CancelledProcessStepX />}
		</VerticalStepperX>
	);
};
