import { addMinutes, intervalToDuration } from 'date-fns';
import { IOption } from '../../../IOption';
import { formatDateX } from '../../../utils/date/formatDateX';

const toOption = (calc: Date, start: Date): IOption<string> => {
	const y = formatDateX(calc, 'HH:mm');
	return { id: y, identifier: `${y} (${toIdentifier(calc, start)})` };
};

const toIdentifier = (calc: Date, start: Date): string => {
	const dur = intervalToDuration({ start: start, end: calc });
	if (dur.hours === 0) {
		return `${dur.minutes} min`;
	} else {
		return `${dur.hours}${dur.minutes !== 0 ? ',5' : ''} u`;
	}
};

const isValid = (calc: Date, start: Date): boolean => {
	return calc.getDay() === start.getDay();
};

const addIfValid = (input: IOption<string>[], calc: Date, start: Date): IOption<string>[] => {
	if (isValid(calc, start)) {
		return [...input, toOption(calc, start)];
	} else {
		return input;
	}
};

export function generateDurationOptions(start: Date): IOption<string>[] {
	let x: IOption<string>[] = [];
	x = addIfValid(x, addMinutes(start, 15), start);
	x = addIfValid(x, addMinutes(start, 30), start);
	x = addIfValid(x, addMinutes(start, 45), start);
	let calc = addMinutes(start, 60);
	while (isValid(calc, start)) {
		x.push(toOption(calc, start));
		//  [...x, toOption(calc, start)];
		calc = addMinutes(calc, 30);
	}
	return x;
}
