import { DialogContent, DialogProps } from '@material-ui/core';
import { FormSelectOrCreateCompanyFieldWithSelector } from 'app/main/contacts/companies/forms/FormSelectOrCreateCompanyFieldWithSelector';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setLineErrors } from 'framework/forms/utils/setLineErrors';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	IRequestForInsuranceAllowance,
	IRfiaAdvanceInvoiceModel,
	salesCommand_newRfiaAdvanceInvoice,
	salesQuery_nextInvoiceReference,
	salesQuery_settings,
	ValidUntilTimeUnits,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { FormRecipientField } from 'shared/forms/FormRecipientField';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useWithRecipientInterceptor } from 'shared/searchAndSelect/useWithRecipientInterceptor';
import * as yup from 'yup';
import { useCurrentLocation } from '../../../../settings/locations/useCurrentLocation';
import { SearchProvider } from '../invoice/context/SearchProvider';
import { SaleFormConfirmation } from '../SaleFormConfirmation';
import { ILine } from './ILine';
import { SearchAndSelectProductsForAdvanceInvoice } from './SearchAndSelectProductsForAdvanceInvoice';
import { lineToModel } from './utils/lineToModel';

const createSchema = (strings: IStrings, mustSelectCompany: boolean) => {
	return yup
		.object<IRfiaAdvanceInvoiceModel>({
			validUntil: yup.date().required(),
			lines: yup.mixed(),
			reference: yup.string().required(),
			isAutoSelectReference: yup.boolean().defined(),
			zip: yup.string(),
			addressLine: yup.string(),
			city: yup.string(),
			companyId: mustSelectCompany ? yup.string().required() : yup.string(),
			country: yup.string(),
			recipientName: yup.string().required(),
		})
		.defined();
};

const emptyValues: IRfiaAdvanceInvoiceModel = {
	lines: [],
	reference: '',
	isAutoSelectReference: true,
	city: '',
	companyId: '',
	addressLine: '',
	zip: '',
	country: '',
	recipientName: '',
	validUntil: undefined as any,
};

const stepsRecord: Record<number, (keyof IRfiaAdvanceInvoiceModel)[]> = {
	0: ['reference', 'isAutoSelectReference', 'validUntil'],
	1: ['companyId'],
	2: ['recipientName', 'addressLine', 'city', 'zip', 'country'],
	3: ['lines'],
	4: [],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	rfia: IRequestForInsuranceAllowance;
}

export const RfiaAdvanceInvoiceForm = ({ confirm, cancel, rfia, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, setLines] = useState<ILine[]>([]);
	const [step, setStep] = useState<number>(0);
	const [isSelectCompany, setIsSelectCompany] = useState<boolean>(false);
	const schema = useMemo(() => createSchema(strings, isSelectCompany), [strings, isSelectCompany]);
	const [create, isSubmitting] = useFormSubmit(salesCommand_newRfiaAdvanceInvoice);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IRfiaAdvanceInvoiceModel, helpers: FormikHelpers<IRfiaAdvanceInvoiceModel>) => {
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await create(rfia.id, { ...values, lines: lines.map((t, index) => lineToModel(t, index)) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify('Voorschotfactuur geregistreerd');
				confirm(r.result.objectId);
			} else {
				setLineErrors('lines', r, lines, setLines);
			}
		}
	};

	return (
		<Formik<IRfiaAdvanceInvoiceModel>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						lines={lines}
						setLines={setLines}
						step={step}
						setStep={setStep}
						stepsRecord={stepsRecord}
						schema={schema}
						isSelectCompany={isSelectCompany}
						setIsSelectCompany={setIsSelectCompany}
						patientId={rfia.patientId}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	lines: ILine[];
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof IRfiaAdvanceInvoiceModel)[]>;
	schema: yup.ObjectSchema<IRfiaAdvanceInvoiceModel>;
	isSelectCompany: boolean;
	setIsSelectCompany: React.Dispatch<React.SetStateAction<boolean>>;
	patientId: string;
}

export const InnerDialog = ({
	cancel,
	lines,
	setLines,
	isSubmitting,
	step,
	setStep,
	stepsRecord,
	schema,
	isSelectCompany,
	setIsSelectCompany,
	patientId,
	...rest
}: IInnerProps) => {
	const props = useFormikContext<IRfiaAdvanceInvoiceModel>();
	const strings = useLocalization();
	useNextReferenceInterceptor(salesQuery_nextInvoiceReference, props);
	const [settings] = useApiEffect(salesQuery_settings);
	const location = useCurrentLocation();
	const [isUseContactPerson, setIsUseContactPerson] = useState<boolean>(false);
	useWithRecipientInterceptor<IRfiaAdvanceInvoiceModel>(props, isSelectCompany, isUseContactPerson, patientId);

	if (location === undefined || settings === undefined) {
		return <div></div>;
	}

	return (
		<FullScreenDialogWithStepper
			{...rest}
			fullScreenStep={3}
			title='Nieuwe voorschotfactuur'
			step={step}
			labels={[strings.referenceShort, strings.vatCustomer, strings.withRespectToShort, strings.items, strings.confirmation]}>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IRfiaAdvanceInvoiceModel>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IRfiaAdvanceInvoiceModel>
							name='reference'
							label={strings.invoiceNumber}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormDatePickerWithUnit<IRfiaAdvanceInvoiceModel>
							name='validUntil'
							label={strings.validTo}
							required
							units={ValidUntilTimeUnits}
							defaultUnit='Months'
							defaultValue={settings.defaultValidUntil}
						/>
					</>
				)}
				{step === 1 && (
					<FormSelectOrCreateCompanyFieldWithSelector<IRfiaAdvanceInvoiceModel>
						isSelectCompany={isSelectCompany}
						setIsSelectCompany={setIsSelectCompany}
						patientId={patientId}
					/>
				)}
				{step === 2 && (
					<FormRecipientField<IRfiaAdvanceInvoiceModel>
						patientId={patientId}
						isUseContactPerson={isUseContactPerson}
						setIsUseContactPerson={setIsUseContactPerson}
					/>
				)}
				{step === 3 && (
					<SearchAndSelectProductsForAdvanceInvoice
						lines={lines}
						setLines={setLines}
						vatPercentage={6}
					/>
				)}
				{step === 4 && (
					<SaleFormConfirmation
						lines={lines}
						reference={props.values.reference}
						recipientName={props.values.recipientName}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</FullScreenDialogWithStepper>
	);
};
