import { Dialog, DialogContent, DialogProps, DialogTitle, Typography, useTheme } from '@material-ui/core';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { IFile } from 'framework/utils/IFile';
import { readFile } from 'framework/utils/readFile';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { FileType } from './FileType';
import { UploadFile } from './UploadFile';

interface IProps extends DialogProps {
	title: string;
	confirm: (files: IFile[]) => void;
	confirmF?: (files: IFile[]) => Promise<void>;
	cancel: VoidFunction;
	isUploading: boolean;
	caption?: string;
	accept: FileType;
	previous?: VoidFunction;
}

export const UploadFilesDialog = ({ title, confirm, confirmF, cancel, isUploading, caption, accept, previous, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const onUpload = async (files: File[]) => {
		let readFiles: IFile[] = [];

		for (const file of files) {
			const x = await readFile(file);
			readFiles.push(x);
		}

		if (confirmF) {
			setIsSubmitting(true);
			await confirmF(readFiles);
			setIsSubmitting(false);
		}
		confirm(readFiles);
	};

	return (
		<Dialog
			fullWidth
			{...rest}>
			<DialogTitle>{title}</DialogTitle>
			{caption && (
				<Typography
					variant='caption'
					style={{ whiteSpace: 'pre-line', paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3), paddingBottom: theme.spacing(2) }}>
					{caption}
				</Typography>
			)}
			<DialogContent dividers>
				<UploadFile
					isUploading={isUploading || isSubmitting}
					onFilesSelected={onUpload}
					accept={accept}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				previous={previous}
				cancel={cancel}
				submitText={strings.upload}
				isValid={false}
				onSubmit={() => {}}
				isSubmitting={isUploading}
				hideSubmitButton
			/>
		</Dialog>
	);
};
