import { CardProps } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import React from 'react';

export const RosaAdminInstructionsCard = (props: CardProps) => {
	return (
		<CardX
			{...props}
			className='df-col gap-8'
			style={{ whiteSpace: 'pre' }}>
			<Typography16pxBold>{`Instructions (only visible for super-users!)`}</Typography16pxBold>
			<span>{`1. Register ROSA integration (need bearer token), DO NOT enable integration yet.`}</span>
			<span>{`2. Sync Motives/Calendars/Sites`}</span>
			<span>{`3. Pull patients\nWill create the ROSA patient records in HAMS which need to be linked by a HAMS user.\nSubsequently pull existing events from ROSA -> HAMS.`}</span>
			<span>{`4. Push patients\nThis will push all existing patients from HAMS to ROSA\nRosa patient records will automatically be created.`}</span>
			<span>{`5. IMPORTANT: PATCH the sync record on NOW!`}</span>
			<span>{`6. Enable the integration`}</span>
		</CardX>
	);
};
