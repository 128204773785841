import { Try } from '../../Try';
import { IValidateResponse } from 'gen/ApiClient';
import { FormikHelpers } from 'formik';

export const handleValidateResponse = <TModel extends unknown>(r: Try<IValidateResponse>, helpers: FormikHelpers<TModel>, key: keyof TModel) => {
	if (r.isSuccess) {
		if (r.result.hasError) {
			helpers.setFieldError(key as string, r.result.error);
			return false;
		} else {
			return true;
		}
	} else {
		return false;
	}
};
