import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectFieldOnId } from 'framework/forms/FormSelectFieldOnId';
import { formatDate } from 'framework/utils/date/formatDate';
import { IRequestForInsuranceAllowance, ITenderSummary } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

interface IModel {
	id: string;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		id: yup.string().required(strings.formRequired('proef')),
	});
};

interface IProps extends DialogProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: (tenderId: string) => void;
	cancel: VoidFunction;
}

export const SelectTenderForm = ({ rfia, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();

	const handleSubmit = (values: IModel, helpers: FormikHelpers<IModel>) => {
		confirm(values.id);
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{ id: '' }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					fullWidth
					maxWidth='sm'>
					<DialogTitle>{strings.selectWhat(strings.tender)}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormSelectFieldOnId<IModel, ITenderSummary>
							name='id'
							label={strings.tender}
							options={rfia.invoiceProcess.tenders}
							renderValue={t => `${t.reference} (${formatDate(t.date)})`}
							getKey={t => t.id}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={false}
						submitText={strings.select}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
