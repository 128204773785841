import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { IValidateResponse } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';
import { useFormSubmit } from '../hooks/useFormSubmit';
import { useSnackbarNotify } from '../hooks/useSnackbarNotify';
import { Try } from '../Try';
import { CancelSubmitFormDialogActions } from './CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from './FormExtendedRadioGroupField';
import { handleValidateResponseBase } from './utils/handleValidateResponseBase';

interface IModel {
	value: boolean;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			value: yup.boolean().defined(),
		})
		.defined();
};

interface IProps extends DialogProps {
	formTitle: string;
	notifyMessage?: string;
	initialValue: boolean;
	confirm: VoidFunction;
	cancel: VoidFunction;
	submitFunction: (value: boolean) => Promise<Try<IValidateResponse>>;
	submitText: string;
	cancelText?: string;
	trueLabel: string;
	falseLabel: string;
	trueCaption?: string;
	falseCaption?: string;
}

export const BoolExtendedRadioGroupForm = ({
	confirm,
	cancel,
	formTitle,
	notifyMessage,
	initialValue,
	submitFunction,
	submitText,
	cancelText,
	trueLabel,
	falseLabel,
	trueCaption,
	falseCaption,
	...rest
}: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(submitFunction);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IModel) => {
		const r = await submit(values.value);
		handleValidateResponseBase(
			r,
			e => notify(e, 'error'),
			() => {
				if (notifyMessage) {
					notify(notifyMessage, 'success');
				}
				confirm();
			}
		);
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{ value: initialValue }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					scroll='paper'
					fullWidth
					{...rest}>
					<DialogTitle>{formTitle}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormExtendedRadioGroupField<IModel, boolean>
							name='value'
							label='x'
							options={[
								{ value: true, label: trueLabel, caption: trueCaption },
								{ value: false, label: falseLabel, caption: falseCaption },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={submitText ?? strings.select}
						cancelText={cancelText}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
