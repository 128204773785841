import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TimelineIcon from '@material-ui/icons/Timeline';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { LabelledChipsList } from 'framework/components/chips/LabelledChipsList';
import { SerialNumberIcon } from 'framework/components/icons/SerialNumberIcon';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { IProductCatalogItemStats, IProductCatalogItemWithStatsAndStockInfo } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { ProductCatalogBreadcrumbs } from '../ProductCatalogBreadcrumbs';
import { ProductCatalogChips } from '../ProductCatalogChips';
import { ProductCatalogItemDetailTabType, ProductCatalogItemDetailTabTypes } from './ProductCatalogItemDetailTabType';

const TabTypeIconRecord: Record<ProductCatalogItemDetailTabType, React.ReactNode> = {
	overview: <DashboardIcon />,
	transactions: <TimelineIcon />,
	comments: <CommentOutlinedIcon />,
	inventoryItems: <SerialNumberIcon />,
};

const createTabRecord = (strings: IStrings): Record<ProductCatalogItemDetailTabType, string> => {
	return {
		overview: strings.overview,
		transactions: strings.transactions,
		comments: strings.remarks,
		inventoryItems: strings.inventoryItems,
	};
};

const createEndIconRecord = (stats: IProductCatalogItemStats): Record<ProductCatalogItemDetailTabType, React.ReactNode | undefined> => {
	return {
		comments: stats.countComments > 0 && (
			<BadgeChipX
				value={stats.countComments}
				color='error'
			/>
		),
		overview: undefined,
		transactions: stats.countTransactions > 0 && (
			<BadgeChipX
				value={stats.countTransactions}
				color='default'
			/>
		),
		inventoryItems: stats.countInventoryItems > 0 && (
			<BadgeChipX
				value={stats.countInventoryItems}
				color='default'
			/>
		),
	};
};

interface IProps {
	tab: ProductCatalogItemDetailTabType;
	setTab: (tab: ProductCatalogItemDetailTabType) => void;
	dto: IProductCatalogItemWithStatsAndStockInfo;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const ProductCatalogItemDetailPageHeader = ({ tab, setTab, dto, reload, style }: IProps) => {
	const strings = useLocalization();
	const tabRecord = useMemo(() => createTabRecord(strings), [strings]);
	const endIconRecord = useMemo(() => createEndIconRecord(dto.stats), [dto]);

	return (
		<div
			className='df-col fg1'
			style={style}>
			<div className='df-row-ac'>
				<ProductCatalogBreadcrumbs />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={dto.item.identifier} />
				<ProductCatalogChips
					item={dto.item}
					style={{ marginLeft: 4 }}
				/>
				<LabelledChipsList labels={dto.item.labels} />
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<HorizontalTabsX
				tabs={ProductCatalogItemDetailTabTypes}
				selectedTab={tab}
				setSelectedTab={setTab}
				titleRecord={tabRecord}
				startIconRecord={TabTypeIconRecord}
				endIconRecord={endIconRecord}
				style={{
					marginTop: 16,
					marginLeft: -4,
				}}
			/>
		</div>
	);
};
