import React from 'react';
import { GeneralPractitionersCacheProvider } from './GeneralPractitionersCacheProvider';
import { GeneralPractitionersFilterDataProvider } from './GeneralPractitionersFilterDataProvider';
import { GeneralPractitionersQueryProvider } from './GeneralPractitionersQueryProvider';

export const GeneralPractitionersProviders = ({ children }: any) => {
	return (
		<GeneralPractitionersCacheProvider>
			<GeneralPractitionersFilterDataProvider>
				<GeneralPractitionersQueryProvider>{children}</GeneralPractitionersQueryProvider>
			</GeneralPractitionersFilterDataProvider>
		</GeneralPractitionersCacheProvider>
	);
};
