import { Theme, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
	iconStyle: {
		'& > svg': {
			fontSize: '1.25rem',
			opacity: 0.8,
			height: '1.25rem',
		},
	},
}));

interface IProps {
	icon: React.ReactNode;
	style?: React.CSSProperties;
}

export const PageBreadcrumbIcon = ({ icon, style }: IProps) => {
	const theme = useTheme();
	const styles = useStyles(theme);

	return (
		<div
			className={styles.iconStyle}
			style={{ display: 'flex', ...style }}>
			{icon}
		</div>
	);
};
