import { useTheme } from '@material-ui/core';
import BlurCircularOutlinedIcon from '@material-ui/icons/BlurCircularOutlined';
import { calcInventoryItemDetailRoute, calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ILoan, ILoanLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	line: ILoanLine;
	loan: ILoan;
	withoutBorder?: boolean;
}

export const LoanLineListItem = ({ line, loan, withoutBorder }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { push } = useHistory();
	const tColor = useMemo(() => (loan.hasReturnedItems ? theme.palette.text.secondary : theme.palette.success.main), [theme, loan]);

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				{loan.hasReturnedItems && <RotateLeftOutlinedIcon style={{ fontSize: 22, color: tColor }} />}
				{loan.hasReturnedItems === false && <BlurCircularOutlinedIcon style={{ fontSize: 22, color: tColor }} />}
			</div>
			<div className='df-col fg1'>
				<TextLinkButton
					onClick={() => {
						if (line.inventoryItemId) {
							push(calcInventoryItemDetailRoute(line.inventoryItemId));
						} else {
							push(calcProductCatalogDetailRoute(line.productCatalogItemId));
						}
					}}
					style={{ fontSize: MajorFontSize }}>
					{`${line.quantity} x ${line.description}`}
				</TextLinkButton>
				<span style={{ fontSize: MinorFontSize }}>{`${strings.priceInVat}: ${formatCurrency(line.lineListPriceInVat)}`}</span>
			</div>
		</div>
	);
};
