import { Grid } from '@material-ui/core';
import React from 'react';
import { DividerWithCaption } from '../DividerWithCaption';

interface IProps {
	caption: string;
	style?: React.CSSProperties;
	captionStyle?: React.CSSProperties;
	action?: React.ReactNode;
}

export const GridDividerWithCaption = ({ style, ...rest }: IProps) => {
	return (
		<Grid
			item
			xs={12}
			style={style}>
			<DividerWithCaption {...rest} />
		</Grid>
	);
};
