import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useUsersAsFilterOptions } from 'app/main/settings/users/useUsersAsFilterOptions';
import { IOption } from 'framework/IOption';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ICommissionsQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { CommissionRulesCacheContext } from '../context/CommissionRulesCacheContext';

interface IProps {
	params: ICommissionsQueryParams;
	onFilter: (params: ICommissionsQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof ICommissionsQueryParams)[] = ['isPaidOut'];

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const [rules] = usePreloadCacheContext(CommissionRulesCacheContext);
	const filterUsers = useUsersAsFilterOptions();
	const filterRules = useMemo(() => rules?.all.map<IOption<string>>(t => ({ id: t.id, identifier: `${t.name} (${t.percentage} %)` })), [rules]);

	if (filterUsers === undefined || filterRules === undefined) {
		return <div></div>;
	}

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.dateCreated}
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<ListFilterComponent<string>
				options={filterUsers}
				selected={params.userIds}
				setSelected={vals => onFilter({ ...params, userIds: vals })}
				label={strings.user}
				icon={<HelpOutlineIcon />}
				title={strings.user}
			/>
			<ListFilterComponent<string>
				options={filterRules}
				selected={params.commissionRuleIds}
				setSelected={vals => onFilter({ ...params, commissionRuleIds: vals })}
				label={strings.rule}
				icon={<HelpOutlineIcon />}
				title={strings.rule}
			/>
		</FilterBar>
	);
};
