import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IEnterOAuthOptionsRequest, exactOnlineSettingsCommand_enterOAuthOptions } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IEnterOAuthOptionsRequest>({
		clientId: yup.string().required(),
		clientSecret: yup.string().required(),
		redirectUri: yup.string().required(),
		endpoint: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	confirm: () => void;
	cancel: VoidFunction;
}

export const EnterOAuthOptionsRequestForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(exactOnlineSettingsCommand_enterOAuthOptions);
	const notify = useSnackbarNotify();
	const schema = useMemo(() => createSchema(strings), [strings]);

	const handleSubmit = async (values: IEnterOAuthOptionsRequest, helpers: FormikHelpers<IEnterOAuthOptionsRequest>) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			notify(`oauth options set`);
			confirm();
		}
	};

	return (
		<Formik<IEnterOAuthOptionsRequest>
			validateOnMount
			initialValues={{
				clientId: 'b0a5628d-fd09-4c72-8157-0ce4ec78f144',
				clientSecret: '',
				redirectUri: 'https://eo-redirect.hams.be/api/exact-online/auth',
				endpoint: 'api/oauth2',
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog {...rest}>
					<DialogTitle>{`Enter OAuth Options`}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'
						style={{ minWidth: 400 }}>
						<FormTextField<IEnterOAuthOptionsRequest>
							name='clientId'
							label={`Client ID`}
							required
						/>
						<FormTextField<IEnterOAuthOptionsRequest>
							name='clientSecret'
							label={`Client Secret`}
							required
						/>
						<FormTextField<IEnterOAuthOptionsRequest>
							name='redirectUri'
							label={`Redirect URI`}
							required
						/>
						<FormTextField<IEnterOAuthOptionsRequest>
							name='endpoint'
							label={`Endpoint (api/oauth2)`}
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={strings.update}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
