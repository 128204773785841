import { endOfMonth, isEqual, startOfMonth } from 'date-fns';
import { toggleSelectArrayById } from 'framework/toggleSelectArrayById';
import { nlBELocaleEndOfWeek } from 'framework/utils/date/nlBELocaleEndOfWeek';
import { nlBELocaleStartOfWeek } from 'framework/utils/date/nlBELocaleStartOfWeek';
import { RosaCalendarViewType } from 'gen/ApiClient';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getNow } from 'shared/utils/getNow';
import { filterRosaEvents } from './filterRosaEvents';
import { IRosaCalendarWithIsSelected } from './IRosaCalendarWithIsSelected';
import { IRosaHpDtoWithIsSelected } from './IRosaHpDtoWithIsSelected';
import { IRosaMotiveSummaryDtoWithIsSelected } from './IRosaMotiveSummaryDtoWithIsSelected';
import { RosaCalendarContext } from './RosaCalendarContext';
import { RosaCalendarQueryContext } from './RosaCalendarQueryContext';
import { RosaViewPreferencesPreloadCacheContext } from './viewPreferences/RosaViewPreferencesPreloadCacheContext';

export const RosaCalendarProvider = ({ children }: any) => {
	const { rosaViewPreferences } = useContext(RosaViewPreferencesPreloadCacheContext);
	const [date, setDate] = useState<Date>(getNow());
	const { params, setParams, queryResult: response, init, reload } = useContext(RosaCalendarQueryContext);
	const [motives, setMotives] = useState<IRosaMotiveSummaryDtoWithIsSelected[]>([]);
	const [myCalendars, setMyCalendars] = useState<IRosaCalendarWithIsSelected[]>([]);
	const [otherCalendars, setOtherCalendars] = useState<IRosaCalendarWithIsSelected[]>([]);
	const [hps, setHps] = useState<IRosaHpDtoWithIsSelected[]>([]);
	const calendars = useMemo(() => [...myCalendars, ...otherCalendars], [myCalendars, otherCalendars]);
	const [view, setView] = useState<RosaCalendarViewType>(rosaViewPreferences.defaultView as RosaCalendarViewType);
	const events = useMemo(() => filterRosaEvents(response?.events ?? [], motives, calendars), [response, motives, calendars]);

	useEffect(() => {
		if (response) {
			setMotives(current =>
				response.motives
					.filter(t => t.isHidden === false)
					.map<IRosaMotiveSummaryDtoWithIsSelected>(t => ({ ...t, isSelected: current.find(x => x.id === t.id)?.isSelected ?? true }))
			);
			setMyCalendars(current =>
				response.myCalendars.map<IRosaCalendarWithIsSelected>(t => ({ ...t, isSelected: current.find(x => x.id === t.id)?.isSelected ?? true }))
			);
			setOtherCalendars(current =>
				response.otherCalendars.map<IRosaCalendarWithIsSelected>(t => ({ ...t, isSelected: current.find(x => x.id === t.id)?.isSelected ?? true }))
			);
			setHps(current => response.hps.map<IRosaHpDtoWithIsSelected>(t => ({ ...t, isSelected: current.find(x => x.id === t.id)?.isSelected ?? true })));
		}
	}, [response]);

	const onSetDate = (val: Date) => {
		setDate(val);
		const after = nlBELocaleStartOfWeek(startOfMonth(val));
		const before = nlBELocaleEndOfWeek(endOfMonth(val));
		if (isEqual(after, params.after) === false || isEqual(before, params.before) === false) {
			setParams({ before: before, after: after });
		}
	};

	const toggleMotive = (motiveId: string) => {
		setMotives(toggleSelectArrayById(motives, motiveId));
	};

	const toggleCalendar = (calendarId: string) => {
		if (myCalendars.findIndex(t => t.id === calendarId) > -1) {
			setMyCalendars(toggleSelectArrayById(myCalendars, calendarId));
		} else {
			setOtherCalendars(toggleSelectArrayById(otherCalendars, calendarId));
		}
	};

	const toggleHp = (hpId: string) => {
		const isSelected = !hps.find(t => t.id === hpId)!.isSelected;
		setHps(toggleSelectArrayById(hps, hpId));
		setMyCalendars(myCalendars.map(t => ({ ...t, isSelected: t.hpId === hpId ? isSelected : t.isSelected })));
		setOtherCalendars(otherCalendars.map(t => ({ ...t, isSelected: t.hpId === hpId ? isSelected : t.isSelected })));
	};

	return (
		<RosaCalendarContext.Provider
			value={{
				events: events,
				after: params.after,
				before: params.before,
				date: date,
				setDate: onSetDate,
				view: view,
				setView: setView,
				init: init,
				reload: reload,
				motives: motives,
				myCalendars: myCalendars,
				otherCalendars: otherCalendars,
				calendars: calendars,
				toggleMotive: toggleMotive,
				toggleCalendar: toggleCalendar,
				toggleHp: toggleHp,
				hps: hps,
				viewPreferences: rosaViewPreferences,
			}}>
			{children}
		</RosaCalendarContext.Provider>
	);
};
