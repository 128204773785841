import { ChipX } from 'framework/components/chips/ChipX';
import { IHealthInsuranceFundWithAddressDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: IHealthInsuranceFundWithAddressDto;
	style?: React.CSSProperties;
}

export const HifChipX = ({ item, style }: IProps) => {
	const strings = useLocalization();

	if (item.hasMetaData) {
		return (
			<ChipX
				color='warning'
				label={strings.manualAdditions}
				style={{ marginRight: 'auto', ...style }}
			/>
		);
	} else {
		return <></>;
	}
};
