import { SelectableColumn } from '../SelectableColumn';
import { IStrings } from 'localization/IStrings';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		created: strings.createdAt,
		lastUpdated: strings.lastUpdated,
		extension: strings.extension,
		templateName: `${strings.name} ${strings.template.toLowerCase()}`,
		templateType: `${strings.type} ${strings.template.toLowerCase()}`,
		typeOrName: `${strings.type}/${strings.name}`,
	};
};
