import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	healthInsuranceFundMetaQuery_concurrencyToken,
	healthInsuranceFundMetaQuery_query,
	IHealthInsuranceFundWithAddressDto,
	IHealthInsuranceFundWithAddressDtoQueryParams,
	IQueryResult,
} from 'gen/ApiClient';
import { hifsMetaQueryParamsLsKey } from '../../../localStorageKeys';
import { HifsQueryContext } from './HifsQueryContext';

const DefaultParams: IHealthInsuranceFundWithAddressDtoQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'code' },
	pageParams: { index: 1, size: 10 },
	hasAdvisoryPhysicianAddress: undefined,
	hasMetadata: undefined,
	hasThirdPayerArrangementAddress: undefined,
};

export const HifsQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IHealthInsuranceFundWithAddressDto>, IHealthInsuranceFundWithAddressDtoQueryParams>
			defaultParams={DefaultParams}
			queryF={healthInsuranceFundMetaQuery_query}
			concurrencyF={healthInsuranceFundMetaQuery_concurrencyToken}
			localStorageKey={hifsMetaQueryParamsLsKey}
			context={HifsQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
