import { PropTypes } from '@material-ui/core';
import React from 'react';
import { ChipWithAvatar } from './ChipWithAvatar';

interface IProps {
	items: string[];
	color?: Exclude<PropTypes.Color, 'inherit'>;
	size?: 'small' | 'medium';
	style?: React.CSSProperties;
	variant?: 'default' | 'outlined';
	disableAvatar?: boolean;
}

export const ChipWithAvatarList = ({ items, ...rest }: IProps) => {
	return (
		<>
			{items.map(t => (
				<ChipWithAvatar
					key={t}
					label={t}
					{...rest}
				/>
			))}
		</>
	);
};
