import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React, { useMemo } from 'react';
import { PaperOutlined } from 'framework/table/PaperOutlined';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { useLocalization } from 'localization/useLocalization';
import { ICommissionWithSelection } from './ICommissionWithSelection';

interface IProps {
	lines: ICommissionWithSelection[];
	style?: React.CSSProperties;
}

export const TotalsTable = ({ lines, style }: IProps) => {
	const strings = useLocalization();

	const total = useMemo(
		() =>
			lines
				.filter(t => t.isSelected)
				.map(t => t.value)
				.reduce((sum, current) => sum + current, 0),
		[lines]
	);

	return (
		<TableContainer
			style={style}
			component={PaperOutlined}>
			<Table size='small'>
				<TableBody>
					<TableRow>
						<TableCell>{strings.quantity}</TableCell>
						<TableCell style={{ textAlign: 'right' }}>{lines.filter(t => t.isSelected).length}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell style={{ borderBottom: 'none' }}>{strings.totalAmount}</TableCell>
						<TableCell style={{ borderBottom: 'none', textAlign: 'right' }}>{formatCurrency(total)}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};
