import { Color } from '@material-ui/lab';
import React, { useContext } from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { ICustomerAccountLine, PaymentStatus } from 'gen/ApiClient';
import { RecordContext } from 'shared/records/RecordContext';

const toColor = (cal: ICustomerAccountLine): Color => {
	if (cal.isFullyPaid) {
		return 'success';
	} else {
		return 'warning';
	}
};

interface IProps {
	cal: ICustomerAccountLine;
	style?: React.CSSProperties;
}

export const CustomerAccountLineStatusChip = ({ cal, style }: IProps) => {
	const { paymentStatusRecord } = useContext(RecordContext);
	return (
		<RectangularChip
			style={style}
			label={paymentStatusRecord[cal.paymentStatus as PaymentStatus]}
			fill={cal.isFullyPaid}
			color={toColor(cal)}
		/>
	);
};
