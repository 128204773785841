import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import { DocumentDetailDialog } from 'documents/DocumentDetailDialog';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { createHeader } from 'framework/table/createHeader';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IDocument } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SuperUserRestrictedPage } from 'shared/layout/SuperUserRestrictedPage';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsHeader } from 'shared/table/createDetailsHeader';
import { DocumentStatusChip } from './components/DocumentStatusChip';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { SelectableColumn } from './SelectableColumn';

export const DocumentsList = () => {
	const theme = useTheme();
	const strings = useLocalization();
	const { queryResult, params, setParams, reload } = useQueryContext(QueryContext);
	const { headers, columns, setColumns, columnsRecord } = useContext(ViewContext);
	const { open, confirm } = useDialogsContext(reload);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onViewDetail = (doc: IDocument) => {
		open(
			<DocumentDetailDialog
				open
				id={doc.id}
				close={confirm}
			/>
		);
	};

	return (
		<SuperUserRestrictedPage
			showHome
			title={strings.documents}
			reload={reload}>
			<div
				className='df-row-ac jc-sb'
				style={{ marginBottom: '24px' }}>
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder='Zoek documenten...'
					style={{ width: '400px' }}
					variant='paper'
				/>
			</div>
			{queryResult.unfilteredCount > 0 && (
				<Card>
					<div className='df-row jc-sb'>
						<Filter
							params={params}
							onFilter={params => setParams(onResetToPage1(params))}
							paddingLeft={theme.spacing(1.5)}
						/>
						<div
							className='df-row-ac'
							style={{ paddingRight: theme.spacing(1.5) }}>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
								view='documents'
							/>
						</div>
					</div>
					<Divider />
					<CardMedia>
						<PageableTableWithColumnSelection<IDocument, SelectableColumn>
							queryResult={queryResult}
							headers={headers}
							sortParams={params.sortParams}
							pageParams={params.pageParams}
							setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
							getKey={inst => inst.id}
							selectedColumns={columns}
							prependColumn={createHeader<IDocument>(strings.status, t => (
								<DocumentStatusChip item={t} />
							))}
							appendColumn={createDetailsHeader<IDocument>(onViewDetail)}
						/>
					</CardMedia>
				</Card>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound>Geen documenten gevonden</NoRecordsFound>}
		</SuperUserRestrictedPage>
	);
};
