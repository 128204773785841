import { Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { RecordContext } from 'shared/records/RecordContext';
import { PatientRequiredProperty, PatientRequiredPropertys, patientSettingsCommand_patchRequiredProperties, patientsQuery_settings } from 'gen/ApiClient';
import { toggleSelect } from 'framework/utils/array/toggleSelect';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	style?: React.CSSProperties;
}

export const PatientRequiredPropertiesComponent = ({ style }: IProps) => {
	const strings = useLocalization();
	const [settings, reload] = useApiEffect(patientsQuery_settings);
	const { patientRequiredPropertiesRecord } = React.useContext(RecordContext);
	const orderedProps = React.useMemo(() => _.orderBy(PatientRequiredPropertys, t => patientRequiredPropertiesRecord[t]), [patientRequiredPropertiesRecord]);
	const [selectedProps, setSelectedProps] = useState<PatientRequiredProperty[]>([]);
	const [patch, isPatching] = useFormSubmit(patientSettingsCommand_patchRequiredProperties);
	const [isDirty, setIsDirty] = useState<boolean>(false);

	useEffect(() => {
		if (settings) {
			setSelectedProps(settings.requiredProperties as PatientRequiredProperty[]);
		}
	}, [settings]);

	const onSave = async () => {
		await patch({ requiredProperties: selectedProps });
		setIsDirty(false);
		reload();
	};

	return (
		<div
			className='df-col gap-16 w100'
			style={style}>
			<div>{strings.requiredPatientFieldsDescription}</div>
			<List dense>
				{orderedProps.map(t => (
					<ListItem
						key={t}
						dense
						button
						style={{ paddingLeft: 0, paddingRight: 0 }}
						onClick={() => {
							setIsDirty(true);
							setSelectedProps(toggleSelect(t, selectedProps));
						}}>
						<ListItemIcon>
							<Checkbox
								size='small'
								checked={selectedProps.indexOf(t) !== -1}
							/>
						</ListItemIcon>
						<ListItemText primary={patientRequiredPropertiesRecord[t]} />
					</ListItem>
				))}
			</List>
			<Divider />
			<div className='df-row'>
				<div className='fg1'></div>
				<LoaderButton
					startIcon={<SaveOutlinedIcon />}
					isLoading={isPatching}
					onClick={onSave}
					disabled={isDirty === false}
					color='secondary'>
					{strings.saveChanges}
				</LoaderButton>
			</div>
		</div>
	);
};
