import LinkIcon from '@material-ui/icons/Link';
import { LinkUserForm } from 'app/main/settings/users/forms/LinkUserForm';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { IRosaUserToHamsUserLink, rosaLinkCommand_linkHp } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

interface IProps {
	item: IRosaUserToHamsUserLink;
	withoutBorder?: boolean;
	reload: VoidFunction;
}

export const RosaUserToHamsUserLinkListItem = ({ item, withoutBorder, reload }: IProps) => {
	const strings = useLocalization();
	const color = useMemo<ColorX>(() => (item.isLinked ? 'success' : 'error'), [item]);
	const tColor = useThemeColor(color);
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onLink = () => {
		open(
			<LinkUserForm
				open
				initialValue={item.hamsId}
				confirm={confirm}
				cancel={cancel}
				title={`${strings.selectWhat(strings.user)} | ROSA: ${item.rosaName}`}
				submitFunction={userId => rosaLinkCommand_linkHp({ rosaHpId: item.rosaId, hamsUserId: userId })}
			/>
		);
	};

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<StatusIcon
					status={item.isLinked ? 'success' : 'error'}
					style={{ marginRight: 4 }}
				/>
			</div>
			<div className='df-col gap-4 fg1'>
				<CaptionVerticalLabelledPropertyX label={`${strings.name} (ROSA)`}>{item.rosaName}</CaptionVerticalLabelledPropertyX>
				{item.isLinked && <CaptionVerticalLabelledPropertyX label={`${strings.name} (HAMS)`}>{item.hamsName}</CaptionVerticalLabelledPropertyX>}
				<ChipX
					label={item.isLinked ? strings.linked : strings.notLinked}
					color={color}
					style={{ marginRight: 'auto' }}
				/>
				<TextLinkButton
					color='primary'
					startIcon={<LinkIcon />}
					onClick={onLink}>
					{strings.link}
				</TextLinkButton>
			</div>
		</div>
	);
};
