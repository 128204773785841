import React, { useContext, useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { DeliveryTimeUnits, ITimeModel } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { formatTimeModel } from '../timeUnit/formatTimeModel';
import { RecordContext } from '../records/RecordContext';
import { ISupplierWithDefaultDeliveryTime } from 'app/main/contacts/suppliers/ISupplierWithDefaultDeliveryTime';

type Mode = 'from-supplier' | 'manual';

interface IProps<TModel> extends IFormProps<TModel> {
	supplier: ISupplierWithDefaultDeliveryTime;
	defaultDeliveryTime: ITimeModel;
}

export const FormSelectDeliveryTimeFromSupplierOrManualField = <TModel extends unknown>({ defaultDeliveryTime, supplier, ...props }: IProps<TModel>) => {
	const strings = useLocalization();
	const [selector, setSelector] = useState<Mode>('from-supplier');
	const { timeUnitRecord } = useContext(RecordContext);

	return (
		<>
			{supplier !== undefined && supplier.hasDefaultDeliveryTime ? (
				<>
					<ExtendedRadioGroup<Mode>
						selected={selector}
						setSelected={setSelector}
						options={[
							{
								value: 'from-supplier',
								label: strings.selectDefaultDeliveryTimeFromSupplier,
								caption: `${strings.deliveryTime}: ${formatTimeModel(supplier.defaultDeliveryTime, timeUnitRecord)}`,
							},
							{ value: 'manual', label: strings.manualSelectADate },
						]}
					/>
					<FormDatePickerWithUnit<TModel>
						{...props}
						disabled={selector === 'from-supplier'}
						defaultUnit='Weeks'
						units={DeliveryTimeUnits}
						defaultValue={supplier.defaultDeliveryTime}
					/>
				</>
			) : (
				<>
					<FormDatePickerWithUnit<TModel>
						{...props}
						defaultUnit='Weeks'
						units={DeliveryTimeUnits}
						defaultValue={defaultDeliveryTime}
					/>
				</>
			)}
		</>
	);
};
