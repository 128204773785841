import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IMedicalTreatmentFeeDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { IHandoverLine } from './IHandoverLine';

interface IProps {
	lines: IHandoverLine[];
	fees: IMedicalTreatmentFeeDto[];
	style?: React.CSSProperties;
}

export const TotalsTable = ({ lines, fees, style }: IProps) => {
	const strings = useLocalization();

	return (
		<TableContainer style={style}>
			<Table size='small'>
				<TableBody>
					<TableRow>
						<TableCell>{strings.total}</TableCell>
						<TableCell style={{ textAlign: 'right' }}>{formatCurrency(_.sum(lines.map(t => t.unitPrice * t.quantity)))}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{strings.refund}</TableCell>
						<TableCell style={{ textAlign: 'right' }}>{formatCurrency(_.sum(fees.map(t => t.contribution)))}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{strings.partPatient}</TableCell>
						<TableCell style={{ textAlign: 'right' }}>
							{formatCurrency(_.sum(lines.map(t => t.unitPrice * t.quantity)) - _.sum(fees.map(t => t.contribution)))}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};
