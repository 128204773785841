import React from 'react';
import { IQuickFilterContext } from 'framework/IQuickFilterContext';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { CustomerAccountLineExportProp, ICustomerAccountLine } from 'gen/ApiClient';
import { QuickFilter } from '../SalesCard';
import { SelectableColumn } from '../SelectableColumn';

interface IViewContext extends ITableViewContext<SelectableColumn, ICustomerAccountLine>, IQuickFilterContext<QuickFilter> {
	exportRecord: Record<CustomerAccountLineExportProp, string>;
}

export const ViewContext = React.createContext<IViewContext>(undefined as any);
