import { createHeader } from './createHeader';
import { TableCellAlign, IHeader } from './IHeader';

export function createHeaderWithKey<T, TColumnType extends string>(
	record: Record<TColumnType, string>,
	key: TColumnType,
	evaluate: (inst: T) => string | JSX.Element | undefined,
	align?: TableCellAlign,
	cellStyle?: React.CSSProperties
): IHeader<T> {
	return createHeader<T>(record[key], evaluate, align, cellStyle, key);
}
