import { ChipX } from 'framework/components/chips/ChipX';
import { IPurchase } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: IPurchase;
	style?: React.CSSProperties;
}

export const PurchaseCreditChipOrFragment = ({ item, style }: IProps) => {
	const strings = useLocalization();

	return (
		<>
			{item.isFullyCredited && (
				<ChipX
					label={strings.fullyCredited}
					style={style}
					color='info'
				/>
			)}
			{item.isPartlyCredited && (
				<ChipX
					style={style}
					label={strings.statusPartlyCredited}
					color='info'
				/>
			)}
		</>
	);
};
