import { Divider, useTheme } from '@material-ui/core';
import { Searchbar } from 'framework/components/search/Searchbar';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { IDateRange } from 'framework/filter/date/IDateRange';
import { FilterBar } from 'framework/filter/FilterBar';
import { SortableTable } from 'framework/table/SortableTable';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';
import { createHeaders } from './createHeaders';
import { ICustomerAccountLineWithSelection } from './ICustomerAccountLineWithSelection';

interface IProps {
	lines: ICustomerAccountLineWithSelection[];
	setLines: React.Dispatch<React.SetStateAction<ICustomerAccountLineWithSelection[]>>;
	initFilter?: IDateRange;
}

export const CustomerAccountLinesTable = ({ lines, setLines, initFilter }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [search, setSearch] = useState<string>('');
	const [filtered, setFiltered] = useState<ICustomerAccountLineWithSelection[]>(lines);
	const headers = useMemo(() => createHeaders(lines, filtered, setLines, strings), [lines, filtered, setLines, strings]);
	const [params, setParams] = useState<IDateRange>(initFilter ?? { after: undefined, before: undefined });

	useEffect(() => {
		const x = lines
			.filter(t => `${t.salesReference} ${t.salesRecipientName}`.toLowerCase().includes(search.toLowerCase()))
			.filter(t => params.after === undefined || params.after === null || t.salesDate >= params.after)
			.filter(t => params.before === undefined || params.before === null || t.salesDate <= params.before);
		setFiltered(x);
		// eslint-disable-next-line
	}, [search, lines, params]);

	return (
		<>
			<Searchbar
				value={search}
				onSearch={val => setSearch(val)}
				placeholder={`${strings.searchVerb}...`}
				style={{ width: '100%' }}
				variant='embedded'
			/>
			<Divider />
			<FilterBar
				isFiltered={params.after !== undefined || params.before !== undefined}
				onClear={() => setParams({ after: undefined, before: undefined })}>
				<DateFilterItem
					label={strings.date}
					after={params.after === null ? undefined : params.after}
					before={params.before === null ? undefined : params.before}
					setSelected={(after, before) => setParams({ after: after, before: before })}
				/>
			</FilterBar>
			<Divider />
			<SortableTable<ICustomerAccountLineWithSelection>
				padding={theme.spacing(1)}
				headers={headers}
				getKey={t => t.id}
				values={filtered}
				defaultSortParams={{ property: 'salesDate', direction: 'asc' }}
			/>
		</>
	);
};
