import { isAfter, startOfDay } from 'date-fns';
import { formatDateX } from 'framework/utils/date/formatDateX';
import { ICalendarEventDto } from 'gen/ApiClient';
import _ from 'lodash';
import { IEventGroup } from '../IEventGroup';

export const filterAndGroupEvents = (data: ICalendarEventDto[], date: Date): IEventGroup[] => {
	const filtered = data.filter(t => isAfter(new Date(t.startDate), startOfDay(new Date(date))));
	const grouped = _.groupBy(filtered, t => formatDateX(t.startDate));
	const keys = Object.keys(grouped);
	return keys.map<IEventGroup>(t => ({ date: new Date(grouped[t][0].startDate), events: grouped[t] }));
};
