import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { ImportRosaPatientAccordionMode } from './ImportRosaPatientAccordionMode';

interface IProps {
	icon: React.ReactNode;
	title: string;
	children: any;
	mode: ImportRosaPatientAccordionMode;
	selectedMode: ImportRosaPatientAccordionMode;
	setMode: React.Dispatch<React.SetStateAction<ImportRosaPatientAccordionMode>>;
	disabled?: boolean;
	withPadding?: boolean;
}

export const ImportRosaPatientAccordion = ({ icon, title, mode, selectedMode, setMode, children, disabled, withPadding }: IProps) => {
	const theme = useTheme();

	return (
		<Accordion
			expanded={mode === selectedMode}
			onChange={() => setMode(mode)}
			disabled={disabled}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<div className='df-row-ac fg1'>
					{icon}
					<Typography
						style={{
							fontSize: theme.typography.pxToRem(15),
							fontWeight: mode === selectedMode ? 'bold' : 'inherit',
						}}>
						{title}
					</Typography>
				</div>
			</AccordionSummary>
			<AccordionDetails
				className='fg1'
				style={{ paddingLeft: withPadding === true ? 16 : 0, paddingRight: withPadding === true ? 16 : 0 }}>
				{children}
			</AccordionDetails>
		</Accordion>
	);
};
