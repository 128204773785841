import { CosiEndResult } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createCosiEndResultRecord = (strings: IStrings): Record<CosiEndResult, string> => {
	return {
		AlmostAlways: strings.almostAlways,
		AlmostNever: strings.almostNever,
		HalfOfTime: strings.halfOfTime,
		MostOfTime: strings.mostOfTime,
		NotApplicable: strings.notApplicableAbbreviation,
		Sometimes: strings.sometimes,
	};
};
