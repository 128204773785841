import { DialogProps } from '@material-ui/core';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IQueryResult, ISale, ISalesQueryParams, salesCommand_rectifyInvalidAmount, salesCommand_scriptSetAsPaid } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { Mode } from './Mode';
import { ModeDialog } from './ModeDialog';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	queryResult: IQueryResult<ISale>;
	params: ISalesQueryParams;
}

export const SalesScriptsDialog = ({ confirm, cancel, queryResult, params, ...rest }: IProps) => {
	const strings = useLocalization();
	const [state, setState] = useState<'mode' | Mode>('mode');

	if (state === 'mode') {
		return (
			<ModeDialog
				{...rest}
				confirm={setState}
				cancel={cancel}
			/>
		);
	} else if (state === 'setAsPaid') {
		return (
			<AlertDialog
				open
				title={'Execute script (set sales as paid)'}
				content={`Mark ${queryResult.filteredCount.toString()} sales as paid?\nOnly sales with outstanding balance will be handled!`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => salesCommand_scriptSetAsPaid(params)}
				accept={confirm}
			/>
		);
	} else if (state === 'rectifyInvalidAmount') {
		return (
			<AlertDialog
				open
				title={'Execute script (rectify invalid amount)'}
				content={`Rectify ${queryResult.filteredCount.toString()} sales?\nOnly sales with invalid amount will be handled!`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => salesCommand_rectifyInvalidAmount(params)}
				accept={confirm}
			/>
		);
	} else {
		return <div></div>;
	}
};
