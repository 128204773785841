import { Card, CardContent, useTheme } from '@material-ui/core';
import CakeIcon from '@material-ui/icons/Cake';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { useFindUser } from 'app/main/settings/users/useFindUser';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { NumericIcon } from 'framework/components/icons/NumericIcon';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { formatDate } from 'framework/utils/date/formatDate';
import { IPatient, IRequestForInsuranceAllowance } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { LabelsComponent } from 'shared/LabelsComponent';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { HealthInsuranceFundCardTitleTypography } from '../../../patients/components/HealthInsuranceFundCardTitleTypography';
import { HealthInsuranceFundDisplay } from '../../../patients/components/HealthInsuranceFundDisplay';
import { LinkAudiologistWithRfiaForm } from '../forms/LinkAudiologistWithRfiaForm';
import { LinkEntPhysicianOfficeWithRfiaForm } from '../forms/LinkEntPhysicianOfficeWithRfiaForm';
import { LinkEntPhysicianWithRfiaForm } from '../forms/LinkEntPhysicianWithRfiaForm';
import { LinkHifForm } from '../forms/LinkHifForm';
import { LinkLocationWithRfiaForm } from '../forms/LinkLocationWithRfiaForm';
import { RfiaLabelsForm } from '../forms/RfiaLabelsForm';
import { ThirdPartySchemeForm } from '../forms/ThirdPartySchemeForm';
import { LumpSumMedicalTreatmentWizardForm } from '../forms/medicalTreatmentNomenclatures/LumpSumMedicalTreatmentWizardForm';
import { MainAndPseudoMedicalTreatmentWizardForm } from '../forms/medicalTreatmentNomenclatures/MainAndPseudoMedicalTreatmentWizardForm';
import { PseudoMedicalTreatmentWizardForm } from '../forms/medicalTreatmentNomenclatures/PseudoMedicalTreatmentWizardForm';
import { SecondaryMedicalTreatmentsWizardForm } from '../forms/medicalTreatmentNomenclatures/SecondaryMedicalTreatmentsWizardForm';
import { MedicalTreatmentNomenclatureChip } from './components/MedicalTreatmentNomenclatureChip';

interface IProps {
	item: IRequestForInsuranceAllowance;
	patient: IPatient;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const DetailsCard = ({ item, patient, reload, style }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();
	const attendingAudiologist = useFindUser(item.audiologistId);

	const openMainNomenclature = () => {
		if (item.isFailedTrial) {
			open(
				<LumpSumMedicalTreatmentWizardForm
					open
					confirm={confirm}
					cancel={cancel}
					rfia={item}
				/>
			);
		} else {
			open(
				<MainAndPseudoMedicalTreatmentWizardForm
					open
					confirm={confirm}
					cancel={cancel}
					rfia={item}
				/>
			);
		}
	};

	const openPseudoNomenclature = () => {
		open(
			<PseudoMedicalTreatmentWizardForm
				open
				confirm={confirm}
				cancel={cancel}
				rfia={item}
			/>
		);
	};

	const openSecondaryNomenclature = () => {
		open(
			<SecondaryMedicalTreatmentsWizardForm
				open
				confirm={confirm}
				cancel={cancel}
				rfia={item}
			/>
		);
	};

	const openLinkHif = () => {
		open(
			<LinkHifForm
				open
				rfia={item}
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	const openLinkEnt = () => {
		open(
			<LinkEntPhysicianWithRfiaForm
				item={item}
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const openLinkEntOffice = () => {
		open(
			<LinkEntPhysicianOfficeWithRfiaForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	const openScheme = () => {
		open(
			<ThirdPartySchemeForm
				open
				rfia={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const openLinkAudiologist = () => {
		open(
			<LinkAudiologistWithRfiaForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const openLinkLocation = () => {
		open(
			<LinkLocationWithRfiaForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onUpdateLabels = () => {
		open(
			<RfiaLabelsForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Card
			style={style}
			className='df-col'>
			<CardContent>
				<CardTitleTypography title={strings.general} />
				<LabelledProperty
					label={strings.patient}
					property={item.patient.identifier}
					navigate={() => push(calcPatientDetailRoute(item.patientId))}
				/>
				<LabelledProperty
					icon={<NumericIcon />}
					label={strings.socialSecurityNumberAbbreviation}
					property={patient.formattedSocialSecurityNumber}
				/>
				<LabelledProperty
					icon={<CakeIcon />}
					label={strings.dateOfBirth}
					property={`${formatDate(patient.dateOfBirth)} (${patient.age})`}
					undefinedText={strings.birthDateUnknown}
				/>
				<CardTitleTypography
					title={strings.attendingDoctors}
					withPaddingTop
				/>
				<LabelledProperty
					label={strings.audiologist}
					property={attendingAudiologist?.lastNameCommaFirstName}
					edit={openLinkAudiologist}
				/>
				<LabelledProperty
					label={strings.location}
					property={item.location?.identifier}
					edit={openLinkLocation}
				/>
				<LabelledProperty
					label={strings.entPhysicianShort}
					property={item.entPhysicianIdentifier}
					edit={openLinkEnt}
				/>
				{item.hasEntPhysician && (
					<LabelledProperty
						label={strings.office}
						property={item.entPhysicianOfficeIdentifier}
						edit={openLinkEntOffice}
					/>
				)}
				<HealthInsuranceFundCardTitleTypography
					code={item.healthInsuranceFundCode}
					onLink={item.isHandedOverOrCancelled ? undefined : openLinkHif}
				/>
				<HealthInsuranceFundDisplay code={item.healthInsuranceFundCode} />
				<CardTitleTypography
					title={strings.thirdPayerArrangement}
					withPaddingTop
					edit={item.isHandedOverOrCancelled ? undefined : openScheme}
				/>
				<RectangularChip
					label={item.hasThirdPartyPaymentScheme ? strings.applicable : strings.notApplicable}
					color={item.hasThirdPartyPaymentScheme ? 'success' : 'warning'}
					fill={item.hasThirdPartyPaymentScheme ? true : false}
					style={{ marginBottom: theme.spacing(1) }}
				/>
				<CardTitleTypography
					title={strings.nomenclatureSingularOrPlural}
					withPaddingTop
				/>
				<LabelledProperty
					label={strings.mainNomenclatureCode}
					edit={item.isHandedOverOrCancelled ? undefined : openMainNomenclature}
					undefinedText=''
					icon={item.hasMainMedicalTreatmentNomenclatureDefined ? undefined : <StatusIcon status='warning' />}
				/>
				{item.hasMainMedicalTreatmentNomenclatureDefined && (
					<>
						<MedicalTreatmentNomenclatureChip
							code={item.mainMedicalTreatmentNomenclatureCode ?? ''}
							rfia={item}
							type='main'
							reload={reload}
						/>
						<LabelledProperty
							label={strings.pseudoNomenclatureCode}
							edit={item.isHandedOverOrCancelled ? undefined : openPseudoNomenclature}
							undefinedText=''
						/>
						{item.hasPseudoMedicalTreatmentNomenclatureDefined && (
							<MedicalTreatmentNomenclatureChip
								code={item.pseudoMedicalTreatmentNomenclatureCode ?? ''}
								rfia={item}
								type='pseudo'
								reload={reload}
							/>
						)}
						<LabelledProperty
							label={strings.additionalNomenclatureCodes}
							undefinedText=''
							edit={item.isHandedOverOrCancelled ? undefined : openSecondaryNomenclature}
							secondaryActionIcon={<LinkOffIcon />}
						/>
						<div
							className='df-row-ac'
							style={{ marginBottom: '4px' }}>
							{item.secondaryMedicalTreatmentNomenclatureCodes?.map(t => (
								<MedicalTreatmentNomenclatureChip
									key={t}
									code={t}
									type='secondary'
									rfia={item}
									reload={reload}
								/>
							))}
						</div>
					</>
				)}
				<LabelsComponent
					labels={item.labels}
					onUpdate={onUpdateLabels}
				/>
			</CardContent>
		</Card>
	);
};
