import React from 'react';
import NumberFormat from 'react-number-format';
import { NumberFormatProps } from './NumberFormatProps';

interface NumberFormatCustomProps extends NumberFormatProps {
	inputRef: (instance: NumberFormat | null) => void;
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

export function NumberFormatX(props: NumberFormatCustomProps) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			name={props.name}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator=' '
			mask='_'
			allowedDecimalSeparators={['.', ',']}
			isNumericString
			allowNegative={props.allowNegative}
			decimalScale={props.decimalScale ?? 2}
			fixedDecimalScale={props.fixedDecimalScale ?? true}
			isAllowed={props.isAllowed}
			// isAllowed={(values) => {
			//     const { floatValue } = values;
			//     return floatValue === undefined || floatValue <= 100;
			// }}
			prefix={props.prefix ?? ''}
			suffix={props.suffix ?? ''}
			// format={limit}
			// max={'100'}
			// min={0}
		/>
	);
}
