import { Badge, IconButton, IconButtonProps } from '@material-ui/core';
import React from 'react';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

export const ViewBadgeIconButton = ({ children, ...props }: IconButtonProps) => {
	return (
		<IconButton {...props}>
			<Badge
				overlap='circle'
				badgeContent={<VisibilityOutlinedIcon style={{ fontSize: '20px', backgroundColor: 'white', borderRadius: '100%' }} />}>
				{children}
			</Badge>
		</IconButton>
	);
};
