import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useContext } from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { ICustomerAccountLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { SelectableColumn } from './SelectableColumn';
import { useQueryContext } from 'framework/hooks/useQueryContext';

interface IProps {
	onNew: VoidFunction;
	style?: React.CSSProperties;
}

export const UnhandledLinesCard = ({ onNew, style }: IProps) => {
	const strings = useLocalization();
	const { queryResult, params, setParams } = useQueryContext(QueryContext);
	const theme = useTheme();
	const { columns, setColumns, columnsRecord, headers } = useContext(ViewContext);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<Card
			className='fg1'
			style={style}>
			<CardTitleTypography
				withPadding
				title={strings.unhandled}
				action={
					<SmallPrimaryTextButton
						startIcon={<AddIcon />}
						onClick={onNew}>
						{strings.newWhat(`${strings.settlementProposal}/${strings.verzamelstaat.toLowerCase()}`)}
					</SmallPrimaryTextButton>
				}
			/>
			{queryResult.unfilteredCount > 0 && (
				<>
					<Divider />
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchVerb}
						style={{ width: '400px' }}
						variant='embedded'
					/>
					<Divider />
					<div
						className='df-row jc-sb'
						style={{ paddingRight: 8 }}>
						<Filter
							params={params}
							onFilter={params => setParams(onResetToPage1(params))}
							paddingLeft={theme.spacing(1.5)}
							metadata={queryResult.metadata}
						/>
						<div>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
								view='settlementProposalsUnhandled'
							/>
						</div>
					</div>
					<Divider />
					<CardMedia>
						<PageableTableWithColumnSelection<ICustomerAccountLine, SelectableColumn>
							queryResult={queryResult}
							headers={headers}
							sortParams={params.sortParams}
							pageParams={params.pageParams}
							setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
							getKey={inst => inst.id}
							selectedColumns={columns}
						/>
					</CardMedia>
				</>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound withPadding>{strings.allRefundsAreCollectedOnASettlementProposal}</NoRecordsFound>}
		</Card>
	);
};
