import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { IExactOnlineSale } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: IExactOnlineSale;
	style?: React.CSSProperties;
}

export const ExactOnlineSaleListItemStatusIcon = ({ item, style }: IProps) => {
	if (item.isIgnored) {
		return <HighlightOffOutlinedIcon style={style} />;
	} else if (item.isSynced === false) {
		return <BlockIcon style={style} />;
	} else {
		return <CheckCircleOutlineIcon style={style} />;
	}
};
