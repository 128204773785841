import React from 'react';
import { ChipWithAvatar } from 'framework/components/ChipWithAvatar';
import { IObjectWithIdAndIdentifier } from 'gen/ApiClient';

interface IProps {
	suppliers: IObjectWithIdAndIdentifier<string>[];
}

export const SuppliersChips = ({ suppliers }: IProps) => {
	return (
		<div>
			{suppliers.map(t => (
				<ChipWithAvatar
					label={t.identifier}
					key={t.id}
				/>
			))}
		</div>
	);
};
