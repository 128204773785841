import { Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { IKeyDoubleValue } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { MuiColors } from '../MuiColors';
import { shuffleArray } from '../shuffleArray';
import { createDonutChartOptions } from './createDonutChartOptions';
import './DonutChart.scss';

interface IProps {
	data: IKeyDoubleValue[];
	title: string;
	style?: React.CSSProperties;
}

const DonutChart = ({ data, title, style }: IProps) => {
	const strings = useLocalization();
	const [options, values] = useMemo<[ApexCharts.ApexOptions, number[]]>(() => {
		const colors = shuffleArray(MuiColors);
		const keys = data.map(t => t.key!);
		const values = data.map(t => t.value);
		const total = values.reduce((a, b) => a + b, 0);
		return [createDonutChartOptions(colors, keys, total), values];
	}, [data]);

	return (
		<Card style={style}>
			<CardTitleTypography
				title={title}
				withPadding
			/>
			<CardContent>
				{values.length === 0 && <NoRecordsFound withPaddingTop>{strings.noDataAvailable}</NoRecordsFound>}
				{options && values && (
					<Chart
						type='donut'
						width={600}
						options={options}
						series={values}
					/>
				)}
			</CardContent>
		</Card>
	);
};

export default DonutChart;
