import { DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IAddressModel } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { ISharedDialogProps } from '../ISharedDialogProps';
import { AddressInput } from './AddressInput';

const createSchema = (strings: IStrings) => {
	return yup.object<IAddressModel>({
		addressLine: yup.string().required(strings.formRequired(strings.addressLine)),
		zip: yup.string().required(strings.formRequired(strings.zip)),
		city: yup.string().required(strings.formRequired(strings.city)),
		country: yup.string(),
	});
};

const toModel = (model: IAddressModel | undefined): IAddressModel => {
	return {
		addressLine: model?.addressLine ?? '',
		zip: model?.zip ?? '',
		city: model?.city ?? '',
		country: model?.country ?? 'België',
	};
};

export const AddressForm = ({ model, submitFunction, cancel, confirm, successMessage, ...rest }: ISharedDialogProps<IAddressModel>) => {
	const strings = useLocalization();
	const [isInSearchMode, setIsInSearchMode] = useState<boolean>(false);
	const [submit, isSubmitting] = useFormSubmit(submitFunction);
	const notify = useSnackbarNotify();
	const initialValues = useMemo<IAddressModel>(() => toModel(model), [model]);

	const handleSubmit = async (values: IAddressModel, helpers: FormikHelpers<IAddressModel>) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			if (successMessage) {
				notify(successMessage);
			}
		}
		confirm();
	};

	return (
		<Formik<IAddressModel>
			validateOnMount
			initialValues={initialValues}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<OverflowDialog
					fullWidth
					{...rest}>
					<DialogTitle>
						<div style={{ height: '48px', lineHeight: '48px' }}>{isInSearchMode === true ? strings.searchAnAddress : strings.editConfirmAddress}</div>
					</DialogTitle>
					<OverflowDialogContent>
						<AddressInput
							embed
							setExternalSearchMode={setIsInSearchMode}
						/>
					</OverflowDialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={model ? strings.update : strings.create}
						isSubmitting={isSubmitting}
					/>
				</OverflowDialog>
			</Form>
		</Formik>
	);
};
