import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { Try } from 'framework/Try';
import { IInventoryItem, IQueryResult } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ICommonParams } from './ICommonParams';
import { SearchInventoryItem } from './SearchInventoryItem';
import { SelectableColumn } from './SelectableColumn';

interface IProps<TParams extends ICommonParams> {
	style?: React.CSSProperties;
	selectedIds: string[];
	defaultParams: TParams;
	queryF: (params: TParams) => Promise<Try<IQueryResult<IInventoryItem>>>;
	canSelectF: (item: IInventoryItem) => boolean;
	confirm: (item: IInventoryItem) => void;
	cancel: VoidFunction;
	defaultSelectableColumns?: SelectableColumn[];
}

export const SearchInventoryItemDialog = <TParams extends ICommonParams>({
	confirm,
	cancel,
	defaultParams,
	queryF,
	canSelectF,
	selectedIds,
	defaultSelectableColumns,
}: IProps<TParams>) => {
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(queryF, defaultParams);
	const { isStacked } = useDialogsContext();

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<Dialog
			open={isStacked === false}
			fullWidth
			maxWidth='md'>
			<DialogTitle>{strings.selectWhat(strings.inventoryItem)}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<SearchInventoryItem
					queryResult={queryResult}
					params={params}
					setParams={setParams}
					selectedIds={selectedIds}
					canSelectF={canSelectF}
					onAdd={confirm}
					mode='select'
					defaultSelectableColumns={defaultSelectableColumns}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant='text'
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
