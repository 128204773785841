import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IRepair, ISendRepairToSupplierRequest, repairsCommand_sendToSupplier, repairsQuery_settings, suppliersQuery_single } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { FormSelectDeliveryTimeFromSupplierOrManualField } from 'shared/forms/FormSelectDeliveryTimeFromSupplierOrManualField';
import * as yup from 'yup';
import { LocationsPreloadCacheContext } from '../../../settings/locations/LocationsPreloadCacheContext';

const createSchema = (strings: IStrings) => {
	return yup
		.object<ISendRepairToSupplierRequest>({
			expectedRedeliveryDate: yup.date().required(strings.formRequired(strings.expectedDeliveryDate)),
			fromLocationId: yup.string().required(strings.formRequired(strings.fromLocation)),
			repairId: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	repair: IRepair;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const SendToSupplierForm = ({ repair, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(repairsCommand_sendToSupplier);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const [supplier] = useApiEffect(suppliersQuery_single, repair.supplierId);
	const [settings] = useApiEffect(repairsQuery_settings);

	const handleSubmit = async (values: ISendRepairToSupplierRequest, helpers: FormikHelpers<ISendRepairToSupplierRequest>) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.repairSentToSupplier);
			confirm();
		}
	};

	if (supplier === undefined || settings === undefined) {
		return <div></div>;
	}

	return (
		<Formik<ISendRepairToSupplierRequest>
			validateOnMount
			initialValues={{
				expectedRedeliveryDate: undefined as any,
				fromLocationId: undefined as any,
				repairId: repair.id,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					maxWidth='sm'
					{...rest}>
					<DialogTitle>{strings.sendRepairToSupplier}</DialogTitle>
					<DialogContent dividers>
						<div className='df-col'>
							<FormSelectFieldWithOption<ISendRepairToSupplierRequest, string>
								name='fromLocationId'
								options={locations}
								label={strings.fromLocation}
							/>
							<FormSelectDeliveryTimeFromSupplierOrManualField<ISendRepairToSupplierRequest>
								name='expectedRedeliveryDate'
								label={strings.expectedDeliveryDate}
								supplier={supplier}
								defaultDeliveryTime={settings.defaultExpectedReturnTime}
							/>
						</div>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.select}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
