import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { ISubscriptionSummary } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { SelectableColumn } from './SelectableColumn';
import { SubscriptionProgress } from './SubscriptionProgress';
import { SubscriptionStatusChip } from './SubscriptionStatusChip';

export const SubscriptionsTroubleshootContent = ({ reloadTrigger }: IReloadTriggerProps) => {
	const theme = useTheme();
	const { params, setParams, queryResult } = useQueryContext(QueryContext, reloadTrigger);
	const { headers, columns, setColumns, columnsRecord } = useContext(ViewContext);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<div className='fg1'>
			<div
				className='df-row-ac jc-sb'
				style={{ marginBottom: '24px' }}>
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder='Zoek...'
					style={{ width: '400px' }}
					variant='paper'
				/>
			</div>
			{queryResult.unfilteredCount > 0 && (
				<Card>
					<div className='df-row jc-sb'>
						<Filter
							params={params}
							onFilter={params => setParams(onResetToPage1(params))}
							paddingLeft={theme.spacing(1.5)}
						/>
						<div
							className='df-row-ac'
							style={{ paddingRight: theme.spacing(1.5) }}>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
								view='subscriptions'
							/>
						</div>
					</div>
					<Divider />
					<CardMedia>
						<PageableTableWithColumnSelection<ISubscriptionSummary, SelectableColumn>
							queryResult={queryResult}
							headers={headers}
							sortParams={params.sortParams}
							pageParams={params.pageParams}
							setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
							getKey={inst => inst.key ?? ''}
							selectedColumns={columns}
							prependColumn={createEmptyHeader<ISubscriptionSummary>('prepend', t => (
								<SubscriptionStatusChip item={t} />
							))}
							appendColumn={createEmptyHeader<ISubscriptionSummary>('append', t => (
								<SubscriptionProgress item={t} />
							))}
						/>
					</CardMedia>
				</Card>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound>No Failed Events found!</NoRecordsFound>}
		</div>
	);
};
