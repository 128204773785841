import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		firstUsageDate: strings.firstUsageDate,
		manufacturerCommaInstrumentTypeName: `${strings.manufacturer}, ${strings.model}`,
		instrumentTypeName: strings.model,
		lastUsageDate: strings.lastUsageDate,
		manufacturer: strings.manufacturer,
		serialNumber: strings.serialNumber,
		type: strings.type,
		formattedReadOutsUniqueField: strings.readOutAs,
	};
};
