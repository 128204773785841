import { DialogProps } from '@material-ui/core';
import React from 'react';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IRequestForInsuranceAllowance, rfiasCommand_setEntPhysicianOffice } from 'gen/ApiClient';
import { SelectEntPhysicianOfficeForm } from '../../../contacts/earNoseThroatPhysicians/forms/SelectEntPhysicianOfficeForm';

interface IProps extends DialogProps {
	item: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LinkEntPhysicianOfficeWithRfiaForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [link, isSubmitting] = useFormSubmit(rfiasCommand_setEntPhysicianOffice);

	const onConfirm = async (id: string, setError: (error: string) => void) => {
		handleValidateResponseBase(await link(item.id, id), setError, confirm);
	};

	return (
		<SelectEntPhysicianOfficeForm
			{...rest}
			confirm={onConfirm}
			cancel={cancel}
			initialId={item.entPhysicianOfficeId}
			entPhysicianId={item.entPhysicianId ?? ''}
			isSubmitting={isSubmitting}
		/>
	);
};
