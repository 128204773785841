import React, { useContext } from 'react';
import { PreloadCacheProvider as FrameworkCacheProvider } from 'framework/context/PreloadCacheProvider';
import { IRoomsCache, roomsQuery_all, roomsQuery_concurrencyToken } from 'gen/ApiClient';
import { PreloadContext } from 'shared/context/PreloadContext';
import { RoomsPreloadCacheContext } from './RoomsPreloadCacheContext';

export const RoomsPreloadCacheProvider = ({ children }: any) => {
	const { roomsCache } = useContext(PreloadContext);

	return (
		<FrameworkCacheProvider<IRoomsCache>
			concurrencyF={roomsQuery_concurrencyToken}
			loadF={roomsQuery_all}
			context={RoomsPreloadCacheContext}
			initialData={roomsCache}>
			{children}
		</FrameworkCacheProvider>
	);
};
