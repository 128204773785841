import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormMaskedTextField } from 'framework/forms/FormMaskedTextField';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatchUserRizivNumberRequest, IUserDto, usersCommand_patchRizivNumber } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { RizivNumberMask } from 'shared/RizivNumberMask';
import { ConventionedRizivNumberTextFieldWithDocumentation } from 'shared/rizivNumber/ConventionedRizivNumberTextFieldWithDocumentation';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchUserRizivNumberRequest>({
		userId: yup.string().required(),
		rizivNumber: yup.string().required(strings.formRequired(strings.RIZIVNumber)),
	});
};

interface IProps extends DialogProps {
	user: IUserDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const RizivNumberForm = ({ user, confirm, cancel, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [change, isSubmitting] = useFormSubmit(usersCommand_patchRizivNumber);
	const strings = useLocalization();

	const handleSubmit = async (values: IPatchUserRizivNumberRequest, helpers: FormikHelpers<IPatchUserRizivNumberRequest>) => {
		const r = await change(values);
		if (handleValidateResponse(r, helpers, 'rizivNumber')) {
			notify(strings.userUpdated);
			confirm();
		}
	};

	return (
		<Formik<IPatchUserRizivNumberRequest>
			validateOnMount
			initialValues={{
				userId: user.id,
				rizivNumber: user.rizivNumber ?? '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					isSubmitting={isSubmitting}
					cancel={cancel}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	isSubmitting: boolean;
	cancel: VoidFunction;
}

const InnerDialog = ({ isSubmitting, cancel, ...rest }: IInnerProps) => {
	const strings = useLocalization();
	const props = useFormikContext<IPatchUserRizivNumberRequest>();

	return (
		<Dialog
			fullWidth
			maxWidth='xs'
			{...rest}>
			<DialogTitle>{strings.updateRIZIVNumber}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<FormMaskedTextField<IPatchUserRizivNumberRequest>
					name='rizivNumber'
					label={strings.RIZIVNumber}
					mask={RizivNumberMask}
					required
				/>
				<ConventionedRizivNumberTextFieldWithDocumentation rizivNumber={props.values.rizivNumber ?? ''} />
			</DialogContent>
			<CancelSubmitFormDialogActions
				isSubmitting={isSubmitting}
				submitText={strings.update}
				cancel={cancel}
			/>
		</Dialog>
	);
};
