import { UserRole } from 'gen/ApiClient';
import { useLoggedInUser } from './useLoggedInUser';

export function useLoggedInUserIsMinimalInRole(role: UserRole): boolean {
	const user = useLoggedInUser();
	if (role === 'Admin') {
		return user.role === 'Admin' || user.role === 'SuperUser';
	} else if (role === 'SuperUser') {
		return user.role === 'SuperUser';
	} else {
		return true;
	}
}
