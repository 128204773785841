import { CacheProvider } from 'framework/context/CacheProvider';
import { IPurchasesFilterData, purchasesQuery_concurrencyToken, purchasesQuery_filterData } from 'gen/ApiClient';
import React from 'react';
import { PurchasesFilterDataContext } from './PurchasesFilterDataContext';

export const PurchasesFilterDataProvider = ({ children }: any) => {
	return (
		<CacheProvider<IPurchasesFilterData>
			concurrencyF={purchasesQuery_concurrencyToken}
			loadF={purchasesQuery_filterData}
			context={PurchasesFilterDataContext}>
			{children}
		</CacheProvider>
	);
};
