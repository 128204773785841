import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IFile } from 'framework/utils/IFile';
import { patientsNhaxCommand_batchImportNhaxPatients } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { UploadFileDialog } from 'shared/dialogs/upload/UploadFileDialog';
import { ISelectAudiologistLocationAndLanguageModel } from 'shared/forms/ISelectAudiologistLocationAndLanguageModel';
import { SelectAudiologistLocationAndLanguageForm } from 'shared/forms/SelectAudiologistLocationAndLanguageForm';
import { PasswordForm } from './PasswordForm';

interface IProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
}

type Mode = 'file' | 'password' | 'selectAudiologistAndLocation';

export const BatchCreatePatientsFromNhaxFile = ({ cancel, confirm }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(patientsNhaxCommand_batchImportNhaxPatients);
	const [mode, setMode] = useState<Mode>('file');
	const notify = useSnackbarNotify();
	const [file, setFile] = useState<IFile>();
	const [password, setPassword] = useState<string>('');

	const onFile = async (file: IFile) => {
		setFile(file);
		if (file.fileName.indexOf('.enhax') > -1) {
			setMode('password');
		} else {
			setMode('selectAudiologistAndLocation');
		}
	};

	const onPassword = (password: string) => {
		setPassword(password);
		setMode('selectAudiologistAndLocation');
	};

	const onSelectAudiologistLocationAndLanguage = async (data: ISelectAudiologistLocationAndLanguageModel) => {
		const r = await submit({ ...file!, ...data, password: password });
		if (r.isSuccess) {
			if (r.result.hasError) {
				notify(r.result.error!, 'error');
			} else {
				notify(`Patients created`, 'success');
				confirm();
			}
		}
	};

	if (mode === 'file') {
		return (
			<UploadFileDialog
				open
				title={`Select NHAX file`}
				caption={`File can contain up to 50 patient records`}
				confirm={onFile}
				isUploading={isSubmitting}
				cancel={cancel}
				accept='nhaxOrEnhax'
			/>
		);
	} else if (mode === 'password' && file) {
		return (
			<PasswordForm
				open
				title={strings.providePassword}
				caption={strings.thisFileIsPasswordProtected}
				confirm={onPassword}
				isSubmitting={isSubmitting}
				cancel={cancel}
				hasInvalidPassword={false}
			/>
		);
	} else if (mode === 'selectAudiologistAndLocation') {
		return (
			<SelectAudiologistLocationAndLanguageForm
				open
				confirm={values => onSelectAudiologistLocationAndLanguage(values)}
				cancel={cancel}
				isSubmitting={isSubmitting}
			/>
		);
	} else {
		return <div></div>;
	}
};
