import { Divider, Typography } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { CardX } from 'framework/components/cards/CardX';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import {
	PatientDetailOverviewLayoutElementType,
	PatientDetailOverviewLayoutElementTypes,
	userPreferencesCommand_patchPatientDetailOverviewLayout,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { UserPreferencesPreloadCacheContext } from 'shared/context/UserPreferencesPreloadCacheContext';
import { RecordContext } from 'shared/records/RecordContext';

interface IPatientDetailOverviewLayoutElement {
	type: PatientDetailOverviewLayoutElementType;
	position: PositionType;
	index: number;
}

type PositionType = 'left' | 'right' | 'hidden';

interface IProps {
	style?: React.CSSProperties;
}

export const PatientDetailOverviewLayoutViewPreferencesComponent = ({ style }: IProps) => {
	const strings = useLocalization();
	const { userPreferences, reload } = useContext(UserPreferencesPreloadCacheContext);
	const [elements, setElements] = useState<IPatientDetailOverviewLayoutElement[]>([]);
	const [patch, isPatching] = useFormSubmit(userPreferencesCommand_patchPatientDetailOverviewLayout);
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const { patientDetailOverviewLayoutElementTypeRecord } = useContext(RecordContext);

	const onSave = async () => {
		await patch({
			leftElements: elements.filter(t => t.position === 'left').map(t => t.type),
			rightElements: elements.filter(t => t.position === 'right').map(t => t.type),
		});
		reload();
		setIsDirty(false);
	};

	useEffect(() => {
		if (userPreferences !== undefined) {
			setElements(
				[
					...userPreferences.patientDetailOverviewLayoutView.leftElements.map<IPatientDetailOverviewLayoutElement>(t => ({
						type: t as PatientDetailOverviewLayoutElementType,
						position: 'left',
						index: 0,
					})),
					...userPreferences.patientDetailOverviewLayoutView.rightElements.map<IPatientDetailOverviewLayoutElement>(t => ({
						type: t as PatientDetailOverviewLayoutElementType,
						position: 'right',
						index: 0,
					})),
					...PatientDetailOverviewLayoutElementTypes.filter(
						t =>
							userPreferences.patientDetailOverviewLayoutView.leftElements.indexOf(t) === -1 &&
							userPreferences.patientDetailOverviewLayoutView.rightElements.indexOf(t) === -1
					).map<IPatientDetailOverviewLayoutElement>(t => ({ type: t, position: 'hidden', index: 0 })),
				].map((t, index) => ({ ...t, index: index }))
			);
		}
	}, [userPreferences]);

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}
		setIsDirty(true);
		const resultIndex = result.destination!.index;
		const rElements = elements.map<IPatientDetailOverviewLayoutElement>(t => ({
			...t,
			position: t.type === result.draggableId ? (result.destination!.droppableId as PositionType) : t.position,
			index: t.type === result.draggableId ? resultIndex : t.index >= resultIndex ? t.index + 1 : t.index,
		}));
		const oElements = _.orderBy(rElements, t => t.index).map((t, index) => ({ ...t, index: index }));
		setElements(oElements);
	};

	return (
		<div
			className='df-col gap-16 w100'
			style={style}>
			<div>{strings.patientDetailOverviewLayoutPreferencesDescription}</div>
			<Divider />
			<DragDropContext onDragEnd={result => onDragEnd(result)}>
				<div className='df-row gap-16'>
					<Droppable
						key={'hidden' as PositionType}
						droppableId={'hidden' as PositionType}>
						{(provided: any) => (
							<div
								className='df-col gap-16'
								ref={provided.innerRef}
								{...provided.droppableProps}>
								<Typography variant='caption'>{strings.elementsBelowWillNotBeShown}</Typography>
								{elements
									.filter(t => t.position === 'hidden')
									.map(t => (
										<DraggablePatientDetailOverviewLayoutElement
											key={t.type}
											item={t}
											titleRecord={patientDetailOverviewLayoutElementTypeRecord}
										/>
									))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
					<Divider orientation='vertical' />
					<div className='df-col gap-16 w100'>
						<CardX className='df-col gap-4'>
							<CardTitleTypography title={`${strings.baseInformation}/${strings.attendingDoctors}/${strings.email}/${strings.phoneNumberShort}`} />
							<span>{strings.thisInfoByDefaultOnTop}</span>
						</CardX>
						<div className='df-row gap-8 w100'>
							<Droppable
								key={'left' as PositionType}
								droppableId={'left' as PositionType}>
								{(provided: any) => (
									<div
										ref={provided.innerRef}
										{...provided.droppableProps}
										style={{ ...provided.droppableProps.style, flexGrow: 1 }}>
										<CardX
											className='df-col gap-8 fg1'
											style={{ height: '100%' }}
											raised>
											<CardTitleTypography
												title={strings.leftColumn}
												style={{ marginBottom: 8 }}
											/>
											{elements
												.filter(t => t.position === 'left')
												.map(t => (
													<DraggablePatientDetailOverviewLayoutElement
														key={t.type}
														item={t}
														titleRecord={patientDetailOverviewLayoutElementTypeRecord}
													/>
												))}
										</CardX>
										{provided.placeholder}
									</div>
								)}
							</Droppable>
							<Droppable
								key={'right' as PositionType}
								droppableId={'right' as PositionType}>
								{(provided: any) => (
									<div
										ref={provided.innerRef}
										{...provided.droppableProps}
										style={{ ...provided.droppableProps.style, flexGrow: 1 }}>
										<CardX
											className='df-col gap-8 fg1'
											style={{ height: '100%' }}
											raised>
											<CardTitleTypography
												title={strings.rightColumn}
												style={{ marginBottom: 8 }}
											/>
											{elements
												.filter(t => t.position === 'right')
												.map(t => (
													<DraggablePatientDetailOverviewLayoutElement
														key={t.type}
														item={t}
														titleRecord={patientDetailOverviewLayoutElementTypeRecord}
													/>
												))}
										</CardX>
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</div>
					</div>
				</div>
			</DragDropContext>
			<Divider />
			<div className='df-row'>
				<div className='fg1'></div>
				<LoaderButton
					startIcon={<SaveOutlinedIcon />}
					isLoading={isPatching}
					onClick={onSave}
					disabled={isDirty === false}
					color='secondary'>
					{strings.saveChanges}
				</LoaderButton>
			</div>
		</div>
	);
};

interface IDraggableLayoutElementProps {
	item: IPatientDetailOverviewLayoutElement;
	style?: React.CSSProperties;
	titleRecord: Record<PatientDetailOverviewLayoutElementType, string>;
}

const DraggablePatientDetailOverviewLayoutElement = ({ item, style, titleRecord }: IDraggableLayoutElementProps) => {
	return (
		<Draggable
			key={item.type}
			draggableId={item.type}
			index={item.index}>
			{(provided: any) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}>
					<div style={{ border: '1px dashed lightgray', borderRadius: 5, padding: 8, ...style }}>
						<span>{titleRecord[item.type as PatientDetailOverviewLayoutElementType]}</span>
					</div>
				</div>
			)}
		</Draggable>
	);
};
