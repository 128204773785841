import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IPatient, patientsCommand_markAsActive, patientsCommand_markAsDeceased, patientsCommand_markAsInactive, PatientStatus } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	patient: IPatient;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const ChangePatientStatusForm = ({ patient, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [status, setStatus] = useState<PatientStatus>(patient.status as PatientStatus);
	const [markAsDeceased, isSubmitting1] = useFormSubmit(patientsCommand_markAsDeceased);
	const [markAsInactive, isSubmitting2] = useFormSubmit(patientsCommand_markAsInactive);
	const [markAsActive, isSubmitting3] = useFormSubmit(patientsCommand_markAsActive);

	const onSubmit = async () => {
		const f = status === 'Deceased' ? markAsDeceased : status === 'Inactive' ? markAsInactive : markAsActive;
		const r = await f(patient.id);
		if (r.isSuccess) {
			confirm();
		}
	};

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='md'>
			<DialogTitle>{strings.changeWhat(`${strings.status} ${strings.patient}`)}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<ExtendedRadioGroup<PatientStatus>
					selected={status}
					setSelected={setStatus}
					options={[
						{ value: 'Active', label: strings.active, caption: strings.patientActiveCaption },
						{ value: 'Inactive', label: strings.noLongerActive, caption: strings.patientInactiveCaption, disabled: patient.isDeceased },
						{ value: 'Deceased', label: strings.deceased, caption: strings.patientDeceasedCaption },
					]}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				isSubmitting={isSubmitting1 || isSubmitting2 || isSubmitting3}
				isValid={patient.status !== status}
				onSubmit={onSubmit}
				submitText={strings.changeWhat(strings.status)}
				cancelText={strings.cancel}
			/>
		</Dialog>
	);
};
