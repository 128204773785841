import React from 'react';
import { CacheProvider } from 'framework/context/CacheProvider';
import { IProductCatalogSearchData, productCatalogQuery_concurrencyToken, productCatalogQuery_searchData } from 'gen/ApiClient';
import { ProductCatalogFilterDataContext } from './ProductCatalogFilterDataContext';

export const FilterDataProvider = ({ children }: any) => {
	return (
		<CacheProvider<IProductCatalogSearchData>
			concurrencyF={productCatalogQuery_concurrencyToken}
			loadF={productCatalogQuery_searchData}
			context={ProductCatalogFilterDataContext}>
			{children}
		</CacheProvider>
	);
};
