import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		created: strings.createdAt,
		payoutDate: strings.paidOutDate,
		reference: strings.reference,
		userIdentifier: strings.user,
		value: strings.amountAsValue,
	};
};
