import { useFindUser } from 'app/main/settings/users/useFindUser';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { IPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { LinkAudiologistWithPatientForm } from '../../forms/LinkAudiologistWithPatientForm';
import { LinkEntPhysicianWithPatientForm } from '../../forms/LinkEntPhysicianWithPatientForm';
import { LinkGeneralPractitionerForm } from '../../forms/LinkGeneralPractitionerForm';
import { LinkLocationWithPatientForm } from '../../forms/LinkLocationWithPatientForm';
import { PatientHifLabelledProperty } from './PatientHifLabelledProperty';

interface IProps {
	item: IPatient;
	reload: VoidFunction;
	style?: React.CSSProperties;
	withoutHif?: boolean;
}

export const PatientAttendingPhysiciansCardContent = ({ item, reload, style, withoutHif }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const attendingAudiologist = useFindUser(item.attendingAudiologistId);

	const onLinkAudiologist = () => {
		open(
			<LinkAudiologistWithPatientForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onLinkLocation = () => {
		open(
			<LinkLocationWithPatientForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onLinkEnt = () => {
		open(
			<LinkEntPhysicianWithPatientForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onLinkGp = () => {
		open(
			<LinkGeneralPractitionerForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<div
			className='df-col gap-4'
			style={style}>
			<CardTitleTypography
				title={strings.attendingDoctors}
				style={{ marginBottom: 8 }}
			/>
			<LabelledPropertyX
				label={strings.audiologist}
				edit={onLinkAudiologist}>
				{attendingAudiologist?.lastNameCommaFirstName ?? <em>{strings.notApplicableAbbreviation}</em>}
			</LabelledPropertyX>
			<LabelledPropertyX
				label={strings.location}
				edit={onLinkLocation}>
				{item?.location?.identifier ?? <em>{strings.notApplicableAbbreviation}</em>}
			</LabelledPropertyX>
			<LabelledPropertyX
				label={strings.entPhysicianShort}
				edit={onLinkEnt}>
				{item?.attendingEntPhysician?.identifier ?? <em>{strings.notApplicableAbbreviation}</em>}
			</LabelledPropertyX>
			<LabelledPropertyX
				label={strings.generalPractitioner}
				edit={onLinkGp}>
				{item?.attendingGeneralPractitioner?.identifier ?? <em>{strings.notApplicableAbbreviation}</em>}
			</LabelledPropertyX>
			{withoutHif !== true && (
				<PatientHifLabelledProperty
					item={item}
					reload={reload}
				/>
			)}
		</div>
	);
};
