import { IPatchBusinessDataRequest } from 'gen/ApiClient';

export const sanitizeIPatchBusinessDataRequest = (values: IPatchBusinessDataRequest): IPatchBusinessDataRequest => {
	return {
		email: values.email ?? '',
		enterpriseNumber: values.enterpriseNumber ?? '',
		name: values.name ?? '',
		phone: values.phone ?? '',
		vatNumber: values.vatNumber ?? '',
		website: values.website ?? '',
	};
};
