import { Paper } from '@material-ui/core';
import { CollapseX } from 'framework/components/CollapseX';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { Searchbar } from 'framework/components/search/Searchbar';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { splitArray } from 'framework/utils/array/splitArray';
import { IProductCatalogItem, IStockItemsQueryParamsForArticleProperties, stockItemsQuery_filterArticleProperties } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo } from 'react';
import { Filter } from './Filter';

const DefaultParams: IStockItemsQueryParamsForArticleProperties = {
	categories: [],
	manufacturers: [],
	searchString: '',
	subcategories: [],
	types: [],
};

interface IProps {
	item: IProductCatalogItem;
	setIds: (ids: string[]) => void;
	style?: React.CSSProperties;
}

export const OtherProductCatalogItems = ({ item, style, setIds }: IProps) => {
	const strings = useLocalization();
	const [params, setParams, pcs] = useApiEffectWithParams(stockItemsQuery_filterArticleProperties, {
		...DefaultParams,
		manufacturers: [item.manufacturer ?? ''],
		types: [item.type ?? ''],
	});
	const countPcs = useMemo(() => pcs?.length ?? 0, [pcs]);
	const [first3, other] = useMemo(() => splitArray(pcs, 3), [pcs]);

	useEffect(() => {
		setIds(pcs?.map(t => t.id) ?? []);
		// eslint-disable-next-line
	}, [pcs]);

	return (
		<div
			className='df-col h100'
			style={style}>
			<Searchbar
				variant='paper'
				style={{ margin: 1, marginBottom: 16 }}
				value={params.searchString}
				placeholder={strings.searchVerb}
				onSearch={text => setParams({ ...params, searchString: text })}
			/>
			<Paper>
				<Filter
					params={params}
					setParams={setParams}
				/>
			</Paper>
			<DividerWithCaption caption={`${strings.results} (${countPcs.toString()})`} />
			<div>
				{first3.map(t => (
					<div key={t.manufacturer!}>{t.manufacturerCommaModel}</div>
				))}
				{other.length > 0 && (
					<CollapseX>
						{other.map(t => (
							<div key={t.manufacturer!}>{t.manufacturerCommaModel}</div>
						))}
					</CollapseX>
				)}
			</div>
		</div>
	);
};
