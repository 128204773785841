import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { ILoan, LoanProcessStatus } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	item: ILoan;
	color: ColorX;
	style?: React.CSSProperties;
	onClick?: VoidFunction;
}

export const LoanStatusChip = ({ item, color, style, onClick }: IProps) => {
	const { loanStatusRecord } = useContext(RecordContext);

	return (
		<ChipX
			label={loanStatusRecord[item.status as LoanProcessStatus]}
			color={color}
			style={style}
			onClick={onClick}
		/>
	);
};
