import BugReportIcon from '@material-ui/icons/BugReport';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageX } from 'framework/components/layout/PageX';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { useTrigger } from 'framework/hooks/useTrigger';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { SuperUserRestrictedContent } from 'shared/layout/SuperUserRestrictedContent';
import { TroubleshootPageViewContext } from './TroubleshootPageViewContext';
import { TroubleshootTabTypes } from './TroubleshootTabType';
import { ApiTokensListPage } from './apiTokens/list/ApiTokensListPage';
import { FailedEventsTroubleshootContent } from './failedEvents/FailedEventsTroubleshootContent';
import { GeneralTroubleshootTab } from './general/GeneralTroubleshootTab';
import { NoahUserSetupsListTab } from './noahUserSetups/NoahUserSetupsListTab';
import { NotificationsTroubleshootContent } from './notifications/NotificationsTroubleshootContent';
import { SubscriptionsTroubleshootContent } from './subscriptions/SubscriptionsTroubleshootContent';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

export const TroubleshootPage = () => {
	const strings = useLocalization();
	const [trigger, reload] = useTrigger();
	const { selectedTab, setSelectedTab, TabTypeRecord } = useContext(TroubleshootPageViewContext);

	return (
		<SuperUserRestrictedContent>
			<PageX
				renderRaw
				stickyHeader={
					<div className='df-col fg1'>
						<div className='df-row-ac'>
							<PageBreadcrumbTitle
								title={strings.troubleShoot}
								icon={<BugReportIcon />}
							/>
							<PageBreadcrumbDivider />
							<PageBreadcrumbTitle title={TabTypeRecord[selectedTab]} />
							<div className='fg1'></div>
							<PageXHeaderActions>
								<PageReloadButtonX reload={reload} />
							</PageXHeaderActions>
						</div>
						<HorizontalTabsX
							tabs={TroubleshootTabTypes}
							selectedTab={selectedTab}
							setSelectedTab={setSelectedTab}
							titleRecord={TabTypeRecord}
							style={{
								marginTop: 16,
								marginLeft: -4,
							}}
						/>
					</div>
				}>
				{selectedTab !== 'noah-user-setups' && (
					<div
						className='df-col fg1'
						style={{
							padding: 16,
							paddingLeft: 16,
							maxWidth: 1280,
						}}>
						{selectedTab === 'general' && <GeneralTroubleshootTab reloadTrigger={trigger} />}
						{selectedTab === 'failed-events' && <FailedEventsTroubleshootContent reloadTrigger={trigger} />}
						{selectedTab === 'es-subscriptions' && <SubscriptionsTroubleshootContent reloadTrigger={trigger} />}
						{selectedTab === 'tokens' && (
							<ApiTokensListPage
								reload={reload}
								reloadTrigger={trigger}
							/>
						)}
						{selectedTab === 'notifications' && <NotificationsTroubleshootContent reloadTrigger={trigger} />}
					</div>
				)}
				{selectedTab === 'noah-user-setups' && (
					<NoahUserSetupsListTab
						reload={reload}
						reloadTrigger={trigger}
					/>
				)}
			</PageX>
		</SuperUserRestrictedContent>
	);
};
