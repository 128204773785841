import { IValidateResponse } from 'gen/ApiClient';
import { Try } from '../../Try';

export const handleValidateResponseBase = (r: Try<IValidateResponse>, setError: (error: string) => void, onSuccess: VoidFunction) => {
	if (r.isSuccess) {
		if (r.result.hasError) {
			setError(r.result.error);
		} else {
			onSuccess();
		}
	}
};
