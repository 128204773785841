import { DialogProps } from '@material-ui/core';
import React from 'react';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { IRequestForInsuranceAllowance, rfiasCommand_cancel } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const RfiaCancelForm = ({ rfia, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<StringModelForm
			{...rest}
			fullWidth
			maxWidth='sm'
			cancel={cancel}
			confirm={confirm}
			initialValue=''
			submitFunction={str => rfiasCommand_cancel(rfia.id, str)}
			formTitle={strings.cancellationReason}
			label={strings.reason}
			submitText={strings.cancelWhat(strings.request)}
			cancelText={strings.goBack}
			multiline
			notifyMessage={strings.cancelledWhat(strings.request)}
		/>
	);
};
