import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { PatientsRoute } from 'routes';
import { PatientDetailPage } from './detailX/PatientDetailPage';
import { PatientsListPage } from './list/PatientsListPage';
import { PatientDetailsRoute, PatientSettingsAndViewPreferencesRoute } from './PatientRoutes';
import { PatientSettingsAndViewPreferencesPage } from './settings/PatientSettingsAndViewPreferencesPage';

export const PatientSwitch = () => {
	return (
		<Switch>
			<Route
				exact
				path={PatientsRoute}
				component={PatientsListPage}
			/>
			<Route
				exact
				path={PatientSettingsAndViewPreferencesRoute}
				component={PatientSettingsAndViewPreferencesPage}
			/>
			<Route
				exact
				path={PatientDetailsRoute}
				component={PatientDetailPage}
			/>
			<Redirect to={PatientsRoute} />
		</Switch>
	);
};
