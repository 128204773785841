import { CustomerAccountLineExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createExportRecord = (strings: IStrings): Record<CustomerAccountLineExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		OutstandingBalance: strings.outstandingBalance,
		HasOutstandingBalance: `${strings.outstandingBalance}?`,
		AmountPaid: strings.amountPaid,
		CustomerAccountIdentifier: strings.who,
		SalesDate: strings.salesDate,
		SalesReference: `${strings.reference} ${strings.sale}`,
		SalesTotalPriceInVat: strings.amountFromSale,
		Value: strings.amountToPay,
		SalesRecipientName: strings.withRespectToShort,
	};
};
