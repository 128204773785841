import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { useLocalization } from 'localization/useLocalization';
import { EndTrialMode } from './EndTrialMode';

interface IProps extends DialogProps {
	confirm: (mode: EndTrialMode) => void;
	cancel: VoidFunction;
}

export const EndTrialModeForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [mode, setMode] = useState<EndTrialMode>('EndPositive');

	return (
		<Dialog
			{...rest}
			scroll='paper'>
			<DialogTitle>{`${strings.endTrial}: ${strings.makeAChoice}`}</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<EndTrialMode>
					selected={mode}
					setSelected={setMode}
					options={[
						{ value: 'EndPositive', label: strings.endTrial, caption: strings.endTrialCaption },
						{ value: 'EndAdministrative', label: strings.endTrialAdministratively, caption: strings.endTrialAdministrativelyCaption },
						{ value: 'StartNew', label: strings.startNewTrial, caption: strings.startNewTrialCaption },
						{ value: 'StartCompareNew', label: strings.startComparisonTrial, caption: strings.startComparisonTrialCaption },
						{ value: 'NegativeResult', label: strings.failedTrial, caption: strings.failedTrialCaption },
					]}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button disabled>{strings.previous}</Button>
				<Button
					onClick={() => confirm(mode)}
					color='primary'>
					{strings.confirm}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
