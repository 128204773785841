import { InventoryManagementRoute } from 'routes';

export const ProductCatalogRoute = `${InventoryManagementRoute}/product-catalog`;
export const ProductCatalogDetailRoute = `${ProductCatalogRoute}/:id`;
export const calcProductCatalogDetailRoute = (id: string) => ProductCatalogDetailRoute.replace(':id', id);
export const InventoryItemsRoute = `${InventoryManagementRoute}/inventory-items`;
export const InventoryItemDetailRoute = `${InventoryItemsRoute}/:id`;
export const calcInventoryItemDetailRoute = (id: string) => InventoryItemDetailRoute.replace(':id', id);
export const StockRoute = `${InventoryManagementRoute}/inventory`;
export const StockEntriesRoute = `${InventoryManagementRoute}/evolution`;
export const StockEntryDetailRoute = `${StockEntriesRoute}/:id`;
export const calcStockEntryDetailRoute = (id: string) => StockEntryDetailRoute.replace(':id', id);
export const StockTransactionsRoute = `${InventoryManagementRoute}/stock-transactions`;
export const NoahDevicesRoute = `${InventoryManagementRoute}/noah-devices`;
export const NoahDevicesRulesRoute = `${NoahDevicesRoute}/rules`;
export const NoahDevicesReadOutsRoute = `${NoahDevicesRoute}/read-outs`;
export const SearchSerialNumberRoute = `${InventoryManagementRoute}/search-serial-number`;
export const DeliveriesRoute = `${StockTransactionsRoute}/deliveries`;
export const DeliveryDetailRoute = `${DeliveriesRoute}/:id`;
export const calcDeliveryDetailRoute = (id: string) => DeliveryDetailRoute.replace(':id', id);
export const ReturnsRoute = `${StockTransactionsRoute}/returns`;
export const ReturnDetailRoute = `${ReturnsRoute}/:id`;
export const calcReturnDetailRoute = (id: string) => ReturnDetailRoute.replace(':id', id);
export const StockMovementsRoute = `${StockTransactionsRoute}/stockmovements`;
export const StockMovementDetailRoute = `${StockMovementsRoute}/:id`;
export const calcStockMovementDetailRoute = (id: string) => StockMovementDetailRoute.replace(':id', id);
