import { IObjectWithIdAndIdentifier, IProductCatalogItem } from 'gen/ApiClient';
import { IOrderLineModel } from './IOrderLineModel';
import { v1 as uuid } from 'uuid';

export const mapProductCatalogItemToOrderLineModel = (
	pc: IProductCatalogItem,
	patient: IObjectWithIdAndIdentifier<string> | undefined | null
): IOrderLineModel => {
	return {
		id: pc.isUniquelyIdentifiable ? uuid() : pc.id,
		description: pc.identifier,
		productCatalogItemId: pc.id,
		quantity: 1,
		productCatalogItem: pc,
		orientation: '',
		patientId: patient?.id,
		patientIdentifier: patient?.identifier,
		articleProperties: pc.articlePropertyDefinitions?.map(t => ({ key: t.key, values: [] })) ?? [],
	};
};
