import { Dialog, DialogContent, DialogProps, Typography, useTheme } from '@material-ui/core';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { ILabelCaptionValue } from 'framework/components/ILabelCaptionValue';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { ErrorTextWithIcon } from 'framework/forms/common/ErrorTextWithIcon';
import { FormIntegerField } from 'framework/forms/FormIntegerField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IAddRepairLineRequest, IInventoryItem, IProductCatalogItem, IRepair, repairsCommand_addLine } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SelectedInventoryItemComponent } from 'shared/SelectedInventoryItemComponent';
import { SelectedProductCatalogItemComponent } from 'shared/SelectedProductCatalogItemComponent';
import * as yup from 'yup';
import { FormRepairReasonsField } from './FormRepairReasonsField';
import { SelectInventoryItem } from './SelectInventoryItem';
import { SelectNoahDevice } from './SelectNoahDevice';
import { SelectStockItem } from './SelectStockItem';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IAddRepairLineRequest>({
			inventoryItemId: yup.string(),
			productCatalogId: yup.string().required(strings.formRequired(strings.productCatalogItem)),
			quantity: yup.number().defined(),
			reasons: yup.mixed(),
			repairId: yup.string().required(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IAddRepairLineRequest)[]> = {
	0: ['inventoryItemId', 'productCatalogId', 'quantity'],
	1: ['reasons'],
};

type Mode = 'withSerialNumber' | 'withoutSerialNumber' | 'fromNoah';

interface IProps extends DialogProps {
	repair: IRepair;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const AddLineToRepairForm = ({ repair, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const notify = useSnackbarNotify();
	const [add, isSubmitting] = useFormSubmit(repairsCommand_addLine);
	const [mode, setMode] = useState<Mode>('withSerialNumber');
	const [step, setStep] = useState<number>(0);
	const [selectedInventoryItem, setSelectedInventoryItem] = useState<IInventoryItem>();
	const [selectedStockItem, setSelectedStockItem] = useState<IProductCatalogItem>();
	const [hasTouchedSubmit, setHasTouchedSubmit] = useState<boolean>(false);
	const { isStacked } = useDialogsContext();
	const options: ILabelCaptionValue<Mode>[] = useMemo(() => {
		const x: ILabelCaptionValue<Mode>[] = [
			{ value: 'withSerialNumber', label: strings.productWithSerialNumber, caption: strings.tangibleArticleWithSerialNumber },
			{ value: 'withoutSerialNumber', label: strings.productWithoutSerialNumber, caption: strings.tangibleArticleWithoutSerialNumber },
		];
		return repair.isForPatient ? [...x, { value: 'fromNoah', label: strings.selectFromWhat('noah'), caption: 'Uitgelezen toestel vanuit Noah' }] : x;
	}, [repair, strings]);

	const handleSubmit = async (values: IAddRepairLineRequest, helpers: FormikHelpers<IAddRepairLineRequest>) => {
		const r = await add(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.itemAddedToRepair);
			confirm();
		} else {
			notify(r.result.errors.map(t => t.error).join(`\n`), 'error');
		}
	};

	const onChangeMode = (mode: Mode, props: FormikProps<IAddRepairLineRequest>) => {
		setFormValue<IAddRepairLineRequest>('inventoryItemId', undefined as any, props);
		setFormValue<IAddRepairLineRequest>('productCatalogId', undefined as any, props);
		setFormValue<IAddRepairLineRequest>('quantity', 1, props);
		setMode(mode);
	};

	const selectSerializedItem = (item: IInventoryItem | undefined, props: FormikProps<IAddRepairLineRequest>) => {
		setSelectedInventoryItem(item);
		setFormValue<IAddRepairLineRequest>('inventoryItemId', item?.id ?? (undefined as any), props);
		setFormValue<IAddRepairLineRequest>('productCatalogId', item?.productCatalogId ?? (undefined as any), props);
	};

	const selectStockItem = (item: IProductCatalogItem | undefined, props: FormikProps<IAddRepairLineRequest>) => {
		setSelectedStockItem(item);
		setFormValue<IAddRepairLineRequest>('productCatalogId', item?.id ?? (undefined as any), props);
	};

	const validateSelectedItem = (props: FormikProps<IAddRepairLineRequest>): boolean => {
		setHasTouchedSubmit(true);
		if (props.values.inventoryItemId || props.values.productCatalogId) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<Formik<IAddRepairLineRequest>
			validateOnMount
			initialValues={{
				inventoryItemId: undefined as any,
				productCatalogId: undefined as any,
				quantity: 1,
				reasons: [],
				repairId: repair.id,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			{props => (
				<Form>
					<Dialog
						{...rest}
						open={rest.open && isStacked === false}
						scroll='paper'
						maxWidth='lg'>
						<DialogTitleWithFormStepper
							title={strings.addItemForRepair}
							step={step}
							labels={[strings.product, strings.reason]}>
							<div className='df-col'>
								<Typography
									variant='caption'
									style={{ marginBottom: theme.spacing(1) }}>
									{strings.supplier}: '{repair.supplier.identifier}'
								</Typography>
								{repair.forPatient && (
									<Typography
										variant='caption'
										style={{ marginBottom: theme.spacing(1) }}>
										{strings.patient}: '{repair.forPatient.identifier}'
									</Typography>
								)}
							</div>
						</DialogTitleWithFormStepper>
						<DialogContent dividers>
							{step === 0 && (
								<div className='df-col'>
									<ExtendedRadioGroup<Mode>
										selected={mode}
										setSelected={val => onChangeMode(val, props)}
										options={options}
									/>
									<DividerWithCaption
										caption={strings.selectWhat(mode === 'withoutSerialNumber' ? strings.productWithoutSerialNumber : strings.productWithSerialNumber)}
									/>
									{mode === 'withSerialNumber' && (
										<>
											{selectedInventoryItem !== undefined && (
												<>
													<Typography
														variant='caption'
														color='primary'>
														{strings.selectedArticle}
													</Typography>
													<SelectedInventoryItemComponent
														item={selectedInventoryItem}
														onClear={() => selectSerializedItem(undefined, props)}
														style={{ marginBottom: 8 }}
													/>
												</>
											)}
											{selectedInventoryItem === undefined && (
												<SelectInventoryItem
													supplierId={repair.supplierId}
													patientId={repair.forPatientId}
													selectItem={item => selectSerializedItem(item, props)}
												/>
											)}
										</>
									)}

									{mode === 'withoutSerialNumber' && (
										<>
											{selectedStockItem !== undefined && (
												<>
													<Typography
														variant='caption'
														color='primary'>
														{strings.selectedArticle}
													</Typography>
													<SelectedProductCatalogItemComponent
														item={selectedStockItem}
														onClear={() => selectStockItem(undefined, props)}
														style={{ marginBottom: 8 }}
													/>
													<FormIntegerField<IAddRepairLineRequest>
														name='quantity'
														label={strings.quantity}
													/>
												</>
											)}
											{selectedStockItem === undefined && (
												<SelectStockItem
													supplier={repair.supplier}
													selectItem={item => selectStockItem(item, props)}
													style={{ marginBottom: 8 }}
												/>
											)}
										</>
									)}
									{mode === 'fromNoah' && (
										<SelectNoahDevice
											patientId={repair.forPatientId!}
											selected={selectedInventoryItem}
											selectItem={item => selectSerializedItem(item, props)}
											hasTouchedSubmit={hasTouchedSubmit}
											repair={repair}
										/>
									)}
									{hasTouchedSubmit && (
										<ErrorTextWithIcon
											error={strings.selectWhat(strings.anItem)}
											style={{ marginTop: theme.spacing(1) }}
										/>
									)}
								</div>
							)}
							{step === 1 && (
								<div className='df-col'>
									<FormRepairReasonsField<IAddRepairLineRequest>
										name='reasons'
										label={strings.reasons}
									/>
								</div>
							)}
						</DialogContent>
						<PageableFormDialogActions
							step={step}
							setStep={setStep}
							cancel={cancel}
							isSubmitting={isSubmitting}
							submitText={strings.create}
							schema={createSchema(strings)}
							stepsRecord={stepsRecord}
							validateMore={() => validateSelectedItem(props)}
						/>
					</Dialog>
				</Form>
			)}
		</Formik>
	);
};
