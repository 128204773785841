import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { calcOrderDetailRoute, calcRepairDetailRoute } from 'app/main/processes/ProcessesRoutes';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { formatDate } from 'framework/utils/date/formatDate';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { IDelivery, deliveriesCommand_patchDeliveryDate, deliveriesCommand_patchExternalReference } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DeliveryDispatchInfoForm } from '../forms/DeliveryDispatchInfoForm';

interface IProps {
	item: IDelivery;
	reload: VoidFunction;
}

export const DeliveryDetailPageSidebar = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();

	const onPatchExternalReference = () => {
		open(
			<StringModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.changeSomething(strings.externalReference)}
				label={strings.externalReference}
				initialValue={item.externalReference}
				cancel={cancel}
				submitText={strings.update}
				submitFunction={value => deliveriesCommand_patchExternalReference({ deliveryId: item.id, externalReference: value })}
			/>
		);
	};

	const onPatchDeliveryDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				formTitle={strings.changeWhat(strings.deliveryDate)}
				label={strings.referenceDate}
				initialValue={item.deliveryDate}
				submitFunction={date => deliveriesCommand_patchDeliveryDate({ deliveryId: item.id, deliveryDate: date })}
			/>
		);
	};

	const onPatchDispatchInfo = () => {
		open(
			<DeliveryDispatchInfoForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	return (
		<div
			className='df-col h100'
			style={{
				padding: 16,
				paddingRight: 8,
				minWidth: 200,
			}}>
			<div className='df-col gap-6'>
				<CaptionVerticalLabelledPropertyX label={strings.reference}>{item.reference}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.externalReference}
					edit={onPatchExternalReference}>
					{item.externalReference ? item.externalReference : strings.notApplicableAbbreviation}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.entryDate}>{formatDate(item.created)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.deliveryDate}
					edit={onPatchDeliveryDate}>
					{formatDate(item.deliveryDate)}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.supplier}>{item.supplierIdentifier}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.location}>{item.locationIdentifier}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.dispatchReference}
					edit={onPatchDispatchInfo}>
					{isNullOrUndefined(item.dispatchReference) ? strings.notApplicableAbbreviation : item.dispatchReference}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.dispatchDate}
					edit={onPatchDispatchInfo}>
					{isNullOrUndefined(item.dispatchDate) ? strings.notApplicableAbbreviation : formatDate(item.dispatchDate)}
				</CaptionVerticalLabelledPropertyX>
			</div>
			<DividerWithMargin />
			<div className='fg1'></div>
			{(item.isLinkedToOrder || item.isLinkedToRepair) && (
				<>
					<DividerWithMargin />
					{item.isLinkedToOrder && (
						<TextLinkButton
							startIcon={<OpenInBrowserIcon />}
							underline
							onClick={() => push(calcOrderDetailRoute(item.orderId!))}>
							{strings.viewLinkedOrder}
						</TextLinkButton>
					)}
					{item.isLinkedToRepair && (
						<TextLinkButton
							startIcon={<OpenInBrowserIcon />}
							underline
							onClick={() => push(calcRepairDetailRoute(item.repairId!))}>
							{strings.viewLinkedRepair}
						</TextLinkButton>
					)}
				</>
			)}
		</div>
	);
};
