import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteMultipleFreeSolo } from 'framework/forms/FormAutocompleteMultipleFreeSolo';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { ISupplier, suppliersCommand_patchBrands } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { SuppliersFilterDataContext } from '../SuppliersFilterDataContext';

interface IProps extends DialogProps {
	supplier: ISupplier;
	cancel: VoidFunction;
	confirm: VoidFunction;
}

interface IChangeBrandsModel {
	brands: string[];
}

export const SupplierBrandsForm = ({ supplier, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [replaceBrands, isSubmitting] = useFormSubmit(suppliersCommand_patchBrands);
	const [data] = useCacheContext(SuppliersFilterDataContext);

	const handleSubmit = async (values: IChangeBrandsModel, helpers: FormikHelpers<IChangeBrandsModel>) => {
		const r = await replaceBrands({ brands: values.brands, supplierId: supplier.id });
		if (handleFormResponse(r, helpers)) {
			notify(strings.supplierUpdated);
			confirm();
		}
	};

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IChangeBrandsModel>
			initialValues={{ brands: supplier.brands }}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.brands}</DialogTitle>
					<DialogContent dividers>
						<FormAutocompleteMultipleFreeSolo<IChangeBrandsModel>
							name='brands'
							label={strings.brands}
							options={data.brands}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
