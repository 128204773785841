import { RfiaInvoiceProcessStep, RfiaInvoiceProcessSteps } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { StepsField } from 'shared/process/StepsField';
import { RecordContext } from 'shared/records/RecordContext';
import { isInvoiceProcessStepOptional } from './isInvoiceProcessStepOptional';

export const FormInvoiceProcessStepsField = <TModel extends { steps: string[] }>() => {
	const { rfiaInvoiceProcessStepRecord } = useContext(RecordContext);

	return (
		<StepsField<TModel, RfiaInvoiceProcessStep>
			steps={RfiaInvoiceProcessSteps}
			name='steps'
			isOptional={isInvoiceProcessStepOptional}
			record={rfiaInvoiceProcessStepRecord}
		/>
	);
};
