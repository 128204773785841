import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	confirm: (mode: OverwriteOrNewMode) => void;
	cancel: VoidFunction;
	overwriteCaption: string;
	isOverwriting: boolean;
}

export type OverwriteOrNewMode = 'overwrite' | 'new';

export const SelectOverwriteOrNewDialog = ({ cancel, confirm, overwriteCaption, isOverwriting, ...rest }: IProps) => {
	const strings = useLocalization();
	const [mode, setMode] = useState<OverwriteOrNewMode>('overwrite');

	return (
		<Dialog
			fullWidth
			maxWidth='sm'
			{...rest}>
			<DialogTitle>{strings.makeAChoice}</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<OverwriteOrNewMode>
					selected={mode}
					setSelected={setMode}
					options={[
						{ value: 'overwrite', label: strings.overwrite, caption: overwriteCaption },
						{ value: 'new', label: strings.new, caption: strings.saveAsNew },
					]}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				onSubmit={() => confirm(mode)}
				submitText={mode === 'overwrite' ? strings.save : strings.select}
				isSubmitting={isOverwriting}
			/>
		</Dialog>
	);
};
