import React from 'react';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { ReturnExportProp, IReturn } from 'gen/ApiClient';
import { ReturnsSelectableColumn } from './ReturnsSelectableColumn';

interface IViewContext extends ITableViewContext<ReturnsSelectableColumn, IReturn> {
	exportRecord: Record<ReturnExportProp, string>;
}

export const ReturnsListViewContext = React.createContext<IViewContext>(undefined as any);
