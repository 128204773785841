import React from 'react';
import { Trigger } from 'framework/hooks/useTrigger';

interface IContext {
	isOpen: boolean;
	open: (element: JSX.Element, reload?: VoidFunction) => void;
	confirm: VoidFunction;
	cancel: VoidFunction;
	trigger: Trigger;
	isStacked: boolean;
	stackCount: number;
}

export const DialogsContext = React.createContext<IContext>(undefined as any);
