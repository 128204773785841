import { IHeader, TableCellAlign } from './IHeader';

export function createHeader<T>(
	text: string,
	evaluate: (inst: T, index: number) => string | JSX.Element | undefined,
	align?: TableCellAlign,
	cellStyle?: React.CSSProperties,
	key?: string
): IHeader<T> {
	return { text: text, key: key ?? text, evaluate: evaluate, align, onlyShowOnHover: false, cellStyle: cellStyle };
}
