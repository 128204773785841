import React from 'react';
import { ArticlePropertyKeysCacheProvider } from '../articlePropertyKeys/context/ArticlePropertyKeysCacheProvider';
import { ViewProvider as InventoryItemsViewProvider } from '../detail/inventoryItems/context/ViewProvider';
import { ViewProvider as TransactionsViewProvider } from '../detail/transactions/context/ViewProvider';
import { ViewProvider as SearchViewProvider } from '../search/context/ViewProvider';
import { FilterDataProvider } from './FilterDataProvider';
import { NavigationProvider } from './NavigationProvider';
import { QueryProvider } from './QueryProvider';
import { ViewProvider } from './ViewProvider';

export const ProductCatalogProviders = ({ children }: any) => {
	return (
		<ArticlePropertyKeysCacheProvider>
			<QueryProvider>
				<ViewProvider>
					<FilterDataProvider>
						<SearchViewProvider>
							<InventoryItemsViewProvider>
								<TransactionsViewProvider>
									<NavigationProvider>{children}</NavigationProvider>
								</TransactionsViewProvider>
							</InventoryItemsViewProvider>
						</SearchViewProvider>
					</FilterDataProvider>
				</ViewProvider>
			</QueryProvider>
		</ArticlePropertyKeysCacheProvider>
	);
};
