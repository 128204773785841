import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IRequestForInsuranceAllowance, rfiasCommand_updateInvoiceProcessSteps } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormInvoiceProcessStepsField } from './FormInvoiceProcessStepsField';

interface IModel {
	steps: string[];
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			steps: yup.array().of(yup.string().defined()).defined(),
		})
		.defined();
};

interface IProps extends DialogProps {
	item: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const InvoiceProcessStepsForm = ({ confirm, cancel, item, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(rfiasCommand_updateInvoiceProcessSteps);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await update(item.id, values.steps);
		if (handleValidateResponse(r, helpers, 'steps')) {
			notify(strings.stepsChanged);
			confirm();
		}
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{ steps: item.invoiceProcess.steps }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='sm'
					fullWidth>
					<DialogTitle>
						<div>{strings.changeSomething(strings.workflow)}</div>
					</DialogTitle>
					<DialogContent
						dividers
						style={{ paddingTop: 0, paddingBottom: 0 }}>
						<FormInvoiceProcessStepsField />
					</DialogContent>
					<CancelSubmitFormDialogActions<IModel>
						cancel={cancel}
						submitText={strings.changeSomething(strings.steps)}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
