import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { useLoggedInLocation } from 'app/auth/useLoggedInLocation';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { FormSelectLocationField } from 'app/main/settings/locations/forms/FormSelectLocationField';
import { FormSelectAudiologistField } from 'app/main/settings/users/forms/FormSelectAudiologistField';
import { Form, Formik } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { IStrings } from 'localization/IStrings';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';
import { FormSelectLanguageField } from './FormSelectLanguageField';
import { ISelectAudiologistLocationAndLanguageModel } from './ISelectAudiologistLocationAndLanguageModel';

const createSchema = (strings: IStrings) => {
	return yup.object<ISelectAudiologistLocationAndLanguageModel>({
		attendingAudiologistId: yup.string().required(strings.formRequired(strings.audiologist)),
		locationId: yup.string().required(strings.formRequired(strings.location)),
		language: yup.string().required(strings.formRequired(strings.language)),
	});
};

interface IProps extends DialogProps {
	initialId?: string;
	confirm: (model: ISelectAudiologistLocationAndLanguageModel) => void;
	cancel: VoidFunction;
	isSubmitting: boolean;
	confirmText?: string;
}

export const SelectAudiologistLocationAndLanguageForm = ({ initialId, confirm, cancel, isSubmitting, confirmText, ...rest }: IProps) => {
	const strings = useLocalization();
	const user = useLoggedInUser();
	const location = useLoggedInLocation();
	const currentLanguage = useCurrentLanguage();

	const handleSubmit = (values: ISelectAudiologistLocationAndLanguageModel) => {
		confirm(values);
	};

	return (
		<Formik<ISelectAudiologistLocationAndLanguageModel>
			validateOnMount
			initialValues={{
				attendingAudiologistId: user.id,
				locationId: location ?? '',
				language: currentLanguage,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.selectWhat(strings.audiologist)}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormSelectAudiologistField<ISelectAudiologistLocationAndLanguageModel>
							name='attendingAudiologistId'
							label={strings.audiologist}
						/>
						<FormSelectLocationField<ISelectAudiologistLocationAndLanguageModel>
							name='locationId'
							label={strings.location}
						/>
						<FormSelectLanguageField<ISelectAudiologistLocationAndLanguageModel>
							name='language'
							label={strings.language}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={confirmText ?? strings.select}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
