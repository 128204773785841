import FrameworkNavigationProvider from 'framework/context/NavigationProvider';
import React from 'react';
import { ProductCatalogItemDetailTabType } from '../detail/ProductCatalogItemDetailTabType';
import { NavigationContext } from './NavigationContext';

export const NavigationProvider = ({ children }: any) => {
	return (
		<FrameworkNavigationProvider<ProductCatalogItemDetailTabType>
			context={NavigationContext}
			fallback='overview'>
			{children}
		</FrameworkNavigationProvider>
	);
};
