import { DialogProps } from '@material-ui/core';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { healthInsuranceFundMetaCommand_patchAdvisoryPhysicianAddress, IAddressModel, IHealthInsuranceFundWithAddressDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { AddressModelForm } from 'shared/address/AddressModelForm';
import { AlsoUpdateAdvisoryPhysicianAddressForm } from './AlsoUpdateAdvisoryPhysicianAddressForm';
import { toModel } from './toModel';

interface IProps extends DialogProps {
	item: IHealthInsuranceFundWithAddressDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchHifAdvisoryPhysicianAddressForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [address, setAddress] = useState<IAddressModel>();
	const [patch, isSubmitting] = useFormSubmit(healthInsuranceFundMetaCommand_patchAdvisoryPhysicianAddress);
	const strings = useLocalization();
	const title = useMemo(() => `${strings.changeWhat(`${strings.address} ${strings.advisoryPhysician}`)}`, [strings]);

	const onSubmit = async (address: IAddressModel, alsoUpdate: boolean) => {
		const r = await patch({ address: address, hifCode: item.code, isUseSame: alsoUpdate });
		if (r.isSuccess) {
			confirm();
		}
	};

	if (address === undefined) {
		return (
			<AddressModelForm
				{...rest}
				cancel={cancel}
				confirm={setAddress}
				initialValue={toModel(item.advisoryPhysicianAddress)}
				title={title}
			/>
		);
	} else {
		return (
			<AlsoUpdateAdvisoryPhysicianAddressForm
				{...rest}
				confirm={alsoUpdate => onSubmit(address!, alsoUpdate)}
				cancel={cancel}
				isSubmitting={isSubmitting}
				title={title}
			/>
		);
	}
};
