import { Dialog, DialogProps, Grid, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import { calcTenderDetailRoute } from 'app/main/financial/routes';
import { DocumentActions } from 'documents/DocumentActions';
import { DocumentFromTemplateForm } from 'documents/DocumentFromTemplateForm';
import { DocumentHistory } from 'documents/DocumentHistory';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DetailDialogGridComponent } from 'framework/components/detailDialog/DetailDialogGridComponent';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { DocumentActionsLeftContent } from 'framework/components/detailDialog/DocumentActionsLeftContent';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { FileDocumentOutlineIcon } from 'framework/components/icons/FileDocumentOutlineIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useApiEffectWithDefer } from 'framework/hooks/useApiEffectWithDefer';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { TemplateType, documentsQuery_single, tendersQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { ContributionLinesTable } from './ContributionLinesTable';
import { TenderLinesTable } from './TenderLinesTable';

interface IProps extends DialogProps {
	id: string;
	close: VoidFunction;
	withCreate?: boolean;
}

export const TenderDetailDialog = ({ id, close, withCreate = false, ...rest }: IProps) => {
	const strings = useLocalization();
	const [tender, reloadTender] = useApiEffect(tendersQuery_single, id);
	const [tenderDocument, reloadTenderDocument] = useApiEffectWithDefer(documentsQuery_single, tender?.tenderDocumentId ?? '', isNotNullNorUndefined);
	const [orderForm, reloadOrderForm] = useApiEffectWithDefer(documentsQuery_single, tender?.orderFormDocumentId ?? '', isNotNullNorUndefined);
	const reload = useCallback(() => {
		reloadTender();
		reloadTenderDocument();
		reloadOrderForm();
	}, [reloadTender, reloadTenderDocument, reloadOrderForm]);
	const { push } = useHistory();
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);
	const { templateTypeRecord } = useContext(RecordContext);

	useEffect(() => {
		if (withCreate === true) {
			open(
				<DocumentFromTemplateForm
					{...rest}
					context='Tender'
					contextId={id}
					templatePredefinedType='Tender'
					confirm={confirm}
					cancel={cancel}
					title={`${strings.createADocumentRightAwayQuestion} (${templateTypeRecord['Tender']})`}
					submitText={strings.selectWhatAndCreate(strings.template)}
					cancelText={strings.closeThisWindow}
				/>
			);
		}
		// eslint-disable-next-line
	}, [withCreate]);

	if (tender === undefined) {
		return <div></div>;
	}

	const onViewDetail = () => {
		close();
		push(calcTenderDetailRoute(tender.id));
	};

	const onCreateDocument = (type: TemplateType) => {
		open(
			<DocumentFromTemplateForm
				open
				title={templateTypeRecord[type]}
				templatePredefinedType={type}
				context='Tender'
				contextId={tender.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			scroll='paper'
			fullWidth
			maxWidth='md'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<ReceiptOutlinedIcon />}>
					<Typography variant='h1'>{tender.reference}</Typography>
					{/* <TenderStatusChip
						item={tender}
						style={{ marginTop: theme.spacing(0.5), marginBottom: theme.spacing(0.5) }}
					/> */}
					<LabelledProperty
						label={strings.date}
						property={formatDate(tender.created)}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.validTo}
						property={formatDate(tender.validUntil)}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.totalAmount}
						property={formatCurrency(tender.priceInVat)}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.contributions}
						property={formatCurrency(tender.contributionsValue)}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.amountMinusContributions}
						property={formatCurrency(tender.priceInVatMinusContributions)}
						withoutMargin
					/>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								startIcon={<OpenInNewIcon />}
								onClick={onViewDetail}>
								{strings.navigateToDetailsPage}
							</SmallPrimaryTextButton>
							{tenderDocument === undefined && (
								<SmallPrimaryTextButton
									startIcon={<FileDocumentOutlineIcon />}
									onClick={() => onCreateDocument('Tender')}>{`${strings.createWhat(strings.document)} (${strings.tender})`}</SmallPrimaryTextButton>
							)}
							{orderForm === undefined && (
								<SmallPrimaryTextButton
									startIcon={<FileDocumentOutlineIcon />}
									onClick={() => onCreateDocument('TenderOrderForm')}>
									{strings.createWhat(strings.orderForm)}
								</SmallPrimaryTextButton>
							)}
						</div>
					}
				/>
				<GridDividerWithCaption caption={strings.recipient} />
				<PropertyInGrid
					label={strings.name}
					value={tender.recipientName}
				/>
				<PropertyInGrid
					label={strings.addressLine}
					value={tender.recipient.addressLine}
				/>
				<PropertyInGrid
					label={strings.zip}
					value={`${tender.recipient.zip} ${tender.recipient.city}`}
				/>
				<GridDividerWithCaption caption={strings.contents} />
				<Grid
					item
					xs={12}>
					<TenderLinesTable lines={tender.lines} />
				</Grid>
				{tender.hasContributions && (
					<>
						<GridDividerWithCaption caption={strings.contributions} />
						<Grid
							item
							xs={12}>
							<ContributionLinesTable lines={tender.contributions} />
						</Grid>
					</>
				)}
				{tenderDocument && (
					<>
						<GridDividerWithCaption caption={`${strings.document} ${strings.tender}`} />
						<DetailDialogGridComponent
							leftContent={<DocumentActionsLeftContent />}
							rightContent={
								<div className='df-col-as'>
									<DocumentActions
										document={tenderDocument}
										reload={reloadTenderDocument}
										close={close}
									/>
								</div>
							}
						/>
						<DocumentHistory document={tenderDocument} />
					</>
				)}
				{orderForm && (
					<>
						<GridDividerWithCaption caption={strings.orderForm} />
						<DetailDialogGridComponent
							leftContent={<DocumentActionsLeftContent />}
							rightContent={
								<div className='df-col-as'>
									<DocumentActions
										document={orderForm}
										reload={reloadOrderForm}
										close={close}
									/>
								</div>
							}
						/>
						<DocumentHistory document={orderForm} />
					</>
				)}
			</DialogContentGrid>
		</Dialog>
	);
};
