import { Typography, useTheme } from '@material-ui/core';
import React from 'react';

interface IProps {
	children: any;
	style?: React.CSSProperties;
}

export const ProcessErrorTypography = ({ children, style }: IProps) => {
	const theme = useTheme();
	return (
		<Typography
			variant='caption'
			color='error'
			style={{ whiteSpace: 'pre', marginBottom: theme.spacing(1), ...style }}>
			{children}
		</Typography>
	);
};
