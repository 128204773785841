import { InputAdornment, TextField, useTheme } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React, { useEffect, useState } from 'react';

interface IProps {
	text: string | undefined;
	setText: (text: string) => void;
	style?: React.CSSProperties;
	fontWeight?: 'bold' | 'normal';
	fixedHeight?: boolean;
}

export const EditableDescription = ({ text, setText, style, fontWeight }: IProps) => {
	const [isEditState, setEditState] = useState<boolean>(false);
	const [localState, setLocalState] = useState<string | undefined>(text);
	const theme = useTheme();

	useEffect(() => {
		setLocalState(text);
	}, [text]);

	const onBlur = () => {
		if (localState !== text) {
			setText(localState ?? '');
		}
		setEditState(false);
	};

	return (
		<div className='df-row-ac fg1'>
			{isEditState && (
				<TextField
					className='fg1'
					style={style}
					value={localState}
					onChange={e => setLocalState(e.target.value)}
					onBlur={onBlur}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<HighlightOffIcon
									fontSize='small'
									style={{ cursor: 'pointer', color: theme.palette.text.secondary }}
									onClick={() => setEditState(false)}
								/>
							</InputAdornment>
						),
					}}
				/>
			)}
			{isEditState === false && (
				<div
					className='df-row-ac gap-4 fg1'
					onClick={() => setEditState(true)}
					style={{ cursor: 'pointer' }}>
					<div
						className='df-col-ac'
						style={{
							fontSize: 18,
							color: theme.palette.text.secondary,
							marginRight: 4,
						}}>
						<EditOutlinedIcon fontSize='small' />
					</div>
					<div
						style={{ ...style, fontWeight: fontWeight ?? 'bold' }}
						onClick={() => setEditState(true)}>
						{text}
					</div>
				</div>
			)}
		</div>
	);
};
