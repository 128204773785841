import React from 'react';
import { SalesDetailTabType } from './SalesDetailTabType';

interface IContext {
	selectedTab: SalesDetailTabType;
	setSelectedTab: React.Dispatch<React.SetStateAction<SalesDetailTabType>>;
	salesId: string;
	setSalesId: React.Dispatch<React.SetStateAction<string>>;
	TabTypeRecord: Record<SalesDetailTabType, string>;
}

export const SalesDetailPageViewContext = React.createContext<IContext>(undefined as any);
