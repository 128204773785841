import React, { useContext } from 'react';
import { PreloadCacheProvider } from 'framework/context/PreloadCacheProvider';
import { Application, softwareQuery_concurrencyToken, softwareQuery_latestBuild } from 'gen/ApiClient';
import { PreloadContext } from 'shared/context/PreloadContext';
import { LatestBuildContext } from './LatestBuildContext';

interface IProps {
	children: any;
}

export const LatestBuildProvider = ({ children }: IProps) => {
	const { eidLatestBuild } = useContext(PreloadContext);

	return (
		<PreloadCacheProvider<number>
			concurrencyF={softwareQuery_concurrencyToken}
			loadF={() => softwareQuery_latestBuild('Eid' as Application)}
			context={LatestBuildContext}
			initialData={eidLatestBuild}>
			{children}
		</PreloadCacheProvider>
	);
};
