import { useTheme } from '@material-ui/core';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IProductCatalogItemSummaryForUblPurchase } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';

interface IProps {
	productCatalogItem: IProductCatalogItemSummaryForUblPurchase;
	unitPrice: number;
	style?: React.CSSProperties;
}

export const ReferencePriceSpan = ({ productCatalogItem, unitPrice, style }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const isSamePrice = useMemo(() => productCatalogItem.purchaseListPrice === unitPrice, [productCatalogItem, unitPrice]);

	return (
		<span
			style={{
				color: isSamePrice ? theme.palette.text.secondary : theme.palette.error.main,
				fontSize: 12,
				...style,
			}}>
			{`(${strings.referencePriceShort.toLowerCase()}: ${formatCurrency(productCatalogItem.purchaseListPrice)})`}
		</span>
	);
};
