import { Card, CardMedia, Divider, Grid, Typography, useTheme } from '@material-ui/core';
import CategoryIcon from '@material-ui/icons/Category';
import { useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim';
import { ProductCatalogFilterDataContext } from 'app/main/inventoryManagement/productCatalog/context/ProductCatalogFilterDataContext';
import { calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { createType } from 'framework/utils/createType';
import { IQueryResult, IStockItemDto, IStockItemDtosQueryParams, ProductCatalogItemType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { XViewBadgeIconButton } from 'shared/icons/XViewBadgeIconButton';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { StockItemDtoSelectableColumn } from './StockItemDtoSelectableColumn';
import { StockItemsExportButtonMenuAndDialog } from './StockItemsExportButtonMenuAndDialog';

const quickFilterToTypeRecord: Record<QuickFilter, ProductCatalogItemType | undefined> = {
	all: undefined,
	hearingInstrument: 'HearingInstrument',
};

const QuickFilters = createType('all', 'hearingInstrument');
export type QuickFilter = typeof QuickFilters[number];

interface IProps {
	stockEntryId: string | undefined;
	params: IStockItemDtosQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IStockItemDtosQueryParams>>;
	queryResult: IQueryResult<IStockItemDto> | undefined;
}

export const StockItems = ({ stockEntryId, params, setParams, queryResult }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const [searchData] = useCacheContext(ProductCatalogFilterDataContext);
	const { quickFilter, quickFilterRecord, setQuickFilter, columns, setColumns, columnsRecord, headers } = useContext(ViewContext);
	const { push } = useHistory();
	const hasVeppClaim = useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim();
	const exclusions: StockItemDtoSelectableColumn[] = useMemo(() => (hasVeppClaim ? [] : (['stockValue'] as StockItemDtoSelectableColumn[])), [hasVeppClaim]);

	useEffect(() => {
		setParams(pars =>
			onResetToPage1<IStockItemDtosQueryParams>({
				...pars,
				keyFilterType: quickFilterToTypeRecord[quickFilter],
				types: quickFilter === 'all' ? pars.types : [],
			})
		);
		// eslint-disable-next-line
	}, [quickFilter]);

	if (queryResult === undefined || searchData === undefined) {
		return <div></div>;
	}

	return (
		<>
			<div className='df-row-ac jc-sb'>
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder={strings.searchVerb}
					style={{ width: '400px' }}
					variant='paper'
				/>
			</div>
			<Grid
				item
				xs={12}>
				{queryResult.unfilteredCount > 0 && (
					<>
						<Card>
							<div
								className='df-row-ac jc-sb'
								style={{ padding: theme.spacing(1) }}>
								<ButtonGroupWithSelection<QuickFilter>
									options={QuickFilters}
									selected={quickFilter}
									setSelected={setQuickFilter}
									renderOption={t => quickFilterRecord[t]}
								/>
								<div>
									{stockEntryId && (
										<StockItemsExportButtonMenuAndDialog
											stockEntryId={stockEntryId}
											params={params}
											queryResult={queryResult}
										/>
									)}
									<SelectColumnsButtonAndDialog
										selectedColumns={columns}
										setSelectedColumns={setColumns}
										record={columnsRecord}
										exclusions={exclusions}
										view='stockItems'
									/>
								</div>
							</div>
							<Divider />
							<div className='df-row-ac jc-sb'>
								<Filter
									params={params}
									onFilter={params => setParams(onResetToPage1(params))}
									paddingLeft={theme.spacing(1.5)}
									data={searchData}
								/>
							</div>
							<Divider />
							<CardMedia>
								<PageableTableWithColumnSelection<IStockItemDto, StockItemDtoSelectableColumn | string>
									queryResult={queryResult}
									headers={headers}
									sortParams={params.sortParams}
									pageParams={params.pageParams}
									setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
									getKey={inst => inst.productCatalogItemId}
									selectedColumns={columns}
									appendColumn={createEmptyHeader<IStockItemDto>(
										'navigate',
										t => (
											<TooltipWithSpan title={strings.clickToViewProductCatalog}>
												<XViewBadgeIconButton
													size='small'
													onClick={() => push(calcProductCatalogDetailRoute(t.productCatalogItemId!))}
													style={{ marginRight: 8 }}>
													<CategoryIcon fontSize='small' />
												</XViewBadgeIconButton>
											</TooltipWithSpan>
										),
										'right'
									)}
								/>
							</CardMedia>
						</Card>
					</>
				)}
				{queryResult.unfilteredCount === 0 && (
					<Typography
						variant='body2'
						style={{ fontStyle: 'italic' }}>
						{strings.noItemsInStockContent}
					</Typography>
				)}
			</Grid>
		</>
	);
};
