import { useParamsFromQueryContext } from 'framework/hooks/useParamsFromQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { QuickFilter } from '../CustomerAccountsList';
import { SelectableColumn } from '../SelectableColumn';
import { createColumnRecord } from '../utils/createColumnRecord';
import { createExportRecord } from '../utils/createExportRecord';
import { createHeaders } from '../utils/createHeaders';
import { createQfRecord } from '../utils/createQfRecord';
import { QueryContext } from './QueryContext';
import { ViewContext } from './ViewContext';

const DefaultColumns: SelectableColumn[] = [
	'identifier',
	'accountType',
	'outstandingBalance',
	'lastSettlementProposalMetaReference',
	'outstandingBalanceNotOnSettlementProposals',
];

export const ViewProvider = ({ children }: any) => {
	const { customerAccountTypeRecord } = useContext(RecordContext);
	const strings = useLocalization();
	const [qf, setQf] = useState<QuickFilter>('all');
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);
	const colRecord = useMemo(() => createColumnRecord(strings), [strings]);
	const headers = useMemo(() => createHeaders(colRecord, customerAccountTypeRecord, strings), [colRecord, customerAccountTypeRecord, strings]);
	const quickFilterRecord = useMemo(() => createQfRecord(strings), [strings]);
	const exportRecord = useMemo(() => createExportRecord(strings), [strings]);
	const { setParams } = useParamsFromQueryContext(QueryContext);

	useEffect(() => {
		setParams(params =>
			onResetToPage1({
				...params,
				hasOutstandingBalance: qf === 'withOutstandingBalance' ? true : undefined,
				hasOutstandingBalanceNotOnSettlementProposals: qf === 'withOutstandingBalanceNotOnSettlementProposals' ? true : undefined,
			})
		);
		// eslint-disable-next-line
	}, [qf]);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
				quickFilter: qf,
				setQuickFilter: setQf,
				quickFilterRecord: quickFilterRecord,
				exportRecord: exportRecord,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
