import React from 'react';
import { PurchasesUblBufferQueryProvider } from './buffer/PurchasesUblBufferQueryProvider';
import { PurchaseDetailPageViewProvider } from './detail/PurchaseDetailPageViewProvider';
import { PurchasesQueryProvider } from './list/PurchasesQueryProvider';
import { PurchasesFilterDataProvider } from './PurchasesFilterDataProvider';

export const PurchasesProviders = ({ children }: any) => {
	return (
		<PurchasesFilterDataProvider>
			<PurchasesQueryProvider>
				<PurchasesUblBufferQueryProvider>
					<PurchaseDetailPageViewProvider>{children}</PurchaseDetailPageViewProvider>
				</PurchasesUblBufferQueryProvider>
			</PurchasesQueryProvider>
		</PurchasesFilterDataProvider>
	);
};
