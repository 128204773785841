import FindInPageIcon from '@material-ui/icons/FindInPage';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IProductCatalogItem, IProductCatalogItemSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { useViewDocumentation } from 'shared/utils/useViewDocumentation';
import { BebatForm } from '../forms/BebatForm';

interface IProps {
	item: IProductCatalogItem | IProductCatalogItemSummary;
	reload?: VoidFunction;
	hideTitle?: boolean;
	label?: string;
}

export const BebatDisplay = ({ item, reload, hideTitle, label }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const onViewBebatTarifs = useViewDocumentation('bebat-tarifs');

	const onEdit = () => {
		open(
			<BebatForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<>
			{hideTitle !== true && (
				<CardTitleTypography
					title='Bebat'
					withPaddingTop
				/>
			)}
			<LabelledProperty
				label={label ? label : strings.rate}
				property={item.hasBebat ? formatCurrency(item.bebatUnitPriceInVat, 3) : undefined}
				undefinedText={strings.noRateDefined}
				edit={reload ? onEdit : undefined}
				secondaryAction={onViewBebatTarifs}
				secondaryActionIcon={<FindInPageIcon />}
			/>
		</>
	);
};
