import ShopTwoOutlinedIcon from '@material-ui/icons/ShopTwoOutlined';
import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { ProcessesPageBreadcrumbTitle } from '../ProcessesPageBreadcrumbTitle';
import { RequestsForInsuranceAllowanceRoute } from '../ProcessesRoutes';

export const RfiasPageBreadcrumbs = () => {
	const strings = useLocalization();

	return (
		<div className='df-row-ac'>
			<ProcessesPageBreadcrumbTitle />
			<PageBreadcrumbDivider />
			<PageBreadcrumb
				text={strings.requestForInsuranceAllowances}
				route={RequestsForInsuranceAllowanceRoute}
				icon={<ShopTwoOutlinedIcon />}
			/>
		</div>
	);
};
