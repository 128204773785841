import { DialogProps } from '@material-ui/core';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Try } from 'framework/Try';
import { IValidateResponse } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { SelectUserForm } from './SelectUserForm';

interface IProps extends DialogProps {
	submitFunction: (value: string) => Promise<Try<IValidateResponse>>;
	confirm: VoidFunction;
	cancel: VoidFunction;
	initialValue?: string;
}

export const LinkUserForm = ({ confirm, cancel, initialValue, submitFunction, ...rest }: IProps) => {
	const [submit, isSubmitting] = useFormSubmit(submitFunction);
	const strings = useLocalization();

	const onConfirm = async (id: string, setError: (error: string) => void) => {
		handleValidateResponseBase(await submit(id), setError, confirm);
	};

	return (
		<SelectUserForm
			{...rest}
			fullWidth
			confirm={onConfirm}
			cancel={cancel}
			initialId={initialValue}
			isSubmitting={isSubmitting}
			submitText={strings.link}
		/>
	);
};
