import { Checkbox, Divider, ListSubheader } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { IOption } from '../IOption';
import { useAnchorElement } from '../hooks/useAnchorElement';
import { ClearFilterTextLinkButton } from './ClearFilterTextLinkButton';
import { FilterButton } from './FilterButton';
import { FilterMenu } from './FilterMenu';
import { FilterMenuItem } from './FilterMenuItem';
import { FilterMenuText } from './FilterMenuText';
import { FilterMenuTextFromOption } from './FilterMenuTextFromOption';
import { ListFilterSearch } from './ListFilterSearch';

interface IProps<T extends string | number | symbol> {
	options: IOption<T>[];
	selected: T[];
	setSelected: (selected: T[]) => void;
	label: string;
	icon?: React.ReactNode;
	title?: string;
	orderRecord?: Record<T, number>;
	skipSort?: boolean;
}

export const ListFilterComponent = <T extends string | number | symbol>({ options, selected, setSelected, label, icon, orderRecord, skipSort }: IProps<T>) => {
	const orderedList = useMemo(
		() => (skipSort === true ? options : _.orderBy(options, orderRecord ? t => orderRecord[t.id] : t => t.identifier)),
		[options, orderRecord, skipSort]
	);
	const strings = useLocalization();
	const [values, setValues] = useState<T[]>(selected); //selection
	const [filtered, setFiltered] = useState<IOption<T>[]>(orderedList);
	const [anchor, open, close] = useAnchorElement();
	const [search, setSearch] = useState<string>('');
	const isLocallyFiltered = useMemo(() => values.length > 0, [values]);

	useEffect(() => {
		setValues(selected);
		setSearch('');
	}, [selected]);

	useEffect(() => {
		if (search !== undefined && search !== '') {
			const x = orderedList.filter(t => (t.identifier ?? '').toLowerCase().includes(search.toLowerCase()));
			setFiltered(x);
		} else {
			setFiltered(orderedList);
		}
		// TODO Validate this exclusion
		// eslint-disable-next-line
	}, [search, orderedList]);

	const handleClose = () => {
		if (values !== selected) {
			setSelected(values);
		}
		close();
	};

	const handleClickItem = (val: T) => {
		values.indexOf(val) > -1 ? setValues(values.filter(t => t !== val)) : setValues([...values, val]);
	};

	return (
		<>
			<FilterButton
				handleOpen={open}
				badgeContent={selected.length}
				label={label}
				icon={icon}
			/>
			<FilterMenu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={handleClose}>
				<ListSubheader style={{ background: common.white, padding: 0, paddingTop: 8 }}>
					<ListFilterSearch
						style={{ paddingLeft: 16, paddingBottom: 4 }}
						search={search}
						setSearch={setSearch}
					/>
					<Divider />
					<FilterMenuItem
						onClick={() => {
							values.length > 0 ? setValues([]) : setValues(filtered.map(t => t.id));
						}}>
						<Checkbox
							checked={values.length === options.length}
							indeterminate={values.length > 0 && values.length < options.length}
							size='small'
							style={{ padding: 0 }}
						/>
						<FilterMenuText identifier={search ? strings.selectAllBelow : strings.selectAll} />
					</FilterMenuItem>
					<Divider />
					<ClearFilterTextLinkButton
						style={{ height: 36, paddingLeft: 16 }}
						isFiltered={isLocallyFiltered}
						onClear={() => setValues([])}
					/>
					<Divider />
				</ListSubheader>
				{filtered.map(option => (
					<FilterMenuItem
						key={option.id.toString()}
						onClick={() => handleClickItem(option.id)}
						value={option.id.toString()}>
						<Checkbox
							checked={values.indexOf(option.id) > -1}
							size='small'
							style={{ padding: 0 }}
						/>
						<FilterMenuTextFromOption option={option} />
					</FilterMenuItem>
				))}
			</FilterMenu>
		</>
	);
};
