import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { setFieldError } from 'framework/forms/utils/setFieldError';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { FormSelectOrCreateEntPhysicianOfficeField } from './FormSelectOrCreateEntPhysicianOfficeField';

interface IModel {
	id: string;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		id: yup.string().required(strings.formRequired(strings.office)),
	});
};

interface IProps extends DialogProps {
	entPhysicianId: string;
	initialId?: string;
	confirm: (id: string, setError: (error: string) => void) => void;
	cancel: VoidFunction;
	isSubmitting: boolean;
}

export const SelectEntPhysicianOfficeForm = ({ entPhysicianId, initialId, confirm, cancel, isSubmitting, ...rest }: IProps) => {
	const strings = useLocalization();
	const { isStacked } = useDialogsContext();

	const handleSubmit = (values: IModel, helpers: FormikHelpers<IModel>) => {
		confirm(values.id, (error: string) => setFieldError<IModel>('id', error, helpers));
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{
				id: initialId ?? '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}
					open={rest.open && isStacked === false}>
					<DialogTitle>{strings.selectWhat(strings.office)}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormSelectOrCreateEntPhysicianOfficeField<IModel>
							name='id'
							label={strings.office}
							entPhysicianId={entPhysicianId}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.link}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};

// interface IInnerProps extends DialogProps {
//     cancel: VoidFunction;
//     isSubmitting: boolean;
//     entPhysicianId: string;
//     initialId: string | undefined;
// }

// const InnerDialog = ({ entPhysicianId, initialId, cancel, isSubmitting, ...rest }: IInnerProps) => {
//     const strings = useLocalization();
//     const props = useFormikContext<IModel>();
//     const { isStacked } = useDialogsContext();
//     const call = useApiCall();
//     const [data, setData] = useState<IEntPhysician>();

//     const load = async (isInitial: boolean) => {
//         const r = await call(entPhysicianId);
//         if (r.isSuccess) {
//             setData(r.result);
//             if (isInitial && (initialId === undefined || initialId === null)) {
//                 setFormValue<IModel>('id', r.result.defaultOfficeId, props);
//             } else if (isInitial && initialId) {
//                 setFormValue<IModel>('id', initialId, props);
//             }
//         }
//     }

//     useEffect(() => {
//         load(true);
//         // eslint-disable-next-line
//     }, []);

//     if (data === undefined) {
//         return <div></div>
//     }

//         <Dialog
//             fullWidth
//             {...rest}
//             open={rest.open && isStacked === false}>
//             <DialogTitle>Selecteer een {strings.office}</DialogTitle>
//             <DialogContent className="df-col">
//                 <FormSelectOrCreateEntPhysicianOfficeField<IModel>
//                     name="id"
//                     label={strings.office}
//                     data={data.notDeletedOffices}
//                     entPhysicianId={entPhysicianId}
//                     reload={() => load(false)} />
//             </DialogContent>
//             <CancelSubmitFormDialogActions
//                 cancel={cancel}
//                 isSubmitting={isSubmitting}
//                 submitText={strings.link} />
//         </Dialog>
//     );
// }
