import React from 'react';
import { IInventoryItem, IInventoryItemsQueryParamsForReturn, IProductCatalogItem, IProductCatalogQueryParamsForReturn, IQueryResult } from 'gen/ApiClient';

export interface IContext {
	pcParams: IProductCatalogQueryParamsForReturn;
	setPcParams: React.Dispatch<React.SetStateAction<IProductCatalogQueryParamsForReturn>>;
	pcQueryResult: IQueryResult<IProductCatalogItem> | undefined;
	iiParams: IInventoryItemsQueryParamsForReturn;
	setIIParams: React.Dispatch<React.SetStateAction<IInventoryItemsQueryParamsForReturn>>;
	iiQueryResult: IQueryResult<IInventoryItem> | undefined;
}

export const SearchContext = React.createContext<IContext>(undefined as any);
