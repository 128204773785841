import { DialogProps } from '@material-ui/core';
import React, { useState } from 'react';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IFile } from 'framework/utils/IFile';
import { DocumentPredefinedType, documentsCommand_upload, LinkViewContextType } from 'gen/ApiClient';
import { ScanOrUploadFileDialog } from 'shared/dialogs/scanOrUpload/ScanOrUploadFileDialog';
import { SelectSignatureModeDialog } from './SelectSignatureModeDialog';
import { SignatureMode } from './SignatureMode';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	title: string;
	contextType: LinkViewContextType;
	contextId: string;
	predefinedType?: DocumentPredefinedType;
}

type State = 'signature' | 'scanOrUpload';

export const DocumentUploadForm = ({ confirm, cancel, title, predefinedType, contextId, contextType, ...rest }: IProps) => {
	const [state, setState] = useState<State>('signature');
	const [signatureMode, setSignatureMode] = useState<SignatureMode>('neverSign');
	const [submit, isSubmitting] = useFormSubmit(documentsCommand_upload);

	const onConfirmSignatureMode = (mode: SignatureMode) => {
		setSignatureMode(mode);
		setState('scanOrUpload');
	};

	const onFile = async (file: IFile) => {
		const r = await submit({
			contentAsBase64: file.contentAsBase64,
			fileName: file.fileName,
			contextId: contextId,
			linkViewContextType: contextType,
			predefinedType: predefinedType,
			hasSignature: signatureMode === 'hasSignature',
			mustSign: signatureMode !== 'neverSign',
		});
		if (r.isSuccess) {
			confirm();
		}
	};

	if (state === 'signature') {
		return (
			<SelectSignatureModeDialog
				{...rest}
				title={title}
				confirm={onConfirmSignatureMode}
				cancel={cancel}
				initialValue={signatureMode}
			/>
		);
	} else if (state === 'scanOrUpload') {
		return (
			<ScanOrUploadFileDialog
				{...rest}
				title={title}
				confirm={onFile}
				cancel={cancel}
				isUploading={isSubmitting}
				accept='scanOrUpload'
			/>
		);
	} else {
		return <div></div>;
	}

	// return (
	//     <Formik<IDocumentUploadModel>
	//         validateOnMount
	//         initialValues={{
	//             ...emptyValues,
	//             predefinedType: predefinedType,
	//             contextId: contextId,
	//             linkViewContextType: contextType,
	//         }}
	//         validationSchema={createSchema(strings)}
	//         onSubmit={handleSubmit}>
	//         <Form>
	//             <InnerDialog
	//                 {...rest}
	//                 step={step}
	//                 setStep={setStep}
	//                 schema={createSchema(strings)}
	//                 cancel={cancel}
	//                 isSubmitting={isSubmitting}
	//                 title={title}
	//                 signatureMode={signatureMode}
	//                 setSignatureMode={setSignatureMode} />
	//         </Form>
	//     </Formik>

	// );
};

// type SignatureMode = 'hasSignature' | 'mustSignLater' | 'neverSign';

// interface IInnerProps extends DialogProps {
//     step: number;
//     setStep: (step: number) => void;
//     isSubmitting: boolean;
//     schema: yup.ObjectSchema<IDocumentUploadModel>;
//     cancel: VoidFunction;
//     title: string;
//     signatureMode: SignatureMode;
//     setSignatureMode: React.Dispatch<React.SetStateAction<SignatureMode>>;
// }

// const InnerDialog = ({ step, setStep, isSubmitting, schema, cancel, title, signatureMode, setSignatureMode, ...rest }: IInnerProps) => {
//     const props = useFormikContext<IDocumentUploadModel>();
//     const strings = useLocalization();

//     const onSelectFile = async (file: File) => {
//         const content = await fileToBase64(file);
//         setFormValue<IDocumentUploadModel>('contentAsBase64', content, props);
//         setFormValue<IDocumentUploadModel>('fileName', file.name, props);
//         props.submitForm();
//     }

//     return (
//         <Dialog
//             {...rest} >
//             <DialogTitleWithFormStepper
//                 title={title}
//                 step={step}
//                 labels={[strings.properties, strings.select]} />
//             <DialogContent dividers>
//                 {step === 0 &&
//                     <>
//                         <ExtendedRadioGroup<SignatureMode>
//                             selected={signatureMode}
//                             setSelected={setSignatureMode}
//                             options={[
//                                 { value: 'hasSignature', label: strings.withWhat(strings.signature), caption: strings.thisDocumentContainsASignature },
//                                 { value: 'mustSignLater', label: strings.scanSignatureLater, caption: strings.thisDocumentMustBeReplacedWithAScanWithSignature },
//                                 { value: 'neverSign', label: strings.noSignatureNeeded, caption: strings.noSignatureIsNeededForThisDocument },
//                             ]} />
//                     </>
//                 }
//                 {step === 1 &&
//                     <UploadFile
//                         isUploading={isSubmitting}
//                         onFileSelected={onSelectFile}
//                         accept="scanOrUpload" />
//                 }
//             </DialogContent>
//             <PageableFormDialogActions
//                 step={step}
//                 setStep={setStep}
//                 cancel={cancel}
//                 isSubmitting={isSubmitting}
//                 submitText={strings.create}
//                 schema={schema}
//                 stepsRecord={stepsRecord} />
//         </Dialog>
//     );
// }
