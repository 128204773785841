import { Stepper, Step, StepLabel } from '@material-ui/core';
import React from 'react';

interface IFormStepperProps {
	activeStep: number;
	labels: string[];
	style?: React.CSSProperties;
}

export const FormStepper = ({ activeStep, labels, style }: IFormStepperProps) => {
	return (
		<Stepper
			activeStep={activeStep}
			style={{ minWidth: '500px', paddingLeft: '0px', paddingRight: '0px', ...style }}>
			{labels.map(t => (
				<Step key={t}>
					<StepLabel>{t}</StepLabel>
				</Step>
			))}
		</Stepper>
	);
};
