import { useTheme } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ZoomOutMapOutlinedIcon from '@material-ui/icons/ZoomOutMapOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { FileDocumentEditOutlineIcon } from 'framework/components/icons/FileDocumentEditOutlineIcon';
import { FileDownloadOutlineIcon } from 'framework/components/icons/FileDownloadOutlineIcon';
import { SignatureFreehandIcon } from 'framework/components/icons/SignatureFreehandIcon';
import { formatDate } from 'framework/utils/date/formatDate';
import { DocumentExportFormat, documentsQuery_download, IDocumentSummary } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import React, { useContext, useMemo } from 'react';
import { useDocumentEditor } from 'shared/dialogs/useDocumentEditor';
import { usePdfViewerWithDocument } from 'shared/dialogs/usePdfViewerWithDocument';
import { RecordContext } from 'shared/records/RecordContext';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IDocumentSummary;
	withoutBorder?: boolean;
	onViewDetails: VoidFunction;
	reload: VoidFunction;
}

export const DocumentListItemX = ({ item, withoutBorder, onViewDetails, reload }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { translateDocumentType } = useContext(RecordContext);
	const title = useMemo(() => translateDocumentType(item), [item, translateDocumentType]);
	const [openEditor] = useDocumentEditor(reload);
	const [download] = useDownload(documentsQuery_download);
	const openPdf = usePdfViewerWithDocument();
	const popupState = usePopupState({
		variant: 'popover',
		popupId: `document-list-x-${item.id!}`,
	});

	const onViewOrEdit = () => {
		if (item.isDocxAndCanEditAndCanViewOnline || item.isDocxAndCanViewOnlineButCannotEdit) {
			openEditor(item.id);
		} else if (item.isPdf) {
			openPdf(title, item.id);
		}
	};

	return (
		<>
			<div
				className='df-row w100'
				style={{
					borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
					padding: '12px 0px',
					gap: 8,
				}}>
				<DescriptionOutlinedIcon style={{ fontSize: 22, color: theme.palette.text.secondary }} />
				<div
					className='df-col'
					{...bindHover(popupState)}>
					<TextLinkButton
						onClick={onViewDetails}
						style={{ fontSize: MajorFontSize }}>
						{title}
					</TextLinkButton>
					<span style={{ fontSize: MinorFontSize }}>{`${formatDate(item.created)} (${item.extension})`}</span>
					{item.mustSign && <span style={{ fontSize: MinorFontSize }}>{item.hasSignature ? strings.signed : strings.notSigned}</span>}
				</div>
				{item.mustSign && <SignatureFreehandIcon style={{ color: item.hasSignature ? theme.palette.success.main : theme.palette.warning.main }} />}
			</div>
			<HoverPopover
				elevation={1}
				{...bindPopover(popupState)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}>
				<div style={{ padding: 12 }}>
					<TextLinkButton
						startIcon={<ZoomOutMapOutlinedIcon />}
						onClick={onViewDetails}>
						{strings.detailsSlashHistorySlashMoreActions}
					</TextLinkButton>
					<TextLinkButton
						startIcon={<FileDocumentEditOutlineIcon />}
						onClick={onViewOrEdit}
						disabled={item.canViewOnline === false && item.canEdit === false}>
						{strings.editSlashView}
					</TextLinkButton>
					<TextLinkButton
						startIcon={<FileDownloadOutlineIcon />}
						onClick={() => download(item.id, 'Native' as DocumentExportFormat)}>
						{strings.download}
					</TextLinkButton>
				</div>
			</HoverPopover>
		</>
	);
};
