import { useCalendarMode } from 'app/main/calendar/useCalendarMode';
import React from 'react';
import { HamsPatientUpcomingEventsCard } from './HamsPatientUpcomingEventsCard';
import { IPatientUpcomingEventsCardProps } from './IPatientUpcomingEventsCardProps';
import { RosaPatientUpcomingEventsCard } from './RosaPatientUpcomingEventsCard';
import { RosaPatientUpcomingEventsNotConfiguredYetCard } from './RosaPatientUpcomingEventsNotConfiguredYetCard';

export const PatientUpcomingEventsCardSwitch = ({ ...props }: IPatientUpcomingEventsCardProps) => {
	const calendarMode = useCalendarMode();

	return (
		<React.Fragment>
			{calendarMode === 'Rosa' && <RosaPatientUpcomingEventsCard {...props} />}
			{calendarMode === 'Hams' && <HamsPatientUpcomingEventsCard {...props} />}
			{calendarMode === 'NotSet' && <RosaPatientUpcomingEventsNotConfiguredYetCard {...props} />}
		</React.Fragment>
	);
};
