import { Divider } from '@material-ui/core';
import React from 'react';

export const ProcessButtonDivider = () => {
	return (
		<Divider
			orientation='vertical'
			flexItem
			style={{ margin: '4px' }}
		/>
	);
};
