import React from 'react';
import { FormTextField } from 'framework/forms/FormTextField';
import { useLocalization } from 'localization/useLocalization';

export const FormRecipientNameField = <TModel extends { recipientName: string | undefined }>() => {
	const strings = useLocalization();

	return (
		<FormTextField<TModel>
			name='recipientName'
			label={strings.withRespectToShort}
			multiline
		/>
	);
};
