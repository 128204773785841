import { IProductCatalogItem, ISalesLineForAssuranceDto } from 'gen/ApiClient';
import { ISearchAndSelectLineWithAssurance } from '../ISearchAndSelectLineWithAssurance';

export const canSelect = <TLine extends ISearchAndSelectLineWithAssurance>(
	assurance: IProductCatalogItem,
	qLine: ISalesLineForAssuranceDto,
	lines: TLine[]
) => {
	if (qLine.canSelect === false) {
		return false;
	}
	const otherAssurancesOfSameType = lines
		.filter(t => t.productCatalogItem !== undefined && t.productCatalogItem.type === assurance.type)
		.map(t => t.assuredLineId)
		.filter(t => t !== undefined);
	return otherAssurancesOfSameType.indexOf(qLine.id) === -1;
};
