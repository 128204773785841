import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ChipX } from 'framework/components/chips/ChipX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { ColoredLinearProgress } from 'framework/components/ColoredLinearProgress';
import { formatDate } from 'framework/utils/date/formatDate';
import { IWorkflowSummary, WorkflowType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { RecordContext } from 'shared/records/RecordContext';
import { WorkflowTypeNavigationRecord } from 'shared/records/WorkflowTypeNavigationRecord';
import { useWorkflowStatusMapper } from 'shared/workflows/useWorkflowStatusMapper';
import { mapWorkflowSummaryToColorX } from './mapWorkflowSummaryToColorX';
import { WorkflowSummaryStatusIcon } from './WorkflowSummaryStatusIcon';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IWorkflowSummary;
	withoutBorder?: boolean;
}

export const WorkflowByPatientListComponent = ({ item, withoutBorder }: IProps) => {
	const strings = useLocalization();
	const { workflowTypeRecord } = useContext(RecordContext);
	const { push } = useHistory();
	const statusMapper = useWorkflowStatusMapper();
	const status = useMemo(() => statusMapper(item), [item, statusMapper]);
	const color = useMemo(() => mapWorkflowSummaryToColorX(item), [item]);
	const tColor = useThemeColor(color);

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div
				className='df-col'
				style={{ marginRight: 8 }}>
				<WorkflowSummaryStatusIcon
					item={item}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col'>
				<div
					className='df-row-ac'
					style={{ paddingBottom: 4 }}>
					<TextLinkButton
						onClick={() => push(WorkflowTypeNavigationRecord[item.type as WorkflowType](item.id))}
						style={{ fontSize: MajorFontSize, marginRight: 4 }}>
						{`${workflowTypeRecord[item.type as WorkflowType]}: ${item.reference}`}
					</TextLinkButton>
				</div>
				<ChipX
					label={status}
					color={color}
					style={{ marginRight: 'auto' }}
				/>
				<div
					className='df-row-ac'
					style={{ fontSize: MinorFontSize, gap: 4, marginTop: 4, marginBottom: 4 }}>
					<span>{`${strings.date}: ${formatDate(item.created)}`}</span>
				</div>
				{item.isCompleted === false && (
					<ColoredLinearProgress
						value={item.progressPercentage}
						color={color}
						style={{ width: 100 }}
					/>
				)}
			</div>
		</div>
	);
};
