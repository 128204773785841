import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { ArticlesInquiriesPrefilter, IArticlesInquiriesMetadata } from 'gen/ApiClient';
import React from 'react';

export const createArticlesInquiriesPrefilterBadgeChipXRecord = (metadata: IArticlesInquiriesMetadata): Record<ArticlesInquiriesPrefilter, React.ReactNode> => {
	return {
		All: metadata.count > 0 && (
			<BadgeChipX
				value={metadata.count}
				color='default'
			/>
		),
		Unassigned: metadata.countNotAssigned > 0 && (
			<BadgeChipX
				value={metadata.countNotAssigned}
				color='warning'
			/>
		),
	};
};
