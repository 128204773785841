import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { healthInsuranceFundMetaQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { HifDetailsContent } from './HifDetailsContent';

interface IProps extends DialogProps {
	close: VoidFunction;
	code: string;
}

export const HifDetailDialog = ({ code, close, ...rest }: IProps) => {
	const strings = useLocalization();
	const [item, reload] = useApiEffect(healthInsuranceFundMetaQuery_single, code);
	const { isStacked } = useDialogsContext(reload);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}>
			<DialogTitle>{strings.detailsOfHif}</DialogTitle>
			<DialogContent
				dividers
				style={{ paddingBottom: 24 }}>
				<CloseButtonOnAbsolutePosition onClick={close} />
				<HifDetailsContent
					item={item}
					reload={reload}
				/>
			</DialogContent>
		</Dialog>
	);
};
