import { DialogProps } from '@material-ui/core';
import React from 'react';
import { IContactModel, ISupplier, suppliersCommand_patchContactPerson } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { ContactForm } from 'shared/ContactForm';

const toModel = (supplier: ISupplier): IContactModel => {
	return {
		firstName: supplier.firstName,
		lastName: supplier.lastName,
		salutation: supplier.salutation,
	};
};

interface IProps extends DialogProps {
	supplier: ISupplier;
	cancel: VoidFunction;
	confirm: VoidFunction;
}

export const SupplierContactPersonForm = ({ supplier, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<ContactForm
			fullWidth
			title={strings.changeSupplierContactPerson}
			model={toModel(supplier)}
			confirm={confirm}
			submitFunction={model => suppliersCommand_patchContactPerson({ supplierId: supplier.id, contact: model })}
			cancel={cancel}
			{...rest}
		/>
	);
};
