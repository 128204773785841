import LinkIcon from '@material-ui/icons/Link';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { IRosaPatientRecord } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RosaPatientRecordListItemStatusIcon } from './RosaPatientRecordListItemStatusIcon';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { formatDate } from 'framework/utils/date/formatDate';
import { ImportRosaPatientRecordDialog } from './import/ImportRosaPatientRecordDialog';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';

const MajorFontSize = 16;

interface IProps {
	item: IRosaPatientRecord;
	withoutBorder?: boolean;
	reload: VoidFunction;
}

export const RosaPatientRecordListItem = ({ item, withoutBorder, reload }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const color = useMemo<ColorX>(() => (item.isLinkedToHamsPatient ? 'success' : 'error'), [item]);
	const tColor = useThemeColor(color);
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onLink = () => {
		open(
			<ImportRosaPatientRecordDialog
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<RosaPatientRecordListItemStatusIcon
					item={item}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col gap-4 fg1'>
				<div className='df-row-ac'>
					<TextLinkButton
						onClick={item.hamsPatientId ? () => push(calcPatientDetailRoute(item.hamsPatientId!)) : undefined}
						style={{ fontSize: MajorFontSize }}
						disabled={Boolean(item.hamsPatientId) === false}>
						{item.lastNameCommaFirstName}
					</TextLinkButton>
				</div>
				<ChipX
					label={item.isLinkedToHamsPatient ? strings.linked : strings.notLinked}
					color={color}
					style={{ marginRight: 'auto' }}
				/>
				<LabelledPropertyX label={strings.lastUpdated}>{formatDate(item.lastUpdated)}</LabelledPropertyX>
				<LabelledPropertyX label={strings.socialSecurityNumber}>{item.formattedSocialSecurityNumber}</LabelledPropertyX>
				<LabelledPropertyX label={strings.dateOfBirth}>{formatDate(item.dateOfBirth)}</LabelledPropertyX>
				{item.isLinkedToHamsPatient === false && (
					<TextLinkButton
						color='primary'
						startIcon={<LinkIcon />}
						onClick={onLink}>
						{strings.link}
					</TextLinkButton>
				)}
			</div>
		</div>
	);
};
