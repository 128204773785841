import { Collapse, Divider, Radio } from '@material-ui/core';
import { InventoryItemStatusChipX } from 'app/main/inventoryManagement/inventoryItems/components/InventoryItemStatusChipX';
import { ArticlePropertyWithMismatchCaptionVerticalLabelledPropertyX } from 'app/main/inventoryManagement/inventoryItems/detail/articleProperties/ArticlePropertyWithMismatchCaptionVerticalleLabelledProperty';
import { useLocalizedArticlePropertyKeys } from 'app/main/inventoryManagement/productCatalog/articlePropertyKeys/context/useLocalizedArticlePropertyKeys';
import { TextLinkButtonWithExpandIcon } from 'framework/components/buttons/TextLinkButtonWithExpandIcon';
import { CardX } from 'framework/components/cards/CardX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { IArticlesInquiryLineDto, IInventoryItemWithMatchArticleProperties, InventoryStatus } from 'gen/ApiClient';
import React from 'react';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IInventoryItemWithMatchArticleProperties;
	articlesInquiryLine: IArticlesInquiryLineDto;
	onSelect: VoidFunction;
	isSelected: boolean;
	style?: React.CSSProperties;
}

export const InventoryItemToAssignListItem = ({ item, articlesInquiryLine, onSelect, isSelected, style }: IProps) => {
	const [hovered, enter, leave] = useStateBoolean(false);
	const [isExpanded, toggleIsExpanded] = useStateToggleBoolean(false);
	const localizedKeys = useLocalizedArticlePropertyKeys();

	return (
		<CardX
			className='df-row gap-8 w100'
			raised={hovered || isSelected}
			onMouseEnter={enter}
			onMouseLeave={leave}
			style={{
				cursor: 'pointer',
				...style,
			}}
			onClick={onSelect}>
			<div className='df-col'>
				<Radio
					style={{ padding: 0 }}
					checked={isSelected}
				/>
			</div>
			<div className='df-col gap-4 fg1'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.data.manufacturerModelAndSerialNumber}</span>
				<InventoryItemStatusChipX
					status={item.data.status as InventoryStatus}
					style={{ marginRight: 'auto' }}
				/>
				<div
					className='df-row-ac'
					style={{ fontSize: MinorFontSize, gap: 4 }}>
					<StatusIcon status={item.hasMismatchingProperties ? 'error' : 'success'} />
					<TextLinkButtonWithExpandIcon
						toggle={toggleIsExpanded}
						isExpanded={isExpanded}>
						<span style={{ fontSize: MinorFontSize }}>
							{item.hasMismatchingProperties ? 'Niet alle eigenschappen komen overeen' : 'Alle eigenschappen komen overeen'}
						</span>
					</TextLinkButtonWithExpandIcon>
				</div>
				<Collapse in={isExpanded}>
					<div className='df-col gap-4'>
						<Divider
							variant='inset'
							style={{ marginTop: 8, marginBottom: 4 }}
						/>
						{item.data.articlePropertiesX.map(t => (
							<ArticlePropertyWithMismatchCaptionVerticalLabelledPropertyX
								key={t.key}
								item={t}
								localizedKeys={localizedKeys}
								mismatch={item.mismatchKeys.indexOf(t.key!) > -1}
								requestedValues={articlesInquiryLine.articlePropertiesX.find(x => x.key === t.key)?.values ?? []}
							/>
						))}
					</div>
				</Collapse>
			</div>
		</CardX>
	);
};
