import { Button } from '@material-ui/core';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { Page } from 'shared/layout/Page';
import { ProcessCfDialog } from './ProcessCfDialog';

export const CustomFeatureCfPage = () => {
	const { open, cancel } = useDialogsContext();

	const onUpload = () => {
		open(<ProcessCfDialog close={cancel} />);
	};

	return (
		<Page title='Custom features - Consignatielijsten opladen'>
			<div className='df-col-as'>
				<Button
					color='primary'
					variant='contained'
					onClick={onUpload}>
					Consignatielijst opladen
				</Button>
			</div>
		</Page>
	);
};
