import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormIntegerFieldWithUnit } from 'framework/forms/FormIntegerFieldWithUnit';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import {
	IPatchProductCatalogItemInsuranceOrProlongedWarrantyTermRequest,
	InsuranceOrProlongedWarrantyTimeUnit,
	InsuranceOrProlongedWarrantyTimeUnits,
	IProductCatalogItem,
	productCatalogCommand_patchTerm,
	TimeUnit,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useRenderTimeUnit } from 'shared/timeUnit/useRenderTimeUnit';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchProductCatalogItemInsuranceOrProlongedWarrantyTermRequest>({
		isUnlimited: yup.boolean(),
		unit: yup.string().required(),
		value: yup.number().required(),
		productCatalogItemId: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	item: IProductCatalogItem;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const InsuranceOrProlongedWarrantyTermForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [update, isSubmitting] = useFormSubmit(productCatalogCommand_patchTerm);
	const strings = useLocalization();

	const handleSubmit = async (
		values: IPatchProductCatalogItemInsuranceOrProlongedWarrantyTermRequest,
		helpers: FormikHelpers<IPatchProductCatalogItemInsuranceOrProlongedWarrantyTermRequest>
	) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	return (
		<Formik<IPatchProductCatalogItemInsuranceOrProlongedWarrantyTermRequest>
			validateOnMount
			initialValues={{
				isUnlimited: item.insuranceOrProlongedWarrantyTerm === null || item.insuranceOrProlongedWarrantyTerm === undefined,
				unit: item.insuranceOrProlongedWarrantyTerm?.unit ?? ('Years' as InsuranceOrProlongedWarrantyTimeUnit),
				value: item.insuranceOrProlongedWarrantyTerm?.value ?? 3,
				productCatalogItemId: item.id,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					isSubmitting={isSubmitting}
					cancel={cancel}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
}

const InnerDialog = ({ cancel, isSubmitting, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IPatchProductCatalogItemInsuranceOrProlongedWarrantyTermRequest>();
	const renderTimeUnit = useRenderTimeUnit();
	const strings = useLocalization();

	useEffect(() => {
		if (props.values.isUnlimited) {
			setFormValue<IPatchProductCatalogItemInsuranceOrProlongedWarrantyTermRequest>('unit', 'Years' as InsuranceOrProlongedWarrantyTimeUnit, props);
			setFormValue<IPatchProductCatalogItemInsuranceOrProlongedWarrantyTermRequest>('value', 3, props);
		}
		// eslint-disable-next-line
	}, [props.values.isUnlimited]);

	return (
		<Dialog
			fullWidth
			maxWidth='xs'
			{...rest}>
			<DialogTitle>Termijn</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<FormSingleCheckboxField<IPatchProductCatalogItemInsuranceOrProlongedWarrantyTermRequest>
					name='isUnlimited'
					label={`${strings.withoutTerm} (${strings.unlimited})`}
				/>
				{props.values.isUnlimited === false && (
					<FormIntegerFieldWithUnit<IPatchProductCatalogItemInsuranceOrProlongedWarrantyTermRequest, TimeUnit>
						name='value'
						unitName='unit'
						units={InsuranceOrProlongedWarrantyTimeUnits}
						label={strings.term}
						defaultUnit='Years'
						renderUnit={(val, unit) => renderTimeUnit(val, unit)}
					/>
				)}
			</DialogContent>
			<CancelSubmitFormDialogActions
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.update}
			/>
		</Dialog>
	);
};
