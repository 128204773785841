import { IRegisterOrderDeliveryLineRequest } from 'gen/ApiClient';
import { IRegisterOrderDeliveryLineModel } from './IRegisterOrderDeliveryLineModel';

export const mapToRegisterOrderDeliveryLineRequest = (model: IRegisterOrderDeliveryLineModel, index: number): IRegisterOrderDeliveryLineRequest => {
	return {
		orderLineId: model.orderLine.id,
		quantity: model.quantity,
		serialNumber: model.serialNumber,
		zeroBasedIndex: index,
		autoGenerateSerialNumber: model.autoGenerateSerialNumber,
	};
};
