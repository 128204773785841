import React, { useContext, useMemo, useState } from 'react';
import { createColumnRecord } from '../utils/createColumnRecord';
import { createHeaders } from '../utils/createHeaders';
import { SelectableColumn } from '../SelectableColumn';
import { ViewContext } from './ViewContext';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';

const DefaultColumns: SelectableColumn[] = ['serialNumber', 'manufacturerAndModel', 'status'];

export const ViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);
	const colRecord = useMemo(() => createColumnRecord(strings), [strings]);
	const { productCatalogItemTypeRecord } = useContext(RecordContext);
	const headers = useMemo(() => createHeaders(colRecord, productCatalogItemTypeRecord, strings), [colRecord, productCatalogItemTypeRecord, strings]);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
