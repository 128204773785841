import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteFieldFromStrings } from 'framework/forms/FormAutocompleteFieldFromStrings';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	ICustomerAccountLine,
	ISettlementProposal,
	settlementProposalsCommand_addLine,
	settlementProposalsQuery_unhandledForCustomerAccount,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

interface IModel {
	proposalId: string;
	customerAccountLineId: string;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			proposalId: yup.string().required(),
			customerAccountLineId: yup.string().required(),
		})
		.defined();
};

const emptyValues: IModel = {
	proposalId: '',
	customerAccountLineId: '',
};

interface IProps extends DialogProps {
	customerAccountLine: ICustomerAccountLine;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const AddLineToSettlementProposalForm = ({ customerAccountLine, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(settlementProposalsCommand_addLine);
	const [proposals] = useApiEffect(settlementProposalsQuery_unhandledForCustomerAccount, customerAccountLine.customerAccountId);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await update(values.proposalId, customerAccountLine.id);
		if (handleValidateResponse(r, helpers, 'proposalId')) {
			notify(strings.changedWhat(strings.settlementProposal));
			confirm();
		}
	};

	if (proposals === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{ ...emptyValues, customerAccountLineId: customerAccountLine.id }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					open
					cancel={cancel}
					isSubmitting={isSubmitting}
					proposals={proposals}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	proposals: ISettlementProposal[];
}

const InnerDialog = ({ cancel, isSubmitting, proposals, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IModel>();
	const strings = useLocalization();

	// TODO useEffect set single Proposal
	useEffect(() => {
		if (proposals.length === 1) {
			setFormValue<IModel>('proposalId', proposals[0].id, props);
		}
		// eslint-disable-next-line
	}, [proposals]);

	return (
		<Dialog
			scroll='paper'
			{...rest}>
			<DialogTitle>
				<div>{strings.addToSettlementProposal}</div>
			</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<FormAutocompleteFieldFromStrings<IModel>
					name='proposalId'
					required
					label={strings.settlementProposal}
					options={proposals.map(t => t.id)}
					renderOption={id => proposals.find(t => t.id === id)?.reference ?? ''}
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions<IModel>
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.add}
			/>
		</Dialog>
	);
};
