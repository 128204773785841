import { useTheme } from '@material-ui/core';
import { useUserHasPageClaimOrDefault } from 'claims/useUserHasPageClaimOrDefault';
import { PageClaim } from 'gen/ApiClient';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { NavigationLinkListItemButton } from 'shared/NavigationLinkListItemButton';

const borderWidth: number = 4;

interface IProps {
	text: string;
	startIcon: React.ReactNode;
	route: string;
	onNavigate: (route: string) => void;
	pageClaim: PageClaim | undefined;
}

export const PrimaryNavLink = ({ text, startIcon, route, onNavigate, pageClaim }: IProps) => {
	const isMatch = useRouteMatch(route);
	const theme = useTheme();
	const hasAccess = useUserHasPageClaimOrDefault(pageClaim);

	return (
		<NavigationLinkListItemButton
			style={{
				padding: '0px',
				borderLeft: isMatch ? `${borderWidth}px solid ${theme.palette.primary.main}` : 'none',
			}}
			buttonStyle={{ paddingLeft: isMatch ? `${24 - borderWidth}px` : '24px' }}
			buttonDisabled={hasAccess === false}
			startIcon={startIcon}
			color={isMatch ? 'primary' : 'default'}
			onClick={() => onNavigate(route)}>
			{text}
		</NavigationLinkListItemButton>
	);
};
