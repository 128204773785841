import LinkIcon from '@material-ui/icons/Link';
import { SearchInventoryItemForProductCatalogDialog } from 'app/main/inventoryManagement/inventoryItems/search/SearchInventoryItemForProductCatalogDialog';
import { mustLinkAnInventoryItem } from 'app/main/processes/requestsForInsuranceAllowance/forms/handover/utils/mustLinkAnInventoryItem';
import { IDraggableItem } from 'framework/components/dnd/IDraggableItem';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { InfoLabelledProperty } from 'framework/components/labelledProperty/InfoLabelledProperty';
import { ILocationSummary, inventoryItemsQuery_queryForSale } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SelectProductsToAssureDialog } from 'shared/searchAndSelect/assurance/SelectProductsToAssureDialog';
import { DefaultInventoryItemsQueryParamsForSale } from 'shared/searchAndSelect/DefaultInventoryItemsQueryParamsForSale';
import { LineDescriptionQuantityAndLinePrice } from 'shared/searchAndSelect/lines/LineDescriptionQuantityAndLinePrice';
import { LinePricingLabelledProperties } from 'shared/searchAndSelect/lines/LinePricingLabelledProperties';
import { LineXComponent } from 'shared/searchAndSelect/lines/LineXComponent';
import { PriceOrDiscountForm } from 'shared/searchAndSelect/PriceOrDiscountForm';
import { calculateDemoWarning } from 'shared/searchAndSelect/utils/calculateDemoWarning';
import { calculateReservedForWarning } from 'shared/searchAndSelect/utils/calculateReservedForWarning';
import { calculateStockWarning } from 'shared/searchAndSelect/utils/calculateStockWarning';
import { AssuranceLineLinkComponent } from './AssuranceLineLinkComponent';
import { IInvoiceLine } from './IInvoiceLine';
import { assuranceToLine } from './utils/assuranceToLine';
import { inventoryItemToLine } from './utils/inventoryItemToLine';

interface IProps extends IDraggableItem {
	line: IInvoiceLine;
	index: number;
	setLine: (val: IInvoiceLine) => void;
	onDelete: VoidFunction | undefined;
	location: ILocationSummary;
	customerAccountId: string | undefined;
	lines: IInvoiceLine[];
	setLines: React.Dispatch<React.SetStateAction<IInvoiceLine[]>>;
}

export const SalesLineComponent = ({ line, setLine, lines, setLines, location, customerAccountId, ...rest }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext();
	const strings = useLocalization();
	const stockWarning = useMemo(() => calculateStockWarning(line, location, strings), [line, location, strings]);
	const demoWarning = useMemo(() => calculateDemoWarning(line, strings), [line, strings]);
	const mustLinkInventoryItem = useMemo(() => mustLinkAnInventoryItem(line), [line]);
	const reservedForWarning = useMemo(() => calculateReservedForWarning(line, strings), [line, strings]);

	const onEditPrice = () => {
		open(
			<PriceOrDiscountForm
				open
				listPrice={line.unitListPrice}
				unitPrice={line.unitPrice}
				confirm={(up, lp) => {
					confirm();
					setLine({ ...line, unitPrice: up, unitListPrice: lp });
				}}
				cancel={cancel}
				forPurchase={false}
			/>
		);
	};

	const onSelectItemToAssurre = () => {
		open(
			<SelectProductsToAssureDialog<IInvoiceLine>
				open
				assurance={line.productCatalogItem!}
				cancel={cancel}
				lines={lines}
				customerAccountId={customerAccountId}
				onAdd={assuranceLines => {
					confirm();
					const y = [
						...lines.filter(t => t.id !== line.id),
						...assuranceLines.map(t => ({
							...assuranceToLine(line.productCatalogItem!, t),
							unitListPriceInVat: line.unitListPrice,
							unitPriceInVat: line.unitPrice,
						})),
					];
					setLines(y);
				}}
			/>
		);
	};

	const onLinkInventoryItem = () => {
		open(
			<SearchInventoryItemForProductCatalogDialog
				confirm={item => {
					confirm();
					const x: IInvoiceLine = {
						...inventoryItemToLine(item, line.productCatalogItem!, false),
						unitPrice: line.unitPrice,
						unitListPrice: line.unitListPrice,
					};
					setLine(x);
				}}
				cancel={cancel}
				canSelectF={t => t.canSelectForSale}
				defaultParams={DefaultInventoryItemsQueryParamsForSale}
				queryF={inventoryItemsQuery_queryForSale}
				item={line.productCatalogItem!}
				selectedIds={lines.filter(t => t.inventoryItemId !== undefined).map(t => t.inventoryItemId) as string[]}
			/>
		);
	};

	return (
		<LineXComponent
			{...rest}
			line={line}
			setLine={setLine}
			errors={line.error}
			warnings={[stockWarning, demoWarning, reservedForWarning]}>
			<LineDescriptionQuantityAndLinePrice<IInvoiceLine>
				line={line}
				setLine={setLine}
				onClickPrice={onEditPrice}
				disableQuantity={line.canChangeQuantity === false}
				mode={line.isCredit ? 'negative' : 'positive'}
			/>
			<div className='fg1'>
				{line.productCatalogItem && line.productCatalogItem.isInsuranceOrProlongedWarranty && (
					<AssuranceLineLinkComponent
						line={line}
						lines={lines}
						onLink={onSelectItemToAssurre}
					/>
				)}
				{mustLinkInventoryItem && (
					<ErrorLabelledProperty
						error={strings.selectItemWithSerialNumberClickHereToLink}
						edit={onLinkInventoryItem}
						editIcon={<LinkIcon />}
						withoutMargin
					/>
				)}
				{line.isCredit && <InfoLabelledProperty info={strings.creditVerb} />}
			</div>
			<LinePricingLabelledProperties<IInvoiceLine>
				line={line}
				onClick={onEditPrice}
				style={{ marginTop: 8 }}
			/>
		</LineXComponent>
	);
};
