import React, { useContext, useMemo, useState } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';
import { SelectableColumn } from '../SelectableColumn';
import { createColumnRecord } from '../utils/createColumnRecord';
import { createExportRecord } from '../utils/createExportRecord';
import { createHeaders } from '../utils/createHeaders';
import { ViewContext } from './ViewContext';

// enkel hier nodig
const DefaultColumns: SelectableColumn[] = ['index', 'referenceDate', 'paymentReferenceOrDescription', 'value', 'globalResultingBalance'];

export const ViewProvider = ({ children }: any) => {
	const { saleTypeRecord } = useContext(RecordContext);
	const strings = useLocalization();
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);
	const colRecord = useMemo(() => createColumnRecord(strings), [strings]);
	const headers = useMemo(() => createHeaders(colRecord, saleTypeRecord, strings), [colRecord, saleTypeRecord, strings]);
	const exportRecord = useMemo(() => createExportRecord(strings), [strings]);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
				exportRecord: exportRecord,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
