import { Collapse, Divider, useTheme } from '@material-ui/core';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { calcCustomerAccountDetailRoute, calcSalesDetailRoute } from 'app/main/financial/routes';
import { calcInventoryItemDetailRoute, calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { TextLinkButtonWithExpandIcon } from 'framework/components/buttons/TextLinkButtonWithExpandIcon';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISale, SalesType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { RecordContext } from 'shared/records/RecordContext';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: ISale;
	patientId: string;
	isExpanded: boolean;
	toggle: VoidFunction;
	withoutBorder?: boolean;
}

export const SaleByPatientListComponent = ({ item, patientId, isExpanded, toggle, withoutBorder }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { saleTypeRecord } = useContext(RecordContext);
	const { push } = useHistory();

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8 }}>
				<MonetizationOnOutlinedIcon style={{ color: item.isFullyPaid ? theme.palette.success.main : theme.palette.error.main, fontSize: 22, opacity: 0.8 }} />
			</div>
			<div className='df-col'>
				<div
					className='df-row-ac'
					style={{ paddingBottom: 4 }}>
					<TextLinkButton
						onClick={() => push(calcSalesDetailRoute(item.id))}
						style={{ fontSize: MajorFontSize }}>
						{`${saleTypeRecord[item.type as SalesType]}: ${item.reference}`}
					</TextLinkButton>
				</div>
				<div
					className='df-row-ac'
					style={{ fontSize: MinorFontSize, gap: 4 }}>
					<span>{`${strings.date}: ${formatDate(item.created)}`}</span>
					<span>{`${strings.totalAmount}: ${formatCurrency(item.amountToPay)} ${strings.inVatShort}`}</span>
					<TextLinkButtonWithExpandIcon
						toggle={toggle}
						isExpanded={isExpanded}>
						<span style={{ fontSize: MinorFontSize, fontWeight: 'unset' }}>{`# ${item.countItems.toString()} ${
							item.countItems === 1 || item.countItems === -1 ? strings.article.toLowerCase() : strings.articles.toLowerCase()
						}, # ${item.countLines.toString()} ${item.countLines === 1 ? strings.line.toLowerCase() : strings.lines.toLowerCase()}`}</span>
					</TextLinkButtonWithExpandIcon>
				</div>
				{item.hasMultipleCustomerAccounts && (
					<div
						className='df-row-ab'
						style={{ fontSize: MinorFontSize, gap: 4 }}>
						<span>{strings.multipleCustomerAccounts}</span>
						{item.customerAccountLines
							.filter(t => t.customerAccountId !== patientId)
							.map(cal => (
								<React.Fragment key={cal.id}>
									<TextLinkButton
										style={{ fontSize: MinorFontSize }}
										underline
										onClick={() => push(calcCustomerAccountDetailRoute(cal.customerAccountId))}>
										{cal.customerAccountIdentifier}
									</TextLinkButton>
									<span>{`${formatCurrency(cal.amountToPay)}`}</span>
								</React.Fragment>
							))}
					</div>
				)}
				<Collapse in={isExpanded}>
					<div className='df-col'>
						<Divider
							variant='inset'
							style={{ marginTop: 8, marginBottom: 4 }}
						/>
						{item.lines.map(line => (
							<div
								className='df-row-ab'
								key={line.id}
								style={{ fontSize: MinorFontSize, gap: 4 }}>
								<span>{`${line.quantity.toString()} x`}</span>
								{line.hasProductCatalogItem && (
									<TextLinkButton
										underline
										style={{ fontSize: MinorFontSize }}
										onClick={() => {
											if (line.inventoryItemId) {
												push(calcInventoryItemDetailRoute(line.inventoryItemId));
											} else {
												push(calcProductCatalogDetailRoute(line.productCatalogItemId!));
											}
										}}>
										{line.description}
									</TextLinkButton>
								)}
								{line.hasProductCatalogItem === false && <span>{line.description}</span>}
							</div>
						))}
					</div>
				</Collapse>
			</div>
		</div>
	);
};
