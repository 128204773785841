import { CardProps, useTheme } from '@material-ui/core';
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { CustomerAccountType, ICustomerAccountLineData, ISale } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { RecordContext } from 'shared/records/RecordContext';
import { calcCustomerAccountDetailRoute } from '../../routes';
import { PaymentStatusChipX } from './PaymentStatusChipX';
import { SalesDetailAccountReceivableStatusIcon } from './SalesDetailAccountReceivableStatusIcon';
import { StatusIcon } from 'framework/components/icons/StatusIcon';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps extends CardProps {
	item: ICustomerAccountLineData;
	sale: ISale;
	isSelected: boolean;
	toggle: VoidFunction;
}

export const SalesDetailAccountReceivableCardX = ({ item, sale, isSelected, toggle, style, className, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const color = useMemo<ColorX>(() => (item.isFullyPaid ? 'success' : sale.isOverDue ? 'warning' : 'info'), [item, sale]);
	const tColor = useThemeColor(color);
	const { customerAccountTypeRecord } = React.useContext(RecordContext);
	const [hovered, enter, leave] = useStateBoolean(false);
	const { push } = useHistory();

	return (
		<CardX
			raised={isSelected || hovered}
			className={`df-row w100 ${className}`}
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={toggle}
			style={{
				borderRight: isSelected ? `6px solid ${theme.palette.primary.main}` : 'unset',
				cursor: 'pointer',
				...style,
			}}
			{...rest}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<SalesDetailAccountReceivableStatusIcon
					item={item}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col fg1 gap-2'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.customerAccountIdentifier}</span>
				<div className='df-row-ac gap-4'>
					<PaymentStatusChipX
						item={item}
						isOverDue={sale.isOverDue}
					/>
					<ChipX
						label={customerAccountTypeRecord[item.accountType as CustomerAccountType]}
						color='primary'
					/>
				</div>
				<span style={{ fontSize: MinorFontSize }}>{`${strings.amountToPay}: ${formatCurrency(item.amountToPay)}`}</span>
				<span style={{ fontSize: MinorFontSize }}>{`${strings.amountPaid}: ${formatCurrency(item.amountPaid)}`}</span>
				<span style={{ fontSize: MinorFontSize, color: item.hasOutstandingBalance ? theme.palette.warning.main : 'unset' }}>
					{`${strings.outstandingBalance}: ${formatCurrency(item.outstandingBalance)}`}
				</span>
				{sale.hasMultipleCustomerAccounts && (
					<span style={{ fontSize: MinorFontSize }}>{`${strings.structuredReference}: ${item.belgianStructuredCreditorReference}`}</span>
				)}
				{item.isOverrideSetToFullyPaid && (
					<div className='df-row-ac gap-4'>
						<StatusIcon
							fontSize='small'
							status='warning'
						/>
						<span style={{ fontSize: MinorFontSize, color: theme.palette.warning.main }}>{strings.paymentsNotRegisteredInHAMS}</span>
					</div>
				)}
				<div className='df-col gap-4'>
					<TextLinkButton
						startIcon={<OpenInBrowserOutlinedIcon />}
						onClick={() => push(calcCustomerAccountDetailRoute(item.customerAccountId!))}>
						{strings.viewAccountReceivable}
					</TextLinkButton>
				</div>
			</div>
		</CardX>
	);
};
