import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useTrigger } from 'framework/hooks/useTrigger';
import React, { useEffect, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { SettlementProposalsPageHeader } from './SettlementProposalsPageHeader';
import { SettlementProposalsTabType } from './SettlementProposalsTabType';
import { SettlementProposalForm } from './forms/SettlementProposalForm';
import { SettlementProposalsCard } from './sps/SettlementProposalsCard';
import { QueryContext, QueryContext as SpsQueryContext } from './sps/context/QueryContext';
import { UnhandledLinesCard } from './unhandled/UnhandledLinesCard';
import { QueryContext as UnhandledQueryContext } from './unhandled/context/QueryContext';

export const SettlementProposalsPage = () => {
	const { reload: reloadUnhandled, queryResult: qrUnhandled } = useQueryContext(UnhandledQueryContext);
	const { reload: reloadSps, queryResult: qrSps } = useQueryContext(SpsQueryContext);
	const [trigger, hitTrigger] = useTrigger();
	const { open, confirm, cancel } = useDialogsContext(hitTrigger);
	const [tab, setTab] = useState<SettlementProposalsTabType>('unhandled');
	const { queryResult, params } = useQueryContext(QueryContext);

	useEffect(() => {
		reloadUnhandled();
		reloadSps();
		// eslint-disable-next-line
	}, [trigger]);

	if (qrUnhandled === undefined || qrSps === undefined || queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<SettlementProposalForm
				open
				metadata={qrUnhandled.metadata}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='SettlementProposals'
			stickyHeader={
				<SettlementProposalsPageHeader
					qrSpsMetadata={qrSps.metadata}
					qrUnhandledMetadata={qrUnhandled.metadata}
					tab={tab}
					setTab={setTab}
					reload={hitTrigger}
					params={params}
					queryResult={queryResult}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16 }}>
				{tab === 'sps' && (
					<SettlementProposalsCard
						onNew={onNew}
						reload={hitTrigger}
					/>
				)}
				{tab === 'unhandled' && <UnhandledLinesCard onNew={onNew} />}
			</div>
		</RestrictedPageX>
	);
};
