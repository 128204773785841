import { DialogProps } from '@material-ui/core';
import React, { useState } from 'react';
import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { commissionsQuery_usersWithNotPaidOutCommissions } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { SelectUserForm } from '../../../../settings/users/forms/SelectUserForm';
import { CommissionPayoutModelForm } from './CommissionPayoutModelForm';

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const CommissionPayoutForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [userId, setUserId] = useState<string>();
	const [userIds] = useApiEffect(commissionsQuery_usersWithNotPaidOutCommissions);

	if (userIds === undefined) {
		return <div></div>;
	} else if (userIds.length === 0) {
		return (
			<NotificationDialog
				{...rest}
				title={strings.commissions}
				content={`Alle commissies zijn reeds uitbetaald`}
				close={cancel}
			/>
		);
	} else if (userId === undefined) {
		return (
			<SelectUserForm
				{...rest}
				confirm={setUserId}
				cancel={cancel}
				userIds={userIds}
				isSubmitting={false}
			/>
		);
	} else {
		return (
			<CommissionPayoutModelForm
				{...rest}
				confirm={confirm}
				cancel={cancel}
				userId={userId}
			/>
		);
	}
};
