import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { SortPropertyDescriptor } from 'framework/components/lists/SortPropertyDescriptor';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { createType } from 'framework/utils/createType';
import { IPurchase, ISortParams } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	sortParams: ISortParams<IPurchase>;
	onFilter: (sp: ISortParams<IPurchase>) => void;
}

const PurchaseOrderableProperties = createType('created', 'supplierIdentifier', 'reference', 'externalReference', 'amountPaid', 'purchaseDate');
type PurchaseOrderableProperty = typeof PurchaseOrderableProperties[number];

const createOrderablePropertyRecord = (strings: IStrings): Record<PurchaseOrderableProperty, string> => {
	return {
		amountPaid: strings.amountPaid,
		created: strings.dateCreated,
		externalReference: strings.externalReference,
		reference: strings.reference,
		supplierIdentifier: strings.supplier,
		purchaseDate: strings.purchaseDate,
	};
};

const OrderablePropertyToSortPropertyDescriptorRecord: Record<PurchaseOrderableProperty, SortPropertyDescriptor> = {
	amountPaid: 'price',
	created: 'date',
	externalReference: 'string',
	reference: 'string',
	supplierIdentifier: 'string',
	purchaseDate: 'date',
};

export const PurchasesListOrderBySelect = ({ sortParams, onFilter }: IProps) => {
	const strings = useLocalization();
	const [anchor, open, close] = useAnchorElement();
	const OrderablePropertyRecord = React.useMemo(() => createOrderablePropertyRecord(strings), [strings]);
	const { ascSortPropertyRecord, descSortPropertyRecord } = React.useContext(RecordContext);

	const title = React.useMemo(() => {
		if (PurchaseOrderableProperties.indexOf(sortParams.property as any) > -1) {
			const prop = sortParams.property as PurchaseOrderableProperty;
			return `${OrderablePropertyRecord[prop]} (${
				sortParams.direction === 'asc'
					? ascSortPropertyRecord[OrderablePropertyToSortPropertyDescriptorRecord[prop]]
					: descSortPropertyRecord[OrderablePropertyToSortPropertyDescriptorRecord[prop]]
			})`;
		} else {
			return strings.unknown;
		}
		// eslint-disable-next-line
	}, [strings, sortParams]);

	const onSelect = (property: PurchaseOrderableProperty & keyof IPurchase, direction: 'asc' | 'desc') => {
		onFilter({ property: property, direction: direction });
		close();
	};

	return (
		<>
			<Button
				size='small'
				endIcon={<ArrowDropDownIcon />}
				onClick={open}>
				{strings.sortOnWhat(title)}
			</Button>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={close}
				keepMounted>
				{PurchaseOrderableProperties.map(prop => (
					<div key={prop}>
						<MenuItem
							key={`asc-${prop}`}
							onClick={() => onSelect(prop, 'asc')}>
							{`${strings.sortOnWhat(OrderablePropertyRecord[prop])} (${ascSortPropertyRecord[OrderablePropertyToSortPropertyDescriptorRecord[prop]]})`}
						</MenuItem>
						<MenuItem
							key={`desc-${prop}`}
							onClick={() => onSelect(prop, 'desc')}>
							{`${strings.sortOnWhat(OrderablePropertyRecord[prop])} (${descSortPropertyRecord[OrderablePropertyToSortPropertyDescriptorRecord[prop]]})`}
						</MenuItem>
					</div>
				))}
			</Menu>
		</>
	);
};
