import { DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { DraggableDialog } from 'framework/dialogs/DraggableDialog';
import { IValidateResponse } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';
import { useFormSubmit } from '../hooks/useFormSubmit';
import { useSnackbarNotify } from '../hooks/useSnackbarNotify';
import { Try } from '../Try';
import { isNullOrUndefined } from '../utils/isNullOrUndefined';
import { CancelSubmitFormDialogActions } from './CancelSubmitFormDialogActions';
import { FormMaskedTextField } from './FormMaskedTextField';
import { FormTextField } from './FormTextField';
import { handleValidateResponse } from './utils/handleValidateResponse';
import { setFieldError } from './utils/setFieldError';

interface IModel {
	value: string;
}

const createSchema = (strings: IStrings, label: string) => {
	return yup.string().required(strings.formRequired(label));
};

interface IProps extends DialogProps {
	formTitle: string;
	label: string;
	notifyMessage?: string;
	initialValue: string | undefined;
	mask?: (string | RegExp)[];
	confirm: VoidFunction;
	cancel: VoidFunction;
	submitFunction: (value: string) => Promise<Try<IValidateResponse>>;
	multiline?: boolean;
	submitText: string;
	cancelText?: string;
}

export const StringModelForm = ({
	cancelText,
	notifyMessage,
	formTitle,
	initialValue,
	submitFunction,
	label,
	confirm,
	cancel,
	mask,
	multiline = false,
	submitText,
	...rest
}: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(submitFunction);

	const handleSubmit = async (value: IModel, helpers: FormikHelpers<IModel>) => {
		if (isNullOrUndefined(value.value)) {
			setFieldError<IModel>('value', strings.formRequired(label), helpers);
		} else {
			const updateResult = await update(value.value);
			if (handleValidateResponse(updateResult, helpers, 'value')) {
				if (notifyMessage) {
					notify(notifyMessage);
				}
				confirm();
			}
		}
	};

	return (
		<Formik
			validateOnMount
			validationSchema={createSchema(strings, label)}
			initialValues={{ value: initialValue ?? '' }}
			onSubmit={handleSubmit}>
			<Form>
				<DraggableDialog
					fullWidth
					maxWidth='xs'
					title={formTitle}
					{...rest}>
					<DialogContent
						dividers
						className='df-col'>
						{mask ? (
							<FormMaskedTextField<IModel>
								mask={mask}
								name='value'
								label={label}
								required
							/>
						) : (
							<FormTextField<IModel>
								name='value'
								label={label}
								multiline={multiline}
								required
							/>
						)}
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={submitText}
						cancelText={cancelText}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</DraggableDialog>
			</Form>
		</Formik>
	);
};
