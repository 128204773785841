import { DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { ILocationModel, locationsCommand_new } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { AddressInput } from 'shared/address/AddressInput';

const createSchema = (strings: IStrings) => {
	return yup
		.object<ILocationModel>({
			name: yup.string().required(strings.formRequired(strings.name)),
			addressLine: yup.string().required(strings.formRequired(strings.addressLine)),
			zip: yup.string().required(strings.formRequired(strings.zip)),
			city: yup.string().required(strings.formRequired(strings.city)),
			country: yup.string(),
			isPlannable: yup.boolean().defined(),
		})
		.defined();
};

const emptyValues: ILocationModel = {
	name: '',
	addressLine: '',
	city: '',
	country: '',
	zip: '',
	isPlannable: true,
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

const stepsRecord: Record<number, (keyof ILocationModel)[]> = {
	0: ['name'],
	1: ['addressLine', 'city', 'country', 'zip'],
	2: ['isPlannable'],
};

export const LocationForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(locationsCommand_new);
	const [step, setStep] = useState<number>(0);

	const handleSubmit = async (values: ILocationModel, helpers: FormikHelpers<ILocationModel>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.locationCreated);
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<ILocationModel>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<OverflowDialog {...rest}>
					<DialogTitleWithFormStepper
						step={step}
						title={strings.addLocation}
						labels={[strings.name, strings.addressData, strings.plannable]}
					/>
					<OverflowDialogContent className='df-col'>
						{step === 0 && (
							<FormTextField<ILocationModel>
								name='name'
								label={strings.name}
								required
							/>
						)}
						{step === 1 && <AddressInput<ILocationModel> />}
						{step === 2 && (
							<FormExtendedRadioGroupField<ILocationModel, boolean>
								name='isPlannable'
								label={strings.plannable}
								options={[
									{ value: true, label: strings.availableInCalendar, caption: strings.availableInCalendarCaption },
									{ value: false, label: strings.notAvailableInCalendar, caption: strings.notAvailableInCalendarCaption },
								]}
							/>
						)}
					</OverflowDialogContent>
					<PageableFormDialogActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
					/>
				</OverflowDialog>
			</Form>
		</Formik>
	);
};
