import _ from 'lodash';

interface IXyPoint {
	x: number;
	y: number;
}

export const linearInterpolateSolveY = (x: number, points: IXyPoint[]): number => {
	if (points.filter(t => x > t.x).length === 0 || points.filter(t => x < t.x).length === 0) {
		return 0;
	}
	const f = points.find(t => t.x === x);
	if (f) {
		return f.y;
	} else {
		const ordered = _.orderBy(points, t => t.x);
		const first = ordered.filter(t => t.x >= x)[0];
		const smaller = ordered.filter(t => t.x <= x);
		const last = smaller[smaller.length - 1];
		return internalSolveForPoint(x, last, first);
	}
};

const internalSolveForPoint = (x: number, xy0: IXyPoint, xy1: IXyPoint) => {
	return internalSolve(x, xy0.x, xy1.x, xy0.y, xy1.y);
};

const internalSolve = (x: number, x0: number, x1: number, y0: number, y1: number) => {
	if (x1 - x0 === 0) {
		return (y0 + y1) / 2;
	} else {
		return y0 + ((x - x0) * (y1 - y0)) / (x1 - x0);
	}
};
