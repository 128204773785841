import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { IPatientDetail, salesQuery_byPatientX } from 'gen/ApiClient';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { SalesByPatientCard } from './SalesByPatientCard';
import { SalesByPatientStats } from './SalesByPatientStats';

interface IProps extends IReloadTriggerProps {
	item: IPatientDetail;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const SalesByPatientTab = ({ item, reloadTrigger, reload, style }: IProps) => {
	const [data, reloadSales] = useApiEffect(salesQuery_byPatientX, item.patient.id);

	useLazyEffect(() => {
		reloadSales();
	}, [reloadTrigger]);

	if (data === undefined) {
		return <></>;
	}

	return (
		<div
			className='df-col gap-16'
			style={style}>
			<SalesByPatientStats data={data} />
			<SalesByPatientCard
				data={data}
				reload={reload}
				hasLinkedCustomerAccount={item.hasCustomerAccount}
			/>
		</div>
	);
};
