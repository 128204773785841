import { Card, CardProps } from '@material-ui/core';
import React from 'react';

interface IProps extends CardProps {
	children: React.ReactNode;
	removeHorizontalPadding?: boolean;
}

export const CardX = ({ children, style, removeHorizontalPadding, ...rest }: IProps) => {
	return (
		<Card
			style={{
				borderRadius: 12,
				paddingTop: 16,
				paddingBottom: 16,
				paddingLeft: removeHorizontalPadding === true ? 0 : 16,
				paddingRight: removeHorizontalPadding === true ? 0 : 16,
				overflow: 'unset',
				...style,
			}}
			{...rest}>
			{children}
		</Card>
	);
};
