import { Divider, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { theme } from '../../theme';
import { MoneyTextField } from '../components/textFields/MoneyTextField';
import { useAnchorElement } from '../hooks/useAnchorElement';
import { formatCurrency } from '../utils/formatCurrency';
import { FilterButton } from './FilterButton';
import { FilterMenu } from './FilterMenu';
import { useLocalization } from 'localization/useLocalization';

interface IMinMax {
	min: number | undefined;
	max: number | undefined;
}

interface IProps {
	min?: number;
	max?: number;
	selected: IMinMax;
	setSelected: (value: IMinMax) => void;
	label: string;
	icon: React.ReactNode;
}

export const MoneyRangeFilterComponent = ({ min, max, selected, setSelected, label, icon }: IProps) => {
	const strings = useLocalization();
	const [value, setValue] = useState<IMinMax>(selected);
	const [anchor, open, close] = useAnchorElement();

	useEffect(() => {
		setValue(selected);
	}, [selected]);

	const handleClose = () => {
		if (value !== selected) {
			setSelected(value);
		}
		close();
	};

	return (
		<>
			<FilterButton
				badgeContent={selected.min === undefined && selected.max === undefined ? 0 : 1}
				label={label}
				handleOpen={open}
				icon={icon}
			/>
			<FilterMenu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={handleClose}>
				<div
					className='df-col'
					style={{ padding: theme.spacing(2) }}>
					<Typography align='center'>{strings.selectPriceRange}</Typography>
					{min && max && (
						<Typography
							variant='caption'
							align='center'>{`min: ${formatCurrency(min)} - max: ${formatCurrency(max)}`}</Typography>
					)}
					<Divider />
					<div
						className='df-row-ac jc-sb'
						style={{ marginTop: theme.spacing(2) }}>
						<MoneyTextField
							value={value.min}
							onChange={val => setValue({ ...value, min: val })}
							label='Min'
							style={{ marginRight: theme.spacing(1.5) }}
						/>
						<MoneyTextField
							value={value.max}
							onChange={val => setValue({ ...value, max: val })}
							label='Max'
						/>
					</div>
				</div>
			</FilterMenu>
		</>
	);
};
