import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		created: strings.createdAt,
		identifier: `${strings.who}?`,
		outstandingBalance: strings.outstandingBalance,
		amountToPay: strings.amountToPay,
		countPurchases: `# ${strings.purchases}`,
	};
};
