import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, useTheme } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { SortableTable } from 'framework/table/SortableTable';
import {
	IInventoryItemWithProductCatalogItemDto,
	inventoryItemsQuery_queryByIdsWithProductCatalogItem,
	noahDeviceInventoryItemMapsQuery_byPatient,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from '../../dialogs/useDialogsContext';
import { ImportDeviceForm } from 'app/main/inventoryManagement/noahDevices/single/forms/ImportDeviceForm';
import { INoahDeviceForPatientWithSelection } from 'app/main/inventoryManagement/noahDevices/single/INoahDeviceForPatientWithSelection';
import { createSmallPrimaryTextButtonHeader } from '../../table/createSmallPrimaryTextButtonHeader';
import { createHeaders } from './createHeaders';

interface IProps extends DialogProps {
	patientId: string;
	confirm: (vals: IInventoryItemWithProductCatalogItemDto[]) => void;
	cancel: VoidFunction;
}

export const ImportNoahDevicesByPatientForm = ({ patientId, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [devices, reload] = useApiEffect(noahDeviceInventoryItemMapsQuery_byPatient, patientId);
	const theme = useTheme();
	const [lines, setLines] = useState<INoahDeviceForPatientWithSelection[]>([]);
	const headers = useMemo(() => createHeaders(lines, setLines, strings), [lines, setLines, strings]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const { open, confirm: confirmX, cancel: cancelX, isStacked } = useDialogsContext(reload);
	const loadInventoryItems = useApiCall(inventoryItemsQuery_queryByIdsWithProductCatalogItem);

	useEffect(() => {
		if (devices) {
			setLines(prev => devices.map(t => ({ isSelected: prev.find(x => x.id === t.id)?.isSelected ?? false, ...t })));
		}
	}, [devices]);

	if (devices === undefined) {
		return <div></div>;
	}

	const onConfirm = async () => {
		setIsSubmitting(true);
		const r = await loadInventoryItems(lines.filter(t => t.isSelected && t.inventoryItemId).map(t => t.inventoryItemId!));
		if (r.isSuccess) {
			setIsSubmitting(false);
			confirm(r.result);
		} else {
			setIsSubmitting(false);
		}
	};

	const onImport = (t: INoahDeviceForPatientWithSelection) => {
		open(
			<ImportDeviceForm
				id={t.id}
				confirm={confirmX}
				cancel={cancelX}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			fullWidth
			maxWidth='md'
			open={rest.open && isStacked === false}>
			<DialogTitle>{strings.selectWhat(strings.devicesFromNoah)}</DialogTitle>
			<DialogContent
				dividers
				style={{ padding: 0 }}>
				<SortableTable<INoahDeviceForPatientWithSelection>
					padding={theme.spacing(1)}
					headers={headers}
					getKey={t => t.id}
					values={lines}
					defaultSortParams={{ property: 'lastSelectionDate', direction: 'desc' }}
					appendColumn={createSmallPrimaryTextButtonHeader<INoahDeviceForPatientWithSelection>(
						'import',
						strings.import,
						t => onImport(t),
						t => t.isLinkedToInventoryItem
					)}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<LoaderButton
					isLoading={isSubmitting}
					onClick={onConfirm}
					disabled={lines.filter(t => t.isSelected).length === 0}
					color='primary'>
					{strings.selectWhat(`(${lines.filter(t => t.isSelected).length})`)}
				</LoaderButton>
			</DialogActions>
		</Dialog>
	);
};
