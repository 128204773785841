import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { FilterBar } from 'framework/filter/FilterBar';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IRizivHearingAidDefinitionsV2ManufacturerAndNameGroupQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { RizivHifsFilterDataContext } from '../context/RizivHifsFilterDataContext';

const exclusions: (keyof IRizivHearingAidDefinitionsV2ManufacturerAndNameGroupQueryParams)[] = [];

interface IProps {
	params: IRizivHearingAidDefinitionsV2ManufacturerAndNameGroupQueryParams;
	onFilter: (params: IRizivHearingAidDefinitionsV2ManufacturerAndNameGroupQueryParams) => void;
	paddingLeft: number;
}

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const [data] = useCacheContext(RizivHifsFilterDataContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			{data && (
				<StringListFilterComponent
					label={strings.distributor}
					selected={params.distributors}
					options={data.distributors}
					icon={<HelpOutlineIcon />}
					setSelected={value => onFilter({ ...params, distributors: value })}
				/>
			)}
		</FilterBar>
	);
};
