import { Popover } from '@material-ui/core';
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IContactInfo, patientContactInfosCommand_deleteContactInfo, patientContactInfosCommand_makeContactInfoPrimary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PatientContactInfoPopoverContent } from './PatientContactInfoPopoverContent';
import { PrimaryContactInfoIconAndTooltip } from './PrimaryContactInfoIconAndTooltip';
import { PatchPatientContactInfoForm } from './PatchPatientContactInfoForm';

interface IProps {
	item: IContactInfo;
	patientId: string;
	reload: VoidFunction;
}

export const PatientContactInfoListComponent = ({ item, patientId, reload }: IProps) => {
	const [makePrimary] = useFormSubmit(patientContactInfosCommand_makeContactInfoPrimary);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const strings = useLocalization();
	const [anchor, openAnchor, closeAnchor] = useAnchorElement();

	const onMakePrimary = async () => {
		closeAnchor();
		if (item.isPrimary === false) {
			const r = await makePrimary({ patientId: patientId, contactInfoId: item.id });
			if (r.isSuccess) {
				reload();
			}
		}
	};

	const onEdit = () => {
		closeAnchor();
		open(
			<PatchPatientContactInfoForm
				open
				confirm={confirm}
				cancel={cancel}
				patientId={patientId}
				contactInfo={item}
			/>
		);
	};

	const onDelete = () => {
		closeAnchor();
		open(
			<AlertDialog
				open
				title={strings.deleteContactInfo}
				content={strings.doYouWantToDeleteThisContactInfoQuestion}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => patientContactInfosCommand_deleteContactInfo({ patientId: patientId, contactInfoId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<>
			{Boolean(anchor) && (
				<Popover
					open={Boolean(anchor)}
					anchorEl={anchor}
					onClose={closeAnchor}>
					<PatientContactInfoPopoverContent
						item={item}
						onDelete={onDelete}
						onEdit={onEdit}
						onMakePrimary={onMakePrimary}
					/>
				</Popover>
			)}
			<div
				onClick={openAnchor}
				className='df-col'
				style={{ cursor: 'pointer', marginTop: 6, marginBottom: 6 }}>
				<div className='df-row-ac'>
					{item.isEmail && (
						<MailOutlineIcon
							color='action'
							style={{ fontSize: 18 }}
						/>
					)}
					{item.isPhone && (
						<LocalPhoneOutlinedIcon
							color='action'
							style={{ fontSize: 18 }}
						/>
					)}
					<div style={{ marginLeft: 4 }}>{item.formattedValue}</div>
					{item.isValid === false && (
						<StatusIcon
							status='error'
							style={{ marginLeft: 4 }}
						/>
					)}
					{item.isPrimary && <PrimaryContactInfoIconAndTooltip />}
				</div>
				{item.description && <div style={{ fontSize: 12, marginLeft: 24 }}>{`(${item.description})`}</div>}
			</div>
		</>
	);
};
