import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { annotationTypesCommand_new, annotationTypesCommand_update, IAnnotationType, IAnnotationTypeModel } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect } from 'react';
import { CirclePicker } from 'react-color';
import * as yup from 'yup';
import { DefaultColors } from '../../color/DefaultColors';
import { AnnotationTypesCacheContext } from '../context/AnnotationTypesCacheContext';

const createSchema = (strings: IStrings) => {
	return yup.object<IAnnotationTypeModel>({
		color: yup.string().required(),
		name: yup.string().required(),
		isFollowUp: yup.bool().defined(),
	});
};

const emptyValues: IAnnotationTypeModel = {
	color: '',
	name: '',
	isFollowUp: false,
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	annotationType?: IAnnotationType;
	isFollowUp: boolean;
}

export const AnnotationTypeForm = ({ confirm, cancel, annotationType, isFollowUp, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [create, isCreateSubmitting] = useFormSubmit(annotationTypesCommand_new);
	const [update, isUpdateSubmitting] = useFormSubmit(annotationTypesCommand_update);
	const [data] = usePreloadCacheContext(AnnotationTypesCacheContext);
	const strings = useLocalization();

	const handleSubmit = async (values: IAnnotationTypeModel, helpers: FormikHelpers<IAnnotationTypeModel>) => {
		if (annotationType) {
			const r = await update(annotationType.id, values);
			if (handleFormResponse(r, helpers)) {
				notify(strings.changedWhat(strings.typeAnnotation));
				confirm(annotationType.id);
			}
		} else {
			const r = await create(values);
			if (handleFormResponse(r, helpers)) {
				notify(strings.addedWhat(strings.typeAnnotation));
				confirm(r.result.objectId);
			}
		}
	};

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IAnnotationTypeModel>
			validateOnMount
			initialValues={{
				...emptyValues,
				isFollowUp: isFollowUp,
				...annotationType,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					isSubmitting={isCreateSubmitting || isUpdateSubmitting}
					cancel={cancel}
					annotationType={annotationType}
					allTypes={data}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	annotationType: IAnnotationType | undefined;
	allTypes: IAnnotationType[];
}

const InnerDialog = ({ cancel, isSubmitting, annotationType, allTypes, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IAnnotationTypeModel>();
	const strings = useLocalization();

	useEffect(() => {
		if (annotationType !== undefined) {
			return;
		}
		// auto choose next color
		const selectedColors = allTypes.map(t => t.color);
		const remaining = DefaultColors.filter(t => selectedColors.indexOf(t) === -1);
		if (remaining.length > 0) {
			setFormValue<IAnnotationTypeModel>('color', remaining[0], props);
		}
		// eslint-disable-next-line
	}, [annotationType, allTypes]);

	return (
		<Dialog
			{...rest}
			fullWidth
			maxWidth='xs'>
			<DialogTitle>{annotationType ? strings.changeWhat(strings.typeAnnotation) : strings.newWhat(strings.typeAnnotation)}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<FormTextField<IAnnotationTypeModel>
					name='name'
					label={strings.name}
				/>
				<DividerWithCaption caption='Kleur' />
				<div style={{ marginTop: 8, marginBottom: 16 }}>
					<CirclePicker
						color={props.values.color}
						onChange={e => setFormValue<IAnnotationTypeModel>('color', e.hex, props)}
						circleSize={20}
						circleSpacing={8}
						colors={DefaultColors}
						width='170px'
					/>
				</div>
			</DialogContent>
			<CancelSubmitFormDialogActions
				submitText={annotationType ? strings.update : strings.create}
				isSubmitting={isSubmitting}
				cancel={cancel}
			/>
		</Dialog>
	);
};
