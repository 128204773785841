import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { IntegrationsRoute } from 'routes';

export const IntegrationsPageBreadcrumb = () => {
	const strings = useLocalization();

	return (
		<PageBreadcrumb
			text={strings.integrations}
			route={IntegrationsRoute}
			icon={<AccountTreeOutlinedIcon />}
		/>
	);
};
