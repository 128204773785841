import { IconButton, useTheme } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

//https://iamhosseindhv.com/notistack/demos#action-for-all-snackbars
export const SnackbarWrapper = ({ children }: any) => {
	const notistackRef = React.createRef<any>();
	const theme = useTheme();

	return (
		<SnackbarProvider
			ref={notistackRef}
			className='snackbar-provider'
			maxSnack={3}
			action={key => (
				<IconButton
					onClick={() => {
						notistackRef.current.closeSnackbar(key);
					}}>
					<CloseIcon style={{ color: theme.palette.getContrastText(theme.palette.primary.main) }} />
				</IconButton>
			)}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
			{children}
		</SnackbarProvider>
	);
};
