import { Grid, GridSize } from '@material-ui/core';
import { useResizeObserver } from 'framework/hooks/useResizeObserver';
import { IDashboardData } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { SalesRoute, SettlementProposalsRoute } from '../financial/routes';
import { DashboardWorkflowCard } from './components/DashboardWorkflowCard';
import { WorkflowSummaryDataCard } from './components/WorkflowSummaryDataCard';

interface IProps {
	data: IDashboardData;
}

export const DashboardProcessesGrid = ({ data }: IProps) => {
	const [ref, width] = useResizeObserver();
	const gridSize = useMemo(() => widthToGridSize(width), [width]);
	const { push } = useHistory();
	const strings = useLocalization();

	const onNavigateToSales = () => {
		push(SalesRoute);
	};

	const onNavigateToSps = () => {
		push(SettlementProposalsRoute);
	};

	return (
		<Grid
			container
			spacing={2}
			style={{ paddingRight: 0 }}
			ref={ref}>
			<GridItemDashboardWorkflowCard
				size={gridSize}
				show={data.rfiasStats.hasData}>
				<WorkflowSummaryDataCard
					data={data.rfiasStats}
					formatTotal={count => `${count} ${strings.runningRequestSingularOrPlural.toLowerCase()}`}
				/>
			</GridItemDashboardWorkflowCard>
			<GridItemDashboardWorkflowCard
				size={gridSize}
				show={data.articlesInquiriesStats.hasData}>
				<WorkflowSummaryDataCard
					data={data.articlesInquiriesStats}
					formatTotal={count => `${count} ${strings.articlesInquiryToAssignSingularOrPlural.toLowerCase()}`}
				/>
			</GridItemDashboardWorkflowCard>
			<GridItemDashboardWorkflowCard
				size={gridSize}
				show={data.ordersStats.hasData}>
				<WorkflowSummaryDataCard
					data={data.ordersStats}
					formatTotal={count => `${count} ${strings.notDeliveredOrdersSingularOrPlural.toLowerCase()}`}
				/>
			</GridItemDashboardWorkflowCard>
			<GridItemDashboardWorkflowCard
				size={gridSize}
				show={data.repairsStats.hasData}>
				<WorkflowSummaryDataCard
					data={data.repairsStats}
					formatTotal={count => `${count} ${strings.runningRepairSingularOrPlural.toLowerCase()}`}
				/>
			</GridItemDashboardWorkflowCard>
			<GridItemDashboardWorkflowCard
				size={gridSize}
				show={data.loansStats.hasData}>
				<WorkflowSummaryDataCard
					data={data.loansStats}
					formatTotal={count => `${count} ${strings.runningLoanSingularOrPlural.toLowerCase()}`}
				/>
			</GridItemDashboardWorkflowCard>
			<GridItemDashboardWorkflowCard
				size={gridSize}
				show={data.salesStats.hasData}>
				<DashboardWorkflowCard
					title={strings.sales}
					data={{ count: data.salesStats.countNotPaid, countWarningNoError: data.salesStats.countOverdue, countError: 0 }}
					formatErrors={count => `${count} ${strings.overdue.toLowerCase()} ${strings.patient.toLowerCase()}`}
					formatWarnings={count => `${count} ${strings.overdue.toLowerCase()} ${strings.healthInsuranceFundShort.toLowerCase()}`}
					formatTotal={count => `${count} ${strings.unPaidSales.toLowerCase()}`}
					normalColor='primary'
					onNavigate={onNavigateToSales}
				/>
			</GridItemDashboardWorkflowCard>
			<GridItemDashboardWorkflowCard
				size={gridSize}
				show={data.settlementProposalsStats.hasData}>
				<DashboardWorkflowCard
					title={strings.settlementProposals}
					data={{ count: data.settlementProposalsStats.countNotPaid, countWarningNoError: data.settlementProposalsStats.countOverDue, countError: 0 }}
					formatWarnings={count => `${count} ${strings.overdue.toLowerCase()}`}
					formatTotal={count => `${count} ${strings.outstanding.toLowerCase()}`}
					normalColor='primary'
					onNavigate={onNavigateToSps}
				/>
			</GridItemDashboardWorkflowCard>
		</Grid>
	);
};

const widthToGridSize = (width: number): GridSize => {
	if (width >= 1260) {
		return 2;
	} else if (width >= 840) {
		return 3;
	} else if (width >= 630) {
		return 4;
	} else {
		return 5;
	}
};

interface IGridItemDashboardWorkflowCardProps {
	children: any;
	size: GridSize;
	show?: boolean;
}

const GridItemDashboardWorkflowCard = ({ children, size, show = true }: IGridItemDashboardWorkflowCardProps) => {
	return show === false ? null : (
		<Grid
			item
			xs={size}>
			{children}
		</Grid>
	);
};
