import { Dialog, DialogContent, DialogProps, DialogTitle, Divider, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { isLast } from 'framework/utils/array/isLast';
import { IMedicalTreatmentNomenclatureForAudiology, IRequestForInsuranceAllowance, rfiasCommand_selectMainMedicalTreatment } from 'gen/ApiClient';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { MedicalTreatmentNomenclatureDialog } from 'shared/medicalTreatmentNomenclatures/MedicalTreatmentNomenclatureDialog';
import { LumpSumFilter } from './LumpSumFilter';
import { LumpSumType } from './LumpSumType';

interface IProps extends DialogProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
	type?: LumpSumType;
}

export const LumpSumMedicalTreatmentWizardForm = ({ rfia, confirm, cancel, type, ...rest }: IProps) => {
	const strings = useLocalization();
	const { open, cancel: closeDetail, isStacked } = useDialogsContext();
	const [submit, isSubmitting] = useFormSubmit(rfiasCommand_selectMainMedicalTreatment);
	const [selectedCode, setSelectedCode] = useState<string>();
	const [filtered, setFiltered] = useState<IMedicalTreatmentNomenclatureForAudiology[]>([]);
	const language = useCurrentLanguage();

	const handleSubmit = async (code: string, onSuccess: VoidFunction) => {
		setSelectedCode(code);
		const r = await submit(rfia.id, code);
		// TODO handle response ico validation error
		if (r.isSuccess) {
			onSuccess();
			confirm();
		}
	};

	const onViewDetail = (code: string) => {
		open(
			<MedicalTreatmentNomenclatureDialog
				code={code}
				open
				cancel={closeDetail}
				select={
					rfia.mainMedicalTreatmentNomenclatureCode !== code
						? async () => {
								await handleSubmit(code, closeDetail);
						  }
						: undefined
				}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			scroll='paper'
			fullWidth
			open={rest.open && isStacked === false}>
			<CloseButtonOnAbsolutePosition onClick={cancel} />
			<DialogTitle>{`${strings.selectWhat(strings.nomenclature)} (${strings.lumpSum})`}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<LumpSumFilter setFiltered={setFiltered} />
				<DividerWithCaption caption={`${strings.filteredResults} (${filtered.length.toString()})`} />
				<List
					dense
					style={{ overflow: 'auto', marginLeft: '-24px', marginRight: '-24px' }}>
					{filtered.map(t => (
						<div
							className='df-col'
							key={t.data.code}>
							<ListItem
								button
								onClick={() => onViewDetail(t.data.code)}
								style={{ paddingLeft: '29px' }}
								disabled={isSubmitting}>
								<ListItemText
									primary={t.data.code}
									secondary={language === 'fr' ? t.data.descriptionFr : t.data.descriptionNl}
									style={{ paddingRight: '40px' }}
								/>
								<ListItemSecondaryAction>
									<LoaderButton
										variant='text'
										size='small'
										color='primary'
										isLoading={isSubmitting && selectedCode === t.data.code}
										disabled={isSubmitting}
										onClick={() => handleSubmit(t.data.code, () => {})}>
										{strings.select}
									</LoaderButton>
								</ListItemSecondaryAction>
							</ListItem>
							{isLast(t, filtered) === false && <Divider />}
						</div>
					))}
				</List>
			</DialogContent>
		</Dialog>
	);
};
