import { Card } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import React from 'react';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DefaultCardContent } from 'framework/components/DefaultCardContent';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { DateWithUnitModelForm } from 'framework/forms/DateWithUnitModelForm';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { formatDate } from 'framework/utils/date/formatDate';
import {
	IInventoryItem,
	inventoryItemsCommand_clearConsignmentReturnBeforeDate,
	inventoryItemsCommand_patchConsignmentReturnBeforeDate,
	TimeUnit,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';

interface IProps {
	item: IInventoryItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const ConsigmentInfoCard = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [patch] = useFormSubmit(inventoryItemsCommand_patchConsignmentReturnBeforeDate);
	const [clear] = useFormSubmit(inventoryItemsCommand_clearConsignmentReturnBeforeDate);

	const onUpdate = () => {
		open(
			<DateWithUnitModelForm
				open
				units={['Days', 'Weeks', 'Months']}
				defaultUnit='Months'
				defaultUnitValue={{ value: 1, unit: 'Months' as TimeUnit }}
				formTitle={strings.selectWhat(strings.dateReturnBefore)}
				confirm={confirm}
				cancel={cancel}
				label={strings.date}
				submitFunction={val => patch({ inventoryItemId: item.id, date: val })}
				initialValue={item.consignmentReturnBeforeDate}
			/>
		);
	};

	const onClear = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.date)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => clear({ inventoryItemId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<Card
			style={style}
			className='df-col'>
			<DefaultCardContent>
				<CardTitleTypography
					title={strings.consignment}
					edit={onUpdate}
				/>
				{item.isOnConsignment && (
					<>
						{item.isConsignmentExpired && (
							<ErrorLabelledProperty
								label={strings.dateReturnBefore}
								error={formatDate(item.consignmentReturnBeforeDate)}
								secondaryAction={onClear}
								secondaryActionIcon={<DeleteOutlineIcon />}
							/>
						)}
						{item.isConsignmentExpired === false && item.consignmentExpiresWithinAWeek && (
							<WarningLabelledProperty
								label={strings.dateReturnBefore}
								warning={formatDate(item.consignmentReturnBeforeDate)}
								secondaryAction={onClear}
								secondaryActionIcon={<DeleteOutlineIcon />}
							/>
						)}
						{item.consignmentExpiresWithinAWeek === false && (
							<LabelledProperty
								label={strings.dateReturnBefore}
								property={formatDate(item.consignmentReturnBeforeDate)}
								secondaryAction={onClear}
								secondaryActionIcon={<DeleteOutlineIcon />}
							/>
						)}
					</>
				)}
				{item.hasConsignmentReturnBeforeDate === false && item.isPurchasedOrReturnedToSupplier === false && (
					<NoRecordsFound style={{ paddingTop: 8 }}>{strings.noWhatSelected(strings.date)}</NoRecordsFound>
				)}
				{item.isPurchasedOrReturnedToSupplier && <NoRecordsFound style={{ paddingTop: 8 }}>{strings.notApplicableAbbreviation}</NoRecordsFound>}
			</DefaultCardContent>
		</Card>
	);
};
