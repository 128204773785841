import React from 'react';
import { BadgeVariant } from './BadgeVariant';
import { RadioFilterComponent } from './RadioFilterComponent';

interface IProps {
	trueText: string;
	falseText: string;
	selected: boolean | undefined;
	setSelected: (selected: boolean | undefined) => void;
	label: string;
	icon?: React.ReactNode;
	style?: React.CSSProperties;
	badgeVariant?: BadgeVariant;
	disabled?: boolean;
}

export const BooleanRadioFilterComponent = ({ trueText, falseText, selected, setSelected, label, icon, style, badgeVariant, disabled }: IProps) => {
	return (
		<RadioFilterComponent<boolean>
			disabled={disabled}
			disableSearch
			options={[
				{ id: true, identifier: trueText },
				{ id: false, identifier: falseText },
			]}
			selected={selected}
			setSelected={setSelected}
			label={label}
			icon={icon}
			style={style}
			badgeVariant={badgeVariant}
		/>
	);
};
