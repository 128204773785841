import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { LocalStateTextField } from 'framework/components/textFields/LocalStateTextField';
import { QuantityField } from 'framework/components/textFields/QuantityField';
import { createHeader } from 'framework/table/createHeader';
import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { IHeader } from 'framework/table/IHeader';
import { updateArray } from 'framework/utils/array/updateArray';
import { IStrings } from 'localization/IStrings';
import React from 'react';
import { IDeliveryLineWithSelection } from '../RegisterRepairDeliveryRequestForm';

export const createHeaders = (
	lines: IDeliveryLineWithSelection[],
	setLines: (lines: IDeliveryLineWithSelection[]) => void,
	hasTouchedSubmit: boolean,
	strings: IStrings
): IHeader<IDeliveryLineWithSelection>[] => {
	return [
		createSelectionHeader<IDeliveryLineWithSelection>(lines, setLines),
		createHeader<IDeliveryLineWithSelection>(strings.name, t => <div className='fg1'>{t.repairLine.identifier}</div>),
		createHeader<IDeliveryLineWithSelection>(
			strings.quantity,
			t => (
				<QuantityField
					value={t.quantity}
					setValue={val => setLines(updateArray(t, lines, { ...t, quantity: val }))}
					error={t.isSelected && t.quantity > t.repairLine.quantity}
					helperText={t.isSelected && t.quantity > t.repairLine.quantity && `Max. ${t.repairLine.quantity}`}
					max={t.repairLine.quantity}
					disabled={t.repairLine.isInventoryItem}
				/>
			),
			'center'
		),
		createHeader<IDeliveryLineWithSelection>(strings.serialNumber, t => (
			<>
				{t.repairLine.isInventoryItem ? (
					<div className='df-row-ac'>
						<FormControlLabelSwitch
							isChecked={t.hasNewSerialNumber}
							toggle={() => setLines(updateArray(t, lines, { ...t, hasNewSerialNumber: !t.hasNewSerialNumber }))}
							label={`${strings.newSerialNumber}?`}
						/>
						{t.hasNewSerialNumber && (
							<LocalStateTextField
								placeholder={`<${strings.newSerialNumber}>`}
								value={t.newSerialNumber}
								onChange={e => setLines(updateArray(t, lines, { ...t, newSerialNumber: e.target.value, hasNewSerialNumber: true }))}
								error={hasTouchedSubmit && t.isSelected && !t.newSerialNumber}
								helperText={hasTouchedSubmit && t.isSelected && !t.newSerialNumber && strings.fillWhat(strings.serialNumber)}
							/>
						)}
					</div>
				) : (
					strings.notApplicableAbbreviation
				)}
			</>
		)),
	];
};
