import { INewLineModel } from 'shared/searchAndSelect/INewLineModel';
import { ILine } from '../ILine';

export const newLineToLine = (t: INewLineModel): ILine => {
	return {
		isSelected: true,
		id: t.id,
		description: t.description,
		item: undefined,
		quantity: 1,
		unitListPrice: t.unitListPrice,
		unitPrice: t.unitListPrice,
		vatPercentage: t.vatPercentage,
	};
};
