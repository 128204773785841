import { DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { DraggableDialog } from 'framework/dialogs/DraggableDialog';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IAnnotationModel, LinkViewContextType, annotationsCommand_add } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import * as yup from 'yup';
import { AnnotationFormField } from './AnnotationFormField';
import { AnnotationTypeFormField } from './AnnotationTypeFormField';
import { AnnotationTypesCacheContext } from './context/AnnotationTypesCacheContext';

const createSchema = (strings: IStrings) => {
	return yup.object<IAnnotationModel>({
		content: yup.string().defined(),
		contextId: yup.string().required(),
		linkViewContextType: yup.string().required(),
		annotationTypeId: yup.string(),
		isFollowUp: yup.bool().defined(),
	});
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	contextId: string;
	linkViewContextType: LinkViewContextType;
	isFollowUp: boolean;
}

export const AnnotationForm = ({ contextId, linkViewContextType, confirm, cancel, isFollowUp, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(annotationsCommand_add);
	const [data] = usePreloadCacheContext(AnnotationTypesCacheContext);
	const { isStacked } = useDialogsContext();

	const handleSubmit = async (values: IAnnotationModel, helpers: FormikHelpers<IAnnotationModel>) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IAnnotationModel>
			validateOnMount
			initialValues={{
				content: '',
				contextId: contextId,
				linkViewContextType: linkViewContextType,
				annotationTypeId: '',
				isFollowUp: isFollowUp,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<DraggableDialog
					fullWidth
					title={strings.createWhat(strings.newAnnotation)}
					{...rest}
					open={rest.open && isStacked === false}>
					<DialogContent
						className='df-col'
						dividers>
						<AnnotationTypeFormField<IAnnotationModel> isFollowUp={isFollowUp} />
						<AnnotationFormField<IAnnotationModel> />
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
					/>
				</DraggableDialog>
			</Form>
		</Formik>
	);
};
