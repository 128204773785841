import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	INoahDeviceInventoryItemMapDetail,
	INoahDeviceInventoryItemMapsQueryParams,
	IQueryResult,
	noahDeviceInventoryItemMapsQuery_concurrencyToken,
	noahDeviceInventoryItemMapsQuery_queryAll,
} from 'gen/ApiClient';
import { noahDeviceInventoryItemMapsQueryParamsLsKey } from '../../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: INoahDeviceInventoryItemMapsQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'serialNumber' },
	pageParams: { index: 1, size: 10 },
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<INoahDeviceInventoryItemMapDetail>, INoahDeviceInventoryItemMapsQueryParams>
			defaultParams={DefaultParams}
			queryF={noahDeviceInventoryItemMapsQuery_queryAll}
			concurrencyF={noahDeviceInventoryItemMapsQuery_concurrencyToken}
			localStorageKey={noahDeviceInventoryItemMapsQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
