import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from './CancelSubmitFormDialogActions';
import { FormMoneyField } from './FormMoneyField';
import { handleValidateResponse } from './utils/handleValidateResponse';
import { useFormSubmit } from '../hooks/useFormSubmit';
import { useSnackbarNotify } from '../hooks/useSnackbarNotify';
import { Try } from '../Try';
import { IValidateResponse } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

interface IModel {
	value: number;
}

const createSchema = (strings: IStrings, label: string) => {
	return yup.number().required(strings.formRequired(label));
};

interface IProps extends DialogProps {
	formTitle: string;
	label: string;
	notifyMessage?: string;
	initialValue: number | undefined;
	confirm: VoidFunction;
	cancel: VoidFunction;
	submitFunction: (value: number) => Promise<Try<IValidateResponse>>;
	allowNegative?: boolean;
}

export const MoneyModelForm = ({ notifyMessage, formTitle, initialValue, submitFunction, label, confirm, cancel, allowNegative, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(submitFunction);

	const handleSubmit = async (value: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await update(value.value);
		if (handleValidateResponse(r, helpers, 'value')) {
			if (notifyMessage) {
				notify(notifyMessage);
			}
			confirm();
		}
	};

	return (
		<Formik
			validateOnMount
			validationSchema={createSchema(strings, label)}
			initialValues={{ value: initialValue as any }}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					maxWidth='xs'
					{...rest}>
					<DialogTitle>{formTitle}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormMoneyField<IModel>
							name='value'
							label={label}
							allowNegative={allowNegative}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.update}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
