import { DialogProps } from '@material-ui/core';
import React from 'react';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { agendaCommand_new, IObjectWithIdAndIdentifier } from 'gen/ApiClient';
import { CalendarEventForm } from './CalendarEventForm';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	startDate: Date;
	endDate: Date;
	isAllDay: boolean;
	patient?: IObjectWithIdAndIdentifier<string>;
	eventType?: IObjectWithIdAndIdentifier<string>;
	roomGroupKey?: string;
	userGroupKey?: string;
}

export const CreateCalendarEventForm = ({ ...props }: IProps) => {
	const [create, isSubmitting] = useFormSubmit(agendaCommand_new);

	return (
		<CalendarEventForm
			submitF={create}
			isSubmitting={isSubmitting}
			{...props}
		/>
	);
};
