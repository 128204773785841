import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { usersQuery_single } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { UserDetailsContent } from '../UserDetailsContent';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';

interface IProps extends DialogProps {
	close: VoidFunction;
	id: string;
}

export const UserDetailDialog = ({ id, close, ...rest }: IProps) => {
	const [user, reload] = useApiEffect(usersQuery_single, id);
	const { isStacked } = useDialogsContext();

	if (user === undefined) {
		return <div></div>;
	}

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			onClose={close}
			fullWidth>
			<CloseButtonOnAbsolutePosition onClick={close} />
			<DialogContent>
				<UserDetailsContent
					item={user}
					reload={reload}
				/>
			</DialogContent>
		</Dialog>
	);
};
