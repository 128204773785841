import { IInventoryItem } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

interface ILine {
	inventoryItem: IInventoryItem | undefined;
}

export const calculateDemoWarning = (line: ILine, strings: IStrings): string | undefined => {
	if (line.inventoryItem !== undefined && line.inventoryItem.isDemo) {
		return strings.articleIsRegisteredAsDemo;
	} else {
		return undefined;
	}
};
