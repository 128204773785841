import { Button, Menu, MenuItem } from '@material-ui/core';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { formatDate } from 'framework/utils/date/formatDate';
import { ITonalAudiogramImageSummary } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface IProps {
	summaries: ITonalAudiogramImageSummary[];
	selectedId: string | undefined;
	setSelectedId: React.Dispatch<React.SetStateAction<string | undefined>>;
	style?: React.CSSProperties;
}

export const TonalImageSelectorButton = ({ summaries, selectedId, setSelectedId, style }: IProps) => {
	const selectedSummary = useMemo(() => (selectedId === undefined ? undefined : summaries.find(t => t.id === selectedId)), [summaries, selectedId]);
	const [anchor, open, close] = useAnchorElement();

	const onSelect = (id: string) => {
		close();
		setSelectedId(id);
	};

	return (
		<>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={close}>
				{summaries.map(t => (
					<MenuItem
						key={t.id}
						value={t.id}
						onClick={() => onSelect(t.id)}>
						{formatDate(t.noahLastModifiedDate)}
					</MenuItem>
				))}
			</Menu>
			<Button
				onClick={open}
				color='primary'
				variant='outlined'
				size='small'
				endIcon={Boolean(anchor) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				style={{
					fontSize: '16px',
					fontWeight: 500,
					letterSpacing: '0.15008px',
					lineHeight: '24px',
					...style,
				}}>
				{formatDate(selectedSummary?.noahLastModifiedDate)}
			</Button>
		</>
	);
};
