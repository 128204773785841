import { CardProps, Divider, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { calcSalesDetailRoute } from 'app/main/financial/routes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { ColorX } from 'framework/components/color/ColorX';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { formatDate } from 'framework/utils/date/formatDate';
import {
	IInvoiceForwardingRecordDetails,
	invoiceForwardingCommand_forward,
	invoiceForwardingCommand_ignore,
	invoiceForwardingCommand_revertIgnore,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { InvoiceForwardingRecordChip } from './InvoiceForwardingRecordChip';

interface IProps extends CardProps, IReloadTriggerProps {
	item: IInvoiceForwardingRecordDetails;
	reload: VoidFunction;
}

export const InvoiceForwardingRecordDetailsCardX = ({ item, reload, reloadTrigger, ...rest }: IProps) => {
	const strings = useLocalization();
	const color = React.useMemo<ColorX>(() => (item.record.isForwarded ? 'success' : item.record.isIgnored ? 'default' : 'error'), [item]);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();
	const theme = useTheme();

	const onIgnore = () => {
		open(
			<AlertDialog
				open
				title={strings.ignoreSale}
				content={strings.doYouWantToIgnoreSaleReferenceQuestion(item.record.reference!)}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => invoiceForwardingCommand_ignore({ salesId: item.record.salesId })}
				accept={confirm}
			/>
		);
	};

	const onRevertIgnore = () => {
		open(
			<AlertDialog
				open
				title={strings.revertIgnoreSale}
				content={strings.doYouWantToRevertIgnoreSaleReferenceQuestion(item.record.reference!)}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => invoiceForwardingCommand_revertIgnore({ salesId: item.record.salesId })}
				accept={confirm}
			/>
		);
	};

	const onForward = () => {
		open(
			<AlertDialog
				open
				title={strings.forward}
				content={strings.doYouWantToForwardInvoiceQuestion}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => invoiceForwardingCommand_forward({ salesId: item.record.salesId })}
				accept={confirm}
			/>
		);
	};

	return (
		<CardX {...rest}>
			<div
				className='df-row-ac gap-8'
				style={{ marginBottom: 16 }}>
				<Typography
					component='div'
					style={{ fontSize: '16px', fontWeight: 500 }}>
					{item.record.reference}
				</Typography>
				<InvoiceForwardingRecordChip
					item={item.record}
					color={color}
					style={{ marginRight: 'auto' }}
				/>
			</div>
			<div className='df-col gap-8'>
				<CaptionVerticalLabelledPropertyX
					label={strings.reference}
					link={() => push(calcSalesDetailRoute(item.record.salesId))}>
					<span>{item.record.reference}</span>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.date}>{formatDate(item.record.created)}</CaptionVerticalLabelledPropertyX>
				<Divider />
				<CaptionVerticalLabelledPropertyX label={strings.validation}>
					<div className='df-col gap-4'>
						{item.validation.canForward && <span style={{ color: theme.palette.success.main }}>{strings.everythingSeemsAllright}</span>}
						{item.validation.hasDocument === false && item.validation.forwardModeIsOnDocumentCreated === true && (
							<span style={{ color: theme.palette.error.main }}>{strings.waitOnDocumentCreation}</span>
						)}
						{item.validation.hasPaymentReference === false && <span style={{ color: theme.palette.error.main }}>{strings.waitOnPaymentReferenceCreation}</span>}
					</div>
				</CaptionVerticalLabelledPropertyX>
				{item.record.isForwarded && (
					<CaptionVerticalLabelledPropertyX label={strings.eMailAddressess}>
						<div className='df-col gap-2'>
							{item.record.forwardedToEmailAddresses.map(t => (
								<React.Fragment key={t}>{t}</React.Fragment>
							))}
						</div>
					</CaptionVerticalLabelledPropertyX>
				)}
				<div className='fg1'></div>
				<Divider style={{ marginBottom: 4 }} />
				<div className='df-col gap-8'>
					<TextLinkButton
						startIcon={<VisibilityOutlinedIcon />}
						onClick={() => push(calcSalesDetailRoute(item.record.salesId))}>
						{strings.viewSale}
					</TextLinkButton>
					<TextLinkButton
						startIcon={<MailOutlineIcon />}
						onClick={onForward}
						disabled={item.record.isForwarded}>
						{strings.forwardViaEmail}
					</TextLinkButton>
					{item.record.isForwarded === false && item.record.isIgnored === false && (
						<TextLinkButton
							startIcon={<DeleteOutlineOutlinedIcon />}
							hoverColor='secondary'
							onClick={onIgnore}>
							{strings.ignoreSaleImperative}
						</TextLinkButton>
					)}
					{item.record.isForwarded === false && item.record.isIgnored === true && (
						<TextLinkButton
							startIcon={<SettingsBackupRestoreOutlinedIcon />}
							color='secondary'
							hoverColor='secondary'
							onClick={onRevertIgnore}>
							{strings.revertIgnoreSale}
						</TextLinkButton>
					)}
				</div>
			</div>
		</CardX>
	);
};
