import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IResponse } from './HamsClient';

export function useHandleClientReponse(): (response: IResponse, successMessage: string | undefined) => void {
	const notify = useSnackbarNotify();
	return (response, successMessage) => {
		if (response.hasError) {
			notify(response.error ?? '', 'error');
		} else if (response.hasWarning) {
			notify(response.warning ?? '', 'warning');
		} else if (response.isSuccess && successMessage !== undefined) {
			notify(successMessage, 'success');
		}
	};
}
