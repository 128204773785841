import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteMultipleFreeSolo } from 'framework/forms/FormAutocompleteMultipleFreeSolo';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IPatient, patientsCommand_updateOrigins } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';
import { PatientsFilterDataContext } from '../PatientsFilterDataContext';

interface IModel {
	origins: string[];
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			origins: yup.mixed(),
		})
		.defined();
};

interface IProps extends DialogProps {
	item: IPatient;
	cancel: VoidFunction;
	confirm: VoidFunction;
}

export const PatientOriginsForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [update, isSubmitting] = useFormSubmit(patientsCommand_updateOrigins);
	const [data] = useCacheContext(PatientsFilterDataContext);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await update(item.id, values.origins);
		if (handleValidateResponse(r, helpers, 'origins')) {
			confirm();
		}
	};

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IModel>
			initialValues={{
				origins: item.origins,
			}}
			onSubmit={handleSubmit}
			validationSchema={createSchema(strings)}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{`${strings.origin} ${strings.patient}`}</DialogTitle>
					<DialogContent dividers>
						<FormAutocompleteMultipleFreeSolo<IModel>
							name='origins'
							label={strings.origin}
							options={data.origins}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
