import { DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectBooleanField } from 'framework/forms/FormSelectBooleanField';
import { FormSelectFieldWithCaption } from 'framework/forms/FormSelectFieldWithCaption';
import { FormSelectFieldWithCaptionFromRecord } from 'framework/forms/FormSelectFieldWithCaptionFromRecord';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	BatteryType,
	HearingAidType,
	IPatchProductCatalogItemHearingAidDataRequest,
	IProductCatalogItem,
	productCatalogCommand_patchHearingAidData,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPatchProductCatalogItemHearingAidDataRequest>({
			type: yup.string().required(),
			batteryType: yup.string().required(),
			hasSeparateEarmould: yup.bool(),
			hasSeparateReceiver: yup.bool(),
			productCatalogItemId: yup.string().required(),
		})
		.defined();
};

const emptyValues: IPatchProductCatalogItemHearingAidDataRequest = {
	type: '',
	batteryType: '',
	hasSeparateEarmould: false,
	hasSeparateReceiver: false,
	productCatalogItemId: undefined as any,
};

const toModel = (item: IProductCatalogItem): IPatchProductCatalogItemHearingAidDataRequest => {
	if (item.hearingAidData !== undefined && item.hearingAidData !== null) {
		return {
			batteryType: item.hearingAidData.batteryType,
			hasSeparateEarmould: item.hearingAidData.hasSeparateEarmould,
			hasSeparateReceiver: item.hearingAidData.hasSeparateReceiver,
			type: item.hearingAidData.type,
			productCatalogItemId: item.id,
		};
	} else {
		return { ...emptyValues, productCatalogItemId: item.id };
	}
};

interface IProps extends DialogProps {
	item: IProductCatalogItem;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const HearingAidDataModelForm = ({ item, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [submit, isSubmitting] = useFormSubmit(productCatalogCommand_patchHearingAidData);

	const handleSubmit = async (values: IPatchProductCatalogItemHearingAidDataRequest, helpers: FormikHelpers<IPatchProductCatalogItemHearingAidDataRequest>) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			// navigate to detail?
			notify(strings.productUpdated);
			confirm();
		}
	};

	return (
		<Formik<IPatchProductCatalogItemHearingAidDataRequest>
			validateOnMount
			initialValues={toModel(item)}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					isSubmitting={isSubmitting}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
}

const InnerDialog = ({ cancel, isSubmitting, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IPatchProductCatalogItemHearingAidDataRequest>();
	const strings = useLocalization();
	const { batteryTypeRecord, batteryTypeCaptionRecord } = useContext(RecordContext);

	return (
		<OverflowDialog {...rest}>
			<DialogTitle>{strings.editWhat(strings.hearingAid)}</DialogTitle>
			<DialogContent
				className='df-col'
				style={{ minWidth: 450 }}>
				<FormSelectFieldWithCaptionFromRecord<IPatchProductCatalogItemHearingAidDataRequest, BatteryType>
					name='batteryType'
					label={strings.batteryType}
					record={batteryTypeRecord}
					captionRecord={batteryTypeCaptionRecord}
				/>
				<FormSelectFieldWithCaption<IPatchProductCatalogItemHearingAidDataRequest, HearingAidType>
					name='type'
					label={strings.type}
					options={[
						{ value: 'BTE', label: `${strings.behindTheEar} (BTE)`, caption: strings.bteCaptionTypes },
						{ value: 'ITE', label: `${strings.inTheEar} (ITE)`, caption: strings.iteCaptionTypes },
					]}
					getKey={t => t}
				/>
				{props.values.type === 'BTE' && (
					<>
						<DividerWithCaption
							caption={`${strings.properties} (BTE)`}
							style={{ marginTop: 16, marginBottom: 16 }}
						/>
						<FormSelectBooleanField<IPatchProductCatalogItemHearingAidDataRequest>
							label={strings.earmould}
							name='hasSeparateEarmould'
							trueText={strings.withSeparateEarmouldCaption}
							falseText={strings.withoutSeparateEarmouldCaption}
						/>
						<FormSelectBooleanField<IPatchProductCatalogItemHearingAidDataRequest>
							label={strings.receiver}
							name='hasSeparateReceiver'
							trueText={strings.withSeparateReceiverCaption}
							falseText={strings.withoutSeparateReceiverCaption}
						/>
					</>
				)}
			</DialogContent>
			<CancelSubmitFormDialogActions
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.update}
			/>
		</OverflowDialog>
	);
};
