import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { DashboardNotificationLevel, IDashboardNotification } from 'gen/ApiClient';
import React, { useMemo } from 'react';

interface IProps {
	notification: IDashboardNotification;
	onClick: VoidFunction;
}

export const SingleNotification = ({ notification, onClick }: IProps) => {
	const level = useMemo(() => notification.level as DashboardNotificationLevel, [notification]);

	if (level === 'Error') {
		return (
			<ErrorLabelledProperty
				error={notification.message ?? ''}
				onClick={onClick}
			/>
		);
	} else {
		return (
			<WarningLabelledProperty
				warning={notification.message ?? ''}
				onClick={onClick}
			/>
		);
	}
};
