import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { useLocalization } from 'localization/useLocalization';
import { VatPercentage, VatPercentages } from './VatPercentages';

const record: Record<VatPercentage, string> = {
	0: `0%`,
	6: `6%`,
	21: `21%`,
};

const orderRecord: Record<VatPercentage, number> = {
	0: 0,
	6: 1,
	21: 2,
};

interface IProps {
	selected: VatPercentage[];
	setSelected: (selected: VatPercentage[]) => void;
}

export const VatPercentagesListFilterItem = ({ selected, setSelected }: IProps) => {
	const strings = useLocalization();

	return (
		<RecordListFilterComponent<VatPercentage>
			record={record}
			options={VatPercentages}
			selected={selected}
			setSelected={setSelected}
			icon={<HelpOutlineIcon />}
			label={strings.vatPercentage}
			orderRecord={orderRecord}
		/>
	);
};
