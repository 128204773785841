import React from 'react';
import emptyEn from './empty-en.png';

interface IProps {
	imageData: string | undefined;
	style?: React.CSSProperties;
}

export const AudiogramImageOrEmpty = ({ imageData, style }: IProps) => {
	return (
		<>
			{imageData !== undefined && (
				<img
					src={`data:image/png;base64,${imageData}`}
					alt='latest_audiogram'
					style={{ ...style }}
				/>
			)}
			{imageData === undefined && (
				<img
					src={emptyEn}
					alt='audiogram...'
					style={{ ...style }}
				/>
			)}
		</>
	);
};
