import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ICommissionPayout } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IPaymentLinesTableProps {
	item: ICommissionPayout;
	style?: React.CSSProperties;
}

export const CommissionLinesTable = ({ item, style }: IPaymentLinesTableProps) => {
	const strings = useLocalization();

	return (
		<Table style={style}>
			{/* <colgroup>
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
                <col style={{ width: 'auto' }} />
            </colgroup> */}
			<TableHead>
				<TableRow>
					<TableCell>{strings.salesDate}</TableCell>
					<TableCell>{`${strings.referenceShort} ${strings.sale}`}</TableCell>
					<TableCell>{strings.withRespectToShort}</TableCell>
					<TableCell>{`${strings.rule} (%)`}</TableCell>
					<TableCell align='right'>{strings.amountAsValue}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{item.lines.map(t => (
					<TableRow key={t.commissionId}>
						<TableCell>{formatDate(t.sale.date)}</TableCell>
						<TableCell>{t.salesReference}</TableCell>
						<TableCell>{t.sale.recipientName}</TableCell>
						<TableCell>{`${t.commissionRule?.name} (${t.commissionRule?.percentage} %)`}</TableCell>
						<TableCell align='right'>{formatCurrency(t.value)}</TableCell>
					</TableRow>
				))}
				<TableRow>
					<TableCell
						colSpan={3}
						style={{ borderBottom: 'none' }}></TableCell>
					<TableCell style={{ fontWeight: 'bold' }}>{strings.total}</TableCell>
					<TableCell
						colSpan={5}
						align='right'
						style={{ fontWeight: 'bold' }}>
						{formatCurrency(item.value)}
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};
