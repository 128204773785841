import { calcInventoryItemDetailRoute } from 'app/main/inventoryManagement/routes';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IProductCatalogItem, ITypesAndCategoriesProductCatalogQueryParams, productCatalogQuery_queryUniquelyIdentifiable } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultTypesAndCategoriesProductCatalogQueryParams } from '../../productCatalog/search/DefaultTypesAndCategoriesProductCatalogQueryParams';
import { SearchProductCatalogDialog } from '../../productCatalog/search/SearchProductCatalogDialog';
import { InventoryItemModelForm } from './InventoryItemModelForm';

interface IProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	productCatalogItemId?: string;
	goToDetails?: boolean;
}

export const InventoryItemForm = ({ cancel, confirm, productCatalogItemId, goToDetails = false }: IProps) => {
	const [pcId, setPcId] = useState<string | undefined>(productCatalogItemId);
	const [hasConfirmedWarning, setHasConfirmedWarning] = useState<boolean>(false);
	const { push } = useHistory();
	const strings = useLocalization();

	const onConfirmProductCatalogItem = (pc: IProductCatalogItem) => {
		setPcId(pc.id);
	};

	const onConfirmNew = (id: string) => {
		if (goToDetails === true) {
			push(calcInventoryItemDetailRoute(id));
		}
		confirm(id);
	};

	if (hasConfirmedWarning === false) {
		return (
			<AlertDialog
				open
				title={strings.warningOnlyRegistrationOfSerialNumber}
				content={<div className='df-col'>{strings.warningOnlyRegistrationOfSerialNumberCaption}</div>}
				rejectText={strings.goBack}
				acceptText={strings.continue}
				accept={() => setHasConfirmedWarning(true)}
				reject={cancel}
			/>
		);
	} else if (pcId === undefined) {
		return (
			<SearchProductCatalogDialog<ITypesAndCategoriesProductCatalogQueryParams>
				open
				filterType='stock'
				confirm={onConfirmProductCatalogItem}
				cancel={cancel}
				defaultParams={DefaultTypesAndCategoriesProductCatalogQueryParams}
				queryF={productCatalogQuery_queryUniquelyIdentifiable}
			/>
		);
	} else {
		return (
			<InventoryItemModelForm
				open
				productCatalogItemId={pcId}
				cancel={cancel}
				confirm={onConfirmNew}
				onPrevious={() => setPcId(undefined)}
			/>
		);
	}
};
