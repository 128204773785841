import { Divider } from '@material-ui/core';
import { startOfDay } from 'date-fns';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { isLast } from 'framework/utils/array/isLast';
import { formatDateX } from 'framework/utils/date/formatDateX';
import { ICalendarEventDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useFormatDateXLocalized } from 'shared/date/useFormatDateXLocalized';
import { getNow } from 'shared/utils/getNow';
import { AgendaColorScheme } from '../AgendaColorScheme';
import { IEventGroup } from '../IEventGroup';
import { EventListItemComponent } from './EventListItemComponent';
import { filterAndGroupEvents } from './filterAndGroupEvents';
import { spreadAndFilterRecurringEvents } from './spreadAndFilterRecurringEvents';

interface IProps {
	events: ICalendarEventDto[];
	afterDate: Date;
	beforeDate: Date;
	reload: VoidFunction;
	colorScheme: AgendaColorScheme;
	hideTitleAndRoom?: boolean;
	noRecordsWithoutPadding?: boolean;
}

export const EventListComponent = ({ events, afterDate, beforeDate, reload, colorScheme, hideTitleAndRoom, noRecordsWithoutPadding }: IProps) => {
	const strings = useLocalization();
	console.log(afterDate);
	console.log(beforeDate);
	const sfEvents = useMemo(() => spreadAndFilterRecurringEvents(events, startOfDay(afterDate), beforeDate), [afterDate, events, beforeDate]);
	const grouped = useMemo(() => filterAndGroupEvents(sfEvents, afterDate), [afterDate, sfEvents]);

	return (
		<>
			{grouped.map(group => (
				<React.Fragment key={formatDateX(group.date)}>
					<EventListGroupedByDayComponent
						group={group}
						reload={reload}
						colorScheme={colorScheme}
						hideTitleAndRoom={hideTitleAndRoom}
					/>
					{isLast(group, grouped) === false && <Divider />}
				</React.Fragment>
			))}
			{grouped.length === 0 && <NoRecordsFound withPadding={noRecordsWithoutPadding === true ? false : true}>{strings.noAppointmentsFound}</NoRecordsFound>}
		</>
	);
};

interface IEventListGroupedByDayComponentProps {
	group: IEventGroup;
	reload: VoidFunction;
	colorScheme: AgendaColorScheme;
	hideTitleAndRoom?: boolean;
}

const EventListGroupedByDayComponent = ({ group, reload, colorScheme, hideTitleAndRoom }: IEventListGroupedByDayComponentProps) => {
	const isToday = useMemo(() => formatDateX(group.date) === formatDateX(getNow()), [group]);
	const formatX = useFormatDateXLocalized();

	return (
		<div
			className='df-row w100'
			style={{ marginTop: 8, paddingBottom: 8 }}>
			<div
				className='df-row-ab'
				style={{ width: 150 }}>
				<div
					className='df-col-ac jc-c'
					style={{
						fontSize: '18px',
						letterSpacing: '-1.8px',
						textIndent: '-1.8px',
						width: '30px',
						height: '30px',
						textAlign: 'center',
						backgroundColor: isToday ? '#1a73e8' : 'inherit',
						borderRadius: '100%',
						marginRight: 8,
						color: isToday ? 'white' : 'inherit',
					}}>
					{formatDateX(group.date, 'd')}
				</div>
				<div
					style={{
						fontSize: '11px',
						letterSpacing: '0.8px',
						textAlign: 'left',
						textTransform: 'uppercase',
						color: isToday ? '#1a73e8' : '#70757a',
					}}>
					{formatX(group.date, 'LLL, E')}
				</div>
			</div>
			<div
				className='df-col fg1'
				style={{ paddingRight: 8 }}>
				{group.events.map(event => (
					<EventListItemComponent
						event={event}
						reload={reload}
						colorScheme={colorScheme}
						key={`${event.id}-${event.startDate}`}
						hideTitleAndRoom={hideTitleAndRoom}
					/>
				))}
			</div>
		</div>
	);
};
