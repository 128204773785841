import React from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

interface IProps {
	title: string;
	children: any;
	isExpanded: boolean;
	toggle: VoidFunction;
}

export const AccordionX = ({ children, title, isExpanded, toggle }: IProps) => {
	return (
		<Accordion
			expanded={isExpanded}
			onChange={toggle}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<span style={{ fontWeight: 500 }}>{title}</span>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};
