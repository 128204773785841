import { Button, ListItem, PropTypes } from '@material-ui/core';
import React from 'react';

const CommonNavLinkStyle: React.CSSProperties = {
	justifyContent: 'flex-start',
	textTransform: 'none',
	letterSpacing: 0,
	paddingRight: '12px',
	textAlign: 'left',
	whiteSpace: 'pre-line',
};

export const commonNavLinkStyle: React.CSSProperties = {
	justifyContent: 'flex-start',
	textTransform: 'none',
	letterSpacing: 0,
	fontWeight: 400,
};

interface IProps {
	buttonStyle?: React.CSSProperties;
	style?: React.CSSProperties;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	disabled?: boolean;
	buttonDisabled?: boolean;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
	color?: PropTypes.Color;
	children: any;
}

export const NavigationLinkListItemButton = ({ buttonStyle, style, onClick, disabled, buttonDisabled, startIcon, endIcon, color, children }: IProps) => {
	return (
		<ListItem
			disableGutters
			component='li'
			onClick={onClick}
			disabled={disabled}
			style={style}>
			<Button
				fullWidth
				variant='text'
				style={{
					...CommonNavLinkStyle,
					...buttonStyle,
				}}
				disabled={buttonDisabled}
				startIcon={startIcon}
				endIcon={endIcon}
				color={color}>
				{children}
			</Button>
		</ListItem>
	);
};
