import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { TypographyBoldCaption } from 'framework/components/typography/TypographyBoldCaption';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { IPurchaseFromUblFormLine } from './IPurchaseFromUblFormLine';
import { PurchaseUblLinkPopoverActions } from './PurchaseUblLinkPopoverActions';
import { PurchaseUblLinkStatus } from './UblPurchaseLineLinkStatus';

interface IProps {
	onRemoveLink: VoidFunction;
	onUndoIgnore: VoidFunction;
	onLink: VoidFunction;
	onIgnore: VoidFunction;
	linkStatus: PurchaseUblLinkStatus;
	line: IPurchaseFromUblFormLine;
}

export const PurchaseFromUblLineLinkStatusPopoverContent = ({ line, onLink, onIgnore, onRemoveLink, onUndoIgnore, linkStatus }: IProps) => {
	const strings = useLocalization();

	return (
		<div
			className='df-col gap-8'
			style={{ padding: 10 }}>
			<CaptionVerticalLabelledPropertyX label={strings.referenceFromSupplier}>{line.ublData!.manufacturerReference}</CaptionVerticalLabelledPropertyX>
			{line.productCatalogItem !== undefined && line.productCatalogItem !== null && (
				<CaptionVerticalLabelledPropertyX label={strings.nameArticleFromProductCatalog}>{line.productCatalogItem.identifier}</CaptionVerticalLabelledPropertyX>
			)}
			<div className='df-col'>
				<TypographyBoldCaption>{strings.actions}</TypographyBoldCaption>
				<PurchaseUblLinkPopoverActions
					onLink={onLink}
					onIgnore={onIgnore}
					onRemoveLink={onRemoveLink}
					onUndoIgnore={onUndoIgnore}
					linkStatus={linkStatus}
				/>
			</div>
		</div>
	);
};
