import { IProductCatalogItem, IStockAtLocation } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const productCatalogItemToLine = (pc: IProductCatalogItem, stockAtLocations: IStockAtLocation[]): ILine => {
	return {
		isSelected: true,
		id: pc.id,
		description: pc.identifier,
		inventoryItemId: undefined,
		productCatalogItemId: pc.id,
		quantity: 1,
		unitListPrice: pc.salesListPrice,
		unitPrice: pc.salesListPrice,
		productCatalogItem: pc,
		vatPercentage: pc.vatPercentage,
		stockAtLocations: stockAtLocations,
		inventoryItem: undefined,
	};
};
