import { IStrings } from 'localization/IStrings';
import { OrderExportProp } from 'gen/ApiClient';

export const createOrderExportRecord = (strings: IStrings): Record<OrderExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		CountItems: `# ${strings.items}`,
		ExpectedDeliveryDate: strings.expectedDeliveryDate,
		ExternalReference: strings.shortExternalReference,
		LocationIdentifier: strings.location,
		Reference: strings.reference,
		Status: strings.status,
		SupplierIdentifier: strings.supplier,
		PatientIdentifiersCsv: strings.patients,
		SinglePatientIdentifier: strings.singlePatient,
	};
};
