import { IRegisterOrderLineRequest } from 'gen/ApiClient';
import { IOrderLineModel } from './IOrderLineModel';

export const mapOrderLineModelToRegisterOrderLineRequest = (line: IOrderLineModel, zeroBasedIndex: number): IRegisterOrderLineRequest => {
	return {
		description: line.description,
		orientation: line.orientation,
		patientId: line.patientId,
		productCatalogItemId: line.productCatalogItemId,
		quantity: line.quantity,
		articleProperties: line.articleProperties,
		zeroBasedIndex: zeroBasedIndex,
	};
};
