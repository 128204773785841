import { Divider } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { HamsClientApplicationContext } from 'app/externalApps/client/HamsClientApplicationContext';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import React, { useContext } from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { HamsClientDetails } from './HamsClientDetails';
import { NoahLicenseDetails } from './NoahLicenseDetails';

export const HamsClientContent = ({ reloadTrigger }: IReloadTriggerProps) => {
	const { isInstalled, isInitialized, installedBuild, latestBuild, hasLatest, check, download, isDownloading } = useContext(HamsClientApplicationContext);

	useLazyEffect(() => {
		check();
	}, [reloadTrigger]);

	return (
		<div className='df-col gap-8'>
			<TextLinkButton
				startIcon={<GetAppOutlinedIcon />}
				disabled={isDownloading}
				color='primary'
				onClick={download}>
				{`Download`}
			</TextLinkButton>
			<CaptionVerticalLabelledPropertyX label='Latest version from server'>
				{latestBuild ? latestBuild.toString() : 'Not loaded yet'}
			</CaptionVerticalLabelledPropertyX>
			{isInitialized && isInstalled === false && <VariantLabelledPropertyX variant='error'>{'HAMS Client not installed'}</VariantLabelledPropertyX>}
			{isInitialized && isInstalled && (
				<>
					<CaptionVerticalLabelledPropertyX label='Installed version'>{installedBuild.toString()}</CaptionVerticalLabelledPropertyX>
					<VariantLabelledPropertyX variant={hasLatest ? 'success' : 'warning'}>
						{hasLatest ? 'up-to-date' : 'not running latest version'}
					</VariantLabelledPropertyX>
					<Divider />
					{installedBuild >= 58 && <HamsClientDetails reloadTrigger={reloadTrigger} />}
				</>
			)}
			{isInitialized === false && <span>{`Fetching data ophalen`}</span>}
			<NoahLicenseDetails />
		</div>
	);
};
