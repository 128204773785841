import { RosaIntegrationSelectedTabKey } from 'app/localStorageKeys';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useLocalStorage } from 'framework/hooks/useLocalStorage';
import { useTrigger } from 'framework/hooks/useTrigger';
import { rosaIntegrationQuery_isEnabled, rosaIntegrationQuery_status } from 'gen/ApiClient';
import React, { useEffect } from 'react';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { RosaIntegrationPageHeader } from './RosaIntegrationPageHeader';
import { RosaIntegrationTabType } from './RosaIntegrationTabType';
import { RosaPatientRecordsListTab } from './patients/RosaPatientRecordsListTab';
import { RosaSettingsTab } from './settings/RosaSettingsTab';
import { RosaUsersTab } from './users/RosaUsersTab';

export const RosaIntegrationPage = () => {
	const [trigger, hitTrigger] = useTrigger();
	const [selectedTab, setSelectedTab] = useLocalStorage<RosaIntegrationTabType>(RosaIntegrationSelectedTabKey, 'settings');
	const [data, reloadData] = useApiEffect(rosaIntegrationQuery_status);
	const [isEnabled, reloadIsEnabled] = useApiEffect(rosaIntegrationQuery_isEnabled);

	useEffect(() => {
		if (isEnabled === false && selectedTab !== 'settings') {
			setSelectedTab('settings');
		}
		// eslint-disable-next-line
	}, [isEnabled, selectedTab]);

	useLazyEffect(() => {
		reloadData();
		reloadIsEnabled();
	}, [trigger]);

	if (data === undefined || isEnabled === undefined) {
		return <></>;
	}

	return (
		<RestrictedPageX
			pageClaim='RosaIntegration'
			renderRaw
			stickyHeader={
				<RosaIntegrationPageHeader
					tab={selectedTab}
					setTab={setSelectedTab}
					data={data}
					reload={hitTrigger}
					isRosaIntegrationEnabled={isEnabled}
				/>
			}>
			{selectedTab === 'patients' && (
				<RosaPatientRecordsListTab
					reloadTrigger={trigger}
					reload={hitTrigger}
				/>
			)}
			{selectedTab === 'users' && <RosaUsersTab reloadTrigger={trigger} />}
			{selectedTab === 'settings' && (
				<RosaSettingsTab
					reloadTrigger={trigger}
					data={data}
					reload={hitTrigger}
				/>
			)}
		</RestrictedPageX>
	);
};
