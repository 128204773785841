import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { IProductCatalogQueryParamsForOrder, productCatalogQuery_queryForOrder } from 'gen/ApiClient';
import React from 'react';
import { SearchProductCatalogItemsForOrderContext } from './SearchProductCatalogItemsForOrderContext';

const DefaultParams: IProductCatalogQueryParamsForOrder = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	categories: [],
	subcategories: [],
	types: [],
};

interface IProps {
	children: any;
}

export const SearchProductCatalogItemsForOrderProvider = ({ children }: IProps) => {
	const [params, setParams, queryResult] = useApiEffectWithParams(productCatalogQuery_queryForOrder, DefaultParams);

	return (
		<SearchProductCatalogItemsForOrderContext.Provider
			value={{
				params: params,
				queryResult: queryResult,
				setParams: setParams,
			}}>
			{children}
		</SearchProductCatalogItemsForOrderContext.Provider>
	);
};
