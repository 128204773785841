import React from 'react';
import MaskedInput from 'react-text-mask';
import { TextMaskCustomProps } from './TextMaskCustomProps';

export function TextMaskCustom(props: TextMaskCustomProps) {
	const { inputRef, mask, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref: any) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={mask}
			guide
			// showMask
		/>
	);
}
