import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { IPurchase } from 'gen/ApiClient';
import React from 'react';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { FinancialsPageBreadcrumbTitle } from '../../FinancialsPageBreadcrumbTitle';
import { PurchasesSettingsRoute } from '../../routes';
import { PurchaseChips } from '../PurchaseChips';
import { PurchasesPageBreadcrumb } from '../PurchasesPageBreadcrumb';
import { PurchaseDetailPageViewContext } from './PurchaseDetailPageViewContext';
import { PurchaseDetailTabType, PurchaseDetailTabTypes } from './PurchaseDetailTabType';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

interface IProps {
	item: IPurchase;
	reload: VoidFunction;
	tab: PurchaseDetailTabType;
	setTab: React.Dispatch<React.SetStateAction<PurchaseDetailTabType>>;
}

export const PurchaseDetailPageHeader = ({ item, reload, tab, setTab }: IProps) => {
	const { TabTypeRecord } = React.useContext(PurchaseDetailPageViewContext);

	return (
		<div className='df-col fg1'>
			<div className='df-row-ac'>
				<FinancialsPageBreadcrumbTitle />
				<PageBreadcrumbDivider />
				<PurchasesPageBreadcrumb />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={item.reference} />
				<PurchaseChips
					item={item}
					style={{ marginLeft: 4 }}
				/>
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageSettingsButtonX route={PurchasesSettingsRoute} />
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<HorizontalTabsX
				tabs={PurchaseDetailTabTypes}
				selectedTab={tab}
				setSelectedTab={setTab}
				titleRecord={TabTypeRecord}
				style={{
					marginTop: 16,
					marginLeft: -4,
				}}
			/>
		</div>
	);
};
