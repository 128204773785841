import { CardProps, useTheme } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import WebOutlinedIcon from '@material-ui/icons/WebOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { IExactOnlineSettingsDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';

interface IProps extends IReloadTriggerProps, CardProps {
	settings: IExactOnlineSettingsDto;
	reload: VoidFunction;
	onRegister: VoidFunction;
	onActivate: VoidFunction;
	onDeactivate: VoidFunction;
}

export const ExactOnlineUserSettingsCardX = ({ settings, reloadTrigger, reload, onRegister, onActivate, onDeactivate, ...rest }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();

	return (
		<CardX
			{...rest}
			className='df-col gap-8'>
			<Typography16pxBold>{strings.generalSettings}</Typography16pxBold>
			<LabelledPropertyX
				iconPosition='start'
				icon={<StatusIcon status={settings.hasOAuthOptions ? 'success' : 'error'} />}>
				{settings.hasOAuthOptions ? (
					strings.exactOnlineActivatedForThisEnvironment
				) : (
					<em style={{ color: theme.palette.error.main }}>{strings.exactOnlineNotActivatedNotification}</em>
				)}
			</LabelledPropertyX>
			{settings.hasOAuthOptions === true && (
				<>
					<LabelledPropertyX
						label={strings.registration}
						iconPosition='start'
						icon={<StatusIcon status={settings.hasOAuthToken ? 'success' : 'error'} />}>
						{settings.hasOAuthToken ? strings.appRegistered : <em>{strings.appNotYetRegistered}</em>}
					</LabelledPropertyX>
					{settings.hasOAuthToken === false && (
						<TextLinkButton
							startIcon={<WebOutlinedIcon />}
							onClick={onRegister}
							color='primary'>
							{strings.register}
						</TextLinkButton>
					)}
					{settings.hasOAuthToken === true && (
						<>
							{settings.isEnabled === false && (
								<LabelledPropertyX
									iconPosition='start'
									icon={<StatusIcon status={'error'} />}>
									<em>{strings.manuallyDeactivated}</em>
								</LabelledPropertyX>
							)}
							<TextLinkButton
								startIcon={<CheckBoxOutlinedIcon />}
								onClick={onActivate}
								color='primary'
								disabled={settings.isEnabled}>
								{strings.activateVerb}
							</TextLinkButton>
							<TextLinkButton
								startIcon={<HighlightOffOutlinedIcon />}
								onClick={onDeactivate}
								color='secondary'
								hoverColor='secondary'
								disabled={settings.isEnabled === false}>
								{strings.deactivateVerb}
							</TextLinkButton>
						</>
					)}
				</>
			)}
			{settings.canPushPull && (
				<LabelledPropertyX
					label={strings.connectionStatus}
					iconPosition='start'
					icon={<StatusIcon status={settings.canConnect ? 'success' : 'error'} />}>
					{settings.canConnect ? strings.successful : strings.errorViewLogs}
				</LabelledPropertyX>
			)}
		</CardX>
	);
};
