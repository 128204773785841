import { FilledInput } from '@material-ui/core';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { TextMaskCustom } from '../components/mask/TextMaskCustom';
import { useStateBoolean } from '../hooks/useStateBool';
import { nameOf } from '../utils/nameOf';
import { ClearEndAdornment } from './common/ClearEndAdornment';
import { FormFilledControl } from './common/FormFilledControl';
import { IFormProps } from './common/IFormProps';
import { useFilledInputStyles } from './common/useFilledInputStyles';

interface IProps<TModel> extends IFormProps<TModel> {
	mask: (string | RegExp)[];
}

export const FormMaskedTextField = <TModel extends unknown>({ mask, ...props }: IProps<TModel>) => {
	const [field, meta, helpers] = useField<string>(nameOf(props.name));
	const [isHovered, enter, leave] = useStateBoolean(false);
	const classes = useFilledInputStyles();
	const [innerValue, setInnerValue] = useState<string>(field.value);

	useEffect(() => {
		setInnerValue(field.value);
	}, [field.value]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInnerValue(e.target.value);
		if (e.target.value.indexOf('_') === -1) {
			helpers.setValue(e.target.value);
		}
	};

	return (
		<FormFilledControl
			meta={meta}
			enter={enter}
			leave={leave}
			{...props}>
			<FilledInput
				{...field}
				value={innerValue}
				onChange={handleChange}
				className={classes.filledInput}
				endAdornment={
					<ClearEndAdornment
						isHovered={isHovered}
						field={field}
						helpers={helpers}
						disabled={props.disabled ?? false}
						defaultValue={''}
					/>
				}
				inputProps={{ mask: mask }}
				inputComponent={TextMaskCustom as any}
			/>
		</FormFilledControl>
	);
};
