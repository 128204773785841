import { Typography, useTheme } from '@material-ui/core';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { IObjectWithIdAndIdentifier } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { useLocalizedHealthInsuranceFundsCache } from 'shared/context/useLocalizedHealthInsuranceFundsCache';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { HifDetailDialog } from '../../contacts/hifs/HifDetailDialog';

interface IProps {
	code: string | undefined;
}

export const HealthInsuranceFundDisplay = ({ code }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const data = useLocalizedHealthInsuranceFundsCache();
	const [hif, setHif] = useState<IObjectWithIdAndIdentifier<string> | undefined>(undefined);
	const { open, confirm } = useDialogsContext();

	useEffect(() => {
		setHif(data?.find(t => t.id === code));
	}, [data, code]);

	if (data === undefined) {
		return <div></div>;
	}

	const onViewDetail = (code: string) => {
		open(
			<HifDetailDialog
				open
				code={code}
				close={confirm}
			/>
		);
	};

	if (code && hif) {
		return (
			<div
				style={{ cursor: 'pointer' }}
				onClick={() => onViewDetail(code)}>
				<Typography style={{ fontSize: '14px', color: theme.palette.text.secondary, fontWeight: 'bold' }}>{code}</Typography>
				<Typography style={{ fontSize: '12px', color: theme.palette.text.secondary }}>{hif.identifier}</Typography>
			</div>
		);
	} else if (code && hif === undefined) {
		return <WarningLabelledProperty warning={`${code}\n${strings.thisHifDoesNotExistAnymore}`} />;
	} else {
		return <NoRecordsFound>{strings.noLinkWithHealthInsuranceFund}</NoRecordsFound>;
	}
};
