import { IDraggableItem } from 'framework/components/dnd/IDraggableItem';
import React from 'react';
import { LineDescriptionAndQuantity } from 'shared/searchAndSelect/lines/LineDescriptionAndQuantity';
import { LineXComponent } from 'shared/searchAndSelect/lines/LineXComponent';
import { ILine } from './ILine';

interface IProps extends IDraggableItem {
	line: ILine;
	index: number;
	setLine: (val: ILine) => void;
	onDelete: VoidFunction;
}

export const ReturnLineComponent = ({ line, setLine, ...rest }: IProps) => {
	return (
		<LineXComponent
			{...rest}
			line={line}
			setLine={setLine}
			errors={line.error}>
			<LineDescriptionAndQuantity<ILine>
				line={line}
				setLine={setLine}
				disableQuantity={line.productCatalogItem.isUniquelyIdentifiable}
			/>
		</LineXComponent>
	);
};
