import { useTheme } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { formatDate } from 'framework/utils/date/formatDate';
import { ICompany } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { CompanyListItemStatusIcon } from './CompanyListItemStatusIcon';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: ICompany;
	isSelected: boolean;
	toggle: VoidFunction;
	style?: React.CSSProperties;
}

export const CompanyListItemCardX = ({ item, isSelected, toggle, style }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const tColor = useMemo(() => theme.palette.success.main, [theme]);
	const [hovered, enter, leave] = useStateBoolean(false);

	return (
		<CardX
			raised={isSelected || hovered}
			className='df-row w100'
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={toggle}
			style={{
				borderRight: isSelected ? `6px solid ${theme.palette.primary.main}` : 'unset',
				cursor: 'pointer',
				...style,
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<CompanyListItemStatusIcon style={{ fontSize: 22, color: tColor }} />
			</div>
			<div className='df-col fg1'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.identifier}</span>
				<span style={{ fontSize: MinorFontSize }}>{`${strings.created}: ${formatDate(item.created)}`}</span>
				{item.vatNumber && <span style={{ fontSize: MinorFontSize }}>{`${strings.vatNumber}: ${item.vatNumber}`}</span>}
			</div>
		</CardX>
	);
};
