import { Dialog, DialogContent, DialogProps, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Form, Formik, FormikHelpers } from 'formik';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponseWithLines } from 'framework/forms/utils/handleFormResponseWithLines';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import {
	IArticlePropertyDefinition,
	IArticlePropertyDefinitionModel,
	IPatchProductCatalogItemArticlePropertyDefinitionsRequest,
	IArticlePropertyKey,
	IProductCatalogItem,
	productCatalogCommand_patchArticlePropertyDefinitions,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { LineErrorComponent } from 'shared/searchAndSelect/LineErrorComponent';
import * as yup from 'yup';
import { ArticlePropertyKeysCacheContext } from '../../articlePropertyKeys/context/ArticlePropertyKeysCacheContext';
import { ArticlePropertyKeyModelForm } from './ArticlePropertyKeyModelForm';
import { ILine } from './ILine';
import { OtherProductCatalogItems } from './OtherProductCatalogItems';
import { createHeaders } from './utils/createHeaders';

const toLines = (lines: ILine[], apks: IArticlePropertyKey[], apds: IArticlePropertyDefinition[]): ILine[] => {
	console.log('calculate lines');
	return apks.map<ILine>(t => {
		const fApd = apds !== undefined && apds !== null ? apds.find(x => x.key === t.key) : undefined;
		const fLine = lines.find(x => x.apk.key === t.key);
		const isNewLine = lines.length > 0 && fLine === undefined;
		if (fLine) {
			return {
				apk: t,
				allowMultiple: fLine.allowMultiple,
				isSelected: fLine.isSelected,
				options: fLine.options,
			};
		} else {
			return {
				apk: t,
				allowMultiple: fApd?.allowMultiple ?? false,
				isSelected: fApd !== undefined ? true : isNewLine,
				options: fApd?.options ?? [],
			};
		}
	});
};

const toModel = (line: ILine): IArticlePropertyDefinitionModel => {
	return {
		allowMultiple: line.allowMultiple,
		options: line.options,
		key: line.apk.key,
	};
};

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPatchProductCatalogItemArticlePropertyDefinitionsRequest>({
			lines: yup.mixed(),
			alsoApplyOnIds: yup.mixed(),
			productCatalogItemId: yup.string().required(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IPatchProductCatalogItemArticlePropertyDefinitionsRequest)[]> = {
	0: ['lines'],
	1: ['alsoApplyOnIds'],
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	item: IProductCatalogItem;
}

export const ArticlePropertyDefinitionsForm = ({ confirm, cancel, item, ...rest }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const notify = useSnackbarNotify();
	const [submit, isSubmitting] = useFormSubmit(productCatalogCommand_patchArticlePropertyDefinitions);
	const [data, load] = usePreloadCacheContext(ArticlePropertyKeysCacheContext);
	const [lines, setLines] = useState<ILine[]>([]);
	const { open, confirm: confirmNew, cancel: cancelNew, isStacked } = useDialogsContext(load);
	const language = useCurrentLanguage();
	const headers = useMemo(() => createHeaders(lines, setLines, strings, language), [lines, setLines, strings, language]);
	const [step, setStep] = useState<number>(0);
	const [alsoApplyOnOthers, setAlsoApplyOnOthers] = useState<boolean>(false);
	const [otherIds, setOtherIds] = useState<string[]>([]);

	useEffect(() => {
		setLines(lines => toLines(lines, data, item.articlePropertyDefinitions));
	}, [data, item.articlePropertyDefinitions]);

	const handleSubmit = async (
		values: IPatchProductCatalogItemArticlePropertyDefinitionsRequest,
		helpers: FormikHelpers<IPatchProductCatalogItemArticlePropertyDefinitionsRequest>
	) => {
		const r = await submit({
			productCatalogItemId: values.productCatalogItemId,
			lines: lines.filter(t => t.isSelected).map(toModel),
			alsoApplyOnIds: alsoApplyOnOthers ? otherIds : [],
		});
		if (handleFormResponseWithLines(r, helpers, undefined as any, undefined as any, 'lines', lines, setLines)) {
			notify(strings.changedWhat(strings.fieldsToFill));
			confirm();
		}
	};

	const onNewKey = () => {
		open(
			<ArticlePropertyKeyModelForm
				open
				cancel={cancelNew}
				confirm={confirmNew}
			/>
		);
	};

	return (
		<Formik<IPatchProductCatalogItemArticlePropertyDefinitionsRequest>
			validateOnMount
			initialValues={{
				lines: [],
				alsoApplyOnIds: [],
				productCatalogItemId: item.id,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xl'
					open={rest.open && isStacked === false}>
					<DialogTitleWithFormStepper
						title={`${strings.fieldsToFill} | ${item.identifier}`}
						step={step}
						labels={[strings.properties, strings.applyOn]}
					/>
					<DialogContent
						dividers
						style={{ minWidth: 500, padding: step === 0 ? 0 : '8px 24px' }}
						className='df-col'>
						{step === 0 && (
							<TableWithHeadersAndValues<ILine>
								padding={theme.spacing(1)}
								headers={headers}
								getKey={t => t.apk.key!}
								values={lines}
								expandableContent={t => (
									<LineErrorComponent
										line={t}
										style={{ marginLeft: '10px' }}
									/>
								)}
								expandAll={true}
							/>
						)}
						{step === 1 && (
							<>
								<ExtendedRadioGroup<boolean>
									selected={alsoApplyOnOthers}
									setSelected={setAlsoApplyOnOthers}
									options={[
										{ value: false, label: strings.onlyForThisArticle, caption: strings.onlyForWhat(item.identifier) },
										{ value: true, label: strings.forMultipleArticles, caption: strings.searchOtherArticlesBelow },
									]}
								/>
								{alsoApplyOnOthers && (
									<>
										<DividerWithCaption caption={strings.otherArticles} />
										<OtherProductCatalogItems
											item={item}
											setIds={setOtherIds}
										/>
									</>
								)}
							</>
						)}
					</DialogContent>
					<PageableFormDialogActions
						leftContent={
							step === 0 ? (
								<SmallPrimaryTextButton
									startIcon={<AddIcon />}
									onClick={onNewKey}>
									{strings.newFieldToFill}
								</SmallPrimaryTextButton>
							) : undefined
						}
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
