import { DialogProps } from '@material-ui/core';
import React from 'react';
import { IAddressModel, ILocation, locationsCommand_patchAddress } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { AddressForm } from 'shared/address/AddressForm';

const toModel = (location: ILocation): IAddressModel => {
	return {
		addressLine: location.addressLine ?? '',
		zip: location.zip ?? '',
		city: location.city ?? '',
		country: location.country,
	};
};

interface IProps extends DialogProps {
	location: ILocation;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LocationAddressForm = ({ location, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<AddressForm
			{...rest}
			submitFunction={model => locationsCommand_patchAddress({ locationId: location.id, model: model })}
			cancel={cancel}
			model={toModel(location)}
			successMessage={strings.addressUpdated}
			confirm={confirm}
		/>
	);
};
