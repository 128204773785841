import { DialogProps } from '@material-ui/core';
import React from 'react';
import { deliveriesCommand_patchDispatchInfo, IDelivery } from 'gen/ApiClient';
import { DispatchInfoForm } from 'shared/DispatchInfoForm';

interface IProps extends DialogProps {
	item: IDelivery;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const DeliveryDispatchInfoForm = ({ item, ...rest }: IProps) => {
	return (
		<DispatchInfoForm
			{...rest}
			reference={item.dispatchReference}
			date={item.dispatchDate}
			submitF={values => deliveriesCommand_patchDispatchInfo({ deliveryId: item.id, dispatchDate: values.date, dispatchReference: values.reference })}
		/>
	);
};
