import { FormControl, TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useField } from 'formik';
import React, { useState } from 'react';
import { AutocompleteCheckbox } from '../components/autocomplete/AutocompleteCheckbox';
import { AutocompleteChip } from '../components/autocomplete/AutocompleteChip';
import { nameOf } from '../utils/nameOf';
import { FormErrorHelper } from './common/FormErrorHelper';
import { IFormProps } from './common/IFormProps';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddIcon from '@material-ui/icons/Add';
import { IOption } from '../IOption';
import { useLocalization } from 'localization/useLocalization';

interface IProps<TModel, TValue extends IOption<string>> extends IFormProps<TModel> {
	options: TValue[];
	onCreateNew: (val: string) => void;
}

const renderOption = <TValue extends IOption<string>>(id: string, options: TValue[]): string => {
	return options.find(t => t.id === id)?.identifier ?? '';
};

export const FormAutocompleteMultipleWithAdd = <TModel extends unknown, TValue extends IOption<string>>({
	name,
	options,
	label,
	disabled,
	onCreateNew,
	...props
}: IProps<TModel, TValue>) => {
	const [field, meta, helpers] = useField<string[]>(nameOf(name));
	const [inputValue, setInputValue] = useState<string>();
	const strings = useLocalization();

	const onClickCreateNew = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();
		onCreateNew(inputValue ?? '');
	};

	return (
		<FormControl
			variant='filled'
			error={meta.error && meta.touched ? true : false}
			{...props}>
			<Autocomplete
				multiple
				options={options.map(t => t.id)}
				value={field.value}
				inputValue={inputValue}
				onInputChange={(_, value) => setInputValue(value)}
				forcePopupIcon
				onChange={(_, value) => helpers.setValue(value)}
				disableCloseOnSelect
				filterOptions={(options, params) => {
					const filtered = createFilterOptions<string>()(options, params);
					if (params.inputValue !== '' && filtered.length === 0) {
						return [...filtered, params.inputValue];
					} else {
						return filtered;
					}
				}}
				getOptionLabel={id => renderOption(id, options)}
				disabled={disabled}
				clearOnBlur
				renderTags={(tagValue, getTagProps) =>
					tagValue.map((option, index) => (
						<AutocompleteChip
							key={index}
							label={renderOption(option, options)}
							getTagProps={getTagProps}
							index={index}
						/>
					))
				}
				renderInput={params => (
					<TextField
						{...params}
						variant='filled'
						label={label}
					/>
				)}
				renderOption={(id, { selected }) => {
					const x = options.find(t => t.id === id);
					if (x !== undefined) {
						return (
							<AutocompleteCheckbox
								selected={selected}
								text={renderOption(id, options)}
							/>
						);
					} else {
						return (
							<div
								onClick={onClickCreateNew}
								className='df-row-ac'>
								<AddIcon color='action' />
								<OpenInNewIcon color='action' />
								<div style={{ marginLeft: '6px' }}>{`${strings.createNew}: "${inputValue}"`}</div>
							</div>
						);
					}
				}}
			/>
			<FormErrorHelper meta={meta} />
		</FormControl>
	);
};
