import React from 'react';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { ProductCatalogItemExportProp, IProductCatalogItem } from 'gen/ApiClient';
import { SelectableColumn } from '../SelectableColumn';
import { IQuickFilterContext } from 'framework/IQuickFilterContext';
import { QuickFilter } from '../ProductCatalogList';

interface IViewContext extends ITableViewContext<SelectableColumn, IProductCatalogItem>, IQuickFilterContext<QuickFilter> {
	exportRecord: Record<ProductCatalogItemExportProp, string>;
}

export const ViewContext = React.createContext<IViewContext>(undefined as any);
