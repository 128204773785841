import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { SelectField } from 'framework/components/select/SelectField';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectField } from 'framework/forms/FormSelectField';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	PhoneNumberRegion,
	PhoneNumberRegions,
	ContactInfoType,
	ContactInfoTypes,
	IAddPatientContactInfoRequest,
	patientContactInfosCommand_addContactInfo,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo, useState } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import * as yup from 'yup';

const createSchema = (strings: IStrings, isEmail: boolean) => {
	return yup.object<IAddPatientContactInfoRequest>({
		type: yup.mixed<ContactInfoType>().required(strings.formRequired(strings.type)),
		value: isEmail ? yup.string().required().email() : yup.string().required(),
		description: yup.string(),
		patientId: yup.string().required(),
		phoneNumberRegion: yup.mixed<PhoneNumberRegion>().required(),
	});
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	patientId: string;
}

export const AddPatientContactInfoForm = ({ confirm, cancel, patientId, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(patientContactInfosCommand_addContactInfo);
	const { contactInfoTypeRecord } = useContext(RecordContext);
	const notify = useSnackbarNotify();
	const [type, setType] = useState<ContactInfoType>('Phone');
	const schema = useMemo(() => createSchema(strings, type === 'Email'), [strings, type]);

	const handleSubmit = async (values: IAddPatientContactInfoRequest, helpers: FormikHelpers<IAddPatientContactInfoRequest>) => {
		const r = await submit({ ...values, type: type });
		if (handleFormResponse(r, helpers)) {
			notify(strings.contactInfoAdded);
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<IAddPatientContactInfoRequest>
			validateOnMount
			initialValues={{
				description: '',
				type: 'Phone',
				value: '',
				patientId: patientId,
				phoneNumberRegion: 'BE',
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog {...rest}>
					<DialogTitle>{strings.addContactInfo}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'
						style={{ minWidth: 400 }}>
						<SelectField<ContactInfoType>
							value={type}
							label={strings.type}
							onChange={setType}
							options={ContactInfoTypes}
							renderValue={t => contactInfoTypeRecord[t]}
							getKey={t => t.toString()}
							style={{ marginBottom: 8 }}
						/>
						<div className='df-row gap-4'>
							{type === 'Phone' && (
								<FormSelectField<IAddPatientContactInfoRequest, PhoneNumberRegion>
									name='phoneNumberRegion'
									label={strings.country}
									options={PhoneNumberRegions}
									renderValue={t => t}
									getKey={t => t}
								/>
							)}
							<FormTextField<IAddPatientContactInfoRequest>
								className='fg1'
								name='value'
								label={type === 'Email' ? strings.email : strings.phone}
								required
							/>
						</div>
						<FormTextField<IAddPatientContactInfoRequest>
							name='description'
							label={strings.description}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={strings.create}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
