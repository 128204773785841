import { IStrings } from 'localization/IStrings';
import { QuickFilter } from '../CustomerAccountsList';

export const createQfRecord = (strings: IStrings): Record<QuickFilter, string> => {
	return {
		all: strings.all,
		withOutstandingBalance: strings.withOutstandingBalance,
		withOutstandingBalanceNotOnSettlementProposals: strings.withOutstandingBalanceNotOnSettlementProposals,
	};
};
