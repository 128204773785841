import { CosiDegreeOfChange } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createCosiDegreeOfChangeRecord = (strings: IStrings): Record<CosiDegreeOfChange, string> => {
	return {
		Better: strings.better,
		MuchBetter: strings.muchBetter,
		NoDifference: strings.noDifference,
		NotApplicable: strings.notApplicableAbbreviation,
		SlightlyBetter: strings.slightlyBetter,
		Worse: strings.worse,
	};
};
