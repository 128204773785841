import { Typography, TypographyProps } from '@material-ui/core';
import React from 'react';

export const Typography16pxBold = ({ style, ...rest }: TypographyProps) => {
	return (
		<Typography
			{...rest}
			style={{ fontSize: 16, fontWeight: 500, ...style }}
		/>
	);
};
