import { IStrings } from 'localization/IStrings';
import { ReturnsSelectableColumn } from './ReturnsSelectableColumn';

export const createReturnsColumnRecord = (strings: IStrings): Record<ReturnsSelectableColumn, string> => {
	return {
		created: strings.createdAt,
		lastUpdated: strings.lastUpdated,
		countItems: `# ${strings.items}`,
		reference: strings.referenceShort,
		externalReference: strings.shortExternalReference,
		returnDate: strings.returnDate,
		toSupplierIdentifier: strings.supplier,
		fromLocationIdentifier: strings.location,
	};
};
