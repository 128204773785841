import { IStrings } from 'localization/IStrings';
import { QuickFilter } from '../NoahDeviceReadOutsList';

export const createQfRecord = (strings: IStrings): Record<QuickFilter, string> => {
	return {
		all: strings.all,
		notLinkedNotIgnored: strings.notLinkedNotIgnored,
		linkedToMultiple: strings.linkedToMultiple,
	};
};
