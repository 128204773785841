import { Checkbox, Dialog, DialogContent, DialogProps, Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { isLast } from 'framework/utils/array/isLast';
import { IEndFailureTrialModel, ILocationSummary, IMedicalTreatmentNomenclatureForAudiology, rfiasCommand_registerFailure } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { MedicalTreatmentNomenclatureDialog } from 'shared/medicalTreatmentNomenclatures/MedicalTreatmentNomenclatureDialog';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { LocationsPreloadCacheContext } from '../../../../settings/locations/LocationsPreloadCacheContext';
import { useCurrentLocation } from '../../../../settings/locations/useCurrentLocation';
import { LumpSumFilter } from '../medicalTreatmentNomenclatures/LumpSumFilter';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IEndFailureTrialModel>({
			returnToLocationId: yup.string().required(strings.formRequired(strings.location)),
			stopDate: yup.date().required(strings.formRequired(strings.stopDate)),
			medicalTreatmentNomenclatureCode: yup.string().required(strings.formRequired(strings.code)),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IEndFailureTrialModel)[]> = {
	0: ['returnToLocationId'],
	1: ['stopDate'],
	2: ['medicalTreatmentNomenclatureCode'],
};

interface IProps extends DialogProps {
	rfiaId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const EndFailureTrialForm = ({ rfiaId, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [submit, isSubmitting] = useFormSubmit(rfiasCommand_registerFailure);
	const [step, setStep] = useState<number>(0);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const location = useCurrentLocation();
	const now = useNow();

	const handleSubmit = async (values: IEndFailureTrialModel, helpers: FormikHelpers<IEndFailureTrialModel>) => {
		const r = await submit(rfiaId, values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.registeredWhat(strings.failedTrial));
			confirm();
		}
	};

	if (locations === undefined || location === undefined || now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IEndFailureTrialModel>
			validateOnMount
			initialValues={{
				returnToLocationId: location.id,
				stopDate: now,
				medicalTreatmentNomenclatureCode: '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					locations={locations}
					cancel={cancel}
					isSubmitting={isSubmitting}
					schema={createSchema(strings)}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	locations: ILocationSummary[];
	cancel: VoidFunction;
	isSubmitting: boolean;
	schema: yup.ObjectSchema<IEndFailureTrialModel>;
}

const InnerDialog = ({ step, setStep, locations, cancel, isSubmitting, schema, ...rest }: IInnerProps) => {
	const props = useFormikContext<IEndFailureTrialModel>();
	const { open, cancel: closeDetail, isStacked } = useDialogsContext();
	const [filtered, setFiltered] = useState<IMedicalTreatmentNomenclatureForAudiology[]>([]);
	const strings = useLocalization();
	const language = useCurrentLanguage();

	const onViewDetail = (code: string) => {
		open(
			<MedicalTreatmentNomenclatureDialog
				code={code}
				open
				cancel={closeDetail}
				select={() => {
					closeDetail();
					setFormValue<IEndFailureTrialModel>('medicalTreatmentNomenclatureCode', code, props);
				}}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			scroll='paper'
			maxWidth='md'>
			<DialogTitleWithFormStepper
				title={strings.registerWhat(strings.failedTrial)}
				step={step}
				labels={[strings.location, strings.date, strings.nomenclature]}
			/>
			<DialogContent
				className='df-col'
				dividers>
				{step === 0 && (
					<FormSelectFieldWithOption<IEndFailureTrialModel, string>
						name='returnToLocationId'
						options={locations}
						label={strings.location}
						helperText={strings.locationWhereProductsWillBeReceived}
						required
					/>
				)}
				{step === 1 && (
					<FormDatePicker<IEndFailureTrialModel>
						name='stopDate'
						label={strings.endDate}
						disablePast
					/>
				)}
				{step === 2 && (
					<>
						<LumpSumFilter setFiltered={setFiltered} />
						<DividerWithCaption caption={`${strings.filteredResults} (${filtered.length.toString()})`} />
						<List
							dense
							style={{ overflow: 'auto', marginLeft: '-24px', marginRight: '-24px' }}>
							{filtered.map(t => (
								<div
									className='df-col'
									key={t.data.code}>
									<ListItem
										button
										onClick={() => setFormValue<IEndFailureTrialModel>('medicalTreatmentNomenclatureCode', t.data.code, props)}
										disabled={isSubmitting}>
										<ListItemIcon>
											<Checkbox checked={props.values.medicalTreatmentNomenclatureCode === t.data.code} />
										</ListItemIcon>
										<ListItemText
											primary={t.data.code}
											secondary={language === 'fr' ? t.data.descriptionFr : t.data.descriptionNl}
											style={{ paddingRight: '40px' }}
										/>
										<ListItemSecondaryAction>
											<SmallPrimaryTextButton onClick={() => onViewDetail(t.data.code)}>{strings.details}</SmallPrimaryTextButton>
										</ListItemSecondaryAction>
									</ListItem>
									{isLast(t, filtered) === false && <Divider />}
								</div>
							))}
						</List>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
