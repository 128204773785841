import React from 'react';
import { AgendaProviders } from '../agenda/context/AgendaProviders';
import { CalendarEventTypesCacheProvider } from './CalendarEventTypesCacheProvider';
import { CalendarResourcesPreloadCacheProvider } from './CalendarResourcesPreloadCacheProvider';

export const CalendarProviders = ({ children }: any) => {
	return (
		<CalendarEventTypesCacheProvider>
			<CalendarResourcesPreloadCacheProvider>
				<AgendaProviders>{children}</AgendaProviders>
			</CalendarResourcesPreloadCacheProvider>
		</CalendarEventTypesCacheProvider>
	);
};
