import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { FilterBar } from 'framework/filter/FilterBar';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IStockItemsQueryParamsForArticleProperties, ProductCatalogItemType, ProductCatalogItemTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { ProductCatalogFilterDataContext } from '../../context/ProductCatalogFilterDataContext';

interface IProps {
	params: IStockItemsQueryParamsForArticleProperties;
	setParams: React.Dispatch<React.SetStateAction<IStockItemsQueryParamsForArticleProperties>>;
}

export const Filter = ({ params, setParams }: IProps) => {
	const strings = useLocalization();
	const [searchData] = useCacheContext(ProductCatalogFilterDataContext);
	const { productCatalogItemTypeRecord } = useContext(RecordContext);

	return (
		<FilterBar
			disableWrap
			isFiltered={isFiltered(params)}
			onClear={() => setParams(clearParams(params))}>
			<RecordListFilterComponent<ProductCatalogItemType>
				options={ProductCatalogItemTypes}
				record={productCatalogItemTypeRecord}
				selected={params.types}
				label={strings.type}
				icon={<HelpOutlineIcon />}
				setSelected={vals => setParams({ ...params, types: vals })}
			/>
			{searchData && searchData.manufacturers.length > 0 && (
				<StringListFilterComponent
					label={strings.manufacturer}
					selected={params.manufacturers}
					options={searchData.manufacturers}
					icon={<HelpOutlineIcon />}
					setSelected={value => setParams({ ...params, manufacturers: value })}
				/>
			)}
			{searchData && searchData.categories.length > 0 && (
				<StringListFilterComponent
					label={strings.category}
					selected={params.categories}
					options={searchData.categories}
					icon={<HelpOutlineIcon />}
					setSelected={value => setParams({ ...params, categories: value })}
				/>
			)}
			{searchData && searchData.subcategories.length > 0 && (
				<StringListFilterComponent
					label={strings.subcategory}
					selected={params.subcategories}
					options={searchData.subcategories}
					icon={<HelpOutlineIcon />}
					setSelected={value => setParams({ ...params, subcategories: value })}
				/>
			)}
		</FilterBar>
	);
};
