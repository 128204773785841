import { useTheme } from '@material-ui/core';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { SignatureFreehandIcon } from 'framework/components/icons/SignatureFreehandIcon';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { IDocument } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: IDocument;
}

export const DocumentStatusChip = ({ item }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();

	return (
		<div className='df-row-ac'>
			{item.isDeleted && (
				<RectangularChip
					style={{ marginRight: 8 }}
					label={strings.deleted}
					fill
					color='error'
				/>
			)}
			{item.mustSign && item.hasSignature === false && (
				<StatusIcon
					status='warning'
					style={{ marginRight: 8 }}
				/>
			)}
			{item.hasSignature === true && <SignatureFreehandIcon style={{ color: theme.palette.success.main, marginRight: 8 }} />}
		</div>
	);
};
