import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { SvgIconProps } from '@material-ui/core';

interface IProps extends SvgIconProps {
	isFavorite: boolean;
}

export const StarFavoriteIcon = ({ isFavorite, style, ...rest }: IProps) => {
	return isFavorite ? (
		<StarIcon
			style={{ color: '#FFB400', ...style }}
			{...rest}
		/>
	) : (
		<StarBorderIcon
			style={{ color: '#FFB400', ...style }}
			{...rest}
		/>
	);
};
