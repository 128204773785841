import { updateArray } from './updateArray';

export const addOrUpdateQuantityByPredicate = <T extends { quantity: number }>(predicate: (val: T) => boolean, line: T, lines: T[]): T[] => {
	const f = lines.find(predicate);
	if (f) {
		return updateArray(f, lines, { ...f, quantity: f.quantity + line.quantity });
	} else {
		return [...lines, line];
	}
};
