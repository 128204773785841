import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { ITendersQueryMetadata, TendersPrefilter } from 'gen/ApiClient';
import React from 'react';

export const createTendersPrefilterBadgeChipXRecord = (metadata: ITendersQueryMetadata): Record<TendersPrefilter, React.ReactNode> => {
	return {
		All: undefined,
		Expired: undefined,
		Invoiced: undefined,
		NotLostAndExpired: metadata.countNotLostAndOverdue > 0 && (
			<BadgeChipX
				value={metadata.countNotLostAndOverdue}
				color='warning'
			/>
		),
	};
};
