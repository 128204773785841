import { NumberFormatProps } from 'framework/components/textFields/NumberFormatProps';
import { TextLink } from 'framework/components/TextLink';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { BebatDefaultCacheContext } from '../context/BebatDefaultCacheContext';
import { useViewDocumentation } from '../utils/useViewDocumentation';

interface IProps<TModel> extends IFormProps<TModel>, NumberFormatProps {
	startAdornment?: JSX.Element;
}

export const FormBebatField = <TModel extends unknown>({ ...rest }: IProps<TModel>) => {
	const [data] = usePreloadCacheContext(BebatDefaultCacheContext);
	const onViewBebatTarifs = useViewDocumentation('bebat-tarifs');
	const strings = useLocalization();

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<FormMoneyField<TModel>
			{...rest}
			decimalScale={3}
			helperText={
				<>
					<div>
						{formatCurrency(data, 4)} {strings.exVatPerPiece}
					</div>
					<TextLink
						text={strings.viewDocumentation}
						onClick={onViewBebatTarifs}
					/>
				</>
			}
		/>
	);
};
