import { set } from 'date-fns';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Try } from 'framework/Try';
import {
	agendaCommand_addExDate,
	agendaCommand_new,
	agendaCommand_update,
	agendaCommand_updateRrule,
	ICalendarEventDto,
	ICalendarEventModel,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { calculateREndDate } from '../forms/utils/calculateREndDate';
import { formatRrule } from '../forms/utils/formatRrule';
import { newRRuleFromDate } from '../newRRuleFromDate';
import { newRRuleUntilDate } from '../newRRuleUntilDate';
import { RecurrenceUpdateMode } from '../RecurrenceUpdateMode';

interface IProps {
	originalEvent: ICalendarEventDto;
	instanceDate: Date;
	values: ICalendarEventModel;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const UpdateRecurringEventDialog = ({ originalEvent, instanceDate, values, confirm, cancel }: IProps) => {
	const strings = useLocalization();
	const [mode, setMode] = useState<RecurrenceUpdateMode>('this');
	const [addExDate] = useFormSubmit(agendaCommand_addExDate);
	const [createNew] = useFormSubmit(agendaCommand_new);
	const [updateRrule] = useFormSubmit(agendaCommand_updateRrule);
	const [update] = useFormSubmit(agendaCommand_update);

	const onAccept = async (): Promise<Try<any>> => {
		if (mode === 'this') {
			const r1 = await addExDate(originalEvent.id, new Date(instanceDate));
			if (r1.isSuccess) {
				return await createNew({ ...values, rRule: undefined, isRecurring: false });
			} else {
				return r1;
			}
		} else if (mode === 'thisAndFuture') {
			const rrule = newRRuleUntilDate(originalEvent.rRule!, originalEvent.startDate, instanceDate);
			const endDate = calculateREndDate(rrule, new Date(originalEvent.startDate));
			const r1 = await updateRrule(originalEvent.id, { rrule: formatRrule(rrule), rEndDate: endDate });
			if (r1.isSuccess) {
				const newRrule = newRRuleFromDate(originalEvent.rRule!, originalEvent.startDate, values.startDate);
				const rEndDate = calculateREndDate(newRrule, values.startDate);
				return await createNew({ ...values, rRule: formatRrule(newRrule), rEndDate: rEndDate });
			} else {
				return r1;
			}
		} else {
			return await update(originalEvent.id, {
				...values,
				startDate: set(new Date(originalEvent.startDate), { hours: values.startDate.getHours(), minutes: values.startDate.getMinutes() }),
				endDate: set(new Date(originalEvent.endDate), { hours: values.endDate.getHours(), minutes: values.endDate.getMinutes() }),
			});
		}
	};

	return (
		<AlertDialog
			open
			title={strings.changeWhat(strings.recurringAppointment)}
			outOfContentText
			content={
				<div className='df-col'>
					<ExtendedRadioGroup<RecurrenceUpdateMode>
						selected={mode}
						setSelected={setMode}
						options={[
							{ value: 'this', label: strings.thisAppointment },
							{ value: 'thisAndFuture', label: strings.thisAndNextAppointments },
							{ value: 'all', label: strings.allAppointments },
						]}
					/>
				</div>
			}
			acceptText={strings.continue}
			rejectText={strings.cancel}
			reject={cancel}
			acceptF={onAccept}
			accept={confirm}
		/>
	);
};
