import React from 'react';
import { CacheProvider } from 'framework/context/CacheProvider';
import { IRecupelItemDto, LanguageOption, recupelsQuery_all, recupelsQuery_concurrencyToken } from 'gen/ApiClient';
import { RecupelCacheContext } from './RecupelCacheContext';

interface IProps {
	children: any;
	language: LanguageOption;
}

export const RecupelCacheProvider = ({ children, language }: IProps) => {
	return (
		<CacheProvider<IRecupelItemDto[]>
			concurrencyF={recupelsQuery_concurrencyToken}
			loadF={recupelsQuery_all}
			context={RecupelCacheContext}
			language={language}>
			{children}
		</CacheProvider>
	);
};
