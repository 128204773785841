import React, { useState } from 'react';
import { EidForm } from '../eid/EidForm';
import { CreateFromNhaxFile } from './CreateFromNhaxFile';
import { Mode } from './Mode';
import { ModeDialog } from './ModeDialog';
import { PatientModelForm } from './PatientModelForm';

interface IProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

type State = Mode | 'select';

export const PatientForm = ({ cancel, confirm }: IProps) => {
	const [state, setState] = useState<State>('select');

	if (state === 'select') {
		return (
			<ModeDialog
				open
				confirm={mode => setState(mode)}
				cancel={cancel}
			/>
		);
	} else if (state === 'eid') {
		return (
			<EidForm
				confirm={confirm}
				cancel={cancel}
			/>
		);
	} else if (state === 'form') {
		return (
			<PatientModelForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<CreateFromNhaxFile
				confirm={confirm}
				cancel={cancel}
			/>
		);
	}
};
