import { StatCardX } from 'framework/components/cards/StatCardX';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { ITendersQueryMetadata, ITendersQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const Width: number = 250;

interface IProps {
	params: ITendersQueryParams;
	setParams: (params: ITendersQueryParams) => void;
	metadata: ITendersQueryMetadata;
	style?: React.CSSProperties;
}

export const TendersListStats = ({ metadata, style, setParams, params }: IProps) => {
	const strings = useLocalization();
	const onFilter = (pars: ITendersQueryParams) => setParams(onResetToPage1(pars));

	return (
		<div
			className='df-row-ac gap-16'
			style={style}>
			<StatCardX
				style={{ width: Width }}
				title={strings.all}
				// caption={`# ${strings.all}`}
				onClick={() => onFilter({ ...params, prefilter: 'All' })}>
				<span style={{ fontSize: 24 }}>{metadata.count.toString()}</span>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={strings.activeAndExpired}
				// caption={`# ${strings.activeAndExpired}`}
				onClick={() => onFilter({ ...params, prefilter: 'NotLostAndExpired' })}>
				<span style={{ fontSize: 24 }}>{metadata.countNotLostAndOverdue.toString()}</span>
			</StatCardX>
		</div>
	);
};
