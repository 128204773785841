import { IconButton, Typography } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import Replay10OutlinedIcon from '@material-ui/icons/Replay10Outlined';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { useContinuousCountdown } from 'framework/hooks/useContinuousCountdown';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	reload: VoidFunction;
	reloadEveryXSeconds?: number;
}

export const PageReloadButton = ({ reload, reloadEveryXSeconds }: IProps) => {
	const strings = useLocalization();
	const [reloadCounter] = useContinuousCountdown(reloadEveryXSeconds ?? -1);

	useLazyEffect(() => {
		if (reloadCounter === 0) {
			reload();
		}
	}, [reloadCounter]);

	return (
		<TooltipWithSpan
			title={reloadEveryXSeconds !== undefined ? strings.autoReloadInXSeconds(reloadCounter) : strings.reload}
			style={{ marginTop: '-12px', marginBottom: '-12px' }}>
			<>
				<IconButton
					onClick={reload}
					edge='end'>
					{reloadEveryXSeconds !== undefined ? <Replay10OutlinedIcon /> : <ReplayIcon />}
					{reloadEveryXSeconds !== undefined && (
						<Typography
							variant='caption'
							style={{ position: 'absolute', top: 34, fontSize: '8px' }}>
							AUTO
						</Typography>
					)}
				</IconButton>
			</>
		</TooltipWithSpan>
	);
};
