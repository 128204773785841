import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useContext } from 'react';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { createHeader } from 'framework/table/createHeader';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { createType } from 'framework/utils/createType';
import { ISettlementProposal } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { createDetailsHeader } from 'shared/table/createDetailsHeader';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';
import { SettlementProposalDialog } from './detail/SettlementProposalDialog';
import { SpsExportButtonMenuAndDialog } from './export/SpsExportButtonMenuAndDialog';
import { Filter } from './Filter';
import { SelectableColumn } from './SelectableColumn';
import { SettlementProposalStatusChip } from './SettlementProposalStatusChip';

const QuickFilters = createType('all', 'unhandled');
export type QuickFilter = typeof QuickFilters[number];

interface IProps {
	reload: VoidFunction;
	onNew: VoidFunction;
	style?: React.CSSProperties;
}

export const SettlementProposalsCard = ({ reload, onNew, style }: IProps) => {
	const { queryResult, params, setParams } = useContext(QueryContext);
	const strings = useLocalization();
	const theme = useTheme();
	const { columns, setColumns, columnsRecord, headers, quickFilter, setQuickFilter, quickFilterRecord } = useContext(ViewContext);
	const { open, confirm: close } = useDialogsContext(reload);
	const prependColumn = createHeader<ISettlementProposal>(strings.status, t => <SettlementProposalStatusChip data={t} />);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onViewDetail = (sps: ISettlementProposal) => {
		open(
			<SettlementProposalDialog
				open
				close={close}
				id={sps.id}
			/>
		);
	};

	return (
		<Card
			className='fg1'
			style={style}>
			<CardTitleTypography
				withPadding
				title={strings.settlementProposals}
				action={
					<SmallPrimaryTextButton
						startIcon={<AddIcon />}
						onClick={onNew}>
						{strings.newWhat(`${strings.settlementProposal}/${strings.verzamelstaat.toLowerCase()}`)}
					</SmallPrimaryTextButton>
				}
			/>
			{queryResult.unfilteredCount > 0 && (
				<>
					<Divider />
					<div
						className='df-row-ac jc-sb'
						style={{ padding: theme.spacing(1) }}>
						<ButtonGroupWithSelection<QuickFilter>
							options={QuickFilters}
							selected={quickFilter}
							setSelected={setQuickFilter}
							renderOption={t => quickFilterRecord[t]}
						/>
						<div>
							<SpsExportButtonMenuAndDialog
								params={params}
								queryResult={queryResult}
							/>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
								view='settlementProposalsSps'
							/>
						</div>
					</div>
					<Divider />
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchVerb}
						style={{ width: '400px' }}
						variant='embedded'
					/>
					<Divider />
					<div className='df-row jc-sb'>
						<Filter
							params={params}
							onFilter={params => setParams(onResetToPage1(params))}
							paddingLeft={theme.spacing(1.5)}
						/>
					</div>
					<Divider />
					<CardMedia>
						<PageableTableWithColumnSelection<ISettlementProposal, SelectableColumn>
							queryResult={queryResult}
							headers={headers}
							sortParams={params.sortParams}
							pageParams={params.pageParams}
							setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
							getKey={inst => inst.id}
							selectedColumns={columns}
							prependColumn={prependColumn}
							appendColumn={createDetailsHeader<ISettlementProposal>(onViewDetail)}
						/>
					</CardMedia>
				</>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound withPadding>{strings.noWhatRegistered(strings.settlementProposals)}</NoRecordsFound>}
		</Card>
	);
};
