import { CardProps, Divider } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ICompaniesQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';

interface IProps extends CardProps {
	params: ICompaniesQueryParams;
	setParams: React.Dispatch<React.SetStateAction<ICompaniesQueryParams>>;
}

export const CompaniesListFilterCard = ({ params, setParams, ...rest }: IProps) => {
	const strings = useLocalization();
	const isFilteredX = useMemo(() => isFiltered(params, []), [params]);

	const onFilter = (pars: ICompaniesQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params, [])));

	return (
		<CardX
			className='df-col gap-8'
			{...rest}>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<DateFilterItem
					label={strings.dateCreated}
					after={params.createdAfter}
					before={params.createdBefore}
					setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
					icon={<DateRangeIcon />}
				/>
			</div>
			{/* <Divider />
			<FormControlLabelSwitch
				isChecked={params.showDeleted}
				toggle={() => onFilter({ ...params, showDeleted: !params.showDeleted })}
				label={strings.alsoShowDeleted}
			/> */}
		</CardX>
	);
};
