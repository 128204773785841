import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { RfiaInvoiceProcessStep, RfiaInvoiceProcessSteps, rfiaSettingsCommand_updateDefaultInvoiceProcessSteps } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { StepsField } from 'shared/process/StepsField';
import { RecordContext } from 'shared/records/RecordContext';
import * as yup from 'yup';
import { isInvoiceProcessStepOptional } from '../forms/isInvoiceProcessStepOptional';

interface IModel {
	steps: string[];
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			steps: yup.array().of(yup.string().defined()).defined(),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	value: string[];
}

export const DefaultInvoiceProcessStepsForm = ({ confirm, cancel, value, ...rest }: IProps) => {
	const strings = useLocalization();
	const { rfiaInvoiceProcessStepRecord } = useContext(RecordContext);
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(rfiaSettingsCommand_updateDefaultInvoiceProcessSteps);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await update({ defaultInvoiceProcessSteps: values.steps });
		if (handleValidateResponse(r, helpers, 'steps')) {
			notify(strings.stepsChanged);
			confirm();
		}
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{ steps: value }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			{props => (
				<Form>
					<Dialog
						{...rest}
						scroll='paper'
						maxWidth='sm'
						fullWidth>
						<DialogTitle>
							<div>{strings.changeWhat(strings.defaultInvoiceProcessSteps)}</div>
						</DialogTitle>
						<DialogContent dividers>
							<StepsField<IModel, RfiaInvoiceProcessStep>
								steps={RfiaInvoiceProcessSteps}
								record={rfiaInvoiceProcessStepRecord}
								name='steps'
								isOptional={isInvoiceProcessStepOptional}
							/>
						</DialogContent>
						<CancelSubmitFormDialogActions<IModel>
							cancel={cancel}
							submitText={strings.changeSomething(strings.steps)}
							isSubmitting={isSubmitting}
						/>
					</Dialog>
				</Form>
			)}
		</Formik>
	);
};
