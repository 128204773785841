import { IRosaStatusDto } from 'gen/ApiClient';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RosaUserSettingsCardX } from './RosaUserSettingsCardX';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { RosaAdminSettingsCardX } from './RosaAdminSettingsCardX';
import { RosaAdminSyncCard } from './RosaAdminSyncCard';
import { RosaAdminInstructionsCard } from './RosaAdminInstructionsCard';
import { Divider } from '@material-ui/core';

interface IProps extends IReloadTriggerProps {
	data: IRosaStatusDto;
	reload: VoidFunction;
}

export const RosaSettingsTab = ({ reloadTrigger, reload, data }: IProps) => {
	const isSuperUser = useLoggedInUserIsSuperUser();

	return (
		<div
			className='df-col gap-16'
			style={{ padding: 16, maxWidth: 1280, height: '100%' }}>
			<RosaUserSettingsCardX
				reloadTrigger={reloadTrigger}
				data={data}
			/>
			{isSuperUser && (
				<>
					<RosaAdminInstructionsCard />
					<RosaAdminSettingsCardX
						reloadTrigger={reloadTrigger}
						reload={reload}
					/>
					<RosaAdminSyncCard
						reloadTrigger={reloadTrigger}
						reload={reload}
					/>
					<Divider />
				</>
			)}
		</div>
	);
};
