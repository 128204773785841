import { Button, Card, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, useTheme } from '@material-ui/core';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { createHeader } from 'framework/table/createHeader';
import { PageableTable } from 'framework/table/PageableTable';
import { formatDate } from 'framework/utils/date/formatDate';
import {
	IInventoryItemsQueryParamsForPurchaseCreditAssurance,
	IInventoryItemSummaryWithPurchasedAssuranceDto,
	inventoryItemsQuery_queryForPurchaseCreditAssurance,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { createSelectHeader } from 'shared/table/createSelectHeader';

interface IProps extends DialogProps {
	confirm: (item: IInventoryItemSummaryWithPurchasedAssuranceDto) => void;
	cancel: VoidFunction;
	assuranceProductCatalogItemId: string;
	selectedCreditedAssuranceLineIds: string[];
}

const DefaultParams: IInventoryItemsQueryParamsForPurchaseCreditAssurance = {
	pageParams: { index: 1, size: 10 },
	sortParams: { property: 'serialNumber', direction: 'asc' },
	searchString: '',
};

export const SelectAssuredInventoryItemToCreditDialog = ({
	confirm,
	cancel,
	assuranceProductCatalogItemId,
	selectedCreditedAssuranceLineIds,
	...rest
}: IProps) => {
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(
		pars => inventoryItemsQuery_queryForPurchaseCreditAssurance(assuranceProductCatalogItemId, pars),
		DefaultParams
	);

	const theme = useTheme();
	const headers = useMemo(
		() => [
			createHeader<IInventoryItemSummaryWithPurchasedAssuranceDto>(strings.inventoryItem, t => t.display),
			createHeader<IInventoryItemSummaryWithPurchasedAssuranceDto>(strings.purchaseReference, t => t.transaction.reference),
			createHeader<IInventoryItemSummaryWithPurchasedAssuranceDto>(strings.purchaseDate, t => formatDate(t.transactionDate)),
			createSelectHeader<IInventoryItemSummaryWithPurchasedAssuranceDto>(
				t => confirm(t),
				t => selectedCreditedAssuranceLineIds.indexOf(t.transactionLineId) > -1
			),
		],
		// eslint-disable-next-line
		[strings]
	);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<Dialog
			{...rest}
			fullWidth
			maxWidth='md'>
			<DialogTitle>{strings.select}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<div className='df-col h100'>
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchVerb}
						variant='paper'
						style={{ margin: '1px', marginBottom: theme.spacing(2) }}
					/>
					<Card
						variant='outlined'
						className='df-col'>
						<PageableTable<IInventoryItemSummaryWithPurchasedAssuranceDto>
							queryResult={queryResult}
							headers={headers}
							sortParams={params.sortParams}
							pageParams={params.pageParams}
							setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
							getKey={inst => inst.assuredInventoryItemId!}
						/>
					</Card>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					variant='text'
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
