import { FadedRectangularChip } from 'framework/components/chips/FadedRectangularChip';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { IChange } from './IChange';

interface IProps {
	change: IChange;
	isCurrency?: boolean;
	tooltip?: string;
	style?: React.CSSProperties;
}

export const AbsoluteChangeFadedRectangularChip = ({ change, isCurrency, tooltip, style }: IProps) => {
	const strings = useLocalization();

	return (
		<FadedRectangularChip
			tooltip={tooltip ?? `${(change.relativeChange * 100).toFixed(0)}% ${strings.inComparisonWithPreviousTransaction}`}
			label={`${change.isChangePositive ? '+' : ''}${isCurrency === true ? formatCurrency(change.absoluteChange) : change.absoluteChange}`}
			color={change.isChanged === false ? 'info' : change.isChangePositive ? 'success' : 'error'}
			style={style}
		/>
	);
};
