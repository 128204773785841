import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { calcCustomerAccountDetailRoute, calcSalesDetailRoute } from 'app/main/financial/routes';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { CashMinusIcon } from 'framework/components/icons/CashMinusIcon';
import { CashPlusIcon } from 'framework/components/icons/CashPlusIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { customerAccountLinesQuery_single, ICustomerAccountLine, settlementProposalsQuery_unhandledForCustomerAccount } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PaymentForSaleForm } from '../../payments/forms/customerAccountLines/PaymentForSaleForm';
import { RefundForSaleForm } from '../../payments/forms/customerAccountLines/RefundForSaleForm';
import { PaymentDialog } from '../../payments/PaymentDialog';
import { AddLineToSettlementProposalForm } from '../../settlementProposals/forms/AddLineToSettlementProposalForm';
import { SettlementProposalDialog } from '../../settlementProposals/sps/detail/SettlementProposalDialog';
import { PaymentsTable } from './PaymentsTable';
import { CustomerAccountLineStatusChip } from './sales/CustomerAccountLineStatusChip';

interface IProps extends DialogProps {
	id: string;
	close: VoidFunction;
	entry: 'customerAccount' | 'sale';
}

export const CustomerAccountLineDialog = ({ id, close, entry, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [line, reload] = useApiEffect(customerAccountLinesQuery_single, id);
	const call = useApiCall(settlementProposalsQuery_unhandledForCustomerAccount);
	const [hasProposals, setHasProposals] = useState<boolean>(false);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);
	const { push } = useHistory();

	useEffect(() => {
		const exec = async (line: ICustomerAccountLine) => {
			const r = await call(line.customerAccountId);
			setHasProposals(r.result.length > 0);
		};
		if (line) {
			exec(line);
		}
		// eslint-disable-next-line
	}, [line]);

	if (line === undefined) {
		return <div></div>;
	}

	const onNavigateToCustomerAccount = () => {
		push(calcCustomerAccountDetailRoute(line.customerAccountId));
		close();
	};

	const onNavigateToSales = () => {
		push(calcSalesDetailRoute(line.salesId!));
		close();
	};

	const onPayment = () => {
		open(
			<PaymentForSaleForm
				open
				customerAccountLineId={line.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onRefund = () => {
		open(
			<RefundForSaleForm
				open
				customerAccountLineId={line.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onAddLine = () => {
		open(
			<AddLineToSettlementProposalForm
				open
				customerAccountLine={line}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onSettlementProposal = () => {
		if (line.unhandledSettlementProposalId) {
			open(
				<SettlementProposalDialog
					open
					id={line.unhandledSettlementProposalId}
					close={confirm}
				/>
			);
		}
	};

	const onViewPayment = (id: string) => {
		open(
			<PaymentDialog
				open
				id={id}
				close={cancel}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			onClose={close}
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<HelpOutlineIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{line.customerAccountIdentifier} | {line.salesReference}
					</Typography>
					<CustomerAccountLineStatusChip
						cal={line}
						style={{ marginBottom: theme.spacing(1) }}
					/>
					<LabelledProperty
						label={strings.accountReceivable}
						property={line.customerAccountIdentifier}
						navigate={entry === 'sale' ? onNavigateToCustomerAccount : undefined}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.salesReference}
						property={line.salesReference}
						navigate={entry === 'customerAccount' ? onNavigateToSales : undefined}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.amountToPay}
						property={formatCurrency(line.value)}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.alreadyPaid}
						property={formatCurrency(line.amountPaid)}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.outstandingBalance}
						property={formatCurrency(line.outstandingBalance)}
						withoutMargin
					/>
				</DialogGridTitle>
				{line.hasSettlementProposal && (
					<>
						<GridDividerWithCaption caption={strings.settlementProposal} />
						<PropertyInGrid
							label={strings.reference}
							value={line.unhandledSettlementProposalReference}
							navigate={onSettlementProposal}
						/>
					</>
				)}
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							{entry === 'sale' && (
								<SmallPrimaryTextButton
									startIcon={<OpenInBrowserIcon />}
									onClick={onNavigateToCustomerAccount}>
									{strings.navigateToWhat(strings.accountReceivable)}
								</SmallPrimaryTextButton>
							)}
							{entry === 'customerAccount' && (
								<SmallPrimaryTextButton
									startIcon={<OpenInBrowserIcon />}
									onClick={onNavigateToSales}>
									{strings.navigateToWhat(strings.sale)}
								</SmallPrimaryTextButton>
							)}
							<SmallPrimaryTextButton
								startIcon={<CashPlusIcon />}
								onClick={onPayment}
								disabled={line.hasSettlementProposal || line.isFullyPaid}>
								{strings.registerPayment}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								startIcon={<CashMinusIcon />}
								onClick={onRefund}
								disabled={line.canCreateRefund === false}>
								{strings.registerRefund}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								startIcon={<HelpOutlineIcon />}
								onClick={onAddLine}
								disabled={line.hasSettlementProposal || line.isFullyPaid || hasProposals === false}>
								{strings.addToSettlementProposal}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
				<GridDividerWithCaption caption={strings.salesData} />
				<PropertyInGrid
					label={strings.date}
					value={formatDate(line.salesDate)}
				/>
				<PropertyInGrid
					label={strings.reference}
					value={line.salesReference}
				/>
				<PropertyInGrid
					label={strings.withRespectToShort}
					value={line.salesRecipientName}
				/>
				<PropertyInGrid
					label={`${strings.totalAmount}\n(${strings.from.toLowerCase()} ${strings.sale.toLowerCase()})`}
					value={formatCurrency(line.salesTotalPriceInVat)}
				/>
				<PropertyInGrid
					label={strings.amountToPay}
					value={formatCurrency(line.amountToPay)}
				/>
				{line.hasPayments && (
					<>
						<GridDividerWithCaption caption={strings.payments} />
						<PaymentsTable
							lines={line.payments}
							total={line.amountPaid}
							onViewDetail={onViewPayment}
						/>
					</>
				)}
			</DialogContentGrid>
		</Dialog>
	);
};
