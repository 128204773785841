import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		id: strings.id,
		name: strings.name,
		identifier: strings.name,
		category: strings.category,
		subcategory: strings.subcategory,
		salesListPrice: strings.recommendedSalesprice,
		vatPercentage: strings.vat,
		isTangible: strings.somethingQuestionmark(strings.tangible),
		isTailorMade: strings.somethingQuestionmark(strings.tailorMadeProduct),
		isHearingInstrument: strings.somethingQuestionmark(strings.hearingAid),
		isElectronics: strings.somethingQuestionmark(strings.electronics),
		hasBatteries: strings.somethingQuestionmark(strings.batteries),
		hasSerialNumber: strings.somethingQuestionmark(strings.serialNumber),
		created: strings.createdAt,
		lastUpdated: strings.lastUpdated,
		manufacturer: strings.manufacturer,
		model: strings.model,
		internalReference: strings.shortInternalReference,
		manufacturerReferencesCsv: strings.shortManufacturerReference,
		supplierIdentifierCsv: strings.suppliers,
		type: strings.type,
		purchaseListPrice: strings.purchaseListPrice,
		rizivHearingAidDefinitionV2IdentificationCodesCsv: strings.RIZIVCode,
		labels: strings.labels,
	};
};
