import React from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { ISettlementProposal } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	data: ISettlementProposal;
	style?: React.CSSProperties;
}

export const SettlementProposalStatusChip = ({ data, style }: IProps) => {
	const strings = useLocalization();

	return (
		<RectangularChip
			style={style}
			label={data.isHandled ? strings.handled : strings.unhandled}
			fill={data.isHandled === true}
			color={data.isHandled === true ? 'success' : 'info'}
		/>
	);
};
