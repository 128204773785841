import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { SuppliersCacheContext } from 'app/main/contacts/suppliers/SuppliersCacheContext';
import { LocationsPreloadCacheContext } from 'app/main/settings/locations/LocationsPreloadCacheContext';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { MoneyRangeFilterComponent } from 'framework/filter/MoneyRangeFilterComponent';
import { NumberRangeFilterComponent } from 'framework/filter/NumberRangeFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IProductCatalogSearchData, IStockItemDtosQueryParams, ProductCatalogItemType, TangibleTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { VatPercentagesListFilterItem } from 'shared/VatPercentagesListFilterItem';
import { RecordContext } from 'shared/records/RecordContext';
import { ViewContext } from './context/ViewContext';

interface IProps {
	params: IStockItemDtosQueryParams;
	onFilter: (params: IStockItemDtosQueryParams) => void;
	paddingLeft: number;
	data: IProductCatalogSearchData;
}

const exclusions: (keyof IStockItemDtosQueryParams)[] = ['keyFilterType'];

export const Filter = ({ params, onFilter, paddingLeft, data }: IProps) => {
	const strings = useLocalization();
	const { productCatalogItemTypeRecord } = useContext(RecordContext);
	const { quickFilter } = useContext(ViewContext);
	const [suppliers] = usePreloadCacheContext(SuppliersCacheContext);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);

	if (data === undefined || suppliers === undefined || locations === undefined) {
		return <div></div>;
	}

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			{quickFilter === 'all' && (
				<RecordListFilterComponent<ProductCatalogItemType>
					options={TangibleTypes}
					record={productCatalogItemTypeRecord}
					selected={params.types}
					setSelected={vals => onFilter({ ...params, types: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.type}
				/>
			)}
			<ListFilterComponent<string>
				label={strings.location}
				selected={params.availableOnLocationIds}
				options={locations}
				setSelected={val => onFilter({ ...params, availableOnLocationIds: val })}
				icon={<HelpOutlineIcon />}
			/>
			<ListFilterComponent<string>
				label={strings.suppliers}
				selected={params.supplierIds}
				options={suppliers}
				setSelected={value => onFilter({ ...params, supplierIds: value })}
				icon={<HelpOutlineIcon />}
			/>
			<NumberRangeFilterComponent
				label={`# ${strings.inStock}`}
				min={0}
				selected={{ min: params.stockMoreThan, max: params.stockLessThan }}
				setSelected={value => onFilter({ ...params, stockLessThan: value.max, stockMoreThan: value.min })}
				icon={<HelpOutlineIcon />}
			/>
			<MoneyRangeFilterComponent
				label={strings.stockValue}
				selected={{ min: params.stockValueMoreThan, max: params.stockValueLessThan }}
				setSelected={value => onFilter({ ...params, stockValueLessThan: value.max, stockValueMoreThan: value.min })}
				icon={<HelpOutlineIcon />}
			/>
			<VatPercentagesListFilterItem
				selected={params.vatPercentages as any}
				setSelected={vals => onFilter({ ...params, vatPercentages: vals })}
			/>
			<BooleanRadioFilterComponent
				label={strings.somethingQuestionmark(strings.serialNumber)}
				trueText={strings.hasSerialNumber}
				falseText={strings.hasNoSerialNumber}
				selected={params.hasSerialNumber}
				setSelected={value => onFilter({ ...params, hasSerialNumber: value })}
				icon={<HelpOutlineIcon />}
			/>
			{data.categories.length > 0 && (
				<StringListFilterComponent
					label={strings.category}
					selected={params.categories}
					options={data.categories}
					icon={<HelpOutlineIcon />}
					setSelected={value => onFilter({ ...params, categories: value })}
				/>
			)}
			{data.subcategories.length > 0 && (
				<StringListFilterComponent
					label={strings.subcategory}
					selected={params.subcategories}
					options={data.subcategories}
					icon={<HelpOutlineIcon />}
					setSelected={value => onFilter({ ...params, subcategories: value })}
				/>
			)}
			{data.manufacturers.length > 0 && (
				<StringListFilterComponent
					label={strings.manufacturers}
					selected={params.manufacturers}
					options={data.manufacturers}
					icon={<HelpOutlineIcon />}
					setSelected={value => onFilter({ ...params, manufacturers: value })}
				/>
			)}
		</FilterBar>
	);
};
