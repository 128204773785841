import { DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { DialogX } from 'framework/dialogs/DialogX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { suppliersQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SupplierDetailsContent } from './SupplierDetailsContent';

interface IProps extends DialogProps {
	close: VoidFunction;
	id: string;
}

export const SupplierDialog = ({ id, close, ...rest }: IProps) => {
	const strings = useLocalization();
	const [supplier, reload] = useApiEffect(suppliersQuery_single, id);
	const { isStacked } = useDialogsContext();

	if (supplier === undefined) {
		return <></>;
	}

	return (
		<DialogX
			{...rest}
			open={rest.open && isStacked === false}>
			<DialogTitle>{strings.detailsOfSupplier}</DialogTitle>
			<DialogContent
				dividers
				style={{ paddingBottom: 24 }}>
				<CloseButtonOnAbsolutePosition onClick={close} />
				<SupplierDetailsContent
					item={supplier}
					reload={reload}
				/>
			</DialogContent>
		</DialogX>
	);
};
