import { Theme } from '@material-ui/core';
import { ColorX } from './ColorX';

export const mapColorXToThemeColor = (color: ColorX, theme: Theme, usePrimaryForInfo?: boolean): string => {
	if (color === 'default') {
		return '#e0e0e0';
	} else if (color === 'primary') {
		return theme.palette.primary.main;
	} else if (color === 'secondary') {
		return theme.palette.secondary.main;
	} else if (color === 'info') {
		return usePrimaryForInfo === true ? theme.palette.primary.main : '#e0e0e0';
	} else if (color === 'error') {
		return theme.palette.error.main;
	} else if (color === 'success') {
		return theme.palette.success.main;
	} else if (color === 'warning') {
		return theme.palette.warning.main;
	} else {
		return '#e0e0e0';
	}
};
