import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, useTheme } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { SortableTable } from 'framework/table/SortableTable';
import {
	IInventoryItem,
	IInventoryItemWithProductCatalogItemDto,
	inventoryItemsQuery_queryByIdsWithProductCatalogItem,
	inventoryItemsQuery_reservedByPatient,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { createHeaders } from './createHeaders';
import { IInventoryItemWithSelection } from './IInventoryItemWithSelection';

interface IProps extends DialogProps {
	patientId: string;
	confirm: (vals: IInventoryItemWithProductCatalogItemDto[]) => void;
	cancel: VoidFunction;
	canSelect: (val: IInventoryItem) => boolean;
}

export const SelectedReservedArticles = ({ patientId, confirm, cancel, canSelect, ...rest }: IProps) => {
	const strings = useLocalization();
	const [items] = useApiEffect(inventoryItemsQuery_reservedByPatient, patientId);
	const theme = useTheme();
	const [lines, setLines] = useState<IInventoryItemWithSelection[]>([]);
	// eslint-disable-next-line
	const headers = useMemo(() => createHeaders(lines, setLines, canSelect, strings), [lines, setLines, strings]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const loadInventoryItems = useApiCall(inventoryItemsQuery_queryByIdsWithProductCatalogItem);

	useEffect(() => {
		if (items) {
			setLines(items.map(t => ({ ...t, isSelected: canSelect(t) })));
		}
		// eslint-disable-next-line
	}, [items]);

	if (items === undefined) {
		return <div></div>;
	}

	const onConfirm = async () => {
		setIsSubmitting(true);
		const r = await loadInventoryItems(lines.filter(t => t.isSelected).map(t => t.id));
		if (r.isSuccess) {
			setIsSubmitting(false);
			confirm(r.result);
		} else {
			setIsSubmitting(false);
		}
	};

	return (
		<Dialog
			{...rest}
			PaperProps={{ style: { minWidth: 400 } }}>
			<DialogTitle>{strings.selectWhat(strings.reservedArticles)}</DialogTitle>
			<DialogContent
				dividers
				style={{ padding: 0 }}>
				<SortableTable<IInventoryItemWithSelection>
					padding={theme.spacing(1)}
					headers={headers}
					getKey={t => t.id}
					values={lines}
					defaultSortParams={{ property: 'serialNumber', direction: 'asc' }}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<LoaderButton
					isLoading={isSubmitting}
					onClick={onConfirm}
					disabled={lines.filter(t => t.isSelected).length === 0}
					color='primary'>
					{strings.selectWhat(`(${lines.filter(t => t.isSelected).length})`)}
				</LoaderButton>
			</DialogActions>
		</Dialog>
	);
};
