import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';
import { notificationsCommand_markAsRead, notificationsQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps extends DialogProps {
	id: string;
	close: VoidFunction;
}

export const NotificationDialog = ({ id, close, ...rest }: IProps) => {
	const [item] = useApiEffect(notificationsQuery_single, id);
	const [markAsRead, isSubmitting] = useFormSubmit(notificationsCommand_markAsRead);
	const openInNewTab = useOpenInNewTab();
	const strings = useLocalization();

	if (item === undefined) {
		return <div></div>;
	}

	const onMarkAsRead = async () => {
		const r = await markAsRead({ notificationId: id });
		if (r.isSuccess) {
			close();
		}
	};

	return (
		<Dialog
			{...rest}
			maxWidth='xl'
			PaperProps={{ style: { minWidth: 400 } }}
			onClose={close}>
			<CloseButtonOnAbsolutePosition onClick={close} />
			<DialogTitle>{item.title}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<div style={{ whiteSpace: 'pre-line' }}>{item.description}</div>
				{item.hasReadMoreLink && (
					<div
						className='df-row-ac jc-s'
						style={{ marginTop: 16 }}>
						<SmallPrimaryTextButton
							startIcon={<OpenInNewIcon />}
							onClick={() => openInNewTab(item.readMoreLink!, true)}>
							{strings.moreInformation}
						</SmallPrimaryTextButton>
					</div>
				)}
			</DialogContent>
			<DialogActions>
				<LoaderButton
					onClick={onMarkAsRead}
					isLoading={isSubmitting}
					color='primary'>
					{strings.markAsRead}
				</LoaderButton>
			</DialogActions>
		</Dialog>
	);
};
