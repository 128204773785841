import React, { useMemo, useState } from 'react';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IFile } from 'framework/utils/IFile';
import {
	INhaxValidateResponse,
	patientsNhaxCommand_newResumeWithNhax,
	patientsNhaxCommand_updateResumeWithNhax,
	patientsNhaxCommand_validateNhaxFile,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { UploadFileDialog } from 'shared/dialogs/upload/UploadFileDialog';
import { ISelectAudiologistLocationAndLanguageModel } from 'shared/forms/ISelectAudiologistLocationAndLanguageModel';
import { SelectAudiologistLocationAndLanguageForm } from 'shared/forms/SelectAudiologistLocationAndLanguageForm';
import { PasswordForm } from './PasswordForm';

interface IProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

type Mode = 'file' | 'password' | 'selectAudiologistAndLocation' | 'warning';

export const CreateFromNhaxFile = ({ cancel, confirm }: IProps) => {
	const strings = useLocalization();
	const [validate, isValidating] = useFormSubmit(patientsNhaxCommand_validateNhaxFile);
	const [validateResponse, setValidateResponse] = useState<INhaxValidateResponse>();
	const [updateResume, isUpdateResuming] = useFormSubmit(patientsNhaxCommand_updateResumeWithNhax);
	const [createResume, isCreateResuming] = useFormSubmit(patientsNhaxCommand_newResumeWithNhax);
	const [mode, setMode] = useState<Mode>('file');
	const notify = useSnackbarNotify();
	const [file, setFile] = useState<IFile>();
	const isBusy = useMemo(() => isValidating || isUpdateResuming || isCreateResuming, [isValidating, isUpdateResuming, isCreateResuming]);
	const [hasInvalidPassword, setHasInvalidPassword] = useState<boolean>(false);

	const onFile = async (file: IFile) => {
		if (file.fileName.indexOf('.enhax') > -1) {
			setFile(file);
			setMode('password');
		} else {
			onValidate(file);
		}
	};

	const onValidate = async (file: IFile, password: string | undefined = undefined) => {
		const r = await validate({ ...file, password: password });
		if (r.isSuccess) {
			setValidateResponse(r.result);
			if (r.result.error) {
				notify(r.result.error, 'error');
				cancel();
			} else if (r.result.invalidPassword) {
				setHasInvalidPassword(false);
				setMode('password');
				setHasInvalidPassword(true);
			} else if (r.result.warning) {
				setMode('warning');
			} else if (r.result.hasExistingPatient) {
				await onResumeWithPatient(r.result.patientId!, r.result.resumeWithFileId);
			} else {
				setMode('selectAudiologistAndLocation');
			}
		}
	};

	const onResumeWithPatient = async (patientId: string, resumeId: string) => {
		const r = await updateResume(patientId, resumeId);
		if (r.isSuccess) {
			notify(strings.nhaxDataReadAndAddedToThisPatient);
			confirm(r.result.objectId);
		}
		return r;
	};

	const onResumeNew = async (resumeId: string, values: ISelectAudiologistLocationAndLanguageModel) => {
		const r = await createResume({ resumeId: resumeId, ...values });
		if (r.isSuccess) {
			notify(strings.patientCreated);
			confirm(r.result.objectId);
		}
	};

	if (mode === 'file') {
		return (
			<UploadFileDialog
				open
				title={strings.selectNhaxOrEnhaxFile}
				caption={strings.selectNhaxOrEnhaxFileCaption}
				confirm={onFile}
				isUploading={isBusy}
				cancel={cancel}
				accept='nhaxOrEnhax'
			/>
		);
	} else if (mode === 'password' && file) {
		return (
			<PasswordForm
				open
				title={strings.providePassword}
				caption={strings.thisFileIsPasswordProtected}
				confirm={pw => onValidate(file, pw)}
				isSubmitting={isBusy}
				cancel={cancel}
				hasInvalidPassword={hasInvalidPassword}
			/>
		);
	} else if (mode === 'warning' && validateResponse && validateResponse.hasExistingPatient) {
		return (
			<AlertDialog
				open
				content={validateResponse.warning!}
				acceptText={strings.proceed}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => onResumeWithPatient(validateResponse.patientId!, validateResponse.resumeWithFileId)}
				accept={() => {}}
			/>
		);
	} else if (mode === 'warning' && validateResponse && validateResponse.hasExistingPatient === false) {
		return (
			<AlertDialog
				open
				content={validateResponse.warning!}
				acceptText={strings.proceed}
				rejectText={strings.cancel}
				reject={cancel}
				accept={() => setMode('selectAudiologistAndLocation')}
			/>
		);
	} else if (mode === 'selectAudiologistAndLocation' && validateResponse) {
		return (
			<SelectAudiologistLocationAndLanguageForm
				open
				confirm={values => onResumeNew(validateResponse.resumeWithFileId, values)}
				cancel={cancel}
				isSubmitting={isBusy}
			/>
		);
	} else {
		return <div></div>;
	}
};
