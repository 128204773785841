import { TextLink } from 'framework/components/TextLink';
import { FormAutocompleteFieldFromStrings } from 'framework/forms/FormAutocompleteFieldFromStrings';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IRecupelItemDto } from 'gen/ApiClient';
import React from 'react';
import { RecupelCacheContext } from '../context/RecupelCacheContext';
import { useViewDocumentation } from '../utils/useViewDocumentation';
import { useLocalization } from 'localization/useLocalization';

const toOptionLabel = (id: string, data: IRecupelItemDto[]) => {
	const f = data.find(t => t.code === id);
	return f ? `${f.subCategoryDescription} (${formatCurrency(f.currentPriceInVat)})` : '';
};

export const FormSelectRecupelField = <TModel extends unknown>({ name, label, ...rest }: IFormProps<TModel>) => {
	const strings = useLocalization();
	const [data] = useCacheContext(RecupelCacheContext);
	const onViewRecupelTarifs = useViewDocumentation('recupel-tarifs');

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<FormAutocompleteFieldFromStrings<TModel>
			{...rest}
			name={name}
			label={label}
			options={data.map(t => t.code!)}
			renderOption={id => toOptionLabel(id, data)}
			style={{ marginBottom: 0 }}
			helperText={
				<TextLink
					text={strings.viewTarifs}
					onClick={onViewRecupelTarifs}
				/>
			}
		/>
	);
};
