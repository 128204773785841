import React from 'react';
import { IInventoryItem, IInventoryItemsQueryParamsForSale, IProductCatalogItem, IProductCatalogQueryParamsForSale, IQueryResult } from 'gen/ApiClient';

export interface IContext {
	pcParams: IProductCatalogQueryParamsForSale;
	setPcParams: React.Dispatch<React.SetStateAction<IProductCatalogQueryParamsForSale>>;
	pcQueryResult: IQueryResult<IProductCatalogItem> | undefined;
	iiParams: IInventoryItemsQueryParamsForSale;
	setIIParams: React.Dispatch<React.SetStateAction<IInventoryItemsQueryParamsForSale>>;
	iiQueryResult: IQueryResult<IInventoryItem> | undefined;
}

export const SearchContext = React.createContext<IContext>(undefined as any);
