import { CardProps } from '@material-ui/core';
import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { CardX } from 'framework/components/cards/CardX';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IInvoiceForwardingQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps extends CardProps {
	params: IInvoiceForwardingQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IInvoiceForwardingQueryParams>>;
}

export const InvoiceForwardingRecordsListFilterCard = ({ params, setParams, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const onFilter = (pars: IInvoiceForwardingQueryParams) => setParams(onResetToPage1(pars));

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<RadioIsSelectedButton
				isSelected={params.prefilter === 'All'}
				onClick={() => onFilter({ ...params, prefilter: 'All' })}>
				{strings.all}
			</RadioIsSelectedButton>
			<RadioIsSelectedButton
				isSelected={params.prefilter === 'NotIgnoredNorForwarded'}
				onClick={() => onFilter({ ...params, prefilter: 'NotIgnoredNorForwarded' })}>
				{strings.toProcess}
			</RadioIsSelectedButton>
		</CardX>
	);
};
