import { Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { TypographyBoldCaption } from 'framework/components/typography/TypographyBoldCaption';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { entPhysiciansCommand_addOffice, entPhysiciansCommand_delete, entPhysiciansCommand_restore, IEntPhysician } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { OfficeForm } from 'shared/forms/OfficeForm';
import { useViewDocumentation } from 'shared/utils/useViewDocumentation';
import { EntPhysicianOfficeListItem } from './EntPhysicianOfficeListItem';
import { PatchEntPhysicianPersonalInformationForm } from './forms/PatchEntPhysicianPersonalInformationForm';
import { PatchEntPhysicianRizivNumberForm } from './forms/PatchEntPhysicianRizivNumberForm';

interface IProps {
	item: IEntPhysician;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const EntPhysicianDetailsContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const onViewRizivNumberingInfo = useViewDocumentation('riziv-numbering-info');

	const onPatchDetails = () => {
		open(
			<PatchEntPhysicianPersonalInformationForm
				open
				entPhysician={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onPatchRizivNumber = () => {
		open(
			<PatchEntPhysicianRizivNumberForm
				open
				confirm={confirm}
				cancel={cancel}
				entPhysician={item}
			/>
		);
	};

	const onAddOffice = () => {
		open(
			<OfficeForm
				open
				submitFunction={model => entPhysiciansCommand_addOffice({ entPhysicianId: item.id, office: model })}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.entPhysician)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => entPhysiciansCommand_delete({ entPhysicianId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onRestore = () => {
		open(
			<AlertDialog
				open
				title={strings.undoDelete}
				content={strings.doYouWantToMakeThisEntPhysicianAvailableAgainQuestion}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => entPhysiciansCommand_restore({ entPhysicianId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<div
			className='df-col gap-8'
			style={style}>
			<CaptionVerticalLabelledPropertyX
				label={strings.name}
				edit={onPatchDetails}>
				{item.identifier}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.RIZIVNumber}
				edit={onPatchRizivNumber}
				info={onViewRizivNumberingInfo}>
				{item.conventionedRizivNumber}
			</CaptionVerticalLabelledPropertyX>
			{item.hasOffices && (
				<>
					<TypographyBoldCaption style={{ marginBottom: 0 }}>{strings.locations}</TypographyBoldCaption>
					{item.offices.map((t, index) => (
						<EntPhysicianOfficeListItem
							item={t}
							index={index + 1}
							entPhysician={item}
							reload={reload}
						/>
					))}
				</>
			)}
			<div className='fg1'></div>
			<Divider style={{ marginBottom: 4 }} />
			<div className='df-col gap-8'>
				<TextLinkButton
					startIcon={<AddIcon />}
					onClick={onAddOffice}>
					{strings.addLocation}
				</TextLinkButton>
				{item.isDeleted === false && (
					<TextLinkButton
						startIcon={<DeleteOutlineOutlinedIcon />}
						hoverColor='secondary'
						onClick={onDelete}>
						{strings.delete}
					</TextLinkButton>
				)}
				{item.isDeleted && (
					<TextLinkButton
						startIcon={<SettingsBackupRestoreOutlinedIcon />}
						color='secondary'
						hoverColor='secondary'
						onClick={onRestore}>
						{strings.restoreVerb}
					</TextLinkButton>
				)}
			</div>
		</div>
	);
};
