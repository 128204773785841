import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { HearingInstrumentMode } from './HearingInstrumentMode';

interface IProps extends DialogProps {
	confirm: (mode: HearingInstrumentMode) => void;
	cancel: VoidFunction;
	jsxTitle: JSX.Element;
	previous?: VoidFunction;
}

export const HearingInstrumentModeForm = ({ confirm, cancel, jsxTitle, previous, ...rest }: IProps) => {
	const strings = useLocalization();
	const [mode, setMode] = useState<HearingInstrumentMode>('manual');

	return (
		<>
			<Dialog
				{...rest}
				scroll='paper'
				fullWidth>
				<CloseButtonOnAbsolutePosition onClick={cancel} />
				<DialogTitle>{jsxTitle}</DialogTitle>
				<DialogContent
					className='df-col'
					dividers>
					<ExtendedRadioGroup<HearingInstrumentMode>
						selected={mode}
						setSelected={setMode}
						options={[
							{ value: 'manual', label: strings.manual, caption: strings.fillMfrModelInYourself },
							{ value: 'readouts', label: 'Noah', caption: strings.fromPreviousReadOutsFromNoah },
							{ value: 'riziv', label: strings.RIZIV, caption: strings.selectADeviceFromRIZIVLimitativeList },
						]}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={cancel}
						color='primary'>
						{strings.cancel}
					</Button>
					{previous && (
						<Button
							onClick={previous}
							color='primary'>
							{strings.previous}
						</Button>
					)}
					<Button
						onClick={() => confirm(mode)}
						color='primary'>
						{strings.next}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
