import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useMemo } from 'react';
import { IPurchaseFromUblFormLine } from './IPurchaseFromUblFormLine';
import { PurchaseFromUblLineLinkStatusPopoverContent } from './PurchaseFromUblLineLinkStatusPopoverContent';
import { calculatePurchaseUblLinkStatus } from './utils';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	line: IPurchaseFromUblFormLine;
	setLine: (val: IPurchaseFromUblFormLine) => void;
	onLink: VoidFunction;
	onIgnore: VoidFunction;
}

export const PurchaseFromUblLineLinkStatusComponent = ({ line, setLine, onLink, onIgnore }: IProps) => {
	const strings = useLocalization();
	const popupState = usePopupState({
		variant: 'popover',
		popupId: `ubl-line-${line.id!}`,
	});
	const linkStatus = useMemo(() => calculatePurchaseUblLinkStatus(line), [line]);

	const onRemoveLink = () => {
		setLine({ ...line, productCatalogItem: undefined });
	};

	const onUndoIgnore = () => {
		setLine({ ...line, ublData: { ...line.ublData!, isIgnored: false } });
	};

	if (Boolean(line.ublData) === false || line.ublData === undefined) {
		return <></>;
	}

	return (
		<>
			<HoverPopover
				elevation={1}
				{...bindPopover(popupState)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}>
				<PurchaseFromUblLineLinkStatusPopoverContent
					onLink={onLink}
					onIgnore={onIgnore}
					onRemoveLink={onRemoveLink}
					onUndoIgnore={onUndoIgnore}
					linkStatus={linkStatus}
					line={line}
				/>
			</HoverPopover>
			<div
				{...bindHover(popupState)}
				style={{ width: 'fit-content' }}>
				<VariantLabelledPropertyX variant={linkStatus === 'linked' ? 'success' : linkStatus === 'ignored' ? 'warning' : 'error'}>
					{linkStatus === 'linked' && strings.articleFromProductCatalogFoundWithReference(line.ublData.manufacturerReference ?? '')}
					{linkStatus === 'ignored' && strings.thisLineWillBeIgnored}
					{linkStatus === 'not-linked' && strings.articleNotFoundInProductCatalogWithReference(line.ublData.manufacturerReference ?? '')}
				</VariantLabelledPropertyX>
			</div>
		</>
	);
};
