import { IAdvanceInvoiceLineModel } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const lineToModel = (line: ILine, index: number): IAdvanceInvoiceLineModel => {
	return {
		zeroBasedIndexNumber: index,
		quantity: line.quantity,
		unitListPriceInVat: line.unitListPrice,
		unitPriceInVat: line.unitPrice,
		vatPercentage: line.vatPercentage,
		description: line.description,
	};
};
