import { Button, Card, CardContent, Divider } from '@material-ui/core';
import { RosaEventListComponent } from 'app/main/rosaCalendar/list/RosaEventListComponent';
import { useRosaRedirect } from 'app/main/rosaCalendar/useRosaRedirect';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { CalendarPlusIcon } from 'framework/components/icons/CalendarPlusIcon';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { isLast } from 'framework/utils/array/isLast';
import { rosaEventsQuery_todayForUser } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { getNow } from 'shared/utils/getNow';

interface IProps {
	className?: string;
	style?: React.CSSProperties;
	reloadTrigger: Trigger;
}

export const RosaTodayEventsCard = ({ className, style, reloadTrigger }: IProps) => {
	const [events, reload] = useApiEffect(rosaEventsQuery_todayForUser);
	const strings = useLocalization();
	const rRedirect = useRosaRedirect();

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	const onNew = () => {
		rRedirect({ date: getNow() });
	};

	return (
		<Card
			className={className}
			style={style}>
			<CardTitleTypography
				title={`${strings.appointments} ${strings.today.toLowerCase()}`}
				withPadding
				action={
					<Button
						variant='text'
						size='small'
						color='primary'
						startIcon={<CalendarPlusIcon />}
						onClick={onNew}>
						{strings.new}
					</Button>
				}
			/>
			{events !== undefined && (
				<>
					{events.length === 0 && (
						<CardContent style={{ paddingTop: 0, paddingBottom: events.length === 0 ? 16 : 0 }}>
							<NoRecordsFound>{strings.noAppointmentsPlanned}</NoRecordsFound>
						</CardContent>
					)}
					{events.length !== 0 && (
						<CardContent style={{ padding: 0 }}>
							{events.map(event => (
								<React.Fragment key={event.id}>
									<RosaEventListComponent
										event={event}
										mode='todayEventsCard'
									/>
									{isLast(event, events) === false && <Divider />}
								</React.Fragment>
							))}
						</CardContent>
					)}
				</>
			)}
		</Card>
	);
};
