import { Typography } from '@material-ui/core';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { StepX } from './StepX';

interface IProps {
	reason?: string;
}

export const CancelledProcessStepX = ({ reason }: IProps) => {
	const strings = useLocalization();
	return (
		<StepX
			color='error'
			status='done'
			isLast>
			<span>{strings.cancelled}</span>
			<Typography variant='caption'>{reason ?? <em>{strings.notApplicableAbbreviation}</em>}</Typography>
		</StepX>
	);
};
