import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { calculateCashDeskRoute } from 'routes';
import { SelectPatientByIdForm } from 'shared/patientSearch/SelectPatientByIdForm';
import { AdvanceInvoiceForm } from './advanceInvoice/AdvanceInvoiceForm';
import { InvoiceForForm } from './invoice/InvoiceForForm';
import { InvoiceModelForm } from './invoice/InvoiceModelForm';
import { SaleType } from './SaleType';
import { SaleTypeForm } from './SaleTypeForm';

interface IProps {
	confirm: (id?: string) => void;
	cancel: VoidFunction;
	forPatientId?: string | undefined;
}

type State = 'type' | 'for' | 'patient' | 'sale';

export const SaleForm = ({ cancel, confirm, forPatientId }: IProps) => {
	const [state, setState] = useState<State>('type');
	const [type, setType] = useState<SaleType>('invoice');
	const [patientId, setPatientId] = useState<string | undefined>(forPatientId);
	const { push } = useHistory();

	const confirmType = (type: SaleType) => {
		if (type === 'cashSale') {
			cancel();
			push(calculateCashDeskRoute(patientId));
		} else {
			setType(type);
			setState(patientId !== undefined ? 'sale' : 'for');
		}
	};

	const confirmFor = (isForPatient: boolean) => {
		setState(isForPatient ? 'patient' : 'sale');
		setPatientId(undefined);
	};

	const confirmPatient = (patientId: string) => {
		setPatientId(patientId);
		setState('sale');
	};

	if (state === 'type') {
		return (
			<SaleTypeForm
				open
				confirm={confirmType}
				cancel={cancel}
			/>
		);
	} else if (state === 'for') {
		return (
			<InvoiceForForm
				open
				confirm={confirmFor}
				previous={() => setState('type')}
				cancel={cancel}
			/>
		);
	} else if (state === 'patient') {
		return (
			<SelectPatientByIdForm
				open
				confirm={confirmPatient}
				cancel={cancel}
				previous={() => setState('for')}
			/>
		);
	} else if (state === 'sale' && type === 'invoice') {
		return (
			<InvoiceModelForm
				open
				confirm={confirm}
				cancel={cancel}
				patientId={patientId}
			/>
		);
	} else if (state === 'sale' && type === 'advanceInvoice') {
		return (
			<AdvanceInvoiceForm
				open
				confirm={confirm}
				cancel={cancel}
				patientId={patientId}
			/>
		);
	} else {
		return <div></div>;
	}
};
