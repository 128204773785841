import React from 'react';
import { ExactOnlineCustomerAccountsProviders } from './customerAccounts/ExactOnlineCustomerAccountsProviders';
import { ExactOnlineProductCatalogItemsProviders } from './productCatalog/ExactOnlineProductCatalogItemsProviders';
import { ExactOnlineSalesProviders } from './sales/ExactOnlineSalesProviders';

export const ExactOnlineProviders = ({ children }: any) => {
	return (
		<ExactOnlineCustomerAccountsProviders>
			<ExactOnlineProductCatalogItemsProviders>
				<ExactOnlineSalesProviders>{children}</ExactOnlineSalesProviders>
			</ExactOnlineProductCatalogItemsProviders>
		</ExactOnlineCustomerAccountsProviders>
	);
};
