import React from 'react';
import { useLocalStorage } from 'framework/hooks/useLocalStorage';
import { IUserInfo } from 'gen/ApiClient';
import { hamsLocationIdLsKey, hamsUserInfoLsKey } from '../localStorageKeys';
import { AuthContext } from './AuthContext';

export const AuthProvider = ({ children }: any) => {
	const [user, setUser] = useLocalStorage<IUserInfo>(hamsUserInfoLsKey);
	const [locationId, setLocationId] = useLocalStorage<string>(hamsLocationIdLsKey);

	return (
		<AuthContext.Provider
			value={{
				isAuth: user !== undefined,
				user: user,
				login: user => {
					setUser(user);
					setLocationId(user.preferredLocationId);
				},
				logout: () => {
					setUser(undefined);
					setLocationId(undefined);
				},
				onRedirect: () => setUser(undefined),
				locationId: locationId,
				switchLocation: locationId => setLocationId(locationId),
			}}>
			{children}
		</AuthContext.Provider>
	);
};
