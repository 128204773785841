import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { FinancialsPageBreadcrumbTitle } from '../FinancialsPageBreadcrumbTitle';
import { CustomerAccountsRoute } from '../routes';

export const CustomerAccountsPageBreadcrumbs = () => {
	const strings = useLocalization();

	return (
		<React.Fragment>
			<FinancialsPageBreadcrumbTitle />
			<PageBreadcrumbDivider />
			<PageBreadcrumb
				text={strings.accountsReceivable}
				route={CustomerAccountsRoute}
			/>
		</React.Fragment>
	);
};
