import { IQuickFilterContext } from 'framework/IQuickFilterContext';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { CustomerAccountExportProp, ICustomerAccount } from 'gen/ApiClient';
import React from 'react';
import { QuickFilter } from '../CustomerAccountsList';
import { SelectableColumn } from '../SelectableColumn';

interface IViewContext extends ITableViewContext<SelectableColumn, ICustomerAccount>, IQuickFilterContext<QuickFilter> {
	exportRecord: Record<CustomerAccountExportProp, string>;
}

export const ViewContext = React.createContext<IViewContext>(undefined as any);
