import { IStrings } from 'localization/IStrings';
import { StockMovementsSelectableColumn } from './StockMovementsSelectableColumn';

export const createStockMovementsColumnRecord = (strings: IStrings): Record<StockMovementsSelectableColumn, string> => {
	return {
		created: strings.createdAt,
		lastUpdated: strings.lastUpdated,
		countItems: `# ${strings.items}`,
		reference: strings.referenceShort,
		fromLocationIdentifier: strings.fromLocation,
		toLocationIdentifier: strings.toLocation,
	};
};
