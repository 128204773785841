import { IObjectWithIdAndIdentifier } from 'gen/ApiClient';
import React, { useState } from 'react';
import { SelectPatientAndLoadSearchForm } from 'shared/patientSearch/SelectPatientAndLoadSearchForm';
import { RegisterArticlesInquiryForm } from './RegisterArticlesInquiryForm';

interface IProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const RegisterArticlesInquiryDialog = ({ cancel, confirm }: IProps) => {
	const [patient, setPatient] = useState<IObjectWithIdAndIdentifier<string>>();

	const confirmPatient = (value: IObjectWithIdAndIdentifier<string>) => {
		setPatient(value);
	};

	if (patient === undefined) {
		return (
			<SelectPatientAndLoadSearchForm
				open
				cancel={cancel}
				confirm={confirmPatient}
			/>
		);
	} else {
		return (
			<RegisterArticlesInquiryForm
				open
				confirm={confirm}
				cancel={cancel}
				patient={patient}
			/>
		);
	}
};
