import { useTheme } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DoneIcon from '@material-ui/icons/Done';
import ListIcon from '@material-ui/icons/List';
import { ISalesByPatientResponse } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { SaleByPatientStatCard } from './SaleByPatientStatCard';

interface IProps {
	data: ISalesByPatientResponse;
	style?: React.CSSProperties;
}

export const SalesByPatientStats = ({ data, style }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();

	return (
		<div
			className='df-row-wrap gap-16'
			style={style}>
			<SaleByPatientStatCard
				title={strings.total}
				icon={<ListIcon />}
				count={data.count}
				amount={data.totalValueInVat}
				color={theme.palette.primary}
				style={{ flexGrow: 1 }}
			/>
			<SaleByPatientStatCard
				title={strings.paid}
				icon={<DoneIcon />}
				count={data.countPaid}
				amount={data.amountPaid}
				color={theme.palette.success}
				style={{ flexGrow: 1 }}
			/>
			<SaleByPatientStatCard
				title={strings.outstanding}
				icon={<AttachMoneyIcon />}
				count={data.countWithOutstandingBalance}
				amount={data.outstandingBalance}
				color={theme.palette.error}
				style={{ flexGrow: 1 }}
			/>
		</div>
	);
};
