import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { ITrialReport, trialReportsCommand_deleteSpeechImages, trialReportsQuery_singleSpeech } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SpeechImagesForm } from './SpeechImagesForm';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { SpeechImagesList } from './SpeechImagesList';
import { useApiEffect } from 'framework/hooks/useApiEffect';

interface IProps extends DialogProps {
	patientId: string;
	close: VoidFunction;
	trialReport: ITrialReport;
}

export const SpeechImagesDialog = ({ patientId, trialReport, close, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [images] = useApiEffect(trialReportsQuery_singleSpeech, trialReport.id);
	const { open, confirm, cancel, isStacked } = useDialogsContext(close);

	if (images === undefined) {
		return <div></div>;
	}

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.graphs)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => trialReportsCommand_deleteSpeechImages(trialReport.id)}
				accept={() => {
					confirm();
					close();
				}}
			/>
		);
	};

	const onEdit = () => {
		open(
			<SpeechImagesForm
				open
				trialId={trialReport.id}
				patientId={patientId}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			maxWidth='sm'
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<GroupWorkIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{strings.speechGraphs}
					</Typography>
				</DialogGridTitle>
				<SpeechImagesList images={images} />
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								onClick={onEdit}
								startIcon={<EditIcon />}>
								{strings.edit}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={onDelete}
								color='secondary'
								startIcon={<DeleteOutlineIcon />}>
								{strings.delete}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
