import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { formatDate } from 'framework/utils/date/formatDate';
import { IStockMovement } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: IStockMovement;
}

export const StockMovementDetailPageSidebar = ({ item }: IProps) => {
	const strings = useLocalization();
	return (
		<div
			className='df-col gap-6 h100'
			style={{
				padding: 16,
				paddingRight: 8,
				minWidth: 200,
			}}>
			<CaptionVerticalLabelledPropertyX label={strings.reference}>{item.reference}</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.entryDate}>{formatDate(item.created)}</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.toLocation}>{item.toLocationIdentifier}</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.fromLocation}>{item.fromLocationIdentifier}</CaptionVerticalLabelledPropertyX>
		</div>
	);
};
