import { DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	IMedicalTreatmentFeeDto,
	IRegisterRfiaProformaHandoverRequest,
	IRequestForInsuranceAllowance,
	rfiasHandoverCommand_registerHandoverProforma,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { SearchProvider } from './context/SearchProvider';
import { FeesTable } from './FeesTable';
import { IHandoverLine } from './IHandoverLine';
import { SearchAndSelectProductsForHandover } from './SearchAndSelectProductsForHandover';
import { lineToModel } from './utils/lineToModel';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterRfiaProformaHandoverRequest>({
			handoverDate: yup.date(),
			lines: yup.mixed(),
			fees: yup.mixed(),
			rfiaId: yup.string().required(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IRegisterRfiaProformaHandoverRequest)[]> = {
	0: ['handoverDate', 'fees'],
	1: ['lines'],
};

interface IProps extends DialogProps {
	confirm: (date: Date | null | undefined) => void;
	cancel: VoidFunction;
	rfia: IRequestForInsuranceAllowance;
}

export const ProformaHandoverModelForm = ({ confirm, cancel, rfia, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(rfiasHandoverCommand_registerHandoverProforma);
	const [step, setStep] = useState<number>(0);
	const [lines, setLines] = useState<IHandoverLine[]>([]);
	const [fees, setFees] = useState<IMedicalTreatmentFeeDto[]>([]);
	const schema = useMemo(() => createSchema(strings), [strings]);
	const now = useNow();

	const handleSubmit = async (values: IRegisterRfiaProformaHandoverRequest, helpers: FormikHelpers<IRegisterRfiaProformaHandoverRequest>) => {
		// TODO meer controleren! -> max. value of stockItems will be max value available at location!
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else if (lines.filter(t => t.productCatalogItem && t.productCatalogItem.hasSerialNumber && t.inventoryItem === undefined).length > 0) {
			notify(strings.selectSerialNumbersForSelectedProducts, 'error');
		} else {
			const r = await create({ ...values, lines: lines.map((t, index) => lineToModel(t, index)), fees: fees });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify(strings.handoverRegistered);
				confirm(values.handoverDate);
			}
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterRfiaProformaHandoverRequest>
			validateOnMount
			initialValues={{
				rfiaId: rfia.id,
				lines: [],
				fees: [],
				handoverDate: now,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider patientId={rfia.patientId}>
					<InnerDialog
						step={step}
						setStep={setStep}
						lines={lines}
						setLines={setLines}
						cancel={cancel}
						isSubmitting={isSubmitting}
						schema={schema}
						rfia={rfia}
						fees={fees}
						setFees={setFees}
						{...rest}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	lines: IHandoverLine[];
	setLines: React.Dispatch<React.SetStateAction<IHandoverLine[]>>;
	isSubmitting: boolean;
	cancel: VoidFunction;
	schema: yup.ObjectSchema<IRegisterRfiaProformaHandoverRequest>;
	rfia: IRequestForInsuranceAllowance;
	fees: IMedicalTreatmentFeeDto[];
	setFees: (fees: IMedicalTreatmentFeeDto[]) => void;
}

const InnerDialog = ({ rfia, step, lines, setLines, setStep, isSubmitting, cancel, schema, fees, setFees, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IRegisterRfiaProformaHandoverRequest>();
	const strings = useLocalization();

	return (
		<FullScreenDialogWithStepper
			{...rest}
			title={`${strings.registerHandover} (${strings.proforma})`}
			step={step}
			labels={[strings.date, strings.items]}
			fullScreenStep={1}
			maxWidth='lg'>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormDatePicker<IRegisterRfiaProformaHandoverRequest>
							name='handoverDate'
							label={strings.handoverDate}
							helperText={strings.thisDateWillBeUsedToCalculateNomenclaturePrices}
						/>
						{props.values.handoverDate !== undefined && (
							<>
								<DividerWithCaption caption={strings.refunds} />
								<FeesTable
									rfia={rfia}
									handOverDate={props.values.handoverDate}
									fees={fees}
									setFees={setFees}
								/>
							</>
						)}
					</>
				)}
				{step === 1 && (
					<SearchAndSelectProductsForHandover
						rfia={rfia}
						lines={lines}
						setLines={setLines}
						fees={fees}
						mode='pro-forma'
						customerAccountId={undefined}
						handoverDate={new Date(props.values.handoverDate!)}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
				validateMore={() => (step === 0 ? fees.length === rfia.allMedicalTreatmentNomenclatureCodes.length : true)}
			/>
		</FullScreenDialogWithStepper>
	);
};
