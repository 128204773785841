import { Button, useTheme } from '@material-ui/core';
import React from 'react';

interface IProps {
	isSelected: boolean;
	onClick: VoidFunction;
	value: string;
	startIcon?: React.ReactNode;
	style?: React.CSSProperties;
	endIcon?: React.ReactNode;
}

export const TabX = ({ isSelected, onClick, style, startIcon, endIcon, value }: IProps) => {
	const theme = useTheme();

	return (
		<div
			className='df-col'
			style={{
				color: theme.palette.text.secondary,
				...style,
			}}>
			<Button
				variant='text'
				size='small'
				color={isSelected ? 'primary' : 'inherit'}
				onClick={onClick}
				startIcon={startIcon}
				endIcon={endIcon}>
				{value}
			</Button>
			<div
				style={{
					marginTop: 2,
					marginLeft: 4,
					marginRight: 4,
					height: 2,
					backgroundColor: isSelected ? theme.palette.primary.main : 'unset',
					borderRadius: '100px',
				}}
			/>
		</div>
	);
};
