import React, { useMemo, useState } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { DeliveriesSelectableColumn } from './DeliveriesSelectableColumn';
import { createColumnRecord } from './createColumnRecord';
import { createExportRecord } from './createExportRecord';
import { createHeaders } from './createHeaders';
import { DeliveriesListViewContext } from './DeliveriesListViewContext';

// enkel hier nodig
const DefaultColumns: DeliveriesSelectableColumn[] = ['reference', 'created', 'deliveryDate', 'supplierIdentifier', 'countItems'];

export const DeliveriesListViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [columns, setColumns] = useState<DeliveriesSelectableColumn[]>(DefaultColumns);
	const colRecord = useMemo(() => createColumnRecord(strings), [strings]);
	const headers = useMemo(() => createHeaders(colRecord, strings), [colRecord, strings]);
	const exportRecord = useMemo(() => createExportRecord(strings), [strings]);

	return (
		<DeliveriesListViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
				exportRecord: exportRecord,
			}}>
			{children}
		</DeliveriesListViewContext.Provider>
	);
};
