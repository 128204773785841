import React from 'react';
import { ArticlesInquiriesProviders } from './articlesInquiries/ArticlesInquiriesProviders';
import { LoansProviders } from './loans/LoansProviders';
import { OrdersProviders } from './orders/OrdersProviders';
import { RepairsProviders } from './repairs/RepairsProviders';
import { RfiaProviders } from './requestsForInsuranceAllowance/context/RfiaProviders';

export const ProcessesProviders = ({ children }: any) => {
	return (
		<LoansProviders>
			<OrdersProviders>
				<ArticlesInquiriesProviders>
					<RepairsProviders>
						<RfiaProviders>{children}</RfiaProviders>
					</RepairsProviders>
				</ArticlesInquiriesProviders>
			</OrdersProviders>
		</LoansProviders>
	);
};
