import { MbscResource } from '@mobiscroll/react';
import _ from 'lodash';
import { IRosaCalendarWithIsSelected } from '../IRosaCalendarWithIsSelected';

export const mapRosaCalendarsToMobiscrollResource = (canGroup: boolean, calendars: IRosaCalendarWithIsSelected[]) => {
	if (canGroup === false) {
		return undefined;
	} else {
		const grouped = _.groupBy(
			calendars.filter(t => t.isSelected),
			t => t.hpId
		);
		return Object.keys(grouped).map<MbscResource>(t => ({ id: grouped[t][0].hpId!, name: grouped[t][0].hpNameFromLabel!, color: grouped[t][0].hexColor }));
	}
};
