import { MbscEventcalendarView } from '@mobiscroll/react';
import { IRosaViewPreferences, RosaCalendarViewType } from 'gen/ApiClient';

export const mapRosaCalendarViewTypeToMobiscrollView = (view: RosaCalendarViewType, viewPreferences: IRosaViewPreferences): MbscEventcalendarView => {
	if (view === 'Day') {
		return {
			schedule: {
				type: 'day',
				timeCellStep: viewPreferences.timeScale,
				timeLabelStep: viewPreferences.timeScale,
				startTime: viewPreferences.showScheduleFromHourFormatted,
				endTime: viewPreferences.showScheduleToHourFormatted,
			},
		};
	} else if (view === 'Week') {
		return {
			schedule: {
				type: 'week',
				timeCellStep: viewPreferences.timeScale,
				timeLabelStep: viewPreferences.timeScale,
				startTime: viewPreferences.showScheduleFromHourFormatted,
				endTime: viewPreferences.showScheduleToHourFormatted,
				startDay: 1,
				endDay: viewPreferences.showOnlyWeekDays ? 5 : 7,
			},
		};
	} else if (view === 'Timeline') {
		return {
			timeline: {
				type: 'day',
				timeCellStep: viewPreferences.timeScale,
				timeLabelStep: viewPreferences.timeScale,
				startTime: viewPreferences.showScheduleFromHourFormatted,
				endTime: viewPreferences.showScheduleToHourFormatted,
			},
		};
	} else {
		return AgendaViewTypeToMobiscrollCalendarViewRecord[view];
	}
};

const AgendaViewTypeToMobiscrollCalendarViewRecord: Record<RosaCalendarViewType, MbscEventcalendarView> = {
	Day: { schedule: { type: 'day' } },
	Week: { schedule: { type: 'week', startTime: '09:00', endTime: '17:00', startDay: 1, endDay: 5 } },
	Month: { calendar: { type: 'month' } },
	Year: { calendar: { type: 'year' } },
	List: { agenda: { type: 'month' } },
	Timeline: { timeline: { type: 'day' } },
};
