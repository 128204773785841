import React, { useState } from 'react';
import { IRequestForInsuranceAllowance, ITenderLine } from 'gen/ApiClient';
import { ITenderLineWithSelection } from '../../../../../../financial/tenders/ITenderLineWithSelection';
import { IHandoverLine } from '../../IHandoverLine';
import { tenderLineToLine } from '../../utils/tenderLineToLine';
import { useMapLine } from '../../utils/useMapLine';
import { SelectLinesFromTenderForm } from './SelectLinesFromTenderForm';
import { SelectTenderForm } from './SelectTenderForm';

interface IProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: (lines: IHandoverLine[]) => void;
	cancel: VoidFunction;
}

export const SelectFromTenderForm = ({ rfia, confirm, cancel }: IProps) => {
	const [tenderId, setTenderId] = useState<string | undefined>(rfia.invoiceProcess.tenders.length === 1 ? rfia.invoiceProcess.tenders[0].id : undefined);
	const mapper = useMapLine<ITenderLine>();

	const onConfirm = async (lines: ITenderLineWithSelection[]): Promise<void> => {
		const mapped = await Promise.all(lines.filter(t => t.isSelected).map(t => mapper(t, (t, line) => tenderLineToLine(t, line))));
		confirm(mapped);
	};

	if (tenderId === undefined) {
		return (
			<SelectTenderForm
				open
				rfia={rfia}
				confirm={setTenderId}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<SelectLinesFromTenderForm
				open
				tenderId={tenderId}
				confirm={onConfirm}
				cancel={cancel}
			/>
		);
	}
};
