import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { ISalesQueryMetadata, SalesPrefilter, SalesPrefilters } from 'gen/ApiClient';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { createSalesPrefilterBadgeChipXRecord } from './createSalesPrefilterBadgeChipXRecord';

interface IProps {
	selectedPrefilter: SalesPrefilter;
	setSelectedPrefilter: (key: SalesPrefilter) => void;
	metadata: ISalesQueryMetadata;
	style?: React.CSSProperties;
}

export const SalesListPrefilter = ({ selectedPrefilter, setSelectedPrefilter, metadata, style }: IProps) => {
	const endIconRecord = useMemo(() => createSalesPrefilterBadgeChipXRecord(metadata), [metadata]);
	const { salesPrefilterRecord } = useContext(RecordContext);

	return (
		<div
			className='df-col'
			style={style}>
			{SalesPrefilters.map(prefilterKeyType => (
				<RadioIsSelectedButton
					key={prefilterKeyType}
					isSelected={prefilterKeyType === selectedPrefilter}
					onClick={() => setSelectedPrefilter(prefilterKeyType)}
					endIcon={endIconRecord[prefilterKeyType]}>
					{salesPrefilterRecord[prefilterKeyType]}
				</RadioIsSelectedButton>
			))}
		</div>
	);
};
