import React from 'react';
import { CacheProvider } from 'framework/context/CacheProvider';
import { ISuppliersFilterData, suppliersQuery_concurrencyToken, suppliersQuery_searchData } from 'gen/ApiClient';
import { SuppliersFilterDataContext } from './SuppliersFilterDataContext';

export const SuppliersFilterDataProvider = ({ children }: any) => {
	return (
		<CacheProvider<ISuppliersFilterData>
			concurrencyF={suppliersQuery_concurrencyToken}
			loadF={suppliersQuery_searchData}
			context={SuppliersFilterDataContext}>
			{children}
		</CacheProvider>
	);
};
