import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IPayment } from 'gen/ApiClient';

interface IPaymentLinesTableProps {
	payment: IPayment;
	style?: React.CSSProperties;
}

export const PaymentForSalesTable = ({ payment, style }: IPaymentLinesTableProps) => {
	return (
		<Table style={style}>
			{/* <colgroup>
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
                <col style={{ width: 'auto' }} />
            </colgroup> */}
			<TableHead>
				<TableRow>
					<TableCell>Datum verkoop</TableCell>
					<TableCell>Ref. verkoop</TableCell>
					<TableCell>Tav</TableCell>
					<TableCell align='right'>Amount</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{payment.lines.map(t => (
					<TableRow key={t.id}>
						<TableCell>{formatDate(t.date)}</TableCell>
						<TableCell>{t.saleOrPurchaseReference}</TableCell>
						<TableCell>{t.recipient}</TableCell>
						<TableCell align='right'>{formatCurrency(t.value)}</TableCell>
					</TableRow>
				))}
				<TableRow>
					<TableCell colSpan={2}></TableCell>
					<TableCell style={{ fontWeight: 'bold' }}>Totaal</TableCell>
					<TableCell
						colSpan={4}
						align='right'
						style={{ fontWeight: 'bold' }}>
						{formatCurrency(payment.amountPaid)}
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};
