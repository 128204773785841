import { MenuItem, Select } from '@material-ui/core';
import { createHeader } from 'framework/table/createHeader';
import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { IHeader } from 'framework/table/IHeader';
import { updateArray } from 'framework/utils/array/updateArray';
import { LanguageOption } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import React from 'react';
import { localizeApkLabel } from '../../../articlePropertyKeys/utils/localizeApkLabel';
import { apdToType } from '../../../detail/utils/apdToType';
import { MultipleValuesTextField } from '../MultipleValuesTextField';
import { ILine } from '../ILine';

export const createHeaders = (
	lines: ILine[],
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>,
	strings: IStrings,
	lang: LanguageOption
): IHeader<ILine>[] => {
	return [
		createSelectionHeader<ILine>(lines, setLines),
		createHeader<ILine>(strings.name, t => `${localizeApkLabel(t.apk, lang)} [${t.apk.key}]`),
		createHeader<ILine>(`${strings.oneOrMultipleNextLinesPossibleValues}?`, t => (
			<Select
				variant='outlined'
				SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
				style={{ width: '100%' }}
				value={t.isSelected === false ? '' : t.allowMultiple ? 'true' : 'false'}
				disabled={t.isSelected === false}
				renderValue={t => (t === 'true' ? strings.multiple : strings.exactlyOne)}>
				<MenuItem
					value='false'
					dense
					onClick={() => setLines(updateArray(t, lines, { ...t, allowMultiple: false }))}>
					{strings.exactlyOne}
				</MenuItem>
				<MenuItem
					value='true'
					dense
					onClick={() => setLines(updateArray(t, lines, { ...t, allowMultiple: true }))}>
					{strings.multiple}
				</MenuItem>
			</Select>
		)),
		createHeader<ILine>(strings.type, t => <em>{apdToType(t, strings)}</em>),
		createHeader<ILine>(strings.options, t => (
			<MultipleValuesTextField
				values={t.isSelected ? t.options : []}
				setValues={vals => setLines(updateArray(t, lines, { ...t, options: vals }))}
				style={{ width: 300 }}
				disabled={t.isSelected === false}
			/>
		)),
	];
};
