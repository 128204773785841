import { DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponseWithLines } from 'framework/forms/utils/handleFormResponseWithLines';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { deliveriesCommand_new, deliveriesQuery_nextReference, IRegisterDeliveryRequest } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { SuppliersCacheContext } from '../../../../contacts/suppliers/SuppliersCacheContext';
import { FormSelectOrCreateSupplierField } from '../../../../contacts/suppliers/forms/FormSelectOrCreateSupplierField';
import { LocationsPreloadCacheContext } from '../../../../settings/locations/LocationsPreloadCacheContext';
import { SearchProvider } from './context/SearchProvider';
import { IRegisterDeliveryLineModel } from './IRegisterDeliveryLineModel';
import { mapToRegisterDeliveryLineRequest } from './mapToRegisterDeliveryLineRequest';
import { SearchAndSelectProductsForDelivery } from './SearchAndSelectProductsForDelivery';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterDeliveryRequest>({
			deliveryDate: yup.date().required(strings.formRequired(strings.expectedDeliveryDate)),
			externalReference: yup.string(),
			lines: yup.mixed(),
			locationId: yup.string().required(strings.formRequired(strings.location)),
			supplierId: yup.string().required(strings.formRequired(strings.supplier)),
			reference: yup.string().required(),
			isAutoSelectReference: yup.boolean().defined(),
			dispatchReference: yup.string(),
			dispatchDate: yup.date(),
		})
		.defined();
};

const EmptyValues: IRegisterDeliveryRequest = {
	deliveryDate: undefined as any,
	externalReference: '',
	lines: [],
	locationId: undefined as any,
	supplierId: undefined as any,
	reference: '',
	isAutoSelectReference: true,
	dispatchDate: undefined as any,
	dispatchReference: '',
};

const stepsRecord: Record<number, (keyof IRegisterDeliveryRequest)[]> = {
	0: ['reference', 'isAutoSelectReference', 'externalReference'],
	1: ['locationId', 'supplierId'],
	2: ['deliveryDate'],
	3: ['dispatchReference', 'dispatchDate'],
	4: ['lines'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	patientId?: string;
}

export const RegisterDeliveryRequestForm = ({ confirm, cancel, patientId, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, setLines] = useState<IRegisterDeliveryLineModel[]>([]);
	const [step, setStep] = useState<number>(0);
	const [create, isSubmitting] = useFormSubmit(deliveriesCommand_new);
	const notify = useSnackbarNotify();
	const now = useNow();

	const handleSubmit = async (values: IRegisterDeliveryRequest, helpers: FormikHelpers<IRegisterDeliveryRequest>) => {
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await create({ ...values, lines: lines.map((t, index) => mapToRegisterDeliveryLineRequest(t, index)) });
			if (handleFormResponseWithLines(r, helpers, stepsRecord, setStep, 'lines', lines, setLines)) {
				notify(strings.addedWhat(strings.delivery));
				confirm(r.result.objectId);
			}
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterDeliveryRequest>
			validateOnMount
			initialValues={{ ...EmptyValues, deliveryDate: now }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						lines={lines}
						setLines={setLines}
						step={step}
						setStep={setStep}
						stepsRecord={stepsRecord}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	lines: IRegisterDeliveryLineModel[];
	setLines: React.Dispatch<React.SetStateAction<IRegisterDeliveryLineModel[]>>;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof IRegisterDeliveryRequest)[]>;
}

const InnerDialog = ({ cancel, lines, setLines, isSubmitting, step, setStep, stepsRecord, ...rest }: IInnerProps) => {
	const props = useFormikContext<IRegisterDeliveryRequest>();
	const strings = useLocalization();
	const [suppliers] = usePreloadCacheContext(SuppliersCacheContext);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	useNextReferenceInterceptor(deliveriesQuery_nextReference, props);

	const onSetLines = (lines: IRegisterDeliveryLineModel[]) => {
		// props.setErrors({}); // doesn't seem so clean
		props.setFieldValue(
			'lines',
			lines.map((line, index) => mapToRegisterDeliveryLineRequest(line, index))
		);
		setLines(lines);
	};

	useEffect(() => {
		if (suppliers?.length === 1) {
			setFormValue<IRegisterDeliveryRequest>('supplierId', suppliers[0].id, props);
		}
		// eslint-disable-next-line
	}, [suppliers]);

	useEffect(() => {
		if (locations?.length === 1) {
			setFormValue<IRegisterDeliveryRequest>('locationId', locations[0].id, props);
		}
		// eslint-disable-next-line
	}, [locations]);

	if (suppliers === undefined || locations === undefined) {
		return <div></div>;
	}

	return (
		<FullScreenDialogWithStepper
			{...rest}
			fullScreenStep={4}
			title={strings.newWhat(strings.delivery)}
			step={step}
			labels={[strings.referenceShort, strings.fromTo, strings.date, strings.shipment, strings.items]}>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IRegisterDeliveryRequest>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IRegisterDeliveryRequest>
							name='reference'
							label={strings.reference}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormTextField<IRegisterDeliveryRequest>
							name='externalReference'
							label={`${strings.externalReference} (${strings.fromSupplier})`}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectOrCreateSupplierField<IRegisterDeliveryRequest>
							name='supplierId'
							label={strings.supplier}
						/>
						<FormSelectFieldWithOption<IRegisterDeliveryRequest, string>
							name='locationId'
							options={locations}
							label={strings.location}
							helperText={strings.selectDeliveryLocationHelperText}
						/>
					</>
				)}
				{step === 2 && (
					<FormDatePicker<IRegisterDeliveryRequest>
						name='deliveryDate'
						label={strings.deliveryDate}
						required
					/>
				)}
				{step === 3 && (
					<>
						<FormTextField<IRegisterDeliveryRequest>
							name='dispatchReference'
							label={`${strings.dispatchReference} (${strings.optional})`}
						/>
						<FormDatePicker<IRegisterDeliveryRequest>
							name='dispatchDate'
							label={`${strings.dispatchDate} (${strings.optional})`}
						/>
					</>
				)}
				{step === 4 && (
					<SearchAndSelectProductsForDelivery
						lines={lines}
						setLines={lines => onSetLines(lines)}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</FullScreenDialogWithStepper>
	);
};
