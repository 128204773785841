import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { formatDate } from 'framework/utils/date/formatDate';
import { IPatient, patientsCommand_registerManualHandoverDate } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

interface IProps {
	item: IPatient;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const PatientDetailRenewalInfoCardContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onManual = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				label={strings.date}
				formTitle={`${strings.dateLastHandover} (${strings.manual})`}
				initialValue={item.renewalInfo.manualHandoverDate}
				submitFunction={date => patientsCommand_registerManualHandoverDate(item.id, date)}
			/>
		);
	};

	return (
		<div
			className='df-col'
			style={style}>
			<CardTitleTypography
				title={strings.rightForRenewal}
				style={{ marginBottom: 8 }}
			/>
			{item.hasRenewalDate === false && <em>{strings.noWhatRegistered(strings.handovers)}</em>}
			{item.hasRenewalDate === true && (
				<>
					<LabelledPropertyX label={strings.afterDate}>{formatDate(item.canRenewAfter)}</LabelledPropertyX>
					<LabelledPropertyX label={strings.termRenewal}>
						<TooltipWithSpan title={<div style={{ whiteSpace: 'pre-wrap' }}>{strings.rightForRenewalTermInfoContent}</div>}>
							<div>{item.renewalTermInYears}</div>
						</TooltipWithSpan>
					</LabelledPropertyX>
					<LabelledPropertyX
						label={strings.manual}
						edit={onManual}>
						<TooltipWithSpan title={<div style={{ whiteSpace: 'pre-wrap' }}>{strings.manualHandoverDateEntryContent}</div>}>
							<div>{formatDate(item.renewalInfo.manualHandoverDate)}</div>
						</TooltipWithSpan>
					</LabelledPropertyX>
				</>
			)}
			<TextLinkButton
				startIcon={<EventOutlinedIcon />}
				onClick={onManual}
				color='primary'>
				{strings.setAManualDate}
			</TextLinkButton>
		</div>
	);
};
