import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Paper, useTheme } from '@material-ui/core';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { createHeader } from 'framework/table/createHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { TablePaging } from 'framework/table/TablePaging';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import {
	INoahDeviceInventoryItemMap,
	INoahDeviceInventoryItemMapsWithUsagesSearchParams,
	noahDeviceInventoryItemMapsQuery_searchWithUsages,
	NoahDeviceType,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { createSelectHeader } from 'shared/table/createSelectHeader';
import { NoahDeviceImportedStatusChip } from '../NoahDeviceImportedStatusChip';

const DefaultParams: INoahDeviceInventoryItemMapsWithUsagesSearchParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'manufacturer' },
	pageParams: { index: 1, size: 10 },
	isLinkedWithInventoryItem: undefined,
};

interface IProps extends DialogProps {
	confirm: (serialNumber: string) => void;
	cancel: VoidFunction;
	initialSearch: string;
}

export const SelectNoahDeviceSerialNumberForm = ({ cancel, confirm, initialSearch, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(noahDeviceInventoryItemMapsQuery_searchWithUsages, {
		...DefaultParams,
		searchString: initialSearch,
	});
	const { noahDeviceTypeRecord } = useContext(RecordContext);

	const headers = [
		createEmptyHeader<INoahDeviceInventoryItemMap>('prepend', t => <NoahDeviceImportedStatusChip item={t} />),
		createSortableHeader<INoahDeviceInventoryItemMap>(strings.serialNumber, 'serialNumber', t => t.serialNumber),
		createSortableHeader<INoahDeviceInventoryItemMap>(strings.manufacturer, 'manufacturer', t => t.manufacturer),
		createSortableHeader<INoahDeviceInventoryItemMap>(strings.model, 'instrumentTypeName', t => t.instrumentTypeName),
		createHeader<INoahDeviceInventoryItemMap>(strings.type, t => <RectangularChip label={noahDeviceTypeRecord[t.type as NoahDeviceType]} />),
		createSelectHeader<INoahDeviceInventoryItemMap>(t => confirm(t.serialNumber ?? '')),
	];

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<>
			<Dialog
				{...rest}
				scroll='paper'
				fullWidth
				maxWidth='md'>
				<CloseButtonOnAbsolutePosition onClick={cancel} />
				<DialogTitle>
					<>
						<div>{strings.searchASerialNumberFor}</div>
						<div>{initialSearch}</div>
					</>
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchOnWhat(`${strings.manufacturer}/${strings.model}`)}
						variant='paper'
						style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
					/>
				</DialogTitle>
				<DialogContent dividers>
					{queryResult.filteredCount > 0 && (
						<Paper variant='outlined'>
							<TableWithHeadersAndValues<INoahDeviceInventoryItemMap>
								padding={theme.spacing(1)}
								headers={headers}
								getKey={t => t.id}
								sortParams={params.sortParams}
								setSortParams={sp => setParams({ ...params, sortParams: sp, pageParams: { index: 1, size: params.pageParams.size } })}
								values={queryResult.values}
							/>
						</Paper>
					)}
					{queryResult.filteredCount === 0 && queryResult.unfilteredCount > 0 && <NoRecordsFound>{strings.nothingFoundCommaChangeSearchParams}</NoRecordsFound>}
				</DialogContent>
				<DialogActions>
					<TablePaging
						countResults={queryResult.filteredCount}
						pageSize={queryResult.pageSize}
						page={queryResult.zeroBasedPageIndex}
						pageParams={params.pageParams}
						setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
						removeBorder
					/>
				</DialogActions>
			</Dialog>
		</>
	);
};
