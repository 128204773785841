import React from 'react';
import { AllQueryProvider } from './AllQueryProvider';
import { MyQueryProvider } from './MyQueryProvider';
import { ViewProvider } from './ViewProvider';

export const CommissionsPageProviders = ({ children }: any) => {
	return (
		<AllQueryProvider>
			<MyQueryProvider>
				<ViewProvider>{children}</ViewProvider>
			</MyQueryProvider>
		</AllQueryProvider>
	);
};
