import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IQueryResult, IStockMovement, IStockMovementsQueryParams, stockMovementsQuery_concurrencyToken, stockMovementsQuery_query } from 'gen/ApiClient';
import { stockMovementsQueryParamsLsKey } from '../../../../../localStorageKeys';
import { StockMovementsQueryContext } from './StockMovementsQueryContext';

const DefaultParams: IStockMovementsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	fromLocationIds: [],
	toLocationIds: [],
};

export const StockMovementsQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IStockMovement>, IStockMovementsQueryParams>
			defaultParams={DefaultParams}
			queryF={stockMovementsQuery_query}
			concurrencyF={stockMovementsQuery_concurrencyToken}
			localStorageKey={stockMovementsQueryParamsLsKey}
			context={StockMovementsQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
