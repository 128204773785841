import React from 'react';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { CashRegisterEntryExportProp, ICashRegisterEntry } from 'gen/ApiClient';
import { SelectableColumn } from '../SelectableColumn';

interface IViewContext extends ITableViewContext<SelectableColumn, ICashRegisterEntry> {
	exportRecord: Record<CashRegisterEntryExportProp, string>;
}

export const ViewContext = React.createContext<IViewContext>(undefined as any);
