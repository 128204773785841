import React from 'react';
import { PurchaseDetailTabType } from './PurchaseDetailTabType';

interface IContext {
	selectedTab: PurchaseDetailTabType;
	setSelectedTab: React.Dispatch<React.SetStateAction<PurchaseDetailTabType>>;
	purchaseId: string;
	setPurchaseId: React.Dispatch<React.SetStateAction<string>>;
	TabTypeRecord: Record<PurchaseDetailTabType, string>;
}

export const PurchaseDetailPageViewContext = React.createContext<IContext>(undefined as any);
