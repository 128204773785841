import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	exactOnlineProductCatalogItemsQuery_concurrencyToken,
	exactOnlineProductCatalogItemsQuery_query,
	IExactOnlineProductCatalogItemDto,
	IExactOnlineProductCatalogItemsQueryParams,
	IQueryResult,
} from 'gen/ApiClient';
import React from 'react';
import { ExactOnlineProductCatalogItemsQueryParamsLsKey } from '../../../../localStorageKeys';
import { ExactOnlineProductCatalogItemsQueryContext } from './ExactOnlineProductCatalogItemsQueryContext';

const DefaultParams: IExactOnlineProductCatalogItemsQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'identifier' },
	pageParams: { index: 1, size: 10 },
	prefilter: 'NotDeletedNorLinked',
	isDeleted: undefined,
};

export const ExactOnlineProductCatalogItemsQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IExactOnlineProductCatalogItemDto>, IExactOnlineProductCatalogItemsQueryParams>
			defaultParams={DefaultParams}
			queryF={exactOnlineProductCatalogItemsQuery_query}
			concurrencyF={exactOnlineProductCatalogItemsQuery_concurrencyToken}
			localStorageKey={ExactOnlineProductCatalogItemsQueryParamsLsKey}
			context={ExactOnlineProductCatalogItemsQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
