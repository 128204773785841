import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IInventoryItem, IInventoryItemsQueryParams, inventoryItemsQuery_concurrencyToken, inventoryItemsQuery_query, IQueryResult } from 'gen/ApiClient';
import React from 'react';
import { inventoryItemsQueryParamsLsKey } from '../../../../localStorageKeys';
import { DefaultInventoryItemsQueryParams } from './DefaultInventoryItemsQueryParams';
import { QueryContext } from './QueryContext';

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IInventoryItem>, IInventoryItemsQueryParams>
			defaultParams={DefaultInventoryItemsQueryParams}
			queryF={inventoryItemsQuery_query}
			concurrencyF={inventoryItemsQuery_concurrencyToken}
			context={QueryContext}
			localStorageKey={inventoryItemsQueryParamsLsKey}>
			{children}
		</FrameworkQueryProvider>
	);
};
