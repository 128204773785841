import { MenuItem, Select } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { AssuranceLineLinkComponent } from 'app/main/financial/sales/forms/invoice/AssuranceLineLinkComponent';
import { SearchInventoryItemForProductCatalogDialog } from 'app/main/inventoryManagement/inventoryItems/search/SearchInventoryItemForProductCatalogDialog';
import { IDraggableItem } from 'framework/components/dnd/IDraggableItem';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { ILocationSummary, inventoryItemsQuery_queryForSale } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SelectProductsToAssureDialog } from 'shared/searchAndSelect/assurance/SelectProductsToAssureDialog';
import { DefaultInventoryItemsQueryParamsForSale } from 'shared/searchAndSelect/DefaultInventoryItemsQueryParamsForSale';
import { LineDescriptionQuantityAndLinePrice } from 'shared/searchAndSelect/lines/LineDescriptionQuantityAndLinePrice';
import { LinePricingLabelledProperties } from 'shared/searchAndSelect/lines/LinePricingLabelledProperties';
import { LineXComponent } from 'shared/searchAndSelect/lines/LineXComponent';
import { PriceOrDiscountForm } from 'shared/searchAndSelect/PriceOrDiscountForm';
import { calculateDemoWarning } from 'shared/searchAndSelect/utils/calculateDemoWarning';
import { calculateReservedForWarning } from 'shared/searchAndSelect/utils/calculateReservedForWarning';
import { calculateStockWarning } from 'shared/searchAndSelect/utils/calculateStockWarning';
import { ViewContext } from '../../context/ViewContext';
import { lineToHandoverLineMode } from '../../utils/lineToHandoverLineMode';
import { HandoverLineMode, HandoverLineModes } from './HandoverLineMode';
import { IHandoverLine } from './IHandoverLine';
import { LineRizivHearingInstrumentCodeComponent } from './LineRizivHearingInstrumentCodeComponent';
import { assuranceToLine } from './utils/assuranceToLine';
import { inventoryItemToLine } from './utils/inventoryItemToLine';
import { mustLinkAnInventoryItem } from './utils/mustLinkAnInventoryItem';

const modeToIsInclude = (mode: HandoverLineMode): boolean => {
	return mode !== 'exclude';
};

const modeToIsRefundable = (mode: HandoverLineMode): boolean => {
	return mode === 'refundable';
};

interface IProps extends IDraggableItem {
	line: IHandoverLine;
	index: number;
	setLine: (val: IHandoverLine) => void;
	onDelete: VoidFunction | undefined;
	lines: IHandoverLine[];
	setLines: (val: IHandoverLine[]) => void;
	location: ILocationSummary | undefined;
	customerAccountId: string | undefined;
	isUsingOldRizivIdentificationCode: boolean;
}

export const HandoverLineComponent = ({ line, setLine, lines, location, setLines, customerAccountId, isUsingOldRizivIdentificationCode, ...rest }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext();
	const strings = useLocalization();
	const stockWarning = useMemo(() => (location === undefined ? undefined : calculateStockWarning(line, location, strings)), [line, location, strings]);
	const demoWarning = useMemo(() => calculateDemoWarning(line, strings), [line, strings]);
	const reservedForWarning = useMemo(() => calculateReservedForWarning(line, strings), [line, strings]);
	const { handoverLineModeRecord } = useContext(ViewContext);
	const mustLinkInventoryItem = useMemo(() => mustLinkAnInventoryItem(line), [line]);

	const onEditPrice = () => {
		open(
			<PriceOrDiscountForm
				open
				listPrice={line.unitListPrice}
				unitPrice={line.unitPrice}
				confirm={(up, lp) => {
					confirm();
					setLine({ ...line, unitPrice: up, unitListPrice: lp });
				}}
				cancel={cancel}
				forPurchase={false}
			/>
		);
	};

	const onSelectItemToAssurre = () => {
		open(
			<SelectProductsToAssureDialog<IHandoverLine>
				open
				assurance={line.productCatalogItem!}
				cancel={cancel}
				lines={lines}
				customerAccountId={customerAccountId}
				onAdd={assuranceLines => {
					confirm();
					const y = [
						...lines.filter(t => t.id !== line.id),
						...assuranceLines.map(t => ({
							...assuranceToLine(line.productCatalogItem!, t),
							unitListPriceInVat: line.unitListPrice,
							unitPriceInVat: line.unitPrice,
						})),
					];
					setLines(y);
				}}
			/>
		);
	};

	const onLinkInventoryItem = () => {
		open(
			<SearchInventoryItemForProductCatalogDialog
				confirm={item => {
					confirm();
					const x: IHandoverLine = {
						...inventoryItemToLine(item, line.productCatalogItem!),
						unitPrice: line.unitPrice,
						unitListPrice: line.unitListPrice,
					};
					setLine(x);
				}}
				cancel={cancel}
				canSelectF={t => t.canSelectForSale}
				defaultParams={DefaultInventoryItemsQueryParamsForSale}
				queryF={inventoryItemsQuery_queryForSale}
				item={line.productCatalogItem!}
				selectedIds={lines.filter(t => t.inventoryItemId !== undefined).map(t => t.inventoryItemId) as string[]}
			/>
		);
	};

	return (
		<LineXComponent
			{...rest}
			line={line}
			setLine={setLine}
			warnings={[stockWarning, demoWarning, reservedForWarning]}
			errors={line.error}>
			<LineDescriptionQuantityAndLinePrice<IHandoverLine>
				line={line}
				setLine={setLine}
				onClickPrice={onEditPrice}
				disableQuantity={line.canChangeQuantity === false}
			/>
			<div className='df-col fg1'>
				{line.productCatalogItem && line.productCatalogItem.isInsuranceOrProlongedWarranty && (
					<AssuranceLineLinkComponent
						line={line}
						lines={lines}
						onLink={onSelectItemToAssurre}
					/>
				)}
				{mustLinkInventoryItem && (
					<ErrorLabelledProperty
						error={strings.selectItemWithSerialNumberClickHereToLink}
						edit={onLinkInventoryItem}
						editIcon={<LinkIcon />}
						withoutMargin
					/>
				)}
				<div className='df-row-ac'>
					<LabelledProperty
						label={strings.refund}
						style={{ marginRight: 8 }}>
						<Select
							variant='outlined'
							SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
							value={lineToHandoverLineMode(line)}
							onChange={e =>
								setLine({
									...line,
									isIncludeOnHandoverCertificate: modeToIsInclude(e.target.value as HandoverLineMode),
									isRefundable: modeToIsRefundable(e.target.value as HandoverLineMode),
								})
							}>
							{HandoverLineModes.map((t, index) => (
								<MenuItem
									dense
									key={t}
									value={t as any}>
									{handoverLineModeRecord[t]}
								</MenuItem>
							))}
						</Select>
					</LabelledProperty>
					{line.inventoryItem && line.inventoryItem.canLinkRizivRefundableHearingInstrument && (
						<LineRizivHearingInstrumentCodeComponent
							line={line}
							lines={lines}
							setLines={setLines}
							isUsingOldRizivIdentificationCode={isUsingOldRizivIdentificationCode}
						/>
					)}
				</div>
			</div>
			<LinePricingLabelledProperties<IHandoverLine>
				line={line}
				onClick={onEditPrice}
				style={{ marginTop: 8 }}
			/>
		</LineXComponent>
	);
};
