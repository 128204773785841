import React from 'react';
import { CurrentLocationProvider } from './CurrentLocationProvider';
import { LocationsPreloadCacheProvider } from './LocationsPreloadCacheProvider';

export const LocationsProviders = ({ children }: any) => {
	return (
		<LocationsPreloadCacheProvider>
			<CurrentLocationProvider>{children}</CurrentLocationProvider>
		</LocationsPreloadCacheProvider>
	);
};
