import React from 'react';
import { IApiErrorStrings } from '../localization/apiErrors/IApiErrorString';
import { NotificationType } from './NotificationType';

export interface IApiContext {
	strings: IApiErrorStrings;
	notify: (message: string, type: NotificationType) => void;
	redirect: VoidFunction;
	mustUpdateFrontEnd: boolean;
	setMustUpdateFrontEnd :  React.Dispatch<React.SetStateAction<boolean>>;
}

export const ApiContext = React.createContext<IApiContext>(undefined as any);
