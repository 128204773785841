import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { IPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AddPatientContactInfoForm } from './AddPatientContactInfoForm';
import { PatientContactInfoListComponent } from './PatientContactInfoListComponent';

interface IProps {
	item: IPatient;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const PatientContactInfoCardContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onAdd = () => {
		open(
			<AddPatientContactInfoForm
				open
				confirm={confirm}
				cancel={cancel}
				patientId={item.id}
			/>
		);
	};

	return (
		<div
			className='df-col gap-4'
			style={style}>
			<CardTitleTypography title={`${strings.email}/${strings.phoneNumberShort}`} />
			{item.contactInfos.length === 0 && <em>{strings.noContactInfo}</em>}
			{item.contactInfos.map(t => (
				<PatientContactInfoListComponent
					key={t.id}
					item={t}
					patientId={item.id}
					reload={reload}
				/>
			))}
			<TextLinkButton
				startIcon={<AddCircleOutlineOutlinedIcon />}
				onClick={onAdd}
				color='primary'>
				{strings.addImperative}
			</TextLinkButton>
		</div>
	);
};
