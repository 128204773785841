import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SelectMedicalTreatmentAndFeeForm } from '../processes/requestsForInsuranceAllowance/forms/medicalTreatmentNomenclatures/SelectMedicalTreatmentAndFeeForm';

export const Test = () => {
	const { open, confirm, cancel } = useDialogsContext();

	const onOpen = () => {
		open(
			<SelectMedicalTreatmentAndFeeForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<div
			className='df-col'
			style={{ margin: 20 }}>
			<TextLinkButton onClick={onOpen}>{`Select nomenclature`}</TextLinkButton>
		</div>
	);
};
