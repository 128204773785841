import { Button } from '@material-ui/core';
import { DownloadMultipleIcon } from 'framework/components/icons/DownloadMultipleIcon';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { ExportFormat } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { ExportMenu } from './ExportMenu';

interface IProps {
	filteredCount: number;
	unfilteredCount: number;
	onExportClick: (applyFilter: boolean, format: ExportFormat) => void;
	children?: any;
	anchor?: [null | HTMLElement, (event: React.MouseEvent<HTMLElement>) => void, VoidFunction, undefined];
	style?: React.CSSProperties;
}

export const ExportButtonAndMenu = ({ filteredCount, unfilteredCount, onExportClick, children, anchor, style }: IProps) => {
	const strings = useLocalization();
	const localAnchor = useAnchorElement();
	const [menuAnchor, openMenu, closeMenu] = useMemo(() => anchor ?? localAnchor, [anchor, localAnchor]);

	return (
		<>
			<Button
				color='primary'
				size='small'
				startIcon={<DownloadMultipleIcon />}
				onClick={openMenu}
				style={style}>
				{strings.exportVerb}
			</Button>
			<ExportMenu
				filteredCount={filteredCount}
				unfilteredCount={unfilteredCount}
				menuAnchor={menuAnchor}
				closeMenu={closeMenu}
				onExportClick={onExportClick}>
				{children}
			</ExportMenu>
		</>
	);
};
