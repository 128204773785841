import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { EntPhysiciansCacheContext } from 'app/main/contacts/earNoseThroatPhysicians/EntPhysiciansCacheContext';
import { GeneralPractitionersCacheContext } from 'app/main/contacts/generalPractitioners/GeneralPractitionersCacheContext';
import { LocationsPreloadCacheContext } from 'app/main/settings/locations/LocationsPreloadCacheContext';
import { useUsersAsFilterOptions } from 'app/main/settings/users/useUsersAsFilterOptions';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IPatientsQueryParams, PatientStatus, PatientStatuses } from 'gen/ApiClient';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { GenderFilterItem } from 'shared/GenderFilterItem';
import { RecordContext } from 'shared/records/RecordContext';
import { PatientsFilterDataContext } from '../PatientsFilterDataContext';
import { PatientsListViewContext } from './PatientsListViewContext';
import { getMonthOptions } from './getMonthOptions';

const exclusions: (keyof IPatientsQueryParams)[] = ['keyStatus', 'searchMode'];

interface IProps {
	params: IPatientsQueryParams;
	onFilter: (params: IPatientsQueryParams) => void;
	paddingLeft: number;
}

export const PatientsListFilter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const { patientStatusRecord } = useContext(RecordContext);
	const [filterData] = useCacheContext(PatientsFilterDataContext);
	const [entPhysicians] = usePreloadCacheContext(EntPhysiciansCacheContext);
	const [generalPractitioners] = usePreloadCacheContext(GeneralPractitionersCacheContext);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const filterUsers = useUsersAsFilterOptions();
	const { quickFilter } = useContext(PatientsListViewContext);
	const language = useCurrentLanguage();
	const monthOptions = useMemo(() => getMonthOptions(language), [language]);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.dateCreated}
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<GenderFilterItem
				selected={params.gender}
				setSelected={gender => onFilter({ ...params, gender: gender })}
			/>
			{quickFilter !== 'active' && (
				<RecordListFilterComponent<PatientStatus>
					options={PatientStatuses}
					record={patientStatusRecord}
					selected={params.statuses as any}
					label={strings.status}
					icon={<HelpOutlineIcon />}
					setSelected={vals => onFilter({ ...params, statuses: vals })}
				/>
			)}
			<ListFilterComponent<string>
				options={filterUsers}
				selected={params.audiologistIds}
				setSelected={vals => onFilter({ ...params, audiologistIds: vals })}
				label={`${strings.user}/${strings.audiologist}`}
				icon={<HelpOutlineIcon />}
				title={`${strings.user}/${strings.audiologist}`}
			/>
			{entPhysicians && (
				<ListFilterComponent<string>
					options={entPhysicians}
					selected={params.entPhysicianIds}
					setSelected={vals => onFilter({ ...params, entPhysicianIds: vals })}
					label={strings.entPhysician}
					icon={<HelpOutlineIcon />}
					title={strings.entPhysician}
				/>
			)}
			{generalPractitioners && (
				<ListFilterComponent<string>
					options={generalPractitioners}
					selected={params.generalPractitionerIds}
					setSelected={vals => onFilter({ ...params, generalPractitionerIds: vals })}
					label={strings.generalPractitioner}
					icon={<HelpOutlineIcon />}
					title={strings.generalPractitioner}
				/>
			)}
			<ListFilterComponent<string>
				options={locations}
				selected={params.locationIds}
				setSelected={vals => onFilter({ ...params, locationIds: vals })}
				label={strings.location}
				icon={<HelpOutlineIcon />}
				title={strings.location}
			/>
			{filterData && (
				<StringListFilterComponent
					options={filterData.origins}
					selected={params.origins}
					setSelected={vals => onFilter({ ...params, origins: vals })}
					label={strings.origin}
					icon={<HelpOutlineIcon />}
				/>
			)}
			{filterData && (
				<StringListFilterComponent
					options={filterData.labels}
					selected={params.labels}
					setSelected={vals => onFilter({ ...params, labels: vals })}
					label={strings.labels}
					icon={<HelpOutlineIcon />}
				/>
			)}
			<DateFilterItem
				label={strings.dateLastHandover}
				after={params.lastHandoverDateAfter}
				before={params.lastHandoverDateBefore}
				setSelected={(after, before) => onFilter({ ...params, lastHandoverDateAfter: after, lastHandoverDateBefore: before })}
			/>
			<DateFilterItem
				label={strings.renewal}
				after={params.canRenewAfter}
				before={params.canRenewBefore}
				setSelected={(after, before) => onFilter({ ...params, canRenewAfter: after, canRenewBefore: before })}
			/>
			<DateFilterItem
				label={strings.lastModifiedDate}
				after={params.lastUpdatedAfter}
				before={params.lastUpdatedBefore}
				setSelected={(after, before) => onFilter({ ...params, lastUpdatedAfter: after, lastUpdatedBefore: before })}
			/>
			<ListFilterComponent<number>
				options={monthOptions}
				selected={params.monthsOfBirth}
				setSelected={vals => onFilter({ ...params, monthsOfBirth: vals })}
				label={strings.monthDateOfBirth}
				icon={<HelpOutlineIcon />}
				skipSort
			/>
			{filterData && (
				<StringListFilterComponent
					options={filterData.zips}
					selected={params.zips}
					setSelected={vals => onFilter({ ...params, zips: vals })}
					label={strings.zip}
					icon={<HelpOutlineIcon />}
				/>
			)}
		</FilterBar>
	);
};
