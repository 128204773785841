import { Divider } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { TextLinkLoaderButton } from 'framework/components/buttons/TextLinkLoaderButton';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { useIntervalTrigger } from 'framework/hooks/useIntervalTrigger';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DownloadAndInstallClientSoftwareDialog } from 'shared/downloads/DownloadAndInstallClientSoftwareDialog';

interface IProps {
	check: VoidFunction;
	isChecking: boolean;
}

export const NoConnectionToClientSoftwareComponent = ({ check, isChecking }: IProps) => {
	const strings = useLocalization();
	const [isAlreadyInstalled, setIsAlreadyInstalled] = useState<boolean>();
	const trigger = useIntervalTrigger(10000);
	const { open, confirm } = useDialogsContext(check);

	useLazyEffect(() => {
		check();
	}, [trigger]);

	const onDownload = () => {
		open(
			<DownloadAndInstallClientSoftwareDialog
				open
				close={confirm}
			/>
		);
	};

	return (
		<>
			<VariantLabelledPropertyX variant='help'>{strings.isSoftwareAlreadyInstalledQuestion}</VariantLabelledPropertyX>
			<div className='df-row-ac gap-8'>
				<TextLinkButton
					color={isAlreadyInstalled === undefined ? 'secondary' : undefined}
					hoverColor='secondary'
					startIcon={<CheckIcon />}
					disabled={isAlreadyInstalled === true}
					onClick={() => setIsAlreadyInstalled(true)}>
					{strings.yes}
				</TextLinkButton>
				<TextLinkButton
					color={isAlreadyInstalled === undefined ? 'secondary' : undefined}
					hoverColor='secondary'
					startIcon={<ClearIcon />}
					disabled={isAlreadyInstalled === false}
					onClick={() => setIsAlreadyInstalled(false)}>
					{strings.no}
				</TextLinkButton>
			</div>
			{isAlreadyInstalled === true && (
				<>
					<Divider />
					<VariantLabelledPropertyX variant='info'>{strings.doubleClickNoahInHamsOnDesktop}</VariantLabelledPropertyX>
					<TextLinkLoaderButton
						isLoading={isChecking}
						startIcon={<RefreshOutlinedIcon />}
						onClick={check}
						color='secondary'
						hoverColor='secondary'>
						{strings.checkAgain}
					</TextLinkLoaderButton>
				</>
			)}
			{isAlreadyInstalled === false && (
				<>
					<Divider />
					<TextLinkButton
						color='primary'
						startIcon={<GetAppOutlinedIcon />}
						onClick={onDownload}>
						{strings.download}
					</TextLinkButton>
				</>
			)}
		</>
	);
};
