import { Avatar } from '@material-ui/core';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { useLoggedInUserIsSuperUserOrHasFunctionClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasFunctionClaim';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { LabelledChipsList } from 'framework/components/chips/LabelledChipsList';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatientDetail, patientsCommand_hardDelete, patientsCommand_removeInvalidEidData, patientsNhaxQuery_exportNhax } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PatientsRoute } from 'routes';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { EidUpdateForm } from '../../eid/EidUpdateForm';
import { ChangePatientStatusForm } from '../../forms/ChangePatientStatusForm';
import { PatientLabelsForm } from '../../forms/PatientLabelsForm';
import { UpdateFromNhaxFileForm } from '../../forms/UpdateFromNhaxFileForm';
import { PatientDetailEidComponent } from './PatientDetailEidComponent';
import { ResponsivePatientDetailActions } from './ResponsivePatientDetailActions';

interface IProps {
	item: IPatientDetail;
	reload: VoidFunction;
}

export const PatientDetailPageSidebar = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();
	const notify = useSnackbarNotify();
	const canExportToNhax = useLoggedInUserIsSuperUserOrHasFunctionClaim('cenhaxf');
	const [exportNhax, isExportingToNhax] = useDownload(patientsNhaxQuery_exportNhax);

	const onReadDataFromEID = () => {
		open(
			<EidUpdateForm
				open
				patient={item.patient}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onRemoveEidData = () => {
		open(
			<AlertDialog
				open
				title={strings.removeEidData}
				content={strings.deleteWhatQuestion(strings.eIDData)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => patientsCommand_removeInvalidEidData({ patientId: item.patient.id })}
				accept={confirm}
			/>
		);
	};

	const onChangeStatus = () => {
		open(
			<ChangePatientStatusForm
				open
				patient={item.patient}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onEditLabels = () => {
		open(
			<PatientLabelsForm
				open
				item={item.patient}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onUploadNHAX = () => {
		open(
			<UpdateFromNhaxFileForm
				patientId={item.patient.id}
				confirm={() => {
					notify(strings.nhaxDataReadAndAddedToThisPatient);
					confirm();
				}}
				cancel={cancel}
			/>
		);
	};

	const onDownloadNHAX = async () => {
		await exportNhax(item.patient.id, { password: '' });
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title={strings.deletePermanently}
				content={strings.deleteWhatQuestion(strings.patient)}
				acceptText={strings.yesWhatExclamation(strings.delete)}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => patientsCommand_hardDelete(item.patient.id)}
				accept={() => {
					cancel();
					push(PatientsRoute);
					notify(strings.patientPermanentlyDeleted);
				}}
			/>
		);
	};

	return (
		<div
			className='df-col h100'
			style={{
				padding: 16,
				paddingRight: 8,
				width: 250,
			}}>
			<Avatar
				src={`data:image/png;base64,${item.photo?.data}`}
				style={{
					width: 150,
					height: 150,
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			/>
			<div style={{ fontWeight: 500, textAlign: 'center', marginTop: 8, fontSize: 16 }}>{item.patient.lastNameCommaFirstNameBracketsNickName}</div>
			<PatientDetailEidComponent
				item={item}
				onRead={onReadDataFromEID}
				onRemoveEidData={onRemoveEidData}
			/>
			<DividerWithMargin marginBottom={12} />
			<ResponsivePatientDetailActions
				item={item}
				reload={reload}
			/>
			<DividerWithMargin marginBottom={12} />
			<div
				className='df-col fg1'
				style={{ overflowY: 'hidden' }}>
				<TextLinkButton
					style={{ fontSize: 12 }}
					color='textSecondary'
					onClick={onEditLabels}>
					<div>{strings.labels}</div>
					<div className='fg1'></div>
					<SettingsOutlinedIcon style={{ fontSize: 16 }} />
				</TextLinkButton>
				<div
					className='fg1'
					style={{ overflowY: 'auto' }}>
					{item !== undefined && item?.patient.labels.length > 0 && <LabelledChipsList labels={item.patient.labels} />}
					{(item === undefined || item.patient.labels.length === 0) && <NoRecordsFound variant='caption'>{strings.noLabelsAssignedYet}</NoRecordsFound>}
				</div>
			</div>
			<DividerWithMargin style={{ marginTop: 8, marginBottom: 8 }} />
			<div className='df-col gap-8'>
				<TextLinkButton
					startIcon={<ContactMailOutlinedIcon />}
					onClick={onReadDataFromEID}>
					{strings.readeID}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<PublishOutlinedIcon />}
					onClick={onUploadNHAX}>
					{strings.uploadNHAX}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<GetAppOutlinedIcon />}
					onClick={onDownloadNHAX}
					disabled={canExportToNhax === false}
					isLoading={isExportingToNhax}>
					{strings.downloadNHAX}
				</TextLinkButton>
				{item.patient.isActive === true && (
					<TooltipWithSpan title={strings.setStatusPatientToStatuses}>
						<TextLinkButton
							startIcon={<DeleteOutlineOutlinedIcon />}
							hoverColor='secondary'
							onClick={onChangeStatus}
							style={{ paddingBottom: 0 }}>
							{strings.delete}
						</TextLinkButton>
					</TooltipWithSpan>
				)}
				{item.patient.isActive === false && (
					<>
						<TextLinkButton
							startIcon={<HistoryOutlinedIcon />}
							onClick={onChangeStatus}
							hoverColor='secondary'>
							{strings.changeWhat(strings.status)}
						</TextLinkButton>
						<TextLinkButton
							startIcon={<DeleteOutlineOutlinedIcon />}
							onClick={onDelete}
							hoverColor='secondary'
							style={{ paddingBottom: 0 }}>
							{strings.deletePermanently}
						</TextLinkButton>
					</>
				)}
			</div>
		</div>
	);
};
