import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';

interface IProps {
	values: string[];
	setValues: (vals: string[]) => void;
	options: string[];
	style?: React.CSSProperties;
	className?: string;
	disabled?: boolean;
}

export const AutocompleteMultiple = ({ values, setValues, options, style, className, disabled }: IProps) => {
	return (
		<Autocomplete
			className={className}
			style={style}
			multiple
			options={options}
			value={values}
			disableCloseOnSelect
			clearOnBlur
			onChange={(e, values, reason) => setValues(values)}
			filterSelectedOptions
			disabled={disabled}
			renderTags={(tagValue, getTagProps) =>
				tagValue.map((option, index) => (
					<Chip
						size='small'
						label={option}
						{...getTagProps({ index })}
					/>
				))
			}
			renderInput={params => (
				<TextField
					{...params}
					variant='outlined'
					inputProps={{ ...params.inputProps, style: { padding: 0 } }}
					InputProps={{ ...params.InputProps, style: { padding: values.length > 0 ? 2 : 9 } }}
				/>
			)}
		/>
	);
};
