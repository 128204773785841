import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { ITimeModel, IValidateResponse, TimeUnit } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useFormSubmit } from '../hooks/useFormSubmit';
import { useSnackbarNotify } from '../hooks/useSnackbarNotify';
import { Try } from '../Try';
import { CancelSubmitFormDialogActions } from './CancelSubmitFormDialogActions';
import { FormDatePickerWithUnit } from './FormDatePickerWithUnit';
import { handleValidateResponse } from './utils/handleValidateResponse';

interface IModel {
	value: Date;
}

const createSchema = (strings: IStrings, label: string) => {
	return yup.date().required(strings.formRequired(label));
};

interface IProps extends DialogProps {
	formTitle: string;
	label: string;
	notifyMessage?: string;
	initialValue?: Date;
	confirm: VoidFunction;
	cancel: VoidFunction;
	submitFunction: (value: Date) => Promise<Try<IValidateResponse>>;
	disableFuture?: boolean;
	disablePast?: boolean;
	units: TimeUnit[];
	defaultUnit: TimeUnit;
	defaultUnitValue?: ITimeModel;
}

export const DateWithUnitModelForm = ({
	notifyMessage,
	formTitle,
	initialValue,
	submitFunction,
	label,
	confirm,
	cancel,
	disableFuture = false,
	disablePast = false,
	units,
	defaultUnit,
	defaultUnitValue,
	...rest
}: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(submitFunction);

	const handleSubmit = async (value: IModel, helpers: FormikHelpers<IModel>) => {
		const updateResult = await update(value.value);
		if (handleValidateResponse(updateResult, helpers, 'value')) {
			if (notifyMessage) {
				notify(notifyMessage);
			}
			confirm();
		}
	};

	return (
		<Formik
			validateOnMount
			validationSchema={createSchema(strings, label)}
			initialValues={{ value: initialValue as any }}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					maxWidth='xs'
					fullWidth
					{...rest}>
					<DialogTitle>{formTitle}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormDatePickerWithUnit<IModel>
							name='value'
							label={label}
							units={units}
							defaultUnit={defaultUnit}
							defaultValue={defaultUnitValue}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.update}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
