import React from 'react';
import { AccordionX } from './AccordionX';

interface IProps<T extends string> {
	type: T;
	expandedAccordion: T | undefined;
	toggle: (type: T) => void;
	children: any;
	title: string;
}

export const AccordionXWithType = <T extends string>({ type, expandedAccordion, toggle, ...rest }: IProps<T>) => {
	return (
		<AccordionX
			{...rest}
			isExpanded={expandedAccordion === type}
			toggle={() => toggle(type)}
		/>
	);
};
