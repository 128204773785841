import { useTheme } from '@material-ui/core';
import React from 'react';
import { ISalesSummary } from 'gen/ApiClient';
import { SalesCreditChipOrFragment } from './SalesCreditChipOrFragment';
import { SalesPaymentStatusChip } from './SalesPaymentStatusChip';

interface IProps {
	item: ISalesSummary;
	style?: React.CSSProperties;
}

export const SalesSummaryPaymentStatusAndCreditChip = ({ item, style }: IProps) => {
	const theme = useTheme();

	return (
		<div
			className='df-row-ac'
			style={style}>
			<SalesPaymentStatusChip
				item={item}
				style={{ marginRight: theme.spacing(1) }}
			/>
			<SalesCreditChipOrFragment item={item} />
		</div>
	);
};
