import { DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { Try } from 'framework/Try';
import { ICreateResponse, IOfficeModel } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { AddressInput } from '../address/AddressInput';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IOfficeModel>({
			addressLine: yup.string().required(strings.formRequired(strings.addressLine)),
			zip: yup.string().required(strings.formRequired(strings.zip)),
			city: yup.string().required(strings.formRequired(strings.city)),
			country: yup.string(),
			phone: yup.string(),
			email: yup.string(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IOfficeModel)[]> = {
	0: ['addressLine', 'city', 'country', 'zip'],
	1: ['phone', 'email'],
};

interface IProps extends DialogProps {
	model?: IOfficeModel;
	submitFunction: (model: IOfficeModel) => Promise<Try<ICreateResponse<IOfficeModel>>>;
	cancel: VoidFunction;
	confirm: (id: string) => void;
	successMessage?: string;
}

export const OfficeForm = ({ model, confirm, cancel, submitFunction, successMessage, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(submitFunction);
	const [step, setStep] = useState<number>(0);

	const handleSubmit = async (values: IOfficeModel, helpers: FormikHelpers<IOfficeModel>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers)) {
			if (successMessage) {
				notify(successMessage);
			}
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<IOfficeModel>
			validateOnMount
			initialValues={
				model ?? {
					addressLine: '',
					city: '',
					country: '',
					zip: '',
					phone: '',
					email: '',
				}
			}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<OverflowDialog {...rest}>
					<DialogTitleWithFormStepper
						step={step}
						title={strings.addOffice}
						labels={[strings.addressData, strings.contactData]}
					/>
					<OverflowDialogContent>
						{step === 0 && <AddressInput<IOfficeModel> />}
						{step === 1 && (
							<>
								<FormTextField<IOfficeModel>
									name='email'
									label={strings.email}
								/>
								<FormTextField<IOfficeModel>
									name='phone'
									label={strings.phone}
								/>
							</>
						)}
					</OverflowDialogContent>
					<PageableFormDialogActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
					/>
				</OverflowDialog>
			</Form>
		</Formik>
	);
};
