import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IOrdersQueryMetadata, IOrdersQueryParams, IOrderV2, IQueryResultWithMetadata, ordersQuery_concurrencyToken, ordersQuery_query } from 'gen/ApiClient';
import React from 'react';
import { ordersQueryParamsLsKey } from '../../../../localStorageKeys';
import { OrdersQueryContext } from './OrdersQueryContext';

const DefaultParams: IOrdersQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	deliveredAfter: undefined,
	deliveredBefore: undefined,
	statuses: [],
	supplierIds: [],
	isCompletelyDelivered: undefined,
	handlingUserIds: [],
	locationIds: [],
	patientId: undefined,
	prefilter: 'All',
};

export const OrdersQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResultWithMetadata<IOrderV2, IOrdersQueryMetadata>, IOrdersQueryParams>
			defaultParams={DefaultParams}
			queryF={ordersQuery_query}
			concurrencyF={ordersQuery_concurrencyToken}
			localStorageKey={ordersQueryParamsLsKey}
			context={OrdersQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
