import { ChipX } from 'framework/components/chips/ChipX';
import { IOrderV2 } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { mapOrderToColorX } from './mapOrderToColorX';
import { OrderStatusChip } from './OrderStatusChip';

interface IProps {
	item: IOrderV2;
	style?: React.CSSProperties;
}

export const OrderChips = ({ item, style }: IProps) => {
	const strings = useLocalization();
	const color = useMemo(() => mapOrderToColorX(item), [item]);

	return (
		<div
			className='df-row-ac gap-4'
			style={style}>
			<OrderStatusChip
				item={item}
				color={color}
			/>
			{item.isOverDue && (
				<ChipX
					label={strings.overdue}
					color='warning'
				/>
			)}
		</div>
	);
};
