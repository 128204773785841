import { IInventoryItem, IProductCatalogItem } from 'gen/ApiClient';
import { IInvoiceLine } from '../IInvoiceLine';

export const inventoryItemToLine = (t: IInventoryItem, pc: IProductCatalogItem, isCredit: boolean): IInvoiceLine => {
	return {
		isSelected: true,
		id: t.id,
		description: t.identifier,
		inventoryItemId: t.id,
		productCatalogItemId: t.productCatalogId,
		quantity: isCredit ? -1 : 1,
		unitListPrice: t.salesListPrice,
		unitPrice: t.salesPrice === 0 ? t.salesListPrice : t.salesPrice,
		vatPercentage: t.vatPercentage,
		productCatalogItem: pc,
		assuredInventoryItemId: undefined,
		assuredLineId: undefined,
		assuredProductCatalogItemId: undefined,
		serialNumber: t.serialNumber,
		canChangeQuantity: false,
		stockAtLocations: undefined,
		inventoryItem: t,
		isCredit: isCredit,
	};
};
