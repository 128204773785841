import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { productCatalogQuery_singleWithStatsAndStockInfo } from 'gen/ApiClient';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AnnotationsCard } from 'shared/annotations/AnnotationsCard';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { NavigationContext } from '../context/NavigationContext';
import { OverviewPage } from './OverviewPage';
import { ProductCatalogItemDetailPageHeader } from './ProductCatalogItemDetailPageHeader';
import { ProductCatalogItemDetailPageSidebar } from './ProductCatalogItemDetailPageSidebar';
import { InventoryItemsCard } from './inventoryItems/InventoryItemsCard';
import { TransactionsCard } from './transactions/TransactionsCard';

export const ProductCatalogItemDetail = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [dto, reload] = useApiEffect(productCatalogQuery_singleWithStatsAndStockInfo, id);
	const [trigger, hitTrigger] = useTrigger();
	const { link, setLink } = useContext(NavigationContext);

	useLazyEffect(() => {
		reload();
	}, [trigger]);

	useEffect(() => {
		if (dto !== undefined && dto.item.isTangible === false && link === 'inventoryItems') {
			setLink('transactions');
		}
		// eslint-disable-next-line
	}, [dto, link]);

	if (dto === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='ProductCatalog'
			maxWidth={1280}
			stickySidebar={
				<ProductCatalogItemDetailPageSidebar
					item={dto.item}
					reload={reload}
					style={{ minWidth: 250 }}
				/>
			}
			stickyHeader={
				<ProductCatalogItemDetailPageHeader
					dto={dto}
					reload={reload}
					tab={link}
					setTab={setLink}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				{link === 'overview' && (
					<OverviewPage
						reloadTrigger={trigger}
						item={dto.item}
						stockInfo={dto.stockInfo}
						reload={hitTrigger}
						isDirty={dto.stockInfoIsDirty}
					/>
				)}
				{link === 'transactions' && (
					<TransactionsCard
						productCatalogId={id}
						reloadTrigger={trigger}
					/>
				)}
				{link === 'comments' && (
					<AnnotationsCard
						contextId={id}
						linkViewContextType='ProductCatalogItem'
						reload={hitTrigger}
						reloadTrigger={trigger}
					/>
				)}
				{link === 'inventoryItems' && dto.item.isUniquelyIdentifiable && (
					<InventoryItemsCard
						productCatalogId={dto.item.id}
						reload={hitTrigger}
						reloadTrigger={trigger}
					/>
				)}
			</div>
		</RestrictedPageX>
	);
};
