import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SimplePriceForm } from 'shared/searchAndSelect/SimplePriceForm';
import { EditableDescription } from 'shared/searchAndSelect/lines/EditableDescription';
import { DeleteColorOnHoverIconButtonWithConfirmation } from './DeleteColorOnHoverIconButtonWithConfirmation';
import { IAllowanceChargeFormLine } from './IAllowanceChargeFormLine';
import { PurchaseAllowanceChargeLineLinkStatusComponent } from './PurchaseAllowanceChargeLineLinkStatusComponent';
import { ReferencePriceSpan } from './ReferencePriceSpan';

interface IProps {
	line: IAllowanceChargeFormLine;
	index: number;
	setLine: (val: IAllowanceChargeFormLine) => void;
	onDelete: VoidFunction;
	quantity: number;
	onLink: VoidFunction;
	onIgnore: VoidFunction;
}

export const PurchaseAllowanceChargeLineComponent = ({ line, index, setLine, onDelete, quantity, onLink, onIgnore }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext();
	const strings = useLocalization();

	const onEditPrice = () => {
		open(
			<SimplePriceForm
				open
				confirm={val => {
					confirm();
					setLine({ ...line, unitPrice: val });
				}}
				unitPrice={line.unitPrice}
				cancel={cancel}
			/>
		);
	};

	return (
		<div className='df-col fg1'>
			<div className='df-row-ac fg1'>
				<span style={{ marginRight: 4 }}>{`#${index}`}</span>
				<PurchaseAllowanceChargeLineLinkStatusComponent
					line={line}
					setLine={setLine}
					onLink={onLink}
					onIgnore={onIgnore}
				/>
				<DeleteColorOnHoverIconButtonWithConfirmation onClick={onDelete} />
				<EditableDescription
					fontWeight='normal'
					text={line.description}
					setText={val => setLine({ ...line, description: val })}
				/>
				<div className='fg1'></div>
				<LabelledPropertyX
					edit={onEditPrice}
					editPosition='start'>
					<div className='df-row-ab gap-4'>
						{line.isMappedFromUbl === true && line.productCatalogItem !== undefined && line.productCatalogItem !== null && (
							<ReferencePriceSpan
								unitPrice={line.unitPrice}
								productCatalogItem={line.productCatalogItem}
							/>
						)}
						<span>{`${quantity} x ${formatCurrency(line.unitPrice)}`}</span>
					</div>
				</LabelledPropertyX>
			</div>
			{line.productCatalogItem?.isUniquelyIdentifiable && (
				<VariantLabelledPropertyX
					variant='error'
					style={{ marginLeft: 4, marginBottom: 8 }}>
					{strings.articleMayNotHaveASerialNumberRemoveFromAllowances}
				</VariantLabelledPropertyX>
			)}
		</div>
	);
};
