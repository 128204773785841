import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IReturnsQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { SuppliersCacheContext } from '../../../../contacts/suppliers/SuppliersCacheContext';

interface IProps {
	params: IReturnsQueryParams;
	onFilter: (params: IReturnsQueryParams) => void;
	paddingLeft: number;
}

// TODO fix this, is this correct?
const exclusions: (keyof IReturnsQueryParams)[] = [];

export const ReturnsFilter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const [suppliers] = usePreloadCacheContext(SuppliersCacheContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.dateCreated}
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<DateFilterItem
				label={strings.returnDate}
				after={params.returnedAfter}
				before={params.returnedBefore}
				setSelected={(after, before) => onFilter({ ...params, returnedAfter: after, returnedBefore: before })}
			/>
			<ListFilterComponent<string>
				label={strings.suppliers}
				selected={params.supplierIds}
				options={suppliers}
				setSelected={value => onFilter({ ...params, supplierIds: value })}
				icon={<HelpOutlineIcon />}
			/>
		</FilterBar>
	);
};
