import { PageX } from 'framework/components/layout/PageX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { articlesInquiriesQuery_single } from 'gen/ApiClient';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ArticlesInquiryDetailPageHeader } from './ArticlesInquiryDetailPageHeader';
import { ArticlesInquiryDetailPageSidebar } from './ArticlesInquiryDetailPageSidebar';
import { ArticlesInquiryDetailContentsCard } from './contents/ArticlesInquiryDetailContentsCard';

export const ArticlesInquiryDetailPage = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [trigger, reload] = useTrigger();
	const [item, reloadDetail] = useApiEffect(articlesInquiriesQuery_single, id);

	useLazyEffect(() => {
		reloadDetail();
	}, [trigger]);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<PageX
			stickyHeader={
				<ArticlesInquiryDetailPageHeader
					item={item}
					reload={reload}
				/>
			}
			stickySidebar={
				<ArticlesInquiryDetailPageSidebar
					item={item}
					reload={reload}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
					maxWidth: 1280,
				}}>
				<div className='df-row-wrap gap-16'>
					<ArticlesInquiryDetailContentsCard
						item={item}
						reload={reload}
						style={{ flexGrow: 2 }}
						className='h-fc'
					/>
				</div>
			</div>
		</PageX>
	);
};
