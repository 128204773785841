import { IProductCatalogItem, IStockAtLocation } from 'gen/ApiClient';
import { IHandoverLine } from '../IHandoverLine';

export const productCatalogItemToLine = (t: IProductCatalogItem, stockAtLocations: IStockAtLocation[]): IHandoverLine => {
	return {
		isSelected: true,
		id: t.id,
		description: t.identifier,
		inventoryItemId: undefined,
		productCatalogItemId: t.id,
		quantity: 1,
		unitListPrice: t.salesListPrice,
		unitPrice: t.salesListPrice,
		productCatalogItem: t,
		vatPercentage: t.vatPercentage,
		isRefundable: false,
		manufacturer: t.manufacturer ?? '',
		model: t.model ?? '',
		rizivHearingAidDefinitionV2IdentificationCode: undefined,
		serialNumber: '',
		inventoryItem: undefined,
		isIncludeOnHandoverCertificate: true,
		assuredInventoryItemId: undefined,
		assuredLineId: undefined,
		assuredProductCatalogItemId: undefined,
		canChangeQuantity: t.isUniquelyIdentifiable === false && t.isInsuranceOrProlongedWarranty === false,
		advanceInvoiceId: undefined,
		stockAtLocations: stockAtLocations,
		isCredit: false,
	};
};
