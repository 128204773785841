import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IQueryResult, ISupplier, ISupplierAccountsQueryParams, suppliersQuery_concurrencyToken, suppliersQuery_queryAccounts } from 'gen/ApiClient';
import { supplierAccountsQueryParamsLsKey } from '../../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: ISupplierAccountsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	hasOutstandingBalance: undefined,
	outstandingBalanceLessThan: undefined,
	outstandingBalanceMoreThan: undefined,
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<ISupplier>, ISupplierAccountsQueryParams>
			defaultParams={DefaultParams}
			queryF={suppliersQuery_queryAccounts}
			concurrencyF={suppliersQuery_concurrencyToken}
			localStorageKey={supplierAccountsQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
