import { DialogProps } from '@material-ui/core';
import { SelectUniquelyIdentifiableProductCatalogItemByTypeDialog } from 'app/main/inventoryManagement/productCatalog/search/SelectUniquelyIdentifiableProductCatalogItemByTypeDialog';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { inventoryItemsCommand_switchProductCatalogItem, IProductCatalogItem, TangibleType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	inventoryItemId: string;
	type: TangibleType;
}

export const SwitchProductCatalogItemForm = ({ cancel, confirm, inventoryItemId, type, ...rest }: IProps) => {
	const [submit, isSubmitting] = useFormSubmit(inventoryItemsCommand_switchProductCatalogItem);
	const notify = useSnackbarNotify();
	const strings = useLocalization();

	const onSubmit = async (productCatalogItem: IProductCatalogItem) => {
		const r = await submit({ inventoryItemId: inventoryItemId, productCatalogItemId: productCatalogItem.id });
		handleValidateResponseBase(
			r,
			error => notify(error, 'error'),
			() => confirm()
		);
	};

	return (
		<SelectUniquelyIdentifiableProductCatalogItemByTypeDialog
			{...rest}
			title={strings.linkWithAnotherArticleFromProductCatalog}
			confirm={onSubmit}
			cancel={cancel}
			isSubmitting={isSubmitting}
			type={type}
		/>
	);
};
