import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IQueryResult, ISubscriptionsQueryParams, ISubscriptionSummary, subscriptionsQuery_concurrencyToken, subscriptionsQuery_query } from 'gen/ApiClient';
import { subscriptionsQueryParamsLsKey } from '../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: ISubscriptionsQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'key' },
	pageParams: { index: 1, size: 10 },
	statuses: [],
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<ISubscriptionSummary>, ISubscriptionsQueryParams>
			defaultParams={DefaultParams}
			queryF={subscriptionsQuery_query}
			concurrencyF={subscriptionsQuery_concurrencyToken}
			localStorageKey={subscriptionsQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
