import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { SelectableColumn } from '../SelectableColumn';
import { createColumnRecord } from '../utils/createColumnRecord';
import { createHeaders } from '../utils/createHeaders';
import { ViewContext } from './ViewContext';

// enkel hier nodig
const DefaultColumns: SelectableColumn[] = ['date', 'stockValueChange', 'countInStockChange'];

export const ViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);
	const colRecord = useMemo(() => createColumnRecord(strings), [strings]);
	const headers = useMemo(() => createHeaders(colRecord), [colRecord]);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
