import { Divider } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import {
	healthInsuranceFundMetaCommand_deleteAdvisoryPhysicianAddress,
	healthInsuranceFundMetaCommand_deleteThirdPayerArrangement,
	IHealthInsuranceFundWithAddressDto,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PatchHifAdvisoryPhysicianAddressForm } from './forms/PatchHifAdvisoryPhysicianAddressForm';
import { PatchHifThirdPayerArrangementAddressForm } from './forms/PatchHifThirdPayerArrangementAddressForm';
import { HifChipX } from './HifChipX';

interface IProps {
	reload: VoidFunction;
	item: IHealthInsuranceFundWithAddressDto;
	style?: React.CSSProperties;
}

export const HifDetailsContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onPatchAdvisoryPhysicianAddress = () => {
		open(
			<PatchHifAdvisoryPhysicianAddressForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onPatchThirdPayerArrangementAddress = () => {
		open(
			<PatchHifThirdPayerArrangementAddressForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDeleteAdvisoryPhysicianAddress = () => {
		open(
			<AlertDialog
				open
				title={strings.deleteWhat(strings.address)}
				content={strings.deleteWhatQuestion(`${strings.address} (${strings.advisoryPhysician})`)}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => healthInsuranceFundMetaCommand_deleteAdvisoryPhysicianAddress({ hifCode: item.code! })}
				accept={confirm}
			/>
		);
	};

	const onDeleteThirdPayerArrangementAddress = () => {
		open(
			<AlertDialog
				open
				title={strings.deleteWhat(strings.address)}
				content={strings.deleteWhatQuestion(`${strings.address} (${strings.thirdPayerArrangement})`)}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => healthInsuranceFundMetaCommand_deleteThirdPayerArrangement({ hifCode: item.code! })}
				accept={confirm}
			/>
		);
	};

	return (
		<div
			className='df-col gap-8'
			style={style}>
			<HifChipX
				item={item}
				style={{ marginRight: 'auto' }}
			/>
			<CaptionVerticalLabelledPropertyX label={strings.name}>{item.name}</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.code}>{item.code}</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.email}>
				<StringValueOrNotApplicable>{item.email}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={`${strings.email} (2)`}>
				<StringValueOrNotApplicable>{item.alternativeEmail}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.phone}>
				<StringValueOrNotApplicable>{item.phone}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={`${strings.phone} (2)`}>
				<StringValueOrNotApplicable>{item.alternativePhone}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={`Fax`}>
				<StringValueOrNotApplicable>{item.fax}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.website}>
				<StringValueOrNotApplicable>{item.website}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={`${strings.address} (${strings.thirdPayerArrangement})`}
				edit={onPatchThirdPayerArrangementAddress}>
				<div className='df-row-ac gap-4'>
					{item.hasThirdPayerArrangementAddress && (
						<StatusIcon
							status='warning'
							fontSize='small'
						/>
					)}
					<StringValueOrNotApplicable>{item.thirdPayerArrangementFormattedAddress}</StringValueOrNotApplicable>
				</div>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={`${strings.address} (${strings.advisoryPhysician})`}
				edit={onPatchAdvisoryPhysicianAddress}>
				<div className='df-row-ac gap-4'>
					{item.hasAdvisoryPhysicianAddress && (
						<StatusIcon
							status='warning'
							fontSize='small'
						/>
					)}
					<StringValueOrNotApplicable>{item.advisoryPhysicianFormattedAddress}</StringValueOrNotApplicable>
				</div>
			</CaptionVerticalLabelledPropertyX>
			<div className='fg1'></div>
			<Divider />
			<div className='df-col'>
				{item.hasThirdPayerArrangementAddress && (
					<TextLinkButton
						startIcon={<DeleteOutlineOutlinedIcon />}
						hoverColor='secondary'
						onClick={onDeleteThirdPayerArrangementAddress}>
						{strings.deleteAddressThirdPartyPayer}
					</TextLinkButton>
				)}
				{item.hasAdvisoryPhysicianAddress && (
					<TextLinkButton
						startIcon={<DeleteOutlineOutlinedIcon />}
						hoverColor='secondary'
						onClick={onDeleteAdvisoryPhysicianAddress}>
						{strings.deleteAddressAdvisoryPhysician}
					</TextLinkButton>
				)}
			</div>
		</div>
	);
};
