import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const CashMinusIcon = ({ viewBox = '0 0 24 24', ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			{...props}
			viewBox={viewBox}>
			<path d='M15 15V17H23V15M14.97 11.61C14.85 10.28 13.59 8.97 12 9C10.3 9.03 9 10.3 9 12C9 13.7 10.3 14.94 12 15C12.38 15 12.77 14.92 13.14 14.77C13.41 13.67 13.86 12.63 14.97 11.61M13 16H7C7 14.9 6.11 14 5 14V10C6.11 10 7 9.11 7 8H17C17 9.11 17.9 10 19 10V10.06C19.67 10.06 20.34 10.18 21 10.4V6H3V18H13.32C13.1 17.33 13 16.66 13 16Z' />
		</SvgIcon>
	);
};
