import { DialogProps } from '@material-ui/core';
import React from 'react';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IUserDto, usersCommand_patchPreferredLocation } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { SelectLocationForm } from '../../locations/forms/SelectLocationForm';

interface IProps extends DialogProps {
	user: IUserDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const UserPreferredLocationForm = ({ user, confirm, cancel, ...rest }: IProps) => {
	const [submit, isSubmitting] = useFormSubmit(usersCommand_patchPreferredLocation);
	const notify = useSnackbarNotify();
	const strings = useLocalization();

	const onSubmit = async (id: string, setError: (error: string) => void) => {
		const r = await submit({ userId: user.id, preferredLocationId: id });
		handleValidateResponseBase(r, setError, () => {
			notify(strings.changedWhat(strings.preferredLocation));
			confirm();
		});
	};

	return (
		<SelectLocationForm
			{...rest}
			confirm={onSubmit}
			cancel={cancel}
			isSubmitting={isSubmitting}
			title={`${strings.preferredLocationFor}: ${user.firstName}`}
			initialId={user.preferredLocationId}
		/>
	);
};
