import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	ExactOnlineGLAccountMatchMode,
	IExactOnlineSettingsDto,
	IPatchExactOnlineGLAccountMatchModeRequest,
	exactOnlineSettingsCommand_patchGLAccountMathModel,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchExactOnlineGLAccountMatchModeRequest>({
		matchMode: yup.mixed<ExactOnlineGLAccountMatchMode>().required(),
	});
};

interface IProps extends DialogProps {
	item: IExactOnlineSettingsDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

// TODO
export const SetExactOnlineGLAccountMatchModeRequestForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [submit, isSubmitting] = useFormSubmit(exactOnlineSettingsCommand_patchGLAccountMathModel);
	const strings = useLocalization();
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IPatchExactOnlineGLAccountMatchModeRequest, helpers: FormikHelpers<IPatchExactOnlineGLAccountMatchModeRequest>) => {
		const r = await submit(values);
		handleValidateResponseBase(r, e => notify(e, 'error'), confirm);
	};

	return (
		<Formik<IPatchExactOnlineGLAccountMatchModeRequest>
			validateOnMount
			initialValues={{
				matchMode: item.glAccountMatchMode,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.changeWhat(strings.type)}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormExtendedRadioGroupField<IPatchExactOnlineGLAccountMatchModeRequest, ExactOnlineGLAccountMatchMode>
							label={strings.mode}
							name='matchMode'
							options={[
								{
									value: 'UseProductCatalog',
									label: `Gebruik codes van artikelen uit product cataloog`,
									caption: `Elk artikel uit product cataloog wordt voorzien van een code uit de grootboekrekening`,
								},
								{ value: 'UseFallback', label: `Gebruik fallback`, caption: `Codes van grootboekrekeningen worden bepaald adhv btw %` },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
