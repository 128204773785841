import { Typography, useTheme } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import React, { useMemo } from 'react';
import { LabelledPropertyXIcon } from './LabelledPropertyXIcon';

export interface ILabelledPropertyXProps {
	link?: VoidFunction;
	edit?: VoidFunction;
	icon?: React.ReactNode;
	label?: string;
	children?: React.ReactNode;
	style?: React.CSSProperties;
	addPadding?: boolean;
	iconPosition?: 'start' | 'middle' | 'end';
	editPosition?: 'start' | 'end';
}

const IconFontSize = 18;
const Padding = 3;

export const LabelledPropertyX = ({ link, edit, icon, label, children, style, addPadding, iconPosition, editPosition }: ILabelledPropertyXProps) => {
	const [isHovered, enter, leave] = useStateBoolean(false);
	const theme = useTheme();
	const isLink = useMemo(() => link !== undefined, [link]);
	const isEdit = useMemo(() => edit !== undefined, [edit]);
	const iconPositionX = useMemo(() => iconPosition ?? 'middle', [iconPosition]);
	const editPositionX = useMemo(() => editPosition ?? 'end', [editPosition]);

	const onClick = () => {
		if (link) {
			link();
		} else if (edit) {
			edit();
		}
	};

	return (
		<div
			className='df-row-ac'
			style={{
				color: (isLink === true || isEdit === true) && isHovered ? theme.palette.primary.main : theme.palette.text.primary,
				textDecoration: isLink === true && isHovered === true ? 'underline' : 'unset',
				cursor: isEdit || isLink ? 'pointer' : 'unset',
				paddingTop: addPadding === true ? Padding : 0,
				paddingBottom: addPadding === true ? Padding : 0,
				gap: 4,
				...style,
			}}
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={onClick}>
			{edit && editPositionX === 'start' && (
				<div
					className='df-col-ac icon-wrapper'
					style={{
						fontSize: IconFontSize,
						color: theme.palette.primary.main,
						cursor: 'pointer',
						visibility: isHovered ? 'inherit' : 'hidden',
					}}
					onClick={e => {
						e.stopPropagation();
						edit();
					}}>
					<EditOutlinedIcon />
				</div>
			)}
			{icon && iconPositionX === 'start' && <LabelledPropertyXIcon icon={icon} />}
			{label && (
				<Typography
					variant='body2'
					style={{
						whiteSpace: 'pre-line',
						color: theme.palette.text.secondary,
					}}>
					{`${label}:`}
				</Typography>
			)}
			{icon && iconPositionX === 'middle' && <LabelledPropertyXIcon icon={icon} />}
			{children}
			{icon && iconPositionX === 'end' && <LabelledPropertyXIcon icon={icon} />}
			{edit && editPositionX === 'end' && (
				<div
					className='df-col-ac icon-wrapper'
					style={{
						fontSize: IconFontSize,
						color: theme.palette.primary.main,
						cursor: 'pointer',
						visibility: isHovered ? 'inherit' : 'hidden',
					}}
					onClick={e => {
						e.stopPropagation();
						edit();
					}}>
					<EditOutlinedIcon />
				</div>
			)}
		</div>
	);
};
