import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { IDelivery } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { DeliveriesSelectableColumn } from './DeliveriesSelectableColumn';

export const createHeaders = (
	colRecord: Record<DeliveriesSelectableColumn, string>,
	strings: IStrings
): Record<DeliveriesSelectableColumn, IHeader<IDelivery>> => {
	return {
		countItems: createSortableHeaderWithKey<IDelivery, DeliveriesSelectableColumn>(colRecord, 'countItems', s => s.countItems.toString()),
		created: createSortableHeaderWithKey<IDelivery, DeliveriesSelectableColumn>(colRecord, 'created', s => formatDate(s.created)),
		deliveryDate: createSortableHeaderWithKey<IDelivery, DeliveriesSelectableColumn>(colRecord, 'deliveryDate', s => formatDate(s.deliveryDate)),
		reference: createSortableHeaderWithKey<IDelivery, DeliveriesSelectableColumn>(colRecord, 'reference', s => s.reference),
		externalReference: createSortableHeaderWithKey<IDelivery, DeliveriesSelectableColumn>(colRecord, 'externalReference', s => s.externalReference),
		supplierIdentifier: createSortableHeaderWithKey<IDelivery, DeliveriesSelectableColumn>(colRecord, 'supplierIdentifier', s => s.supplierIdentifier),
		lastUpdated: createSortableHeaderWithKey<IDelivery, DeliveriesSelectableColumn>(colRecord, 'lastUpdated', p => formatDate(p.lastUpdated)),
		locationIdentifier: createSortableHeaderWithKey<IDelivery, DeliveriesSelectableColumn>(colRecord, 'locationIdentifier', s => s.locationIdentifier),
		dispatchReference: createSortableHeaderWithKey<IDelivery, DeliveriesSelectableColumn>(colRecord, 'dispatchReference', s => s.dispatchReference),
		dispatchDate: createSortableHeaderWithKey<IDelivery, DeliveriesSelectableColumn>(colRecord, 'dispatchDate', s => formatDate(s.dispatchDate)),
	};
};
