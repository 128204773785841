import { Button, Card, CardContent, useTheme } from '@material-ui/core';
import EuroIcon from '@material-ui/icons/Euro';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { setLineErrors } from 'framework/forms/utils/setLineErrors';
import { useParamsFromQueryContext } from 'framework/hooks/useParamsFromQueryContext';
import { useRouteQueryParam } from 'framework/router/useRouteQueryParam';
import { ISalesQueryParams, SalesType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { PrintSalesTicketDialog } from 'shared/dialogs/thermalPrinter/PrintSalesTicketDialog';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { FinancialsPageBreadcrumbTitle } from '../financial/FinancialsPageBreadcrumbTitle';
import { SalesRoute } from '../financial/routes';
import { IInvoiceLine } from '../financial/sales/forms/invoice/IInvoiceLine';
import { SearchAndSelectProductsForSale } from '../financial/sales/forms/invoice/SearchAndSelectProductsForSale';
import { SearchProvider } from '../financial/sales/forms/invoice/context/SearchProvider';
import { SalesListQueryContext } from '../financial/sales/list/SalesListQueryContext';
import { clearSalesFilterParams } from '../financial/sales/list/clearSalesFilterParams';
import { useCurrentLocation } from '../settings/locations/useCurrentLocation';
import { CheckoutForm } from './CheckoutForm';

export const CashRegister = () => {
	const strings = useLocalization();
	const [lines, setLines] = useState<IInvoiceLine[]>([]);
	const location = useCurrentLocation();
	const theme = useTheme();
	const { open, confirm, cancel } = useDialogsContext();
	const { params, setParams } = useParamsFromQueryContext(SalesListQueryContext);
	const { push } = useHistory();
	const patientId = useRouteQueryParam('patientId');

	if (location === undefined) {
		return <div></div>;
	}

	const onViewCashSales = () => {
		const pars: ISalesQueryParams = { ...clearSalesFilterParams(params), prefilter: 'All', types: ['CashSale' as SalesType] };
		setParams(pars);
		push(SalesRoute);
	};

	const onReset = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.lines)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				accept={() => {
					confirm();
					setLines([]);
				}}
			/>
		);
	};

	const onCheckout = () => {
		open(
			<CheckoutForm
				open
				lines={lines}
				confirm={onConfirmCheckout}
				cancel={cancel}
				patientId={patientId}
				onLineErrors={r => {
					cancel();
					setLineErrors('lines', r, lines, setLines);
				}}
			/>
		);
	};

	const onConfirmCheckout = (id: string, isPrintTicket: boolean) => {
		confirm();
		setLines([]);
		if (isPrintTicket) {
			open(
				<PrintSalesTicketDialog
					open
					title='Print ticket'
					cashSaleId={id}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	return (
		<RestrictedPageX
			pageClaim='CashRegister'
			stickyHeader={
				<div
					className='df-row-ac'
					style={{ paddingBottom: 16 }}>
					<FinancialsPageBreadcrumbTitle />
					<PageBreadcrumbDivider />
					<PageBreadcrumbTitle title={strings.cashSales} />
					<div className='fg1'></div>
					<PageXHeaderActions>
						<SmallPrimaryTextButton
							variant='outlined'
							startIcon={<LaunchOutlinedIcon />}
							onClick={onViewCashSales}>
							{strings.viewAllCashSales}
						</SmallPrimaryTextButton>
					</PageXHeaderActions>
				</div>
			}>
			<div
				className='df-col gap-16 h100'
				style={{ padding: 16 }}>
				<Card
					style={{ position: 'relative' }}
					className='fg1'>
					<CardContent
						className='h100'
						style={{ padding: 0 }}>
						<SearchProvider>
							<SearchAndSelectProductsForSale
								lines={lines}
								setLines={setLines}
								location={location}
								withoutNegativeMargin
								customerAccountId={undefined}
								patientId={undefined}
								isShiftVat={false}
								canCredit
							/>
						</SearchProvider>
					</CardContent>
				</Card>
				<Card className='df-row-ab jc-e'>
					<CardContent
						className='df-row'
						style={{ alignItems: 'flex-end' }}>
						<Button
							variant='outlined'
							color='primary'
							startIcon={<SettingsBackupRestoreIcon />}
							style={{ marginRight: theme.spacing(1), width: '130px' }}
							onClick={onReset}>
							{strings.reset}
						</Button>
						<Button
							variant='outlined'
							color='primary'
							size='large'
							startIcon={<EuroIcon />}
							disabled={lines.length === 0}
							onClick={onCheckout}>
							{strings.checkoutVerb}
						</Button>
					</CardContent>
				</Card>
			</div>
		</RestrictedPageX>
	);
};
