import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { PaymentMethodsPreloadCacheContext } from 'app/main/settings/paymentMethods/PaymentMethodsPreloadCacheContext';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { MoneyRangeFilterComponent } from 'framework/filter/MoneyRangeFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IOption } from 'framework/IOption';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ExpenseOrIncomeType, ExpenseOrIncomeTypes, IPaymentsForSupplierAccountQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	params: IPaymentsForSupplierAccountQueryParams;
	onFilter: (params: IPaymentsForSupplierAccountQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof IPaymentsForSupplierAccountQueryParams)[] = ['isMultipleLines', 'isSingleLine'];

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const [paymentMethods] = usePreloadCacheContext(PaymentMethodsPreloadCacheContext);
	const { translatePaymentMethod, expenseOrIncomeTypeRecord } = useContext(RecordContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.dateCreated}
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<DateFilterItem
				label={strings.paymentDate}
				after={params.paymentDateAfter}
				before={params.paymentDateBefore}
				setSelected={(after, before) => onFilter({ ...params, paymentDateAfter: after, paymentDateBefore: before })}
			/>
			<MoneyRangeFilterComponent
				label={strings.amount}
				selected={{ min: params.amountPaidMoreThan, max: params.amountPaidLessThan }}
				setSelected={val => onFilter({ ...params, amountPaidLessThan: val.max, amountPaidMoreThan: val.min })}
				icon={<HelpOutlineIcon />}
			/>
			{paymentMethods && (
				<ListFilterComponent<string>
					options={paymentMethods.map<IOption<string>>(t => ({ id: t.id, identifier: translatePaymentMethod(t.identifier) }))}
					selected={params.paymentMethodIds}
					setSelected={vals => onFilter({ ...params, paymentMethodIds: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.method}
				/>
			)}
			<RecordListFilterComponent<ExpenseOrIncomeType>
				options={ExpenseOrIncomeTypes}
				record={expenseOrIncomeTypeRecord}
				selected={params.expenseOrIncomeTypes as any}
				label={strings.expenseOrIncome}
				icon={<HelpOutlineIcon />}
				setSelected={vals => onFilter({ ...params, expenseOrIncomeTypes: vals })}
			/>
			<BooleanRadioFilterComponent
				trueText={strings.withWhat(strings.remark)}
				falseText={strings.withoutWhat(strings.remark)}
				selected={params.hasComment}
				setSelected={val => onFilter({ ...params, hasComment: val })}
				label={strings.remark}
				icon={<HelpOutlineIcon />}
			/>
		</FilterBar>
	);
};
