import { IProductCatalogItem } from 'gen/ApiClient';
import { IRegisterDeliveryLineModel } from './IRegisterDeliveryLineModel';
import { v1 as uuid } from 'uuid';

export const mapProductCatalogItemToRegisterDeliveryLineModel = (t: IProductCatalogItem): IRegisterDeliveryLineModel => {
	return {
		id: t.isUniquelyIdentifiable ? uuid() : t.id,
		description: t.identifier,
		productCatalogItemId: t.id,
		quantity: 1,
		productCatalogItem: t,
		serialNumber: undefined,
		tailorMadeForPatientId: undefined,
		tailorMadeForPatientIdentifier: undefined,
		autoGenerateSerialNumber: false,
	};
};
