import { CardProps, Divider } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { SuppliersCacheContext } from 'app/main/contacts/suppliers/SuppliersCacheContext';
import { LocationsPreloadCacheContext } from 'app/main/settings/locations/LocationsPreloadCacheContext';
import { useUsersAsFilterOptions } from 'app/main/settings/users/useUsersAsFilterOptions';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IRepairsQueryMetadata, IRepairsQueryParams, RepairProcessStatus, RepairProcessStatuses, RepairWarning, RepairWarnings } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { RepairsListPrefilter } from './RepairsListPrefilter';

interface IProps extends CardProps {
	params: IRepairsQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IRepairsQueryParams>>;
	metadata: IRepairsQueryMetadata;
}

export const RepairsListFilterCard = ({ params, setParams, metadata, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const { repairProcessStatusRecord: repairStatusRecord, repairWarningRecord } = React.useContext(RecordContext);
	const filterUsers = useUsersAsFilterOptions();
	const [suppliers] = usePreloadCacheContext(SuppliersCacheContext);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const isFilteredX = useMemo(() => isFiltered(params, []), [params]);
	const onFilter = (pars: IRepairsQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params, [])));

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<RepairsListPrefilter
				selectedPrefilter={params.prefilter}
				setSelectedPrefilter={key => onFilter({ ...params, prefilter: key })}
				metadata={metadata}
			/>
			<Divider />
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<RecordListFilterComponent<RepairProcessStatus>
					options={RepairProcessStatuses}
					record={repairStatusRecord}
					selected={params.statuses as any}
					label={strings.status}
					icon={<HelpOutlineIcon />}
					setSelected={vals => onFilter({ ...params, statuses: vals })}
				/>
				<RecordListFilterComponent<RepairWarning>
					options={RepairWarnings}
					record={repairWarningRecord}
					selected={params.warnings as any}
					label={strings.warning}
					icon={<HelpOutlineIcon />}
					setSelected={vals => onFilter({ ...params, warnings: vals })}
				/>
				<DateFilterItem
					label={strings.dateCreated}
					after={params.createdAfter}
					before={params.createdBefore}
					setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
				/>
				{suppliers && (
					<ListFilterComponent<string>
						label={strings.suppliers}
						selected={params.supplierIds}
						options={suppliers}
						setSelected={value => onFilter({ ...params, supplierIds: value })}
						icon={<HelpOutlineIcon />}
					/>
				)}
				{filterUsers && (
					<ListFilterComponent<string>
						options={filterUsers}
						selected={params.handlingUserIds}
						setSelected={vals => onFilter({ ...params, handlingUserIds: vals })}
						label={strings.user}
						icon={<HelpOutlineIcon />}
						title={strings.user}
					/>
				)}
				{locations && (
					<ListFilterComponent<string>
						options={locations}
						selected={params.locationIds}
						setSelected={vals => onFilter({ ...params, locationIds: vals })}
						label={strings.location}
						icon={<HelpOutlineIcon />}
						title={strings.location}
					/>
				)}
			</div>
		</CardX>
	);
};
