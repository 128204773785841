import { IProductCatalogItem, IStockInfo } from 'gen/ApiClient';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { ArticlePropertyDefinitionsCard } from './ArticlePropertyDefinitionsCard';
import { HearingAidPropertiesCard } from './HearingAidPropertiesCard';
import { InsuranceOrProlongedWarrantyTermCard } from './InsuranceOrProlongedWarrantyTermCard';
import { PricesCard } from './PricesCard';
import { StockCard } from './StockCard';
import { TangiblePropertiesCard } from './TangiblePropertiesCard';

interface IProps extends IReloadTriggerProps {
	item: IProductCatalogItem;
	stockInfo: IStockInfo;
	isDirty: boolean;
	reload: VoidFunction;
}

export const OverviewPage = ({ item, stockInfo, isDirty, reload, reloadTrigger }: IProps) => {
	return (
		<div className='df-row-wrap gap-16'>
			<PricesCard
				item={item}
				reload={reload}
				style={{ width: 300, height: 'fit-content' }}
			/>
			{item.isTangible && (
				<React.Fragment>
					<TangiblePropertiesCard
						item={item}
						reload={reload}
						style={{ width: 350, height: 'fit-content' }}
					/>
					{item.isHearingInstrument && (
						<HearingAidPropertiesCard
							reloadTrigger={reloadTrigger}
							item={item}
							reload={reload}
							style={{ width: 300, height: 'fit-content' }}
						/>
					)}
					<StockCard
						display={item.manufacturerCommaModel ?? ''}
						item={stockInfo}
						className='h-fc'
						reload={reload}
						isDirty={isDirty}
						style={{ width: 350, height: 'fit-content' }}
					/>
					{item.isUniquelyIdentifiable && (
						<ArticlePropertyDefinitionsCard
							className='h-fc'
							item={item}
							reload={reload}
							style={{ width: 350, height: 'fit-content' }}
						/>
					)}
				</React.Fragment>
			)}
			{item.isInsuranceOrProlongedWarranty && (
				<InsuranceOrProlongedWarrantyTermCard
					item={item}
					reload={reload}
					style={{ width: 350, height: 'fit-content' }}
				/>
			)}
		</div>
	);
};
