import {
	agendaColorSchemeLsKey,
	agendaGroupSchemeLsKey,
	agendaSelectedEventTypeKeysLsKey,
	agendaSelectedRoomKeysLsKey,
	agendaSelectedUserKeysLsKey,
	agendaTimeScaleLsKey,
	agendaViewLsKey,
} from 'app/localStorageKeys';
import { endOfMonth, isEqual, startOfMonth } from 'date-fns';
import { useLocalStorage } from 'framework/hooks/useLocalStorage';
import { nlBELocaleEndOfWeek } from 'framework/utils/date/nlBELocaleEndOfWeek';
import { nlBELocaleStartOfWeek } from 'framework/utils/date/nlBELocaleStartOfWeek';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { getNow } from 'shared/utils/getNow';
import { useCalendarResources } from '../../context/useLocalizedCalendarResources';
import { AgendaColorScheme } from '../AgendaColorScheme';
import { AgendaGroupScheme } from '../AgendaGroupScheme';
import { AgendaTimeScale } from '../AgendaTimeScale';
import { AgendaViewType } from '../AgendaViewType';
import { AgendaContext } from './AgendaContext';
import { AgendaQueryContext } from './AgendaQueryContext';
import { filterEvents } from './filterEvents';

export const AgendaProvider = ({ children }: any) => {
	const [date, setDate] = useState<Date>(getNow());
	const { params, setParams, queryResult: response, init, reload } = useContext(AgendaQueryContext);
	const { rooms, users, eventTypes, reload: reloadCalendarResources } = useCalendarResources();

	const [view, setView] = useLocalStorage<AgendaViewType>(agendaViewLsKey, 'day');
	const [colorScheme, setColorScheme] = useLocalStorage<AgendaColorScheme>(agendaColorSchemeLsKey, 'types');
	const [groupScheme, setGroupScheme] = useLocalStorage<AgendaGroupScheme>(agendaGroupSchemeLsKey, 'none');
	const [selectedEventTypeResourceKeys, setSelectedEventTypeResourceKeys] = useLocalStorage<string[]>(
		agendaSelectedEventTypeKeysLsKey,
		eventTypes.map(t => t.key)
	);
	const [selectedUserResourceKeys, setSelectedUserResourceKeys] = useLocalStorage<string[]>(
		agendaSelectedUserKeysLsKey,
		users.map(t => t.key)
	);
	const [selectedRoomResourceKeys, setSelectedRoomResourceKeys] = useLocalStorage<string[]>(
		agendaSelectedRoomKeysLsKey,
		rooms.map(t => t.key)
	);
	const [timeScale, setTimeScale] = useLocalStorage<AgendaTimeScale>(agendaTimeScaleLsKey, 60);

	const events = useMemo(
		() => filterEvents(response?.events ?? [], selectedEventTypeResourceKeys, selectedUserResourceKeys, selectedRoomResourceKeys),
		[response, selectedEventTypeResourceKeys, selectedUserResourceKeys, selectedRoomResourceKeys]
	);

	const onSetDate = (val: Date) => {
		setDate(val);
		const after = nlBELocaleStartOfWeek(startOfMonth(val));
		const before = nlBELocaleEndOfWeek(endOfMonth(val));
		if (isEqual(after, params.afterDate) === false || isEqual(before, params.beforeDate) === false) {
			setParams({ beforeDate: before, afterDate: after });
		}
	};

	const onReload = useCallback(() => {
		reload();
		reloadCalendarResources();
		// eslint-disable-next-line
	}, []);

	return (
		<AgendaContext.Provider
			value={{
				events: events,
				beforeDate: params.beforeDate,
				colorScheme: colorScheme,
				date: date,
				eventTypes: eventTypes,
				groupScheme: groupScheme,
				init: init,
				reload: onReload,
				rooms: rooms,
				selectedEventTypeResourceKeys: selectedEventTypeResourceKeys,
				selectedRoomResourceKeys: selectedRoomResourceKeys,
				selectedUserResourceKeys: selectedUserResourceKeys,
				setColorScheme: setColorScheme,
				setDate: onSetDate,
				setGroupScheme: setGroupScheme,
				setSelectedEventTypeResourceKeys: setSelectedEventTypeResourceKeys,
				setSelectedRoomResourceKeys: setSelectedRoomResourceKeys,
				setSelectedUserResourceKeys: setSelectedUserResourceKeys,
				setView: setView,
				users: users,
				view: view,
				timeScale: timeScale,
				setTimeScale: setTimeScale,
			}}>
			{children}
		</AgendaContext.Provider>
	);
};
