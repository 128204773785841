import { DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { setLineErrors } from 'framework/forms/utils/setLineErrors';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	IPurchaseUbl,
	IRegisterPurchaseFromUblRequest,
	ValidUntilTimeUnits,
	purchasesQuery_nextReference,
	purchasesQuery_settings,
	purchasesUblCommand_register,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { FormSelectOrCreateSupplierField } from '../../../../contacts/suppliers/forms/FormSelectOrCreateSupplierField';
import { SearchProvider } from '../context/SearchProvider';
import { IPurchaseFromUblFormLine } from './IPurchaseFromUblFormLine';
import { PurchaseFromUblFormConfirmation } from './PurchaseFromUblFormConfirmation';
import { SearchAndSelectProductForPurchaseFromUbl } from './SearchAndSelectProductForPurchaseFromUbl';
import { mapFormLineToRequest, mapPurchaseUblLineToFormLine, validateLines } from './utils';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterPurchaseFromUblRequest>({
			validUntil: yup.date().required(),
			lines: yup.mixed(),
			reference: yup.string().required(),
			externalReference: yup.string(),
			isAutoSelectReference: yup.boolean().defined(),
			supplierId: yup.string().required(),
			purchaseDate: yup.date().required(),
			ublBufferId: yup.string(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IRegisterPurchaseFromUblRequest)[]> = {
	0: ['supplierId'],
	1: ['reference', 'isAutoSelectReference', 'externalReference', 'purchaseDate', 'validUntil'],
	2: ['lines'],
	3: [],
};

interface IProps extends DialogProps {
	item: IPurchaseUbl;
	confirm: (id: string) => void;
	cancel: VoidFunction;
	ublBufferId?: string;
}

export const RegisterPurchaseFromUblRequestForm = ({ item, confirm, cancel, ublBufferId, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, setLines] = useState<IPurchaseFromUblFormLine[]>(item.lines.map(mapPurchaseUblLineToFormLine));
	const [step, setStep] = useState<number>(0);
	const schema = useMemo(() => createSchema(strings), [strings]);
	const [create, isSubmitting] = useFormSubmit(purchasesUblCommand_register);
	const [isShiftVat, setIsShiftVat] = useState<boolean>(false);
	const notify = useSnackbarNotify();
	const now = useNow();

	const handleSubmit = async (values: IRegisterPurchaseFromUblRequest, helpers: FormikHelpers<IRegisterPurchaseFromUblRequest>) => {
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await create({ ...values, lines: lines.map((t, index) => mapFormLineToRequest(t, index, isShiftVat)) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify('Aankoop geregistreerd');
				confirm(r.result.objectId);
			} else {
				setLineErrors('lines', r, lines, setLines);
			}
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterPurchaseFromUblRequest>
			validateOnMount
			initialValues={{
				lines: [],
				reference: '',
				isAutoSelectReference: true,
				validUntil: item.dueDate ?? (undefined as any),
				supplierId: item.supplierId ?? '',
				purchaseDate: item.issueDate,
				externalReference: item.reference,
				ublBufferId: ublBufferId,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						lines={lines}
						setLines={setLines}
						step={step}
						setStep={setStep}
						stepsRecord={stepsRecord}
						schema={schema}
						isShiftVat={isShiftVat}
						setIsShiftVat={setIsShiftVat}
						item={item}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	lines: IPurchaseFromUblFormLine[];
	setLines: React.Dispatch<React.SetStateAction<IPurchaseFromUblFormLine[]>>;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof IRegisterPurchaseFromUblRequest)[]>;
	schema: yup.ObjectSchema<IRegisterPurchaseFromUblRequest>;
	setIsShiftVat: React.Dispatch<React.SetStateAction<boolean>>;
	isShiftVat: boolean;
	item: IPurchaseUbl;
}

export const InnerDialog = ({
	cancel,
	lines,
	setLines,
	isSubmitting,
	step,
	setStep,
	stepsRecord,
	schema,
	isShiftVat,
	setIsShiftVat,
	item,
	...rest
}: IInnerProps) => {
	const props = useFormikContext<IRegisterPurchaseFromUblRequest>();
	const strings = useLocalization();
	useNextReferenceInterceptor(purchasesQuery_nextReference, props);
	const [settings] = useApiEffect(purchasesQuery_settings);
	const notify = useSnackbarNotify();

	const setLinesX = (lines: IPurchaseFromUblFormLine[]) => {
		// setFormValue<IRegisterPurchaseFromUblRequest>('lines', lines.map((t, index) => lineToModel(t, index, undefined)), props);
		setFormValue<IRegisterPurchaseFromUblRequest>('lines', [], props);
		setLines(lines);
	};

	const validateMore = (): boolean => {
		if (step === 2) {
			const lineErrors = validateLines(lines, strings);
			if (lineErrors.length > 0) {
				const errorMessage = lineErrors
					.map(t => {
						if (t.allowanceChargeIndex !== undefined) {
							return `Lijn ${t.index} (toeslag: ${t.allowanceChargeIndex}): ${t.error}`;
						} else {
							return `Lijn ${t.index}: ${t.error}`;
						}
					})
					.reduce((previous, current) => `${previous}\n${current}`, '');
				console.log(errorMessage);
				notify(errorMessage, 'error');
				// notify!
				return false;
			}
			return true;
		} else {
			return true;
		}
	};

	const onSetDifferenceLine = (line: IPurchaseFromUblFormLine) => {
		setLinesX([...lines, line]);
		setStep(2);
	};

	if (settings === undefined) {
		return <div></div>;
	}

	return (
		<FullScreenDialogWithStepper
			{...rest}
			fullScreenStep={2}
			title={strings.newWhat(strings.purchase)}
			step={step}
			labels={[strings.supplier, strings.reference, strings.items, strings.confirmation]}>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<FormSelectOrCreateSupplierField<IRegisterPurchaseFromUblRequest>
						name='supplierId'
						label={strings.supplier}
						selectIfSingle={false}
					/>
				)}
				{step === 1 && (
					<>
						<FormSingleCheckboxField<IRegisterPurchaseFromUblRequest>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IRegisterPurchaseFromUblRequest>
							name='reference'
							label={strings.invoiceNumber}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormTextField<IRegisterPurchaseFromUblRequest>
							name='externalReference'
							label={strings.externalReference}
						/>
						<FormDatePicker<IRegisterPurchaseFromUblRequest>
							name='purchaseDate'
							label={strings.purchaseDate}
							required
						/>
						<FormDatePickerWithUnit<IRegisterPurchaseFromUblRequest>
							name='validUntil'
							label='Te betalen vóór'
							units={ValidUntilTimeUnits}
							defaultUnit='Months'
							defaultValue={settings.defaultValidUntil}
						/>
					</>
				)}
				{step === 2 && (
					<SearchAndSelectProductForPurchaseFromUbl
						lines={lines}
						setLines={setLinesX}
						supplierId={props.values.supplierId}
						isShiftVat={isShiftVat}
						setIsShiftVat={setIsShiftVat}
					/>
				)}
				{step === 3 && (
					<PurchaseFromUblFormConfirmation
						lines={lines}
						isShiftVat={isShiftVat}
						item={item}
						onSetDifferenceLine={onSetDifferenceLine}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
				validateMore={validateMore}
			/>
		</FullScreenDialogWithStepper>
	);
};
