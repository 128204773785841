import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Try } from 'framework/Try';
import { IUpdateResponse } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

interface IModel {
	date: Date;
	reference: string;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			date: yup.date().required(strings.formRequired(strings.date)),
			reference: yup.string().required(strings.formRequired(strings.reference)),
		})
		.defined();
};

interface IProps extends DialogProps {
	reference: string | undefined;
	date: Date | undefined;
	submitF: (values: IModel) => Promise<Try<IUpdateResponse<IModel>>>;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const DispatchInfoForm = ({ reference, date, submitF, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [update, isSubmitting] = useFormSubmit(submitF);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{
				reference: reference ?? '',
				date: date as any,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{strings.changeSomething(strings.dispatchInfo)}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<IModel>
							name='reference'
							label={strings.dispatchReference}
							required
						/>
						<FormDatePicker<IModel>
							name='date'
							label={strings.dispatchDate}
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<IModel>
						cancel={cancel}
						submitText={strings.changeSomething(strings.dispatchInfo)}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
