import { Card, CardContent, CardProps } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useApiEffectWithDefer } from 'framework/hooks/useApiEffectWithDefer';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { audiogramStandardsQuery_tonalAudiogramImage, audiogramStandardsQuery_tonalAudiogramImageSummariesByPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { AudiogramImageOrEmpty } from './AudiogramImageOrEmpty';
import { LrbButtons } from './LrbButtons';
import { selectImageData } from './selectImageData';
import { TonalImageSelectorButton } from './TonalImageSelectorButton';
import { ToneThresholdImageType } from './ToneThresholdImageType';
import GraphicEqOutlinedIcon from '@material-ui/icons/GraphicEqOutlined';

interface IProps extends IReloadTriggerProps, CardProps {
	patientId: string;
}

export const TonalAudiogramCard = ({ patientId, reloadTrigger, ...rest }: IProps) => {
	const strings = useLocalization();
	const [summaries, reload] = useApiEffect(audiogramStandardsQuery_tonalAudiogramImageSummariesByPatient, patientId);
	const [selectedId, setSelectedId] = useState<string>();
	const [audiogram] = useApiEffectWithDefer(audiogramStandardsQuery_tonalAudiogramImage, selectedId ?? '', isNotNullNorUndefined);
	const [selectedImageType, setSelectedImageType] = useState<ToneThresholdImageType>('both');
	const imageData = useMemo(() => selectImageData(selectedImageType, audiogram), [selectedImageType, audiogram]);
	const showMostRecent = useMemo(
		() => (selectedId !== undefined && summaries !== undefined && summaries.length > 0 && summaries[0].id === selectedId ? true : false),
		[summaries, selectedId]
	);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	useEffect(() => {
		if (summaries !== undefined && summaries.length > 0) {
			setSelectedId(summaries[0].id);
		}
	}, [summaries]);

	return (
		<Card {...rest}>
			<CardContent>
				<CardTitleTypography
					startIcon={<GraphicEqOutlinedIcon style={{ marginRight: 4, opacity: 0.8 }} />}
					title={showMostRecent ? strings.mostRecentTonalAudiogramOfDate : strings.tonalAudiogramOfDate}
					style={{ marginBottom: 8 }}
				/>
				{summaries !== undefined && summaries.length > 0 && (
					<div className='df-row-ac'>
						<TonalImageSelectorButton
							summaries={summaries ?? []}
							selectedId={selectedId}
							setSelectedId={setSelectedId}
						/>
						<LrbButtons
							selectedImage={selectedImageType}
							setSelectedImage={setSelectedImageType}
							audiogram={audiogram}
							style={{ marginLeft: 4 }}
						/>
					</div>
				)}
				<AudiogramImageOrEmpty
					imageData={imageData}
					style={{ maxWidth: 450, width: '100%', paddingRight: 16 }}
				/>
			</CardContent>
		</Card>
	);
};
