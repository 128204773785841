import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { IExactOnlineCustomerAccountDto } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: IExactOnlineCustomerAccountDto;
	style?: React.CSSProperties;
}

export const ExactOnlineCustomerAccountListItemStatusIcon = ({ item, style }: IProps) => {
	if (item.isLinked) {
		return <CheckCircleOutlineIcon style={style} />;
	} else {
		return <BlockIcon style={style} />;
	}
};
