import { ButtonProps, IconButton } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import React from 'react';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { IHeader } from 'framework/table/IHeader';

export const createRemoveHeader = <T extends unknown>(
	onClick: (val: T) => void,
	disabledF: (val: T) => boolean = (val: T) => false,
	onlyShowOnHover: boolean = false,
	cellStyle: React.CSSProperties | undefined = undefined
): IHeader<T> => {
	return createEmptyHeader<T>(
		'remove-header',
		t => (
			<ButtonX
				onClick={() => onClick(t)}
				disabled={disabledF(t)}
			/>
		),
		'right',
		onlyShowOnHover,
		cellStyle
	);
};

const ButtonX = ({ children, ...props }: ButtonProps) => {
	return (
		<IconButton
			{...props}
			size='small'
			color='secondary'>
			<RemoveIcon />
		</IconButton>
	);
};
