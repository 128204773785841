import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IColumnSelectionModel, viewColumnSelectionsCommand_new } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

const createSchema = (strings: IStrings) => {
	return yup.object<IColumnSelectionModel>({
		name: yup.string().required(),
		useAsDefault: yup.boolean().defined(),
		columns: yup.mixed(),
	});
};

interface IProps extends DialogProps {
	view: string;
	columns: string[];
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const ColumnSelectionModelForm = ({ view, columns, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [create, isSubmitting] = useFormSubmit(viewColumnSelectionsCommand_new);

	const handleSubmit = async (values: IColumnSelectionModel, helpers: FormikHelpers<IColumnSelectionModel>) => {
		const r = await create(view, values);
		if (handleFormResponse(r, helpers)) {
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<IColumnSelectionModel>
			validateOnMount
			initialValues={{
				columns: columns,
				name: '',
				useAsDefault: false,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{`Kolom selectie opslaan`}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormTextField<IColumnSelectionModel>
							name='name'
							label={strings.name}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.save}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
