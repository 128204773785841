import { Card, CardContent } from '@material-ui/core';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { RosaEventListComponent } from 'app/main/rosaCalendar/list/RosaEventListComponent';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { FixedWidthCard } from 'framework/components/FixedWidthCard';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { IUpcomingRosaEventsForPatientResponse, rosaEventsQuery_upcomingByPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { IPatientUpcomingEventsCardProps } from './IPatientUpcomingEventsCardProps';
import { RosaPatientRedirectButton } from './RosaPatientRedirectButton';

export const RosaPatientUpcomingEventsCard = ({ item, width, reloadTrigger, reload, ...rest }: IPatientUpcomingEventsCardProps) => {
	const [response, reloadEvents] = useApiEffect(rosaEventsQuery_upcomingByPatient, item.id);

	useLazyEffect(() => {
		reloadEvents();
	}, [reloadTrigger]);

	if (response === undefined) {
		return <></>;
	}

	if (width === undefined) {
		return (
			<Card {...rest}>
				<InnerContent
					response={response}
					reload={reload}
				/>
			</Card>
		);
	} else {
		return (
			<FixedWidthCard
				{...rest}
				width={width}>
				<InnerContent
					response={response}
					reload={reload}
				/>
			</FixedWidthCard>
		);
	}
};

interface IInnerContentProps {
	response: IUpcomingRosaEventsForPatientResponse;
	reload: VoidFunction;
}

const InnerContent = ({ response, reload }: IInnerContentProps) => {
	const strings = useLocalization();

	return (
		<CardContent>
			<CardTitleTypography
				startIcon={<TodayOutlinedIcon style={{ marginRight: 4, opacity: 0.8 }} />}
				title={strings.nextAppointments}
				style={{ marginBottom: 8 }}
			/>
			<RosaPatientRedirectButton
				variant='text'
				size='small'
				color='primary'
				hamsPatientId={response.hamsPatientId}
				redirectRosaPatientRecordId={response.redirectRosaPatientRecordId}
				reload={reload}
				style={{ marginLeft: -5 }}>
				{strings.newWhat(strings.appointment)}
			</RosaPatientRedirectButton>
			{response.events.map(t => (
				<RosaEventListComponent
					key={t.id!}
					event={t}
					mode='patientUpcoming'
				/>
			))}
			{response.events.length === 0 && <NoRecordsFound>{strings.noAppointmentsFound}</NoRecordsFound>}
		</CardContent>
	);
};
