import React from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { FilterBar } from 'framework/filter/FilterBar';
import { isFiltered } from 'framework/utils/isFiltered';
import { ICommonParams } from './ICommonParams';
import { onResetToPage1 } from 'framework/types/onResetToPage1';

const exclusions: (keyof ICommonParams)[] = ['forProductCatalogId', 'forPatientId' as any, 'supplierId' as any, 'isShowAll'];

interface IProps<TParams extends ICommonParams> {
	params: TParams;
	setParams: React.Dispatch<React.SetStateAction<TParams>>;
}

export const Filter = <TParams extends ICommonParams>({ params, setParams }: IProps<TParams>) => {
	const onFilter = (params: TParams) => {
		setParams(onResetToPage1(params));
	};

	return (
		<FilterBar
			disableWrap
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}>
			{/* <ListFilterItem<string>
                options={ProductCatalogItemTypes.map<IOption<string>>(t => ({ id: t, identifier: productCatalogItemTypeRecord[t] }))}
                label={strings.type}
                selected={params.types}
                setSelected={vals => onFilter({ ...params, types: vals })}
                icon={<HelpOutlineIcon />} /> */}
			{/* TODO add filter on has serial number or not */}
			{/* {searchData.categories.length > 0 &&
                <StringListFilterItem
                    label={strings.category}
                    selected={params.categories}
                    options={searchData.categories}
                    icon={<HelpOutlineIcon />}
                    setSelected={(value) => onFilter({ ...params, categories: value })} />
            }
            {searchData.subcategories.length > 0 &&
                <StringListFilterItem
                    label={strings.subcategory}
                    selected={params.subcategories}
                    options={searchData.subcategories}
                    icon={<HelpOutlineIcon />}
                    setSelected={(value) => onFilter({ ...params, subcategories: value })} />
            } */}
		</FilterBar>
	);
};
