import { ITypesAndCategoriesProductCatalogQueryParams } from 'gen/ApiClient';

export const DefaultTypesAndCategoriesProductCatalogQueryParams: ITypesAndCategoriesProductCatalogQueryParams = {
	categories: [],
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	searchString: '',
	subcategories: [],
	types: [],
};
