import { IRegisterSupplierRequest, ISupplierSummaryWithOrderStats } from 'gen/ApiClient';

export const mapRegisterSupplierRequestToSupplierSummaryWithOrderStats = (id: string, request: IRegisterSupplierRequest): ISupplierSummaryWithOrderStats => {
	return {
		id: id,
		name: request.name,
		accountNumber: request.accountNumber,
		brands: request.brands,
		countBrands: request.brands?.length ?? 0,
		countOrders: 0,
		countOrdersToPlaceWithSupplier: 0,
		hasOrders: false,
		hasOrdersToPlaceWithSupplier: false,
		lastOrderDate: undefined,
		defaultDeliveryTime: null as any,
		hasDefaultDeliveryTime: false,
		identifier: request.name ?? '',
	};
};
