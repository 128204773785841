import { Dialog, DialogProps, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IArticlePropertyKey } from 'gen/ApiClient';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ArticlePropertyKeysCacheContext } from '../articlePropertyKeys/context/ArticlePropertyKeysCacheContext';
import { localizeApkLabel } from '../articlePropertyKeys/utils/localizeApkLabel';
import { ArticlePropertyKeyModelForm } from '../forms/articlePropertyDefinitions/ArticlePropertyKeyModelForm';

interface IProps extends DialogProps {
	close: VoidFunction;
}

export const ProductCatalogSettingsDialog = ({ close, ...rest }: IProps) => {
	const strings = useLocalization();
	const [data, load] = usePreloadCacheContext(ArticlePropertyKeysCacheContext);
	const { open, confirm, cancel, isStacked } = useDialogsContext(load);
	const language = useCurrentLanguage();

	const onNew = () => {
		open(
			<ArticlePropertyKeyModelForm
				open
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	const onEdit = (apk: IArticlePropertyKey) => {
		open(
			<ArticlePropertyKeyModelForm
				open
				cancel={cancel}
				confirm={confirm}
				item={apk}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			onClose={close}
			fullWidth>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<SettingsIcon />}>
					<Typography variant='h1'>{`${strings.settings} | ${strings.productCatalog}`}</Typography>
				</DialogGridTitle>
				<GridDividerWithCaption
					caption={strings.fieldsToFill}
					action={
						<SmallPrimaryTextButton
							startIcon={<AddIcon />}
							onClick={onNew}>
							{strings.new}
						</SmallPrimaryTextButton>
					}
				/>
				{data.map(t => (
					<PropertyInGrid
						key={t.key}
						labelGridSize={5}
						label={`${localizeApkLabel(t, language)} [${t.key}]`}
						value={t.isUserDefined ? strings.userDefined : strings.predefined}
						edit={t.isUserDefined ? () => onEdit(t) : undefined}
					/>
				))}
			</DialogContentGrid>
		</Dialog>
	);
};
