import { DialogProps } from '@material-ui/core';
import React, { useState } from 'react';
import { IInventoryItem, inventoryItemsQuery_queryForPurchaseAssurance, IProductCatalogItem } from 'gen/ApiClient';
import { SearchInventoryItemDialog } from '../../../../inventoryManagement/inventoryItems/search/SearchInventoryItemDialog';
import { IPurchaseFormLine } from '../register/IPurchaseFormLine';
import { Mode } from './Mode';
import { ModeDialog } from './ModeDialog';
import { SelectLinesFromThisPurchaseToAssureDialog } from './thisPurchase/SelectLinesFromThisPurchaseToAssureDialog';

interface IProps extends DialogProps {
	assurance: IProductCatalogItem;
	cancel: VoidFunction;
	lines: IPurchaseFormLine[];
	supplierId: string;
	onAdd: (line: (IPurchaseFormLine | IInventoryItem)[]) => void;
}

type State = 'mode' | Mode;

export const SelectProductsToAssureDialog = ({ supplierId, assurance, cancel, lines, onAdd, ...rest }: IProps) => {
	const [state, setState] = useState<State>('mode');

	if (state === 'mode') {
		return (
			<ModeDialog
				{...rest}
				confirm={setState}
				cancel={cancel}
			/>
		);
	} else if (state === 'same') {
		return (
			<SelectLinesFromThisPurchaseToAssureDialog
				{...rest}
				purchaseLines={lines}
				assurance={assurance}
				confirm={lines => onAdd(lines)}
				cancel={cancel}
			/>
		);
	} else if (state === 'other') {
		return (
			<SearchInventoryItemDialog
				queryF={inventoryItemsQuery_queryForPurchaseAssurance}
				selectedIds={lines.filter(t => t.assuredLineId).map(t => t.assuredLineId!)}
				cancel={cancel}
				canSelectF={t =>
					t.isPurchased &&
					t.supplierId === supplierId &&
					(assurance.isInsurance ? t.hasPurchasedInsurance === false : t.hasPurchasedProlongedWarranty === false)
				}
				confirm={line => onAdd([line])}
				defaultSelectableColumns={['status', 'manufacturerAndModel', 'serialNumber', 'supplierIdentifier']}
				defaultParams={{
					searchString: '',
					sortParams: { direction: 'desc', property: 'created' },
					pageParams: { index: 1, size: 10 },
					categories: [],
					subcategories: [],
					isShowAll: false,
					supplierId: supplierId,
				}}
			/>
		);
	} else {
		return <div></div>;
	}
};
