import { ChipX } from 'framework/components/chips/ChipX';
import { SalesType } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface ISaleX {
	type: string | undefined;
}

interface IProps {
	item: ISaleX;
	style?: React.CSSProperties;
}

export const SalesTypeChip = ({ item, style }: IProps) => {
	const { saleTypeRecord } = useContext(RecordContext);
	const type = React.useMemo(() => item.type as SalesType, [item]);

	return (
		<ChipX
			style={style}
			label={saleTypeRecord[type as SalesType]}
			color={type === 'AdvanceInvoice' || type === 'CreditNote' ? 'warning' : 'info'}
		/>
	);
};
