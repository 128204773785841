import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { IRequestForInsuranceAllowance, RfiaWarning } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { RfiaStatusChip } from './RfiaStatusChip';

interface IProps {
	item: IRequestForInsuranceAllowance;
	style?: React.CSSProperties;
}

export const RfiaStatusComponent = ({ item }: IProps) => {
	const { rfiaWarningRecord } = useContext(RecordContext);

	return (
		<div className='df-row-ac'>
			{item.hasWarning && (
				<TooltipWithSpan title={rfiaWarningRecord[item.warning as RfiaWarning]}>
					<StatusIcon
						status='warning'
						style={{ marginRight: 8 }}
					/>
				</TooltipWithSpan>
			)}
			<RfiaStatusChip item={item} />
		</div>
	);
};
