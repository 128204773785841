import { Radio } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { IExactOnlineGLAccountDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IExactOnlineGLAccountDto;
	onSelect: VoidFunction;
	isSelected: boolean;
	style?: React.CSSProperties;
}

export const ExactOnlineGLAccountListItemCardX = ({ item, style, onSelect, isSelected }: IProps) => {
	const strings = useLocalization();
	const [hovered, enter, leave] = useStateBoolean(false);

	return (
		<CardX
			className='df-row gap-8 w100'
			raised={hovered || isSelected}
			onMouseEnter={enter}
			onMouseLeave={leave}
			style={{
				cursor: 'pointer',
				...style,
			}}
			onClick={onSelect}>
			<div className='df-col'>
				<Radio
					style={{ padding: 0 }}
					checked={isSelected}
				/>
			</div>
			<div className='df-col fg1'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.description}</span>
				<span style={{ fontSize: MinorFontSize }}>{`${strings.code}: ${item.code}`}</span>
			</div>
		</CardX>
	);
};
