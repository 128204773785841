import { Divider } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { InnerPageXWithStickySidebar } from 'framework/components/layout/InnerPageXWithStickySidebar';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { ExactOnlineCustomerAccountListItem } from './ExactOnlineCustomerAccountListItem';
import { ExactOnlineCustomerAccountsListFilterCard } from './ExactOnlineCustomerAccountsListFilterCard';
import { ExactOnlineCustomerAccountsQueryContext } from './ExactOnlineCustomerAccountsQueryContext';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useDownload } from 'gen/useDownload';
import { exactOnlineCrmAccountsQuery_export } from 'gen/ApiClient';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { DownloadMultipleIcon } from 'framework/components/icons/DownloadMultipleIcon';

interface IProps extends IReloadTriggerProps {
	reload: VoidFunction;
}

export const ExactOnlineCustomerAccountsListTab = ({ reload, reloadTrigger }: IProps) => {
	const strings = useLocalization();
	const { reload: reloadQuery, queryResult, params, setParams } = useQueryContext(ExactOnlineCustomerAccountsQueryContext);
	const [exportCrmAccountsToXlsx, isExporting] = useDownload(exactOnlineCrmAccountsQuery_export);

	useLazyEffect(() => {
		reloadQuery();
	}, [reloadTrigger]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<InnerPageXWithStickySidebar
			maxWidth={1280}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<ExactOnlineCustomerAccountsListFilterCard
						params={params}
						setParams={setParams}
						style={{ minWidth: 220 }}
					/>
					<div className='fg1'></div>
					<Divider />
					<TextLinkButton
						startIcon={<DownloadMultipleIcon />}
						onClick={() => exportCrmAccountsToXlsx()}
						disabled={isExporting}>
						{`Export CRM accounts to xlsx`}
					</TextLinkButton>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16, paddingLeft: 8 }}>
				<SearchbarX
					placeholder={`${strings.searchOnNameOrExactOnlineCode}...`}
					value={params.searchString}
					onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
				/>
				<CardX className='w100'>
					<div
						className='df-row'
						style={{ gap: 8 }}>
						<Typography16pxBold>{strings.results}</Typography16pxBold>
						<div className='fg1'></div>
					</div>
					<Divider style={{ marginTop: 8 }} />
					{queryResult.values.map(t => (
						<ExactOnlineCustomerAccountListItem
							key={t.hamsId}
							item={t}
							reload={reload}
						/>
					))}
					<QueryResultPaginationX
						queryResult={queryResult}
						setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
						style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
					/>
				</CardX>
			</div>
		</InnerPageXWithStickySidebar>
	);
};
