import { frFrameworkStrings } from 'framework/localization/frFrameworkStrings';
import { IStrings } from './IStrings';

export const frStrings: IStrings = {
    ...frFrameworkStrings,
    acceptFittingContent: `Continuer à facturer ou terminer ce flux de travail`,
    accessToFunctions: `Accès aux fonctions`,
    accessToPages: `Accès aux pages`,
    accountNumber: `Numéro de compte`,
    accountPayable: `Compte fournisseur`,
    accountReceivable: `Compte client`,
    accountReceivableSingularOrPlural: `Compte(s) client`,
    accountsPayable: `Comptes fournisseurs`,
    accountsPayableShort: `Compte fourn.`,
    accountsReceivable: `Comptes clients`,
    accountsReceivableShort: `Compte cl.`,
    action: `Action`,
    actionsWillBeDeletedFromThisList: `Les actions seront supprimées de cette liste`,
    actionsWillBeVisibleAgainInThisList: `Les actions seront à nouveau visibles dans cette liste`,
    activated: `Activé`,
    activateExactOnlineIntegration: `Activer l'intégration d'Exact Online`,
    activateIntegration: `Activer l'intégration`,
    activateVerb: `Activer`,
    active: `Actif`,
    activeAndExpired: `Actif et expiré`,
    activeNow: `Actuellement actif`,
    activeTreatments: `Traitements actifs`,
    actual: `Actuel`,
    actualDeliveryDate: `Date de livraison effective`,
    actualEndDate: `Date de fin effective`,
    actualEquipment: `Équipement actuel`,
    add: `Ajouter`,
    addALine: `Ajouter une ligne`,
    addALineForExampleDiscount: `Ajouter une ligne (p.ex. réduction)`,
    addALineWithDifference: `Ajouter une ligne avec la différence`,
    addAnEmailAdress: `Ajouter une adresse e-mail`,
    addArticlesFromProformaHandover: `Ajouter articles de pro forma annexe 12`,
    addAttachment: `Ajouter annexe`,
    addContactInfo: `Ajouter des informations de contact`,
    addContactPerson: `Ajouter un contact`,
    addDefineInventoryItem: `Ajouter article en stock`,
    addDevicesFromNoah: `Ajouter appareils de Noah`,
    addedWhat: (what: string | number) => `${what} ajouté(e)(s)`,
    addEmailAddress: `Ajouter une adresse e-mail`,
    addImperative: `Ajouter`,
    addItemForRepair: `Ajouter article à la réparation`,
    addItems: `Ajouter articles`,
    additionalNomenclatureCodes: `Code(s) de nomenclature supplémentaire(s)`,
    additionalNomenclatures: `Nomenclatures supplémentaires`,
    addLines: `Ajouter des lignes`,
    addLinesToExistingOrder: `Ajouter des lignes à une commande existante`,
    addLinesToThisOrder: `Ajouter des lignes à cette commande`,
    addLocation: `Ajouter emplacement`,
    addNeeds: `Ajouter des besoins`,
    addOffice: `Ajouter cabinet`,
    addProcess: `Ajouter un processus`,
    addRemark: `Ajouter commentaire`,
    addReservedArticles: `Ajouter articles réservés`,
    address: `Adresse`,
    addressData: `Coordonnées`,
    addressLine: `Rue et numéro de maison`,
    addressUpdated: `Adresse mis à jour`,
    addToExistingRepair: `Ajouter à une réparation existante.`,
    addToExistingRepairCaption: `Article est ajouté à réparation existante (pas encore envoyée)`,
    addToSettlementProposal: `Ajouter à proposition de règlement`,
    adjustWhat: (what: string | number) => `Ajuster ${what.toString().toLowerCase()}`,
    administratively: `Administratif`,
    advance: `Avance`,
    advancedFilters: `Filtres avancés`,
    advancedInventoryFilters: `Filtres d'inventaire avancés`,
    advanceInvoice: `Facture d'acompte`,
    advanceInvoices: `Factures d'acompte`,
    advisoryPhysician: `Médecin-conseil`,
    after: `Après`,
    afterAccents: `Après`,
    afterDate: `Après la date`,
    afterTreatment: `Après traitement`,
    age: `Âge`,
    agenda: `Horaire`,
    aHearingAidCreatefromLimitativeListNoahOrManual: `Un appareil auditif (tous les types)\nÀ partir de la liste exhaustive, Noah ou manuelle`,
    all: `Tout`,
    allAppointments: `Tous les rendez-vous`,
    allArticlesLinked: `Tous les articles liés`,
    allCommissions: `Toutes les commissions`,
    allCustomersLinked: `Tous les clients liés`,
    allDevicesFromNoah: `Tous les appareils de Noah`,
    allFields: `Tous les champs`,
    allForwarded: `Tous les documents transmis`,
    allocateArticlesFromStock: `Assigner des articles du stock`,
    allPayouts: `Tous les paiements`,
    allRefundsAreCollectedOnASettlementProposal: `Tous les remboursements sont sur un état récapitulatif`,
    allSales: `Toutes les ventes`,
    allSalesSynced: `Toutes les ventes sont synchronisées`,
    allUsers: `Tous les utilisateurs`,
    allWhat: (what: string | number) => `Tou(te)s les ${what.toString().toLowerCase()}`,
    allWorkscheduleEntries: `Toutes les entrées`,
    almostAlways: `Presque toujours`,
    almostNever: `Presque jamais`,
    alreadyPaid: `Déjà payé`,
    alreadyProformaAttachmentPresentDeleteFirstQuestion: `Une annexe 12 pro forma a déjà été créée.\n\nVoulez-vous d'abord supprimer ces données ou simplement re-générer le document?`,
    alsoChangeAdvisoryPhysicianAddress: `Changer également l'adresse du médecin conseil`,
    alsoChangeThirdPayerArrangementAddress: `Changer également l'adresse de régime du tiers payant`,
    alsoChangeWithPatient: `Changer également chez patient?`,
    alsoCreatePatientInNoah: `Créer patient également dans Noah ?`,
    alsoExistsInNoah: `Aussi disponible dans Noah`,
    alsoSelectACharger: `Sélectionnez également un chargeur`,
    alsoSelectAnEarmould: `Sélectionnez également un embout`,
    alsoSelectAReceiver: `Sélectionnez également un receiver`,
    alsoShowDeleted: `Voir aussi données supprimées`,
    alsoShowRemarksOfPatient: `Voir également commentaires des patients`,
    alsoUpdateOnProcesses: `Changer également chez processus`,
    alternativeAddress: `Adresse alternative`,
    alternativeLogo: `Logo alternative`,
    alwaysImport: `Toujours importer`,
    ambulatory: `Ambulant`,
    amountAsValue: `Montant`,
    amountFromSale: `Montant des ventes`,
    amountMinusContributions: `Montant moins allocations`,
    amountPaid: `Montant payé`,
    amountToPay: `Montant à payer`,
    analysis: `Analyse`,
    anamnesis: `Anamnèse`,
    anExample: `Un exemple`,
    anItem: `Un article`,
    annotation: `Annotation`,
    annotations: `Annotations`,
    applicable: `Applicable`,
    apply: `Appliquer`,
    applyCodeForAllArticlesOfWhatQuestion: (what: string | number) => `Appliquer code pour tous les articles de '${what.toString().toLowerCase()}'?`,
    applyOn: `Appliquer sur`,
    appNotYetRegistered: `L'application n'est pas encore enregistrée`,
    appointment: `Rendez-vous`,
    appointmentPlanned: `Rendez-vous fixé`,
    appointments: `Rendez-vous`,
    appRegistered: `Application enregistrée`,
    approval: `Approbation`,
    approvalAdvisoryPhysician: `Approbation du médecin-conseil`,
    approvalAdvisoryPhysicianCaption: `Approbation du médecin-conseil reçue et signée`,
    approvalAdvisoryPhysicianReceived: `Approbation méd.-conseil reçue`,
    approvalDate: `Date d'approbation`,
    approvalEntPhysicianCaption: `Prescription médicale reçue et signée par médecin ORL`,
    approved: `Approuvé`,
    approvedByAdvisoryPhysicianShort: `Approuvé par méd.-con.`,
    aRemoteDevice: `Un appareil à distance (remote)`,
    areYouSureYouWantToCancelThisOrderQuestion: `Êtes-vous sûr de vouloir annuler cette commande ?`,
    areYouSureYouWantToDeleteThisAppointmentQuestion: `Voulez-vous vraiment supprimer ce rendez-vous?`,
    areYouSureYouWantToDeleteThisEntPhysician: `Etes-vous sûr de vouloir supprimer ce médecin ORL?`,
    article: `Article`,
    articleFromProductCatalogFoundWithReference: (whatX: string | number) => `Article trouvé dans le catalogue des produits (réf. fournisseur : ${whatX})`,
    articleIsRegisteredAsDemo: `L'article est enregistré en tant que DEMO`,
    articleMayNotHaveASerialNumberRemoveFromAllowances: `Le produit ne doit pas contenir de numéro de série.`,
    articleNotFoundInProductCatalogWithReference: (whatX: string | number) => `Article introuvable dans le catalogue des produits (réf. fournisseur : ${whatX})`,
    articleNumber: `Article #`,
    articleReservedForWho: (who: string | number) => `Article réservé à ${who}`,
    articles: `Articles`,
    articlesInquiries: `Demandes d'articles`,
    articlesInquiry: `Demande d'articles`,
    articlesInquiryToAssignSingularOrPlural: `Demandes d'articles à attribuer`,
    articlesRegistered: `Articles enregistrés`,
    askEveryTime: `Demander à chaque fois`,
    askEveryTimeHandoverModeCaption: `Sur chaque épisode, demander à nouveau`,
    assigned: `Attribué`,
    assignFromStock: `Affectation à partir du stock`,
    atPatient: `Chez patient`,
    atSupplier: `Chez fournisseur`,
    atSupplierForRepair: `Chez fournisseur pour réparation`,
    attachment: `Annexe`,
    attachment12AndInvoice: `Annexe 12 et facture`,
    attachments: `Annexes`,
    attendingAudiologistWillAlsoBeChangedOnOtherProcesses: `L'audiologiste traitant sera AUSSI changé chez les processus actifs`,
    attendingAudiologistWillAlsoBeChangedWithPatient: `L'audiologiste traitant sera AUSSI changé chez le patient`,
    attendingAudiologistWillNotBeChangedWithPatient: `L'audiologiste traitant ne sera PAS changé chez le patient`,
    attendingAudiologistWillOnlyBeChangedWithThisPatientAndNotOnProcesses: `L'audiologiste traitant ne sera que changé chez le patient et non chez les processus`,
    attendingDoctors: `Les médecins traitants`,
    audiogram: `Audiogramme`,
    audiograms: `Audiogrammes`,
    audiologist: `Audiologiste`,
    authorization: `Autorisé`,
    autoGenerateSerialNumberQuestion: `Générer un numéro de série automatiquement ?`,
    autoPatientNumbering: `Numérotation automatique des patients`,
    autoReloadInXSeconds: (count: string | number) => `Rechargement automatique dans ${count.toString().toLowerCase()} seconde(s)`,
    autoSelectIndexNumberQuestion: `Séclectionner automatiquement numéro d'ordre ?`,
    autoSelectPaymentReferenceQuestion: `Sélectionner automatiquement référence de paiement ?`,
    autoSetOrderNumber: `Définir automatiquement numéro de commande`,
    available: `Disponible`,
    availableForWhat: (what: string | number) => `Disponible pour ${what.toString().toLowerCase()}`,
    availableHifsAutomaticallySyncedWithRizivData: `Mutualitées disponibles en synchronisation avec les données de RIZIV`,
    availableInCalendar: `Disponible dans le calendrier`,
    availableInCalendarCaption: `Les rendez-vous peuvent être fixés à cet emplacement`,
    average: `En moyenne`,
    averageNumberOfHoursPerDayWearingHearingAids: `Durée moyenne de port`,
    averagePurchasePrice: `Prix moyen d'achat`,
    b10Caption: `Jaune | 5,8x3,6mm | Mini RITE of CIC`,
    b13Caption: `Rouge | 7,9x5,4mm | BTE of ITE`,
    b312Caption: `Brun | 7,9x3,6mm | Mini BTE of RITE of ITC`,
    b675Caption: `Bleu | 11,6x5,4mm | Power BTE`,
    balance: `Solde`,
    bancontact: `Bancontact`,
    bankData: `Coordonnées bancaires`,
    bankTransfer: `Virement`,
    base: `Base`,
    basedOn: `Basé sur`,
    baseInformation: `Informations de base`,
    batteries: `Piles`,
    batteryType: `Type de batterie`,
    bebatRate: `Taux Bebat`,
    before: `Avant`,
    beforeAccents: `Avant`,
    behindTheEar: `Derrière l'oreille`,
    beIDMiddlewareIsNotInstalled: `Le BeID middleware n'est pas installé`,
    better: `Meilleur`,
    birthDateUnknown: `Date de naissance inconnue`,
    block: `Bloquer`,
    blocked: `Bloqué`,
    blockUser: `Bloquer utilisateur`,
    blockUserContent: `Un utilisateur bloqué ne peut pas se connecter.`,
    blockUserQuestion: `Bloquer utilisateur ?`,
    boneAnchorage: `Ancrage osseux`,
    brand: `Marque`,
    brandAndModel: `Marque et modèle`,
    brandName: `Nom de marque`,
    brands: `Marques`,
    broken: `Défectueux`,
    bteCaptionTypes: `Derrière l'oreille (RITE, Power, Open Ear, Open Fit, …)`,
    bulkImport: `Importer en vrac`,
    bulkRepair: `Réparation en vrac`,
    calculation: `Calcul`,
    calculations: `Calculs`,
    calendar: `Calendrier`,
    canBeUsedForWhat: (what: string | number) => `Peut être utilisé pour ${what.toString().toLowerCase()}`,
    cancelArticlesInquiry: `Annuler demande d'articles`,
    cancellationReason: `Raison d'annulation`,
    cancelled: `Annulé`,
    cancelledAfterPartlyDelivery: `Annulé après livraison partielle`,
    cancelledAfterShipment: `Annulé (après expédition)`,
    cancelledBeforeShipment: `Annulé (avant expédition)`,
    cancelledWhat: (what: string | number) => `${what} annulé(e)(s)`,
    cancelLoan: `Annuler prêt`,
    cancelOrder: `Annuler la commande`,
    cancelSomething: (something: string | number) => `Annuler ${something.toString().toLowerCase()}`,
    cancelWhat: (what: string | number) => `Annuler ${what.toString().toLowerCase()}`,
    cannotChangeRepititionToDoThisDeleteEntireSequence: `Impossible de modifier la répétition, il faut supprimer toute la séquence pour le faire.`,
    cannotReachServerNoInternetConnection: `Impossible d'atteindre le serveur, pas de connexion internet`,
    canReturn: `Peut être terminé`,
    canViewInAppOrBrowser: `Peut être consulté dans le navigateur (dans l'app)`,
    cash: `En espèces`,
    cashDesk: `Caisse`,
    cashDeskLocation: `Utilise la caisse`,
    cashRegisterAsLedger: `Livre de caisse`,
    cashSale: `Vente au comptant`,
    cashSaleForPatient: `Ventes au comptant pour patient`,
    cashSales: `Ventes au comptant`,
    cashSalesAreNotLinkedToAnAccountReceivableGoToCashRegister: `Ventes au comptant ne sont pas liés à un compte client.\nNaviguez vers module de caisse`,
    cashTransaction: `Transaction en espèces`,
    cashTransactions: `Transactions en espèces`,
    catalogNumber: `Numéro de catalogue`,
    categories: `Catégories`,
    category: `Catégorie`,
    change: `Changer`,
    changed: `Modifié`,
    changeDateOrNomenCode: `Changer la date ou changer les codes de nomenclature`,
    changeDefaultDeliveryTime: `Changer délai de livraison normal`,
    changeDetailsEntPhysician: `Changer données médecin ORL`,
    changedWhat: (what: string | number) => `${what} changé(e)(s)`,
    changeEmail: `Changer adresse e-mail`,
    changeName: `Changer nom`,
    changeOnlyAdvisoryPhysicianAddress: `Changer seulement l'adresse du médecin conseil`,
    changeOnlyThirdPayerArrangementAddress: `Changer seulement l'adresse de régime du tiers payant`,
    changeOrientation: `Changement d'orientation`,
    changePassword: `Changer mot de passe`,
    changePhone: `Changer numéro de téléphone`,
    changePrice: `Changer le prix`,
    changePrices: `Ajuster les prix`,
    changeProcessSteps: `Modifier le processus`,
    changeProductCatalog: `Modifier le catalogue de produits`,
    changeRemark: `Changer commentaire`,
    changeRIZIVNumber: `Changer numéro INAMI`,
    changeSomething: (something: string | number) => `Changer ${something.toString().toLowerCase()}`,
    changeStartModule: `Ajuster (lancer module)`,
    changesToDetailScreen: `Modifications écran de détail`,
    changesToDetailScreenAlertDescription: `Bientôt, l'écran de détail du patient sera remplacé par une vue améliorée.\nCet écran est la nouvelle vue améliorée.`,
    changeSupplierContactPerson: `Changer personne de contact du fournisseur`,
    changeWhat: (what: string | number) => `Changer ${what.toString().toLowerCase()}`,
    charger: `Chargeur`,
    chargerNewArticleCaption: `Chargeur (pour appareil auditifs rechargeable)`,
    checkAgain: `Revérifier`,
    checkDocuments: `Contrôle des documents`,
    checkoutVerb: `Payer`,
    circularLetters: `Circulairs`,
    city: `Ville`,
    clearFilter: `Supprimer filtre`,
    clearLink: `Supprimer lien`,
    clearRIZIVContent: `L'utilisateur sera marqué comme non audiologiste`,
    clearRIZIVQuestion: `Supprimer lien INAMI ?`,
    click: `Cliquer`,
    clickHere: `Cliquez ici`,
    clickHereForMoreInformationAboutROSA: `Cliquez ici pour plus d'informations sur ROSA`,
    clickHereToNavigateToNewView: `Cliquez ici pour accéder à la nouvelle vue.`,
    clickHereToViewDocumentation: `Cliquez ici pour consulter la documentation`,
    clickToAdd: `Cliquez pour ajouter`,
    clickToViewDetails: `Cliquez ici pour voir les détails`,
    clickToViewHamsPatient: `Cliquez ici pour voir le patient HAMS`,
    clickToViewItemWithSerialNumber: `Cliquez ici pour voir l'article avec ce numéro de série`,
    clickToViewMore: `Cliquez ici pour voir plus`,
    clickToViewProductCatalog: `Cliquez ici pour voir le catalogue des produits`,
    close: `Fermer`,
    closeSearch: `Quitter la recherche`,
    closeThisWindow: `Fermer cette fenêtre`,
    cltTest: `TLC test`,
    code: `Code`,
    codesLinkedToRequest: (codes: string | number) => `${codes} lié(e)(s) à demande`,
    codesSingularOrPlural: `Code(s)`,
    color: `Couleur`,
    colorScheme: `Schéma de couleur`,
    column: `Colonne`,
    combinationOfBrandAndModelExists: `Cette combinaison de marque et de modèle existe déjà.`,
    commission: `Commission`,
    commissions: `Commissions`,
    companyData: `Données de l'entreprise`,
    companyName: `Nom de la société`,
    comparedWithSamePeriodLastYearBracketsWhat: (what: string | number) => `Par rapport à la même période de l'année précédente (${what.toString().toLowerCase()})`,
    comparisonAll: `Comparaison tous`,
    comparisonStereo: `Comparaison stéréo`,
    compensation: `Allocation`,
    completed: `Achevé`,
    completelyDelivered: `Entièrement livré`,
    configuration: `Configuration`,
    configurationCorrect: `Configuration correcte`,
    confirm: `Confirmer`,
    confirmation: `Confirmation`,
    confirmChoice: `Confirmer choix`,
    confirmDelivery: `Confirmer livraison`,
    confirmed: `Confirmé`,
    confirmNewPassword: `Confirmer nouveau mot de passe`,
    confirmPassword: `Confirmer mot de passe`,
    confirmUserHeader: `Entrer un mot de passe`,
    connection: `Connexion`,
    connectionStatus: `État de la connexion`,
    connectReader: `Connecter un lecteur de carte`,
    consignment: `Consignation`,
    consignmentData: `Données de consignation`,
    consignmentExpired: `Consignment expirée`,
    consignmentExpiresWithinAWeek: `Consignment expire dans une semaine`,
    consignmentWarning: `Avertissement de consignement`,
    contact: `Contact`,
    contactAHamsAdminUserForMoreInformation: `Contacter un administrateur HAMS pour plus d'informations`,
    contactData: `Données de contact`,
    contactHAMSForValidLicense: `Contactez HAMS pour obtenir une licence valide`,
    contactInfoAdded: `Informations de contact ajoutées`,
    contactInfoUpdated: `Les informations de contact ont changé`,
    contactPerson: `Personne de contact`,
    contacts: `Contacts`,
    contactWho: (who: string | number) => `Contacter ${who}`,
    contents: `Contenu`,
    continue: `Continuez`,
    contraLateralEquipment: `Appareillage controlatéral`,
    contribution: `Allocation`,
    contributionCodeForPatient: (code: string | number, who: string | number) => `Allocation ${code.toString().toLowerCase()} pour ${who}`,
    contributions: `Allocations`,
    conventionedEqualsPrecededByOne: `Conventionné = précédé de « 1 »`,
    conventionedRIZIVNumber: `Numéro INAMI conventionné`,
    conventionedRIZIVNumberShort: `# INAMI conv.`,
    convertedToSales: `Converti en ventes`,
    copyVatDataFromPatient: `Copier les données du patient`,
    cosiNeed: `Besoin COSI`,
    cosiQuestionnaire: `Questionnaire COSI`,
    countArticlesNotLinked: (count: string | number) => `${count} articles non liés`,
    countCustomersNotLinked: (count: string | number) => `${count} clients non liés`,
    countDeliveries: `Nombre de livraisons`,
    countEventsAreLinkedToThisLocationDoYouWantToContinueQuestion: (count: string | number) => `${count} rendez-vous sont liés à ce lieu.
Si vous continuez, cet emplacement sera supprimé de tous les rendez-vous.
Souhaitez-vous continuer?`,
    countEventsAreLinkedToThisUserDoYouWantToContinueQuestion: (count: string | number) => `${count} rendez-vous sont liés à cet utilisateur.
Si vous continuez, cet utilisateur sera supprimé de tous les rendez-vous.
Souhaitez-vous continuer?`,
    countFilesInBuffer: `# fichiers dans la mémoire tampon`,
    countInvoicesNotForwarded: (count: string | number) => `${count} factures non transmises`,
    countOrders: `Nombre de commandes`,
    countOrdersToPlaceWithSupplier: (count: string | number) => `# ${count.toString().toLowerCase()} commandes à passer auprès du fournisseur`,
    countPatientRecordsFromRosaNotImported: (count: string | number) => `${count} patient(s) de ROSA n'a(ont) pas été importé(s).`,
    countPatientsImported: (count: string | number) => `${count} patient(s) importé(s)`,
    countPatientsNotImported: (count: string | number) => `${count} patient(s) non importé(s)`,
    countPurchases: `Nombre d'achats`,
    countRosaUsersNotLinked: (count: string | number) => `${count} utilisateur(s) ROSA non lié(s)`,
    country: `Pays`,
    countSalesNotSynced: (count: string | number) => `${count} ventes non synchronisées`,
    countUsersLinked: (count: string | number) => `${count} utilisateurs liés`,
    countUsersNotLinked: (count: string | number) => `${count} utilisateurs non liés`,
    create: `Créer`,
    createADocumentRightAwayQuestion: `Créer un document immédiatement ?`,
    createANewPatientInHAMS: `Créer un nouveau patient dans HAMS`,
    createANewSupplier: `Créer un nouveau fournisseur`,
    createAnOrderForThisNeed: `Faire une nouvelle commande pour ce besoin`,
    created: `Créé`,
    createdAt: `Créé le`,
    createdBracketsHow: (how: string | number) => `Créé (${how.toString().toLowerCase()})`,
    createdByUser: `Créé par l'utilisateur`,
    createdFromUploadWithSignature: `Créé (téléchargement) avec signature`,
    createDocument: `Créer document`,
    createDocumentFromPersonalisedVersion: `Créer un document à partir d'une version personnalisée`,
    createDocumentFromStandardisedVersion: `Créer un document à partir de la version standardisée`,
    createDocumentsRightAwayQuestion: `Créer des documents immédiatement ?`,
    createInHamsAndNoah: `Créer dans HAMS et dans Noah`,
    createNewCRMAccountInExactOnline: `Créer un nouveau compte CRM dans Exact Online`,
    createNewEvent: `Créer nouvel événement`,
    createNewPatient: `Créer nouveau patient`,
    createNewRule: `Créer nouvelle règle`,
    createOnlyInHams: `Créer uniquement dans HAMS, PAS dans Noah`,
    createPatientFromFormCaption: `Entrer toutes les données manuellement`,
    createPatientFromIdentityCardCaption: `Créer nouveau patient à partir de la carte eID`,
    createPatientFromNhaxCaption: `Importer patient à partir d'une autre installation Noah \n(.nhax ou .enhax)`,
    createPatientInNoah: `Créer patient dans Noah`,
    createPatientInRosa: `Créer un patient dans ROSA`,
    createProformaAttachment12Content: `Ainsi, vous pouvez créer une annexe 12 AVANT la fourniture finale de l'équipement. Cette annexe 12 n'est PAS l'annexe finale, l'annexe finale sera créée à la livraison.Ce  document ne sera PAS supprimé à la livraison finale. Vous voulez continuer ?`,
    createServiceOrGoods: `Créer un nouveau produit ou service`,
    createSlashSyncSaleInExactOnline: `Créer/synchroniser une vente dans Exact Online`,
    createSomething: (something: string | number) => `Créer ${something.toString().toLowerCase()}`,
    createStrCommaWhat: (str: string | number, what: string | number) => `Créer ${str.toString().toLowerCase()}, ${what.toString().toLowerCase()}`,
    createTenderAndOrderForm: `Rédiger offre (et bon de commande)`,
    createWhat: (what: string | number) => `Créer ${what.toString().toLowerCase()}`,
    createWhatInProductCatalog: (what: string | number) => `Créer '${what.toString().toLowerCase()}' dans le catalogue des produits`,
    creditNote: `Note de crédit`,
    creditNoteDate: `Date note de crédit`,
    creditNoteLine: `Ligne de crédit`,
    creditNotePurchase: `Note de crédit achat`,
    creditNoteSale: `Note de crédit vente`,
    creditPurchaseVerb: `Achat à crédit`,
    creditStatus: `Statut de crédit`,
    creditVerb: `Créditer`,
    crmAccountOrCustomerDoesNotExistYetInExactOnline: `Le compte CRM (client) n'existe pas encore dans Exact Online`,
    currentFeePersonalShare: `Ticket modérateur actuel`,
    currentFeeValue: `Allocation actuelle`,
    currentLocation: `Emplacement actuel`,
    currentlyLoggedInOnDevices: (count: string | number) => `Actuellement connecté sur ${count.toString().toLowerCase()} appareils`,
    currentlyLoggedInOnOneDevice: `Actuellement connecté sur 1 appareil`,
    currentlyNotLoggedIn: `Pas connecté pour l'instant`,
    currentPrice: `Prix actuel`,
    customer: `Client`,
    customerAccountIsNotLinkedInExactOnline: `Le compte client n'est pas lié à Exact Online`,
    customerIsAPatient: `Client est un patient`,
    customerIsNotAPatient: `Client n'est pas un patient`,
    customerSingularOrPlural: `Client(s)`,
    customWorkflows: `Flux de travail personnalisés`,
    dailyReceipt: `Entrée quotidienne`,
    dailyReceipts: `Entrées quotidiennes`,
    dashboard: `Tableau de bord`,
    data: `Données`,
    dataNotReadViaeID: `Données pas lues par la carte eID`,
    dataPaging: (fc: string | number, ufc: string | number, what: string | number, index: string | number, total: string | number) => `${fc} / ${ufc.toString().toLowerCase()} ${what.toString().toLowerCase()}. Page ${index.toString().toLowerCase()} de ${total.toString().toLowerCase()}`,
    dataReadViaEID: `Données lues via l'eID`,
    date: `Date`,
    dateApprovalAdvisoryPhysician: `Date de l'approbation médecin-conseil`,
    dateBinFormat: `Format de date selon l'IBN (année-mois-jour)`,
    dateCreated: `Date créée`,
    dateDayMonthYear: `Date (jour/mois /année)`,
    dateLastAudiogram: `Date dernier audiogramme`,
    dateLastChangedInNoah: `Date dernière modification dans Noah`,
    dateLastHandover: `Date dernière fourniture`,
    dateLastOrder: `Date de la dernière commande`,
    dateLastSelection: `Date dernière sélection`,
    dateOfBirth: `Date de naissance`,
    dateOfBirthShort: `Date de naiss.`,
    dateOfDispatch: `Date d'expédition`,
    datePlusTimeDayMonthYearHourMinutes: `Date + heure (jour/mois/année heures: minutes)`,
    dateReturnBefore: `Date de retour avant`,
    dateStartLoan: `Date de début du prêt`,
    dateUploaded: `Date de l'inculpation`,
    dBAgainstVocalIndex: `dB contre index vocale`,
    deactivated: `Désactivé`,
    deactivateExactOnlineIntegration: `Désactiver l'intégration d'Exact Online`,
    deactivateIntegration: `Désactiver l'intégration`,
    deactivateVerb: `Désactiver`,
    deactivePaymentMethod: `Désactiver mode de paiement ?`,
    deactivePaymentMethodContent: `Ce mode de paiement n'est alors plus disponible`,
    deactivePaymentMethodForWhatContent: (what: string | number) => `Ce mode de paiement n'est alors plus disponible pour ${what.toString().toLowerCase()}`,
    deactivePaymentMethodForWhatQuestion: (what: string | number) => `Désactiver mode de paiement pour ${what.toString().toLowerCase()} ?`,
    deceased: `Décédé`,
    defaultBlockDuration: `Durée par défaut`,
    defaultDeliveryTime: `Délai de livraison normal`,
    defaultDuration: `Durée normale`,
    defaultFailedTrialVatPercentage: `Défaut TVA pourcentage allocation forfaitaire`,
    defaultInvoiceProcessSteps: `Étapes de facturation normales`,
    defaultOffice: `Cabinet normal`,
    defaultRepairTime: `Temps de réparation normal`,
    defaultSteps: `Étapes par défaut`,
    defaultView: `Default View`,
    defaultWarranty: `Garantie par défaut`,
    defaultWhat: (what: string | number) => `${what} normal(e)(s)`,
    defineAndOrderOrSelectNeedsFromStock: `Définir et commander/sélectionner les besoins`,
    defineAndOrderOrSelectNeedsFromStockCaption: `Sélection d'articles en prêt, demande d'AA, offre, facture, … partira de ces besoins`,
    defineNeeds: `Définir les besoins`,
    defineNewInventoryItem: `Définir nouvel article de stock`,
    degreeOfChange: `Degré de changement`,
    deleteAddressAdvisoryPhysician: `Supprimer l'adresse du médecin-conseil`,
    deleteAddressThirdPartyPayer: `Supprimer l'adresse de l'accord de tiers payant`,
    deleteAndOrAddLines: `Ajouter et/ou supprimer lignes`,
    deleteAppointmentQuestion: `Supprimer le rendez-vous?`,
    deleteAttachment17: `Supprimer annexe 17`,
    deleteContactInfo: `Supprimer les informations de contact`,
    deleted: `Supprimé`,
    deleteData: `Supprimer les données`,
    deletedWhat: (what: string | number) => `${what} supprimé(e)(s)`,
    deleteEmailAddress: `Supprimer l'adresse e-mail`,
    deleteEntPhysician: `Supprimer médecin ORL`,
    deleteEntPhysicianQuestion: `Supprimer médecin ORL ?`,
    deleteFromRequest: `Supprimer de la demande`,
    deleteGeneralPractitioner: `Supprimer médecin généraliste`,
    deleteGeneralPractitionerQuestion: `Supprimer médecin généraliste ?`,
    deleteInventoryItemFromArticleNeedQuestion: `Le lien avec ce besoin sera supprimé
ainsi que la réservation sur cet article en stock.
Continuer?`,
    deleteItemFromProductCatalog: `Supprimer article du catalogue des produits`,
    deleteItemFromProductCatalogContent: `En supprimant le produit, il n'est plus possible de le sélectionner\npour un prêt, un essai, une vente, un achat, etc.\n\nVous voulez continuer ?`,
    deleteLine: `Supprimer la ligne`,
    deleteLink: `Supprimer le lien`,
    deleteLinkImperative: `Supprimer le lien`,
    deleteLinkWithInventoryItem: `Lien avec un article en stock`,
    deleteLocation: `Supprimer emplacement`,
    deleteLocationQuestion: `Supprimer emplacement ?`,
    deleteManualAdjustment: `Supprimer l'ajustement manuel`,
    deleteNeed: `Supprimer le besoin`,
    deleteNeedAndPropertiesQuestion: `Supprimer l'article de sélection et les propriétés?`,
    deleteOffice: `Supprimer cabinet`,
    deletePermanently: `Supprimer Permanent`,
    deleteProduct: `Supprimer produit`,
    deleteProductQuestion: `Êtes-vous sûr de bien vouloir supprimer ce produit ?`,
    deleteScanHistory: `Supprimer l'historique`,
    deleteSettlementProposalWithReferenceQuestion: (reference: string | number) => `Supprimer proposition de règlement ${reference.toString().toLowerCase()} ?`,
    deleteSupplier: `Supprimer fournisseur`,
    deleteUblFromBuffer: `Retrait de l'UBL de la mémoire tampon`,
    deleteUblFromBufferQuestionConfirmation: `Voulez-vous retirer cette UBL de la liste tampon ?`,
    deleteUser: `Supprimer utilisateur`,
    deleteUserContent: `Cette action est irréversible. Toutes les données associées à cet utilisateur seront supprimées.`,
    deleteUserQuestion: `Supprimer utilisateur ?`,
    deleteWhat: (what: string | number) => `Supprimer ${what.toString().toLowerCase()}`,
    deleteWhatQuestion: (what: string | number) => `Supprimer ${what.toString().toLowerCase()} ?`,
    delivered: `Livré`,
    deliveredToPatient: `Livré au patient`,
    deliveries: `Livraisons`,
    deliverToPatient: `Livrer au patient`,
    delivery: `Livraison`,
    deliveryDate: `Date de livraison`,
    deliveryHelperText: `L'emplacement où la livraison est reçue`,
    deliveryNote: `Bon de livraison`,
    deliveryOverDue: `Livraison en retard`,
    deliveryRegistered: `Livraison enregistrée`,
    deliverySingularOrPlural: `Livraison(s)`,
    deliveryTime: `Délai de livraison`,
    deltaFinancialAndPhysical: `Différence entre financière et physique`,
    demanded: `Demandé`,
    demo: `Démo`,
    department: `Département`,
    departmentSlashLocation: `Département/lieu`,
    description: `Description`,
    descriptionOfSupplier: `Description du fournisseur`,
    descriptions: `Descriptions`,
    detail: `Détail`,
    detailsOfEntPhysician: `Détails Médecin ORL`,
    detailsOfGeneralPractitioner: `Détails médecin généraliste`,
    detailsOfHif: `Détails fonds d'assurance maladie`,
    detailsOfSupplier: `Coordonnées du fournisseur`,
    detailsOfVatCustomer: `Coordonnées du client TVA`,
    detailsSlashHistorySlashMoreActions: `Détails/histoire/actions supplémentaires`,
    determineOrientation: `Déterminer l'orientation`,
    devicesFromNoah: `Appareils de Noah`,
    differenceWithNorm: `Différence par rapport à la norme`,
    differentLabelPerLanguage: `Étiquette différente par langue`,
    differentReadOuts: `Différentes lectures`,
    directedToContactPerson: `Adressé à personne de contact`,
    directedToPatient: `Adressé à patient`,
    directedToPatientAndOrVatCustomer: `Adressé à un patient et/ou client TVA`,
    directedToVatCustomer: `Adressé à un client TVA`,
    disableCashDeskResource: `Désactiver module de caisse`,
    disableCashDeskResourceQuestion: `Désactiver module de caisse pour cet emplacement ?`,
    disableExternalLogin: `Désactiver connexion externe`,
    disableExternalLoginContent: `Utilisateur ne pourra plus utiliser les services de Google pour se connecter.`,
    disableExternalLoginQuestion: `Désactiver connexion externe ?`,
    disablePlannable: `N'est plus planifiable`,
    disablePlannableQuestion: `Faire en sorte que l'emplacement ne soit plus planifiable ?`,
    disableStockManagementResource: `Désactiver gestion des stocks`,
    disableStockManagementResourceQuestion: `Activer gestion des stocks pour cet emplacement ?`,
    disApprovalAdvisoryPhysicianCaption: `Feed-back reçu sans approbation`,
    disApprovalEntPhysicianCaption: `Feed-back reçu sans approbation`,
    disApproved: `Rejeté`,
    discount: `Réduction`,
    discountCannotBeGreaterThanListPrice: `Réduction ne peut être supérieure au prix recommandé`,
    discountCaption: `Remise unique (absolu)`,
    discountsCanBeAddedAfterwards: `Réductions peuvent être accordées par la suite`,
    dispatchDate: `Date d'expédition`,
    dispatchInfo: `Détails d'expédition`,
    dispatchReference: `Note d'expédition`,
    displayName: `Nom affiché`,
    distortion: `Distorsion`,
    distributedBrands: `Marques distribuées`,
    distributor: `Distributeur`,
    doctorWithRIZIVNumberExists: `Il y a déjà un médecin avec ce numéro INAMI.`,
    document: `Document`,
    documents: `Documents`,
    documentsForWho: (who: string | number) => `Documents pour ${who}`,
    doesNotExistsInNoah: `N'existe pas dans Noah`,
    doesNotHaveRequestForInsuranceAllowance: `N'a pas de demande d'allocation de l'assurance`,
    dome: `Dome`,
    domeNewArticleCaption: `Click, open, mini, …`,
    doNotAddLinesToAnExistingOrder: `Ajouter des lignes à une commande existante`,
    doNotChangeWithPatient: `Ne pas changer chez patient`,
    doNotMention: `Ne mentionner pas`,
    doNotMentionCaption: `Cette ligne ne figure PAS sur annexe 12`,
    doNotMentionOnAttachment12: `Ne pas mentionner sur annexe 12`,
    doNotRegisterPaymentsInHAMS: `Ne pas enregistrer les paiements dans HAMS`,
    doNotUpdateOnProcesses: `Ne pas changer chez processus`,
    dottedLine: `Ligne pointillée`,
    doubleClickNoahInHamsOnDesktop: `Double-cliquez sur « Noah in HAMS » sur le bureau`,
    download: `Télécharger`,
    downloadAndInstallHamsNoahClientSoftware: `Télécharger et installer le logiciel client HAMS-Noah`,
    downloadDocument: `Télécharger document`,
    downloadIsStartedWaitUntiflFileIsVisible: `Le téléchargement a commencé, attendez que le fichier soit visible\nCliquez sur le fichier pour démarrer l'installation`,
    downloadMiddleware: `Télécharger middleware`,
    downloadNHAX: `Télécharger NHAX`,
    downloadWhat: (what: string | number) => `Télécharger ${what.toString().toLowerCase()}`,
    doYouWantToActivateExactOnlineIntegrationQuestion: `Voulez-vous activer l'intégration d'Exact Online?`,
    doYouWantToActivateThisIntegrationQuestion: `Vous souhaitez activer cette intégration ?`,
    doYouWantToCancelThisArticlesInquiryQuestion: `Voulez-vous annuler cette demande d'articles?`,
    doYouWantToCreateANewPatientBasedOnRosaPatientRecordData: `Voulez-vous créer un nouveau patient dans HAMS sur la base de ces données ROSA ?`,
    doYouWantToCreateATenderWithoutContributionsQuestion: `Voulez-vous créer une offre sans allocations ?`,
    doYouWantToCreateSlashSyncSaleInExactOnlineQuestion: `Voulez-vous créer/synchroniser cette vente dans Exact Online?`,
    doYouWantToCreateThisPatientInRosaQuestion: `Voulez-vous créer ce patient à ROSA?`,
    doYouWantToDeactivateExactOnlineIntegrationQuestion: `Voulez-vous désactiver l'intégration d'Exact Online ?`,
    doYouWantToDeactivateThisIntegrationQuestion: `Vous souhaitez désactiver cette intégration ?`,
    doYouWantToDeleteAttachment17: `Voulez-vous supprimer l'annexe 17 ?`,
    doYouWantToDeleteExistingLinkWithCRMAccountQuestion: `Vous souhaitez supprimer le lien existant vers un compte CRM existant ?`,
    doYouWantToDeleteExistingLinkWithGLAccountQuestion: `Souhaitez-vous supprimer le lien existant avec le compte GL ?`,
    doYouWantToDeleteRuleForWhatQuestion: (what: string | number) => `Voulez-vous supprimer la règle: ${what.toString().toLowerCase()} ?`,
    doYouWantToDeleteScanHistory: `Souhaitez-vous supprimer cet historique?`,
    doYouWantToDeleteThisContactInfoQuestion: `Voulez-vous supprimer cette information de contact ?`,
    doYouWantToDeleteThisEmailAddressQuestion: `Voulez-vous supprimer cette adresse e-mail ?`,
    doYouWantToDeleteThisLineFromThisArticlesInquiryQuestion: `Voulez-vous supprimer cette ligne de cette demande d'articles?`,
    doYouWantToDeleteThisLineFromThisOrderQuestion: `Voulez-vous supprimer cette ligne de la commande?`,
    doYouWantToForwardInvoiceQuestion: `Souhaitez-vous transmettre cette facture ?`,
    doYouWantToHideThisArticleFromListOfActualEquipmentQuestion: `Voulez-vous cacher cet article de la liste des équipements actuels?`,
    doYouWantToIgnoreSaleReferenceQuestion: (reference: string | number) => `Voulez-vous ignorer la vente ${reference.toString().toLowerCase()}?`,
    doYouWantToLinkTheRosaUsersNowQuestion: `Voulez-vous relier les utilisateurs de ROSA maintenant?`,
    doYouWantToLinkThisRosaPatientWithAnExistingPatientFromHAMS: `Souhaitez-vous associer ce patient ROSA à un patient existant du HAMS ?`,
    doYouWantToMakeThisEntPhysicianAvailableAgainQuestion: `Souhaitez-vous que ce médecin ORL soit à nouveau disponible ?`,
    doYouWantToMakeThisGPAvailableAgainQuestion: `Souhaitez-vous que ce médecin généraliste soit à nouveau disponible?`,
    doYouWantToMakeThisSupplierAvailableAgainQuestion: `Voulez-vous que le fournisseur soit à nouveau disponible?`,
    doYouWantToPushPatientDataToRosaQuestion: `Souhaitez-vous transférer les données HAMS de ce patient vers ROSA ?`,
    doYouWantToRemoveManualWarrantyEndDateQuestion: `Voulez-vous supprimer la date de fin de garantie définie manuellement?`,
    doYouWantToRepairRuleForWhatQuestion: (what: string | number) => `Voulez-vous récupérer la règle: ${what.toString().toLowerCase()} ?`,
    doYouWantToRepairTemplateWithName: (template: string | number) => `Voulez-vous récupérer modèle: ${template.toString().toLowerCase()} ?`,
    doYouWantToRevertIgnoreSaleReferenceQuestion: (reference: string | number) => `Voulez-vous annuler ignorer la vente ${reference.toString().toLowerCase()}?`,
    doYouWantToShowThisArticleAgainInListOfActualEquipment: `Voulez-vous montrer cet article à nouveau dans la liste des équipements actuels?`,
    draft: `Rédiger`,
    draftCosiCaption: `Ne remplissez que les besoins spécifiques, évaluez plus tard`,
    dutch: `Néerlandais`,
    earCanalLength: `Longueur canal auditif`,
    earmould: `Embout auriculaire`,
    earMouldNewArticleCaption: `Embout auriculaire sur mesure (du appareil auditif)`,
    earmoulds: `Embouts auriculaires`,
    edit: `Modifier`,
    editable: `Modifiable`,
    editAddress: `Changer adresse`,
    editConfirmAddress: `Modifier/confirmer adresse`,
    editFieldToFill: `Modifier le champ à remplir`,
    editName: `Modifier nom`,
    editProperties: `Modifier les propriétés`,
    editSlashView: `Modifier/visualiser`,
    editVerb: `Modifier`,
    editWhat: (what: string | number) => `Modifier ${what.toString().toLowerCase()}`,
    eIDData: `Données eID`,
    eIDDataLastReadDate: `Date de dernière lecture eID`,
    eIDDataRead: `Données eID lues`,
    eIDDataUpdatedToPatient: `Données eID lues, patient mis à jour`,
    eIDReadInfo: `Les données du patient sont lues à partir de la carte eID.

Le numéro de registre national de la carte est comparé aux numéros de registre national des patients existants, 
En cas de correspondance, les données du patient sont mises à jour avec les données de la carte eID. 

S'il n'y a pas de correspondance, une fenêtre contextuelle vous demandera de confirmer avant de créer un nouveau patient.

Si vous êtes sûr que le patient a déjà été créé, mais que le numéro de registre national n'a pas été renseigné : 
Fermez cette fenêtre, trouvez le patient et mettez à jour les données eID à partir de l'écran de détails.`,
    electronics: `Électronique`,
    elementsBelowWillNotBeShown: `Les éléments ci-dessous ne sont pas représentés`,
    email: `E-mail`,
    eMailAddressess: `Adresses e-mail`,
    emailAddressWhereToInvoicedWillBeSent: `Adresses électroniques (auxquelles les factures sont transmises)`,
    emptyDocument: `Document vide`,
    enableCashDeskResource: `Activer module de caisse`,
    enableCashDeskResourceQuestion: `Activer module de caisse pour cet emplacement ?`,
    enableExternalLogin: `Activer service de connexion externe`,
    enableExternalLoginContent: `L'utilisateur pourra utiliser les services de Google pour se connecter`,
    enableExternalLoginQuestion: `Activer connexion externe ?`,
    enablePlannable: `Rendre planifiable`,
    enablePlannableQuestion: `Rendre emplacement planifiable ?`,
    enableStockManagementResource: `Activer gestion des stock`,
    enableStockManagementResourceQuestion: `Désactiver gestion des stocks pour cet emplacement ?`,
    end: `Terminer`,
    endAfterTreatment: `Fin du post-traitement`,
    endAfterTreatmentContentQuestion: `Êtes-vous sûr d'y mettre un terme l'après traitement?`,
    endAllSessions: `Terminer toutes sessions`,
    endAllSessionsForUser: `Terminer toutes sessions pour utilisateur`,
    endDate: `Date de fin`,
    ended: `Terminé`,
    endLoan: `Mettre fin au prêt`,
    endResult: `Résultat final`,
    endSession: `Terminer session`,
    endSessionForUser: `Terminer session pour utilisateur`,
    endThisSession: `Terminer cette session`,
    endTrial: `Terminer essai`,
    endTrialAdministratively: `Fin d'essai (administratif)`,
    endTrialAdministrativelyCaption: `Terminez l'essai administrativement\nEntrez une date pour la fin administrative`,
    endTrialCaption: `Mettre fin au prêt\nAppareils sont remis en stock, cette étape n'est pas nécessaire, l'essai se termine automatiquement lorsque l'appareil est vendu`,
    endTrialPositively: `Terminer essai (positif)`,
    endVerb: `Terminer`,
    english: `Anglais`,
    enterDiscountAbsolute: `Entrer réduction en euros`,
    enterDiscountInPercentage: `Entrer % de réduction`,
    enterpriseNumber: `Numéro d'entreprise`,
    enterSerialNumber: `Entrer numéro de série`,
    entPhysician: `Otorhinolaryngologiste`,
    entPhysicianCreated: `Nouveau médecin ORL créé`,
    entPhysicians: `Médecins ORL`,
    entPhysicianShort: `Médecin ORL`,
    entPhysicianUpdated: `Médecin ORL mis à jour`,
    entryDate: `Date d'entrée`,
    equation: `Comparaison`,
    equipmentTestTitle: `Tester équipement (essai)`,
    error: `Erreur`,
    errorCannotStartExternalModules: `ERREUR, ne peut pas démarrer modules externes`,
    errorDownloadingConfiguration: `Erreur de récupération de la configuration`,
    errorViewLogs: `Erreur, consulter les journaux`,
    evaluateCosi: `Évaluer COSI`,
    evaluateCosiCaption: `Remplir besoins spécifiques et évaluer immédiatement`,
    evaluatedNegatively: `Évalué négativement`,
    evaluateVerb: `Évaluer`,
    evaluation: `Évaluation`,
    evaluationDate: `Date d'évaluation`,
    everythingCapital: `Tout en majuscules`,
    everythingLower: `Tout en minuscules`,
    everythingSeemsAllright: `Tout semble aller bien`,
    evolution: `Évolution`,
    exact: `Exact`,
    exactlyOne: `Exactement un`,
    exactOnlineActivatedForThisEnvironment: `Exact Online activé pour cet environnement`,
    exactOnlineCRMAccountCode: `Code de compte Exact Online CRM`,
    exactOnlineGLAccountCode: `Code de Exact Online GL account`,
    exactOnlineIntegration: `Intégration Exact Online`,
    exactOnlineNotActivatedNotification: `Exact Online n'est pas activé pour cet environnement, contactez support@hams.be pour activer cette intégration.`,
    exactOnlineUpToDate: `Exact Online up to date`,
    example: `Exemple`,
    existingProcess: `Processus existant`,
    expected: `Prévu`,
    expectedDeliveryDate: `Date de livraison prévue`,
    expectedEndDate: `Date de fin prévue`,
    expectedHandoverDate: `Date de fourniture estimée`,
    expectedPaymentBefore: `Paiement prévu avant`,
    expectedWhat: (what: string | number) => `${what} prévu(e)(s)`,
    expectedWhatExpired: (what: string | number) => `${what} prévu(e)(s) expiré(e)(s)`,
    expense: `Dépense`,
    expenseOrIncome: `Dépense ou recette`,
    expired: `Expiré`,
    exportAllCountNonCashSalesToZipFile: (count: string | number) => `Exporter tous (${count.toString().toLowerCase()}) non ventes au comptant en fichier .zip`,
    exportCountToZip: (count: string | number) => `Exporter (${count.toString().toLowerCase()}) en fichier .zip`,
    exportDailyReceiptsFormattedAnWithTotals: `Exporter entrées quotidiennes formatées et avec les totaux`,
    exportFinancialStockFormatted: `Exporter le stock financier formaté`,
    exportJournalFormatted: `Journal d'exportation formaté`,
    exportLinesAllResultsInFormat: (format: string | number) => `Exporter les lignes (tous les résultats) au format ${format.toString().toLowerCase()}.`,
    exportLinesFilteredResultsInFormat: (format: string | number) => `Exporter les lignes (résultats filtrés) au format ${format.toString().toLowerCase()}.`,
    exportPatientToNhaxFile: `Exporter le patient vers le fichier NHAX`,
    exportToUbl: `Exporter vers l'UBL`,
    exportWhat: (what: string | number) => `Exporter ${what.toString().toLowerCase()}`,
    extension: `Extension`,
    external: `Externe`,
    externalAuthentication: `Authentification externe`,
    externalLoginDisabled: `L'utilisateur ne peut se connecter via les services de Google`,
    externalLoginEnabled: `L'utilisateur peut se connecter via les services de Google`,
    externalReference: `Référence externe`,
    extraLine: `Ligne supplémentaire`,
    extraLineCaption: `Article à vendre pour lequel aucun article n'existe dans le catalogue de produits`,
    exVatPerPiece: `ex. TVA par pièce`,
    exVatShort: `Hors TVA`,
    failed: `Raté`,
    failedTrial: `Essai raté`,
    failedTrialCaption: `Terminer le test négativement\nPatient n'est pas satisfait, les appareils retournent au stock`,
    failedTrials: `Essais ratés`,
    failedTrialsThisYearBracketsYTD: `Essais ratés cette année (YTD)`,
    failureVatPercentageHelperText: `TVA pourcentage allocation forfaitaire`,
    fault: `Erreur`,
    faultInConfiguration: `Erreur de configuration`,
    faultSingularOrPlural: `Erreur(s)`,
    faultStartNoahModules: `Erreur lors du démarrage modules Noah`,
    feHearingProtectionChargerEarmouldEtc: `P.e. protection auditive, embout auriculaire, chargeur, …`,
    female: `Féminin`,
    fieldsToFill: `Champs à remplir`,
    file: `Fichier`,
    fileName: `Nom de fichier`,
    filledInWhat: (what: string | number) => `${what} rempli(e)(s)`,
    fillInLRSeparately: `Entrez G/D séparément`,
    fillInMissingData: `Veuillez remplir les données manquantes`,
    fillInMissingDataTitle: `Complétez les informations manquantes.`,
    fillInMono: `Entrez mono`,
    fillMfrModelInYourself: `Entrer marque/modèle vous-même`,
    fillWhat: (what: string | number) => `Entrer ${what.toString().toLowerCase()}`,
    filteredResults: `Résultats filtrés`,
    financial: `Financier`,
    financialQuantity: `Nombre en stock (financière)`,
    finished: `Fini`,
    finishedProcesses: `Processus finis`,
    finishedTreatments: `Traitements finis`,
    firstDeliveryDate: `Date de 1ère livraison`,
    firstLetterOfFriday: `V`,
    firstLetterOfMonday: `L`,
    firstLetterOfSaturday: `S`,
    firstLetterOfSunday: `D`,
    firstLetterOfThursday: `J`,
    firstLetterOfTuesday: `M`,
    firstLetterOfWednesday: `M`,
    firstName: `Prénom`,
    firstUsageDate: `Date de première utilisation`,
    fitted: `Ajusté`,
    fitting: `Ajustement`,
    fittingDate: `Date d'ajustement`,
    followingSalesHaveManualAdditionsToTheDocument: `Pour les ventes suivantes, le document a été ajusté manuellement`,
    followingSettlementProposalsHaveManualAdditionsToTheDocument: `Pour les propositions de règlement suivantes, le document a été ajusté manuellement`,
    followInstallationInstructionsOnScreenCloseScreenAfterInstallation: `Suivez les instructions d'installation à l'écran\nFermez cette fenêtre après l'installation`,
    followUpVerb: `Suivre`,
    forAPatientQuestion: `Pour un patient?`,
    forASinglePatient: `Pour un seul patient`,
    forExampleHearingAid: `P.ex. appareil auditif`,
    forExampleShort: `P.ex.`,
    forgotPasswordHeaderText: `Veuillez saisir votre adresse électronique ci-dessous.
Ensuite, vous recevrez un courriel contenant des instructions pour changer votre mot de passe.`,
    forgotPasswordQuestion: `Oublié mot de passe?`,
    form: `Formulaire`,
    formatAsSize: `Format`,
    formattingOptions: `Options de formatage`,
    forMultipleArticles: `Pour plusieurs articles`,
    forMultiplePatients: `Pour plusieurs patients`,
    forNoneOneOrMultiplePatients: `Pour aucun, un ou plusieurs patients`,
    forPatient: `Pour patient`,
    forward: `En avant`,
    forwarded: `Transmis`,
    forwardInvoiceAfterDocumentIsCreated: `Transmettre la facture uniquement après la création du document`,
    forwardInvoiceOnceItsCreatedEvenWithoutDocument: `Transmettre la facture immédiatement (sans document)`,
    forwardViaEmail: `Envoyer par e-mail`,
    forWho: (who: string | number) => `Pour ${who}`,
    foundAtInventoryItems: `Trouvé dans les articles en stock`,
    foundInReadOutsFromNoah: `Trouvé dans les lectures de Noah`,
    foundPatientsOnName: `Patients trouvés par nom`,
    foundPatientsOnSsNr: `Patients trouvés par numéro de registre national`,
    freeInputField: `Champ libre`,
    french: `Français`,
    frequencyRange: `Bande fréquentielle`,
    fromArrowTo: `De -> à`,
    fromCompleteList: `À partir de liste complète`,
    fromLocation: `À partir d'emplacement`,
    fromNeeds: `Des besoins`,
    fromPreviousReadOutsFromNoah: `À partir des appareils de Noah déjà lus`,
    fromProductCatalog: `À partir du catalogue des produits`,
    fromStock: `En stock`,
    fromStockShort: `En stock`,
    fromSupplier: `À partir du fournisseur`,
    fromTemplate: `À partir du modèle`,
    fromTo: `De / À`,
    fullDay: `Journée complète`,
    fullyCredited: `Entièrement crédité`,
    fullyPaid: `Entièrement payé`,
    function: `Fonction`,
    functions: `Fonctions`,
    futureAppointments: `Rendez-vous à venir`,
    gender: `Sexe`,
    general: `Général`,
    generalData: `Données générales`,
    generalLedger: `Compte du grand livre`,
    generalLedgerAccountAllocationMethod: `Affectation de la méthode de grands livres`,
    generalPractitioner: `Médecin généraliste`,
    generalPractitionerCreated: `Nouveau médecin généraliste créé`,
    generalPractitioners: `Médecins généralistes`,
    generalPractitionerUpdated: `Médecin généraliste mis à jour`,
    generalSettings: `Paramètres généraux`,
    goBack: `Retourner`,
    graph: `Graphique`,
    graphs: `Graphiques`,
    groupedByMfrModel: `Groupés (par marque/modèle)`,
    groupedPayments: `Paiements groupés`,
    groupOnThis: `Grouper par ceci`,
    groupPayment: `Paiement de groupe`,
    halfOfTime: `Moitié du temps`,
    HAMSCollabWithRosa: `HAMS et le calendrier de Rosa font équipe, plus d'informations sur`,
    hamsIsNotRegisteredAsMgmtSoftware: `HAMS n'est pas enregistré comme logiciel de « gestion » pour Noah.`,
    hamsNoahClientIsInstalled: `Le logiciel client HAMS-Noah est installé`,
    hamsNoahClientIsNotInstalledOrNotActive: `Le logiciel client HAMS-Noah n'est pas installé ou inactif`,
    hamsWorksTogetherWithROSAforCalendar: `HAMS collabore avec ROSA pour le module calendrier/agenda`,
    handedOver: `Livré`,
    handedOverAndPaid: `Livré et payé`,
    handedOverBeforeApprovals: `Livré avant approbations`,
    handedOverThisYearBracketsYTD: `Remis cette année (YTD)`,
    handedOverWithoutApprovals: `Livré sans approbations`,
    handled: `Traité`,
    handover: `Fourniture`,
    handoverBeforeFirstOfAugustWillBeRegisteredWithNumber: (code: string | number) => `Les fournitures avant 01/08/2023 sont enregistrés sous le numéro: ${code.toString().toLowerCase()}`,
    handoverBeforeFirstOfAugustWillUseOldCode: `Les fournitures avant 01/08/2023 sont enregistrés sous l'ancien numéro`,
    handoverCertificate: `Attestation de fourniture`,
    handoverDate: `Date de fourniture`,
    handoverOfEquipment: `Fourniture d'équipement`,
    handoverOverDue: `Fourniture en retard`,
    handoverRegistered: `Fourniture enregistrée`,
    handovers: `Fournitures`,
    handoverToPatient: `Fournir au patient`,
    hardware: `Bien ou matériel`,
    hardwareExplanation: `Quelque chose de tangible (p.ex. embout auriculaire, appareil auditif ou protection auditive)`,
    hasBatteries: `Contient des piles`,
    hasBatteriesBebatApplicable: `Contient des piles (Bebat en vigueur)`,
    hasBatteriesExplanation: `Cet appareil contient des piles, donc Bebat applique`,
    hasDeliveries: `A des livraisons`,
    hasDeltaFinancialAndPhysical: `Il y a un diff. entre financière et physique`,
    hasNoBatteries: `N'a pas de piles`,
    hasNoDeliveries: `N'a pas de livraisons`,
    hasNoOrders: `N'a pas de commandes`,
    hasNoPurchases: `N'a pas d'achats`,
    hasNoSerialNumber: `N'a pas de numéro de série`,
    hasOrders: `A des commandes`,
    hasPurchases: `A des achats`,
    hasRechargeableBatteriesBebatApplicable: `Contient batteries rechargeables (Bebat en vigueur)`,
    hasRequestForInsuranceAllowance: `A demande d'allocation de l'assurance`,
    hasSerialNumber: `A numéro de série`,
    hasVaryingPrice: `A un prix variable`,
    heading: `En-tête`,
    headPhones: `Casque d'écoute`,
    headPhonesLeftRight: `Casque d'écoute G/D`,
    headPhonesShort: `CE`,
    healthInsuranceFund: `Mutualité`,
    healthInsuranceFunds: `Mutualités`,
    healthInsuranceFundShort: `Mutual.`,
    hearingAid: `Appareil auditif`,
    hearingAids: `Appareils auditifs`,
    hearingAidsShort: `AA`,
    hearingCentre: `Centre d'audition`,
    hearingImprovementInWhat: (what: string | number) => `Gain auditif en ${what.toString().toLowerCase()}`,
    hearingLossLessOrMoreThan40dB: `Perte auditive supérieure/inférieure à 40db`,
    hearingLossLessThen40dB: `Perte auditive < 40dB`,
    hearingLossLessThen40dBWithAverages: `Perte auditive inférieure à 40db (moy. 1000/2000/4000Hz)`,
    hearingLossMoreThan40dB: `Perte auditive ≥ 40dB`,
    hearingLossMoreThan40dBWithAverages: `Perte auditive d'au moins 40 db (moy. 1000/2000 / 4000hz) ou exception (2.1.2)`,
    hearingProtection: `Protection auditive`,
    hearingProtectionNewArticleCaption: `Sur mesure ou non. Bonnet de bain, bonnet de couchage, bonnet musical, …`,
    here: `Ici`,
    hidden: `Caché`,
    hideAnamnesis: `Cacher anamnèse`,
    hideArticle: `Masquer l'article`,
    hideProperties: `Cacher les propriétés`,
    hideSplitUpPerVatPercentage: `Masquer répartition de %TVA`,
    hideThisArticle: `Cacher cet article`,
    hideWhat: (what: string | number) => `Cacher ${what.toString().toLowerCase()}`,
    hifWhatDoesNotExistAnymore: (what: string | number) => `Mutualité ${what.toString().toLowerCase()} n'existe plus`,
    highToLow: `Haut -> bas`,
    historicalSale: `Vente (historique)`,
    history: `Histoire`,
    home: `Domicile`,
    homePhone: `Tél. domicile`,
    hospitalized: `Hospitalisé`,
    hoursAgo: (hours: string | number) => `Il y a ${hours.toString().toLowerCase()} heures`,
    hoursPerDay: `Heure/jour`,
    howDoeseIDReadWorks: `Comment fonctionne la lecture de la carte eID ?`,
    icoHifsPrefixIsCodeHif: `S'il y a des mutualitées, le préfixe sera automatiquement le code de cette mutualité.`,
    id: `ID`,
    identityCard: `Carte d'identité`,
    ifNoPatientSelectedItsObligatedToSelectAVatCustomer: `Si aucun patient n'est sélectionné, vous devez sélectionner un client TVA.`,
    ignore: `Ignorer`,
    ignoreAllowanceOrLinkWithArticleFromProductCatalog: `Ignorer l'allocation ou créer un lien avec un article du catalogue de produits`,
    ignored: `Ignoré`,
    ignoreOrLinkWithAnArticleFromProductCatalog: `Ignorer un article du catalogue de produits ou créer un lien avec celui-ci`,
    ignoreSale: `Ignorer la vente`,
    ignoreSaleImperative: `Ignorer les ventes`,
    ignoreThisDevice: `Ignorer cet appareil`,
    ignoreThisReadOut: `Ignorer cette lecture`,
    ignoreVerb: `Ignorer`,
    ignoreWhatQuestion: (what: string | number) => `Ignorer ${what.toString().toLowerCase()} ?`,
    image: `Image`,
    imageFormattingHelperText: (key: string | number) => `Une image peut éventuellement être fournie avec des dimensions.\nUtilisez {{${key.toString().toLowerCase()}:BxH}}} avec B = largeur, H = hauteur (en pixels)\nCette clé peut également être utilisée dans un tableau.Les dimensions de la cellule seront alors les dimensions maximales.`,
    import: `Importer`,
    importCountDevices: (count: string | number) => `Importer ${count.toString().toLowerCase()} appareils`,
    importDeviceInStock: `Ajouter appareil au stock`,
    imported: `Importé`,
    importedFromNoah: `Importé de Noah`,
    importIfNewerAction: `Importer comme nouvelle action`,
    importMultipleDevicesToStock: `Importer plusieurs appareils en stock`,
    importMultipleDevicesToStockContentCount: (count: string | number) => `Sur la base du filtre défini, il y a ${count.toString().toLowerCase()} appareils trouvés\nqui peuvent être importés selon les règles prédéfinies.\n\nVous voulez les importer tous ?`,
    importRosaPatient: `Importez patient du ROSA`,
    importThisDeviceInStock: `Importer cet appareil dans le stock`,
    importVerb: `Importer`,
    importWhat: (what: string | number) => `Importer ${what.toString().toLowerCase()}`,
    improvement: `Amélioration`,
    inactive: `Inactif`,
    inAfterTreatment: `En après traitement`,
    inBackOrder: `Commande en souffrance`,
    inBrowser: `Dans le navigateur`,
    income: `Recettes`,
    inComparisonWithPreviousPeriod: `Par rapp. à la période précédente`,
    inComparisonWithPreviousTransaction: `Par rapport à la transaction précédente`,
    inConsulation: `En consultation`,
    incorrectFormat: `Format non valide`,
    incorrectlyLinked: `Incorrectement lié`,
    indexNumber: `Numéro d'index`,
    individualPerSerialNumber: `Individuel (par numéro de série)`,
    individualReadOutDevice: `Individuel (par numéro de série)`,
    infoNotFilledIn: (info: string | number) => `${info} non renseigné(e)(s)`,
    information: `Information`,
    inForRepair: `En réparation`,
    initials: `Initiales`,
    inLoan: `En prêt`,
    inOrder: `En commande`,
    inRepair: `En réparation`,
    insertCard: `Insérez une carte eID valide`,
    installation: `Installation`,
    installationAndConfiguration: `Installation et configuration`,
    installMiddleware: `Installer middleware`,
    installWhat: (what: string | number) => `Installer ${what.toString().toLowerCase()}`,
    inStepsOf: `Par tranche de`,
    inStock: `En stock`,
    inStockOnALocation: `En stock dans un emplacement`,
    insufficientInformation: `Information insuffisante`,
    insurance: `Assurance`,
    insurancedAndProlongedBetweenBracketsWarranties: `Assurances et garanties (prolongées)`,
    insurancesAndProlongedWarranties: `Assurances et garanties prolongées`,
    integrations: `Intégrations`,
    integrationStatus: `Statut d'intégration`,
    intensity: `Intensité`,
    internal: `Interne`,
    internalReference: `Référence interne`,
    inTest: `En test`,
    inTheEar: `Dans l'oreille`,
    intraCommCaption: `Livraisons intracommunautaires basées sur l'article 138 de la directive européenne TVA 2006/112/CE`,
    inTreatment: `En traitement`,
    invalidAction: `Action invalide`,
    invalidCombinationEmailPassword: `Combinaison e-mail/mot de passe incorrecte`,
    invalidLicense: `License non valide`,
    invalidLicenseContactHams: `Licence non valide, contactez HAMS`,
    invalidSocialSecurityNumber: `Numéro de registre national non valide`,
    inVatShort: `TVA incl.`,
    inventoryItem: `Article en stock`,
    inventoryItemNotInStock: `Article pas en stock`,
    inventoryItems: `Articles en stock`,
    inventoryManagement: `Gestion des stocks`,
    inventoryManagementShortened: `Gestion des stocks`,
    invoice: `Facture`,
    invoiceAdvance: `Facturation anticipée`,
    invoiceAndCash: `Facture et comptant`,
    invoiceCustomerIsAPatientCaption: `La facture sera liée au patient\nEn option, une facture peut être adressée à un client TVA`,
    invoiceCustomerIsNotAPatientCaption: `La facture est adressée à un client TVA`,
    invoiced: `Facturé`,
    invoiceForwarding: `Transmission des factures`,
    invoiceForwardingIntegration: `Intégration de la transmission des factures`,
    invoiceNumber: `Numéro de facture`,
    invoicePriorToApprovals: `Facturation avant approbation`,
    invoiceProcess: `Processus de facturation`,
    invoices: `Factures`,
    invoiceVerb: `Facturer`,
    inWaitingRoom: `Dans la salle d'attente`,
    isAlsoPurchased: `Peut également être acheté`,
    isElectronics: `Est électronique`,
    isElectronicsExplanation: `Cet appareil contient de l'électronique, les taxes s'appliquent donc.`,
    isNotElectronics: `N'est pas électronique`,
    isNotTailorMade: `Pas sur mesure`,
    isRecalculatingNowDataWillBeUpdated: `Recalcul maintenant, les données seront bientôt mises à jour`,
    isSoftwareAlreadyInstalledQuestion: `Le logiciel est-il déjà installé ?`,
    isTailorMade: `Sur mesure`,
    iteCaptionTypes: `Dans l'oreille (IIC, CIC, ITC, HS, FS, …)`,
    item: `Article`,
    itemAddedToRepair: `Article ajouté à la réparation`,
    itemAlreadyInList: `Article est déjà dans la liste`,
    itemFromProductCatalog: `Article du catalogue des produits`,
    itemNotFoundQuestionClickHereToDefineNewProductCatalogItem: `Article non trouvé ?\nCliquez ici pour définir un nouvel article dans le catalogue de produits.`,
    itemNotFoundQuestionClickHereToDefineNewSerialNumber: `Article non trouvé ?\nCliquez ici pour définir un nouveau numéro de série.`,
    itemReserved: `Article réservé`,
    items: `Articles`,
    itemsReturned: `Articles retournés`,
    itemsToDeliver: `Articles à livrer`,
    itemsToLoan: `Articles à prêter`,
    itemsToOrder: `Articles à commander`,
    kpiCashDesk: `Utiliser module de caisse`,
    kpiPlannable: `Planifiable`,
    kpiStockResource: `Utilisater module de stock`,
    label: `Étiqueter`,
    labels: `Étiquettes`,
    language: `Langue`,
    lastActivity: `Dernière activité`,
    lastHandover: `Dernière fourniture`,
    lastLinkWas: `La dernière fois, cet appareil était lié à:`,
    lastLoggedIn: `Dernière connexion`,
    lastLoggedInDate: `Date de dernière connexion`,
    lastModifiedAt: `Dernière modification le`,
    lastModifiedDate: `Date de la dernière mise à jour`,
    lastName: `Nom de famille`,
    lastNameCommaFirstName: `Nom de famille, prénom`,
    lastPaymentDate: `Date du (dernier) paiement`,
    lastPayments: `Derniers paiements`,
    lastReadOut: `Dernière lecture`,
    lastReadOutDate: `Date de dernière lecture`,
    lastReadOutDevicesFromNoah: `Dernière lecture d'appareils (de Noah)`,
    lastSpsMetaRefHeader: `Méta-réf. de dernière prop. de règlement`,
    lastSpsRefHeader: `Réf. de dernière prop. de règlement`,
    lastSynced: `Dernière synchronisation`,
    lastUpdated: `Dernière modification`,
    lastUsageDate: `Date de dernière utilisation`,
    lastWhat: (what: string | number) => `Dernier(ière) ${what.toString().toLowerCase()}`,
    lastWhatDays: (what: string | number) => `Les ${what.toString().toLowerCase()} derniers jours`,
    latest: `Dernier`,
    latest10: `10 derniers`,
    latest10Notifications: `10 derniers avis`,
    latestTreatment: `Dernier traitement`,
    layoutOverviewScreenOfAPatient: `Mise en page de l'écran d'aperçu d'un patient`,
    left: `Gauche`,
    leftAndRight: `Gauche et droite`,
    leftColumn: `Colonne de gauche`,
    leftEar: `Oreille gauche`,
    legend: `Légende`,
    lent: `Prêté`,
    less: `Moins`,
    letter: `Lettre`,
    letterToAdvisoryPhysician: `Lettre au médecin-conseil`,
    letterToEntPhysician: `Lettre au médecin ORL`,
    license: `Licence`,
    line: `Ligne`,
    lines: `Lignes`,
    linesDeletedAndOrAddedAfterDocumentCreation: `Lignes ajoutées et/ou supprimées après la création du document.`,
    link: `Lien`,
    linkAnExistingProcess: `Lier un processus existant`,
    linked: `Lié`,
    linkedToAnotherPatient: `Lié à un autre patient`,
    linkedToMultiple: `Lié à plusieurs`,
    linkExactOnlineCRMAccountForWho: (who: string | number) => `Lier le compte Exact Online CRM de ${who}`,
    linkExactOnlineGLAccountForFallbackVatPercentage: (vatPercentage: string | number) => `Lie Exact Online GL account for fallback value ${vatPercentage.toString().toLowerCase()} TVA %`,
    linkExactOnlineGLAccountForWhat: (what: string | number) => `Lien Exact Online GL en ligne pour ${what.toString().toLowerCase()}`,
    linkGLAccount: `Lier le compte GL`,
    linkHealthInsuranceFund: `Relier mutualité`,
    linkItemWithThisWhat: (what: string | number) => `Lier article avec cette ${what.toString().toLowerCase()}`,
    linkRIZIVFromProductCatalogCaption: `Pour cet article, plusieurs possibilités ont déjà été définies,\nfaites un choix dans cette liste abrégée`,
    linkRosaPatient: `Liens vers les patients de ROSA`,
    linkRosaUsersWithHamsUsers: `Relier le ou les utilisateurs ROSA avec le ou les utilisateurs HAMS`,
    links: `Liens`,
    linkWithAHealthInsuranceFund: `Lien avec un mutualité`,
    linkWithAnExactOnlineCRMAccount: `Lien avec un compte CRM Exact Online`,
    linkWithAnInventoryItem: `Lien avec un article en stock`,
    linkWithAnotherArticleFromProductCatalog: `Lien avec un autre article du catalogue des produits`,
    linkWithAnotherHealthInsuranceFund: `Lien avec un autre mutualité`,
    linkWithPatient: `Lien avec un patient`,
    linkWithSameItemFromProductCatalogAsLastTime: `Lien avec le même article du catalogue des produits que la dernière fois`,
    list: `Liste`,
    listPrice: `Prix recommandé`,
    loadingEllipsis: `En chargement ...`,
    loadingStatus: `Récupérer le statut`,
    loan: `Prêt`,
    loanedOutItems: `Articles prêtés`,
    loanEnded: `Prêt terminé`,
    loans: `Prêts`,
    loanSlip: `Fiche de prêt`,
    loanStarted: `Prêt commencé`,
    location: `Emplacement`,
    locationCashAndStockResourcesBothNeedToBeEnabled: `Un emplacement où le module de caisse est utilisé doit également être un emplacement de stock.`,
    locationCashDesk: `Emplacement de caisse`,
    locationCashDeskResourceWillBeDisabled: `Le module de caisse ne sera plus utilisable à cet emplacement`,
    locationCashDeskResourceWillBeEnabled: `Le module de caisse deviendra utilisable à cet emplacement`,
    locationCreated: `Emplacement créé`,
    locationDetails: `Détails d'emplacement`,
    locationPlannable: `Planifiable`,
    locationPlannableWillBeDisabled: `Cet emplacement ne peut plus être utilisé pour prendre des rendez-vous.`,
    locationPlannableWillBeEnabled: `Cet emplacement peut être utilisé pour prendre des rendez-vous.`,
    locations: `Emplacements`,
    locationsCurrentlyOnStock: `Emplacement(s) actuellement en stock`,
    locationSlashRoom: `Emplacement/Chambre`,
    locationStockManagement: `Emplacement du stock`,
    locationStockManagementResourceWillBeDisabled: `Le module de gestion des stocks ne sera plus utilisable à cet emplacement`,
    locationStockManagementResourceWillBeEnabled: `Le module de gestion des stocks sera utilisable à cet emplacement`,
    locationUpdated: `Emplacement mis à jour`,
    locationWhereProductsWillBeReceived: `L'emplacement où les articles sont reçus.`,
    locationWhereSold: `Lieu de vente`,
    locationWillAlsoBeChangedOnOtherProcesses: `L'emplacement est AUSSI changé chez les processus en cours d'exécution`,
    locationWillAlsoBeChangedWithPatient: `L'emplacement est AUSSI changé chez le patient`,
    locationWillBeDeleted: `Cet emplacement sera définitivement supprimé du système.`,
    locationWillNotBeChangedWithPatient: `L'emplacement n'est PAS modifié chez le patient`,
    locationWillOnlyBeChangedWithThisPatientAndNotOnProcesses: `L'emplacement n'est modifié que chez le patient et non chez les processus.`,
    locationWithAddressExists: `Il existe déjà un emplacement avec cette adresse.`,
    locationWithNameExists: `Il existe déjà un lieu portant le même nom.`,
    loggedIn: `Connecté`,
    loggedInDevices: `Appareils connectés`,
    login: `Connecter`,
    loginHeaderText: `Connectez-vous avec votre e-mail et votre mot de passe`,
    logo: `Logo`,
    logout: `Se déconnecter`,
    logoutAfter20MinutesOfInactivity: `Déconnexion automatique après 20 minutes d'inactivité`,
    logoutAllSessions: `Déconnexion de toutes les sessions`,
    lost: `Perdu`,
    lowerCase: `Première lettre en miniscule`,
    lowToHigh: `Bas -> haut`,
    lumpSum: `Montant forfaitaire`,
    lumpSumContribution: `Allocation forfaitaire`,
    mainNomenclatureCode: `Code de nomenclature principale`,
    mainUser: `Utilisateur principal`,
    makeAChoice: `Faites un choix`,
    makeAChoiceFromCompleteList: `Veuillez choisir dans la liste complète`,
    makeDefault: `Rendre par défaut`,
    makeDefaultOffice: `Faites-en le cabinet par défaut`,
    makePrimary: `Rendre primaire`,
    makeUserNoLongerPlannableContent: `En confirmant, il n'est plus possible de planifier des rendez-vous pour cet utilisateur`,
    makeUserPlannableAgainContent: `En confirmant, il est à nouveau possible de planifier des rendez-vous pour cet utilisateur`,
    male: `Masculin`,
    manual: `Manuellement`,
    manualAdditions: `Ajouts manuels`,
    manualHandoverDateEntryContent: `Date de la dernière fourniture saisie manuellement.\n(*si cette livraison n'a pas été enregistrée via HAMS)`,
    manuallyAssignedLabels: `Étiquettes attribués manuellement`,
    manuallyDeactivated: `Désactivé manuellement`,
    manuallySetWarrantyEndDate: `Fixation manuelle de la date de fin de garantie`,
    manualPatientNumbering: `Numérotation manuelle des patients`,
    manualSelectADate: `Sélectionnez manuellement une date`,
    manufacturer: `Fabricant`,
    manufacturerReference: `Référence du fabricant`,
    manufacturers: `Fabricants`,
    margin: `Taux de marque`,
    marginTooltip: `Taux de marque = Marge brute / Prix de vente`,
    markAsLost: `Marquer comme perdu`,
    markAsRead: `Marquer comme lu`,
    markItemAsDemoQuestion: `Marquer article comme DEMO ?`,
    markItemAsNonDemoQuestion: `Marquer article comme NON DEMO ?`,
    markup: `Taux de marge`,
    markupTooltip: `Taux de marge = Marge brute / Prix d'achat`,
    material: `Matériel`,
    measured: `Mesuré`,
    measureVerb: `Mesurer`,
    medicalPrescription: `Prescription medicale`,
    medicalPrescriptionApprovedTitle: `Prescription médicale reçue (approbation ORL)`,
    medicalPrescriptionDisApprovedTitle: `Prescription médicale (NON approuvée par le médecin ORL)`,
    medicalPrescriptionReceived: `Prescription médicale reçue`,
    medicalPrescriptionReceivedNotSentToAdvPhys: `Prescription médicale reçue, pas encore envoyée au méd.-cons.`,
    medicalTreatmentNomenclature: `Nomenclature`,
    method: `Méthode`,
    minimalRequiredDocumentsAccordingToRIZIV: `Documents minimums requis (INAMI)`,
    minutesAgo: (minutes: string | number) => `Il y a ${minutes.toString().toLowerCase()} minutes`,
    missingDocuments: `Documents manquants`,
    mobile: `Mobile`,
    mobilePhone: `Tél. mobile`,
    mode: `Mode`,
    modeGenerateInvoiceAtHandover: `Mode génère des factures à la livraison`,
    model: `Modèle`,
    modules: `Modules`,
    moduleStarted: `Module démarré`,
    mono: `Mono`,
    monoPhonic: `Monophonique`,
    monthDateOfBirth: `Mois de la date de naissance`,
    monthHandover: `Mois de fourniture`,
    more: `Plus`,
    moreActions: `Plus d'actions`,
    moreInformationAboutWhatHasChangedQuestionClickHereForMoreInformation: `Plus d'informations sur ce qui change exactement ? Cliquez ici pour en savoir plus.`,
    moreThanOneDayAgo: `> il y a 1 jour`,
    mostOfTime: `La plupart du temps`,
    mostRecentTonalAudiogramOfDate: `Audiogramme tonal le plus récent de`,
    moveAppointmentQuestion: `Déplacer le rendez-vous?`,
    movedToAnotherLocation: `Déplacé vers un autre endroit`,
    muchBetter: `Beaucoup mieux`,
    multiple: `Plusieurs`,
    multipleArticlesFound: `Plusieurs articles trouvés`,
    multipleCustomerAccounts: `Plusieurs comptes clients`,
    multipleLines: `Plusieurs lignes`,
    multipleModulesCanOpenThisActionSelectOne: `Plusieurs modules peuvent ouvrir cette action, sélectionnez-en un`,
    multipleNomenclaturesSelectedThisIsNotPossible: `Nomenclatures multiples sélectionnées, ce n'est pas possible`,
    multipleProductsNotSpecificForAPatient: `Produits multiples, non spécifiques à un patient`,
    multiplePurchases: `Achats multiples`,
    multipleRooms: `Plusieurs chambres`,
    multipleSales: `Ventes multiples`,
    mustBeLargerThanWhat: (what: string | number) => `Doit être plus grand que ${what.toString().toLowerCase()}`,
    mustUpdateText: `Une mise à jour est disponible,\ncliquez ci-dessous pour mettre à jour l'application`,
    myCalendars: `Mes calendriers`,
    myCommissions: `Mes commissions`,
    myPayouts: `Mes paiements`,
    myProfile: `Mon profil`,
    myWhat: (what: string | number) => `Mon/a/es ${what.toString().toLowerCase()}`,
    name: `Nom`,
    nameArticleFromProductCatalog: `Nom du catalogue de produits`,
    navigateToDetailsOfSale: `Naviguer vers les détails de la vente`,
    navigateToDetailsOfTreatment: `Accédez aux détails du traitement`,
    navigateToDetailsPage: `Naviguez vers la page détails`,
    navigateToWhat: (what: string | number) => `Naviguez vers ${what.toString().toLowerCase()}`,
    navigation: `Navigation`,
    need: `Besoin`,
    needed: `Requis`,
    needs: `Besoins`,
    needsASignature: `Nécessite une signature`,
    needsASignatureQuestion: `Nécessite une signature?`,
    negative: `Négatif`,
    negativeEvaluationTrial: `Essai d'évaluation négatif`,
    neverImport: `Ne jamais importer`,
    neverLogoutAuto: `Ne jamais se déconnecter automatiquement`,
    newAnnotation: `Nouvelle annotation`,
    newArticlesInquiry: `Demande d'articles`,
    newArticlesInquiryForWho: (who: string | number) => `Nouvelle demande d'articles`,
    newCharger: `Nouveau chargeur`,
    newDocument: `Nouveau document`,
    newEarmould: `Nouveau embout auriculaire`,
    newEntPhysician: `Nouveau médecin ORL`,
    newestFirst: `Le plus récent d'abord`,
    newFieldToFill: `Nouveau champ à remplir`,
    newGeneralPractitioner: `Nouveau médecin généraliste`,
    newHardware: `Nouveau type de matériel « autre »`,
    newHearingAid: `Nouveau appareil auditif`,
    newInvoiceForWhoQuestion: `Nouvelle facture : pour qui ?`,
    newItem: `Nouvel article`,
    newItemChoice: `Nouvel article: faire un choix`,
    newLoan: `Nouveau prêt`,
    newLocation: `Nouvel emplacement`,
    newNotifications: `Nouveaux avis`,
    newOffice: `Nouveau cabinet`,
    newOrder: `Nouvelle commande`,
    newOrderWithWho: (who: string | number) => `Nouvelle commande avec ${who}`,
    newPassword: `Nouveau mot de passe`,
    newPatient: `Nouveau patient`,
    newProcess: `Nouveau processus`,
    newProduct: `Nouveau produit`,
    newRemark: `Nouveau commentaire`,
    newRequestForInsuranceAllowance: `Nouvelle demande d'allocation de l'assurance`,
    newRfiaShort: `Nouvelle demande d'AA`,
    newSerialNumber: `Nouveau numéro de série`,
    newSerialNumberShort: `Nouveau ns`,
    newService: `Nouveau service`,
    newSupplier: `Nouveau fournisseur`,
    newTailorMadeProduct: `Nouveau produit sur mesure`,
    newTailorMadeProductProcess: `Nouveaux processus de produit sur mesure`,
    newTangibleProduct: `Nouveau produit tangible`,
    newTender: `Nouvelle offre`,
    newTreatment: `Nouveau traitement`,
    newWhat: (what: string | number) => `Nouveau(x)(elle)(s) ${what.toString().toLowerCase()}`,
    nextAppointments: `Prochains rendez-vous`,
    nhaxDataReadAndAddedToThisPatient: `Données de nhax lues et ajoutées à ce patient`,
    nickName: `Surnom`,
    no: `Non`,
    noAccess: `Pas d'accès`,
    noAccessToAllPages: `Pas d'accès à toutes les pages`,
    noAccessToThisPageContactHamsAdmin: `Pas d'accès à cette page\nContactez un administrateur HAMS pour plus d'informations.\n\nÊtes-vous un administrateur ?\nVeuillez vérifier votre profil et modifier « l'accès aux pages » sous l'onglet « restrictions ».`,
    noAddressInformation: `Aucune information sur l'adresse`,
    NoahActions: `Actions de Noah`,
    NoahActionSingularOrPlural: `Action(s) de Noah`,
    NoahData: `Données de Noah`,
    noahDeviceReadOutsLinkedToPcsHeader: `Lié aux articles du catalogue de produits (règles)`,
    NoahExternalModules: `Modules externes de Noah`,
    NoahImportAlwaysCaption: `Importer automatiquement tous les appareils dans le stock.\nCette méthode permet de réduire le travail manuel, mais les appareils plus anciens apparaîtront également dans le stock.`,
    NoahImportIfNewerActionCaption: `Importer les appareils uniquement s'il s'agit d'une « nouvelle » action Noah.\nLes anciens appareils ne seront pas importés dans le stock`,
    NoahNeverImportCaption: `Ne jamais importer automatiquement des appareils dans le stock.\nCette option donne le plus de contrôle sur le stock.\nL'importation des appareils se fait manuellement.`,
    NoahSessions: `Sessions de Noah`,
    noAlternativeLogoPresent: `Il n'y a pas de logo alternative`,
    noAppointmentsFound: `Aucun rendez-vous trouvé`,
    noAppointmentsPlanned: `Aucun rendez-vous prévu`,
    noArticlesInquiriesDefinedYet: `Aucune demandes d'articles enregistrée pour le moment`,
    noBeIDMiddlewareInstalled: `Aucun BeID middleware n'est installé`,
    noCardReaderAttached: `Aucun lecteur de carte n'est connecté`,
    noCloseThisScreen: `Non, fermer cette fenêtre`,
    noCodesLinkedYet: `Aucun code n'est encore lié`,
    noCommaCloseWithoutSaving: `Non, fermer sans enregistrer`,
    noCommaGoBack: `Non, reviens en arrière`,
    noCommaWhat: (what: string | number) => `Non, ${what.toString().toLowerCase()}`,
    noConnectionCouldBeMadeToeIDSoftware: `Une connexion ne peut être établie avec le logiciel eID\nSi vous êtes sûr qu'il est installé, démarrez-le d'abord`,
    noConnectionToClientSoftware: `Pas de connexion avec logiciel Hams.Client`,
    noConnectionToClientSoftwareText: `Une connexion ne peut être établie avec le logiciel client\nSi vous êtes sûr qu'il est installé, démarrez-le d'abord.`,
    noContactInfo: `Aucune information de contact`,
    noContactPerson: `Pas de personne de contact`,
    noDataAvailable: `Pas de données disponibles`,
    noDataInHams: `Pas encore de données dans HAMS`,
    noDataReadViaEIDYet: `Pas encore de données lues via l'eID`,
    noDataToCompare: `Pas de données pour comparer`,
    noDecimals: `Pas de point décimal`,
    noDefaultOfficeSelected: `Il n'y a pas encore de cabinet standard indiqué.`,
    noDeliveriesRegisteredYet: `Aucune livraison n'a encore été enregistrée`,
    noDemoRegular: `Pas de démo (régulier)`,
    noDevicesReadOutByNoahYet: `Aucun appareil lu par Noah pour le moment`,
    noDifference: `Aucune différence`,
    noDocumentHasBeenCreatedForFollowingSales: `Aucun document n'a été créé pour les ventes suivantes`,
    noDocumentHasBeenCreatedForFollowingSettlementProposals: `Aucun document n'a été créé pour les propositions de règlement suivantes`,
    noDocumentsAddedYet: `Aucun document n'a encore été ajouté`,
    noEarmouldsDefined: `Aucun embout trouvé, cliquez sur '+' pour en créer un nouveau`,
    noeIDCardInserted: `Pas d'eID trouvé`,
    noEmail: `Pas d'e-mail`,
    noEmailAddressesDefinedYet: `Aucune adresse électronique n'a encore été saisie`,
    noEndDate: `Pas de date de fin`,
    noEquipmentAssignedYet: `Aucun équipement n'a encore été attribué`,
    noExtraDataViewTransactionsOrRemarks: `Pas de données supplémentaires ici, consulter les transactions ou commentaires`,
    noFieldsDefined: `Aucun champ défini`,
    noFillInLater: `Non, remplissez-les plus tard`,
    noFollowUpProcesses: `Pas de processus à suivre`,
    noGeneralPractitionersDefined: `Aucun médecin généraliste défini, cliquez sur '+' pour en créer un nouveau`,
    noInventoryDefined: `Aucun produit défini`,
    noInventoryItemsFound: `Aucun élément d'inventaire n'a été trouvé`,
    noInventoryItemsFoundChangeSearch: `Aucun élément d'inventaire n'a été trouvé, modifier les paramètres de recherche`,
    noItDoesntFit: `Non, il ne convient pas`,
    noItemsFound: `Aucun article trouvé`,
    noItemsFoundForSupplier: (supplier: string | number) => `Aucun article trouvé pour le fournisseur ${supplier.toString().toLowerCase()}`,
    noItemsInStockContent: `Aucun article en stock, ajouter une commande, livraison, vente pour voir les articles dans cette liste`,
    noLabelsAssignedYet: `Aucun label attribué pour le moment`,
    noLinesDefinedYet: `Aucune ligne n'a encore été ajoutée`,
    noLinesSelectedYet: `Aucune ligne sélectionnée pour le moment`,
    noLinkedAccountsReceivableForCashSales: `Pas de comptes clients liés pour les ventes au comptant`,
    noLinkWithHealthInsuranceFund: `Mutualité pas liée`,
    noLoansRegisteredYet: `Aucun prêt n'a encore été enregistré`,
    noLocationsDefined: `Aucun emplacement trouvé, cliquez sur '+' pour en créer un nouveau`,
    noLogoPresent: `Il n'y a pas de logo`,
    noLongerActive: `Non (plus) actif`,
    nomenclature: `Nomenclature`,
    nomenclatureCode: `Code de nomenclature`,
    nomenclatureSingularOrPlural: `Nomenclature(s)`,
    noneActive: `Aucun actif`,
    noneOfAbove: `Aucune de ces réponses`,
    noNoahDataAvailable: `Pas de données de NOAH disponibles`,
    noNomenclaturesSelected: `Aucune nomenclature sélectionnée`,
    noNotifications: `Aucune notification`,
    noOffices: `Aucun cabinet`,
    noOfficesDefined: `Aucun cabinet n'a encore été créée`,
    noOnlyForThis: `Non, seulement pour cet article`,
    noOrdersRegisteredYet: `Aucune commande n'a encore été enregistrée`,
    noPatientFoundWithThisSsnrCreateNewQuestion: `Aucun patient trouvé avec ce numéro de registre national\nCréer nouveau patient ?`,
    noPatientsDefined: `Aucun patient trouvé, cliquez sur '+' pour en créer un nouveau.`,
    noPatientSelected: `Pas de patient sélectionné`,
    noPaymentsReceivedYet: `Aucun paiement n'a encore été reçu`,
    noPaymentsRegisteredYet: `Aucun paiement n'a encore été enregistré`,
    noPhoneNumber: `Aucun numéro de téléphone`,
    noPreviousSoldLines: `Aucune ligne vendue précédemment`,
    noProcessesDefinedForThisPatientYet: `Aucun processus n'a encore été créé pour ce patient`,
    noProductsDefinedClickPlusToCreateNew: `Aucun produit défini, cliquez sur '+' pour en créer un nouveau`,
    noPseudoNomenclatureSelected: `Pas de pseudo nomenclature sélectionée`,
    noPurchasesRegisteredYet: `Aucun achat n'a encore été enregistré`,
    noRateDefined: `Pas de taux défini`,
    noRepairsRegisteredYet: `Aucune réparation n'a encore été enregistrée`,
    noResults: `Aucun résultat`,
    noResultsCommaChangeFilterParams: `Aucun résultat, ajustez la recherche`,
    noRfiaDefinedText: `Aucune demande n'est enregistrée, sélectionnez un patient pour en créer une nouvelle`,
    noRIZIVNumberLinkAction: `Cliquez ici afin de lier`,
    noRIZIVNumberLinkedText: `Aucun numéro INAMI lié à cet utilisateur`,
    normal: `Normal`,
    normalOrder: `Commande régulière`,
    normalOrderCaption: `Sélectionnez les articles du catalogue de produits manuellement`,
    normScore: `Score normal`,
    normValue: `Valeur normale`,
    normValueForThisList: (list: string | number) => `Valeur normale pour la liste: ${list.toString().toLowerCase()}`,
    noSalesRegisteredYet: `Aucune vente enregistrée pour le moment`,
    noShow: `No-Show`,
    noSignatureIsNeededForThisDocument: `Ce document ne devrait jamais être signé`,
    noSignatureNeeded: `Aucune signature requise`,
    noSocialSecurityNumberDefined: `Pas de numéro de registre national saisi`,
    noSpeechTestRecordedCreateOneFirst: `Aucun test vocal n'a été effectué.\nCréez d'abord un test vocal via un module Noah.`,
    noSubProcess: `Pas de sous-processus`,
    noSuppliersDefined: `Aucun fournisseur trouvé, cliquez sur '+' pour en créer un nouveau`,
    noSupplierWithPurchasesRegisteredYet: `Aucun fournisseur (avec des ventes) n'est encore enregistré`,
    notACashDeskLocation: `N'utilise pas de caisse`,
    notActive: `Non actif`,
    notActived: `Non activé`,
    notAllFallbackGLAccountsAreDefined: `Tous les codes de repli ne sont pas définis`,
    notAllFieldsAreFilledInForThisPatient: `Tous les champs obligatoires ne sont pas remplis pour ce patient.\nVoulez-vous les remplir maintenant ?`,
    notAllNomenclaturesAreValidOnThisDate: `Toutes les nomenclatures sélectionnées ne sont pas valables à cette date !\nDans demande`,
    notAllRIZIVCodesAreSelectedDoYouWantToProceed: `Tous les codes INAMI n'ont pas été sélectionnés,\nêtes-vous sûr de vouloir continuer ?`,
    notAllUserVisibleHelperText: `Tous les utilisateurs ne sont pas visibles ? Seuls les audiologistes sont affichés.\nUn audiologiste est un utilisateur avec un numéro INAMI`,
    notApplicable: `Non applicable`,
    notASingleDeviceRegisteredInProductCatalog: `Aucun appareil enregistré dans le catalogue des produits`,
    notAStockLocation: `Emplacement sans stock`,
    notAuthorizedCommaMandatoryToRegisterAgain: `Non autorisé, obligation de se réenregistrer`,
    notAvailable: `Non disponible`,
    notAvailableForWhat: (what: string | number) => `Non disponible pour ${what.toString().toLowerCase()}`,
    notAvailableInCalendar: `Non disponible dans le calendrier`,
    notAvailableInCalendarCaption: `Il n'est pas possible de prendre des rendez-vous à cet emplacement.`,
    notCompleted: `Non achevé`,
    notConfirmed: `Non confirmé`,
    notConfirmedTooltip: `L'utilisateur n'a pas encore ouvert son/sa courrier ni créé de profil.`,
    notDeleted: `Non supprimé`,
    notDeliveredOrdersSingularOrPlural: `Commandes non liées`,
    notDeliveredYet: `Pas encore livré`,
    notDirectedToVatCustomer: `Non adressé à un client TVA`,
    note: `Reçu`,
    notEditable: `Non modifiable`,
    noTemplatesForThisTypeOrSubject: `Aucun modèle pour ce type/sujet`,
    noTendersRegisteredYet: `Aucune offre enregistrée pour le moment`,
    notFilledIn: `Pas rempli`,
    notForAPatient: `Pas pour un patient`,
    notForPatient: `Pas pour patient`,
    notFoundAtInventoryItems: `Pas trouvé dan les articles en stock`,
    notFoundInReadOutsFromNoah: `Pas trouvé dan les lectures de Noah`,
    notHandedOverNorFailed: `Ni remis, ni échoué`,
    nothingCredited: `Rien crédité`,
    nothingFoundCommaChangeSearchParams: `Rien trouvé, changez les paramètres de recherche`,
    nothingPaid: `Non payé`,
    notifications: `Avis`,
    notImported: `Non importé`,
    notImportedNotIgnored: `Pas importé et pas ignoré`,
    notInventoried: `Non inventorié`,
    notKnownYet: `Pas encore connu`,
    notLinked: `Non lié`,
    notLinkedInNoah: `Non lié à Noah`,
    notLinkedNotIgnored: `Pas lié, pas ignoré`,
    notPaidAndOverdue: `Non payé et en retard`,
    notPlannable: `Ne peut être planifié`,
    notPossibleToMoveARecurringAppointment: `Impossible de déplacer un rendez-vous récurrent`,
    noTransactionsForThisItem: `Aucune transaction pour cet article`,
    noTreatmentsStarted: `Aucun traitement n'a encore commencé`,
    notRefundable: `Non remboursable`,
    notRefundableCaption: `Prestation non remboursable`,
    noTrialStartedYet: `Aucun essai n'a encore commencé`,
    notSendToSupplierYet: `Pas encore envoyé au fournisseur`,
    notSet: `Non défini`,
    notSigned: `Non signé`,
    notSynced: `Non synchronisé`,
    notTangible: `Non tangible`,
    notTangibleCaption: `Un produit non tangible\nUn service, une assurance ou une garantie prolongée`,
    notUpToDate: `Pas à jour`,
    notViewableInApp: `Non consultable/modifiable dans l'app`,
    notWhat: (what: string | number) => `Non ${what.toString().toLowerCase()}`,
    notYetRepaired: `Pas encore réparé`,
    notYetSynchronized: `Pas encore synchronisé`,
    noTypesAnnotationDefined: `Aucun type d'annotation/commentaire n'a encore été défini`,
    noTypesAppointmentsDefined: `Aucun type de rendez-vous n'a encore été défini`,
    noVatCustomer: `Client sans TVA`,
    noVatNumber: `Aucun numéro de TVA trouvé`,
    noWhat: (what: string | number) => `Aucun(e) ${what.toString().toLowerCase()}`,
    noWhatDefined: (what: string | number) => `Aucun(e) ${what.toString().toLowerCase()} défini(e)`,
    noWhatFound: (what: string | number) => `Pas de ${what.toString().toLowerCase()} trouvé(e)(s)`,
    noWhatFoundClickPlusToCreateNew: (what: string | number) => `Aucun(e) ${what.toString().toLowerCase()} trouvé(e), cliquez sur '+' pour en créer un(e) nouveau(elle)`,
    noWhatRegistered: (what: string | number) => `Aucun(e) ${what.toString().toLowerCase()} enregistré(e)`,
    noWhatSelected: (what: string | number) => `Aucun(e) ${what.toString().toLowerCase()} sélectionné(e)`,
    nowNotWhat: (what: string | number) => `Pas maintenant ${what.toString().toLowerCase()}`,
    nowWhat: (what: string | number) => `Maintenant ${what.toString().toLowerCase()}`,
    number: `Numéro`,
    numbering: `Numérotage`,
    numberingWhat: (what: string | number) => `Numérotage ${what.toString().toLowerCase()}`,
    office: `Cabinet`,
    offices: `Cabinets`,
    ofPreviousStep: `De l'étape précédente`,
    ofThePatient: `Du patient`,
    ofWhichCountInAfterTreatment: (count: string | number) => `Dont ${count.toString().toLowerCase()} en après traitement`,
    oldestFirst: `Le plus ancien d'abord`,
    oldPassword: `Ancien mot de passe`,
    onConsignment: `Consignment`,
    oneDecimal: `1 chiffre après la virgule`,
    oneOrMoreArticlesHasNoGLAccountLinked: `Un ou plusieurs postes n'ont pas de code de compte général.`,
    oneOrMoreOffices: `Un ou plusieurs cabinets`,
    oneOrMoreRosaUsersOrLocationsUnlinked: `Un ou plusieurs utilisateurs/localisations non liés`,
    oneOrMultipleNextLinesPossibleValues: `Une ou plusieurs\nvaleurs possibles`,
    onePurchase: `Un achat`,
    oneSale: `Une vente`,
    onlyActive: `Uniquement actifs`,
    onlyAdminUserCanPerformThisAction: `Seuls les administrateurs peuvent effectuer cette action`,
    onlyAnArticleForWhatOrientation: (orientation: string | number) => `Article uniquement pour ${orientation.toString().toLowerCase()}`,
    onlyForThisArticle: `Uniquement pour cet article`,
    onlyForWhat: (what: string | number) => `Seulement pour ${what.toString().toLowerCase()}`,
    onlyHifPaid: `Seulement C.A. payé`,
    onlyInCaseOfNegativeEvaluation: `Seulement en cas d'essai négatif`,
    onlyInFuture: `Seulement dans le futur`,
    onlyInPast: `Seulement dans le passé`,
    onlyPatientPaid: `Seul le patient a payé`,
    onlyTangibleItemsWithoutSerialNumberCanBeSelected: `Seuls les produits tangibles sans numéro de série peuvent être sélectionnés`,
    onlyWIACompatibleScannersWillBeShown: `Seuls les scanners compatibles WIA sont indiqués`,
    onRemote: `À distance (remote)`,
    onThisPageItsPossibleToDefineDifferentAddresses: `Sur cette page, il est possible de saisir différentes adresses`,
    open: `Ouvert`,
    openConfigurationScreen: `Ouvrir l'écran de configuration`,
    optional: `Optionnel`,
    options: `Options`,
    order: `Commande`,
    orderArticles: `Commande d'articles`,
    orderCancelled: `Commande annulée`,
    orderDate: `Date de commande`,
    ordered: `Commandé`,
    orderForASinglePatientContent: `La commande concerne exactement un patient`,
    orderForm: `Bon de commande`,
    orderFormToWho: (who: string | number) => `Bon de commande pour ${who}`,
    orderForNoneOneOrMultiplePatientsContent: `Commande groupée pour aucun, un ou plusieurs patients`,
    orderFromNeedsCaption: `Faire une commande à partir des besoins`,
    orderIsForASupplierWhoIsNotRegisteredYet: `La commande concerne un fournisseur qui n'est pas encore enregistré`,
    orderNumber: `Numéro de commande`,
    orderOrSelectFromStock: `Commandez ou sélectionnez en stock`,
    orderOrSelectNeeds: `Commander ou sélectionner des besoins`,
    orderPlaced: `Commande passée`,
    orders: `Commandes`,
    orderSlashAllocate: `Commande/assignation`,
    orientation: `Orientation`,
    orientationTest: `Test d'orientation`,
    origin: `Origine/provenance`,
    original: `Original`,
    originalIdentificationCode: `Code d'identification original`,
    other: `Autres`,
    otherActiveTreatmentForPatientDoYouWantToContinueQuestion: (whatX: string | number) => `Détecté d'autres traitements actifs pour '${whatX}'\nÊtes-vous sûr de commencer un nouveau traitement?`,
    otherArticles: `Autres articles`,
    otherCalendars: `Autres calendriers`,
    otherData: `Autres données`,
    otherDocuments: `Autres documents`,
    otherExplanation: `Avec/sans numéro de série (p.ex. chargeur…)`,
    otherItemFromProductCatalog: `Autre article du catalogue des produits`,
    otherNewArticleCaption: `Avec ou sans numéro de serie\nSur mesure ou non`,
    otherPatientWithThisSsNumberAlreadyExists: `Il y a déjà un patient avec ce numéro de registre national`,
    otherRunningRfiaProcessesForPatientDoYouWantToContinueQuestion: (whatX: string | number) => `Il y a d'autres demandes d'allocation de l'assurance pour '${whatX}'.\nÊtes-vous sûr de vouloir démarrer un nouveaux?`,
    otherSettings: `Autres paramètres`,
    otherTailorMade: `Autres (sur mesure)`,
    otherTreatments: `Autres traitements`,
    otherWithoutSerialNumber: `Autres (sans numéro de série)`,
    otherWithSerialNumber: `Autres (avec numéro de série)`,
    outstanding: `En souffrance`,
    outstandingBalance: `Solde en souffrance`,
    outstandingBalanceNotOnSps: `Solde en souffrance ne figure pas sur prop. de règl.`,
    outstandingTenders: `Offres en souffrance`,
    overdue: `En retard`,
    overDue: `En retard`,
    overview: `Aperçu`,
    overviewDeliveredToDeliver: `Aperçu livré/à livrer`,
    overwrite: `Écraser`,
    overwriteColumnsOfWhat: (what: string | number) => `Écraser colonnes de ${what.toString().toLowerCase()}`,
    ownProperty: `Propriété`,
    padNumbers: `Nombre de numéros d'index`,
    padNumbersExplanation: `P.ex. pour obtenir 0001, sélectionnez 4. Pour obtenir 001, sélectionnez 3`,
    page: `Page`,
    pageColonIndexOfCount: (index: string | number, count: string | number) => `Page: ${index.toString().toLowerCase()} de ${count.toString().toLowerCase()}`,
    paid: `Payé`,
    paidBeforeApprovals: `Payé avant approbations`,
    paidOut: `Payé`,
    paidOutDate: `Date du paiement`,
    paperSize: `Format du papier`,
    partialAssigned: `Partiellement attribué`,
    partialDelivered: `Partiellement livré`,
    partialDelivery: `Livraison partielle`,
    partlyDelivered: `Partiellement livré`,
    partlyPaid: `Partiellement payé`,
    partOfRfiaClickToNavigate: `Fait partie de la demande d'allocation d'assurance, cliquez pour y accéder`,
    partOfTmpClickToNavigate: `Fait partie d'un produit sur mesure, cliquez pour naviguer`,
    partPatient: `Part du patient`,
    passportPhotograph: `Photo d'identité`,
    password: `Mot de passe`,
    passwordChanged: `Mot de passe changé`,
    passwordComplexityRule: `Mot de passe doit comporter au moins 6 caractères, dont une lettre majuscule [A-Z], une lettre minuscule [a-z], un chiffre [0-9] et un symbole complexe [!@#$%]`,
    passwordDoesNotMatch: `Mots de passe ne correspondent pas`,
    passwordIncorrect: `Mot de passe incorrect`,
    patient: `Patient`,
    patientActiveCaption: `Patient est un patient actif`,
    patientAlsoExistsInNoah: `Patient existe aussi dans Noah`,
    patientCannotChangeIfeIDRead: `Données de la carte d'identité électronique ayant été lues, il n'est pas possible de modifier nom/sexe/date de naissance`,
    patientCreated: `Patient créé`,
    patientData: `Données du patient`,
    patientDeceasedCaption: `Patient est décédé`,
    patientDetailOverviewLayoutPreferencesDescription: `En dessous, faites glisser les éléments vers la colonne 'gauche' ou 'droite' pour les rendre visibles dans l'écran de résumé du patient.`,
    patientInactiveCaption: `Patient n'est plus considéré comme « actif »`,
    patientPermanentlyDeleted: `Patient supprimé définitivement`,
    patients: `Patients`,
    patientUpdated: `Patient mis à jour`,
    payBefore: `Payer avant`,
    payedByHifShort: `Payé par mut.`,
    payedByPatient: `Payé par patient`,
    payment: `Paiement`,
    paymentDate: `Date de paiement`,
    paymentDetails: `Modalités de paiement`,
    paymentMethod: `Mode de paiement`,
    paymentMethods: `Modes de paiement`,
    paymentMultiplePurchasesCaption: `Le paiement concerne plusieurs achats\nPaiement partiel n'est pas possible`,
    paymentMultipleSalesCaption: `Le paiement concerne plusieurs ventes\nPaiement partiel n'est pas possible`,
    paymentOnePurchaseCaption: `Le paiement est lié à un seul achat\nPeut-être même un paiement partiel`,
    paymentOneSaleCaption: `Le paiement est lié à une seul vente\nPeut-être même un paiement partiel`,
    paymentReference: `Référence de paiement`,
    payments: `Paiements`,
    paymentsNotRegisteredInHAMS: `Paiement(s) non enregistré(s) dans le HAMS`,
    paymentSpsCaption: `Le paiement est lié à une proposition de règlement faite précédemment`,
    paymentStatus: `Statut de paiement`,
    payout: `Paiement`,
    payouts: `Paiements`,
    payVerb: `Payer`,
    pending: `En prévision`,
    percentage: `Pourcentage`,
    personalData: `Données personnelles`,
    personalInformationData: `Renseignements personnels`,
    personalisedVersion: `Version personnalisée`,
    personalShare: `Part personnelle`,
    phone: `Numéro de téléphone`,
    phoneNumberShort: `N° de tél.`,
    physicalQuantity: `Nombre en stock (physique)`,
    place: `Placer`,
    placed: `Posté chez`,
    placeDigitalSignature: `Placez signature numérique`,
    placedWithSupplier: `Posté chez le fournisseur`,
    placeOrder: `Passer commande`,
    placeOrderImmediatelyWithSupplier: `Passer immédiatement la commande au fournisseur`,
    placeOrderImmediatelyWithSupplierContent: `La commande est complète et peut être transmise au fournisseur.`,
    placeOrderWithSupplier: `Passer la commande au fournisseur`,
    placeOrderWithSupplierLater: `Passer la commande au fournisseur plus tard`,
    placeOrderWithSupplierLaterContent: `Ajouter des lignes à cette commande à une date ultérieure`,
    placeWithSupplier: `Passer au fournisseur`,
    planAppointment: `Fixer rendez-vous`,
    plannable: `Planifiable`,
    plannableLocations: `Emplacements planifiables`,
    plannableUsers: `Utilisateurs planifiables`,
    plannedAppointmentIsAfterExpectedRedeliveryDate: `Le rendez-vous est fixé APRÈS la date de livraison prévue !`,
    pleaseAddOffice: `Veuillez d'abord ajouter un cabinet pour ce médecin.`,
    point: `Point`,
    points: `Points`,
    portal: `Portail`,
    positive: `Positif`,
    predefined: `Prédéfini`,
    predefinedType: `Type prédéfini`,
    preferences: `Préférences`,
    preferredLocation: `Emplacement de prédilection`,
    preferredLocationFor: `Emplacement de prédilection pour`,
    prefix: `Préfixe`,
    prescription: `Prescription`,
    prescriptionAttachment17: `Prescription (annexe 17)`,
    prescriptionDate: `Date de prescription`,
    prescriptionForTrial: `Prescription pour tests`,
    prescriptionForTrialDate: `Date de prescription pour tests`,
    prescriptionForTrialReceived: `Prescription pour tests reçue`,
    prescriptionReceived: `Prescription reçue`,
    prescriptionStep1Caption: `Prescription pour les tests des équipements pour la correction de l'audition\nRempli par médecin ORL`,
    prescriptionStep2Caption: `Demande par l'audiologiste\nRemplie par l'audiologiste`,
    prescriptionStep3Caption: `Prescription de l'équipement\nRempli par médecin ORL`,
    prescriptionStep4Caption: `Approbation du médecin-conseil\nRempli par la mutualité`,
    pressEnterToSearch: `Appuyez sur la touche Entrée pour effectuer une recherche`,
    previouslyPurchasedItem: `Article déjà acheté`,
    previousSale: `Vente précédente`,
    previousTrial: `Essai précédent`,
    price: `Prix`,
    priceExVat: `Prix (hors TVA)`,
    priceForPatient: `Prix pour patient`,
    priceIncludesAllContributionsRecupelAndBebat: `Le prix comprend toutes les contributions (Recupel et Bebat)`,
    priceInVat: `Prix (TVA incl.)`,
    priceMustBeLowerThanListPrice: `Montant doit être inférieur au prix recommandé`,
    prices: `Prix`,
    pricing: `Fixation des prix`,
    primaryContactInfosAreUsedForCommunicatingWithThePatient: `Les informations de contact primaires sont utilisées pour communiquer avec le patient (par exemple, SMS ou e-mail).`,
    print: `Imprimer`,
    printTicket: `Imprimer le billet`,
    priority: `Priorité`,
    privateLabel: `Marque de distributeur`,
    privateLabelData: `Données sur marque distribiteur`,
    proceed: `Continuer`,
    procentSpeechImprovementAccordingToBIAPMethod: `% d'intelligibilité de la parole selon la méthode BIAP`,
    procentSpeechImprovementAccordingToICAMethod: `% d'intelligibilité de la parole selon la méthode I.C.A.`,
    procentSpeechImprovementAtSrtPoint: `% d'intelligibilité de la parole au point SRT`,
    process: `Processus`,
    processAdvanceInvoice: `Traiter facture d'acompte`,
    processes: `Processus`,
    product: `Produit`,
    productCatalog: `Catalogue des produits`,
    productCatalogItem: `Produit du catalogue des produits`,
    productCreated: `Produit créé`,
    productInfo: `Information du produit`,
    productName: `Nom du produit`,
    productUpdated: `Produit mis à jour`,
    productWithoutSerialNumber: `Produit sans numéro de série`,
    productWithSerialNumber: `Produit avec numéro de série`,
    productWithThisIntRefExists: `Il existe déjà un produit avec cette référence.`,
    productWithThisManRefExists: `Il existe déjà un produit avec cette référence de fabrication.`,
    productWithThisNameExists: `Il existe déjà un produit portant ce nom.`,
    profile: `Profil`,
    profit: `Bénéfice`,
    proforma: `Pro forma`,
    proformaAttachment12: `Pro forma annexe 12`,
    prolongedWarranty: `Garantie prolongée`,
    properties: `Propriétés`,
    propertiesDoNotMatch: `Les propriétés ne correspondent pas`,
    propertiesOfHearingAid: `Propriétés des appareil auditif`,
    propertiesOfTemplateNotRecursiveOnDocuments: `Les propriétés des documents précédemment créés ne sont PAS ajustées.\nL'ajustement ne concerne que les documents créés ultérieurement`,
    propertiesOfWhat: (what: string | number) => `Propriétés de ${what.toString().toLowerCase()}`,
    propertiesSingleOrPlural: `Propriété(-s)`,
    property: `Propriété`,
    propertyIsNotEqual: (property: string | number) => `'${property.toString().toLowerCase()}'' ne correspond à rien`,
    propertyOf: `Propriété de`,
    providePassword: `Entrer mot de passe`,
    pseudoNomenclatureCode: `Pseudo code de nomenclature`,
    purchase: `Achat`,
    purchaseCreditDate: `Date achat crédité`,
    purchaseCredited: `Achat crédité`,
    purchaseDate: `Date d'achat`,
    purchaseListPrice: `Prix d'achat recommandé`,
    purchaseListPriceShort: `Prix d'achat recom.`,
    purchaseOrderCustomer: `Bon de commande du client`,
    purchaseOrderSupplier: `Bon de commande du fournisseur`,
    purchasePrice: `Prix d'achat`,
    purchasePrices: `Prix d'achats`,
    purchaseReference: `Referende d'achat`,
    purchases: `Achats`,
    purchaseStatus: `Statut d'achat`,
    purchaseVerb: `Acheter`,
    pushDataToRosa: `PUSH données à ROSA`,
    pushPatientDataToRosa: `PUSH les données des patients vers ROSA`,
    pushSlashSyncWithExactOnline: `Push/sync avec Exact Online`,
    qtyMoreThanAvailableAtLocation: (available: string | number, location: string | number) => `Nombre dépasse le nombre disponible (${available.toString().toLowerCase()}) à l'emplacement ${location.toString().toLowerCase()}, (stock devient négatif à cet emplacement)`,
    qualificationCodes: `Code(s) de qualification`,
    quantity: `Nombre`,
    quantityInStock: `Nombre en stock`,
    rate: `Tarif`,
    reactivatePaymentMethod: `Réactiver mode de paiement ?`,
    reactivatePaymentMethodContent: `Ce mode de paiement sera à nouveau disponible`,
    reactivatePaymentMethodForWhatContent: (what: string | number) => `Ce mode de paiement sera à nouveau disponible pour ${what.toString().toLowerCase()}`,
    reactivatePaymentMethodForWhatQuestion: (what: string | number) => `Réactiver le mode de paiement pour ${what.toString().toLowerCase()} ?`,
    read: `Lire`,
    readData: `Lire les données`,
    readDataFromeID: `Lire les données de l'eID`,
    readeID: `Lire eID`,
    readEIDData: `Lire les données eID`,
    readOn: `Lu le`,
    readOutAs: `Exquis comme`,
    readOutFromUbl: `Extrait de l'UBL`,
    reason: `Raison`,
    reasons: `Raisons`,
    reasonsAppointment: `Motifs`,
    rebuildFromTemplate: `Reconstruit (à partir du modèle)`,
    recalculating: `Recalculer`,
    receive: `Recevoir`,
    receiver: `Receiver`,
    receiverNewArticleCaption: `Haut-parleur, receiver (pour RITE AA)`,
    reception: `Réception`,
    receptionDate: `Date de réception`,
    receptionDateOfDocuments: `Date de réception des documents`,
    receptionFeedbackAdvisoryPhysician: `Réception du feed-back du médecin-cons.`,
    receptionTermOfDocuments: `Délai de réception des documents`,
    rechargeable: `Rechargeable`,
    rechargeableBatteries: `Batteries rechargeables`,
    recipient: `Destinataire`,
    recommended: `Recommandé`,
    recommendedSalesprice: `Prix de vente recommandé`,
    recupelRate: `Taux Recupel`,
    recurringAppointment: `Rendez-vous récurrent`,
    redelivered: `Livré`,
    redeliverVerb: `Livrer`,
    redelivery: `Livraison`,
    redeliveryDate: `Date de livraison`,
    reference: `Référence`,
    referenceDate: `Date de référence`,
    referenceFromSupplier: `Référence du fournisseur`,
    referenceIsNotANumber: `La référence n'est pas un numéro`,
    referenceMustNotContainAYear: `La référence ne doit pas contenir d'année`,
    referencePriceShort: `Prix de réf.`,
    references: `Références`,
    referenceShort: `Réf.`,
    refund: `Remboursement`,
    refundable: `Remboursable`,
    refundableCaption: `Prestation est remboursable`,
    refundAmount: `Montant à rembourser`,
    refunds: `Remboursements`,
    refundsToProcess: `Remboursements à traiter`,
    regenerateDocumentFromTemplate: `Régénérer document à partir du modèle`,
    regenerateDocumentFromTemplateQuestion: `Régénérer document à partir du modèle ?`,
    regenerateTheDocument: `Recréer le document`,
    register: `Enregistrer`,
    registerAgain: `Nouvelle inscription`,
    registerANewOrder: `Enregistrer une nouvelle commande`,
    registerANewRepair: `Enregistrer une nouvelle réparation`,
    registerANewRepairCaption: `N'ajouter pas à réparation existante, créer-en une nouvelle`,
    registerArticles: `Enregistrer les articles`,
    registerAsBroken: `Enregistrer comme défectueux`,
    registerAsSold: `Enregistrer une vente historique`,
    registerDelivery: `Enregistrer livraison`,
    registered: `Enregistré`,
    registeredAsBroken: `Enregistré comme défectueux`,
    registeredAsPurchasedScript: `Achat (script)`,
    registeredAsSold: `Enregistré comme vendu`,
    registeredWhat: (what: string | number) => `${what} enregistré(e)(s)`,
    RegisterForWebinar: `s'inscrire au webinaire`,
    registerGroupPaymentForSupplier: (supplier: string | number) => `Enregistrer paiement de groupe pour fournisseur : ${supplier.toString().toLowerCase()}`,
    registerHandover: `Enregistrer fourniture`,
    registerHandoverEquipment: `Enregistrer fourniture d'équipement`,
    registerInventoryItemAsSold: `Enregistrer les articles en stock comme vendus`,
    registerLetterDispatch: `Enregistrer envoi des documents`,
    registerLumpSumContribution: `Enregistrer allocation forfaitaire`,
    registerMultipleSales: `Enregistrer deux ventes`,
    registerMultipleSalesCaption: `Une vente est enregistrée pour le patient.\nLe remboursement est facturé à la mutualité par le biais d'une vente séparée.\nIl est également possible d'établir un état récapitulatif en utilisant cette méthode.`,
    registerNewPurchase: `Enregistrer nouvel achat`,
    registerNewSale: `Enregistrer nouvelle vente`,
    registerNewUser: `Enregistrer un nouvel utilisateur`,
    registerOneSale: `Enregistrer une vente`,
    registerOneSaleCaption: `Une vente est enregistrée.\nLe remboursement est facturé à la mutualité sur la base de cette vente.`,
    registerPayment: `Enregistrer paiement`,
    registerPaymentsInHAMS: `Enregistrer les paiements dans HAMS`,
    registerReceptionMedicalPrescription: `Enregistrer réception de la prescription médicale`,
    registerRefund: `Enregistré remboursement`,
    registerThisComputer: `Enregistrer cet ordinateur`,
    registerWhat: (what: string | number) => `Enregistrer ${what.toString().toLowerCase()}`,
    registration: `Enregistrement`,
    registrationReceptionPrescription: `Enregistrement de réception de la prescription`,
    reimbursementProcedure: `Procédure de remboursement`,
    rejectFittingContent: `Les produits sont retournés au fournisseur, ce flux est terminé.`,
    releaseNote: `Note de version`,
    remark: `Commentaire`,
    remarks: `Commentaires`,
    remote: `À distance`,
    remoteDevice: `Appareil à distance`,
    removedFromATreatment: `Retiré d'un traitement`,
    removeEidData: `Supprimer les données de l'eID`,
    removeLink: `Supprimer le lien`,
    removeLinkBetweenRosaAndHamsPatient: `Supprimer le lien entre ROSA et le patient HAMS`,
    removeLinkFromWithRosaPatientQuestion: `Voulez-vous supprimer le lien entre ROSA et le patient HAMS ?`,
    removeLinkWithPatient: `Supprimer lien avec patient`,
    removeRepairLinkWithPatientContent: `Cette réparation n'est plus liée à un patient.\nVous voulez continuer ?`,
    removeThis: `Enlevez-le`,
    renewal: `Renouvellement`,
    renewalDate: `Date de renouvellement`,
    renewRegistrationToken: `Renouveler jeton d'enregistrement`,
    repair: `Réparation`,
    repairCancelContent: `En annulant une réparation, il n'est pas possible de réaliser d'autres étapes`,
    repaired: `Réparée`,
    repairFormToWho: (who: string | number) => `Bon de réparation pour ${who}`,
    repairIsSpecificForAPatient: `Réparation est spécifique à un patient`,
    repairs: `Réparations`,
    repairSentToSupplier: `Réparation envoyée au fournisseur`,
    repairUndoDelete: `Réparation (annuler la suppression)`,
    repairVerb: `Réparation`,
    repairWhatQuestion: (what: string | number) => `Réparer ${what.toString().toLowerCase()} ?`,
    repeat: `Répétition`,
    replacedBy: `Remplacé par`,
    replacementDevices: `Appareils de remplacement`,
    replacementDevicesSelected: `Appareils de remplacement sélectionnés`,
    replaceWhat: (what: string | number) => `Remplacer ${what.toString().toLowerCase()}`,
    reports: `Rapports`,
    request: `Demande`,
    requestConfiguration: `Demander configuration`,
    requested: `Demandé`,
    requestForInsuranceAllowance: `Demande d'allocation de l'assurance`,
    requestForInsuranceAllowances: `Demandes d'allocation de l'assurance`,
    requestForInsuranceAllowanceShort: `Demande d'AA`,
    requestForInsuranceAllowancesShortened: `Demandes d'AA`,
    requestNew: `Demander nouveau`,
    requiredFieldsToFillInForAPatient: `Champs obligatoires pour le patient`,
    requiredPatientFieldsDescription: `Les champs indiqués ci-dessous sont obligatoires à remplir pour chaque patient.\nHAMS affiche un écran contextuel si un ou plusieurs de ces champs ne sont pas remplis`,
    requiredProperties: `Champs obligatoires`,
    reReadDataFromEIDCard: `Relire les données de la carte ID`,
    resendRegistrationTokenQuestion: `Renvoyer jeton d'enregistrement ?`,
    reservation: `Réservation`,
    reservationCleared: `Réservation annulée`,
    reserved: `Réservé`,
    reservedArticles: `Articles réservés`,
    reservedForAPatient: `Réservé à un patient`,
    reservedForWhat: (whatX: string | number) => `Réservé pour ${whatX}`,
    reservedShort: `Rés.`,
    reservedToAnotherPatient: `Réservé à un autre patient`,
    reset: `Réinitialiser`,
    resetPassword: `Réinitialiser mot de passe`,
    resetPasswordMailSent: `Courrier envoyé avec des instructions pour réinitialiser le mot de passe, vérifiez boîte aux lettres`,
    restart: `Redémarrer`,
    restartAfterTreatment: `Redémarrer l'après traitement`,
    restartAfterTreatmentContentQuestion: `Êtes-vous sûr de recommencer l'après traitement ?`,
    restoreVerb: `Restaurer`,
    results: `Résultats`,
    resultsHearingImprovementIn: `Résultats gain auditif en`,
    retailPrice: `Prix de détail`,
    retry: `Réessayer`,
    return: `Retour`,
    returnDate: `Date de retour`,
    returnedToSupplier: `Retourné au fournisseur`,
    returns: `Retours`,
    returnSlip: `Bon de retour`,
    returnToSupplier: `Retour au fournisseur`,
    returnToSupplierDate: `Date retour au fournisseur`,
    revenueExVatPerCategory: `Revenu (TVA excl.) par catégorie`,
    revenueExVatPerLocation: `Revenu (TVA excl.) par emplacement`,
    revenueExVatPerMonth: `Revenu (TVA excl.) par mois`,
    revenueExVatPerWeek: `Revenu (TVA excl.) par semaine`,
    revenues: `Recettes`,
    revenueThisYear: `Revenus de cette année`,
    revertIgnoreSale: `Annuler ignorer la vente`,
    right: `Droite`,
    rightColumn: `Colonne de droite`,
    rightEar: `Oreille droite`,
    rightForRenewal: `Droit au renouvellement`,
    rightForRenewalTermInfoContent: `Droit à renouvellement après « x » ans \n- >18 ans à la fourniture = 5 ans\n- <18 ans à la livraison = 3 ans`,
    RIZIV: `INAMI`,
    RIZIVCode: `Code INAMI`,
    RIZIVCoding: `Codage INAMI`,
    RIZIVNumber: `Numéro INAMI`,
    RIZIVRefundNomenclatureCode: (code: string | number) => `Nomenclature de remboursement INAMI ${code.toString().toLowerCase()}`,
    role: `Rôle`,
    room: `Chambre`,
    rooms: `Chambres`,
    roomsAreOnlyUsedInCalendar: `Chambres ne sont utilisées que dans le calendrier.`,
    rosaHAMSLinkActive: `ROSA - Lien HAMS actif`,
    rosaHAMSLinkNotActive: `ROSA - Lien HAMS non actif`,
    rosaIntegration: `Intégration de ROSA`,
    rosaLinkedPatientRecord: `Patient lié à ROSA`,
    rosaLinkUsersAndSitesPageTitle: `ROSA liens utilisateurs et emplacements`,
    rosaNotActivatedNotification: `ROSA n'est pas activé pour cet environnement, contactez support@hams.be pour activer cette intégration.`,
    rosaNotConfiguredYet: `ROSA n'a pas encore été configuré`,
    rosaPatients: `Les patients de ROSA`,
    rosaRecordNotFound: `Le patient de ROSA n'a pas été trouvé`,
    rosaRecordNotFoundErrorDescription: `Le lien demandé n'a pas été trouvé dans le système HAMS.
Veuillez réessayer plus tard.`,
    rosaSettings: `Paramètres ROSA`,
    rule: `Ligne`,
    ruleForWhatDelete: (what: string | number) => `Supprimer règle pour ${what.toString().toLowerCase()}`,
    ruleForWhatRepair: (what: string | number) => `Réparer règle pour ${what.toString().toLowerCase()}`,
    rules: `Règles`,
    rulesForReadOuts: `Règles pour lecture`,
    rulesForReadOutsSuggestionCaption: `Si un appareil est lu par NOAH en tant que nom ci-dessous, cet article du catalogue de produits sera affiché`,
    runningLoanSingularOrPlural: `Prêt(s) actuel(s)`,
    runningProcesses: `Processus en cours d'exécution`,
    runningRepairSingularOrPlural: `Réparation(s) actuelle(s)`,
    runningRequestSingularOrPlural: `Demande(s) actuelle(s)`,
    saldoBetween: `Solde entre`,
    saldoSettlementProposalsShort: `Solde prop. de règl.`,
    sale: `Vente`,
    saleHasMultipleCustomerAccounts: `La vente a plusieurs comptes clients`,
    saleHasNoCustomerAccounts: `La vante n'a pas de compte client`,
    saleIsACashSale: `La vente est une vente au comptant`,
    sales: `Ventes`,
    salesCredited: `Vente créditée`,
    salesData: `Données sur les ventes`,
    salesDate: `Date de vente`,
    salesInvoicesForwarding: `Transmission des factures de vente`,
    salesListPrice: `Prix de vente conseillé`,
    salesListPriceShort: `Prix de vente rec.`,
    salesPrice: `Prix de vente`,
    salesReference: `Référence de vente`,
    salesStatus: `Statut des ventes`,
    salesType: `Type de vente`,
    salutation: `Appel`,
    sameArticleForBothLeftAndRight: `Même article à gauche et à droite`,
    sameLabelForEveryLanguage: `Même étiquette pour chaque langue`,
    save: `Sauvegarder`,
    saveAsNew: `Enregistrer comme nouveau`,
    saveChanges: `Sauvegarder les changements`,
    saveChangesToDocumentQuestion: `Voulez-vous enregistrer les modifications actuelles apportées à ce document ?`,
    saveChangesToTemplateQuestion: `Voulez-vous enregistrer les modifications actuelles apportées à ce modèle ?`,
    savedColumnSelection: `Sélection de colonne sauvegardée`,
    savedSelection: `Sélection sauvegardée`,
    saveThisSelection: `Sauvegarder cette sélection`,
    scan: `Scan`,
    scanned: `Scanné`,
    scannedDocumentIsWithoutSignature: `Le document scanné n'a PAS de signature`,
    scannedDocumentIsWithSignature: `Le document scanné a BIEN une signature`,
    scannedOrUploaded: `Scanné ou téléchargé`,
    scanner: `Scanner`,
    scanOrUploadDocument: `Scanner ou télécharger document`,
    scanSignatureLater: `Scanner la signature plus tard`,
    scanSoftware: `Scanner logiciel`,
    schedule: `Horaire`,
    scope: `Portée`,
    score: `Score`,
    scoreWith: `Score avec`,
    scoreWithEquipment: `Score avec équipement`,
    scoreWithEquipmentAtSNROfSRT: `Score avec équipement sur RSB du SRT`,
    scoreWithout: `Score sans`,
    scoreWithoutEquipment: `Score sans équipement`,
    search: `Rechercher`,
    searchAnAddress: `Rechercher une adresse`,
    searchAndSelectSerialNumberFor: `Rechercher et sélectionner un numéro de série pour`,
    searchAppointmentsAndEvents: `Rechercher réunions et événements`,
    searchASerialNumberFor: `Rechercher numéro de série pour`,
    searchASerialNumberFromNoahReadOuts: `Rechercher numéro de série des appareils lus dans Noah`,
    searchFromProductCatalog: `Rechercher dans catalogue des produits`,
    searchInProductCatalogOrCreateNewItem: `Rechercher dans catalogue des produits ou créer nouvel article`,
    searchOnName: `Rechercher par nom`,
    searchOnNameOrExactOnlineCode: `Recherche par nom ou par code de Exact Online`,
    searchOnNameSlashCode: `Recherche par nom/code`,
    searchOnReference: `Recherchez sur reference`,
    searchOnRIZIVNumber: `Rechercher par numéro INAMI`,
    searchOnSerialNumber: `Rechercher par numéro de série`,
    searchOnWeb: `Rechercher sur le web`,
    searchOnWhat: (what: string | number) => `Rechercher par ${what.toString().toLowerCase()}`,
    searchOtherArticlesBelow: `Rechercher d'autres articles ci-dessous`,
    searchPatients: `Rechercher par nom, adresse, date de naissance, # tél. ...`,
    searchSaleForCommission: `Trouver vente pour commission`,
    searchSerialNumbersInInventoryItemsAndNoahReadouts: `Rechercher par numéro de série dans les articles en stock et dans les AA lu par Noah`,
    searchTimeSlot: `Trouver créneau horaire`,
    searchUsers: `Rechercher par nom, e-mail ...`,
    security: `Sécurité`,
    securityToken: `Jeton de sécurité`,
    securityTokenIsExpired: `Jeton de sécurité a expiré`,
    seen: `Vu`,
    selectADeviceFromRIZIVLimitativeList: `Sélectionner un appareil dans la liste exhaustive INAMI`,
    selectAModule: `Sélectionner un module`,
    selectAnArticleFromProductCatalog: `Sélectionnez un article dans le catalogue de produits`,
    selectAnExistingOrderOrRegisterANew: `Sélectionner une commande existante ou en créer une nouvelle`,
    selectAnExistingSerialNumber: `Sélectionner numéro de série existant`,
    selectAPatient: `Sélectionner un patient`,
    selectAPreviouslyPurchasedItem: `Sélectionnez un article déjà acheté`,
    selectASupplierForAnOrder: `Sélectionner un fournisseur pour la commande`,
    selectATestWithAndWithoutHearingAids: `Sélectionner un test avec appareils auditifs et un test sans appareils auditifs`,
    selectATestWithSpeechAudiograms: `Sélectionner un test avec audiogrammes vocaux`,
    selectAtLeastOneWhat: (what: string | number) => `Sélectionnez au moins 1 ${what.toString().toLowerCase()}`,
    selectCodeFor: (what: string | number) => `Sélectionner code pour : ${what.toString().toLowerCase()}`,
    selectCodesFirst: `Sélectionnez d'abord les codes`,
    selectDefaultDeliveryTimeFromSupplier: `Sélectionner délai de livraison normal du fournisseur`,
    selectDeliveryLocationHelperText: `Sélectionner l'emplacement où la livraison sera reçu`,
    selectedArticle: `Article sélectionné`,
    selectedInATreatment: `Sélectionné dans un traitement`,
    selectedProduct: `Produit sélectionné`,
    selectedTreatment: `Traitement sélectionné`,
    selectedWhat: (what: string | number) => `${what} sélectionné(e)(s)`,
    selectExistingInventoryItemFromStock: `Sélectionnez un article existant en stock`,
    selectExportableFieldsBelow: `Sélectionnez les champs à exporter ci-dessous`,
    selectFromStock: `Sélectionner en stock`,
    selectFromWhat: (what: string | number) => `Sélectionner à partir de ${what.toString().toLowerCase()}`,
    selectHealthInsuranceFund: `Sélectionner une mutualité`,
    selection: `Sélection`,
    selectItemsToAssure: `Sélectionner articles à assurer`,
    selectItemWithSerialNumberClickHereToLink: `Sélectionner un article avec numéro de série, cliquez ici pour lier`,
    selectLineFromPreviousSale: `Sélectionnez une ligne d'une vente précédente`,
    selectLineFromThisPurchase: `Sélectionnez une ligne de cet achat`,
    selectLineFromThisSale: `Sélectionnez une ligne de cette vente`,
    selectLocationAtWhereDeliveryWillBeReceived: `Sélectionner l'emplacement où la livraison sera reçue`,
    selectLocationAtWhereYouAreCurrentlyWorking: `Sélectionner l'emplacement où vous travaillez actuellement`,
    selectNeeds: `Sélectionnez les besoins`,
    selectNhaxOrEnhaxFile: `Sélectionner fichier nhax ou enhax`,
    selectNhaxOrEnhaxFileCaption: `Sélectionner fichier .nhax ou .enhax\nSi le patient existe déjà, les sessions et les actions sont importées\nSi le patient n'existe pas encore, il est créé`,
    selectNomenclatureCodeFirst: `Sélectionnez d'abord un code de nomenclature`,
    selectNomenclatureCodeFirstYouCanDeleteItAfterwards: `Sélectionnez d'abord un code de nomenclature\nVous pouvez le supprimer par la suite`,
    selectOneOrMultipleSuppliers: `Sélectionner un ou plusieurs fournisseurs`,
    selectOnlyNecessaryFields: `Ne sélectionnez que les champs nécessaires`,
    selectOnlyWho: (who: string | number) => `Sélectionnez uniquement ${who}`,
    selectOtherProductQuestion: `Sélectionnez un autre produit`,
    selectPseudoNomenclatureCodeFirst: `Sélectionnez d'abord un pseudo code de nomenclature`,
    selectSerialNumbersForSelectedProducts: `Sélectionnez numéros de série des produits sélectionnés`,
    selectStep: (what: string | number) => `Sélectionner ${what.toString().toLowerCase()}`,
    selectSuppliers: `Sélectionner fournisseurs`,
    selectThis: `Sélectionnez-le`,
    selectThisArticle: `Sélectionner cet article`,
    selectVerb: `Sélectionner`,
    selectWhat: (what: string | number) => `Sélectionner ${what.toString().toLowerCase()}`,
    selectWhatAndCreate: (what: string | number) => `Sélectionner ${what.toString().toLowerCase()} et créer`,
    send: `Envoyer`,
    sendRepairToSupplier: `Envoyer réparation au fournisseur`,
    sendToSupplier: `Envoyer au fournisseur`,
    sentToAdvisoryPhysicianShort: `Envoyé à méd.-cons.`,
    sentToEntPhysicianShort: `Envoyé à ORL`,
    sentToSupplier: `Envoyé au fournisseur`,
    sentToSupplierForRepair: `Envoyé au fournisseur pour réparation`,
    separator: `Séparateur`,
    separatorExamples: `P.ex. '/' ou '-' ou '.'`,
    serialNumber: `Numéro de série`,
    serialNumberNotUnique: `Numéro de série pas unique`,
    serialNumbers: `Numéros de série`,
    serialNumberShort: `Num. d. série`,
    service: `Service`,
    serviceExplanation: `Un service offert à vos clients (p.ex. audiométrie)`,
    serviceName: `Nom du service`,
    session: `Session`,
    sessions: `Sessions`,
    setAManualDate: `Définir une date manuelle`,
    setInvoicesAutoToPaid: `Mettre automatiquement les factures en paiement`,
    setInvoicesToPaidViaPayments: `Suivi de l'état des paiements des factures par le biais des paiements`,
    setLocationAsNotPlannable: `Définir l'emplacement comme non planifiable`,
    setManualWarrantyEndDate: `Fixer manuellement la date de fin de garantie`,
    setPassword: `Définir mot de passe`,
    setStatusPatientToStatuses: `Définir statut du patient (décédé ou plus actif)`,
    settings: `Paramètres`,
    settingsAndPreferences: `Paramètres & préférences`,
    settlementProposal: `Proposition de règlement`,
    settlementProposals: `Propositions de règlement`,
    setUserAsNotPlannable: `Définir l'utilisateur comme non planifiable`,
    shiftVat: `Déplacer TVA`,
    shipment: `Expédition`,
    shortExternalReference: `Réf. ext.`,
    shortInternalReference: `Réf. int.`,
    shortManufacturerReference: `Réf. de fab.`,
    showAll: `Afficher tout`,
    showAllContents: `Afficher tous les contenus`,
    showAllDaysInclWeekend: `Afficher tous les jours (y compris SA/DI)`,
    showAllWhat: (what: string | number) => `Afficher tous(tes) les ${what.toString().toLowerCase()}`,
    showAnamnesis: `Voir anamnèse`,
    showArticleAgain: `Montrer l'article à nouveau`,
    showColon: `Afficher:`,
    showHoursFrom: `Affichez les heures de`,
    showHoursTo: `Affichez les heures à`,
    showMore: `Afficher plus`,
    showOnlyActive: `Afficher uniquement actifs`,
    showOnlyNotPaid: `Afficher uniquement non payé`,
    showOnlyOutstanding: `Afficher uniquement en souffrance`,
    showOnlyWeekdays: `Afficher uniquement les jours de semaine`,
    showOnlyWhat: (what: string | number) => `Afficher uniquement ${what.toString().toLowerCase()}`,
    showProperties: `Afficher propriétés`,
    showStockPerLocation: `Afficher stock par emplacement`,
    showThisArticle: `Montrer cet article`,
    showWhat: (what: string | number) => `Afficher ${what.toString().toLowerCase()}`,
    signature: `Signature`,
    signatureNeeded: `Signature requise`,
    signatureScanned: `Signature scannée`,
    signatureScannedOrUploaded: `Signature scannée ou téléchargée`,
    signDigitally: `Sign numériquement`,
    signed: `Signé`,
    signVerb: `Signer`,
    singleLine: `Une seule ligne`,
    singlePatient: `Patient exclusif`,
    singlePayments: `Paiements uniques`,
    singleReadout: `Exactement une lecture`,
    skipFollowUpProcessesContentQuestion: `Il n'y a pas de processus à suivre dans ce traitement\nVoulez-vous vraiment sauter cette étape?`,
    skipThisStep: `Passer cette étape`,
    slashCannotBeUsedAsASerialNumber: `'/' ou '|' ne peut pas apparaître dans un numéro de série`,
    slightlyBetter: `Légèrement mieux`,
    slip: `Bon`,
    small: `Petite`,
    socialSecurityNumber: `Numéro de registre national`,
    socialSecurityNumberAbbreviation: `nrn`,
    software: `Logiciel`,
    softwareNotActiveOnThisComputer: `Logiciel n'est pas actif sur cet ordinateur`,
    solveProblems: `Résoudre problèmes`,
    someFunctionsAreNotAccessible: `Certaines fonctions ne sont pas accessibles`,
    somethingOrSomething: (first: string | number, second: string | number) => `${first} ou ${second.toString().toLowerCase()}`,
    somethingQuestionmark: (something: string | number) => `${something}?`,
    sometimes: `Parfois`,
    sortOnWhat: (what: string | number) => `Trier par : ${what.toString().toLowerCase()}.`,
    source: `Source`,
    speakerBracketsReceiver: `Haut-parleur (receiver)`,
    speechAudiograms: `Audiogrammes vocaux`,
    speechAudiometries: `Audiométries vocales`,
    speechAudiometryNotPossible: `Audiométrie vocale n'est pas possible`,
    speechAudiometryPossible: `Audiométrie vocale possible`,
    speechAudiometryPossibleOrNotPossible: `Audiométrie vocale possible/impossible`,
    speechAudiometryPossibleWithException: `Audiométrie vocale possible ou exception (2.2.2)`,
    speechGraphs: `Graphiques vocaux`,
    speechInNoise: `Parole dans le bruit`,
    speechIntelligibilityImprovement: `Amélioration de l'intelligibilité de la parole`,
    speechTest: `Test vocal`,
    splitUpAttachment12: `Répartition annexe 12`,
    ssNumbersDoNotMatch: `Numéros de registre national ne correspondent pas !`,
    standardDocumentWarning: (what: string | number) => `${what} est un document standardisé\nmais HAMS détecte une version personnalisée\nFaites un choix ci-dessous`,
    standardisedVersion: `Version standardisée`,
    standardWhat: (what: string | number) => `${what} par défaut`,
    startComparisonTrial: `Lancer test comparatif`,
    startComparisonTrialCaption: `Les appareils actuellement prêtés restent avec le patient\nDe nouveaux appareils auditifs sont ajoutés à la période d'essai`,
    startDate: `Date de début`,
    started: `Commencé`,
    startedThisYearBracketsYTD: `Commencé cette année (YTD)`,
    startFromAnExample: `Commencer à partir d'un exemple`,
    startFromATemplate: `Commencer à partir d'un modèle`,
    startLoan: `Commencer prêt`,
    startNewTrial: `Commencer nouvel essai`,
    startNewTrialCaption: `Les appareils actuellement prêtés sont remis en stock\nUn nouvel essai est lancé avec d'autres appareils auditifs`,
    startRepair: `Commencer réparation`,
    startSoftware: `Démarrer logiciel`,
    startup: `Commencement`,
    startVerb: `Commencer`,
    status: `Statut`,
    statusCredited: `Crédité`,
    statusNotCredited: `Non crédité`,
    statusNotPurchased: `Non acheté`,
    statusNotReserved: `Non réservé`,
    statusNotSold: `Non vendu`,
    statusPartlyCredited: `En partie crédité`,
    statusPurchased: `Acheté`,
    statusReserved: `Réservé`,
    statusSold: `Vendu`,
    step: `Étape`,
    steps: `Étapes`,
    stepsChanged: `Étapes ajustées`,
    stereo: `Stéréo`,
    stereoPhonic: `Stéréophonique`,
    stereoWith: `Stéréo avec`,
    stereoWithout: `Stéréo sans`,
    stillCountToDeliver: (count: string | number) => `Encore ${count.toString().toLowerCase()} à livrer`,
    stock: `Stock`,
    stockInfo: `Info sur le stock`,
    stockInfoNotUpToDateClickToRecalculate: `Informations sur les stocks non mises à jour, cliquez ici pour recalculer`,
    stockLocation: `Emplacement avec stock`,
    stockMovement: `Mouvement de stock`,
    stockMovements: `Mouvements de stock`,
    stockReplentishment: `Réapprovisionnement du stock`,
    stockTransactions: `Transactions du stock`,
    stockValue: `Valeur du stock`,
    stop: `Arrêter`,
    stopDate: `Date d'arrêt`,
    structuredReference: `Référence structurée`,
    style: `Style`,
    subArticle: `Sous-article`,
    subcategories: `Sous-catégories`,
    subcategory: `Sous-catégorie`,
    subject: `Sujet`,
    subProcess: `Sous-processus`,
    subProcesses: `Sous-processus`,
    subtotal: `Sous-total`,
    successful: `Réussite`,
    summary: `Résumé`,
    supplement: `Supplément`,
    supplements: `Suppléments`,
    supplier: `Fournisseur`,
    supplierDetails: `Coordonnées d'un fournisseur`,
    supplierIsNotRegisteredOnThisProduct: (supplier: string | number) => `Le fournisseur ${supplier.toString().toLowerCase()} n'est pas enregistré sur ce produit`,
    supplierName: `Nom du fournisseur`,
    supplierNameIsTaken: `Il existe déjà un fournisseur portant ce nom`,
    suppliers: `Fournisseurs`,
    supplierSingularOrPlural: `Fournisseur(s)`,
    supplierUpdated: `Fournisseur mis à jour`,
    supplierWhoHasCountOrdersToAddLinesTo: (who: string | number, count: string | number) => `Le fournisseur ${who} a ${count.toString().toLowerCase()} commande(s) où des lignes peuvent être ajoutées à`,
    supplierWillBeDeleted: `Ce fournisseur sera définitivement supprimé du système.`,
    synced: `Synchronisé`,
    tableFormattingHelperText: `Créez un tableau comme indiqué dans l'exemple ci-dessous\nSous l'en-tête de la première colonne, la clé est saisie, suivie de la « clé du tableau »`,
    tailorMadeFor: `Sur mesure pour`,
    tailorMadeHearingAid: `Appareil auditif (sur mesure)`,
    tailorMadeHearingProtection: `Protection auditive (sur mesure)`,
    tailorMadeProduct: `Produit sur mesure`,
    tailorMadeProductExplanation: `Est fabriqué sur mesure pour l'un de vos clients (p.ex. embout, protection auditive sur mesure, etc.)`,
    tailorMadeProducts: `Produits sur mesure`,
    tailorMadeProductType: `Type de produit sur mesure`,
    tailorMadeProductValidated: `Produit sur mesure validé`,
    tailorMadeShort: `Sur mes.`,
    tangible: `Tangible`,
    tangibleArticle: `Article tangible`,
    tangibleArticleWithoutSerialNumber: `Article tangible sans numéro de série`,
    tangibleArticleWithSerialNumber: `Article tangible avec numéro de série`,
    tangibleCaption: `Un produit tangible\np.ex. un embout, un appareil auditif, une protection auditive, etc.`,
    technicalData: `Données techniques`,
    template: `Modèle`,
    templateCanEditCaption: `Ce document peut être modifié`,
    templateCannotEditCaption: `Ce document ne peut pas être modifié`,
    templateNoSignatureNeededCaption: `Un document créé à partir de ce modèle ne doit jamais être signé`,
    templateNotViewableInAppCaption: `Ce document n'est pas adapté à la visualisation en ligne\n(toutes les fonctionnalités de Word ne sont pas prises en charge par l'éditeur en ligne)`,
    templatePredefinedTypeCaption: `Créez un modèle basé sur un type prédéfini`,
    templates: `Modèles`,
    templateScanSignatureLaterCaption: `Un document créé à partir de ce modèle doit être remplacé ultérieurement\npar une copie avec signature`,
    templateStartFromEmptyCaption: `Commencer à partir d'un document vide`,
    templateStartFromExampleCaption: `Commencer à partir d'un exemple`,
    templateStartFromUploadCaption: `Commencer à partir d'un document sur l'ordinateur`,
    templateSubjectCaption: `Créer un modèle basé sur un sujet\np.ex. un patient, une demande d'AA, etc.`,
    templateViewableInAppCaption: `Ce document peut être consulté en ligne`,
    tender: `Offre`,
    tenderCustomerIsAPatientCaption: `En option, une offre peut être adressée à un client TVA\nL'offre sera liée au patient`,
    tenderCustomerIsNotAPatientCaption: `Coordonnées du destinateire sont remplies manuellement\nL'offre peut également être adressée à un client TVA`,
    tendered: `Offre préparée`,
    tenderLines: `Lignes d'offre`,
    tenders: `Offres`,
    term: `Délai`,
    termForHandoverAfterApproval: `Délai de fourniture après approbation méd.-cons.`,
    termRenewal: `Durée du renouvellement`,
    terms: `Termes`,
    test: `Test`,
    testEquipmentTitle: `Tester l'équipement (essai)`,
    tests: `Tester`,
    testsAddedOrChangedAfterCreationOfDocument: `Tests ajoutés ou modifiés après création du document !`,
    thanksForYourTimeAndHonestFeedback: `Merci pour votre temps et vos commentaires honnêtes`,
    theHAMSFeedbackFormIsAvailableAt: `Le formulaire de commentaires HAMS est disponible sur`,
    thereIsAnUpdateForWhatSoftware: (what: string | number) => `Il y a une mise à jour pour logiciel ${what.toString().toLowerCase()}`,
    thereIsNoTestWithAsWelALeftAsARightTonalAudiogramCreateFirst: `Aucun test n'a été effectué avec audiogramme tonal gauche et droit\nCréez d'abord ces tests via un module Noah.`,
    thereIsNoTypeTonalAudiogramCreateFirst: (type: string | number) => `Aucun test n'a été effectué avec audiogramme tonal ${type.toString().toLowerCase()}.\nCréez d'abord ces tests via un module Noah.`,
    thermalPrinter: `Imprimante thermique`,
    thermalPrinters: `Imprimantes thermiques`,
    thermalPrinterSoftware: `Logiciel pour imprimante thermique`,
    thirdPayerArrangement: `Régime du tiers payant`,
    thisAndNextAppointments: `Ce rendez-vous et les suivants`,
    thisAndNextWorkscheduleEntries: `Cette entrée et les suivantes`,
    thisAppointment: `Ce rendez-vous`,
    thisArticleWillBeAvailableForSaleWithNextSale: `Cet article est alors à nouveau disponible à la vente.`,
    thisDateWillBeUsedToCalculateNomenclaturePrices: `Cette date est utilisée pour sélectionner les prix des nomenclatures`,
    thisDeliveryIsLinkedToAnOrder: `Cette livraison est liée à une commande`,
    thisDeliveryIsLinkedToARepair: `Cette livraison est liée à une réparation`,
    thisDeliveryIsLinkedToATailorMadeProduct: `Cette livraison est liée à un produit sur mesure`,
    thisDocumentContainsASignature: `Ce document contient une signature`,
    thisDocumentMustBeReplacedWithAScanWithSignature: `Ce document doit être remplacé ultérieurement par une copie comportant une signature`,
    thisFileIsPasswordProtected: `Ce fichier est protégé par un mot de passe`,
    thisHifDoesNotExistAnymore: `Cette mutualité n'existe plus`,
    thisInfoByDefaultOnTop: `Cette information est en haut par défaut`,
    thisLineWillBeIgnored: `Cette ligne sera ignorée`,
    thisLink: `Ce lien`,
    thisOrderIsCancelled: `Cette commande a été annulée !`,
    thisOrderShouldAlreadyBeDelivered: `Cette commande aurait déjà dû être livré`,
    thisPatientAlreadyHasALinkWithAROSAPatient: `Ce patient en HAMS a déjà un lien avec un patient ROSA.`,
    thisPatientDoesNotExistInHAMSDoYouWantToImportNow: `Ce patient n'existe pas encore dans le HAMS.
Voulez-vous l'importer maintenant ?`,
    thisPatientIsMergedToAnotherPatient: `Les données de ce patient ont été fusionnées avec celles d'un autre patient.`,
    thisProcesIsPartOfARfia: `Ce processus s'inscrit dans le cadre d'une demande d'allocation d'assurance.`,
    thisProcessIsCancelled: `Ce processus a été annulé !`,
    thisPurchase: `Cet achat`,
    thisRepairShouldAlreadyBeRedelivered: `Cette réparation devrait déjà avoir été livrée !`,
    thisSale: `Cette vente`,
    thisScreenWillSoonBeReplacedByAnImprovedView: `Cet écran sera bientôt remplacé par un écran amélioré.`,
    thisTransactionCannotBeUndoneText: `Attention, cette transaction est définitive !\nIl n'est pas possible de la supprimer par la suite\nVeuillez vérifier les détails ci-dessous avant de confirmer`,
    thisWhatWillAlsoBePurchasedFromASupplier: (what: string | number) => `Ce/tte/s ${what.toString().toLowerCase()} est/sont également acheté(e)(s) chez un fournisseur`,
    thisWhatWillNotBePurchasedFromASupplier: (what: string | number) => `Ce/tte/s ${what.toString().toLowerCase()} n'est/ne sont pas acheté(e)(s) chez un fournisseur`,
    thisWorkscheduleEntry: `Seule cette entrée`,
    timeline: `Chronologie`,
    timeScale: `Échelle de temps`,
    timetable: `Horaires`,
    title: `Titre`,
    toAnotherLocation: `Vers un autre emplacement`,
    toAssign: `Pour attribuer`,
    toCreateNewProduct: `Pour créer un nouveau produit`,
    toHandoverToPatient: `A remettre au patient`,
    toLocation: `Vers emplacement`,
    tonalAudiogram: `Audiogramme tonal`,
    tonalAudiogramOfDate: `Audiogramme tonal de`,
    tooltipIsACashDeskResource: `Peut être utilisé dans le module de caisse`,
    tooltipIsAPlannableResource: `Peut être utilisé pour planifier des rendez-vous`,
    tooltipIsAStockManagementResource: `Peut être utilisé pour stockage`,
    tooltipIsNotACashDeskResource: `Ne peut PAS être utilisé dans le module de caisse`,
    tooltipIsNotAPlannableResource: `Ne peut PAS être utilisé pour planifier des rendez-vous`,
    tooltipIsNotAStockManagementResource: `Ne peut PAS être utilisé pour le stockage`,
    toPayBefore: `À payer avant`,
    toPayBeforeDate: `À payer avant la date`,
    toPayByPatient: `À payer par le patient`,
    toPlaceWithSupplier: `A placer auprès du fournisseur`,
    toProcess: `A traiter`,
    total: `Total`,
    totalAmount: `Montant total`,
    totalExVat: `Total hors TVA`,
    totalInVat: `Total TVA incl.`,
    totalOfContributions: `Total allocations`,
    totalPriceDevicesExVat: `Prix total des appareils (hors TVA).`,
    totals: `Total`,
    totalValueOfDevices: `Valeur totale des appareils`,
    totalValueOfSale: `Valeur totale des ventes`,
    transactions: `Transactions`,
    transactionType: `Type de transaction`,
    treatment: `Traitement`,
    treatmentInvoiceCaption: `Facturation séparée des p.e. appareils auditifs sans demande d'AA`,
    treatmentPermanentlyDeleted: `Traitement définitivement supprimé`,
    treatments: `Traitements`,
    trial: `Essai`,
    trialAdministrativeEndDate: `Date de fin administrative de l'essai`,
    trialDuration: `Durée du test`,
    trialEnded: `Essai terminé`,
    trialReport: `Rapport d'essai`,
    trialRunning: `Essai en cours`,
    trialsExpiredNoDocumentsSentForMedicalPrescription: `Périodes d'essai expiré, aucun document n'a encore été envoyé au médecin ORL`,
    troubleShoot: `Dépannage`,
    twoDecimals: `2 chiffres après la virgule`,
    type: `Type`,
    typeAnnotation: `Type d'annotation`,
    typeAppointment: `Type de rendez-vous`,
    typeHearingAid: `Type appareil auditif`,
    typePayer: `Type de payeur`,
    types: `Types`,
    typesAnnotation: `Types de commentaire/annotation`,
    typesAppointments: `Types de rendez-vous`,
    typicalForHardwareWithWithoutSerialNumber: `Typique pour matériel (avec/sans numéro de série)`,
    ublFilesToHandle: `Les UBL à traiter`,
    unassigned: `Non attribué`,
    unblock: `Débloquer`,
    unBlockUser: `Débloquer utilisateur`,
    unblockUserContent: `Cet utilisateur pourra à nouveau se connecter`,
    unBlockUserQuestion: `Débloquer utilisateur ?`,
    undefined: `Non défini`,
    understood: `Compris`,
    undoCancellation: `Annuler l'annulation`,
    undoDelete: `Annuler la suppression`,
    undoDeleteAnnotationQuestion: `Annuler suppression d'annotation ?`,
    undoDeleteAttachmentQuestion: `Annuler suppression d'annexe ?`,
    undoFailedTrail: `Annuler essai raté`,
    undoFailedTrailContent: `Erreur de correction ; cette demande d'allocation d'assurance n'a pas échoué.\nVoulez-vous continuer ?`,
    undoFailedTrailShort: `Annuler essai raté`,
    undoIgnore: `Annuler l'ignorer`,
    undoPlaceWithSupplier: `Annuler le placement avec le fournisseur`,
    undoPlaceWithSupplierQuestion: `Défaire la place avec le fournisseur?`,
    undoSkipThisStep: `Annuler sauter l'étape`,
    undoSkipThisStepContentQuestion: `Êtes-vous sûr d'annuler sauter l'étape?`,
    unhandled: `Non traité`,
    uniqueReference: `Référence unique`,
    unitDiscount: `Remise unitaire`,
    unitListPrice: `Prix unitaire recommandé`,
    unitPrice: `Prix unitaire`,
    unitVatValue: `TVA par unité`,
    unknown: `Inconnu`,
    unknownDateFormat: `Format de données inconnu`,
    unlimited: `Illimité`,
    unlinked: `Pas de lien`,
    unpaid: `Non payé`,
    unPaidSales: `Ventes non payées`,
    unsavedChanges: `Modifications non sauvegardées`,
    updateContactInfo: `Modifier les informations de contact`,
    updated: `Mis à jour`,
    updateRIZIVNumber: `Mettre à jour numéro INAMI`,
    upload: `Télécharger`,
    uploadDocument: `Télécharger document`,
    uploadNHAX: `Importer NHAX`,
    uploadReplaceDocument: `Télécharger/remplacer document`,
    uploadWhat: (what: string | number) => `Télécharger ${what.toString().toLowerCase()}`,
    uploadWrittenOrSignedDocument: `Télécharger un document (manuscrit et/ou signé)`,
    upperCase: `Première lettre en majuscule`,
    useAPrefix: `Utiliser un préfixe`,
    useCodeFromProductCatalog: `Utiliser codes du catalogue de produits`,
    usedFrequencies: `Fréquences utilisées`,
    usedList: `Liste utilisée`,
    useFallbackCodes: `Utiliser fallback values`,
    usefulLinks: `Liens utiles`,
    useLatestDocument: `Utiliser le dernier document`,
    useOriginalDocument: `Utiliser le document original`,
    user: `Utilisateur`,
    userDefined: `Défini par l'utilisateur`,
    userRegisteredCheckEmail: (email: string | number) => `Utilisateur ${email} enregistré, vérifier adresse e-mail`,
    users: `Utilisateurs`,
    userUpdated: `Utilisateur changé`,
    userWillBeLoggedOutOfAllSessions: `L'utilisateur est déconnecté de tous les appareils et navigateurs`,
    userWillBeLoggedOutOfSpecificSession: `L'utilisateur est déconnecté de cet appareil ou de ce navigateur`,
    userWithEmailCreated: (email: string | number) => `Utilisateur avec e-mail: ${email} créé`,
    useThisColorScheme: `Utilisez ce jeu de couleurs`,
    useYear: `Utiliser année`,
    validated: `Validé`,
    validateData: `Valider les données`,
    validatedNegativeResult: `Évalué négativement`,
    validateVerb: `Valider`,
    validation: `Validation`,
    validFrom: `Valable à partir du`,
    validityFrom: `Validité du`,
    validityTo: `Validité jusqu'au`,
    validPhoneNumberFormat: `Format valide: +32xxxxxxxxx`,
    validTo: `Valable jusqu'au`,
    vat: `TVA`,
    vatAmount: `Montant TVA`,
    vatCustomer: `Client TVA`,
    vatCustomers: `Clients TVA`,
    vatNumber: `Numéro de TVA`,
    vatPercentage: `% TVA`,
    ventDiameterInMm: `Diamètre de l'ouverture de ventilation (en mm)`,
    verzamelstaat: `État récapitulatif`,
    verzamelstaten: `États récapitulatifs`,
    view: `Voir`,
    viewableInApp: `Voir dans l'app`,
    viewAccountReceivable: `Consulter le compte client`,
    viewAll: `Voir tout`,
    viewAllCashSales: `Voir tous les ventes au comptant`,
    viewAllCountAppointments: (count: string | number) => `Afficher tous les ${count.toString().toLowerCase()} rendez-vous`,
    viewAllDetails: `Voir tous les détails`,
    viewArticleFromProductCatalog: `Voir l'article du catalogue de produits`,
    viewCustomerAccount: `Consulter le compte client`,
    viewDetailsOfThisArticle: `Voir les détails de cet article`,
    viewDocument: `Voir document`,
    viewDocumentation: `Voir la documentation`,
    viewEditDocument: `Afficher/modifier document`,
    viewEditWhat: (what: string | number) => `Voir/modifier ${what.toString().toLowerCase()}`,
    viewHAMSPatient: `Voir le patient HAMS`,
    viewHereHowToIntegrateWithROSA: `Pour savoir comment demander une intégration ROSA, cliquez ici.`,
    viewInRosa: `Voir à ROSA`,
    viewInventoryItem: `Voir l'article de stock`,
    viewLinkedCustomerAccount: `Voir le compte client lié`,
    viewLinkedInventoryItem: `Voir l'article de stock lié`,
    viewLinkedOrder: `Voir la commande liée`,
    viewLinkedRepair: `Voir la réparation liée`,
    viewNoahData: `Voir les données de NOAH`,
    viewPreferences: `Préférences d'affichage`,
    viewRIZIVDocumentation: `Voir la documentation INAMI`,
    viewRosaHamsUsersLink: `Voir le lien utilisateur ROSA - HAMS`,
    viewSale: `Voir la vente`,
    viewSaleInHAMS: `Regarder la vente en HAMS`,
    viewSplitUpPerVatPercentage: `Afficher la répartition par % de TVA`,
    viewTarifs: `Voir les tarifs`,
    viewTheOldVersionForNowQuestionClickHereToNavigate: `Vous voulez voir l'ancienne vue pour le moment ? Cliquez ici pour aller à l'ancienne vue.`,
    viewWhatInOtherTab: (what: string | number) => `Voir ${what.toString().toLowerCase()} dans un autre onglet`,
    viewWhatInThisTab: (what: string | number) => `Voir ${what.toString().toLowerCase()} dans cet onglet.`,
    waitOnDocumentCreation: `En attente de la création d'un document`,
    waitOnPaymentReferenceCreation: `Attente de la création d'une référence de paiement`,
    wantToMoveAppointmenToAnotherTimeUserLocationQuestion: `Déplacer le rendez-vous à un autre moment/utilisateur/lieu?`,
    warning: `Avertissement`,
    warningHamsNoahClientVersion: `Attention!\n* Le logiciel client HAMS-Noah ne fonctionne qu'avec la version 4.12 ou ultérieure de Noah.\n* Le logiciel client HAMS-Noah nécessite une licence Noah valide.`,
    warningOnlyRegistrationOfSerialNumber: `Attention, uniquement enregistrement du numéro de série`,
    warningOnlyRegistrationOfSerialNumberCaption: `De cette façon, il est possible de définir un article de stock avec un numéro de série\nLe statut du stock est initialement non inventorié « jusqu'à ce que des transactions soient ajoutées.\n(transaction = livraison, essai, vente ...) »`,
    warningOnlyThingsLinkedToAttachment12CanBeAltered: `Attention : seules les questions concernant l'annexe 12 peuvent être ajustées (remboursable et codage INAMI)`,
    warningOnlyUnlinkedAndNotIgnoredDevicesAreShownHere: `Attention : Seuls les appareils NON liés et NON ignorés sont affichés ici`,
    warningRegistrationHandoverBeforeApprovals: `Attention, enregistrement de fourniture avant approbations`,
    warningRegistrationHandoverBeforeApprovalsCaption: `Grâce à cette méthode, il est possible d'enregistrer la fourniture (et d'établir une facture) avant l'approbation finale.\n(Ceci n'est pas conforme aux directives INAMI)`,
    warningSingularOrPlural: `Avertissement(s)`,
    warningThisPatientAlreadyHasALinkWithAnotherRosaRecod: (who: string | number) => `Attention: le patient '${who}' a déjà un lien avec un autre patient ROSA.`,
    warrantyEndDate: `Garantie jusqu'à la date`,
    wasOverDue: `Était en retard`,
    website: `Site web`,
    welcomeBack: `Bienvenue à nouveau`,
    whatAdded: (what: string | number) => `${what} ajouté(e)(s)`,
    whatForUnArticleFromAPreviousPurchase: (what: string | number) => `${what} pour un article d'un achat précédent`,
    whatForUnArticleFromAPreviousSale: (what: string | number) => `${what} pour un article d'une vente précédent`,
    whatSoftwareNotInstalled: (what: string | number) => `Logiciel ${what.toString().toLowerCase()} n'est pas installé`,
    who: `Qui`,
    WIACompatibleScanners: `Scanners compatibles WIA`,
    willBeDefaultOffice: `Cela deviendra le cabinet par défaut`,
    willBeRepeatedQuestion: `Est répété ?`,
    willNotBePurchased: `Ne peut être acheté`,
    with: `Avec`,
    withOrWithoutTerm: `Avec/sans délai`,
    withOrWithoutWhat: (what: string | number) => `Avec/sans ${what.toString().toLowerCase()}`,
    without: `Sans`,
    withoutPrescriptionForTrialCaption: `Patient n'a pas encore reçu de prescription pour les tests`,
    withoutSeparateEarmould: `Sans embout auriculaire séparé`,
    withoutSeparateEarmouldCaption: `Sans embout auriculaire séparé (p.e. OpenFit, OpenEar, ...)`,
    withoutSeparateReceiver: `Sans receiver séparé`,
    withoutSeparateReceiverCaption: `Sans receiver séparé (p.e. Power BTE)`,
    withOutstandingBalance: `Avec solde en souffrance`,
    withOutstandingBalanceNotOnSettlementProposals: `Avec solde en souffrance, pas sur un proposition de règlement`,
    withoutTerm: `Sans délai`,
    withoutThirdPayerArrangementCaption: `Le patient paie la totalité du montant.`,
    withoutWhat: (what: string | number) => `Sans ${what.toString().toLowerCase()}`,
    withPrescriptionForTrialCaption: `Annexe 17 à portée de main, saisissez date de prescription et sélectionnez médecin ORL`,
    withRespectToShort: `À l'égard de`,
    withSeparateEarmould: `Avec embout auriculaire séparé`,
    withSeparateEarmouldCaption: `Avec embout auriculaire séparé (p.e. Power BTE)`,
    withSeparateReceiver: `Avec receiver séparé`,
    withSeparateReceiverCaption: `Avec receiver séparé, receiver dans l'oreille (p.e. RITE, RIC)`,
    withSerialNumber: `Avec numéro de série`,
    withThirdPayerArrangementCaption: `Le patient ne paiera que part personnelle.`,
    withWhat: (what: string | number) => `Avec ${what.toString().toLowerCase()}`,
    wizardAndHelp: `Assistant & aide`,
    work: `Travail`,
    workflow: `Flux de travail`,
    workPhone: `Tél. du travail`,
    workWeek: `Semaine de travail`,
    worse: `Pire`,
    writeAnAddressHere: `Saisissez une adresse ici`,
    wrongDataQuestion: `Données incorrect?`,
    wrongRIZIVNumberFormat: `Mauvais format : donnez un numéro INAMI dans le format 'x-xxxx-xx-xxx'`,
    yearComesAfterPrefixAndBeforeNumber: `L'année vient après le « préfixe » et avant le « numéro d'index »`,
    yes: `Oui`,
    yesCommaActivate: `Oui, activer!`,
    yesCommaContinue: `Oui, continuer`,
    yesCommaCreateNewPatient: `Oui, créer nouveau patient`,
    yesCommaDeactivate: `Oui, désactivez!`,
    yesCommaDelete: `Oui, supprimer`,
    yesCommaSave: `Oui, sauvegarder`,
    yesFillInNow: `Oui, remplissez-les maintenant`,
    yesForAll: `Oui, pour tout`,
    yesItFits: `Oui, il convient`,
    yesWhatExclamation: (what: string | number) => `Oui, ${what.toString().toLowerCase()}!`,
    zip: `Code postal`,
}
