import { PatientExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createPatientsExportRecord = (strings: IStrings): Record<PatientExportProp, string> => {
	return {
		Created: strings.created,
		LastUpdated: strings.lastUpdated,
		AddressLine: strings.addressLine,
		Age: strings.age,
		AttendingAudiologistIdentifier: strings.audiologist,
		AttendingEntPhysicianIdentifier: strings.entPhysician,
		AttendingGeneralPractitionerIdentifier: strings.generalPractitioner,
		City: strings.city,
		Country: strings.country,
		DateOfBirth: strings.dateOfBirth,
		EidDataLastRead: strings.eIDDataLastReadDate,
		FirstName: strings.firstName,
		Gender: strings.gender,
		HealthInsuranceFundCode: strings.healthInsuranceFund,
		IsActive: `${strings.active}?`,
		IsDeceased: `${strings.deceased}?`,
		IsMarkedAsInactive: `${strings.inactive}?`,
		LastName: strings.lastName,
		LocationIdentifier: strings.location,
		OriginsCsv: strings.origin,
		Salutation: strings.salutation,
		SocialSecurityNumber: strings.socialSecurityNumber,
		Status: strings.status,
		Zip: strings.zip,
		LabelsCsv: strings.labels,
		Email: strings.email,
		Phone: strings.phone,
		AttendingEntPhysicianId: `ID ${strings.entPhysician}`,
		AttendingGeneralPractitionerId: `ID ${strings.generalPractitioner}`,
		Id: strings.id,
		LastHandoverDate: strings.dateLastHandover,
		Reference: strings.reference,
	};
};
