import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { returnsQuery_single } from 'gen/ApiClient';
import React from 'react';
import { useParams } from 'react-router-dom';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { ReturnDetailDocumentsCard } from './ReturnDetailDocumentsCard';
import { ReturnDetailPageHeader } from './ReturnDetailPageHeader';
import { ReturnDetailPageSidebar } from './ReturnDetailPageSidebar';
import { ReturnDetailContentsCard } from './contents/ReturnDetailContentsCard';

export const ReturnDetailPage = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [trigger, reload] = useTrigger();
	const [item, reloadDetail] = useApiEffect(returnsQuery_single, id);

	useLazyEffect(() => {
		reloadDetail();
	}, [trigger]);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='Returns'
			stickyHeader={
				<ReturnDetailPageHeader
					item={item}
					reload={reload}
				/>
			}
			stickySidebar={
				<ReturnDetailPageSidebar
					item={item}
					reload={reload}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
					maxWidth: 1280,
				}}>
				<div className='df-row-wrap gap-16'>
					<ReturnDetailContentsCard
						item={item}
						style={{ flexGrow: 2 }}
						className='h-fc'
					/>
					<div
						className='df-col gap-16'
						style={{ flexGrow: 1 }}>
						<ReturnDetailDocumentsCard
							item={item}
							reload={reload}
							reloadTrigger={trigger}
						/>
					</div>
				</div>
			</div>
		</RestrictedPageX>
	);
};
