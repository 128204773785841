import { Divider } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { InnerPageXWithStickySidebar } from 'framework/components/layout/InnerPageXWithStickySidebar';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RosaPatientRecordListItem } from './RosaPatientRecordListItem';
import { RosaPatientRecordsListFilterCard } from './RosaPatientRecordsListFilterCard';
import { RosaPatientRecordsQueryContext } from './RosaPatientRecordsQueryContext';
import { RosaPatientRecordsListStats } from './RosaPatientRecordsListStats';

interface IProps extends IReloadTriggerProps {
	reload: VoidFunction;
}

export const RosaPatientRecordsListTab = ({ reload, reloadTrigger }: IProps) => {
	const { params, setParams, queryResult, reload: reloadQuery } = useQueryContext(RosaPatientRecordsQueryContext);
	const strings = useLocalization();

	useLazyEffect(() => {
		reloadQuery();
	}, [reloadTrigger]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<InnerPageXWithStickySidebar
			maxWidth={1280}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<RosaPatientRecordsListFilterCard
						params={params}
						setParams={setParams}
						style={{ minWidth: 220 }}
					/>
					<div className='fg1'></div>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16, paddingLeft: 8 }}>
				<RosaPatientRecordsListStats metadata={queryResult.metadata} />
				<SearchbarX
					placeholder={`${strings.searchVerb}...`}
					value={params.searchString}
					onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
				/>
				<CardX className='w100'>
					<div
						className='df-row'
						style={{ gap: 8 }}>
						<Typography16pxBold>{strings.results}</Typography16pxBold>
						<div className='fg1'></div>
					</div>
					<Divider style={{ marginTop: 8 }} />
					{queryResult.values.map(t => (
						<RosaPatientRecordListItem
							key={t.id}
							item={t}
							reload={reload}
						/>
					))}
					<QueryResultPaginationX
						queryResult={queryResult}
						setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
						style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
					/>
				</CardX>
			</div>
		</InnerPageXWithStickySidebar>
	);
};
