// import { createSelectionHeader } from "framework/table/createSelectionHeader";
import React from 'react';
import { createHeader } from 'framework/table/createHeader';
import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { IStrings } from 'localization/IStrings';
import { INoahDeviceForPatientWithSelection } from 'app/main/inventoryManagement/noahDevices/single/INoahDeviceForPatientWithSelection';
import { NoahDeviceImportedStatusChip } from 'app/main/inventoryManagement/noahDevices/single/NoahDeviceImportedStatusChip';
import { LeftRightRemoteStatusChip } from './LeftRightRemoteStatusChip';

export const createHeaders = (
	lines: INoahDeviceForPatientWithSelection[],
	setLines: React.Dispatch<React.SetStateAction<INoahDeviceForPatientWithSelection[]>>,
	strings: IStrings
): IHeader<INoahDeviceForPatientWithSelection>[] => {
	return [
		createSelectionHeader<INoahDeviceForPatientWithSelection>(lines, setLines, t => t.isLinkedToInventoryItem === false),
		createSortableHeader<INoahDeviceForPatientWithSelection>(strings.dateLastSelection, 'lastSelectionDate', t => formatDate(t.lastSelectionDate)),
		createSortableHeader<INoahDeviceForPatientWithSelection>(
			`${strings.manufacturer}, ${strings.model}`,
			'manufacturerCommaInstrumentTypeName',
			t => t.manufacturerCommaInstrumentTypeName
		),
		createSortableHeader<INoahDeviceForPatientWithSelection>(strings.serialNumber, 'serialNumber', t => t.serialNumber),
		createHeader<INoahDeviceForPatientWithSelection>(strings.orientation, t => <LeftRightRemoteStatusChip item={t} />),
		createHeader<INoahDeviceForPatientWithSelection>(strings.status, t => <NoahDeviceImportedStatusChip item={t} />),
	];
};
