import { useCallback, useContext } from 'react';
import { ApiContext } from 'framework/context/ApiContext';
import { useApi } from 'framework/hooks/useApi';
import { useApiErrorHandling } from 'framework/hooks/useApiErrorHandling';
import { Try } from 'framework/Try';
import { fileResponseInterceptor } from 'framework/xhr/fileResponseInterceptor';
import { IFileResponse } from './ApiClient';
import { DefaultAxiosInstance } from './DefaultAxiosInstance';

export function useDownloadWithLoader<TArgs extends any[]>(
	callback: (...args: TArgs) => Promise<Try<IFileResponse>>
): (...args: TArgs) => Promise<Try<IFileResponse>> {
	const [setLoading, clearLoading] = useApi();
	const apiContext = useContext(ApiContext);
	const handleError = useApiErrorHandling(apiContext.strings, apiContext.redirect, apiContext.notify, apiContext.setMustUpdateFrontEnd);

	return useCallback(
		async (...args: TArgs) => {
			const loadId = setLoading();
			const instance = DefaultAxiosInstance();
			instance.interceptors.response.use(fileResponseInterceptor);
			const newArgs = [...args, undefined, instance];
			const r = await callback(...(newArgs as any));
			if (r.isFailure) {
				handleError(r.error);
			}
			clearLoading(loadId);
			return r;
			// missing deps handleError -> is a function
		},
		// eslint-disable-next-line
		[callback]
	);
}
