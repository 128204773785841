import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { isFiltered } from 'framework/utils/isFiltered';
import { ITransactionsQueryParams, TransactionType, TransactionTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	params: ITransactionsQueryParams;
	onFilter: (params: ITransactionsQueryParams) => void;
	paddingLeft: number;
}

// TODO fix this, is this correct?
const exclusions: (keyof ITransactionsQueryParams)[] = [];

// TODO add filter on Type!
export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const { transactionTypeRecord } = useContext(RecordContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.date}
				before={params.createdBefore}
				after={params.createdAfter}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<RecordListFilterComponent<TransactionType>
				record={transactionTypeRecord}
				options={TransactionTypes}
				selected={params.types as any}
				setSelected={vals => onFilter({ ...params, types: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.type}
			/>
		</FilterBar>
	);
};
