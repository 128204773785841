import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import { HamsClientConfigurationDialog } from 'app/client/HamsClientConfigurationDialog';
import { HamsClientApplicationContext } from 'app/externalApps/client/HamsClientApplicationContext';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IModuleSummaryWithIcon, force_set_patient, launch_Module } from 'gen/client/HamsClient';
import { useHandleClientReponse } from 'gen/client/useHandleClientReponse';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { Modules } from './Modules';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';

interface IProps extends DialogProps {
	patientIndex: number;
	close: VoidFunction;
}

export const LaunchModuleDialog = ({ patientIndex, close, ...rest }: IProps) => {
	const { open, cancel, isStacked } = useDialogsContext();
	const { canLaunch, check, installedBuild } = useContext(HamsClientApplicationContext);
	const theme = useTheme();
	const strings = useLocalization();
	const [launch] = useFormSubmit(launch_Module);
	const handle = useHandleClientReponse();
	const notify = useSnackbarNotify();
	const [resetPatient, isResettingPatient] = useFormSubmit(force_set_patient);

	useEffect(() => {
		check();
		// eslint-disable-next-line
	}, []);

	const onStartModule = async (module: IModuleSummaryWithIcon) => {
		const r = await launch(module.moduleId ?? 0, patientIndex);
		if (r.isSuccess) {
			handle(r.result, `${module.name} gestart`);
			if (r.result.isSuccess) {
				close();
			}
		}
	};

	const onConfiguration = () => {
		open(
			<HamsClientConfigurationDialog
				open
				close={cancel}
			/>
		);
	};

	const onResetPatient = async () => {
		const r = await resetPatient(patientIndex);
		if (r.isSuccess) {
			if (isNotNullNorUndefined(r.result)) {
				notify(r.result, 'error');
			} else {
				notify('Patient reset', 'success');
			}
		}
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<DescriptionIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{strings.NoahExternalModules}
					</Typography>
					{canLaunch === false && <WarningLabelledProperty warning={strings.errorCannotStartExternalModules} />}
				</DialogGridTitle>
				{canLaunch === false && (
					<>
						<GridDividerWithCaption caption={strings.installationAndConfiguration} />
						<PropertyInGrid
							value={
								<SmallPrimaryTextButton
									color='secondary'
									onClick={onConfiguration}
									startIcon={<SettingsIcon />}>
									{strings.solveProblems}
								</SmallPrimaryTextButton>
							}
						/>
					</>
				)}
				{canLaunch === true && (
					<>
						{installedBuild >= 120 && (
							<>
								<GridDividerWithCaption caption={strings.actions} />
								<PropertyInGrid
									value={
										<LoaderButton
											isLoading={isResettingPatient}
											color='primary'
											onClick={onResetPatient}
											startIcon={<RotateLeftIcon />}>
											{`Reset patient in Noah`}
										</LoaderButton>
									}
								/>
							</>
						)}
						<GridDividerWithCaption caption={strings.modules} />
						<Modules onSelect={onStartModule} />
					</>
				)}
			</DialogContentGrid>
		</Dialog>
	);
};
