import { InvoiceForwardingSalesQueryParamsLsKey } from 'app/localStorageKeys';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	IInvoiceForwardingQueryParams,
	IInvoiceForwardingRecord,
	invoiceForwardingQuery_concurrencyToken,
	invoiceForwardingQuery_query,
	IQueryResult,
} from 'gen/ApiClient';
import React from 'react';
import { InvoiceForwardingRecordsQueryContext } from './InvoiceForwardingRecordsQueryContext';

const DefaultParams: IInvoiceForwardingQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	prefilter: 'NotIgnoredNorForwarded',
};

export const InvoiceForwardingRecordsQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IInvoiceForwardingRecord>, IInvoiceForwardingQueryParams>
			defaultParams={DefaultParams}
			queryF={invoiceForwardingQuery_query}
			concurrencyF={invoiceForwardingQuery_concurrencyToken}
			localStorageKey={InvoiceForwardingSalesQueryParamsLsKey}
			context={InvoiceForwardingRecordsQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
