import { Dialog, DialogContent, DialogProps, DialogTitle, Divider, useTheme } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { ILinkLocationWithPatientModel, IPatient, patientsCommand_updateLocation } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormSelectLocationField } from '../../settings/locations/forms/FormSelectLocationField';

const createSchema = (strings: IStrings) => {
	return yup.object<ILinkLocationWithPatientModel>({
		locationId: yup.string().required(strings.formRequired(strings.location)),
		alsoUpdateLocationOnRunningProcesses: yup.boolean().defined(),
	});
};

interface IProps extends DialogProps {
	item: IPatient;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LinkLocationWithPatientForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const [link, isSubmitting] = useFormSubmit(patientsCommand_updateLocation);

	const handleSubmit = async (values: ILinkLocationWithPatientModel, helpers: FormikHelpers<ILinkLocationWithPatientModel>) => {
		const r = await link(item.id, values);
		if (handleValidateResponse(r, helpers, 'locationId')) {
			confirm();
		}
	};

	return (
		<Formik<ILinkLocationWithPatientModel>
			validateOnMount
			initialValues={{
				locationId: item.locationId ?? '',
				alsoUpdateLocationOnRunningProcesses: true,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.selectWhat(strings.location)}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormSelectLocationField<ILinkLocationWithPatientModel>
							name='locationId'
							label={strings.location}
						/>
						<Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
						<FormExtendedRadioGroupField<ILinkLocationWithPatientModel, boolean>
							name='alsoUpdateLocationOnRunningProcesses'
							label=''
							options={[
								{ value: true, label: strings.alsoUpdateOnProcesses, caption: strings.locationWillAlsoBeChangedOnOtherProcesses },
								{ value: false, label: strings.doNotUpdateOnProcesses, caption: strings.locationWillOnlyBeChangedWithThisPatientAndNotOnProcesses },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.link}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
