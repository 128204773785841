import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { SelectField } from 'framework/components/select/SelectField';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { imageScanner_devices, imageScanner_scan, IScannerDevice, ScanError, ScanSource } from 'gen/imageScanner/ImageScannerClient';
import { useLocalization } from 'localization/useLocalization';
import { NoRecordsFound } from '../../../framework/components/NoRecordsFound';
import { RecordContext } from '../../records/RecordContext';

interface IProps extends DialogProps {
	title: string;
	cancel: VoidFunction;
	confirm: (contentAsBase64: string) => void;
	previous?: VoidFunction;
	isSubmitting: boolean;
}

export const InstalledDialog = ({ title, cancel, confirm, previous, isSubmitting, ...rest }: IProps) => {
	const strings = useLocalization();
	const [devices] = useApiEffect(imageScanner_devices);
	const [selectedDevice, setSelectedDevice] = useState<IScannerDevice>();
	const [selectedSource, setSelectedSource] = useState<string>();
	const { scanSourceRecord } = useContext(RecordContext);
	const [scan, isScanning] = useFormSubmit(imageScanner_scan);
	const notify = useSnackbarNotify();
	const { scanErrorRecord } = useContext(RecordContext);

	useEffect(() => {
		if (selectedDevice && selectedDevice.supportedSources.length > 0) {
			setSelectedSource(selectedDevice.supportedSources[0]);
		}
	}, [selectedDevice]);

	const onScan = async () => {
		if (selectedDevice && selectedSource) {
			const r = await scan({ deviceId: selectedDevice.deviceId!, source: selectedSource! });
			if (r.isSuccess) {
				if (r.result.hasError) {
					notify(scanErrorRecord[r.result.error! as ScanError], 'error');
				} else {
					confirm(r.result.contentAsBase64!);
				}
			}
		}
	};

	return (
		<Dialog
			maxWidth='sm'
			fullWidth
			{...rest}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				{devices !== undefined && devices.length > 0 && (
					<>
						<SelectField<IScannerDevice>
							options={devices}
							value={selectedDevice}
							onChange={setSelectedDevice}
							getKey={t => t.deviceId!}
							label={strings.scanner}
							renderValue={t => t.name!}
						/>
						<LightBulbNotification style={{ marginTop: 8 }}>
							<div>{strings.onlyWIACompatibleScannersWillBeShown}</div>
						</LightBulbNotification>
					</>
				)}
				{(devices === undefined || devices.length === 0) && <NoRecordsFound>{strings.noWhatFound(strings.WIACompatibleScanners)}</NoRecordsFound>}
				{selectedDevice !== undefined && (
					<>
						<DividerWithCaption caption={strings.options} />
						<SelectField<string>
							options={selectedDevice.supportedSources}
							value={selectedSource}
							onChange={setSelectedSource}
							getKey={t => t}
							label={strings.source}
							renderValue={t => scanSourceRecord[t as ScanSource]}
						/>
					</>
				)}
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				previous={previous}
				submitText={strings.scan}
				isSubmitting={isScanning || isSubmitting}
				isValid={selectedDevice !== undefined && selectedSource !== undefined}
				onSubmit={onScan}
			/>
		</Dialog>
	);
};
