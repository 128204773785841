import { Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { useAnchorElement } from '../../hooks/useAnchorElement';
import { SelectButton } from './SelectButton';

interface IProps<T extends string | number> {
	options: T[];
	record: Record<T, string>;
	state: T;
	setState: (val: T) => void;
	style?: React.CSSProperties;
}

export const SelectButtonWithMenu = <T extends string | number>({ options, record, state, setState, style }: IProps<T>) => {
	const [anchor, open, close] = useAnchorElement();

	return (
		<>
			<SelectButton
				onClick={open}
				style={{ paddingRight: 8, ...style }}>
				{record[state]}
			</SelectButton>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={close}
				keepMounted>
				{options.map(t => (
					<MenuItem
						key={t}
						onClick={() => {
							setState(t);
							close();
						}}
						value={t}>
						{record[t]}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
