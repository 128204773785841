import { useFormikContext } from 'formik';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { FormAutocompleteFieldWithAdd } from 'framework/forms/FormAutocompleteFieldWithAdd';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { ICompanySummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { CompaniesCacheContext } from '../CompaniesCacheContext';
import { RegisterCompanyForm } from './RegisterCompanyForm';

interface IProps<TModel> extends IFormProps<TModel> {
	patientId: string | undefined;
}

export const FormSelectOrCreateCompanyField = <TModel extends unknown>({ patientId, name, label, ...rest }: IProps<TModel>) => {
	const props = useFormikContext<TModel>();
	const strings = useLocalization();
	const [data, load] = useCacheContext(CompaniesCacheContext);
	const { open, confirm, cancel } = useDialogsContext();

	if (data === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<RegisterCompanyForm
				open
				patientId={patientId}
				confirm={async id => {
					confirm();
					await load();
					setFormValue<TModel>(name, id, props);
				}}
				cancel={cancel}
			/>
		);
	};

	return (
		<FormAutocompleteFieldWithAdd<TModel, ICompanySummary>
			{...rest}
			name={name}
			label={label}
			options={data}
			onCreateNew={onNew}
			renderCreate={str => `${strings.createSomething(str)}, ${strings.newWhat(strings.vatCustomer)}`}
		/>
	);
};
