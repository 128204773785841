import React, { useMemo } from 'react';
import { IFormProps } from './common/IFormProps';
import { FormNumberField } from './FormNumberField';

interface IProps<TModel> extends IFormProps<TModel> {
	allowNegative?: boolean;
	decimalScale?: number;
}

export const FormMoneyField = <TModel extends unknown>({ allowNegative, decimalScale = 2, ...props }: IProps<TModel>) => {
	const sAllowNegative = useMemo(() => (allowNegative === true ? true : false), [allowNegative]);

	return (
		<FormNumberField<TModel>
			{...props}
			decimalScale={decimalScale}
			startAdornment={<span>&euro;</span>}
			allowNegative={sAllowNegative}
		/>
	);
};
