import { DialogProps } from '@material-ui/core';
import { IInventoryItem, inventoryItemsCommand_patchArticleProperties } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { ArticlePropertiesForm } from './ArticlePropertiesForm';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	item: IInventoryItem;
}

export const ArticlePropertiesForInventoryItemForm = ({ item, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<ArticlePropertiesForm
			{...rest}
			apds={item.productCatalogData.articlePropertyDefinitions ?? []}
			aps={item.articlePropertiesX}
			submitFunction={model => inventoryItemsCommand_patchArticleProperties({ inventoryItemId: item.id, articleProperties: model.lines })}
			title={`${strings.properties} | ${item.identifier}`}
		/>
	);
};
