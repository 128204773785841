import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IAssignedOrInquiredArticleDto, Orientation, patientAssignedArticlesCommand_patchOrientation } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import * as yup from 'yup';

type OrientationOrEmpty = Orientation | '';

interface IModel {
	orientation: string;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			orientation: yup.string().defined(),
		})
		.defined();
};

interface IProps extends DialogProps {
	item: IAssignedOrInquiredArticleDto;
	patientId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchOrientationOfAssignedOrInquiredArticleForm = ({ item, patientId, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [patch, isPatching] = useFormSubmit(patientAssignedArticlesCommand_patchOrientation);
	const { orientationRecord } = useContext(RecordContext);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await patch({ patientId: patientId, articleId: item.id, orientation: values.orientation });
		handleValidateResponse<IModel>(r, helpers, 'orientation');
		if (r.isSuccess) {
			confirm();
		}
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{ orientation: item.orientation ?? '' }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					scroll='paper'
					fullWidth
					{...rest}>
					<DialogTitle>{strings.orientation}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormExtendedRadioGroupField<IModel, OrientationOrEmpty>
							name='orientation'
							label={strings.orientation}
							options={[
								{ value: 'Left', label: orientationRecord['Left'] },
								{ value: 'Right', label: orientationRecord['Right'] },
								{ value: '', label: strings.notApplicable },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isPatching}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
