import { DialogProps } from '@material-ui/core';
import React, { useContext } from 'react';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IFile } from 'framework/utils/IFile';
import { documentsCommand_replace } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from '../../records/RecordContext';
import { IDocumentX } from './IDocumentX';
import { ScanOrUploadFileDialog } from './ScanOrUploadFileDialog';

interface IProps extends DialogProps {
	document: IDocumentX;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const ReplaceDocumentDialog = ({ document, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const { translateDocumentType } = useContext(RecordContext);
	const [replace, isUploading] = useFormSubmit(documentsCommand_replace);

	const onFile = async (file: IFile, withSignature: boolean = false) => {
		const r = await replace({ documentId: document.id, withSignature: withSignature, contentAsBase64: file.contentAsBase64, fileName: file.fileName });
		if (r.isSuccess) {
			confirm();
		}
	};

	return (
		<ScanOrUploadFileDialog
			{...rest}
			confirm={onFile}
			isUploading={isUploading}
			accept='scanOrUpload'
			withSignature={document.mustSign}
			title={strings.replaceWhat(translateDocumentType(document))}
		/>
	);
};
