import { Divider, Grid, useTheme } from '@material-ui/core';
import React from 'react';

interface IProps {
	style?: React.CSSProperties;
	dividerStyle?: React.CSSProperties;
}

export const GridDivider = ({ style, dividerStyle }: IProps) => {
	const theme = useTheme();

	return (
		<Grid
			item
			xs={12}
			style={style}>
			<Divider style={{ marginLeft: 0, marginRight: 0, marginTop: theme.spacing(1), marginBottom: theme.spacing(1), ...dividerStyle }} />
		</Grid>
	);
};
