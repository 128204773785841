import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ICommissionPayout } from 'gen/ApiClient';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (colRecord: Record<SelectableColumn, string>): Record<SelectableColumn, IHeader<ICommissionPayout>> => {
	return {
		created: createSortableHeaderWithKey<ICommissionPayout, SelectableColumn>(colRecord, 'created', t => formatDate(t.created)),
		userIdentifier: createSortableHeaderWithKey<ICommissionPayout, SelectableColumn>(colRecord, 'userIdentifier', t => t.userIdentifier),
		value: createSortableHeaderWithKey<ICommissionPayout, SelectableColumn>(colRecord, 'value', t => formatCurrency(t.value)),
		payoutDate: createSortableHeaderWithKey<ICommissionPayout, SelectableColumn>(colRecord, 'payoutDate', t => formatDate(t.payoutDate)),
		reference: createSortableHeaderWithKey<ICommissionPayout, SelectableColumn>(colRecord, 'reference', t => t.reference),
	};
};
