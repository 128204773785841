import { useField } from 'formik';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { FormAutocompleteFieldFromStrings } from 'framework/forms/FormAutocompleteFieldFromStrings';
import { nameOf } from 'framework/utils/nameOf';
import { IObjectWithIdAndIdentifier } from 'gen/ApiClient';
import React, { useEffect } from 'react';
import { useLocalizedHealthInsuranceFundsCache } from 'shared/context/useLocalizedHealthInsuranceFundsCache';

const toOptionLabel = (id: string, data: IObjectWithIdAndIdentifier<string>[]) => {
	const f = data.find(t => t.id === id);
	return f ? `${f.id} - ${f.identifier}` : '';
};

export const FormSelectHealthInsuranceFundField = <TModel extends unknown>({ name, label, ...rest }: IFormProps<TModel>) => {
	// eslint-disable-next-line
	const [field, meta, helpers] = useField<string>(nameOf(name));
	const data = useLocalizedHealthInsuranceFundsCache();

	useEffect(() => {
		if (data && field.value) {
			const f = data.find(t => t.id === field.value);
			if (f === undefined) {
				helpers.setValue('');
			}
		}
		// eslint-disable-next-line
	}, [data, field.value]);

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<FormAutocompleteFieldFromStrings<TModel>
			name={name}
			label={label}
			options={data.map(t => t.id)}
			renderOption={id => toOptionLabel(id, data)}
			{...rest}
		/>
	);
};
