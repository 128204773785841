import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		manufacturer: strings.manufacturer,
		model: strings.model,
		category: strings.category,
		subcategory: strings.subcategory,
		purchasePrice: strings.purchasePrice,
		salesPrice: strings.salesPrice,
		vatPercentage: strings.vatPercentage,
		firstDeliveryDate: strings.deliveryDate,
		purchaseDate: strings.purchaseDate,
		returnToSupplierDate: strings.returnDate,
		dateSold: strings.salesDate,
		hasBatteries: strings.hasBatteries,
		isInRepair: `${strings.inForRepair}?`,
		isElectronics: `${strings.isElectronics}?`,
		isHearingInstrument: `${strings.hearingAid}?`,
		isRemoteControl: `${strings.remoteDevice}?`,
		isPurchased: `${strings.statusPurchased}?`,
		isReturnedToSupplier: `${strings.returnedToSupplier}?`,
		isSold: `${strings.statusSold}?`,
		isTailorMade: strings.tailorMadeProducts,
		patientIdentifier: strings.patient,
		supplierIdentifier: strings.supplier,
		productCatalogId: strings.catalogNumber,
		serialNumber: strings.serialNumber,
		type: strings.type,
		countTransactions: `# ${strings.transactions}`,
		currentLocationIdentifier: strings.currentLocation,
		propertyOf: strings.propertyOf,
	};
};
