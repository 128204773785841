import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IDailyReceiptsQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { LocationsPreloadCacheContext } from '../../settings/locations/LocationsPreloadCacheContext';
import { ViewContext } from './context/ViewContext';

interface IProps {
	params: IDailyReceiptsQueryParams;
	onFilter: (params: IDailyReceiptsQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof IDailyReceiptsQueryParams)[] = ['isGlobal'];

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const { quickFilter } = useContext(ViewContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.date}
				after={params.referenceDateAfter}
				before={params.referenceDateBefore}
				setSelected={(after, before) => onFilter({ ...params, referenceDateAfter: after, referenceDateBefore: before })}
			/>
			{quickFilter === 'location' && (
				<ListFilterComponent<string>
					options={locations}
					selected={params.locationIds}
					setSelected={vals => onFilter({ ...params, locationIds: vals })}
					label={strings.location}
					icon={<HelpOutlineIcon />}
					title={strings.location}
				/>
			)}
		</FilterBar>
	);
};
