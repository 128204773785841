import { Card, CardMedia, CardProps, Divider, useTheme } from '@material-ui/core';
import { TransactionTypeChip } from 'app/main/inventoryManagement/inventoryItems/detail/TransactionTypeChip';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { createHeader } from 'framework/table/createHeader';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { combineClassNames } from 'framework/utils/combineClassNames';
import { ITransactionDto, ITransactionsQueryParams, LinkViewContextType, transactionsQuery_queryForProductCatalog } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { DetailsNavigateButton } from 'shared/DetailsNavigateButton';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { LinkViewContextNavigationRecord } from 'shared/records/XLinkViewContextNavigationRecord';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { SelectableColumn } from './SelectableColumn';

const DefaultParams: ITransactionsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	types: [],
};

const routeOrDefault = (t: ITransactionDto): string | undefined => {
	if (t.linkViewContextId) {
		const navF = LinkViewContextNavigationRecord[t.linkViewContextType as LinkViewContextType];
		return navF === undefined ? '' : navF(t.linkViewContextId);
	} else {
		return undefined;
	}
};

interface IProps extends IReloadTriggerProps, CardProps {
	productCatalogId: string;
}

export const TransactionsCard = ({ productCatalogId, reloadTrigger, ...rest }: IProps) => {
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(
		pars => transactionsQuery_queryForProductCatalog(productCatalogId, pars),
		DefaultParams,
		reloadTrigger
	);
	const theme = useTheme();
	const { columns, setColumns, columnsRecord, headers } = useContext(ViewContext);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<Card
			{...rest}
			className={combineClassNames('fg1', rest.className)}>
			<div
				className='df-row-ac jc-sb'
				style={{ paddingRight: theme.spacing(1.5) }}>
				<CardTitleTypography
					withPadding
					title={strings.transactions}
				/>
			</div>
			<Divider />
			{queryResult.unfilteredCount > 0 && (
				<>
					<div className='df-row-ac jc-sb'>
						<SearchbarWithParams
							params={params}
							setParams={setParams}
							placeholder={strings.searchVerb}
							style={{ width: '400px' }}
							variant='embedded'
						/>
						<div>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
								view='productCatalogTransactions'
							/>
						</div>
					</div>
					<Divider />
					<div className='df-row jc-sb'>
						<Filter
							params={params}
							onFilter={params => setParams(onResetToPage1(params))}
							paddingLeft={theme.spacing(1.5)}
						/>
					</div>
					<Divider />
					<CardMedia>
						<PageableTableWithColumnSelection<ITransactionDto, SelectableColumn>
							queryResult={queryResult}
							headers={headers}
							sortParams={params.sortParams}
							pageParams={params.pageParams}
							setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
							getKey={inst => inst.id}
							selectedColumns={columns}
							appendColumn={createEmptyHeader<ITransactionDto>(
								'details-header',
								t => (
									<DetailsNavigateButton
										disabled={routeOrDefault(t) === ''}
										route={routeOrDefault(t)}
									/>
								),
								'right'
							)}
							prependColumn={createHeader<ITransactionDto>(strings.type, t => (
								<TransactionTypeChip type={t.type} />
							))}
						/>
					</CardMedia>
				</>
			)}
			{queryResult.unfilteredCount === 0 && (
				<NoRecordsFound style={{ padding: theme.spacing(1.5) }}>{strings.noWhatRegistered(strings.transactions)}</NoRecordsFound>
			)}
		</Card>
	);
};
