import React from 'react';
import { QueryProvider as SpsQueryProvider } from './sps/context/QueryProvider';
import { ViewProvider as SpsViewProvider } from './sps/context/ViewProvider';
import { QueryProvider as UnhandledQueryProvider } from './unhandled/context/QueryProvider';
import { ViewProvider as UnhandledViewProvider } from './unhandled/context/ViewProvider';
import { FilterDataProvider } from './FilterDataProvider';

export const SettlementProposalProviders = ({ children }: any) => {
	return (
		<SpsQueryProvider>
			<SpsViewProvider>
				<UnhandledQueryProvider>
					<UnhandledViewProvider>
						<FilterDataProvider>{children}</FilterDataProvider>
					</UnhandledViewProvider>
				</UnhandledQueryProvider>
			</SpsViewProvider>
		</SpsQueryProvider>
	);
};
