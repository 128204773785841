import { Dialog, DialogProps, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { CommissionRuleMethod, commissionsCommand_delete, commissionsQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { calcSalesDetailRoute } from 'app/main/financial/routes';
import { RecordContext } from 'shared/records/RecordContext';
import { boolToYesNo } from 'shared/utils/boolToString';
import { SalesSummaryPaymentStatusAndCreditChip } from '../../sales/components/SalesSummaryPaymentStatusAndCreditChip';
import { CommissionPaidOutStatusChip } from './CommissionPaidOutStatusChip';
import { calculateCommissionF } from './utils/calculateCommissionF';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AlertDialog } from 'framework/dialogs/AlertDialog';

interface IProps extends DialogProps {
	id: string;
	close: VoidFunction;
}

export const CommissionDetailDialog = ({ id, close, ...rest }: IProps) => {
	const strings = useLocalization();
	const [item] = useApiEffect(commissionsQuery_single, id);
	const ruleMethod = useMemo(() => item?.ruleMethod as CommissionRuleMethod, [item]);
	const { push } = useHistory();
	const { commissionRuleMethodRecord } = useContext(RecordContext);
	const { open, cancel, isStacked } = useDialogsContext();

	if (item === undefined || ruleMethod === undefined) {
		return <div></div>;
	}

	const onViewDetail = () => {
		close();
		push(calcSalesDetailRoute(item.salesId));
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.commission)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => commissionsCommand_delete(item.id)}
				accept={() => {
					cancel();
					close();
				}}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			scroll='paper'
			fullWidth>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<ReceiptOutlinedIcon />}>
					<Typography variant='h1'>{`${strings.commission}: ${item.ruleName} (${item.salesReference})`}</Typography>
					<CommissionPaidOutStatusChip
						item={item}
						style={{ marginTop: 8, marginBottom: 8 }}
					/>
					<LabelledProperty
						label={strings.date}
						property={formatDate(item.created)}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.user}
						property={item.userIdentifier}
						withoutMargin
					/>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								startIcon={<OpenInNewIcon />}
								onClick={onViewDetail}>
								{strings.navigateToDetailsOfSale}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								startIcon={<DeleteOutlineOutlinedIcon />}
								color='secondary'
								onClick={onDelete}
								disabled={item.isPaidOut}>
								{strings.delete}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
				<GridDividerWithCaption caption={strings.sale} />
				<PropertyInGrid
					label={strings.reference}
					value={item.salesReference}
				/>
				<PropertyInGrid
					label={strings.date}
					value={formatDate(item.sale.date)}
				/>
				<PropertyInGrid
					label={strings.withRespectToShort}
					value={item.salesRecipientName}
				/>
				<PropertyInGrid
					label={strings.paymentStatus}
					value={<SalesSummaryPaymentStatusAndCreditChip item={item.sale} />}
				/>
				<PropertyInGrid
					label={`${strings.payedByPatient}?`}
					value={boolToYesNo(item.isPaidByPatientOrCompany, strings)}
				/>
				<PropertyInGrid
					label={`${strings.payedByHifShort}?`}
					value={boolToYesNo(item.isPaidByHif, strings)}
				/>
				<GridDividerWithCaption caption={strings.calculation} />
				<PropertyInGrid
					label={`${strings.name} (%)`}
					value={`${item.ruleName} (${item.rulePercentage} %)`}
				/>
				<PropertyInGrid
					label={strings.method}
					value={commissionRuleMethodRecord[ruleMethod]}
				/>
				{ruleMethod === 'TotalValueOfDevices' && (
					<PropertyInGrid
						label={strings.totalPriceDevicesExVat}
						value={formatCurrency(item.totalValueExVatOfDevices)}
					/>
				)}
				{ruleMethod === 'TotalValueOfSale' && (
					<PropertyInGrid
						label={strings.priceExVat}
						value={formatCurrency(item.totalValueExVatOfSale)}
					/>
				)}
				<PropertyInGrid
					label={strings.commission}
					value={formatCurrency(calculateCommissionF(item))}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
