import { useCallback, useContext, useState } from 'react';
import { ApiContext } from 'framework/context/ApiContext';
import { useApiErrorHandling } from 'framework/hooks/useApiErrorHandling';
import { Try } from 'framework/Try';
import { fileResponseInterceptor } from 'framework/xhr/fileResponseInterceptor';
import { IFileResponse } from './ApiClient';
import { DefaultAxiosInstance } from './DefaultAxiosInstance';

export function useDownload<TArgs extends any[]>(
	callback: (...args: TArgs) => Promise<Try<IFileResponse>>,
	setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
): [(...args: TArgs) => Promise<Try<IFileResponse>>, boolean, React.Dispatch<React.SetStateAction<boolean>>] {
	const [isLoading, setInternalLoading] = useState<boolean>(false);
	const apiContext = useContext(ApiContext);
	const handleError = useApiErrorHandling(apiContext.strings, apiContext.redirect, apiContext.notify, apiContext.setMustUpdateFrontEnd);
	const cbSetIsLoading = useCallback(setIsLoading ?? setInternalLoading, [setIsLoading]);

	return [
		useCallback(
			async (...args: TArgs) => {
				console.log('enters callback');
				cbSetIsLoading(true);
				const instance = DefaultAxiosInstance();
				instance.interceptors.response.use(fileResponseInterceptor);
				const newArgs = [...args, undefined, instance];
				console.log(callback);
				const r = await callback(...(newArgs as any));
				if (r.isFailure) {
					handleError(r.error);
				}
				cbSetIsLoading(false);
				return r;
				// missing deps handleError -> is a function
			},
			// eslint-disable-next-line
			[callback, cbSetIsLoading]
		),
		isLoading,
		cbSetIsLoading,
	];
}

// export function useDownloadFile<TArgs>(callback: (args: TArgs, instance: AxiosInstance) => Promise<Try<IFileResponse>>, setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>):
//     [(args: TArgs) => Promise<Try<IFileResponse>>, boolean, React.Dispatch<React.SetStateAction<boolean>>] {

//     const [isLoading, setInternalLoading] = useState<boolean>(false);
//     const apiContext = useContext(ApiContext);
//     const handleError = useApiErrorHandling(apiContext.strings, apiContext.redirect, apiContext.showError);
//     const cbSetIsLoading = useCallback(setIsLoading ?? setInternalLoading, [setIsLoading]);

//     return [useCallback(async (args: TArgs) => {
//         cbSetIsLoading(true);
//         const instance = DefaultAxiosInstance();
//         instance.interceptors.response.use(fileResponseInterceptor);
//         const r = await callback(args, instance);
//         if (r.isFailure) {
//             handleError(r.error);
//         }
//         cbSetIsLoading(false);
//         return r;
//         // missing deps handleError -> is a function
//         // eslint-disable-next-line
//     }, [callback, cbSetIsLoading]), isLoading, cbSetIsLoading];
// }

// if ever overloading of this function needed -> use below
// export function useDownload<TArgs>(callBack: (args: TArgs, instance: AxiosInstance) => Promise<Try<IFileResponse>>): (args: TArgs) => Promise<Try<IFileResponse>>;
// export function useDownload<TArg1, TArg2>(callBack: (arg1: TArg1, arg2: TArg2, instance: AxiosInstance) => Promise<Try<IFileResponse>>): (arg1: TArg1, arg2: TArg2) => Promise<Try<IFileResponse>>;
// export function useDownload<TArg1, TArg2>(callBack: (arg1: TArg1, arg2: TArg2, instance: AxiosInstance) => Promise<Try<IFileResponse>>): (arg1: TArg1, arg2: TArg2) => Promise<Try<IFileResponse>> {
//     return async (arg1: TArg1, arg2: TArg2) => {
//         if (arg2 === undefined) {
//             return await callBack(arg1, DownloadAxiosInstance() as any, undefined as any);
//         } else {
//             return await callBack(arg1, arg2, DownloadAxiosInstance());
//         }
//     }
// }
