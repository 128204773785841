import {
	IInventoryItem,
	IProductCatalogItem,
	IProductCatalogItemSummaryForUblPurchase,
	IPurchaseUblAllowanceCharge,
	IPurchaseUblLine,
	IRegisterPurchaseLineFromUblRequest,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { INewLineModel } from 'shared/searchAndSelect/INewLineModel';
import { v1 as uuid } from 'uuid';
import { IAllowanceChargeFormLine } from './IAllowanceChargeFormLine';
import { IPurchaseFromUblFormLine } from './IPurchaseFromUblFormLine';
import { IPurchaseFromUblFormLineError } from './IPurchaseFromUblFormLineError';
import { PurchaseUblLinkStatus } from './UblPurchaseLineLinkStatus';

export const calculateAllowanceChargePurchaseUblLinkStatus = (line: IAllowanceChargeFormLine): PurchaseUblLinkStatus => {
	if (line.productCatalogItem !== undefined && line.productCatalogItem !== null) {
		return 'linked';
	} else if (line.isIgnored) {
		return 'ignored';
	} else {
		return 'not-linked';
	}
};

export const calculateLinePrice = (line: IPurchaseFromUblFormLine): number => {
	return line.quantity * (line.unitPrice + line.allowanceCharges.map(t => t.unitPrice).reduce((sum, current) => sum + current, 0));
};

export const calculateMustAlsoIgnoreAllowanceLine = (line: IAllowanceChargeFormLine, ignoredLine: IAllowanceChargeFormLine): boolean => {
	return (
		(line.productCatalogItem === undefined || line.productCatalogItem === null) && line.isMappedFromUbl === true && line.description === ignoredLine.description
	);
};

export const calculateMustAlsoIgnoreLine = (line: IPurchaseFromUblFormLine, ignoredLine: IPurchaseFromUblFormLine): boolean => {
	return (
		(line.productCatalogItem === undefined || line.productCatalogItem === null) &&
		line.ublData !== undefined &&
		ignoredLine.ublData !== undefined &&
		line.ublData.manufacturerReference === ignoredLine.ublData.manufacturerReference
	);
};

export const calculateMustAlsoLink = (line: IPurchaseFromUblFormLine, linkedLine: IPurchaseFromUblFormLine): boolean => {
	return (
		(line.productCatalogItem === undefined || line.productCatalogItem === null) &&
		line.ublData !== undefined &&
		linkedLine.ublData !== undefined &&
		line.ublData.isIgnored === false &&
		line.ublData.manufacturerReference === linkedLine.ublData.manufacturerReference
	);
};

export const calculateMustAlsoLinkAllowanceLine = (line: IAllowanceChargeFormLine, linkedLine: IAllowanceChargeFormLine): boolean => {
	return (
		(line.productCatalogItem === undefined || line.productCatalogItem === null) &&
		line.isMappedFromUbl === true &&
		linkedLine.isMappedFromUbl === true &&
		line.isIgnored === false &&
		line.description === linkedLine.description
	);
};

export const calculatePurchaseUblLinkStatus = (line: IPurchaseFromUblFormLine): PurchaseUblLinkStatus => {
	if (Boolean(line.productCatalogItem)) {
		return 'linked';
	} else if (line.ublData !== undefined && line.ublData.isIgnored === false) {
		return 'not-linked';
	} else {
		return 'ignored';
	}
};

export const mapInventoryItemToPurchaseFromUblFormLine = (t: IInventoryItem, pc: IProductCatalogItem, isCredit: boolean): IPurchaseFromUblFormLine => {
	return {
		id: t.id,
		description: t.identifier,
		inventoryItemId: t.id,
		quantity: isCredit ? -1 : 1,
		vatPercentage: t.vatPercentage,
		productCatalogItem: pc,
		serialNumber: t.serialNumber ?? '',
		unitPrice: pc.purchaseListPrice,
		isCreditLine: isCredit,
		allowanceCharges: [],
		ublData: undefined,
	};
};

export const mapAllowanceChargeToFormLine = (line: IPurchaseUblAllowanceCharge): IAllowanceChargeFormLine => {
	return {
		id: uuid(),
		description: line.description ?? '',
		isCharge: line.isCharge,
		productCatalogItem: line.productCatalogItem,
		unitPrice: line.unitAmount,
		isMappedFromUbl: true,
		isIgnored: false,
		ublDescription: line.description ?? '',
	};
};

export const mapFormLineToRequest = (line: IPurchaseFromUblFormLine, index: number, isShiftVat: boolean): IRegisterPurchaseLineFromUblRequest => {
	return {
		zeroBasedIndexNumber: index,
		productCatalogItemId: line.productCatalogItem?.id,
		quantity: line.quantity,
		unitPriceExVat: line.unitPrice,
		vatPercentage: isShiftVat ? 0 : line.vatPercentage,
		description: line.description,
		serialNumber: line.serialNumber,
		allowanceCharges: line.allowanceCharges.map((t, acIndex) => ({
			zeroBasedIndexNumber: acIndex,
			description: t.description,
			isCharge: t.isCharge,
			productCatalogItemId: t.productCatalogItem?.id,
			unitPriceExVat: t.unitPrice,
			isIgnored: t.productCatalogItem !== undefined && t.productCatalogItem !== null ? false : t.isIgnored,
		})),
		isIgnored: line.productCatalogItem !== undefined && line.productCatalogItem !== null ? false : line.ublData?.isIgnored ?? false,
	};
};

export const mapIgnoreAllowanceLine = (line: IPurchaseFromUblFormLine, ignoredLine: IAllowanceChargeFormLine): IPurchaseFromUblFormLine => {
	return {
		...line,
		allowanceCharges: line.allowanceCharges.map(t => (calculateMustAlsoIgnoreAllowanceLine(t, ignoredLine) ? { ...t, isIgnored: true } : t)),
	};
};

export const mapIgnorePurchaseLine = (line: IPurchaseFromUblFormLine, ignoredLine: IPurchaseFromUblFormLine): IPurchaseFromUblFormLine => {
	if (line.ublData === undefined || calculateMustAlsoIgnoreLine(line, ignoredLine) === false) {
		return line;
	} else {
		return { ...line, ublData: { ...line.ublData, isIgnored: true } };
	}
};

export const mapLinkAllowanceLine = (
	line: IPurchaseFromUblFormLine,
	linkedLine: IAllowanceChargeFormLine,
	pc: IProductCatalogItemSummaryForUblPurchase
): IPurchaseFromUblFormLine => {
	return {
		...line,
		allowanceCharges: line.allowanceCharges.map(t => (calculateMustAlsoLinkAllowanceLine(t, linkedLine) ? { ...t, productCatalogItem: pc } : t)),
	};
};

export const mapLinkPurchaseLine = (
	line: IPurchaseFromUblFormLine,
	linkedLine: IPurchaseFromUblFormLine,
	pc: IProductCatalogItemSummaryForUblPurchase
): IPurchaseFromUblFormLine => {
	if (line.ublData === undefined || calculateMustAlsoLink(line, linkedLine) === false) {
		return line;
	} else {
		return { ...line, productCatalogItem: pc };
	}
};

export const mapProductCatalogItemToProductCatalogItemSummaryForUblPurchase = (pc: IProductCatalogItem): IProductCatalogItemSummaryForUblPurchase => {
	return {
		id: pc.id,
		isUniquelyIdentifiable: pc.isUniquelyIdentifiable,
		manufacturerReferences: pc.manufacturerReferences,
		purchaseListPrice: pc.purchaseListPrice,
		type: pc.type,
		vatPercentage: pc.vatPercentage,
		identifier: pc.identifier,
	};
};

export const mapPurchaseUblLineToFormLine = (line: IPurchaseUblLine): IPurchaseFromUblFormLine => {
	return {
		id: line.ublLineId!,
		description: line.description ?? '',
		allowanceCharges: line.allowanceCharges.map(mapAllowanceChargeToFormLine),
		productCatalogItem: line.productCatalogItem,
		quantity: line.quantity,
		isCreditLine: line.quantity < 0 ? true : false,
		serialNumber: line.serialNumber ?? '',
		unitPrice: line.unitAmount,
		vatPercentage: line.vatPercentage,
		inventoryItemId: line.inventoryItemId,
		ublData: { id: line.ublLineId!, manufacturerReference: line.manufacturerReference!, isIgnored: false },
	};
};

export const mapNewLineToPurchaseFromUblFormLine = (t: INewLineModel): IPurchaseFromUblFormLine => {
	return {
		id: t.id,
		description: t.description,
		quantity: 1,
		vatPercentage: t.vatPercentage,
		inventoryItemId: undefined,
		productCatalogItem: undefined,
		unitPrice: t.unitListPrice,
		serialNumber: '',
		isCreditLine: t.unitListPrice < 0,
		allowanceCharges: [],
		ublData: undefined,
	};
};

export const mapProductCatalogItemToPurchaseFromUblFormLine = (t: IProductCatalogItem, isCredit: boolean): IPurchaseFromUblFormLine => {
	return {
		id: `${t.isUniquelyIdentifiable ? uuid() : t.id}-${isCredit ? 'CREDIT' : 'PURCHASE'}`,
		description: t.identifier,
		quantity: isCredit ? -1 : 1,
		unitPrice: t.purchaseListPrice,
		vatPercentage: t.vatPercentage,
		productCatalogItem: t,
		serialNumber: '',
		isCreditLine: isCredit,
		allowanceCharges: [],
		inventoryItemId: undefined,
		ublData: undefined,
	};
};

export const mapProductCatalogItemToAllowanceChargeFormLine = (pc: IProductCatalogItem): IAllowanceChargeFormLine => {
	return {
		description: pc.identifier,
		id: uuid(),
		isCharge: true,
		isIgnored: false,
		isMappedFromUbl: false,
		productCatalogItem: mapProductCatalogItemToProductCatalogItemSummaryForUblPurchase(pc),
		ublDescription: undefined,
		unitPrice: pc.purchaseListPrice,
	};
};

export const validateLines = (lines: IPurchaseFromUblFormLine[], strings: IStrings): IPurchaseFromUblFormLineError[] => {
	let out: IPurchaseFromUblFormLineError[] = [];
	lines.forEach((line, index) => {
		if (line.ublData !== undefined) {
			if ((line.productCatalogItem === undefined || line.productCatalogItem === null) && line.ublData.isIgnored === false) {
				out = [...out, { index: index + 1, error: strings.ignoreOrLinkWithAnArticleFromProductCatalog }];
			}
		}
		line.allowanceCharges.forEach((ac, acIndex) => {
			if (ac.isMappedFromUbl === true) {
				if ((ac.productCatalogItem === undefined || ac.productCatalogItem === null) && ac.isIgnored === false) {
					out = [...out, { index: index + 1, allowanceChargeIndex: acIndex + 1, error: strings.ignoreAllowanceOrLinkWithArticleFromProductCatalog }];
				}
			}
		});
	});
	out = out.sort(t => t.index);
	return out;
};

export const mapIgnoreUnlinkedLineAndAllowances = (line: IPurchaseFromUblFormLine): IPurchaseFromUblFormLine => {
	if (line.ublData === undefined) {
		return line;
	} else {
		return {
			...line,
			ublData: { ...line.ublData, isIgnored: line.productCatalogItem === undefined || line.productCatalogItem === null ? true : line.ublData.isIgnored },
			allowanceCharges: line.allowanceCharges.map(mapIgnoreAllowanceCharge),
		};
	}
};

export const mapIgnoreAllowanceCharge = (line: IAllowanceChargeFormLine): IAllowanceChargeFormLine => {
	if (line.isMappedFromUbl === false || (line.productCatalogItem !== undefined && line.productCatalogItem !== null)) {
		return line;
	} else {
		return { ...line, isIgnored: true };
	}
};
