import React from 'react';
import { RosaCalendarProvider } from './RosaCalendarProvider';
import { RosaCalendarQueryProvider } from './RosaCalendarQueryProvider';
import { RosaViewPreferencesPreloadCacheProvider } from './viewPreferences/RosaViewPreferencesPreloadCacheProvider';

export const RosaCalendarProviders = ({ children }: any) => {
	return (
		<RosaViewPreferencesPreloadCacheProvider>
			<RosaCalendarQueryProvider>
				<RosaCalendarProvider>{children}</RosaCalendarProvider>
			</RosaCalendarQueryProvider>
		</RosaViewPreferencesPreloadCacheProvider>
	);
};
