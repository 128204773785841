import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import {
	CurrentLocationType,
	CurrentLocationTypes,
	IInventoryItemSearchData,
	IInventoryItemsQueryParams,
	InventoryStatus,
	InventoryStatuses,
	PropertyOfType,
	PropertyOfTypes,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { LocationsPreloadCacheContext } from '../../../../settings/locations/LocationsPreloadCacheContext';
import { InventoryItemsFilterMoreDialog } from '../../../inventoryItems/InventoryItemsFilterMoreDialog';

interface IProps {
	params: IInventoryItemsQueryParams;
	onFilter: (params: IInventoryItemsQueryParams) => void;
	paddingLeft: number;
	data: IInventoryItemSearchData;
}

// TODO fix this, is this correct?
const exclusions: (keyof IInventoryItemsQueryParams)[] = ['keyFilterType'];

// TODO add filter on Type!
export const Filter = ({ params, onFilter, paddingLeft, data }: IProps) => {
	const strings = useLocalization();
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const { open, confirm, cancel } = useDialogsContext();
	const { currentLocationTypeRecord, inventoryStatusRecord, propertyOfTypeRecord } = useContext(RecordContext);

	if (data === undefined) {
		return <div></div>;
	}

	const onFilterMore = () => {
		open(
			<InventoryItemsFilterMoreDialog
				open
				params={params}
				onFilter={pars => {
					onFilter(pars);
					confirm();
				}}
				cancel={cancel}
				data={data}
				forProductCatalog
			/>
		);
	};

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}
			onMore={onFilterMore}>
			<RecordListFilterComponent<InventoryStatus>
				record={inventoryStatusRecord}
				options={InventoryStatuses}
				selected={params.statuses as any}
				setSelected={vals => onFilter({ ...params, statuses: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.status}
			/>
			<RecordListFilterComponent<CurrentLocationType>
				record={currentLocationTypeRecord}
				selected={params.locationTypes as any}
				setSelected={vals => onFilter({ ...params, locationTypes: vals })}
				options={CurrentLocationTypes}
				icon={<HelpOutlineIcon />}
				label={strings.currentLocation}
			/>
			<RecordListFilterComponent<PropertyOfType>
				record={propertyOfTypeRecord}
				selected={params.propertyOfTypes as any}
				setSelected={vals => onFilter({ ...params, propertyOfTypes: vals })}
				options={PropertyOfTypes}
				icon={<HelpOutlineIcon />}
				label={strings.propertyOf}
			/>
			<ListFilterComponent<string>
				options={locations}
				selected={params.atLocationIds}
				setSelected={vals => onFilter({ ...params, atLocationIds: vals })}
				label={strings.location}
				icon={<HelpOutlineIcon />}
				title={strings.locationsCurrentlyOnStock}
			/>
			<BooleanRadioFilterComponent
				trueText={strings.statusSold}
				falseText={strings.statusNotSold}
				selected={params.isSold}
				setSelected={val => onFilter({ ...params, isSold: val })}
				label={`${strings.statusSold}?`}
				icon={<HelpOutlineIcon />}
			/>
			<DateFilterItem
				label={strings.salesDate}
				before={params.soldBefore}
				after={params.soldAfter}
				setSelected={(after, before) => onFilter({ ...params, soldAfter: after, soldBefore: before })}
			/>
			<BooleanRadioFilterComponent
				trueText={strings.statusPurchased}
				falseText={strings.statusNotPurchased}
				selected={params.isPurchased}
				setSelected={val => onFilter({ ...params, isPurchased: val })}
				label={`${strings.statusPurchased}?`}
				icon={<HelpOutlineIcon />}
			/>
			<BooleanRadioFilterComponent
				trueText={strings.demo}
				falseText={strings.noDemoRegular}
				selected={params.isDemo}
				setSelected={val => onFilter({ ...params, isDemo: val })}
				label={`${strings.demo}?`}
				icon={<HelpOutlineIcon />}
			/>
			<BooleanRadioFilterComponent
				trueText={strings.statusReserved}
				falseText={strings.statusNotReserved}
				selected={params.isReserved}
				setSelected={val => onFilter({ ...params, isReserved: val })}
				label={`${strings.statusReserved}?`}
				icon={<HelpOutlineIcon />}
			/>
		</FilterBar>
	);
};
