import { Card, Divider, useTheme } from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { InventoryItemForm } from 'app/main/inventoryManagement/inventoryItems/forms/InventoryItemForm';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { createHeader } from 'framework/table/createHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { PageableTable } from 'framework/table/PageableTable';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import {
	IInventoryItem,
	IInventoryItemsQueryParamsForRepair,
	inventoryItemsQuery_queryForRepair,
	inventoryItemsQuery_queryForRepairForPatient,
	inventoryItemsQuery_single,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { createSelectHeader } from 'shared/table/createSelectHeader';
import { InventoryItemChips } from '../../../inventoryManagement/inventoryItems/components/InventoryItemChips';
import { InventoryItemsFilterDataContext } from '../../../inventoryManagement/inventoryItems/context/InventoryItemsFilterDataContext';
import { FilterInventoryItems } from './FilterInventoryItems';

const DefaultParams: IInventoryItemsQueryParamsForRepair = {
	categories: [],
	subcategories: [],
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	isShowAll: false,
};

interface IProps {
	supplierId: string;
	patientId: string | undefined;
	selectItem: (item: IInventoryItem) => void;
}

export const SelectInventoryItem = ({ supplierId, patientId, selectItem }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const [params, setParams, queryResult] = useApiEffectWithParams(
		pars => (patientId ? inventoryItemsQuery_queryForRepairForPatient(supplierId, patientId, pars) : inventoryItemsQuery_queryForRepair(supplierId, pars)),
		DefaultParams
	);
	const [searchData] = useCacheContext(InventoryItemsFilterDataContext);
	const { open, confirm, cancel } = useDialogsContext();
	const load = useApiCall(inventoryItemsQuery_single);

	const onFilter = (params: IInventoryItemsQueryParamsForRepair) => {
		setParams(onResetToPage1(params));
	};

	const headers = [
		createSortableHeader<IInventoryItem>(strings.name, 'identifier', t => t.identifier),
		createSortableHeader<IInventoryItem>(strings.serialNumber, 'serialNumber', t => t.serialNumber),
		createSortableHeader<IInventoryItem>(strings.patient, 'patientIdentifier', t => t.patientIdentifier),
		createSortableHeader<IInventoryItem>(strings.supplier, 'supplierIdentifier', t => t.supplierIdentifier),
		createHeader<IInventoryItem>(strings.status, t => <InventoryItemChips item={t} />),
		createSelectHeader<IInventoryItem>(
			t => selectItem(t),
			t => t.canSelectForRepair === false
		),
	];

	if (searchData === undefined || queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<InventoryItemForm
				confirm={onConfirm}
				cancel={cancel}
			/>
		);
	};

	const onConfirm = async (id: string) => {
		const r = await load(id);
		if (r.isSuccess) {
			selectItem(r.result);
		}
		confirm();
	};

	return (
		<Card
			variant='outlined'
			className='df-col'>
			<div className='df-row-ac jc-sb'>
				<FilterInventoryItems
					params={params}
					onFilter={onFilter}
					data={searchData}
				/>
				<div className='df-row-ac'>
					<TooltipWithSpan title={strings.itemNotFoundQuestionClickHereToDefineNewSerialNumber}>
						<SmallPrimaryTextButton
							startIcon={<PlaylistAddIcon />}
							color={queryResult.filteredCount === 0 ? 'secondary' : 'primary'}
							onClick={onNew}>
							{strings.new}
						</SmallPrimaryTextButton>
					</TooltipWithSpan>
					<FormControlLabelSwitch
						style={{ marginLeft: 12 }}
						isChecked={params.isShowAll}
						toggle={() => onFilter({ ...params, isShowAll: !params.isShowAll })}
						label={strings.viewAll}
					/>
				</div>
			</div>
			<Divider />
			<SearchbarWithParams
				params={params}
				setParams={setParams}
				placeholder={strings.searchVerb}
				variant='embedded'
			/>
			<Divider />
			{queryResult.filteredCount === 0 ? (
				<NoRecordsFound withPadding>{strings.noResultsCommaChangeFilterParams}</NoRecordsFound>
			) : (
				<PageableTable<IInventoryItem>
					padding={theme.spacing(1)}
					headers={headers}
					getKey={t => t.id}
					pageParams={params.pageParams}
					sortParams={params.sortParams}
					queryResult={queryResult}
					setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
				/>
			)}
		</Card>
	);
};
