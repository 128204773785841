import { IStrings } from 'localization/IStrings';
import { SettingsTabType } from './SettingsTabType';

export const createSettingsTabTypeRecord = (strings: IStrings): Record<SettingsTabType, string> => {
	return {
		general: strings.general,
		templates: strings.templates,
		users: strings.users,
	};
};
