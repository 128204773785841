import { PreloadCacheProvider as FrameworkCacheProvider } from 'framework/context/PreloadCacheProvider';
import { ILocationSummary, locationsQuery_cache, locationsQuery_concurrencyToken } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { PreloadContext } from 'shared/context/PreloadContext';
import { LocationsPreloadCacheContext } from './LocationsPreloadCacheContext';

export const LocationsPreloadCacheProvider = ({ children }: any) => {
	const { locations } = useContext(PreloadContext);

	return (
		<FrameworkCacheProvider<ILocationSummary[]>
			concurrencyF={locationsQuery_concurrencyToken}
			loadF={locationsQuery_cache}
			context={LocationsPreloadCacheContext}
			initialData={locations}>
			{children}
		</FrameworkCacheProvider>
	);
};
