import { ICalendarEventDto, ICalendarResourceDto } from 'gen/ApiClient';
import React from 'react';
import { AgendaColorScheme } from '../AgendaColorScheme';
import { AgendaGroupScheme } from '../AgendaGroupScheme';
import { AgendaTimeScale } from '../AgendaTimeScale';
import { AgendaViewType } from '../AgendaViewType';

interface IContext {
	init: VoidFunction;
	reload: VoidFunction;
	date: Date;
	setDate: (val: Date) => void;
	view: AgendaViewType;
	setView: React.Dispatch<React.SetStateAction<AgendaViewType>>;
	colorScheme: AgendaColorScheme;
	setColorScheme: React.Dispatch<React.SetStateAction<AgendaColorScheme>>;
	groupScheme: AgendaGroupScheme;
	setGroupScheme: React.Dispatch<React.SetStateAction<AgendaGroupScheme>>;
	selectedEventTypeResourceKeys: string[];
	setSelectedEventTypeResourceKeys: React.Dispatch<React.SetStateAction<string[]>>;
	selectedUserResourceKeys: string[];
	setSelectedUserResourceKeys: React.Dispatch<React.SetStateAction<string[]>>;
	selectedRoomResourceKeys: string[];
	setSelectedRoomResourceKeys: React.Dispatch<React.SetStateAction<string[]>>;
	rooms: ICalendarResourceDto[];
	users: ICalendarResourceDto[];
	eventTypes: ICalendarResourceDto[];
	events: ICalendarEventDto[];
	beforeDate: Date;
	timeScale: AgendaTimeScale;
	setTimeScale: React.Dispatch<React.SetStateAction<AgendaTimeScale>>;
}

export const AgendaContext = React.createContext<IContext>(undefined as any);
