import React from 'react';
import { commissionsQuery_concurrencyToken, commissionsQuery_query, ICommission, ICommissionsQueryParams, IQueryResult } from 'gen/ApiClient';
import { AllQueryContext } from './AllQueryContext';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { allCommissionsQueryParamsLsKey } from '../../../../../localStorageKeys';
import { DefaultParams } from './DefaultParams';

export const AllQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<ICommission>, ICommissionsQueryParams>
			defaultParams={DefaultParams}
			queryF={commissionsQuery_query}
			concurrencyF={commissionsQuery_concurrencyToken}
			localStorageKey={allCommissionsQueryParamsLsKey}
			context={AllQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
