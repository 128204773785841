import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { LoansPrefilter, LoansPrefilters } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	selectedPrefilter: LoansPrefilter;
	setSelectedPrefilter: (key: LoansPrefilter) => void;
	style?: React.CSSProperties;
}

export const LoansListPrefilter = ({ selectedPrefilter, setSelectedPrefilter, style }: IProps) => {
	// const endIconRecord = useMemo(() => createOrderPrefilterBadgeChipXRecord(metadata), [metadata]);
	const { loansPrefilterKeyTypeRecord } = useContext(RecordContext);

	return (
		<div
			className='df-col'
			style={style}>
			{LoansPrefilters.map(prefilter => (
				<RadioIsSelectedButton
					key={prefilter}
					isSelected={prefilter === selectedPrefilter}
					onClick={() => setSelectedPrefilter(prefilter)}
					// endIcon={endIconRecord[prefilterKeyType]}
				>
					{loansPrefilterKeyTypeRecord[prefilter]}
				</RadioIsSelectedButton>
			))}
		</div>
	);
};
