import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IStrings } from 'localization/IStrings';
import { IPurchaseWithSelection } from '../../../purchases/IPurchaseWithSelection';

export const createHeaders = (
	lines: IPurchaseWithSelection[],
	setLines: React.Dispatch<React.SetStateAction<IPurchaseWithSelection[]>>,
	strings: IStrings
): IHeader<IPurchaseWithSelection>[] => {
	return [
		createSelectionHeader<IPurchaseWithSelection>(lines, setLines),
		createSortableHeader<IPurchaseWithSelection>(strings.date, 'created', t => formatDate(t.created)),
		createSortableHeader<IPurchaseWithSelection>(strings.reference, 'reference', t => t.reference),
		createSortableHeader<IPurchaseWithSelection>(strings.externalReference, 'externalReference', t => t.externalReference),
		createSortableHeader<IPurchaseWithSelection>(strings.outstandingBalance, 'outstandingBalance', t => formatCurrency(t.outstandingBalance)),
	];
};
