import { Typography, useTheme } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';

export const NoAccessPage = () => {
	const strings = useLocalization();
	const theme = useTheme();

	return (
		<div className='df-col-ac stretch-ver jc-c'>
			<LockOutlinedIcon
				color='primary'
				style={{ fontSize: '120px', marginBottom: 20 }}
			/>
			<div style={{ fontSize: '34px', color: theme.palette.primary.main, textTransform: 'uppercase', marginBottom: 10 }}>{strings.noAccess}</div>
			<Typography style={{ color: theme.palette.text.primary, whiteSpace: 'pre-wrap', textAlign: 'center' }}>
				{strings.noAccessToThisPageContactHamsAdmin}
			</Typography>
		</div>
	);
};
