import { Typography } from '@material-ui/core';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { isLast } from 'framework/utils/array/isLast';
import { formatDate } from 'framework/utils/date/formatDate';
import { INoahDeviceUsage } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	usages: INoahDeviceUsage[];
	style?: React.CSSProperties;
}

export const UsagesTimeLine = ({ usages, style }: IProps) => {
	const strings = useLocalization();

	return (
		<Timeline style={{ padding: 0, margin: 0, ...style }}>
			{usages.map(t => (
				<TimelineItem key={t.noahActionId}>
					<TimelineOppositeContent>
						<Typography
							color='textSecondary'
							variant='caption'>
							{formatDate(t.noahActionDate, 'date-and-time')}
						</Typography>
						<div>
							{t.isLeft && <RectangularChip label={strings.left} />}
							{t.isRight && <RectangularChip label={strings.right} />}
						</div>
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot
							variant='outlined'
							color='primary'
						/>
						{isLast(t, usages) === false && <TimelineConnector />}
					</TimelineSeparator>
					<TimelineContent>
						<div
							className='df-col'
							style={{ marginTop: '-3px' }}>
							<LabelledProperty
								label={strings.patient}
								property={t.patientIdentifier}
								withoutMargin
							/>
							<LabelledProperty
								label={strings.readOutAs}
								property={t.readOut.uniqueField}
								withoutMargin
							/>
						</div>
					</TimelineContent>
				</TimelineItem>
			))}
		</Timeline>
	);
};
