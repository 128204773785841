import React from 'react';
import { CacheProvider as CosiNeedsCacheProvider } from '../cosi/context/CacheProvider';
import { FilterDataProvider } from './FilterDataProvider';
import { NavigationProvider } from './NavigationProvider';
import { RfiasQueryProvider } from './RfiasQueryProvider';
import { ViewProvider } from './ViewProvider';

export const RfiaProviders = ({ children }: any) => {
	return (
		<RfiasQueryProvider>
			<ViewProvider>
				<CosiNeedsCacheProvider>
					<NavigationProvider>
						<FilterDataProvider>{children}</FilterDataProvider>
					</NavigationProvider>
				</CosiNeedsCacheProvider>
			</ViewProvider>
		</RfiasQueryProvider>
	);
};
