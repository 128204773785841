import { FormControl } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { IFormProps } from './common/IFormProps';
import { nameOf } from '../utils/nameOf';
import { FormErrorHelper } from './common/FormErrorHelper';
import { AutocompleteSingleWithAdd } from '../components/autocomplete/AutocompleteSingleWithAdd';
import { IOption } from '../IOption';

interface IProps<TModel, TValue extends IOption<string>> extends IFormProps<TModel> {
	options: TValue[];
	onCreateNew: (input: string) => void;
	renderCreate: (input: string) => string;
}

export const FormAutocompleteFieldWithAdd = <TModel extends unknown, TValue extends IOption<string>>({
	options,
	onCreateNew,
	renderCreate,
	helperText,
	...props
}: IProps<TModel, TValue>) => {
	const [field, meta, helpers] = useField<string | undefined>(nameOf(props.name));

	return (
		<FormControl
			variant='filled'
			fullWidth
			disabled={props.disabled}
			error={meta.error && meta.touched ? true : false}>
			<AutocompleteSingleWithAdd
				style={{ paddingBottom: 0 }}
				options={options}
				selectedId={field.value}
				setSelectedId={id => {
					helpers.setTouched(true);
					helpers.setValue(id);
				}}
				onCreateNew={onCreateNew}
				renderCreate={renderCreate}
				required={props.required}
				disabled={props.disabled}
				label={props.label}
				error={meta.error && meta.touched ? true : false}
			/>
			<FormErrorHelper
				meta={meta}
				helperText={helperText}
			/>
		</FormControl>
	);
};
