import { AppBar, Dialog, DialogContent, DialogProps, IconButton, Toolbar, Typography, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import React from 'react';
import { SlideUpTransition } from '../components/transitions/SlideUpTransition';
import { DownloadComponent } from './DownloadComponent';
import { PdfViewer } from './PdfViewer';
import { ZoomComponent } from './ZoomComponent';

interface IProps extends DialogProps {
	title: string | undefined;
	close: VoidFunction;
	url: string | undefined;
}

export const PdfViewerDialog = ({ title, url, close, ...rest }: IProps) => {
	const theme = useTheme();
	const zoomPluginInstance = zoomPlugin();
	const getFilePluginInstance = getFilePlugin();
	// const printPluginInstance = printPlugin();

	return (
		<Dialog
			fullScreen
			TransitionComponent={SlideUpTransition}
			scroll='paper'
			{...rest}>
			{rest.open && url && (
				<>
					<AppBar style={{ position: 'relative' }}>
						<Toolbar className='df-row-ac jc-sb'>
							<div className='fg1 fb0'>
								<Typography>{title}</Typography>
							</div>
							<div>
								<ZoomComponent plugin={zoomPluginInstance} />
							</div>
							<div className='df-row fg1 fb0 jc-e'>
								{/* <PrintComponent
                                    plugin={printPluginInstance} /> */}
								<DownloadComponent plugin={getFilePluginInstance} />
								<IconButton
									edge='end'
									color='inherit'
									onClick={close}
									aria-label='close'>
									<CloseIcon />
								</IconButton>
							</div>
						</Toolbar>
					</AppBar>
					<DialogContent
						style={{
							backgroundColor: theme.palette.background.default,
							padding: 0,
						}}>
						{url && (
							<PdfViewer
								url={url}
								plugins={[zoomPluginInstance, getFilePluginInstance]}
							/>
						)}
					</DialogContent>
				</>
			)}
		</Dialog>
	);
};
