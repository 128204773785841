import { Dialog, DialogContent, DialogProps, DialogTitle, TextField } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IRequestOAuthTokenFromAuthorizationCodeRequest, exactOnlineSettingsCommand_enterAuthorizationCode } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IRequestOAuthTokenFromAuthorizationCodeRequest>({
		authorizationCode: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	confirm: () => void;
	cancel: VoidFunction;
}

export const RequestOAuthTokenFromAuthorizationCodeRequestForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(exactOnlineSettingsCommand_enterAuthorizationCode);
	const notify = useSnackbarNotify();
	const schema = useMemo(() => createSchema(strings), [strings]);

	const handleSubmit = async (
		values: IRequestOAuthTokenFromAuthorizationCodeRequest,
		helpers: FormikHelpers<IRequestOAuthTokenFromAuthorizationCodeRequest>
	) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			notify(`oauth token set`);
			confirm();
		}
	};

	return (
		<Formik<IRequestOAuthTokenFromAuthorizationCodeRequest>
			validateOnMount
			initialValues={{
				authorizationCode: '',
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					cancel={cancel}
					isSubmitting={isSubmitting}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
}

const InnerDialog = ({ cancel, isSubmitting, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const [url, setUrl] = useState<string>();
	const props = useFormikContext<IRequestOAuthTokenFromAuthorizationCodeRequest>();

	useEffect(() => {
		if (url) {
			const x = url.indexOf('stampBE');
			if (x !== -1) {
				const y = url.substring(x);
				const z = y.replace('%21', '!');
				setFormValue<IRequestOAuthTokenFromAuthorizationCodeRequest>('authorizationCode', z, props);
			}
		}
		// eslint-disable-next-line
	}, [url]);

	return (
		<Dialog {...rest}>
			<DialogTitle>{`Request OAuth token in exchange for authorization code`}</DialogTitle>
			<DialogContent
				dividers
				className='df-col gap-16'
				style={{ minWidth: 400 }}>
				<TextField
					className='fg1'
					value={url}
					onChange={e => setUrl(e.target.value)}
					variant='filled'
					label='URL'
				/>
				<FormTextField<IRequestOAuthTokenFromAuthorizationCodeRequest>
					name='authorizationCode'
					label={`Authorization Code`}
					required
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions
				cancel={cancel}
				submitText={strings.update}
				isSubmitting={isSubmitting}
			/>
		</Dialog>
	);
};
