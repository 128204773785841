import { DialogProps } from '@material-ui/core';
import React from 'react';
import { suppliersCommand_patchEmail, ISupplier } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { StringModelForm } from 'framework/forms/StringModelForm';

interface IProps extends DialogProps {
	supplier: ISupplier;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const SupplierEmailForm = ({ supplier, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<StringModelForm
			label={strings.email}
			formTitle={strings.changeWhat(strings.email)}
			notifyMessage={strings.supplierUpdated}
			submitFunction={val => suppliersCommand_patchEmail({ supplierId: supplier.id, email: val })}
			initialValue={supplier.email ?? ''}
			submitText={strings.update}
			{...rest}
		/>
	);
};
