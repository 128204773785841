import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	cashRegisterEntriesQuery_concurrencyToken,
	cashRegisterEntriesQuery_query,
	ICashRegisterEntriesQueryParams,
	ICashRegisterEntry,
	IQueryResult,
} from 'gen/ApiClient';
import { cashRegisterEntriesQueryParamsLsKey } from '../../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: ICashRegisterEntriesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'index' },
	pageParams: { index: 1, size: 10 },
	locationIds: [],
	referenceDateAfter: undefined,
	referenceDateBefore: undefined,
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<ICashRegisterEntry>, ICashRegisterEntriesQueryParams>
			defaultParams={DefaultParams}
			queryF={cashRegisterEntriesQuery_query}
			concurrencyF={cashRegisterEntriesQuery_concurrencyToken}
			localStorageKey={cashRegisterEntriesQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
