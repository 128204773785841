import { DialogProps } from '@material-ui/core';
import React from 'react';
import { documentsCommand_createFromTemplate, LinkViewContextType, TemplateType } from 'gen/ApiClient';
import { SelectTemplateFromPredefinedTypeForm } from './SelectTemplateFromPredefinedTypeForm';

interface IProps extends DialogProps {
	cancel: VoidFunction;
	confirm: VoidFunction;
	context: LinkViewContextType;
	contextId: string;
	templatePredefinedType: TemplateType;
	title?: string;
	submitText?: string;
	cancelText?: string;
}

export const DocumentFromTemplateForm = ({ context, contextId, templatePredefinedType, ...rest }: IProps) => {
	return (
		<SelectTemplateFromPredefinedTypeForm
			{...rest}
			type={templatePredefinedType}
			submitF={templateId =>
				documentsCommand_createFromTemplate({ contextId: contextId, linkViewContextType: context, templateId: templateId, standardDocumentId: undefined })
			}
		/>
	);
};
