import { IProductCatalogItem, IStockAtLocation } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const productCatalogItemToLine = (t: IProductCatalogItem, stockAtLocations: IStockAtLocation[]): ILine => {
	return {
		isSelected: true,
		id: t.id,
		description: t.identifier,
		inventoryItemId: undefined,
		productCatalogItemId: t.id,
		quantity: 1,
		unitListPrice: t.salesListPrice,
		unitPrice: t.salesListPrice,
		productCatalogItem: t,
		vatPercentage: t.vatPercentage,
		stockAtLocations: stockAtLocations,
		inventoryItem: undefined,
	};
};
