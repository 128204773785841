import { useTheme } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { formatDate } from 'framework/utils/date/formatDate';
import { IEntPhysician } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { EntPhysicianListItemStatusIcon } from './EntPhysicianListItemStatusIcon';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IEntPhysician;
	isSelected: boolean;
	toggle: VoidFunction;
	style?: React.CSSProperties;
}

export const EntPhysicianListItemCardX = ({ item, isSelected, toggle, style }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const color = useMemo<ColorX>(() => (item.isDeleted ? 'error' : 'success'), [item]);
	const tColor = useThemeColor(color);
	const [hovered, enter, leave] = useStateBoolean(false);

	return (
		<CardX
			raised={isSelected || hovered}
			className='df-row w100'
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={toggle}
			style={{
				borderRight: isSelected ? `6px solid ${theme.palette.primary.main}` : 'unset',
				cursor: 'pointer',
				...style,
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<EntPhysicianListItemStatusIcon
					item={item}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col fg1'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.identifier}</span>
				{item.isDeleted && (
					<ChipX
						color='error'
						label={strings.deleted}
						style={{ marginRight: 'auto' }}
					/>
				)}
				<span style={{ fontSize: MinorFontSize }}>{`${strings.created}: ${formatDate(item.created)}`}</span>
				<span style={{ fontSize: MinorFontSize }}>{`${strings.RIZIVNumber}: ${item.conventionedRizivNumber}`}</span>
				{item.defaultEmail && <span style={{ fontSize: MinorFontSize }}>{`${strings.email}: ${item.defaultEmail}`}</span>}
				{item.defaultPhone && <span style={{ fontSize: MinorFontSize }}>{`${strings.phone}: ${item.defaultPhone}`}</span>}
			</div>
		</CardX>
	);
};
