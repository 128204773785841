import { CardProps, Divider } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SecurityIcon from '@material-ui/icons/Security';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IUsersQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';

interface IProps extends CardProps {
	params: IUsersQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IUsersQueryParams>>;
}

const Exclusions: (keyof IUsersQueryParams)[] = [];

export const UsersListFilterCard = ({ params, setParams, ...rest }: IProps) => {
	const strings = useLocalization();
	const isFilteredX = useMemo(() => isFiltered(params, Exclusions), [params]);
	const onFilter = (pars: IUsersQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params, Exclusions)));

	return (
		<CardX
			className='df-col gap-8'
			{...rest}>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<BooleanRadioFilterComponent
					label={params.canLogin === undefined ? strings.active : params.canLogin === true ? strings.notWhat(strings.blocked) : strings.blocked}
					trueText={strings.notWhat(strings.blocked)}
					falseText={strings.blocked}
					selected={params.canLogin}
					setSelected={selected => onFilter({ ...params, canLogin: selected })}
					icon={<LockOpenIcon />}
				/>
				<BooleanRadioFilterComponent
					label={
						params.isLoggedIn === undefined
							? strings.loggedIn
							: params.isLoggedIn === true
							? strings.nowWhat(strings.loggedIn)
							: strings.nowNotWhat(strings.loggedIn)
					}
					trueText={strings.nowWhat(strings.loggedIn)}
					falseText={strings.nowNotWhat(strings.loggedIn)}
					selected={params.isLoggedIn}
					setSelected={selected => onFilter({ ...params, isLoggedIn: selected })}
					icon={<VerifiedUserIcon />}
				/>
				<BooleanRadioFilterComponent
					label={params.hasConfirmed === undefined ? strings.confirmed : params.hasConfirmed === true ? strings.confirmed : strings.notWhat(strings.confirmed)}
					trueText={strings.confirmed}
					falseText={strings.notWhat(strings.confirmed)}
					selected={params.hasConfirmed}
					setSelected={selected => onFilter({ ...params, hasConfirmed: selected })}
					icon={<SecurityIcon />}
				/>
				<DateFilterItem
					label={strings.dateCreated}
					after={params.createdAfter}
					before={params.createdBefore}
					setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
					icon={<DateRangeIcon />}
				/>
				<DateFilterItem
					label={strings.lastLoggedInDate}
					icon={<DateRangeIcon />}
					after={params.lastLoggedInAfter}
					before={params.lastLoggedInBefore}
					setSelected={(after, before) => onFilter({ ...params, lastLoggedInAfter: after, lastLoggedInBefore: before })}
				/>
			</div>
		</CardX>
	);
};
