import { IOption } from '../../../IOption';
import { pad } from './pad';

export function generateTimeOptions(): IOption<string>[] {
	let x: IOption<string>[] = [];
	for (let hour = 0; hour < 24; hour++) {
		for (let minutes = 0; minutes < 4; minutes++) {
			const y = `${pad(hour, 2)}:${pad(minutes * 15, 2).toString()}`;
			x.push({ id: y, identifier: y });
		}
	}
	return x;
}
