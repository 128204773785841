import { Popover, useTheme } from '@material-ui/core';
import { isBefore } from 'date-fns';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatTime } from 'framework/utils/date/formatTime';
import { IRosaEventDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { getNow } from 'shared/utils/getNow';
import { RosaEventPopoverContent } from '../popover/RosaEventPopoverContent';
import { RosaEventStatusChip } from '../RosaEventStatusChip';
import { RosaEventListComponentMode } from './RosaEventListComponentMode';

interface IProps {
	event: IRosaEventDto;
	style?: React.CSSProperties;
	mode: RosaEventListComponentMode;
}

export const RosaEventListComponent = ({ event, style, mode = 'calendarList' }: IProps) => {
	const [isHovered, enter, leave] = useStateBoolean(false);
	const isPast = useMemo(() => isBefore(new Date(event.endsAt ?? event.startsAt), getNow()), [event]);
	const [anchor, open, close] = useAnchorElement();
	const strings = useLocalization();
	const theme = useTheme();

	return (
		<>
			{Boolean(anchor) && (
				<Popover
					open={Boolean(anchor)}
					anchorEl={anchor}
					onClose={close}>
					<RosaEventPopoverContent
						event={event}
						close={close}
					/>
				</Popover>
			)}
			<div
				onClick={open}
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{
					display: 'flex',
					flexDirection: mode === 'todayEventsCard' || mode === 'patientUpcoming' ? 'column' : 'row',
					alignItems: mode === 'todayEventsCard' || mode === 'patientUpcoming' ? 'left' : 'center',
					width: '100%',
					height: mode === 'todayEventsCard' || mode === 'patientUpcoming' ? 'unset' : 32,
					cursor: 'pointer',
					borderRadius: mode === 'todayEventsCard' || mode === 'patientUpcoming' ? 'unset' : 32,
					backgroundColor: isHovered ? '#f6f6f6' : 'inherit',
					paddingLeft: mode === 'todayEventsCard' || mode === 'patientUpcoming' ? 16 : 8,
					paddingRight: mode === 'todayEventsCard' || mode === 'patientUpcoming' ? 16 : 8,
					paddingTop: mode === 'todayEventsCard' || mode === 'patientUpcoming' ? 8 : 0,
					paddingBottom: mode === 'todayEventsCard' || mode === 'patientUpcoming' ? 8 : 0,
					opacity: isPast ? 0.8 : 1,
					...style,
				}}>
				<div className='df-row-ac'>
					<div style={{ display: 'flex', marginRight: 6, marginBottom: 3 }}>
						<div style={{ borderRadius: 12, border: `6px solid ${event.motiveHexColor}` }}></div>
					</div>
					{mode === 'patientUpcoming' && <div style={{ fontWeight: 'bold', marginRight: 6 }}>{formatDate(event.startsAt, 'only-date')}</div>}
					<div
						style={{
							width: '100px',
							textDecoration: event.isNoShow ? 'line-through' : 'inherit',
							color: event.isNoShow ? theme.palette.error.main : 'inherit',
						}}>
						{event.isAllDay ? strings.fullDay : `${formatTime(event.startsAt)} - ${formatTime(event.endsAt)}`}
					</div>
					{event.isActive === false && (
						<RosaEventStatusChip
							style={{ marginRight: 8 }}
							event={event}
						/>
					)}
				</div>
				{(mode === 'todayEventsCard' || mode === 'patientUpcoming') && event.hasMotive && (
					<div className='df-row-ac'>
						<div style={{ color: event.motiveHexColor, fontSize: 12 }}>{event.motiveLabel}</div>
						{event.hasSite && <div style={{ fontSize: 12, marginLeft: 8 }}>{`@${event.siteName}`}</div>}
					</div>
				)}
				{mode !== 'patientList' && mode !== 'patientUpcoming' && (
					<div style={{ fontWeight: 500, marginRight: 8, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{event.display}</div>
				)}
				{(mode === 'patientList' || mode === 'calendarList') && event.hasMotive && (
					<div style={{ color: event.motiveHexColor, marginRight: 8 }}>{event.motiveLabel}</div>
				)}
				{(mode === 'patientList' || mode === 'calendarList') && event.hasSite && <div>{`@${event.siteName}`}</div>}
			</div>
		</>
	);
};
