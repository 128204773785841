import { Collapse } from '@material-ui/core';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { FunctionVariantIcon } from 'framework/components/icons/FunctionVariantIcon';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { PageStatsButtonX } from 'framework/components/layout/PageStatsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { IQueryResult, IRequestForInsuranceAllowance, IRfiaQueryMetadata, IRfiasQueryParams } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RequestsForInsuranceAllowancesSettingsRoute } from '../ProcessesRoutes';
import { RfiasListStats } from './RfiasListStats';
import { RfiasPageBreadcrumbs } from './RfiasPageBreadcrumbs';
import { RfiaScriptsDialog } from './scripts/RfiaScriptsDialog';

interface IProps {
	metadata: IRfiaQueryMetadata;
	reload: VoidFunction;
	queryResult: IQueryResult<IRequestForInsuranceAllowance>;
	params: IRfiasQueryParams;
	style?: React.CSSProperties;
}

export const RfiasListPageHeader = ({ metadata, queryResult, params, reload, style }: IProps) => {
	const userIsSuperUser = useLoggedInUserIsSuperUser();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [showStats, toggleShowStats] = useStateToggleBoolean(true);

	const onScript = () => {
		open(
			<RfiaScriptsDialog
				open
				confirm={confirm}
				cancel={cancel}
				queryResult={queryResult}
				params={params}
			/>
		);
	};

	return (
		<div
			className='df-col fg1'
			style={style}>
			<div className='df-row-ac'>
				<RfiasPageBreadcrumbs />
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageStatsButtonX
						showStats={showStats}
						toggleShowStats={toggleShowStats}
					/>
					{userIsSuperUser && (
						<TooltipWithSpan title={`Execute custom scripts`}>
							<ColorOnHoverIconButton
								onClick={onScript}
								size='small'
								edge='end'>
								<FunctionVariantIcon />
							</ColorOnHoverIconButton>
						</TooltipWithSpan>
					)}
					<PageSettingsButtonX route={RequestsForInsuranceAllowancesSettingsRoute} />
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<Collapse in={showStats}>
				<RfiasListStats
					metadata={metadata}
					style={{ paddingTop: 16 }}
				/>
			</Collapse>
		</div>
	);
};
