import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatchProductCatalogItemVatPercentageRequest, IProductCatalogItem, productCatalogCommand_patchVatPercentage } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { FormSelectVatPercentageField } from 'shared/forms/FormSelectVatPercentageField';
import * as yup from 'yup';
import { ProductCatalogFilterDataContext } from '../context/ProductCatalogFilterDataContext';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchProductCatalogItemVatPercentageRequest>({
		vatPercentage: yup.number().required(strings.formRequired(strings.vatPercentage)),
		productCatalogItemId: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	productCatalogItem: IProductCatalogItem;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const VatPercentageForm = ({ productCatalogItem, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [data] = useCacheContext(ProductCatalogFilterDataContext);
	const [update, isSubmitting] = useFormSubmit(productCatalogCommand_patchVatPercentage);

	const handleSubmit = async (values: IPatchProductCatalogItemVatPercentageRequest, helpers: FormikHelpers<IPatchProductCatalogItemVatPercentageRequest>) => {
		const r = await update(values);
		if (handleValidateResponse(r, helpers, 'vatPercentage')) {
			// TODO change notify string to 'VAT percentage changed'
			notify(strings.productUpdated);
			confirm();
		}
	};

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IPatchProductCatalogItemVatPercentageRequest>
			validateOnMount
			validationSchema={createSchema(strings)}
			initialValues={{
				vatPercentage: productCatalogItem.vatPercentage,
				productCatalogItemId: productCatalogItem.id,
			}}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.vatPercentage}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'
						style={{ overflow: 'visible' }}>
						<FormSelectVatPercentageField<IPatchProductCatalogItemVatPercentageRequest>
							name='vatPercentage'
							label={strings.vatPercentage}
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.update}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
