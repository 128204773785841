import { IconButton, InputBase, useTheme } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	search: string;
	setSearch: React.Dispatch<React.SetStateAction<string>>;
	style?: React.CSSProperties;
}

export const ListFilterSearch = ({ search, setSearch, style }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();

	return (
		<div
			className='df-row-ac'
			style={style}>
			<SearchIcon
				fontSize='small'
				style={{ color: theme.palette.text.secondary }}
			/>
			<InputBase
				placeholder={`${strings.searchVerb}...`}
				style={{ width: '100%', paddingLeft: 4 }}
				value={search}
				onKeyDown={e => e.stopPropagation()}
				onChange={e => setSearch(e.target.value)}
			/>
			{search && (
				<IconButton
					onClick={() => setSearch('')}
					size='small'>
					<ClearIcon />
				</IconButton>
			)}
		</div>
	);
};
