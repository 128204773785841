import { IFormProps } from 'framework/forms/common/IFormProps';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IOption } from 'framework/IOption';
import React from 'react';
import { LocationsPreloadCacheContext } from '../LocationsPreloadCacheContext';

export const FormSelectLocationField = <TModel extends unknown>({ name, label, ...rest }: IFormProps<TModel>) => {
	const [data] = usePreloadCacheContext(LocationsPreloadCacheContext);

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<FormAutocompleteField<TModel, IOption<string>>
			{...rest}
			name={name}
			options={data}
			label={label}
			style={{ width: '100%' }}
		/>
	);
};
