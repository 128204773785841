import React from 'react';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { DeliveryExportProp, IDelivery } from 'gen/ApiClient';
import { DeliveriesSelectableColumn } from './DeliveriesSelectableColumn';

interface IViewContext extends ITableViewContext<DeliveriesSelectableColumn, IDelivery> {
	exportRecord: Record<DeliveryExportProp, string>;
}

export const DeliveriesListViewContext = React.createContext<IViewContext>(undefined as any);
