import LinkIcon from '@material-ui/icons/Link';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { updateArray } from 'framework/utils/array/updateArray';
import { IRizivHearingAidDefinitionV2Dto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RizivRefundableHearingInstrumentChip } from 'shared/rizivRefundableHearingInstruments/RizivRefundableHearingInstrumentChip';
import { SelectRizivRefundableHearingInstrumentForInventoryItemForm } from 'shared/rizivRefundableHearingInstruments/SelectRizivRefundableHearingInstrumentForInventoryItemForm';
import { IHandoverLine } from './IHandoverLine';
import { appendRizivHearingInstrumentData } from './utils/appendRizivHearingInstrumentData';

interface IProps {
	line: IHandoverLine;
	lines: IHandoverLine[];
	setLines: (val: IHandoverLine[]) => void;
	isUsingOldRizivIdentificationCode: boolean;
}

export const LineRizivHearingInstrumentCodeComponent = ({ line, lines, setLines, isUsingOldRizivIdentificationCode }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext();
	const hasLink = useMemo(
		() =>
			line.rizivHearingAidDefinitionV2IdentificationCode !== undefined &&
			line.rizivHearingAidDefinitionV2IdentificationCode !== null &&
			line.rizivHearingAidDefinitionV2IdentificationCode !== -1,
		[line]
	);
	const hasMultiple = useMemo(() => lines.filter(t => t.productCatalogItemId === line.productCatalogItemId).length > 1, [lines, line]);

	const onLink = () => {
		if (line.inventoryItem && line.inventoryItem.canLinkRizivRefundableHearingInstrument) {
			open(
				<SelectRizivRefundableHearingInstrumentForInventoryItemForm
					open
					item={line.inventoryItem}
					confirm={item => {
						confirm();
						if (hasMultiple) {
							onRequestMultiple(item);
						} else {
							onSelectSingle(item);
						}
					}}
					cancel={cancel}
				/>
			);
		}
	};

	const onRequestMultiple = (item: IRizivHearingAidDefinitionV2Dto) => {
		open(
			<AlertDialog
				open
				title={strings.multipleArticlesFound}
				content={strings.applyCodeForAllArticlesOfWhatQuestion(line.productCatalogItem?.manufacturerCommaModel ?? '')}
				acceptText={strings.yesForAll}
				rejectText={strings.noOnlyForThis}
				reject={() => {
					confirm();
					onSelectSingle(item);
				}}
				accept={() => {
					confirm();
					onSelectMultiple(item);
				}}
			/>
		);
	};

	const onSelectSingle = (item: IRizivHearingAidDefinitionV2Dto) => {
		setLines(updateArray(line, lines, appendRizivHearingInstrumentData(line, item)));
	};

	const onSelectMultiple = (item: IRizivHearingAidDefinitionV2Dto) => {
		const x = lines
			.filter(t => t.productCatalogItemId === line.productCatalogItemId)
			.reduce((aggregate, current) => updateArray(current, aggregate, appendRizivHearingInstrumentData(current, item)), lines);
		setLines(x);
	};

	if (hasLink === false) {
		return (
			<WarningLabelledProperty
				label={strings.RIZIVCode}
				warning={strings.notApplicableAbbreviation}
				editIcon={<LinkIcon />}
				edit={onLink}
			/>
		);
	} else {
		return (
			<div className='df-row-ac'>
				<LabelledProperty
					label={strings.RIZIVCode}
					withoutMargin>
					<RizivRefundableHearingInstrumentChip
						identificationCode={line.rizivHearingAidDefinitionV2IdentificationCode!}
						reload={() => {}}
						noMargin
					/>
				</LabelledProperty>
				{isUsingOldRizivIdentificationCode && (
					<TooltipWithSpan
						title={strings.handoverBeforeFirstOfAugustWillUseOldCode}
						spanStyle={{ display: 'flex' }}>
						<StatusIcon status='warning' />
					</TooltipWithSpan>
				)}
			</div>
		);
	}
};
