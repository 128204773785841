import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { ApplicationLogoutMode, applicationSettingsCommand_patchLogoutMode, applicationSettingsQuery_logoutMode } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useState } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchLogoutModeForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [mode] = useApiEffect(applicationSettingsQuery_logoutMode);
	const [option, setOption] = useState<ApplicationLogoutMode>('Never');
	const [update, isSubmitting] = useFormSubmit(applicationSettingsCommand_patchLogoutMode);
	const { logoutModeRecord } = useContext(RecordContext);

	useEffect(() => {
		if (mode) {
			setOption(mode);
		}
	}, [mode]);

	const handleSubmit = async () => {
		const r = await update({ mode: option });
		if (r.isSuccess) {
			confirm();
		}
	};

	return (
		<Dialog
			{...rest}
			scroll='paper'>
			<DialogTitle>{strings.settings}</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<ApplicationLogoutMode>
					selected={option}
					setSelected={setOption}
					options={[
						{ value: 'Never', label: logoutModeRecord['Never'], caption: '' },
						{ value: 'After20Minutes', label: logoutModeRecord['After20Minutes'], caption: '' },
					]}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				submitText={strings.confirmChoice}
				cancel={cancel}
				isSubmitting={isSubmitting}
				onSubmit={handleSubmit}
			/>
		</Dialog>
	);
};
