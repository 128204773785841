import { add, intervalToDuration } from 'date-fns';
import _ from 'lodash';
import { RRuleSet, rrulestr } from 'rrule';
import { ICalendarEventDto } from 'gen/ApiClient';

export const spreadAndFilterRecurringEvents = (events: ICalendarEventDto[], after: Date, before: Date): ICalendarEventDto[] => {
	console.log(after);
	console.log(before);
	after = new Date(after);
	before = new Date(before);
	const sfEvents = events.flatMap(t => spreadRecurringEvent(t, after, before));
	return _.orderBy(sfEvents, t => new Date(t.startDate));
};

const spreadRecurringEvent = (event: ICalendarEventDto, after: Date, before: Date): ICalendarEventDto[] => {
	if (event.isRecurring === false) {
		return [event];
	} else {
		console.log(after);
		console.log(before);
		const rruleSet = new RRuleSet();
		rruleSet.rrule(rrulestr(`RRULE:${event.rRule}`, { dtstart: new Date(event.startDate) }));
		event.exDates.map(t => rruleSet.exdate(new Date(t)));
		const all = rruleSet.between(after, before, true);
		const duration = intervalToDuration({ start: new Date(event.startDate), end: new Date(event.endDate) });
		return all.map<ICalendarEventDto>(t => ({ ...event, startDate: t, endDate: add(t, duration) }));
	}
};
