import React, { useEffect, useState } from 'react';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import {
	customerAccountLinesQuery_allForCustomerAccountNoSpsWithOutstandingBalance,
	settlementProposalsQuery_unhandledForCustomerAccount,
} from 'gen/ApiClient';
import { Mode } from './Mode';
import { PaymentForSaleForm } from './PaymentForSaleForm';
import { PaymentForSalesForm } from './PaymentForSalesForm';
import { PaymentFromSettlementProposalForm } from './PaymentFromSettlementProposalForm';
import { RegisterPaymentForSaleModeForm } from './RegisterPaymentForSaleModeForm';
import { SelectCustomerAccountLineForm } from './SelectSaleForm';
import { SelectSettlementProposalForm } from './SelectSettlementProposalForm';

interface IProps {
	customerAccountId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const RegisterPaymentForCustomerAccountForm = ({ customerAccountId, cancel, confirm }: IProps) => {
	const [lines] = useApiEffect(customerAccountLinesQuery_allForCustomerAccountNoSpsWithOutstandingBalance, customerAccountId);
	const [proposals] = useApiEffect(settlementProposalsQuery_unhandledForCustomerAccount, customerAccountId);
	const [customerAccountLineId, setCustomerAccountLineId] = useState<string>();
	const [settlementProposalId, setSettlementProposalId] = useState<string>();
	const [mode, setMode] = useState<Mode | 'select'>('select');

	useEffect(() => {
		if (lines?.length === 1 && proposals?.length === 0) {
			setCustomerAccountLineId(lines[0].id);
			setMode('single');
		} else if (lines?.length === 0 && proposals?.length === 1) {
			setSettlementProposalId(proposals[0].id);
			setMode('settlementProposal');
		} else {
			if (lines?.length === 1) {
				setCustomerAccountLineId(lines[0].id);
			}
			if (proposals?.length === 1) {
				setSettlementProposalId(proposals[0].id);
			}
		}
		// eslint-disable-next-line
	}, [lines, proposals]);

	if (lines === undefined || proposals === undefined) {
		return <div></div>;
	}

	if (mode === 'select') {
		return (
			<RegisterPaymentForSaleModeForm
				open
				confirm={setMode}
				cancel={cancel}
				proposals={proposals}
				lines={lines}
			/>
		);
	} else if (mode === 'single' && customerAccountLineId === undefined) {
		return (
			<SelectCustomerAccountLineForm
				open
				confirm={setCustomerAccountLineId}
				cancel={cancel}
				lines={lines}
			/>
		);
	} else if (mode === 'single' && customerAccountLineId !== undefined) {
		return (
			<PaymentForSaleForm
				open
				customerAccountLineId={customerAccountLineId}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	} else if (mode === 'settlementProposal' && settlementProposalId === undefined) {
		return (
			<SelectSettlementProposalForm
				open
				confirm={setSettlementProposalId}
				cancel={cancel}
				proposals={proposals}
			/>
		);
	} else if (mode === 'settlementProposal' && settlementProposalId !== undefined) {
		return (
			<PaymentFromSettlementProposalForm
				open
				confirm={confirm}
				cancel={cancel}
				customerAccountId={customerAccountId}
				settlementProposalId={settlementProposalId}
			/>
		);
	} else {
		return (
			<PaymentForSalesForm
				open
				customerAccountId={customerAccountId}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	}
};
