import { INewLineModel } from 'shared/searchAndSelect/INewLineModel';
import { IPurchaseFormLine } from './IPurchaseFormLine';

export const newLineToLine = (t: INewLineModel): IPurchaseFormLine => {
	return {
		id: t.id,
		description: t.description,
		quantity: 1,
		vatPercentage: t.vatPercentage,
		inventoryItemId: undefined,
		productCatalogItem: undefined,
		productCatalogItemId: undefined,
		unitPrice: t.unitListPrice,
		serialNumber: '',
		unitListPrice: t.unitListPrice,
		assuredLineId: undefined,
		assuredProductCatalogItemId: undefined,
		canChangeQuantity: true,
		assuredSerialNumber: undefined,
		isCreditLine: t.unitListPrice < 0,
		creditedAssuranceLineId: undefined,
	};
};
