import { rosaEventsQueryParamsLsKey } from 'app/localStorageKeys';
import { endOfMonth, startOfMonth } from 'date-fns';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { nlBELocaleEndOfWeek } from 'framework/utils/date/nlBELocaleEndOfWeek';
import { nlBELocaleStartOfWeek } from 'framework/utils/date/nlBELocaleStartOfWeek';
import { IRosaEventsQueryParams, IRosaEventsResponse, rosaEventsQuery_concurrencyToken, rosaEventsQuery_query } from 'gen/ApiClient';
import React from 'react';
import { getNow } from 'shared/utils/getNow';
import { RosaCalendarQueryContext } from './RosaCalendarQueryContext';

const DefaultParams: IRosaEventsQueryParams = {
	after: nlBELocaleStartOfWeek(startOfMonth(getNow())),
	before: nlBELocaleEndOfWeek(endOfMonth(getNow())),
};

export const RosaCalendarQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IRosaEventsResponse, IRosaEventsQueryParams>
			defaultParams={DefaultParams}
			queryF={rosaEventsQuery_query}
			concurrencyF={rosaEventsQuery_concurrencyToken}
			localStorageKey={rosaEventsQueryParamsLsKey}
			context={RosaCalendarQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
