import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { TabX } from 'framework/components/tabs/TabX';
import { TabsX } from 'framework/components/tabs/TabsX';
import { IRosaStatusDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { IntegrationsPageBreadcrumb } from '../IntegrationsPageBreadcrumb';
import { RosaIntegrationTabType } from './RosaIntegrationTabType';
import { RosaLogo } from './RosaLogo';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

interface IProps {
	reload: VoidFunction;
	tab: RosaIntegrationTabType;
	setTab: React.Dispatch<React.SetStateAction<RosaIntegrationTabType>>;
	data: IRosaStatusDto;
	isRosaIntegrationEnabled: boolean;
}

export const RosaIntegrationPageHeader = ({ reload, tab, setTab, data, isRosaIntegrationEnabled }: IProps) => {
	const strings = useLocalization();

	return (
		<div className='df-col fg1 gap-8'>
			<div className='df-row-ac'>
				<IntegrationsPageBreadcrumb />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={`Rosa`} />
				{data.hasNotification && (
					<StatusIcon
						status='error'
						style={{ marginLeft: 4 }}
					/>
				)}
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<RosaLogo style={{ height: 30, width: 'fit-content' }} />
			<TabsX
				style={{
					marginLeft: -4,
				}}>
				{isRosaIntegrationEnabled && (
					<>
						<TabX
							isSelected={tab === 'patients'}
							onClick={() => setTab('patients')}
							startIcon={<GroupOutlinedIcon />}
							endIcon={
								data.countUnlinkedPatientRecords > 0 && (
									<BadgeChipX
										value={data.countUnlinkedPatientRecords}
										color='error'
									/>
								)
							}
							value={strings.patients}
						/>
						<TabX
							isSelected={tab === 'users'}
							onClick={() => setTab('users')}
							startIcon={<SupervisedUserCircleOutlinedIcon />}
							endIcon={
								data.countUnlinkedUsers > 0 && (
									<BadgeChipX
										value={data.countUnlinkedUsers}
										color='error'
									/>
								)
							}
							value={strings.users}
						/>
					</>
				)}
				<TabX
					isSelected={tab === 'settings'}
					onClick={() => setTab('settings')}
					startIcon={<SettingsOutlinedIcon />}
					value={strings.settings}
				/>
			</TabsX>
		</div>
	);
};
