import { Stepper, StepperProps } from '@material-ui/core';
import React from 'react';

export const VerticalProcess = ({ style, ...props }: StepperProps) => {
	return (
		<Stepper
			{...props}
			style={{ paddingLeft: 18, paddingTop: 0, ...style }}
			orientation='vertical'
		/>
	);
};
