import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { SelectField } from 'framework/components/select/SelectField';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { IObjectWithIdAndIdentifier } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	customerAccounts: IObjectWithIdAndIdentifier<string>[];
	confirm: (customerAccountId: string) => void;
	cancel: VoidFunction;
}

export const SelectCustomerAccountDialog = ({ customerAccounts, confirm, cancel }: IProps) => {
	const strings = useLocalization();
	const [selectedCustomerAccount, setSelectedCustomerAccount] = useState<IObjectWithIdAndIdentifier<string>>();

	return (
		<Dialog
			open
			fullWidth>
			<DialogTitle>{strings.selectWhat(strings.healthInsuranceFund)}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<SelectField<IObjectWithIdAndIdentifier<string>>
					options={customerAccounts}
					value={selectedCustomerAccount}
					onChange={setSelectedCustomerAccount}
					getKey={t => t.id}
					label={strings.healthInsuranceFund}
					renderValue={t => t.identifier}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				submitText={strings.select}
				isSubmitting={false}
				isValid={selectedCustomerAccount !== undefined}
				onSubmit={() => confirm(selectedCustomerAccount!.id)}
			/>
		</Dialog>
	);
};
