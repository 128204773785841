import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SelectField } from 'framework/components/select/SelectField';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { IHeaderInfo, IWorksheetInfo } from 'gen/ApiClient';

interface IProps extends DialogProps {
	sheets: IWorksheetInfo[];
	cancel: VoidFunction;
	confirm: (sheetName: string, column: number) => void;
	isSubmitting: boolean;
}

export const SelectSheetAndColumnDialog = ({ confirm, cancel, sheets, isSubmitting }: IProps) => {
	const [selectedSheet, setSelectedSheet] = useState<IWorksheetInfo>();
	const [selectedHeader, setSelectedHeader] = useState<IHeaderInfo>();

	useEffect(() => {
		if (sheets.length === 1) {
			setSelectedSheet(sheets[0]);
		}
	}, [sheets]);

	useEffect(() => {
		setSelectedHeader(undefined);
	}, [selectedSheet]);

	return (
		<Dialog
			open
			fullWidth>
			<DialogTitle>Selecteer tabblad en kolom van serienummer</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				{sheets.length > 1 && (
					<SelectField<IWorksheetInfo>
						style={{ marginBottom: 16 }}
						options={sheets}
						value={selectedSheet}
						onChange={setSelectedSheet}
						getKey={t => t.name!}
						label='Data van tabblad'
						renderValue={t => t.name!}
					/>
				)}
				{selectedSheet && (
					<SelectField<IHeaderInfo>
						options={selectedSheet.headers}
						value={selectedHeader}
						onChange={setSelectedHeader}
						getKey={t => t.column.toString()}
						label='Kolom met het serienummer'
						renderValue={t => t.value ?? ''}
					/>
				)}
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				submitText='Selecteer'
				isSubmitting={isSubmitting}
				isValid={selectedSheet !== undefined && selectedHeader !== undefined}
				onSubmit={() => confirm(selectedSheet!.name!, selectedHeader!.column)}
			/>
		</Dialog>
	);
};
