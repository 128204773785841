import { createStyles, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { ColorX } from './color/ColorX';
import { mapColorXToThemeColor } from './color/mapColorXToThemeColor';
import { mapColorXToProgressSecondaryColor } from './color/mapColorXToProgressSecondaryColor';

const toStyles = (color: ColorX) => {
	return makeStyles((theme: Theme) =>
		createStyles({
			bar: {
				backgroundColor: mapColorXToThemeColor(color, theme),
			},
			colorPrimary: {
				backgroundColor: mapColorXToProgressSecondaryColor(color),
			},
		})
	);
};

interface IProps {
	value: number;
	color: ColorX;
	style?: React.CSSProperties;
}

export const ColoredLinearProgress = ({ value, color, style }: IProps) => {
	const styles = toStyles(color)();

	return (
		<LinearProgress
			variant='determinate'
			value={value}
			classes={{ bar: styles.bar, colorPrimary: styles.colorPrimary }}
			style={style}
		/>
	);
};
