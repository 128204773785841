import { RRule, RRuleSet, rrulestr } from 'rrule';
import { calculateREndDate } from './forms/utils/calculateREndDate';

export const newRRuleFromDate = (rRuleStr: string, originalStartDate: Date, newStartDate: Date) => {
	const rrule = rrulestr(`RRULE:${rRuleStr}`);
	if (rrule.options.count !== undefined && rrule.options.count !== null) {
		const rEndDate = calculateREndDate(rrule, originalStartDate);
		const rruleSet = new RRuleSet();
		rruleSet.rrule(rrulestr(`RRULE:${rRuleStr}`, { dtstart: new Date(newStartDate) }));
		const all = rruleSet.between(new Date(newStartDate), rEndDate!, true);
		return new RRule({ ...rrule.options, count: all.length + 1 });
	} else {
		return rrule;
	}
};
