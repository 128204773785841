import { Button, Dialog, DialogContent, DialogProps, useTheme } from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { CosiDegreeOfChange, CosiEndResult, cosiQuestionnaireCommand_new, ICosiLineModel, ICosiQuestionnaireModel } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { CosiNeedForm } from './CosiNeedForm';
import { DragAndDropCosiNeedsTable } from './DragAndDropCosiNeedsTable';

const createSchema = (strings: IStrings, isEvaluate: boolean) => {
	return yup
		.object<ICosiQuestionnaireModel>({
			rfiaId: yup.string().required(),
			evaluationDate: isEvaluate ? yup.date().required(strings.formRequired(strings.evaluationDate)) : yup.date(),
			lines: yup.mixed(),
			remarks: yup.string(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof ICosiQuestionnaireModel)[]> = {
	0: ['evaluationDate'],
	1: ['lines'],
	2: ['remarks'],
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	rfiaId: string;
}

export const CosiQuestionnaireForm = ({ confirm, cancel, rfiaId, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [step, setStep] = useState<number>(0);
	const [isEvaluate, setIsEvaluate] = useState<boolean>(false);
	const [lines, setLines] = useState<ICosiLineModel[]>([]);
	const [submit, isSubmitting] = useFormSubmit(cosiQuestionnaireCommand_new);
	const schema = useMemo(() => createSchema(strings, isEvaluate), [strings, isEvaluate]);
	const { open, cancel: closeNewNeed, isStacked } = useDialogsContext();

	const handleSubmit = async (values: ICosiQuestionnaireModel, helpers: FormikHelpers<ICosiQuestionnaireModel>) => {
		const r = await submit({ ...values, lines: lines.map((t, index) => ({ ...t, priority: index + 1 })) });
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.addedWhat(strings.cosiQuestionnaire));
			confirm();
		}
	};

	const onAdd = () => {
		open(
			<CosiNeedForm
				open
				confirm={onAddNeed}
				cancel={closeNewNeed}
			/>
		);
	};

	const onAddNeed = (need: string) => {
		const naDoc: CosiDegreeOfChange = 'NotApplicable';
		const naEr: CosiEndResult = 'NotApplicable';
		setLines([...lines, { description: need, degreeOfChange: naDoc, endResult: naEr, priority: 0 }]);
		closeNewNeed();
	};

	return (
		<Formik<ICosiQuestionnaireModel>
			validateOnMount
			initialValues={{
				rfiaId: rfiaId,
				evaluationDate: undefined,
				lines: [],
				remarks: '',
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='md'
					fullWidth={step === 1}
					open={rest.open && isStacked === false}>
					<DialogTitleWithFormStepper
						title={strings.cosiQuestionnaire}
						step={step}
						labels={[strings.date, strings.needs, strings.remarks]}
					/>
					<DialogContent
						className='df-col'
						dividers>
						{step === 0 && (
							<>
								<ExtendedRadioGroup<boolean>
									style={{ marginBottom: theme.spacing(2) }}
									selected={isEvaluate}
									setSelected={setIsEvaluate}
									options={[
										{ value: false, label: strings.draft, caption: strings.draftCosiCaption },
										{ value: true, label: strings.evaluateVerb, caption: strings.evaluateCosiCaption },
									]}
								/>
								<FormDatePicker<ICosiQuestionnaireModel>
									name='evaluationDate'
									label={strings.evaluationDate}
									disabled={isEvaluate === false}
								/>
							</>
						)}
						{step === 1 && (
							<>
								<DragAndDropCosiNeedsTable
									ordered={lines}
									setOrdered={setLines}
									mustEvaluate={isEvaluate}
								/>
								<div
									className='df-row-ac jc-e'
									style={{ marginTop: 16 }}>
									<Button
										startIcon={<PlaylistAddIcon />}
										color='primary'
										variant='contained'
										onClick={onAdd}>
										{strings.addWhat(strings.cosiNeed)}
									</Button>
								</div>
							</>
						)}
						{step === 2 && (
							<FormTextField<ICosiQuestionnaireModel>
								name='remarks'
								label={strings.remarks}
								multiline
							/>
						)}
					</DialogContent>
					<PageableFormDialogActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
						schema={schema}
						stepsRecord={stepsRecord}
						canNext={step !== 1 || lines.length > 0}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
