import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatchUserNameRequest, IUserDto, usersCommand_patchName } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchUserNameRequest>({
		userId: yup.string().required(),
		salutation: yup.string(),
		firstName: yup.string().required(strings.formRequired(strings.firstName)),
		lastName: yup.string().required(strings.formRequired(strings.lastName)),
	});
};

interface IProps extends DialogProps {
	user: IUserDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

const toModel = (user: IUserDto): IPatchUserNameRequest => {
	return {
		userId: user.id,
		salutation: user.salutation,
		firstName: user.firstName,
		lastName: user.lastName,
	};
};

export const UserNameForm = ({ user, confirm, cancel, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [change, isSubmitting] = useFormSubmit(usersCommand_patchName);
	const strings = useLocalization();

	const handleSubmit = async (values: IPatchUserNameRequest, helpers: FormikHelpers<IPatchUserNameRequest>) => {
		const r = await change(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.userUpdated);
			confirm();
		}
	};

	return (
		<Formik<IPatchUserNameRequest>
			validateOnMount
			initialValues={toModel(user)}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					maxWidth='sm'
					fullWidth>
					<DialogTitle>{strings.editName}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<IPatchUserNameRequest>
							name='salutation'
							label={strings.salutation}
						/>
						<FormTextField<IPatchUserNameRequest>
							name='firstName'
							label={strings.firstName}
							required
						/>
						<FormTextField<IPatchUserNameRequest>
							name='lastName'
							label={strings.lastName}
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.update}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
