import DateRangeIcon from '@material-ui/icons/DateRange';
import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { IOption } from 'framework/IOption';
import { isFiltered } from 'framework/utils/isFiltered';
import { FailedEventStatuses, IFailedEventsQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { ViewContext } from './context/ViewContext';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface IProps {
	params: IFailedEventsQueryParams;
	onFilter: (params: IFailedEventsQueryParams) => void;
	paddingLeft: number;
}

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const { statusRecord } = useContext(ViewContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params)}
			onClear={() => onFilter(clearParams(params))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.dateCreated}
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
				icon={<DateRangeIcon />}
			/>
			<ListFilterComponent<string>
				options={FailedEventStatuses.map<IOption<string>>(t => ({ id: t, identifier: statusRecord[t] }))}
				selected={params.statuses}
				setSelected={vals => onFilter({ ...params, statuses: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.status}
			/>
		</FilterBar>
	);
};
