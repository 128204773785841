import { Collapse, Divider, List } from '@material-ui/core';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { toggleSelect } from 'framework/utils/array/toggleSelect';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { CalendarFilterListItemComponent } from '../CalendarFilterListItemComponent';
import { CalendarFilterListItemHeaderComponent } from '../CalendarFilterListItemHeaderComponent';
import { ICalendarResource } from '../ICalendarResource';

interface IProps {
	data: ICalendarResource[];
	selectedKeys: string[];
	setSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>;
	isColorSchemeSelected: boolean;
	selectColorScheme: VoidFunction;
	style?: React.CSSProperties;
}

export const AgendaEventTypesFilterListComponent = ({ data, selectedKeys, setSelectedKeys, isColorSchemeSelected, selectColorScheme, style }: IProps) => {
	const strings = useLocalization();
	const [isExpanded, toggle] = useStateToggleBoolean(false);
	const isAllSelected = useMemo(() => data.length === selectedKeys.length, [data, selectedKeys]);
	const isNoneSelected = useMemo(() => selectedKeys.length === 0, [selectedKeys]);

	return (
		<div
			className='df-col'
			style={style}>
			<CalendarFilterListItemHeaderComponent
				title={strings.typesAppointments}
				isColorSchemeSelected={isColorSchemeSelected}
				isExpanded={isExpanded}
				isGroupSelected={false}
				onClick={toggle}
				selectColorScheme={selectColorScheme}
				selectGroup={() => {}}
				hideGroup
			/>
			<Collapse
				in={isExpanded}
				timeout='auto'
				unmountOnExit>
				<CalendarFilterListItemComponent
					onClick={() => setSelectedKeys(isAllSelected ? [] : data.map(t => t.key))}
					isSelected={isAllSelected}
					indeterminate={isAllSelected === false && isNoneSelected === false}
					text={strings.selectAll}
					color='unset'
					showSecondaryColor={false}
					showPrimaryColor={false}
				/>
				<List
					dense
					disablePadding>
					<Divider variant='middle' />
					{data.map(t => (
						<CalendarFilterListItemComponent
							onClick={() => setSelectedKeys(toggleSelect(t.key, selectedKeys))}
							isSelected={selectedKeys.indexOf(t.key) !== -1}
							color={t.color}
							showPrimaryColor={isColorSchemeSelected}
							showSecondaryColor={false}
							text={t.isEmpty ? <em>{t.name}</em> : t.name}
							key={t.key}
						/>
					))}
				</List>
			</Collapse>
		</div>
	);
};
