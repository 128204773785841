import { IProductCatalogItem } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const productCatalogItemToLine = (t: IProductCatalogItem): ILine => {
	return {
		id: t.id,
		description: t.identifier,
		productCatalogItemId: t.id,
		quantity: 1,
		productCatalogItem: t,
		inventoryItemId: undefined,
		inventoryItem: undefined,
	};
};
