import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import {
	IArticlePropertyKey,
	IArticlePropertyKeyModel,
	userDefinedArticlePropertyKeysCommand_new,
	userDefinedArticlePropertyKeysCommand_update,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { languageRecord } from 'localization/languageRecord';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import * as yup from 'yup';

interface IModel extends IArticlePropertyKeyModel {
	label: string;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			label: yup.string(),
			labelEn: yup.string(),
			labelFr: yup.string(),
			labelNl: yup.string(),
		})
		.defined();
};

const emptyValues: IModel = {
	label: '',
	labelEn: '',
	labelFr: '',
	labelNl: '',
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	item?: IArticlePropertyKey;
}

export const ArticlePropertyKeyModelForm = ({ confirm, item, ...rest }: IProps) => {
	const strings = useLocalization();
	const [update, isUpdating] = useFormSubmit(userDefinedArticlePropertyKeysCommand_update);
	const [add, isAdding] = useFormSubmit(userDefinedArticlePropertyKeysCommand_new);
	const [isSameLabel, setIsSameLabel] = useState<boolean>(item === undefined ? true : item.isSameForEveryLanguage);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const model: IArticlePropertyKeyModel = {
			labelFr: isSameLabel ? values.label : values.labelFr,
			labelEn: isSameLabel ? values.label : values.labelEn,
			labelNl: isSameLabel ? values.label : values.labelNl,
		};
		if (item) {
			const r = await update(item.userDefinedId!, model);
			if (handleFormResponse(r, helpers)) {
				confirm(item.userDefinedId!);
			}
		} else {
			const r = await add(model);
			if (handleFormResponse(r, helpers)) {
				confirm(r.result.objectId);
			}
		}
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={
				item
					? {
							label: item.labelNl!,
							labelEn: item.labelEn!,
							labelFr: item.labelFr!,
							labelNl: item.labelNl!,
					  }
					: emptyValues
			}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					isSubmitting={isAdding || isUpdating}
					item={item}
					isSameLabel={isSameLabel}
					setIsSameLabel={setIsSameLabel}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	item: IArticlePropertyKey | undefined;
	isSameLabel: boolean;
	setIsSameLabel: React.Dispatch<React.SetStateAction<boolean>>;
}

const InnerDialog = ({ cancel, isSubmitting, item, isSameLabel, setIsSameLabel, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const props = useFormikContext<IModel>();

	useLazyEffect(() => {
		setFormValue<IModel>('label', '', props);
		setFormValue<IModel>('labelNl', '', props);
		setFormValue<IModel>('labelFr', '', props);
		setFormValue<IModel>('labelEn', '', props);
		// eslint-disable-next-line
	}, [isSameLabel]);

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='xl'>
			<DialogTitle>{item ? strings.editFieldToFill : strings.newFieldToFill}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'
				style={{ minWidth: 400 }}>
				<ExtendedRadioGroup<boolean>
					selected={isSameLabel}
					setSelected={setIsSameLabel}
					options={[
						{ value: true, label: strings.sameLabelForEveryLanguage },
						{ value: false, label: strings.differentLabelPerLanguage },
					]}
				/>
				<DividerWithCaption caption={strings.form} />
				{isSameLabel === true && (
					<FormTextField<IModel>
						label={`${strings.label} (${languageRecord['nl']}, ${languageRecord['fr']}, ${languageRecord['en']})`}
						name='label'
					/>
				)}
				{isSameLabel === false && (
					<>
						<FormTextField<IModel>
							label={`${strings.label} (${languageRecord['nl']})`}
							name='labelNl'
						/>
						<FormTextField<IModel>
							label={`${strings.label} (${languageRecord['fr']})`}
							name='labelFr'
						/>
						<FormTextField<IModel>
							label={`${strings.label} (${languageRecord['en']})`}
							name='labelEn'
						/>
					</>
				)}
			</DialogContent>
			<CancelSubmitFormDialogActions
				cancel={cancel}
				submitText={item ? strings.update : strings.add}
				isSubmitting={isSubmitting}
			/>
		</Dialog>
	);
};
