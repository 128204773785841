import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	style?: React.CSSProperties;
}

export const SalesCommissionStatusChip = ({ style }: IProps) => {
	const strings = useLocalization();
	return (
		<RectangularChip
			label={strings.commission}
			fill
			color='success'
			style={style}
		/>
	);
};
