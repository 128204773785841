import { ApiContext } from 'framework/context/ApiContext';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { meta_build } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { build } from '../build';
import { MustUpdateWall } from './MustUpdateWall';

export const AppUpdateWall: React.FC = ({ children }: any) => {
	const [serverBuild] = useApiEffect(meta_build);
	const { mustUpdateFrontEnd } = useContext(ApiContext);

	if (mustUpdateFrontEnd) {
		return (
			<MustUpdateWall
				serverBuild={serverBuild ?? 0}
				build={build}
			/>
		);
	} else {
		return children;
	}
};
