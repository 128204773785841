import { useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { calcInventoryItemDetailRoute } from 'app/main/inventoryManagement/routes';
import { SerialNumberIcon } from 'framework/components/icons/SerialNumberIcon';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { INoahDeviceInventoryItemMapDetail } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { XViewBadgeIconButton } from 'shared/icons/XViewBadgeIconButton';

interface IProps {
	item: INoahDeviceInventoryItemMapDetail;
}

export const InventoryItemHeader = ({ item }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { push } = useHistory();

	if (item.inventoryItemId) {
		return (
			<div
				className='df-row-ac'
				style={{ color: theme.palette.success.main }}>
				<TooltipWithSpan title={strings.foundAtInventoryItems}>
					<CheckIcon style={{ marginRight: 4 }} />
				</TooltipWithSpan>
				<XViewBadgeIconButton
					size='small'
					onClick={() => push(calcInventoryItemDetailRoute(item.inventoryItemId!))}>
					<SerialNumberIcon fontSize='small' />
				</XViewBadgeIconButton>
			</div>
		);
	} else {
		return (
			<TooltipWithSpan title={strings.notFoundAtInventoryItems}>
				<ClearIcon style={{ marginRight: 4, color: theme.palette.error.main }} />
			</TooltipWithSpan>
		);
	}
};
