import { DialogProps } from '@material-ui/core';
import React from 'react';
import { BoolExtendedRadioGroupForm } from 'framework/forms/BoolExtendedRadioGroupForm';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { documentsCommand_disableMustSign, documentsCommand_enableMustSign, IDocument } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	document: IDocument;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const DocumentMustSignForm = ({ document, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [enable] = useFormSubmit(documentsCommand_enableMustSign);
	const [disable] = useFormSubmit(documentsCommand_disableMustSign);

	const onSubmit = async (val: boolean) => {
		const r = val ? await enable(document.id) : await disable(document.id);
		return r;
	};

	return (
		<BoolExtendedRadioGroupForm
			open
			formTitle={strings.needsASignatureQuestion}
			trueCaption={strings.thisDocumentMustBeReplacedWithAScanWithSignature}
			trueLabel={strings.scanSignatureLater}
			falseCaption={strings.noSignatureIsNeededForThisDocument}
			falseLabel={strings.noSignatureNeeded}
			submitFunction={onSubmit}
			confirm={confirm}
			cancel={cancel}
			initialValue={document.mustSign}
			submitText={strings.change}
		/>
	);
};
