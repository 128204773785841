import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { ITender, TenderProcessStatus } from 'gen/ApiClient';
import React from 'react';
import { RecordContext } from 'shared/records/RecordContext';

const colorRecord: Record<TenderProcessStatus, ColorX> = {
	ConvertedToSales: 'success',
	Created: 'info',
	Expired: 'warning',
	Lost: 'error',
	Deleted: 'error',
};

interface IProps {
	item: ITender;
	style?: React.CSSProperties;
}

export const TenderStatusChipX = ({ item, style }: IProps) => {
	const { tenderProcessStatusRecord } = React.useContext(RecordContext);

	return (
		<ChipX
			style={style}
			label={tenderProcessStatusRecord[item.status as TenderProcessStatus]}
			color={colorRecord[item.status as TenderProcessStatus]}
		/>
	);
};
