import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

interface IProps {
	isExpanded: boolean;
	style?: React.CSSProperties;
}

export const ExpandLessOrMoreIcon = ({ isExpanded, style }: IProps) => {
	return isExpanded ? <ExpandLessIcon style={style} /> : <ExpandMoreIcon style={style} />;
};
