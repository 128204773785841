import React, { useState } from 'react';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import {
	IInventoryItemsQueryParamsForPurchaseOrCreditPurchase,
	inventoryItemsQuery_queryForPurchaseOrCreditPurchase,
	IProductCatalogQueryParamsForPurchase,
	productCatalogQuery_queryForPurchase,
} from 'gen/ApiClient';
import { DefaultInventoryItemsQueryParamsForPurchaseOrCreditPurchase } from 'shared/searchAndSelect/DefaultInventoryItemsQueryParamsForPurchase';
import { SearchContext } from './SearchContext';

const DefaultPcParams: IProductCatalogQueryParamsForPurchase = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	types: [],
	categories: [],
	subcategories: [],
	hasSerialNumber: undefined,
};

interface IProps {
	children: any;
}

export const SearchProvider = ({ children }: IProps) => {
	const [pcParams, setPcParams] = useState<IProductCatalogQueryParamsForPurchase>(DefaultPcParams);
	const [pcQueryResult] = useApiEffect(productCatalogQuery_queryForPurchase, pcParams);
	const [iiParams, setIIParams] = useState<IInventoryItemsQueryParamsForPurchaseOrCreditPurchase>(DefaultInventoryItemsQueryParamsForPurchaseOrCreditPurchase);
	const [iiQueryResult] = useApiEffect(inventoryItemsQuery_queryForPurchaseOrCreditPurchase, iiParams);

	return (
		<SearchContext.Provider
			value={{
				pcParams: pcParams,
				pcQueryResult: pcQueryResult,
				setPcParams: setPcParams,
				iiParams: iiParams,
				iiQueryResult: iiQueryResult,
				setIIParams: setIIParams,
			}}>
			{children}
		</SearchContext.Provider>
	);
};
