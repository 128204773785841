import { Link } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageBreadcrumbIcon } from './PageBreadcrumbIcon';

interface IProps {
	text: string;
	route: string;
	icon?: React.ReactNode;
}

export const PageBreadcrumb = ({ text, route, icon }: IProps) => {
	const { push } = useHistory();

	return (
		<Link
			component='button'
			variant='body2'
			color='textPrimary'
			onClick={() => push(route)}
			style={{ fontWeight: 500 }}>
			<div className='df-row-ac'>
				{icon && (
					<PageBreadcrumbIcon
						icon={icon}
						style={{ marginRight: 2 }}
					/>
				)}
				<span>{text}</span>
			</div>
		</Link>
	);
};
