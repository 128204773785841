import { DialogProps } from '@material-ui/core';
import React from 'react';
import { generalPractitionersCommand_patchOfficeAddress, IAddressModel, IGeneralPractitioner, IOfficeData } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { AddressForm } from 'shared/address/AddressForm';

interface IProps extends DialogProps {
	generalPractitioner: IGeneralPractitioner;
	office: IOfficeData;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

const toModel = (office: IOfficeData): IAddressModel => {
	return {
		addressLine: office.addressLine,
		zip: office.zip,
		city: office.city,
		country: office.country,
	};
};

export const PatchGeneralPractitionerOfficeAddressForm = ({ generalPractitioner, office, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<AddressForm
			cancel={cancel}
			model={toModel(office)}
			confirm={confirm}
			submitFunction={model =>
				generalPractitionersCommand_patchOfficeAddress({ generalPractitionerId: generalPractitioner.id, officeId: office.id, address: model })
			}
			successMessage={strings.addressUpdated}
			{...rest}
		/>
	);
};
