import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, Typography, useTheme } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import ScannerIcon from '@material-ui/icons/Scanner';
import { FileDownloadOutlineIcon } from 'framework/components/icons/FileDownloadOutlineIcon';
import { FileSearchOutlineIcon } from 'framework/components/icons/FileSearchOutlineIcon';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { formatDate } from 'framework/utils/date/formatDate';
import { IPrescriptionScan } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { usePdfViewerWithDocument } from 'shared/dialogs/usePdfViewerWithDocument';
import { ScanStatusFibers } from './ScanStatusFibers';

interface IProps {
	scan: IPrescriptionScan;
	requiredStep: number;
	onClick: VoidFunction;
	onClickScan: VoidFunction;
}

export const ScanListItem = ({ scan, requiredStep, onClick, onClickScan }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [isHovered, enter, leave] = useStateBoolean(false);
	const openPdf = usePdfViewerWithDocument();

	return (
		<div
			onMouseEnter={enter}
			onMouseLeave={leave}>
			<ListItem
				disableGutters
				button
				onClick={onClick}>
				<ListItemIcon style={{ minWidth: '35px' }}>{scan.step < requiredStep ? <StatusIcon status='warning' /> : <DescriptionIcon />}</ListItemIcon>
				<div className='df-row-ac'>
					<div className='df-col'>
						<Typography
							variant='caption'
							color='textSecondary'
							style={{ marginRight: theme.spacing(0.5) }}>
							{formatDate(scan.created)}
						</Typography>
						<Typography
							variant='body2'
							noWrap
							display='block'
							style={{ marginRight: theme.spacing(1) }}>
							{strings.prescriptionAttachment17}
						</Typography>
					</div>
					<ScanStatusFibers
						scan={scan}
						requiredStep={requiredStep}
					/>
				</div>
				<ListItemSecondaryAction style={{ visibility: isHovered ? 'visible' : 'collapse' }}>
					{scan.step < 4 && (
						<IconButton
							edge='end'
							onClick={onClickScan}>
							<ScannerIcon />
						</IconButton>
					)}
					<IconButton
						edge='end'
						onClick={() => openPdf(strings.prescriptionAttachment17, scan.id)}>
						<FileSearchOutlineIcon />
					</IconButton>
					<IconButton edge='end'>
						<FileDownloadOutlineIcon />
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
		</div>
	);
};
