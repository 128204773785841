import { Divider } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import LinkIcon from '@material-ui/icons/Link';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { InnerPageXWithStickySidebar } from 'framework/components/layout/InnerPageXWithStickySidebar';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { noahUserSetupsCommand_linkAll } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { NoahUserSetupListItem } from './NoahUserSetupListItem';
import { NoahUserSetupsCopyAllRequestForm } from './NoahUserSetupsCopyAllRequestForm';
import { NoahUserSetupsListFilterCard } from './NoahUserSetupsListFilterCard';
import { NoahUserSetupsQueryContext } from './NoahUserSetupsQueryContext';

interface IProps extends IReloadTriggerProps {
	reload: VoidFunction;
}

export const NoahUserSetupsListTab = ({ reload, reloadTrigger }: IProps) => {
	const strings = useLocalization();
	const { reload: reloadQuery, queryResult, params, setParams } = useQueryContext(NoahUserSetupsQueryContext);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadQuery();
	}, [reloadTrigger]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onLinkAll = () => {
		open(
			<StringModelForm
				open
				initialValue={``}
				confirm={confirm}
				cancel={cancel}
				submitFunction={val => noahUserSetupsCommand_linkAll({ clientId: val })}
				formTitle={`Link to a client id`}
				submitText={strings.update}
				label={`Client id`}
			/>
		);
	};

	const onCopyAll = () => {
		open(
			<NoahUserSetupsCopyAllRequestForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<InnerPageXWithStickySidebar
			maxWidth={1280}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<NoahUserSetupsListFilterCard
						params={params}
						setParams={setParams}
						metadata={queryResult.metadata}
						style={{ minWidth: 220 }}
					/>
					<div className='fg1'></div>
					<TextLinkButton
						startIcon={<LinkIcon />}
						onClick={onLinkAll}>
						{`Link all`}
					</TextLinkButton>
					<TextLinkButton
						startIcon={<FileCopyOutlinedIcon />}
						onClick={onCopyAll}>
						{`Copy all`}
					</TextLinkButton>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16, paddingLeft: 8 }}>
				<CardX className='w100'>
					<div
						className='df-row'
						style={{ gap: 8 }}>
						<Typography16pxBold>{strings.results}</Typography16pxBold>
						<div className='fg1'></div>
					</div>
					<Divider style={{ marginTop: 8 }} />
					{queryResult.values.map(t => (
						<NoahUserSetupListItem
							key={t.id}
							item={t}
							reload={reload}
						/>
					))}
					<QueryResultPaginationX
						queryResult={queryResult}
						setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
						style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
					/>
				</CardX>
			</div>
		</InnerPageXWithStickySidebar>
	);
};
