import { Divider } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import UndoIcon from '@material-ui/icons/Undo';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { LabelledChipsList } from 'framework/components/chips/LabelledChipsList';
import { CashPlusIcon } from 'framework/components/icons/CashPlusIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IPurchase, purchasesCommand_patchExternalReference, purchasesCommand_patchPayBeforeDate, purchasesCommand_patchReferenceDate } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PaymentForPurchaseForm } from '../../payments/forms/purchases/PaymentForPurchaseForm';
import { PurchaseLabelsForm } from '../forms/PurchaseLabelsForm';
import { PurchaseCreditNoteForm } from '../forms/creditNote/PurchaseCreditNoteForm';
import { PurchaseDetailDocumentContent } from './PurchaseDetailDocumentContent';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';

interface IProps {
	item: IPurchase;
	reload: VoidFunction;
}

export const PurchaseDetailPageSidebar = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onPatchExternalReference = () => {
		open(
			<StringModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.changeSomething(strings.externalReference)}
				label={strings.externalReference}
				initialValue={item.externalReference}
				cancel={cancel}
				submitText={strings.update}
				submitFunction={val => purchasesCommand_patchExternalReference({ purchaseId: item.id, externalReference: val })}
			/>
		);
	};

	const onPatchReferenceDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				formTitle={strings.changeWhat(strings.referenceDate)}
				label={strings.referenceDate}
				initialValue={item.purchaseDate}
				submitFunction={date => purchasesCommand_patchReferenceDate({ purchaseId: item.id, referenceDate: date })}
			/>
		);
	};

	const onPatchPayBeforeDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				formTitle={strings.changeWhat(strings.toPayBeforeDate)}
				label={strings.toPayBeforeDate}
				initialValue={item.validUntil}
				submitFunction={date => purchasesCommand_patchPayBeforeDate({ purchaseId: item.id, payBeforeDate: date })}
			/>
		);
	};

	const onPayment = () => {
		open(
			<PaymentForPurchaseForm
				open
				confirm={confirm}
				cancel={cancel}
				purchaseId={item.id}
			/>
		);
	};

	const onCreditNote = () => {
		open(
			<PurchaseCreditNoteForm
				open
				purchaseId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onEditLabels = () => {
		open(
			<PurchaseLabelsForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<div
			className='df-col h100'
			style={{
				padding: 16,
				paddingRight: 8,
				// width: 250,
			}}>
			<div className='df-col gap-6'>
				<CaptionVerticalLabelledPropertyX label={strings.reference}>{item.reference}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.externalReference}
					edit={onPatchExternalReference}>
					<StringValueOrNotApplicable>{item.externalReference}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.entryDate}>{formatDate(item.created)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.referenceDate}
					edit={onPatchReferenceDate}>
					{formatDate(item.purchaseDate)}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.payBefore}
					edit={onPatchPayBeforeDate}>
					{formatDate(item.validUntil)}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.totalInVat}>{formatCurrency(item.priceInVat)}</CaptionVerticalLabelledPropertyX>
				<Typography16pxBold>{strings.paymentDetails}</Typography16pxBold>
				<CaptionVerticalLabelledPropertyX label={strings.amountToPay}>{formatCurrency(item.amountToPay)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.amountPaid}>{formatCurrency(item.amountPaid)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.outstandingBalance}>{formatCurrency(item.outstandingBalance)}</CaptionVerticalLabelledPropertyX>
			</div>
			<Divider style={{ marginTop: 16, marginBottom: 8 }} />
			<PurchaseDetailDocumentContent
				item={item}
				reload={reload}
			/>
			<Divider style={{ marginTop: 16, marginBottom: 8 }} />
			<div
				className='df-col fg1 gap-4'
				style={{ overflowY: 'hidden' }}>
				<TextLinkButton
					style={{ fontSize: 12 }}
					color='textSecondary'
					onClick={onEditLabels}>
					<div>{strings.labels}</div>
					<div className='fg1'></div>
					<SettingsOutlinedIcon style={{ fontSize: 16 }} />
				</TextLinkButton>
				<div
					className='fg1'
					style={{ overflowY: 'auto' }}>
					{item.labels.length > 0 && <LabelledChipsList labels={item.labels} />}
					{item.labels.length === 0 && <NoRecordsFound variant='caption'>{strings.noLabelsAssignedYet}</NoRecordsFound>}
				</div>
			</div>
			<div className='fg1'></div>
			<DividerWithMargin />
			<div className='df-col gap-12'>
				<TextLinkButton
					startIcon={<CashPlusIcon />}
					disabled={item.isFullyPaid}
					onClick={onPayment}>
					{strings.registerWhat(strings.payment)}
				</TextLinkButton>
				{item.canCreateCreditNote && (
					<TextLinkButton
						startIcon={<UndoIcon />}
						onClick={onCreditNote}>
						{strings.createWhat(strings.creditNote)}
					</TextLinkButton>
				)}
			</div>
		</div>
	);
};
