import { Button, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { DialogX } from 'framework/dialogs/DialogX';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useAccordionState } from 'framework/utils/useAccordionState';
import {
	articlesInquiriesCommand_assignLineFromStock,
	IArticlesInquiryLineDto,
	IAssignInventoryItemToArticlesInquiryFromStockQueryParams,
	IInventoryItem,
	inventoryItemsQuery_queryAssignToArticlesInquiryLine,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect } from 'react';
import { InventoryItemToAssignListItem } from './InventoryItemToAssignListItem';

const DefaultParams: IAssignInventoryItemToArticlesInquiryFromStockQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'serialNumber' },
	pageParams: { index: 1, size: 10 },
	articlesInquiryId: undefined as any,
	articlesInquiryLineId: undefined as any,
};

interface IProps extends DialogProps {
	articlesInquiryId: string;
	articlesInquiryLine: IArticlesInquiryLineDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
	previous?: VoidFunction;
}

export const AssignInventoryItemFromStockDialog = ({ articlesInquiryId, articlesInquiryLine, cancel, confirm, previous, ...rest }: IProps) => {
	const strings = useLocalization();
	const [selectedInventoryItem, setSelectedInventoryItem] = useAccordionState<IInventoryItem | undefined>();
	const [params, setParams, queryResult] = useApiEffectWithParams(inventoryItemsQuery_queryAssignToArticlesInquiryLine, {
		...DefaultParams,
		articlesInquiryId: articlesInquiryId,
		articlesInquiryLineId: articlesInquiryLine.id,
	});
	const [assign, isAssigning] = useFormSubmit(articlesInquiriesCommand_assignLineFromStock);
	const notify = useSnackbarNotify();

	useEffect(() => {
		setSelectedInventoryItem(undefined);
		// eslint-disable-next-line
	}, [params]);

	const onAssign = async () => {
		const r = await assign({
			articlesInquiryId: articlesInquiryId,
			articlesInquiryLineId: articlesInquiryLine.id,
			inventoryItemId: selectedInventoryItem!.id,
			quantity: 1,
		});
		handleValidateResponseBase(r, e => notify(e, 'error'), confirm);
	};

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<DialogX
			{...rest}
			fullHeight
			minWidth={600}>
			<CloseButtonOnAbsolutePosition onClick={cancel} />
			<DialogTitle>
				<span>{`${strings.assignFromStock}: ${articlesInquiryLine.description}`}</span>
				<SearchbarX
					placeholder={strings.searchVerb}
					value={params.searchString}
					onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
					style={{ width: 500, marginTop: 8 }}
				/>
			</DialogTitle>
			<DialogContent
				dividers
				className='df-col gap-16'>
				{queryResult.values.map(t => (
					<InventoryItemToAssignListItem
						key={t.id}
						item={t}
						onSelect={() => setSelectedInventoryItem(t.data)}
						articlesInquiryLine={articlesInquiryLine}
						isSelected={t.id === selectedInventoryItem?.id}
					/>
				))}
				{queryResult.unfilteredCount === 0 && <em>{strings.noInventoryItemsFound}</em>}
				{queryResult.filteredCount === 0 && queryResult.unfilteredCount > 0 && <em>{strings.noInventoryItemsFoundChangeSearch}</em>}
			</DialogContent>
			<DialogActions
				className='df-col gap-4'
				style={{ marginLeft: 'auto' }}>
				<QueryResultPaginationX
					queryResult={queryResult}
					setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
					style={{ justifyContent: 'end' }}
				/>
				<div
					className='df-row-ac'
					style={{ marginLeft: 'auto' }}>
					{previous && (
						<Button
							onClick={previous}
							color='primary'>
							{strings.previous}
						</Button>
					)}
					<Button
						onClick={cancel}
						color='primary'>
						{strings.cancel}
					</Button>
					<LoaderButton
						onClick={onAssign}
						color='primary'
						isLoading={isAssigning}
						disabled={selectedInventoryItem === undefined || isAssigning}>
						{strings.selectThisArticle}
					</LoaderButton>
				</div>
			</DialogActions>
		</DialogX>
	);
};
