import { Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { Breadcrumb } from './Breadcrumb';
import { IBreadcrumb } from './IBreadcrumb';

interface IProps {
	title: string | undefined;
	secondaryTitle?: React.ReactNode;
	breadcrumbs?: IBreadcrumb[];
	showHome?: boolean;
	style?: React.CSSProperties;
	secondaryBreadcrumbs?: IBreadcrumb[];
}

export const Breadcrumbs = ({ title, breadcrumbs, secondaryTitle, showHome = false, style, secondaryBreadcrumbs }: IProps) => {
	const strings = useLocalization();

	return (
		<div
			className='df-row-ac'
			style={{ height: '28px', ...style }}>
			{showHome && (
				<Breadcrumb
					icon={<HomeIcon />}
					text={strings.start}
				/>
			)}
			{breadcrumbs &&
				breadcrumbs.map(t => (
					<Breadcrumb
						key={t.text}
						icon={t.icon}
						route={t.route}
						text={t.text}
					/>
				))}
			<div style={{ marginBottom: '5px' }}>
				<Typography variant='h1'>{title}</Typography>
			</div>
			{secondaryTitle && <div style={{ display: 'flex', marginBottom: '5px', marginLeft: 8 }}>{secondaryTitle}</div>}
			{secondaryBreadcrumbs &&
				secondaryBreadcrumbs.map(t => (
					<Breadcrumb
						key={t.text}
						icon={t.icon}
						route={t.route}
						text={t.text}
						slashBefore
					/>
				))}
		</div>
	);
};
