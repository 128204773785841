import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface IProps {
	route?: string;
	onClick?: VoidFunction;
	style?: React.CSSProperties;
}

export const PageSettingsButtonX = ({ route, onClick, style }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();

	return (
		<TooltipWithSpan title={strings.changeSettings}>
			<ColorOnHoverIconButton
				edge='end'
				size='small'
				color='primary'
				onClick={() => {
					if (route) {
						push(route);
					} else if (onClick) {
						onClick();
					} else {
						// no - op
					}
				}}
				style={style}>
				<SettingsOutlinedIcon />
			</ColorOnHoverIconButton>
		</TooltipWithSpan>
	);
};
