import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { FileSearchOutlineIcon } from 'framework/components/icons/FileSearchOutlineIcon';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { ISpeechInNoiseTest, trialReportsCommand_deleteSpeechInNoise } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { useViewDocumentation } from 'shared/utils/useViewDocumentation';
import { SpeechInNoiseTestForm } from './SpeechInNoiseTestForm';

interface IProps extends DialogProps {
	id: string;
	item: ISpeechInNoiseTest;
	close: VoidFunction;
}

export const SpeechInNoiseTestDialog = ({ id, item, close, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { open, confirm, cancel, isStacked } = useDialogsContext(close);
	const onViewSinRizivInfo = useViewDocumentation('sin-riziv-info');

	if (item === undefined) {
		return <div></div>;
	}

	const onEdit = () => {
		open(
			<SpeechInNoiseTestForm
				open
				trialId={id}
				cancel={cancel}
				confirm={confirm}
				test={item}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.test)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => trialReportsCommand_deleteSpeechInNoise(id)}
				accept={close}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			maxWidth='sm'
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<GroupWorkIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{strings.speechInNoise}
					</Typography>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.results} />
				<PropertyInGrid
					label={strings.usedList}
					value={item.list.name}
				/>
				<PropertyInGrid
					label={strings.normValue}
					value={item.list.normScoreInDbSnrFor50Percent + ' dB SNR @ 50%'}
				/>
				<PropertyInGrid
					label={strings.scoreWithoutEquipment}
					value={item.unaidedScoreInDbSnrFor50Percent + ' dB SNR @ 50%'}
				/>
				<PropertyInGrid
					label={strings.differenceWithNorm}
					value={item.scoreDifferenceWithNormInDbSnrFor50Percent + 'dB SNR @ 50%'}
				/>
				<PropertyInGrid
					label={strings.scoreWithEquipment}
					value={item.aidedScoreInDbSnrFor50Percent + ' dB SNR @ 50%'}
				/>
				<PropertyInGrid
					label={strings.scoreWithEquipmentAtSNROfSRT}
					value={item.aidedScoreAtDbSnrOfSrt + ' %'}
				/>
				<PropertyInGrid
					label={strings.improvement}
					value={item.improvementAidedVersusUnaidedInDbSnrFor50Percent + ' dB SNR @ 50%'}
				/>
				<PropertyInGrid
					label={strings.speechIntelligibilityImprovement}
					value={item.speechIntelligibilityImprovement + ' %'}
				/>
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								onClick={onViewSinRizivInfo}
								startIcon={<FileSearchOutlineIcon />}>
								{strings.viewRIZIVDocumentation}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={onEdit}
								startIcon={<EditIcon />}>
								{strings.edit}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={onDelete}
								color='secondary'
								startIcon={<DeleteOutlineIcon />}>
								{strings.delete}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
