import { DialogProps } from '@material-ui/core';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { ILocation, locationsCommand_patchName } from 'gen/ApiClient';

interface IProps extends DialogProps {
	location: ILocation;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LocationNameForm = ({ location, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<StringModelForm
			label={strings.name}
			formTitle={strings.changeWhat(strings.name)}
			notifyMessage={strings.locationUpdated}
			submitFunction={val => locationsCommand_patchName({ locationId: location.id, name: val })}
			initialValue={location.name ?? ''}
			submitText={strings.update}
			{...rest}
		/>
	);
};
