import { IconButton, Step, StepLabel, Typography, useTheme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { FixedWidthCard, IFixedWidthCardProps } from 'framework/components/FixedWidthCard';
import { IRequestForInsuranceAllowance, IRfiaDetail } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { VerticalProcess } from 'shared/process/VerticalProcess';
import { FeedbackAdvisoryPhysicianStep } from './mainProcess/FeedbackAdvisoryPhysicianStep';
import { HandoverEquipmentStep } from './mainProcess/HandoverEquipmentStep';
import { MedicalPrescriptionStep } from './mainProcess/MedicalPrescriptionStep';
import { PrescriptionForTrialStep } from './mainProcess/PrescriptionForTrialStep';
import { TrialStep } from './mainProcess/TrialStep';
import { TrialStepContent } from './mainProcess/TrialStepContent';

// import { FilePlusOutlineIcon } from 'framework/components/icons/FilePlusOutlineIcon';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
// import AttachFileIcon from '@material-ui/icons/AttachFile';
// import PublishIcon from '@material-ui/icons/Publish';
// import { UploadIcon } from 'framework/components/icons/UploadIcon';
// import { TextBoxOutlineIcon } from 'framework/components/icons/TextBoxOutlineIcon';
// import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
// import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
// import ZoomInIcon from '@material-ui/icons/ZoomIn';
// import VisibilityIcon from '@material-ui/icons/Visibility';

const calcShowMedicalStep = (item: IRequestForInsuranceAllowance): boolean =>
	(item.isFailedTrial && item.hasApprovedMedicalPrescription) || item.isFailedTrial === false;

const calcShowAdvPhysicianStep = (item: IRequestForInsuranceAllowance): boolean =>
	(item.isFailedTrial && item.hasApprovedFeedbackAdvisoryPhysician) || item.isFailedTrial === false;

interface IProps extends IFixedWidthCardProps {
	item: IRfiaDetail;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const ProcessCard = ({ item, reload, ...props }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const showActualMedicalStep = useMemo(() => calcShowMedicalStep(item.rfia), [item.rfia]);
	const showActualAdvPhysStep = useMemo(() => calcShowAdvPhysicianStep(item.rfia), [item.rfia]);

	return (
		<FixedWidthCard {...props}>
			<CardTitleTypography
				withPadding
				title={strings.reimbursementProcedure}
			/>
			<VerticalProcess>
				<PrescriptionForTrialStep
					rfia={item.rfia}
					reload={reload}
				/>
				{item.trialSteps.map(t => (
					<TrialStep
						key={t.id}
						rfia={item.rfia}
						steps={item.trialSteps}
						step={t}
						reload={reload}
					/>
				))}
				{item.rfia.hasTrialStep === false && (
					<Step
						completed={false}
						active={item.rfia.isCancelled === false}>
						<StepLabel error={item.rfia.isFailedTrial}>
							<div className='df-col'>
								<div className='df-row-ac'>
									<div>{`${strings.equipmentTestTitle}${item.rfia.isFailedTrial ? ` (${strings.failed.toLowerCase()})` : ''}`}</div>
									<IconButton
										size='small'
										style={{ marginLeft: theme.spacing(0.5), color: item.rfia.isFailedTrial ? theme.palette.error.main : theme.palette.primary.main }}>
										<HelpOutlineIcon fontSize='small' />
									</IconButton>
								</div>
							</div>
						</StepLabel>
						<TrialStepContent
							rfia={item.rfia}
							reload={reload}
						/>
					</Step>
				)}
				{item.rfia.medicalPrescriptionSteps
					.filter(t => t.isDisapproved)
					.map(t => (
						<MedicalPrescriptionStep
							rfia={item.rfia}
							step={t}
							reload={reload}
						/>
					))}
				{showActualMedicalStep && (
					<MedicalPrescriptionStep
						rfia={item.rfia}
						step={item.actualMedicalPrescriptionStep}
						reload={reload}
					/>
				)}
				{item.rfia.feedbackAdvisoryPhysicianSteps
					.filter(t => t.isDisapproved)
					.map(t => (
						<FeedbackAdvisoryPhysicianStep
							rfia={item.rfia}
							step={t}
							reload={reload}
						/>
					))}
				{showActualAdvPhysStep && (
					<FeedbackAdvisoryPhysicianStep
						rfia={item.rfia}
						step={item.actualFeedbackAdvisoryPhysicianStep}
						reload={reload}
					/>
				)}
				<HandoverEquipmentStep
					rfia={item.rfia}
					isHifExist={item.isHifExist}
					reload={reload}
				/>
				{item.rfia.isCancelled && (
					<Step>
						<StepLabel error>
							<div className='df-col'>
								<div>{strings.thisProcessIsCancelled}</div>
								<Typography variant='caption'>{`${strings.reason}: ${item.rfia.cancelReason}`}</Typography>
							</div>
						</StepLabel>
					</Step>
				)}
			</VerticalProcess>
		</FixedWidthCard>
	);
};
