import { useTheme } from '@material-ui/core';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import { ChipX } from 'framework/components/chips/ChipX';
import { IAnnotation, IAnnotationType } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { AnnotationAttachmentsComponent } from './AnnotationAttachmentsComponent';
import { AnnotationDate } from './AnnotationDate';
import { AnnotationFab } from './AnnotationFab';

interface IProps {
	item: IAnnotation;
	types: IAnnotationType[];
	reload: VoidFunction;
	onClickAnnotationType: (id: string) => void;
	showDeleted: boolean;
}

export const AnnotationTimelineItem = ({ item, types, reload, onClickAnnotationType, showDeleted }: IProps) => {
	const theme = useTheme();
	const annotationType = useMemo(() => types.find(t => t.id === item.annotationTypeId), [item, types]);
	const color = useMemo(() => annotationType?.color ?? '', [annotationType]);
	const annotationLabel = useMemo(() => annotationType?.name ?? '', [annotationType]);

	return (
		<TimelineItem key={item.id}>
			<TimelineOppositeContent style={{ flex: 'unset', padding: 0 }} />
			<TimelineSeparator>
				<TimelineDot
					variant={item.hasType ? 'default' : 'outlined'}
					style={{ backgroundColor: item.hasType ? color : '#bdbdbd' }}
				/>
				<TimelineConnector style={{ backgroundColor: item.hasType ? color : '#bdbdbd' }} />
			</TimelineSeparator>
			<TimelineContent>
				<div className='df-col'>
					<div
						className='df-row-ac'
						style={{ marginBottom: 8 }}>
						<AnnotationDate item={item} />
						{item.hasType && (
							<ChipX
								onClick={() => onClickAnnotationType(item.annotationTypeId!)}
								style={{
									borderColor: color,
									backgroundColor: color,
									color: theme.palette.getContrastText(color),
									fontWeight: 500,
									marginLeft: 8,
								}}
								label={annotationLabel}
							/>
						)}
						<AnnotationFab
							item={item}
							style={{ marginLeft: 8 }}
							reload={reload}
						/>
					</div>
					<div style={{ whiteSpace: 'pre-wrap', textDecoration: item.isDeleted === true ? 'line-through' : 'unset' }}>{item.content}</div>
					<AnnotationAttachmentsComponent
						item={item}
						reload={reload}
						showDeleted={showDeleted}
					/>
				</div>
			</TimelineContent>
		</TimelineItem>
	);
};
