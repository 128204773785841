import { TenderExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createExportRecord = (strings: IStrings): Record<TenderExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		CompanyId: `${strings.vatCustomer} ID`,
		CountItems: `# ${strings.items}`,
		ForPatientId: `${strings.patient} ID`,
		HasSalesTransaction: `${strings.convertedToSales}?`,
		IsExpired: `${strings.expired}?`,
		IsForCompany: `${strings.forWho(strings.vatCustomer)}?`,
		IsForPatient: `${strings.forWho(strings.patient)}?`,
		IsLost: `${strings.lost}?`,
		PriceExVat: strings.priceExVat,
		PriceInVat: strings.priceInVat,
		RecipientName: strings.withRespectToShort,
		Reference: strings.reference,
		SalesId: `${strings.sale} ID`,
		Status: strings.status,
		ValidUntil: strings.validTo,
		VatAmount: strings.vatAmount,
	};
};
