import { PreloadCacheProvider } from 'framework/context/PreloadCacheProvider';
import { IPaymentMethodSummary, paymentMethodsQuery_cache, paymentMethodsQuery_concurrencyToken } from 'gen/ApiClient';
import React from 'react';
import { PreloadContext } from 'shared/context/PreloadContext';
import { PaymentMethodsPreloadCacheContext } from './PaymentMethodsPreloadCacheContext';

export const PaymentMethodsPreloadCacheProvider = ({ children }: any) => {
	const { paymentMethods } = React.useContext(PreloadContext);

	return (
		<PreloadCacheProvider<IPaymentMethodSummary[]>
			concurrencyF={paymentMethodsQuery_concurrencyToken}
			loadF={paymentMethodsQuery_cache}
			context={PaymentMethodsPreloadCacheContext}
			initialData={paymentMethods}>
			{children}
		</PreloadCacheProvider>
	);
};
