import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	InterchangeableTangibleType,
	IPatchProductCatalogItemTypeRequest,
	IProductCatalogItem,
	productCatalogCommand_patchType,
	ProductCatalogItemType,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchProductCatalogItemTypeRequest>({
		type: yup.mixed<ProductCatalogItemType>().required(),
		productCatalogItemId: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	item: IProductCatalogItem;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const SwitchTypeForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [submit, isSubmitting] = useFormSubmit(productCatalogCommand_patchType);
	const strings = useLocalization();
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IPatchProductCatalogItemTypeRequest, helpers: FormikHelpers<IPatchProductCatalogItemTypeRequest>) => {
		const r = await submit(values);
		handleValidateResponseBase(r, e => notify(e, 'error'), confirm);
	};

	return (
		<Formik<IPatchProductCatalogItemTypeRequest>
			validateOnMount
			initialValues={{
				type: item.type ?? '',
				productCatalogItemId: item.id,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.changeWhat(strings.type)}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormExtendedRadioGroupField<IPatchProductCatalogItemTypeRequest, InterchangeableTangibleType>
							label={strings.type}
							name='type'
							options={[
								{ value: 'HearingProtection', label: strings.hearingProtection, caption: strings.hearingProtectionNewArticleCaption },
								{ value: 'Earmould', label: strings.earmould, caption: strings.earMouldNewArticleCaption },
								{ value: 'Dome', label: strings.dome, caption: strings.domeNewArticleCaption },
								{ value: 'Receiver', label: strings.receiver, caption: strings.receiverNewArticleCaption },
								{ value: 'Charger', label: strings.charger, caption: strings.chargerNewArticleCaption },
								{ value: 'Other', label: strings.other, caption: strings.otherNewArticleCaption },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
