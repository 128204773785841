import { IProductCatalogItem } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const productCatalogItemToLine = (t: IProductCatalogItem): ILine => {
	return {
		isSelected: true,
		id: t.id,
		description: t.identifier,
		item: t,
		quantity: 1,
		unitListPrice: t.salesListPrice,
		unitPrice: t.salesListPrice,
		vatPercentage: t.vatPercentage,
	};
};
