import { Card } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DefaultCardContent } from 'framework/components/DefaultCardContent';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { IProductCatalogItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { useRenderTimeModel } from 'shared/timeUnit/useRenderTimeModel';
import { InsuranceOrProlongedWarrantyTermForm } from '../forms/InsuranceOrProlongedWarrantyTermForm';

interface IProps {
	item: IProductCatalogItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const InsuranceOrProlongedWarrantyTermCard = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const renderTimeModel = useRenderTimeModel();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onEdit = () => {
		open(
			<InsuranceOrProlongedWarrantyTermForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Card style={style}>
			<DefaultCardContent>
				<CardTitleTypography
					title={item.isInsurance ? `${strings.term} ${strings.insurance}` : `${strings.term} ${strings.prolongedWarranty}`}
					edit={onEdit}
				/>
				{item.insuranceOrProlongedWarrantyTerm !== undefined && item.insuranceOrProlongedWarrantyTerm !== null ? (
					<LabelledProperty
						label={strings.term}
						property={renderTimeModel(item.insuranceOrProlongedWarrantyTerm)}
					/>
				) : (
					<NoRecordsFound style={{ paddingTop: 8 }}>{strings.unlimited}</NoRecordsFound>
				)}
			</DefaultCardContent>
		</Card>
	);
};
