import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormIntegerFieldWithUnit } from 'framework/forms/FormIntegerFieldWithUnit';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	CalendarEventTimeUnit,
	CalendarEventTimeUnits,
	calendarEventTypesCommand_new,
	calendarEventTypesCommand_update,
	ICalendarEventType,
	ICalendarEventTypeModel,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect } from 'react';
import { CirclePicker } from 'react-color';
import { DefaultColors } from 'shared/color/DefaultColors';
import { useRenderTimeUnit } from 'shared/timeUnit/useRenderTimeUnit';
import * as yup from 'yup';
import { CalendarEventTypesCacheContext } from '../../context/CalendarEventTypesCacheContext';

const createSchema = (strings: IStrings) => {
	return yup.object<ICalendarEventTypeModel>({
		color: yup.string().required(),
		name: yup.string().required(),
		defaultDurationUnit: yup.string().required(),
		defaultDurationValue: yup.number().required(),
	});
};

const emptyValues: ICalendarEventTypeModel = {
	color: '',
	name: '',
	defaultDurationUnit: 'Hours' as CalendarEventTimeUnit,
	defaultDurationValue: 1,
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	calendarEventType?: ICalendarEventType;
}

export const CalendarEventTypeForm = ({ confirm, cancel, calendarEventType, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [create, isCreateSubmitting] = useFormSubmit(calendarEventTypesCommand_new);
	const [update, isUpdateSubmitting] = useFormSubmit(calendarEventTypesCommand_update);
	const [data] = usePreloadCacheContext(CalendarEventTypesCacheContext);
	const strings = useLocalization();

	const handleSubmit = async (values: ICalendarEventTypeModel, helpers: FormikHelpers<ICalendarEventTypeModel>) => {
		const r = calendarEventType ? await update(calendarEventType.id, values) : await create(values);
		if (handleFormResponse(r, helpers)) {
			notify(calendarEventType ? strings.changedWhat(strings.typeAppointment) : strings.addedWhat(strings.typeAppointment));
			confirm();
		}
	};

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<ICalendarEventTypeModel>
			validateOnMount
			initialValues={{ ...emptyValues, ...calendarEventType }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					isSubmitting={isCreateSubmitting || isUpdateSubmitting}
					cancel={cancel}
					calendarEventType={calendarEventType}
					allTypes={data}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	calendarEventType: ICalendarEventType | undefined;
	allTypes: ICalendarEventType[];
}

const InnerDialog = ({ cancel, isSubmitting, calendarEventType, allTypes, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<ICalendarEventTypeModel>();
	const strings = useLocalization();
	const renderTimeUnit = useRenderTimeUnit();

	useEffect(() => {
		if (calendarEventType !== undefined) {
			return;
		}
		// auto choose next color
		const selectedColors = allTypes.map(t => t.color);
		const remaining = DefaultColors.filter(t => selectedColors.indexOf(t) === -1);
		if (remaining.length > 0) {
			setFormValue<ICalendarEventTypeModel>('color', remaining[0], props);
		}
		// eslint-disable-next-line
	}, [calendarEventType, allTypes]);

	return (
		<Dialog
			{...rest}
			fullWidth
			maxWidth='xs'>
			<DialogTitle>{calendarEventType ? strings.changeWhat(strings.typeAppointment) : strings.addWhat(strings.typeAppointment)}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<FormTextField<ICalendarEventTypeModel>
					name='name'
					label={strings.name}
				/>
				<FormIntegerFieldWithUnit<ICalendarEventTypeModel, CalendarEventTimeUnit>
					name='defaultDurationValue'
					units={CalendarEventTimeUnits}
					label={strings.defaultDuration}
					unitName='defaultDurationUnit'
					defaultUnit='Hours'
					renderUnit={(val, unit) => renderTimeUnit(val, unit)}
				/>
				<DividerWithCaption caption='Kleur' />
				<div style={{ marginTop: 8, marginBottom: 16 }}>
					<CirclePicker
						color={props.values.color}
						onChange={e => setFormValue<ICalendarEventTypeModel>('color', e.hex, props)}
						circleSize={20}
						circleSpacing={8}
						colors={DefaultColors}
						width='170px'
					/>
				</div>
			</DialogContent>
			<CancelSubmitFormDialogActions
				submitText={calendarEventType ? strings.update : strings.create}
				isSubmitting={isSubmitting}
				cancel={cancel}
			/>
		</Dialog>
	);
};
