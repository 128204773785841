import { StatCardX } from 'framework/components/cards/StatCardX';
import { ISupplierAccountOverview } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { SupplierAccountDetailTabType } from './SupplierAccountDetailTabType';

interface IProps {
	metadata: ISupplierAccountOverview;
	setTab: (tab: SupplierAccountDetailTabType) => void;
	style?: React.CSSProperties;
}

export const SupplierAccountDetailQuickStats = ({ setTab, metadata, style }: IProps) => {
	const strings = useLocalization();

	return (
		<div
			className='df-row-ac gap-16'
			style={style}>
			<StatCardX
				style={{ width: 300 }}
				onClick={() => setTab('purchases')}
				title={strings.purchases}
				caption={`${strings.unpaid}/${strings.total}`}>
				<span style={{ fontSize: 24 }}>{`${metadata.countPurchasesWithOutstandingBalance}/${metadata.countPurchases}`}</span>
			</StatCardX>
			<StatCardX
				style={{ width: 300 }}
				onClick={() => setTab('payments')}
				title={strings.payments}
				caption={strings.quantity}>
				<span style={{ fontSize: 24 }}>{`${metadata.countPayments}`}</span>
			</StatCardX>
		</div>
	);
};
