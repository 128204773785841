import { DialogProps } from '@material-ui/core';
import React from 'react';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { rfiasCommand_registerSendDocumentsAdvisoryPhysician, rfiasQuery_settings } from 'gen/ApiClient';
import { RegisterSendDocumentsForm } from './RegisterSendDocumentsForm';

interface IProps extends DialogProps {
	rfiaId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}
export const RegisterSendDocumentsToAdvisoryPhysicianForm = ({ rfiaId, ...rest }: IProps) => {
	const [settings] = useApiEffect(rfiasQuery_settings);

	if (settings === undefined) {
		return <div></div>;
	}

	return (
		<RegisterSendDocumentsForm
			{...rest}
			submitFunction={val => rfiasCommand_registerSendDocumentsAdvisoryPhysician(rfiaId, val)}
			defaultExpectedReceptionTerm={settings.defaultExpectedReceptionTermForAdvisoryPhysician}
		/>
	);
};
