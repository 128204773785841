import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	IQueryResultWithMetadata,
	IRosaPatientRecord,
	IRosaPatientRecordsQueryParams,
	IRosaPatientsQueryMetadata,
	rosaPatientsQuery_concurrencyToken,
	rosaPatientsQuery_query,
} from 'gen/ApiClient';
import React from 'react';
import { RosaPatientRecordsQueryContext } from './RosaPatientRecordsQueryContext';
import { rosaPatientsQueryParamsLsKey } from 'app/localStorageKeys';

const DefaultParams: IRosaPatientRecordsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'lastUpdated' },
	pageParams: { index: 1, size: 10 },
	isLinked: undefined,
	lastUpdatedAfter: undefined,
	lastUpdatedBefore: undefined,
};

export const RosaPatientRecordsQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResultWithMetadata<IRosaPatientRecord, IRosaPatientsQueryMetadata>, IRosaPatientRecordsQueryParams>
			defaultParams={DefaultParams}
			queryF={rosaPatientsQuery_query}
			concurrencyF={rosaPatientsQuery_concurrencyToken}
			localStorageKey={rosaPatientsQueryParamsLsKey}
			context={RosaPatientRecordsQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
