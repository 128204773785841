import { Paper, PaperProps, useTheme } from '@material-ui/core';
import { InventoryItemStatusChipX } from 'app/main/inventoryManagement/inventoryItems/components/InventoryItemStatusChipX';
import { ChipX } from 'framework/components/chips/ChipX';
import { IAssignedOrInquiredArticleDto, InventoryStatus, Orientation, ProductCatalogItemType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { AssignedOrInquiredArticlePopoverContent } from './AssignedOrInquiredArticlePopoverContent';
import { AssignedOrInquiredTypeIconCircle } from './AssignedOrInquiredTypeIconCircle';

interface IProps extends PaperProps {
	patientId: string;
	item: IAssignedOrInquiredArticleDto;
	reload: VoidFunction;
}

export const AssignedOrInquiredArticleComponent = ({ patientId, item, style, reload, ...props }: IProps) => {
	const theme = useTheme();
	const orientation = useMemo(() => (item.orientation ? (item.orientation as Orientation) : 'unknown'), [item]);
	// eslint-disable-next-line
	const color = useMemo(() => (orientation === 'Left' ? 'blue' : orientation === 'Right' ? 'red' : theme.palette.grey[500]), [orientation]);
	const { orientationRecord, productCatalogItemTypeRecord } = useContext(RecordContext);
	const strings = useLocalization();
	const popupState = usePopupState({
		variant: 'popover',
		popupId: `ass-article-${item.id!}`,
	});

	return (
		<>
			<Paper
				variant='outlined'
				className='df-row'
				style={{
					padding: 8,
					gap: 12,
					borderRadius: 12,
					borderStyle: item.isHidden ? 'dashed' : 'solid',
					...style,
				}}
				{...props}
				{...bindHover(popupState)}>
				<div
					className='df-col'
					style={{ width: 40, marginTop: 2 }}>
					<AssignedOrInquiredTypeIconCircle
						type={item.type as ProductCatalogItemType}
						color={color}
					/>
					<span style={{ color: color, fontSize: 10, textAlign: 'center', textTransform: 'uppercase', marginTop: 2 }}>
						{orientation === 'unknown' ? strings.notApplicableAbbreviation : orientationRecord[orientation]}
					</span>
				</div>
				<div
					className='df-col gap-4'
					style={{ width: `calc(100% - 40px)` }}>
					<span style={{ fontWeight: 500 }}>{productCatalogItemTypeRecord[item.type as ProductCatalogItemType]}</span>
					<div className='df-col'>
						{item.hasInventoryItem && (
							<InventoryItemStatusChipX
								status={item.inventoryStatus as InventoryStatus}
								style={{ marginRight: 'auto' }}
							/>
						)}
						{item.hasInventoryItem === false && (
							<ChipX
								style={{ marginRight: 'auto' }}
								label={strings.toAssign}
								color='warning'
							/>
						)}
						<span
							style={{
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								marginRight: 8,
							}}>
							{item.description}
						</span>
					</div>
				</div>
			</Paper>
			<HoverPopover
				elevation={1}
				{...bindPopover(popupState)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}>
				<AssignedOrInquiredArticlePopoverContent
					item={item}
					patientId={patientId}
					reload={reload}
				/>
			</HoverPopover>
		</>
	);
};
