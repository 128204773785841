import { useTheme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Color } from '@material-ui/lab';
import React, { useMemo } from 'react';
import { TooltipWithSpan } from '../TooltipWithSpan';
import { toColor } from '../color/toColor';

interface IProps {
	label: string | number;
	color?: Color;
	style?: React.CSSProperties;
}

export const FadedRectangularChip = ({ tooltip, ...props }: IProps & { tooltip?: string }) => {
	if (tooltip) {
		return (
			<TooltipWithSpan title={tooltip}>
				<Inner {...props} />
			</TooltipWithSpan>
		);
	} else {
		return <Inner {...props} />;
	}
};

const Inner = ({ label, style, color = 'info' }: IProps) => {
	const theme = useTheme();
	const themeColor = useMemo(() => toColor(color, theme), [theme, color]);

	return (
		<div
			style={{
				color: themeColor,
				fontSize: '12px',
				fontWeight: 500,
				backgroundColor: fade(themeColor, 0.1),
				padding: '2px 8px',
				borderRadius: 8,
				...style,
			}}>
			{label}
		</div>
	);
};
