import { Button, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { ImportExamplesDialog } from 'app/main/settings/templates/forms/ImportExamplesDialog';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RestrictedContent } from 'shared/layout/RestrictedContent';
import { TemplateDetailsCard } from './TemplateDetailsCard';
import { TemplateListItemCardX } from './TemplateListItemCardX';
import { TemplatesListFilterCard } from './TemplatesListFilterCard';
import { TemplatesQueryContext } from './TemplatesQueryContext';
import { TemplateForm } from './forms/TemplateForm';

export const TemplatesTab = ({ reloadTrigger }: IReloadTriggerProps) => {
	const { reload, queryResult, params, setParams } = useQueryContext(TemplatesQueryContext);
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [expandedId, setExpandedId] = useState<string>();
	const expandedTemplate = useMemo(() => queryResult?.values.find(t => t.id === expandedId), [queryResult, expandedId]);
	const isSuperUser = useLoggedInUserIsSuperUser();

	const onCreate = () => {
		open(
			<TemplateForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onImport = () => {
		if (isSuperUser) {
			open(
				<ImportExamplesDialog
					open
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	if (queryResult === undefined) {
		return <></>;
	}

	return (
		<RestrictedContent pageClaim='Templates'>
			<div className='df-row gap-16'>
				<div className='df-col gap-16'>
					<Button
						variant='outlined'
						color='primary'
						startIcon={<AddIcon />}
						onClick={onCreate}
						style={{ borderRadius: 12 }}>
						{strings.new}
					</Button>
					{isSuperUser && (
						<Button
							variant='outlined'
							color='primary'
							startIcon={<AddIcon />}
							onClick={onImport}
							style={{ borderRadius: 12 }}>
							{`Import example templates`}
						</Button>
					)}
					<TemplatesListFilterCard
						params={params}
						setParams={setParams}
					/>
				</div>
				<div className='df-col gap-8'>
					<SearchbarX
						placeholder={strings.searchVerb}
						value={params.searchString}
						onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
						// style={{ width: 500 }}
					/>
					{queryResult.values.map(t => (
						<TemplateListItemCardX
							key={t.id}
							item={t}
							isSelected={expandedId === t.id}
							toggle={() => setExpandedId(expandedId === t.id ? undefined : t.id)}
						/>
					))}
					<QueryResultPaginationX
						queryResult={queryResult}
						setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
						style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
					/>
				</div>
				{expandedTemplate !== undefined && (
					<>
						<Divider orientation='vertical' />
						<TemplateDetailsCard
							item={expandedTemplate}
							className='fg1 h-fc'
							reload={reload}
						/>
					</>
				)}
			</div>
		</RestrictedContent>
	);
};
