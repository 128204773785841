import React from 'react';
import { ViewProvider as PaymentsViewProvider } from './detail/payments/context/ViewProvider';
import { ViewProvider as LinesViewProvider } from './detail/purchases/context/ViewProvider';
import { QueryProvider } from './context/QueryProvider';
import { ViewProvider } from './context/ViewProvider';

export const SupplierAccountsProvider = ({ children }: any) => {
	return (
		<QueryProvider>
			<ViewProvider>
				<LinesViewProvider>
					<PaymentsViewProvider>{children}</PaymentsViewProvider>
				</LinesViewProvider>
			</ViewProvider>
		</QueryProvider>
	);
};
