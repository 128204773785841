import { DialogProps } from '@material-ui/core';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { IRequestForInsuranceAllowance, rfiasCommand_updateLabels } from 'gen/ApiClient';
import React from 'react';
import { LabelsForm } from 'shared/LabelsForm';
import { FilterDataContext } from '../context/FilterDataContext';

interface IProps extends DialogProps {
	item: IRequestForInsuranceAllowance;
	cancel: VoidFunction;
	confirm: VoidFunction;
}

export const RfiaLabelsForm = ({ item, ...rest }: IProps) => {
	const [data] = useCacheContext(FilterDataContext);

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<LabelsForm
			{...rest}
			labels={item.labels}
			updateF={values => rfiasCommand_updateLabels(item.id, values)}
			autoCompleteLabels={data.labels}
		/>
	);
};
