import { TableBody } from '@material-ui/core';
import React from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DropResult } from 'react-beautiful-dnd';
import { isLast } from '../utils/array/isLast';
import { reorder } from '../utils/array/reorder';
import { DraggableTableRow } from './DraggableTableRow';
import { IHeader } from './IHeader';

interface IProps<T> {
	ordered: T[];
	setOrdered: (val: T[]) => void;
	getKey: (inst: T) => string;
	headers: IHeader<T>[];
	padding: number;
	keepDndLeftPadding: boolean;
	expandableContent?: (value: T) => JSX.Element;
}

export const DragAndDropTableValues = <T extends unknown>({
	ordered,
	setOrdered,
	getKey,
	headers,
	padding,
	keepDndLeftPadding,
	expandableContent,
}: IProps<T>) => {
	const onDragEndHandler = ({ destination, source }: DropResult) => {
		// dropped outside the list
		if (destination !== undefined) {
			const items = reorder(ordered, source.index, destination.index);
			setOrdered(items);
		}
	};

	return (
		<DragDropContext onDragEnd={onDragEndHandler}>
			<Droppable droppableId='list'>
				{(provided: any) => (
					<TableBody
						ref={provided.innerRef}
						{...provided.droppableProps}>
						{ordered.map((value, index) => (
							<Draggable
								draggableId={getKey(value)}
								key={getKey(value)}
								index={index}>
								{(provided: DraggableProvided) => (
									<DraggableTableRow<T>
										provided={provided}
										headers={headers}
										index={index}
										value={value}
										key={getKey(value)}
										keepDndLeftPadding={keepDndLeftPadding}
										padding={padding}
										isLast={isLast(value, ordered)}
										expandableContent={expandableContent && expandableContent(value)}
									/>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</TableBody>
				)}
			</Droppable>
		</DragDropContext>
	);
};
