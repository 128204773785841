import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IPatchProductCatalogItemRecupelCodeRequest, IProductCatalogItem, IProductCatalogItemSummary, productCatalogCommand_patchRecupel } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { RecupelDefaultCacheContext } from 'shared/context/RecupelDefaultCacheContext';
import { FormSelectRecupelField } from 'shared/forms/FormSelectRecupelField';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchProductCatalogItemRecupelCodeRequest>({
		recupelCode: yup.string().required(strings.formRequired('Recupel tarief')),
		productCatalogItemId: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	item: IProductCatalogItem | IProductCatalogItemSummary;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LinkRecupelForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [link, isSubmitting] = useFormSubmit(productCatalogCommand_patchRecupel);
	const strings = useLocalization();
	const [data] = usePreloadCacheContext(RecupelDefaultCacheContext);

	const handleSubmit = async (values: IPatchProductCatalogItemRecupelCodeRequest, helpers: FormikHelpers<IPatchProductCatalogItemRecupelCodeRequest>) => {
		const r = await link(values);
		if (handleValidateResponse(r, helpers, 'recupelCode')) {
			confirm();
		}
	};

	return (
		<Formik<IPatchProductCatalogItemRecupelCodeRequest>
			validateOnMount
			initialValues={{
				recupelCode: item.recupelCode ?? data,
				productCatalogItemId: item.id,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>Recupel</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormSelectRecupelField<IPatchProductCatalogItemRecupelCodeRequest>
							name='recupelCode'
							label={`Recupel ${strings.rate}`}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.link}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
