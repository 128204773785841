import { IArticleProperty, IArticlePropertyAutocomplete, IArticlePropertyDefinition, IArticlePropertyKey, IArticlePropertyModel } from 'gen/ApiClient';
import { IArticlePropertyLine } from '../IArticlePropertyLine';

export const mapToArticlePropertyLines = (
	apds: IArticlePropertyDefinition[],
	aps: (IArticleProperty | IArticlePropertyModel)[],
	apks: IArticlePropertyKey[],
	apacs: IArticlePropertyAutocomplete[]
): IArticlePropertyLine[] => {
	return apds
		.map<IArticlePropertyLine | undefined>(apd => {
			const fApk = apks.find(x => x.key === apd.key);
			if (fApk === undefined) {
				return undefined;
			} else {
				const fAp = aps.find(x => x.key === apd.key);
				const fApac = apacs.find(x => x.key === apd.key);
				return {
					apd: apd,
					apk: fApk,
					values: fAp !== undefined ? fAp.values : [],
					autoCompleteOptions: fApac?.values ?? [],
				};
			}
		})
		.filter(t => t !== undefined) as IArticlePropertyLine[];
};
