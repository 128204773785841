import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IPatchRfiaHandoverSaleGenerationModeRequest, RfiaHandoverSaleGenerationMode, rfiaSettingsCommand_patchSaleGenerationMode } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPatchRfiaHandoverSaleGenerationModeRequest>({
			mode: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	mode: string;
}

export const PatchRfiaHandoverSaleGenerationModeRequestForm = ({ confirm, cancel, mode, ...rest }: IProps) => {
	const strings = useLocalization();
	const [update, isSubmitting] = useFormSubmit(rfiaSettingsCommand_patchSaleGenerationMode);
	const modeX = useMemo(() => mode ?? ('AskEveryTime' as RfiaHandoverSaleGenerationMode), [mode]);

	const handleSubmit = async (values: IPatchRfiaHandoverSaleGenerationModeRequest, helpers: FormikHelpers<IPatchRfiaHandoverSaleGenerationModeRequest>) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	return (
		<Formik<IPatchRfiaHandoverSaleGenerationModeRequest>
			validateOnMount
			initialValues={{ mode: modeX }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					scroll='paper'
					fullWidth
					{...rest}>
					<DialogTitle>{strings.modeGenerateInvoiceAtHandover}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormExtendedRadioGroupField<IPatchRfiaHandoverSaleGenerationModeRequest, RfiaHandoverSaleGenerationMode>
							name='mode'
							label={`Mode genereren facturen`}
							options={[
								{ value: 'GenerateSingleInvoice', label: strings.registerOneSale, caption: strings.registerOneSaleCaption },
								{ value: 'GenerateMultipleInvoices', label: strings.registerMultipleSales, caption: strings.registerMultipleSalesCaption },
								{ value: 'AskEveryTime', label: strings.askEveryTime, caption: strings.askEveryTimeHandoverModeCaption },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
