import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AgendaPage } from './agenda/AgendaPage';
import { SearchEventsPage } from './agenda/search/SearchEventsPage';
import * as routes from './calendarRoutes';

export const CalendarSwitch = () => {
	return (
		<Switch>
			<Route
				exact
				path={routes.AgendaRoute}
				component={AgendaPage}
			/>
			<Route
				exact
				path={routes.SearchEventsRoute}
				component={SearchEventsPage}
			/>
			<Redirect to={routes.AgendaRoute} />
		</Switch>
	);
};
