import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { IOption } from 'framework/IOption';
import { isFiltered } from 'framework/utils/isFiltered';
import { CreditedStatus, CreditedStatuses, ISalesForCommissionQueryParams, PaymentStatuses, SalesTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';

const exclusions: (keyof ISalesForCommissionQueryParams)[] = ['forProductCatalogId', 'forPatientId' as any, 'isShowAll'];

interface IProps {
	params: ISalesForCommissionQueryParams;
	setParams: React.Dispatch<React.SetStateAction<ISalesForCommissionQueryParams>>;
}

export const Filter = ({ params, setParams }: IProps) => {
	const strings = useLocalization();
	const { paymentStatusRecord, saleTypeRecord, creditedStatusRecord } = useContext(RecordContext);

	const onFilter = (params: ISalesForCommissionQueryParams) => {
		setParams(onResetToPage1(params));
	};

	return (
		<FilterBar
			disableWrap
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}>
			<DateFilterItem
				label={strings.dateCreated}
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<ListFilterComponent<string>
				options={PaymentStatuses.map<IOption<string>>(t => ({ id: t, identifier: paymentStatusRecord[t] }))}
				selected={params.paymentStatuses}
				setSelected={vals => onFilter({ ...params, paymentStatuses: vals })}
				icon={<HelpOutlineIcon />}
				label='Betaal status'
			/>
			<ListFilterComponent<string>
				options={SalesTypes.map<IOption<string>>(t => ({ id: t, identifier: saleTypeRecord[t] }))}
				selected={params.types}
				setSelected={vals => onFilter({ ...params, types: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.type}
			/>
			<RecordListFilterComponent<CreditedStatus>
				record={creditedStatusRecord}
				options={CreditedStatuses}
				selected={params.creditedStatuses as any}
				setSelected={vals => onFilter({ ...params, creditedStatuses: vals })}
				icon={<HelpOutlineIcon />}
				label='Krediet status'
			/>
		</FilterBar>
	);
};
