import { Dialog, DialogProps, Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { stockMovementSettingsCommand_patchReferenceSettings, stockMovementsQuery_settings } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ReferenceSettingsForm } from 'shared/ReferenceSettingsForm';
import { calculateReference } from 'shared/utils/calculateReference';

interface IProps extends DialogProps {
	close: VoidFunction;
}

export const StockMovementSettingsDialog = ({ close, ...rest }: IProps) => {
	const strings = useLocalization();
	const [settings, reload] = useApiEffect(stockMovementsQuery_settings);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);

	if (settings === undefined) {
		return <div></div>;
	}

	const onReferenceSettings = () => {
		open(
			<ReferenceSettingsForm
				cancel={cancel}
				confirm={confirm}
				open
				value={settings.referenceSettings}
				title={strings.changeWhat(strings.numberingWhat(strings.fromArrowTo))}
				submitFunction={model => stockMovementSettingsCommand_patchReferenceSettings(model)}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			onClose={close}
			fullWidth>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<SettingsIcon />}>
					<Typography variant='h1'>{strings.fromArrowTo}</Typography>
					<Typography
						variant='h2'
						style={{ marginTop: 8 }}>
						{strings.settingsAndPreferences}
					</Typography>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.settings} />
				<PropertyInGrid
					label={strings.numbering}
					value={calculateReference(settings.referenceSettings)}
					edit={onReferenceSettings}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
