import { ISalesLine } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const salesLineToLine = (t: ISalesLine): ILine => {
	return {
		isSelected: true,
		quantity: t.quantity - t.creditedQuantity,
		salesLine: t,
	};
};
