import { Dialog, DialogContent, DialogProps, DialogTitle, Divider, useTheme } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { ILinkLocationWithRfiaModel, IRequestForInsuranceAllowance, rfiasCommand_updateLocation } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormSelectLocationField } from '../../../settings/locations/forms/FormSelectLocationField';

const createSchema = (strings: IStrings) => {
	return yup.object<ILinkLocationWithRfiaModel>({
		locationId: yup.string().required(strings.formRequired(strings.location)),
		alsoUpdateLocationOnPatient: yup.boolean().defined(),
	});
};

interface IProps extends DialogProps {
	item: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LinkLocationWithRfiaForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const [link, isSubmitting] = useFormSubmit(rfiasCommand_updateLocation);

	const handleSubmit = async (values: ILinkLocationWithRfiaModel, helpers: FormikHelpers<ILinkLocationWithRfiaModel>) => {
		const r = await link(item.id, values);
		if (handleValidateResponse(r, helpers, 'locationId')) {
			confirm();
		}
	};

	return (
		<Formik<ILinkLocationWithRfiaModel>
			validateOnMount
			initialValues={{
				locationId: item.locationId ?? '',
				alsoUpdateLocationOnPatient: true,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.selectWhat(strings.location)}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormSelectLocationField<ILinkLocationWithRfiaModel>
							name='locationId'
							label={strings.location}
						/>
						<Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
						<FormExtendedRadioGroupField<ILinkLocationWithRfiaModel, boolean>
							name='alsoUpdateLocationOnPatient'
							label=''
							options={[
								{ value: true, label: strings.alsoChangeWithPatient, caption: strings.locationWillAlsoBeChangedWithPatient },
								{ value: false, label: strings.doNotChangeWithPatient, caption: strings.locationWillNotBeChangedWithPatient },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.link}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
