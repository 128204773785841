import { IPurchaseLine } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { PurchaseDetailTabType } from './PurchaseDetailTabType';

export const mapPurchaseLineToColorX = (line: IPurchaseLine) => {
	return line.isFullyCredited || line.isPartlyCredited ? 'error' : 'info';
};

export const createPurchaseDetailTabTypeRecord = (strings: IStrings): Record<PurchaseDetailTabType, string> => {
	return {
		contents: strings.contents,
		payments: strings.payments,
	};
};
