import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { IPurchase, PurchaseType } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	item: IPurchase;
	color: ColorX;
	style?: React.CSSProperties;
}

export const PurchaseTypeChip = ({ item, style, color }: IProps) => {
	const { purchaseTypeRecord } = useContext(RecordContext);

	return (
		<ChipX
			label={purchaseTypeRecord[item.type as PurchaseType]}
			color={color}
			style={style}
		/>
	);
};
