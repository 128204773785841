import { Badge, createStyles, Theme, withStyles } from '@material-ui/core';
import React from 'react';

interface IProps {
	isLoggedIn: boolean;
	children: any;
	style?: React.CSSProperties;
}

const StyledBadge = withStyles((theme: Theme) =>
	createStyles({
		badge: {
			backgroundColor: '#44b700',
			color: '#44b700',
			boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
			'&::after': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				borderRadius: '50%',
				animation: '$ripple 1.2s infinite ease-in-out',
				border: '1px solid currentColor',
				content: '""',
			},
		},
		'@keyframes ripple': {
			'0%': {
				transform: 'scale(.8)',
				opacity: 1,
			},
			'100%': {
				transform: 'scale(2.4)',
				opacity: 0,
			},
		},
	})
)(Badge);

export const ActivityBadge = ({ isLoggedIn, children, style }: IProps) => {
	if (isLoggedIn) {
		return (
			<StyledBadge
				overlap='circle'
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				variant='dot'
				style={style}>
				{children}
			</StyledBadge>
		);
	} else {
		return <div style={style}>{children}</div>;
	}
};
