import React, { useState } from 'react';
import { TroubleshootPageViewContext } from './TroubleshootPageViewContext';
import { TroubleshootTabType } from './TroubleshootTabType';

const TabTypeRecord: Record<TroubleshootTabType, string> = {
	'es-subscriptions': 'ES subscriptions',
	'failed-events': 'Failed events',
	general: 'General',
	notifications: 'Notifications',
	tokens: 'Tokens',
	'noah-user-setups': 'Noah user setups',
};

export const TroubleshootPageViewProvider = ({ children }: any) => {
	const [selectedTab, setSelectedTab] = useState<TroubleshootTabType>('general');

	return (
		<TroubleshootPageViewContext.Provider
			value={{
				selectedTab: selectedTab,
				setSelectedTab: setSelectedTab,
				TabTypeRecord: TabTypeRecord,
			}}>
			{children}
		</TroubleshootPageViewContext.Provider>
	);
};
