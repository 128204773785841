import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		created: strings.createdAt,
		identifier: `${strings.who}?`,
		outstandingBalance: strings.outstandingBalance,
		accountType: strings.type,
		value: strings.totalAmount,
		lastSettlementProposalMetaReference: strings.lastSpsMetaRefHeader,
		lastSettlementProposalReference: strings.lastSpsRefHeader,
		outstandingBalanceNotOnSettlementProposals: strings.outstandingBalanceNotOnSps,
	};
};
