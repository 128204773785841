import { IDraggableItem } from 'framework/components/dnd/IDraggableItem';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { LineDescriptionAndQuantity } from 'shared/searchAndSelect/lines/LineDescriptionAndQuantity';
import { LineXComponent } from 'shared/searchAndSelect/lines/LineXComponent';
import { TextFieldSelectPatient } from 'shared/TextFieldSelectPatient';
import { TextFieldSelectSerialNumber } from 'shared/TextFieldSelectSerialNumber';
import { IRegisterDeliveryLineModel } from './IRegisterDeliveryLineModel';

interface IProps extends IDraggableItem {
	line: IRegisterDeliveryLineModel;
	index: number;
	setLine: (val: IRegisterDeliveryLineModel) => void;
	onDelete: VoidFunction;
}

export const DeliveryLineComponent = ({ line, setLine, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<LineXComponent
			{...rest}
			line={line}
			setLine={setLine}
			errors={line.error}>
			<LineDescriptionAndQuantity<IRegisterDeliveryLineModel>
				line={line}
				setLine={setLine}
				disableQuantity={line.productCatalogItem.isUniquelyIdentifiable}
			/>
			{line.productCatalogItem.isUniquelyIdentifiable && (
				<div
					className='df-col gap-8'
					style={{ marginTop: 8 }}>
					<FormControlLabelSwitch
						isChecked={line.autoGenerateSerialNumber}
						toggle={() => setLine({ ...line, autoGenerateSerialNumber: !line.autoGenerateSerialNumber })}
						label={strings.autoGenerateSerialNumberQuestion}
						style={{ marginLeft: 0 }}
					/>
					{line.autoGenerateSerialNumber === false && (
						<TextFieldSelectSerialNumber
							value={line.serialNumber}
							setValue={val => setLine({ ...line, description: `${line.productCatalogItem.manufacturerCommaModel} sn: ${val}`, serialNumber: val })}
							style={{ width: 'fit-content' }}
							productCatalogItem={line.productCatalogItem}
						/>
					)}
					{line.productCatalogItem.isTailorMade && (
						<TextFieldSelectPatient
							patientId={line.tailorMadeForPatientId}
							patientIdentifier={line.tailorMadeForPatientIdentifier}
							onPatientSelected={val => setLine({ ...line, tailorMadeForPatientId: val.id, tailorMadeForPatientIdentifier: val.identifier })}
							style={{ width: 'fit-content' }}
						/>
					)}
				</div>
			)}
		</LineXComponent>
	);
};
