import { Locale } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import frBE from 'date-fns/locale/fr';
import nlBE from 'date-fns/locale/nl-BE';
import { LanguageOption } from 'localization/LanguageOption';

export const LanguageOptionToLocaleRecord: Record<LanguageOption, Locale> = {
	en: enUS,
	nl: nlBE,
	fr: frBE,
};
