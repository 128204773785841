import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { apiTokensQuery_concurrencyToken, apiTokensQuery_query, IApiTokenDto, IApiTokensQueryParams, IQueryResult } from 'gen/ApiClient';
import React from 'react';
import { apiTokensQueryParamsLsKey } from '../../../localStorageKeys';
import { ApiTokensQueryContext } from './ApiTokensQueryContext';

const DefaultParams: IApiTokensQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
};

export const ApiTokensQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IApiTokenDto>, IApiTokensQueryParams>
			defaultParams={DefaultParams}
			queryF={apiTokensQuery_query}
			concurrencyF={apiTokensQuery_concurrencyToken}
			localStorageKey={apiTokensQueryParamsLsKey}
			context={ApiTokensQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
