import { DialogProps } from '@material-ui/core';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { patientsQuery_single, rfiasQuery_countActiveForPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { calcRequestForInsuranceAllowanceDetailRoute } from '../../../ProcessesRoutes';
import { RfiaModelForm } from './RfiaModelForm';

interface IProps extends DialogProps {
	patientId: string;
	cancel: VoidFunction;
	confirm: (id: string) => void;
}

export const RfiaForPatientForm = ({ patientId, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const [patient] = useApiEffect(patientsQuery_single, patientId);
	const [countForPatient] = useApiEffect(rfiasQuery_countActiveForPatient, patientId);
	const [isConfirmedWarning, setIsConfirmedWarning] = useState<boolean>(false);
	const { push } = useHistory();

	const onConfirm = (id: string) => {
		push(calcRequestForInsuranceAllowanceDetailRoute(id));
		confirm(id);
	};

	if (countForPatient === undefined || patient === undefined) {
		return <div></div>;
	} else if (countForPatient > 0 && isConfirmedWarning === false) {
		return (
			<AlertDialog
				{...rest}
				title={strings.newRequestForInsuranceAllowance}
				content={strings.otherRunningRfiaProcessesForPatientDoYouWantToContinueQuestion(patient.identifier ?? '')}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				accept={() => setIsConfirmedWarning(true)}
				reject={cancel}
			/>
		);
	} else {
		return (
			<RfiaModelForm
				{...rest}
				patient={patient}
				confirm={onConfirm}
				cancel={cancel}
			/>
		);
	}
};
