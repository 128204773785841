import { Divider, IconButton, InputBase, useTheme } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useMemo, useState } from 'react';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { IDateRange } from 'framework/filter/date/IDateRange';
import { FilterBar } from 'framework/filter/FilterBar';
import { SortableTable } from 'framework/table/SortableTable';
import { useLocalization } from 'localization/useLocalization';
import { IPurchaseWithSelection } from '../../../purchases/IPurchaseWithSelection';
import { createHeaders } from './createHeaders';

interface IProps {
	lines: IPurchaseWithSelection[];
	setLines: React.Dispatch<React.SetStateAction<IPurchaseWithSelection[]>>;
	initFilter?: IDateRange;
}

export const PurchasesTable = ({ lines, setLines, initFilter }: IProps) => {
	const theme = useTheme();
	const [search, setSearch] = useState<string>('');
	const strings = useLocalization();
	const [filtered, setFiltered] = useState<IPurchaseWithSelection[]>(lines);
	const headers = useMemo(() => createHeaders(lines, setLines, strings), [lines, setLines, strings]);
	const [params, setParams] = useState<IDateRange>(initFilter ?? { after: undefined, before: undefined });

	useEffect(() => {
		const x = lines
			.filter(t => (t.searchField ?? '').toLowerCase().includes(search.toLowerCase()))
			.filter(t => params.after === undefined || params.after === null || t.created >= params.after)
			.filter(t => params.before === undefined || params.before === null || t.created <= params.before);
		setFiltered(x);
		// eslint-disable-next-line
	}, [search, lines, params]);

	return (
		<>
			<div
				className='df-row-ac'
				style={{ padding: theme.spacing(1.5), paddingLeft: theme.spacing(2) }}>
				<SearchIcon style={{ color: theme.palette.text.secondary }} />
				<InputBase
					placeholder={`${strings.searchVerb}...`}
					style={{ width: '100%', paddingLeft: '26px' }}
					value={search}
					onKeyDown={e => e.stopPropagation()}
					onChange={e => setSearch(e.target.value)}
				/>
				{search && (
					<IconButton
						onClick={() => setSearch('')}
						size='small'>
						<ClearIcon />
					</IconButton>
				)}
			</div>
			<Divider />
			<FilterBar
				isFiltered={params.after !== undefined || params.before !== undefined}
				onClear={() => setParams({ after: undefined, before: undefined })}>
				<DateFilterItem
					label={strings.date}
					after={params.after === null ? undefined : params.after}
					before={params.before === null ? undefined : params.before}
					setSelected={(after, before) => setParams({ after: after, before: before })}
				/>
			</FilterBar>
			<Divider />
			<SortableTable<IPurchaseWithSelection>
				padding={theme.spacing(1)}
				headers={headers}
				getKey={t => t.id}
				values={filtered}
				defaultSortParams={{ property: 'created', direction: 'desc' }}
			/>
		</>
	);
};
