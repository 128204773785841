import { AccordionXWithType } from 'framework/components/accordion/AccordionXWithType';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { serverMeta_dbName, meta_build as server_meta_build } from 'gen/ApiClient';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { build } from '../../../build';
import { EidClientContent } from './EidClientContent';
import { HamsClientContent } from './HamsClientContent';
import { ImageScannerContent } from './ImageScannerContent';
import { ThermalPrinterContent } from './ThermalPrinterContent';

type AccordionType = 'versions' | 'eid' | 'noah-client' | 'image-scanner' | 'thermal-printer';

export const GeneralTroubleshootTab = ({ reloadTrigger }: IReloadTriggerProps) => {
	const [serverBuild, reload] = useApiEffect(server_meta_build);
	const [dbName] = useApiEffect(serverMeta_dbName);
	const [expandedAccordion, setExpandedAccordion] = useAccordionState<AccordionType>();

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	return (
		<>
			<AccordionXWithType<AccordionType>
				type='versions'
				expandedAccordion={expandedAccordion}
				toggle={setExpandedAccordion}
				title='Versions'>
				<div className='df-col gap-8'>
					<CaptionVerticalLabelledPropertyX label='Server version'>
						{serverBuild !== undefined ? serverBuild.toString() : 'Not loaded yet'}
					</CaptionVerticalLabelledPropertyX>
					<CaptionVerticalLabelledPropertyX label='Client (frontend) version'>{build.toString()}</CaptionVerticalLabelledPropertyX>
					<CaptionVerticalLabelledPropertyX label='On database'>{dbName !== undefined ? dbName : 'Not loaded yet'}</CaptionVerticalLabelledPropertyX>
				</div>
			</AccordionXWithType>
			<AccordionXWithType<AccordionType>
				type='eid'
				expandedAccordion={expandedAccordion}
				toggle={setExpandedAccordion}
				title='Eid software'>
				<EidClientContent reloadTrigger={reloadTrigger} />
			</AccordionXWithType>
			<AccordionXWithType<AccordionType>
				type='noah-client'
				expandedAccordion={expandedAccordion}
				toggle={setExpandedAccordion}
				title='HAMS Noah Client software'>
				<HamsClientContent reloadTrigger={reloadTrigger} />
			</AccordionXWithType>
			<AccordionXWithType<AccordionType>
				type='image-scanner'
				expandedAccordion={expandedAccordion}
				toggle={setExpandedAccordion}
				title='Image scanner software'>
				<ImageScannerContent reloadTrigger={reloadTrigger} />
			</AccordionXWithType>
			<AccordionXWithType<AccordionType>
				type='thermal-printer'
				expandedAccordion={expandedAccordion}
				toggle={setExpandedAccordion}
				title='Thermal printer software'>
				<ThermalPrinterContent reloadTrigger={reloadTrigger} />
			</AccordionXWithType>
		</>
	);
};
