import { useLoggedInUserIsSuperUserOrHasClientClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasClientClaim';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { PatientAnnotationsCard } from './PatientAnnotationsCard';
import { PatientFollowUpCard } from './PatientFollowUpCard';

interface IProps extends IReloadTriggerProps {
	patientId: string;
}

export const PatientDetailAnnotationsAndFollowUpTab = ({ patientId, reloadTrigger }: IProps) => {
	const hasAccessToFollowUp = useLoggedInUserIsSuperUserOrHasClientClaim('vfu');

	return (
		<div
			className='df-row'
			style={{ gap: 16 }}>
			<div className='w50'>
				<PatientAnnotationsCard
					patientId={patientId}
					reloadTrigger={reloadTrigger}
				/>
			</div>
			{hasAccessToFollowUp && (
				<div className='w50'>
					<PatientFollowUpCard
						patientId={patientId}
						reloadTrigger={reloadTrigger}
					/>
				</div>
			)}
		</div>
	);
};
