import { Checkbox, useTheme } from '@material-ui/core';
import { CollapseXTextAndIcon } from 'framework/components/CollapseXTextAndIcon';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { createHeader } from 'framework/table/createHeader';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { ISpeechAudiogram, LeftRightOrStereoType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { SpeechAudiogramNoahInfo } from './SpeechAudiogramNoahInfo';
import { SpeechChart } from './SpeechChart';

interface IProps {
	audiograms: ISpeechAudiogram[];
	aided: ISpeechAudiogram | undefined;
	unaided: ISpeechAudiogram | undefined;
	setAided: (val: ISpeechAudiogram | undefined) => void;
	setUnaided: (val: ISpeechAudiogram | undefined) => void;
	type: LeftRightOrStereoType;
}

export const SelectSpeechAudiogramsTable = ({ audiograms, aided, unaided, setAided, setUnaided, type }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const audiogramsByType = useMemo(() => audiograms.filter(t => t.leftRightOrStereo === type), [audiograms, type]);
	const [isViewAll, toggleIsViewAll] = useStateToggleBoolean(false);
	const { leftRightOrStereoRecord } = useContext(RecordContext);

	const onClickAided = (t: ISpeechAudiogram) => {
		if (t === unaided) {
			setUnaided(undefined);
		}
		setAided(t);
	};

	const onClickUnaided = (t: ISpeechAudiogram) => {
		if (t === aided) {
			setAided(undefined);
		}
		setUnaided(t);
	};

	const onToggleViewAll = () => {
		if (isViewAll === false) {
			if (aided !== undefined && aided.leftRightOrStereo !== type) {
				setAided(undefined);
			}
			if (unaided !== undefined && unaided.leftRightOrStereo !== type) {
				setUnaided(undefined);
			}
		}
		toggleIsViewAll();
	};

	const headers = [
		createHeader<ISpeechAudiogram>(
			`${strings.without}\n${strings.hearingAid}`,
			t => (
				<Checkbox
					checked={t === unaided}
					onClick={() => onClickUnaided(t)}
				/>
			),
			'center'
		),
		createHeader<ISpeechAudiogram>(
			`${strings.with}\n${strings.hearingAid}`,
			t => (
				<Checkbox
					checked={t === aided}
					onClick={() => onClickAided(t)}
				/>
			),
			'center'
		),
		createHeader<ISpeechAudiogram>(`SRT ${strings.point}`, t => `${Math.round(t.srtIntensity)} dB`),
		createHeader<ISpeechAudiogram>(`ICA ${strings.score}`, t => `${Math.round(t.icaScore)} %`),
		createHeader<ISpeechAudiogram>(`BIAP ${strings.score}`, t => `${Math.round(t.biapScore)} %`),
		createEmptyHeader<ISpeechAudiogram>('image', t => (
			<SpeechChart
				audiogram={t}
				lrs={t.leftRightOrStereo as LeftRightOrStereoType}
				withoutLabels
				width={100}
				minHeight={40}
				hideAxes
			/>
		)),
		createEmptyHeader<ISpeechAudiogram>('info', t => (
			<SpeechAudiogramNoahInfo
				speechAudiogram={t}
				lrs={t.leftRightOrStereo as LeftRightOrStereoType}
			/>
		)),
	];

	return (
		<>
			<TableWithHeadersAndValues<ISpeechAudiogram>
				values={isViewAll ? audiograms : audiogramsByType}
				headers={headers}
				getKey={t => t.displayOrKey ?? ''}
				padding={theme.spacing(0.5)}
				withPaperContainer
			/>
			{audiograms.length !== audiogramsByType.length && (
				<CollapseXTextAndIcon
					style={{ marginTop: 8 }}
					isExpanded={isViewAll}
					toggleIsExpanded={onToggleViewAll}
					expandText={strings.showAllWhat(strings.audiograms)}
					collapseText={strings.showOnlyWhat(`${leftRightOrStereoRecord[type]} ${strings.audiograms}`)}
				/>
			)}
		</>
	);
};
