import { RRule } from 'rrule';
import { ICalendarEventModel } from 'gen/ApiClient';
import { calculateREndDate } from './calculateREndDate';
import { formatRrule } from './formatRrule';

export const appendRrule = (model: ICalendarEventModel, rrule: RRule): ICalendarEventModel => {
	if (model.isRecurring === false) {
		return model;
	} else {
		return { ...model, rRule: formatRrule(rrule), rEndDate: calculateREndDate(rrule, model.startDate) };
	}
};
