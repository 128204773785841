import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useState } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

type Mode = 'sameSale' | 'previousSale';

interface IProps extends DialogProps {
	confirm: (value: Mode) => void;
	cancel: VoidFunction;
	type: 'ProlongedWarranty' | 'Insurance';
}

export const SelectProductsToAssureModeDialog = ({ confirm, cancel, type, ...rest }: IProps) => {
	const strings = useLocalization();
	const [value, setValue] = useState<Mode>('sameSale');
	const { productCatalogItemTypeRecord } = useContext(RecordContext);

	return (
		<Dialog
			scroll='paper'
			PaperProps={{ style: { minWidth: 400 } }}
			{...rest}>
			<DialogTitle>{productCatalogItemTypeRecord[type]}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<ExtendedRadioGroup<Mode>
					selected={value}
					setSelected={setValue}
					options={[
						{ value: 'sameSale', label: strings.thisSale, caption: strings.selectLineFromThisSale },
						{ value: 'previousSale', label: strings.previousSale, caption: strings.selectLineFromPreviousSale },
					]}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				onSubmit={() => confirm(value)}
				cancel={cancel}
				isSubmitting={false}
				submitText={strings.create}
			/>
		</Dialog>
	);
};
