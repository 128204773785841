import { DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { IAddressModel } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { AddressInput } from './AddressInput';

const createSchema = (strings: IStrings) => {
	return yup.object<IAddressModel>({
		addressLine: yup.string().required(strings.formRequired(strings.addressLine)),
		zip: yup.string().required(strings.formRequired(strings.zip)),
		city: yup.string().required(strings.formRequired(strings.city)),
		country: yup.string(),
	});
};

interface IProps extends DialogProps {
	confirm: (model: IAddressModel) => void;
	cancel: VoidFunction;
	initialValue?: IAddressModel | null;
	submitText?: string;
	title?: string;
}

export const AddressModelForm = ({ cancel, confirm, title, initialValue, submitText, ...rest }: IProps) => {
	const strings = useLocalization();
	const [isInSearchMode, setIsInSearchMode] = useState<boolean>(false);

	const handleSubmit = async (values: IAddressModel, helpers: FormikHelpers<IAddressModel>) => {
		confirm(values);
	};

	return (
		<Formik<IAddressModel>
			validateOnMount
			initialValues={initialValue ?? { addressLine: '', zip: '', city: '', country: '' }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<OverflowDialog
					fullWidth
					{...rest}>
					<DialogTitle>
						<div style={{ height: '48px', lineHeight: '48px' }}>
							{title ? title : isInSearchMode === true ? strings.searchAnAddress : strings.editConfirmAddress}
						</div>
					</DialogTitle>
					<OverflowDialogContent>
						<AddressInput
							embed
							setExternalSearchMode={setIsInSearchMode}
						/>
					</OverflowDialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={submitText ? submitText : initialValue ? strings.update : strings.create}
						isSubmitting={false}
					/>
				</OverflowDialog>
			</Form>
		</Formik>
	);
};
