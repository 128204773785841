import { Card, CardContent, CardProps } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { isLast } from 'framework/utils/array/isLast';
import { IArticlesInquiryDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AddArticlesInquiryLinesRequestForm } from '../../forms/addLines/AddArticlesInquiryLinesRequestForm';
import { ArticlesInquiryLineListItem } from './ArticlesInquiryLineListItem';

interface IProps extends CardProps {
	item: IArticlesInquiryDto;
	reload: VoidFunction;
}

export const ArticlesInquiryDetailContentsCard = ({ item, reload, ...rest }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onAddLines = () => {
		open(
			<AddArticlesInquiryLinesRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	return (
		<Card {...rest}>
			<CardContent>
				<CardTitleTypography
					title={strings.contents}
					style={{ marginBottom: 8 }}
				/>
				<TextLinkButton
					startIcon={<AddCircleOutlineIcon />}
					onClick={onAddLines}
					color='primary'
					disabled={item.isCancelled}>
					{strings.addLines}
				</TextLinkButton>
				<DividerWithMargin />
				{item.lines.map(t => (
					<ArticlesInquiryLineListItem
						line={t}
						key={t.id}
						withoutBorder={isLast(t, item.lines)}
						articlesInquiry={item}
						reload={reload}
					/>
				))}
				{item.lines.length === 0 && <em>{strings.noLinesDefinedYet}</em>}
			</CardContent>
		</Card>
	);
};
