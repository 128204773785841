import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IMedicalTreatmentFeeDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { ILine } from './ILine';

interface IProps {
	lines: ILine[];
	fees: IMedicalTreatmentFeeDto[];
	style?: React.CSSProperties;
}

export const TotalsTable = ({ lines, fees, style }: IProps) => {
	const strings = useLocalization();

	return (
		<TableContainer style={style}>
			<Table size='small'>
				<TableBody>
					<TableRow>
						<TableCell>{strings.total}</TableCell>
						<TableCell>{formatCurrency(_.sum(lines.map(t => t.unitPrice * t.quantity)))}</TableCell>
					</TableRow>
					{fees.length > 0 && (
						<TableRow>
							<TableCell>{strings.refund}</TableCell>
							<TableCell>{formatCurrency(_.sum(fees.map(t => t.contribution)))}</TableCell>
						</TableRow>
					)}
					<TableRow>
						<TableCell>{strings.partPatient}</TableCell>
						<TableCell>{formatCurrency(_.sum(lines.map(t => t.unitPrice * t.quantity)) - _.sum(fees.map(t => t.contribution)))}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};
