import { CircularProgress, Theme, useTheme } from '@material-ui/core';
import { mapColorXToThemeColor } from 'framework/components/color/mapColorXToThemeColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import React, { useMemo } from 'react';
import { ITextLinkButtonProps } from './ITextLinkButtonProps';
import { TextLinkButtonColor } from './TextLinkButtonColor';

const mapTextLinkButtonColorToThemeColor = (color: TextLinkButtonColor, theme: Theme): string => {
	if (color === 'textPrimary') {
		return theme.palette.text.primary;
	} else if (color === 'textSecondary') {
		return theme.palette.text.secondary;
	} else if (color === 'disabled') {
		return theme.palette.text.disabled;
	} else {
		return mapColorXToThemeColor(color, theme);
	}
};

export const TextLinkButton = ({
	children,
	startIcon,
	endIcon,
	disabled,
	onClick,
	underline,
	style,
	color,
	hoverColor,
	addPadding,
	isLoading,
}: ITextLinkButtonProps) => {
	const theme = useTheme();
	const [isHovered, enter, leave] = useStateBoolean(false);
	const colorX = useMemo(() => mapTextLinkButtonColorToThemeColor(color ?? 'textPrimary', theme), [color, theme]);
	const hoverColorX = useMemo(() => mapTextLinkButtonColorToThemeColor(hoverColor ?? 'primary', theme), [hoverColor, theme]);
	const disabledX = useMemo(() => disabled === true || isLoading === true, [disabled, isLoading]);

	return (
		<div
			className='df-row-ac'
			onClick={disabledX === true ? undefined : onClick}
			style={{
				color: disabledX === true ? theme.palette.text.disabled : isHovered ? hoverColorX : colorX,
				textDecoration: underline === true && isHovered && disabled !== true ? 'underline' : 'unset',
				fontWeight: 500,
				fontSize: 13,
				cursor: disabledX ? 'unset' : 'pointer',
				paddingTop: addPadding === true ? 6 : 0,
				paddingBottom: addPadding === true ? 6 : 0,
				opacity: colorX === hoverColorX && isHovered ? 0.9 : 1,
				...style,
			}}
			onMouseEnter={enter}
			onMouseLeave={leave}>
			{startIcon && isLoading !== true && (
				<div
					className='icon-wrapper'
					style={{ fontSize: 18, display: 'flex', marginRight: 4 }}>
					{startIcon}
				</div>
			)}
			{isLoading === true && (
				<div
					className='df-col'
					style={{ marginRight: 4 }}>
					<CircularProgress
						style={{ color: 'inherit' }}
						size={16}
					/>
				</div>
			)}
			{children}
			{endIcon && (
				<div
					className='icon-wrapper'
					style={{ fontSize: 18, display: 'flex', marginRight: 4 }}>
					{endIcon}
				</div>
			)}
		</div>
	);
};
