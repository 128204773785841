import React, { useState } from 'react';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { IRequestForInsuranceAllowance, rfiaDocumentsCommand_createProformaHandoverCertificate } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { SelectRfiaHandoverCertificateTemplateForm } from '../../detail/documents/SelectRfiaHandoverCertificateTemplateForm';
import { ProformaHandoverModelForm } from './ProformaHandoverModelForm';
import { ProformaHandoverFailureModelForm } from './ProformaHandoverFailureModelForm';

type Mode = 'info' | 'model' | 'document';

interface IProps {
	rfia: IRequestForInsuranceAllowance;
	isHifExist: boolean;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const ProformaHandoverForm = ({ confirm, cancel, rfia, isHifExist }: IProps) => {
	const [mode, setMode] = useState<Mode>('info');
	const strings = useLocalization();
	const [handoverDate, setHandoverDate] = useState<Date>();

	if (rfia.hasMainMedicalTreatmentNomenclatureDefined === false) {
		return (
			<NotificationDialog
				open
				title={strings.noWhat(strings.nomenclatureCode)}
				content={strings.selectNomenclatureCodeFirstYouCanDeleteItAfterwards}
				close={cancel}
				closeText={strings.understood}
			/>
		);
	} else if (rfia.hasPseudoMedicalTreatmentNomenclatureDefined === false && rfia.isFailedTrial === false) {
		return (
			<NotificationDialog
				open
				fullWidth
				title={strings.noPseudoNomenclatureSelected}
				content={strings.selectPseudoNomenclatureCodeFirst}
				close={cancel}
				closeText={strings.understood}
			/>
		);
	} else if (isHifExist === false) {
		return (
			<NotificationDialog
				open
				fullWidth
				title={strings.fillInMissingData}
				content={
					rfia.healthInsuranceFundCode ? strings.hifWhatDoesNotExistAnymore(rfia.healthInsuranceFundCode!) : strings.selectWhat(strings.healthInsuranceFund)
				}
				close={cancel}
				closeText={strings.understood}
			/>
		);
	} else if (mode === 'info') {
		return (
			<AlertDialog
				open
				fullWidth
				title={strings.createWhat(strings.proformaAttachment12)}
				content={strings.createProformaAttachment12Content}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				accept={() => setMode('model')}
			/>
		);
	} else if (mode === 'model' && rfia.isFailedTrial === false) {
		return (
			<ProformaHandoverModelForm
				open
				rfia={rfia}
				confirm={date => {
					setHandoverDate(date ? date : undefined);
					setMode('document');
				}}
				cancel={cancel}
			/>
		);
	} else if (mode === 'model' && rfia.isFailedTrial === true) {
		return (
			<ProformaHandoverFailureModelForm
				open
				rfia={rfia}
				confirm={date => {
					setHandoverDate(date ? date : undefined);
					setMode('document');
				}}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<SelectRfiaHandoverCertificateTemplateForm
				open
				submitF={values => rfiaDocumentsCommand_createProformaHandoverCertificate(rfia.id, values)}
				confirm={confirm}
				cancel={confirm}
				handoverDate={handoverDate}
			/>
		);
	}
};
