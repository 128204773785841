import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IStockMovementsQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { LocationsPreloadCacheContext } from '../../../../settings/locations/LocationsPreloadCacheContext';

interface IProps {
	params: IStockMovementsQueryParams;
	onFilter: (params: IStockMovementsQueryParams) => void;
	paddingLeft: number;
}

// TODO fix this, is this correct?
const exclusions: (keyof IStockMovementsQueryParams)[] = [];

export const StockMovementsFilter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.dateCreated}
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<ListFilterComponent<string>
				label={strings.fromLocation}
				selected={params.fromLocationIds}
				options={locations}
				setSelected={value => onFilter({ ...params, fromLocationIds: value })}
				icon={<HelpOutlineIcon />}
			/>
			<ListFilterComponent<string>
				label={strings.toLocation}
				selected={params.toLocationIds}
				options={locations}
				setSelected={value => onFilter({ ...params, toLocationIds: value })}
				icon={<HelpOutlineIcon />}
			/>
		</FilterBar>
	);
};
