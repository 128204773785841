import { Button, Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IReturn, IReturnsFilterParams, ReturnExportProp, ReturnExportProps, returnsQuery_export } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RestrictedContent } from 'shared/layout/RestrictedContent';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsNavigateHeader } from 'shared/table/createDetailsNavigateHeader';
import { calcReturnDetailRoute } from '../../../routes';
import { ReturnsQueryContext } from './ReturnsQueryContext';
import { ReturnsListViewContext } from './ReturnsListViewContext';
import { ReturnsFilter } from './ReturnsFilter';
import { ReturnForm } from '../forms/ReturnForm';
import { ReturnsSelectableColumn } from './ReturnsSelectableColumn';

export const ReturnsListPage = ({ reloadTrigger }: IReloadTriggerProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { params, setParams, queryResult, reload } = useQueryContext(ReturnsQueryContext, reloadTrigger);
	const { columns, columnsRecord, setColumns, exportRecord, headers } = useContext(ReturnsListViewContext);
	const { push } = useHistory();
	const { open, confirm, cancel } = useDialogsContext(reload);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<ReturnForm
				open
				confirm={onConfirmNew}
				cancel={cancel}
			/>
		);
	};

	const onConfirmNew = (id: string) => {
		confirm();
		push(calcReturnDetailRoute(id));
	};

	return (
		<RestrictedContent pageClaim='Returns'>
			<div
				className='df-row-ac jc-sb'
				style={{ marginBottom: '24px' }}>
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder={strings.searchVerb}
					style={{ width: '400px' }}
					variant='paper'
				/>
				<Button
					variant='contained'
					color='primary'
					startIcon={<AddIcon />}
					onClick={onNew}>
					{strings.newWhat(strings.return)}
				</Button>
			</div>
			{queryResult.unfilteredCount > 0 && (
				<>
					<Card>
						<div className='df-row jc-sb'>
							<ReturnsFilter
								params={params}
								onFilter={params => setParams(onResetToPage1(params))}
								paddingLeft={theme.spacing(1.5)}
							/>
							<div
								className='df-row-ac'
								style={{ paddingRight: theme.spacing(1.5) }}>
								<ExportButtonMenuAndDialog<IReturnsFilterParams, ReturnExportProp>
									params={params}
									filteredCount={queryResult.filteredCount}
									unfilteredCount={queryResult.unfilteredCount}
									allProps={ReturnExportProps}
									downloadF={returnsQuery_export}
									fileName={strings.locations}
									record={exportRecord}
									view='returns'
								/>
								<SelectColumnsButtonAndDialog
									selectedColumns={columns}
									setSelectedColumns={setColumns}
									record={columnsRecord}
									view='returns'
								/>
							</div>
						</div>
						<Divider />
						<CardMedia>
							<PageableTableWithColumnSelection<IReturn, ReturnsSelectableColumn>
								queryResult={queryResult}
								headers={headers}
								sortParams={params.sortParams}
								pageParams={params.pageParams}
								setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
								getKey={inst => inst.id}
								selectedColumns={columns}
								appendColumn={createDetailsNavigateHeader<IReturn>(calcReturnDetailRoute)}
							/>
						</CardMedia>
					</Card>
				</>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound>{strings.noWhatRegistered(strings.returns)}</NoRecordsFound>}
		</RestrictedContent>
	);
};
