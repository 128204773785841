import { Checkbox } from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { IndexAvatar } from './IndexAvatar';

interface IProps {
	index: number;
	isDiscount: boolean;
	dragHandleProps: DraggableProvidedDragHandleProps | undefined;
	isSelected: boolean;
	toggleSelect: VoidFunction;
	style?: React.CSSProperties;
	hideIndex?: boolean;
}

const LineIndexDragAndSelect = ({ index, dragHandleProps, isSelected, toggleSelect, style, isDiscount, hideIndex }: IProps) => {
	return (
		<div
			className='df-col-ac'
			style={{ marginLeft: -6, ...style }}>
			{hideIndex !== true && (
				<IndexAvatar
					index={index}
					color={isDiscount ? 'error' : 'normal'}
				/>
			)}
			<div
				className='df-row-ac'
				{...dragHandleProps}>
				<DragHandleIcon color='action' />
			</div>
			<Checkbox
				checked={isSelected}
				tabIndex={-1}
				disableRipple
				onClick={toggleSelect}
				style={{ marginTop: -9, marginBottom: -9 }}
			/>
		</div>
	);
};

export default LineIndexDragAndSelect;
