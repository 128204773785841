import { v1 as uuid } from 'uuid';
import { IInventoryItemSummaryWithPurchasedAssuranceDto, IProductCatalogItem } from 'gen/ApiClient';
import { IPurchaseFormLine } from './IPurchaseFormLine';

const toDescription = (assurance: IProductCatalogItem, line: IInventoryItemSummaryWithPurchasedAssuranceDto): string => {
	if (line.serialNumber) {
		return `${assurance.name} sn: ${line.serialNumber}`;
	} else {
		return assurance.name ?? '';
	}
};

export const purchasedAssuranceToCreditedAssuranceLine = (
	assurance: IProductCatalogItem,
	line: IInventoryItemSummaryWithPurchasedAssuranceDto
): IPurchaseFormLine => {
	return {
		id: uuid(),
		description: toDescription(assurance, line),
		inventoryItemId: undefined,
		productCatalogItemId: assurance.id,
		quantity: -1,
		vatPercentage: assurance.vatPercentage,
		productCatalogItem: assurance,
		assuredLineId: line.transactionLineId,
		assuredProductCatalogItemId: line.assuredProductCatalogItemId,
		canChangeQuantity: false,
		serialNumber: '',
		unitListPrice: assurance.purchaseListPrice,
		unitPrice: assurance.purchaseListPrice,
		assuredSerialNumber: line.serialNumber,
		isCreditLine: true,
		creditedAssuranceLineId: line.transactionLineId,
	};
};
