import { LabelledChipsList } from 'framework/components/chips/LabelledChipsList';
import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { IRequestForInsuranceAllowance } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { boolToYesNo } from 'shared/utils/boolToString';
import { SelectableColumn } from '../SelectableColumn';
import React from 'react';

// TODO translate
export const createHeaders = (
	colRecord: Record<SelectableColumn, string>,
	strings: IStrings
): Record<SelectableColumn, IHeader<IRequestForInsuranceAllowance>> => {
	return {
		created: createSortableHeaderWithKey<IRequestForInsuranceAllowance, SelectableColumn>(colRecord, 'created', s => formatDate(s.created)),
		patientName: createSortableHeaderWithKey<IRequestForInsuranceAllowance, SelectableColumn>(colRecord, 'patientName', s => s.patientName),
		reference: createSortableHeaderWithKey<IRequestForInsuranceAllowance, SelectableColumn>(colRecord, 'reference', s => s.reference),
		isCancelled: createSortableHeaderWithKey<IRequestForInsuranceAllowance, SelectableColumn>(colRecord, 'isCancelled', p =>
			boolToYesNo(p.isCancelled, strings)
		),
		hasWarning: createSortableHeaderWithKey<IRequestForInsuranceAllowance, SelectableColumn>(colRecord, 'hasWarning', p => boolToYesNo(p.hasWarning, strings)),
		lastUpdated: createSortableHeaderWithKey<IRequestForInsuranceAllowance, SelectableColumn>(colRecord, 'lastUpdated', p => formatDate(p.lastUpdated)),
		handoverDate: createSortableHeaderWithKey<IRequestForInsuranceAllowance, SelectableColumn>(colRecord, 'handoverDate', p => formatDate(p.handoverDate)),
		medicalPrescriptionSentDate: createSortableHeaderWithKey<IRequestForInsuranceAllowance, SelectableColumn>(colRecord, 'medicalPrescriptionSentDate', p => formatDate(p.medicalPrescriptionSentDate)),
		labels: createHeaderWithKey<IRequestForInsuranceAllowance, SelectableColumn>(colRecord, 'labels', t => <LabelledChipsList labels={t.labels} />),
		isHandedOver: createHeaderWithKey<IRequestForInsuranceAllowance, SelectableColumn>(colRecord, 'isHandedOver', t => boolToYesNo(t.isHandedOver, strings)),
	};
};
