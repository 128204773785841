import { IconButton, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import { FileDownloadOutlineIcon } from 'framework/components/icons/FileDownloadOutlineIcon';
import { FileSearchOutlineIcon } from 'framework/components/icons/FileSearchOutlineIcon';
import { isFirst } from 'framework/utils/array/isFirst';
import { isLast } from 'framework/utils/array/isLast';
import { formatDate } from 'framework/utils/date/formatDate';
import { DocumentExportFormat, IPrescriptionScanHistory, documentsQuery_historyDownload } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { usePdfViewerWithDocument } from 'shared/dialogs/usePdfViewerWithDocument';

interface IProps {
	id: string;
	history: IPrescriptionScanHistory[];
	style?: React.CSSProperties;
	onDeleteHistory: (historyId: string) => void;
}

export const PrescriptionScanHistory = ({ id, history, style, onDeleteHistory }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [download] = useDownload(documentsQuery_historyDownload);
	const openPdf = usePdfViewerWithDocument();

	return (
		<Timeline style={{ padding: 0, margin: 0, ...style }}>
			{history.map(t => (
				<TimelineItem key={t.id}>
					<TimelineOppositeContent>
						<Typography
							color='textSecondary'
							variant='caption'>
							{formatDate(t.date, 'date-and-time')}
						</Typography>
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot
							variant='outlined'
							color='primary'
						/>
						{isLast(t, history) === false && <TimelineConnector />}
					</TimelineSeparator>
					<TimelineContent>
						<div
							className='df-row-ac'
							style={{ marginTop: isFirst(t, history) ? 0 : '-5px' }}>
							<div>
								{strings.step}: {t.step}
							</div>
							{isFirst(t, history) === false && (
								<div
									className='df-row-ac'
									style={{ marginLeft: theme.spacing(1) }}>
									{t.isPdf && (
										<IconButton
											size='small'
											onClick={() => openPdf(`${strings.history} ${strings.prescriptionAttachment17}`, id, t.id)}>
											<FileSearchOutlineIcon fontSize='small' />
										</IconButton>
									)}
									<IconButton
										size='small'
										onClick={() => download(id, t.id, 'Native' as DocumentExportFormat)}>
										<FileDownloadOutlineIcon fontSize='small' />
									</IconButton>
									<IconButton
										size='small'
										onClick={() => onDeleteHistory(t.id)}>
										<DeleteOutlineIcon fontSize='small' />
									</IconButton>
								</div>
							)}
						</div>
					</TimelineContent>
				</TimelineItem>
			))}
		</Timeline>
	);
};
