import { TextField } from '@material-ui/core';
import React from 'react';
import { DatePickerViewType } from '../../forms/common/DatePickerViewType';
import { useAnchorElement } from '../../hooks/useAnchorElement';
import { DefaultDateFormat } from '../../utils/date/DefaultDateFormat';
import { formatDateX } from '../../utils/date/formatDateX';
import { FormatDateXType } from '../../utils/date/FormatDateXType';
import { StaticDatePickerPopoverX } from './StaticDatePickerPopoverX';

interface IProps {
	value: Date;
	setValue: (val: Date) => void;
	label: string;
	disableFuture?: boolean;
	disablePast?: boolean;
	openTo?: DatePickerViewType;
	views?: DatePickerViewType[];
	format?: FormatDateXType;
	style?: React.CSSProperties;
}

export const DatePickerX = ({
	value,
	setValue,
	label,
	disableFuture = false,
	disablePast = false,
	openTo = 'date',
	views = ['year', 'month', 'date'],
	format = DefaultDateFormat,
	style,
}: IProps) => {
	const [anchor, openSelectDate, closeSelectDate] = useAnchorElement();

	return (
		<>
			{Boolean(anchor) && (
				<StaticDatePickerPopoverX
					value={value}
					setValue={setValue}
					anchor={anchor}
					disablePast={disablePast}
					disableFuture={disableFuture}
					close={closeSelectDate}
					openTo={openTo}
					views={views}
				/>
			)}
			{/* <Button variant="contained" color="default" onClick={openSelectDate} style={style}>{formatDateX(value, format)}</Button> */}
			<TextField
				style={style}
				variant='filled'
				label={label}
				value={formatDateX(value, format)}
				inputProps={{ style: { width: '90px' } }}
				// disabled
				onClick={openSelectDate}
			/>
		</>
	);
};
