import React from 'react';
import { AgendaProvider } from './AgendaProvider';
import { AgendaQueryProvider } from './AgendaQueryProvider';
import { AgendaViewProvider } from './AgendaViewProvider';

export const AgendaProviders = ({ children }: any) => {
	return (
		<AgendaQueryProvider>
			<AgendaProvider>
				<AgendaViewProvider>{children}</AgendaViewProvider>
			</AgendaProvider>
		</AgendaQueryProvider>
	);
};
