import { ChipX } from 'framework/components/chips/ChipX';
import { ArticlesInquiryStatusType, IArticlesInquiry, IArticlesInquiryDto } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { ArticlesInquiryStatusTypeToColorXRecord } from './ArticlesInquiryStatusToColorXRecord';

interface IProps {
	item: IArticlesInquiry | IArticlesInquiryDto;
	style?: React.CSSProperties;
	onClick?: VoidFunction;
}

export const ArticlesInquiryStatusChip = ({ item, style, onClick }: IProps) => {
	const { articlesInquiryStatusTypeRecord } = useContext(RecordContext);

	return (
		<ChipX
			label={articlesInquiryStatusTypeRecord[item.status as ArticlesInquiryStatusType]}
			color={ArticlesInquiryStatusTypeToColorXRecord[item.status as ArticlesInquiryStatusType]}
			style={style}
			onClick={onClick}
		/>
	);
};
