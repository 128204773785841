import React from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { INoahDeviceReadOut, NoahDeviceType } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (
	colRecord: Record<SelectableColumn, string>,
	strings: IStrings,
	typeRecord: Record<NoahDeviceType, string>
): Record<SelectableColumn, IHeader<INoahDeviceReadOut>> => {
	return {
		lastReadOutDate: createSortableHeaderWithKey<INoahDeviceReadOut, SelectableColumn>(colRecord, 'lastReadOutDate', t => formatDate(t.lastReadOutDate)),
		type: createHeaderWithKey<INoahDeviceReadOut, SelectableColumn>(colRecord, 'type', t => <RectangularChip label={typeRecord[t.type as NoahDeviceType]} />),
		instrumentTypeName: createSortableHeaderWithKey<INoahDeviceReadOut, SelectableColumn>(colRecord, 'instrumentTypeName', t => t.instrumentTypeName),
		formattedManufacturerCommaModelFromProductCatalogs: createHeaderWithKey<INoahDeviceReadOut, SelectableColumn>(
			colRecord,
			'formattedManufacturerCommaModelFromProductCatalogs',
			t => <div style={{ whiteSpace: 'pre-line' }}>{t.formattedManufacturerCommaModelFromProductCatalogs}</div>
		),
		uniqueField: createSortableHeaderWithKey<INoahDeviceReadOut, SelectableColumn>(colRecord, 'uniqueField', t => t.uniqueField),
		manufacturer: createSortableHeaderWithKey<INoahDeviceReadOut, SelectableColumn>(colRecord, 'manufacturer', t => t.manufacturer),
		countNonDeletedProductCatalogIds: createSortableHeaderWithKey<INoahDeviceReadOut, SelectableColumn>(colRecord, 'countNonDeletedProductCatalogIds', t =>
			t.countNonDeletedProductCatalogIds.toString()
		),
		manufacturerCommaModelFromProductCatalogsCsv: createSortableHeaderWithKey<INoahDeviceReadOut, SelectableColumn>(
			colRecord,
			'manufacturerCommaModelFromProductCatalogsCsv',
			t => t.manufacturerCommaModelFromProductCatalogsCsv
		),
	};
};
