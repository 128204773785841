import { useTheme } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { DocumentDetailDialog } from 'documents/DocumentDetailDialog';
import { DocumentFromTemplateForm } from 'documents/DocumentFromTemplateForm';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ISale, SalesType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	item: ISale;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const SalesDetailDocumentContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { saleTypeRecord } = useContext(RecordContext);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const theme = useTheme();

	const onNew = () => {
		open(
			<DocumentFromTemplateForm
				open
				context='Sale'
				contextId={item.id}
				templatePredefinedType='Invoice'
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onViewDetail = (documentId: string) => {
		open(
			<DocumentDetailDialog
				open
				close={confirm}
				id={documentId}
			/>
		);
	};

	return (
		<div
			className='df-col gap-6'
			style={style}>
			<div
				className='df-row-ac'
				style={{ fontSize: 12, color: theme.palette.text.secondary }}>
				<span>{strings.documents}</span>
				<div className='fg1'></div>
				<DescriptionOutlinedIcon style={{ fontSize: 16 }} />
			</div>
			<TextLinkButton
				startIcon={<DescriptionOutlinedIcon />}
				color={item.hasDocument === false ? 'secondary' : undefined}
				onClick={() => (item.hasDocument ? onViewDetail(item.documentId!) : onNew())}>
				{item.hasDocument === false && <em>{strings.createWhat(saleTypeRecord[item.type as SalesType])}</em>}
				{item.hasDocument === true && strings.viewEditWhat(saleTypeRecord[item.type as SalesType])}
			</TextLinkButton>
		</div>
	);
};
