import React from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { ICustomerAccount } from 'gen/ApiClient';

interface IProps {
	ca: ICustomerAccount;
	style?: React.CSSProperties;
}

export const CustomerAccountStatusChip = ({ ca, style }: IProps) => {
	return (
		<RectangularChip
			style={style}
			label={ca.hasOutstandingBalance ? 'Open' : 'Voldaan'}
			fill={ca.hasOutstandingBalance}
			color={ca.hasOutstandingBalance ? 'warning' : 'success'}
		/>
	);
};
