import { MbscCalendarEvent } from '@mobiscroll/react';
import { parseISO } from 'date-fns';
import { ICalendarEventDto } from 'gen/ApiClient';
import { AgendaColorScheme } from './AgendaColorScheme';
import { AgendaGroupScheme } from './AgendaGroupScheme';
import { mapEventColor } from './mapEventColor';
import { mapEventGroupKeys } from './mapEventGroupKeys';

export const mapEventToMobiscrollCalendarEvent = (
	event: ICalendarEventDto,
	groupScheme: AgendaGroupScheme,
	colorScheme: AgendaColorScheme
): MbscCalendarEvent => {
	return {
		id: event.id,
		start: parseISO(event.startDate as any),
		end: parseISO(event.endDate as any),
		allDay: event.isAllDay,
		recurring: event.rRule,
		recurringException: event.exDates,
		title: event.title,
		resource: mapEventGroupKeys(event, groupScheme),
		color: mapEventColor(event, colorScheme),
		data: event,
	};
};
