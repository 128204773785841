import { ChipX } from 'framework/components/chips/ChipX';
import { PaymentStatus } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IXSale {
	isFullyPaid: boolean;
	isOverDue?: boolean;
	paymentStatus: string | undefined;
}

interface IProps {
	item: IXSale;
	style?: React.CSSProperties;
}

export const SalesPaymentStatusChip = ({ item, style }: IProps) => {
	const { paymentStatusRecord } = useContext(RecordContext);

	return (
		<ChipX
			style={style}
			label={paymentStatusRecord[item.paymentStatus as PaymentStatus]}
			color={item.isFullyPaid ? 'success' : item.isOverDue ? 'error' : 'info'}
		/>
	);
};
