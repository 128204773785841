import { Button, Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { SearchbarWithMode } from 'framework/components/search/SearchbarWithMode';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IPatientSummary, IPatientsFilterParams, PatientExportProp, PatientExportProps, PatientSearchMode, patientsQuery_export } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { RecordContext } from 'shared/records/RecordContext';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsNavigateHeader } from 'shared/table/createDetailsNavigateHeader';
import { PatientSettingsAndViewPreferencesRoute } from '../PatientRoutes';
import { PatientStatusChipOrFragment } from '../PatientStatusChipOrFragment';
import { PatientsBreadcrumb } from '../PatientsBreadcrumb';
import { EidForm } from '../eid/EidForm';
import { PatientForm } from '../forms/PatientForm';
import { PatientQuickFilter, PatientQuickFilters } from './PatientQuickFilter';
import { PatientsListFilter } from './PatientsListFilter';
import { PatientsListViewContext } from './PatientsListViewContext';
import { PatientsQueryContext } from './PatientsQueryContext';
import { PatientsSelectableColumn } from './PatientsSelectableColumn';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { BatchCreatePatientsFromNhaxFile } from '../forms/BatchCreatePatientsFromNhaxFile';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

export const PatientsListPage = () => {
	const theme = useTheme();
	const { params, setParams, queryResult, reload } = useQueryContext(PatientsQueryContext);
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { headers, columns, setColumns, columnsRecord, exportRecord, quickFilter, setQuickFilter, quickFilterRecord } = useContext(PatientsListViewContext);
	const { push } = useHistory();
	const { patientSearchModeToLabelRecord, patientSearchModeRecord } = useContext(RecordContext);
	const isSuperUser = useLoggedInUserIsSuperUser();

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onEid = () => {
		open(
			<EidForm
				cancel={cancel}
				confirm={id => {
					confirm();
					push(calcPatientDetailRoute(id));
				}}
			/>
		);
	};

	const onNew = () => {
		open(
			<PatientForm
				cancel={cancel}
				confirm={id => {
					confirm();
					push(calcPatientDetailRoute(id));
				}}
			/>
		);
	};

	const onBatchCreateFromNhax = () => {
		open(
			<BatchCreatePatientsFromNhaxFile
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='Patients'
			stickyHeader={
				<div
					className='df-row-ac'
					style={{ paddingBottom: 16 }}>
					<PatientsBreadcrumb />
					<div className='fg1'></div>
					<PageXHeaderActions>
						<PageSettingsButtonX route={PatientSettingsAndViewPreferencesRoute} />
						<PageReloadButtonX reload={reload} />
					</PageXHeaderActions>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16 }}>
				<div className='df-row-ac jc-sb'>
					<SearchbarWithMode
						params={params}
						setParams={setParams}
						modeRecord={patientSearchModeRecord}
						variant='paper'
						style={{ width: '520px' }}
						placeholder={patientSearchModeToLabelRecord[params.searchMode as PatientSearchMode]}
					/>
					<div>
						{isSuperUser && (
							<Button
								variant='outlined'
								color='primary'
								startIcon={<GroupAddIcon />}
								onClick={onBatchCreateFromNhax}
								style={{ marginRight: '8px' }}>
								{`Create multiple patients from NHAX file`}
							</Button>
						)}
						<Button
							variant='outlined'
							color='primary'
							startIcon={<PersonAddIcon />}
							onClick={onNew}
							style={{ marginRight: '8px' }}>
							{strings.newPatient}
						</Button>
						<Button
							variant='contained'
							color='primary'
							startIcon={<ContactMailIcon />}
							onClick={onEid}>
							{strings.readeID}
						</Button>
					</div>
				</div>
				{queryResult.unfilteredCount > 0 && (
					<Card>
						<div
							className='df-row-ac jc-sb'
							style={{ padding: theme.spacing(1) }}>
							<ButtonGroupWithSelection<PatientQuickFilter>
								options={PatientQuickFilters}
								selected={quickFilter}
								setSelected={setQuickFilter}
								renderOption={t => quickFilterRecord[t]}
							/>
							<div>
								<ExportButtonMenuAndDialog<IPatientsFilterParams, PatientExportProp>
									params={params}
									filteredCount={queryResult.filteredCount}
									unfilteredCount={queryResult.unfilteredCount}
									downloadF={patientsQuery_export}
									fileName={strings.patients}
									record={exportRecord}
									allProps={PatientExportProps}
									view='patients'
								/>
								<SelectColumnsButtonAndDialog
									selectedColumns={columns}
									setSelectedColumns={setColumns}
									record={columnsRecord}
									view='patients'
								/>
							</div>
						</div>
						<Divider />
						<div className='df-row jc-sb'>
							<PatientsListFilter
								params={params}
								onFilter={params => setParams(onResetToPage1(params))}
								paddingLeft={theme.spacing(1.5)}
							/>
						</div>
						<Divider />
						<CardMedia>
							<PageableTableWithColumnSelection<IPatientSummary, PatientsSelectableColumn>
								queryResult={queryResult}
								headers={headers}
								sortParams={params.sortParams}
								pageParams={params.pageParams}
								setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
								getKey={inst => inst.id}
								selectedColumns={columns}
								prependColumn={createEmptyHeader<IPatientSummary>('status', t => (
									<PatientStatusChipOrFragment item={t} />
								))}
								appendColumn={createDetailsNavigateHeader<IPatientSummary>(calcPatientDetailRoute)}
							/>
						</CardMedia>
					</Card>
				)}
				{queryResult.unfilteredCount === 0 && <NoRecordsFound>{strings.noPatientsDefined}</NoRecordsFound>}
			</div>
		</RestrictedPageX>
	);
};
