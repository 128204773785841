import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Try } from 'framework/Try';
import { IRegisterSendDocumentsModel, ITimeModel, IUpdateResponse, TimeUnit } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IRegisterSendDocumentsModel>({
		sentDate: yup.date().required(),
		expectedReceptionDate: yup.date().required(),
	});
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	submitFunction: (values: IRegisterSendDocumentsModel) => Promise<Try<IUpdateResponse<IRegisterSendDocumentsModel>>>;
	defaultExpectedReceptionTerm: ITimeModel;
}

export const RegisterSendDocumentsForm = ({ confirm, cancel, submitFunction, defaultExpectedReceptionTerm, ...rest }: IProps) => {
	const strings = useLocalization();
	const [update, isSubmitting] = useFormSubmit(submitFunction);
	const units: TimeUnit[] = useMemo(() => ['Days', 'Weeks'] as TimeUnit[], []);
	const now = useNow();

	const handleSubmit = async (values: IRegisterSendDocumentsModel, helpers: FormikHelpers<IRegisterSendDocumentsModel>) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik
			validateOnMount
			validationSchema={createSchema(strings)}
			initialValues={{
				sentDate: now,
				expectedReceptionDate: now,
			}}
			onSubmit={handleSubmit}>
			{props => (
				<Form>
					<Dialog
						maxWidth='xs'
						fullWidth
						{...rest}>
						<DialogTitle>{strings.registerLetterDispatch}</DialogTitle>
						<DialogContent
							dividers
							className='df-col'>
							<FormDatePicker<IRegisterSendDocumentsModel>
								name='sentDate'
								label={strings.dispatchDate}
							/>
							<FormDatePickerWithUnit<IRegisterSendDocumentsModel>
								name='expectedReceptionDate'
								fromDate={props.values.sentDate ?? now}
								label={strings.expectedWhat(strings.receptionDateOfDocuments)}
								required
								units={units}
								defaultUnit='Days'
								defaultValue={defaultExpectedReceptionTerm}
							/>
						</DialogContent>
						<CancelSubmitFormDialogActions
							submitText={strings.register}
							isSubmitting={isSubmitting}
							cancel={cancel}
						/>
					</Dialog>
				</Form>
			)}
		</Formik>
	);
};
