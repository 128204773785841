import { Collapse } from '@material-ui/core';
import { calcInventoryItemDetailRoute, calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { TextLinkButtonWithExpandIcon } from 'framework/components/buttons/TextLinkButtonWithExpandIcon';
import { ChipX } from 'framework/components/chips/ChipX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IPurchase, IPurchaseLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { PurchaseLineStatusIcon } from './PurchaseLineStatusIcon';
import { mapPurchaseLineToColorX } from './utils';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	line: IPurchaseLine;
	purchase: IPurchase;
	withoutBorder?: boolean;
	isExpanded: boolean;
	toggle: VoidFunction;
}

export const PurchaseLineListItem = ({ line, purchase, withoutBorder, isExpanded, toggle }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const color = useMemo(() => mapPurchaseLineToColorX(line), [line]);
	const tColor = useThemeColor(color);

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<PurchaseLineStatusIcon
					item={line}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col fg1 gap-4'>
				{line.hasProductCatalogItem === true && (
					<TextLinkButton
						onClick={() => {
							if (line.inventoryItemId) {
								push(calcInventoryItemDetailRoute(line.inventoryItemId));
							} else if (line.productCatalogItemId) {
								push(calcProductCatalogDetailRoute(line.productCatalogItemId));
							}
						}}
						style={{ fontSize: MajorFontSize }}>
						{`${line.quantity} x ${line.description}`}
					</TextLinkButton>
				)}
				{line.hasProductCatalogItem === false && <span style={{ fontSize: MajorFontSize }}>{`${line.quantity} x ${line.description}`}</span>}
				{line.isCreditLine && purchase.isCreditNote === false && (
					<ChipX
						label={strings.creditNoteLine}
						color='error'
					/>
				)}
				<TextLinkButtonWithExpandIcon
					toggle={toggle}
					isExpanded={isExpanded}>
					<span style={{ fontSize: MinorFontSize, fontWeight: 'unset' }}>
						{`${line.vatPercentage}% ${strings.vat}, ${formatCurrency(line.linePriceExVat)} (${strings.exVatShort})`}
					</span>
				</TextLinkButtonWithExpandIcon>
				<Collapse in={isExpanded}>
					<div className='df-col gap-4'>
						<span style={{ fontSize: MinorFontSize }}>
							{`${strings.unitListPrice}: ${formatCurrency(line.unitListPriceExVat)} (${strings.exVatShort}) | ${formatCurrency(line.unitListPriceInVat)} (${
								strings.inVatShort
							})`}
						</span>
						<span style={{ fontSize: MinorFontSize }}>
							{`${strings.discount}: ${line.discountPercentage.toFixed(2)}% | ${formatCurrency(line.lineDiscountValueExVat)} (${
								strings.exVatShort
							}) | ${formatCurrency(line.lineDiscountValueInVat)} (${strings.inVatShort})`}
						</span>
						<span style={{ fontSize: MinorFontSize }}>{`${strings.vat}: ${line.vatPercentage}% | ${formatCurrency(line.lineVatValue)}`}</span>
					</div>
				</Collapse>
			</div>
		</div>
	);
};
