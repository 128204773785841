import { IConcurrencyData } from 'framework/types/IConcurrencyData';
import {
	CalendarMode,
	IAnnotationType,
	IArticlePropertyAutocomplete,
	IArticlePropertyKey,
	ICalendarEventType,
	ICalendarResourcesDto,
	ICommissionRulesCache,
	IDefaultColumnSelectionDto,
	IEntPhysicianWithOfficesSummary,
	IGeneralPractitionerWithOfficesSummary,
	IHealthInsuranceFundCache,
	ILocationSummary,
	INotificationWithStatusDto,
	IPaymentMethodSummary,
	IRoomsCache,
	IRosaViewPreferences,
	ISupplierSummary,
	IUserPreferences,
	IUsersCache,
} from 'gen/ApiClient';
import React from 'react';

interface IProps {
	annotationTypes: IConcurrencyData<IAnnotationType[]>;
	bebat: IConcurrencyData<number>;
	recupel: IConcurrencyData<string>;
	clientLatestBuild: IConcurrencyData<number>;
	eidLatestBuild: IConcurrencyData<number>;
	imageScannerLatestBuild: IConcurrencyData<number>;
	thermalPrinterLatestBuild: IConcurrencyData<number>;
	calendarEventTypes: IConcurrencyData<ICalendarEventType[]>;
	commissionRules: IConcurrencyData<ICommissionRulesCache>;
	locations: IConcurrencyData<ILocationSummary[]>;
	roomsCache: IConcurrencyData<IRoomsCache>;
	usersCache: IConcurrencyData<IUsersCache>;
	defaults: IDefaultColumnSelectionDto[];
	lastNotifications: IConcurrencyData<INotificationWithStatusDto[]>;
	articlePropertyKeys: IConcurrencyData<IArticlePropertyKey[]>;
	articlePropertiesAutocomplete: IConcurrencyData<IArticlePropertyAutocomplete[]>;
	generalPractitioners: IConcurrencyData<IGeneralPractitionerWithOfficesSummary[]>;
	entPhysicians: IConcurrencyData<IEntPhysicianWithOfficesSummary[]>;
	suppliers: IConcurrencyData<ISupplierSummary[]>;
	hifCache: IConcurrencyData<IHealthInsuranceFundCache>;
	calendarResources: IConcurrencyData<ICalendarResourcesDto>;
	paymentMethods: IConcurrencyData<IPaymentMethodSummary[]>;
	isRosaIntegrationEnabled: boolean;
	rosaViewPreferences: IRosaViewPreferences;
	rosaUrl: string;
	userPreferences: IUserPreferences;
	isExactOnlineIntegrationEnabled: boolean;
	isInvoiceForwardingIntegrationEnabled: boolean;
	requestUrl: string;
	calendarMode: CalendarMode;
}

export const PreloadContext = React.createContext<IProps>(undefined as any);
