import { Divider, IconButton, InputBase, useTheme } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SearchIcon from '@material-ui/icons/Search';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { SortableTable } from 'framework/table/SortableTable';
import { commissionsQuery_allNotPaidOutForUser, PaymentStatus, PaymentStatuses } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';
import { createHeaders } from './createHeaders';
import { ICommissionWithSelection } from './ICommissionWithSelection';

interface IFilterParams {
	after: Date | undefined;
	before: Date | undefined;
	paymentStatuses: string[];
	isPaidByPatient: boolean | undefined;
	isPaidByHif: boolean | undefined;
}

const DefaultParams: IFilterParams = {
	after: undefined,
	before: undefined,
	paymentStatuses: [],
	isPaidByHif: undefined,
	isPaidByPatient: undefined,
};

interface IProps {
	lines: ICommissionWithSelection[];
	setLines: React.Dispatch<React.SetStateAction<ICommissionWithSelection[]>>;
	userId: string;
}

export const CommissionLineTable = ({ lines, setLines, userId }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [search, setSearch] = useState<string>('');
	const [filtered, setFiltered] = useState<ICommissionWithSelection[]>(lines);
	const headers = useMemo(() => createHeaders(lines, filtered, setLines, strings), [lines, filtered, setLines, strings]);
	const [params, setParams] = useState<IFilterParams>(DefaultParams);
	const { paymentStatusRecord } = useContext(RecordContext);
	const [allLines] = useApiEffect(commissionsQuery_allNotPaidOutForUser, userId);

	useEffect(() => {
		if (allLines !== undefined) {
			setLines(allLines.map<ICommissionWithSelection>(t => ({ isSelected: t.isFullyPaid, ...t })));
		}
		// eslint-disable-next-line
	}, [allLines]);

	useEffect(() => {
		const x = lines
			.filter(t => `${t.salesReference} ${t.salesRecipientName}`.toLowerCase().includes(search.toLowerCase()))
			.filter(t => params.after === undefined || params.after === null || t.salesDate >= params.after)
			.filter(t => params.before === undefined || params.before === null || t.salesDate <= params.before)
			.filter(t => params.isPaidByHif === undefined || params.isPaidByHif === null || t.isPaidByHif === params.isPaidByHif)
			.filter(t => params.isPaidByPatient === undefined || params.isPaidByPatient === null || t.isPaidByPatientOrCompany === params.isPaidByPatient)
			.filter(t => params.paymentStatuses.length === 0 || params.paymentStatuses.indexOf(t.sale.paymentStatus as PaymentStatus) > -1);
		setFiltered(x);
		// eslint-disable-next-line
	}, [search, lines, params]);

	return (
		<>
			<div
				className='df-row-ac'
				style={{ padding: theme.spacing(1.5), paddingLeft: theme.spacing(2) }}>
				<SearchIcon style={{ color: theme.palette.text.secondary }} />
				<InputBase
					placeholder={`${strings.searchVerb}...`}
					style={{ width: '100%', paddingLeft: '26px' }}
					value={search}
					onKeyDown={e => e.stopPropagation()}
					onChange={e => setSearch(e.target.value)}
				/>
				{search && (
					<IconButton
						onClick={() => setSearch('')}
						size='small'>
						<ClearIcon />
					</IconButton>
				)}
			</div>
			<Divider />
			<FilterBar
				isFiltered={false}
				onClear={() => setParams(clearParams(params))}>
				<DateFilterItem
					label={strings.date}
					after={params.after === null ? undefined : params.after}
					before={params.before === null ? undefined : params.before}
					setSelected={(after, before) => setParams({ ...params, after: after, before: before })}
				/>
				<RecordListFilterComponent<PaymentStatus>
					options={PaymentStatuses}
					record={paymentStatusRecord}
					selected={params.paymentStatuses as any}
					setSelected={vals => setParams({ ...params, paymentStatuses: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.paymentStatus}
				/>
				<BooleanRadioFilterComponent
					label={`Betaald door patiënt?`}
					style={{ marginBottom: theme.spacing(0.5) }}
					badgeVariant='dot'
					trueText={strings.paid}
					falseText={strings.notWhat(strings.paid)}
					selected={params.isPaidByPatient}
					setSelected={val => setParams({ ...params, isPaidByPatient: val })}
					icon={<HelpOutlineIcon />}
				/>
				<BooleanRadioFilterComponent
					label={`Betaald door V.I.?`}
					style={{ marginBottom: theme.spacing(0.5) }}
					badgeVariant='dot'
					trueText={strings.paid}
					falseText={strings.notWhat(strings.paid)}
					selected={params.isPaidByHif}
					setSelected={val => setParams({ ...params, isPaidByHif: val })}
					icon={<HelpOutlineIcon />}
				/>
			</FilterBar>
			<Divider />
			<SortableTable<ICommissionWithSelection>
				padding={theme.spacing(1)}
				headers={headers}
				getKey={t => t.id}
				values={filtered}
				defaultSortParams={{ property: 'created', direction: 'asc' }}
			/>
		</>
	);
};
