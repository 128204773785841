import { IHeader } from './IHeader';

export const filterHeaders = <TData extends unknown, TSelectable extends string>(
	headers: Record<TSelectable, IHeader<TData>>,
	selectedColumns: TSelectable[],
	appendColumn: IHeader<TData> | IHeader<TData>[] | undefined,
	prependColumn: IHeader<TData> | IHeader<TData>[] | undefined
): IHeader<TData>[] => {
	const selColumns = [...selectedColumns.map(t => headers[t] as IHeader<TData>)];
	const x = prependColumn === undefined ? selColumns : Array.isArray(prependColumn) ? [...prependColumn, ...selColumns] : [prependColumn, ...selColumns];
	const y = appendColumn === undefined ? x : Array.isArray(appendColumn) ? [...x, ...appendColumn] : [...x, appendColumn];
	return y;
};
