import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { QuickFilter } from '../SalesCard';
import { SelectableColumn } from '../SelectableColumn';
import { createColumnRecord } from '../utils/createColumnRecord';
import { createExportRecord } from '../utils/createExportRecord';
import { createHeaders } from '../utils/createHeaders';
import { createQfRecord } from '../utils/createQfRecord';
import { ViewContext } from './ViewContext';

// enkel hier nodig
const DefaultColumns: SelectableColumn[] = [
	'salesReference',
	'referencePatientIdentifier',
	'settlementProposalReference',
	'value',
	'outstandingBalance',
	'amountPaid',
];

export const ViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [qf, setQf] = useState<QuickFilter>('all');
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);
	const colRecord = useMemo(() => createColumnRecord(strings), [strings]);
	const headers = useMemo(() => createHeaders(colRecord, strings), [colRecord, strings]);
	const quickFilterRecord = useMemo(() => createQfRecord(strings), [strings]);
	const exportRecord = useMemo(() => createExportRecord(strings), [strings]);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
				quickFilter: qf,
				setQuickFilter: setQf,
				quickFilterRecord: quickFilterRecord,
				exportRecord: exportRecord,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
