import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { createExportRecord } from './createExportRecord';
import { SalesListViewContext } from './SalesListViewContext';
import { createLinesExportRecord } from './createLinesExportRecord';

export const SalesListViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const exportRecord = useMemo(() => createExportRecord(strings), [strings]);
	const linesExportRecord = useMemo(() => createLinesExportRecord(strings), [strings]);

	return (
		<SalesListViewContext.Provider
			value={{
				exportRecord: exportRecord,
				linesExportRecord: linesExportRecord,
			}}>
			{children}
		</SalesListViewContext.Provider>
	);
};
