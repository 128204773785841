import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	title?: string;
	content: string | JSX.Element;
	closeText?: string;
	close: VoidFunction;
}

// const defaultFunction = () => Promise.resolve(new Try({ error: '', hasError: false }));

export const NotificationDialog = ({ title, content, closeText, close, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<Dialog
			// onClose={close}
			{...rest}>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent dividers>
				<DialogContentText style={{ whiteSpace: 'pre-line' }}>{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={close}
					color='primary'>
					{closeText ?? strings.close}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
