import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { IContactInfo } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { PrimaryContactInfoIconAndTooltip } from './PrimaryContactInfoIconAndTooltip';

interface IProps {
	item: IContactInfo;
	onMakePrimary: VoidFunction;
	onEdit: VoidFunction;
	onDelete: VoidFunction;
}

export const PatientContactInfoPopoverContent = ({ item, onMakePrimary, onEdit, onDelete }: IProps) => {
	const strings = useLocalization();

	return (
		<div
			className='df-col'
			style={{ padding: 16 }}>
			<div className='df-row-ac'>
				<div style={{ fontWeight: 'bold', marginRight: 4 }}>{item.value}</div>
				{item.isPrimary && <PrimaryContactInfoIconAndTooltip />}
			</div>
			{item.isValid === false && <ErrorLabelledProperty error={strings.incorrectFormat} />}
			{item.description && <div>{`(${item.description})`}</div>}
			<DividerWithMargin />
			<TextLinkButton
				startIcon={<EditOutlinedIcon />}
				onClick={onEdit}>
				{strings.edit}
			</TextLinkButton>
			<TextLinkButton
				startIcon={<StarBorderOutlinedIcon />}
				onClick={onMakePrimary}
				disabled={item.canMakePrimary === false}>
				{strings.makePrimary}
			</TextLinkButton>
			<TextLinkButton
				startIcon={<DeleteOutlinedIcon />}
				onClick={onDelete}
				hoverColor='secondary'>
				{strings.delete}
			</TextLinkButton>
		</div>
	);
};
