import { DialogProps } from '@material-ui/core';
import React from 'react';
import { IInventoryItem, inventoryItemsCommand_patchRizivHearingAidDefinitionV2IdentificationCode } from 'gen/ApiClient';
import { SelectRizivRefundableHearingInstrumentForInventoryItemForm } from 'shared/rizivRefundableHearingInstruments/SelectRizivRefundableHearingInstrumentForInventoryItemForm';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	item: IInventoryItem;
}

export const LinkRizivRefundableHearingInstrumentForm = ({ item, cancel, confirm, ...rest }: IProps) => {
	return (
		<SelectRizivRefundableHearingInstrumentForInventoryItemForm
			{...rest}
			cancel={cancel}
			confirm={confirm}
			item={item}
			submitF={rizivHearingAidDefinitionV2 =>
				inventoryItemsCommand_patchRizivHearingAidDefinitionV2IdentificationCode({
					inventoryItemId: item.id,
					rizivHearingAidDefinitionV2IdentificationCode: rizivHearingAidDefinitionV2.identificationCode,
				})
			}
		/>
	);
};
