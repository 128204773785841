import { Dialog, DialogProps, Grid, Typography } from '@material-ui/core';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import React from 'react';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { formatDate } from 'framework/utils/date/formatDate';
import { commissionPayoutsCommand_delete, commissionPayoutsQuery_single, commissionPayoutsCommand_confirm } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { CommissionLinesTable } from './CommissionLinesTable';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { PayoutConfirmationStatusChip } from './PayoutConfirmationStatusChip';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';

interface IProps extends DialogProps {
	id: string;
	close: VoidFunction;
	withActions?: boolean;
}

export const CommissionPayoutDetailDialog = ({ id, close, withActions = false, ...rest }: IProps) => {
	const strings = useLocalization();
	const [item, reload] = useApiEffect(commissionPayoutsQuery_single, id);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);

	if (item === undefined) {
		return <div></div>;
	}

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(`${strings.payout}-${strings.commissions}`)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => commissionPayoutsCommand_delete(item.id)}
				accept={() => {
					cancel();
					close();
				}}
			/>
		);
	};

	const onConfirm = () => {
		open(
			<AlertDialog
				open
				content={`Uitbetaling bevestigen?`}
				acceptText={strings.yes}
				rejectText={strings.no}
				reject={cancel}
				acceptF={() => commissionPayoutsCommand_confirm(item.id)}
				accept={confirm}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			scroll='paper'
			fullWidth
			maxWidth='md'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<ReceiptOutlinedIcon />}>
					<Typography variant='h1'>{`${strings.commissions}-${strings.payout}`}</Typography>
					<PayoutConfirmationStatusChip
						data={item}
						style={{ marginTop: 8, marginBottom: 8 }}
					/>
					<LabelledProperty
						label={strings.date}
						property={formatDate(item.created)}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.paidOutDate}
						property={formatDate(item.payoutDate)}
						withoutMargin
					/>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.details} />
				<PropertyInGrid
					label={strings.reference}
					value={item.reference}
				/>
				<PropertyInGrid
					label={strings.user}
					value={item.userIdentifier}
				/>
				<GridDividerWithCaption caption={strings.lines} />
				<Grid
					item
					xs={12}>
					<CommissionLinesTable item={item} />
				</Grid>
				{withActions === true && (
					<>
						<GridDividerWithCaption caption={strings.actions} />
						<PropertyInGrid
							value={
								<div className='df-col-as'>
									{item.isConfirmed === false && (
										<SmallPrimaryTextButton
											onClick={onConfirm}
											startIcon={<ThumbUpAltOutlinedIcon />}>
											Bevestig uitbetaling
										</SmallPrimaryTextButton>
									)}
									<SmallPrimaryTextButton
										startIcon={<DeleteOutlineOutlinedIcon />}
										color='secondary'
										onClick={onDelete}>
										{strings.delete}
									</SmallPrimaryTextButton>
								</div>
							}
						/>
					</>
				)}
			</DialogContentGrid>
		</Dialog>
	);
};
