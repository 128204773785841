import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RestrictedContent } from 'shared/layout/RestrictedContent';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { CommissionsTable } from './CommissionsTable';
import { AllQueryContext } from './context/AllQueryContext';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { QuickFilter, QuickFilters } from './QuickFilter';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { CommissionExportProp, CommissionExportProps, ICommissionsFilterParams, commissionsQuery_export } from 'gen/ApiClient';

export const AllCommissionsPage = ({ reloadTrigger }: IReloadTriggerProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { params, setParams, queryResult, reload } = useQueryContext(AllQueryContext, reloadTrigger);
	const { columns, columnsRecord, setColumns, quickFilterRecord, quickFilter, setQuickFilter, exportRecord } = useContext(ViewContext);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedContent pageClaim='AllCommissions'>
			<div
				className='df-row-ac jc-sb'
				style={{ marginBottom: '24px' }}>
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder={strings.searchVerb}
					style={{ width: '400px' }}
					variant='paper'
				/>
			</div>
			{queryResult.unfilteredCount > 0 && (
				<>
					<Card>
						<div
							className='df-row-ac jc-sb'
							style={{ padding: theme.spacing(1) }}>
							<ButtonGroupWithSelection<QuickFilter>
								options={QuickFilters}
								selected={quickFilter}
								setSelected={setQuickFilter}
								renderOption={t => quickFilterRecord[t]}
							/>
						</div>
						<Divider />
						<div className='df-row'>
							<Filter
								params={params}
								onFilter={params => setParams(onResetToPage1(params))}
								paddingLeft={theme.spacing(1.5)}
							/>
							<div className='fg1'></div>
							<ExportButtonMenuAndDialog<ICommissionsFilterParams, CommissionExportProp>
								params={params}
								filteredCount={queryResult.filteredCount}
								unfilteredCount={queryResult.unfilteredCount}
								allProps={CommissionExportProps}
								downloadF={commissionsQuery_export}
								fileName={strings.commissions}
								record={exportRecord}
								view='noahDevices'
							/>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
								view='allCommissionsPage'
							/>
						</div>
						<Divider />
						<CardMedia>
							<CommissionsTable
								queryResult={queryResult}
								params={params}
								setParams={setParams}
								reload={reload}
							/>
						</CardMedia>
					</Card>
				</>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound>{strings.noWhatDefined(strings.commissions)}</NoRecordsFound>}
		</RestrictedContent>
	);
};
