import { Dialog, DialogContent, DialogProps, DialogTitle, FormControlLabel, IconButton, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { AutocompleteMultipleFreeSolo } from 'framework/components/autocomplete/AutocompleteMultipleFreeSolo';
import { useApiCall } from 'framework/hooks/useApiCall';
import { IWebPhysician, physiciansSearch_searchByName, physiciansSearch_searchByRizivNumber } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { GenderIcon } from './GenderIcon';

interface IProps extends DialogProps {
	searchForWhatTitle: string;
	qualicationCodes: string[];
	confirm: (val: IWebPhysician) => void;
	cancel: VoidFunction;
}

export const SearchPhysicianDialog = ({ confirm, cancel, qualicationCodes, searchForWhatTitle: title, ...rest }: IProps) => {
	const strings = useLocalization();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selected, setSelected] = useState<IWebPhysician>();
	const [qCodes, setQCodes] = useState<string[]>(qualicationCodes);
	const [searchMode, setSearchMode] = useState<'name' | 'riziv'>('name');
	const [results, setResults] = useState<IWebPhysician[]>([]);
	const [searchString, setSearchString] = useState<string>('');

	useEffect(() => {
		if (selected) {
			confirm(selected);
		}
		// confirm function does not change
		// eslint-disable-next-line
	}, [selected]);

	const searchByNameF = useApiCall(physiciansSearch_searchByName, setIsLoading);
	const searchByRizivF = useApiCall(physiciansSearch_searchByRizivNumber, setIsLoading);

	const clear = () => {
		setSelected(undefined);
		setResults([]);
	};

	const search = async (searchString: string, searchMode: 'name' | 'riziv', qCodes: string[]) => {
		if (searchString) {
			const r =
				searchMode === 'name' ? await searchByNameF({ codes: qCodes, name: searchString }) : await searchByRizivF({ codes: qCodes, rizivNumber: searchString });
			setResults(r.isSuccess ? r.result : []);
		}
	};

	useEffect(() => {
		const timeoutId = setTimeout(() => search(searchString, searchMode, qCodes), 500);
		return () => clearTimeout(timeoutId);
		// eslint-disable-next-line
	}, [searchString, searchMode, qCodes]);

	return (
		<Dialog
			{...rest}
			fullWidth>
			<DialogTitle>
				<div className='df-row-ac jc-sb'>
					<div>
						{strings.search} {title}
					</div>
					<IconButton
						style={{ margin: '-12px' }}
						onClick={cancel}>
						<CloseIcon />
					</IconButton>
				</div>
			</DialogTitle>
			<DialogContent dividers>
				<RadioGroup
					value={searchMode}
					onChange={(_, val) => {
						setSearchMode(val as any);
						clear();
					}}>
					<FormControlLabel
						value='name'
						control={<Radio />}
						label={strings.searchOnName}
					/>
					<FormControlLabel
						value='riziv'
						control={<Radio />}
						label={strings.searchOnRIZIVNumber}
					/>
				</RadioGroup>
				<AutocompleteMultipleFreeSolo
					style={{ padding: '10px 0px' }}
					disabled={isLoading}
					label={strings.qualificationCodes}
					options={['041', '414', '410']}
					selected={qCodes}
					setSelected={setQCodes}
				/>
				<Autocomplete
					style={{ padding: '10px 0px' }}
					inputValue={searchString}
					onInputChange={(e: any, val: string, reason: string) => {
						if ((searchMode === 'riziv' && isNaN(val as any) === false) || searchMode === 'name') {
							setSearchString(val);
						}
					}}
					// disabled={mode === 'searching'}
					loading={isLoading}
					loadingText={`${strings.searchVerb}...`}
					disableClearable
					forcePopupIcon={false}
					value={selected}
					onChange={(_: any, newValue: IWebPhysician) => setSelected(newValue)}
					options={results}
					noOptionsText={strings.noResults}
					getOptionLabel={option => `${option.lastName}, ${option.firstName}`}
					renderOption={option => (
						<div className='df-col'>
							<div className='df-row-ac'>
								<Typography variant='body1'>
									{option.lastName}, {option.firstName}
								</Typography>
								<GenderIcon
									gender={option.gender as any}
									style={{ width: '15px', marginLeft: '4px' }}
								/>
							</div>
							<Typography variant='caption'>{option.rizivNumberWithQualificationFormatted}</Typography>
						</div>
					)}
					renderInput={params => (
						<TextField
							{...params}
							label={searchMode === 'name' ? strings.searchOnName : strings.searchOnRIZIVNumber}
							placeholder={searchMode === 'name' ? strings.lastNameCommaFirstName : 'X-XXXXX-XX'}
							variant='filled'
							onKeyDownCapture={e => e.key === 'Enter' && search(searchString, searchMode, qCodes)}
						/>
					)}
				/>
			</DialogContent>
		</Dialog>
	);
};
