import { Typography } from '@material-ui/core';
import React from 'react';

export const PageBreadcrumbDivider = () => {
	return (
		<Typography
			variant='body2'
			style={{ marginLeft: 2, marginRight: 2, fontWeight: 500 }}
			color='textPrimary'>
			{`/`}
		</Typography>
	);
};
