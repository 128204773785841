import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { HeaderTableCellX } from 'framework/table/HeaderTableCellX';
import { NoContentTableHeadX } from 'framework/table/NoContentTableHeadX';
import { SortableHeaderTableCellX } from 'framework/table/SortableHeaderTableCellX';
import { IPurchase, IQueryResult, ISortParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { PurchaseTableRow } from './PurchaseTableRow';

interface IProps {
	queryResult: IQueryResult<IPurchase>;
	sortParams: ISortParams<IPurchase>;
	onFilter: (sp: ISortParams<IPurchase>) => void;
}

export const PurchasesTable = ({ queryResult, sortParams, onFilter }: IProps) => {
	const strings = useLocalization();

	return (
		<Table stickyHeader>
			{queryResult.unfilteredCount === 0 && <NoContentTableHeadX>{strings.noPurchasesRegisteredYet}</NoContentTableHeadX>}
			{queryResult.unfilteredCount > 0 && (
				<TableHead>
					<TableRow>
						<SortableHeaderTableCellX<IPurchase>
							isFirst
							forProperty='reference'
							sortParams={sortParams}
							onFilter={onFilter}>
							{strings.reference}
						</SortableHeaderTableCellX>
						<SortableHeaderTableCellX<IPurchase>
							forProperty='purchaseDate'
							sortParams={sortParams}
							onFilter={onFilter}>
							{strings.purchaseDate}
						</SortableHeaderTableCellX>
						<HeaderTableCellX>{strings.supplier}</HeaderTableCellX>
						<SortableHeaderTableCellX<IPurchase>
							forProperty='priceExVat'
							onFilter={onFilter}
							sortParams={sortParams}
							align='right'>
							{`${strings.totalAmount} (${strings.exVatShort})`}
						</SortableHeaderTableCellX>
						<HeaderTableCellX isLast></HeaderTableCellX>
					</TableRow>
				</TableHead>
			)}
			<TableBody>
				{queryResult.values.map(t => (
					<PurchaseTableRow
						key={t.id}
						item={t}
					/>
				))}
			</TableBody>
		</Table>
	);
};
