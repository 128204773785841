import { CustomerAccountExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createExportRecord = (strings: IStrings): Record<CustomerAccountExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		OutstandingBalance: strings.outstandingBalance,
		HasOutstandingBalance: 'Heeft openstaand saldo?',
		Identifier: 'Wie?',
		AccountType: strings.type,
	};
};
