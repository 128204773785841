import { IconButton, MenuItem, Select } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import React, { useContext } from 'react';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { createHeader } from 'framework/table/createHeader';
import { DragAndDropTable } from 'framework/table/DragAndDropTable';
import { updateArray } from 'framework/utils/array/updateArray';
import { CosiDegreeOfChanges, CosiEndResults, ICosiLineModel } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { ViewContext } from '../context/ViewContext';

interface IProps {
	ordered: ICosiLineModel[];
	setOrdered: (val: ICosiLineModel[]) => void;
	mustEvaluate: boolean;
}

export const DragAndDropCosiNeedsTable = ({ ordered, setOrdered, mustEvaluate }: IProps) => {
	const strings = useLocalization();
	const { cosiDegreeOfChangeRecord, cosiEndResultRecord } = useContext(ViewContext);
	const priorityHeader = createHeader<ICosiLineModel>(strings.priority, (t, index) => `${index + 1}`, 'left');
	const descriptionHeader = createHeader<ICosiLineModel>(strings.need, t => t.description, 'left');

	const degreeOfChangeHeader = createHeader<ICosiLineModel>(
		strings.degreeOfChange,
		t => (
			<Select
				style={{ width: '130px', textAlign: 'left' }}
				value={t.degreeOfChange}
				onChange={e => setOrdered(updateArray(t, ordered, { ...t, degreeOfChange: e.target.value as string }))}>
				{CosiDegreeOfChanges.map(x => (
					<MenuItem
						key={x}
						value={x}>
						{cosiDegreeOfChangeRecord[x]}
					</MenuItem>
				))}
			</Select>
		),
		'center'
	);
	const endResultHeader = createHeader<ICosiLineModel>(
		strings.endResult,
		t => (
			<Select
				style={{ width: '130px', textAlign: 'left' }}
				value={t.endResult}
				onChange={e => setOrdered(updateArray(t, ordered, { ...t, endResult: e.target.value as string }))}>
				{CosiEndResults.map(x => (
					<MenuItem
						key={x}
						value={x}>
						{cosiEndResultRecord[x]}
					</MenuItem>
				))}
			</Select>
		),
		'center'
	);
	const actionsHeader = createEmptyHeader<ICosiLineModel>(
		'actions',
		t => (
			<IconButton
				size='small'
				edge='end'
				onClick={() => setOrdered(ordered.filter(x => x !== t))}>
				<DeleteOutlineIcon />
			</IconButton>
		),
		'right'
	);

	const headersWithEvaluation = [priorityHeader, descriptionHeader, degreeOfChangeHeader, endResultHeader, actionsHeader];
	const headersWithoutEvalution = [priorityHeader, descriptionHeader, actionsHeader];

	return (
		<DragAndDropTable<ICosiLineModel>
			ordered={ordered}
			setOrdered={setOrdered}
			headers={mustEvaluate ? headersWithEvaluation : headersWithoutEvalution}
			getKey={t => t.description ?? ''}
			withPaperContainer
		/>
	);
};
