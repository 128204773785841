import { Card, CardContent, useTheme } from '@material-ui/core';
import { DefaultInventoryItemsQueryParams } from 'app/main/inventoryManagement/inventoryItems/context/DefaultInventoryItemsQueryParams';
import { QueryContext } from 'app/main/inventoryManagement/inventoryItems/context/QueryContext';
import * as routes from 'app/main/inventoryManagement/routes';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { isLast } from 'framework/utils/array/isLast';
import { DashboardNotificationType, IDashboardNotification } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SingleNotification } from './SingleNotification';

interface IProps {
	notifications: IDashboardNotification[];
	className?: string;
	style?: React.CSSProperties;
}

export const NotificationsCard = ({ notifications, className, style }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { push } = useHistory();
	const { setParams } = useContext(QueryContext);

	const onNavigate = (type: DashboardNotificationType) => {
		if (type === 'InventoryItemsSoldNotPurchased') {
			// set filters
			setParams({ ...DefaultInventoryItemsQueryParams, isSold: true, isPurchased: false });
			push(routes.InventoryItemsRoute);
		} else if (type === 'InventoryItemsConsignmentExpired') {
			setParams({ ...DefaultInventoryItemsQueryParams, isConsignmentExpired: true });
			push(routes.InventoryItemsRoute);
		} else if (type === 'InventoryItemsConsignmentExpiresWithinAWeek') {
			setParams({ ...DefaultInventoryItemsQueryParams, consignmentExpiresWithinAWeek: true });
			push(routes.InventoryItemsRoute);
		}
	};

	return (
		<Card
			className={className}
			style={style}>
			<CardTitleTypography
				title={strings.notifications}
				withPadding
			/>
			<CardContent style={{ paddingTop: 0, paddingBottom: theme.spacing(2) }}>
				{notifications.length === 0 && <NoRecordsFound>{strings.noWhat(strings.notifications)}</NoRecordsFound>}
				{notifications.map(notification => (
					<React.Fragment key={notification.type}>
						<SingleNotification
							notification={notification}
							onClick={() => onNavigate(notification.type as DashboardNotificationType)}
						/>
						{isLast(notification, notifications) === false && <DividerWithMargin />}
					</React.Fragment>
				))}
			</CardContent>
		</Card>
	);
};
