import { RRule } from 'rrule';

export const formatRrule = (rrule: RRule): string => {
	const x = RRule.optionsToString({
		freq: rrule.options.freq,
		byweekday: rrule.options.byweekday,
		until: rrule.options.until,
		count: rrule.options.count,
		interval: rrule.options.interval,
	});
	return x.replace('RRULE:', '');
};
