import { Button, Checkbox, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { calcSalesDetailRoute } from 'app/main/financial/routes';
import { TableCardX } from 'framework/components/cards/TableCardX';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarXWithMode } from 'framework/components/search/SearchbarXWithMode';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { RecordContext } from 'shared/records/RecordContext';
import { SaleForm } from '../forms/SaleForm';
import { SalesExportButtonMenuAndDialogX } from './SalesExportButtonMenuAndDialogX';
import { SalesListFilterCard } from './SalesListFilterCard';
import { SalesListOrderBySelect } from './SalesListOrderBySelect';
import { SalesListPageHeader } from './SalesListPageHeader';
import { SalesListQueryContext } from './SalesListQueryContext';
import { SalesTable } from './SalesTable';

export const SalesListPage = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(SalesListQueryContext);
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();
	const { salesSearchModeRecord } = React.useContext(RecordContext);
	const [expandedId, setExpandedId] = useAccordionState<string>();
	const [expandAll, toggleExpandAll] = useStateToggleBoolean(false);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<SaleForm
				confirm={id => {
					if (id) {
						push(calcSalesDetailRoute(id));
					}
					confirm();
				}}
				cancel={cancel}
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='Sales'
			maxWidth={1280}
			stickyHeader={
				<SalesListPageHeader
					style={{ paddingBottom: 16 }}
					queryResult={queryResult}
					params={params}
					setParams={setParams}
					reload={reload}
				/>
			}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<div className='df-col gap-8'>
						<Button
							variant='contained'
							color='primary'
							startIcon={<AddIcon />}
							onClick={onNew}
							style={{ borderRadius: 12 }}>
							{strings.registerNewSale}
						</Button>
					</div>
					<SalesListFilterCard
						params={params}
						setParams={setParams}
						metadata={queryResult.metadata}
					/>
					<div className='fg1'></div>
					<Divider />
					<SalesExportButtonMenuAndDialogX
						params={params}
						queryResult={queryResult}
					/>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				<SearchbarXWithMode
					placeholder={strings.searchVerb}
					value={params.searchString}
					mode={params.searchMode}
					modeRecord={salesSearchModeRecord}
					onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
					onSetMode={mode => setParams(onResetToPage1({ ...params, searchMode: mode }))}
				/>
				<TableCardX
					header={
						<div className='df-col-ae'>
							<Button
								size='small'
								endIcon={
									<Checkbox
										checked={expandAll}
										style={{ padding: 0 }}
									/>
								}
								style={{ marginRight: -2 }}
								onClick={toggleExpandAll}>
								{strings.showAllContents}
							</Button>
							<SalesListOrderBySelect
								sortParams={params.sortParams}
								onFilter={sp => setParams(onResetToPage1({ ...params, sortParams: sp }))}
							/>
						</div>
					}
					table={
						<SalesTable
							queryResult={queryResult}
							sortParams={params.sortParams}
							onFilter={sp => setParams(onResetToPage1({ ...params, sortParams: sp }))}
							expandAll={expandAll}
							expandedId={expandedId}
							toggle={setExpandedId}
						/>
					}
					footer={
						<QueryResultPaginationX
							queryResult={queryResult}
							setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
							flexEnd
						/>
					}
				/>
			</div>
		</RestrictedPageX>
	);
};
