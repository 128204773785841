import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { applicationSettingsCommand_noahDeviceReadOutMode, applicationSettingsQuery_noahDeviceReadOutMode, NoahDeviceReadOutMode } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
}

// TODO translate
export const NoahDeviceReadOutModeForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [mode] = useApiEffect(applicationSettingsQuery_noahDeviceReadOutMode);
	const [option, setOption] = useState<NoahDeviceReadOutMode>('NeverCreateAnInventoryItem');
	const [update, isSubmitting] = useFormSubmit(applicationSettingsCommand_noahDeviceReadOutMode);

	useEffect(() => {
		if (mode) {
			setOption(mode as NoahDeviceReadOutMode);
		}
	}, [mode]);

	const handleSubmit = async () => {
		const r = await update({ mode: option });
		if (r.isSuccess) {
			confirm();
		}
	};

	return (
		<Dialog
			{...rest}
			scroll='paper'>
			<DialogTitle>Instellingen: Toestellen uitlezen van Noah</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<NoahDeviceReadOutMode>
					selected={option}
					setSelected={setOption}
					options={[
						{ value: 'NeverCreateAnInventoryItem', label: `${strings.neverImport} (${strings.recommended})`, caption: strings.NoahNeverImportCaption },
						{ value: 'OnlyCreateAnInventoryItemIfNewNoahAction', label: strings.importIfNewerAction, caption: strings.NoahImportIfNewerActionCaption },
						{ value: 'AlwaysCreateAnInventoryItem', label: strings.neverImport, caption: strings.NoahImportAlwaysCaption },
					]}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				submitText='Bevestig keuze'
				cancel={cancel}
				isSubmitting={isSubmitting}
				onSubmit={handleSubmit}
			/>
		</Dialog>
	);
};
