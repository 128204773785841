import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import { IRepair, IRepairLine } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: IRepairLine;
	repair: IRepair;
	style?: React.CSSProperties;
}

export const RepairLineStatusIcon = ({ item, repair, style }: IProps) => {
	if (item.isRedelivered) {
		return <CheckCircleOutlineIcon style={style} />;
	} else if (repair.isSentToSupplier) {
		return <RadioButtonUncheckedIcon style={style} />;
	} else {
		return <TimerOutlinedIcon style={style} />;
	}
};
