import { IPatientDetail } from 'gen/ApiClient';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { PatientDetailDocumentsCard } from './documents/PatientDetailDocumentsCard';
import { NoahOverviewTab } from './noah/NoahOverviewTab';
import { PatientDetailOverviewTab } from './overview/PatientDetailOverviewTab';
import { PatientDetailAnnotationsAndFollowUpTab } from './PatientDetailAnnotationsAndFollowUpTab';
import { PatientDetailAppointmentsTab } from './appointments/PatientDetailAppointmentsTab';
import { PatientDetailTabType } from './PatientDetailTabType';
import { PatientDetailPersonalDataTab } from './personalData/PatientDetailPersonalDataTab';
import { SalesByPatientTab } from './sales/SalesByPatientTab';
import { TendersByPatientTab } from './tenders/TendersByPatientTab';
import { WorkflowsByPatientTab } from './workflows/WorkflowsByPatientTab';

interface IProps extends IReloadTriggerProps {
	tab: PatientDetailTabType;
	item: IPatientDetail;
	reload: VoidFunction;
}

export const PatientDetailPageTabContentSwitch = ({ tab, item, reload, reloadTrigger }: IProps) => {
	return (
		<>
			{tab === 'overview' && (
				<PatientDetailOverviewTab
					item={item}
					reload={reload}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{tab === 'personalData' && (
				<PatientDetailPersonalDataTab
					item={item}
					reload={reload}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{tab === 'noahData' && (
				<NoahOverviewTab
					data={item.patient}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{tab === 'annotationsAndFollowUp' && (
				<PatientDetailAnnotationsAndFollowUpTab
					patientId={item.patient.id}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{tab === 'appointments' && (
				<PatientDetailAppointmentsTab
					patient={item.patient}
					reload={reload}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{tab === 'documents' && (
				<PatientDetailDocumentsCard
					patient={item.patient}
					reload={reload}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{tab === 'sales' && (
				<SalesByPatientTab
					item={item}
					reload={reload}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{tab === 'workflows' && (
				<WorkflowsByPatientTab
					item={item}
					reload={reload}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{tab === 'tenders' && (
				<TendersByPatientTab
					item={item}
					reload={reload}
					reloadTrigger={reloadTrigger}
				/>
			)}
		</>
	);
};
