import { FormHelperText } from '@material-ui/core';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { theme } from '../../../theme';
import { ErrorTextWithIcon } from './ErrorTextWithIcon';
import { FormHelperTextX } from './FormHelperTextX';

export interface IMeta {
	error?: string | undefined;
	touched: boolean;
	initialError?: string | undefined;
}

interface IProps {
	meta: IMeta;
	withDate?: boolean;
	helperText?: JSX.Element | string;
}

export const FormErrorHelper = ({ meta, withDate = false, helperText }: IProps) => {
	const strings = useLocalization();
	return (
		<>
			{(meta.error && meta.touched) || meta.initialError ? (
				<FormHelperText
					style={{ marginBottom: '6px', marginLeft: '4px', color: theme.palette.error.main }}
					component='div'>
					<ErrorTextWithIcon
						style={{ whiteSpace: 'pre' }}
						error={(meta.error?.indexOf('Invalid Date') ?? -1) > -1 && withDate ? strings.invalidDateFormat : meta.error}
					/>
				</FormHelperText>
			) : (
				<FormHelperTextX>{helperText}</FormHelperTextX>
			)}
		</>
	);
};
