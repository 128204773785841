import { useTheme } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { formatDate } from 'framework/utils/date/formatDate';
import { IInvoiceForwardingRecord } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { InvoiceForwardingRecordChip } from './InvoiceForwardingRecordChip';
import { InvoiceForwardingRecordListItemStatusIcon } from './InvoiceForwardingRecordListItemStatusIcon';

const MajorFontSize = 16;

interface IProps {
	item: IInvoiceForwardingRecord;
	isSelected: boolean;
	toggle: VoidFunction;
	style?: React.CSSProperties;
}

export const InvoiceForwardingRecordListItemCard = ({ item, isSelected, toggle, style }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const color = useMemo<ColorX>(() => (item.isForwarded ? 'success' : item.isIgnored ? 'default' : 'error'), [item]);
	const tColor = useThemeColor(color);
	const [hovered, enter, leave] = useStateBoolean(false);

	return (
		<CardX
			raised={isSelected || hovered}
			className='df-row w100'
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={toggle}
			style={{
				borderRight: isSelected ? `6px solid ${theme.palette.primary.main}` : 'unset',
				cursor: 'pointer',
				...style,
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<InvoiceForwardingRecordListItemStatusIcon
					item={item}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col gap-4 fg1'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.reference}</span>
				<InvoiceForwardingRecordChip
					item={item}
					color={color}
					style={{ marginRight: 'auto' }}
				/>
				<LabelledPropertyX label={strings.date}>{formatDate(item.created)}</LabelledPropertyX>
			</div>
		</CardX>
	);
};
