import { ILocalizedArticlePropertyKey } from 'app/main/inventoryManagement/productCatalog/articlePropertyKeys/context/ILocalizedArticlePropertyKey';
import { IArticleProperty, IArticlePropertyModel } from 'gen/ApiClient';
import React from 'react';
import { ArticlePropertyLabelledPropertyX } from './ArticlePropertyLabelledPropertyX';

interface IProps {
	aps: (IArticleProperty | IArticlePropertyModel)[];
	localizedKeys: ILocalizedArticlePropertyKey[];
	edit?: VoidFunction;
}

export const ArticlePropertiesLabelledPropertiesX = ({ aps, localizedKeys, edit }: IProps) => {
	return (
		<div className='df-col gap-6'>
			{aps.map(t => (
				<ArticlePropertyLabelledPropertyX
					key={t.key}
					item={t}
					localizedKeys={localizedKeys}
					edit={edit}
				/>
			))}
		</div>
	);
};
