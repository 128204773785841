import AndroidIcon from '@material-ui/icons/Android';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import React from 'react';
import { AppleIcon } from './AppleIcon';
import { Windows10Icon } from './Windows10Icon';

interface IProps {
	os: string;
	style?: React.CSSProperties;
}

export const OsIcon = ({ os, style }: IProps) => {
	if (os.toLowerCase().startsWith('windows')) {
		return <Windows10Icon style={style} />;
	} else if (os.toLowerCase().startsWith('android')) {
		return <AndroidIcon style={style} />;
	} else if (os.toLowerCase().startsWith('ios')) {
		return <AppleIcon style={style} />;
	} else if (os.toLowerCase().startsWith('mac')) {
		return <AppleIcon style={style} />;
	} else {
		return <DesktopWindowsIcon style={style} />;
	}
};
