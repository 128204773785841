import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { useAccordionState } from 'framework/utils/useAccordionState';
import {
	ValidUntilTimeUnits,
	purchaseSettingsCommand_patchDefaultValidUntil,
	purchaseSettingsCommand_patchReferenceSettings,
	purchasesQuery_settings,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReferenceSettingsForm } from 'shared/ReferenceSettingsForm';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PageReloadButton } from 'shared/layout/PageReloadButton';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { RecordContext } from 'shared/records/RecordContext';
import { TimeModelForm } from 'shared/timeUnit/TimeModelForm';
import { formatTimeModel } from 'shared/timeUnit/formatTimeModel';
import { calculateReference } from 'shared/utils/calculateReference';
import { FinancialsPageBreadcrumbTitle } from '../FinancialsPageBreadcrumbTitle';
import { PurchasesPageBreadcrumb } from './PurchasesPageBreadcrumb';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

type AccordionType = 'default-due-date' | 'reference-settings';

export const PurchasesSettingsPage = () => {
	const strings = useLocalization();
	const [trigger, reload] = useTrigger();
	const [settings, reloadSettings] = useApiEffect(purchasesQuery_settings);
	const [expandedAccordion, setExpandedAccordion] = useAccordionState<AccordionType>();
	const { goBack } = useHistory();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { timeUnitRecord } = React.useContext(RecordContext);

	useLazyEffect(() => {
		reloadSettings();
	}, [trigger]);

	if (settings === undefined) {
		return <div></div>;
	}

	const onPatchDefaultDueDate = () => {
		open(
			<TimeModelForm
				open
				title={strings.changeWhat(strings.defaultWhat(strings.validTo))}
				model={settings.defaultValidUntil}
				label={strings.validTo}
				defaultUnit='Weeks'
				confirm={confirm}
				submitFunction={model => purchaseSettingsCommand_patchDefaultValidUntil({ defaultValidUntil: model })}
				cancel={cancel}
				options={ValidUntilTimeUnits}
			/>
		);
	};

	const onReferenceSettings = () => {
		open(
			<ReferenceSettingsForm
				cancel={cancel}
				confirm={confirm}
				open
				value={settings.referenceSettings}
				title={strings.changeWhat(strings.numberingWhat(strings.purchases))}
				submitFunction={model => purchaseSettingsCommand_patchReferenceSettings(model)}
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='Purchases'
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<FinancialsPageBreadcrumbTitle />
						<PageBreadcrumbDivider />
						<PurchasesPageBreadcrumb />
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={strings.settings} />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<Button
								size='small'
								variant='outlined'
								startIcon={<CloseIcon />}
								onClick={() => goBack()}>
								{strings.closeThisWindow}
							</Button>
							<PageReloadButton reload={reload} />
						</PageXHeaderActions>
					</div>
				</div>
			}>
			<div
				className='df-col'
				style={{
					padding: 16,
					maxWidth: 1280,
				}}>
				<Accordion
					expanded={expandedAccordion === 'default-due-date'}
					onChange={() => setExpandedAccordion('default-due-date')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{strings.defaultDeliveryTime}</span>
					</AccordionSummary>
					<AccordionDetails>
						<LabelledPropertyX
							icon={<ScheduleIcon />}
							edit={onPatchDefaultDueDate}>
							{formatTimeModel(settings.defaultValidUntil, timeUnitRecord)}
						</LabelledPropertyX>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expandedAccordion === 'reference-settings'}
					onChange={() => setExpandedAccordion('reference-settings')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{strings.numbering}</span>
					</AccordionSummary>
					<AccordionDetails>
						<LabelledPropertyX
							icon={<FormatListNumberedIcon />}
							edit={onReferenceSettings}>
							{calculateReference(settings.referenceSettings)}
						</LabelledPropertyX>
					</AccordionDetails>
				</Accordion>
			</div>
		</RestrictedPageX>
	);
};
