import ShowChartIcon from '@material-ui/icons/ShowChart';
import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { InventoryManagementPageBreadcrumb } from '../InventoryManagementPageBreadcrumb';
import { StockEntriesRoute } from '../routes';

export const StockEntriesBreadcrumbs = () => {
	const strings = useLocalization();

	return (
		<React.Fragment>
			<InventoryManagementPageBreadcrumb />
			<PageBreadcrumbDivider />
			<PageBreadcrumb
				text={strings.evolution}
				route={StockEntriesRoute}
				icon={<ShowChartIcon />}
			/>
		</React.Fragment>
	);
};
