import { calcInventoryItemDetailRoute, calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { formatDate } from 'framework/utils/date/formatDate';
import { IRepair, IRepairLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { RepairLineStatusIcon } from './RepairLineStatusIcon';

const MajorFontSize = 16;

interface IProps {
	line: IRepairLine;
	repair: IRepair;
	withoutBorder?: boolean;
}

export const RepairLineListItem = ({ line, repair, withoutBorder }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const color = useMemo<ColorX>(() => (line.isRedelivered ? 'success' : repair.hasWarning ? 'warning' : 'default'), [line, repair]);
	const tColor = useThemeColor(color);

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<RepairLineStatusIcon
					repair={repair}
					item={line}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col fg1'>
				<div className='df-row-ac'>
					<TextLinkButton
						onClick={() => {
							if (line.inventoryItemId) {
								push(calcInventoryItemDetailRoute(line.inventoryItemId));
							} else {
								push(calcProductCatalogDetailRoute(line.productCatalogItemId));
							}
						}}
						style={{ fontSize: MajorFontSize }}>
						{`${line.quantity} x ${line.identifier}`}
					</TextLinkButton>
					<div className='fg1'></div>
				</div>
				{line.isRedelivered === true && (
					<>
						<ChipX
							className='w-fc'
							label={strings.repaired}
							color={color}
						/>
						<LabelledPropertyX label={strings.redeliveryDate}>{formatDate(line.redeliveryDate)}</LabelledPropertyX>
					</>
				)}
				{line.isRedelivered === false && (
					<ChipX
						className='w-fc'
						label={strings.notYetRepaired}
						color={color}
					/>
				)}
				{line.isReplaced === true && <VariantLabelledPropertyX variant='error'>{`${strings.replacedBy}: ${line.newSerialNumber}`}</VariantLabelledPropertyX>}
			</div>
		</div>
	);
};
