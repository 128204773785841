import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { locationsQuery_all } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { LocationDetailContent } from './LocationDetailContent';
import { LocationListItem } from './LocationListItem';
import { LocationForm } from './forms/LocationForm';

interface IProps extends IReloadTriggerProps {}

export const LocationsContent = ({ reloadTrigger }: IProps) => {
	const strings = useLocalization();
	const [all, reload] = useApiEffect(locationsQuery_all);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [selectedId, setSelectedId] = useAccordionState<string>();
	const expandedLocation = useMemo(() => (all ?? []).find(t => t.id === selectedId), [all, selectedId]);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	const onNew = () => {
		open(
			<LocationForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	if (all === undefined) {
		return <></>;
	}

	return (
		<div
			className='df-row'
			style={{ gap: 24 }}>
			<div className='df-col'>
				<TextLinkButton
					startIcon={<AddCircleOutlineOutlinedIcon />}
					onClick={onNew}
					color='primary'>
					{strings.newLocation}
				</TextLinkButton>
				<DividerWithMargin />
				{all.map(t => (
					<LocationListItem
						key={t.id}
						item={t}
						isSelected={expandedLocation?.id === t.id}
						toggle={() => setSelectedId(t.id)}
					/>
				))}
			</div>
			{expandedLocation !== undefined && (
				<LocationDetailContent
					item={expandedLocation}
					reload={reload}
				/>
			)}
		</div>
	);
};
