import { DialogProps } from '@material-ui/core';
import { useWrapApiCallWithLoad } from 'framework/hooks/useWrapApiCallWithLoad';
import { IObjectWithIdAndIdentifier, patientsQuery_singleIdAndIdentifier } from 'gen/ApiClient';
import React from 'react';
import { SelectPatientByIdForm } from './SelectPatientByIdForm';

interface IProps extends DialogProps {
	confirm: (val: IObjectWithIdAndIdentifier<string>) => void;
	cancel: VoidFunction;
	previous?: VoidFunction;
	isSubmitting?: boolean;
}

export const SelectPatientAndLoadSearchForm = ({ confirm, previous, ...rest }: IProps) => {
	const [loadF, isLoading] = useWrapApiCallWithLoad(patientsQuery_singleIdAndIdentifier);

	const onSelect = async (id: string) => {
		const r = await loadF(id);
		if (r.isSuccess) {
			confirm(r.result);
		}
	};

	return (
		<SelectPatientByIdForm
			{...rest}
			confirm={onSelect}
			isSubmitting={rest.isSubmitting || isLoading}
		/>
	);
};
