import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { applicationSettingsQuery_logoutMode } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RecordContext } from 'shared/records/RecordContext';
import { PatchLogoutModeForm } from './PatchLogoutModeForm';

interface IProps extends IReloadTriggerProps {}

export const HamsAdminSettingsContent = ({ reloadTrigger }: IProps) => {
	const [data, reload] = useApiEffect(applicationSettingsQuery_logoutMode);
	const { logoutModeRecord } = useContext(RecordContext);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	if (data === undefined) {
		return <div></div>;
	}

	const onPatchLogoutMode = () => {
		open(
			<PatchLogoutModeForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<div className='df-col gap-6'>
			<Typography16pxBold>{`HAMS admin settings`}</Typography16pxBold>
			<CaptionVerticalLabelledPropertyX
				label={`Logout mode`}
				edit={onPatchLogoutMode}>
				{logoutModeRecord[data]}
			</CaptionVerticalLabelledPropertyX>
		</div>
	);
};
