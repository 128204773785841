import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { PatientsRoute } from 'routes';

export const PatientsBreadcrumb = () => {
	const strings = useLocalization();

	return (
		<PageBreadcrumb
			text={strings.patients}
			route={PatientsRoute}
			icon={<GroupOutlinedIcon />}
		/>
	);
};
