import { DocumentDetailDialog } from 'documents/DocumentDetailDialog';
import { IDocumentSummary } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DocumentListItemX } from './DocumentListItemX';

interface IProps {
	item: IDocumentSummary;
	withoutBorder?: boolean;
	reload: VoidFunction;
}

export const DocumentListItemXDefault = ({ item, reload, ...rest }: IProps) => {
	const { open, confirm } = useDialogsContext(reload);

	const onViewDetails = () => {
		open(
			<DocumentDetailDialog
				open
				id={item.id}
				close={confirm}
			/>
		);
	};

	return (
		<DocumentListItemX
			{...rest}
			item={item}
			reload={reload}
			onViewDetails={onViewDetails}
		/>
	);
};
