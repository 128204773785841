import { Collapse, Divider, TableRow, useTheme } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { calcInventoryItemDetailRoute, calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { ExpandLessOrMoreIcon } from 'framework/components/ExpandLessOrMoreIcon';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { TableCellX } from 'framework/table/TableCellX';
import { TableDetailsRowX } from 'framework/table/TableDetailsRowX';
import { formatDate } from 'framework/utils/date/formatDate';
import { ArticlesInquiryStatusType, IArticlesInquiry } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { calculateArticlesInquiryDetailRoute } from '../../ProcessesRoutes';
import { ArticlesInquiryStatusChip } from '../ArticlesInquiryStatusChip';
import { ArticlesInquiryStatusTypeToColorXRecord } from '../ArticlesInquiryStatusToColorXRecord';
import { ArticlesInquiryListItemStatusIcon } from './ArticlesInquiryListItemStatusIcon';

const MinorFontSize = 13;

interface IProps {
	item: IArticlesInquiry;
	isExpanded: boolean;
	toggle: VoidFunction;
}

export const ArticlesInquiryTableRow = ({ item, isExpanded, toggle }: IProps) => {
	const strings = useLocalization();
	const color = useMemo(() => ArticlesInquiryStatusTypeToColorXRecord[item.status as ArticlesInquiryStatusType], [item]);
	const tColor = useThemeColor(color);
	const theme = useTheme();
	const { push } = useHistory();
	const [isHovered, enter, leave] = useStateBoolean(false);

	const onViewDetails = () => push(calculateArticlesInquiryDetailRoute(item.id));

	return (
		<React.Fragment>
			<TableRow
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<TableCellX>
					<div className='df-row gap-4'>
						<ArticlesInquiryListItemStatusIcon
							item={item}
							style={{ fontSize: 22, color: tColor }}
						/>
						<div className='df-col gap-2'>
							<TextLinkButton
								underline
								style={{ fontSize: 14 }}
								onClick={onViewDetails}>
								{item.reference}
							</TextLinkButton>
							<ArticlesInquiryStatusChip
								item={item}
								style={{ marginRight: 'auto' }}
							/>
						</div>
					</div>
				</TableCellX>
				<TableCellX>
					<span>{formatDate(item.created)}</span>
				</TableCellX>
				<TableCellX>
					<TextLinkButton
						style={{ fontSize: MinorFontSize }}
						underline
						onClick={() => push(calcPatientDetailRoute(item.patientId))}>
						{item.patientIdentifier}
					</TextLinkButton>
				</TableCellX>
				<TableCellX>
					<TextLinkButton style={{ fontSize: MinorFontSize }}>{item.location?.identifier ?? strings.notApplicableAbbreviation}</TextLinkButton>
				</TableCellX>
				<TableCellX align='right'>
					<div className='df-row-ac jc-e'>
						<span style={{ fontSize: MinorFontSize, fontWeight: 'unset' }}>
							{`# ${item.countItems.toString()} ${
								item.countItems === 1 || item.countItems === -1 ? strings.article.toLowerCase() : strings.articles.toLowerCase()
							}`}
						</span>
						<ColorOnHoverIconButton
							edge='end'
							size='small'
							color='primary'
							onClick={toggle}>
							<ExpandLessOrMoreIcon isExpanded={isExpanded} />
						</ColorOnHoverIconButton>
						<ColorOnHoverIconButton
							size='small'
							edge='end'
							color='primary'
							onClick={onViewDetails}>
							<VisibilityOutlinedIcon />
						</ColorOnHoverIconButton>
					</div>
				</TableCellX>
			</TableRow>
			<TableDetailsRowX
				colSpan={4}
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<Collapse in={isExpanded}>
					<div
						className='df-col'
						style={{ paddingBottom: 12 }}>
						<Divider
							variant='inset'
							style={{ marginTop: 8, marginBottom: 4 }}
						/>
						{item.lines.map(line => (
							<div
								className='df-row-ab'
								key={line.id}
								style={{ fontSize: MinorFontSize, gap: 4 }}>
								<span>{`${line.quantity.toString()} x`}</span>
								<TextLinkButton
									underline
									style={{ fontSize: MinorFontSize }}
									onClick={() => {
										if (line.inventoryItemId) {
											push(calcInventoryItemDetailRoute(line.inventoryItemId));
										} else {
											push(calcProductCatalogDetailRoute(line.productCatalogItemId));
										}
									}}>
									{line.description}
								</TextLinkButton>
							</div>
						))}
					</div>
				</Collapse>
			</TableDetailsRowX>
		</React.Fragment>
	);
};
