import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import LinkOffOutlinedIcon from '@material-ui/icons/LinkOffOutlined';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import React from 'react';
import { PurchaseUblLinkStatus } from './UblPurchaseLineLinkStatus';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	linkStatus: PurchaseUblLinkStatus;
	onIgnore: VoidFunction;
	onRemoveLink: VoidFunction;
	onUndoIgnore: VoidFunction;
	onLink: VoidFunction;
	style?: React.CSSProperties;
}

export const PurchaseUblLinkPopoverActions = ({ linkStatus, onIgnore, onRemoveLink, onUndoIgnore, onLink, style }: IProps) => {
	const strings = useLocalization();
	return (
		<div
			className='df-col gap-4'
			style={style}>
			{linkStatus === 'not-linked' && (
				<TextLinkButton
					onClick={() => {
						onLink();
					}}
					startIcon={<LinkOutlinedIcon />}>
					{strings.link}
				</TextLinkButton>
			)}
			{linkStatus === 'not-linked' && (
				<TextLinkButton
					onClick={() => {
						onIgnore();
					}}
					startIcon={<CancelOutlinedIcon />}>
					{strings.ignoreVerb}
				</TextLinkButton>
			)}
			{linkStatus === 'linked' && (
				<TextLinkButton
					onClick={() => {
						onRemoveLink();
					}}
					startIcon={<LinkOffOutlinedIcon />}>
					{strings.removeLink}
				</TextLinkButton>
			)}
			{linkStatus === 'ignored' && (
				<TextLinkButton
					onClick={() => {
						onUndoIgnore();
					}}
					startIcon={<UndoOutlinedIcon />}>
					{strings.undoIgnore}
				</TextLinkButton>
			)}
		</div>
	);
};
