import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { InventoryManagementPageBreadcrumb } from '../InventoryManagementPageBreadcrumb';
import { StockTransactionTabIconRecord } from './StockTransactionTabIconRecord';
import { StockTransactionTabType, StockTransactionTabTypes } from './StockTransactionTabType';
import { DeliverySettingsDialog } from './deliveries/settings/DeliverySettingsDialog';
import { ReturnSettingsDialog } from './returns/settings/ReturnSettingsDialog';
import { StockMovementSettingsDialog } from './stockMovements/settings/StockMovementSettingsDialog';

interface IProps {
	reload: VoidFunction;
	tab: StockTransactionTabType;
	setTab: (tab: StockTransactionTabType) => void;
}

export const StockTransactionsPageHeader = ({ reload, tab, setTab }: IProps) => {
	const strings = useLocalization();
	const { stockTransactionTabTitleRecord } = useContext(RecordContext);
	const { open, confirm } = useDialogsContext(reload);

	const onSettings = () => {
		if (tab === 'deliveries') {
			open(
				<DeliverySettingsDialog
					open
					close={confirm}
				/>
			);
		} else if (tab === 'returns') {
			open(
				<ReturnSettingsDialog
					open
					close={confirm}
				/>
			);
		} else if (tab === 'stock-movements') {
			open(
				<StockMovementSettingsDialog
					open
					close={confirm}
				/>
			);
		}
	};

	return (
		<div className='df-col fg1'>
			<div className='df-row-ac'>
				<InventoryManagementPageBreadcrumb />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={strings.stockTransactions} />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={stockTransactionTabTitleRecord[tab]} icon={StockTransactionTabIconRecord[tab]} />
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageSettingsButtonX onClick={onSettings} />
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<HorizontalTabsX
				tabs={StockTransactionTabTypes}
				selectedTab={tab}
				setSelectedTab={setTab}
				startIconRecord={StockTransactionTabIconRecord}
				titleRecord={stockTransactionTabTitleRecord}
				style={{
					marginTop: 16,
					marginLeft: -4,
				}}
			/>
		</div>
	);
};
