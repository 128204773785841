import { CardProps, Divider } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { IOption } from 'framework/IOption';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ITendersQueryMetadata, ITendersQueryParams, TenderProcessStatuses } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { TendersListPrefilter } from './TendersListPrefilter';

interface IProps extends CardProps {
	params: ITendersQueryParams;
	setParams: (params: ITendersQueryParams) => void;
	metadata: ITendersQueryMetadata;
}

const TendersParamsExclusions: (keyof ITendersQueryParams)[] = ['alsoIncludeDeleted'];

export const TendersListFilterCard = ({ params, setParams, metadata, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const { tenderProcessStatusRecord } = useContext(RecordContext);
	const isFilteredX = useMemo(() => isFiltered(params, TendersParamsExclusions), [params]);
	const onFilter = (pars: ITendersQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params, TendersParamsExclusions)));

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<FormControlLabelSwitch
				isChecked={params.alsoIncludeDeleted}
				toggle={() => setParams({ ...params, alsoIncludeDeleted: !params.alsoIncludeDeleted, pageParams: { index: 1, size: params.pageParams.size } })}
				label={strings.alsoShowDeleted}
			/>
			<Divider />
			<TendersListPrefilter
				selectedPrefilter={params.prefilter}
				setSelectedPrefilter={key => onFilter({ ...params, prefilter: key })}
				metadata={metadata}
			/>
			<Divider />
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<ListFilterComponent<string>
					options={TenderProcessStatuses.map<IOption<string>>(t => ({ id: t, identifier: tenderProcessStatusRecord[t] }))}
					selected={params.statuses}
					setSelected={vals => onFilter({ ...params, statuses: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.status}
				/>
				<DateFilterItem
					label={strings.dateCreated}
					after={params.createdAfter}
					before={params.createdBefore}
					setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
				/>
				<DateFilterItem
					label={strings.validTo}
					after={params.validUntilAfter}
					before={params.validUntilBefore}
					setSelected={(after, before) => onFilter({ ...params, validUntilAfter: after, validUntilBefore: before })}
				/>
			</div>
		</CardX>
	);
};
