import { ArticlePropertyKeysCacheContext } from 'app/main/inventoryManagement/productCatalog/articlePropertyKeys/context/ArticlePropertyKeysCacheContext';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IArticlePropertyAutocomplete, IArticlePropertyKey } from 'gen/ApiClient';
import { ArticlePropertiesAutocompleteCacheContext } from '../../context/ArticlePropertiesAutocompleteCacheContext';

export const useArticlePropertyKeysAndAutocomplete = (): [IArticlePropertyKey[], IArticlePropertyAutocomplete[]] => {
	const [apks] = usePreloadCacheContext(ArticlePropertyKeysCacheContext);
	const [apacs] = usePreloadCacheContext(ArticlePropertiesAutocompleteCacheContext);

	return [apks, apacs];
};
