import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { TabX } from 'framework/components/tabs/TabX';
import { TabsX } from 'framework/components/tabs/TabsX';
import { ExactOnlineGLAccountMatchMode, IExactOnlineSettingsDto, IExactOnlineStatusDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { IntegrationsPageBreadcrumb } from '../IntegrationsPageBreadcrumb';
import { ExactOnlineIntegrationTabType } from './ExactOnlineIntegrationTabType';
import { ExactOnlineLogo } from './ExactOnlineLogo';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

interface IProps {
	reload: VoidFunction;
	tab: ExactOnlineIntegrationTabType;
	setTab: React.Dispatch<React.SetStateAction<ExactOnlineIntegrationTabType>>;
	status: IExactOnlineStatusDto;
	settings: IExactOnlineSettingsDto;
	showOnlySettings: boolean;
}

export const ExactOnlineIntegrationPageHeader = ({ reload, tab, setTab, status, settings, showOnlySettings }: IProps) => {
	const strings = useLocalization();
	const isUseProductCatalog = useMemo(() => settings.glAccountMatchMode === ('UseProductCatalog' as ExactOnlineGLAccountMatchMode), [settings]);

	return (
		<div className='df-col fg1 gap-4'>
			<div className='df-row-ac'>
				<IntegrationsPageBreadcrumb />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={`Exact Online`} />
				{status.hasNotification && (
					<StatusIcon
						status='error'
						style={{ marginLeft: 4 }}
					/>
				)}
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<ExactOnlineLogo width={100} />
			<TabsX
				style={{
					marginLeft: -4,
				}}>
				{showOnlySettings === false && (
					<>
						<TabX
							isSelected={tab === 'sales'}
							onClick={() => setTab('sales')}
							startIcon={<AttachMoneyOutlinedIcon />}
							endIcon={
								status.countUnprocessedSales > 0 && (
									<BadgeChipX
										value={status.countUnprocessedSales}
										color='error'
									/>
								)
							}
							value={strings.sales}
						/>
						<TabX
							isSelected={tab === 'customer-accounts'}
							onClick={() => setTab('customer-accounts')}
							startIcon={<AccountCircleOutlinedIcon />}
							endIcon={
								status.countUnprocessedCustomerAccounts > 0 && (
									<BadgeChipX
										value={status.countUnprocessedCustomerAccounts}
										color='error'
									/>
								)
							}
							value={strings.accountsReceivable}
						/>
						{isUseProductCatalog && (
							<TabX
								isSelected={tab === 'product-catalog'}
								onClick={() => setTab('product-catalog')}
								startIcon={<AccountCircleOutlinedIcon />}
								endIcon={
									status.countUnlinkedProductCatalogItems > 0 && (
										<BadgeChipX
											value={status.countUnlinkedProductCatalogItems}
											color='error'
										/>
									)
								}
								value={strings.productCatalog}
							/>
						)}
					</>
				)}
				<TabX
					isSelected={tab === 'settings'}
					onClick={() => setTab('settings')}
					startIcon={<SettingsOutlinedIcon />}
					value={strings.settings}
				/>
			</TabsX>
		</div>
	);
};
