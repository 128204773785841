import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { formatDate } from 'framework/utils/date/formatDate';
import { IInventoryItem, inventoryItemsCommand_clearManuallySetWarrantyEndDate, inventoryItemsCommand_patchManuallySetWarrantyEndDate } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { useRenderTimeModel } from 'shared/timeUnit/useRenderTimeModel';

interface IProps {
	item: IInventoryItem;
	reload: VoidFunction;
}

export const DefaultWarranty = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const renderTimeModel = useRenderTimeModel();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onClearManualWarrantyEndDate = () => {
		open(
			<AlertDialog
				open
				title={strings.deleteManualAdjustment}
				content={strings.doYouWantToRemoveManualWarrantyEndDateQuestion}
				acceptText={strings.yes}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => inventoryItemsCommand_clearManuallySetWarrantyEndDate({ inventoryItemId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onPatchManualWarrantyEndDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				formTitle={strings.changeWhat(strings.manuallySetWarrantyEndDate)}
				label={strings.manuallySetWarrantyEndDate}
				initialValue={item.manualWarrantyEndDate}
				submitFunction={date => inventoryItemsCommand_patchManuallySetWarrantyEndDate({ inventoryItemId: item.id, warrantyEndDate: date })}
			/>
		);
	};

	return (
		<div className='df-col gap-6'>
			{item.isSold && item.dateSold && item.productCatalogData.defaultWarranty && (
				<>
					<DividerWithCaption caption={strings.defaultWarranty} />
					<CaptionVerticalLabelledPropertyX label={strings.salesDate}>{formatDate(item.dateSold)}</CaptionVerticalLabelledPropertyX>
					<CaptionVerticalLabelledPropertyX
						label={strings.term}
						style={{ textDecoration: item.hasOtherThanDefaultWarrantyEndDate ? 'line-through' : 'inherit' }}>
						{renderTimeModel(item.productCatalogData.defaultWarranty)}
					</CaptionVerticalLabelledPropertyX>
					<CaptionVerticalLabelledPropertyX
						label={strings.endDate}
						style={{ textDecoration: item.hasOtherThanDefaultWarrantyEndDate ? 'line-through' : 'inherit' }}>
						{formatDate(item.defaultWarrantyEndDate)}
					</CaptionVerticalLabelledPropertyX>
					{item.hasOtherThanDefaultWarrantyEndDate && item.hasManualEndWarrantyDate === false && (
						<CaptionVerticalLabelledPropertyX
							label={strings.endDate}
							startIcon={<StatusIcon status='warning' />}>
							{formatDate(item.warrantyEndDate)}
						</CaptionVerticalLabelledPropertyX>
					)}
				</>
			)}
			{item.isSold === false && item.productCatalogData.defaultWarranty && (
				<>
					<DividerWithCaption caption={strings.defaultWarranty} />
					<CaptionVerticalLabelledPropertyX label={strings.term}>{renderTimeModel(item.productCatalogData.defaultWarranty)}</CaptionVerticalLabelledPropertyX>
				</>
			)}
			{item.hasManualEndWarrantyDate && (
				<>
					<CaptionVerticalLabelledPropertyX
						label={strings.manuallySetWarrantyEndDate}
						startIcon={<StatusIcon status='warning' />}
						edit={onPatchManualWarrantyEndDate}>
						{formatDate(item.warrantyEndDate)}
					</CaptionVerticalLabelledPropertyX>
					<TextLinkButton
						startIcon={<EventBusyIcon />}
						onClick={onClearManualWarrantyEndDate}>
						{strings.deleteManualAdjustment}
					</TextLinkButton>
				</>
			)}
			{item.hasManualEndWarrantyDate === false && (
				<TextLinkButton
					startIcon={<EventAvailableIcon />}
					onClick={onPatchManualWarrantyEndDate}>
					{strings.setManualWarrantyEndDate}
				</TextLinkButton>
			)}
		</div>
	);
};
