import { AppBar, Dialog, DialogProps, IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { RichEdit } from 'devexpress-richedit';
import React, { useState } from 'react';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { SlideUpTransition } from 'framework/components/transitions/SlideUpTransition';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Try } from 'framework/Try';
import { documentsCommand_save, IDocumentContent, IValidateResponse } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { Rte } from './Rte';
import { exportToBase64 } from './utils/exportToBase64';

interface IProps extends DialogProps {
	document: IDocumentContent | undefined;
	close: VoidFunction;
	isReadOnly?: boolean;
	hasChanges: boolean;
	setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DocumentEditorDialog = ({ document, close, hasChanges, setHasChanges, isReadOnly = false, ...rest }: IProps) => {
	const strings = useLocalization();
	const [richEdit, setRichEdit] = useState<RichEdit>();
	const { open, confirm } = useDialogsContext(close);
	const [save, isSubmitting] = useFormSubmit(documentsCommand_save);

	const onClose = () => {
		if (hasChanges === false) {
			close();
		} else if (document) {
			onAlert(document);
		}
	};

	const onSave = async (document: IDocumentContent): Promise<Try<IValidateResponse>> => {
		const content = await exportToBase64(richEdit ?? (undefined as any));
		const r = await save({ documentId: document.id, contentAsBase64: content });
		if (r.isSuccess) {
			setHasChanges(false);
		}
		return r;
	};

	const onAlert = (document: IDocumentContent) => {
		open(
			<AlertDialog
				open
				title={strings.unsavedChanges}
				content={strings.saveChangesToDocumentQuestion}
				acceptText={strings.yesCommaSave}
				rejectText={strings.noCommaCloseWithoutSaving}
				reject={confirm}
				acceptF={() => onSave(document)}
				accept={confirm}
			/>
		);
	};

	return (
		<Dialog
			fullScreen
			TransitionComponent={SlideUpTransition}
			{...rest}>
			{rest.open && document && (
				<>
					<AppBar style={{ position: 'relative' }}>
						<Toolbar className='df-row-ac jc-sb'>
							<Typography>{strings.viewEditDocument}</Typography>
							<div className='df-row-ac'>
								<LoaderButton
									color='inherit'
									startIcon={<SaveIcon />}
									isLoading={isSubmitting}
									onClick={() => onSave(document)}
									disabled={hasChanges === false}>
									{strings.save}
								</LoaderButton>
								<IconButton
									edge='end'
									color='inherit'
									onClick={onClose}
									aria-label='close'>
									<CloseIcon />
								</IconButton>
							</div>
						</Toolbar>
					</AppBar>
					<div className='df-row fg1'>
						<Rte
							data={document.content}
							richEdit={richEdit}
							setRichEdit={setRichEdit}
							setHasChanges={setHasChanges}
							isReadOnly={isReadOnly}
						/>
					</div>
				</>
			)}
		</Dialog>
	);
};
