import { IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import React, { useContext } from 'react';
import { LoaderContext } from 'framework/context/LoaderContext';
import { DialogsContext } from 'shared/dialogs/DialogsContext';
import { NotificationsIconButtonAndMenu } from './NotificationsIconButtonAndMenu';
import { UserButtonAndMenu } from './UserButtonAndMenu';
import { SupportButtonAndMenu } from './SupportButtonAndMenu';

interface IProps {
	toggleDrawer: VoidFunction;
	isDrawerOpen: boolean;
}

export const AppBarContent = ({ toggleDrawer, isDrawerOpen }: IProps) => {
	const { stackCount } = useContext(DialogsContext);
	const { count: loadCount } = useContext(LoaderContext);

	return (
		<div>
			<Toolbar>
				<IconButton
					edge='start'
					color='inherit'
					aria-label='open drawer'
					onClick={toggleDrawer}>
					{isDrawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
				</IconButton>
				{process.env.NODE_ENV === `development` && (
					<>
						<div style={{ marginRight: 8 }}>Stack count: {stackCount}</div>
						<div>Load count: {loadCount}</div>
					</>
				)}
				<div className='fg1' />
				<NotificationsIconButtonAndMenu />
				<SupportButtonAndMenu />
				<UserButtonAndMenu />
			</Toolbar>
		</div>
	);
};
