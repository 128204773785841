import { Card, CardContent, CardProps } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { isLast } from 'framework/utils/array/isLast';
import { IDelivery } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { DeliveryLineListItem } from './DeliveryLineListItem';

interface IProps extends CardProps {
	item: IDelivery;
}

export const DeliveryDetailContentsCard = ({ item, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<Card {...rest}>
			<CardContent>
				<CardTitleTypography
					title={strings.contents}
					style={{ marginBottom: 8 }}
				/>
				{item.lines.map(t => (
					<DeliveryLineListItem
						line={t}
						key={t.id}
						withoutBorder={isLast(t, item.lines)}
					/>
				))}
				{item.lines.length === 0 && <em>{strings.noLinesDefinedYet}</em>}
			</CardContent>
		</Card>
	);
};
