import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import { calcSupplierAccountDetailRoute } from 'app/main/financial/routes';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { createType } from 'framework/utils/createType';
import { ISupplier, ISupplierAccountsFilterParams, SupplierExportProp, SupplierExportProps, suppliersQuery_exportAccounts } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsNavigateHeader } from 'shared/table/createDetailsNavigateHeader';
import { SupplierAccountsFilter } from './SupplierAccountsFilter';
import { SelectableColumn } from './SelectableColumn';
import { SupplierAccountStatusChip } from './SupplierAccountStatusChip';
import { SupplierAccountsPageBreadcrumbs } from './SupplierAccountsPageBreadcrumbs';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';

const QuickFilters = createType('all', 'withOutstandingBalance');
export type QuickFilter = typeof QuickFilters[number];

export const SupplierAccountsListPage = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(QueryContext);
	const strings = useLocalization();
	const theme = useTheme();
	const { columns, setColumns, columnsRecord, headers, quickFilter, setQuickFilter, quickFilterRecord, exportRecord } = useContext(ViewContext);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='SupplierAccounts'
			stickyHeader={
				<div
					className='df-row-ac'
					style={{ paddingBottom: 16 }}>
					<SupplierAccountsPageBreadcrumbs />
					<div className='fg1'></div>
					<PageXHeaderActions>
						<PageReloadButtonX reload={reload} />
					</PageXHeaderActions>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16 }}>
				<div className='df-row-ac jc-sb'>
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchVerb}
						style={{ width: '400px' }}
						variant='paper'
					/>
				</div>
				{queryResult.unfilteredCount > 0 && (
					<>
						<Card>
							<div
								className='df-row-ac jc-sb'
								style={{ padding: theme.spacing(1) }}>
								<ButtonGroupWithSelection<QuickFilter>
									options={QuickFilters}
									selected={quickFilter}
									setSelected={setQuickFilter}
									renderOption={t => quickFilterRecord[t]}
								/>
								<div>
									<ExportButtonMenuAndDialog<ISupplierAccountsFilterParams, SupplierExportProp>
										params={params}
										filteredCount={queryResult.filteredCount}
										unfilteredCount={queryResult.unfilteredCount}
										allProps={SupplierExportProps}
										downloadF={suppliersQuery_exportAccounts}
										fileName='Leveranciersrekeningen'
										record={exportRecord}
										view='supplierAccounts'
									/>
									<SelectColumnsButtonAndDialog
										selectedColumns={columns}
										setSelectedColumns={setColumns}
										record={columnsRecord}
										view='supplierAccounts'
									/>
								</div>
							</div>
							<Divider />
							<div className='df-row jc-sb'>
								<SupplierAccountsFilter
									params={params}
									onFilter={params => setParams(onResetToPage1(params))}
									paddingLeft={theme.spacing(1.5)}
								/>
							</div>
							<Divider />
							<CardMedia>
								<PageableTableWithColumnSelection<ISupplier, SelectableColumn>
									queryResult={queryResult}
									headers={headers}
									sortParams={params.sortParams}
									pageParams={params.pageParams}
									setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
									getKey={inst => inst.id}
									selectedColumns={columns}
									prependColumn={createEmptyHeader<ISupplier>('prepend', t => (
										<SupplierAccountStatusChip item={t} />
									))}
									appendColumn={createDetailsNavigateHeader<ISupplier>(calcSupplierAccountDetailRoute)}
								/>
							</CardMedia>
						</Card>
					</>
				)}
				{queryResult.unfilteredCount === 0 && <NoRecordsFound>{strings.noSupplierWithPurchasesRegisteredYet}</NoRecordsFound>}
			</div>
		</RestrictedPageX>
	);
};
