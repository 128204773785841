import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		created: strings.createdAt,
		amountPaid: strings.amount,
		paymentDate: strings.paymentDate,
		paymentMethodIdentifier: strings.method,
		reference: strings.referenceShort,
		comment: strings.remark,
	};
};
