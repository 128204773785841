import { DialogProps } from '@material-ui/core';
import React from 'react';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { rfiaTrialsCommand_endAdministrative } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useNow } from 'shared/utils/useNow';

interface IProps extends DialogProps {
	rfiaId: string;
	loanId: string;
	loanAdministrativeEndDate: Date | undefined;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const EndTrialAdministrativelyForm = ({ rfiaId, loanId, loanAdministrativeEndDate, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const now = useNow();

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<DateModelForm
			{...rest}
			confirm={confirm}
			cancel={cancel}
			label={strings.date}
			formTitle={strings.trialAdministrativeEndDate}
			initialValue={loanAdministrativeEndDate ?? now}
			submitFunction={date => rfiaTrialsCommand_endAdministrative({ rfiaId: rfiaId, trialId: loanId, date: date })}
		/>
	);
};
