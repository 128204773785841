import { IRegisterDeliveryLineRequest } from 'gen/ApiClient';
import { IRegisterDeliveryLineModel } from './IRegisterDeliveryLineModel';

export const mapToRegisterDeliveryLineRequest = (line: IRegisterDeliveryLineModel, index: number): IRegisterDeliveryLineRequest => {
	return {
		description: line.description,
		productCatalogId: line.productCatalogItemId,
		quantity: line.quantity,
		serialNumber: line.serialNumber,
		zeroBasedIndex: index,
		tailorMadeForPatientId: line.tailorMadeForPatientId,
		autoGenerateSerialNumber: line.autoGenerateSerialNumber,
	};
};
