import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IQueryResult, ISupplier, ISuppliersQueryParams, suppliersQuery_concurrencyToken, suppliersQuery_query } from 'gen/ApiClient';
import { suppliersQueryParamsLsKey } from '../../../localStorageKeys';
import { SuppliersQueryContext } from './SuppliersQueryContext';

const DefaultParams: ISuppliersQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	brands: [],
	createdBefore: undefined,
	createdAfter: undefined,
	hasPurchases: undefined,
	hasOrders: undefined,
	hasDeliveries: undefined,
	showDeleted: false,
};

export const SuppliersQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<ISupplier>, ISuppliersQueryParams>
			defaultParams={DefaultParams}
			queryF={suppliersQuery_query}
			concurrencyF={suppliersQuery_concurrencyToken}
			localStorageKey={suppliersQueryParamsLsKey}
			context={SuppliersQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
