import { DialogProps } from '@material-ui/core';
import React, { useContext } from 'react';
import { EidApplicationContext } from 'app/externalApps/eid/EidApplicationContext';
import { DownloadAndInstallSoftwareDialog } from './DownloadAndInstallSoftwareDialog';

interface IProps extends DialogProps {
	close: VoidFunction;
}

export const DownloadAndInstallEidSoftwareDialog = ({ ...rest }: IProps) => {
	const { download, isDownloading } = useContext(EidApplicationContext);
	return (
		<DownloadAndInstallSoftwareDialog
			{...rest}
			download={download}
			isDownloading={isDownloading}
			title={`Download en installeer eID software`}
		/>
	);
};
