import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useContext, useMemo } from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormIntegerField } from 'framework/forms/FormIntegerField';
import { FormSelectFieldFromRecord } from 'framework/forms/FormSelectFieldFromRecord';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { CommissionRuleMethod, commissionRulesCommand_new, commissionRulesCommand_update, ICommissionRule, ICommissionRuleModel } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';

const createSchema = (strings: IStrings) => {
	return yup.object<ICommissionRuleModel>({
		name: yup.string().required(),
		method: yup.string().required(),
		percentage: yup.number().required(),
	});
};

const emptyValues: ICommissionRuleModel = {
	name: '',
	method: '',
	percentage: 0,
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	rule?: ICommissionRule;
}

export const CommissionRuleForm = ({ confirm, cancel, rule, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [create, isCreateSubmitting] = useFormSubmit(commissionRulesCommand_new);
	const [update, isUpdateSubmitting] = useFormSubmit(commissionRulesCommand_update);
	const isSubmitting = useMemo(() => isCreateSubmitting || isUpdateSubmitting, [isCreateSubmitting, isUpdateSubmitting]);
	const strings = useLocalization();
	const { commissionRuleMethodRecord } = useContext(RecordContext);

	const handleSubmit = async (values: ICommissionRuleModel, helpers: FormikHelpers<ICommissionRuleModel>) => {
		const r = rule ? await update(rule.id, values) : await create(values);
		if (handleFormResponse(r, helpers)) {
			notify(rule ? strings.changedWhat(strings.rule) : strings.addedWhat(strings.rule));
			confirm();
		}
	};

	return (
		<Formik<ICommissionRuleModel>
			validateOnMount
			initialValues={{ ...emptyValues, ...rule }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					fullWidth
					maxWidth='xs'>
					<DialogTitle>{rule ? strings.changeWhat(strings.rule) : strings.addWhat(strings.rule)}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<ICommissionRuleModel>
							name='name'
							label={strings.name}
						/>
						<FormIntegerField<ICommissionRuleModel>
							name='percentage'
							label={strings.percentage}
						/>
						<FormSelectFieldFromRecord<ICommissionRuleModel, CommissionRuleMethod>
							name='method'
							label={strings.method}
							record={commissionRuleMethodRecord}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={rule ? strings.update : strings.create}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
