import { IconButton, IconButtonProps } from '@material-ui/core';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import React from 'react';

export const ColorOnHoverIconButton = (props: IconButtonProps) => {
	const [isHovered, enter, leave] = useStateBoolean(false);

	return (
		<IconButton
			{...props}
			onMouseEnter={enter}
			onMouseLeave={leave}
			color={isHovered ? props.color : 'default'}
		/>
	);
};
