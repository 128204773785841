import React from 'react';
import { calendarEventTypesCommand_updateColor, ICalendarEventType } from 'gen/ApiClient';
import { ColorPickerWrapper as SharedColorPickerWrapper } from 'shared/color/ColorPickerWrapper';

interface IProps {
	eventType: ICalendarEventType;
	reload: VoidFunction;
}

export const EventTypesColorPickerWrapper = ({ eventType, reload }: IProps) => {
	return (
		<SharedColorPickerWrapper
			initialColor={eventType.color}
			submitF={color => calendarEventTypesCommand_updateColor(eventType.id, color)}
			reload={reload}
		/>
	);
};
