import {
	Checkbox,
	Dialog,
	DialogContent,
	DialogProps,
	DialogTitle,
	Divider,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import { LoaderIconButton } from 'framework/components/buttons/LoaderIconButton';
import { DragAndDropList } from 'framework/components/dnd/DragAndDropList';
import { StarFavoriteIcon } from 'framework/components/icons/StarFavoriteIcon';
import { SelectOnIdField } from 'framework/components/select/SelectOnIdField';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useApiEffectX } from 'framework/hooks/useApiEffectX';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IColumnSelection, viewColumnSelectionsCommand_delete, viewColumnSelectionsCommand_makeDefault, viewColumnSelectionsQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDialogsContext } from '../dialogs/useDialogsContext';
import { ColumnSelectionForm } from './ColumnSelectionForm';
import { ViewColumnSelectionType } from './ViewColumnSelectionType';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';

interface IProps<TProp extends string> extends DialogProps {
	record: Record<TProp, string>;
	submit: (props: TProp[]) => void;
	cancel: VoidFunction;
	selected: TProp[];
	columns?: TProp[];
	exclusions: TProp[];
	view: ViewColumnSelectionType;
}

export const SelectColumnsDialog = <TProp extends string>({ submit, cancel, record, selected, columns, exclusions, view, ...rest }: IProps<TProp>) => {
	const allProps: TProp[] = useMemo(
		() => (columns ? columns : Object.keys(record).filter(t => exclusions.indexOf(t as TProp) === -1)),
		[record, columns, exclusions]
	) as any;
	const strings = useLocalization();
	const [ordered, setOrdered] = useState<TProp[]>(allProps);
	const [selectedProps, setSelectedProps] = useState<TProp[]>(selected);
	const [viewStored, reload] = useApiEffectX(viewColumnSelectionsQuery_single, view);
	const columnSelections = useMemo(() => viewStored?.columnSelections ?? [], [viewStored]);
	const [selectedColumnSelection, setSelectedColumnSelection] = useState<IColumnSelection>();
	const { open, confirm, cancel: cancelSave, isStacked } = useDialogsContext(reload);
	const [makeDefault, isMakingDefault] = useFormSubmit(viewColumnSelectionsCommand_makeDefault);

	useEffect(() => {
		setOrdered([...selected, ..._.orderBy(allProps, t => record[t]).filter(t => selected.indexOf(t) === -1)]);
	}, [selected, allProps, record]);

	useEffect(() => {
		setSelectedProps(selected);
	}, [selected]);

	const onSubmit = () => {
		submit(ordered.filter(t => selectedProps.indexOf(t) > -1));
	};

	const onSave = () => {
		open(
			<ColumnSelectionForm
				view={view}
				columns={ordered.filter(t => selectedProps.indexOf(t) > -1)}
				confirm={onConfirm}
				cancel={cancelSave}
				selected={selectedColumnSelection}
			/>
		);
	};

	const onConfirm = async (id: string) => {
		confirm();
		await onReloadAndSelect(id);
	};

	const onReloadAndSelect = async (id: string) => {
		const r = await reload();
		if (r.isSuccess) {
			setSelectedColumnSelection(r.result.columnSelections.find(t => t.id === id));
		}
	};

	const onMakeDefault = async (val: IColumnSelection) => {
		const r = await makeDefault(view, val.id);
		if (r.isSuccess) {
			await onReloadAndSelect(val.id);
		}
	};

	const onDelete = (val: IColumnSelection) => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.savedColumnSelection)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => viewColumnSelectionsCommand_delete(view, val.id)}
				accept={() => {
					setSelectedColumnSelection(undefined);
					confirm();
				}}
			/>
		);
	};

	const onSetSelectedColumnSelection = (val: IColumnSelection | undefined) => {
		setSelectedColumnSelection(val);
		if (val) {
			const selected = val.columns.filter(t => allProps.indexOf(t as any) > -1) as any;
			setOrdered([...selected, ..._.orderBy(allProps, t => record[t]).filter(t => selected.indexOf(t) === -1)]);
			setSelectedProps(selected);
		}
	};

	return (
		<Dialog
			{...rest}
			scroll='paper'
			fullWidth
			open={rest.open && isStacked === false}>
			<DialogTitle>
				<div>{strings.selectColumns}</div>
				<Typography variant='body2'>{strings.selectColumnsToShowBelow}</Typography>
				<DividerWithMargin />
				<div className='df-row-ac jc-sb'>
					<SelectOnIdField<IColumnSelection>
						value={selectedColumnSelection}
						onChange={onSetSelectedColumnSelection}
						options={columnSelections}
						label={strings.savedSelection}
						className='fg1'
						style={{ marginRight: 8 }}
						withNone
						renderValue={t => (
							<div className='df-row-ac'>
								{t.useAsDefault && (
									<StarFavoriteIcon
										style={{ marginRight: 8, fontSize: 16 }}
										isFavorite
									/>
								)}
								<div>{t.name}</div>
							</div>
						)}
					/>
					<div className='df-row-ac'>
						<TooltipWithSpan title={strings.saveThisSelection}>
							<IconButton
								onClick={onSave}
								edge='end'>
								<SaveOutlinedIcon />
							</IconButton>
						</TooltipWithSpan>
						<TooltipWithSpan title={strings.makeDefault}>
							<LoaderIconButton
								edge='end'
								isLoading={isMakingDefault}
								disabled={selectedColumnSelection === undefined || selectedColumnSelection.useAsDefault === true}
								onClick={() => (selectedColumnSelection ? onMakeDefault(selectedColumnSelection) : undefined)}>
								<StarBorderOutlinedIcon />
							</LoaderIconButton>
						</TooltipWithSpan>
						<TooltipWithSpan title={strings.delete}>
							<IconButton
								edge='end'
								disabled={selectedColumnSelection === undefined}
								onClick={() => (selectedColumnSelection ? onDelete(selectedColumnSelection) : undefined)}>
								<DeleteOutlinedIcon />
							</IconButton>
						</TooltipWithSpan>
					</div>
				</div>
			</DialogTitle>
			<DialogContent
				dividers
				style={{ padding: '0px' }}>
				<ListItem
					dense
					button
					onClick={() => (selectedProps.length === allProps.length ? setSelectedProps([]) : setSelectedProps(allProps))}>
					<ListItemIcon>
						<Checkbox
							indeterminate={selectedProps.length > 0 && selectedProps.length < allProps.length}
							checked={selectedProps.length === allProps.length}
						/>
					</ListItemIcon>
					<ListItemText primary={selectedProps.length === allProps.length ? strings.selectNone : strings.selectAll} />
				</ListItem>
				<Divider variant='middle' />
				<DragAndDropList<TProp>
					ordered={ordered}
					setOrdered={setOrdered}
					selected={selectedProps}
					setSelected={setSelectedProps}
					getKey={t => t}
					getValue={t => record[t]}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				onSubmit={onSubmit}
				submitText={strings.select}
				cancelText={strings.close}
				isSubmitting={false}
				variantSubmitButton='outlined'
			/>
		</Dialog>
	);
};
