import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatDateX } from 'framework/utils/date/formatDateX';
import { IAnnotation } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import React from 'react';

interface IProps {
	item: IAnnotation;
	style?: React.CSSProperties;
}

export const AnnotationDate = ({ item, style }: IProps) => {
	const strings = useLocalization();
	const popupState = usePopupState({
		variant: 'popover',
		popupId: 'demoPopover',
	});

	return (
		<>
			<div
				style={{ fontWeight: 500, ...style }}
				{...bindHover(popupState)}>
				{formatDate(item.created)}
			</div>
			<HoverPopover
				elevation={1}
				{...bindPopover(popupState)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}>
				<div
					className='df-col'
					style={{ padding: 8 }}>
					<LabelledPropertyX label={strings.created}>{formatDateX(item.created, 'dd/MM/yyyy HH:mm')}</LabelledPropertyX>
					<LabelledPropertyX label={strings.lastUpdated}>{formatDateX(item.lastUpdated, 'dd/MM/yyyy HH:mm')}</LabelledPropertyX>
				</div>
			</HoverPopover>
		</>
	);
};
