import { useTheme } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { calcInventoryItemDetailRoute, calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { IReturnLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	line: IReturnLine;
	withoutBorder?: boolean;
}

export const ReturnLineListItem = ({ line, withoutBorder }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { push } = useHistory();

	return (
		<div
			className='df-row gap-8 w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<RadioButtonCheckedIcon style={{ color: theme.palette.success.main }} />
			<div className='df-col fg1'>
				<div className='df-row-ac'>
					<TextLinkButton
						onClick={() => {
							if (line.inventoryItemId) {
								push(calcInventoryItemDetailRoute(line.inventoryItemId));
							} else {
								push(calcProductCatalogDetailRoute(line.productCatalogItemId));
							}
						}}
						style={{ fontSize: MajorFontSize }}>
						{`${line.quantity} x ${line.description}`}
					</TextLinkButton>
				</div>
				<span style={{ fontSize: MinorFontSize }}>{`# ${line.quantity} ${strings.delivered.toLowerCase()}`}</span>
			</div>
		</div>
	);
};
