import React from 'react';
import { IFormProps } from './common/IFormProps';
import { FormSelectField } from './FormSelectField';

interface IProps<TModel> extends IFormProps<TModel> {
	trueText: string;
	falseText: string;
}

export const FormSelectBooleanField = <TModel extends unknown>({ trueText, falseText, ...props }: IProps<TModel>) => {
	return (
		<FormSelectField<TModel, boolean>
			{...props}
			options={[true, false]}
			renderValue={t => (t ? trueText : falseText)}
			getKey={t => t.toString()}
		/>
	);
};
