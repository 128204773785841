import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPaymentMethodModel, paymentMethodsCommand_new } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPaymentMethodModel>({
			name: yup.string().required(),
			canUseForCashSale: yup.bool().defined(),
			canUseForPurchase: yup.bool().defined(),
			canUseForSale: yup.bool().defined(),
		})
		.defined();
};

const emptyValues: IPaymentMethodModel = {
	name: '',
	canUseForCashSale: true,
	canUseForPurchase: true,
	canUseForSale: true,
};

const stepsRecord: Record<number, (keyof IPaymentMethodModel)[]> = {
	0: ['name'],
	1: ['canUseForSale'],
	2: ['canUseForCashSale'],
	3: ['canUseForPurchase'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const PaymentMethodForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(paymentMethodsCommand_new);
	const [step, setStep] = useState<number>(0);

	const handleSubmit = async (values: IPaymentMethodModel, helpers: FormikHelpers<IPaymentMethodModel>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.addedWhat(strings.paymentMethod));
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<IPaymentMethodModel>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
}

const InnerDialog = ({ step, setStep, cancel, isSubmitting, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const props = useFormikContext<IPaymentMethodModel>();

	return (
		<Dialog {...rest}>
			<DialogTitleWithFormStepper
				title={strings.newWhat(strings.paymentMethod)}
				step={step}
				labels={[strings.name, strings.cashSale]}
			/>
			<DialogContent
				className='df-col'
				dividers>
				{step === 0 && (
					<>
						<FormTextField<IPaymentMethodModel>
							name='name'
							label={strings.name}
						/>
					</>
				)}
				{step === 1 && (
					<FormExtendedRadioGroupField<IPaymentMethodModel, boolean>
						name='canUseForSale'
						label={strings.canBeUsedForWhat(strings.sales)}
						options={[
							{ value: true, label: strings.availableForWhat(strings.sales), caption: '' },
							{ value: false, label: strings.notAvailableForWhat(strings.sales), caption: '' },
						]}
					/>
				)}
				{step === 2 && (
					<FormExtendedRadioGroupField<IPaymentMethodModel, boolean>
						name='canUseForCashSale'
						label={strings.canBeUsedForWhat(strings.cashSales)}
						disabled={props.values.canUseForSale === false}
						options={[
							{ value: true, label: strings.availableForWhat(strings.cashSales), caption: '' },
							{ value: false, label: strings.notAvailableForWhat(strings.cashSales), caption: '' },
						]}
					/>
				)}
				{step === 3 && (
					<FormExtendedRadioGroupField<IPaymentMethodModel, boolean>
						name='canUseForPurchase'
						label={strings.canBeUsedForWhat(strings.purchases)}
						options={[
							{ value: true, label: strings.availableForWhat(strings.purchases), caption: '' },
							{ value: false, label: strings.notAvailableForWhat(strings.purchases), caption: '' },
						]}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
