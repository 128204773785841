import { Card, Divider, List } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DefaultCardContent } from 'framework/components/DefaultCardContent';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { isLast } from 'framework/utils/array/isLast';
import { IAssuranceLine, IInventoryItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AssuranceLineDialog } from './AssuranceLineDialog';
import { AssuranceLineListItem } from './AssuranceLineListItem';
import { DefaultWarranty } from './DefaultWarranty';

interface IProps {
	item: IInventoryItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const AssurancesInfoCard = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, cancel } = useDialogsContext();

	const onViewDetail = (t: IAssuranceLine, type: 'sale' | 'purchase') => {
		open(
			<AssuranceLineDialog
				open
				close={cancel}
				item={t}
				iiItem={item}
				type={type}
			/>
		);
	};

	return (
		<Card
			style={style}
			className='df-col'>
			<DefaultCardContent>
				<CardTitleTypography
					title={strings.insurancedAndProlongedBetweenBracketsWarranties}
					style={{ marginBottom: 8 }}
				/>
				<List
					dense
					disablePadding>
					{item.soldAssurances.map(t => (
						<React.Fragment key={t.id}>
							<AssuranceLineListItem
								item={t}
								type='sale'
								onViewDetail={() => onViewDetail(t, 'sale')}
							/>
							{(isLast(t, item.soldAssurances) === false || item.hasPurchasedAssurances) && <Divider />}
						</React.Fragment>
					))}
				</List>
				<List
					dense
					disablePadding>
					{item.purchasedAssurances.map(t => (
						<React.Fragment key={t.id}>
							<AssuranceLineListItem
								item={t}
								type='purchase'
								onViewDetail={() => onViewDetail(t, 'purchase')}
							/>
							{isLast(t, item.purchasedAssurances) === false && <Divider />}
						</React.Fragment>
					))}
				</List>
				{item.soldAssurances.length === 0 && item.purchasedAssurances.length === 0 && (
					<NoRecordsFound style={{ paddingTop: 8 }}>{strings.noWhatDefined(strings.insurancesAndProlongedWarranties)}</NoRecordsFound>
				)}
				<DefaultWarranty
					item={item}
					reload={reload}
				/>
			</DefaultCardContent>
		</Card>
	);
};
