import { Dialog, DialogContent, DialogProps, DialogTitle, Divider, TextField } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { FormControlLabelCheckbox } from 'framework/components/FormControlLabelCheckbox';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { FormIntegerField } from 'framework/forms/FormIntegerField';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { Try } from 'framework/Try';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { IReferenceSettings, IReferenceSettingsModel, IUpdateResponse } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { calculateReference } from './utils/calculateReference';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IReferenceSettingsModel>({
			countLeadingZeros: yup.number().required(),
			prefix: yup.string(),
			separator: yup.string(),
			withYear: yup.bool(),
			isShortYearFormat: yup.bool(),
		})
		.defined();
};

interface IProps extends DialogProps {
	title: string;
	value: IReferenceSettings;
	confirm: VoidFunction;
	cancel: VoidFunction;
	submitFunction: (model: IReferenceSettingsModel) => Promise<Try<IUpdateResponse<IReferenceSettingsModel>>>;
	prefixHelperText?: string;
}

export const ReferenceSettingsForm = ({ value, title, confirm, cancel, submitFunction, prefixHelperText, ...rest }: IProps) => {
	const strings = useLocalization();
	const [update, isSubmitting] = useFormSubmit(submitFunction);

	const handleSubmit = async (values: IReferenceSettingsModel, helpers: FormikHelpers<IReferenceSettingsModel>) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	return (
		<Formik<IReferenceSettingsModel>
			validateOnMount
			initialValues={value}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					title={title}
					cancel={cancel}
					isSubmitting={isSubmitting}
					value={value}
					prefixHelperText={prefixHelperText}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	title: string;
	value: IReferenceSettings;
	prefixHelperText?: string;
}

const InnerDialog = ({ cancel, isSubmitting, title, value, prefixHelperText, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const props = useFormikContext<IReferenceSettingsModel>();
	const [usePrefix, toggleSetUsePrefix] = useStateToggleBoolean(isNullOrUndefined(value.prefix) === false);

	useEffect(() => {
		if (usePrefix === false) {
			setFormValue<IReferenceSettingsModel>('prefix', '', props);
		}
		// eslint-disable-next-line
	}, [usePrefix]);

	return (
		<Dialog
			fullWidth
			maxWidth='sm'
			{...rest}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<FormControlLabelCheckbox
					isChecked={usePrefix}
					toggle={toggleSetUsePrefix}
					label={strings.useAPrefix}
				/>
				<FormTextField<IReferenceSettingsModel>
					name='prefix'
					label={strings.prefix}
					disabled={usePrefix === false}
					helperText={prefixHelperText}
				/>
				<Divider style={{ marginTop: 16, marginBottom: 16 }} />
				<FormExtendedRadioGroupField<IReferenceSettingsModel, boolean>
					name='withYear'
					label={strings.useYear}
					options={[
						{ value: true, label: strings.withWhat(strings.year), caption: strings.yearComesAfterPrefixAndBeforeNumber },
						{ value: false, label: strings.withoutWhat(strings.year), caption: strings.referenceMustNotContainAYear },
					]}
				/>
				{props.values.withYear === true && (
					<>
						<Divider style={{ marginTop: 16, marginBottom: 16 }} />
						<FormExtendedRadioGroupField<IReferenceSettingsModel, boolean>
							name='isShortYearFormat'
							label=''
							options={[
								{ value: false, label: 'Volledig jaartal', caption: `Volledige jaartal` },
								{ value: true, label: 'Korte jaarnotatie', caption: `Enkel laatste 2 cijfers van het jaartal` },
							]}
						/>
					</>
				)}
				<Divider style={{ marginTop: 16, marginBottom: 16 }} />
				<FormTextField<IReferenceSettingsModel>
					name='separator'
					label={strings.separator}
					helperText={strings.separatorExamples}
				/>
				<FormIntegerField<IReferenceSettingsModel>
					name='countLeadingZeros'
					label={strings.padNumbers}
					helperText={strings.padNumbersExplanation}
				/>
				<DividerWithCaption caption={strings.example} />
				<TextField
					value={calculateReference(props.values)}
					disabled
					variant='outlined'
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions
				submitText={strings.change}
				isSubmitting={isSubmitting}
				cancel={cancel}
			/>
		</Dialog>
	);
};
