import { CardContent, IconButton, List, Step, StepContent, StepLabel, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { FixedWidthCard, IFixedWidthCardProps } from 'framework/components/FixedWidthCard';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { FileDocumentEditOutlineIcon } from 'framework/components/icons/FileDocumentEditOutlineIcon';
import { IRequestForInsuranceAllowance, RfiaInvoiceProcessStep } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ProcessButton } from 'shared/process/ProcessButton';
import { VerticalProcess } from 'shared/process/VerticalProcess';
import { RecordContext } from 'shared/records/RecordContext';
import { containsStep } from 'shared/utils/containsStep';
import { SalesDetailDialog } from '../../../financial/sales/components/SalesDetailDialog';
import { SalesSummaryListItem } from '../../../financial/sales/components/SalesSummaryListItem';
import { RfiaAdvanceInvoiceForm } from '../../../financial/sales/forms/advanceInvoice/RfiaAdvanceInvoiceForm';
import { TenderDetailDialog } from '../../../financial/tenders/components/TenderDetailDialog';
import { TenderListItem } from '../../../financial/tenders/components/TenderListItem';
import { RfiaTenderWrapperForm } from '../../../financial/tenders/forms/RfiaTenderWrapperForm';
import { RfiaInvoiceProcessStatusChip } from '../RfiaInvoiceProcessStatusChip';
import { InvoiceProcessStepsForm } from '../forms/InvoiceProcessStepsForm';
import { HandoverForm } from '../forms/handover/HandoverForm';

// type Step = 'Offerte opstellen' | 'Bestellen' | 'Leveren' | 'Facturatie' | 'Betalen';

const isTenderActive = (item: IRequestForInsuranceAllowance) =>
	item.isHandedOver === false && item.isCancelled === false && item.invoiceProcess.hasTenders === false;
const isTenderCompleted = (item: IRequestForInsuranceAllowance) => item.isHandedOver || item.invoiceProcess.hasTenders;
const isInvoiceCompleted = (item: IRequestForInsuranceAllowance) => item.isHandedOver;
const canCreateAdvanceInvoice = (item: IRequestForInsuranceAllowance) =>
	item.isHandedOver === false && item.isCancelled === false && item.invoiceProcess.hasAdvanceInvoices === false;
const canHandoverBeforeApprovals = (item: IRequestForInsuranceAllowance) =>
	item.isHandedOver === false && item.isCancelled === false && item.isFailedTrial === false;
const canInvoice = (item: IRequestForInsuranceAllowance) => item.isHandedOver === false && item.isCancelled === false;

interface IProps extends IFixedWidthCardProps {
	item: IRequestForInsuranceAllowance;
	isHifExist: boolean;
	reload: VoidFunction;
}

export const InvoiceProcessCard = ({ item, isHifExist, reload, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { rfiaInvoiceProcessStepRecord } = useContext(RecordContext);
	const containsAdvanceInvoiceOrHandoverBeforeApprovalStep = useMemo(
		() =>
			containsStep<RfiaInvoiceProcessStep>('AdvanceInvoice', item.invoiceProcess.steps) ||
			containsStep<RfiaInvoiceProcessStep>('HandoverBeforeApprovals', item.invoiceProcess.steps),
		[item]
	);

	const onCreateTender = () => {
		open(
			<RfiaTenderWrapperForm
				rfia={item}
				confirm={onTenderCreated}
				cancel={cancel}
			/>
		);
	};

	const onTenderCreated = (id: string) => {
		confirm();
		open(
			<TenderDetailDialog
				open
				id={id}
				close={confirm}
				withCreate
			/>
		);
	};

	const onCreateAdvanceInvoice = () => {
		open(
			<RfiaAdvanceInvoiceForm
				open
				rfia={item}
				confirm={onAdvanceInvoiceCreated}
				cancel={cancel}
			/>
		);
	};

	const onAdvanceInvoiceCreated = (id: string) => {
		confirm();
		open(
			<SalesDetailDialog
				open
				id={id}
				close={confirm}
				withCreate
			/>
		);
	};

	const onHandoverBeforeApproval = () => {
		open(
			<HandoverForm
				confirm={confirm}
				cancel={cancel}
				rfia={item}
				isHifExist={isHifExist}
			/>
		);
	};

	const onSteps = () => {
		open(
			<InvoiceProcessStepsForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<FixedWidthCard {...rest}>
			<CardTitleTypography
				withPadding
				title={strings.invoiceProcess}
				secondaryTitle={
					<RfiaInvoiceProcessStatusChip
						item={item}
						style={{ marginLeft: theme.spacing(1) }}
					/>
				}
				action={
					<IconButton onClick={onSteps}>
						<SettingsIcon />
					</IconButton>
				}
			/>
			<VerticalProcess style={{ paddingBottom: 0 }}>
				{containsStep<RfiaInvoiceProcessStep>('Tender', item.invoiceProcess.steps) && (
					<Step
						active={isTenderActive(item)}
						completed={isTenderCompleted(item)}>
						<StepLabel>{rfiaInvoiceProcessStepRecord['Tender']}</StepLabel>
						<StepContent>
							<ProcessButton
								text={strings.createWhat(strings.tender)}
								startIcon={<FileDocumentEditOutlineIcon />}
								onClick={onCreateTender}
							/>
						</StepContent>
					</Step>
				)}
				{/* <Step active>
                    <StepLabel>Bestelbon klant maken</StepLabel>
                    <StepContent>
                        <ProcessButton text="Creëer" startIcon={<FileDocumentEditOutlineIcon />} />
                    </StepContent>
                </Step> */}
				{/* <Step>
                    <StepLabel>Bestelling(-en) plaatsen</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Levering(-en) registreren</StepLabel>
                </Step> */}
				{containsAdvanceInvoiceOrHandoverBeforeApprovalStep && (
					<Step
						active={canInvoice(item)}
						completed={isInvoiceCompleted(item)}>
						<StepLabel>{strings.invoiceVerb}</StepLabel>
						<StepContent>
							<div className='df-col'>
								<div className='df-col-as'>
									{containsStep<RfiaInvoiceProcessStep>('AdvanceInvoice', item.invoiceProcess.steps) && (
										<ProcessButton
											text={strings.advance}
											startIcon={<ReceiptOutlinedIcon />}
											disabled={canCreateAdvanceInvoice(item) === false}
											onClick={onCreateAdvanceInvoice}
										/>
									)}
									{containsStep<RfiaInvoiceProcessStep>('HandoverBeforeApprovals', item.invoiceProcess.steps) && (
										<ProcessButton
											text='Factureren vóór de goedkeuring(-en)'
											startIcon={<ReceiptOutlinedIcon />}
											disabled={canHandoverBeforeApprovals(item) === false}
											onClick={onHandoverBeforeApproval}
										/>
									)}
								</div>
							</div>
						</StepContent>
					</Step>
				)}
				<Step
					active={item.isHandedOver && item.isCancelled === false}
					completed={item.isHandedOverAndPaid}>
					<StepLabel>{rfiaInvoiceProcessStepRecord['Payment']}</StepLabel>
				</Step>
			</VerticalProcess>
			<CardContent style={{ paddingTop: 0 }}>
				{item.invoiceProcess.hasTenders && (
					<>
						<DividerWithCaption
							caption={strings.tenders}
							action={
								item.isHandedOver === false && (
									<SmallPrimaryTextButton
										startIcon={<AddIcon />}
										onClick={onCreateTender}>
										{strings.new}
									</SmallPrimaryTextButton>
								)
							}
						/>
						<List
							dense
							disablePadding>
							{item.invoiceProcess.tenders.map(t => (
								<TenderListItem
									key={t.id}
									item={t}
									reload={reload}
								/>
							))}
						</List>
					</>
				)}
				{item.invoiceProcess.hasSales && (
					<>
						<DividerWithCaption
							caption={strings.sales}
							action={
								item.isHandedOver === false && (
									<SmallPrimaryTextButton
										startIcon={<AddIcon />}
										onClick={onCreateAdvanceInvoice}>
										{strings.new}
									</SmallPrimaryTextButton>
								)
							}
						/>
						<List
							dense
							disablePadding>
							{item.invoiceProcess.sales.map(t => (
								<SalesSummaryListItem
									key={t.id}
									item={t}
									reload={reload}
								/>
							))}
						</List>
					</>
				)}
			</CardContent>
		</FixedWidthCard>
	);
};
