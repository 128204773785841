import { Button, CardProps, Checkbox } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { isLast } from 'framework/utils/array/isLast';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { ISale } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { SalesLineListItem } from './SalesLineListItem';

interface IProps extends CardProps {
	item: ISale;
}

export const SalesDetailContentsCard = ({ item, ...rest }: IProps) => {
	const strings = useLocalization();
	const [expandedId, setExpandedId] = useAccordionState<string>();
	const [expandAll, toggleExpandAll] = useStateToggleBoolean(false);

	return (
		<CardX {...rest}>
			<div
				className='df-row'
				style={{ gap: 8 }}>
				<Typography16pxBold>{strings.contents}</Typography16pxBold>
				<div className='fg1'></div>
				<div
					className='df-col'
					style={{ alignItems: 'end' }}>
					<Button
						size='small'
						endIcon={
							<Checkbox
								checked={expandAll}
								style={{ padding: 0 }}
							/>
						}
						style={{ marginRight: -2 }}
						onClick={toggleExpandAll}>
						{strings.showAllContents}
					</Button>
				</div>
			</div>
			{item.lines.map(t => (
				<SalesLineListItem
					key={t.id}
					line={t}
					withoutBorder={isLast(t, item.lines)}
					isExpanded={expandedId === t.id || expandAll}
					toggle={() => setExpandedId(t.id)}
				/>
			))}
		</CardX>
	);
};
