import { Divider } from '@material-ui/core';
import { ArticlePropertiesLabelledPropertiesX } from 'app/main/inventoryManagement/inventoryItems/detail/articleProperties/ArticlePropertiesLabelledPropertiesX';
import { ArticlePropertiesDialog } from 'app/main/inventoryManagement/inventoryItems/forms/articleProperties/ArticlePropertiesDialog';
import { useLocalizedArticlePropertyKeys } from 'app/main/inventoryManagement/productCatalog/articlePropertyKeys/context/useLocalizedArticlePropertyKeys';
import { CollapseX } from 'framework/components/CollapseX';
import { IDraggableItem } from 'framework/components/dnd/IDraggableItem';
import { SelectFieldFromRecord } from 'framework/components/select/SelectFieldFromRecord';
import { Orientation } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { LineDescriptionAndQuantity } from 'shared/searchAndSelect/lines/LineDescriptionAndQuantity';
import { LineXComponent } from 'shared/searchAndSelect/lines/LineXComponent';
import { IArticlesInquiryLineModel } from './IArticlesInquiryLineModel';

interface IProps extends IDraggableItem {
	line: IArticlesInquiryLineModel;
	index: number;
	setLine: (val: IArticlesInquiryLineModel) => void;
	onDelete: VoidFunction;
}

export const RegisterArticlesInquiryLineComponent = ({ line, setLine, ...rest }: IProps) => {
	const strings = useLocalization();
	const { orientationRecord } = useContext(RecordContext);
	const localizedKeys = useLocalizedArticlePropertyKeys();
	const { open, confirm, cancel } = useDialogsContext();

	const onEditAps = () => {
		open(
			<ArticlePropertiesDialog
				open
				confirm={apLines => {
					confirm();
					setLine({ ...line, articleProperties: apLines });
				}}
				cancel={cancel}
				apds={line.productCatalogItem.articlePropertyDefinitions}
				aps={line.articleProperties}
			/>
		);
	};

	return (
		<LineXComponent
			{...rest}
			line={line}
			setLine={setLine}
			errors={line.error}>
			<LineDescriptionAndQuantity<IArticlesInquiryLineModel>
				line={line}
				setLine={setLine}
				disableQuantity={line.productCatalogItem.isUniquelyIdentifiable}
			/>
			<div
				className='df-row-wrap gap-16'
				style={{ marginTop: 8 }}>
				<div
					className='df-col gap-16'
					style={{ minWidth: 200 }}>
					{line.productCatalogItem.isUniquelyIdentifiable && (
						<SelectFieldFromRecord<Orientation>
							variant='outlined'
							size='small'
							label={strings.orientation}
							record={orientationRecord}
							value={line.orientation as any}
							onChange={value => setLine({ ...line, orientation: value })}
							withNone
						/>
					)}
				</div>
				{line.productCatalogItem.isUniquelyIdentifiable && line.productCatalogItem.articlePropertyDefinitions.length > 0 && (
					<>
						<Divider orientation='vertical' />
						<div className='df-col'>
							<CollapseX
								expandText={strings.showProperties}
								collapseText={strings.hideProperties}
								expandByDefault>
								<ArticlePropertiesLabelledPropertiesX
									aps={line.articleProperties}
									localizedKeys={localizedKeys}
									edit={onEditAps}
								/>
							</CollapseX>
						</div>
					</>
				)}
			</div>
		</LineXComponent>
	);
};
