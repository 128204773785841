import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, Typography, useTheme } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import { calcTenderDetailRoute } from 'app/main/financial/routes';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { formatDate } from 'framework/utils/date/formatDate';
import { ITenderSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TenderDetailDialog } from './TenderDetailDialog';

interface IProps {
	item: ITenderSummary;
	reload: VoidFunction;
	hideAction?: boolean;
}

export const TenderListItem = ({ item, reload, hideAction = false }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { push } = useHistory();
	const { open, confirm } = useDialogsContext(reload);

	const onViewDetail = () => {
		open(
			<TenderDetailDialog
				open
				id={item.id}
				close={confirm}
			/>
		);
	};

	const onNavigate = () => {
		push(calcTenderDetailRoute(item.id));
	};

	return (
		<div>
			<ListItem
				disableGutters
				button
				onClick={onViewDetail}>
				<ListItemIcon style={{ minWidth: '40px' }}>
					<ReceiptOutlinedIcon />
				</ListItemIcon>
				<div className='df-row-ac w100'>
					<div className='df-col'>
						<Typography
							variant='caption'
							color='textSecondary'
							style={{ marginRight: theme.spacing(0.5) }}>
							{formatDate(item.date)}
						</Typography>
						<Typography
							variant='body2'
							noWrap
							display='block'
							style={{ marginRight: theme.spacing(1) }}>
							{item.reference}
						</Typography>
					</div>
				</div>
				{hideAction === false && (
					<ListItemSecondaryAction>
						<TooltipWithSpan title={strings.navigateToDetailsPage}>
							<IconButton
								edge='end'
								onClick={onNavigate}>
								<OpenInNewIcon />
							</IconButton>
						</TooltipWithSpan>
					</ListItemSecondaryAction>
				)}
			</ListItem>
		</div>
	);
};
