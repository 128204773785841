import { Checkbox, Dialog, DialogContent, DialogProps, DialogTitle, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { toggleSelect } from 'framework/utils/array/toggleSelect';
import { FunctionalClaim, FunctionalClaims, IPatchUserFunctionalClaimsRequest, IUserDto, usersCommand_patchFunctionalClaims } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useState } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPatchUserFunctionalClaimsRequest>({
			userId: yup.string().required(),
			functionalClaims: yup.mixed(),
		})
		.defined();
};

interface IProps extends DialogProps {
	user: IUserDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const UserFunctionalClaimsForm = ({ user, confirm, cancel, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [change, isSubmitting] = useFormSubmit(usersCommand_patchFunctionalClaims);
	const strings = useLocalization();
	const [selected, setSelected] = useState<FunctionalClaim[]>(user.functionalClaims as FunctionalClaim[]);
	const { functionalClaimRecord } = useContext(RecordContext);

	const handleSubmit = async (values: IPatchUserFunctionalClaimsRequest, helpers: FormikHelpers<IPatchUserFunctionalClaimsRequest>) => {
		const r = await change({ ...values, functionalClaims: selected });
		if (handleValidateResponse(r, helpers, 'functionalClaims')) {
			notify(strings.changedWhat(strings.functions));
			confirm();
		}
	};

	return (
		<Formik<IPatchUserFunctionalClaimsRequest>
			validateOnMount
			initialValues={{
				userId: user.id,
				functionalClaims: [],
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					maxWidth='sm'
					{...rest}>
					<DialogTitle>{strings.accessToFunctions}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<List
							dense
							style={{ marginLeft: '-24px', marginRight: '-24px' }}>
							{FunctionalClaims.map(t => (
								<ListItem
									key={t}
									button
									onClick={() => setSelected(toggleSelect(t as FunctionalClaim, selected))}>
									<ListItemIcon>
										<Checkbox checked={selected.indexOf(t as FunctionalClaim) !== -1} />
									</ListItemIcon>
									<ListItemText primary={functionalClaimRecord[t]} />
								</ListItem>
							))}
						</List>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.change}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
