import { useApiEffect } from 'framework/hooks/useApiEffect';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useTrigger } from 'framework/hooks/useTrigger';
import { useWrapApiCallWithLoad } from 'framework/hooks/useWrapApiCallWithLoad';
import { meta_url, apiTokensCommand_setUser } from 'gen/ApiClient';
import { CredentialsError, info_ClientId, meta_Get, meta_Ping, status_CanLaunch, status_CheckCredentials, status_ExtendedStatus } from 'gen/client/HamsClient';
import React, { useEffect, useMemo, useState } from 'react';
import { useDownloadApplicationInstaller } from 'shared/utils/useDownloadApplicationInstaller';
import { HamsClientApplicationContext } from './HamsClientApplicationContext';
import { LatestBuildContext } from './LatestBuildContext';
import { LatestBuildProvider } from './LatestBuildProvider';
import { useAutoFixCredentials } from './useAutoFixCredentials';

export const ApplicationProvider = ({ children }: any) => {
	return (
		<LatestBuildProvider>
			<Inner>{children}</Inner>
		</LatestBuildProvider>
	);
};

const Inner = ({ children }: any) => {
	const [ping, isPinging] = useWrapApiCallWithLoad(meta_Ping);
	const [canLaunchCall, isLoadingCanLaunch] = useWrapApiCallWithLoad(status_CanLaunch);
	const [extendedStatusCall, isLoadingExtendedStatus] = useWrapApiCallWithLoad(status_ExtendedStatus);
	const [buildCall, isLoadingBuild] = useWrapApiCallWithLoad(meta_Get);
	const [checkCredentialsCall, isLoadingCredentials] = useWrapApiCallWithLoad(status_CheckCredentials);
	const [loadClientId] = useWrapApiCallWithLoad(info_ClientId);
	const [setClientId] = useWrapApiCallWithLoad(apiTokensCommand_setUser);
	const [data] = usePreloadCacheContext(LatestBuildContext);
	const [trigger, hitTrigger] = useTrigger();
	const [isInstalled, setIsInstalled] = useState<boolean>();
	const [canLaunch, setCanLaunch] = useState<boolean>(false);
	const [installedBuild, setInstalledBuild] = useState<number>(0);
	const hasLatest = useMemo(() => (isInstalled === true && data !== undefined ? data <= installedBuild : true), [isInstalled, data, installedBuild]);
	const [requestUrl] = useApiEffect(meta_url);
	const requestOrRenewCredentials = useAutoFixCredentials();
	const isInitialized = useMemo(() => isInstalled !== undefined && data !== undefined, [isInstalled, data]);
	const [download, isDownloading] = useDownloadApplicationInstaller('Client');

	useEffect(() => {
		const load = async () => {
			const r = await ping();
			setIsInstalled(r.isSuccess);
			if (r.isSuccess) {
				const rB = await buildCall();
				if (rB.isSuccess) {
					setInstalledBuild(rB.result);
				}
			}
		};
		load();
		// eslint-disable-next-line
	}, [trigger]);

	useEffect(() => {
		const loadOld = async () => {
			const r = await canLaunchCall();
			setCanLaunch(r.isSuccess && r.result);
		};
		const loadNew = async (url: string) => {
			const r = await extendedStatusCall(url);
			setCanLaunch(r.isSuccess && r.result.hasError === false);
		};
		if (isInstalled === true && installedBuild !== undefined && installedBuild < 58) {
			loadOld();
		} else if (isInstalled === true && installedBuild >= 58 && requestUrl !== undefined) {
			loadNew(requestUrl);
		}
		// eslint-disable-next-line
	}, [trigger, installedBuild, requestUrl, isInstalled]);

	useEffect(() => {
		const call = async (url: string) => {
			const r = await checkCredentialsCall(url);
			if (
				r.isSuccess &&
				r.result.hasError &&
				(r.result.error as CredentialsError) !== 'CannotReachServer' &&
				(r.result.error as CredentialsError) !== 'NoRequestUrlProvided'
			) {
				await requestOrRenewCredentials();
			}
		};
		if (installedBuild !== undefined && installedBuild >= 58 && requestUrl !== undefined) {
			call(requestUrl);
		}
		// eslint-disable-next-line
	}, [requestUrl, installedBuild]);

	useEffect(() => {
		const loadAndSet = async () => {
			const r = await loadClientId();
			if (r.isSuccess) {
				await setClientId(r.result);
			}
		};
		if (canLaunch === true) {
			// is placed inside AppWrapper, so after login will come back to here, if it's installed the canLaunch will change, so also set the credentials
			console.log('----------set credentials');
			loadAndSet();
		}
		// eslint-disable-next-line
	}, [canLaunch]);

	return (
		<HamsClientApplicationContext.Provider
			value={{
				isInstalled: isInstalled ?? true,
				canLaunch: canLaunch,
				check: hitTrigger,
				hasLatest: hasLatest,
				allChecked: isInstalled === true && canLaunch && hasLatest,
				installedBuild: installedBuild,
				latestBuild: data,
				isChecking: isLoadingCanLaunch || isLoadingExtendedStatus || isLoadingBuild || isLoadingCredentials || isPinging,
				isInitialized: isInitialized,
				download: download,
				isDownloading: isDownloading,
			}}>
			{children}
		</HamsClientApplicationContext.Provider>
	);
};
