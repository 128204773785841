import { IStrings } from 'localization/IStrings';

interface IApd {
	options: string[];
	allowMultiple: boolean;
}

export const apdToType = (apd: IApd, strings: IStrings): string => {
	return `${apd.options !== undefined && apd.options !== null && apd.options.length > 0 ? strings.selectVerb : strings.freeInputField}${
		apd.allowMultiple ? ' (>1)' : ''
	}`;
};
