import { useContext } from 'react';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { DocumentEditorDialogContext } from './DocumentEditorDialogContext';

export function useDocumentEditor(reload?: VoidFunction): [(documentId: string, historyId?: string) => void, boolean] {
	const { isOpen, open, trigger } = useContext(DocumentEditorDialogContext);

	useLazyEffect(() => {
		if (reload) {
			reload();
		}
	}, [trigger]);

	return [open, isOpen];
}
