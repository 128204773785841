import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { failedEventsQuery_concurrencyToken, failedEventsQuery_query, IFailedEvent, IFailedEventsQueryParams, IQueryResult } from 'gen/ApiClient';

import { failedEventsQueryParamsLsKey } from '../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: IFailedEventsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	statuses: [],
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IFailedEvent>, IFailedEventsQueryParams>
			defaultParams={DefaultParams}
			queryF={failedEventsQuery_query}
			concurrencyF={failedEventsQuery_concurrencyToken}
			localStorageKey={failedEventsQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
