import { TableSortLabel } from '@material-ui/core';
import { nextSortParams } from 'framework/table/nextSortParams';
import { ISortParams } from 'gen/ApiClient';
import React from 'react';
import { HeaderTableCellX } from './HeaderTableCellX';
import { HeaderTableCellXProps } from './HeaderTableCellXProps';

interface IProps<T> extends HeaderTableCellXProps {
	forProperty: keyof T;
	sortParams: ISortParams<T>;
	onFilter: (sp: ISortParams<T>) => void;
}

export const SortableHeaderTableCellX = <T extends unknown>({ forProperty, sortParams, onFilter, children, ...rest }: IProps<T>) => {
	return (
		<HeaderTableCellX {...rest}>
			<TableSortLabel
				active={sortParams.property === forProperty}
				direction={sortParams.direction}
				onClick={() => onFilter(nextSortParams<T>(sortParams, forProperty))}>
				{children}
			</TableSortLabel>
		</HeaderTableCellX>
	);
};
