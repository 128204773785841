import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { IOrderV2 } from 'gen/ApiClient';
import React from 'react';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { OrdersSettingsRoute } from '../../ProcessesRoutes';
import { OrderChips } from '../OrderChips';
import { OrdersPageBreadcrumbs } from '../OrdersPageBreadcrumbs';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

interface IProps {
	item: IOrderV2;
	reload: VoidFunction;
}

export const OrderDetailPageHeader = ({ item, reload }: IProps) => {
	return (
		<div
			className='df-row-ac fg1'
			style={{ paddingBottom: 16 }}>
			<OrdersPageBreadcrumbs />
			<PageBreadcrumbDivider />
			<PageBreadcrumbTitle title={item.reference} />
			<OrderChips
				item={item}
				style={{ marginLeft: 4 }}
			/>
			<div className='fg1'></div>
			<PageXHeaderActions>
				<PageSettingsButtonX route={OrdersSettingsRoute} />
				<PageReloadButtonX reload={reload} />
			</PageXHeaderActions>
		</div>
	);
};
