import { StatCardX } from 'framework/components/cards/StatCardX';
import { RelativeChangeFadedRectangularChip } from 'framework/components/chips/RelativeChangeFadedRectangularChip';
import { IRfiaQueryMetadata } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const Width: number = 250;

interface IProps {
	metadata: IRfiaQueryMetadata;
	style?: React.CSSProperties;
}

export const RfiasListStats = ({ metadata, style }: IProps) => {
	const strings = useLocalization();

	return (
		<div
			className='df-row-ac gap-16'
			style={style}>
			<StatCardX
				style={{ width: Width }}
				title={strings.active}
				caption={strings.notHandedOverNorFailed}>
				<span style={{ fontSize: 24 }}>{metadata.countActive.toString()}</span>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={strings.started}
				caption={strings.startedThisYearBracketsYTD}>
				<div className='df-row-ac'>
					<span style={{ fontSize: 24 }}>{metadata.startedYtd.current.toString()}</span>
					<RelativeChangeFadedRectangularChip
						change={metadata.startedYtd}
						tooltip={strings.comparedWithSamePeriodLastYearBracketsWhat(metadata.startedYtd.previous.toString())}
						style={{ marginLeft: 4 }}
					/>
				</div>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={strings.handedOver}
				caption={strings.handedOverThisYearBracketsYTD}>
				<div className='df-row-ac'>
					<span style={{ fontSize: 24 }}>{metadata.handedOverYtd.current.toString()}</span>
					<RelativeChangeFadedRectangularChip
						change={metadata.handedOverYtd}
						tooltip={strings.comparedWithSamePeriodLastYearBracketsWhat(metadata.handedOverYtd.previous.toString())}
						style={{ marginLeft: 4 }}
					/>
				</div>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={strings.failedTrials}
				caption={strings.failedTrialsThisYearBracketsYTD}>
				<div className='df-row-ac'>
					<span style={{ fontSize: 24 }}>{metadata.failedTrialYtd.current.toString()}</span>
					<RelativeChangeFadedRectangularChip
						change={metadata.failedTrialYtd}
						tooltip={strings.comparedWithSamePeriodLastYearBracketsWhat(metadata.failedTrialYtd.previous.toString())}
						invertColors
						style={{ marginLeft: 4 }}
					/>
				</div>
			</StatCardX>
		</div>
	);
};
