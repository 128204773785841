import React from 'react';
import { Route, Switch } from 'react-router';
import * as routes from './routes';
import { NoahDevicesPage } from './noahDevices/NoahDevicesPage';
import { ProductCatalogItemDetail } from './productCatalog/detail/ProductCatalogItemDetail';
import { ProductCatalogList } from './productCatalog/ProductCatalogList';
import { SearchSerialNumbers } from './searchSerialNumbers/SearchSerialNumbers';
import { Stock } from './stock/Stock';
import { StockTransactionsSwitch } from './stockTransactions/StockTransactionsSwitch';
import { StockEntriesList } from './stockEntries/StockEntriesList';
import { StockEntryDetail } from './stockEntries/detail/StockEntryDetail';
import { InventoryItemDetail } from './inventoryItems/detail/InventoryItemDetail';
import { InventoryItemsList } from './inventoryItems/InventoryItemsList';

export const InventoryMagementSwitch = () => {
	return (
		<Switch>
			<Route
				exact
				path={routes.ProductCatalogRoute}
				component={ProductCatalogList}
			/>
			<Route
				exact
				path={routes.ProductCatalogDetailRoute}
				component={ProductCatalogItemDetail}
			/>
			<Route
				exact
				path={routes.InventoryItemsRoute}
				component={InventoryItemsList}
			/>
			<Route
				exact
				path={routes.InventoryItemDetailRoute}
				component={InventoryItemDetail}
			/>
			<Route
				exact
				path={routes.StockRoute}
				component={Stock}
			/>
			<Route
				exact
				path={routes.StockEntriesRoute}
				component={StockEntriesList}
			/>
			<Route
				exact
				path={routes.StockEntryDetailRoute}
				component={StockEntryDetail}
			/>
			<Route
				path={routes.StockTransactionsRoute}
				component={StockTransactionsSwitch}
			/>
			<Route
				path={routes.NoahDevicesRoute}
				component={NoahDevicesPage}
			/>
			<Route
				exact
				path={routes.SearchSerialNumberRoute}
				component={SearchSerialNumbers}
			/>
		</Switch>
	);
};
