import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import * as routes from './ProcessesRoutes';
import { ArticlesInquiryDetailPage } from './articlesInquiries/detail/ArticlesInquiryDetailPage';
import { ArticlesInquiriesListPage } from './articlesInquiries/list/ArticlesInquiriesListPage';
import { LoansSettingsPage } from './loans/LoansSettingsPage';
import { LoanDetailPage } from './loans/detail/LoanDetailPage';
import { LoansListPage } from './loans/list/LoansListPage';
import { OrdersSettingsPage } from './orders/OrdersSettingsPage';
import { OrderDetailPage } from './orders/detail/OrderDetailPage';
import { OrdersListPage } from './orders/list/OrdersListPage';
import { RepairDetailPage } from './repairs/detail/RepairDetailPage';
import { RepairsListPage } from './repairs/list/RepairsListPage';
import { RfiasListPage } from './requestsForInsuranceAllowance/RfiasListPage';
import { RfiaDetail } from './requestsForInsuranceAllowance/detail/RfiaDetail';
import { RfiasSettingsPage } from './requestsForInsuranceAllowance/settings/RfiasSettingsPage';
import { RepairSettingsPage } from './repairs/RepairSettingsPage';

export const ProcessesSwitch = () => {
	return (
		<Switch>
			<Route
				exact
				path={routes.OrdersRoute}
				component={OrdersListPage}
			/>
			<Route
				exact
				path={routes.OrdersSettingsRoute}
				component={OrdersSettingsPage}
			/>
			<Route
				exact
				path={routes.OrderDetailRoute}
				component={OrderDetailPage}
			/>
			<Route
				exact
				path={routes.RepairsRoute}
				component={RepairsListPage}
			/>
			<Route
				exact
				path={routes.RepairsSettingsRoute}
				component={RepairSettingsPage}
			/>
			<Route
				exact
				path={routes.RepairDetailRoute}
				component={RepairDetailPage}
			/>
			<Route
				exact
				path={routes.RequestsForInsuranceAllowanceRoute}
				component={RfiasListPage}
			/>
			<Route
				exact
				path={routes.RequestsForInsuranceAllowancesSettingsRoute}
				component={RfiasSettingsPage}
			/>
			<Route
				exact
				path={routes.RequestForInsuranceAllowanceDetailRoute}
				component={RfiaDetail}
			/>
			<Route
				exact
				path={routes.LoansRoute}
				component={LoansListPage}
			/>
			<Route
				exact
				path={routes.LoansSettingsroute}
				component={LoansSettingsPage}
			/>
			<Route
				exact
				path={routes.LoanDetailRoute}
				component={LoanDetailPage}
			/>
			<Route
				exact
				path={routes.ArticlesInquiriesRoute}
				component={ArticlesInquiriesListPage}
			/>
			<Route
				exact
				path={routes.ArticlesInquiryDetailRoute}
				component={ArticlesInquiryDetailPage}
			/>
			<Redirect to={routes.OrdersRoute} />
		</Switch>
	);
};
