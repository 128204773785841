import { useTheme } from '@material-ui/core';
import BlurCircularOutlinedIcon from '@material-ui/icons/BlurCircularOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { formatDate } from 'framework/utils/date/formatDate';
import { IPurchaseUblBuffer, purchasesUblCommand_handleFromBuffer, purchasesUblCommand_removeFromBuffer, purchasesUblQuery_downloadUblFromBuffer } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { calcPurchaseDetailRoute } from '../../routes';
import { RegisterPurchaseFromUblRequestForm } from '../forms/ubl/RegisterPurchaseFromUblRequestForm';
import { useDownload } from 'gen/useDownload';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IPurchaseUblBuffer;
	withoutBorder?: boolean;
	reload: VoidFunction;
}

export const PurchaseUblBufferListItem = ({ item, withoutBorder, reload }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { open, cancel, confirm } = useDialogsContext(reload);
	const loadUbl = useApiCall(purchasesUblCommand_handleFromBuffer);
	const notify = useSnackbarNotify();
	const { push } = useHistory();
	const [download] = useDownload(purchasesUblQuery_downloadUblFromBuffer);

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteUblFromBufferQuestionConfirmation}
				title={strings.deleteUblFromBuffer}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => purchasesUblCommand_removeFromBuffer({ bufferId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onHandle = async () => {
		const r = await loadUbl({ bufferId: item.id });
		if (r.isSuccess) {
			if (r.result.hasError) {
				notify(r.error, 'error');
			} else {
				open(
					<RegisterPurchaseFromUblRequestForm
						open
						confirm={id => {
							if (id) {
								push(calcPurchaseDetailRoute(id));
							}
							confirm();
						}}
						cancel={cancel}
						item={r.result.value}
						ublBufferId={item.id}
					/>
				);
			}
		}
	};

	return (
		<div
			className='df-row w100 gap-8'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<BlurCircularOutlinedIcon style={{ fontSize: 22, color: theme.palette.success.main }} />
			<div className='df-col fg1 gap-2'>
				<TextLinkButton
					onClick={onHandle}
					style={{ fontSize: MajorFontSize }}>
					{item.ublId ?? item.fileName}
				</TextLinkButton>
				<span style={{ fontSize: MinorFontSize }}>{`${strings.dateUploaded}: ${formatDate(item.dateUploaded, 'date-and-time')}`}</span>
				<span style={{ fontSize: MinorFontSize }}>{`UBL id: ${item.ublId}`}</span>
				<span style={{ fontSize: MinorFontSize }}>{`${strings.fileName}: ${item.fileName}`}</span>
				<TextLinkButton
					style={{ marginLeft: -4, marginTop: 4 }}
					startIcon={<DeleteOutlineOutlinedIcon />}
					hoverColor='secondary'
					onClick={onDelete}>
					{strings.delete}
				</TextLinkButton>
				<TextLinkButton
					style={{ marginLeft: -4, marginTop: 4 }}
					startIcon={<GetAppOutlinedIcon />}
					onClick={() => download(item.id)}>
					{strings.download}
				</TextLinkButton>
			</div>
		</div>
	);
};
