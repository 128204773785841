import React from 'react';
import { IProductCatalogItem, IProductCatalogQueryParamsForTender, IQueryResult } from 'gen/ApiClient';

export interface IContext {
	params: IProductCatalogQueryParamsForTender;
	setParams: React.Dispatch<React.SetStateAction<IProductCatalogQueryParamsForTender>>;
	queryResult: IQueryResult<IProductCatalogItem> | undefined;
}

export const SearchContext = React.createContext<IContext>(undefined as any);
