import { Typography } from '@material-ui/core';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { ArrowDropDownOrUpIcon } from './ArrowDropDownOrUpIcon';

interface IProps {
	alignRight?: boolean;
	style?: React.CSSProperties;
	isExpanded: boolean;
	toggleIsExpanded: VoidFunction;
	expandText?: string;
	collapseText?: string;
}

export const CollapseXTextAndIcon = ({ alignRight, style, isExpanded, toggleIsExpanded, expandText, collapseText }: IProps) => {
	const strings = useLocalization();
	const className = useMemo<string>(() => (alignRight === true ? 'df-row-ac jc-e' : 'df-row-ac'), [alignRight]);
	const styleX = useMemo<React.CSSProperties>(
		() => (alignRight === true ? { cursor: 'pointer', marginRight: '-4px', ...style } : { cursor: 'pointer', marginLeft: '-4px', ...style }),
		[style, alignRight]
	);
	const text = useMemo<string>(
		() => (isExpanded ? collapseText ?? `${strings.showLess}...` : expandText ?? `${strings.showMore}...`),
		[isExpanded, collapseText, expandText, strings]
	);

	return (
		<div
			className={className}
			style={styleX}
			onClick={toggleIsExpanded}>
			{alignRight !== true && <ArrowDropDownOrUpIcon isUp={isExpanded} />}
			<Typography
				variant='caption'
				style={{ fontStyle: 'italic', cursor: 'pointer', fontWeight: 'bold' }}>
				{text}
			</Typography>
			{alignRight === true && <ArrowDropDownOrUpIcon isUp={isExpanded} />}
		</div>
	);
};
