import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IPatchPatientsSettingsAutoFillReferenceRequest, patientSettingsCommand_pathAutoFillReference } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchPatientsSettingsAutoFillReferenceRequest>({
		autoFillReference: yup.bool().required(),
	});
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchPatientSettingsAutoFillReferenceRequestForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [patch, isSubmitting] = useFormSubmit(patientSettingsCommand_pathAutoFillReference);

	const handleSubmit = async (
		values: IPatchPatientsSettingsAutoFillReferenceRequest,
		helpers: FormikHelpers<IPatchPatientsSettingsAutoFillReferenceRequest>
	) => {
		const r = await patch(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	return (
		<Formik<IPatchPatientsSettingsAutoFillReferenceRequest>
			validateOnMount
			initialValues={{
				autoFillReference: true,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.selectWhat(strings.location)}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormExtendedRadioGroupField<IPatchPatientsSettingsAutoFillReferenceRequest, boolean>
							name='autoFillReference'
							label=''
							options={[
								{ value: true, label: strings.autoPatientNumbering },
								{ value: false, label: strings.manualPatientNumbering },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
