import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { IOption } from 'framework/IOption';
import { isFiltered } from 'framework/utils/isFiltered';
import { INoahDeviceInventoryItemMapsWithUsagesQueryParams, NoahDeviceTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	params: INoahDeviceInventoryItemMapsWithUsagesQueryParams;
	onFilter: (params: INoahDeviceInventoryItemMapsWithUsagesQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof INoahDeviceInventoryItemMapsWithUsagesQueryParams)[] = [];

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const { noahDeviceTypeRecord } = useContext(RecordContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<BooleanRadioFilterComponent
				trueText={strings.imported}
				falseText={strings.notWhat(strings.imported)}
				selected={params.isLinkedToInventoryItem}
				setSelected={val => onFilter({ ...params, isLinkedToInventoryItem: val })}
				label={strings.imported}
				icon={<HelpOutlineIcon />}
			/>
			<BooleanRadioFilterComponent
				trueText={strings.differentReadOuts}
				falseText={strings.singleReadout}
				selected={params.hasDifferentReadOuts}
				setSelected={val => onFilter({ ...params, hasDifferentReadOuts: val })}
				label={`${strings.differentReadOuts}?`}
				icon={<HelpOutlineIcon />}
			/>
			<ListFilterComponent<string>
				options={NoahDeviceTypes.map<IOption<string>>(t => ({ id: t, identifier: noahDeviceTypeRecord[t] }))}
				selected={params.types}
				setSelected={vals => onFilter({ ...params, types: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.type}
			/>
			<DateFilterItem
				label={strings.firstUsageDate}
				after={params.firstUsageAfter}
				before={params.firstUsageBefore}
				setSelected={(after, before) => onFilter({ ...params, firstUsageAfter: after, firstUsageBefore: before })}
			/>
			<DateFilterItem
				label={strings.lastUsageDate}
				after={params.lastUsageAfter}
				before={params.lastUsageBefore}
				setSelected={(after, before) => onFilter({ ...params, lastUsageAfter: after, lastUsageBefore: before })}
			/>
		</FilterBar>
	);
};
