import React, { useContext } from 'react';
import { IDocumentSummary, IRequestForInsuranceAllowance } from 'gen/ApiClient';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { TrialReportDialog } from '../../trialReport/TrialReportDialog';
import { DocumentListItemOrEmpty } from 'documents/DocumentListItemOrEmpty';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	rfia: IRequestForInsuranceAllowance;
	document: IDocumentSummary;
	reload: VoidFunction;
}

export const TrialReportListItem = ({ rfia, document, reload }: IProps) => {
	const { documentPredefinedTypeRecord } = useContext(RecordContext);
	const { open, confirm } = useDialogsContext(reload);
	const strings = useLocalization();

	const onViewDetail = () => {
		if (rfia.lastLoanId === undefined) {
			return;
		}
		open(
			<TrialReportDialog
				open
				trialId={rfia.lastLoanId ?? ''}
				rfiaId={rfia.id}
				patientId={rfia.patientId}
				close={confirm}
			/>
		);
	};

	return (
		<DocumentListItemOrEmpty
			onNew={onViewDetail}
			reload={reload}
			onViewDetail={onViewDetail}
			document={document}
			title={`${documentPredefinedTypeRecord['RfiaTrialReport']} (${strings.lastWhat(strings.trial)})`}
			disabled={rfia.canCreateTestReport === false}
			appendTitle={strings.lastWhat(strings.trial)}
		/>
	);
};
