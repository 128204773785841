import { Divider } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { useFindUser } from 'app/main/settings/users/useFindUser';
import { LinkUserForm } from 'app/main/settings/users/forms/LinkUserForm';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { articlesInquiriesCommand_cancel, articlesInquiriesCommand_patchHandlingUser, IArticlesInquiryDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AddArticlesInquiryLinesRequestForm } from '../forms/addLines/AddArticlesInquiryLinesRequestForm';
import { OrderArticlesInquiry } from '../forms/order/OrderArticlesInquiry';
import { ArticlesInquiryDetailProcessComponent } from './ArticlesInquiryDetailProcessComponent';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';

interface IProps {
	item: IArticlesInquiryDto;
	reload: VoidFunction;
}

export const ArticlesInquiryDetailPageSidebar = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const user = useFindUser(item.handlingUserId);
	const { push } = useHistory();

	const onCancel = () => {
		open(
			<AlertDialog
				open
				title={strings.cancel}
				content={strings.doYouWantToCancelThisArticlesInquiryQuestion}
				acceptText={strings.yesWhatExclamation(strings.cancel)}
				rejectText={strings.no}
				reject={cancel}
				acceptF={() => articlesInquiriesCommand_cancel({ articlesInquiryId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onPatchHandlingUser = () => {
		open(
			<LinkUserForm
				open
				initialValue={item.handlingUserId}
				confirm={confirm}
				cancel={cancel}
				submitFunction={userId => articlesInquiriesCommand_patchHandlingUser({ articlesInquiryId: item.id, userId: userId })}
			/>
		);
	};

	const onOrder = () => {
		open(
			<OrderArticlesInquiry
				articlesInquiry={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onAddLines = () => {
		open(
			<AddArticlesInquiryLinesRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	return (
		<div
			className='df-col h100'
			style={{
				padding: 16,
				paddingRight: 8,
				minWidth: 250,
			}}>
			<div className='df-col gap-6'>
				<CaptionVerticalLabelledPropertyX label={strings.reference}>{item.reference}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.user}
					edit={onPatchHandlingUser}>
					{user?.lastNameCommaFirstName}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.patient}
					link={() => push(calcPatientDetailRoute(item.patientId))}>
					{item.patientIdentifier}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.location}>{item.location?.identifier}</CaptionVerticalLabelledPropertyX>
			</div>
			<Divider style={{ marginTop: 16, marginBottom: 24 }} />
			<ArticlesInquiryDetailProcessComponent item={item} />
			<div className='fg1'></div>
			<DividerWithMargin />
			<div className='df-col gap-8'>
				<TextLinkButton
					startIcon={<AddCircleOutlineOutlinedIcon />}
					onClick={onAddLines}
					disabled={item.isCancelled}>
					{strings.addLines}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<LocalShippingOutlinedIcon />}
					onClick={onOrder}
					disabled={item.canOrder === false}>
					{strings.orderArticles}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<DeleteOutlineOutlinedIcon />}
					onClick={onCancel}
					hoverColor='secondary'
					disabled={item.isCompleted}
					style={{ paddingBottom: 0 }}>
					{strings.cancel}
				</TextLinkButton>
			</div>
		</div>
	);
};
