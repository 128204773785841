import { ICreateResponse, IUpdateResponse } from 'gen/ApiClient';
import { Try } from '../../Try';
import { updateArray } from '../../utils/array/updateArray';

export function setLineErrors<TModel, TLine extends { error?: string }>(
	key: keyof TModel,
	r: Try<ICreateResponse<TModel>> | Try<IUpdateResponse<TModel>>,
	lines: TLine[],
	setLines: React.Dispatch<React.SetStateAction<TLine[]>>
) {
	if (r.isSuccess) {
		const lineErrors = r.result.errors.filter(t => (t.property as keyof TModel) === key);
		if (lineErrors.length > 0) {
			let x = [...lines];
			// reset all errors first
			x.forEach(t => {
				x = updateArray(t, x, { ...t, error: undefined });
			});
			lineErrors.forEach(t => {
				if (t.zeroBasedIndex !== undefined && t.zeroBasedIndex !== null) {
					x = updateArray(x[t.zeroBasedIndex], x, { ...x[t.zeroBasedIndex], error: t.error });
				}
			});
			setLines(x);
		}
	}
}
