import { useCalendarMode } from 'app/main/calendar/useCalendarMode';
import { Trigger } from 'framework/hooks/useTrigger';
import React from 'react';
import { HamsTodayEventsCard } from './HamsTodayEventsCard';
import { RosaTodayEventsCard } from './RosaTodayEventsCard';
import { RosaTodayEventsNotConfiguredYetCard } from './RosaTodayEventsNotConfiguredYetCard';

interface IProps {
	className?: string;
	style?: React.CSSProperties;
	reloadTrigger: Trigger;
}

export const TodayEventsCardSwitch = ({ ...props }: IProps) => {
	const calendarMode = useCalendarMode();

	return (
		<React.Fragment>
			{calendarMode === 'Rosa' && <RosaTodayEventsCard {...props} />}
			{calendarMode === 'Hams' && <HamsTodayEventsCard {...props} />}
			{calendarMode === 'NotSet' && <RosaTodayEventsNotConfiguredYetCard {...props} />}
		</React.Fragment>
	);
};
