import { calcDeliveryDetailRoute } from 'app/main/inventoryManagement/routes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { formatDate } from 'framework/utils/date/formatDate';
import { IOrderLineV2DeliveryData, IOrderV2 } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

const MinorFontSize = 13;

interface IProps {
	delivery: IOrderLineV2DeliveryData;
	order: IOrderV2;
	style?: React.CSSProperties;
}

export const OrderLineDeliveryComponent = ({ delivery, order, style }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const fDelivery = useMemo(() => order.deliveries.find(t => t.id === delivery.deliveryId)!, [delivery, order]);

	return (
		<div
			className='df-row-ac'
			style={{ fontSize: MinorFontSize, gap: 4 }}>
			<span>{`${strings.delivery}:`}</span>
			<span>{formatDate(fDelivery.date)}</span>
			<TextLinkButton
				style={{ fontSize: MinorFontSize }}
				underline
				onClick={() => push(calcDeliveryDetailRoute(fDelivery.id))}>
				{fDelivery.reference}
			</TextLinkButton>
		</div>
	);
};
