import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import BrightnessAutoOutlinedIcon from '@material-ui/icons/BrightnessAutoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { useAccordionState } from 'framework/utils/useAccordionState';
import {
	ValidUntilTimeUnits,
	salesQuery_settings,
	salesSettingsCommand_patchCashSaleReferenceSettings,
	salesSettingsCommand_patchDefaultValidUntilDate,
	salesSettingsCommand_patchInvoiceReferenceSettings,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReferenceSettingsForm } from 'shared/ReferenceSettingsForm';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PageReloadButton } from 'shared/layout/PageReloadButton';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { RecordContext } from 'shared/records/RecordContext';
import { TimeModelForm } from 'shared/timeUnit/TimeModelForm';
import { formatTimeModel } from 'shared/timeUnit/formatTimeModel';
import { calculateReference } from 'shared/utils/calculateReference';
import { SalesPageBreadcrumb } from './SalesPageBreadcrumb';
import { PatchAutoSetSalesToPaidRequestForm } from './settings/PatchAutoSetSalesToPaidRequestForm';

type AccordionType = 'default-due-date' | 'reference-settings' | 'auto-set-to-paid';

export const SalesSettingsPage = () => {
	const strings = useLocalization();
	const [trigger, reload] = useTrigger();
	const [settings, reloadSettings] = useApiEffect(salesQuery_settings);
	const [expandedAccordion, setExpandedAccordion] = useAccordionState<AccordionType>();
	const { goBack } = useHistory();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { timeUnitRecord } = React.useContext(RecordContext);

	useLazyEffect(() => {
		reloadSettings();
	}, [trigger]);

	if (settings === undefined) {
		return <div></div>;
	}

	const onPatchDefaultValidUntil = () => {
		open(
			<TimeModelForm
				open
				title={strings.changeWhat(strings.defaultWhat(strings.validTo))}
				label={strings.validTo}
				model={settings.defaultValidUntil}
				confirm={confirm}
				submitFunction={model => salesSettingsCommand_patchDefaultValidUntilDate({ defaultValidUntilTimeModel: model })}
				cancel={cancel}
				options={ValidUntilTimeUnits}
				defaultUnit='Weeks'
			/>
		);
	};

	const onPatchInvoiceReferenceSettings = () => {
		open(
			<ReferenceSettingsForm
				cancel={cancel}
				confirm={confirm}
				open
				value={settings.invoiceReferenceSettings}
				title={strings.changeWhat(strings.numberingWhat(strings.invoices))}
				submitFunction={model => salesSettingsCommand_patchInvoiceReferenceSettings(model)}
			/>
		);
	};

	const onPatchCashSaleReferenceSettings = () => {
		open(
			<ReferenceSettingsForm
				cancel={cancel}
				confirm={confirm}
				open
				value={settings.cashSaleReferenceSettings}
				title={strings.changeWhat(strings.numberingWhat(strings.cashSales))}
				submitFunction={model => salesSettingsCommand_patchCashSaleReferenceSettings(model)}
			/>
		);
	};

	const onPatchAutoSetToPaid = () => {
		open(
			<PatchAutoSetSalesToPaidRequestForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='Sales'
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<SalesPageBreadcrumb />
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={strings.settings} />
						<div className='fg1'></div>
						<Button
							size='small'
							variant='outlined'
							startIcon={<CloseIcon />}
							onClick={() => goBack()}>
							{strings.closeThisWindow}
						</Button>
						<PageReloadButton reload={reload} />
					</div>
				</div>
			}>
			<div
				className='df-col'
				style={{
					padding: 16,
					maxWidth: 1280,
				}}>
				<Accordion
					expanded={expandedAccordion === 'default-due-date'}
					onChange={() => setExpandedAccordion('default-due-date')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{strings.defaultWhat(strings.validTo)}</span>
					</AccordionSummary>
					<AccordionDetails>
						<LabelledPropertyX
							icon={<ScheduleIcon />}
							edit={onPatchDefaultValidUntil}>
							{formatTimeModel(settings.defaultValidUntil, timeUnitRecord)}
						</LabelledPropertyX>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expandedAccordion === 'reference-settings'}
					onChange={() => setExpandedAccordion('reference-settings')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{strings.numbering}</span>
					</AccordionSummary>
					<AccordionDetails className='df-col'>
						<LabelledPropertyX
							icon={<FormatListNumberedIcon />}
							label={strings.invoices}
							edit={onPatchInvoiceReferenceSettings}>
							{calculateReference(settings.invoiceReferenceSettings)}
						</LabelledPropertyX>
						<LabelledPropertyX
							icon={<FormatListNumberedIcon />}
							label={strings.cashSales}
							edit={onPatchCashSaleReferenceSettings}>
							{calculateReference(settings.cashSaleReferenceSettings)}
						</LabelledPropertyX>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expandedAccordion === 'auto-set-to-paid'}
					onChange={() => setExpandedAccordion('auto-set-to-paid')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{strings.setInvoicesAutoToPaid}</span>
					</AccordionSummary>
					<AccordionDetails className='df-col'>
						<LabelledPropertyX
							icon={<BrightnessAutoOutlinedIcon />}
							edit={onPatchAutoSetToPaid}>
							{settings.autoSetToPaid ? strings.setInvoicesAutoToPaid : strings.setInvoicesToPaidViaPayments}
						</LabelledPropertyX>
					</AccordionDetails>
				</Accordion>
			</div>
		</RestrictedPageX>
	);
};
