import { Card, Divider, Typography, useTheme } from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { Try } from 'framework/Try';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IInventoryItem, inventoryItemsQuery_single, IProductCatalogItem, IQueryResult } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createAddHeader } from 'shared/table/createAddHeader';
import { InventoryItemForm } from '../forms/InventoryItemForm';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { ICommonParams } from './ICommonParams';
import { SelectableColumn } from './SelectableColumn';

interface IProps<TParams extends ICommonParams> {
	item: IProductCatalogItem;
	onAdd: (item: IInventoryItem) => void;
	style?: React.CSSProperties;
	selectedIds: string[];
	defaultParams: TParams;
	queryF: (params: TParams) => Promise<Try<IQueryResult<IInventoryItem>>>;
	canSelectF: (item: IInventoryItem) => boolean;
}

export const SearchInventoryItemForProductCatalog = <TParams extends ICommonParams>({
	item,
	onAdd,
	selectedIds,
	defaultParams,
	queryF,
	canSelectF,
	style,
}: IProps<TParams>) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { columns, columnsRecord, setColumns, headers } = useContext(ViewContext);
	const [params, setParams, queryResult] = useApiEffectWithParams<TParams, IQueryResult<IInventoryItem>>(queryF, {
		...defaultParams,
		forProductCatalogId: item.id,
	});
	const load = useApiCall(inventoryItemsQuery_single);
	const { open, confirm, cancel } = useDialogsContext();

	useEffect(() => {
		setParams(prev => onResetToPage1({ ...prev, forProductCatalogId: item.id }));
		// eslint-disable-next-line
	}, [item]);

	const onFilter = (params: TParams) => {
		setParams(onResetToPage1(params));
	};

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<InventoryItemForm
				confirm={onConfirm}
				cancel={cancel}
				productCatalogItemId={item.id}
			/>
		);
	};

	const onConfirm = async (id: string) => {
		const r = await load(id);
		if (r.isSuccess) {
			onAdd(r.result);
		}
		confirm();
	};

	return (
		<div
			className='df-col h100'
			style={{ padding: theme.spacing(2), paddingTop: theme.spacing(3), ...style }}>
			<Typography>{strings.searchAndSelectSerialNumberFor}</Typography>
			<Typography style={{ fontWeight: 'bold' }}>{item.manufacturerCommaModel}</Typography>
			<SearchbarWithParams
				params={params}
				setParams={setParams}
				placeholder={strings.searchVerb}
				variant='paper'
				style={{ margin: '1px', marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
			/>
			<Card
				variant='outlined'
				className='df-col'>
				<div className='df-row-ac jc-sb'>
					<Filter
						params={params}
						setParams={setParams}
					/>
					<div className='df-row-ac'>
						<FormControlLabelSwitch
							isChecked={params.isShowAll}
							toggle={() => onFilter({ ...params, isShowAll: !params.isShowAll })}
							label={strings.viewAll}
						/>
						<TooltipWithSpan title={strings.itemNotFoundQuestionClickHereToDefineNewSerialNumber}>
							<SmallPrimaryTextButton
								startIcon={<PlaylistAddIcon />}
								color={queryResult.filteredCount === 0 ? 'secondary' : 'primary'}
								onClick={onNew}>
								{strings.new}
							</SmallPrimaryTextButton>
						</TooltipWithSpan>
						<SelectColumnsButtonAndDialog
							selectedColumns={columns}
							setSelectedColumns={setColumns}
							record={columnsRecord}
							view='searchInventoryItemsForProductCatalog'
						/>
					</div>
				</div>
				<Divider />
				<PageableTableWithColumnSelection<IInventoryItem, SelectableColumn>
					padding={theme.spacing(1)}
					headers={headers}
					selectedColumns={columns}
					getKey={t => t.id}
					pageParams={params.pageParams}
					sortParams={params.sortParams}
					queryResult={queryResult}
					setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
					appendColumn={createAddHeader<IInventoryItem>(
						t => onAdd(t),
						t => canSelectF(t) === false || selectedIds.indexOf(t.id) > -1
					)}
				/>
			</Card>
		</div>
	);
};
