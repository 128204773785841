import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IPurchase } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (colRecord: Record<SelectableColumn, string>, strings: IStrings): Record<SelectableColumn, IHeader<IPurchase>> => {
	return {
		amountPaid: createSortableHeaderWithKey<IPurchase, SelectableColumn>(colRecord, 'amountPaid', t => formatCurrency(t.amountPaid), 'right'),
		created: createSortableHeaderWithKey<IPurchase, SelectableColumn>(colRecord, 'created', t => formatDate(t.created)),
		validUntil: createSortableHeaderWithKey<IPurchase, SelectableColumn>(colRecord, 'validUntil', t => formatDate(t.validUntil)),
		outstandingBalance: createSortableHeaderWithKey<IPurchase, SelectableColumn>(
			colRecord,
			'outstandingBalance',
			t => formatCurrency(t.outstandingBalance),
			'right'
		),
		priceExVat: createSortableHeaderWithKey<IPurchase, SelectableColumn>(colRecord, 'priceExVat', t => formatCurrency(t.priceExVat), 'right'),
		priceInVat: createSortableHeaderWithKey<IPurchase, SelectableColumn>(colRecord, 'priceInVat', t => formatCurrency(t.priceInVat), 'right'),
		vatAmount: createSortableHeaderWithKey<IPurchase, SelectableColumn>(colRecord, 'vatAmount', t => formatCurrency(t.vatAmount), 'right'),
		reference: createSortableHeaderWithKey<IPurchase, SelectableColumn>(colRecord, 'reference', t => t.reference, 'right'),
		supplierIdentifier: createSortableHeaderWithKey<IPurchase, SelectableColumn>(colRecord, 'reference', t => t.reference, 'right'),
	};
};
