import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import StoreIcon from '@material-ui/icons/Store';

export const InventoryManagementPageBreadcrumb = () => {
	const strings = useLocalization();

	return (
		<PageBreadcrumbTitle
			title={strings.inventoryManagementShortened}
			icon={<StoreIcon />}
		/>
	);
};
