import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { LinkRizivMode } from './LinkRizivMode';

interface IProps extends DialogProps {
	confirm: (mode: LinkRizivMode) => void;
	cancel: VoidFunction;
	jsxTitle: JSX.Element;
}

export const LinkRizivModeForm = ({ confirm, cancel, jsxTitle, ...rest }: IProps) => {
	const strings = useLocalization();
	const [mode, setMode] = useState<LinkRizivMode>('fromProductCatalog');

	return (
		<Dialog
			{...rest}
			scroll='paper'
			fullWidth>
			<CloseButtonOnAbsolutePosition onClick={cancel} />
			<DialogTitle>{jsxTitle}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<ExtendedRadioGroup<LinkRizivMode>
					selected={mode}
					setSelected={setMode}
					options={[
						{ value: 'fromProductCatalog', label: strings.fromProductCatalog, caption: strings.linkRIZIVFromProductCatalogCaption },
						{ value: 'fromList', label: strings.fromCompleteList, caption: strings.makeAChoiceFromCompleteList },
					]}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button
					onClick={() => confirm(mode)}
					color='primary'>
					{strings.next}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
