import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IQueryResult, IPayment, IPaymentsQueryParams, paymentsQuery_concurrencyToken, paymentsQuery_query } from 'gen/ApiClient';
import { revenuesQueryParamsLsKey } from '../../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: IPaymentsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'paymentDate' },
	pageParams: { index: 1, size: 10 },
	amountPaidLessThan: undefined,
	amountPaidMoreThan: undefined,
	createdAfter: undefined,
	createdBefore: undefined,
	paymentDateAfter: undefined,
	paymentDateBefore: undefined,
	paymentMethodIds: [],
	customerAccountTypes: [],
	saleTypes: [],
	expenseOrIncomeTypes: [],
	isLinkedToAPurchase: undefined,
	isLinkedToASale: undefined,
	hasComment: undefined,
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IPayment>, IPaymentsQueryParams>
			defaultParams={DefaultParams}
			queryF={paymentsQuery_query}
			concurrencyF={paymentsQuery_concurrencyToken}
			localStorageKey={revenuesQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
