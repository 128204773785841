import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { IOrderV2, OrderProcessStatus } from 'gen/ApiClient';
import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const OrderProcessStatusIconRecord: Record<OrderProcessStatus, OverridableComponent<SvgIconTypeMap<{}, 'svg'>>> = {
	CancelledAfterPartialDelivery: BlockIcon,
	Cancelled: BlockIcon,
	CompletelyDelivered: CheckCircleOutlineIcon,
	PartialDelivered: RadioButtonUncheckedIcon,
	PlacedWithSupplier: RadioButtonUncheckedIcon,
	Registered: RadioButtonUncheckedIcon,
};

interface IProps {
	item: IOrderV2;
	style?: React.CSSProperties;
}

export const OrderListItemStatusIcon = ({ item, style }: IProps) => {
	const Icon = item.isOverDue ? AccessTimeIcon : OrderProcessStatusIconRecord[item.status as OrderProcessStatus];
	return <Icon style={style} />;
};
