import React from 'react';
import { CacheProvider } from 'framework/context/CacheProvider';
import { generalPractitionersQuery_concurrencyToken, generalPractitionersQuery_searchData, IGeneralPractitionersSearchData } from 'gen/ApiClient';
import { GeneralPractitionersFilterDataContext } from './GeneralPractitionersFilterDataContext';

export const GeneralPractitionersFilterDataProvider = ({ children }: any) => {
	return (
		<CacheProvider<IGeneralPractitionersSearchData>
			concurrencyF={generalPractitionersQuery_concurrencyToken}
			loadF={generalPractitionersQuery_searchData}
			context={GeneralPractitionersFilterDataContext}>
			{children}
		</CacheProvider>
	);
};
