import React, { useState } from 'react';
import { ISale } from 'gen/ApiClient';
import { SearchSaleForCommissionDialog } from '../../../sales/forms/search/SearchSaleForCommissionDialog';
import { CommissionModelForm } from './CommissionModelForm';

interface IProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const CommissionForm = ({ cancel, confirm }: IProps) => {
	const [sale, setSale] = useState<ISale>();

	if (sale === undefined) {
		return (
			<SearchSaleForCommissionDialog
				open
				confirm={setSale}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<CommissionModelForm
				open
				sale={sale}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	}
};
