import { List, ListItem, ListItemIcon, ListItemText, useTheme } from '@material-ui/core';
import { CollapseX } from 'framework/components/CollapseX';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { splitArray } from 'framework/utils/array/splitArray';
import { IRoom } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { RoomsPreloadCacheContext } from '../../../settings/rooms/context/RoomsPreloadCacheContext';
import { RoomColorPickerWrapper } from './RoomColorPickerWrapper';

export const RoomsList = () => {
	const theme = useTheme();
	const strings = useLocalization();
	const { data: roomsCache, load } = useContext(RoomsPreloadCacheContext);
	const plannableRooms = useMemo(() => _.orderBy(roomsCache?.notDeletedAndLocationPlannable, t => t.identifier), [roomsCache]);
	const [first3, other] = useMemo(() => splitArray(plannableRooms, 3), [plannableRooms]);

	return (
		<>
			{plannableRooms && (
				<div style={{ marginBottom: theme.spacing(4) }}>
					<DividerWithCaption
						caption={strings.rooms}
						captionStyle={{ fontWeight: 500, color: 'black' }}
					/>
					<List
						dense
						disablePadding>
						{first3.map(t => (
							<SingleItem
								key={t.id}
								room={t}
								reload={load}
							/>
						))}
						{other.length > 0 && (
							<CollapseX style={{ marginLeft: 10 }}>
								{other.map(t => (
									<SingleItem
										key={t.id}
										room={t}
										reload={load}
									/>
								))}
							</CollapseX>
						)}
					</List>
				</div>
			)}
		</>
	);
};

interface ISingleItemProps {
	room: IRoom;
	reload: VoidFunction;
}

const SingleItem = ({ room, reload }: ISingleItemProps) => {
	return (
		<ListItem
			key={room.id}
			disableGutters>
			<ListItemIcon
				style={{ paddingLeft: '11px' }}
				className='df-row-ac'>
				<RoomColorPickerWrapper
					room={room}
					reload={reload}
				/>
			</ListItemIcon>
			<ListItemText primary={room.identifier} />
		</ListItem>
	);
};
