import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	cancel: VoidFunction;
	confirm: (isForPatient: boolean) => void;
}

export const TenderForForm = ({ cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const [isForPatient, setIsForPatient] = useState<boolean>(true);

	return (
		<Dialog {...rest}>
			<DialogTitle>{`${strings.newWhat(strings.tender)}: ${strings.makeAChoice}`}</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<boolean>
					selected={isForPatient}
					setSelected={setIsForPatient}
					options={[
						{ value: true, label: strings.customerIsAPatient, caption: strings.tenderCustomerIsAPatientCaption },
						{ value: false, label: strings.customerIsNotAPatient, caption: strings.tenderCustomerIsNotAPatientCaption },
					]}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button
					onClick={() => confirm(isForPatient)}
					color='primary'>
					{strings.select}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
