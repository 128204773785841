import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RestrictedContent } from 'shared/layout/RestrictedContent';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { CommissionPayoutsTable } from './CommissionPayoutsTable';
import { MyQueryContext } from './context/MyQueryContext';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';

export const MyCommissionPayoutsPage = ({ reloadTrigger }: IReloadTriggerProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { params, setParams, queryResult, reload } = useQueryContext(MyQueryContext, reloadTrigger);
	const { columns, columnsRecord, setColumns } = useContext(ViewContext);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedContent pageClaim='MyCommissionPayouts'>
			<div
				className='df-row-ac jc-sb'
				style={{ marginBottom: '24px' }}>
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder={strings.searchVerb}
					style={{ width: '400px' }}
					variant='paper'
				/>
			</div>
			{queryResult.unfilteredCount > 0 && (
				<>
					<Card>
						<div className='df-row'>
							<Filter
								params={params}
								onFilter={params => setParams(onResetToPage1(params))}
								paddingLeft={theme.spacing(1.5)}
							/>
							<div className='fg1'></div>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
								view='myCommissionsPayoutPage'
							/>
						</div>
						<Divider />
						<CardMedia>
							<CommissionPayoutsTable
								queryResult={queryResult}
								params={params}
								setParams={setParams}
								reload={reload}
								withDeleteOption={false}
							/>
						</CardMedia>
					</Card>
				</>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound>{strings.noWhatDefined(strings.payouts)}</NoRecordsFound>}
		</RestrictedContent>
	);
};
