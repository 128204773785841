import { formatCurrency } from 'framework/utils/formatCurrency';
import React from 'react';
import { AbsoluteChangeFadedRectangularChip } from '../../../../framework/components/chips/AbsoluteChangeFadedRectangularChip';
import { IChange } from '../../../../framework/components/chips/IChange';

interface IProps {
	change: IChange;
	isCurrency?: boolean;
	style?: React.CSSProperties;
}

export const TextWithChange = ({ change, isCurrency, style }: IProps) => {
	return (
		<div
			className='df-row-ac'
			style={style}>
			<div>{isCurrency ? formatCurrency(change.current) : change.current.toString()}</div>
			<AbsoluteChangeFadedRectangularChip
				change={change}
				isCurrency={isCurrency}
				style={{ marginLeft: 8 }}
			/>
		</div>
	);
};
