import { Checkbox, Dialog, DialogContent, DialogProps, DialogTitle, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { useState } from 'react';
import { useMemo } from 'react';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { toggleSelect } from 'framework/utils/array/toggleSelect';
import { exampleTemplatesQuery_all, IExampleTemplateDto, templatesCommand_importExamples } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const ImportExamplesDialog = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [examples] = useApiEffect(exampleTemplatesQuery_all);
	const fExamples = useMemo(() => examples?.filter(t => t.isStandardDocument === false), [examples]);
	const [submit, isSubmitting] = useFormSubmit(templatesCommand_importExamples);
	const [selected, setSelected] = useState<IExampleTemplateDto[]>([]);

	const onConfirm = async () => {
		const r = await submit(selected.map(t => t.id));
		if (r.isSuccess) {
			confirm();
		}
	};

	if (fExamples === undefined) {
		return <div></div>;
	}

	return (
		<Dialog
			{...rest}
			scroll='paper'
			fullWidth>
			<DialogTitle>
				<div>Select one or more templates to import</div>
			</DialogTitle>
			<DialogContent
				dividers
				style={{ padding: '0px' }}>
				<ListItem
					dense
					button
					onClick={() => (selected.length === fExamples.length ? setSelected([]) : setSelected(fExamples))}>
					<ListItemIcon>
						<Checkbox
							indeterminate={selected.length > 0 && selected.length < fExamples.length}
							checked={selected.length === fExamples.length}
						/>
					</ListItemIcon>
					<ListItemText primary={selected.length === fExamples.length ? strings.selectNone : strings.selectAll} />
				</ListItem>
				<Divider variant='middle' />
				<List dense>
					{fExamples.map(t => (
						<ListItem
							key={t.name}
							button
							onClick={() => setSelected(toggleSelect(t, selected))}>
							<ListItemIcon>
								<Checkbox checked={selected.indexOf(t) !== -1} />
							</ListItemIcon>
							<ListItemText
								primary={t.name}
								secondary={`${t.language} | ${t.predefinedType} | ${t.mainContext}`}
							/>
						</ListItem>
					))}
				</List>
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				onSubmit={onConfirm}
				isValid={selected.length > 0}
				submitText={strings.selectWhat(selected.length.toString())}
				isSubmitting={isSubmitting}
				variantSubmitButton='outlined'
			/>
		</Dialog>
	);
};
