import { IconButton, InputAdornment, useTheme } from '@material-ui/core';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { SelectNoahDeviceSerialNumberForm } from 'app/main/inventoryManagement/noahDevices/single/forms/SelectNoahDeviceSerialNumberForm';
import { LocalStateTextField } from 'framework/components/textFields/LocalStateTextField';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { IProductCatalogItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from './dialogs/useDialogsContext';

interface IProps {
	value: string | undefined;
	setValue: (val: string) => void;
	productCatalogItem: IProductCatalogItem;
	disabled?: boolean;
	style?: React.CSSProperties;
	hideLabel?: boolean;
}

export const TextFieldSelectSerialNumber = ({ value, setValue, productCatalogItem, disabled, style, hideLabel }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const hasError = useMemo(() => isNullOrUndefined(value), [value]);
	const { open, confirm, cancel } = useDialogsContext();
	const isHisOrRc = useMemo(() => productCatalogItem.isHearingInstrument || productCatalogItem.isRemoteControl, [productCatalogItem]);

	const onSearch = () => {
		open(
			<SelectNoahDeviceSerialNumberForm
				open
				confirm={sn => {
					confirm();
					setValue(sn);
				}}
				initialSearch={`${productCatalogItem.manufacturer} ${productCatalogItem.model}`}
				cancel={cancel}
			/>
		);
	};

	return (
		<LocalStateTextField
			variant='outlined'
			size='small'
			label={hideLabel === true ? undefined : strings.serialNumber}
			value={value ?? ''}
			onChange={e => setValue(e.target.value)}
			error={hasError}
			disabled={disabled}
			style={style}
			InputProps={{
				endAdornment: (
					<>
						{isHisOrRc && (
							<InputAdornment position='end'>
								<TooltipWithSpan title={strings.searchASerialNumberFromNoahReadOuts}>
									<IconButton
										onClick={disabled === true ? undefined : onSearch}
										edge='end'
										style={{ color: hasError ? theme.palette.error.main : theme.palette.primary.main }}>
										<YoutubeSearchedForIcon fontSize='small' />
									</IconButton>
								</TooltipWithSpan>
							</InputAdornment>
						)}
					</>
				),
			}}
		/>
	);
};
