import { FinancialRoute } from 'routes';

export const TendersRoute = `${FinancialRoute}/tenders`;
export const TenderDetailRoute = `${TendersRoute}/:id`;
export const TendersSettingsRoute = `${TendersRoute}/settings`;
export const calcTenderDetailRoute = (id: string) => TenderDetailRoute.replace(':id', id);
export const CustomerAccountsRoute = `${FinancialRoute}/customer-accounts`;
export const CustomerAccountDetailRoute = `${CustomerAccountsRoute}/:id`;
export const calcCustomerAccountDetailRoute = (id: string) => CustomerAccountDetailRoute.replace(':id', id);
export const SupplierAccountsRoute = `${FinancialRoute}/supplier-accounts`;
export const SupplierAccountDetailRoute = `${SupplierAccountsRoute}/:id`;
export const calcSupplierAccountDetailRoute = (id: string) => SupplierAccountDetailRoute.replace(':id', id);
export const SalesRoute = `${FinancialRoute}/sales`;
export const SalesDetailRoute = `${SalesRoute}/:id`;
export const SalesSettingsRoute = `${SalesRoute}/settings`;
export const calcSalesDetailRoute = (id: string) => SalesDetailRoute.replace(':id', id);
export const PurchasesRoute = `${FinancialRoute}/purchases`;
export const PurchaseDetailRoute = `${PurchasesRoute}/:id`;
export const calcPurchaseDetailRoute = (id: string) => PurchaseDetailRoute.replace(':id', id);
export const PurchasesSettingsRoute = `${PurchasesRoute}/settings`;
export const PurchasesUblBufferRoute = `${PurchasesRoute}/ubl-buffer`;
export const PaymentsRoute = `${FinancialRoute}/payments`;
export const SettlementProposalsRoute = `${FinancialRoute}/settlement-proposals`;
export const CommissionsRoute = `${FinancialRoute}/commissions`;
export const AllCommissionsRoute = `${CommissionsRoute}/all`;
export const MyCommissionsRoute = `${CommissionsRoute}/my`;
export const AllCommissionPayoutsRoute = `${CommissionsRoute}/payouts/all`;
export const MyCommissionPayoutsRoute = `${CommissionsRoute}/payouts/my`;
export const DailyReceiptsRoute = `${FinancialRoute}/daily-receipts`;
export const CashRegisterEntriesRoute = `${FinancialRoute}/cash-register`;
export const SalesAnalysisRoute = `${FinancialRoute}/sales/sales-analysis`;
