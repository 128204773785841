import { ChipX } from 'framework/components/chips/ChipX';
import { PaymentStatus } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IData {
	paymentStatus: string | undefined;
	isFullyPaid: boolean;
}

interface IProps {
	item: IData;
	isOverDue: boolean;
	style?: React.CSSProperties;
}

export const PaymentStatusChipX = ({ item, isOverDue, style }: IProps) => {
	const { paymentStatusRecord } = useContext(RecordContext);

	return (
		<ChipX
			style={style}
			label={paymentStatusRecord[item.paymentStatus as PaymentStatus]}
			color={item.isFullyPaid === true ? 'success' : isOverDue === true ? 'warning' : 'info'}
		/>
	);
};
