import { FormikContextType } from 'formik';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiCall } from 'framework/hooks/useApiCall';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import {
	companiesQuery_singleRecipientAndAddress,
	patientsQuery_singleContactPersonRecipientAndAddress,
	patientsQuery_singleRecipientAndAddress,
} from 'gen/ApiClient';
import { useEffect } from 'react';

export interface IWithRecipientModel {
	companyId: string | undefined;
	recipientName: string | undefined;
	addressLine: string | undefined;
	zip: string | undefined;
	city: string | undefined;
	country: string | undefined;
}

export const useWithRecipientInterceptor = <TModel extends IWithRecipientModel>(
	props: FormikContextType<TModel>,
	isUseCompany: boolean,
	isUseContactPerson: boolean,
	patientId: string | undefined
) => {
	const loadPatient = useApiCall(patientsQuery_singleRecipientAndAddress);
	const loadCompany = useApiCall(companiesQuery_singleRecipientAndAddress);
	const loadContactPerson = useApiCall(patientsQuery_singleContactPersonRecipientAndAddress);

	useEffect(() => {
		const loadAndSetCompany = async (companyId: string) => {
			const r = await loadCompany(companyId);
			if (r.isSuccess) {
				setFormValue<TModel>('addressLine', r.result.addressLine, props);
				setFormValue<TModel>('zip', r.result.zip, props);
				setFormValue<TModel>('city', r.result.city, props);
				setFormValue<TModel>('country', r.result.country ?? '', props);
				setFormValue<TModel>('recipientName', r.result.recipient, props);
			}
		};

		const loadAndSetPatient = async (patientId: string) => {
			const r = await loadPatient(patientId);
			if (r.isSuccess) {
				setFormValue<TModel>('addressLine', r.result.addressLine, props);
				setFormValue<TModel>('zip', r.result.zip, props);
				setFormValue<TModel>('city', r.result.city, props);
				setFormValue<TModel>('country', r.result.country ?? '', props);
				setFormValue<TModel>('recipientName', r.result.recipient, props);
			}
		};

		const loadAndSetContactPerson = async (patientId: string) => {
			const r = await loadContactPerson(patientId);
			if (r.isSuccess) {
				setFormValue<TModel>('addressLine', r.result.addressLine, props);
				setFormValue<TModel>('zip', r.result.zip, props);
				setFormValue<TModel>('city', r.result.city, props);
				setFormValue<TModel>('country', r.result.country ?? '', props);
				setFormValue<TModel>('recipientName', r.result.recipient, props);
			}
		};

		if (isUseCompany === true && isNotNullNorUndefined(props.values.companyId)) {
			loadAndSetCompany(props.values.companyId!);
		} else if (isUseCompany === false && isUseContactPerson === false && isNotNullNorUndefined(patientId)) {
			loadAndSetPatient(patientId!);
		} else if (isUseCompany === false && isUseContactPerson === true && isNotNullNorUndefined(patientId)) {
			loadAndSetContactPerson(patientId!);
		} else {
			setFormValue<TModel>('addressLine', undefined, props);
			setFormValue<TModel>('zip', undefined, props);
			setFormValue<TModel>('city', undefined, props);
			setFormValue<TModel>('country', undefined, props);
			setFormValue<TModel>('recipientName', undefined, props);
		}
		// eslint-disable-next-line
	}, [props.values.companyId, isUseCompany, isUseContactPerson, patientId]);
};
