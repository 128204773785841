import { Color } from '@material-ui/lab';
import React, { useContext } from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { FailedEventStatus, IFailedEvent } from 'gen/ApiClient';
import { ViewContext } from './context/ViewContext';

const colorRecord: Record<FailedEventStatus, Color> = {
	Ignored: 'warning',
	Resolved: 'success',
	Open: 'error',
};

interface IProps {
	item: IFailedEvent;
	style?: React.CSSProperties;
}

export const FailedEventStatusChip = ({ item, style }: IProps) => {
	const { statusRecord } = useContext(ViewContext);

	return (
		<RectangularChip
			style={style}
			label={statusRecord[item.status as FailedEventStatus]}
			fill
			color={colorRecord[item.status as FailedEventStatus]}
		/>
	);
};
