import { IconButton } from '@material-ui/core';
import UndoIcon from '@material-ui/icons/Undo';
import { CancelledProcessStepX } from 'framework/components/stepper/CancelledProcessStepX';
import { ProcessStepX } from 'framework/components/stepper/ProcessStepX';
import { VerticalStepperX } from 'framework/components/stepper/VerticalStepperX';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IOrderV2, ordersCommand_undoPlaceWithSupplier } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { calculateActiveOrderProcessStep } from './calculateActiveOrderProcessStep';

interface IProps {
	item: IOrderV2;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const OrderDetailProcessComponent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const activeStep = React.useMemo(() => calculateActiveOrderProcessStep(item), [item]);

	const onUndoPlaceWithSupplier = () => {
		open(
			<AlertDialog
				open
				title={strings.undoPlaceWithSupplier}
				content={strings.undoPlaceWithSupplierQuestion}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => ordersCommand_undoPlaceWithSupplier({ orderId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<VerticalStepperX style={style}>
			<ProcessStepX
				doneText={strings.registered}
				todoText={strings.register}
				date={item.created}
				isCancelled={item.isCancelled}
				isActive={activeStep === 'register'}
			/>
			<ProcessStepX
				doneText={strings.placedWithSupplier}
				todoText={strings.placeWithSupplier}
				date={item.placedWithSupplierDate}
				isCancelled={item.isCancelled}
				isActive={activeStep === 'place'}>
				{item.isPlacedWithSupplier && (
					<div>
						<IconButton
							size='small'
							onClick={onUndoPlaceWithSupplier}
							disabled={item.canUndoPlaceWithSupplier === false}
							edge='start'>
							<UndoIcon fontSize='small' />
						</IconButton>
					</div>
				)}
			</ProcessStepX>
			<ProcessStepX
				doneText={strings.delivered}
				todoText={strings.delivery}
				date={item.isCompletelyDelivered ? item.lastActualDeliveryDate : undefined}
				isCancelled={item.isCancelled}
				isActive={activeStep === 'deliver'}
				isLast={item.isCancelled === false}
			/>
			{item.isCancelled && <CancelledProcessStepX />}
		</VerticalStepperX>
	);
};
