import { SubscriptionStatus } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createStatusRecord = (strings: IStrings): Record<SubscriptionStatus, string> => {
	return {
		Down: 'Down',
		ProcessingBacklog: 'Processing Backlog',
		Unknown: 'Unknown',
		UpToDate: 'Up-to-date',
	};
};
