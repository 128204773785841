import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GenderXIcon = ({ viewBox = '0 0 24 24', ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			{...props}
			viewBox={viewBox}>
			<path d='M15 0v2h2.586l-3.399 3.399c-1.169-.875-2.615-1.399-4.187-1.399-3.866 0-7 3.134-7 7 0 3.526 2.609 6.434 6 6.92v2.08h-3v2h3v2h2v-2h3v-2h-3v-2.08c3.391-.486 6-3.395 6-6.92 0-1.572-.524-3.018-1.399-4.187l3.399-3.399v2.586h2v-6h-6zm-5 16c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z' />
		</SvgIcon>
	);
};
