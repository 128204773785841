import { DialogProps } from '@material-ui/core';
import React, { useState } from 'react';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Try } from 'framework/Try';
import {
	IInventoryItem,
	IRizivHearingAidDefinitionV2Dto,
	IRizivHearingAidDefinitionV2IdAndDistributorDto,
	IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto,
	IValidateResponse,
	rizivHearingAidDefinitionsV2Query_identifyAndConvertToV2,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from '../dialogs/useDialogsContext';
import { LinkRizivMode } from 'app/main/inventoryManagement/inventoryItems/forms/LinkRizivMode';
import { LinkRizivModeForm } from 'app/main/inventoryManagement/inventoryItems/forms/LinkRizivModeForm';
import { RizivRefundableHearingInstrumentDialog } from './RizivRefundableHearingInstrumentDialog';
import { SelectCodeDialog } from './SelectCodeDialog';
import { SelectRizivRefundableHearingInstrumentForm } from './SelectRizivRefundableHearingInstrumentForm';
import { useApiCall } from 'framework/hooks/useApiCall';

type State = 'mode' | LinkRizivMode;

interface IProps extends DialogProps {
	confirm: (item: IRizivHearingAidDefinitionV2Dto) => void;
	cancel: VoidFunction;
	item: IInventoryItem;
	submitF?: (item: IRizivHearingAidDefinitionV2Dto) => Promise<Try<IValidateResponse>>;
}

export const SelectRizivRefundableHearingInstrumentForInventoryItemForm = ({
	item,
	cancel,
	confirm,
	submitF = () => Promise.resolve(new Try({ error: '', hasError: false })),
	...rest
}: IProps) => {
	const { open, confirm: confirmX, cancel: cancelX, isStacked } = useDialogsContext();
	const [state, setState] = useState<State>(item.productCatalogData.hasMultipleRizivRefundableHearingInstruments ? 'mode' : 'fromList');
	const [submit, isSubmitting] = useFormSubmit(submitF);
	const loadDef = useApiCall(rizivHearingAidDefinitionsV2Query_identifyAndConvertToV2);

	const onLink = async (item: IRizivHearingAidDefinitionV2Dto) => {
		const r = await submit(item);
		if (r.isSuccess) {
			confirm(item);
		}
	};

	const onLinkAndConfirmX = async (item: IRizivHearingAidDefinitionV2Dto) => {
		const r = await submit(item);
		if (r.isSuccess) {
			confirmX();
			confirm(item);
		}
	};

	const onSelectDefinition = async (group: IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto) => {
		if (group.hasMultipleDistributors) {
			open(
				<SelectCodeDialog
					{...rest}
					cancel={cancelX}
					confirm={onLinkAndConfirmX}
					identificationCodes={group.identificationCodes}
				/>
			);
		} else {
			var r = await loadDef(group.matches[0].identificationCode.toString());
			if (r.isSuccess) {
				onLink(r.result);
			}
		}
	};

	const onClickDetail = (item: IRizivHearingAidDefinitionV2IdAndDistributorDto) => {
		open(
			<RizivRefundableHearingInstrumentDialog
				{...rest}
				identificationCode={item.identificationCode}
				cancel={cancelX}
				select={item => onLinkAndConfirmX(item)}
			/>
		);
	};

	if (state === 'mode') {
		return (
			<LinkRizivModeForm
				{...rest}
				jsxTitle={<JsxTitle item={item} />}
				confirm={mode => setState(mode)}
				cancel={cancel}
			/>
		);
	} else if (state === 'fromProductCatalog') {
		return (
			<SelectCodeDialog
				{...rest}
				cancel={cancel}
				confirm={onLink}
				identificationCodes={item.productCatalogData.rizivHearingAidDefinitionV2IdentificationCodes}
			/>
		);
	} else if (state === 'fromList') {
		return (
			<SelectRizivRefundableHearingInstrumentForm
				{...rest}
				open={rest.open && isStacked === false}
				cancel={cancel}
				jxsTitle={<JsxTitle item={item} />}
				initialSearch={item.manufacturerAndModel}
				onClickSelect={onSelectDefinition}
				onClickDetail={onClickDetail}
				isSubmitting={isSubmitting}
			/>
		);
	} else {
		return <></>;
	}
};

const JsxTitle = ({ item }: { item: IInventoryItem }) => {
	const strings = useLocalization();
	return <div>{strings.selectCodeFor(item.identifier)}</div>;
};
