import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, Typography, useTheme } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import ScannerIcon from '@material-ui/icons/Scanner';
import { FileDocumentEditOutlineIcon } from 'framework/components/icons/FileDocumentEditOutlineIcon';
import { FileDownloadOutlineIcon } from 'framework/components/icons/FileDownloadOutlineIcon';
import { FileSearchOutlineIcon } from 'framework/components/icons/FileSearchOutlineIcon';
import { SignatureFreehandIcon } from 'framework/components/icons/SignatureFreehandIcon';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { formatDate } from 'framework/utils/date/formatDate';
import { DocumentExportFormat, IDocumentSummary, documentsQuery_download } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import React, { useContext, useMemo } from 'react';
import { useReplaceDocument } from 'shared/dialogs/scanOrUpload/useReplaceDocument';
import { useDocumentEditor } from 'shared/dialogs/useDocumentEditor';
import { usePdfViewerWithDocument } from 'shared/dialogs/usePdfViewerWithDocument';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	document: IDocumentSummary;
	children?: any;
	onClick: (id: string) => void;
	reload: VoidFunction;
	layout?: 'vertical' | 'horizontal';
	style?: React.CSSProperties;
	appendTitle?: string;
}

export const DocumentListItem = ({ document, children, onClick, reload, layout = 'vertical', style, appendTitle }: IProps) => {
	const theme = useTheme();
	const [isHovered, enter, leave] = useStateBoolean(false);
	const [openEditor] = useDocumentEditor();
	const [download] = useDownload(documentsQuery_download);
	const openPdf = usePdfViewerWithDocument();
	const replace = useReplaceDocument(reload);
	const { translateDocumentType } = useContext(RecordContext);
	const xTitle = useMemo(() => `${translateDocumentType(document)}${appendTitle ? ` (${appendTitle})` : ''}`, [appendTitle, document, translateDocumentType]);

	return (
		<div
			onMouseEnter={enter}
			onMouseLeave={leave}
			style={style}>
			<ListItem
				disableGutters
				button
				onClick={() => onClick(document.id)}>
				<ListItemIcon style={{ minWidth: '35px' }}>
					{document.mustSign && document.hasSignature === false ? <StatusIcon status='warning' /> : <DescriptionIcon />}
				</ListItemIcon>
				<div className='df-row-ac'>
					{layout === 'vertical' && (
						<div className='df-col'>
							<Typography
								variant='caption'
								color='textSecondary'>
								{formatDate(document.created)}
							</Typography>
							<Typography
								variant='body2'
								noWrap
								display='block'>
								{xTitle}
							</Typography>
						</div>
					)}
					{layout === 'horizontal' && (
						<div className='df-row-ab'>
							<Typography
								variant='caption'
								color='textSecondary'
								style={{ width: 80 }}>
								{formatDate(document.created)}
							</Typography>
							<Typography
								variant='body2'
								noWrap
								display='block'>
								{xTitle}
							</Typography>
						</div>
					)}
					{document.mustSign && document.hasSignature && (
						<SignatureFreehandIcon
							style={{ color: document.hasSignature ? theme.palette.success.main : theme.palette.warning.main, marginLeft: theme.spacing(1) }}
						/>
					)}
					{children && children}
				</div>
				<ListItemSecondaryAction style={{ visibility: isHovered ? 'visible' : 'collapse' }}>
					{document.mustSign && document.hasSignature === false && (
						<IconButton
							edge='end'
							onClick={() => replace(document)}>
							<ScannerIcon />
						</IconButton>
					)}
					{document.isDocxAndCanEditAndCanViewOnline && (
						<IconButton
							edge='end'
							onClick={() => openEditor(document.id)}>
							<FileDocumentEditOutlineIcon />
						</IconButton>
					)}
					{document.isDocxAndCanViewOnlineButCannotEdit && (
						<IconButton
							edge='end'
							onClick={() => openEditor(document.id)}>
							<FileSearchOutlineIcon />
						</IconButton>
					)}
					{document.isPdf && (
						<IconButton
							edge='end'
							onClick={() => openPdf(translateDocumentType(document), document.id)}>
							<FileSearchOutlineIcon />
						</IconButton>
					)}
					<IconButton
						edge='end'
						onClick={() => download(document.id, 'Native' as DocumentExportFormat)}>
						<FileDownloadOutlineIcon />
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
		</div>
	);
};
