import { IHeader, TableCellAlign } from './IHeader';

export function createSortableHeader<T>(
	text: string,
	key: keyof T,
	evaluate: (inst: T, index: number, isExpanded?: boolean, toggleExpand?: VoidFunction) => string | JSX.Element | undefined,
	align?: TableCellAlign,
	cellStyle?: React.CSSProperties
): IHeader<T> {
	return { text: text, key: key.toString(), evaluate: evaluate, sortProperty: key, align, onlyShowOnHover: false, cellStyle: cellStyle };
}
