import { TableRow, useTheme } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { SupplierDialog } from 'app/main/contacts/suppliers/SupplierDialog';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ChipX } from 'framework/components/chips/ChipX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { TableCellX } from 'framework/table/TableCellX';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IPurchase } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { calcPurchaseDetailRoute } from '../../routes';
import { PurchaseCreditChipOrFragment } from '../PurchaseCreditChipOrFragment';
import { PurchasePaymentStatusChip } from '../PurchasePaymentStatusChip';
import { PurchaseTypeChip } from '../PurchaseTypeChip';
import { PurchaseListItemStatusIcon } from './PurchaseListItemStatusIcon';
import { mapPurchaseToColorX } from './utils';
import { TableDetailsRowX } from 'framework/table/TableDetailsRowX';

const MinorFontSize = 13;

interface IProps {
	item: IPurchase;
}

export const PurchaseTableRow = ({ item }: IProps) => {
	const strings = useLocalization();
	const color = useMemo(() => mapPurchaseToColorX(item), [item]);
	const tColor = useThemeColor(color);
	const theme = useTheme();
	const { push } = useHistory();
	const { open, cancel } = useDialogsContext();
	const [isHovered, enter, leave] = useStateBoolean(false);
	const onViewDetails = () => push(calcPurchaseDetailRoute(item.id));

	const onViewSupplier = () => {
		open(
			<SupplierDialog
				open
				id={item.supplierId}
				close={cancel}
			/>
		);
	};

	return (
		<React.Fragment>
			<TableRow
				hover
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<TableCellX isFirst>
					<div className='df-row gap-4'>
						<PurchaseListItemStatusIcon
							item={item}
							style={{ fontSize: 22, color: tColor }}
						/>
						<div className='df-col gap-2'>
							<TextLinkButton
								underline
								style={{ fontSize: 14 }}
								onClick={onViewDetails}>
								{item.reference}
							</TextLinkButton>
							<div className='df-row-ac gap-4'>
								<PurchaseTypeChip
									item={item}
									color={item.isCreditNote ? 'warning' : 'info'}
								/>
								<PurchaseCreditChipOrFragment item={item} />
							</div>
							{Boolean(item.externalReference) && (
								<span
									style={{
										fontSize: MinorFontSize,
										color: theme.palette.text.secondary,
									}}>{`${strings.shortExternalReference}: ${item.externalReference}`}</span>
							)}
						</div>
					</div>
				</TableCellX>
				<TableCellX>
					<div className='df-col gap-2'>
						<span>{formatDate(item.purchaseDate)}</span>
						<span style={{ fontSize: MinorFontSize, color: theme.palette.text.secondary }}>{`${strings.created}: ${formatDate(item.created)}`}</span>
					</div>
				</TableCellX>
				<TableCellX>
					<TextLinkButton
						style={{ fontSize: MinorFontSize }}
						onClick={onViewSupplier}>
						{item.supplierIdentifier}
					</TextLinkButton>
				</TableCellX>
				<TableCellX align='right'>
					<div className='df-col-ae gap-2'>
						<span>{formatCurrency(item.priceExVat)}</span>
						<span style={{ fontSize: MinorFontSize, color: theme.palette.text.secondary }}>{`${strings.amountToPay}: ${formatCurrency(
							item.amountToPay
						)}`}</span>
						<PurchasePaymentStatusChip
							item={item}
							color={item.isFullyPaid ? 'success' : item.isOverDue ? 'warning' : 'info'}
						/>
					</div>
				</TableCellX>
				<TableCellX
					align='right'
					isLast>
					<div className='df-row-ac jc-e'>
						<ColorOnHoverIconButton
							size='small'
							edge='end'
							color='primary'
							onClick={onViewDetails}>
							<VisibilityOutlinedIcon />
						</ColorOnHoverIconButton>
					</div>
				</TableCellX>
			</TableRow>
			{(item.labels ?? []).length > 0 && (
				<TableDetailsRowX
					colSpan={5}
					onMouseEnter={enter}
					onMouseLeave={leave}
					style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
					<div
						className='df-row-wrap gap-4'
						style={{ paddingBottom: 12 }}>
						{item.labels.map(t => (
							<ChipX
								key={t}
								label={t}
								color='primary'
							/>
						))}
					</div>
				</TableDetailsRowX>
			)}
		</React.Fragment>
	);
};
