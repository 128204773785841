import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocalization } from 'localization/useLocalization';

interface IProps<TValue extends { id: string }> {
	label?: string;
	value: TValue | undefined;
	onChange: (val: TValue | undefined) => void;
	options: TValue[];
	renderValue: (val: TValue, index: number) => string | JSX.Element;
	style?: React.CSSProperties;
	className?: string;
	withNone?: boolean;
	variant?: 'standard' | 'outlined' | 'filled';
	size?: 'medium' | 'small';
}

export const SelectOnIdField = <TValue extends { id: string }>({
	label,
	value,
	onChange,
	options,
	renderValue,
	style,
	className,
	withNone = false,
	variant = 'filled',
	size = 'medium',
}: IProps<TValue>) => {
	const strings = useLocalization();
	const [selectedId, setSelectedId] = useState<string>('');

	useEffect(() => {
		if (value === undefined) {
			setSelectedId('');
		} else {
			setSelectedId(value.id);
		}
	}, [value]);

	return (
		<FormControl
			size={size}
			variant={variant}
			className={className}
			style={style}>
			{label && <InputLabel>{label}</InputLabel>}
			<Select
				value={selectedId}
				onChange={e => {
					const id = e.target.value as any;
					setSelectedId(id);
					onChange(options.find(t => t.id === id));
				}}>
				{withNone && (
					<MenuItem value={''}>
						<em>{strings.none.toLowerCase()}</em>
					</MenuItem>
				)}
				{options.map((t, index) => (
					<MenuItem
						key={t.id}
						value={t.id}>
						{renderValue(t, index)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
