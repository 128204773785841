import { CompanyExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createCompaniesExportRecord = (strings: IStrings): Record<CompanyExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		Name: strings.name,
		AddressLine: strings.addressLine,
		Zip: strings.zip,
		City: strings.city,
		Country: strings.country,
		FirstName: strings.firstName,
		LastName: strings.lastName,
		Salutation: strings.salutation,
		VatNumber: strings.vatNumber,
	};
};
