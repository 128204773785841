import { CardProps } from '@material-ui/core';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { calcRequestForInsuranceAllowanceDetailRoute } from 'app/main/processes/ProcessesRoutes';
import { CardX } from 'framework/components/cards/CardX';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISale } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { calcCustomerAccountDetailRoute } from '../../routes';

const Width: number = 280;

interface IProps extends CardProps {
	item: ISale;
	reload: VoidFunction;
}

export const SalesDetailOverviewHeaderCard = ({ item, reload, ...rest }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();

	return (
		<CardX
			{...rest}
			className='df-row-wrap gap-16'>
			<div
				className='df-col gap-6'
				style={{ width: Width }}>
				<Typography16pxBold>{strings.paymentDetails}</Typography16pxBold>
				<CaptionVerticalLabelledPropertyX label={strings.amountToPay}>{formatCurrency(item.amountToPay)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.amountPaid}>{formatCurrency(item.amountPaid)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.outstandingBalance}>{formatCurrency(item.outstandingBalance)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.structuredReference}>
					{item.defaultBelgianStructuredCreditorReference}
				</CaptionVerticalLabelledPropertyX>
			</div>
			{Boolean(item.recipient) && (
				<div
					className='df-col gap-6'
					style={{ width: Width }}>
					<Typography16pxBold>{strings.recipient}</Typography16pxBold>
					<LabelledPropertyX icon={<PersonOutlineIcon />}>{item.recipient.name}</LabelledPropertyX>
					{item.recipient.hasVatNumber && <LabelledPropertyX icon={<BusinessIcon />}>{item.recipient.vatNumber}</LabelledPropertyX>}
					{item.recipient.hasData && (
						<LabelledPropertyX icon={<HomeWorkOutlinedIcon />}>
							<span style={{ whiteSpace: 'pre' }}>{item.recipient.formattedAddressMultipleLines}</span>
						</LabelledPropertyX>
					)}
				</div>
			)}
			<div className='df-col gap-6'>
				<Typography16pxBold>{strings.links}</Typography16pxBold>
				{item.referencePatient && (
					<LabelledPropertyX
						icon={<PersonOutlineIcon />}
						link={() => push(calcPatientDetailRoute(item.referencePatient.id!))}>
						{strings.patient}
					</LabelledPropertyX>
				)}
				{item.cashSaleForPatient && (
					<LabelledPropertyX
						icon={<PersonOutlineIcon />}
						link={() => push(calcPatientDetailRoute(item.cashSaleForPatient.id!))}>
						{item.cashSaleForPatient.identifier}
					</LabelledPropertyX>
				)}
				{item.singleCustomerAccount && (
					<LabelledPropertyX
						icon={<AccountBalanceWalletOutlinedIcon />}
						link={() => push(calcCustomerAccountDetailRoute(item.singleCustomerAccount.customerAccountId!))}>
						{strings.accountReceivable}
					</LabelledPropertyX>
				)}
				{item.rfiaId && (
					<LabelledPropertyX
						icon={<DoubleArrowIcon />}
						link={() => push(calcRequestForInsuranceAllowanceDetailRoute(item.rfiaId!))}>
						{strings.requestForInsuranceAllowanceShort}
					</LabelledPropertyX>
				)}
			</div>
		</CardX>
	);
};
