import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export const FrameworkDefaultAxiosInstance = (apiUrl: string, config?: AxiosRequestConfig): AxiosInstance => {
	const instance = Axios.create({ baseURL: apiUrl, ...config });
	instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	return instance;
};

// for upload or download progress monitoring
// const config: AxiosRequestConfig = {
//     onUploadProgress: (e) => {
//         const percentCompleted = Math.round((e.loaded * 100) / e.total);
//     }
// }

// async transformOptions(options_: AxiosRequestConfig): Promise<AxiosRequestConfig> {
//     const token = getToken(this.getTokenName());
//     if (options_.url && options_.url.indexOf('/export') > -1) {
//         options_ = {...options_, responseType: 'blob'};
//     }
//     return token ? addAuthHeader(options_, token) : options_;
// }

// export const addAuthHeader = (options_: AxiosRequestConfig, token: string): AxiosRequestConfig => {
//     options_.headers.Authorization = `Bearer ${token}`;
//     return options_;
// }
