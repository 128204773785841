import React from 'react';
import { IAddressModel, ISupplier, suppliersCommand_patchAddress } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { AddressForm } from 'shared/address/AddressForm';

interface IProps {
	supplier: ISupplier;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

const toModel = (supplier: ISupplier): IAddressModel => {
	return {
		addressLine: supplier.addressLine ?? '',
		zip: supplier.zip ?? '',
		city: supplier.city ?? '',
		country: supplier.country,
	};
};

export const SupplierAddressForm = ({ supplier, confirm, cancel }: IProps) => {
	const strings = useLocalization();

	return (
		<AddressForm
			open
			submitFunction={model => suppliersCommand_patchAddress({ supplierId: supplier.id, address: model })}
			cancel={cancel}
			model={toModel(supplier)}
			successMessage={strings.addressUpdated}
			confirm={confirm}
		/>
	);
};
