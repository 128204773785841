import { Button, Card, CardMedia, Divider, Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim';
import { calcInventoryItemDetailRoute } from 'app/main/inventoryManagement/routes';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useTrigger } from 'framework/hooks/useTrigger';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { createType } from 'framework/utils/createType';
import { IInventoryItem, IInventoryItemFilterParams, InventoryItemExportProp, InventoryItemExportProps, inventoryItemsQuery_export } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { RecordContext } from 'shared/records/RecordContext';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsNavigateHeader } from 'shared/table/createDetailsNavigateHeader';
import { Filter } from './Filter';
import { InventoryItemsListPageHeader } from './InventoryItemsListPageHeader';
import { SelectableColumn } from './SelectableColumn';
import { InventoryItemChips } from './components/InventoryItemChips';
import { InventoryItemsFilterDataContext } from './context/InventoryItemsFilterDataContext';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';
import { InventoryItemForm } from './forms/InventoryItemForm';

const QuickFilters = createType('all', 'hearingInstrument');
export type QuickFilter = typeof QuickFilters[number];

export const InventoryItemsList = () => {
	const strings = useLocalization();
	const theme = useTheme();
	const [trigger, hitTrigger] = useTrigger();
	const { params, setParams, queryResult, reload } = useQueryContext(QueryContext, trigger);
	const [searchData] = useCacheContext(InventoryItemsFilterDataContext);
	const { inventoryItemExportRecord } = useContext(RecordContext);
	const { columns, setColumns, columnsRecord, headers, quickFilter, quickFilterRecord, setQuickFilter } = useContext(ViewContext);
	const hasVeppClaim = useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim();
	const exportProps = useMemo(
		() => (hasVeppClaim ? InventoryItemExportProps : InventoryItemExportProps.filter(t => t !== 'PurchaseListPrice' && t !== 'PurchasePrice')),
		[hasVeppClaim]
	);
	const exclusions: SelectableColumn[] = useMemo(() => (hasVeppClaim ? [] : ['purchasePrice', 'purchaseListPrice']), [hasVeppClaim]);
	const { open, confirm, cancel } = useDialogsContext(reload);

	if (queryResult === undefined || searchData === undefined) {
		return <div></div>;
	}

	const onDefine = () => {
		open(
			<InventoryItemForm
				confirm={confirm}
				cancel={cancel}
				goToDetails
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='InventoryItems'
			stickyHeader={
				<InventoryItemsListPageHeader
					params={params}
					queryResult={queryResult}
					reload={hitTrigger}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
				}}>
				<div className='df-row-ac jc-sb'>
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchVerb}
						style={{ width: '400px' }}
						variant='paper'
					/>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						onClick={onDefine}>
						{strings.addDefineInventoryItem}
					</Button>
				</div>
				{queryResult.unfilteredCount > 0 && (
					<Card>
						<div
							className='df-row-ac jc-sb'
							style={{ padding: theme.spacing(1) }}>
							<ButtonGroupWithSelection<QuickFilter>
								options={QuickFilters}
								selected={quickFilter}
								setSelected={setQuickFilter}
								renderOption={t => quickFilterRecord[t]}
							/>
							<div>
								<ExportButtonMenuAndDialog<IInventoryItemFilterParams, InventoryItemExportProp>
									params={params}
									filteredCount={queryResult.filteredCount}
									unfilteredCount={queryResult.unfilteredCount}
									downloadF={inventoryItemsQuery_export}
									fileName={strings.inventoryItems}
									record={inventoryItemExportRecord}
									allProps={exportProps}
									view='inventoryItems'
								/>
								<SelectColumnsButtonAndDialog
									selectedColumns={columns}
									setSelectedColumns={setColumns}
									record={columnsRecord}
									exclusions={exclusions}
									view='inventoryItems'
								/>
							</div>
						</div>
						<Divider />
						<div className='df-row-ac jc-sb'>
							<Filter
								params={params}
								onFilter={params => setParams(onResetToPage1(params))}
								paddingLeft={theme.spacing(1.5)}
								data={searchData}
								type={quickFilter}
							/>
						</div>
						<Divider />
						<CardMedia>
							<PageableTableWithColumnSelection<IInventoryItem, SelectableColumn>
								queryResult={queryResult}
								headers={headers}
								sortParams={params.sortParams}
								pageParams={params.pageParams}
								setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
								getKey={inst => inst.id}
								selectedColumns={columns}
								prependColumn={createEmptyHeader<IInventoryItem>('prepend', t => (
									<InventoryItemChips item={t} />
								))}
								appendColumn={createDetailsNavigateHeader<IInventoryItem>(calcInventoryItemDetailRoute)}
							/>
						</CardMedia>
					</Card>
				)}
				{queryResult.unfilteredCount === 0 && (
					<Typography
						variant='body2'
						style={{ fontStyle: 'italic' }}>
						{strings.noItemsInStockContent}
					</Typography>
				)}
			</div>
		</RestrictedPageX>
	);
};
