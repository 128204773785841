import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { createExportRecord } from '../../utils/createExportRecord';
import { AgendaViewContext } from './AgendaViewContext';
import { createAgendaTimeScaleRecord } from './createAgendaTimeScaleRecord';
import { createAgendaViewTypeRecord } from './createAgendaViewTypeRecord';

export const AgendaViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const agendaViewTypeRecord = useMemo(() => createAgendaViewTypeRecord(strings), [strings]);
	const agendaTimeScaleRecord = useMemo(() => createAgendaTimeScaleRecord(strings), [strings]);
	const exportRecord = useMemo(() => createExportRecord(strings), [strings]);

	return (
		<AgendaViewContext.Provider
			value={{
				agendaViewTypeRecord: agendaViewTypeRecord,
				exportRecord: exportRecord,
				agendaTimeScaleRecord: agendaTimeScaleRecord,
			}}>
			{children}
		</AgendaViewContext.Provider>
	);
};
