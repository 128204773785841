import { Button, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useLoggedInUserIsMinimalInRole } from 'app/auth/useLoggedInUserIsMinimalInRole';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { RegisterUserWithPasswordForm } from 'app/main/settings/users/forms/RegisterUserWithPasswordForm';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RestrictedContent } from 'shared/layout/RestrictedContent';
import { UserDetailsCard } from './UserDetailsCard';
import { UserListItemCardX } from './UserListItemCardX';
import { UsersListFilterCard } from './UsersListFilterCard';
import { UsersQueryContext } from './UsersQueryContext';
import { RegisterForm } from './forms/RegisterForm';

export const UsersTab = ({ reloadTrigger }: IReloadTriggerProps) => {
	const { reload, queryResult, params, setParams } = useQueryContext(UsersQueryContext);
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [expandedId, setExpandedId] = useState<string>();
	const expandedUser = useMemo(() => queryResult?.values.find(t => t.id === expandedId), [queryResult, expandedId]);
	// const user = useLoggedInUser();
	const canRegisterNewUser = useLoggedInUserIsMinimalInRole('Admin');
	const isSuperUser = useLoggedInUserIsSuperUser();

	const onRegister = () => {
		if (canRegisterNewUser === false) {
			open(
				<NotificationDialog
					open
					title={strings.restrictions}
					content={strings.onlyAdminUserCanPerformThisAction}
					close={cancel}
				/>
			);
		} else {
			open(
				<RegisterForm
					open
					cancel={cancel}
					confirm={confirm}
				/>
			);
		}
	};

	const onRegisterWithPassword = () => {
		if (isSuperUser) {
			open(
				<RegisterUserWithPasswordForm
					open
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	if (queryResult === undefined) {
		return <></>;
	}

	return (
		<RestrictedContent pageClaim='Users'>
			<div className='df-row gap-16'>
				<div className='df-col gap-16'>
					<Button
						variant='outlined'
						color='primary'
						startIcon={<AddIcon />}
						onClick={onRegister}
						style={{ borderRadius: 12 }}>
						{strings.new}
					</Button>
					{isSuperUser && (
						<Button
							variant='outlined'
							color='primary'
							startIcon={<AddIcon />}
							onClick={onRegisterWithPassword}
							style={{ borderRadius: 12 }}>
							{`Registreer met wachtwoord`}
						</Button>
					)}
					<UsersListFilterCard
						params={params}
						setParams={setParams}
					/>
				</div>
				<div className='df-col gap-8'>
					<SearchbarX
						placeholder={strings.searchVerb}
						value={params.searchString}
						onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
						// style={{ width: 500 }}
					/>
					{queryResult.values.map(t => (
						<UserListItemCardX
							key={t.id}
							item={t}
							isSelected={expandedId === t.id}
							toggle={() => setExpandedId(expandedId === t.id ? undefined : t.id)}
						/>
					))}
					<QueryResultPaginationX
						queryResult={queryResult}
						setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
						style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
					/>
				</div>
				{expandedUser !== undefined && (
					<>
						<Divider orientation='vertical' />
						<UserDetailsCard
							item={expandedUser}
							className='fg1 h-fc'
							reload={reload}
						/>
					</>
				)}
			</div>
		</RestrictedContent>
	);
};
