import { Dialog, DialogProps, Typography } from '@material-ui/core';
import LinkOffOutlinedIcon from '@material-ui/icons/LinkOffOutlined';
import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { formatDate } from 'framework/utils/date/formatDate';
import { Gender, LanguageOption, rosaPatientsCommand_pushToRosa, rosaPatientsCommand_unlink, rosaPatientsQuery_singleWithMemberEquality } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps extends DialogProps {
	hamsPatientId: string;
	rosaPatientId: string;
	close: VoidFunction;
}

export const RosaPatientWithMemberEqualityDialog = ({ rosaPatientId, hamsPatientId, close, ...rest }: IProps) => {
	const strings = useLocalization();
	const [item, reload] = useApiEffect(rosaPatientsQuery_singleWithMemberEquality, rosaPatientId);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);
	const { genderRecord, localizedLanguageRecord } = useContext(RecordContext);

	if (item === undefined) {
		return <div></div>;
	}

	const onRemoveLink = () => {
		open(
			<AlertDialog
				open
				title={strings.removeLinkBetweenRosaAndHamsPatient}
				content={strings.removeLinkFromWithRosaPatientQuestion}
				acceptText={strings.yesWhatExclamation(strings.continue)}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => rosaPatientsCommand_unlink({ rosaPatientRecordId: rosaPatientId })}
				accept={() => {
					cancel();
					close();
				}}
			/>
		);
	};

	const onPublishDataToRosa = () => {
		open(
			<AlertDialog
				open
				title={strings.pushPatientDataToRosa}
				content={strings.doYouWantToPushPatientDataToRosaQuestion}
				acceptText={strings.yesWhatExclamation(strings.continue)}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => rosaPatientsCommand_pushToRosa({ rosaPatientRecordId: rosaPatientId, hamsPatientId: hamsPatientId })}
				accept={confirm}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			onClose={close}
			fullWidth>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<PersonPinOutlinedIcon />}>
					<Typography variant='h1'>{strings.rosaLinkedPatientRecord}</Typography>
					{item.isMergedTo && (
						<LightBulbNotification style={{ marginTop: 16 }}>
							<div>{strings.thisPatientIsMergedToAnotherPatient}</div>
						</LightBulbNotification>
					)}
				</DialogGridTitle>
				{item.isMergedTo === false && (
					<>
						<GridDividerWithCaption caption={strings.properties} />
						<PropertyInGridX
							label={strings.firstName}
							value={item.rosaData.firstName}
							isEqual={item.isFirstNameEqual}
						/>
						<PropertyInGridX
							label={strings.lastName}
							value={item.rosaData.lastName}
							isEqual={item.isLastNameEqual}
						/>
						<PropertyInGridX
							label={strings.socialSecurityNumber}
							value={item.rosaData.formattedSocialSecurityNumber}
							isEqual={item.isSocialSecurityNumberEqual}
						/>
						<PropertyInGridX
							label={strings.phone}
							value={item.rosaData.primaryPhoneNumber}
							isEqual={item.isPrimaryPhoneNumberEqual}
						/>
						<PropertyInGridX
							label={strings.email}
							value={item.rosaData.primaryEmail}
							isEqual={item.isPrimaryEmailEqual}
						/>
						<PropertyInGridX
							label={strings.gender}
							value={genderRecord[item.rosaData.gender as Gender]}
							isEqual={item.isGenderEqual}
						/>
						<PropertyInGridX
							label={strings.dateOfBirth}
							value={formatDate(item.rosaData.dateOfBirth)}
							isEqual={item.isDateOfBirthEqual}
						/>
						<PropertyInGridX
							label={strings.address}
							value={item.rosaData.formattedAddress}
							isEqual={item.isAddressEqual}
						/>
						<PropertyInGridX
							label={strings.language}
							value={localizedLanguageRecord[item.rosaData.language as LanguageOption]}
							isEqual={item.isLanguageEqual}
						/>
					</>
				)}
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								startIcon={<PublishOutlinedIcon />}
								onClick={onPublishDataToRosa}
								disabled={item.areAllMembersEqual || item.isMergedTo}>
								{strings.pushDataToRosa}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								startIcon={<LinkOffOutlinedIcon />}
								onClick={onRemoveLink}
								color='secondary'
								disabled={item.isMergedTo}>
								{strings.removeLink}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};

interface IPropertyInGridXProps {
	label: string;
	value: string | undefined;
	isEqual: boolean;
}

const PropertyInGridX = ({ label, value, isEqual }: IPropertyInGridXProps) => {
	const strings = useLocalization();
	const isEmpty = useMemo(() => value === undefined || value === '', [value]);

	if (isEmpty && isEqual) {
		return <></>;
	}

	return (
		<PropertyInGrid
			label={label}
			value={
				<div className='df-row-ac'>
					<div style={{ marginRight: 4 }}>{value}</div>
					{isEqual && (
						<StatusIcon
							status='success'
							fontSize='small'
						/>
					)}
					{isEqual === false && (
						<TooltipWithSpan title={strings.propertyIsNotEqual(label)}>
							<StatusIcon
								status='error'
								fontSize='small'
							/>
						</TooltipWithSpan>
					)}
				</div>
			}
		/>
	);
};
