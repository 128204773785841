import { DialogProps } from '@material-ui/core';
import React from 'react';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IPatient, patientsCommand_updateAttendingEntPhysician } from 'gen/ApiClient';
import { SelectEntPhysicianForm } from '../../contacts/earNoseThroatPhysicians/forms/SelectEntPhysicianForm';

interface IProps extends DialogProps {
	item: IPatient;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LinkEntPhysicianWithPatientForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [link, isSubmitting] = useFormSubmit(patientsCommand_updateAttendingEntPhysician);

	const onConfirm = async (id: string, setError: (error: string) => void) => {
		handleValidateResponseBase(await link(item.id, id), setError, confirm);
	};

	return (
		<SelectEntPhysicianForm
			{...rest}
			confirm={onConfirm}
			cancel={cancel}
			initialId={item.attendingEntPhysicianId}
			isSubmitting={isSubmitting}
		/>
	);
};
