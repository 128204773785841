import { CardProps, Divider } from '@material-ui/core';
import SettingsRemoteOutlinedIcon from '@material-ui/icons/SettingsRemoteOutlined';
import SyncIcon from '@material-ui/icons/Sync';
import { CodeBlock } from 'app/main/test/CodeBlock';
import { CollapseX } from 'framework/components/CollapseX';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { formatDate } from 'framework/utils/date/formatDate';
import {
	IPushPatientsToRosaResponse,
	rosaSyncCommand_calendars,
	rosaSyncCommand_motives,
	rosaSyncCommand_pullEvents,
	rosaSyncCommand_pullPatients,
	rosaSyncCommand_pushPatients,
	rosaSyncCommand_sites,
	rosaSyncQuery_status,
} from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { PatchRosaHamsSyncRecordForm } from './PatchRosaHamsSyncRecordForm';

interface IProps extends IReloadTriggerProps, CardProps {
	reload: VoidFunction;
}

export const RosaAdminSyncCard = ({ reloadTrigger, reload, ...rest }: IProps) => {
	const [response, reloadResponse] = useApiEffect(rosaSyncQuery_status);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [pushPatientsResponse, setPushPatientsResponse] = React.useState<IPushPatientsToRosaResponse>();

	useLazyEffect(() => {
		reloadResponse();
	}, [reloadTrigger]);

	const onSyncMotives = () => {
		open(
			<AlertDialog
				open
				title={`Sync motives`}
				content={`Do you want to sync motives?`}
				acceptText={`Yes!`}
				rejectText={`No, go back`}
				reject={cancel}
				acceptF={() => rosaSyncCommand_motives()}
				accept={confirm}
			/>
		);
	};

	const onSyncCalendars = () => {
		open(
			<AlertDialog
				open
				title={`Sync calendars`}
				content={`Do you want to sync calendars?`}
				acceptText={`Yes!`}
				rejectText={`No, go back`}
				reject={cancel}
				acceptF={() => rosaSyncCommand_calendars()}
				accept={confirm}
			/>
		);
	};

	const onSyncSites = () => {
		open(
			<AlertDialog
				open
				title={`Sync sites`}
				content={`Do you want to sync sites?`}
				acceptText={`Yes!`}
				rejectText={`No, go back`}
				reject={cancel}
				acceptF={() => rosaSyncCommand_sites()}
				accept={confirm}
			/>
		);
	};

	const onPushPatients = () => {
		open(
			<AlertDialog
				open
				title={`Push patients (HAMS -> ROSA)`}
				content={`Do you want to push patients?`}
				acceptText={`Yes!`}
				rejectText={`No, go back`}
				reject={cancel}
				accept={async () => {
					confirm();
					const r = await rosaSyncCommand_pushPatients();
					if (r.isSuccess) {
						setPushPatientsResponse(r.result);
						reload();
					}
				}}
			/>
		);
	};

	const onPullPatients = () => {
		open(
			<AlertDialog
				open
				title={`Pull patients (ROSA -> HAMS)`}
				content={`Do you want to pull patients?`}
				acceptText={`Yes!`}
				rejectText={`No, go back`}
				reject={cancel}
				acceptF={() => rosaSyncCommand_pullPatients()}
				accept={confirm}
			/>
		);
	};

	const onPullEvents = () => {
		open(
			<AlertDialog
				open
				title={`Pull events (ROSA -> HAMS)`}
				content={`Do you want to pull events?`}
				acceptText={`Yes!`}
				rejectText={`No, go back`}
				reject={cancel}
				acceptF={() => rosaSyncCommand_pullEvents()}
				accept={confirm}
			/>
		);
	};

	const onPatchSyncRecord = () => {
		open(
			<PatchRosaHamsSyncRecordForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	if (response === undefined) {
		return <></>;
	}

	return (
		<CardX
			{...rest}
			className='df-col gap-8'>
			<Typography16pxBold>{`Sync status (only visible for super-users!)`}</Typography16pxBold>
			{response.hasStatus === false && <div>{`loading or error fetching status...`}</div>}
			{response.hasStatus === true && response.status && (
				<>
					<VariantLabelledPropertyX
						iconPosition='start'
						variant={response.status.hasNotSyncedMotiveIds ? 'error' : 'success'}>
						{`Motives (ROSA: ${response.status.motiveIdsOnRosa.length} | HAMS: ${response.status.motiveIdsLocal.length})`}
					</VariantLabelledPropertyX>
					<VariantLabelledPropertyX
						iconPosition='start'
						variant={response.status.hasNotSyncedCalendarIds ? 'error' : 'success'}>
						{`Calendars (ROSA: ${response.status.calendarIdsOnRosa.length} | HAMS: ${response.status.calendarIdsLocal.length})`}
					</VariantLabelledPropertyX>
					<VariantLabelledPropertyX
						iconPosition='start'
						variant={response.status.hasNotSyncedSiteIds ? 'error' : 'success'}>
						{`Sites (ROSA: ${response.status.siteIdsOnRosa.length} | HAMS: ${response.status.siteIdsLocal.length})`}
					</VariantLabelledPropertyX>
					<Divider />
					<div className='df-row-ac gap-8'>
						<TextLinkButton
							color='primary'
							startIcon={<SyncIcon />}
							onClick={onSyncMotives}>
							{`Sync motives`}
						</TextLinkButton>
						<Divider orientation='vertical' />
						<TextLinkButton
							color='primary'
							startIcon={<SyncIcon />}
							onClick={onSyncCalendars}>
							{`Sync calendars`}
						</TextLinkButton>
						<Divider orientation='vertical' />
						<TextLinkButton
							color='primary'
							startIcon={<SyncIcon />}
							onClick={onSyncSites}>
							{`Sync sites`}
						</TextLinkButton>
					</div>
					<Divider />
					<VariantLabelledPropertyX
						iconPosition='start'
						variant={response.status.patientsSyncStatus.areSynced ? 'success' : 'error'}>
						<div className='df-col'>
							<span>{`Patients in ROSA: ${response.status.patientsSyncStatus.countInRosa}`}</span>
							<span>{`RosaPatientRecords in HAMS: ${response.status.patientsSyncStatus.countRosaPatientRecordsInHams}`}</span>
							<span>{`Patients in HAMS: ${response.status.patientsSyncStatus.countPatientsInHams}`}</span>
						</div>
					</VariantLabelledPropertyX>
					<VariantLabelledPropertyX
						iconPosition='start'
						variant={response.status.eventsSyncStatus.areSynced ? 'success' : 'error'}>
						<div className='df-col'>
							<span>{`Events in ROSA: ${response.status.eventsSyncStatus.countInRosa}`}</span>
							<span>{`Event-records in HAMS: ${response.status.eventsSyncStatus.countInHams}`}</span>
						</div>
					</VariantLabelledPropertyX>
					<Divider />
					<div className='df-row-ac gap-8'>
						<TextLinkButton
							color='primary'
							startIcon={<SyncIcon />}
							onClick={onPullPatients}>
							{`Pull patients (ROSA -> HAMS)`}
						</TextLinkButton>
						<Divider orientation='vertical' />
						<TextLinkButton
							color='primary'
							startIcon={<SyncIcon />}
							onClick={onPullEvents}>
							{`Pull events (ROSA -> HAMS)`}
						</TextLinkButton>
						<Divider orientation='vertical' />
						<TextLinkButton
							color='primary'
							startIcon={<SyncIcon />}
							onClick={onPushPatients}>
							{`Push patients (HAMS -> ROSA)`}
						</TextLinkButton>
					</div>
					{pushPatientsResponse && (
						<>
							<Divider />
							<CollapseX
								collapseAfter
								expandByDefault>
								<CodeBlock
									value={pushPatientsResponse}
									style={{ margin: 0 }}
								/>
							</CollapseX>
						</>
					)}
					<Divider />
				</>
			)}
			<VariantLabelledPropertyX
				iconPosition='start'
				variant={response.hasSyncRecord ? 'success' : 'error'}
				label={`Patients last updated since`}>
				{formatDate(response.syncRecord?.patientLastUpdatedSince, 'date-and-time')}
			</VariantLabelledPropertyX>
			<VariantLabelledPropertyX
				iconPosition='start'
				variant={response.hasSyncRecord ? 'success' : 'error'}
				label={`Events last updated since`}>
				{formatDate(response.syncRecord?.eventsLastUpdatedSince, 'date-and-time')}
			</VariantLabelledPropertyX>
			<VariantLabelledPropertyX
				iconPosition='start'
				variant={response.hasSyncRecord ? 'success' : 'error'}
				label={`Last synced`}>
				{formatDate(response.syncRecord?.lastSyncedDate, 'date-and-time')}
			</VariantLabelledPropertyX>
			<Divider />
			<div className='df-row-ac gap-8'>
				<TextLinkButton
					color='primary'
					startIcon={<SettingsRemoteOutlinedIcon />}
					onClick={onPatchSyncRecord}>
					{`Patch sync record`}
				</TextLinkButton>
			</div>
			<Divider />
			<CollapseX collapseAfter>
				<CodeBlock
					value={response}
					style={{ margin: 0 }}
				/>
			</CollapseX>
		</CardX>
	);
};
