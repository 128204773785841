import React from 'react';
import { CacheProvider } from 'framework/context/CacheProvider';
import { entPhysiciansQuery_concurrencyToken, entPhysiciansQuery_searchData, IEntPhysiciansSearchData } from 'gen/ApiClient';
import { EntPhysiciansFilterDataContext } from './EntPhysiciansFilterDataContext';

export const EntPhysiciansFilterDataProvider = ({ children }: any) => {
	return (
		<CacheProvider<IEntPhysiciansSearchData>
			concurrencyF={entPhysiciansQuery_concurrencyToken}
			loadF={entPhysiciansQuery_searchData}
			context={EntPhysiciansFilterDataContext}>
			{children}
		</CacheProvider>
	);
};
