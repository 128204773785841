import { IStrings } from 'localization/IStrings';
import { DeliveriesSelectableColumn } from './DeliveriesSelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<DeliveriesSelectableColumn, string> => {
	return {
		created: strings.createdAt,
		lastUpdated: strings.lastUpdated,
		countItems: `# ${strings.items}`,
		reference: strings.referenceShort,
		externalReference: strings.shortExternalReference,
		deliveryDate: strings.deliveryDate,
		supplierIdentifier: strings.supplier,
		locationIdentifier: strings.location,
		dispatchDate: strings.dispatchDate,
		dispatchReference: strings.dispatchReference,
	};
};
