import { DialogProps } from '@material-ui/core';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { IPurchase, purchasesCommand_patchLabels } from 'gen/ApiClient';
import React from 'react';
import { LabelsForm } from 'shared/LabelsForm';
import { PurchasesFilterDataContext } from '../PurchasesFilterDataContext';

interface IProps extends DialogProps {
	item: IPurchase;
	cancel: VoidFunction;
	confirm: VoidFunction;
}

export const PurchaseLabelsForm = ({ item, ...rest }: IProps) => {
	const [data] = useCacheContext(PurchasesFilterDataContext);

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<LabelsForm
			{...rest}
			autoCompleteLabels={data.labels}
			labels={item.labels}
			updateF={values => purchasesCommand_patchLabels({ purchaseId: item.id, labels: values })}
		/>
	);
};
