import { DialogProps } from '@material-ui/core';
import React, { useState } from 'react';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IMissingOrManuallyChangesDocumentsResponse, OriginalOrLatestDocumentExportMode } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { SelectOriginalOrLatestDocumentExportModeDialog } from 'shared/SelectOriginalOrLatestDocumentExportModeDialog';

type State = 'showWarning' | 'selectMode';

interface IProps extends DialogProps {
	documentsResponse: IMissingOrManuallyChangesDocumentsResponse;
	confirm: (mode: OriginalOrLatestDocumentExportMode) => void;
	cancel: VoidFunction;
}

export const NonCashSalesExportWarningDialog = ({ documentsResponse, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const [state, setState] = useState<State>('showWarning');

	const onConfirmWarning = () => {
		if (documentsResponse.hasReferencesWithManuallyChangedDocument) {
			setState('selectMode');
		} else {
			confirm('OriginalDocument');
		}
	};

	if (state === 'showWarning') {
		return (
			<AlertDialog
				{...rest}
				title={strings.checkDocuments}
				content={
					<>
						{documentsResponse.hasReferencesWithoutDocument && (
							<>
								<div>
									<b>{strings.noDocumentHasBeenCreatedForFollowingSales}</b>
								</div>
								{documentsResponse.referencesWithoutDocument.map(t => (
									<div key={t}>{t}</div>
								))}
							</>
						)}
						{documentsResponse.hasReferencesWithManuallyChangedDocument && (
							<>
								<div>
									<b>{strings.followingSalesHaveManualAdditionsToTheDocument}</b>
								</div>
								{documentsResponse.referencesWithManuallyChangedDocument.map(t => (
									<div key={t}>{t}</div>
								))}
							</>
						)}
					</>
				}
				acceptText={strings.continue}
				rejectText={strings.cancel}
				accept={onConfirmWarning}
				reject={cancel}
			/>
		);
	} else {
		return (
			<SelectOriginalOrLatestDocumentExportModeDialog
				{...rest}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	}
};
