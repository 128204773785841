import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, useTheme } from '@material-ui/core';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined';
import { LocationToggleIsPlannableDialog } from 'app/main/settings/locations/forms/LocationToggleIsPlannableDialog';
import { CollapseX } from 'framework/components/CollapseX';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { splitArray } from 'framework/utils/array/splitArray';
import { ILocationSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { LocationsPreloadCacheContext } from '../../../settings/locations/LocationsPreloadCacheContext';
import { useLoggedInUserIsMinimalInRole } from 'app/auth/useLoggedInUserIsMinimalInRole';

export const LocationsList = () => {
	const theme = useTheme();
	const strings = useLocalization();
	const [locations, reload] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [first3, other] = useMemo(() => splitArray(locations, 3), [locations]);
	const canEdit = useLoggedInUserIsMinimalInRole('Admin');

	const onToggleIsPlannable = (location: ILocationSummary) => {
		if (canEdit) {
			open(
				<LocationToggleIsPlannableDialog
					open
					location={location}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	return (
		<>
			{locations && (
				<div style={{ marginBottom: theme.spacing(4) }}>
					<DividerWithCaption
						caption={strings.plannableLocations}
						captionStyle={{ fontWeight: 500, color: 'black' }}
					/>
					<List
						dense
						disablePadding>
						{first3.map(t => (
							<SingleItem
								key={t.id}
								location={t}
								onToggleIsPlannable={() => onToggleIsPlannable(t)}
							/>
						))}
						{other.length > 0 && (
							<CollapseX style={{ marginLeft: 10 }}>
								{other.map(t => (
									<SingleItem
										key={t.id}
										location={t}
										onToggleIsPlannable={() => onToggleIsPlannable(t)}
									/>
								))}
							</CollapseX>
						)}
					</List>
				</div>
			)}
		</>
	);
};

interface ISingleItemProps {
	location: ILocationSummary;
	onToggleIsPlannable: VoidFunction;
}

const SingleItem = ({ location, onToggleIsPlannable }: ISingleItemProps) => {
	const strings = useLocalization();

	return (
		<ListItem
			key={location.id}
			disableGutters
			style={{ marginLeft: 16 }}>
			<ListItemText
				primary={location.identifier}
				secondary={`# ${strings.rooms}: ${location.countRooms} (${location.isPlannable ? strings.plannable : strings.notPlannable})`}
			/>
			<ListItemSecondaryAction>
				{location.isPlannable === false && (
					<IconButton
						edge='end'
						color='primary'>
						<EventAvailableOutlinedIcon />
					</IconButton>
				)}
				{location.isPlannable === true && (
					<IconButton
						edge='end'
						color='secondary'>
						<EventBusyOutlinedIcon />
					</IconButton>
				)}
			</ListItemSecondaryAction>
		</ListItem>
	);
};
