import BlurCircularOutlinedIcon from '@material-ui/icons/BlurCircularOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { IHealthInsuranceFundWithAddressDto } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: IHealthInsuranceFundWithAddressDto;
	style?: React.CSSProperties;
}

export const HifListItemStatusIcon = ({ item, style }: IProps) => {
	if (item.hasMetaData) {
		return <ErrorOutlineOutlinedIcon style={style} />;
	} else {
		return <BlurCircularOutlinedIcon style={style} />;
	}
};
