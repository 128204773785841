import React, { useState } from 'react';
import { IPatientSearch } from 'gen/ApiClient';
import { PatientSearchContext } from './PatientSearchContext';

export const PatientSearchProvider = ({ children }: any) => {
	const [searchString, setSearchString] = useState<string>('');
	const [results, setResults] = useState<IPatientSearch[]>([]);
	const [selected, setSelected] = useState<IPatientSearch>();

	return (
		<PatientSearchContext.Provider
			value={{
				searchString: searchString,
				setSearchString: setSearchString,
				results: results,
				setResults: setResults,
				selected: selected,
				setSelected: setSelected,
			}}>
			{children}
		</PatientSearchContext.Provider>
	);
};
