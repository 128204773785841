import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React from 'react';
import { AutocompleteCheckbox } from './AutocompleteCheckbox';
import { AutocompleteChip } from './AutocompleteChip';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	disabled?: boolean;
	label: string;
	options: string[];
	selected: string[];
	setSelected: (val: string[]) => void;
	style?: React.CSSProperties;
}

export const AutocompleteMultipleFreeSolo = ({ label, options, selected, setSelected, disabled = false, style }: IProps) => {
	const strings = useLocalization();

	return (
		<Autocomplete
			style={style}
			multiple
			options={options}
			value={selected}
			forcePopupIcon
			onChange={(_, value, reason) => {
				if (reason === 'create-option') {
					setSelected([...(value.slice(0, -1) as any), value.pop()]);
				} else {
					setSelected(value ? (value as any) : []);
				}
			}}
			disableCloseOnSelect
			freeSolo
			clearOnBlur
			filterOptions={(options, params) => {
				const filtered = createFilterOptions<string>()(options, params);
				if (params.inputValue !== '' && filtered.length === 0) {
					return [...filtered, params.inputValue];
				} else {
					return filtered;
				}
			}}
			disabled={disabled}
			renderTags={(tagValue, getTagProps) =>
				tagValue.map((option, index) => (
					<AutocompleteChip
						key={index}
						label={option}
						getTagProps={getTagProps}
						index={index}
					/>
				))
			}
			renderInput={params => (
				<TextField
					{...params}
					variant='filled'
					label={label}
				/>
			)}
			renderOption={(option, { selected }) => (
				<AutocompleteCheckbox
					selected={selected}
					text={options.indexOf(option) > -1 ? option : strings.addWhat(`"${option}"`)}
				/>
			)}
		/>
	);
};

// FOR LATER -> implementation with an object iso of string
// export const AutocompleteMultipleFreeSolo = <TValue extends unknown>({ label, options, selected, setSelected, add, selector, disabled = false, style }: IProps<TValue>) => {
//     const strings = useLocalization();

//         <Autocomplete
//             style={style}
//             multiple
//             options={options}
//             value={selected}
//             forcePopupIcon
//             onChange={(_, value, reason) => {
//                 if (reason === 'create-option') {
//                     setSelected([...value.slice(0, -1) as any, add(value.pop() as any)])
//                 } else {
//                     setSelected(value ? value as any : [])
//                 }
//             }}
//             disableCloseOnSelect
//             freeSolo
//             filterOptions={(options, params) => {
//                 const filtered = createFilterOptions<TValue>()(options, params);
//                 if (params.inputValue !== '' && filtered.length === 0) {
//                     return [...filtered, add(params.inputValue)]
//                 } else {
//                     return filtered;
//                 }
//             }}
//             getOptionLabel={(option) => selector(option)}
//             disabled={disabled}
//             renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => <AutocompleteChip key={index} label={selector(option)} getTagProps={getTagProps} index={index} />)}
//             renderInput={(params) => <TextField {...params} variant="filled" label={label} />}
//             renderOption={(option, { selected }) =>
//                 <AutocompleteCheckbox selected={selected} text={options.indexOf(option) > -1 ? selector(option) : strings.addWhat(`"${selector(option)}"`)} />
//             } />
//     );
// }
