import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import React from 'react';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IModuleSummary, IModuleSummaryWithIcon, launch_WithSpecificModuleAndAction } from 'gen/client/HamsClient';
import { useHandleClientReponse } from 'gen/client/useHandleClientReponse';
import { useLocalization } from 'localization/useLocalization';
import { Modules } from './Modules';

interface IProps extends DialogProps {
	modules: IModuleSummary[];
	patientIndex: number;
	actionIndex: number;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const SelectModuleDialog = ({ modules, patientIndex, actionIndex, confirm, cancel, ...rest }: IProps) => {
	const theme = useTheme();
	const [launch] = useFormSubmit(launch_WithSpecificModuleAndAction);
	const handle = useHandleClientReponse();
	const strings = useLocalization();

	const onStartModule = async (module: IModuleSummaryWithIcon) => {
		const r = await launch(module.moduleId ?? 0, patientIndex, actionIndex);
		if (r.isSuccess) {
			handle(r.result, `${module.name} ${strings.started}`);
			confirm();
		}
	};

	return (
		<Dialog
			{...rest}
			onClose={cancel}
			open={rest.open}
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={cancel}>
				<DialogGridTitle icon={<DescriptionIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{strings.selectAModule}
					</Typography>
					<Typography variant='caption'>{strings.multipleModulesCanOpenThisActionSelectOne}</Typography>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.modules} />
				<Modules
					moduleIds={modules.map(t => t.moduleId ?? 0)}
					onSelect={onStartModule}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
