import React from 'react';
import { IQuickFilterContext } from 'framework/IQuickFilterContext';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { IPayment } from 'gen/ApiClient';
import { QuickFilter } from '../PaymentsList';
import { SelectableColumn } from '../SelectableColumn';

interface IViewContext extends ITableViewContext<SelectableColumn, IPayment>, IQuickFilterContext<QuickFilter> {}

export const ViewContext = React.createContext<IViewContext>(undefined as any);
