import { useTheme } from '@material-ui/core';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { LineErrorComponent } from 'shared/searchAndSelect/LineErrorComponent';
import { IArticlePropertyLine } from './IArticlePropertyLine';
import { createHeaders } from './utils/createHeaders';

interface IProps {
	lines: IArticlePropertyLine[];
	setLines: React.Dispatch<React.SetStateAction<IArticlePropertyLine[]>>;
}

export const ArticlePropertiesTable = ({ lines, setLines }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const language = useCurrentLanguage();
	const headers = useMemo(() => createHeaders(lines, setLines, strings, language), [lines, setLines, strings, language]);

	return (
		<TableWithHeadersAndValues<IArticlePropertyLine>
			padding={theme.spacing(1)}
			headers={headers}
			getKey={t => t.apk.key!}
			values={lines}
			expandableContent={t => (
				<LineErrorComponent
					line={t}
					style={{ marginLeft: '10px' }}
				/>
			)}
			expandAll={true}
		/>
	);
};
