import { ISale, ISalesLine } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { IHandoverLine } from '../IHandoverLine';

export const advanceInvoiceToLine = (t: ISalesLine, sale: ISale, strings: IStrings): IHandoverLine => {
	return {
		isSelected: true,
		id: t.id,
		description: `${strings.advanceInvoice} ${sale.reference}`,
		quantity: t.quantity,
		unitListPrice: t.unitPriceInVat * -1,
		unitPrice: t.unitPriceInVat * -1,
		vatPercentage: t.vatPercentage,
		inventoryItemId: undefined,
		productCatalogItem: undefined,
		inventoryItem: undefined,
		isRefundable: false,
		manufacturer: '',
		model: '',
		productCatalogItemId: undefined,
		rizivHearingAidDefinitionV2IdentificationCode: undefined,
		serialNumber: '',
		advanceInvoiceId: sale.id,
		isIncludeOnHandoverCertificate: false,
		assuredInventoryItemId: undefined,
		assuredLineId: undefined,
		assuredProductCatalogItemId: undefined,
		canChangeQuantity: false,
		stockAtLocations: undefined,
		isCredit: false,
	};
};
