import { useCallback } from 'react';
import { useApiCall } from 'framework/hooks/useApiCall';
import { apiTokensCommand_requestOrRenew } from 'gen/ApiClient';
import { configuration_Set, info_Info, IResponse } from 'gen/client/HamsClient';

export function useAutoFixCredentials() {
	const getClientInfo = useApiCall(info_Info);
	const requestOrRenewToken = useApiCall(apiTokensCommand_requestOrRenew);
	const setTokenOnClient = useApiCall(configuration_Set);

	const requestOrRenewCred = async (): Promise<IResponse | undefined> => {
		console.log('auto fix cred');
		const rInfo = await getClientInfo();
		if (rInfo.isSuccess) {
			const rGet = await requestOrRenewToken({ clientId: rInfo.result.clientId ?? '', computerName: rInfo.result.machineName ?? '' });
			if (rGet.isSuccess) {
				const rSet = await setTokenOnClient({ apiKey: rGet.result.apiKey, clientId: rGet.result.clientId, url: rGet.result.url });
				if (rSet.isSuccess) {
					return rSet.result;
				}
			}
		}
		return undefined;
	};

	return useCallback(requestOrRenewCred, []);
}
