import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IQueryResult, IEntPhysiciansQueryParams, IEntPhysician, entPhysiciansQuery_concurrencyToken, entPhysiciansQuery_query } from 'gen/ApiClient';
import { entPhysiciansQueryParamsLsKey } from '../../../localStorageKeys';
import { EntPhysiciansQueryContext } from './EntPhysiciansQueryContext';

export const DefaultParams: IEntPhysiciansQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'id' },
	pageParams: { index: 1, size: 10 },
	zips: [],
	gender: undefined,
	hasOffices: undefined,
	hasRequestsToInsuranceAllowance: undefined,
	createdAfter: undefined,
	createdBefore: undefined,
	showDeleted: false,
};

export const EntPhysiciansQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IEntPhysician>, IEntPhysiciansQueryParams>
			defaultParams={DefaultParams}
			queryF={entPhysiciansQuery_query}
			concurrencyF={entPhysiciansQuery_concurrencyToken}
			context={EntPhysiciansQueryContext}
			localStorageKey={entPhysiciansQueryParamsLsKey}>
			{children}
		</FrameworkQueryProvider>
	);
};
