import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { rfiaSettingsCommand_patchDefaultThirdPartyScheme } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	value: boolean;
}

export const DefaultThirdPartySchemeForm = ({ confirm, cancel, value, ...rest }: IProps) => {
	const strings = useLocalization();
	const [update, isSubmitting] = useFormSubmit(rfiaSettingsCommand_patchDefaultThirdPartyScheme);
	const [defaultApply, setDefaultApply] = useState<boolean>(value);

	const onSubmit = async () => {
		const r = await update({ defaultApply: defaultApply });
		if (r.isSuccess) {
			confirm();
		}
	};

	return (
		<Dialog
			scroll='paper'
			fullWidth
			{...rest}>
			<DialogTitle>{strings.defaultWhat(strings.thirdPayerArrangement)}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<ExtendedRadioGroup<boolean>
					selected={defaultApply}
					setSelected={setDefaultApply}
					options={[
						{ value: true, label: strings.withWhat(strings.thirdPayerArrangement), caption: strings.withThirdPayerArrangementCaption },
						{ value: false, label: strings.withoutWhat(strings.thirdPayerArrangement), caption: strings.withoutThirdPayerArrangementCaption },
					]}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				onSubmit={onSubmit}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
			/>
		</Dialog>
	);
};
