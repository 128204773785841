import React from 'react';
import { PatientDetailTabType } from './PatientDetailTabType';

interface IContext {
	selectedTab: PatientDetailTabType;
	setSelectedTab: React.Dispatch<React.SetStateAction<PatientDetailTabType>>;
	patientId: string;
	setPatientId: React.Dispatch<React.SetStateAction<string>>;
}

export const PatientDetailPageViewContext = React.createContext<IContext>(undefined as any);
