import { Dialog, DialogContent, DialogProps, Typography } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	IInventoryItem,
	inventoryItemsQuery_single,
	IPurchaseFromInventoryItemModel,
	purchasesCommand_newFromInventoryItem,
	purchasesQuery_nextReference,
	purchasesQuery_settings,
	ValidUntilTimeUnits,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState, useEffect } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { FormSelectOrCreateSupplierField } from '../../../../contacts/suppliers/forms/FormSelectOrCreateSupplierField';
import { SearchProvider } from '../context/SearchProvider';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPurchaseFromInventoryItemModel>({
			validUntil: yup.date().required(),
			reference: yup.string().required(),
			externalReference: yup.string(),
			isAutoSelectReference: yup.boolean().defined(),
			supplierId: yup.string().required(),
			inventoryItemId: yup.string().required(),
			unitListPriceExVat: yup.number().defined(),
			unitPriceExVat: yup.number().defined(),
			vatPercentage: yup.number().defined(),
			purchaseDate: yup.date().required(),
		})
		.defined();
};

const EmptyValues: IPurchaseFromInventoryItemModel = {
	reference: '',
	isAutoSelectReference: true,
	validUntil: undefined as any,
	supplierId: '',
	inventoryItemId: '',
	unitListPriceExVat: 0,
	unitPriceExVat: 0,
	vatPercentage: 6,
	purchaseDate: undefined as any,
	externalReference: '',
};

const stepsRecord: Record<number, (keyof IPurchaseFromInventoryItemModel)[]> = {
	0: ['supplierId'],
	1: ['reference', 'isAutoSelectReference', 'externalReference', 'purchaseDate', 'validUntil'],
	2: ['unitPriceExVat'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	inventoryItemId: string;
}

export const PurchaseFromInventoryItemForm = ({ confirm, cancel, inventoryItemId, ...rest }: IProps) => {
	const strings = useLocalization();
	const [step, setStep] = useState<number>(0);
	const schema = useMemo(() => createSchema(strings), [strings]);
	const [create, isSubmitting] = useFormSubmit(purchasesCommand_newFromInventoryItem);
	const [inventoryItem] = useApiEffect(inventoryItemsQuery_single, inventoryItemId);
	const [isShiftVat, setIsShiftVat] = useState<boolean>(false);
	const notify = useSnackbarNotify();
	const now = useNow();

	const handleSubmit = async (values: IPurchaseFromInventoryItemModel, helpers: FormikHelpers<IPurchaseFromInventoryItemModel>) => {
		const r = await create({ ...values });
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.registeredWhat(strings.purchase));
			confirm(r.result.objectId);
		}
	};

	if (inventoryItem === undefined || now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IPurchaseFromInventoryItemModel>
			validateOnMount
			initialValues={{
				...EmptyValues,
				inventoryItemId: inventoryItemId,
				supplierId: inventoryItem.supplierId ?? '',
				vatPercentage: inventoryItem.vatPercentage,
				unitPriceExVat: inventoryItem.purchaseListPrice,
				unitListPriceExVat: inventoryItem.purchaseListPrice,
				purchaseDate: now,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						step={step}
						setStep={setStep}
						stepsRecord={stepsRecord}
						schema={schema}
						isShiftVat={isShiftVat}
						setIsShiftVat={setIsShiftVat}
						inventoryItem={inventoryItem}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof IPurchaseFromInventoryItemModel)[]>;
	schema: yup.ObjectSchema<IPurchaseFromInventoryItemModel>;
	isShiftVat: boolean;
	setIsShiftVat: React.Dispatch<React.SetStateAction<boolean>>;
	inventoryItem: IInventoryItem;
}

export const InnerDialog = ({ cancel, isSubmitting, step, setStep, stepsRecord, schema, isShiftVat, setIsShiftVat, inventoryItem, ...rest }: IInnerProps) => {
	const props = useFormikContext<IPurchaseFromInventoryItemModel>();
	const strings = useLocalization();
	useNextReferenceInterceptor(purchasesQuery_nextReference, props);
	const [settings] = useApiEffect(purchasesQuery_settings);

	useEffect(() => {
		setFormValue<IPurchaseFromInventoryItemModel>('vatPercentage', isShiftVat ? 0 : inventoryItem.vatPercentage, props);
		// eslint-disable-next-line
	}, [isShiftVat]);

	if (settings === undefined) {
		return <div></div>;
	}

	return (
		<Dialog {...rest}>
			<DialogTitleWithFormStepper
				title={strings.newWhat(strings.purchase)}
				step={step}
				labels={[strings.supplier, strings.reference, strings.price]}
			/>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<FormSelectOrCreateSupplierField<IPurchaseFromInventoryItemModel>
						name='supplierId'
						label={strings.supplier}
					/>
				)}
				{step === 1 && (
					<>
						<FormSingleCheckboxField<IPurchaseFromInventoryItemModel>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IPurchaseFromInventoryItemModel>
							name='reference'
							label={strings.invoiceNumber}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormTextField<IPurchaseFromInventoryItemModel>
							name='externalReference'
							label={strings.externalReference}
						/>
						<FormDatePicker<IPurchaseFromInventoryItemModel>
							name='purchaseDate'
							label={strings.purchaseDate}
							required
						/>
						<FormDatePickerWithUnit<IPurchaseFromInventoryItemModel>
							name='validUntil'
							label={strings.toPayBeforeDate}
							units={ValidUntilTimeUnits}
							defaultUnit='Months'
							defaultValue={settings.defaultValidUntil}
						/>
					</>
				)}
				{step === 2 && (
					<>
						<FormMoneyField<IPurchaseFromInventoryItemModel>
							name='unitPriceExVat'
							label={strings.priceExVat}
						/>
						<FormControlLabelSwitch
							isChecked={isShiftVat}
							toggle={() => setIsShiftVat(!isShiftVat)}
							label={strings.shiftVat}
							style={{ marginTop: 16, marginLeft: 0 }}
						/>
						<Typography variant='caption'>{strings.intraCommCaption}</Typography>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
