import { useCallback, useContext } from 'react';
import { useApiCall } from 'framework/hooks/useApiCall';
import { PdfViewerDialogContext } from 'framework/pdf/context/PdfViewerDialogContext';
import { DocumentExportFormat, documentsQuery_content, documentsQuery_historyContent } from 'gen/ApiClient';

export function usePdfViewerWithDocument(): (title: string, documentId: string, historyId?: string) => void {
	const loadHistoryF = useApiCall(documentsQuery_historyContent);
	const loadF = useApiCall(documentsQuery_content);
	const { openWithContent } = useContext(PdfViewerDialogContext);

	const f = async (title: string, documentId: string, historyId?: string) => {
		const r = historyId ? await loadHistoryF(documentId, historyId, 'Pdf' as DocumentExportFormat) : await loadF(documentId, 'Pdf' as DocumentExportFormat);
		if (r.isSuccess) {
			openWithContent(title, r.result.content);
		}
	};

	return useCallback(f, []);
}
