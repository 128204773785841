import { CardContent, List, ListSubheader } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { FixedWidthCard, IFixedWidthCardProps } from 'framework/components/FixedWidthCard';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { IDocumentSummary, IRfiaDetail, TemplateType } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DocumentCreateOrUploadForm } from 'documents/DocumentCreateOrUploadForm';
import { DocumentDetailDialog } from 'documents/DocumentDetailDialog';
import { DocumentListItem } from 'documents/DocumentListItem';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { TrialReportDialog } from '../../trialReport/TrialReportDialog';
import { AnamnesisListItem } from './AnamnesisListItem';
import { CosiListItem } from './CosiListItem';
import { HandoverCertificateListItem } from './HandoverCertificateListItem';
import { LoanSlipListItem } from './LoanSlipListItem';
import { PrescriptionScanListItem } from './PrescriptionListItem';
import { TrialReportListItem } from './TrialReportListItem';

const calculateAppendTitle = (doc: IDocumentSummary, strings: IStrings) => {
	if (isNullOrUndefined(doc.templateType)) {
		return undefined;
	}
	const xType = doc.templateType as TemplateType;
	if (xType === 'RfiaTrialReport' || xType === 'RfiaLoanSlip') {
		return strings.previousTrial;
	}
};

interface IProps extends IFixedWidthCardProps {
	reload: VoidFunction;
	item: IRfiaDetail;
}

export const DocumentsCard = ({ item, reload, ...rest }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);
	const strings = useLocalization();

	const onViewDetail = (id: string) => {
		const docSummary = item.documents.find(t => t.id === id);
		if (docSummary && docSummary.templateType === ('RfiaTrialReport' as TemplateType) && item.trialSteps.some(t => t.trialReportDocumentId === id)) {
			const trial = item.trialSteps.find(t => t.trialReportDocumentId === id)!;
			open(
				<TrialReportDialog
					open
					rfiaId={item.rfia.id}
					patientId={item.patient.id}
					trialId={trial.id}
					close={confirm}
				/>
			);
		} else {
			open(
				<DocumentDetailDialog
					open
					id={id}
					close={confirm}
				/>
			);
		}
	};

	const onNew = () => {
		open(
			<DocumentCreateOrUploadForm
				title={strings.newWhat(strings.document)}
				context='Rfia'
				additionalContexts={['Patient']}
				contextId={item.rfia.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<FixedWidthCard {...rest}>
			<CardTitleTypography
				withPadding
				title={strings.documents}
				action={
					<SmallPrimaryTextButton
						startIcon={<AddIcon />}
						onClick={onNew}>
						{strings.newWhat(strings.document)}
					</SmallPrimaryTextButton>
				}
			/>
			<CardContent style={{ paddingTop: 0 }}>
				<List
					dense
					disablePadding>
					<ListSubheader
						disableGutters
						style={{ marginTop: '-10px' }}>
						{strings.minimalRequiredDocumentsAccordingToRIZIV}
					</ListSubheader>
					<PrescriptionScanListItem
						rfiaId={item.rfia.id}
						scan={item.rfia.prescriptionScan}
						requiredStep={item.rfia.requiredPrescriptionScanStep}
						reload={reload}
					/>
					<AnamnesisListItem
						rfiaId={item.rfia.id}
						document={item.anamnesisDocument}
						reload={reload}
					/>
					<CosiListItem
						rfia={item.rfia}
						document={item.cosiDocument}
						reload={reload}
					/>
					<LoanSlipListItem
						rfia={item.rfia}
						lastLoanSlip={item.lastLoanSlip}
						reload={reload}
					/>
					<TrialReportListItem
						rfia={item.rfia}
						document={item.lastTrialReportDocument}
						reload={reload}
					/>
					<HandoverCertificateListItem
						rfia={item.rfia}
						document={item.handoverCertificate}
						reload={reload}
						isHifExist={item.isHifExist}
					/>
					<ListSubheader disableGutters>{strings.otherDocuments}</ListSubheader>
					{item.otherDocuments &&
						item.otherDocuments.map(t => (
							<DocumentListItem
								key={t.id}
								reload={reload}
								document={t}
								onClick={onViewDetail}
								appendTitle={calculateAppendTitle(t, strings)}
							/>
						))}
					{(!item.otherDocuments || item.otherDocuments.length === 0) && <NoRecordsFound>{strings.noWhat(strings.otherDocuments)}</NoRecordsFound>}
				</List>
			</CardContent>
		</FixedWidthCard>
	);
};
