import { DragAndDropContainer } from 'framework/components/dnd/DragAndDropContainer';
import { LeftRightDividedContentScrollYRight } from 'framework/components/LeftRightDividedContentScrollYRight';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { addOrUpdateQuantity } from 'framework/utils/array/addOrUpdateQuantity';
import { updateArray } from 'framework/utils/array/updateArray';
import { IInventoryItem, ILocationSummary, IProductCatalogItem, IStockAtLocation } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { SearchProductsForLoanOrTrialTabs } from 'shared/searchAndSelect/SearchProductsForLoanOrTrialTabs';
import { SearchContext } from '../context/SearchContext';
import { ILine } from './ILine';
import { LoanLineComponent } from './LoanLineComponent';
import { inventoryItemToLine } from './utils/inventoryItemToLine';
import { productCatalogItemToLine } from './utils/productCatalogItemToLine';

interface IProps {
	lines: ILine[];
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>;
	location: ILocationSummary;
}

export const SearchAndSelectProductsForLoan = ({ lines, setLines, location }: IProps) => {
	const strings = useLocalization();
	const { ...allSearchParams } = useContext(SearchContext);
	const notify = useSnackbarNotify();

	const onAddPc = (item: IProductCatalogItem, stockAtLocations: IStockAtLocation[]) => {
		if (item.isTangible === false) {
			return; // dit kan niet
		} else {
			setLines(addOrUpdateQuantity(item, lines, () => productCatalogItemToLine(item, stockAtLocations)));
		}
	};

	const onAddII = (item: IInventoryItem) => {
		if (lines.findIndex(t => t.inventoryItemId === item.id) > -1) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'warning');
		} else {
			setLines([...lines, inventoryItemToLine(item)]);
		}
	};

	return (
		<LeftRightDividedContentScrollYRight
			leftContent={
				<SearchProductsForLoanOrTrialTabs<ILine>
					{...allSearchParams}
					lines={lines}
					onAddII={onAddII}
					onAddPc={onAddPc}
				/>
			}
			rightContent={
				<DragAndDropContainer<ILine>
					lines={lines}
					setLines={setLines}
					getKey={t => t.id}
					render={(line, index, props) => (
						<LoanLineComponent
							line={line}
							index={index + 1}
							dragHandleProps={props}
							setLine={val => setLines(updateArray(line, lines, val))}
							onDelete={() => setLines(lines.filter(t => t !== line))}
							location={location}
						/>
					)}
				/>
			}
		/>
	);
};
