import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IQueryResult, IReturn, IReturnsQueryParams, returnsQuery_concurrencyToken, returnsQuery_query } from 'gen/ApiClient';
import { returnsQueryParamsLsKey } from '../../../../../localStorageKeys';
import { ReturnsQueryContext } from './ReturnsQueryContext';

const DefaultParams: IReturnsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	returnedAfter: undefined,
	returnedBefore: undefined,
	supplierIds: [],
};

export const ReturnsQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IReturn>, IReturnsQueryParams>
			defaultParams={DefaultParams}
			queryF={returnsQuery_query}
			concurrencyF={returnsQuery_concurrencyToken}
			localStorageKey={returnsQueryParamsLsKey}
			context={ReturnsQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
