import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { IRepair, RepairProcessStatus } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	item: IRepair;
	color: ColorX;
	style?: React.CSSProperties;
}

export const RepairProcessStatusChipX = ({ item, color, style }: IProps) => {
	const { repairProcessStatusRecord } = useContext(RecordContext);

	return (
		<ChipX
			style={style}
			label={repairProcessStatusRecord[item.status as RepairProcessStatus]}
			color={color}
		/>
	);
};
