import { IPatientDetail } from 'gen/ApiClient';
import React from 'react';
import { UserPreferencesPreloadCacheContext } from 'shared/context/UserPreferencesPreloadCacheContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { PatientDetailOverviewCard } from './PatientDetailOverviewCard';
import { PatientDetailOverviewLayoutElementSwitch } from './PatientDetailOverviewLayoutElementSwitch';

interface IProps extends IReloadTriggerProps {
	item: IPatientDetail;
	reload: VoidFunction;
}

export const PatientDetailOverviewTab = ({ item, reload, reloadTrigger }: IProps) => {
	const { userPreferences } = React.useContext(UserPreferencesPreloadCacheContext);

	return (
		<div className='df-col gap-16'>
			<PatientDetailOverviewCard
				item={item}
				reload={reload}
				reloadTrigger={reloadTrigger}
			/>
			<div
				className='df-row'
				style={{ gap: 16 }}>
				<div
					className='df-col gap-16'
					style={{ width: '50%' }}>
					{userPreferences.patientDetailOverviewLayoutView.leftElements.map(t => (
						<PatientDetailOverviewLayoutElementSwitch
							key={t}
							type={t as any}
							item={item}
							reload={reload}
							reloadTrigger={reloadTrigger}
						/>
					))}
				</div>
				<div
					className='df-col gap-16'
					style={{ width: '50%' }}>
					{userPreferences.patientDetailOverviewLayoutView.rightElements.map(t => (
						<PatientDetailOverviewLayoutElementSwitch
							key={t}
							type={t as any}
							item={item}
							reload={reload}
							reloadTrigger={reloadTrigger}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
