import { Card, CardContent } from '@material-ui/core';
import { EventListComponent } from 'app/main/calendar/agenda/list/EventListComponent';
import { CreateDefaultCalendarEventForm } from 'app/main/calendar/forms/CreateDefaultCalendarEventForm';
import { addYears, startOfDay } from 'date-fns';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { FixedWidthCard } from 'framework/components/FixedWidthCard';
import { CalendarPlusIcon } from 'framework/components/icons/CalendarPlusIcon';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { agendaQuery_upcomingByPatient, ICalendarEventDto, IPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { getNow } from 'shared/utils/getNow';
import { IPatientUpcomingEventsCardProps } from './IPatientUpcomingEventsCardProps';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';

export const HamsPatientUpcomingEventsCard = ({ item: patient, width, reloadTrigger, reload, ...rest }: IPatientUpcomingEventsCardProps) => {
	const [events, reloadEvents] = useApiEffect(agendaQuery_upcomingByPatient, patient.id);
	const after = useMemo(() => startOfDay(getNow()), []);
	const before = useMemo(
		() => (events === undefined ? addYears(after, 1) : _.max([...events.map(t => new Date(t.endDate)), addYears(after, 1)])!),
		[events, after]
	);

	useLazyEffect(() => {
		reloadEvents();
	}, [reloadTrigger]);

	if (events === undefined) {
		return <div></div>;
	}

	if (width === undefined) {
		return (
			<Card {...rest}>
				<InnerContent
					patient={patient}
					events={events}
					reload={reload}
					afterDate={after}
					beforeDate={before}
				/>
			</Card>
		);
	} else {
		return (
			<FixedWidthCard
				{...rest}
				width={width}>
				<InnerContent
					patient={patient}
					events={events}
					reload={reload}
					afterDate={after}
					beforeDate={before}
				/>
			</FixedWidthCard>
		);
	}
};

interface IInnerContentProps {
	events: ICalendarEventDto[];
	reload: VoidFunction;
	patient: IPatient;
	afterDate: Date;
	beforeDate: Date;
}

const InnerContent = ({ events, reload, patient, afterDate, beforeDate }: IInnerContentProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onNew = () => {
		open(
			<CreateDefaultCalendarEventForm
				open
				confirm={confirm}
				cancel={cancel}
				patient={{ id: patient.id, identifier: patient.identifier }}
			/>
		);
	};

	return (
		<CardContent>
			<CardTitleTypography
				startIcon={<TodayOutlinedIcon style={{ marginRight: 4, opacity: 0.8 }} />}
				title={strings.nextAppointments}
				style={{ marginBottom: 8 }}
			/>
			<SmallPrimaryTextButton
				startIcon={<CalendarPlusIcon />}
				color='primary'
				style={{ marginLeft: -5 }}
				onClick={onNew}>
				{strings.newWhat(strings.appointment)}
			</SmallPrimaryTextButton>
			<EventListComponent
				events={events}
				afterDate={afterDate}
				beforeDate={beforeDate}
				reload={reload}
				colorScheme='types'
				hideTitleAndRoom
				noRecordsWithoutPadding
			/>
		</CardContent>
	);
};
