import { TextField, TextFieldProps } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

export const LocalStateTextField = ({ onChange, value, ...rest }: TextFieldProps) => {
	const [localValue, setLocalValue] = useState<unknown>(value);

	useEffect(() => {
		setLocalValue(value);
	}, [value]);

	return (
		<TextField
			value={localValue}
			onChange={e => setLocalValue(e.target.value)}
			onBlur={onChange}
			{...rest}
		/>
	);
};
