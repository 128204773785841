import { CardProps } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IHealthInsuranceFundWithAddressDtoQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';

interface IProps extends CardProps {
	params: IHealthInsuranceFundWithAddressDtoQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IHealthInsuranceFundWithAddressDtoQueryParams>>;
}

export const HifsListFilterCard = ({ params, setParams, ...rest }: IProps) => {
	const strings = useLocalization();
	const isFilteredX = useMemo(() => isFiltered(params, []), [params]);

	const onFilter = (pars: IHealthInsuranceFundWithAddressDtoQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params, [])));

	return (
		<CardX
			className='df-col gap-8'
			{...rest}>
			<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<BooleanRadioFilterComponent
					label={`${strings.withWhat(`${strings.address} (${strings.advisoryPhysician})`)}?`}
					trueText={strings.yes}
					falseText={strings.no}
					selected={params.hasAdvisoryPhysicianAddress}
					setSelected={value => onFilter({ ...params, hasAdvisoryPhysicianAddress: value })}
					icon={<HelpOutlineIcon />}
				/>
				<BooleanRadioFilterComponent
					label={`${strings.withWhat(`${strings.address} (${strings.thirdPayerArrangement})`)}?`}
					trueText={strings.yes}
					falseText={strings.no}
					selected={params.hasThirdPayerArrangementAddress}
					setSelected={value => onFilter({ ...params, hasThirdPayerArrangementAddress: value })}
					icon={<HelpOutlineIcon />}
				/>
			</div>
		</CardX>
	);
};
