import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	title: string;
	confirm: (withSignature: boolean) => void;
	cancel: VoidFunction;
	isSubmitting: boolean;
}

export const WithSignatureDialog = ({ title, confirm, cancel, isSubmitting, ...rest }: IProps) => {
	const strings = useLocalization();
	const [withSignature, setWithSignature] = useState<boolean>(true);

	return (
		<Dialog
			fullWidth
			maxWidth='sm'
			{...rest}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<ExtendedRadioGroup<boolean>
					selected={withSignature}
					setSelected={setWithSignature}
					options={[
						{ value: true, label: strings.withWhat(strings.signature), caption: strings.scannedDocumentIsWithSignature },
						{ value: false, label: strings.withoutWhat(strings.signature), caption: strings.scannedDocumentIsWithoutSignature },
					]}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				submitText={strings.select}
				onSubmit={() => confirm(withSignature)}
				isSubmitting={isSubmitting}
			/>
		</Dialog>
	);
};
