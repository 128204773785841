import { ColorX } from 'framework/components/color/ColorX';
import { OrderProcessStatus } from 'gen/ApiClient';

export const OrderProcessStatusToColorXRecord: Record<OrderProcessStatus, ColorX> = {
	Cancelled: 'error',
	CancelledAfterPartialDelivery: 'error',
	CompletelyDelivered: 'success',
	PlacedWithSupplier: 'default',
	Registered: 'warning',
	PartialDelivered: 'default',
};
