import { DialogContent, DialogProps } from '@material-ui/core';
import { FormSelectOrCreateCompanyFieldWithSelector } from 'app/main/contacts/companies/forms/FormSelectOrCreateCompanyFieldWithSelector';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { setLineErrors } from 'framework/forms/utils/setLineErrors';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	IMedicalTreatmentFeeDto,
	IMedicalTreatmentFeeModel,
	IRegisterRfiaHandoverSplitRequest,
	IRequestForInsuranceAllowance,
	rfiasHandoverCommand_registerHandoverSplit,
	salesQuery_nextInvoiceReference,
	salesQuery_nextInvoiceReferenceExclude,
	salesQuery_settings,
	ValidUntilTimeUnits,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { FormRecipientField } from 'shared/forms/FormRecipientField';
import { useWithRecipientInterceptor } from 'shared/searchAndSelect/useWithRecipientInterceptor';
import { calculateMustLinkAnItemToAssure } from 'shared/searchAndSelect/utils/calculateMustLinkAnItemToAssure';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { useCurrentLocation } from '../../../../settings/locations/useCurrentLocation';
import { SearchProvider } from './context/SearchProvider';
import { FeesModelTable } from './FeesModelTable';
import { FeesTable } from './FeesTable';
import { IHandoverLine } from './IHandoverLine';
import { SearchAndSelectProductsForHandover } from './SearchAndSelectProductsForHandover';
import { SplitHandoverFormConfirmation } from './SplitHandoverFormConfirmation';
import { calculateHasRizivCodeWarning } from './utils/calculateHasRizivCodeWarning';
import { lineToModel } from './utils/lineToModel';
import { mustLinkAnInventoryItem } from './utils/mustLinkAnInventoryItem';

const createSchema = (strings: IStrings, mustSelectCompany: boolean) => {
	return yup
		.object<IRegisterRfiaHandoverSplitRequest>({
			rfiaId: yup.string().required(),
			lines: yup.mixed(),
			patientValidUntil: yup.date().required(strings.formRequired(strings.validTo)),
			patientReference: yup.string().required(strings.formRequired(strings.reference)),
			isAutoSelectPatientReference: yup.boolean().defined(),
			rizivValidUntil: yup.date().required(strings.formRequired(strings.validTo)),
			rizivReference: yup.string().required(strings.formRequired(strings.reference)),
			isAutoSelectRizivReference: yup.boolean().defined(),
			zip: yup.string(),
			addressLine: yup.string(),
			city: yup.string(),
			companyId: mustSelectCompany ? yup.string().required(strings.formRequired(strings.vatCustomer)) : yup.string(),
			country: yup.string(),
			recipientName: yup.string().required(strings.formRequired(strings.recipient)),
			handoverDate: yup.date().required(strings.formRequired(strings.handoverDate)),
			fees: yup.mixed(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IRegisterRfiaHandoverSplitRequest)[]> = {
	0: ['handoverDate', 'fees'],
	1: ['patientReference', 'isAutoSelectPatientReference', 'patientValidUntil'],
	2: ['rizivReference', 'isAutoSelectRizivReference', 'rizivValidUntil'],
	3: ['companyId'],
	4: ['recipientName', 'addressLine', 'city', 'zip', 'country'],
	5: ['lines'],
	6: [],
};

interface IProps extends DialogProps {
	confirm: (patientSalesId: string, handoverDate: Date, rizivSalesId: string) => void;
	cancel: VoidFunction;
	rfia: IRequestForInsuranceAllowance;
}

export const SplitHandoverForm = ({ confirm, cancel, rfia, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(rfiasHandoverCommand_registerHandoverSplit);
	const [step, setStep] = useState<number>(0);
	const [lines, setLines] = useState<IHandoverLine[]>([]);
	const [fees, setFees] = useState<IMedicalTreatmentFeeModel[]>([]);
	const [isSelectCompany, setIsSelectCompany] = useState<boolean>(false);
	const schema = useMemo(() => createSchema(strings, isSelectCompany), [strings, isSelectCompany]);
	const { open, confirm: accept, cancel: reject } = useDialogsContext();
	const now = useNow();

	const handleSubmit = async (values: IRegisterRfiaHandoverSplitRequest, helpers: FormikHelpers<IRegisterRfiaHandoverSplitRequest>) => {
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else if (lines.filter(t => mustLinkAnInventoryItem(t)).length > 0) {
			notify(strings.selectSerialNumbersForSelectedProducts, 'error');
		} else if (lines.filter(t => calculateMustLinkAnItemToAssure(t)).length > 0) {
			notify(strings.selectItemsToAssure, 'error');
		} else if (lines.filter(t => calculateHasRizivCodeWarning(t)).length > 0) {
			open(
				<AlertDialog
					open
					title={strings.confirmation}
					content={strings.notAllRIZIVCodesAreSelectedDoYouWantToProceed}
					acceptText={strings.yesCommaContinue}
					rejectText={strings.noCommaGoBack}
					accept={() => {
						accept();
						onSubmit(values, helpers);
					}}
					reject={reject}
				/>
			);
		} else {
			onSubmit(values, helpers);
		}
	};

	const onSubmit = async (values: IRegisterRfiaHandoverSplitRequest, helpers: FormikHelpers<IRegisterRfiaHandoverSplitRequest>) => {
		const r = await create({ ...values, lines: lines.map((t, index) => lineToModel(t, index)), fees: fees });
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.handoverRegistered);
			confirm(r.result.patientSalesId, values.handoverDate, r.result.rizivSalesId);
		} else {
			setLineErrors('lines', r, lines, setLines);
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterRfiaHandoverSplitRequest>
			validateOnMount
			initialValues={{
				rfiaId: rfia.id,
				handoverDate: now,
				lines: [],
				patientValidUntil: undefined as any,
				patientReference: '',
				isAutoSelectPatientReference: true,
				rizivValidUntil: undefined as any,
				rizivReference: '',
				isAutoSelectRizivReference: true,
				city: '',
				companyId: '',
				addressLine: '',
				zip: '',
				country: '',
				recipientName: '',
				fees: [],
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider patientId={rfia.patientId}>
					<InnerDialog
						step={step}
						setStep={setStep}
						lines={lines}
						setLines={setLines}
						cancel={cancel}
						isSubmitting={isSubmitting}
						schema={schema}
						isSelectCompany={isSelectCompany}
						setIsSelectCompany={setIsSelectCompany}
						rfia={rfia}
						fees={fees}
						setFees={setFees}
						{...rest}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	lines: IHandoverLine[];
	setLines: React.Dispatch<React.SetStateAction<IHandoverLine[]>>;
	isSubmitting: boolean;
	cancel: VoidFunction;
	schema: yup.ObjectSchema<IRegisterRfiaHandoverSplitRequest>;
	isSelectCompany: boolean;
	setIsSelectCompany: React.Dispatch<React.SetStateAction<boolean>>;
	rfia: IRequestForInsuranceAllowance;
	fees: IMedicalTreatmentFeeModel[];
	setFees: (fees: IMedicalTreatmentFeeModel[]) => void;
}

const InnerDialog = ({
	rfia,
	step,
	lines,
	setLines,
	setStep,
	isSubmitting,
	cancel,
	schema,
	isSelectCompany,
	setIsSelectCompany,
	fees,
	setFees,
	...rest
}: IInnerDialogProps) => {
	const props = useFormikContext<IRegisterRfiaHandoverSplitRequest>();
	const strings = useLocalization();
	const [nextPatientReference] = useApiEffect(salesQuery_nextInvoiceReference);
	const [nextRizivReference] = useApiEffect(salesQuery_nextInvoiceReferenceExclude, props.values.patientReference);
	const [settings] = useApiEffect(salesQuery_settings);
	const location = useCurrentLocation();
	const [isUseContactPerson, setIsUseContactPerson] = useState<boolean>(false);
	useWithRecipientInterceptor<IRegisterRfiaHandoverSplitRequest>(props, isSelectCompany, isUseContactPerson, rfia.patientId);

	useEffect(() => {
		if (nextPatientReference) {
			setFormValue<IRegisterRfiaHandoverSplitRequest>('patientReference', nextPatientReference, props);
		}
		// eslint-disable-next-line
	}, [nextPatientReference]);

	useEffect(() => {
		if (nextRizivReference) {
			setFormValue<IRegisterRfiaHandoverSplitRequest>('rizivReference', nextRizivReference, props);
		}
		// eslint-disable-next-line
	}, [nextRizivReference]);

	if (location === undefined || settings === undefined) {
		return <div></div>;
	}

	const onSetFees = (vals: IMedicalTreatmentFeeDto[]) => {
		setFees(
			vals.map(t => ({
				data: t,
				patientDescription: fees.find(x => x.data.code === t.code)?.patientDescription ?? strings.RIZIVRefundNomenclatureCode(t.code ?? ''),
				rizivDescription: fees.find(x => x.data.code === t.code)?.rizivDescription ?? strings.contributionCodeForPatient(t.code ?? '', rfia.patientName ?? ''),
			}))
		);
	};

	return (
		<FullScreenDialogWithStepper
			{...rest}
			title={strings.registerHandover}
			step={step}
			labels={[
				strings.date,
				`${strings.referenceShort} ${strings.patient}`,
				`${strings.referenceShort} ${strings.healthInsuranceFundShort}`,
				strings.vatCustomer,
				strings.withRespectToShort,
				strings.items,
				strings.confirmation,
			]}
			fullWidth={step === 0}
			maxWidth='lg'
			fullScreenStep={5}>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormDatePicker<IRegisterRfiaHandoverSplitRequest>
							name='handoverDate'
							label={strings.handoverDate}
							helperText={strings.thisDateWillBeUsedToCalculateNomenclaturePrices}
						/>
						{props.values.handoverDate !== undefined && (
							<>
								<DividerWithCaption
									caption={`${strings.refunds} (${strings.prices})`}
									spacingTop={4}
									spacingBottom={4}
								/>
								<FeesTable
									rfia={rfia}
									handOverDate={props.values.handoverDate}
									fees={fees.map(t => t.data)}
									setFees={onSetFees}
								/>
								<DividerWithCaption
									caption={`${strings.refunds} (${strings.descriptions})`}
									spacingBottom={4}
									spacingTop={6}
								/>
								<FeesModelTable
									fees={fees}
									setFees={setFees}
								/>
							</>
						)}
					</>
				)}
				{step === 1 && (
					<>
						<FormSingleCheckboxField<IRegisterRfiaHandoverSplitRequest>
							name='isAutoSelectPatientReference'
							label={`${strings.autoSelectIndexNumberQuestion} (${strings.patient})`}
						/>
						<FormTextField<IRegisterRfiaHandoverSplitRequest>
							name='patientReference'
							label={`${strings.invoiceNumber} (${strings.patient})`}
							disabled={props.values.isAutoSelectPatientReference}
						/>
						<FormDatePickerWithUnit<IRegisterRfiaHandoverSplitRequest>
							name='patientValidUntil'
							label={`${strings.validTo} (${strings.patient})`}
							required
							units={ValidUntilTimeUnits}
							defaultUnit='Months'
							defaultValue={settings.defaultValidUntil}
						/>
					</>
				)}
				{step === 2 && (
					<>
						<FormSingleCheckboxField<IRegisterRfiaHandoverSplitRequest>
							name='isAutoSelectRizivReference'
							label={`${strings.autoSelectIndexNumberQuestion} (${strings.RIZIV})`}
						/>
						<FormTextField<IRegisterRfiaHandoverSplitRequest>
							name='rizivReference'
							label={`${strings.invoiceNumber} (${strings.RIZIV})`}
							disabled={props.values.isAutoSelectRizivReference}
						/>
						<FormDatePickerWithUnit<IRegisterRfiaHandoverSplitRequest>
							name='rizivValidUntil'
							label={`${strings.validTo} (${strings.RIZIV})`}
							required
							units={ValidUntilTimeUnits}
							defaultUnit='Months'
							defaultValue={settings.defaultValidUntil}
						/>
					</>
				)}
				{step === 3 && (
					<FormSelectOrCreateCompanyFieldWithSelector<IRegisterRfiaHandoverSplitRequest>
						isSelectCompany={isSelectCompany}
						setIsSelectCompany={setIsSelectCompany}
						patientId={rfia.patientId}
					/>
				)}
				{step === 4 && (
					<FormRecipientField<IRegisterRfiaHandoverSplitRequest>
						patientId={rfia.patientId}
						isUseContactPerson={isUseContactPerson}
						setIsUseContactPerson={setIsUseContactPerson}
					/>
				)}
				{step === 5 && (
					<SearchAndSelectProductsForHandover
						rfia={rfia}
						lines={lines}
						setLines={setLines}
						location={location}
						fees={fees.map(t => t.data)}
						mode='handover'
						customerAccountId={props.values.companyId ? props.values.companyId : rfia.patientId}
						handoverDate={new Date(props.values.handoverDate!)}
					/>
				)}
				{step === 6 && (
					<SplitHandoverFormConfirmation
						lines={lines}
						fees={fees}
						rfia={rfia}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
				validateMore={() => (step === 0 ? fees.length === rfia.allMedicalTreatmentNomenclatureCodes.length : true)}
			/>
		</FullScreenDialogWithStepper>
	);
};
