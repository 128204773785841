import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectFieldOnId } from 'framework/forms/FormSelectFieldOnId';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { CommissionRuleMethod, commissionsCommand_new, ICommissionModel, ICommissionRule, ISale } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import * as yup from 'yup';
import { CommissionRulesCacheContext } from '../../context/CommissionRulesCacheContext';
import { calculateCommission } from '../utils/calculateCommission';

const createSchema = (strings: IStrings) => {
	return yup.object<ICommissionModel>({
		userId: yup.string().required(),
		salesId: yup.string().required(),
		commissionRuleId: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	sale: ISale;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const CommissionModelForm = ({ sale, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(commissionsCommand_new);
	const [rules] = usePreloadCacheContext(CommissionRulesCacheContext);
	const user = useLoggedInUser();

	const handleSubmit = async (values: ICommissionModel, helpers: FormikHelpers<ICommissionModel>) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<ICommissionModel>
			validateOnMount
			initialValues={{
				commissionRuleId: undefined as any,
				salesId: sale.id,
				userId: user.id,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					cancel={cancel}
					rules={rules.enabled}
					isSubmitting={isSubmitting}
					sale={sale}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	rules: ICommissionRule[];
	cancel: VoidFunction;
	isSubmitting: boolean;
	sale: ISale;
}

const InnerDialog = ({ rules, cancel, isSubmitting, sale, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const props = useFormikContext<ICommissionModel>();
	const selectedRule = useMemo(() => rules.find(t => t.id === props.values.commissionRuleId), [props.values.commissionRuleId, rules]);
	const selectedRuleMethod = useMemo(() => selectedRule && (selectedRule.method as CommissionRuleMethod), [selectedRule]);
	const { commissionRuleMethodRecord } = useContext(RecordContext);

	return (
		<Dialog
			fullWidth
			{...rest}>
			<DialogTitle>{strings.selectWhat(strings.rule)}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<FormSelectFieldOnId<ICommissionModel, ICommissionRule>
					options={rules}
					getKey={t => t.id}
					name='commissionRuleId'
					label={strings.rule}
					renderValue={t => `${t.name} (${t.percentage} %)`}
				/>
				{selectedRule && selectedRuleMethod && (
					<>
						<DividerWithCaption caption={strings.calculation} />
						<LabelledProperty
							label={strings.method}
							property={commissionRuleMethodRecord[selectedRuleMethod]}
						/>
						{selectedRuleMethod === 'TotalValueOfDevices' && (
							<LabelledProperty
								label={strings.totalPriceDevicesExVat}
								property={formatCurrency(sale.priceDevicesExVat)}
							/>
						)}
						{selectedRuleMethod === 'TotalValueOfSale' && (
							<LabelledProperty
								label={strings.priceExVat}
								property={formatCurrency(sale.priceExVat)}
							/>
						)}
						<LabelledProperty
							label={strings.commission}
							property={formatCurrency(calculateCommission(sale, selectedRule))}
						/>
					</>
				)}
			</DialogContent>
			<CancelSubmitFormDialogActions
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
			/>
		</Dialog>
	);
};
