import React from 'react';
import { ICosiQuestionnaireSummary } from 'gen/ApiClient';
import { StatusFibers } from '../detail/prescriptionScan/StatusFibers';

interface IProps {
	hasSignature: boolean;
	cosiQuestionnaire: ICosiQuestionnaireSummary;
}
export const CosiStatusFibers = ({ hasSignature, cosiQuestionnaire }: IProps) => {
	return (
		<StatusFibers
			step={hasSignature ? 3 : cosiQuestionnaire.isEvaluated ? 2 : 1}
			requiredStep={3}
			totalSteps={3}
		/>
	);
};
