import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Paper, Typography, useTheme } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { TablePaging } from 'framework/table/TablePaging';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { formatDate } from 'framework/utils/date/formatDate';
import { INoahDeviceReadOut, INotLinkedNorIgnoredNoahDeviceReadOutsQueryParams, noahDeviceReadOutsQuery_queryNotLinkedNorIgnored } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { createSelectHeader } from 'shared/table/createSelectHeader';

const DefaultParams: INotLinkedNorIgnoredNoahDeviceReadOutsQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'uniqueField' },
	pageParams: { index: 1, size: 10 },
};

interface IProps extends DialogProps {
	cancel: VoidFunction;
	initialSearch?: string;
	isSubmitting?: boolean;
	jxsTitle: JSX.Element;
	onClickSelect: (def: INoahDeviceReadOut) => void;
}

export const SelectNoahDeviceReadOutForm = ({ cancel, initialSearch = '', isSubmitting = false, jxsTitle, onClickSelect, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(noahDeviceReadOutsQuery_queryNotLinkedNorIgnored, {
		...DefaultParams,
		searchString: initialSearch,
	});

	const headers = [
		createSortableHeader<INoahDeviceReadOut>(strings.manufacturer, 'manufacturer', t => t.manufacturer),
		createSortableHeader<INoahDeviceReadOut>(strings.model, 'instrumentTypeName', t => t.instrumentTypeName),
		createSortableHeader<INoahDeviceReadOut>(strings.lastReadOutDate, 'lastReadOutDate', t => formatDate(t.lastReadOutDate)),
		createSelectHeader<INoahDeviceReadOut>(
			t => onClickSelect(t),
			t => isSubmitting
		),
	];

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='md'
			fullWidth>
			<CloseButtonOnAbsolutePosition onClick={cancel} />
			<DialogTitle>
				{jxsTitle}
				<Typography variant='caption'>{strings.warningOnlyUnlinkedAndNotIgnoredDevicesAreShownHere}</Typography>
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder={strings.searchOnWhat(`${strings.manufacturer}/${strings.model}`)}
					variant='paper'
					style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
				/>
			</DialogTitle>
			<DialogContent dividers>
				{queryResult.filteredCount > 0 && (
					<Paper variant='outlined'>
						<TableWithHeadersAndValues<INoahDeviceReadOut>
							padding={theme.spacing(1)}
							headers={headers}
							getKey={t => t.id}
							sortParams={params.sortParams}
							setSortParams={sp => setParams({ ...params, sortParams: sp, pageParams: { index: 1, size: params.pageParams.size } })}
							values={queryResult.values}
						/>
					</Paper>
				)}
				{queryResult.filteredCount === 0 && queryResult.unfilteredCount > 0 && <NoRecordsFound>{strings.nothingFoundCommaChangeSearchParams}</NoRecordsFound>}
			</DialogContent>
			<DialogActions>
				<TablePaging
					countResults={queryResult.filteredCount}
					pageSize={queryResult.pageSize}
					page={queryResult.zeroBasedPageIndex}
					pageParams={params.pageParams}
					setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
					removeBorder
				/>
			</DialogActions>
		</Dialog>
	);
};
