import { INewLineModel } from 'shared/searchAndSelect/INewLineModel';
import { IInvoiceLine } from '../IInvoiceLine';

export const newLineToLine = (t: INewLineModel): IInvoiceLine => {
	return {
		isSelected: true,
		id: t.id,
		description: t.description,
		quantity: 1,
		unitListPrice: t.unitListPrice,
		unitPrice: t.unitListPrice,
		vatPercentage: t.vatPercentage,
		inventoryItemId: undefined,
		productCatalogItem: undefined,
		productCatalogItemId: undefined,
		assuredLineId: undefined,
		assuredProductCatalogItemId: undefined,
		assuredInventoryItemId: undefined,
		serialNumber: undefined,
		canChangeQuantity: true,
		stockAtLocations: undefined,
		inventoryItem: undefined,
		isCredit: false,
	};
};
