import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useContext } from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { TextWithOpenInNew } from 'framework/components/TextWithOpenInNew';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IPaymentLineData } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	lines: IPaymentLineData[];
	total: number;
	onViewDetail: (id: string) => void;
}

export const PaymentsTable = ({ lines, total, onViewDetail }: IProps) => {
	const { translatePaymentMethod } = useContext(RecordContext);
	const strings = useLocalization();

	return (
		<>
			<Table>
				{/* <colgroup>
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
                <col style={{ width: 'auto' }} />
            </colgroup> */}
				<TableHead>
					<TableRow>
						<TableCell>{strings.dateCreated}</TableCell>
						<TableCell>{strings.reference}</TableCell>
						<TableCell>{strings.method}</TableCell>
						<TableCell align='right'>{strings.amount}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{lines.map(t => (
						<TableRow key={t.reference}>
							<TableCell>{formatDate(t.created)}</TableCell>
							<TableCell>{<TextWithOpenInNew onClick={() => onViewDetail(t.paymentId)}>{t.reference}</TextWithOpenInNew>}</TableCell>
							<TableCell>
								<RectangularChip label={translatePaymentMethod(t.paymentMethodIdentifier)} />
							</TableCell>
							<TableCell align='right'>{formatCurrency(t.value)}</TableCell>
						</TableRow>
					))}
					<TableRow>
						<TableCell
							colSpan={2}
							style={{ borderBottom: 'none' }}></TableCell>
						<TableCell style={{ fontWeight: 'bold' }}>Totaal</TableCell>
						<TableCell
							align='right'
							style={{ fontWeight: 'bold' }}>
							{formatCurrency(total)}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</>
	);
};
