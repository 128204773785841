import { CardProps } from '@material-ui/core';
import { CollapseX } from 'framework/components/CollapseX';
import { CardX } from 'framework/components/cards/CardX';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IPurchase } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps extends CardProps {
	item: IPurchase;
}

export const PurchaseDetailsTotalsBreakdownCard = ({ item, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<CardX
			className='df-col gap-4'
			{...rest}>
			<Typography16pxBold>{strings.totals}</Typography16pxBold>
			<LabelledPropertyX label={strings.totalInVat}>{formatCurrency(item.priceInVat)}</LabelledPropertyX>
			<LabelledPropertyX label={strings.totalExVat}>{formatCurrency(item.priceExVat)}</LabelledPropertyX>
			<LabelledPropertyX label={strings.vatAmount}>{formatCurrency(item.vatAmount)}</LabelledPropertyX>
			<CollapseX
				expandText={strings.viewSplitUpPerVatPercentage}
				collapseText={strings.hideSplitUpPerVatPercentage}>
				{item.vatSplits.map(t => (
					<React.Fragment key={t.percentage}>
						<LabelledPropertyX label={`@${t.percentage}% - ${strings.totalInVat}`}>{formatCurrency(t.amountInVat)}</LabelledPropertyX>
						<LabelledPropertyX label={strings.totalExVat}>{formatCurrency(t.amountExVat)}</LabelledPropertyX>
						<LabelledPropertyX label={strings.vatAmount}>{formatCurrency(t.value)}</LabelledPropertyX>
					</React.Fragment>
				))}
			</CollapseX>
		</CardX>
	);
};
