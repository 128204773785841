import { Card, IconButton, Paper, useScrollTrigger, useTheme } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { FunctionVariantIcon } from 'framework/components/icons/FunctionVariantIcon';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { useHeightResizeObserver } from 'framework/hooks/useHeightResizeObserver';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Breadcrumbs } from '../breadcrumbs/Breadcrumbs';
import { IPageProps } from './IPageProps';
import { PageReloadButton } from './PageReloadButton';

const useStickyStyle = (): React.CSSProperties => {
	const theme = useTheme();
	return {
		position: 'sticky',
		top: 0,
		left: 0,
		backgroundColor: theme.palette.background.default,
		zIndex: 3,
	};
};

export const Page = ({
	children,
	spacing,
	detailsFixedWidth,
	title,
	secondaryTitle,
	reload,
	settings,
	breadcrumbs,
	showHome,
	details,
	nav,
	fullHeight,
	style,
	header,
	breadCrumbsContainerStyle,
	styleRightContent,
	styleDetailsContent,
	onScript,
	secondaryBreadcrumbs,
	reloadEveryXSeconds,
}: IPageProps) => {
	const theme = useTheme();
	const xSpacing = useMemo(() => spacing ?? 2, [spacing]);
	const xDetailsFixedWidth = useMemo(() => detailsFixedWidth ?? 300, [detailsFixedWidth]);
	const [scrollTarget, setScrollTarget] = useState<HTMLDivElement | undefined>(undefined);
	const trigger = useScrollTrigger({ target: scrollTarget, disableHysteresis: true, threshold: 20 });
	const stickyStyle = useStickyStyle();
	const strings = useLocalization();
	const stickyTopRef = useRef<HTMLDivElement>(null);
	const leftColRef = useRef<HTMLDivElement>(null);
	// eslint-disable-next-line
	const [ref, height] = useHeightResizeObserver();
	const user = useLoggedInUser();

	useEffect(() => {
		if (leftColRef.current !== null && leftColRef.current !== undefined) {
			leftColRef.current.style.top = `-${leftColRef.current.scrollHeight - leftColRef.current.offsetHeight}px`;
		}
	}, [leftColRef, height]);

	return (
		<div
			className='df-col'
			style={{ height: '100%', ...style }}
			ref={ref}>
			<Paper
				square
				elevation={trigger ? 4 : 0}
				style={{ ...stickyStyle, padding: theme.spacing(xSpacing), borderRight: `5px solid ${trigger ? '#f1f1f1' : '#888'}` }}
				ref={stickyTopRef}>
				<div className='df-row-ac'>
					<Breadcrumbs
						breadcrumbs={breadcrumbs}
						title={title}
						secondaryTitle={secondaryTitle}
						showHome={showHome}
						style={breadCrumbsContainerStyle}
						secondaryBreadcrumbs={secondaryBreadcrumbs}
					/>
					<div className='fg1'>{header && header}</div>
					<div className='df-row-ac'>
						{onScript && user.isSuperUser && (
							<TooltipWithSpan
								title={`Execute custom scripts`}
								style={{ marginTop: '-12px', marginBottom: '-12px' }}>
								<IconButton
									onClick={onScript}
									edge='end'>
									<FunctionVariantIcon />
								</IconButton>
							</TooltipWithSpan>
						)}
						{settings && (
							<TooltipWithSpan
								title={strings.changeSettings}
								style={{ marginTop: '-12px', marginBottom: '-12px' }}>
								<IconButton
									onClick={settings}
									edge='end'>
									<SettingsIcon />
								</IconButton>
							</TooltipWithSpan>
						)}
						{reload && (
							<PageReloadButton
								reload={reload}
								reloadEveryXSeconds={reloadEveryXSeconds}
							/>
						)}
					</div>
				</div>
			</Paper>
			<div
				className='df-row fg1'
				style={{ overflowY: 'scroll', height: `calc(100% - ${stickyTopRef.current?.clientHeight ?? 0}px)` }}
				ref={node => setScrollTarget(node ?? undefined)}>
				{(nav || details) && (
					<div
						style={{
							padding: theme.spacing(xSpacing),
							paddingTop: '1px',
							paddingRight: theme.spacing(xSpacing / 2),
							flex: `${xDetailsFixedWidth}px 0 0`,
							position: 'sticky',
							top: '0px',
							height: 'fit-content',
							maxWidth: `${xDetailsFixedWidth}px`,
							...styleDetailsContent,
						}}
						ref={leftColRef}>
						<>
							{nav !== undefined && nav !== null && (
								<Card
									style={{ position: 'sticky', top: trigger ? '8px' : 0, marginBottom: theme.spacing(2), zIndex: 2 }}
									raised={trigger}>
									{nav}
								</Card>
							)}
							{details}
						</>
					</div>
				)}
				<div
					className='df-col fg1'
					style={{
						padding: theme.spacing(xSpacing),
						paddingTop: 1,
						paddingLeft: details ? theme.spacing(xSpacing / 2) : theme.spacing(xSpacing),
						height: fullHeight === true ? '100%' : 'fit-content',
						...styleRightContent,
					}}>
					{children}
				</div>
			</div>
		</div>
	);
};
