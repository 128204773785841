import { IPatient, IPatientSummary, PatientStatus } from 'gen/ApiClient';
import React from 'react';
import { PatientStatusChip } from './PatientStatusChip';

interface IProps {
	item: IPatient | IPatientSummary;
	style?: React.CSSProperties;
	onClick?: VoidFunction;
}

export const PatientStatusChipOrFragment = ({ item, style, onClick }: IProps) => {
	if ((item.status as PatientStatus) === 'Active') {
		return <></>;
	} else {
		return (
			<PatientStatusChip
				item={item}
				onClick={onClick}
				style={style}
			/>
		);
	}
};
