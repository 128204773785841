import { DialogProps } from '@material-ui/core';
import { ProductCatalogItemType } from 'gen/ApiClient';
import React, { useState } from 'react';
import { CreateHearingInstrumentForm } from './hearingInstrument/CreateHearingInstrumentForm';
import { HearingInstrumentForm } from './hearingInstrument/HearingInstrumentForm';
import { InsuranceForm } from './InsuranceForm';
import { TangibleProductCatalogItemForm } from './TangibleProductCatalogItemForm';
import { ProductCatalogItemModeForm } from './ProductCatalogItemModeForm';
import { ProlongedWarrantyForm } from './ProlongedWarrantyForm';
import { ServiceForm } from './ServiceForm';

interface IProps extends DialogProps {
	cancel: VoidFunction;
	confirm: (id: string) => void;
	disableIntangible?: boolean;
}

export const ProductCatalogItemForm = ({ cancel, confirm, disableIntangible = false, ...rest }: IProps) => {
	const [mode, setMode] = useState<ProductCatalogItemType | 'mode'>('mode');

	if (mode === 'mode') {
		return (
			<ProductCatalogItemModeForm
				{...rest}
				confirm={setMode}
				cancel={cancel}
				disableIntangible={disableIntangible}
			/>
		);
	} else if (mode === 'Service') {
		return (
			<ServiceForm
				{...rest}
				cancel={cancel}
				confirm={confirm}
				previous={() => setMode('mode')}
			/>
		);
	} else if (mode === 'Insurance') {
		return (
			<InsuranceForm
				{...rest}
				cancel={cancel}
				confirm={confirm}
				previous={() => setMode('mode')}
			/>
		);
	} else if (mode === 'ProlongedWarranty') {
		return (
			<ProlongedWarrantyForm
				{...rest}
				cancel={cancel}
				confirm={confirm}
				previous={() => setMode('mode')}
			/>
		);
	} else if (mode === 'HearingInstrument') {
		return (
			<CreateHearingInstrumentForm
				cancel={cancel}
				confirm={confirm}
				previous={() => setMode('mode')}
			/>
		);
	} else if (mode === 'RemoteControl') {
		return (
			<HearingInstrumentForm
				{...rest}
				cancel={cancel}
				confirm={confirm}
				isHearingInstrument={false}
				previous={() => setMode('mode')}
			/>
		);
	} else {
		return (
			<TangibleProductCatalogItemForm
				{...rest}
				cancel={cancel}
				confirm={confirm}
				previous={() => setMode('mode')}
				type={mode}
			/>
		);
	}
};
