import { useTheme } from '@material-ui/core';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import BlurCircularOutlinedIcon from '@material-ui/icons/BlurCircularOutlined';
import { CardX } from 'framework/components/cards/CardX';
import { ChipX } from 'framework/components/chips/ChipX';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { ITemplate, LanguageOption, LinkViewContextType, TemplateType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { RecordContext } from 'shared/records/RecordContext';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: ITemplate;
	isSelected: boolean;
	toggle: VoidFunction;
	style?: React.CSSProperties;
}

export const TemplateListItemCardX = ({ item, isSelected, toggle, style }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [hovered, enter, leave] = useStateBoolean(false);
	const { templateTypeRecord, localizedLanguageRecord, linkViewContextTypeRecord } = React.useContext(RecordContext);

	return (
		<CardX
			raised={isSelected || hovered}
			className='df-row w100 gap-8'
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={toggle}
			style={{
				borderRight: isSelected ? `6px solid ${theme.palette.primary.main}` : 'unset',
				cursor: 'pointer',
				...style,
			}}>
			{item.isDeleted === false && <BlurCircularOutlinedIcon style={{ fontSize: 22, color: theme.palette.success.main }} />}
			{item.isDeleted === true && <BlockOutlinedIcon style={{ fontSize: 22, color: theme.palette.error.main }} />}
			<div className='df-col fg1 gap-2'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.name}</span>
				{item.isPredefinedType === true && (
					<ChipX
						label={templateTypeRecord[item.predefinedType as TemplateType]}
						color='primary'
						className='w-fc'
					/>
				)}
				{item.isPredefinedType === false && (
					<ChipX
						label={linkViewContextTypeRecord[item.mainContext as LinkViewContextType]}
						color='secondary'
						className='w-fc'
					/>
				)}
				<span style={{ fontSize: MinorFontSize }}>{`${strings.language}: ${localizedLanguageRecord[item.language as LanguageOption]}`}</span>
			</div>
		</CardX>
	);
};
