import { ITenderLine } from 'gen/ApiClient';
import { IHandoverLine } from '../IHandoverLine';

export const tenderLineToLine = (t: ITenderLine, line: IHandoverLine): IHandoverLine => {
	return {
		...line,
		id: t.id,
		description: t.description ?? line?.description ?? '',
		quantity: t.quantity,
		unitListPrice: t.unitListPriceInVat,
		unitPrice: t.unitPriceInVat,
		vatPercentage: t.vatPercentage,
	};
};
