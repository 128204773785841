import { Menu } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { IPatientDetail } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { PatientDetailProcessesActions } from './PatientDetailProcessesActions';

interface IProps {
	item: IPatientDetail;
	reload: VoidFunction;
}

export const PatientDetailNewProcessTextLinkButton = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });

	return (
		<>
			<Menu
				{...bindMenu(popupState)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				style={{ padding: 0 }}
				keepMounted>
				<PatientDetailProcessesActions
					item={item}
					reload={reload}
					style={{ paddingRight: 16, paddingLeft: 8, paddingTop: 4, paddingBottom: 4 }}
				/>
			</Menu>
			<TextLinkButton
				startIcon={<AddCircleOutlineIcon />}
				{...bindTrigger(popupState)}>
				{strings.newProcess}
			</TextLinkButton>
		</>
	);
};
