import React from 'react';
import { TroubleshootPageViewProvider } from './TroubleshootPageViewProvider';
import { ApiTokensProviders } from './apiTokens/ApiTokensProviders';
import { FailedEventsProviders } from './failedEvents/context/FailedEventsProviders';
import { SubscriptionsProviders } from './subscriptions/context/SubscriptionsProviders';
import { NoahUserSetupsQueryProvider } from './noahUserSetups/NoahUserSetupsQueryProvider';

export const TroubleshootProviders = ({ children }: any) => {
	return (
		<TroubleshootPageViewProvider>
			<FailedEventsProviders>
				<SubscriptionsProviders>
					<ApiTokensProviders>
						<NoahUserSetupsQueryProvider>{children}</NoahUserSetupsQueryProvider>
					</ApiTokensProviders>
				</SubscriptionsProviders>
			</FailedEventsProviders>
		</TroubleshootPageViewProvider>
	);
};
