import React from 'react';
import { ExactOnlineProviders } from './exactOnline/ExactOnlineProviders';
import { InvoiceForwardingProviders } from './invoiceForwarding/InvoiceForwardingProviders';
import { RosaProviders } from './rosa/RosaProviders';
import { ExactGlobeProviders } from './exactGlobe/ExactGlobePage';

export const IntegrationsProviders = ({ children }: any) => {
	return (
		<RosaProviders>
			<ExactOnlineProviders>
				<InvoiceForwardingProviders>
					<ExactGlobeProviders>{children}</ExactGlobeProviders>
				</InvoiceForwardingProviders>
			</ExactOnlineProviders>
		</RosaProviders>
	);
};
