import { Typography, useTheme } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { auth_sendResetPasswordLink, ISendResetPasswordLinkRequest } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { EmptyPageWithLogo } from './EmptyPageWithLogo';

const createSchema = (strings: IStrings) => {
	return yup.object<ISendResetPasswordLinkRequest>({
		email: yup.string().email().required(strings.formRequired(strings.email)),
	});
};

export const SendResetPasswordLink = () => {
	const [fp, isSubmitting] = useFormSubmit(auth_sendResetPasswordLink);
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [hasSent, setHasSent] = useState<boolean>(false);
	const theme = useTheme();

	const handleSubmit = async (values: ISendResetPasswordLinkRequest, helpers: FormikHelpers<ISendResetPasswordLinkRequest>) => {
		const r = await fp(values);
		if (handleValidateResponse(r, helpers, 'email')) {
			setHasSent(true);
			notify(strings.resetPasswordMailSent);
		}
	};

	return (
		<EmptyPageWithLogo>
			<Formik<ISendResetPasswordLinkRequest>
				validateOnMount
				initialValues={{ email: '' }}
				validationSchema={createSchema(strings)}
				onSubmit={handleSubmit}>
				{props => (
					<Form
						autoComplete='off'
						className='df-col'
						style={{ minWidth: '400px' }}>
						{hasSent === false && (
							<>
								<Typography
									variant='body2'
									style={{ textAlign: 'center', marginBottom: '12px', whiteSpace: 'pre-wrap' }}>
									{strings.forgotPasswordHeaderText}
								</Typography>
								<FormTextField<ISendResetPasswordLinkRequest>
									name='email'
									label={strings.email}
									required
								/>
								<LoaderButton
									style={{ marginTop: '20px' }}
									size='large'
									variant='contained'
									color='primary'
									type='submit'
									isLoading={isSubmitting}
									disabled={isSubmitting || !props.isValid}>
									{strings.resetPassword}
								</LoaderButton>
							</>
						)}
						{hasSent === true && (
							<div className='df-row-ac'>
								<Typography style={{ color: theme.palette.success.main }}>{strings.resetPasswordMailSent}</Typography>
							</div>
						)}
					</Form>
				)}
			</Formik>
		</EmptyPageWithLogo>
	);
};
