import { IProductCatalogItem } from 'gen/ApiClient';
import { IPurchaseFormLine } from '../../register/IPurchaseFormLine';

export const filterLines = (assurance: IProductCatalogItem, lines: IPurchaseFormLine[]): IPurchaseFormLine[] => {
	const x = lines.filter(t => t.productCatalogItem !== undefined && t.productCatalogItem.isTangible);
	const otherAssurancesOfSameType = lines
		.filter(t => t.productCatalogItem !== undefined && t.productCatalogItem.type === assurance.type)
		.map(t => t.assuredLineId)
		.filter(t => t !== undefined);
	return x.filter(t => otherAssurancesOfSameType.indexOf(t.id) === -1);
};
