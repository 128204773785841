import { Button, Card, CardMedia, CardProps, Divider, List, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { DocumentCreateOrUploadForm } from 'documents/DocumentCreateOrUploadForm';
import { DocumentDetailDialog } from 'documents/DocumentDetailDialog';
import { DocumentListItem } from 'documents/DocumentListItem';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { TablePaging } from 'framework/table/TablePaging';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { isLast } from 'framework/utils/array/isLast';
import { combineClassNames } from 'framework/utils/combineClassNames';
import { IDocumentsQueryParamsForPatient, IPatient, documentsQuery_queryForPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { PatientDetailDocumentsFilter } from './PatientDetailDocumentsFilter';

const DefaultParams: IDocumentsQueryParamsForPatient = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	predefinedTypes: [],
};

interface IProps extends IReloadTriggerProps, CardProps {
	patient: IPatient;
	reload: VoidFunction;
}

export const PatientDetailDocumentsCard = ({ patient, reload, reloadTrigger, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(pars => documentsQuery_queryForPatient(patient.id, pars), DefaultParams, reloadTrigger);
	const { open, confirm, cancel } = useDialogsContext(reload);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<DocumentCreateOrUploadForm
				title={strings.documentsForWho(patient.lastNameCommaFirstNameBracketsNickName ?? '')}
				context='Patient'
				contextId={patient.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDetail = (id: string) => {
		open(
			<DocumentDetailDialog
				id={id}
				open
				close={confirm}
			/>
		);
	};

	return (
		<Card
			{...rest}
			className={combineClassNames('fg1', rest.className)}>
			<div
				className='df-row-ac jc-sb'
				style={{ paddingRight: theme.spacing(1.5) }}>
				<CardTitleTypography
					withPadding
					title={strings.documents}
				/>
				<Button
					variant='text'
					color='primary'
					onClick={onNew}
					startIcon={<AddIcon />}>
					{strings.newDocument}
				</Button>
			</div>
			<Divider />
			{queryResult.unfilteredCount > 0 && (
				<>
					<div className='df-row-ac jc-sb'>
						<SearchbarWithParams
							params={params}
							setParams={setParams}
							placeholder={strings.searchVerb}
							style={{ width: '400px' }}
							variant='embedded'
						/>
					</div>
					<Divider />
					<div className='df-row jc-sb'>
						<PatientDetailDocumentsFilter
							params={params}
							onFilter={params => setParams(onResetToPage1(params))}
							paddingLeft={theme.spacing(1.5)}
						/>
					</div>
					<Divider />
					<CardMedia style={{ paddingLeft: 12, paddingRight: 12 }}>
						<List disablePadding>
							{queryResult.values.map(doc => (
								<React.Fragment key={doc.id}>
									<DocumentListItem
										document={doc}
										onClick={() => onDetail(doc.id)}
										reload={reload}
										layout='horizontal'
										style={{ paddingTop: 4, paddingBottom: 4 }}
									/>
									{isLast(doc, queryResult.values) === false && <Divider />}
								</React.Fragment>
							))}
						</List>
						<TablePaging
							countResults={queryResult.filteredCount}
							pageSize={queryResult.pageSize}
							page={queryResult.zeroBasedPageIndex}
							pageParams={params.pageParams}
							setPageParams={val => setParams({ ...params, pageParams: val })}
						/>
					</CardMedia>
				</>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound style={{ padding: theme.spacing(1.5) }}>{strings.noWhatDefined(strings.documents)}</NoRecordsFound>}
		</Card>
	);
};
