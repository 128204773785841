import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		amountPaid: strings.amountPaid,
		created: strings.createdAt,
		outstandingBalance: strings.outstandingBalance,
		priceExVat: strings.priceExVat,
		priceInVat: strings.priceInVat,
		reference: strings.reference,
		supplierIdentifier: strings.supplier,
		validUntil: strings.validTo,
		vatAmount: strings.vatAmount,
	};
};
