import { DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { DraggableDialog } from 'framework/dialogs/DraggableDialog';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IAnnotationUpdateModel, annotationsCommand_update, annotationsQuery_single } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import * as yup from 'yup';
import { AnnotationFormField } from './AnnotationFormField';
import { AnnotationTypeFormField } from './AnnotationTypeFormField';

const createSchema = (strings: IStrings) => {
	return yup.object<IAnnotationUpdateModel>({
		content: yup.string().defined(),
		annotationTypeId: yup.string(),
	});
};

interface IProps extends DialogProps {
	id: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const UpdateAnnotationForm = ({ id, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [update, isSubmitting] = useFormSubmit(annotationsCommand_update);
	const [annotation] = useApiEffect(annotationsQuery_single, id);
	const { isStacked } = useDialogsContext();

	const handleSubmit = async (values: IAnnotationUpdateModel, helpers: FormikHelpers<IAnnotationUpdateModel>) => {
		const r = await update(id, values);
		if (handleValidateResponse(r, helpers, 'content')) {
			confirm();
		}
	};

	if (annotation === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IAnnotationUpdateModel>
			validateOnMount
			initialValues={{
				content: annotation.content,
				annotationTypeId: annotation.annotationTypeId ?? '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<DraggableDialog
					fullWidth
					title={strings.adjustWhat(strings.remark)}
					{...rest}
					open={rest.open && isStacked === false}>
					<DialogContent
						className='df-col'
						dividers>
						<AnnotationTypeFormField<IAnnotationUpdateModel> isFollowUp={annotation.isFollowUp} />
						<AnnotationFormField<IAnnotationUpdateModel> />
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</DraggableDialog>
			</Form>
		</Formik>
	);
};
