import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import React from 'react';

interface IProps {
	isUp: boolean;
	style?: React.CSSProperties;
}

export const ArrowDropDownOrUpIcon = ({ isUp, style }: IProps) => {
	if (isUp) {
		return (
			<ArrowDropUpIcon
				fontSize='small'
				style={style}
			/>
		);
	} else {
		return (
			<ArrowDropDownIcon
				fontSize='small'
				style={style}
			/>
		);
	}
};
