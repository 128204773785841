import React from 'react';
import { TextLinkButton } from './TextLinkButton';
import { ITextLinkButtonProps } from './ITextLinkButtonProps';
import { CircularProgress } from '@material-ui/core';

interface IProps extends ITextLinkButtonProps {
	isLoading: boolean;
}

export const TextLinkLoaderButton = ({ isLoading, disabled, startIcon, ...rest }: IProps) => {
	return (
		<TextLinkButton
			{...rest}
			disabled={isLoading || disabled}
			startIcon={
				isLoading ? (
					<div
						className='df-row-ac jc-c'
						style={{ width: 18, height: 18 }}>
						<CircularProgress
							style={{ color: 'inherit' }}
							size={14}
						/>
					</div>
				) : (
					startIcon
				)
			}
		/>
	);
};
