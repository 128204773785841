import React, { useContext } from 'react';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { ICommissionPayout, ICommissionPayoutsQueryParams, IQueryResult } from 'gen/ApiClient';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { createDetailsHeader } from 'shared/table/createDetailsHeader';
import { CommissionPayoutDetailDialog } from './CommissionPayoutDetailDialog';
import { ViewContext } from './context/ViewContext';
import { PayoutConfirmationStatusChip } from './PayoutConfirmationStatusChip';
import { SelectableColumn } from './SelectableColumn';

interface IProps {
	queryResult: IQueryResult<ICommissionPayout>;
	params: ICommissionPayoutsQueryParams;
	setParams: React.Dispatch<React.SetStateAction<ICommissionPayoutsQueryParams>>;
	reload: VoidFunction;
	withDeleteOption: boolean;
}

export const CommissionPayoutsTable = ({ queryResult, params, setParams, reload, withDeleteOption }: IProps) => {
	const { columns, headers } = useContext(ViewContext);
	const { open, confirm } = useDialogsContext(reload);

	const onViewDetail = (id: string) => {
		open(
			<CommissionPayoutDetailDialog
				open
				close={confirm}
				id={id}
				withActions={withDeleteOption}
			/>
		);
	};

	return (
		<PageableTableWithColumnSelection<ICommissionPayout, SelectableColumn>
			queryResult={queryResult}
			headers={headers}
			sortParams={params.sortParams}
			pageParams={params.pageParams}
			setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
			getKey={inst => inst.id}
			selectedColumns={columns}
			prependColumn={createEmptyHeader<ICommissionPayout>('confirmation', t => (
				<PayoutConfirmationStatusChip data={t} />
			))}
			appendColumn={createDetailsHeader<ICommissionPayout>(t => onViewDetail(t.id))}
		/>
	);
};
