import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IProductCatalogItem, ProductCatalogItemType } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (
	colRecord: Record<SelectableColumn, string>,
	typeRecord: Record<ProductCatalogItemType, string>,
	strings: IStrings
): Record<SelectableColumn, IHeader<IProductCatalogItem>> => {
	return {
		identifier: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'identifier', t => t.identifier),
		internalReference: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'internalReference', t => t.internalReference),
		manufacturerReferencesCsv: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(
			colRecord,
			'manufacturerReferencesCsv',
			t => t.manufacturerReferencesCsv
		),
		category: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'category', p => p.category),
		subcategory: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'subcategory', p => p.subcategory),
		salesListPrice: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(
			colRecord,
			'salesListPrice',
			p => formatCurrency(p.salesListPrice),
			'right'
		),
		vatPercentage: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'vatPercentage', p => `${p.vatPercentage}%`),
		type: createHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'type', p => typeRecord[p.type as ProductCatalogItemType]),
	};
};
