import { CardProps, Divider } from '@material-ui/core';
import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IRosaPatientRecordsQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';

const exclusions: (keyof IRosaPatientRecordsQueryParams)[] = ['isLinked'];

interface IProps extends CardProps {
	params: IRosaPatientRecordsQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IRosaPatientRecordsQueryParams>>;
}

export const RosaPatientRecordsListFilterCard = ({ params, setParams, ...rest }: IProps) => {
	const strings = useLocalization();
	const onFilter = (pars: IRosaPatientRecordsQueryParams) => setParams(onResetToPage1(pars));
	const isFilteredX = useMemo(() => isFiltered(params, exclusions), [params]);
	const onClear = () => setParams(onResetToPage1(clearParams(params, exclusions)));

	return (
		<CardX
			{...rest}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<RadioIsSelectedButton
				isSelected={params.isLinked === undefined}
				onClick={() => onFilter({ ...params, isLinked: undefined })}>
				{strings.all}
			</RadioIsSelectedButton>
			<RadioIsSelectedButton
				isSelected={params.isLinked === false}
				onClick={() => onFilter({ ...params, isLinked: false })}>
				{strings.notLinked}
			</RadioIsSelectedButton>
			<Divider />
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<DateFilterItem
					label={strings.lastUpdated}
					after={params.lastUpdatedAfter}
					before={params.lastUpdatedBefore}
					setSelected={(after, before) => onFilter({ ...params, lastUpdatedAfter: after, lastUpdatedBefore: before })}
				/>
			</div>
		</CardX>
	);
};
