import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React from 'react';
import { Try } from 'framework/Try';
import { IInventoryItem, IProductCatalogItem, IQueryResult } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { ICommonParams } from './ICommonParams';
import { SearchInventoryItemForProductCatalog } from './SearchInventoryItemForProductCatalog';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

interface IProps<TParams extends ICommonParams> {
	item: IProductCatalogItem;
	style?: React.CSSProperties;
	selectedIds: string[];
	defaultParams: TParams;
	queryF: (params: TParams) => Promise<Try<IQueryResult<IInventoryItem>>>;
	canSelectF: (item: IInventoryItem) => boolean;
	confirm: (item: IInventoryItem) => void;
	cancel: VoidFunction;
}

export const SearchInventoryItemForProductCatalogDialog = <TParams extends ICommonParams>({ confirm, cancel, ...props }: IProps<TParams>) => {
	const strings = useLocalization();
	const { isStacked } = useDialogsContext();

	return (
		<Dialog open={isStacked === false}>
			<DialogContent
				dividers
				style={{ padding: 0 }}>
				<SearchInventoryItemForProductCatalog
					{...props}
					onAdd={confirm}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant='text'
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
