import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	onClear: VoidFunction;
	isFiltered: boolean;
	style?: React.CSSProperties;
}

export const ClearFilterTextLinkButton = ({ onClear, isFiltered, style }: IProps) => {
	const strings = useLocalization();

	return (
		<TextLinkButton
			style={style}
			startIcon={<FilterOffOutlineIcon />}
			disabled={isFiltered === false}
			color={isFiltered ? 'secondary' : 'info'}
			hoverColor={isFiltered ? 'secondary' : 'info'}
			onClick={onClear}>
			{strings.clearFilter}
		</TextLinkButton>
	);
};
