import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import React, { useCallback } from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { FileDocumentEditOutlineIcon } from 'framework/components/icons/FileDocumentEditOutlineIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { formatDate } from 'framework/utils/date/formatDate';
import { loansCommand_patchStopDate, loansQuery_single, rfiaDocumentsCommand_createLoanSlip, rfiasQuery_singleTrial } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DocumentDetailDialog } from 'documents/DocumentDetailDialog';
import { SelectTemplateFromPredefinedTypeForm } from 'documents/SelectTemplateFromPredefinedTypeForm';
import { EndTrialAdministrativelyForm } from '../../forms/trial/EndTrialAdministrativelyForm';
import { TrialReportDialog } from '../../trialReport/TrialReportDialog';

interface IProps extends DialogProps {
	close: VoidFunction;
	rfiaId: string;
	trialId: string;
	patientId: string;
}

export const TrailStepDetailDialog = ({ rfiaId, trialId, patientId, close, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [trial, reloadTrial] = useApiEffect(rfiasQuery_singleTrial, rfiaId, trialId);
	const [loan, reloadLoan] = useApiEffect(loansQuery_single, trialId);

	const reload = useCallback(
		() => {
			reloadTrial();
			reloadLoan();
		},
		// eslint-disable-next-line
		[]
	);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);

	if (loan === undefined) {
		return <div></div>;
	}

	const onSetAdministrativeEnd = () => {
		open(
			<EndTrialAdministrativelyForm
				open
				confirm={confirm}
				cancel={cancel}
				rfiaId={rfiaId}
				loanId={loan.id}
				loanAdministrativeEndDate={loan.onlyAdministrativeTrialEndDate}
			/>
		);
	};

	const onPatchStopDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				formTitle={strings.changeWhat(strings.expectedWhat(strings.stopDate))}
				label={strings.expectedWhat(strings.stopDate)}
				initialValue={loan.stopDate}
				submitFunction={date => loansCommand_patchStopDate({ trialId: trialId, stopDate: date })}
			/>
		);
	};

	const onViewLoanSlip = () => {
		if (trial?.loanSlipId) {
			open(
				<DocumentDetailDialog
					id={trial.loanSlipId}
					open
					close={confirm}
				/>
			);
		} else {
			open(
				<SelectTemplateFromPredefinedTypeForm
					open
					type='RfiaLoanSlip'
					submitF={templateId => rfiaDocumentsCommand_createLoanSlip(rfiaId, trialId, templateId)}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	const onViewTrialReport = () => {
		open(
			<TrialReportDialog
				open
				rfiaId={rfiaId}
				patientId={patientId}
				trialId={trialId}
				close={confirm}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			fullWidth>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<HistoryIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{strings.trial}
					</Typography>
					<LabelledProperty
						label={strings.start}
						property={formatDate(loan.startDate)}
						withoutMargin
					/>
					<LabelledProperty
						label={`${strings.stop} (${strings.expected})`}
						property={formatDate(loan.stopDate)}
						edit={onPatchStopDate}
						withoutMargin
					/>
					<LabelledProperty
						label={`${strings.stop} (${strings.actual})`}
						property={formatDate(loan.actualStopDate)}
						withoutMargin
					/>
					<LabelledProperty
						label={`${strings.end} (${strings.administratively})`}
						property={formatDate(loan.onlyAdministrativeTrialEndDate)}
						withoutMargin
						edit={onSetAdministrativeEnd}
					/>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.loanedOutItems} />
				{loan.lines.map(t => (
					<PropertyInGrid
						key={t.id}
						value={t.description}
					/>
				))}
				<GridDividerWithCaption caption={strings.documents} />
				<PropertyInGrid
					label=''
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								startIcon={<FileDocumentEditOutlineIcon />}
								onClick={onViewLoanSlip}>
								{strings.viewEditWhat(strings.loanSlip)}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								startIcon={<FileDocumentEditOutlineIcon />}
								onClick={onViewTrialReport}>
								{strings.viewEditWhat(strings.trialReport)}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
