import { IHeader } from 'framework/table/IHeader';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { formatDate } from 'framework/utils/date/formatDate';
import { IApiTokenDto } from 'gen/ApiClient';
import { ApiTokensSelectableColumn } from './ApiTokensSelectableColumn';

export const createApiTokensHeaders = (colRecord: Record<ApiTokensSelectableColumn, string>): Record<ApiTokensSelectableColumn, IHeader<IApiTokenDto>> => {
	return {
		created: createSortableHeaderWithKey<IApiTokenDto, ApiTokensSelectableColumn>(colRecord, 'created', s => formatDate(s.created)),
		computerName: createSortableHeaderWithKey<IApiTokenDto, ApiTokensSelectableColumn>(colRecord, 'computerName', t => t.computerName),
		clientId: createSortableHeaderWithKey<IApiTokenDto, ApiTokensSelectableColumn>(colRecord, 'clientId', t => t.clientId),
		firstFourKeyChars: createSortableHeaderWithKey<IApiTokenDto, ApiTokensSelectableColumn>(
			colRecord,
			'firstFourKeyChars',
			t => `${t.firstFourKeyChars}********`
		),
	};
};
