import { formatCurrency } from 'framework/utils/formatCurrency';
import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { IKeyValuePair } from '../IKeyValuePair';

interface IProps {
	data: IKeyValuePair[];
	seriesName: string;
}

const BasicAreaChart = ({ data, seriesName }: IProps) => {
	const [values, keys] = useMemo<[number[], string[]]>(() => [data.map(t => t.value), data.map(t => t.key ?? 'n/a')], [data]);

	return (
		<ReactApexChart
			height={280}
			type='area'
			series={[{ name: seriesName, data: values }]}
			options={{
				chart: {
					type: 'area',
				},
				fill: {
					type: 'gradient',
					gradient: {
						shadeIntensity: 1,
						opacityFrom: 0.7,
						opacityTo: 0.9,
						stops: [0, 90, 100],
					},
				},
				xaxis: {
					type: 'category',
					categories: keys,
					tickAmount: 6,
				},
				dataLabels: {
					enabled: false,
				},
				yaxis: {
					min: 0,

					labels: {
						formatter: val => formatCurrency(val),
					},
				},
				markers: {
					size: 5,
				},
				tooltip: {},
			}}
		/>
	);
};

export default BasicAreaChart;
