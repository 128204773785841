import { Popover } from '@material-ui/core';
import React, { useState } from 'react';
import { CirclePicker } from 'react-color';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Try } from 'framework/Try';
import { IValidateResponse } from 'gen/ApiClient';
import { DefaultColors } from './DefaultColors';

interface IProps {
	submitF: (color: string) => Promise<Try<IValidateResponse>>;
	initialColor: string;
	reload: VoidFunction;
	colors?: string[];
	circleSize?: number;
	width?: string | undefined;
}

export const ColorPickerWrapper = ({ submitF, initialColor, reload, colors = DefaultColors, circleSize = 20, width = '40px' }: IProps) => {
	const [anchor, open, close] = useAnchorElement();
	const [update, isSubmitting] = useFormSubmit(submitF);
	const [color, setColor] = useState<string>(initialColor);

	const onSubmit = async (color: string) => {
		close();
		setColor(color);
		const r = await update(color);
		if (r.isSuccess) {
			reload();
		} else {
			setColor(color);
		}
	};

	return (
		<>
			{Boolean(anchor) && (
				<Popover
					open={Boolean(anchor)}
					anchorEl={anchor}
					onClose={close}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}>
					<div style={{ padding: 20 }}>
						<CirclePicker
							color={color}
							onChange={e => onSubmit(e.hex)}
							circleSize={20}
							circleSpacing={8}
							colors={colors}
							width='170px'
						/>
					</div>
				</Popover>
			)}
			<div onClick={isSubmitting ? undefined : open}>
				<CirclePicker
					colors={[color]}
					circleSize={circleSize}
					circleSpacing={8}
					width={width}
				/>
			</div>
		</>
	);
};
