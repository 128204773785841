import React from 'react';
import { Menu, MenuProps } from '@material-ui/core';

export const FilterMenu = (props: MenuProps) => {
	return (
		<Menu
			{...props}
			PaperProps={{ style: { minWidth: '240px' } }}
		/>
	);
};
