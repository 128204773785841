import { Card, CardContent, CardProps } from '@material-ui/core';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { calcCustomerAccountDetailRoute, calcSalesDetailRoute } from 'app/main/financial/routes';
import { SaleForm } from 'app/main/financial/sales/forms/SaleForm';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { isLast } from 'framework/utils/array/isLast';
import { ISalesByPatientResponse } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SaleByPatientListComponent } from './SaleByPatientListComponent';

interface IProps extends CardProps {
	hasLinkedCustomerAccount: boolean;
	data: ISalesByPatientResponse;
	reload: VoidFunction;
}

export const SalesByPatientCard = ({ hasLinkedCustomerAccount, data, reload, ...rest }: IProps) => {
	const strings = useLocalization();
	const [expandedId, setExpandedId] = useState<string>();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();

	const onAdd = () => {
		open(
			<SaleForm
				forPatientId={data.patientId}
				confirm={id => {
					confirm();
					if (id) {
						push(calcSalesDetailRoute(id));
					}
				}}
				cancel={cancel}
			/>
		);
	};

	return (
		<Card {...rest}>
			<CardContent>
				<CardTitleTypography
					title={strings.sales}
					style={{ marginBottom: 8 }}
				/>
				<TextLinkButton
					startIcon={<AddCircleOutlineIcon />}
					onClick={onAdd}
					color='primary'>
					{strings.registerNewSale}
				</TextLinkButton>
				{hasLinkedCustomerAccount && (
					<TextLinkButton
						underline
						startIcon={<AccountBalanceWalletOutlinedIcon />}
						onClick={() => push(calcCustomerAccountDetailRoute(data.patientId))}>
						{strings.viewLinkedCustomerAccount}
					</TextLinkButton>
				)}
				<DividerWithMargin />
				{data.sales.map(t => (
					<SaleByPatientListComponent
						item={t}
						key={t.id}
						isExpanded={expandedId === t.id}
						toggle={() => (expandedId === t.id ? setExpandedId(undefined) : setExpandedId(t.id))}
						patientId={data.patientId!}
						withoutBorder={isLast(t, data.sales)}
					/>
				))}
				{data.count === 0 && <em>{strings.noSalesRegisteredYet}</em>}
			</CardContent>
		</Card>
	);
};
