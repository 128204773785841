import { Divider } from '@material-ui/core';
import React, { useMemo } from 'react';

interface IProps {
	leftContent: JSX.Element;
	rightContent: JSX.Element;
	rightBottomFixedContent?: JSX.Element;
	withoutNegativeMargin?: boolean;
}

export const LeftRightDividedContentScrollYRight = ({ leftContent, rightContent, rightBottomFixedContent, withoutNegativeMargin }: IProps) => {
	const style = useMemo<React.CSSProperties>(() => (withoutNegativeMargin === true ? {} : { margin: '-16px -24px' }), [withoutNegativeMargin]);

	return (
		<div
			className='df-row fg1 h100'
			style={style}>
			<div
				className='h100'
				style={{ padding: 16, paddingLeft: 24 }}>
				{leftContent}
			</div>
			<Divider
				orientation='vertical'
				flexItem
				style={{ marginTop: 16, marginBottom: 16 }}
			/>
			<div className='df-col h100 fg1'>
				<div style={{ height: '100%', overflowY: 'auto', padding: 16, paddingBottom: 0, paddingRight: 24 }}>{rightContent}</div>
				{rightBottomFixedContent && (
					<div
						className='df-col'
						style={{ padding: 16, paddingTop: 0, paddingRight: 24 }}>
						<Divider />
						{rightBottomFixedContent}
					</div>
				)}
			</div>
		</div>
	);

	// return (
	//     <div className="df-row fg1 h100" style={{ margin: '-16px -24px' }}>
	//         <div className="h100" style={{ padding: 16, paddingLeft: 24 }}>{leftContent}</div>
	//         <Divider orientation="vertical" flexItem style={{ marginTop: 16, marginBottom: 16 }} />
	//         <div className="h100 fg1" style={{ overflowY: 'auto', padding: 16, paddingRight: 24 }}>{rightContent}</div>
	//     </div>
	// );

	// return (
	//     <div className="df-row fg1 h100" style={{ margin: '-16px -24px' }}>
	//         <div className="h100" style={{ padding: 16, paddingLeft: 24 }}>{leftContent}</div>
	//         <Divider orientation="vertical" flexItem style={{ marginTop: 16, marginBottom: 16 }} />
	//         <div className="h100 fg1" style={{ overflowY: 'hidden'}}>
	//             <div className="fg1 h100" style={{ overflowY: 'auto'}}>{rightContent}</div>
	//             <div className="df-row-ae">TOTALS: 100 euro</div>
	//         </div>
	//     </div>
	// );
};

//
