import React from 'react';

interface IProps {
	color: string;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	style?: React.CSSProperties;
}

export const ColorSquareWithoutStyling = ({ color, onClick, style }: IProps) => {
	return (
		<div
			style={style}
			onClick={onClick}>
			<div style={{ borderRadius: '4px', backgroundColor: color, height: '14px', width: '14px' }}></div>
		</div>
	);
};
