import React from 'react';
import {
	IInventoryItem,
	IInventoryItemsQueryParamsForLoan,
	inventoryItemsQuery_queryForLoan,
	IProductCatalogItem,
	IQueryResult,
	IStockAtLocation,
	IStockItemsQueryParamsForLoan,
} from 'gen/ApiClient';
import { DefaultInventoryItemsQueryParamsForLoan } from './DefaultInventoryItemsQueryParamsForLoan';
import { ISearchAndSelectLine } from './ISearchAndSelectLine';
import { SearchWithTabsContainer } from './SearchWithTabsContainer';

interface IProps<TLine extends ISearchAndSelectLine> {
	lines: TLine[];
	wizardContent?: JSX.Element;
	iiParams: IInventoryItemsQueryParamsForLoan;
	setIIParams: React.Dispatch<React.SetStateAction<IInventoryItemsQueryParamsForLoan>>;
	iiQueryResult: IQueryResult<IInventoryItem> | undefined;
	pcParams: IStockItemsQueryParamsForLoan;
	setPcParams: React.Dispatch<React.SetStateAction<IStockItemsQueryParamsForLoan>>;
	pcQueryResult: IQueryResult<IProductCatalogItem> | undefined;
	onAddPc: (item: IProductCatalogItem, stockAtLocations: IStockAtLocation[]) => void;
	onAddII: (item: IInventoryItem, pc: IProductCatalogItem) => void;
}

export const SearchProductsForLoanOrTrialTabs = <TLine extends ISearchAndSelectLine>({ ...props }: IProps<TLine>) => {
	return (
		<SearchWithTabsContainer<TLine, IInventoryItemsQueryParamsForLoan, IStockItemsQueryParamsForLoan>
			{...props}
			canSelectInventoryItemF={t => true}
			productCatalogFilterType='stock'
			queryInventoryItemF={inventoryItemsQuery_queryForLoan}
			defaultIIParams={DefaultInventoryItemsQueryParamsForLoan}
		/>
	);
};
