import React from 'react';
import { UsersCacheProvider } from './UsersCacheProvider';
import { UsersQueryProvider } from './UsersQueryProvider';

export const UsersProviders = ({ children }: any) => {
	return (
		<UsersCacheProvider>
			<UsersQueryProvider>{children}</UsersQueryProvider>
		</UsersCacheProvider>
	);
};
