import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useMemo } from 'react';

interface IProps<TLine extends { unitPrice: number; quantity: number }> {
	lines: TLine[];
	isShiftVat: boolean;
	style?: React.CSSProperties;
}

export const TotalsExVatTable = <TLine extends { unitPrice: number; quantity: number; vatPercentage: number }>({ lines, style, isShiftVat }: IProps<TLine>) => {
	const strings = useLocalization();
	const exVatTotal = useMemo(() => _.sum(lines.map(t => t.unitPrice * t.quantity)), [lines]);
	const inVatTotal = useMemo(() => _.sum(lines.map(t => t.unitPrice * t.quantity * (isShiftVat ? 1 : 1 + t.vatPercentage / 100))), [lines, isShiftVat]);

	return (
		<TableContainer style={style}>
			<Table size='small'>
				<TableBody>
					<TableRow>
						<TableCell>{`${strings.total} (${strings.exVatShort})`}</TableCell>
						<TableCell style={{ textAlign: 'right' }}>{formatCurrency(exVatTotal)}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{`${strings.vatAmount}`}</TableCell>
						<TableCell style={{ textAlign: 'right' }}>{formatCurrency(inVatTotal - exVatTotal)}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{`${strings.total} (${strings.inVatShort})`}</TableCell>
						<TableCell style={{ textAlign: 'right' }}>{formatCurrency(inVatTotal)}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};
