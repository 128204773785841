import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { IInventoryItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { InventoryStatusChip } from './InventoryStatusChip';

interface IProps {
	item: IInventoryItem;
	direction?: 'row' | 'column';
	style?: React.CSSProperties;
}

export const InventoryItemChips = ({ item, direction = 'row', style }: IProps) => {
	const strings = useLocalization();
	const margin = useMemo(() => (direction === 'column' ? '1px' : '0'), [direction]);

	return (
		<div
			className={direction === 'row' ? 'df-row-ac' : 'df-col-as'}
			style={style}>
			{item.isConsignmentExpired && (
				<TooltipWithSpan
					title={strings.consignmentExpired}
					style={{ display: 'flex' }}>
					<StatusIcon
						status='error'
						style={{ marginRight: 8 }}
					/>
				</TooltipWithSpan>
			)}
			{item.isConsignmentExpired === false && item.consignmentExpiresWithinAWeek && (
				<TooltipWithSpan
					title={strings.consignmentExpiresWithinAWeek}
					style={{ display: 'flex' }}>
					<StatusIcon
						status='warning'
						style={{ marginRight: 8 }}
					/>
				</TooltipWithSpan>
			)}
			<InventoryStatusChip
				item={item}
				style={{ marginRight: 8, marginTop: margin, marginBottom: margin }}
			/>
			{item.isDemo && (
				<RectangularChip
					fill
					color='error'
					label={strings.demo}
					style={{ marginRight: 8, marginTop: margin, marginBottom: margin }}
				/>
			)}
			{item.isReserved && (
				<RectangularChip
					tooltip={strings.reserved}
					color='warning'
					label={strings.reservedShort}
					style={{ marginRight: 8, marginTop: margin, marginBottom: margin }}
				/>
			)}
			{item.isTailorMade && (
				<RectangularChip
					tooltip={strings.isTailorMade}
					color={item.hasTailorMadeForPatient ? 'warning' : 'error'}
					fill={item.hasTailorMadeForPatient === false}
					label={strings.tailorMadeShort}
					style={{ marginRight: 8, marginTop: margin, marginBottom: margin }}
				/>
			)}
			{item.hasSwitchedSerialNumber && (
				<RectangularChip
					fill
					color='error'
					tooltip={strings.newSerialNumber}
					label={strings.newSerialNumberShort}
					style={{ marginRight: 8, marginTop: margin, marginBottom: margin }}
				/>
			)}
		</div>
	);
};
