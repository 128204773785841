import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';

interface IProps {
	isChecked: boolean;
	toggle: VoidFunction;
	label: string;
}

export const FormControlLabelCheckbox = ({ isChecked, toggle, label }: IProps) => {
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={isChecked}
					onChange={toggle}
				/>
			}
			label={<div>{label}</div>}
		/>
	);
};
