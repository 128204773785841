import { Chip } from '@material-ui/core';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { useIsRosaIntegrationEnabled } from 'app/main/integrations/rosa/useIsRosaIntegrationEnabled';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { dashboardQuery_data } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { RestrictedPage } from 'shared/layout/RestrictedPage';
import { useIsExactOnlineIntegrationEnabled } from '../integrations/exactOnline/useIsExactOnlineIntegrationEnabled';
import { useIsInvoiceForwardingIntegrationEnabled } from '../integrations/invoiceForwarding/useIsInvoiceForwardingIntegrationEnabled';
import { DashboardKpisGrid } from './DashboardKpisGrid';
import { DashboardProcessesGrid } from './DashboardProcessesGrid';
import { ExactOnlineDashboardCard } from './ExactOnlineDashboardCard';
import { InvoiceForwardingDashboardCard } from './InvoiceForwardingDashboardCard';
import { KpiSelector } from './KpiSelector';
import { RosaDashboardCard } from './RosaDashboardCard';
import { NotificationsCard } from './components/NotificationsCard';
import { SoftwareCard } from './components/SoftwareCard';
import { TodayEventsCardSwitch } from './components/TodayEventsCardSwitch';
import { UsefulLinksCard } from './components/UsefulLinksCard';

export const Dashboard = () => {
	const strings = useLocalization();
	const [trigger, hitTrigger] = useTrigger();
	const [data, reload] = useApiEffect(dashboardQuery_data);
	const user = useLoggedInUser();
	const [selector, setSelector] = useState<KpiSelector>('last30');
	const isRosaEnabled = useIsRosaIntegrationEnabled();
	const isExactOnlineEnabled = useIsExactOnlineIntegrationEnabled();
	const isInvoiceForwardingEnabled = useIsInvoiceForwardingIntegrationEnabled();

	useLazyEffect(() => {
		reload();
	}, [trigger]);

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPage
			pageClaim='Dashboard'
			showHome
			reload={hitTrigger}
			title={`${strings.welcomeBack}, ${user?.firstName}`}
			styleRightContent={{ width: '100%' }}
			details={
				<div className='df-col gap-16'>
					<TodayEventsCardSwitch reloadTrigger={trigger} />
					<NotificationsCard notifications={data.notifications} />
					<SoftwareCard />
					<UsefulLinksCard />
					{isRosaEnabled && <RosaDashboardCard reloadTrigger={trigger} />}
					{isExactOnlineEnabled && <ExactOnlineDashboardCard reloadTrigger={trigger} />}
					{isInvoiceForwardingEnabled && <InvoiceForwardingDashboardCard reloadTrigger={trigger} />}
				</div>
			}
			detailsFixedWidth={350}
			styleDetailsContent={{ maxWidth: '350px' }}>
			<>
				{/* <DashBoardAlert /> */}
				<DividerWithCaption caption={strings.followUpVerb} />
				{data.hasSummaryData && <DashboardProcessesGrid data={data} />}
				{data.hasSummaryData === false && <NoRecordsFound>{strings.noFollowUpProcesses}</NoRecordsFound>}
				<DividerWithCaption
					caption="KPI's"
					action={
						<Chip
							label={selector === 'last30' ? strings.lastWhatDays('30') : strings.lastWhatDays('7')}
							color='primary'
							size='small'
							style={{ fontSize: '10px', cursor: 'pointer' }}
							onClick={() => setSelector(selector === 'last30' ? 'last7' : 'last30')}
							variant={selector === 'last30' ? 'default' : 'outlined'}
						/>
					}
				/>
				{data.canShowKpis && (
					<DashboardKpisGrid
						data={data}
						selector={selector}
					/>
				)}
				{data.canShowKpis === false && <NoRecordsFound>{strings.noDataInHams}</NoRecordsFound>}
			</>
		</RestrictedPage>
	);
};
