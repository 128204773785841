import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { TendersRoute } from '../routes';

export const TendersPageBreadcrump = () => {
	const strings = useLocalization();

	return (
		<PageBreadcrumb
			text={strings.tenders}
			route={TendersRoute}
		/>
	);
};
