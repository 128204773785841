import { Divider, TablePagination } from '@material-ui/core';
import React from 'react';
import { IPageParams } from '../types/IPageParams';
import { makeANumber } from '../utils/makeANumber';

interface IProps {
	countResults: number;
	pageSize: number;
	page: number;
	// queryResult: IQueryResult<T>;
	pageParams: IPageParams;
	setPageParams: (pageParams: IPageParams) => void;
	rowsPerPageOptions?: number[];
	removeBorder?: boolean;
	style?: React.CSSProperties;
}
export const TablePaging = ({
	countResults,
	pageSize,
	page,
	pageParams,
	setPageParams,
	rowsPerPageOptions = [5, 10, 20, 50, 100],
	removeBorder = false,
	style,
}: IProps) => {
	return (
		<>
			{removeBorder === false && <Divider />}
			<TablePagination
				rowsPerPageOptions={rowsPerPageOptions}
				component='div'
				count={countResults}
				rowsPerPage={pageSize}
				page={page}
				onChangePage={(e, page) => setPageParams({ ...pageParams, index: page + 1 })}
				onChangeRowsPerPage={e => setPageParams({ index: 1, size: makeANumber(e.target.value) })}
				style={{ minHeight: '52px', ...style }}
			/>
		</>
	);
};
