import { Dialog, DialogProps } from '@material-ui/core';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SlideUpTransition } from '../components/transitions/SlideUpTransition';
import { DialogTitleWithFormStepper } from './DialogTitleWithFormStepper';

interface IProps extends DialogProps {
	step: number;
	fullScreenStep: number;
	children: any;
	title: string;
	labels: string[];
}

export const FullScreenDialogWithStepper = ({ step, fullScreenStep, title, labels, children, ...rest }: IProps) => {
	const { isStacked } = useDialogsContext();

	return (
		<Dialog
			{...rest}
			open={rest.open && (step === fullScreenStep || isStacked === false)}
			fullScreen={step === fullScreenStep}
			TransitionComponent={SlideUpTransition}
			scroll='paper'>
			<DialogTitleWithFormStepper
				step={step}
				title={title}
				labels={labels}
			/>
			{children}
		</Dialog>
	);
};
