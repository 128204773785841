import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';
import { LoggedInUserContext } from './LoggedInUserContext';

export const LoggedInUserProvider = ({ children }: any) => {
	const { user } = useContext(AuthContext);

	if (user === undefined) {
		return children;
	}

	return (
		<LoggedInUserContext.Provider
			value={{
				user: user,
			}}>
			{children}
		</LoggedInUserContext.Provider>
	);
};
