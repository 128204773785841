import { useTheme } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { ChipWithAvatar } from 'framework/components/ChipWithAvatar';
import { IRizivHearingAidDefinitionV2IdAndDistributorDto } from 'gen/ApiClient';

interface IProps {
	item: IRizivHearingAidDefinitionV2IdAndDistributorDto;
	onClick?: (x: IRizivHearingAidDefinitionV2IdAndDistributorDto) => void;
}

export const SingleItem = ({ item, onClick }: IProps) => {
	const theme = useTheme();

	return (
		<div className='df-row-ac'>
			{item.distributor && <ChipWithAvatar label={item.distributor ?? ''} />}
			<div style={{ marginLeft: theme.spacing(0.5), marginRight: theme.spacing(0.5) }}>{item.identificationCode}</div>
			{onClick && (
				<OpenInNewIcon
					fontSize='small'
					style={{ cursor: 'pointer', color: theme.palette.primary.main }}
					onClick={() => onClick(item)}
				/>
			)}
		</div>
	);
};
