import React from 'react';

interface IProps {
	value: any;
	onClick?: VoidFunction;
	style?: React.CSSProperties;
}

export const CodeBlock = ({ value, style, onClick }: IProps) => {
	return (
		<pre style={style}>
			<code
				onClick={onClick}
				style={{ cursor: onClick === undefined ? 'inherit' : 'pointer' }}>
				{JSON.stringify(value, null, 1)}
			</code>
		</pre>
	);
};
