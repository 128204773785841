import { DocumentFromTemplateForm } from 'documents/DocumentFromTemplateForm';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { tendersQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { TenderDetailContentsCard } from './TenderDetailContentsCard';
import { TenderDetailOverviewHeaderCard } from './TenderDetailOverviewHeaderCard';
import { TenderDetailPageHeader } from './TenderDetailPageHeader';
import { TenderDetailPageSidebar } from './TenderDetailPageSidebar';
import { TenderDetailsTotalsBreakdownCard } from './TenderDetailsTotalsBreakdownCard';

export const TenderDetailPage = () => {
	const strings = useLocalization();
	const { id } = useParams<IRouteParamsWithId>();
	const [item, reload] = useApiEffect(tendersQuery_single, id);
	const [hasChecked, setHasChecked] = React.useState<boolean>(false);
	const { open, confirm, cancel } = useDialogsContext(reload);

	React.useEffect(() => {
		if (item !== undefined) {
			setHasChecked(true);
		}
		if (item !== undefined && item.hasTenderDocument === false && hasChecked === false) {
			open(
				<DocumentFromTemplateForm
					open
					context='Tender'
					contextId={item.id}
					templatePredefinedType='Tender'
					confirm={confirm}
					cancel={cancel}
					title={`${strings.createADocumentRightAwayQuestion} (${strings.tender})`}
					submitText={strings.selectWhatAndCreate(strings.template)}
					cancelText={strings.closeThisWindow}
				/>
			);
		}
		// eslint-disable-next-line
	}, [item, hasChecked]);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='Tenders'
			maxWidth={1280}
			stickyHeader={
				<TenderDetailPageHeader
					item={item}
					reload={reload}
				/>
			}
			stickySidebar={
				<TenderDetailPageSidebar
					item={item}
					reload={reload}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
					maxWidth: 1280,
				}}>
				<TenderDetailOverviewHeaderCard
					item={item}
					reload={reload}
				/>
				<div className='df-row gap-16'>
					<TenderDetailContentsCard
						item={item}
						className='fg1 h-fc'
					/>
					<TenderDetailsTotalsBreakdownCard
						item={item}
						className='h-fc'
					/>
				</div>
			</div>
		</RestrictedPageX>
	);
};
