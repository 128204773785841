import { Button, Divider, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import React from 'react';
import { DownloadMultipleIcon } from 'framework/components/icons/DownloadMultipleIcon';
import { ExcelIcon } from 'framework/components/icons/ExcelIcon';
import { FileDelimitedOutlineIcon } from 'framework/components/icons/FileDelimitedOutlineIcon';
import { FilterOutlineIcon } from 'framework/components/icons/FilterOutlineIcon';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { ExportFormat } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	filteredCount: number;
	onExportClick: (format: ExportFormat) => void;
	children?: any;
}

export const SearchEventsExportButtonAndMenu = ({ filteredCount, onExportClick, children }: IProps) => {
	const strings = useLocalization();
	const [anchor, open, close] = useAnchorElement();

	const onMenuClick = (format: ExportFormat) => {
		onExportClick(format);
		close();
	};

	return (
		<>
			<Button
				color='primary'
				size='small'
				startIcon={<DownloadMultipleIcon />}
				onClick={open}>
				{strings.exportVerb}
			</Button>
			<Menu
				anchorEl={anchor}
				keepMounted
				open={Boolean(anchor)}
				onClose={close}>
				<MenuItem onClick={() => onMenuClick('Xlsx')}>
					<ListItemIcon>
						<FilterOutlineIcon fontSize='small' />
						<ExcelIcon fontSize='small' />
					</ListItemIcon>
					<Typography variant='inherit'>{strings.exportFilteredResultsCountInFormat(filteredCount, '.xlsx')}</Typography>
				</MenuItem>
				<Divider />
				<MenuItem onClick={() => onMenuClick('Csv')}>
					<ListItemIcon>
						<FilterOutlineIcon fontSize='small' />
						<FileDelimitedOutlineIcon fontSize='small' />
					</ListItemIcon>
					<Typography variant='inherit'>{strings.exportFilteredResultsCountInFormat(filteredCount, '.csv')}</Typography>
				</MenuItem>
				{children && (
					<>
						<Divider />
						{children}
					</>
				)}
			</Menu>
		</>
	);
};
