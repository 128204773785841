import React from 'react';
import { ColoredLinearProgress } from 'framework/components/ColoredLinearProgress';
import { ISubscriptionSummary } from 'gen/ApiClient';

interface IProps {
	item: ISubscriptionSummary;
}

export const SubscriptionProgress = ({ item }: IProps) => {
	if (item.isUpToDate) {
		return (
			<ColoredLinearProgress
				value={100}
				color='success'
				style={{ width: '80px' }}
			/>
		);
	} else if (item.isProcessingBacklog) {
		return (
			<ColoredLinearProgress
				value={item.backlog > 1000 ? 0 : (1000 - item.backlog) / 10}
				color='warning'
				style={{ width: '80px' }}
			/>
		);
	} else {
		return (
			<ColoredLinearProgress
				value={0}
				color='error'
				style={{ width: '80px' }}
			/>
		);
	}
};
