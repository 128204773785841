import { Dialog, DialogContent, DialogProps, Divider } from '@material-ui/core';
import { useLoggedInLocation } from 'app/auth/useLoggedInLocation';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { Form, Formik, FormikHelpers } from 'formik';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatient, IRfiaModel, rfiasCommand_new, rfiasQuery_settings } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { FormSelectHealthInsuranceFundField } from 'shared/forms/FormSelectHealthInsuranceFundField';
import * as yup from 'yup';
import { FormSelectOrCreateEntPhysicianWithOfficeField } from '../../../../contacts/earNoseThroatPhysicians/forms/FormSelectOrCreateEntPhysicianWithOfficeField';
import { FormSelectLocationField } from '../../../../settings/locations/forms/FormSelectLocationField';
import { FormSelectUserField } from '../../../../settings/users/forms/FormSelectUserField';
import { FormInvoiceProcessStepsField } from '../FormInvoiceProcessStepsField';

const createSchema = (strings: IStrings, isWithPrescription: boolean) => {
	return yup
		.object<IRfiaModel>({
			patientId: yup.string().required(strings.formRequired(strings.patient)),
			applyThirdPartyPaymentScheme: yup.boolean().defined(),
			entPhysicianId: isWithPrescription ? yup.string().required(strings.formRequired(strings.entPhysician)) : yup.string(),
			entPhysicianOfficeId: yup.string(),
			healthInsuranceFundCode: yup.string(),
			prescriptionForTrialDate: isWithPrescription ? yup.date().required(strings.formRequired(strings.prescriptionForTrialDate)) : yup.date(),
			steps: yup.mixed(),
			attendingAudiologistId: yup.string().required(strings.formRequired(strings.audiologist)),
			locationId: yup.string().required(strings.formRequired(strings.location)),
		})
		.defined();
};

const emptyValues: IRfiaModel = {
	patientId: '',
	prescriptionForTrialDate: undefined,
	entPhysicianId: '',
	entPhysicianOfficeId: '',
	healthInsuranceFundCode: '',
	applyThirdPartyPaymentScheme: false,
	steps: [],
	attendingAudiologistId: '',
	locationId: '',
};

const stepsRecord: Record<number, (keyof IRfiaModel)[]> = {
	0: ['prescriptionForTrialDate', 'entPhysicianId', 'entPhysicianOfficeId'],
	1: ['applyThirdPartyPaymentScheme', 'healthInsuranceFundCode'],
	2: ['steps'],
	3: ['attendingAudiologistId', 'locationId'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	patient: IPatient;
}

export const RfiaModelForm = ({ confirm, cancel, patient, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(rfiasCommand_new);
	const [step, setStep] = useState<number>(0);
	const [settings] = useApiEffect(rfiasQuery_settings);
	const user = useLoggedInUser();
	const locationId = useLoggedInLocation();
	const [isWithPrescription, setIsWithPrescription] = useState<boolean>(true);
	const schema = useMemo(() => createSchema(strings, isWithPrescription), [strings, isWithPrescription]);

	if (settings === undefined) {
		return <div></div>;
	}

	const handleSubmit = async (values: IRfiaModel, helpers: FormikHelpers<IRfiaModel>) => {
		const r = await create({ ...values, prescriptionForTrialDate: isWithPrescription ? values.prescriptionForTrialDate : undefined });
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.addedWhat(strings.requestForInsuranceAllowance));
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<IRfiaModel>
			validateOnMount
			initialValues={{
				...emptyValues,
				patientId: patient.id,
				healthInsuranceFundCode: patient.healthInsuranceFundCode,
				applyThirdPartyPaymentScheme: settings.defaultApplyThirdPartyScheme,
				steps: settings.defaultSteps,
				entPhysicianId: patient.attendingEntPhysicianId ?? '',
				attendingAudiologistId: patient.attendingAudiologistId ?? user.id,
				locationId: patient.locationId ?? locationId ?? '',
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					isWithPrescription={isWithPrescription}
					setIsWithPrescription={setIsWithPrescription}
					schema={schema}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	isWithPrescription: boolean;
	setIsWithPrescription: React.Dispatch<React.SetStateAction<boolean>>;
	schema: yup.ObjectSchema<IRfiaModel>;
}

const InnerDialog = ({ step, setStep, cancel, isSubmitting, schema, isWithPrescription, setIsWithPrescription, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const { isStacked } = useDialogsContext();

	return (
		<Dialog
			scroll='paper'
			maxWidth='lg'
			{...rest}
			open={rest.open && isStacked === false}>
			<DialogTitleWithFormStepper
				title={strings.newRequestForInsuranceAllowance}
				step={step}
				labels={[strings.prescription, strings.insurance, strings.steps, strings.audiologist]}
			/>
			<DialogContent
				className='df-col'
				dividers>
				{step === 0 && (
					<>
						<ExtendedRadioGroup<boolean>
							selected={isWithPrescription}
							setSelected={setIsWithPrescription}
							options={[
								{ value: true, label: strings.withWhat(strings.prescriptionForTrial), caption: strings.withPrescriptionForTrialCaption },
								{ value: false, label: strings.withoutWhat(strings.prescriptionForTrial), caption: strings.withoutPrescriptionForTrialCaption },
							]}
						/>
						<Divider style={{ marginTop: 16, marginBottom: 16 }} />
						{isWithPrescription && (
							<FormDatePicker<IRfiaModel>
								name='prescriptionForTrialDate'
								label={strings.prescriptionForTrialDate}
							/>
						)}
						<FormSelectOrCreateEntPhysicianWithOfficeField<IRfiaModel>
							nameEnt='entPhysicianId'
							labelEnt={strings.entPhysician}
							nameOffice='entPhysicianOfficeId'
							labelOffice={strings.office}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormExtendedRadioGroupField<IRfiaModel, boolean>
							name='applyThirdPartyPaymentScheme'
							label='applyThirdPartyPaymentScheme'
							options={[
								{ value: true, label: strings.withWhat(strings.thirdPayerArrangement), caption: strings.withThirdPayerArrangementCaption },
								{ value: false, label: strings.withoutWhat(strings.thirdPayerArrangement), caption: strings.withoutThirdPayerArrangementCaption },
							]}
						/>
						<Divider style={{ marginTop: 16, marginBottom: 16 }} />
						<FormSelectHealthInsuranceFundField<IRfiaModel>
							name='healthInsuranceFundCode'
							label={strings.healthInsuranceFund}
						/>
					</>
				)}
				{step === 2 && <FormInvoiceProcessStepsField<IRfiaModel> />}
				{step === 3 && (
					<>
						<FormSelectUserField<IRfiaModel>
							name='attendingAudiologistId'
							label={strings.audiologist}
						/>
						<FormSelectLocationField<IRfiaModel>
							name='locationId'
							label={strings.location}
						/>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
