import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { IOrdersQueryMetadata, OrderPrefilter, OrderPrefilters } from 'gen/ApiClient';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { createOrderPrefilterBadgeChipXRecord } from './createOrderPrefilterBadgeChipXRecord';

interface IProps {
	selectedPrefilter: OrderPrefilter;
	setSelectedPrefilter: (key: OrderPrefilter) => void;
	metadata: IOrdersQueryMetadata;
	style?: React.CSSProperties;
}

export const OrdersListPrefilter = ({ selectedPrefilter, setSelectedPrefilter, metadata, style }: IProps) => {
	const endIconRecord = useMemo(() => createOrderPrefilterBadgeChipXRecord(metadata), [metadata]);
	const { orderPrefilterRecord: orderPrefilterKeyTypeRecord } = useContext(RecordContext);

	return (
		<div
			className='df-col'
			style={style}>
			{OrderPrefilters.map(prefilter => (
				<RadioIsSelectedButton
					key={prefilter}
					isSelected={prefilter === selectedPrefilter}
					onClick={() => setSelectedPrefilter(prefilter)}
					endIcon={endIconRecord[prefilter]}>
					{orderPrefilterKeyTypeRecord[prefilter]}
				</RadioIsSelectedButton>
			))}
		</div>
	);
};
