import { ITender } from 'gen/ApiClient';
import React from 'react';
import { TenderStatusChipX } from './TenderStatusChipX';

interface IProps {
	item: ITender;
	style?: React.CSSProperties;
}

export const TenderChips = ({ item, style }: IProps) => {
	return (
		<TenderStatusChipX
			item={item}
			style={style}
		/>
	);
};
