import { TableCell, useTheme } from '@material-ui/core';
import React from 'react';
import { TableCellXProps } from './TableCellXProps';

export const TableCellX = ({ style, removeBorder, isFirst, isLast, ...rest }: TableCellXProps) => {
	const theme = useTheme();

	return (
		<TableCell
			style={{
				padding: 12,
				paddingRight: isLast === true ? 16 : 12,
				paddingLeft: isFirst === true ? 16 : 12,
				borderBottom: 'none',
				minWidth: 'unset',
				borderTop: removeBorder === true ? 'none' : `1px dashed ${theme.palette.grey[300]}`,
				...style,
			}}
			{...rest}
		/>
	);
};
