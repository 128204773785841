import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IAnnotationsQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { AnnotationTypesCacheContext } from 'shared/annotations/context/AnnotationTypesCacheContext';

const exclusions: (keyof IAnnotationsQueryParams)[] = ['alsoIncludeDeleted'];

interface IProps {
	params: IAnnotationsQueryParams;
	onFilter: (params: IAnnotationsQueryParams) => void;
	paddingLeft: number;
}

export const AnnotationsFilter = ({ params, onFilter, paddingLeft }: IProps) => {
	const [data] = usePreloadCacheContext(AnnotationTypesCacheContext);
	const strings = useLocalization();

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			{data && (
				<ListFilterComponent<string>
					options={data}
					selected={params.types}
					setSelected={vals => onFilter({ ...params, types: vals })}
					label={strings.type}
					icon={<HelpOutlineIcon />}
				/>
			)}
		</FilterBar>
	);
};
