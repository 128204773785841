import FrameworkNavigationProvider from 'framework/context/NavigationProvider';
import React from 'react';
import { CustomerAccountDetailTabType } from '../detail/CustomerAccountDetailTabType';
import { NavigationContext } from './NavigationContext';

export const NavigationProvider = ({ children }: any) => {
	return (
		<FrameworkNavigationProvider<CustomerAccountDetailTabType>
			context={NavigationContext}
			fallback='sales'>
			{children}
		</FrameworkNavigationProvider>
	);
};
