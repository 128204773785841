import { useParamsFromQueryContext } from 'framework/hooks/useParamsFromQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { QuickFilter } from '../RfiasListPage';
import { SelectableColumn } from '../SelectableColumn';
import { createColumnRecord } from '../utils/createColumnRecord';
import { createCosiDegreeOfChangeRecord } from '../utils/createCosiDegreeOfChangeRecord';
import { createCosiEndResultRecord } from '../utils/createCosiEndResultRecord';
import { createExportRecord } from '../utils/createExportRecord';
import { createHandoverLineModeRecord } from '../utils/createHandoverLineModeRecord';
import { createHeaders } from '../utils/createHeaders';
import { createInvoiceProcessStatusRecord } from '../utils/createInvoiceProcessStatusRecord';
import { createPaymentStatusRecord } from '../utils/createPaymentStatusRecord';
import { createQfRecord } from '../utils/createQfRecord';
import { createStatusOrderRecord } from '../utils/createStatusOrderRecord';
import { RfiasQueryContext } from './RfiasQueryContext';
import { ViewContext } from './ViewContext';
import { RfiaDetailTabType } from '../detail/RfiaDetailTabType';

const createRecord = (strings: IStrings): Record<RfiaDetailTabType, string> => {
	return {
		process: strings.process,
		noahOverview: strings.NoahData,
		comments: `${strings.annotations}\n(${strings.ofThePatient.toLowerCase()})`,
	};
};
const DefaultColumns: SelectableColumn[] = ['reference', 'created', 'patientName'];

export const ViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [qf, setQf] = useState<QuickFilter>('all');
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);
	const colRecord = useMemo(() => createColumnRecord(strings), [strings]);
	const headers = useMemo(() => createHeaders(colRecord, strings), [colRecord, strings]);
	const quickFilterRecord = useMemo(() => createQfRecord(strings), [strings]);
	const cosiDegreeOfChangeRecord = useMemo(() => createCosiDegreeOfChangeRecord(strings), [strings]);
	const cosiEndResultRecord = useMemo(() => createCosiEndResultRecord(strings), [strings]);
	const invoiceProcessStatusRecord = useMemo(() => createInvoiceProcessStatusRecord(strings), [strings]);
	const paymentStatusRecord = useMemo(() => createPaymentStatusRecord(strings), [strings]);
	const statusOrderRecord = useMemo(() => createStatusOrderRecord(strings), [strings]);
	const exportRecord = useMemo(() => createExportRecord(strings), [strings]);
	const handoverLineModeRecord = useMemo(() => createHandoverLineModeRecord(strings), [strings]);
	const navigationLinkRecord = useMemo(() => createRecord(strings), [strings]);

	const { setParams } = useParamsFromQueryContext(RfiasQueryContext);
	const { rfiaStatusRecord } = useContext(RecordContext);

	useEffect(() => {
		setParams(params =>
			onResetToPage1({
				...params,
				hasWarning: qf === 'warning' ? true : undefined,
				isCompleted: qf === 'running' ? false : undefined,
			})
		);
		// eslint-disable-next-line
	}, [qf]);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
				quickFilter: qf,
				setQuickFilter: setQf,
				quickFilterRecord: quickFilterRecord,
				cosiDegreeOfChangeRecord: cosiDegreeOfChangeRecord,
				cosiEndResultRecord: cosiEndResultRecord,
				statusRecord: rfiaStatusRecord,
				invoiceProcessStatusRecord: invoiceProcessStatusRecord,
				paymentStatusRecord: paymentStatusRecord,
				statusOrderRecord: statusOrderRecord,
				exportRecord: exportRecord,
				handoverLineModeRecord: handoverLineModeRecord,
				navigationLinkRecord: navigationLinkRecord,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
