import React from 'react';
import { CacheProvider } from 'framework/context/CacheProvider';
import { repairsQuery_concurrencyToken, repairsQuery_reasons } from 'gen/ApiClient';
import { RepairReasonsCacheContext } from './RepairReasonsCacheContext';

export const RepairReasonsCacheProvider = ({ children }: any) => {
	return (
		<CacheProvider<string[]>
			concurrencyF={repairsQuery_concurrencyToken}
			loadF={repairsQuery_reasons}
			context={RepairReasonsCacheContext}>
			{children}
		</CacheProvider>
	);
};
