import { useTheme } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React from 'react';

const RecordX = ({ thisStep, activeStep, requiredStep }: { thisStep: number; activeStep: number; requiredStep: number }) => {
	const theme = useTheme();
	return (
		<FiberManualRecordIcon
			color='action'
			style={{
				color: activeStep >= thisStep ? theme.palette.success.main : requiredStep >= thisStep ? theme.palette.warning.main : 'rgba(0,0,0,0.54)',
				fontSize: '14px',
				margin: '0px 2px',
			}}
		/>
	);
};

interface IProps {
	step: number;
	requiredStep: number;
	totalSteps: number;
}

export const StatusFibers = ({ step, requiredStep, totalSteps }: IProps) => {
	return (
		<div className='df-row-ac'>
			{Array.from(new Array(totalSteps)).map((_, index) => (
				<RecordX
					key={index + 1}
					thisStep={index + 1}
					activeStep={step}
					requiredStep={requiredStep}
				/>
			))}
			{/* <RecordX thisStep={1} activeStep={step} requiredStep={requiredStep} />
            <RecordX thisStep={2} activeStep={step} requiredStep={requiredStep} />
            <RecordX thisStep={3} activeStep={step} requiredStep={requiredStep} />
            <RecordX thisStep={4} activeStep={step} requiredStep={requiredStep} /> */}
		</div>
	);
};
