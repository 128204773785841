import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { IOrderV2, ISortParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	sortParams: ISortParams<IOrderV2>;
	onFilter: (sp: ISortParams<IOrderV2>) => void;
}

export const OrdersListOrderBySelect = ({ sortParams, onFilter }: IProps) => {
	const strings = useLocalization();
	const [anchor, open, close] = useAnchorElement();
	const title = React.useMemo(() => {
		if (sortParams.property === 'created') {
			return `${strings.dateCreated} (${sortParams.direction === 'desc' ? strings.newestFirst.toLowerCase() : strings.oldestFirst.toLowerCase()})`;
		} else if (sortParams.property === 'reference') {
			return `${strings.reference} (${sortParams.direction === 'desc' ? 'Z-A' : 'A-Z'})`;
		} else if (sortParams.property === 'expectedDeliveryDate') {
			return `${strings.expectedDeliveryDate} (${sortParams.direction === 'desc' ? strings.newestFirst.toLowerCase() : strings.oldestFirst.toLowerCase()})`;
		} else {
			return strings.unknown;
		}
	}, [strings, sortParams]);

	const onSelect = (property: keyof IOrderV2, direction: 'asc' | 'desc') => {
		onFilter({ property: property, direction: direction });
		close();
	};

	return (
		<>
			<Button
				size='small'
				endIcon={<ArrowDropDownIcon />}
				onClick={open}>
				{strings.sortOnWhat(title)}
			</Button>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={close}
				keepMounted>
				<MenuItem onClick={() => onSelect('created', 'asc')}>
					{`${strings.sortOnWhat(`${strings.dateCreated} (${strings.oldestFirst.toLowerCase()})`)}`}
				</MenuItem>
				<MenuItem onClick={() => onSelect('created', 'desc')}>
					{`${strings.sortOnWhat(`${strings.dateCreated} (${strings.newestFirst.toLowerCase()})`)}`}
				</MenuItem>
				<MenuItem onClick={() => onSelect('reference', 'asc')}>{`${strings.sortOnWhat(`${strings.reference} (A-Z)`)}`}</MenuItem>
				<MenuItem onClick={() => onSelect('reference', 'desc')}>{`${strings.sortOnWhat(`${strings.reference} (Z-A)`)}`}</MenuItem>
				<MenuItem onClick={() => onSelect('expectedDeliveryDate', 'asc')}>
					{`${strings.sortOnWhat(`${strings.expectedDeliveryDate} (${strings.oldestFirst.toLowerCase()})`)}`}
				</MenuItem>
				<MenuItem onClick={() => onSelect('expectedDeliveryDate', 'desc')}>
					{`${strings.sortOnWhat(`${strings.expectedDeliveryDate} (${strings.newestFirst.toLowerCase()})`)}`}
				</MenuItem>
			</Menu>
		</>
	);
};
