import { Chip } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import React from 'react';

interface IProps {
	label: string;
	style?: React.CSSProperties;
}

export const LabelledChip = ({ label, style }: IProps) => {
	return (
		<Chip
			icon={<LocalOfferIcon />}
			label={label}
			style={{ margin: '2px', ...style }}
			size='small'
		/>
	);
};
