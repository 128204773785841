import { CommissionExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createCommissionExportRecord = (strings: IStrings): Record<CommissionExportProp, string> => {
	return {
		Created: strings.created,
		IsPaidOut: `${strings.paidOut}?`,
		PayoutDate: strings.paidOutDate,
		RuleMethod: strings.method,
		RuleName: strings.rule,
		RulePercentage: `${strings.rule} ${strings.percentage}`,
		SalesDate: strings.salesDate,
		SalesRecipientName: strings.recipient,
		SalesReference: strings.reference,
		TotalValueExVatOfDevices: `${strings.totalValueOfDevices} (${strings.exVatShort})`,
		TotalValueExVatOfSale: `${strings.totalValueOfSale} (${strings.totalValueOfSale})`,
		UserIdentifier: strings.user,
		Value: strings.amountAsValue,
	};
};
