import React from 'react';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { ISubscriptionSummary, SubscriptionStatus } from 'gen/ApiClient';
import { SelectableColumn } from '../SelectableColumn';

interface IViewContext extends ITableViewContext<SelectableColumn, ISubscriptionSummary> {
	statusRecord: Record<SubscriptionStatus, string>;
}

export const ViewContext = React.createContext<IViewContext>(undefined as any);
