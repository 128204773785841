import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { ISale } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: ISale;
	style?: React.CSSProperties;
}

export const SalesListItemStatusIcon = ({ item, style }: IProps) => {
	if (item.isFullyPaid) {
		return <CheckCircleOutlineIcon style={style} />;
	} else if (item.isOverDue) {
		return <AccessTimeIcon style={style} />;
	} else {
		return <RadioButtonUncheckedIcon style={style} />;
	}
};
