import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IBusinessDataWithImageDataDto, IPatchBankAccountRequest, businessDataCommand_patchBankAccount } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPatchBankAccountRequest>({
			bic: yup.string().required(),
			iban: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	data: IBusinessDataWithImageDataDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const BusinessDataBankForm = ({ data, confirm, cancel, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [submit, isSubmitting] = useFormSubmit(businessDataCommand_patchBankAccount);
	const strings = useLocalization();

	const handleSubmit = async (values: IPatchBankAccountRequest, helpers: FormikHelpers<IPatchBankAccountRequest>) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.passwordChanged);
			confirm();
		}
	};

	return (
		<Formik<IPatchBankAccountRequest>
			validateOnMount
			initialValues={{
				bic: data.bicBankAccount,
				iban: data.ibanBankAccount,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					fullWidth
					maxWidth='sm'>
					<DialogTitle>{strings.changeWhat(strings.bankData)}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormTextField<IPatchBankAccountRequest>
							name='iban'
							label='IBAN'
							required
						/>
						<FormTextField<IPatchBankAccountRequest>
							name='bic'
							label='BIC'
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.change}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
