import { useUserHasPageClaim } from 'claims/useUserHasPageClaim';
import { IPageXProps } from 'framework/components/layout/IPageXProps';
import { PageX } from 'framework/components/layout/PageX';
import { PageClaim } from 'gen/ApiClient';
import React from 'react';
import { NoAccessPage } from './NoAccessPage';

interface IProps extends IPageXProps {
	pageClaim: PageClaim;
}

export const RestrictedPageX = ({ pageClaim, ...props }: IProps) => {
	const hasAccess = useUserHasPageClaim(pageClaim);

	return hasAccess ? <PageX {...props} /> : <NoAccessPage />;
};
