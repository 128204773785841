import { useCallback, useEffect, useState } from 'react';
import { Try } from '../Try';
import { useApi } from './useApi';

export function useApiEffectX<TResult>(f: () => Promise<Try<TResult>>): [TResult | undefined, () => Promise<Try<TResult>>];
export function useApiEffectX<TArgs, TResult>(f: (args: TArgs) => Promise<Try<TResult>>, args: TArgs): [TResult | undefined, () => Promise<Try<TResult>>];
export function useApiEffectX<TArgs extends any[], TResult>(
	f: (...args: TArgs) => Promise<Try<TResult>>,
	...args: TArgs
): [TResult | undefined, () => Promise<Try<TResult>>];

export function useApiEffectX<TArgs extends any[], TResult>(
	f: (...args: TArgs) => Promise<Try<TResult>>,
	...args: TArgs
): [TResult | undefined, () => Promise<Try<TResult>>] {
	const [setLoading, clearLoading, handleError] = useApi();
	const [data, setData] = useState<TResult>();

	const loadF = useCallback(async () => {
		console.log(`load useApiEffectX ${f.name}`);
		const loadId = setLoading();
		const r = await f(...args);
		if (r.isFailure) {
			handleError(r.error);
		} else {
			setData(r.result);
		}
		clearLoading(loadId);
		return r;
		// eslint-disable-next-line
	}, [f, ...args]);

	useEffect(() => {
		let cancelled = false;
		if (cancelled === false) {
			loadF();
		}
		return () => {
			cancelled = true;
		};
	}, [loadF]);

	return [data, loadF];
}
