import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { useResizeObserver } from 'framework/hooks/useResizeObserver';
import { useTrigger } from 'framework/hooks/useTrigger';
import { createType } from 'framework/utils/createType';
import { IAnalyzeSalesParams, salesQuery_analyze } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { SalesPageBreadcrumb } from '../SalesPageBreadcrumb';
import SalesAnalysisTimeSeriesChartCard from './SalesAnalysisTimeSeriesChartCard';
import DonutChart from './charts/donut/DonutChart';
import { SalesAnalysisFilter } from './filter/SalesAnalysisFilter';

const QuickFilters = createType('all', 'notPaid', 'notPaidAndOverDue');
export type QuickFilter = typeof QuickFilters[number];

const DefaultParams: IAnalyzeSalesParams = {
	createdBefore: undefined,
	createdAfter: undefined,
	paymentStatuses: [],
	types: [],
	creditedStatuses: [],
	locationIds: [],
	hasOutstandingBalance: undefined,
	isOverDue: undefined,
	labels: [],
	zips: [],
};

export const SalesAnalysis = () => {
	const [trigger, hitTrigger] = useTrigger();
	const [params, setParams, queryResult] = useApiEffectWithParams(salesQuery_analyze, DefaultParams, trigger);
	const strings = useLocalization();
	const [ref, width] = useResizeObserver();

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='Sales'
			stickyHeader={
				<div
					className='df-row-ac'
					style={{ paddingBottom: 16 }}>
					<SalesPageBreadcrumb />
					<PageBreadcrumbDivider />
					<PageBreadcrumbTitle
						title={strings.analysis}
						icon={<AttachMoneyIcon />}
					/>
					<div className='fg1'></div>
					<PageXHeaderActions>
						<PageReloadButtonX reload={hitTrigger} />
					</PageXHeaderActions>
				</div>
			}
			stickySidebar={
				<div
					className='df-col gap-16'
					style={{ padding: 16, paddingRight: 8 }}>
					<SalesAnalysisFilter
						onFilter={pars => setParams(pars)}
						params={params}
					/>
				</div>
			}>
			<div
				className='df-col fg1'
				style={{ padding: 16, paddingLeft: 8 }}
				ref={ref}>
				{width >= 1296 && (
					<div className='df-row-ac'>
						<DonutChart
							title={strings.revenueExVatPerCategory}
							data={queryResult.revenueByCategory}
							style={{ marginBottom: 16, marginRight: 16 }}
						/>
						<DonutChart
							title={strings.revenueExVatPerLocation}
							data={queryResult.revenueByLocation}
							style={{ marginBottom: 16 }}
						/>
					</div>
				)}
				{width < 1296 && (
					<>
						<DonutChart
							title={strings.revenueExVatPerCategory}
							data={queryResult.revenueByCategory}
							style={{ marginBottom: 16 }}
						/>
						<DonutChart
							title={strings.revenueExVatPerLocation}
							data={queryResult.revenueByLocation}
							style={{ marginBottom: 16 }}
						/>
					</>
				)}
				<SalesAnalysisTimeSeriesChartCard queryResult={queryResult} />
			</div>
		</RestrictedPageX>
	);
};
