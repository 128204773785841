import React, { useContext } from 'react';
import { PreloadCacheProvider as FrameworkCacheProvider } from 'framework/context/PreloadCacheProvider';
import { INotificationWithStatusDto, notificationsQuery_concurrencyToken, notificationsQuery_latest } from 'gen/ApiClient';
import { PreloadContext } from 'shared/context/PreloadContext';
import { CacheContext } from './CacheContext';

export const CacheProvider = ({ children }: any) => {
	const { lastNotifications } = useContext(PreloadContext);

	return (
		<FrameworkCacheProvider<INotificationWithStatusDto[]>
			concurrencyF={notificationsQuery_concurrencyToken}
			loadF={notificationsQuery_latest}
			context={CacheContext}
			initialData={lastNotifications}>
			{children}
		</FrameworkCacheProvider>
	);
};
