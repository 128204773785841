import React from 'react';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';

interface IProps<TLine extends { error?: string }> {
	line: TLine;
	style?: React.CSSProperties;
}

export const LineErrorComponent = <TLine extends { error?: string }>({ line, style }: IProps<TLine>) => {
	return (
		<>
			{line.error && (
				<ErrorLabelledProperty
					error={line.error}
					style={style}
					withoutMargin
				/>
			)}
		</>
	);
};
