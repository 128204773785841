import React from 'react';
import { ViewProvider as SearchViewProvider } from '../search/context/ViewProvider';
import { ArticlePropertiesAutocompleteCacheProvider } from './ArticlePropertiesAutocompleteCacheProvider';
import { FilterDataProvider } from './FilterDataProvider';
import { NavigationProvider } from './NavigationProvider';
import { QueryProvider } from './QueryProvider';
import { ViewProvider } from './ViewProvider';

export const InventoryItemsProviders = ({ children }: any) => {
	return (
		<ArticlePropertiesAutocompleteCacheProvider>
			<QueryProvider>
				<ViewProvider>
					<FilterDataProvider>
						<SearchViewProvider>
							<NavigationProvider>{children}</NavigationProvider>
						</SearchViewProvider>
					</FilterDataProvider>
				</ViewProvider>
			</QueryProvider>
		</ArticlePropertiesAutocompleteCacheProvider>
	);
};
