import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { WizardButton } from './WizardButton';

interface IProps {
	onClick: VoidFunction;
	count: number;
}

export const ReservedWizardButton = ({ count, onClick }: IProps) => {
	const strings = useLocalization();

	return (
		<WizardButton
			badge={count}
			disabled={count === 0}
			startIcon={<PersonPinOutlinedIcon />}
			onClick={onClick}>
			{strings.addReservedArticles}
		</WizardButton>
	);
};
