import React from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { ICashRegisterEntry } from 'gen/ApiClient';

interface IProps {
	item: ICashRegisterEntry;
	style?: React.CSSProperties;
}

export const CashRegisterEntryInOrOutStatusChip = ({ item, style }: IProps) => {
	// Object.keys(item.entryBalances!).map(t => console.log(t));
	return (
		<RectangularChip
			style={style}
			label={item.value < 0 ? 'Uit' : 'In'}
			fill={false}
			color={item.value < 0 ? 'error' : 'success'}
		/>
	);
};
