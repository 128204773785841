import { addDays, addMonths, addWeeks, addYears } from 'date-fns';
import { RosaCalendarViewType } from 'gen/ApiClient';

export const calculateNextDate = (val: Date, view: RosaCalendarViewType): Date => {
	return nextRecord[view](val);
};

const nextRecord: Record<RosaCalendarViewType, (val: Date) => Date> = {
	Day: (val: Date) => addDays(val, 1),
	Week: (val: Date) => addWeeks(val, 1),
	Month: (val: Date) => addMonths(val, 1),
	Year: (val: Date) => addYears(val, 1),
	List: (val: Date) => addDays(val, 1),
	Timeline: (val: Date) => addDays(val, 1),
};
