import { Divider, useTheme } from '@material-ui/core';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { ITonalAudiogramImage } from 'gen/ApiClient';
import { ToneThresholdImageType } from './ToneThresholdImageType';

interface IProps {
	selectedImage: ToneThresholdImageType;
	setSelectedImage: (image: ToneThresholdImageType) => void;
	audiogram: ITonalAudiogramImage | undefined | null;
	style?: React.CSSProperties;
}

export const LrbButtons = ({ selectedImage, setSelectedImage, audiogram, style }: IProps) => {
	const theme = useTheme();

	if (audiogram === undefined || audiogram === null) {
		return <div></div>;
	}

	return (
		<div
			className='df-row-ac'
			style={style}>
			<SmallPrimaryTextButton
				style={{ minWidth: 0, width: '30px' }}
				disabled={audiogram.hasLeftEarImage === false}
				onClick={() => setSelectedImage('left')}
				variant={selectedImage === 'left' ? 'outlined' : 'text'}>
				L
			</SmallPrimaryTextButton>
			<Divider
				orientation='vertical'
				flexItem
				style={{ margin: theme.spacing(0.5) }}
			/>
			<SmallPrimaryTextButton
				style={{ minWidth: 0, width: '30px' }}
				disabled={audiogram.hasRightEarImage === false}
				onClick={() => setSelectedImage('right')}
				variant={selectedImage === 'right' ? 'outlined' : 'text'}>
				R
			</SmallPrimaryTextButton>
			<Divider
				orientation='vertical'
				flexItem
				style={{ margin: theme.spacing(0.5) }}
			/>
			<SmallPrimaryTextButton
				style={{ minWidth: 0, width: '30px' }}
				disabled={audiogram.hasBothEarImage === false}
				onClick={() => setSelectedImage('both')}
				variant={selectedImage === 'both' ? 'outlined' : 'text'}>
				B
			</SmallPrimaryTextButton>
		</div>
	);
};
