import { useTheme } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { IHealthInsuranceFundWithAddressDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { HifChipX } from './HifChipX';
import { HifListItemStatusIcon } from './HifListItemStatusIcon';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IHealthInsuranceFundWithAddressDto;
	isSelected: boolean;
	toggle: VoidFunction;
	style?: React.CSSProperties;
}

export const HifListItemCardX = ({ item, isSelected, toggle, style }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const color = useMemo<ColorX>(() => (item.hasMetaData ? 'warning' : 'success'), [item]);
	const tColor = useThemeColor(color);
	const [hovered, enter, leave] = useStateBoolean(false);

	return (
		<CardX
			raised={isSelected || hovered}
			className='df-row w100'
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={toggle}
			style={{
				borderRight: isSelected ? `6px solid ${theme.palette.primary.main}` : 'unset',
				cursor: 'pointer',
				...style,
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<HifListItemStatusIcon
					item={item}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col fg1'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.name}</span>
				<HifChipX
					item={item}
					style={{ marginRight: 'auto' }}
				/>
				<span style={{ fontSize: MinorFontSize }}>{`${strings.code}: ${item.code}`}</span>
				{item.email && <span style={{ fontSize: MinorFontSize }}>{`${strings.email}: ${item.email}`}</span>}
			</div>
		</CardX>
	);
};
