import { DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { setFieldError } from 'framework/forms/utils/setFieldError';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Try } from 'framework/Try';
import { ICreateResponse, ICreateValidateResponse, IDocumentFromTemplateModel, templatesQuery_byType, TemplateType } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useDocumentEditor } from 'shared/dialogs/useDocumentEditor';
import { RecordContext } from 'shared/records/RecordContext';
import { SelectTemplateInnerDialog } from './SelectTemplateInnerDialog';

interface IModel {
	id: string;
}

const createSchema = (strings: IStrings): yup.ObjectSchema<IModel> => {
	return yup
		.object<IModel>({
			id: yup.string().required(strings.formRequired(strings.template)),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: (documentId: string) => void;
	submitF: (id: string) => Promise<Try<ICreateValidateResponse | ICreateResponse<IDocumentFromTemplateModel>>>;
	cancel: VoidFunction;
	type: TemplateType;
	deferOpenEditor?: boolean;
	title?: string;
	submitText?: string;
	cancelText?: string;
}

export const SelectTemplateFromPredefinedTypeForm = ({ title, confirm, submitF, type, deferOpenEditor = false, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(submitF);
	const [openEditor] = useDocumentEditor();
	const [templates] = useApiEffect(templatesQuery_byType, type); // TODO must come from type!
	const { templateTypeRecord } = useContext(RecordContext);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await commonSubmit(values.id);
		if (r.isSuccess && r.result.hasError) {
			if (r.result.hasError) {
				setFieldError<IModel>('id', 'Fout', helpers);
			}
		}
	};

	const commonSubmit = async (templateId: string): Promise<Try<ICreateValidateResponse | ICreateResponse<IDocumentFromTemplateModel>>> => {
		const r = await submit(templateId);
		if (r.isSuccess && r.result.hasError === false) {
			confirm(r.result.objectId);
			const selectedTemplate = templates?.find(t => t.id === templateId);
			if (selectedTemplate !== undefined && selectedTemplate.canViewOnline && deferOpenEditor === false) {
				openEditor(r.result.objectId);
			}
		}
		return r;
	};

	if (templates === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{
				id: '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<SelectTemplateInnerDialog<IModel>
					name='id'
					title={title ?? `${strings.selectWhat(strings.template)}: ${templateTypeRecord[type]}`}
					isSubmitting={isSubmitting}
					templates={templates}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};
