import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	IQueryResult,
	IStockItemDto,
	IStockItemDtosQueryParams,
	stockEntriesQuery_concurrencyToken,
	stockEntriesQuery_queryCurrentStockItems,
} from 'gen/ApiClient';
import React from 'react';
import { stockItemDtosQueryParamsLsKey } from '../../../../localStorageKeys';
import { QueryContext } from './QueryContext';

export const DefaultParams: IStockItemDtosQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'manufacturerCommaModel' },
	pageParams: { index: 1, size: 10 },
	manufacturers: [],
	categories: [],
	subcategories: [],
	stockLessThan: undefined,
	stockMoreThan: undefined,
	vatPercentages: [],
	types: [],
	keyFilterType: undefined,
	availableOnLocationIds: [],
	stockValueLessThan: undefined,
	stockValueMoreThan: undefined,
	supplierIds: [],
	hasSerialNumber: undefined,
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IStockItemDto>, IStockItemDtosQueryParams>
			defaultParams={DefaultParams}
			queryF={stockEntriesQuery_queryCurrentStockItems}
			concurrencyF={stockEntriesQuery_concurrencyToken}
			context={QueryContext}
			localStorageKey={stockItemDtosQueryParamsLsKey}>
			{children}
		</FrameworkQueryProvider>
	);
};
