import { DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IStockMovementModel, stockMovementsCommand_new, stockMovementsQuery_nextReference } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import * as yup from 'yup';
import { LocationsPreloadCacheContext } from '../../../../settings/locations/LocationsPreloadCacheContext';
import { SearchProvider } from './context/SearchProvider';
import { ILine } from './ILine';
import { SearchAndSelectProductsForStockMovement } from './SearchAndSelectProductsForStockMovement';
import { lineToModel } from './utils/lineToModel';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IStockMovementModel>({
			lines: yup.mixed(),
			fromLocationId: yup.string().required(strings.formRequired(strings.fromLocation)),
			toLocationId: yup
				.string()
				.required(strings.formRequired(strings.toLocation))
				.notOneOf([yup.ref('fromLocationId')], 'Naar locatie moet verschillend zijn als de van locatie'),
			reference: yup.string().required(),
			isAutoSelectReference: yup.boolean().defined(),
		})
		.defined();
};

const emptyValues: IStockMovementModel = {
	lines: [],
	fromLocationId: undefined as any,
	toLocationId: undefined as any,
	reference: '',
	isAutoSelectReference: true,
};

const stepsRecord: Record<number, (keyof IStockMovementModel)[]> = {
	0: ['reference', 'isAutoSelectReference'],
	1: ['fromLocationId', 'toLocationId'],
	2: ['lines'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const StockMovementForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, setLines] = useState<ILine[]>([]);
	const [step, setStep] = useState<number>(0);
	const [create, isSubmitting] = useFormSubmit(stockMovementsCommand_new);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IStockMovementModel, helpers: FormikHelpers<IStockMovementModel>) => {
		// TODO meer controleren! -> max. value of stockItems will be max value available at location!
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await create({ ...values, lines: lines.map((t, index) => lineToModel(t, index)) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify(strings.addedWhat(strings.fromArrowTo));
				confirm(r.result.objectId);
			}
		}
	};

	return (
		<Formik<IStockMovementModel>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						lines={lines}
						setLines={setLines}
						step={step}
						setStep={setStep}
						stepsRecord={stepsRecord}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	lines: ILine[];
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof IStockMovementModel)[]>;
}

const InnerDialog = ({ cancel, lines, setLines, isSubmitting, step, setStep, stepsRecord, ...rest }: IInnerProps) => {
	const props = useFormikContext<IStockMovementModel>();
	const strings = useLocalization();
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	useNextReferenceInterceptor(stockMovementsQuery_nextReference, props);

	return (
		<FullScreenDialogWithStepper
			{...rest}
			fullScreenStep={2}
			title={strings.newWhat(strings.fromArrowTo)}
			step={step}
			labels={['Ref.', strings.fromTo, strings.items]}>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IStockMovementModel>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IStockMovementModel>
							name='reference'
							label={strings.reference}
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectFieldWithOption<IStockMovementModel, string>
							name='fromLocationId'
							options={locations}
							label={strings.fromLocation}
						/>
						<FormSelectFieldWithOption<IStockMovementModel, string>
							name='toLocationId'
							options={locations}
							label={strings.toLocation}
						/>
					</>
				)}
				{step === 2 && (
					<SearchAndSelectProductsForStockMovement
						lines={lines}
						setLines={setLines}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</FullScreenDialogWithStepper>
	);
};
