import React from 'react';
import { ITextLinkButtonProps } from './ITextLinkButtonProps';
import { TextLinkButton } from './TextLinkButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

interface IProps extends ITextLinkButtonProps {
	isExpanded: boolean;
	toggle: VoidFunction;
}

export const TextLinkButtonWithExpandIcon = ({ isExpanded, toggle, children, ...rest }: IProps) => {
	return (
		<TextLinkButton
			{...rest}
			onClick={e => {
				e.stopPropagation();
				e.preventDefault();
				toggle();
			}}>
			{children}
			{isExpanded === true && <ArrowDropUpIcon fontSize='small' />}
			{isExpanded === false && <ArrowDropDownIcon fontSize='small' />}
		</TextLinkButton>
	);
};
