import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISale, IUserSummary, SalesType } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (
	colRecord: Record<SelectableColumn, string>,
	typeRecord: Record<SalesType, string>,
	users: IUserSummary[],
	strings: IStrings
): Record<SelectableColumn, IHeader<ISale>> => {
	return {
		countItems: createSortableHeaderWithKey<ISale, SelectableColumn>(colRecord, 'countItems', t => t.countItems.toString()),
		created: createSortableHeaderWithKey<ISale, SelectableColumn>(colRecord, 'created', t => formatDate(t.created)),
		validUntil: createSortableHeaderWithKey<ISale, SelectableColumn>(colRecord, 'validUntil', t => formatDate(t.validUntil)),
		recipientName: createSortableHeaderWithKey<ISale, SelectableColumn>(colRecord, 'recipientName', t => t.recipientName),
		reference: createSortableHeaderWithKey<ISale, SelectableColumn>(colRecord, 'reference', t => t.reference),
		externalReference: createSortableHeaderWithKey<ISale, SelectableColumn>(colRecord, 'externalReference', t => t.externalReference),
		amountPaid: createSortableHeaderWithKey<ISale, SelectableColumn>(colRecord, 'amountPaid', t => formatCurrency(t.amountPaid)),
		outstandingBalance: createSortableHeaderWithKey<ISale, SelectableColumn>(colRecord, 'outstandingBalance', t => formatCurrency(t.outstandingBalance)),
		priceExVat: createSortableHeaderWithKey<ISale, SelectableColumn>(colRecord, 'priceExVat', t => formatCurrency(t.priceExVat)),
		priceInVat: createSortableHeaderWithKey<ISale, SelectableColumn>(colRecord, 'priceInVat', t => formatCurrency(t.priceInVat)),
		vatAmount: createSortableHeaderWithKey<ISale, SelectableColumn>(colRecord, 'vatAmount', t => formatCurrency(t.vatAmount)),
		type: createHeaderWithKey<ISale, SelectableColumn>(colRecord, 'type', t => typeRecord[t.type as SalesType]),
		createdByUser: createHeaderWithKey<ISale, SelectableColumn>(
			colRecord,
			'createdByUser',
			t => users.find(x => x.id === t.createdByUserId)?.lastNameCommaFirstName ?? ''
		),
	};
};
