import { CardProps, Typography } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { IGeneralPractitioner } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { GeneralPractitionerDetailsContent } from './GeneralPractitionerDetailsContent';

interface IProps extends CardProps {
	item: IGeneralPractitioner;
	reload: VoidFunction;
}

export const GeneralPractitionerDetailsCard = ({ item, reload, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<CardX {...rest}>
			<Typography
				component='div'
				style={{ fontSize: '16px', fontWeight: 500, marginBottom: 16 }}>
				{`${strings.detailsOfGeneralPractitioner}: ${item.identifier}`}
			</Typography>
			<GeneralPractitionerDetailsContent
				item={item}
				reload={reload}
			/>
		</CardX>
	);
};
