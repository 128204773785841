import { IApiErrorStrings } from './IApiErrorString';

export const nlApiErrorStrings: IApiErrorStrings = {
	error401: 'Je bent niet (correct) aangemeld, gelieve in te loggen en probeer opnieuw.\n[Status code 401]',
	error403: 'Je bent niet gemachtigd om deze operatie uit te voeren.\n[Status code 403]',
	error500: 'Een ongekende fout is opgetreden.\nProbeer opnieuw!\n[Status code 500]',
	errorUnmapped: (statusCode: string) => `Een ongekende fout is opgetreden.\nProbeer opnieuw!\n[Status code ${statusCode}]`,
	errorUnknown: 'Een ongekende fout is opgetreden.\nProbeer opnieuw!',
	errorNoNetwork: 'Geen verbinding met de server.\nControleer je netwerkverbinding.',
};
