import { SaleExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createExportRecord = (strings: IStrings): Record<SaleExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		AmountPaid: strings.amountPaid,
		CountItems: `# ${strings.items}`,
		HasOutstandingBalance: `${strings.outstandingBalance}?`,
		IsCashSale: `${strings.cashSale}?`,
		IsOverDue: `${strings.overdue}?`,
		IsInvoice: `${strings.invoice}?`,
		IsFullyPaid: `${strings.fullyPaid}?`,
		IsPartlyPaid: `${strings.partlyPaid}?`,
		OutstandingBalance: strings.outstandingBalance,
		PriceExVat: strings.priceExVat,
		PriceInVat: strings.priceInVat,
		RecipientName: strings.withRespectToShort,
		Reference: strings.reference,
		ValidUntil: strings.validTo,
		VatAmount: strings.vatAmount,
		ExternalReference: strings.externalReference,
		LocationIdentifier: strings.location,
		DefaultBelgianStructuredCreditorReference: strings.structuredReference,
		RecipientAddressLine: strings.recipient + ' ' + strings.address,
		RecipientCity: strings.recipient + ' ' + strings.city,
		RecipientCountry: strings.recipient + ' ' + strings.country,
		RecipientZip: strings.recipient + ' ' + strings.zip,
	};
};
