import { Button, Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RestrictedContent } from 'shared/layout/RestrictedContent';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { CommissionPayoutsTable } from './CommissionPayoutsTable';
import { AllQueryContext } from './context/AllQueryContext';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { CommissionPayoutForm } from './forms/CommissionPayoutForm';

export const AllCommissionPayoutsPage = ({ reloadTrigger }: IReloadTriggerProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { params, setParams, queryResult, reload } = useQueryContext(AllQueryContext, reloadTrigger);
	const { columns, columnsRecord, setColumns } = useContext(ViewContext);
	const { open, confirm, cancel } = useDialogsContext(reload);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onRegisterPayout = () => {
		open(
			<CommissionPayoutForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<RestrictedContent pageClaim='AllCommissionPayouts'>
			<div
				className='df-row-ac jc-sb'
				style={{ marginBottom: '24px' }}>
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder={strings.searchVerb}
					style={{ width: '400px' }}
					variant='paper'
				/>
				<Button
					variant='contained'
					color='primary'
					startIcon={<AddIcon />}
					onClick={onRegisterPayout}>
					{strings.registerWhat(strings.payout)}
				</Button>
			</div>
			{queryResult.unfilteredCount > 0 && (
				<>
					<Card>
						<div className='df-row'>
							<Filter
								params={params}
								onFilter={params => setParams(onResetToPage1(params))}
								paddingLeft={theme.spacing(1.5)}
							/>
							<div className='fg1'></div>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
								view='allCommissionsPayoutPage'
							/>
						</div>
						<Divider />
						<CardMedia>
							<CommissionPayoutsTable
								queryResult={queryResult}
								params={params}
								setParams={setParams}
								reload={reload}
								withDeleteOption
							/>
						</CardMedia>
					</Card>
				</>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound>{strings.noWhatDefined(strings.payouts)}</NoRecordsFound>}
		</RestrictedContent>
	);
};
