import { IStrings } from 'localization/IStrings';
import { QuickFilter } from '../ProductCatalogList';

export const createQuickFilterRecord = (strings: IStrings): Record<QuickFilter, string> => {
	return {
		all: strings.all,
		hearingInstruments: strings.hearingAids,
		intangible: strings.notTangible,
	};
};
