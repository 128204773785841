import { Typography, useTheme } from '@material-ui/core';
import { LeftRightDividedContentScrollYRight } from 'framework/components/LeftRightDividedContentScrollYRight';
import { DragAndDropContainer } from 'framework/components/dnd/DragAndDropContainer';
import { addOrUpdateQuantity } from 'framework/utils/array/addOrUpdateQuantity';
import { updateArray } from 'framework/utils/array/updateArray';
import { IObjectWithIdAndIdentifier, IProductCatalogItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { v1 as uuid } from 'uuid';
import { SearchProductCatalog } from '../../../../inventoryManagement/productCatalog/search/SearchProductCatalog';
import { IOrderLineModel } from './IOrderLineModel';
import { RegisterOrderLineComponent } from './RegisterOrderLineComponent';
import { SearchProductCatalogItemsForOrderContext } from './SearchProductCatalogItemsForOrderContext';
import { mapProductCatalogItemToOrderLineModel } from './mapProductCatalogItemToOrderLineModel';

interface IProps {
	lines: IOrderLineModel[];
	setLines: React.Dispatch<React.SetStateAction<IOrderLineModel[]>>;
	patient: IObjectWithIdAndIdentifier<string> | undefined | null;
}

export const SearchAndSelectProductsForOrder = ({ lines, setLines, patient }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { params, setParams, queryResult } = useContext(SearchProductCatalogItemsForOrderContext);

	const onAddPc = async (item: IProductCatalogItem) => {
		if (item.isUniquelyIdentifiable) {
			setLines([...lines, mapProductCatalogItemToOrderLineModel(item, patient)]);
		} else {
			setLines(addOrUpdateQuantity(item, lines, () => mapProductCatalogItemToOrderLineModel(item, patient)));
		}
	};

	const onCopy = (line: IOrderLineModel) => {
		setLines([...lines, { ...line, orientation: '', id: uuid() }]);
	};

	return (
		<LeftRightDividedContentScrollYRight
			leftContent={
				<div className='df-col'>
					<Typography style={{ fontSize: '18px', marginBottom: theme.spacing(1) }}>{strings.searchFromProductCatalog}</Typography>
					<SearchProductCatalog
						params={params}
						setParams={setParams}
						queryResult={queryResult}
						onAdd={onAddPc}
						filterType='stock'
						style={{ width: '500px' }}
					/>
				</div>
			}
			rightContent={
				<DragAndDropContainer<IOrderLineModel>
					lines={lines}
					setLines={setLines}
					getKey={t => t.id}
					render={(line, index, props) => (
						<RegisterOrderLineComponent
							line={line}
							index={index + 1}
							dragHandleProps={props}
							setLine={val => setLines(updateArray(line, lines, val))}
							onDelete={() => setLines(lines.filter(t => t !== line))}
							onCopy={() => onCopy(line)}
						/>
					)}
				/>
			}
		/>
	);
};
