import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CalendarRoute } from 'routes';
import { RosaCalendarPage } from './RosaCalendarPage';

export const RosaCalendarPageSwitch = () => {
	return (
		<Switch>
			<Route
				exact
				path={CalendarRoute}
				component={RosaCalendarPage}
			/>
			<Redirect to={CalendarRoute} />
		</Switch>
	);
};
