import { IRepair } from 'gen/ApiClient';
import { RepairProcessStepX } from './RepairProcessStepX';

export const calculateActiveRepairProcessStepX = (item: IRepair): RepairProcessStepX | undefined => {
	if (item.isCompleted) {
		return undefined;
	} else if (item.hasLines === false) {
		return 'define-articles';
	} else if (item.isSentToSupplier === false) {
		return 'send-to-supplier';
	} else if (item.isCompletelyRedelivered === false) {
		return 'delivery';
	} else if (item.isHandedOver === false && item.handoverToPatientStepIncluded) {
		return 'handover';
	} else {
		return 'register';
	}
};
