import { IOrderV2, IRegisterOrderDeliveryRequest } from 'gen/ApiClient';

export const mapToRegisterOrderDeliveryRequest = (order: IOrderV2, now: Date): IRegisterOrderDeliveryRequest => {
	return {
		locationId: order.locationId,
		date: now,
		lines: [],
		dispatchDate: undefined as any,
		dispatchReference: '',
		orderId: order.id,
		isAutoSelectReference: true,
		reference: '',
	};
};
