import { Menu, MenuItem, TextField } from '@material-ui/core';
import { isValid, parse } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useAnchorElement } from '../../hooks/useAnchorElement';
import { IOption } from '../../IOption';
import { formatDateX } from '../../utils/date/formatDateX';
import { isNotNullNorUndefined } from '../../utils/nullNorUndefinedCheck';
import { TextMaskCustom } from '../mask/TextMaskCustom';

const HoursMinutesMask1 = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
const HoursMinutesMask2 = [/[0-2]/, /[0-3]/, ':', /[0-5]/, /[0-9]/];

interface IProps {
	value: Date;
	setValue: (val: Date) => void;
	options: IOption<string>[];
	label: string;
	style?: React.CSSProperties;
}

export const TimePickerX = ({ value, setValue, style, options, label }: IProps) => {
	const [text, setText] = useState<string>('');
	const [anchor, open, close] = useAnchorElement();

	useEffect(() => {
		setText(formatDateX(value, 'HH:mm'));
	}, [value]);

	const onBlur = () => {
		onSet(text);
	};

	const onSet = (timeAsString: string) => {
		const parsed = parse(timeAsString, 'HH:mm', value);
		if (isValid(parsed)) {
			setValue(parsed);
		} else {
			setText(formatDateX(value, 'HH:mm'));
		}
	};

	return (
		<>
			<TextField
				style={style}
				variant='filled'
				label={label}
				value={text}
				onBlur={() => onBlur()}
				disabled={isNotNullNorUndefined(value) === false}
				onChange={e => setText(e.target.value)}
				inputProps={{ mask: text !== undefined && text[0] === '2' ? HoursMinutesMask2 : HoursMinutesMask1, style: { width: '90px' } }}
				onClick={isNotNullNorUndefined(value) ? open : undefined}
				InputProps={{
					inputComponent: TextMaskCustom as any,
				}}
			/>
			{Boolean(anchor) && (
				<Menu
					anchorEl={anchor}
					keepMounted
					open={Boolean(anchor)}
					PaperProps={{ style: { minWidth: '115px' } }}
					onClose={close}>
					{options.map(t => (
						<MenuItem
							key={t.id}
							selected={t.id === text}
							onClick={() => {
								close();
								onSet(t.id);
							}}>
							{t.identifier}
						</MenuItem>
					))}
				</Menu>
			)}
		</>
	);
};
