import { Dialog, DialogContent, DialogProps, Typography } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	IPaymentForPurchaseModel,
	IPurchase,
	paymentsForPurchasesCommand_new,
	paymentsQuery_nextReferenceForPurchase,
	purchasesQuery_single,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useCallback, useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { FormSelectOrCreatePaymentMethodField } from '../../../../settings/paymentMethods/forms/FormSelectOrCreatePaymentMethodField';

const createSchema = (strings: IStrings, max: number, min?: number) => {
	return yup
		.object<IPaymentForPurchaseModel>({
			purchaseId: yup.string().required(),
			date: yup.date().required(),
			isAutoSelectReference: yup.boolean().defined(),
			methodId: yup.string().required(),
			reference: yup.string(),
			value: yup
				.number()
				// .min(min ?? 0.01)
				// .max(max)
				.required(),
		})
		.defined();
};

const EmptyValues: IPaymentForPurchaseModel = {
	purchaseId: '',
	date: undefined as any,
	isAutoSelectReference: true,
	methodId: '',
	reference: '',
	value: 0,
};

const stepsRecord: Record<number, (keyof IPaymentForPurchaseModel)[]> = {
	0: ['isAutoSelectReference', 'reference', 'date'],
	1: ['methodId', 'value'],
};

interface IProps extends DialogProps {
	purchaseId: string;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const PaymentForPurchaseForm = ({ purchaseId, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [purchase] = useApiEffect(purchasesQuery_single, purchaseId);
	const [create, isSubmitting] = useFormSubmit(paymentsForPurchasesCommand_new);
	const [step, setStep] = useState<number>(0);
	const now = useNow();

	const handleSubmit = async (values: IPaymentForPurchaseModel, helpers: FormikHelpers<IPaymentForPurchaseModel>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.registeredWhat(strings.payment));
			confirm(r.result.objectId);
		}
	};

	if (purchase === undefined || now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IPaymentForPurchaseModel>
			validateOnMount
			initialValues={{
				...EmptyValues,
				value: purchase.outstandingBalance,
				purchaseId: purchaseId,
				date: now,
			}}
			validationSchema={createSchema(strings, purchase.outstandingBalance)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					purchase={purchase}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	purchase: IPurchase;
}

const InnerDialog = ({ step, setStep, cancel, isSubmitting, purchase, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IPaymentForPurchaseModel>();
	const strings = useLocalization();
	const cb = useCallback(() => paymentsQuery_nextReferenceForPurchase(purchase.id), [purchase.id]);
	useNextReferenceInterceptor(cb, props);

	return (
		<Dialog {...rest}>
			<DialogTitleWithFormStepper
				title={`${strings.registerWhat(strings.payment)} (${strings.purchase})`}
				step={step}
				labels={[`${strings.date} & ${strings.reference}`, `${strings.method} & ${strings.amountAsValue}`]}>
				<div className='df-col'>
					<Typography
						variant='caption'
						style={{ marginBottom: 8 }}>{`${strings.supplier}: ${purchase.supplierIdentifier}`}</Typography>
					<Typography
						variant='caption'
						style={{ marginBottom: 8 }}>{`${strings.reference}: ${purchase.reference}`}</Typography>
					<Typography
						variant='caption'
						style={{ marginBottom: 8 }}>{`${strings.externalReference}: ${purchase.externalReference}`}</Typography>
				</div>
			</DialogTitleWithFormStepper>
			<DialogContent
				className='df-col'
				dividers>
				{step === 0 && (
					<>
						<FormDatePicker<IPaymentForPurchaseModel>
							name='date'
							label={strings.referenceDate}
							disableFuture
							required
						/>
						<FormSingleCheckboxField<IPaymentForPurchaseModel>
							name='isAutoSelectReference'
							label={strings.autoSelectPaymentReferenceQuestion}
						/>
						<FormTextField<IPaymentForPurchaseModel>
							name='reference'
							label={strings.paymentReference}
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectOrCreatePaymentMethodField<IPaymentForPurchaseModel>
							name='methodId'
							label={strings.paymentMethod}
							mode='purchase'
						/>
						{purchase.outstandingBalance > 0 && (
							<FormMoneyField<IPaymentForPurchaseModel>
								name='value'
								label={strings.amountAsValue}
							/>
						)}
						{purchase.outstandingBalance < 0 && (
							<FormMoneyField<IPaymentForPurchaseModel>
								name='value'
								label={strings.refundAmount}
								allowNegative
							/>
						)}
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings, purchase.outstandingBalance)}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
