import { Button, Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IApiTokenDto, apiTokensCommand_revokeAll } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { ApiTokensListFilter } from './ApiTokensListFilter';
import { ApiTokensListViewContext } from './ApiTokensListViewContext';
import { ApiTokensQueryContext } from './ApiTokensQueryContext';
import { ApiTokensSelectableColumn } from './ApiTokensSelectableColumn';

interface IProps extends IReloadTriggerProps {
	reload: VoidFunction;
}

export const ApiTokensListPage = ({ reload, reloadTrigger }: IProps) => {
	const { params, setParams, queryResult } = useQueryContext(ApiTokensQueryContext, reloadTrigger);
	const strings = useLocalization();
	const { headers, columns, setColumns, columnsRecord } = useContext(ApiTokensListViewContext);
	const theme = useTheme();
	const { open, confirm, cancel } = useDialogsContext(reload);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onRevokeAll = () => {
		open(
			<AlertDialog
				open
				title={`Revoke all api tokens`}
				content={`Revoke all api tokens?`}
				acceptText={`Yes, proceed`}
				rejectText={`No, go back`}
				reject={cancel}
				acceptF={() => apiTokensCommand_revokeAll()}
				accept={confirm}
			/>
		);
	};

	return (
		<div className='fg1'>
			<div
				className='df-row-ac jc-sb'
				style={{ marginBottom: '24px' }}>
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder={strings.searchUsers}
					style={{ width: '400px' }}
					variant='paper'
				/>
				<Button
					variant='contained'
					color='secondary'
					onClick={onRevokeAll}>
					{`Revoke all api tokens`}
				</Button>
			</div>
			<Card>
				<div className='df-row jc-sb'>
					<ApiTokensListFilter
						params={params}
						onFilter={params => setParams(onResetToPage1(params))}
					/>
					<div
						className='df-row-ac'
						style={{ paddingRight: theme.spacing(1.5) }}>
						<SelectColumnsButtonAndDialog
							selectedColumns={columns}
							setSelectedColumns={setColumns}
							record={columnsRecord}
							view='registeredClients'
						/>
					</div>
				</div>
				<Divider />
				<CardMedia>
					<PageableTableWithColumnSelection<IApiTokenDto, ApiTokensSelectableColumn>
						queryResult={queryResult}
						headers={headers}
						sortParams={params.sortParams}
						pageParams={params.pageParams}
						setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
						getKey={inst => inst.firstFourKeyChars ?? ''}
						selectedColumns={columns}
					/>
				</CardMedia>
			</Card>
		</div>
	);
};
