import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IStrings } from 'localization/IStrings';
import { IEquipmentDeliveryLineWithSelection } from './IEquipmentDeliveryLineWithSelection';

export const createHeaders = (
	lines: IEquipmentDeliveryLineWithSelection[],
	setLines: React.Dispatch<React.SetStateAction<IEquipmentDeliveryLineWithSelection[]>>,
	strings: IStrings
): IHeader<IEquipmentDeliveryLineWithSelection>[] => {
	return [
		createSelectionHeader<IEquipmentDeliveryLineWithSelection>(lines, setLines),
		createSortableHeader<IEquipmentDeliveryLineWithSelection>(strings.description, 'description', t => t.description),
		createSortableHeader<IEquipmentDeliveryLineWithSelection>(strings.quantity, 'quantity', t => t.quantity.toString()),
		createSortableHeader<IEquipmentDeliveryLineWithSelection>(`${strings.listPrice}\n${strings.inVatShort}`, 'unitListPriceInVat', t =>
			formatCurrency(t.unitListPriceInVat)
		),
		createSortableHeader<IEquipmentDeliveryLineWithSelection>(`${strings.salesPrice}\n${strings.inVatShort}`, 'unitPriceInVat', t =>
			formatCurrency(t.unitPriceInVat)
		),
	];
};
