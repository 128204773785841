import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { TendersListViewContext } from './TendersListViewContext';
import { createExportRecord } from './createExportRecord';

export const TendersListViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const exportRecord = useMemo(() => createExportRecord(strings), [strings]);

	return (
		<TendersListViewContext.Provider
			value={{
				exportRecord: exportRecord,
			}}>
			{children}
		</TendersListViewContext.Provider>
	);
};
