import { Button } from '@material-ui/core';
import { FormikProps, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { useLocalization } from 'localization/useLocalization';
import { LoaderButton } from '../components/buttons/LoaderButton';
import { maxStep } from './utils/maxStep';
import { validateStepModelByRecord } from './utils/validateStepModelByRecord';

export interface IPageableFormActionsProps<TModel extends object | null | undefined> {
	cancel: VoidFunction;
	step: number;
	setStep: (step: number) => void;
	schema: yup.ObjectSchema<TModel>;
	stepsRecord: Record<number, any>;
	isSubmitting: boolean;
	submitText: string;
	validateMore?: () => boolean;
	canNext?: boolean;
	previous?: VoidFunction;
	leftContent?: JSX.Element;
}

export const PageableFormActions = <TModel extends object | null | undefined>({
	cancel,
	setStep,
	schema,
	stepsRecord,
	isSubmitting,
	submitText,
	step,
	validateMore,
	canNext = true,
	previous,
	leftContent,
}: IPageableFormActionsProps<TModel>) => {
	const props = useFormikContext<TModel>();
	const strings = useLocalization();
	const canClickPrevious = useMemo(() => step > 0 || Boolean(previous), [previous, step]);

	const goToNextStep = async (props: FormikProps<TModel>) => {
		const canContinue = validateStepModelByRecord(props, schema, step, stepsRecord);
		const canContinueMore = validateMore === undefined || validateMore();
		if (canContinue && canContinueMore) {
			setStep(step + 1);
		}
	};

	const onPrevious = () => {
		if (step > 0) {
			setStep(step - 1);
		} else if (previous) {
			previous();
		} else {
			// no - op
		}
	};

	return (
		<div className='df-row-ac w100 jc-e'>
			{leftContent && (
				<>
					{leftContent}
					<div className='fg1'></div>
				</>
			)}
			<Button
				onClick={cancel}
				color='primary'>
				{strings.cancel}
			</Button>
			<Button
				disabled={canClickPrevious === false}
				onClick={onPrevious}
				color='primary'>
				{strings.previous}
			</Button>
			{step !== maxStep(stepsRecord) && (
				<Button
					onClick={() => goToNextStep(props)}
					disabled={canNext === false}
					color='primary'>
					{strings.next}
				</Button>
			)}
			{step === maxStep(stepsRecord) && (
				<LoaderButton
					isLoading={isSubmitting}
					type='submit'
					disabled={isSubmitting || !props.isValid}
					color='primary'
					onClick={() => props.submitForm()}>
					{submitText}
				</LoaderButton>
			)}
		</div>
	);
};
