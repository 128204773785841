import { ButtonProps } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { MediumPrimaryTextButton } from 'framework/components/buttons/MediumPrimaryTextButton';
import { RectangularChip } from 'framework/components/chips/RectangularChip';

interface IProps extends ButtonProps {
	badge?: number;
}

export const WizardButton = ({ style, badge, ...props }: IProps) => {
	return (
		<div
			className='df-row-ac'
			style={style}>
			<MediumPrimaryTextButton
				{...props}
				endIcon={<AddIcon />}
			/>
			{badge !== undefined && badge >= 1 && (
				<RectangularChip
					label={badge.toString()}
					fill
					color='error'
					style={{ borderRadius: 20, fontSize: 12 }}
				/>
			)}
		</div>
	);
};
