import { Dialog, DialogContent, DialogProps, DialogTitle, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import React, { useContext, useEffect, useState } from 'react';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';
import { useTrigger } from 'framework/hooks/useTrigger';
import { eid_read, IEidData, IEidReadResponse } from 'gen/eid/EidClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { EidApplicationContext } from 'app/externalApps/eid/EidApplicationContext';
import { DownloadAndInstallEidSoftwareDialog } from 'shared/downloads/DownloadAndInstallEidSoftwareDialog';
import { ProcessButton } from 'shared/process/ProcessButton';
import { ProcessErrorTypography } from 'shared/process/ProcessErrorTypography';
import { DragAndDropWrapper } from './DragAndDropWrapper';
import { BeidMiddlewareUrl } from 'keys';

const calculateStep = (isInstalled: boolean, response: IEidReadResponse | undefined): number => {
	if (isInstalled === false) {
		return 0;
	} else if (response === undefined) {
		return successStep;
	} else if (response && response.errorNoMiddleWareInstalled) {
		return 1;
	} else if (response && response?.errorNoReaderConnected) {
		return 2;
	} else if (response && response?.errorNoCardInserted) {
		return 3;
	} else if (response && response?.unknownError && response?.unknownError !== '') {
		return 4;
	} else {
		return successStep;
	}
};

const successStep: number = 5;

interface IProps extends DialogProps {
	cancel: VoidFunction;
	onRead: (data: IEidData) => void;
	isSubmitting: boolean;
	validationError?: string;
}

export const EidReadDialog = ({ cancel, onRead, isSubmitting, validationError, ...rest }: IProps) => {
	const strings = useLocalization();
	const { isInstalled, check, isInitialized, isChecking } = useContext(EidApplicationContext);
	const [response, setResponse] = useState<IEidReadResponse>();
	const [loadEidData, isLoading] = useFormSubmit(eid_read);
	// const loadEidData = useApiCall(eid_read, setIsLoading);
	const [step, setStep] = useState<number>(calculateStep(isInstalled, undefined));
	const [trigger, hitTrigger] = useTrigger();
	const openInNewTab = useOpenInNewTab();
	const { open, confirm, isStacked } = useDialogsContext(check);

	useEffect(() => {
		check();
	}, [check]);

	useLazyEffect(() => {
		setStep(calculateStep(isInstalled, response));
		if (response?.isSuccess && response?.data) {
			onRead(response.data);
		}
	}, [response, isInstalled]);

	useEffect(() => {
		const load = async () => {
			const r = await loadEidData();
			setResponse(r.isSuccess ? r.result : undefined);
		};
		if (isInstalled === true && isInitialized === true) {
			load();
		}
		// eslint-disable-next-line
	}, [trigger, isInstalled, isInitialized]);

	const onDownload = () => {
		open(
			<DownloadAndInstallEidSoftwareDialog
				open
				close={confirm}
			/>
		);
	};

	return (
		<Dialog
			maxWidth='md'
			{...rest}
			open={rest.open && isStacked === false}>
			<DialogTitle>{strings.readDataFromeID}</DialogTitle>
			<DialogContent
				dividers
				style={{ minWidth: '400px' }}
				className='df-col'>
				<DragAndDropWrapper onRead={onRead}>
					<Stepper
						activeStep={step}
						orientation='vertical'
						style={{ paddingLeft: '0px' }}>
						<Step>
							<StepLabel error={isInstalled === false}>{strings.installation}</StepLabel>
							<StepContent>
								<div className='df-col'>
									<ProcessErrorTypography>{strings.noConnectionCouldBeMadeToeIDSoftware}</ProcessErrorTypography>
									<ProcessButton
										startIcon={<GetAppOutlinedIcon />}
										text={strings.download}
										onClick={onDownload}
									/>
								</div>
							</StepContent>
						</Step>
						<Step active={response && response.errorNoMiddleWareInstalled}>
							<StepLabel error={response?.errorNoMiddleWareInstalled}>Middleware</StepLabel>
							<StepContent>
								<div className='df-col'>
									<ProcessErrorTypography>{strings.beIDMiddlewareIsNotInstalled}</ProcessErrorTypography>
									<ProcessButton
										text={strings.download}
										onClick={() => openInNewTab(BeidMiddlewareUrl, true)}
									/>
								</div>
							</StepContent>
						</Step>
						<Step>
							<StepLabel error={response && response.errorNoReaderConnected}>{strings.connectReader}</StepLabel>
						</Step>
						<Step>
							<StepLabel error={response && response.errorNoCardInserted}>{strings.insertCard}</StepLabel>
						</Step>
						<Step>
							<StepLabel error={response && response.unknownError !== ''}>{strings.readData}</StepLabel>
							<StepContent>{response && response.unknownError && <div>{response.unknownError}</div>}</StepContent>
						</Step>
						<Step>
							<StepLabel error={validationError !== undefined && validationError !== ''}>{strings.validateData}</StepLabel>
							<StepContent>
								<div>{validationError}</div>
							</StepContent>
						</Step>
					</Stepper>
				</DragAndDropWrapper>
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				submitText={strings.retry}
				onSubmit={() => (isInstalled ? hitTrigger() : check())}
				isSubmitting={isLoading || isSubmitting || isChecking}
				colorSubmitButton='secondary'
			/>
		</Dialog>
	);
};
