import { DialogProps } from '@material-ui/core';
import React from 'react';
import { ISupplier, suppliersCommand_patchDefaultDeliveryTime, DeliveryTimeUnits } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { TimeModelForm } from 'shared/timeUnit/TimeModelForm';

interface IProps extends DialogProps {
	supplier: ISupplier;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const SupplierDefaultDeliveryTimeForm = ({ supplier, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<TimeModelForm
			{...rest}
			title={strings.changeDefaultDeliveryTime}
			label={strings.defaultDeliveryTime}
			defaultUnit='Weeks'
			model={supplier.defaultDeliveryTime}
			confirm={confirm}
			submitFunction={model => suppliersCommand_patchDefaultDeliveryTime({ supplierId: supplier.id, defaultDeliveryTime: model })}
			cancel={cancel}
			options={DeliveryTimeUnits}
		/>
	);
};
