import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { SuppliersCacheContext } from 'app/main/contacts/suppliers/SuppliersCacheContext';
import { FormSelectOrCreateSupplierField } from 'app/main/contacts/suppliers/forms/FormSelectOrCreateSupplierField';
import { LocationsPreloadCacheContext } from 'app/main/settings/locations/LocationsPreloadCacheContext';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	deliveriesCommand_newNonTailorMadeInventoryItem,
	deliveriesQuery_nextReference,
	inventoryItemsQuery_single,
	IRegisterDeliveryFromInventoryItemRequest,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterDeliveryFromInventoryItemRequest>({
			reference: yup.string().required(),
			externalReference: yup.string(),
			isAutoSelectReference: yup.boolean().defined(),
			supplierId: yup.string().required(),
			inventoryItemId: yup.string().required(),
			deliveryDate: yup.date().required(),
			dispatchDate: yup.date(),
			dispatchReference: yup.string(),
			locationId: yup.string().required(),
		})
		.defined();
};

const EmptyValues: IRegisterDeliveryFromInventoryItemRequest = {
	reference: '',
	isAutoSelectReference: true,
	supplierId: '',
	inventoryItemId: '',
	externalReference: '',
	deliveryDate: undefined as any,
	dispatchDate: undefined as any,
	dispatchReference: undefined as any,
	locationId: '',
};

const stepsRecord: Record<number, (keyof IRegisterDeliveryFromInventoryItemRequest)[]> = {
	0: ['reference', 'isAutoSelectReference', 'externalReference'],
	1: ['locationId', 'supplierId'],
	2: ['deliveryDate'],
	3: ['dispatchReference', 'dispatchDate'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	inventoryItemId: string;
}

export const RegisterDeliveryFromInventoryItemRequestForm = ({ confirm, cancel, inventoryItemId, ...rest }: IProps) => {
	const strings = useLocalization();
	const [step, setStep] = useState<number>(0);
	const schema = useMemo(() => createSchema(strings), [strings]);
	const [create, isSubmitting] = useFormSubmit(deliveriesCommand_newNonTailorMadeInventoryItem);
	const [inventoryItem] = useApiEffect(inventoryItemsQuery_single, inventoryItemId);
	const notify = useSnackbarNotify();
	const now = useNow();

	const handleSubmit = async (values: IRegisterDeliveryFromInventoryItemRequest, helpers: FormikHelpers<IRegisterDeliveryFromInventoryItemRequest>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.registeredWhat(strings.purchase));
			confirm(r.result.objectId);
		}
	};

	if (inventoryItem === undefined || now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterDeliveryFromInventoryItemRequest>
			validateOnMount
			initialValues={{
				...EmptyValues,
				inventoryItemId: inventoryItemId,
				supplierId: inventoryItem.supplierId ?? '',
				deliveryDate: now,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					isSubmitting={isSubmitting}
					cancel={cancel}
					step={step}
					setStep={setStep}
					stepsRecord={stepsRecord}
					schema={schema}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof IRegisterDeliveryFromInventoryItemRequest)[]>;
	schema: yup.ObjectSchema<IRegisterDeliveryFromInventoryItemRequest>;
}

export const InnerDialog = ({ cancel, isSubmitting, step, setStep, stepsRecord, schema, ...rest }: IInnerProps) => {
	const props = useFormikContext<IRegisterDeliveryFromInventoryItemRequest>();
	const strings = useLocalization();
	const [suppliers] = usePreloadCacheContext(SuppliersCacheContext);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	useNextReferenceInterceptor(deliveriesQuery_nextReference, props);

	useEffect(() => {
		if (suppliers?.length === 1) {
			setFormValue<IRegisterDeliveryFromInventoryItemRequest>('supplierId', suppliers[0].id, props);
		}
		// eslint-disable-next-line
	}, [suppliers]);

	useEffect(() => {
		if (locations?.length === 1) {
			setFormValue<IRegisterDeliveryFromInventoryItemRequest>('locationId', locations[0].id, props);
		}
		// eslint-disable-next-line
	}, [locations]);

	if (suppliers === undefined || locations === undefined) {
		return <div></div>;
	}

	return (
		<Dialog {...rest}>
			<DialogTitleWithFormStepper
				title={strings.newWhat(strings.purchase)}
				step={step}
				labels={[strings.referenceShort, strings.fromTo, strings.date, strings.shipment]}
			/>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IRegisterDeliveryFromInventoryItemRequest>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IRegisterDeliveryFromInventoryItemRequest>
							name='reference'
							label={strings.reference}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormTextField<IRegisterDeliveryFromInventoryItemRequest>
							name='externalReference'
							label={`${strings.externalReference} (${strings.fromSupplier})`}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectOrCreateSupplierField<IRegisterDeliveryFromInventoryItemRequest>
							name='supplierId'
							label={strings.supplier}
						/>
						<FormSelectFieldWithOption<IRegisterDeliveryFromInventoryItemRequest, string>
							name='locationId'
							options={locations}
							label={strings.location}
							helperText={strings.selectDeliveryLocationHelperText}
						/>
					</>
				)}
				{step === 2 && (
					<FormDatePicker<IRegisterDeliveryFromInventoryItemRequest>
						name='deliveryDate'
						label={strings.deliveryDate}
						required
					/>
				)}
				{step === 3 && (
					<>
						<FormTextField<IRegisterDeliveryFromInventoryItemRequest>
							name='dispatchReference'
							label={`${strings.dispatchReference} (${strings.optional})`}
						/>
						<FormDatePicker<IRegisterDeliveryFromInventoryItemRequest>
							name='dispatchDate'
							label={`${strings.dispatchDate} (${strings.optional})`}
						/>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
