import React from 'react';
import { useRouteQuery } from './useRouteQuery';

export function useRouteQueryParam(identifier: string): string | undefined {
	const routeQuery = useRouteQuery();
	return React.useMemo(() => {
		const x = routeQuery.get(identifier);
		if (x === null) {
			return undefined;
		} else {
			return x;
		}
	}, [routeQuery, identifier]);
}
