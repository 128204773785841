import { useContext } from 'react';
import {
	ArticlesInquiryStatusType,
	IWorkflowSummary,
	LoanProcessStatus,
	OrderProcessStatus,
	RepairProcessStatus,
	RfiaStatus,
	WorkflowType,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from '../records/RecordContext';

export function useWorkflowStatusMapper() {
	const strings = useLocalization();
	const {
		rfiaStatusRecord,
		repairProcessStatusRecord: repairStatusRecord,
		loanStatusRecord,
		orderStatusRecord,
		articlesInquiryStatusTypeRecord,
	} = useContext(RecordContext);

	return (item: IWorkflowSummary): string => {
		const type = item.type as WorkflowType;
		if (type === 'Loan') {
			return loanStatusRecord[item.status as LoanProcessStatus];
		} else if (type === 'Rfia') {
			return rfiaStatusRecord[item.status as RfiaStatus];
		} else if (type === 'Order') {
			return orderStatusRecord[item.status as OrderProcessStatus];
		} else if (type === 'Repair') {
			return repairStatusRecord[item.status as RepairProcessStatus];
		} else if (type === 'ArticlesInquiry') {
			return articlesInquiryStatusTypeRecord[item.status as ArticlesInquiryStatusType];
		} else {
			return strings.notApplicableAbbreviation;
		}
	};
}
