import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RestoreIcon from '@material-ui/icons/Restore';
import SettingsIcon from '@material-ui/icons/Settings';
import { HamsClientConfigurationDialog } from 'app/client/HamsClientConfigurationDialog';
import { HamsClientApplicationContext } from 'app/externalApps/client/HamsClientApplicationContext';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { formatDate } from 'framework/utils/date/formatDate';
import { INoahActionGroupDisplay, noahDataCommand_deleteActions, noahDataCommand_restoreActions } from 'gen/ApiClient';
import { IModuleSummary, force_set_patient, launch_WithAction } from 'gen/client/HamsClient';
import { useHandleClientReponse } from 'gen/client/useHandleClientReponse';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SelectModuleDialog } from './SelectModuleDialog';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

interface IProps extends DialogProps {
	patientIndex: number;
	actionGroup: INoahActionGroupDisplay;
	close: VoidFunction;
}

export const ActionGroupDetailDialog = ({ patientIndex, actionGroup, close, ...rest }: IProps) => {
	const { open, confirm, cancel, isStacked } = useDialogsContext();
	const { canLaunch, check, installedBuild } = useContext(HamsClientApplicationContext);
	const theme = useTheme();
	const [launch] = useFormSubmit(launch_WithAction);
	const handle = useHandleClientReponse();
	const strings = useLocalization();
	const [resetPatient, isResettingPatient] = useFormSubmit(force_set_patient);
	const notify = useSnackbarNotify();

	useEffect(() => {
		check();
		// eslint-disable-next-line
	}, []);

	const onStartModule = async () => {
		const r = await launch(patientIndex, actionGroup.firstIndex);
		if (r.isSuccess) {
			handle(r.result, strings.moduleStarted);
			if (r.result.mustSelectModule) {
				onSelectModule(r.result.modules ?? []);
			} else {
				close();
			}
		}
	};

	const onConfig = () => {
		open(
			<HamsClientConfigurationDialog
				open
				close={cancel}
			/>
		);
	};

	const onSelectModule = (modules: IModuleSummary[]) => {
		open(
			<SelectModuleDialog
				open
				modules={modules}
				confirm={onConfirmModule}
				cancel={cancel}
				patientIndex={patientIndex}
				actionIndex={actionGroup.firstIndex}
			/>
		);
	};

	const onConfirmModule = () => {
		confirm();
		close();
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title={strings.deleteWhatQuestion(strings.NoahActionSingularOrPlural)}
				content={strings.actionsWillBeDeletedFromThisList}
				acceptText={strings.yesCommaDelete}
				acceptF={() => noahDataCommand_deleteActions(actionGroup.actionIds)}
				accept={onRestoredOrDeleted}
				reject={cancel}
			/>
		);
	};

	const onRestore = () => {
		open(
			<AlertDialog
				open
				title={strings.repairWhatQuestion(strings.NoahActionSingularOrPlural)}
				content={strings.actionsWillBeVisibleAgainInThisList}
				acceptText={strings.yesWhatExclamation(strings.repairVerb)}
				acceptF={() => noahDataCommand_restoreActions(actionGroup.actionIds)}
				accept={onRestoredOrDeleted}
				reject={cancel}
			/>
		);
	};

	const onResetPatient = async () => {
		const r = await resetPatient(patientIndex);
		if (r.isSuccess) {
			if (isNotNullNorUndefined(r.result)) {
				notify(r.result, 'error');
			} else {
				notify('Patient reset', 'success');
			}
		}
	};

	const onRestoredOrDeleted = () => {
		confirm();
		close();
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<DescriptionIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{actionGroup.firstManufacturer}
					</Typography>
					<LabelledProperty
						label={strings.quantity}
						property={actionGroup.actions.length}
						withoutMargin
					/>
					{actionGroup.areAllDeleted && (
						<RectangularChip
							fill
							color='error'
							label={strings.deleted}
						/>
					)}
					{canLaunch === false && <WarningLabelledProperty warning={strings.errorCannotStartExternalModules} />}
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.NoahActions} />
				{actionGroup.actions.map(t => (
					<PropertyInGrid
						key={t.index}
						label={formatDate(t.noahCreatedDate, 'date-and-time')}
						labelGridStyle={{ alignItems: 'top' }}
						value={
							<div className='df-col'>
								<div style={{ textDecoration: t.isHamsDeleted ? 'line-through' : 'inherit' }}>
									<b>{t.userInitials}</b>
									<span>{` | ${t.description}`}</span>
								</div>
								{t.references.map(t => (
									<LabelledProperty
										key={t.description}
										label={strings.basedOn}
										property={`${formatDate(t.noahCreatedDate, 'date-and-time')} ${t.manufacturer}`}
									/>
								))}
							</div>
						}
					/>
				))}
				{canLaunch === false && (
					<>
						<GridDividerWithCaption caption={strings.installationAndConfiguration} />
						<PropertyInGrid
							value={
								<SmallPrimaryTextButton
									color='secondary'
									onClick={onConfig}
									startIcon={<SettingsIcon />}>
									{strings.solveProblems}
								</SmallPrimaryTextButton>
							}
						/>
					</>
				)}
				<GridDividerWithCaption caption={strings.actions} />
				{canLaunch === true && (
					<PropertyInGrid
						value={
							<div className='df-col-ab'>
								{canLaunch === true && (
									<SmallPrimaryTextButton
										onClick={onStartModule}
										startIcon={<OpenInNewIcon />}>
										{strings.changeStartModule}
									</SmallPrimaryTextButton>
								)}
								{actionGroup.areAllDeleted && (
									<SmallPrimaryTextButton
										onClick={onRestore}
										startIcon={<RestoreIcon />}>
										{strings.repairUndoDelete}
									</SmallPrimaryTextButton>
								)}
								{actionGroup.areAllDeleted === false && (
									<SmallPrimaryTextButton
										onClick={onDelete}
										startIcon={<DeleteOutlineIcon />}
										color='secondary'>
										{strings.delete}
									</SmallPrimaryTextButton>
								)}
								{installedBuild >= 120 && (
									<LoaderButton
										isLoading={isResettingPatient}
										color='primary'
										onClick={onResetPatient}
										size='small'
										startIcon={<RotateLeftIcon />}>
										{`Reset patient in Noah`}
									</LoaderButton>
								)}
							</div>
						}
					/>
				)}
			</DialogContentGrid>
		</Dialog>
	);
};
