import React from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { ICommissionPayout } from 'gen/ApiClient';

interface IProps {
	data: ICommissionPayout;
	style?: React.CSSProperties;
}

export const PayoutConfirmationStatusChip = ({ data, style }: IProps) => {
	return (
		<>
			{data.isConfirmed === false && (
				<RectangularChip
					style={style}
					label='Niet bevestigd'
					fill
					color='error'
				/>
			)}
		</>
	);
};
