import { useTheme } from '@material-ui/core';
import React from 'react';
import { FemaleIcon } from './icons/FemaleIcon';
import { GenderXIcon } from './icons/GenderXIcon';
import { MaleIcon } from './icons/MaleIcon';
import { Gender } from 'gen/ApiClient';

interface IProps {
	gender: Gender | undefined;
	style?: React.CSSProperties;
}

export const GenderIcon = ({ gender, style }: IProps) => {
	const theme = useTheme();
	switch (gender) {
		case 'Male':
			return <MaleIcon style={{ ...style, color: theme.palette.primary.main }} />;
		case 'Female':
			return <FemaleIcon style={{ ...style, color: theme.palette.secondary.main }} />;
		case 'X':
			return <GenderXIcon style={{ ...style, color: theme.palette.text.secondary }} />;
		default:
			return <div></div>;
	}
};
