import { useFormikContext } from 'formik';
import { RoundedSquareX } from 'framework/components/RoundedSquareX';
import { FormSelectFieldOnId } from 'framework/forms/FormSelectFieldOnId';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IAnnotationType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AnnotationTypesCacheContext } from './context/AnnotationTypesCacheContext';
import { AnnotationTypeForm } from './types/AnnotationTypeForm';

interface IProps {
	isFollowUp: boolean;
}

export const AnnotationTypeFormField = <TModel extends { annotationTypeId: string | undefined }>({ isFollowUp }: IProps) => {
	const [allAnnotationTypes, load] = usePreloadCacheContext(AnnotationTypesCacheContext);
	const strings = useLocalization();
	const fAnnotationTypes = useMemo(
		() => allAnnotationTypes.filter(t => t.isDeleted === false && t.isFollowUp === isFollowUp),
		[allAnnotationTypes, isFollowUp]
	);
	const { open, confirm, cancel } = useDialogsContext();
	const props = useFormikContext<TModel>();

	const onNew = () => {
		open(
			<AnnotationTypeForm
				open
				confirm={async id => {
					confirm();
					await load();
					setFormValue<TModel>('annotationTypeId', id, props);
				}}
				cancel={cancel}
				isFollowUp={isFollowUp}
			/>
		);
	};

	return (
		<FormSelectFieldOnId<TModel, IAnnotationType>
			name='annotationTypeId'
			label={strings.type}
			options={fAnnotationTypes}
			style={{ width: '100%' }}
			getKey={t => t.id}
			withNone
			onNew={onNew}
			withNoneText={strings.withoutWhat(strings.type)}
			renderValue={option => (
				<div className='df-row-ac'>
					<RoundedSquareX
						color={option.color}
						size={20}
					/>
					<div style={{ marginLeft: '5px' }}>{option.name}</div>
				</div>
			)}
		/>
	);
};
