import { DialogProps } from '@material-ui/core';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { BoolExtendedRadioGroupWithExternalSubmitForm } from 'framework/forms/BoolExtendedRadioGroupWithExternalSubmitForm';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { agendaQuery_countForLocation, ILocationSummary, locationsCommand_patchIsPlannable } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';

interface IProps extends DialogProps {
	location: ILocationSummary;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LocationToggleIsPlannableDialog = ({ location, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [mode, setMode] = useState<'request' | 'confirm'>('request');
	const [submit, isSubmitting] = useFormSubmit(locationsCommand_patchIsPlannable);
	const notify = useSnackbarNotify();
	const [count] = useApiEffect(agendaQuery_countForLocation, location.id);

	const onConfirm = async (isPlannable: boolean) => {
		if (location.isPlannable && isPlannable === false) {
			setMode('confirm');
		} else {
			onProceed(isPlannable);
		}
	};

	const onProceed = async (val: boolean) => {
		const r = await submit({ locationId: location.id, isPlannable: val });
		handleValidateResponseBase(
			r,
			e => notify(e, 'error'),
			() => {
				confirm();
			}
		);
		return r;
	};

	if (mode === 'request') {
		return (
			<BoolExtendedRadioGroupWithExternalSubmitForm
				{...rest}
				formTitle={strings.availableInCalendar}
				trueLabel={strings.availableInCalendar}
				falseLabel={strings.notAvailableInCalendar}
				trueCaption={strings.availableInCalendarCaption}
				falseCaption={strings.notAvailableInCalendarCaption}
				submit={onConfirm}
				initialValue={location.isPlannable}
				cancel={cancel}
				submitText={strings.update}
				isSubmitting={isSubmitting}
			/>
		);
	} else if (count !== undefined && mode === 'confirm') {
		return (
			<AlertDialog
				{...rest}
				title={strings.setLocationAsNotPlannable}
				content={strings.countEventsAreLinkedToThisLocationDoYouWantToContinueQuestion(count)}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				acceptF={() => onProceed(false)}
				accept={() => {}}
				reject={cancel}
			/>
		);
	} else {
		return <div></div>;
	}
};
