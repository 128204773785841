import { IRfiaHandoverLineModel } from 'gen/ApiClient';
import { IHandoverLine } from '../IHandoverLine';

export const lineToModel = (line: IHandoverLine, index: number): IRfiaHandoverLineModel => {
	return {
		productCatalogItemId: line.productCatalogItemId,
		inventoryItemId: line.inventoryItemId,
		unitListPriceInVat: line.unitListPrice,
		unitPriceInVat: line.unitPrice,
		quantity: line.quantity,
		description: line.description,
		vatPercentage: line.vatPercentage,
		zeroBasedIndexNumber: index,
		isRizivRefundable: line.isRefundable,
		isIncludeOnHandoverCertificate: line.isIncludeOnHandoverCertificate,
		manufacturer: line.manufacturer,
		model: line.model,
		serialNumber: line.serialNumber,
		rizivHearingAidDefinitionV2IdentificationCode: line.rizivHearingAidDefinitionV2IdentificationCode,
		assuredInventoryItemId: line.assuredInventoryItemId,
		assuredProductCatalogItemId: line.assuredProductCatalogItemId,
	};
};
