import { Grid } from '@material-ui/core';
import { GridDivider } from 'framework/components/detailDialog/GridDivider';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { calculateTotalsFromAmountInVat } from 'shared/searchAndSelect/utils/calculateTotalsFromAmountInVat';
import { boolToYesNo } from 'shared/utils/boolToString';

interface ILine {
	unitPrice: number;
	quantity: number;
	vatPercentage: number;
}

interface IProps<TLine extends ILine> {
	reference: string | undefined;
	recipientName: string | undefined;
	lines: TLine[];
	isShiftVat?: boolean;
}

export const SaleFormConfirmation = <TLine extends ILine>({ reference, recipientName, lines, isShiftVat }: IProps<TLine>) => {
	const strings = useLocalization();
	const { totalExVat, totalInVat } = useMemo(() => calculateTotalsFromAmountInVat(lines, isShiftVat ?? false), [lines, isShiftVat]);

	return (
		<Grid
			container
			spacing={2}
			style={{ width: 550 }}>
			<Grid
				item
				xs={12}
				className='df-col-ac'>
				<LightBulbNotification>{strings.thisTransactionCannotBeUndoneText}</LightBulbNotification>
			</Grid>
			<GridDivider />
			<PropertyInGrid
				labelGridSize={6}
				label={strings.invoiceNumber}
				value={reference}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.withRespectToShort}
				value={recipientName}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.totalInVat}
				value={formatCurrency(totalInVat)}
			/>
			{isShiftVat !== undefined && (
				<PropertyInGrid
					labelGridSize={6}
					label={strings.shiftVat}
					value={boolToYesNo(isShiftVat, strings)}
				/>
			)}
			<PropertyInGrid
				labelGridSize={6}
				label={strings.totalExVat}
				value={formatCurrency(totalExVat)}
			/>
		</Grid>
	);
};
