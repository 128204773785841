import { Card, Divider, Typography } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DefaultCardContent } from 'framework/components/DefaultCardContent';
import React, { useMemo } from 'react';

interface IProps {
	title: string;
	description: string;
	value: string | number;
	width?: number;
	action?: JSX.Element;
	style?: React.CSSProperties;
	actions?: JSX.Element;
	secondaryTitle?: JSX.Element;
	secondaryValue?: JSX.Element;
	withoutMargin?: boolean;
	valueStyle?: React.CSSProperties;
}

export const QuickStatCard = ({
	title,
	description,
	value,
	action,
	style,
	actions,
	secondaryTitle,
	secondaryValue,
	width,
	valueStyle,
	withoutMargin = false,
}: IProps) => {
	const fixedWidthStyle: React.CSSProperties = useMemo<React.CSSProperties>(() => (width ? { width: `${width}px` } : {}), [width]);

	return (
		<Card
			style={{
				height: '100%',
				marginRight: withoutMargin ? 0 : 16,
				marginBottom: withoutMargin ? 0 : 16,
				position: 'relative',
				minHeight: '110px',
				...fixedWidthStyle,
				...style,
			}}
			className='df-col'>
			{action && <div style={{ position: 'absolute', right: 8, top: 8 }}>{action}</div>}
			<DefaultCardContent className='df-col fg1'>
				<div className='df-row-ac jc-sb'>
					<CardTitleTypography
						title={title}
						fontWeight='inherit'
					/>
					{secondaryTitle && secondaryTitle}
				</div>
				<Typography
					variant='caption'
					color='textSecondary'
					style={{ marginTop: '-2px' }}>
					{description}
				</Typography>
				<div className='df-col jc-c fg1'>
					<div className='df-row-ac'>
						<div style={{ fontSize: '24px', ...valueStyle }}>{value}</div>
						{secondaryValue && secondaryValue}
					</div>
				</div>
			</DefaultCardContent>
			{actions && (
				<div className='df-col'>
					<Divider />
					<div style={{ padding: 8 }}>{actions}</div>
				</div>
			)}
		</Card>
	);
};
