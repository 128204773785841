import { Button } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { GetFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file';
import React from 'react';

interface IProps {
	plugin: GetFilePlugin;
}

export const DownloadComponent = ({ plugin }: IProps) => {
	const { Download } = plugin;

	return (
		<Download>
			{(props: RenderDownloadProps) => (
				<Button
					variant='text'
					color='inherit'
					startIcon={<GetAppOutlinedIcon />}
					onClick={props.onClick}>
					Download
				</Button>
			)}
		</Download>
	);
};
