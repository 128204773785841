import { IconButton, Step, StepLabel, StepProps, useTheme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { formatDate } from 'framework/utils/date/formatDate';
import { IRequestForInsuranceAllowance, ITrialStepDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TrailStepDetailDialog } from '../components/TrailStepDetailDialog';
import { TrialStepContent } from './TrialStepContent';

interface IProps extends StepProps {
	rfia: IRequestForInsuranceAllowance;
	steps: ITrialStepDto[];
	step: ITrialStepDto;
	reload: VoidFunction;
}

export const TrialStep = ({ rfia, step, steps, reload, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { open, confirm } = useDialogsContext(reload);

	const onViewDetail = () => {
		open(
			<TrailStepDetailDialog
				rfiaId={rfia.id}
				trialId={step.id}
				patientId={rfia.patientId}
				open
				close={confirm}
			/>
		);
	};

	return (
		<Step
			{...rest}
			completed={step.isEnded}
			active={step.isEnded === false && rfia.isCancelled === false}>
			<StepLabel error={rfia.isFailedTrial}>
				<div className='df-col'>
					<div className='df-row-ac'>
						<div>{`${strings.equipmentTestTitle}${rfia.isFailedTrial ? ` (${strings.failed.toLowerCase()})` : ''}`}</div>
						<IconButton
							size='small'
							style={{ marginLeft: theme.spacing(0.5), color: rfia.isFailedTrial ? theme.palette.error.main : theme.palette.primary.main }}
							onClick={onViewDetail}>
							<HelpOutlineIcon fontSize='small' />
						</IconButton>
					</div>
					<LabelledProperty
						label='Start'
						property={formatDate(step.startDate)}
						withoutMargin
						variant='caption'
					/>
					{step.isEnded === false && (
						<LabelledProperty
							label={`${strings.stop} (${strings.expected})`}
							property={formatDate(step.expectedStopDate)}
							withoutMargin
							variant='caption'
						/>
					)}
					{step.onlyAdministrativeTrialEndDate && (
						<LabelledProperty
							label={strings.endTrialAdministratively}
							property={formatDate(step.onlyAdministrativeTrialEndDate)}
							withoutMargin
							variant='caption'
						/>
					)}
					{step.isEnded === true && (
						<LabelledProperty
							label={`${strings.endLoan} (${strings.actual})`}
							property={formatDate(step.loanEndDate)}
							withoutMargin
							variant='caption'
						/>
					)}
				</div>
			</StepLabel>
			<TrialStepContent
				style={{ marginTop: theme.spacing(1) }}
				rfia={rfia}
				reload={reload}
				step={step}
			/>
		</Step>
	);
};
