import { useApiCall } from 'framework/hooks/useApiCall';
import { IRosaViewPreferences, rosaViewPreferencesQuery_get } from 'gen/ApiClient';
import React, { useCallback, useContext, useState } from 'react';
import { PreloadContext } from 'shared/context/PreloadContext';
import { RosaViewPreferencesPreloadCacheContext } from './RosaViewPreferencesPreloadCacheContext';

export const RosaViewPreferencesPreloadCacheProvider = ({ children }: any) => {
	const { rosaViewPreferences } = useContext(PreloadContext);
	const [data, setData] = useState<IRosaViewPreferences>(rosaViewPreferences);
	const load = useApiCall(rosaViewPreferencesQuery_get);

	const reload = useCallback(async () => {
		const r = await load();
		if (r.isSuccess) {
			setData(r.result);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<RosaViewPreferencesPreloadCacheContext.Provider
			value={{
				rosaViewPreferences: data,
				reload: reload,
			}}>
			{children}
		</RosaViewPreferencesPreloadCacheContext.Provider>
	);
};
