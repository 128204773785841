import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IPatchAutoSetSalesToPaidRequest, salesSettingsCommand_patchAutoSetToPaid } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchAutoSetSalesToPaidRequest>({
		autoSetToPaid: yup.bool().required(),
	});
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchAutoSetSalesToPaidRequestForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [patch, isSubmitting] = useFormSubmit(salesSettingsCommand_patchAutoSetToPaid);

	const handleSubmit = async (values: IPatchAutoSetSalesToPaidRequest, helpers: FormikHelpers<IPatchAutoSetSalesToPaidRequest>) => {
		const r = await patch(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	return (
		<Formik<IPatchAutoSetSalesToPaidRequest>
			validateOnMount
			initialValues={{
				autoSetToPaid: false,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.selectWhat(strings.location)}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormExtendedRadioGroupField<IPatchAutoSetSalesToPaidRequest, boolean>
							name='autoSetToPaid'
							label=''
							options={[
								{ value: false, label: strings.setInvoicesToPaidViaPayments, caption: strings.registerPaymentsInHAMS },
								{ value: true, label: strings.setInvoicesAutoToPaid, caption: strings.doNotRegisterPaymentsInHAMS },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
