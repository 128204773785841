import { Button, Card, CardContent, Divider } from '@material-ui/core';
import { EventListItemComponent } from 'app/main/calendar/agenda/list/EventListItemComponent';
import { spreadAndFilterRecurringEvents } from 'app/main/calendar/agenda/list/spreadAndFilterRecurringEvents';
import { CreateDefaultCalendarEventForm } from 'app/main/calendar/forms/CreateDefaultCalendarEventForm';
import { endOfDay, startOfDay } from 'date-fns';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { CalendarPlusIcon } from 'framework/components/icons/CalendarPlusIcon';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { isLast } from 'framework/utils/array/isLast';
import { agendaQuery_queryByUser, IAgendaQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { getNow } from 'shared/utils/getNow';

interface IProps {
	className?: string;
	style?: React.CSSProperties;
	reloadTrigger: Trigger;
}

export const HamsTodayEventsCard = ({ className, style, reloadTrigger }: IProps) => {
	const queryParams = useMemo<IAgendaQueryParams>(() => ({ afterDate: startOfDay(getNow()), beforeDate: endOfDay(getNow()) }), []);
	const [events, reload] = useApiEffect(agendaQuery_queryByUser, queryParams);
	const sfEvents = useMemo(() => spreadAndFilterRecurringEvents(events ?? [], queryParams.afterDate, queryParams.beforeDate), [events, queryParams]);
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	const onNew = () => {
		open(
			<CreateDefaultCalendarEventForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Card
			className={className}
			style={style}>
			<CardTitleTypography
				title={`${strings.appointments} ${strings.today.toLowerCase()}`}
				withPadding
				action={
					<Button
						variant='text'
						size='small'
						color='primary'
						startIcon={<CalendarPlusIcon />}
						onClick={onNew}>
						{strings.new}
					</Button>
				}
			/>
			{sfEvents.length === 0 && (
				<CardContent style={{ paddingTop: 0, paddingBottom: sfEvents.length === 0 ? 16 : 0 }}>
					<NoRecordsFound>{strings.noAppointmentsPlanned}</NoRecordsFound>
				</CardContent>
			)}
			{sfEvents.length !== 0 && (
				<CardContent style={{ padding: 0 }}>
					{sfEvents.map(event => (
						<React.Fragment key={event.id}>
							<EventListItemComponent
								event={event}
								reload={reload}
								colorScheme='types'
								style={{
									display: 'flex',
									flexDirection: 'column',
									height: 'unset',
									borderRadius: 'unset',
									alignItems: 'start',
									paddingLeft: 16,
									paddingRight: 16,
									paddingTop: 8,
									paddingBottom: 8,
								}}
							/>
							{isLast(event, sfEvents) === false && <Divider />}
						</React.Fragment>
					))}
				</CardContent>
			)}
		</Card>
	);
};
