import React from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { INoahDeviceInventoryItemMap, INoahDeviceForPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	item: INoahDeviceInventoryItemMap | INoahDeviceForPatient;
	style?: React.CSSProperties;
}

export const NoahDeviceImportedStatusChip = ({ item, style }: IProps) => {
	const strings = useLocalization();

	return (
		<RectangularChip
			style={style}
			label={item.isLinkedToInventoryItem ? strings.imported : strings.notWhat(strings.imported)}
			color={item.isLinkedToInventoryItem ? 'success' : 'info'}
		/>
	);
};
