import { ICalendarEventDto } from 'gen/ApiClient';

export const filterEvents = (
	events: ICalendarEventDto[],
	selectedEventTypeResourceKeys: string[],
	selectedUserResourceKeys: string[],
	selectedRoomResourceKeys: string[]
) => {
	return events
		.filter(t => selectedEventTypeResourceKeys.findIndex(x => x === t.eventTypeKey) !== -1)
		.filter(t => selectedUserResourceKeys.some(x => t.userGroupKeys.indexOf(x) !== -1))
		.filter(t => selectedRoomResourceKeys.findIndex(x => x === t.roomGroupKey) !== -1);
};
