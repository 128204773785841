import React from 'react';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { useLocalization } from 'localization/useLocalization';
import { DialogProps } from '@material-ui/core';
import { IEntPhysician, entPhysiciansCommand_patchRizivNumber } from 'gen/ApiClient';
import { RizivNumberMask } from 'shared/RizivNumberMask';

interface IProps extends DialogProps {
	entPhysician: IEntPhysician;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchEntPhysicianRizivNumberForm = ({ entPhysician, ...props }: IProps) => {
	const strings = useLocalization();

	return (
		<StringModelForm
			mask={RizivNumberMask}
			formTitle={strings.changeRIZIVNumber}
			label={strings.RIZIVNumber}
			notifyMessage={strings.entPhysicianUpdated}
			submitFunction={val => entPhysiciansCommand_patchRizivNumber({ entPhysicianId: entPhysician.id, rizivNumber: val })}
			initialValue={entPhysician.rizivCode ?? ''}
			submitText={strings.update}
			{...props}
		/>
	);
};
