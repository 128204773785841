import { StatCardX } from 'framework/components/cards/StatCardX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { formatDate } from 'framework/utils/date/formatDate';
import { IRosaPatientsQueryMetadata } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	metadata: IRosaPatientsQueryMetadata;
}

export const RosaPatientRecordsListStats = ({ metadata }: IProps) => {
	const strings = useLocalization();

	return (
		<div className='df-row gap-16'>
			<StatCardX
				style={{ width: 200 }}
				className='fg1'
				title={strings.total}
				caption={`# ${strings.patients}`}>
				<span style={{ fontSize: 24 }}>{metadata.countRecords}</span>
			</StatCardX>
			<StatCardX
				style={{ width: 200 }}
				className='fg1'
				title={strings.notImported}
				caption={`# ${strings.patients}`}>
				<div className='df-row-ac gap-4'>
					<span style={{ fontSize: 24 }}>{metadata.countUnlinkedRecords}</span>
					<StatusIcon status={metadata.countUnlinkedRecords === 0 ? 'success' : 'error'} />
				</div>
			</StatCardX>
			<StatCardX
				style={{ width: 200 }}
				className='fg1'
				title={strings.lastSynced}
				caption={strings.date}>
				<span style={{ fontSize: 24 }}>{formatDate(metadata.lastSyncedDate, 'date-and-time')}</span>
			</StatCardX>
		</div>
	);
};
