import { Divider } from '@material-ui/core';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { useApiEffectWithDefer } from 'framework/hooks/useApiEffectWithDefer';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { patientsQuery_hasContactPerson } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { AddressInput } from 'shared/address/AddressInput';
import { FormRecipientNameField } from './FormRecipientNameField';

interface IModel {
	recipientName: string | undefined;
	addressLine: string | undefined;
	zip: string | undefined;
	city: string | undefined;
	country: string | undefined;
}

interface IProps {
	patientId?: string;
	isUseContactPerson: boolean;
	setIsUseContactPerson: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormRecipientField = <TModel extends IModel>({ patientId, isUseContactPerson, setIsUseContactPerson }: IProps) => {
	const strings = useLocalization();
	const [hasContactPerson] = useApiEffectWithDefer(patientsQuery_hasContactPerson, patientId ?? '', isNotNullNorUndefined);

	return (
		<>
			{hasContactPerson === true && (
				<>
					<ExtendedRadioGroup<boolean>
						selected={isUseContactPerson}
						setSelected={setIsUseContactPerson}
						options={[
							{ value: false, label: strings.directedToPatient },
							{ value: true, label: strings.directedToContactPerson },
						]}
					/>
					<Divider style={{ marginTop: 8, marginBottom: 24 }} />
				</>
			)}
			<FormRecipientNameField<TModel> />
			<AddressInput<TModel> optional />
		</>
	);
};
