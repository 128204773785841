import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import {
	audiogramStandardsQuery_withTtaByPatient,
	IAudiogramStandardWithToneThresholdAudiogramsDto,
	IStereoToneThresholdAudiogramTestModel,
	ITonePoint,
	IToneThresholdAudiogramTestsDto,
	trialReportsCommand_addToneThresholdBoth,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { SelectAudiogramStandardToToneThresholdAudiogramsField } from './SelectAudiogramStandardToToneThresholdAudiogramsField';
import { ToneThresholdAudiogramPtaComponent } from './ToneThresholdAudiogramPtaComponent';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IStereoToneThresholdAudiogramTestModel>({
			audiogramStandardId: yup.string().required(),
			leftPointsForPta: yup.mixed(),
			leftData: yup.mixed(),
			rightPointsForPta: yup.mixed(),
			rightData: yup.mixed(),
		})
		.defined();
};

const emptyValues: IStereoToneThresholdAudiogramTestModel = {
	audiogramStandardId: '',
	leftPointsForPta: [],
	leftData: undefined as any,
	rightPointsForPta: [],
	rightData: undefined as any,
};

const stepsRecord: Record<number, (keyof IStereoToneThresholdAudiogramTestModel)[]> = {
	0: ['audiogramStandardId'],
	1: ['leftPointsForPta'],
	2: ['rightPointsForPta'],
};

interface IProps extends DialogProps {
	patientId: string;
	trialId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
	test?: IToneThresholdAudiogramTestsDto;
}

export const StereoToneThresholdAudiogramTestForm = ({ patientId, trialId, confirm, cancel, test, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(trialReportsCommand_addToneThresholdBoth);
	const [step, setStep] = useState<number>(0);
	const [audiograms] = useApiEffect(audiogramStandardsQuery_withTtaByPatient, patientId);
	const bothAudiograms = useMemo(() => audiograms?.filter(t => t.hasBothAudiogram), [audiograms]);
	const [leftPtaPoints, setLeftPtaPoints] = useState<ITonePoint[]>([]);
	const [rightPtaPoints, setRightPtaPoints] = useState<ITonePoint[]>([]);

	if (audiograms === undefined || bothAudiograms === undefined) {
		return <div></div>;
	}

	if (bothAudiograms.length === 0) {
		return (
			<NotificationDialog
				open
				title={strings.insufficientInformation}
				content={strings.thereIsNoTestWithAsWelALeftAsARightTonalAudiogramCreateFirst}
				close={cancel}
				closeText={strings.understood}
			/>
		);
	}

	const handleSubmit = async (values: IStereoToneThresholdAudiogramTestModel, helpers: FormikHelpers<IStereoToneThresholdAudiogramTestModel>) => {
		const r = await create(trialId, {
			...values,
			leftPointsForPta: leftPtaPoints.map(t => t.stimulusFrequency),
			rightPointsForPta: rightPtaPoints.map(t => t.stimulusFrequency),
		});
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.addedWhat(strings.test));
			confirm();
		}
	};

	return (
		<Formik<IStereoToneThresholdAudiogramTestModel>
			validateOnMount
			initialValues={{
				...emptyValues,
				leftData: test?.left.data ?? (undefined as any),
				rightData: test?.right.data ?? (undefined as any),
				audiogramStandardId: test?.left.audiogramStandardId ?? '',
				leftPointsForPta: test?.left.pointsForPta ?? [],
				rightPointsForPta: test?.right.pointsForPta ?? [],
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					audiograms={bothAudiograms}
					leftPtaPoints={leftPtaPoints}
					setLeftPtaPoints={setLeftPtaPoints}
					rightPtaPoints={rightPtaPoints}
					setRightPtaPoints={setRightPtaPoints}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	audiograms: IAudiogramStandardWithToneThresholdAudiogramsDto[];
	leftPtaPoints: ITonePoint[];
	setLeftPtaPoints: (points: ITonePoint[]) => void;
	rightPtaPoints: ITonePoint[];
	setRightPtaPoints: (points: ITonePoint[]) => void;
}

const InnerDialog = ({
	step,
	setStep,
	cancel,
	audiograms,
	isSubmitting,
	leftPtaPoints,
	setLeftPtaPoints,
	rightPtaPoints,
	setRightPtaPoints,
	...rest
}: IInnerDialogProps) => {
	const props = useFormikContext<IStereoToneThresholdAudiogramTestModel>();
	const strings = useLocalization();
	const tta = useMemo(
		() => (isNullOrUndefined(props.values.audiogramStandardId) ? [] : audiograms.find(t => t.id === props.values.audiogramStandardId)!.toneThresholdAudiograms),
		[props.values.audiogramStandardId, audiograms]
	);
	const leftTta = useMemo(() => tta.filter(t => t.isLeft), [tta]);
	const rightTta = useMemo(() => tta.filter(t => t.isRight), [tta]);

	useEffect(() => {
		if (tta.length > 0) {
			const left = tta.filter(t => t.isLeft);
			if (left.length === 1) {
				setFormValue<IStereoToneThresholdAudiogramTestModel>('leftData', left[0], props);
			}
			const right = tta.filter(t => t.isRight);
			if (right.length === 1) {
				setFormValue<IStereoToneThresholdAudiogramTestModel>('rightData', right[0], props);
			}
		}
		// eslint-disable-next-line
	}, [tta]);

	return (
		<Dialog {...rest}>
			<DialogTitleWithFormStepper
				title='Tonale test'
				step={step}
				labels={['Audiogram', 'PTA links', 'PTA rechts']}
			/>
			<DialogContent
				className='df-col'
				dividers>
				{step === 0 && (
					<>
						<SelectAudiogramStandardToToneThresholdAudiogramsField<IStereoToneThresholdAudiogramTestModel>
							audiograms={audiograms}
							// setToneThresholdAudiograms={onSetToneThresholdAudiograms}
							type='both'
						/>
					</>
				)}
				{step === 1 && (
					<>
						<ToneThresholdAudiogramPtaComponent<IStereoToneThresholdAudiogramTestModel>
							nameData='leftData'
							selectedPoints={leftPtaPoints}
							setSelectedPoints={setLeftPtaPoints}
							toneThresholdAudiograms={leftTta}
							selectedToneThresholdAudiogram={leftTta.find(t => t === props.values.leftData)}
						/>
					</>
				)}
				{step === 2 && (
					<>
						<ToneThresholdAudiogramPtaComponent<IStereoToneThresholdAudiogramTestModel>
							nameData='rightData'
							selectedPoints={rightPtaPoints}
							setSelectedPoints={setRightPtaPoints}
							toneThresholdAudiograms={rightTta}
							selectedToneThresholdAudiogram={rightTta.find(t => t === props.values.rightData)}
						/>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
