import { Collapse } from '@material-ui/core';
import React from 'react';
import { CollapseXTextAndIcon } from './CollapseXTextAndIcon';
import { ICollapseXProps } from './ICollapseXProps';

interface IProps extends ICollapseXProps {
	isExpanded: boolean;
	toggleIsExpanded: VoidFunction;
}

export const CollapseXManaged = ({ isExpanded, toggleIsExpanded, children, collapseAfter, collapseStyle, collapsedHeight, unmountOnExit, ...rest }: IProps) => {
	return (
		<>
			{collapseAfter !== true && (
				<Collapse
					in={isExpanded}
					timeout='auto'
					unmountOnExit={unmountOnExit}
					collapsedHeight={collapsedHeight}
					style={collapseStyle}>
					{children}
				</Collapse>
			)}
			<CollapseXTextAndIcon
				isExpanded={isExpanded}
				toggleIsExpanded={toggleIsExpanded}
				{...rest}
			/>
			{collapseAfter === true && (
				<Collapse
					in={isExpanded}
					timeout='auto'
					unmountOnExit={collapsedHeight === undefined}
					collapsedHeight={collapsedHeight}
					style={collapseStyle}>
					{children}
				</Collapse>
			)}
		</>
	);
};
