import { DialogProps } from '@material-ui/core';
import React from 'react';
import { IAddressModel, IPatient, patientsCommand_updateAddress } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { AddressForm } from 'shared/address/AddressForm';

interface IProps extends DialogProps {
	patient: IPatient;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

const toModel = (patient: IPatient): IAddressModel => {
	return {
		addressLine: patient.addressLine ?? '',
		zip: patient.zip ?? '',
		city: patient.city ?? '',
		country: patient.country,
	};
};

export const PatientAddressForm = ({ patient, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<AddressForm
			cancel={cancel}
			model={toModel(patient)}
			confirm={confirm}
			submitFunction={model => patientsCommand_updateAddress(patient.id, model)}
			successMessage={strings.addressUpdated}
			{...rest}
		/>
	);
};
