import { RfiaInvoiceProcessStatus } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createInvoiceProcessStatusRecord = (strings: IStrings): Record<RfiaInvoiceProcessStatus, string> => {
	return {
		Delivered: strings.delivered,
		Invoiced: strings.invoiced,
		IsFullyPaid: strings.fullyPaid,
		NotApplicable: strings.notApplicableAbbreviation,
		Ordered: strings.ordered,
		Tendered: strings.tendered,
	};
};
