import React from 'react';
import { IPrescriptionScan } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { EmptyDocumentListItem } from 'documents/EmptyDocumentListItem';
import { PrescriptionDetailDialog } from '../prescriptionScan/PrescriptionDetailDialog';
import { PrescriptionScanDialog } from '../prescriptionScan/PrescriptionScanDialog';
import { ScanListItem } from '../prescriptionScan/ScanListItem';

interface IProps {
	rfiaId: string;
	scan: IPrescriptionScan | undefined;
	requiredStep: number;
	reload: VoidFunction;
}

export const PrescriptionScanListItem = ({ rfiaId, scan, requiredStep, reload }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);
	const strings = useLocalization();

	const onScan = () => {
		open(
			<PrescriptionScanDialog
				rfiaId={rfiaId}
				open
				confirm={confirm}
				cancel={cancel}
				suggestedStep={Math.min((scan?.step ?? 0) + 1, 4) as any}
			/>
		);
	};

	const onViewDetail = (scan: IPrescriptionScan) => {
		open(
			<PrescriptionDetailDialog
				rfiaId={rfiaId}
				scan={scan}
				open
				requiredStep={requiredStep}
				close={confirm}
			/>
		);
	};

	return (
		<>
			{scan ? (
				<ScanListItem
					scan={scan}
					requiredStep={requiredStep}
					onClick={() => onViewDetail(scan)}
					onClickScan={onScan}
				/>
			) : (
				<EmptyDocumentListItem
					title={strings.prescriptionAttachment17}
					createText='scan'
					onNew={onScan}
				/>
			)}
		</>
	);
};
