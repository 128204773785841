import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	ICreditPurchaseFromInventoryItemModel,
	inventoryItemsQuery_single,
	purchasesCommand_newCreditNoteFromInventoryItem,
	purchasesQuery_nextReference,
	purchasesQuery_settings,
	ValidUntilTimeUnits,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { SearchProvider } from '../context/SearchProvider';

const createSchema = (strings: IStrings) => {
	return yup
		.object<ICreditPurchaseFromInventoryItemModel>({
			validUntil: yup.date().required(),
			reference: yup.string().required(),
			externalReference: yup.string(),
			isAutoSelectReference: yup.boolean().defined(),
			inventoryItemId: yup.string().required(),
			unitListPriceExVat: yup.number().defined(),
			unitPriceExVat: yup.number().defined(),
			vatPercentage: yup.number().defined(),
			creditDate: yup.date().required(),
		})
		.defined();
};

const EmptyValues: ICreditPurchaseFromInventoryItemModel = {
	reference: '',
	isAutoSelectReference: true,
	validUntil: undefined as any,
	inventoryItemId: '',
	unitListPriceExVat: 0,
	unitPriceExVat: 0,
	vatPercentage: 6,
	creditDate: undefined as any,
	externalReference: '',
};

const stepsRecord: Record<number, (keyof ICreditPurchaseFromInventoryItemModel)[]> = {
	0: ['reference', 'isAutoSelectReference', 'externalReference', 'creditDate', 'validUntil'],
	1: ['unitPriceExVat'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	inventoryItemId: string;
}

export const CreditPurchaseFromInventoryItemForm = ({ confirm, cancel, inventoryItemId, ...rest }: IProps) => {
	const strings = useLocalization();
	const [step, setStep] = useState<number>(0);
	const schema = useMemo(() => createSchema(strings), [strings]);
	const [create, isSubmitting] = useFormSubmit(purchasesCommand_newCreditNoteFromInventoryItem);
	const [inventoryItem] = useApiEffect(inventoryItemsQuery_single, inventoryItemId);
	const notify = useSnackbarNotify();
	const now = useNow();

	const handleSubmit = async (values: ICreditPurchaseFromInventoryItemModel, helpers: FormikHelpers<ICreditPurchaseFromInventoryItemModel>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.registeredWhat(strings.creditNotePurchase));
			confirm(r.result.objectId);
		}
	};

	if (inventoryItem === undefined || now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<ICreditPurchaseFromInventoryItemModel>
			validateOnMount
			initialValues={{
				...EmptyValues,
				inventoryItemId: inventoryItemId,
				vatPercentage: inventoryItem.vatPercentage,
				unitPriceExVat: inventoryItem.purchasePrice,
				unitListPriceExVat: inventoryItem.purchaseListPrice,
				creditDate: now,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						step={step}
						setStep={setStep}
						stepsRecord={stepsRecord}
						schema={schema}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof ICreditPurchaseFromInventoryItemModel)[]>;
	schema: yup.ObjectSchema<ICreditPurchaseFromInventoryItemModel>;
}

export const InnerDialog = ({ cancel, isSubmitting, step, setStep, stepsRecord, schema, ...rest }: IInnerProps) => {
	const props = useFormikContext<ICreditPurchaseFromInventoryItemModel>();
	const strings = useLocalization();
	useNextReferenceInterceptor(purchasesQuery_nextReference, props);
	const [settings] = useApiEffect(purchasesQuery_settings);

	if (settings === undefined) {
		return <div></div>;
	}

	return (
		<Dialog {...rest}>
			<DialogTitleWithFormStepper
				title={strings.newWhat(strings.creditNotePurchase)}
				step={step}
				labels={[strings.reference, strings.price]}
			/>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<ICreditPurchaseFromInventoryItemModel>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<ICreditPurchaseFromInventoryItemModel>
							name='reference'
							label={strings.invoiceNumber}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormTextField<ICreditPurchaseFromInventoryItemModel>
							name='externalReference'
							label={strings.externalReference}
						/>
						<FormDatePicker<ICreditPurchaseFromInventoryItemModel>
							name='creditDate'
							label={strings.date}
							required
						/>
						<FormDatePickerWithUnit<ICreditPurchaseFromInventoryItemModel>
							name='validUntil'
							label={strings.toPayBeforeDate}
							units={ValidUntilTimeUnits}
							defaultUnit='Months'
							defaultValue={settings.defaultValidUntil}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormMoneyField<ICreditPurchaseFromInventoryItemModel>
							name='unitPriceExVat'
							label={strings.priceExVat}
						/>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
