import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		countNonDeletedProductCatalogIds: `# ${strings.rules}`,
		instrumentTypeName: strings.model,
		lastReadOutDate: strings.lastReadOutDate,
		manufacturer: strings.manufacturer,
		manufacturerCommaModelFromProductCatalogsCsv: `${strings.link} (csv)`,
		type: strings.type,
		uniqueField: strings.readOutAs,
		formattedManufacturerCommaModelFromProductCatalogs: strings.noahDeviceReadOutsLinkedToPcsHeader,
	};
};
