import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import React from 'react';
import { ActionCardButton } from 'framework/components/buttons/ActionCardButton';
import { IRequestForInsuranceAllowance, rfiasCommand_undoCancellation, rfiasCommand_undoFailure } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DefaultActionsCard } from 'shared/DefaultActionsCard';
import { RfiaCancelForm } from '../forms/RfiaCancelForm';
import { RegisterRfiaTrialForm } from '../forms/trial/RegisterRfiaTrialForm';
import SmsFailedOutlinedIcon from '@material-ui/icons/SmsFailedOutlined';
import { EndFailureTrialForm } from '../forms/trial/EndFailureTrialForm';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import UndoIcon from '@material-ui/icons/Undo';

interface IProps {
	item: IRequestForInsuranceAllowance;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const ActionsCard = ({ item, reload, style }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);
	const strings = useLocalization();

	const onNewTrial = () => {
		open(
			<RegisterRfiaTrialForm
				open
				rfia={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onCancel = () => {
		open(
			<RfiaCancelForm
				open
				rfia={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onRegisterFailure = () => {
		open(
			<EndFailureTrialForm
				open
				confirm={confirm}
				cancel={cancel}
				rfiaId={item.id}
			/>
		);
	};

	const onUndoFailure = () => {
		open(
			<AlertDialog
				open
				title={strings.undoFailedTrailShort}
				content={strings.undoFailedTrailContent}
				acceptText={strings.yes}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => rfiasCommand_undoFailure(item.id)}
				accept={confirm}
			/>
		);
	};

	const onUndoCancellation = () => {
		open(
			<AlertDialog
				open
				content={`${strings.undoCancellation}?`}
				acceptText={strings.yes}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => rfiasCommand_undoCancellation(item.id)}
				accept={confirm}
			/>
		);
	};

	return (
		<DefaultActionsCard style={style}>
			<ActionCardButton
				startIcon={<PlayCircleOutlineIcon />}
				color='primary'
				disabled={item.canStartNewTrial === false}
				onClick={onNewTrial}>
				{strings.startNewTrial}
			</ActionCardButton>
			{item.isFailedTrial === false && (
				<ActionCardButton
					startIcon={<SmsFailedOutlinedIcon />}
					color='primary'
					disabled={item.isHandedOver || item.isCancelled || item.isFailedTrial}
					onClick={onRegisterFailure}>
					{strings.registerWhat(strings.failedTrial)}
				</ActionCardButton>
			)}
			{item.isFailedTrial && (
				<ActionCardButton
					startIcon={<SettingsBackupRestoreOutlinedIcon />}
					disabled={item.isHandedOver}
					color='primary'
					onClick={onUndoFailure}>
					{strings.undoFailedTrailShort}
				</ActionCardButton>
			)}
			<ActionCardButton
				startIcon={<DeleteOutlineIcon />}
				color='secondary'
				disabled={item.isHandedOver || item.isCompleted || item.isCancelled}
				onClick={onCancel}>
				{strings.cancelWhat(strings.request)}
			</ActionCardButton>
			{item.isCancelled && (
				<ActionCardButton
					startIcon={<UndoIcon />}
					color='secondary'
					onClick={onUndoCancellation}>
					{strings.undoCancellation}
				</ActionCardButton>
			)}
		</DefaultActionsCard>
	);
};
