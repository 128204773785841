import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { deliveriesQuery_single } from 'gen/ApiClient';
import React from 'react';
import { useParams } from 'react-router-dom';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { DeliveryDetailDocumentsCard } from './DeliveryDetailDocumentsCard';
import { DeliveryDetailPageHeader } from './DeliveryDetailPageHeader';
import { DeliveryDetailPageSidebar } from './DeliveryDetailPageSidebar';
import { DeliveryDetailContentsCard } from './contents/DeliveryDetailContentsCard';

export const DeliveryDetailPage = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [trigger, reload] = useTrigger();
	const [item, reloadDetail] = useApiEffect(deliveriesQuery_single, id);

	useLazyEffect(() => {
		reloadDetail();
	}, [trigger]);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='Deliveries'
			stickyHeader={
				<DeliveryDetailPageHeader
					item={item}
					reload={reload}
				/>
			}
			stickySidebar={
				<DeliveryDetailPageSidebar
					item={item}
					reload={reload}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
					maxWidth: 1280,
				}}>
				<div className='df-row-wrap gap-16'>
					<DeliveryDetailContentsCard
						item={item}
						style={{ flexGrow: 2 }}
						className='h-fc'
					/>
					<div
						className='df-col gap-16'
						style={{ flexGrow: 1 }}>
						<DeliveryDetailDocumentsCard
							item={item}
							reload={reload}
							reloadTrigger={trigger}
						/>
					</div>
				</div>
			</div>
		</RestrictedPageX>
	);
};
