import { IOrderLineWithProductCatalogItem } from 'gen/ApiClient';
import { IRegisterOrderDeliveryLineModel } from './IRegisterOrderDeliveryLineModel';

export const mapToRegisterOrderLineModel = (pc: IOrderLineWithProductCatalogItem): IRegisterOrderDeliveryLineModel => {
	return {
		id: pc.data.id,
		isSelected: true,
		orderLine: pc.data,
		serialNumber: undefined,
		quantity: pc.data.quantity,
		productCatalogItem: pc.productCatalogItem,
		autoGenerateSerialNumber: false,
	};
};
