import { IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, useTheme } from '@material-ui/core';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined';
import { UserToggleIsPlannableDialog } from 'app/main/settings/users/forms/UserToggleIsPlannableDialog';
import { CollapseX } from 'framework/components/CollapseX';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { splitArray } from 'framework/utils/array/splitArray';
import { IUserSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { UserColorPickerWrapper } from './UserColorPickerWrapper';
import { UsersCacheContext } from 'app/main/settings/users/UsersCacheContext';
import { useLoggedInUserIsMinimalInRole } from 'app/auth/useLoggedInUserIsMinimalInRole';

export const UsersList = () => {
	const strings = useLocalization();
	const theme = useTheme();
	const [usersCache, load] = usePreloadCacheContext(UsersCacheContext);
	const { confirm, cancel, open } = useDialogsContext(load);
	const orderedUsers = useMemo(() => _.orderBy(usersCache?.users, t => t.identifier), [usersCache]);
	const [first3, other] = useMemo(() => splitArray(orderedUsers, 3), [orderedUsers]);
	const canEdit = useLoggedInUserIsMinimalInRole('Admin');

	const onToggleIsPlannable = (user: IUserSummary) => {
		if (canEdit) {
			open(
				<UserToggleIsPlannableDialog
					open
					user={user}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	return (
		<>
			{usersCache && (
				<div style={{ marginBottom: theme.spacing(4) }}>
					<DividerWithCaption
						caption={strings.users}
						captionStyle={{ fontWeight: 500, color: 'black' }}
					/>
					<List
						dense
						disablePadding>
						{first3.map(t => (
							<SingleItem
								key={t.id}
								user={t}
								reload={load}
								onToggleIsPlannable={() => onToggleIsPlannable(t)}
							/>
						))}
						{other.length > 0 && (
							<CollapseX style={{ marginLeft: 10 }}>
								{other.map(t => (
									<SingleItem
										key={t.id}
										user={t}
										reload={load}
										onToggleIsPlannable={() => onToggleIsPlannable(t)}
									/>
								))}
							</CollapseX>
						)}
					</List>
				</div>
			)}
		</>
	);
};

interface ISingleItemProps {
	user: IUserSummary;
	reload: VoidFunction;
	onToggleIsPlannable: VoidFunction;
}

const SingleItem = ({ user, reload, onToggleIsPlannable }: ISingleItemProps) => {
	const strings = useLocalization();

	return (
		<ListItem
			dense
			disableGutters>
			<ListItemIcon
				style={{ paddingLeft: '11px' }}
				className='df-row-ac'>
				<UserColorPickerWrapper
					user={user}
					reload={reload}
				/>
			</ListItemIcon>
			<ListItemText
				primary={user.identifier}
				secondary={`${user.lastNameCommaFirstName} (${user.isPlannable ? strings.plannable : strings.notPlannable})`}
			/>
			<ListItemSecondaryAction onClick={onToggleIsPlannable}>
				{user.isPlannable === false && (
					<IconButton
						edge='end'
						color='primary'>
						<EventAvailableOutlinedIcon />
					</IconButton>
				)}
				{user.isPlannable === true && (
					<IconButton
						edge='end'
						color='secondary'>
						<EventBusyOutlinedIcon />
					</IconButton>
				)}
			</ListItemSecondaryAction>
		</ListItem>
	);
};
