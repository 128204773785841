import { FormikHelpers } from 'formik';
import { ICreateResponse, IUpdateResponse } from 'gen/ApiClient';
import { Try } from '../../Try';
import { findFieldErrorMinStep } from './findFieldErrorMinStep';
import { setLineErrors } from './setLineErrors';

// export function handleFormResponseWithLines<TModel, TX extends ICommonValidation<TModel>>(r: Try<TX>, helpers: FormikHelpers<TModel>): boolean;
// export function handleFormResponseWithLines<TModel, TX extends ICommonValidation<TModel>>(r: Try<TX>, helpers: FormikHelpers<TModel>, stepsRecord: Record<number, (keyof TModel)[]>, setStep: React.Dispatch<React.SetStateAction<number>>): boolean;

export function handleFormResponseWithLines<TModel, TLine extends { error?: string }>(
	r: Try<ICreateResponse<TModel>> | Try<IUpdateResponse<TModel>>,
	helpers: FormikHelpers<TModel>,
	stepsRecord: Record<number, (keyof TModel)[]>,
	setStep: React.Dispatch<React.SetStateAction<number>>,
	linesKey: keyof TModel,
	lines: TLine[],
	setLines: React.Dispatch<React.SetStateAction<TLine[]>>
): boolean {
	if (r.isSuccess) {
		if (r.result.hasError) {
			r.result.errors.forEach(t => helpers.setFieldError(t.property, t.error));
			if (stepsRecord && setStep) {
				setStep(
					findFieldErrorMinStep(
						r.result.errors.map(e => e.property),
						stepsRecord
					)
				);
			}
			setLineErrors(linesKey, r, lines, setLines);
			return false;
		} else {
			return true;
		}
	} else {
		return false;
	}
}

// export function handleFormResponse<TModel>(r: Try<ICreateResponse<TModel>> | Try<IUpdateResponse<TModel>> | Try<IConfirmUserResponse>, helpers: FormikHelpers<TModel>): boolean;
// export function handleFormResponse<TModel>(r: Try<ICreateResponse<TModel>> | Try<IUpdateResponse<TModel>> | Try<IConfirmUserResponse>, helpers: FormikHelpers<TModel>, stepsRecord: Record<number, (keyof TModel)[]>, setStep: React.Dispatch<React.SetStateAction<number>>): boolean;

// export function handleFormResponse<TModel>(r: Try<ICreateResponse<TModel>> | Try<IUpdateResponse<TModel>> | Try<IConfirmUserResponse>, helpers: FormikHelpers<TModel>, stepsRecord?: Record<number, (keyof TModel)[]>, setStep?: React.Dispatch<React.SetStateAction<number>>): boolean {
//     if (r.isSuccess) {
//         if (r.result.hasError) {
//             r.result.errors.forEach(t => helpers.setFieldError(t.property, t.error));
//             if (stepsRecord && setStep) {
//                 setStep(findFieldErrorMinStep(r.result.errors.map(e => e.property), stepsRecord))
//             }
//             return false;
//         } else {
//             return true;
//         }
//     } else {
//         return false;
//     }
// }
