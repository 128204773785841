import { Dialog, DialogContent, DialogProps, useTheme } from '@material-ui/core';
import { calcPurchaseDetailRoute } from 'app/main/financial/routes';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { QuantityField } from 'framework/components/textFields/QuantityField';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setLineErrors } from 'framework/forms/utils/setLineErrors';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { createHeader } from 'framework/table/createHeader';
import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { updateArray } from 'framework/utils/array/updateArray';
import { IPurchase, IPurchaseCreditNoteModel, purchasesCommand_newCreditNote, purchasesQuery_nextReference, purchasesQuery_single } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import * as yup from 'yup';
import { ILine } from './ILine';
import { lineToModel } from './utils/lineToModel';
import { purchaseLineToLine } from './utils/purchaseLineToLine';

const createSchema = (strings: IStrings): yup.ObjectSchema<IPurchaseCreditNoteModel> => {
	return yup
		.object<IPurchaseCreditNoteModel>({
			purchaseId: yup.string().required(),
			reference: yup.string().required(strings.formRequired(strings.reference)),
			isAutoSelectReference: yup.boolean().defined(),
			lines: yup.mixed(),
		})
		.defined();
};

const emptyValues: IPurchaseCreditNoteModel = {
	purchaseId: '',
	isAutoSelectReference: true,
	lines: [],
	reference: '',
};

const stepsRecord: Record<number, (keyof IPurchaseCreditNoteModel)[]> = {
	0: ['isAutoSelectReference', 'reference'],
	1: ['lines'],
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	purchaseId: string;
	stopNavigation?: boolean;
}

export const PurchaseCreditNoteForm = ({ confirm, cancel, purchaseId, stopNavigation = false, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(purchasesCommand_newCreditNote);
	const [step, setStep] = useState<number>(0);
	const { push } = useHistory();
	const [purchase] = useApiEffect(purchasesQuery_single, purchaseId);
	const [lines, setLines] = useState<ILine[]>([]);
	const schema = useMemo(() => createSchema(strings), [strings]);

	useEffect(() => {
		if (purchase) {
			setLines(purchase.lines.filter(t => t.quantity - t.creditedQuantity > 0).map<ILine>(t => purchaseLineToLine(t)));
		}
		// TODO set all lines
	}, [purchase]);

	const handleSubmit = async (values: IPurchaseCreditNoteModel, helpers: FormikHelpers<IPurchaseCreditNoteModel>) => {
		// TODO validate here
		if (lines.length === 0 || lines.filter(t => t.isSelected).length === 0) {
			setStep(2);
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await create({ ...values, lines: lines.filter(t => t.isSelected).map((t, index) => lineToModel(t, index)) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify('Kredietnota aangemaakt');
				if (stopNavigation === false) {
					push(calcPurchaseDetailRoute(r.result.objectId));
				}
				confirm();
			} else {
				setLineErrors('lines', r, lines, setLines);
			}
		}
	};

	if (purchase === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IPurchaseCreditNoteModel>
			validateOnMount
			initialValues={{ ...emptyValues, purchaseId: purchaseId }}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					open
					step={step}
					setStep={setStep}
					schema={schema}
					lines={lines}
					setLines={setLines}
					cancel={cancel}
					isSubmitting={isSubmitting}
					purchase={purchase}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	schema: yup.ObjectSchema<IPurchaseCreditNoteModel>;
	lines: ILine[];
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	purchase: IPurchase;
}

const InnerDialog = ({ step, setStep, schema, lines, setLines, cancel, isSubmitting, purchase, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const props = useFormikContext<IPurchaseCreditNoteModel>();
	useNextReferenceInterceptor(purchasesQuery_nextReference, props);

	const headers = [
		createSelectionHeader<ILine>(lines, setLines),
		createHeader<ILine>(strings.name, t => <div className='fg1'>{t.purchaseLine.description}</div>),
		createHeader<ILine>(
			strings.amount,
			t => (
				<QuantityField
					value={t.quantity}
					setValue={val => setLines(updateArray(t, lines, { ...t, quantity: val }))}
					max={t.purchaseLine.quantity - t.purchaseLine.creditedQuantity}
					disabled={t.purchaseLine.isInventoryItem}
				/>
			),
			'center'
		),
	];

	return (
		<Dialog
			{...rest}
			scroll='paper'>
			<DialogTitleWithFormStepper
				title={`Creëer kredietnota van aankoop ${purchase.reference}`}
				step={step}
				labels={['Referentie', strings.items]}
			/>
			<DialogContent
				className='df-col'
				dividers
				style={{ padding: step === 1 ? '0px' : '8px 24px' }}>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IPurchaseCreditNoteModel>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IPurchaseCreditNoteModel>
							name='reference'
							label='Nummer kredietnota'
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<TableWithHeadersAndValues<ILine>
							padding={theme.spacing(1)}
							headers={headers}
							getKey={t => t.purchaseLine.id}
							values={lines}
							expandableContent={t => <CreditNoteWarningOrErrorContent line={t} />}
							expandAll
						/>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};

const CreditNoteWarningOrErrorContent = ({ line }: { line: ILine }): JSX.Element => {
	return (
		<div
			className='df-col'
			style={{ marginLeft: '10px', marginTop: '-6px' }}>
			{line.quantity > line.purchaseLine.quantity - line.purchaseLine.creditedQuantity && (
				<ErrorLabelledProperty error={`Max ${line.purchaseLine.quantity - line.purchaseLine.creditedQuantity}`} />
			)}
		</div>
	);
};
