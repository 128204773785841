import { Divider } from '@material-ui/core';
import React from 'react';

export const WizardDivider = () => {
	return (
		<Divider
			style={{ marginTop: 4, marginBottom: 4 }}
			light
		/>
	);
};
