import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { useLocalization } from 'localization/useLocalization';
import { Mode } from './Mode';

interface IProps extends DialogProps {
	confirm: (mode: Mode) => void;
	cancel: VoidFunction;
}

export const RegisterPaymentForSupplierModeForm = ({ confirm, cancel, ...rest }: IProps) => {
	const [mode, setMode] = useState<Mode>('group');
	const strings = useLocalization();

	return (
		<Dialog
			{...rest}
			scroll='paper'>
			<DialogTitle>{strings.makeAChoice}</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<Mode>
					selected={mode}
					setSelected={setMode}
					options={[
						{ value: 'single', label: strings.onePurchase, caption: strings.paymentOnePurchaseCaption },
						{ value: 'group', label: strings.multiplePurchases, caption: strings.paymentMultiplePurchasesCaption },
					]}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button disabled>{strings.previous}</Button>
				<Button
					onClick={() => confirm(mode)}
					color='primary'>
					{strings.confirmChoice}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
