import { Step, StepProps } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import React from 'react';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { StepLabelWithWarning } from 'framework/components/StepLabelWithWarning';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { formatDate } from 'framework/utils/date/formatDate';
import {
	IFeedbackAdvisoryPhysicianStep,
	IRequestForInsuranceAllowance,
	rfiasCommand_deleteApprovalFeedbackAdvisoryPhysician,
	rfiasCommand_updateExpectedReceptionDateForAdvisoryPhysician,
	rfiasCommand_updateFeedbackAdvisoryPhysicianApprovalDate,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { FeedbackAdvisoryPhysicianStepContent } from './FeedbackAdvisoryPhysicianStepContent';

interface IProps extends StepProps {
	rfia: IRequestForInsuranceAllowance;
	step: IFeedbackAdvisoryPhysicianStep;
	reload: VoidFunction;
}

export const FeedbackAdvisoryPhysicianStep = ({ reload, rfia, step, ...rest }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onEditPrescriptionDate = () => {
		open(
			<DateModelForm
				open
				disableFuture
				confirm={confirm}
				cancel={cancel}
				label={strings.date}
				formTitle={strings.changeWhat(strings.dateApprovalAdvisoryPhysician)}
				initialValue={step.approvalDate}
				submitFunction={date => rfiasCommand_updateFeedbackAdvisoryPhysicianApprovalDate(rfia.id, step.id, date)}
			/>
		);
	};

	const onEditExpectedReceptionDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				label={strings.expectedWhat(strings.receptionDateOfDocuments)}
				formTitle={strings.changeWhat(strings.expectedWhat(strings.receptionDateOfDocuments))}
				initialValue={step.expectedReceptionDate}
				submitFunction={date => rfiasCommand_updateExpectedReceptionDateForAdvisoryPhysician(rfia.id, step.id, date)}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.approvalAdvisoryPhysician)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => rfiasCommand_deleteApprovalFeedbackAdvisoryPhysician(rfia.id, step.id)}
				accept={confirm}
			/>
		);
	};

	return (
		<Step
			{...rest}
			completed={step.hasReceived}
			active={step.hasReceived === false && rfia.canReceiveFeedbackAdvisoryPhysician}>
			<StepLabelWithWarning warning={step.isDisapproved}>
				<div className='df-col'>
					<div className='df-row-ac'>
						<div>{step.isDisapproved ? `${strings.advisoryPhysician} (${strings.disApproved})` : strings.approvalAdvisoryPhysicianReceived}</div>
					</div>
					{step.hasSent && (
						<LabelledProperty
							label={strings.dateOfDispatch}
							property={formatDate(step.sentDate)}
							withoutMargin
							variant='caption'
						/>
					)}
					{step.hasSent && (
						<LabelledProperty
							label={strings.expectedWhat(strings.receptionDateOfDocuments)}
							property={formatDate(step.expectedReceptionDate)}
							edit={onEditExpectedReceptionDate}
							withoutMargin
							variant='caption'
						/>
					)}
					{step.hasReceived && step.isApproved === false && (
						<LabelledProperty
							label={strings.receptionDate}
							property={formatDate(step.receptionDate)}
							withoutMargin
							variant='caption'
						/>
					)}
					{step.isApproved && (
						<LabelledProperty
							label={strings.approvalDate}
							property={formatDate(step.approvalDate)}
							edit={onEditPrescriptionDate}
							withoutMargin
							variant='caption'
							secondaryAction={rfia.isHandedOver === false && rfia.isCompleted === false ? onDelete : undefined}
							secondaryActionIcon={<DeleteOutlineIcon />}
						/>
					)}
					{step.isDisapproved && (
						<LabelledProperty
							property={strings.disApproved}
							withoutMargin
							variant='caption'
						/>
					)}
				</div>
			</StepLabelWithWarning>
			<FeedbackAdvisoryPhysicianStepContent
				rfia={rfia}
				step={step}
				reload={reload}
			/>
		</Step>
	);
};
