import { Typography, useTheme } from '@material-ui/core';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { formatDate } from 'framework/utils/date/formatDate';
import { IPatientDetail } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import React from 'react';
import DeleteSweepOutlinedIcon from '@material-ui/icons/DeleteSweepOutlined';

interface IProps {
	item: IPatientDetail;
	onRead: VoidFunction;
	onRemoveEidData: VoidFunction;
}

export const PatientDetailEidComponent = ({ item, onRead, onRemoveEidData }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const popupState = usePopupState({
		variant: 'popover',
		popupId: 'demoPopover',
	});

	return (
		<>
			<div
				className='df-row-ac jc-c'
				{...bindHover(popupState)}>
				<Typography
					variant='caption'
					style={{ marginRight: 4, textAlign: 'center' }}>
					{item.patient.hasEidData ? strings.dataReadViaEID : strings.dataNotReadViaeID}
				</Typography>
				{item.hasEidDataAndIsValid && <VerifiedUserOutlinedIcon style={{ color: theme.palette.success.main, fontSize: 16 }} />}
				{item.patient.hasEidData === false && (
					<StatusIcon
						status='warning'
						style={{ fontSize: 16 }}
					/>
				)}
				{item.patient.hasEidData && item.hasEidDataAndIsValid === false && (
					<StatusIcon
						status='error'
						style={{ fontSize: 16 }}
					/>
				)}
			</div>
			<HoverPopover
				elevation={1}
				{...bindPopover(popupState)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}>
				<div
					className='df-col gap-4'
					style={{ padding: 16 }}>
					{item.patient.hasEidData === false && <Typography variant='caption'>{strings.noDataReadViaEIDYet}</Typography>}
					{item.patient.hasEidData && (
						<>
							<Typography variant='caption'>{`${strings.readOn}: ${formatDate(item.patient.eidDataLastRead)}`}</Typography>
							<Typography variant='caption'>{`${strings.validFrom}: ${formatDate(item.patient.eidData.validityBeginDate)}`}</Typography>
							<Typography
								variant='caption'
								color={item.hasEidDataAndIsValid === false ? 'error' : 'initial'}>
								{`${strings.validTo}: ${formatDate(item.patient.eidData.validityEndDate)}`}
							</Typography>
						</>
					)}
					<TextLinkButton
						startIcon={<ContactMailOutlinedIcon />}
						onClick={onRead}
						color='primary'
						hoverColor={item.hasEidDataAndIsValid === false ? 'error' : 'primary'}>
						{item.patient.hasEidData ? strings.reReadDataFromEIDCard : strings.readEIDData}
					</TextLinkButton>
					<TextLinkButton
						startIcon={<DeleteSweepOutlinedIcon />}
						onClick={onRemoveEidData}
						hoverColor='warning'
						color='warning'
						disabled={item.patient.hasEidData === false}>
						{strings.removeEidData}
					</TextLinkButton>
				</div>
			</HoverPopover>
		</>
	);
};
