import { Color } from '@material-ui/lab';
import { ChipX } from 'framework/components/chips/ChipX';
import { InventoryStatus } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

const colorRecord: Record<InventoryStatus, Color> = {
	InRepair: 'error',
	InStock: 'success',
	InTestOrLoan: 'warning',
	NotInventoried: 'info',
	Ordered: 'info',
	ReturnedToSupplier: 'info',
	Sold: 'success',
	Broken: 'error',
};

const fillRecord: Record<InventoryStatus, boolean> = {
	InRepair: true,
	InStock: false,
	InTestOrLoan: false,
	NotInventoried: false,
	Ordered: false,
	ReturnedToSupplier: true,
	Sold: true,
	Broken: false,
};

interface IProps {
	status: InventoryStatus;
	style?: React.CSSProperties;
}

export const InventoryItemStatusChipX = ({ status, style }: IProps) => {
	const { inventoryStatusRecord } = useContext(RecordContext);

	return (
		<ChipX
			style={style}
			label={inventoryStatusRecord[status as InventoryStatus]}
			fill={fillRecord[status as InventoryStatus]}
			color={colorRecord[status as InventoryStatus]}
		/>
	);
};
