import { Avatar, Chip, PropTypes } from '@material-ui/core';
import React, { useMemo } from 'react';

interface IProps {
	label: string;
	disableAvatar?: boolean;
	style?: React.CSSProperties;
	color?: Exclude<PropTypes.Color, 'inherit'>;
	size?: 'small' | 'medium';
	variant?: 'default' | 'outlined';
}

export const ChipWithAvatar = ({ label, style, color = 'primary', size = 'small', variant = 'default', disableAvatar = false }: IProps) => {
	const isEmptyLabel = useMemo(() => label === undefined || label === null || label.length === 0, [label]);

	return (
		<Chip
			color={color}
			label={label}
			variant={variant}
			avatar={disableAvatar === false && isEmptyLabel === false ? <Avatar>{label[0].toUpperCase()}</Avatar> : undefined}
			style={{ margin: '2px', ...style }}
			size={size}
		/>
	);
};
