import { DialogProps } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { ImageScannerApplicationContext } from 'app/externalApps/imageScanner/ImageScannerApplicationContext';
import { DownloadAndInstallScanSoftwareDialog } from '../../downloads/DownloadAndInstallScanSoftwareDialog';
import { EmptyDialog } from '../../EmptyDialog';
import { InstalledDialog } from './InstalledDialog';

interface IProps extends DialogProps {
	title: string;
	cancel: VoidFunction;
	confirm: (contentAsBase64: string) => void;
	previous?: VoidFunction;
	isSubmitting: boolean;
}

export const ScanFileDialog = ({ title, ...props }: IProps) => {
	const { isInstalled, isInitialized, check, hasLatest } = useContext(ImageScannerApplicationContext);

	useEffect(() => {
		check();
	}, [check]);

	if (isInitialized === false) {
		return (
			<EmptyDialog
				open
				title={title}
			/>
		);
	} else if (isInstalled === true && hasLatest === true) {
		return (
			<InstalledDialog
				title={title}
				{...props}
			/>
		);
	} else {
		return (
			<DownloadAndInstallScanSoftwareDialog
				open
				close={check}
			/>
		);
	}
};
