import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IPurchaseUblBuffer, IPurchasesUblBufferQueryParams, IQueryResult, purchasesUblQuery_concurrencyToken, purchasesUblQuery_query } from 'gen/ApiClient';
import React from 'react';
import { purchasesUblQueryParamsLsKey } from '../../../../localStorageKeys';
import { PurchasesUblBufferQueryContext } from './PurchasesUblBufferQueryContext';

const DefaultParams: IPurchasesUblBufferQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'dateUploaded' },
	pageParams: { index: 1, size: 10 },
	uploadedAfter: undefined,
	uploadedBefore: undefined,
};

export const PurchasesUblBufferQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IPurchaseUblBuffer>, IPurchasesUblBufferQueryParams>
			defaultParams={DefaultParams}
			queryF={purchasesUblQuery_query}
			concurrencyF={purchasesUblQuery_concurrencyToken}
			localStorageKey={purchasesUblQueryParamsLsKey}
			context={PurchasesUblBufferQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
