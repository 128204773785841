import { DialogProps } from '@material-ui/core';
import React, { useState } from 'react';
import { IFile } from 'framework/utils/IFile';
import { ScanFileDialog } from '../scan/ScanFileDialog';
import { FileType } from '../upload/FileType';
import { UploadFileDialog } from '../upload/UploadFileDialog';
import { SelectScanOrUploadDialog } from './SelectScanOrUploadDialog';
import { WithSignatureDialog } from './WithSignatureDialog';

interface IProps extends DialogProps {
	title: string;
	confirm: (file: IFile, withSignature?: boolean) => void;
	cancel: VoidFunction;
	withSignature?: boolean;
	isUploading: boolean;
	accept: FileType;
}

type Mode = 'select' | 'scan' | 'upload' | 'signature';

export const ScanOrUploadFileDialog = ({ title, confirm, cancel, withSignature = false, isUploading, ...rest }: IProps) => {
	const [mode, setMode] = useState<Mode>('select');
	const [file, setFile] = useState<IFile>();

	const onUpload = (file: IFile) => {
		if (withSignature) {
			setFile(file);
			setMode('signature');
		} else {
			confirm(file);
		}
	};

	const onScan = (content: string) => {
		const file: IFile = { contentAsBase64: content, fileName: 'scan.pdf' };
		if (withSignature) {
			setFile(file);
			setMode('signature');
		} else {
			confirm(file);
		}
	};

	if (mode === 'select') {
		return (
			<SelectScanOrUploadDialog
				{...rest}
				open
				cancel={cancel}
				confirm={m => setMode(m)}
				title={title}
			/>
		);
	} else if (mode === 'scan') {
		return (
			<ScanFileDialog
				{...rest}
				open
				cancel={cancel}
				previous={() => setMode('select')}
				confirm={onScan}
				isSubmitting={isUploading}
				title={title}
			/>
		);
	} else if (mode === 'upload') {
		return (
			<UploadFileDialog
				{...rest}
				open
				cancel={cancel}
				previous={() => setMode('upload')}
				confirm={onUpload}
				title={title}
				isUploading={isUploading}
				accept='scanOrUpload'
			/>
		);
	} else if (file !== undefined && mode === 'signature') {
		return (
			<WithSignatureDialog
				{...rest}
				open
				confirm={ws => confirm(file!, ws)}
				cancel={cancel}
				isSubmitting={isUploading}
				title={title}
			/>
		);
	} else {
		return <div></div>;
	}
};
