import { Form, Formik, FormikHelpers } from 'formik';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { FormTextField } from 'framework/forms/FormTextField';
import { setFieldError } from 'framework/forms/utils/setFieldError';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { auth_loginWithToken, auth_logout, auth_sendLoginToken, ILoginWithTokenRequest } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { AuthContext } from './AuthContext';
import { EmptyPageWithLogo } from './EmptyPageWithLogo';
import { useHandleLogin } from './useHandleLogin';

const createSchema = (strings: IStrings) => {
	return yup.object<ILoginWithTokenRequest>({
		email: yup.string().email().required(strings.formRequired(strings.email)),
		token: yup.string().required(strings.formRequired(strings.securityToken)),
	});
};

export const LoginWithToken = () => {
	const strings = useLocalization();
	const authContext = useContext(AuthContext);
	const [login, isLoggingIn] = useFormSubmit(auth_loginWithToken);
	const [logout, isLoggingOut] = useFormSubmit(auth_logout);
	const [request, isRequesting] = useFormSubmit(auth_sendLoginToken);
	const isSubmitting = useMemo(() => isLoggingIn || isLoggingOut || isRequesting, [isLoggingOut, isLoggingIn, isRequesting]);
	const handleLogin = useHandleLogin();
	const [hasRequested, setHasRequested] = useState<boolean>(false);
	const notify = useSnackbarNotify();

	useEffect(() => {
		const x = () => {
			logout();
			authContext.logout();
		};
		x();
		// only happens at mount of this screen
		// eslint-disable-next-line
	}, []);

	const onRequest = async (email: string) => {
		const r = await request({ email: email });
		if (r.isSuccess) {
			if (r.result.hasError) {
				notify(r.result.error, 'error');
				setHasRequested(false);
			} else {
				notify('E-mail with token sent', 'success');
				setHasRequested(true);
			}
		}
	};

	const handleSubmit = async (values: ILoginWithTokenRequest, helpers: FormikHelpers<ILoginWithTokenRequest>) => {
		const r = await login(values);
		if (r.isSuccess) {
			if (r.result.hasError) {
				setFieldError<ILoginWithTokenRequest>('token', r.result.error!, helpers);
				notify(r.result.error!, 'error');
			} else {
				handleLogin(r.result);
			}
		}
	};

	return (
		<EmptyPageWithLogo title={strings.loginHeaderText}>
			<Formik<ILoginWithTokenRequest>
				validateOnMount
				initialValues={{ email: '', token: '' }}
				validationSchema={createSchema(strings)}
				onSubmit={handleSubmit}>
				{props => (
					<Form
						autoComplete='off'
						className='df-col'
						style={{ minWidth: '400px' }}>
						<FormTextField<ILoginWithTokenRequest>
							name='email'
							label={strings.email}
							disabled={hasRequested}
							required
						/>
						{hasRequested === false && (
							<LoaderButton
								style={{ marginTop: '20px' }}
								size='large'
								variant='contained'
								color='primary'
								isLoading={isRequesting}
								disabled={isNullOrUndefined(props.values.email)}
								onClick={() => onRequest(props.values.email!)}>
								{strings.login}
							</LoaderButton>
						)}
						{hasRequested && (
							<>
								<FormTextField<ILoginWithTokenRequest>
									name='token'
									label={strings.securityToken}
									required
								/>
								<LoaderButton
									style={{ marginTop: '20px' }}
									size='large'
									variant='contained'
									color='primary'
									type='submit'
									isLoading={isSubmitting}
									disabled={props.isValid === false}>
									{strings.login}
								</LoaderButton>
							</>
						)}
					</Form>
				)}
			</Formik>
		</EmptyPageWithLogo>
	);
};
