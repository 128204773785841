import { IAddress, IAddressModel } from 'gen/ApiClient';

export const toModel = (address: IAddress | undefined | null): IAddressModel | undefined => {
	if (address === undefined || address === null) {
		return undefined;
	} else {
		return {
			addressLine: address.addressLine ?? '',
			city: address.city ?? '',
			country: address.country ?? '',
			zip: address.zip ?? '',
		};
	}
};
