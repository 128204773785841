import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { ILabelledPropertyProps, LabelledProperty } from './LabelledProperty';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { NotificationDialog } from '../../dialogs/NotificationDialog';

interface IProps extends ILabelledPropertyProps {
	hasAccess: boolean;
}

export const NoAccessToThisFunctionLabelledProperty = ({ hasAccess, ...rest }: IProps) => {
	const strings = useLocalization();
	const { open, cancel } = useDialogsContext();

	const onViewError = () => {
		open(
			<NotificationDialog
				open
				title={strings.restrictions}
				content={strings.noAccessToThisFunction}
				close={cancel}
			/>
		);
	};

	return (
		<LabelledProperty
			{...rest}
			property={hasAccess ? rest.property : strings.notApplicableAbbreviation}
			edit={hasAccess ? rest.edit : undefined}
			secondaryAction={hasAccess ? rest.secondaryAction : onViewError}
			secondaryActionIcon={hasAccess ? rest.secondaryActionIcon : <InfoOutlinedIcon />}
		/>
	);
};
