import { IconButton, Typography, useTheme } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import React from 'react';
import { useMemo } from 'react';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import {
	annotationsCommand_deleteAttachment,
	annotationsCommand_restoreAttachment,
	annotationsQuery_downloadAttachment,
	IAnnotation,
	IAnnotationAttachment,
} from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from '../dialogs/useDialogsContext';
import RestoreIcon from '@material-ui/icons/Restore';

interface IProps {
	annotation: IAnnotation;
	attachment: IAnnotationAttachment;
	reload: VoidFunction;
}

export const SingleAnnotationAttachment = ({ annotation, attachment, reload }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [hover, enter, leave] = useStateBoolean(false);
	const [download] = useDownload(annotationsQuery_downloadAttachment);
	const canDelete = useMemo(() => annotation.isDeleted === false && attachment.isDeleted === false, [annotation, attachment]);
	const canRestore = useMemo(() => annotation.isDeleted === false && attachment.isDeleted === true, [annotation, attachment]);
	const isLineThrough = useMemo(() => annotation.isDeleted === true || attachment.isDeleted === true, [annotation, attachment]);

	const onDeleteAttachment = (id: string, attachmentId: string) => {
		open(
			<AlertDialog
				maxWidth='xs'
				open
				content={strings.deleteWhatQuestion(strings.attachment)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => annotationsCommand_deleteAttachment(id, attachmentId)}
				accept={confirm}
			/>
		);
	};

	const onRestoreAttachment = (id: string, attachmentId: string) => {
		open(
			<AlertDialog
				maxWidth='xs'
				open
				content={strings.undoDeleteAttachmentQuestion}
				acceptText={strings.yes}
				rejectText={strings.no}
				reject={cancel}
				acceptF={() => annotationsCommand_restoreAttachment(id, attachmentId)}
				accept={confirm}
			/>
		);
	};

	return (
		<div
			className='df-row-ac'
			onMouseEnter={enter}
			onMouseLeave={leave}>
			<div
				className='df-row-ac'
				style={{ cursor: 'pointer' }}
				onClick={() => download(annotation.id, attachment.attachmentId)}>
				<AttachmentIcon
					fontSize='small'
					style={{ color: theme.palette.text.secondary }}
				/>
				<Typography
					color='textSecondary'
					variant='subtitle2'
					style={{
						marginLeft: theme.spacing(1),
						marginRight: theme.spacing(1),
						textDecoration: isLineThrough ? 'line-through' : 'inherit',
					}}>
					{attachment.fileName}
				</Typography>
			</div>
			{canDelete && (
				<IconButton
					size='small'
					style={{ visibility: hover ? 'visible' : 'collapse' }}
					onClick={() => onDeleteAttachment(annotation.id, attachment.attachmentId)}>
					<DeleteOutlineIcon fontSize='small' />
				</IconButton>
			)}
			{canRestore && (
				<IconButton
					size='small'
					style={{ visibility: hover ? 'visible' : 'collapse' }}
					onClick={() => onRestoreAttachment(annotation.id, attachment.attachmentId)}>
					<RestoreIcon fontSize='small' />
				</IconButton>
			)}
		</div>
	);
};
