import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IDailyReceipt, SalesType } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { boolToYesNo } from 'shared/utils/boolToString';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (
	colRecord: Record<SelectableColumn, string>,
	typeRecord: Record<SalesType, string>,
	strings: IStrings
): Record<SelectableColumn, IHeader<IDailyReceipt>> => {
	return {
		locationIdentifier: createSortableHeaderWithKey<IDailyReceipt, SelectableColumn>(colRecord, 'locationIdentifier', t => t.locationIdentifier),
		priceInVatAt0: createSortableHeaderWithKey<IDailyReceipt, SelectableColumn>(colRecord, 'priceInVatAt0', t => formatCurrency(t.priceInVatAt0)),
		priceInVatAt6: createSortableHeaderWithKey<IDailyReceipt, SelectableColumn>(colRecord, 'priceInVatAt6', t => formatCurrency(t.priceInVatAt6)),
		priceInVatAt12: createSortableHeaderWithKey<IDailyReceipt, SelectableColumn>(colRecord, 'priceInVatAt12', t => formatCurrency(t.priceInVatAt12)),
		priceInVatAt21: createSortableHeaderWithKey<IDailyReceipt, SelectableColumn>(colRecord, 'priceInVatAt21', t => formatCurrency(t.priceInVatAt21)),
		priceExVat: createSortableHeaderWithKey<IDailyReceipt, SelectableColumn>(colRecord, 'priceExVat', t => formatCurrency(t.priceExVat)),
		priceInVat: createSortableHeaderWithKey<IDailyReceipt, SelectableColumn>(colRecord, 'priceInVat', t => formatCurrency(t.priceInVat)),
		vatAmount: createSortableHeaderWithKey<IDailyReceipt, SelectableColumn>(colRecord, 'vatAmount', t => formatCurrency(t.vatAmount)),
		isGlobal: createSortableHeaderWithKey<IDailyReceipt, SelectableColumn>(colRecord, 'isGlobal', t => boolToYesNo(t.isGlobal, strings)),
		onDate: createSortableHeaderWithKey<IDailyReceipt, SelectableColumn>(colRecord, 'onDate', t => formatDate(t.onDate)),
	};
};
