import { DialogContent, DialogProps } from '@material-ui/core';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { FormSelectUserField } from 'app/main/settings/users/forms/FormSelectUserField';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	articlesInquiriesCommand_register,
	articlesInquiriesQuery_nextReference,
	IObjectWithIdAndIdentifier,
	IRegisterArticlesInquiryRequest,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { IArticlesInquiryLineModel } from './IArticlesInquiryLineModel';
import { mapArticlesInquiryLineModelToRequest } from './mapArticlesInquiryLineModelToRequest';
import { SearchAndSelectProductsForArticlesInquiry } from './SearchAndSelectProductsForArticlesInquiry';
import { SearchProductCatalogItemsForArticlesInquiryProvider } from './SearchProductCatalogItemsForArticlesInquiryProvider';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterArticlesInquiryRequest>({
			lines: yup.mixed(),
			reference: yup.string().required(strings.formRequired(strings.reference)),
			isAutoSelectReference: yup.boolean().defined(),
			handlingUserId: yup.string().required(),
			patientId: yup.string().required(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IRegisterArticlesInquiryRequest)[]> = {
	0: ['reference', 'isAutoSelectReference'],
	1: ['handlingUserId'],
	2: ['lines'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	patient: IObjectWithIdAndIdentifier<string>;
}

export const RegisterArticlesInquiryForm = ({ confirm, cancel, patient, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, setLines] = useState<IArticlesInquiryLineModel[]>([]);
	const [step, setStep] = useState<number>(0);
	const [register, isSubmitting] = useFormSubmit(articlesInquiriesCommand_register);
	const user = useLoggedInUser();
	const notify = useSnackbarNotify();
	const now = useNow();

	const handleSubmit = async (values: IRegisterArticlesInquiryRequest, helpers: FormikHelpers<IRegisterArticlesInquiryRequest>) => {
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await register({ ...values, lines: lines.map(mapArticlesInquiryLineModelToRequest) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify(strings.orderPlaced);
				confirm(r.result.objectId);
			}
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterArticlesInquiryRequest>
			validateOnMount
			initialValues={{
				handlingUserId: user.id,
				isAutoSelectReference: true,
				lines: [],
				patientId: patient.id,
				reference: '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProductCatalogItemsForArticlesInquiryProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						lines={lines}
						setLines={setLines}
						step={step}
						setStep={setStep}
						stepsRecord={stepsRecord}
						patient={patient}
					/>
				</SearchProductCatalogItemsForArticlesInquiryProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	lines: IArticlesInquiryLineModel[];
	setLines: React.Dispatch<React.SetStateAction<IArticlesInquiryLineModel[]>>;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof IRegisterArticlesInquiryRequest)[]>;
	patient: IObjectWithIdAndIdentifier<string>;
}

const InnerDialog = ({ cancel, lines, setLines, isSubmitting, step, setStep, stepsRecord, patient, ...rest }: IInnerProps) => {
	const props = useFormikContext<IRegisterArticlesInquiryRequest>();
	const strings = useLocalization();
	useNextReferenceInterceptor(articlesInquiriesQuery_nextReference, props);

	return (
		<FullScreenDialogWithStepper
			{...rest}
			fullScreenStep={2}
			title={strings.newArticlesInquiryForWho(patient.identifier)}
			step={step}
			labels={[strings.referenceShort, strings.user, strings.items]}>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IRegisterArticlesInquiryRequest>
							name='isAutoSelectReference'
							label={strings.autoSetOrderNumber}
						/>
						<FormTextField<IRegisterArticlesInquiryRequest>
							name='reference'
							label={strings.orderNumber}
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectUserField<IRegisterArticlesInquiryRequest>
							name='handlingUserId'
							label={strings.user}
						/>
					</>
				)}
				{step === 2 && (
					<SearchAndSelectProductsForArticlesInquiry
						lines={lines}
						setLines={setLines}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.register}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</FullScreenDialogWithStepper>
	);
};
