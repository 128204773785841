// auth
export const LoginRoute = '/login';
export const LoginWithTokenRoute = '/login/token';
export const ConfirmUserRoute = '/confirmUser/:token/:email';
export const ForgotPasswordRoute = '/forgotPassword';
export const ResetPasswordRoute = '/resetPassword/:token/:email';

// main routes
export const DashboardRoute = '/dashboard';
export const CalendarRoute = '/calendar';
export const PatientsRoute = '/patients';
export const DocumentsRoute = '/documents';
export const ProcessesRoute = '/processes';
export const InventoryManagementRoute = '/inventory-management';
export const FinancialRoute = '/financial';
export const CashDeskRoute = '/cash-desk';
export const calculateCashDeskRoute = (patientId: string | undefined) => (patientId === undefined ? CashDeskRoute : `${CashDeskRoute}?patientId=${patientId}`);
export const ContactsRoute = '/contacts';
export const IntegrationsRoute = '/integrations';
export const SettingsRoute = '/settings';
export const TroubleshootRoute = '/troubleshoot';
export const CustomFeaturesRoute = '/custom-features';
export const NotificationsRoute = '/notifications';

export const RosaPatientRedirectRoute = '/rosa-patients/redirect/:id';
export const calculateRosaPatientRedirectRoute = (id: string) => RosaPatientRedirectRoute.replace(':id', id);

export const CustomFeaturesCfRoute = `${CustomFeaturesRoute}/cf`;
