import { Typography, useTheme } from '@material-ui/core';
import CakeIcon from '@material-ui/icons/Cake';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import React from 'react';
import { NumericIcon } from 'framework/components/icons/NumericIcon';
import { formatDate } from 'framework/utils/date/formatDate';
import { IPatientSearch, PatientSearchMode } from 'gen/ApiClient';
import { GenderIcon } from '../GenderIcon';

interface IProps {
	searchMode: PatientSearchMode;
	option: IPatientSearch;
}

export const PatientSearchOption = ({ searchMode, option }: IProps) => {
	const theme = useTheme();

	return (
		<div className='df-col'>
			<div className='df-row-ac'>
				<Typography variant='body1'>{option.lastNameCommaFirstNameBracketsNickName}</Typography>
				<GenderIcon
					gender={option.gender as any}
					style={{ width: '15px', marginLeft: '4px' }}
				/>
			</div>
			{searchMode === 'Phone' && (
				<div className='df-row-ac'>
					<PhoneIcon style={{ color: theme.palette.text.secondary, marginRight: 4, fontSize: 12, marginTop: -2 }} />
					<Typography variant='caption'>{option.phoneNumber}</Typography>
				</div>
			)}
			{searchMode === 'DateOfBirth' && (
				<div className='df-row-ac'>
					<CakeIcon style={{ color: theme.palette.text.secondary, marginRight: 4, fontSize: 12, marginTop: -2 }} />
					<Typography variant='caption'>{formatDate(option.dateOfBirth)}</Typography>
				</div>
			)}
			{searchMode === 'SocialSecurityNumber' && (
				<div className='df-row-ac'>
					<NumericIcon style={{ color: theme.palette.text.secondary, marginRight: 4, fontSize: 12, marginTop: -2 }} />
					<Typography variant='caption'>{option.socialSecurityNumber}</Typography>
				</div>
			)}
			{searchMode === 'Address' && (
				<div className='df-row-ac'>
					<HomeIcon style={{ color: theme.palette.text.secondary, marginRight: 4, fontSize: 12, marginTop: -2 }} />
					<Typography variant='caption'>{option.addressFormatted}</Typography>
				</div>
			)}
		</div>
	);
};
