import { ISaleCreditNoteLineModel } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const lineToModel = (t: ILine, index: number): ISaleCreditNoteLineModel => {
	return {
		invoiceLineId: t.salesLine.id,
		quantity: t.quantity,
		zeroBasedIndexNumber: index,
	};
};
