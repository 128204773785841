import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { MoneyRangeFilterComponent } from 'framework/filter/MoneyRangeFilterComponent';
import { IOption } from 'framework/IOption';
import { isFiltered } from 'framework/utils/isFiltered';
import { CustomerAccountTypes, ICustomerAccountsQueryParams } from 'gen/ApiClient';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	params: ICustomerAccountsQueryParams;
	onFilter: (params: ICustomerAccountsQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof ICustomerAccountsQueryParams)[] = ['hasOutstandingBalance', 'hasOutstandingBalanceNotOnSettlementProposals'];

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const { customerAccountTypeRecord } = useContext(RecordContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<ListFilterComponent<string>
				options={CustomerAccountTypes.map<IOption<string>>(t => ({ id: t, identifier: customerAccountTypeRecord[t] }))}
				selected={params.accountTypes}
				setSelected={vals => onFilter({ ...params, accountTypes: vals })}
				icon={<HelpOutlineIcon />}
				label='Type account'
			/>
			<MoneyRangeFilterComponent
				label='Saldo tussen'
				selected={{ min: params.outstandingBalanceMoreThan, max: params.outstandingBalanceLessThan }}
				setSelected={val => onFilter({ ...params, outstandingBalanceLessThan: val.max, outstandingBalanceMoreThan: val.min })}
				icon={<HelpOutlineIcon />}
			/>
		</FilterBar>
	);
};
