import { DialogContent, DialogProps } from '@material-ui/core';
import { useLoggedInLocation } from 'app/auth/useLoggedInLocation';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { ISupplierWithDefaultDeliveryTime } from 'app/main/contacts/suppliers/ISupplierWithDefaultDeliveryTime';
import { FormSelectUserField } from 'app/main/settings/users/forms/FormSelectUserField';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IObjectWithIdAndIdentifier, IRegisterOrderRequest, ordersCommand_register, ordersQuery_nextReference, ordersQuery_settings } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { FormSelectDeliveryTimeFromSupplierOrManualField } from 'shared/forms/FormSelectDeliveryTimeFromSupplierOrManualField';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { LocationsPreloadCacheContext } from '../../../../settings/locations/LocationsPreloadCacheContext';
import { IOrderLineModel } from './IOrderLineModel';
import { mapOrderLineModelToRegisterOrderLineRequest } from './mapOrderLineModelToRegisterOrderLineRequest';
import { SearchAndSelectProductsForOrder } from './SearchAndSelectProductsForOrder';
import { SearchProductCatalogItemsForOrderProvider } from './SearchProductCatalogItemsForOrderProvider';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterOrderRequest>({
			expectedDeliveryDate: yup.date().required(strings.formRequired(strings.expectedDeliveryDate)),
			externalReference: yup.string(),
			lines: yup.mixed(),
			locationId: yup.string().required(strings.formRequired(strings.location)),
			supplierId: yup.string().required(strings.formRequired(strings.supplier)),
			reference: yup.string().required(),
			isAutoSelectReference: yup.boolean().defined(),
			handlingUserId: yup.string().required(),
			placeOrderImmediately: yup.bool().defined(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IRegisterOrderRequest)[]> = {
	0: ['reference', 'isAutoSelectReference', 'externalReference'],
	1: ['locationId', 'expectedDeliveryDate', 'handlingUserId'],
	2: ['lines'],
	3: ['placeOrderImmediately'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	supplier: ISupplierWithDefaultDeliveryTime;
	previous?: VoidFunction;
	patient: IObjectWithIdAndIdentifier<string> | undefined;
}

export const RegisterOrderForm = ({ confirm, cancel, previous, patient, supplier, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, setLines] = useState<IOrderLineModel[]>([]);
	const [step, setStep] = useState<number>(0);
	const [create, isSubmitting] = useFormSubmit(ordersCommand_register);
	const user = useLoggedInUser();
	const locationId = useLoggedInLocation();
	const notify = useSnackbarNotify();
	const now = useNow();

	const handleSubmit = async (values: IRegisterOrderRequest, helpers: FormikHelpers<IRegisterOrderRequest>) => {
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await create({ ...values, lines: lines.map(mapOrderLineModelToRegisterOrderLineRequest) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify(strings.orderPlaced);
				confirm(r.result.objectId);
			}
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterOrderRequest>
			validateOnMount
			initialValues={{
				externalReference: '',
				lines: [],
				reference: '',
				isAutoSelectReference: true,
				placeOrderImmediately: false,
				expectedDeliveryDate: now,
				handlingUserId: user.id,
				locationId: locationId ?? '',
				supplierId: supplier.id,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProductCatalogItemsForOrderProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						lines={lines}
						setLines={setLines}
						step={step}
						setStep={setStep}
						stepsRecord={stepsRecord}
						patient={patient}
						previous={previous}
						supplier={supplier}
					/>
				</SearchProductCatalogItemsForOrderProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	lines: IOrderLineModel[];
	setLines: React.Dispatch<React.SetStateAction<IOrderLineModel[]>>;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof IRegisterOrderRequest)[]>;
	patient: IObjectWithIdAndIdentifier<string> | undefined;
	previous: VoidFunction | undefined;
	supplier: ISupplierWithDefaultDeliveryTime;
}

const InnerDialog = ({ cancel, lines, setLines, isSubmitting, step, setStep, stepsRecord, patient, previous, supplier, ...rest }: IInnerProps) => {
	const props = useFormikContext<IRegisterOrderRequest>();
	const strings = useLocalization();
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	useNextReferenceInterceptor(ordersQuery_nextReference, props);
	const [settings] = useApiEffect(ordersQuery_settings);

	useEffect(() => {
		if (locations?.length === 1) {
			setFormValue<IRegisterOrderRequest>('locationId', locations[0].id, props);
		}
		// eslint-disable-next-line
	}, [locations]);

	if (locations === undefined || settings === undefined) {
		return <div></div>;
	}

	return (
		<FullScreenDialogWithStepper
			{...rest}
			fullScreenStep={2}
			title={strings.newOrderWithWho(supplier.name ?? '')}
			step={step}
			labels={[strings.referenceShort, strings.details, strings.items, strings.confirm]}>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IRegisterOrderRequest>
							name='isAutoSelectReference'
							label={strings.autoSetOrderNumber}
						/>
						<FormTextField<IRegisterOrderRequest>
							name='reference'
							label={strings.orderNumber}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormTextField<IRegisterOrderRequest>
							name='externalReference'
							label={`${strings.externalReference} (${strings.fromSupplier})`}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectFieldWithOption<IRegisterOrderRequest, string>
							name='locationId'
							options={locations}
							label={strings.location}
							helperText={strings.selectDeliveryLocationHelperText}
						/>
						<FormSelectDeliveryTimeFromSupplierOrManualField<IRegisterOrderRequest>
							name='expectedDeliveryDate'
							label={strings.expectedDeliveryDate}
							supplier={supplier}
							defaultDeliveryTime={settings.defaultDeliveryTime}
						/>
						<FormSelectUserField<IRegisterOrderRequest>
							name='handlingUserId'
							label={strings.user}
						/>
					</>
				)}
				{step === 2 && (
					<SearchAndSelectProductsForOrder
						lines={lines}
						setLines={setLines}
						patient={patient}
					/>
				)}
				{step === 3 && (
					<FormExtendedRadioGroupField<IRegisterOrderRequest, boolean>
						name='placeOrderImmediately'
						label=''
						options={[
							{ value: true, label: strings.placeOrderImmediatelyWithSupplier, caption: strings.placeOrderImmediatelyWithSupplierContent },
							{ value: false, label: strings.placeOrderWithSupplierLater, caption: strings.placeOrderWithSupplierLaterContent },
						]}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
				previous={previous}
			/>
		</FullScreenDialogWithStepper>
	);
};
