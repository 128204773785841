import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { CashPlusIcon } from 'framework/components/icons/CashPlusIcon';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { ICustomerAccount, ICustomerAccountMetadata } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { CustomerAccountStatusChip } from '../CustomerAccountStatusChip';
import { CustomerAccountsPageBreadcrumbs } from '../CustomerAccountsPageBreadcrumbs';
import { CustomerAccountDetailTabType, CustomerAccountDetailTabTypes } from './CustomerAccountDetailTabType';
import { CustomerAccountDetailQuickStats } from './CustomerAccountDetailQuickStats';
import { PageStatsButtonX } from 'framework/components/layout/PageStatsButtonX';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { Collapse } from '@material-ui/core';

const TabTypeIconRecord: Record<CustomerAccountDetailTabType, React.ReactNode> = {
	payments: <CashPlusIcon />,
	sales: <AttachMoneyIcon />,
	settlementProposals: <ReceiptOutlinedIcon />,
};

const createTabRecord = (strings: IStrings): Record<CustomerAccountDetailTabType, string> => {
	return {
		payments: strings.payments,
		sales: strings.sales,
		settlementProposals: strings.settlementProposals,
	};
};

interface IProps {
	item: ICustomerAccount;
	metadata: ICustomerAccountMetadata;
	reload: VoidFunction;
	tab: CustomerAccountDetailTabType;
	setTab: (tab: CustomerAccountDetailTabType) => void;
	style?: React.CSSProperties;
}

export const CustomerAccountDetailHeader = ({ item, metadata, tab, setTab, reload, style }: IProps) => {
	const strings = useLocalization();
	const tabRecord = useMemo(() => createTabRecord(strings), [strings]);
	const [showStats, toggleShowStats] = useStateToggleBoolean(true);

	return (
		<div
			className='df-col fg1'
			style={style}>
			<div className='df-row-ac'>
				<CustomerAccountsPageBreadcrumbs />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={item.identifier} />
				<CustomerAccountStatusChip
					ca={item}
					style={{ marginLeft: 4 }}
				/>
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageStatsButtonX
						showStats={showStats}
						toggleShowStats={toggleShowStats}
					/>
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<Collapse in={showStats}>
				<CustomerAccountDetailQuickStats
					item={item}
					metadata={metadata}
					setTab={setTab}
					style={{ paddingTop: 16 }}
				/>
			</Collapse>
			<HorizontalTabsX
				tabs={CustomerAccountDetailTabTypes}
				selectedTab={tab}
				setSelectedTab={setTab}
				titleRecord={tabRecord}
				startIconRecord={TabTypeIconRecord}
				// endIconRecord={endIconRecord}
				style={{
					marginTop: 16,
					marginLeft: -4,
				}}
			/>
		</div>
	);
};
