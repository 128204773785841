import { IUserDto } from 'gen/ApiClient';
import React from 'react';
import { LastActivityStatusChipX } from './components/LastActivityStatusChipX';
import { UserRoleStatusChipX } from './components/UserRoleStatusChipX';

interface IProps {
	item: IUserDto;
}

export const UserChips = ({ item }: IProps) => {
	return (
		<div className='df-row-ac gap-2'>
			<UserRoleStatusChipX item={item} />
			<LastActivityStatusChipX item={item} />
		</div>
	);
};
