import React, { useEffect, useMemo, useState } from 'react';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { IInventoryItem, repairsQuery_notSent } from 'gen/ApiClient';
import { AddInventoryItemToRepairForm } from './AddInventoryItemToRepairForm';
import { AddOrCreateForm } from './AddOrCreateForm';
import { LinkToPatientForm } from './LinkToPatientForm';
import { RepairFromInventoryItemModelForm } from './RepairFromInventoryItemModelForm';
import { SelectRepairForm } from './SelectRepairForm';

interface IProps {
	item: IInventoryItem;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

type State = 'linkToPatient' | 'addOrCreate' | 'select' | 'create' | 'add';

export const RepairFromInventoryItemForm = ({ item, cancel, confirm }: IProps) => {
	const [state, setState] = useState<State>(item.patientId ? 'linkToPatient' : 'addOrCreate');
	const [repairs] = useApiEffect(repairsQuery_notSent, item.id);
	const [isForPatient, setIsForPatient] = useState<boolean>(item.patientId !== undefined && item.patientId !== null);
	const filteredRepairs = useMemo(
		() =>
			repairs === undefined
				? undefined
				: isForPatient && item.patientId
				? repairs.filter(t => t.forPatientId === item.patientId)
				: item.patientId
				? repairs.filter(t => t.isForPatient === false)
				: repairs,
		[repairs, isForPatient, item]
	);
	const [repairId, setRepairId] = useState<string>();

	useEffect(() => {
		if (item.patientId) {
			setState('linkToPatient');
		} else {
			setState('addOrCreate');
		}
	}, [item]);

	useEffect(() => {
		if (state === 'addOrCreate' && filteredRepairs !== undefined) {
			if (filteredRepairs.length === 0) {
				setState('create');
			}
		}
	}, [filteredRepairs, state]);

	const confirmFor = (isForPatient: boolean) => {
		setIsForPatient(isForPatient);
		setState('addOrCreate');
	};

	const confirmAddOrCreate = (isAdd: boolean) => {
		setState(isAdd ? 'select' : 'create');
	};

	const confirmRepair = (repairId: string) => {
		setRepairId(repairId);
		setState('add');
	};

	if (state === 'linkToPatient') {
		return (
			<LinkToPatientForm
				open
				item={item}
				confirm={confirmFor}
				cancel={cancel}
			/>
		);
	} else if (state === 'addOrCreate') {
		return (
			<AddOrCreateForm
				open
				confirm={confirmAddOrCreate}
				cancel={cancel}
			/>
		);
	} else if (state === 'select') {
		return (
			<SelectRepairForm
				open
				repairs={filteredRepairs!}
				confirm={confirmRepair}
				cancel={cancel}
			/>
		);
	} else if (state === 'add') {
		return (
			<AddInventoryItemToRepairForm
				open
				item={item}
				repairId={repairId!}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<RepairFromInventoryItemModelForm
				open
				item={item}
				patientId={isForPatient ? item.patientId : undefined}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	}
};
