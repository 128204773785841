import { Typography, useTheme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { FilterOffIcon } from 'framework/components/icons/FilterOffIcon';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { RadioFilterComponent } from 'framework/filter/RadioFilterComponent';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { IOption } from 'framework/IOption';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { IMedicalTreatmentNomenclatureForAudiology } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MedicalTreatmentNomenclaturesCacheContext } from 'shared/context/MedicalTreatmentNomenclaturesCacheContext';
import { RecordContext } from 'shared/records/RecordContext';
import { LumpSumType, LumpSumTypes } from './LumpSumType';

interface ILumpSumFilterData {
	isAmbulant: boolean | undefined;
	lumpSumType: LumpSumType | undefined;
}

const DefaultFilterData: ILumpSumFilterData = {
	isAmbulant: true,
	lumpSumType: undefined,
};

interface IProps {
	setFiltered: React.Dispatch<React.SetStateAction<IMedicalTreatmentNomenclatureForAudiology[]>>;
}

export const LumpSumFilter = ({ setFiltered }: IProps) => {
	const [data] = useCacheContext(MedicalTreatmentNomenclaturesCacheContext);
	const [params, setParams] = useState<ILumpSumFilterData>(DefaultFilterData);
	const strings = useLocalization();
	const theme = useTheme();
	const isFiltered = useMemo(() => params.lumpSumType !== undefined, [params]);
	const { lumSumpRecord } = useContext(RecordContext);

	useEffect(() => {
		if (data !== undefined) {
			const x = data
				// TODO delete isNullOrUndefined after 1/10/21
				.filter(t => t.data.isCurrentlyValid === true && t.isLumpSum === true && t.isPseudoCode === false && isNullOrUndefined(t.validityEnd))
				.filter(t => params.isAmbulant === undefined || t.data.isAmbulant === params.isAmbulant)
				.filter(
					t =>
						params.lumpSumType === undefined ||
						(params.lumpSumType === 'FailedTrial' && t.isLumpSumNegativeTrial) ||
						(params.lumpSumType === 'PassedAway' && t.isLumpSumPassedAway)
				);
			setFiltered(x);
		}
		// eslint-disable-next-line
	}, [params, data]);

	const onClearFilters = () => {
		setParams({ ...params, lumpSumType: undefined });
	};

	return (
		<div className='df-col'>
			<DividerWithCaption
				caption={strings.filter}
				action={
					<SmallPrimaryTextButton
						onClick={onClearFilters}
						disabled={isFiltered === false}
						startIcon={<FilterOffIcon />}
						color='secondary'>
						{strings.clearFilter}
					</SmallPrimaryTextButton>
				}
			/>
			<div className='df-col-as'>
				<Typography style={{ paddingLeft: '5px', marginBottom: theme.spacing(1) }}>{strings.filter}</Typography>
				<BooleanRadioFilterComponent
					style={{ marginBottom: theme.spacing(0.5) }}
					badgeVariant='dot'
					trueText={strings.ambulatory}
					falseText={strings.hospitalized}
					selected={params.isAmbulant}
					setSelected={val => setParams({ ...params, isAmbulant: val })}
					label={params.isAmbulant ? strings.ambulatory : strings.hospitalized}
					icon={<HelpOutlineIcon />}
				/>
				<RadioFilterComponent<LumpSumType>
					style={{ marginBottom: theme.spacing(0.5) }}
					badgeVariant='dot'
					options={LumpSumTypes.map<IOption<LumpSumType>>(t => ({ id: t, identifier: lumSumpRecord[t] }))}
					selected={params.lumpSumType}
					setSelected={val => setParams({ ...params, lumpSumType: val })}
					label={params.lumpSumType ? lumSumpRecord[params.lumpSumType] : strings.type}
					icon={<HelpOutlineIcon />}
				/>
			</div>
		</div>
	);
};
