import { ParamPropertyNameExclusions } from './ParamPropertyNameExclusions';

export function clearParams<T extends {}>(pars: T, exclusions?: (keyof T)[]): T {
	let newPars = pars;
	// for (let [key, value] of Object.entries(newPars).filter(t => t[0] !== 'searchString' && t[0] !== 'sortParams' && t[0] !== 'pageParams')) {
	for (let [key, value] of filterEntries(newPars, exclusions)) {
		if (Array.isArray(value)) {
			newPars = { ...newPars, ...Object.fromEntries([[key, []]]) };
		} else {
			newPars = { ...newPars, ...Object.fromEntries([[key, undefined]]) };
		}
	}
	return newPars;
}

function filterEntries<T>(pars: T, exclusions?: (keyof T)[]) {
	return Object.entries(pars)
		.filter(t => ParamPropertyNameExclusions.indexOf(t[0]) === -1)
		.filter(t => (exclusions !== undefined ? exclusions.indexOf(t[0] as any) === -1 : true));
}
