import React, { useContext } from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { PaymentStatus } from 'gen/ApiClient';
import { RecordContext } from './records/RecordContext';

interface IData {
	paymentStatus: string | undefined;
	isFullyPaid: boolean;
}

interface IProps {
	data: IData;
	style?: React.CSSProperties;
}

export const PaymentStatusChip = ({ data, style }: IProps) => {
	const { paymentStatusRecord } = useContext(RecordContext);
	return (
		<RectangularChip
			style={style}
			label={paymentStatusRecord[data.paymentStatus as PaymentStatus]}
			fill={data.isFullyPaid === true}
			color={data.isFullyPaid === true ? 'success' : 'info'}
		/>
	);
};
