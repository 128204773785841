import { ISalesLine } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SalesDetailTabType } from './SalesDetailTabType';

export const mapSalesLineToColorX = (line: ISalesLine) => {
	return line.isFullyCredited || line.isPartlyCredited ? 'error' : 'info';
};

export const createSalesDetailTabTypeRecord = (strings: IStrings): Record<SalesDetailTabType, string> => {
	return {
		contents: strings.contents,
		accountsReceivableAndPayments: `${strings.accountReceivableSingularOrPlural} & ${strings.payments}`,
	};
};
