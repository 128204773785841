import React from 'react';
import { CacheProvider } from 'framework/context/CacheProvider';
import {
	IMedicalTreatmentNomenclatureForAudiology,
	medicalTreatmentNomenclaturesQuery_all,
	medicalTreatmentNomenclaturesQuery_concurrencyToken,
} from 'gen/ApiClient';
import { MedicalTreatmentNomenclaturesCacheContext } from './MedicalTreatmentNomenclaturesCacheContext';

export const MedicalTreatmentNomenclaturesCacheProvider = ({ children }: any) => {
	return (
		<CacheProvider<IMedicalTreatmentNomenclatureForAudiology[]>
			concurrencyF={medicalTreatmentNomenclaturesQuery_concurrencyToken}
			loadF={medicalTreatmentNomenclaturesQuery_all}
			context={MedicalTreatmentNomenclaturesCacheContext}>
			{children}
		</CacheProvider>
	);
};
