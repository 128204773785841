import { useField } from 'formik';
import { IOption } from 'framework/IOption';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { nameOf } from 'framework/utils/nameOf';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo } from 'react';
import { useUsersAsFilterOptions } from '../useUsersAsFilterOptions';

export const FormSelectAudiologistField = <TModel extends unknown>({ name, label, ...rest }: IFormProps<TModel>) => {
	// eslint-disable-next-line
	const [field, _, helpers] = useField<string | undefined>(nameOf(name));
	const fUsers = useUsersAsFilterOptions();
	const filteredAudiologists = useMemo(() => fUsers.filter(t => t.isAudiologist), [fUsers]);

	const strings = useLocalization();

	useEffect(() => {
		if (filteredAudiologists !== undefined && isNotNullNorUndefined(field.value)) {
			if (filteredAudiologists.findIndex(t => t.id === field.value) === -1) {
				helpers.setValue(undefined as any);
			}
		}
		// eslint-disable-next-line
	}, [filteredAudiologists, field.value]);

	if (filteredAudiologists === undefined) {
		return <div></div>;
	}

	return (
		<FormAutocompleteField<TModel, IOption<string>>
			name={name}
			label={label}
			options={filteredAudiologists}
			helperText={strings.notAllUserVisibleHelperText}
			{...rest}
		/>
	);
};
