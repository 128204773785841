import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		amountPaid: strings.amountPaid,
		created: strings.createdAt,
		paymentReference: strings.paymentReference,
		reference: strings.reference,
		totalValue: strings.totalAmount,
		belgianStructuredCreditorReference: strings.structuredReference,
	};
};
