import { Divider } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import UndoIcon from '@material-ui/icons/Undo';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { LabelledChipsList } from 'framework/components/chips/LabelledChipsList';
import { CashMinusIcon } from 'framework/components/icons/CashMinusIcon';
import { CashPlusIcon } from 'framework/components/icons/CashPlusIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISale, salesCommand_patchExternalReference, salesQuery_downloadUbl } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PrintSalesTicketDialog } from 'shared/dialogs/thermalPrinter/PrintSalesTicketDialog';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RegisterPaymentForSaleForm } from '../../payments/forms/RegisterPaymentForSaleForm';
import { RegisterRefundForSaleForm } from '../../payments/forms/RegisterRefundForSaleForm';
import { calcSalesDetailRoute } from '../../routes';
import { SalesLabelsForm } from '../forms/SalesLabelsForm';
import { SaleCreditNoteForm } from '../forms/creditNote/SaleCreditNoteForm';
import { SalesDetailDocumentContent } from './SalesDetailDocumentContent';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';

interface IProps {
	item: ISale;
	reload: VoidFunction;
}

export const SalesDetailPageSidebar = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const [downloadUbl] = useDownload(salesQuery_downloadUbl);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();

	const onPatchExternalReference = () => {
		open(
			<StringModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.changeSomething(strings.externalReference)}
				label={strings.externalReference}
				initialValue={item.externalReference}
				cancel={cancel}
				submitText={strings.update}
				submitFunction={val => salesCommand_patchExternalReference({ salesId: item.id, externalReference: val })}
			/>
		);
	};

	const onEditLabels = () => {
		open(
			<SalesLabelsForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onPayment = () => {
		open(
			<RegisterPaymentForSaleForm
				open
				sale={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onRefund = () => {
		open(
			<RegisterRefundForSaleForm
				open
				sale={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onCreditNote = () => {
		open(
			<SaleCreditNoteForm
				open
				invoiceId={item.id}
				confirm={id => {
					confirm();
					push(calcSalesDetailRoute(id));
				}}
				cancel={cancel}
			/>
		);
	};

	const onPrint = () => {
		open(
			<PrintSalesTicketDialog
				open
				confirm={confirm}
				cancel={cancel}
				cashSaleId={item.id}
				title={strings.printTicket}
			/>
		);
	};

	return (
		<div
			className='df-col h100'
			style={{
				padding: 16,
				paddingRight: 8,
				// width: 250,
			}}>
			<div className='df-col gap-6'>
				<CaptionVerticalLabelledPropertyX label={strings.reference}>{item.reference}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.externalReference}
					edit={onPatchExternalReference}>
					<StringValueOrNotApplicable>{item.externalReference}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.date}>{formatDate(item.created)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.payBefore}>{formatDate(item.validUntil)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.totalInVat}>{formatCurrency(item.priceInVat)}</CaptionVerticalLabelledPropertyX>
			</div>
			<Divider style={{ marginTop: 16, marginBottom: 8 }} />
			<SalesDetailDocumentContent
				item={item}
				reload={reload}
			/>
			<Divider style={{ marginTop: 16, marginBottom: 8 }} />
			<div
				className='df-col fg1 gap-4'
				style={{ overflowY: 'hidden' }}>
				<TextLinkButton
					style={{ fontSize: 12 }}
					color='textSecondary'
					onClick={onEditLabels}>
					<div>{strings.labels}</div>
					<div className='fg1'></div>
					<SettingsOutlinedIcon style={{ fontSize: 16 }} />
				</TextLinkButton>
				<div
					className='fg1'
					style={{ overflowY: 'auto' }}>
					{item.labels.length > 0 && <LabelledChipsList labels={item.labels} />}
					{item.labels.length === 0 && <NoRecordsFound variant='caption'>{strings.noLabelsAssignedYet}</NoRecordsFound>}
				</div>
			</div>
			<div className='fg1'></div>
			<DividerWithMargin />
			<div className='df-col gap-12'>
				<TextLinkButton
					startIcon={<GetAppOutlinedIcon />}
					onClick={() => downloadUbl(item.id)}>
					{strings.exportToUbl}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<CashPlusIcon />}
					disabled={item.isFullyPaid}
					onClick={onPayment}>
					{strings.registerPayment}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<CashMinusIcon />}
					disabled={item.canCreateRefund === false}
					onClick={onRefund}>
					{strings.registerRefund}
				</TextLinkButton>
				{item.canCreateCreditNote && (
					<TextLinkButton
						startIcon={<UndoIcon />}
						onClick={onCreditNote}>
						{strings.createWhat(strings.creditNote)}
					</TextLinkButton>
				)}
				{item.isCashSale && (
					<TextLinkButton
						startIcon={<PrintOutlinedIcon />}
						onClick={onPrint}>
						{strings.printTicket}
					</TextLinkButton>
				)}
			</div>
		</div>
	);
};
