import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { useLocalization } from 'localization/useLocalization';
import { Mode } from './Mode';

interface IProps extends DialogProps {
	cancel: VoidFunction;
	confirm: (mode: Mode) => void;
}

export const ModeDialog = ({ cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const [mode, setMode] = useState<Mode>('eid');

	return (
		<Dialog {...rest}>
			<DialogTitle>{`${strings.newWhat(strings.patient)}; ${strings.selectWhat(strings.mode)}`}</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<Mode>
					selected={mode}
					setSelected={setMode}
					options={[
						{ label: strings.identityCard, value: 'eid', caption: strings.createPatientFromIdentityCardCaption },
						{ label: strings.form, value: 'form', caption: strings.createPatientFromFormCaption },
						{ label: strings.importVerb, value: 'nhax', caption: strings.createPatientFromNhaxCaption },
					]}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button
					onClick={() => confirm(mode)}
					color='primary'>
					{strings.select}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
