import { Button, Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { createHeader } from 'framework/table/createHeader';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { createType } from 'framework/utils/createType';
import { IRequestForInsuranceAllowance, IRfiasFilterParams, RfiaExportProp, RfiaExportProps, rfiasQuery_export } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsNavigateHeader } from 'shared/table/createDetailsNavigateHeader';
import { calcRequestForInsuranceAllowanceDetailRoute } from '../ProcessesRoutes';
import { Filter } from './Filter';
import { RfiaStatusComponent } from './RfiaStatusComponent';
import { RfiasListPageHeader } from './RfiasListPageHeader';
import { SelectableColumn } from './SelectableColumn';
import { RfiasQueryContext } from './context/RfiasQueryContext';
import { ViewContext } from './context/ViewContext';
import { RfiaForm } from './forms/new/RfiaForm';

const QuickFilters = createType('all', 'running', 'warning');
export type QuickFilter = typeof QuickFilters[number];

const qfIconRecord: Record<QuickFilter, JSX.Element | undefined> = {
	warning: <StatusIcon status='warning' />,
	running: undefined,
	all: undefined,
};

export const RfiasListPage = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(RfiasQueryContext);
	const strings = useLocalization();
	const theme = useTheme();
	const { columns, setColumns, columnsRecord, headers, quickFilter, setQuickFilter, quickFilterRecord, exportRecord } = useContext(ViewContext);
	const { open, confirm, cancel } = useDialogsContext(reload);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<RfiaForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='Rfias'
			stickyHeader={
				<RfiasListPageHeader
					queryResult={queryResult}
					params={params}
					metadata={queryResult.metadata}
					reload={reload}
					style={{ paddingBottom: 16 }}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16 }}>
				<div className='df-row-ac jc-sb'>
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchVerb}
						style={{ width: '400px' }}
						variant='paper'
					/>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						onClick={onNew}>
						{strings.newWhat(strings.requestForInsuranceAllowance)}
					</Button>
				</div>
				{queryResult.unfilteredCount > 0 && (
					<>
						<Card>
							<div
								className='df-row-ac jc-sb'
								style={{ padding: theme.spacing(1) }}>
								<ButtonGroupWithSelection<QuickFilter>
									options={QuickFilters}
									selected={quickFilter}
									setSelected={setQuickFilter}
									renderOption={t => quickFilterRecord[t]}
									iconRecord={qfIconRecord}
								/>
								<div>
									<ExportButtonMenuAndDialog<IRfiasFilterParams, RfiaExportProp>
										params={params}
										filteredCount={queryResult.filteredCount}
										unfilteredCount={queryResult.unfilteredCount}
										downloadF={rfiasQuery_export}
										fileName={strings.requestForInsuranceAllowances}
										record={exportRecord}
										allProps={RfiaExportProps}
										view='rfias'
									/>
									<SelectColumnsButtonAndDialog
										selectedColumns={columns}
										setSelectedColumns={setColumns}
										record={columnsRecord}
										view='rfias'
									/>
								</div>
							</div>
							<Divider />
							<div className='df-row jc-sb'>
								<Filter
									params={params}
									onFilter={params => setParams(onResetToPage1(params))}
									paddingLeft={theme.spacing(1.5)}
								/>
							</div>
							<Divider />
							<CardMedia>
								<PageableTableWithColumnSelection<IRequestForInsuranceAllowance, SelectableColumn>
									queryResult={queryResult}
									headers={headers}
									sortParams={params.sortParams}
									pageParams={params.pageParams}
									setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
									getKey={inst => inst.id}
									selectedColumns={columns}
									prependColumn={createHeader<IRequestForInsuranceAllowance>(strings.status, t => (
										<RfiaStatusComponent item={t} />
									))}
									appendColumn={createDetailsNavigateHeader<IRequestForInsuranceAllowance>(calcRequestForInsuranceAllowanceDetailRoute)}
								/>
							</CardMedia>
						</Card>
					</>
				)}
				{queryResult.unfilteredCount === 0 && <NoRecordsFound>{strings.noRfiaDefinedText}</NoRecordsFound>}
			</div>
		</RestrictedPageX>
	);
};
