import { IProductCatalogItem } from 'gen/ApiClient';
import { v1 as uuid } from 'uuid';
import { IArticlesInquiryLineModel } from './IArticlesInquiryLineModel';

export const mapProductCatalogItemToArticlesInquiryLineModel = (pc: IProductCatalogItem): IArticlesInquiryLineModel => {
	return {
		id: pc.isUniquelyIdentifiable ? uuid() : pc.id,
		description: pc.identifier,
		productCatalogItemId: pc.id,
		quantity: 1,
		productCatalogItem: pc,
		orientation: '',
		articleProperties: pc.articlePropertyDefinitions.map(t => ({ key: t.key, values: [] })),
	};
};
