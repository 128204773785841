import { Button } from '@material-ui/core';
import React from 'react';
import { theme } from '../../theme';

const commonStyle: React.CSSProperties = {
	textTransform: 'none',
	textAlign: 'left',
	marginRight: theme.spacing(1),
	fontWeight: 'normal',
};

const selectedPrimaryStyle: React.CSSProperties = {
	...commonStyle,
	backgroundColor: 'rgba(25, 118, 210, 0.1)',
	// fontWeight: 'bold',
};

const selectedSecondaryStyle: React.CSSProperties = {
	...commonStyle,
	backgroundColor: 'rgb(245, 0, 87, 0.1)',
	// fontWeight: 'bold',
};

interface IProps<TOption extends string> {
	options: TOption[];
	selected: TOption;
	setSelected: React.Dispatch<React.SetStateAction<TOption>>;
	renderOption: (val: TOption) => string;
	color?: 'primary' | 'secondary';
	style?: React.CSSProperties;
	variant?: 'text' | 'outlined';
	iconRecord?: Record<TOption, JSX.Element | undefined>;
}

export const ButtonGroupWithSelection = <TOption extends string>({
	options,
	selected,
	setSelected,
	renderOption,
	color = 'primary',
	style,
	variant = 'text',
	iconRecord,
}: IProps<TOption>) => {
	return (
		<div
			className='df-row-ac'
			style={style}>
			{options.map(t => (
				<Button
					startIcon={iconRecord ? iconRecord[t] : undefined}
					key={t}
					onClick={() => setSelected(t)}
					color={selected === t ? color : 'default'}
					variant={variant}
					style={selected === t ? (color === 'primary' ? selectedPrimaryStyle : selectedSecondaryStyle) : commonStyle}>
					{renderOption(t)}
				</Button>
			))}
		</div>
	);
};
