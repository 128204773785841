import { RosaRoute } from 'app/main/integrations/IntegrationsRoutes';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface IProps {
	countUnlinkedUsers: number;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const RosaLinkUsersDialogWithAlertDialog = ({ countUnlinkedUsers, cancel, confirm }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();

	return (
		<AlertDialog
			open
			title={strings.countRosaUsersNotLinked(countUnlinkedUsers)}
			content={strings.doYouWantToLinkTheRosaUsersNowQuestion}
			acceptText={strings.yes}
			rejectText={strings.noCommaGoBack}
			accept={() => {
				confirm();
				push(RosaRoute);
			}}
			reject={cancel}
		/>
	);
};
