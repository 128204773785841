import { Divider } from '@material-ui/core';
import { DocumentFromTemplateForm } from 'documents/DocumentFromTemplateForm';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { SalesType, salesQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { RecordContext } from 'shared/records/RecordContext';
import { SalesDetailContentsCard } from './SalesDetailContentsCard';
import { SalesDetailOverviewHeaderCard } from './SalesDetailOverviewHeaderCard';
import { SalesDetailPageHeader } from './SalesDetailPageHeader';
import { SalesDetailPageSidebar } from './SalesDetailPageSidebar';
import { SalesDetailPageViewContext } from './SalesDetailPageViewContext';
import { SalesDetailTabTypes } from './SalesDetailTabType';
import { SalesDetailsAccountsReceivableAndPaymentsContent } from './SalesDetailsAccountsReceivableAndPaymentsContent';
import { SalesDetailsTotalsBreakdownCard } from './SalesDetailsTotalsBreakdownCard';

export const SalesDetailPage = () => {
	const strings = useLocalization();
	const { id } = useParams<IRouteParamsWithId>();
	const [item, reload] = useApiEffect(salesQuery_single, id);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { saleTypeRecord } = useContext(RecordContext);
	const { selectedTab, setSelectedTab, salesId, setSalesId } = useContext(SalesDetailPageViewContext);
	const { TabTypeRecord } = useContext(SalesDetailPageViewContext);
	const [hasChecked, setHasChecked] = React.useState<boolean>(false);

	useEffect(() => {
		if (salesId !== id) {
			setSelectedTab('contents');
			setSalesId(id);
		}
		// eslint-disable-next-line
	}, [id, salesId]);

	useEffect(() => {
		if (item !== undefined) {
			setHasChecked(true);
		}
		if (item !== undefined && item.hasDocument === false && hasChecked === false) {
			open(
				<DocumentFromTemplateForm
					open
					context='Sale'
					contextId={item.id}
					templatePredefinedType='Invoice'
					confirm={confirm}
					cancel={cancel}
					title={`${strings.createADocumentRightAwayQuestion} (${saleTypeRecord[item.type as SalesType]})`}
					submitText={strings.selectWhatAndCreate(strings.template)}
					cancelText={strings.closeThisWindow}
				/>
			);
		}
		// eslint-disable-next-line
	}, [item, hasChecked]);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='Sales'
			maxWidth={1280}
			stickyHeader={
				<SalesDetailPageHeader
					item={item}
					reload={reload}
				/>
			}
			stickySidebar={
				<SalesDetailPageSidebar
					item={item}
					reload={reload}
				/>
			}>
			<div
				className='df-col'
				style={{
					padding: 16,
					paddingLeft: 8,
					maxWidth: 1280,
				}}>
				<SalesDetailOverviewHeaderCard
					item={item}
					reload={reload}
				/>
				<div
					className='df-col'
					style={{ marginTop: 24, marginBottom: 16 }}>
					<HorizontalTabsX
						tabs={SalesDetailTabTypes}
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
						titleRecord={TabTypeRecord}
					/>
					<Divider style={{ marginTop: -1 }} />
				</div>
				{selectedTab === 'contents' && (
					<div className='df-row gap-16'>
						<SalesDetailContentsCard
							item={item}
							className='fg1 h-fc'
						/>
						<SalesDetailsTotalsBreakdownCard
							item={item}
							className='h-fc'
						/>
					</div>
				)}
				{selectedTab === 'accountsReceivableAndPayments' && <SalesDetailsAccountsReceivableAndPaymentsContent item={item} />}
			</div>
		</RestrictedPageX>
	);
};
