import { DialogProps } from '@material-ui/core';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { BoolExtendedRadioGroupWithExternalSubmitForm } from 'framework/forms/BoolExtendedRadioGroupWithExternalSubmitForm';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { agendaQuery_countForUser, usersCommand_patchIsPlannable } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';

interface IUserForThisDialog {
	id: string;
	isPlannable: boolean;
}

interface IProps extends DialogProps {
	user: IUserForThisDialog;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const UserToggleIsPlannableDialog = ({ user, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [mode, setMode] = useState<'request' | 'confirm'>('request');
	const [submit, isSubmitting] = useFormSubmit(usersCommand_patchIsPlannable);
	const notify = useSnackbarNotify();
	const [count] = useApiEffect(agendaQuery_countForUser, user.id);

	const onConfirm = async (isPlannable: boolean) => {
		if (user.isPlannable && isPlannable === false) {
			setMode('confirm');
		} else {
			onProceed(isPlannable);
		}
	};

	const onProceed = async (val: boolean) => {
		const r = await submit({ userId: user.id, isPlannable: val });
		handleValidateResponseBase(
			r,
			e => notify(e, 'error'),
			() => {
				confirm();
			}
		);
		return r;
	};

	if (mode === 'request') {
		return (
			<BoolExtendedRadioGroupWithExternalSubmitForm
				{...rest}
				formTitle={strings.plannable}
				trueLabel={strings.plannable}
				falseLabel={strings.notWhat(strings.plannable)}
				trueCaption={strings.makeUserPlannableAgainContent}
				falseCaption={strings.makeUserNoLongerPlannableContent}
				submit={onConfirm}
				initialValue={user.isPlannable}
				cancel={cancel}
				submitText={strings.update}
				isSubmitting={isSubmitting}
			/>
		);
	} else if (count !== undefined && mode === 'confirm') {
		return (
			<AlertDialog
				{...rest}
				title={strings.setUserAsNotPlannable}
				content={strings.countEventsAreLinkedToThisUserDoYouWantToContinueQuestion(count)}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				acceptF={() => onProceed(false)}
				accept={() => {}}
				reject={cancel}
			/>
		);
	} else {
		return <div></div>;
	}
};
