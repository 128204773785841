import { LanguageOption } from 'gen/ApiClient';
import React from 'react';
import { SharedProviders } from 'shared/context/SharedProviders';
import { DialogsProvider } from 'shared/dialogs/DialogsProvider';
import { CalendarProviders } from './main/calendar/context/CalendarProviders';
import { ContactsProviders } from './main/contacts/ContactsProviders';
import { DocumentsProviders } from './main/documents/context/DocumentsProviders';
import { FinancialsProviders } from './main/financial/FinancialsProviders';
import { IntegrationsProviders } from './main/integrations/IntegrationsProviders';
import { InventoryManagementProviders } from './main/inventoryManagement/InventoryManagementProviders';
import { NotificationsProviders } from './main/notifications/context/NotificationsProviders';
import { PatientsProviders } from './main/patients/PatientsProviders';
import { ProcessesProviders } from './main/processes/ProcessesProviders';
import { SettingsProviders } from './main/settings/SettingsProviders';
import { TroubleshootProviders } from './troubleshooting/TroubleshootProviders';

interface IProps {
	language: LanguageOption;
	children: any;
}

export const AppProviders = ({ children, language }: IProps) => {
	return (
		<TroubleshootProviders>
			<ContactsProviders>
				<SettingsProviders>
					<InventoryManagementProviders>
						<FinancialsProviders>
							<PatientsProviders>
								<DocumentsProviders>
									<CalendarProviders>
										<IntegrationsProviders>
											<ProcessesProviders>
												<SharedProviders language={language}>
													{/* must be as deep as possible (will use other contexts) */}
													<DialogsProvider>
														<NotificationsProviders>{children}</NotificationsProviders>
													</DialogsProvider>
												</SharedProviders>
											</ProcessesProviders>
										</IntegrationsProviders>
									</CalendarProviders>
								</DocumentsProviders>
							</PatientsProviders>
						</FinancialsProviders>
					</InventoryManagementProviders>
				</SettingsProviders>
			</ContactsProviders>
		</TroubleshootProviders>
	);
};
