import React from 'react';
import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IPayment, SalesType } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { PaymentMethodStatusChip } from 'shared/PaymentMethodStatusChip';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (
	colRecord: Record<SelectableColumn, string>,
	saleTypeRecord: Record<SalesType, string>,
	strings: IStrings
): Record<SelectableColumn, IHeader<IPayment>> => {
	return {
		created: createSortableHeaderWithKey<IPayment, SelectableColumn>(colRecord, 'created', t => formatDate(t.created)),
		amountPaid: createSortableHeaderWithKey<IPayment, SelectableColumn>(colRecord, 'amountPaid', t => formatCurrency(t.amountPaid), 'right'),
		cashSaleForPatientIdentifier: createSortableHeaderWithKey<IPayment, SelectableColumn>(
			colRecord,
			'cashSaleForPatientIdentifier',
			t => t.cashSaleForPatientIdentifier
		),
		customerOrSupplierAccountIdentifier: createSortableHeaderWithKey<IPayment, SelectableColumn>(
			colRecord,
			'customerOrSupplierAccountIdentifier',
			t => t.customerOrSupplierAccountIdentifier
		),
		paymentDate: createSortableHeaderWithKey<IPayment, SelectableColumn>(colRecord, 'paymentDate', t => formatDate(t.paymentDate)),
		paymentMethodIdentifier: createHeaderWithKey<IPayment, SelectableColumn>(colRecord, 'paymentMethodIdentifier', t => (
			<PaymentMethodStatusChip label={t.paymentMethodIdentifier ?? ''} />
		)),
		reference: createSortableHeaderWithKey<IPayment, SelectableColumn>(colRecord, 'reference', t => t.reference),
		salesType: createHeaderWithKey<IPayment, SelectableColumn>(colRecord, 'salesType', t => saleTypeRecord[t.salesType as SalesType]),
		comment: createSortableHeaderWithKey<IPayment, SelectableColumn>(colRecord, 'comment', t => t.comment),
	};
};
