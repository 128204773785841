import React from 'react';
import { ILabelledPropertyProps } from './LabelledProperty';
import { VariantLabelledProperty } from './VariantLabelledProperty';

interface IProps extends ILabelledPropertyProps {
	error: string;
}

export const ErrorLabelledProperty = ({ error, ...rest }: IProps) => {
	return (
		<VariantLabelledProperty
			variantX='error'
			property={error}
			{...rest}
		/>
	);
};
