import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { setFieldError } from 'framework/forms/utils/setFieldError';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormSelectAudiologistField } from './FormSelectAudiologistField';

interface IModel {
	id: string;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		id: yup.string().required(strings.formRequired(strings.audiologist)),
	});
};

interface IProps extends DialogProps {
	initialId?: string;
	confirm: (id: string, setError: (error: string) => void) => void;
	cancel: VoidFunction;
	isSubmitting: boolean;
	confirmText?: string;
}

export const SelectAudiologistForm = ({ initialId, confirm, cancel, isSubmitting, confirmText, ...rest }: IProps) => {
	const strings = useLocalization();

	const handleSubmit = (values: IModel, helpers: FormikHelpers<IModel>) => {
		confirm(values.id, (error: string) => setFieldError<IModel>('id', error, helpers));
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{
				id: initialId ?? '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.selectWhat(strings.audiologist)}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormSelectAudiologistField<IModel>
							name='id'
							label={strings.audiologist}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={confirmText ?? strings.link}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
