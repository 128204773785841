import { PreloadCacheProvider as FrameworkCacheProvider } from 'framework/context/PreloadCacheProvider';
import { annotationTypesQuery_all, annotationTypesQuery_concurrencyToken, IAnnotationType } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { PreloadContext } from '../../context/PreloadContext';
import { AnnotationTypesCacheContext } from './AnnotationTypesCacheContext';

export const CacheProvider = ({ children }: any) => {
	const { annotationTypes } = useContext(PreloadContext);

	return (
		<FrameworkCacheProvider<IAnnotationType[]>
			concurrencyF={annotationTypesQuery_concurrencyToken}
			loadF={annotationTypesQuery_all}
			context={AnnotationTypesCacheContext}
			initialData={annotationTypes}>
			{children}
		</FrameworkCacheProvider>
	);
};
