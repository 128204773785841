import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { EidApplicationContext } from 'app/externalApps/eid/EidApplicationContext';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { SuccessLabelledProperty } from 'framework/components/labelledProperty/SuccessLabelledProperty';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import React, { useContext } from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';

export const EidClientContent = ({ reloadTrigger }: IReloadTriggerProps) => {
	const { isInstalled, isInitialized, installedBuild, latestBuild, hasLatest, check, download, isDownloading } = useContext(EidApplicationContext);

	useLazyEffect(() => {
		check();
	}, [reloadTrigger]);

	return (
		<div className='df-col gap-8'>
			<TextLinkButton
				startIcon={<GetAppOutlinedIcon />}
				onClick={download}
				color='primary'
				disabled={isDownloading}>
				{`Download`}
			</TextLinkButton>
			<CaptionVerticalLabelledPropertyX label='Latest version (from server)'>
				{latestBuild ? latestBuild.toString() : 'Not loaded yet'}
			</CaptionVerticalLabelledPropertyX>
			{isInitialized && isInstalled === false && <VariantLabelledPropertyX variant='error'>{`Eid not installed`}</VariantLabelledPropertyX>}
			{isInitialized && isInstalled && (
				<>
					<CaptionVerticalLabelledPropertyX label='Geïnstalleerde versie'>{installedBuild.toString()}</CaptionVerticalLabelledPropertyX>
					{hasLatest ? (
						<SuccessLabelledProperty successMessage='Up-to-date' />
					) : (
						<VariantLabelledPropertyX variant='warning'>{`Not latest version`}</VariantLabelledPropertyX>
					)}
				</>
			)}
			{isInitialized === false && <span>{`Fetching data...`}</span>}
		</div>
	);
};
