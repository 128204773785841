import { Button, Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import BackspaceIcon from '@material-ui/icons/Backspace';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { endOfMonth, startOfMonth } from 'date-fns';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { Searchbar } from 'framework/components/search/Searchbar';
import { BrowsableDateFilterItem } from 'framework/filter/date/BrowsableDateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { agendaQuery_search, ICalendarEventsSearchQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RestrictedPage } from 'shared/layout/RestrictedPage';
import { getNow } from 'shared/utils/getNow';
import { AgendaRoute } from '../../calendarRoutes';
import { useCalendarResources } from '../../context/useLocalizedCalendarResources';
import { EventListComponent } from '../list/EventListComponent';
import { SearchEventsExportButtonMenuAndDialog } from './SearchEventsExportButtonMenuAndDialog';

const DefaultParams: ICalendarEventsSearchQueryParams = {
	afterDate: startOfMonth(getNow()),
	beforeDate: endOfMonth(getNow()),
	eventTypeIds: [],
	roomGroupKeys: [],
	searchString: '',
	userGroupKeys: [],
};

const exclusions: (keyof ICalendarEventsSearchQueryParams)[] = ['afterDate', 'beforeDate'];

export const SearchEventsPage = () => {
	const strings = useLocalization();
	const [params, setParams] = useState<ICalendarEventsSearchQueryParams>(DefaultParams);
	const [events, reload] = useApiEffect(agendaQuery_search, params);
	const { rooms, users, eventTypes } = useCalendarResources();
	const theme = useTheme();
	const { push } = useHistory();

	if (events === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPage
			pageClaim='Calendar'
			breadcrumbs={[
				{ text: strings.calendar, icon: <TodayOutlinedIcon /> },
				{ text: strings.agenda, route: AgendaRoute },
			]}
			breadCrumbsContainerStyle={{ width: '277px' }}
			title={strings.search}
			reload={reload}
			header={
				<div className='df-row-ac jc-e'>
					<Button
						variant='outlined'
						color='primary'
						startIcon={<BackspaceIcon />}
						onClick={() => push(AgendaRoute)}>
						{strings.closeSearch}
					</Button>
				</div>
			}>
			<Card className='fg1'>
				<div
					className='df-row-ac jc-sb'
					style={{ paddingRight: theme.spacing(1.5) }}>
					<Searchbar
						value={params.searchString}
						onSearch={text => setParams({ ...params, searchString: text })}
						placeholder={strings.searchVerb}
						style={{ width: '400px' }}
						variant='embedded'
					/>
					<SearchEventsExportButtonMenuAndDialog events={events} />
				</div>
				<Divider />
				<div className='df-row jc-sb'>
					<FilterBar
						isFiltered={isFiltered(params, exclusions)}
						onClear={() => setParams(clearParams(params, exclusions))}
						paddingLeft={theme.spacing(1.5)}>
						{rooms && (
							<ListFilterComponent<string>
								options={rooms}
								selected={params.roomGroupKeys}
								setSelected={vals => setParams({ ...params, roomGroupKeys: vals })}
								label={strings.room}
								icon={<HelpOutlineIcon />}
							/>
						)}
						{users && (
							<ListFilterComponent<string>
								options={users}
								selected={params.userGroupKeys}
								setSelected={vals => setParams({ ...params, userGroupKeys: vals })}
								label={strings.user}
								icon={<HelpOutlineIcon />}
							/>
						)}
						{eventTypes && (
							<ListFilterComponent<string>
								options={eventTypes}
								selected={params.eventTypeIds}
								setSelected={vals => setParams({ ...params, eventTypeIds: vals })}
								label={strings.type}
								icon={<HelpOutlineIcon />}
							/>
						)}
					</FilterBar>
				</div>
				<BrowsableDateFilterItem
					dateRangeType='month'
					after={params.afterDate}
					before={params.beforeDate}
					setSelected={(after, before) => setParams({ ...params, afterDate: after, beforeDate: before })}
					style={{ padding: 10 }}
				/>
				{events.length > 0 && (
					<>
						<Divider />
						<CardMedia style={{ paddingLeft: 12, paddingRight: 12 }}>
							<EventListComponent
								afterDate={params.afterDate}
								beforeDate={params.beforeDate}
								events={events}
								reload={reload}
								colorScheme='types'
							/>
						</CardMedia>
					</>
				)}
				{events?.length === 0 && <NoRecordsFound style={{ padding: theme.spacing(1.5) }}>{strings.nothingFoundCommaChangeSearchParams}</NoRecordsFound>}
			</Card>
		</RestrictedPage>
	);
};
