import React from 'react';
import { IQuickFilterContext } from 'framework/IQuickFilterContext';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { CommissionExportProp, ICommission } from 'gen/ApiClient';
import { SelectableColumn } from '../SelectableColumn';
import { QuickFilter } from '../QuickFilter';

interface IViewContext extends ITableViewContext<SelectableColumn, ICommission>, IQuickFilterContext<QuickFilter> {
	exportRecord: Record<CommissionExportProp, string>;
}

export const ViewContext = React.createContext<IViewContext>(undefined as any);
