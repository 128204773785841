import { StatCardX } from 'framework/components/cards/StatCardX';
import { ICustomerAccountLineQueryMetadata, ISettlementProposalQueryMetadata } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const Width: number = 350;

interface IProps {
	qrUnhandledMetadata: ICustomerAccountLineQueryMetadata;
	qrSpsMetadata: ISettlementProposalQueryMetadata;
	style?: React.CSSProperties;
}

export const SettlementProposalsPageQuickStats = ({ qrUnhandledMetadata, qrSpsMetadata, style }: IProps) => {
	const strings = useLocalization();

	return (
		<div
			className='df-row-ac gap-8'
			style={style}>
			<StatCardX
				style={{ width: Width }}
				caption={`# ${strings.refundsToProcess.toLowerCase()}`}
				title={strings.verzamelstaten}>
				{`# ${qrSpsMetadata.countUnhandled}`}
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				caption={`# ${strings.total} | ${strings.lastMonth} | ${strings.thisMonth}`}
				title={strings.refundsToProcess}>
				<span
					style={{
						fontSize: 24,
					}}>{`# ${qrUnhandledMetadata.unfilteredCount} | ${qrUnhandledMetadata.countPreviousMonth} | ${qrUnhandledMetadata.countThisMonth}`}</span>
			</StatCardX>
		</div>
	);
};
