import { Divider } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { DefaultTypesAndCategoriesProductCatalogQueryParams } from 'app/main/inventoryManagement/productCatalog/search/DefaultTypesAndCategoriesProductCatalogQueryParams';
import { SearchProductCatalogDialog } from 'app/main/inventoryManagement/productCatalog/search/SearchProductCatalogDialog';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { IDraggableItem } from 'framework/components/dnd/IDraggableItem';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { LocalStateTextField } from 'framework/components/textFields/LocalStateTextField';
import { updateArray } from 'framework/utils/array/updateArray';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { ITypesAndCategoriesProductCatalogQueryParams, productCatalogQuery_queryNonDeleted } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SimplePriceForm } from 'shared/searchAndSelect/SimplePriceForm';
import { LineDescriptionAndQuantity } from 'shared/searchAndSelect/lines/LineDescriptionAndQuantity';
import { LineXComponent } from 'shared/searchAndSelect/lines/LineXComponent';
import { IAllowanceChargeFormLine } from './IAllowanceChargeFormLine';
import { IPurchaseFromUblFormLine } from './IPurchaseFromUblFormLine';
import { PurchaseAllowanceChargeLineComponent } from './PurchaseAllowanceChargeLineComponent';
import { PurchaseFromUblLineLinkStatusComponent } from './PurchaseFromUblLineLinkStatusComponent';
import { ReferencePriceSpan } from './ReferencePriceSpan';
import { calculateLinePrice, mapProductCatalogItemToAllowanceChargeFormLine } from './utils';

interface IProps extends IDraggableItem {
	line: IPurchaseFromUblFormLine;
	setLine: (val: IPurchaseFromUblFormLine) => void;
	index: number;
	onDelete: VoidFunction;
	onLink: VoidFunction;
	onIgnore: VoidFunction;
	onLinkAllowanceCharge: (line: IAllowanceChargeFormLine) => void;
	onIgnoreAllowanceCharge: (line: IAllowanceChargeFormLine) => void;
}

export const PurchaseFromUblLineComponent = ({ line, setLine, onLink, onIgnore, onLinkAllowanceCharge, onIgnoreAllowanceCharge, ...rest }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext();
	const strings = useLocalization();
	const mustFillInSerialNumber = useMemo(() => Boolean(line.productCatalogItem && line.productCatalogItem.isUniquelyIdentifiable), [line]);
	const disableQuantity = useMemo(() => Boolean(line.inventoryItemId || line.productCatalogItem?.isUniquelyIdentifiable), [line]);
	const linePrice = useMemo(() => calculateLinePrice(line), [line]);

	const onEditPrice = () => {
		open(
			<SimplePriceForm
				open
				unitPrice={line.unitPrice}
				forPurchase
				confirm={val => {
					confirm();
					setLine({ ...line, unitPrice: val });
				}}
				cancel={cancel}
			/>
		);
	};

	const onAddAllowanceCharge = () => {
		open(
			<SearchProductCatalogDialog<ITypesAndCategoriesProductCatalogQueryParams>
				open
				filterType='all'
				confirm={pc => {
					setLine({ ...line, allowanceCharges: [...line.allowanceCharges, mapProductCatalogItemToAllowanceChargeFormLine(pc)] });
					confirm();
				}}
				cancel={cancel}
				defaultParams={DefaultTypesAndCategoriesProductCatalogQueryParams}
				queryF={productCatalogQuery_queryNonDeleted}
			/>
		);
	};

	return (
		<LineXComponent
			{...rest}
			errors={line.error}
			line={line}
			setLine={setLine}>
			{line.isCreditLine && <VariantLabelledPropertyX variant='info'>{strings.creditVerb}</VariantLabelledPropertyX>}
			<PurchaseFromUblLineLinkStatusComponent
				line={line}
				setLine={setLine}
				onLink={onLink}
				onIgnore={onIgnore}
			/>
			<LineDescriptionAndQuantity<IPurchaseFromUblFormLine>
				line={line}
				setLine={setLine}
				disableQuantity={disableQuantity}
				mode={line.isCreditLine ? 'negative' : 'positive'}
			/>
			{mustFillInSerialNumber && (
				<LocalStateTextField
					variant='outlined'
					size='small'
					label={strings.serialNumber}
					value={line.serialNumber}
					style={{ marginTop: 8, width: 'fit-content' }}
					onChange={e => setLine({ ...line, serialNumber: e.target.value })}
					error={isNullOrUndefined(line.serialNumber)}
					helperText={isNullOrUndefined(line.serialNumber) ? strings.enterSerialNumber : undefined}
				/>
			)}
			<div
				className='df-row'
				style={{ marginTop: 4 }}>
				<div className='fg1'></div>
				<LabelledPropertyX
					edit={onEditPrice}
					editPosition='start'>
					<div className='df-row-ab gap-4'>
						{line.productCatalogItem !== undefined && line.productCatalogItem !== null && line.ublData !== undefined && (
							<ReferencePriceSpan
								unitPrice={line.unitPrice}
								productCatalogItem={line.productCatalogItem}
							/>
						)}
						<div
							style={{
								textAlign: 'right',
								cursor: 'pointer',
							}}>
							{`${line.quantity} x ${formatCurrency(line.unitPrice)}`}
						</div>
					</div>
				</LabelledPropertyX>
			</div>
			<div className='df-row'>
				<div className='fg1'></div>
				<span>{`${strings.vat}: ${line.vatPercentage}%`}</span>
			</div>
			<Divider style={{ marginTop: 4, marginBottom: 4 }} />
			<div className='df-col fg1'>
				<div className='df-row-ac gap-4'>
					<span style={{ fontWeight: 'bold', fontSize: 12 }}>{strings.supplements}</span>
					<ColorOnHoverIconButton
						size='small'
						color='primary'
						onClick={onAddAllowanceCharge}>
						<AddCircleOutlineOutlinedIcon fontSize='small' />
					</ColorOnHoverIconButton>
				</div>
				{line.allowanceCharges.map((t, index) => (
					<PurchaseAllowanceChargeLineComponent
						key={t.id}
						index={index + 1}
						line={t}
						setLine={val => setLine({ ...line, allowanceCharges: updateArray(t, line.allowanceCharges, val) })}
						onDelete={() => setLine({ ...line, allowanceCharges: line.allowanceCharges.filter(x => x !== t) })}
						quantity={line.quantity}
						onLink={() => onLinkAllowanceCharge(t)}
						onIgnore={() => onIgnoreAllowanceCharge(t)}
					/>
				))}
			</div>
			{line.allowanceCharges.length > 0 && <Divider style={{ marginTop: 4, marginBottom: 4 }} />}
			<div className='df-row fg1'>
				<div className='fg1'></div>
				<div
					style={{
						fontWeight: 'bold',
						textAlign: 'right',
					}}>
					{`${strings.subtotal}: ${formatCurrency(linePrice)}`}
				</div>
			</div>
		</LineXComponent>
	);
};
