import { StepLabelProps, StepLabel } from '@material-ui/core';
import React from 'react';

interface IProps extends StepLabelProps {
	warning?: boolean;
}

export const StepLabelWithWarning = ({ warning, ...rest }: IProps) => {
	return (
		<StepLabel
			{...rest}
			error={rest.error || warning}
			className={warning === true ? 'step-warning' : ''}
		/>
	);
};
