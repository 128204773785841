import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useMemo } from 'react';
import { localizeApkLabel } from '../utils/localizeApkLabel';
import { ArticlePropertyKeysCacheContext } from './ArticlePropertyKeysCacheContext';
import { ILocalizedArticlePropertyKey } from './ILocalizedArticlePropertyKey';

export const useLocalizedArticlePropertyKeys = (): ILocalizedArticlePropertyKey[] => {
	const [data] = usePreloadCacheContext(ArticlePropertyKeysCacheContext);
	const language = useCurrentLanguage();
	const localizedKeys = useMemo<ILocalizedArticlePropertyKey[]>(() => data.map(t => ({ key: t.key!, label: localizeApkLabel(t, language) })), [data, language]);
	return localizedKeys;
};
