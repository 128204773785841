import { Dialog, DialogActions, DialogProps, Divider, Grid, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import LinkIcon from '@material-ui/icons/Link';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { StethoscopeIcon } from 'framework/components/icons/StethoscopeIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { MedicalTreatmentNomenclaturesCacheContext } from '../context/MedicalTreatmentNomenclaturesCacheContext';
import { HistoryTable } from './HistoryTable';

interface IProps extends DialogProps {
	code: string;
	select?: () => void | Promise<void>;
	cancel: VoidFunction;
	clear?: () => Promise<void>;
}

export const MedicalTreatmentNomenclatureDialog = ({ code, select, cancel, clear, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const language = useCurrentLanguage();
	const [data] = useCacheContext(MedicalTreatmentNomenclaturesCacheContext);
	const item = useMemo(() => data?.find(t => t.data.code === code), [data, code]);
	const [isSubmittingX, setIsSubmitting] = useState<boolean>(false);

	if (item === undefined) {
		return <div></div>;
	}

	const onClear = async () => {
		if (clear) {
			setIsSubmitting(true);
			await clear();
			setIsSubmitting(false);
		}
	};

	const onSelect = async () => {
		if (select) {
			setIsSubmitting(true);
			await select();
			setIsSubmitting(false);
		}
	};

	return (
		<Dialog
			{...rest}
			onClose={cancel}
			fullWidth
			maxWidth='md'>
			<DialogContentGrid close={cancel}>
				<DialogGridTitle icon={<StethoscopeIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{strings.detail} {strings.medicalTreatmentNomenclature} {item.data.code}
					</Typography>
					{item.isPseudoCode && (
						<LabelledProperty
							label=''
							property={strings.pseudoNomenclatureCode}
							withoutMargin
						/>
					)}
					<LabelledProperty
						label={strings.validFrom}
						property={formatDate(item.data.validityStart)}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.validTo}
						property={item.data.hasValidityEnd ? formatDate(item.data.validityEnd) : strings.noEndDate}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.scope}
						property={item.data.isAmbulant ? strings.ambulatory : strings.hospitalized}
						withoutMargin
					/>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.details} />
				<PropertyInGrid
					label={strings.number}
					value={item.data.code}
				/>
				<PropertyInGrid
					label={strings.description}
					value={language === 'fr' ? item.data.descriptionFr : item.data.descriptionNl}
				/>
				<PropertyInGrid
					label={strings.subArticle}
					value={language === 'fr' ? item.data.subArticleDescriptionFr : item.data.subArticleDescriptionNl}
				/>
				{item.isPseudoCode === false && (
					<>
						<PropertyInGrid
							label={strings.currentPrice}
							value={formatCurrency(item.data.currentFeeValue)}
						/>
						<PropertyInGrid
							label={strings.currentFeeValue}
							value={formatCurrency(item.data.currentFeeContribution)}
						/>
						<PropertyInGrid
							label={strings.currentFeePersonalShare}
							value={formatCurrency(item.data.currentFeePersonalShare)}
						/>
						<GridDividerWithCaption caption={strings.history} />
						<Grid
							item
							xs={12}>
							<HistoryTable fees={item.data.fees} />
						</Grid>
					</>
				)}
			</DialogContentGrid>
			{(select !== undefined || clear !== undefined) && (
				<>
					<Divider style={{ marginLeft: theme.spacing(4), marginRight: theme.spacing(4) }} />
					<DialogActions>
						{select && (
							<LoaderButton
								variant='text'
								color='primary'
								startIcon={<LinkIcon />}
								isLoading={isSubmittingX}
								onClick={onSelect}>
								{strings.selectThis}
							</LoaderButton>
						)}
						{clear && (
							<LoaderButton
								variant='text'
								color='secondary'
								startIcon={<DeleteOutlineIcon />}
								isLoading={isSubmittingX}
								onClick={onClear}>
								{strings.deleteFromRequest}
							</LoaderButton>
						)}
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};
