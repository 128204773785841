import { QuantityField } from 'framework/components/textFields/QuantityField';
import React from 'react';
import { EditableDescription } from './EditableDescription';
import { PositiveOrNegativeType } from './PositiveOrNegativeType';

interface ILine {
	quantity: number;
	description: string;
}

interface IProps<TLine extends ILine> {
	line: TLine;
	setLine: (val: TLine) => void;
	style?: React.CSSProperties;
	disableQuantity?: boolean;
	mode?: PositiveOrNegativeType;
}

export const LineDescriptionAndQuantity = <TLine extends ILine>({ line, setLine, style, disableQuantity, mode }: IProps<TLine>) => {
	return (
		<div
			className='df-row-ac'
			style={style}>
			<EditableDescription
				text={line.description}
				setText={val => setLine({ ...line, description: val })}
				style={{ marginRight: 8 }}
			/>
			<QuantityField
				value={line.quantity}
				setValue={val => setLine({ ...line, quantity: val })}
				disabled={disableQuantity}
				mode={mode}
			/>
		</div>
	);
};
