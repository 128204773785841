import React, { useState } from 'react';
import { useSnackbarNotify } from '../hooks/useSnackbarNotify';
import { ApiContext } from './ApiContext';
import { IApiErrorStrings } from '../localization/apiErrors/IApiErrorString';

interface IProps {
	children: any;
	strings: IApiErrorStrings;
	onRedirect: VoidFunction;
	
}

export const ApiProvider = ({ children, strings, onRedirect,}: IProps) => {
	const notify = useSnackbarNotify();
	const [mustUpdateFrontEnd,setMustUpdateFrontEnd] = useState<boolean>(false);
	return (
		<ApiContext.Provider
			value={{
				strings: strings,
				notify: notify,
				redirect: onRedirect,
				mustUpdateFrontEnd: mustUpdateFrontEnd, 
				setMustUpdateFrontEnd: setMustUpdateFrontEnd,
			}}>
			{children}
		</ApiContext.Provider>
	);
};
