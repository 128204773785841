import { RepairProcessStep, RepairProcessSteps } from 'gen/ApiClient';
import React, { useContext, useMemo } from 'react';
import { StepsField } from 'shared/process/StepsField';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	isForPatient: boolean;
}

export const FormRepairProcessStepsField = <TModel extends { steps: string[] }>({ isForPatient }: IProps) => {
	const { repairProcessStepRecord } = useContext(RecordContext);
	const steps = useMemo(() => (isForPatient ? RepairProcessSteps : RepairProcessSteps.filter(t => t !== 'DeliverToPatient')), [isForPatient]);

	return (
		<StepsField<TModel, RepairProcessStep>
			steps={steps}
			name='steps'
			isOptional={t => t === 'DeliverToPatient'}
			record={repairProcessStepRecord}
		/>
	);
};
