import { Card, CardContent } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { CustomerAccountType, ICustomerAccount } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { CompanyDialog } from '../../../contacts/companies/CompanyDialog';
import { HifDetailDialog } from '../../../contacts/hifs/HifDetailDialog';

interface IProps {
	item: ICustomerAccount;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const DetailsCard = ({ item, style, reload }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const { open, cancel } = useDialogsContext();
	const { customerAccountTypeRecord } = useContext(RecordContext);

	const onViewDetails = () => {
		const type = item.accountType as CustomerAccountType;
		if (type === 'Patient') {
			push(calcPatientDetailRoute(item.link.idAsGuid ?? ''));
		} else if (type === 'HealthInsuranceFund') {
			open(
				<HifDetailDialog
					open
					close={cancel}
					code={item.accountLinkIdAsString!}
				/>
			);
			// toon overzicht maandelijkse verzamelstaten
		} else if (type === 'Company' && item.link.idAsGuid) {
			open(
				<CompanyDialog
					id={item.link.idAsGuid}
					open
					close={cancel}
				/>
			);
			// must be a company
			// show dialog of company
		}
	};

	return (
		<Card
			style={style}
			className='df-col'>
			<CardContent>
				<CardTitleTypography title={strings.general} />
				<LabelledProperty
					label={strings.date}
					property={formatDate(item.created)}
				/>
				<LabelledProperty
					label={`${strings.type} ${strings.customer}`}
					property={customerAccountTypeRecord[item.accountType as CustomerAccountType]}
				/>
				<LabelledProperty
					label={customerAccountTypeRecord[item.accountType as CustomerAccountType]}
					property={item.identifier}
					navigate={onViewDetails}
				/>
				<CardTitleTypography
					title={strings.overview}
					withPaddingTop
				/>
				<LabelledProperty
					label={strings.total}
					property={formatCurrency(item.value)}
				/>
				{item.amountPaid > 0 && (
					<LabelledProperty
						label={strings.alreadyPaid}
						property={formatCurrency(item.amountPaid)}
					/>
				)}
				{item.hasOutstandingBalance && (
					<LabelledProperty
						label={strings.outstandingBalance}
						property={formatCurrency(item.outstandingBalance)}
					/>
				)}
				{item.hasOutstandingBalance && (
					<LabelledProperty
						label={strings.saldoSettlementProposalsShort}
						property={formatCurrency(item.outstandingBalanceOnSettlementProposals)}
					/>
				)}
			</CardContent>
		</Card>
	);
};
