import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LinkIcon from '@material-ui/icons/Link';
import React, { useCallback, useMemo } from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { formatDate } from 'framework/utils/date/formatDate';
import { noahDeviceInventoryItemMapsQuery_readOut, noahDeviceInventoryItemMapsQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ImportDeviceForm } from '../forms/ImportDeviceForm';
import { NoahDeviceImportedStatusChip } from '../NoahDeviceImportedStatusChip';
import { UsagesTimeLine } from './UsagesTimeLine';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useHistory } from 'react-router-dom';
import { calcInventoryItemDetailRoute } from 'app/main/inventoryManagement/routes';

interface IProps extends DialogProps {
	close: VoidFunction;
	id: string;
	hideNavigateToInventoryItem?: boolean;
}

export const NoahDeviceDialog = ({ id, close, hideNavigateToInventoryItem, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [dto, reloadItem] = useApiEffect(noahDeviceInventoryItemMapsQuery_single, id);
	const item = useMemo(() => dto?.data, [dto]);
	const [readOut, reloadReadOut] = useApiEffect(noahDeviceInventoryItemMapsQuery_readOut, id);
	const reload = useCallback(() => {
		reloadItem();
		reloadReadOut();
		// eslint-disable-next-line
	}, []);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);
	const { push } = useHistory();

	if (item === undefined || readOut === undefined) {
		return <div></div>;
	}

	const onSelect = () => {
		open(
			<ImportDeviceForm
				id={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onViewInventoryItem = () => {
		if (dto && dto.data && dto.data.inventoryItemId) {
			push(calcInventoryItemDetailRoute(dto.data.inventoryItemId));
			close();
		}
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<HelpOutlineIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{item.serialNumber}
					</Typography>
					<NoahDeviceImportedStatusChip
						item={item}
						style={{ marginBottom: theme.spacing(1) }}
					/>
					<LabelledProperty
						label={`${strings.manufacturer} (${strings.lastReadOut})`}
						property={item.manufacturer}
						withoutMargin
					/>
					<LabelledProperty
						label={`${strings.model} (${strings.lastReadOut})`}
						property={item.instrumentTypeName}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.lastUsageDate}
						property={formatDate(item.lastUsageDate)}
						withoutMargin
					/>
				</DialogGridTitle>
				{hideNavigateToInventoryItem !== true && (
					<>
						<GridDividerWithCaption caption={strings.actions} />
						<PropertyInGrid
							value={
								<div className='df-col-as'>
									{item.isLinkedToInventoryItem === false && (
										<SmallPrimaryTextButton
											onClick={onSelect}
											startIcon={<LinkIcon />}>
											{strings.importThisDeviceInStock}
										</SmallPrimaryTextButton>
									)}
									{item.isLinkedToInventoryItem && (
										<SmallPrimaryTextButton
											onClick={onViewInventoryItem}
											startIcon={<VisibilityOutlinedIcon />}>
											{strings.viewLinkedInventoryItem}
										</SmallPrimaryTextButton>
									)}
								</div>
							}
						/>
					</>
				)}
				<GridDividerWithCaption caption={strings.history} />
				<UsagesTimeLine
					style={{ marginLeft: '-144px' }}
					usages={item.orderedUsages}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
