import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { ISubscriptionSummary } from 'gen/ApiClient';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (colRecord: Record<SelectableColumn, string>): Record<SelectableColumn, IHeader<ISubscriptionSummary>> => {
	return {
		pos: createSortableHeaderWithKey<ISubscriptionSummary, SelectableColumn>(colRecord, 'pos', t => `${t.pos}`),
		key: createSortableHeaderWithKey<ISubscriptionSummary, SelectableColumn>(colRecord, 'key', t => t.key),
		backlog: createSortableHeaderWithKey<ISubscriptionSummary, SelectableColumn>(colRecord, 'backlog', t => `${t.backlog}`),
	};
};
