import { Typography, useTheme } from '@material-ui/core';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { useUserHasPageClaim } from 'claims/useUserHasPageClaim';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { IRosaPatientRecord, rosaPatientsQuery_singleRedirect } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PatientsRoute } from 'routes';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ImportRosaPatientRecordDialog } from './import/ImportRosaPatientRecordDialog';

export const RosaPatientRedirectToHamsPatientPage = () => {
	const { push } = useHistory();
	const { id } = useParams<IRouteParamsWithId>();
	const [data] = useApiEffect(rosaPatientsQuery_singleRedirect, id);
	const { open, confirm, cancel } = useDialogsContext();
	const strings = useLocalization();
	const theme = useTheme();
	const hasAccess = useUserHasPageClaim('Patients');

	useEffect(() => {
		if (data && data.isLinkedToHamsPatient) {
			push(calcPatientDetailRoute(data.hamsPatientId!));
		} else if (data && data.exists === true) {
			onImportRecordQuestion(data.data);
			// do import!
		}
		// eslint-disable-next-line
	}, [data]);

	const onImportRecordQuestion = (record: IRosaPatientRecord) => {
		if (hasAccess === false) {
			push(PatientsRoute);
		} else {
			open(
				<AlertDialog
					open
					title={strings.importRosaPatient}
					content={strings.thisPatientDoesNotExistInHAMSDoYouWantToImportNow}
					acceptText={strings.yesWhatExclamation(strings.continue)}
					rejectText={strings.cancel}
					reject={() => {
						cancel();
						push(PatientsRoute);
					}}
					accept={() => {
						confirm();
						onImport(record);
					}}
				/>
			);
		}
	};

	const onImport = (record: IRosaPatientRecord) => {
		open(
			<ImportRosaPatientRecordDialog
				open
				item={record}
				confirm={id => {
					confirm();
					push(calcPatientDetailRoute(id));
				}}
				cancel={() => {
					cancel();
					push(PatientsRoute);
				}}
			/>
		);
	};

	if (data === undefined) {
		return <></>;
	}

	if (data !== undefined && data.exists === true) {
		return <></>;
	}

	return (
		<div className='df-col-ac stretch-ver jc-c'>
			<StatusIcon
				status='error'
				style={{ fontSize: '120px', marginBottom: 20 }}
			/>
			<div style={{ fontSize: '34px', color: theme.palette.error.main, textTransform: 'uppercase', marginBottom: 10 }}>{strings.rosaRecordNotFound}</div>
			<Typography style={{ color: theme.palette.text.primary, whiteSpace: 'pre-wrap', textAlign: 'center' }}>
				{strings.rosaRecordNotFoundErrorDescription}
			</Typography>
		</div>
	);
};
