import { ISalesSummary } from 'gen/ApiClient';
import React from 'react';
import { SalesListItemX } from './SalesListItemX';

interface IProps {
	item: ISalesSummary;
	reload: VoidFunction;
	hideAction?: boolean;
}

export const SalesSummaryListItem = ({ item, reload, hideAction = false }: IProps) => {
	return (
		<SalesListItemX
			item={item}
			// salesId={item.id}
			// salesType={item.type as SalesType}
			// isFullyPaid={item.isFullyPaid}
			// date={item.date}
			// reference={item.reference!}
			hideAction={hideAction}
			reload={reload}
		/>
	);
};
