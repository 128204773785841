import React, { useContext } from 'react';
import { PreloadCacheProvider as FrameworkCacheProvider } from 'framework/context/PreloadCacheProvider';
import { generalPractitionersQuery_all, generalPractitionersQuery_concurrencyToken, IGeneralPractitionerWithOfficesSummary } from 'gen/ApiClient';
import { GeneralPractitionersCacheContext } from './GeneralPractitionersCacheContext';
import { PreloadContext } from 'shared/context/PreloadContext';

export const GeneralPractitionersCacheProvider = ({ children }: any) => {
	const { generalPractitioners } = useContext(PreloadContext);

	return (
		<FrameworkCacheProvider<IGeneralPractitionerWithOfficesSummary[]>
			initialData={generalPractitioners}
			concurrencyF={generalPractitionersQuery_concurrencyToken}
			loadF={generalPractitionersQuery_all}
			context={GeneralPractitionersCacheContext}>
			{children}
		</FrameworkCacheProvider>
	);
};
