import { IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { AddSmallPrimaryTextButton } from 'framework/components/buttons/AddSmallPrimaryTextButton';
import { CollapseX } from 'framework/components/CollapseX';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { calendarEventTypesCommand_delete, ICalendarEventType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { formatTimeModel } from 'shared/timeUnit/formatTimeModel';
import { CalendarEventTypesCacheContext } from '../../context/CalendarEventTypesCacheContext';
import { EventTypesColorPickerWrapper } from './EventTypesColorPickerWrapper';
import { CalendarEventTypeForm } from './CalendarEventTypeForm';

export const TypesList = () => {
	const strings = useLocalization();
	const theme = useTheme();
	const [types, load] = usePreloadCacheContext(CalendarEventTypesCacheContext);
	const { open, confirm, cancel } = useDialogsContext(load);
	const orderedTypes = useMemo(() => _.orderBy(types, t => t.identifier), [types]);
	const first3 = useMemo(() => (orderedTypes ?? []).slice(0, 3), [orderedTypes]);
	const other = useMemo(() => (orderedTypes ?? []).slice(3, orderedTypes?.length ?? 0), [orderedTypes]);

	const onNew = () => {
		open(
			<CalendarEventTypeForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDelete = (id: string) => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhat(strings.typeAppointment)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => calendarEventTypesCommand_delete(id)}
				accept={confirm}
			/>
		);
	};

	const onEdit = (val: ICalendarEventType) => {
		open(
			<CalendarEventTypeForm
				open
				confirm={confirm}
				cancel={cancel}
				calendarEventType={val}
			/>
		);
	};

	return (
		<>
			{types && (
				<div style={{ marginBottom: theme.spacing(4) }}>
					<DividerWithCaption
						caption={strings.typesAppointments}
						captionStyle={{ fontWeight: 500, color: 'black' }}
						action={<AddSmallPrimaryTextButton onClick={onNew}>{strings.add}</AddSmallPrimaryTextButton>}
					/>
					{types.length !== 0 && (
						<>
							<List
								dense
								disablePadding>
								{first3.map(t => (
									<SingleItem
										key={t.id}
										t={t}
										load={load}
										onEdit={() => onEdit(t)}
										onDelete={() => onDelete(t.id)}
									/>
								))}
								{other.length > 0 && (
									<CollapseX style={{ marginLeft: 10 }}>
										{other.map(t => (
											<SingleItem
												key={t.id}
												t={t}
												load={load}
												onEdit={() => onEdit(t)}
												onDelete={() => onDelete(t.id)}
											/>
										))}
									</CollapseX>
								)}
							</List>
						</>
					)}
					{types.length === 0 && <NoRecordsFound>{strings.noTypesAppointmentsDefined}</NoRecordsFound>}
				</div>
			)}
		</>
	);
};

interface ISingleItemProps {
	t: ICalendarEventType;
	load: VoidFunction;
	onEdit: VoidFunction;
	onDelete: VoidFunction;
}

const SingleItem = ({ t, load, onEdit, onDelete }: ISingleItemProps) => {
	const strings = useLocalization();
	const { timeUnitRecord } = useContext(RecordContext);

	return (
		<ListItem disableGutters>
			<ListItemIcon style={{ paddingLeft: '11px' }}>
				<EventTypesColorPickerWrapper
					eventType={t}
					reload={load}
				/>
			</ListItemIcon>
			<ListItemText
				primary={
					<LabelledProperty
						withoutMargin
						label={strings.name}
						property={t.name}
					/>
				}
				secondary={
					<LabelledProperty
						withoutMargin
						label={strings.defaultDuration}
						property={formatTimeModel(t.defaultDuration, timeUnitRecord)}
					/>
				}
			/>
			<ListItemSecondaryAction>
				<IconButton
					onClick={onEdit}
					edge='end'>
					<EditOutlinedIcon />
				</IconButton>
				<IconButton
					onClick={onDelete}
					edge='end'>
					<DeleteOutlineIcon />
				</IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	);
};
