import { useTheme } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { ILocation } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: ILocation;
	withoutBorder?: boolean;
	isSelected: boolean;
	toggle: VoidFunction;
}

export const LocationListItem = ({ item, withoutBorder, isSelected, toggle }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const color = useMemo<ColorX>(() => (item.isDeleted ? 'error' : 'default'), [item]);
	const tColor = useThemeColor(color);

	return (
		<div
			className='df-row w100'
			onClick={toggle}
			style={{
				borderRight: isSelected ? `6px solid ${theme.palette.primary.main}` : 'unset',
				cursor: 'pointer',
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				paddingTop: 12,
				paddingBottom: 12,
				paddingRight: 12,
				borderRadius: 12,
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				{item.isDeleted === false && <BlurCircularIcon style={{ fontSize: 22, color: theme.palette.success.main }} />}
				{item.isDeleted === true && <BlockIcon style={{ fontSize: 22, color: theme.palette.error.main }} />}
			</div>
			<div className='df-col fg1 gap-2'>
				<span style={{ fontSize: MajorFontSize }}>{item.name}</span>
				<div className='df-row-ac gap-4'>
					{item.isDeleted && (
						<ChipX
							label={strings.deleted}
							color='error'
						/>
					)}
					<ChipX
						label={item.isPlannable ? strings.plannable : strings.notPlannable}
						color='primary'
					/>
				</div>
				<span style={{ fontSize: MinorFontSize }}>{item.addressFormatted}</span>
				<span style={{ fontSize: MinorFontSize }}>{`# ${item.countRooms} ${strings.rooms}`}</span>
			</div>
		</div>
	);
};
