import { IExactOnlineSale } from 'gen/ApiClient';

export function exactOnlineSaleToColorX(item: IExactOnlineSale) {
	if (item.isIgnored) {
		return 'default';
	} else if (item.isSynced === false) {
		return 'error';
	} else {
		return 'success';
	}
}
