import { useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { useLoggedInUserIsMinimalInRole } from 'app/auth/useLoggedInUserIsMinimalInRole';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { ILocation, IRoomData, locationsCommand_delete, locationsCommand_deleteRoom, locationsCommand_updateRoom } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { LocationAddressForm } from './forms/LocationAddressForm';
import { LocationEmailForm } from './forms/LocationEmailForm';
import { LocationNameForm } from './forms/LocationNameForm';
import { LocationPhoneForm } from './forms/LocationPhoneForm';
import { LocationToggleIsPlannableDialog } from './forms/LocationToggleIsPlannableDialog';
import { RoomForm } from './forms/RoomForm';

interface IProps {
	item: ILocation;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const LocationDetailContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const canEdit = useLoggedInUserIsMinimalInRole('Admin');

	const onAddRoom = () => {
		open(
			<RoomForm
				open
				locationId={item.id}
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	const onTogglePlannable = () => {
		if (canEdit) {
			open(
				<LocationToggleIsPlannableDialog
					open
					confirm={confirm}
					cancel={cancel}
					location={item}
				/>
			);
		}
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title={strings.deleteLocationQuestion}
				content={strings.locationWillBeDeleted}
				acceptText={strings.yesWhatExclamation(strings.deleteLocation)}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => locationsCommand_delete({ locationId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onDeleteRoom = (room: IRoomData) => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(`${strings.room}: ${room.name}`)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => locationsCommand_deleteRoom(item.id, room.id)}
				accept={confirm}
			/>
		);
	};

	const onEditRoom = (room: IRoomData) => {
		open(
			<StringModelForm
				open
				initialValue={room.name}
				confirm={confirm}
				cancel={cancel}
				submitFunction={name => locationsCommand_updateRoom(item.id, room.id, name)}
				formTitle={strings.changeWhat(strings.name)}
				submitText={strings.update}
				label={strings.name}
			/>
		);
	};

	const onChangeName = () => {
		open(
			<LocationNameForm
				open
				confirm={confirm}
				cancel={cancel}
				location={item}
			/>
		);
	};

	const onChangePhone = () => {
		open(
			<LocationPhoneForm
				open
				confirm={confirm}
				cancel={cancel}
				location={item}
			/>
		);
	};

	const onChangeEmail = () => {
		open(
			<LocationEmailForm
				open
				confirm={confirm}
				cancel={cancel}
				location={item}
			/>
		);
	};

	const onChangeAddress = () => {
		open(
			<LocationAddressForm
				open
				confirm={confirm}
				cancel={cancel}
				location={item}
			/>
		);
	};
	return (
		<div
			className='df-col gap-8'
			style={{
				paddingLeft: 12,
				borderLeft: `6px solid ${theme.palette.primary.main}`,
				borderRadius: 12,
				...style,
			}}>
			<Typography16pxBold>{`${strings.details} | ${item.name}`}</Typography16pxBold>
			<CaptionVerticalLabelledPropertyX
				label={strings.name}
				edit={onChangeName}>
				{item.name}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.address}
				edit={onChangeAddress}>
				<StringValueOrNotApplicable>{item.addressFormatted}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.phone}
				edit={onChangePhone}>
				<StringValueOrNotApplicable>{item.phone}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.email}
				edit={onChangeEmail}>
				<StringValueOrNotApplicable>{item.email}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={`${strings.plannable}?`}
				edit={onTogglePlannable}>
				{item.isPlannable ? strings.plannable : strings.notPlannable}
			</CaptionVerticalLabelledPropertyX>
			<Typography16pxBold>{strings.rooms}</Typography16pxBold>
			<TextLinkButton
				startIcon={<AddIcon />}
				onClick={onAddRoom}>
				{strings.addWhat(strings.room)}
			</TextLinkButton>
			{item.rooms.length === 0 && <NoRecordsFound variant='caption'>{strings.noWhatDefined(strings.rooms)}</NoRecordsFound>}
			{item.rooms.map((t, index) => (
				<div
					className='df-row-ac'
					key={t.id}>
					<ColorOnHoverIconButton
						color='secondary'
						onClick={() => onDeleteRoom(t)}
						size='small'>
						<DeleteOutlineOutlinedIcon fontSize='small' />
					</ColorOnHoverIconButton>
					<LabelledPropertyX
						edit={() => onEditRoom(t)}
						label={(index + 1).toString()}>
						{t.name}
					</LabelledPropertyX>
				</div>
			))}
			<Typography16pxBold>{strings.actions}</Typography16pxBold>
			<TextLinkButton
				startIcon={<DeleteOutlineIcon />}
				onClick={onDelete}
				color='secondary'
				hoverColor='secondary'>
				{strings.deleteWhat(strings.location)}
			</TextLinkButton>
		</div>
	);
};
