import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { isFiltered } from 'framework/utils/isFiltered';
import { DocumentPredefinedType, DocumentPredefinedTypes, IDocumentsQueryParams, LinkViewContextType, LinkViewContextTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	params: IDocumentsQueryParams;
	onFilter: (params: IDocumentsQueryParams) => void;
	paddingLeft: number;
}

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const { linkViewContextTypeRecord, documentPredefinedTypeRecord } = useContext(RecordContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params)}
			onClear={() => onFilter(clearParams(params))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.dateCreated}
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<RecordListFilterComponent<LinkViewContextType>
				options={LinkViewContextTypes}
				record={linkViewContextTypeRecord}
				selected={params.linkViewContextTypes as any}
				label='Context'
				icon={<HelpOutlineIcon />}
				setSelected={vals => onFilter({ ...params, linkViewContextTypes: vals })}
			/>
			<RecordListFilterComponent<DocumentPredefinedType>
				options={DocumentPredefinedTypes}
				record={documentPredefinedTypeRecord}
				selected={params.predefinedTypes as any}
				label={strings.type}
				icon={<HelpOutlineIcon />}
				setSelected={vals => onFilter({ ...params, predefinedTypes: vals })}
			/>
		</FilterBar>
	);
};
