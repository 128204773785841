import { TextField } from '@material-ui/core';
import React from 'react';
import { TextLink } from 'framework/components/TextLink';
import { FormHelperTextX } from 'framework/forms/common/FormHelperTextX';
import { useLocalization } from 'localization/useLocalization';
import { useViewDocumentation } from '../utils/useViewDocumentation';

interface IProps {
	rizivNumber: string;
}

export const ConventionedRizivNumberTextFieldWithDocumentation = ({ rizivNumber }: IProps) => {
	const strings = useLocalization();
	const onViewRizivNumberingInfo = useViewDocumentation('riziv-numbering-info');

	return (
		<>
			<TextField
				label={strings.conventionedRIZIVNumber}
				value={`1-${rizivNumber}`}
				disabled
				variant='filled'
			/>
			<FormHelperTextX>
				<div>{strings.conventionedEqualsPrecededByOne}</div>
				<TextLink
					text={strings.clickHereToViewDocumentation}
					onClick={onViewRizivNumberingInfo}
				/>
			</FormHelperTextX>
		</>
	);
};
