import { CardProps, Divider } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ExactOnlineProductCatalogItemPrefilters, IExactOnlineProductCatalogItemsQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps extends CardProps {
	params: IExactOnlineProductCatalogItemsQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IExactOnlineProductCatalogItemsQueryParams>>;
}

const exclusions: (keyof IExactOnlineProductCatalogItemsQueryParams)[] = [];

export const ExactOnlineProductCatalogItemsListFilterCard = ({ params, setParams, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const onFilter = (pars: IExactOnlineProductCatalogItemsQueryParams) => setParams(onResetToPage1(pars));
	const isFilteredX = useMemo(() => isFiltered(params, exclusions), [params]);
	const onClear = () => setParams(onResetToPage1(clearParams(params, exclusions)));
	const { exactOnlineProductCatalogPrefilterRecord } = useContext(RecordContext);

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-2'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<div className='df-col'>
				{ExactOnlineProductCatalogItemPrefilters.map(prefilter => (
					<RadioIsSelectedButton
						key={prefilter}
						isSelected={prefilter === params.prefilter}
						onClick={() => onFilter({ ...params, prefilter: prefilter })}>
						{exactOnlineProductCatalogPrefilterRecord[prefilter]}
					</RadioIsSelectedButton>
				))}
			</div>
			<Divider />
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<BooleanRadioFilterComponent
					label={`${strings.deleted}?`}
					trueText={strings.deleted}
					falseText={strings.notDeleted}
					selected={params.isDeleted}
					setSelected={value => onFilter({ ...params, isDeleted: value })}
					icon={<HelpOutlineIcon />}
				/>
			</div>
		</CardX>
	);
};
