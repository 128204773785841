import React from 'react';
import { DeliveriesQueryProvider } from './list/DeliveriesQueryProvider';
import { DeliveriesListViewProvider } from './list/DeliveriesListViewProvider';

export const DeliveriesProviders = ({ children }: any) => {
	return (
		<DeliveriesQueryProvider>
			<DeliveriesListViewProvider>{children}</DeliveriesListViewProvider>
		</DeliveriesQueryProvider>
	);
};
