import { useApiCall } from 'framework/hooks/useApiCall';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { inventoryItemsQuery_singleWithProductCatalogItem, productCatalogQuery_singleWithStockAtLocations } from 'gen/ApiClient';
import { IHandoverLine } from '../IHandoverLine';
import { inventoryItemToLine } from './inventoryItemToLine';
import { productCatalogItemToLine } from './productCatalogItemToLine';

const EmptyLine: IHandoverLine = {
	isSelected: true,
	id: '',
	description: '',
	quantity: 1,
	unitListPrice: 0,
	unitPrice: 0,
	vatPercentage: 21,
	inventoryItemId: undefined,
	productCatalogItem: undefined,
	inventoryItem: undefined,
	isRefundable: false,
	manufacturer: '',
	model: '',
	productCatalogItemId: undefined,
	rizivHearingAidDefinitionV2IdentificationCode: undefined,
	serialNumber: '',
	isIncludeOnHandoverCertificate: true,
	assuredInventoryItemId: undefined,
	assuredLineId: undefined,
	assuredProductCatalogItemId: undefined,
	canChangeQuantity: true,
	advanceInvoiceId: undefined,
	stockAtLocations: undefined,
	isCredit: false,
};

interface ICommon {
	inventoryItemId?: string;
	productCatalogItemId?: string;
}

export const useMapLine = <T extends ICommon>(): ((t: T, mapF: (t: T, line: IHandoverLine) => IHandoverLine) => Promise<IHandoverLine>) => {
	const loadProductCatalog = useApiCall(productCatalogQuery_singleWithStockAtLocations);
	const loadInventoryItem = useApiCall(inventoryItemsQuery_singleWithProductCatalogItem);

	return async (t: T, mapF: (t: T, line: IHandoverLine) => IHandoverLine) => {
		if (isNotNullNorUndefined(t.inventoryItemId)) {
			const r = await loadInventoryItem(t.inventoryItemId!);
			if (r.isSuccess) {
				const line = inventoryItemToLine(r.result.inventoryItem, r.result.productCatalogItem);
				return mapF(t, line);
			} else {
				return Promise.reject();
			}
		}
		if (isNotNullNorUndefined(t.productCatalogItemId)) {
			const r = await loadProductCatalog(t.productCatalogItemId!);
			if (r.isSuccess) {
				const line = productCatalogItemToLine(r.result.item, r.result.stockAtLocations);
				return mapF(t, line);
			} else {
				return Promise.reject();
			}
		}
		return mapF(t, EmptyLine);
	};
};
