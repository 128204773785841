import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { PurchasesRoute } from '../routes';

export const PurchasesPageBreadcrumb = () => {
	const strings = useLocalization();

	return (
		<PageBreadcrumb
			text={strings.purchases}
			route={PurchasesRoute}
		/>
	);
};
