import { Typography, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { IAnnotation } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { AnnotationAttachmentComponent } from './AnnotationAttachmentComponent';

interface IProps {
	item: IAnnotation;
	showDeleted: boolean;
	reload: VoidFunction;
}

export const AnnotationAttachmentsComponent = ({ item, reload, showDeleted }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const isShowAttachments = useMemo(
		() => (showDeleted === true && item.hasAttachment) || (showDeleted === false && item.hasNonDeletedAttachments),
		[showDeleted, item]
	);
	const attachments = useMemo(() => (showDeleted ? item.attachments : item.attachments.filter(t => t.isDeleted === false)), [item, showDeleted]);

	if (isShowAttachments === false) {
		return <div></div>;
	}

	return (
		<>
			<Typography
				variant='caption'
				style={{
					marginTop: theme.spacing(2),
					textDecoration: item.isDeleted ? 'line-through' : 'inherit',
					fontWeight: 500,
				}}>
				{strings.attachments}
			</Typography>
			{attachments.map(attachment => (
				<AnnotationAttachmentComponent
					key={attachment.id}
					annotation={item}
					attachment={attachment}
					reload={reload}
				/>
			))}
		</>
	);
};
