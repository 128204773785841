import { Typography, TypographyProps } from '@material-ui/core';
import React from 'react';

export const TypographyBoldCaption = ({ style, ...rest }: TypographyProps) => {
	return (
		<Typography
			{...rest}
			variant='caption'
			style={{ fontWeight: 'bold', ...style }}
		/>
	);
};
