import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { IDocument } from 'gen/ApiClient';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (colRecord: Record<SelectableColumn, string>): Record<SelectableColumn, IHeader<IDocument>> => {
	return {
		created: createSortableHeaderWithKey<IDocument, SelectableColumn>(colRecord, 'created', s => formatDate(s.created)),
		lastUpdated: createSortableHeaderWithKey<IDocument, SelectableColumn>(colRecord, 'lastUpdated', s => formatDate(s.lastUpdated)),
		extension: createSortableHeaderWithKey<IDocument, SelectableColumn>(colRecord, 'extension', t => t.extension),
		typeOrName: createSortableHeaderWithKey<IDocument, SelectableColumn>(colRecord, 'typeOrName', t => t.typeOrName),
		templateType: createSortableHeaderWithKey<IDocument, SelectableColumn>(colRecord, 'templateType', t => t.templateType),
		templateName: createSortableHeaderWithKey<IDocument, SelectableColumn>(colRecord, 'templateName', t => t.templateName),
	};
};
