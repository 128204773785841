import { DialogProps } from '@material-ui/core';
import React, { useState } from 'react';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IEidData, patientsCommand_updateEidData, patientsQuery_checkSsNumber } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { EidReadDialog } from './EidReadDialog';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	patient: { id: string; firstName: string | undefined; lastName: string | undefined; dateOfBirth: Date | undefined; socialSecurityNumber: string | undefined };
}

export const EidUpdateForm = ({ confirm, cancel, patient, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const strings = useLocalization();
	const [updatePatient, isSubmitting] = useFormSubmit(patientsCommand_updateEidData);
	const [validationError, setValidationError] = useState<string>();
	const checkSsNumber = useApiCall(patientsQuery_checkSsNumber);

	const onRead = async (data: IEidData) => {
		if (patient.socialSecurityNumber === data.socialSecurityNumber) {
			await onUpdate(data);
		} else if (patient.socialSecurityNumber) {
			setValidationError(strings.ssNumbersDoNotMatch);
		} else {
			const rCheck = await checkSsNumber(data.socialSecurityNumber);
			if (rCheck.isSuccess) {
				if (rCheck.result.exists === false) {
					await onUpdate(data);
				} else {
					notify(strings.otherPatientWithThisSsNumberAlreadyExists, 'error');
				}
			}
		}
	};

	const onUpdate = async (data: IEidData) => {
		const r = await updatePatient(patient.id, data);
		if (r.isSuccess) {
			notify(strings.eIDDataUpdatedToPatient);
			confirm();
		}
	};

	return (
		<EidReadDialog
			{...rest}
			cancel={cancel}
			isSubmitting={isSubmitting}
			onRead={onRead}
			validationError={validationError}
		/>
	);
};
