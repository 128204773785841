import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, useTheme } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { customerAccountLinesQuery_allForSpsOrWithoutSpsWithOutstandingBalance, ISettlementProposal, settlementProposalsCommand_update } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { CustomerAccountLinesTable } from '../../payments/forms/customerAccountLines/CustomerAccountLinesTable';
import { ICustomerAccountLineWithSelection } from '../../payments/forms/customerAccountLines/ICustomerAccountLineWithSelection';
import { TotalsTable } from '../../payments/forms/customerAccountLines/TotalsTable';

interface IModel {
	lines: string[];
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			lines: yup.mixed(),
		})
		.defined();
};

const emptyValues: IModel = {
	lines: [],
};

interface IProps extends DialogProps {
	proposal: ISettlementProposal;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const UpdateSettlementProposalForm = ({ proposal, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(settlementProposalsCommand_update);
	const [allLines] = useApiEffect(customerAccountLinesQuery_allForSpsOrWithoutSpsWithOutstandingBalance, proposal.customerAccountId, proposal.id);
	const [lines, setLines] = useState<ICustomerAccountLineWithSelection[]>([]);

	useEffect(() => {
		console.log('use effect on all-lines or proposal.lines');
		if (allLines !== undefined) {
			setLines(
				allLines.map<ICustomerAccountLineWithSelection>(t => ({
					isSelected: proposal.lines.find(x => x.customerAccountLineId === t.id) !== undefined,
					...t,
				}))
			);
		}
	}, [allLines, proposal.lines]);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await update(
			proposal.id,
			lines.filter(t => t.isSelected).map(t => t.id)
		);
		if (handleValidateResponse(r, helpers, 'lines')) {
			notify(strings.changedWhat(strings.settlementProposal));
			confirm();
		}
	};

	if (allLines === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					scroll='paper'
					{...rest}
					maxWidth='md'>
					<DialogTitle>
						<div>{`${strings.settlementProposal}: ${proposal.reference}: ${proposal.customerAccountIdentifier}`}</div>
					</DialogTitle>
					<DialogContent
						className='df-col'
						dividers
						style={{ padding: 0 }}>
						<CustomerAccountLinesTable
							lines={lines}
							setLines={setLines}
						/>
					</DialogContent>
					<DialogActions>
						<div className='df-col'>
							<TotalsTable
								lines={lines}
								style={{ marginBottom: theme.spacing(1) }}
							/>
							<CancelSubmitFormDialogActions<IModel>
								cancel={cancel}
								isSubmitting={isSubmitting}
								submitText={strings.create}
							/>
						</div>
					</DialogActions>
				</Dialog>
			</Form>
		</Formik>
	);
};
