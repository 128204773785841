import { useWindowHeight } from 'framework/hooks/useWindowHeight';
import { IPatientDetail } from 'gen/ApiClient';
import React from 'react';
import { PatientDetailNewProcessTextLinkButton } from './PatientDetailNewProcessTextLinkButton';
import { PatientDetailProcessesActions } from './PatientDetailProcessesActions';

interface IProps {
	item: IPatientDetail;
	reload: VoidFunction;
}

export const ResponsivePatientDetailActions = ({ item, reload }: IProps) => {
	const height = useWindowHeight();

	if (height <= 950) {
		return (
			<PatientDetailNewProcessTextLinkButton
				item={item}
				reload={reload}
			/>
		);
	} else {
		return (
			<PatientDetailProcessesActions
				item={item}
				reload={reload}
			/>
		);
	}
};
