import { MbscResource } from '@mobiscroll/react';
import { ICalendarResource } from '../ICalendarResource';
import { AgendaGroupScheme } from './AgendaGroupScheme';

export const mapResourcesToMobiscrollResources = (
	groupScheme: AgendaGroupScheme,
	selectedUserResources: ICalendarResource[],
	selectedRoomResources: ICalendarResource[]
): MbscResource[] | undefined => {
	if (groupScheme === 'rooms') {
		return selectedRoomResources.map<MbscResource>(t => ({
			id: t.key,
			name: t.name,
			color: t.color,
		}));
	} else if (groupScheme === 'users') {
		return selectedUserResources.map<MbscResource>(t => ({
			id: t.key,
			name: t.name,
			color: t.color,
		}));
	} else {
		return undefined;
	}
};
