import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { ITransactionDto } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (colRecord: Record<SelectableColumn, string>, strings: IStrings): Record<SelectableColumn, IHeader<ITransactionDto>> => {
	return {
		created: createSortableHeaderWithKey<ITransactionDto, SelectableColumn>(colRecord, 'created', s => formatDate(s.created)),
		referenceDate: createSortableHeaderWithKey<ITransactionDto, SelectableColumn>(colRecord, 'referenceDate', s => formatDate(s.referenceDate)),
		fromLocationIdentifier: createSortableHeaderWithKey<ITransactionDto, SelectableColumn>(colRecord, 'fromLocationIdentifier', s => s.fromLocationIdentifier),
		toLocationIdentifier: createSortableHeaderWithKey<ITransactionDto, SelectableColumn>(colRecord, 'toLocationIdentifier', s => s.toLocationIdentifier),
		supplierIdentifier: createSortableHeaderWithKey<ITransactionDto, SelectableColumn>(colRecord, 'supplierIdentifier', s => s.supplierIdentifier),
		patients: createHeaderWithKey<ITransactionDto, SelectableColumn>(colRecord, 'patients', s => s.patientIdentifiers.join('\n')),
		reference: createSortableHeaderWithKey<ITransactionDto, SelectableColumn>(colRecord, 'reference', s => s.reference),
		externalReference: createSortableHeaderWithKey<ITransactionDto, SelectableColumn>(colRecord, 'externalReference', s => s.externalReference),
		quantity: createHeaderWithKey<ITransactionDto, SelectableColumn>(colRecord, 'quantity', s => s.quantity.toString()),
	};
};
