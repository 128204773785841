import WcIcon from '@material-ui/icons/Wc';
import React, { useContext } from 'react';
import { RadioFilterComponent } from 'framework/filter/RadioFilterComponent';
import { IOption } from 'framework/IOption';
import { Gender, Genders } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from './records/RecordContext';

interface IProps {
	selected: string | undefined;
	setSelected: (val: Gender | undefined) => void;
}

export const GenderFilterItem = ({ selected, setSelected }: IProps) => {
	const strings = useLocalization();
	const { genderRecord } = useContext(RecordContext);

	return (
		<RadioFilterComponent<Gender>
			label={strings.gender}
			icon={<WcIcon />}
			options={Genders.map<IOption<Gender>>(t => ({ id: t, identifier: genderRecord[t] }))}
			selected={selected as any}
			setSelected={setSelected}
		/>
	);
};
