import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { IArticleProperty, IArticlePropertyDefinition, IArticlePropertyModel } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { ArticlePropertiesTable } from './ArticlePropertiesTable';
import { IArticlePropertyLine } from './IArticlePropertyLine';
import { useArticlePropertyKeysAndAutocomplete } from './useArticlePropertyKeysAndAutocomplete';
import { articlePropertyLineToModel } from './utils/articlePropertyLineToModel';
import { mapToArticlePropertyLines } from './utils/mapToArticlePropertyLines';

interface IProps extends DialogProps {
	confirm: (lines: IArticlePropertyModel[]) => void;
	cancel: VoidFunction;
	apds: IArticlePropertyDefinition[];
	aps: (IArticleProperty | IArticlePropertyModel)[];
}

export const ArticlePropertiesDialog = ({ confirm, cancel, apds, aps, ...rest }: IProps) => {
	const strings = useLocalization();
	const [apks, apacs] = useArticlePropertyKeysAndAutocomplete();
	const [lines, setLines] = useState<IArticlePropertyLine[]>([]);

	useEffect(() => {
		setLines(mapToArticlePropertyLines(apds ?? [], aps ?? [], apks, apacs));
	}, [apks, apacs, apds, aps]);

	const onConfirm = () => {
		confirm(lines.map(articlePropertyLineToModel));
	};

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='xl'>
			<DialogTitle>{strings.editProperties}</DialogTitle>
			<DialogContent
				dividers
				style={{ minWidth: 500, padding: 0 }}
				className='df-col'>
				<ArticlePropertiesTable
					lines={lines}
					setLines={setLines}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				submitText={strings.update}
				isSubmitting={false}
				onSubmit={onConfirm}
			/>
		</Dialog>
	);
};
