import { NoahDeviceInventoryItemMapExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createExportRecord = (strings: IStrings): Record<NoahDeviceInventoryItemMapExportProp, string> => {
	return {
		IsHearingInstrument: `${strings.hearingAid}?`,
		IsRemoteControl: `${strings.remoteDevice}?`,
		FirstUsageDate: strings.firstUsageDate,
		InstrumentTypeName: strings.model,
		LastUsageDate: strings.lastUsageDate,
		Manufacturer: strings.manufacturer,
		SerialNumber: strings.serialNumber,
		Type: strings.type,
	};
};
