import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IStockMovementFromInventoryItemModel, stockMovementsCommand_new_inventoryItem, stockMovementsQuery_nextReference } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import * as yup from 'yup';
import { LocationsPreloadCacheContext } from '../../../../settings/locations/LocationsPreloadCacheContext';
import { SearchProvider } from './context/SearchProvider';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IStockMovementFromInventoryItemModel>({
			reference: yup.string().required(),
			isAutoSelectReference: yup.boolean().defined(),
			inventoryItemId: yup.string().required(),
			fromLocationId: yup.string().required(strings.formRequired('van locatie')),
			toLocationId: yup
				.string()
				.required(strings.formRequired('naar locatie'))
				.notOneOf([yup.ref('fromLocationId')], 'Naar locatie moet verschillend zijn als de van locatie'),
		})
		.defined();
};

const emptyValues: IStockMovementFromInventoryItemModel = {
	fromLocationId: undefined as any,
	toLocationId: undefined as any,
	inventoryItemId: '',
	reference: '',
	isAutoSelectReference: true,
};

const stepsRecord: Record<number, (keyof IStockMovementFromInventoryItemModel)[]> = {
	0: ['reference', 'isAutoSelectReference'],
	1: ['fromLocationId', 'toLocationId'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	inventoryItemId: string;
}

export const StockMovementFromInventoryItemForm = ({ confirm, cancel, inventoryItemId, ...rest }: IProps) => {
	const strings = useLocalization();
	const [create, isSubmitting] = useFormSubmit(stockMovementsCommand_new_inventoryItem);
	const notify = useSnackbarNotify();
	const [step, setStep] = useState<number>(0);

	const handleSubmit = async (values: IStockMovementFromInventoryItemModel, helpers: FormikHelpers<IStockMovementFromInventoryItemModel>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.addedWhat(strings.fromArrowTo));
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<IStockMovementFromInventoryItemModel>
			validateOnMount
			initialValues={{ ...emptyValues, inventoryItemId: inventoryItemId }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						step={step}
						setStep={setStep}
						stepsRecord={stepsRecord}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof IStockMovementFromInventoryItemModel)[]>;
}

const InnerDialog = ({ cancel, isSubmitting, step, stepsRecord, setStep, ...rest }: IInnerProps) => {
	const props = useFormikContext<IStockMovementFromInventoryItemModel>();
	const strings = useLocalization();
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	useNextReferenceInterceptor(stockMovementsQuery_nextReference, props);

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='md'>
			<DialogTitleWithFormStepper
				title={strings.newWhat(strings.fromArrowTo)}
				step={step}
				labels={['Ref.', strings.fromTo]}
			/>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IStockMovementFromInventoryItemModel>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IStockMovementFromInventoryItemModel>
							name='reference'
							label={strings.reference}
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectFieldWithOption<IStockMovementFromInventoryItemModel, string>
							name='fromLocationId'
							options={locations}
							label={strings.fromLocation}
						/>
						<FormSelectFieldWithOption<IStockMovementFromInventoryItemModel, string>
							name='toLocationId'
							options={locations}
							label={strings.toLocation}
						/>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
