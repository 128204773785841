import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { IStockMovement } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { StockMovementsSelectableColumn } from './StockMovementsSelectableColumn';

export const createStockMovementsHeaders = (
	colRecord: Record<StockMovementsSelectableColumn, string>,
	strings: IStrings
): Record<StockMovementsSelectableColumn, IHeader<IStockMovement>> => {
	return {
		countItems: createSortableHeaderWithKey<IStockMovement, StockMovementsSelectableColumn>(colRecord, 'countItems', s => s.countItems.toString()),
		created: createSortableHeaderWithKey<IStockMovement, StockMovementsSelectableColumn>(colRecord, 'created', s => formatDate(s.created)),
		reference: createSortableHeaderWithKey<IStockMovement, StockMovementsSelectableColumn>(colRecord, 'reference', s => s.reference),
		lastUpdated: createSortableHeaderWithKey<IStockMovement, StockMovementsSelectableColumn>(colRecord, 'lastUpdated', p => formatDate(p.lastUpdated)),
		fromLocationIdentifier: createSortableHeaderWithKey<IStockMovement, StockMovementsSelectableColumn>(
			colRecord,
			'fromLocationIdentifier',
			s => s.fromLocationIdentifier
		),
		toLocationIdentifier: createSortableHeaderWithKey<IStockMovement, StockMovementsSelectableColumn>(
			colRecord,
			'toLocationIdentifier',
			s => s.toLocationIdentifier
		),
	};
};
