import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		locationIdentifier: strings.location,
		referenceDate: strings.date,
		value: strings.amountAsValue,
		globalResultingBalance: strings.balance,
		index: strings.indexNumber,
		paymentReferenceOrDescription: strings.description,
	};
};
