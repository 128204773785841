import React from 'react';
import { AllQueryProvider } from './AllQueryProvider';
import { MyQueryProvider } from './MyQueryProvider';
import { ViewProvider } from './ViewProvider';

export const CommissionPayoutsPageProviders = ({ children }: any) => {
	return (
		<ViewProvider>
			<AllQueryProvider>
				<MyQueryProvider>{children}</MyQueryProvider>
			</AllQueryProvider>
		</ViewProvider>
	);
};
