import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { IInventoryItem, ProductCatalogItemType } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { InventoryItemChips } from '../../components/InventoryItemChips';
import { SelectableColumn } from '../SelectableColumn';
import React from 'react';

export const createHeaders = (
	colRecord: Record<SelectableColumn, string>,
	record: Record<ProductCatalogItemType, string>,
	strings: IStrings
): Record<SelectableColumn, IHeader<IInventoryItem>> => {
	return {
		serialNumber: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'serialNumber', t => t.serialNumber),
		type: createHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'type', t => record[t.type as ProductCatalogItemType]),
		status: createHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'status', t => (
			<InventoryItemChips
				direction='column'
				item={t}
			/>
		)),
		manufacturerAndModel: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'manufacturerAndModel', t => t.manufacturerAndModel),
		patientIdentifier: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'patientIdentifier', t => t.patientIdentifier),
		supplierIdentifier: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'supplierIdentifier', t => t.supplierIdentifier),
	};
};
