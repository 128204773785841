import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledChipsList } from 'framework/components/chips/LabelledChipsList';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	labels: string[];
	onUpdate: VoidFunction;
}

export const LabelsComponent = ({ labels, onUpdate }: IProps) => {
	const strings = useLocalization();

	return (
		<>
			<CardTitleTypography
				title={strings.manuallyAssignedLabels}
				withPaddingTop
				edit={onUpdate}
			/>
			{labels.length !== 0 && <LabelledChipsList labels={labels} />}
			{labels.length === 0 && <NoRecordsFound>{strings.notFilledIn}</NoRecordsFound>}
		</>
	);
};
