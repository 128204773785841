import React, { useMemo } from 'react';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { IDocument } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { DocumentHistoryTimeline } from './DocumentHistoryTimeline';

interface IProps {
	document: IDocument;
}

export const DocumentHistory = ({ document }: IProps) => {
	const showHistory = useMemo(() => document.history.length > 1, [document]);
	const strings = useLocalization();

	return (
		<>
			{showHistory && (
				<>
					<GridDividerWithCaption caption={strings.history} />
					<DocumentHistoryTimeline
						id={document.id}
						canViewOnline={document.canViewOnline}
						style={{ marginLeft: '-144px' }}
						history={document.historyOrderedDesc}
					/>
				</>
			)}
		</>
	);
};
