import { Divider } from '@material-ui/core';
import { RosaPatientEventListComponent } from 'app/main/rosaCalendar/list/RosaPatientEventListComponent';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { CardX } from 'framework/components/cards/CardX';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { combineClassNames } from 'framework/utils/combineClassNames';
import { IRosaEventsByPatientQueryParams, rosaEventsQuery_queryByPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect } from 'react';
import { IPatientEventsCardProps } from './IPatientEventsCardProps';
import { RosaPatientRedirectButton } from './RosaPatientRedirectButton';

export const DefaultParams: IRosaEventsByPatientQueryParams = {
	patientId: undefined as any,
	pageParams: { index: 1, size: 10 },
};

export const RosaPatientEventsCard = ({ patient, reloadTrigger, reload, ...rest }: IPatientEventsCardProps) => {
	const strings = useLocalization();
	const [params, setParams, response, reloadEvents] = useApiEffectWithParams(
		rosaEventsQuery_queryByPatient,
		{ ...DefaultParams, patientId: patient.id },
		reloadTrigger
	);

	useEffect(() => {
		reloadEvents();
		// eslint-disable-next-line
	}, [reloadTrigger]);

	useEffect(() => {
		setParams({ ...params, patientId: patient.id });
		// eslint-disable-next-line
	}, [patient]);

	if (response === undefined) {
		return <div></div>;
	}

	return (
		<CardX
			{...rest}
			className={combineClassNames('w100 df-col fg1 gap-8', rest.className)}>
			<div className='df-row-ac'>
				<CardTitleTypography title={strings.appointments} />
				<div className='fg1'></div>
				<RosaPatientRedirectButton
					hamsPatientId={response.metadata.hamsPatientId}
					redirectRosaPatientRecordId={response.metadata.redirectRosaPatientRecordId}
					reload={reload}
					variant='contained'
					color='primary'>
					{strings.newWhat(strings.appointment)}
				</RosaPatientRedirectButton>
			</div>
			<Divider />
			<div className='df-col gap-4'>
				{response.values.map(t => (
					<RosaPatientEventListComponent
						event={t}
						key={t.id}
					/>
				))}
			</div>
			{response.values.length === 0 && <NoRecordsFound withPadding>{strings.noAppointmentsFound}</NoRecordsFound>}
			<QueryResultPaginationX
				queryResult={response}
				setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
				style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
			/>
		</CardX>
	);
};
