import { Avatar, Card, CardContent, useTheme } from '@material-ui/core';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	icon: React.ReactNode;
	title: string;
	count: number;
	amount: number;
	color: PaletteColor;
	style?: React.CSSProperties;
}

export const SaleByPatientStatCard = ({ icon, title, count, amount, color, style }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();

	return (
		<Card style={style}>
			<CardContent className='df-row gap-16'>
				<div className='df-col-ac jc-c'>
					<Avatar style={{ backgroundColor: color.main, color: theme.palette.getContrastText(color.main) }}>{icon}</Avatar>
				</div>
				<div className='df-col'>
					<span style={{ fontWeight: 500, marginBottom: 8, fontSize: 16 }}>{title}</span>
					<span>
						<strong>{count.toString()}</strong>
						{` ${count === 1 ? strings.sale.toLowerCase() : strings.sales.toLowerCase()}`}
					</span>
					<span style={{ color: color.main }}>{formatCurrency(amount)}</span>
				</div>
			</CardContent>
		</Card>
	);
};
