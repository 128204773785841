import { IStrings } from 'localization/IStrings';
import { QuickFilter } from '../SalesCard';

export const createQfRecord = (strings: IStrings): Record<QuickFilter, string> => {
	return {
		all: strings.all,
		withOutstandingBalance: strings.withWhat(strings.outstandingBalance),
		withOutstandingBalanceAndWithoutSettlementProposal: `${strings.withWhat(strings.outstandingBalance)}, ${strings.withoutWhat(strings.settlementProposal)}`,
	};
};
