import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	IRegisterApprovalAdvisoryPhysicianModel,
	rfiasCommand_registerApprovalAdvisoryPhysician,
	rfiasCommand_registerDisapprovalAdvisoryPhysician,
	rfiasQuery_settings,
	TimeUnit,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useNow } from 'shared/utils/useNow';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterApprovalAdvisoryPhysicianModel>({
			approvalDate: yup.date().required(strings.formRequired(strings.approvalDate)),
			expectedHandoverDate: yup.date().required(strings.formRequired(strings.expectedHandoverDate)),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IRegisterApprovalAdvisoryPhysicianModel)[]> = {
	0: [],
	1: ['approvalDate', 'expectedHandoverDate'],
};

interface IProps extends DialogProps {
	rfiaId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const ReceiveFeedbackAdvisoryPhysicianForm = ({ rfiaId, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [isApproved, setIsApproved] = useState<boolean>(true);
	const notify = useSnackbarNotify();
	const [approve, isSubmitting, setIsSubmitting] = useFormSubmit(rfiasCommand_registerApprovalAdvisoryPhysician);
	const [disApprove] = useFormSubmit(rfiasCommand_registerDisapprovalAdvisoryPhysician, setIsSubmitting);
	const [step, setStep] = useState<number>(0);
	const [settings] = useApiEffect(rfiasQuery_settings);
	const units = useMemo(() => ['Days', 'Weeks'] as TimeUnit[], []);
	const now = useNow();

	if (settings === undefined || now === undefined) {
		return <div></div>;
	}

	const handleSubmit = async (values: IRegisterApprovalAdvisoryPhysicianModel, helpers: FormikHelpers<IRegisterApprovalAdvisoryPhysicianModel>) => {
		if (isApproved) {
			const r = await approve(rfiaId, values);
			if (handleFormResponse(r, helpers)) {
				notify(strings.registeredWhat(strings.approval));
				confirm();
			}
		} else {
			const r = await disApprove(rfiaId, values.approvalDate);
			if (handleValidateResponse(r, helpers, 'approvalDate')) {
				notify(strings.registeredWhat(strings.reception));
				confirm();
			}
		}
	};

	return (
		<Formik<IRegisterApprovalAdvisoryPhysicianModel>
			validateOnMount
			initialValues={{
				approvalDate: now,
				expectedHandoverDate: now,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			{props => (
				<Form>
					<Dialog
						{...rest}
						scroll='paper'
						maxWidth='md'>
						<DialogTitleWithFormStepper
							step={step}
							title={strings.receptionFeedbackAdvisoryPhysician}
							labels={[strings.approval, strings.date]}
						/>
						<DialogContent
							className='df-col'
							dividers>
							{step === 0 && (
								<ExtendedRadioGroup<boolean>
									selected={isApproved}
									setSelected={setIsApproved}
									options={[
										{ value: true, label: strings.approved, caption: strings.approvalAdvisoryPhysicianCaption },
										{ value: false, label: strings.disApproved, caption: strings.disApprovalAdvisoryPhysicianCaption },
									]}
								/>
							)}
							{step === 1 && (
								<>
									<FormDatePicker<IRegisterApprovalAdvisoryPhysicianModel>
										name='approvalDate'
										label={isApproved ? strings.approvalDate : strings.receptionDate}
										disableFuture
									/>
									{isApproved && (
										<FormDatePickerWithUnit<IRegisterApprovalAdvisoryPhysicianModel>
											name='expectedHandoverDate'
											fromDate={props.values.approvalDate ?? now}
											label={strings.expectedWhat(strings.handoverDate)}
											required
											units={units}
											defaultUnit='Days'
											defaultValue={settings.defaultExpectedHandoverTermAfterApproval}
										/>
									)}
								</>
							)}
						</DialogContent>
						<PageableFormDialogActions
							step={step}
							setStep={setStep}
							cancel={cancel}
							isSubmitting={isSubmitting}
							submitText={strings.create}
							schema={createSchema(strings)}
							stepsRecord={stepsRecord}
						/>
					</Dialog>
				</Form>
			)}
		</Formik>
	);
};
