import { Avatar, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';

interface IProps {
	index: number;
	color: 'error' | 'normal';
}

export const IndexAvatar = ({ index, color }: IProps) => {
	const theme = useTheme();
	const xStyle = useMemo<React.CSSProperties>(() => (color === 'error' ? { backgroundColor: theme.palette.error.main } : {}), [theme, color]);

	return (
		<Avatar
			variant='rounded'
			style={{ width: 32, height: 32, fontSize: 16, ...xStyle }}>
			{`#${index}`}
		</Avatar>
	);
};
