import { Card } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DefaultCardContent } from 'framework/components/DefaultCardContent';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IInventoryItem, inventoryItemsCommand_clearReservation } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { ReserveForPatientForm } from '../forms/ReserveForPatientForm';

interface IProps {
	item: IInventoryItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const ReservationCard = ({ item, reload, style }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();
	const strings = useLocalization();

	const onReserve = () => {
		open(
			<ReserveForPatientForm
				open
				inventoryItemId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onClear = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.reservation)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => inventoryItemsCommand_clearReservation({ inventoryItemId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<Card
			style={style}
			className='df-col'>
			<CardTitleTypography
				title={strings.reservation}
				withPadding
				style={{ paddingBottom: 0 }}
				action={
					item.isReserved ? (
						<SmallPrimaryTextButton
							color='secondary'
							onClick={onClear}>
							{strings.delete}
						</SmallPrimaryTextButton>
					) : (
						<SmallPrimaryTextButton
							onClick={onReserve}
							disabled={item.canReserve === false}>
							{strings.createWhat(strings.reservation)}
						</SmallPrimaryTextButton>
					)
				}
			/>
			<DefaultCardContent style={{ paddingTop: 0 }}>
				{item.isReserved === true && (
					<LabelledProperty
						label={strings.patient}
						property={item.reservedForPatientIdentifier}
						navigate={() => push(calcPatientDetailRoute(item.reservedForPatientId ?? ''))}
					/>
				)}
				{item.isReserved === false && <NoRecordsFound>{strings.statusNotReserved}</NoRecordsFound>}
			</DefaultCardContent>
		</Card>
	);
};
