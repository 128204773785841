import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { IProductCatalogItem, productCatalogCommand_patchDefaultWarranty } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TimeModelForm } from 'shared/timeUnit/TimeModelForm';
import { useRenderTimeModel } from 'shared/timeUnit/useRenderTimeModel';

interface IProps {
	item: IProductCatalogItem;
	reload: VoidFunction;
}

export const DefaultWarranty = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const renderTimeModel = useRenderTimeModel();

	const onEdit = () => {
		open(
			<TimeModelForm
				open
				model={item.defaultWarranty}
				title={strings.changeWhat(strings.defaultWarranty)}
				label={strings.defaultWarranty}
				defaultUnit='Years'
				options={['Years', 'Months']}
				confirm={confirm}
				cancel={cancel}
				submitFunction={val => productCatalogCommand_patchDefaultWarranty({ productCatalogItemId: item.id, unit: val.unit, value: val.value })}
			/>
		);
	};

	return (
		<>
			<CardTitleTypography
				title={strings.defaultWarranty}
				withPaddingTop
				edit={onEdit}
			/>
			{item.defaultWarranty && (
				<LabelledProperty
					label={strings.term}
					property={renderTimeModel(item.defaultWarranty)}
				/>
			)}
		</>
	);
};
