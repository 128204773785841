import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReturnUrlPrefix } from 'keys';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { auth_switchLocation } from 'gen/ApiClient';
import { DashboardRoute } from 'routes';
import { AuthContext } from './AuthContext';
import { IBaseLoginResponse } from './IBaseLoginResponse';
import { ILocationResponse } from './ILocationResponse';
import { LoginContext } from './LoginContext';
import { SelectLocation } from './SelectLocation';

export const LoginProvider = ({ children }: any) => {
	const [loc, setLoc] = useState<ILocationResponse | undefined>(undefined);
	const authContext = useContext(AuthContext);
	const [switchLocation, isSubmitting] = useFormSubmit(auth_switchLocation);
	const location = useLocation();
	const { push } = useHistory();

	const onSwitchLocation = async (id: string) => {
		if (id !== authContext.locationId) {
			const r = await switchLocation({ locationId: id });
			if (r.isSuccess) {
				authContext.switchLocation(id);
				proceedWithLogin();
			}
		} else {
			proceedWithLogin();
		}
	};

	const handleLogin = (response: IBaseLoginResponse) => {
		authContext.login(response.user);
		if (response.mustSelectLocation) {
			setLoc({ locations: response.locations, preferredLocationId: response.user.preferredLocationId });
		} else {
			proceedWithLogin();
		}
	};

	const proceedWithLogin = () => {
		if (location.search.indexOf(ReturnUrlPrefix) > -1) {
			const returnUrl = location.search.replace(ReturnUrlPrefix, '');
			push(returnUrl);
		} else {
			push(DashboardRoute);
		}
		setLoc(undefined);
	};

	return (
		<LoginContext.Provider
			value={{
				handleLogin: handleLogin,
			}}>
			<>
				{loc ? (
					<SelectLocation
						response={loc}
						isSubmitting={isSubmitting}
						confirm={onSwitchLocation}
					/>
				) : (
					children
				)}
			</>
		</LoginContext.Provider>
	);
};
