import React, { useContext } from 'react';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { FormSelectField } from 'framework/forms/FormSelectField';
import { Gender, Genders } from 'gen/ApiClient';
import { GenderIcon } from '../GenderIcon';
import { RecordContext } from '../records/RecordContext';

export const FormGenderSelectField = <TModel extends unknown>({ ...props }: IFormProps<TModel>) => {
	const { genderRecord } = useContext(RecordContext);

	return (
		<FormSelectField<TModel, Gender>
			{...props}
			options={Genders}
			getKey={t => t}
			renderValue={option => (
				<div className='df-row-ac'>
					<GenderIcon
						style={{ width: '15px', marginRight: '5px' }}
						gender={option as any}
					/>
					{genderRecord[option]}
				</div>
			)}
		/>
	);
};
