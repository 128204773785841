import { Collapse } from '@material-ui/core';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageStatsButtonX } from 'framework/components/layout/PageStatsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { formatDate } from 'framework/utils/date/formatDate';
import { IStockEntryDto } from 'gen/ApiClient';
import React from 'react';
import { StockStats } from '../../stock/StockStats';
import { StockEntriesBreadcrumbs } from '../StockEntriesBreadcrumbs';

interface IProps {
	item: IStockEntryDto;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const StockEntryDetailHeader = ({ item, reload, style }: IProps) => {
	const [showStats, toggleShowStats] = useStateToggleBoolean(true);

	return (
		<div
			className='df-col fg1'
			style={style}>
			<div className='df-row-ac'>
				<StockEntriesBreadcrumbs />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={formatDate(item.date, 'date-and-time')} />
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageStatsButtonX
						showStats={showStats}
						toggleShowStats={toggleShowStats}
					/>
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<Collapse in={showStats}>
				<StockStats
					item={item}
					style={{ paddingTop: 16 }}
				/>
			</Collapse>
		</div>
	);
};
