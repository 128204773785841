import React from 'react';
import { Trigger } from 'framework/hooks/useTrigger';
import { INotificationWithStatusDto } from 'gen/ApiClient';

interface IProps {
	notifications: INotificationWithStatusDto[];
	onView: (id: string) => void;
	trigger: Trigger;
	countUnreadNotifications: number;
	countNotShownNotifications: number;
	notShownNotifications: INotificationWithStatusDto[];
}

export const NotificationsContext = React.createContext<IProps>(undefined as any);
