import { Popover } from '@material-ui/core';
import { isBefore } from 'date-fns';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { formatTime } from 'framework/utils/date/formatTime';
import { ICalendarEventDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { getNow } from 'shared/utils/getNow';
import { AgendaColorScheme } from '../AgendaColorScheme';
import { EventPopoverContent } from '../EventPopoverContent';
import { mapEventColor } from '../mapEventColor';

interface IProps {
	event: ICalendarEventDto;
	colorScheme: AgendaColorScheme;
	reload: VoidFunction;
	hideTitleAndRoom?: boolean;
	style?: React.CSSProperties;
}

export const EventListItemComponent = ({ event, reload, colorScheme, hideTitleAndRoom, style }: IProps) => {
	const [isHovered, enter, leave] = useStateBoolean(false);
	const isPast = useMemo(() => isBefore(new Date(event.endDate), getNow()), [event]);
	const [anchor, open, close] = useAnchorElement();
	const strings = useLocalization();
	const color = useMemo(() => mapEventColor(event, colorScheme), [event, colorScheme]);

	return (
		<>
			{Boolean(anchor) && (
				<Popover
					open={Boolean(anchor)}
					anchorEl={anchor}
					onClose={close}>
					<EventPopoverContent
						event={event}
						instanceStartDate={event.startDate}
						instanceEndDate={event.endDate}
						close={close}
						reload={reload}
						colorScheme={colorScheme}
					/>
				</Popover>
			)}
			<div
				className='df-row-ac w100'
				onClick={open}
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{
					height: 32,
					cursor: 'pointer',
					borderRadius: 32,
					backgroundColor: isHovered ? '#f6f6f6' : 'inherit',
					paddingLeft: 8,
					opacity: isPast ? 0.33 : 1,
					...style,
				}}>
				<div className='df-row-ac'>
					<div style={{ display: 'flex', marginRight: 6, marginBottom: 3 }}>
						<div style={{ borderRadius: 12, border: `6px solid ${color}` }}></div>
					</div>
					<div style={{ width: `100px` }}>
						{event.isAllDay || event.spansMultipleDays ? strings.fullDay : `${formatTime(event.startDate)} - ${formatTime(event.endDate)}`}
					</div>
				</div>
				{hideTitleAndRoom !== true && (
					<>
						<div style={{ fontWeight: 500, marginRight: 8, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{event.display}</div>
						{event.hasRoom && <div>{event.roomIdentifier}</div>}
					</>
				)}
			</div>
		</>
	);
};
