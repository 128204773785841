import React from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { INoahDeviceInventoryItemMap, NoahDeviceType } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (
	colRecord: Record<SelectableColumn, string>,
	strings: IStrings,
	typeRecord: Record<NoahDeviceType, string>
): Record<SelectableColumn, IHeader<INoahDeviceInventoryItemMap>> => {
	return {
		formattedReadOutsUniqueField: createSortableHeaderWithKey<INoahDeviceInventoryItemMap, SelectableColumn>(colRecord, 'formattedReadOutsUniqueField', t => (
			<div style={{ whiteSpace: 'pre-line' }}>{t.formattedReadOutsUniqueField}</div>
		)),
		firstUsageDate: createSortableHeaderWithKey<INoahDeviceInventoryItemMap, SelectableColumn>(colRecord, 'firstUsageDate', t => formatDate(t.firstUsageDate)),
		lastUsageDate: createSortableHeaderWithKey<INoahDeviceInventoryItemMap, SelectableColumn>(colRecord, 'lastUsageDate', t => formatDate(t.lastUsageDate)),
		type: createHeaderWithKey<INoahDeviceInventoryItemMap, SelectableColumn>(colRecord, 'type', t => (
			<RectangularChip label={typeRecord[t.type as NoahDeviceType]} />
		)),
		instrumentTypeName: createSortableHeaderWithKey<INoahDeviceInventoryItemMap, SelectableColumn>(colRecord, 'instrumentTypeName', t => t.instrumentTypeName),
		manufacturer: createSortableHeaderWithKey<INoahDeviceInventoryItemMap, SelectableColumn>(colRecord, 'manufacturer', t => t.manufacturer),
		serialNumber: createSortableHeaderWithKey<INoahDeviceInventoryItemMap, SelectableColumn>(colRecord, 'serialNumber', t => t.serialNumber),
		manufacturerCommaInstrumentTypeName: createSortableHeaderWithKey<INoahDeviceInventoryItemMap, SelectableColumn>(
			colRecord,
			'manufacturerCommaInstrumentTypeName',
			t => t.manufacturerCommaInstrumentTypeName
		),
	};
};
