import { Dialog, DialogContent, DialogProps, DialogTitle, Divider, IconButton } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import {
	IEquipmentDeliveryLine,
	IPatchRfiaHandoverLineRequest,
	IRizivHearingAidDefinitionV2Dto,
	inventoryItemsQuery_single,
	rfiasHandoverCommand_patchHandoverLine,
	rizivHearingAidDefinitionsV2Query_identifyAndConvertToV2,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RizivRefundableHearingInstrumentChip } from 'shared/rizivRefundableHearingInstruments/RizivRefundableHearingInstrumentChip';
import { SelectRizivRefundableHearingInstrumentForInventoryItemForm } from 'shared/rizivRefundableHearingInstruments/SelectRizivRefundableHearingInstrumentForInventoryItemForm';
import * as yup from 'yup';
import { lineToHandoverLineMode } from '../../utils/lineToHandoverLineMode';
import { HandoverLineMode } from './HandoverLineMode';
import { useApiEffect } from 'framework/hooks/useApiEffect';

interface IModel {
	mode: HandoverLineMode;
	rizivHearingAidDefinitionIdentificationCode: number | undefined;
	manufacturer: string | undefined;
	model: string | undefined;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			mode: yup.mixed(),
			rizivHearingAidDefinitionIdentificationCode: yup.string(),
			manufacturer: yup.string(),
			model: yup.string(),
		})
		.defined();
};

const toModel = (model: IModel, rfiaId: string, lineId: string): IPatchRfiaHandoverLineRequest => {
	return {
		rfiaId: rfiaId,
		lineId: lineId,
		isIncludeOnHandoverCertificate: model.mode !== 'exclude',
		isRefundable: model.mode === 'refundable',
		rizivHearingAidDefinitionIdentificationCode: model.rizivHearingAidDefinitionIdentificationCode,
		manufacturer: model.manufacturer ?? '',
		model: model.model ?? '',
	};
};

interface IProps extends DialogProps {
	line: IEquipmentDeliveryLine;
	rfiaId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
	handoverDate: Date;
}

export const UpdateHandoverLineForm = ({ rfiaId, line, confirm, cancel, handoverDate, ...rest }: IProps) => {
	const strings = useLocalization();
	const schema = useMemo(() => createSchema(strings), [strings]);
	const [update, isSubmitting] = useFormSubmit(rfiasHandoverCommand_patchHandoverLine);
	const [rizivHearingAidDefinition] = useApiEffect(
		rizivHearingAidDefinitionsV2Query_identifyAndConvertToV2,
		line.rizivHearingAidDefinitionV1IdentificationCode ?? ''
	);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await update(toModel(values, rfiaId, line.id));
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	if (rizivHearingAidDefinition === undefined) {
		return <></>;
	}

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{
				mode: lineToHandoverLineMode(line),
				rizivHearingAidDefinitionIdentificationCode: rizivHearingAidDefinition?.identificationCode ?? undefined,
				manufacturer: line.manufacturer,
				model: line.model,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					line={line}
					isSubmitting={isSubmitting}
					cancel={cancel}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	line: IEquipmentDeliveryLine;
	isSubmitting: boolean;
}

const InnerDialog = ({ cancel, line, isSubmitting, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const props = useFormikContext<IModel>();
	const { open, confirm, isStacked } = useDialogsContext();
	const call = useApiCall(inventoryItemsQuery_single);

	const onLink = async () => {
		if (line.inventoryItemId) {
			const r = await call(line.inventoryItemId);
			if (r.isSuccess) {
				open(
					<SelectRizivRefundableHearingInstrumentForInventoryItemForm
						open
						item={r.result}
						confirm={onSelect}
						cancel={confirm}
					/>
				);
			}
		}
	};

	const onSelect = (item: IRizivHearingAidDefinitionV2Dto) => {
		confirm();
		setFormValue<IModel>('rizivHearingAidDefinitionIdentificationCode', item.identificationCode, props);
		setFormValue<IModel>('manufacturer', item.manufacturer, props);
		setFormValue<IModel>('model', item.name, props);
	};

	const onDeleteLink = () => {
		setFormValue<IModel>('rizivHearingAidDefinitionIdentificationCode', 'n/a', props);
		setFormValue<IModel>('manufacturer', '', props);
		setFormValue<IModel>('model', '', props);
	};

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			fullWidth
			maxWidth='sm'>
			<DialogTitle>{`${strings.changeWhat(strings.line)}: #${line.indexNumber}; ${line.description}`}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<LightBulbNotification>{strings.warningOnlyThingsLinkedToAttachment12CanBeAltered}</LightBulbNotification>
				<Divider style={{ marginTop: 16, marginBottom: 16 }} />
				<FormExtendedRadioGroupField<IModel, HandoverLineMode>
					name='mode'
					label='Modus?'
					options={[
						{ value: 'exclude', label: strings.doNotMention, caption: strings.doNotMentionCaption },
						{ value: 'refundable', label: strings.refundable, caption: strings.refundableCaption },
						{ value: 'nonRefundable', label: strings.notRefundable, caption: strings.notRefundableCaption },
					]}
				/>
				{line.isInventoryItem && (
					<>
						<DividerWithCaption
							caption={strings.RIZIVCoding}
							action={<SmallPrimaryTextButton onClick={onLink}>{strings.change}</SmallPrimaryTextButton>}
						/>
						<div className='df-row-ac'>
							{isNotNullNorUndefined(props.values.rizivHearingAidDefinitionIdentificationCode) ? (
								<div className='df-row-ac'>
									<div style={{ marginRight: 8 }}>{`${props.values.manufacturer}, ${props.values.model}`}</div>
									<RizivRefundableHearingInstrumentChip
										identificationCode={props.values.rizivHearingAidDefinitionIdentificationCode!}
										noMargin
										reload={() => {}}
									/>
									<IconButton
										onClick={onLink}
										edge='end'
										size='small'
										style={{ marginLeft: 8 }}>
										<EditOutlinedIcon />
									</IconButton>
									<IconButton
										onClick={onDeleteLink}
										edge='end'
										size='small'>
										<DeleteOutlineIcon />
									</IconButton>
								</div>
							) : (
								<em>{strings.notApplicableAbbreviation}</em>
							)}
						</div>
					</>
				)}
			</DialogContent>
			<CancelSubmitFormDialogActions
				submitText={strings.change}
				isSubmitting={isSubmitting}
				cancel={cancel}
			/>
		</Dialog>
	);
};
