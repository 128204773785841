import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import EventIcon from '@material-ui/icons/Event';
import { FileDocumentOutlineIcon } from 'framework/components/icons/FileDocumentOutlineIcon';
import { GoogleEarthIcon } from 'framework/components/icons/GoogleEarthIcon';
import React from 'react';
import { PatientDetailTabType } from './PatientDetailTabType';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

export const PatientDetailTabTypeIconRecord: Record<PatientDetailTabType, React.ReactNode> = {
	annotationsAndFollowUp: <CommentOutlinedIcon />,
	noahData: <GoogleEarthIcon />,
	overview: <DashboardOutlinedIcon />,
	personalData: <AssignmentIndOutlinedIcon />,
	documents: <FileDocumentOutlineIcon />,
	appointments: <EventIcon />,
	sales: <AttachMoneyOutlinedIcon />,
	workflows: <DoubleArrowIcon />,
	tenders: <AttachMoneyOutlinedIcon />,
};
