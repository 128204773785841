import { v1 as uuid } from 'uuid';
import { IInventoryItem, IProductCatalogItem } from 'gen/ApiClient';
import { IPurchaseFormLine } from './IPurchaseFormLine';

const toDescription = (t: IProductCatalogItem, line: IPurchaseFormLine | IInventoryItem): string => {
	if (line.serialNumber) {
		return `${t.name} sn: ${line.serialNumber}`;
	} else {
		return t.name ?? '';
	}
};

const toPcId = (line: IPurchaseFormLine | IInventoryItem): string => {
	if ((line as IInventoryItem).productCatalogId) {
		return (line as IInventoryItem).productCatalogId;
	} else {
		return (line as IPurchaseFormLine).productCatalogItemId!;
	}
};

export const assuranceToLine = (assurance: IProductCatalogItem, line: IPurchaseFormLine | IInventoryItem): IPurchaseFormLine => {
	return {
		id: uuid(),
		description: toDescription(assurance, line),
		inventoryItemId: undefined,
		productCatalogItemId: assurance.id,
		quantity: 1,
		vatPercentage: assurance.vatPercentage,
		productCatalogItem: assurance,
		assuredLineId: line.id,
		assuredProductCatalogItemId: toPcId(line),
		canChangeQuantity: false,
		serialNumber: '',
		unitListPrice: assurance.purchaseListPrice,
		unitPrice: assurance.purchaseListPrice,
		assuredSerialNumber: line.serialNumber,
		isCreditLine: false,
		creditedAssuranceLineId: undefined,
	};
};
