import React, { useContext } from 'react';
import { IDocumentSummary, IRequestForInsuranceAllowance } from 'gen/ApiClient';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { CosiDocumentDetailDialog } from '../../cosi/CosiDocumentDetailDialog';
import { CosiQuestionnaireOrDocumentForm } from '../../cosi/CosiQuestionnaireOrDocumentForm';
import { CosiStatusFibers } from '../../cosi/CosiStatusFibers';
import { DocumentListItemOrEmpty } from 'documents/DocumentListItemOrEmpty';

interface IProps {
	rfia: IRequestForInsuranceAllowance;
	document: IDocumentSummary;
	reload: VoidFunction;
}

export const CosiListItem = ({ rfia, document, reload }: IProps) => {
	const { templateTypeRecord } = useContext(RecordContext);
	const { open, cancel, confirm } = useDialogsContext(reload);

	const onNew = () => {
		open(
			<CosiQuestionnaireOrDocumentForm
				confirm={confirm}
				cancel={cancel}
				rfia={rfia}
				reload={reload}
			/>
		);
	};

	const onViewDetail = (id: string) => {
		open(
			<CosiDocumentDetailDialog
				open
				id={id}
				close={confirm}
				rfiaId={rfia.id}
			/>
		);
	};

	return (
		<DocumentListItemOrEmpty
			onNew={onNew}
			reload={reload}
			onViewDetail={onViewDetail}
			document={document}
			title={templateTypeRecord['RfiaCosi']}>
			{document && rfia.cosiQuestionnaire && (
				<CosiStatusFibers
					hasSignature={document?.hasSignature ?? false}
					cosiQuestionnaire={rfia.cosiQuestionnaire}
				/>
			)}
		</DocumentListItemOrEmpty>
	);
};
