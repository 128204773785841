import { LoanExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createLoanExportRecord = (strings: IStrings): Record<LoanExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		CanReturnItems: strings.canReturn,
		CountItems: `# ${strings.items}`,
		ForPatientId: `${strings.forPatient} ID`,
		ForPatientIdentifier: strings.forPatient,
		HasReturnedItems: `${strings.itemsReturned}?`,
		HasWarning: `${strings.warning}?`,
		IsCancelled: `${strings.cancelled}?`,
		IsCompleted: `${strings.completed}?`,
		Reference: strings.reference,
		StartDate: strings.startDate,
		Status: strings.status,
		StopDate: strings.stopDate,
	};
};
