import { patientsQuickFilterLsKey } from 'app/localStorageKeys';
import { useLocalStorage } from 'framework/hooks/useLocalStorage';
import { useParamsFromQueryContext } from 'framework/hooks/useParamsFromQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { PatientStatus } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';
import { createPatientHeaders } from './createPatientHeaders';
import { createPatientQuickFilterRecord } from './createPatientQuickFilterRecord';
import { createPatientsExportRecord } from './createPatientsExportRecord';
import { createPatientsColumnRecord } from './createPatientsListColumnRecord';
import { PatientQuickFilter } from './PatientQuickFilter';
import { PatientsListViewContext } from './PatientsListViewContext';
import { PatientsQueryContext } from './PatientsQueryContext';
import { PatientsSelectableColumn } from './PatientsSelectableColumn';

// enkel hier nodig
const DefaultColumns: PatientsSelectableColumn[] = ['lastNameCommaFirstNameBracketsNickName', 'dateOfBirth', 'created'];

export const PatientsListViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [quickFilter, setQuickFilter] = useLocalStorage<PatientQuickFilter>(patientsQuickFilterLsKey, 'active');
	const columnRecord = useMemo(() => createPatientsColumnRecord(strings), [strings]);
	const headers = useMemo(() => createPatientHeaders(columnRecord), [columnRecord]);
	const exportRecord = useMemo(() => createPatientsExportRecord(strings), [strings]);
	const quickFilterRecord = useMemo(() => createPatientQuickFilterRecord(strings), [strings]);
	const { setParams } = useParamsFromQueryContext(PatientsQueryContext);
	const [columns, setColumns] = useState<PatientsSelectableColumn[]>(DefaultColumns);

	useEffect(() => {
		setParams(params =>
			onResetToPage1({
				...params,
				keyStatus: quickFilter === 'active' ? ('Active' as PatientStatus) : undefined,
				statuses: quickFilter === 'active' ? [] : params.statuses,
			})
		);
		// eslint-disable-next-line
	}, [quickFilter]);

	return (
		<PatientsListViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: columnRecord,
				headers: headers,
				setColumns: setColumns,
				exportRecord: exportRecord,
				quickFilter: quickFilter,
				setQuickFilter: setQuickFilter,
				quickFilterRecord: quickFilterRecord,
			}}>
			{children}
		</PatientsListViewContext.Provider>
	);
};
