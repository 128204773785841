import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useLocalization } from 'localization/useLocalization';
import { SignatureMode } from './SignatureMode';

interface IProps extends DialogProps {
	title: string;
	initialValue: SignatureMode;
	confirm: (mode: SignatureMode) => void;
	cancel: VoidFunction;
}

export const SelectSignatureModeDialog = ({ title, initialValue, confirm, cancel, ...rest }: IProps) => {
	const [mode, setMode] = useState<SignatureMode>(initialValue);
	const strings = useLocalization();

	return (
		<Dialog {...rest}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<SignatureMode>
					selected={mode}
					setSelected={setMode}
					options={[
						{ value: 'hasSignature', label: strings.withWhat(strings.signature), caption: strings.thisDocumentContainsASignature },
						{ value: 'mustSignLater', label: strings.scanSignatureLater, caption: strings.thisDocumentMustBeReplacedWithAScanWithSignature },
						{ value: 'neverSign', label: strings.noSignatureNeeded, caption: strings.noSignatureIsNeededForThisDocument },
					]}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				submitText={strings.select}
				onSubmit={() => confirm(mode)}
				isSubmitting={false}
			/>
		</Dialog>
	);
};
