import React from 'react';
import { useHistory } from 'react-router-dom';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	route: string | undefined;
	disabled?: boolean;
}

export const DetailsNavigateButton = ({ route, disabled }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();

	return (
		<SmallPrimaryTextButton
			disabled={disabled || route === undefined}
			onClick={route === undefined ? undefined : () => push(route)}>
			{strings.details}
		</SmallPrimaryTextButton>
	);
};
