import { SvgIconProps } from '@material-ui/core';
import DevicesOtherOutlinedIcon from '@material-ui/icons/DevicesOtherOutlined';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import HearingOutlinedIcon from '@material-ui/icons/HearingOutlined';
import PowerOutlinedIcon from '@material-ui/icons/PowerOutlined';
import VolumeUpOutlinedIcon from '@material-ui/icons/VolumeUpOutlined';
import { ProductCatalogItemType } from 'gen/ApiClient';
import React from 'react';

interface IProps extends SvgIconProps {
	type: ProductCatalogItemType;
}

export const ProductCatalogItemTypeIcon = ({ type, ...props }: IProps) => {
	if (type === 'HearingInstrument') {
		return <HearingOutlinedIcon {...props} />;
	} else if (type === 'Earmould') {
		return <GpsFixedIcon {...props} />;
	} else if (type === 'Receiver') {
		return <VolumeUpOutlinedIcon {...props} />;
	} else if (type === 'Charger') {
		return <PowerOutlinedIcon {...props} />;
	} else {
		return <DevicesOtherOutlinedIcon {...props} />;
	}
};
