import React, { useState } from 'react';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IColumnSelection, viewColumnSelectionsCommand_update } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { ColumnSelectionModelForm } from './ColumnSelectionModelForm';
import { OverwriteOrNewMode, SelectOverwriteOrNewDialog } from './SelectOverwriteOrNewDialog';

interface IProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	selected?: IColumnSelection;
	view: string;
	columns: string[];
}

export const ColumnSelectionForm = ({ cancel, confirm, view, columns, selected }: IProps) => {
	const strings = useLocalization();
	const [state, setState] = useState<'new' | 'select'>(selected === undefined ? 'new' : 'select');
	const [update, isUpdating] = useFormSubmit(viewColumnSelectionsCommand_update);

	const onSelectMode = async (mode: OverwriteOrNewMode) => {
		if (mode === 'overwrite') {
			const r = await update(view, selected!.id, { columns: columns, useAsDefault: selected!.useAsDefault, name: selected!.name });
			if (r.isSuccess) {
				confirm(selected!.id);
			}
		} else {
			setState('new');
		}
	};

	if (state === 'select') {
		return (
			<SelectOverwriteOrNewDialog
				open
				confirm={onSelectMode}
				cancel={cancel}
				overwriteCaption={strings.overwriteColumnsOfWhat(`'${selected!.name}'`)}
				isOverwriting={isUpdating}
			/>
		);
	} else {
		return (
			<ColumnSelectionModelForm
				open
				view={view}
				columns={columns}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	}
};
