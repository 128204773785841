import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { WizardButton } from './WizardButton';

interface IProps {
	onClick: VoidFunction;
}

export const DiscountWizardButton = ({ onClick }: IProps) => {
	const strings = useLocalization();

	return (
		<WizardButton
			startIcon={<LocalOfferOutlinedIcon />}
			onClick={onClick}>
			{strings.addALineForExampleDiscount}
		</WizardButton>
	);
};
