import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { ProcessesPageBreadcrumbTitle } from '../ProcessesPageBreadcrumbTitle';
import { RepairsRoute } from '../ProcessesRoutes';

export const RepairsPageBreadcrumbs = () => {
	const strings = useLocalization();

	return (
		<div className='df-row-ac'>
			<ProcessesPageBreadcrumbTitle />
			<PageBreadcrumbDivider />
			<PageBreadcrumb
				text={strings.repairs}
				route={RepairsRoute}
				icon={<BuildOutlinedIcon />}
			/>
		</div>
	);
};
