import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React from 'react';

interface IProps extends DialogProps {
	title: string;
}

export const EmptyDialog = ({ title, ...rest }: IProps) => {
	return (
		<Dialog
			maxWidth='sm'
			fullWidth
			{...rest}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'></DialogContent>
		</Dialog>
	);
};
