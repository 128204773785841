import { Card, CardMedia, Divider, Paper, useTheme } from '@material-ui/core';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { TablePaging } from 'framework/table/TablePaging';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { INotificationsQueryParams, notificationsQuery_query } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { Page } from 'shared/layout/Page';
import { NotificationsContext } from './context/NotificationsContext';
import { Filter } from './Filter';
import { NotificationListItem } from './NotificationListItem';

const DefaultParams: INotificationsQueryParams = {
	pageParams: { index: 1, size: 10 },
	searchString: '',
	types: [],
	after: undefined,
	before: undefined,
};

export const NotificationsList = () => {
	const strings = useLocalization();
	const theme = useTheme();
	const [params, setParams, queryResult, reload] = useApiEffectWithParams(notificationsQuery_query, DefaultParams);
	const { trigger, onView } = useContext(NotificationsContext);

	useLazyEffect(() => {
		reload();
	}, [trigger]);

	return (
		<Page
			showHome
			title={strings.notifications}
			reload={reload}>
			<div
				className='df-row-ac jc-sb'
				style={{ marginBottom: '24px' }}>
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder={strings.searchVerb}
					style={{ width: '400px' }}
					variant='paper'
				/>
			</div>
			<Paper style={{ marginBottom: 16 }}>
				<Filter
					params={params}
					onFilter={params => setParams(onResetToPage1(params))}
					paddingLeft={theme.spacing(1.5)}
				/>
			</Paper>
			{queryResult && queryResult.filteredCount > 0 && (
				<Card>
					<CardMedia>
						{queryResult.values.map(t => (
							<React.Fragment key={t.id}>
								<NotificationListItem
									item={t}
									onView={onView}
								/>
								<Divider />
							</React.Fragment>
						))}
						<TablePaging
							removeBorder
							countResults={queryResult.filteredCount}
							pageSize={queryResult.pageSize}
							page={queryResult.zeroBasedPageIndex}
							pageParams={params.pageParams}
							setPageParams={val => setParams({ ...params, pageParams: val })}
						/>
					</CardMedia>
				</Card>
			)}
			{queryResult && queryResult.filteredCount === 0 && <NoRecordsFound>{strings.nothingFoundCommaChangeSearchParams}</NoRecordsFound>}
		</Page>
	);
};
