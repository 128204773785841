import { parse } from 'date-fns';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { IEidData } from 'gen/ApiClient';
import { getNow } from 'shared/utils/getNow';

const getNodeValue = (xmlDoc: Document, nodeName: string): string => {
	const nodes = xmlDoc.getElementsByTagName(nodeName);
	if (nodes.length > 0 && nodes[0].childNodes.length > 0) {
		return nodes[0].childNodes[0].nodeValue ?? '';
	} else {
		return '';
	}
};

const getAttributeValue = (xmlDoc: Document, nodeName: string, attributeName: string): string => {
	const nodes = xmlDoc.getElementsByTagName(nodeName);
	if (nodes.length > 0 && nodes[0].getAttribute(attributeName) !== null) {
		return nodes[0].getAttribute(attributeName) ?? '';
	} else {
		return '';
	}
};

const mapToDate = (dateAsString: string): Date => {
	return parse(dateAsString, 'yyyyMMdd', getNow());
};

export const parseEidDataOrDefault = (data: string): IEidData | undefined => {
	const xmlDoc = new DOMParser().parseFromString(data, 'text/xml');
	const parsed: IEidData = {
		firstNames: getNodeValue(xmlDoc, 'firstname'),
		surName: getNodeValue(xmlDoc, 'name'),
		socialSecurityNumber: getAttributeValue(xmlDoc, 'identity', 'nationalnumber'),
		addressMunicipality: getNodeValue(xmlDoc, 'municipality'),
		addressStreetAndNumber: getNodeValue(xmlDoc, 'streetandnumber'),
		addressZip: getNodeValue(xmlDoc, 'zip'),
		cardNumber: getAttributeValue(xmlDoc, 'card', 'cardnumber'),
		dateOfBirth: mapToDate(getAttributeValue(xmlDoc, 'identity', 'dateofbirth')),
		gender: getAttributeValue(xmlDoc, 'identity', 'gender'),
		issuedMunicipality: getNodeValue(xmlDoc, 'deliverymunicipality'),
		locationOfBirth: getNodeValue(xmlDoc, 'placeofbirth'),
		nationality: getNodeValue(xmlDoc, 'nationality'),
		photo: getNodeValue(xmlDoc, 'photo'),
		validityBeginDate: mapToDate(getAttributeValue(xmlDoc, 'card', 'validitydatebegin')),
		validityEndDate: mapToDate(getAttributeValue(xmlDoc, 'card', 'validitydateend')),
	};
	if (isNullOrUndefined(parsed.surName)) {
		return undefined;
	} else if (isNaN(parsed.dateOfBirth.getTime())) {
		return undefined;
	} else {
		return parsed;
	}
};
