import { format as formatDateFns } from 'date-fns';
import { FormatDateXType } from './FormatDateXType';

export function formatDateX(date: Date | undefined | null | string | number, format: FormatDateXType = 'dd/MM/yyyy'): string {
	if (date === undefined || date === null) {
		return '';
	} else {
		return formatDateFns(date instanceof Date ? date : new Date(date), format);
	}
}
