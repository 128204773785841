import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { formatDate } from 'framework/utils/date/formatDate';
import { IReturn, returnsCommand_patchExternalReference, returnsCommand_patchReturnDate } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

interface IProps {
	item: IReturn;
	reload: VoidFunction;
}

export const ReturnDetailPageSidebar = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onPatchExternalReference = () => {
		open(
			<StringModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.changeSomething(strings.externalReference)}
				label={strings.externalReference}
				initialValue={item.externalReference}
				cancel={cancel}
				submitText={strings.update}
				submitFunction={value => returnsCommand_patchExternalReference({ returnId: item.id, externalReference: value })}
			/>
		);
	};

	const onPatchReturnDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				formTitle={strings.changeWhat(strings.returnDate)}
				label={strings.returnDate}
				initialValue={item.returnDate}
				submitFunction={date => returnsCommand_patchReturnDate({ returnId: item.id, returnDate: date })}
			/>
		);
	};

	return (
		<div
			className='df-col gap-6 h100'
			style={{
				padding: 16,
				paddingRight: 8,
				minWidth: 200,
			}}>
			<CaptionVerticalLabelledPropertyX label={strings.reference}>{item.reference}</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.externalReference}
				edit={onPatchExternalReference}>
				{item.externalReference ? item.externalReference : strings.notApplicableAbbreviation}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.entryDate}>{formatDate(item.created)}</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.returnDate}
				edit={onPatchReturnDate}>
				{formatDate(item.returnDate)}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.supplier}>{item.toSupplierIdentifier}</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.location}>{item.fromLocationIdentifier}</CaptionVerticalLabelledPropertyX>
		</div>
	);
};
