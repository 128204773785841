import { DialogProps } from '@material-ui/core';
import React from 'react';
import { documentsCommand_createFromTemplate, LinkViewContextType, TemplateType } from 'gen/ApiClient';
import { SelectTemplateOrStandardDocumentFromPredefinedTypeForm } from './SelectTemplateOrStandardDocumentFromPredefinedTypeForm';

interface IProps extends DialogProps {
	cancel: VoidFunction;
	confirm: VoidFunction;
	context: LinkViewContextType;
	contextId: string;
	templatePredefinedType: TemplateType;
	typeOverrideName?: string;
	submitText?: string;
	cancelText?: string;
	title?: string;
}

export const DocumentFromTemplateOrStandardDocumentForm = ({ context, contextId, templatePredefinedType, ...rest }: IProps) => {
	return (
		<SelectTemplateOrStandardDocumentFromPredefinedTypeForm
			{...rest}
			type={templatePredefinedType}
			submitF={({ templateId, standardDocumentId }) =>
				documentsCommand_createFromTemplate({
					contextId: contextId,
					linkViewContextType: context,
					templateId: templateId,
					standardDocumentId: standardDocumentId,
				})
			}
		/>
	);
};
