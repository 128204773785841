import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import React from 'react';
import { StockTransactionTabType } from './StockTransactionTabType';

export const StockTransactionTabIconRecord: Record<StockTransactionTabType, React.ReactNode> = {
	deliveries: <LocalShippingOutlinedIcon />,
	returns: <KeyboardReturnIcon />,
	'stock-movements': <PinDropOutlinedIcon />,
};
