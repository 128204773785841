import React, { useContext, useEffect } from 'react';
import { ThermalPrinterApplicationContext } from 'app/externalApps/thermalPrinter/ThermalPrinterApplicationContext';
import { DownloadAndInstallThermalPrinterSoftwareDialog } from '../../downloads/DownloadAndInstallThermalPrinterSoftwareDialog';
import { EmptyDialog } from '../../EmptyDialog';
import { InstalledDialog } from './InstalledDialog';
import { IPrintSalesTicketDialogProps } from './IPrintSalesTicketDialogProps';

export const PrintSalesTicketDialog = ({ title, ...props }: IPrintSalesTicketDialogProps) => {
	const { isInstalled, isInitialized, check } = useContext(ThermalPrinterApplicationContext);

	useEffect(() => {
		check();
	}, [check]);

	if (isInitialized === false) {
		return (
			<EmptyDialog
				open
				title={title}
			/>
		);
	} else if (isInstalled === true) {
		return (
			<InstalledDialog
				title={title}
				{...props}
			/>
		);
	} else {
		return (
			<DownloadAndInstallThermalPrinterSoftwareDialog
				open
				close={check}
			/>
		);
	}
};
