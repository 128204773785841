import React from 'react';
import { CacheProvider } from 'framework/context/CacheProvider';
import { IInventoryItemSearchData, inventoryItemsQuery_concurrencyToken, inventoryItemsQuery_searchData } from 'gen/ApiClient';
import { InventoryItemsFilterDataContext } from './InventoryItemsFilterDataContext';

export const FilterDataProvider = ({ children }: any) => {
	return (
		<CacheProvider<IInventoryItemSearchData>
			concurrencyF={inventoryItemsQuery_concurrencyToken}
			loadF={inventoryItemsQuery_searchData}
			context={InventoryItemsFilterDataContext}>
			{children}
		</CacheProvider>
	);
};
