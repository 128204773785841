import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';

interface IProps {
	value: string;
	setValue: (val: string) => void;
	options: string[];
	style?: React.CSSProperties;
	className?: string;
	disabled?: boolean;
	freeSolo?: boolean;
}

export const AutocompleteSingle = ({ value, setValue, options, style, className, disabled, freeSolo }: IProps) => {
	const [inputValue, setInputValue] = useState<string>(value);

	return (
		<Autocomplete
			inputValue={inputValue}
			onInputChange={(e, value, reason) => setInputValue(value)}
			freeSolo={freeSolo}
			className={className}
			style={style}
			options={options}
			value={value}
			clearOnBlur
			autoSelect
			autoHighlight
			onChange={(e, value, reason) => setValue(value === null ? '' : value)}
			disabled={disabled}
			onClose={(_, reason) => {
				if (reason === 'blur' && freeSolo === true) {
					setValue(inputValue);
				}
			}}
			renderInput={params => (
				<TextField
					{...params}
					variant='outlined'
					inputProps={{ ...params.inputProps, style: { padding: 0 } }}
					// InputProps={{ ...params.InputProps, style: { padding: 9 } }}
				/>
			)}
		/>
	);
};
