import { useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { SerialNumberIcon } from 'framework/components/icons/SerialNumberIcon';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { INoahDeviceInventoryItemMapDetail } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { XViewBadgeIconButton } from 'shared/icons/XViewBadgeIconButton';
import { NoahDeviceDialog } from '../../noahDevices/single/detail/NoahDeviceDialog';

interface IProps {
	item: INoahDeviceInventoryItemMapDetail;
	reload: VoidFunction;
}

export const NoahDeviceHeader = ({ item, reload }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { open, confirm } = useDialogsContext(reload);

	const onView = () => {
		open(
			<NoahDeviceDialog
				open
				id={item.serialNumber}
				close={confirm}
			/>
		);
	};

	if (item.hasNoahUsages) {
		return (
			<div
				className='df-row-ac'
				style={{ color: theme.palette.success.main }}>
				<TooltipWithSpan title={strings.foundInReadOutsFromNoah}>
					<CheckIcon style={{ marginRight: 4 }} />
				</TooltipWithSpan>
				<XViewBadgeIconButton
					size='small'
					onClick={onView}>
					<SerialNumberIcon fontSize='small' />
				</XViewBadgeIconButton>
			</div>
		);
	} else {
		return (
			<TooltipWithSpan title={strings.notFoundInReadOutsFromNoah}>
				<ClearIcon style={{ marginRight: 4, color: theme.palette.error.main }} />
			</TooltipWithSpan>
		);
	}
};
