import { Checkbox, Paper } from '@material-ui/core';
import { QuantityField } from 'framework/components/textFields/QuantityField';
import React from 'react';
import { IndexAvatar } from 'shared/searchAndSelect/lines/IndexAvatar';
import { LineWarningsAndErrors } from 'shared/searchAndSelect/lines/LineWarningsAndErrors';
import { IArticlesInquiryLineDtoWithIsSelected } from './IArticlesInquiryLineDtoWithIsSelected';

interface IProps {
	line: IArticlesInquiryLineDtoWithIsSelected;
	index: number;
	setLine: (val: IArticlesInquiryLineDtoWithIsSelected) => void;
	style?: React.CSSProperties;
}

export const OrderArticlesInquiryLineComponent = ({ line, index, setLine, style }: IProps) => {
	return (
		<Paper
			variant='outlined'
			style={{ padding: 8, ...style }}>
			<div className='df-row gap-8'>
				<div
					className='df-col-ac'
					style={{ marginLeft: -6, ...style }}>
					<IndexAvatar
						index={index}
						color='normal'
					/>
					<Checkbox
						checked={line.isSelected}
						tabIndex={-1}
						disableRipple
						onClick={() => setLine({ ...line, isSelected: !line.isSelected })}
					/>
				</div>
				<div className='df-col gap-8 w100'>
					<div className='df-row-ac'>
						<div>{line.description}</div>
						<div className='fg1'></div>
						<QuantityField
							value={line.quantityToOrder}
							setValue={val => setLine({ ...line, quantityToOrder: val })}
							error={line.isSelected && line.quantityToOrder > line.quantityToAssign}
							helperText={line.isSelected && line.quantityToOrder > line.quantityToAssign && `Max. ${line.quantityToAssign}`}
							max={line.quantityToAssign}
							disabled={line.isUniquelyIdentifiable || line.isSelected === false}
						/>
					</div>
				</div>
			</div>
			{line.isSelected === true && <LineWarningsAndErrors errors={line.error} />}
		</Paper>
	);
};
