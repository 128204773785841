import React, { useState } from 'react';
import { SelectPatientByIdForm } from 'shared/patientSearch/SelectPatientByIdForm';
import { TenderForForm } from './TenderForForm';
import { TenderModelForm } from './TenderModelForm';

interface IProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

type State = 'for' | 'patient' | 'model';

export const TenderForm = ({ cancel, confirm }: IProps) => {
	const [state, setState] = useState<State>('for');
	const [patientId, setPatientId] = useState<string>();

	const confirmFor = (isForPatient: boolean) => {
		setState(isForPatient ? 'patient' : 'model');
		setPatientId(undefined);
	};

	const confirmPatient = (patientId: string) => {
		setPatientId(patientId);
		setState('model');
	};

	if (state === 'for') {
		return (
			<TenderForForm
				open
				confirm={confirmFor}
				cancel={cancel}
			/>
		);
	} else if (state === 'patient') {
		return (
			<SelectPatientByIdForm
				open
				confirm={confirmPatient}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<TenderModelForm
				open
				confirm={confirm}
				cancel={cancel}
				patientId={patientId}
			/>
		);
	}
};
