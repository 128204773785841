import { useResizeObserver } from 'framework/hooks/useResizeObserver';
import { IInventoryItemDetail } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { ArticlePropertiesCard } from './articleProperties/ArticlePropertiesCard';
import { AssurancesInfoCard } from './AssurancesInfoCard';
import { ConsigmentInfoCard } from './ConsigmentInfoCard';
import { DispatchInfoCard } from './DispatchInfoCard';
import { HearingAidPropertiesCard } from './HearingAidPropertiesCard';
import { LinkedNoahDeviceCard } from './LinkedNoahDeviceCard';
import { ReservationCard } from './ReservationCard';
import { RizivRefundableDeviceLinkCard } from './RizivRefundableDeviceLinkCard';
import { TailorMadeForPatientCard } from './TailorMadeForPatientCard';
import { PricesAndMarginsCard } from './PricesAndMarginsCard';

interface IProps {
	detail: IInventoryItemDetail;
	reload: VoidFunction;
}

export const OverviewPage = ({ detail, reload }: IProps) => {
	const item = useMemo(() => detail.data, [detail]);
	const [ref, width] = useResizeObserver();

	return (
		<div
			className='df-row gap-16'
			ref={ref}>
			<div
				className='df-col gap-16'
				style={{ width: 350 }}>
				{item.isTailorMade && (
					<TailorMadeForPatientCard
						item={item}
						reload={reload}
					/>
				)}
				{(item.isTailorMade === false || item.isReserved) && (
					<ReservationCard
						item={item}
						reload={reload}
					/>
				)}
				<PricesAndMarginsCard item={item} />
				<DispatchInfoCard
					item={item}
					reload={reload}
				/>
				<ConsigmentInfoCard
					item={item}
					reload={reload}
				/>
				<AssurancesInfoCard
					item={item}
					reload={reload}
				/>
			</div>
			{width < 1082 && (
				<div
					className='df-col gap-16'
					style={{ width: 350 }}>
					{item.isHearingInstrument && <HearingAidPropertiesCard item={item} />}
					{item.canLinkRizivRefundableHearingInstrument && (
						<RizivRefundableDeviceLinkCard
							item={item}
							reload={reload}
						/>
					)}
					{detail.hasLinkedNoahDevice && (
						<LinkedNoahDeviceCard
							detail={detail}
							reload={reload}
						/>
					)}
					<ArticlePropertiesCard
						item={item}
						reload={reload}
					/>
				</div>
			)}
			{width > 1082 && (
				<>
					{(item.isHearingInstrument || item.canLinkRizivRefundableHearingInstrument || detail.hasLinkedNoahDevice) && (
						<div
							className='df-col gap-16'
							style={{ width: 350 }}>
							{item.isHearingInstrument && <HearingAidPropertiesCard item={item} />}
							{item.canLinkRizivRefundableHearingInstrument && (
								<RizivRefundableDeviceLinkCard
									item={item}
									reload={reload}
								/>
							)}
							{detail.hasLinkedNoahDevice && (
								<LinkedNoahDeviceCard
									detail={detail}
									reload={reload}
								/>
							)}
						</div>
					)}
					<div
						className='df-col gap-16'
						style={{ width: 350 }}>
						<ArticlePropertiesCard
							item={item}
							reload={reload}
						/>
					</div>
				</>
			)}
		</div>
	);
};
