import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormPasswordField } from 'framework/forms/FormPasswordField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IResetUserPasswordRequest, IUserDto, usersCommand_resetPassword } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
    return yup
        .object<IResetUserPasswordRequest>({
            password: yup.string().required(strings.formRequired(strings.password)),
            userId: yup.string().required(),
        })
        .defined();
};

interface IProps extends DialogProps {
    user: IUserDto;
    confirm: VoidFunction;
    cancel: VoidFunction;
}

export const ResetPasswordForm = ({ user, confirm, cancel, ...rest }: IProps) => {
    const notify = useSnackbarNotify();
    const [reset, isSubmitting] = useFormSubmit(usersCommand_resetPassword);
    const strings = useLocalization();

    const handleSubmit = async (values: IResetUserPasswordRequest, helpers: FormikHelpers<IResetUserPasswordRequest>) => {
        const r = await reset(values);
        if (handleFormResponse(r, helpers)) {
            notify(strings.passwordChanged);
            confirm();
        }
    };

    return (
        <Formik<IResetUserPasswordRequest>
            validateOnMount
            initialValues={{
                password: '',
                userId: user.id,
            }}
            validationSchema={createSchema(strings)}
            onSubmit={handleSubmit}>
            <Form>
                <Dialog
                    fullWidth
                    maxWidth='xs'
                    {...rest}>
                    <DialogTitle>{`Reset password (only for super-users)`}</DialogTitle>
                    <DialogContent
                        className='df-col'
                        dividers>
                        <FormPasswordField<IResetUserPasswordRequest>
                            name='password'
                            label={strings.newPassword}
                            required
                        />
                    </DialogContent>
                    <CancelSubmitFormDialogActions
                        submitText={`Reset`}
                        isSubmitting={isSubmitting}
                        cancel={cancel}
                    />
                </Dialog>
            </Form>
        </Formik>
    );
};
