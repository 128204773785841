import BlurCircularOutlinedIcon from '@material-ui/icons/BlurCircularOutlined';
import React from 'react';

interface IProps {
	style?: React.CSSProperties;
}

export const CompanyListItemStatusIcon = ({ style }: IProps) => {
	return <BlurCircularOutlinedIcon style={style} />;
};
