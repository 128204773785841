import React from 'react';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { FailedEventStatus, IFailedEvent } from 'gen/ApiClient';
import { SelectableColumn } from '../SelectableColumn';

interface IViewContext extends ITableViewContext<SelectableColumn, IFailedEvent> {
	statusRecord: Record<FailedEventStatus, string>;
}

export const ViewContext = React.createContext<IViewContext>(undefined as any);
