import { IconButton, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { IOption } from '../../IOption';
import AddCircleIcon from '@material-ui/icons/AddCircle';

interface IProps<TValue extends IOption<string>> {
	options: TValue[];
	selectedId: string | undefined; // id
	setSelectedId: (id: string | undefined) => void;
	error?: boolean;
	disabled?: boolean;
	label?: string;
	required?: boolean;
	onCreateNew: (input: string) => void;
	renderCreate: (input: string) => string;
	style?: React.CSSProperties;
}

const renderOption = <TValue extends IOption<string>>(id: string, options: TValue[]): string => {
	return options.find(t => t.id === id)?.identifier ?? '';
};

export const AutocompleteSingleWithAdd = <TValue extends IOption<string>>({
	options,
	selectedId,
	setSelectedId,
	error,
	disabled = false,
	label,
	required,
	onCreateNew,
	renderCreate,
	style,
}: IProps<TValue>) => {
	const [inputValue, setInputValue] = useState<string>('');

	const onClickCreateNew = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();
		onCreateNew(inputValue ?? '');
	};

	return (
		<>
			<Autocomplete
				className='auto-complete-with-add'
				style={style}
				inputValue={inputValue}
				onInputChange={(_, value) => setInputValue(value)}
				disabled={disabled}
				options={options.map(t => t.id)}
				autoComplete
				autoHighlight
				autoSelect
				selectOnFocus
				getOptionLabel={id => renderOption(id, options)}
				getOptionSelected={(option, value) => options.findIndex(t => t.id === option) > -1}
				filterOptions={(options, params) => {
					const filtered = createFilterOptions<string>()(options, params);
					if (params.inputValue !== '' && filtered.length === 0) {
						return [...filtered, params.inputValue];
					} else {
						return filtered;
					}
				}}
				fullWidth
				value={options.find(t => t.id === selectedId) ? selectedId : null}
				onChange={(_, value) => setSelectedId(value ?? undefined)}
				// popupIcon={<div>hello world</div>}
				renderInput={params => (
					<>
						<TextField
							variant='filled'
							{...params}
							ref={params.InputProps.ref}
							required={required}
							error={error}
							label={label}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										<div
											className='df-row-ac'
											style={{ position: 'absolute', top: 'calc(50% - 15px)', right: '9px' }}>
											<IconButton
												size='small'
												color='primary'
												onClick={() => onCreateNew('')}
												disabled={disabled}>
												<AddCircleIcon />
											</IconButton>
										</div>
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					</>
				)}
				renderOption={id => {
					const x = options.find(t => t.id === id);
					if (x !== undefined) {
						return x.identifier;
					} else {
						return (
							<div
								onClick={onClickCreateNew}
								className='df-row-ac'>
								<AddIcon color='action' />
								<OpenInNewIcon color='action' />
								<div style={{ marginLeft: '6px' }}>{renderCreate(inputValue ?? '')}</div>
							</div>
						);
					}
				}}
			/>
		</>
	);
};
