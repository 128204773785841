export const languageKey = 'language-key';
export const LanguageHeaderKey = 'x-language';
export const ReturnUrlPrefix = '?returnUrl=';
export const apiUrl = process.env.NODE_ENV === `development` ? 'https://localhost:44363' : ''; //will be served from same domain!
export const eidUrl = 'http://localhost:50052';
export const clientUrl = 'http://localhost:9502';
export const imageScannerUrl = 'http://localhost:50053';
export const thermalPrinterUrl = 'http://localhost:50054';
export const MustUpdateFrontendHttpStatusCode = 418;
export const ClientBuildNumberHeaderKey = 'client-build-number';
export const BeidMiddlewareUrl = 'https://eid.belgium.be/sites/default/files/software/Belgium%20eID-QuickInstaller%205.0.17.5498b.exe';

export const HifsInfoNlUrl = 'https://www.riziv.fgov.be/nl/professionals/andere-professionals/ziekenfonds/Paginas/contacteer-ziekenfondsen.aspx';
export const HifsInfoFrUrl = 'https://www.riziv.fgov.be/fr/professionnels/autres/mutualites/Pages/contactez-mutualites.aspx';

// export const RosaBaseUrl = 'https://staging-hp-calendar.rosa.be/nl/calendar';

// export const thermalPrinterUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:36338' : "http://localhost:50054"
// export const imageScannerUrl = process.env.NODE_ENV === 'development' ? 'https://localhost:44365' : "http://localhost:50053";
// export const eidUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:53839' : 'http://localhost:50052';
// export const clientUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:9503' : 'http://localhost:9502';
