import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteMultipleFreeSolo } from 'framework/forms/FormAutocompleteMultipleFreeSolo';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Try } from 'framework/Try';
import { IValidateResponse } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import * as yup from 'yup';

interface IModel {
	labels: string[];
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			labels: yup.mixed(),
		})
		.defined();
};

interface IProps extends DialogProps {
	labels: string[];
	autoCompleteLabels: string[];
	updateF: (labels: string[]) => Promise<Try<IValidateResponse>>;
	cancel: VoidFunction;
	confirm: VoidFunction;
	title?: string;
	label?: string;
}

// rename -> make more generic
export const LabelsForm = ({ labels, autoCompleteLabels, updateF, confirm, cancel, title, label, ...rest }: IProps) => {
	const strings = useLocalization();
	const [update, isSubmitting] = useFormSubmit(updateF);
	const titleX = useMemo(() => title ?? strings.manuallyAssignedLabels, [title, strings]);
	const labelX = useMemo(() => label ?? strings.labels, [label, strings]);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await update(values.labels);
		if (handleValidateResponse(r, helpers, 'labels')) {
			confirm();
		}
	};

	return (
		<Formik<IModel>
			initialValues={{
				labels: labels,
			}}
			onSubmit={handleSubmit}
			validationSchema={createSchema(strings)}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{titleX}</DialogTitle>
					<DialogContent dividers>
						<FormAutocompleteMultipleFreeSolo<IModel>
							name='labels'
							label={labelX}
							options={autoCompleteLabels}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
