import { ColorX } from 'framework/components/color/ColorX';
import { ITender } from 'gen/ApiClient';

export const mapTenderToColorX = (item: ITender): ColorX => {
	if (item.isDeleted) {
		return 'error';
	} else if (item.isLost) {
		return 'error';
	} else if (item.hasSalesTransaction) {
		return 'success';
	} else {
		return 'info';
	}
};
