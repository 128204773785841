import { QueryProvider } from 'framework/context/QueryProvider';
import {
	IQueryResultWithMetadata,
	ITender,
	ITendersQueryMetadata,
	ITendersQueryParams,
	tendersQuery_concurrencyToken,
	tendersQuery_query,
} from 'gen/ApiClient';
import React from 'react';
import { tendersQueryParamsLsKey } from '../../../../localStorageKeys';
import { TendersListQueryContext } from './TendersListQueryContext';

const DefaultParams: ITendersQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	forPatientId: undefined,
	isForPatient: undefined,
	isSubProcess: undefined,
	statuses: [],
	validUntilAfter: undefined,
	validUntilBefore: undefined,
	forCompanyId: undefined,
	isForCompany: undefined,
	alsoIncludeDeleted: false,
	prefilter: 'All',
};

export const TendersListQueryProvider = ({ children }: { children: any }) => {
	return (
		<QueryProvider<IQueryResultWithMetadata<ITender, ITendersQueryMetadata>, ITendersQueryParams>
			defaultParams={DefaultParams}
			queryF={tendersQuery_query}
			concurrencyF={tendersQuery_concurrencyToken}
			localStorageKey={tendersQueryParamsLsKey}
			context={TendersListQueryContext}>
			{children}
		</QueryProvider>
	);
};
