import { PaperProps } from '@material-ui/core';
import TrackChangesOutlinedIcon from '@material-ui/icons/TrackChangesOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { ArticlePropertiesLabelledPropertiesX } from 'app/main/inventoryManagement/inventoryItems/detail/articleProperties/ArticlePropertiesLabelledPropertiesX';
import { useLocalizedArticlePropertyKeys } from 'app/main/inventoryManagement/productCatalog/articlePropertyKeys/context/useLocalizedArticlePropertyKeys';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ChipX } from 'framework/components/chips/ChipX';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { TypographyBoldCaption } from 'framework/components/typography/TypographyBoldCaption';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import {
	BatteryType,
	HearingAidType,
	IAssignedOrInquiredArticleDto,
	patientAssignedArticlesCommand_markAsHidden,
	patientAssignedArticlesCommand_markAsVisible,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';
import { useHistory } from 'react-router-dom';
import { calcInventoryItemDetailRoute } from 'app/main/inventoryManagement/routes';
import { PatchOrientationOfAssignedOrInquiredArticleForm } from './PatchOrientationOfAssignedOrInquiredArticleForm';
import { calculateArticlesInquiryDetailRoute } from 'app/main/processes/ProcessesRoutes';

interface IProps extends PaperProps {
	patientId: string;
	item: IAssignedOrInquiredArticleDto;
	reload: VoidFunction;
}

export const AssignedOrInquiredArticlePopoverContent = ({ patientId, item, style, reload, ...props }: IProps) => {
	const { hearingAidTypeRecord, batteryTypeRecord } = useContext(RecordContext);
	const strings = useLocalization();
	const localizedKeys = useLocalizedArticlePropertyKeys();
	const { push } = useHistory();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onHide = () => {
		open(
			<AlertDialog
				open
				title={strings.hideArticle}
				content={strings.doYouWantToHideThisArticleFromListOfActualEquipmentQuestion}
				rejectText={strings.goBack}
				acceptText={strings.yes}
				acceptF={() => patientAssignedArticlesCommand_markAsHidden({ patientId: patientId, articleId: item.id })}
				accept={confirm}
				reject={cancel}
			/>
		);
	};

	const onRestore = () => {
		open(
			<AlertDialog
				open
				title={strings.showArticleAgain}
				content={strings.doYouWantToShowThisArticleAgainInListOfActualEquipment}
				rejectText={strings.goBack}
				acceptText={strings.yes}
				acceptF={() => patientAssignedArticlesCommand_markAsVisible({ patientId: patientId, articleId: item.id })}
				accept={confirm}
				reject={cancel}
			/>
		);
	};

	const onSetOrientation = () => {
		open(
			<PatchOrientationOfAssignedOrInquiredArticleForm
				open
				item={item}
				patientId={patientId}
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	return (
		<div
			className='df-col'
			style={{ padding: 12 }}>
			{item.inventoryItemId && (
				<LabelledPropertyX
					link={() => push(calcInventoryItemDetailRoute(item.inventoryItemId!))}
					style={{ marginBottom: 16 }}>
					{item.description}
				</LabelledPropertyX>
			)}
			{item.isHidden && (
				<ChipX
					color='error'
					label={strings.hidden}
					style={{ marginRight: 'auto', marginBottom: 16 }}
					fill
				/>
			)}
			{item.hasHearingAidData && item.hearingAidData && (
				<div
					className='df-col'
					style={{ marginBottom: 16 }}>
					<TypographyBoldCaption>{strings.propertiesOfHearingAid}</TypographyBoldCaption>
					<LabelledPropertyX label={strings.typeHearingAid}>
						{isNullOrUndefined(item.hearingAidData?.type) ? (
							<em>{strings.notApplicableAbbreviation}</em>
						) : (
							<span>{hearingAidTypeRecord[item.hearingAidData.type as HearingAidType]}</span>
						)}
					</LabelledPropertyX>
					<LabelledPropertyX label={strings.batteryType}>
						{isNullOrUndefined(item.hearingAidData?.batteryType) ? (
							<em>{strings.notApplicableAbbreviation}</em>
						) : (
							<span>{batteryTypeRecord[item.hearingAidData.batteryType as BatteryType]}</span>
						)}
					</LabelledPropertyX>
				</div>
			)}
			{item.articlePropertiesX?.length > 0 && (
				<div
					className='df-col'
					style={{ marginBottom: 16 }}>
					<TypographyBoldCaption>{strings.properties}</TypographyBoldCaption>
					<ArticlePropertiesLabelledPropertiesX
						aps={item.articlePropertiesX}
						localizedKeys={localizedKeys}
					/>
				</div>
			)}
			<TypographyBoldCaption>{strings.actions}</TypographyBoldCaption>
			{item.isHidden === false && (
				<TextLinkButton
					startIcon={<VisibilityOffOutlinedIcon />}
					onClick={onHide}>
					{strings.hideThisArticle}
				</TextLinkButton>
			)}
			{item.isHidden === true && (
				<TextLinkButton
					startIcon={<VisibilityOutlinedIcon />}
					onClick={onRestore}>
					{strings.showThisArticle}
				</TextLinkButton>
			)}
			<TextLinkButton
				startIcon={<TrackChangesOutlinedIcon />}
				onClick={onSetOrientation}>
				{item.hasOrientation ? strings.changeOrientation : strings.determineOrientation}
			</TextLinkButton>
			{item.inventoryItemId && (
				<TextLinkButton
					startIcon={<OpenInBrowserOutlinedIcon />}
					onClick={() => push(calcInventoryItemDetailRoute(item.inventoryItemId!))}
					underline>
					{strings.viewDetailsOfThisArticle}
				</TextLinkButton>
			)}
			{item.articlesInquiryId && (
				<TextLinkButton
					startIcon={<OpenInBrowserOutlinedIcon />}
					onClick={() => push(calculateArticlesInquiryDetailRoute(item.articlesInquiryId!))}
					underline>
					{strings.viewDetailsOfThisArticle}
				</TextLinkButton>
			)}
		</div>
	);
};
