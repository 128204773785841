import { IRizivHearingAidDefinitionV2Dto } from 'gen/ApiClient';
import { IHandoverLine } from '../IHandoverLine';

export const appendRizivHearingInstrumentData = (line: IHandoverLine, item: IRizivHearingAidDefinitionV2Dto): IHandoverLine => {
	return {
		...line,
		rizivHearingAidDefinitionV2IdentificationCode: item.identificationCode,
		manufacturer: item.manufacturer ?? '',
		model: item.name ?? '',
	};
};
