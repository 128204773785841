import LinkIcon from '@material-ui/icons/Link';
import LinkOffOutlinedIcon from '@material-ui/icons/LinkOffOutlined';
import { calcCustomerAccountDetailRoute } from 'app/main/financial/routes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IExactOnlineCustomerAccountDto, exactOnlineCustomerAccountsCommand_unlink } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExactOnlineCustomerAccountListItemStatusIcon } from './ExactOnlineListItemStatusIcon';
import { LinkOrPushExactOnlineCustomerAccountDialog } from './selectCrmAccount/LinkOrPushExactOnlineCustomerAccountDialog';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IExactOnlineCustomerAccountDto;
	withoutBorder?: boolean;
	reload: VoidFunction;
}

export const ExactOnlineCustomerAccountListItem = ({ item, withoutBorder, reload }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const color = useMemo<ColorX>(() => (item.isLinked ? 'success' : 'error'), [item]);
	const tColor = useThemeColor(color);
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onUnlink = () => {
		open(
			<AlertDialog
				open
				title={strings.deleteLink}
				content={strings.doYouWantToDeleteExistingLinkWithCRMAccountQuestion}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactOnlineCustomerAccountsCommand_unlink({ hamsId: item.hamsId })}
				accept={confirm}
			/>
		);
	};

	const onLink = () => {
		open(
			<LinkOrPushExactOnlineCustomerAccountDialog
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<ExactOnlineCustomerAccountListItemStatusIcon
					item={item}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col gap-4 fg1'>
				<div className='df-row-ac'>
					<TextLinkButton
						onClick={() => push(calcCustomerAccountDetailRoute(item.hamsId))}
						style={{ fontSize: MajorFontSize }}>
						{item.name}
					</TextLinkButton>
				</div>
				<ChipX
					label={item.isLinked ? strings.linked : strings.notLinked}
					color={color}
					style={{ marginRight: 'auto' }}
				/>
				{item.isLinked && (
					<>
						<div
							className='df-row-ac'
							style={{ fontSize: MinorFontSize, gap: 4 }}>
							<span>{`${strings.exactOnlineCRMAccountCode}:`}</span>
							<TextLinkButton
								style={{ fontSize: MinorFontSize }}
								onClick={() => {}}>
								{item.exactOnlineCode}
							</TextLinkButton>
						</div>
						<TextLinkButton
							hoverColor='secondary'
							startIcon={<LinkOffOutlinedIcon />}
							onClick={onUnlink}>
							{strings.deleteLinkImperative}
						</TextLinkButton>
					</>
				)}
				{item.isLinked === false && (
					<TextLinkButton
						color='primary'
						startIcon={<LinkIcon />}
						onClick={onLink}>
						{strings.link}
					</TextLinkButton>
				)}
			</div>
		</div>
	);
};
