import { useFormikContext } from 'formik';
import { FormAutocompleteFieldWithAdd } from 'framework/forms/FormAutocompleteFieldWithAdd';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IObjectWithIdAndIdentifier } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { PaymentMethodsPreloadCacheContext } from '../PaymentMethodsPreloadCacheContext';
import { PaymentMethodForm } from './PaymentMethodForm';

type Mode = 'cashSale' | 'sale' | 'purchase';

interface IProps<TModel> extends IFormProps<TModel> {
	mode: Mode;
}

export const FormSelectOrCreatePaymentMethodField = <TModel extends unknown>({ mode, name, label, ...rest }: IProps<TModel>) => {
	const props = useFormikContext<TModel>();
	const strings = useLocalization();
	const [data, load] = usePreloadCacheContext(PaymentMethodsPreloadCacheContext);
	const { open, confirm, cancel } = useDialogsContext();
	const { translatePaymentMethod } = useContext(RecordContext);
	const filtered = useMemo(
		() =>
			data
				?.filter(t => (mode === 'sale' && t.canUserForSale) || mode !== 'sale')
				.filter(t => (mode === 'cashSale' && t.canUseForCashSale) || mode !== 'cashSale')
				.filter(t => (mode === 'purchase' && t.canUseForPurchase) || mode !== 'purchase')
				.map<IObjectWithIdAndIdentifier<string>>(t => ({ id: t.id, identifier: translatePaymentMethod(t.identifier) })),
		[data, translatePaymentMethod, mode]
	);

	if (data === undefined || filtered === undefined) {
		return <div></div>;
	}

	const onConfirm = async (id: string) => {
		await load();
		setFormValue<TModel>(name, id, props);
		confirm();
	};

	const onNew = () => {
		open(
			<PaymentMethodForm
				open
				confirm={onConfirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<FormAutocompleteFieldWithAdd<TModel, IObjectWithIdAndIdentifier<string>>
			name={name}
			label={label}
			options={filtered}
			onCreateNew={onNew}
			renderCreate={str => `${strings.createSomething(str)}, ${strings.paymentMethod}`}
			{...rest}
		/>
	);
};
