import { Typography } from '@material-ui/core';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { CodeBlock } from 'app/main/test/CodeBlock';
import { CollapseX } from 'framework/components/CollapseX';
import { CardX } from 'framework/components/cards/CardX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { invoiceForwardingQuery_details } from 'gen/ApiClient';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { InvoiceForwardingRecordDetailsCardX } from './InvoiceForwardingRecordDetailsCardX';

interface IProps extends IReloadTriggerProps {
	id: string;
	reload: VoidFunction;
}

export const InvoiceForwardingRecordDetailsComponent = ({ id, reload, reloadTrigger }: IProps) => {
	const [item, reloadDetails] = useApiEffect(invoiceForwardingQuery_details, id);
	const isSuperUser = useLoggedInUserIsSuperUser();

	useLazyEffect(() => {
		reloadDetails();
	}, [reloadTrigger]);

	if (item === undefined) {
		return <></>;
	}

	return (
		<div className='df-col gap-16 fg1'>
			<InvoiceForwardingRecordDetailsCardX
				item={item}
				reloadTrigger={reloadTrigger}
				reload={reload}
			/>
			{isSuperUser && (
				<CardX>
					<Typography>{`Only visible for super-users!`}</Typography>
					<CollapseX collapseAfter>
						<CodeBlock
							value={item}
							style={{ margin: 0 }}
						/>
					</CollapseX>
				</CardX>
			)}
		</div>
	);
};
