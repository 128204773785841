import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { suppliersQuery_overview, suppliersQuery_single } from 'gen/ApiClient';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { ActionsCard } from './ActionsCard';
import { DetailsCard } from './DetailsCard';
import { SupplierAccountDetailPageHeader } from './SupplierAccountDetailPageHeader';
import { SupplierAccountDetailTabType } from './SupplierAccountDetailTabType';
import { PaymentsCard } from './payments/PaymentsCard';
import { PurchasesCard } from './purchases/PurchasesCard';

export const SupplierAccountDetail = () => {
	const [trigger, hitTrigger] = useTrigger();
	const { id } = useParams<IRouteParamsWithId>();
	const [item, reloadSingle] = useApiEffect(suppliersQuery_single, id);
	const [overview, reloadOverview] = useApiEffect(suppliersQuery_overview, id);
	const [tab, setTab] = useState<SupplierAccountDetailTabType>('purchases');

	useEffect(() => {
		reloadSingle();
		reloadOverview();
		// eslint-disable-next-line
	}, [trigger]);

	if (item === undefined || overview === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='SupplierAccounts'
			stickyHeader={
				<SupplierAccountDetailPageHeader
					item={item}
					metadata={overview}
					reload={hitTrigger}
					setTab={setTab}
					tab={tab}
				/>
			}
			stickySidebar={
				<div
					className='df-col gap-16'
					style={{ padding: 16 }}>
					<DetailsCard item={item} />
					<ActionsCard
						item={item}
						reload={hitTrigger}
					/>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16 }}>
				{tab === 'purchases' && (
					<PurchasesCard
						supplierId={item.id}
						reloadTrigger={trigger}
					/>
				)}
				{tab === 'payments' && (
					<PaymentsCard
						supplier={item}
						reloadTrigger={trigger}
						reload={hitTrigger}
					/>
				)}
			</div>
		</RestrictedPageX>
	);
};
