import { CardProps, Divider } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import BlockIcon from '@material-ui/icons/Block';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { ExactGlobeCustomerAccountsQueryParamsLsKey } from 'app/localStorageKeys';
import { calcCustomerAccountDetailRoute } from 'app/main/financial/routes';
import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { InnerPageXWithStickySidebar } from 'framework/components/layout/InnerPageXWithStickySidebar';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { IQueryContext } from 'framework/context/IQueryContext';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import {
	ExactGlobeCustomerAccountPrefilter,
	ExactGlobeCustomerAccountPrefilters,
	exactGlobeCustomerAccounts_concurrencyToken,
	exactGlobeCustomerAccounts_exportSingle,
	exactGlobeCustomerAccounts_exportUnhandled,
	exactGlobeCustomerAccounts_markAllAsHandled,
	exactGlobeCustomerAccounts_markAsExported,
	exactGlobeCustomerAccounts_markAsHandled,
	exactGlobeCustomerAccounts_patchDebtorCode,
	exactGlobeCustomerAccounts_query,
	IExactGlobeCustomerAccountDto,
	IExactGlobeCustomerAccountQueryParams,
	IQueryResult,
} from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';

export const ExactGlobeCustomerAccountsQueryContext = React.createContext<
	IQueryContext<IQueryResult<IExactGlobeCustomerAccountDto>, IExactGlobeCustomerAccountQueryParams>
>(undefined as any);

const DefaultParams: IExactGlobeCustomerAccountQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'identifier' },
	pageParams: { index: 1, size: 10 },
	prefilter: 'Unhandled',
};

export const ExactGlobeCustomerAccountsQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IExactGlobeCustomerAccountDto>, IExactGlobeCustomerAccountQueryParams>
			defaultParams={DefaultParams}
			queryF={exactGlobeCustomerAccounts_query}
			concurrencyF={exactGlobeCustomerAccounts_concurrencyToken}
			localStorageKey={ExactGlobeCustomerAccountsQueryParamsLsKey}
			context={ExactGlobeCustomerAccountsQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};

interface IExactGlobeCustomerAccountsTabProps extends IReloadTriggerProps {
	reload: VoidFunction;
}

export const ExactGlobeCustomerAccountsTab = ({ reload, reloadTrigger }: IExactGlobeCustomerAccountsTabProps) => {
	const strings = useLocalization();
	const { reload: reloadQuery, queryResult, params, setParams } = useQueryContext(ExactGlobeCustomerAccountsQueryContext);
	const [download, isDownloading] = useDownload(exactGlobeCustomerAccounts_exportUnhandled);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadQuery();
	}, [reloadTrigger]);

	const onMarkAllAsHandled = () => {
		open(
			<AlertDialog
				open
				title={`Markeren als behandeld`}
				content={`Wil je alle 'geëxporteerde' markeren als behandeld?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactGlobeCustomerAccounts_markAllAsHandled({ })}
				accept={confirm}
			/>
		);
	};

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<InnerPageXWithStickySidebar
			maxWidth={1280}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<ExactGlobeCustomerAccountsListFilterCard
						params={params}
						setParams={setParams}
						style={{ minWidth: 220 }}
					/>
					<Divider />
					<TextLinkButton
						onClick={async () => {
							await download();
							reload();
						}}
						startIcon={<GetAppOutlinedIcon />}
						disabled={isDownloading}>
						{`Exporteer onbehandelde .xml`}
					</TextLinkButton>
					<TextLinkButton
						onClick={onMarkAllAsHandled}
						startIcon={<Check />}
						disabled={isDownloading}>
						{`Alles markeren als behandeld`}
					</TextLinkButton>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16, paddingLeft: 8 }}>
				<SearchbarX
					placeholder={`Zoeken...`}
					value={params.searchString}
					onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
				/>
				<CardX className='w100'>
					<div
						className='df-row'
						style={{ gap: 8 }}>
						<Typography16pxBold>{strings.results}</Typography16pxBold>
						<div className='fg1'></div>
					</div>
					<Divider style={{ marginTop: 8 }} />
					{queryResult.values.map(t => (
						<ExactGlobeCustomerAccountListItem
							key={t.hamsId}
							item={t}
							reload={reload}
						/>
					))}
					<QueryResultPaginationX
						queryResult={queryResult}
						setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
						style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
					/>
				</CardX>
			</div>
		</InnerPageXWithStickySidebar>
	);
};

const ExactGlobeCustomerAccountPrefilterRecord: Record<ExactGlobeCustomerAccountPrefilter, string> = {
	All: 'Alles',
	Exported: 'Geëxporteerd',
	Handled: 'Behandeld',
	Unhandled: 'Onbehandeld',
};

interface IExactGlobeCustomerAccountsListFilterCardProps extends CardProps {
	params: IExactGlobeCustomerAccountQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IExactGlobeCustomerAccountQueryParams>>;
}

export const ExactGlobeCustomerAccountsListFilterCard = ({ params, setParams, style, ...rest }: IExactGlobeCustomerAccountsListFilterCardProps) => {
	const strings = useLocalization();
	const onFilter = (pars: IExactGlobeCustomerAccountQueryParams) => setParams(onResetToPage1(pars));

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<div className='df-col'>
				{ExactGlobeCustomerAccountPrefilters.map(prefilter => (
					<RadioIsSelectedButton
						key={prefilter}
						isSelected={prefilter === params.prefilter}
						onClick={() => onFilter({ ...params, prefilter: prefilter })}>
						{ExactGlobeCustomerAccountPrefilterRecord[prefilter]}
					</RadioIsSelectedButton>
				))}
			</div>
		</CardX>
	);
};

const MajorFontSize = 16;

interface IExactGlobeCustomerAccountListItemProps {
	item: IExactGlobeCustomerAccountDto;
	withoutBorder?: boolean;
	reload: VoidFunction;
}

export const ExactGlobeCustomerAccountListItem = ({ item, withoutBorder, reload }: IExactGlobeCustomerAccountListItemProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const color = useMemo<ColorX>(() => (item.status === 'Created' ? 'error' : item.status === 'Exported' ? 'warning' : 'success'), [item]);
	const tColor = useThemeColor(color);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [download, isDownloading] = useDownload(exactGlobeCustomerAccounts_exportSingle);

	const onMarkAsExported = () => {
		open(
			<AlertDialog
				open
				title={`Markeren als geëxporteerd`}
				content={`Wil je deze markeren als geëxporteerd?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactGlobeCustomerAccounts_markAsExported({ exactGlobeCustomerAccountId: item.hamsId })}
				accept={confirm}
			/>
		);
	};

	const onMarkAsHandled = () => {
		open(
			<AlertDialog
				open
				title={`Markeren als behandeld`}
				content={`Wil je deze markeren als behandeld?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactGlobeCustomerAccounts_markAsHandled({ exactGlobeCustomerAccountId: item.hamsId })}
				accept={confirm}
			/>
		);
	};

	const onEdit = () => {
		open(
			<StringModelForm
				open
				confirm={confirm}
				cancel={cancel}
				fullWidth
				label={`${strings.name} ${strings.vatCustomer}`}
				formTitle={strings.changeWhat(`${strings.name} ${strings.vatCustomer}`)}
				notifyMessage={strings.changedWhat(`${strings.name} ${strings.vatCustomer}`)}
				submitText={strings.update}
				submitFunction={val => exactGlobeCustomerAccounts_patchDebtorCode({ debtorCode: val, exactGlobeCustomerAccountId: item.hamsId })}
				initialValue={item.debtorCode ?? ''}
			/>
		);
	};

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<ExactGlobeCustomerAccountListItemStatusIcon
					item={item}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col gap-4 fg1'>
				<div className='df-row-ac'>
					<TextLinkButton
						onClick={() => push(calcCustomerAccountDetailRoute(item.hamsId))}
						style={{ fontSize: MajorFontSize }}>
						{item.identifier}
					</TextLinkButton>
				</div>
				<ChipX
					label={item.status === 'Handled' ? `Behandeld` : item.status === 'Exported' ? 'Geëxporteerd' : 'Niet behandeld'}
					color={color}
					style={{ marginRight: 'auto' }}
				/>
				<LabelledPropertyX
					label='Debtor code'
					edit={onEdit}>
					{item.debtorCode}
				</LabelledPropertyX>{' '}
				<TextLinkButton
					color='primary'
					startIcon={<BorderColorOutlinedIcon />}
					onClick={onMarkAsExported}
					disabled={item.status === 'Exported'}>
					{`Markeren als geëxporteerd`}
				</TextLinkButton>
				<TextLinkButton
					color='primary'
					startIcon={<BorderColorOutlinedIcon />}
					onClick={onMarkAsHandled}
					disabled={item.status === 'Handled'}>
					{`Markeren als behandeld`}
				</TextLinkButton>
				<TextLinkButton
					color='primary'
					startIcon={<GetAppOutlinedIcon />}
					onClick={() => download(item.id)}
					disabled={isDownloading || item.hasError}>
					{`Download xml`}
				</TextLinkButton>
			</div>
		</div>
	);
};

interface IExactGlobeCustomerAccountListItemStatusIconProps {
	item: IExactGlobeCustomerAccountDto;
	style?: React.CSSProperties;
}

export const ExactGlobeCustomerAccountListItemStatusIcon = ({ item, style }: IExactGlobeCustomerAccountListItemStatusIconProps) => {
	if (item.status === 'Handled') {
		return <CheckCircleOutlineIcon style={style} />;
	} else if (item.status === 'Exported') {
		return <GetAppOutlinedIcon style={style} />;
	} else {
		return <BlockIcon style={style} />;
	}
};
