import React, { useContext, useState } from 'react';
import { Frequency, RRule } from 'rrule';
import { DatePickerX } from 'framework/components/datePickers/DatePickerX';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { IntegerTextFieldWithPlusAndMin } from 'framework/components/textFields/IntegerTextFieldWithPlusAndMin';
import { SelectField } from 'framework/components/select/SelectField';
import { RecordContext } from 'shared/records/RecordContext';
import { ByWeekdaySelection } from './ByWeekdaySelection';
import { getNow } from 'shared/utils/getNow';

interface IProps {
	rrule: RRule;
	setRrule: React.Dispatch<React.SetStateAction<RRule>>;
	style?: React.CSSProperties;
}

type EndMode = 'never' | 'onDate' | 'afterXOccurences';

const rruleToMode = (rrule: RRule): EndMode => {
	console.log('initial values');
	if (rrule.options.count !== undefined && rrule.options.count !== null) {
		return 'afterXOccurences';
	} else if (rrule.options.until !== undefined && rrule.options.until !== null) {
		return 'onDate';
	} else {
		return 'never';
	}
};

export const CalendarEventRecurrenceTab = ({ rrule, setRrule, style }: IProps) => {
	const { rruleFrequencyRecord } = useContext(RecordContext);
	const [endMode, setEndMode] = useState<EndMode>(rruleToMode(rrule));

	const onChangeFrequency = (val: Frequency) => {
		setRrule(new RRule({ ...rrule.options, freq: val, byweekday: val !== Frequency.WEEKLY ? [] : rrule.options.byweekday }));
	};

	const onChangeEndMode = (mode: EndMode) => {
		if (mode === 'never') {
			setRrule(new RRule({ ...rrule.options, count: undefined, until: undefined }));
		} else if (mode === 'afterXOccurences') {
			setRrule(new RRule({ ...rrule.options, count: 5, until: undefined }));
		} else {
			setRrule(new RRule({ ...rrule.options, count: undefined, until: getNow() }));
		}
		setEndMode(mode);
	};

	return (
		<div
			className='df-col'
			style={style}>
			<SelectField<Frequency>
				value={rrule.options.freq}
				label='Herhaal elke...'
				onChange={onChangeFrequency}
				options={[Frequency.DAILY, Frequency.WEEKLY, Frequency.MONTHLY, Frequency.YEARLY]}
				renderValue={t => rruleFrequencyRecord[t]}
				getKey={t => t.toString()}
				style={{ marginBottom: 16 }}
			/>
			<IntegerTextFieldWithPlusAndMin
				label={'Interval'}
				onChange={val => setRrule(new RRule({ ...rrule.options, interval: val }))}
				value={rrule.options.interval ?? 1}
				min={1}
				style={{ marginBottom: 16 }}
			/>
			{rrule.options.freq === Frequency.WEEKLY && (
				<div className='df-col'>
					<div style={{ marginBottom: 8 }}>Herhalen op</div>
					<ByWeekdaySelection
						selected={rrule.options.byweekday ?? []}
						setSelected={val => setRrule(new RRule({ ...rrule.options, byweekday: val }))}
					/>
				</div>
			)}
			<div style={{ marginTop: 24 }}>Eindigt</div>
			<ExtendedRadioGroup<EndMode>
				selected={endMode}
				setSelected={onChangeEndMode}
				options={[
					{ value: 'never', label: 'Nooit' },
					{ value: 'onDate', label: 'Op datum' },
					{ value: 'afterXOccurences', label: `Na aantal keren` },
				]}
			/>
			{endMode === 'onDate' && (
				<DatePickerX
					label='Tot datum'
					value={rrule.options.until as any}
					setValue={val => setRrule(new RRule({ ...rrule.options, until: val }))}
					style={{ marginBottom: 16 }}
				/>
			)}
			{endMode === 'afterXOccurences' && (
				<IntegerTextFieldWithPlusAndMin
					label={'Na aantal keren'}
					onChange={val => setRrule(new RRule({ ...rrule.options, count: val }))}
					value={rrule.options.count ?? 1}
					min={1}
					style={{ marginBottom: 16 }}
				/>
			)}
		</div>
	);
};
