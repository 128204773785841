import { DialogProps } from '@material-ui/core';
import React from 'react';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { locationsCommand_addRoom } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	locationId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const RoomForm = ({ locationId, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<StringModelForm
			{...rest}
			cancel={cancel}
			confirm={confirm}
			formTitle={strings.newWhat(strings.room)}
			label={strings.name}
			submitText={strings.create}
			initialValue=''
			submitFunction={name => locationsCommand_addRoom(locationId, name)}
		/>
	);
};
