import { ProductCatalogItemTypeIcon } from 'app/main/inventoryManagement/productCatalog/ProductCatalogItemTypeIcon';
import { HtmlDivProps } from 'framework/components/HtmlDivProps';
import { ProductCatalogItemType } from 'gen/ApiClient';
import React from 'react';

interface IProps extends HtmlDivProps {
	type: ProductCatalogItemType;
	color: string;
}

export const AssignedOrInquiredTypeIconCircle = ({ type, color, style, className, ...props }: IProps) => {
	return (
		<div
			className={`df-row-ac jc-c ${className}`}
			style={{ width: 40, height: 40, borderRadius: '100%', border: `1px solid ${color}`, ...style }}
			{...props}>
			<ProductCatalogItemTypeIcon
				type={type}
				fontSize='small'
				style={{
					color: color,
					marginBottom: type === 'HearingInstrument' ? -2 : 'unset',
					marginLeft: type === 'HearingInstrument' ? -3 : 'unset',
				}}
			/>
		</div>
	);
};
