import React, { useState } from 'react';
import { PurchaseDetailPageViewContext } from './PurchaseDetailPageViewContext';
import { PurchaseDetailTabType } from './PurchaseDetailTabType';
import { createPurchaseDetailTabTypeRecord } from './utils';
import { useLocalization } from 'localization/useLocalization';

export const PurchaseDetailPageViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [selectedTab, setSelectedTab] = useState<PurchaseDetailTabType>('contents');
	const [purchaseId, setPurchaseId] = useState<string>('');
	const PurchaseDetailTabTypeRecord = React.useMemo(() => createPurchaseDetailTabTypeRecord(strings), [strings]);

	return (
		<PurchaseDetailPageViewContext.Provider
			value={{
				selectedTab: selectedTab,
				setSelectedTab: setSelectedTab,
				purchaseId: purchaseId,
				setPurchaseId: setPurchaseId,
				TabTypeRecord: PurchaseDetailTabTypeRecord,
			}}>
			{children}
		</PurchaseDetailPageViewContext.Provider>
	);
};
