import { CardProps, Divider } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FolderIcon from '@material-ui/icons/Folder';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IEntPhysiciansQueryParams, IEntPhysiciansSearchData } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { GenderFilterItem } from 'shared/GenderFilterItem';

interface IProps extends CardProps {
	params: IEntPhysiciansQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IEntPhysiciansQueryParams>>;
	searchData: IEntPhysiciansSearchData;
}

const exclusions: (keyof IEntPhysiciansQueryParams)[] = ['showDeleted'];

export const EntPhysiciansListFilterCard = ({ params, setParams, searchData, ...rest }: IProps) => {
	const strings = useLocalization();
	const isFilteredX = useMemo(() => isFiltered(params, exclusions), [params]);

	const onFilter = (pars: IEntPhysiciansQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params, exclusions)));

	return (
		<CardX
			className='df-col gap-8'
			{...rest}>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				{searchData.zipCodes.length > 0 && (
					<StringListFilterComponent
						label={strings.zip}
						selected={params.zips}
						options={searchData.zipCodes}
						icon={<FolderIcon />}
						setSelected={value => onFilter({ ...params, zips: value })}
					/>
				)}
				<BooleanRadioFilterComponent
					label={`${strings.offices}?`}
					trueText={strings.oneOrMoreOffices}
					falseText={strings.noOffices}
					selected={params.hasOffices}
					setSelected={value => onFilter({ ...params, hasOffices: value })}
					icon={<HomeWorkIcon />}
				/>
				<GenderFilterItem
					selected={params.gender as any}
					setSelected={gender => onFilter({ ...params, gender: gender })}
				/>
				<DateFilterItem
					label={strings.dateCreated}
					after={params.createdAfter}
					before={params.createdBefore}
					setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
					icon={<DateRangeIcon />}
				/>
			</div>
			<Divider />
			<FormControlLabelSwitch
				isChecked={params.showDeleted}
				toggle={() => onFilter({ ...params, showDeleted: !params.showDeleted })}
				label={strings.alsoShowDeleted}
			/>
		</CardX>
	);
};
