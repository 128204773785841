import React, { useMemo } from 'react';
import { ChipX } from './ChipX';
import { ColorX } from '../color/ColorX';

interface IProps {
	value: number;
	max?: number;
	fill?: boolean;
	color?: ColorX;
	icon?: React.ReactElement;
	style?: React.CSSProperties;
	onClick?: VoidFunction;
}

export const BadgeChipX = ({ value, max = 99, style, ...rest }: IProps) => {
	const label = useMemo(() => (value > max ? `+${max.toString()}` : value.toString()), [value, max]);

	return (
		<ChipX
			label={label}
			fill
			style={{ height: '18px', ...style }}
			{...rest}
		/>
	);
};
