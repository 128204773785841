import { useTheme } from '@material-ui/core';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { IRosaPatientRecordWithMemberEquality, rosaPatientsCommand_createInRosa, rosaPatientsQuery_recordsByHamsPatientId } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RosaPatientWithMemberEqualityDialog } from '../RosaPatientWithMemberEqualityDialog';

interface IProps extends IReloadTriggerProps {
	patientId: string;
	style?: React.CSSProperties;
}

export const PatientRosaLinkCardContent = ({ style, patientId, reloadTrigger }: IProps) => {
	const strings = useLocalization();
	const [rosaLink, reload] = useApiEffect(rosaPatientsQuery_recordsByHamsPatientId, patientId);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const theme = useTheme();

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	if (rosaLink === undefined) {
		return <></>;
	}

	const onClickRosaPatient = (item: IRosaPatientRecordWithMemberEquality) => {
		open(
			<RosaPatientWithMemberEqualityDialog
				open
				rosaPatientId={item.rosaData.rosaPatientId!}
				hamsPatientId={patientId}
				close={confirm}
			/>
		);
	};

	const onCreatePatientInRosa = () => {
		open(
			<AlertDialog
				open
				title={strings.createPatientInRosa}
				content={strings.doYouWantToCreateThisPatientInRosaQuestion}
				acceptText={strings.yesWhatExclamation(strings.continue)}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => rosaPatientsCommand_createInRosa({ hamsPatientId: patientId })}
				accept={confirm}
			/>
		);
	};

	return (
		<div
			className='df-col'
			style={style}>
			<CardTitleTypography
				style={{ marginBottom: 8 }}
				title={`Rosa link`}
				endIcon={
					<StatusIcon
						status={rosaLink.isLinked ? 'success' : 'error'}
						fontSize='small'
						style={{ marginLeft: 4 }}
					/>
				}
			/>
			{rosaLink.isLinked === false && (
				<TextLinkButton
					startIcon={<PersonAddOutlinedIcon />}
					onClick={onCreatePatientInRosa}
					color='secondary'>
					{strings.createPatientInRosa}
				</TextLinkButton>
			)}
			{rosaLink.rosaPatientRecords.map((t, index) => (
				<LabelledPropertyX
					key={t.rosaData.rosaPatientId}
					label={`${index + 1}`}
					edit={() => onClickRosaPatient(t)}
					icon={
						t.isMergedTo ? (
							<MergeTypeIcon style={{ color: theme.palette.error.main }} />
						) : t.oneOrMoreMembersAreDifferent ? (
							<StatusIcon status='warning' />
						) : undefined
					}>
					{t.rosaData.lastNameCommaFirstName}
				</LabelledPropertyX>
			))}
		</div>
	);
};
