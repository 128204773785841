import { CardProps, Divider } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import NoEncryptionOutlinedIcon from '@material-ui/icons/NoEncryptionOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import WebOutlinedIcon from '@material-ui/icons/WebOutlined';
import { CodeBlock } from 'app/main/test/CodeBlock';
import { CollapseX } from 'framework/components/CollapseX';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import {
	exactOnlineIntegrationQuery_myDivision,
	exactOnlineSettingsCommand_clearOAuthOptions,
	exactOnlineSettingsCommand_clearOAuthToken,
	exactOnlineSettingsQuery_adminSettings,
} from 'gen/ApiClient';
import React, { useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { EnterOAuthOptionsRequestForm } from './EnterOAuthOptionsRequestForm';
import { RequestOAuthTokenFromAuthorizationCodeRequestForm } from './RequestOAuthTokenFromAuthorizationCodeRequestForm';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useApiCall } from 'framework/hooks/useApiCall';
import { formatDate } from 'framework/utils/date/formatDate';

interface IProps extends IReloadTriggerProps, CardProps {
	reload: VoidFunction;
	onRegister: VoidFunction;
	onActivate: VoidFunction;
	onDeactivate: VoidFunction;
}

export const ExactOnlineAdminSettingsCardX = ({ reloadTrigger, reload, onRegister, onActivate, onDeactivate, ...rest }: IProps) => {
	const [settings, reloadSettings] = useApiEffect(exactOnlineSettingsQuery_adminSettings);
	const call = useApiCall(exactOnlineIntegrationQuery_myDivision);
	// const [myDivision, reloadMyDivision] = useApiEffect(exactOnlineIntegrationQuery_myDivision);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [myDivision, setMyDivision] = useState<number>();

	useLazyEffect(() => {
		reloadSettings();
		// reloadMyDivision();
	}, [reloadTrigger]);

	if (settings === undefined) {
		return <></>;
	}

	const onLoadMyCurrentDivision = async () => {
		const r = await call();
		if (r.isSuccess) {
			setMyDivision(r.result);
		} else {
			setMyDivision(-1);
		}
	};

	const onEnterOAuthOptions = () => {
		open(
			<EnterOAuthOptionsRequestForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onClearOAuthOptions = () => {
		open(
			<AlertDialog
				open
				title={`Clear OAuth Options`}
				content={`Do you want to clear the OAuth options?`}
				acceptText={`Yes, clear!`}
				rejectText={`No, go back`}
				reject={cancel}
				acceptF={() => exactOnlineSettingsCommand_clearOAuthOptions()}
				accept={confirm}
			/>
		);
	};

	const onEnterAuthorizationCode = () => {
		open(
			<RequestOAuthTokenFromAuthorizationCodeRequestForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onClearOAuthToken = () => {
		open(
			<AlertDialog
				open
				title={`Clear OAuth Token`}
				content={`Do you want to clear the OAuth Token?`}
				acceptText={`Yes, clear!`}
				rejectText={`No, go back`}
				reject={cancel}
				acceptF={() => exactOnlineSettingsCommand_clearOAuthToken()}
				accept={confirm}
			/>
		);
	};

	return (
		<CardX
			{...rest}
			className='df-col gap-8'>
			<Typography16pxBold>{`Only visible for super-users!`}</Typography16pxBold>
			<CollapseX collapseAfter>
				<CodeBlock
					value={settings}
					style={{ margin: 0 }}
				/>
			</CollapseX>
			<TextLinkButton
				startIcon={<LockOpenOutlinedIcon />}
				color='primary'
				onClick={onEnterOAuthOptions}
				disabled={settings.hasOAuthOptions}>
				{`Enter OAuth options`}
			</TextLinkButton>
			<TextLinkButton
				startIcon={<NoEncryptionOutlinedIcon />}
				color='secondary'
				hoverColor='secondary'
				onClick={onClearOAuthOptions}
				disabled={settings.hasOAuthOptions === false}>
				{`Clear OAuth options`}
			</TextLinkButton>
			<Divider />
			<TextLinkButton
				startIcon={<WebOutlinedIcon />}
				onClick={onRegister}
				color='primary'>
				{`Register (login to exact-online)`}
			</TextLinkButton>
			<TextLinkButton
				startIcon={<VpnKeyOutlinedIcon />}
				onClick={onEnterAuthorizationCode}
				color='primary'
				disabled={settings.hasOAuthToken}>
				{`Manual fill in authorization code`}
			</TextLinkButton>
			<Divider />
			<LabelledPropertyX
				label={`O Auth Token issued at`}
				iconPosition='start'>
				{settings.oAuthToken ? formatDate(settings.oAuthToken.issuedAt, 'date-and-time') : <em>{`Not set`}</em>}
			</LabelledPropertyX>
			<LabelledPropertyX
				label={`O Auth Token expires at`}
				iconPosition='start'>
				{settings.oAuthToken ? formatDate(settings.oAuthToken.expiresAt, 'date-and-time') : <em>{`Not set`}</em>}
			</LabelledPropertyX>
			<CollapseX
				collapseAfter
				expandText='View last 5 issued tokens'
				collapseText='Hide last 5 issued tokens'>
				<CodeBlock
					value={settings.last5IssuedTokens}
					style={{ margin: 0 }}
				/>
			</CollapseX>
			<TextLinkButton
				startIcon={<DeleteForeverOutlinedIcon />}
				onClick={onClearOAuthToken}
				color='secondary'
				hoverColor='secondary'
				disabled={settings.hasOAuthToken === false}>
				{`Clear OAuth token`}
			</TextLinkButton>
			<Divider />
			<TextLinkButton
				startIcon={<CheckBoxOutlinedIcon />}
				onClick={onActivate}
				color='primary'
				disabled={settings.isEnabled}>
				{`Activate`}
			</TextLinkButton>
			<TextLinkButton
				startIcon={<HighlightOffOutlinedIcon />}
				onClick={onDeactivate}
				color='secondary'
				hoverColor='secondary'
				disabled={settings.isEnabled === false}>
				{`Deactivate`}
			</TextLinkButton>
			<Divider />
			<TextLinkButton
				color='primary'
				startIcon={<GetAppOutlinedIcon />}
				onClick={onLoadMyCurrentDivision}>
				{`Load my current division`}
			</TextLinkButton>
			{myDivision !== undefined && (
				<LabelledPropertyX
					icon={<StatusIcon status={myDivision !== -1 ? 'success' : 'error'} />}
					iconPosition='start'
					label={`My division (API call result, should not be -1)`}>
					{myDivision.toString()}
				</LabelledPropertyX>
			)}
		</CardX>
	);
};
