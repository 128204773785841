import { Card, CardMedia } from '@material-ui/core';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { PageableTable } from 'framework/table/PageableTable';
import { createHeader } from 'framework/table/createHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { INoahDeviceInventoryItemMapDetail } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { InventoryManagementPageBreadcrumb } from '../InventoryManagementPageBreadcrumb';
import { QueryContext } from './context/QueryContext';
import { InventoryItemHeader } from './headers/InventoryItemHeader';
import { NoahDeviceHeader } from './headers/NoahDeviceHeader';
import { PatientFromInventoryItemHeader } from './headers/PatientFromInventoryItemHeader';
import { PatientFromNoahHeader } from './headers/PatientFromNoahHeader';

export const SearchSerialNumbers = () => {
	const strings = useLocalization();
	const { reload, queryResult, params, setParams } = useQueryContext(QueryContext);
	const headers = useMemo(
		() => [
			createSortableHeader<INoahDeviceInventoryItemMapDetail>(strings.serialNumber, 'serialNumber', t => t.serialNumber),
			createHeader<INoahDeviceInventoryItemMapDetail>(`Noah?`, t => (
				<NoahDeviceHeader
					item={t}
					reload={reload}
				/>
			)),
			createHeader<INoahDeviceInventoryItemMapDetail>(`${strings.patient} (Noah)`, t => <PatientFromNoahHeader item={t} />),
			createHeader<INoahDeviceInventoryItemMapDetail>(`${strings.inventoryItem}?`, t => <InventoryItemHeader item={t} />),
			createHeader<INoahDeviceInventoryItemMapDetail>(`${strings.patient} (${strings.inventoryItem})`, t => <PatientFromInventoryItemHeader item={t} />),
		],
		// eslint-disable-next-line
		[strings]
	);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='SearchOnSerialNumbers'
			stickyHeader={
				<div
					className='df-row-ac'
					style={{ paddingBottom: 16 }}>
					<InventoryManagementPageBreadcrumb />
					<PageBreadcrumbDivider />
					<PageBreadcrumbTitle title={strings.searchOnWhat(strings.serialNumber)} />
					<div className='fg1'></div>
					<PageXHeaderActions>
						<PageReloadButtonX reload={reload} />
					</PageXHeaderActions>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16 }}>
				<LightBulbNotification style={{ marginBottom: 8 }}>{strings.searchSerialNumbersInInventoryItemsAndNoahReadouts}</LightBulbNotification>
				<div
					className='df-row-ac jc-sb'
					style={{ marginBottom: '24px' }}>
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchVerb}
						style={{ width: '400px' }}
						variant='paper'
					/>
				</div>
				{queryResult.unfilteredCount > 0 && (
					<>
						<Card>
							<CardMedia>
								<PageableTable<INoahDeviceInventoryItemMapDetail>
									queryResult={queryResult}
									headers={headers}
									sortParams={params.sortParams}
									pageParams={params.pageParams}
									setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
									getKey={inst => inst.serialNumber}
								/>
							</CardMedia>
						</Card>
					</>
				)}
				{queryResult.unfilteredCount === 0 && <NoRecordsFound>{strings.noWhatDefined(strings.serialNumbers)}</NoRecordsFound>}
			</div>
		</RestrictedPageX>
	);
};
