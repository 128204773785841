import { IHandoverLine } from '../IHandoverLine';
import { IEquipmentDeliveryLineWithSelection } from '../wizard/proforma/IEquipmentDeliveryLineWithSelection';

export const equipmentDeliveryLineToLine = (t: IEquipmentDeliveryLineWithSelection, line: IHandoverLine): IHandoverLine => {
	return {
		...line,
		id: t.id,
		description: t.description ?? line?.description ?? '',
		quantity: t.quantity,
		unitListPrice: t.unitListPriceInVat,
		unitPrice: t.unitPriceInVat,
		isRefundable: t.isRefundable,
		isIncludeOnHandoverCertificate: t.isIncludeOnHandoverCertificate,
	};
};
