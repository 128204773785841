import React from 'react';
import { RepairsQueryProvider } from './list/RepairsQueryProvider';
import { RepairReasonsCacheProvider } from './RepairReasonsCacheProvider';

export const RepairsProviders = ({ children }: any) => {
	return (
		<RepairsQueryProvider>
			<RepairReasonsCacheProvider>{children}</RepairReasonsCacheProvider>
		</RepairsQueryProvider>
	);
};
