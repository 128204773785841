import { Avatar, createStyles, makeStyles, Theme } from '@material-ui/core';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import React from 'react';
import { IUserDto } from 'gen/ApiClient';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		orange: {
			color: theme.palette.getContrastText(deepOrange[500]),
			backgroundColor: deepOrange[500],
		},
		purple: {
			color: theme.palette.getContrastText(deepPurple[500]),
			backgroundColor: deepPurple[500],
		},
	})
);

interface IProps {
	user: IUserDto;
}

export const UserAvatar = ({ user }: IProps) => {
	const classes = useStyles();

	return <Avatar className={user.isAdmin === true ? classes.orange : classes.purple}>{user.initials}</Avatar>;
};
