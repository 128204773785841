import { IQueryContext } from 'framework/context/IQueryContext';
import { useContext } from 'react';

interface IXQueryContext<TParams> {
	params: TParams;
	setParams: React.Dispatch<React.SetStateAction<TParams>>;
}

export function useParamsFromQueryContext<TResult, TParams>(context: React.Context<IQueryContext<TResult, TParams>>): IXQueryContext<TParams> {
	const { params, setParams } = useContext(context);
	return { params: params, setParams: setParams };
}
