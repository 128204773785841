import { Card, Divider, useTheme } from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { useApiCall } from 'framework/hooks/useApiCall';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IProductCatalogItem, IQueryResult, productCatalogQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createAddHeader } from 'shared/table/createAddHeader';
import { createRemoveHeader } from 'shared/table/createRemoveHeader';
import { createSelectHeader } from 'shared/table/createSelectHeader';
import { ProductCatalogItemForm } from '../forms/ProductCatalogItemForm';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { FilterProductCatalogType } from './FilterProductCatalogType';
import { ICommonParams } from './ICommonParams';
import { SelectableColumn } from './SelectableColumn';

interface IProps<TParams extends ICommonParams> {
	onAdd: (item: IProductCatalogItem) => void;
	onCredit?: (item: IProductCatalogItem) => void;
	style?: React.CSSProperties;
	queryResult: IQueryResult<IProductCatalogItem> | undefined;
	params: TParams;
	setParams: React.Dispatch<React.SetStateAction<TParams>>;
	filterType: FilterProductCatalogType;
	canCreditF?: (item: IProductCatalogItem) => boolean;
	isSubmitting?: boolean;
	mode?: 'add' | 'select';
}

export const SearchProductCatalog = <TParams extends ICommonParams>({
	onAdd,
	onCredit,
	queryResult,
	params,
	setParams,
	style,
	filterType,
	canCreditF,
	isSubmitting = false,
	mode,
}: IProps<TParams>) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { columns, columnsRecord, setColumns, headers } = useContext(ViewContext);
	const { open, confirm, cancel } = useDialogsContext();
	const load = useApiCall(productCatalogQuery_single);
	const selectedMode = useMemo(() => (mode === 'select' ? 'select' : 'add'), [mode]);
	const [selectedId, setSelectedId] = useState<string>();
	const addOrSelectHeader = useMemo(
		() =>
			selectedMode === 'add'
				? createAddHeader<IProductCatalogItem>(t => onAdd(t))
				: createSelectHeader<IProductCatalogItem>(
						t => {
							setSelectedId(t.id);
							onAdd(t);
						},
						t => isSubmitting,
						undefined,
						t => isSubmitting && t.id === selectedId
				  ),
		[selectedMode, onAdd, isSubmitting, selectedId]
	);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<ProductCatalogItemForm
				open
				confirm={id => {
					confirm();
					onConfirmNew(id);
				}}
				cancel={cancel}
				disableIntangible={filterType === 'stock'}
			/>
		);
	};

	const onConfirmNew = async (id: string) => {
		const r = await load(id);
		if (r.isSuccess) {
			setParams(onResetToPage1({ ...params, searchString: `${r.result.identifier}` }));
		}
	};

	return (
		<div
			className='df-col h100'
			style={style}>
			<SearchbarWithParams
				params={params}
				setParams={setParams}
				placeholder={strings.searchVerb}
				variant='paper'
				style={{ margin: '1px', marginBottom: theme.spacing(2) }}
			/>
			<Card
				variant='outlined'
				className='df-col'>
				<div className='df-row-ac jc-sb'>
					<Filter
						params={params}
						setParams={setParams}
						filterType={filterType}
					/>
					<div className='df-row-ac'>
						<TooltipWithSpan title={strings.itemNotFoundQuestionClickHereToDefineNewProductCatalogItem}>
							<SmallPrimaryTextButton
								startIcon={<PlaylistAddIcon />}
								color={queryResult.filteredCount === 0 ? 'secondary' : 'primary'}
								onClick={onNew}>
								{strings.new}
							</SmallPrimaryTextButton>
						</TooltipWithSpan>
						<SelectColumnsButtonAndDialog
							selectedColumns={columns}
							setSelectedColumns={setColumns}
							record={columnsRecord}
							view='productCatalogSearch'
						/>
					</div>
				</div>
				<Divider />
				<PageableTableWithColumnSelection<IProductCatalogItem, SelectableColumn>
					padding={theme.spacing(1)}
					headers={headers}
					selectedColumns={columns}
					getKey={t => t.id}
					pageParams={params.pageParams}
					sortParams={params.sortParams}
					queryResult={queryResult}
					setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
					appendColumn={
						onCredit
							? [
									createAddHeader<IProductCatalogItem>(t => onAdd(t), undefined, undefined, { paddingRight: 0 }),
									createRemoveHeader<IProductCatalogItem>(
										t => onCredit(t),
										t => (canCreditF === undefined ? false : canCreditF(t) === false),
										undefined,
										{ paddingLeft: 0 }
									),
							  ]
							: addOrSelectHeader
					}
				/>
			</Card>
		</div>
	);
};
