import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { IProductCatalogItem, TangibleType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { SelectUniquelyIdentifiableProductCatalogItemByType } from './SelectUniquelyIdentifiableProductCatalogItemByType';

interface IProps extends DialogProps {
	type: TangibleType;
	confirm: (item: IProductCatalogItem) => void;
	cancel: VoidFunction;
	title?: string;
	isSubmitting?: boolean;
}

export const SelectUniquelyIdentifiableProductCatalogItemByTypeDialog = ({ type, confirm, cancel, title, isSubmitting, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<Dialog
			{...rest}
			fullWidth
			maxWidth='md'>
			<CloseButtonOnAbsolutePosition onClick={cancel} />
			<DialogTitle>{title ? title : strings.selectAnArticleFromProductCatalog}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<SelectUniquelyIdentifiableProductCatalogItemByType
					type={type}
					onSelectPc={confirm}
					isSubmitting={isSubmitting}
				/>
			</DialogContent>
		</Dialog>
	);
};
