import React, { useContext, useMemo, useState } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';
import { SelectableColumn } from '../SelectableColumn';
import { createColumnRecord } from '../utils/createColumnRecord';
import { createHeaders } from '../utils/createHeaders';
import { ViewContext } from './ViewContext';

// enkel hier nodig
const DefaultColumns: SelectableColumn[] = ['serialNumber', 'vatPercentage', 'patientIdentifier', 'supplierIdentifier'];

export const ViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);
	const colRecord = useMemo(() => createColumnRecord(strings), [strings]);
	const { productCatalogItemTypeRecord, propertyOfTypeRecord } = useContext(RecordContext);
	const headers = useMemo(
		() => createHeaders(colRecord, strings, productCatalogItemTypeRecord, propertyOfTypeRecord),
		[colRecord, propertyOfTypeRecord, productCatalogItemTypeRecord, strings]
	);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
