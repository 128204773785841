import { IconButton, Typography, useTheme } from '@material-ui/core';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import React, { useContext } from 'react';
import { FileDownloadOutlineIcon } from 'framework/components/icons/FileDownloadOutlineIcon';
import { FileSearchOutlineIcon } from 'framework/components/icons/FileSearchOutlineIcon';
import { isFirst } from 'framework/utils/array/isFirst';
import { isLast } from 'framework/utils/array/isLast';
import { formatDate } from 'framework/utils/date/formatDate';
import { DocumentAction, DocumentExportFormat, documentsQuery_historyDownload, IDocumentHistory } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import { useDocumentEditor } from 'shared/dialogs/useDocumentEditor';
import { usePdfViewerWithDocument } from 'shared/dialogs/usePdfViewerWithDocument';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	id: string;
	canViewOnline: boolean;
	history: IDocumentHistory[];
	style?: React.CSSProperties;
	format?: DocumentExportFormat;
}

export const DocumentHistoryTimeline = ({ id, history, canViewOnline, style, format = 'Native' }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { documentActionRecord } = useContext(RecordContext);
	const [openEditor] = useDocumentEditor();
	const [download] = useDownload(documentsQuery_historyDownload);
	const openPdf = usePdfViewerWithDocument();

	const onView = async (historyId: string) => {
		openEditor(id, historyId);
	};

	return (
		<>
			{/* <PdfViewerDialog open={isPdfOpen} close={closePdf} id={id} historyId={historyId} fromHistory title="Historiek" /> */}
			<Timeline style={{ padding: 0, margin: 0, ...style }}>
				{history.map(t => (
					<TimelineItem key={t.id}>
						<TimelineOppositeContent>
							<Typography
								color='textSecondary'
								variant='caption'>
								{formatDate(t.date, 'date-and-time')}
							</Typography>
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineDot
								variant='outlined'
								color='primary'
							/>
							{isLast(t, history) === false && <TimelineConnector />}
						</TimelineSeparator>
						<TimelineContent>
							<div
								className='df-row-ac'
								style={{ marginTop: isFirst(t, history) ? 0 : '-5px' }}>
								<div>{documentActionRecord[t.action as DocumentAction]}</div>
								{isFirst(t, history) === false && (
									<div
										className='df-row-ac'
										style={{ marginLeft: theme.spacing(1) }}>
										{t.isDocx && canViewOnline && format === 'Native' && (
											<IconButton
												size='small'
												onClick={() => onView(t.id)}>
												<FileSearchOutlineIcon fontSize='small' />
											</IconButton>
										)}
										{(t.isPdf || format === 'Pdf') && (
											<IconButton
												size='small'
												onClick={() => openPdf(strings.history, id, t.id)}>
												<FileSearchOutlineIcon fontSize='small' />
											</IconButton>
										)}
										<IconButton
											size='small'
											onClick={() => download(id, t.id, format)}>
											<FileDownloadOutlineIcon fontSize='small' />
										</IconButton>
									</div>
								)}
							</div>
						</TimelineContent>
					</TimelineItem>
				))}
			</Timeline>
		</>
	);
};
