import { Card, CardContent, IconButton } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { LocationsPreloadCacheContext } from 'app/main/settings/locations/LocationsPreloadCacheContext';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { FilterOffIcon } from 'framework/components/icons/FilterOffIcon';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { CreditedStatus, CreditedStatuses, IAnalyzeSalesParams, PaymentStatus, PaymentStatuses, SalesType, SalesTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { SalesFilterDataContext } from '../../SalesFilterDataContext';

interface IProps {
	params: IAnalyzeSalesParams;
	onFilter: (params: IAnalyzeSalesParams) => void;
}

export const SalesAnalysisFilter = ({ params, onFilter }: IProps) => {
	const strings = useLocalization();
	const { paymentStatusRecord, saleTypeRecord, creditedStatusRecord } = useContext(RecordContext);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const [filterData] = useCacheContext(SalesFilterDataContext);

	return (
		<Card>
			<CardTitleTypography
				title={strings.filter}
				withPadding
				action={
					isFiltered(params) && (
						<TooltipWithSpan title={strings.clearFilter}>
							<IconButton
								color='secondary'
								onClick={() => onFilter(clearParams(params, []))}>
								<FilterOffIcon />
							</IconButton>
						</TooltipWithSpan>
					)
				}
			/>
			<CardContent
				className='df-col-as'
				style={{ paddingLeft: 10, paddingTop: 0 }}>
				<DateFilterItem
					label={strings.date}
					after={params.createdAfter}
					before={params.createdBefore}
					setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
				/>
				<RecordListFilterComponent<PaymentStatus>
					options={PaymentStatuses}
					record={paymentStatusRecord}
					selected={params.paymentStatuses as any}
					setSelected={vals => onFilter({ ...params, paymentStatuses: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.paymentStatus}
				/>
				<RecordListFilterComponent<SalesType>
					options={SalesTypes}
					record={saleTypeRecord}
					selected={params.types as any}
					setSelected={vals => onFilter({ ...params, types: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.type}
				/>
				<RecordListFilterComponent<CreditedStatus>
					record={creditedStatusRecord}
					options={CreditedStatuses}
					selected={params.creditedStatuses as any}
					setSelected={vals => onFilter({ ...params, creditedStatuses: vals })}
					icon={<HelpOutlineIcon />}
					label='Krediet status'
				/>
				<ListFilterComponent<string>
					options={locations}
					selected={params.locationIds}
					setSelected={vals => onFilter({ ...params, locationIds: vals })}
					label={strings.location}
					icon={<HelpOutlineIcon />}
					title={strings.location}
				/>
				{filterData && (
					<StringListFilterComponent
						options={filterData.labels}
						selected={params.labels}
						setSelected={vals => onFilter({ ...params, labels: vals })}
						label={strings.labels}
						icon={<HelpOutlineIcon />}
					/>
				)}
				{filterData && (
					<StringListFilterComponent
						options={filterData.zips}
						selected={params.zips}
						setSelected={vals => onFilter({ ...params, zips: vals })}
						label={strings.zip}
						icon={<HelpOutlineIcon />}
					/>
				)}
			</CardContent>
		</Card>
	);
};
