import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { loansQuery_single } from 'gen/ApiClient';
import React from 'react';
import { useParams } from 'react-router-dom';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { LoanDetailDocumentsCard } from './LoanDetailDocumentsCard';
import { LoanDetailPageHeader } from './LoanDetailPageHeader';
import { LoanDetailPageSidebar } from './LoanDetailPageSidebar';
import { LoanDetailsContentCard } from './contents/LoanDetailsContentCard';

export const LoanDetailPage = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [trigger, reload] = useTrigger();
	const [item, reloadDetail] = useApiEffect(loansQuery_single, id);

	useLazyEffect(() => {
		reloadDetail();
	}, [trigger]);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='Loans'
			stickyHeader={
				<LoanDetailPageHeader
					item={item}
					reload={reload}
				/>
			}
			stickySidebar={
				<LoanDetailPageSidebar
					item={item}
					reload={reload}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
					maxWidth: 1280,
				}}>
				<div className='df-row-wrap gap-16'>
					<LoanDetailsContentCard
						item={item}
						reload={reload}
						style={{ flexGrow: 2 }}
						className='h-fc'
					/>
					<div
						className='df-col gap-16'
						style={{ flexGrow: 1 }}>
						<LoanDetailDocumentsCard
							item={item}
							reload={reload}
							reloadTrigger={trigger}
						/>
					</div>
				</div>
			</div>
		</RestrictedPageX>
	);
};
