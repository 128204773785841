import { Badge, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, Typography, useTheme } from '@material-ui/core';
import EuroOutlinedIcon from '@material-ui/icons/EuroOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import { calcSalesDetailRoute } from 'app/main/financial/routes';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { formatDate } from 'framework/utils/date/formatDate';
import { ISalesSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SalesDetailDialog } from './SalesDetailDialog';
import { SalesTypeChip } from './SalesTypeChip';

interface IProps {
	reload: VoidFunction;
	hideAction?: boolean;
	item: ISalesSummary;
}

export const SalesListItemX = ({ item, reload, hideAction = false }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { push } = useHistory();
	const { open, confirm } = useDialogsContext(reload);

	const onViewDetail = () => {
		open(
			<SalesDetailDialog
				open
				id={item.id}
				close={confirm}
			/>
		);
	};

	const onNavigate = () => {
		push(calcSalesDetailRoute(item.id));
	};

	return (
		<div>
			<ListItem
				disableGutters
				button
				onClick={onViewDetail}>
				<ListItemIcon style={{ minWidth: '40px' }}>
					<Badge
						badgeContent={
							<EuroOutlinedIcon
								style={{
									color: item.isFullyPaid ? theme.palette.success.main : theme.palette.error.main,
									borderRadius: '100%',
									fontSize: '18px',
									backgroundColor: theme.palette.background.default,
								}}
							/>
						}
						overlap='circle'>
						<ReceiptOutlinedIcon />
					</Badge>
				</ListItemIcon>
				<div className='df-row-ac w100 jc-sb'>
					<div className='df-col'>
						<Typography
							variant='caption'
							color='textSecondary'>
							{formatDate(item.date)}
						</Typography>
						<Typography
							variant='body2'
							noWrap
							display='block'>
							{item.reference}
						</Typography>
					</div>
					<SalesTypeChip
						item={item}
						style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(1) }}
						// size='extra-small'
					/>
				</div>
				{hideAction === false && (
					<ListItemSecondaryAction>
						<TooltipWithSpan title={strings.navigateToDetailsPage}>
							<IconButton
								edge='end'
								onClick={onNavigate}>
								<OpenInNewIcon />
							</IconButton>
						</TooltipWithSpan>
					</ListItemSecondaryAction>
				)}
			</ListItem>
		</div>
	);
};
