import { CardProps, Typography } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { ISupplier } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { SupplierDetailsContent } from './SupplierDetailsContent';

interface IProps extends CardProps {
	reload: VoidFunction;
	item: ISupplier;
}

export const SupplierDetailsCard = ({ item, reload, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<CardX {...rest}>
			<Typography
				component='div'
				style={{ fontSize: '16px', fontWeight: 500, marginBottom: 16 }}>
				{`${strings.detailsOfSupplier}: ${item.name}`}
			</Typography>
			<SupplierDetailsContent
				item={item}
				reload={reload}
			/>
		</CardX>
	);
};
