import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { useLoggedInUserIsUserById } from 'app/auth/useLoggedInUserIsUserById';
import { useLoggedInUserIsMinimalInRole } from 'app/auth/useLoggedInUserIsMinimalInRole';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IUserDto, usersCommand_block, usersCommand_delete, usersCommand_unBlock } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ChangePasswordForm } from './forms/ChangePasswordForm';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { ResetPasswordForm } from './forms/ResetPasswordForm';

interface IProps {
	item: IUserDto;
	reload: VoidFunction;
}

export const UserDetailsActionsContent = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const itsMe = useLoggedInUserIsUserById(item.id);
	const canExecuteAdminActions = useLoggedInUserIsMinimalInRole('Admin');
	const isSuperUser = useLoggedInUserIsSuperUser();

	const onDelete = () => {
		if (canExecuteAdminActions) {
			open(
				<AlertDialog
					open
					title={strings.deleteUserQuestion}
					content={strings.deleteUserContent}
					acceptText={strings.yesWhatExclamation(strings.deleteUser)}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => usersCommand_delete(item.id)}
					accept={confirm}
				/>
			);
		}
	};

	const onBlock = () => {
		if (canExecuteAdminActions) {
			open(
				<AlertDialog
					open
					title={strings.blockUserQuestion}
					content={strings.blockUserContent}
					acceptText={strings.yesWhatExclamation(strings.block)}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => usersCommand_block(item.id)}
					accept={confirm}
				/>
			);
		}
	};

	const onUnblock = () => {
		if (canExecuteAdminActions) {
			open(
				<AlertDialog
					open
					title={strings.unBlockUserQuestion}
					content={strings.unblockUserContent}
					acceptText={strings.yesWhatExclamation(strings.unblock)}
					rejectText={strings.cancel}
					reject={cancel}
					acceptF={() => usersCommand_unBlock(item.id)}
					accept={confirm}
				/>
			);
		}
	};

	const onChangePassword = () => {
		if (itsMe) {
			open(
				<ChangePasswordForm
					open
					user={item}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	const onResetPassword = () => {
		if (isSuperUser) {
			open(
				<ResetPasswordForm
					open
					user={item}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	return (
		<>
			<Typography16pxBold>{strings.actions}</Typography16pxBold>
			<TextLinkButton
				color='primary'
				startIcon={<VpnKeyIcon />}
				onClick={onChangePassword}
				disabled={itsMe === false}>
				{strings.changePassword}
			</TextLinkButton>
			{isSuperUser === true &&
				<TextLinkButton
					color='primary'
					startIcon={<VpnKeyIcon />}
					onClick={onResetPassword}>
					{`Reset password (only for super-users)`}
				</TextLinkButton>
			}
			{item.blocked === false && (
				<TextLinkButton
					color='secondary'
					startIcon={<BlockIcon />}
					onClick={onBlock}
					disabled={canExecuteAdminActions === false}>
					{strings.blockUser}
				</TextLinkButton>
			)}
			{item.blocked === true && (
				<>
					<TextLinkButton
						color='primary'
						startIcon={<LockOpenIcon />}
						onClick={onUnblock}
						disabled={canExecuteAdminActions === false}>
						{strings.unBlockUser}
					</TextLinkButton>
					<TextLinkButton
						color='secondary'
						startIcon={<DeleteIcon />}
						onClick={onDelete}
						disabled={canExecuteAdminActions === false}>
						{strings.deleteUser}
					</TextLinkButton>
				</>
			)}
		</>
	);
};
