import { Card, Divider, useTheme } from '@material-ui/core';
import React, { useContext } from 'react';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { createHeader } from 'framework/table/createHeader';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IQueryResult, ISale, ISalesForCommissionQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { createSelectHeader } from 'shared/table/createSelectHeader';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { SalesPaymentStatusAndCreditChips } from '../../components/SalesPaymentStatusAndCreditChips';
import { SalesCommissionStatusChip } from '../../SalesCommissionStatusChip';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { SelectableColumn } from './SelectableColumn';

interface IProps {
	params: ISalesForCommissionQueryParams;
	setParams: React.Dispatch<React.SetStateAction<ISalesForCommissionQueryParams>>;
	queryResult: IQueryResult<ISale>;
	onSelect: (item: ISale) => void;
	style?: React.CSSProperties;
}

export const SearchSaleForCommission = ({ params, setParams, queryResult, onSelect, style }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { columns, columnsRecord, setColumns, headers } = useContext(ViewContext);

	return (
		<div
			className='df-col h100'
			style={{ padding: theme.spacing(2), paddingTop: theme.spacing(3), ...style }}>
			<SearchbarWithParams
				params={params}
				setParams={setParams}
				placeholder={strings.searchVerb}
				variant='paper'
				style={{ margin: '1px', marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
			/>
			<Card
				variant='outlined'
				className='df-col'>
				<div className='df-row-ac jc-sb'>
					<Filter
						params={params}
						setParams={setParams}
					/>
					<div className='df-row-ac'>
						<FormControlLabelSwitch
							isChecked={params.isShowAll}
							toggle={() => setParams(onResetToPage1({ ...params, isShowAll: !params.isShowAll }))}
							label={strings.viewAll}
						/>
						<SelectColumnsButtonAndDialog
							selectedColumns={columns}
							setSelectedColumns={setColumns}
							record={columnsRecord}
							view='searchSales'
						/>
					</div>
				</div>
				<Divider />
				<PageableTableWithColumnSelection<ISale, SelectableColumn>
					padding={theme.spacing(1)}
					headers={headers}
					selectedColumns={columns}
					getKey={t => t.id}
					pageParams={params.pageParams}
					sortParams={params.sortParams}
					queryResult={queryResult}
					setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
					prependColumn={createHeader<ISale>(strings.status, t => (
						<div className='df-row-ac'>
							{t.hasCommission && <SalesCommissionStatusChip style={{ marginRight: 8 }} />}
							<SalesPaymentStatusAndCreditChips item={t} />
						</div>
					))}
					appendColumn={createSelectHeader<ISale>(
						t => onSelect(t),
						t => t.canSelectForCommission === false
					)}
				/>
			</Card>
		</div>
	);
};
