import { IconButton, TextField } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useApiCall } from 'framework/hooks/useApiCall';
import { IPatientSearch, PatientSearchMode, patientsQuery_search, patientsQuery_singleSearch } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from '../dialogs/useDialogsContext';
import { PatientModelForm } from 'app/main/patients/forms/PatientModelForm';
import { RecordContext } from '../records/RecordContext';
import { PatientSearchContext } from './PatientSearchContext';
import { PatientSearchOption } from './PatientSearchOption';
import { SelectPatientSearchMode } from './SelectPatientSearchMode';

//https://joaoforja.com/blog/5-steps-to-perform-a-search-when-user-stops-typing-using-react-+-hooks-in-a-controlled-component/
//https://dev.to/przemwo/how-to-execute-a-function-only-after-the-user-stops-typing-beh

interface IProps {
	selectedId: string | undefined;
	onSetSelected: (val: IPatientSearch | undefined) => void;
	disabled?: boolean;
	required?: boolean;
	label?: string;
	error?: boolean;
	style?: React.CSSProperties;
	canCreateNew: boolean;
}

export const SelectPatient = ({ selectedId, disabled, required, label, style, error, onSetSelected, canCreateNew }: IProps) => {
	const strings = useLocalization();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const searchF = useApiCall(patientsQuery_search, setIsLoading);
	const { searchString, setSearchString, results, setResults, setSelected } = useContext(PatientSearchContext);
	const initializeRef = React.useRef<boolean>(false);
	const [searchMode, setSearchMode] = useState<PatientSearchMode>('Name');
	const { patientSearchModeToLabelRecord } = useContext(RecordContext);
	const placeHolder = useMemo(() => patientSearchModeToLabelRecord[searchMode], [searchMode, patientSearchModeToLabelRecord]);
	const { open, confirm, cancel } = useDialogsContext();
	const loadSingle = useApiCall(patientsQuery_singleSearch);

	const search = async (mode: PatientSearchMode, searchString: string) => {
		if (searchString) {
			const r = await searchF({ mode: mode, searchString: searchString });
			setResults(r.isSuccess ? r.result : []);
			// setSelected(undefined);
		}
	};

	useEffect(() => {
		if (initializeRef.current) {
			const timeoutId = setTimeout(() => search(searchMode, searchString), 500);
			return () => clearTimeout(timeoutId);
		} else {
			initializeRef.current = true;
		}
		// eslint-disable-next-line
	}, [searchMode, searchString]);

	useEffect(() => {
		var f = results.find(t => t.id === selectedId);
		if (f === undefined) {
			setResults([]);
			setSearchString('');
		} else {
			setSelected(f);
		}
		// eslint-disable-next-line
	}, [selectedId]);

	const onSelect = (val: IPatientSearch | null) => {
		onSetSelected(val ?? undefined);
		setSelected(val ?? undefined);
	};

	const onNew = () => {
		open(
			<PatientModelForm
				open
				confirm={id => {
					confirm();
					onConfirmNew(id);
				}}
				cancel={cancel}
			/>
		);
	};

	const onConfirmNew = async (id: string) => {
		const r = await loadSingle(id);
		if (r.isSuccess) {
			setResults([r.result]);
			onSelect(r.result);
		}
	};

	return (
		<Autocomplete
			style={{ padding: '10px 0px', ...style }}
			disabled={disabled}
			inputValue={searchString}
			onInputChange={(e: any, val: string, reason: string) => {
				setSearchString(val);
			}}
			loading={isLoading}
			loadingText={strings.searchVerb}
			value={results.find(t => t.id === selectedId) ?? null}
			onChange={(_: any, newValue: IPatientSearch | null) => onSelect(newValue)}
			options={results ?? []}
			noOptionsText={strings.noResults}
			getOptionLabel={option => option.lastNameCommaFirstNameBracketsNickName ?? ''}
			getOptionSelected={option => option?.id === selectedId}
			filterOptions={x => x}
			renderOption={option => (
				<PatientSearchOption
					searchMode={searchMode}
					option={option}
				/>
			)}
			renderInput={params => (
				<TextField
					{...params}
					error={error}
					required={required}
					label={label ?? strings.patient}
					placeholder={placeHolder}
					variant='filled'
					onKeyDownCapture={e => e.key === 'Enter' && search(searchMode, searchString)}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								<div
									className='df-row-ac'
									style={{ marginTop: -16 }}>
									<SelectPatientSearchMode
										mode={searchMode}
										setMode={setSearchMode}
									/>
									{canCreateNew && (
										<IconButton
											size='small'
											color='primary'
											onClick={onNew}
											disabled={disabled}>
											<AddCircleIcon />
										</IconButton>
									)}
								</div>
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	);
};
