import { useTheme } from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ChipX } from 'framework/components/chips/ChipX';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IPaymentLineData } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { PaymentDialog } from '../../payments/PaymentDialog';
import { useLocalization } from 'localization/useLocalization';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	line: IPaymentLineData;
	withoutBorder?: boolean;
}

export const SalesDetailAccountReceivablePaymentListItem = ({ line, withoutBorder }: IProps) => {
	const { translatePaymentMethod } = useContext(RecordContext);
	const { open, confirm } = useDialogsContext();
	const theme = useTheme();
	const strings = useLocalization();

	const onViewDetail = () => {
		open(
			<PaymentDialog
				open
				id={line.paymentId}
				close={confirm}
			/>
		);
	};

	return (
		<div
			className='df-row w100 gap-8'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<CheckCircleOutlineOutlinedIcon style={{ fontSize: 22, color: theme.palette.success.main }} />
			<div className='df-col fg1 gap-2'>
				<TextLinkButton
					onClick={onViewDetail}
					style={{ fontSize: MajorFontSize }}>
					{line.reference}
				</TextLinkButton>
				<ChipX
					color='primary'
					label={translatePaymentMethod(line.paymentMethodIdentifier)}
					style={{ width: 'fit-content' }}
				/>
				<span style={{ fontSize: MinorFontSize }}>{`${strings.date}: ${formatDate(line.created, 'date-and-time')}`}</span>
				<span style={{ fontSize: MinorFontSize }}>{`${strings.amount}: ${formatCurrency(line.value)}`}</span>
			</div>
		</div>
	);
};
