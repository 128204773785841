import React from 'react';
import { ReactComponent } from './rosa-logo.svg';

interface IProps {
	className?: string;
	style?: React.CSSProperties;
}

export const RosaLogo = ({ className, style }: IProps) => {
	return (
		<ReactComponent
			className={className}
			style={style}
		/>
	);
};
