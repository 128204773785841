import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	pageSize: number;
	pageSizes: number[];
	setPageSize: (index: number) => void;
	style?: React.CSSProperties;
}

export const PaginationXPageSizeButton = ({ pageSize, setPageSize, pageSizes, style }: IProps) => {
	const strings = useLocalization();
	const [anchor, open, close] = useAnchorElement();

	return (
		<>
			<Button
				size='small'
				endIcon={<ArrowDropDownIcon />}
				onClick={open}
				style={style}>
				{`${strings.showColon} ${pageSize.toString()} ${strings.lines.toLowerCase()}`}
			</Button>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={close}
				keepMounted>
				{pageSizes.map(x => (
					<MenuItem
						key={x.toString()}
						value={x}
						onClick={() => {
							setPageSize(x);
							close();
						}}>
						{`${x.toString()} ${strings.lines.toLowerCase()}`}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
