import { DialogActions } from '@material-ui/core';
import React from 'react';
import { IPageableFormActionsProps, PageableFormActions } from './PageableFormActions';

interface IProps<TModel extends object | null | undefined> extends IPageableFormActionsProps<TModel> {
	children?: any;
}

export const PageableFormDialogActions = <TModel extends object | null | undefined>({ children, ...props }: IProps<TModel>) => {
	return (
		<DialogActions className='df-col'>
			{children && children}
			<PageableFormActions {...props} />
		</DialogActions>
	);
};
