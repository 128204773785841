import { Collapse } from '@material-ui/core';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageStatsButtonX } from 'framework/components/layout/PageStatsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { ICustomerAccountQueryMetadata } from 'gen/ApiClient';
import React from 'react';
import { CustomerAccountsPageBreadcrumbs } from './CustomerAccountsPageBreadcrumbs';
import { CustomerAccountsStats } from './CustomerAccountsStats';

interface IProps {
	metadata: ICustomerAccountQueryMetadata;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const CustomerAccountsListHeader = ({ metadata, reload, style }: IProps) => {
	const [showStats, toggleShowStats] = useStateToggleBoolean(true);

	return (
		<div
			className='df-col fg1'
			style={style}>
			<div className='df-row-ac'>
				<CustomerAccountsPageBreadcrumbs />
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageStatsButtonX
						showStats={showStats}
						toggleShowStats={toggleShowStats}
					/>
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<Collapse in={showStats}>
				<CustomerAccountsStats
					metadata={metadata}
					style={{ paddingTop: 16 }}
				/>
			</Collapse>
		</div>
	);
};
