import React, { useState } from 'react';
import { INoahDeviceReadOut, IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto } from 'gen/ApiClient';
import { SelectNoahDeviceReadOutForm } from '../../../noahDevices/rules/forms/SelectNoahDeviceReadOutForm';
import { SelectRizivRefundableHearingInstrumentForm } from 'shared/rizivRefundableHearingInstruments/SelectRizivRefundableHearingInstrumentForm';
import { HearingInstrumentForm } from './HearingInstrumentForm';
import { HearingInstrumentMode } from './HearingInstrumentMode';
import { HearingInstrumentModeForm } from './HearingInstrumentModeForm';
import { useLocalization } from 'localization/useLocalization';

type State = 'mode' | HearingInstrumentMode;

interface IProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	previous?: VoidFunction;
}

export const CreateHearingInstrumentForm = ({ confirm, cancel, previous }: IProps) => {
	const [state, setState] = useState<State>('mode');
	const [rizivHearingAidGroup, setRizivHearingAidGroup] = useState<IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto>();
	const [noahReadOut, setNoahReadOut] = useState<INoahDeviceReadOut>();

	const onSelectMode = (mode: HearingInstrumentMode) => {
		setState(mode);
	};

	const onRiziv = (group: IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto) => {
		setNoahReadOut(undefined);
		setRizivHearingAidGroup(group);
		setState('manual');
	};

	const onReadOut = (readOut: INoahDeviceReadOut) => {
		setRizivHearingAidGroup(undefined);
		setNoahReadOut(readOut);
		setState('manual');
	};

	if (state === 'mode') {
		return (
			<HearingInstrumentModeForm
				open
				jsxTitle={<Title />}
				confirm={onSelectMode}
				cancel={cancel}
				previous={previous}
			/>
		);
	} else if (state === 'riziv') {
		return (
			<SelectRizivRefundableHearingInstrumentForm
				open
				isSubmitting={false}
				cancel={cancel}
				jxsTitle={<Title />}
				onClickSelect={onRiziv}
			/>
		);
	} else if (state === 'readouts') {
		return (
			<SelectNoahDeviceReadOutForm
				open
				cancel={cancel}
				jxsTitle={<Title />}
				onClickSelect={onReadOut}
			/>
		);
	} else {
		return (
			<HearingInstrumentForm
				open
				isHearingInstrument
				confirm={confirm}
				cancel={cancel}
				manufacturer={rizivHearingAidGroup?.manufacturer ?? noahReadOut?.manufacturer ?? ''}
				model={rizivHearingAidGroup?.name ?? noahReadOut?.instrumentTypeName ?? ''}
				rizivHearingAidDefinitionV2ManufacturerAndNameGroupId={rizivHearingAidGroup?.id}
				previous={() => setState('mode')}
			/>
		);
	}
};

const Title = () => {
	const strings = useLocalization();
	return (
		<>
			<div>{strings.newWhat(strings.hearingAid)}</div>
			{/* <div>{item.manufacturerCommaInstrumentTypeName} {item.serialNumber}</div> */}
		</>
	);
};
