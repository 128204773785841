export class Try<T> {
	readonly result: T;
	readonly error: any | undefined;
	readonly isFailure: boolean;
	readonly isSuccess: boolean;

	constructor(result: T, error?: any) {
		this.result = result;
		this.error = error;
		if (error) {
			this.isFailure = true;
			this.isSuccess = false;
		} else {
			this.isFailure = false;
			this.isSuccess = true;
		}
	}
}
