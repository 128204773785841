import { Fade, InputAdornment } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { FieldHelperProps, FieldInputProps } from 'formik';
import React from 'react';

interface IProps<TProperty> {
	isHovered: boolean;
	field: FieldInputProps<TProperty>;
	disabled: boolean;
	helpers: FieldHelperProps<TProperty>;
	clear?: VoidFunction;
	defaultValue: TProperty;
}

export const ClearEndAdornment = <TProperty extends unknown>({
	isHovered,
	field,
	helpers,
	disabled,
	clear: externalClear,
	defaultValue,
}: IProps<TProperty>) => {
	const clear = () => {
		helpers.setTouched(true);
		helpers.setValue(defaultValue);
		if (externalClear) {
			externalClear();
		}
	};

	return (
		<InputAdornment position='end'>
			<Fade in={isHovered && field.value !== undefined && field.value !== '' && disabled === false}>
				<CancelIcon
					color='primary'
					style={{ cursor: 'pointer' }}
					onClick={() => clear()}
				/>
			</Fade>
		</InputAdornment>
	);
};
