import { IOrderArticlesInquiryLineRequest } from 'gen/ApiClient';
import { IArticlesInquiryLineDtoWithIsSelected } from './IArticlesInquiryLineDtoWithIsSelected';

export const mapArticlesInquiryLineDtoWithIsSelectedToLineRequest = (
	line: IArticlesInquiryLineDtoWithIsSelected,
	zeroBasedIndex: number
): IOrderArticlesInquiryLineRequest => {
	return {
		articlesInquiryLineId: line.id,
		quantity: line.quantityToOrder,
		zeroBasedIndex: zeroBasedIndex,
	};
};
