import { PatientsSelectableColumn } from './PatientsSelectableColumn';
import { IStrings } from 'localization/IStrings';

export const createPatientsColumnRecord = (strings: IStrings): Record<PatientsSelectableColumn, string> => {
	return {
		created: strings.createdAt,
		lastUpdated: strings.lastUpdated,
		dateOfBirth: strings.dateOfBirth,
		lastNameCommaFirstNameBracketsNickName: strings.name,
		lastName: strings.lastName,
		firstName: strings.firstName,
		gender: strings.gender,
		canRenewAfter: strings.renewalDate,
		lastHandoverDate: strings.lastHandover,
		phoneNumbersDisplay: strings.phone,
		addressFormatted: strings.address,
		socialSecurityNumber: strings.socialSecurityNumber,
		labels: strings.labels,
		reference: strings.numbering,
	};
};
