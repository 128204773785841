import { Collapse, useTheme } from '@material-ui/core';
import BlurCircularOutlinedIcon from '@material-ui/icons/BlurCircularOutlined';
import { calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { TextLinkButtonWithExpandIcon } from 'framework/components/buttons/TextLinkButtonWithExpandIcon';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ITenderLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	line: ITenderLine;
	withoutBorder?: boolean;
	isExpanded: boolean;
	toggle: VoidFunction;
}

export const TenderLineListItem = ({ line, withoutBorder, isExpanded, toggle }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const theme = useTheme();

	return (
		<div
			className='df-row w100 gap-2'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<BlurCircularOutlinedIcon style={{ fontSize: 22, color: theme.palette.text.secondary }} />
			<div className='df-col fg1 gap-4'>
				{line.hasProductCatalogItem === true && (
					<TextLinkButton
						onClick={() => push(calcProductCatalogDetailRoute(line.productCatalogItemId!))}
						style={{ fontSize: MajorFontSize }}>
						{`${line.quantity} x ${line.description}`}
					</TextLinkButton>
				)}
				{line.hasProductCatalogItem === false && <span style={{ fontSize: MajorFontSize }}>{`${line.quantity} x ${line.description}`}</span>}
				<TextLinkButtonWithExpandIcon
					toggle={toggle}
					isExpanded={isExpanded}>
					<span style={{ fontSize: MinorFontSize, fontWeight: 'unset' }}>
						{`${line.vatPercentage}% ${strings.vat}, ${formatCurrency(line.linePriceInVat)} ${strings.inVatShort}`}
					</span>
				</TextLinkButtonWithExpandIcon>
				<Collapse in={isExpanded}>
					<div className='df-col gap-4'>
						<span style={{ fontSize: MinorFontSize }}>
							{`${strings.unitListPrice}: ${formatCurrency(line.unitListPriceExVat)} (${strings.exVatShort}) | ${formatCurrency(line.unitListPriceInVat)} (${
								strings.inVatShort
							})`}
						</span>
						<span style={{ fontSize: MinorFontSize }}>
							{`${strings.discount}: ${line.discountPercentage.toFixed(2)}% | ${formatCurrency(line.lineDiscountValueExVat)} (${
								strings.exVatShort
							}) | ${formatCurrency(line.lineDiscountValueInVat)} (${strings.inVatShort})`}
						</span>
						<span style={{ fontSize: MinorFontSize }}>{`${strings.vat}: ${line.vatPercentage}% | ${formatCurrency(line.lineVatValue)}`}</span>
					</div>
				</Collapse>
			</div>
		</div>
	);
};
