import { IMedicalTreatmentFeeDto, LanguageOption } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const mapMedicalTreatmentFeeToTenderModelLine = (val: IMedicalTreatmentFeeDto, language: LanguageOption): ILine => {
	return {
		isSelected: true,
		id: val.code!,
		description: `${val.code!} ${language === 'fr' ? val.nomenDescriptionFr : val.nomenDescriptionNl}`,
		item: undefined,
		quantity: 1,
		unitListPrice: val.contribution * -1,
		unitPrice: val.contribution * -1,
		vatPercentage: 6,
	};
};
