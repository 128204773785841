import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { InventoryManagementPageBreadcrumb } from '../InventoryManagementPageBreadcrumb';
import { NoahDevicesTabType, NoahDevicesTabTypes } from './NoahDevicesTabType';
import { NoahDeviceReadOutModeForm } from 'app/main/settings/forms/NoahDeviceReadOutModeForm';

interface IProps {
	reload: VoidFunction;
	tab: NoahDevicesTabType;
	setTab: (tab: NoahDevicesTabType) => void;
}

export const NoahDevicesPageHeader = ({ reload, tab, setTab }: IProps) => {
	const strings = useLocalization();
	const { noahDevicesTabTypeRecord } = useContext(RecordContext);
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onSettings = () => {
		open(
			<NoahDeviceReadOutModeForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<div className='df-col fg1'>
			<div className='df-row-ac'>
				<InventoryManagementPageBreadcrumb />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={strings.stockTransactions} />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle
					title={noahDevicesTabTypeRecord[tab]}
					// icon={StockTransactionTabIconRecord[tab]}
				/>
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageSettingsButtonX onClick={onSettings} />
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<HorizontalTabsX
				tabs={NoahDevicesTabTypes}
				selectedTab={tab}
				setSelectedTab={setTab}
				// startIconRecord={StockTransactionTabIconRecord}
				titleRecord={noahDevicesTabTypeRecord}
				style={{
					marginTop: 16,
					marginLeft: -4,
				}}
			/>
		</div>
	);
};
