import { useApiCall } from 'framework/hooks/useApiCall';
import { IUserPreferences, userPreferencesQuery_get } from 'gen/ApiClient';
import React, { useCallback, useContext, useState } from 'react';
import { PreloadContext } from 'shared/context/PreloadContext';
import { UserPreferencesPreloadCacheContext } from './UserPreferencesPreloadCacheContext';

export const UserPreferencesPreloadCacheProvider = ({ children }: any) => {
	const { userPreferences } = useContext(PreloadContext);
	const [data, setData] = useState<IUserPreferences>(userPreferences);
	const load = useApiCall(userPreferencesQuery_get);

	const reload = useCallback(async () => {
		const r = await load();
		if (r.isSuccess) {
			setData(r.result);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<UserPreferencesPreloadCacheContext.Provider
			value={{
				userPreferences: data,
				reload: reload,
			}}>
			{children}
		</UserPreferencesPreloadCacheContext.Provider>
	);
};
