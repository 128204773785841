import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import LinkIcon from '@material-ui/icons/Link';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { NumericIcon } from 'framework/components/icons/NumericIcon';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { IPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useMemo } from 'react';
import { useLocalizedHealthInsuranceFundsCache } from 'shared/context/useLocalizedHealthInsuranceFundsCache';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { LinkHealthInsuranceFundForm } from '../../forms/LinkHealthInsuranceFundForm';

interface IProps {
	item: IPatient;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const PatientHifLabelledProperty = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const data = useLocalizedHealthInsuranceFundsCache();
	const hif = useMemo(() => data?.find(t => t.id === item.healthInsuranceFundCode), [data, item]);
	const popupState = usePopupState({
		variant: 'popover',
		popupId: 'demoPopover',
	});

	const onLinkHif = () => {
		open(
			<LinkHealthInsuranceFundForm
				open
				confirm={confirm}
				cancel={cancel}
				patient={item}
			/>
		);
	};

	return (
		<>
			<LabelledPropertyX
				label={strings.healthInsuranceFund}
				style={style}>
				<div
					className='df-row-ac'
					style={{ cursor: 'pointer' }}
					{...bindHover(popupState)}>
					{hif === undefined && <em>{strings.notApplicableAbbreviation}</em>}
					{hif !== undefined && <span style={{ marginRight: 4 }}>{hif.id}</span>}
					<StatusIcon
						status={hif !== undefined ? 'success' : 'error'}
						fontSize='small'
						style={{ marginLeft: 4 }}
					/>
				</div>
			</LabelledPropertyX>
			<HoverPopover
				elevation={1}
				{...bindPopover(popupState)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}>
				<div
					className='df-col'
					style={{ padding: 8 }}>
					{item.hasHealthInsuranceFundSet === true && hif === undefined && (
						<WarningLabelledProperty warning={`${item.healthInsuranceFundCode}\n${strings.thisHifDoesNotExistAnymore}`} />
					)}
					{item.hasHealthInsuranceFundSet === false && <em>{strings.noLinkWithHealthInsuranceFund}</em>}
					{hif !== undefined && (
						<>
							<LabelledPropertyX icon={<NumericIcon />}>{hif.id}</LabelledPropertyX>
							<LabelledPropertyX icon={<HomeOutlinedIcon />}>{hif.identifier}</LabelledPropertyX>
						</>
					)}
					<TextLinkButton
						startIcon={<LinkIcon />}
						onClick={onLinkHif}
						style={{ paddingBottom: 0 }}>
						{item.hasHealthInsuranceFundSet ? strings.linkWithAnotherHealthInsuranceFund : strings.linkWithAHealthInsuranceFund}
					</TextLinkButton>
				</div>
			</HoverPopover>
		</>
	);
};
