import { DialogProps } from '@material-ui/core';
import React from 'react';
import { DiscountForm } from './DiscountForm';
import { PriceForm } from './PriceForm';

interface IProps extends DialogProps {
	unitPrice: number;
	listPrice: number;
	confirm: (unitPrice: number, listPrice: number) => void;
	cancel: VoidFunction;
	forPurchase: boolean;
}

export const PriceOrDiscountForm = ({ unitPrice, listPrice, confirm, cancel, forPurchase, ...rest }: IProps) => {
	if (unitPrice < 0) {
		return (
			<DiscountForm
				{...rest}
				unitPrice={unitPrice}
				confirm={up => confirm(up, up)}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<PriceForm
				{...rest}
				unitPrice={unitPrice}
				listPrice={listPrice}
				confirm={confirm}
				cancel={cancel}
				forPurchase={forPurchase}
			/>
		);
	}
};
