import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, LinearProgress, Paper, useTheme } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { TablePaging } from 'framework/table/TablePaging';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { createHeader } from 'framework/table/createHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import {
	IRizivHearingAidDefinitionV2IdAndDistributorDto,
	IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto,
	IRizivHearingAidDefinitionsV2ManufacturerAndNameGroupQueryParams,
	rizivHearingAidDefinitionsV2ManufacturerAndNameGroupQuery_query,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { NoRecordsFound } from '../../framework/components/NoRecordsFound';
import { createSelectHeader } from '../table/createSelectHeader';
import { Filter } from './Filter';
import { SingleItem } from './SingleItem';

const DefaultParams: IRizivHearingAidDefinitionsV2ManufacturerAndNameGroupQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'manufacturerCommaName' },
	pageParams: { index: 1, size: 10 },
	distributors: [],
	manufacturers: [],
	subListCodes: [],
};

interface IProps extends DialogProps {
	cancel: VoidFunction;
	initialSearch?: string;
	isSubmitting: boolean;
	jxsTitle: JSX.Element;
	onClickSelect: (def: IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto) => void;
	onClickDetail?: (code: IRizivHearingAidDefinitionV2IdAndDistributorDto) => void;
}

export const SelectRizivRefundableHearingInstrumentForm = ({
	cancel,
	initialSearch = '',
	isSubmitting,
	jxsTitle,
	onClickSelect,
	onClickDetail,
	...rest
}: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(rizivHearingAidDefinitionsV2ManufacturerAndNameGroupQuery_query, {
		...DefaultParams,
		searchString: initialSearch,
	});

	const headers = [
		createSortableHeader<IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto>(strings.manufacturer, 'manufacturer', t => t.manufacturer),
		createSortableHeader<IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto>(strings.model, 'name', t => t.name),
		createHeader<IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto>(strings.codesSingularOrPlural, t => (
			<div>
				{t.matches.map(x => (
					<SingleItem
						key={x.identificationCode.toString()}
						item={x}
						onClick={onClickDetail}
					/>
				))}
			</div>
		)),
		createSelectHeader<IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto>(
			t => onClickSelect(t),
			t => isSubmitting
		),
	];

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='md'
			fullWidth>
			<CloseButtonOnAbsolutePosition onClick={cancel} />
			<DialogTitle>
				{jxsTitle}
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder={strings.searchOnWhat(`${strings.manufacturer}/${strings.model}`)}
					variant='paper'
					style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
				/>
				<Filter
					params={params}
					onFilter={params => setParams(onResetToPage1(params))}
					paddingLeft={1.5}
				/>
				<Divider />
			</DialogTitle>
			<DialogContent dividers>
				{isSubmitting && <LinearProgress style={{ margin: '-16px -24px 16px -24px' }} />}
				{queryResult.filteredCount > 0 && (
					<Paper variant='outlined'>
						<TableWithHeadersAndValues<IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto>
							padding={theme.spacing(1)}
							headers={headers}
							getKey={t => t.id}
							sortParams={params.sortParams}
							setSortParams={sp => setParams({ ...params, sortParams: sp, pageParams: { index: 1, size: params.pageParams.size } })}
							values={queryResult.values}
						/>
					</Paper>
				)}
				{queryResult.filteredCount === 0 && queryResult.unfilteredCount > 0 && <NoRecordsFound>{strings.nothingFoundCommaChangeSearchParams}</NoRecordsFound>}
			</DialogContent>
			<DialogActions>
				<TablePaging
					countResults={queryResult.filteredCount}
					pageSize={queryResult.pageSize}
					page={queryResult.zeroBasedPageIndex}
					pageParams={params.pageParams}
					setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
					removeBorder
				/>
			</DialogActions>
		</Dialog>
	);
};
