import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { IProductCatalogQueryParamsForTender, productCatalogQuery_queryForTender } from 'gen/ApiClient';
import React from 'react';
import { SearchContext } from './SearchContext';

const DefaultParams: IProductCatalogQueryParamsForTender = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	types: [],
	categories: [],
	subcategories: [],
};

interface IProps {
	children: any;
}

export const SearchProvider = ({ children }: IProps) => {
	const [params, setParams, queryResult] = useApiEffectWithParams(productCatalogQuery_queryForTender, DefaultParams);
	return (
		<SearchContext.Provider
			value={{
				params: params,
				queryResult: queryResult,
				setParams: setParams,
			}}>
			{children}
		</SearchContext.Provider>
	);
};
