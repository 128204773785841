import { useTheme } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { calcDeliveryDetailRoute } from 'app/main/inventoryManagement/routes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { formatDate } from 'framework/utils/date/formatDate';
import { IOrderDeliveryData } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IOrderDeliveryData;
	withoutBorder?: boolean;
}

export const OrderDeliveryListItem = ({ item, withoutBorder }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { push } = useHistory();

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8 }}>
				<RadioButtonCheckedIcon style={{ fontSize: 22, color: theme.palette.success.main }} />
			</div>
			<div className='df-col'>
				<div
					className='df-row-ac'
					style={{ paddingBottom: 4 }}>
					<TextLinkButton
						onClick={() => push(calcDeliveryDetailRoute(item.id))}
						style={{ fontSize: MajorFontSize }}>
						{item.reference}
					</TextLinkButton>
				</div>
				<span style={{ fontSize: MinorFontSize }}>{formatDate(item.date)}</span>
				{item.hasDispatchReference && <span style={{ fontSize: MinorFontSize }}>{`${strings.dispatchReference}: ${item.dispatchReference}`}</span>}
				{item.hasDispatchDate && <span style={{ fontSize: MinorFontSize }}>{`${strings.dispatchDate}: ${formatDate(item.dispatchDate)}`}</span>}
			</div>
		</div>
	);
};
