import { DialogContent, DialogProps } from '@material-ui/core';
import { FormSelectOrCreateCompanyFieldWithSelector } from 'app/main/contacts/companies/forms/FormSelectOrCreateCompanyFieldWithSelector';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setLineErrors } from 'framework/forms/utils/setLineErrors';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IInvoiceModel, salesCommand_newInvoice, salesQuery_nextInvoiceReference, salesQuery_settings, ValidUntilTimeUnits } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { FormRecipientField } from 'shared/forms/FormRecipientField';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useWithRecipientInterceptor } from 'shared/searchAndSelect/useWithRecipientInterceptor';
import * as yup from 'yup';
import { useCurrentLocation } from '../../../../settings/locations/useCurrentLocation';
import { SaleFormConfirmation } from '../SaleFormConfirmation';
import { SearchProvider } from './context/SearchProvider';
import { IInvoiceLine } from './IInvoiceLine';
import { SearchAndSelectProductsForSale } from './SearchAndSelectProductsForSale';
import { invoiceLineToSalesLineModel } from './utils/invoiceLineToSalesLineModel';

const createSchema = (strings: IStrings, mustSelectCompany: boolean) => {
	return yup
		.object<IInvoiceModel>({
			validUntil: yup.date().required(),
			lines: yup.mixed(),
			reference: yup.string().required(),
			isAutoSelectReference: yup.boolean().defined(),
			patientId: yup.string(),
			zip: yup.string(),
			addressLine: yup.string(),
			city: yup.string(),
			companyId: mustSelectCompany ? yup.string().required() : yup.string(),
			country: yup.string(),
			recipientName: yup.string().required(),
		})
		.defined();
};

const EmptyValues: IInvoiceModel = {
	lines: [],
	reference: '',
	isAutoSelectReference: true,
	patientId: '',
	city: '',
	companyId: '',
	addressLine: '',
	zip: '',
	country: '',
	recipientName: '',
	validUntil: undefined as any,
};

const StepsRecord: Record<number, (keyof IInvoiceModel)[]> = {
	0: ['reference', 'isAutoSelectReference', 'validUntil'],
	1: ['companyId'],
	2: ['recipientName', 'addressLine', 'city', 'zip', 'country'],
	3: ['lines'],
	4: [],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	patientId?: string;
	initialLines?: IInvoiceLine[];
}

export const InvoiceModelForm = ({ confirm, cancel, patientId, initialLines, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, setLines] = useState<IInvoiceLine[]>(initialLines ?? []);
	const [step, setStep] = useState<number>(0);
	const [isSelectCompany, setIsSelectCompany] = useState<boolean>(patientId === undefined);
	const schema = useMemo(() => createSchema(strings, patientId === undefined || isSelectCompany), [strings, patientId, isSelectCompany]);
	const [create, isSubmitting] = useFormSubmit(salesCommand_newInvoice);
	const [isShiftVat, setIsShiftVat] = useState<boolean>(false);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IInvoiceModel, helpers: FormikHelpers<IInvoiceModel>) => {
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await create({ ...values, lines: lines.map((t, index) => invoiceLineToSalesLineModel(t, index, isShiftVat)) });
			if (handleFormResponse(r, helpers, StepsRecord, setStep)) {
				notify(strings.registeredWhat(strings.sale));
				confirm(r.result.objectId);
			} else {
				setLineErrors('lines', r, lines, setLines);
			}
		}
	};

	return (
		<Formik<IInvoiceModel>
			validateOnMount
			initialValues={{
				...EmptyValues,
				patientId: patientId,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						lines={lines}
						setLines={setLines}
						step={step}
						setStep={setStep}
						stepsRecord={StepsRecord}
						schema={schema}
						isSelectCompany={isSelectCompany}
						setIsSelectCompany={setIsSelectCompany}
						patientId={patientId}
						isShiftVat={isShiftVat}
						setIsShiftVat={setIsShiftVat}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	lines: IInvoiceLine[];
	setLines: React.Dispatch<React.SetStateAction<IInvoiceLine[]>>;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof IInvoiceModel)[]>;
	schema: yup.ObjectSchema<IInvoiceModel>;
	isSelectCompany: boolean;
	setIsSelectCompany: React.Dispatch<React.SetStateAction<boolean>>;
	patientId: string | undefined;
	setIsShiftVat: React.Dispatch<React.SetStateAction<boolean>>;
	isShiftVat: boolean;
}

export const InnerDialog = ({
	cancel,
	lines,
	setLines,
	isSubmitting,
	step,
	setStep,
	stepsRecord,
	schema,
	isSelectCompany,
	setIsSelectCompany,
	patientId,
	isShiftVat,
	setIsShiftVat,
	...rest
}: IInnerProps) => {
	const props = useFormikContext<IInvoiceModel>();
	const strings = useLocalization();
	useNextReferenceInterceptor(salesQuery_nextInvoiceReference, props);
	const [settings] = useApiEffect(salesQuery_settings);
	const location = useCurrentLocation();
	const [isUseContactPerson, setIsUseContactPerson] = useState<boolean>(false);
	useWithRecipientInterceptor<IInvoiceModel>(props, isSelectCompany, isUseContactPerson, patientId);

	if (location === undefined || settings === undefined) {
		return <div></div>;
	}

	return (
		<FullScreenDialogWithStepper
			{...rest}
			fullScreenStep={3}
			title={strings.newWhat(strings.invoice)}
			step={step}
			labels={[strings.referenceShort, strings.vatCustomer, strings.withRespectToShort, strings.items, strings.confirmation]}
			maxWidth='lg'>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IInvoiceModel>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IInvoiceModel>
							name='reference'
							label={strings.invoiceNumber}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormDatePickerWithUnit<IInvoiceModel>
							name='validUntil'
							label={strings.validTo}
							required
							units={ValidUntilTimeUnits}
							defaultUnit='Months'
							defaultValue={settings.defaultValidUntil}
						/>
					</>
				)}
				{step === 1 && (
					<FormSelectOrCreateCompanyFieldWithSelector<IInvoiceModel>
						patientId={patientId}
						isSelectCompany={isSelectCompany}
						setIsSelectCompany={setIsSelectCompany}
						disableSelector={patientId === undefined}
					/>
				)}
				{step === 2 && (
					<FormRecipientField<IInvoiceModel>
						patientId={patientId}
						isUseContactPerson={isUseContactPerson}
						setIsUseContactPerson={setIsUseContactPerson}
					/>
				)}
				{step === 3 && (
					<SearchAndSelectProductsForSale
						lines={lines}
						setLines={setLines}
						location={location}
						customerAccountId={props.values.companyId ? props.values.companyId : props.values.patientId}
						patientId={props.values.patientId}
						isShiftVat={isShiftVat}
						setIsShiftVat={setIsShiftVat}
						canCredit={false}
					/>
				)}
				{step === 4 && (
					<SaleFormConfirmation
						lines={lines}
						reference={props.values.reference}
						recipientName={props.values.recipientName}
						isShiftVat={isShiftVat}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</FullScreenDialogWithStepper>
	);
};
