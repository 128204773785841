import { useCallback } from 'react';
import { Try } from '../Try';
import { useStateBoolean } from './useStateBool';

export const useWrapApiCallWithLoad = <TArgs extends any[], TResult>(
	callBack: (...args: TArgs) => Promise<Try<TResult>>
): [(...args: TArgs) => Promise<Try<TResult>>, boolean] => {
	const [isLoading, setLoading, clearLoading] = useStateBoolean(false);

	const x = useCallback(
		async (...args: TArgs) => {
			setLoading();
			const r = await callBack(...args);
			clearLoading();
			return r;
		},
		// eslint-disable-next-line
		[callBack]
	);

	return [x, isLoading];
};
