import { IQuickFilterContext } from 'framework/IQuickFilterContext';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { IPatientSummary, PatientExportProp } from 'gen/ApiClient';
import React from 'react';
import { PatientQuickFilter } from './PatientQuickFilter';
import { PatientsSelectableColumn } from './PatientsSelectableColumn';

interface IViewContext extends ITableViewContext<PatientsSelectableColumn, IPatientSummary>, IQuickFilterContext<PatientQuickFilter> {
	exportRecord: Record<PatientExportProp, string>;
}

export const PatientsListViewContext = React.createContext<IViewContext>(undefined as any);
