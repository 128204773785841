import { useFormikContext } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SelectField } from 'framework/components/select/SelectField';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { ITemplate, LanguageOption } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';
import { toLanguageOptions } from './utils/toLanguageOptions';

interface IProps<TModel> extends IFormProps<TModel> {
	templates: ITemplate[];
}

export const FormSelectTemplateField = <TModel extends unknown>({ name, templates, ...rest }: IProps<TModel>) => {
	const strings = useLocalization();
	const languageOptions = useMemo(() => toLanguageOptions(templates), [templates]);
	const [language, setLanguage] = useState<LanguageOption>('nl');
	const filteredTemplates = useMemo(() => templates?.filter(t => t.language === language), [templates, language]);
	const props = useFormikContext<TModel>();
	const { localizedLanguageRecord } = useContext(RecordContext);

	useEffect(() => {
		if (filteredTemplates.length === 1) {
			setFormValue<TModel>(name, filteredTemplates[0].id, props);
		}
		// eslint-disable-next-line
	}, [filteredTemplates]);

	return (
		<div className='df-col'>
			<SelectField<LanguageOption>
				label={strings.language}
				options={languageOptions}
				value={language}
				onChange={setLanguage}
				renderValue={t => localizedLanguageRecord[t]}
				getKey={t => t}
				style={{ marginBottom: 8 }}
			/>
			<FormAutocompleteField<TModel, ITemplate>
				{...rest}
				name={name}
				options={filteredTemplates}
			/>
		</div>
	);
};
