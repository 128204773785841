import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IStrings } from 'localization/IStrings';
import { ISalesSummaryWithSelection } from '../../../../../../financial/sales/ISalesSummaryWithSelection';

export const createHeaders = (
	lines: ISalesSummaryWithSelection[],
	setLines: React.Dispatch<React.SetStateAction<ISalesSummaryWithSelection[]>>,
	strings: IStrings
): IHeader<ISalesSummaryWithSelection>[] => {
	return [
		createSelectionHeader<ISalesSummaryWithSelection>(lines, setLines),
		createSortableHeader<ISalesSummaryWithSelection>(strings.reference, 'reference', t => t.reference),
		createSortableHeader<ISalesSummaryWithSelection>(`${strings.price}\n(${strings.inVatShort})`, 'totalValue', t => formatCurrency(t.totalValue)),
	];
};
