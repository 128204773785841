import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectBooleanField } from 'framework/forms/FormSelectBooleanField';
import { FormSelectFieldFromRecord } from 'framework/forms/FormSelectFieldFromRecord';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IOption } from 'framework/IOption';
import { ISetRosaViewPreferencesRequest, RosaCalendarViewType, rosaViewPreferencesCommand_set } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import * as yup from 'yup';
import { createTimeScaleRecord } from './createTimeScaleRecord';
import { HoursOptions } from './HoursOptions';
import { RosaViewPreferencesPreloadCacheContext } from './RosaViewPreferencesPreloadCacheContext';
import { TimeScales } from './TimeScale';

const createSchema = (strings: IStrings) => {
	return yup
		.object<ISetRosaViewPreferencesRequest>({
			defaultView: yup.string().required(strings.formRequired(strings.defaultView)),
			showOnlyWeekDays: yup.boolean().required(strings.formRequired(strings.showOnlyWeekdays)),
			showScheduleFromHour: yup.number().required(strings.formRequired(strings.from)),
			showScheduleToHour: yup.number().required(strings.formRequired(strings.to)),
			timeScale: yup.number().required(strings.formRequired(strings.timeScale)),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const RosaViewPreferencesForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const { rosaViewPreferences, reload } = useContext(RosaViewPreferencesPreloadCacheContext);
	const [update, isSubmitting] = useFormSubmit(rosaViewPreferencesCommand_set);
	const timeScaleRecord = useMemo(() => createTimeScaleRecord(strings), [strings]);
	const timeScaleOptions = useMemo(() => TimeScales.map<IOption<number>>(t => ({ id: t, identifier: timeScaleRecord[t] })), [timeScaleRecord]);
	const { rosaCalendarViewTypeRecord } = useContext(RecordContext);

	const handleSubmit = async (values: ISetRosaViewPreferencesRequest, helpers: FormikHelpers<ISetRosaViewPreferencesRequest>) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers)) {
			reload();
			confirm();
		}
	};

	return (
		<Formik<ISetRosaViewPreferencesRequest>
			validateOnMount
			initialValues={rosaViewPreferences}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{strings.changeSomething(strings.viewPreferences)}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormSelectFieldWithOption<ISetRosaViewPreferencesRequest, number>
							name='showScheduleFromHour'
							options={HoursOptions}
							label={strings.showHoursFrom}
							required
						/>
						<FormSelectFieldWithOption<ISetRosaViewPreferencesRequest, number>
							name='showScheduleToHour'
							options={HoursOptions}
							label={strings.showHoursTo}
							required
						/>
						<FormSelectFieldWithOption<ISetRosaViewPreferencesRequest, number>
							name='timeScale'
							options={timeScaleOptions}
							label={strings.inStepsOf}
							required
						/>
						<FormSelectBooleanField<ISetRosaViewPreferencesRequest>
							label={strings.receiver}
							name='showOnlyWeekDays'
							trueText={strings.showOnlyWeekdays}
							falseText={strings.showAllDaysInclWeekend}
						/>
						<FormSelectFieldFromRecord<ISetRosaViewPreferencesRequest, RosaCalendarViewType>
							name='defaultView'
							label={strings.defaultView}
							record={rosaCalendarViewTypeRecord}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<ISetRosaViewPreferencesRequest>
						cancel={cancel}
						submitText={strings.update}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
