import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { setFieldError } from 'framework/forms/utils/setFieldError';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormSelectUserField } from './FormSelectUserField';

interface IModel {
	id: string;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		id: yup.string().required(strings.formRequired(strings.audiologist)),
	});
};

interface IProps extends DialogProps {
	initialId?: string;
	confirm: (id: string, setError: (error: string) => void) => void;
	cancel: VoidFunction;
	isSubmitting: boolean;
	userIds?: string[];
	submitText?: string;
}

export const SelectUserForm = ({ initialId, confirm, cancel, isSubmitting, userIds, submitText, ...rest }: IProps) => {
	const strings = useLocalization();

	const handleSubmit = (values: IModel, helpers: FormikHelpers<IModel>) => {
		confirm(values.id, (error: string) => setFieldError<IModel>('id', error, helpers));
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{
				id: initialId ?? '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{rest.title ?? strings.selectWhat(strings.user)}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormSelectUserField<IModel>
							name='id'
							label={strings.user}
							userIds={userIds}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={submitText ?? strings.select}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
