import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	Divider,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	useTheme,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { FilterOffIcon } from 'framework/components/icons/FilterOffIcon';
import { Searchbar } from 'framework/components/search/Searchbar';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { RadioFilterComponent } from 'framework/filter/RadioFilterComponent';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IOption } from 'framework/IOption';
import { isLast } from 'framework/utils/array/isLast';
import { toggleSelect } from 'framework/utils/array/toggleSelect';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import {
	IMedicalTreatmentNomenclatureForAudiology,
	IRequestForInsuranceAllowance,
	patientsQuery_filterForMedicalTreatmentNomenclatures,
	rfiasCommand_selectSecondaryMedicalTreatments,
} from 'gen/ApiClient';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';
import { MedicalTreatmentNomenclaturesCacheContext } from 'shared/context/MedicalTreatmentNomenclaturesCacheContext';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { MedicalTreatmentNomenclatureDialog } from 'shared/medicalTreatmentNomenclatures/MedicalTreatmentNomenclatureDialog';
import { AgeRestriction, AgeRestrictions } from './AgeRestriction';
import { ageToRestriction } from './utils/ageToRestriction';

interface IFilterData {
	isAmbulant: boolean | undefined;
	ageRestriction: AgeRestriction | undefined;
	isBoneAnchorage: boolean | undefined;
	searchString: string;
}

const defaultFilterData: IFilterData = {
	isAmbulant: true,
	ageRestriction: undefined,
	isBoneAnchorage: undefined,
	searchString: '',
};

interface IProps extends DialogProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const SecondaryMedicalTreatmentsWizardForm = ({ rfia, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const { open, cancel: closeDetail, isStacked } = useDialogsContext();
	const theme = useTheme();
	const notify = useSnackbarNotify();
	const [submit, isSubmitting] = useFormSubmit(rfiasCommand_selectSecondaryMedicalTreatments);
	const [data] = useCacheContext(MedicalTreatmentNomenclaturesCacheContext);
	const [params, setParams] = useState<IFilterData>(defaultFilterData);
	const [filtered, setFiltered] = useState<IMedicalTreatmentNomenclatureForAudiology[]>([]);
	const [preFilter] = useApiEffect(patientsQuery_filterForMedicalTreatmentNomenclatures, rfia.patientId);
	const [selected, setSelected] = useState<string[]>(rfia.secondaryMedicalTreatmentNomenclatureCodes);
	const isFiltered = useMemo(() => params.ageRestriction !== undefined || params.isBoneAnchorage !== undefined, [params]);
	const language = useCurrentLanguage();

	useEffect(() => {
		if (preFilter) {
			setParams({
				isAmbulant: preFilter.isAmbulant ?? true,
				ageRestriction: ageToRestriction(preFilter.age),
				isBoneAnchorage: undefined,
				searchString: '',
			});
		}
	}, [preFilter]);

	useEffect(() => {
		if (data !== undefined) {
			const x = data
				// TODO delete isNullOrUndefined after 1/10/21
				.filter(t => t.isSecondary && t.data.isCurrentlyValid === true && t.isLumpSum === false && t.isPseudoCode === false && isNullOrUndefined(t.validityEnd))
				.filter(t => params.isAmbulant === undefined || t.data.isAmbulant === params.isAmbulant)
				.filter(
					t =>
						params.ageRestriction === undefined ||
						t.isAgeRestricted === false ||
						(params.ageRestriction === '<18' && t.isAgeRestrictedToLessThan18Years) ||
						(params.ageRestriction === '>18' && t.isAgeRestrictedToOlderThan18Years) ||
						(params.ageRestriction === '>18&<65' && t.isAgeRestrictedBetween18And65Years) ||
						(params.ageRestriction === '>65' && t.isAgeRestrictedToOlderThan65Years)
				)
				.filter(t => params.isBoneAnchorage === undefined || t.isBoneAnchorage === params.isBoneAnchorage)
				.filter(t => `${t.data.code} ${t.data.descriptionNl} ${t.data.descriptionFr}`.toLowerCase().includes(params.searchString.toLowerCase()));
			setFiltered(x);
		}
	}, [params, data]);

	const handleSubmit = async () => {
		const r = await submit(rfia.id, selected);
		// TODO handle response ico validation error
		if (r.isSuccess) {
			notify(strings.selectedWhat(strings.additionalNomenclatures));
			confirm();
		}
	};

	if (data === undefined || preFilter === undefined) {
		return <div></div>;
	}

	const onClearFilters = () => {
		setParams({ ...params, isBoneAnchorage: undefined, ageRestriction: undefined });
	};

	const onViewDetail = (code: string) => {
		open(
			<MedicalTreatmentNomenclatureDialog
				code={code}
				open
				cancel={closeDetail}
				select={() => {
					closeDetail();
					if (selected.indexOf(code) === -1) {
						setSelected(toggleSelect(code, selected));
					}
				}}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			scroll='paper'
			fullWidth
			open={rest.open && isStacked === false}>
			<DialogTitle>{strings.selectWhat(strings.additionalNomenclatures)}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<DividerWithCaption
					caption={strings.filter}
					action={
						<SmallPrimaryTextButton
							onClick={onClearFilters}
							disabled={isFiltered === false}
							startIcon={<FilterOffIcon />}
							color='secondary'>
							{strings.clearFilter}
						</SmallPrimaryTextButton>
					}
				/>
				<div className='df-col-as'>
					<BooleanRadioFilterComponent
						style={{ marginBottom: theme.spacing(0.5) }}
						badgeVariant='dot'
						trueText={strings.ambulatory}
						falseText={strings.hospitalized}
						selected={params.isAmbulant}
						setSelected={val => setParams({ ...params, isAmbulant: val })}
						label={params.isAmbulant ? strings.ambulatory : strings.hospitalized}
						icon={<HelpOutlineIcon />}
					/>
					<RadioFilterComponent<AgeRestriction>
						style={{ marginBottom: theme.spacing(0.5) }}
						badgeVariant='dot'
						options={AgeRestrictions.map<IOption<AgeRestriction>>(t => ({ id: t, identifier: t }))}
						selected={params.ageRestriction}
						setSelected={val => setParams({ ...params, ageRestriction: val })}
						label={params.ageRestriction ? params.ageRestriction : strings.age}
						icon={<HelpOutlineIcon />}
					/>
					<BooleanRadioFilterComponent
						style={{ marginBottom: theme.spacing(0.5) }}
						badgeVariant='dot'
						trueText={strings.withWhat(strings.boneAnchorage)}
						falseText={strings.withoutWhat(strings.boneAnchorage)}
						selected={params.isBoneAnchorage}
						setSelected={val => setParams({ ...params, isBoneAnchorage: val })}
						label={
							params.isBoneAnchorage === undefined
								? strings.withOrWithoutWhat(strings.boneAnchorage)
								: params.isBoneAnchorage === true
								? strings.withWhat(strings.boneAnchorage)
								: strings.withoutWhat(strings.boneAnchorage)
						}
						icon={<HelpOutlineIcon />}
					/>
				</div>
				<DividerWithCaption caption={strings.search} />
				<div className='df-row-ac jc-sb'>
					<Searchbar
						value={params.searchString}
						onSearch={text => setParams({ ...params, searchString: text })}
						placeholder={strings.searchVerb}
						style={{ width: '100%' }}
						variant='paper'
					/>
				</div>
				<DividerWithCaption caption={`${strings.filteredResults} (${filtered.length.toString()})`} />
				<List
					dense
					style={{ overflow: 'auto', marginLeft: '-24px', marginRight: '-24px' }}>
					{filtered.map(t => (
						<React.Fragment key={t.data.code}>
							<ListItem
								button
								onClick={() => onViewDetail(t.data.code)}
								style={{ paddingLeft: '29px' }}
								disabled={isSubmitting}>
								<ListItemText
									primary={t.data.code}
									secondary={language === 'fr' ? t.data.descriptionFr : t.data.descriptionNl}
								/>
								<ListItemSecondaryAction>
									<Checkbox
										edge='start'
										checked={selected.indexOf(t.data.code) !== -1}
										tabIndex={-1}
										disableRipple
										onClick={e => setSelected(toggleSelect(t.data.code, selected))}
									/>
								</ListItemSecondaryAction>
							</ListItem>
							{isLast(t, filtered) === false && <Divider />}
						</React.Fragment>
					))}
				</List>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<LoaderButton
					type='button'
					color='primary'
					disabled={isSubmitting || selected.length === 0}
					isLoading={isSubmitting}
					onClick={handleSubmit}>
					{selected.length === 0 ? strings.select : `${strings.select} ${selected.length}`}
				</LoaderButton>
				{/* {rfia.secondaryMedicalTreatmentNomenclatureCodes?.length > 0 && <LoaderButton type="button" color="primary" disabled={isSubmitting || isClearing} isLoading={isClearing} onClick={handleClear}>Verwijder codes</LoaderButton>} */}
			</DialogActions>
		</Dialog>
	);
};
