import React from 'react';

interface IProps {
	children: React.ReactNode;
	style?: React.CSSProperties;
}

export const TabsX = ({ children, style }: IProps) => {
	return (
		<div
			className='df-row-ac'
			style={{ gap: 8, ...style }}>
			{children}
		</div>
	);
};
