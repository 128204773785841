import { DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteMultipleFreeSolo } from 'framework/forms/FormAutocompleteMultipleFreeSolo';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormMaskedTextField } from 'framework/forms/FormMaskedTextField';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFieldError } from 'framework/forms/utils/setFieldError';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { isValidBelgianSocialSecurityNumber } from 'framework/utils/isValidBelgianSocialSecurityNumber';
import {
	IPatient,
	IPatchPatientMissingPropertiesRequest,
	PatientRequiredProperty,
	patientsCommand_patchMissingProperties,
	PhoneNumberRegion,
	PhoneNumberRegions,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { AddressInput } from 'shared/address/AddressInput';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { FormSelectHealthInsuranceFundField } from 'shared/forms/FormSelectHealthInsuranceFundField';
import * as yup from 'yup';
import { FormSelectOrCreateEntPhysicianField } from '../../contacts/earNoseThroatPhysicians/forms/FormSelectOrCreateEntPhysicianField';
import { FormSelectOrCreateGeneralPractitionerField } from '../../contacts/generalPractitioners/forms/FormSelectOrCreateGeneralPractitionerField';
import { FormSelectLocationField } from '../../settings/locations/forms/FormSelectLocationField';
import { FormSelectAudiologistField } from '../../settings/users/forms/FormSelectAudiologistField';
import { PatientsFilterDataContext } from '../PatientsFilterDataContext';
import { SocialSecurityNumberMask } from './SocialSecurityNumberMask';
import { FormSelectField } from 'framework/forms/FormSelectField';

const createSchema = (strings: IStrings, requiredProperties: PatientRequiredProperty[]) => {
	return yup.object<IPatchPatientMissingPropertiesRequest>({
		addressLine: requiredProperties.indexOf('Address') > -1 ? yup.string().required() : (yup.string().nullable() as any),
		zip: requiredProperties.indexOf('Address') > -1 ? yup.string().required() : (yup.string().nullable() as any),
		city: requiredProperties.indexOf('Address') > -1 ? yup.string().required() : (yup.string().nullable() as any),
		country: yup.string().nullable() as any,
		dateOfBirth: requiredProperties.indexOf('DateOfBirth') > -1 ? yup.date().required() : (yup.date().nullable() as any),
		email: requiredProperties.indexOf('Email') > -1 ? yup.string().required() : (yup.string().nullable() as any),
		phone: requiredProperties.indexOf('PhoneNumber') > -1 ? yup.string().required() : (yup.string().nullable() as any),
		socialSecurityNumber:
			requiredProperties.indexOf('SocialSecurityNumber') > -1
				? yup
						.string()
						.required()
						.test('isValid', strings.invalidSocialSecurityNumber, t => isValidBelgianSocialSecurityNumber(t ?? ''))
				: (yup.string().nullable() as any),
		onLocationId: requiredProperties.indexOf('OnLocation') > -1 ? yup.string().required() : (yup.string().nullable() as any),
		attendingEntPhysicianId: requiredProperties.indexOf('AttendingEntPhysician') > -1 ? yup.string().required() : (yup.string().nullable() as any),
		attendingAudiologistId: requiredProperties.indexOf('AttendingAudiologist') > -1 ? yup.string().required() : (yup.string().nullable() as any),
		attendingGeneralPractitionerId:
			requiredProperties.indexOf('AttendingGeneralPractitioner') > -1 ? yup.string().required() : (yup.string().nullable() as any),
		origins: yup.mixed().nullable() as any,
		hifCode: requiredProperties.indexOf('HealthInsuranceFund') > -1 ? yup.string().required() : (yup.string().nullable() as any),
		fromMissingProperties: yup.mixed().nullable() as any,
		labels: yup.mixed().nullable() as any,
		patientId: yup.string().required(),
		phoneNumberRegion: yup.mixed<PhoneNumberRegion>().required(),
		reference: requiredProperties.indexOf('Reference') > -1 ? yup.string().required() : (yup.string().nullable() as any),
	});
};

const patientToModel = (patient: IPatient, fromMissingProperties: string[]): IPatchPatientMissingPropertiesRequest => {
	return {
		patientId: patient.id,
		addressLine: patient.addressLine,
		attendingAudiologistId: patient.attendingAudiologistId,
		attendingEntPhysicianId: patient.attendingEntPhysicianId,
		attendingGeneralPractitionerId: patient.attendingGeneralPractitionerId,
		city: patient.city,
		country: patient.country,
		dateOfBirth: patient.dateOfBirth,
		email: patient.email,
		phone: patient.phone,
		fromMissingProperties: fromMissingProperties,
		hifCode: patient.healthInsuranceFundCode,
		onLocationId: patient.locationId,
		origins: patient.origins,
		socialSecurityNumber: patient.formattedSocialSecurityNumber,
		zip: patient.zip,
		labels: patient.labels,
		phoneNumberRegion: 'BE',
		reference: patient.reference,
	};
};

interface IProps extends DialogProps {
	item: IPatient;
	confirm: VoidFunction;
	cancel: VoidFunction;
	missingProperties: string[];
}

export const PatientMissingPropertiesForm = ({ item, confirm, cancel, missingProperties, ...rest }: IProps) => {
	const strings = useLocalization();
	const [update, isSubmitting] = useFormSubmit(patientsCommand_patchMissingProperties);
	const xMissingProperties = useMemo(() => missingProperties.map(t => t as PatientRequiredProperty), [missingProperties]);
	const [data] = useCacheContext(PatientsFilterDataContext);
	const { isStacked } = useDialogsContext();

	if (data === undefined) {
		return <div></div>;
	}

	const handleSubmit = async (values: IPatchPatientMissingPropertiesRequest, helpers: FormikHelpers<IPatchPatientMissingPropertiesRequest>) => {
		// validation
		if (xMissingProperties.indexOf('Origins') > -1 && (values.origins === undefined || values.origins.length === 0)) {
			setFieldError<IPatchPatientMissingPropertiesRequest>('origins', strings.formRequired(strings.origin), helpers);
		} else {
			const r = await update(values);
			if (handleFormResponse(r, helpers)) {
				confirm();
			}
		}
	};

	return (
		<Formik<IPatchPatientMissingPropertiesRequest>
			validateOnMount
			initialValues={patientToModel(item, missingProperties)}
			validationSchema={createSchema(strings, xMissingProperties)}
			onSubmit={handleSubmit}>
			<Form>
				<OverflowDialog
					fullWidth
					{...rest}
					open={rest.open && isStacked === false}>
					<DialogTitle>{strings.fillInMissingData}</DialogTitle>
					<OverflowDialogContent
						className='df-col'
						dividers>
						{xMissingProperties.indexOf('DateOfBirth') > -1 && (
							<FormDatePicker<IPatchPatientMissingPropertiesRequest>
								name='dateOfBirth'
								label={strings.dateOfBirth}
								required
							/>
						)}
						{xMissingProperties.indexOf('SocialSecurityNumber') > -1 && (
							<FormMaskedTextField<IPatchPatientMissingPropertiesRequest>
								name='socialSecurityNumber'
								label={strings.socialSecurityNumber}
								mask={SocialSecurityNumberMask}
								required
							/>
						)}
						{xMissingProperties.indexOf('Email') > -1 && (
							<FormTextField<IPatchPatientMissingPropertiesRequest>
								name='email'
								label={strings.email}
								required
							/>
						)}
						{xMissingProperties.indexOf('PhoneNumber') > -1 && (
							<div className='df-row gap-4'>
								<FormSelectField<IPatchPatientMissingPropertiesRequest, PhoneNumberRegion>
									name='phoneNumberRegion'
									label={strings.country}
									options={PhoneNumberRegions}
									renderValue={t => t}
									getKey={t => t}
								/>
								<FormTextField<IPatchPatientMissingPropertiesRequest>
									className='fg1'
									name='phone'
									label={strings.phone}
									required
								/>
							</div>
						)}
						{xMissingProperties.indexOf('AttendingAudiologist') > -1 && (
							<FormSelectAudiologistField<IPatchPatientMissingPropertiesRequest>
								name='attendingAudiologistId'
								label={strings.audiologist}
								required
							/>
						)}
						{xMissingProperties.indexOf('AttendingEntPhysician') > -1 && (
							<FormSelectOrCreateEntPhysicianField<IPatchPatientMissingPropertiesRequest>
								name='attendingEntPhysicianId'
								label={strings.entPhysician}
								required
							/>
						)}
						{xMissingProperties.indexOf('AttendingGeneralPractitioner') > -1 && (
							<FormSelectOrCreateGeneralPractitionerField<IPatchPatientMissingPropertiesRequest>
								name='attendingGeneralPractitionerId'
								label={strings.generalPractitioner}
								required
							/>
						)}
						{xMissingProperties.indexOf('HealthInsuranceFund') > -1 && (
							<FormSelectHealthInsuranceFundField<IPatchPatientMissingPropertiesRequest>
								name='hifCode'
								label={strings.healthInsuranceFund}
								required
							/>
						)}
						{xMissingProperties.indexOf('OnLocation') > -1 && (
							<FormSelectLocationField<IPatchPatientMissingPropertiesRequest>
								name='onLocationId'
								label={strings.location}
							/>
						)}
						{xMissingProperties.indexOf('Origins') > -1 && (
							<FormAutocompleteMultipleFreeSolo<IPatchPatientMissingPropertiesRequest>
								name='origins'
								label={strings.origin}
								options={data.origins}
							/>
						)}
						{xMissingProperties.indexOf('Labels') > -1 && (
							<FormAutocompleteMultipleFreeSolo<IPatchPatientMissingPropertiesRequest>
								name='labels'
								label={strings.labels}
								options={data.labels}
							/>
						)}
						{xMissingProperties.indexOf('Address') > -1 && (
							<>
								<DividerWithCaption
									caption={strings.address}
									style={{ marginBottom: 64 }}
								/>
								<AddressInput embed />
							</>
						)}
						{xMissingProperties.indexOf('Reference') > -1 && (
							<FormTextField<IPatchPatientMissingPropertiesRequest>
								name='reference'
								label={strings.number}
								required
							/>
						)}
					</OverflowDialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</OverflowDialog>
			</Form>
		</Formik>
	);
};
