import React from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { IPayment } from 'gen/ApiClient';

interface IProps {
	item: IPayment;
	style?: React.CSSProperties;
}

export const PaymentIncomeOrExpenseStatusChip = ({ item, style }: IProps) => {
	return (
		<RectangularChip
			style={style}
			label={item.isExpense ? 'Uit' : item.isIncome ? 'In' : 'n/a'}
			fill={false}
			color={item.isExpense ? 'error' : item.isIncome ? 'success' : 'info'}
		/>
	);
};
