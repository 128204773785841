import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { IOption } from 'framework/IOption';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ProductCatalogItemType, ProductCatalogItemTypes, TangibleTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { ProductCatalogFilterDataContext } from '../context/ProductCatalogFilterDataContext';
import { FilterProductCatalogType } from './FilterProductCatalogType';
import { ICommonParams } from './ICommonParams';

interface IProps<TParams extends ICommonParams> {
	params: TParams;
	setParams: React.Dispatch<React.SetStateAction<TParams>>;
	filterType: FilterProductCatalogType;
}

export const Filter = <TParams extends ICommonParams>({ params, setParams, filterType }: IProps<TParams>) => {
	const strings = useLocalization();
	const [searchData] = useCacheContext(ProductCatalogFilterDataContext);
	const { productCatalogItemTypeRecord } = useContext(RecordContext);

	if (searchData === undefined) {
		return <div></div>;
	}

	const onFilter = (params: TParams) => {
		setParams(onResetToPage1(params));
	};

	return (
		<FilterBar
			disableWrap
			isFiltered={isFiltered(params)}
			onClear={() => onFilter(clearParams(params))}>
			{filterType === 'all' && (
				<RecordListFilterComponent<ProductCatalogItemType>
					options={ProductCatalogItemTypes}
					record={productCatalogItemTypeRecord}
					label={strings.type}
					selected={params.types as any}
					setSelected={vals => onFilter({ ...params, types: vals })}
					icon={<HelpOutlineIcon />}
				/>
			)}
			{filterType === 'stock' && (
				<ListFilterComponent<string>
					options={TangibleTypes.map<IOption<string>>(t => ({ id: t, identifier: productCatalogItemTypeRecord[t] }))}
					label={strings.type}
					selected={params.types}
					setSelected={vals => onFilter({ ...params, types: vals })}
					icon={<HelpOutlineIcon />}
				/>
			)}
			{searchData.categories.length > 0 && (
				<StringListFilterComponent
					label={strings.category}
					selected={params.categories}
					options={searchData.categories}
					icon={<HelpOutlineIcon />}
					setSelected={value => onFilter({ ...params, categories: value })}
				/>
			)}
			{searchData.subcategories.length > 0 && (
				<StringListFilterComponent
					label={strings.subcategory}
					selected={params.subcategories}
					options={searchData.subcategories}
					icon={<HelpOutlineIcon />}
					setSelected={value => onFilter({ ...params, subcategories: value })}
				/>
			)}
		</FilterBar>
	);
};
