import { RegisterSupplierForm } from 'app/main/contacts/suppliers/forms/RegisterSupplierForm';
import { SelectExistingOrderOrNewDialog } from 'app/main/processes/orders/forms/register/selectOrderToPlace/SelectExistingOrderOrNewDialog';
import { SelectSupplierForOrderDialog } from 'app/main/processes/orders/forms/register/selectSupplier/SelectSupplierForOrderDialog';
import { IArticlesInquiryDto, IOrderV2, IRegisterSupplierRequest, ISupplierSummaryWithOrderStats } from 'gen/ApiClient';
import React, { useState } from 'react';
import { AddArticlesInquiryToExistingOrderForm } from './AddArticlesInquiryToExistingOrderForm';
import { OrderArticlesInquiryForm } from './OrderArticlesInquiryForm';

const mapRegisterSupplierRequestToSupplierSummaryWithOrderStats = (id: string, request: IRegisterSupplierRequest): ISupplierSummaryWithOrderStats => {
	return {
		id: id,
		name: request.name,
		accountNumber: request.accountNumber,
		brands: request.brands,
		countBrands: request.brands?.length ?? 0,
		countOrders: 0,
		countOrdersToPlaceWithSupplier: 0,
		hasOrders: false,
		hasOrdersToPlaceWithSupplier: false,
		lastOrderDate: undefined,
		defaultDeliveryTime: null as any,
		hasDefaultDeliveryTime: false,
		identifier: request.name ?? '',
	};
};

type Mode = 'select-supplier' | 'new-supplier' | 'select-existing-order' | 'add-lines-to-existing-order' | 'new-order';

interface IProps {
	articlesInquiry: IArticlesInquiryDto;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const OrderArticlesInquiry = ({ confirm, cancel, articlesInquiry }: IProps) => {
	const [state, setState] = useState<Mode>('select-supplier');
	const [selectedSupplier, setSelectedSupplier] = useState<ISupplierSummaryWithOrderStats>();
	const [selectedOrder, setSelectedOrder] = useState<IOrderV2>();

	const onSetSupplier = (supplier: ISupplierSummaryWithOrderStats) => {
		setSelectedSupplier(supplier);
		setState(supplier.hasOrdersToPlaceWithSupplier ? 'select-existing-order' : 'new-order');
	};

	const onNewSupplier = (id: string, request: IRegisterSupplierRequest) => {
		setSelectedSupplier(mapRegisterSupplierRequestToSupplierSummaryWithOrderStats(id, request));
		setState('new-order');
	};

	const onSelectExistingOrder = (order: IOrderV2) => {
		setSelectedOrder(order);
		setState('add-lines-to-existing-order');
	};

	if (state === 'select-supplier') {
		return (
			<SelectSupplierForOrderDialog
				open
				confirm={onSetSupplier}
				cancel={cancel}
				onNew={() => setState('new-supplier')}
			/>
		);
	} else if (state === 'new-supplier') {
		return (
			<RegisterSupplierForm
				open
				confirm={onNewSupplier}
				previous={() => setState('select-supplier')}
				cancel={cancel}
			/>
		);
	} else if (state === 'select-existing-order' && selectedSupplier !== undefined) {
		return (
			<SelectExistingOrderOrNewDialog
				open
				confirm={onSelectExistingOrder}
				cancel={cancel}
				previous={() => setState('select-supplier')}
				supplier={selectedSupplier}
				onNew={() => setState('new-order')}
			/>
		);
	} else if (state === 'add-lines-to-existing-order' && selectedOrder !== undefined) {
		return (
			<AddArticlesInquiryToExistingOrderForm
				open
				confirm={() => confirm(selectedOrder.id)}
				cancel={cancel}
				order={selectedOrder}
				articlesInquiry={articlesInquiry}
			/>
		);
	} else if (state === 'new-order' && selectedSupplier !== undefined) {
		return (
			<OrderArticlesInquiryForm
				open
				confirm={confirm}
				cancel={cancel}
				supplier={selectedSupplier}
				previous={() => setState('select-supplier')}
				articlesInquiry={articlesInquiry}
			/>
		);
	} else {
		return <></>;
	}
};
