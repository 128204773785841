import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		created: strings.createdAt,
		isPaidOut: `${strings.paidOut}?`,
		isFullyCredited: `${strings.fullyCredited}?`,
		isFullyPaid: `${strings.fullyPaid}?`,
		isPaidByHif: `${strings.payedByHifShort}?`,
		isPaidByPatientOrCompany: `${strings.payedByPatient}?`,
		ruleMethod: strings.method,
		ruleName: strings.rule,
		rulePercentage: `%`,
		salesRecipientName: strings.withRespectToShort,
		salesReference: `${strings.reference} (${strings.sale})`,
		totalValueExVatOfDevices: strings.totalPriceDevicesExVat,
		totalValueExVatOfSale: strings.totalExVat,
		userIdentifier: strings.user,
		value: strings.amountAsValue,
	};
};
