import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { IPatientDetail, tendersQuery_byPatientX } from 'gen/ApiClient';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { TendersByPatientCard } from './TendersByPatientCard';

interface IProps extends IReloadTriggerProps {
	item: IPatientDetail;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const TendersByPatientTab = ({ item, reloadTrigger, reload, style }: IProps) => {
	const [tenders, reloadTenders] = useApiEffect(tendersQuery_byPatientX, item.patient.id);

	useLazyEffect(() => {
		reloadTenders();
	}, [reloadTrigger]);

	if (tenders === undefined) {
		return <></>;
	}

	return (
		<div
			className='df-col gap-16'
			style={style}>
			<TendersByPatientCard
				items={tenders}
				reload={reload}
				patientId={item.patient.id}
			/>
		</div>
	);
};
