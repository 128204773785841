import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { rosaLinkQuery_get } from 'gen/ApiClient';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RosaUserToHamsUserLinkListCardX } from './RosaUserToHamsUserLinkListCardX';

interface IProps extends IReloadTriggerProps {}

export const RosaUsersTab = ({ reloadTrigger }: IProps) => {
	const [data, reload] = useApiEffect(rosaLinkQuery_get);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	if (data === undefined) {
		return <></>;
	}

	return (
		<div
			className='df-col fg1 h100'
			style={{
				maxWidth: 1280,
				padding: 16,
			}}>
			<RosaUserToHamsUserLinkListCardX
				data={data}
				reload={reload}
			/>
		</div>
	);
};
