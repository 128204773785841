import { StockTransactionTabType } from 'app/main/inventoryManagement/stockTransactions/StockTransactionTabType';
import { PatientDetailTabType } from 'app/main/patients/detailX/PatientDetailTabType';
import { LumpSumType } from 'app/main/processes/requestsForInsuranceAllowance/forms/medicalTreatmentNomenclatures/LumpSumType';
import { StereoMonoContralateralType } from 'app/main/processes/requestsForInsuranceAllowance/forms/medicalTreatmentNomenclatures/StereoMonoContralateralType';
import {
	ArticlesInquiryLineStatusType,
	ArticlesInquiryStatusType,
	BatteryType,
	CalendarEventRequiredProperty,
	CommissionRuleMethod,
	ContactInfoType,
	CreditedStatus,
	CurrentLocationType,
	CustomerAccountType,
	DocumentAction,
	DocumentPredefinedType,
	ExpenseOrIncomeType,
	FunctionalClaim,
	Gender,
	HearingAidType,
	IDocument,
	IDocumentSummary,
	InventoryItemExportProp,
	InventoryStatus,
	LanguageOption,
	LeftRightOrStereoType,
	LinkViewContextType,
	LoanProcessStatus,
	NoahDeviceType,
	NotificationType,
	OrderLineStatus,
	OrderProcessStatus,
	Orientation,
	PageClaim,
	PatientDetailOverviewLayoutElementType,
	PatientRequiredProperty,
	PatientSearchMode,
	PatientStatus,
	PaymentExportProp,
	PaymentStatus,
	PredefinedPaymentMethod,
	ProductCatalogItemType,
	PropertyOfType,
	PurchaseExportProp,
	PurchaseType,
	RepairProcessStatus,
	RepairWarning,
	RfiaHandoverSaleGenerationMode,
	RfiaInvoiceProcessStep,
	RfiaStatus,
	RfiaWarning,
	ProductCatalogItemRizivHearingAidDefinitionV2LinkStatus,
	RosaCalendarViewType,
	RosaEventStatus,
	SalesSearchMode,
	SalesType,
	SpeechImageType,
	TemplateMainContext,
	TemplateType,
	TimeUnit,
	TransactionType,
	UserRole,
	WorkflowType,
	PurchasesPrefilter,
	SalesPrefilter,
	TenderProcessStatus,
	TendersPrefilter,
	ExactOnlineSalePrefilter,
	ExactOnlineProductCatalogItemPrefilter,
	ExactOnlineCustomerAccountPrefilter,
	RepairProcessStep,
	RepairPrefilter,
	OrderPrefilter,
	LoansPrefilter,
	ArticlesInquiriesPrefilter,
	ApplicationLogoutMode,
} from 'gen/ApiClient';
import { ScanError, ScanSource } from 'gen/imageScanner/ImageScannerClient';
import React from 'react';
import { Frequency } from 'rrule';
import { DocumentationType } from 'shared/utils/DocumentationType';
import { IDocumentX } from '../dialogs/scanOrUpload/IDocumentX';
import { ITimeUnitTranslation } from '../timeUnit/ITimeUnitTranslation';
import { SortPropertyDescriptor } from 'framework/components/lists/SortPropertyDescriptor';
import { NoahDevicesTabType } from 'app/main/inventoryManagement/noahDevices/NoahDevicesTabType';

interface IRecordContext {
	timeUnitRecord: Record<TimeUnit, ITimeUnitTranslation>;
	genderRecord: Record<Gender, string>;
	paymentStatusRecord: Record<PaymentStatus, string>;
	saleTypeRecord: Record<SalesType, string>;
	customerAccountTypeRecord: Record<CustomerAccountType, string>;
	predefinedPaymentMethodRecord: Record<PredefinedPaymentMethod, string>;
	translatePaymentMethod: (method: string) => string;
	translateDocumentType: (document: IDocumentSummary | IDocument | IDocumentX) => string;
	paymentExportRecord: Record<PaymentExportProp, string>;
	productCatalogItemTypeRecord: Record<ProductCatalogItemType, string>;
	noahDeviceTypeRecord: Record<NoahDeviceType, string>;
	templateTypeRecord: Record<TemplateType, string>;
	documentPredefinedTypeRecord: Record<DocumentPredefinedType, string>;
	documentActionRecord: Record<DocumentAction, string>;
	workflowTypeRecord: Record<WorkflowType, string>;
	workflowTypeRecordPlural: Record<WorkflowType, string>;
	rfiaStatusRecord: Record<RfiaStatus, string>;
	orderStatusRecord: Record<OrderProcessStatus, string>;
	repairProcessStatusRecord: Record<RepairProcessStatus, string>;
	loanStatusRecord: Record<LoanProcessStatus, string>;
	transactionTypeRecord: Record<TransactionType, string>;
	inventoryStatusRecord: Record<InventoryStatus, string>;
	inventoryItemExportRecord: Record<InventoryItemExportProp, string>;
	currentLocationTypeRecord: Record<CurrentLocationType, string>;
	purchaseExportRecord: Record<PurchaseExportProp, string>;
	purchaseTypeRecord: Record<PurchaseType, string>;
	expenseOrIncomeTypeRecord: Record<ExpenseOrIncomeType, string>;
	creditedStatusRecord: Record<CreditedStatus, string>;
	propertyOfTypeRecord: Record<PropertyOfType, string>;
	patientStatusRecord: Record<PatientStatus, string>;
	documentationTypeRecord: Record<DocumentationType, string>;
	localizedLanguageRecord: Record<LanguageOption, string>;
	templateMainContextRecord: Record<TemplateMainContext, string>;
	linkViewContextTypeRecord: Record<LinkViewContextType, string>;
	leftRightOrStereoRecord: Record<LeftRightOrStereoType, string>;
	speechImageTypeRecord: Record<SpeechImageType, string>;
	pageClaimRecord: Record<PageClaim, string>;
	userRoleRecord: Record<UserRole, string>;
	functionalClaimRecord: Record<FunctionalClaim, string>;
	rfiaWarningRecord: Record<RfiaWarning, string>;
	repairWarningRecord: Record<RepairWarning, string>;
	scanSourceRecord: Record<ScanSource, string>;
	scanErrorRecord: Record<ScanError, string>;
	commissionRuleMethodRecord: Record<CommissionRuleMethod, string>;
	patientRequiredPropertiesRecord: Record<PatientRequiredProperty, string>;
	rruleFrequencyRecord: Record<Frequency, string>;
	patientSearchModeRecord: Record<PatientSearchMode, string>;
	salesSearchModeRecord: Record<SalesSearchMode, string>;
	patientSearchModeToLabelRecord: Record<PatientSearchMode, string>;
	calendarEventRequiredPropertiesRecord: Record<CalendarEventRequiredProperty, string>;
	notificationTypeRecord: Record<NotificationType, string>;
	rizivRefundableHearingInstrumentLinkStatusRecord: Record<ProductCatalogItemRizivHearingAidDefinitionV2LinkStatus, string>;
	lumSumpRecord: Record<LumpSumType, string>;
	stereoMonoContralateralRecord: Record<StereoMonoContralateralType, string>;
	batteryTypeRecord: Record<BatteryType, string>;
	batteryTypeCaptionRecord: Record<BatteryType, string>;
	hearingAidTypeRecord: Record<HearingAidType, string>;
	orientationRecord: Record<Orientation, string>;
	contactInfoTypeRecord: Record<ContactInfoType, string>;
	rosaCalendarViewTypeRecord: Record<RosaCalendarViewType, string>;
	rosaEventStatusRecord: Record<RosaEventStatus, string>;
	patientDetailTabTitleRecord: Record<PatientDetailTabType, string>;
	patientDetailOverviewLayoutElementTypeRecord: Record<PatientDetailOverviewLayoutElementType, string>;
	orderLineStatusRecord: Record<OrderLineStatus, string>;
	stockTransactionTabTitleRecord: Record<StockTransactionTabType, string>;
	articlesInquiryStatusTypeRecord: Record<ArticlesInquiryStatusType, string>;
	articlesInquiryLineStatusTypeRecord: Record<ArticlesInquiryLineStatusType, string>;
	orderPrefilterRecord: Record<OrderPrefilter, string>;
	loansPrefilterKeyTypeRecord: Record<LoansPrefilter, string>;
	articlesInquiriesPrefilterRecord: Record<ArticlesInquiriesPrefilter, string>;
	rfiaInvoiceProcessStepRecord: Record<RfiaInvoiceProcessStep, string>;
	rfiaHandoverSaleGenerationModeRecord: Record<RfiaHandoverSaleGenerationMode, string>;
	purchasesPrefilterRecord: Record<PurchasesPrefilter, string>;
	salesPrefilterRecord: Record<SalesPrefilter, string>;
	ascSortPropertyRecord: Record<SortPropertyDescriptor, string>;
	descSortPropertyRecord: Record<SortPropertyDescriptor, string>;
	tenderProcessStatusRecord: Record<TenderProcessStatus, string>;
	tendersPrefilterRecord: Record<TendersPrefilter, string>;
	exactOnlineSalesPrefilterRecord: Record<ExactOnlineSalePrefilter, string>;
	exactOnlineProductCatalogPrefilterRecord: Record<ExactOnlineProductCatalogItemPrefilter, string>;
	exactOnlineCustomerAccountPrefilterRecord: Record<ExactOnlineCustomerAccountPrefilter, string>;
	repairProcessStepRecord: Record<RepairProcessStep, string>;
	repairPrefilterRecord: Record<RepairPrefilter, string>;
	noahDevicesTabTypeRecord: Record<NoahDevicesTabType, string>;
	logoutModeRecord: Record<ApplicationLogoutMode, string>;
}

export const RecordContext = React.createContext<IRecordContext>(undefined as any);
