import { NoahUserSetupsQueryParamsLsKey } from 'app/localStorageKeys';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	INoahUserSetup,
	INoahUserSetupsQueryMetadata,
	INoahUserSetupsQueryParams,
	IQueryResultWithMetadata,
	noahUserSetupsQuery_concurrencyToken,
	noahUserSetupsQuery_query,
} from 'gen/ApiClient';
import React from 'react';
import { NoahUserSetupsQueryContext } from './NoahUserSetupsQueryContext';

const DefaultParams: INoahUserSetupsQueryParams = {
	sortParams: { direction: 'asc', property: 'clientId' },
	pageParams: { index: 1, size: 10 },
	clientIds: [],
	isLinked: undefined,
	moduleIds: [],
};

export const NoahUserSetupsQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResultWithMetadata<INoahUserSetup, INoahUserSetupsQueryMetadata>, INoahUserSetupsQueryParams>
			defaultParams={DefaultParams}
			queryF={noahUserSetupsQuery_query}
			concurrencyF={noahUserSetupsQuery_concurrencyToken}
			localStorageKey={NoahUserSetupsQueryParamsLsKey}
			context={NoahUserSetupsQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
