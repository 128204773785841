import React from 'react';
import { useContext } from 'react';
import { PreloadCacheProvider as FrameworkCacheProvider } from 'framework/context/PreloadCacheProvider';
import { calendarEventTypesQuery_all, calendarEventTypesQuery_concurrencyToken, ICalendarEventType } from 'gen/ApiClient';
import { PreloadContext } from 'shared/context/PreloadContext';
import { CalendarEventTypesCacheContext } from './CalendarEventTypesCacheContext';

export const CalendarEventTypesCacheProvider = ({ children }: any) => {
	const { calendarEventTypes } = useContext(PreloadContext);

	return (
		<FrameworkCacheProvider<ICalendarEventType[]>
			concurrencyF={calendarEventTypesQuery_concurrencyToken}
			loadF={calendarEventTypesQuery_all}
			context={CalendarEventTypesCacheContext}
			initialData={calendarEventTypes}>
			{children}
		</FrameworkCacheProvider>
	);
};
