import { CardContent, Typography, useTheme } from '@material-ui/core';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { FixedWidthCard } from 'framework/components/FixedWidthCard';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { isLast } from 'framework/utils/array/isLast';
import { formatDate } from 'framework/utils/date/formatDate';
import { IInventoryItem, ITransactionSummary, LinkViewContextType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { LinkViewContextNavigationRecord } from 'shared/records/XLinkViewContextNavigationRecord';
import { TransactionTypeChip } from './TransactionTypeChip';

const canNavigate = (t: ITransactionSummary) => {
	if (t.linkViewContextId) {
		if ((t.linkViewContextType as LinkViewContextType) === 'InventoryItem') {
			return false;
		} else {
			return LinkViewContextNavigationRecord[t.linkViewContextType as LinkViewContextType] !== undefined;
		}
	} else {
		return false;
	}
};

interface IProps {
	item: IInventoryItem;
	width: number;
	style?: React.CSSProperties;
}

export const HistoryCard = ({ item, width, style }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { push } = useHistory();

	const onNavigate = (t: ITransactionSummary) => {
		if (t.linkViewContextId) {
			const navF = LinkViewContextNavigationRecord[t.linkViewContextType as LinkViewContextType];
			if (navF) {
				push(navF(t.linkViewContextId));
			}
		}
	};

	return (
		<FixedWidthCard
			width={width}
			style={{ marginBottom: theme.spacing(2), overflow: 'unset', ...style }}>
			<CardTitleTypography
				withPadding
				title={`${strings.history} (# ${item.countTransactions})`}
			/>
			<CardContent style={{ paddingTop: 0 }}>
				{item.orderedTransactions.length === 0 && <NoRecordsFound>{strings.noTransactionsForThisItem}</NoRecordsFound>}
				{item.orderedTransactions.length > 0 && (
					<Timeline style={{ padding: 0, margin: 0 }}>
						{item.orderedTransactions.map(t => (
							<TimelineItem key={`${t.id}-${t.reference}-${t.type}`}>
								<TimelineOppositeContent
									className='df-col'
									style={{ maxWidth: 150 }}>
									<TooltipWithSpan title={`${strings.referenceDate}: ${formatDate(t.referenceOrEntryDate, 'date-and-time')}`}>
										<Typography
											color='textSecondary'
											variant='caption'
											style={{ width: 120 }}>
											{formatDate(t.referenceOrEntryDate)}
										</Typography>
									</TooltipWithSpan>
									{t.hasReferenceDate && (
										<TooltipWithSpan title={`${strings.entryDate}: ${formatDate(t.date, 'date-and-time')}`}>
											<Typography
												color='textSecondary'
												variant='caption'
												style={{ width: 120 }}>{`(${formatDate(t.date)})`}</Typography>
										</TooltipWithSpan>
									)}
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot color='secondary' />
									{isLast(t, item.orderedTransactions) === false && <TimelineConnector />}
								</TimelineSeparator>
								<TimelineContent>
									<div
										className='df-col'
										style={{
											marginTop: '-3px',
											cursor: canNavigate(t) ? 'pointer' : 'inherit',
											marginBottom: theme.spacing(1),
										}}
										onClick={() => (canNavigate(t) ? onNavigate(t) : undefined)}>
										<TransactionTypeChip
											type={t.type}
											style={{ width: 'fit-content', marginBottom: theme.spacing(1) }}
										/>
										{t.hasReference && (
											<LabelledProperty
												label={strings.reference}
												property={t.reference}
												spacing={2}
											/>
										)}
										{t.hasReferenceDate && (
											<LabelledProperty
												label={strings.referenceDate}
												property={formatDate(t.referenceDate)}
												spacing={2}
											/>
										)}
										{t.hasExternalReference && (
											<LabelledProperty
												label={strings.externalReference}
												property={t.externalReference}
												spacing={2}
											/>
										)}
										{t.hasPatient && (
											<LabelledProperty
												label={strings.patient}
												property={t.patientIdentifier}
												spacing={2}
											/>
										)}
										{t.hasSupplier && (
											<LabelledProperty
												label={strings.supplier}
												property={t.supplierIdentifier}
												spacing={2}
											/>
										)}
										{t.hasFromLocation && (
											<LabelledProperty
												label={strings.fromLocation}
												property={t.fromLocationIdentifier}
												spacing={2}
											/>
										)}
										{t.hasToLocation && (
											<LabelledProperty
												label={strings.toLocation}
												property={t.toLocationIdentifier}
												spacing={2}
											/>
										)}
									</div>
								</TimelineContent>
							</TimelineItem>
						))}
					</Timeline>
				)}
			</CardContent>
		</FixedWidthCard>
	);
};
