import { IPurchaseCreditNoteLineModel } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const lineToModel = (t: ILine, index: number): IPurchaseCreditNoteLineModel => {
	return {
		purchaseLineId: t.purchaseLine.id,
		quantity: t.quantity,
		zeroBasedIndexNumber: index,
	};
};
