import { MbscCalendarEvent } from '@mobiscroll/react';
import { parseISO } from 'date-fns';
import { IRosaEventDto } from 'gen/ApiClient';

export const mapRosaEventToMobiscrollCalendarEvent = (event: IRosaEventDto, canGroup: boolean): MbscCalendarEvent => {
	return {
		id: event.id,
		start: parseISO(event.startsAt as any),
		end: parseISO(event.endsAt as any),
		allDay: event.isAllDay,
		// recurring:
		// recurring: event.rRule,
		// recurringException: event.exDates,
		title: event.display,
		color: event.motiveHexColor,
		resource: canGroup ? event.hpId : undefined,
		//  mapEventGroupKeys(event, groupScheme),
		// color: mapEventColor(event, colorScheme),
		data: event,
	};
};
