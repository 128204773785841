import React from 'react';
import { IFormProps } from './common/IFormProps';
import { FormNumberField } from './FormNumberField';

interface IProps<TModel> extends IFormProps<TModel> {
	allowNegative?: boolean;
	startAdornment?: JSX.Element;
	endAdornment?: JSX.Element;
}

export const FormPercentageField = <TModel extends unknown>({ allowNegative, ...props }: IProps<TModel>) => {
	return (
		<FormNumberField<TModel>
			{...props}
			decimalScale={2}
			suffix=' %'
			allowNegative={allowNegative ?? false}
			isAllowed={values => {
				const { floatValue } = values;
				return floatValue === undefined || floatValue <= 100;
			}}
		/>
	);
};
