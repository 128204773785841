import { Step, StepProps } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import React from 'react';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { StepLabelWithWarning } from 'framework/components/StepLabelWithWarning';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { formatDate } from 'framework/utils/date/formatDate';
import {
	IMedicalPrescriptionStep,
	IRequestForInsuranceAllowance,
	rfiasCommand_deleteMedicalPrescription,
	rfiasCommand_updateApprovalMedicalPrescriptionDate,
	rfiasCommand_updateExpectedReceptionDateForMedicalPrescription,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { MedicalPrescriptionStepContent } from './MedicalPrescriptionStepContent';

interface IProps extends StepProps {
	rfia: IRequestForInsuranceAllowance;
	step: IMedicalPrescriptionStep;
	reload: VoidFunction;
}

export const MedicalPrescriptionStep = ({ rfia, step, reload, ...rest }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onEditApprovalDate = () => {
		open(
			<DateModelForm
				open
				disableFuture
				confirm={confirm}
				cancel={cancel}
				label={strings.date}
				formTitle={strings.changeWhat(strings.prescriptionDate)}
				initialValue={step.prescriptionDate}
				submitFunction={date => rfiasCommand_updateApprovalMedicalPrescriptionDate(rfia.id, step.id, date)}
			/>
		);
	};

	const onEditExpectedReceptionDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				label={strings.expectedWhat(strings.receptionDateOfDocuments)}
				formTitle={strings.changeWhat(strings.expectedWhat(strings.receptionDateOfDocuments))}
				initialValue={step.expectedReceptionDate}
				submitFunction={date => rfiasCommand_updateExpectedReceptionDateForMedicalPrescription(rfia.id, step.id, date)}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.medicalPrescription)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => rfiasCommand_deleteMedicalPrescription(rfia.id, step.id)}
				accept={confirm}
			/>
		);
	};

	return (
		<Step
			{...rest}
			completed={step.hasReceived}
			active={step.hasReceived === false && rfia.canReceiveMedicalPrescription}>
			<StepLabelWithWarning warning={step.isDisapproved}>
				<div className='df-col'>
					<div className='df-row-ac'>
						<div>{step.isDisapproved ? strings.medicalPrescriptionDisApprovedTitle : strings.medicalPrescriptionApprovedTitle}</div>
					</div>
					{step.hasSent && (
						<LabelledProperty
							label={strings.dateOfDispatch}
							property={formatDate(step.sentDate)}
							withoutMargin
							variant='caption'
							color={step.isDisapproved ? 'warning' : 'info'}
						/>
					)}
					{step.hasSent && (
						<LabelledProperty
							label={strings.expectedWhat(strings.receptionDateOfDocuments)}
							property={formatDate(step.expectedReceptionDate)}
							edit={onEditExpectedReceptionDate}
							withoutMargin
							variant='caption'
							color={step.isDisapproved ? 'warning' : 'info'}
						/>
					)}
					{step.hasReceived && step.isApproved === false && (
						<LabelledProperty
							label={strings.receptionDate}
							property={formatDate(step.receptionDate)}
							withoutMargin
							variant='caption'
							color={step.isDisapproved ? 'warning' : 'info'}
						/>
					)}
					{step.isApproved && (
						<LabelledProperty
							label={strings.prescriptionDate}
							edit={onEditApprovalDate}
							property={formatDate(step.prescriptionDate)}
							withoutMargin
							variant='caption'
							secondaryActionIcon={<DeleteOutlineIcon />}
							secondaryAction={rfia.isCompleted === false && rfia.hasApprovedFeedbackAdvisoryPhysician === false ? onDelete : undefined}
						/>
					)}
				</div>
			</StepLabelWithWarning>
			<MedicalPrescriptionStepContent
				rfia={rfia}
				reload={reload}
				step={step}
			/>
		</Step>
	);
};
