import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo, useState } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { SelectableColumn } from '../SelectableColumn';
import { createColumnRecord } from '../utils/createColumnRecord';
import { createHeaders } from '../utils/createHeaders';
import { ViewContext } from './ViewContext';
import { UsersCacheContext } from 'app/main/settings/users/UsersCacheContext';

const DefaultColumns: SelectableColumn[] = ['type', 'createdByUser', 'reference', 'created', 'validUntil', 'recipientName', 'countItems', 'priceInVat'];

export const ViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);
	const colRecord = useMemo(() => createColumnRecord(strings), [strings]);
	const { saleTypeRecord } = useContext(RecordContext);
	const [usersCache] = usePreloadCacheContext(UsersCacheContext);
	const users = useMemo(() => usersCache?.users ?? [], [usersCache]);
	const headers = useMemo(() => createHeaders(colRecord, saleTypeRecord, users, strings), [colRecord, saleTypeRecord, users, strings]);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
