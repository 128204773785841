import { ISale } from 'gen/ApiClient';
import React from 'react';
import { SalesCreditChipOrFragment } from './SalesCreditChipOrFragment';
import { SalesPaymentStatusChip } from './SalesPaymentStatusChip';
import { SalesTypeChip } from './SalesTypeChip';

interface IProps {
	item: ISale;
	style?: React.CSSProperties;
}

export const SalesChips = ({ item, style }: IProps) => {
	return (
		<div
			className='df-row-ac gap-8'
			style={style}>
			<SalesPaymentStatusChip item={item} />
			<SalesTypeChip item={item} />
			<SalesCreditChipOrFragment item={item} />
		</div>
	);
};
