import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { FinancialsPageBreadcrumbTitle } from '../FinancialsPageBreadcrumbTitle';
import { SupplierAccountsRoute } from '../routes';

export const SupplierAccountsPageBreadcrumbs = () => {
	const strings = useLocalization();

	return (
		<React.Fragment>
			<FinancialsPageBreadcrumbTitle />
			<PageBreadcrumbDivider />
			<PageBreadcrumb
				text={strings.accountsPayable}
				route={SupplierAccountsRoute}
			/>
		</React.Fragment>
	);
};
