import { useUserHasPageClaim } from 'claims/useUserHasPageClaim';
import { PageClaim } from 'gen/ApiClient';
import React from 'react';
import { NoAccessPage } from './NoAccessPage';

interface IProps {
	pageClaim: PageClaim;
	children: any;
}

export const RestrictedContent = ({ children, pageClaim }: IProps) => {
	const hasAccess = useUserHasPageClaim(pageClaim);

	return hasAccess ? children : <NoAccessPage />;
};
