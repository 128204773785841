import { useTheme } from '@material-ui/core';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import React from 'react';

interface IProps {
	children: any;
	onClick?: VoidFunction;
	style?: React.CSSProperties;
	textStyle?: React.CSSProperties;
	className?: string;
}

export const LightBulbNotification = ({ children, onClick, style, textStyle, className }: IProps) => {
	const theme = useTheme();

	return (
		<div
			className={`df-row-ac ${className}`}
			style={{ color: theme.palette.warning.main, whiteSpace: 'pre-wrap', cursor: onClick ? 'pointer' : 'inherit', ...style }}
			onClick={onClick}>
			<EmojiObjectsIcon style={{ fontSize: '24px' }} />
			<div style={{ marginLeft: theme.spacing(1), fontSize: '14px', fontWeight: 400, ...textStyle }}>{children}</div>
		</div>
	);
};
