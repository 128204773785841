import { DialogProps } from '@material-ui/core';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { businessDataCommand_patchMainUser } from 'gen/ApiClient';
import React from 'react';
import { SelectAudiologistForm } from '../../users/forms/SelectAudiologistForm';

interface IProps extends DialogProps {
	initialId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const BusinessDataMainUserForm = ({ initialId, confirm, cancel, ...rest }: IProps) => {
	const [link, isSubmitting] = useFormSubmit(businessDataCommand_patchMainUser);

	const onConfirm = async (id: string, setError: (error: string) => void) => {
		handleValidateResponseBase(await link({ mainUserId: id }), setError, confirm);
	};

	return (
		<SelectAudiologistForm
			{...rest}
			fullWidth
			confirm={onConfirm}
			cancel={cancel}
			initialId={initialId}
			isSubmitting={isSubmitting}
		/>
	);
};
