import { IconButton, Step, StepContent, StepLabel, StepProps, useTheme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import React from 'react';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { formatDate } from 'framework/utils/date/formatDate';
import { IRequestForInsuranceAllowance, rfiasCommand_updatePrescriptionForTrialDate } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ProcessButton } from 'shared/process/ProcessButton';
import { ReceivePrescriptionForm } from '../../forms/ReceivePrescriptionForm';

interface IProps extends StepProps {
	rfia: IRequestForInsuranceAllowance;
	reload: VoidFunction;
}

export const PrescriptionForTrialStep = ({ rfia, reload, ...rest }: IProps) => {
	const theme = useTheme();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [hovered, enter, leave] = useStateBoolean(false);
	const strings = useLocalization();

	const onReceivePrescription = () => {
		open(
			<ReceivePrescriptionForm
				rfia={rfia}
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onEditPrescriptionDate = () => {
		open(
			<DateModelForm
				open
				disableFuture
				confirm={confirm}
				cancel={cancel}
				label={strings.date}
				formTitle={strings.prescriptionForTrial}
				initialValue={rfia.prescriptionForTrialDate}
				submitFunction={date => rfiasCommand_updatePrescriptionForTrialDate(rfia.id, date)}
			/>
		);
	};

	return (
		<Step
			{...rest}
			completed={rfia.hasPrescriptionForTrialDate}
			active={(rfia.hasPrescriptionForTrialDate === false || rfia.hasEntPhysician === false) && rfia.isCancelled === false}>
			<StepLabel error={rfia.hasPrescriptionForTrialDate === false}>
				<div className='df-col'>
					<div
						className='df-row-ac'
						onMouseEnter={enter}
						onMouseLeave={leave}>
						<div>{strings.prescriptionForTrialReceived}</div>
						{rfia.isHandedOver === false && (
							<IconButton
								size='small'
								style={{
									marginLeft: theme.spacing(0.5),
									visibility: hovered ? 'visible' : 'collapse',
									color: rfia.hasPrescriptionForTrialDate === false ? theme.palette.error.main : theme.palette.primary.main,
								}}
								onClick={onEditPrescriptionDate}>
								<EditIcon fontSize='small' />
							</IconButton>
						)}
					</div>
					{rfia.hasPrescriptionForTrialDate && (
						<LabelledProperty
							label={strings.prescriptionDate}
							property={formatDate(rfia.prescriptionForTrialDate)}
							withoutMargin
							variant='caption'
						/>
					)}
				</div>
			</StepLabel>
			<StepContent>
				<ProcessButton
					startIcon={<ScheduleIcon />}
					text={strings.register}
					onClick={onReceivePrescription}
				/>
			</StepContent>
		</Step>
	);
};
