import { ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import { FilterOutlineIcon } from 'framework/components/icons/FilterOutlineIcon';
import { FolderZipOutlineIcon } from 'framework/components/icons/FolderZipOutlineIcon';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useApiCall } from 'framework/hooks/useApiCall';
import {
	IQueryResultWithMetadata,
	ISettlementProposal,
	ISettlementProposalQueryMetadata,
	ISettlementProposalsQueryParams,
	OriginalOrLatestDocumentExportMode,
	SettlementProposalExportProp,
	SettlementProposalExportProps,
	settlementProposalsQuery_checkDocuments,
	settlementProposalsQuery_export,
	settlementProposalsQuery_exportToZip,
} from 'gen/ApiClient';
import { useDownloadWithLoader } from 'gen/useDownloadWithLoader';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { ViewContext } from '../context/ViewContext';
import { ExportToZipWarningDialog } from './ExportToZipWarningDialog';

interface IProps {
	params: ISettlementProposalsQueryParams;
	queryResult: IQueryResultWithMetadata<ISettlementProposal, ISettlementProposalQueryMetadata>;
}

export const SpsExportButtonMenuAndDialog = ({ params, queryResult }: IProps) => {
	const strings = useLocalization();
	const { exportRecord } = useContext(ViewContext);
	const checkCall = useApiCall(settlementProposalsQuery_checkDocuments);
	const exportToZip = useDownloadWithLoader(settlementProposalsQuery_exportToZip);
	const { open, confirm, cancel } = useDialogsContext();
	const anchor = useAnchorElement();

	const onCheckAndExportToZip = async () => {
		const r = await checkCall(params);
		if (r.isSuccess) {
			if (r.result.hasWarning) {
				open(
					<ExportToZipWarningDialog
						open
						confirm={mode => {
							confirm();
							onExportToZip(mode);
						}}
						cancel={cancel}
						documentsResponse={r.result}
					/>
				);
			} else {
				await onExportToZip('OriginalDocument');
			}
		}
	};

	const onExportToZip = async (mode: OriginalOrLatestDocumentExportMode) => {
		await exportToZip(mode, params);
	};

	return (
		<ExportButtonMenuAndDialog<ISettlementProposalsQueryParams, SettlementProposalExportProp>
			params={params}
			filteredCount={queryResult.filteredCount}
			unfilteredCount={queryResult.unfilteredCount}
			allProps={SettlementProposalExportProps}
			downloadF={settlementProposalsQuery_export}
			fileName={strings.settlementProposals}
			record={exportRecord}
			view='settlementProposals'
			anchor={anchor}>
			<MenuItem
				onClick={() => {
					anchor[2]();
					onCheckAndExportToZip();
				}}>
				<ListItemIcon>
					<FilterOutlineIcon fontSize='small' />
					<FolderZipOutlineIcon fontSize='small' />
				</ListItemIcon>
				<Typography variant='inherit'>{strings.exportCountToZip(queryResult.filteredCount)}</Typography>
			</MenuItem>
		</ExportButtonMenuAndDialog>
	);
};
