import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';
import { QuickFilter } from '../PaymentsList';
import { SelectableColumn } from '../SelectableColumn';
import { createColumnRecord } from '../utils/createColumnRecord';
import { createHeaders } from '../utils/createHeaders';
import { createQfRecord } from '../utils/createQfRecord';
import { ViewContext } from './ViewContext';
import { QueryContext } from './QueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useParamsFromQueryContext } from 'framework/hooks/useParamsFromQueryContext';

// enkel hier nodig
const DefaultColumns: SelectableColumn[] = [
	'paymentDate',
	'reference',
	'paymentMethodIdentifier',
	'customerOrSupplierAccountIdentifier',
	'salesType',
	'amountPaid',
];

export const ViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [qf, setQf] = useState<QuickFilter>('all');
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);
	const { saleTypeRecord } = useContext(RecordContext);
	const colRecord = useMemo(() => createColumnRecord(strings), [strings]);
	const headers = useMemo(() => createHeaders(colRecord, saleTypeRecord, strings), [colRecord, saleTypeRecord, strings]);
	const quickFilterRecord = useMemo(() => createQfRecord(strings), [strings]);
	const { setParams } = useParamsFromQueryContext(QueryContext);

	useEffect(() => {
		setParams(params =>
			onResetToPage1({
				...params,
				isLinkedToASale: qf === 'linkedToASale' ? true : undefined,
				isLinkedToAPurchase: qf === 'linkedToAPurchase' ? true : undefined,
			})
		);
		// eslint-disable-next-line
	}, [qf]);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
				quickFilter: qf,
				setQuickFilter: setQf,
				quickFilterRecord: quickFilterRecord,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
