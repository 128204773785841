import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IPatchInvoiceForwardingModeRequest, InvoiceForwardingMode, invoiceForwardingSettingsCommand_patchMode } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';
import { createInvoiceForwardingModeRecord } from './createInvoiceForwardingModeRecord';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPatchInvoiceForwardingModeRequest>({
			mode: yup.mixed<InvoiceForwardingMode>(),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchInvoiceForwardingModeForm = ({ title, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [patch, isSubmitting] = useFormSubmit(invoiceForwardingSettingsCommand_patchMode);
	const ModeRecord = React.useMemo(() => createInvoiceForwardingModeRecord(strings), [strings]);

	const handleSubmit = async (values: IPatchInvoiceForwardingModeRequest, helpers: FormikHelpers<IPatchInvoiceForwardingModeRequest>) => {
		const r = await patch(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	return (
		<Formik<IPatchInvoiceForwardingModeRequest>
			validateOnMount
			initialValues={{
				mode: 'OnInvoiceCreated',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					maxWidth='sm'
					{...rest}>
					<DialogTitle>{strings.mode}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormExtendedRadioGroupField<IPatchInvoiceForwardingModeRequest, InvoiceForwardingMode>
							name='mode'
							label={strings.mode}
							options={[
								{ value: 'OnInvoiceCreated', label: ModeRecord['OnInvoiceCreated'] },
								{ value: 'OnDocumentCreated', label: ModeRecord['OnDocumentCreated'] },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.update}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
