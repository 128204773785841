import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { Logo } from '../logo/Logo';

interface IProps {
	children: any;
	title?: string;
}

export const EmptyPageWithLogo = ({ title, children }: IProps) => {
	return (
		<div
			className='df-col-ac stretch-ver jc-c'
			style={{ paddingBottom: '100px' }}>
			<Logo
				width={300}
				style={{ marginBottom: '40px' }}
			/>
			<Card style={{ maxWidth: '500px' }}>
				<CardContent style={{ padding: '40px' }}>
					{title && (
						<Typography
							variant='body2'
							style={{ textAlign: 'center', marginBottom: '12px' }}>
							{title}
						</Typography>
					)}
					{children}
				</CardContent>
			</Card>
		</div>
	);
};
