import { useTheme } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useLoggedInUserIsUserById } from 'app/auth/useLoggedInUserIsUserById';
import { useLoggedInUserIsMinimalInRole } from 'app/auth/useLoggedInUserIsMinimalInRole';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IAuthDto, auth_endSession } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { formatLastActivity } from './components/formatLastActivity';
import { OsIcon } from './icons/OsIcon';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IAuthDto;
	withoutBorder?: boolean;
	reload: VoidFunction;
}

export const AuthSessionListItem = ({ item, withoutBorder, reload }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { open, cancel, confirm } = useDialogsContext(reload);
	const lastActivityString = useMemo(() => formatLastActivity(item.lastActivity, strings), [item, strings]);
	const canExecuteAdminActions = useLoggedInUserIsMinimalInRole('Admin');
	const itsMe = useLoggedInUserIsUserById(item.id);
	const itsMeOrCanExecuteAdminActions = React.useMemo(() => itsMe || canExecuteAdminActions, [itsMe, canExecuteAdminActions]);

	const onEnd = () => {
		open(
			<AlertDialog
				open
				title={strings.endSessionForUser}
				content={strings.userWillBeLoggedOutOfSpecificSession}
				acceptText={strings.yesWhatExclamation(strings.endSession)}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => auth_endSession({ authId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<div
			className='df-row w100 gap-16'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<OsIcon
				os={item.metadata.os}
				style={{ color: theme.palette.text.secondary }}
			/>
			<div className='df-col fg1'>
				<span style={{ fontSize: MajorFontSize }}>{item.metadata.os}</span>
				<span style={{ fontSize: MinorFontSize }}>{item.metadata.browserVersion}</span>
				<span style={{ fontSize: MinorFontSize, color: lastActivityString === strings.activeNow ? theme.palette.success.main : 'unset' }}>
					{lastActivityString}
				</span>
				<span style={{ fontSize: MinorFontSize }}>{`IP: ${item.metadata.remoteIp}`}</span>
				<TextLinkButton
					startIcon={<ExitToAppIcon />}
					hoverColor='secondary'
					onClick={onEnd}
					disabled={itsMeOrCanExecuteAdminActions === false}>
					{strings.endThisSession}
				</TextLinkButton>
			</div>
		</div>
	);
};
