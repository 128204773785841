import { ClientClaim } from 'gen/ApiClient';
import { calculateUserHasClientClaim } from './calculateUserHasClientClaim';
import { useLoggedInUser } from './useLoggedInUser';

export function useLoggedInUserIsSuperUserOrHasClientClaim(clientClaim: ClientClaim): boolean {
	const user = useLoggedInUser();
	if (user.isSuperUser) {
		return true;
	} else {
		return calculateUserHasClientClaim(user, clientClaim);
	}
}
