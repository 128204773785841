import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import {
	rfiaDocumentsCommand_createHandoverCertificate,
	rfiaDocumentsCommand_createProformaHandoverCertificate,
	rfiasHandoverCommand_deleteProforma,
	rfiasQuery_single,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { ProformaHandoverForm } from '../../forms/handover/ProformaHandoverForm';
import { SelectRfiaHandoverCertificateTemplateForm } from './SelectRfiaHandoverCertificateTemplateForm';

type Mode = 'question' | 'create-document';

interface IProps {
	rfiaId: string;
	isHifExist: boolean;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const HandoverCertificateDialog = ({ rfiaId, isHifExist, confirm, cancel }: IProps) => {
	const [rfiaDetail, reload] = useApiEffect(rfiasQuery_single, rfiaId);
	const rfia = useMemo(() => rfiaDetail?.rfia, [rfiaDetail]);
	const strings = useLocalization();
	const [mode, setMode] = useState<Mode>('question');

	if (rfia === undefined) {
		return <></>;
	} else if (rfia.isHandedOver) {
		return (
			<SelectRfiaHandoverCertificateTemplateForm
				open
				submitF={values => rfiaDocumentsCommand_createHandoverCertificate(rfia.id, values)}
				confirm={confirm}
				cancel={cancel}
				handoverDate={rfia.handoverDate}
			/>
		);
	} else if (rfia.equipmentDeliveryStep.hasProformaData && mode === 'question') {
		return (
			<AlertDialog
				open
				title={strings.proformaAttachment12}
				content={strings.alreadyProformaAttachmentPresentDeleteFirstQuestion}
				acceptText={strings.deleteData}
				rejectText={strings.regenerateTheDocument}
				reject={() => setMode('create-document')}
				accept={reload}
				acceptF={() => rfiasHandoverCommand_deleteProforma({ rfiaId: rfia.id })}
			/>
		);
	} else if (rfia.equipmentDeliveryStep.hasProformaData && mode === 'create-document') {
		return (
			<SelectRfiaHandoverCertificateTemplateForm
				open
				submitF={values => rfiaDocumentsCommand_createProformaHandoverCertificate(rfia.id, values)}
				confirm={confirm}
				cancel={cancel}
				handoverDate={rfia.equipmentDeliveryStep?.proformaHandoverDate}
			/>
		);
	} else {
		return (
			<ProformaHandoverForm
				rfia={rfia}
				confirm={confirm}
				cancel={cancel}
				isHifExist={isHifExist}
			/>
		);
	}
};
