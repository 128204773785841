import { IStrings } from 'localization/IStrings';
import { AgendaViewType } from '../AgendaViewType';

export const createAgendaViewTypeRecord = (strings: IStrings): Record<AgendaViewType, string> => {
	return {
		day: strings.day,
		week: strings.week,
		month: strings.month,
		year: strings.year,
		list: strings.list,
		timeline: strings.timeline,
	};
};
