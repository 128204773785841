import React, { useState } from 'react';
import { PatientDetailPageViewContext } from './PatientDetailPageViewContext';
import { PatientDetailTabType } from './PatientDetailTabType';

export const PatientDetailPageViewProvider = ({ children }: any) => {
	const [selectedTab, setSelectedTab] = useState<PatientDetailTabType>('overview');
	const [patientId, setPatientId] = useState<string>('');

	return (
		<PatientDetailPageViewContext.Provider
			value={{
				selectedTab: selectedTab,
				setSelectedTab: setSelectedTab,
				patientId: patientId,
				setPatientId: setPatientId,
			}}>
			{children}
		</PatientDetailPageViewContext.Provider>
	);
};
