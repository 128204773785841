import startOfDay from 'date-fns/startOfDay';
import { RRule, rrulestr } from 'rrule';

export const newRRuleUntilDate = (rRule: string, originalStartDate: Date, untilDate: Date) => {
	return new RRule({
		...rrulestr(`RRULE:${rRule}`, { dtstart: new Date(originalStartDate) }).options,
		count: undefined,
		until: startOfDay(new Date(untilDate)),
	});
};
