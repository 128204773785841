import { IQuickFilterContext } from 'framework/IQuickFilterContext';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { IStockItemDto } from 'gen/ApiClient';
import React from 'react';
import { StockItemDtoSelectableColumn } from '../StockItemDtoSelectableColumn';
import { QuickFilter } from '../StockItems';

interface IViewContext extends ITableViewContext<StockItemDtoSelectableColumn | string, IStockItemDto>, IQuickFilterContext<QuickFilter> {
	exportRecord: Record<string, string>;
}

export const ViewContext = React.createContext<IViewContext>(undefined as any);
