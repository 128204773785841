import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		created: strings.dateCreated,
		fromLocationIdentifier: strings.fromLocation,
		patients: strings.patients,
		reference: strings.reference,
		supplierIdentifier: strings.supplier,
		toLocationIdentifier: strings.toLocation,
		quantity: strings.quantity,
		externalReference: strings.externalReference,
		referenceDate: strings.referenceDate,
	};
};
