import { FormHelperText } from '@material-ui/core';
import React from 'react';

interface IProps {
	children: any;
}

export const FormHelperTextX = ({ children }: IProps) => {
	return (
		<FormHelperText
			variant='filled'
			style={{ marginBottom: '6px', marginLeft: '2px', whiteSpace: 'pre-wrap' }}>
			{children}
		</FormHelperText>
	);
};
