import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { setFieldError } from 'framework/forms/utils/setFieldError';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import * as yup from 'yup';
import { EntPhysiciansCacheContext } from '../EntPhysiciansCacheContext';
import { FormSelectOrCreateEntPhysicianWithOfficeField } from './FormSelectOrCreateEntPhysicianWithOfficeField';

interface IModel {
	id: string;
	officeId: string | undefined;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		id: yup.string().required(strings.formRequired(strings.entPhysician)),
		officeId: yup.string(),
	});
};

interface IProps extends DialogProps {
	initialEntId?: string;
	initialOfficeId?: string;
	confirm: (id: string, officeId: string | undefined, setError: (error: string) => void) => void;
	cancel: VoidFunction;
	isSubmitting: boolean;
	withSelectOffice?: boolean;
}

export const SelectEntPhysicianWithOfficeForm = ({
	initialEntId,
	initialOfficeId,
	confirm,
	cancel,
	isSubmitting,
	withSelectOffice = false,
	...rest
}: IProps) => {
	const strings = useLocalization();
	const [data] = usePreloadCacheContext(EntPhysiciansCacheContext);
	const { isStacked } = useDialogsContext();

	const handleSubmit = (values: IModel, helpers: FormikHelpers<IModel>) => {
		confirm(values.id, values.officeId, (error: string) => setFieldError<IModel>('id', error, helpers));
	};

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{
				id: initialEntId ?? '',
				officeId: initialOfficeId,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}
					open={rest.open && isStacked === false}>
					<DialogTitle>{`${strings.selectWhat(strings.entPhysician)} (+ ${strings.office})`}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormSelectOrCreateEntPhysicianWithOfficeField<IModel>
							nameEnt='id'
							nameOffice='officeId'
							labelEnt={strings.entPhysician}
							labelOffice={strings.office}
							initialEntId={initialEntId}
							initialOfficeId={initialOfficeId}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.link}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
