import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { rfiasCommand_registerApprovalMedicalPrescription, rfiasCommand_registerDisapprovalMedicalPrescription } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useNow } from 'shared/utils/useNow';

interface IModel {
	date: Date;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			date: yup.date().required(strings.formRequired(strings.date)),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IModel)[]> = {
	0: [],
	1: ['date'],
};

interface IProps extends DialogProps {
	rfiaId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const ReceiveMedicalPrescriptionForm = ({ rfiaId, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [isApproved, setIsApproved] = useState<boolean>(true);
	const notify = useSnackbarNotify();
	const [approve, isSubmitting, setIsSubmitting] = useFormSubmit(rfiasCommand_registerApprovalMedicalPrescription);
	const [disApprove] = useFormSubmit(rfiasCommand_registerDisapprovalMedicalPrescription, setIsSubmitting);
	const [step, setStep] = useState<number>(0);
	const now = useNow();

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = isApproved ? await approve(rfiaId, values.date) : await disApprove(rfiaId, values.date);
		if (handleValidateResponse<IModel>(r, helpers, 'date')) {
			notify(isApproved ? strings.registeredWhat(strings.approval) : strings.registeredWhat(strings.reception));
			confirm();
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{ date: now }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='md'>
					<DialogTitleWithFormStepper
						title={strings.registerReceptionMedicalPrescription}
						step={step}
						labels={[strings.approval, strings.date]}
					/>
					<DialogContent
						className='df-col'
						dividers>
						{step === 0 && (
							<ExtendedRadioGroup<boolean>
								selected={isApproved}
								setSelected={setIsApproved}
								options={[
									{ value: true, label: strings.approved, caption: strings.approvalEntPhysicianCaption },
									{ value: false, label: strings.disApproved, caption: strings.disApprovalEntPhysicianCaption },
								]}
							/>
						)}
						{step === 1 && (
							<FormDatePicker<IModel>
								name='date'
								label={isApproved ? strings.prescriptionDate : strings.receptionDate}
								disableFuture
							/>
						)}
					</DialogContent>
					<PageableFormDialogActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
