import { Collapse } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { FunctionVariantIcon } from 'framework/components/icons/FunctionVariantIcon';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { PageStatsButtonX } from 'framework/components/layout/PageStatsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import {
	ICustomerAccountLineQueryMetadata,
	IQueryResult,
	ISettlementProposal,
	ISettlementProposalQueryMetadata,
	ISettlementProposalsQueryParams,
	settlementProposalsCommand_scriptSetAsPaid,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { FinancialsPageBreadcrumbTitle } from '../FinancialsPageBreadcrumbTitle';
import { SettlementProposalsPageQuickStats } from './SettlementProposalsPageQuickStats';
import { SettlementProposalsTabType, SettlementProposalsTabTypes } from './SettlementProposalsTabType';
import { SettlementProposalSettingsDialog } from './settings/SettlementProposalSettingsDialog';
import { createSettlementProposalsTabTypeRecord } from './utils';

const TabTypeIconRecord: Record<SettlementProposalsTabType, React.ReactNode> = {
	sps: <ListIcon />,
	unhandled: <PlaylistAddCheckIcon />,
};

const createEndIconRecord = (
	qrUnhandledMetadata: ICustomerAccountLineQueryMetadata,
	qrSpsMetadata: ISettlementProposalQueryMetadata
): Record<SettlementProposalsTabType, React.ReactNode | undefined> => {
	return {
		sps: qrSpsMetadata.countUnhandled > 0 && (
			<BadgeChipX
				value={qrSpsMetadata.countUnhandled}
				color='error'
			/>
		),
		unhandled: qrUnhandledMetadata.unfilteredCount > 0 && (
			<BadgeChipX
				value={qrUnhandledMetadata.unfilteredCount}
				color='error'
			/>
		),
	};
};

interface IProps {
	reload: VoidFunction;
	tab: SettlementProposalsTabType;
	setTab: (tab: SettlementProposalsTabType) => void;
	queryResult: IQueryResult<ISettlementProposal>;
	params: ISettlementProposalsQueryParams;
	qrUnhandledMetadata: ICustomerAccountLineQueryMetadata;
	qrSpsMetadata: ISettlementProposalQueryMetadata;
	style?: React.CSSProperties;
}

export const SettlementProposalsPageHeader = ({ queryResult, params, tab, setTab, reload, style, qrUnhandledMetadata, qrSpsMetadata }: IProps) => {
	const strings = useLocalization();
	const userIsSuperUser = useLoggedInUserIsSuperUser();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [showStats, toggleShowStats] = useStateToggleBoolean(true);
	const tabRecord = useMemo(() => createSettlementProposalsTabTypeRecord(strings), [strings]);
	const endIconRecord = useMemo(() => createEndIconRecord(qrUnhandledMetadata, qrSpsMetadata), [qrUnhandledMetadata, qrSpsMetadata]);

	const onScript = () => {
		open(
			<AlertDialog
				open
				title={'Execute script (set sps as paid)'}
				content={`Mark ${queryResult.filteredCount.toString()} sps as paid?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => settlementProposalsCommand_scriptSetAsPaid(params)}
				accept={confirm}
			/>
		);
	};

	const onSettings = () => {
		open(
			<SettlementProposalSettingsDialog
				open
				close={cancel}
			/>
		);
	};

	return (
		<div
			className='df-col fg1'
			style={style}>
			<div className='df-row-ac'>
				<FinancialsPageBreadcrumbTitle />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={strings.verzamelstaten} />
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageStatsButtonX
						showStats={showStats}
						toggleShowStats={toggleShowStats}
					/>
					{userIsSuperUser && tab === 'sps' && (
						<TooltipWithSpan title={`Execute custom scripts`}>
							<ColorOnHoverIconButton
								onClick={onScript}
								size='small'
								edge='end'>
								<FunctionVariantIcon />
							</ColorOnHoverIconButton>
						</TooltipWithSpan>
					)}
					<PageSettingsButtonX onClick={onSettings} />
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<Collapse in={showStats}>
				<SettlementProposalsPageQuickStats
					qrSpsMetadata={qrSpsMetadata}
					qrUnhandledMetadata={qrUnhandledMetadata}
					style={{ paddingTop: 16 }}
				/>
			</Collapse>
			<HorizontalTabsX
				tabs={SettlementProposalsTabTypes}
				selectedTab={tab}
				setSelectedTab={setTab}
				titleRecord={tabRecord}
				startIconRecord={TabTypeIconRecord}
				endIconRecord={endIconRecord}
				style={{
					marginTop: 16,
					marginLeft: -4,
				}}
			/>
		</div>
	);
};
