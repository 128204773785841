import { Divider } from '@material-ui/core';
import { InnerPageXWithStickySidebar } from 'framework/components/layout/InnerPageXWithStickySidebar';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { InvoiceForwardingRecordsListFilterCard } from './InvoiceForwardingRecordsListFilterCard';
import { InvoiceForwardingRecordsQueryContext } from './InvoiceForwardingRecordsQueryContext';
import { InvoiceForwardingRecordListItemCard } from './InvoiceForwardingRecordListItemCard';
import { InvoiceForwardingRecordDetailsComponent } from './InvoiceForwardingRecordDetailsComponent';

interface IProps extends IReloadTriggerProps {
	reload: VoidFunction;
}

export const InvoiceForwardingRecordsListTab = ({ reload, reloadTrigger }: IProps) => {
	const strings = useLocalization();
	const { reload: reloadQuery, queryResult, params, setParams } = useQueryContext(InvoiceForwardingRecordsQueryContext);
	const [selectedId, setSelectedId] = useState<string>();
	const expandedRecord = useMemo(() => queryResult?.values.find(t => t.id === selectedId), [queryResult, selectedId]);

	useLazyEffect(() => {
		reloadQuery();
	}, [reloadTrigger]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<InnerPageXWithStickySidebar
			maxWidth={1280}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<InvoiceForwardingRecordsListFilterCard
						params={params}
						setParams={setParams}
						style={{ minWidth: 220 }}
					/>
					<div className='fg1'></div>
					<Divider />
				</div>
			}>
			<div
				className='df-row gap-16 h100'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				<div className='df-col gap-16'>
					<SearchbarX
						placeholder={`${strings.searchOnReference}...`}
						value={params.searchString}
						onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
					/>
					<div
						className='df-col'
						style={{ gap: 8 }}>
						{queryResult.values.map(t => (
							<InvoiceForwardingRecordListItemCard
								key={t.id}
								item={t}
								isSelected={selectedId === t.id}
								toggle={() => setSelectedId(selectedId === t.id ? undefined : t.id)}
							/>
						))}
						<QueryResultPaginationX
							queryResult={queryResult}
							setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
							style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
						/>
					</div>
				</div>
				{expandedRecord !== undefined && expandedRecord.id === selectedId && (
					<>
						<Divider orientation='vertical' />
						<InvoiceForwardingRecordDetailsComponent
							id={expandedRecord.id}
							reload={reload}
							reloadTrigger={reloadTrigger}
						/>
					</>
				)}
			</div>
		</InnerPageXWithStickySidebar>
	);
};
