import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { PaymentMethodsPreloadCacheContext } from 'app/main/settings/paymentMethods/PaymentMethodsPreloadCacheContext';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { MoneyRangeFilterComponent } from 'framework/filter/MoneyRangeFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IOption } from 'framework/IOption';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { CustomerAccountTypes, ExpenseOrIncomeType, ExpenseOrIncomeTypes, IPaymentsQueryParams, SalesTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	params: IPaymentsQueryParams;
	onFilter: (params: IPaymentsQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof IPaymentsQueryParams)[] = ['isLinkedToAPurchase', 'isLinkedToASale'];

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const { customerAccountTypeRecord } = useContext(RecordContext);
	const [paymentMethods] = usePreloadCacheContext(PaymentMethodsPreloadCacheContext);
	const { translatePaymentMethod, saleTypeRecord, expenseOrIncomeTypeRecord } = useContext(RecordContext);

	if (paymentMethods === undefined) {
		return <div></div>;
	}

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.dateCreated}
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<DateFilterItem
				label='Betaaldatum'
				after={params.paymentDateAfter}
				before={params.paymentDateBefore}
				setSelected={(after, before) => onFilter({ ...params, paymentDateAfter: after, paymentDateBefore: before })}
			/>
			<MoneyRangeFilterComponent
				label={strings.amountAsValue}
				selected={{ min: params.amountPaidMoreThan, max: params.amountPaidLessThan }}
				setSelected={val => onFilter({ ...params, amountPaidLessThan: val.max, amountPaidMoreThan: val.min })}
				icon={<HelpOutlineIcon />}
			/>
			<ListFilterComponent<string>
				options={CustomerAccountTypes.map<IOption<string>>(t => ({ id: t, identifier: customerAccountTypeRecord[t] }))}
				selected={params.customerAccountTypes}
				setSelected={vals => onFilter({ ...params, customerAccountTypes: vals })}
				icon={<HelpOutlineIcon />}
				label='Type account'
			/>
			<ListFilterComponent<string>
				options={paymentMethods.map<IOption<string>>(t => ({ id: t.id, identifier: translatePaymentMethod(t.identifier) }))}
				selected={params.paymentMethodIds}
				setSelected={vals => onFilter({ ...params, paymentMethodIds: vals })}
				icon={<HelpOutlineIcon />}
				label='Methode'
			/>
			<ListFilterComponent<string>
				options={SalesTypes.map<IOption<string>>(t => ({ id: t, identifier: saleTypeRecord[t] }))}
				selected={params.saleTypes}
				setSelected={vals => onFilter({ ...params, saleTypes: vals })}
				icon={<HelpOutlineIcon />}
				label='Type verkoop'
			/>
			<RecordListFilterComponent<ExpenseOrIncomeType>
				options={ExpenseOrIncomeTypes}
				record={expenseOrIncomeTypeRecord}
				selected={params.expenseOrIncomeTypes as any}
				label='Uitgave of inkomst'
				icon={<HelpOutlineIcon />}
				setSelected={vals => onFilter({ ...params, expenseOrIncomeTypes: vals })}
			/>
			<BooleanRadioFilterComponent
				trueText={strings.withWhat(strings.remark)}
				falseText={strings.withoutWhat(strings.remark)}
				selected={params.hasComment}
				setSelected={val => onFilter({ ...params, hasComment: val })}
				label={strings.remark}
				icon={<HelpOutlineIcon />}
			/>
		</FilterBar>
	);
};
