import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteFreeSoloTextField } from 'framework/forms/FormAutocompleteFreeSoloTextField';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';
import { CosiNeedsCacheContext } from './context/CosiNeedsCacheContext';

interface IModel {
	need: string;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			need: yup.string().required(strings.cosiNeed),
		})
		.defined();
};

const emptyValues: IModel = {
	need: '',
};

interface IProps extends DialogProps {
	confirm: (need: string) => void;
	cancel: VoidFunction;
}

export const CosiNeedForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [data] = useCacheContext(CosiNeedsCacheContext);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		confirm(values.need);
	};

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					fullWidth>
					<DialogTitle>
						<div>{strings.newWhat(strings.cosiNeed)}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormAutocompleteFreeSoloTextField<IModel>
							name='need'
							label={strings.need}
							options={data}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={strings.create}
						isSubmitting={false}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
