import { ICalendarEventDto } from 'gen/ApiClient';
import { AgendaColorScheme } from './AgendaColorScheme';
import { DefaultCalendarColor } from '../DefaultCalendarColor';

export const mapEventColor = (event: ICalendarEventDto, colorScheme: AgendaColorScheme): string => {
	if (colorScheme === 'users') {
		return event.userColor ?? DefaultCalendarColor;
	} else if (colorScheme === 'rooms') {
		return event.roomColor ?? DefaultCalendarColor;
	} else if (colorScheme === 'types') {
		return event.eventTypeColor ?? DefaultCalendarColor;
	} else {
		return DefaultCalendarColor;
	}
};
