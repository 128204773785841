import React from 'react';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { IStockMovement, StockMovementExportProp } from 'gen/ApiClient';
import { StockMovementsSelectableColumn } from './StockMovementsSelectableColumn';

interface IViewContext extends ITableViewContext<StockMovementsSelectableColumn, IStockMovement> {
	exportRecord: Record<StockMovementExportProp, string>;
}

export const StockMovementsListViewContext = React.createContext<IViewContext>(undefined as any);
