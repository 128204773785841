import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IInventoryItemModel, inventoryItemsCommand_define, productCatalogQuery_single } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormSelectPatientField } from 'shared/patientSearch/FormSelectPatientField';
import { PatientSearchProvider } from 'shared/patientSearch/PatientSearchProvider';
import { useApiEffect } from 'framework/hooks/useApiEffect';

const createSchema = (strings: IStrings) => {
	return yup.object<IInventoryItemModel>({
		serialNumber: yup.string().required(),
		productCatalogItemId: yup.string().required(),
		tailorMadeForPatientId: yup.string(),
	});
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	productCatalogItemId: string;
	onPrevious: VoidFunction;
}

export const InventoryItemModelForm = ({ confirm, cancel, productCatalogItemId, onPrevious, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [productCatalogItem] = useApiEffect(productCatalogQuery_single, productCatalogItemId);
	const [submit, isSubmitting] = useFormSubmit(inventoryItemsCommand_define);

	const handleSubmit = async (values: IInventoryItemModel, helpers: FormikHelpers<IInventoryItemModel>) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.addedWhat(strings.inventoryItem));
			confirm(r.result.objectId);
		}
	};

	if (productCatalogItem === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IInventoryItemModel>
			validateOnMount
			initialValues={{
				productCatalogItemId: productCatalogItemId,
				serialNumber: '',
				tailorMadeForPatientId: '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<PatientSearchProvider>
					<Dialog
						fullWidth
						{...rest}>
						<DialogTitle>{strings.defineNewInventoryItem}</DialogTitle>
						<DialogContent
							className='df-col'
							dividers>
							<FormTextField<IInventoryItemModel>
								name='serialNumber'
								label={strings.serialNumber}
							/>
							{productCatalogItem.isTailorMade && (
								<FormSelectPatientField<IInventoryItemModel>
									name='tailorMadeForPatientId'
									label={strings.patient}
								/>
							)}
						</DialogContent>
						<CancelSubmitFormDialogActions
							cancel={cancel}
							isSubmitting={isSubmitting}
							submitText={strings.add}
							previous={onPrevious}
						/>
					</Dialog>
				</PatientSearchProvider>
			</Form>
		</Formik>
	);
};
