import { Card, CardContent, CardProps, IconButton, Popover, useTheme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { combineClassNames } from 'framework/utils/combineClassNames';
import { BatteryType, HearingAidType, IProductCatalogItem, noahDeviceReadOutsQuery_lastNonDeletedRuleForProductCatalogItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RecordContext } from 'shared/records/RecordContext';
import { RizivRefundableHearingInstrumentChip } from 'shared/rizivRefundableHearingInstruments/RizivRefundableHearingInstrumentChip';
import { boolToYesNo } from 'shared/utils/boolToString';
import { NoahDeviceReadOutDialog } from '../../noahDevices/rules/detail/NoahDeviceReadOutDialog';
import { HearingAidDataModelForm } from '../forms/hearingInstrument/HearingAidDataModelForm';
import { LinkRizivRefundableHearingInstrumentForm } from '../forms/LinkRizivRefundableHearingInstrumentForm';

interface IProps extends IReloadTriggerProps, CardProps {
	item: IProductCatalogItem;
	reload: VoidFunction;
}

export const HearingAidPropertiesCard = ({ item, reload, reloadTrigger, ...rest }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { hearingAidTypeRecord, batteryTypeRecord } = useContext(RecordContext);
	const [anchor, openAnchor, closeAnchor] = useAnchorElement();
	const [rors, reloadRors] = useApiEffect(noahDeviceReadOutsQuery_lastNonDeletedRuleForProductCatalogItem, item.id);

	useLazyEffect(() => {
		reloadRors();
	}, [reloadTrigger]);

	const onLinkRiziv = () => {
		open(
			<LinkRizivRefundableHearingInstrumentForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	const onEdit = () => {
		open(
			<HearingAidDataModelForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	const onViewRule = (id: string) => {
		open(
			<NoahDeviceReadOutDialog
				open
				id={id}
				close={cancel}
			/>
		);
	};

	return (
		<Card
			{...rest}
			className={combineClassNames('df-col', rest.className)}>
			<CardContent>
				<CardTitleTypography
					title={`${strings.properties} (${strings.hearingAid})`}
					edit={onEdit}
				/>
				<LabelledProperty
					label={strings.type}
					property={hearingAidTypeRecord[item.hearingAidData?.type as HearingAidType]}
				/>
				<LabelledProperty
					label={strings.batteryType}
					property={batteryTypeRecord[item.hearingAidData?.batteryType as BatteryType]}
				/>
				{(item.hearingAidData?.type as HearingAidType) === 'BTE' && (
					<>
						<LabelledProperty
							label={strings.somethingQuestionmark(strings.withSeparateEarmould)}
							property={boolToYesNo(item.hearingAidData?.hasSeparateEarmould, strings)}
						/>
						<LabelledProperty
							label={strings.somethingQuestionmark(strings.withSeparateReceiver)}
							property={boolToYesNo(item.hearingAidData?.hasSeparateReceiver, strings)}
						/>
					</>
				)}
				<CardTitleTypography
					title={`${strings.refund} ${strings.RIZIV}`}
					edit={onLinkRiziv}
					withPaddingTop
					style={{ marginBottom: 16 }}
				/>
				{(item.rizivHearingAidDefinitionV2IdentificationCodes ?? []).map(t => (
					<RizivRefundableHearingInstrumentChip
						key={t}
						identificationCode={t}
						reload={reload}
					/>
				))}
				{(item.rizivHearingAidDefinitionV2IdentificationCodes ?? []).length === 0 && <NoRecordsFound>{strings.noCodesLinkedYet}</NoRecordsFound>}
				{rors && rors.length > 0 && (
					<>
						{Boolean(anchor) && (
							<Popover
								open={Boolean(anchor)}
								anchorEl={anchor}
								onClose={closeAnchor}>
								<div style={{ padding: 16, maxWidth: 400 }}>
									<LightBulbNotification>{strings.rulesForReadOutsSuggestionCaption}</LightBulbNotification>
								</div>
							</Popover>
						)}
						<div
							className='df-row-ac'
							style={{ paddingTop: 16 }}>
							<CardTitleTypography title={`${strings.rulesForReadOuts} (NOAH)`} />
							<IconButton
								size='small'
								style={{ marginLeft: theme.spacing(0.5), color: theme.palette.primary.main }}
								onClick={openAnchor}>
								<HelpOutlineIcon fontSize='small' />
							</IconButton>
						</div>
						{rors.map((t, index) => (
							<LabelledProperty
								key={t}
								label={`# ${(index + 1).toString()}`}
								property={t}
								navigate={() => onViewRule(t)}
							/>
						))}
					</>
				)}
			</CardContent>
		</Card>
	);
};
