import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { ArticlesInquiryLineStatusType, IArticlesInquiryLineDto } from 'gen/ApiClient';
import React from 'react';

const ArticlesInquiryLineStatusTypeIconRecord: Record<ArticlesInquiryLineStatusType, OverridableComponent<SvgIconTypeMap<{}, 'svg'>>> = {
	Assigned: CheckCircleOutlineIcon,
	PartialAssigned: RadioButtonUncheckedIcon,
	Unassigned: RadioButtonUncheckedIcon,
};

interface IProps {
	item: IArticlesInquiryLineDto;
	style?: React.CSSProperties;
}

export const ArticlesInquiryLineStatusIcon = ({ item, style }: IProps) => {
	const Icon = ArticlesInquiryLineStatusTypeIconRecord[item.status as ArticlesInquiryLineStatusType];
	return <Icon style={style} />;
};
