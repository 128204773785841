import { IProductCatalogItem } from 'gen/ApiClient';
import { ISearchAndSelectLineWithAssurance } from '../ISearchAndSelectLineWithAssurance';

export const filterLines = <TLine extends ISearchAndSelectLineWithAssurance>(assurance: IProductCatalogItem, lines: TLine[]): TLine[] => {
	const x = lines.filter(t => t.productCatalogItem !== undefined && t.productCatalogItem.isTangible);
	const otherAssurancesOfSameType = lines
		.filter(t => t.productCatalogItem !== undefined && t.productCatalogItem.type === assurance.type)
		.map(t => t.assuredLineId)
		.filter(t => t !== undefined);
	return x.filter(t => otherAssurancesOfSameType.indexOf(t.id) === -1);
};
