import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, useTheme } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { useApiCall } from 'framework/hooks/useApiCall';
import { SortableTable } from 'framework/table/SortableTable';
import { ISalesSummary, salesQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { ISalesSummaryWithSelection } from '../../../../../../financial/sales/ISalesSummaryWithSelection';
import { IHandoverLine } from '../../IHandoverLine';
import { advanceInvoiceToLine } from '../../utils/advanceInvoiceToLine';
import { createHeaders } from './createHeaders';

interface IProps extends DialogProps {
	advanceInvoices: ISalesSummary[];
	confirm: (lines: IHandoverLine[]) => void;
	cancel: VoidFunction;
}

export const SelectAdvanceInvoicesForm = ({ advanceInvoices, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const [lines, setLines] = useState<ISalesSummaryWithSelection[]>(advanceInvoices.map(t => ({ ...t, isSelected: true })));
	const headers = useMemo(() => createHeaders(lines, setLines, strings), [lines, setLines, strings]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const loadSale = useApiCall(salesQuery_single);

	const onConfirm = async () => {
		setIsSubmitting(true);
		let x: IHandoverLine[] = [];
		await Promise.all(
			lines
				.filter(t => t.isSelected)
				.map(async t => {
					const r = await loadSale(t.id);
					if (r.isSuccess) {
						r.result.lines.forEach(line => {
							x = [...x, advanceInvoiceToLine(line, r.result, strings)];
						});
					}
				})
		);
		setIsSubmitting(false);
		confirm(x);
	};

	return (
		<Dialog {...rest}>
			<DialogTitle>{strings.selectWhat(strings.advanceInvoices)}</DialogTitle>
			<DialogContent
				dividers
				style={{ padding: 0 }}>
				<SortableTable<ISalesSummaryWithSelection>
					padding={theme.spacing(1)}
					headers={headers}
					getKey={t => t.id}
					values={lines}
					defaultSortParams={{ property: 'date', direction: 'asc' }}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<LoaderButton
					isLoading={isSubmitting}
					onClick={onConfirm}
					disabled={lines.filter(t => t.isSelected).length === 0}
					color='primary'>{`${strings.select} (${lines.filter(t => t.isSelected).length})`}</LoaderButton>
			</DialogActions>
		</Dialog>
	);
};
