import { Card, CardMedia, CardProps, Divider, useTheme } from '@material-ui/core';
import { calcPurchaseDetailRoute } from 'app/main/financial/routes';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { createHeader } from 'framework/table/createHeader';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { combineClassNames } from 'framework/utils/combineClassNames';
import { createType } from 'framework/utils/createType';
import {
	ICommonPurchasesFilterParams,
	IPurchase,
	IPurchasesForSupplierQueryParams,
	PurchaseExportProp,
	PurchaseExportProps,
	purchasesQuery_exportForSupplier,
	purchasesQuery_queryForSupplier,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect } from 'react';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsNavigateHeader } from 'shared/table/createDetailsNavigateHeader';
import { PurchaseChips } from '../../../purchases/PurchaseChips';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { SelectableColumn } from './SelectableColumn';

const DefaultParams: IPurchasesForSupplierQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	paymentStatuses: [],
	priceExVatLessThan: undefined,
	priceExVatMoreThan: undefined,
	types: [],
	validUntilAfter: undefined,
	validUntilBefore: undefined,
	creditedStatuses: [],
	purchaseReferenceDateAfter: undefined,
	purchaseReferenceDateBefore: undefined,
	prefilter: 'All',
	labels: [],
};

const QuickFilters = createType('all', 'withOutstandingBalance');
export type QuickFilter = typeof QuickFilters[number];

interface IProps extends IReloadTriggerProps, CardProps {
	supplierId: string;
}

// TODO watch out for the reload!
export const PurchasesCard = ({ supplierId, reloadTrigger, ...rest }: IProps) => {
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(pars => purchasesQuery_queryForSupplier(supplierId, pars), DefaultParams, reloadTrigger);
	const theme = useTheme();
	const { columns, setColumns, columnsRecord, headers, quickFilter, setQuickFilter, quickFilterRecord, exportRecord } = useContext(ViewContext);

	useEffect(() => {
		setParams(params =>
			onResetToPage1({
				...params,
				hasOutstandingBalance: quickFilter === 'withOutstandingBalance' ? true : undefined,
			})
		);
		// eslint-disable-next-line
	}, [quickFilter]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<Card
			{...rest}
			className={combineClassNames('fg1', rest.className)}>
			<CardTitleTypography
				withPadding
				title={strings.purchases}
			/>
			<Divider />
			<div
				className='df-row-ac jc-sb'
				style={{ padding: theme.spacing(1) }}>
				<ButtonGroupWithSelection<QuickFilter>
					options={QuickFilters}
					selected={quickFilter}
					setSelected={setQuickFilter}
					renderOption={t => quickFilterRecord[t]}
				/>
				<div>
					<ExportButtonMenuAndDialog<ICommonPurchasesFilterParams, PurchaseExportProp>
						params={params}
						filteredCount={queryResult.filteredCount}
						unfilteredCount={queryResult.unfilteredCount}
						allProps={PurchaseExportProps}
						downloadF={(...pars) => purchasesQuery_exportForSupplier(supplierId, ...pars)}
						fileName='Aankopen_leverancier'
						record={exportRecord}
						view='purchases'
					/>
					<SelectColumnsButtonAndDialog
						selectedColumns={columns}
						setSelectedColumns={setColumns}
						record={columnsRecord}
						view='supplierAccountsPurchases'
					/>
				</div>
			</div>
			<Divider />
			<SearchbarWithParams
				params={params}
				setParams={setParams}
				placeholder={strings.searchVerb}
				style={{ width: '400px' }}
				variant='embedded'
			/>
			<Divider />
			<div className='df-row jc-sb'>
				<Filter
					params={params}
					onFilter={params => setParams(onResetToPage1(params))}
					paddingLeft={theme.spacing(1.5)}
				/>
			</div>
			<Divider />
			<CardMedia>
				<PageableTableWithColumnSelection<IPurchase, SelectableColumn>
					queryResult={queryResult}
					headers={headers}
					sortParams={params.sortParams}
					pageParams={params.pageParams}
					setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
					getKey={inst => inst.id}
					selectedColumns={columns}
					prependColumn={createHeader<IPurchase>(strings.status, t => (
						<PurchaseChips item={t} />
					))}
					appendColumn={createDetailsNavigateHeader<IPurchase>(calcPurchaseDetailRoute)}
				/>
			</CardMedia>
		</Card>
	);
};
