import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		created: strings.createdAt,
		lastUpdated: strings.lastUpdated,
		pos: 'Positie',
		subscriptionKey: 'Subscription Key',
	};
};
