import { StepContent, StepContentProps } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import React from 'react';
import { FileDocumentEditOutlineIcon } from 'framework/components/icons/FileDocumentEditOutlineIcon';
import { StopCircleOutlineIcon } from 'framework/components/icons/StopCircleOutlineIcon';
import { IRequestForInsuranceAllowance, ITrialStepDto, rfiaDocumentsCommand_createLoanSlip } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SelectTemplateFromPredefinedTypeForm } from 'documents/SelectTemplateFromPredefinedTypeForm';
import { ProcessButton } from 'shared/process/ProcessButton';
import { ProcessButtonDivider } from 'shared/process/ProcessButtonDivider';
import { EndRfiaTrial } from '../../forms/trial/EndRfiaTrial';
import { RegisterRfiaTrialForm } from '../../forms/trial/RegisterRfiaTrialForm';
import { TrialReportDialog } from '../../trialReport/TrialReportDialog';

interface IProps extends StepContentProps {
	rfia: IRequestForInsuranceAllowance;
	step?: ITrialStepDto;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const TrialStepContent = ({ style, rfia, reload, step, ...rest }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onLoanSlip = () => {
		if (step?.id === undefined) {
			return;
		}
		open(
			<SelectTemplateFromPredefinedTypeForm
				open
				type='RfiaLoanSlip'
				submitF={templateId => rfiaDocumentsCommand_createLoanSlip(rfia.id, step.id, templateId)}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onStart = () => {
		open(
			<RegisterRfiaTrialForm
				open
				confirm={confirm}
				cancel={cancel}
				rfia={rfia}
			/>
		);
	};

	const onEnd = () => {
		if (step === undefined) {
			return;
		}
		open(
			<EndRfiaTrial
				rfia={rfia}
				trial={step}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onTrialReport = () => {
		if (step === undefined) {
			return;
		}
		open(
			<TrialReportDialog
				open
				trialId={step.id}
				rfiaId={rfia.id}
				patientId={rfia.patientId}
				close={confirm}
			/>
		);
	};

	return (
		<StepContent {...rest}>
			<div
				className='df-row-ac'
				style={style}>
				<ProcessButton
					disabled={step !== undefined || rfia.isFailedTrial}
					startIcon={<PlayCircleOutlineIcon />}
					onClick={onStart}
					text={strings.start}
				/>
				<ProcessButtonDivider />
				<ProcessButton
					disabled={step === undefined || step?.isEnded}
					onClick={onEnd}
					startIcon={<StopCircleOutlineIcon />}
					text={strings.stop}
				/>
				<ProcessButtonDivider />
				<ProcessButton
					disabled={step === undefined || step?.hasLoanSlip}
					onClick={onLoanSlip}
					startIcon={<FileDocumentEditOutlineIcon />}
					text={strings.slip}
				/>
				<ProcessButtonDivider />
				<ProcessButton
					disabled={step === undefined || step?.hasTrialReportDocument}
					onClick={onTrialReport}
					startIcon={<FileDocumentEditOutlineIcon />}
					text={strings.trialReport}
				/>
			</div>
		</StepContent>
	);
};
