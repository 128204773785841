import React from 'react';
import { SettingsTabType } from './SettingsTabType';

interface IContext {
	selectedTab: SettingsTabType;
	setSelectedTab: React.Dispatch<React.SetStateAction<SettingsTabType>>;
	TabTypeRecord: Record<SettingsTabType, string>;
}

export const SettingsPageViewContext = React.createContext<IContext>(undefined as any);
