import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	IQueryResultWithMetadata,
	ISale,
	ISalesQueryMetadata,
	ISalesQueryParams,
	salesQuery_concurrencyToken,
	salesQuery_query,
	SalesSearchMode,
} from 'gen/ApiClient';
import React from 'react';
import { salesQueryParamsLsKey } from '../../../../localStorageKeys';
import { SalesListQueryContext } from './SalesListQueryContext';

const DefaultParams: ISalesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	priceInVatLessThan: undefined,
	priceInVatMoreThan: undefined,
	validUntilAfter: undefined,
	validUntilBefore: undefined,
	paymentStatuses: [],
	types: [],
	creditedStatuses: [],
	hasDocument: undefined,
	locationIds: [],
	searchMode: 'AllFields' as SalesSearchMode,
	labels: [],
	zips: [],
	isRecipientVatCustomer: undefined,
	prefilter: 'All',
};

export const SalesListQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResultWithMetadata<ISale, ISalesQueryMetadata>, ISalesQueryParams>
			defaultParams={DefaultParams}
			queryF={salesQuery_query}
			concurrencyF={salesQuery_concurrencyToken}
			localStorageKey={salesQueryParamsLsKey}
			context={SalesListQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
