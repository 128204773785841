import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { IInvoiceForwardingRecord } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: IInvoiceForwardingRecord;
	color: ColorX;
	style?: React.CSSProperties;
}

export const InvoiceForwardingRecordChip = ({ item, style, color }: IProps) => {
	const strings = useLocalization();

	return (
		<ChipX
			label={item.isForwarded ? strings.forwarded : item.isIgnored ? strings.ignored : strings.notSynced}
			color={color}
			style={style}
		/>
	);
};
