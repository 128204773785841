import { Card, CardContent, CardProps } from '@material-ui/core';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import { DocumentCreateOrUploadForm } from 'documents/DocumentCreateOrUploadForm';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { isLast } from 'framework/utils/array/isLast';
import { IDocumentsByContextAndIdParams, IOrderV2, LinkViewContextType, TemplateType, documentsQuery_byIdAndContextNotDeleted } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RecordContext } from 'shared/records/RecordContext';
import { DocumentListItemXDefault } from '../../../../../documents/DocumentListItemXDefault';
import { DocumentUploadForm } from 'documents/DocumentUploadForm';

const OrderLinkViewContextType: LinkViewContextType = 'Order';

interface IProps extends CardProps, IReloadTriggerProps {
	item: IOrderV2;
	reload: VoidFunction;
}
export const OrderDetailDocumentsCard = ({ item, reload, reloadTrigger, ...rest }: IProps) => {
	const strings = useLocalization();
	const params = useMemo<IDocumentsByContextAndIdParams>(() => ({ linkViewContextId: item.id, linkViewContextType: OrderLinkViewContextType }), [item]);
	const [documents, reloadDocuments] = useApiEffect(documentsQuery_byIdAndContextNotDeleted, params);
	const { templateTypeRecord } = useContext(RecordContext);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadDocuments();
	}, [reloadTrigger]);

	const onNew = (type: TemplateType) => {
		open(
			<DocumentCreateOrUploadForm
				title={templateTypeRecord[type]}
				templatePredefinedType={type}
				context={OrderLinkViewContextType}
				contextId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onUpload = () => {
		open(
			<DocumentUploadForm
				title={strings.order}
				contextId={item.id}
				contextType='Order'
				confirm={confirm}
				cancel={cancel}
				open
			/>
		);
	};

	if (documents === undefined) {
		return <></>;
	}

	return (
		<Card {...rest}>
			<CardContent className='df-col gap-8'>
				<CardTitleTypography title={strings.documents} />
				<TextLinkButton
					startIcon={<PostAddOutlinedIcon />}
					onClick={() => onNew('OrderFormToCustomer')}
					color='primary'>
					{strings.orderFormToWho(strings.customer)}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<PostAddOutlinedIcon />}
					onClick={() => onNew('OrderFormToSupplier')}
					color='primary'>
					{strings.orderFormToWho(strings.supplier)}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<PostAddOutlinedIcon />}
					onClick={onUpload}
					color='primary'>
					{strings.uploadADocument}
				</TextLinkButton>
				<DividerWithMargin />
				{documents.map(t => (
					<DocumentListItemXDefault
						key={t.id}
						item={t}
						reload={reloadDocuments}
						withoutBorder={isLast(t, documents)}
					/>
				))}
				{documents.length === 0 && <em>{strings.noDocumentsAddedYet}</em>}
			</CardContent>
		</Card>
	);
};
