import { DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormAutocompleteFreeSoloTextField } from 'framework/forms/FormAutocompleteFreeSoloTextField';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	InterchangeableTangibleType,
	IProductCatalogSearchData,
	ICreateTangibleProductCatalogItemRequest,
	productCatalogCommand_newTangible,
	ProductCatalogItemType,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RecupelDefaultCacheContext } from 'shared/context/RecupelDefaultCacheContext';
import { FormBebatField } from 'shared/forms/FormBebatField';
import { FormSelectRecupelField } from 'shared/forms/FormSelectRecupelField';
import { FormSelectVatPercentageField } from 'shared/forms/FormSelectVatPercentageField';
import { RecordContext } from 'shared/records/RecordContext';
import * as yup from 'yup';
import { ProductCatalogFilterDataContext } from '../context/ProductCatalogFilterDataContext';

const createSchema = (strings: IStrings) => {
	return yup
		.object<ICreateTangibleProductCatalogItemRequest>({
			type: yup.mixed<ProductCatalogItemType>().required(),
			manufacturer: yup.string().required(strings.formRequired(strings.manufacturer)),
			model: yup.string().required(strings.formRequired(strings.model)),
			listPrice: yup.number().required(strings.formRequired(strings.recommendedSalesprice)),
			vatPercentage: yup.number().required(strings.formRequired(strings.vatPercentage)).defined(),
			category: yup.string(),
			subcategory: yup.string(),
			hasBatteries: yup.boolean().defined(),
			hasSerialNumber: yup.boolean().defined(),
			isElectronics: yup.boolean().defined(),
			recupelCode: yup.string(),
			bebatUnitPriceInVat: yup.number(),
			isTailorMade: yup.boolean().defined(),
		})
		.defined();
};

const emptyValues: ICreateTangibleProductCatalogItemRequest = {
	type: 'Charger',
	manufacturer: '',
	model: '',
	category: '',
	subcategory: '',
	listPrice: undefined as any,
	vatPercentage: 21,
	hasBatteries: false,
	hasSerialNumber: false,
	isElectronics: false,
	recupelCode: '',
	bebatUnitPriceInVat: 0,
	isTailorMade: false,
};

const typeToModelRecord: Record<InterchangeableTangibleType, ICreateTangibleProductCatalogItemRequest> = {
	Charger: { ...emptyValues, isElectronics: true, hasBatteries: false, hasSerialNumber: true },
	Dome: emptyValues,
	Earmould: { ...emptyValues, vatPercentage: 6, isTailorMade: true },
	HearingProtection: emptyValues,
	Other: emptyValues,
	Receiver: { ...emptyValues, vatPercentage: 6, isElectronics: true, hasSerialNumber: true },
};

const stepsRecord: Record<number, (keyof ICreateTangibleProductCatalogItemRequest)[]> = {
	0: ['manufacturer', 'model'],
	1: ['hasBatteries', 'isElectronics', 'hasSerialNumber', 'recupelCode', 'bebatUnitPriceInVat', 'isTailorMade'],
	2: ['category', 'subcategory'],
	3: ['listPrice', 'vatPercentage'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	type: InterchangeableTangibleType;
	previous?: VoidFunction;
}

export const TangibleProductCatalogItemForm = ({ confirm, type, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [data] = useCacheContext(ProductCatalogFilterDataContext);
	const [create, isSubmitting] = useFormSubmit(productCatalogCommand_newTangible);
	const [step, setStep] = useState<number>(0);
	const [defaultRecupel] = usePreloadCacheContext(RecupelDefaultCacheContext);

	const handleSubmit = async (values: ICreateTangibleProductCatalogItemRequest, helpers: FormikHelpers<ICreateTangibleProductCatalogItemRequest>) => {
		const r = await create({ ...values, type: type });
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.productCreated);
			confirm(r.result.objectId);
		}
	};

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<ICreateTangibleProductCatalogItemRequest>
			validateOnMount
			initialValues={typeToModelRecord[type]}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					isSubmitting={isSubmitting}
					defaultRecupel={defaultRecupel}
					searchData={data}
					type={type}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	defaultRecupel: string;
	searchData: IProductCatalogSearchData;
	type: InterchangeableTangibleType;
	previous?: VoidFunction;
}

const InnerDialog = ({ step, setStep, cancel, isSubmitting, defaultRecupel, searchData, previous, type, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<ICreateTangibleProductCatalogItemRequest>();
	const strings = useLocalization();
	const canChangeIsTailorMade = useMemo(() => type !== 'Earmould', [type]);
	const { productCatalogItemTypeRecord } = useContext(RecordContext);

	useEffect(() => {
		if (props.values.isElectronics === false) {
			setFormValue<ICreateTangibleProductCatalogItemRequest>('recupelCode', '', props);
		} else {
			setFormValue<ICreateTangibleProductCatalogItemRequest>('recupelCode', defaultRecupel, props);
		}
		// eslint-disable-next-line
	}, [props.values.isElectronics]);

	useEffect(() => {
		if (props.values.hasBatteries === false) {
			setFormValue<ICreateTangibleProductCatalogItemRequest>('bebatUnitPriceInVat', 0, props);
		}
		// eslint-disable-next-line
	}, [props.values.hasBatteries]);

	useEffect(() => {
		if (props.values.isTailorMade) {
			setFormValue<ICreateTangibleProductCatalogItemRequest>('hasSerialNumber', true, props);
		}
		// eslint-disable-next-line
	}, [props.values.isTailorMade]);

	return (
		<OverflowDialog {...rest}>
			<DialogTitleWithFormStepper
				title={`${strings.newTangibleProduct} | ${productCatalogItemTypeRecord[type]}`}
				step={step}
				labels={[strings.name, strings.properties, strings.category, strings.price]}
			/>
			<OverflowDialogContent>
				{step === 0 && (
					<>
						<FormAutocompleteFreeSoloTextField<ICreateTangibleProductCatalogItemRequest>
							name='manufacturer'
							label={strings.manufacturer}
							options={searchData.manufacturers}
							required
						/>
						<FormTextField<ICreateTangibleProductCatalogItemRequest>
							name='model'
							label={strings.model}
							required
						/>
					</>
				)}
				{step === 1 && (
					<>
						{canChangeIsTailorMade && (
							<FormSingleCheckboxField<ICreateTangibleProductCatalogItemRequest>
								name='isTailorMade'
								label={strings.isTailorMade}
							/>
						)}
						<FormSingleCheckboxField<ICreateTangibleProductCatalogItemRequest>
							name='hasSerialNumber'
							label={strings.hasSerialNumber}
							disabled={props.values.isTailorMade}
						/>
						<FormSingleCheckboxField<ICreateTangibleProductCatalogItemRequest>
							name='isElectronics'
							label={strings.isElectronics}
						/>
						{props.values.isElectronics && (
							<FormSelectRecupelField<ICreateTangibleProductCatalogItemRequest>
								name='recupelCode'
								label='Recupel'
							/>
						)}
						<FormSingleCheckboxField<ICreateTangibleProductCatalogItemRequest>
							name='hasBatteries'
							label={strings.hasBatteriesBebatApplicable}
						/>
						{props.values.hasBatteries && (
							<FormBebatField<ICreateTangibleProductCatalogItemRequest>
								name='bebatUnitPriceInVat'
								label={`Bebat ${strings.inVatShort}`}
							/>
						)}
					</>
				)}
				{step === 2 && (
					<>
						<FormAutocompleteFreeSoloTextField<ICreateTangibleProductCatalogItemRequest>
							name='category'
							label={strings.category}
							options={searchData.categories}
						/>
						<FormAutocompleteFreeSoloTextField<ICreateTangibleProductCatalogItemRequest>
							name='subcategory'
							label={strings.subcategory}
							options={searchData.subcategories}
						/>
					</>
				)}
				{step === 3 && (
					<>
						<FormSelectVatPercentageField<ICreateTangibleProductCatalogItemRequest>
							name='vatPercentage'
							label={strings.vatPercentage}
						/>
						<FormMoneyField<ICreateTangibleProductCatalogItemRequest>
							name='listPrice'
							label={strings.salesPrice}
							helperText={strings.priceIncludesAllContributionsRecupelAndBebat}
						/>
					</>
				)}
			</OverflowDialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
				previous={previous}
			/>
		</OverflowDialog>
	);
};
