import React from 'react';
import { SalesDetailPageViewProvider } from './detail/SalesDetailPageViewProvider';
import { ViewProvider as SearchViewProvider } from './forms/search/context/ViewProvider';
import { SalesFilterDataProvider } from './SalesFilterDataProvider';
import { SalesListQueryProvider } from './list/SalesListQueryProvider';
import { SalesListViewProvider } from './list/SalesListViewProvider';

export const SalesProviders = ({ children }: any) => {
	return (
		<SalesListQueryProvider>
			<SalesListViewProvider>
				<SearchViewProvider>
					<SalesDetailPageViewProvider>
						<SalesFilterDataProvider>{children}</SalesFilterDataProvider>
					</SalesDetailPageViewProvider>
				</SearchViewProvider>
			</SalesListViewProvider>
		</SalesListQueryProvider>
	);
};
