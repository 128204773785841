import { DialogProps } from '@material-ui/core';
import React, { useState } from 'react';
import { IProductCatalogItem, ISalesLineForAssuranceDto } from 'gen/ApiClient';
import { ISearchAndSelectLineWithAssurance } from './ISearchAndSelectLineWithAssurance';
import { SelectLinesFromPreviousSaleToAssureDialog } from './previousSale/SelectLinesFromPreviousSaleToAssureDialog';
import { SelectLinesFromThisSaleToAssureDialog } from './thisSale/SelectLinesFromThisSaleToAssureDialog';
import { SelectProductsToAssureModeDialog } from './SelectProductsToAssureModeDialog';

interface IProps<TLine extends ISearchAndSelectLineWithAssurance> extends DialogProps {
	assurance: IProductCatalogItem;
	cancel: VoidFunction;
	lines: TLine[];
	customerAccountId: string | undefined;
	onAdd: (lines: (TLine | ISalesLineForAssuranceDto)[]) => void;
}

type Mode = 'mode' | 'sameSale' | 'previousSale';

export const SelectProductsToAssureDialog = <TLine extends ISearchAndSelectLineWithAssurance>({
	customerAccountId,
	assurance,
	cancel,
	lines,
	onAdd,
	...rest
}: IProps<TLine>) => {
	const [mode, setMode] = useState<Mode>(customerAccountId ? 'mode' : 'sameSale');

	if (mode === 'mode') {
		return (
			<SelectProductsToAssureModeDialog
				{...rest}
				confirm={setMode}
				cancel={cancel}
				type={assurance.isProlongedWarranty ? 'ProlongedWarranty' : 'Insurance'}
			/>
		);
	} else if (mode === 'sameSale') {
		return (
			<SelectLinesFromThisSaleToAssureDialog<TLine>
				{...rest}
				lines={lines}
				assurance={assurance}
				confirm={lines => onAdd(lines)}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<SelectLinesFromPreviousSaleToAssureDialog<TLine>
				{...rest}
				assurance={assurance}
				customerAccountId={customerAccountId!}
				cancel={cancel}
				confirm={lines => onAdd(lines)}
				lines={lines}
			/>
		);
	}
};
