import { ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim';
import { FilterOutlineIcon } from 'framework/components/icons/FilterOutlineIcon';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { IQueryResult } from 'framework/types/IQueryResult';
import { IStockItemDto, IStockItemDtosFilterParams, stockEntriesQuery_exportFinancialFormatted, stockEntriesQuery_exportStockItems } from 'gen/ApiClient';
import { useDownloadWithLoader } from 'gen/useDownloadWithLoader';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { ViewContext } from './context/ViewContext';

interface IProps {
	stockEntryId: string;
	params: IStockItemDtosFilterParams;
	queryResult: IQueryResult<IStockItemDto>;
}

export const StockItemsExportButtonMenuAndDialog = ({ stockEntryId, params, queryResult }: IProps) => {
	const strings = useLocalization();
	const { exportRecord } = useContext(ViewContext);
	const anchor = useAnchorElement();
	const exportFinancialFormatted = useDownloadWithLoader(stockEntriesQuery_exportFinancialFormatted);
	const hasVeppClaim = useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim();
	const exportProps = useMemo(
		() => (hasVeppClaim ? Object.keys(exportRecord) : Object.keys(exportRecord).filter(t => t !== 'StockValue')),
		[exportRecord, hasVeppClaim]
	);

	const onExportFormatted = async () => {
		anchor[2]();
		await exportFinancialFormatted(stockEntryId);
	};

	return (
		<ExportButtonMenuAndDialog<IStockItemDtosFilterParams, string>
			params={params}
			filteredCount={queryResult.filteredCount}
			unfilteredCount={queryResult.unfilteredCount}
			downloadF={(...pars) => stockEntriesQuery_exportStockItems(stockEntryId, ...pars)}
			fileName={strings.stock}
			record={exportRecord}
			allProps={exportProps}
			view='stockItems'
			anchor={anchor}>
			<MenuItem onClick={onExportFormatted}>
				<ListItemIcon>
					<FilterOutlineIcon fontSize='small' />
					<FormatListNumberedIcon fontSize='small' />
				</ListItemIcon>
				<Typography variant='inherit'>{strings.exportFinancialStockFormatted}</Typography>
			</MenuItem>
		</ExportButtonMenuAndDialog>
	);
};
