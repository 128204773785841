import { Card, CardContent } from '@material-ui/core';
import { useLocalizedArticlePropertyKeys } from 'app/main/inventoryManagement/productCatalog/articlePropertyKeys/context/useLocalizedArticlePropertyKeys';
import { ArticlePropertiesForInventoryItemForm } from 'app/main/inventoryManagement/inventoryItems/forms/articleProperties/ArticlePropertiesForInventoryItemForm';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { IInventoryItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ArticlePropertiesLabelledPropertiesX } from './ArticlePropertiesLabelledPropertiesX';

interface IProps {
	item: IInventoryItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
	className?: string;
}

export const ArticlePropertiesCard = ({ item, reload, style, className }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const localizedKeys = useLocalizedArticlePropertyKeys();

	const onEdit = () => {
		open(
			<ArticlePropertiesForInventoryItemForm
				open
				cancel={cancel}
				confirm={confirm}
				item={item}
			/>
		);
	};

	return (
		<Card
			style={style}
			className={`df-col ${className}`}>
			<CardContent>
				<CardTitleTypography
					title={strings.properties}
					edit={onEdit}
				/>
				<ArticlePropertiesLabelledPropertiesX
					aps={item.articlePropertiesX}
					localizedKeys={localizedKeys}
				/>
				{item.articlePropertiesX.length === 0 && <NoRecordsFound withPaddingTop>{strings.noFieldsDefined}</NoRecordsFound>}
			</CardContent>
		</Card>
	);
};
