import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { useFindUser } from 'app/main/settings/users/useFindUser';
import { RoundedSquareX } from 'framework/components/RoundedSquareX';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { StethoscopeIcon } from 'framework/components/icons/StethoscopeIcon';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';
import { isLast } from 'framework/utils/array/isLast';
import { ICalendarEventDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { UpdateCalendarEventForm } from '../forms/UpdateCalendarEventForm';
import { AgendaColorScheme } from './AgendaColorScheme';
import { DeleteEventDialog } from './DeleteEventDialog';
import { formatEventDates } from './formatEventDates';
import { mapEventColor } from './mapEventColor';

interface IProps {
	event: ICalendarEventDto;
	instanceStartDate: Date;
	instanceEndDate: Date;
	colorScheme: AgendaColorScheme;
	reload: VoidFunction;
	close: VoidFunction;
}

export const EventPopoverContent = ({ event, instanceStartDate, instanceEndDate, colorScheme, close, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();
	const openInNewTab = useOpenInNewTab();
	const createdByUser = useFindUser(event.createdByUserId);
	const color = useMemo(() => mapEventColor(event, colorScheme), [event, colorScheme]);

	const onEdit = () => {
		close();
		open(
			<UpdateCalendarEventForm
				open
				confirm={confirm}
				cancel={cancel}
				event={event}
				instanceStartDate={instanceStartDate}
				instanceEndDate={instanceEndDate}
			/>
		);
	};

	const onDelete = () => {
		close();
		open(
			<DeleteEventDialog
				confirm={confirm}
				cancel={cancel}
				event={event}
				instanceDate={instanceStartDate}
			/>
		);
	};

	return (
		<div
			className='df-col'
			style={{ minWidth: 400 }}>
			<div
				className='df-row-ac jc-e'
				style={{ paddingTop: 8, paddingLeft: 6, paddingRight: 6 }}>
				<TooltipWithSpan title={strings.edit}>
					<ColorOnHoverIconButton
						color='primary'
						onClick={onEdit}>
						<EditOutlinedIcon fontSize='small' />
					</ColorOnHoverIconButton>
				</TooltipWithSpan>
				<TooltipWithSpan title={strings.delete}>
					<ColorOnHoverIconButton
						color='secondary'
						onClick={onDelete}>
						<DeleteOutlineOutlinedIcon fontSize='small' />
					</ColorOnHoverIconButton>
				</TooltipWithSpan>
				<IconButton onClick={close}>
					<CloseIcon fontSize='small' />
				</IconButton>
			</div>
			<div style={{ padding: 16, paddingTop: 4 }}>
				<div
					className='df-col'
					style={{ marginBottom: 12 }}>
					<div className='df-row-ac'>
						<RoundedSquareX
							size={40}
							color={color}
						/>
						<div style={{ fontSize: '22px', lineHeight: '28px', fontWeight: 400, color: 'rgb(60, 64, 67)' }}>{event.display}</div>
					</div>
					<div
						className='df-row-ac'
						style={{ marginTop: 3 }}>
						<div style={{ width: '40px' }}></div>
						<div>{formatEventDates(event)}</div>
					</div>
					<div
						className='df-row-ac'
						style={{ marginTop: 3 }}>
						<div style={{ width: '40px' }}></div>
						<div>{event.eventTypeIdentifier}</div>
					</div>
				</div>
				{event.hasRoom && (
					<div className='df-row-ac'>
						<div style={{ color: '#5f6368', width: '40px', display: 'flex' }}>
							<LocationOnOutlinedIcon />
						</div>
						<div
							className='df-col'
							style={{ paddingTop: 6, paddingBottom: 6 }}>
							<div>{event.roomIdentifier}</div>
						</div>
					</div>
				)}
				{event.hasPatient && (
					<div className='df-row-ac'>
						<div style={{ color: '#5f6368', width: '40px', display: 'flex' }}>
							<PersonOutlineOutlinedIcon />
						</div>
						<div
							className='df-col'
							style={{ paddingTop: 6, paddingBottom: 6, marginRight: 6 }}>
							<div>{event.patientIdentifier}</div>
						</div>
						<TooltipWithSpan title={strings.viewWhatInThisTab(strings.patient)}>
							<ColorOnHoverIconButton
								color='primary'
								size='small'
								onClick={() => push(calcPatientDetailRoute(event.patientId!))}>
								<OpenInBrowserIcon fontSize='small' />
							</ColorOnHoverIconButton>
						</TooltipWithSpan>
						<TooltipWithSpan title={strings.viewWhatInOtherTab(strings.patient)}>
							<ColorOnHoverIconButton
								color='primary'
								size='small'
								onClick={() => openInNewTab(calcPatientDetailRoute(event.patientId!), false)}>
								<OpenInNewOutlinedIcon fontSize='small' />
							</ColorOnHoverIconButton>
						</TooltipWithSpan>
					</div>
				)}
				{event.hasUsers && (
					<div className='df-row-ac'>
						<div style={{ color: '#5f6368', width: '40px', display: 'flex' }}>
							<StethoscopeIcon />
						</div>
						<div
							className='df-col'
							style={{ paddingTop: 6, paddingBottom: 6 }}>
							<div>
								{event.users.map(t => (
									<div key={t.id}>
										{t.identifier}
										{isLast(t, event.users) ? '' : ', '}
									</div>
								))}
							</div>
						</div>
					</div>
				)}
				{event.hasContact && (
					<div className='df-row-ac'>
						<div style={{ color: '#5f6368', width: '40px', display: 'flex' }}>
							<PhoneOutlinedIcon />
						</div>
						<div
							className='df-col'
							style={{ paddingTop: 6, paddingBottom: 6 }}>
							<div>{event.contact}</div>
						</div>
					</div>
				)}
				{event.hasComment && (
					<div
						className='df-row'
						style={{ paddingTop: 6 }}>
						<div style={{ color: '#5f6368', width: '40px', display: 'flex' }}>
							<SubjectOutlinedIcon />
						</div>
						<div
							className='df-col'
							style={{ paddingTop: 6, paddingBottom: 6 }}>
							<div style={{ whiteSpace: 'pre', marginTop: '-4px' }}>{event.description}</div>
						</div>
					</div>
				)}
				{createdByUser && (
					<div className='df-row-ac'>
						<div style={{ color: '#5f6368', width: '40px', display: 'flex' }}>
							<GroupAddOutlinedIcon />
						</div>
						<div
							className='df-col'
							style={{ paddingTop: 6, paddingBottom: 6 }}>
							<Typography
								variant='caption'
								style={{ marginBottom: -3, fontWeight: 'bold' }}>
								{strings.createdByUser}
							</Typography>
							<div>{createdByUser.identifier}</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
