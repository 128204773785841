import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteFreeSoloTextField } from 'framework/forms/FormAutocompleteFreeSoloTextField';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatchProductCatalogItemManufacturerAndModelRequest, IProductCatalogItem, productCatalogCommand_patchManufacturerAndModel } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';
import { ProductCatalogFilterDataContext } from '../context/ProductCatalogFilterDataContext';

interface IProps extends DialogProps {
	productCatalogItem: IProductCatalogItem;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchProductCatalogItemManufacturerAndModelRequest>({
		manufacturer: yup.string().required(strings.formRequired(strings.manufacturer)),
		model: yup.string().required(strings.formRequired(strings.model)),
		productCatalogItemId: yup.string().required(),
	});
};

export const HardwareDetailsForm = ({ productCatalogItem, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [data] = useCacheContext(ProductCatalogFilterDataContext);
	const [update, isSubmitting] = useFormSubmit(productCatalogCommand_patchManufacturerAndModel);

	const handleSubmit = async (
		values: IPatchProductCatalogItemManufacturerAndModelRequest,
		helpers: FormikHelpers<IPatchProductCatalogItemManufacturerAndModelRequest>
	) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.productUpdated);
			confirm();
		}
	};
	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IPatchProductCatalogItemManufacturerAndModelRequest>
			validateOnMount
			validationSchema={createSchema(strings)}
			initialValues={{
				productCatalogItemId: productCatalogItem.id,
				manufacturer: productCatalogItem.manufacturer ?? '',
				model: productCatalogItem.model ?? '',
			}}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{`${strings.manufacturer} & ${strings.model}`}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'
						style={{ overflow: 'visible' }}>
						<FormAutocompleteFreeSoloTextField<IPatchProductCatalogItemManufacturerAndModelRequest>
							name='manufacturer'
							label={strings.manufacturer}
							options={data.manufacturers}
							required
						/>
						<FormTextField<IPatchProductCatalogItemManufacturerAndModelRequest>
							name='model'
							label={strings.model}
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.update}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
