import React from 'react';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { formatDate } from 'framework/utils/date/formatDate';
import { INoahActionGroupDisplay } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	actionGroup: INoahActionGroupDisplay;
}

export const ActionGroupTooltipContent = ({ actionGroup }: IProps) => {
	const strings = useLocalization();

	return (
		<div
			className='df-col'
			style={{ padding: 24 }}>
			{actionGroup.actions.map(t => (
				<LabelledProperty
					key={t.index}
					label={formatDate(t.noahCreatedDate, 'date-and-time')}>
					<div className='df-col'>
						<div style={{ textDecoration: t.isHamsDeleted ? 'line-through' : 'inherit' }}>{t.description}</div>
						{t.references.map(t => (
							<LabelledProperty
								key={t.description}
								label={strings.basedOn}
								property={`${formatDate(t.noahCreatedDate, 'date-and-time')} ${t.manufacturer}`}
							/>
						))}
					</div>
				</LabelledProperty>
			))}
		</div>
	);
};
