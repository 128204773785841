import { Dialog, DialogContent, DialogProps, useTheme } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { QuantityField } from 'framework/components/textFields/QuantityField';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setLineErrors } from 'framework/forms/utils/setLineErrors';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { createHeader } from 'framework/table/createHeader';
import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { updateArray } from 'framework/utils/array/updateArray';
import { ISale, ISaleCreditNoteModel, salesCommand_newCreditNote, salesQuery_nextInvoiceReference, salesQuery_single } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import * as yup from 'yup';
import { ILine } from './ILine';
import { lineToModel } from './utils/lineToModel';
import { salesLineToLine } from './utils/salesLineToLine';

const createSchema = (strings: IStrings): yup.ObjectSchema<ISaleCreditNoteModel> => {
	return yup
		.object<ISaleCreditNoteModel>({
			invoiceId: yup.string().required(strings.formRequired(strings.invoice)),
			reference: yup.string().required(strings.formRequired(strings.reference)),
			isAutoSelectReference: yup.boolean().defined(),
			lines: yup.mixed(),
		})
		.defined();
};

const emptyValues: ISaleCreditNoteModel = {
	invoiceId: '',
	isAutoSelectReference: true,
	lines: [],
	reference: '',
};

const stepsRecord: Record<number, (keyof ISaleCreditNoteModel)[]> = {
	0: ['isAutoSelectReference', 'reference'],
	1: ['lines'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	invoiceId: string;
}

export const SaleCreditNoteForm = ({ confirm, cancel, invoiceId, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(salesCommand_newCreditNote);
	const [step, setStep] = useState<number>(0);
	const [sale] = useApiEffect(salesQuery_single, invoiceId);
	const [lines, setLines] = useState<ILine[]>([]);
	const [hasTouchedSubmit, setHasTouchedSubmit] = useState<boolean>(false);
	const schema = useMemo(() => createSchema(strings), [strings]);

	useEffect(() => {
		if (sale) {
			setLines(sale.lines.filter(t => t.quantity - t.creditedQuantity > 0).map<ILine>(t => salesLineToLine(t)));
		}
		// TODO set all lines
	}, [sale]);

	const handleSubmit = async (values: ISaleCreditNoteModel, helpers: FormikHelpers<ISaleCreditNoteModel>) => {
		// TODO validate here
		if (lines.length === 0 || lines.filter(t => t.isSelected).length === 0) {
			setStep(2);
			setHasTouchedSubmit(true);
		} else {
			const r = await create({ ...values, lines: lines.filter(t => t.isSelected).map((t, index) => lineToModel(t, index)) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify(strings.addedWhat(strings.creditNote));
				confirm(r.result.objectId);
			} else {
				setLineErrors('lines', r, lines, setLines);
			}
		}
	};

	if (sale === undefined) {
		return <div></div>;
	}

	return (
		<Formik<ISaleCreditNoteModel>
			validateOnMount
			initialValues={{ ...emptyValues, invoiceId: invoiceId }}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					open
					step={step}
					setStep={setStep}
					schema={schema}
					lines={lines}
					setLines={setLines}
					cancel={cancel}
					isSubmitting={isSubmitting}
					sale={sale}
					hasTouchedSubmit={hasTouchedSubmit}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	schema: yup.ObjectSchema<ISaleCreditNoteModel>;
	lines: ILine[];
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	sale: ISale;
	hasTouchedSubmit: boolean;
}

const InnerDialog = ({ step, setStep, schema, lines, setLines, cancel, isSubmitting, sale, hasTouchedSubmit, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const props = useFormikContext<ISaleCreditNoteModel>();
	useNextReferenceInterceptor(salesQuery_nextInvoiceReference, props);

	const headers = [
		createSelectionHeader<ILine>(lines, setLines),
		createHeader<ILine>(strings.name, t => <div className='fg1'>{t.salesLine.description}</div>),
		createHeader<ILine>(
			strings.amount,
			t => (
				<QuantityField
					value={t.quantity}
					setValue={val => setLines(updateArray(t, lines, { ...t, quantity: val }))}
					max={t.salesLine.quantity - t.salesLine.creditedQuantity}
					disabled={Boolean(t.salesLine.isInventoryItem || t.salesLine.assuredProductCatalogItemId)}
				/>
			),
			'center',
			{ paddingRight: 16 }
		),
	];

	return (
		<Dialog
			{...rest}
			scroll='paper'>
			<DialogTitleWithFormStepper
				title={`Creëer kredietnota van factuur ${sale.reference}`}
				step={step}
				labels={['Referentie', strings.items]}
			/>
			<DialogContent
				className='df-col'
				dividers
				style={{ padding: step === 1 ? '0px' : '8px 24px' }}>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<ISaleCreditNoteModel>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<ISaleCreditNoteModel>
							name='reference'
							label='Nummer kredietnota'
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<TableWithHeadersAndValues<ILine>
							padding={theme.spacing(1)}
							headers={headers}
							getKey={t => t.salesLine.id}
							values={lines}
							expandableContent={t => <CreditNoteWarningOrErrorContent line={t} />}
							expandAll
						/>
						{hasTouchedSubmit && lines.filter(t => t.isSelected === false).length === 0 && <ErrorLabelledProperty error='Selecteer minstens 1 lijn' />}
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};

const CreditNoteWarningOrErrorContent = ({ line }: { line: ILine }): JSX.Element => {
	return (
		<div
			className='df-col'
			style={{ marginLeft: '10px', marginTop: '-6px' }}>
			{line.quantity > line.salesLine.quantity - line.salesLine.creditedQuantity && (
				<ErrorLabelledProperty error={`Max ${line.salesLine.quantity - line.salesLine.creditedQuantity}`} />
			)}
		</div>
	);
};
