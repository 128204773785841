import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import React from 'react';
import { UsersCacheContext } from './UsersCacheContext';
import { IOption } from 'framework/IOption';

export interface IUserOption extends IOption<string> {
	isAudiologist: boolean;
}

export const useUsersAsFilterOptions = (): IUserOption[] => {
	const [usersCache] = usePreloadCacheContext(UsersCacheContext);
	return React.useMemo(
		() => usersCache.users.map<IUserOption>(t => ({ id: t.id, identifier: t.lastNameCommaFirstName ?? '', isAudiologist: t.isAudiologist })),
		[usersCache]
	);
};
