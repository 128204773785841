import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { IPatientDetail } from 'gen/ApiClient';
import React from 'react';
import { PatientDetailTabType } from './PatientDetailTabType';

export const createPatientDetailTabTypeEndIconRecord = (item: IPatientDetail): Record<PatientDetailTabType, React.ReactNode> => {
	return {
		annotationsAndFollowUp: item.countNonFollowUpAnnotations + item.countFollowUpAnnotations > 0 && (
			<BadgeChipX
				value={item.countNonFollowUpAnnotations + item.countFollowUpAnnotations}
				color='error'
			/>
		),
		noahData: undefined,
		overview: undefined,
		personalData: undefined,
		appointments: (
			<div className='df-row gap-4'>
				{item.countPastAppointments > 0 && (
					<BadgeChipX
						value={item.countPastAppointments}
						color='default'
					/>
				)}
				{item.countUpcomingAppointments > 0 && (
					<BadgeChipX
						value={item.countUpcomingAppointments}
						color='primary'
					/>
				)}
			</div>
		),
		documents: item.countDocuments > 0 && (
			<BadgeChipX
				value={item.countDocuments}
				color='default'
			/>
		),
		sales: item.countSales > 0 && (
			<BadgeChipX
				value={item.countSales}
				color='default'
			/>
		),
		workflows: (
			<div className='df-row gap-4'>
				{item.countWorkflows > 0 && (
					<BadgeChipX
						value={item.countWorkflows}
						color='default'
					/>
				)}
				{item.countActiveWorkflows > 0 && (
					<BadgeChipX
						value={item.countActiveWorkflows}
						color='primary'
					/>
				)}
				{item.countWorkflowsWithWarning > 0 && (
					<BadgeChipX
						value={item.countWorkflowsWithWarning}
						color='warning'
					/>
				)}
				{item.countWorkflowsWithError > 0 && (
					<BadgeChipX
						value={item.countWorkflowsWithError}
						color='error'
					/>
				)}
			</div>
		),
		tenders: item.countTenders > 0 && (
			<BadgeChipX
				value={item.countTenders}
				color='default'
			/>
		),
	};
};
