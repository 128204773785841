import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ILocationSummary, IStockItemDto } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { boolToYesNo } from 'shared/utils/boolToString';
import { StockItemDtoSelectableColumn } from '../StockItemDtoSelectableColumn';

export const createHeaders = (
	colRecord: Record<StockItemDtoSelectableColumn | string, string>,
	strings: IStrings,
	locations: ILocationSummary[]
): Record<StockItemDtoSelectableColumn | string, IHeader<IStockItemDto>> => {
	const xRecord = createHeadersFromSelectableColumn(colRecord, strings);
	const xHeaders = Object.keys(xRecord).map(t => xRecord[t as StockItemDtoSelectableColumn]);
	const yHeaders = locations.map(location =>
		createHeaderWithKey<IStockItemDto, string>(
			colRecord,
			location.id,
			t => (t.stockAtLocations.find(x => x.id === location.id)?.count ?? 0).toString(),
			'center'
		)
	);
	const headers = [...xHeaders, ...yHeaders];
	return headers.reduce((acc, item) => ({ ...acc, [item['key']]: item }), {} as Record<string, IHeader<IStockItemDto>>);
};

const createHeadersFromSelectableColumn = (
	colRecord: Record<StockItemDtoSelectableColumn, string>,
	strings: IStrings
): Record<StockItemDtoSelectableColumn, IHeader<IStockItemDto>> => {
	return {
		manufacturer: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(colRecord, 'manufacturer', s => s.manufacturer),
		model: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(colRecord, 'model', s => s.model),
		physicalQuantity: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(colRecord, 'physicalQuantity', t =>
			t.physicalQuantity.toString()
		),
		financialQuantity: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(colRecord, 'financialQuantity', t =>
			t.financialQuantity.toString()
		),
		countPurchased: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(colRecord, 'countPurchased', t => t.countPurchased.toString()),
		countSold: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(colRecord, 'countSold', t => t.countSold.toString()),
		countInRepair: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(colRecord, 'countInRepair', t => t.countInRepair.toString()),
		countInBackOrder: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(colRecord, 'countInBackOrder', t =>
			t.countInBackOrder.toString()
		),
		category: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(colRecord, 'category', s => s.category),
		subcategory: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(colRecord, 'subcategory', s => s.subcategory),
		manufacturerCommaModel: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(
			colRecord,
			'manufacturerCommaModel',
			s => s.manufacturerCommaModel
		),
		hasSerialNumber: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(colRecord, 'hasSerialNumber', s =>
			boolToYesNo(s.hasSerialNumber, strings)
		),
		internalReference: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(colRecord, 'internalReference', s => s.internalReference),
		manufacturerReferencesCsv: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(
			colRecord,
			'manufacturerReferencesCsv',
			s => s.manufacturerReferencesCsv
		),
		supplierIdentifierCsv: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(
			colRecord,
			'supplierIdentifierCsv',
			s => s.supplierIdentifierCsv
		),
		stockValue: createSortableHeaderWithKey<IStockItemDto, StockItemDtoSelectableColumn>(colRecord, 'stockValue', s => formatCurrency(s.stockValue)),
	};
};
