import { Card, CardMedia, CardProps, Divider, useTheme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { combineClassNames } from 'framework/utils/combineClassNames';
import { createType } from 'framework/utils/createType';
import {
	ICustomerAccount,
	ISettlementProposal,
	ISettlementProposalsForCustomerAccountFilterParams,
	ISettlementProposalsForCustomerAccountQueryParams,
	SettlementProposalExportProp,
	SettlementProposalExportProps,
	settlementProposalsQuery_exportForCustomerAccount,
	settlementProposalsQuery_queryForCustomerAccount,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsHeader } from 'shared/table/createDetailsHeader';
import { SettlementProposalDialog } from '../../../settlementProposals/sps/detail/SettlementProposalDialog';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { PrependColumn } from './PrependColumn';
import { SelectableColumn } from './SelectableColumn';

const DefaultParams: ISettlementProposalsForCustomerAccountQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	isHandled: undefined,
	paymentStatuses: [],
};

const QuickFilters = createType('all', 'unhandled');
export type QuickFilter = typeof QuickFilters[number];

interface IProps extends IReloadTriggerProps, CardProps {
	customerAccount: ICustomerAccount;
	reload: VoidFunction;
	onSps: VoidFunction;
}

export const SettlementProposalsCard = ({ onSps, customerAccount, reload, reloadTrigger, ...rest }: IProps) => {
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(
		pars => settlementProposalsQuery_queryForCustomerAccount(customerAccount.id, pars),
		DefaultParams,
		reloadTrigger
	);
	const theme = useTheme();
	const { columns, setColumns, columnsRecord, headers, quickFilter, setQuickFilter, quickFilterRecord, exportRecord } = useContext(ViewContext);
	const { open, confirm } = useDialogsContext(reload);

	useEffect(() => {
		setParams(params =>
			onResetToPage1({
				...params,
				isHandled: quickFilter === 'unhandled' ? false : undefined,
			})
		);
		// eslint-disable-next-line
	}, [quickFilter]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onViewDetail = (id: string) => {
		open(
			<SettlementProposalDialog
				open
				close={confirm}
				id={id}
			/>
		);
	};

	return (
		<Card
			{...rest}
			className={combineClassNames('fg1', rest.className)}>
			<div className='df-row-ac jc-sb'>
				<CardTitleTypography
					withPadding
					title={strings.settlementProposals}
				/>
				<SmallPrimaryTextButton
					startIcon={<HelpOutlineIcon />}
					style={{ marginRight: theme.spacing(1.5) }}
					onClick={onSps}
					disabled={customerAccount.hasOutstandingBalance === false}>
					{strings.newWhat(strings.settlementProposal)}
				</SmallPrimaryTextButton>
			</div>
			<Divider />
			{queryResult.unfilteredCount > 0 && (
				<>
					<div
						className='df-row-ac jc-sb'
						style={{ padding: '2px', paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}>
						<ButtonGroupWithSelection<QuickFilter>
							options={QuickFilters}
							selected={quickFilter}
							setSelected={setQuickFilter}
							renderOption={t => quickFilterRecord[t]}
						/>
						<div>
							<ExportButtonMenuAndDialog<ISettlementProposalsForCustomerAccountFilterParams, SettlementProposalExportProp>
								params={params}
								filteredCount={queryResult.filteredCount}
								unfilteredCount={queryResult.unfilteredCount}
								allProps={SettlementProposalExportProps}
								downloadF={(...pars) => settlementProposalsQuery_exportForCustomerAccount(customerAccount.id, ...pars)}
								fileName='Afrekeningsvoorstellen_lijnen'
								record={exportRecord}
								view='settlementProposals'
							/>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
								view='settlementProposals'
							/>
						</div>
					</div>
					<Divider />
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchVerb}
						style={{ width: '400px', marginRight: theme.spacing(1) }}
						variant='embedded'
					/>
					<Divider />
					<div className='df-row'>
						<Filter
							params={params}
							onFilter={params => setParams(onResetToPage1(params))}
							paddingLeft={theme.spacing(1.5)}
						/>
					</div>
					<Divider />
					<CardMedia>
						<PageableTableWithColumnSelection<ISettlementProposal, SelectableColumn>
							queryResult={queryResult}
							headers={headers}
							sortParams={params.sortParams}
							pageParams={params.pageParams}
							setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
							getKey={inst => inst.id}
							selectedColumns={columns}
							prependColumn={PrependColumn}
							appendColumn={createDetailsHeader<ISettlementProposal>(t => onViewDetail(t.id))}
						/>
					</CardMedia>
				</>
			)}
			{queryResult.unfilteredCount === 0 && (
				<NoRecordsFound style={{ padding: theme.spacing(1.5) }}>{strings.noWhatRegistered(strings.settlementProposals)}</NoRecordsFound>
			)}
		</Card>
	);
};
