import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import { ISalesLine } from 'gen/ApiClient';
import React from 'react';
import ReplayIcon from '@material-ui/icons/Replay';

interface IProps {
	item: ISalesLine;
	style?: React.CSSProperties;
}

export const SalesLineStatusIcon = ({ item, style }: IProps) => {
	if (item.isFullyCredited || item.isPartlyCredited) {
		return <ReplayIcon style={style} />;
	} else {
		return <BlurCircularIcon style={style} />;
	}
};
