import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import React from 'react';
import { CollapseXManaged } from './CollapseXManaged';
import { ICollapseXProps } from './ICollapseXProps';

interface IProps extends ICollapseXProps {
	expandByDefault?: boolean;
}

export const CollapseX = ({ expandByDefault, ...rest }: IProps) => {
	const [isExpanded, toggleIsExpanded] = useStateToggleBoolean(expandByDefault ?? false);

	return (
		<CollapseXManaged
			isExpanded={isExpanded}
			toggleIsExpanded={toggleIsExpanded}
			{...rest}
		/>
	);
};
