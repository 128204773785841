import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { deliveriesQuery_concurrencyToken, deliveriesQuery_query, IDeliveriesQueryParams, IDelivery, IQueryResult } from 'gen/ApiClient';
import { deliveriesQueryParamsLsKey } from '../../../../../localStorageKeys';
import { DeliveriesQueryContext } from './DeliveriesQueryContext';

const DefaultParams: IDeliveriesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	deliveredAfter: undefined,
	deliveredBefore: undefined,
	supplierIds: [],
};

export const DeliveriesQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IDelivery>, IDeliveriesQueryParams>
			defaultParams={DefaultParams}
			queryF={deliveriesQuery_query}
			concurrencyF={deliveriesQuery_concurrencyToken}
			localStorageKey={deliveriesQueryParamsLsKey}
			context={DeliveriesQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
