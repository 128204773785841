import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { IOrderV2, OrderProcessStatus } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	item: IOrderV2;
	color: ColorX;
	style?: React.CSSProperties;
	onClick?: VoidFunction;
}

export const OrderStatusChip = ({ item, color, style, onClick }: IProps) => {
	const { orderStatusRecord } = useContext(RecordContext);

	return (
		<ChipX
			label={orderStatusRecord[item.status as OrderProcessStatus]}
			color={color}
			style={style}
			onClick={onClick}
		/>
	);
};
