import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { ISalesQueryMetadata, SalesPrefilter } from 'gen/ApiClient';
import React from 'react';

export const createSalesPrefilterBadgeChipXRecord = (metadata: ISalesQueryMetadata): Record<SalesPrefilter, React.ReactNode> => {
	return {
		All: undefined,
		Overdue: metadata.countNotPaidAndOverdue > 0 && (
			<BadgeChipX
				value={metadata.countNotPaidAndOverdue}
				color='warning'
			/>
		),
		NotPaid: metadata.countNotPaid > 0 && (
			<BadgeChipX
				value={metadata.countNotPaid}
				color='primary'
			/>
		),
	};
};
