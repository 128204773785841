import { formatDateX } from 'framework/utils/date/formatDateX';
import { ICalendarEventDto } from 'gen/ApiClient';

export const formatEventDates = (event: ICalendarEventDto) => {
	const isStartAndEndOnSameDay = formatDateX(event.startDate) === formatDateX(event.endDate);
	if (event.isAllDay && isStartAndEndOnSameDay) {
		return formatDateX(event.startDate);
	} else if (event.isAllDay) {
		return `${formatDateX(event.startDate)} - ${formatDateX(event.endDate)}`;
	} else if (isStartAndEndOnSameDay) {
		return `${formatDateX(event.startDate, 'dd/MM/yyyy HH:mm')} - ${formatDateX(event.endDate, 'HH:mm')}`;
	} else {
		return `${formatDateX(event.startDate, 'dd/MM/yyyy HH:mm')} - ${formatDateX(event.endDate, 'dd/MM/yyyy HH:mm')}`;
	}
};
