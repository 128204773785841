import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	title: string;
	confirm: (mode: Mode) => void;
	cancel: VoidFunction;
}

type Mode = 'scan' | 'upload';

export const SelectScanOrUploadDialog = ({ title, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [mode, setMode] = useState<Mode>('upload');

	return (
		<Dialog
			fullWidth
			maxWidth='sm'
			{...rest}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<Mode>
					selected={mode}
					setSelected={setMode}
					options={[
						{ value: 'upload', label: strings.select, caption: 'Selecteer het bestand van de computer' },
						{ value: 'scan', label: strings.scan, caption: 'Gebruik de scanner' },
					]}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				onSubmit={() => confirm(mode)}
				submitText={strings.select}
				isSubmitting={false}
			/>
		</Dialog>
	);
};
