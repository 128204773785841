import { Dialog, DialogContent, DialogProps, Typography, useTheme } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormMaskedTextField } from 'framework/forms/FormMaskedTextField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatient, IPatientPersonalInformationModel, patientsCommand_updatePersonalInformation } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormGenderSelectField } from 'shared/forms/FormGenderSelectField';
import { SocialSecurityNumberMask } from './SocialSecurityNumberMask';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { isValidBelgianSocialSecurityNumber } from 'framework/utils/isValidBelgianSocialSecurityNumber';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPatientPersonalInformationModel>({
			salutation: yup.string(),
			firstName: yup.string().required(strings.formRequired(strings.firstName)),
			lastName: yup.string().required(strings.formRequired(strings.lastName)),
			socialSecurityNumber: yup
				.string()
				.test('valid', strings.invalidSocialSecurityNumber, t => (isNullOrUndefined(t) ? true : isValidBelgianSocialSecurityNumber(t ?? ''))),
			gender: yup.string().required(strings.formRequired(strings.gender)),
			dateOfBirth: yup.date(),
			nickName: yup.string(),
		})
		.defined();
};

interface IProps extends DialogProps {
	patient: IPatient;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

const toModel = (patient: IPatient): IPatientPersonalInformationModel => {
	return {
		salutation: patient.salutation,
		firstName: patient.firstName,
		lastName: patient.lastName,
		socialSecurityNumber: patient.formattedSocialSecurityNumber,
		gender: patient.gender,
		dateOfBirth: patient.dateOfBirth ?? (undefined as any),
		nickName: patient.nickName ?? '',
	};
};

const stepsRecord: Record<number, (keyof IPatientPersonalInformationModel)[]> = {
	0: ['salutation', 'firstName', 'lastName', 'nickName'],
	1: ['gender', 'socialSecurityNumber', 'dateOfBirth'],
};

export const PatientPersonalInformationForm = ({ patient, confirm, cancel, ...rest }: IProps) => {
	const [step, setStep] = useState<number>(0);
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(patientsCommand_updatePersonalInformation);
	const strings = useLocalization();
	const theme = useTheme();

	const handleSubmit = async (values: IPatientPersonalInformationModel, helpers: FormikHelpers<IPatientPersonalInformationModel>) => {
		const r = await update(patient.id, values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.patientUpdated);
			confirm();
		}
	};

	return (
		<Formik<IPatientPersonalInformationModel>
			validateOnMount
			initialValues={toModel(patient)}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog {...rest}>
					<DialogTitleWithFormStepper
						title={strings.personalInformationData}
						step={step}
						labels={[strings.name, strings.details]}
					/>
					<DialogContent
						dividers
						className='df-col'>
						{patient.hasEidData && (
							<Typography
								className='df-row-afs'
								variant='caption'
								style={{ color: theme.palette.warning.main, marginBottom: '8px' }}>
								<WarningIcon style={{ marginRight: '8px' }} />
								<div style={{ whiteSpace: 'pre' }}>{strings.patientCannotChangeIfeIDRead}</div>
							</Typography>
						)}
						{step === 0 && (
							<>
								<FormTextField<IPatientPersonalInformationModel>
									name='salutation'
									label={strings.salutation}
								/>
								<FormTextField<IPatientPersonalInformationModel>
									name='firstName'
									label={strings.firstName}
									required
									disabled={patient.hasEidData}
								/>
								<FormTextField<IPatientPersonalInformationModel>
									name='lastName'
									label={strings.lastName}
									required
									disabled={patient.hasEidData}
								/>
								<FormTextField<IPatientPersonalInformationModel>
									name='nickName'
									label={strings.nickName}
								/>
							</>
						)}
						{step === 1 && (
							<>
								<FormGenderSelectField
									name='gender'
									label={strings.gender}
									required
									disabled={patient.hasEidData}
								/>
								<FormDatePicker<IPatientPersonalInformationModel>
									name='dateOfBirth'
									label={strings.dateOfBirth}
									disabled={patient.hasEidData}
								/>
								<FormMaskedTextField<IPatientPersonalInformationModel>
									name='socialSecurityNumber'
									label={strings.socialSecurityNumber}
									mask={SocialSecurityNumberMask}
									disabled={patient.hasEidData}
								/>
							</>
						)}
					</DialogContent>
					<PageableFormDialogActions
						cancel={cancel}
						setStep={setStep}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
						isSubmitting={isSubmitting}
						submitText={strings.update}
						step={step}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
