import { IStrings } from 'localization/IStrings';
import { WorkflowFilterTabType } from './WorkflowFilterTabType';

export const createWorkflowFilterTabTypTitleRecord = (strings: IStrings): Record<WorkflowFilterTabType, string> => {
	return {
		active: strings.active,
		all: strings.all,
		error: strings.error,
		warning: strings.warning,
	};
};
