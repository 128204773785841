import { Backdrop, Paper, Slide, useTheme } from '@material-ui/core';
import { ICommonParams as IInventoryItemsCommonParams } from 'app/main/inventoryManagement/inventoryItems/search/ICommonParams';
import { SearchInventoryItem } from 'app/main/inventoryManagement/inventoryItems/search/SearchInventoryItem';
import { SearchInventoryItemForProductCatalog } from 'app/main/inventoryManagement/inventoryItems/search/SearchInventoryItemForProductCatalog';
import { FilterProductCatalogType } from 'app/main/inventoryManagement/productCatalog/search/FilterProductCatalogType';
import { ICommonParams as IProductCatalogCommonParams } from 'app/main/inventoryManagement/productCatalog/search/ICommonParams';
import { SearchProductCatalog } from 'app/main/inventoryManagement/productCatalog/search/SearchProductCatalog';
import { Try } from 'framework/Try';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import {
	IInventoryItem,
	IProductCatalogItem,
	IQueryResult,
	IStockAtLocation,
	inventoryItemsQuery_singleWithProductCatalogItem,
	productCatalogQuery_singleWithStockAtLocations,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { SwipeableViewsWithTabs } from '../swipeableViews/SwipeableViewsWithTabs';
import { TabPanel } from '../swipeableViews/TabPanel';
import { ISearchAndSelectLine } from './ISearchAndSelectLine';

interface IProps<TLine extends ISearchAndSelectLine, TIIParams extends IInventoryItemsCommonParams, TPcParams extends IProductCatalogCommonParams> {
	lines: TLine[];
	wizardContent?: JSX.Element;
	canSelectInventoryItemF: (item: IInventoryItem) => boolean;
	iiParams: TIIParams;
	setIIParams: React.Dispatch<React.SetStateAction<TIIParams>>;
	iiQueryResult: IQueryResult<IInventoryItem> | undefined;
	pcParams: TPcParams;
	setPcParams: React.Dispatch<React.SetStateAction<TPcParams>>;
	pcQueryResult: IQueryResult<IProductCatalogItem> | undefined;
	queryInventoryItemF: (params: TIIParams) => Promise<Try<IQueryResult<IInventoryItem>>>;
	defaultIIParams: TIIParams;
	onAddPc: (item: IProductCatalogItem, stockAtLocations: IStockAtLocation[]) => void;
	onAddAssurance?: (item: IProductCatalogItem) => void;
	// onAddAssuredLine?: (item: IProductCatalogItem, line: TLine) => void;
	onAddII: (item: IInventoryItem, pc: IProductCatalogItem) => void;
	productCatalogFilterType: FilterProductCatalogType;
	disableSelectInventoryItem?: boolean;
	onCreditPc?: (item: IProductCatalogItem, stockAtLocations: IStockAtLocation[]) => void;
	onCreditIi?: (item: IInventoryItem, pc: IProductCatalogItem) => void;
}

export const SearchWithTabsContainer = <
	TLine extends ISearchAndSelectLine,
	TIIParams extends IInventoryItemsCommonParams,
	TPcParams extends IProductCatalogCommonParams
>({
	lines,
	wizardContent,
	canSelectInventoryItemF,
	iiParams,
	setIIParams,
	iiQueryResult,
	pcParams,
	setPcParams,
	pcQueryResult,
	queryInventoryItemF,
	defaultIIParams,
	onAddII,
	onAddPc,
	productCatalogFilterType,
	disableSelectInventoryItem = false,
	onAddAssurance,
	onCreditPc,
	onCreditIi,
}: IProps<TLine, TIIParams, TPcParams>) => {
	const strings = useLocalization();
	const [tab, setTab] = useState<number>(0);
	const [isDrawerOpen, toggleDrawer] = useStateToggleBoolean(false);
	const theme = useTheme();
	const [pc, setPc] = useState<IProductCatalogItem>();
	const loadProductCatalog = useApiCall(productCatalogQuery_singleWithStockAtLocations);
	const loadInventoryItem = useApiCall(inventoryItemsQuery_singleWithProductCatalogItem);

	const onAddProductCatalogItem = async (item: IProductCatalogItem) => {
		const r = await loadProductCatalog(item.id);
		if (r.isSuccess) {
			item = r.result.item;
			if (item.isUniquelyIdentifiable && disableSelectInventoryItem !== true) {
				setPc(item);
				toggleDrawer();
			} else if (item.isInsuranceOrProlongedWarranty && onAddAssurance !== undefined) {
				onAddAssurance(item);
			} else {
				onAddPc(r.result.item, r.result.stockAtLocations);
			}
		}
	};

	const onCreditProductCatalogItem = async (item: IProductCatalogItem) => {
		const r = await loadProductCatalog(item.id);
		if (r.isSuccess) {
			item = r.result.item;
			if (onCreditPc !== undefined && item.isUniquelyIdentifiable === false && item.isInsuranceOrProlongedWarranty === false) {
				onCreditPc(item, r.result.stockAtLocations);
			} else {
				// no-op
			}
		}
	};

	const onAddOrCreditInventoryItem = async (item: IInventoryItem, isCredit: boolean) => {
		const r = await loadInventoryItem(item.id);
		if (r.isSuccess) {
			if (isCredit && onCreditIi) {
				onCreditIi(r.result.inventoryItem, r.result.productCatalogItem);
			} else {
				onAddII(r.result.inventoryItem, r.result.productCatalogItem);
			}
		}
	};

	return (
		<>
			<Slide
				direction='right'
				in={isDrawerOpen}>
				<Paper
					elevation={10}
					style={{ width: '624px', height: '100%', top: 0, left: 0, zIndex: 4, position: 'absolute', backgroundColor: theme.palette.background.paper }}>
					<>
						<CloseButtonOnAbsolutePosition onClick={toggleDrawer} />
						{pc && (
							<SearchInventoryItemForProductCatalog
								canSelectF={canSelectInventoryItemF}
								defaultParams={defaultIIParams}
								queryF={queryInventoryItemF}
								item={pc}
								onAdd={item => {
									onAddOrCreditInventoryItem(item, false);
									toggleDrawer();
								}}
								selectedIds={lines.filter(t => t.inventoryItemId !== undefined).map(t => t.inventoryItemId) as string[]}
							/>
						)}
					</>
				</Paper>
			</Slide>
			<Backdrop
				open={isDrawerOpen}
				style={{ zIndex: 3, opacity: 0.5 }}
			/>
			<SwipeableViewsWithTabs
				tab={tab}
				setTab={setTab}
				textColor='secondary'
				indicatorColor='secondary'
				tabs={
					wizardContent
						? [strings.fromProductCatalog, strings.searchOnSerialNumber, strings.wizardAndHelp]
						: [strings.fromProductCatalog, strings.searchOnSerialNumber]
				}
				style={{ width: '500px', marginTop: '-10px', overflow: 'auto', height: '100%', marginBottom: 3 }}>
				<TabPanel
					value={tab}
					index={0}>
					<SearchProductCatalog
						params={pcParams}
						setParams={setPcParams}
						queryResult={pcQueryResult}
						onAdd={onAddProductCatalogItem}
						style={{ width: '500px' }}
						filterType={productCatalogFilterType}
						onCredit={onCreditPc !== undefined ? onCreditProductCatalogItem : undefined}
						canCreditF={item => onCreditPc !== undefined && item.isUniquelyIdentifiable === false && item.isInsuranceOrProlongedWarranty === false}
					/>
				</TabPanel>
				<TabPanel
					value={tab}
					index={1}>
					<SearchInventoryItem
						params={iiParams}
						setParams={setIIParams}
						queryResult={iiQueryResult}
						onAdd={item => onAddOrCreditInventoryItem(item, false)}
						onCredit={onCreditIi !== undefined ? item => onAddOrCreditInventoryItem(item, true) : undefined}
						style={{ width: '500px' }}
						canSelectF={canSelectInventoryItemF}
						canCreditF={onCreditIi !== undefined ? item => item.isSold === true : undefined}
						selectedIds={lines.filter(t => t.inventoryItemId !== undefined).map(t => t.inventoryItemId) as string[]}
					/>
				</TabPanel>
				{wizardContent && (
					<TabPanel
						value={tab}
						index={2}>
						{wizardContent}
					</TabPanel>
				)}
			</SwipeableViewsWithTabs>
		</>
	);
};
