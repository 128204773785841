import { Typography } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { FormPasswordField } from 'framework/forms/FormPasswordField';
import { FormTextField } from 'framework/forms/FormTextField';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { auth_login, auth_logout, ILoginRequest } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import * as routes from 'routes';
import { AuthContext } from './AuthContext';
import { EmptyPageWithLogo } from './EmptyPageWithLogo';
import { useHandleLogin } from './useHandleLogin';

const createSchema = (strings: IStrings) => {
	return yup.object<ILoginRequest>({
		email: yup.string().email().required(strings.formRequired(strings.email)),
		password: yup.string().required(strings.formRequired(strings.password)),
	});
};

export const Login = () => {
	const authContext = useContext(AuthContext);
	const history = useHistory();
	const [login, isSubmitting, setSubmitting] = useFormSubmit(auth_login);
	// const [externalLogin, isExternalSubmitting] = useFormSubmit(auth_external);
	const [logout] = useFormSubmit(auth_logout, setSubmitting);
	const strings = useLocalization();
	// const notify = useSnackbarNotify();
	const handleLogin = useHandleLogin();

	useEffect(() => {
		const x = () => {
			logout();
			authContext.logout();
		};
		x();
		// only happens at mount of this screen
		// eslint-disable-next-line
	}, []);

	const handleSubmit = async (values: ILoginRequest, helpers: FormikHelpers<ILoginRequest>) => {
		const r = await login(values);
		if (r.isSuccess) {
			if (r.result.hasError) {
				if (r.result.errorEmail) {
					helpers.setFieldError('email', r.result.errorEmail);
				}
				if (r.result.errorPassword) {
					helpers.setFieldError('password', r.result.errorPassword);
				}
			} else {
				handleLogin(r.result);
			}
		}
	};

	// const responseGoogle = async (response: any) => {
	//     if (response && response.tokenId) {
	//         const r = await externalLogin({ tokenId: response.tokenId });
	//         if (r.isSuccess) {
	//             if (r.result.hasError) {
	//                 notify(r.result.error ?? '')
	//             } else {
	//                 onSuccess(r.result);
	//             }
	//         }
	//     }
	// }

	return (
		<EmptyPageWithLogo title={strings.loginHeaderText}>
			<Formik<ILoginRequest>
				validateOnMount
				initialValues={{ email: '', password: '' }}
				validationSchema={createSchema(strings)}
				onSubmit={handleSubmit}>
				{props => (
					<Form
						autoComplete='off'
						className='df-col'
						style={{ minWidth: '400px' }}>
						<FormTextField<ILoginRequest>
							name='email'
							label={strings.email}
							required
						/>
						<FormPasswordField<ILoginRequest>
							name='password'
							label={strings.password}
							required
						/>
						<Typography
							onClick={() => history.push(routes.ForgotPasswordRoute)}
							className='forgot-password-button'
							variant='caption'
							component='div'
							style={{ textAlign: 'right', cursor: 'pointer', fontWeight: 'bold' }}
							color='primary'>
							{strings.forgotPasswordQuestion}
						</Typography>
						<LoaderButton
							style={{ marginTop: '20px' }}
							size='large'
							variant='contained'
							color='primary'
							type='submit'
							isLoading={isSubmitting}
							disabled={isSubmitting || !props.isValid}>
							{strings.login}
						</LoaderButton>
						{/* <GoogleLogin
                            clientId="766799473028-egnu9g2bd3uqtht8khlour7v7itgcsta.apps.googleusercontent.com"
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            render={props => (
                                <LoaderButton {...props} style={{ marginTop: '20px' }} size="large" variant="outlined" isLoading={isExternalSubmitting} disabled={isExternalSubmitting} color="primary">
                                    <div className="df-row-ac">
                                        <GoogleIcon fontSize="small" style={{ marginRight: '10px' }} />
                                        <span>Google</span>
                                    </div>
                                </LoaderButton>
                            )}
                        /> */}
					</Form>
				)}
			</Formik>
		</EmptyPageWithLogo>
	);
};
