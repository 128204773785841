import React, { useState } from 'react';
import { ILoanLine, IRequestForInsuranceAllowance } from 'gen/ApiClient';
import { ILoanLineWithSelection } from '../../../../../loans/ILoanLineWithSelection';
import { IHandoverLine } from '../../IHandoverLine';
import { loanLineToLine } from '../../utils/loanLineToLine';
import { useMapLine } from '../../utils/useMapLine';
import { SelectLinesFromLoanForm } from './SelectLinesFromLoanForm';
import { SelectLoanSummaryForm } from './SelectLoanSummaryForm';

interface IProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: (lines: IHandoverLine[]) => void;
	cancel: VoidFunction;
}

export const SelectFromLoanForm = ({ rfia, confirm, cancel }: IProps) => {
	const [loanId, setLoanId] = useState<string | undefined>(rfia.loanSummaries.length === 1 ? rfia.loanSummaries[0].id : undefined);
	const mapper = useMapLine<ILoanLine>();

	const onConfirm = async (lines: ILoanLineWithSelection[]): Promise<void> => {
		const mapped = await Promise.all(lines.filter(t => t.isSelected).map(t => mapper(t, (t, line) => loanLineToLine(t, line))));
		confirm(mapped);
	};

	if (loanId === undefined) {
		return (
			<SelectLoanSummaryForm
				open
				rfia={rfia}
				confirm={setLoanId}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<SelectLinesFromLoanForm
				open
				trialId={loanId}
				confirm={onConfirm}
				cancel={cancel}
			/>
		);
	}
};
