import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { inventoryItemsQuery_single, IReturnFromInventoryItemModel, returnsCommand_new_inventoryItem, returnsQuery_nextReference } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { SuppliersCacheContext } from '../../../../contacts/suppliers/SuppliersCacheContext';
import { FormSelectOrCreateSupplierField } from '../../../../contacts/suppliers/forms/FormSelectOrCreateSupplierField';
import { LocationsPreloadCacheContext } from '../../../../settings/locations/LocationsPreloadCacheContext';
import { SearchProvider } from './context/SearchProvider';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IReturnFromInventoryItemModel>({
			returnDate: yup.date().required(strings.formRequired('retourdatum')),
			locationId: yup.string().required(strings.formRequired(strings.location)),
			supplierId: yup.string().required(strings.formRequired(strings.supplier)),
			reference: yup.string().required(),
			isAutoSelectReference: yup.boolean().defined(),
			inventoryItemId: yup.string().required(),
		})
		.defined();
};

const EmptyValues: IReturnFromInventoryItemModel = {
	returnDate: undefined as any,
	locationId: undefined as any,
	supplierId: undefined as any,
	reference: '',
	isAutoSelectReference: true,
	inventoryItemId: '',
};

const stepsRecord: Record<number, (keyof IReturnFromInventoryItemModel)[]> = {
	0: ['reference', 'isAutoSelectReference'],
	1: ['locationId', 'supplierId'],
	2: ['returnDate'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	inventoryItemId: string;
}

export const ReturnFromInventoryItemForm = ({ confirm, cancel, inventoryItemId, ...rest }: IProps) => {
	const strings = useLocalization();
	const [step, setStep] = useState<number>(0);
	const [create, isSubmitting] = useFormSubmit(returnsCommand_new_inventoryItem);
	const notify = useSnackbarNotify();
	const [inventoryItem] = useApiEffect(inventoryItemsQuery_single, inventoryItemId);
	const now = useNow();

	const handleSubmit = async (values: IReturnFromInventoryItemModel, helpers: FormikHelpers<IReturnFromInventoryItemModel>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep, e => notify(e, 'error'))) {
			notify(strings.addedWhat(strings.return));
			confirm(r.result.objectId);
		}
	};

	if (inventoryItem === undefined || now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IReturnFromInventoryItemModel>
			validateOnMount
			initialValues={{
				...EmptyValues,
				inventoryItemId: inventoryItemId,
				supplierId: inventoryItem.supplierId ?? '',
				locationId: inventoryItem.currentLocationId ?? '',
				returnDate: now,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						step={step}
						setStep={setStep}
						stepsRecord={stepsRecord}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof IReturnFromInventoryItemModel)[]>;
}

const InnerDialog = ({ cancel, isSubmitting, step, setStep, stepsRecord, ...rest }: IInnerProps) => {
	const props = useFormikContext<IReturnFromInventoryItemModel>();
	const strings = useLocalization();
	const [suppliers] = usePreloadCacheContext(SuppliersCacheContext);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	useNextReferenceInterceptor(returnsQuery_nextReference, props);
	const { isStacked } = useDialogsContext();

	useEffect(() => {
		if (suppliers?.length === 1) {
			setFormValue<IReturnFromInventoryItemModel>('supplierId', suppliers[0].id, props);
		}
		// eslint-disable-next-line
	}, [suppliers]);

	useEffect(() => {
		if (locations?.length === 1) {
			setFormValue<IReturnFromInventoryItemModel>('locationId', locations[0].id, props);
		}
		// eslint-disable-next-line
	}, [locations]);

	if (suppliers === undefined || locations === undefined) {
		return <div></div>;
	}

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}>
			<DialogTitleWithFormStepper
				title={strings.newWhat(strings.return)}
				step={step}
				labels={['Ref.', strings.fromTo, strings.date]}
			/>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IReturnFromInventoryItemModel>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IReturnFromInventoryItemModel>
							name='reference'
							label={strings.reference}
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectFieldWithOption<IReturnFromInventoryItemModel, string>
							name='locationId'
							options={locations}
							label={strings.location}
						/>
						<FormSelectOrCreateSupplierField<IReturnFromInventoryItemModel>
							name='supplierId'
							label={strings.supplier}
						/>
					</>
				)}
				{step === 2 && (
					<FormDatePicker<IReturnFromInventoryItemModel>
						name='returnDate'
						label={strings.returnDate}
						required
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
