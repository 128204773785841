import React from 'react';
import { CacheProvider } from 'framework/context/CacheProvider';
import { ISettlementProposalFilterData, settlementProposalsQuery_concurrencyToken, settlementProposalsQuery_filterData } from 'gen/ApiClient';
import { FilterDataContext } from './FilterDataContext';

export const FilterDataProvider = ({ children }: any) => {
	return (
		<CacheProvider<ISettlementProposalFilterData>
			concurrencyF={settlementProposalsQuery_concurrencyToken}
			loadF={settlementProposalsQuery_filterData}
			context={FilterDataContext}>
			{children}
		</CacheProvider>
	);
};
