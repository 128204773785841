import { DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Try } from 'framework/Try';
import { ICreateResponse, IDocumentFromTemplateModel, LinkViewContextType, templatesQuery_byContext } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useDocumentEditor } from 'shared/dialogs/useDocumentEditor';
import { SelectTemplateInnerDialog } from './SelectTemplateInnerDialog';

interface IModel {
	id: string;
}

const createSchema = (strings: IStrings): yup.ObjectSchema<IModel> => {
	return yup
		.object<IModel>({
			id: yup.string().required(strings.formRequired(strings.template)),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: (documentId: string) => void;
	submitF: (templateId: string) => Promise<Try<ICreateResponse<IDocumentFromTemplateModel>>>;
	cancel: VoidFunction;
	contexts: LinkViewContextType[];
	deferOpenEditor?: boolean;
}

export const SelectTemplateFromContextForm = ({ confirm, cancel, submitF, contexts, deferOpenEditor = false, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(submitF);
	const [openEditor] = useDocumentEditor();
	const [templates] = useApiEffect(templatesQuery_byContext, contexts); // TODO must come from type!

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await submit(values.id);
		if (handleFormResponse(r, helpers)) {
			confirm(r.result.objectId);
			const selectedTemplate = templates?.find(t => t.id === values.id);
			if (selectedTemplate !== undefined && selectedTemplate.canViewOnline && deferOpenEditor === false) {
				openEditor(r.result.objectId);
			}
		}
	};

	if (templates === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{ id: '' }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<SelectTemplateInnerDialog<IModel>
					name='id'
					title={strings.selectWhat(strings.template)}
					cancel={cancel}
					isSubmitting={isSubmitting}
					templates={templates}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};
