import { Chip, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useMemo } from 'react';
import { useAnchorElement } from '../../hooks/useAnchorElement';
import { SearchbarX } from './SearchbarX';

interface IProps<TMode extends string> {
	value: string;
	mode: TMode;
	onSetMode: (mode: TMode) => void;
	onSearch: (value: string) => void;
	modeRecord: Record<TMode, string>;
	placeholder: string;
}

export const SearchbarXWithMode = <TMode extends string>({ modeRecord, mode, onSetMode, ...props }: IProps<TMode>) => {
	const [anchor, open, close] = useAnchorElement();
	const options = useMemo(() => Object.keys(modeRecord) as TMode[], [modeRecord]);

	return (
		<SearchbarX {...props}>
			<>
				<Chip
					color='primary'
					size='small'
					variant='outlined'
					label={
						<div className='df-row-ac jc-sb'>
							<div>{modeRecord[mode]}</div>
							<ArrowDropDownIcon
								fontSize='small'
								style={{ marginRight: -8 }}
							/>
						</div>
					}
					clickable
					onClick={open}
					style={{ marginRight: 16 }}
				/>
				<Menu
					anchorEl={anchor}
					open={Boolean(anchor)}
					onClose={close}
					keepMounted>
					{options.map(t => (
						<MenuItem
							key={t}
							onClick={() => {
								onSetMode(t);
								close();
							}}
							value={t}>
							{modeRecord[t]}
						</MenuItem>
					))}
				</Menu>
			</>
		</SearchbarX>
	);
};
