export enum ILicenseInfoLicenseTypeAsEnum {
	undefined = -1, // 0xFFFF
	Demo3 = 0,
	NoahSystem3 = 1,
	NoahEngine3 = 2,
	Research3 = 3,
	Sales3 = 4,
	Developer3 = 5,
	NoahSystemENT3 = 6,
	NoahEngineENT3 = 7,
	Demo4 = 10, // 0x000A
	NoahSystem4 = 11, // 0x000B
	NoahEngine4 = 12, // 0x000C
	Research4 = 13, // 0x000D
	Sales4 = 14, // 0x000E
	Developer4 = 15, // 0x000F
	NoahSystemENT4 = 16, // 0x0010
	NoahEngineENT4 = 17, // 0x0011
	NoahSystemLocalLock = 18, // 0x0012
}
