import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';
import { ISortParams } from '../types/ISortParams';
import { IHeader } from './IHeader';
import { nextSortParams } from './nextSortParams';

interface IProps<T> {
	headers: IHeader<T>[];
	sortParams?: ISortParams<T>;
	setSortParams?: (pars: ISortParams<T>) => void;
	padding: number;
}

export const TableHeader = <T extends unknown>({ headers, sortParams, setSortParams, padding }: IProps<T>) => {
	return (
		<TableHead>
			<TableRow>
				{headers.map(header => (
					<React.Fragment key={header.key}>
						{header.selectionHeaderProps === undefined ? (
							<TableCell
								align={header.align}
								style={{ padding: padding, whiteSpace: 'pre', ...header.cellStyle }}>
								{header.sortProperty !== undefined && sortParams && setSortParams ? (
									<TableSortLabel
										active={sortParams.property === header.sortProperty}
										direction={sortParams.direction as any}
										onClick={() => setSortParams(nextSortParams<T>(sortParams, header.sortProperty))}>
										{header.text}
									</TableSortLabel>
								) : (
									<div>{header.text}</div>
								)}
							</TableCell>
						) : (
							<TableCell
								align={header.align}
								style={{ padding: padding, ...header.cellStyle }}
								padding='checkbox'>
								<Checkbox
									indeterminate={header.selectionHeaderProps.selected > 0 && header.selectionHeaderProps.selected !== header.selectionHeaderProps.count}
									checked={header.selectionHeaderProps.selected === header.selectionHeaderProps.count}
									onChange={header.selectionHeaderProps.onToggle}
								/>
							</TableCell>
						)}
					</React.Fragment>
				))}
			</TableRow>
		</TableHead>
	);
};
