import { FadedRectangularChip } from 'framework/components/chips/FadedRectangularChip';
import React from 'react';
import { IChange } from './IChange';

interface IProps {
	change: IChange;
	tooltip?: string;
	style?: React.CSSProperties;
	invertColors?: boolean;
}

export const RelativeChangeFadedRectangularChip = ({ change, tooltip, invertColors, style }: IProps) => {
	return (
		<FadedRectangularChip
			tooltip={tooltip}
			label={`${change.isChangePositive ? '+' : ''}${(change.relativeChange * 100).toFixed(0)}%`}
			color={
				change.isChanged === false
					? 'info'
					: change.isChangePositive
					? invertColors === true
						? 'error'
						: 'success'
					: invertColors === true
					? 'success'
					: 'error'
			}
			style={style}
		/>
	);
};
