import { Collapse } from '@material-ui/core';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { FunctionVariantIcon } from 'framework/components/icons/FunctionVariantIcon';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageStatsButtonX } from 'framework/components/layout/PageStatsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { IStockEntryDto, productCatalogCommand_scriptRectifyStock } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { StockBreadcrumbs } from './StockBreadcrumbs';
import { StockStats } from './StockStats';

interface IProps {
	item: IStockEntryDto;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const StockHeader = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const userIsSuperUser = useLoggedInUserIsSuperUser();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [showStats, toggleShowStats] = useStateToggleBoolean(true);

	const onScript = () => {
		open(
			<AlertDialog
				open
				title={'Execute script (negative stock)'}
				content={`Equalize stock?\nRemove negative stock?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => productCatalogCommand_scriptRectifyStock()}
				accept={confirm}
			/>
		);
	};

	return (
		<div
			className='df-col fg1'
			style={style}>
			<div className='df-row-ac'>
				<StockBreadcrumbs />
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageStatsButtonX
						showStats={showStats}
						toggleShowStats={toggleShowStats}
					/>
					{userIsSuperUser && (
						<TooltipWithSpan title={`Execute custom scripts`}>
							<ColorOnHoverIconButton
								onClick={onScript}
								size='small'
								edge='end'>
								<FunctionVariantIcon />
							</ColorOnHoverIconButton>
						</TooltipWithSpan>
					)}
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<Collapse in={showStats}>
				<StockStats
					item={item}
					style={{ paddingTop: 16 }}
				/>
			</Collapse>
		</div>
	);
};
