import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		created: strings.createdAt,
		outstandingBalance: strings.outstandingBalance,
		salesReference: strings.reference,
		salesRecipientName: strings.withRespectToShort,
		referencePatientIdentifier: strings.patient,
		customerAccountIdentifier: strings.healthInsuranceFund,
	};
};
