import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { ICltTest, trialReportsCommand_deleteClt } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { CltTestForm } from './CltTestForm';

interface IProps extends DialogProps {
	id: string;
	item: ICltTest;
	close: VoidFunction;
}

export const CltTestDialog = ({ id, item, close, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { open, confirm, cancel, isStacked } = useDialogsContext(close);

	if (item === undefined) {
		return <div></div>;
	}

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.test)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => trialReportsCommand_deleteClt(id)}
				accept={close}
			/>
		);
	};

	const onEdit = () => {
		open(
			<CltTestForm
				open
				trialId={id}
				cancel={cancel}
				confirm={confirm}
				test={item}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			maxWidth='sm'
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<GroupWorkIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						CLT
					</Typography>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.results} />
				{isNotNullNorUndefined(item.monoLeft) && (
					<PropertyInGrid
						label={strings.left}
						value={`${item.monoLeft} °`}
					/>
				)}
				{isNotNullNorUndefined(item.monoRight) && (
					<PropertyInGrid
						label={strings.right}
						value={`${item.monoRight} °`}
					/>
				)}
				<PropertyInGrid
					label={strings.mono}
					value={`${item.mono} °`}
				/>
				<PropertyInGrid
					label={strings.stereo}
					value={`${item.stereo} °`}
				/>
				<PropertyInGrid
					label={strings.improvement}
					value={`${item.improvement} °`}
				/>
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								onClick={onEdit}
								startIcon={<EditIcon />}>
								{strings.edit}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={onDelete}
								color='secondary'
								startIcon={<DeleteOutlineIcon />}>
								{strings.delete}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
