import { IconButton, MenuItem, Select } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { nameOf } from '../utils/nameOf';
import { FormFilledControl } from './common/FormFilledControl';
import { IFormProps } from './common/IFormProps';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useStateBoolean } from 'framework/hooks/useStateBool';

interface IProps<TModel, TValue> extends IFormProps<TModel> {
	options: TValue[];
	withNone?: boolean;
	withNoneText?: string;
	renderValue: (option: TValue, index: number) => React.ReactNode;
	getKey: (val: TValue) => string;
	onNew?: VoidFunction;
}

export const FormSelectFieldOnId = <TModel extends unknown, TValue extends { id: string }>({
	options,
	withNone = false,
	withNoneText,
	renderValue,
	getKey,
	onNew,
	...props
}: IProps<TModel, TValue>) => {
	const [field, meta] = useField<string>(nameOf(props.name));
	const strings = useLocalization();
	const [isHovered, enter, leave] = useStateBoolean(false);

	return (
		<FormFilledControl
			meta={meta}
			{...props}>
			<Select
				{...field}
				onMouseEnter={enter}
				onMouseLeave={leave}
				endAdornment={
					onNew && isHovered ? (
						<IconButton
							size='small'
							color='primary'
							onClick={onNew}
							disabled={props.disabled}
							style={{ marginRight: 16 }}>
							<AddCircleIcon />
						</IconButton>
					) : undefined
				}>
				{withNone && (
					<MenuItem value={undefined}>
						<em>{withNoneText ? withNoneText : strings.none.toLowerCase()}</em>
					</MenuItem>
				)}
				{options.map((t, index) => (
					<MenuItem
						key={getKey(t)}
						value={t.id}>
						{renderValue(t, index)}
					</MenuItem>
				))}
			</Select>
		</FormFilledControl>
	);
};
