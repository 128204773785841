import React from 'react';
import {
	IInventoryItem,
	IInventoryItemsQueryParamsForPurchaseOrCreditPurchase,
	IProductCatalogItem,
	IProductCatalogQueryParamsForPurchase,
	IQueryResult,
} from 'gen/ApiClient';

export interface IContext {
	pcParams: IProductCatalogQueryParamsForPurchase;
	setPcParams: React.Dispatch<React.SetStateAction<IProductCatalogQueryParamsForPurchase>>;
	pcQueryResult: IQueryResult<IProductCatalogItem> | undefined;
	iiParams: IInventoryItemsQueryParamsForPurchaseOrCreditPurchase;
	setIIParams: React.Dispatch<React.SetStateAction<IInventoryItemsQueryParamsForPurchaseOrCreditPurchase>>;
	iiQueryResult: IQueryResult<IInventoryItem> | undefined;
}

export const SearchContext = React.createContext<IContext>(undefined as any);
