import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { InventoryManagementPageBreadcrumb } from '../InventoryManagementPageBreadcrumb';
import { ProductCatalogRoute } from '../routes';
import { CubeOutlineIcon } from 'framework/components/icons/CubeOutlineIcon';

export const ProductCatalogBreadcrumbs = () => {
	const strings = useLocalization();

	return (
		<React.Fragment>
			<InventoryManagementPageBreadcrumb />
			<PageBreadcrumbDivider />
			<PageBreadcrumb
				text={strings.productCatalog}
				route={ProductCatalogRoute}
				icon={<CubeOutlineIcon />}
			/>
		</React.Fragment>
	);
};
