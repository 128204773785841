import { Card, CardContent, CardProps, Divider } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { TabsX } from 'framework/components/tabs/TabsX';
import { TabX } from 'framework/components/tabs/TabX';
import { isLast } from 'framework/utils/array/isLast';
import { IPrimaryWorkflowsByPatientResponse } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { createWorkflowFilterTabTypeStartIconRecord } from './createWorkflowFilterTabTypeStartIconRecord';
import { createWorkflowFilterTabTypTitleRecord } from './createWorkflowFilterTabTypTitleRecord';
import { filterWorkflowsByTabType } from './filterWorkflowsByTabType';
import { WorkflowByPatientListComponent } from './WorkflowByPatientListComponent';
import { WorkflowFilterTabType, WorkflowFilterTabTypes } from './WorkflowFilterTabType';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';

interface IProps extends CardProps {
	data: IPrimaryWorkflowsByPatientResponse;
	reload: VoidFunction;
}

export const WorkflowsByPatientCard = ({ data, reload, ...rest }: IProps) => {
	const strings = useLocalization();
	const [selectedTab, setSelectedTab] = React.useState<WorkflowFilterTabType>('all');
	const rec = useMemo(() => createWorkflowFilterTabTypTitleRecord(strings), [strings]);
	const startIconRecord = useMemo(() => createWorkflowFilterTabTypeStartIconRecord(data), [data]);
	const fWfs = useMemo(() => filterWorkflowsByTabType(data.workflows, selectedTab), [data, selectedTab]);

	return (
		<Card {...rest}>
			<CardContent>
				<CardTitleTypography
					title={strings.processes}
					style={{ marginBottom: 8 }}
				/>
				<DividerWithMargin />
				<TabsX>
					{WorkflowFilterTabTypes.map(tab => (
						<TabX
							key={tab}
							isSelected={tab === selectedTab}
							onClick={() => setSelectedTab(tab)}
							startIcon={startIconRecord[tab]}
							value={rec[tab]}
						/>
					))}
				</TabsX>
				<Divider style={{ marginBottom: 8 }} />
				{fWfs.map(t => (
					<WorkflowByPatientListComponent
						item={t}
						key={t.id}
						withoutBorder={isLast(t, data.workflows)}
					/>
				))}
				{data.workflows.length === 0 && <em>{strings.noProcessesDefinedForThisPatientYet}</em>}
			</CardContent>
		</Card>
	);
};
