import { CardProps, Divider, Typography, useTheme } from '@material-ui/core';
import { GetAppOutlined } from '@material-ui/icons';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { calcSalesDetailRoute } from 'app/main/financial/routes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { formatDate } from 'framework/utils/date/formatDate';
import {
	exactGlobeSales_check,
	exactGlobeSales_exportSingle,
	exactGlobeSales_markAsExported,
	exactGlobeSales_markAsHandled,
	IExactGlobeSaleDto,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { ExactGlobeSaleListItemChipX, exactGlobeSaleToColorX } from './exact-globe-sales.tab';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useDownload } from 'gen/useDownload';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

interface IExactGlobeSaleDetailsCardXProps extends CardProps, IReloadTriggerProps {
	item: IExactGlobeSaleDto;
	reload: VoidFunction;
}

export const ExactGlobeSaleDetailsCardX = ({ item, reload, reloadTrigger, ...rest }: IExactGlobeSaleDetailsCardXProps) => {
	const strings = useLocalization();
	const color = React.useMemo(() => exactGlobeSaleToColorX(item), [item]);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();
	const theme = useTheme();
	const [download, isDownloading] = useDownload(exactGlobeSales_exportSingle);

	const onMarkAsHandled = () => {
		open(
			<AlertDialog
				open
				title={`Markeren als behandeld`}
				content={`Wil je deze verkoop markeren als behandeld?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactGlobeSales_markAsHandled({ exactGlobeSaleId: item.hamsId })}
				accept={confirm}
			/>
		);
	};

	const onMarkAsExported = () => {
		open(
			<AlertDialog
				open
				title={`Markeren als geëxporteerd`}
				content={`Wil je deze verkoop markeren als geëxporteerd?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactGlobeSales_markAsExported({ exactGlobeSaleId: item.hamsId })}
				accept={confirm}
			/>
		);
	};

	const onCheck = () => {
		open(
			<AlertDialog
				open
				title={`Opnieuw controleren`}
				content={`Wil je deze verkoop markeren opnieuw controleren?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactGlobeSales_check({ exactGlobeSaleId: item.hamsId })}
				accept={confirm}
			/>
		);
	};

	return (
		<CardX {...rest}>
			<div
				className='df-row-ac gap-8'
				style={{ marginBottom: 16 }}>
				<Typography
					component='div'
					style={{ fontSize: '16px', fontWeight: 500 }}>
					{item.reference}
				</Typography>
				<ExactGlobeSaleListItemChipX
					item={item}
					color={color}
				/>
			</div>
			<div className='df-col gap-8'>
				<CaptionVerticalLabelledPropertyX
					label={strings.reference}
					link={() => push(calcSalesDetailRoute(item.hamsId))}>
					<span>{item.reference}</span>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.date}>{formatDate(item.date)}</CaptionVerticalLabelledPropertyX>
				<Divider />
				<CaptionVerticalLabelledPropertyX label={strings.validation}>
					<div className='df-col gap-4'>
						{item.validation.hasError === false && <span style={{ color: theme.palette.success.main }}>{strings.everythingSeemsAllright}</span>}
						{item.validation.hasMultipleCustomerAccounts && <span style={{ color: theme.palette.error.main }}>{`Meerdere klantenrekeningen`}</span>}
						{item.validation.customerAccountHasNoDebtorCode && <span style={{ color: theme.palette.error.main }}>{`Klantenrekening geen 'debtor code'`}</span>}
						{item.validation.hasAnyUnlinkedProductCatalogItems && (
							<span style={{ color: theme.palette.error.main }}>{`Eén of meerdere artikelen hebben nog geen kostendrager`}</span>
						)}
					</div>
				</CaptionVerticalLabelledPropertyX>
				<div className='fg1'></div>
				<Divider style={{ marginBottom: 4 }} />
				<div className='df-col gap-8'>
					<TextLinkButton
						startIcon={<VisibilityOutlinedIcon />}
						onClick={() => push(calcSalesDetailRoute(item.hamsId))}>
						{strings.viewSaleInHAMS}
					</TextLinkButton>
					<TextLinkButton
						startIcon={<RefreshIcon />}
						hoverColor='secondary'
						onClick={onCheck}
						disabled={item.hasError === false}>
						{`Opnieuw controleren`}
					</TextLinkButton>
					<TextLinkButton
						startIcon={<GetAppOutlined />}
						hoverColor='secondary'
						onClick={onMarkAsExported}
						disabled={item.status === 'Exported'}>
						{`Markeren als geëxporteerd`}
					</TextLinkButton>
					<TextLinkButton
						startIcon={<GetAppOutlined />}
						hoverColor='secondary'
						onClick={onMarkAsHandled}
						disabled={item.status === 'Handled'}>
						{`Markeren als behandeld`}
					</TextLinkButton>
					<TextLinkButton
						color='primary'
						startIcon={<GetAppOutlinedIcon />}
						onClick={() => download(item.id)}
						disabled={isDownloading || item.hasError}>
						{`Download xml`}
					</TextLinkButton>
				</div>
			</div>
		</CardX>
	);
};
