import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import { useLoggedInUserIsSuperUserOrHasClientClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasClientClaim';
import { SalesDetailDialog } from 'app/main/financial/sales/components/SalesDetailDialog';
import { SaleForm } from 'app/main/financial/sales/forms/SaleForm';
import { TenderDetailDialog } from 'app/main/financial/tenders/components/TenderDetailDialog';
import { TenderModelForm } from 'app/main/financial/tenders/forms/TenderModelForm';
import { RegisterArticlesInquiryForm } from 'app/main/processes/articlesInquiries/forms/register/RegisterArticlesInquiryForm';
import { RegisterLoanForm } from 'app/main/processes/loans/forms/new/RegisterLoanForm';
import { RegisterOrder } from 'app/main/processes/orders/forms/register/RegisterOrder';
import { RegisterRepairRequestForm } from 'app/main/processes/repairs/forms/RegisterRepairRequestForm';
import { RfiaForPatientForm } from 'app/main/processes/requestsForInsuranceAllowance/forms/new/RfiaForPatientForm';
import { calcLoanDetailRoute, calcOrderDetailRoute, calcRepairDetailRoute, calculateArticlesInquiryDetailRoute } from 'app/main/processes/ProcessesRoutes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { IPatientDetail } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

interface IProps {
	item: IPatientDetail;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const PatientDetailProcessesActions = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();
	const canRegisterArticlesInquiry = useLoggedInUserIsSuperUserOrHasClientClaim('ais');

	const onNewRfia = () => {
		open(
			<RfiaForPatientForm
				open
				patientId={item.patient.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onNewOrder = () => {
		open(
			<RegisterOrder
				patient={item.patient}
				cancel={cancel}
				confirm={id => {
					push(calcOrderDetailRoute(id));
					confirm();
				}}
			/>
		);
	};

	const onNewLoan = () => {
		open(
			<RegisterLoanForm
				open
				patientId={item.patient.id}
				cancel={cancel}
				confirm={id => {
					push(calcLoanDetailRoute(id));
					confirm();
				}}
			/>
		);
	};

	const onNewRepair = () => {
		open(
			<RegisterRepairRequestForm
				open
				patientId={item.patient.id}
				cancel={cancel}
				confirm={id => {
					confirm();
					push(calcRepairDetailRoute(id));
				}}
			/>
		);
	};

	const onNewSale = () => {
		open(
			<SaleForm
				forPatientId={item.patient.id}
				confirm={id => {
					confirm();
					onSaleAdded(id);
				}}
				cancel={cancel}
			/>
		);
	};

	const onSaleAdded = (id?: string) => {
		if (id) {
			open(
				<SalesDetailDialog
					open
					id={id}
					close={confirm}
					withCreate
				/>
			);
		}
	};

	const onNewTender = () => {
		open(
			<TenderModelForm
				open
				patientId={item.patient.id}
				confirm={id => {
					confirm();
					onTenderAdded(id);
				}}
				cancel={cancel}
			/>
		);
	};

	const onTenderAdded = (id: string) => {
		open(
			<TenderDetailDialog
				open
				id={id}
				withCreate
				close={confirm}
			/>
		);
	};

	const onNewArticlesInquiry = () => {
		open(
			<RegisterArticlesInquiryForm
				open
				patient={item.patient}
				cancel={cancel}
				confirm={id => {
					push(calculateArticlesInquiryDetailRoute(id));
					confirm();
				}}
			/>
		);
	};

	return (
		<div
			className='df-col gap-8'
			style={style}>
			<TextLinkButton
				onClick={onNewRfia}
				startIcon={<AddToPhotosOutlinedIcon />}>
				{strings.requestForInsuranceAllowanceShort}
			</TextLinkButton>
			{canRegisterArticlesInquiry && (
				<TextLinkButton
					onClick={onNewArticlesInquiry}
					startIcon={<PlaylistAddCheckIcon />}>
					{strings.articlesInquiry}
				</TextLinkButton>
			)}
			<TextLinkButton
				onClick={onNewOrder}
				startIcon={<AddShoppingCartOutlinedIcon />}>
				{strings.order}
			</TextLinkButton>
			<TextLinkButton
				onClick={onNewLoan}
				startIcon={<ExitToAppOutlinedIcon />}>
				{strings.loan}
			</TextLinkButton>
			<TextLinkButton
				onClick={onNewRepair}
				startIcon={<BuildOutlinedIcon />}>
				{strings.repair}
			</TextLinkButton>
			<TextLinkButton
				onClick={onNewSale}
				startIcon={<MonetizationOnOutlinedIcon />}>
				{strings.sale}
			</TextLinkButton>
			<TextLinkButton
				onClick={onNewTender}
				startIcon={<PostAddOutlinedIcon />}>
				{strings.tender}
			</TextLinkButton>
		</div>
	);
};
