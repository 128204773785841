import { TextField } from '@material-ui/core';
import { StaticDatePicker } from '@material-ui/pickers';
import { parseDateOrDefault } from 'framework/utils/date/parseDateOrDefault';
import React from 'react';

interface IProps {
	date: Date;
	setDate: (val: Date) => void;
}

export const RosaCalendarDatePickerComponent = ({ date, setDate }: IProps) => {
	return (
		<div className='small-date-picker'>
			<StaticDatePicker
				className='small-date-picker'
				disableHighlightToday
				displayStaticWrapperAs='desktop'
				openTo='date'
				open
				value={date}
				onChange={newValue => setDate(parseDateOrDefault(newValue))}
				views={['date']}
				renderInput={props => (
					<TextField
						{...props}
						variant='standard'
					/>
				)}
			/>
		</div>
	);
};
