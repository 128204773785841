import { Collapse, Divider, TableRow, useTheme } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { calcInventoryItemDetailRoute, calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { ExpandLessOrMoreIcon } from 'framework/components/ExpandLessOrMoreIcon';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { TableCellX } from 'framework/table/TableCellX';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISale } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { calcCustomerAccountDetailRoute, calcSalesDetailRoute } from '../../routes';
import { SalesCreditChipOrFragment } from '../components/SalesCreditChipOrFragment';
import { SalesPaymentStatusChip } from '../components/SalesPaymentStatusChip';
import { SalesTypeChip } from '../components/SalesTypeChip';
import { SalesListItemStatusIcon } from './SalesListItemStatusIcon';
import { mapSaleToColorX } from './utils';
import { TableDetailsRowX } from 'framework/table/TableDetailsRowX';
import { ChipX } from 'framework/components/chips/ChipX';

const MinorFontSize = 13;

interface IProps {
	item: ISale;
	isExpanded: boolean;
	toggle: VoidFunction;
}

export const SalesTableRow = ({ item, isExpanded, toggle }: IProps) => {
	const strings = useLocalization();
	const color = useMemo(() => mapSaleToColorX(item), [item]);
	const tColor = useThemeColor(color);
	const theme = useTheme();
	const { push } = useHistory();
	const [isHovered, enter, leave] = useStateBoolean(false);

	const onViewDetails = () => push(calcSalesDetailRoute(item.id));

	return (
		<React.Fragment>
			<TableRow
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<TableCellX>
					<div className='df-row gap-4'>
						<SalesListItemStatusIcon
							item={item}
							style={{ fontSize: 22, color: tColor }}
						/>
						<div className='df-col gap-2'>
							<TextLinkButton
								underline
								style={{ fontSize: 14 }}
								onClick={onViewDetails}>
								{item.reference}
							</TextLinkButton>
							<div className='df-row-ac gap-4'>
								<SalesTypeChip item={item} />
								<SalesCreditChipOrFragment item={item} />
							</div>
							{Boolean(item.externalReference) && (
								<span
									style={{
										fontSize: MinorFontSize,
										color: theme.palette.text.secondary,
									}}>{`${strings.shortExternalReference}: ${item.externalReference}`}</span>
							)}
						</div>
					</div>
				</TableCellX>
				<TableCellX>
					<div className='df-col gap-2'>
						<span>{formatDate(item.created)}</span>
						<span style={{ fontSize: MinorFontSize, color: theme.palette.text.secondary }}>{`${strings.payBefore}: ${formatDate(item.validUntil)}`}</span>
					</div>
				</TableCellX>
				<TableCellX>
					<div className='df-col'>
						{item.customerAccountLines.map((t, index) => (
							<div
								className='df-row-ab gap-4'
								key={t.id}>
								<span style={{ fontSize: MinorFontSize, color: theme.palette.text.secondary }}>{`# ${index + 1}`}</span>
								<TextLinkButton
									underline
									style={{ fontWeight: 'unset' }}
									onClick={() => push(calcCustomerAccountDetailRoute(t.customerAccountId!))}>
									{t.customerAccountIdentifier}
								</TextLinkButton>
							</div>
						))}
					</div>
				</TableCellX>
				<TableCellX align='right'>
					<div className='df-col-ae gap-2'>
						<span>{formatCurrency(item.priceInVat)}</span>
						<SalesPaymentStatusChip item={item} />
					</div>
				</TableCellX>
				<TableCellX align='right'>
					<div className='df-row-ac jc-e'>
						<ColorOnHoverIconButton
							edge='end'
							size='small'
							color='primary'
							onClick={toggle}>
							<ExpandLessOrMoreIcon isExpanded={isExpanded} />
						</ColorOnHoverIconButton>
						<ColorOnHoverIconButton
							size='small'
							edge='end'
							color='primary'
							onClick={onViewDetails}>
							<VisibilityOutlinedIcon />
						</ColorOnHoverIconButton>
					</div>
				</TableCellX>
			</TableRow>
			{(item.labels ?? []).length > 0 && (
				<TableDetailsRowX
					colSpan={5}
					onMouseEnter={enter}
					onMouseLeave={leave}
					style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
					<div
						className='df-row-wrap gap-4'
						style={{ paddingBottom: 12 }}>
						{item.labels.map(t => (
							<ChipX
								key={t}
								label={t}
								color='primary'
							/>
						))}
					</div>
				</TableDetailsRowX>
			)}
			<TableDetailsRowX
				colSpan={5}
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<Collapse in={isExpanded}>
					<div
						className='df-col'
						style={{ paddingBottom: 12 }}>
						<Divider
							variant='inset'
							style={{ marginTop: 8, marginBottom: 4 }}
						/>
						{item.lines.map(line => (
							<div
								className='df-row-ab'
								key={line.id}
								style={{ fontSize: MinorFontSize, gap: 4 }}>
								<span>{`${line.quantity.toString()} x`}</span>
								{line.hasProductCatalogItem === true && (
									<TextLinkButton
										underline
										style={{ fontSize: MinorFontSize }}
										onClick={() => {
											if (line.inventoryItemId) {
												push(calcInventoryItemDetailRoute(line.inventoryItemId));
											} else if (line.productCatalogItemId) {
												push(calcProductCatalogDetailRoute(line.productCatalogItemId));
											}
										}}>
										{line.description}
									</TextLinkButton>
								)}
								{line.hasProductCatalogItem === false && <span style={{ fontSize: MinorFontSize }}>{line.description}</span>}
							</div>
						))}
					</div>
				</Collapse>
			</TableDetailsRowX>
		</React.Fragment>
	);
};
