import React from 'react';

interface IProps {
	children: React.ReactNode | React.ReactNode[];
}

export const PageXHeaderActions = ({ children }: IProps) => {
	return (
		<div
			className='df-row-ac gap-4'
			style={{ marginTop: -12, marginBottom: -12 }}>
			{children}
		</div>
	);
};
