import { Dialog, DialogContent, DialogProps, DialogTitle, Divider, useTheme } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { ILinkAttendingAudiologistWithPatientModel, IPatient, patientsCommand_updateAttendingAudiologist } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormSelectAudiologistField } from '../../settings/users/forms/FormSelectAudiologistField';

const createSchema = (strings: IStrings) => {
	return yup.object<ILinkAttendingAudiologistWithPatientModel>({
		audiologistId: yup.string().required(strings.formRequired(strings.audiologist)),
		alsoUpdateAudiologistOnRunningProcesses: yup.boolean().defined(),
	});
};

interface IProps extends DialogProps {
	item: IPatient;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LinkAudiologistWithPatientForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const [link, isSubmitting] = useFormSubmit(patientsCommand_updateAttendingAudiologist);

	const handleSubmit = async (values: ILinkAttendingAudiologistWithPatientModel, helpers: FormikHelpers<ILinkAttendingAudiologistWithPatientModel>) => {
		const r = await link(item.id, values);
		if (handleValidateResponse(r, helpers, 'audiologistId')) {
			confirm();
		}
	};

	return (
		<Formik<ILinkAttendingAudiologistWithPatientModel>
			validateOnMount
			initialValues={{
				audiologistId: item.attendingAudiologistId ?? '',
				alsoUpdateAudiologistOnRunningProcesses: true,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.selectWhat(strings.audiologist)}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormSelectAudiologistField<ILinkAttendingAudiologistWithPatientModel>
							name='audiologistId'
							label={strings.audiologist}
						/>
						<Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
						<FormExtendedRadioGroupField<ILinkAttendingAudiologistWithPatientModel, boolean>
							name='alsoUpdateAudiologistOnRunningProcesses'
							label=''
							options={[
								{ value: true, label: strings.alsoUpdateOnProcesses, caption: strings.attendingAudiologistWillAlsoBeChangedOnOtherProcesses },
								{ value: false, label: strings.doNotUpdateOnProcesses, caption: strings.attendingAudiologistWillOnlyBeChangedWithThisPatientAndNotOnProcesses },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.link}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
