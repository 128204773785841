import { Popover, TextField } from '@material-ui/core';
import { StaticDatePicker } from '@material-ui/pickers';
import React from 'react';
import { DatePickerViewType } from '../../forms/common/DatePickerViewType';
import { DefaultDateFormat } from '../../utils/date/DefaultDateFormat';
import { parseDateOrDefault } from '../../utils/date/parseDateOrDefault';

interface IProps {
	anchor: HTMLElement | null;
	close: VoidFunction;
	value: Date;
	setValue: (date: Date) => void;
	openTo?: DatePickerViewType;
	views?: DatePickerViewType[];
	disableFuture?: boolean;
	disablePast?: boolean;
}

export const StaticDatePickerPopoverX = ({
	anchor,
	close,
	value,
	setValue,
	openTo = 'date',
	views = ['year', 'month', 'date'],
	disableFuture,
	disablePast,
}: IProps) => {
	return (
		<Popover
			anchorEl={anchor}
			open
			onClose={close}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			keepMounted>
			<div className='small-date-picker'>
				<StaticDatePicker
					className='small-date-picker'
					displayStaticWrapperAs='desktop'
					openTo={openTo}
					open
					value={value === undefined ? null : value}
					onChange={date => {
						const x = parseDateOrDefault(date);
						console.log(x);
						// const y = set(value, {  })

						setValue(x);
						// setValue(parseDateOrDefault(date));
						// setValue(mapToIsoOnlyDate(date))

						close();
					}}
					views={views}
					disableFuture={disableFuture}
					disablePast={disablePast}
					inputFormat={DefaultDateFormat}
					renderInput={props => (
						<TextField
							{...props}
							variant='standard'
						/>
					)}
				/>
			</div>
		</Popover>
	);
};
