import { ColorX } from 'framework/components/color/ColorX';
import React from 'react';
import { useThemeColorOrHex } from './color/useThemeColorOrHex';

interface IProps {
	size: number;
	color: ColorX | string;
	style?: React.CSSProperties;
	variant?: 'filled' | 'outlined';
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const CircleX = ({ size, color, style, onClick, variant = 'filled' }: IProps) => {
	const tColor = useThemeColorOrHex(color);

	return (
		<div
			style={{
				color: tColor,
				backgroundColor: variant === 'filled' ? tColor : 'unset',
				border: `1px solid ${tColor}`,
				borderRadius: '50%',
				height: size,
				width: size,
				cursor: onClick ? 'pointer' : 'unset',
				...style,
			}}
			onClick={onClick}></div>
	);
};
