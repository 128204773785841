import { IInventoryItemsQueryParams } from 'gen/ApiClient';

export const DefaultInventoryItemsQueryParams: IInventoryItemsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	isPurchased: undefined,
	isSold: undefined,
	categories: [],
	manufacturers: [],
	subcategories: [],
	statuses: [],
	lastUpdatedAfter: undefined,
	lastUpdatedBefore: undefined,
	vatPercentage: undefined,
	isPurchaseCredited: undefined,
	isSalesCredited: undefined,
	listPriceMoreThan: undefined,
	listPriceLessThan: undefined,
	salesPriceMoreThan: undefined,
	salesPriceLessThan: undefined,
	purchasedAfter: undefined,
	purchasedBefore: undefined,
	soldAfter: undefined,
	soldBefore: undefined,
	deliveredAfter: undefined,
	deliveredBefore: undefined,
	orderedAfter: undefined,
	orderedBefore: undefined,
	returnedAfter: undefined,
	returnedBefore: undefined,
	hasBatteries: undefined,
	createdAfter: undefined,
	createdBefore: undefined,
	isAtSupplierForRepair: undefined,
	isElectronics: undefined,
	isReturnedToSupplier: undefined,
	atLocationIds: [],
	locationTypes: [],
	supplierIds: [],
	vatPercentages: [],
	keyFilterType: undefined,
	types: [],
	isDemo: undefined,
	isReserved: undefined,
	propertyOfTypes: [],
	consignmentReturnBeforeDateAfter: undefined,
	consignmentReturnBeforeDateBefore: undefined,
	hasPurchasedInsurance: undefined,
	hasPurchasedProlongedWarranty: undefined,
	hasSoldInsurance: undefined,
	hasSoldProlongedWarranty: undefined,
	purchaseCreditDateAfter: undefined,
	purchaseCreditDateBefore: undefined,
	salesCreditDateAfter: undefined,
	salesCreditDateBefore: undefined,
	isTailorMade: undefined,
	consignmentExpiresWithinAWeek: undefined,
	isConsignmentExpired: undefined,
	isOnConsignment: undefined,
	hasBebat: undefined,
	labels: [],
};
