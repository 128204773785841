import React from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { INoahDeviceForPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	item: INoahDeviceForPatient;
	style?: React.CSSProperties;
}

export const LeftRightRemoteStatusChip = ({ item, style }: IProps) => {
	const strings = useLocalization();
	return (
		<RectangularChip
			style={style}
			label={item.lastUsage.isLeft ? strings.left : item.lastUsage.isRight ? strings.right : strings.remote}
		/>
	);
};
