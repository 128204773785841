import { SalesLineExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createLinesExportRecord = (strings: IStrings): Record<SalesLineExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		CreditedStatus: strings.creditStatus,
		Description: strings.description,
		DiscountPercentage: `${strings.discount} %`,
		InventoryItemId: `${strings.inventoryItem} ID`,
		LinePriceExVat: strings.priceExVat,
		LinePriceInVat: strings.priceInVat,
		ProductCatalogItemId: `${strings.productCatalogItem} ID`,
		ProductCatalogItemType: `${strings.productCatalogItem} TYPE`,
		Quantity: strings.quantity,
		SalesDate: strings.date,
		SalesId: `${strings.sale} ID`,
		SalesLabelsCsv: strings.labels,
		SalesReference: strings.salesReference,
		SerialNumber: strings.serialNumber,
		UnitListPrice: strings.unitListPrice,
		UnitPrice: strings.unitPrice,
		VatPercentage: strings.vatPercentage,
	};
};
