import { NumberFormatting } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createNumberFormattingRecord = (strings: IStrings): Record<NumberFormatting, string> => {
	return {
		N0: strings.noDecimals,
		N1: strings.oneDecimal,
		N2: strings.twoDecimals,
	};
};
