import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteMultipleWithAdd } from 'framework/forms/FormAutocompleteMultipleWithAdd';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatchProductCatalogItemSuppliersRequest, IProductCatalogItem, ISupplierSummary, productCatalogCommand_patchSuppliers } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import * as yup from 'yup';
import { SuppliersCacheContext } from '../../../contacts/suppliers/SuppliersCacheContext';
import { RegisterSupplierForm } from '../../../contacts/suppliers/forms/RegisterSupplierForm';

interface IProps extends DialogProps {
	productCatalogItem: IProductCatalogItem;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPatchProductCatalogItemSuppliersRequest>({
			supplierIds: yup.array().of(yup.string().defined()).defined(),
			productCatalogItemId: yup.string().required(),
		})
		.defined();
};

export const SelectSuppliersForm = ({ productCatalogItem, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(productCatalogCommand_patchSuppliers);

	const handleSubmit = async (values: IPatchProductCatalogItemSuppliersRequest, helpers: FormikHelpers<IPatchProductCatalogItemSuppliersRequest>) => {
		const updateResult = await update(values);
		if (handleValidateResponse(updateResult, helpers, 'supplierIds')) {
			notify(strings.productUpdated);
			confirm();
		}
	};

	return (
		<Formik<IPatchProductCatalogItemSuppliersRequest>
			validateOnMount
			validationSchema={createSchema(strings)}
			initialValues={{
				supplierIds: productCatalogItem.supplierIds,
				productCatalogItemId: productCatalogItem.id,
			}}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					isSubmitting={isSubmitting}
					cancel={cancel}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	isSubmitting: boolean;
	cancel: VoidFunction;
}

const InnerDialog = ({ cancel, isSubmitting, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IPatchProductCatalogItemSuppliersRequest>();
	const strings = useLocalization();
	const [data, load] = usePreloadCacheContext(SuppliersCacheContext);
	const { open, confirm: confirmNewSupplier, cancel: cancelNewSupplier, isStacked } = useDialogsContext();

	const onAddNewSupplier = async (id: string) => {
		await load();
		setFormValue<IPatchProductCatalogItemSuppliersRequest>('supplierIds', [...props.values.supplierIds, id], props);
		confirmNewSupplier();
	};

	const onNew = (newName: string) => {
		open(
			<RegisterSupplierForm
				open
				confirm={id => onAddNewSupplier(id)}
				cancel={cancelNewSupplier}
				name={newName}
			/>
		);
	};

	return (
		<Dialog
			fullWidth
			{...rest}
			open={rest.open && isStacked === false}>
			<DialogTitle>{strings.selectSuppliers}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'
				style={{ overflow: 'visible' }}>
				<FormAutocompleteMultipleWithAdd<IPatchProductCatalogItemSuppliersRequest, ISupplierSummary>
					name='supplierIds'
					label={strings.suppliers}
					options={data}
					onCreateNew={val => onNew(val)}
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions
				submitText={strings.update}
				isSubmitting={isSubmitting}
				cancel={cancel}
			/>
		</Dialog>
	);
};
