import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { PaginationXPageSizeButton } from './PaginationXPageSizeButton';
import { PaginationXPreviousNext } from './PaginationXPreviousNext';

interface IProps {
	pageSize: number;
	pageIndex: number;
	pageSizes: number[];
	countRecords: number;
	countPages: number;
	setPageIndex: (index: number) => void;
	setPageSize: (index: number) => void;
	style?: React.CSSProperties;
}

export const PaginationX = ({ pageSize, pageIndex, setPageIndex, setPageSize, pageSizes, countRecords, countPages, style, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<div
			style={{ ...style, gap: 4 }}
			className='df-row-ab'
			{...rest}>
			<PaginationXPageSizeButton
				pageSize={pageSize}
				pageSizes={pageSizes}
				setPageSize={setPageSize}
			/>
			<span>{`# ${countRecords} ${strings.lines.toLowerCase()}`}</span>
			<PaginationXPreviousNext
				pageIndex={pageIndex}
				setPageIndex={setPageIndex}
				countPages={countPages}
			/>
		</div>
	);
};
