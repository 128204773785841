import { Divider } from '@material-ui/core';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { isLast } from 'framework/utils/array/isLast';
import { formatDateX } from 'framework/utils/date/formatDateX';
import { IRosaEventDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { RosaEventListComponentMode } from './RosaEventListComponentMode';
import { RosaEventsGroupedByDayComponent } from './RosaEventsGroupedByDayComponent';
import { groupRosaEventsByDate } from './groupRosaEventsByDate';

interface IProps {
	events: IRosaEventDto[];
	noRecordsWithoutPadding?: boolean;
	mode: RosaEventListComponentMode;
}

export const RosaEventsListComponent = ({ events, noRecordsWithoutPadding, mode }: IProps) => {
	const strings = useLocalization();
	const grouped = useMemo(() => groupRosaEventsByDate(events), [events]);

	return (
		<>
			{grouped.map(group => (
				<React.Fragment key={formatDateX(group.date)}>
					<RosaEventsGroupedByDayComponent
						group={group}
						mode={mode}
					/>
					{isLast(group, grouped) === false && <Divider />}
				</React.Fragment>
			))}
			{grouped.length === 0 && <NoRecordsFound withPadding={noRecordsWithoutPadding === true ? false : true}>{strings.noAppointmentsFound}</NoRecordsFound>}
		</>
	);
};
