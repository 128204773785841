import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { ICashRegisterEntry } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { XViewBadgeIconButton } from 'shared/icons/XViewBadgeIconButton';
import { PaymentDialog } from '../payments/PaymentDialog';

interface IProps {
	item: ICashRegisterEntry;
}

export const CashRegisterPaymentReferenceOrDescriptionHeader = ({ item }: IProps) => {
	const strings = useLocalization();
	const { open, cancel: close } = useDialogsContext();

	const onViewDetail = (id: string) => {
		open(
			<PaymentDialog
				open
				id={id}
				close={close}
			/>
		);
	};

	return (
		<div className='df-row-ac'>
			<div>{item.paymentReferenceOrDescription}</div>
			<div
				className='df-row-ac'
				style={{ marginLeft: 8 }}>
				{item.paymentId && (
					<TooltipWithSpan title={strings.clickToViewDetails}>
						<XViewBadgeIconButton
							style={{ marginRight: 8 }}
							size='small'
							onClick={() => onViewDetail(item.paymentId!)}>
							<AttachMoneyIcon fontSize='small' />
						</XViewBadgeIconButton>
					</TooltipWithSpan>
				)}
			</div>
		</div>
	);
};
