import { Card, CardContent, CardProps, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, useTheme } from '@material-ui/core';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { isLast } from 'framework/utils/array/isLast';
import { formatDate } from 'framework/utils/date/formatDate';
import { INoahDeviceForPatient, noahDeviceInventoryItemMapsQuery_byPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { NoahDeviceDialog } from '../../../inventoryManagement/noahDevices/single/detail/NoahDeviceDialog';

interface IProps extends IReloadTriggerProps, CardProps {
	patientId: string;
}

export const AllNoahDevicesCard = ({ patientId, reloadTrigger, style, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [noahDevices, reload] = useApiEffect(noahDeviceInventoryItemMapsQuery_byPatient, patientId);
	const { open, confirm } = useDialogsContext(reload);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	if (noahDevices === undefined) {
		return <div></div>;
	}

	const onViewDetail = (item: INoahDeviceForPatient) => {
		open(
			<NoahDeviceDialog
				open
				id={item.id}
				close={confirm}
			/>
		);
	};

	return (
		<Card
			{...rest}
			style={{ marginBottom: theme.spacing(2), ...style }}>
			<CardTitleTypography
				withPadding
				title={strings.allDevicesFromNoah}
			/>
			<CardContent style={{ paddingTop: 0 }}>
				{noahDevices.length > 0 && (
					<List dense>
						{noahDevices.map(t => (
							<React.Fragment key={`${t.serialNumber}-${t.lastSelectionDate}`}>
								<ListItem
									key={t.id}
									disableGutters
									dense>
									<ListItemText
										primary={`${t.manufacturerCommaInstrumentTypeName} ${t.serialNumber}`}
										secondary={`${formatDate(t.lastSelectionDate)} (${
											t.lastUsage.isLeft ? strings.left : t.lastUsage.isRight ? strings.right : strings.remote
										})`}
									/>
									<ListItemSecondaryAction style={{ right: 0 }}>
										<SmallPrimaryTextButton onClick={() => onViewDetail(t)}>{strings.details}</SmallPrimaryTextButton>
									</ListItemSecondaryAction>
								</ListItem>
								{isLast(t, noahDevices) === false && <Divider />}
							</React.Fragment>
						))}
					</List>
				)}
				{noahDevices.length === 0 && <NoRecordsFound>{strings.noWhatRegistered(strings.devicesFromNoah)}</NoRecordsFound>}
			</CardContent>
		</Card>
	);
};
