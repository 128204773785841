import React, { useState } from 'react';
import { useApiCall } from 'framework/hooks/useApiCall';
import { IProductCatalogItem, IQueryResult, productCatalogQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { FilterProductCatalogType } from 'app/main/inventoryManagement/productCatalog/search/FilterProductCatalogType';
import { ICommonParams as IProductCatalogCommonParams } from 'app/main/inventoryManagement/productCatalog/search/ICommonParams';
import { SearchProductCatalog } from 'app/main/inventoryManagement/productCatalog/search/SearchProductCatalog';
import { SwipeableViewsWithTabs } from '../swipeableViews/SwipeableViewsWithTabs';
import { TabPanel } from '../swipeableViews/TabPanel';

interface IProps<TParams extends IProductCatalogCommonParams> {
	wizardContent: JSX.Element;
	params: TParams;
	setParams: React.Dispatch<React.SetStateAction<TParams>>;
	queryResult: IQueryResult<IProductCatalogItem> | undefined;
	onAddPc: (item: IProductCatalogItem) => void;
	productCatalogFilterType: FilterProductCatalogType;
}

export const SearchProductCatalogAndWizardTabsContainer = <TParams extends IProductCatalogCommonParams>({
	wizardContent,
	onAddPc,
	productCatalogFilterType,
	params,
	setParams,
	queryResult,
}: IProps<TParams>) => {
	const strings = useLocalization();
	const [tab, setTab] = useState<number>(0);
	const loadProductCatalog = useApiCall(productCatalogQuery_single);

	const onAddProductCatalogItem = async (item: IProductCatalogItem) => {
		const r = await loadProductCatalog(item.id);
		if (r.isSuccess) {
			item = r.result;
			onAddPc(r.result);
		}
	};

	return (
		<SwipeableViewsWithTabs
			tab={tab}
			setTab={setTab}
			textColor='secondary'
			indicatorColor='secondary'
			tabs={[strings.fromProductCatalog, strings.wizardAndHelp]}
			style={{ width: '500px', marginTop: '-10px' }}>
			<TabPanel
				value={tab}
				index={0}>
				<SearchProductCatalog
					params={params}
					setParams={setParams}
					queryResult={queryResult}
					onAdd={onAddProductCatalogItem}
					style={{ width: '500px' }}
					filterType={productCatalogFilterType}
				/>
			</TabPanel>
			<TabPanel
				value={tab}
				index={1}>
				{wizardContent}
			</TabPanel>
		</SwipeableViewsWithTabs>
	);
};
