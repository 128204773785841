import { Card, CardContent, CardProps } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { calcTenderDetailRoute } from 'app/main/financial/routes';
import { TenderModelForm } from 'app/main/financial/tenders/forms/TenderModelForm';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { isLast } from 'framework/utils/array/isLast';
import { ITender } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TenderByPatientListComponent } from './TenderByPatientListComponent';

interface IProps extends CardProps {
	items: ITender[];
	patientId: string;
	reload: VoidFunction;
}

export const TendersByPatientCard = ({ items, patientId, reload, ...rest }: IProps) => {
	const strings = useLocalization();
	const [expandedId, setExpandedId] = useState<string>();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();

	const onAdd = () => {
		open(
			<TenderModelForm
				open
				patientId={patientId}
				confirm={id => {
					confirm();
					if (id) {
						push(calcTenderDetailRoute(id));
					}
				}}
				cancel={cancel}
			/>
		);
	};

	return (
		<Card {...rest}>
			<CardContent>
				<CardTitleTypography
					title={strings.tenders}
					style={{ marginBottom: 8 }}
				/>
				<TextLinkButton
					startIcon={<AddCircleOutlineIcon />}
					onClick={onAdd}
					color='primary'>
					{strings.newTender}
				</TextLinkButton>
				<DividerWithMargin />
				{items.map(t => (
					<TenderByPatientListComponent
						item={t}
						key={t.id}
						isExpanded={expandedId === t.id}
						toggle={() => (expandedId === t.id ? setExpandedId(undefined) : setExpandedId(t.id))}
						withoutBorder={isLast(t, items)}
					/>
				))}
				{items.length === 0 && <em>{strings.noTendersRegisteredYet}</em>}
			</CardContent>
		</Card>
	);
};
