import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { IProductCatalogItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: IProductCatalogItem;
	short?: boolean;
	style?: React.CSSProperties;
}

export const ProductCatalogChips = ({ item, short = false, style }: IProps) => {
	const strings = useLocalization();

	return (
		<div
			className='df-row-ac'
			style={style}>
			{item.isDeleted && (
				<RectangularChip
					label={strings.deleted}
					fill
					color='error'
					style={{ marginRight: 8 }}
				/>
			)}
			{item.hasSerialNumber && (
				<RectangularChip
					tooltip={short ? strings.hasSerialNumber : undefined}
					label={short ? strings.serialNumberShort : strings.hasSerialNumber}
					style={{ marginRight: 8 }}
				/>
			)}
			{item.isTailorMade && (
				<RectangularChip
					tooltip={short ? strings.isTailorMade : undefined}
					label={short ? strings.tailorMadeShort : strings.isTailorMade}
				/>
			)}
		</div>
	);
};
