import { ISortParams } from '../types/ISortParams';

export function nextSortParams<T>(inst: ISortParams<T>, property: keyof T | undefined): ISortParams<T> {
	if (property === undefined) {
		return inst;
	} else if (inst.property === property) {
		return { ...inst, property: property, direction: inst.direction === 'asc' ? 'desc' : 'asc' };
	} else {
		return { ...inst, property: property, direction: 'asc' };
	}
}
