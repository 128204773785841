import React, { useState } from 'react';
import { IRequestForInsuranceAllowance } from 'gen/ApiClient';
import { EndAllTrialsModelForm } from './EndAllTrialsModelForm';
import { RegisterRfiaTrialForm } from './RegisterRfiaTrialForm';

type Mode = 'end-previous' | 'start-new';

interface IProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const EndAndStartNewTrialForm = ({ rfia, cancel, confirm }: IProps) => {
	const [mode, setMode] = useState<Mode>('end-previous');

	if (mode === 'end-previous') {
		return (
			<EndAllTrialsModelForm
				open
				rfiaId={rfia.id}
				confirm={() => setMode('start-new')}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<RegisterRfiaTrialForm
				open
				confirm={confirm}
				cancel={cancel}
				rfia={rfia}
			/>
		);
	}
};
