import { ButtonProps } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { SmallPrimaryTextButton } from './SmallPrimaryTextButton';

export const AddSmallPrimaryTextButton = ({ children, ...props }: ButtonProps) => {
	const strings = useLocalization();
	return (
		<SmallPrimaryTextButton
			startIcon={<AddIcon />}
			{...props}>
			{children ? children : strings.new}
		</SmallPrimaryTextButton>
	);
};
