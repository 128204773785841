import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectFieldFromStrings } from 'framework/forms/FormSelectFieldFromStrings';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { ILocationSummary, IPatchUserRoleRequest, IUserDto, IUserInfo, UserRole, usersCommand_patchRole } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import * as yup from 'yup';
import { LocationsPreloadCacheContext } from '../../locations/LocationsPreloadCacheContext';
import { userToSelectableRoles } from '../utils/userToSelectableRoles';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchUserRoleRequest>({
		userId: yup.string().required(),
		role: yup.mixed<UserRole>(),
	});
};

interface IProps extends DialogProps {
	user: IUserDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const ChangeUserRoleForm = ({ user, confirm, cancel, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(usersCommand_patchRole);
	const strings = useLocalization();
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const loggedInUser = useLoggedInUser();

	const handleSubmit = async (values: IPatchUserRoleRequest, helpers: FormikHelpers<IPatchUserRoleRequest>) => {
		const r = await update(values);
		if (handleValidateResponse(r, helpers, 'role')) {
			notify(strings.changedWhat(strings.role));
			confirm();
		}
	};

	if (locations === undefined || user === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IPatchUserRoleRequest>
			validateOnMount
			initialValues={{
				userId: user.id,
				role: 'Normal',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					isSubmitting={isSubmitting}
					locations={locations}
					cancel={cancel}
					user={user}
					loggedInUser={loggedInUser}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	locations: ILocationSummary[];
	user: IUserDto;
	loggedInUser: IUserInfo;
}

const InnerDialog = ({ cancel, isSubmitting, locations, loggedInUser, user, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const userRoleOptions = useMemo(() => userToSelectableRoles(loggedInUser), [loggedInUser]);
	const { userRoleRecord } = useContext(RecordContext);

	return (
		<Dialog
			{...rest}
			fullWidth
			maxWidth='xs'>
			<DialogTitle>{`${strings.changeWhat(strings.role)}: ${user.lastNameCommaFirstName}`}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<FormSelectFieldFromStrings<IPatchUserRoleRequest>
					name='role'
					options={userRoleOptions}
					label={strings.role}
					renderValue={t => userRoleRecord[t as UserRole]}
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions
				submitText={strings.change}
				isSubmitting={isSubmitting}
				cancel={cancel}
			/>
		</Dialog>
	);
};
