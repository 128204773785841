import { useLoggedInUserIsSuperUserOrHasClientClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasClientClaim';
import { StatCardX } from 'framework/components/cards/StatCardX';
import { IPurchasesQueryMetadata } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PurchasesUblBufferRoute } from '../../routes';

interface IProps {
	metadata: IPurchasesQueryMetadata;
	countBuffer: number;
	style?: React.CSSProperties;
}

export const PurchasesListStats = ({ metadata, countBuffer, style }: IProps) => {
	const strings = useLocalization();
	const canImportUbl = useLoggedInUserIsSuperUserOrHasClientClaim('CanImportUblPurchase');
	const { push } = useHistory();

	return (
		<div
			className='df-row gap-16'
			style={style}>
			<StatCardX
				style={{ width: 200 }}
				title={strings.withOutstandingBalance}
				caption={`#`}>
				<span style={{ fontSize: 24 }}>{metadata.countNotPaid}</span>
			</StatCardX>
			<StatCardX
				style={{ width: 200 }}
				title={strings.overdue}
				caption={`#`}>
				<span style={{ fontSize: 24 }}>{metadata.countOverdue}</span>
			</StatCardX>
			{canImportUbl && (
				<StatCardX
					style={{ width: 200 }}
					title={strings.ublFilesToHandle}
					caption={strings.countFilesInBuffer}
					onClick={() => push(PurchasesUblBufferRoute)}>
					<span style={{ fontSize: 24 }}>{countBuffer}</span>
				</StatCardX>
			)}
		</div>
	);
};
