import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { rfiaSettingsCommand_patchDefaultRizivRefundVatPercentage } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { FormSelectVatPercentageField } from 'shared/forms/FormSelectVatPercentageField';
import * as yup from 'yup';

interface IModel {
	vatPercentage: number;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		vatPercentage: yup.number().required(strings.formRequired(strings.vatPercentage)),
	});
};

interface IProps extends DialogProps {
	initialValue: number;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const DefaultRizivRefundVatPercentageForm = ({ initialValue, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(rfiaSettingsCommand_patchDefaultRizivRefundVatPercentage);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await submit({ vatPercentage: values.vatPercentage });
		if (handleValidateResponse(r, helpers, 'vatPercentage')) {
			confirm();
		}
	};

	return (
		<Formik<IModel>
			validateOnMount
			validationSchema={createSchema(strings)}
			initialValues={{ vatPercentage: initialValue }}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.vatPercentage}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'
						style={{ overflow: 'visible' }}>
						<FormSelectVatPercentageField<IModel>
							name='vatPercentage'
							label={strings.vatPercentage}
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.update}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
