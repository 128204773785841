import { Table, TableContainer, useTheme } from '@material-ui/core';
import React from 'react';
import { ISortParams } from '../types/ISortParams';
import { IHeader } from './IHeader';
import { PaperOutlined } from './PaperOutlined';
import { TableHeader } from './TableHeader';
import { TableValues } from './TableValues';

interface IProps<T> {
	values: T[];
	headers: IHeader<T>[];
	getKey: (inst: T) => string;
	sortParams?: ISortParams<T>;
	setSortParams?: (params: ISortParams<T>) => void;
	padding?: number;
	withPaperContainer?: boolean;
	expandableContent?: (value: T) => JSX.Element;
	expandMultiple?: boolean;
	expandAll?: boolean;
	style?: React.CSSProperties;
	stickyHeader?: boolean;
}

export const TableWithHeadersAndValues = <T extends unknown>({
	values,
	headers,
	getKey,
	sortParams,
	setSortParams,
	padding,
	withPaperContainer = false,
	expandableContent,
	expandMultiple = false,
	expandAll = false,
	style,
	stickyHeader = true,
}: IProps<T>) => {
	const theme = useTheme();

	return (
		<TableContainer
			component={withPaperContainer ? PaperOutlined : ('div' as any)}
			style={style}>
			<Table stickyHeader={stickyHeader}>
				<TableHeader
					sortParams={sortParams}
					setSortParams={setSortParams}
					headers={headers}
					padding={padding ?? theme.spacing(1.5)}
				/>
				<TableValues<T>
					values={values}
					getKey={getKey}
					headers={headers}
					padding={padding ?? theme.spacing(1.5)}
					expandableContent={expandableContent}
					expandMultiple={expandMultiple}
					expandAll={expandAll}
				/>
			</Table>
		</TableContainer>
	);
};
