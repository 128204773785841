import { Collapse, Divider, useTheme } from '@material-ui/core';
import AdjustOutlinedIcon from '@material-ui/icons/AdjustOutlined';
import { calcTenderDetailRoute } from 'app/main/financial/routes';
import { calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { TextLinkButtonWithExpandIcon } from 'framework/components/buttons/TextLinkButtonWithExpandIcon';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ITender } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: ITender;
	isExpanded: boolean;
	toggle: VoidFunction;
	withoutBorder?: boolean;
}

export const TenderByPatientListComponent = ({ item, isExpanded, toggle, withoutBorder }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { push } = useHistory();

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8 }}>
				<AdjustOutlinedIcon style={{ color: theme.palette.success.main, fontSize: 22, opacity: 0.8 }} />
			</div>
			<div className='df-col'>
				<div
					className='df-row-ac'
					style={{ paddingBottom: 4 }}>
					<TextLinkButton
						onClick={() => push(calcTenderDetailRoute(item.id))}
						style={{ fontSize: MajorFontSize }}>
						{`${strings.tender}: ${item.reference}`}
					</TextLinkButton>
				</div>
				<div
					className='df-row-ac'
					style={{ fontSize: MinorFontSize, gap: 4 }}>
					<span>{`${strings.date}: ${formatDate(item.created)}`}</span>
					<span>{`${strings.totalAmount}: ${formatCurrency(item.priceInVat)} ${strings.inVatShort}`}</span>
					<TextLinkButtonWithExpandIcon
						toggle={toggle}
						isExpanded={isExpanded}>
						<span style={{ fontSize: MinorFontSize, fontWeight: 'unset' }}>{`# ${item.countItems.toString()} ${
							item.countItems === 1 || item.countItems === -1 ? strings.article.toLowerCase() : strings.articles.toLowerCase()
						}, # ${item.lines.length.toString()} ${item.lines.length === 1 ? strings.line.toLowerCase() : strings.lines.toLowerCase()}`}</span>
					</TextLinkButtonWithExpandIcon>
				</div>
				<Collapse in={isExpanded}>
					<div className='df-col'>
						<Divider
							variant='inset'
							style={{ marginTop: 8, marginBottom: 4 }}
						/>
						{item.lines.map(line => (
							<div
								className='df-row-ab'
								key={line.id}
								style={{ fontSize: MinorFontSize, gap: 4 }}>
								<span>{`${line.quantity.toString()} x`}</span>
								{line.hasProductCatalogItem && (
									<TextLinkButton
										underline
										style={{ fontSize: MinorFontSize }}
										onClick={() => push(calcProductCatalogDetailRoute(line.productCatalogItemId!))}>
										{line.description}
									</TextLinkButton>
								)}
								{line.hasProductCatalogItem === false && <span>{line.description}</span>}
							</div>
						))}
					</div>
				</Collapse>
			</div>
		</div>
	);
};
