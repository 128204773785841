import { useCalendarMode } from 'app/main/calendar/useCalendarMode';
import { IPatient } from 'gen/ApiClient';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { HamsPatientEventsCard } from './HamsPatientEventsCard';
import { RosaPatientEventsCard } from './RosaPatientEventsCard';
import { RosaPatientEventsNotConfiguredYetCard } from './RosaPatientEventsNotConfiguredYetCard';

interface IProps extends IReloadTriggerProps {
	patient: IPatient;
	reload: VoidFunction;
}

export const PatientDetailAppointmentsTab = ({ patient, reload, reloadTrigger, ...rest }: IProps) => {
	const calendarMode = useCalendarMode();

	return (
		<React.Fragment>
			{calendarMode === 'Rosa' && (
				<RosaPatientEventsCard
					patient={patient}
					reload={reload}
					reloadTrigger={reloadTrigger}
					{...rest}
				/>
			)}
			{calendarMode === 'Hams' && (
				<HamsPatientEventsCard
					patient={patient}
					reload={reload}
					reloadTrigger={reloadTrigger}
					{...rest}
				/>
			)}
			{calendarMode === 'NotSet' && <RosaPatientEventsNotConfiguredYetCard {...rest} />}
		</React.Fragment>
	);
};
