import { RRule, rrulestr } from 'rrule';
import { formatRrule } from './formatRrule';

// TODO move to RRules
export const calculateREndDate = (rrule: RRule, dtStart: Date): Date | undefined => {
	if ((rrule.options.count === null || rrule.options.count === undefined) && (rrule.options.until === null || rrule.options.until === undefined)) {
		return undefined;
	} else {
		const x = rrulestr(`RRULE:${formatRrule(rrule)}`, { dtstart: new Date(dtStart) });
		const all = x.all();
		return all[all.length - 1];
	}
};
