import { addMonths } from 'date-fns';
import { formatDateX } from 'framework/utils/date/formatDateX';
import { formatDateXLocalized } from 'framework/utils/date/formatDateXLocalized';
import { nlBELocaleEndOfWeek } from 'framework/utils/date/nlBELocaleEndOfWeek';
import { nlBELocaleStartOfWeek } from 'framework/utils/date/nlBELocaleStartOfWeek';
import { LanguageOption } from 'gen/ApiClient';
import { LanguageOptionToLocaleRecord } from 'localization/LanguageOptionToLocaleRecord';
import { IStrings } from 'localization/IStrings';
import { AgendaViewType } from './agenda/AgendaViewType';

export const renderDateDisplay = (val: Date, view: AgendaViewType, strings: IStrings, language: LanguageOption) => {
	if (view === 'list') {
		return `${strings.from}: ${formatDateX(val, 'd MMMM y')}`;
	} else if (view === 'day' || view === 'timeline') {
		return formatDateX(val, 'd MMMM y');
	} else if (view === 'week') {
		const start = val instanceof Date ? nlBELocaleStartOfWeek(val) : nlBELocaleStartOfWeek(new Date(val));
		const end = val instanceof Date ? nlBELocaleEndOfWeek(val) : nlBELocaleEndOfWeek(new Date(val));
		return `${strings.week} ${formatDateX(val, 'I')} (${formatDateX(start)} - ${formatDateX(end)})`;
	} else if (view === 'month') {
		return formatDateXLocalized(val, LanguageOptionToLocaleRecord[language], 'LLLL y');
	} else if (view === 'year') {
		return formatDateXLocalized(val, LanguageOptionToLocaleRecord[language], 'yyyy');
	} else {
		return `${formatDateXLocalized(val, LanguageOptionToLocaleRecord[language], 'LLLL')} - ${formatDateXLocalized(
			addMonths(val, 1),
			LanguageOptionToLocaleRecord[language],
			'LLLL y'
		)}`;
	}
};
