import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { SelectField } from 'framework/components/select/SelectField';
import { IPurchase } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	purchases: IPurchase[];
	confirm: (purchaseId: string) => void;
	cancel: VoidFunction;
}

export const SelectPurchaseForm = ({ confirm, cancel, purchases, ...rest }: IProps) => {
	const [purchase, setPurchase] = useState<IPurchase>();
	const strings = useLocalization();

	return (
		<Dialog
			{...rest}
			scroll='paper'
			fullWidth>
			<DialogTitle>{strings.selectWhat(strings.purchase)}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<SelectField<IPurchase>
					getKey={t => t.id}
					renderValue={t => t.reference!}
					onChange={setPurchase}
					value={purchase}
					options={purchases}
					label={strings.purchase}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button disabled>{strings.previous}</Button>
				<Button
					onClick={purchase ? () => confirm(purchase.id) : undefined}
					disabled={purchase === undefined}
					color='primary'>
					{strings.select}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
