import { Card, Divider, useTheme } from '@material-ui/core';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { createHeader } from 'framework/table/createHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { PageableTable } from 'framework/table/PageableTable';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IObjectWithIdAndIdentifier, IProductCatalogItem, IStockItemsQueryParamsForRepair, stockItemsQuery_queryForRepair } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { createSelectHeader } from 'shared/table/createSelectHeader';
import { ProductCatalogFilterDataContext } from '../../../inventoryManagement/productCatalog/context/ProductCatalogFilterDataContext';
import { FilterStockItems } from './FilterStockItems';

const DefaultParams: IStockItemsQueryParamsForRepair = {
	categories: [],
	subcategories: [],
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	types: [],
	isShowAll: false,
};

interface IProps {
	supplier: IObjectWithIdAndIdentifier<string>;
	selectItem: (item: IProductCatalogItem) => void;
	style?: React.CSSProperties;
}

export const SelectStockItem = ({ supplier, selectItem, style }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const [params, setParams, queryResult] = useApiEffectWithParams(pars => stockItemsQuery_queryForRepair(supplier.id, pars), DefaultParams);
	const [searchData] = useCacheContext(ProductCatalogFilterDataContext);

	const onFilter = (params: IStockItemsQueryParamsForRepair) => {
		setParams(onResetToPage1(params));
	};

	const headers = [
		createSortableHeader<IProductCatalogItem>(strings.brandAndModel, 'manufacturerCommaModel', t => t.manufacturerCommaModel),
		createHeader<IProductCatalogItem>(strings.suppliers, t => t.supplierIdentifierCsv),
		createSelectHeader<IProductCatalogItem>(t => selectItem(t)),
	];

	if (searchData === undefined || queryResult === undefined) {
		return <div></div>;
	}

	return (
		<div
			className='df-col'
			style={style}>
			<Card variant='outlined'>
				<div className='df-row-ac jc-sb'>
					<FilterStockItems
						params={params}
						onFilter={onFilter}
						data={searchData}
					/>
					<FormControlLabelSwitch
						isChecked={params.isShowAll}
						toggle={() => onFilter({ ...params, isShowAll: !params.isShowAll })}
						label={strings.viewAll}
					/>
				</div>
				<Divider />
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder={strings.searchVerb}
					variant='embedded'
				/>
				<Divider />
				{queryResult.filteredCount === 0 ? (
					<NoRecordsFound withPadding>{strings.noResultsCommaChangeFilterParams}</NoRecordsFound>
				) : (
					<PageableTable<IProductCatalogItem>
						padding={theme.spacing(1)}
						headers={headers}
						getKey={t => t.id}
						pageParams={params.pageParams}
						sortParams={params.sortParams}
						queryResult={queryResult}
						setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
					/>
				)}
			</Card>
		</div>
	);
};
