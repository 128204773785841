import { DialogContent, DialogProps, DialogTitle, Divider } from '@material-ui/core';
import { IOption } from 'framework/IOption';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { DatePickerX } from 'framework/components/datePickers/DatePickerX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { DialogX } from 'framework/dialogs/DialogX';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { RadioFilterComponent } from 'framework/filter/RadioFilterComponent';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { isLast } from 'framework/utils/array/isLast';
import { IMedicalTreatmentFeeDto, IMedicalTreatmentNomenclatureForAudiology, IPatient } from 'gen/ApiClient';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useState } from 'react';
import { MedicalTreatmentNomenclaturesCacheContext } from 'shared/context/MedicalTreatmentNomenclaturesCacheContext';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { getNow } from 'shared/utils/getNow';
import { AgeRestriction, AgeRestrictions } from './AgeRestriction';
import { MedicalTreatmentNomenclatureListItem } from './MedicalTreatmentNomenclatureListItem';
import { StereoMonoContralateralType, StereoMonoContralateralTypes } from './StereoMonoContralateralType';
import { ageToRestriction } from './utils/ageToRestriction';

interface IFilterData {
	isMain: boolean | undefined;
	isAmbulant: boolean | undefined;
	ageRestriction: AgeRestriction | undefined;
	stereoMonoOrNa: StereoMonoContralateralType | undefined;
	searchString: string;
}

const Now = getNow();

const filter = (data: IMedicalTreatmentNomenclatureForAudiology[], handoverDate: Date, params: IFilterData): IMedicalTreatmentFeeDto[] => {
	return data
		.filter(t => t.isPseudoCode === false)
		.filter(t => params.isMain === undefined || (params.isMain === true && t.isMain === true) || (params.isMain === false && t.isMain === false))
		.filter(t => params.isAmbulant === undefined || t.data.isAmbulant === params.isAmbulant)
		.filter(
			t =>
				params.stereoMonoOrNa === undefined ||
				(params.stereoMonoOrNa === 'stereo' && t.isStereo) ||
				(params.stereoMonoOrNa === 'mono' && t.isMono) ||
				(params.stereoMonoOrNa === 'contraLateral' && t.isContraLateral)
		)
		.filter(
			t =>
				params.ageRestriction === undefined ||
				(params.ageRestriction === '<18' && t.isAgeRestrictedToLessThan18Years) ||
				(params.ageRestriction === '>18' && t.isAgeRestrictedToOlderThan18Years) ||
				(params.ageRestriction === '>18&<65' && t.isAgeRestrictedBetween18And65Years) ||
				(params.ageRestriction === '>65' && t.isAgeRestrictedToOlderThan65Years)
		)
		.filter(t => `${t.data.code} ${t.data.descriptionNl} ${t.data.descriptionFr}`.toLowerCase().includes(params.searchString.toLowerCase()))
		.map<IMedicalTreatmentFeeDto | undefined>(t =>
			t.data.fees.find(
				x =>
					new Date(x.validityStart) <= new Date(handoverDate) &&
					(x.validityEnd === undefined || x.validityEnd === null || new Date(x.validityEnd) >= new Date(handoverDate))
			)
		)
		.filter(t => t !== undefined)
		.map<IMedicalTreatmentFeeDto>(t => t!);
};

interface IProps extends DialogProps {
	patient?: IPatient;
	confirm: (val: IMedicalTreatmentFeeDto) => void;
	cancel: VoidFunction;
}

export const SelectMedicalTreatmentAndFeeForm = ({ patient, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [data] = useCacheContext(MedicalTreatmentNomenclaturesCacheContext);
	const [params, setParams] = useState<IFilterData>({
		isMain: undefined,
		isAmbulant: true,
		stereoMonoOrNa: undefined,
		ageRestriction: undefined,
		searchString: '',
	});
	const [handoverDate, setHandoverDate] = useState<Date>(Now);
	const [filtered, setFiltered] = useState<IMedicalTreatmentFeeDto[]>([]);
	const language = useCurrentLanguage();
	const { stereoMonoContralateralRecord } = useContext(RecordContext);
	const { isStacked } = useDialogsContext();

	useEffect(() => {}, []);

	useEffect(() => {
		if (patient?.age) {
			setParams({
				isMain: undefined,
				isAmbulant: true,
				stereoMonoOrNa: undefined,
				ageRestriction: ageToRestriction(patient.age),
				searchString: '',
			});
		}
	}, [patient]);

	useEffect(() => {
		if (data !== undefined && handoverDate !== undefined) {
			setFiltered(filter(data, handoverDate, params));
		} else {
			setFiltered([]);
		}
	}, [params, handoverDate, data]);

	return (
		<DialogX
			{...rest}
			scroll='paper'
			open={rest.open && isStacked === false}>
			<CloseButtonOnAbsolutePosition onClick={cancel} />
			<DialogTitle>{strings.selectWhat(strings.nomenclatureCode)}</DialogTitle>
			<DialogContent
				className='df-col gap-12'
				dividers>
				<DatePickerX
					label={strings.expectedHandoverDate}
					value={handoverDate}
					setValue={val => setHandoverDate(val)}
				/>
				<div className='df-col-as gap-4'>
					<BooleanRadioFilterComponent
						badgeVariant='dot'
						trueText={strings.ambulatory}
						falseText={strings.hospitalized}
						selected={params.isAmbulant}
						setSelected={val => setParams({ ...params, isAmbulant: val })}
						label={params.isAmbulant ? strings.ambulatory : strings.hospitalized}
					/>
					<BooleanRadioFilterComponent
						badgeVariant='dot'
						trueText={strings.mainNomenclatureCode}
						falseText={strings.additionalNomenclatureCodes}
						selected={params.isMain}
						setSelected={val => setParams({ ...params, isMain: val })}
						label={params.isMain ? strings.mainNomenclatureCode : strings.additionalNomenclatureCodes}
					/>
					<RadioFilterComponent<AgeRestriction>
						badgeVariant='dot'
						options={AgeRestrictions.map<IOption<AgeRestriction>>(t => ({ id: t, identifier: t }))}
						selected={params.ageRestriction}
						setSelected={val => setParams({ ...params, ageRestriction: val })}
						label={params.ageRestriction ? params.ageRestriction : strings.age}
					/>
					<RadioFilterComponent<StereoMonoContralateralType>
						badgeVariant='dot'
						options={StereoMonoContralateralTypes.map<IOption<StereoMonoContralateralType>>(t => ({ id: t, identifier: stereoMonoContralateralRecord[t] }))}
						selected={params.stereoMonoOrNa}
						setSelected={val => setParams({ ...params, stereoMonoOrNa: val })}
						label={
							params.stereoMonoOrNa === undefined
								? `${strings.stereoPhonic}/${strings.monoPhonic}/${strings.contraLateralEquipment}`
								: stereoMonoContralateralRecord[params.stereoMonoOrNa]
						}
					/>
				</div>
				<SearchbarX
					value={params.searchString}
					onSearch={text => setParams({ ...params, searchString: text })}
					placeholder={strings.searchVerb}
					style={{ width: '100%' }}
				/>
				<div className='df-col gap-4'>
					{filtered.map(t => (
						<React.Fragment>
							<MedicalTreatmentNomenclatureListItem
								item={t}
								key={t.code}
								onSelect={() => confirm(t)}
								language={language}
							/>
							{isLast(t, filtered) === false && <Divider />}
						</React.Fragment>
					))}
				</div>
			</DialogContent>
		</DialogX>
	);
};
