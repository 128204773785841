import { enFrameworkStrings } from 'framework/localization/enFrameworkStrings';
import { IStrings } from './IStrings';

export const enStrings: IStrings = {
    ...enFrameworkStrings,
    acceptFittingContent: `Continue to invoicing or end this flow`,
    accessToFunctions: `Access to functions`,
    accessToPages: `Access to pages`,
    accountNumber: `Account number`,
    accountPayable: `Account payable`,
    accountReceivable: `Account receivable`,
    accountReceivableSingularOrPlural: `Account(s) receivable`,
    accountsPayable: `Accounts payable`,
    accountsPayableShort: `Accounts pay.`,
    accountsReceivable: `Accounts receivable`,
    accountsReceivableShort: `Account rec.`,
    action: `Actions`,
    actionsWillBeDeletedFromThisList: `Actions will be deleted from this list`,
    actionsWillBeVisibleAgainInThisList: `Actions will be visible again in this list`,
    activated: `Activated`,
    activateExactOnlineIntegration: `Activate Exact Online integration`,
    activateIntegration: `Activate integration`,
    activateVerb: `Activate`,
    active: `Active`,
    activeAndExpired: `Active & Expired`,
    activeNow: `Active now`,
    activeTreatments: `Active treatments`,
    actual: `Actual`,
    actualDeliveryDate: `Actual delivery date`,
    actualEndDate: `Actual end date`,
    actualEquipment: `Actual equipment`,
    add: `Add`,
    addALine: `Add a line`,
    addALineForExampleDiscount: `Add a line (e.g. discount)`,
    addALineWithDifference: `Add a line with the difference`,
    addAnEmailAdress: `Add an email adress`,
    addArticlesFromProformaHandover: `Add articles from pro forma handover`,
    addAttachment: `Add attachment`,
    addContactInfo: `Add contact info`,
    addContactPerson: `Add contact person`,
    addDefineInventoryItem: `Add (define) inventory item`,
    addDevicesFromNoah: `Add devices from Noah`,
    addedWhat: (what: string | number) => `Added ${what.toString().toLowerCase()}`,
    addEmailAddress: `Add email address`,
    addImperative: `Add`,
    addItemForRepair: `Add item for repair`,
    addItems: `Add items`,
    additionalNomenclatureCodes: `Additional nomenclature code(s)`,
    additionalNomenclatures: `Additional nomenclatures`,
    addLines: `Add lines`,
    addLinesToExistingOrder: `Add lines to existing order`,
    addLinesToThisOrder: `Add lines to this order`,
    addLocation: `Add location`,
    addNeeds: `Add needs`,
    addOffice: `Add office`,
    addProcess: `Add process`,
    addRemark: `Add remark`,
    addReservedArticles: `Add reserved articles`,
    address: `Address`,
    addressData: `Address data`,
    addressLine: `Street and number`,
    addressUpdated: `Address updated`,
    addToExistingRepair: `Add to existing repair`,
    addToExistingRepairCaption: `Article will be added to a not sent repair`,
    addToSettlementProposal: `Add to settlement proposal`,
    adjustWhat: (what: string | number) => `Adjust ${what.toString().toLowerCase()}`,
    administratively: `Adminstrative`,
    advance: `Advance`,
    advancedFilters: `Advanced filters`,
    advancedInventoryFilters: `Advanced inventory filters`,
    advanceInvoice: `Advance invoice`,
    advanceInvoices: `Advance invoices`,
    advisoryPhysician: `Advisory physician`,
    after: `After`,
    afterAccents: `After`,
    afterDate: `After date`,
    afterTreatment: `After treatment`,
    age: `Age`,
    agenda: `Schedule`,
    aHearingAidCreatefromLimitativeListNoahOrManual: `A hearing aid\nCreate from limitative list, Noah or manual`,
    all: `All`,
    allAppointments: `All appointments`,
    allArticlesLinked: `All articles linked`,
    allCommissions: `All commissions`,
    allCustomersLinked: `All customers linked`,
    allDevicesFromNoah: `All devices from Noah`,
    allFields: `All fields`,
    allForwarded: `All forwarded`,
    allocateArticlesFromStock: `Allocate articles from stock`,
    allPayouts: `All payouts`,
    allRefundsAreCollectedOnASettlementProposal: `All refunds are processed on a settlement proposal`,
    allSales: `All sales`,
    allSalesSynced: `All sales synced`,
    allUsers: `All users`,
    allWhat: (what: string | number) => `All ${what.toString().toLowerCase()}`,
    allWorkscheduleEntries: `All entries`,
    almostAlways: `Almost always`,
    almostNever: `Almost never`,
    alreadyPaid: `Already paid`,
    alreadyProformaAttachmentPresentDeleteFirstQuestion: `Proforma attachment 12 already created\n\nDo you want to delete this data or just re-generate the document?`,
    alsoChangeAdvisoryPhysicianAddress: `Also change address of advisory physician`,
    alsoChangeThirdPayerArrangementAddress: `Also change address of third payer arrangement`,
    alsoChangeWithPatient: `Also change data with patient?`,
    alsoCreatePatientInNoah: `Also create patient in Noah?`,
    alsoExistsInNoah: `Also exists in Noah`,
    alsoSelectACharger: `Select a charger`,
    alsoSelectAnEarmould: `Select an earmould`,
    alsoSelectAReceiver: `Select a receiver`,
    alsoShowDeleted: `Also show deleted`,
    alsoShowRemarksOfPatient: `Also show remarks of patient`,
    alsoUpdateOnProcesses: `Also update on processes`,
    alternativeAddress: `Alternative address`,
    alternativeLogo: `Alternative logo`,
    alwaysImport: `Import always`,
    ambulatory: `Ambulatory`,
    amountAsValue: `Amount`,
    amountFromSale: `Amount from sale`,
    amountMinusContributions: `Amount minus contributions`,
    amountPaid: `Amount paid`,
    amountToPay: `Amount to pay`,
    analysis: `Analysis`,
    anamnesis: `Anamnesis`,
    anExample: `An example`,
    anItem: `An item`,
    annotation: `Annotation`,
    annotations: `Annotations`,
    applicable: `Applicable`,
    apply: `Apply`,
    applyCodeForAllArticlesOfWhatQuestion: (what: string | number) => `Apply code for all articles of '${what.toString().toLowerCase()}'?`,
    applyOn: `Apply on`,
    appNotYetRegistered: `App not registered yet`,
    appointment: `Appointment`,
    appointmentPlanned: `Appointment planned`,
    appointments: `Appointments`,
    appRegistered: `App registered`,
    approval: `Approval`,
    approvalAdvisoryPhysician: `Approval advisory physician`,
    approvalAdvisoryPhysicianCaption: `Approval advisory physician received and signed`,
    approvalAdvisoryPhysicianReceived: `Approval adv. phys. received`,
    approvalDate: `Approval date`,
    approvalEntPhysicianCaption: `Medical prescription received and signed by ENT physician.`,
    approved: `Approved`,
    approvedByAdvisoryPhysicianShort: `Approved by adv. phys.`,
    aRemoteDevice: `A remote device`,
    areYouSureYouWantToCancelThisOrderQuestion: `Are you sure you want to cancel this order?`,
    areYouSureYouWantToDeleteThisAppointmentQuestion: `Are you sure you want to delete this appointment?`,
    areYouSureYouWantToDeleteThisEntPhysician: `Are you sure you want to delete this ENT physician?`,
    article: `Article`,
    articleFromProductCatalogFoundWithReference: (whatX: string | number) => `Article from product catalog found (ref. supplier: ${whatX})`,
    articleIsRegisteredAsDemo: `Article is registered as DEMO`,
    articleMayNotHaveASerialNumberRemoveFromAllowances: `Article may not have a serial number, remove from allowances`,
    articleNotFoundInProductCatalogWithReference: (whatX: string | number) => `Article not found in product catalog (ref. supplier: ${whatX})`,
    articleNumber: `Article #`,
    articleReservedForWho: (who: string | number) => `Article reserved for ${who}`,
    articles: `Articles`,
    articlesInquiries: `Articles inquiries`,
    articlesInquiry: `Articles inquiry`,
    articlesInquiryToAssignSingularOrPlural: `Articles inquiries to assign`,
    articlesRegistered: `Articles registered`,
    askEveryTime: `Ask every time`,
    askEveryTimeHandoverModeCaption: `At each handover ask again`,
    assigned: `Assigned`,
    assignFromStock: `Assign from stock`,
    atPatient: `At patient`,
    atSupplier: `At supplier`,
    atSupplierForRepair: `At supplier for repair`,
    attachment: `Attachment`,
    attachment12AndInvoice: `Attachment 12 and invoice`,
    attachments: `Attachments`,
    attendingAudiologistWillAlsoBeChangedOnOtherProcesses: `Attending audiologist will also be changed on active processes`,
    attendingAudiologistWillAlsoBeChangedWithPatient: `Attending audiologist will also be changed at patient`,
    attendingAudiologistWillNotBeChangedWithPatient: `Attending audiologist will not be changed at patient`,
    attendingAudiologistWillOnlyBeChangedWithThisPatientAndNotOnProcesses: `Attending audiologist will only be changed on patient and not on active processes`,
    attendingDoctors: `Attending physicians`,
    audiogram: `Audiogram`,
    audiograms: `Audiograms`,
    audiologist: `Audiologist`,
    authorization: `Authorized`,
    autoGenerateSerialNumberQuestion: `Auto generate serial number?`,
    autoPatientNumbering: `Auto patient numbering`,
    autoReloadInXSeconds: (count: string | number) => `Auto reload in ${count.toString().toLowerCase()} seconds`,
    autoSelectIndexNumberQuestion: `Automatically select index number?`,
    autoSelectPaymentReferenceQuestion: `Automatically select payment reference?`,
    autoSetOrderNumber: `Automatically set order number`,
    available: `Available`,
    availableForWhat: (what: string | number) => `Available for ${what.toString().toLowerCase()}`,
    availableHifsAutomaticallySyncedWithRizivData: `Available health insurance funds in sync with data from RIZIV`,
    availableInCalendar: `Available in calendar`,
    availableInCalendarCaption: `Appointments can be planned on this location`,
    average: `Average`,
    averageNumberOfHoursPerDayWearingHearingAids: `Average wearing time`,
    averagePurchasePrice: `Average purchase price`,
    b10Caption: `Yellow | 5,8x3,6mm | Mini RITE of CIC`,
    b13Caption: `Red | 7,9x5,4mm | BTE of ITE`,
    b312Caption: `Brown | 7,9x3,6mm | Mini BTE of RITE of ITC`,
    b675Caption: `Blue | 11,6x5,4mm | Power BTE`,
    balance: `Balance`,
    bancontact: `Bancontact`,
    bankData: `Bank data`,
    bankTransfer: `Bank transfer`,
    base: `Base`,
    basedOn: `Based on`,
    baseInformation: `Basic information`,
    batteries: `Batteries`,
    batteryType: `Type battery`,
    bebatRate: `Bebat rate`,
    before: `Before`,
    beforeAccents: `Before`,
    behindTheEar: `Behind the ear`,
    beIDMiddlewareIsNotInstalled: `BeID middleware is not installed`,
    better: `Better`,
    birthDateUnknown: `Date of birth unknown`,
    block: `Block`,
    blocked: `Blocked`,
    blockUser: `Block user`,
    blockUserContent: `A blocked user won't be able to login.`,
    blockUserQuestion: `Block user?`,
    boneAnchorage: `Bone anchorage`,
    brand: `Brand`,
    brandAndModel: `Brand and model`,
    brandName: `Brand name`,
    brands: `Brands`,
    broken: `Broken`,
    bteCaptionTypes: `Behind the ear (RITE, Power, Open Ear, Open Fit, …)`,
    bulkImport: `Bulk import`,
    bulkRepair: `Bulk repair`,
    calculation: `Calculation`,
    calculations: `Calculations`,
    calendar: `Calendar`,
    canBeUsedForWhat: (what: string | number) => `Can be used for ${what.toString().toLowerCase()}`,
    cancelArticlesInquiry: `Cancel articles inquiry`,
    cancellationReason: `Reason of cancellation`,
    cancelled: `Cancelled`,
    cancelledAfterPartlyDelivery: `Cancelled after partial delivery`,
    cancelledAfterShipment: `Cancelled (after shipment)`,
    cancelledBeforeShipment: `Cancelled (before shipment)`,
    cancelledWhat: (what: string | number) => `${what} cancelled`,
    cancelLoan: `Cancel loan`,
    cancelOrder: `Cancel order`,
    cancelSomething: (something: string | number) => `Cancel ${something.toString().toLowerCase()}`,
    cancelWhat: (what: string | number) => `Cancel ${what.toString().toLowerCase()}`,
    cannotChangeRepititionToDoThisDeleteEntireSequence: `Cannot change repetition, to do this delete the entire sequence`,
    cannotReachServerNoInternetConnection: `Cannot reach server, no internet connection`,
    canReturn: `Can be terminated`,
    canViewInAppOrBrowser: `Can view in browser or app`,
    cash: `Cash`,
    cashDesk: `Cashier`,
    cashDeskLocation: `Uses cashier`,
    cashRegisterAsLedger: `Cash register`,
    cashSale: `Cash sale`,
    cashSaleForPatient: `Cash sale for patient`,
    cashSales: `Cash sales`,
    cashSalesAreNotLinkedToAnAccountReceivableGoToCashRegister: `Cash sales are not linked to an account receivable\nNavigate to cash register.`,
    cashTransaction: `Cash transaction`,
    cashTransactions: `Cash transactions`,
    catalogNumber: `Catalog number`,
    categories: `Categories`,
    category: `Category`,
    change: `Change`,
    changed: `Changed`,
    changeDateOrNomenCode: `Change date or change nomenclature codes`,
    changeDefaultDeliveryTime: `Change default delivery time`,
    changeDetailsEntPhysician: `Change details ENT physician`,
    changedWhat: (what: string | number) => `${what} changed`,
    changeEmail: `Change email`,
    changeName: `Change name`,
    changeOnlyAdvisoryPhysicianAddress: `Only change address of advisory physician`,
    changeOnlyThirdPayerArrangementAddress: `Only change address of third payer arrangement`,
    changeOrientation: `Change orientation`,
    changePassword: `Change password`,
    changePhone: `Change phone number`,
    changePrice: `Change price`,
    changePrices: `Change prices`,
    changeProcessSteps: `Change process steps`,
    changeProductCatalog: `Change product catalog`,
    changeRemark: `Change remarks`,
    changeRIZIVNumber: `Change RIZIV number`,
    changeSomething: (something: string | number) => `Change ${something.toString().toLowerCase()}`,
    changeStartModule: `Change (start module)`,
    changesToDetailScreen: `Changes to detail screen`,
    changesToDetailScreenAlertDescription: `Soon the patient detail screen will be replaced with an improved view. This screen is the new enhanced view.`,
    changeSupplierContactPerson: `Change supplier contact person`,
    changeWhat: (what: string | number) => `Change ${what.toString().toLowerCase()}`,
    charger: `Charger`,
    chargerNewArticleCaption: `Charger (for rechargeable hearing aids)`,
    checkAgain: `Check again`,
    checkDocuments: `Check documents`,
    checkoutVerb: `Checkout`,
    circularLetters: `Circular letters`,
    city: `City`,
    clearFilter: `Clear filter`,
    clearLink: `Clear link`,
    clearRIZIVContent: `User will be marked as 'no' audiologist`,
    clearRIZIVQuestion: `Clear RIZIV link?`,
    click: `Click`,
    clickHere: `Click here`,
    clickHereForMoreInformationAboutROSA: `Click here for more information about ROSA`,
    clickHereToNavigateToNewView: `Click here to navigate to the new view.`,
    clickHereToViewDocumentation: `Click here to view documentation`,
    clickToAdd: `Click to add`,
    clickToViewDetails: `Click here to view details`,
    clickToViewHamsPatient: `Click here to view HAMS patient`,
    clickToViewItemWithSerialNumber: `Click to view item with this serial number`,
    clickToViewMore: `Click here to view more`,
    clickToViewProductCatalog: `Click to view product catalog`,
    close: `Close`,
    closeSearch: `Close search`,
    closeThisWindow: `Close this window`,
    cltTest: `CLT test`,
    code: `Code`,
    codesLinkedToRequest: (codes: string | number) => `${codes} linked to request`,
    codesSingularOrPlural: `Code(s)`,
    color: `Color`,
    colorScheme: `Color scheme`,
    column: `Column`,
    combinationOfBrandAndModelExists: `This combination of model and brand already exists.`,
    commission: `Commission`,
    commissions: `Commissions`,
    companyData: `Company data`,
    companyName: `Name of company`,
    comparedWithSamePeriodLastYearBracketsWhat: (what: string | number) => `Compared with same period last year (${what.toString().toLowerCase()})`,
    comparisonAll: `Comparison all`,
    comparisonStereo: `Comparison stereo`,
    compensation: `Compensation`,
    completed: `Completed`,
    completelyDelivered: `Completely delivered`,
    configuration: `Configuration`,
    configurationCorrect: `Configuration correct`,
    confirm: `Confirm`,
    confirmation: `Confirmation`,
    confirmChoice: `Confirm choice`,
    confirmDelivery: `Confirm delivery`,
    confirmed: `Confirmed`,
    confirmNewPassword: `Confirm new password`,
    confirmPassword: `Confirm password`,
    confirmUserHeader: `Fill in a password`,
    connection: `Connection`,
    connectionStatus: `Connection status`,
    connectReader: `Connect a card reader`,
    consignment: `Consignment`,
    consignmentData: `Consignment data`,
    consignmentExpired: `Consignment expired`,
    consignmentExpiresWithinAWeek: `Consignment expires within week`,
    consignmentWarning: `Consigment warning`,
    contact: `Contact`,
    contactAHamsAdminUserForMoreInformation: `Contact a HAMS admin user for more information`,
    contactData: `Contact data`,
    contactHAMSForValidLicense: `Contact HAMS for a valid license`,
    contactInfoAdded: `Contact info added`,
    contactInfoUpdated: `Contact info updated`,
    contactPerson: `Contact person`,
    contacts: `Contacts`,
    contactWho: (who: string | number) => `Contact ${who}`,
    contents: `Contents`,
    continue: `Continue`,
    contraLateralEquipment: `Controlateral equipment`,
    contribution: `Contribution`,
    contributionCodeForPatient: (code: string | number, who: string | number) => `Contribution ${code.toString().toLowerCase()} for ${who}`,
    contributions: `Contributions`,
    conventionedEqualsPrecededByOne: `Conventioned = preceded by '1'`,
    conventionedRIZIVNumber: `Conventioned RIZIV number`,
    conventionedRIZIVNumberShort: `Conv. RIZI #`,
    convertedToSales: `Converted to sales`,
    copyVatDataFromPatient: `Copy data from patient`,
    cosiNeed: `COSI need`,
    cosiQuestionnaire: `COSI questionnaire`,
    countArticlesNotLinked: (count: string | number) => `${count} articles not linked`,
    countCustomersNotLinked: (count: string | number) => `${count} customers not linked`,
    countDeliveries: `Count deliveries`,
    countEventsAreLinkedToThisLocationDoYouWantToContinueQuestion: (count: string | number) => `${count} appointments are linked to this location.
If you continue, this location will be removed from all appointments.
Do you wish to continue?`,
    countEventsAreLinkedToThisUserDoYouWantToContinueQuestion: (count: string | number) => `${count} appointments are linked to this user.
If you continue, this user will be removed from all appointments.
Do you wish to continue?`,
    countFilesInBuffer: `# files in buffer`,
    countInvoicesNotForwarded: (count: string | number) => `${count} invoices not forwarded`,
    countOrders: `Count orders`,
    countOrdersToPlaceWithSupplier: (count: string | number) => `# ${count.toString().toLowerCase()} orders to place with supplier`,
    countPatientRecordsFromRosaNotImported: (count: string | number) => `${count} ROSA patient(s) not imported`,
    countPatientsImported: (count: string | number) => `${count} patient(s) imported`,
    countPatientsNotImported: (count: string | number) => `${count} patient(s) not imported`,
    countPurchases: `Count purchases`,
    countRosaUsersNotLinked: (count: string | number) => `${count} ROSA users not linked`,
    country: `Country`,
    countSalesNotSynced: (count: string | number) => `${count} sales not synced`,
    countUsersLinked: (count: string | number) => `${count} users linked`,
    countUsersNotLinked: (count: string | number) => `${count} users not linked`,
    create: `Create`,
    createADocumentRightAwayQuestion: `Create a document right away?`,
    createANewPatientInHAMS: `Create a new patient in HAMS`,
    createANewSupplier: `Create a new supplier`,
    createAnOrderForThisNeed: `Make a new order for this need`,
    created: `Created`,
    createdAt: `Created at`,
    createdBracketsHow: (how: string | number) => `Create (${how.toString().toLowerCase()})`,
    createdByUser: `Created by user`,
    createdFromUploadWithSignature: `Created (upload) with signature`,
    createDocument: `Create document`,
    createDocumentFromPersonalisedVersion: `Create document from a personalised version`,
    createDocumentFromStandardisedVersion: `Create document from a standardised version`,
    createDocumentsRightAwayQuestion: `Create documents right away?`,
    createInHamsAndNoah: `Create in HAMS and Noah`,
    createNewCRMAccountInExactOnline: `Create new CRM account in Exact Online`,
    createNewEvent: `Create new event`,
    createNewPatient: `Create new patient`,
    createNewRule: `Create new rule`,
    createOnlyInHams: `Create only in HAMS, NOT in Noah`,
    createPatientFromFormCaption: `Manually fill in all data`,
    createPatientFromIdentityCardCaption: `Create patient from eID card`,
    createPatientFromNhaxCaption: `Import patient from another Noah installation\n(.nhax or .enhax)`,
    createPatientInNoah: `Create patient in Noah`,
    createPatientInRosa: `Create patient in ROSA`,
    createProformaAttachment12Content: `Via this method it's possible to create an attachment 12 BEFORE definitive handover\n\nThis document is not final\n\nFinal document will be created at handover`,
    createServiceOrGoods: `Create a new product or service`,
    createSlashSyncSaleInExactOnline: `Create/sync sale in Exact Online`,
    createSomething: (something: string | number) => `Create ${something.toString().toLowerCase()}`,
    createStrCommaWhat: (str: string | number, what: string | number) => `Create ${str.toString().toLowerCase()}, ${what.toString().toLowerCase()}`,
    createTenderAndOrderForm: `Create tender (and orderform)`,
    createWhat: (what: string | number) => `Create ${what.toString().toLowerCase()}`,
    createWhatInProductCatalog: (what: string | number) => `Create ${what.toString().toLowerCase()} in product catalog`,
    creditNote: `Credit note`,
    creditNoteDate: `Credit note date`,
    creditNoteLine: `Credit note line`,
    creditNotePurchase: `Purchase credit note`,
    creditNoteSale: `Sales credit note`,
    creditPurchaseVerb: `Credit purchase`,
    creditStatus: `Credit status`,
    creditVerb: `Credit`,
    crmAccountOrCustomerDoesNotExistYetInExactOnline: `CRM account (customer) does not exist in Exact Online`,
    currentFeePersonalShare: `Current personal share`,
    currentFeeValue: `Current fee value`,
    currentLocation: `Current location`,
    currentlyLoggedInOnDevices: (count: string | number) => `Currently logged in on ${count.toString().toLowerCase()} devices`,
    currentlyLoggedInOnOneDevice: `Currently logged in on 1 device`,
    currentlyNotLoggedIn: `Currently not logged in`,
    currentPrice: `Current price`,
    customer: `Customer`,
    customerAccountIsNotLinkedInExactOnline: `Customer account is not linked with Exact Online`,
    customerIsAPatient: `Customer is a patient`,
    customerIsNotAPatient: `Customer is not a patient`,
    customerSingularOrPlural: `Customer(s)`,
    customWorkflows: `Custom workflows`,
    dailyReceipt: `Daily receipt`,
    dailyReceipts: `Daily receipts`,
    dashboard: `Dashboard`,
    data: `Data`,
    dataNotReadViaeID: `Data not read via eID card`,
    dataPaging: (fc: string | number, ufc: string | number, what: string | number, index: string | number, total: string | number) => `${fc}/${ufc.toString().toLowerCase()} ${what.toString().toLowerCase()}. Page ${index.toString().toLowerCase()} of ${total.toString().toLowerCase()}`,
    dataReadViaEID: `Data read via eID`,
    date: `Date`,
    dateApprovalAdvisoryPhysician: `Date approval advisory physician`,
    dateBinFormat: `BIN date format (year-month-day)`,
    dateCreated: `Date created`,
    dateDayMonthYear: `Date (day/month/year)`,
    dateLastAudiogram: `Last audiogram's date`,
    dateLastChangedInNoah: `Date last changed in Noah`,
    dateLastHandover: `Last handover's date`,
    dateLastOrder: `Date last order`,
    dateLastSelection: `Last selection's date`,
    dateOfBirth: `Date of birth`,
    dateOfBirthShort: `Date of birth`,
    dateOfDispatch: `Dispatch date`,
    datePlusTimeDayMonthYearHourMinutes: `Date + time (day/month/year hours:minutes)`,
    dateReturnBefore: `Date return before`,
    dateStartLoan: `Date start loan`,
    dateUploaded: `Date uploaded`,
    dBAgainstVocalIndex: `dB against vocal index`,
    deactivated: `Deactivated`,
    deactivateExactOnlineIntegration: `Deactivate Exact Online integration`,
    deactivateIntegration: `Deactivate integration`,
    deactivateVerb: `Deactivate`,
    deactivePaymentMethod: `Deactivate payment method?`,
    deactivePaymentMethodContent: `This payment method is subsequently not applicable anymore`,
    deactivePaymentMethodForWhatContent: (what: string | number) => `This payment method is subsequently not applicable anymore for ${what.toString().toLowerCase()}`,
    deactivePaymentMethodForWhatQuestion: (what: string | number) => `Deactivate payment method for ${what.toString().toLowerCase()}?`,
    deceased: `Deceased`,
    defaultBlockDuration: `Default duration one block`,
    defaultDeliveryTime: `Default delivery time`,
    defaultDuration: `Default duration`,
    defaultFailedTrialVatPercentage: `Default VAT percentage lump sum contribution`,
    defaultInvoiceProcessSteps: `Default invoice process steps`,
    defaultOffice: `Default office`,
    defaultRepairTime: `Default repair time`,
    defaultSteps: `Default steps`,
    defaultView: `Default view`,
    defaultWarranty: `Default warranty`,
    defaultWhat: (what: string | number) => `Default ${what.toString().toLowerCase()}`,
    defineAndOrderOrSelectNeedsFromStock: `Define and order/select needs`,
    defineAndOrderOrSelectNeedsFromStockCaption: `Selection of articles in loan, request for IA, tender, invoice, … will start from these needs`,
    defineNeeds: `Define needs`,
    defineNewInventoryItem: `Define new inventory item`,
    degreeOfChange: `Degree of change`,
    deleteAddressAdvisoryPhysician: `Delete address advisory physician`,
    deleteAddressThirdPartyPayer: `Delete address third party payer arrangement`,
    deleteAndOrAddLines: `Delete and/or add lines`,
    deleteAppointmentQuestion: `Delete appointment?`,
    deleteAttachment17: `Delete attachment 17`,
    deleteContactInfo: `Delete contact info`,
    deleted: `Deleted`,
    deleteData: `Delete the data`,
    deletedWhat: (what: string | number) => `${what} deleted`,
    deleteEmailAddress: `Delete email adress`,
    deleteEntPhysician: `Delete ENT physician`,
    deleteEntPhysicianQuestion: `Delete ENT physician?`,
    deleteFromRequest: `Delete from request`,
    deleteGeneralPractitioner: `Delete general practitioner`,
    deleteGeneralPractitionerQuestion: `Delete general practitioner?`,
    deleteInventoryItemFromArticleNeedQuestion: `Link with need and reservation on this inventory item will be deleted. Continue?`,
    deleteItemFromProductCatalog: `Delete item from product catalog`,
    deleteItemFromProductCatalogContent: `By deleting this product it's no longer selectable\nat a loan, tender, sale, …`,
    deleteLine: `Delete line`,
    deleteLink: `Delete link`,
    deleteLinkImperative: `Delete link`,
    deleteLinkWithInventoryItem: `Delete link with an inventory item`,
    deleteLocation: `Delete location`,
    deleteLocationQuestion: `Delete location?`,
    deleteManualAdjustment: `Delete manual adjustment`,
    deleteNeed: `Delete need`,
    deleteNeedAndPropertiesQuestion: `Delete selection article and properties?`,
    deleteOffice: `Are you sure you want to delete this office?`,
    deletePermanently: `Delete Permanently`,
    deleteProduct: `Delete product`,
    deleteProductQuestion: `Are you sure you want to delete this product?`,
    deleteScanHistory: `Delete scan history`,
    deleteSettlementProposalWithReferenceQuestion: (reference: string | number) => `Delete settlement proposal ${reference.toString().toLowerCase()}?`,
    deleteSupplier: `Delete supplier`,
    deleteUblFromBuffer: `Delete UBL from buffer`,
    deleteUblFromBufferQuestionConfirmation: `Do you want to delete this UBL file from buffer?`,
    deleteUser: `Delete user`,
    deleteUserContent: `This action cannot be undone! All user data will be lost.`,
    deleteUserQuestion: `Delete user?`,
    deleteWhat: (what: string | number) => `Delete ${what.toString().toLowerCase()}`,
    deleteWhatQuestion: (what: string | number) => `Delete ${what.toString().toLowerCase()}?`,
    delivered: `Delivered`,
    deliveredToPatient: `Delivered to patient`,
    deliveries: `Deliveries`,
    deliverToPatient: `Deliver to patient`,
    delivery: `Delivery`,
    deliveryDate: `Delivery date`,
    deliveryHelperText: `The location where the delivery was received`,
    deliveryNote: `Delivery note`,
    deliveryOverDue: `Delivery overdue`,
    deliveryRegistered: `Delivery registered`,
    deliverySingularOrPlural: `Delivery(-ies)`,
    deliveryTime: `Delivery time`,
    deltaFinancialAndPhysical: `Difference between financial and physical`,
    demanded: `Demanded`,
    demo: `Demo`,
    department: `Department`,
    departmentSlashLocation: `Department/location`,
    description: `Description`,
    descriptionOfSupplier: `Description of supplier`,
    descriptions: `Descriptions`,
    detail: `Details`,
    detailsOfEntPhysician: `Details ENT physician`,
    detailsOfGeneralPractitioner: `Details general practitioner`,
    detailsOfHif: `Details health insurance fund`,
    detailsOfSupplier: `Details of supplier`,
    detailsOfVatCustomer: `Details VAT customer`,
    detailsSlashHistorySlashMoreActions: `Details/history/more actions`,
    determineOrientation: `Determine orientation`,
    devicesFromNoah: `Devices from Noah`,
    differenceWithNorm: `Difference with norm`,
    differentLabelPerLanguage: `Different label per language`,
    differentReadOuts: `Different readouts`,
    directedToContactPerson: `Directed to contact person`,
    directedToPatient: `Directed to patient`,
    directedToPatientAndOrVatCustomer: `Directed to a patient and/or VAT customer`,
    directedToVatCustomer: `Directed to a VAT customer`,
    disableCashDeskResource: `Cash register not active`,
    disableCashDeskResourceQuestion: `Disable cash register module for this location?`,
    disableExternalLogin: `Disable external login`,
    disableExternalLoginContent: `User will no longer be able to use google services to log in.`,
    disableExternalLoginQuestion: `Disable external login?`,
    disablePlannable: `Not plannable anymore`,
    disablePlannableQuestion: `Make location no longer plannable?`,
    disableStockManagementResource: `Not usable as  stock location anymore`,
    disableStockManagementResourceQuestion: `Enable stock management for this location?`,
    disApprovalAdvisoryPhysicianCaption: `Feedback received without approval\nThis step will be duplicated for retry`,
    disApprovalEntPhysicianCaption: `Feedback received without approval\nThis step will be duplicated for retry`,
    disApproved: `Disapproved`,
    discount: `Discount`,
    discountCannotBeGreaterThanListPrice: `Discount cannot be greater than list price`,
    discountCaption: `One time (absolute) discount`,
    discountsCanBeAddedAfterwards: `Discounts can be added afterwards`,
    dispatchDate: `Dispatch date`,
    dispatchInfo: `Dispatch info`,
    dispatchReference: `Dispatch reference`,
    displayName: `Displayed name`,
    distortion: `Distortion`,
    distributedBrands: `Distributed brands`,
    distributor: `Distributor`,
    doctorWithRIZIVNumberExists: `A practitioner with this RIZIV number already exists`,
    document: `Document`,
    documents: `Documents`,
    documentsForWho: (who: string | number) => `Documents for ${who}`,
    doesNotExistsInNoah: `Does not exist in Noah`,
    doesNotHaveRequestForInsuranceAllowance: `Does not have a request for insurance allowance`,
    dome: `Dome`,
    domeNewArticleCaption: `Click, open, mini, …`,
    doNotAddLinesToAnExistingOrder: `Add lines to an existing order`,
    doNotChangeWithPatient: `Do not change with patient`,
    doNotMention: `Do not mention`,
    doNotMentionCaption: `This line will not be mentioned on attachment 12`,
    doNotMentionOnAttachment12: `Do not mention on attachment 12`,
    doNotRegisterPaymentsInHAMS: `Do not register payments in HAMS`,
    doNotUpdateOnProcesses: `Do not update on processes`,
    dottedLine: `Dotted line`,
    doubleClickNoahInHamsOnDesktop: `Double click 'Noah in HAMS' on desktop`,
    download: `Download`,
    downloadAndInstallHamsNoahClientSoftware: `Download and install HAMS-Noah client software`,
    downloadDocument: `Download document`,
    downloadIsStartedWaitUntiflFileIsVisible: `Download is started, wait until file is visible.\nClick on file to start installation`,
    downloadMiddleware: `Download middleware`,
    downloadNHAX: `Download NHAX`,
    downloadWhat: (what: string | number) => `Download ${what.toString().toLowerCase()}`,
    doYouWantToActivateExactOnlineIntegrationQuestion: `Do you want to activate the Exact Online integration?`,
    doYouWantToActivateThisIntegrationQuestion: `Do you want to activate this integration?`,
    doYouWantToCancelThisArticlesInquiryQuestion: `Do you want to cancel this articles inquiry?`,
    doYouWantToCreateANewPatientBasedOnRosaPatientRecordData: `Do you want ot create a new patient in HAMS based on this ROSA data?`,
    doYouWantToCreateATenderWithoutContributionsQuestion: `Do you want to create a tender without contributions?`,
    doYouWantToCreateSlashSyncSaleInExactOnlineQuestion: `Do you want to create/sync this sale in Exact Online?`,
    doYouWantToCreateThisPatientInRosaQuestion: `Do you want to create this patient in ROSA?`,
    doYouWantToDeactivateExactOnlineIntegrationQuestion: `Do you want to deactivate the Exact Online integration?`,
    doYouWantToDeactivateThisIntegrationQuestion: `Do you want to deactivate this integration?`,
    doYouWantToDeleteAttachment17: `Do you want to delete attachment 17?`,
    doYouWantToDeleteExistingLinkWithCRMAccountQuestion: `Do you want to delete existing link with CRM account?`,
    doYouWantToDeleteExistingLinkWithGLAccountQuestion: `Do you want to delete existing link with GL account?`,
    doYouWantToDeleteRuleForWhatQuestion: (what: string | number) => `Do you want to delete rule: ${what.toString().toLowerCase()}?`,
    doYouWantToDeleteScanHistory: `Do you want to delete this scan history?`,
    doYouWantToDeleteThisContactInfoQuestion: `Do you want to delete this contact info?`,
    doYouWantToDeleteThisEmailAddressQuestion: `Do you want to delete this email adress?`,
    doYouWantToDeleteThisLineFromThisArticlesInquiryQuestion: `Do you want to delete this line form this articles inquiry?`,
    doYouWantToDeleteThisLineFromThisOrderQuestion: `Do you want to delete this line from this order?`,
    doYouWantToForwardInvoiceQuestion: `Do you want to forward this invoice?`,
    doYouWantToHideThisArticleFromListOfActualEquipmentQuestion: `Do you want to hide this article from list of actual equipment?`,
    doYouWantToIgnoreSaleReferenceQuestion: (reference: string | number) => `Do you want to ignore sale ${reference.toString().toLowerCase()}?`,
    doYouWantToLinkTheRosaUsersNowQuestion: `Do you want to link ROSA users now?`,
    doYouWantToLinkThisRosaPatientWithAnExistingPatientFromHAMS: `Do you want to link this ROSA patient with an existing patient from HAMS?`,
    doYouWantToMakeThisEntPhysicianAvailableAgainQuestion: `Do you want to make this ENT physician available again?`,
    doYouWantToMakeThisGPAvailableAgainQuestion: `Do you want to make this GP available again?`,
    doYouWantToMakeThisSupplierAvailableAgainQuestion: `Do you want to make supplier available again?`,
    doYouWantToPushPatientDataToRosaQuestion: `Do you want to transfer HAMS data to ROSA?`,
    doYouWantToRemoveManualWarrantyEndDateQuestion: `Do you want to delete the manually set warranty end date?`,
    doYouWantToRepairRuleForWhatQuestion: (what: string | number) => `Do you want to restore rule: ${what.toString().toLowerCase()}?`,
    doYouWantToRepairTemplateWithName: (template: string | number) => `Do you want to restore template: ${template.toString().toLowerCase()}?`,
    doYouWantToRevertIgnoreSaleReferenceQuestion: (reference: string | number) => `Do you want to revert ignore sale ${reference.toString().toLowerCase()}?`,
    doYouWantToShowThisArticleAgainInListOfActualEquipment: `Do you want to show this article again in list of actual equipment?`,
    draft: `Draft`,
    draftCosiCaption: `Fill in specific needs, evaluate later`,
    dutch: `Dutch`,
    earCanalLength: `Ear canal length`,
    earmould: `Ear mould`,
    earMouldNewArticleCaption: `Tailor made earmould (for hearing aid)`,
    earmoulds: `Ear moulds`,
    edit: `Edit`,
    editable: `Editable`,
    editAddress: `Change address`,
    editConfirmAddress: `Edit/confirm address`,
    editFieldToFill: `Edit field to fill`,
    editName: `Edit name`,
    editProperties: `Edit properties`,
    editSlashView: `Edit/view`,
    editVerb: `Edit`,
    editWhat: (what: string | number) => `Edit ${what.toString().toLowerCase()}`,
    eIDData: `eID data`,
    eIDDataLastReadDate: `Date last read out eID`,
    eIDDataRead: `Data eID read`,
    eIDDataUpdatedToPatient: `eID data read, patient updated`,
    eIDReadInfo: `The patient's data is read via the eID card.\n\nThe social security number registered on the card will be compared with the social security number of existing patients, \nin case of a match, de patient's data is updated with the data from eID card.\n\nIf no match found, you will receive a popup screen to confirm before a new patient is created.\n\nAre you sure that the patient is already created, but the social security number isn't filled in:\nclose this window, find the patient and update the eID data from the detail screen.`,
    electronics: `Electronic`,
    elementsBelowWillNotBeShown: `Elements below will not be shown`,
    email: `Email`,
    eMailAddressess: `Email addresses`,
    emailAddressWhereToInvoicedWillBeSent: `Email addresses (to where invoices will be sent)`,
    emptyDocument: `Empty document`,
    enableCashDeskResource: `Cash register active`,
    enableCashDeskResourceQuestion: `Enable cash register module for this location?`,
    enableExternalLogin: `Enable external login`,
    enableExternalLoginContent: `User will be able to use google services to log in.`,
    enableExternalLoginQuestion: `Enable external login?`,
    enablePlannable: `Make plannable`,
    enablePlannableQuestion: `Make location plannable?`,
    enableStockManagementResource: `Usable as stock location`,
    enableStockManagementResourceQuestion: `Disable stock management for this location?`,
    end: `End`,
    endAfterTreatment: `End after treatment`,
    endAfterTreatmentContentQuestion: `Are you sure to end this after treatment?`,
    endAllSessions: `End all sessions`,
    endAllSessionsForUser: `End all sessions for user`,
    endDate: `End date`,
    ended: `Ended`,
    endLoan: `End loan`,
    endResult: `End result`,
    endSession: `End session`,
    endSessionForUser: `End session for user`,
    endThisSession: `End this session`,
    endTrial: `End trial`,
    endTrialAdministratively: `End trial (administratively)`,
    endTrialAdministrativelyCaption: `End trial administratively\nFill in a date for administrative end`,
    endTrialCaption: `End loan\nDevices will return to stock, this step is not necessary, loan will end automatically at handover`,
    endTrialPositively: `End trial (positive)`,
    endVerb: `End`,
    english: `English`,
    enterDiscountAbsolute: `Fill in discount in EURO`,
    enterDiscountInPercentage: `Fill % discount`,
    enterpriseNumber: `Enterprise number`,
    enterSerialNumber: `Enter serialnumber`,
    entPhysician: `Ear Nose Throat Physician`,
    entPhysicianCreated: `New ENT physician created`,
    entPhysicians: `ENT physicians`,
    entPhysicianShort: `ENT physician`,
    entPhysicianUpdated: `ENT physician updated`,
    entryDate: `Entry date`,
    equation: `Equation`,
    equipmentTestTitle: `Test equipment (trial)`,
    error: `Error`,
    errorCannotStartExternalModules: `Error, cannot start external modules`,
    errorDownloadingConfiguration: `Error retrieving configuration`,
    errorViewLogs: `Error, view logs`,
    evaluateCosi: `Evaluate COSI`,
    evaluateCosiCaption: `Fill in specific needs and evaluate immediately`,
    evaluatedNegatively: `Negative evaluation`,
    evaluateVerb: `Evaluate`,
    evaluation: `Evaluation`,
    evaluationDate: `Evaluation date`,
    everythingCapital: `Everything captital`,
    everythingLower: `Everything to lower`,
    everythingSeemsAllright: `Everything seems allright`,
    evolution: `Evolution`,
    exact: `Exact`,
    exactlyOne: `Exactly one`,
    exactOnlineActivatedForThisEnvironment: `Exact Online is activated for this environment`,
    exactOnlineCRMAccountCode: `Exact Online CRM account code`,
    exactOnlineGLAccountCode: `Exact Online GL account code`,
    exactOnlineIntegration: `Exact Online integration`,
    exactOnlineNotActivatedNotification: `Exact Online is not activated for this environment, contact support@hams.be to activate this integration.`,
    exactOnlineUpToDate: `Exact Online up to date`,
    example: `Example`,
    existingProcess: `Existing process`,
    expected: `Expected`,
    expectedDeliveryDate: `Expected delivery date`,
    expectedEndDate: `Expected end date`,
    expectedHandoverDate: `Expected handover date`,
    expectedPaymentBefore: `Expected payment before`,
    expectedWhat: (what: string | number) => `Expected ${what.toString().toLowerCase()}`,
    expectedWhatExpired: (what: string | number) => `Expected ${what.toString().toLowerCase()} expired`,
    expense: `Expense`,
    expenseOrIncome: `Expense or income`,
    expired: `Expired`,
    exportAllCountNonCashSalesToZipFile: (count: string | number) => `Export all (${count.toString().toLowerCase()}) non cash sales in .zip file`,
    exportCountToZip: (count: string | number) => `Export (${count.toString().toLowerCase()}) in .zip file`,
    exportDailyReceiptsFormattedAnWithTotals: `Export daily receipts formatted and with totals`,
    exportFinancialStockFormatted: `Export financial stock formatted`,
    exportJournalFormatted: `Export journal formatted`,
    exportLinesAllResultsInFormat: (format: string | number) => `Export lines (all results) in ${format.toString().toLowerCase()} format`,
    exportLinesFilteredResultsInFormat: (format: string | number) => `Export lines (filtered results) in ${format.toString().toLowerCase()} format`,
    exportPatientToNhaxFile: `Export patient to NHAX-file`,
    exportToUbl: `Export to UBL`,
    exportWhat: (what: string | number) => `Export ${what.toString().toLowerCase()}`,
    extension: `Extension`,
    external: `External`,
    externalAuthentication: `External authentication`,
    externalLoginDisabled: `User cannot log in via google services`,
    externalLoginEnabled: `User can log in via google services`,
    externalReference: `External reference`,
    extraLine: `Extra line`,
    extraLineCaption: `Item to sell, but not defined in product catalog`,
    exVatPerPiece: `ex. VAT per piece`,
    exVatShort: `test equipment (trial)`,
    failed: `Failed`,
    failedTrial: `Failed trial`,
    failedTrialCaption: `End trial negative\nPatient is not satisfied, devices will return to stock`,
    failedTrials: `Failed trials`,
    failedTrialsThisYearBracketsYTD: `Failed trials this year (YTD)`,
    failureVatPercentageHelperText: `VAT percentage lump sum contribution`,
    fault: `Fault`,
    faultInConfiguration: `Fault in configuration`,
    faultSingularOrPlural: `Fault(s)`,
    faultStartNoahModules: `Fout starting Noah modules`,
    feHearingProtectionChargerEarmouldEtc: `F.e. hearing protection, earmould, charger, …`,
    female: `Female`,
    fieldsToFill: `Fields to fill`,
    file: `File`,
    fileName: `Filename`,
    filledInWhat: (what: string | number) => `Filled in ${what.toString().toLowerCase()}`,
    fillInLRSeparately: `Fill in L/R separately`,
    fillInMissingData: `Fill in missing data`,
    fillInMissingDataTitle: `Fill in missing data`,
    fillInMono: `Fill in mono`,
    fillMfrModelInYourself: `Fill in mfr/model manually`,
    fillWhat: (what: string | number) => `Fill in ${what.toString().toLowerCase()}`,
    filteredResults: `Filtered results`,
    financial: `Financial`,
    financialQuantity: `Quantity in stock (financial)`,
    finished: `Finished`,
    finishedProcesses: `Finished processes`,
    finishedTreatments: `Finished treatments`,
    firstDeliveryDate: `First delivery date`,
    firstLetterOfFriday: `F`,
    firstLetterOfMonday: `M`,
    firstLetterOfSaturday: `S`,
    firstLetterOfSunday: `S`,
    firstLetterOfThursday: `T`,
    firstLetterOfTuesday: `T`,
    firstLetterOfWednesday: `W`,
    firstName: `First name`,
    firstUsageDate: `Date first usage`,
    fitted: `Fitted`,
    fitting: `Fitting`,
    fittingDate: `Fitting date`,
    followingSalesHaveManualAdditionsToTheDocument: `For following sales the document have been manually adjusted`,
    followingSettlementProposalsHaveManualAdditionsToTheDocument: `For following settlement proposals the document have been manually adjusted`,
    followInstallationInstructionsOnScreenCloseScreenAfterInstallation: `Follow installation instructions on screen\nClose this screen after installation`,
    followUpVerb: `Follow up`,
    forAPatientQuestion: `For a patient?`,
    forASinglePatient: `For exactly one patient`,
    forExampleHearingAid: `E.g. hearing aid`,
    forExampleShort: `E.g.`,
    forgotPasswordHeaderText: `Fill in your email address.\nYou will receive instruction on how to reset your password afterwards.`,
    forgotPasswordQuestion: `Forgot password?`,
    form: `Form`,
    formatAsSize: `Size`,
    formattingOptions: `Formatting options`,
    forMultipleArticles: `For multiple articles`,
    forMultiplePatients: `For multiple patients`,
    forNoneOneOrMultiplePatients: `For none, one or multiple patients`,
    forPatient: `For patient`,
    forward: `Forward`,
    forwarded: `Forwarded`,
    forwardInvoiceAfterDocumentIsCreated: `Forward invoice after document is created`,
    forwardInvoiceOnceItsCreatedEvenWithoutDocument: `Forward invoice immediatetly (without document)`,
    forwardViaEmail: `Forward via email`,
    forWho: (who: string | number) => `For ${who}`,
    foundAtInventoryItems: `Found at inventory items`,
    foundInReadOutsFromNoah: `Found in readouts from Noah`,
    foundPatientsOnName: `Patients found on name`,
    foundPatientsOnSsNr: `Patients found on social security number`,
    freeInputField: `Free field`,
    french: `French`,
    frequencyRange: `Frequency range`,
    fromArrowTo: `From -> to`,
    fromCompleteList: `From complete list`,
    fromLocation: `From location`,
    fromNeeds: `From needs`,
    fromPreviousReadOutsFromNoah: `From previous readouts from Noah`,
    fromProductCatalog: `From product catalog`,
    fromStock: `From inventory`,
    fromStockShort: `From stock`,
    fromSupplier: `From supplier`,
    fromTemplate: `From template`,
    fromTo: `From / To`,
    fullDay: `Full day`,
    fullyCredited: `Fully credited`,
    fullyPaid: `Fully paid`,
    function: `Function`,
    functions: `Functions`,
    futureAppointments: `Future appointments`,
    gender: `Gender`,
    general: `General`,
    generalData: `General data`,
    generalLedger: `General ledger`,
    generalLedgerAccountAllocationMethod: `Method allocation general ledger accounts`,
    generalPractitioner: `General practitioner`,
    generalPractitionerCreated: `New general practitioner created`,
    generalPractitioners: `General Practitioners`,
    generalPractitionerUpdated: `General Practitioner updated`,
    generalSettings: `General settings`,
    goBack: `Go back`,
    graph: `Graphic`,
    graphs: `Graphics`,
    groupedByMfrModel: `Grouped (by brand/model)`,
    groupedPayments: `Grouped payments`,
    groupOnThis: `Group on this`,
    groupPayment: `Group payment`,
    halfOfTime: `Half of time`,
    HAMSCollabWithRosa: `HAMS & Rosa Calender collaboration, read more on`,
    hamsIsNotRegisteredAsMgmtSoftware: `HAMS is not registered as the 'MGMT' software for Noah.`,
    hamsNoahClientIsInstalled: `HAMS-Noah client software is installed`,
    hamsNoahClientIsNotInstalledOrNotActive: `HAMS-Noah client software is not installed or not active`,
    hamsWorksTogetherWithROSAforCalendar: `HAMS works together with ROSA for calendar/agenda module`,
    handedOver: `Handed over`,
    handedOverAndPaid: `Handed over and paid`,
    handedOverBeforeApprovals: `Handed over before approvals`,
    handedOverThisYearBracketsYTD: `Handed over this year (YTD)`,
    handedOverWithoutApprovals: `Handed over without approvals`,
    handled: `Handled`,
    handover: `Handover`,
    handoverBeforeFirstOfAugustWillBeRegisteredWithNumber: (code: string | number) => `Handovers before 01/08/2023 will be registered with number: ${code.toString().toLowerCase()}`,
    handoverBeforeFirstOfAugustWillUseOldCode: `Handovers before 01/08/2023 will be registered with old number`,
    handoverCertificate: `Handover certificate`,
    handoverDate: `Handover date`,
    handoverOfEquipment: `Handover of equipment`,
    handoverOverDue: `Handover overdue`,
    handoverRegistered: `Handover registered`,
    handovers: `Handovers`,
    handoverToPatient: `Handover to patient`,
    hardware: `Hardware`,
    hardwareExplanation: `Something tangible (e.g. an earpiece, hearing aid or hearing protection)`,
    hasBatteries: `Has batteries`,
    hasBatteriesBebatApplicable: `Has/are batteries (Bebat applicable)`,
    hasBatteriesExplanation: `This device contains batteries, so Bebat applies.`,
    hasDeliveries: `Has deliveries`,
    hasDeltaFinancialAndPhysical: `Has diff. between financial and physical`,
    hasNoBatteries: `Has no batteries`,
    hasNoDeliveries: `Does not have deliveries`,
    hasNoOrders: `Does not have orders`,
    hasNoPurchases: `Does not have purchases`,
    hasNoSerialNumber: `Does not have serial number`,
    hasOrders: `Has orders`,
    hasPurchases: `Has purchases`,
    hasRechargeableBatteriesBebatApplicable: `Has rechargeable batteries (Bebat applicable)`,
    hasRequestForInsuranceAllowance: `Has request for insurance allowance`,
    hasSerialNumber: `Has a serial number`,
    hasVaryingPrice: `Has a varying price`,
    heading: `Heading`,
    headPhones: `Headphones`,
    headPhonesLeftRight: `Headphones L/R`,
    headPhonesShort: `HP`,
    healthInsuranceFund: `Health insurance fund`,
    healthInsuranceFunds: `Health insurance funds`,
    healthInsuranceFundShort: `Health ins. fund`,
    hearingAid: `Hearing aid`,
    hearingAids: `Hearing aids`,
    hearingAidsShort: `HA`,
    hearingCentre: `Hearing centre`,
    hearingImprovementInWhat: (what: string | number) => `Hearing improvement in ${what.toString().toLowerCase()}`,
    hearingLossLessOrMoreThan40dB: `Hearing loss more/less than 40dB`,
    hearingLossLessThen40dB: `Hearing loss < 40dB`,
    hearingLossLessThen40dBWithAverages: `Hearing loss less than 40dB (avg. 1000/2000/4000Hz)`,
    hearingLossMoreThan40dB: `Hearing loss  ≥ 40dB`,
    hearingLossMoreThan40dBWithAverages: `Hearing loss more than 40dB (avg. 1000/2000/4000Hz) or exception (2.1.2)`,
    hearingProtection: `Hearing protection`,
    hearingProtectionNewArticleCaption: `Tailor made or not\n Swimming-, sleeping-, music-cap, …`,
    here: `Here`,
    hidden: `Hidden`,
    hideAnamnesis: `Hide anamnesis`,
    hideArticle: `Hide article`,
    hideProperties: `Hide properties`,
    hideSplitUpPerVatPercentage: `Hide split up per VAT%`,
    hideThisArticle: `Hide this article`,
    hideWhat: (what: string | number) => `Hide ${what.toString().toLowerCase()}`,
    hifWhatDoesNotExistAnymore: (what: string | number) => `Health ins. fund ${what.toString().toLowerCase()} does not exist anymore`,
    highToLow: `High -> low`,
    historicalSale: `(Historical) sale`,
    history: `History`,
    home: `Home`,
    homePhone: `Home phone #`,
    hospitalized: `Hospitalized`,
    hoursAgo: (hours: string | number) => `${hours} hour ago`,
    hoursPerDay: `hours/day`,
    howDoeseIDReadWorks: `How does eID card reading works?`,
    icoHifsPrefixIsCodeHif: `In case of health insurance fund, prefix will be the health insurance fund's code`,
    id: `ID`,
    identityCard: `Identity card`,
    ifNoPatientSelectedItsObligatedToSelectAVatCustomer: `If no patient selected, select a VAT customer`,
    ignore: `Ignore`,
    ignoreAllowanceOrLinkWithArticleFromProductCatalog: `Ignore allowance or link with an article from product catalog`,
    ignored: `Ignored`,
    ignoreOrLinkWithAnArticleFromProductCatalog: `Ignore or link with an article from product catalog`,
    ignoreSale: `Ignore sale`,
    ignoreSaleImperative: `Ignore sale`,
    ignoreThisDevice: `Ignore this device`,
    ignoreThisReadOut: `Ignore this readout`,
    ignoreVerb: `Ignore`,
    ignoreWhatQuestion: (what: string | number) => `Ignore ${what.toString().toLowerCase()}?`,
    image: `Image`,
    imageFormattingHelperText: (key: string | number) => `An image can optionally be formatted with dimension\nUse {{${key.toString().toLowerCase()}:WxH}} with W = width, H = height (in pixels)\nAlternative it's possible to use this key inside a table. The dimensions of the cell will be used as width and height for this image`,
    import: `Import`,
    importCountDevices: (count: string | number) => `Import ${count.toString().toLowerCase()} devices`,
    importDeviceInStock: `Import device in stock`,
    imported: `Imported`,
    importedFromNoah: `Imported from Noah`,
    importIfNewerAction: `Import if new action`,
    importMultipleDevicesToStock: `Import multiple devices to stock`,
    importMultipleDevicesToStockContentCount: (count: string | number) => `Based on the filter: ${count.toString().toLowerCase()} devices found\n\nDo you want to import?`,
    importRosaPatient: `Import ROSA patient`,
    importThisDeviceInStock: `Import this device in stock`,
    importVerb: `Import`,
    importWhat: (what: string | number) => `Import ${what.toString().toLowerCase()}`,
    improvement: `Improvement`,
    inactive: `Inactive`,
    inAfterTreatment: `In after treatment`,
    inBackOrder: `Backorder`,
    inBrowser: `In browser`,
    income: `Income`,
    inComparisonWithPreviousPeriod: `In comparion with previous period`,
    inComparisonWithPreviousTransaction: `In comparion with previous transaction`,
    inConsulation: `In consultation`,
    incorrectFormat: `Invalid format`,
    incorrectlyLinked: `Incorrectly linked`,
    indexNumber: `Index number`,
    individualPerSerialNumber: `Individual (per serial number)`,
    individualReadOutDevice: `Individual readout device`,
    infoNotFilledIn: (info: string | number) => `${info} not set`,
    information: `Information`,
    inForRepair: `In for repair`,
    initials: `Initials`,
    inLoan: `In loan`,
    inOrder: `In order`,
    inRepair: `In repair`,
    insertCard: `Insert a valid eID card`,
    installation: `Installation`,
    installationAndConfiguration: `Installation and configuration`,
    installMiddleware: `Install middleware`,
    installWhat: (what: string | number) => `Install ${what.toString().toLowerCase()}`,
    inStepsOf: `In steps of`,
    inStock: `In stock`,
    inStockOnALocation: `In stock on a location`,
    insufficientInformation: `Insufficient information`,
    insurance: `Insurance`,
    insurancedAndProlongedBetweenBracketsWarranties: `Insurances and (prolonged) warranties`,
    insurancesAndProlongedWarranties: `Insurances and prolonged warranties`,
    integrations: `Integrations`,
    integrationStatus: `Integration status`,
    intensity: `Intensity`,
    internal: `Internal`,
    internalReference: `Internal reference`,
    inTest: `In test`,
    inTheEar: `In the ear`,
    intraCommCaption: `Intra-Community deliveries based on article 138 EU VAT directives 2006/112/EC`,
    inTreatment: `In treatment`,
    invalidAction: `Invalid action`,
    invalidCombinationEmailPassword: `Email/password combination incorrect`,
    invalidLicense: `Invalid license`,
    invalidLicenseContactHams: `Invalid license, contact HAMS`,
    invalidSocialSecurityNumber: `Invalid social security number`,
    inVatShort: `in VAT`,
    inventoryItem: `Inventory item`,
    inventoryItemNotInStock: `Article not on stock`,
    inventoryItems: `Inventory items`,
    inventoryManagement: `Inventory management`,
    inventoryManagementShortened: `Inventory mgmt`,
    invoice: `Invoice`,
    invoiceAdvance: `Advance billing`,
    invoiceAndCash: `Invoice and cash`,
    invoiceCustomerIsAPatientCaption: `Invoice will be linked with a patient\nOptionally invoice can be directed to a VAT customer`,
    invoiceCustomerIsNotAPatientCaption: `Invoice will be directed to a VAT customer`,
    invoiced: `Invoiced`,
    invoiceForwarding: `Invoice forwarding`,
    invoiceForwardingIntegration: `Invoice forwarding integratie`,
    invoiceNumber: `Invoice number`,
    invoicePriorToApprovals: `Invoice prior to approvals`,
    invoiceProcess: `Invoice process`,
    invoices: `Invoices`,
    invoiceVerb: `Invoice`,
    inWaitingRoom: `In waiting room`,
    isAlsoPurchased: `Can also purchase`,
    isElectronics: `Is electronics`,
    isElectronicsExplanation: `This device contains electronics, so taxes apply.`,
    isNotElectronics: `Not electronics`,
    isNotTailorMade: `Not tailor-made`,
    isRecalculatingNowDataWillBeUpdated: `Recalculating now, data will be updated soon`,
    isSoftwareAlreadyInstalledQuestion: `Is software already installed?`,
    isTailorMade: `Tailor-made`,
    iteCaptionTypes: `In the ear (IIC, CIC, ITC, HS, FS, …)`,
    item: `Item`,
    itemAddedToRepair: `Item added to repair`,
    itemAlreadyInList: `Item already in list`,
    itemFromProductCatalog: `Item from product catalog`,
    itemNotFoundQuestionClickHereToDefineNewProductCatalogItem: `Item not found?\nClick here to define a new product catalog item.`,
    itemNotFoundQuestionClickHereToDefineNewSerialNumber: `Item not found?\nClick here to define a new serial number,`,
    itemReserved: `Item reserved`,
    items: `Items`,
    itemsReturned: `Items returned`,
    itemsToDeliver: `Items to deliver`,
    itemsToLoan: `Items to loan`,
    itemsToOrder: `Items to order`,
    kpiCashDesk: `Using cash register module`,
    kpiPlannable: `Plannable`,
    kpiStockResource: `Using stock module`,
    label: `Label`,
    labels: `Labels`,
    language: `Language`,
    lastActivity: `Last Activity`,
    lastHandover: `Last handover`,
    lastLinkWas: `This device was last linked to:`,
    lastLoggedIn: `Last logged in`,
    lastLoggedInDate: `Last logged in date`,
    lastModifiedAt: `Last modified on`,
    lastModifiedDate: `Last modified date`,
    lastName: `Last name`,
    lastNameCommaFirstName: `Lastname, Firstname`,
    lastPaymentDate: `(Last) Payment date`,
    lastPayments: `Last payments`,
    lastReadOut: `Last readout`,
    lastReadOutDate: `Last readout date`,
    lastReadOutDevicesFromNoah: `Last readout devices (from Noah)`,
    lastSpsMetaRefHeader: `Last settl. prop. meta-ref`,
    lastSpsRefHeader: `Last settl. prop. ref.`,
    lastSynced: `Last synced`,
    lastUpdated: `Last updated`,
    lastUsageDate: `Last usage date`,
    lastWhat: (what: string | number) => `Last ${what.toString().toLowerCase()}`,
    lastWhatDays: (what: string | number) => `Last ${what.toString().toLowerCase()} days`,
    latest: `Latest`,
    latest10: `Latest 10`,
    latest10Notifications: `Latest 10 notifications`,
    latestTreatment: `Latest treatment`,
    layoutOverviewScreenOfAPatient: `Layout overview screen detail of a patient`,
    left: `Left`,
    leftAndRight: `Left and right`,
    leftColumn: `Left column`,
    leftEar: `Left ear`,
    legend: `Legend`,
    lent: `Lent`,
    less: `Less`,
    letter: `Letter`,
    letterToAdvisoryPhysician: `Letter to advisory physician`,
    letterToEntPhysician: `Letter to ENT physician`,
    license: `License`,
    line: `Line`,
    lines: `Lines`,
    linesDeletedAndOrAddedAfterDocumentCreation: `Lines deleted and/or added after document creation`,
    link: `Link`,
    linkAnExistingProcess: `Link an existing process`,
    linked: `Linked`,
    linkedToAnotherPatient: `Linked to another patient`,
    linkedToMultiple: `Linked to multiple`,
    linkExactOnlineCRMAccountForWho: (who: string | number) => `Link Exact Online CRM account for ${who}`,
    linkExactOnlineGLAccountForFallbackVatPercentage: (vatPercentage: string | number) => `Link Exact Online GL account for fallback ${vatPercentage.toString().toLowerCase()} VAT %`,
    linkExactOnlineGLAccountForWhat: (what: string | number) => `Link Exact Online GL account for ${what.toString().toLowerCase()}`,
    linkGLAccount: `Link GL account`,
    linkHealthInsuranceFund: `Link health insurance fund`,
    linkItemWithThisWhat: (what: string | number) => `Link item with this ${what.toString().toLowerCase()}`,
    linkRIZIVFromProductCatalogCaption: `Multiple links exist for this item\nMake a choice`,
    linkRosaPatient: `Link ROSA patient`,
    linkRosaUsersWithHamsUsers: `Link ROSA user(s) with HAMS user(s)`,
    links: `Links`,
    linkWithAHealthInsuranceFund: `Link with a health insurance fund`,
    linkWithAnExactOnlineCRMAccount: `Link with an Exact Online CRM account`,
    linkWithAnInventoryItem: `Link with an inventory item`,
    linkWithAnotherArticleFromProductCatalog: `Link with another article from product catalog`,
    linkWithAnotherHealthInsuranceFund: `Link with another health insurance fund`,
    linkWithPatient: `Link with a patient`,
    linkWithSameItemFromProductCatalogAsLastTime: `Link with same item from product catalog as last time`,
    list: `List`,
    listPrice: `List price`,
    loadingEllipsis: `Loading…`,
    loadingStatus: `Loading status`,
    loan: `Loan`,
    loanedOutItems: `Loaned out items`,
    loanEnded: `Loan ended`,
    loans: `Loans`,
    loanSlip: `Loan slip`,
    loanStarted: `Loan started`,
    location: `Location`,
    locationCashAndStockResourcesBothNeedToBeEnabled: `A location where the cashregister is enabled, should also be a stock location.`,
    locationCashDesk: `Cash register location`,
    locationCashDeskResourceWillBeDisabled: `The cash register module will be disabled for this location`,
    locationCashDeskResourceWillBeEnabled: `The cash register module will be activated for this location`,
    locationCreated: `Location created`,
    locationDetails: `Location details`,
    locationPlannable: `Plannable`,
    locationPlannableWillBeDisabled: `This location can't be used to schedule appointments anymore.`,
    locationPlannableWillBeEnabled: `This location can be used to schedule appointments.`,
    locations: `Locations`,
    locationsCurrentlyOnStock: `Locations currently in stock`,
    locationSlashRoom: `Location/Room`,
    locationStockManagement: `Stock location`,
    locationStockManagementResourceWillBeDisabled: `The stock management module will be disabled for this location`,
    locationStockManagementResourceWillBeEnabled: `The stock management module will be activated for this location`,
    locationUpdated: `Location updated`,
    locationWhereProductsWillBeReceived: `Location where products will be received`,
    locationWhereSold: `Location where sold`,
    locationWillAlsoBeChangedOnOtherProcesses: `Location will also be changed at active processes`,
    locationWillAlsoBeChangedWithPatient: `Location will also be changed at patient`,
    locationWillBeDeleted: `This location will be deleted permanently.`,
    locationWillNotBeChangedWithPatient: `Location will not be changed at patient`,
    locationWillOnlyBeChangedWithThisPatientAndNotOnProcesses: `Location will only be changed on patient and not on active processes`,
    locationWithAddressExists: `A location with this address already exists.`,
    locationWithNameExists: `A location with this name already exists.`,
    loggedIn: `Logged in`,
    loggedInDevices: `Logged in devices`,
    login: `Login`,
    loginHeaderText: `Log in with your email and password`,
    logo: `Logo`,
    logout: `Logout`,
    logoutAfter20MinutesOfInactivity: `Logout automatically after 20 min. inactivity`,
    logoutAllSessions: `Logout all sessions`,
    lost: `Lost`,
    lowerCase: `Lowercase`,
    lowToHigh: `Low -> high`,
    lumpSum: `Lump sum`,
    lumpSumContribution: `Lump sum contribution`,
    mainNomenclatureCode: `Main nomenclature code`,
    mainUser: `Main user`,
    makeAChoice: `Make a choice`,
    makeAChoiceFromCompleteList: `Make a choice from complete list`,
    makeDefault: `Make default`,
    makeDefaultOffice: `Make this the default office`,
    makePrimary: `Make primary`,
    makeUserNoLongerPlannableContent: `By confirming it's no longer possible to plan appointments for this user`,
    makeUserPlannableAgainContent: `By confirming it's possible again to plan appointments for this user`,
    male: `Male`,
    manual: `Manually`,
    manualAdditions: `Manual additions`,
    manualHandoverDateEntryContent: `Manual entry date of last handover\n|(*if handover not registered through HAMS)`,
    manuallyAssignedLabels: `Manually assigned labels`,
    manuallyDeactivated: `Manually deactivated`,
    manuallySetWarrantyEndDate: `Manually set warranty end date`,
    manualPatientNumbering: `Manual patient numbering`,
    manualSelectADate: `Manually select a date`,
    manufacturer: `Manufacturer`,
    manufacturerReference: `Manufacturers reference`,
    manufacturers: `Manufacturers`,
    margin: `Margin`,
    marginTooltip: `Margin = Profit / Salesprice`,
    markAsLost: `Mark as lost`,
    markAsRead: `Mark as read`,
    markItemAsDemoQuestion: `Mark item as DEMO?`,
    markItemAsNonDemoQuestion: `Mark item as non demo?`,
    markup: `Markup`,
    markupTooltip: `Markup = Profit / Purchase price`,
    material: `Material`,
    measured: `Measured`,
    measureVerb: `Measure`,
    medicalPrescription: `Medical prescription`,
    medicalPrescriptionApprovedTitle: `Medical prescription received (approval ENT)`,
    medicalPrescriptionDisApprovedTitle: `Medical prescription (NOT approved by ENT)`,
    medicalPrescriptionReceived: `Medical prescription received`,
    medicalPrescriptionReceivedNotSentToAdvPhys: `Medical prescription received, not sent to adv. phys.`,
    medicalTreatmentNomenclature: `Nomenclature`,
    method: `Method`,
    minimalRequiredDocumentsAccordingToRIZIV: `Minimal required documents (RIZIV)`,
    minutesAgo: (minutes: string | number) => `${minutes} minute(s) ago`,
    missingDocuments: `Missing documents`,
    mobile: `Mobile`,
    mobilePhone: `Mobile phone #`,
    mode: `Mode`,
    modeGenerateInvoiceAtHandover: `Mode generating invoices at handover`,
    model: `Model`,
    modules: `Modules`,
    moduleStarted: `Module started`,
    mono: `Mono`,
    monoPhonic: `Monophonic`,
    monthDateOfBirth: `Month date of birth`,
    monthHandover: `Month handover`,
    more: `More`,
    moreActions: `More actions`,
    moreInformationAboutWhatHasChangedQuestionClickHereForMoreInformation: `Learn more about what exactly is changing? Click here for more explanation.`,
    moreThanOneDayAgo: `> 1 day ago`,
    mostOfTime: `Most of the time`,
    mostRecentTonalAudiogramOfDate: `Most recent tonal audiogram of`,
    moveAppointmentQuestion: `Move appointment?`,
    movedToAnotherLocation: `Moved to another location`,
    muchBetter: `Much better`,
    multiple: `Multiple`,
    multipleArticlesFound: `Multiple articles found`,
    multipleCustomerAccounts: `Multiple customer accounts`,
    multipleLines: `Multiple lines`,
    multipleModulesCanOpenThisActionSelectOne: `Multiple modules can open this action, select one`,
    multipleNomenclaturesSelectedThisIsNotPossible: `Multiple nomenclatures selected, this is not possible`,
    multipleProductsNotSpecificForAPatient: `Multiple products, not specifically for a patient`,
    multiplePurchases: `Multiple purchases`,
    multipleRooms: `Multiple rooms`,
    multipleSales: `Multiple sales`,
    mustBeLargerThanWhat: (what: string | number) => `Must be larger than ${what.toString().toLowerCase()}`,
    mustUpdateText: `An update is available, \nclick below to update!`,
    myCalendars: `My calendars`,
    myCommissions: `My commissions`,
    myPayouts: `My payouts`,
    myProfile: `My profile`,
    myWhat: (what: string | number) => `My ${what.toString().toLowerCase()}`,
    name: `Name`,
    nameArticleFromProductCatalog: `Name article from product catalog`,
    navigateToDetailsOfSale: `Navigate to details of sale`,
    navigateToDetailsOfTreatment: `Navigate to details of treatment`,
    navigateToDetailsPage: `Navigate to detail page`,
    navigateToWhat: (what: string | number) => `Navigate to ${what.toString().toLowerCase()}`,
    navigation: `Navigation`,
    need: `Need`,
    needed: `Needed`,
    needs: `Needs`,
    needsASignature: `Needs a signature`,
    needsASignatureQuestion: `Needs a signature?`,
    negative: `Negative`,
    negativeEvaluationTrial: `Negative evaluation trial`,
    neverImport: `Never import`,
    neverLogoutAuto: `Never logout automatically`,
    newAnnotation: `New annotation`,
    newArticlesInquiry: `New articles inquiry`,
    newArticlesInquiryForWho: (who: string | number) => `New articles inquiry for ${who}`,
    newCharger: `New charger`,
    newDocument: `New document`,
    newEarmould: `New ear mould`,
    newEntPhysician: `New ENT physician`,
    newestFirst: `Newest first`,
    newFieldToFill: `New field to fill`,
    newGeneralPractitioner: `New general practitioner`,
    newHardware: `New hardware type 'other'`,
    newHearingAid: `New hearing aid`,
    newInvoiceForWhoQuestion: `New invoice: for who?`,
    newItem: `New item`,
    newItemChoice: `New item: make a choice`,
    newLoan: `New loan`,
    newLocation: `New Location`,
    newNotifications: `New notifications`,
    newOffice: `New office`,
    newOrder: `New order`,
    newOrderWithWho: (who: string | number) => `New order with ${who}`,
    newPassword: `New password`,
    newPatient: `New patient`,
    newProcess: `New process`,
    newProduct: `new Product`,
    newRemark: `New remark`,
    newRequestForInsuranceAllowance: `New request for insurance allowance`,
    newRfiaShort: `New request for IA`,
    newSerialNumber: `New serial number`,
    newSerialNumberShort: `New sn`,
    newService: `New service`,
    newSupplier: `New supplier`,
    newTailorMadeProduct: `New tailor made product`,
    newTailorMadeProductProcess: `New tailor made product proces`,
    newTangibleProduct: `New tangible product`,
    newTender: `New tender`,
    newTreatment: `New treatment`,
    newWhat: (what: string | number) => `New ${what.toString().toLowerCase()}`,
    nextAppointments: `Upcoming appointments`,
    nhaxDataReadAndAddedToThisPatient: `nhax data read and added to this patient`,
    nickName: `Nickname`,
    no: `No`,
    noAccess: `No access`,
    noAccessToAllPages: `No access to all pages`,
    noAccessToThisPageContactHamsAdmin: `No access to this page\nContact a HAMS admin user for more information`,
    noAddressInformation: `No adress information`,
    NoahActions: `Noah actions`,
    NoahActionSingularOrPlural: `Noah action(s)`,
    NoahData: `Noah data`,
    noahDeviceReadOutsLinkedToPcsHeader: `Linked to articles of\nproduct catalog (rules)`,
    NoahExternalModules: `Noah external module`,
    NoahImportAlwaysCaption: `Automatically import all devices to stock\nThis method means less manual work, but older devices will also appear in stock`,
    NoahImportIfNewerActionCaption: `Only import devices on a 'new' Noah action\nOld devices will not be imported to stock`,
    NoahNeverImportCaption: `Never automatically import devices to stock\nThis option results in most control over stock\nDevices must be added stock manually`,
    NoahSessions: `Noah sessions`,
    noAlternativeLogoPresent: `No alternative logo present`,
    noAppointmentsFound: `No appointments found`,
    noAppointmentsPlanned: `No appointments planned`,
    noArticlesInquiriesDefinedYet: `No articles inquiries defined yet`,
    noBeIDMiddlewareInstalled: `No BeID middleware installed`,
    noCardReaderAttached: `No card reader attached`,
    noCloseThisScreen: `No, close this screen`,
    noCodesLinkedYet: `No codes linked yet`,
    noCommaCloseWithoutSaving: `No, close without saving`,
    noCommaGoBack: `No, go back`,
    noCommaWhat: (what: string | number) => `No, ${what.toString().toLowerCase()}`,
    noConnectionCouldBeMadeToeIDSoftware: `No connection could be made to eID software\nIf you're sure it's installed, start it first`,
    noConnectionToClientSoftware: `No connection with Hams.Client software`,
    noConnectionToClientSoftwareText: `No connection could be made to client software\nIf you're sure it's installed, start it first`,
    noContactInfo: `No contact info`,
    noContactPerson: `No contact person`,
    noDataAvailable: `No data available`,
    noDataInHams: `No data in HAMS yet`,
    noDataReadViaEIDYet: `No data read via eID yet`,
    noDataToCompare: `No data to compare`,
    noDecimals: `No decimals`,
    noDefaultOfficeSelected: `No default office assigned`,
    noDeliveriesRegisteredYet: `No deliveries registered yet`,
    noDemoRegular: `No demo (regular)`,
    noDevicesReadOutByNoahYet: `No devices read out by Noah`,
    noDifference: `No difference`,
    noDocumentHasBeenCreatedForFollowingSales: `No document has been created for following sales`,
    noDocumentHasBeenCreatedForFollowingSettlementProposals: `No document has been created for following settlement proposals`,
    noDocumentsAddedYet: `No documents added yet`,
    noEarmouldsDefined: `No ear moulds defined, click '+' to create a new`,
    noeIDCardInserted: `No eID card inserted`,
    noEmail: `No email`,
    noEmailAddressesDefinedYet: `No email addresses defined yet`,
    noEndDate: `No end date`,
    noEquipmentAssignedYet: `No equipment assigned yet`,
    noExtraDataViewTransactionsOrRemarks: `No extra data here, view transactions or comments`,
    noFieldsDefined: `No fields defined`,
    noFillInLater: `No, fill in later`,
    noFollowUpProcesses: `No follow up processes`,
    noGeneralPractitionersDefined: `No general practitioners defined, click '+' to create a new`,
    noInventoryDefined: `No inventory defined`,
    noInventoryItemsFound: `No inventory items found`,
    noInventoryItemsFoundChangeSearch: `No inventory items found, change search params`,
    noItDoesntFit: `No, it does not fit`,
    noItemsFound: `No items found`,
    noItemsFoundForSupplier: (supplier: string | number) => `No items found for supplier ${supplier.toString().toLowerCase()}`,
    noItemsInStockContent: `No items in stock, add an order, repair, sales to view items`,
    noLabelsAssignedYet: `No labels assigned yet`,
    noLinesDefinedYet: `No lines added yet`,
    noLinesSelectedYet: `No lines selected yet`,
    noLinkedAccountsReceivableForCashSales: `No linked accounts receivable for cash sales`,
    noLinkWithHealthInsuranceFund: `No link with a health insurance fund`,
    noLoansRegisteredYet: `No loans registered yet`,
    noLocationsDefined: `No locations defined, click '+' to create a new`,
    noLogoPresent: `No logo present`,
    noLongerActive: `No (longer) active`,
    nomenclature: `Nomenclature`,
    nomenclatureCode: `Nomenclature code`,
    nomenclatureSingularOrPlural: `Nomenclature(s)`,
    noneActive: `None active`,
    noneOfAbove: `None of above`,
    noNoahDataAvailable: `No data from NOAH available`,
    noNomenclaturesSelected: `No nomenclatures selected`,
    noNotifications: `No notifications`,
    noOffices: `No offices`,
    noOfficesDefined: `No offices have been defined yet.`,
    noOnlyForThis: `No, only for this`,
    noOrdersRegisteredYet: `No orders registered yet`,
    noPatientFoundWithThisSsnrCreateNewQuestion: `No patient found with this social security number\nCreate new?`,
    noPatientsDefined: `No patients defined, click '+' to create a new patient`,
    noPatientSelected: `No patient selected`,
    noPaymentsReceivedYet: `No payments received yet`,
    noPaymentsRegisteredYet: `No payments registered yet`,
    noPhoneNumber: `No phone number`,
    noPreviousSoldLines: `No previous sold lines`,
    noProcessesDefinedForThisPatientYet: `No processes defined yet for this patient`,
    noProductsDefinedClickPlusToCreateNew: `No products defined, click '+' to create a new product`,
    noPseudoNomenclatureSelected: `No pseudo nomenclature selected`,
    noPurchasesRegisteredYet: `No purchases registered yet`,
    noRateDefined: `No rate defined`,
    noRepairsRegisteredYet: `No repairs registered yet`,
    noResults: `No results`,
    noResultsCommaChangeFilterParams: `No results, change filter params.`,
    noRfiaDefinedText: `No requests registered, select a patient to create a new one`,
    noRIZIVNumberLinkAction: `Click here to link`,
    noRIZIVNumberLinkedText: `No RIZIV number linked to this user`,
    normal: `Normal`,
    normalOrder: `Normal order`,
    normalOrderCaption: `Select articles from product catalog manually`,
    normScore: `Norm score`,
    normValue: `Norm value`,
    normValueForThisList: (list: string | number) => `Norm value for list: ${list.toString().toLowerCase()}`,
    noSalesRegisteredYet: `No sales registered yet`,
    noShow: `No Show`,
    noSignatureIsNeededForThisDocument: `This document must never be signed`,
    noSignatureNeeded: `No signature needed`,
    noSocialSecurityNumberDefined: `No social security number defined`,
    noSpeechTestRecordedCreateOneFirst: `No speech tests recordes\nCreate a speech test first via a Noah module.`,
    noSubProcess: `No sub process`,
    noSuppliersDefined: `No suppliers defined, click '+' to create a new`,
    noSupplierWithPurchasesRegisteredYet: `No suppliers (with purchases) registered`,
    notACashDeskLocation: `Does not use cash register`,
    notActive: `Not active`,
    notActived: `Not activated`,
    notAllFallbackGLAccountsAreDefined: `Not all fallback codes are defined`,
    notAllFieldsAreFilledInForThisPatient: `Not all required fields are filled in for this patient.\nDo you want to complete this now?`,
    notAllNomenclaturesAreValidOnThisDate: `Not alle nomenclatures are valid on this date\nIn request`,
    notAllRIZIVCodesAreSelectedDoYouWantToProceed: `No all RIZIV codes selected\nAre you sure you want to continue?`,
    notAllUserVisibleHelperText: `Not all users visible? Only audiologist are shown\nAn audiologist is a user with RIZIV number`,
    notApplicable: `Not applicable`,
    notASingleDeviceRegisteredInProductCatalog: `Not a single device registered in product catalog`,
    notAStockLocation: `Location without stock`,
    notAuthorizedCommaMandatoryToRegisterAgain: `Not authorized, required to register again`,
    notAvailable: `Not available`,
    notAvailableForWhat: (what: string | number) => `Not available for ${what.toString().toLowerCase()}`,
    notAvailableInCalendar: `Not available in calendar`,
    notAvailableInCalendarCaption: `It's not possible to create appointments on this location`,
    notCompleted: `Not completed`,
    notConfirmed: `Not confirmed`,
    notConfirmedTooltip: `User has not yet opened his/her email nor created a profile.`,
    notDeleted: `Not deleted`,
    notDeliveredOrdersSingularOrPlural: `Not linked orders`,
    notDeliveredYet: `Nog delivered yet`,
    notDirectedToVatCustomer: `Not directed to VAT customer`,
    note: `Note`,
    notEditable: `Not editable`,
    noTemplatesForThisTypeOrSubject: `No templates for this type/subject`,
    noTendersRegisteredYet: `No tenders registered yet`,
    notFilledIn: `Not filled in`,
    notForAPatient: `Not for a patient`,
    notForPatient: `Not for patient`,
    notFoundAtInventoryItems: `Not found at inventory items`,
    notFoundInReadOutsFromNoah: `Not found in Noah readouts`,
    notHandedOverNorFailed: `Not handed over nor failed`,
    nothingCredited: `Nothing credited`,
    nothingFoundCommaChangeSearchParams: `Nothing found, change search params`,
    nothingPaid: `Nothing paid`,
    notifications: `Notifications`,
    notImported: `Not imported`,
    notImportedNotIgnored: `Not imported nor ignored`,
    notInventoried: `Not inventoried`,
    notKnownYet: `Not known yet`,
    notLinked: `Not linked`,
    notLinkedInNoah: `Not linked to Noah`,
    notLinkedNotIgnored: `Not linked, not ignored`,
    notPaidAndOverdue: `Not paid and overdue`,
    notPlannable: `Not plannable`,
    notPossibleToMoveARecurringAppointment: `Not possible to move a recurring appointment`,
    noTransactionsForThisItem: `No transactions for this item`,
    noTreatmentsStarted: `No treatment started yet`,
    notRefundable: `Not refundable`,
    notRefundableCaption: `Line is not refundable`,
    noTrialStartedYet: `No trial started yet`,
    notSendToSupplierYet: `Not send to supplier yet`,
    notSet: `Not set`,
    notSigned: `Not signed`,
    notSynced: `Not synced`,
    notTangible: `Not tangible`,
    notTangibleCaption: `Not a tangible product\nService, insurance or prolonged warranty`,
    notUpToDate: `Not up-to-date`,
    notViewableInApp: `Not possible to view/edit in app`,
    notWhat: (what: string | number) => `Not ${what.toString().toLowerCase()}`,
    notYetRepaired: `Not yet repaired`,
    notYetSynchronized: `Not yet synchronized`,
    noTypesAnnotationDefined: `No annotation types defined yet`,
    noTypesAppointmentsDefined: `No appointment types defined yet`,
    noVatCustomer: `No VAT customer`,
    noVatNumber: `No VAT number found`,
    noWhat: (what: string | number) => `No ${what.toString().toLowerCase()}`,
    noWhatDefined: (what: string | number) => `No ${what.toString().toLowerCase()} defined`,
    noWhatFound: (what: string | number) => `No ${what.toString().toLowerCase()} found`,
    noWhatFoundClickPlusToCreateNew: (what: string | number) => `No ${what.toString().toLowerCase()} found, click '+' to create new`,
    noWhatRegistered: (what: string | number) => `No ${what.toString().toLowerCase()} registered`,
    noWhatSelected: (what: string | number) => `No ${what.toString().toLowerCase()} selected`,
    nowNotWhat: (what: string | number) => `Not now ${what.toString().toLowerCase()}`,
    nowWhat: (what: string | number) => `Now ${what.toString().toLowerCase()}`,
    number: `Number`,
    numbering: `Numbering`,
    numberingWhat: (what: string | number) => `Numbering ${what.toString().toLowerCase()}`,
    office: `Office`,
    offices: `Offices`,
    ofPreviousStep: `Of previous step`,
    ofThePatient: `Of patient`,
    ofWhichCountInAfterTreatment: (count: string | number) => `Of which ${count.toString().toLowerCase()} in after treatment`,
    oldestFirst: `Oldest first`,
    oldPassword: `Old password`,
    onConsignment: `On consignment`,
    oneDecimal: `1 significant digit`,
    oneOrMoreArticlesHasNoGLAccountLinked: `One or more articles has no GL code`,
    oneOrMoreOffices: `One or multiple offices`,
    oneOrMoreRosaUsersOrLocationsUnlinked: `On or more users/locations not linked`,
    oneOrMultipleNextLinesPossibleValues: `One or multiple\nvalues possible`,
    onePurchase: `One purchase`,
    oneSale: `One sale`,
    onlyActive: `Only active`,
    onlyAdminUserCanPerformThisAction: `Only admin users can perform this action`,
    onlyAnArticleForWhatOrientation: (orientation: string | number) => `Article only for ${orientation.toString().toLowerCase()}`,
    onlyForThisArticle: `Only for this article`,
    onlyForWhat: (what: string | number) => `Only for ${what.toString().toLowerCase()}`,
    onlyHifPaid: `Only I.C. paid`,
    onlyInCaseOfNegativeEvaluation: `Only in case of negative evaluation`,
    onlyInFuture: `Only in future`,
    onlyInPast: `Only in past`,
    onlyPatientPaid: `Only patient paid`,
    onlyTangibleItemsWithoutSerialNumberCanBeSelected: `Only tangible articles without serial numbers can be selected`,
    onlyWIACompatibleScannersWillBeShown: `Only WIA compatible scanners will be shown`,
    onRemote: `On remote`,
    onThisPageItsPossibleToDefineDifferentAddresses: `On this page it's possible to enter different addresses`,
    open: `Open`,
    openConfigurationScreen: `Open configuration screen`,
    optional: `Optional`,
    options: `Options`,
    order: `Order`,
    orderArticles: `Order articles`,
    orderCancelled: `Order cancelled`,
    orderDate: `Order date`,
    ordered: `Ordered`,
    orderForASinglePatientContent: `Order is for exactly one patient`,
    orderForm: `Order form`,
    orderFormToWho: (who: string | number) => `Order form for ${who}`,
    orderForNoneOneOrMultiplePatientsContent: `Grouped order for none, one or multiple patients`,
    orderFromNeedsCaption: `Make an order starting from needs`,
    orderIsForASupplierWhoIsNotRegisteredYet: `Order is for a supplier who is not yet registered`,
    orderNumber: `Order number`,
    orderOrSelectFromStock: `Order or select from stock`,
    orderOrSelectNeeds: `Order or select needs`,
    orderPlaced: `Order placed`,
    orders: `Orders`,
    orderSlashAllocate: `Order/assign`,
    orientation: `Orientation`,
    orientationTest: `Orientation test`,
    origin: `Origin`,
    original: `Original`,
    originalIdentificationCode: `Original identification code`,
    other: `Other (tailor-made)`,
    otherActiveTreatmentForPatientDoYouWantToContinueQuestion: (whatX: string | number) => `Detected other active treatment(s) for '${whatX}'\nAre you sure to start a new treatment?`,
    otherArticles: `Other articles`,
    otherCalendars: `Other calendars`,
    otherData: `Other data`,
    otherDocuments: `Other documents`,
    otherExplanation: `With/without serial number (e.g. charger, …)`,
    otherItemFromProductCatalog: `Another item from product catalog`,
    otherNewArticleCaption: `With/without serial number\nTailor made or not`,
    otherPatientWithThisSsNumberAlreadyExists: `Other patient with same social security number already exists`,
    otherRunningRfiaProcessesForPatientDoYouWantToContinueQuestion: (whatX: string | number) => `There are other running request for '${whatX}'\nAre you sure you want to create a new one?`,
    otherSettings: `Other settings`,
    otherTailorMade: `Other (tailor-made)`,
    otherTreatments: `Other treatments`,
    otherWithoutSerialNumber: `Other (without serialnumber)`,
    otherWithSerialNumber: `Other (with serial number)`,
    outstanding: `Outstanding`,
    outstandingBalance: `Outstanding balance`,
    outstandingBalanceNotOnSps: `Outstanding balance not on settl. prop.`,
    outstandingTenders: `Outstanding tenders`,
    overdue: `Overdue`,
    overDue: `Overdue`,
    overview: `Overview`,
    overviewDeliveredToDeliver: `Overview delivered/to deliver`,
    overwrite: `Overwrite`,
    overwriteColumnsOfWhat: (what: string | number) => `Overwrite columns of ${what.toString().toLowerCase()}`,
    ownProperty: `Own property`,
    padNumbers: `Pad numbers`,
    padNumbersExplanation: `F.e. to get 0001, select 4. To get 001, select 3`,
    page: `Page`,
    pageColonIndexOfCount: (index: string | number, count: string | number) => `Page: ${index.toString().toLowerCase()} of ${count.toString().toLowerCase()}`,
    paid: `Paid`,
    paidBeforeApprovals: `Paid before approvals`,
    paidOut: `Paid out`,
    paidOutDate: `Paid out date`,
    paperSize: `Paper size`,
    partialAssigned: `Partial assigned`,
    partialDelivered: `Partially delivered`,
    partialDelivery: `Partial delivery`,
    partlyDelivered: `Partially delivered`,
    partlyPaid: `Partly paid`,
    partOfRfiaClickToNavigate: `Part of request for insurance allowance, click to navigate`,
    partOfTmpClickToNavigate: `Part of a tailor made product, click to navigate`,
    partPatient: `Part patient`,
    passportPhotograph: `Passport photograph`,
    password: `Password`,
    passwordChanged: `Password changed`,
    passwordComplexityRule: `Password should be at least 6 character long, one caption [A-Z], one lowercase [a-z], one number [0-9] and one symbol  [!@#$%]`,
    passwordDoesNotMatch: `Passwords do not match`,
    passwordIncorrect: `Password incorrect`,
    patient: `Patient`,
    patientActiveCaption: `Patient is an active patient`,
    patientAlsoExistsInNoah: `Patient also exists in Noah`,
    patientCannotChangeIfeIDRead: `It's not possible to change name/date of birth/gender, \nbecause eID data was read`,
    patientCreated: `Patient created`,
    patientData: `Patient data`,
    patientDeceasedCaption: `Patient is deceased`,
    patientDetailOverviewLayoutPreferencesDescription: `Below, drag the elements to the 'left' or 'right' column to make them visible in the patient summary screen.`,
    patientInactiveCaption: `Patient is no longer active`,
    patientPermanentlyDeleted: `Patient permanently deleted`,
    patients: `Patients`,
    patientUpdated: `Patient updated`,
    payBefore: `Pay before`,
    payedByHifShort: `Payed by hif`,
    payedByPatient: `Payed by patient`,
    payment: `Payment`,
    paymentDate: `Payment date`,
    paymentDetails: `Payment details`,
    paymentMethod: `Payment method`,
    paymentMethods: `Payment methods`,
    paymentMultiplePurchasesCaption: `Payment relates to multiple purchases.\nPartial payment is not possible.`,
    paymentMultipleSalesCaption: `Payment relates to multiple sales.\nPartial payment is not possible.`,
    paymentOnePurchaseCaption: `Payment relates to a single purchase\nPossibly a partial payment`,
    paymentOneSaleCaption: `Payment relates to a single sale\nPossibley a partial payment`,
    paymentReference: `Payment reference`,
    payments: `Payments`,
    paymentsNotRegisteredInHAMS: `Payment(s) not registered in HAMS`,
    paymentSpsCaption: `Payment relates to a previously created settlement proposal`,
    paymentStatus: `Payment status`,
    payout: `Payout`,
    payouts: `Payouts`,
    payVerb: `Pay`,
    pending: `Pending`,
    percentage: `Percentage`,
    personalData: `Personal data`,
    personalInformationData: `Personal information`,
    personalisedVersion: `Personalized version`,
    personalShare: `Personal share`,
    phone: `Phonenumber`,
    phoneNumberShort: `Phone nr.`,
    physicalQuantity: `Quantity in stock (physical)`,
    place: `Place`,
    placed: `Placed`,
    placeDigitalSignature: `Place digital signature`,
    placedWithSupplier: `Placed with supplier`,
    placeOrder: `Place order`,
    placeOrderImmediatelyWithSupplier: `Place order immediately with supplier`,
    placeOrderImmediatelyWithSupplierContent: `Order is complete and can be placed with supplier`,
    placeOrderWithSupplier: `Place order with supplier`,
    placeOrderWithSupplierLater: `Place order with supplier later`,
    placeOrderWithSupplierLaterContent: `Add lines to this order at a later date`,
    placeWithSupplier: `Place with supplier`,
    planAppointment: `Plan appointment`,
    plannable: `Plannable`,
    plannableLocations: `Plannable locations`,
    plannableUsers: `Plannable users`,
    plannedAppointmentIsAfterExpectedRedeliveryDate: `Planned appointments is AFTER expected redelivery date!`,
    pleaseAddOffice: `Please add an office for this physician first`,
    point: `Point`,
    points: `Points`,
    portal: `Portal`,
    positive: `Positive`,
    predefined: `Predefined`,
    predefinedType: `Predefined type`,
    preferences: `Preferences`,
    preferredLocation: `Preferred location`,
    preferredLocationFor: `Preferred location for`,
    prefix: `Prefix`,
    prescription: `Prescription`,
    prescriptionAttachment17: `Prescription (attachment 17)`,
    prescriptionDate: `Prescription date`,
    prescriptionForTrial: `Prescription for trial`,
    prescriptionForTrialDate: `Prescription for trial date`,
    prescriptionForTrialReceived: `Prescription for trial received`,
    prescriptionReceived: `Prescription received`,
    prescriptionStep1Caption: `Prescription for trial\nFilled in by ENT physician`,
    prescriptionStep2Caption: `Request by audiologist\nFilled in by audiologist`,
    prescriptionStep3Caption: `Prescription\nFilled in by ENT physician`,
    prescriptionStep4Caption: `Approval advisory physician\nFilled in by health insurance fund`,
    pressEnterToSearch: `Press enter to search`,
    previouslyPurchasedItem: `Previously purchased item`,
    previousSale: `Previous sale`,
    previousTrial: `Previous trial`,
    price: `Price`,
    priceExVat: `Price (ex VAT)`,
    priceForPatient: `Price for patient`,
    priceIncludesAllContributionsRecupelAndBebat: `Price includes all contributions (Recupel & Bebat)`,
    priceInVat: `Price (in VAT)`,
    priceMustBeLowerThanListPrice: `Amount must be larger than list price`,
    prices: `Prices`,
    pricing: `Pricing`,
    primaryContactInfosAreUsedForCommunicatingWithThePatient: `primary contact infos are used for communicating with the patient`,
    print: `Print`,
    printTicket: `Print ticket`,
    priority: `Priority`,
    privateLabel: `Private label`,
    privateLabelData: `Private label data`,
    proceed: `Proceed`,
    procentSpeechImprovementAccordingToBIAPMethod: `% speech intelligibility acc. BIAP method`,
    procentSpeechImprovementAccordingToICAMethod: `% speech intelligibility acc. SRT method`,
    procentSpeechImprovementAtSrtPoint: `% speech intelligibility at SRT point`,
    process: `Process`,
    processAdvanceInvoice: `Process advance invoice`,
    processes: `Processes`,
    product: `Product`,
    productCatalog: `Product catalog`,
    productCatalogItem: `Product catalog item`,
    productCreated: `Product created`,
    productInfo: `Product information`,
    productName: `Product name`,
    productUpdated: `Product updated`,
    productWithoutSerialNumber: `Product without serialnumber`,
    productWithSerialNumber: `Product with serialnumber`,
    productWithThisIntRefExists: `A product with this internal reference already exists.`,
    productWithThisManRefExists: `A product with this manufacturing reference already exists.`,
    productWithThisNameExists: `A product with this name already exists.`,
    profile: `Profile`,
    profit: `Profit`,
    proforma: `Pro forma`,
    proformaAttachment12: `Pro forma attachment 12`,
    prolongedWarranty: `Prolonged warranty`,
    properties: `Properties`,
    propertiesDoNotMatch: `Properties do not match`,
    propertiesOfHearingAid: `Properties of hearing aid`,
    propertiesOfTemplateNotRecursiveOnDocuments: `Properties of earlier created documents will not be changed\nThis change will only be reflected on documents created afterwards`,
    propertiesOfWhat: (what: string | number) => `Properties of ${what.toString().toLowerCase()}`,
    propertiesSingleOrPlural: `Propert(-y)(-ies)`,
    property: `Property`,
    propertyIsNotEqual: (property: string | number) => `'${property.toString().toLowerCase()}' does not match`,
    propertyOf: `Property of`,
    providePassword: `Provider password`,
    pseudoNomenclatureCode: `Pseudo nomenclature code`,
    purchase: `Purchase`,
    purchaseCreditDate: `Purchase credit note date`,
    purchaseCredited: `Purchase credited`,
    purchaseDate: `Purchase date`,
    purchaseListPrice: `Purchase list price`,
    purchaseListPriceShort: `Purchase list price`,
    purchaseOrderCustomer: `Purchase order customer`,
    purchaseOrderSupplier: `Purchase order supplier`,
    purchasePrice: `Purchase price`,
    purchasePrices: `Purchase prices`,
    purchaseReference: `Purchase reference`,
    purchases: `Purchases`,
    purchaseStatus: `Purchase status`,
    purchaseVerb: `Purchase`,
    pushDataToRosa: `PUSH data to ROSA`,
    pushPatientDataToRosa: `PUSH patient data to ROSA`,
    pushSlashSyncWithExactOnline: `Push/sync with Exact Online`,
    qtyMoreThanAvailableAtLocation: (available: string | number, location: string | number) => `Quantity more than quantity available (${available.toString().toLowerCase()}) on location ${location.toString().toLowerCase()}, stock will become negative`,
    qualificationCodes: `Qualification code(s)`,
    quantity: `Quantity`,
    quantityInStock: `Quantity in stock`,
    rate: `Rate`,
    reactivatePaymentMethod: `Reactivate payment method?`,
    reactivatePaymentMethodContent: `This payment method will be available again.`,
    reactivatePaymentMethodForWhatContent: (what: string | number) => `This payment method will be available again for ${what.toString().toLowerCase()}`,
    reactivatePaymentMethodForWhatQuestion: (what: string | number) => `Reactivate payment method for ${what.toString().toLowerCase()}?`,
    read: `Read`,
    readData: `Read data`,
    readDataFromeID: `Read data form eID card`,
    readeID: `Read eID`,
    readEIDData: `Read eID data`,
    readOn: `Read on`,
    readOutAs: `Read out as`,
    readOutFromUbl: `Readout from UBL`,
    reason: `Reason`,
    reasons: `Reasons`,
    reasonsAppointment: `Motives`,
    rebuildFromTemplate: `Rebuilt from template`,
    recalculating: `Recalculating`,
    receive: `Receive`,
    receiver: `Receiver`,
    receiverNewArticleCaption: `Loudspeaker, receiver (for RITE HA)`,
    reception: `Reception`,
    receptionDate: `Reception date`,
    receptionDateOfDocuments: `Reception date documents`,
    receptionFeedbackAdvisoryPhysician: `Reception feedback adv. physician`,
    receptionTermOfDocuments: `Term reception documents`,
    rechargeable: `Rechargeable`,
    rechargeableBatteries: `Rechargeable batteries`,
    recipient: `Receiver`,
    recommended: `Recommended`,
    recommendedSalesprice: `Recommended sales price`,
    recupelRate: `Recupel rate`,
    recurringAppointment: `Recurring appointment`,
    redelivered: `Redelivered`,
    redeliverVerb: `Redeliver`,
    redelivery: `Redelivery`,
    redeliveryDate: `Redelivery date`,
    reference: `Reference`,
    referenceDate: `Reference date`,
    referenceFromSupplier: `Supplier's reference`,
    referenceIsNotANumber: `Reference is not a number`,
    referenceMustNotContainAYear: `Reference must not contain a year`,
    referencePriceShort: `Ref. price`,
    references: `References`,
    referenceShort: `Ref.`,
    refund: `Refund`,
    refundable: `Refundable`,
    refundableCaption: `Line is refundable`,
    refundAmount: `Refund amount`,
    refunds: `Refunds`,
    refundsToProcess: `Refunds to process`,
    regenerateDocumentFromTemplate: `Regenerate document from template`,
    regenerateDocumentFromTemplateQuestion: `Regenerate document from template?`,
    regenerateTheDocument: `Re-generate the document`,
    register: `Register`,
    registerAgain: `Register again`,
    registerANewOrder: `Register a new order`,
    registerANewRepair: `Register a new repair`,
    registerANewRepairCaption: `Do not add to an existing, create a new`,
    registerArticles: `Register articles`,
    registerAsBroken: `Register as broken`,
    registerAsSold: `Register historical sale`,
    registerDelivery: `Register delivery`,
    registered: `Registered`,
    registeredAsBroken: `Registered as broken`,
    registeredAsPurchasedScript: `Purchase (script)`,
    registeredAsSold: `Registered as sold`,
    registeredWhat: (what: string | number) => `${what} registered`,
    RegisterForWebinar: `Register for the webinar`,
    registerGroupPaymentForSupplier: (supplier: string | number) => `Register group payment for supplier: ${supplier.toString().toLowerCase()}`,
    registerHandover: `Register handover`,
    registerHandoverEquipment: `Register handover of equipment`,
    registerInventoryItemAsSold: `Register inventory item as sold`,
    registerLetterDispatch: `Register dispatch of letter`,
    registerLumpSumContribution: `Register lump sum contribution`,
    registerMultipleSales: `Register multiple sales`,
    registerMultipleSalesCaption: `A sale will be registered for patient\nRefund will be invoiced to health insurance fund via a separate sale`,
    registerNewPurchase: `Register new purchase`,
    registerNewSale: `Register new sale`,
    registerNewUser: `Register a new user`,
    registerOneSale: `Register one sale`,
    registerOneSaleCaption: `One sale will be registered\nRefund is registered to health insurance fund via the same sale`,
    registerPayment: `Register payment`,
    registerPaymentsInHAMS: `Register payments in HAMS`,
    registerReceptionMedicalPrescription: `Register reception medical prescription`,
    registerRefund: `Register refund`,
    registerThisComputer: `Register this computer`,
    registerWhat: (what: string | number) => `Register ${what.toString().toLowerCase()}`,
    registration: `Registration`,
    registrationReceptionPrescription: `Registration reception of prescription`,
    reimbursementProcedure: `Reimbursement procedure`,
    rejectFittingContent: `Articles will be returned to supplier, this flow will be ended`,
    releaseNote: `Release note`,
    remark: `Remark`,
    remarks: `Remarks`,
    remote: `Remote`,
    remoteDevice: `Remote device`,
    removedFromATreatment: `Removed from a treatment`,
    removeEidData: `Remove eID data`,
    removeLink: `Remove link`,
    removeLinkBetweenRosaAndHamsPatient: `Remove link between ROSA and HAMS patient`,
    removeLinkFromWithRosaPatientQuestion: `Do you want to remove the link between ROSA and HAMS patient?`,
    removeLinkWithPatient: `Remove link with patient`,
    removeRepairLinkWithPatientContent: `This repair will no longer be linked with a patient\nDo you want to continue?`,
    removeThis: `Remove this`,
    renewal: `Renewal`,
    renewalDate: `Renewal date`,
    renewRegistrationToken: `Renew registration token`,
    repair: `Repair`,
    repairCancelContent: `By cancelling a repair, it's impossible to process any further steps`,
    repaired: `Repaired`,
    repairFormToWho: (who: string | number) => `Repair form to ${who}`,
    repairIsSpecificForAPatient: `Repair is specific for a patient`,
    repairs: `Repairs`,
    repairSentToSupplier: `Repair sent to supplier`,
    repairUndoDelete: `Repair (undo delete)`,
    repairVerb: `Repair`,
    repairWhatQuestion: (what: string | number) => `Repair ${what.toString().toLowerCase()}?`,
    repeat: `Repeat`,
    replacedBy: `Replaced by`,
    replacementDevices: `Replacement devices`,
    replacementDevicesSelected: `Replacement devices selected`,
    replaceWhat: (what: string | number) => `Replace ${what.toString().toLowerCase()}`,
    reports: `Reports`,
    request: `Request`,
    requestConfiguration: `Request configuration`,
    requested: `Requested`,
    requestForInsuranceAllowance: `Request for insurance allowance`,
    requestForInsuranceAllowances: `Requests for insurance allowance`,
    requestForInsuranceAllowanceShort: `Request for IA`,
    requestForInsuranceAllowancesShortened: `Requests for IA`,
    requestNew: `Request new`,
    requiredFieldsToFillInForAPatient: `Required fields to fill in for a patient`,
    requiredPatientFieldsDescription: `Indicated fields below are required to be completed for each patient.\nHAMS will display a popup screen if one or more of these fields are not completed`,
    requiredProperties: `Required properties`,
    reReadDataFromEIDCard: `Re-read data from eID card`,
    resendRegistrationTokenQuestion: `Resend registration token?`,
    reservation: `Reservation`,
    reservationCleared: `Reservation cleared`,
    reserved: `Reserved`,
    reservedArticles: `Reserved articles`,
    reservedForAPatient: `Reserved for a patient`,
    reservedForWhat: (whatX: string | number) => `Reserved for ${whatX}`,
    reservedShort: `Reserv.`,
    reservedToAnotherPatient: `Reserved to another patient`,
    reset: `Reset`,
    resetPassword: `Reset password`,
    resetPasswordMailSent: `Mail sent with instruction to reset password, check inbox`,
    restart: `Restart`,
    restartAfterTreatment: `Restart after treatment`,
    restartAfterTreatmentContentQuestion: `Are you sure to restart this after treatment?`,
    restoreVerb: `Restore`,
    results: `Results`,
    resultsHearingImprovementIn: `Results hearing improvement in`,
    retailPrice: `Retail price`,
    retry: `Retry`,
    return: `Return`,
    returnDate: `Return date`,
    returnedToSupplier: `Returned to supplier`,
    returns: `Returns`,
    returnSlip: `Return slip`,
    returnToSupplier: `Return to supplier`,
    returnToSupplierDate: `Return to supplier date`,
    revenueExVatPerCategory: `Revenue (ex. VAT) per category`,
    revenueExVatPerLocation: `Revenue (ex. VAT) per location`,
    revenueExVatPerMonth: `Revenue (ex. VAT) per month`,
    revenueExVatPerWeek: `Revenue (ex. VAT) per week`,
    revenues: `Revenues`,
    revenueThisYear: `Revenues this year`,
    revertIgnoreSale: `Undo ignore sale`,
    right: `Right`,
    rightColumn: `Right column`,
    rightEar: `Right ear`,
    rightForRenewal: `Right for renewal`,
    rightForRenewalTermInfoContent: `Right for renewal after 'x' years\n- >18 year at time of handover -> 5 jaar\n- <18 year at time of handover -> 3 jaar`,
    RIZIV: `RIZIV`,
    RIZIVCode: `RIZIV code`,
    RIZIVCoding: `RIZIV coding`,
    RIZIVNumber: `RIZIV number`,
    RIZIVRefundNomenclatureCode: (code: string | number) => `RIZIV refund nomenclature code`,
    role: `Role`,
    room: `Room`,
    rooms: `Rooms`,
    roomsAreOnlyUsedInCalendar: `Rooms are only used in calendar`,
    rosaHAMSLinkActive: `ROSA - HAMS link active`,
    rosaHAMSLinkNotActive: `ROSA - HAMS link not active`,
    rosaIntegration: `ROSA integration`,
    rosaLinkedPatientRecord: `Rosa linked patient record`,
    rosaLinkUsersAndSitesPageTitle: `ROSA link users and locations`,
    rosaNotActivatedNotification: `ROSA is not activated for this environment, contact support@hams.be to activate this integration.`,
    rosaNotConfiguredYet: `ROSA not configured yet`,
    rosaPatients: `Patients in ROSA`,
    rosaRecordNotFound: `ROSA patient not found`,
    rosaRecordNotFoundErrorDescription: `The requested link is not found in HAMS.
Try again later.`,
    rosaSettings: `ROSA Settings`,
    rule: `Rule`,
    ruleForWhatDelete: (what: string | number) => `Rule for ${what.toString().toLowerCase()} delete`,
    ruleForWhatRepair: (what: string | number) => `Rule for ${what.toString().toLowerCase()} repair`,
    rules: `Rules`,
    rulesForReadOuts: `Rules for readouts`,
    rulesForReadOutsSuggestionCaption: `If a device is read out by NOAH as naming(s) below, this article from product catalog will be shown as a suggestion`,
    runningLoanSingularOrPlural: `Active loan(s)`,
    runningProcesses: `Running processes`,
    runningRepairSingularOrPlural: `Active repair(s)`,
    runningRequestSingularOrPlural: `Active request(s)`,
    saldoBetween: `Saldo between`,
    saldoSettlementProposalsShort: `Saldo settl. prop.`,
    sale: `Sale`,
    saleHasMultipleCustomerAccounts: `Sale has multiple customer accounts`,
    saleHasNoCustomerAccounts: `Sale has no customer account`,
    saleIsACashSale: `Sale is a cash sale`,
    sales: `Sales`,
    salesCredited: `Sale credited`,
    salesData: `Sales data`,
    salesDate: `Sales date`,
    salesInvoicesForwarding: `Sales invoices forwarding`,
    salesListPrice: `Sales list price`,
    salesListPriceShort: `Sales list price`,
    salesPrice: `Sales price`,
    salesReference: `Sales reference`,
    salesStatus: `Sales status`,
    salesType: `Sales type`,
    salutation: `Salutation`,
    sameArticleForBothLeftAndRight: `Same article left and right`,
    sameLabelForEveryLanguage: `Same label for every language`,
    save: `Save`,
    saveAsNew: `Save as new`,
    saveChanges: `Save changes`,
    saveChangesToDocumentQuestion: `Do you want to save changes?`,
    saveChangesToTemplateQuestion: `Do you want to save changes?`,
    savedColumnSelection: `Saved column selection`,
    savedSelection: `Saved selection`,
    saveThisSelection: `Save this selection`,
    scan: `Scan`,
    scanned: `Scanned`,
    scannedDocumentIsWithoutSignature: `Scanned document is without signature`,
    scannedDocumentIsWithSignature: `Scanned document is with signature`,
    scannedOrUploaded: `Scanned or uploaded`,
    scanner: `Scanner`,
    scanOrUploadDocument: `Scan or upload document`,
    scanSignatureLater: `Scan signature later`,
    scanSoftware: `Scan software`,
    schedule: `Schedule`,
    scope: `Scope`,
    score: `Score`,
    scoreWith: `Score with`,
    scoreWithEquipment: `Score with equipment`,
    scoreWithEquipmentAtSNROfSRT: `Score with equipment at SNR of SRT`,
    scoreWithout: `Score without`,
    scoreWithoutEquipment: `Score without equipment`,
    search: `Search`,
    searchAnAddress: `Search an address`,
    searchAndSelectSerialNumberFor: `Search and select serial number for`,
    searchAppointmentsAndEvents: `Search appointments and events`,
    searchASerialNumberFor: `Search serialnumber for`,
    searchASerialNumberFromNoahReadOuts: `Search serialnumber from Noah readouts`,
    searchFromProductCatalog: `Search from product catalog`,
    searchInProductCatalogOrCreateNewItem: `Search in product catalog or create new item`,
    searchOnName: `Search on name`,
    searchOnNameOrExactOnlineCode: `Search on name or Exact Online code`,
    searchOnNameSlashCode: `Search on name/code`,
    searchOnReference: `Search on reference`,
    searchOnRIZIVNumber: `Search on RIZIV number`,
    searchOnSerialNumber: `Search on serial number`,
    searchOnWeb: `Search on web`,
    searchOnWhat: (what: string | number) => `Search on ${what.toString().toLowerCase()}`,
    searchOtherArticlesBelow: `Search other articles below`,
    searchPatients: `Search on name, address, date of birth, tel. #...`,
    searchSaleForCommission: `Search sale for commission`,
    searchSerialNumbersInInventoryItemsAndNoahReadouts: `Search serial numbers in inventory items and Noah readouts`,
    searchTimeSlot: `Search time slot`,
    searchUsers: `Search on name/email/…`,
    security: `Security`,
    securityToken: `Security token`,
    securityTokenIsExpired: `Security token is expired`,
    seen: `Seen`,
    selectADeviceFromRIZIVLimitativeList: `Select a device from RIZIV limitative list`,
    selectAModule: `Select a module`,
    selectAnArticleFromProductCatalog: `Select an article from product catalog`,
    selectAnExistingOrderOrRegisterANew: `Select an existing order or create a new one`,
    selectAnExistingSerialNumber: `Select an existing serial number`,
    selectAPatient: `Select a patient`,
    selectAPreviouslyPurchasedItem: `Select a previously purchased item`,
    selectASupplierForAnOrder: `Select a supplier for order`,
    selectATestWithAndWithoutHearingAids: `Select a test with hearing aids and a test without`,
    selectATestWithSpeechAudiograms: `Select a test with speech audiograms`,
    selectAtLeastOneWhat: (what: string | number) => `Select at least 1 ${what.toString().toLowerCase()}`,
    selectCodeFor: (what: string | number) => `Select code for: ${what.toString().toLowerCase()}`,
    selectCodesFirst: `Select codes first`,
    selectDefaultDeliveryTimeFromSupplier: `Select default delivery time from supplier`,
    selectDeliveryLocationHelperText: `Select the location where the delivery will be made`,
    selectedArticle: `Selected article`,
    selectedInATreatment: `Selected in a treatment`,
    selectedProduct: `Selected product`,
    selectedTreatment: `Selected treatment`,
    selectedWhat: (what: string | number) => `${what} selected`,
    selectExistingInventoryItemFromStock: `Select an existing article from inventory`,
    selectExportableFieldsBelow: `Select exportable fields below`,
    selectFromStock: `Select from inventory`,
    selectFromWhat: (what: string | number) => `Select from ${what.toString().toLowerCase()}`,
    selectHealthInsuranceFund: `Select a health insurance fund`,
    selection: `Selection`,
    selectItemsToAssure: `Select items to assure`,
    selectItemWithSerialNumberClickHereToLink: `Select a serialnumber, click here to link`,
    selectLineFromPreviousSale: `Select a line from a previous sale`,
    selectLineFromThisPurchase: `Select a line from this purchase`,
    selectLineFromThisSale: `Select a line from this sale`,
    selectLocationAtWhereDeliveryWillBeReceived: `Select location where delivery will be received`,
    selectLocationAtWhereYouAreCurrentlyWorking: `Select location where you're currently working`,
    selectNeeds: `Select needs`,
    selectNhaxOrEnhaxFile: `Select nhax or enhax file`,
    selectNhaxOrEnhaxFileCaption: `Select a .nhax or .enhax file\nIf patient already exists, actions and sessions will be merged\nIf patient does not exists, a new patient will be created`,
    selectNomenclatureCodeFirst: `Select a main nomenclature code first`,
    selectNomenclatureCodeFirstYouCanDeleteItAfterwards: `Select a nomanclature code\nYou can delete it afterwards`,
    selectOneOrMultipleSuppliers: `Select one or multiple suppliers`,
    selectOnlyNecessaryFields: `Select only necessary fields`,
    selectOnlyWho: (who: string | number) => `Select only ${who}`,
    selectOtherProductQuestion: `Select another product`,
    selectPseudoNomenclatureCodeFirst: `Select a pseudo nomenclature code first`,
    selectSerialNumbersForSelectedProducts: `Select serial numbers for selected products`,
    selectStep: (what: string | number) => `Select ${what.toString().toLowerCase()}`,
    selectSuppliers: `Select suppliers`,
    selectThis: `Select this`,
    selectThisArticle: `Select this article`,
    selectVerb: `Select`,
    selectWhat: (what: string | number) => `Select ${what.toString().toLowerCase()}`,
    selectWhatAndCreate: (what: string | number) => `Select ${what.toString().toLowerCase()} and create`,
    send: `Send`,
    sendRepairToSupplier: `Send repair to supplier`,
    sendToSupplier: `Send to supplier`,
    sentToAdvisoryPhysicianShort: `Sent to adv. phys.`,
    sentToEntPhysicianShort: `Sent to ENT`,
    sentToSupplier: `Sent to supplier`,
    sentToSupplierForRepair: `Sent to supplier for repair`,
    separator: `Separator`,
    separatorExamples: `f.e. '/' or '-' or '.'`,
    serialNumber: `Serial number`,
    serialNumberNotUnique: `Serial number not unique`,
    serialNumbers: `Serialnumbers`,
    serialNumberShort: `Serial nr.`,
    service: `Service`,
    serviceExplanation: `A service offered to your customers (e.g. audiometry)`,
    serviceName: `Service name`,
    session: `Session`,
    sessions: `Sessions`,
    setAManualDate: `Set a manual date`,
    setInvoicesAutoToPaid: `Set invoices automatically to paid`,
    setInvoicesToPaidViaPayments: `Follow up paymentstatus of invoices via payments`,
    setLocationAsNotPlannable: `Set location as not plannable`,
    setManualWarrantyEndDate: `Set manual warranty end date`,
    setPassword: `Set Password`,
    setStatusPatientToStatuses: `Set status of patient (deceased or no longer active)`,
    settings: `Settings`,
    settingsAndPreferences: `Settings and preferences`,
    settlementProposal: `Settlement proposal`,
    settlementProposals: `Settlement proposals`,
    setUserAsNotPlannable: `Set user as not plannable`,
    shiftVat: `Shift VAT`,
    shipment: `Shipment`,
    shortExternalReference: `Ext. ref.`,
    shortInternalReference: `Int. Ref.`,
    shortManufacturerReference: `Man. Ref.`,
    showAll: `Show all`,
    showAllContents: `Show all contents`,
    showAllDaysInclWeekend: `Show all days including weekend (SA/SU)`,
    showAllWhat: (what: string | number) => `Show all ${what.toString().toLowerCase()}`,
    showAnamnesis: `Show anamnesis`,
    showArticleAgain: `Show article again`,
    showColon: `Show:`,
    showHoursFrom: `Show houres from`,
    showHoursTo: `Show houres until`,
    showMore: `Show more`,
    showOnlyActive: `Show only active`,
    showOnlyNotPaid: `Show only not paid`,
    showOnlyOutstanding: `Show only outstanding`,
    showOnlyWeekdays: `Show only weekdays`,
    showOnlyWhat: (what: string | number) => `Show only ${what.toString().toLowerCase()}`,
    showProperties: `Show properties`,
    showStockPerLocation: `Show stock per location`,
    showThisArticle: `Show this article`,
    showWhat: (what: string | number) => `Show ${what.toString().toLowerCase()}`,
    signature: `Signature`,
    signatureNeeded: `Signature needed`,
    signatureScanned: `Signature scanned`,
    signatureScannedOrUploaded: `Signagure scanned or uploaded`,
    signDigitally: `Sign digitally`,
    signed: `Signed`,
    signVerb: `Sign`,
    singleLine: `Single line`,
    singlePatient: `Exclusive patient`,
    singlePayments: `Single payments`,
    singleReadout: `Exactly one reading`,
    skipFollowUpProcessesContentQuestion: `There are no processes to follow up in this treatment.\nAre you sure you want to skip this step?`,
    skipThisStep: `Skip this step`,
    slashCannotBeUsedAsASerialNumber: `'/' of '|' cannot appear in a serial number`,
    slightlyBetter: `Slightly better`,
    slip: `Slip`,
    small: `Small`,
    socialSecurityNumber: `Social security number`,
    socialSecurityNumberAbbreviation: `ssnr`,
    software: `Software`,
    softwareNotActiveOnThisComputer: `Software is not active on this computer`,
    solveProblems: `Solve problems`,
    someFunctionsAreNotAccessible: `Some functions are not accessible`,
    somethingOrSomething: (first: string | number, second: string | number) => `${first} or ${second.toString().toLowerCase()}`,
    somethingQuestionmark: (something: string | number) => `${something}?`,
    sometimes: `Sometimes`,
    sortOnWhat: (what: string | number) => `Sort on: ${what.toString().toLowerCase()}`,
    source: `Source`,
    speakerBracketsReceiver: `Speaker (receiver)`,
    speechAudiograms: `Speech audiograms`,
    speechAudiometries: `Speech audiometries`,
    speechAudiometryNotPossible: `Speech audiometry not possible`,
    speechAudiometryPossible: `Speech audiometry possible`,
    speechAudiometryPossibleOrNotPossible: `Speech audiometry possible/impossible`,
    speechAudiometryPossibleWithException: `Speech audiometry possible or exception (2.2.2)`,
    speechGraphs: `Speech graphics`,
    speechInNoise: `Speech in noise`,
    speechIntelligibilityImprovement: `Speech intelligibility improvement`,
    speechTest: `Speech test`,
    splitUpAttachment12: `Split up attachment 12`,
    ssNumbersDoNotMatch: `Social security numbers do not match`,
    standardDocumentWarning: (what: string | number) => `${what} is a standardised document\nbut HAMS detected a personalised version\nMake a choice`,
    standardisedVersion: `Standardised version`,
    standardWhat: (what: string | number) => `Default ${what.toString().toLowerCase()}`,
    startComparisonTrial: `Start comparison trial`,
    startComparisonTrialCaption: `Current devices in loan stay with patient\nNew hearing aids will be added to loan`,
    startDate: `Start date`,
    started: `Started`,
    startedThisYearBracketsYTD: `Started this year (YTD)`,
    startFromAnExample: `Start from an example`,
    startFromATemplate: `Start from a template`,
    startLoan: `Start loan`,
    startNewTrial: `Start new trial`,
    startNewTrialCaption: `Current devices in loan will return to stock\nA new trial is started`,
    startRepair: `Start repair`,
    startSoftware: `Start software`,
    startup: `Startup`,
    startVerb: `Start`,
    status: `Status`,
    statusCredited: `Credited`,
    statusNotCredited: `Not credited`,
    statusNotPurchased: `Not purchased`,
    statusNotReserved: `Not reserved`,
    statusNotSold: `Not sold`,
    statusPartlyCredited: `Partly credited`,
    statusPurchased: `Purchased`,
    statusReserved: `Reserved`,
    statusSold: `Sold`,
    step: `Step`,
    steps: `Steps`,
    stepsChanged: `Steps changed`,
    stereo: `Stereo`,
    stereoPhonic: `Stereophonic`,
    stereoWith: `Stereo with`,
    stereoWithout: `Stereo without`,
    stillCountToDeliver: (count: string | number) => `Still ${count.toString().toLowerCase()} to deliver`,
    stock: `Stock`,
    stockInfo: `Stock info`,
    stockInfoNotUpToDateClickToRecalculate: `Stock information not up-to-date, click here to recalculate.`,
    stockLocation: `Location with stock`,
    stockMovement: `Stock movement`,
    stockMovements: `Stock movements`,
    stockReplentishment: `Stock replenishment`,
    stockTransactions: `Stock transactions`,
    stockValue: `Stock value`,
    stop: `Stop`,
    stopDate: `Stop date`,
    structuredReference: `Structured reference`,
    style: `Style`,
    subArticle: `Subarticle`,
    subcategories: `Subcategories`,
    subcategory: `Subcategory`,
    subject: `Subject`,
    subProcess: `Subprocess`,
    subProcesses: `Subprocesses`,
    subtotal: `Subtotal`,
    successful: `Successful`,
    summary: `Summary`,
    supplement: `Supplement`,
    supplements: `Supplements`,
    supplier: `Supplier`,
    supplierDetails: `Supplier details`,
    supplierIsNotRegisteredOnThisProduct: (supplier: string | number) => `Supplier ${supplier.toString().toLowerCase()} is not registered on this product`,
    supplierName: `Supplier name`,
    supplierNameIsTaken: `A supplier with this name already exists.`,
    suppliers: `Suppliers`,
    supplierSingularOrPlural: `Supplier(s)`,
    supplierUpdated: `Supplier updated`,
    supplierWhoHasCountOrdersToAddLinesTo: (who: string | number, count: string | number) => `Supplier ${who} has ${count.toString().toLowerCase()} order(s) where lines can be added to`,
    supplierWillBeDeleted: `This supplier will be deleted permanently.`,
    synced: `Synced`,
    tableFormattingHelperText: `Create a table like example below\nBeneath heading in first colum fill in key`,
    tailorMadeFor: `Tailor made for`,
    tailorMadeHearingAid: `Hearing aid (tailor-made)`,
    tailorMadeHearingProtection: `Hearing protection (tailor-made)`,
    tailorMadeProduct: `Tailor made product`,
    tailorMadeProductExplanation: `Is tailor made for one of your customers (e.g. earpiece, tailor made hearing aid)`,
    tailorMadeProducts: `Tailor made products`,
    tailorMadeProductType: `Type tailor made product`,
    tailorMadeProductValidated: `Tailor made product validated`,
    tailorMadeShort: `Tailored`,
    tangible: `Tangible`,
    tangibleArticle: `Tangible article`,
    tangibleArticleWithoutSerialNumber: `Tangible product without a serial number`,
    tangibleArticleWithSerialNumber: `Tangible product with a serial number`,
    tangibleCaption: `A tangible product\nF.e. earmould, hearing aid, hearing protection,…`,
    technicalData: `Technical data`,
    template: `Template`,
    templateCanEditCaption: `This document can be changed`,
    templateCannotEditCaption: `This document cannot be changed`,
    templateNoSignatureNeededCaption: `A document created from this template doesn't need to be signed`,
    templateNotViewableInAppCaption: `This documents is appropriate to view online\n(not all Word features are supported)`,
    templatePredefinedTypeCaption: `Create a template based on a predefined type`,
    templates: `Templates`,
    templateScanSignatureLaterCaption: `A document created from this template must be changed later on\nwith a signed one`,
    templateStartFromEmptyCaption: `Start from an empty document`,
    templateStartFromExampleCaption: `Start from an example`,
    templateStartFromUploadCaption: `Start from a document on computer`,
    templateSubjectCaption: `Create a template based on a subject\nF.e. patient, request for IA, …`,
    templateViewableInAppCaption: `This document can be viewed online`,
    tender: `Tender`,
    tenderCustomerIsAPatientCaption: `Optionally tender can be directed to a VAT customer`,
    tenderCustomerIsNotAPatientCaption: `Data of receiver must be filled in manually\nOptionally tender can be directed to a VAT customer`,
    tendered: `Tender created`,
    tenderLines: `Tender lines`,
    tenders: `Tenders`,
    term: `Term`,
    termForHandoverAfterApproval: `Term handover after approval adv. phys.`,
    termRenewal: `Term renewal`,
    terms: `Terms`,
    test: `Test`,
    testEquipmentTitle: `Test equipment (trial)`,
    tests: `Tests`,
    testsAddedOrChangedAfterCreationOfDocument: `Tests added or changed after document was created`,
    thanksForYourTimeAndHonestFeedback: `Thank you for your time & honest feedback`,
    theHAMSFeedbackFormIsAvailableAt: `The HAMS Feedback Form is available at`,
    thereIsAnUpdateForWhatSoftware: (what: string | number) => `There's an update for ${what.toString().toLowerCase()} software`,
    thereIsNoTestWithAsWelALeftAsARightTonalAudiogramCreateFirst: `There's no test with as wel a left as a right tonal audiogram\nCreate them first in a Noah module`,
    thereIsNoTypeTonalAudiogramCreateFirst: (type: string | number) => `There's no ${type.toString().toLowerCase()} tonal audiogram test\nCreate this first from a Noah module`,
    thermalPrinter: `Thermal printer`,
    thermalPrinters: `Thermal printers`,
    thermalPrinterSoftware: `Thermal printer software`,
    thirdPayerArrangement: `Third payer`,
    thisAndNextAppointments: `This and following appointments`,
    thisAndNextWorkscheduleEntries: `This entry and following`,
    thisAppointment: `This appointment`,
    thisArticleWillBeAvailableForSaleWithNextSale: `This article will be available on the next sale.`,
    thisDateWillBeUsedToCalculateNomenclaturePrices: `This date will be used to calculate nomenclature prices`,
    thisDeliveryIsLinkedToAnOrder: `This delivery is linked to an order`,
    thisDeliveryIsLinkedToARepair: `This delivery is linked to a repair`,
    thisDeliveryIsLinkedToATailorMadeProduct: `This delivery is linked to a tailor made product`,
    thisDocumentContainsASignature: `This document contains a signature`,
    thisDocumentMustBeReplacedWithAScanWithSignature: `This document must be replaced with a signed one`,
    thisFileIsPasswordProtected: `This file is password protected`,
    thisHifDoesNotExistAnymore: `This health insurance fund does not exist anymore`,
    thisInfoByDefaultOnTop: `This information is on top by default`,
    thisLineWillBeIgnored: `This line will be ignored`,
    thisLink: `This link`,
    thisOrderIsCancelled: `This order is cancelled`,
    thisOrderShouldAlreadyBeDelivered: `This order should already have been delivered`,
    thisPatientAlreadyHasALinkWithAROSAPatient: `This HAMS patient already has a link with a ROSA patient`,
    thisPatientDoesNotExistInHAMSDoYouWantToImportNow: `This patient does not exist yet in HAMS.
Do you want to import this patient now?`,
    thisPatientIsMergedToAnotherPatient: `This patient is merged to another patient`,
    thisProcesIsPartOfARfia: `This proces is part of a request for insurance allowance`,
    thisProcessIsCancelled: `This proces is cancelled`,
    thisPurchase: `This purchase`,
    thisRepairShouldAlreadyBeRedelivered: `This repair should have already been delivered`,
    thisSale: `This sale`,
    thisScreenWillSoonBeReplacedByAnImprovedView: `This screen will soon be replaced with an improved display.`,
    thisTransactionCannotBeUndoneText: `Warning, this transaction is defintive!\nNot possible to delete it afterwards.\nCheck data below before confirming`,
    thisWhatWillAlsoBePurchasedFromASupplier: (what: string | number) => `This ${what.toString().toLowerCase()} will also be purchased from a supplier`,
    thisWhatWillNotBePurchasedFromASupplier: (what: string | number) => `This ${what.toString().toLowerCase()} will not be purchased from a supplier`,
    thisWorkscheduleEntry: `Only this entry`,
    timeline: `Timeline`,
    timeScale: `Time scale`,
    timetable: `Time table`,
    title: `Title`,
    toAnotherLocation: `To another location`,
    toAssign: `To assign`,
    toCreateNewProduct: `To create a new product`,
    toHandoverToPatient: `To handover to patient`,
    toLocation: `To location`,
    tonalAudiogram: `Tonal audiograms`,
    tonalAudiogramOfDate: `Tonal audiogram of`,
    tooltipIsACashDeskResource: `Can be used in cash register module`,
    tooltipIsAPlannableResource: `Can be used to plan meetings`,
    tooltipIsAStockManagementResource: `Can be used for stock`,
    tooltipIsNotACashDeskResource: `Can NOT be used in cash register module`,
    tooltipIsNotAPlannableResource: `Can NOT be used to plan meetings`,
    tooltipIsNotAStockManagementResource: `Can NOT be used for stock`,
    toPayBefore: `To pay before`,
    toPayBeforeDate: `To pay before date`,
    toPayByPatient: `To pay by patient`,
    toPlaceWithSupplier: `To place with supplier`,
    toProcess: `To process`,
    total: `Total`,
    totalAmount: `Total amount`,
    totalExVat: `Total ex VAT`,
    totalInVat: `Total in VAT`,
    totalOfContributions: `Total contributions`,
    totalPriceDevicesExVat: `Total price devices (ex. VAT)`,
    totals: `Totals`,
    totalValueOfDevices: `Total value of hearing aid devices`,
    totalValueOfSale: `Total value of sale`,
    transactions: `Transactions`,
    transactionType: `Transaction type`,
    treatment: `Treatment`,
    treatmentInvoiceCaption: `Separate billing of f.e. hearing aids without request for IA`,
    treatmentPermanentlyDeleted: `Treatment permanently deleted`,
    treatments: `Treatments`,
    trial: `Trial`,
    trialAdministrativeEndDate: `Administrative trial end date`,
    trialDuration: `Trial duration`,
    trialEnded: `Trial ended`,
    trialReport: `Trial report`,
    trialRunning: `Trial running`,
    trialsExpiredNoDocumentsSentForMedicalPrescription: `Trials expired, no documents sent to ENT phys.`,
    troubleShoot: `Troubleshoot`,
    twoDecimals: `Two decimals`,
    type: `Type`,
    typeAnnotation: `Type annotation`,
    typeAppointment: `Type appointment`,
    typeHearingAid: `Hearing aid type`,
    typePayer: `Type payer`,
    types: `Types`,
    typesAnnotation: `Annotation types`,
    typesAppointments: `Appointment types`,
    typicalForHardwareWithWithoutSerialNumber: `Typical for hardware (with/without serialnumber)`,
    ublFilesToHandle: `UBL's to handle`,
    unassigned: `Unassigned`,
    unblock: `Unblock`,
    unBlockUser: `Unblock user`,
    unblockUserContent: `This user will be able to login again.`,
    unBlockUserQuestion: `Unblock user?`,
    undefined: `Undefined`,
    understood: `Understood`,
    undoCancellation: `Undo cancellation`,
    undoDelete: `Undo delete`,
    undoDeleteAnnotationQuestion: `Undo delete annotation?`,
    undoDeleteAttachmentQuestion: `Undo delete attachment?`,
    undoFailedTrail: `Undo failed trial`,
    undoFailedTrailContent: `Errata; this request for insurance allowance is not failed.\nDo you want to continue?`,
    undoFailedTrailShort: `Undo failed trial`,
    undoIgnore: `Undo ignore`,
    undoPlaceWithSupplier: `Undo place with supplier`,
    undoPlaceWithSupplierQuestion: `Undo place with supplier?`,
    undoSkipThisStep: `Undo skip step`,
    undoSkipThisStepContentQuestion: `Are you sure to undo skip this step?`,
    unhandled: `Unhandled`,
    uniqueReference: `Unique reference`,
    unitDiscount: `Unit discount`,
    unitListPrice: `Unit list price`,
    unitPrice: `Unit price`,
    unitVatValue: `Unit VAT value`,
    unknown: `Unknown`,
    unknownDateFormat: `Unknown data format`,
    unlimited: `Unlimited`,
    unlinked: `Not linked`,
    unpaid: `Unpaid`,
    unPaidSales: `Unpaid sales`,
    unsavedChanges: `Unsaved changes`,
    updateContactInfo: `Change contact info`,
    updated: `Updated`,
    updateRIZIVNumber: `Update RIZIV number`,
    upload: `Upload`,
    uploadDocument: `Upload document`,
    uploadNHAX: `Upload NHAX`,
    uploadReplaceDocument: `Upload/replace document`,
    uploadWhat: (what: string | number) => `Upload ${what.toString().toLowerCase()}`,
    uploadWrittenOrSignedDocument: `Upload a (handwritten and/or signed) document`,
    upperCase: `First letter in capital`,
    useAPrefix: `Use a prefix`,
    useCodeFromProductCatalog: `Use codes of product catalog`,
    usedFrequencies: `Used frequencies`,
    usedList: `Used list`,
    useFallbackCodes: `Use fallback values`,
    usefulLinks: `Useful links`,
    useLatestDocument: `Use the latest document`,
    useOriginalDocument: `Use the original document`,
    user: `User`,
    userDefined: `User defined`,
    userRegisteredCheckEmail: (email: string | number) => `User ${email} registered, please check email.`,
    users: `Users`,
    userUpdated: `User updated`,
    userWillBeLoggedOutOfAllSessions: `User will be logged out of all devices and browsers`,
    userWillBeLoggedOutOfSpecificSession: `User will be logged out of specific device or browser`,
    userWithEmailCreated: (email: string | number) => `User with email: ${email} created`,
    useThisColorScheme: `Use this color scheme`,
    useYear: `Use year`,
    validated: `Validated`,
    validateData: `Validate data`,
    validatedNegativeResult: `Validated negative`,
    validateVerb: `Validate`,
    validation: `Validation`,
    validFrom: `Valid from`,
    validityFrom: `Validity from`,
    validityTo: `Validity to`,
    validPhoneNumberFormat: `Valid format: +32xxxxxxxxx`,
    validTo: `Valid to`,
    vat: `VAT`,
    vatAmount: `VAT amount`,
    vatCustomer: `VAT customer`,
    vatCustomers: `VAT customers`,
    vatNumber: `VAT number`,
    vatPercentage: `VAT %`,
    ventDiameterInMm: `Vent diameter (in mm)`,
    verzamelstaat: `Settlement proposal`,
    verzamelstaten: `Settlement proposals`,
    view: `View`,
    viewableInApp: `Viewable in app`,
    viewAccountReceivable: `View account receivable`,
    viewAll: `View all`,
    viewAllCashSales: `View all cash sales`,
    viewAllCountAppointments: (count: string | number) => `View all ${count.toString().toLowerCase()} appointments`,
    viewAllDetails: `View all details`,
    viewArticleFromProductCatalog: `View article from product catalog`,
    viewCustomerAccount: `View account receivable`,
    viewDetailsOfThisArticle: `View details of this article`,
    viewDocument: `View document`,
    viewDocumentation: `View documentation`,
    viewEditDocument: `View/edit document`,
    viewEditWhat: (what: string | number) => `View/edit ${what.toString().toLowerCase()}`,
    viewHAMSPatient: `View patient in HAMS`,
    viewHereHowToIntegrateWithROSA: `View here for more information on how to ask a ROSA integration`,
    viewInRosa: `View in ROSA`,
    viewInventoryItem: `View inventory item`,
    viewLinkedCustomerAccount: `View linked customer account`,
    viewLinkedInventoryItem: `View linked inventory item`,
    viewLinkedOrder: `View linked order`,
    viewLinkedRepair: `View linked repair`,
    viewNoahData: `View NOAH data`,
    viewPreferences: `View preferences`,
    viewRIZIVDocumentation: `View RIZIV documentation`,
    viewRosaHamsUsersLink: `View ROSA HAMS user link`,
    viewSale: `View sale`,
    viewSaleInHAMS: `View sale in HAMS`,
    viewSplitUpPerVatPercentage: `View split up per VAT%`,
    viewTarifs: `View tarifs`,
    viewTheOldVersionForNowQuestionClickHereToNavigate: `Still see the old view for now? Click here to go to the old view.`,
    viewWhatInOtherTab: (what: string | number) => `View ${what.toString().toLowerCase()} in other tab`,
    viewWhatInThisTab: (what: string | number) => `View ${what.toString().toLowerCase()} in this tab`,
    waitOnDocumentCreation: `Wait on document creation`,
    waitOnPaymentReferenceCreation: `Wait on payment reference creation`,
    wantToMoveAppointmenToAnotherTimeUserLocationQuestion: `Move appointment to different time/user/location?`,
    warning: `Warning`,
    warningHamsNoahClientVersion: `Attention!\n* HAMS-Noah client software only works with Noah version 4.12 or later\n* HAMS-Noah client software requires a valid Noah licence`,
    warningOnlyRegistrationOfSerialNumber: `Warning, only registration of serial number`,
    warningOnlyRegistrationOfSerialNumberCaption: `Through this way it's possible to define an inventory item with serial number\nInventory status is initially 'not inventoried' until transactions are added.\n(delivery, trial, sale, …)`,
    warningOnlyThingsLinkedToAttachment12CanBeAltered: `Warning; only attributes for attachment 12 can be changed (refundable & RIZIV-coding)`,
    warningOnlyUnlinkedAndNotIgnoredDevicesAreShownHere: `Warning: only not linked nor ignored devices are shown heren`,
    warningRegistrationHandoverBeforeApprovals: `Warning, registration handover before approvals`,
    warningRegistrationHandoverBeforeApprovalsCaption: `Through this way it's possible to register handover (and create an invoice) before the final approval.\n(This is not conform RIZIV guidelines)`,
    warningSingularOrPlural: `Warning(s)`,
    warningThisPatientAlreadyHasALinkWithAnotherRosaRecod: (who: string | number) => `Warning: patient '${who}' already has a link with another ROSA patient`,
    warrantyEndDate: `Warranty until date`,
    wasOverDue: `Was overdue`,
    website: `Website`,
    welcomeBack: `Welcome back`,
    whatAdded: (what: string | number) => `${what} added`,
    whatForUnArticleFromAPreviousPurchase: (what: string | number) => `${what} for an article from a previous purchase`,
    whatForUnArticleFromAPreviousSale: (what: string | number) => `${what} for an article from a previous sale`,
    whatSoftwareNotInstalled: (what: string | number) => `${what} software is not installed`,
    who: `Who`,
    WIACompatibleScanners: `WIA compatible scanners`,
    willBeDefaultOffice: `This will become the default office`,
    willBeRepeatedQuestion: `Will be repeated?`,
    willNotBePurchased: `Cannot purchase`,
    with: `With`,
    withOrWithoutTerm: `With/without term`,
    withOrWithoutWhat: (what: string | number) => `With/without ${what.toString().toLowerCase()}`,
    without: `Without`,
    withoutPrescriptionForTrialCaption: `Patient did not yet receive a prescription for trial`,
    withoutSeparateEarmould: `Without separate earmould`,
    withoutSeparateEarmouldCaption: `Without separate earmould (f.e. OpenFit, OpenEar, ...)`,
    withoutSeparateReceiver: `Without separate receiver`,
    withoutSeparateReceiverCaption: `Without separate receiver (f.e. Power BTE)`,
    withOutstandingBalance: `With outstanding balance`,
    withOutstandingBalanceNotOnSettlementProposals: `With outstanding balance, not on a settlement proposal`,
    withoutTerm: `Without term`,
    withoutThirdPayerArrangementCaption: `Patient will pay the full amount`,
    withoutWhat: (what: string | number) => `Without ${what.toString().toLowerCase()}`,
    withPrescriptionForTrialCaption: `Attachment 17 at hand, fill in prescription for trial date and select ENT physician`,
    withRespectToShort: `Wrt`,
    withSeparateEarmould: `With separate earmould`,
    withSeparateEarmouldCaption: `With separate earmould (f.e. Power BTE)`,
    withSeparateReceiver: `With separate receiver`,
    withSeparateReceiverCaption: `With separate receiver, receiver in the ear (f.e. RITE, RIC)`,
    withSerialNumber: `With serial number`,
    withThirdPayerArrangementCaption: `Patient will only pay allowance`,
    withWhat: (what: string | number) => `With ${what.toString().toLowerCase()}`,
    wizardAndHelp: `Wizard & Help`,
    work: `Work`,
    workflow: `Workflow`,
    workPhone: `Work phone #`,
    workWeek: `Work week`,
    worse: `Worse`,
    writeAnAddressHere: `Write an address here`,
    wrongDataQuestion: `Wrong data?`,
    wrongRIZIVNumberFormat: `Wrong format: enter a RIZIV number with format 'x-xxxx-xx-xxx'`,
    yearComesAfterPrefixAndBeforeNumber: `Year comes before 'prefix' and after 'index number'`,
    yes: `Yes`,
    yesCommaActivate: `Yes, activate!`,
    yesCommaContinue: `Yes, continue`,
    yesCommaCreateNewPatient: `Yes, create new patient`,
    yesCommaDeactivate: `Yes, deactivate!`,
    yesCommaDelete: `Yes, delete`,
    yesCommaSave: `Yes, save`,
    yesFillInNow: `Yes, fill in now`,
    yesForAll: `Yes, for all`,
    yesItFits: `Yes, it fits`,
    yesWhatExclamation: (what: string | number) => `Yes, ${what.toString().toLowerCase()}!`,
    zip: `Zip`,
}
