import { CardProps, Divider } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { LocationsPreloadCacheContext } from 'app/main/settings/locations/LocationsPreloadCacheContext';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { MoneyRangeFilterComponent } from 'framework/filter/MoneyRangeFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { isFiltered } from 'framework/utils/isFiltered';
import { CreditedStatus, CreditedStatuses, ISalesQueryMetadata, ISalesQueryParams, PaymentStatus, PaymentStatuses, SalesType, SalesTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { SalesFilterDataContext } from '../SalesFilterDataContext';
import { SalesListPrefilter } from './SalesListPrefilter';
import { SalesParamsExclusions } from './SalesParamsExclusions';
import { clearSalesFilterParams } from './clearSalesFilterParams';

interface IProps extends CardProps {
	params: ISalesQueryParams;
	setParams: (params: ISalesQueryParams) => void;
	metadata: ISalesQueryMetadata;
}

export const SalesListFilterCard = ({ params, setParams, metadata, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const { paymentStatusRecord, saleTypeRecord, creditedStatusRecord } = useContext(RecordContext);
	const isFilteredX = useMemo(() => isFiltered(params, SalesParamsExclusions), [params]);
	const onFilter = (pars: ISalesQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearSalesFilterParams(params)));
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const [filterData] = useCacheContext(SalesFilterDataContext);

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<SalesListPrefilter
				selectedPrefilter={params.prefilter}
				setSelectedPrefilter={key => onFilter({ ...params, prefilter: key })}
				metadata={metadata}
			/>
			<Divider />
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<DateFilterItem
					label={strings.dateCreated}
					after={params.createdAfter}
					before={params.createdBefore}
					setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
				/>
				<DateFilterItem
					label={strings.validTo}
					after={params.validUntilAfter}
					before={params.validUntilBefore}
					setSelected={(after, before) => onFilter({ ...params, validUntilAfter: after, validUntilBefore: before })}
				/>
				<RecordListFilterComponent<PaymentStatus>
					options={PaymentStatuses}
					record={paymentStatusRecord}
					selected={params.paymentStatuses as any}
					setSelected={vals => onFilter({ ...params, paymentStatuses: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.paymentStatus}
				/>
				<RecordListFilterComponent<SalesType>
					options={SalesTypes}
					record={saleTypeRecord}
					selected={params.types as any}
					setSelected={vals => onFilter({ ...params, types: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.type}
				/>
				<RecordListFilterComponent<CreditedStatus>
					record={creditedStatusRecord}
					options={CreditedStatuses}
					selected={params.creditedStatuses as any}
					setSelected={vals => onFilter({ ...params, creditedStatuses: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.creditStatus}
				/>
				<MoneyRangeFilterComponent
					label={strings.price}
					selected={{ min: params.priceInVatMoreThan, max: params.priceInVatLessThan }}
					setSelected={val => onFilter({ ...params, priceInVatLessThan: val.max, priceInVatMoreThan: val.min })}
					icon={<HelpOutlineIcon />}
				/>
				<BooleanRadioFilterComponent
					trueText={strings.withWhat(strings.document)}
					falseText={strings.withoutWhat(strings.document)}
					selected={params.hasDocument}
					setSelected={val => onFilter({ ...params, hasDocument: val })}
					label={strings.documents}
					icon={<HelpOutlineIcon />}
				/>
				<ListFilterComponent<string>
					options={locations}
					selected={params.locationIds}
					setSelected={vals => onFilter({ ...params, locationIds: vals })}
					label={strings.location}
					icon={<HelpOutlineIcon />}
					title={strings.location}
				/>
				{filterData && (
					<StringListFilterComponent
						options={filterData.labels}
						selected={params.labels}
						setSelected={vals => onFilter({ ...params, labels: vals })}
						label={strings.labels}
						icon={<HelpOutlineIcon />}
					/>
				)}
				{filterData && (
					<StringListFilterComponent
						options={filterData.zips}
						selected={params.zips}
						setSelected={vals => onFilter({ ...params, zips: vals })}
						label={strings.zip}
						icon={<HelpOutlineIcon />}
					/>
				)}
				<BooleanRadioFilterComponent
					trueText={strings.yes}
					falseText={strings.no}
					selected={params.isRecipientVatCustomer}
					setSelected={val => onFilter({ ...params, isRecipientVatCustomer: val })}
					label={strings.vatCustomer}
					icon={<HelpOutlineIcon />}
				/>
			</div>
		</CardX>
	);
};
