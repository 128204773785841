import React, { useContext } from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { createHeader } from 'framework/table/createHeader';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { CustomerAccountType, ICustomerAccountLineData } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { PaymentStatusChip } from 'shared/PaymentStatusChip';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	lines: ICustomerAccountLineData[];
}

export const CustomerAccountLinesTable = ({ lines }: IProps) => {
	const strings = useLocalization();
	const { customerAccountTypeRecord } = useContext(RecordContext);

	const headers = [
		createHeader<ICustomerAccountLineData>(strings.status, t => <PaymentStatusChip data={t} />),
		createHeader<ICustomerAccountLineData>(strings.type, t => (
			<RectangularChip
				label={customerAccountTypeRecord[t.accountType as CustomerAccountType]}
				fill
			/>
		)),
		createHeader<ICustomerAccountLineData>(`${strings.who}?`, t => t.customerAccountIdentifier),
		createHeader<ICustomerAccountLineData>(strings.amount, t => formatCurrency(t.value)),
		createHeader<ICustomerAccountLineData>(strings.amountPaid, t => formatCurrency(t.amountPaid)),
	];

	return (
		<TableWithHeadersAndValues<ICustomerAccountLineData>
			values={lines}
			headers={headers}
			getKey={t => t.id}
			withPaperContainer
		/>
	);
};
