import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useLayoutEffect, useState } from 'react';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { SelectField } from 'framework/components/select/SelectField';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { salesQuery_salesTicket } from 'gen/ApiClient';
import { IThermalPrinter, settingsQuery_get, thermalPrinter_print, thermalPrinter_printers } from 'gen/thermalPrinter/thermalPrinterClient';
import { useLocalization } from 'localization/useLocalization';
import { NoRecordsFound } from '../../../framework/components/NoRecordsFound';
import { IPrintSalesTicketDialogProps } from './IPrintSalesTicketDialogProps';

export const InstalledDialog = ({ title, cashSaleId, cancel, confirm, previous, ...rest }: IPrintSalesTicketDialogProps) => {
	const strings = useLocalization();
	const [print, isPrinting] = useFormSubmit(thermalPrinter_print);
	const [settings] = useApiEffect(settingsQuery_get);
	const [printers] = useApiEffect(thermalPrinter_printers);
	const [selectedPrinter, setSelectedPrinter] = useState<IThermalPrinter>();
	const [selectedPaperSize, setSelectedPaperSize] = useState<string>();
	const [data] = useApiEffect(salesQuery_salesTicket, cashSaleId);

	useLayoutEffect(() => {
		if (settings && printers) {
			const fP = printers.find(t => t.name === settings.printerName);
			if (fP) {
				setSelectedPrinter(fP);
				const fS = fP.paperSources.find(t => t === settings.paperSizeName);
				if (fS) {
					setSelectedPaperSize(fS);
				}
			}
		}
	}, [settings, printers]);

	const onPrint = async () => {
		if (selectedPrinter && selectedPaperSize && data) {
			const r = await print({ printerName: selectedPrinter.name!, paperSizeName: selectedPaperSize, data: data });
			if (r.isSuccess) {
				confirm();
			}
		}
	};

	return (
		<Dialog
			maxWidth='sm'
			fullWidth
			{...rest}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				{printers !== undefined && printers.length > 0 && selectedPrinter === undefined && (
					<SelectField<IThermalPrinter>
						options={printers}
						value={selectedPrinter}
						onChange={setSelectedPrinter}
						getKey={t => t.name!}
						label={strings.thermalPrinter}
						renderValue={t => t.name!}
					/>
				)}
				{printers !== undefined && printers.length > 0 && selectedPrinter && (
					<SelectField<IThermalPrinter>
						options={printers}
						value={selectedPrinter}
						onChange={setSelectedPrinter}
						getKey={t => t.name!}
						label={strings.thermalPrinter}
						renderValue={t => t.name!}
					/>
				)}
				{(printers === undefined || printers.length === 0) && <NoRecordsFound>{strings.noWhatFound(strings.thermalPrinters)}</NoRecordsFound>}
				{selectedPrinter !== undefined && (
					<>
						<DividerWithCaption caption={strings.paperSize} />
						<SelectField<string>
							options={selectedPrinter.paperSources}
							value={selectedPaperSize}
							onChange={setSelectedPaperSize}
							getKey={t => t}
							label={strings.formatAsSize}
							renderValue={t => t}
						/>
					</>
				)}
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				previous={previous}
				submitText={strings.print}
				isSubmitting={isPrinting}
				isValid={selectedPrinter !== undefined && selectedPaperSize !== undefined && data !== undefined}
				onSubmit={onPrint}
			/>
		</Dialog>
	);
};
