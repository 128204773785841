import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { SelectableColumn } from '../SelectableColumn';
import { ViewContext } from './ViewContext';
import { QuickFilter } from '../QuickFilter';
import { createColumnRecord } from '../utils/createColumnRecord';
import { createHeaders } from '../utils/createHeaders';
import { RecordContext } from 'shared/records/RecordContext';
import { createQuickFilterRecord } from '../utils/createQuickFilterRecord';
import { AllQueryContext } from './AllQueryContext';
import { MyQueryContext } from './MyQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { createCommissionExportRecord } from './createCommissionExportRecord';

// enkel hier nodig
const DefaultColumns: SelectableColumn[] = ['created', 'ruleName', 'rulePercentage', 'userIdentifier', 'salesReference'];

export const ViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);
	const colRecord = useMemo(() => createColumnRecord(strings), [strings]);
	const { commissionRuleMethodRecord } = useContext(RecordContext);
	const headers = useMemo(() => createHeaders(colRecord, commissionRuleMethodRecord, strings), [colRecord, strings, commissionRuleMethodRecord]);
	const qfRecord = useMemo(() => createQuickFilterRecord(strings), [strings]);
	const [qf, setQf] = useState<QuickFilter>('notPaid');
	const { setParams: setAllParams } = useContext(AllQueryContext);
	const { setParams: setMyParams } = useContext(MyQueryContext);
	const exportRecord = useMemo(() => createCommissionExportRecord(strings), [strings]);

	useEffect(() => {
		setAllParams(allParams =>
			onResetToPage1({
				...allParams,
				isPaidOut: qf === 'notPaid' ? false : undefined,
			})
		);
		setMyParams(myParams =>
			onResetToPage1({
				...myParams,
				isPaidOut: qf === 'notPaid' ? false : undefined,
			})
		);
		// eslint-disable-next-line
	}, [qf]);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
				quickFilter: qf,
				quickFilterRecord: qfRecord,
				setQuickFilter: setQf,
				exportRecord: exportRecord,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
