import { Tooltip, TooltipProps } from '@material-ui/core';
import React from 'react';

interface IProps extends TooltipProps {
	spanStyle?: React.CSSProperties;
}

export const TooltipWithSpan = ({ children, spanStyle, ...rest }: IProps) => {
	return (
		<Tooltip {...rest}>
			<span style={spanStyle}>{children}</span>
		</Tooltip>
	);
};
