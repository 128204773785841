import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { useMemo } from 'react';
import { toggleSelect } from 'framework/utils/array/toggleSelect';
import { PageClaim, PageClaims } from 'gen/ApiClient';
import { IPageClaimTree } from './IPageClaimTree';
import { PageClaimGroup } from './PageClaimGroup';

interface IProps {
	indentation?: number;
	t: IPageClaimTree;
	selected: PageClaim[];
	setSelected: React.Dispatch<React.SetStateAction<PageClaim[]>>;
	record: Record<PageClaim | PageClaimGroup, string>;
}

const isPageClaim = (claimOrGroup: PageClaim | PageClaimGroup): boolean => {
	return PageClaims.indexOf(claimOrGroup as any) !== -1;
};

const getAllClaims = (tree: IPageClaimTree): PageClaim[] => {
	if (tree.children === undefined) {
		return isPageClaim(tree.claim) ? [tree.claim as PageClaim] : [];
	} else {
		return tree.children.flatMap(t => getAllClaims(t));
	}
};

export const TreePageClaimListItem = ({ indentation = 0, t, selected, setSelected, record }: IProps) => {
	const paddingLeft = useMemo(() => (indentation === 0 ? 16 : indentation === 1 ? 40 : 80), [indentation]);
	const allClaims = useMemo(() => getAllClaims(t), [t]);
	const showChildren = useMemo(() => (isPageClaim(t.claim) ? selected.indexOf(t.claim as PageClaim) > -1 : true), [t, selected]);

	const onClickGroup = () => {
		const isAll = allClaims.every(x => selected.indexOf(x) !== -1);
		if (isAll) {
			setSelected(selected.filter(t => allClaims.indexOf(t) === -1));
		} else {
			let x = [...selected];
			allClaims.forEach(t => {
				if (selected.indexOf(t) === -1) {
					x.push(t);
				}
			});
			setSelected(x);
		}
	};

	return (
		<React.Fragment>
			{isPageClaim(t.claim) ? (
				<ListItem
					style={{ paddingLeft: paddingLeft }}
					button
					onClick={() => setSelected(toggleSelect(t.claim as PageClaim, selected))}>
					<ListItemIcon>
						<Checkbox checked={selected.indexOf(t.claim as PageClaim) !== -1} />
					</ListItemIcon>
					<ListItemText primary={record[t.claim]} />
				</ListItem>
			) : (
				<ListItem
					style={{ paddingLeft: paddingLeft }}
					button
					onClick={onClickGroup}>
					<ListItemIcon>
						<Checkbox
							checked={allClaims.every(x => selected.indexOf(x) !== -1)}
							indeterminate={allClaims.some(x => selected.indexOf(x) !== -1)}
						/>
					</ListItemIcon>
					<ListItemText primary={record[t.claim]} />
				</ListItem>
			)}
			{t.children !== undefined &&
				showChildren &&
				t.children.map(x => (
					<TreePageClaimListItem
						key={x.claim}
						t={x}
						indentation={indentation + 1}
						selected={selected}
						setSelected={setSelected}
						record={record}
					/>
				))}
		</React.Fragment>
	);
};
