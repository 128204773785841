import { AppBar, Button, Dialog, DialogProps, IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import SaveIcon from '@material-ui/icons/Save';
import { RichEdit } from 'devexpress-richedit';
import React, { useState } from 'react';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { SlideUpTransition } from 'framework/components/transitions/SlideUpTransition';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffectWithDefer } from 'framework/hooks/useApiEffectWithDefer';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Try } from 'framework/Try';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { IDocumentContextKey, ITemplate, IValidateResponse, LanguageOption, templatesCommand_upload, templatesQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { Rte } from 'documents/Rte';
import { exportToBase64 } from 'documents/utils/exportToBase64';
import { useDialogsContext } from '../useDialogsContext';
import { DocumentKeysComponent } from './DocumentKeysComponent';
import { SelectExampleTemplateDialog } from './SelectExampleTemplateDialog';

interface IProps extends DialogProps {
	templateId: string | undefined;
	content: string | undefined;
	setContent: (content: string) => void;
	close: VoidFunction;
	isInitial?: boolean;
	isReadOnly?: boolean;
	keys: IDocumentContextKey[];
	hasChanges: boolean;
	setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TemplateEditorDialog = ({
	templateId,
	content,
	setContent,
	close,
	hasChanges,
	setHasChanges,
	isInitial = false,
	isReadOnly = false,
	keys,
	...rest
}: IProps) => {
	const strings = useLocalization();
	const [richEdit, setRichEdit] = useState<RichEdit>();
	const { open: openSaveAndClose, confirm: saveAndClose } = useDialogsContext(close);
	const { open, confirm, cancel } = useDialogsContext();
	const [save, isSubmitting] = useFormSubmit(templatesCommand_upload);
	const [template] = useApiEffectWithDefer(templatesQuery_single, templateId ?? '', isNotNullNorUndefined);

	const onClose = () => {
		if (hasChanges === false || isReadOnly === true) {
			close();
		} else {
			openSaveAndClose(
				<AlertDialog
					open
					title={strings.unsavedChanges}
					content={strings.saveChangesToTemplateQuestion}
					acceptText={strings.yesCommaSave}
					rejectText={strings.noCommaCloseWithoutSaving}
					reject={saveAndClose}
					acceptF={() => onSave(templateId ?? '')}
					accept={saveAndClose}
				/>
			);
		}
	};

	const onSave = async (templateId: string): Promise<Try<IValidateResponse>> => {
		const content = await exportToBase64(richEdit ?? (undefined as any));
		const r = await save(templateId, content);
		if (r.isSuccess) {
			setHasChanges(false);
		}
		return r;
	};

	const onExample = (template: ITemplate) => {
		open(
			<SelectExampleTemplateDialog
				open
				language={template.language as LanguageOption}
				predefinedType={template.predefinedType}
				mainContext={template.mainContext}
				confirm={content => {
					confirm();
					onExampleSelected(content);
				}}
				cancel={cancel}
			/>
		);
	};

	const onExampleSelected = (content: string) => {
		setHasChanges(true);
		setContent(content);
	};

	return (
		<Dialog
			fullScreen
			TransitionComponent={SlideUpTransition}
			{...rest}>
			{rest.open && template && (
				<>
					<AppBar style={{ position: 'relative' }}>
						<Toolbar className='df-row-ac jc-sb'>
							<Typography>{`${strings.template} ${strings.view.toLowerCase()}/${strings.edit.toLowerCase()}`}</Typography>
							<div className='df-row-ac'>
								{isReadOnly === false && (
									<Button
										color='inherit'
										startIcon={<CloudDownloadOutlinedIcon />}
										onClick={() => onExample(template)}
										style={{ marginRight: 16 }}>
										{strings.startFromAnExample}
									</Button>
								)}
								<LoaderButton
									color='inherit'
									startIcon={<SaveIcon />}
									isLoading={isSubmitting}
									onClick={() => onSave(template.id)}
									disabled={hasChanges === false}>
									{strings.save}
								</LoaderButton>
								<IconButton
									edge='end'
									color='inherit'
									onClick={onClose}
									aria-label='close'>
									<CloseIcon />
								</IconButton>
							</div>
						</Toolbar>
					</AppBar>
					<div
						className='df-row fg1'
						style={{ height: '100%', overflow: 'hidden' }}>
						<Rte
							data={content}
							richEdit={richEdit}
							setRichEdit={setRichEdit}
							setHasChanges={setHasChanges}
							isReadOnly={isReadOnly}
						/>
						<DocumentKeysComponent keys={keys} />
					</div>
				</>
			)}
		</Dialog>
	);
};
