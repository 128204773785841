import { Collapse, Divider, TableRow, useTheme } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { SupplierDialog } from 'app/main/contacts/suppliers/SupplierDialog';
import { calcInventoryItemDetailRoute, calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { ExpandLessOrMoreIcon } from 'framework/components/ExpandLessOrMoreIcon';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ChipX } from 'framework/components/chips/ChipX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { TableCellX } from 'framework/table/TableCellX';
import { TableDetailsRowX } from 'framework/table/TableDetailsRowX';
import { formatDate } from 'framework/utils/date/formatDate';
import { IOrderV2 } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { calcOrderDetailRoute } from '../../ProcessesRoutes';
import { OrderStatusChip } from '../OrderStatusChip';
import { mapOrderToColorX } from '../mapOrderToColorX';
import { OrderListItemStatusIcon } from './OrderListItemStatusIcon';

const MinorFontSize = 13;

interface IProps {
	item: IOrderV2;
	isExpanded: boolean;
	toggle: VoidFunction;
}

export const OrderTableRow = ({ item, isExpanded, toggle }: IProps) => {
	const strings = useLocalization();
	const color = useMemo(() => mapOrderToColorX(item), [item]);
	const tColor = useThemeColor(color);
	const theme = useTheme();
	const { push } = useHistory();
	const [isHovered, enter, leave] = useStateBoolean(false);
	const { open, cancel } = useDialogsContext();

	const onViewDetails = () => push(calcOrderDetailRoute(item.id));

	const onViewSupplier = () => {
		open(
			<SupplierDialog
				open
				id={item.supplierId}
				close={cancel}
			/>
		);
	};

	return (
		<React.Fragment>
			<TableRow
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<TableCellX>
					<div className='df-row gap-4'>
						<OrderListItemStatusIcon
							item={item}
							style={{ fontSize: 22, color: tColor }}
						/>
						<div className='df-col gap-2'>
							<TextLinkButton
								underline
								style={{ fontSize: 14 }}
								onClick={onViewDetails}>
								{item.reference}
							</TextLinkButton>
							<OrderStatusChip
								item={item}
								color={color}
								style={{ width: 'fit-content' }}
							/>
							{Boolean(item.externalReference) && (
								<span
									style={{
										fontSize: MinorFontSize,
										color: theme.palette.text.secondary,
									}}>{`${strings.shortExternalReference}: ${item.externalReference}`}</span>
							)}
						</div>
					</div>
				</TableCellX>
				<TableCellX>
					<div className='df-col gap-2'>
						<span>{formatDate(item.created)}</span>
						<span style={{ fontSize: MinorFontSize, color: theme.palette.text.secondary }}>
							{`${strings.expectedDeliveryDate}: ${formatDate(item.expectedDeliveryDate)}`}
						</span>
						{item.isOverDue && (
							<ChipX
								label={strings.overdue}
								color='warning'
							/>
						)}
					</div>
				</TableCellX>
				<TableCellX>
					{item.isForSinglePatient && (
						<TextLinkButton
							style={{ fontSize: MinorFontSize }}
							underline
							onClick={() => push(calcPatientDetailRoute(item.singlePatientId!))}>
							{item.singlePatientIdentifier}
						</TextLinkButton>
					)}
					{item.isForSinglePatient === false && item.patients.length > 0 && <span style={{ fontSize: MinorFontSize }}>{strings.forMultiplePatients}</span>}
					{item.isForSinglePatient === false && item.patients.length === 0 && <span style={{ fontSize: MinorFontSize }}>{strings.notForAPatient}</span>}
				</TableCellX>
				<TableCellX>
					<TextLinkButton
						style={{ fontSize: MinorFontSize }}
						onClick={onViewSupplier}>
						{item.supplierIdentifier}
					</TextLinkButton>
				</TableCellX>
				<TableCellX align='right'>
					<div className='df-row-ac jc-e'>
						<span style={{ fontSize: MinorFontSize, fontWeight: 'unset' }}>
							{`# ${item.countItems.toString()} ${
								item.countItems === 1 || item.countItems === -1 ? strings.article.toLowerCase() : strings.articles.toLowerCase()
							}`}
						</span>
						<ColorOnHoverIconButton
							edge='end'
							size='small'
							color='primary'
							onClick={toggle}>
							<ExpandLessOrMoreIcon isExpanded={isExpanded} />
						</ColorOnHoverIconButton>
						<ColorOnHoverIconButton
							size='small'
							edge='end'
							color='primary'
							onClick={onViewDetails}>
							<VisibilityOutlinedIcon />
						</ColorOnHoverIconButton>
					</div>
				</TableCellX>
			</TableRow>
			<TableDetailsRowX
				colSpan={5}
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<Collapse in={isExpanded}>
					<div
						className='df-col'
						style={{ paddingBottom: 12 }}>
						<Divider
							variant='inset'
							style={{ marginTop: 8, marginBottom: 4 }}
						/>
						{item.lines.map(line => (
							<div
								className='df-row-ab'
								key={line.id}
								style={{ fontSize: MinorFontSize, gap: 4 }}>
								<span>{`${line.quantity.toString()} x`}</span>
								<TextLinkButton
									underline
									style={{ fontSize: MinorFontSize }}
									onClick={() => {
										if (line.inventoryItemId) {
											push(calcInventoryItemDetailRoute(line.inventoryItemId));
										} else {
											push(calcProductCatalogDetailRoute(line.productCatalogItemId));
										}
									}}>
									{line.description}
								</TextLinkButton>
								{item.isForSinglePatient === false && line.hasPatient && (
									<TextLinkButton
										style={{ fontSize: MinorFontSize }}
										underline
										onClick={() => push(calcPatientDetailRoute(line.patientId!))}>
										{line.patientIdentifier}
									</TextLinkButton>
								)}
							</div>
						))}
					</div>
				</Collapse>
			</TableDetailsRowX>
		</React.Fragment>
	);
};
