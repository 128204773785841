import React, { useState } from 'react';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { exportColumnSelectionsCommand_update, IExportColumnSelection } from 'gen/ApiClient';
import { OverwriteOrNewMode, SelectOverwriteOrNewDialog } from '../selectColumns/SelectOverwriteOrNewDialog';
import { ExportColumnSelectionModelForm } from './ExportColumnSelectionModelForm';

interface IProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	selected?: IExportColumnSelection;
	view: string;
	columns: string[];
}

export const ExportColumnSelectionForm = ({ cancel, confirm, view, columns, selected }: IProps) => {
	const [state, setState] = useState<'new' | 'select'>(selected === undefined ? 'new' : 'select');
	const [update, isUpdating] = useFormSubmit(exportColumnSelectionsCommand_update);

	const onSelectMode = async (mode: OverwriteOrNewMode) => {
		if (mode === 'overwrite') {
			const r = await update(selected!.id, columns);
			if (r.isSuccess) {
				confirm(selected!.id);
			}
		} else {
			setState('new');
		}
	};

	if (state === 'select') {
		return (
			<SelectOverwriteOrNewDialog
				open
				confirm={onSelectMode}
				cancel={cancel}
				overwriteCaption={`Overschrijf kolommen van '${selected!.name}'`}
				isOverwriting={isUpdating}
			/>
		);
	} else {
		return (
			<ExportColumnSelectionModelForm
				open
				view={view}
				columns={columns}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	}
};
