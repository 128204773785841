import React from 'react';
import { EntPhysiciansCacheProvider } from './EntPhysiciansCacheProvider';
import { EntPhysiciansFilterDataProvider } from './EntPhysiciansFilterDataProvider';
import { EntPhysiciansQueryProvider } from './EntPhysiciansQueryProvider';

export const EntPhysiciansProviders = ({ children }: any) => {
	return (
		<EntPhysiciansCacheProvider>
			<EntPhysiciansFilterDataProvider>
				<EntPhysiciansQueryProvider>{children}</EntPhysiciansQueryProvider>
			</EntPhysiciansFilterDataProvider>
		</EntPhysiciansCacheProvider>
	);
};
