import { Button, DialogActions, DialogContent, DialogProps, DialogTitle, Radio } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { DialogX } from 'framework/dialogs/DialogX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { IExactGlobeCostUnit, IExactGlobeProductCatalogItemDto, exactGlobeCostUnit_all, exactGlobeProductCatalogItem_link } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';

const filter = (items: IExactGlobeCostUnit[], search: string | undefined) => {
	if (search === undefined) {
		return items;
	} else {
		return items.filter(t => `${t.code ?? ''} ${t.description ?? ''}`.toLowerCase().includes(search.toLowerCase()));
	}
};

interface ILinkExactGlobeProductCatalogItemWithCostUnitDialogProps extends DialogProps {
	item: IExactGlobeProductCatalogItemDto;
	cancel: VoidFunction;
	confirm: () => void;
}

export const LinkExactGlobeProductCatalogItemWithCostUnitDialog = ({
	item,
	cancel,
	confirm,
	...rest
}: ILinkExactGlobeProductCatalogItemWithCostUnitDialogProps) => {
	const strings = useLocalization();
	const [costUnits] = useApiEffect(exactGlobeCostUnit_all);
	const [searchString, setSearchString] = useState<string>('');
	const fGLAccounts = useMemo(() => filter(costUnits ?? [], searchString), [costUnits, searchString]);
	const [selectedCostUnit, setSelectedCostUnit] = useAccordionState<IExactGlobeCostUnit | undefined>();
	const [submit] = useFormSubmit(exactGlobeProductCatalogItem_link);
	const notify = useSnackbarNotify();

	useEffect(() => {
		setSelectedCostUnit(undefined);
		// eslint-disable-next-line
	}, [searchString]);

	const onSubmit = async () => {
		if (selectedCostUnit === undefined) {
			return;
		}

		const r = await submit({ hamsProductCatalogItemId: item.id, exactGlobeCostUnitId: selectedCostUnit.id });
		if (r.isFailure) {
			notify(r.error);
		} else {
			confirm();
		}
	};

	return (
		<DialogX
			{...rest}
			open={rest.open}
			fullHeight>
			<DialogTitle>
				<div className='df-col gap-8'>
					<span>{`Link product cataloog item met kostendrager`}</span>
					<SearchbarX
						placeholder={`${strings.searchOnNameSlashCode}...`}
						onSearch={setSearchString}
						value={searchString}
					/>
				</div>
			</DialogTitle>
			<DialogContent
				className='df-col gap-8'
				dividers>
				{fGLAccounts.map(t => (
					<ExactGlobeCostUnitCardX
						key={t.id}
						item={t}
						onSelect={() => setSelectedCostUnit(t)}
						isSelected={t.id === selectedCostUnit?.id}
					/>
				))}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button
					onClick={onSubmit}
					color='primary'
					disabled={selectedCostUnit === undefined}>
					{strings.proceed}
				</Button>
			</DialogActions>
		</DialogX>
	);
};

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IExactGlobeCostUnitCardXProps {
	item: IExactGlobeCostUnit;
	onSelect: VoidFunction;
	isSelected: boolean;
	style?: React.CSSProperties;
}

const ExactGlobeCostUnitCardX = ({ item, style, onSelect, isSelected }: IExactGlobeCostUnitCardXProps) => {
	const strings = useLocalization();
	const [hovered, enter, leave] = useStateBoolean(false);

	return (
		<CardX
			className='df-row gap-8 w100'
			raised={hovered || isSelected}
			onMouseEnter={enter}
			onMouseLeave={leave}
			style={{
				cursor: 'pointer',
				...style,
			}}
			onClick={onSelect}>
			<div className='df-col'>
				<Radio
					style={{ padding: 0 }}
					checked={isSelected}
				/>
			</div>
			<div className='df-col fg1'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.description}</span>
				<span style={{ fontSize: MinorFontSize }}>{`${strings.code}: ${item.code}`}</span>
			</div>
		</CardX>
	);
};
