import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { IFailedEvent } from 'gen/ApiClient';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (colRecord: Record<SelectableColumn, string>): Record<SelectableColumn, IHeader<IFailedEvent>> => {
	return {
		created: createSortableHeaderWithKey<IFailedEvent, SelectableColumn>(colRecord, 'created', s => formatDate(s.created, 'date-and-time')),
		lastUpdated: createSortableHeaderWithKey<IFailedEvent, SelectableColumn>(colRecord, 'lastUpdated', s => formatDate(s.lastUpdated, 'date-and-time')),
		pos: createSortableHeaderWithKey<IFailedEvent, SelectableColumn>(colRecord, 'pos', t => `${t.pos}`),
		subscriptionKey: createSortableHeaderWithKey<IFailedEvent, SelectableColumn>(colRecord, 'subscriptionKey', t => t.subscriptionKey),
	};
};
