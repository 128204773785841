import { LocationsPreloadCacheContext } from 'app/main/settings/locations/LocationsPreloadCacheContext';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { StockItemDtoSelectableColumn } from '../StockItemDtoSelectableColumn';
import { QuickFilter } from '../StockItems';
import { createColumnRecord } from '../utils/createColumnRecord';
import { createExportRecord } from '../utils/createExportRecord';
import { createHeaders } from '../utils/createHeaders';
import { createQfRecord } from '../utils/createQfRecord';
import { ViewContext } from './ViewContext';

// enkel hier nodig
const DefaultColumns: StockItemDtoSelectableColumn[] = ['manufacturerCommaModel', 'stockValue', 'physicalQuantity', 'category', 'subcategory'];

export const ViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const [columns, setColumns] = useState<(StockItemDtoSelectableColumn | string)[]>(DefaultColumns);
	const colRecord = useMemo(() => createColumnRecord(strings, locations ?? []), [strings, locations]);
	const headers = useMemo(() => createHeaders(colRecord, strings, locations ?? []), [colRecord, strings, locations]);
	const quickFilterRecord = useMemo(() => createQfRecord(strings), [strings]);
	const exportRecord = useMemo(() => createExportRecord(strings, locations ?? []), [strings, locations]);
	const [qf, setQf] = useState<QuickFilter>('all');

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
				quickFilter: qf,
				setQuickFilter: setQf,
				quickFilterRecord: quickFilterRecord,
				exportRecord: exportRecord,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
