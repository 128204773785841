import { Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IArticlePropertyDefinition, IArticlePropertyKey, IProductCatalogItem, LanguageOption } from 'gen/ApiClient';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ArticlePropertyKeysCacheContext } from '../articlePropertyKeys/context/ArticlePropertyKeysCacheContext';
import { localizeApkLabel } from '../articlePropertyKeys/utils/localizeApkLabel';
import { ArticlePropertyDefinitionsForm } from '../forms/articlePropertyDefinitions/ArticlePropertyDefinitionsForm';
import { ArticlePropertyDefinitionComponent } from './ArticlePropertyDefinitionComponent';

interface IMapKey {
	apd: IArticlePropertyDefinition;
	label: string;
}

const toMapKeys = (item: IProductCatalogItem, apks: IArticlePropertyKey[], lang: LanguageOption): IMapKey[] => {
	const apds = item.articlePropertyDefinitions;
	if (apds === null || apds === undefined || apds.length === 0) {
		return [];
	} else {
		return apds
			.map<IMapKey | undefined>(apd => {
				const fKey = apks.find(t => t.key === apd.key);
				if (fKey === undefined) {
					return undefined;
				} else {
					return {
						apd: apd,
						label: localizeApkLabel(fKey, lang),
					};
				}
			})
			.filter(t => t !== undefined) as IMapKey[];
	}
};

interface IProps {
	item: IProductCatalogItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
	className?: string;
}

export const ArticlePropertyDefinitionsCard = ({ item, reload, style, className }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [data] = usePreloadCacheContext(ArticlePropertyKeysCacheContext);
	const language = useCurrentLanguage();
	const mapped = useMemo(() => toMapKeys(item, data, language), [item, data, language]);

	const onEdit = () => {
		open(
			<ArticlePropertyDefinitionsForm
				open
				cancel={cancel}
				confirm={confirm}
				item={item}
			/>
		);
	};

	return (
		<Card
			style={style}
			className={`df-col ${className}`}>
			<CardContent>
				<CardTitleTypography
					title={strings.fieldsToFill}
					edit={onEdit}
				/>
				{mapped.map(t => (
					<ArticlePropertyDefinitionComponent
						key={t.apd.key}
						item={t.apd}
						label={t.label}
					/>
				))}
				{mapped.length === 0 && <NoRecordsFound withPaddingTop>{strings.noFieldsDefined}</NoRecordsFound>}
			</CardContent>
		</Card>
	);
};
