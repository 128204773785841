import React from 'react';
import { SelectField } from './SelectField';

interface IProps<TValue extends string> {
	label?: string;
	value: TValue | undefined;
	onChange: (val: TValue) => void;
	record: Record<TValue, string>;
	style?: React.CSSProperties;
	className?: string;
	withNone?: boolean;
	variant?: 'standard' | 'outlined' | 'filled';
	size?: 'medium' | 'small';
}

export const SelectFieldFromRecord = <TValue extends string>({ record, ...rest }: IProps<TValue>) => {
	return (
		<SelectField<TValue>
			options={Object.keys(record) as TValue[]}
			renderValue={t => record[t]}
			getKey={t => record[t] ?? 'undefined'}
			{...rest}
		/>
	);
};
