import { useResizeObserver } from 'framework/hooks/useResizeObserver';
import { IPatient } from 'gen/ApiClient';
import React from 'react';
import { TonalAudiogramCard } from 'shared/audiograms/TonalAudiogramCard';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { AllNoahDevicesCard } from './AllNoahDevicesCard';
import { LastNoahDevicesCard } from './LastNoahDevicesCard';
import { NoahSessionsCard } from './NoahSessionsCard';

interface IProps extends IReloadTriggerProps {
	data: IPatient;
}

export const NoahOverviewTab = ({ data, reloadTrigger }: IProps) => {
	const [ref, width] = useResizeObserver();

	return (
		<div ref={ref}>
			{width < 816 && (
				<div className='df-col gap-16'>
					<TonalAudiogramCard
						patientId={data.id}
						reloadTrigger={reloadTrigger}
						style={{ width: '546px' }}
					/>
					<NoahSessionsCard
						patientId={data.id}
						reloadTrigger={reloadTrigger}
						style={{ width: '546px' }}
					/>
					<LastNoahDevicesCard
						patientId={data.id}
						reloadTrigger={reloadTrigger}
						style={{ width: '546px' }}
					/>
					<AllNoahDevicesCard
						className='h-fc'
						patientId={data.id}
						reloadTrigger={reloadTrigger}
						style={{ width: '546px' }}
					/>
				</div>
			)}
			{width >= 816 && (
				<div className='df-row-wrap gap-16'>
					<div className='df-col gap-16'>
						<TonalAudiogramCard
							patientId={data.id}
							reloadTrigger={reloadTrigger}
							style={{ width: '546px' }}
						/>
						<LastNoahDevicesCard
							patientId={data.id}
							reloadTrigger={reloadTrigger}
							style={{ width: '546px' }}
						/>
						<AllNoahDevicesCard
							className='h-fc'
							patientId={data.id}
							reloadTrigger={reloadTrigger}
							style={{ width: '546px' }}
						/>
					</div>
					<div
						className='df-col fg1'
						style={{ height: 'fit-content' }}>
						<NoahSessionsCard
							patientId={data.id}
							reloadTrigger={reloadTrigger}
							style={{ maxWidth: '500px' }}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
