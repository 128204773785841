import { Button, Card, CardMedia, Divider, Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { createType } from 'framework/utils/createType';
import {
	IProductCatalogFilterParams,
	IProductCatalogItem,
	ProductCatalogItemExportProp,
	ProductCatalogItemExportProps,
	productCatalogQuery_export,
} from 'gen/ApiClient';

import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsNavigateHeader } from 'shared/table/createDetailsNavigateHeader';
import { calcProductCatalogDetailRoute } from '../routes';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { ProductCatalogItemForm } from './forms/ProductCatalogItemForm';
import { ProductCatalogBreadcrumbs } from './ProductCatalogBreadcrumbs';
import { ProductCatalogChips } from './ProductCatalogChips';
import { SelectableColumn } from './SelectableColumn';
import { SelectableColumns } from './SelectableColumns';
import { ProductCatalogSettingsDialog } from './settings/ProductCatalogSettingsDialog';

export const QuickFilters = createType('all', 'hearingInstruments', 'intangible');
export type QuickFilter = typeof QuickFilters[number];

export const ProductCatalogList = () => {
	const strings = useLocalization();
	const theme = useTheme();
	const { params, setParams, queryResult, reload } = useQueryContext(QueryContext);
	const { columns, setColumns, columnsRecord, headers, exportRecord, quickFilter, setQuickFilter, quickFilterRecord } = useContext(ViewContext);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();
	const hasVeppClaim = useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim();
	const selectableColumns = useMemo(
		() => (hasVeppClaim ? SelectableColumns[quickFilter] : SelectableColumns[quickFilter].filter(t => t !== 'purchaseListPrice')),
		[quickFilter, hasVeppClaim]
	);
	const exportProps = useMemo(
		() => (hasVeppClaim ? ProductCatalogItemExportProps : ProductCatalogItemExportProps.filter(t => t !== 'PurchaseListPrice')),
		[hasVeppClaim]
	);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onSettings = () => {
		open(
			<ProductCatalogSettingsDialog
				open
				close={cancel}
			/>
		);
	};

	const onNew = () => {
		open(
			<ProductCatalogItemForm
				open
				cancel={cancel}
				confirm={onConfirmNew}
			/>
		);
	};

	const onConfirmNew = (id: string) => {
		push(calcProductCatalogDetailRoute(id));
		confirm();
	};

	const onToggleIncludeDeleted = () => {
		setParams({ ...params, alsoIncludeDeleted: !params.alsoIncludeDeleted, isDeleted: undefined, pageParams: { index: 1, size: params.pageParams.size } });
	};

	return (
		<RestrictedPageX
			pageClaim='ProductCatalog'
			stickyHeader={
				<div
					className='df-row-ac'
					style={{ paddingBottom: 16 }}>
					<ProductCatalogBreadcrumbs />
					<div className='fg1'></div>
					<PageXHeaderActions>
						<PageSettingsButtonX onClick={onSettings} />
						<PageReloadButtonX reload={reload} />
					</PageXHeaderActions>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
				}}>
				<div className='df-row-ac jc-sb'>
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchVerb}
						style={{ width: '400px' }}
						variant='paper'
					/>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						onClick={onNew}>
						{strings.newItem}
					</Button>
				</div>
				{queryResult.unfilteredCount > 0 && (
					<>
						<Card>
							<div
								className='df-row-ac jc-sb'
								style={{ padding: theme.spacing(1) }}>
								<ButtonGroupWithSelection<QuickFilter>
									options={QuickFilters}
									selected={quickFilter}
									setSelected={setQuickFilter}
									renderOption={t => quickFilterRecord[t]}
								/>
								<div>
									<ExportButtonMenuAndDialog<IProductCatalogFilterParams, ProductCatalogItemExportProp>
										params={params}
										filteredCount={queryResult.filteredCount}
										unfilteredCount={queryResult.unfilteredCount}
										downloadF={productCatalogQuery_export}
										fileName={strings.productCatalog}
										record={exportRecord}
										allProps={exportProps}
										view='productCatalog'
									/>
									<SelectColumnsButtonAndDialog
										selectedColumns={columns}
										setSelectedColumns={setColumns}
										record={columnsRecord}
										columns={selectableColumns}
										view='productCatalog'
									/>
								</div>
							</div>
							<Divider />
							<div className='df-row-ac jc-sb'>
								<Filter
									params={params}
									onFilter={params => setParams(onResetToPage1(params))}
									paddingLeft={theme.spacing(1.5)}
								/>
								<FormControlLabelSwitch
									isChecked={params.alsoIncludeDeleted}
									toggle={onToggleIncludeDeleted}
									label={strings.alsoShowDeleted}
								/>
							</div>
							<Divider />
							<CardMedia>
								<PageableTableWithColumnSelection<IProductCatalogItem, SelectableColumn>
									queryResult={queryResult}
									headers={headers}
									sortParams={params.sortParams}
									pageParams={params.pageParams}
									setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
									getKey={inst => inst.id}
									selectedColumns={columns}
									prependColumn={createEmptyHeader<IProductCatalogItem>('deleted', t => (
										<ProductCatalogChips
											item={t}
											short
										/>
									))}
									appendColumn={createDetailsNavigateHeader<IProductCatalogItem>(calcProductCatalogDetailRoute)}
								/>
							</CardMedia>
						</Card>
					</>
				)}
				{queryResult.unfilteredCount === 0 && (
					<Typography
						variant='body2'
						style={{ fontStyle: 'italic' }}>
						{strings.noProductsDefinedClickPlusToCreateNew}
					</Typography>
				)}
			</div>
		</RestrictedPageX>
	);
};
