import { IQuickFilterContext } from 'framework/IQuickFilterContext';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import {
	CosiDegreeOfChange,
	CosiEndResult,
	IRequestForInsuranceAllowance,
	RfiaExportProp,
	RfiaInvoiceProcessStatus,
	RfiaPaymentStatus,
	RfiaStatus,
} from 'gen/ApiClient';
import React from 'react';
import { QuickFilter } from '../RfiasListPage';
import { SelectableColumn } from '../SelectableColumn';
import { RfiaDetailTabType } from '../detail/RfiaDetailTabType';
import { HandoverLineMode } from '../forms/handover/HandoverLineMode';

interface IViewContext extends ITableViewContext<SelectableColumn, IRequestForInsuranceAllowance>, IQuickFilterContext<QuickFilter> {
	cosiDegreeOfChangeRecord: Record<CosiDegreeOfChange, string>;
	cosiEndResultRecord: Record<CosiEndResult, string>;
	statusRecord: Record<RfiaStatus, string>;
	invoiceProcessStatusRecord: Record<RfiaInvoiceProcessStatus, string>;
	paymentStatusRecord: Record<RfiaPaymentStatus, string>;
	statusOrderRecord: Record<RfiaStatus, number>;
	exportRecord: Record<RfiaExportProp, string>;
	handoverLineModeRecord: Record<HandoverLineMode, string>;
	navigationLinkRecord: Record<RfiaDetailTabType, string>;
}

export const ViewContext = React.createContext<IViewContext>(undefined as any);
