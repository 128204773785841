import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useTrigger } from 'framework/hooks/useTrigger';
import { notificationsCommand_markAsShown } from 'gen/ApiClient';
import React, { useEffect, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { NotificationDialog } from '../NotificationDialog';
import { NotShownNotificationsDialog } from '../NotShownNotificationsDialog';
import { CacheContext } from './CacheContext';
import { NotificationsContext } from './NotificationsContext';

export const NotificationsProvider = ({ children }: any) => {
	const [data, load] = usePreloadCacheContext(CacheContext);
	const [trigger, hitTrigger] = useTrigger();
	const { open, confirm } = useDialogsContext(hitTrigger);
	const countUnreadNotifications = useMemo(() => data?.filter(t => t.isRead === false).length ?? 0, [data]);
	const notShownNotifications = useMemo(() => data.filter(t => t.hasShown === false), [data]);
	const countNotShownNotifications = useMemo(() => notShownNotifications.length, [notShownNotifications]);
	const [markAsShown] = useFormSubmit(notificationsCommand_markAsShown);

	useEffect(() => {
		const notShownIds = data.filter(t => t.hasShown === false).map(t => t.id);
		if (notShownIds.length > 0) {
			open(
				<NotShownNotificationsDialog
					open
					ids={notShownIds}
					onView={onView}
					close={async () => {
						await markAsShown({ notificationIds: notShownIds });
						confirm();
					}}
				/>
			);
		}
		// eslint-disable-next-line
	}, []);

	useLazyEffect(() => {
		load();
	}, [trigger]);

	const onView = (id: string) => {
		open(
			<NotificationDialog
				open
				id={id}
				close={confirm}
			/>
		);
	};

	return (
		<NotificationsContext.Provider
			value={{
				notifications: data,
				onView: onView,
				trigger: trigger,
				countUnreadNotifications: countUnreadNotifications,
				notShownNotifications: notShownNotifications,
				countNotShownNotifications: countNotShownNotifications,
			}}>
			{children}
		</NotificationsContext.Provider>
	);
};
