import { CircularProgress, Dialog, DialogContent, DialogProps, DialogTitle, Divider, useTheme } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect } from 'react';
import step1 from './step1.png';
import step3 from './step3.png';

interface IProps extends DialogProps {
	close: VoidFunction;
	download: VoidFunction;
	isDownloading: boolean;
	title: string;
	prependChildren?: any;
}

export const DownloadAndInstallSoftwareDialog = ({ close, download, isDownloading, transitionDuration, title, prependChildren, ...rest }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();

	useEffect(() => {
		download();
		// eslint-disable-next-line
	}, []);

	return (
		<Dialog
			{...rest}
			maxWidth='xl'>
			<CloseButtonOnAbsolutePosition onClick={close} />
			<DialogTitle>{title}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'
				style={{ paddingBottom: 24 }}>
				{prependChildren && prependChildren}
				<div className='df-row'>
					<div
						className='df-col-ac'
						style={{ width: 350 }}>
						<div style={{ marginBottom: 16, fontSize: 28 }}>{`${strings.step} 1`}</div>
						<div
							className='df-col-ac jc-e fg1'
							style={{ position: 'relative' }}>
							{isDownloading && <CircularProgress style={{ width: 75, height: 75, position: 'absolute', top: 65 }} />}
							<img
								src={step1}
								alt='step1'
								style={{ width: 250, border: '1px solid lightgray' }}
							/>
							<div style={{ whiteSpace: 'pre-line', color: theme.palette.text.secondary, marginTop: 8, textAlign: 'center' }}>
								{strings.downloadIsStartedWaitUntiflFileIsVisible}
							</div>
						</div>
					</div>
					<Divider
						orientation='vertical'
						flexItem
						style={{ marginLeft: 16, marginRight: 16 }}
					/>
					<div className='df-col-ac'>
						<div style={{ marginBottom: 16, fontSize: 28 }}>{`${strings.step} 2`}</div>
						<div className='df-col-ac jc-e fg1'>
							<img
								src={step3}
								alt='step3'
								style={{ width: 250, border: '1px solid lightgray' }}
							/>
							<div style={{ whiteSpace: 'pre-line', color: theme.palette.text.secondary, marginTop: 8, textAlign: 'center' }}>
								{strings.followInstallationInstructionsOnScreenCloseScreenAfterInstallation}
							</div>
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};
