import { DialogProps } from '@material-ui/core';
import React from 'react';
import { Try } from 'framework/Try';
import { ICreateResponse, ICreateValidateResponse, IDocumentFromTemplateModel } from 'gen/ApiClient';
import { ISelectTemplateModel } from 'documents/ISelectTemplateModel';
import { SelectTemplateOrStandardDocumentFromPredefinedTypeForm } from 'documents/SelectTemplateOrStandardDocumentFromPredefinedTypeForm';

interface IProps extends DialogProps {
	confirm: (documentId: string) => void;
	cancel: VoidFunction;
	submitF: (model: ISelectTemplateModel) => Promise<Try<ICreateValidateResponse | ICreateResponse<IDocumentFromTemplateModel>>>;
	submitText?: string;
	cancelText?: string;
	title?: string;
	handoverDate: Date | undefined;
}

export const SelectRfiaHandoverCertificateTemplateForm = ({ handoverDate, ...props }: IProps) => {
	return (
		<SelectTemplateOrStandardDocumentFromPredefinedTypeForm
			{...props}
			type='RfiaHandoverCertificate'
			date={handoverDate}
		/>
	);
};
