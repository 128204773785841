import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	INoahDeviceReadOut,
	INoahDeviceReadOutsQueryParams,
	IQueryResult,
	noahDeviceReadOutsQuery_concurrencyToken,
	noahDeviceReadOutsQuery_query,
} from 'gen/ApiClient';
import { noahDeviceReadOutsQueryParamsLsKey } from '../../../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: INoahDeviceReadOutsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	lastReadOutDateAfter: undefined,
	lastReadOutDateBefore: undefined,
	statuses: [],
	types: [],
	keyFilterStatus: undefined,
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<INoahDeviceReadOut>, INoahDeviceReadOutsQueryParams>
			defaultParams={DefaultParams}
			queryF={noahDeviceReadOutsQuery_query}
			concurrencyF={noahDeviceReadOutsQuery_concurrencyToken}
			localStorageKey={noahDeviceReadOutsQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
