import { IStrings } from 'localization/IStrings';
import { ApiTokensSelectableColumn } from './ApiTokensSelectableColumn';

export const createApiTokensColumnRecord = (strings: IStrings): Record<ApiTokensSelectableColumn, string> => {
	return {
		created: strings.createdAt,
		computerName: 'Naam computer',
		clientId: 'Client Id',
		firstFourKeyChars: 'Key',
	};
};
