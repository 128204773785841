import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { companiesQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { CompanyDetailsContent } from './CompanyDetailsContent';

interface IProps extends DialogProps {
	close: VoidFunction;
	id: string;
}

export const CompanyDialog = ({ id, close, ...rest }: IProps) => {
	const strings = useLocalization();
	const [company, reload] = useApiEffect(companiesQuery_single, id);
	const { isStacked } = useDialogsContext();

	if (company === undefined) {
		return <></>;
	}

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}>
			<DialogTitle>{strings.detailsOfVatCustomer}</DialogTitle>
			<DialogContent
				dividers
				style={{ paddingBottom: 24 }}>
				<CloseButtonOnAbsolutePosition onClick={close} />
				<CompanyDetailsContent
					item={company}
					reload={reload}
				/>
			</DialogContent>
		</Dialog>
	);
};
