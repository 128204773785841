import { IDraggableItem } from 'framework/components/dnd/IDraggableItem';
import { ILocationSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { LineDescriptionQuantityAndLinePrice } from 'shared/searchAndSelect/lines/LineDescriptionQuantityAndLinePrice';
import { LinePricingLabelledProperties } from 'shared/searchAndSelect/lines/LinePricingLabelledProperties';
import { LineXComponent } from 'shared/searchAndSelect/lines/LineXComponent';
import { PriceOrDiscountForm } from 'shared/searchAndSelect/PriceOrDiscountForm';
import { calculateReservedForWarning } from 'shared/searchAndSelect/utils/calculateReservedForWarning';
import { calculateStockWarning } from 'shared/searchAndSelect/utils/calculateStockWarning';
import { ILine } from './ILine';

interface IProps extends IDraggableItem {
	index: number;
	onDelete: VoidFunction | undefined;
	line: ILine;
	setLine: (val: ILine) => void;
	location: ILocationSummary;
}

export const LoanLineComponent = ({ line, setLine, location, ...rest }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext();
	const strings = useLocalization();
	const stockWarning = useMemo(() => calculateStockWarning(line, location, strings), [line, location, strings]);
	const reservedForWarning = useMemo(() => calculateReservedForWarning(line, strings), [line, strings]);

	const onEditPrice = () => {
		open(
			<PriceOrDiscountForm
				open
				listPrice={line.unitListPrice}
				unitPrice={line.unitPrice}
				confirm={(up, lp) => {
					confirm();
					setLine({ ...line, unitPrice: up, unitListPrice: lp });
				}}
				cancel={cancel}
				forPurchase={false}
			/>
		);
	};

	return (
		<LineXComponent
			{...rest}
			line={line}
			setLine={setLine}
			warnings={[stockWarning, reservedForWarning]}
			errors={line.error}>
			<LineDescriptionQuantityAndLinePrice<ILine>
				line={line}
				setLine={setLine}
				onClickPrice={onEditPrice}
				disableQuantity={Boolean(line.inventoryItemId)}
			/>
			<div className='fg1'></div>
			<LinePricingLabelledProperties<ILine>
				line={line}
				onClick={onEditPrice}
				style={{ marginTop: 8 }}
			/>
		</LineXComponent>
	);
};
