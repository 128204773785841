import { DialogProps } from '@material-ui/core';
import React from 'react';
import { ISupplier, suppliersCommand_patchAccountNumber } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { StringModelForm } from 'framework/forms/StringModelForm';

interface IProps extends DialogProps {
	supplier: ISupplier;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const SupplierAccountNumberForm = ({ supplier, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<StringModelForm
			label={strings.accountNumber}
			formTitle={strings.changeWhat(strings.accountNumber)}
			notifyMessage={strings.supplierUpdated}
			submitFunction={val => suppliersCommand_patchAccountNumber({ supplierId: supplier.id, accountNumber: val })}
			initialValue={supplier.accountNumber ?? ''}
			submitText={strings.update}
			{...rest}
		/>
	);
};
