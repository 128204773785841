import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { SortableTable } from 'framework/table/SortableTable';
import { IProductCatalogItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { NoRecordsFound } from '../../../../framework/components/NoRecordsFound';
import { ISearchAndSelectLineWithAssurance } from '../ISearchAndSelectLineWithAssurance';
import { createHeaders } from './createHeaders';
import { filterLines } from './filterLines';

interface IProps<TLine extends ISearchAndSelectLineWithAssurance> extends DialogProps {
	assurance: IProductCatalogItem;
	confirm: (lines: TLine[]) => void;
	cancel: VoidFunction;
	lines: TLine[];
}

export const SelectLinesFromThisSaleToAssureDialog = <TLine extends ISearchAndSelectLineWithAssurance>({
	assurance,
	confirm,
	cancel,
	lines: salesLines,
	...rest
}: IProps<TLine>) => {
	const strings = useLocalization();
	const fSalesLines = useMemo(() => filterLines(assurance, salesLines), [assurance, salesLines]);
	const [lines, setLines] = useState<(TLine & { isSelected: boolean })[]>([]);
	const headers = useMemo(() => createHeaders<TLine & { isSelected: boolean }>(lines, setLines, strings), [lines, setLines, strings]);

	useEffect(() => {
		setLines(fSalesLines.map(t => ({ ...t, isSelected: false })));
	}, [fSalesLines]);

	return (
		<Dialog
			{...rest}
			PaperProps={{ style: { minWidth: 400 } }}
			maxWidth='xl'>
			<DialogTitle>{strings.selectWhat(strings.lines)}</DialogTitle>
			<DialogContent
				dividers
				style={{ padding: 0 }}>
				{lines.length > 0 && (
					<SortableTable<TLine & { isSelected: boolean }>
						padding={8}
						headers={headers}
						getKey={t => t.id}
						values={lines}
						defaultSortParams={{ property: 'description', direction: 'desc' }}
					/>
				)}
				{lines.length === 0 && <NoRecordsFound withPadding>{strings.noPreviousSoldLines}</NoRecordsFound>}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<LoaderButton
					isLoading={false}
					onClick={() => confirm(lines.filter(t => t.isSelected))}
					disabled={lines.filter(t => t.isSelected).length === 0}
					color='primary'>{`${strings.select} (${lines.filter(t => t.isSelected).length})`}</LoaderButton>
			</DialogActions>
		</Dialog>
	);
};
