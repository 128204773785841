import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { IPatientDetail } from 'gen/ApiClient';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { PatientSettingsAndViewPreferencesRoute } from '../PatientRoutes';
import { PatientStatusChip } from '../PatientStatusChip';
import { PatientsBreadcrumb } from '../PatientsBreadcrumb';
import { PatientDetailTabType, PatientDetailTabTypes } from './PatientDetailTabType';
import { PatientDetailTabTypeIconRecord } from './PatientDetailTabTypeIconRecord';
import { createPatientDetailTabTypeEndIconRecord } from './createPatientDetailTabTypeEndIconRecord';

interface IProps {
	item: IPatientDetail;
	reload: VoidFunction;
	tab: PatientDetailTabType;
	setTab: React.Dispatch<React.SetStateAction<PatientDetailTabType>>;
}

export const PatientDetailPageHeader = ({ item, reload, tab, setTab }: IProps) => {
	const { patientDetailTabTitleRecord } = useContext(RecordContext);
	const endIconRecord = useMemo(() => createPatientDetailTabTypeEndIconRecord(item), [item]);

	return (
		<div className='df-col fg1'>
			<div className='df-row-ac'>
				<PatientsBreadcrumb />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={item.patient.lastNameCommaFirstNameBracketsNickName} />
				<PatientStatusChip
					item={item.patient}
					style={{ marginLeft: 4 }}
				/>
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageSettingsButtonX route={PatientSettingsAndViewPreferencesRoute} />
					<PageReloadButtonX
						reload={reload}
						reloadEveryXSeconds={process.env.NODE_ENV !== `development` && tab === 'noahData' ? 10 : undefined}
					/>
				</PageXHeaderActions>
			</div>
			<HorizontalTabsX
				tabs={PatientDetailTabTypes}
				selectedTab={tab}
				setSelectedTab={setTab}
				startIconRecord={PatientDetailTabTypeIconRecord}
				titleRecord={patientDetailTabTitleRecord}
				endIconRecord={endIconRecord}
				style={{
					marginTop: 16,
					marginLeft: -4,
				}}
			/>
		</div>
	);
};
