import { Dialog, DialogContent, DialogProps, DialogTitle, List, ListItem, ListItemSecondaryAction, ListItemText, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { ChipWithAvatar } from 'framework/components/ChipWithAvatar';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { IRizivHearingAidDefinitionV2Dto, rizivHearingAidDefinitionsV2Query_multiple } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useApiEffect } from 'framework/hooks/useApiEffect';

interface IProps extends DialogProps {
	confirm: (item: IRizivHearingAidDefinitionV2Dto) => Promise<void>;
	cancel: VoidFunction;
	identificationCodes: number[];
}

export const SelectCodeDialog = ({ confirm, cancel, identificationCodes, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [value, setValue] = useState<IRizivHearingAidDefinitionV2Dto>();
	const [options] = useApiEffect(rizivHearingAidDefinitionsV2Query_multiple, identificationCodes);

	const onConfirm = async (t: IRizivHearingAidDefinitionV2Dto) => {
		setIsSubmitting(true);
		setValue(t);
		await confirm(t);
		setIsSubmitting(false);
	};

	if (options === undefined) {
		return <></>;
	}

	return (
		<Dialog
			{...rest}
			scroll='paper'
			fullWidth>
			<DialogTitle>
				<div>{strings.selectWhat(strings.supplier)}</div>
			</DialogTitle>
			<DialogContent
				dividers
				style={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}>
				<List dense>
					{options.map(t => (
						<ListItem
							key={t.identificationCode.toString()}
							disableGutters>
							<ListItemText
								primary={
									<div className='df-row-ac'>
										<div style={{ marginRight: theme.spacing(1) }}>
											<b>{t.identificationCode.toString()}</b> {t.manufacturer} {t.name}
										</div>
										<ChipWithAvatar label={t.distributor ?? ''} />
									</div>
								}
							/>
							<ListItemSecondaryAction>
								<LoaderButton
									variant='text'
									size='small'
									color='primary'
									disabled={isSubmitting}
									isLoading={isSubmitting && value === t}
									onClick={() => onConfirm(t)}>
									{strings.select}
								</LoaderButton>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				onSubmit={() => {}}
				isValid={false}
				submitText={strings.select}
				isSubmitting={isSubmitting}
				variantSubmitButton='outlined'
			/>
		</Dialog>
	);
};
