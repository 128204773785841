import { FunctionalClaims, IUserDto } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const functionalClaimsToSummary = (user: IUserDto | undefined, strings: IStrings) => {
	if (user === undefined || user.functionalClaims === undefined || user.functionalClaims === null) {
		return '';
	} else {
		return user.functionalClaims.length === FunctionalClaims.length ? strings.noWhat(strings.restrictions) : strings.someFunctionsAreNotAccessible;
	}
};
