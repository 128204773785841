import { DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setLineErrors } from 'framework/forms/utils/setLineErrors';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { mapWithIndexFilterIsSelected } from 'framework/utils/array/mapWithIndexFilterIsSelected';
import { IRegisterRfiaTrialRequest, IRequestForInsuranceAllowance, rfiasQuery_settings, rfiaTrialsCommand_start, TrialTimeUnits } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { useCurrentLocation } from '../../../../settings/locations/useCurrentLocation';
import { SearchProvider } from './context/SearchProvider';
import { ILine } from './ILine';
import { SearchAndSelectProductsForTrial } from './SearchAndSelectProductsForTrial';
import { lineToModel } from './utils/lineToModel';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterRfiaTrialRequest>({
			lines: yup.mixed(),
			stopDate: yup.date().required(strings.formRequired(strings.stopDate)),
			startDate: yup.date().required(strings.formRequired(strings.startDate)),
			rfiaId: yup.string().required(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IRegisterRfiaTrialRequest)[]> = {
	0: ['stopDate', 'startDate'],
	1: ['lines'],
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	rfia: IRequestForInsuranceAllowance;
}

export const RegisterRfiaTrialForm = ({ confirm, cancel, rfia, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(rfiaTrialsCommand_start);
	const [step, setStep] = useState<number>(0);
	const [lines, setLines] = useState<ILine[]>([]);
	const now = useNow();

	const handleSubmit = async (values: IRegisterRfiaTrialRequest, helpers: FormikHelpers<IRegisterRfiaTrialRequest>) => {
		// TODO meer controleren! -> max. value of stockItems will be max value available at location!
		if (lines.filter(t => t.isSelected).length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await create({ ...values, lines: mapWithIndexFilterIsSelected(lines).map(lineToModel) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify(strings.registerWhat(strings.loan));
				confirm();
			} else {
				setLineErrors('lines', r, lines, setLines);
			}
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterRfiaTrialRequest>
			validateOnMount
			initialValues={{
				stopDate: undefined as any,
				startDate: now,
				lines: [],
				rfiaId: rfia.id,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider>
					<InnerDialog
						step={step}
						setStep={setStep}
						lines={lines}
						setLines={setLines}
						cancel={cancel}
						isSubmitting={isSubmitting}
						rfia={rfia}
						{...rest}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	rfia: IRequestForInsuranceAllowance;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	lines: ILine[];
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>;
	isSubmitting: boolean;
	cancel: VoidFunction;
}

const InnerDialog = ({ rfia, step, lines, setLines, setStep, isSubmitting, cancel, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IRegisterRfiaTrialRequest>();
	const strings = useLocalization();
	const location = useCurrentLocation();
	const [settings] = useApiEffect(rfiasQuery_settings);

	if (location === undefined || settings === undefined) {
		return <div></div>;
	}

	return (
		<FullScreenDialogWithStepper
			{...rest}
			title={strings.startNewTrial}
			step={step}
			labels={[strings.general, strings.items]}
			fullScreenStep={1}
			maxWidth='lg'>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormDatePicker<IRegisterRfiaTrialRequest>
							name='startDate'
							label={strings.startDate}
							disableFuture
							helperText={`${strings.dateStartLoan} (${strings.onlyInPast})`}
						/>
						<FormDatePickerWithUnit<IRegisterRfiaTrialRequest>
							name='stopDate'
							label={strings.endDate}
							disablePast
							helperText={`${strings.expectedEndDate} (${strings.onlyInFuture})`}
							units={TrialTimeUnits}
							defaultUnit='Weeks'
							fromDate={props.values.startDate}
							defaultValue={settings.defaultTrialDuration}
						/>
					</>
				)}
				{step === 1 && (
					<SearchAndSelectProductsForTrial
						lines={lines}
						setLines={setLines}
						location={location}
						patientId={rfia.patientId}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</FullScreenDialogWithStepper>
	);
};
