import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { IOption } from 'framework/IOption';
import { isFiltered } from 'framework/utils/isFiltered';
import { INoahDeviceReadOutsQueryParams, NoahDeviceReadOutStatuses, NoahDeviceTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';
import { ViewContext } from './context/ViewContext';

interface IProps {
	params: INoahDeviceReadOutsQueryParams;
	onFilter: (params: INoahDeviceReadOutsQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof INoahDeviceReadOutsQueryParams)[] = ['keyFilterStatus'];

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const { noahDeviceTypeRecord } = useContext(RecordContext);
	const { statusRecord, quickFilter } = useContext(ViewContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			{quickFilter === 'all' && (
				<ListFilterComponent<string>
					options={NoahDeviceReadOutStatuses.map<IOption<string>>(t => ({ id: t, identifier: statusRecord[t] }))}
					selected={params.statuses}
					setSelected={vals => onFilter({ ...params, statuses: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.status}
				/>
			)}
			<ListFilterComponent<string>
				options={NoahDeviceTypes.map<IOption<string>>(t => ({ id: t, identifier: noahDeviceTypeRecord[t] }))}
				selected={params.types}
				setSelected={vals => onFilter({ ...params, types: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.type}
			/>
			<DateFilterItem
				label={strings.lastReadOutDate}
				after={params.lastReadOutDateAfter}
				before={params.lastReadOutDateBefore}
				setSelected={(after, before) => onFilter({ ...params, lastReadOutDateAfter: after, lastReadOutDateBefore: before })}
			/>
		</FilterBar>
	);
};
