import { useTheme } from '@material-ui/core';
import React, { useContext } from 'react';
import { createHeader } from 'framework/table/createHeader';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IEquipmentDeliveryLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { createEditHeader } from 'shared/table/createEditHeader';
import { RizivRefundableHearingInstrumentChip } from 'shared/rizivRefundableHearingInstruments/RizivRefundableHearingInstrumentChip';
import { calculateDiscountPercentage } from '../../../../financial/sales/forms/utils/calculateDiscountPercentage';
import { ViewContext } from '../../context/ViewContext';
import { lineToHandoverLineMode } from '../../utils/lineToHandoverLineMode';

interface IProps {
	lines: IEquipmentDeliveryLine[];
	onEdit: (line: IEquipmentDeliveryLine) => void;
}

export const EquipmentDeliveryLinesTable = ({ lines, onEdit }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { handoverLineModeRecord } = useContext(ViewContext);

	const headers = [
		createHeader<IEquipmentDeliveryLine>('#', t => t.indexNumber.toString()),
		createHeader<IEquipmentDeliveryLine>(strings.type, t => handoverLineModeRecord[lineToHandoverLineMode(t)]),
		createHeader<IEquipmentDeliveryLine>(strings.description, t => t.description),
		createHeader<IEquipmentDeliveryLine>(`${strings.brand}, ${strings.model}`, t => t.manufacturerCommaModel),
		createHeader<IEquipmentDeliveryLine>(strings.code, t => <RizivRefundableHeader line={t} />),
		createHeader<IEquipmentDeliveryLine>(strings.quantity, t => t.quantity.toString()),
		createHeader<IEquipmentDeliveryLine>(`${strings.listPrice}\n${strings.inVatShort}`, t => formatCurrency(t.unitListPriceInVat), 'right'),
		createHeader<IEquipmentDeliveryLine>(strings.discount, t => `${calculateDiscountPercentage(t.unitListPriceInVat, t.unitPriceInVat).toFixed(2)} %`, 'right'),
		createHeader<IEquipmentDeliveryLine>(`${strings.salesPrice}\n${strings.inVatShort}`, t => formatCurrency(t.unitPriceInVat), 'right'),
		createEditHeader<IEquipmentDeliveryLine>(t => onEdit(t)),
	];

	return (
		<TableWithHeadersAndValues<IEquipmentDeliveryLine>
			values={lines}
			headers={headers}
			getKey={t => t.id}
			padding={theme.spacing(0.5)}
			withPaperContainer
		/>
	);
};

const RizivRefundableHeader = ({ line }: { line: IEquipmentDeliveryLine }) => {
	const strings = useLocalization();
	return (
		<div className='df-row-ac'>
			{line.rizivRefundableHearingInstrumentCode !== 'n/a' &&
			line.rizivRefundableHearingInstrumentCode !== '' &&
			line.rizivRefundableHearingInstrumentCode !== undefined ? (
				<RizivRefundableHearingInstrumentChip
					identificationCode={line.rizivRefundableHearingInstrumentCode}
					reload={() => {}}
					noMargin
				/>
			) : (
				<div>
					<em>{strings.notApplicableAbbreviation}</em>
				</div>
			)}
		</div>
	);
};
