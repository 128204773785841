import { CardProps, Divider } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { IOption } from 'framework/IOption';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { MoneyRangeFilterComponent } from 'framework/filter/MoneyRangeFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { CreditedStatus, CreditedStatuses, IPurchasesQueryMetadata, IPurchasesQueryParams, PaymentStatuses, SalesTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { SuppliersCacheContext } from '../../../contacts/suppliers/SuppliersCacheContext';
import { PurchasesListPrefilter } from './PurchasesListPrefilter';
import { PurchasesFilterDataContext } from '../PurchasesFilterDataContext';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { useCacheContext } from 'framework/hooks/useCacheContext';

interface IProps extends CardProps {
	params: IPurchasesQueryParams;
	setParams: (params: IPurchasesQueryParams) => void;
	metadata: IPurchasesQueryMetadata;
}

const exclusions: (keyof IPurchasesQueryParams)[] = [];

export const PurchasesListFilterCard = ({ params, setParams, metadata, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const { paymentStatusRecord, saleTypeRecord, creditedStatusRecord } = useContext(RecordContext);
	const [suppliers] = usePreloadCacheContext(SuppliersCacheContext);
	const isFilteredX = useMemo(() => isFiltered(params, exclusions), [params]);
	const onFilter = (pars: IPurchasesQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params, exclusions)));
	const [filterData] = useCacheContext(PurchasesFilterDataContext);

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<PurchasesListPrefilter
				selectedPrefilter={params.prefilter}
				setSelectedPrefilter={key => onFilter({ ...params, prefilter: key })}
				metadata={metadata}
			/>
			<Divider />
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<DateFilterItem
					label={strings.dateCreated}
					after={params.createdAfter}
					before={params.createdBefore}
					setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
				/>
				<DateFilterItem
					label={strings.toPayBeforeDate}
					after={params.validUntilAfter}
					before={params.validUntilBefore}
					setSelected={(after, before) => onFilter({ ...params, validUntilAfter: after, validUntilBefore: before })}
				/>
				<DateFilterItem
					label={strings.referenceDate}
					after={params.purchaseReferenceDateAfter}
					before={params.purchaseReferenceDateBefore}
					setSelected={(after, before) => onFilter({ ...params, purchaseReferenceDateAfter: after, purchaseReferenceDateBefore: before })}
				/>
				<ListFilterComponent<string>
					options={PaymentStatuses.map<IOption<string>>(t => ({ id: t, identifier: paymentStatusRecord[t] }))}
					selected={params.paymentStatuses}
					setSelected={vals => onFilter({ ...params, paymentStatuses: vals })}
					icon={<HelpOutlineIcon />}
					label='Betaal status'
				/>
				<ListFilterComponent<string>
					options={SalesTypes.map<IOption<string>>(t => ({ id: t, identifier: saleTypeRecord[t] }))}
					selected={params.types}
					setSelected={vals => onFilter({ ...params, types: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.type}
				/>
				<RecordListFilterComponent<CreditedStatus>
					record={creditedStatusRecord}
					options={CreditedStatuses}
					selected={params.creditedStatuses as any}
					setSelected={vals => onFilter({ ...params, creditedStatuses: vals })}
					icon={<HelpOutlineIcon />}
					label='Krediet status'
				/>
				<MoneyRangeFilterComponent
					label='Prijs'
					selected={{ min: params.priceExVatMoreThan, max: params.priceExVatLessThan }}
					setSelected={val => onFilter({ ...params, priceExVatLessThan: val.max, priceExVatMoreThan: val.min })}
					icon={<HelpOutlineIcon />}
				/>
				{suppliers && (
					<ListFilterComponent<string>
						label={strings.suppliers}
						selected={params.supplierIds}
						options={suppliers}
						setSelected={value => onFilter({ ...params, supplierIds: value })}
						icon={<HelpOutlineIcon />}
					/>
				)}
				{filterData && filterData.labels && (
					<StringListFilterComponent
						options={filterData.labels}
						selected={params.labels}
						setSelected={vals => onFilter({ ...params, labels: vals })}
						label={strings.labels}
						icon={<HelpOutlineIcon />}
					/>
				)}
			</div>
		</CardX>
	);
};
