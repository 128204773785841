import { agendaQueryParamsLsKey } from 'app/localStorageKeys';
import { endOfMonth, startOfMonth } from 'date-fns';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { nlBELocaleEndOfWeek } from 'framework/utils/date/nlBELocaleEndOfWeek';
import { nlBELocaleStartOfWeek } from 'framework/utils/date/nlBELocaleStartOfWeek';
import { agendaQuery_concurrencyToken, agendaQuery_query, IAgendaQueryParams, IAgendaResponse } from 'gen/ApiClient';
import React from 'react';
import { getNow } from 'shared/utils/getNow';
import { AgendaQueryContext } from './AgendaQueryContext';

const DefaultParams: IAgendaQueryParams = {
	afterDate: nlBELocaleStartOfWeek(startOfMonth(getNow())),
	beforeDate: nlBELocaleEndOfWeek(endOfMonth(getNow())),
};

export const AgendaQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IAgendaResponse, IAgendaQueryParams>
			defaultParams={DefaultParams}
			queryF={agendaQuery_query}
			concurrencyF={agendaQuery_concurrencyToken}
			localStorageKey={agendaQueryParamsLsKey}
			context={AgendaQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
