import { IconButton, InputAdornment } from '@material-ui/core';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import { SearchInventoryItemForProductCatalogDialog } from 'app/main/inventoryManagement/inventoryItems/search/SearchInventoryItemForProductCatalogDialog';
import { IDraggableItem } from 'framework/components/dnd/IDraggableItem';
import { InfoLabelledProperty } from 'framework/components/labelledProperty/InfoLabelledProperty';
import { LocalStateTextField } from 'framework/components/textFields/LocalStateTextField';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { IInventoryItem, inventoryItemsQuery_queryForPurchaseOrCreditPurchase } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DefaultInventoryItemsQueryParamsForPurchaseOrCreditPurchase } from 'shared/searchAndSelect/DefaultInventoryItemsQueryParamsForPurchase';
import { LineDescriptionQuantityAndLinePrice } from 'shared/searchAndSelect/lines/LineDescriptionQuantityAndLinePrice';
import { LinePricingLabelledProperties } from 'shared/searchAndSelect/lines/LinePricingLabelledProperties';
import { LineXComponent } from 'shared/searchAndSelect/lines/LineXComponent';
import { PriceOrDiscountForm } from 'shared/searchAndSelect/PriceOrDiscountForm';
import { AssuranceLineLinkComponent } from './AssuranceLineLinkComponent';
// import { AssuranceLineLinkComponent } from "./AssuranceLineLinkComponent";
import { IPurchaseFormLine } from './IPurchaseFormLine';
import { inventoryItemToLine } from './inventoryItemToLine';

interface IProps extends IDraggableItem {
	line: IPurchaseFormLine;
	index: number;
	setLine: (val: IPurchaseFormLine) => void;
	onDelete: VoidFunction;
	lines: IPurchaseFormLine[];
	onLinkAssurance: VoidFunction;
}

export const PurchaseLineComponent = ({ line, setLine, lines, onLinkAssurance, ...rest }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext();
	const strings = useLocalization();
	const mustFillInSerialNumber = useMemo(
		() => Boolean(line.productCatalogItem && line.productCatalogItem.hasSerialNumber && line.inventoryItemId === undefined),
		[line]
	);
	const disableQuantity = useMemo(
		() => Boolean(line.inventoryItemId || line.productCatalogItem?.isUniquelyIdentifiable || line.productCatalogItem?.isInsuranceOrProlongedWarranty),
		[line]
	);

	const onEditPrice = () => {
		open(
			<PriceOrDiscountForm
				open
				listPrice={line.unitListPrice}
				unitPrice={line.unitPrice}
				confirm={(up, lp) => {
					confirm();
					setLine({ ...line, unitPrice: up, unitListPrice: lp });
				}}
				cancel={cancel}
				forPurchase={true}
			/>
		);
	};

	const onLinkInventoryItem = () => {
		open(
			<SearchInventoryItemForProductCatalogDialog
				confirm={item => {
					confirm();
					onAdd(line, item);
				}}
				cancel={cancel}
				canSelectF={t => t.canSelectForPurchase}
				defaultParams={DefaultInventoryItemsQueryParamsForPurchaseOrCreditPurchase}
				queryF={inventoryItemsQuery_queryForPurchaseOrCreditPurchase}
				item={line.productCatalogItem!}
				selectedIds={lines.filter(t => t.inventoryItemId !== undefined).map(t => t.inventoryItemId) as string[]}
			/>
		);
	};

	const onAdd = (line: IPurchaseFormLine, item: IInventoryItem) => {
		if (line.productCatalogItem) {
			setLine(inventoryItemToLine(item, line.productCatalogItem, false));
		}
	};

	return (
		<LineXComponent
			{...rest}
			errors={line.error}
			line={line}
			setLine={setLine}>
			<LineDescriptionQuantityAndLinePrice<IPurchaseFormLine>
				line={line}
				setLine={setLine}
				onClickPrice={onEditPrice}
				disableQuantity={disableQuantity}
				mode={line.isCreditLine ? 'negative' : 'positive'}
			/>
			<div className='fg1'>
				{mustFillInSerialNumber && (
					<LocalStateTextField
						variant='outlined'
						size='small'
						label={strings.serialNumber}
						value={line.serialNumber}
						style={{ marginTop: 8, width: 'fit-content' }}
						onChange={e =>
							setLine({ ...line, description: `${line.productCatalogItem!.manufacturerCommaModel} sn: ${e.target.value}`, serialNumber: e.target.value })
						}
						error={isNullOrUndefined(line.serialNumber)}
						helperText={isNullOrUndefined(line.serialNumber) ? strings.enterSerialNumber : undefined}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<TooltipWithSpan title={strings.searchASerialNumberFromNoahReadOuts}>
										<IconButton
											color='primary'
											onClick={onLinkInventoryItem}
											size='small'
											edge='end'>
											<YoutubeSearchedForIcon fontSize='small' />
										</IconButton>
									</TooltipWithSpan>
								</InputAdornment>
							),
						}}
					/>
				)}
				{line.productCatalogItem && line.productCatalogItem.isInsuranceOrProlongedWarranty && (
					<AssuranceLineLinkComponent
						line={line}
						lines={lines}
						onLink={onLinkAssurance}
					/>
				)}
				{line.isCreditLine && <InfoLabelledProperty info={strings.creditVerb} />}
			</div>
			<LinePricingLabelledProperties<IPurchaseFormLine>
				line={line}
				onClick={onEditPrice}
				style={{ marginTop: 8 }}
			/>
		</LineXComponent>
	);
};
