import { AxiosResponse } from 'axios';

export const fileResponseInterceptor = (response: AxiosResponse): AxiosResponse => {
	console.log('enters interceptor');
	const cd = response.headers['content-disposition'];
	const splitted = cd.split(';');
	const filename = splitted[1].split('filename=').pop();
	const url = window.URL.createObjectURL(new Blob([response.data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', filename);
	document.body.appendChild(link);
	link.click();
	return response;
};
