import { CardProps, Divider, Typography, useTheme } from '@material-ui/core';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { calcCustomerAccountDetailRoute, calcSalesDetailRoute } from 'app/main/financial/routes';
import { calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { formatDate } from 'framework/utils/date/formatDate';
import {
	IExactOnlineCustomerAccountDto,
	IExactOnlineProductCatalogItemDto,
	IExactOnlineSaleDetails,
	exactOnlineSalesCommand_ignore,
	exactOnlineSalesCommand_revertIgnore,
	exactOnlineSalesCommand_syncOrPush,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { LinkOrPushExactOnlineCustomerAccountDialog } from '../customerAccounts/selectCrmAccount/LinkOrPushExactOnlineCustomerAccountDialog';
import { LinkExactOnlineProductCatalogItemWithExactOnlineGLAccountDialog } from '../productCatalog/link/LinkExactOnlineProductCatalogItemWithExactOnlineGLAccountDialog';
import { ExactOnlineSaleListItemChipX } from './ExactOnlineSaleListItemChipX';
import { exactOnlineSaleToColorX } from './exactOnlineSaleToColorX';

interface IProps extends CardProps, IReloadTriggerProps {
	item: IExactOnlineSaleDetails;
	reload: VoidFunction;
}

export const ExactOnlineSaleDetailsCardX = ({ item, reload, reloadTrigger, ...rest }: IProps) => {
	const strings = useLocalization();
	const color = React.useMemo(() => exactOnlineSaleToColorX(item.sale), [item]);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();
	const theme = useTheme();

	const onIgnore = () => {
		open(
			<AlertDialog
				open
				title={strings.ignoreSale}
				content={strings.doYouWantToIgnoreSaleReferenceQuestion(item.sale.reference!)}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactOnlineSalesCommand_ignore({ hamsSalesId: item.sale.hamsSalesId })}
				accept={confirm}
			/>
		);
	};

	const onRevertIgnore = () => {
		open(
			<AlertDialog
				open
				title={strings.revertIgnoreSale}
				content={strings.doYouWantToRevertIgnoreSaleReferenceQuestion(item.sale.reference!)}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactOnlineSalesCommand_revertIgnore({ hamsSalesId: item.sale.hamsSalesId })}
				accept={confirm}
			/>
		);
	};

	const onLinkCustomerAccount = (t: IExactOnlineCustomerAccountDto) => {
		open(
			<LinkOrPushExactOnlineCustomerAccountDialog
				item={t}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onLinkGLAccount = (t: IExactOnlineProductCatalogItemDto) => {
		open(
			<LinkExactOnlineProductCatalogItemWithExactOnlineGLAccountDialog
				open
				item={t}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onPushOrSync = () => {
		open(
			<AlertDialog
				open
				title={strings.createSlashSyncSaleInExactOnline}
				content={strings.doYouWantToCreateSlashSyncSaleInExactOnlineQuestion}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactOnlineSalesCommand_syncOrPush({ hamsId: item.sale.hamsSalesId })}
				accept={confirm}
			/>
		);
	};

	return (
		<CardX {...rest}>
			<div
				className='df-row-ac gap-8'
				style={{ marginBottom: 16 }}>
				<Typography
					component='div'
					style={{ fontSize: '16px', fontWeight: 500 }}>
					{item.sale.reference}
				</Typography>
				<ExactOnlineSaleListItemChipX
					item={item.sale}
					color={color}
				/>
			</div>
			<div className='df-col gap-8'>
				<CaptionVerticalLabelledPropertyX
					label={strings.reference}
					link={() => push(calcSalesDetailRoute(item.sale.hamsSalesId))}>
					<div className='df-row-ac gap-4'>
						<span>{item.sale.reference}</span>
						<StatusIcon
							fontSize='small'
							status={item.validation.referenceIsANumber ? 'success' : 'error'}
						/>
					</div>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.date}>{formatDate(item.sale.created)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.accountsReceivable}>
					<div className='df-col gap-4'>
						{item.customerAccounts.map(t => (
							<div
								className='df-row-ac gap-4'
								key={t.hamsId}>
								<TextLinkButton
									underline
									onClick={() => push(calcCustomerAccountDetailRoute(t.hamsId))}>
									{t.name}
								</TextLinkButton>
								<StatusIcon
									fontSize='small'
									status={t.isLinked ? 'success' : 'error'}
								/>
								{t.isLinked === false && <TextLinkButton onClick={() => onLinkCustomerAccount(t)}>{strings.linkWithAnExactOnlineCRMAccount}</TextLinkButton>}
							</div>
						))}
					</div>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.lines}>
					<div className='df-col gap-4'>
						{item.lines.map(t => (
							<div
								className='df-row-ac gap-4'
								key={t.id}>
								<TextLinkButton
									underline
									disabled={t.productCatalogItemId === null || t.productCatalogItemId === undefined}
									onClick={() => push(calcProductCatalogDetailRoute(t.productCatalogItemId!))}>
									{t.description}
								</TextLinkButton>
								<StatusIcon
									fontSize='small'
									status={t.mustLink ? 'error' : 'success'}
								/>
								{t.mustLink && <TextLinkButton onClick={() => onLinkGLAccount(t.exactOnlineProductCatalogItem)}>{strings.linkGLAccount}</TextLinkButton>}
							</div>
						))}
					</div>
				</CaptionVerticalLabelledPropertyX>
				<Divider />
				<CaptionVerticalLabelledPropertyX label={strings.validation}>
					<div className='df-col gap-4'>
						{item.validation.isValid && <span style={{ color: theme.palette.success.main }}>{strings.everythingSeemsAllright}</span>}
						{item.validation.isCashSale && <span style={{ color: theme.palette.error.main }}>{strings.saleIsACashSale}</span>}
						{item.validation.hasMultipleCustomerAccounts && <span style={{ color: theme.palette.error.main }}>{strings.saleHasMultipleCustomerAccounts}</span>}
						{item.validation.hasNoCustomerCustomerAccount && <span style={{ color: theme.palette.error.main }}>{strings.saleHasNoCustomerAccounts}</span>}
						{item.validation.referenceIsANumber === false && <span style={{ color: theme.palette.error.main }}>{strings.referenceIsNotANumber}</span>}
						{item.validation.hasAnyProductsWithoutGLAccountCode && (
							<span style={{ color: theme.palette.error.main }}>{strings.oneOrMoreArticlesHasNoGLAccountLinked}</span>
						)}
						{item.validation.hasMissingFallbackGLAccounts && (
							<span style={{ color: theme.palette.error.main }}>{strings.notAllFallbackGLAccountsAreDefined}</span>
						)}
						{item.validation.hasUnlinkedCustomerAccounts && (
							<span style={{ color: theme.palette.error.main }}>{strings.customerAccountIsNotLinkedInExactOnline}</span>
						)}
					</div>
				</CaptionVerticalLabelledPropertyX>
				<div className='fg1'></div>
				<Divider style={{ marginBottom: 4 }} />
				<div className='df-col gap-8'>
					<TextLinkButton
						startIcon={<VisibilityOutlinedIcon />}
						onClick={() => push(calcSalesDetailRoute(item.sale.hamsSalesId))}>
						{strings.viewSaleInHAMS}
					</TextLinkButton>
					<TextLinkButton
						startIcon={<BackupOutlinedIcon />}
						onClick={onPushOrSync}
						disabled={item.sale.isSynced}>
						{strings.pushSlashSyncWithExactOnline}
					</TextLinkButton>
					{item.sale.isIgnored === false && (
						<TextLinkButton
							startIcon={<DeleteOutlineOutlinedIcon />}
							hoverColor='secondary'
							onClick={onIgnore}>
							{strings.ignoreSaleImperative}
						</TextLinkButton>
					)}
					{item.sale.isIgnored === true && (
						<TextLinkButton
							startIcon={<SettingsBackupRestoreOutlinedIcon />}
							color='secondary'
							hoverColor='secondary'
							onClick={onRevertIgnore}>
							{strings.revertIgnoreSale}
						</TextLinkButton>
					)}
				</div>
			</div>
		</CardX>
	);
};
