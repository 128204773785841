import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	exactOnlineSalesQuery_concurrencyToken,
	exactOnlineSalesQuery_query,
	IExactOnlineSale,
	IExactOnlineSalesQueryParams,
	IQueryResult,
} from 'gen/ApiClient';
import React from 'react';
import { ExactOnlineSalesQueryParamsLsKey } from '../../../../localStorageKeys';
import { ExactOnlineSalesQueryContext } from './ExactOnlineSalesQueryContext';

const DefaultParams: IExactOnlineSalesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	prefilter: 'NotIgnoredNorSynced',
};

export const ExactOnlineSalesQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IExactOnlineSale>, IExactOnlineSalesQueryParams>
			defaultParams={DefaultParams}
			queryF={exactOnlineSalesQuery_query}
			concurrencyF={exactOnlineSalesQuery_concurrencyToken}
			localStorageKey={ExactOnlineSalesQueryParamsLsKey}
			context={ExactOnlineSalesQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
