import { RfiaStatus } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createStatusOrderRecord = (strings: IStrings): Record<RfiaStatus, number> => {
	return {
		Created: 1,
		PrescriptionForTrialReceived: 2,
		TrialRunning: 3,
		TrialEnded: 4,
		DocumentsSentToEntPhysician: 5,
		MedicalPrescriptionReceived: 6,
		DocumentsSentToAdvisoryPhysician: 7,
		ApprovedByAdvisoryPhysician: 8,
		HandedOver: 9,
		HandedOverAndPaid: 10,
		HandedOverBeforeApprovals: 11,
		HandedOverAndPaidBeforeApprovals: 12,
		Cancelled: 13,
		FailedTrial: 14,
	};
};
