import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormPasswordField } from 'framework/forms/FormPasswordField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IUserDto, usersCommand_patchPassword } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

interface IModel {
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			oldPassword: yup.string().required(strings.formRequired(strings.oldPassword)),
			newPassword: yup.string().required(strings.formRequired(strings.newPassword)),
			confirmPassword: yup
				.string()
				.required(strings.formRequired(strings.confirmNewPassword))
				.oneOf([yup.ref('newPassword')], strings.passwordDoesNotMatch),
		})
		.defined();
};

interface IProps extends DialogProps {
	user: IUserDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const ChangePasswordForm = ({ user, confirm, cancel, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [change, isSubmitting] = useFormSubmit(usersCommand_patchPassword);
	const strings = useLocalization();

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await change({ userId: user.id, oldPassword: values.oldPassword, newPassword: values.newPassword });
		if (handleFormResponse(r, helpers)) {
			notify(strings.passwordChanged);
			confirm();
		}
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{
				oldPassword: '',
				newPassword: '',
				confirmPassword: '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					maxWidth='xs'
					{...rest}>
					<DialogTitle>{strings.changePassword}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormPasswordField<IModel>
							name='oldPassword'
							label={strings.oldPassword}
							required
						/>
						<FormPasswordField<IModel>
							name='newPassword'
							label={strings.newPassword}
							required
						/>
						<FormPasswordField<IModel>
							name='confirmPassword'
							label={strings.confirmNewPassword}
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.changePassword}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
