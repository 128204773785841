import { Checkbox } from '@material-ui/core';
import React from 'react';
import { toggleSelectAll } from '../utils/array/toggleSelectAll';
import { updateArray } from '../utils/array/updateArray';
import { IHeader } from './IHeader';
import { ISelectionHeaderProps } from './ISelectionHeaderProps';

export const createSelectionHeader = <T extends { isSelected: boolean }>(
	lines: T[],
	setLines: (val: T[]) => void,
	disabledF?: (val: T) => boolean
): IHeader<T> => {
	const selectionHeaderProps: ISelectionHeaderProps = {
		count: lines.length,
		selected: lines.filter(t => t.isSelected).length,
		onToggle: () => setLines(toggleSelectAll(lines, disabledF)),
	};
	return {
		text: '',
		key: 'selection-header',
		evaluate: t => (
			<CheckboxX<T>
				t={t}
				lines={lines}
				setLines={setLines}
				disabled={disabledF ? disabledF(t) : undefined}
			/>
		),
		selectionHeaderProps: selectionHeaderProps,
		onlyShowOnHover: false,
		cellStyle: { paddingTop: 0, paddingBottom: 0 },
	};
};

interface ICheckboxXProps<T> {
	t: T;
	lines: T[];
	setLines: (val: T[]) => void;
	disabled?: boolean;
}

const CheckboxX = <T extends { isSelected: boolean }>({ t, lines, setLines, disabled }: ICheckboxXProps<T>) => {
	return (
		<Checkbox
			checked={t.isSelected}
			disabled={disabled}
			onClick={() => setLines(updateArray(t, lines, { ...t, isSelected: !t.isSelected }))}
		/>
	);
};
