import { CalculatorIcon } from 'framework/components/icons/CalculatorIcon';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IQueryResult, IStockEntryDto, IStockItemDto, IStockItemDtosQueryParams, stockEntriesCommand_forceReculculation } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { StockItems } from './StockItems';

interface IProps {
	item: IStockEntryDto;
	reload: VoidFunction;
	params: IStockItemDtosQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IStockItemDtosQueryParams>>;
	queryResult: IQueryResult<IStockItemDto> | undefined;
}

export const StockItemsOrDirtyCheck = ({ item, reload, params, setParams, queryResult }: IProps) => {
	const strings = useLocalization();
	const [recalc, isRecalculating] = useFormSubmit(stockEntriesCommand_forceReculculation);
	const notify = useSnackbarNotify();

	const onRecalculateStock = async () => {
		const r = await recalc();
		if (r.result.hasError) {
			notify(r.result.error);
		} else {
			reload();
		}
	};

	return (
		<div
			className='df-col gap-16 fg1'
			style={{ padding: 16 }}>
			{item.isDirty === true && (
				<ErrorLabelledProperty
					error={strings.stockInfoNotUpToDateClickToRecalculate}
					edit={isRecalculating ? undefined : onRecalculateStock}
					editIcon={<CalculatorIcon />}
					style={{ marginLeft: 4 }}
				/>
			)}
			{item.isDirty === false && (
				<StockItems
					stockEntryId={item.id}
					params={params}
					queryResult={queryResult}
					setParams={setParams}
				/>
			)}
		</div>
	);
};
