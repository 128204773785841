import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ICustomerAccountLineQueryMetadata, IUnhandledCustomerAccountLinesQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	metadata: ICustomerAccountLineQueryMetadata;
	params: IUnhandledCustomerAccountLinesQueryParams;
	onFilter: (params: IUnhandledCustomerAccountLinesQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof IUnhandledCustomerAccountLinesQueryParams)[] = [];

export const Filter = ({ metadata, params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.salesDate}
				after={params.salesDateCreatedAfter}
				before={params.salesDateBefore}
				setSelected={(after, before) => onFilter({ ...params, salesDateCreatedAfter: after, salesDateBefore: before })}
			/>
			{metadata.customerAccounts.length > 0 && (
				<ListFilterComponent<string>
					options={metadata.customerAccounts}
					selected={params.customerAccountIds}
					setSelected={vals => onFilter({ ...params, customerAccountIds: vals })}
					label={strings.healthInsuranceFund}
					icon={<HelpOutlineIcon />}
				/>
			)}
		</FilterBar>
	);
};
