import { FormikContextType } from 'formik';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { Try } from 'framework/Try';
import { useEffect } from 'react';

interface IWithReference {
	reference: string | undefined;
}

export const useNextReferenceInterceptor = <TModel extends IWithReference>(f: () => Promise<Try<string>>, props: FormikContextType<TModel>) => {
	const [nextReference] = useApiEffect(f);

	useEffect(() => {
		if (nextReference) {
			setFormValue<TModel>('reference', nextReference, props);
		}
		// eslint-disable-next-line
	}, [nextReference]);
};
