import { CardProps, Typography } from '@material-ui/core';
import React from 'react';
import { Typography16pxBold } from '../typography/Typography16pxBold';
import { CardX } from './CardX';
import { useStateBoolean } from 'framework/hooks/useStateBool';

interface IProps extends CardProps {
	title: string;
	caption?: string;
	children: any;
}

export const StatCardX = ({ title, caption, children, className, onClick, style, ...rest }: IProps) => {
	const [isHovered, enter, leave] = useStateBoolean(false);

	return (
		<CardX
			className={`df-col ${className}`}
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={onClick}
			raised={isHovered && onClick ? true : false}
			style={{
				padding: 12,
				cursor: onClick ? 'pointer' : 'inherit',
				...style,
			}}
			{...rest}>
			<Typography16pxBold>{title}</Typography16pxBold>
			{caption && (
				<Typography
					variant='caption'
					color='textSecondary'
					style={{ marginTop: '-2px', whiteSpace: 'pre', lineHeight: '14px' }}>
					{caption}
				</Typography>
			)}
			{children}
		</CardX>
	);
};
