import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { ArticlesInquiryStatusType, IArticlesInquiry } from 'gen/ApiClient';
import React from 'react';
import BlockIcon from '@material-ui/icons/Block';

const ArticlesInquiryStatusTypeIconRecord: Record<ArticlesInquiryStatusType, OverridableComponent<SvgIconTypeMap<{}, 'svg'>>> = {
	PartialAssigned: RadioButtonUncheckedIcon,
	Assigned: CheckCircleOutlineIcon,
	Unassigned: RadioButtonUncheckedIcon,
	Cancelled: BlockIcon,
};

interface IProps {
	item: IArticlesInquiry;
	style?: React.CSSProperties;
}

export const ArticlesInquiryListItemStatusIcon = ({ item, style }: IProps) => {
	const Icon = ArticlesInquiryStatusTypeIconRecord[item.status as ArticlesInquiryStatusType];
	return <Icon style={style} />;
};
