import React, { useContext } from 'react';
import { PreloadCacheProvider } from 'framework/context/PreloadCacheProvider';
import { bebatQuery_concurrencyToken, bebatQuery_default } from 'gen/ApiClient';
import { BebatDefaultCacheContext } from './BebatDefaultCacheContext';
import { PreloadContext } from './PreloadContext';

export const BebatDefaultCacheProvider = ({ children }: any) => {
	const { bebat } = useContext(PreloadContext);

	return (
		<PreloadCacheProvider<number>
			concurrencyF={bebatQuery_concurrencyToken}
			loadF={bebatQuery_default}
			context={BebatDefaultCacheContext}
			initialData={bebat}>
			{children}
		</PreloadCacheProvider>
	);
};
