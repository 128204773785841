import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import {
	CurrentLocationType,
	CurrentLocationTypes,
	IInventoryItemSearchData,
	IInventoryItemsQueryParams,
	InventoryStatus,
	InventoryStatuses,
	ProductCatalogItemType,
	PropertyOfType,
	PropertyOfTypes,
	TangibleTypes,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { LocationsPreloadCacheContext } from '../../settings/locations/LocationsPreloadCacheContext';
import { ViewContext } from './context/ViewContext';
import { QuickFilter } from './InventoryItemsList';
import { InventoryItemsFilterMoreDialog } from './InventoryItemsFilterMoreDialog';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';

interface IProps {
	params: IInventoryItemsQueryParams;
	onFilter: (params: IInventoryItemsQueryParams) => void;
	paddingLeft: number;
	data: IInventoryItemSearchData;
	type: QuickFilter;
}

// TODO fix this, is this correct?
const exclusions: (keyof IInventoryItemsQueryParams)[] = ['keyFilterType'];

// TODO add filter on Type!
export const Filter = ({ params, onFilter, paddingLeft, data, type }: IProps) => {
	const strings = useLocalization();
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const { open, confirm, cancel } = useDialogsContext();
	const { currentLocationTypeRecord, inventoryStatusRecord, productCatalogItemTypeRecord, propertyOfTypeRecord } = useContext(RecordContext);
	const { quickFilter } = useContext(ViewContext);

	const onFilterMore = () => {
		open(
			<InventoryItemsFilterMoreDialog
				open
				params={params}
				onFilter={pars => {
					onFilter(pars);
					confirm();
				}}
				cancel={cancel}
				data={data}
			/>
		);
	};

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}
			onMore={onFilterMore}>
			{quickFilter === 'all' && (
				<RecordListFilterComponent<ProductCatalogItemType>
					record={productCatalogItemTypeRecord}
					options={TangibleTypes}
					selected={params.types as any}
					setSelected={vals => onFilter({ ...params, types: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.type}
				/>
			)}
			<BooleanRadioFilterComponent
				label={strings.somethingQuestionmark(strings.tailorMadeShort)}
				trueText={strings.isTailorMade}
				falseText={strings.isNotTailorMade}
				selected={params.isTailorMade}
				setSelected={value => onFilter({ ...params, isTailorMade: value })}
				icon={<HelpOutlineIcon />}
			/>
			<RecordListFilterComponent<InventoryStatus>
				record={inventoryStatusRecord}
				options={InventoryStatuses}
				selected={params.statuses as any}
				setSelected={vals => onFilter({ ...params, statuses: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.status}
			/>
			<RecordListFilterComponent<CurrentLocationType>
				record={currentLocationTypeRecord}
				selected={params.locationTypes as any}
				setSelected={vals => onFilter({ ...params, locationTypes: vals })}
				options={CurrentLocationTypes}
				icon={<HelpOutlineIcon />}
				label={strings.place}
			/>
			<RecordListFilterComponent<PropertyOfType>
				record={propertyOfTypeRecord}
				selected={params.propertyOfTypes as any}
				setSelected={vals => onFilter({ ...params, propertyOfTypes: vals })}
				options={PropertyOfTypes}
				icon={<HelpOutlineIcon />}
				label={strings.propertyOf}
			/>
			<ListFilterComponent<string>
				options={locations}
				selected={params.atLocationIds}
				setSelected={vals => onFilter({ ...params, atLocationIds: vals })}
				label={strings.location}
				icon={<HelpOutlineIcon />}
				title={strings.locationsCurrentlyOnStock}
			/>
			<BooleanRadioFilterComponent
				trueText={strings.statusSold}
				falseText={strings.statusNotSold}
				selected={params.isSold}
				setSelected={val => onFilter({ ...params, isSold: val })}
				label={`${strings.statusSold}?`}
				icon={<HelpOutlineIcon />}
			/>
			<DateFilterItem
				label={strings.salesDate}
				before={params.soldBefore}
				after={params.soldAfter}
				setSelected={(after, before) => onFilter({ ...params, soldAfter: after, soldBefore: before })}
			/>
			<BooleanRadioFilterComponent
				trueText={strings.statusPurchased}
				falseText={strings.statusNotPurchased}
				selected={params.isPurchased}
				setSelected={val => onFilter({ ...params, isPurchased: val })}
				label={`${strings.statusPurchased}?`}
				icon={<HelpOutlineIcon />}
			/>
			<BooleanRadioFilterComponent
				trueText={strings.demo}
				falseText={strings.noDemoRegular}
				selected={params.isDemo}
				setSelected={val => onFilter({ ...params, isDemo: val })}
				label={`${strings.demo}?`}
				icon={<HelpOutlineIcon />}
			/>
			<BooleanRadioFilterComponent
				trueText={strings.statusReserved}
				falseText={strings.statusNotReserved}
				selected={params.isReserved}
				setSelected={val => onFilter({ ...params, isReserved: val })}
				label={`${strings.statusReserved}?`}
				icon={<HelpOutlineIcon />}
			/>
			{data.labels.length > 0 && (
				<StringListFilterComponent
					options={data.labels}
					selected={params.labels}
					setSelected={vals => onFilter({ ...params, labels: vals })}
					label={strings.labels}
					icon={<HelpOutlineIcon />}
				/>
			)}
		</FilterBar>
	);
};
