import React, { useRef } from 'react';

interface IInnerPageXWithStickySidebar {
	stickySidebar: React.ReactNode;
	children: React.ReactNode;
	spacing?: number;
	maxWidth?: number;
}

export const InnerPageXWithStickySidebar = ({ stickySidebar, children, maxWidth }: IInnerPageXWithStickySidebar) => {
	const leftColRef = useRef<HTMLDivElement>(null);

	return (
		<>
			<div
				ref={leftColRef}
				style={{
					position: 'sticky',
					top: '0px',
					height: '100%',
					overflowY: 'auto',
				}}>
				{stickySidebar}
			</div>
			<div
				className='df-col fg1 h100'
				style={{
					paddingTop: 1,
					maxWidth: maxWidth ? `calc(${maxWidth}px - ${leftColRef.current?.clientWidth ?? 0}px)` : 'unset',
				}}>
				{children}
			</div>
		</>
	);
};
