import { Divider } from '@material-ui/core';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import React, { useMemo } from 'react';

interface IProps {
	warnings?: string | (string | undefined)[];
	errors?: string | (string | undefined)[];
	style?: React.CSSProperties;
}

export const LineWarningsAndErrors = ({ warnings, errors, style }: IProps) => {
	const fErrors = useMemo<string[]>(
		() => (Array.isArray(errors) ? (errors.filter(t => t !== undefined) as string[]) : errors !== undefined ? [errors] : []),
		[errors]
	);
	const fWarnings = useMemo<string[]>(
		() => (Array.isArray(warnings) ? (warnings.filter(t => t !== undefined) as string[]) : warnings !== undefined ? [warnings] : []),
		[warnings]
	);
	const hasErrorOrWarning = useMemo(() => fErrors.length > 0 || fWarnings.length > 0, [fErrors, fWarnings]);

	return (
		<>
			{hasErrorOrWarning && (
				<>
					<Divider style={{ marginTop: 12, marginBottom: 8, ...style }} />
					{fWarnings.map(t => (
						<WarningLabelledProperty
							key={t}
							warning={t}
							withoutMargin
						/>
					))}
					{fErrors.map(t => (
						<ErrorLabelledProperty
							key={t}
							error={t}
							withoutMargin
						/>
					))}
				</>
			)}
		</>
	);
};
