import HearingIcon from '@material-ui/icons/Hearing';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { WizardButton } from './WizardButton';

interface IProps {
	onClick: VoidFunction;
}

export const ImportFromNoahWizardButton = ({ onClick }: IProps) => {
	const strings = useLocalization();

	return (
		<WizardButton
			startIcon={<HearingIcon />}
			onClick={onClick}>
			{strings.addDevicesFromNoah}
		</WizardButton>
	);
};
