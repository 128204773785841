import { useField } from 'formik';
import { IOption } from 'framework/IOption';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { nameOf } from 'framework/utils/nameOf';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import React, { useEffect, useMemo } from 'react';
import { useUsersAsFilterOptions } from '../useUsersAsFilterOptions';

interface IProps<TModel> extends IFormProps<TModel> {
	userIds?: string[];
}

export const FormSelectUserField = <TModel extends unknown>({ userIds, name, label, ...rest }: IProps<TModel>) => {
	// eslint-disable-next-line
	const [field, _, helpers] = useField<string | undefined>(nameOf(name));
	const mappedUsers = useUsersAsFilterOptions();
	const filteredUsers = useMemo(() => (userIds !== undefined ? mappedUsers?.filter(t => userIds?.indexOf(t.id) > -1) : mappedUsers), [mappedUsers, userIds]);

	useEffect(() => {
		if (filteredUsers !== undefined && isNotNullNorUndefined(field.value)) {
			if (filteredUsers.findIndex(t => t.id === field.value) === -1) {
				helpers.setValue(undefined as any);
			}
		}
		// eslint-disable-next-line
	}, [filteredUsers, field.value]);

	if (filteredUsers === undefined) {
		return <div></div>;
	}

	return (
		<FormAutocompleteField<TModel, IOption<string>>
			name={name}
			label={label}
			options={filteredUsers}
			{...rest}
		/>
	);
};
