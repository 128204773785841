import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { IRepair, RepairProcessStatus } from 'gen/ApiClient';
import React from 'react';

const RepairProcessStatusIconRecord: Record<RepairProcessStatus, OverridableComponent<SvgIconTypeMap<{}, 'svg'>>> = {
	CancelledAfterSentToSupplier: BlockIcon,
	CancelledBeforeSentToSupplier: BlockIcon,
	DeliveredToPatient: CheckCircleOutlineIcon,
	PartialRedelivered: RadioButtonUncheckedIcon,
	Redelivered: CheckCircleOutlineIcon,
	SentToSupplier: RadioButtonUncheckedIcon,
	Registered: RadioButtonUncheckedIcon,
};

interface IProps {
	item: IRepair;
	style?: React.CSSProperties;
}

export const RepairListItemStatusIcon = ({ item, style }: IProps) => {
	const Icon = RepairProcessStatusIconRecord[item.status as RepairProcessStatus];
	return <Icon style={style} />;
};
