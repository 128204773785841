import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';

export const ActionCardButton = ({ style, ...props }: ButtonProps) => (
	<Button
		variant='text'
		size='small'
		color='primary'
		style={{ marginLeft: '-5px', ...style }}
		{...props}
	/>
);
