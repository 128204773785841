import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { MoneyRangeFilterComponent } from 'framework/filter/MoneyRangeFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import {
	IProductCatalogQueryParams,
	ProductCatalogItemRizivHearingAidDefinitionV2LinkStatus,
	ProductCatalogItemRizivHearingAidDefinitionV2LinkStatuses,
	ProductCatalogItemType,
	ProductCatalogItemTypes,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { VatPercentagesListFilterItem } from 'shared/VatPercentagesListFilterItem';
import { SuppliersCacheContext } from '../../contacts/suppliers/SuppliersCacheContext';
import { ProductCatalogFilterDataContext } from './context/ProductCatalogFilterDataContext';
import { ViewContext } from './context/ViewContext';

interface IProps {
	params: IProductCatalogQueryParams;
	onFilter: (params: IProductCatalogQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof IProductCatalogQueryParams)[] = ['keyFilterType', 'isTangible', 'alsoIncludeDeleted'];

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const { productCatalogItemTypeRecord, rizivRefundableHearingInstrumentLinkStatusRecord } = useContext(RecordContext);
	const [suppliers] = usePreloadCacheContext(SuppliersCacheContext);
	const { quickFilter } = useContext(ViewContext);
	const [data] = useCacheContext(ProductCatalogFilterDataContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			{quickFilter === 'all' && (
				<RecordListFilterComponent<ProductCatalogItemType>
					options={ProductCatalogItemTypes}
					record={productCatalogItemTypeRecord}
					label={strings.type}
					selected={params.types as any}
					setSelected={vals => onFilter({ ...params, types: vals })}
					icon={<HelpOutlineIcon />}
				/>
			)}
			{data && data.categories.length > 0 && (
				<StringListFilterComponent
					label={strings.category}
					selected={params.categories}
					options={data.categories}
					icon={<HelpOutlineIcon />}
					setSelected={value => onFilter({ ...params, categories: value })}
				/>
			)}
			{data && data.subcategories.length > 0 && (
				<StringListFilterComponent
					label={strings.subcategory}
					selected={params.subcategories}
					options={data.subcategories}
					icon={<HelpOutlineIcon />}
					setSelected={value => onFilter({ ...params, subcategories: value })}
				/>
			)}
			{data && data.labels.length > 0 && (
				<StringListFilterComponent
					options={data.labels}
					selected={params.labels}
					setSelected={vals => onFilter({ ...params, labels: vals })}
					label={strings.labels}
					icon={<HelpOutlineIcon />}
				/>
			)}
			<DateFilterItem
				label={strings.dateCreated}
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<VatPercentagesListFilterItem
				selected={params.vatPercentages as any}
				setSelected={vals => onFilter({ ...params, vatPercentages: vals })}
			/>
			{data && (
				<MoneyRangeFilterComponent
					label={strings.price}
					min={data.minListPrice}
					max={data.maxListPrice}
					selected={{ min: params.listPriceMoreThan, max: params.listPriceLessThan }}
					setSelected={val => onFilter({ ...params, listPriceMoreThan: val.min, listPriceLessThan: val.max })}
					icon={<HelpOutlineIcon />}
				/>
			)}
			{quickFilter !== 'all' && quickFilter !== 'intangible' && (
				<>
					{data && data.manufacturers.length > 0 && (
						<StringListFilterComponent
							label={strings.manufacturers}
							selected={params.manufacturers}
							options={data.manufacturers}
							icon={<HelpOutlineIcon />}
							setSelected={value => onFilter({ ...params, manufacturers: value })}
						/>
					)}
					<ListFilterComponent<string>
						label={strings.suppliers}
						selected={params.suppliers}
						options={suppliers}
						setSelected={value => onFilter({ ...params, suppliers: value })}
						icon={<HelpOutlineIcon />}
					/>
				</>
			)}
			{quickFilter !== 'intangible' && (
				<>
					<BooleanRadioFilterComponent
						label={strings.somethingQuestionmark(strings.serialNumber)}
						trueText={strings.hasSerialNumber}
						falseText={strings.hasNoSerialNumber}
						selected={params.hasSerialNumber}
						setSelected={value => onFilter({ ...params, hasSerialNumber: value })}
						icon={<HelpOutlineIcon />}
					/>
					<BooleanRadioFilterComponent
						label={strings.somethingQuestionmark(strings.tailorMadeShort)}
						trueText={strings.isTailorMade}
						falseText={strings.isNotTailorMade}
						selected={params.isTailorMade}
						setSelected={value => onFilter({ ...params, isTailorMade: value })}
						icon={<HelpOutlineIcon />}
					/>
					<BooleanRadioFilterComponent
						label={strings.batteries}
						trueText={strings.withWhat(strings.batteries)}
						falseText={strings.withoutWhat(strings.batteries)}
						selected={params.hasBatteries}
						setSelected={value => onFilter({ ...params, hasBatteries: value })}
						icon={<HelpOutlineIcon />}
					/>
					<BooleanRadioFilterComponent
						label={strings.electronics}
						trueText={strings.isElectronics}
						falseText={strings.isNotElectronics}
						selected={params.isElectronics}
						setSelected={value => onFilter({ ...params, isElectronics: value })}
						icon={<HelpOutlineIcon />}
					/>
				</>
			)}
			{params.alsoIncludeDeleted === true && (
				<BooleanRadioFilterComponent
					trueText={strings.deleted}
					falseText={strings.notWhat(strings.deleted)}
					selected={params.isDeleted}
					setSelected={val => onFilter({ ...params, isDeleted: val })}
					label={strings.somethingQuestionmark(strings.deleted)}
					icon={<HelpOutlineIcon />}
				/>
			)}
			{quickFilter === 'hearingInstruments' && (
				<RecordListFilterComponent<ProductCatalogItemRizivHearingAidDefinitionV2LinkStatus>
					record={rizivRefundableHearingInstrumentLinkStatusRecord}
					options={ProductCatalogItemRizivHearingAidDefinitionV2LinkStatuses}
					selected={params.rizivHearingAidDefinitionV2LinkStatuses as any}
					setSelected={val => onFilter({ ...params, rizivHearingAidDefinitionV2LinkStatuses: val })}
					label={strings.RIZIVCode}
					icon={<HelpOutlineIcon />}
				/>
			)}
		</FilterBar>
	);
};
