import { Box, CardProps } from '@material-ui/core';
import HearingOutlinedIcon from '@material-ui/icons/HearingOutlined';
import { CardX } from 'framework/components/cards/CardX';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { patientAssignedArticlesQuery_get } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { AssignedOrInquiredArticleComponent } from './AssignedOrInquiredArticleComponent';

interface IProps extends IReloadTriggerProps, CardProps {
	patientId: string;
}

export const PatientAssignedOrInquiredArticlesCard = ({ patientId, reloadTrigger, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const [articles, reloadArticles] = useApiEffect(patientAssignedArticlesQuery_get, patientId);
	const [isShowAll, toggleShowAll] = useStateToggleBoolean(false);
	const hasHiddenArticles = useMemo(() => (articles ?? []).filter(t => t.isHidden).length > 0, [articles]);
	const nonLeftArticles = useMemo(() => (articles ?? []).filter(t => isShowAll || t.isHidden === false).filter(t => t.isLeft === false), [articles, isShowAll]);
	const leftArticles = useMemo(() => (articles ?? []).filter(t => isShowAll || t.isHidden === false).filter(t => t.isLeft === true), [articles, isShowAll]);

	useLazyEffect(() => {
		reloadArticles();
	}, [reloadTrigger]);

	if (articles === undefined) {
		return <></>;
	}

	return (
		<CardX style={style}>
			<Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 8 }}>
				<CardTitleTypography
					startIcon={<HearingOutlinedIcon style={{ marginRight: 4, opacity: 0.8 }} />}
					title={strings.actualEquipment}
					style={{ marginBottom: 8 }}
				/>
				{hasHiddenArticles && (
					<FormControlLabelSwitch
						isChecked={isShowAll}
						toggle={toggleShowAll}
						label={strings.alsoShowDeleted}
					/>
				)}
			</Box>
			{(articles ?? []).length === 0 && <em>{strings.noEquipmentAssignedYet}</em>}
			<div className='df-row gap-16'>
				<div className='df-col gap-16 w50'>
					{nonLeftArticles.map(t => (
						<AssignedOrInquiredArticleComponent
							key={t.id}
							item={t}
							reload={reloadArticles}
							patientId={patientId}
						/>
					))}
				</div>
				<div className='df-col gap-16 w50'>
					{leftArticles.map(t => (
						<AssignedOrInquiredArticleComponent
							key={t.id}
							item={t}
							reload={reloadArticles}
							patientId={patientId}
						/>
					))}
				</div>
			</div>
		</CardX>
	);
};
