import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { ICalendarResourceDto, ICalendarResourcesDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useMemo } from 'react';
import { CalendarResourcesPreloadCacheContext } from './CalendarResourcesPreloadCacheContext';

const createEmpty = (data: ICalendarResourcesDto, name: string): ICalendarResourceDto => {
	return {
		color: data.defaultCalendarColor!,
		key: data.emptyGroupKey!,
		name: name,
		isEmpty: true,
		id: data.emptyGroupKey!,
		identifier: name,
		isDeleted: false,
		isPlannable: true,
	};
};

export function useCalendarResources(): {
	users: ICalendarResourceDto[];
	rooms: ICalendarResourceDto[];
	eventTypes: ICalendarResourceDto[];
	reload: VoidFunction;
} {
	const strings = useLocalization();
	const [data, reload] = usePreloadCacheContext(CalendarResourcesPreloadCacheContext);

	const users = useMemo<ICalendarResourceDto[]>(() => {
		const empty: ICalendarResourceDto = createEmpty(data, strings.withoutWhat(strings.user));
		return [...data.plannableUsers, empty];
	}, [data, strings]);

	const rooms = useMemo<ICalendarResourceDto[]>(() => {
		const empty: ICalendarResourceDto = createEmpty(data, strings.withoutWhat(strings.room));
		return [...data.plannableRooms, empty];
	}, [data, strings]);

	const eventTypes = useMemo<ICalendarResourceDto[]>(() => {
		const empty: ICalendarResourceDto = createEmpty(data, strings.withoutWhat(strings.type));
		return [...data.plannableEventTypes, empty];
	}, [data, strings]);

	return {
		eventTypes: eventTypes,
		rooms: rooms,
		users: users,
		reload: reload,
	};
}
