import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IOrientationTest, trialReportsCommand_deleteOrientation } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { OrientationTestForm } from './OrientationTestForm';

interface IProps extends DialogProps {
	id: string;
	item: IOrientationTest;
	close: VoidFunction;
}

export const OrientationTestDialog = ({ id, item, close, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { open, confirm, cancel, isStacked } = useDialogsContext(close);

	if (item === undefined) {
		return <div></div>;
	}

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.test)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => trialReportsCommand_deleteOrientation(id)}
				accept={close}
			/>
		);
	};

	const onEdit = () => {
		open(
			<OrientationTestForm
				open
				trialId={id}
				cancel={cancel}
				confirm={confirm}
				test={item}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			maxWidth='sm'
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<GroupWorkIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						CLT
					</Typography>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.results} />
				<PropertyInGrid
					label={strings.left}
					value={item.left + ' %'}
				/>
				<PropertyInGrid
					label={strings.right}
					value={item.right + ' %'}
				/>
				<PropertyInGrid
					label={strings.stereoWith}
					value={item.bothEarsWithDevices + ' %'}
				/>
				<PropertyInGrid
					label={strings.stereoWithout}
					value={item.bothEarsWithoutDevices + ' %'}
				/>
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								onClick={onEdit}
								startIcon={<EditIcon />}>
								{strings.edit}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={onDelete}
								color='secondary'
								startIcon={<DeleteOutlineIcon />}>
								{strings.delete}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
