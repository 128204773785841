import React from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { ISupplier } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	item: ISupplier;
	style?: React.CSSProperties;
}

export const SupplierAccountStatusChip = ({ item, style }: IProps) => {
	const strings = useLocalization();

	return (
		<RectangularChip
			style={style}
			label={item.hasOutstandingBalance ? strings.open : strings.handled}
			fill={item.hasOutstandingBalance}
			color={item.hasOutstandingBalance ? 'warning' : 'success'}
		/>
	);
};
