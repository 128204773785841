import { Collapse, Divider, Radio } from '@material-ui/core';
import { TextLinkButtonWithExpandIcon } from 'framework/components/buttons/TextLinkButtonWithExpandIcon';
import { CardX } from 'framework/components/cards/CardX';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { formatDate } from 'framework/utils/date/formatDate';
import { IOrderV2 } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IOrderV2;
	onSelect: VoidFunction;
	isSelected: boolean;
	style?: React.CSSProperties;
}

export const OrderNotPlacedWithSupplierListItemCardX = ({ item, style, onSelect, isSelected }: IProps) => {
	const strings = useLocalization();
	const [hovered, enter, leave] = useStateBoolean(false);
	const [isExpanded, toggleIsExpanded] = useStateToggleBoolean(false);

	return (
		<CardX
			className='df-row gap-8 w100'
			raised={hovered || isSelected}
			onMouseEnter={enter}
			onMouseLeave={leave}
			style={{
				cursor: 'pointer',
				...style,
			}}
			onClick={onSelect}>
			<div className='df-col'>
				<Radio
					style={{ padding: 0 }}
					checked={isSelected}
				/>
			</div>
			<div className='df-col fg1'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.reference}</span>
				{item.externalReference && <span style={{ fontSize: MinorFontSize }}>{`${strings.externalReference}: ${item.externalReference}`}</span>}
				{item.isForSinglePatient && <span style={{ fontSize: MinorFontSize }}>{`${strings.patient}: ${item.singlePatientIdentifier}`}</span>}
				<div
					className='df-row-ac'
					style={{ fontSize: MinorFontSize, gap: 4 }}>
					<span>{`${strings.date}: ${formatDate(item.created)}`}</span>
					<span>{`${strings.expectedDeliveryDate}: ${formatDate(item.expectedDeliveryDate)}`}</span>
					<TextLinkButtonWithExpandIcon
						toggle={toggleIsExpanded}
						isExpanded={isExpanded}>
						<span style={{ fontSize: MinorFontSize, fontWeight: 'unset' }}>
							{`# ${item.countItems.toString()} ${
								item.countItems === 1 || item.countItems === -1 ? strings.article.toLowerCase() : strings.articles.toLowerCase()
							}`}
						</span>
					</TextLinkButtonWithExpandIcon>
				</div>
				<Collapse in={isExpanded}>
					<div className='df-col'>
						<Divider
							variant='inset'
							style={{ marginTop: 8, marginBottom: 4 }}
						/>
						{item.lines.map(line => (
							<div
								className='df-row-ab gap-4'
								key={line.id}
								style={{ fontSize: MinorFontSize, gap: 4 }}>
								<span>{`${line.quantity.toString()} x ${line.description}`}</span>
								{item.isForSinglePatient === false && line.hasPatient && <span>{`${strings.patient}: ${line.patientIdentifier}`}</span>}
							</div>
						))}
					</div>
				</Collapse>
			</div>
		</CardX>
	);
};
