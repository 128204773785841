import AccessibilityIcon from '@material-ui/icons/Accessibility';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import { DragAndDropContainer } from 'framework/components/dnd/DragAndDropContainer';
import { LeftRightDividedContentScrollYRight } from 'framework/components/LeftRightDividedContentScrollYRight';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { addOrUpdateQuantityByPredicate } from 'framework/utils/array/addOrUpdateQuantityByPredicate';
import { updateArray } from 'framework/utils/array/updateArray';
import {
	IInventoryItem,
	IInventoryItemWithProductCatalogItemDto,
	ILocationSummary,
	IMedicalTreatmentFeeDto,
	inventoryItemsQuery_reservedByPatientIds,
	IProductCatalogItem,
	IRequestForInsuranceAllowance,
	ISalesLineForAssuranceDto,
	IStockAtLocation,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AddLineForm } from 'shared/searchAndSelect/AddLineForm';
import { DefaultInventoryItemsQueryParamsForSale } from 'shared/searchAndSelect/DefaultInventoryItemsQueryParamsForSale';
import { INewLineModel } from 'shared/searchAndSelect/INewLineModel';
import { SearchProductCatalogOrInventoryItemForSaleTabs } from 'shared/searchAndSelect/SearchProductCatalogOrInventoryItemForSaleTabs';
import { DiscountWizardButton } from 'shared/searchAndSelect/wizard/DiscountWizardButton';
import { ImportFromNoahWizardButton } from 'shared/searchAndSelect/wizard/ImportFromNoahWizardButton';
import { ReservedWizardButton } from 'shared/searchAndSelect/wizard/ReservedWizardButton';
import { TenderWizardButton } from 'shared/searchAndSelect/wizard/TenderWizardButton';
import { WizardButton } from 'shared/searchAndSelect/wizard/WizardButton';
import { WizardDivider } from 'shared/searchAndSelect/wizard/WizardDivider';
import { ImportNoahDevicesByPatientForm } from 'shared/searchAndSelect/wizardImportNoahDevices/ImportNoahDevicesByPatientForm';
import { SelectedReservedArticles } from 'shared/searchAndSelect/wizardImportReserved/SelectedReservedArticles';
import { v1 as uuid } from 'uuid';
import { SearchContext } from './context/SearchContext';
import { HandoverLineComponent } from './HandoverLineComponent';
import { IHandoverLine } from './IHandoverLine';
import { TotalsTable } from './TotalsTable';
import { assuranceToLine } from './utils/assuranceToLine';
import { inventoryItemToLine } from './utils/inventoryItemToLine';
import { newLineToLine } from './utils/newLineToLine';
import { productCatalogItemToLine } from './utils/productCatalogItemToLine';
import { SelectAdvanceInvoicesForm } from './wizard/advanceInvoices/SelectAdvanceInvoicesForm';
import { SelectLinesFromProformaHandoverForm } from './wizard/proforma/SelectLinesFromProformaHandoverForm';
import { SelectFromTenderForm } from './wizard/tender/SelectFromTenderForm';
import { SelectFromLoanForm } from './wizard/trial/SelectFromLoanForm';

interface IProps {
	rfia: IRequestForInsuranceAllowance;
	lines: IHandoverLine[];
	setLines: React.Dispatch<React.SetStateAction<IHandoverLine[]>>;
	fees: IMedicalTreatmentFeeDto[];
	location?: ILocationSummary;
	mode: 'handover' | 'pro-forma';
	customerAccountId: string | undefined;
	handoverDate: Date;
}

export const SearchAndSelectProductsForHandover = ({ customerAccountId, rfia, lines, setLines, location, fees, mode, handoverDate }: IProps) => {
	const notify = useSnackbarNotify();
	const strings = useLocalization();
	const { ...allSearchParams } = useContext(SearchContext);
	const { open, confirm, cancel } = useDialogsContext();
	const processedAdvanceInvoices = useMemo(() => lines.filter(t => t.advanceInvoiceId !== undefined).map(t => t.advanceInvoiceId!), [lines]);
	const advanceInvoices = useMemo(
		() => rfia.invoiceProcess.sales.filter(t => t.isAdvance && processedAdvanceInvoices.indexOf(t.id) === -1),
		[processedAdvanceInvoices, rfia]
	);
	const [reservedIds] = useApiEffect(inventoryItemsQuery_reservedByPatientIds, rfia.patientId);
	const countReserved = useMemo(() => reservedIds?.filter(t => lines.findIndex(x => x.inventoryItemId === t) === -1).length ?? 0, [reservedIds, lines]);
	const isUsingOldRizivIdentificationCode = useMemo(() => handoverDate < new Date(2023, 7, 1), [handoverDate]);

	const onAddPc = (item: IProductCatalogItem, stockAtLocations: IStockAtLocation[]) => {
		setLines(addOrUpdateQuantityByPredicate<IHandoverLine>(t => t.productCatalogItemId === item.id, productCatalogItemToLine(item, stockAtLocations), lines));
	};

	const onAddAssuredLines = (insuranceOrProlongedWarranty: IProductCatalogItem, linesToAssure: (IHandoverLine | ISalesLineForAssuranceDto)[]) => {
		setLines([...lines, ...linesToAssure.map(t => assuranceToLine(insuranceOrProlongedWarranty, t))]);
	};

	const onAddII = (item: IInventoryItem, pc: IProductCatalogItem) => {
		if (lines.findIndex(t => t.inventoryItemId === item.id) > -1) {
			notify(strings.itemAlreadyInList, 'warning');
		} else {
			setLines([...lines, inventoryItemToLine(item, pc)]);
		}
	};

	const onAddLine = () => {
		open(
			<AddLineForm
				open
				confirm={line => {
					confirm();
					onLineAdded(line);
				}}
				cancel={cancel}
			/>
		);
	};

	const onLineAdded = (line: INewLineModel) => {
		setLines([...lines, newLineToLine(line)]);
	};

	const onAddLines = (linesToAdd: IHandoverLine[]) => {
		let x: IHandoverLine[] = [...lines];
		linesToAdd.forEach(line => {
			if (line.inventoryItem && lines.findIndex(t => t.inventoryItemId === line.inventoryItemId) === -1) {
				x = [...x, line];
			} else if (line.productCatalogItem) {
				// here implement the split up in if has serial number!
				if (line.productCatalogItem.isUniquelyIdentifiable) {
					for (var i = 1; i <= line.quantity; i++) {
						x = [...x, { ...line, quantity: 1, id: uuid() }];
					}
				} else {
					x = addOrUpdateQuantityByPredicate<IHandoverLine>(t => t.productCatalogItemId === line.productCatalogItemId, line, x);
				}
			} else {
				// just add it!
				x = [...x, line];
			}
		});
		setLines(x);
	};

	const onFromTrial = () => {
		open(
			<SelectFromLoanForm
				rfia={rfia}
				confirm={lines => {
					confirm();
					onAddLines(lines);
				}}
				cancel={cancel}
			/>
		);
	};

	const onAddFromProforma = () => {
		open(
			<SelectLinesFromProformaHandoverForm
				open
				rfia={rfia}
				confirm={lines => {
					confirm();
					onAddLines(lines);
				}}
				cancel={cancel}
			/>
		);
	};

	const onFromTender = () => {
		open(
			<SelectFromTenderForm
				rfia={rfia}
				confirm={lines => {
					confirm();
					onAddLines(lines);
				}}
				cancel={cancel}
			/>
		);
	};

	const onFromAdvanceInvoice = () => {
		open(
			<SelectAdvanceInvoicesForm
				open
				cancel={cancel}
				confirm={lines => {
					confirm();
					onAddAdvanceInvoices(lines);
				}}
				advanceInvoices={advanceInvoices}
			/>
		);
	};

	const onAddAdvanceInvoices = (vals: IHandoverLine[]) => {
		let x = [...lines];
		vals.forEach(t => {
			if (lines.findIndex(x => x.id === t.id) === -1) {
				x = [...x, t];
			}
		});
		setLines(x);
	};

	const onAddIIs = (items: IInventoryItemWithProductCatalogItemDto[]) => {
		let x = [...lines];
		items.forEach(item => {
			if (x.findIndex(t => t.inventoryItemId === item.inventoryItem.id) === -1) {
				x = [...x, inventoryItemToLine(item.inventoryItem, item.productCatalogItem)];
			}
		});
		setLines(x);
	};

	const onAddNoahDevices = () => {
		open(
			<ImportNoahDevicesByPatientForm
				open
				confirm={items => {
					confirm();
					onAddIIs(items);
				}}
				cancel={cancel}
				patientId={rfia.patientId}
			/>
		);
	};

	const onAddReservedArticles = () => {
		open(
			<SelectedReservedArticles
				open
				confirm={items => {
					confirm();
					onAddIIs(items);
				}}
				cancel={cancel}
				patientId={rfia.patientId}
				canSelect={t => t.canSelectForSale && lines.findIndex(x => x.id === t.id) === -1}
			/>
		);
	};

	return (
		<LeftRightDividedContentScrollYRight
			leftContent={
				<SearchProductCatalogOrInventoryItemForSaleTabs<IHandoverLine>
					lines={lines}
					{...allSearchParams}
					defaultIIParams={{ ...DefaultInventoryItemsQueryParamsForSale, forPatientId: rfia.patientId }}
					onAddII={onAddII}
					onAddAssuredLines={mode === 'pro-forma' ? undefined : onAddAssuredLines}
					onAddPc={onAddPc}
					canSelectInventoryItemF={i => i.canSelectForSale && (i.isInLoan === false || i.patientId === rfia.patientId)}
					customerAccountId={customerAccountId}
					wizardContent={
						<div className='df-col'>
							<DiscountWizardButton onClick={onAddLine} />
							<WizardDivider />
							<WizardButton
								startIcon={<AccessibilityIcon />}
								onClick={onFromTrial}
								disabled={rfia.loanSummaries.length === 0}>
								{strings.selectFromWhat(strings.trial)}
							</WizardButton>
							<WizardDivider />
							<TenderWizardButton
								onClick={onFromTender}
								count={rfia.invoiceProcess.tenders?.length ?? 0}
							/>
							<WizardDivider />
							<WizardButton
								badge={advanceInvoices.length}
								startIcon={<ReceiptOutlinedIcon />}
								onClick={onFromAdvanceInvoice}
								disabled={advanceInvoices.length === 0}>
								{strings.processAdvanceInvoice}
							</WizardButton>
							<WizardDivider />
							<ImportFromNoahWizardButton onClick={onAddNoahDevices} />
							<WizardDivider />
							<WizardButton
								disabled={rfia.hasProformaHandoverLines === false}
								startIcon={<ReceiptOutlinedIcon />}
								onClick={onAddFromProforma}>
								{strings.addArticlesFromProformaHandover}
							</WizardButton>
							{countReserved !== undefined && (
								<>
									<WizardDivider />
									<ReservedWizardButton
										onClick={onAddReservedArticles}
										count={countReserved}
									/>
								</>
							)}
						</div>
					}
				/>
			}
			rightContent={
				<DragAndDropContainer<IHandoverLine>
					lines={lines}
					setLines={setLines}
					getKey={t => t.id}
					render={(line, index, props) => (
						<HandoverLineComponent
							line={line}
							index={index + 1}
							dragHandleProps={props}
							setLine={val => setLines(updateArray(line, lines, val))}
							onDelete={() => setLines(lines.filter(x => x !== line && x.assuredLineId !== line.id))}
							setLines={setLines}
							location={location}
							lines={lines}
							customerAccountId={customerAccountId}
							isUsingOldRizivIdentificationCode={isUsingOldRizivIdentificationCode}
						/>
					)}
				/>
			}
			rightBottomFixedContent={
				<div className='df-col-ae'>
					<TotalsTable
						lines={lines}
						fees={fees}
						style={{ width: 'fit-content' }}
					/>
				</div>
			}
		/>
	);
};
