import { useApiEffect } from 'framework/hooks/useApiEffect';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { purchasesQuery_single } from 'gen/ApiClient';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { PurchaseDetailContentsCard } from './PurchaseDetailContentsCard';
import { PurchaseDetailPageHeader } from './PurchaseDetailPageHeader';
import { PurchaseDetailPageSidebar } from './PurchaseDetailPageSidebar';
import { PurchaseDetailPageViewContext } from './PurchaseDetailPageViewContext';
import { PurchaseDetailPaymentsCard } from './PurchaseDetailPaymentsCard';
import { PurchaseDetailsTotalsBreakdownCard } from './PurchaseDetailsTotalsBreakdownCard';

export const PurchaseDetailPage = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [item, reload] = useApiEffect(purchasesQuery_single, id);
	const { selectedTab, setSelectedTab, purchaseId, setPurchaseId } = useContext(PurchaseDetailPageViewContext);

	useEffect(() => {
		if (purchaseId !== id) {
			setSelectedTab('contents');
			setPurchaseId(id);
		}
		// eslint-disable-next-line
	}, [id, purchaseId]);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='Purchases'
			maxWidth={1280}
			stickyHeader={
				<PurchaseDetailPageHeader
					item={item}
					reload={reload}
					tab={selectedTab}
					setTab={setSelectedTab}
				/>
			}
			stickySidebar={
				<PurchaseDetailPageSidebar
					item={item}
					reload={reload}
				/>
			}>
			<div
				className='df-col'
				style={{
					padding: 16,
					paddingLeft: 8,
					maxWidth: 1280,
				}}>
				{selectedTab === 'contents' && (
					<div className='df-row gap-16'>
						<PurchaseDetailContentsCard
							item={item}
							className='fg1 h-fc'
						/>
						<PurchaseDetailsTotalsBreakdownCard
							item={item}
							className='h-fc'
						/>
					</div>
				)}
				{selectedTab === 'payments' && <PurchaseDetailPaymentsCard item={item} />}
			</div>
		</RestrictedPageX>
	);
};
