import { Typography, useTheme } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

export const RosaNotEnabledPage = () => {
	const strings = useLocalization();
	const theme = useTheme();

	return (
		<div className='df-col-ac stretch-ver jc-c'>
			<ReportProblemOutlinedIcon
				// color='primary'
				style={{ fontSize: '120px', marginBottom: 20, color: theme.palette.warning.main }}
			/>
			<div style={{ fontSize: '34px', color: theme.palette.warning.main, textTransform: 'uppercase', marginBottom: 10 }}>{strings.rosaNotConfiguredYet}</div>
			<Typography style={{ color: theme.palette.text.primary, whiteSpace: 'pre-wrap', textAlign: 'center' }}>
				{strings.hamsWorksTogetherWithROSAforCalendar}
			</Typography>
			<a
				href='https://pro.rosa.be/nl/'
				target='_blank'
				rel='noopener noreferrer'>
				{strings.clickHereForMoreInformationAboutROSA}
			</a>
			<a
				href='https://app.getguru.com/card/TLMook6c/-START-met-ROSA-kalender-in-HAMS'
				target='_blank'
				rel='noopener noreferrer'>
				{strings.viewHereHowToIntegrateWithROSA}
			</a>
		</div>
	);
};
