import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { LabelledChipsList } from 'framework/components/chips/LabelledChipsList';
import { GoogleEarthIcon } from 'framework/components/icons/GoogleEarthIcon';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { IRequestForInsuranceAllowance, RfiaWarning } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { RequestsForInsuranceAllowancesSettingsRoute } from '../../ProcessesRoutes';
import { RfiaStatusChip } from '../RfiaStatusChip';
import { RfiasPageBreadcrumbs } from '../RfiasPageBreadcrumbs';
import { ViewContext } from '../context/ViewContext';
import { RfiaDetailTabType, RfiaDetailTabTypes } from './RfiaDetailTabType';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { useLocalization } from 'localization/useLocalization';
import { useHistory } from 'react-router-dom';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';

const TabTypeIconRecord: Record<RfiaDetailTabType, React.ReactNode> = {
	process: <DoubleArrowIcon />,
	noahOverview: <GoogleEarthIcon />,
	comments: <CommentOutlinedIcon />,
};

interface IProps {
	item: IRequestForInsuranceAllowance;
	reload: VoidFunction;
	tab: RfiaDetailTabType;
	setTab: React.Dispatch<React.SetStateAction<RfiaDetailTabType>>;
}

export const RfiaDetailPageHeader = ({ item, reload, tab, setTab }: IProps) => {
	const strings = useLocalization();
	const { rfiaWarningRecord } = useContext(RecordContext);
	const { navigationLinkRecord } = useContext(ViewContext);
	const { push } = useHistory();

	return (
		<div className='df-col fg1'>
			<div className='df-row-ac'>
				<RfiasPageBreadcrumbs />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={item.reference} />
				<RfiaStatusChip
					item={item}
					style={{ marginLeft: 16 }}
				/>
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageSettingsButtonX route={RequestsForInsuranceAllowancesSettingsRoute} />
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			{item.hasWarning && (
				<VariantLabelledPropertyX
					variant='warning'
					style={{ marginTop: 8 }}>
					{rfiaWarningRecord[item.warning as RfiaWarning]}
				</VariantLabelledPropertyX>
			)}
			<LabelledChipsList
				labels={item.labels}
				style={{ marginTop: 8 }}
			/>
			<div
				className='df-row-ac'
				style={{
					marginTop: 8,
					marginLeft: -4,
				}}>
				<HorizontalTabsX
					tabs={RfiaDetailTabTypes}
					selectedTab={tab}
					setSelectedTab={setTab}
					startIconRecord={TabTypeIconRecord}
					titleRecord={navigationLinkRecord}
				/>
				<div className='fg1'></div>
				<div className='df-row-ac gap-4'>
					<TextLinkButton
						startIcon={
							<div className='df-row-ac'>
								<PersonOutlineOutlinedIcon />
								<OpenInBrowserOutlinedIcon style={{ marginLeft: -2 }} />
							</div>
						}
						color='textSecondary'
						underline
						onClick={() => push(calcPatientDetailRoute(item.patientId))}>
						{strings.patient}
					</TextLinkButton>
				</div>
			</div>
		</div>
	);
};
