import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { generalPractitionersCommand_patchPersonalInfo, IGeneralPractitioner, IPatchGeneralPractitionerPersonalInformationRequest } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { FormGenderSelectField } from 'shared/forms/FormGenderSelectField';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchGeneralPractitionerPersonalInformationRequest>({
		firstName: yup.string().required(strings.formRequired(strings.firstName)),
		lastName: yup.string().required(strings.formRequired(strings.lastName)),
		gender: yup.string().required(strings.formRequired(strings.gender)),
		salutation: yup.string(),
		generalPractitionerId: yup.string().required(),
	});
};

const toModel = (gp: IGeneralPractitioner): IPatchGeneralPractitionerPersonalInformationRequest => {
	return {
		firstName: gp.firstName,
		lastName: gp.lastName,
		gender: gp.gender ?? '',
		salutation: gp.salutation,
		generalPractitionerId: gp.id,
	};
};

interface IProps extends DialogProps {
	generalPractitioner: IGeneralPractitioner;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchGeneralPractitionerPersonalInformationForm = ({ generalPractitioner, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(generalPractitionersCommand_patchPersonalInfo);

	const handleSubmit = async (
		values: IPatchGeneralPractitionerPersonalInformationRequest,
		helpers: FormikHelpers<IPatchGeneralPractitionerPersonalInformationRequest>
	) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.generalPractitionerUpdated);
			confirm();
		}
	};

	return (
		<Formik<IPatchGeneralPractitionerPersonalInformationRequest>
			validateOnMount
			initialValues={toModel(generalPractitioner)}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog {...rest}>
					<DialogTitle>{strings.update}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<IPatchGeneralPractitionerPersonalInformationRequest>
							name='salutation'
							label={strings.salutation}
						/>
						<FormTextField<IPatchGeneralPractitionerPersonalInformationRequest>
							name='firstName'
							label={strings.firstName}
						/>
						<FormTextField<IPatchGeneralPractitionerPersonalInformationRequest>
							name='lastName'
							label={strings.lastName}
						/>
						<FormGenderSelectField<IPatchGeneralPractitionerPersonalInformationRequest>
							name='gender'
							label={strings.gender}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
