import { ProcessesRoute } from 'routes';

export const OrdersRoute = `${ProcessesRoute}/orders`;
export const OrdersSettingsRoute = `${OrdersRoute}/settings`;
export const OrderDetailRoute = `${OrdersRoute}/:id`;
export const calcOrderDetailRoute = (id: string) => OrderDetailRoute.replace(':id', id);
export const RepairsRoute = `${ProcessesRoute}/repairs`;
export const RepairsSettingsRoute = `${RepairsRoute}/settings`;
export const RepairDetailRoute = `${RepairsRoute}/:id`;
export const calcRepairDetailRoute = (id: string) => RepairDetailRoute.replace(':id', id);
export const RequestsForInsuranceAllowanceRoute = `${ProcessesRoute}/requests-for-insurance-allowance`;
export const RequestsForInsuranceAllowancesSettingsRoute = `${RequestsForInsuranceAllowanceRoute}/settings`;
export const RequestForInsuranceAllowanceDetailRoute = `${RequestsForInsuranceAllowanceRoute}/:id`;
export const calcRequestForInsuranceAllowanceDetailRoute = (id: string) => RequestForInsuranceAllowanceDetailRoute.replace(':id', id);
export const LoansRoute = `${ProcessesRoute}/loans`;
export const LoansSettingsroute = `${LoansRoute}/settings`;
export const LoanDetailRoute = `${LoansRoute}/:id`;
export const calcLoanDetailRoute = (id: string) => LoanDetailRoute.replace(':id', id);
export const ArticlesInquiriesRoute = `${ProcessesRoute}/articles-inquiries`;
export const ArticlesInquiryDetailRoute = `${ArticlesInquiriesRoute}/:id`;
export const calculateArticlesInquiryDetailRoute = (id: string) => ArticlesInquiryDetailRoute.replace(':id', id);
