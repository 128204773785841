import { CancelledProcessStepX } from 'framework/components/stepper/CancelledProcessStepX';
import { ProcessStepX } from 'framework/components/stepper/ProcessStepX';
import { VerticalStepperX } from 'framework/components/stepper/VerticalStepperX';
import { IArticlesInquiryDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: IArticlesInquiryDto;
	style?: React.CSSProperties;
}

// TODO date assignment?
export const ArticlesInquiryDetailProcessComponent = ({ item, style }: IProps) => {
	const strings = useLocalization();

	return (
		<VerticalStepperX style={style}>
			<ProcessStepX
				doneText={strings.registered}
				todoText={strings.register}
				date={item.created}
				isCancelled={item.isCancelled}
				isActive={false}
			/>
			<ProcessStepX
				doneText={strings.assigned}
				todoText={strings.orderSlashAllocate}
				date={item.isAssigned ? item.lastUpdated : undefined}
				isCancelled={item.isCancelled}
				isActive
				isLast={item.isCancelled === false}
			/>
			{item.isCancelled && <CancelledProcessStepX />}
		</VerticalStepperX>
	);
};
