import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { LocationsPreloadCacheContext } from 'app/main/settings/locations/LocationsPreloadCacheContext';
import { userToSelectableRoles } from 'app/main/settings/users/utils/userToSelectableRoles';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { FormPasswordField } from 'framework/forms/FormPasswordField';
import { FormSelectFieldFromStrings } from 'framework/forms/FormSelectFieldFromStrings';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { ILocationSummary, IRegisterUserWithPasswordModel, IUserInfo, UserRole, usersCommand_registerWithPassword } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IRegisterUserWithPasswordModel>({
		salutation: yup.string(),
		firstName: yup.string().required(strings.formRequired(strings.firstName)),
		lastName: yup.string().required(strings.formRequired(strings.lastName)),
		email: yup.string().email().required(strings.formRequired(strings.email)),
		preferredLocationId: yup.string().required(),
		role: yup.mixed<UserRole>().required(),
		password: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const RegisterUserWithPasswordForm = ({ confirm, cancel, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [register, isSubmitting] = useFormSubmit(usersCommand_registerWithPassword);
	const strings = useLocalization();
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const user = useLoggedInUser();

	const handleSubmit = async (values: IRegisterUserWithPasswordModel, helpers: FormikHelpers<IRegisterUserWithPasswordModel>) => {
		const r = await register(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.userWithEmailCreated(values.email));
			confirm();
		}
	};

	if (locations === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterUserWithPasswordModel>
			validateOnMount
			initialValues={{
				salutation: '',
				firstName: '',
				lastName: '',
				email: '',
				preferredLocationId: '',
				role: 'Normal' as UserRole,
				password: undefined as any,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					isSubmitting={isSubmitting}
					locations={locations}
					cancel={cancel}
					user={user}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	locations: ILocationSummary[];
	user: IUserInfo;
}

const InnerDialog = ({ cancel, isSubmitting, locations, user, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IRegisterUserWithPasswordModel>();
	const strings = useLocalization();
	const userRoleOptions = useMemo(() => userToSelectableRoles(user), [user]);
	const { userRoleRecord } = useContext(RecordContext);

	useEffect(() => {
		if (locations.length === 1) {
			setFormValue<IRegisterUserWithPasswordModel>('preferredLocationId', locations[0].id, props);
		}
		// eslint-disable-next-line
	}, [locations]);

	return (
		<Dialog
			{...rest}
			fullWidth
			maxWidth='xs'>
			<DialogTitle>{strings.registerNewUser}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<FormTextField<IRegisterUserWithPasswordModel>
					name='email'
					label={strings.email}
					required
				/>
				<FormTextField<IRegisterUserWithPasswordModel>
					name='salutation'
					label={strings.salutation}
				/>
				<FormTextField<IRegisterUserWithPasswordModel>
					name='firstName'
					label={strings.firstName}
					required
				/>
				<FormTextField<IRegisterUserWithPasswordModel>
					name='lastName'
					label={strings.lastName}
					required
				/>
				<FormAutocompleteField<IRegisterUserWithPasswordModel, ILocationSummary>
					name='preferredLocationId'
					label={strings.location}
					options={locations}
				/>
				<FormSelectFieldFromStrings<IRegisterUserWithPasswordModel>
					name='role'
					options={userRoleOptions}
					label={strings.role}
					renderValue={t => userRoleRecord[t as UserRole]}
				/>
				<FormPasswordField<IRegisterUserWithPasswordModel>
					name='password'
					label={strings.password}
					required
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions
				submitText={strings.create}
				isSubmitting={isSubmitting}
				cancel={cancel}
			/>
		</Dialog>
	);
};
