import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import BlockIcon from '@material-ui/icons/Block';
import CancelScheduleSendOutlinedIcon from '@material-ui/icons/CancelScheduleSendOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import { IOrderLineV2, OrderLineStatus } from 'gen/ApiClient';
import React from 'react';

const OrderLineStatusIconRecord: Record<OrderLineStatus, OverridableComponent<SvgIconTypeMap<{}, 'svg'>>> = {
	Cancelled: BlockIcon,
	CompletelyDelivered: CheckCircleOutlineIcon,
	PartiallyDelivered: TimerOutlinedIcon,
	CancelledAfterPartialDelivery: CancelScheduleSendOutlinedIcon,
	NotDelivered: TimerOutlinedIcon,
};

interface IProps {
	item: IOrderLineV2;
	style?: React.CSSProperties;
}

export const OrderLineStatusIcon = ({ item, style }: IProps) => {
	const Icon = OrderLineStatusIconRecord[item.status as OrderLineStatus];
	return <Icon style={style} />;
};
