import { useTheme } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { DocumentDetailDialog } from 'documents/DocumentDetailDialog';
import { DocumentFromTemplateForm } from 'documents/DocumentFromTemplateForm';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ITender, TemplateType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

interface IProps {
	item: ITender;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const TenderDetailDocumentContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const theme = useTheme();

	const onNew = (type: TemplateType) => {
		open(
			<DocumentFromTemplateForm
				open
				templatePredefinedType={type}
				context='Tender'
				contextId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onViewDetail = (documentId: string) => {
		open(
			<DocumentDetailDialog
				open
				close={confirm}
				id={documentId}
			/>
		);
	};

	return (
		<div
			className='df-col gap-6'
			style={style}>
			<div
				className='df-row-ac'
				style={{ fontSize: 12, color: theme.palette.text.secondary }}>
				<span>{strings.documents}</span>
				<div className='fg1'></div>
				<DescriptionOutlinedIcon style={{ fontSize: 16 }} />
			</div>
			<TextLinkButton
				startIcon={<DescriptionOutlinedIcon />}
				color={item.hasTenderDocument === false ? 'secondary' : undefined}
				onClick={() => (item.tenderDocument ? onViewDetail(item.tenderDocumentId!) : onNew('Tender'))}>
				{item.hasTenderDocument === false && <em>{strings.createWhat(strings.tender)}</em>}
				{item.hasTenderDocument === true && strings.viewEditWhat(strings.tender)}
			</TextLinkButton>
			<TextLinkButton
				startIcon={<DescriptionOutlinedIcon />}
				color={item.hasOrderFormDocument === false ? 'secondary' : undefined}
				onClick={() => (item.orderFormDocument ? onViewDetail(item.orderFormDocumentId!) : onNew('TenderOrderForm'))}>
				{item.hasOrderFormDocument === false && <em>{strings.createWhat(strings.orderForm)}</em>}
				{item.hasOrderFormDocument === true && strings.viewEditWhat(strings.orderForm)}
			</TextLinkButton>
		</div>
	);
};
