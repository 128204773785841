import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { GridDivider } from 'framework/components/detailDialog/GridDivider';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IRegisterPurchaseRequest } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { boolToYesNo } from 'shared/utils/boolToString';
import { IPurchaseFormLine } from './IPurchaseFormLine';

interface IProps {
	lines: IPurchaseFormLine[];
	isShiftVat: boolean;
}

export const PurchaseFormConfirmation = ({ lines, isShiftVat }: IProps) => {
	const strings = useLocalization();
	const exVatTotal = useMemo(() => _.sum(lines.map(t => t.unitPrice * t.quantity)), [lines]);
	const inVatTotal = useMemo(() => _.sum(lines.map(t => t.unitPrice * t.quantity * (isShiftVat ? 1 : 1 + t.vatPercentage / 100))), [lines, isShiftVat]);
	const props = useFormikContext<IRegisterPurchaseRequest>();

	return (
		<Grid
			container
			spacing={2}
			style={{ maxWidth: 550 }}>
			<Grid
				item
				xs={12}
				className='df-col-ac'>
				<LightBulbNotification>{strings.thisTransactionCannotBeUndoneText}</LightBulbNotification>
			</Grid>
			<GridDivider />
			<PropertyInGrid
				labelGridSize={6}
				label={strings.reference}
				value={props.values.reference}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.externalReference}
				value={props.values.externalReference}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.quantity}
				value={_.sum(lines.map(t => t.quantity))}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.shiftVat}
				value={boolToYesNo(isShiftVat, strings)}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.totalExVat}
				value={formatCurrency(exVatTotal)}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.vatAmount}
				value={formatCurrency(inVatTotal - exVatTotal)}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.totalInVat}
				value={formatCurrency(inVatTotal)}
			/>
		</Grid>
	);
};
