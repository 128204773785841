import {
	Button,
	Dialog,
	DialogContent,
	DialogProps,
	DialogTitle,
	Divider,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
	useTheme,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { RadioFilterComponent } from 'framework/filter/RadioFilterComponent';
import { useApiEffectWithDefer } from 'framework/hooks/useApiEffectWithDefer';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IOption } from 'framework/IOption';
import { isLast } from 'framework/utils/array/isLast';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import {
	exampleTemplatesQuery_byMainContext,
	exampleTemplatesQuery_byPredefinedType,
	exampleTemplatesQuery_content,
	IExampleTemplateDto,
	LanguageOption,
	LanguageOptions,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NoRecordsFound } from '../../../framework/components/NoRecordsFound';
import { RecordContext } from '../../records/RecordContext';

interface IFilterData {
	language: LanguageOption | undefined;
}

interface IProps extends DialogProps {
	predefinedType?: string;
	mainContext?: string;
	language: LanguageOption;
	confirm: (content: string) => void;
	cancel: VoidFunction;
}

export const SelectExampleTemplateDialog = ({ confirm, cancel, predefinedType, mainContext, language, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [byPredefinedType] = useApiEffectWithDefer(exampleTemplatesQuery_byPredefinedType, predefinedType ?? '', isNotNullNorUndefined);
	const [byMainContext] = useApiEffectWithDefer(exampleTemplatesQuery_byMainContext, mainContext ?? '', isNotNullNorUndefined);
	const examples = useMemo(() => (predefinedType !== undefined ? byPredefinedType : byMainContext), [predefinedType, byPredefinedType, byMainContext]);
	const [filtered, setFiltered] = useState<IExampleTemplateDto[]>([]);
	const [params, setParams] = useState<IFilterData>({ language: language });
	const { localizedLanguageRecord } = useContext(RecordContext);
	const [loadContent, isSubmitting] = useFormSubmit(exampleTemplatesQuery_content);

	useEffect(() => {
		setParams({ language: language });
	}, [language]);

	useEffect(() => {
		if (examples !== undefined) {
			const x = examples.filter(t => params.language === undefined || t.language === params.language);
			setFiltered(x);
		}
	}, [params, examples]);

	const handleSubmit = async (id: string) => {
		const r = await loadContent(id);
		if (r.isSuccess) {
			confirm(r.result);
		}
	};

	if (examples === undefined) {
		return <div></div>;
	}
	return (
		<Dialog
			{...rest}
			scroll='paper'
			fullWidth>
			<CloseButtonOnAbsolutePosition onClick={cancel} />
			<DialogTitle>{strings.selectWhat(strings.anExample)}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<div className='df-col-as'>
					<Typography style={{ paddingLeft: '5px', marginBottom: theme.spacing(1) }}>{strings.filter}</Typography>
					<RadioFilterComponent<LanguageOption>
						style={{ marginBottom: theme.spacing(0.5) }}
						badgeVariant='dot'
						options={LanguageOptions.map<IOption<LanguageOption>>(t => ({ id: t, identifier: localizedLanguageRecord[t] }))}
						selected={params.language}
						setSelected={val => setParams({ ...params, language: val })}
						label={strings.language}
						icon={<HelpOutlineIcon />}
					/>
				</div>
				<DividerWithCaption caption={strings.filteredResults} />
				{filtered.length > 0 && (
					<List
						dense
						style={{ overflow: 'auto', marginLeft: '-24px', marginRight: '-24px' }}>
						{filtered.map(t => (
							<div
								className='df-col'
								key={t.name}>
								<ListItem style={{ paddingLeft: '29px' }}>
									<ListItemText
										primary={t.name}
										secondary={localizedLanguageRecord[t.language as LanguageOption]}
										style={{ paddingRight: '40px' }}
									/>
									<ListItemSecondaryAction>
										<Button
											variant='text'
											size='small'
											color='primary'
											disabled={isSubmitting}
											onClick={() => handleSubmit(t.id)}>
											{strings.select}
										</Button>
									</ListItemSecondaryAction>
								</ListItem>
								{isLast(t, filtered) === false && <Divider />}
							</div>
						))}
					</List>
				)}
				{filtered.length === 0 && <NoRecordsFound>{strings.noResultsCommaChangeFilterParams}</NoRecordsFound>}
			</DialogContent>
		</Dialog>
	);
};
