import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import React from 'react';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { FinancialsPageBreadcrumbTitle } from '../../FinancialsPageBreadcrumbTitle';
import { ITender } from 'gen/ApiClient';
import { TendersPageBreadcrump } from '../TendersPageBreadcrump';
import { TenderChips } from '../TenderChips';
import { TendersSettingsRoute } from '../../routes';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

interface IProps {
	item: ITender;
	reload: VoidFunction;
}

export const TenderDetailPageHeader = ({ item, reload }: IProps) => {
	return (
		<div
			className='df-col fg1'
			style={{ paddingBottom: 16 }}>
			<div className='df-row-ac'>
				<FinancialsPageBreadcrumbTitle />
				<PageBreadcrumbDivider />
				<TendersPageBreadcrump />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={item.reference} />
				<TenderChips
					item={item}
					style={{ marginLeft: 4 }}
				/>
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageSettingsButtonX route={TendersSettingsRoute} />
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
		</div>
	);
};
