import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { IAnnotation, ISortParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	sortParams: ISortParams<IAnnotation>;
	onFilter: (sp: ISortParams<IAnnotation>) => void;
}

export const OrderBySelect = ({ sortParams, onFilter }: IProps) => {
	const strings = useLocalization();

	return (
		<FormControl variant='outlined'>
			<Select
				value={`${sortParams.property};${sortParams.direction}`}
				onChange={e => {
					const splits = (e.target.value as string).split(';');
					onFilter({ property: splits[0] as keyof IAnnotation, direction: splits[1] as 'asc' | 'desc' });
				}}
				displayEmpty
				fullWidth
				SelectDisplayProps={{ style: { padding: '8px', paddingRight: '32px' } }}>
				<MenuItem value='created;desc'>{`${strings.sortOnWhat(`${strings.dateCreated} (${strings.newestFirst.toLowerCase()})`)}`}</MenuItem>
				<MenuItem value='created;asc'>{`${strings.sortOnWhat(`${strings.dateCreated} (${strings.oldestFirst.toLowerCase()})`)}`}</MenuItem>
				<MenuItem value='lastUpdated;desc'>{`${strings.sortOnWhat(`${strings.lastModifiedAt} (${strings.newestFirst.toLowerCase()})`)}`}</MenuItem>
				<MenuItem value='lastUpdated;asc'>{`${strings.sortOnWhat(`${strings.lastModifiedAt} (${strings.oldestFirst.toLowerCase()})`)}`}</MenuItem>
			</Select>
		</FormControl>
	);
};
