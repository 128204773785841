import { CardProps } from '@material-ui/core';
import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { CardX } from 'framework/components/cards/CardX';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { ExactOnlineSalePrefilters, IExactOnlineSalesQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps extends CardProps {
	params: IExactOnlineSalesQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IExactOnlineSalesQueryParams>>;
}

export const ExactOnlineSalesListFilterCard = ({ params, setParams, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const onFilter = (pars: IExactOnlineSalesQueryParams) => setParams(onResetToPage1(pars));
	const { exactOnlineSalesPrefilterRecord } = useContext(RecordContext);

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<div className='df-col'>
				{ExactOnlineSalePrefilters.map(prefilter => (
					<RadioIsSelectedButton
						key={prefilter}
						isSelected={prefilter === params.prefilter}
						onClick={() => onFilter({ ...params, prefilter: prefilter })}>
						{exactOnlineSalesPrefilterRecord[prefilter]}
					</RadioIsSelectedButton>
				))}
			</div>
		</CardX>
	);
};
