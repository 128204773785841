import { IReferenceSettings, IReferenceSettingsModel } from 'gen/ApiClient';
import { getNow } from './getNow';

export function calculateReference(val: IReferenceSettings | IReferenceSettingsModel) {
	const x = getNow();
	const fullYear = x.getFullYear().toString();
	const year = val.isShortYearFormat ? fullYear.substring(2) : fullYear;
	const suffix = '1'.padStart(val.countLeadingZeros, '0');
	let str = '';
	if (val.prefix) {
		str = str + val.prefix + val.separator;
	}
	if (val.withYear) {
		str = str + year + val.separator;
	}
	return str + suffix;
}
