import { Popover } from '@material-ui/core';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IProductCatalogItem, IProductCatalogItemSummary, IRecupelItemDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { RecupelCacheContext } from 'shared/context/RecupelCacheContext';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { useViewDocumentation } from 'shared/utils/useViewDocumentation';
import { LinkRecupelForm } from '../forms/LinkRecupelForm';

interface IProps {
	item: IProductCatalogItem | IProductCatalogItemSummary;
	reload?: VoidFunction;
	hideTitle?: boolean;
	label?: string;
}

export const RecupelDisplay = ({ item, reload, hideTitle, label }: IProps) => {
	const strings = useLocalization();
	const [data] = useCacheContext(RecupelCacheContext);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [anchor, openAnchor, closeAnchor] = useAnchorElement();
	const onViewRecupelTarifs = useViewDocumentation('recupel-tarifs');

	const onEdit = () => {
		open(
			<LinkRecupelForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<>
			{Boolean(anchor) && (
				<Popover
					open={Boolean(anchor)}
					anchorEl={anchor}
					onClose={closeAnchor}>
					<RecupelPopoverContent data={data?.find(t => t.code === item.recupelCode)} />
				</Popover>
			)}
			{hideTitle !== true && (
				<CardTitleTypography
					title='Recupel'
					withPaddingTop
				/>
			)}
			<LabelledProperty
				label={label ? label : strings.rate}
				property={item.hasRecupelCode ? formatCurrency(data?.find(t => t.code === item.recupelCode)?.currentPriceInVat ?? 0) : undefined}
				undefinedText={strings.noRateDefined}
				onClick={item.hasRecupelCode ? openAnchor : undefined}
				secondaryActionIcon={<FindInPageIcon />}
				secondaryAction={onViewRecupelTarifs}
				edit={reload ? onEdit : undefined}
			/>
		</>
	);
};

const RecupelPopoverContent = ({ data }: { data: IRecupelItemDto | undefined }) => {
	const strings = useLocalization();

	return (
		<div
			className='df-col'
			style={{ padding: 16 }}>
			<LabelledProperty
				label={strings.code}
				property={data?.code}
			/>
			<LabelledProperty
				label={strings.category}
				property={data?.weeeCategoryDescription}
			/>
			<LabelledProperty
				label={strings.subcategory}
				property={data?.subCategoryDescription}
			/>
			<LabelledProperty
				label={strings.rate}
				property={formatCurrency(data?.currentPriceInVat ?? 0)}
			/>
		</div>
	);
};
