import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { FabExtraSmall } from 'framework/components/buttons/FabExtraSmall';
import React from 'react';

interface IProps {
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	style?: React.CSSProperties;
	className?: string;
}

export const FabExtraSmallMoreButton = ({ style, ...props }: IProps) => {
	return (
		<FabExtraSmall
			style={{ boxShadow: 'none', ...style }}
			{...props}>
			<MoreHorizIcon fontSize='small' />
		</FabExtraSmall>
	);
};
