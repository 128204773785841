import React, { useState } from 'react';
import { SalesDetailPageViewContext } from './SalesDetailPageViewContext';
import { SalesDetailTabType } from './SalesDetailTabType';
import { createSalesDetailTabTypeRecord } from './utils';
import { useLocalization } from 'localization/useLocalization';

export const SalesDetailPageViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [selectedTab, setSelectedTab] = useState<SalesDetailTabType>('contents');
	const [salesId, setSalesId] = useState<string>('');
	const SalesDetailTabTypeRecord = React.useMemo(() => createSalesDetailTabTypeRecord(strings), [strings]);

	return (
		<SalesDetailPageViewContext.Provider
			value={{
				selectedTab: selectedTab,
				setSelectedTab: setSelectedTab,
				salesId: salesId,
				setSalesId: setSalesId,
				TabTypeRecord: SalesDetailTabTypeRecord,
			}}>
			{children}
		</SalesDetailPageViewContext.Provider>
	);
};
