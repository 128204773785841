import { ILabelCaptionValue } from 'framework/components/ILabelCaptionValue';
import React, { useMemo } from 'react';
import { typesToOptions } from '../utils/typesToOptions';
import { IFormProps } from './common/IFormProps';
import { FormSelectFieldWithCaption } from './FormSelectFieldWithCaption';

interface IProps<TModel, TType extends string> extends IFormProps<TModel> {
	record: Record<TType, string>;
	captionRecord: Record<TType, string>;
	withNone?: boolean;
}

export const FormSelectFieldWithCaptionFromRecord = <TModel extends unknown, TType extends string>({
	record,
	captionRecord,
	...props
}: IProps<TModel, TType>) => {
	const sorted = useMemo(() => typesToOptions(Object.keys(record) as TType[], record), [record]);
	const options = useMemo<ILabelCaptionValue<TType>[]>(
		() => sorted.map<ILabelCaptionValue<TType>>(t => ({ label: t.identifier, caption: captionRecord[t.id], value: t.id })),
		[sorted, captionRecord]
	);

	return (
		<FormSelectFieldWithCaption<TModel, TType>
			{...props}
			options={options}
			getKey={t => t}
		/>
	);
};
