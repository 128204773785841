import { IProductCatalogItem } from 'gen/ApiClient';

interface ILine {
	productCatalogItem: IProductCatalogItem | undefined;
	inventoryItemId: string | undefined;
}

export function mustLinkAnInventoryItem(line: ILine): boolean {
	if (line.productCatalogItem && line.productCatalogItem.isUniquelyIdentifiable && line.inventoryItemId === undefined) {
		return true;
	} else {
		return false;
	}
}
