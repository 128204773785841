import React, { useState } from 'react';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import {
	IInventoryItemsQueryParamsForLoan,
	inventoryItemsQuery_queryForLoan,
	IStockItemsQueryParamsForLoan,
	stockItemsQuery_queryForLoan,
} from 'gen/ApiClient';
import { DefaultInventoryItemsQueryParamsForLoan } from 'shared/searchAndSelect/DefaultInventoryItemsQueryParamsForLoan';
import { SearchContext } from './SearchContext';

const DefaultPcParams: IStockItemsQueryParamsForLoan = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	categories: [],
	subcategories: [],
	types: [],
};

interface IProps {
	children: any;
}

export const SearchProvider = ({ children }: IProps) => {
	const [pcParams, setPcParams] = useState<IStockItemsQueryParamsForLoan>(DefaultPcParams);
	const [pcQueryResult] = useApiEffect(stockItemsQuery_queryForLoan, pcParams);

	const [iiParams, setIIParams] = useState<IInventoryItemsQueryParamsForLoan>(DefaultInventoryItemsQueryParamsForLoan);
	const [iiQueryResult] = useApiEffect(inventoryItemsQuery_queryForLoan, iiParams);

	return (
		<SearchContext.Provider
			value={{
				pcParams: pcParams,
				pcQueryResult: pcQueryResult,
				setPcParams: setPcParams,
				iiParams: iiParams,
				iiQueryResult: iiQueryResult,
				setIIParams: setIIParams,
			}}>
			{children}
		</SearchContext.Provider>
	);
};
