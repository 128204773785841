import { Collapse } from '@material-ui/core';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { useLoggedInUserIsSuperUserOrHasClientClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasClientClaim';
import { SalesAnalysisRoute, SalesSettingsRoute } from 'app/main/financial/routes';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { FunctionVariantIcon } from 'framework/components/icons/FunctionVariantIcon';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { PageStatsButtonX } from 'framework/components/layout/PageStatsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { IQueryResultWithMetadata, ISale, ISalesQueryMetadata, ISalesQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SalesPageBreadcrumb } from '../SalesPageBreadcrumb';
import { SalesScriptsDialog } from '../scripts/SalesScriptsDialog';
import { SalesListStats } from './SalesListStats';

interface IProps {
	reload: VoidFunction;
	queryResult: IQueryResultWithMetadata<ISale, ISalesQueryMetadata>;
	params: ISalesQueryParams;
	setParams: React.Dispatch<React.SetStateAction<ISalesQueryParams>>;
	style?: React.CSSProperties;
}

export const SalesListPageHeader = ({ reload, queryResult, params, setParams, style }: IProps) => {
	const strings = useLocalization();
	const [showStats, toggleShowStats] = useStateToggleBoolean(true);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const userIsSuperUser = useLoggedInUserIsSuperUser();
	const canViewSalesAnalysis = useLoggedInUserIsSuperUserOrHasClientClaim('vsa');
	const { push } = useHistory();

	const onScript = () => {
		if (queryResult && params) {
			open(
				<SalesScriptsDialog
					open
					queryResult={queryResult}
					params={params}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	return (
		<div
			className='df-col fg1'
			style={style}>
			<div className='df-row-ac'>
				<SalesPageBreadcrumb />
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageStatsButtonX
						showStats={showStats}
						toggleShowStats={toggleShowStats}
					/>
					{userIsSuperUser && (
						<TooltipWithSpan
							title={`Execute custom scripts`}
							style={{ marginTop: '-12px', marginBottom: '-12px' }}>
							<ColorOnHoverIconButton
								onClick={onScript}
								size='small'
								edge='end'>
								<FunctionVariantIcon />
							</ColorOnHoverIconButton>
						</TooltipWithSpan>
					)}
					{canViewSalesAnalysis && (
						<TooltipWithSpan
							title={strings.analysis}
							style={{ marginTop: '-12px', marginBottom: '-12px' }}>
							<ColorOnHoverIconButton
								onClick={() => push(SalesAnalysisRoute)}
								edge='end'
								size='small'>
								<TimelineOutlinedIcon />
							</ColorOnHoverIconButton>
						</TooltipWithSpan>
					)}
					<PageSettingsButtonX route={SalesSettingsRoute} />
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<Collapse in={showStats}>
				<SalesListStats
					metadata={queryResult.metadata}
					params={params}
					setParams={setParams}
					style={{ paddingTop: 16 }}
				/>
			</Collapse>
		</div>
	);
};
