import { IHeader } from 'framework/table/IHeader';
import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { formatDate } from 'framework/utils/date/formatDate';
import { IStockEntryDto } from 'gen/ApiClient';
import React from 'react';
import { SelectableColumn } from '../SelectableColumn';
import { TextWithChange } from '../TextWithChange';

export const createHeaders = (colRecord: Record<SelectableColumn, string>): Record<SelectableColumn, IHeader<IStockEntryDto>> => {
	return {
		date: createSortableHeaderWithKey<IStockEntryDto, SelectableColumn>(colRecord, 'date', s => formatDate(s.date, 'date-and-time')),
		countInBackOrderChange: createHeaderWithKey<IStockEntryDto, SelectableColumn>(colRecord, 'countInBackOrderChange', t => (
			<TextWithChange change={t.countInBackOrderChange} />
		)),
		countInTestOrLoanChange: createHeaderWithKey<IStockEntryDto, SelectableColumn>(colRecord, 'countInTestOrLoanChange', t => (
			<TextWithChange change={t.countInTestOrLoanChange} />
		)),
		countInStockChange: createHeaderWithKey<IStockEntryDto, SelectableColumn>(colRecord, 'countInStockChange', t => (
			<TextWithChange change={t.countInStockChange} />
		)),
		countInRepairChange: createHeaderWithKey<IStockEntryDto, SelectableColumn>(colRecord, 'countInRepairChange', t => (
			<TextWithChange change={t.countInRepairChange} />
		)),
		stockValueChange: createHeaderWithKey<IStockEntryDto, SelectableColumn>(colRecord, 'stockValueChange', t => (
			<TextWithChange
				change={t.stockValueChange}
				isCurrency
			/>
		)),
	};
};
