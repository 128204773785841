import { Button, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { DialogX } from 'framework/dialogs/DialogX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { useAccordionState } from 'framework/utils/useAccordionState';
import {
	IExactOnlineGLAccountDto,
	IExactOnlineProductCatalogItemDto,
	exactOnlineGLAccountsQuery_all,
	exactOnlineProductCatalogItemsCommand_link,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';
import { ExactOnlineGLAccountListItemCardX } from './ExactOnlineGLAccountListItemCardX';

const filterCrmAccounts = (crmAccounts: IExactOnlineGLAccountDto[], search: string | undefined) => {
	if (search === undefined) {
		return crmAccounts;
	} else {
		return crmAccounts.filter(t => `${t.code ?? ''} ${t.description ?? ''}`.toLowerCase().includes(search.toLowerCase()));
	}
};

interface IProps extends DialogProps {
	item: IExactOnlineProductCatalogItemDto;
	cancel: VoidFunction;
	confirm: () => void;
}

export const LinkExactOnlineProductCatalogItemWithExactOnlineGLAccountDialog = ({ item, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const [glAccounts] = useApiEffect(exactOnlineGLAccountsQuery_all);
	const [searchString, setSearchString] = useState<string>('');
	const fGLAccounts = useMemo(() => filterCrmAccounts(glAccounts ?? [], searchString), [glAccounts, searchString]);
	const [selectedGLAccount, setSelectedGLAccount] = useAccordionState<IExactOnlineGLAccountDto | undefined>();
	const [submit] = useFormSubmit(exactOnlineProductCatalogItemsCommand_link);
	const notify = useSnackbarNotify();

	useEffect(() => {
		setSelectedGLAccount(undefined);
		// eslint-disable-next-line
	}, [searchString]);

	const onSubmit = async () => {
		if (selectedGLAccount === undefined) {
			return;
		}

		const r = await submit({ productCatalogItemId: item.id, glAccountId: selectedGLAccount.id });
		if (r.isFailure) {
			notify(r.error);
		} else {
			confirm();
		}
	};

	return (
		<DialogX
			{...rest}
			open={rest.open}
			fullHeight>
			<DialogTitle>
				<div className='df-col gap-8'>
					<span>{strings.linkExactOnlineGLAccountForWhat(item.identifier!)}</span>
					<SearchbarX
						placeholder={`${strings.searchOnNameSlashCode}...`}
						onSearch={setSearchString}
						value={searchString}
					/>
				</div>
			</DialogTitle>
			<DialogContent
				className='df-col gap-8'
				dividers>
				{fGLAccounts.map(t => (
					<ExactOnlineGLAccountListItemCardX
						key={t.id}
						item={t}
						onSelect={() => setSelectedGLAccount(t)}
						isSelected={t.id === selectedGLAccount?.id}
					/>
				))}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button
					onClick={onSubmit}
					color='primary'
					disabled={selectedGLAccount === undefined}>
					{strings.proceed}
				</Button>
			</DialogActions>
		</DialogX>
	);
};
