import { IInventoryItem } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

interface ILine {
	inventoryItem: IInventoryItem | undefined;
}

export const calculateReservedForWarning = (line: ILine, strings: IStrings): string | undefined => {
	if (line.inventoryItem !== undefined && line.inventoryItem.isReserved) {
		return strings.articleReservedForWho(line.inventoryItem.reservedForPatientIdentifier!);
	} else {
		return undefined;
	}
};
