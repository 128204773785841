import { Dialog, DialogActions, DialogProps, Divider, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import HearingIcon from '@material-ui/icons/Hearing';
import LinkIcon from '@material-ui/icons/Link';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IRizivHearingAidDefinitionV2Dto, rizivHearingAidDefinitionsV2Query_identifyAndConvertToV2 } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';

interface IProps extends DialogProps {
	identificationCode: number | string;
	select?: (item: IRizivHearingAidDefinitionV2Dto) => Promise<void>;
	cancel: VoidFunction;
	clear?: () => Promise<void>;
}

export const RizivRefundableHearingInstrumentDialog = ({ identificationCode, select, cancel, clear, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [item] = useApiEffect(rizivHearingAidDefinitionsV2Query_identifyAndConvertToV2, identificationCode.toString());
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	if (item === undefined) {
		return <div></div>;
	}

	const onSelect = async () => {
		if (select) {
			setIsSubmitting(true);
			await select(item);
			setIsSubmitting(false);
		}
	};

	const onClear = async () => {
		if (clear) {
			setIsSubmitting(true);
			await clear();
			setIsSubmitting(false);
		}
	};

	return (
		<Dialog
			{...rest}
			onClose={cancel}
			fullWidth>
			<DialogContentGrid close={cancel}>
				<DialogGridTitle icon={<HearingIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{item.id}
					</Typography>
					<LabelledProperty
						label={strings.validityFrom}
						property={formatDate(item.validFrom)}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.validityTo}
						property={item.validTo ? formatDate(item.validTo) : strings.noEndDate}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.lastUpdated}
						property={item.updatedOn ? formatDate(item.updatedOn) : strings.notApplicableAbbreviation}
						withoutMargin
					/>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.generalData} />
				<PropertyInGrid
					label={strings.manufacturer}
					value={item.name}
				/>
				<PropertyInGrid
					label={strings.name}
					value={item.name}
				/>
				<PropertyInGrid
					label={strings.distributor}
					value={item.distributor}
				/>
				<PropertyInGrid
					label={strings.reference}
					value={item.reference}
				/>
				<PropertyInGrid
					label={strings.originalIdentificationCode}
					value={item.originalIdentificationCode?.toString() ?? strings.notApplicableAbbreviation}
				/>
				<GridDividerWithCaption caption={strings.pricing} />
				<PropertyInGrid
					label={strings.listPrice}
					value={item.publicPrice ? formatCurrency(item.publicPrice) : strings.notApplicableAbbreviation}
				/>
				<PropertyInGrid
					label={strings.refund}
					value={item.reimbursement ? formatCurrency(item.reimbursement) : strings.notApplicableAbbreviation}
				/>
				<PropertyInGrid
					label={strings.supplement}
					value={item.supplement ? formatCurrency(item.supplement) : strings.notApplicableAbbreviation}
				/>
				<PropertyInGrid
					label={`${strings.supplement} (code)`}
					value={item.supplementCode ?? strings.notApplicableAbbreviation}
				/>
				<GridDividerWithCaption caption={strings.technicalData} />
				<PropertyInGrid
					label={strings.distortion}
					value={item.distortion}
				/>
				<PropertyInGrid
					label={strings.frequencyRange}
					value={item.frequencyRange}
				/>
				<PropertyInGrid
					label={`Technology Level`}
					value={item.technologyLevel?.toString() ?? strings.notApplicableAbbreviation}
				/>
			</DialogContentGrid>
			{(select !== undefined || clear !== undefined) && (
				<>
					<Divider style={{ marginLeft: theme.spacing(4), marginRight: theme.spacing(4) }} />
					<DialogActions>
						{select && (
							<LoaderButton
								variant='text'
								color='primary'
								startIcon={<LinkIcon />}
								isLoading={isSubmitting ?? false}
								onClick={onSelect}>
								{strings.selectThis}
							</LoaderButton>
						)}
						{clear && (
							<LoaderButton
								variant='text'
								color='secondary'
								startIcon={<DeleteOutlineIcon />}
								isLoading={isSubmitting ?? false}
								onClick={onClear}>
								{strings.removeThis}
							</LoaderButton>
						)}
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};
