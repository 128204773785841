import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteFreeSoloTextField } from 'framework/forms/FormAutocompleteFreeSoloTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatchProductCatalogItemCategoriesRequest, IProductCatalogItem, productCatalogCommand_patchCategories } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';
import { ProductCatalogFilterDataContext } from '../context/ProductCatalogFilterDataContext';

interface IProps extends DialogProps {
	productCatalogItem: IProductCatalogItem;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchProductCatalogItemCategoriesRequest>({
		category: yup.string(),
		subcategory: yup.string(),
		productCatalogItemId: yup.string().required(),
	});
};

export const CategoriesForm = ({ productCatalogItem, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [data] = useCacheContext(ProductCatalogFilterDataContext);
	const [update, isSubmitting] = useFormSubmit(productCatalogCommand_patchCategories);

	const handleSubmit = async (values: IPatchProductCatalogItemCategoriesRequest, helpers: FormikHelpers<IPatchProductCatalogItemCategoriesRequest>) => {
		const updateResult = await update(values);
		if (handleFormResponse(updateResult, helpers)) {
			notify(strings.productUpdated);
			confirm();
		}
	};
	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IPatchProductCatalogItemCategoriesRequest>
			validateOnMount
			validationSchema={createSchema(strings)}
			initialValues={{
				category: productCatalogItem.category ?? '',
				subcategory: productCatalogItem.subcategory ?? '',
				productCatalogItemId: productCatalogItem.id,
			}}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.categories}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'
						style={{ overflow: 'visible' }}>
						<FormAutocompleteFreeSoloTextField<IPatchProductCatalogItemCategoriesRequest>
							name='category'
							label={strings.category}
							options={data.categories}
						/>
						<FormAutocompleteFreeSoloTextField<IPatchProductCatalogItemCategoriesRequest>
							name='subcategory'
							label={strings.subcategory}
							options={data.subcategories}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.update}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
