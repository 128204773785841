import React from 'react';
import { IUserSummary, usersCommand_patchCalendarColor } from 'gen/ApiClient';
import { ColorPickerWrapper as SharedColorPickerWrapper } from 'shared/color/ColorPickerWrapper';
import { DefaultCalendarColor } from '../../DefaultCalendarColor';

interface IProps {
	user: IUserSummary;
	reload: VoidFunction;
	circleSize?: number;
	width?: string | undefined;
}

export const UserColorPickerWrapper = ({ user, reload, circleSize, width }: IProps) => {
	return (
		<SharedColorPickerWrapper
			initialColor={user.calendarColor ?? DefaultCalendarColor}
			submitF={color => usersCommand_patchCalendarColor({ userId: user.id, color: color })}
			reload={reload}
			circleSize={circleSize}
			width={width}
		/>
	);
};
