import React from 'react';
import { CalendarSwitch } from './CalendarSwitch';
import { RosaCalendarPageSwitch } from '../rosaCalendar/RosaCalendarPageSwitch';
import { useCalendarMode } from './useCalendarMode';
import { RosaNotEnabledPage } from '../rosaCalendar/RosaNotEnabledPage';

export const CalendarModePageSwitch = () => {
	const calendarMode = useCalendarMode();

	return (
		<React.Fragment>
			{calendarMode === 'Rosa' && <RosaCalendarPageSwitch />}
			{calendarMode === 'Hams' && <CalendarSwitch />}
			{calendarMode === 'NotSet' && <RosaNotEnabledPage />}
		</React.Fragment>
	);
};
