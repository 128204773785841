import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { isFiltered } from 'framework/utils/isFiltered';
import { CreditedStatus, CreditedStatuses, IPurchasesForSupplierQueryParams } from 'gen/ApiClient';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { RecordContext } from 'shared/records/RecordContext';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	params: IPurchasesForSupplierQueryParams;
	onFilter: (params: IPurchasesForSupplierQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof IPurchasesForSupplierQueryParams)[] = [];

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const { creditedStatusRecord } = useContext(RecordContext);
	const strings = useLocalization();

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.purchaseDate}
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<DateFilterItem
				label={strings.toPayBeforeDate}
				after={params.validUntilAfter}
				before={params.validUntilBefore}
				setSelected={(after, before) => onFilter({ ...params, validUntilAfter: after, validUntilBefore: before })}
			/>
			<DateFilterItem
				label={strings.referenceDate}
				after={params.purchaseReferenceDateAfter}
				before={params.purchaseReferenceDateBefore}
				setSelected={(after, before) => onFilter({ ...params, purchaseReferenceDateAfter: after, purchaseReferenceDateBefore: before })}
			/>
			<RecordListFilterComponent<CreditedStatus>
				record={creditedStatusRecord}
				options={CreditedStatuses}
				selected={params.creditedStatuses as any}
				setSelected={vals => onFilter({ ...params, creditedStatuses: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.creditStatus}
			/>
		</FilterBar>
	);
};
