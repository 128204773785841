import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { EntPhysiciansCacheContext } from 'app/main/contacts/earNoseThroatPhysicians/EntPhysiciansCacheContext';
import { LocationsPreloadCacheContext } from 'app/main/settings/locations/LocationsPreloadCacheContext';
import { useUsersAsFilterOptions } from 'app/main/settings/users/useUsersAsFilterOptions';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IRfiasQueryParams, RfiaInvoiceProcessStatuses, RfiaPaymentStatuses, RfiaStatus, RfiaStatuses, RfiaWarning, RfiaWarnings } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { FilterDataContext } from './context/FilterDataContext';
import { ViewContext } from './context/ViewContext';

interface IProps {
	params: IRfiasQueryParams;
	onFilter: (params: IRfiasQueryParams) => void;
	paddingLeft: number;
}

// TODO fix this, is this correct?
const exclusions: (keyof IRfiasQueryParams)[] = ['hasWarning', 'isCompleted'];

// TODO translate
export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const { rfiaWarningRecord } = useContext(RecordContext);
	const { statusRecord, invoiceProcessStatusRecord, paymentStatusRecord, statusOrderRecord } = useContext(ViewContext);
	const [entPhysicians] = usePreloadCacheContext(EntPhysiciansCacheContext);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const filterUsers = useUsersAsFilterOptions();
	const [filterData] = useCacheContext(FilterDataContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<RecordListFilterComponent<RfiaStatus>
				record={statusRecord}
				options={RfiaStatuses}
				selected={params.statuses as any}
				setSelected={vals => onFilter({ ...params, statuses: vals })}
				icon={<HelpOutlineIcon />}
				orderRecord={statusOrderRecord}
				label={strings.status}
			/>
			<RecordListFilterComponent<RfiaWarning>
				record={rfiaWarningRecord}
				options={RfiaWarnings}
				selected={params.warnings as any}
				setSelected={vals => onFilter({ ...params, warnings: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.warning}
			/>
			{filterData && (
				<StringListFilterComponent
					options={filterData.labels}
					selected={params.labels}
					setSelected={vals => onFilter({ ...params, labels: vals })}
					label={strings.labels}
					icon={<HelpOutlineIcon />}
				/>
			)}
			<RecordListFilterComponent<string>
				options={RfiaInvoiceProcessStatuses}
				record={invoiceProcessStatusRecord}
				selected={params.invoiceProcessStatuses}
				setSelected={vals => onFilter({ ...params, invoiceProcessStatuses: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.salesStatus}
			/>
			<RecordListFilterComponent<string>
				options={RfiaPaymentStatuses}
				record={paymentStatusRecord}
				selected={params.paymentStatuses}
				setSelected={vals => onFilter({ ...params, paymentStatuses: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.paymentStatus}
			/>
			<DateFilterItem
				label={strings.dateCreated}
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<DateFilterItem
				label={strings.prescriptionForTrialDate}
				after={params.prescriptionForTrialDateAfter}
				before={params.prescriptionForTrialDateBefore}
				setSelected={(after, before) => onFilter({ ...params, prescriptionForTrialDateAfter: after, prescriptionForTrialDateBefore: before })}
			/>
			<DateFilterItem
				label={strings.handoverDate}
				after={params.handoverDateAfter}
				before={params.handoverDateBefore}
				setSelected={(after, before) => onFilter({ ...params, handoverDateAfter: after, handoverDateBefore: before })}
			/>
			<BooleanRadioFilterComponent
				trueText={strings.negative}
				falseText={strings.positive}
				selected={params.isFailedTrial}
				setSelected={val => onFilter({ ...params, isFailedTrial: val })}
				label={strings.evaluation}
				icon={<HelpOutlineIcon />}
			/>
			<BooleanRadioFilterComponent
				trueText={strings.withWhat(strings.missingDocuments)}
				falseText={strings.withoutWhat(strings.missingDocuments)}
				selected={params.hasMissingDocuments}
				setSelected={val => onFilter({ ...params, hasMissingDocuments: val })}
				label={strings.documents}
				icon={<HelpOutlineIcon />}
			/>
			<ListFilterComponent<string>
				options={filterUsers}
				selected={params.audiologistIds}
				setSelected={vals => onFilter({ ...params, audiologistIds: vals })}
				label={`${strings.user}/${strings.audiologist}`}
				icon={<HelpOutlineIcon />}
				title={`${strings.user}/${strings.audiologist}`}
			/>
			<ListFilterComponent<string>
				options={entPhysicians}
				selected={params.entPhysicianIds}
				setSelected={vals => onFilter({ ...params, entPhysicianIds: vals })}
				label={strings.entPhysicianShort}
				icon={<HelpOutlineIcon />}
				title={strings.entPhysicianShort}
			/>
			<ListFilterComponent<string>
				options={locations}
				selected={params.locationIds}
				setSelected={vals => onFilter({ ...params, locationIds: vals })}
				label={strings.location}
				icon={<HelpOutlineIcon />}
				title={strings.location}
			/>
			<DateFilterItem
				label={`Datum verstuurd naar NKO`}
				after={params.medicalPrescriptionSentDateAfter}
				before={params.medicalPrescriptionSentDateBefore}
				setSelected={(after, before) => onFilter({ ...params, medicalPrescriptionSentDateAfter: after, medicalPrescriptionSentDateBefore: before })}
			/>
		</FilterBar>
	);
};
