import { NoahDeviceReadOutStatus } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createStatusRecord = (strings: IStrings): Record<NoahDeviceReadOutStatus, string> => {
	return {
		Ignored: strings.ignored,
		LinkedToMultiple: strings.linkedToMultiple,
		LinkedToSingle: strings.linked,
		NotLinked: strings.notLinked,
	};
};
