import { IStrings } from 'localization/IStrings';
import { QuickFilter } from '../RfiasListPage';

export const createQfRecord = (strings: IStrings): Record<QuickFilter, string> => {
	return {
		all: strings.all,
		running: strings.notCompleted,
		warning: strings.withWhat(strings.warning),
	};
};
