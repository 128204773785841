import { StatCardX } from 'framework/components/cards/StatCardX';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ICustomerAccountQueryMetadata } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const Width: number = 250;

interface IProps {
	metadata: ICustomerAccountQueryMetadata;
	style?: React.CSSProperties;
}

export const CustomerAccountsStats = ({ metadata, style }: IProps) => {
	const strings = useLocalization();

	return (
		<div
			className='df-row-ac gap-16'
			style={style}>
			<StatCardX
				style={{ width: Width, marginRight: 16 }}
				title={strings.outstandingBalance}
				caption={strings.quantity}>
				<span style={{ fontSize: 24 }}>{metadata.countWithOutstandingBalance.toString()}</span>
			</StatCardX>
			<StatCardX
				style={{ width: Width, marginRight: 16 }}
				title={strings.outstandingBalance}
				caption={strings.totalAmount}>
				<span style={{ fontSize: 24 }}>{formatCurrency(metadata.totalOutstandingBalance)}</span>
			</StatCardX>
		</div>
	);
};
