import { Card, CardContent, CardProps } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { combineClassNames } from 'framework/utils/combineClassNames';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CalendarRoute } from 'routes';

export const RosaPatientEventsNotConfiguredYetCard = ({ ...props }: CardProps) => {
	const strings = useLocalization();
	const { push } = useHistory();

	return (
		<Card
			{...props}
			className={combineClassNames('fg1', props.className)}>
			<CardContent className='df-col gap-8'>
				<CardTitleTypography title={strings.appointments} />
				<VariantLabelledPropertyX
					variant='warning'
					link={() => push(CalendarRoute)}>
					{strings.rosaNotConfiguredYet}
				</VariantLabelledPropertyX>
			</CardContent>
		</Card>
	);
};
