import { formatDateX } from 'framework/utils/date/formatDateX';
import { IRosaEventDto } from 'gen/ApiClient';
import _ from 'lodash';
import { IRosaEventsGroup } from './IRosaEventsGroup';

export const groupRosaEventsByDate = (events: IRosaEventDto[]): IRosaEventsGroup[] => {
	const grouped = _.groupBy(events, t => formatDateX(t.startsAt));
	const keys = Object.keys(grouped);
	const groups = keys.map<IRosaEventsGroup>(t => ({ date: new Date(grouped[t][0].startsAt), events: grouped[t] }));
	return _.orderBy(groups, t => new Date(t.date));
};
