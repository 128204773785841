import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import React from 'react';
import { TooltipWithSpan } from '../TooltipWithSpan';

interface IProps {
	showStats: boolean;
	toggleShowStats: VoidFunction;
	style?: React.CSSProperties;
}

export const PageStatsButtonX = ({ showStats, toggleShowStats, style }: IProps) => {
	return (
		<TooltipWithSpan title={showStats === true ? `Hide stats` : `Show stats`}>
			<ColorOnHoverIconButton
				edge='end'
				size='small'
				onClick={toggleShowStats}
				color='primary'
				style={style}>
				<EqualizerOutlinedIcon />
				<div style={{ position: 'absolute', left: -10 }}>
					{showStats === true && <ArrowDropDownIcon fontSize='small' />}
					{showStats === false && <ArrowDropUpIcon fontSize='small' />}
				</div>
			</ColorOnHoverIconButton>
		</TooltipWithSpan>
	);
};
