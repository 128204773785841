import { CardProps, Divider } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FolderIcon from '@material-ui/icons/Folder';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import StoreIcon from '@material-ui/icons/Store';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ISuppliersFilterData, ISuppliersQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';

interface IProps extends CardProps {
	params: ISuppliersQueryParams;
	setParams: React.Dispatch<React.SetStateAction<ISuppliersQueryParams>>;
	searchData: ISuppliersFilterData;
}

const exclusions: (keyof ISuppliersQueryParams)[] = ['showDeleted'];

export const SuppliersListFilterCard = ({ params, setParams, searchData, ...rest }: IProps) => {
	const strings = useLocalization();
	const isFilteredX = useMemo(() => isFiltered(params, exclusions), [params]);

	const onFilter = (pars: ISuppliersQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params, exclusions)));

	return (
		<CardX
			className='df-col gap-8'
			{...rest}>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<BooleanRadioFilterComponent
					label={strings.deliveries}
					trueText={strings.hasDeliveries}
					falseText={strings.hasNoDeliveries}
					selected={params.hasDeliveries}
					setSelected={value => onFilter({ ...params, hasDeliveries: value })}
					icon={<LocalShippingIcon />}
				/>
				<BooleanRadioFilterComponent
					label={strings.orders}
					trueText={strings.hasOrders}
					falseText={strings.hasNoOrders}
					selected={params.hasOrders}
					setSelected={value => onFilter({ ...params, hasOrders: value })}
					icon={<ShoppingBasketIcon />}
				/>
				<BooleanRadioFilterComponent
					label={strings.purchases}
					trueText={strings.hasPurchases}
					falseText={strings.hasNoPurchases}
					selected={params.hasPurchases}
					setSelected={value => onFilter({ ...params, hasPurchases: value })}
					icon={<StoreIcon />}
				/>
				<DateFilterItem
					label={strings.dateCreated}
					after={params.createdAfter}
					before={params.createdBefore}
					setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
					icon={<DateRangeIcon />}
				/>
				<StringListFilterComponent
					label={strings.brands}
					selected={params.brands}
					options={searchData.brands}
					icon={<FolderIcon />}
					setSelected={value => onFilter({ ...params, brands: value })}
				/>
			</div>
			<Divider />
			<FormControlLabelSwitch
				isChecked={params.showDeleted}
				toggle={() => onFilter({ ...params, showDeleted: !params.showDeleted })}
				label={strings.alsoShowDeleted}
			/>
		</CardX>
	);
};
