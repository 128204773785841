import React from 'react';
import { CompaniesCacheProvider } from './CompaniesCacheProvider';
import { CompaniesQueryProvider } from './CompaniesQueryProvider';

export const CompaniesProviders = ({ children }: any) => {
	return (
		<CompaniesQueryProvider>
			<CompaniesCacheProvider>{children}</CompaniesCacheProvider>
		</CompaniesQueryProvider>
	);
};
