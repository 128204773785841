import React from 'react';
import { IOption } from '../IOption';
import { ListFilterComponent } from './ListFilterComponent';

interface IProps {
	options: string[];
	selected: string[];
	setSelected: (selected: string[]) => void;
	label: string;
	icon: React.ReactNode;
}

export const StringListFilterComponent = ({ options, selected, setSelected, label, icon }: IProps) => {
	return (
		<ListFilterComponent<string>
			options={options.map<IOption<string>>(t => ({ identifier: t, id: t }))}
			selected={selected}
			setSelected={setSelected}
			label={label}
			icon={icon}
		/>
	);
};
