import { DialogProps } from '@material-ui/core';
import React from 'react';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { documentsCommand_updateName, IDocument, IDocumentSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	data: IDocument | IDocumentSummary;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const DocumentNameForm = ({ data, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<StringModelForm
			{...rest}
			fullWidth
			cancel={cancel}
			confirm={confirm}
			initialValue={data.userDefinedName}
			submitFunction={str => documentsCommand_updateName(data.id, str)}
			formTitle={strings.changeWhat(strings.name)}
			label={strings.name}
			submitText={strings.change}
		/>
	);
};
