import { Checkbox, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { RoundedSquareX } from 'framework/components/RoundedSquareX';
import React from 'react';

interface IProps {
	onClick: VoidFunction;
	indeterminate?: boolean;
	isSelected: boolean;
	text: string | React.ReactNode;
	color: string;
	showPrimaryColor: boolean;
	showSecondaryColor: boolean;
}

export const CalendarFilterListItemComponent = ({ onClick, indeterminate, isSelected, color, showPrimaryColor, showSecondaryColor, text }: IProps) => {
	return (
		<ListItem
			dense
			button
			disableGutters
			onClick={onClick}
			style={{ paddingLeft: 14, paddingRight: 16 }}>
			<ListItemIcon style={{ minWidth: 30 }}>
				<Checkbox
					indeterminate={indeterminate}
					checked={isSelected}
					color='default'
					style={{ padding: 0, color: showPrimaryColor ? color : 'inherit' }}
				/>
			</ListItemIcon>
			<ListItemText primary={text} />
			{showSecondaryColor && (
				<ListItemSecondaryAction>
					<RoundedSquareX
						color={`${color}90`}
						size={16}
					/>
				</ListItemSecondaryAction>
			)}
		</ListItem>
	);
};
