import { IHandoverLine } from '../IHandoverLine';

export function calculateHasRizivCodeWarning(line: IHandoverLine): boolean {
	if (
		line.inventoryItem &&
		line.isIncludeOnHandoverCertificate === true &&
		line.inventoryItem.canLinkRizivRefundableHearingInstrument &&
		line.rizivHearingAidDefinitionV2IdentificationCode === undefined
	) {
		return true;
	} else {
		return false;
	}
}
