import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import { calcCustomerAccountDetailRoute } from 'app/main/financial/routes';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { createType } from 'framework/utils/createType';
import {
	CustomerAccountExportProp,
	CustomerAccountExportProps,
	ICustomerAccount,
	ICustomerAccountsFilterParams,
	customerAccountsQuery_export,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsNavigateHeader } from 'shared/table/createDetailsNavigateHeader';
import { CustomerAccountStatusChip } from './CustomerAccountStatusChip';
import { CustomerAccountsListHeader } from './CustomerAccountsListHeader';
import { Filter } from './Filter';
import { SelectableColumn } from './SelectableColumn';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';

const QuickFilters = createType('all', 'withOutstandingBalance', 'withOutstandingBalanceNotOnSettlementProposals');
export type QuickFilter = typeof QuickFilters[number];

const prependColumn = createEmptyHeader<ICustomerAccount>('prepend', t => <CustomerAccountStatusChip ca={t} />);

export const CustomerAccountsList = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(QueryContext);
	const strings = useLocalization();
	const theme = useTheme();
	const { columns, setColumns, columnsRecord, headers, quickFilter, setQuickFilter, quickFilterRecord, exportRecord } = useContext(ViewContext);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='CustomerAccounts'
			stickyHeader={
				<CustomerAccountsListHeader
					metadata={queryResult.metadata}
					reload={reload}
					style={{ paddingBottom: 16 }}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16 }}>
				<div className='df-row-ac jc-sb'>
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchVerb}
						style={{ width: '400px' }}
						variant='paper'
					/>
				</div>
				{queryResult.unfilteredCount > 0 && (
					<>
						<Card>
							<div
								className='df-row-ac jc-sb'
								style={{ padding: theme.spacing(1) }}>
								<ButtonGroupWithSelection<QuickFilter>
									options={QuickFilters}
									selected={quickFilter}
									setSelected={setQuickFilter}
									renderOption={t => quickFilterRecord[t]}
								/>
								<div>
									<ExportButtonMenuAndDialog<ICustomerAccountsFilterParams, CustomerAccountExportProp>
										params={params}
										filteredCount={queryResult.filteredCount}
										unfilteredCount={queryResult.unfilteredCount}
										allProps={CustomerAccountExportProps}
										downloadF={customerAccountsQuery_export}
										fileName={strings.accountsReceivable}
										record={exportRecord}
										view='customerAccounts'
									/>
									<SelectColumnsButtonAndDialog
										selectedColumns={columns}
										setSelectedColumns={setColumns}
										record={columnsRecord}
										view='customerAccounts'
									/>
								</div>
							</div>
							<Divider />
							<div className='df-row jc-sb'>
								<Filter
									params={params}
									onFilter={params => setParams(onResetToPage1(params))}
									paddingLeft={theme.spacing(1.5)}
								/>
							</div>
							<Divider />
							<CardMedia>
								<PageableTableWithColumnSelection<ICustomerAccount, SelectableColumn>
									queryResult={queryResult}
									headers={headers}
									sortParams={params.sortParams}
									pageParams={params.pageParams}
									setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
									getKey={inst => inst.id}
									selectedColumns={columns}
									prependColumn={prependColumn}
									appendColumn={createDetailsNavigateHeader<ICustomerAccount>(calcCustomerAccountDetailRoute)}
								/>
							</CardMedia>
						</Card>
					</>
				)}
				{queryResult.unfilteredCount === 0 && <NoRecordsFound>Nog geen customer accounts geregistreerd</NoRecordsFound>}
			</div>
		</RestrictedPageX>
	);
};
