import React from 'react';
import { IDocumentSummary } from 'gen/ApiClient';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DocumentDetailDialog } from './DocumentDetailDialog';
import { DocumentListItem } from './DocumentListItem';
import { EmptyDocumentListItem, IPlaceholderDocumentListItemProps } from './EmptyDocumentListItem';

interface IDocumentOrPlaceholder extends IPlaceholderDocumentListItemProps {
	document: IDocumentSummary | undefined;
	reload: VoidFunction;
	onViewDetail?: (id: string) => void;
	children?: any;
	appendTitle?: string;
}

export const DocumentListItemOrEmpty = ({ document, reload, onViewDetail, children, appendTitle, ...rest }: IDocumentOrPlaceholder) => {
	const { open, confirm } = useDialogsContext(reload);

	const onClickViewDetail = (id: string) => {
		if (onViewDetail) {
			onViewDetail(id);
		} else {
			open(
				<DocumentDetailDialog
					open
					id={id}
					close={confirm}
				/>
			);
		}
	};

	return (
		<>
			{document ? (
				<DocumentListItem
					document={document}
					reload={reload}
					onClick={onClickViewDetail}
					children={children}
					appendTitle={appendTitle}
				/>
			) : (
				<EmptyDocumentListItem {...rest} />
			)}
		</>
	);
};
