import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IProductCatalogItem, ProductCatalogItemType } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { boolToYesNo } from 'shared/utils/boolToString';
import { SelectableColumn } from '../SelectableColumn';
import { formatDate } from 'framework/utils/date/formatDate';
import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { LabelledChipsList } from 'framework/components/chips/LabelledChipsList';
import React from 'react';

export const createHeaders = (
	colRecord: Record<SelectableColumn, string>,
	strings: IStrings,
	typeRecord: Record<ProductCatalogItemType, string>
): Record<SelectableColumn, IHeader<IProductCatalogItem>> => {
	return {
		id: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'id', t => t.id),
		identifier: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'identifier', t => t.identifier),
		rizivHearingAidDefinitionV2IdentificationCodesCsv: createHeaderWithKey<IProductCatalogItem, SelectableColumn>(
			colRecord,
			'rizivHearingAidDefinitionV2IdentificationCodesCsv',
			t => t.rizivHearingAidDefinitionV2IdentificationCodesCsv
		),
		name: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'name', t => t.name),
		manufacturer: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'manufacturer', t => t.manufacturer),
		model: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'model', t => t.model),
		internalReference: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'internalReference', t => t.internalReference),
		manufacturerReferencesCsv: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(
			colRecord,
			'manufacturerReferencesCsv',
			t => t.manufacturerReferencesCsv
		),
		supplierIdentifierCsv: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'supplierIdentifierCsv', t => t.supplierIdentifierCsv),
		category: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'category', p => p.category),
		subcategory: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'subcategory', p => p.subcategory),
		salesListPrice: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'salesListPrice', p => formatCurrency(p.salesListPrice)),
		purchaseListPrice: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'purchaseListPrice', p =>
			formatCurrency(p.purchaseListPrice)
		),
		vatPercentage: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'vatPercentage', p => `${p.vatPercentage}%`),
		isTangible: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'isTangible', p => boolToYesNo(p.isTangible, strings)),
		isTailorMade: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'isTailorMade', p => boolToYesNo(p.isTailorMade, strings)),
		isHearingInstrument: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'isHearingInstrument', p =>
			boolToYesNo(p.isHearingInstrument, strings)
		),
		// isHardware: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'isHardware', p => boolToYesNo(p.isHardware, strings)),
		isElectronics: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'isElectronics', p => boolToYesNo(p.isElectronics, strings)),
		hasBatteries: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'hasBatteries', p => boolToYesNo(p.hasBatteries, strings)),
		hasSerialNumber: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'hasSerialNumber', p =>
			boolToYesNo(p.hasSerialNumber, strings)
		),
		created: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'created', p => formatDate(p.created)),
		lastUpdated: createSortableHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'lastUpdated', p => formatDate(p.lastUpdated)),
		type: createHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'type', t => typeRecord[t.type as ProductCatalogItemType]),
		labels: createHeaderWithKey<IProductCatalogItem, SelectableColumn>(colRecord, 'labels', t => <LabelledChipsList labels={t.labels} />),
	};
};
