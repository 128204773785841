import { nameOf } from '../../utils/nameOf';
import { makeANumber } from '../../utils/makeANumber';

export const findFieldErrorMinStep = <TModel extends unknown>(errorProperties: string[], record: Record<number, (keyof TModel)[]>): number => {
	const keys = Object.keys(record);
	let minStep = -1;
	for (const key in keys) {
		const props = record[key];
		const any = props.length > 0 && props.map(t => errorProperties.indexOf(nameOf(t)) > -1).reduce((acc, current) => (acc === true ? acc : current));
		if (any && (minStep > makeANumber(key) || minStep === -1)) {
			minStep = makeANumber(key);
		}
	}
	return minStep;
};
