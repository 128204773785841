// import { createSelectionHeader } from "framework/table/createSelectionHeader";
import React from 'react';
import { createHeader } from 'framework/table/createHeader';
import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import { IInventoryItem } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { InventoryItemChips } from 'app/main/inventoryManagement/inventoryItems/components/InventoryItemChips';
import { IInventoryItemWithSelection } from './IInventoryItemWithSelection';

export const createHeaders = (
	lines: IInventoryItemWithSelection[],
	setLines: React.Dispatch<React.SetStateAction<IInventoryItemWithSelection[]>>,
	canSelect: (val: IInventoryItem) => boolean,
	strings: IStrings
): IHeader<IInventoryItemWithSelection>[] => {
	return [
		createSelectionHeader<IInventoryItemWithSelection>(lines, setLines, t => canSelect(t) === false),
		createSortableHeader<IInventoryItemWithSelection>(strings.name, 'identifier', t => t.identifier),
		createSortableHeader<IInventoryItemWithSelection>(strings.serialNumber, 'serialNumber', t => t.serialNumber),
		createHeader<IInventoryItemWithSelection>(strings.status, t => <InventoryItemChips item={t} />),
	];
};
