import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { INoahDeviceWithLastLinkedProductCatalogItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { ImportDeviceMode } from './ImportDeviceMode';

interface IProps extends DialogProps {
	item: INoahDeviceWithLastLinkedProductCatalogItem;
	confirm: (mode: ImportDeviceMode) => Promise<void>;
	cancel: VoidFunction;
	jsxTitle: JSX.Element;
}

export const ImportDeviceModeForm = ({ item, confirm, cancel, jsxTitle, ...rest }: IProps) => {
	const strings = useLocalization();
	const [mode, setMode] = useState<ImportDeviceMode>('lastLinked');
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const onSelectMode = async () => {
		setIsSubmitting(true);
		await confirm(mode);
		setIsSubmitting(false);
	};

	return (
		<>
			<Dialog
				{...rest}
				scroll='paper'
				fullWidth>
				<DialogTitle>{jsxTitle}</DialogTitle>
				<DialogContent
					className='df-col'
					dividers>
					<CloseButtonOnAbsolutePosition onClick={cancel} />
					<ExtendedRadioGroup<ImportDeviceMode>
						selected={mode}
						setSelected={setMode}
						options={[
							{
								value: 'lastLinked',
								label: strings.linkWithSameItemFromProductCatalogAsLastTime,
								caption: `${strings.lastLinkWas}\n${item.lastLinked.manufacturerCommaModel}\n${formatCurrency(item.lastLinked.salesListPrice)}\n${
									item.lastLinked.category
								} ${item.lastLinked.subcategory}`,
							},
							{
								value: 'other',
								label: strings.otherItemFromProductCatalog,
								caption: strings.searchInProductCatalogOrCreateNewItem,
							},
						]}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={cancel}
						color='primary'>
						{strings.cancel}
					</Button>
					<LoaderButton
						onClick={onSelectMode}
						isLoading={isSubmitting}
						color='primary'>
						{strings.next}
					</LoaderButton>
				</DialogActions>
			</Dialog>
		</>
	);
};
