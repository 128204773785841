import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { patientsQuery_details } from 'gen/ApiClient';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { PatientMissingPropertiesWithAlertDialog } from '../forms/PatientMissingPropertiesWithAlertDialog';
import { PatientDetailPageHeader } from './PatientDetailPageHeader';
import { PatientDetailPageTabContentSwitch } from './PatientDetailPageTabContentSwitch';
import { PatientDetailPageViewContext } from './PatientDetailPageViewContext';
import { PatientDetailPageSidebar } from './sidebar/PatientDetailPageSidebar';

export const PatientDetailPage = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [trigger, reload] = useTrigger();
	const [data, reloadDetail] = useApiEffect(patientsQuery_details, id);
	const patient = useMemo(() => data?.patient, [data]);
	const [hasChecked, setHasChecked] = useState<boolean>(false);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { selectedTab, setSelectedTab, patientId, setPatientId } = useContext(PatientDetailPageViewContext);

	useEffect(() => {
		if (patientId !== id) {
			setSelectedTab('overview');
			setPatientId(id);
		}
		// eslint-disable-next-line
	}, [id, patientId]);

	useLazyEffect(() => {
		reloadDetail();
	}, [trigger]);

	useEffect(() => {
		if (data !== undefined) {
			setHasChecked(true);
		}
		if (data !== undefined && data.missingProperties.length > 0 && hasChecked === false) {
			open(
				<PatientMissingPropertiesWithAlertDialog
					confirm={confirm}
					cancel={cancel}
					data={data}
				/>
			);
		}
		// eslint-disable-next-line
	}, [data, hasChecked]);

	if (patient === undefined || data === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='Patients'
			stickyHeader={
				<PatientDetailPageHeader
					item={data}
					reload={reload}
					tab={selectedTab}
					setTab={setSelectedTab}
				/>
			}
			stickySidebar={
				<PatientDetailPageSidebar
					item={data}
					reload={reload}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
					maxWidth: 1280,
				}}>
				<PatientDetailPageTabContentSwitch
					tab={selectedTab}
					item={data}
					reload={reload}
					reloadTrigger={trigger}
				/>
			</div>
		</RestrictedPageX>
	);
};
