import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

interface IModel {
	alsoUpdate: boolean;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		alsoUpdate: yup.boolean().defined(),
	});
};

interface IProps extends DialogProps {
	confirm: (val: boolean) => void;
	cancel: VoidFunction;
	isSubmitting: boolean;
	title: string;
}

export const AlsoUpdateAdvisoryPhysicianAddressForm = ({ title, confirm, cancel, isSubmitting, ...rest }: IProps) => {
	const strings = useLocalization();

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		confirm(values.alsoUpdate);
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{
				alsoUpdate: false,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{title}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormExtendedRadioGroupField<IModel, boolean>
							name='alsoUpdate'
							label=''
							options={[
								{ value: false, label: strings.changeOnlyThirdPayerArrangementAddress },
								{ value: true, label: strings.alsoChangeAdvisoryPhysicianAddress },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
