import { ITrialLineModel } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const lineToModel = (line: ILine & { index: number }): ITrialLineModel => {
	return {
		productCatalogItemId: line.productCatalogItemId,
		inventoryItemId: line.inventoryItemId,
		unitListPriceInVat: line.unitListPrice,
		unitPriceInVat: line.unitPrice,
		quantity: line.quantity,
		description: line.description,
		vatPercentage: line.vatPercentage,
		zeroBasedIndexNumber: line.index,
	};
};
