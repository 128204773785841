import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';

export const SmallPrimaryTextButton = (props: ButtonProps) => {
	return (
		<Button
			variant='text'
			size='small'
			color='primary'
			{...props}
		/>
	);
};
