import { ColorX } from 'framework/components/color/ColorX';
import { OrderLineStatus } from 'gen/ApiClient';

export const OrderLineStatusToColorXRecord: Record<OrderLineStatus, ColorX> = {
	Cancelled: 'error',
	CancelledAfterPartialDelivery: 'error',
	CompletelyDelivered: 'success',
	NotDelivered: 'default',
	PartiallyDelivered: 'warning',
};
