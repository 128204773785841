import React from 'react';
import { CacheProvider } from './CacheProvider';
import { NotificationsProvider } from './NotificationsProvider';

export const NotificationsProviders = ({ children }: any) => {
	return (
		<CacheProvider>
			<NotificationsProvider>{children}</NotificationsProvider>
		</CacheProvider>
	);
};
