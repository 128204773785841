import { useFormikContext } from 'formik';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect } from 'react';
import { FormSelectOrCreateCompanyField } from './FormSelectOrCreateCompanyField';

interface IProps {
	isSelectCompany: boolean;
	setIsSelectCompany: React.Dispatch<React.SetStateAction<boolean>>;
	disableSelector?: boolean;
	patientId: string | undefined;
}

export const FormSelectOrCreateCompanyFieldWithSelector = <TModel extends { companyId: string | undefined }>({
	isSelectCompany,
	setIsSelectCompany,
	disableSelector,
	patientId,
}: IProps) => {
	const strings = useLocalization();
	const props = useFormikContext<TModel>();

	useEffect(() => {
		if (disableSelector === true) {
			setIsSelectCompany(true);
		}
		// eslint-disable-next-line
	}, [disableSelector]);

	useEffect(() => {
		if (isSelectCompany === false) {
			setFormValue<TModel>('companyId', undefined, props);
		}
		// eslint-disable-next-line
	}, [isSelectCompany]);

	return (
		<>
			{disableSelector !== true && (
				<ExtendedRadioGroup<boolean>
					selected={isSelectCompany}
					setSelected={setIsSelectCompany}
					options={[
						{ value: true, label: strings.directedToVatCustomer },
						{ value: false, label: strings.notDirectedToVatCustomer },
					]}
				/>
			)}
			<FormSelectOrCreateCompanyField<TModel>
				name='companyId'
				label={strings.vatCustomer}
				disabled={isSelectCompany === false}
				patientId={patientId}
			/>
		</>
	);
};
