import { TextField, useTheme } from '@material-ui/core';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import TitleOutlinedIcon from '@material-ui/icons/TitleOutlined';
import { RoundedSquareX } from 'framework/components/RoundedSquareX';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { FormSelectFieldOnId } from 'framework/forms/FormSelectFieldOnId';
import { FormTextField } from 'framework/forms/FormTextField';
import { ICalendarEventDto, ICalendarEventModel, ICalendarEventType, ICalendarResourceDto, IObjectWithIdAndIdentifier, IPatientSearch } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { FormSelectPatientField } from 'shared/patientSearch/FormSelectPatientField';
import { RecordContext } from 'shared/records/RecordContext';
import { formatTimeModel } from 'shared/timeUnit/formatTimeModel';
import { FormFieldWithIcon } from './FormFieldWithIcon';

interface IProps {
	rooms: ICalendarResourceDto[];
	types: ICalendarEventType[];
	patient: IObjectWithIdAndIdentifier<string> | undefined;
	calendarEvent: ICalendarEventDto | undefined;
	onSelectPatient: (val: IPatientSearch | undefined) => void;
}

export const CalendarEventDetailsTab = ({ rooms, types, patient, calendarEvent, onSelectPatient }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { timeUnitRecord } = useContext(RecordContext);

	return (
		<>
			<FormFieldWithIcon
				icon={<CategoryOutlinedIcon />}
				field={
					<FormSelectFieldOnId<ICalendarEventModel, ICalendarEventType>
						name='eventTypeId'
						label={strings.type}
						options={types}
						style={{ width: '100%' }}
						getKey={t => t.id}
						renderValue={option => (
							<div className='df-row-ac'>
								<RoundedSquareX
									color={option.color}
									size={20}
								/>
								<div style={{ marginLeft: '5px' }}>{`${option.name} (${formatTimeModel(option.defaultDuration, timeUnitRecord)})`}</div>
							</div>
						)}
					/>
				}
			/>
			<FormFieldWithIcon
				icon={<PersonOutlineIcon />}
				field={
					patient === undefined && (calendarEvent === undefined || calendarEvent.hasPatient === false) ? (
						<FormSelectPatientField<ICalendarEventModel>
							style={{ padding: 0 }}
							name='patientId'
							label={strings.patient}
							onSelected={onSelectPatient}
							canCreateNew
						/>
					) : (
						<TextField
							label={strings.patient}
							value={patient?.identifier ?? calendarEvent?.patientIdentifier ?? ''}
							disabled
							variant='filled'
							style={{ marginBottom: theme.spacing(1), width: '100%' }}
						/>
					)
				}
			/>
			<FormFieldWithIcon
				icon={<LocationOnOutlinedIcon />}
				field={
					<FormAutocompleteField<ICalendarEventModel, ICalendarResourceDto>
						name='roomId'
						label={strings.locationSlashRoom}
						options={rooms}
					/>
				}
			/>
			<FormFieldWithIcon
				icon={<TitleOutlinedIcon />}
				field={
					<FormTextField<ICalendarEventModel>
						name='title'
						style={{ width: '100%' }}
						label={strings.title}
					/>
				}
			/>
			<FormFieldWithIcon
				icon={<PhoneOutlinedIcon />}
				field={
					<FormTextField<ICalendarEventModel>
						name='contact'
						style={{ width: '100%' }}
						label={strings.contact}
					/>
				}
			/>
			<FormFieldWithIcon
				icon={<SubjectOutlinedIcon />}
				field={
					<FormTextField<ICalendarEventModel>
						name='description'
						label={strings.remark}
						style={{ width: '100%' }}
						multiline
						rows={10}
					/>
				}
			/>
		</>
	);
};
