import { ICommissionsQueryParams } from 'gen/ApiClient';

export const DefaultParams: ICommissionsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	userIds: [],
	commissionRuleIds: [],
	createdAfter: undefined,
	createdBefore: undefined,
	isPaidOut: undefined,
};
