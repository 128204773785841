import React, { useMemo, useState } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { StockMovementsSelectableColumn } from './StockMovementsSelectableColumn';
import { createStockMovementsColumnRecord } from './createStockMovementsColumnRecord';
import { createStockMovementsExportRecord } from './createStockMovementsExportRecord';
import { createStockMovementsHeaders } from './createStockMovementsHeaders';
import { StockMovementsListViewContext } from './StockMovementsListViewContext';

// enkel hier nodig
const DefaultColumns: StockMovementsSelectableColumn[] = ['reference', 'created', 'fromLocationIdentifier', 'toLocationIdentifier', 'countItems'];

export const StockMovementsListViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [columns, setColumns] = useState<StockMovementsSelectableColumn[]>(DefaultColumns);
	const colRecord = useMemo(() => createStockMovementsColumnRecord(strings), [strings]);
	const headers = useMemo(() => createStockMovementsHeaders(colRecord, strings), [colRecord, strings]);
	const exportRecord = useMemo(() => createStockMovementsExportRecord(strings), [strings]);

	return (
		<StockMovementsListViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
				exportRecord: exportRecord,
			}}>
			{children}
		</StockMovementsListViewContext.Provider>
	);
};
