import { MenuItem, Select } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { nameOf } from '../utils/nameOf';
import { FormFilledControl } from './common/FormFilledControl';
import { IFormProps } from './common/IFormProps';

interface IProps<TModel, TValue> extends IFormProps<TModel> {
	options: TValue[];
	withNone?: boolean;
	renderValue: (option: TValue, index: number) => React.ReactNode;
	getKey: (val: TValue) => string;
}

export const FormSelectField = <TModel extends unknown, TValue extends unknown>({
	options,
	withNone = false,
	renderValue,
	getKey,
	...props
}: IProps<TModel, TValue>) => {
	const [field, meta] = useField<TValue>(nameOf(props.name));
	const strings = useLocalization();

	return (
		<FormFilledControl
			meta={meta}
			{...props}>
			<Select {...field}>
				{withNone && (
					<MenuItem value={undefined}>
						<em>{strings.none.toLowerCase()}</em>
					</MenuItem>
				)}
				{options.map((t, index) => (
					<MenuItem
						key={getKey(t)}
						value={t as any}>
						{renderValue(t, index)}
					</MenuItem>
				))}
			</Select>
		</FormFilledControl>
	);
};
