import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import React, { useState } from 'react';

interface IProps {
	values: string[];
	setValues: (vals: string[]) => void;
	style?: React.CSSProperties;
	className?: string;
	disabled?: boolean;
	autocompleteOptions?: string[];
}

export const MultipleValuesTextField = ({ values, setValues, style, className, disabled, autocompleteOptions }: IProps) => {
	const [iValue, setIValue] = useState<string>('');

	return (
		<Autocomplete
			className={className}
			inputValue={iValue}
			onInputChange={(e, value, reason) => setIValue(value)}
			style={style}
			multiple
			freeSolo
			options={autocompleteOptions ?? []}
			value={values}
			open={autocompleteOptions === undefined ? false : undefined}
			onChange={(e, vals, reason) => {
				if (reason === 'select-option' && vals !== undefined && vals.length > 0) {
					const val = vals[vals.length - 1];
					if (values.indexOf(val) === -1) {
						setValues([...values, val]);
					}
				}
			}}
			disableCloseOnSelect
			clearOnBlur
			disabled={disabled}
			disableClearable
			renderTags={value =>
				value.map((option, index) => (
					<Chip
						size='small'
						key={index.toString()}
						label={option}
						style={{ margin: 3 }}
						onDelete={() => setValues(values.filter(t => t !== (option as any)))}
					/>
				))
			}
			renderInput={params => (
				<TextField
					{...params}
					variant='outlined'
					inputProps={{ ...params.inputProps, style: { padding: 0 } }}
					InputProps={{ ...params.InputProps, style: { padding: values.length > 0 ? 2 : 9 } }}
					onKeyDown={e => {
						if (e.key === 'Tab' && isNullOrUndefined(iValue)) {
							// no-op
						} else if (e.key === 'Tab' || e.key === 'Enter') {
							e.stopPropagation();
							e.preventDefault();
							if (isNotNullNorUndefined(iValue) && values.indexOf(iValue) === -1) {
								setValues([...values, iValue]);
							}
							setIValue('');
						}
					}}
				/>
			)}
		/>
	);
};
