import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { INoahDeviceInventoryItemMapDetail } from 'gen/ApiClient';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { XViewBadgeIconButton } from 'shared/icons/XViewBadgeIconButton';

interface IProps {
	item: INoahDeviceInventoryItemMapDetail;
}

export const PatientFromNoahHeader = ({ item }: IProps) => {
	const { push } = useHistory();

	if (item.hasPatientFromLastUsage) {
		return (
			<div className='df-row-ac'>
				<div>{item.patientFromLastUsage!.identifier}</div>
				<XViewBadgeIconButton
					size='small'
					onClick={() => push(calcPatientDetailRoute(item.patientFromLastUsage!.id!))}>
					<PersonOutlineOutlinedIcon fontSize='small' />
				</XViewBadgeIconButton>
			</div>
		);
	} else {
		return <></>;
	}
};
