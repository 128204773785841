import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';

interface IProps<TValue> {
	label?: string;
	value: TValue | undefined;
	onChange: (val: TValue) => void;
	options: TValue[];
	renderValue: (val: TValue, index: number) => string | JSX.Element;
	getKey: (val: TValue) => string;
	style?: React.CSSProperties;
	className?: string;
	withNone?: boolean;
	variant?: 'standard' | 'outlined' | 'filled';
	size?: 'medium' | 'small';
}

export const SelectField = <TValue extends unknown>({
	label,
	value,
	onChange,
	options,
	renderValue,
	getKey,
	style,
	className,
	withNone = false,
	variant = 'filled',
	size = 'medium',
}: IProps<TValue>) => {
	const strings = useLocalization();

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const val = event.target.value;
		if (val === 'true') {
			onChange(true as TValue);
		} else if (val === 'false') {
			onChange(false as TValue);
		} else {
			onChange(val as TValue);
		}
	};

	return (
		<FormControl
			size={size}
			variant={variant}
			className={className}
			style={style}>
			{label && <InputLabel>{label}</InputLabel>}
			<Select
				label={label}
				value={value}
				onChange={handleChange}>
				{withNone && (
					<MenuItem value={undefined}>
						<em>{strings.none.toLowerCase()}</em>
					</MenuItem>
				)}
				{options.map((t, index) => (
					<MenuItem
						key={getKey(t)}
						value={t as any}>
						{renderValue(t, index)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
