import { NoahOverviewTab } from 'app/main/patients/detailX/noah/NoahOverviewTab';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { rfiasQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AnnotationsCard } from 'shared/annotations/AnnotationsCard';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { PatientUpcomingEventsCardSwitch } from '../../../patients/detailX/appointments/PatientUpcomingEventsCardSwitch';
import { NavigationContext } from '../context/NavigationContext';
import { ActionsCard } from './ActionsCard';
import { DetailsCard } from './DetailsCard';
import { ProcessPage } from './ProcessPage';
import { RfiaDetailPageHeader } from './RfiaDetailPageHeader';

export const RfiaDetail = () => {
	const strings = useLocalization();
	const { id } = useParams<IRouteParamsWithId>();
	const [detail, reload] = useApiEffect(rfiasQuery_single, id);
	const { link, setLink } = useContext(NavigationContext);
	const [trigger, hitTrigger] = useTrigger();

	useLazyEffect(() => {
		reload();
	}, [trigger]);

	if (detail === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='Rfias'
			stickyHeader={
				<RfiaDetailPageHeader
					item={detail.rfia}
					reload={hitTrigger}
					setTab={setLink}
					tab={link}
				/>
			}
			stickySidebar={
				<div
					className='df-col gap-16'
					style={{ padding: 16, paddingRight: 8 }}>
					<DetailsCard
						item={detail.rfia}
						patient={detail.patient}
						reload={hitTrigger}
					/>
					<PatientUpcomingEventsCardSwitch
						item={detail.patient}
						reloadTrigger={trigger}
						reload={hitTrigger}
					/>
					<ActionsCard
						item={detail.rfia}
						reload={hitTrigger}
					/>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16 }}>
				{link === 'process' && (
					<ProcessPage
						detail={detail}
						reload={hitTrigger}
					/>
				)}
				{link === 'comments' && (
					<AnnotationsCard
						contextId={detail.patient.id}
						linkViewContextType='Patient'
						title={`${strings.annotations} (${strings.ofThePatient.toLowerCase()})`}
						reloadTrigger={trigger}
						reload={hitTrigger}
					/>
				)}
				{link === 'noahOverview' && (
					<NoahOverviewTab
						data={detail.patient}
						reloadTrigger={trigger}
					/>
				)}
			</div>
		</RestrictedPageX>
	);
};
