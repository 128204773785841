import { Dialog, DialogContent, DialogProps, useTheme } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { FileSearchOutlineIcon } from 'framework/components/icons/FileSearchOutlineIcon';
import { NumberFormatTextField } from 'framework/components/textFields/NumberFormatTextField';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormHelperTextX } from 'framework/forms/common/FormHelperTextX';
import { FormNumberField } from 'framework/forms/FormNumberField';
import { FormPercentageField } from 'framework/forms/FormPercentageField';
import { FormSelectFieldFromStrings } from 'framework/forms/FormSelectFieldFromStrings';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	ISpeechInNoiseTest,
	ISpeechInNoiseTestModel,
	ISpeechInNoiseTestWordsList,
	trialReportsCommand_addSpeechInNoiseTest,
	trialReportsQuery_speechInNoiseTestWordsLists,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useViewDocumentation } from 'shared/utils/useViewDocumentation';

const createSchema = (strings: IStrings) => {
	return yup
		.object<ISpeechInNoiseTestModel>({
			listName: yup.string().required(),
			aidedScoreAtDbSnrOfSrt: yup.number().defined(),
			aidedScoreInDbSnrFor50Percent: yup.number().defined(),
			unaidedScoreInDbSnrFor50Percent: yup.number().defined(),
		})
		.defined();
};

const emptyValues: ISpeechInNoiseTestModel = {
	aidedScoreAtDbSnrOfSrt: 0,
	aidedScoreInDbSnrFor50Percent: 0,
	listName: '',
	unaidedScoreInDbSnrFor50Percent: 0,
};

const stepsRecord: Record<number, (keyof ISpeechInNoiseTestModel)[]> = {
	0: ['listName'],
	1: ['unaidedScoreInDbSnrFor50Percent'],
	2: ['aidedScoreAtDbSnrOfSrt', 'aidedScoreInDbSnrFor50Percent'],
};

interface IProps extends DialogProps {
	trialId: string;
	test?: ISpeechInNoiseTest;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const SpeechInNoiseTestForm = ({ trialId, test, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(trialReportsCommand_addSpeechInNoiseTest);
	const [step, setStep] = useState<number>(0);
	const [lists] = useApiEffect(trialReportsQuery_speechInNoiseTestWordsLists);

	if (lists === undefined) {
		return <div></div>;
	}

	const handleSubmit = async (values: ISpeechInNoiseTestModel, helpers: FormikHelpers<ISpeechInNoiseTestModel>) => {
		const r = await create(trialId, values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.addedWhat(strings.test));
			confirm();
		}
	};

	return (
		<Formik<ISpeechInNoiseTestModel>
			validateOnMount
			initialValues={test ? { listName: test.list.name, ...test } : emptyValues}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					lists={lists}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	lists: ISpeechInNoiseTestWordsList[];
}

const InnerDialog = ({ step, setStep, cancel, lists, isSubmitting, ...rest }: IInnerDialogProps) => {
	const theme = useTheme();
	const props = useFormikContext<ISpeechInNoiseTestModel>();
	const strings = useLocalization();
	const [absScoreDifferenceWithNorm, setAbsScoreDifferenceWithNorm] = useState<number>(0);
	const [normScore, setNormScore] = useState<number>(0);
	const [scoreImprovement, setScoreImprovement] = useState<number>(0);
	const [speechIllegibilityImprovement, setSpeechIllegibilityImprovement] = useState<number>(0);
	const onViewSinRizivInfo = useViewDocumentation('sin-riziv-info');

	useEffect(() => {
		const list = lists.find(t => t.name === props.values.listName);
		if (list !== undefined) {
			setAbsScoreDifferenceWithNorm(Math.abs(list.normScoreInDbSnrFor50Percent - props.values.unaidedScoreInDbSnrFor50Percent));
			setNormScore(list.normScoreInDbSnrFor50Percent);
		} else {
			setAbsScoreDifferenceWithNorm(0);
			setNormScore(0);
		}
	}, [props.values.listName, props.values.unaidedScoreInDbSnrFor50Percent, lists]);

	useEffect(() => {
		if (props.values.unaidedScoreInDbSnrFor50Percent && props.values.aidedScoreInDbSnrFor50Percent) {
			setScoreImprovement(props.values.unaidedScoreInDbSnrFor50Percent - props.values.aidedScoreInDbSnrFor50Percent);
		} else {
			setScoreImprovement(0);
		}
	}, [props.values.unaidedScoreInDbSnrFor50Percent, props.values.aidedScoreInDbSnrFor50Percent]);

	useEffect(() => {
		if (props.values.aidedScoreAtDbSnrOfSrt) {
			setSpeechIllegibilityImprovement(props.values.aidedScoreAtDbSnrOfSrt - 50);
		} else {
			setSpeechIllegibilityImprovement(0);
		}
	}, [props.values.aidedScoreAtDbSnrOfSrt]);

	return (
		<Dialog {...rest}>
			<DialogTitleWithFormStepper
				title={strings.speechInNoise}
				step={step}
				labels={[strings.list, strings.scoreWithout, strings.scoreWith]}>
				<div style={{ marginBottom: theme.spacing(1) }}>
					<SmallPrimaryTextButton
						startIcon={<FileSearchOutlineIcon />}
						onClick={onViewSinRizivInfo}>
						{strings.viewRIZIVDocumentation}
					</SmallPrimaryTextButton>
				</div>
			</DialogTitleWithFormStepper>
			<DialogContent
				className='df-col'
				dividers>
				{step === 0 && (
					<FormSelectFieldFromStrings<ISpeechInNoiseTestModel>
						name='listName'
						label='Lijst'
						options={lists.map(t => t.name ?? '')}
					/>
				)}
				{step === 1 && (
					<>
						<FormNumberField<ISpeechInNoiseTestModel>
							name='unaidedScoreInDbSnrFor50Percent'
							label={`${strings.scoreWithoutEquipment} (dBSNR @ 50%)`}
							suffix=' dBSNR @ 50%'
							allowNegative={true}
							decimalScale={0}
						/>
						<NumberFormatTextField
							variant='filled'
							disabled
							label={`${strings.normScore} (dbSNR @ 50%)`}
							value={normScore}
							suffix=' dBSNR @ 50%'
							decimalScale={0}
						/>
						<FormHelperTextX>{`${strings.normValueForThisList(lists.find(t => t.name === props.values.listName)?.name ?? '')} (dbSNR @ 50%)`}</FormHelperTextX>
						<NumberFormatTextField
							disabled
							label={`${strings.differenceWithNorm} (dbSNR @ 50%)`}
							value={absScoreDifferenceWithNorm}
							suffix=' dbSNR @ 50%'
							decimalScale={0}
						/>
						<FormHelperTextX>{strings.mustBeLargerThanWhat('3dB SNR')}</FormHelperTextX>
					</>
				)}
				{step === 2 && (
					<>
						<NumberFormatTextField
							variant='filled'
							disabled
							label={`${strings.scoreWithoutEquipment} (dBSNR @ 50%)`}
							value={props.values.unaidedScoreInDbSnrFor50Percent}
							suffix=' dBSNR @ 50%'
							decimalScale={0}
						/>
						<FormHelperTextX>{strings.ofPreviousStep}</FormHelperTextX>
						<FormNumberField<ISpeechInNoiseTestModel>
							name='aidedScoreInDbSnrFor50Percent'
							label={`${strings.scoreWithEquipment} (dBSNR @ 50%)`}
							suffix=' dBSNR @ 50%'
							allowNegative={true}
							decimalScale={0}
						/>
						<FormPercentageField<ISpeechInNoiseTestModel>
							name='aidedScoreAtDbSnrOfSrt'
							label={strings.scoreWithEquipmentAtSNROfSRT}
						/>
						<NumberFormatTextField
							variant='filled'
							disabled
							label={`${strings.improvement} (dbSNR @ 50%)`}
							value={scoreImprovement}
							suffix=' dBSNR @ 50%'
							decimalScale={0}
						/>
						<FormHelperTextX>{strings.mustBeLargerThanWhat('2dB SNR')}</FormHelperTextX>
						<NumberFormatTextField
							variant='filled'
							disabled
							label={strings.speechIntelligibilityImprovement}
							value={speechIllegibilityImprovement}
							suffix=' %'
							decimalScale={0}
						/>
						<FormHelperTextX>{strings.mustBeLargerThanWhat('10%')}</FormHelperTextX>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
