import { Badge, Divider, IconButton, List, Menu } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useLocalization } from 'localization/useLocalization';
import { NotificationsRoute } from 'routes';
import { NotificationsContext } from 'app/main/notifications/context/NotificationsContext';
import { NotificationListItem } from 'app/main/notifications/NotificationListItem';

export const NotificationsIconButtonAndMenu = () => {
	const { push } = useHistory();
	const strings = useLocalization();
	const { notifications, countUnreadNotifications, onView } = useContext(NotificationsContext);
	const [anchor, open, close] = useAnchorElement();

	const onViewAll = () => {
		push(NotificationsRoute);
		close();
	};

	return (
		<>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={close}
				keepMounted
				getContentAnchorEl={null}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				style={{ padding: 3 }}>
				<div style={{ fontWeight: 'bold', fontSize: '16px', padding: 16, textAlign: 'center' }}>{strings.latest10Notifications}</div>
				<Divider />
				<List>
					{notifications.map(t => (
						<React.Fragment key={t.id}>
							<NotificationListItem
								item={t}
								onView={id => {
									close();
									onView(id);
								}}
							/>
							<Divider />
						</React.Fragment>
					))}
				</List>
				<div
					className='df-row-ac jc-e'
					style={{ marginRight: 12 }}>
					<SmallPrimaryTextButton onClick={onViewAll}>{strings.viewAll}</SmallPrimaryTextButton>
				</div>
			</Menu>
			<IconButton
				color='inherit'
				onClick={open}
				edge='end'>
				<Badge
					badgeContent={countUnreadNotifications}
					color='secondary'>
					<NotificationsIcon />
				</Badge>
			</IconButton>
		</>
	);
};
