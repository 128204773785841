import { IObjectWithIdAndIdentifier } from 'gen/ApiClient';
import { useContext, useEffect } from 'react';
import { LocalizedHealthInsuranceFundsCacheContext } from './LocalizedHealthInsuranceFundsCacheContext';

export function useLocalizedHealthInsuranceFundsCache(): IObjectWithIdAndIdentifier<string>[] | undefined {
	const { data, init } = useContext(LocalizedHealthInsuranceFundsCacheContext);

	useEffect(() => {
		init();
	}, [init]);

	return data;
}
