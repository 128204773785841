import { Collapse, Divider } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { ArticlePropertiesLabelledPropertiesX } from 'app/main/inventoryManagement/inventoryItems/detail/articleProperties/ArticlePropertiesLabelledPropertiesX';
import { useLocalizedArticlePropertyKeys } from 'app/main/inventoryManagement/productCatalog/articlePropertyKeys/context/useLocalizedArticlePropertyKeys';
import { calcInventoryItemDetailRoute, calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { TextLinkButtonWithExpandIcon } from 'framework/components/buttons/TextLinkButtonWithExpandIcon';
import { ChipX } from 'framework/components/chips/ChipX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { IOrderLineWithInventoryItem, IOrderV2, OrderLineStatus } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { RecordContext } from 'shared/records/RecordContext';
import { OrderLineDeliveryComponent } from './OrderLineDeliveryComponent';
import { OrderLineStatusIcon } from './OrderLineStatusIcon';
import { OrderLineStatusToColorXRecord } from './OrderLineStatusToColorXRecord';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	dto: IOrderLineWithInventoryItem;
	order: IOrderV2;
	withoutBorder?: boolean;
	onDelete: VoidFunction;
}

export const OrderLineListItem = ({ dto, order, withoutBorder, onDelete }: IProps) => {
	const line = dto.data;
	const inventoryItem = dto.inventoryItem;
	const strings = useLocalization();
	const { orderLineStatusRecord } = useContext(RecordContext);
	const { push } = useHistory();
	const color = useMemo(() => OrderLineStatusToColorXRecord[line.status as OrderLineStatus], [line]);
	const tColor = useThemeColor(color);
	const localizedKeys = useLocalizedArticlePropertyKeys();
	const [isExpanded, toggleExpanded] = useStateToggleBoolean(false);

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<OrderLineStatusIcon
					item={line}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col fg1'>
				<div className='df-row-ac'>
					<TextLinkButton
						onClick={() => {
							if (line.inventoryItemId) {
								push(calcInventoryItemDetailRoute(line.inventoryItemId));
							} else {
								push(calcProductCatalogDetailRoute(line.productCatalogItemId));
							}
						}}
						style={{ fontSize: MajorFontSize }}>
						{`${line.quantity} x ${line.description}`}
					</TextLinkButton>
					<div className='fg1'></div>
					<TextLinkButton
						hoverColor='secondary'
						style={{ fontSize: MinorFontSize }}
						disabled={order.canAddOrDeleteLines === false || line.deliveredQuantity > 0}
						startIcon={<DeleteOutlineIcon />}
						onClick={onDelete}>
						{strings.delete}
					</TextLinkButton>
				</div>
				<ChipX
					label={orderLineStatusRecord[line.status as OrderLineStatus]}
					color={color}
					style={{ marginRight: 'auto', marginTop: 4, marginBottom: 4 }}
				/>
				<span style={{ fontSize: MinorFontSize }}>
					{`# ${line.quantity} ${strings.ordered.toLowerCase()}, # ${line.deliveredQuantity} ${strings.delivered.toLowerCase()}`}
				</span>
				{line.hasPatient && (
					<div
						className='df-row-ac'
						style={{ fontSize: MinorFontSize, gap: 4 }}>
						<span>{`${strings.patient}:`}</span>
						<TextLinkButton
							style={{ fontSize: MinorFontSize }}
							underline
							onClick={() => push(calcPatientDetailRoute(line.patientId!))}>
							{`${line.patientIdentifier} (${dto.patientReference})`}
						</TextLinkButton>
					</div>
				)}
				{line.deliveries.map(t => (
					<OrderLineDeliveryComponent
						key={t.deliveryId}
						delivery={t}
						order={order}
					/>
				))}
				{inventoryItem !== undefined && inventoryItem !== null && inventoryItem.articlePropertiesX !== null && inventoryItem.articlePropertiesX.length > 0 && (
					<>
						<TextLinkButtonWithExpandIcon
							toggle={toggleExpanded}
							isExpanded={isExpanded}>
							<span style={{ fontSize: MinorFontSize, fontWeight: 'unset' }}>{`# ${inventoryItem.articlePropertiesX.length.toString()} ${
								inventoryItem.articlePropertiesX.length > 1 ? strings.properties : strings.property
							}`}</span>
						</TextLinkButtonWithExpandIcon>
						<Collapse in={isExpanded}>
							<div className='df-col'>
								<Divider
									variant='inset'
									style={{
										marginTop: 8,
										marginBottom: 4,
										width: '50%',
									}}
								/>
								<ArticlePropertiesLabelledPropertiesX
									aps={inventoryItem.articlePropertiesX}
									localizedKeys={localizedKeys}
								/>
							</div>
						</Collapse>
					</>
				)}
			</div>
		</div>
	);
};
