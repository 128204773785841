import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { ExactGlobeSelectedTabKey } from 'app/localStorageKeys';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { TabsX } from 'framework/components/tabs/TabsX';
import { TabX } from 'framework/components/tabs/TabX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useLocalStorage } from 'framework/hooks/useLocalStorage';
import { useTrigger } from 'framework/hooks/useTrigger';
import { createType } from 'framework/utils/createType';
import { exactGlobeStatus_status, IExactGlobeStatusDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { IntegrationsPageBreadcrumb } from '../IntegrationsPageBreadcrumb';
import { ExactGlobeCustomerAccountsQueryProvider, ExactGlobeCustomerAccountsTab } from './exact-globe-customer-accounts.tab';
import { ExactGlobePcsQueryProvider, ExactGlobePcsTab } from './exact-globe-pcs.tab';
import { ExactGlobeSalesQueryProvider, ExactGlobeSalesTab } from './exact-globe-sales.tab';
import { ExactGlobeSettingsTab } from './exact-globe-settings.tab';

export const ExactGlobeProviders = ({ children }: any) => {
	return (
		<ExactGlobeCustomerAccountsQueryProvider>
			<ExactGlobePcsQueryProvider>
				<ExactGlobeSalesQueryProvider>{children}</ExactGlobeSalesQueryProvider>
			</ExactGlobePcsQueryProvider>
		</ExactGlobeCustomerAccountsQueryProvider>
	);
};

export const ExactGlobeTabTypes = createType('customer-accounts', 'sales', 'product-catalog', 'settings');
export type ExactGlobeTabType = typeof ExactGlobeTabTypes[number];

export const ExactGlobePage = () => {
	const [reloadTrigger, reload] = useTrigger();
	const [selectedTab, setSelectedTab] = useLocalStorage<ExactGlobeTabType>(ExactGlobeSelectedTabKey, 'settings');
	const [status, reloadStatus] = useApiEffect(exactGlobeStatus_status);

	useLazyEffect(() => {
		reloadStatus();
	}, [reloadTrigger]);

	if (status === undefined) {
		return <></>;
	}

	return (
		<RestrictedPageX
			pageClaim='ExactGlobeIntegration'
			renderRaw
			stickyHeader={
				<ExactGlobePageHeader
					tab={selectedTab}
					setTab={setSelectedTab}
					status={status}
					reload={reload}
				/>
			}>
			{selectedTab === 'sales' && (
				<ExactGlobeSalesTab
					reload={reload}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{selectedTab === 'customer-accounts' && (
				<ExactGlobeCustomerAccountsTab
					reload={reload}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{selectedTab === 'product-catalog' && (
				<ExactGlobePcsTab
					reload={reload}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{selectedTab === 'settings' && (
				<ExactGlobeSettingsTab
					reload={reload}
					reloadTrigger={reloadTrigger}
					status={status}
				/>
			)}
		</RestrictedPageX>
	);
};

interface IExactGlobePageHeaderProps {
	reload: VoidFunction;
	tab: ExactGlobeTabType;
	setTab: React.Dispatch<React.SetStateAction<ExactGlobeTabType>>;
	status: IExactGlobeStatusDto;
}

const ExactGlobePageHeader = ({ reload, tab, setTab, status }: IExactGlobePageHeaderProps) => {
	const strings = useLocalization();

	return (
		<div className='df-col fg1 gap-4'>
			<div className='df-row-ac'>
				<IntegrationsPageBreadcrumb />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={`Exact Globe`} />
				{status.hasNotifications && (
					<StatusIcon
						status='error'
						style={{ marginLeft: 4 }}
					/>
				)}
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<TabsX
				style={{
					marginLeft: -4,
				}}>
				<TabX
					isSelected={tab === 'sales'}
					onClick={() => setTab('sales')}
					startIcon={<AttachMoneyOutlinedIcon />}
					endIcon={
						status.countUnhandledSales > 0 && (
							<BadgeChipX
								value={status.countUnhandledSales}
								color='error'
							/>
						)
					}
					value={`Verkopen`}
				/>
				<TabX
					isSelected={tab === 'customer-accounts'}
					onClick={() => setTab('customer-accounts')}
					startIcon={<AccountCircleOutlinedIcon />}
					endIcon={
						status.countUnhandledCustomerAccounts > 0 && (
							<BadgeChipX
								value={status.countUnhandledCustomerAccounts}
								color='error'
							/>
						)
					}
					value={`Klanten`}
				/>
				<TabX
					isSelected={tab === 'product-catalog'}
					onClick={() => setTab('product-catalog')}
					startIcon={<AccountCircleOutlinedIcon />}
					endIcon={
						status.countUnlinkedPcs > 0 && (
							<BadgeChipX
								value={status.countUnlinkedPcs}
								color='error'
							/>
						)
					}
					value={`Productcatalogus`}
				/>
				<TabX
					isSelected={tab === 'settings'}
					onClick={() => setTab('settings')}
					startIcon={<SettingsOutlinedIcon />}
					value={strings.settings}
				/>
			</TabsX>
		</div>
	);
};
