import React, { useEffect, useState } from 'react';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	INoahDeviceForPatient,
	INoahDeviceInventoryItemMap,
	noahDeviceInventoryItemMapsCommand_import,
	noahDeviceInventoryItemMapsQuery_single,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { HearingInstrumentForm } from '../../../productCatalog/forms/hearingInstrument/HearingInstrumentForm';
import { ImportDeviceMode } from './ImportDeviceMode';
import { ImportDeviceModeForm } from './ImportDeviceModeForm';
import { SelectProductCatalogItemForm } from './SelectProductCatalogItemForm';

type State = 'select' | ImportDeviceMode;

interface IProps {
	id: string;
	confirm: (id?: string) => void;
	cancel: VoidFunction;
}

export const ImportDeviceForm = ({ id, cancel, confirm }: IProps) => {
	const [item] = useApiEffect(noahDeviceInventoryItemMapsQuery_single, id);
	const [state, setState] = useState<State>('other');
	const [submit, isSubmitting] = useFormSubmit(noahDeviceInventoryItemMapsCommand_import);
	const notify = useSnackbarNotify();

	useEffect(() => {
		if (item !== undefined && item.hasLastLinked) {
			setState('select');
		} else if (item !== undefined && item.hasLastLinked === false) {
			setState('other');
		}
	}, [item]);

	if (item === undefined) {
		return <div></div>;
	}

	const onSelectMode = async (mode: ImportDeviceMode) => {
		if (mode === 'lastLinked') {
			await onSelect(item.lastLinked.id);
		} else {
			setState('other');
		}
	};

	const onSelect = async (productCatalogId: string) => {
		const r = await submit(item.data.id, productCatalogId);
		if (r.isSuccess) {
			notify('Toestel geïmporteerd');
			confirm();
		}
	};

	if (state === 'select') {
		return (
			<ImportDeviceModeForm
				open
				jsxTitle={<Title item={item.data} />}
				confirm={onSelectMode}
				cancel={cancel}
				item={item}
			/>
		);
	} else if (state === 'other') {
		return (
			<SelectProductCatalogItemForm
				open
				confirm={onSelect}
				cancel={cancel}
				initialSearch={`${item.data.manufacturer} ${item.data.instrumentTypeName}`}
				jsxTitle={<Title item={item.data} />}
				isSubmitting={isSubmitting}
				onNew={() => setState('new')}
			/>
		);
	} else {
		return (
			<HearingInstrumentForm
				open
				confirm={onSelect}
				cancel={cancel}
				isHearingInstrument={item.data.isHearingInstrument}
				manufacturer={item.data.manufacturer}
				model={item.data.instrumentTypeName}
			/>
		);
	}
};

const Title = ({ item }: { item: INoahDeviceInventoryItemMap | INoahDeviceForPatient }) => {
	const strings = useLocalization();

	return (
		<>
			<div>{strings.importDeviceInStock}</div>
			<div>
				{item.manufacturerCommaInstrumentTypeName} {item.serialNumber}
			</div>
		</>
	);
};
