import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { TransactionType } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	type: string | undefined;
	marginBottom?: boolean;
	style?: React.CSSProperties;
}

export const TransactionTypeChip = ({ type, style }: IProps) => {
	const { transactionTypeRecord } = useContext(RecordContext);

	return (
		<RectangularChip
			label={transactionTypeRecord[type as TransactionType]}
			style={style}
		/>
	);
};
