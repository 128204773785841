import { Color } from '@material-ui/lab';
import React from 'react';
import { StatusIcon } from '../icons/StatusIcon';
import { ILabelledPropertyProps, LabelledProperty } from './LabelledProperty';

type VariantX = 'warning' | 'info' | 'error' | 'success';

interface IProps extends ILabelledPropertyProps {
	variantX: 'warning' | 'info' | 'error' | 'success';
}

const VariantToColorRecord: Record<VariantX, Color | 'primary'> = {
	error: 'error',
	info: 'info',
	success: 'success',
	warning: 'warning',
};

export const VariantLabelledProperty = ({ variantX, ...rest }: IProps) => {
	return (
		<LabelledProperty
			icon={<StatusIcon status={variantX} />}
			color={VariantToColorRecord[variantX]}
			{...rest}
		/>
	);
};
