import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { IPatientDetail, workflowsQuery_queryPrimaryByPatient } from 'gen/ApiClient';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { WorkflowsByPatientCard } from './WorkflowsByPatientCard';

interface IProps extends IReloadTriggerProps {
	item: IPatientDetail;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const WorkflowsByPatientTab = ({ item, reloadTrigger, reload, style }: IProps) => {
	const [data, reloadWorkflows] = useApiEffect(workflowsQuery_queryPrimaryByPatient, item.patient.id);

	useLazyEffect(() => {
		reloadWorkflows();
	}, [reloadTrigger]);

	if (data === undefined) {
		return <></>;
	}

	return (
		<div
			className='df-col gap-16'
			style={style}>
			<WorkflowsByPatientCard
				data={data}
				reload={reload}
			/>
		</div>
	);
};
