import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';
import { IExactOnlineSettingsDto, exactOnlineSettingsCommand_disable, exactOnlineSettingsCommand_enable, exactOnline_startAuth } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { ExactOnlineAdminSettingsCardX } from './ExactOnlineAdminSettingsCardX';
import { ExactOnlineOtherSettingsCardX } from './ExactOnlineOtherSettingsCardX';
import { ExactOnlineUserSettingsCardX } from './ExactOnlineUserSettingsCardX';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends IReloadTriggerProps {
	settings: IExactOnlineSettingsDto;
	reload: VoidFunction;
}

export const ExactOnlineSettingsTab = ({ settings, reloadTrigger, reload }: IProps) => {
	const strings = useLocalization();
	const isSuperUser = useLoggedInUserIsSuperUser();
	const getAuthUrl = useApiCall(exactOnline_startAuth);
	const openInNewtab = useOpenInNewTab();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onRegister = async () => {
		const r = await getAuthUrl();
		if (r.isSuccess) {
			openInNewtab(r.result, true);
		}
	};

	const onActivate = () => {
		open(
			<AlertDialog
				open
				title={strings.activateExactOnlineIntegration}
				content={strings.doYouWantToActivateExactOnlineIntegrationQuestion}
				acceptText={strings.yesCommaActivate}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactOnlineSettingsCommand_enable()}
				accept={confirm}
			/>
		);
	};
	const onDeactivate = () => {
		open(
			<AlertDialog
				open
				title={strings.deactivateExactOnlineIntegration}
				content={strings.doYouWantToDeactivateExactOnlineIntegrationQuestion}
				acceptText={strings.yesCommaDeactivate}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => exactOnlineSettingsCommand_disable()}
				accept={confirm}
			/>
		);
	};

	return (
		<div
			className='df-col gap-16 fg1'
			style={{ padding: 16, maxWidth: 1280 }}>
			<ExactOnlineUserSettingsCardX
				reloadTrigger={reloadTrigger}
				reload={reload}
				onRegister={onRegister}
				onActivate={onActivate}
				onDeactivate={onDeactivate}
				settings={settings}
			/>
			{settings.hasOAuthOptions && settings.hasOAuthToken && (
				<ExactOnlineOtherSettingsCardX
					reloadTrigger={reloadTrigger}
					reload={reload}
					settings={settings}
				/>
			)}
			{isSuperUser && (
				<ExactOnlineAdminSettingsCardX
					reloadTrigger={reloadTrigger}
					reload={reload}
					onRegister={onRegister}
					onActivate={onActivate}
					onDeactivate={onDeactivate}
				/>
			)}
		</div>
	);
};
