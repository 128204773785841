import { DialogProps } from '@material-ui/core';
import React, { useState } from 'react';
import { SelectPatientByIdForm } from 'shared/patientSearch/SelectPatientByIdForm';
import { RfiaForPatientForm } from './RfiaForPatientForm';

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const RfiaForm = ({ cancel, confirm, ...rest }: IProps) => {
	const [patientId, setPatientId] = useState<string>();

	if (patientId === undefined) {
		return (
			<SelectPatientByIdForm
				{...rest}
				confirm={setPatientId}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<RfiaForPatientForm
				{...rest}
				patientId={patientId}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	}
};
