import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { IPurchasesQueryMetadata, PurchasesPrefilter, PurchasesPrefilters } from 'gen/ApiClient';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { createPurchasesPrefilterBadgeChipXRecord } from './createPurchasesPrefilterBadgeChipXRecord';

interface IProps {
	selectedPrefilter: PurchasesPrefilter;
	setSelectedPrefilter: (key: PurchasesPrefilter) => void;
	metadata: IPurchasesQueryMetadata;
	style?: React.CSSProperties;
}

export const PurchasesListPrefilter = ({ selectedPrefilter, setSelectedPrefilter, metadata, style }: IProps) => {
	const endIconRecord = useMemo(() => createPurchasesPrefilterBadgeChipXRecord(metadata), [metadata]);
	const { purchasesPrefilterRecord } = useContext(RecordContext);

	return (
		<div
			className='df-col'
			style={style}>
			{PurchasesPrefilters.map(prefilterKeyType => (
				<RadioIsSelectedButton
					key={prefilterKeyType}
					isSelected={prefilterKeyType === selectedPrefilter}
					onClick={() => setSelectedPrefilter(prefilterKeyType)}
					endIcon={endIconRecord[prefilterKeyType]}>
					{purchasesPrefilterRecord[prefilterKeyType]}
				</RadioIsSelectedButton>
			))}
		</div>
	);
};
