import { createType } from 'framework/utils/createType';

export const PatientDetailTabTypes = createType(
	'overview',
	'personalData',
	'noahData',
	'annotationsAndFollowUp',
	'appointments',
	'sales',
	'workflows',
	'documents',
	'tenders'
);
export type PatientDetailTabType = typeof PatientDetailTabTypes[number];
