import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { IPatient, IPatientSummary, PatientStatus } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

const PatientStatusToColorXRecord: Record<PatientStatus, ColorX> = {
	Active: 'success',
	Deceased: 'error',
	Inactive: 'warning',
};

interface IProps {
	item: IPatient | IPatientSummary;
	style?: React.CSSProperties;
	onClick?: VoidFunction;
}

export const PatientStatusChip = ({ item, style, onClick }: IProps) => {
	const { patientStatusRecord } = useContext(RecordContext);

	return (
		<ChipX
			color={PatientStatusToColorXRecord[item.status as PatientStatus]}
			onClick={onClick}
			label={patientStatusRecord[item.status as PatientStatus]}
			style={style}
		/>
	);
};
