import { IRosaEventDto, IRosaViewPreferences, RosaCalendarViewType } from 'gen/ApiClient';
import React from 'react';
import { IRosaCalendarWithIsSelected } from './IRosaCalendarWithIsSelected';
import { IRosaHpDtoWithIsSelected } from './IRosaHpDtoWithIsSelected';
import { IRosaMotiveSummaryDtoWithIsSelected } from './IRosaMotiveSummaryDtoWithIsSelected';

interface IContext {
	init: VoidFunction;
	reload: VoidFunction;
	date: Date;
	setDate: (val: Date) => void;
	after: Date;
	before: Date;
	view: RosaCalendarViewType;
	setView: React.Dispatch<React.SetStateAction<RosaCalendarViewType>>;
	motives: IRosaMotiveSummaryDtoWithIsSelected[];
	myCalendars: IRosaCalendarWithIsSelected[];
	otherCalendars: IRosaCalendarWithIsSelected[];
	calendars: IRosaCalendarWithIsSelected[];
	hps: IRosaHpDtoWithIsSelected[];
	toggleCalendar: (calendarId: string) => void;
	toggleMotive: (motiveId: string) => void;
	toggleHp: (hpId: string) => void;
	events: IRosaEventDto[];
	viewPreferences: IRosaViewPreferences;
}

export const RosaCalendarContext = React.createContext<IContext>(undefined as any);
