import React from 'react';
import { Checkbox } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

interface IProps {
	selected: boolean;
	text: string;
}

export const AutocompleteCheckbox = ({ selected, text }: IProps) => {
	return (
		<>
			<Checkbox
				icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
				checkedIcon={<CheckBoxIcon fontSize='small' />}
				style={{ marginRight: 8 }}
				checked={selected}
			/>
			{text}
		</>
	);
};
