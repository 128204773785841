import { useTheme } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { IUserDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { UserChips } from './UserChips';
import { ActivityBadge } from './components/ActivityBadge';
import { UserAvatar } from './components/UserAvatar';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IUserDto;
	isSelected: boolean;
	toggle: VoidFunction;
	style?: React.CSSProperties;
}

export const UserListItemCardX = ({ item, isSelected, toggle, style }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [hovered, enter, leave] = useStateBoolean(false);

	return (
		<CardX
			raised={isSelected || hovered}
			className='df-row w100 gap-8'
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={toggle}
			style={{
				borderRight: isSelected ? `6px solid ${theme.palette.primary.main}` : 'unset',
				cursor: 'pointer',
				...style,
			}}>
			<div>
				<ActivityBadge
					isLoggedIn={item.isLoggedIn}
					style={{ marginRight: 16 }}>
					<UserAvatar user={item} />
				</ActivityBadge>
			</div>
			<div className='df-col fg1 gap-2'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.lastNameCommaFirstName}</span>
				<UserChips item={item} />
				<span style={{ fontSize: MinorFontSize }}>{`${strings.email}: ${item.email}`}</span>
				{Boolean(item.conventionedRizivNumber) && (
					<span style={{ fontSize: MinorFontSize }}>{`${strings.conventionedRIZIVNumberShort}: ${item.conventionedRizivNumber}`}</span>
				)}
			</div>
		</CardX>
	);
};
