import React, { useMemo, useState } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { ReturnsSelectableColumn } from './ReturnsSelectableColumn';
import { createReturnsColumnRecord } from './createReturnsColumnRecord';
import { createReturnsExportRecord } from './createReturnsExportRecord';
import { createReturnsHeaders } from './createReturnsHeaders';
import { ReturnsListViewContext } from './ReturnsListViewContext';

// enkel hier nodig
const DefaultColumns: ReturnsSelectableColumn[] = ['reference', 'created', 'returnDate', 'toSupplierIdentifier', 'countItems'];

export const ReturnsListViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [columns, setColumns] = useState<ReturnsSelectableColumn[]>(DefaultColumns);
	const colRecord = useMemo(() => createReturnsColumnRecord(strings), [strings]);
	const headers = useMemo(() => createReturnsHeaders(colRecord, strings), [colRecord, strings]);
	const exportRecord = useMemo(() => createReturnsExportRecord(strings), [strings]);

	return (
		<ReturnsListViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
				exportRecord: exportRecord,
			}}>
			{children}
		</ReturnsListViewContext.Provider>
	);
};
