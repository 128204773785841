import { CardProps, Divider } from '@material-ui/core';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { CodeBlock } from 'app/main/test/CodeBlock';
import { CollapseX } from 'framework/components/CollapseX';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import {
	rosaIntegrationCommand_disable,
	rosaIntegrationCommand_enable,
	rosaIntegrationCommand_remove,
	rosaIntegrationQuery_integrationStatus,
} from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RosaRegistrationRequestForm } from './RosaRegistrationRequestForm';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends IReloadTriggerProps, CardProps {
	reload: VoidFunction;
}

export const RosaAdminSettingsCardX = ({ reloadTrigger, reload, ...rest }: IProps) => {
	const strings = useLocalization();
	const [status, reloadStatus] = useApiEffect(rosaIntegrationQuery_integrationStatus);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadStatus();
	}, [reloadTrigger]);

	const onRegister = () => {
		open(
			<RosaRegistrationRequestForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onEnable = () => {
		open(
			<AlertDialog
				open
				title={`Enable ROSA integration`}
				content={`Do you want to enable ROSA integration?`}
				acceptText={`Yes!`}
				rejectText={`No, go back`}
				reject={cancel}
				acceptF={() => rosaIntegrationCommand_enable()}
				accept={confirm}
			/>
		);
	};

	const onDisable = () => {
		open(
			<AlertDialog
				open
				title={`Disable ROSA integration`}
				content={`Do you want to disable ROSA integration?`}
				acceptText={`Yes!`}
				rejectText={`No, go back`}
				reject={cancel}
				acceptF={() => rosaIntegrationCommand_disable()}
				accept={confirm}
			/>
		);
	};

	const onRemove = () => {
		open(
			<AlertDialog
				open
				title={`Remove integration record`}
				content={`Do you want to remove integration record?`}
				acceptText={`Yes, remove!`}
				rejectText={`No, go back`}
				reject={cancel}
				acceptF={() => rosaIntegrationCommand_remove()}
				accept={confirm}
			/>
		);
	};

	if (status === undefined) {
		return <></>;
	}

	return (
		<CardX
			{...rest}
			className='df-col gap-8'>
			<Typography16pxBold>{`Integration status (only visible for super-users!)`}</Typography16pxBold>
			<LabelledPropertyX
				label='ROSA integration token'
				iconPosition='start'
				icon={<StatusIcon status={status.hasToken ? 'success' : 'error'} />}>
				{status.hasToken ? `${status.tokenFirstFourChars}****` : <em>{strings.notApplicableAbbreviation}</em>}
			</LabelledPropertyX>
			<LabelledPropertyX
				label='ROSA organization ID'
				iconPosition='start'
				icon={<StatusIcon status={status.hasToken ? 'success' : 'error'} />}>
				{status.hasToken ? status.organizationId : <em>{strings.notApplicableAbbreviation}</em>}
			</LabelledPropertyX>
			<LabelledPropertyX
				label='ROSA organization ID (registered on ROOT, needed for callback)'
				iconPosition='start'
				icon={<StatusIcon status={status.hasToken && status.isRegisteredOnRootWithSameOrganizationId ? 'success' : 'error'} />}>
				{status.hasToken ? status.organizationIdRegisteredOnRoot : <em>{strings.notApplicableAbbreviation}</em>}
			</LabelledPropertyX>
			<LabelledPropertyX
				label='ROSA environment'
				iconPosition='start'
				icon={<StatusIcon status={status.hasToken ? 'success' : 'error'} />}>
				{status.hasToken ? status.environment : <em>{strings.notApplicableAbbreviation}</em>}
			</LabelledPropertyX>
			<Divider />
			<div className='df-row-ac gap-8'>
				<TextLinkButton
					startIcon={<LockOpenOutlinedIcon />}
					color='primary'
					onClick={onRegister}
					disabled={status.hasToken}>
					{`Register`}
				</TextLinkButton>
				<Divider orientation='vertical' />
				<TextLinkButton
					startIcon={<VerifiedUserOutlinedIcon />}
					color='primary'
					onClick={onEnable}
					disabled={status.isEnabled}>
					{`Enable`}
				</TextLinkButton>
				<Divider orientation='vertical' />
				<TextLinkButton
					startIcon={<BlockOutlinedIcon />}
					hoverColor='secondary'
					onClick={onDisable}
					disabled={status.isEnabled === false}>
					{`Disable`}
				</TextLinkButton>
				<Divider orientation='vertical' />
				<TextLinkButton
					startIcon={<DeleteOutlineOutlinedIcon />}
					onClick={onRemove}
					hoverColor='secondary'>
					{`Remove`}
				</TextLinkButton>
			</div>
			<Divider />
			<CollapseX collapseAfter>
				<CodeBlock
					value={status}
					style={{ margin: 0 }}
				/>
			</CollapseX>
		</CardX>
	);
};
