import { CardProps } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { IPatientDetail } from 'gen/ApiClient';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { PatientContactInfoCardContent } from '../contactInfo/PatientContactInfoCardContent';
import { PatientAttendingPhysiciansCardContent } from './PatientAttendingPhysiciansCardContent';
import { PatientBasicInformationCardContent } from './PatientBasicInformationCardContent';
import { PatientNumberingCardContent } from '../contactInfo/PatientNumberingCardContent';

const Width: number = 280;

interface IProps extends IReloadTriggerProps, CardProps {
	item: IPatientDetail;
	reload: VoidFunction;
}

export const PatientDetailOverviewCard = ({ item, reload, reloadTrigger, ...rest }: IProps) => {
	return (
		<CardX
			{...rest}
			className='df-row-wrap gap-16'>
			<PatientBasicInformationCardContent
				item={item.patient}
				reload={reload}
				style={{ width: Width }}
			/>
			<PatientAttendingPhysiciansCardContent
				item={item.patient}
				reload={reload}
				style={{ width: Width }}
			/>
			<PatientContactInfoCardContent
				item={item.patient}
				reload={reload}
				style={{ width: Width }}
			/>
			<PatientNumberingCardContent
				item={item.patient}
				reload={reload}
				style={{ width: Width }}
			/>
		</CardX>
	);
};
