import React, { useState } from 'react';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IEidData, patientsCommand_newFromEid, patientsCommand_updateEidData, patientsQuery_checkSsNumber } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { SelectAudiologistLocationAndLanguageForm } from 'shared/forms/SelectAudiologistLocationAndLanguageForm';
import { EidInfo } from './EidInfo';
import { EidReadDialog } from './EidReadDialog';
import { ISelectAudiologistLocationAndLanguageModel } from 'shared/forms/ISelectAudiologistLocationAndLanguageModel';

type Mode = 'info' | 'read' | 'confirmNew' | 'audiologistAndLocation';

interface IProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const EidForm = ({ confirm, cancel }: IProps) => {
	const notify = useSnackbarNotify();
	const strings = useLocalization();
	const [mode, setMode] = useState<Mode>('info');
	const [data, setData] = useState<IEidData>();
	const [check, isChecking] = useFormSubmit(patientsQuery_checkSsNumber);
	const [create, isCreating] = useFormSubmit(patientsCommand_newFromEid);
	const [update, isUpdating] = useFormSubmit(patientsCommand_updateEidData);

	const onRead = async (data: IEidData) => {
		const r = await check(data.socialSecurityNumber);
		if (r.isSuccess && r.result.exists === true) {
			await onUpdate(r.result.patientId!, data);
		} else {
			setData(data);
			setMode('confirmNew');
		}
	};

	const onUpdate = async (id: string, data: IEidData) => {
		const r = await update(id, data);
		if (r.isSuccess) {
			notify(strings.eIDDataRead);
			confirm(id);
		}
	};

	const onCreate = async (model: ISelectAudiologistLocationAndLanguageModel, data: IEidData) => {
		const r = await create({ eidData: data, ...model });
		if (r.isSuccess) {
			notify(strings.eIDDataRead);
			confirm(r.result.objectId);
		}
	};

	if (mode === 'info') {
		return (
			<EidInfo
				open
				cancel={cancel}
				goNext={() => setMode('read')}
			/>
		);
	} else if (mode === 'read') {
		return (
			<EidReadDialog
				open
				cancel={cancel}
				isSubmitting={isChecking || isCreating || isUpdating}
				onRead={onRead}
			/>
		);
	} else if (mode === 'confirmNew') {
		return (
			<AlertDialog
				open
				fullWidth
				content={strings.noPatientFoundWithThisSsnrCreateNewQuestion}
				acceptText={strings.yesCommaCreateNewPatient}
				rejectText={strings.noCloseThisScreen}
				reject={cancel}
				accept={() => setMode('audiologistAndLocation')}
			/>
		);
	} else if (mode === 'audiologistAndLocation' && data !== undefined) {
		return (
			<SelectAudiologistLocationAndLanguageForm
				open
				confirm={val => onCreate(val, data)}
				cancel={cancel}
				isSubmitting={isCreating}
			/>
		);
	} else {
		return <div></div>;
	}
};
