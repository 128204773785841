import { Collapse, Divider, TableRow, useTheme } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { SupplierDialog } from 'app/main/contacts/suppliers/SupplierDialog';
import { calcInventoryItemDetailRoute, calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { ExpandLessOrMoreIcon } from 'framework/components/ExpandLessOrMoreIcon';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { TableCellX } from 'framework/table/TableCellX';
import { TableDetailsRowX } from 'framework/table/TableDetailsRowX';
import { formatDate } from 'framework/utils/date/formatDate';
import { IRepair, RepairWarning } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { calcRepairDetailRoute } from '../../ProcessesRoutes';
import { RepairProcessStatusChipX } from '../RepairProcessStatusChipX';
import { RepairListItemStatusIcon } from './RepairListItemStatusIcon';
import { mapRepairToColorX } from './utils';

const MinorFontSize = 13;

interface IProps {
	item: IRepair;
	isExpanded: boolean;
	toggle: VoidFunction;
}

export const RepairTableRow = ({ item, isExpanded, toggle }: IProps) => {
	const strings = useLocalization();
	const color = useMemo(() => mapRepairToColorX(item), [item]);
	const tColor = useThemeColor(color);
	const theme = useTheme();
	const { push } = useHistory();
	const [isHovered, enter, leave] = useStateBoolean(false);
	const { open, cancel } = useDialogsContext();
	const { repairWarningRecord } = React.useContext(RecordContext);

	const onViewDetails = () => push(calcRepairDetailRoute(item.id));

	const onViewSupplier = () => {
		open(
			<SupplierDialog
				open
				id={item.supplierId}
				close={cancel}
			/>
		);
	};

	return (
		<React.Fragment>
			<TableRow
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<TableCellX>
					<div className='df-row gap-4'>
						<RepairListItemStatusIcon
							item={item}
							style={{ fontSize: 22, color: tColor }}
						/>
						<div className='df-col gap-2'>
							<TextLinkButton
								underline
								style={{ fontSize: 14 }}
								onClick={onViewDetails}>
								{item.reference}
							</TextLinkButton>
							<div className='df-row-ac gap-4'>
								{item.hasWarning && (
									<TooltipWithSpan title={repairWarningRecord[item.warning as RepairWarning]}>
										<StatusIcon
											status='warning'
											style={{ marginRight: 8 }}
										/>
									</TooltipWithSpan>
								)}
								<RepairProcessStatusChipX
									item={item}
									color={color}
								/>
							</div>
							{Boolean(item.externalReference) && (
								<span
									style={{
										fontSize: MinorFontSize,
										color: theme.palette.text.secondary,
									}}>{`${strings.shortExternalReference}: ${item.externalReference}`}</span>
							)}
						</div>
					</div>
				</TableCellX>
				<TableCellX>
					<div className='df-col gap-2'>
						<span>{formatDate(item.created)}</span>
						<span style={{ fontSize: MinorFontSize, color: theme.palette.text.secondary }}>
							{`${strings.sentToSupplier}: ${formatDate(item.sentToSupplierDate)}`}
						</span>
						<span style={{ fontSize: MinorFontSize, color: theme.palette.text.secondary }}>
							{`${strings.expectedDeliveryDate}: ${formatDate(item.expectedRedeliveryDate)}`}
						</span>
					</div>
				</TableCellX>
				<TableCellX>
					{item.isForPatient === true && (
						<TextLinkButton
							style={{ fontSize: MinorFontSize }}
							underline
							onClick={() => push(calcPatientDetailRoute(item.forPatientId!))}>
							{item.forPatientIdentifier}
						</TextLinkButton>
					)}
					{item.isForPatient === false && <span style={{ fontSize: MinorFontSize }}>{strings.notForAPatient}</span>}
				</TableCellX>
				<TableCellX>
					<TextLinkButton
						style={{ fontSize: MinorFontSize }}
						onClick={onViewSupplier}>
						{item.supplierIdentifier}
					</TextLinkButton>
				</TableCellX>
				<TableCellX align='right'>
					<div className='df-row-ac jc-e'>
						<span style={{ fontSize: MinorFontSize, fontWeight: 'unset' }}>
							{`# ${item.countItems.toString()} ${
								item.countItems === 1 || item.countItems === -1 ? strings.article.toLowerCase() : strings.articles.toLowerCase()
							}`}
						</span>
						<ColorOnHoverIconButton
							edge='end'
							size='small'
							color='primary'
							onClick={toggle}>
							<ExpandLessOrMoreIcon isExpanded={isExpanded} />
						</ColorOnHoverIconButton>
						<ColorOnHoverIconButton
							size='small'
							edge='end'
							color='primary'
							onClick={onViewDetails}>
							<VisibilityOutlinedIcon />
						</ColorOnHoverIconButton>
					</div>
				</TableCellX>
			</TableRow>
			<TableDetailsRowX
				colSpan={5}
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<Collapse in={isExpanded}>
					<div
						className='df-col'
						style={{ paddingBottom: 12 }}>
						<Divider
							variant='inset'
							style={{ marginTop: 8, marginBottom: 4 }}
						/>
						{item.lines.map(line => (
							<div
								className='df-row-ab'
								key={line.id}
								style={{ fontSize: MinorFontSize, gap: 4 }}>
								<span>{`${line.quantity.toString()} x`}</span>
								<TextLinkButton
									underline
									style={{ fontSize: MinorFontSize }}
									onClick={() => {
										if (line.inventoryItemId) {
											push(calcInventoryItemDetailRoute(line.inventoryItemId));
										} else {
											push(calcProductCatalogDetailRoute(line.productCatalogItemId));
										}
									}}>
									{line.identifier}
								</TextLinkButton>
							</div>
						))}
					</div>
				</Collapse>
			</TableDetailsRowX>
		</React.Fragment>
	);
};
