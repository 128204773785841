import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { cashRegisterCommand_new, ICashTransferModel } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

const createSchema = (strings: IStrings) => {
	return yup.object<ICashTransferModel>({
		description: yup.string().required(strings.formRequired(strings.description)),
		value: yup.number().notOneOf([0]).required(strings.formRequired(strings.amountAsValue)),
	});
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const CashTransferForm = ({ confirm, cancel, ...rest }: IProps) => {
	const [submit, isSubmitting] = useFormSubmit(cashRegisterCommand_new);
	const strings = useLocalization();

	const handleSubmit = async (values: ICashTransferModel, helpers: FormikHelpers<ICashTransferModel>) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	return (
		<Formik<ICashTransferModel>
			validateOnMount
			initialValues={{
				description: '',
				value: 0,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					maxWidth='sm'
					fullWidth>
					<DialogTitle>{strings.createWhat(strings.cashTransaction)}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<ICashTransferModel>
							name='description'
							label={strings.description}
						/>
						<FormMoneyField<ICashTransferModel>
							name='value'
							label='Bedrag (positief of negatief)'
							allowNegative
							helperText={`Negatief als geld uit de kassa, positief als geld in kassa`}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.create}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
