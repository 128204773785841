import { DeliveryExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createExportRecord = (strings: IStrings): Record<DeliveryExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		CountItems: `# ${strings.items}`,
		DeliveryDate: strings.deliveryDate,
		ExternalReference: strings.shortExternalReference,
		LocationIdentifier: strings.location,
		Reference: strings.reference,
		SupplierIdentifier: strings.supplier,
		DispatchDate: strings.dispatchDate,
		DispatchReference: strings.dispatchReference,
	};
};
