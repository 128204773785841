import { DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { DraggableDialog } from 'framework/dialogs/DraggableDialog';
import { IValidateResponse } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { useFormSubmit } from '../hooks/useFormSubmit';
import { useSnackbarNotify } from '../hooks/useSnackbarNotify';
import { Try } from '../Try';
import { isNullOrUndefined } from '../utils/isNullOrUndefined';
import { CancelSubmitFormDialogActions } from './CancelSubmitFormDialogActions';
import { FormTextField } from './FormTextField';
import { handleValidateResponse } from './utils/handleValidateResponse';
import { setFieldError } from './utils/setFieldError';

interface IModel {
	email: string;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		email: yup.string().email().required(strings.formRequired(strings.email)),
	});
};

interface IProps extends DialogProps {
	formTitle: string;
	notifyMessage?: string;
	initialValue: string | undefined;
	confirm: VoidFunction;
	cancel: VoidFunction;
	submitFunction: (value: string) => Promise<Try<IValidateResponse>>;
	submitText: string;
	cancelText?: string;
}

export const EmailModelForm = ({ cancelText, notifyMessage, formTitle, initialValue, submitFunction, confirm, cancel, submitText, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [submit, isSubmitting] = useFormSubmit(submitFunction);
	const schema = useMemo(() => createSchema(strings), [strings]);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		if (isNullOrUndefined(values.email)) {
			setFieldError<IModel>('email', strings.formRequired(strings.email), helpers);
		} else {
			const updateResult = await submit(values.email);
			if (handleValidateResponse(updateResult, helpers, 'email')) {
				if (notifyMessage) {
					notify(notifyMessage);
				}
				confirm();
			}
		}
	};

	return (
		<Formik
			validateOnMount
			validationSchema={schema}
			initialValues={{ email: initialValue ?? '' }}
			onSubmit={handleSubmit}>
			<Form>
				<DraggableDialog
					fullWidth
					maxWidth='xs'
					title={formTitle}
					{...rest}>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<IModel>
							name='email'
							label={strings.email}
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={submitText}
						cancelText={cancelText}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</DraggableDialog>
			</Form>
		</Formik>
	);
};
