import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { InventoryManagementPageBreadcrumb } from '../InventoryManagementPageBreadcrumb';
import { InventoryItemsRoute } from '../routes';

export const InventoryItemsBreadcrumbs = () => {
    const strings = useLocalization();

    return (
        <React.Fragment>
            <InventoryManagementPageBreadcrumb />
            <PageBreadcrumbDivider />
            <PageBreadcrumb text={strings.serialNumbers} route={InventoryItemsRoute} />
        </React.Fragment>
    );
};
