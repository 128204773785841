import { addDays, addMonths, addWeeks, addYears } from 'date-fns';
import { AgendaViewType } from './agenda/AgendaViewType';

export const calculateNextDate = (val: Date, view: AgendaViewType): Date => {
	return nextRecord[view](val);
};

const nextRecord: Record<AgendaViewType, (val: Date) => Date> = {
	day: (val: Date) => addDays(val, 1),
	week: (val: Date) => addWeeks(val, 1),
	month: (val: Date) => addMonths(val, 1),
	year: (val: Date) => addYears(val, 1),
	list: (val: Date) => addDays(val, 1),
	timeline: (val: Date) => addDays(val, 1),
};
