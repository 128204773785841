import { useField } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SelectField } from 'framework/components/select/SelectField';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { nameOf } from 'framework/utils/nameOf';
import { IStandardDocumentDto, LanguageOption } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';
import { toLanguageOptions } from './utils/toLanguageOptions';

interface IProps<TModel> extends IFormProps<TModel> {
	documents: IStandardDocumentDto[];
}

export const FormSelectStandardDocumentField = <TModel extends unknown>({ name, label, documents, ...rest }: IProps<TModel>) => {
	const strings = useLocalization();
	const options = useMemo(() => toLanguageOptions(documents), [documents]);
	// eslint-disable-next-line
	const [field, meta, helpers] = useField<string>(nameOf(name));
	const [language, setLanguage] = useState<LanguageOption>('nl');
	const { localizedLanguageRecord } = useContext(RecordContext);

	useEffect(() => {
		helpers.setValue(documents.find(t => t.language === language)!.id!);
		// eslint-disable-next-line
	}, [language, documents]);

	return (
		<SelectField<LanguageOption>
			label={strings.language}
			options={options}
			value={language}
			onChange={setLanguage}
			renderValue={t => localizedLanguageRecord[t]}
			getKey={t => t}
		/>
	);
};
