import { Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { SuccessLabelledProperty } from 'framework/components/labelledProperty/SuccessLabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { invoiceForwardingSettingsQuery_status } from 'gen/ApiClient';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { InvoiceForwardingRoute } from '../integrations/IntegrationsRoutes';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends IReloadTriggerProps {
	className?: string;
	style?: React.CSSProperties;
}

export const InvoiceForwardingDashboardCard = ({ style, className, reloadTrigger }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const [data, reload] = useApiEffect(invoiceForwardingSettingsQuery_status);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	const onGoToInvoiceForwarding = () => {
		push(InvoiceForwardingRoute);
	};

	if (data === undefined) {
		return <></>;
	}

	return (
		<Card
			className={className}
			style={style}>
			<CardTitleTypography
				title={strings.invoiceForwardingIntegration}
				withPadding
			/>

			<CardContent style={{ paddingTop: 0, paddingBottom: 16 }}>
				<div
					className='df-col'
					style={style}>
					{data.countSalesNotIgnoredNorForwarded === 0 && (
						<SuccessLabelledProperty
							successMessage={strings.allForwarded}
							onClick={onGoToInvoiceForwarding}
							spacing={3}
						/>
					)}
					{data.countSalesNotIgnoredNorForwarded !== 0 && (
						<ErrorLabelledProperty
							error={strings.countInvoicesNotForwarded(data.countSalesNotIgnoredNorForwarded)}
							onClick={onGoToInvoiceForwarding}
							spacing={3}
						/>
					)}
				</div>
			</CardContent>
		</Card>
	);
};
