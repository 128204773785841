import { CardProps, Divider } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { IOption } from 'framework/IOption';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { INoahUserSetupsQueryMetadata, INoahUserSetupsQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';

interface IProps extends CardProps {
	params: INoahUserSetupsQueryParams;
	setParams: React.Dispatch<React.SetStateAction<INoahUserSetupsQueryParams>>;
	metadata: INoahUserSetupsQueryMetadata;
}

const exclusions: (keyof INoahUserSetupsQueryParams)[] = [];

export const NoahUserSetupsListFilterCard = ({ params, setParams, metadata, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const onFilter = (pars: INoahUserSetupsQueryParams) => setParams(onResetToPage1(pars));
	const isFilteredX = useMemo(() => isFiltered(params, exclusions), [params]);
	const onClear = () => setParams(onResetToPage1(clearParams(params, exclusions)));

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<BooleanRadioFilterComponent
					label={`${strings.linked}?`}
					trueText={strings.linked}
					falseText={strings.notLinked}
					selected={params.isLinked}
					setSelected={value => onFilter({ ...params, isLinked: value })}
					icon={<HelpOutlineIcon />}
				/>
				<StringListFilterComponent
					options={metadata.clientIds}
					selected={params.clientIds}
					setSelected={vals => onFilter({ ...params, clientIds: vals })}
					label={`Client ids`}
					icon={<HelpOutlineIcon />}
				/>
				<ListFilterComponent<number>
					options={metadata.moduleIds.map<IOption<number>>(t => ({ id: t, identifier: t.toString() }))}
					selected={params.moduleIds}
					setSelected={vals => onFilter({ ...params, moduleIds: vals })}
					label={`Module ids`}
					icon={<HelpOutlineIcon />}
				/>
			</div>
		</CardX>
	);
};
