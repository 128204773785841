import { CardProps } from '@material-ui/core';
import React from 'react';
import { AnnotationsCard } from 'shared/annotationsX/AnnotationsCard';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';

interface IProps extends IReloadTriggerProps, CardProps {
	patientId: string;
}

export const PatientAnnotationsCard = ({ patientId, reloadTrigger, ...rest }: IProps) => {
	return (
		<AnnotationsCard
			contextId={patientId}
			contextType='Patient'
			reloadTrigger={reloadTrigger}
			{...rest}
		/>
	);
};
