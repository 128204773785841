import { IInventoryItem, IProductCatalogItem } from 'gen/ApiClient';
import { IPurchaseFormLine } from './IPurchaseFormLine';

export const inventoryItemToLine = (t: IInventoryItem, pc: IProductCatalogItem, isCredit: boolean): IPurchaseFormLine => {
	return {
		id: t.id,
		description: t.identifier,
		inventoryItemId: t.id,
		productCatalogItemId: t.productCatalogId,
		quantity: isCredit ? -1 : 1,
		vatPercentage: t.vatPercentage,
		productCatalogItem: pc,
		unitListPrice: pc.purchaseListPrice,
		serialNumber: t.serialNumber ?? '',
		unitPrice: pc.purchaseListPrice,
		assuredLineId: undefined,
		assuredProductCatalogItemId: undefined,
		canChangeQuantity: false,
		assuredSerialNumber: undefined,
		isCreditLine: isCredit,
		creditedAssuranceLineId: undefined,
	};
};
