import { Dialog, DialogContent, DialogProps, DialogTitle, Divider } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { setFieldError } from 'framework/forms/utils/setFieldError';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Try } from 'framework/Try';
import {
	ICreateResponse,
	ICreateValidateResponse,
	IDocumentFromTemplateModel,
	IStandardDocumentsQueryParams,
	standardDocumentsQuery_query,
	templatesQuery_byType,
	TemplateType,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useDocumentEditor } from 'shared/dialogs/useDocumentEditor';
import { RecordContext } from 'shared/records/RecordContext';
import { FormSelectStandardDocumentField } from './FormSelectStandardDocumentField';
import { FormSelectTemplateField } from './FormSelectTemplateField';
import { ISelectTemplateModel } from './ISelectTemplateModel';

const createSchema = (strings: IStrings): yup.ObjectSchema<ISelectTemplateModel> => {
	return yup
		.object<ISelectTemplateModel>({
			templateId: yup.string(),
			standardDocumentId: yup.string(),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: (documentId: string) => void;
	submitF: (model: ISelectTemplateModel) => Promise<Try<ICreateValidateResponse | ICreateResponse<IDocumentFromTemplateModel>>>;
	cancel: VoidFunction;
	type: TemplateType;
	deferOpenEditor?: boolean;
	typeOverrideName?: string;
	submitText?: string;
	cancelText?: string;
	title?: string;
	date?: Date;
}

type Mode = 'localTemplate' | 'standardDocument';

export const SelectTemplateOrStandardDocumentFromPredefinedTypeForm = ({
	title,
	submitText,
	cancelText,
	confirm,
	cancel,
	submitF,
	type,
	deferOpenEditor = false,
	typeOverrideName,
	date,
	...rest
}: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(submitF);
	const [openEditor] = useDocumentEditor();
	const [templates] = useApiEffect(templatesQuery_byType, type); // TODO must come from type!
	const qParams = useMemo<IStandardDocumentsQueryParams>(() => ({ date: date, type: type }), [date, type]);
	const [standardDocuments] = useApiEffect(standardDocumentsQuery_query, qParams);
	const { templateTypeRecord } = useContext(RecordContext);
	const [mode, setMode] = useState<Mode>('standardDocument');
	const hasBothStandardDocumentsAndTemplates = useMemo(
		() => templates !== undefined && templates.length > 0 && standardDocuments !== undefined && standardDocuments.length > 0,
		[templates, standardDocuments]
	);

	useEffect(() => {
		if (standardDocuments !== undefined && standardDocuments.length > 0) {
			setMode('standardDocument');
		} else {
			setMode('localTemplate');
		}
	}, [templates, standardDocuments]);

	const handleSubmit = async (values: ISelectTemplateModel, helpers: FormikHelpers<ISelectTemplateModel>) => {
		const r = await submit(
			mode === 'standardDocument'
				? { templateId: undefined, standardDocumentId: values.standardDocumentId }
				: { templateId: values.templateId, standardDocumentId: undefined }
		);
		if (r.isSuccess) {
			if (r.result.hasError) {
				setFieldError<ISelectTemplateModel>('templateId', strings.error, helpers);
				setFieldError<ISelectTemplateModel>('standardDocumentId', strings.error, helpers);
			} else {
				confirm(r.result.objectId);
				if (deferOpenEditor === false) {
					const canViewOnline =
						mode === 'localTemplate'
							? templates?.find(t => t.id === values.templateId)?.canViewOnline
							: standardDocuments?.find(t => t.id === values.standardDocumentId)?.canViewOnline;
					if (canViewOnline === true) {
						openEditor(r.result.objectId);
					}
				}
			}
		}
	};

	if (templates === undefined || standardDocuments === undefined) {
		return <div></div>;
	}

	return (
		<Formik<ISelectTemplateModel>
			validateOnMount
			initialValues={{
				templateId: '',
				standardDocumentId: '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			{props => (
				<Form>
					<Dialog
						fullWidth
						{...rest}>
						<DialogTitle>{title ?? `${strings.selectWhat(strings.template)}: ${templateTypeRecord[type]}`}</DialogTitle>
						<DialogContent
							className='df-col'
							dividers>
							{hasBothStandardDocumentsAndTemplates && (
								<>
									<LightBulbNotification>
										<div>{strings.standardDocumentWarning(typeOverrideName ? typeOverrideName : templateTypeRecord[type])}</div>
									</LightBulbNotification>
									<Divider style={{ marginTop: 16, marginBottom: 16 }} />
									<ExtendedRadioGroup<Mode>
										selected={mode}
										setSelected={setMode}
										options={[
											{ value: 'standardDocument', label: strings.standardisedVersion, caption: strings.createDocumentFromStandardisedVersion },
											{ value: 'localTemplate', label: strings.personalisedVersion, caption: strings.createDocumentFromPersonalisedVersion },
										]}
									/>
									<Divider style={{ marginTop: 16, marginBottom: 16 }} />
								</>
							)}
							{mode === 'standardDocument' && (
								<FormSelectStandardDocumentField<ISelectTemplateModel>
									name='standardDocumentId'
									label='Does not matter'
									documents={standardDocuments}
								/>
							)}
							{mode === 'localTemplate' && (
								<FormSelectTemplateField<ISelectTemplateModel>
									name='templateId'
									label={strings.template}
									required
									templates={templates}
								/>
							)}
						</DialogContent>
						<CancelSubmitFormDialogActions<ISelectTemplateModel>
							cancel={cancel}
							isSubmitting={isSubmitting}
							cancelText={cancelText}
							submitText={submitText ?? strings.select}
						/>
					</Dialog>
				</Form>
			)}
		</Formik>
	);
};
