import { DialogProps } from '@material-ui/core';
import React from 'react';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { ITemplate, templatesCommand_updateName } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	template: ITemplate;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const TemplateNameForm = ({ template, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<StringModelForm
			label={strings.name}
			formTitle={strings.changeWhat(strings.name)}
			notifyMessage={strings.changedWhat(strings.name)}
			submitFunction={val => templatesCommand_updateName(template.id, val)}
			initialValue={template.name}
			submitText={strings.update}
			{...rest}
			fullWidth
			maxWidth='sm'
		/>
	);
};
