import { IHeader, TableCellAlign } from './IHeader';

export function createEmptyHeader<T>(
	key: string,
	evaluate: (inst: T, index: number, isExpanded?: boolean, toggleExpand?: VoidFunction) => string | JSX.Element | undefined,
	align?: TableCellAlign,
	onlyShowOnHover: boolean = false,
	cellStyle?: React.CSSProperties
): IHeader<T> {
	return { text: '', key: key, evaluate: evaluate, onlyShowOnHover, align, cellStyle: cellStyle };
}
