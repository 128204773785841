import { Grid, GridSize } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useResizeObserver } from 'framework/hooks/useResizeObserver';
import { IDashboardData } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { KpiCard } from './components/KpiCard';
import { KpiSelector } from './KpiSelector';

interface IProps {
	data: IDashboardData;
	selector: KpiSelector;
}

export const DashboardKpisGrid = ({ data, selector }: IProps) => {
	const [ref, width] = useResizeObserver();
	const gridSize = useMemo(() => widthToGridSize(width), [width]);
	const strings = useLocalization();

	return (
		<Grid
			container
			spacing={2}
			style={{ paddingRight: 0 }}
			ref={ref}>
			<GridItemKpiCard size={gridSize}>
				<KpiCard
					data={data.salesKpi}
					description={strings.invoiceAndCash}
					title={strings.sales}
					selector={selector}
				/>
			</GridItemKpiCard>
			<GridItemKpiCard size={gridSize}>
				<KpiCard
					data={data.patientsKpi}
					title={strings.patients}
					description={strings.new}
					selector={selector}
				/>
			</GridItemKpiCard>
			<GridItemKpiCard size={gridSize}>
				<KpiCard
					data={data.rfiaIntakeKpi}
					title={strings.requestForInsuranceAllowancesShortened}
					description={strings.startup}
					selector={selector}
				/>
			</GridItemKpiCard>
			<GridItemKpiCard size={gridSize}>
				<KpiCard
					data={data.rfiaHandoverKpi}
					title={strings.requestForInsuranceAllowanceShort}
					description={strings.handover}
					selector={selector}
				/>
			</GridItemKpiCard>
		</Grid>
	);
};

const widthToGridSize = (width: number): GridSize => {
	if (width >= 1000) {
		return 3;
	} else {
		return 6;
	}
};

interface IGridItemKpiCardProps {
	children: any;
	size: GridSize;
}

const GridItemKpiCard = ({ children, size }: IGridItemKpiCardProps) => {
	return (
		<Grid
			item
			xs={size}>
			{children}
		</Grid>
	);
};
