import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	commissionPayoutsQuery_concurrencyToken,
	commissionPayoutsQuery_queryForUser,
	ICommissionPayout,
	ICommissionPayoutsQueryParams,
	IQueryResult,
} from 'gen/ApiClient';
import { myCommissionPayoutsQueryParamsLsKey } from '../../../../../localStorageKeys';
import { DefaultParams } from './DefaultParams';
import { MyQueryContext } from './MyQueryContext';

export const MyQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<ICommissionPayout>, ICommissionPayoutsQueryParams>
			defaultParams={DefaultParams}
			queryF={commissionPayoutsQuery_queryForUser}
			concurrencyF={commissionPayoutsQuery_concurrencyToken}
			localStorageKey={myCommissionPayoutsQueryParamsLsKey}
			context={MyQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
