import { CardProps, Divider } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { SuppliersCacheContext } from 'app/main/contacts/suppliers/SuppliersCacheContext';
import { LocationsPreloadCacheContext } from 'app/main/settings/locations/LocationsPreloadCacheContext';
import { useUsersAsFilterOptions } from 'app/main/settings/users/useUsersAsFilterOptions';
import { IOption } from 'framework/IOption';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IOrdersQueryMetadata, IOrdersQueryParams, OrderProcessStatuses } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { OrdersListPrefilter } from './OrdersListPrefilter';

interface IProps extends CardProps {
	params: IOrdersQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IOrdersQueryParams>>;
	metadata: IOrdersQueryMetadata;
}

export const OrdersListFilterCard = ({ params, setParams, metadata, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const { orderStatusRecord } = React.useContext(RecordContext);
	const fUsers = useUsersAsFilterOptions();
	const [suppliers] = usePreloadCacheContext(SuppliersCacheContext);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const isFilteredX = useMemo(() => isFiltered(params, []), [params]);
	const onFilter = (pars: IOrdersQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params, [])));

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<OrdersListPrefilter
				selectedPrefilter={params.prefilter}
				setSelectedPrefilter={key => onFilter({ ...params, prefilter: key })}
				metadata={metadata}
			/>
			<Divider />
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<ListFilterComponent<string>
					options={OrderProcessStatuses.map<IOption<string>>(t => ({ id: t, identifier: orderStatusRecord[t] }))}
					selected={params.statuses}
					setSelected={vals => onFilter({ ...params, statuses: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.status}
				/>
				<DateFilterItem
					label={strings.dateCreated}
					after={params.createdAfter}
					before={params.createdBefore}
					setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
				/>
				<DateFilterItem
					label={strings.deliveryDate}
					after={params.deliveredAfter}
					before={params.deliveredBefore}
					setSelected={(after, before) => onFilter({ ...params, deliveredAfter: after, deliveredBefore: before })}
				/>
				{suppliers && (
					<ListFilterComponent<string>
						label={strings.suppliers}
						selected={params.supplierIds}
						options={suppliers}
						setSelected={value => onFilter({ ...params, supplierIds: value })}
						icon={<HelpOutlineIcon />}
					/>
				)}
				{fUsers && (
					<ListFilterComponent<string>
						options={fUsers}
						selected={params.handlingUserIds}
						setSelected={vals => onFilter({ ...params, handlingUserIds: vals })}
						label={strings.user}
						icon={<HelpOutlineIcon />}
						title={strings.user}
					/>
				)}
				{locations && (
					<ListFilterComponent<string>
						options={locations}
						selected={params.locationIds}
						setSelected={vals => onFilter({ ...params, locationIds: vals })}
						label={strings.location}
						icon={<HelpOutlineIcon />}
						title={strings.location}
					/>
				)}
			</div>
		</CardX>
	);
};
