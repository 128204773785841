import React from 'react';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { useLocalization } from 'localization/useLocalization';
import { DialogProps } from '@material-ui/core';
import { generalPractitionersCommand_patchRizivNumber, IGeneralPractitioner } from 'gen/ApiClient';
import { RizivNumberMask } from 'shared/RizivNumberMask';

interface IProps extends DialogProps {
	generalPractitioner: IGeneralPractitioner;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchGeneralPractitionerRizivNumberForm = ({ generalPractitioner, ...props }: IProps) => {
	const strings = useLocalization();

	return (
		<StringModelForm
			mask={RizivNumberMask}
			formTitle={strings.changeRIZIVNumber}
			label={strings.RIZIVNumber}
			notifyMessage={strings.generalPractitionerUpdated}
			submitFunction={val => generalPractitionersCommand_patchRizivNumber({ generalPractitionerId: generalPractitioner.id, rizivNumber: val })}
			initialValue={generalPractitioner.rizivCode ?? ''}
			submitText={strings.update}
			{...props}
		/>
	);
};
