import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IAddRepairLineRequest, IInventoryItem, repairsCommand_addLine } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormRepairReasonsField } from './FormRepairReasonsField';

const createSchema = (strings: IStrings) => {
	return yup.object<IAddRepairLineRequest>({
		inventoryItemId: yup.string(),
		productCatalogId: yup.string().required(),
		quantity: yup.number().defined(),
		reasons: yup.mixed(),
		repairId: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	repairId: string;
	item: IInventoryItem;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const AddInventoryItemToRepairForm = ({ repairId, item, confirm, cancel, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [add, isSubmitting] = useFormSubmit(repairsCommand_addLine);
	const strings = useLocalization();

	const handleSubmit = async (values: IAddRepairLineRequest, helpers: FormikHelpers<IAddRepairLineRequest>) => {
		const r = await add(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.itemAddedToRepair);
			confirm(repairId);
		} else {
			notify(r.result.errors.map(t => t.error).join(`\n`), 'error');
		}
	};

	return (
		<Formik<IAddRepairLineRequest>
			validateOnMount
			initialValues={{
				repairId: item.id,
				productCatalogId: item.productCatalogId,
				inventoryItemId: item.id,
				quantity: 1,
				reasons: [],
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					maxWidth='sm'
					fullWidth>
					<DialogTitle>{strings.addItemForRepair}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<div className='df-col'>
							<FormRepairReasonsField<IAddRepairLineRequest>
								name='reasons'
								label={strings.reasons}
							/>
						</div>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.update}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
