import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Paper, useTheme } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { TextLink } from 'framework/components/TextLink';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { createHeader } from 'framework/table/createHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { TablePaging } from 'framework/table/TablePaging';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { IProductCatalogItem, ISearchNoahDevicesQueryParams, productCatalogQuery_searchNoahDevices } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { createSelectHeader } from 'shared/table/createSelectHeader';
import { SuppliersChips } from '../../../productCatalog/detail/SuppliersChips';

const DefaultParams: ISearchNoahDevicesQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'manufacturer' },
	pageParams: { index: 1, size: 10 },
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	jsxTitle: JSX.Element;
	isSubmitting: boolean;
	initialSearch: string;
	onNew: VoidFunction;
}

export const SelectProductCatalogItemForm = ({ jsxTitle, cancel, confirm, isSubmitting, initialSearch, onNew, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(productCatalogQuery_searchNoahDevices, { ...DefaultParams, searchString: initialSearch });

	const headers = [
		createSortableHeader<IProductCatalogItem>(strings.manufacturer, 'manufacturer', t => t.manufacturer),
		createSortableHeader<IProductCatalogItem>(strings.model, 'model', t => t.model),
		createHeader<IProductCatalogItem>(strings.supplierSingularOrPlural, t => <SuppliersChips suppliers={t.suppliers} />),
		createSelectHeader<IProductCatalogItem>(
			t => confirm(t.id),
			t => isSubmitting
		),
	];

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<>
			<Dialog
				{...rest}
				scroll='paper'
				fullWidth>
				<CloseButtonOnAbsolutePosition onClick={cancel} />
				<DialogTitle>
					{jsxTitle}
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchOnWhat(`${strings.manufacturer}/${strings.model}`)}
						variant='paper'
						style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
					/>
					{/* <Typography variant="caption" className="df-row-ac">Niets gevonden in de product-cataloog? Klik <TextLink text="hier" style={{ marginLeft: 4, marginRight: 4 }} onClick={() => { }} /> om een nieuw item aan te maken</Typography> */}
				</DialogTitle>
				<DialogContent dividers>
					{queryResult.filteredCount > 0 && (
						<Paper variant='outlined'>
							<TableWithHeadersAndValues<IProductCatalogItem>
								padding={theme.spacing(1)}
								headers={headers}
								getKey={t => t.id}
								sortParams={params.sortParams}
								setSortParams={sp => setParams({ ...params, sortParams: sp, pageParams: { index: 1, size: params.pageParams.size } })}
								values={queryResult.values}
							/>
						</Paper>
					)}
					{queryResult.filteredCount === 0 && queryResult.unfilteredCount > 0 && <NoRecordsFound>{strings.nothingFoundCommaChangeSearchParams}</NoRecordsFound>}
					{queryResult.unfilteredCount === 0 && <NoRecordsFound>{strings.notASingleDeviceRegisteredInProductCatalog}</NoRecordsFound>}
					<TextLink
						text={strings.here.toLowerCase()}
						onClick={onNew}
						prependText={strings.click}
						appendText={strings.toCreateNewProduct.toLowerCase()}
						style={{ marginTop: 8 }}
					/>
				</DialogContent>
				<DialogActions>
					<TablePaging
						countResults={queryResult.filteredCount}
						pageSize={queryResult.pageSize}
						page={queryResult.zeroBasedPageIndex}
						pageParams={params.pageParams}
						setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
						removeBorder
					/>
				</DialogActions>
			</Dialog>
		</>
	);
};
