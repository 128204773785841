import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { IArticlesInquiryLineDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { AssignArticlesInquiryLineQuantityFromStockDialog } from './AssignArticlesInquiryLineQuantityFromStockDialog';
import { AssignInventoryItemFromStockDialog } from './AssignInventoryItemFromStockDialog';

interface IProps {
	line: IArticlesInquiryLineDto;
	articlesInquiryId: string;
	cancel: VoidFunction;
	confirm: VoidFunction;
}

// TODO
// check in old code for: <SelectInventoryItemForArticleNeedDialog
export const AssignArticlesInquiryLineFromStock = ({ articlesInquiryId, line, cancel, confirm }: IProps) => {
	const strings = useLocalization();

	if (line.canAssignFromStock === false) {
		return (
			<NotificationDialog
				open
				title={strings.error}
				content={`Deze lijn is reeds toegewezen`}
				close={cancel}
			/>
		);
	} else if (line.isUniquelyIdentifiable) {
		return (
			<AssignInventoryItemFromStockDialog
				open
				articlesInquiryId={articlesInquiryId}
				articlesInquiryLine={line}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<AssignArticlesInquiryLineQuantityFromStockDialog
				open
				articlesInquiryId={articlesInquiryId}
				line={line}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	}
};
