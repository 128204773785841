import { Card, CardContent, Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { Trigger } from 'framework/hooks/useTrigger';
import { formatDate } from 'framework/utils/date/formatDate';
import { noahDataCommand_fixIndices, noahDataQuery_sessionsByPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { LaunchModuleDialog } from '../../noah/LaunchModuleDialog';
import { NoahActionGroup } from './NoahActionGroup';

interface IProps {
	reloadTrigger: Trigger;
	patientId: string;
	style?: React.CSSProperties;
}

export const NoahSessionsCard = ({ reloadTrigger, patientId, style }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [dto, reload] = useApiEffect(noahDataQuery_sessionsByPatient, patientId);
	const [fixErrors, isFixing] = useFormSubmit(noahDataCommand_fixIndices);
	const notify = useSnackbarNotify();

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	const { open, cancel } = useDialogsContext();
	const [isShowAll, toggleShowAll] = useStateToggleBoolean(false);

	const onLaunchModule = (patientIndex: number) => {
		open(
			<LaunchModuleDialog
				open
				close={cancel}
				patientIndex={patientIndex}
			/>
		);
	};

	const onFixIndexErrors = async () => {
		const r = await fixErrors({ patientId: patientId });
		if (r.result.hasError) {
			notify(r.result.error, 'error');
		} else {
			reload();
		}
	};

	if (dto === undefined) {
		return <></>;
	}

	return (
		<Card
			className='fg1'
			style={style}>
			<CardTitleTypography
				withPadding
				title={strings.NoahSessions}
				action={
					<SmallPrimaryTextButton
						startIcon={<AddIcon />}
						disabled={dto.hasIndexError}
						onClick={() => onLaunchModule(dto.patientIndex)}
						style={{ marginRight: 'auto' }}>
						{strings.newWhat(strings.action)}
					</SmallPrimaryTextButton>
				}
			/>
			<CardContent
				className='df-col'
				style={{ paddingTop: 0 }}>
				{dto.hasIndexError === true && (
					<LoaderButton
						color='secondary'
						onClick={onFixIndexErrors}
						isLoading={isFixing}
						startIcon={<BuildOutlinedIcon />}>
						{`Fout in Noah data, klik hier om fout recht te zetten`}
					</LoaderButton>
				)}
				{dto.hasIndexError === false && (
					<>
						<FormControlLabelSwitch
							isChecked={isShowAll}
							toggle={toggleShowAll}
							label={strings.alsoShowDeleted}
						/>
						<div style={{ marginBottom: 8 }}></div>
						{dto.noahSessions.length === 0 && <NoRecordsFound>{strings.noWhatDefined(strings.NoahSessions)}</NoRecordsFound>}
						{dto.noahSessions
							.filter(t => isShowAll === true || t.isDeleted === false)
							.map(session => (
								<div
									key={formatDate(session.date)}
									className='df-col'
									style={{ marginBottom: theme.spacing(2) }}>
									<Typography
										variant='subtitle2'
										style={{ textDecoration: session.isDeleted ? 'line-through' : 'inherit' }}>
										{formatDate(session.date)}:
									</Typography>
									{session.actionGroups
										.filter(t => isShowAll === true || t.areAllDeleted === false)
										.map(action => (
											<NoahActionGroup
												key={action.firstIndex}
												actionGroup={action}
												patientIndex={dto.patientIndex}
												reload={reload}
											/>
										))}
								</div>
							))}
					</>
				)}
			</CardContent>
		</Card>
	);
};
