import { DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { setLineErrors } from 'framework/forms/utils/setLineErrors';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IReturnModel, returnsCommand_new, returnsQuery_nextReference } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { SuppliersCacheContext } from '../../../../contacts/suppliers/SuppliersCacheContext';
import { FormSelectOrCreateSupplierField } from '../../../../contacts/suppliers/forms/FormSelectOrCreateSupplierField';
import { LocationsPreloadCacheContext } from '../../../../settings/locations/LocationsPreloadCacheContext';
import { SearchProvider } from './context/SearchProvider';
import { ILine } from './ILine';
import { SearchAndSelectProductsForReturn } from './SearchAndSelectProductsForReturn';
import { lineToModel } from './utils/lineToModel';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IReturnModel>({
			returnDate: yup.date().required(strings.formRequired(strings.returnDate)),
			externalReference: yup.string(),
			lines: yup.mixed(),
			locationId: yup.string().required(strings.formRequired(strings.location)),
			supplierId: yup.string().required(strings.formRequired(strings.supplier)),
			reference: yup.string().required(),
			isAutoSelectReference: yup.boolean().defined(),
		})
		.defined();
};

const EmptyValues: IReturnModel = {
	returnDate: undefined as any,
	externalReference: '',
	lines: [],
	locationId: undefined as any,
	supplierId: undefined as any,
	reference: '',
	isAutoSelectReference: true,
};

const stepsRecord: Record<number, (keyof IReturnModel)[]> = {
	0: ['reference', 'isAutoSelectReference'],
	1: ['locationId', 'supplierId'],
	2: ['returnDate', 'externalReference'],
	3: ['lines'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const ReturnForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, setLines] = useState<ILine[]>([]);
	const [step, setStep] = useState<number>(0);
	const [create, isSubmitting] = useFormSubmit(returnsCommand_new);
	const notify = useSnackbarNotify();
	const now = useNow();

	const handleSubmit = async (values: IReturnModel, helpers: FormikHelpers<IReturnModel>) => {
		// TODO meer controleren! -> max. value of stockItems will be max value available at location!
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await create({ ...values, lines: lines.map((t, index) => lineToModel(t, index)) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify(strings.addedWhat(strings.return));
				confirm(r.result.objectId);
			} else {
				setLineErrors('lines', r, lines, setLines);
			}
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IReturnModel>
			validateOnMount
			initialValues={{ ...EmptyValues, returnDate: now }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						lines={lines}
						setLines={setLines}
						step={step}
						setStep={setStep}
						stepsRecord={stepsRecord}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	lines: ILine[];
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof IReturnModel)[]>;
}

const InnerDialog = ({ cancel, lines, setLines, isSubmitting, step, setStep, stepsRecord, ...rest }: IInnerProps) => {
	const props = useFormikContext<IReturnModel>();
	const strings = useLocalization();
	const [suppliers] = usePreloadCacheContext(SuppliersCacheContext);
	const [locations] = usePreloadCacheContext(LocationsPreloadCacheContext);
	const { isStacked } = useDialogsContext();
	useNextReferenceInterceptor(returnsQuery_nextReference, props);

	useEffect(() => {
		if (suppliers?.length === 1) {
			setFormValue<IReturnModel>('supplierId', suppliers[0].id, props);
		}
		// eslint-disable-next-line
	}, [suppliers]);

	useEffect(() => {
		if (locations?.length === 1) {
			setFormValue<IReturnModel>('locationId', locations[0].id, props);
		}
		// eslint-disable-next-line
	}, [locations]);

	if (suppliers === undefined || locations === undefined) {
		return <div></div>;
	}

	return (
		<FullScreenDialogWithStepper
			{...rest}
			open={rest.open && isStacked === false}
			fullScreenStep={3}
			title={strings.newWhat(strings.return)}
			step={step}
			labels={[strings.referenceShort, strings.fromTo, strings.date, strings.items]}>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IReturnModel>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IReturnModel>
							name='reference'
							label={strings.reference}
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectFieldWithOption<IReturnModel, string>
							name='locationId'
							options={locations}
							label={strings.location}
						/>
						<FormSelectOrCreateSupplierField<IReturnModel>
							name='supplierId'
							label={strings.supplier}
						/>
					</>
				)}
				{step === 2 && (
					<FormDatePicker<IReturnModel>
						name='returnDate'
						label={strings.returnDate}
						required
					/>
				)}
				{step === 3 && (
					<SearchAndSelectProductsForReturn
						lines={lines}
						setLines={setLines}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</FullScreenDialogWithStepper>
	);
};
