import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { IRepairsQueryMetadata, RepairPrefilter, RepairPrefilters } from 'gen/ApiClient';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { createRepairPrefilterBadgeChipXRecord } from './utils';

interface IProps {
	selectedPrefilter: RepairPrefilter;
	setSelectedPrefilter: (key: RepairPrefilter) => void;
	metadata: IRepairsQueryMetadata;
	style?: React.CSSProperties;
}

export const RepairsListPrefilter = ({ selectedPrefilter, setSelectedPrefilter, metadata, style }: IProps) => {
	const endIconRecord = useMemo(() => createRepairPrefilterBadgeChipXRecord(metadata), [metadata]);
	const { repairPrefilterRecord } = useContext(RecordContext);

	return (
		<div
			className='df-col'
			style={style}>
			{RepairPrefilters.map(prefilter => (
				<RadioIsSelectedButton
					key={prefilter}
					isSelected={prefilter === selectedPrefilter}
					onClick={() => setSelectedPrefilter(prefilter)}
					endIcon={endIconRecord[prefilter]}>
					{repairPrefilterRecord[prefilter]}
				</RadioIsSelectedButton>
			))}
		</div>
	);
};
