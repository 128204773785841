import { CacheProvider } from 'framework/context/CacheProvider';
import {
	IRizivHearingAidDefinitionV2FilterData,
	rizivHearingAidDefinitionsV2Query_concurrencyToken,
	rizivHearingAidDefinitionsV2Query_filterData,
} from 'gen/ApiClient';
import React from 'react';
import { RizivHifsFilterDataContext } from './RizivHifsFilterDataContext';

export const RizivHifsFilterDataProvider = ({ children }: any) => {
	return (
		<CacheProvider<IRizivHearingAidDefinitionV2FilterData>
			concurrencyF={rizivHearingAidDefinitionsV2Query_concurrencyToken}
			loadF={rizivHearingAidDefinitionsV2Query_filterData}
			context={RizivHifsFilterDataContext}>
			{children}
		</CacheProvider>
	);
};
