import { DateFormatting } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createDateFormattingRecord = (strings: IStrings): Record<DateFormatting, string> => {
	return {
		D: strings.dateDayMonthYear,
		DT: strings.datePlusTimeDayMonthYearHourMinutes,
		IN: strings.dateBinFormat,
	};
};
