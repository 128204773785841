import React, { useContext } from 'react';
import { PreloadCacheProvider } from 'framework/context/PreloadCacheProvider';
import { recupelsQuery_concurrencyToken, recupelsQuery_default } from 'gen/ApiClient';
import { PreloadContext } from './PreloadContext';
import { RecupelDefaultCacheContext } from './RecupelDefaultCacheContext';

export const RecupelDefaultCacheProvider = ({ children }: any) => {
	const { recupel } = useContext(PreloadContext);

	return (
		<PreloadCacheProvider<string>
			concurrencyF={recupelsQuery_concurrencyToken}
			loadF={recupelsQuery_default}
			context={RecupelDefaultCacheContext}
			initialData={recupel}>
			{children}
		</PreloadCacheProvider>
	);
};
