import { Typography } from '@material-ui/core';
import React from 'react';
import { PageBreadcrumbIcon } from './PageBreadcrumbIcon';

interface IProps {
	title: string | undefined;
	icon?: React.ReactNode;
}

export const PageBreadcrumbTitle = ({ title, icon }: IProps) => {
	return (
		<div className='df-row-ac gap-2'>
			{icon && <PageBreadcrumbIcon icon={icon} />}
			<Typography
				variant='body2'
				style={{ fontWeight: 500 }}>
				{title ?? ''}
			</Typography>
		</div>
	);
};
