import { Dialog, DialogContent, DialogProps, DialogTitle, Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@material-ui/core';
import { Form, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormPercentageField } from 'framework/forms/FormPercentageField';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { PaperOutlined } from 'framework/table/PaperOutlined';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { calculateDiscountPercentage } from 'app/main/financial/sales/forms/utils/calculateDiscountPercentage';
import { calculateSalesPriceByDiscountPercentage } from 'app/main/financial/sales/forms/utils/calculateSalesPriceByDiscountPercentage';

interface IModel {
	listPrice: number;
	discountPercentage: number;
	unitPrice: number;
	discountAbsolute: number;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			listPrice: yup.number().min(0).required(),
			discountPercentage: yup.number().defined(),
			unitPrice: yup.number().min(0).max(yup.ref('listPrice'), strings.priceMustBeLowerThanListPrice).defined(),
			discountAbsolute: yup.number().min(0).max(yup.ref('listPrice'), strings.discountCannotBeGreaterThanListPrice).defined(),
		})
		.defined();
};

type Mode = 'discountPercentage' | 'discountAbsolute' | 'unitPrice';

interface IProps extends DialogProps {
	unitPrice: number;
	listPrice: number;
	confirm: (unitPrice: number, listPrice: number) => void;
	cancel: VoidFunction;
	forPurchase: boolean;
}

export const PriceForm = ({ unitPrice, listPrice, confirm, cancel, forPurchase, ...rest }: IProps) => {
	const strings = useLocalization();

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		confirm(values.unitPrice, values.listPrice);
	};

	return (
		<Formik<IModel>
			validateOnMount
			validationSchema={createSchema(strings)}
			initialValues={{
				listPrice: listPrice,
				unitPrice: unitPrice,
				discountPercentage: calculateDiscountPercentage(listPrice, unitPrice),
				discountAbsolute: listPrice - unitPrice,
			}}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					cancel={cancel}
					forPurchase={forPurchase}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

const calculate = (mode: Mode, props: FormikProps<IModel>, key: keyof IModel) => {
	if (mode === 'discountPercentage' && (key === 'discountPercentage' || key === 'listPrice')) {
		setFormValue<IModel>('unitPrice', calculateSalesPriceByDiscountPercentage(props.values.listPrice, props.values.discountPercentage), props);
		setFormValue<IModel>(
			'discountAbsolute',
			props.values.listPrice - calculateSalesPriceByDiscountPercentage(props.values.listPrice, props.values.discountPercentage),
			props
		);
	}
	if (mode === 'unitPrice' && (key === 'unitPrice' || key === 'listPrice')) {
		setFormValue<IModel>('discountPercentage', calculateDiscountPercentage(props.values.listPrice, props.values.unitPrice).toFixed(2), props);
		setFormValue<IModel>('discountAbsolute', props.values.listPrice - props.values.unitPrice, props);
	}
	if (mode === 'discountAbsolute' && (key === 'discountAbsolute' || key === 'listPrice')) {
		setFormValue<IModel>(
			'discountPercentage',
			calculateDiscountPercentage(props.values.listPrice, props.values.listPrice - props.values.discountAbsolute).toFixed(2),
			props
		);
		setFormValue<IModel>('unitPrice', props.values.listPrice - props.values.discountAbsolute, props);
	}
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	forPurchase: boolean;
}

interface IValues {
	unitPrice: number;
	listPrice: number;
	discountPercentage: number;
	discountAbsolute: number;
}

const InnerDialog = ({ cancel, forPurchase, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IModel>();
	const strings = useLocalization();
	const [mode, setMode] = useState<Mode>('discountPercentage');
	const theme = useTheme();

	useEffect(() => {
		calculate(mode, props, 'discountPercentage');
		// eslint-disable-next-line
	}, [props.values.discountPercentage, mode]);

	useEffect(() => {
		calculate(mode, props, 'listPrice');
		// eslint-disable-next-line
	}, [props.values.listPrice, mode]);

	useEffect(() => {
		calculate(mode, props, 'unitPrice');
		// eslint-disable-next-line
	}, [props.values.unitPrice, mode]);

	useEffect(() => {
		calculate(mode, props, 'discountAbsolute');
		// eslint-disable-next-line
	}, [props.values.discountAbsolute, mode]);

	return (
		<Dialog
			fullWidth
			{...rest}>
			<DialogTitle>{strings.changePrices}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<ExtendedRadioGroup<Mode>
					style={{ marginBottom: theme.spacing(2) }}
					selected={mode}
					setSelected={setMode}
					options={[
						{ value: 'discountPercentage', label: strings.enterDiscountInPercentage },
						{ value: 'discountAbsolute', label: strings.enterDiscountAbsolute },
						{ value: 'unitPrice', label: forPurchase ? strings.fillWhat(strings.purchasePrice) : strings.fillWhat(strings.salesPrice) },
					]}
				/>
				<FormMoneyField<IModel>
					name='listPrice'
					label={forPurchase === true ? strings.purchaseListPrice : strings.salesListPrice}
				/>
				{mode === 'discountPercentage' && (
					<FormPercentageField<IModel>
						name='discountPercentage'
						label={`${strings.discount} (%)`}
						endAdornment={<SmallPrimaryTextButton onClick={() => setFormValue<IModel>('discountPercentage', 100, props)}>{`100 %`}</SmallPrimaryTextButton>}
					/>
				)}
				{mode === 'discountAbsolute' && (
					<FormMoneyField<IModel>
						name='discountAbsolute'
						label={`${strings.discount} (euro)`}
					/>
				)}
				{mode === 'unitPrice' && (
					<FormMoneyField<IModel>
						name='unitPrice'
						label={forPurchase === true ? strings.purchasePrice : strings.salesPrice}
					/>
				)}
				<DividerWithCaption caption={strings.calculations} />
				<div className='df-row-ac jc-c'>
					<TableContainer
						component={PaperOutlined}
						style={{ width: 'fit-content' }}>
						<Table size='small'>
							<TableBody>
								<TableRow>
									<TableCell style={{ borderRight: '1px solid rgb(224, 224, 224)' }}>
										{forPurchase === true ? strings.purchaseListPrice : strings.salesListPrice}
									</TableCell>
									<TableCell style={{ fontWeight: 500 }}>{formatCurrency(props.values.listPrice)}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{ borderRight: '1px solid rgb(224, 224, 224)' }}>
										{forPurchase === true ? strings.purchasePrice : strings.salesPrice}
									</TableCell>
									<TableCell style={{ fontWeight: 500 }}>{formatCurrency(props.values.unitPrice)}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{ borderRight: '1px solid rgb(224, 224, 224)' }}>{`${strings.discount} (%)`}</TableCell>
									<TableCell style={{ fontWeight: 500 }}>{`${props.values.discountPercentage} %`}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{ borderBottom: 'none', borderRight: '1px solid rgb(224, 224, 224)' }}>{`${strings.discount} (euro)`}</TableCell>
									<TableCell style={{ borderBottom: 'none', fontWeight: 500 }}>{formatCurrency(props.values.discountAbsolute)}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</DialogContent>
			<CancelSubmitFormDialogActions
				submitText={strings.changePrices}
				isSubmitting={false}
				cancel={cancel}
			/>
		</Dialog>
	);
};
