import { CardProps } from '@material-ui/core';
import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { CardX } from 'framework/components/cards/CardX';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { ExactOnlineCustomerAccountPrefilters, IExactOnlineCustomerAccountQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps extends CardProps {
	params: IExactOnlineCustomerAccountQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IExactOnlineCustomerAccountQueryParams>>;
}

export const ExactOnlineCustomerAccountsListFilterCard = ({ params, setParams, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const onFilter = (pars: IExactOnlineCustomerAccountQueryParams) => setParams(onResetToPage1(pars));
	const { exactOnlineCustomerAccountPrefilterRecord } = useContext(RecordContext);

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<div className='df-col'>
				{ExactOnlineCustomerAccountPrefilters.map(prefilter => (
					<RadioIsSelectedButton
						key={prefilter}
						isSelected={prefilter === params.prefilter}
						onClick={() => onFilter({ ...params, prefilter: prefilter })}>
						{exactOnlineCustomerAccountPrefilterRecord[prefilter]}
					</RadioIsSelectedButton>
				))}
			</div>
		</CardX>
	);
};
