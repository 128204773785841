import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IRequestForInsuranceAllowance, rfiasCommand_setThirdPartyPaymentScheme } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

interface IModel {
	applyScheme: boolean;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			applyScheme: yup.boolean().defined(),
		})
		.defined();
};

interface IProps extends DialogProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const ThirdPartySchemeForm = ({ confirm, cancel, rfia, ...rest }: IProps) => {
	const strings = useLocalization();
	const [update, isSubmitting] = useFormSubmit(rfiasCommand_setThirdPartyPaymentScheme);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await update(rfia.id, values.applyScheme);
		handleValidateResponse<IModel>(r, helpers, 'applyScheme');
		if (r.isSuccess) {
			confirm();
		}
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{ applyScheme: rfia.hasThirdPartyPaymentScheme }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					scroll='paper'
					fullWidth
					{...rest}>
					<DialogTitle>{strings.thirdPayerArrangement}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormExtendedRadioGroupField<IModel, boolean>
							name='applyScheme'
							label='3rd betalersregeling'
							options={[
								{ value: true, label: strings.withWhat(strings.thirdPayerArrangement), caption: strings.withThirdPayerArrangementCaption },
								{ value: false, label: strings.withoutWhat(strings.thirdPayerArrangement), caption: strings.withoutThirdPayerArrangementCaption },
							]}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
