import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IBusinessDataWithImageDataDto, IPatchBusinessDataRequest, businessDataCommand_patchGeneral } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';
import { sanitizeIPatchBusinessDataRequest } from '../utils';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPatchBusinessDataRequest>({
			name: yup.string().required(),
			email: yup.string(),
			enterpriseNumber: yup.string(),
			phone: yup.string(),
			vatNumber: yup.string(),
			website: yup.string(),
		})
		.defined();
};

interface IProps extends DialogProps {
	data: IBusinessDataWithImageDataDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchBusinessDataGeneralInformationForm = ({ data, confirm, cancel, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [submit, isSubmitting] = useFormSubmit(businessDataCommand_patchGeneral);
	const strings = useLocalization();

	const handleSubmit = async (values: IPatchBusinessDataRequest, helpers: FormikHelpers<IPatchBusinessDataRequest>) => {
		values = sanitizeIPatchBusinessDataRequest(values);
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.passwordChanged);
			confirm();
		}
	};

	return (
		<Formik<IPatchBusinessDataRequest>
			validateOnMount
			initialValues={{
				name: data.companyName ?? '',
				email: data.email ?? '',
				enterpriseNumber: data.enterpriseNumber ?? '',
				phone: data.phone ?? '',
				vatNumber: data.vatNumber ?? '',
				website: data.website ?? '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					fullWidth
					maxWidth='sm'>
					<DialogTitle>{strings.changeWhat(strings.generalData)}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormTextField<IPatchBusinessDataRequest>
							name='name'
							label={strings.companyName}
							required
						/>
						<FormTextField<IPatchBusinessDataRequest>
							name='vatNumber'
							label={strings.vatNumber}
						/>
						<FormTextField<IPatchBusinessDataRequest>
							name='enterpriseNumber'
							label={strings.enterpriseNumber}
						/>
						<FormTextField<IPatchBusinessDataRequest>
							name='email'
							label={strings.email}
						/>
						<FormTextField<IPatchBusinessDataRequest>
							name='phone'
							label={strings.phone}
						/>
						<FormTextField<IPatchBusinessDataRequest>
							name='website'
							label={strings.website}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.change}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
