import { CancelledProcessStepX } from 'framework/components/stepper/CancelledProcessStepX';
import { ProcessStepX } from 'framework/components/stepper/ProcessStepX';
import { VerticalStepperX } from 'framework/components/stepper/VerticalStepperX';
import { IRepair } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { calculateActiveRepairProcessStepX } from './calculateActiveRepairProcessStepX';

interface IProps {
	item: IRepair;
	style?: React.CSSProperties;
}

export const RepairDetailProcessComponent = ({ item, style }: IProps) => {
	const strings = useLocalization();
	const activeStep = React.useMemo(() => calculateActiveRepairProcessStepX(item), [item]);

	return (
		<VerticalStepperX style={style}>
			<ProcessStepX
				doneText={strings.registered}
				todoText={strings.register}
				date={item.created}
				isCancelled={item.isCancelled}
				isActive={activeStep === 'register'}
			/>
			<ProcessStepX
				doneText={strings.articlesRegistered}
				todoText={strings.registerArticles}
				date={activeStep === 'define-articles' ? undefined : item.created}
				isCancelled={item.isCancelled}
				isActive={activeStep === 'define-articles'}
			/>
			<ProcessStepX
				doneText={strings.sentToSupplier}
				todoText={strings.sendToSupplier}
				date={item.sentToSupplierDate}
				isCancelled={item.isCancelled}
				isActive={activeStep === 'send-to-supplier'}
			/>
			<ProcessStepX
				doneText={strings.redelivered}
				todoText={strings.redelivery}
				date={item.firstActualDeliveryDate}
				isCancelled={item.isCancelled}
				isActive={activeStep === 'delivery'}
				isLast={item.handoverToPatientStepIncluded === false && item.isCancelled === false}
			/>
			{item.handoverToPatientStepIncluded && (
				<ProcessStepX
					doneText={strings.handedOver}
					todoText={strings.handover}
					date={item.handoverDate}
					isCancelled={item.isCancelled}
					isActive={activeStep === 'handover'}
					isLast={item.isCancelled === false}
				/>
			)}
			{item.isCancelled && <CancelledProcessStepX />}
		</VerticalStepperX>
	);
};
