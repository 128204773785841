import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import BlockIcon from '@material-ui/icons/Block';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import { ILoan, LoanProcessStatus } from 'gen/ApiClient';
import React from 'react';

const LoanProcessStatusIconRecord: Record<LoanProcessStatus, OverridableComponent<SvgIconTypeMap<{}, 'svg'>>> = {
	Cancelled: BlockIcon,
	Ended: HighlightOffOutlinedIcon,
	Started: PlayCircleFilledWhiteOutlinedIcon,
};

interface IProps {
	item: ILoan;
	style?: React.CSSProperties;
}

export const LoanListItemStatusIcon = ({ item, style }: IProps) => {
	const Icon = LoanProcessStatusIconRecord[item.status as LoanProcessStatus];
	return <Icon style={style} />;
};
