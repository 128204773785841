import { ICalendarEventDto } from 'gen/ApiClient';
import { AgendaGroupScheme } from './AgendaGroupScheme';

export const mapEventGroupKeys = (event: ICalendarEventDto, groupScheme: AgendaGroupScheme): string | string[] | undefined => {
	if (groupScheme === 'rooms') {
		return event.roomGroupKey;
	} else if (groupScheme === 'users') {
		return event.userGroupKeys;
	} else {
		return undefined;
	}
};
