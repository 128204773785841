import { CircularProgress, Dialog, DialogContent, DialogProps, DialogTitle, Divider, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { isLast } from 'framework/utils/array/isLast';
import { notificationsQuery_multiple } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { NotificationListItem } from './NotificationListItem';

interface IProps extends DialogProps {
	ids: string[];
	onView: (id: string) => void;
	close: () => Promise<void>;
}

export const NotShownNotificationsDialog = ({ ids, onView, close, ...rest }: IProps) => {
	const strings = useLocalization();
	const [notifications, reload] = useApiEffect(notificationsQuery_multiple, ids);
	const { isStacked } = useDialogsContext();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	useLazyEffect(() => {
		if (isStacked === false) {
			reload();
		}
	}, [isStacked]);

	if (notifications === undefined) {
		return <div></div>;
	}

	const onClose = async () => {
		setIsSubmitting(true);
		await close();
		setIsSubmitting(false);
	};

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			maxWidth='xl'
			PaperProps={{ style: { minWidth: 400 } }}>
			<IconButton
				onClick={onClose}
				style={{ position: 'absolute', right: '10px', top: '10px' }}>
				{isSubmitting ? (
					<CircularProgress
						style={{ color: 'inherit' }}
						size={18}
					/>
				) : (
					<CloseIcon />
				)}
			</IconButton>
			<DialogTitle>{strings.newNotifications}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'
				style={{ padding: 0 }}>
				{notifications.map(t => (
					<React.Fragment key={t.id}>
						<NotificationListItem
							item={t}
							onView={onView}
						/>
						{isLast(t, notifications) === false && <Divider />}
					</React.Fragment>
				))}
			</DialogContent>
			{/* <DialogActions>
                <LoaderButton color="primary" isLoading={isSubmitting} onClick={onClose}>{strings.close}</LoaderButton>
            </DialogActions> */}
		</Dialog>
	);
};
