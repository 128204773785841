import { updateArray } from './updateArray';

export const addOrUpdateQuantity = <TList extends { id: string; quantity: number }, TFrom extends { id: string }>(
	line: TFrom,
	lines: TList[],
	createF: (from: TFrom) => TList
): TList[] => {
	const f = lines.find(t => t.id === line.id);
	if (f) {
		return updateArray(f, lines, { ...f, quantity: f.quantity + 1 });
	} else {
		return [...lines, createF(line)];
	}
};
