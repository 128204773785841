import { ISalesLineModel } from 'gen/ApiClient';
import { IInvoiceLine } from '../IInvoiceLine';
import { calculateAmountInVatWithVatShift } from './calculateAmountInVatWithVatShift';

export const invoiceLineToSalesLineModel = (line: IInvoiceLine, index: number, isShiftVat: boolean): ISalesLineModel => {
	return {
		zeroBasedIndexNumber: index,
		productCatalogItemId: line.productCatalogItemId,
		inventoryItemId: line.inventoryItemId,
		quantity: line.quantity,
		unitListPriceInVat: calculateAmountInVatWithVatShift(line.unitListPrice, line.vatPercentage, isShiftVat),
		unitPriceInVat: calculateAmountInVatWithVatShift(line.unitPrice, line.vatPercentage, isShiftVat),
		vatPercentage: isShiftVat ? 0 : line.vatPercentage,
		description: line.description,
		assuredInventoryItemId: line.assuredInventoryItemId,
		assuredProductCatalogItemId: line.assuredProductCatalogItemId,
	};
};
