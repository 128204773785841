import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPrescriptionModel, IRequestForInsuranceAllowance, rfiasCommand_receivePrescription } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { FormSelectOrCreateEntPhysicianField } from '../../../contacts/earNoseThroatPhysicians/forms/FormSelectOrCreateEntPhysicianField';
import { useNow } from 'shared/utils/useNow';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPrescriptionModel>({
			entPhysician: yup.string().required(strings.formRequired(strings.entPhysician)),
			prescriptionForTrialDate: yup.date().required(strings.formRequired(strings.prescriptionForTrialDate)),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IPrescriptionModel)[]> = {
	0: ['entPhysician'],
	1: ['prescriptionForTrialDate'],
};

interface IProps extends DialogProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const ReceivePrescriptionForm = ({ rfia, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [submit, isSubmitting] = useFormSubmit(rfiasCommand_receivePrescription);
	const [step, setStep] = useState<number>(0);
	const { isStacked } = useDialogsContext();
	const now = useNow();

	const handleSubmit = async (values: IPrescriptionModel, helpers: FormikHelpers<IPrescriptionModel>) => {
		const r = await submit(rfia.id, values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.prescriptionReceived);
			confirm();
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IPrescriptionModel>
			validateOnMount
			initialValues={{
				entPhysician: rfia.entPhysicianId ?? '',
				prescriptionForTrialDate: rfia.prescriptionForTrialDate ?? now,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					open={rest.open && isStacked === false}
					scroll='paper'
					maxWidth='md'>
					<DialogTitleWithFormStepper
						title={strings.registrationReceptionPrescription}
						step={step}
						labels={[strings.entPhysicianShort, strings.date]}
					/>
					<DialogContent
						className='df-col'
						dividers>
						{step === 0 && (
							<FormSelectOrCreateEntPhysicianField<IPrescriptionModel>
								name='entPhysician'
								label={strings.entPhysician}
							/>
						)}
						{step === 1 && (
							<FormDatePicker<IPrescriptionModel>
								name='prescriptionForTrialDate'
								label={strings.prescriptionForTrialDate}
								disableFuture
							/>
						)}
					</DialogContent>
					<PageableFormDialogActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
