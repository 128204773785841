import { PageClaim } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { PageClaimGroup } from '../PageClaimGroup';

export const createPageClaimOrGroupRecord = (record: Record<PageClaim, string>, strings: IStrings): Record<PageClaim | PageClaimGroup, string> => {
	return {
		...record,
		contacts: strings.contacts,
		financials: strings.financial,
		inventory: strings.inventoryManagement,
		processes: strings.processes,
		settings: strings.settings,
		'stock-transactions': strings.stockTransactions,
		commissions: strings.commissions,
		integrations: strings.integrations,
	};
};
