import { useEffect, useState } from 'react';
import { Try } from '../Try';
import { useApi } from './useApi';
import { useTrigger } from './useTrigger';

export function useApiEffectWithoutLoader<TResult>(f: () => Promise<Try<TResult>>): [TResult | undefined, VoidFunction];
export function useApiEffectWithoutLoader<TArgs, TResult>(f: (args: TArgs) => Promise<Try<TResult>>, args: TArgs): [TResult | undefined, VoidFunction];
export function useApiEffectWithoutLoader<TArgs extends any[], TResult>(
	f: (...args: TArgs) => Promise<Try<TResult>>,
	...args: TArgs
): [TResult | undefined, VoidFunction];

export function useApiEffectWithoutLoader<TArgs extends any[], TResult>(
	f: (...args: TArgs) => Promise<Try<TResult>>,
	...args: TArgs
): [TResult | undefined, VoidFunction] {
	// eslint-disable-next-line
	const [_1, _2, handleError] = useApi();
	const [data, setData] = useState<TResult>();
	const [trigger, reload] = useTrigger();

	useEffect(() => {
		let cancelled = false;
		const loadX = async () => {
			console.log(`load useApiEffect ${f.name}`);
			const r = await f(...args);
			if (r.isFailure) {
				handleError(r.error);
			} else {
				setData(r.result);
			}
		};
		if (cancelled === false) {
			loadX();
		}
		return () => {
			cancelled = true;
		};
		// missing deps handleError -> is a function
		// eslint-disable-next-line
	}, [trigger, f, ...args]);

	return [data, reload];
}
