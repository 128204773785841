import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatchPatientReferenceRequest, IPatient, patientsCommand_patchReference } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchPatientReferenceRequest>({
		reference: yup.string().required(strings.formRequired(strings.reference)),
		patientId: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	patient: IPatient;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchPatientReferenceRequestForm = ({ patient, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(patientsCommand_patchReference);

	const handleSubmit = async (values: IPatchPatientReferenceRequest, helpers: FormikHelpers<IPatchPatientReferenceRequest>) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.patientUpdated);
			confirm();
		}
	};

	return (
		<Formik<IPatchPatientReferenceRequest>
			validateOnMount
			validationSchema={createSchema(strings)}
			initialValues={{
				reference: patient.reference ?? '',
				patientId: patient.id,
			}}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.numbering}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'
						style={{ overflow: 'visible' }}>
						<FormTextField<IPatchPatientReferenceRequest>
							label={strings.numbering}
							name='reference'
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.update}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
