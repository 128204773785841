import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { CashRegisterEntriesList } from './cashRegister/CashRegisterEntriesList';
import { CommissionsPageSwitch } from './commissions/CommissionsPageSwitch';
import { CustomerAccountsList } from './customerAccounts/CustomerAccountsList';
import { CustomerAccountDetail } from './customerAccounts/detail/CustomerAccountDetail';
import { DailyReceiptsList } from './dailyReceipts/DailyReceiptsList';
import { PaymentsList } from './payments/PaymentsList';
import { PurchasesSettingsPage } from './purchases/PurchasesSettingsPage';
import { PurchasesUblBufferListPage } from './purchases/buffer/PurchasesUblBufferListPage';
import { PurchaseDetailPage } from './purchases/detail/PurchaseDetailPage';
import { PurchasesListPage } from './purchases/list/PurchasesListPage';
import * as routes from './routes';
import { SalesSettingsPage } from './sales/SalesSettingsPage';
import { SalesAnalysis } from './sales/analysis/SalesAnalysis';
import { SalesDetailPage } from './sales/detail/SalesDetailPage';
import { SalesListPage } from './sales/list/SalesListPage';
import { SettlementProposalsPage } from './settlementProposals/SettlementProposalsPage';
import { SupplierAccountsListPage } from './supplierAccounts/SupplierAccountsListPage';
import { SupplierAccountDetail } from './supplierAccounts/detail/SupplierAccountDetail';
import { TendersSettingsPage } from './tenders/TendersSettingsPage';
import { TenderDetailPage } from './tenders/detail/TenderDetailPage';
import { TendersListPage } from './tenders/list/TendersListPage';

export const FinancialsSwitch = () => {
	return (
		<Switch>
			<Route
				exact
				path={routes.TendersRoute}
				component={TendersListPage}
			/>
			<Route
				exact
				path={routes.TendersSettingsRoute}
				component={TendersSettingsPage}
			/>
			<Route
				exact
				path={routes.TenderDetailRoute}
				component={TenderDetailPage}
			/>
			<Route
				exact
				path={routes.CustomerAccountsRoute}
				component={CustomerAccountsList}
			/>
			<Route
				exact
				path={routes.CustomerAccountDetailRoute}
				component={CustomerAccountDetail}
			/>
			<Route
				exact
				path={routes.SalesRoute}
				component={SalesListPage}
			/>
			<Route
				exact
				path={routes.SalesAnalysisRoute}
				component={SalesAnalysis}
			/>
			<Route
				exact
				path={routes.SalesSettingsRoute}
				component={SalesSettingsPage}
			/>
			<Route
				exact
				path={routes.SalesDetailRoute}
				component={SalesDetailPage}
			/>
			<Route
				exact
				path={routes.PurchasesRoute}
				component={PurchasesListPage}
			/>
			<Route
				exact
				path={routes.PurchasesSettingsRoute}
				component={PurchasesSettingsPage}
			/>
			<Route
				exact
				path={routes.PurchasesUblBufferRoute}
				component={PurchasesUblBufferListPage}
			/>
			<Route
				exact
				path={routes.PurchaseDetailRoute}
				component={PurchaseDetailPage}
			/>
			<Route
				exact
				path={routes.SupplierAccountsRoute}
				component={SupplierAccountsListPage}
			/>
			<Route
				exact
				path={routes.SupplierAccountDetailRoute}
				component={SupplierAccountDetail}
			/>

			<Route
				exact
				path={routes.PaymentsRoute}
				component={PaymentsList}
			/>
			<Route
				exact
				path={routes.SettlementProposalsRoute}
				component={SettlementProposalsPage}
			/>
			<Route
				path={routes.CommissionsRoute}
				component={CommissionsPageSwitch}
			/>
			<Route
				exact
				path={routes.DailyReceiptsRoute}
				component={DailyReceiptsList}
			/>
			<Route
				exact
				path={routes.CashRegisterEntriesRoute}
				component={CashRegisterEntriesList}
			/>
			<Redirect to={routes.TendersRoute} />
		</Switch>
	);
};
