import { Collapse, Radio, useTheme } from '@material-ui/core';
import { TextLinkButtonWithExpandIcon } from 'framework/components/buttons/TextLinkButtonWithExpandIcon';
import { CardX } from 'framework/components/cards/CardX';
import { ChipWithAvatarList } from 'framework/components/ChipWithAvatarList';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { formatDate } from 'framework/utils/date/formatDate';
import { ISupplierSummaryWithOrderStats } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: ISupplierSummaryWithOrderStats;
	onSelect: VoidFunction;
	isSelected: boolean;
	style?: React.CSSProperties;
}

export const SupplierWithOrderStatsListItemCardX = ({ item, style, onSelect, isSelected }: IProps) => {
	const strings = useLocalization();
	const [hovered, enter, leave] = useStateBoolean(false);
	const [isExpanded, toggleIsExpanded] = useStateToggleBoolean(false);
	const theme = useTheme();

	return (
		<CardX
			className='df-row gap-8 w100'
			raised={hovered || isSelected}
			onMouseEnter={enter}
			onMouseLeave={leave}
			style={{
				cursor: 'pointer',
				...style,
			}}
			onClick={onSelect}>
			<div className='df-col'>
				<Radio
					style={{ padding: 0 }}
					checked={isSelected}
				/>
			</div>
			<div className='df-col fg1'>
				<span style={{ fontSize: MajorFontSize, fontWeight: 500 }}>{item.name}</span>
				{item.accountNumber && <span style={{ fontSize: MinorFontSize }}>{`${strings.accountNumber}: ${item.accountNumber}`}</span>}
				<span style={{ fontSize: MinorFontSize }}>{`# ${item.countOrders} ${strings.orders.toLowerCase()}`}</span>
				{item.hasOrdersToPlaceWithSupplier && (
					<span
						style={{
							fontSize: MinorFontSize,
							color: theme.palette.warning.main,
						}}>
						{strings.countOrdersToPlaceWithSupplier(item.countOrdersToPlaceWithSupplier)}
					</span>
				)}
				{item.lastOrderDate && <span style={{ fontSize: MinorFontSize }}>{`${strings.dateLastOrder}: ${formatDate(item.lastOrderDate)}`}</span>}
				{item.countBrands > 0 && (
					<>
						<TextLinkButtonWithExpandIcon
							toggle={toggleIsExpanded}
							isExpanded={isExpanded}>
							<span style={{ fontSize: MinorFontSize, fontWeight: 'unset' }}>
								{`# ${item.countBrands.toString()} ${item.countBrands === 1 ? strings.brand.toLowerCase() : strings.brands.toLowerCase()}`}
							</span>
						</TextLinkButtonWithExpandIcon>
						<Collapse in={isExpanded}>
							<div className='df-row-wrap-4'>
								<ChipWithAvatarList items={item.brands} />
							</div>
						</Collapse>
					</>
				)}
			</div>
		</CardX>
	);
};
