import { Card, CardContent } from '@material-ui/core';
import { IPatientDetail } from 'gen/ApiClient';
import React from 'react';
import { useIsRosaIntegrationEnabled } from 'app/main/integrations/rosa/useIsRosaIntegrationEnabled';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { PatientContactInfoCardContent } from '../contactInfo/PatientContactInfoCardContent';
import { PatientAttendingPhysiciansCardContent } from '../overview/PatientAttendingPhysiciansCardContent';
import { PatientBasicInformationCardContent } from '../overview/PatientBasicInformationCardContent';
import { PatientContactPersonCardContent } from './PatientContactPersonCardContent';
import { PatientDetailRenewalInfoCardContent } from './PatientDetailRenewalInfoCardContent';
import { PatientHifLinkCardContent } from './PatientHifLinkCardContent';
import { PatientOriginsCardContent } from './PatientOriginsCardContent';
import { PatientRosaLinkCardContent } from './PatientRosaLinkCardContent';
import { PatientNumberingCardContent } from '../contactInfo/PatientNumberingCardContent';

const MaxWidth = 300;

interface IProps extends IReloadTriggerProps {
	item: IPatientDetail;
	reload: VoidFunction;
}

export const PatientDetailPersonalDataTab = ({ item, reload, reloadTrigger }: IProps) => {
	const isRosaEnabled = useIsRosaIntegrationEnabled();

	return (
		<div className='df-row-wrap gap-16'>
			<div
				className='df-col gap-16'
				style={{ maxWidth: MaxWidth }}>
				<Card>
					<CardContent>
						<PatientBasicInformationCardContent
							item={item.patient}
							reload={reload}
						/>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<PatientDetailRenewalInfoCardContent
							item={item.patient}
							reload={reload}
						/>
					</CardContent>
				</Card>
				{isRosaEnabled && (
					<Card>
						<CardContent>
							<PatientRosaLinkCardContent
								patientId={item.patient.id}
								reloadTrigger={reloadTrigger}
							/>
						</CardContent>
					</Card>
				)}
			</div>
			<div
				className='df-col gap-16'
				style={{ maxWidth: MaxWidth }}>
				<Card>
					<CardContent>
						<PatientAttendingPhysiciansCardContent
							item={item.patient}
							reload={reload}
							withoutHif
						/>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<PatientContactInfoCardContent
							item={item.patient}
							reload={reload}
						/>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<PatientContactPersonCardContent
							item={item.patient}
							reload={reload}
						/>
					</CardContent>
				</Card>
			</div>
			<div
				className='df-col gap-16'
				style={{ maxWidth: MaxWidth }}>
				<Card>
					<CardContent>
						<PatientHifLinkCardContent
							item={item.patient}
							reload={reload}
						/>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<PatientOriginsCardContent
							item={item.patient}
							reload={reload}
						/>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<PatientNumberingCardContent
							item={item.patient}
							reload={reload}
						/>
					</CardContent>
				</Card>
			</div>
		</div>
	);
};
