import { DialogProps } from '@material-ui/core';
import React from 'react';
import { IInventoryItem, inventoryItemsCommand_patchDispatchInfo } from 'gen/ApiClient';
import { DispatchInfoForm } from 'shared/DispatchInfoForm';

interface IProps extends DialogProps {
	item: IInventoryItem;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const InventoryItemDispatchInfoForm = ({ item, ...rest }: IProps) => {
	return (
		<DispatchInfoForm
			{...rest}
			reference={item.dispatchReference}
			date={item.dispatchDate}
			submitF={values => inventoryItemsCommand_patchDispatchInfo({ inventoryItemId: item.id, reference: values.reference, date: values.date })}
		/>
	);
};
