import { Button, Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { ICashRegisterEntry } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { FinancialsPageBreadcrumbTitle } from '../FinancialsPageBreadcrumbTitle';
import { CashRegisterEntriesExportButtonMenuAndDialog } from './CashRegisterEntriesExportButtonMenuAndDialog';
import { CashRegisterEntryInOrOutStatusChip } from './CashRegisterEntryInOrOutStatusChip';
import { Filter } from './Filter';
import { SelectableColumn } from './SelectableColumn';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';
import { CashTransferForm } from './forms/CashTransferForm';

export const CashRegisterEntriesList = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(QueryContext);
	const strings = useLocalization();
	const theme = useTheme();
	const { columns, setColumns, columnsRecord, headers } = useContext(ViewContext);
	const { open, confirm, cancel } = useDialogsContext(reload);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<CashTransferForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='CashRegisterEntries'
			stickyHeader={
				<div
					className='df-row-ac'
					style={{ paddingBottom: 16 }}>
					<FinancialsPageBreadcrumbTitle />
					<PageBreadcrumbDivider />
					<PageBreadcrumbTitle title={strings.cashRegisterAsLedger} />
					<div className='fg1'></div>
					<PageXHeaderActions>
						<PageReloadButtonX reload={reload} />
					</PageXHeaderActions>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16 }}>
				<div className='df-row-ac jc-sb'>
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchVerb}
						style={{ width: '400px' }}
						variant='paper'
					/>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						onClick={onNew}>
						{strings.registerWhat(strings.cashTransaction)}
					</Button>
				</div>
				{queryResult.unfilteredCount > 0 && (
					<>
						<Card>
							<div className='df-row jc-sb'>
								<Filter
									params={params}
									onFilter={p => setParams(onResetToPage1(p))}
									paddingLeft={theme.spacing(1.5)}
								/>
								<div>
									<CashRegisterEntriesExportButtonMenuAndDialog
										params={params}
										queryResult={queryResult}
									/>
									<SelectColumnsButtonAndDialog
										selectedColumns={columns}
										setSelectedColumns={setColumns}
										record={columnsRecord}
										view='cashRegisterEntries'
									/>
								</div>
							</div>
							<Divider />
							<CardMedia>
								<PageableTableWithColumnSelection<ICashRegisterEntry, SelectableColumn>
									queryResult={queryResult}
									headers={headers}
									sortParams={params.sortParams}
									pageParams={params.pageParams}
									setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
									getKey={inst => inst.id.toString()}
									selectedColumns={columns}
									prependColumn={createEmptyHeader<ICashRegisterEntry>('in-out', t => (
										<CashRegisterEntryInOrOutStatusChip item={t} />
									))}
									// prependColumn={createHeader<ISale>(strings.status, t => <SalesPaymentStatusAndCreditChips item={t} />)}
									// appendColumn={createDetailsNavigateHeader<ISale>(calcSalesDetailRoute)}
								/>
							</CardMedia>
						</Card>
					</>
				)}
				{queryResult.unfilteredCount === 0 && <NoRecordsFound>{strings.noWhatRegistered(strings.cashTransactions)}</NoRecordsFound>}
			</div>
		</RestrictedPageX>
	);
};
