import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { CommissionRuleMethod, ICommission } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { boolToYesNo } from 'shared/utils/boolToString';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (
	colRecord: Record<SelectableColumn, string>,
	commissionRuleMethodRecord: Record<CommissionRuleMethod, string>,
	strings: IStrings
): Record<SelectableColumn, IHeader<ICommission>> => {
	return {
		created: createSortableHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'created', t => formatDate(t.created)),
		ruleName: createSortableHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'ruleName', t => t.ruleName),
		rulePercentage: createSortableHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'rulePercentage', t => `${t.rulePercentage.toString()} %`),
		isPaidOut: createHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'isPaidOut', t => boolToYesNo(t.isPaidOut, strings)),
		isFullyPaid: createHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'isFullyPaid', t => boolToYesNo(t.isFullyPaid, strings)),
		isFullyCredited: createHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'isFullyCredited', t => boolToYesNo(t.isFullyCredited, strings)),
		isPaidByPatientOrCompany: createHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'isPaidByPatientOrCompany', t =>
			boolToYesNo(t.isPaidByPatientOrCompany, strings)
		),
		isPaidByHif: createHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'isPaidByHif', t => boolToYesNo(t.isPaidByHif, strings)),
		ruleMethod: createHeaderWithKey<ICommission, SelectableColumn>(
			colRecord,
			'ruleMethod',
			t => commissionRuleMethodRecord[t.ruleMethod as CommissionRuleMethod]
		),
		value: createSortableHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'value', t => formatCurrency(t.value)),
		totalValueExVatOfDevices: createSortableHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'totalValueExVatOfDevices', t =>
			formatCurrency(t.totalValueExVatOfDevices)
		),
		totalValueExVatOfSale: createSortableHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'totalValueExVatOfSale', t =>
			formatCurrency(t.totalValueExVatOfSale)
		),
		salesRecipientName: createSortableHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'salesRecipientName', t => t.salesRecipientName),
		salesReference: createSortableHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'salesReference', t => t.salesReference),
		userIdentifier: createSortableHeaderWithKey<ICommission, SelectableColumn>(colRecord, 'userIdentifier', t => t.userIdentifier),
	};
};
