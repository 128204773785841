import React from 'react';
import { LabelledChip } from './LabelledChip';

interface IProps {
	labels: string[];
	style?: React.CSSProperties;
}

export const LabelledChipsList = ({ labels, ...rest }: IProps) => {
	return (
		<>
			{labels.map(t => (
				<LabelledChip
					key={t}
					label={t}
					{...rest}
				/>
			))}
		</>
	);
};
