import { DialogProps } from '@material-ui/core';
import React from 'react';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { ILocation, locationsCommand_patchEmail } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	location: ILocation;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LocationEmailForm = ({ location, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<StringModelForm
			label={strings.email}
			formTitle={strings.changeWhat(strings.email)}
			notifyMessage={strings.changedWhat(strings.email)}
			submitFunction={val => locationsCommand_patchEmail({ locationId: location.id, email: val })}
			initialValue={location.email ?? ''}
			submitText={strings.update}
			{...rest}
		/>
	);
};
