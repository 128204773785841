import { calculateDiscountPercentage } from 'app/main/financial/sales/forms/utils/calculateDiscountPercentage';
import { CalculatorIcon } from 'framework/components/icons/CalculatorIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { SpreadAndDivideHorizontallyComponent } from 'framework/components/SpreadAndDivideHorizontallyComponent';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';

interface ILine {
	vatPercentage: number;
	unitListPrice: number;
	unitPrice: number;
}

interface IProps<TLine extends ILine> {
	line: TLine;
	onClick: VoidFunction;
	style?: React.CSSProperties;
}

export const LinePricingLabelledProperties = <TLine extends ILine>({ line, onClick, style }: IProps<TLine>) => {
	const strings = useLocalization();
	const discountPercentage = useMemo(() => calculateDiscountPercentage(line.unitListPrice, line.unitPrice), [line]);
	const hasDiscount = useMemo(() => discountPercentage !== 0, [discountPercentage]);
	const [isHovered, enter, leave] = useStateBoolean(false);
	const isDiscount = useMemo(() => line.unitPrice < 0, [line]);

	return (
		<div className='df-row fg1'>
			<div className='fg1'></div>
			<SpreadAndDivideHorizontallyComponent
				style={{ cursor: 'pointer', ...style }}
				onMouseEnter={enter}
				onMouseLeave={leave}
				onClick={onClick}>
				<CalculatorIcon
					fontSize='small'
					color={isHovered ? 'primary' : 'action'}
				/>
				<LabelledProperty
					label={strings.vat}
					property={`${line.vatPercentage} %`}
					withoutMargin
				/>
				{isDiscount === false ? (
					<LabelledProperty
						label={strings.listPrice}
						property={formatCurrency(line.unitListPrice)}
						withoutMargin
					/>
				) : undefined}
				{isDiscount === false ? (
					<LabelledProperty
						label={strings.discount}
						propertyStyle={{ fontWeight: hasDiscount ? 'bold' : 'inherit' }}
						property={`${discountPercentage.toFixed(2)} %`}
						withoutMargin
					/>
				) : undefined}
				<LabelledProperty
					label={strings.unitPrice}
					property={formatCurrency(line.unitPrice)}
					withoutMargin
				/>
			</SpreadAndDivideHorizontallyComponent>
		</div>
	);
};
