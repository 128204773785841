import { InvoiceForwardingSelectedTabKey } from 'app/localStorageKeys';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useLocalStorage } from 'framework/hooks/useLocalStorage';
import { useTrigger } from 'framework/hooks/useTrigger';
import { invoiceForwardingSettingsQuery_get, invoiceForwardingSettingsQuery_status } from 'gen/ApiClient';
import React, { useEffect } from 'react';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { InvoiceForwardingIntegrationPageHeader } from './InvoiceForwardingIntegrationPageHeader';
import { InvoiceForwardingTabType } from './InvoiceForwardingTabType';
import { InvoiceForwardingSettingsTab } from './InvoiceForwardingSettingsTab';
import { InvoiceForwardingRecordsListTab } from './InvoiceForwardingRecordsListTab';

export const InvoiceForwardingIntegrationPage = () => {
	const [trigger, hitTrigger] = useTrigger();
	const [selectedTab, setSelectedTab] = useLocalStorage<InvoiceForwardingTabType>(InvoiceForwardingSelectedTabKey, 'settings');
	const [settings, reloadSettings] = useApiEffect(invoiceForwardingSettingsQuery_get);
	const [status, reloadStatus] = useApiEffect(invoiceForwardingSettingsQuery_status);

	useLazyEffect(() => {
		reloadSettings();
		reloadStatus();
	}, [trigger]);

	useEffect(() => {
		if (settings?.isEnabled === false && selectedTab !== 'settings') {
			setSelectedTab('settings');
		}
		// eslint-disable-next-line
	}, [settings, selectedTab]);

	if (settings === undefined || status === undefined) {
		return <></>;
	}

	return (
		<RestrictedPageX
			pageClaim='InvoiceForwardingIntegration'
			renderRaw
			stickyHeader={
				<InvoiceForwardingIntegrationPageHeader
					tab={selectedTab}
					setTab={setSelectedTab}
					status={status}
					reload={hitTrigger}
					settings={settings}
				/>
			}>
			{selectedTab === 'records' && (
				<InvoiceForwardingRecordsListTab
					reload={hitTrigger}
					reloadTrigger={trigger}
				/>
			)}
			{selectedTab === 'settings' && (
				<InvoiceForwardingSettingsTab
					reload={hitTrigger}
					settings={settings}
				/>
			)}
		</RestrictedPageX>
	);
};
