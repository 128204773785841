import { DialogProps } from '@material-ui/core';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { IProductCatalogItem, productCatalogCommand_patchLabels } from 'gen/ApiClient';
import React from 'react';
import { LabelsForm } from 'shared/LabelsForm';
import { ProductCatalogFilterDataContext } from '../context/ProductCatalogFilterDataContext';

interface IProps extends DialogProps {
	item: IProductCatalogItem;
	cancel: VoidFunction;
	confirm: VoidFunction;
}

export const ProductCatalogItemLabelsForm = ({ item, ...rest }: IProps) => {
	const [data] = useCacheContext(ProductCatalogFilterDataContext);

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<LabelsForm
			{...rest}
			autoCompleteLabels={data.labels}
			labels={item.labels}
			updateF={values => productCatalogCommand_patchLabels({ productCatalogItemId: item.id, labels: values })}
		/>
	);
};
