import React from 'react';
import { CompaniesProviders } from './companies/CompaniesProviders';
import { EntPhysiciansProviders } from './earNoseThroatPhysicians/EntPhysiciansProviders';
import { GeneralPractitionersProviders } from './generalPractitioners/GeneralPractitionersProviders';
import { HifsProviders } from './hifs/HifsProviders';
import { SuppliersProviders } from './suppliers/SuppliersProviders';

export const ContactsProviders = ({ children }: any) => {
	return (
		<CompaniesProviders>
			<EntPhysiciansProviders>
				<GeneralPractitionersProviders>
					<SuppliersProviders>
						<HifsProviders>{children}</HifsProviders>
					</SuppliersProviders>
				</GeneralPractitionersProviders>
			</EntPhysiciansProviders>
		</CompaniesProviders>
	);
};
