import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { CustomerAccountType, ICustomerAccount } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (
	colRecord: Record<SelectableColumn, string>,
	typeRecord: Record<CustomerAccountType, string>,
	strings: IStrings
): Record<SelectableColumn, IHeader<ICustomerAccount>> => {
	return {
		created: createSortableHeaderWithKey<ICustomerAccount, SelectableColumn>(colRecord, 'created', t => formatDate(t.created)),
		outstandingBalance: createSortableHeaderWithKey<ICustomerAccount, SelectableColumn>(colRecord, 'outstandingBalance', t =>
			formatCurrency(t.outstandingBalance)
		),
		accountType: createHeaderWithKey<ICustomerAccount, SelectableColumn>(colRecord, 'accountType', t => typeRecord[t.accountType as CustomerAccountType]),
		identifier: createSortableHeaderWithKey<ICustomerAccount, SelectableColumn>(colRecord, 'identifier', t => t.identifier),
		value: createSortableHeaderWithKey<ICustomerAccount, SelectableColumn>(colRecord, 'value', t => formatCurrency(t.value)),
		lastSettlementProposalMetaReference: createSortableHeaderWithKey<ICustomerAccount, SelectableColumn>(
			colRecord,
			'lastSettlementProposalMetaReference',
			t => t.lastSettlementProposalMetaReference
		),
		lastSettlementProposalReference: createSortableHeaderWithKey<ICustomerAccount, SelectableColumn>(
			colRecord,
			'lastSettlementProposalReference',
			t => t.lastSettlementProposalReference
		),
		outstandingBalanceNotOnSettlementProposals: createSortableHeaderWithKey<ICustomerAccount, SelectableColumn>(
			colRecord,
			'outstandingBalanceNotOnSettlementProposals',
			t => formatCurrency(t.outstandingBalanceNotOnSettlementProposals)
		),
	};
};
