import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RulesList } from './RulesList';

interface IProps extends DialogProps {
	close: VoidFunction;
}

export const CommissionSettingsDialog = ({ close, ...rest }: IProps) => {
	const strings = useLocalization();
	const { isStacked } = useDialogsContext();

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			fullWidth>
			<CloseButtonOnAbsolutePosition onClick={close} />
			<DialogTitle>{`${strings.settings} ${strings.commissions.toLowerCase()}`}</DialogTitle>
			<DialogContent dividers>
				<RulesList />
			</DialogContent>
		</Dialog>
	);
};
