import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { DocumentCreateOrUploadMode } from './DocumentCreateOrUploadMode';

interface IProps extends DialogProps {
	confirm: (mode: DocumentCreateOrUploadMode) => void;
	cancel: VoidFunction;
	title: string;
}

export const DocumentCreateOrUploadModeForm = ({ confirm, cancel, title, ...rest }: IProps) => {
	const strings = useLocalization();
	const [mode, setMode] = useState<DocumentCreateOrUploadMode>('template');

	return (
		<>
			<Dialog
				{...rest}
				scroll='paper'
				fullWidth>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent
					className='df-col'
					dividers>
					<CloseButtonOnAbsolutePosition onClick={cancel} />
					<ExtendedRadioGroup<DocumentCreateOrUploadMode>
						selected={mode}
						setSelected={setMode}
						options={[
							{ value: 'template', label: strings.template, caption: strings.startFromATemplate },
							{ value: 'upload', label: strings.upload, caption: strings.uploadWrittenOrSignedDocument },
						]}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={cancel}
						color='primary'>
						{strings.cancel}
					</Button>
					<Button
						onClick={() => confirm(mode)}
						color='primary'>
						{strings.next}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
