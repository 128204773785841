import { Divider } from '@material-ui/core';
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { HifsInfoFrUrl, HifsInfoNlUrl } from 'keys';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { HifDetailsCard } from './HifDetailsCard';
import { HifListItemCardX } from './HifListItemCardX';
import { HifsListFilterCard } from './HifsListFilterCard';
import { HifsQueryContext } from './HifsQueryContext';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

export const HifsPage = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(HifsQueryContext);
	const strings = useLocalization();
	const [expandedHifCode, setExpandedHifCode] = useState<string>();
	const expandedHif = useMemo(() => queryResult?.values.find(t => t.code === expandedHifCode), [queryResult, expandedHifCode]);
	const openInNewTab = useOpenInNewTab();
	const language = useCurrentLanguage();

	const onViewLink = () => {
		openInNewTab(language === 'fr' ? HifsInfoFrUrl : HifsInfoNlUrl, true);
	};

	if (queryResult === undefined) {
		return <></>;
	}

	return (
		<RestrictedPageX
			pageClaim='Hifs'
			maxWidth={1280}
			stickyHeader={
				<div
					className='df-col gap-8 fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<PageBreadcrumbTitle title={strings.contacts} />
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={strings.healthInsuranceFunds} />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
					<LightBulbNotification>
						<div className='df-col'>
							<span>{strings.availableHifsAutomaticallySyncedWithRizivData}</span>
							<div>{strings.onThisPageItsPossibleToDefineDifferentAddresses}</div>
							<TextLinkButton
								startIcon={<OpenInBrowserOutlinedIcon />}
								onClick={onViewLink}
								color='warning'
								hoverColor='warning'>
								{`Bekijk op RIZIV website`}
							</TextLinkButton>
						</div>
					</LightBulbNotification>
				</div>
			}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ maxWidth: 500, padding: 16, paddingRight: 8 }}>
					<SearchbarX
						placeholder={strings.searchVerb}
						value={params.searchString}
						onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
					/>
					<HifsListFilterCard
						params={params}
						setParams={setParams}
					/>
					<div className='df-col gap-8'>
						{queryResult.values.map(t => (
							<HifListItemCardX
								key={t.code}
								item={t}
								isSelected={expandedHifCode === t.code}
								toggle={() => setExpandedHifCode(expandedHifCode === t.code ? undefined : t.code)}
							/>
						))}
						<QueryResultPaginationX
							queryResult={queryResult}
							setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
							style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
						/>
					</div>
				</div>
			}>
			<div
				className='df-row gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				{expandedHif !== undefined && (
					<>
						<Divider orientation='vertical' />
						<HifDetailsCard
							item={expandedHif}
							className='h-fc'
							style={{ flexGrow: 3 }}
							reload={reload}
						/>
					</>
				)}
			</div>
		</RestrictedPageX>
	);
};
