import { Button, Checkbox, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { TableCardX } from 'framework/components/cards/TableCardX';
import { PageX } from 'framework/components/layout/PageX';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { ArticlesInquiryExportProp, ArticlesInquiryExportProps, IArticlesInquiriesFilterParams, articlesInquiriesQuery_export } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TextLinkExportButtonMenuAndDialog } from 'shared/export/TextLinkExportButtonMenuAndDialog';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { calculateArticlesInquiryDetailRoute } from '../../ProcessesRoutes';
import { ArticlesInquiriesPageBreadcrumbs } from '../ArticlesInquiriesPageBreadcrumbs';
import { RegisterArticlesInquiryDialog } from '../forms/register/RegisterArticlesInquiryDialog';
import { ArticlesInquiriesListFilterCard } from './ArticlesInquiriesListFilterCard';
import { ArticlesInquiriesListOrderBySelect } from './ArticlesInquiriesListOrderBySelect';
import { ArticlesInquiriesQueryContext } from './ArticlesInquiriesQueryContext';
import { ArticlesInquiriesTable } from './ArticlesInquiriesTable';
import { createArticlesInquiriesExportRecord } from './createArticlesInquiriesExportRecord';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

export const ArticlesInquiriesListPage = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(ArticlesInquiriesQueryContext);
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [expandedId, setExpandedId] = useAccordionState<string>();
	const [expandAll, toggleExpandAll] = useStateToggleBoolean(false);
	const exportRecord = useMemo(() => createArticlesInquiriesExportRecord(strings), [strings]);

	const onRegister = () => {
		open(
			<RegisterArticlesInquiryDialog
				cancel={cancel}
				confirm={id => {
					calculateArticlesInquiryDetailRoute(id);
					confirm();
				}}
			/>
		);
	};

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<PageX
			maxWidth={1280}
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<ArticlesInquiriesPageBreadcrumbs />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
				</div>
			}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8 }}>
					<Button
						variant='outlined'
						color='primary'
						startIcon={<AddIcon />}
						onClick={onRegister}
						style={{ borderRadius: 12 }}>
						{strings.new}
					</Button>
					<ArticlesInquiriesListFilterCard
						params={params}
						setParams={setParams}
						metadata={queryResult.metadata}
					/>
					<div className='fg1'></div>
					<Divider />
					<TextLinkExportButtonMenuAndDialog<IArticlesInquiriesFilterParams, ArticlesInquiryExportProp>
						params={params}
						filteredCount={queryResult.filteredCount}
						unfilteredCount={queryResult.unfilteredCount}
						downloadF={articlesInquiriesQuery_export}
						fileName={strings.articlesInquiries}
						record={exportRecord}
						allProps={ArticlesInquiryExportProps}
						view='articlesInquiries'
						style={{ paddingBottom: 0 }}
					/>
				</div>
			}>
			<div
				className='df-col gap-16 h100'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				<SearchbarX
					placeholder={strings.searchVerb}
					value={params.searchString}
					onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
				/>
				<TableCardX
					header={
						<div className='df-col-ae'>
							<Button
								size='small'
								endIcon={
									<Checkbox
										checked={expandAll}
										style={{ padding: 0 }}
									/>
								}
								style={{ marginRight: -2 }}
								onClick={toggleExpandAll}>
								{strings.showAllContents}
							</Button>
							<ArticlesInquiriesListOrderBySelect
								sortParams={params.sortParams}
								onFilter={sortParams => setParams(onResetToPage1({ ...params, sortParams: sortParams }))}
							/>
						</div>
					}
					table={
						<ArticlesInquiriesTable
							queryResult={queryResult}
							sortParams={params.sortParams}
							onFilter={sp => setParams(onResetToPage1({ ...params, sortParams: sp }))}
							expandAll={expandAll}
							expandedId={expandedId}
							toggle={setExpandedId}
						/>
					}
					footer={
						<QueryResultPaginationX
							queryResult={queryResult}
							setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
							flexEnd
						/>
					}
				/>
			</div>
		</PageX>
	);
};
