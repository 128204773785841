import { useApiEffectWithoutLoader } from 'framework/hooks/useApiEffectWithoutLoader';
import {
	annotationTypesQuery_all,
	Application,
	applicationSettingsQuery_calendarMode,
	articlePropertyAutocompleteQuery_all,
	articlePropertyKeysQuery_all,
	bebatQuery_default,
	calendarEventTypesQuery_all,
	calendarResourcesQuery_data,
	commissionRulesQuery_all,
	entPhysiciansQuery_all,
	exactOnlineIntegrationQuery_isActive,
	generalPractitionersQuery_all,
	healthInsuranceFundsQuery_all,
	invoiceForwardingSettingsQuery_isEnabled,
	locationsQuery_cache,
	meta_url,
	notificationsQuery_latest,
	paymentMethodsQuery_cache,
	recupelsQuery_default,
	roomsQuery_all,
	rosaIntegrationQuery_frontendUrl,
	rosaIntegrationQuery_isEnabled,
	rosaViewPreferencesQuery_get,
	softwareQuery_latestBuild,
	suppliersQuery_all,
	userPreferencesQuery_get,
	usersQuery_all,
	viewColumnSelectionsQuery_defaults,
} from 'gen/ApiClient';
import React from 'react';
import { PreloadWall } from '../PreloadWall';
import { DefaultColumnSelectionsProvider } from '../selectColumns/DefaultColumnSelectionsProvider';
import { PreloadContext } from './PreloadContext';

export const PreloadProvider = ({ children }: any) => {
	const [annotationTypes] = useApiEffectWithoutLoader(annotationTypesQuery_all);
	const [bebat] = useApiEffectWithoutLoader(bebatQuery_default);
	const [recupel] = useApiEffectWithoutLoader(recupelsQuery_default);
	const [clientLatestBuild] = useApiEffectWithoutLoader(softwareQuery_latestBuild, 'Client' as Application);
	const [eidLatestBuild] = useApiEffectWithoutLoader(softwareQuery_latestBuild, 'Eid' as Application);
	const [imageScannerLatestBuild] = useApiEffectWithoutLoader(softwareQuery_latestBuild, 'ImageScanner' as Application);
	const [thermalPrinterLatestBuild] = useApiEffectWithoutLoader(softwareQuery_latestBuild, 'ThermalPrinter' as Application);
	const [calendarEventTypes] = useApiEffectWithoutLoader(calendarEventTypesQuery_all);
	const [commissionRules] = useApiEffectWithoutLoader(commissionRulesQuery_all);
	const [locations] = useApiEffectWithoutLoader(locationsQuery_cache);
	const [roomsCache] = useApiEffectWithoutLoader(roomsQuery_all);
	const [usersCache] = useApiEffectWithoutLoader(usersQuery_all);
	const [defaults] = useApiEffectWithoutLoader(viewColumnSelectionsQuery_defaults);
	const [lastNotifications] = useApiEffectWithoutLoader(notificationsQuery_latest);
	const [articlePropertyKeys] = useApiEffectWithoutLoader(articlePropertyKeysQuery_all);
	const [articlePropertiesAutocomplete] = useApiEffectWithoutLoader(articlePropertyAutocompleteQuery_all);
	const [entPhysicians] = useApiEffectWithoutLoader(entPhysiciansQuery_all);
	const [generalPractitioners] = useApiEffectWithoutLoader(generalPractitionersQuery_all);
	const [suppliers] = useApiEffectWithoutLoader(suppliersQuery_all);
	const [hifCache] = useApiEffectWithoutLoader(healthInsuranceFundsQuery_all);
	const [calendarResources] = useApiEffectWithoutLoader(calendarResourcesQuery_data);
	const [isRosaIntegrationEnabled] = useApiEffectWithoutLoader(rosaIntegrationQuery_isEnabled);
	const [rosaViewPreferences] = useApiEffectWithoutLoader(rosaViewPreferencesQuery_get);
	const [rosaUrl] = useApiEffectWithoutLoader(rosaIntegrationQuery_frontendUrl);
	const [userPreferences] = useApiEffectWithoutLoader(userPreferencesQuery_get);
	const [isExactOnlineIntegrationEnabled] = useApiEffectWithoutLoader(exactOnlineIntegrationQuery_isActive);
	const [isInvoiceForwardingIntegrationEnabled] = useApiEffectWithoutLoader(invoiceForwardingSettingsQuery_isEnabled);
	const [paymentMethods] = useApiEffectWithoutLoader(paymentMethodsQuery_cache);
	const [requestUrl] = useApiEffectWithoutLoader(meta_url);
	const [calendarMode] = useApiEffectWithoutLoader(applicationSettingsQuery_calendarMode);

	if (
		entPhysicians === undefined ||
		generalPractitioners === undefined ||
		articlePropertiesAutocomplete === undefined ||
		articlePropertyKeys === undefined ||
		lastNotifications === undefined ||
		defaults === undefined ||
		usersCache === undefined ||
		roomsCache === undefined ||
		locations === undefined ||
		commissionRules === undefined ||
		annotationTypes === undefined ||
		bebat === undefined ||
		recupel === undefined ||
		clientLatestBuild === undefined ||
		eidLatestBuild === undefined ||
		imageScannerLatestBuild === undefined ||
		thermalPrinterLatestBuild === undefined ||
		calendarEventTypes === undefined ||
		suppliers === undefined ||
		hifCache === undefined ||
		calendarResources === undefined ||
		rosaViewPreferences === undefined ||
		rosaUrl === undefined ||
		userPreferences === undefined ||
		isExactOnlineIntegrationEnabled === undefined ||
		isInvoiceForwardingIntegrationEnabled === undefined ||
		paymentMethods === undefined ||
		requestUrl === undefined ||
		calendarMode === undefined
	) {
		return <PreloadWall />;
	} else {
		return (
			<PreloadContext.Provider
				value={{
					annotationTypes: annotationTypes,
					bebat: bebat,
					recupel: recupel,
					clientLatestBuild: clientLatestBuild,
					eidLatestBuild: eidLatestBuild,
					imageScannerLatestBuild: imageScannerLatestBuild,
					thermalPrinterLatestBuild: thermalPrinterLatestBuild,
					calendarEventTypes: calendarEventTypes,
					commissionRules: commissionRules,
					locations: locations,
					roomsCache: roomsCache,
					usersCache: usersCache,
					defaults: defaults,
					lastNotifications: lastNotifications,
					articlePropertyKeys: articlePropertyKeys,
					articlePropertiesAutocomplete: articlePropertiesAutocomplete,
					entPhysicians: entPhysicians,
					generalPractitioners: generalPractitioners,
					suppliers: suppliers,
					hifCache: hifCache,
					calendarResources: calendarResources,
					isRosaIntegrationEnabled: isRosaIntegrationEnabled ?? false,
					rosaViewPreferences: rosaViewPreferences,
					rosaUrl: rosaUrl,
					userPreferences: userPreferences,
					isExactOnlineIntegrationEnabled: isExactOnlineIntegrationEnabled ?? false,
					isInvoiceForwardingIntegrationEnabled: isInvoiceForwardingIntegrationEnabled ?? false,
					paymentMethods: paymentMethods,
					requestUrl: requestUrl,
					calendarMode: calendarMode,
				}}>
				<DefaultColumnSelectionsProvider>{children}</DefaultColumnSelectionsProvider>
			</PreloadContext.Provider>
		);
	}
};
