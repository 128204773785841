import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

// TODO translate
export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		created: strings.createdAt,
		lastUpdated: strings.lastUpdated,
		reference: strings.referenceShort,
		patientName: strings.patient,
		isCancelled: strings.cancelled,
		hasWarning: strings.withWhat(strings.warning),
		handoverDate: strings.handoverDate,
		labels: strings.labels,
		isHandedOver: `${strings.handedOver}?`,
		medicalPrescriptionSentDate: `Datum opgestuurd NKO`
	};
};
