import { useApiEffect } from 'framework/hooks/useApiEffect';
import { purchasesQuery_allForSupplier } from 'gen/ApiClient';
import React, { useEffect, useState } from 'react';
import { Mode } from './Mode';
import { PaymentForPurchaseForm } from './PaymentForPurchaseForm';
import { PaymentForPurchasesForm } from './PaymentForPurchasesForm';
import { RegisterPaymentForSupplierModeForm } from './RegisterPaymentForSupplierModeForm';
import { SelectPurchaseForm } from './SelectPurchaseForm';

interface IProps {
	supplierId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const RegisterPaymentForSupplierForm = ({ supplierId, cancel, confirm }: IProps) => {
	const [purchases] = useApiEffect(purchasesQuery_allForSupplier, supplierId);
	const [mode, setMode] = useState<Mode | 'select'>('select');
	const [purchaseId, setPurchaseId] = useState<string>();

	useEffect(() => {
		if (purchases && purchases.length === 1) {
			setPurchaseId(purchases[0].id);
			setMode('single');
		}
	}, [purchases]);

	if (purchases === undefined) {
		return <div></div>;
	}

	if (mode === 'select') {
		return (
			<RegisterPaymentForSupplierModeForm
				open
				confirm={setMode}
				cancel={cancel}
			/>
		);
	} else if (mode === 'single' && purchaseId === undefined) {
		return (
			<SelectPurchaseForm
				open
				purchases={purchases}
				confirm={setPurchaseId}
				cancel={cancel}
			/>
		);
	} else if (mode === 'single' && purchaseId !== undefined) {
		return (
			<PaymentForPurchaseForm
				open
				purchaseId={purchaseId}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<PaymentForPurchasesForm
				open
				supplierId={supplierId}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	}
};
