import { useTheme } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';
import { createHeaders } from './createHeaders';
import { ISpeechModelWithSelection } from './ISpeechModelWithSelection';

interface IProps {
	tests: ISpeechModelWithSelection[];
	setTests: (val: ISpeechModelWithSelection[]) => void;
}

export const SelectSpeechAudiogramForImagesTable = ({ tests, setTests }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { leftRightOrStereoRecord } = useContext(RecordContext);
	const headers = useMemo(() => createHeaders(tests, setTests, leftRightOrStereoRecord, strings), [tests, setTests, leftRightOrStereoRecord, strings]);

	return (
		<TableWithHeadersAndValues<ISpeechModelWithSelection>
			values={tests}
			headers={headers}
			getKey={t => t.speechAudiogram.displayOrKey ?? ''} // TODO!
			padding={theme.spacing(0.5)}
			withPaperContainer
			style={{ overflow: 'visible' }}
		/>
	);
};
