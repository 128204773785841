import { Backdrop } from '@material-ui/core';
import React from 'react';
// import spinner from './spinner.gif';
import preloader from './preloader.gif';

export const Loader = () => {
	return (
		<Backdrop
			style={{ zIndex: 25000, backgroundColor: 'rgba(0, 0, 0, 0.05' }}
			open={true}>
			<img
				src={preloader}
				alt='loading...'
			/>
		</Backdrop>
	);
};
