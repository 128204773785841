import { ParamPropertyNameExclusions } from './ParamPropertyNameExclusions';

function prop<T, K extends keyof T>(obj: T, key: K) {
	return obj[key];
}
// sortParams: { property: string, direction: 'asc' | 'desc'},
export function isFiltered<T extends {}>(pars: T, exclusions?: (keyof T)[]): boolean {
	const keys = Object.keys(pars)
		.filter(t => ParamPropertyNameExclusions.indexOf(t) === -1)
		.filter(t => (exclusions !== undefined ? exclusions.indexOf(t as any) === -1 : true));
	for (let keyX of keys) {
		const x = prop(pars, keyX as any);
		const isArray = Array.isArray(x);
		// if (isArray && Array(...x).length !== 0) {
		if (isArray && x.length !== 0) {
			return true;
		} else if (isArray === false && x !== undefined) {
			return true;
		}
	}
	return false;
}
