import React from 'react';
import { IRoom, roomsCommand_updateColor } from 'gen/ApiClient';
import { ColorPickerWrapper as SharedColorPickerWrapper } from 'shared/color/ColorPickerWrapper';
import { DefaultCalendarColor } from '../../DefaultCalendarColor';

interface IProps {
	room: IRoom;
	reload: VoidFunction;
	circleSize?: number;
	width?: string | undefined;
}

export const RoomColorPickerWrapper = ({ room, reload, circleSize, width }: IProps) => {
	return (
		<SharedColorPickerWrapper
			initialColor={room.calendarColor ?? DefaultCalendarColor}
			submitF={color => roomsCommand_updateColor(room.id, color)}
			reload={reload}
			circleSize={circleSize}
			width={width}
		/>
	);
};
