import { DialogProps } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IFile } from 'framework/utils/IFile';
import { DocumentPredefinedType, rfiaDocumentsCommand_uploadPrescription } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { ScanOrUploadFileDialog } from 'shared/dialogs/scanOrUpload/ScanOrUploadFileDialog';
import { RecordContext } from 'shared/records/RecordContext';
import { SelectFilledInStepDialog, SignedStepOption } from './SelectFilledInStepDialog';

interface IProps extends DialogProps {
	rfiaId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
	suggestedStep?: SignedStepOption;
}

export const PrescriptionScanDialog = ({ rfiaId, confirm, cancel, suggestedStep = 1, ...rest }: IProps) => {
	const strings = useLocalization();
	const [file, setFile] = useState<IFile>();
	const [upload, isUploading] = useFormSubmit(rfiaDocumentsCommand_uploadPrescription);
	const { documentPredefinedTypeRecord } = useContext(RecordContext);

	const onUpload = async (file: IFile, step: SignedStepOption) => {
		const r = await upload({
			rfiaId: rfiaId,
			signedStep: step,
			contentAsBase64: file.contentAsBase64,
			fileName: file.fileName,
		});
		if (r.isSuccess) {
			confirm();
		}
	};

	if (file === undefined) {
		return (
			<ScanOrUploadFileDialog
				{...rest}
				confirm={setFile}
				cancel={cancel}
				isUploading={false}
				accept='scanOrUpload'
				title={strings.replaceWhat(documentPredefinedTypeRecord['RfiaPrescriptionScan' as DocumentPredefinedType])}
			/>
		);
	} else {
		return (
			<SelectFilledInStepDialog
				{...rest}
				confirm={step => onUpload(file, step)}
				cancel={cancel}
				suggestedStep={suggestedStep}
				previous={() => setFile(undefined)}
				isSubmitting={isUploading}
			/>
		);
	}
};
