import { Try } from 'framework/Try';
import {
	agendaQuery_export,
	CalendarEventExportProp,
	CalendarEventExportProps,
	ExportFormat,
	ICalendarEventDto,
	IExportParams,
	IFileResponse,
} from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExportSelectPropsDialog } from 'shared/export/ExportSelectPropsDialog';
import { AgendaViewContext } from '../context/AgendaViewContext';
import { mapCalendarEventToExport } from '../mapCalendarEventToExport';
import { SearchEventsExportButtonAndMenu } from './SearchEventsExportButtonAndMenu';

interface IEmptyFilterParams {}

interface IProps {
	events: ICalendarEventDto[];
}

export const SearchEventsExportButtonMenuAndDialog = ({ events }: IProps) => {
	const { open, cancel } = useDialogsContext();
	const { exportRecord } = useContext(AgendaViewContext);
	const strings = useLocalization();
	const [xDownload] = useDownload(agendaQuery_export);

	const onDownload = async (pars: IEmptyFilterParams & IExportParams): Promise<Try<IFileResponse>> => {
		return await xDownload({ events: events.map(t => mapCalendarEventToExport(t)), ...pars });
	};

	const onExportClick = (format: ExportFormat) => {
		open(
			<ExportSelectPropsDialog<IEmptyFilterParams, CalendarEventExportProp>
				open
				fileName={strings.calendar}
				close={cancel}
				params={{}}
				downloadF={onDownload}
				record={exportRecord}
				allProps={CalendarEventExportProps}
				necessaryProps={[]}
				title={strings.exportWhat(strings.calendar)}
				format={format}
				mustApplyFilter={true}
				view='calendarEvents'
			/>
		);
	};

	return (
		<SearchEventsExportButtonAndMenu
			filteredCount={events.length}
			onExportClick={onExportClick}
		/>
	);
};
