import { Paper, PaperProps, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { BatteryType, HearingAidType, IProductCatalogItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps extends PaperProps {
	item: IProductCatalogItem;
	onClear: VoidFunction;
}

export const SelectedProductCatalogItemComponent = ({ item, onClear, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const { hearingAidTypeRecord, batteryTypeRecord } = useContext(RecordContext);
	const theme = useTheme();

	return (
		<Paper
			variant='outlined'
			style={{ borderLeft: `4px solid ${theme.palette.primary.main}`, padding: 8, ...style }}
			{...rest}>
			<div className='df-row'>
				<div className='df-col fg1'>
					<LabelledProperty
						label={strings.manufacturer}
						property={item.manufacturer}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.model}
						property={item.model}
						withoutMargin
					/>
					{item.hearingAidData && (
						<>
							<LabelledProperty
								label={strings.type}
								property={hearingAidTypeRecord[item.hearingAidData?.type as HearingAidType]}
								withoutMargin
							/>
							<LabelledProperty
								label={strings.batteryType}
								property={batteryTypeRecord[item.hearingAidData?.batteryType as BatteryType]}
								withoutMargin
							/>
						</>
					)}
				</div>
				<div className='df-col-ac jc-c'>
					<ColorOnHoverIconButton
						color='secondary'
						onClick={onClear}
						size='small'>
						<DeleteIcon />
					</ColorOnHoverIconButton>
				</div>
			</div>
		</Paper>
	);
};
