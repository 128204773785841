import { Divider, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { DragAndDropContainer } from 'framework/components/dnd/DragAndDropContainer';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { LeftRightDividedContentScrollYRight } from 'framework/components/LeftRightDividedContentScrollYRight';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { updateArray } from 'framework/utils/array/updateArray';
import { IInventoryItem, inventoryItemsQuery_singleWithProductCatalogItem, IProductCatalogItem, productCatalogQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AddLineForm } from 'shared/searchAndSelect/AddLineForm';
import { TotalsExVatTable } from 'shared/searchAndSelect/TotalsExVatTable';
import { DiscountWizardButton } from 'shared/searchAndSelect/wizard/DiscountWizardButton';
import { SwipeableViewsWithTabs } from 'shared/swipeableViews/SwipeableViewsWithTabs';
import { TabPanel } from 'shared/swipeableViews/TabPanel';
import { SearchProductCatalog } from '../../../../inventoryManagement/productCatalog/search/SearchProductCatalog';
import { SearchInventoryItem } from '../../../../inventoryManagement/inventoryItems/search/SearchInventoryItem';
import { SelectAssuredInventoryItemToCreditDialog } from '../assurances/SelectAssuredInventoryItemToCreditDialog';
import { SelectProductsToAssureDialog } from '../assurances/SelectProductsToAssureDialog';
import { SearchContext } from '../context/SearchContext';
import { IPurchaseFormLine } from './IPurchaseFormLine';
import { PurchaseLineComponent } from './PurchaseLineComponent';
import { assuranceToLine } from './assuranceToLine';
import { inventoryItemToLine } from './inventoryItemToLine';
import { newLineToLine } from './newLineToLine';
import { productCatalogItemToLine } from './productCatalogItemToLine';
import { purchasedAssuranceToCreditedAssuranceLine } from './purchasedAssuranceToCreditedAssuranceLine';

interface IProps {
	lines: IPurchaseFormLine[];
	setLines: (lines: IPurchaseFormLine[]) => void;
	supplierId: string;
	setIsShiftVat: React.Dispatch<React.SetStateAction<boolean>>;
	isShiftVat: boolean;
}

export const SearchAndSelectProductsForPurchase = ({ lines, setLines, supplierId, isShiftVat, setIsShiftVat }: IProps) => {
	const strings = useLocalization();
	const { pcParams, setPcParams, pcQueryResult, iiParams, iiQueryResult, setIIParams } = useContext(SearchContext);
	const notify = useSnackbarNotify();
	const { open, confirm, cancel } = useDialogsContext();
	const [tab, setTab] = useState<number>(0);
	const loadProductCatalog = useApiCall(productCatalogQuery_single);
	const loadInventoryItem = useApiCall(inventoryItemsQuery_singleWithProductCatalogItem);

	useEffect(() => {
		setIIParams(prev => ({ ...prev, supplierId: supplierId }));
		// eslint-disable-next-line
	}, [supplierId]);

	const onPurchaseOrCreditProductCatalogItem = async (item: IProductCatalogItem, isCredit: boolean) => {
		const r = await loadProductCatalog(item.id);
		if (r.isSuccess) {
			item = r.result;
			if (item.isUniquelyIdentifiable && isCredit === false) {
				setLines([...lines, productCatalogItemToLine(item, false)]);
			} else if (item.isUniquelyIdentifiable && isCredit === true) {
				// no - op
			} else if (item.isInsuranceOrProlongedWarranty) {
				onPurchaseOrCreditAssurance(item, isCredit);
			} else {
				const f = lines.find(t => t.productCatalogItemId === item.id && t.isCreditLine === isCredit);
				if (f) {
					setLines(updateArray(f, lines, { ...f, quantity: f.quantity + 1 * (isCredit ? -1 : 1) }));
				} else {
					setLines([...lines, productCatalogItemToLine(item, isCredit)]);
				}
			}
		}
	};

	const onPurchaseOrCreditInventoryItem = async (item: IInventoryItem, isCredit: boolean) => {
		const r = await loadInventoryItem(item.id);
		if (r.isSuccess) {
			if (lines.findIndex(t => t.inventoryItemId === item.id) > -1) {
				notify(strings.itemAlreadyInList, 'warning');
			} else {
				setLines([...lines, inventoryItemToLine(r.result.inventoryItem, r.result.productCatalogItem, isCredit)]);
			}
		}
	};

	const onPurchaseOrCreditAssurance = (item: IProductCatalogItem, isCredit: boolean) => {
		if (isCredit === false) {
			open(
				<SelectProductsToAssureDialog
					open
					assurance={item}
					supplierId={supplierId}
					onAdd={assuranceLines => {
						confirm();
						setLines([...lines, ...assuranceLines.map(t => assuranceToLine(item, t))]);
					}}
					lines={lines.filter(t => t.isCreditLine === false)}
					cancel={cancel}
				/>
			);
		} else {
			open(
				<SelectAssuredInventoryItemToCreditDialog
					open
					assuranceProductCatalogItemId={item.id}
					selectedCreditedAssuranceLineIds={lines.map(t => t.creditedAssuranceLineId).filter(t => t !== undefined) as string[]}
					confirm={line => {
						confirm();
						setLines([...lines, purchasedAssuranceToCreditedAssuranceLine(item, line)]);
					}}
					cancel={cancel}
				/>
			);
		}
	};

	const onAddLine = () => {
		open(
			<AddLineForm
				open
				confirm={line => {
					confirm();
					setLines([...lines, newLineToLine(line)]);
				}}
				cancel={cancel}
			/>
		);
	};

	const onSelectItemToAssurre = (assurance: IPurchaseFormLine) => {
		open(
			<SelectProductsToAssureDialog
				open
				assurance={assurance.productCatalogItem!}
				cancel={cancel}
				lines={lines.filter(t => t.isCreditLine === false)}
				supplierId={supplierId}
				onAdd={assuranceLines => {
					confirm();
					const y = [
						...lines.filter(t => t.id !== assurance.id),
						...assuranceLines.map(t => ({
							...assuranceToLine(assurance.productCatalogItem!, t),
						})),
					];
					setLines(y);
				}}
			/>
		);
	};

	return (
		<LeftRightDividedContentScrollYRight
			leftContent={
				<div className='df-col'>
					<SwipeableViewsWithTabs
						tab={tab}
						setTab={setTab}
						textColor='secondary'
						indicatorColor='secondary'
						tabs={[strings.fromProductCatalog, strings.searchOnSerialNumber, strings.wizardAndHelp]}
						style={{ width: '500px', marginTop: '-10px', overflow: 'auto' }}>
						<TabPanel
							value={tab}
							index={0}>
							<SearchProductCatalog
								params={pcParams}
								setParams={setPcParams}
								queryResult={pcQueryResult}
								onAdd={pc => onPurchaseOrCreditProductCatalogItem(pc, false)}
								onCredit={pc => onPurchaseOrCreditProductCatalogItem(pc, true)}
								style={{ width: '500px' }}
								canCreditF={t => t.isUniquelyIdentifiable === false}
								filterType='all'
							/>
						</TabPanel>
						<TabPanel
							value={tab}
							index={1}>
							<SearchInventoryItem
								params={iiParams}
								setParams={setIIParams}
								queryResult={iiQueryResult}
								onAdd={ii => onPurchaseOrCreditInventoryItem(ii, false)}
								onCredit={ii => onPurchaseOrCreditInventoryItem(ii, true)}
								style={{ width: '500px' }}
								canSelectF={i => i.canSelectForPurchase}
								canCreditF={i => i.canSelectForCreditPurchase && i.supplierFromPurchaseId === supplierId}
								selectedIds={lines.filter(t => t.inventoryItemId !== undefined).map(t => t.inventoryItemId) as string[]}
							/>
						</TabPanel>
						<TabPanel
							value={tab}
							index={2}>
							<div className='df-col'>
								<DiscountWizardButton onClick={onAddLine} />
							</div>
						</TabPanel>
					</SwipeableViewsWithTabs>
					<div
						className='df-row-ac jc-e'
						style={{ marginTop: 8, marginBottom: -8 }}>
						<div style={{ marginRight: 8 }}>{strings.legend}</div>
						<IconButton
							color='primary'
							size='small'>
							<AddIcon />
						</IconButton>
						<div style={{ marginLeft: 8 }}>{`= ${strings.purchaseVerb}`}</div>
						<Divider style={{ marginLeft: 4, marginRight: 4 }} />
						<IconButton
							color='secondary'
							size='small'>
							<RemoveIcon />
						</IconButton>
						<div style={{ marginLeft: 8 }}>{`= ${strings.creditVerb}`}</div>
					</div>
				</div>
			}
			rightContent={
				<DragAndDropContainer<IPurchaseFormLine>
					lines={lines}
					setLines={setLines}
					getKey={t => t.id}
					render={(line, index, props) => (
						<PurchaseLineComponent
							line={line}
							index={index + 1}
							dragHandleProps={props}
							setLine={val => setLines(updateArray(line, lines, val))}
							onDelete={() => setLines(lines.filter(x => x !== line && x.assuredLineId !== line.id))}
							// location={location}
							lines={lines}
							onLinkAssurance={() => onSelectItemToAssurre(line)}
						/>
					)}
				/>
			}
			rightBottomFixedContent={
				<div className='df-col-ae'>
					<TotalsExVatTable
						lines={lines}
						style={{ width: 'fit-content' }}
						isShiftVat={isShiftVat}
					/>
					<FormControlLabelSwitch
						isChecked={isShiftVat}
						toggle={() => setIsShiftVat(!isShiftVat)}
						label={strings.shiftVat}
						style={{ marginTop: 16 }}
					/>
					<Typography variant='caption'>{strings.intraCommCaption}</Typography>
				</div>
			}
		/>
	);
};
