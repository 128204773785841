import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { DashboardRoute } from 'routes';
import { NoAccessPage } from './NoAccessPage';

interface IProps {
	children: any;
}

export const SuperUserRestrictedContent = ({ children }: IProps) => {
	const hasAccess = useLoggedInUserIsSuperUser();
	const { push } = useHistory();

	useEffect(() => {
		if (hasAccess === false) {
			push(DashboardRoute);
		}
		// eslint-disable-next-line
	}, [hasAccess]);

	return hasAccess ? children : <NoAccessPage />;
};
