import { Chip, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useMemo } from 'react';
import { IPageParams } from 'gen/ApiClient';
import { useAnchorElement } from '../../hooks/useAnchorElement';
import { onResetToPage1 } from '../../types/onResetToPage1';
import { ISearchbarProps } from '../ISearchbarProps';
import { Searchbar } from './Searchbar';

interface TModeParams {
	searchString: string;
	searchMode: string | undefined;
	pageParams: IPageParams;
}

interface IProps<TMode extends string, TParams extends TModeParams> extends ISearchbarProps {
	params: TParams;
	setParams: (params: TParams) => void;
	modeRecord: Record<TMode, string>;
	chipVariant?: 'default' | 'outlined';
	chipStyle?: React.CSSProperties;
}

export const SearchbarWithMode = <TMode extends string, TParams extends TModeParams>({
	chipVariant = 'outlined',
	modeRecord,
	params,
	setParams,
	chipStyle,
	...props
}: IProps<TMode, TParams>) => {
	const [anchor, open, close] = useAnchorElement();
	const options = useMemo(() => Object.keys(modeRecord) as TMode[], [modeRecord]);

	return (
		<Searchbar
			{...props}
			value={params.searchString}
			onSearch={text => setParams(onResetToPage1({ ...params, searchString: text }))}>
			<>
				<Chip
					color='primary'
					size='small'
					variant={chipVariant}
					label={
						<div className='df-row-ac jc-sb'>
							<div>{modeRecord[params.searchMode as TMode]}</div>
							<ArrowDropDownIcon
								fontSize='small'
								style={{ marginRight: -8 }}
							/>
						</div>
					}
					clickable
					onClick={open}
					style={chipStyle}
				/>
				<Menu
					anchorEl={anchor}
					open={Boolean(anchor)}
					onClose={close}
					keepMounted>
					{options.map(t => (
						<MenuItem
							key={t}
							onClick={() => {
								setParams(onResetToPage1({ ...params, searchMode: t }));
								close();
							}}
							value={t}>
							{modeRecord[t]}
						</MenuItem>
					))}
				</Menu>
			</>
		</Searchbar>
	);
};
