import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { IProductCatalogQueryParamsForArticlesInquiry, productCatalogQuery_queryForArticlesInquiry } from 'gen/ApiClient';
import React from 'react';
import { SearchProductCatalogItemsForArticlesInquiryContext } from './SearchProductCatalogItemsForArticlesInquiryContext';

const DefaultParams: IProductCatalogQueryParamsForArticlesInquiry = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	categories: [],
	subcategories: [],
	types: [],
};

interface IProps {
	children: any;
}

export const SearchProductCatalogItemsForArticlesInquiryProvider = ({ children }: IProps) => {
	const [params, setParams, queryResult] = useApiEffectWithParams(productCatalogQuery_queryForArticlesInquiry, DefaultParams);

	return (
		<SearchProductCatalogItemsForArticlesInquiryContext.Provider
			value={{
				params: params,
				queryResult: queryResult,
				setParams: setParams,
			}}>
			{children}
		</SearchProductCatalogItemsForArticlesInquiryContext.Provider>
	);
};
