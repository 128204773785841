import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, useTheme } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { SortableTable } from 'framework/table/SortableTable';
import { IRequestForInsuranceAllowance } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { IHandoverLine } from '../../IHandoverLine';
import { equipmentDeliveryLineToLine } from '../../utils/equipmentDeliveryLineToLine';
import { useMapLine } from '../../utils/useMapLine';
import { createHeaders } from './createHeaders';
import { IEquipmentDeliveryLineWithSelection } from './IEquipmentDeliveryLineWithSelection';

interface IProps extends DialogProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: (lines: IHandoverLine[]) => void;
	cancel: VoidFunction;
}

export const SelectLinesFromProformaHandoverForm = ({ rfia, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const [lines, setLines] = useState<IEquipmentDeliveryLineWithSelection[]>(rfia.equipmentDeliveryStep.lines.map(t => ({ isSelected: true, ...t })));
	const headers = useMemo(() => createHeaders(lines, setLines, strings), [lines, setLines, strings]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const mapper = useMapLine();

	const onConfirm = async () => {
		setIsSubmitting(true);
		const mapped = await Promise.all(lines.filter(t => t.isSelected).map(t => mapper(t, (x, line) => equipmentDeliveryLineToLine(t, line))));
		setIsSubmitting(false);
		confirm(mapped);
	};

	return (
		<Dialog {...rest}>
			<DialogTitle>{strings.selectWhat(strings.lines)}</DialogTitle>
			<DialogContent
				dividers
				style={{ padding: 0 }}>
				<SortableTable<IEquipmentDeliveryLineWithSelection>
					padding={theme.spacing(1)}
					headers={headers}
					getKey={t => t.id}
					values={lines}
					defaultSortParams={{ property: 'indexNumber', direction: 'asc' }}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<LoaderButton
					isLoading={isSubmitting}
					onClick={onConfirm}
					disabled={lines.filter(t => t.isSelected).length === 0}
					color='primary'>
					{`${strings.select} (${lines.filter(t => t.isSelected).length})`}
				</LoaderButton>
			</DialogActions>
		</Dialog>
	);
};
