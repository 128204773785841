import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IInventoryItem, ProductCatalogItemType, PropertyOfType } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { boolToYesNo } from 'shared/utils/boolToString';
import { SelectableColumn } from '../SelectableColumn';
import React from 'react';

export const createHeaders = (
	colRecord: Record<SelectableColumn, string>,
	strings: IStrings,
	typeRecord: Record<ProductCatalogItemType, string>,
	propertyOfRecord: Record<PropertyOfType, string>
): Record<SelectableColumn, IHeader<IInventoryItem>> => {
	return {
		manufacturer: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'manufacturer', s => s.manufacturer),
		model: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'model', s => s.model),
		category: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'category', s => s.category),
		subcategory: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'subcategory', s => s.subcategory),
		salesListPrice: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'salesListPrice', s => formatCurrency(s.salesListPrice)),
		salesPrice: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'salesPrice', s => formatCurrency(s.salesPrice)),
		purchaseListPrice: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'purchaseListPrice', s => formatCurrency(s.purchaseListPrice)),
		purchasePrice: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'purchasePrice', s => formatCurrency(s.purchasePrice)),
		vatPercentage: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'vatPercentage', s => `${s.vatPercentage}%`),
		firstDeliveryDate: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'firstDeliveryDate', s => formatDate(s.firstDeliveryDate)),
		dispatchDate: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'dispatchDate', s => formatDate(s.dispatchDate)),
		purchaseDate: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'purchaseDate', s => formatDate(s.purchaseDate)),
		returnToSupplierDate: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'returnToSupplierDate', s =>
			formatDate(s.returnToSupplierDate)
		),
		dateSentToSupplierForRepair: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'dateSentToSupplierForRepair', s =>
			formatDate(s.dateSentToSupplierForRepair)
		),
		dateSold: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'dateSold', s => formatDate(s.dateSold)),
		warrantyEndDate: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'warrantyEndDate', s => formatDate(s.warrantyEndDate)),
		hasBatteries: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'hasBatteries', s => boolToYesNo(s.hasBatteries, strings)),
		isInRepair: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'isInRepair', s => boolToYesNo(s.isInRepair, strings)),
		isElectronics: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'isElectronics', s => boolToYesNo(s.isElectronics, strings)),
		isHearingInstrument: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'isHearingInstrument', s =>
			boolToYesNo(s.isHearingInstrument, strings)
		),
		isRemoteControl: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'isRemoteControl', s => boolToYesNo(s.isRemoteControl, strings)),
		isPurchased: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'isPurchased', s => boolToYesNo(s.isPurchased, strings)),
		isReturnedToSupplier: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'isReturnedToSupplier', s =>
			boolToYesNo(s.isReturnedToSupplier, strings)
		),
		isSold: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'isSold', s => boolToYesNo(s.isSold, strings)),
		isTailorMade: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'isTailorMade', s => boolToYesNo(s.isTailorMade, strings)),
		productCatalogId: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'productCatalogId', s => s.productCatalogId),
		serialNumber: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'serialNumber', s =>
			s.hasSerialNumberDefined ? s.serialNumber : <em>{strings.notKnownYet}</em>
		),
		type: createHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'type', s => typeRecord[s.type as ProductCatalogItemType]),
		countTransactions: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'countTransactions', t => t.countTransactions.toString()),
		supplierIdentifier: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'supplierIdentifier', s => s.supplierIdentifier),
		patientIdentifier: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'patientIdentifier', s => s.patientIdentifier),
		currentLocationIdentifier: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(
			colRecord,
			'currentLocationIdentifier',
			s => s.currentLocationIdentifier
		),
		dispatchReference: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'dispatchReference', s => s.dispatchReference),
		propertyOf: createHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'propertyOf', s => propertyOfRecord[s.propertyOf as PropertyOfType]),
		consignmentReturnBeforeDate: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'consignmentReturnBeforeDate', s =>
			formatDate(s.consignmentReturnBeforeDate)
		),
		bebatUnitPriceInVat: createSortableHeaderWithKey<IInventoryItem, SelectableColumn>(colRecord, 'bebatUnitPriceInVat', s => s.bebatUnitPriceInVat.toString()),
	};
};
