import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { commissionsQuery_concurrencyToken, commissionsQuery_queryForUser, ICommission, ICommissionsQueryParams, IQueryResult } from 'gen/ApiClient';
import { myCommissionsQueryParamsLsKey } from '../../../../../localStorageKeys';
import { MyQueryContext } from './MyQueryContext';
import { DefaultParams } from './DefaultParams';

export const MyQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<ICommission>, ICommissionsQueryParams>
			defaultParams={DefaultParams}
			queryF={commissionsQuery_queryForUser}
			concurrencyF={commissionsQuery_concurrencyToken}
			localStorageKey={myCommissionsQueryParamsLsKey}
			context={MyQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
