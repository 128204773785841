import { IconButton, Step, StepContent, StepLabel, StepProps, Typography, useTheme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
import React from 'react';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { formatDate } from 'framework/utils/date/formatDate';
import { IRequestForInsuranceAllowance, rfiasCommand_updateExpectedHandoverDate } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ProcessButton } from 'shared/process/ProcessButton';
import { HandoverForm } from '../../forms/handover/HandoverForm';
import { EquipmentDeliveryStepDialog } from '../components/EquipmentDeliveryStepDialog';

interface IProps extends StepProps {
	rfia: IRequestForInsuranceAllowance;
	isHifExist: boolean;
	reload: VoidFunction;
}

export const HandoverEquipmentStep = ({ rfia, isHifExist, reload, ...rest }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);
	const strings = useLocalization();
	const theme = useTheme();

	const onRegister = () => {
		open(
			<HandoverForm
				rfia={rfia}
				isHifExist={isHifExist}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onViewDetail = () => {
		open(
			<EquipmentDeliveryStepDialog
				open
				close={confirm}
				rfiaId={rfia.id}
			/>
		);
	};

	const onEditExpectedHandoverDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				formTitle={strings.changeWhat(strings.expectedWhat(strings.handoverDate))}
				label={strings.expectedWhat(strings.handoverDate)}
				initialValue={rfia.equipmentDeliveryStep.expectedHandoverDate}
				submitFunction={date => rfiasCommand_updateExpectedHandoverDate(rfia.id, date)}
			/>
		);
	};

	return (
		<Step
			{...rest}
			completed={rfia.isMainProcessCompletedExceptDocuments}
			active={rfia.canHandoverEquipment || (rfia.isFailedTrial && rfia.isHandedOver === false)}>
			<StepLabel>
				<div className='df-col'>
					<div className='df-row-ac'>
						<div>{rfia.isFailedTrial ? strings.registerLumpSumContribution : strings.handoverOfEquipment}</div>
						{rfia.isHandedOver && (
							<IconButton
								size='small'
								color='primary'
								style={{ marginLeft: theme.spacing(0.5) }}
								onClick={onViewDetail}>
								<HelpOutlineIcon fontSize='small' />
							</IconButton>
						)}
					</div>
					{rfia.hasEquipmentDeliveryStep && rfia.equipmentDeliveryStep.hasHandoverDate && (
						<LabelledProperty
							label={strings.deliveryDate}
							property={formatDate(rfia.equipmentDeliveryStep.handoverDate)}
							withoutMargin
							variant='caption'
						/>
					)}
					{rfia.equipmentDeliveryStep.expectedHandoverDate && (
						<LabelledProperty
							label={strings.expectedWhat(strings.handoverDate)}
							property={formatDate(rfia.equipmentDeliveryStep.expectedHandoverDate)}
							edit={onEditExpectedHandoverDate}
							withoutMargin
							variant='caption'
						/>
					)}
				</div>
			</StepLabel>
			<StepContent>
				<div className='df-col-as'>
					<ProcessButton
						disabled={rfia.hasEquipmentDeliveryStep && rfia.equipmentDeliveryStep.hasHandoverDate}
						onClick={onRegister}
						startIcon={
							<div className='df-row-ac'>
								<SyncAltOutlinedIcon
									fontSize='small'
									style={{ marginRight: 8 }}
								/>
								<ReceiptOutlinedIcon fontSize='small' />
							</div>
						}
						text={strings.register}
					/>
					{rfia.isFailedTrial ? (
						<Typography variant='caption'>{strings.registerLumpSumContribution}</Typography>
					) : (
						<Typography
							variant='caption'
							style={{ whiteSpace: 'pre-wrap', fontStyle: 'italic' }}>{`${strings.registerHandoverEquipment}\n(${strings.attachment12AndInvoice})`}</Typography>
					)}
				</div>
			</StepContent>
		</Step>
	);
};
