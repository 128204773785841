import React from 'react';
import { FormTextField } from 'framework/forms/FormTextField';
import { useLocalization } from 'localization/useLocalization';

export const AnnotationFormField = <TModel extends { content: string | undefined }>() => {
	const strings = useLocalization();
	return (
		<FormTextField<TModel>
			name='content'
			label={strings.remarks}
			multiline
			rows={20}
		/>
	);
};
