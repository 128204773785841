import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';

interface ICommonResource {
	id: string | undefined;
	isSelected: boolean;
	label: string | undefined;
	hexColor?: string;
}

interface IProps {
	item: ICommonResource;
	onClick: VoidFunction;
	style?: React.CSSProperties;
}

export const RosaCalendarOrMotiveListItemComponent = ({ item, onClick, style, ...rest }: IProps) => {
	return (
		<ListItem
			dense
			disableGutters
			onClick={onClick}
			style={{ paddingTop: 0, paddingBottom: 0, cursor: 'pointer', ...style }}>
			<ListItemIcon style={{ minWidth: 30 }}>
				<Checkbox
					checked={item.isSelected}
					color='default'
					size='small'
					style={{ padding: 0, color: item.hexColor }}
				/>
			</ListItemIcon>
			<ListItemText primary={item.label} />
		</ListItem>
	);
};
