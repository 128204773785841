import { Popover } from '@material-ui/core';
import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IProductCatalogItemSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { RecupelCacheContext } from 'shared/context/RecupelCacheContext';

interface IProps {
	item: IProductCatalogItemSummary;
}

export const RecupelCaptionVerticallyLabelledPropertyX = ({ item }: IProps) => {
	const strings = useLocalization();
	const [data] = useCacheContext(RecupelCacheContext);
	const fData = useMemo(() => data?.find(t => t.code === item.recupelCode), [item, data]);
	const [anchor, openAnchor, closeAnchor] = useAnchorElement();

	return (
		<>
			{Boolean(anchor) && (
				<Popover
					open={Boolean(anchor)}
					anchorEl={anchor}
					onClose={closeAnchor}>
					<div
						className='df-col'
						style={{ padding: 16 }}>
						<CaptionVerticalLabelledPropertyX label={strings.code}>{fData?.code}</CaptionVerticalLabelledPropertyX>
						<CaptionVerticalLabelledPropertyX label={strings.category}>{fData?.weeeCategoryDescription}</CaptionVerticalLabelledPropertyX>
						<CaptionVerticalLabelledPropertyX label={strings.subcategory}>{fData?.subCategoryDescription}</CaptionVerticalLabelledPropertyX>
						<CaptionVerticalLabelledPropertyX label={strings.rate}>{formatCurrency(fData?.currentPriceInVat ?? 0)}</CaptionVerticalLabelledPropertyX>
					</div>
				</Popover>
			)}
			<CaptionVerticalLabelledPropertyX label={strings.recupelRate}>
				<div
					onClick={fData ? openAnchor : undefined}
					style={{ cursor: fData ? 'pointer' : 'unset' }}>
					<StringValueOrNotApplicable notApplicableText={strings.noRateDefined}>
						{item.hasRecupelCode ? formatCurrency(data?.find(t => t.code === item.recupelCode)?.currentPriceInVat ?? 0) : undefined}
					</StringValueOrNotApplicable>
				</div>
			</CaptionVerticalLabelledPropertyX>
		</>
	);
};
