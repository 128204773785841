import { IStrings } from 'localization/IStrings';
import { AgendaTimeScale } from '../AgendaTimeScale';

export const createAgendaTimeScaleRecord = (strings: IStrings): Record<AgendaTimeScale, string> => {
	return {
		'15': `15 ${strings.minutes}`,
		'30': `30 ${strings.minutes}`,
		'60': `1 ${strings.hour}`,
		'120': `2 ${strings.hours}`,
	};
};
