import React from 'react';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IFile } from 'framework/utils/IFile';
import { annotationsCommand_addAttachment } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { ScanOrUploadFileDialog } from '../dialogs/scanOrUpload/ScanOrUploadFileDialog';

interface IProps {
	id: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const UploadAnnotationAttachmentForm = ({ id, confirm, cancel }: IProps) => {
	const strings = useLocalization();
	const [uploadAttachment, isUploading] = useFormSubmit(annotationsCommand_addAttachment);
	const notify = useSnackbarNotify();

	const onFile = async (file: IFile) => {
		const r = await uploadAttachment(id, { fileName: file.fileName, contentAsBase64: file.contentAsBase64 });
		if (r.isSuccess) {
			confirm();
			notify(strings.whatAdded(strings.attachment));
		}
	};

	return (
		<ScanOrUploadFileDialog
			open
			title={strings.addWhat(strings.attachment)}
			cancel={cancel}
			isUploading={isUploading}
			accept='annotation'
			confirm={onFile}
		/>
	);
};
