import { Fade, IconButton, InputAdornment, TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { DatePicker } from '@material-ui/pickers';
import { useField } from 'formik';
import React from 'react';
import { useAnchorElement } from '../hooks/useAnchorElement';
import { useStateBoolean } from '../hooks/useStateBool';
import { DefaultDateFormat } from '../utils/date/DefaultDateFormat';
import { mapToIsoOnlyDate } from '../utils/date/mapToIsoOnlyDate';
import { nameOf } from '../utils/nameOf';
import { DatePickerViewType } from './common/DatePickerViewType';
import { FormErrorHelper } from './common/FormErrorHelper';
import { IFormProps } from './common/IFormProps';
import { StaticDatePickerPopover } from './common/StaticDatePickerPopover';
import { useFilledInputStyles } from './common/useFilledInputStyles';

interface IProps<TModel> extends IFormProps<TModel> {
	disableFuture?: boolean;
	disablePast?: boolean;
	openTo?: DatePickerViewType;
	views?: DatePickerViewType[];
	format?: string;
}

export const FormDatePicker = <TModel extends unknown>({
	disableFuture = false,
	disablePast = false,
	disabled = false,
	helperText,
	openTo = 'date',
	views = ['year', 'month', 'date'],
	format = DefaultDateFormat,
	...props
}: IProps<TModel>) => {
	const [field, meta, helpers] = useField<Date | null | undefined>(nameOf(props.name));
	const classes = useFilledInputStyles();
	const [anchor, openSelectDate, closeSelectDate] = useAnchorElement();
	const [isHovered, enter, leave] = useStateBoolean(false);

	return (
		<>
			{Boolean(anchor) && (
				<StaticDatePickerPopover
					value={field.value}
					setValue={helpers.setValue}
					anchor={anchor}
					close={closeSelectDate}
					openTo={openTo}
					views={views}
				/>
			)}
			<DatePicker
				className={classes.filledInput}
				label={props.label}
				disabled={disabled}
				{...field}
				disableFuture={disableFuture}
				disablePast={disablePast}
				clearable
				value={field.value === undefined ? null : field.value} // workaround for not handling undefined values BUT handling null values
				onChange={date => helpers.setValue(mapToIsoOnlyDate(date))}
				inputFormat={format}
				openTo={openTo}
				views={views}
				renderInput={rProps => (
					<TextField
						{...rProps}
						onMouseEnter={enter}
						onMouseLeave={leave}
						className='fg1'
						variant='filled'
						helperText=''
						required={props.required}
						error={Boolean(meta.error) && meta.touched}
						onBlur={() => helpers.setTouched(true, true)}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<Fade in={isHovered && field.value !== undefined && field.value !== null && disabled === false}>
										<CancelIcon
											color='primary'
											style={{ cursor: 'pointer' }}
											onClick={() => helpers.setValue(undefined)}
										/>
									</Fade>
									<IconButton
										edge='end'
										disabled={disabled}
										onClick={openSelectDate}>
										<InsertInvitationIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				)}
			/>
			<FormErrorHelper
				meta={meta}
				withDate
				helperText={helperText}
			/>
		</>
	);
};
