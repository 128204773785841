import React, { useContext, useEffect, useMemo, useState } from 'react';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IProductCatalogQueryParams, ProductCatalogItemType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';
import { QuickFilter } from '../ProductCatalogList';
import { SelectableColumn } from '../SelectableColumn';
import { createColumnRecord } from '../utils/createColumnRecord';
import { createExportRecord } from '../utils/createExportRecord';
import { createHeaders } from '../utils/createHeaders';
import { createQuickFilterRecord } from '../utils/createQuickFilterRecord';
import { QueryContext } from './QueryContext';
import { ViewContext } from './ViewContext';
import { useParamsFromQueryContext } from 'framework/hooks/useParamsFromQueryContext';

const DefaultColumns: Record<QuickFilter, SelectableColumn[]> = {
	all: ['type', 'created', 'identifier', 'category', 'subcategory', 'salesListPrice', 'vatPercentage'],
	intangible: ['created', 'name', 'category', 'subcategory', 'salesListPrice', 'vatPercentage'],
	hearingInstruments: [
		'created',
		'manufacturer',
		'model',
		'category',
		'subcategory',
		'salesListPrice',
		'vatPercentage',
		'internalReference',
		'manufacturerReferencesCsv',
	],
};

const DefaultedParams = {
	manufacturers: [],
	vatPercentages: [],
	createdBefore: undefined,
	createdAfter: undefined,
	lastUpdatedBefore: undefined,
	lastUpdatedAfter: undefined,
	salesPriceLessThan: undefined,
	salesPriceHigherThan: undefined,
	suppliers: [],
};

const quickFilterToTypeRecord: Record<QuickFilter, ProductCatalogItemType | undefined> = {
	all: undefined,
	hearingInstruments: 'HearingInstrument',
	intangible: undefined,
};

export const ViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns['all']);
	const colRecord = useMemo(() => createColumnRecord(strings), [strings]);
	const { productCatalogItemTypeRecord } = useContext(RecordContext);
	const headers = useMemo(() => createHeaders(colRecord, strings, productCatalogItemTypeRecord), [colRecord, productCatalogItemTypeRecord, strings]);
	const exportRecord = useMemo(() => createExportRecord(strings), [strings]);
	const [qf, setQf] = useState<QuickFilter>('all');
	const qfRecord = useMemo(() => createQuickFilterRecord(strings), [strings]);
	const { setParams } = useParamsFromQueryContext(QueryContext);

	useEffect(() => {
		setParams(params =>
			onResetToPage1<IProductCatalogQueryParams>({
				...params,
				...DefaultedParams,
				keyFilterType: quickFilterToTypeRecord[qf],
				isTangible: qf === 'intangible' ? false : undefined,
				types: qf === 'all' ? params.types : [],
				hasSerialNumber: qf === 'intangible' ? undefined : params.hasSerialNumber,
				isTailorMade: qf === 'intangible' ? undefined : params.isTailorMade,
				rizivHearingAidDefinitionV2LinkStatuses: qf !== 'hearingInstruments' ? [] : params.rizivHearingAidDefinitionV2LinkStatuses,
			})
		);
		setColumns(DefaultColumns[qf]);
		// eslint-disable-next-line
	}, [qf]);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
				exportRecord: exportRecord,
				quickFilter: qf,
				setQuickFilter: setQf,
				quickFilterRecord: qfRecord,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
