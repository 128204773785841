import { useTheme } from '@material-ui/core';
import React from 'react';
import { CardX } from './CardX';

interface IProps {
	header?: React.ReactNode;
	table?: React.ReactNode;
	footer?: React.ReactNode;
}

export const TableCardX = ({ header, table, footer }: IProps) => {
	const theme = useTheme();

	return (
		<CardX
			className='w100 df-col'
			removeHorizontalPadding>
			{header && (
				<div
					className='w100'
					style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 8, borderBottom: `1px solid ${theme.palette.divider}` }}>
					{header}
				</div>
			)}
			{table && table}
			{footer && (
				<div
					className='w100'
					style={{ paddingRight: 16, paddingLeft: 16, paddingTop: 12, borderTop: `1px solid ${theme.palette.divider}` }}>
					{footer}
				</div>
			)}
		</CardX>
	);
};
