import { Typography, useTheme } from '@material-ui/core';
import { DragAndDropContainer } from 'framework/components/dnd/DragAndDropContainer';
import { LeftRightDividedContentScrollYRight } from 'framework/components/LeftRightDividedContentScrollYRight';
import { addOrUpdateQuantity } from 'framework/utils/array/addOrUpdateQuantity';
import { updateArray } from 'framework/utils/array/updateArray';
import { IProductCatalogItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { SearchProductCatalog } from '../../../../inventoryManagement/productCatalog/search/SearchProductCatalog';
import { IArticlesInquiryLineModel } from './IArticlesInquiryLineModel';
import { mapProductCatalogItemToArticlesInquiryLineModel } from './mapProductCatalogItemToArticlesInquiryLineModel';
import { RegisterArticlesInquiryLineComponent } from './RegisterArticlesInquiryLineComponent';
import { SearchProductCatalogItemsForArticlesInquiryContext } from './SearchProductCatalogItemsForArticlesInquiryContext';

interface IProps {
	lines: IArticlesInquiryLineModel[];
	setLines: React.Dispatch<React.SetStateAction<IArticlesInquiryLineModel[]>>;
}

export const SearchAndSelectProductsForArticlesInquiry = ({ lines, setLines }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { params, setParams, queryResult } = useContext(SearchProductCatalogItemsForArticlesInquiryContext);

	const onAddPc = async (item: IProductCatalogItem) => {
		if (item.isUniquelyIdentifiable) {
			setLines([...lines, mapProductCatalogItemToArticlesInquiryLineModel(item)]);
		} else {
			setLines(addOrUpdateQuantity(item, lines, () => mapProductCatalogItemToArticlesInquiryLineModel(item)));
		}
	};

	return (
		<LeftRightDividedContentScrollYRight
			leftContent={
				<div className='df-col'>
					<Typography style={{ fontSize: '18px', marginBottom: theme.spacing(1) }}>{strings.searchFromProductCatalog}</Typography>
					<SearchProductCatalog
						params={params}
						setParams={setParams}
						queryResult={queryResult}
						onAdd={onAddPc}
						filterType='stock'
						style={{ width: '500px' }}
					/>
				</div>
			}
			rightContent={
				<DragAndDropContainer<IArticlesInquiryLineModel>
					lines={lines}
					setLines={setLines}
					getKey={t => t.id}
					render={(line, index, props) => (
						<RegisterArticlesInquiryLineComponent
							line={line}
							index={index + 1}
							dragHandleProps={props}
							setLine={val => setLines(updateArray(line, lines, val))}
							onDelete={() => setLines(lines.filter(t => t !== line))}
						/>
					)}
				/>
			}
		/>
	);
};
