import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { useApiCall } from 'framework/hooks/useApiCall';
import { createHeader } from 'framework/table/createHeader';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IMedicalTreatmentFeeDto, IRequestForInsuranceAllowance, medicalTreatmentFeesQuery_multiple } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect } from 'react';
import { MedicalTreatmentNomenclatureChip } from '../../detail/components/MedicalTreatmentNomenclatureChip';

interface IProps {
	rfia: IRequestForInsuranceAllowance;
	handOverDate: Date;
	fees: IMedicalTreatmentFeeDto[];
	setFees: (fees: IMedicalTreatmentFeeDto[]) => void;
}

export const FeesTable = ({ rfia, handOverDate, fees, setFees }: IProps) => {
	const call = useApiCall(medicalTreatmentFeesQuery_multiple);
	const strings = useLocalization();

	useEffect(() => {
		const load = async () => {
			const r = await call({ codes: rfia.allMedicalTreatmentNomenclatureCodes, date: handOverDate });
			if (r.isSuccess) {
				setFees(r.result.filter(t => t !== null && t !== undefined));
			}
		};
		if (rfia && handOverDate) {
			load();
		}
		// eslint-disable-next-line
	}, [rfia, handOverDate]);

	const headers = [
		createHeader<IMedicalTreatmentFeeDto>(strings.code, t => <MedicalTreatmentNomenclatureChip code={t.code ?? ''} />),
		createHeader<IMedicalTreatmentFeeDto>(strings.price, t => formatCurrency(t.value), 'right'),
		createHeader<IMedicalTreatmentFeeDto>(strings.contribution, t => formatCurrency(t.contribution), 'right'),
		createHeader<IMedicalTreatmentFeeDto>(strings.personalShare, t => formatCurrency(t.personalShare), 'right'),
	];

	if (fees === undefined) {
		return <div></div>;
	}

	return (
		<>
			{rfia.allMedicalTreatmentNomenclatureCodes.length !== fees.length && (
				<WarningLabelledProperty
					warning={`${strings.notAllNomenclaturesAreValidOnThisDate}: ${rfia.allMedicalTreatmentNomenclatureCodes.join(' - ')}\n${
						strings.changeDateOrNomenCode
					}`}
				/>
			)}
			{fees.length > 0 && (
				<TableWithHeadersAndValues<IMedicalTreatmentFeeDto>
					values={fees}
					headers={headers}
					getKey={t => t.code ?? ''}
					withPaperContainer
				/>
			)}
		</>
	);
};
