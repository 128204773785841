import {
	Checkbox,
	Dialog,
	DialogContent,
	DialogProps,
	DialogTitle,
	Divider,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { AxiosInstance, CancelToken } from 'axios';
import { Try } from 'framework/Try';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { DragAndDropList } from 'framework/components/dnd/DragAndDropList';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { SelectOnIdField } from 'framework/components/select/SelectOnIdField';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useApiEffectX } from 'framework/hooks/useApiEffectX';
import {
	ExportFormat,
	IExportColumnSelection,
	IExportParams,
	IFileResponse,
	INamedProperty,
	exportColumnSelectionsCommand_delete,
	exportColumnSelectionsQuery_allByView,
} from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDialogsContext } from '../dialogs/useDialogsContext';
import { ExportColumnSelectionForm } from './ExportColumnSelectionForm';
import { ExportViewType } from './ExportViewType';

interface IProps<TFilterParams, TProp extends string> extends DialogProps {
	close: VoidFunction;
	params: TFilterParams;
	necessaryProps: TProp[];
	downloadF: (args: TFilterParams & IExportParams, cancelToken?: CancelToken | undefined, instance?: AxiosInstance) => Promise<Try<IFileResponse>>;
	record: Record<TProp, string>;
	allProps: TProp[];
	fileName: string;
	mustApplyFilter: boolean;
	title: string;
	format: ExportFormat;
	view: ExportViewType;
}

const toNamedProperties = <TProp extends string>(ordered: TProp[], props: TProp[], record: Record<TProp, string>): INamedProperty[] => {
	return ordered.filter(t => props.indexOf(t) > -1).map<INamedProperty>(t => ({ property: t, text: record[t] }));
};

export const ExportSelectPropsDialog = <TParams, TProp extends string>({
	title,
	close,
	params,
	downloadF,
	record,
	allProps,
	necessaryProps,
	fileName,
	mustApplyFilter,
	format,
	view,
	...rest
}: IProps<TParams, TProp>) => {
	const strings = useLocalization();
	const [exportToXlsx, isExporting] = useDownload(downloadF);
	const [ordered, setOrdered] = useState<TProp[]>(allProps);
	const [filtered, setFiltered] = useState<TProp[]>(allProps);
	const [selectedProps, setSelectedProps] = useState<TProp[]>(allProps);
	const [lcs, reload] = useApiEffectX(exportColumnSelectionsQuery_allByView, view);
	const columnSelections = useMemo(() => lcs ?? [], [lcs]);
	const [selectedColumnSelection, setSelectedColumnSelection] = useState<IExportColumnSelection>();
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);
	const [search, setSearch] = useState<string>('');

	useEffect(() => {
		setOrdered(_.orderBy(allProps, t => record[t]));
	}, [allProps, record]);

	useEffect(() => {
		if (search !== undefined && search !== '') {
			const x = ordered.filter(t => record[t].toLowerCase().includes(search.toLowerCase()));
			setFiltered(x);
			console.log(x);
		} else {
			setFiltered(ordered);
		}
	}, [ordered, search, record]);

	const onExport = async () => {
		const r = await exportToXlsx({
			...params,
			fileName: fileName,
			headers: toNamedProperties(ordered, selectedProps, record),
			mustApplyFilter: mustApplyFilter,
			format: format,
		});
		if (r.isSuccess) {
			close();
		}
	};

	const onSave = () => {
		open(
			<ExportColumnSelectionForm
				view={view}
				columns={ordered.filter(t => selectedProps.indexOf(t) > -1)}
				confirm={onConfirm}
				cancel={cancel}
				selected={selectedColumnSelection}
			/>
		);
	};

	const onConfirm = async (id: string) => {
		confirm();
		await onReloadAndSelect(id);
	};

	const onReloadAndSelect = async (id: string) => {
		const r = await reload();
		if (r.isSuccess) {
			setSelectedColumnSelection(r.result.find(t => t.id === id));
		}
	};

	const onDelete = (val: IExportColumnSelection) => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.savedColumnSelection)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => exportColumnSelectionsCommand_delete(val.id)}
				accept={() => {
					setSelectedColumnSelection(undefined);
					confirm();
				}}
			/>
		);
	};

	const onSetSelectedColumnSelection = (val: IExportColumnSelection | undefined) => {
		setSelectedColumnSelection(val);
		if (val) {
			const selected = val.columns.filter(t => allProps.indexOf(t as any) > -1) as any;
			setOrdered([...selected, ..._.orderBy(allProps, t => record[t]).filter(t => selected.indexOf(t) === -1)]);
			setSelectedProps(selected);
		}
	};

	return (
		<Dialog
			{...rest}
			scroll='paper'
			fullWidth
			open={rest.open && isStacked === false}>
			<DialogTitle>
				<div>{title}</div>
				<Typography variant='body2'>{strings.selectExportableFieldsBelow}</Typography>
				<div className='df-row-ac jc-sb'>
					<SelectOnIdField<IExportColumnSelection>
						value={selectedColumnSelection}
						onChange={onSetSelectedColumnSelection}
						options={columnSelections}
						label={strings.savedSelection}
						className='fg1'
						style={{ marginRight: 8 }}
						withNone
						renderValue={t => t.name!}
					/>
					<div className='df-row-ac'>
						<TooltipWithSpan title={strings.saveThisSelection}>
							<IconButton
								onClick={onSave}
								edge='end'>
								<SaveOutlinedIcon />
							</IconButton>
						</TooltipWithSpan>
						<TooltipWithSpan title={strings.delete}>
							<IconButton
								edge='end'
								disabled={selectedColumnSelection === undefined}
								onClick={() => (selectedColumnSelection ? onDelete(selectedColumnSelection) : undefined)}>
								<DeleteOutlinedIcon />
							</IconButton>
						</TooltipWithSpan>
					</div>
				</div>
				<SearchbarX
					value={search}
					onSearch={setSearch}
					placeholder={`${strings.search}...`}
					style={{ marginTop: 12 }}
				/>
			</DialogTitle>
			<DialogContent
				dividers
				style={{ padding: '0px' }}>
				<ListItem
					dense
					button
					onClick={() => (selectedProps.length === allProps.length ? setSelectedProps(necessaryProps) : setSelectedProps(allProps))}>
					<ListItemIcon>
						<Checkbox
							indeterminate={selectedProps.length > 0 && selectedProps.length < allProps.length}
							checked={selectedProps.length === allProps.length}
						/>
					</ListItemIcon>
					<ListItemText primary={selectedProps.length === allProps.length ? strings.selectNone : strings.selectAll} />
				</ListItem>
				<Divider variant='middle' />
				<DragAndDropList<TProp>
					ordered={filtered}
					setOrdered={setFiltered}
					selected={selectedProps}
					setSelected={setSelectedProps}
					getKey={t => t}
					getValue={t => record[t]}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={close}
				onSubmit={onExport}
				submitText={`${strings.exportVerb} (${selectedProps.length})`}
				isSubmitting={isExporting}
				variantSubmitButton='outlined'
			/>
		</Dialog>
	);
};
