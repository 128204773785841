import { StarFavoriteIcon } from 'framework/components/icons/StarFavoriteIcon';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

export const PrimaryContactInfoIconAndTooltip = () => {
	const strings = useLocalization();

	return (
		<TooltipWithSpan title={strings.primaryContactInfosAreUsedForCommunicatingWithThePatient}>
			<StarFavoriteIcon
				isFavorite
				style={{ cursor: 'pointer', fontSize: 20, marginLeft: 2 }}
			/>
		</TooltipWithSpan>
	);
};
