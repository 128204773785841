import { useTheme } from '@material-ui/core';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { customerAccountsQuery_detail } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { SettlementProposalModelForm } from '../../settlementProposals/forms/SettlementProposalModelForm';
import { SettlementProposalDialog } from '../../settlementProposals/sps/detail/SettlementProposalDialog';
import { NavigationContext } from '../context/NavigationContext';
import { ActionsCard } from './ActionsCard';
import { CustomerAccountDetailHeader } from './CustomerAccountDetailHeader';
import { DetailsCard } from './DetailsCard';
import { PaymentsCard } from './payments/PaymentsCard';
import { SalesCard } from './sales/SalesCard';
import { SettlementProposalsCard } from './settlementProposals/SettlementProposalsCard';

export const CustomerAccountDetail = () => {
	const theme = useTheme();
	const strings = useLocalization();
	const { id } = useParams<IRouteParamsWithId>();
	const [detail, reload] = useApiEffect(customerAccountsQuery_detail, id);
	const item = useMemo(() => detail?.data, [detail]);
	const metadata = useMemo(() => detail?.metadata, [detail]);
	const { link, setLink } = useContext(NavigationContext);
	const [trigger, hitTrigger] = useTrigger();
	const { open, confirm, cancel } = useDialogsContext(hitTrigger);
	const { push } = useHistory();

	useLazyEffect(() => {
		reload();
	}, [trigger]);

	if (detail === undefined || item === undefined || metadata === undefined) {
		return <div></div>;
	}

	const onSettlementProposal = () => {
		open(
			<SettlementProposalModelForm
				open
				customerAccountId={detail.data.id}
				cancel={cancel}
				confirm={onSettlementProposalCreated}
			/>
		);
	};

	const onSettlementProposalCreated = (id: string) => {
		confirm();
		setLink('settlementProposals');
		open(
			<SettlementProposalDialog
				open
				id={id}
				close={confirm}
				withCreate
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='CustomerAccounts'
			stickyHeader={
				<CustomerAccountDetailHeader
					item={item}
					reload={hitTrigger}
					tab={link}
					setTab={setLink}
					metadata={metadata}
				/>
			}
			stickySidebar={
				<div
					className='df-col gap-16'
					style={{ padding: 16, paddingRight: 8 }}>
					{item.isPatient && (
						<CardX>
							<TextLinkButton onClick={() => push(calcPatientDetailRoute(item.accountLinkIdAsGuid!))}>
								{strings.navigateToWhat(strings.patientData)}
							</TextLinkButton>
						</CardX>
					)}
					<DetailsCard
						item={item}
						reload={hitTrigger}
					/>
					<ActionsCard
						item={item}
						metadata={metadata}
						reload={hitTrigger}
						onSps={onSettlementProposal}
					/>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{ padding: 16, paddingLeft: 8 }}>
				{link === 'sales' && (
					<SalesCard
						customerAccountId={item.id}
						reloadTrigger={trigger}
						reload={hitTrigger}
						style={{ marginBottom: theme.spacing(2) }}
					/>
				)}
				{link === 'settlementProposals' && (
					<SettlementProposalsCard
						onSps={onSettlementProposal}
						customerAccount={item}
						reloadTrigger={trigger}
						reload={hitTrigger}
						style={{ marginBottom: theme.spacing(2) }}
					/>
				)}
				{link === 'payments' && (
					<PaymentsCard
						customerAccount={item}
						reloadTrigger={trigger}
						reload={hitTrigger}
						style={{ marginBottom: theme.spacing(2) }}
					/>
				)}
			</div>
		</RestrictedPageX>
	);
};
