import { Fade, InputAdornment, TextField } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import React, { useMemo } from 'react';
import { useStateBoolean } from '../../hooks/useStateBool';
import { matchNullOrEmptyToUndefined } from '../../utils/matchNullOrEmptyToUndefined';

interface IProps {
	label: string;
	value: number;
	disabled?: boolean;
	onChange: (val: number) => void;
	min: number;
	style?: React.CSSProperties;
}

export const IntegerTextFieldWithPlusAndMin = ({ label, value, onChange, disabled = false, style, min = 0 }: IProps) => {
	const [isHovered, enter, leave] = useStateBoolean(false);
	const canMin = useMemo(() => value > min, [value, min]);

	return (
		<TextField
			style={style}
			onMouseEnter={enter}
			onMouseLeave={leave}
			variant='filled'
			fullWidth
			value={value !== undefined ? value : null} //trick to render it
			label={label}
			onChange={e => {
				const r = matchNullOrEmptyToUndefined<number>(e.target.value);
				if (r !== undefined && r >= min) {
					onChange(r);
				}
			}}
			type='text'
			inputProps={{ pattern: '[0-9]*' }}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						<Fade in={isHovered && value !== undefined && disabled === false}>
							<>
								<RemoveCircleIcon
									color={canMin ? 'primary' : 'disabled'}
									style={{ cursor: canMin ? 'pointer' : 'inherit' }}
									onClick={value > min ? () => onChange(value - 1) : undefined}
								/>
								<AddCircleIcon
									color='primary'
									style={{ cursor: 'pointer' }}
									onClick={() => onChange(value + 1)}
								/>
							</>
						</Fade>
					</InputAdornment>
				),
			}}
		/>
	);
};
