import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField } from 'formik';
import React from 'react';
import { nameOf } from '../utils/nameOf';
import { FormErrorHelper } from './common/FormErrorHelper';
import { IFormProps } from './common/IFormProps';

interface IProps<TModel> extends IFormProps<TModel> {
	options: string[];
	renderOption: (option: string) => string;
}

export const FormAutocompleteFieldFromStrings = <TModel extends unknown>({ options, renderOption, name, disabled, helperText, ...props }: IProps<TModel>) => {
	const [field, meta, helpers] = useField<string>(nameOf(name));

	return (
		<FormControl
			variant='filled'
			error={meta.error && meta.touched ? true : false}>
			<Autocomplete
				disabled={disabled}
				options={options}
				autoComplete
				autoHighlight
				autoSelect
				selectOnFocus
				getOptionLabel={renderOption}
				fullWidth
				value={field.value}
				onChange={(_, value) => {
					helpers.setTouched(true);
					helpers.setValue(value === null ? (undefined as any) : value);
				}}
				renderInput={params => (
					<TextField
						variant='filled'
						{...params}
						{...props}
						error={meta.error && meta.touched ? true : false}
					/>
				)}
			/>
			<FormErrorHelper
				meta={meta}
				helperText={helperText}
			/>
		</FormControl>
	);
};
