import { CalendarEventExportProp } from 'gen/ApiClient';
import React from 'react';
import { AgendaTimeScale } from '../AgendaTimeScale';
import { AgendaViewType } from '../AgendaViewType';

interface IViewContext {
	agendaViewTypeRecord: Record<AgendaViewType, string>;
	exportRecord: Record<CalendarEventExportProp, string>;
	agendaTimeScaleRecord: Record<AgendaTimeScale, string>;
}

export const AgendaViewContext = React.createContext<IViewContext>(undefined as any);
