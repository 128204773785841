import { Popover } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import RestoreIcon from '@material-ui/icons/Restore';
import { FabExtraSmallMoreButton } from 'framework/components/buttons/FabExtraSmallMoreButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { annotationsCommand_delete, annotationsCommand_restore, IAnnotation } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { UpdateAnnotationForm } from 'shared/annotations/UpdateAnnotationForm';
import { UploadAnnotationAttachmentForm } from 'shared/annotations/UploadAnnotationAttachmentForm';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

interface IProps {
	item: IAnnotation;
	style?: React.CSSProperties;
	reload: VoidFunction;
}

export const AnnotationFab = ({ item, style, reload }: IProps) => {
	const strings = useLocalization();
	const [anchor, openMenu, closeMenu] = useAnchorElement();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onRestore = () => {
		closeMenu();
		open(
			<AlertDialog
				open
				title={strings.repairVerb}
				content={strings.undoDeleteAnnotationQuestion}
				acceptText={strings.yes}
				rejectText={strings.no}
				reject={cancel}
				acceptF={() => annotationsCommand_restore(item.id)}
				accept={confirm}
			/>
		);
	};

	const onEdit = () => {
		closeMenu();
		open(
			<UpdateAnnotationForm
				open
				id={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDelete = () => {
		closeMenu();
		open(
			<AlertDialog
				open
				title={strings.delete}
				content={strings.deleteWhatQuestion(strings.remark)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => annotationsCommand_delete(item.id)}
				accept={confirm}
			/>
		);
	};

	const onAddAttachment = () => {
		closeMenu();
		open(
			<UploadAnnotationAttachmentForm
				id={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<>
			<FabExtraSmallMoreButton
				style={style}
				onClick={openMenu}
			/>
			{Boolean(anchor) && (
				<Popover
					open={Boolean(anchor)}
					anchorEl={anchor}
					onClose={closeMenu}>
					<div
						className='df-col'
						style={{ padding: 12 }}>
						<TextLinkButton
							startIcon={<EditOutlinedIcon />}
							onClick={onEdit}>
							{strings.edit}
						</TextLinkButton>
						<TextLinkButton
							startIcon={<AttachFileIcon />}
							onClick={onAddAttachment}>
							{strings.addAttachment}
						</TextLinkButton>
						{item.isDeleted === false && (
							<TextLinkButton
								startIcon={<DeleteOutlineIcon />}
								hoverColor='secondary'
								onClick={onDelete}>
								{strings.delete}
							</TextLinkButton>
						)}
						{item.isDeleted === true && (
							<TextLinkButton
								startIcon={<RestoreIcon />}
								hoverColor='secondary'
								onClick={onRestore}>
								{strings.repairVerb}
							</TextLinkButton>
						)}
					</div>
				</Popover>
			)}
		</>
	);
};
