import { Fade, IconButton, InputAdornment, TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { DatePicker } from '@material-ui/pickers';
import { useField } from 'formik';
import React from 'react';
import { TimeUnit, ITimeModel } from 'gen/ApiClient';
import { CalculatorIcon } from '../components/icons/CalculatorIcon';
import { useAnchorElement } from '../hooks/useAnchorElement';
import { useStateBoolean } from '../hooks/useStateBool';
import { useStateToggleBoolean } from '../hooks/useStateToggleBoolean';
import { DefaultDateFormat } from '../utils/date/DefaultDateFormat';
import { formatDate } from '../utils/date/formatDate';
import { mapToIsoOnlyDate } from '../utils/date/mapToIsoOnlyDate';
import { nameOf } from '../utils/nameOf';
import { FormErrorHelper } from './common/FormErrorHelper';
import { IFormProps } from './common/IFormProps';
import { StaticDatePickerPopover } from './common/StaticDatePickerPopover';
import { useFilledInputStyles } from './common/useFilledInputStyles';
import { TimeUnitTextField } from './TimeUnitTextField';

interface IProps<TModel> extends IFormProps<TModel> {
	disableFuture?: boolean;
	disablePast?: boolean;
	units: TimeUnit[];
	defaultUnit: TimeUnit;
	fromDate?: Date;
	defaultValue?: ITimeModel;
	openOn?: 'picker' | 'unit';
}

export const FormDatePickerWithUnit = <TModel extends unknown>({
	disableFuture = false,
	disablePast = false,
	disabled = false,
	units,
	defaultUnit,
	fromDate,
	defaultValue,
	openOn = 'unit',
	...props
}: IProps<TModel>) => {
	const [field, meta, helpers] = useField<Date | null>(nameOf(props.name));
	const classes = useFilledInputStyles();
	const [anchor, openSelectDate, closeSelectDate] = useAnchorElement();
	const [isHovered, enter, leave] = useStateBoolean(false);
	const [isShowDatePicker, toggle] = useStateToggleBoolean(openOn === 'picker' ? true : false);

	return (
		<>
			{Boolean(anchor) && (
				<StaticDatePickerPopover
					value={field.value}
					setValue={helpers.setValue}
					anchor={anchor}
					close={closeSelectDate}
				/>
			)}
			{isShowDatePicker && (
				<DatePicker
					className={classes.filledInput}
					disableFuture={disableFuture}
					disablePast={disablePast}
					label={props.label}
					disabled={disabled}
					{...field}
					clearable
					value={field.value === undefined ? null : field.value} // workaround for not handling undefined values BUT handling null values
					onChange={date => helpers.setValue(mapToIsoOnlyDate(date))}
					inputFormat={DefaultDateFormat}
					openTo='date'
					views={['year', 'month', 'date']}
					renderInput={rProps => (
						<TextField
							{...rProps}
							onMouseEnter={enter}
							onMouseLeave={leave}
							className='fg1'
							variant='filled'
							helperText=''
							required={props.required}
							error={Boolean(meta.error) && meta.touched}
							onBlur={() => helpers.setTouched(true, true)}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Fade in={isHovered && field.value !== undefined && field.value !== null && disabled === false}>
											<CancelIcon
												color='primary'
												style={{ cursor: 'pointer' }}
												onClick={() => helpers.setValue(null)}
											/>
										</Fade>
										<IconButton
											edge='end'
											disabled={disabled}
											onClick={openSelectDate}>
											<InsertInvitationIcon />
										</IconButton>
										<IconButton
											edge='end'
											disabled={disabled}
											onClick={toggle}>
											<CalculatorIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
			)}
			{isShowDatePicker === false && (
				<TimeUnitTextField
					units={units}
					defaultUnit={defaultUnit}
					label={props.label}
					onChangeDate={date => {
						const x = mapToIsoOnlyDate(date);
						if (x !== field.value) {
							// disable unneeded updates
							helpers.setValue(mapToIsoOnlyDate(date));
						}
					}}
					showDatePicker={toggle}
					fromDate={fromDate}
					defaultValue={defaultValue}
					disabled={disabled}
				/>
			)}
			<FormErrorHelper
				meta={meta}
				withDate
				helperText={isShowDatePicker === false ? `Datum: ${formatDate(field.value)}` : ''}
			/>
		</>
	);
};
