import { Button, Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';

interface ITrialReportButtonGroupProps {
	hasTest: boolean;
	onClick: VoidFunction;
	caption?: string;
}

export const TrialReportButtonGroup = ({ hasTest, onClick, caption }: ITrialReportButtonGroupProps) => {
	const theme = useTheme();
	const strings = useLocalization();

	return (
		<div className='df-col-ac'>
			{caption && (
				<Typography
					variant='caption'
					style={{ textTransform: 'uppercase' }}
					color='textSecondary'>
					{caption}
				</Typography>
			)}
			<div className='df-row-ac'>
				{hasTest === true && (
					<DoneIcon
						fontSize='small'
						style={{ color: theme.palette.success.main }}
					/>
				)}
				{hasTest === false && (
					<CloseIcon
						fontSize='small'
						style={{ color: theme.palette.error.main }}
					/>
				)}
				<Button
					variant='text'
					endIcon={hasTest ? <OpenInNewIcon fontSize='small' /> : <AddIcon fontSize='small' />}
					size='small'
					onClick={onClick}
					color='primary'
					style={{ marginLeft: theme.spacing(1), width: '80px', letterSpacing: 'normal' }}>
					{hasTest ? strings.view : strings.add}
				</Button>
			</div>
		</div>
	);
};
