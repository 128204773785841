import { PageX } from 'framework/components/layout/PageX';
import { useTrigger } from 'framework/hooks/useTrigger';
import { useNavigationLinkRouting } from 'framework/router/useNavigationLinkRouting';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import * as routes from '../routes';
import { StockTransactionTabType } from './StockTransactionTabType';
import { StockTransactionsPageHeader } from './StockTransactionsPageHeader';
import { StockTransactionsRouteRecord } from './StockTransactionsRouteRecord';
import { DeliveriesListPage } from './deliveries/list/DeliveriesListPage';
import { ReturnsListPage } from './returns/list/ReturnsListPage';
import { StockMovementsListPage } from './stockMovements/list/StockMovementsListPage';

export const StockTransactionsPage = () => {
	const [trigger, reload] = useTrigger();
	const [tab, setTab] = useNavigationLinkRouting<StockTransactionTabType>(StockTransactionsRouteRecord, 'deliveries');

	return (
		<PageX
			stickyHeader={
				<StockTransactionsPageHeader
					reload={reload}
					tab={tab}
					setTab={setTab}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					// maxWidth: 1280,
				}}>
				<Switch>
					<Route
						exact
						path={routes.DeliveriesRoute}
						render={() => <DeliveriesListPage reloadTrigger={trigger} />}
					/>
					<Route
						exact
						path={routes.ReturnsRoute}
						render={() => <ReturnsListPage reloadTrigger={trigger} />}
					/>
					<Route
						exact
						path={routes.StockMovementsRoute}
						render={() => <StockMovementsListPage reloadTrigger={trigger} />}
					/>
					<Redirect to={routes.DeliveriesRoute} />
				</Switch>
			</div>
		</PageX>
	);
};
