import { useTheme } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { IPaymentMethod } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

const MajorFontSize = 16;

interface IProps {
	item: IPaymentMethod;
	withoutBorder?: boolean;
	isSelected: boolean;
	toggle: VoidFunction;
}

export const PaymentMethodListItem = ({ item, withoutBorder, isSelected, toggle }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const color = useMemo<ColorX>(() => (item.isDeleted ? 'error' : 'default'), [item]);
	const tColor = useThemeColor(color);
	const { translatePaymentMethod } = React.useContext(RecordContext);

	return (
		<div
			className='df-row w100'
			onClick={toggle}
			style={{
				borderRight: isSelected ? `6px solid ${theme.palette.primary.main}` : 'unset',
				cursor: 'pointer',
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				paddingTop: 12,
				paddingBottom: 12,
				paddingRight: 12,
				borderRadius: 12,
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				{item.isDeleted === false && <BlurCircularIcon style={{ fontSize: 22, color: theme.palette.success.main }} />}
				{item.isDeleted === true && <BlockIcon style={{ fontSize: 22, color: theme.palette.error.main }} />}
			</div>
			<div className='df-col fg1 gap-2'>
				<span style={{ fontSize: MajorFontSize }}>{translatePaymentMethod(item.identifier)}</span>
				<div className='df-row-ac gap-4'>
					<ChipX
						label={item.isEnabled ? strings.available : strings.notAvailable}
						color={item.isEnabled ? 'success' : 'warning'}
					/>
					{item.isDeleted && (
						<ChipX
							label={strings.deleted}
							color='error'
						/>
					)}
				</div>
			</div>
		</div>
	);
};
