import { Color } from '@material-ui/lab';
import React, { useContext } from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { INoahDeviceReadOut, NoahDeviceReadOutStatus } from 'gen/ApiClient';
import { ViewContext } from './context/ViewContext';

const colorRecord: Record<NoahDeviceReadOutStatus, Color> = {
	Ignored: 'info',
	LinkedToMultiple: 'warning',
	LinkedToSingle: 'success',
	NotLinked: 'warning',
};

const fillRecord: Record<NoahDeviceReadOutStatus, boolean> = {
	LinkedToSingle: true,
	LinkedToMultiple: true,
	Ignored: false,
	NotLinked: false,
};

interface IProps {
	item: INoahDeviceReadOut;
	style?: React.CSSProperties;
}

export const NoahDeviceReadOutStatusChip = ({ item, style }: IProps) => {
	const { statusRecord } = useContext(ViewContext);
	return (
		<RectangularChip
			style={style}
			label={statusRecord[item.status as NoahDeviceReadOutStatus]}
			fill={fillRecord[item.status as NoahDeviceReadOutStatus]}
			color={colorRecord[item.status as NoahDeviceReadOutStatus]}
		/>
	);
};
