import { CardProps } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { IUserDto } from 'gen/ApiClient';
import React from 'react';
import { UserDetailsContent } from './UserDetailsContent';

interface IProps extends CardProps {
	item: IUserDto;
	reload: VoidFunction;
}

export const UserDetailsCard = ({ item, reload, ...rest }: IProps) => {
	return (
		<CardX {...rest}>
			<UserDetailsContent
				item={item}
				reload={reload}
			/>
		</CardX>
	);
};
