import CakeIcon from '@material-ui/icons/Cake';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { NumericIcon } from 'framework/components/icons/NumericIcon';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { formatDate } from 'framework/utils/date/formatDate';
import { Gender, IPatient, LanguageOption } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { GenderIcon } from 'shared/GenderIcon';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { PatientAddressForm } from '../../forms/PatientAddressForm';
import { PatientPersonalInformationForm } from '../../forms/PatientPersonalInformationForm';
import { PatchPatientLanguageRequestForm } from '../../forms/PatchPatientLanguageRequestForm';

interface IProps {
	item: IPatient;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const PatientBasicInformationCardContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { localizedLanguageRecord } = useContext(RecordContext);
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onUpdateLanguage = () => {
		open(
			<PatchPatientLanguageRequestForm
				open
				patient={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onChangePersonalInformation = () => {
		open(
			<PatientPersonalInformationForm
				open
				patient={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onChangeAddress = () => {
		open(
			<PatientAddressForm
				open
				patient={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<div
			className='df-col gap-4'
			style={style}>
			<CardTitleTypography
				title={strings.baseInformation}
				startIcon={<PersonPinOutlinedIcon style={{ marginRight: 4, opacity: 0.8 }} />}
				style={{ marginBottom: 8 }}
			/>
			<LabelledPropertyX
				icon={<PersonIcon />}
				edit={onChangePersonalInformation}>
				<div className='df-row-ac'>
					<span>{item.lastNameCommaFirstNameBracketsNickName}</span>
					<GenderIcon
						gender={item.gender as Gender}
						style={{ fontSize: 16, marginLeft: 4 }}
					/>
				</div>
			</LabelledPropertyX>
			<LabelledPropertyX
				icon={<NumericIcon />}
				edit={onChangePersonalInformation}>
				{item.hasSocialSecurityNumber === false && <em>{strings.noSocialSecurityNumberDefined}</em>}
				{item.hasSocialSecurityNumber === true && (
					<div className='df-row-ac'>
						<div style={{ marginRight: 4 }}>{item.formattedSocialSecurityNumber}</div>
						{item.isValidSocialSecurityNumber && (
							<StatusIcon
								status='success'
								fontSize='small'
							/>
						)}
						{item.isValidSocialSecurityNumber === false && (
							<TooltipWithSpan title={strings.invalidSocialSecurityNumber}>
								<StatusIcon
									status='warning'
									fontSize='small'
								/>
							</TooltipWithSpan>
						)}
					</div>
				)}
			</LabelledPropertyX>
			<LabelledPropertyX
				icon={<CakeIcon />}
				edit={onChangePersonalInformation}>
				{item.hasDobSet ? `${formatDate(item.dateOfBirth)} (${item.age})` : <em>{strings.birthDateUnknown}</em>}
			</LabelledPropertyX>
			<LabelledPropertyX
				icon={<LanguageIcon />}
				edit={onUpdateLanguage}>
				{localizedLanguageRecord[item.language as LanguageOption]}
			</LabelledPropertyX>
			<LabelledPropertyX
				icon={<HomeOutlinedIcon />}
				edit={onChangeAddress}>
				{item.hasAddressInformation ? item.addressFormatted : <em>{strings.noAddressInformation}</em>}
			</LabelledPropertyX>
		</div>
	);
};
