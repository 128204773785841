import { Button, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffectWithDefer } from 'framework/hooks/useApiEffectWithDefer';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { companiesCommand_new, IPatient, IRegisterVatCustomerRequest, patientsQuery_single } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { AddressInput } from 'shared/address/AddressInput';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IRegisterVatCustomerRequest>({
		salutation: yup.string(),
		firstName: yup.string(),
		lastName: yup.string(),
		addressLine: yup.string().required(strings.formRequired(strings.addressLine)),
		zip: yup.string().required(strings.formRequired(strings.zip)),
		city: yup.string().required(strings.formRequired(strings.city)),
		country: yup.string(),
		name: yup.string().required(),
		vatNumber: yup.string().required(),
		email: yup.string(),
		phone: yup.string(),
	});
};

const stepsRecord: Record<number, (keyof IRegisterVatCustomerRequest)[]> = {
	0: ['name', 'vatNumber'],
	1: ['firstName', 'lastName', 'salutation'],
	2: ['addressLine', 'zip', 'city', 'country'],
	3: ['phone', 'email'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	name?: string;
	patientId?: string;
}

export const RegisterCompanyForm = ({ confirm, cancel, name, patientId, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(companiesCommand_new);
	const [step, setStep] = useState<number>(0);
	const [patient] = useApiEffectWithDefer(patientsQuery_single, patientId ?? '', isNotNullNorUndefined);

	const handleSubmit = async (values: IRegisterVatCustomerRequest, helpers: FormikHelpers<IRegisterVatCustomerRequest>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.addedWhat(strings.vatCustomer));
			confirm(r.result.objectId);
		}
	};

	const onCopyFromPatient = (patient: IPatient, props: FormikProps<IRegisterVatCustomerRequest>) => {
		props.setValues({
			name: props.values.name,
			vatNumber: props.values.vatNumber,
			salutation: patient.salutation,
			addressLine: patient.addressLine ?? '',
			firstName: patient.firstName,
			lastName: patient.lastName,
			zip: patient.zip ?? '',
			city: patient.city ?? '',
			country: patient.country ?? 'BE',
			phone: props.values.phone,
			email: props.values.email,
		});
	};

	return (
		<Formik<IRegisterVatCustomerRequest>
			validateOnMount
			initialValues={{
				firstName: '',
				lastName: '',
				salutation: '',
				addressLine: '',
				city: '',
				country: '',
				zip: '',
				name: name,
				vatNumber: '',
				phone: '',
				email: '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			{props => (
				<Form>
					<OverflowDialog {...rest}>
						<DialogTitleWithFormStepper
							title={strings.newWhat(strings.vatCustomer)}
							step={step}
							labels={[strings.name, strings.address, strings.contact, strings.phone]}
						/>
						<OverflowDialogContent>
							{step === 0 && (
								<>
									<FormTextField<IRegisterVatCustomerRequest>
										name='name'
										label={`${strings.name} ${strings.vatCustomer}`}
										required
									/>
									<FormTextField<IRegisterVatCustomerRequest>
										name='vatNumber'
										label={strings.vatNumber}
										required
									/>
								</>
							)}
							{step === 1 && (
								<>
									{patient && (
										<Button
											variant='text'
											color='primary'
											onClick={() => onCopyFromPatient(patient, props)}
											style={{
												marginBottom: 8,
												marginRight: 'auto',
												textTransform: 'none',
											}}>
											{strings.copyVatDataFromPatient}
										</Button>
									)}
									<AddressInput<IRegisterVatCustomerRequest> />
								</>
							)}
							{step === 2 && (
								<>
									<FormTextField<IRegisterVatCustomerRequest>
										name='salutation'
										label={strings.salutation}
									/>
									<FormTextField<IRegisterVatCustomerRequest>
										name='firstName'
										label={strings.firstName}
									/>
									<FormTextField<IRegisterVatCustomerRequest>
										name='lastName'
										label={strings.lastName}
									/>
								</>
							)}
							{step === 3 && (
								<>
									<FormTextField<IRegisterVatCustomerRequest>
										name='phone'
										label={strings.phone}
									/>
									<FormTextField<IRegisterVatCustomerRequest>
										name='email'
										label={strings.email}
									/>
								</>
							)}
						</OverflowDialogContent>
						<PageableFormDialogActions
							step={step}
							setStep={setStep}
							cancel={cancel}
							isSubmitting={isSubmitting}
							submitText={strings.create}
							schema={createSchema(strings)}
							stepsRecord={stepsRecord}
						/>
					</OverflowDialog>
				</Form>
			)}
		</Formik>
	);
};
