import React from 'react';
import { IDefaultColumnSelectionDto } from 'gen/ApiClient';
import { ViewColumnSelectionType } from './ViewColumnSelectionType';

interface IProps {
	defaults: IDefaultColumnSelectionDto[];
	isMounted: (val: ViewColumnSelectionType) => boolean;
	setMounted: (val: ViewColumnSelectionType) => void;
}

export const DefaultColumnSelectionsContext = React.createContext<IProps>(undefined as any);
