import { MenuItem, MenuItemProps } from '@material-ui/core';
import React from 'react';

export const FilterMenuItem = ({ style, button, className, ...rest }: MenuItemProps) => {
	return (
		<MenuItem
			dense
			style={{ paddingTop: 8, paddingBottom: 8, ...style }}
			className={`df-row gap-4 ${className}`}
			button
			{...rest}
		/>
	);
};
