import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { useAutoFixCredentials } from 'app/externalApps/client/useAutoFixCredentials';
import { CodeBlock } from 'app/main/test/CodeBlock';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CollapseX } from 'framework/components/CollapseX';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { meta_url } from 'gen/ApiClient';
import {
	AssemblyError,
	configuration_Get,
	configuration_GetNoahInfo,
	configuration_GetNoahLicense,
	controls_Restart,
	CredentialsError,
	status_ExtendedStatus,
	status_FixAssembly,
} from 'gen/client/HamsClient';
import { ILicenseInfoLicenseTypeAsEnum } from 'gen/client/ILicenseInfoLicenseTypeAsEnum';
import { INoahPropertiesServerType } from 'gen/client/INoahPropertiesServerType';
import { useHandleClientReponse } from 'gen/client/useHandleClientReponse';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { formatNoahVersion } from './formatNoahVersion';
import { Divider } from '@material-ui/core';

export const HamsClientDetails = ({ reloadTrigger }: IReloadTriggerProps) => {
	const [configuration, reloadConfig] = useApiEffect(configuration_Get);
	const [noahProps, reloadNoahProps] = useApiEffect(configuration_GetNoahInfo);
	const [noahLicense, reloadNoahLicense] = useApiEffect(configuration_GetNoahLicense);
	const [requestUrl, reloadRequestUrl] = useApiEffect(meta_url);
	const [extendedStatus, reloadExtStatus] = useApiEffect(status_ExtendedStatus, requestUrl ?? '');
	const requestOrRenewCredentials = useAutoFixCredentials();
	const restartHams = useApiCall(controls_Restart);
	const fixAssembly = useApiCall(status_FixAssembly);
	const handle = useHandleClientReponse();
	const notify = useSnackbarNotify();

	useLazyEffect(() => {
		reloadConfig();
		reloadNoahProps();
		reloadNoahLicense();
		reloadRequestUrl();
		reloadExtStatus();
	}, [reloadTrigger]);

	const onFixCredentials = async (error: CredentialsError) => {
		if (error === 'NoRequestUrlProvided' || error === 'CannotReachServer') {
			notify('Cannot fix this error, contact HAMS', 'warning');
		} else {
			await onRequestOrRenewCredentials();
		}
	};

	const onFixAssembly = async (error: AssemblyError) => {
		const r = await fixAssembly();
		if (r.isSuccess) {
			handle(r.result, 'Fixed!');
			reloadExtStatus();
		}
	};

	const onRequestOrRenewCredentials = async () => {
		const r = await requestOrRenewCredentials();
		if (r !== undefined) {
			handle(r, 'Fixed!');
			reloadExtStatus();
			reloadConfig();
		}
	};

	const onRestartHams = async () => {
		const r = await restartHams();
		if (r.isSuccess) {
			reloadExtStatus();
		}
	};

	return (
		<>
			<Typography16pxBold>{`Configuration`}</Typography16pxBold>
			{configuration === null && (
				<VariantLabelledPropertyX
					variant='warning'
					link={onRequestOrRenewCredentials}
					icon={<BuildOutlinedIcon />}
					iconPosition='end'>
					{'Nog niet geconfigureerd'}
				</VariantLabelledPropertyX>
			)}
			{configuration !== null && configuration !== undefined && (
				<>
					<LabelledPropertyX label='Request url'>{requestUrl ? requestUrl : 'Nog niet ingeladen'}</LabelledPropertyX>
					<LabelledPropertyX label='Cred. Url'>{configuration.url}</LabelledPropertyX>
					<LabelledPropertyX label='ClientId'>{`${configuration.firstFourClientIdChars}***`}</LabelledPropertyX>
					<LabelledPropertyX label='ApiKey'>{`${configuration.firstFourApiKeyChars}***`}</LabelledPropertyX>
				</>
			)}
			<Divider />
			<Typography16pxBold>{`Noah properties`}</Typography16pxBold>
			{noahProps === null && <VariantLabelledPropertyX variant='error'>{'Noah properties NULL'}</VariantLabelledPropertyX>}
			{noahProps !== null && noahProps !== undefined && (
				<>
					<LabelledPropertyX label='Client version'>{formatNoahVersion(noahProps.clientVersion)}</LabelledPropertyX>
					<LabelledPropertyX label='Server version'>{formatNoahVersion(noahProps.serverVersion)}</LabelledPropertyX>
					{noahProps.serverType !== undefined && noahProps.serverType !== null && (
						<LabelledPropertyX label='Server type'>{INoahPropertiesServerType[noahProps.serverType].toString() ?? ''}</LabelledPropertyX>
					)}
					<CollapseX collapseAfter>
						<CodeBlock value={noahProps} />
					</CollapseX>
				</>
			)}
			<Divider />
			<Typography16pxBold>{'Noah License'}</Typography16pxBold>
			{noahLicense === null && <VariantLabelledPropertyX variant='error'>{'Noah License NULL'}</VariantLabelledPropertyX>}
			{noahLicense !== null && noahLicense !== undefined && (
				<>
					<LabelledPropertyX label='Company'>{noahLicense.company}</LabelledPropertyX>
					<LabelledPropertyX label='Serialnumber'>{noahLicense.serialNumber}</LabelledPropertyX>
					{noahLicense.licenseTypeAsEnum !== undefined && noahLicense.licenseTypeAsEnum !== null && (
						<LabelledPropertyX label='License type'>{ILicenseInfoLicenseTypeAsEnum[noahLicense.licenseTypeAsEnum].toString() ?? ''}</LabelledPropertyX>
					)}
					<CollapseX collapseAfter>
						<CodeBlock value={noahLicense} />
					</CollapseX>
				</>
			)}
			<Divider />
			<Typography16pxBold>{'Status'}</Typography16pxBold>
			{requestUrl && extendedStatus && (
				<>
					<VariantLabelledPropertyX
						label='Credentials'
						variant={extendedStatus.checkCredentials!.hasError ? 'error' : 'success'}
						link={() => onFixCredentials(extendedStatus.checkCredentials!.error! as CredentialsError)}
						iconPosition='end'
						icon={<BuildOutlinedIcon />}>
						{extendedStatus.checkCredentials!.hasError ? extendedStatus.checkCredentials!.error! : 'Credentials check'}
					</VariantLabelledPropertyX>
					<VariantLabelledPropertyX
						label='Assembly'
						variant={extendedStatus.checkAssembly!.hasError ? 'error' : 'success'}
						link={() => onFixAssembly(extendedStatus.checkAssembly!.error! as AssemblyError)}
						icon={<BuildOutlinedIcon />}
						iconPosition='end'>
						{extendedStatus.checkAssembly!.hasError ? extendedStatus.checkAssembly!.error! : 'Assembly check'}
					</VariantLabelledPropertyX>
					<VariantLabelledPropertyX
						label='License'
						variant={extendedStatus.checkNoahLicense!.hasError ? 'error' : 'success'}>
						{extendedStatus.checkNoahLicense!.hasError ? extendedStatus.checkNoahLicense!.error! : 'Noah license info'}
					</VariantLabelledPropertyX>
				</>
			)}
			<Typography16pxBold>{'Actions'}</Typography16pxBold>
			<TextLinkButton
				startIcon={<VerifiedUserOutlinedIcon />}
				onClick={onRequestOrRenewCredentials}
				color='primary'>
				{`Request/renew credentials`}
			</TextLinkButton>
			<TextLinkButton
				startIcon={<PlayCircleFilledWhiteOutlinedIcon />}
				onClick={onRestartHams}
				color='primary'>
				{`Restart Hams.Client`}
			</TextLinkButton>
		</>
	);
};
