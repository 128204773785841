import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const circularLettersNlLink = 'https://www.inami.fgov.be/nl/professionals/individuelezorgverleners/audiciens/Paginas/omzendbrieven-audicien.aspx';
const circularLettersFrLink = 'https://www.inami.fgov.be/fr/professionnels/sante/audiciens/Pages/circulaires-audicien.aspx';
const portalNlLink = 'https://www.riziv.fgov.be/nl/professionals/individuelezorgverleners/audiciens/Paginas/default.aspx';
const portalFrLink = 'https://www.riziv.fgov.be/fr/professionnels/sante/audiciens/Pages/default.aspx';

interface IProps {
	style?: React.CSSProperties;
}

export const UsefulLinksCard = ({ style }: IProps) => {
	const openInNewTab = useOpenInNewTab();
	const language = useCurrentLanguage();
	const strings = useLocalization();

	const onOpenCircularLetters = () => {
		openInNewTab(language === 'fr' ? circularLettersFrLink : circularLettersNlLink, true);
	};

	const onOpenPortal = () => {
		openInNewTab(language === 'fr' ? portalFrLink : portalNlLink, true);
	};

	return (
		<CardX
			className='df-col gap-8'
			style={style}>
			<Typography16pxBold>{`${strings.usefulLinks} (${strings.RIZIV})`}</Typography16pxBold>
			<TextLinkButton
				underline
				color='primary'
				startIcon={<OpenInBrowserIcon />}
				onClick={onOpenPortal}>
				{strings.portal}
			</TextLinkButton>
			<TextLinkButton
				underline
				color='primary'
				startIcon={<OpenInBrowserIcon />}
				onClick={onOpenCircularLetters}>
				{strings.circularLetters}
			</TextLinkButton>
		</CardX>
	);
};
