import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { FormSelectUserField } from 'app/main/settings/users/forms/FormSelectUserField';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	IInventoryItem,
	IRepairFromInventoryItemModel,
	RepairProcessSteps,
	repairsCommand_newFromInventoryItem,
	repairsQuery_nextReference,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import * as yup from 'yup';
import { FormSelectOrCreateSupplierField } from '../../../contacts/suppliers/forms/FormSelectOrCreateSupplierField';
import { FormRepairProcessStepsField } from './FormRepairProcessStepsField';
import { FormRepairReasonsField } from './FormRepairReasonsField';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRepairFromInventoryItemModel>({
			reference: yup.string().required(strings.formRequired(strings.reference)),
			isAutoSelectReference: yup.boolean().defined(),
			supplierId: yup.string().required(strings.formRequired(strings.supplier)),
			patientId: yup.string(),
			steps: yup.mixed(),
			inventoryItemId: yup.string().required(strings.formRequired(strings.inventoryItem)),
			reasons: yup.mixed(),
			handlingUserId: yup.string().required(strings.formRequired(strings.user)),
		})
		.defined();
};

const emptyValues: IRepairFromInventoryItemModel = {
	isAutoSelectReference: true,
	reference: '',
	supplierId: undefined as any,
	patientId: undefined as any,
	steps: [],
	inventoryItemId: undefined as any,
	reasons: [],
	handlingUserId: '',
};

const stepsRecord: Record<number, (keyof IRepairFromInventoryItemModel)[]> = {
	0: ['reasons', 'inventoryItemId'],
	1: ['reference', 'isAutoSelectReference'],
	2: ['supplierId', 'handlingUserId'],
	3: ['steps'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	patientId?: string;
	item: IInventoryItem;
}

export const RepairFromInventoryItemModelForm = ({ confirm, cancel, patientId, item, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(repairsCommand_newFromInventoryItem);
	const [step, setStep] = useState<number>(0);
	const steps = useMemo(() => (patientId !== undefined ? RepairProcessSteps : RepairProcessSteps.filter(t => t !== 'DeliverToPatient')), [patientId]);
	const user = useLoggedInUser();

	const handleSubmit = async (values: IRepairFromInventoryItemModel, helpers: FormikHelpers<IRepairFromInventoryItemModel>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.registerWhat(strings.repair));
			confirm(r.result.objectId);
		} else {
			notify(r.result.errors.map(t => t.error).join(`\n`), 'error');
		}
	};

	return (
		<Formik<IRepairFromInventoryItemModel>
			validateOnMount
			initialValues={{
				...emptyValues,
				patientId: patientId,
				steps: steps,
				supplierId: item.supplierId ?? '',
				inventoryItemId: item.id,
				handlingUserId: user.id,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					isSubmitting={isSubmitting}
					cancel={cancel}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: () => void;
	isSubmitting: boolean;
}

const InnerDialog = ({ step, setStep, cancel, isSubmitting, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const { isStacked } = useDialogsContext();
	const props = useFormikContext<IRepairFromInventoryItemModel>();
	useNextReferenceInterceptor(repairsQuery_nextReference, props);

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='md'
			open={rest.open && isStacked === false}>
			<DialogTitleWithFormStepper
				title={strings.registerWhat(strings.repair)}
				step={step}
				labels={[strings.reasons, strings.referenceShort, `${strings.supplier} & ${strings.user}`, strings.steps]}
			/>
			<DialogContent dividers>
				{step === 0 && (
					<div className='df-col'>
						<FormRepairReasonsField<IRepairFromInventoryItemModel>
							name='reasons'
							label={strings.reasons}
						/>
					</div>
				)}
				{step === 1 && (
					<div className='df-col'>
						<FormSingleCheckboxField<IRepairFromInventoryItemModel>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IRepairFromInventoryItemModel>
							name='reference'
							label={strings.reference}
							disabled={props.values.isAutoSelectReference}
						/>
					</div>
				)}
				{step === 2 && (
					<div className='df-col'>
						<FormSelectOrCreateSupplierField<IRepairFromInventoryItemModel>
							name='supplierId'
							label={strings.supplier}
						/>
						<FormSelectUserField<IRepairFromInventoryItemModel>
							name='handlingUserId'
							label={strings.user}
						/>
					</div>
				)}
				{step === 3 && <FormRepairProcessStepsField<IRepairFromInventoryItemModel> isForPatient={props.values.patientId !== undefined} />}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
