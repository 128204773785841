import { DefaultParams } from 'app/main/inventoryManagement/stock/context/QueryProvider';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { stockEntriesQuery_queryStockItems, stockEntriesQuery_single } from 'gen/ApiClient';
import React from 'react';
import { useParams } from 'react-router-dom';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { StockEntryDetailHeader } from './StockEntryDetailHeader';
import { StockItemsOrDirtyCheck } from './stockItems/StockItemsOrDirtyCheck';

export const StockEntryDetail = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [trigger, hitTrigger] = useTrigger();
	const [item, reload] = useApiEffect(stockEntriesQuery_single, id);
	const [params, setParams, queryResult] = useApiEffectWithParams(pars => stockEntriesQuery_queryStockItems(id, pars), DefaultParams, trigger);

	useLazyEffect(() => {
		reload();
	}, [trigger]);

	if (item === undefined) {
		return <></>;
	}

	return (
		<RestrictedPageX
			pageClaim='StockEntries'
			stickyHeader={
				<StockEntryDetailHeader
					item={item}
					reload={hitTrigger}
					style={{ paddingBottom: 16 }}
				/>
			}>
			<StockItemsOrDirtyCheck
				item={item}
				params={params}
				queryResult={queryResult}
				reload={reload}
				setParams={setParams}
			/>
		</RestrictedPageX>
	);
};
