import React from 'react';
import { ReturnsListViewProvider } from './list/ReturnsListViewProvider';
import { ReturnsQueryProvider } from './list/ReturnsQueryProvider';

export const ReturnsProviders = ({ children }: any) => {
	return (
		<ReturnsQueryProvider>
			<ReturnsListViewProvider>{children}</ReturnsListViewProvider>
		</ReturnsQueryProvider>
	);
};
