import React from 'react';
import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import { IStrings } from 'localization/IStrings';
import { ISearchAndSelectLineWithAssurance } from '../ISearchAndSelectLineWithAssurance';

export const createHeaders = <TLine extends ISearchAndSelectLineWithAssurance & { isSelected: boolean }>(
	lines: TLine[],
	setLines: React.Dispatch<React.SetStateAction<TLine[]>>,
	strings: IStrings
): IHeader<TLine>[] => {
	return [createSelectionHeader<TLine>(lines, setLines), createSortableHeader<TLine>(strings.description, 'description', t => t.description)];
};
