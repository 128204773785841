import React from 'react';
import { Try } from 'framework/Try';
import { IInventoryItem, IProductCatalogItem, IQueryResult } from 'gen/ApiClient';
import { ICommonParams as IProductCatalogCommonParams } from 'app/main/inventoryManagement/productCatalog/search/ICommonParams';
import { ICommonParams as IInventoryItemsCommonParams } from 'app/main/inventoryManagement/inventoryItems/search/ICommonParams';
import { ISearchAndSelectLine } from './ISearchAndSelectLine';
import { SearchWithTabsContainer } from './SearchWithTabsContainer';

interface IProps<TLine extends ISearchAndSelectLine, TIIParams extends IInventoryItemsCommonParams, TPcParams extends IProductCatalogCommonParams> {
	lines: TLine[];
	canSelectInventoryItemF: (item: IInventoryItem) => boolean;
	iiParams: TIIParams;
	setIIParams: React.Dispatch<React.SetStateAction<TIIParams>>;
	iiQueryResult: IQueryResult<IInventoryItem> | undefined;
	pcParams: TPcParams;
	setPcParams: React.Dispatch<React.SetStateAction<TPcParams>>;
	pcQueryResult: IQueryResult<IProductCatalogItem> | undefined;
	queryInventoryItemF: (params: TIIParams) => Promise<Try<IQueryResult<IInventoryItem>>>;
	defaultIIParams: TIIParams;
	onAddPc: (item: IProductCatalogItem) => void;
	onAddII: (item: IInventoryItem, pc: IProductCatalogItem) => void;
}

export const SearchStockProductCatalogInventoryItemTabs = <
	TLine extends ISearchAndSelectLine,
	TIIParams extends IInventoryItemsCommonParams,
	TPcParams extends IProductCatalogCommonParams
>({
	...props
}: IProps<TLine, TIIParams, TPcParams>) => {
	return (
		<SearchWithTabsContainer<TLine, TIIParams, TPcParams>
			{...props}
			productCatalogFilterType='stock'
		/>
	);
};
