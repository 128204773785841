import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { ILoan, LoanProcessStatus } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { LoansSettingsroute } from '../../ProcessesRoutes';
import { LoanStatusChip } from '../LoanStatusChip';
import { LoansPageBreadcrumbs } from '../LoansPageBreadcrumbs';
import { LoanProcessStatusToColorXRecord } from '../list/LoanProcessStatusToColorXRecord';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

interface IProps {
	item: ILoan;
	reload: VoidFunction;
}

export const LoanDetailPageHeader = ({ item, reload }: IProps) => {
	const color = useMemo(() => LoanProcessStatusToColorXRecord[item.status as LoanProcessStatus], [item]);

	return (
		<div
			className='df-row-ac fg1'
			style={{ paddingBottom: 16 }}>
			<LoansPageBreadcrumbs />
			<PageBreadcrumbDivider />
			<PageBreadcrumbTitle title={item.reference} />
			<LoanStatusChip
				item={item}
				color={color}
				style={{ marginRight: 'auto', marginLeft: 4 }}
			/>
			<div className='fg1'></div>
			<PageXHeaderActions>
				<PageSettingsButtonX route={LoansSettingsroute} />
				<PageReloadButtonX reload={reload} />
			</PageXHeaderActions>
		</div>
	);
};
