import { Card, CardMedia, CardProps, Divider, useTheme } from '@material-ui/core';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { createHeader } from 'framework/table/createHeader';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { combineClassNames } from 'framework/utils/combineClassNames';
import { createType } from 'framework/utils/createType';
import {
	CustomerAccountLineExportProp,
	CustomerAccountLineExportProps,
	customerAccountLinesQuery_export,
	customerAccountLinesQuery_query,
	ICustomerAccountLine,
	ICustomerAccountLinesFilterParams,
	ICustomerAccountLinesQueryParams,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsHeader } from 'shared/table/createDetailsHeader';
import { CustomerAccountLineDialog } from '../CustomerAccountLineDialog';
import { ViewContext } from './context/ViewContext';
import { CustomerAccountLineStatusChip } from './CustomerAccountLineStatusChip';
import { Filter } from './Filter';
import { SelectableColumn } from './SelectableColumn';

const DefaultParams: ICustomerAccountLinesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	hasOutstandingBalance: undefined,
	outstandingBalanceLessThan: undefined,
	outstandingBalanceMoreThan: undefined,
	salesDateBefore: undefined,
	salesDateCreatedAfter: undefined,
	hasSettlementProposal: undefined,
};

const QuickFilters = createType('all', 'withOutstandingBalance', 'withOutstandingBalanceAndWithoutSettlementProposal');
export type QuickFilter = typeof QuickFilters[number];

interface IProps extends IReloadTriggerProps, CardProps {
	customerAccountId: string;
	reload: VoidFunction;
}

// TODO watch out for the reload!
export const SalesCard = ({ customerAccountId, reloadTrigger, reload, className, ...rest }: IProps) => {
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(
		pars => customerAccountLinesQuery_query(customerAccountId, pars),
		DefaultParams,
		reloadTrigger
	);
	const theme = useTheme();
	const { columns, setColumns, columnsRecord, headers, quickFilter, setQuickFilter, quickFilterRecord, exportRecord } = useContext(ViewContext);
	const { open, confirm } = useDialogsContext(reload);
	const prependColumn = createHeader<ICustomerAccountLine>(strings.status, t => <CustomerAccountLineStatusChip cal={t} />);

	useEffect(() => {
		setParams(pars =>
			onResetToPage1({
				...pars,
				hasOutstandingBalance:
					quickFilter === 'withOutstandingBalance' || quickFilter === 'withOutstandingBalanceAndWithoutSettlementProposal' ? true : undefined,
				hasSettlementProposal: quickFilter === 'withOutstandingBalanceAndWithoutSettlementProposal' ? false : undefined,
			})
		);
		// eslint-disable-next-line
	}, [quickFilter]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onViewDetail = (id: string) => {
		open(
			<CustomerAccountLineDialog
				open
				id={id}
				close={confirm}
				entry='customerAccount'
			/>
		);
	};

	return (
		<Card
			className={combineClassNames('fg1', className)}
			{...rest}>
			<CardTitleTypography
				withPadding
				title={strings.sales}
			/>
			<Divider />
			<div
				className='df-row-ac jc-sb'
				style={{ padding: theme.spacing(1) }}>
				<ButtonGroupWithSelection<QuickFilter>
					options={QuickFilters}
					selected={quickFilter}
					setSelected={setQuickFilter}
					renderOption={t => quickFilterRecord[t]}
				/>
				<div>
					<ExportButtonMenuAndDialog<ICustomerAccountLinesFilterParams, CustomerAccountLineExportProp>
						params={params}
						filteredCount={queryResult.filteredCount}
						unfilteredCount={queryResult.unfilteredCount}
						allProps={CustomerAccountLineExportProps}
						downloadF={(...pars) => customerAccountLinesQuery_export(customerAccountId, ...pars)}
						fileName='Klantenrekening_lijnen'
						record={exportRecord}
						view='sales'
					/>
					<SelectColumnsButtonAndDialog
						selectedColumns={columns}
						setSelectedColumns={setColumns}
						record={columnsRecord}
						view='sales'
					/>
				</div>
			</div>
			<Divider />
			<SearchbarWithParams
				params={params}
				setParams={setParams}
				placeholder={strings.searchVerb}
				style={{ width: '400px' }}
				variant='embedded'
			/>
			<Divider />
			<div className='df-row jc-sb'>
				<Filter
					params={params}
					onFilter={params => setParams(onResetToPage1(params))}
					paddingLeft={theme.spacing(1.5)}
				/>
			</div>
			<Divider />
			<CardMedia>
				<PageableTableWithColumnSelection<ICustomerAccountLine, SelectableColumn>
					queryResult={queryResult}
					headers={headers}
					sortParams={params.sortParams}
					pageParams={params.pageParams}
					setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
					getKey={inst => inst.id}
					selectedColumns={columns}
					prependColumn={prependColumn}
					appendColumn={createDetailsHeader<ICustomerAccountLine>(t => onViewDetail(t.id))}
				/>
			</CardMedia>
		</Card>
	);
};
