import { ArticlesInquiryExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createArticlesInquiriesExportRecord = (strings: IStrings): Record<ArticlesInquiryExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		CountItems: `# ${strings.items}`,
		Reference: strings.reference,
		Status: strings.status,
		PatientIdentifier: strings.patient,
	};
};
