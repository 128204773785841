import React, { useMemo, useState } from 'react';
import { documentsCommand_createFromTemplate, LinkViewContextType, TemplateType } from 'gen/ApiClient';
import { DocumentCreateOrUploadMode } from './DocumentCreateOrUploadMode';
import { DocumentCreateOrUploadModeForm } from './DocumentCreateOrUploadModeForm';
import { DocumentUploadForm } from './DocumentUploadForm';
import { SelectTemplateFromContextForm } from './SelectTemplateFromContextForm';
import { SelectTemplateOrStandardDocumentFromPredefinedTypeForm } from './SelectTemplateOrStandardDocumentFromPredefinedTypeForm';

type State = 'mode' | DocumentCreateOrUploadMode;

interface IProps {
	cancel: VoidFunction;
	confirm: VoidFunction;
	title: string;
	context: LinkViewContextType;
	additionalContexts?: LinkViewContextType[];
	contextId: string;
	templatePredefinedType?: TemplateType;
}

export const DocumentCreateOrUploadForm = ({ cancel, confirm, title, context, additionalContexts = [], contextId, templatePredefinedType }: IProps) => {
	const [state, setState] = useState<State>('mode');
	const contexts = useMemo(() => [...additionalContexts, context], [additionalContexts, context]);

	if (state === 'mode') {
		return (
			<DocumentCreateOrUploadModeForm
				open
				confirm={setState}
				cancel={cancel}
				title={title}
			/>
		);
	} else if (state === 'template' && templatePredefinedType === undefined) {
		return (
			<SelectTemplateFromContextForm
				open
				contexts={contexts}
				submitF={id => documentsCommand_createFromTemplate({ contextId: contextId, linkViewContextType: context, templateId: id, standardDocumentId: '' })}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	} else if (state === 'template' && templatePredefinedType !== undefined) {
		return (
			<SelectTemplateOrStandardDocumentFromPredefinedTypeForm
				open
				type={templatePredefinedType}
				submitF={({ templateId, standardDocumentId }) =>
					documentsCommand_createFromTemplate({
						contextId: contextId,
						linkViewContextType: context,
						templateId: templateId,
						standardDocumentId: standardDocumentId,
					})
				}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	} else {
		return (
			<DocumentUploadForm
				open
				contextId={contextId}
				contextType={context}
				predefinedType={templatePredefinedType}
				title={title}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	}
};
