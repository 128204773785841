import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import ScannerIcon from '@material-ui/icons/Scanner';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { FileDownloadOutlineIcon } from 'framework/components/icons/FileDownloadOutlineIcon';
import { FileSearchOutlineIcon } from 'framework/components/icons/FileSearchOutlineIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import {
	DocumentExportFormat,
	IPrescriptionScan,
	documentsQuery_download,
	rfiaDocumentsCommand_deletePrescriptionScan,
	rfiaDocumentsCommand_deletePrescriptionScanHistory,
	rfiasQuery_singlePrescriptionScan,
} from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { usePdfViewerWithDocument } from 'shared/dialogs/usePdfViewerWithDocument';
import { PrescriptionScanDialog } from './PrescriptionScanDialog';
import { PrescriptionScanHistory } from './PrescriptionScanHistory';
import { ScanStatusFibers } from './ScanStatusFibers';

interface IProps extends DialogProps {
	rfiaId: string;
	scan: IPrescriptionScan;
	requiredStep: number;
	close: VoidFunction;
}

export const PrescriptionDetailDialog = ({ rfiaId, requiredStep, close, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [scan, reload] = useApiEffect(rfiasQuery_singlePrescriptionScan, rfiaId);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);
	const [download] = useDownload(documentsQuery_download);
	const openPdf = usePdfViewerWithDocument();
	const showHistory = useMemo(() => scan && scan.history.length > 0, [scan]);

	if (scan === undefined || scan === null) {
		return <div></div>;
	}

	const onScan = () => {
		open(
			<PrescriptionScanDialog
				open
				rfiaId={rfiaId}
				confirm={confirm}
				cancel={cancel}
				suggestedStep={Math.min(scan.step + 1, 4) as any}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title={strings.deleteAttachment17}
				content={strings.doYouWantToDeleteAttachment17}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => rfiaDocumentsCommand_deletePrescriptionScan({ rfiaId: rfiaId })}
				accept={() => {
					confirm();
					close();
				}}
			/>
		);
	};

	const onDeleteHistory = (historyId: string) => {
		open(
			<AlertDialog
				open
				title={strings.deleteScanHistory}
				content={strings.doYouWantToDeleteScanHistory}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => rfiaDocumentsCommand_deletePrescriptionScanHistory({ rfiaId: rfiaId, historyId: historyId })}
				accept={confirm}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<DescriptionIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{strings.prescriptionAttachment17}
					</Typography>
					<LabelledProperty
						label={strings.extension}
						property={scan.extension}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.status}
						property={`${strings.scanned}: ${scan.step}, ${strings.needed.toLowerCase()}: ${requiredStep}`}
					/>
					<ScanStatusFibers
						scan={scan}
						requiredStep={requiredStep}
					/>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							{scan.isPdf && (
								<SmallPrimaryTextButton
									onClick={() => openPdf('Bijlage 17', scan.id)}
									startIcon={<FileSearchOutlineIcon />}>{`${strings.viewDocument} (${strings.inBrowser})`}</SmallPrimaryTextButton>
							)}
							<SmallPrimaryTextButton
								onClick={() => download(scan.id, 'Native' as DocumentExportFormat)}
								startIcon={<FileDownloadOutlineIcon />}>{`${strings.downloadDocument} (${scan.extension})`}</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={onScan}
								startIcon={<ScannerIcon />}>
								{strings.scanOrUploadDocument}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={onDelete}
								color='secondary'
								startIcon={<DeleteOutlineIcon />}>
								{strings.delete}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
				{showHistory && (
					<>
						<GridDividerWithCaption caption={strings.history} />
						<PrescriptionScanHistory
							id={scan.id}
							style={{ marginLeft: '-144px' }}
							history={scan.historyOrderedDesc}
							onDeleteHistory={onDeleteHistory}
						/>
					</>
				)}
			</DialogContentGrid>
		</Dialog>
	);
};
