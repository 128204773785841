import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	customerAccountsQuery_concurrencyToken,
	customerAccountsQuery_query,
	ICustomerAccount,
	ICustomerAccountQueryMetadata,
	ICustomerAccountsQueryParams,
	IQueryResultWithMetadata,
} from 'gen/ApiClient';
import React from 'react';
import { customerAccountsQueryParamsLsKey } from '../../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: ICustomerAccountsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	hasOutstandingBalance: undefined,
	outstandingBalanceLessThan: undefined,
	outstandingBalanceMoreThan: undefined,
	accountTypes: [],
	hasOutstandingBalanceNotOnSettlementProposals: undefined,
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResultWithMetadata<ICustomerAccount, ICustomerAccountQueryMetadata>, ICustomerAccountsQueryParams>
			defaultParams={DefaultParams}
			queryF={customerAccountsQuery_query}
			concurrencyF={customerAccountsQuery_concurrencyToken}
			localStorageKey={customerAccountsQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
