import { Menu, MenuItem } from '@material-ui/core';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import React, { useContext } from 'react';
import { AgendaTimeScales } from '../AgendaTimeScale';
import { AgendaContext } from '../context/AgendaContext';
import { AgendaViewContext } from '../context/AgendaViewContext';

export const SelectTimeScale = () => {
	const [anchor, open, close] = useAnchorElement();
	const { timeScale, setTimeScale } = useContext(AgendaContext);
	const { agendaTimeScaleRecord } = useContext(AgendaViewContext);

	return (
		<>
			<div
				style={{ cursor: 'pointer' }}
				onClick={open}>
				{agendaTimeScaleRecord[timeScale]}
			</div>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={close}
				keepMounted>
				{AgendaTimeScales.map(t => (
					<MenuItem
						key={t.toString()}
						onClick={() => {
							setTimeScale(t);
							close();
						}}
						value={t}>
						{agendaTimeScaleRecord[t]}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
