import { FunctionalClaim } from 'gen/ApiClient';
import { calculateUserHasFunctionalClaim } from './calculateUserHasFunctionalClaim';
import { useLoggedInUser } from './useLoggedInUser';

export function useLoggedInUserIsSuperUserOrHasFunctionClaim(functionalClaim: FunctionalClaim): boolean {
	const user = useLoggedInUser();
	if (user.isSuperUser) {
		return true;
	} else {
		return calculateUserHasFunctionalClaim(user, functionalClaim);
	}
}
