import { DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { DialogX } from 'framework/dialogs/DialogX';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormIntegerField } from 'framework/forms/FormIntegerField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { articlesInquiriesCommand_assignLineFromStock, IArticlesInquiryLineDto, IAssignArticlesInquiryLineFromStockRequest } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IAssignArticlesInquiryLineFromStockRequest>({
			articlesInquiryId: yup.string().required(),
			articlesInquiryLineId: yup.string().required(),
			inventoryItemId: yup.string(),
			quantity: yup.number().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	line: IArticlesInquiryLineDto;
	articlesInquiryId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
	previous?: VoidFunction;
}

export const AssignArticlesInquiryLineQuantityFromStockDialog = ({ confirm, cancel, previous, line, articlesInquiryId, ...rest }: IProps) => {
	const strings = useLocalization();
	const [patch, isSubmitting] = useFormSubmit(articlesInquiriesCommand_assignLineFromStock);

	const handleSubmit = async (values: IAssignArticlesInquiryLineFromStockRequest, helpers: FormikHelpers<IAssignArticlesInquiryLineFromStockRequest>) => {
		const r = await patch(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	return (
		<Formik<IAssignArticlesInquiryLineFromStockRequest>
			validateOnMount
			initialValues={{
				articlesInquiryId: articlesInquiryId,
				articlesInquiryLineId: line.id,
				inventoryItemId: undefined,
				quantity: line.quantityToAssign,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<DialogX {...rest}>
					<DialogTitle>{strings.assignFromStock}</DialogTitle>
					<DialogContent
						dividers
						className='df-col fg1'>
						<FormIntegerField<IAssignArticlesInquiryLineFromStockRequest>
							name='quantity'
							label={strings.quantity}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<IAssignArticlesInquiryLineFromStockRequest>
						cancel={cancel}
						submitText={`Wijs toe`}
						isSubmitting={isSubmitting}
						previous={previous}
					/>
				</DialogX>
			</Form>
		</Formik>
	);
};
