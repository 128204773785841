import { Card } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DefaultCardContent } from 'framework/components/DefaultCardContent';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { NoAccessToThisFunctionLabelledProperty } from 'framework/components/labelledProperty/NoAccessToThisFunctionLabelledProperty';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IStockInfo, stockEntriesCommand_forceReculculation } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PerLocationDialog } from '../../stock/PerLocationDialog';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { CalculatorIcon } from 'framework/components/icons/CalculatorIcon';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';

interface IProps {
	display: string;
	item: IStockInfo;
	isDirty: boolean;
	style?: React.CSSProperties;
	className?: string;
	reload: VoidFunction;
}

export const StockCard = ({ display, item, isDirty, style, className, reload }: IProps) => {
	const strings = useLocalization();
	const { open, cancel } = useDialogsContext();
	const hasVeppClaim = useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim();
	const [recalc, isRecalculating] = useFormSubmit(stockEntriesCommand_forceReculculation);
	const notify = useSnackbarNotify();

	const onView = () => {
		open(
			<PerLocationDialog
				open
				display={display}
				item={item}
				close={cancel}
			/>
		);
	};

	const onRecalculateStock = async () => {
		const r = await recalc();
		if (r.result.hasError) {
			notify(r.result.error);
		} else {
			reload();
		}
	};

	return (
		<Card
			style={style}
			className={`df-col ${className}`}>
			<DefaultCardContent>
				<CardTitleTypography title={strings.stockInfo} />
				{isDirty && (
					<ErrorLabelledProperty
						error={strings.stockInfoNotUpToDateClickToRecalculate}
						edit={isRecalculating ? undefined : onRecalculateStock}
						editIcon={<CalculatorIcon />}
					/>
				)}
				{isDirty === false && (
					<>
						<LabelledProperty
							label={strings.physicalQuantity}
							property={item.physicalQuantity.toString()}
						/>
						<LabelledProperty
							label={`# ${strings.inOrder}`}
							property={item.countInBackOrder.toString()}
						/>
						<LabelledProperty
							label={`# ${strings.inRepair}`}
							property={item.countInRepair.toString()}
						/>
						<LabelledProperty
							label={`# ${strings.inTest}`}
							property={item.countInTestOrLoan.toString()}
						/>
						<SmallPrimaryTextButton
							startIcon={<HelpOutlineIcon />}
							onClick={onView}>
							{strings.showStockPerLocation}
						</SmallPrimaryTextButton>
						<CardTitleTypography
							title={strings.stockValue}
							withPaddingTop
						/>
						<LabelledProperty
							label={`# ${strings.statusPurchased}`}
							property={item.countPurchased.toString()}
						/>
						<LabelledProperty
							label={`# ${strings.statusSold}`}
							property={item.countSold.toString()}
						/>
						<LabelledProperty
							label={strings.financialQuantity}
							property={item.financialQuantity.toString()}
						/>
						<NoAccessToThisFunctionLabelledProperty
							hasAccess={hasVeppClaim}
							label={strings.stockValue}
							property={formatCurrency(item.stockValue)}
						/>
					</>
				)}
			</DefaultCardContent>
		</Card>
	);
};
