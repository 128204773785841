import { FormikContextType, useFormikContext } from 'formik';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { FormAutocompleteFieldWithAdd } from 'framework/forms/FormAutocompleteFieldWithAdd';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { ISupplierSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SuppliersCacheContext } from '../SuppliersCacheContext';
import { RegisterSupplierForm } from './RegisterSupplierForm';

interface IProps<TModel> extends IFormProps<TModel> {
	selectIfSingle?: boolean;
}

export const FormSelectOrCreateSupplierField = <TModel extends unknown>({ name, selectIfSingle, ...rest }: IProps<TModel>) => {
	const props = useFormikContext<TModel>();
	const [data, load] = usePreloadCacheContext(SuppliersCacheContext);
	const { open, confirm, cancel } = useDialogsContext();

	if (data === undefined) {
		return <div></div>;
	}

	const onConfirm = async (id: string) => {
		await load();
		setFormValue<TModel>(name, id, props);
		confirm();
	};

	const onNew = (name: string) => {
		open(
			<RegisterSupplierForm
				open
				confirm={onConfirm}
				cancel={cancel}
				name={name}
			/>
		);
	};

	return (
		<Inner
			data={data}
			props={props}
			name={name}
			onNew={onNew}
			selectIfSingle={selectIfSingle ?? true}
			{...rest}
		/>
	);
};

interface IInnerProps<TModel> extends IFormProps<TModel> {
	props: FormikContextType<TModel>;
	data: ISupplierSummary[];
	onNew: (name: string) => void;
	selectIfSingle: boolean;
}

const Inner = <TModel extends unknown>({ name, label, props, data, onNew, selectIfSingle, ...rest }: IInnerProps<TModel>) => {
	const strings = useLocalization();

	useEffect(() => {
		if (selectIfSingle && data.length === 1 && (props.values[name] === undefined || props.values[name] === null || (props.values[name] as any) === '')) {
			setFormValue<TModel>(name, data[0].id, props);
		}
		// only on mount!
		// eslint-disable-next-line
	}, []);

	return (
		<FormAutocompleteFieldWithAdd<TModel, ISupplierSummary>
			name={name}
			label={label}
			options={data}
			onCreateNew={onNew}
			renderCreate={str => strings.createStrCommaWhat(str, strings.newSupplier)}
			{...rest}
		/>
	);
};
