import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import {
	IPatchProductCatalogItemBebatUnitPriceRequest,
	IProductCatalogItem,
	IProductCatalogItemSummary,
	productCatalogCommand_patchBebatUnitPrice,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormBebatField } from 'shared/forms/FormBebatField';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchProductCatalogItemBebatUnitPriceRequest>({
		bebatUnitPriceInVat: yup.number(),
		productCatalogItemId: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	item: IProductCatalogItem | IProductCatalogItemSummary;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const BebatForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [link, isSubmitting] = useFormSubmit(productCatalogCommand_patchBebatUnitPrice);
	const strings = useLocalization();

	const handleSubmit = async (values: IPatchProductCatalogItemBebatUnitPriceRequest, helpers: FormikHelpers<IPatchProductCatalogItemBebatUnitPriceRequest>) => {
		const r = await link(values);
		if (handleValidateResponse(r, helpers, 'bebatUnitPriceInVat')) {
			confirm();
		}
	};

	return (
		<Formik<IPatchProductCatalogItemBebatUnitPriceRequest>
			validateOnMount
			initialValues={{
				bebatUnitPriceInVat: item.bebatUnitPriceInVat ?? 0,
				productCatalogItemId: item.id,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>Bebat</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormBebatField<IPatchProductCatalogItemBebatUnitPriceRequest>
							name='bebatUnitPriceInVat'
							label={`Bebat ${strings.inVatShort}`}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.update}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
