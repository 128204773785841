import { ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import React, { useContext } from 'react';
import { FilterOutlineIcon } from 'framework/components/icons/FilterOutlineIcon';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { IQueryResult } from 'framework/types/IQueryResult';
import {
	cashRegisterEntriesQuery_export,
	cashRegisterEntriesQuery_exportFormatted,
	CashRegisterEntryExportProp,
	CashRegisterEntryExportProps,
	ICashRegisterEntriesFilterParams,
	ICashRegisterEntriesQueryParams,
	ICashRegisterEntry,
} from 'gen/ApiClient';
import { useDownloadWithLoader } from 'gen/useDownloadWithLoader';
import { useLocalization } from 'localization/useLocalization';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { ViewContext } from './context/ViewContext';

interface IProps {
	params: ICashRegisterEntriesQueryParams;
	queryResult: IQueryResult<ICashRegisterEntry>;
}

export const CashRegisterEntriesExportButtonMenuAndDialog = ({ params, queryResult }: IProps) => {
	const strings = useLocalization();
	const { exportRecord } = useContext(ViewContext);
	const anchor = useAnchorElement();
	const exportFormatted = useDownloadWithLoader(cashRegisterEntriesQuery_exportFormatted);

	const onExportFormatted = async () => {
		anchor[2]();
		await exportFormatted(params);
	};

	return (
		<ExportButtonMenuAndDialog<ICashRegisterEntriesFilterParams, CashRegisterEntryExportProp>
			params={params}
			filteredCount={queryResult.filteredCount}
			unfilteredCount={queryResult.unfilteredCount}
			allProps={CashRegisterEntryExportProps}
			downloadF={cashRegisterEntriesQuery_export}
			fileName={strings.cashTransactions}
			view='cashRegisterEntries'
			record={exportRecord}
			anchor={anchor}>
			<MenuItem onClick={onExportFormatted}>
				<ListItemIcon>
					<FilterOutlineIcon fontSize='small' />
					<FormatListNumberedIcon fontSize='small' />
				</ListItemIcon>
				<Typography variant='inherit'>{strings.exportJournalFormatted}</Typography>
			</MenuItem>
		</ExportButtonMenuAndDialog>
	);
};
