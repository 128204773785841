import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		amountPaid: strings.amountPaid,
		created: strings.createdAt,
		outstandingBalance: strings.outstandingBalance,
		salesReference: strings.reference,
		value: strings.amountToPay,
		salesTotalPriceInVat: strings.amountFromSale,
		salesRecipientName: strings.withRespectToShort,
		settlementProposalReference: strings.settlementProposal,
		referencePatientIdentifier: strings.patient,
	};
};
