import React from 'react';
import { IUserInfo } from 'gen/ApiClient';

interface AuthenticationProps {
	isAuth: boolean;
	user: IUserInfo | undefined;
	login: (user: IUserInfo) => void;
	switchLocation: (locationId: string) => void;
	logout: VoidFunction;
	onRedirect: VoidFunction;
	locationId: string | undefined;
}

export const AuthContext = React.createContext<AuthenticationProps>(undefined as any);
