import { Typography, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { IAnnotation } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { SingleAnnotationAttachment } from './SingleAnnotationAttachment';

interface IProps {
	annotation: IAnnotation;
	alsoIncludeDeleted: boolean;
	reload: VoidFunction;
}

export const AnnotationAttachmentsComponent = ({ annotation, reload, alsoIncludeDeleted }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const isShowAttachments = useMemo(
		() => (alsoIncludeDeleted === true && annotation.hasAttachment) || (alsoIncludeDeleted === false && annotation.hasNonDeletedAttachments),
		[alsoIncludeDeleted, annotation]
	);
	const attachments = useMemo(
		() => (alsoIncludeDeleted ? annotation.attachments : annotation.attachments.filter(t => t.isDeleted === false)),
		[annotation, alsoIncludeDeleted]
	);

	if (isShowAttachments === false) {
		return <div></div>;
	}

	return (
		<>
			<Typography
				variant='caption'
				style={{
					marginTop: theme.spacing(2),
					textDecoration: annotation.isDeleted ? 'line-through' : 'inherit',
				}}>
				{strings.attachments}
			</Typography>
			{attachments.map(attachment => (
				<SingleAnnotationAttachment
					key={attachment.id}
					annotation={annotation}
					attachment={attachment}
					reload={reload}
				/>
			))}
		</>
	);
};
