import { Chip } from '@material-ui/core';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';

interface IProps {
	values: string[] | undefined | null;
}

export const ArticlePropertyContent = ({ values }: IProps) => {
	const strings = useLocalization();
	const xValues = useMemo(() => values ?? [], [values]);
	const hasMultiple = useMemo(() => xValues.length > 1, [xValues]);
	const singleValueOrEmpty = useMemo(() => (xValues.length === 1 ? xValues[0] : undefined), [xValues]);

	return (
		<>
			{hasMultiple === false && singleValueOrEmpty === undefined && <em>{strings.notApplicableAbbreviation}</em>}
			{hasMultiple === false && singleValueOrEmpty !== undefined && <span>{singleValueOrEmpty}</span>}
			{hasMultiple === true &&
				xValues.map(t => (
					<Chip
						key={t}
						size='small'
						label={t}
						style={{ marginBottom: 3, marginRight: 3 }}
					/>
				))}
		</>
	);
};
