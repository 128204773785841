import { DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponseWithLines } from 'framework/forms/utils/handleFormResponseWithLines';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { mapWithIndexFilterIsSelected } from 'framework/utils/array/mapWithIndexFilterIsSelected';
import { IRegisterLoanRequest, loansCommand_register, loansQuery_nextReference, loansQuery_settings, TrialTimeUnits } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { useCurrentLocation } from '../../../../settings/locations/useCurrentLocation';
import { SearchProvider } from '../context/SearchProvider';
import { ILine } from './ILine';
import { SearchAndSelectProductsForLoan } from './SearchAndSelectProductsForLoan';
import { loanLineToRegisterLoanLineRequest } from './utils/loanLineToRegisterLoanLineRequest';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterLoanRequest>({
			reference: yup.string().required(strings.formRequired(strings.reference)),
			isAutoSelectReference: yup.boolean().defined(),
			patientId: yup.string().required(),
			lines: yup.mixed(),
			stopDate: yup.date().required(strings.formRequired(strings.stopDate)),
			startDate: yup.date().required(strings.formRequired(strings.startDate)),
		})
		.defined();
};

const EmptyValues: IRegisterLoanRequest = {
	isAutoSelectReference: true,
	reference: '',
	patientId: undefined as any,
	lines: [],
	stopDate: undefined as any,
	startDate: undefined as any,
};

const stepsRecord: Record<number, (keyof IRegisterLoanRequest)[]> = {
	0: ['reference', 'isAutoSelectReference'],
	1: ['stopDate', 'startDate'],
	2: ['lines'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	patientId: string;
}

export const RegisterLoanForm = ({ confirm, cancel, patientId, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(loansCommand_register);
	const [step, setStep] = useState<number>(0);
	const [lines, setLines] = useState<ILine[]>([]);
	const now = useNow();

	const handleSubmit = async (values: IRegisterLoanRequest, helpers: FormikHelpers<IRegisterLoanRequest>) => {
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await create({ ...values, lines: mapWithIndexFilterIsSelected(lines).map(loanLineToRegisterLoanLineRequest) });
			if (handleFormResponseWithLines(r, helpers, stepsRecord, setStep, 'lines', lines, setLines)) {
				notify(strings.loanStarted);
				confirm(r.result.objectId);
			}
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterLoanRequest>
			validateOnMount
			initialValues={{ ...EmptyValues, patientId: patientId, startDate: now }}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider>
					<InnerDialog
						step={step}
						setStep={setStep}
						lines={lines}
						setLines={setLines}
						isSubmitting={isSubmitting}
						cancel={cancel}
						{...rest}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	lines: ILine[];
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
}

const InnerDialog = ({ step, setStep, lines, setLines, cancel, isSubmitting, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IRegisterLoanRequest>();
	const strings = useLocalization();
	useNextReferenceInterceptor(loansQuery_nextReference, props);
	const [settings] = useApiEffect(loansQuery_settings);
	const location = useCurrentLocation();

	if (location === undefined || settings === undefined) {
		return <div></div>;
	}

	return (
		<FullScreenDialogWithStepper
			{...rest}
			title={strings.startLoan}
			step={step}
			labels={[strings.referenceShort, strings.date, strings.items]}
			fullScreenStep={2}
			maxWidth='lg'>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IRegisterLoanRequest>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IRegisterLoanRequest>
							name='reference'
							label={strings.reference}
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormDatePicker<IRegisterLoanRequest>
							name='startDate'
							label={strings.startDate}
							disableFuture
						/>
						<FormDatePickerWithUnit<IRegisterLoanRequest>
							units={TrialTimeUnits}
							defaultUnit='Weeks'
							name='stopDate'
							label={strings.endDate}
							defaultValue={settings.defaultDuration}
						/>
					</>
				)}
				{step === 2 && (
					<SearchAndSelectProductsForLoan
						lines={lines}
						setLines={setLines}
						location={location}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</FullScreenDialogWithStepper>
	);
};
