import { useTheme } from '@material-ui/core';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';

interface IProps {
	onClick: VoidFunction;
	style?: React.CSSProperties;
}

export const DeleteColorOnHoverIconButtonWithConfirmation = ({ onClick, style }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<>
			{isOpen === true && (
				<div
					className='df-row-ac gap-4'
					style={style}>
					<TextLinkButton
						style={{ border: `1px solid ${theme.palette.secondary.main}`, borderRadius: 6, padding: 4, marginLeft: 4 }}
						onClick={onClick}
						color='secondary'
						hoverColor='secondary'
						startIcon={<DeleteOutlineOutlinedIcon />}>
						{strings.confirm}
					</TextLinkButton>
					<ColorOnHoverIconButton
						onClick={() => setIsOpen(false)}
						size='small'
						edge='start'
						color='primary'>
						<ClearOutlinedIcon fontSize='small' />
					</ColorOnHoverIconButton>
				</div>
			)}
			{isOpen === false && (
				<ColorOnHoverIconButton
					onClick={() => setIsOpen(true)}
					style={style}
					size='small'
					edge='start'
					color='secondary'>
					<DeleteOutlineOutlinedIcon fontSize='small' />
				</ColorOnHoverIconButton>
			)}
		</>
	);
};
