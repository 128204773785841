import { Button, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useTrigger } from 'framework/hooks/useTrigger';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { EntPhysicianExportProp, EntPhysicianExportProps, IEntPhysicianFilterParams, entPhysiciansQuery_export } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TextLinkExportButtonMenuAndDialog } from 'shared/export/TextLinkExportButtonMenuAndDialog';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { EntPhysicianDetailsCard } from './EntPhysicianDetailsCard';
import { EntPhysicianListItemCardX } from './EntPhysicianListItemCardX';
import { EntPhysiciansFilterDataContext } from './EntPhysiciansFilterDataContext';
import { EntPhysiciansListFilterCard } from './EntPhysiciansListFilterCard';
import { EntPhysiciansQueryContext } from './EntPhysiciansQueryContext';
import { createEntPhysiciansExportRecord } from './createEntPhysiciansExportRecord';
import { RegisterEntPhysicianForm } from './forms/RegisterEntPhysicianForm';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

export const EntPhysiciansPage = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(EntPhysiciansQueryContext);
	const [searchData, reloadSearchData] = useCacheContext(EntPhysiciansFilterDataContext);
	const strings = useLocalization();
	const [trigger, hitTrigger] = useTrigger();
	const { open, confirm, cancel } = useDialogsContext(hitTrigger);
	const exportRecord = useMemo(() => createEntPhysiciansExportRecord(strings), [strings]);
	const [selectedId, setSelectedId] = useState<string>();
	const expandedEntPhysician = useMemo(() => queryResult?.values.find(t => t.id === selectedId), [queryResult, selectedId]);

	useLazyEffect(() => {
		reload();
		reloadSearchData();
	}, [trigger]);

	const onCreate = () => {
		open(
			<RegisterEntPhysicianForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	if (queryResult === undefined || searchData === undefined) {
		return <></>;
	}

	return (
		<RestrictedPageX
			pageClaim='EntPhysicians'
			maxWidth={1280}
			stickyHeader={
				<div
					className='df-col gap-8 fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<PageBreadcrumbTitle title={strings.contacts} />
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={strings.entPhysicians} />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageReloadButtonX reload={hitTrigger} />
						</PageXHeaderActions>
					</div>
					<SearchbarX
						placeholder={strings.searchVerb}
						value={params.searchString}
						onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
						style={{ width: 500 }}
					/>
				</div>
			}
			stickySidebar={
				<div
					className='df-col gap-16'
					style={{ padding: 16, paddingRight: 8 }}>
					<Button
						variant='outlined'
						color='primary'
						startIcon={<AddIcon />}
						onClick={onCreate}
						style={{ borderRadius: 12 }}>
						{strings.new}
					</Button>
					<EntPhysiciansListFilterCard
						params={params}
						setParams={setParams}
						searchData={searchData}
					/>
					<div className='fg1'></div>
					<Divider />
					<TextLinkExportButtonMenuAndDialog<IEntPhysicianFilterParams, EntPhysicianExportProp>
						params={params}
						filteredCount={queryResult.filteredCount}
						unfilteredCount={queryResult.unfilteredCount}
						downloadF={entPhysiciansQuery_export}
						fileName={strings.entPhysicians}
						record={exportRecord}
						allProps={EntPhysicianExportProps}
						view='entPhysicians'
					/>
				</div>
			}>
			<div
				className='df-row gap-16 h100'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				<div
					className='df-col'
					style={{ gap: 8 }}>
					{queryResult.values.map(t => (
						<EntPhysicianListItemCardX
							key={t.id}
							item={t}
							isSelected={selectedId === t.id}
							toggle={() => setSelectedId(selectedId === t.id ? undefined : t.id)}
						/>
					))}
					<QueryResultPaginationX
						queryResult={queryResult}
						setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
						style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
					/>
				</div>
				{expandedEntPhysician !== undefined && (
					<>
						<Divider orientation='vertical' />
						<EntPhysicianDetailsCard
							item={expandedEntPhysician}
							className='fg1 h-fc'
							reload={hitTrigger}
						/>
					</>
				)}
			</div>
		</RestrictedPageX>
	);
};
