import { Paper, PaperProps } from '@material-ui/core';
import React from 'react';

export const PaperOutlined = (props: PaperProps) => {
	return (
		<Paper
			{...props}
			variant='outlined'
		/>
	);
};
