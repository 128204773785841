import { DialogProps } from '@material-ui/core';
import { addHours, roundToNearestMinutes } from 'date-fns';
import { IObjectWithIdAndIdentifier } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { getNow } from 'shared/utils/getNow';
import { CreateCalendarEventForm } from './CreateCalendarEventForm';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	patient?: IObjectWithIdAndIdentifier<string>;
	eventType?: IObjectWithIdAndIdentifier<string>;
	roomGroupKey?: string;
	userGroupKey?: string;
}

export const CreateDefaultCalendarEventForm = ({ ...props }: IProps) => {
	const startDate = useMemo(() => roundToNearestMinutes(getNow(), { nearestTo: 15 }), []);
	const endDate = useMemo(() => addHours(startDate, 1), [startDate]);

	return (
		<CreateCalendarEventForm
			{...props}
			startDate={startDate}
			endDate={endDate}
			isAllDay={false}
		/>
	);
};
