import { Dialog, DialogProps, Grid, Typography, useTheme } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import React from 'react';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IEquipmentDeliveryLine, rfiasQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { UpdateHandoverLineForm } from '../../forms/handover/UpdateHandoverLineForm';
import { EquipmentDeliveryLinesTable } from './EquipmentDeliveryLinesTable';
import { FeesTable } from './FeesTable';

interface IProps extends DialogProps {
	close: VoidFunction;
	rfiaId: string;
}

export const EquipmentDeliveryStepDialog = ({ rfiaId, close, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [detail, reload] = useApiEffect(rfiasQuery_single, rfiaId);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);

	if (detail === undefined) {
		return <div></div>;
	}

	const onEdit = (line: IEquipmentDeliveryLine) => {
		open(
			<UpdateHandoverLineForm
				open
				rfiaId={rfiaId}
				line={line}
				confirm={confirm}
				cancel={cancel}
				handoverDate={detail.rfia.handoverDate!}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			maxWidth='lg'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<HistoryIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{strings.handoverOfEquipment}
					</Typography>
					<LabelledProperty
						label={strings.deliveryDate}
						property={formatDate(detail.rfia.equipmentDeliveryStep.handoverDate)}
						withoutMargin
					/>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.summary} />
				<PropertyInGrid
					label={strings.totalInVat}
					value={formatCurrency(detail.rfia.equipmentDeliveryStep.totalPriceInVat)}
				/>
				<PropertyInGrid
					label={strings.totalOfContributions}
					value={formatCurrency(detail.rfia.equipmentDeliveryStep.totalContributions)}
				/>
				<PropertyInGrid
					label={strings.priceForPatient}
					value={formatCurrency(detail.rfia.equipmentDeliveryStep.patientPriceInVat)}
				/>
				<GridDividerWithCaption caption={strings.items} />
				<Grid
					item
					xs={12}>
					<EquipmentDeliveryLinesTable
						lines={detail.rfia.equipmentDeliveryStep.lines}
						onEdit={onEdit}
					/>
				</Grid>
				<GridDividerWithCaption caption={strings.contributions} />
				<Grid
					item
					xs={12}>
					<FeesTable fees={detail.rfia.equipmentDeliveryStep.fees} />
				</Grid>
			</DialogContentGrid>
		</Dialog>
	);
};
