import { MenuItem, Select, Typography } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { useCallback } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { ILabelCaptionValue } from '../components/ILabelCaptionValue';
import { nameOf } from '../utils/nameOf';
import { FormFilledControl } from './common/FormFilledControl';
import { IFormProps } from './common/IFormProps';

interface IProps<TModel, TValue> extends IFormProps<TModel> {
	options: ILabelCaptionValue<TValue>[];
	withNone?: boolean;
	getKey: (val: TValue) => string;
	renderWithCaption?: boolean;
}

export const FormSelectFieldWithCaption = <TModel extends unknown, TValue extends unknown>({
	options,
	withNone = false,
	getKey,
	renderWithCaption = false,
	...props
}: IProps<TModel, TValue>) => {
	const [field, meta] = useField<TValue>(nameOf(props.name));
	const strings = useLocalization();

	const renderSelectedValue = useCallback(
		(value: TValue | undefined) => {
			if (value === undefined) {
				return '';
			}
			return options.find(t => t.value === value)?.label ?? '';
		},
		[options]
	);

	return (
		<FormFilledControl
			meta={meta}
			{...props}>
			<Select
				{...field}
				renderValue={renderWithCaption ? undefined : t => renderSelectedValue(t as TValue | undefined)}>
				{withNone && (
					<MenuItem value={undefined}>
						<em>{strings.none.toLowerCase()}</em>
					</MenuItem>
				)}
				{options.map(t => (
					<MenuItem
						key={getKey(t.value)}
						value={t.value as any}>
						<div className='df-col'>
							<div>{t.label}</div>
							<Typography
								variant='caption'
								style={{ whiteSpace: 'pre-line' }}
								color='textSecondary'>
								{t.caption!}
							</Typography>
						</div>
					</MenuItem>
				))}
			</Select>
		</FormFilledControl>
	);
};
