import { useTheme } from '@material-ui/core';
import React from 'react';

interface IProps {
	icon?: JSX.Element;
	field: JSX.Element;
	alignItems?: 'center' | 'baseline';
	style?: React.CSSProperties;
}

// TODO move to common
export const FormFieldWithIcon = ({ icon, field, alignItems = 'center', style }: IProps) => {
	const theme = useTheme();
	return (
		<div
			className={alignItems === 'center' ? 'df-row-ac' : 'df-row-ab'}
			style={style}>
			<div style={{ marginRight: theme.spacing(1), color: theme.palette.text.secondary, width: '30px' }}>{icon && icon}</div>
			{field}
		</div>
	);
};
