import React from 'react';
import { IDocumentX } from './IDocumentX';
import { useDialogsContext } from '../useDialogsContext';
import { ReplaceDocumentDialog } from './ReplaceDocumentDialog';

export function useReplaceDocument(reload?: VoidFunction): (document: IDocumentX) => void {
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onOpen = (document: IDocumentX) => {
		open(
			<ReplaceDocumentDialog
				open
				document={document}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return onOpen;
}
