import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { SortableTable } from 'framework/table/SortableTable';
import { IProductCatalogItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { IPurchaseFormLine } from '../../register/IPurchaseFormLine';
import { createHeaders } from './createHeaders';
import { filterLines } from './filterLines';
import { IPurchaseFormLineWithSelection } from './IPurchaseFormLineWithSelection';

interface IProps extends DialogProps {
	assurance: IProductCatalogItem;
	confirm: (line: IPurchaseFormLine[]) => void;
	cancel: VoidFunction;
	purchaseLines: IPurchaseFormLine[];
}

export const SelectLinesFromThisPurchaseToAssureDialog = ({ assurance, confirm, cancel, purchaseLines, ...rest }: IProps) => {
	const strings = useLocalization();
	const fSalesLines = useMemo(() => filterLines(assurance, purchaseLines), [assurance, purchaseLines]);
	const [lines, setLines] = useState<IPurchaseFormLineWithSelection[]>([]);
	const headers = useMemo(() => createHeaders(lines, setLines, strings), [lines, setLines, strings]);

	useEffect(() => {
		setLines(fSalesLines.map(t => ({ ...t, isSelected: false })));
	}, [fSalesLines]);

	return (
		<Dialog
			{...rest}
			PaperProps={{ style: { minWidth: 400 } }}
			maxWidth='xl'>
			<DialogTitle>{strings.selectWhat(strings.lines)}</DialogTitle>
			<DialogContent
				dividers
				style={{ padding: 0 }}>
				{lines.length > 0 && (
					<SortableTable<IPurchaseFormLineWithSelection>
						padding={8}
						headers={headers}
						getKey={t => t.id}
						values={lines}
						defaultSortParams={{ property: 'description', direction: 'desc' }}
					/>
				)}
				{lines.length === 0 && <NoRecordsFound withPadding>{strings.noPreviousSoldLines}</NoRecordsFound>}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<LoaderButton
					isLoading={false}
					onClick={() => confirm(lines.filter(t => t.isSelected))}
					disabled={lines.filter(t => t.isSelected).length === 0}
					color='primary'>{`${strings.select} (${lines.filter(t => t.isSelected).length})`}</LoaderButton>
			</DialogActions>
		</Dialog>
	);

	// const strings = useLocalization();
	// const linesToSelectFrom = useMemo(() => filterLines(assurance, lines), [assurance, lines]);

	// return (
	//     <Dialog
	//         {...rest}
	//         scroll="paper"
	//         fullWidth>
	//         <IconButton
	//             onClick={cancel}
	//             style={{ position: 'absolute', right: '10px', top: '10px' }}>
	//             <CloseIcon />
	//         </IconButton>
	//         <DialogTitle>
	//             <div>{strings.selectWhat(strings.line)}</div>
	//         </DialogTitle>
	//         <DialogContent
	//             dividers>
	//             <List dense>
	//                 {linesToSelectFrom.map(t =>
	//                     <React.Fragment>
	//                         <ListItem
	//                             key={t.id}
	//                             disableGutters>
	//                             <ListItemText primary={t.description} />
	//                             <ListItemSecondaryAction style={{ right: 0 }}>
	//                                 <SmallPrimaryTextButton
	//                                     onClick={() => confirm(t)}>
	//                                     {strings.select}
	//                                 </SmallPrimaryTextButton>
	//                             </ListItemSecondaryAction>
	//                         </ListItem>
	//                         {isLast(t, linesToSelectFrom) === false &&  <DividerWithMargin />}
	//                     </React.Fragment>
	//                 )}
	//                 {linesToSelectFrom.length === 0 &&
	//                     <NoRecordsFound>Geen lijnen te selecteren, selecteer eerst de te verzekeren producten</NoRecordsFound>
	//                 }
	//             </List>
	//         </DialogContent>
	//     </Dialog>
	// );
};
