import React from 'react';
import { Route, Switch } from 'react-router';
import * as routes from '../routes';
import { DeliveryDetailPage } from './deliveries/detail/DeliveryDetailPage';
import { ReturnDetailPage } from './returns/detail/ReturnDetailPage';
import { StockMovementDetailPage } from './stockMovements/detail/StockMovementDetailPage';
import { StockTransactionsPage } from './StockTransactionsPage';

export const StockTransactionsSwitch = () => {
	return (
		<Switch>
			<Route
				exact
				path={routes.DeliveryDetailRoute}
				component={DeliveryDetailPage}
			/>
			<Route
				exact
				path={routes.ReturnDetailRoute}
				component={ReturnDetailPage}
			/>
			<Route
				exact
				path={routes.StockMovementDetailRoute}
				component={StockMovementDetailPage}
			/>
			<Route component={StockTransactionsPage} />
		</Switch>
	);
};
