import { Application, downloads_application } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';

export const useDownloadApplicationInstaller = (application: Application): [() => Promise<void>, boolean] => {
	const [download, isLoading] = useDownload(downloads_application);

	const onDownload = async () => {
		await download(application);
	};

	return [onDownload, isLoading];
};
