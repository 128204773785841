import React from 'react';
import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import { IStrings } from 'localization/IStrings';
import { IPurchaseFormLineWithSelection } from './IPurchaseFormLineWithSelection';

export const createHeaders = (
	lines: IPurchaseFormLineWithSelection[],
	setLines: React.Dispatch<React.SetStateAction<IPurchaseFormLineWithSelection[]>>,
	strings: IStrings
): IHeader<IPurchaseFormLineWithSelection>[] => {
	return [
		createSelectionHeader<IPurchaseFormLineWithSelection>(lines, setLines),
		createSortableHeader<IPurchaseFormLineWithSelection>(strings.description, 'description', t => t.description),
	];
};
