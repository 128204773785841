import { Dialog, DialogProps, Divider, Typography, useTheme } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import React, { useCallback } from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DetailDialogGridComponent } from 'framework/components/detailDialog/DetailDialogGridComponent';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { FileDocumentEditOutlineIcon } from 'framework/components/icons/FileDocumentEditOutlineIcon';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useApiEffectWithDefer } from 'framework/hooks/useApiEffectWithDefer';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { documentsQuery_single, rfiaDocumentsCommand_createTrialReport, trialReportsQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DocumentHistory } from 'documents/DocumentHistory';
import { DocumentWithRebuildActions } from 'documents/DocumentWithRebuildActions';
import { SelectTemplateFromPredefinedTypeForm } from 'documents/SelectTemplateFromPredefinedTypeForm';
import { StatusFibers } from '../detail/prescriptionScan/StatusFibers';
import { AverageWearingHoursForm } from './AverageWearingHoursForm';
import { CltTestDialog } from './CltTestDialog';
import { CltTestForm } from './CltTestForm';
import { MonoToneThresholdAudiogramTestForm } from './MonoToneThresholdAudiogramTestForm';
import { OrientationTestDialog } from './OrientationTestDialog';
import { OrientationTestForm } from './OrientationTestForm';
import { SpeechImagesDialog } from './SpeechImages/SpeechImagesDialog';
import { SpeechImagesForm } from './SpeechImages/SpeechImagesForm';
import { SpeechInNoiseTestDialog } from './SpeechInNoiseTestDialog';
import { SpeechInNoiseTestForm } from './SpeechInNoiseTestForm';
import { SpeechTestDialog } from './SpeechTestDialog';
import { SpeechTestForm } from './SpeechTestForm';
import { StereoToneThresholdAudiogramTestForm } from './StereoToneThresholdAudiogramTestForm';
import { ToneThresholdAudiogramTestDialog } from './ToneThresholdAudiogramTestDialog';
import { TrialReportButtonGroup } from './TrialReportButtonGroup';

interface IProps extends DialogProps {
	close: VoidFunction;
	trialId: string;
	patientId: string;
	rfiaId: string;
}

export const TrialReportDialog = ({ rfiaId, patientId, trialId, close, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [item, reloadTrial] = useApiEffect(trialReportsQuery_single, trialId);
	// hier zit nog iets mis... -> laadt toch het document in.
	const [document, reloadDocument] = useApiEffectWithDefer(documentsQuery_single, item?.documentId ?? '', isNotNullNorUndefined);
	const reload = useCallback(() => {
		reloadTrial();
		reloadDocument();
	}, [reloadTrial, reloadDocument]);
	const { open, cancel, confirm, isStacked } = useDialogsContext(reload);

	if (item === undefined) {
		return <div></div>;
	}

	const onLeftSpeechTest = () => {
		if (item.hasLeftSpeechTest) {
			open(
				<SpeechTestDialog
					open
					trialReport={item}
					patientId={patientId}
					close={confirm}
					type='Left'
				/>
			);
		} else {
			open(
				<SpeechTestForm
					open
					trialId={trialId}
					patientId={patientId}
					confirm={confirm}
					cancel={cancel}
					type='Left'
				/>
			);
		}
	};

	const onRightSpeechTest = () => {
		if (item.hasRightSpeechTest) {
			open(
				<SpeechTestDialog
					open
					trialReport={item}
					patientId={patientId}
					close={confirm}
					type='Right'
				/>
			);
		} else {
			open(
				<SpeechTestForm
					open
					trialId={trialId}
					patientId={patientId}
					confirm={confirm}
					cancel={cancel}
					type='Right'
				/>
			);
		}
	};

	const onStereoSpeechTest = () => {
		if (item.hasStereoSpeechTest) {
			open(
				<SpeechTestDialog
					open
					trialReport={item}
					patientId={patientId}
					close={confirm}
					type='Stereo'
				/>
			);
		} else {
			open(
				<SpeechTestForm
					open
					trialId={trialId}
					patientId={patientId}
					confirm={confirm}
					cancel={cancel}
					type='Stereo'
				/>
			);
		}
	};

	const onLeftToneThreshold = () => {
		if (item.hasLeftToneThresholdAudiogramTest) {
			open(
				<ToneThresholdAudiogramTestDialog
					open
					id={trialId}
					patientId={patientId}
					close={confirm}
					type='left'
				/>
			);
		} else {
			open(
				<MonoToneThresholdAudiogramTestForm
					open
					patientId={patientId}
					trialId={trialId}
					cancel={cancel}
					confirm={confirm}
					type='left'
				/>
			);
		}
	};

	const onRightToneThreshold = () => {
		if (item.hasRightToneThresholdAudiogramTest) {
			open(
				<ToneThresholdAudiogramTestDialog
					open
					id={trialId}
					patientId={patientId}
					close={confirm}
					type='right'
				/>
			);
		} else {
			open(
				<MonoToneThresholdAudiogramTestForm
					open
					patientId={patientId}
					trialId={trialId}
					cancel={cancel}
					confirm={confirm}
					type='right'
				/>
			);
		}
	};

	const onStereoToneThreshold = () => {
		if (item.hasBothEarsToneThresholdImageAttachmentId) {
			open(
				<ToneThresholdAudiogramTestDialog
					open
					id={trialId}
					patientId={patientId}
					close={confirm}
					type='both'
				/>
			);
		} else {
			open(
				<StereoToneThresholdAudiogramTestForm
					open
					patientId={patientId}
					trialId={trialId}
					cancel={cancel}
					confirm={confirm}
				/>
			);
		}
	};

	const onOrientationTest = () => {
		if (item.hasOrientationTest) {
			open(
				<OrientationTestDialog
					open
					id={trialId}
					item={item.orientationTest}
					close={confirm}
				/>
			);
		} else {
			open(
				<OrientationTestForm
					open
					trialId={trialId}
					test={item.orientationTest}
					cancel={cancel}
					confirm={confirm}
				/>
			);
		}
	};

	const onCltTest = () => {
		if (item.hasCltTest) {
			open(
				<CltTestDialog
					open
					id={trialId}
					item={item.cltTest}
					close={confirm}
				/>
			);
		} else {
			open(
				<CltTestForm
					open
					trialId={trialId}
					test={item.cltTest}
					cancel={cancel}
					confirm={confirm}
				/>
			);
		}
	};

	const onSpeechInNoiseTest = () => {
		if (item.hasSpeechInNoiseTest) {
			open(
				<SpeechInNoiseTestDialog
					open
					id={trialId}
					item={item.speechInNoiseTest}
					close={confirm}
				/>
			);
		} else {
			open(
				<SpeechInNoiseTestForm
					open
					trialId={trialId}
					test={item.speechInNoiseTest}
					cancel={cancel}
					confirm={confirm}
				/>
			);
		}
	};

	const onSpeechImages = () => {
		if (item.hasSpeechImages) {
			open(
				<SpeechImagesDialog
					open
					patientId={patientId}
					trialReport={item}
					close={confirm}
				/>
			);
		} else {
			open(
				<SpeechImagesForm
					open
					patientId={patientId}
					trialId={item.id}
					confirm={confirm}
					cancel={cancel}
				/>
			);
		}
	};

	const onCreateDocument = () => {
		open(
			<SelectTemplateFromPredefinedTypeForm
				open
				type='RfiaTrialReport'
				submitF={templateId => rfiaDocumentsCommand_createTrialReport(rfiaId, trialId, templateId)}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onAverageHours = () => {
		open(
			<AverageWearingHoursForm
				open
				trialId={trialId}
				initialValue={item.averageNumberOfHoursPerDayWearingHearingAids}
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			maxWidth='md'
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<DescriptionIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{strings.trialReport}
					</Typography>
					<StatusFibers
						step={item.isDocumentInSync ? 2 : 1}
						requiredStep={2}
						totalSteps={2}
					/>
					{item.hasDocument && item.isDocumentInSync === false && <WarningLabelledProperty warning={strings.testsAddedOrChangedAfterCreationOfDocument} />}
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.tests} />
				<PropertyInGrid
					label={`${strings.averageNumberOfHoursPerDayWearingHearingAids} (${strings.hoursPerDay})`}
					value={`${item.averageNumberOfHoursPerDayWearingHearingAids} ${strings.hoursPerDay}`}
					edit={onAverageHours}
				/>
				<PropertyInGrid
					labelGridStyle={{ marginTop: '20px' }}
					label={`${strings.tonalAudiogram} (+PTA)`}
					value={
						<div className='df-row-ac'>
							<TrialReportButtonGroup
								hasTest={item.hasLeftToneThresholdAudiogramTest}
								onClick={onLeftToneThreshold}
								caption={strings.left}
							/>
							<Divider
								orientation='vertical'
								flexItem
								style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), marginTop: theme.spacing(1) }}
							/>
							<TrialReportButtonGroup
								hasTest={item.hasRightToneThresholdAudiogramTest}
								onClick={onRightToneThreshold}
								caption={strings.right}
							/>
							<Divider
								orientation='vertical'
								flexItem
								style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), marginTop: theme.spacing(1) }}
							/>
							<TrialReportButtonGroup
								hasTest={item.hasBothEarsToneThresholdImageAttachmentId}
								onClick={onStereoToneThreshold}
								caption={strings.stereo}
							/>
						</div>
					}
				/>
				<PropertyInGrid
					labelGridStyle={{ marginTop: '20px' }}
					label={strings.speechAudiometries}
					value={
						<div className='df-row-ac'>
							<TrialReportButtonGroup
								hasTest={item.hasLeftSpeechTest}
								onClick={onLeftSpeechTest}
								caption={strings.left}
							/>
							<Divider
								orientation='vertical'
								flexItem
								style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), marginTop: theme.spacing(1) }}
							/>
							<TrialReportButtonGroup
								hasTest={item.hasRightSpeechTest}
								onClick={onRightSpeechTest}
								caption={strings.right}
							/>
							<Divider
								orientation='vertical'
								flexItem
								style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), marginTop: theme.spacing(1) }}
							/>
							<TrialReportButtonGroup
								hasTest={item.hasStereoSpeechTest}
								onClick={onStereoSpeechTest}
								caption={strings.stereo}
							/>
							<Divider
								orientation='vertical'
								flexItem
								style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), marginTop: theme.spacing(1) }}
							/>
							<TrialReportButtonGroup
								hasTest={item.hasSpeechImages}
								onClick={onSpeechImages}
								caption={strings.graphs}
							/>
						</div>
					}
				/>
				<PropertyInGrid
					label={strings.orientationTest}
					value={
						<TrialReportButtonGroup
							hasTest={item.hasOrientationTest}
							onClick={onOrientationTest}
						/>
					}
				/>
				<PropertyInGrid
					label={strings.cltTest}
					value={
						<TrialReportButtonGroup
							hasTest={item.hasCltTest}
							onClick={onCltTest}
						/>
					}
				/>
				<PropertyInGrid
					label={strings.speechInNoise}
					value={
						<TrialReportButtonGroup
							hasTest={item.hasSpeechInNoiseTest}
							onClick={onSpeechInNoiseTest}
						/>
					}
				/>
				{item.hasDocument === false && (
					<>
						<GridDividerWithCaption caption={strings.document} />
						<DetailDialogGridComponent
							rightContent={
								<SmallPrimaryTextButton
									onClick={onCreateDocument}
									startIcon={<FileDocumentEditOutlineIcon />}>
									Creëer document
								</SmallPrimaryTextButton>
							}
						/>
					</>
				)}
				{document && (
					<>
						<GridDividerWithCaption caption={strings.document} />
						<PropertyInGrid
							value={
								<div className='df-col-as'>
									<DocumentWithRebuildActions
										document={document}
										reload={reloadDocument}
										close={close}
										isDocumentInSync={item.isDocumentInSync}
									/>
								</div>
							}
						/>
						<DocumentHistory document={document} />
					</>
				)}
			</DialogContentGrid>
		</Dialog>
	);
};
