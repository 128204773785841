import { SupplierExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createSuppliersExportRecord = (strings: IStrings): Record<SupplierExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		Name: strings.name,
		AddressLine: strings.addressLine,
		Zip: strings.zip,
		City: strings.city,
		Country: strings.country,
		Email: strings.email,
		DefaultDeliveryTime: strings.defaultDeliveryTime,
		AccountNumber: strings.accountNumber,
		FirstName: strings.firstName,
		LastName: strings.lastName,
		Salutation: strings.salutation,
		CountOrders: strings.countOrders,
		CountDeliveries: strings.countDeliveries,
		CountPurchases: strings.countPurchases,
		BrandsCsv: strings.brands,
	};
};
