import { Divider } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import { SupplierDialog } from 'app/main/contacts/suppliers/SupplierDialog';
import { useFindUser } from 'app/main/settings/users/useFindUser';
import { LinkUserForm } from 'app/main/settings/users/forms/LinkUserForm';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { DateModelForm } from 'framework/forms/DateModelForm';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { formatDate } from 'framework/utils/date/formatDate';
import {
	IOrderV2,
	ordersCommand_cancel,
	ordersCommand_patchExpectedDeliveryDate,
	ordersCommand_patchExternalReference,
	ordersCommand_patchHandlingUser,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PlaceOrderWithSupplierRequestForm } from '../forms/PlaceOrderWithSupplierRequestForm';
import { AddOrderLinesRequestForm } from '../forms/addLines/AddOrderLinesRequestForm';
import { RegisterOrderDeliveryRequestForm } from '../forms/delivery/RegisterOrderDeliveryRequestForm';
import { OrderDetailProcessComponent } from './OrderDetailProcessComponent';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';

interface IProps {
	item: IOrderV2;
	reload: VoidFunction;
}

export const OrderDetailPageSidebar = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const user = useFindUser(item.handlingUserId);

	const onCancel = () => {
		open(
			<AlertDialog
				open
				title={strings.cancelOrder}
				content={strings.areYouSureYouWantToCancelThisOrderQuestion}
				acceptText={strings.yesWhatExclamation(strings.cancel)}
				rejectText={strings.no}
				reject={cancel}
				acceptF={() => ordersCommand_cancel({ orderId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onPatchExternalReference = () => {
		open(
			<StringModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.changeSomething(strings.externalReference)}
				label={strings.externalReference}
				initialValue={item.externalReference}
				cancel={cancel}
				submitText={strings.update}
				submitFunction={value => ordersCommand_patchExternalReference({ orderId: item.id, externalReference: value })}
			/>
		);
	};

	const onPatchExpectedDeliveryDate = () => {
		open(
			<DateModelForm
				open
				confirm={confirm}
				cancel={cancel}
				formTitle={strings.changeWhat(strings.expectedDeliveryDate)}
				label={strings.referenceDate}
				initialValue={item.expectedDeliveryDate}
				submitFunction={date => ordersCommand_patchExpectedDeliveryDate({ orderId: item.id, expectedDeliveryDate: date })}
			/>
		);
	};

	const onPatchHandlingUser = () => {
		open(
			<LinkUserForm
				open
				initialValue={item.handlingUserId}
				confirm={confirm}
				cancel={cancel}
				submitFunction={userId => ordersCommand_patchHandlingUser({ orderId: item.id, userId: userId })}
			/>
		);
	};

	const onPlaceWithSupplier = () => {
		open(
			<PlaceOrderWithSupplierRequestForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onRegisterDelivery = () => {
		open(
			<RegisterOrderDeliveryRequestForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onAddLines = () => {
		open(
			<AddOrderLinesRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	const onViewSupplier = () => {
		open(
			<SupplierDialog
				open
				close={cancel}
				id={item.supplierId}
			/>
		);
	};

	return (
		<div
			className='df-col h100'
			style={{
				padding: 16,
				paddingRight: 8,
				// width: 250,
			}}>
			<div className='df-col gap-6'>
				<CaptionVerticalLabelledPropertyX label={strings.reference}>{item.reference}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.externalReference}
					edit={onPatchExternalReference}>
					{item.externalReference ? item.externalReference : strings.notApplicableAbbreviation}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.expectedDeliveryDate}
					edit={onPatchExpectedDeliveryDate}>
					<div className='df-row-ac gap-4'>
						{item.isOverDue && (
							<StatusIcon
								status='warning'
								fontSize='small'
							/>
						)}
						<span>{formatDate(item.expectedDeliveryDate)}</span>
					</div>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.user}
					edit={onPatchHandlingUser}>
					{user?.lastNameCommaFirstName}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.supplier}
					link={onViewSupplier}>
					{item.supplierIdentifier}
				</CaptionVerticalLabelledPropertyX>
			</div>
			<Divider style={{ marginTop: 16, marginBottom: 24 }} />
			<OrderDetailProcessComponent
				item={item}
				reload={reload}
			/>
			<div className='fg1'></div>
			<DividerWithMargin />
			<div className='df-col gap-8'>
				<TextLinkButton
					startIcon={<AddCircleOutlineOutlinedIcon />}
					onClick={onAddLines}
					disabled={item.canAddOrDeleteLines === false}>
					{strings.addLines}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<StorefrontOutlinedIcon />}
					onClick={onPlaceWithSupplier}
					disabled={item.canPlaceWithSupplier === false}>
					{strings.placeOrderWithSupplier}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<LocalShippingOutlinedIcon />}
					onClick={onRegisterDelivery}
					disabled={item.canRegisterDelivery === false}>
					{strings.registerDelivery}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<DeleteOutlineOutlinedIcon />}
					onClick={onCancel}
					hoverColor='secondary'
					disabled={item.canCancel === false}
					style={{ paddingBottom: 0 }}>
					{strings.cancelOrder}
				</TextLinkButton>
			</div>
		</div>
	);
};
