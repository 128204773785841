import { CashRegisterEntryExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createExportRecord = (strings: IStrings): Record<CashRegisterEntryExportProp, string> => {
	return {
		LocationIdentifier: strings.location,
		ReferenceDate: strings.date,
		Value: strings.amountAsValue,
		GlobalResultingBalance: strings.balance,
		Index: strings.indexNumber,
		PaymentReferenceOrDescription: strings.description,
	};
};
