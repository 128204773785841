import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useUsersAsFilterOptions } from 'app/main/settings/users/useUsersAsFilterOptions';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ICommissionPayoutsQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	params: ICommissionPayoutsQueryParams;
	onFilter: (params: ICommissionPayoutsQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof ICommissionPayoutsQueryParams)[] = [];

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const filterUsers = useUsersAsFilterOptions();

	if (filterUsers === undefined) {
		return <div></div>;
	}

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.paidOutDate}
				after={params.payoutAfter}
				before={params.payoutBefore}
				setSelected={(after, before) => onFilter({ ...params, payoutAfter: after, payoutBefore: before })}
			/>
			<ListFilterComponent<string>
				options={filterUsers}
				selected={params.userIds}
				setSelected={vals => onFilter({ ...params, userIds: vals })}
				label={strings.user}
				icon={<HelpOutlineIcon />}
				title={strings.user}
			/>
		</FilterBar>
	);
};
