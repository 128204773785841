import { TextField, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { createHeader } from 'framework/table/createHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { SortableTable } from 'framework/table/SortableTable';
import { formatDate } from 'framework/utils/date/formatDate';
import { IInventoryItem, INoahDeviceForPatient, inventoryItemsQuery_single, IRepair, noahDeviceInventoryItemMapsQuery_byPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { LeftRightRemoteStatusChip } from 'shared/searchAndSelect/wizardImportNoahDevices/LeftRightRemoteStatusChip';
import { ImportDeviceForm } from '../../../inventoryManagement/noahDevices/single/forms/ImportDeviceForm';
import { NoahDeviceImportedStatusChip } from '../../../inventoryManagement/noahDevices/single/NoahDeviceImportedStatusChip';

interface IProps {
	patientId: string;
	selected: IInventoryItem | undefined;
	selectItem: (item: IInventoryItem | undefined) => void;
	hasTouchedSubmit: boolean;
	repair: IRepair;
}

export const SelectNoahDevice = ({ patientId, selected, selectItem, repair }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const [devices, reload] = useApiEffect(noahDeviceInventoryItemMapsQuery_byPatient, patientId);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const loadII = useApiCall(inventoryItemsQuery_single);

	const headers = [
		createHeader<INoahDeviceForPatient>(strings.status, t => <NoahDeviceImportedStatusChip item={t} />),
		createSortableHeader<INoahDeviceForPatient>(strings.dateLastSelection, 'lastSelectionDate', t => formatDate(t.lastSelectionDate)),
		createSortableHeader<INoahDeviceForPatient>(
			`${strings.manufacturer}, ${strings.model}`,
			'manufacturerCommaInstrumentTypeName',
			t => t.manufacturerCommaInstrumentTypeName
		),
		createSortableHeader<INoahDeviceForPatient>(strings.serialNumber, 'serialNumber', t => t.serialNumber),
		createHeader<INoahDeviceForPatient>(strings.orientation, t => <LeftRightRemoteStatusChip item={t} />),
	];

	if (devices === undefined) {
		return <div></div>;
	}

	const onImportOrSelect = (device: INoahDeviceForPatient) => {
		if (device.isLinkedToInventoryItem) {
			onSelect(device.inventoryItemId!);
		} else {
			onImport(device);
		}
	};

	const onImport = (t: INoahDeviceForPatient) => {
		open(
			<ImportDeviceForm
				id={t.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onSelect = async (inventoryItemId: string) => {
		const r = await loadII(inventoryItemId);
		if (r.isSuccess) {
			selectItem(r.result);
		}
	};

	return (
		<div className='df-col'>
			{selected ? (
				<>
					<Typography
						variant='body2'
						style={{ cursor: 'pointer', marginBottom: theme.spacing(1) }}
						onClick={() => selectItem(undefined)}
						color='primary'>
						<em>{strings.selectOtherProductQuestion}</em>
					</Typography>
					<TextField
						label={strings.selectedProduct}
						variant='filled'
						disabled
						value={selected.identifier}
					/>
				</>
			) : (
				<>
					{devices.length === 0 ? (
						<NoRecordsFound withPadding>{strings.noResults}</NoRecordsFound>
					) : (
						<SortableTable<INoahDeviceForPatient>
							withPaperContainer
							padding={theme.spacing(1)}
							headers={headers}
							getKey={t => t.id}
							values={devices}
							defaultSortParams={{ property: 'lastSelectionDate', direction: 'desc' }}
							appendColumn={createEmptyHeader<INoahDeviceForPatient>('importOrSelect', t => (
								<SmallPrimaryTextButton
									disabled={t.inventoryItemId !== undefined && repair.inventoryItemIds.indexOf(t.inventoryItemId) !== -1}
									onClick={() => onImportOrSelect(t)}>
									{t.isLinkedToInventoryItem ? strings.select : strings.import}
								</SmallPrimaryTextButton>
							))}
						/>
					)}
				</>
			)}
		</div>
	);
};
