import { Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { formatDate } from 'framework/utils/date/formatDate';
import { IInventoryItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: IInventoryItem;
	style?: React.CSSProperties;
}

export const TransactionsSummaryCard = ({ item, style }: IProps) => {
	const strings = useLocalization();

	return (
		<Card
			style={style}
			className='df-col'>
			<CardContent>
				<CardTitleTypography title={strings.summary} />
				{item.orderDate && (
					<LabelledProperty
						label={strings.orderDate}
						property={formatDate(item.orderDate)}
					/>
				)}
				{item.firstDeliveryDate && (
					<LabelledProperty
						label={strings.deliveryDate}
						property={formatDate(item.firstDeliveryDate)}
					/>
				)}
				{item.dateSold && (
					<LabelledProperty
						label={strings.salesDate}
						property={formatDate(item.dateSold)}
					/>
				)}
				{item.salesCreditDate && (
					<LabelledProperty
						label={strings.creditNoteDate}
						property={formatDate(item.salesCreditDate)}
					/>
				)}
				{item.returnToSupplierDate && (
					<LabelledProperty
						label={strings.returnToSupplierDate}
						property={formatDate(item.returnToSupplierDate)}
					/>
				)}
				{item.purchaseDate && (
					<LabelledProperty
						label={strings.purchaseDate}
						property={formatDate(item.purchaseDate)}
					/>
				)}
				{item.purchaseCreditDate && (
					<LabelledProperty
						label={strings.purchaseCreditDate}
						property={formatDate(item.purchaseCreditDate)}
					/>
				)}
			</CardContent>
		</Card>
	);
};
