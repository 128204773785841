import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ISortParams } from '../types/ISortParams';
import { IHeader } from './IHeader';
import { TableWithHeadersAndValues } from './TableWithHeadersAndValues';

interface IProps<T> {
	values: T[];
	headers: IHeader<T>[];
	getKey: (inst: T) => string;
	padding?: number;
	defaultSortParams: ISortParams<T>;
	appendColumn?: IHeader<T>;
	withPaperContainer?: boolean;
}

export const SortableTable = <T extends unknown>({ values, headers, defaultSortParams, appendColumn, ...rest }: IProps<T>) => {
	const [sp, setSp] = useState<ISortParams<T>>(defaultSortParams);
	const [sorted, setSorted] = useState<T[]>([]);

	useEffect(() => {
		setSorted(_.orderBy(values, sp.property, sp.direction as any));
	}, [values, defaultSortParams, sp]);

	return (
		<TableWithHeadersAndValues<T>
			values={sorted}
			headers={appendColumn ? [...headers, appendColumn] : headers}
			sortParams={sp}
			setSortParams={setSp}
			{...rest}
		/>
	);
};
