import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ProductCatalogFilterDataContext } from 'app/main/inventoryManagement/productCatalog/context/ProductCatalogFilterDataContext';
import { FilterBar } from 'framework/filter/FilterBar';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ITypesAndCategoriesProductCatalogQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	params: ITypesAndCategoriesProductCatalogQueryParams;
	onFilter: (params: ITypesAndCategoriesProductCatalogQueryParams) => void;
}

const exclusions: (keyof ITypesAndCategoriesProductCatalogQueryParams)[] = [];

export const FilterCategories = ({ params, onFilter }: IProps) => {
	const strings = useLocalization();
	const [data] = useCacheContext(ProductCatalogFilterDataContext);

	return (
		<FilterBar
			disableWrap
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			style={{ minHeight: 48.5 }}>
			{data && data.categories.length > 0 && (
				<StringListFilterComponent
					label={strings.category}
					selected={params.categories}
					options={data.categories}
					icon={<HelpOutlineIcon />}
					setSelected={value => onFilter({ ...params, categories: value })}
				/>
			)}
			{data && data.subcategories.length > 0 && (
				<StringListFilterComponent
					label={strings.subcategory}
					selected={params.subcategories}
					options={data.subcategories}
					icon={<HelpOutlineIcon />}
					setSelected={value => onFilter({ ...params, subcategories: value })}
				/>
			)}
		</FilterBar>
	);
};
