import { INoahVersion } from 'gen/client/HamsClient';

export const formatNoahVersion = (version: INoahVersion | undefined): string => {
	if (version === undefined) {
		return 'Invalid format (undefined)';
	} else if (version === null) {
		return 'Invalid format (null)';
	} else {
		return `${version.fileMajorPart}.${version.fileMinorPart}.${version.fileBuildPart}.${version.filePrivatePart}_${version.patchVersion}`;
	}
};
