import React, { useContext } from 'react';
import { PreloadCacheProvider } from 'framework/context/PreloadCacheProvider';
import { IHealthInsuranceFundCache, healthInsuranceFundsQuery_all, healthInsuranceFundsQuery_concurrencyToken } from 'gen/ApiClient';
import { HealthInsuranceFundsCacheContext } from './HealthInsuranceFundsCacheContext';
import { PreloadContext } from './PreloadContext';

export const HealthInsuranceFundsCacheProvider = ({ children }: any) => {
	const { hifCache } = useContext(PreloadContext);

	return (
		<PreloadCacheProvider<IHealthInsuranceFundCache>
			initialData={hifCache}
			concurrencyF={healthInsuranceFundsQuery_concurrencyToken}
			loadF={healthInsuranceFundsQuery_all}
			context={HealthInsuranceFundsCacheContext}>
			{children}
		</PreloadCacheProvider>
	);
};
