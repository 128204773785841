import { Form, Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { FormPasswordField } from 'framework/forms/FormPasswordField';
import { FormTextField } from 'framework/forms/FormTextField';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { auth_logout, auth_resetPassword } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { AuthContext } from './AuthContext';
import { EmptyPageWithLogo } from './EmptyPageWithLogo';
import { useHandleLogin } from './useHandleLogin';

interface IModel {
	email: string;
	password: string;
	confirmPassword: string;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		email: yup.string().defined(),
		password: yup.string().required(strings.formRequired(strings.password)),
		confirmPassword: yup
			.string()
			.required(strings.formRequired(strings.confirmPassword))
			.oneOf([yup.ref('password')], strings.passwordDoesNotMatch),
	});
};

interface IRouteParams {
	token: string;
	email: string;
}

export const ResetPassword = () => {
	const authContext = useContext(AuthContext);
	const [resetPassword, isSubmitting, setSubmitting] = useFormSubmit(auth_resetPassword);
	const [logout] = useFormSubmit(auth_logout, setSubmitting);
	const strings = useLocalization();
	const { token, email } = useParams<IRouteParams>();
	const handleLogin = useHandleLogin();

	useEffect(() => {
		const x = () => {
			logout();
			authContext.logout();
		};
		x();
		// only happens at mount of this screen
		// eslint-disable-next-line
	}, []);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await resetPassword({ resetPasswordTokenId: token, password: values.password, email: email });

		if (r.isSuccess) {
			if (r.result.hasError) {
				r.result.errors.forEach(t => helpers.setFieldError(t.property, t.error));
			} else {
				handleLogin(r.result);
			}
		}
	};

	return (
		<EmptyPageWithLogo title={strings.setPassword}>
			<Formik<IModel>
				validateOnMount
				initialValues={{ email: email, password: '', confirmPassword: '' }}
				validationSchema={createSchema(strings)}
				onSubmit={handleSubmit}>
				{props => (
					<Form
						autoComplete='off'
						className='df-col'
						style={{ minWidth: '400px' }}>
						<FormTextField<IModel>
							name='email'
							label={strings.email}
							required
							disabled
						/>
						<FormPasswordField<IModel>
							name='password'
							label={strings.password}
							required
						/>
						<FormPasswordField<IModel>
							name='confirmPassword'
							label={strings.confirmPassword}
							required
						/>
						<LoaderButton
							style={{ marginTop: '20px' }}
							size='large'
							variant='contained'
							color='primary'
							type='submit'
							isLoading={isSubmitting}
							disabled={isSubmitting || !props.isValid}>
							{strings.setPassword}
						</LoaderButton>
					</Form>
				)}
			</Formik>
		</EmptyPageWithLogo>
	);
};
