import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { IArticlesInquiryDto } from 'gen/ApiClient';
import React from 'react';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { ArticlesInquiriesPageBreadcrumbs } from '../ArticlesInquiriesPageBreadcrumbs';
import { ArticlesInquiryStatusChip } from '../ArticlesInquiryStatusChip';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

interface IProps {
	item: IArticlesInquiryDto;
	reload: VoidFunction;
}

export const ArticlesInquiryDetailPageHeader = ({ item, reload }: IProps) => {
	return (
		<div
			className='df-row-ac fg1'
			style={{ paddingBottom: 16 }}>
			<ArticlesInquiriesPageBreadcrumbs />
			<PageBreadcrumbDivider />
			<PageBreadcrumbTitle title={item.reference} />
			<ArticlesInquiryStatusChip
				item={item}
				style={{ marginLeft: 4 }}
			/>
			<div className='fg1'></div>
			<PageXHeaderActions>
				<PageReloadButtonX reload={reload} />
			</PageXHeaderActions>
		</div>
	);
};
