import { DialogProps, Divider } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormAutocompleteFreeSoloTextField } from 'framework/forms/FormAutocompleteFreeSoloTextField';
import { FormExtendedRadioGroupField } from 'framework/forms/FormExtendedRadioGroupField';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IServiceProductCatalogItemModel, productCatalogCommand_newService } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { FormSelectVatPercentageField } from 'shared/forms/FormSelectVatPercentageField';
import * as yup from 'yup';
import { ProductCatalogFilterDataContext } from '../context/ProductCatalogFilterDataContext';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IServiceProductCatalogItemModel>({
			name: yup.string().required(strings.formRequired(strings.name)),
			listPrice: yup.number().required('Aanbevolen verkoopsprijs'), //TODO
			vatPercentage: yup.number().required(strings.formRequired(strings.vatPercentage)).defined(),
			category: yup.string(),
			subcategory: yup.string(),
			canAlsoPurchase: yup.boolean(),
		})
		.defined();
};

const emptyValues: IServiceProductCatalogItemModel = {
	name: '',
	category: '',
	subcategory: '',
	listPrice: undefined as any,
	vatPercentage: 21,
	canAlsoPurchase: false,
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	previous?: VoidFunction;
}

const stepsRecord: Record<number, (keyof IServiceProductCatalogItemModel)[]> = {
	0: ['name'],
	1: ['listPrice', 'vatPercentage', 'canAlsoPurchase'],
	2: ['category', 'subcategory'],
};

export const ServiceForm = ({ confirm, cancel, previous, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [data] = useCacheContext(ProductCatalogFilterDataContext);
	const [create, isSubmitting] = useFormSubmit(productCatalogCommand_newService);
	const [step, setStep] = useState<number>(0);

	const handleSubmit = async (values: IServiceProductCatalogItemModel, helpers: FormikHelpers<IServiceProductCatalogItemModel>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.productCreated);
			confirm(r.result.objectId);
		}
	};

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IServiceProductCatalogItemModel>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<OverflowDialog {...rest}>
					<DialogTitleWithFormStepper
						title={strings.newService}
						step={step}
						labels={[strings.name, strings.price, strings.category]}
					/>
					<OverflowDialogContent>
						{step === 0 && (
							<>
								<FormTextField<IServiceProductCatalogItemModel>
									name='name'
									label={strings.name}
									required
								/>
							</>
						)}
						{step === 1 && (
							<>
								<FormSelectVatPercentageField<IServiceProductCatalogItemModel>
									name='vatPercentage'
									label={strings.vatPercentage}
									required
								/>
								<FormMoneyField<IServiceProductCatalogItemModel>
									name='listPrice'
									label={strings.salesPrice}
									allowNegative
								/>
								<Divider style={{ marginTop: 16, marginBottom: 16 }} />
								<FormExtendedRadioGroupField<IServiceProductCatalogItemModel, boolean>
									name='canAlsoPurchase'
									label='fittingValidated'
									options={[
										{ value: true, label: strings.isAlsoPurchased, caption: strings.thisWhatWillAlsoBePurchasedFromASupplier(strings.service) },
										{ value: false, label: strings.willNotBePurchased, caption: strings.thisWhatWillNotBePurchasedFromASupplier(strings.service) },
									]}
								/>
							</>
						)}
						{step === 2 && (
							<>
								<FormAutocompleteFreeSoloTextField<IServiceProductCatalogItemModel>
									name='category'
									label={strings.category}
									options={data.categories}
								/>
								<FormAutocompleteFreeSoloTextField<IServiceProductCatalogItemModel>
									name='subcategory'
									label={strings.subcategory}
									options={data.subcategories}
								/>
							</>
						)}
					</OverflowDialogContent>
					<PageableFormDialogActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
						previous={previous}
					/>
				</OverflowDialog>
			</Form>
		</Formik>
	);
};
