import React from 'react';
import { CustomerAccountsProvider } from './customerAccounts/context/CustomerAccountsProvider';
import { PaymentsProvider } from './payments/context/PaymentsProvider';
import { SalesProviders } from './sales/SalesProviders';
import { TendersProviders } from './tenders/TendersProviders';
import { SettlementProposalProviders } from './settlementProposals/SettlementProposalProviders';
import { SupplierAccountsProvider } from './supplierAccounts/SupplierAccountsProvider';
import { PurchasesProviders } from './purchases/PurchasesProviders';
import { CommissionsProviders } from './commissions/context/CommissionsProviders';
import { DailyReceiptsProviders } from './dailyReceipts/context/DailyReceiptsProviders';
import { CashRegisterEntriesProviders } from './cashRegister/context/CashRegisterEntriesProviders';

export const FinancialsProviders = ({ children }: any) => {
	return (
		<TendersProviders>
			<SalesProviders>
				<CustomerAccountsProvider>
					<PaymentsProvider>
						<SettlementProposalProviders>
							<SupplierAccountsProvider>
								<PurchasesProviders>
									<CommissionsProviders>
										<DailyReceiptsProviders>
											<CashRegisterEntriesProviders>{children}</CashRegisterEntriesProviders>
										</DailyReceiptsProviders>
									</CommissionsProviders>
								</PurchasesProviders>
							</SupplierAccountsProvider>
						</SettlementProposalProviders>
					</PaymentsProvider>
				</CustomerAccountsProvider>
			</SalesProviders>
		</TendersProviders>
	);
};
