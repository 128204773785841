import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { IOption } from 'framework/IOption';
import { isFiltered } from 'framework/utils/isFiltered';
import { ISubscriptionsQueryParams, SubscriptionStatuses } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { ViewContext } from './context/ViewContext';

interface IProps {
	params: ISubscriptionsQueryParams;
	onFilter: (params: ISubscriptionsQueryParams) => void;
	paddingLeft: number;
}

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const { statusRecord } = useContext(ViewContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params)}
			onClear={() => onFilter(clearParams(params))}
			paddingLeft={paddingLeft}>
			<ListFilterComponent<string>
				options={SubscriptionStatuses.map<IOption<string>>(t => ({ id: t, identifier: statusRecord[t] }))}
				selected={params.statuses}
				setSelected={vals => onFilter({ ...params, statuses: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.status}
			/>
		</FilterBar>
	);
};
