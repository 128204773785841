import React from 'react';
import { preventDefaultAndStopPropagation } from 'framework/utils/preventDefaultAndStopPropagation';
import { IEidData } from 'gen/ApiClient';
import { parseEidDataOrDefault } from './utils/parseEidDataOrDefault';

interface IProps {
	children: any;
	onRead: (data: IEidData) => void;
}

export const DragAndDropWrapper = ({ children, onRead }: IProps) => {
	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		preventDefaultAndStopPropagation(e);
		e.dataTransfer.dropEffect = 'copy';
	};
	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		preventDefaultAndStopPropagation(e);
		const data = e.dataTransfer.getData('text');
		if (data !== null && typeof data === 'string') {
			const parsed = parseEidDataOrDefault(data);
			if (parsed !== undefined) {
				onRead(parsed);
			}
		}
		// handle error!
	};

	return (
		<div
			onDrop={handleDrop}
			onDragOver={handleDragOver}
			onDragEnter={preventDefaultAndStopPropagation}
			onDragLeave={preventDefaultAndStopPropagation}>
			{children}
		</div>
	);
};
