import { DialogProps } from '@material-ui/core';
import React from 'react';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IPatient, patientsCommand_updateAttendingGeneralPractitioner } from 'gen/ApiClient';
import { SelectGeneralPractitionerForm } from '../../contacts/generalPractitioners/forms/SelectGeneralPractitionerForm';

interface IProps extends DialogProps {
	item: IPatient;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LinkGeneralPractitionerForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [link, isSubmitting] = useFormSubmit(patientsCommand_updateAttendingGeneralPractitioner);

	const onConfirm = async (id: string, setError: (error: string) => void) => {
		handleValidateResponseBase(await link(item.id, id), setError, confirm);
	};

	return (
		<SelectGeneralPractitionerForm
			{...rest}
			confirm={onConfirm}
			cancel={cancel}
			initialId={item.attendingGeneralPractitionerId}
			isSubmitting={isSubmitting}
		/>
	);
};
