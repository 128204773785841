import { Dialog, DialogContent, DialogProps, Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { FileSearchOutlineIcon } from 'framework/components/icons/FileSearchOutlineIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { FormSelectFieldOnId } from 'framework/forms/FormSelectFieldOnId';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFieldError } from 'framework/forms/utils/setFieldError';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { PaperOutlined } from 'framework/table/PaperOutlined';
import { formatDate } from 'framework/utils/date/formatDate';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import {
	audiogramStandardsQuery_withSpeechByPatient,
	IAudiogramStandardWithSpeechAudiogramsDto,
	ISpeechAudiogram,
	ISpeechAudiogramTestModel,
	LeftRightOrStereoType,
	trialReportsCommand_addSpeechLeft,
	trialReportsCommand_addSpeechRight,
	trialReportsCommand_addSpeechStereo,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useViewDocumentation } from 'shared/utils/useViewDocumentation';
import { SelectSpeechAudiogramsTable } from './SelectSpeechAudiogramsTable';
import { calculateBiapImprovement } from './utils/calculateBiapImprovement';
import { calculateIcaImprovement } from './utils/calculateIcaImprovement';
import { calculateImprovementAtSrtPoint } from './utils/calculateImprovementAtSrtPoint';
import { calculateVocalIndexImprovement } from './utils/calculateVocalIndexImprovement';

const createSchema = (strings: IStrings) => {
	return yup
		.object<ISpeechAudiogramTestModel>({
			audiogramStandardId: yup.string().required(),
			aided: yup.mixed(),
			unaided: yup.mixed(),
		})
		.defined();
};

const emptyValues: ISpeechAudiogramTestModel = {
	audiogramStandardId: '',
	aided: undefined as any,
	unaided: undefined as any,
};

const stepsRecord: Record<number, (keyof ISpeechAudiogramTestModel)[]> = {
	0: ['audiogramStandardId'],
	1: ['aided', 'unaided'],
};

const typeToSubmitCall = (type: LeftRightOrStereoType) => {
	if (type === 'Left') {
		return trialReportsCommand_addSpeechLeft;
	} else if (type === 'Right') {
		return trialReportsCommand_addSpeechRight;
	} else {
		return trialReportsCommand_addSpeechStereo;
	}
};

interface IProps extends DialogProps {
	patientId: string;
	trialId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
	type: LeftRightOrStereoType;
}

export const SpeechTestForm = ({ patientId, trialId, confirm, cancel, type, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(typeToSubmitCall(type));
	const [step, setStep] = useState<number>(0);
	const [audiograms] = useApiEffect(audiogramStandardsQuery_withSpeechByPatient, patientId);
	const [aided, setAided] = useState<ISpeechAudiogram>();
	const [unaided, setUnaided] = useState<ISpeechAudiogram>();

	if (audiograms === undefined) {
		return <div></div>;
	}

	if (audiograms.length === 0) {
		return (
			<NotificationDialog
				open
				title={strings.insufficientInformation}
				content={strings.noSpeechTestRecordedCreateOneFirst}
				close={cancel}
				closeText={strings.understood}
			/>
		);
	}

	const handleSubmit = async (values: ISpeechAudiogramTestModel, helpers: FormikHelpers<ISpeechAudiogramTestModel>) => {
		if (aided === undefined || unaided === undefined) {
			notify(strings.selectATestWithAndWithoutHearingAids, 'warning');
		} else {
			const r = await create(trialId, { ...values, aided: aided, unaided: unaided });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				// navigate to detail?
				notify(strings.addWhat(strings.test));
				confirm();
			}
		}
	};

	return (
		<Formik<ISpeechAudiogramTestModel>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					audiograms={audiograms}
					type={type}
					aided={aided}
					unaided={unaided}
					setAided={setAided}
					setUnaided={setUnaided}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	audiograms: IAudiogramStandardWithSpeechAudiogramsDto[];
	type: LeftRightOrStereoType;
	aided: ISpeechAudiogram | undefined;
	unaided: ISpeechAudiogram | undefined;
	setAided: (val: ISpeechAudiogram | undefined) => void;
	setUnaided: (val: ISpeechAudiogram | undefined) => void;
}

const InnerDialog = ({ step, setStep, cancel, audiograms, isSubmitting, type, aided, unaided, setAided, setUnaided, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<ISpeechAudiogramTestModel>();
	const strings = useLocalization();
	const theme = useTheme();
	const onViewSpeechRizivInfo = useViewDocumentation('speech-riziv-info');
	const selectedAudiogram = useMemo(
		() => (isNotNullNorUndefined(props.values.audiogramStandardId) ? audiograms.find(t => t.id === props.values.audiogramStandardId) : undefined),
		[audiograms, props.values.audiogramStandardId]
	);

	useEffect(() => {
		// by default ordered -> just select the first by default
		if (audiograms.length > 0) {
			setFormValue<ISpeechAudiogramTestModel>('audiogramStandardId', audiograms[0].id, props);
		}
		// eslint-disable-next-line
	}, [audiograms]);

	useEffect(() => {
		setAided(undefined);
		setUnaided(undefined);
		if (selectedAudiogram) {
			setAided(selectedAudiogram.speechAudiograms.find(t => t.isAided === true && t.leftRightOrStereo === type));
			setUnaided(selectedAudiogram.speechAudiograms.find(t => t.isUnaided === true && t.leftRightOrStereo === type));
		}
		// eslint-disable-next-line
	}, [selectedAudiogram]);

	const validateMore = () => {
		if (selectedAudiogram === null || selectedAudiogram === undefined) {
			setFieldError<ISpeechAudiogramTestModel>('audiogramStandardId', strings.selectWhat(strings.test), props);
			return false;
		} else if (selectedAudiogram.countSpeechAudiograms === 0) {
			setFieldError<ISpeechAudiogramTestModel>('audiogramStandardId', strings.selectATestWithSpeechAudiograms, props);
			return false;
		} else {
			return true;
		}
	};

	return (
		<Dialog
			{...rest}
			maxWidth={step === 1 ? 'md' : 'sm'}
			fullWidth>
			<DialogTitleWithFormStepper
				title={strings.speechTest}
				step={step}
				labels={['Audiogram', 'Vergelijk']}>
				<div style={{ marginBottom: theme.spacing(1) }}>
					<SmallPrimaryTextButton
						startIcon={<FileSearchOutlineIcon />}
						onClick={onViewSpeechRizivInfo}>
						{strings.viewRIZIVDocumentation}
					</SmallPrimaryTextButton>
				</div>
			</DialogTitleWithFormStepper>
			<DialogContent
				className='df-col'
				dividers>
				{step === 0 && (
					<>
						<FormSelectFieldOnId<ISpeechAudiogramTestModel, IAudiogramStandardWithSpeechAudiogramsDto>
							name='audiogramStandardId'
							label={strings.audiogram}
							options={audiograms}
							renderValue={t => formatDate(t.noahLastModifiedDate, 'date-and-time')}
							getKey={t => t.id}
							helperText={strings.dateLastChangedInNoah}
						/>
						<DividerWithCaption caption={strings.details} />
						{selectedAudiogram && (
							<>
								<LabelledProperty
									label={`# ${strings.speechAudiograms}`}
									property={selectedAudiogram.countSpeechAudiograms.toString()}
								/>
								<LabelledProperty
									label={`# ${strings.speechAudiograms} (${strings.left})`}
									property={selectedAudiogram.speechAudiograms.filter(t => t.isLeft).length.toString()}
								/>
								<LabelledProperty
									label={`# ${strings.speechAudiograms} (${strings.right})`}
									property={selectedAudiogram.speechAudiograms.filter(t => t.isRight).length.toString()}
								/>
								<LabelledProperty
									label={`# ${strings.speechAudiograms} (${strings.stereo})`}
									property={selectedAudiogram.speechAudiograms.filter(t => t.isStereo).length.toString()}
								/>
							</>
						)}
					</>
				)}
				{step === 1 && selectedAudiogram && (
					<>
						<SelectSpeechAudiogramsTable
							audiograms={selectedAudiogram.speechAudiograms ?? []}
							aided={aided}
							unaided={unaided}
							setAided={setAided}
							setUnaided={setUnaided}
							type={type}
						/>
						<DividerWithCaption
							caption={strings.results}
							style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
						/>
						<div className='df-row-ac jc-c'>
							<TableContainer
								component={PaperOutlined}
								style={{ width: 'fit-content' }}>
								<Table size='small'>
									<TableBody>
										<TableRow>
											<TableCell style={{ borderRight: '1px solid rgb(224, 224, 224)' }}>{`1) ${strings.hearingImprovementInWhat(
												strings.dBAgainstVocalIndex
											)}`}</TableCell>
											<TableCell style={{ fontWeight: 500 }}>{`${calculateVocalIndexImprovement(aided, unaided)} dB`}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell style={{ borderRight: '1px solid rgb(224, 224, 224)' }}>{`2.1) ${strings.hearingImprovementInWhat(
												strings.procentSpeechImprovementAtSrtPoint
											)}`}</TableCell>
											<TableCell style={{ fontWeight: 500 }}>{`${calculateImprovementAtSrtPoint(aided, unaided)} %`}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell style={{ borderRight: '1px solid rgb(224, 224, 224)' }}>{`2.2) ${strings.hearingImprovementInWhat(
												strings.procentSpeechImprovementAccordingToICAMethod
											)}`}</TableCell>
											<TableCell style={{ fontWeight: 500 }}>{`${calculateIcaImprovement(aided, unaided)} %`}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell style={{ borderBottom: 'none', borderRight: '1px solid rgb(224, 224, 224)' }}>{`2.3) ${strings.hearingImprovementInWhat(
												strings.procentSpeechImprovementAccordingToBIAPMethod
											)}`}</TableCell>
											<TableCell style={{ borderBottom: 'none', fontWeight: 500 }}>{`${calculateBiapImprovement(aided, unaided)} %`}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
				validateMore={validateMore}
			/>
		</Dialog>
	);
};
