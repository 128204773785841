import { Collapse } from '@material-ui/core';
import { ArrowDropDownOrUpIcon } from 'framework/components/ArrowDropDownOrUpIcon';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import React from 'react';

interface IProps {
	title: string | JSX.Element;
	style?: React.CSSProperties;
	children: any;
	titleStyle?: React.CSSProperties;
}

export const RosaCalendarCollapseListFilterComponent = ({ title, style, children, titleStyle }: IProps) => {
	const [isExpanded, toggleIsExpanded] = useStateToggleBoolean(true);

	return (
		<div style={style}>
			<div
				className='df-row-ac fg1'
				style={{ cursor: 'pointer' }}
				onClick={toggleIsExpanded}>
				<div style={{ fontWeight: 'bold', ...titleStyle }}>{title}</div>
				<div className='fg1'></div>
				<ArrowDropDownOrUpIcon isUp={isExpanded} />
			</div>
			<Collapse in={isExpanded}>{children}</Collapse>
		</div>
	);
};
