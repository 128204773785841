import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { FormSelectUserField } from 'app/main/settings/users/forms/FormSelectUserField';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IRegisterRepairRequest, RepairProcessSteps, repairsCommand_new, repairsQuery_nextReference } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import * as yup from 'yup';
import { FormSelectOrCreateSupplierField } from '../../../contacts/suppliers/forms/FormSelectOrCreateSupplierField';
import { FormRepairProcessStepsField } from './FormRepairProcessStepsField';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterRepairRequest>({
			reference: yup.string().required(strings.formRequired(strings.reference)),
			isAutoSelectReference: yup.boolean().defined(),
			supplierId: yup.string().required(strings.formRequired(strings.supplier)),
			patientId: yup.string(),
			steps: yup.mixed(),
			handlingUserId: yup.string().required(strings.formRequired(strings.user)),
		})
		.defined();
};

const emptyValues: IRegisterRepairRequest = {
	isAutoSelectReference: true,
	reference: '',
	supplierId: undefined as any,
	patientId: undefined as any,
	steps: [],
	handlingUserId: '',
};

const stepsRecord: Record<number, (keyof IRegisterRepairRequest)[]> = {
	0: ['reference', 'isAutoSelectReference'],
	1: ['supplierId', 'handlingUserId'],
	2: ['steps'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	patientId?: string;
}

export const RegisterRepairRequestForm = ({ confirm, cancel, patientId, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(repairsCommand_new);
	const [step, setStep] = useState<number>(0);
	const steps = useMemo(() => (patientId !== undefined ? RepairProcessSteps : RepairProcessSteps.filter(t => t !== 'DeliverToPatient')), [patientId]);
	const user = useLoggedInUser();

	const handleSubmit = async (values: IRegisterRepairRequest, helpers: FormikHelpers<IRegisterRepairRequest>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			notify(strings.registerWhat(strings.repair));
			confirm(r.result.objectId);
		}
	};
	return (
		<Formik<IRegisterRepairRequest>
			validateOnMount
			initialValues={{
				...emptyValues,
				patientId: patientId,
				steps: steps,
				handlingUserId: user.id,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	isSubmitting: boolean;
	cancel: VoidFunction;
}

const InnerDialog = ({ step, setStep, isSubmitting, cancel, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IRegisterRepairRequest>();
	const { isStacked } = useDialogsContext();
	const strings = useLocalization();
	useNextReferenceInterceptor(repairsQuery_nextReference, props);

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='md'
			open={rest.open && isStacked === false}>
			<DialogTitleWithFormStepper
				title={strings.registerWhat(strings.repair)}
				step={step}
				labels={[strings.referenceShort, strings.supplier, strings.steps]}
			/>
			<DialogContent dividers>
				{step === 0 && (
					<div className='df-col'>
						<FormSingleCheckboxField<IRegisterRepairRequest>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IRegisterRepairRequest>
							name='reference'
							label={strings.reference}
							disabled={props.values.isAutoSelectReference}
						/>
					</div>
				)}
				{step === 1 && (
					<div className='df-col'>
						<FormSelectOrCreateSupplierField<IRegisterRepairRequest>
							name='supplierId'
							label={strings.supplier}
						/>
						<FormSelectUserField<IRegisterRepairRequest>
							name='handlingUserId'
							label={strings.user}
						/>
					</div>
				)}
				{step === 2 && <FormRepairProcessStepsField<IRegisterRepairRequest> isForPatient={props.values.patientId !== undefined} />}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
