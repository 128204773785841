import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { FilterBar } from 'framework/filter/FilterBar';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { isFiltered } from 'framework/utils/isFiltered';
import { IProductCatalogSearchData, IStockItemsQueryParamsForRepair } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	data: IProductCatalogSearchData;
	params: IStockItemsQueryParamsForRepair;
	onFilter: (params: IStockItemsQueryParamsForRepair) => void;
}

const exclusions: (keyof IStockItemsQueryParamsForRepair)[] = ['isShowAll'];

export const FilterStockItems = ({ data, params, onFilter }: IProps) => {
	const strings = useLocalization();

	return (
		<FilterBar
			disableWrap
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}>
			<StringListFilterComponent
				label={strings.category}
				selected={params.categories}
				options={data.categories}
				icon={<HelpOutlineIcon />}
				setSelected={value => onFilter({ ...params, categories: value })}
			/>
			{data.subcategories.length > 0 && (
				<StringListFilterComponent
					label={strings.subcategory}
					selected={params.subcategories}
					options={data.subcategories}
					icon={<HelpOutlineIcon />}
					setSelected={value => onFilter({ ...params, subcategories: value })}
				/>
			)}
		</FilterBar>
	);
};
