import { Button, Card, CardMedia, IconButton, Popover, TextField, useScrollTrigger, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { StaticDatePicker } from '@material-ui/pickers';
import { Eventcalendar, localeEn, localeFr, localeNl, MbscCalendarEvent, MbscEventcalendarView } from '@mobiscroll/react';
import { addHours, parse } from 'date-fns';
import { SelectButtonWithMenu } from 'framework/components/buttons/SelectButtonWithMenu';
import { CalendarPlusIcon } from 'framework/components/icons/CalendarPlusIcon';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Try } from 'framework/Try';
import { parseDateOrDefault } from 'framework/utils/date/parseDateOrDefault';
import { agendaCommand_move, ICalendarEventDto, ICalendarEventMoveModel, IValidateResponse } from 'gen/ApiClient';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RestrictedPage } from 'shared/layout/RestrictedPage';
import { getNow } from 'shared/utils/getNow';
import { calculateNextDate } from '../calculateNextDate';
import { calculatePreviousDate } from '../calculatePreviousDate';
import { CalendarRoomsFilterListComponent } from '../CalendarRoomsFilterListComponent';
import { SearchEventsRoute } from '../calendarRoutes';
import { CalendarUsersFilterListComponent } from '../CalendarUsersFilterListComponent';
import { EmptyGroupKey } from '../EmptyGroupKey';
import { CreateCalendarEventForm } from '../forms/CreateCalendarEventForm';
import { renderDateDisplay } from '../renderDateDisplay';
import { AgendaEventTypesFilterListComponent } from './AgendaEventTypesFilterListComponent';
import { AgendaGroupScheme } from './AgendaGroupScheme';
import { AgendaViewType } from './AgendaViewType';
import { AgendaViewContext } from './context/AgendaViewContext';
import { useAgendaContext } from './context/useAgendaContext';
import { EventPopoverContent } from './EventPopoverContent';
import { EventListComponent } from './list/EventListComponent';
import { mapAgendaViewTypeToMobiscrollView } from './mapAgendaViewTypeToMobiscrollView';
import { mapEventToMobiscrollCalendarEvent } from './mapEventToMobiscrollCalendarEvent';
import { mapResourcesToMobiscrollResources } from './mapResourcesToMobiscrollResources';
import { AgendaSettingsDialog } from './settings/AgendaSettingsDialog';

export const AgendaPage = () => {
	const theme = useTheme();
	const { push } = useHistory();
	const strings = useLocalization();
	const language = useCurrentLanguage();
	const mobiscrollLocale = useMemo(() => (language === 'nl' ? localeNl : language === 'fr' ? localeFr : localeEn), [language]);
	const [scrollTarget, setScrollTarget] = useState<HTMLDivElement | undefined>(undefined);
	const trigger = useScrollTrigger({ target: scrollTarget, disableHysteresis: true, threshold: 0 });
	const [overflowY, setOverflowY] = useState<'hidden' | 'overlay'>('hidden');
	const {
		view,
		setView,
		date,
		setDate,
		groupScheme,
		setGroupScheme,
		colorScheme,
		setColorScheme,
		selectedEventTypeResourceKeys,
		setSelectedEventTypeResourceKeys,
		selectedUserResourceKeys,
		setSelectedUserResourceKeys,
		selectedRoomResourceKeys,
		setSelectedRoomResourceKeys,
		reload,
		rooms,
		users,
		eventTypes,
		events,
		beforeDate,
		timeScale,
	} = useAgendaContext();
	const [submitMove] = useFormSubmit(agendaCommand_move);
	const { agendaViewTypeRecord } = useContext(AgendaViewContext);
	const { open, confirm } = useDialogsContext(reload);
	const mobiscrollAgendaView = useMemo<MbscEventcalendarView>(() => mapAgendaViewTypeToMobiscrollView(view, timeScale), [view, timeScale]);

	// only grouping certain views
	const canGroup = useMemo(() => view === 'day' || view === 'week' || view === 'timeline', [view]);
	const defaultedGroupScheme = useMemo<AgendaGroupScheme>(() => (canGroup ? groupScheme : 'none'), [groupScheme, canGroup]);

	const mappedEvents = useMemo(
		() => events.map(t => mapEventToMobiscrollCalendarEvent(t, defaultedGroupScheme, colorScheme)),
		[events, defaultedGroupScheme, colorScheme]
	);
	const mappedResources = useMemo(
		() =>
			mapResourcesToMobiscrollResources(
				defaultedGroupScheme,
				users.filter(t => selectedUserResourceKeys.indexOf(t.key) !== -1),
				rooms.filter(t => selectedRoomResourceKeys.indexOf(t.key) !== -1)
			),
		[defaultedGroupScheme, users, rooms, selectedUserResourceKeys, selectedRoomResourceKeys]
	);

	// TOOLTIP
	const [anchor, setAnchor] = React.useState<any>(undefined);
	const [selectedEvent, setSelectedEvent] = useState<MbscCalendarEvent>();

	const onNew = (startDate: Date, endDate: Date, isAllDay: boolean, userGroupKey?: string, roomGroupKey?: string) => {
		open(
			<CreateCalendarEventForm
				open
				confirm={confirm}
				cancel={confirm} //force reload
				startDate={startDate}
				endDate={endDate}
				isAllDay={isAllDay}
				roomGroupKey={roomGroupKey}
				userGroupKey={userGroupKey}
			/>
		);
	};

	const onSettings = () => {
		open(
			<AgendaSettingsDialog
				open
				close={confirm}
			/>
		);
	};

	const toggleGroupScheme = (val: AgendaGroupScheme) => {
		setGroupScheme(groupScheme === val ? 'none' : val);
	};

	const onEventDragged = (mbscEvent: MbscCalendarEvent, event: ICalendarEventDto, groupScheme: AgendaGroupScheme) => {
		if (event.isRecurring) {
			open(
				<NotificationDialog
					open
					close={confirm}
					title={strings.invalidAction}
					content={strings.notPossibleToMoveARecurringAppointment}
				/>
			);
		} else {
			open(
				<AlertDialog
					open
					title={strings.moveAppointmentQuestion}
					content={strings.wantToMoveAppointmenToAnotherTimeUserLocationQuestion}
					acceptText={strings.yes}
					rejectText={strings.noCommaGoBack}
					reject={confirm}
					acceptF={async () => await onEventMoveConfirmed(groupScheme, event, mbscEvent)}
					accept={confirm}
				/>
			);
		}
	};

	const onEventMoveConfirmed = async (
		groupScheme: AgendaGroupScheme,
		event: ICalendarEventDto,
		mbscEvent: MbscCalendarEvent
	): Promise<Try<IValidateResponse>> => {
		const roomId: string = groupScheme !== 'rooms' ? event.roomId : mbscEvent.resource === EmptyGroupKey ? undefined : (mbscEvent.resource as any);
		const userIds: string[] = groupScheme !== 'users' ? event.userIds : (mbscEvent.resource as string[]).filter(t => t !== EmptyGroupKey);
		const model: ICalendarEventMoveModel = {
			startDate: mbscEvent.start as any,
			endDate: mbscEvent.end as any,
			roomId: roomId,
			userIds: userIds,
			isAllDay: event.isAllDay,
		};
		return await submitMove(event.id, model);
	};

	return (
		<RestrictedPage
			pageClaim='Calendar'
			breadcrumbs={[{ text: strings.calendar, icon: <TodayOutlinedIcon /> }]}
			breadCrumbsContainerStyle={{ width: '277px' }}
			title={strings.schedule}
			reload={reload}
			settings={onSettings}
			detailsFixedWidth={286}
			fullHeight
			styleDetailsContent={{ height: '100%', paddingLeft: 0, paddingRight: 0 }}
			styleRightContent={{ width: `calc(100% - 286px)` }}
			header={
				<div className='df-row-ac fg1'>
					<Button
						variant='outlined'
						onClick={() => setDate(getNow())}
						style={{ marginRight: 8 }}>
						{strings.today}
					</Button>
					<IconButton
						size='small'
						onClick={() => setDate(calculatePreviousDate(date, view))}>
						<ChevronLeftIcon />
					</IconButton>
					<IconButton
						size='small'
						onClick={() => setDate(calculateNextDate(date, view))}>
						<ChevronRightIcon />
					</IconButton>
					<div style={{ fontSize: '22px' }}>{renderDateDisplay(date, view, strings, language)}</div>
					<div className='fg1'></div>
					<SelectButtonWithMenu<AgendaViewType>
						options={Object.keys(agendaViewTypeRecord) as AgendaViewType[]}
						record={agendaViewTypeRecord}
						state={view}
						setState={setView}
					/>
					<IconButton
						onClick={() => push(SearchEventsRoute)}
						edge='end'>
						<SearchIcon />
					</IconButton>
				</div>
			}
			details={
				<div
					onMouseEnter={() => setOverflowY('overlay')}
					onMouseLeave={() => setOverflowY('hidden')}
					ref={node => setScrollTarget(node ?? undefined)}
					style={{ height: '100%', overflowY: overflowY as any, paddingLeft: 16, paddingRight: 8 }}>
					<div
						style={{
							position: 'sticky',
							top: 0,
							left: 0,
							backgroundColor: theme.palette.background.default,
							zIndex: 3,
							borderBottom: trigger ? `1px solid ${theme.palette.divider}` : 'none',
							marginBottom: 1,
						}}>
						<Button
							variant='contained'
							className='w100'
							color='primary'
							style={{ marginBottom: 16 }}
							startIcon={<CalendarPlusIcon />}
							onClick={() => onNew(parse('12:00', 'HH:mm', date), parse('13:00', 'HH:mm', date), false)}>
							{strings.new}
						</Button>
					</div>
					<Card
						className='df-col'
						style={{ paddingBottom: 8, marginBottom: 1 }}>
						<div className='small-date-picker'>
							<StaticDatePicker
								className='small-date-picker'
								disableHighlightToday
								displayStaticWrapperAs='desktop'
								openTo='date'
								open
								value={date}
								onChange={newValue => setDate(parseDateOrDefault(newValue))}
								views={['date']}
								renderInput={props => (
									<TextField
										{...props}
										variant='standard'
									/>
								)}
							/>
						</div>
						<AgendaEventTypesFilterListComponent
							data={eventTypes}
							selectedKeys={selectedEventTypeResourceKeys}
							setSelectedKeys={setSelectedEventTypeResourceKeys}
							isColorSchemeSelected={colorScheme === 'types'}
							selectColorScheme={() => setColorScheme('types')}
							style={{ marginBottom: 16 }}
						/>
						<CalendarUsersFilterListComponent
							data={users}
							selectedKeys={selectedUserResourceKeys}
							setSelectedKeys={setSelectedUserResourceKeys}
							isColorSchemeSelected={colorScheme === 'users'}
							selectColorScheme={() => setColorScheme('users')}
							isGroupSelected={defaultedGroupScheme === 'users'}
							selectGroup={() => toggleGroupScheme('users')}
							canGroup={canGroup}
							style={{ marginBottom: 16 }}
							showSecondaryColors={defaultedGroupScheme === 'rooms'}
						/>
						<CalendarRoomsFilterListComponent
							data={rooms}
							selectedKeys={selectedRoomResourceKeys}
							setSelectedKeys={setSelectedRoomResourceKeys}
							isColorSchemeSelected={colorScheme === 'rooms'}
							selectColorScheme={() => setColorScheme('rooms')}
							isGroupSelected={defaultedGroupScheme === 'rooms'}
							selectGroup={() => toggleGroupScheme('rooms')}
							canGroup={canGroup}
							showSecondaryColors={defaultedGroupScheme === 'users'}
						/>
					</Card>
				</div>
			}>
			<Card style={{ height: view !== 'timeline' && view !== 'list' ? '100%' : 'unset' }}>
				{view !== 'list' && (
					<>
						{Boolean(anchor) && selectedEvent && (
							<Popover
								open={Boolean(anchor)}
								anchorEl={anchor}
								onClose={() => setAnchor(undefined)}>
								<EventPopoverContent
									event={selectedEvent.data}
									instanceStartDate={selectedEvent.start as any}
									instanceEndDate={selectedEvent.end as any}
									colorScheme={colorScheme}
									close={() => setAnchor(undefined)}
									reload={reload}
								/>
							</Popover>
						)}
						<Eventcalendar
							data={mappedEvents}
							showControls={false} // <- do this to remove all the controls
							showEventTooltip={false}
							locale={mobiscrollLocale}
							selectedDate={date}
							theme='material'
							themeVariant='light'
							clickToCreate={false}
							dragToCreate={true}
							dragToMove={true}
							dragToResize={true}
							eventDelete={false}
							resources={mappedResources}
							onCellDoubleClick={(args, inst) => {
								console.log(args);
								console.log(inst);
								if (args.source === 'calendar') {
									// do something else... TODO
								}
								onNew(
									args.date,
									addHours(args.date, 1),
									false,
									defaultedGroupScheme === 'users' ? (args.resource as any) : undefined,
									defaultedGroupScheme === 'rooms' ? (args.resource as any) : undefined
								);
							}}
							onEventDragEnd={args => {
								if (args.event.data) {
									onEventDragged(args.event, args.event.data as any, defaultedGroupScheme);
								} else {
									onNew(
										args.event.start as any,
										args.event.end as any,
										false,
										defaultedGroupScheme === 'users' ? (args.resource as any) : undefined,
										defaultedGroupScheme === 'rooms' ? (args.resource as any) : undefined
									);
								}
							}}
							onEventClick={args => {
								setSelectedEvent(args.event);
								setAnchor(args.domEvent.target);
							}}
							view={mobiscrollAgendaView}
						/>
					</>
				)}
				{view === 'list' && (
					<CardMedia style={{ paddingLeft: 12, paddingRight: 12 }}>
						<EventListComponent
							beforeDate={beforeDate}
							events={events}
							afterDate={date}
							reload={reload}
							colorScheme={colorScheme}
						/>
					</CardMedia>
				)}
			</Card>
		</RestrictedPage>
	);
};
