import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISettlementProposal } from 'gen/ApiClient';

interface IPaymentLinesTableProps {
	settlementProposal: ISettlementProposal;
	style?: React.CSSProperties;
}

export const SettlementProposalLinesTable = ({ settlementProposal, style }: IPaymentLinesTableProps) => {
	return (
		<Table style={style}>
			{/* <colgroup>
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
                <col style={{ width: '100px' }} />
                <col style={{ width: 'auto' }} />
            </colgroup> */}
			<TableHead>
				<TableRow>
					<TableCell>Datum verkoop</TableCell>
					<TableCell>Ref. verkoop</TableCell>
					<TableCell>Tav (factuur)</TableCell>
					<TableCell>Tav (patiënt)</TableCell>
					<TableCell align='right'>Amount</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{settlementProposal.lines.map(t => (
					<TableRow
						key={t.id}
						style={{ textDecoration: settlementProposal.isHandled && t.isPayed === false ? 'line-through' : 'inherit' }}>
						<TableCell>{formatDate(t.salesDate)}</TableCell>
						<TableCell>{t.salesReference}</TableCell>
						<TableCell>{t.salesRecipientName}</TableCell>
						<TableCell>{t.referencePatient?.identifier}</TableCell>
						<TableCell align='right'>{formatCurrency(t.value)}</TableCell>
					</TableRow>
				))}
				<TableRow style={{ textDecoration: settlementProposal.isPartlyPaid ? 'line-through' : 'inherit' }}>
					<TableCell
						colSpan={3}
						style={{ borderBottom: 'none' }}></TableCell>
					<TableCell style={{ fontWeight: 'bold' }}>Totaal</TableCell>
					<TableCell
						colSpan={5}
						align='right'
						style={{ fontWeight: 'bold' }}>
						{formatCurrency(settlementProposal.totalValue)}
					</TableCell>
				</TableRow>
				{settlementProposal.isHandled && (
					<TableRow>
						<TableCell
							colSpan={3}
							style={{ borderBottom: 'none' }}></TableCell>
						<TableCell style={{ fontWeight: 'bold' }}>Totaal betaald</TableCell>
						<TableCell
							colSpan={5}
							align='right'
							style={{ fontWeight: 'bold' }}>
							{formatCurrency(settlementProposal.amountPaid)}
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
};
