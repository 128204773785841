import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { ICustomerAccountLineData } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: ICustomerAccountLineData;
	style?: React.CSSProperties;
}

export const SalesDetailAccountReceivableStatusIcon = ({ item, style }: IProps) => {
	if (item.isFullyPaid) {
		return <CheckCircleOutlineIcon style={style} />;
	} else if (item.isPartlyPaid) {
		return <RadioButtonCheckedIcon style={style} />;
	} else {
		return <RadioButtonUncheckedIcon style={style} />;
	}
};
