import React from 'react';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { FormSelectField } from 'framework/forms/FormSelectField';
import { VatPercentages } from '../VatPercentages';

export const FormSelectVatPercentageField = <TModel extends unknown>({ ...props }: IFormProps<TModel>) => {
	return (
		<FormSelectField<TModel, number>
			{...props}
			options={VatPercentages}
			getKey={t => t.toString()}
			renderValue={t => `${t}%`}
		/>
	);
};
