import { DialogContent, DialogProps } from '@material-ui/core';
import { FormSelectOrCreateCompanyFieldWithSelector } from 'app/main/contacts/companies/forms/FormSelectOrCreateCompanyFieldWithSelector';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { mapWithIndexFilterIsSelected } from 'framework/utils/array/mapWithIndexFilterIsSelected';
import {
	ICreateTenderFromRfiaRequest,
	IMedicalTreatmentFeeDto,
	IRequestForInsuranceAllowance,
	ValidUntilTimeUnits,
	tendersCommand_newFromRfia,
	tendersQuery_nextReference,
	tendersQuery_settings,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { FormRecipientField } from 'shared/forms/FormRecipientField';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useWithRecipientInterceptor } from 'shared/searchAndSelect/useWithRecipientInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { FeesTable } from '../../../processes/requestsForInsuranceAllowance/forms/handover/FeesTable';
import { ILine } from './ILine';
import { SearchAndSelectProductsForTender } from './SearchAndSelectProductsForTender';
import { SearchProvider } from './context/SearchProvider';
import { lineToModel } from './utils/lineToModel';

const createSchema = (strings: IStrings, mustSelectCompany: boolean) => {
	return yup
		.object<ICreateTenderFromRfiaRequest>({
			rfiaId: yup.string().required(),
			validUntil: yup.date().required(),
			expectedHandoverDate: yup.date().required(),
			lines: yup.mixed(),
			reference: yup.string().required(),
			isAutoSelectReference: yup.boolean().defined(),
			zip: yup.string(),
			addressLine: yup.string(),
			city: yup.string(),
			companyId: mustSelectCompany ? yup.string().required() : yup.string(),
			country: yup.string(),
			recipientName: yup.string().required(),
			fees: yup.mixed(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof ICreateTenderFromRfiaRequest)[]> = {
	0: ['reference', 'isAutoSelectReference', 'validUntil'],
	1: ['companyId'],
	2: ['recipientName', 'addressLine', 'city', 'zip', 'country'],
	3: ['fees'],
	4: ['lines'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	rfia: IRequestForInsuranceAllowance;
}

export const RfiaTenderForm = ({ confirm, cancel, rfia, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, setLines] = useState<ILine[]>([]);
	const [step, setStep] = useState<number>(0);
	const [isSelectCompany, setIsSelectCompany] = useState<boolean>(false);
	const [create, isSubmitting] = useFormSubmit(tendersCommand_newFromRfia);
	const notify = useSnackbarNotify();
	const schema = useMemo(() => createSchema(strings, isSelectCompany), [strings, isSelectCompany]);
	const [fees, setFees] = useState<IMedicalTreatmentFeeDto[]>([]);
	const now = useNow();

	const handleSubmit = async (values: ICreateTenderFromRfiaRequest, helpers: FormikHelpers<ICreateTenderFromRfiaRequest>) => {
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await create({ ...values, lines: mapWithIndexFilterIsSelected(lines).map(lineToModel), fees: fees });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify(strings.addedWhat(strings.tender));
				confirm(r.result.objectId);
			}
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<ICreateTenderFromRfiaRequest>
			validateOnMount
			initialValues={{
				rfiaId: rfia.id,
				lines: [],
				reference: '',
				isAutoSelectReference: true,
				city: '',
				companyId: '',
				addressLine: '',
				zip: '',
				country: '',
				recipientName: '',
				validUntil: undefined as any,
				fees: [],
				expectedHandoverDate: now,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						lines={lines}
						setLines={setLines}
						step={step}
						setStep={setStep}
						stepsRecord={stepsRecord}
						isSelectCompany={isSelectCompany}
						setIsSelectCompany={setIsSelectCompany}
						fees={fees}
						setFees={setFees}
						rfia={rfia}
						schema={schema}
					/>
				</SearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	lines: ILine[];
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	stepsRecord: Record<number, (keyof ICreateTenderFromRfiaRequest)[]>;
	isSelectCompany: boolean;
	setIsSelectCompany: React.Dispatch<React.SetStateAction<boolean>>;
	schema: yup.ObjectSchema<ICreateTenderFromRfiaRequest>;
	fees: IMedicalTreatmentFeeDto[];
	setFees: React.Dispatch<React.SetStateAction<IMedicalTreatmentFeeDto[]>>;
	rfia: IRequestForInsuranceAllowance;
}

const InnerDialog = ({
	cancel,
	lines,
	setLines,
	isSubmitting,
	step,
	setStep,
	stepsRecord,
	isSelectCompany,
	setIsSelectCompany,
	schema,
	fees,
	setFees,
	rfia,
	...rest
}: IInnerDialogProps) => {
	const props = useFormikContext<ICreateTenderFromRfiaRequest>();
	const strings = useLocalization();
	useNextReferenceInterceptor(tendersQuery_nextReference, props);
	const [settings] = useApiEffect(tendersQuery_settings);
	const [isUseContactPerson, setIsUseContactPerson] = useState<boolean>(false);
	useWithRecipientInterceptor<ICreateTenderFromRfiaRequest>(props, isSelectCompany, isUseContactPerson, rfia.patientId);

	if (settings === undefined) {
		return <div></div>;
	}

	return (
		<FullScreenDialogWithStepper
			{...rest}
			fullScreenStep={4}
			title={strings.newWhat(strings.tender)}
			step={step}
			labels={[strings.referenceShort, strings.vatCustomer, strings.withRespectToShort, strings.contributions, strings.items]}
			maxWidth='lg'>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<ICreateTenderFromRfiaRequest>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<ICreateTenderFromRfiaRequest>
							name='reference'
							label={strings.reference}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormDatePickerWithUnit<ICreateTenderFromRfiaRequest>
							name='validUntil'
							label={strings.validTo}
							required
							units={ValidUntilTimeUnits}
							defaultUnit='Months'
							defaultValue={settings.defaultValidUntil}
						/>
					</>
				)}
				{step === 1 && (
					<FormSelectOrCreateCompanyFieldWithSelector<ICreateTenderFromRfiaRequest>
						isSelectCompany={isSelectCompany}
						setIsSelectCompany={setIsSelectCompany}
						patientId={rfia.patientId}
					/>
				)}
				{step === 2 && (
					<FormRecipientField<ICreateTenderFromRfiaRequest>
						patientId={rfia.patientId}
						isUseContactPerson={isUseContactPerson}
						setIsUseContactPerson={setIsUseContactPerson}
					/>
				)}
				{step === 3 && (
					<>
						<FormDatePicker<ICreateTenderFromRfiaRequest>
							name='expectedHandoverDate'
							label={strings.expectedHandoverDate}
							helperText={strings.thisDateWillBeUsedToCalculateNomenclaturePrices}
						/>
						{props.values.expectedHandoverDate !== undefined && (
							<>
								<DividerWithCaption caption={strings.refunds} />
								<FeesTable
									rfia={rfia}
									handOverDate={props.values.expectedHandoverDate}
									fees={fees}
									setFees={setFees}
								/>
							</>
						)}
					</>
				)}
				{step === 4 && (
					<SearchAndSelectProductsForTender
						lines={lines}
						setLines={setLines}
						fees={fees}
						patient={undefined}
						hideNomenclatures
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
				validateMore={() => (step === 3 ? fees.length === rfia.allMedicalTreatmentNomenclatureCodes.length : true)}
			/>
		</FullScreenDialogWithStepper>
	);
};
