import { Divider, Typography, useTheme } from '@material-ui/core';
import React from 'react';

interface IProps {
	caption: string | JSX.Element | undefined;
	style?: React.CSSProperties;
	spacingTop?: number;
	spacingBottom?: number;
	action?: React.ReactNode;
	captionStyle?: React.CSSProperties;
	dividerStyle?: React.CSSProperties;
}

export const DividerWithCaption = ({ caption, spacingTop = 2, spacingBottom = 2, style, action, captionStyle, dividerStyle }: IProps) => {
	const theme = useTheme();

	return (
		<div
			className='df-row-ac'
			style={{ paddingTop: theme.spacing(spacingTop), paddingBottom: theme.spacing(spacingBottom), position: 'relative', ...style }}>
			<Divider
				className='fg1'
				style={dividerStyle}
			/>
			{caption !== undefined && (
				<Typography
					variant='caption'
					style={{
						textTransform: 'uppercase',
						paddingLeft: theme.spacing(1.5),
						paddingRight: theme.spacing(1.5),
						color: theme.palette.text.secondary,
						...captionStyle,
					}}>
					{caption}
				</Typography>
			)}
			<Divider
				className='fg1'
				style={dividerStyle}
			/>
			{action && <div style={{ backgroundColor: theme.palette.background.paper, position: 'absolute', right: theme.spacing(1) }}>{action}</div>}
		</div>
	);
};
