import { PageX } from 'framework/components/layout/PageX';
import { useTrigger } from 'framework/hooks/useTrigger';
import { useNavigationLinkRouting } from 'framework/router/useNavigationLinkRouting';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as routes from '../routes';
import { NoahDevicesPageHeader } from './NoahDevicesPageHeader';
import { NoahDevicesRouteRecord } from './NoahDevicesRouteRecord';
import { NoahDevicesTabType } from './NoahDevicesTabType';
import { NoahDeviceReadOutsList } from './rules/NoahDeviceReadOutsList';
import { NoahDevicesList } from './single/NoahDevicesList';

export const NoahDevicesPage = () => {
	const [trigger, hitTrigger] = useTrigger();
	const [tab, setTab] = useNavigationLinkRouting<NoahDevicesTabType>(NoahDevicesRouteRecord, 'rules');

	return (
		<PageX
			stickyHeader={
				<NoahDevicesPageHeader
					reload={hitTrigger}
					tab={tab}
					setTab={setTab}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					// maxWidth: 1280,
				}}>
				<Switch>
					<Route
						exact
						path={routes.NoahDevicesRulesRoute}
						render={() => <NoahDeviceReadOutsList reloadTrigger={trigger} />}
					/>
					<Route
						exact
						path={routes.NoahDevicesReadOutsRoute}
						render={() => <NoahDevicesList reloadTrigger={trigger} />}
					/>
					<Redirect to={routes.NoahDevicesRulesRoute} />
				</Switch>
			</div>
		</PageX>
	);
};
