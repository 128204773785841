import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IStrings } from 'localization/IStrings';
import { ICustomerAccountLineWithSelection } from './ICustomerAccountLineWithSelection';

export const createHeaders = (
	lines: ICustomerAccountLineWithSelection[],
	filtered: ICustomerAccountLineWithSelection[],
	setLines: React.Dispatch<React.SetStateAction<ICustomerAccountLineWithSelection[]>>,
	strings: IStrings
): IHeader<ICustomerAccountLineWithSelection>[] => {
	return [
		createSelectionHeader<ICustomerAccountLineWithSelection>(filtered, newLines => {
			const x = lines.map(line => ({ ...line, isSelected: newLines.find(x => x.id === line.id)?.isSelected ?? line.isSelected }));
			setLines(x);
		}),
		createSortableHeader<ICustomerAccountLineWithSelection>(strings.date, 'salesDate', t => formatDate(t.salesDate)),
		createSortableHeader<ICustomerAccountLineWithSelection>(strings.reference, 'salesReference', t => t.salesReference),
		createSortableHeader<ICustomerAccountLineWithSelection>(strings.balance, 'outstandingBalance', t => formatCurrency(t.outstandingBalance)),
		createSortableHeader<ICustomerAccountLineWithSelection>(strings.withRespectToShort, 'salesRecipientName', t => t.salesRecipientName),
		createSortableHeader<ICustomerAccountLineWithSelection>(strings.patient, 'referencePatientIdentifier', t => t.referencePatientIdentifier),
	];
};
