import { CardProps, Divider } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { IntegerNumberModelForm } from 'framework/forms/IntegerNumberModelForm';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import {
	ExactOnlineGLAccountMatchMode,
	IExactOnlineSettingsDto,
	exactOnlineSettingsCommand_patchBaseUrl,
	exactOnlineSettingsCommand_patchDivision,
	exactOnlineSettingsCommand_patchJournal,
	meta_url,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { SetExactOnlineGLAccountMatchModeRequestForm } from './SetExactOnlineGLAccountMatchModeRequestForm';
import { LinkFallbackGLAccountRequestDialog } from './fallback/LinkFallbackGLAccountRequestDialog';

interface IProps extends IReloadTriggerProps, CardProps {
	settings: IExactOnlineSettingsDto;
	reload: VoidFunction;
}

const createGLAccountMatchModeRecord = (strings: IStrings): Record<ExactOnlineGLAccountMatchMode, string> => {
	return {
		UseFallback: strings.useFallbackCodes,
		UseProductCatalog: strings.useCodeFromProductCatalog,
	};
};

export const ExactOnlineOtherSettingsCardX = ({ settings, reloadTrigger, reload, ...rest }: IProps) => {
	const strings = useLocalization();
	const [requestUrl] = useApiEffect(meta_url);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const glAccountMatchModeRecord = React.useMemo(() => createGLAccountMatchModeRecord(strings), [strings]);

	const onPatchBaseUrl = () => {
		open(
			<StringModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.changeSomething(`URL`)}
				label={`URL`}
				initialValue={requestUrl ?? ''}
				cancel={cancel}
				submitText={strings.update}
				submitFunction={value => exactOnlineSettingsCommand_patchBaseUrl({ baseUrl: value })}
			/>
		);
	};

	const onPatchJournal = () => {
		open(
			<StringModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.changeSomething(`Dagboek`)}
				label={`Dagboek`}
				initialValue={settings.journal ?? '701'}
				cancel={cancel}
				submitText={strings.update}
				submitFunction={value => exactOnlineSettingsCommand_patchJournal({ journal: value })}
			/>
		);
	};

	const onPatchDivision = () => {
		open(
			<IntegerNumberModelForm
				open
				fullWidth
				confirm={confirm}
				formTitle={strings.changeSomething(`Division`)}
				label={`Division`}
				initialValue={settings.division ?? -1}
				cancel={cancel}
				submitFunction={value => exactOnlineSettingsCommand_patchDivision({ division: value })}
			/>
		);
	};

	const onPatchFallbackGLAccount = (vatPercentage: number) => {
		open(
			<LinkFallbackGLAccountRequestDialog
				open
				vatPercentage={vatPercentage}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onPatchGLAccountMatchMode = () => {
		open(
			<SetExactOnlineGLAccountMatchModeRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				item={settings}
			/>
		);
	};

	return (
		<CardX
			{...rest}
			className='df-col gap-8'>
			<Typography16pxBold>{strings.otherSettings}</Typography16pxBold>
			<LabelledPropertyX
				label='URL'
				icon={<StatusIcon status={settings.hasBaseUrl ? 'success' : 'warning'} />}
				iconPosition='start'
				edit={onPatchBaseUrl}>
				{settings.hasBaseUrl ? settings.baseUrl : <em>{strings.notSet}</em>}
			</LabelledPropertyX>
			<Divider />
			<LabelledPropertyX
				label='Division'
				icon={<StatusIcon status={settings.hasDivision ? 'success' : 'warning'} />}
				iconPosition='start'
				edit={onPatchDivision}>
				{settings.hasDivision ? settings.division : <em>{strings.notSet}</em>}
			</LabelledPropertyX>
			<LabelledPropertyX
				label='Dagboek'
				icon={<StatusIcon status={settings.hasJournal ? 'success' : 'warning'} />}
				iconPosition='start'
				edit={onPatchJournal}>
				{settings.hasJournal ? settings.journal : <em>{strings.notSet}</em>}
			</LabelledPropertyX>
			<LabelledPropertyX
				label={strings.generalLedgerAccountAllocationMethod}
				icon={<StatusIcon status={'success'} />}
				iconPosition='start'
				edit={onPatchGLAccountMatchMode}>
				{glAccountMatchModeRecord[settings.glAccountMatchMode as ExactOnlineGLAccountMatchMode]}
			</LabelledPropertyX>
			<Divider />
			<LabelledPropertyX
				label={`0% ${strings.generalLedger.toLowerCase()}`}
				icon={<StatusIcon status={settings.has0PercentFallbackGLAccount ? 'success' : 'error'} />}
				iconPosition='start'
				edit={() => onPatchFallbackGLAccount(0)}>
				{settings.has0PercentFallbackGLAccount ? settings.fallbackGLAccountCode0Percent : <em>{strings.notSet}</em>}
			</LabelledPropertyX>
			<LabelledPropertyX
				label={`6% ${strings.generalLedger.toLowerCase()}`}
				icon={<StatusIcon status={settings.has6PercentFallbackGLAccount ? 'success' : 'error'} />}
				iconPosition='start'
				edit={() => onPatchFallbackGLAccount(6)}>
				{settings.has6PercentFallbackGLAccount ? settings.fallbackGLAccountCode6Percent : <em>{strings.notSet}</em>}
			</LabelledPropertyX>
			<LabelledPropertyX
				label={`21% ${strings.generalLedger.toLowerCase()}`}
				icon={<StatusIcon status={settings.has21PercentFallbackGLAccount ? 'success' : 'error'} />}
				iconPosition='start'
				edit={() => onPatchFallbackGLAccount(21)}>
				{settings.has21PercentFallbackGLAccount ? settings.fallbackGLAccountCode21Percent : <em>{strings.notSet}</em>}
			</LabelledPropertyX>
		</CardX>
	);
};
