import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

interface IModel {
	unitPrice: number;
}

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			unitPrice: yup.number().max(0).required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	unitPrice: number;
	confirm: (unitPrice: number) => void;
	cancel: VoidFunction;
	forPurchase?: boolean;
}

export const DiscountForm = ({ unitPrice, confirm, cancel, forPurchase = false, ...rest }: IProps) => {
	const strings = useLocalization();

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		confirm(values.unitPrice);
	};

	return (
		<Formik<IModel>
			validateOnMount
			validationSchema={createSchema(strings)}
			initialValues={{
				unitPrice: unitPrice,
			}}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.changePrices}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormMoneyField<IModel>
							name='unitPrice'
							label={forPurchase === true ? strings.purchasePrice : strings.salesPrice}
							allowNegative
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.changePrices}
						isSubmitting={false}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
