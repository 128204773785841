import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField } from 'formik';
import React, { useState } from 'react';
import { nameOf } from '../utils/nameOf';
import { FormErrorHelper } from './common/FormErrorHelper';
import { IFormProps } from './common/IFormProps';
import { FormTextField } from './FormTextField';

interface IProps<TModel> extends IFormProps<TModel> {
	options: string[];
}

export const FormAutocompleteFreeSoloTextField = <TModel extends unknown>({ name, options, disabled, ...props }: IProps<TModel>) => {
	const [field, meta, helpers] = useField<string>(nameOf(name));
	const [inputVal, setInputVal] = useState<string>(field.value);

	if (options === null || options === undefined || options.length === 0) {
		return (
			<FormTextField
				name={name}
				disabled={disabled}
				{...props}
			/>
		);
	}

	return (
		<FormControl
			variant='filled'
			error={meta.error && meta.touched ? true : false}>
			<Autocomplete
				freeSolo
				options={options}
				autoSelect
				autoHighlight
				forcePopupIcon
				selectOnFocus
				value={field.value ?? ''}
				onChange={(e, value) => {
					helpers.setTouched(true);
					helpers.setValue(value === null ? undefined : (value as any));
				}}
				inputValue={inputVal}
				onInputChange={(e, value, reason) => {
					setInputVal(value);
					// disable validation ico of deleting and type a new value
					if (reason === 'input') {
						helpers.setTouched(false);
					}
				}}
				onClose={(_, reason) => {
					if (reason === 'blur') {
						helpers.setTouched(true);
						helpers.setValue(inputVal);
					}
				}}
				disabled={disabled}
				renderInput={params => (
					<TextField
						variant='filled'
						{...params}
						{...props}
						error={meta.error && meta.touched ? true : false}
					/>
				)}
			/>
			<FormErrorHelper meta={meta} />
		</FormControl>
	);
};
