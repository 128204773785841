import React from 'react';
import { TabsX } from './TabsX';
import { TabX } from './TabX';

interface IProps<T extends string> {
	tabs: T[];
	selectedTab: T;
	setSelectedTab: (tab: T) => void;
	startIconRecord?: Record<T, React.ReactNode | undefined>;
	titleRecord: Record<T, string>;
	endIconRecord?: Record<T, React.ReactNode | undefined>;
	style?: React.CSSProperties;
}

export const HorizontalTabsX = <T extends string>({ tabs, selectedTab, setSelectedTab, startIconRecord, titleRecord, endIconRecord, style }: IProps<T>) => {
	return (
		<TabsX style={style}>
			{tabs.map(tab => (
				<TabX
					key={tab}
					isSelected={tab === selectedTab}
					onClick={() => setSelectedTab(tab)}
					startIcon={startIconRecord && startIconRecord[tab]}
					endIcon={endIconRecord && endIconRecord[tab]}
					value={titleRecord[tab]}
				/>
			))}
		</TabsX>
	);
};
