import React from 'react';
import { HeaderTableCellXProps } from './HeaderTableCellXProps';
import { TableCellX } from './TableCellX';

export const HeaderTableCellX = (props: HeaderTableCellXProps) => {
	return (
		<TableCellX
			removeBorder
			{...props}
		/>
	);
};
