import { Checkbox, Dialog, DialogContent, DialogProps, DialogTitle, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { toggleSelect } from 'framework/utils/array/toggleSelect';
import { ClientClaim, ClientClaims, IPatchClientClaimsRequest, applicationSettingsCommand_clientClaims } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { ClientClaimRecord } from '../ClientClaimRecord';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchClientClaimsRequest>({
		clientClaims: yup.mixed(),
	});
};

interface IProps extends DialogProps {
	confirm: () => void;
	cancel: VoidFunction;
	clientClaims: ClientClaim[];
}

export const PatchClientClaimsRequestForm = ({ confirm, cancel, clientClaims, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(applicationSettingsCommand_clientClaims);
	const notify = useSnackbarNotify();
	const schema = useMemo(() => createSchema(strings), [strings]);
	const [selectedClientClaims, setSelectedClientClaims] = useState<ClientClaim[]>(clientClaims);

	// useEffect(() => {
	//     setSelectedClientClaims(clientClaims);
	// }, [clientClaims]);

	const handleSubmit = async (values: IPatchClientClaimsRequest, helpers: FormikHelpers<IPatchClientClaimsRequest>) => {
		values = { ...values, clientClaims: selectedClientClaims };
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			notify(`Application settings patched`);
			confirm();
		}
	};

	return (
		<Formik<IPatchClientClaimsRequest>
			validateOnMount
			initialValues={{
				clientClaims: clientClaims,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog {...rest}>
					<DialogTitle>{`Patch client claims`}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'
						style={{ minWidth: 400 }}>
						<List dense>
							{ClientClaims.map(t => (
								<ListItem
									key={t}
									dense
									button
									style={{ paddingLeft: 0, paddingRight: 0 }}
									onClick={() => {
										setSelectedClientClaims(toggleSelect(t, selectedClientClaims));
									}}>
									<ListItemIcon>
										<Checkbox
											size='small'
											checked={selectedClientClaims.indexOf(t) !== -1}
										/>
									</ListItemIcon>
									<ListItemText primary={ClientClaimRecord[t]} />
								</ListItem>
							))}
						</List>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={strings.update}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
