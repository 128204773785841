import { Divider, Tab, Tabs, useTheme } from '@material-ui/core';
import React from 'react';
import { SwipeableViewsX } from './SwipeableViewsX';

interface IProps {
	children: any;
	tab: number;
	setTab: (tab: number) => void;
	tabs: string[];
	indicatorColor?: 'secondary' | 'primary';
	textColor?: 'secondary' | 'primary' | 'inherit';
	style?: React.CSSProperties;
	tabsStyle?: React.CSSProperties;
	withoutDivider?: boolean;
}

export const SwipeableViewsWithTabs = ({
	children,
	tabs,
	tab,
	setTab,
	indicatorColor = 'primary',
	textColor = 'primary',
	style,
	tabsStyle,
	withoutDivider = false,
}: IProps) => {
	const theme = useTheme();
	return (
		<div
			className='df-col'
			style={style}>
			<Tabs
				value={tab}
				indicatorColor={indicatorColor}
				textColor={textColor}
				onChange={(_, newValue) => setTab(newValue)}
				style={{ marginBottom: withoutDivider === false ? 0 : theme.spacing(3), ...tabsStyle }}
				variant='fullWidth'>
				{tabs.map(t => (
					<Tab
						key={t}
						label={t}
						style={{ fontSize: '12px' }}
						wrapped
					/>
				))}
			</Tabs>
			{withoutDivider === false && <Divider style={{ marginBottom: theme.spacing(3) }} />}
			<SwipeableViewsX
				tab={tab}
				setTab={setTab}>
				{children}
			</SwipeableViewsX>
		</div>
	);
};
