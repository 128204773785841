import { IInventoryItem, IProductCatalogItem } from 'gen/ApiClient';
import { IHandoverLine } from '../IHandoverLine';

export const inventoryItemToLine = (t: IInventoryItem, pc: IProductCatalogItem): IHandoverLine => {
	return {
		isSelected: true,
		id: t.id,
		description: t.identifier,
		inventoryItemId: t.id,
		productCatalogItemId: t.productCatalogId,
		quantity: 1,
		unitListPrice: t.salesListPrice,
		unitPrice: t.salesPrice === 0 ? t.salesListPrice : t.salesPrice,
		productCatalogItem: pc,
		vatPercentage: t.vatPercentage,
		isRefundable: t.isHearingInstrument || t.isRemoteControl,
		manufacturer: t.manufacturer ?? 'n/a',
		model: t.model ?? 'n/a',
		serialNumber: t.serialNumber ?? '',
		rizivHearingAidDefinitionV2IdentificationCode: t.rizivHearingAidDefinitionV2IdentificationCode ?? undefined,
		inventoryItem: t,
		isIncludeOnHandoverCertificate: true,
		assuredInventoryItemId: undefined,
		assuredLineId: undefined,
		assuredProductCatalogItemId: undefined,
		canChangeQuantity: false,
		advanceInvoiceId: undefined,
		stockAtLocations: undefined,
		isCredit: false,
	};
};
