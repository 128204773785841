import React from 'react';
import { QueryProvider } from './QueryProvider';
import { ViewProvider } from './ViewProvider';

export const NoahDevicesProviders = ({ children }: any) => {
	return (
		<QueryProvider>
			<ViewProvider>{children}</ViewProvider>
		</QueryProvider>
	);
};
