import { DialogContent, DialogContentProps, Grid, useTheme } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import React from 'react';

interface IProps extends DialogContentProps {
	close: VoidFunction;
}

export const DialogContentGrid = ({ close, children, style, ...rest }: IProps) => {
	const theme = useTheme();
	return (
		<DialogContent
			{...rest}
			style={{ padding: theme.spacing(4), ...style }}>
			<CloseButtonOnAbsolutePosition onClick={close} />
			<Grid
				container
				spacing={2}>
				{children}
			</Grid>
		</DialogContent>
	);
};
