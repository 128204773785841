import React, { useContext } from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { IRequestForInsuranceAllowance, RfiaStatus } from 'gen/ApiClient';
import { ViewContext } from './context/ViewContext';

// const colorRecord: Record<RfiaStatus, Color> = {
//     HandedOverAndPaid: 'success',
//     ApprovedByAdvisoryPhysician: 'info',
//     HandedOver: 'success',
//     MedicalPrescriptionReceived: 'info',
//     TrialRunning:'info',
//     Created: 'warning',
//     DocumentsSentToAdvisoryPhysician: 'info',
//     DocumentsSentToEntPhysician: 'info',
//     PrescriptionForTrialReceived: 'info',
//     TrialEnded: 'warning',
//     Cancelled: 'error'
// }

// const fillRecord: Record<RfiaStatus, boolean> = {
//     HandedOverAndPaid: true,
//     ApprovedByAdvisoryPhysician: false,
//     HandedOver: false,
//     MedicalPrescriptionReceived: false,
//     TrialRunning: false,
//     Created: false,
//     DocumentsSentToAdvisoryPhysician: false,
//     DocumentsSentToEntPhysician: false,
//     PrescriptionForTrialReceived: false,
//     TrialEnded: false,
//     Cancelled: true,
// }

interface IProps {
	item: IRequestForInsuranceAllowance;
	style?: React.CSSProperties;
}

export const RfiaStatusChip = ({ item, style }: IProps) => {
	const { statusRecord } = useContext(ViewContext);

	return (
		<RectangularChip
			style={style}
			label={statusRecord[item.status as RfiaStatus]}
			// fill={fillRecord[item.status as RfiaStatus]}
			color={item.isCancelled ? 'error' : item.hasWarning ? 'warning' : item.isCompleted || item.isHandedOver ? 'success' : 'info'}
		/>
	);
};
