import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import {
	INoahDeviceInventoryItemMap,
	INoahDeviceInventoryItemMapsWithUsagesFilterParams,
	NoahDeviceInventoryItemMapExportProp,
	NoahDeviceInventoryItemMapExportProps,
	noahDeviceInventoryItemMapsQuery_exportWithUsages,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsHeader } from 'shared/table/createDetailsHeader';
import { Filter } from './Filter';
import { NoahDeviceImportedStatusChip } from './NoahDeviceImportedStatusChip';
import { SelectableColumn } from './SelectableColumn';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';
import { NoahDeviceDialog } from './detail/NoahDeviceDialog';

interface IProps extends IReloadTriggerProps {}

export const NoahDevicesList = ({ reloadTrigger }: IProps) => {
	const { reload, queryResult, params, setParams } = useQueryContext(QueryContext);
	const { open, confirm } = useDialogsContext(reload);
	const strings = useLocalization();
	const theme = useTheme();
	// eslint-disable-next-line
	const { columns, setColumns, columnsRecord, headers, exportRecord } = useContext(ViewContext);

	useEffect(() => {
		reload();
	}, [reloadTrigger, reload]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onViewDetail = (id: string) => {
		open(
			<NoahDeviceDialog
				open
				id={id}
				close={confirm}
			/>
		);
	};

	return (
		<>
			<div className='df-row-ac jc-sb'>
				<SearchbarWithParams
					params={params}
					setParams={setParams}
					placeholder={strings.searchVerb}
					style={{ width: '400px' }}
					variant='paper'
				/>
			</div>
			{queryResult.unfilteredCount > 0 && (
				<>
					<Card>
						<div className='df-row jc-sb'>
							<Filter
								params={params}
								onFilter={params => setParams(onResetToPage1(params))}
								paddingLeft={theme.spacing(1.5)}
							/>
							<div>
								<ExportButtonMenuAndDialog<INoahDeviceInventoryItemMapsWithUsagesFilterParams, NoahDeviceInventoryItemMapExportProp>
									params={params}
									filteredCount={queryResult.filteredCount}
									unfilteredCount={queryResult.unfilteredCount}
									allProps={NoahDeviceInventoryItemMapExportProps}
									downloadF={noahDeviceInventoryItemMapsQuery_exportWithUsages}
									fileName={strings.devicesFromNoah}
									record={exportRecord}
									view='noahDevices'
								/>
								<SelectColumnsButtonAndDialog
									selectedColumns={columns}
									setSelectedColumns={setColumns}
									record={columnsRecord}
									view='noahDevices'
								/>
							</div>
						</div>
						<Divider />
						<CardMedia>
							<PageableTableWithColumnSelection<INoahDeviceInventoryItemMap, SelectableColumn>
								queryResult={queryResult}
								headers={headers}
								sortParams={params.sortParams}
								pageParams={params.pageParams}
								setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
								getKey={inst => inst.id}
								selectedColumns={columns}
								prependColumn={createEmptyHeader<INoahDeviceInventoryItemMap>('prepend', t => (
									<NoahDeviceImportedStatusChip item={t} />
								))}
								appendColumn={createDetailsHeader<INoahDeviceInventoryItemMap>(t => onViewDetail(t.id))}
							/>
						</CardMedia>
					</Card>
				</>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound>{strings.noDevicesReadOutByNoahYet}</NoRecordsFound>}
		</>
	);
};
