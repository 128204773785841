import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { Try } from 'framework/Try';
import {
	agendaCommand_addExDate,
	agendaCommand_delete,
	agendaCommand_updateRrule,
	agendaQuery_single,
	ICalendarEventDto,
	IValidateResponse,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { calculateREndDate } from '../forms/utils/calculateREndDate';
import { formatRrule } from '../forms/utils/formatRrule';
import { newRRuleUntilDateForDelete } from '../newRRuleUntilDateForDelete';
import { RecurrenceUpdateMode } from '../RecurrenceUpdateMode';

interface IProps {
	event: ICalendarEventDto;
	instanceDate: Date;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const DeleteEventDialog = ({ event, instanceDate, confirm, cancel }: IProps) => {
	const strings = useLocalization();
	const [mode, setMode] = useState<RecurrenceUpdateMode>('this');
	const [originalEvent] = useApiEffect(agendaQuery_single, event.id);

	const onDeleteRecurring = async (originalEvent: ICalendarEventDto): Promise<Try<IValidateResponse>> => {
		if (mode === 'this') {
			return await agendaCommand_addExDate(originalEvent.id, new Date(instanceDate));
		} else if (mode === 'thisAndFuture') {
			const rRule = newRRuleUntilDateForDelete(event.rRule!, originalEvent.startDate, instanceDate);
			const endDate = calculateREndDate(rRule, new Date(originalEvent.startDate));
			return await agendaCommand_updateRrule(originalEvent.id, { rrule: formatRrule(rRule), rEndDate: endDate });
		} else {
			return await agendaCommand_delete(originalEvent.id);
		}
	};

	if (event.isRecurring === false) {
		return (
			<AlertDialog
				open
				title={strings.deleteAppointmentQuestion}
				content={strings.areYouSureYouWantToDeleteThisAppointmentQuestion}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => agendaCommand_delete(event.id)}
				accept={confirm}
			/>
		);
	} else if (originalEvent !== undefined) {
		return (
			<AlertDialog
				open
				title={strings.deleteAppointmentQuestion}
				outOfContentText
				content={
					<div className='df-col'>
						<ExtendedRadioGroup<RecurrenceUpdateMode>
							selected={mode}
							setSelected={setMode}
							options={[
								{ value: 'this', label: strings.thisAppointment },
								{ value: 'thisAndFuture', label: strings.thisAndNextAppointments },
								{ value: 'all', label: strings.allAppointments },
							]}
						/>
					</div>
				}
				acceptText={strings.continue}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => onDeleteRecurring(originalEvent)}
				accept={confirm}
			/>
		);
	} else {
		return <div></div>;
	}
};
