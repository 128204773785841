import React from 'react';
import { createHeader } from 'framework/table/createHeader';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IMedicalTreatmentFeeDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { MedicalTreatmentNomenclatureChip } from '../../detail/components/MedicalTreatmentNomenclatureChip';

interface IProps {
	fees: IMedicalTreatmentFeeDto[];
}

export const FeesTable = ({ fees }: IProps) => {
	const strings = useLocalization();

	const headers = [
		createHeader<IMedicalTreatmentFeeDto>(strings.code, t => <MedicalTreatmentNomenclatureChip code={t.code ?? ''} />),
		createHeader<IMedicalTreatmentFeeDto>(strings.price, t => formatCurrency(t.value), 'right'),
		createHeader<IMedicalTreatmentFeeDto>(strings.contribution, t => formatCurrency(t.contribution), 'right'),
		createHeader<IMedicalTreatmentFeeDto>(strings.personalShare, t => formatCurrency(t.personalShare), 'right'),
	];

	return (
		<TableWithHeadersAndValues<IMedicalTreatmentFeeDto>
			values={fees.filter(t => t !== null && t !== undefined)}
			headers={headers}
			getKey={t => t.code ?? ''}
			withPaperContainer
		/>
	);
};
