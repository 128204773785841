import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectFieldWithCaption } from 'framework/forms/FormSelectFieldWithCaption';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { ITemplate, ITemplateMetaModel, templatesCommand_updateMeta } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

const createSchema = (strings: IStrings) => {
	return yup
		.object<ITemplateMetaModel>({
			canEdit: yup.bool().defined(),
			mustSign: yup.bool().defined(),
			canViewOnline: yup.bool().defined(),
		})
		.defined();
};

interface IProps extends DialogProps {
	template: ITemplate;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const TemplateMetaForm = ({ template, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(templatesCommand_updateMeta);

	const handleSubmit = async (values: ITemplateMetaModel, helpers: FormikHelpers<ITemplateMetaModel>) => {
		const r = await create(template.id, values);
		if (handleFormResponse(r, helpers)) {
			// navigate to detail?
			notify(strings.changedWhat(strings.template));
			confirm();
		}
	};

	return (
		<Formik<ITemplateMetaModel>
			validateOnMount
			initialValues={{
				mustSign: template.mustSign,
				canViewOnline: template.canViewOnline,
				canEdit: template.canEdit,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog {...rest}>
					<DialogTitle>
						<div>{strings.changeWhat(strings.propertiesOfWhat(strings.template))}</div>
						<LightBulbNotification style={{ marginBottom: 8, marginTop: 8 }}>{strings.propertiesOfTemplateNotRecursiveOnDocuments}</LightBulbNotification>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormSelectFieldWithCaption<ITemplateMetaModel, boolean>
							label={strings.signature}
							name='mustSign'
							getKey={t => t.toString()}
							options={[
								{ value: true, label: strings.scanSignatureLater, caption: strings.templateScanSignatureLaterCaption },
								{ value: false, label: strings.noSignatureNeeded, caption: strings.templateNoSignatureNeededCaption },
							]}
						/>
						<FormSelectFieldWithCaption<ITemplateMetaModel, boolean>
							label={strings.editable}
							name='canEdit'
							options={[
								{ value: true, label: strings.editable, caption: strings.templateCanEditCaption },
								{ value: false, label: strings.notEditable, caption: strings.templateCannotEditCaption },
							]}
							getKey={t => t.toString()}
						/>
						<FormSelectFieldWithCaption<ITemplateMetaModel, boolean>
							label={strings.canViewInAppOrBrowser}
							name='canViewOnline'
							options={[
								{ value: true, label: strings.viewableInApp, caption: strings.templateViewableInAppCaption },
								{ value: false, label: strings.notViewableInApp, caption: strings.templateNotViewableInAppCaption },
							]}
							getKey={t => t.toString()}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<ITemplateMetaModel>
						cancel={cancel}
						submitText={strings.update}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
