import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { IRosaEventDto } from 'gen/ApiClient';
import { IRosaCalendarWithIsSelected } from './IRosaCalendarWithIsSelected';
import { IRosaMotiveSummaryDtoWithIsSelected } from './IRosaMotiveSummaryDtoWithIsSelected';

export const filterRosaEvents = (events: IRosaEventDto[], motives: IRosaMotiveSummaryDtoWithIsSelected[], calendars: IRosaCalendarWithIsSelected[]) => {
	return events
		.filter(
			t =>
				isNullOrUndefined(t.motiveId) ||
				motives
					.filter(t => t.isSelected)
					.map(t => t.id)
					.findIndex(x => x === t.motiveId) !== -1
		)
		.filter(
			t =>
				isNullOrUndefined(t.calendarId) ||
				calendars
					.filter(t => t.isSelected)
					.map(t => t.id)
					.findIndex(x => x === t.calendarId) !== -1
		);
};
