import { DialogProps } from '@material-ui/core';
import { businessDataCommand_patchAddress, IAddress } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { AddressForm } from 'shared/address/AddressForm';

interface IProps extends DialogProps {
	data: IAddress;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const BusinessDataAddressForm = ({ data, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<AddressForm
			cancel={cancel}
			model={{
				addressLine: data?.addressLine ?? '',
				zip: data?.zip ?? '',
				city: data?.city ?? '',
				country: data?.country ?? '',
			}}
			confirm={confirm}
			submitFunction={model => businessDataCommand_patchAddress(model)}
			successMessage={strings.addressUpdated}
			{...rest}
		/>
	);
};
