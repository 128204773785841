import { IUserDto, PageClaims } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const accessToPagesToSummary = (user: IUserDto | undefined, strings: IStrings) => {
	if (user === undefined || user.pageClaims === undefined || user.pageClaims === null) {
		return '';
	} else {
		return PageClaims.filter(t => user.pageClaims.indexOf(t) === -1).length === 0 ? strings.noWhat(strings.restrictions) : strings.noAccessToAllPages;
		// return user.pageClaims.length === PageClaims.length ? strings.noWhat(strings.restrictions) : strings.noAccessToAllPages;
	}
};
