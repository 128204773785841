import { ColorX } from './ColorX';

export const mapColorXToProgressSecondaryColor = (color: ColorX) => {
	if (color === 'success') {
		return 'rgba(76, 175, 80, 0.4)';
	} else if (color === 'warning') {
		return 'rgba(255, 152, 0, 0.4)';
	} else if (color === 'error') {
		return 'rgba(244, 67, 54, 0.4)';
	} else {
		return 'rgba(25, 118, 210, 0.4)';
	}
};
