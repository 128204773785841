import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { isFiltered } from 'framework/utils/isFiltered';
import { DocumentPredefinedType, DocumentPredefinedTypes, IDocumentsQueryParamsForPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';

const exclusions: (keyof IDocumentsQueryParamsForPatient)[] = [];

interface IProps {
	params: IDocumentsQueryParamsForPatient;
	onFilter: (params: IDocumentsQueryParamsForPatient) => void;
	paddingLeft: number;
}

export const PatientDetailDocumentsFilter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const { documentPredefinedTypeRecord } = useContext(RecordContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.date}
				before={params.createdBefore}
				after={params.createdAfter}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<RecordListFilterComponent<DocumentPredefinedType>
				record={documentPredefinedTypeRecord}
				options={DocumentPredefinedTypes}
				selected={params.predefinedTypes as any}
				setSelected={vals => onFilter({ ...params, predefinedTypes: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.predefinedType}
			/>
		</FilterBar>
	);
};
