import React from 'react';
import { CacheProvider as FrameworkCacheProvider } from 'framework/context/CacheProvider';
import { cosiQuestionnaireQuery_all, cosiQuestionnaireQuery_concurrencyToken } from 'gen/ApiClient';
import { CosiNeedsCacheContext } from './CosiNeedsCacheContext';

export const CacheProvider = ({ children }: any) => {
	return (
		<FrameworkCacheProvider<string[]>
			concurrencyF={cosiQuestionnaireQuery_concurrencyToken}
			loadF={cosiQuestionnaireQuery_all}
			context={CosiNeedsCacheContext}>
			{children}
		</FrameworkCacheProvider>
	);
};
