import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectField } from 'framework/forms/FormSelectField';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IRosaRegistrationRequest, RosaEnvironment, RosaEnvironments, rosaIntegrationCommand_register } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IRosaRegistrationRequest>({
		environment: yup.mixed<RosaEnvironment>().required(),
		rosaBearerToken: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	confirm: () => void;
	cancel: VoidFunction;
}

export const RosaRegistrationRequestForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(rosaIntegrationCommand_register);
	const notify = useSnackbarNotify();
	const schema = useMemo(() => createSchema(strings), [strings]);

	const handleSubmit = async (values: IRosaRegistrationRequest, helpers: FormikHelpers<IRosaRegistrationRequest>) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			notify(`ROSA bearer token set`);
			confirm();
		}
	};

	return (
		<Formik<IRosaRegistrationRequest>
			validateOnMount
			initialValues={{
				environment: 'Prod',
				rosaBearerToken: '',
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog {...rest}>
					<DialogTitle>{`Register Rosa Integration`}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'
						style={{ minWidth: 400 }}>
						<FormSelectField<IRosaRegistrationRequest, RosaEnvironment>
							name='environment'
							label={`Environment`}
							options={RosaEnvironments}
							renderValue={t => t.toString()}
							getKey={t => t}
						/>
						<FormTextField<IRosaRegistrationRequest>
							name='rosaBearerToken'
							label={`Bearer Token`}
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={strings.update}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
