import React from 'react';

interface IProps {
	text: string;
	onClick: VoidFunction;
	textLinkStyle?: React.CSSProperties;
	prependText?: string;
	appendText?: string;
	style?: React.CSSProperties;
}

export const TextLink = ({ text, onClick, textLinkStyle, prependText, appendText, style }: IProps) => {
	return (
		<div
			className='df-row-ac'
			style={style}>
			{prependText && <div style={{ marginRight: 3 }}>{prependText}</div>}
			<div
				style={{
					color: 'rgb(0, 0, 238)',
					textDecoration: 'underline',
					cursor: 'pointer',
					...textLinkStyle,
				}}
				onClick={onClick}>
				{text}
			</div>
			{appendText && <div style={{ marginLeft: 3 }}>{appendText}</div>}
		</div>
	);
};
