import { Dialog, DialogProps, Divider } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import * as yup from 'yup';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { ILabelCaptionValue } from 'framework/components/ILabelCaptionValue';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormAutocompleteFieldFromTypes } from 'framework/forms/FormAutocompleteFieldFromTypes';
import { FormSelectFieldFromRecord } from 'framework/forms/FormSelectFieldFromRecord';
import { FormSelectFieldWithCaption } from 'framework/forms/FormSelectFieldWithCaption';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffectWithDefer } from 'framework/hooks/useApiEffectWithDefer';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { fileToBase64 } from 'framework/utils/fileToBase64';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import {
	exampleTemplatesQuery_byMainContext,
	exampleTemplatesQuery_byPredefinedType,
	ITemplateUploadModel,
	LanguageOption,
	TemplateMainContext,
	templatesCommand_new,
	TemplateType,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { UploadFile } from 'shared/dialogs/upload/UploadFile';
import { RecordContext } from 'shared/records/RecordContext';

type Mode = 'upload' | 'fromExample' | 'empty';

const createSchema = (strings: IStrings, isFromPredefinedType: boolean, mode: Mode) => {
	return yup
		.object<ITemplateUploadModel>({
			canEdit: yup.bool().defined(),
			contentAsBase64: yup.string(),
			isFromPredefinedType: yup.bool().defined(),
			name: yup.string().required(strings.formRequired(strings.name)),
			predefinedType: isFromPredefinedType ? yup.string().required(strings.formRequired(strings.predefinedType)) : yup.string(),
			mustSign: yup.bool().defined(),
			canViewOnline: yup.bool().defined(),
			language: yup.string().required(strings.formRequired(strings.language)),
			mainContext: isFromPredefinedType ? yup.string() : yup.string().required(strings.formRequired(strings.subject)),
			exampleId: mode === 'fromExample' ? yup.string().required(strings.formRequired(strings.example)) : yup.string(),
		})
		.defined();
};

const emptyValues: ITemplateUploadModel = {
	canEdit: true,
	contentAsBase64: '',
	isFromPredefinedType: true,
	mustSign: false,
	canViewOnline: true,
	name: '',
	predefinedType: '',
	language: 'nl' as LanguageOption,
	mainContext: '',
	exampleId: undefined as any,
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

const stepsRecordEmpty: Record<number, (keyof ITemplateUploadModel)[]> = {
	0: ['predefinedType', 'mainContext', 'isFromPredefinedType'],
	1: ['name', 'language'],
	2: ['mustSign', 'canEdit', 'canViewOnline'],
	3: [],
};

const stepsRecordWithUpload: Record<number, (keyof ITemplateUploadModel)[]> = {
	...stepsRecordEmpty,
	4: ['contentAsBase64'],
};

export const TemplateForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(templatesCommand_new);
	const [step, setStep] = useState<number>(0);
	const [mode, setMode] = useState<Mode>('upload');
	const stepsRecord = useMemo(() => (mode === 'upload' ? stepsRecordWithUpload : stepsRecordEmpty), [mode]);
	const [isFromPredefinedType, setIsFromPredefinedType] = useState<boolean>(true);
	const schema = useMemo(() => createSchema(strings, isFromPredefinedType, mode), [strings, isFromPredefinedType, mode]);

	const handleSubmit = async (values: ITemplateUploadModel, helpers: FormikHelpers<ITemplateUploadModel>) => {
		const r = await create({ ...values, isFromPredefinedType: isFromPredefinedType, exampleId: mode === 'fromExample' ? values.exampleId : undefined });
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(`${values.name} toegevoegd aan de templates`);
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<ITemplateUploadModel>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					schema={schema}
					cancel={cancel}
					isSubmitting={isSubmitting}
					stepsRecord={stepsRecord}
					mode={mode}
					setMode={setMode}
					isFromPredefinedType={isFromPredefinedType}
					setIsFromPredefinedType={setIsFromPredefinedType}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	step: number;
	setStep: (step: number) => void;
	isSubmitting: boolean;
	schema: yup.ObjectSchema<ITemplateUploadModel>;
	cancel: VoidFunction;
	stepsRecord: Record<number, (keyof ITemplateUploadModel)[]>;
	mode: Mode;
	setMode: (mode: Mode) => void;
	isFromPredefinedType: boolean;
	setIsFromPredefinedType: React.Dispatch<React.SetStateAction<boolean>>;
}

const InnerDialog = ({
	step,
	setStep,
	isSubmitting,
	schema,
	cancel,
	stepsRecord,
	mode,
	setMode,
	isFromPredefinedType,
	setIsFromPredefinedType,
	...rest
}: IInnerProps) => {
	const props = useFormikContext<ITemplateUploadModel>();
	const strings = useLocalization();
	const { templateTypeRecord, localizedLanguageRecord, templateMainContextRecord } = useContext(RecordContext);

	useEffect(() => {
		if (mode === 'empty') {
			setFormValue<ITemplateUploadModel>('exampleId', '', props);
		}
		// eslint-disable-next-line
	}, [mode]);

	useEffect(() => {
		const cosiTemplateType: TemplateType = 'RfiaCosi';
		setFormValue<ITemplateUploadModel>('canViewOnline', props.values.predefinedType !== cosiTemplateType, props);
		// eslint-disable-next-line
	}, [props.values.predefinedType]);

	const onSelectFile = async (file: File) => {
		const content = await fileToBase64(file);
		setFormValue<ITemplateUploadModel>('contentAsBase64', content, props);
		props.submitForm();
	};

	return (
		<Dialog
			{...rest}
			maxWidth='lg'>
			<DialogTitleWithFormStepper
				title={strings.newWhat(strings.template)}
				step={step}
				labels={[strings.type, strings.name, strings.properties, strings.create]}
			/>
			<OverflowDialogContent dividers>
				{step === 0 && (
					<>
						<ExtendedRadioGroup<boolean>
							selected={isFromPredefinedType}
							setSelected={setIsFromPredefinedType}
							options={[
								{ value: true, label: strings.predefinedType, caption: strings.templatePredefinedTypeCaption },
								{ value: false, label: strings.subject, caption: strings.templateSubjectCaption },
							]}
						/>
						<Divider style={{ marginTop: 16, marginBottom: 16 }} />
						{isFromPredefinedType && (
							<FormAutocompleteFieldFromTypes<ITemplateUploadModel, TemplateType>
								name='predefinedType'
								label={strings.predefinedType}
								record={templateTypeRecord}
							/>
						)}
						{isFromPredefinedType === false && (
							<FormSelectFieldFromRecord<ITemplateUploadModel, TemplateMainContext>
								name='mainContext'
								label={strings.subject}
								record={templateMainContextRecord}
							/>
						)}
					</>
				)}
				{step === 1 && (
					<>
						<FormTextField<ITemplateUploadModel>
							name='name'
							label={strings.name}
							required
						/>
						<FormSelectFieldFromRecord<ITemplateUploadModel, LanguageOption>
							record={localizedLanguageRecord}
							name='language'
							label={strings.language}
						/>
					</>
				)}
				{step === 2 && (
					<>
						<FormSelectFieldWithCaption<ITemplateUploadModel, boolean>
							label={strings.signature}
							name='mustSign'
							getKey={t => t.toString()}
							options={[
								{ value: true, label: strings.scanSignatureLater, caption: strings.templateScanSignatureLaterCaption },
								{ value: false, label: strings.noSignatureNeeded, caption: strings.templateNoSignatureNeededCaption },
							]}
						/>
						<FormSelectFieldWithCaption<ITemplateUploadModel, boolean>
							label={strings.editable}
							name='canEdit'
							options={[
								{ value: true, label: strings.editable, caption: strings.templateCanEditCaption },
								{ value: false, label: strings.notEditable, caption: strings.templateCannotEditCaption },
							]}
							getKey={t => t.toString()}
						/>
					</>
				)}
				{step === 3 && (
					<>
						<ExtendedRadioGroup<Mode>
							selected={mode}
							setSelected={setMode}
							options={[
								{ value: 'upload', label: strings.uploadDocument, caption: strings.templateStartFromUploadCaption },
								{ value: 'empty', label: strings.emptyDocument, caption: strings.templateStartFromEmptyCaption },
								{ value: 'fromExample', label: strings.example, caption: strings.templateStartFromExampleCaption },
							]}
						/>
						{mode === 'fromExample' && (
							<>
								<Divider style={{ marginTop: 16, marginBottom: 16 }} />
								<SelectExampleTemplateComponent
									predefinedType={props.values.predefinedType}
									isFromPredefinedType={isFromPredefinedType}
									mainContext={props.values.mainContext}
								/>
							</>
						)}
					</>
				)}
				{step === 4 && (
					<UploadFile
						isUploading={isSubmitting}
						onFilesSelected={files => onSelectFile(files[0])}
						accept='word'
					/>
				)}
			</OverflowDialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};

interface ISelectExampleTemplateComponentProps {
	predefinedType: string | undefined;
	mainContext: string | undefined;
	isFromPredefinedType: boolean;
}

const SelectExampleTemplateComponent = ({ predefinedType, mainContext, isFromPredefinedType }: ISelectExampleTemplateComponentProps) => {
	const strings = useLocalization();
	const [byPredefinedType] = useApiEffectWithDefer(exampleTemplatesQuery_byPredefinedType, predefinedType ?? '', isNotNullNorUndefined);
	const [byMainContext] = useApiEffectWithDefer(exampleTemplatesQuery_byMainContext, mainContext ?? '', isNotNullNorUndefined);
	const examples = useMemo(() => (isFromPredefinedType ? byPredefinedType : byMainContext), [isFromPredefinedType, byPredefinedType, byMainContext]);
	const { localizedLanguageRecord } = useContext(RecordContext);
	const options = useMemo(
		() => examples?.map<ILabelCaptionValue<string>>(t => ({ value: t.id, label: t.name, caption: localizedLanguageRecord[t.language as LanguageOption] })),
		[examples, localizedLanguageRecord]
	);

	if (options === undefined) {
		return <div></div>;
	}

	return (
		<FormSelectFieldWithCaption<ITemplateUploadModel, string>
			label={strings.example}
			name='exampleId'
			options={options}
			getKey={t => t}
		/>
	);
};
