import { IWorkflowSummary } from 'gen/ApiClient';
import { WorkflowFilterTabType } from './WorkflowFilterTabType';

export const filterWorkflowsByTabType = (wfs: IWorkflowSummary[], tab: WorkflowFilterTabType) => {
	if (tab === 'active') {
		return wfs.filter(t => t.isActive);
	} else if (tab === 'error') {
		return wfs.filter(t => t.hasError);
	} else if (tab === 'warning') {
		return wfs.filter(t => t.hasWarning);
	} else {
		return wfs;
	}
};
