import React from 'react';
import { ILoaderButtonProps, LoaderButton } from 'framework/components/buttons/LoaderButton';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { IHeader } from 'framework/table/IHeader';

export const createSmallPrimaryTextButtonHeader = <T extends unknown>(
	key: string,
	content: string | JSX.Element,
	onClick: (val: T, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
	disabledF: (val: T) => boolean = (val: T) => false,
	onlyShowOnHover: boolean = false,
	isLoadingF: (val: T) => boolean = (val: T) => false
): IHeader<T> => {
	return createEmptyHeader<T>(
		key,
		t => (
			<ButtonX
				onClick={e => onClick(t, e)}
				disabled={disabledF(t)}
				isLoading={isLoadingF(t)}>
				{content}
			</ButtonX>
		),
		'right',
		onlyShowOnHover
	);
};

const ButtonX = ({ ...props }: ILoaderButtonProps) => {
	return (
		<LoaderButton
			variant='text'
			size='small'
			color='primary'
			{...props}
		/>
	);
};
