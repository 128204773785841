import React from 'react';
import { createHeader } from 'framework/table/createHeader';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IMedicalTreatmentFeeDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	fees: IMedicalTreatmentFeeDto[];
}

export const HistoryTable = ({ fees }: IProps) => {
	const strings = useLocalization();

	const headers = [
		createHeader<IMedicalTreatmentFeeDto>(strings.from, t => formatDate(t.validityStart)),
		createHeader<IMedicalTreatmentFeeDto>(strings.to, t => formatDate(t.validityEnd)),
		createHeader<IMedicalTreatmentFeeDto>(strings.price, t => formatCurrency(t.value), 'right'),
		createHeader<IMedicalTreatmentFeeDto>(strings.compensation, t => formatCurrency(t.contribution), 'right'),
		createHeader<IMedicalTreatmentFeeDto>(strings.personalShare, t => formatCurrency(t.personalShare), 'right'),
	];

	return (
		<TableWithHeadersAndValues<IMedicalTreatmentFeeDto>
			values={fees}
			headers={headers}
			getKey={t => t.validityStart.toString()}
			withPaperContainer
		/>
	);
};
