import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { ISale, ISalesForCommissionQueryParams, salesQuery_queryForCommission } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { SearchSaleForCommission } from './SearchSaleForCommission';

const DefaultParams: ISalesForCommissionQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	paymentStatuses: [],
	types: [],
	creditedStatuses: [],
	isShowAll: false,
};

interface IProps extends DialogProps {
	confirm: (item: ISale) => void;
	cancel: VoidFunction;
}

export const SearchSaleForCommissionDialog = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(salesQuery_queryForCommission, DefaultParams);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<Dialog
			fullWidth
			maxWidth='lg'
			{...rest}>
			<DialogTitle>{strings.searchSaleForCommission}</DialogTitle>
			<DialogContent
				dividers
				style={{ padding: 0 }}>
				<SearchSaleForCommission
					onSelect={confirm}
					params={params}
					setParams={setParams}
					queryResult={queryResult}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant='text'
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
