import { ITenderLineModel } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const lineToModel = (line: ILine & { index: number }): ITenderLineModel => {
	return {
		indexNumber: line.index,
		productCatalogItemId: line.item?.id,
		quantity: line.quantity,
		description: line.description,
		unitListPriceInVat: line.unitListPrice,
		unitPriceInVat: line.unitPrice,
		vatPercentage: line.vatPercentage,
	};
};
