import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import RestoreFromTrashOutlinedIcon from '@material-ui/icons/RestoreFromTrashOutlined';
import { AddSmallPrimaryTextButton } from 'framework/components/buttons/AddSmallPrimaryTextButton';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { CommissionRuleMethod, commissionRulesCommand_disable, commissionRulesCommand_enable, ICommissionRule } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { CommissionRulesCacheContext } from '../context/CommissionRulesCacheContext';
import { CommissionRuleForm } from './CommissionRuleForm';

export const RulesList = () => {
	const strings = useLocalization();
	const theme = useTheme();
	const [rules, load] = usePreloadCacheContext(CommissionRulesCacheContext);
	const { open, confirm, cancel } = useDialogsContext(load);
	const { commissionRuleMethodRecord } = useContext(RecordContext);

	if (rules === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<CommissionRuleForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDisable = (rule: ICommissionRule) => {
		open(
			<AlertDialog
				open
				content={`Commissie-regel ${rule.name} de-activeren?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => commissionRulesCommand_disable(rule.id)}
				accept={confirm}
			/>
		);
	};

	const onEnable = (rule: ICommissionRule) => {
		open(
			<AlertDialog
				open
				content={`Commissie-regel ${rule.name} terug activeren?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => commissionRulesCommand_enable(rule.id)}
				accept={confirm}
			/>
		);
	};

	const onEdit = (rule: ICommissionRule) => {
		open(
			<CommissionRuleForm
				open
				confirm={confirm}
				cancel={cancel}
				rule={rule}
			/>
		);
	};

	return (
		<div style={{ marginBottom: theme.spacing(4) }}>
			<DividerWithCaption
				caption={strings.rules}
				captionStyle={{ fontWeight: 500, color: 'black' }}
				action={<AddSmallPrimaryTextButton onClick={onNew}>{strings.add}</AddSmallPrimaryTextButton>}
			/>
			{rules.all.length !== 0 && (
				<>
					<List
						dense
						disablePadding>
						{rules.all.map(t => (
							<ListItem
								key={t.id}
								disableGutters>
								<ListItemText
									primary={`${t.name} (${t.percentage} %)`}
									secondary={commissionRuleMethodRecord[t.method as CommissionRuleMethod]}
								/>
								<ListItemSecondaryAction>
									<IconButton
										onClick={() => onEdit(t)}
										edge='end'>
										<EditOutlinedIcon />
									</IconButton>
									{t.isEnabled === true && (
										<IconButton
											onClick={() => onDisable(t)}
											edge='end'>
											<DeleteOutlineIcon />
										</IconButton>
									)}
									{t.isEnabled === false && (
										<IconButton
											onClick={() => onEnable(t)}
											edge='end'>
											<RestoreFromTrashOutlinedIcon />
										</IconButton>
									)}
								</ListItemSecondaryAction>
							</ListItem>
						))}
					</List>
				</>
			)}
			{rules.all.length === 0 && <NoRecordsFound>{strings.noWhatDefined(strings.rules)}</NoRecordsFound>}
		</div>
	);
};
