import { Card, CardContent, CardProps, Divider, IconButton } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { Timeline } from '@material-ui/lab';
import { AddSmallPrimaryTextButton } from 'framework/components/buttons/AddSmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { TablePaging } from 'framework/table/TablePaging';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { annotationsQuery_query, IAnnotation, IAnnotationsQueryParams, LinkViewContextType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { AnnotationForm } from 'shared/annotations/AnnotationForm';
import { AnnotationTypesCacheContext } from 'shared/annotations/context/AnnotationTypesCacheContext';
import { AnnotationSettingsDialog } from 'shared/annotations/types/AnnotationSettingsDialog';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import { AnnotationsFilter } from './AnnotationsFilter';
import { AnnotationTimelineItem } from './AnnotationTimelineItem';

const DefaultParams: IAnnotationsQueryParams = {
	pageParams: { index: 1, size: 50 },
	sortParams: { property: 'created', direction: 'desc' },
	createdAfterDate: undefined,
	createdBeforeDate: undefined,
	types: [],
	alsoIncludeDeleted: false,
};

interface IProps extends IReloadTriggerProps, CardProps {
	contextId: string;
	contextType: LinkViewContextType;
	title?: string;
	icon?: React.ReactNode;
	isFollowUp?: boolean;
}

export const AnnotationsCard = ({ contextId, contextType, reloadTrigger, title, icon, isFollowUp = false, ...rest }: IProps) => {
	const strings = useLocalization();
	const [params, setParams, queryResult, reloadAnnotations] = useApiEffectWithParams(
		pars => annotationsQuery_query(isFollowUp, contextId, pars),
		DefaultParams
	);
	const annotations = React.useMemo<IAnnotation[]>(() => queryResult?.values ?? [], [queryResult]);
	const [types] = usePreloadCacheContext(AnnotationTypesCacheContext);
	const { open, confirm, cancel } = useDialogsContext(reloadAnnotations);
	const xTitle = useMemo(() => title ?? strings.annotations, [title, strings]);
	const xIcon = useMemo<React.ReactNode>(() => icon ?? <CommentOutlinedIcon style={{ marginRight: 4, opacity: 0.8 }} />, [icon]);

	useLazyEffect(() => {
		reloadAnnotations();
	}, [reloadTrigger]);

	const onFilter = (params: IAnnotationsQueryParams) => {
		setParams(onResetToPage1(params));
	};

	const onAdd = () => {
		open(
			<AnnotationForm
				open
				contextId={contextId}
				linkViewContextType={contextType}
				confirm={confirm}
				cancel={cancel}
				isFollowUp={isFollowUp}
			/>
		);
	};

	const onSettings = () => {
		open(
			<AnnotationSettingsDialog
				open
				close={confirm}
				isFollowUp={isFollowUp}
			/>
		);
	};

	const onClickAnnotationType = (id: string) => {
		onFilter({ ...params, types: [id] });
	};

	return (
		<Card {...rest}>
			<CardTitleTypography
				startIcon={xIcon}
				withPadding
				title={xTitle}
				action={
					<IconButton
						onClick={onSettings}
						size='small'
						color='primary'>
						<SettingsOutlinedIcon fontSize='small' />
					</IconButton>
				}
			/>
			<CardContent style={{ paddingTop: 0 }}>
				<div className='df-row-ac'>
					<AnnotationsFilter
						params={params}
						onFilter={onFilter}
						paddingLeft={8}
					/>
					<div className='fg1'></div>
					<FormControlLabelSwitch
						isChecked={params.alsoIncludeDeleted}
						toggle={() => onFilter({ ...params, alsoIncludeDeleted: !params.alsoIncludeDeleted })}
						label={strings.alsoShowDeleted}
						style={{ marginRight: 0 }}
					/>
				</div>
				<Divider style={{ marginBottom: 6 }} />
				<AddSmallPrimaryTextButton onClick={onAdd}>{strings.newAnnotation}</AddSmallPrimaryTextButton>
				{queryResult !== undefined && (
					<>
						<div style={{ overflowY: 'auto' }}>
							<Timeline style={{ padding: 0, paddingLeft: 6, marginTop: 0 }}>
								{annotations.map(t => (
									<AnnotationTimelineItem
										key={t.id}
										item={t}
										types={types}
										reload={reloadAnnotations}
										onClickAnnotationType={onClickAnnotationType}
										showDeleted={params.alsoIncludeDeleted}
									/>
								))}
							</Timeline>
						</div>
						<Divider style={{ marginTop: 24 }} />
						<TablePaging
							removeBorder
							countResults={queryResult.filteredCount}
							pageSize={queryResult.pageSize}
							page={queryResult.zeroBasedPageIndex}
							pageParams={params.pageParams}
							setPageParams={val => setParams({ ...params, pageParams: val })}
							style={{ marginBottom: -16, marginRight: -16 }}
						/>
					</>
				)}
			</CardContent>
		</Card>
	);
};
