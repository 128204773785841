import { useTheme } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { IOption } from 'framework/IOption';
import { formatDate } from 'framework/utils/date/formatDate';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { IAudiogramStandardWithToneThresholdAudiogramsDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { AudiogramImageOrEmpty } from 'shared/audiograms/AudiogramImageOrEmpty';
import { selectImageData } from 'shared/audiograms/selectImageData';
import { ToneThresholdImageType } from 'shared/audiograms/ToneThresholdImageType';

interface IProps {
	audiograms: IAudiogramStandardWithToneThresholdAudiogramsDto[];
	// setToneThresholdAudiograms: (value: IToneThresholdAudiogram[] | undefined) => void;
	type: ToneThresholdImageType;
}

export const SelectAudiogramStandardToToneThresholdAudiogramsField = <TModel extends { audiogramStandardId: string }>({ audiograms, type }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const props = useFormikContext<TModel>();

	useEffect(() => {
		// just select the last one by default
		if (audiograms.length > 0) {
			setFormValue<TModel>('audiogramStandardId', audiograms[0].id, props);
		}
		// eslint-disable-next-line
	}, [audiograms]);

	return (
		<>
			<FormSelectFieldWithOption<TModel, string>
				name='audiogramStandardId'
				label={strings.audiogram}
				options={audiograms.map<IOption<string>>(t => ({ id: t.id, identifier: formatDate(t.noahLastModifiedDate, 'date-and-time') }))}
				helperText={strings.dateLastChangedInNoah}
			/>
			<DividerWithCaption caption={strings.image} />
			{isNotNullNorUndefined(props.values.audiogramStandardId) && (
				<AudiogramImageOrEmpty
					imageData={selectImageData(type, audiograms.find(t => t.id === props.values.audiogramStandardId)!)}
					style={{ width: 450, marginBottom: theme.spacing(1) }}
				/>
			)}
		</>
	);
};
