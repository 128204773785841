import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IFile } from 'framework/utils/IFile';
import { IPurchaseUbl, purchasesUblCommand_upload } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { UploadFileDialog } from 'shared/dialogs/upload/UploadFileDialog';
import { RegisterPurchaseFromUblRequestForm } from './RegisterPurchaseFromUblRequestForm';

interface IProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const UploadPurchaseUblDialog = ({ confirm, cancel }: IProps) => {
	const strings = useLocalization();
	const [response, setResponse] = useState<IPurchaseUbl>();
	const [upload, isUploading] = useFormSubmit(purchasesUblCommand_upload);
	const notify = useSnackbarNotify();

	const onFileSelected = async (file: IFile) => {
		const r = await upload(file);
		if (r.isSuccess) {
			if (r.result.hasError) {
				notify(r.result.error!, 'error');
			} else {
				setResponse(r.result.value);
			}
		}
	};

	if (response === undefined) {
		return (
			<UploadFileDialog
				open
				title={strings.selectWhat('UBL')}
				confirm={() => {}}
				confirmF={onFileSelected}
				isUploading={isUploading}
				cancel={cancel}
				accept='xml'
			/>
		);
	} else {
		return (
			<RegisterPurchaseFromUblRequestForm
				open
				item={response}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	}
};
