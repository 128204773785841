import { CardProps, useTheme } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { formatDateX } from 'framework/utils/date/formatDateX';
import { IRosaStatusDto, rosaIntegrationQuery_isEnabled } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';

interface IProps extends IReloadTriggerProps, CardProps {
	data: IRosaStatusDto;
}

export const RosaUserSettingsCardX = ({ reloadTrigger, data, ...rest }: IProps) => {
	const strings = useLocalization();
	const [isEnabled, reloadIsEnabled] = useApiEffect(rosaIntegrationQuery_isEnabled);
	const theme = useTheme();

	useLazyEffect(() => {
		reloadIsEnabled();
	}, [reloadTrigger]);

	if (isEnabled === undefined) {
		return <></>;
	}

	return (
		<CardX
			{...rest}
			className='df-col gap-8'>
			<Typography16pxBold>{strings.integrationStatus}</Typography16pxBold>
			<LabelledPropertyX
				iconPosition='start'
				icon={<StatusIcon status={isEnabled ? 'success' : 'error'} />}
				label={isEnabled ? strings.status : undefined}>
				{isEnabled ? strings.rosaHAMSLinkActive : <em style={{ color: theme.palette.error.main }}>{strings.rosaNotActivatedNotification}</em>}
			</LabelledPropertyX>
			{isEnabled && (
				<LabelledPropertyX
					iconPosition='start'
					icon={<StatusIcon status={'success'} />}
					label={strings.lastSynced}>
					{data.lastSyncedDate ? formatDateX(data.lastSyncedDate, 'dd/MM/yyyy HH:mm:ss') : strings.notYetSynchronized}
				</LabelledPropertyX>
			)}
		</CardX>
	);
};
