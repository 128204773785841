import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import UpdateIcon from '@material-ui/icons/Update';
import { IWorkflowSummary } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: IWorkflowSummary;
	style?: React.CSSProperties;
}

export const WorkflowSummaryStatusIcon = ({ item, style }: IProps) => {
	if (item.isCancelled) {
		return <BlockIcon style={style} />;
	} else if (item.hasError) {
		return <ErrorOutlineIcon style={style} />;
	} else if (item.hasWarning) {
		return <ReportProblemOutlinedIcon style={style} />;
	} else if (item.isCompleted) {
		return <CheckCircleOutlineIcon style={style} />;
	} else {
		return <UpdateIcon style={style} />;
	}
};
