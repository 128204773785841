import { IPreloadCacheContext } from 'framework/context/IPreloadCacheContext';
import { Try } from 'framework/Try';
import { useContext, useEffect } from 'react';

export function usePreloadCacheContext<T>(context: React.Context<IPreloadCacheContext<T>>): [T, () => Promise<Try<T>>] {
	const { data, init, load } = useContext(context);

	useEffect(() => {
		init();
	}, [init]);

	return [data, load];
}
