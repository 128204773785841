import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ICustomerAccountLine } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (colRecord: Record<SelectableColumn, string>, strings: IStrings): Record<SelectableColumn, IHeader<ICustomerAccountLine>> => {
	return {
		amountPaid: createSortableHeaderWithKey<ICustomerAccountLine, SelectableColumn>(colRecord, 'amountPaid', t => formatCurrency(t.amountPaid), 'right'),
		created: createSortableHeaderWithKey<ICustomerAccountLine, SelectableColumn>(colRecord, 'created', t => formatDate(t.created)),
		outstandingBalance: createSortableHeaderWithKey<ICustomerAccountLine, SelectableColumn>(
			colRecord,
			'outstandingBalance',
			t => formatCurrency(t.outstandingBalance),
			'right'
		),
		value: createSortableHeaderWithKey<ICustomerAccountLine, SelectableColumn>(colRecord, 'value', t => formatCurrency(t.value), 'right'),
		salesTotalPriceInVat: createSortableHeaderWithKey<ICustomerAccountLine, SelectableColumn>(
			colRecord,
			'salesTotalPriceInVat',
			t => formatCurrency(t.salesTotalPriceInVat),
			'right'
		),
		salesReference: createSortableHeaderWithKey<ICustomerAccountLine, SelectableColumn>(colRecord, 'salesReference', t => t.salesReference),
		salesRecipientName: createSortableHeaderWithKey<ICustomerAccountLine, SelectableColumn>(colRecord, 'salesRecipientName', t => t.salesRecipientName),
		settlementProposalReference: createSortableHeaderWithKey<ICustomerAccountLine, SelectableColumn>(
			colRecord,
			'settlementProposalReference',
			t => t.settlementProposalReference
		),
		referencePatientIdentifier: createSortableHeaderWithKey<ICustomerAccountLine, SelectableColumn>(
			colRecord,
			'referencePatientIdentifier',
			t => t.referencePatientIdentifier
		),
	};
};
