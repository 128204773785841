import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

export const FinancialsPageBreadcrumbTitle = () => {
	const strings = useLocalization();

	return (
		<PageBreadcrumbTitle
			title={strings.financial}
			icon={<AttachMoneyIcon />}
		/>
	);
};
