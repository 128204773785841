import { IStrings } from 'localization/IStrings';
import { QuickFilter } from '../PaymentsList';

export const createQfRecord = (strings: IStrings): Record<QuickFilter, string> => {
	return {
		all: strings.all,
		linkedToAPurchase: strings.purchase,
		linkedToASale: strings.sale,
	};
};
