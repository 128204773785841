import { IProductCatalogItem } from 'gen/ApiClient';
import { IPurchaseFormLine } from './IPurchaseFormLine';
import { v1 as uuid } from 'uuid';

export const productCatalogItemToLine = (t: IProductCatalogItem, isCredit: boolean): IPurchaseFormLine => {
	return {
		id: `${t.isUniquelyIdentifiable ? uuid() : t.id}-${isCredit ? 'CREDIT' : 'PURCHASE'}`,
		description: t.identifier,
		inventoryItemId: undefined,
		productCatalogItemId: t.id,
		quantity: isCredit ? -1 : 1,
		unitListPrice: t.purchaseListPrice,
		unitPrice: t.purchaseListPrice,
		vatPercentage: t.vatPercentage,
		productCatalogItem: t,
		serialNumber: '',
		assuredLineId: undefined,
		assuredProductCatalogItemId: undefined,
		canChangeQuantity: t.isUniquelyIdentifiable === false && t.isInsuranceOrProlongedWarranty === false,
		assuredSerialNumber: undefined,
		isCreditLine: isCredit,
		creditedAssuranceLineId: undefined,
	};
};
