import { FilledInput } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { useStateBoolean } from '../hooks/useStateBool';
import { nameOf } from '../utils/nameOf';
import { ClearEndAdornment } from './common/ClearEndAdornment';
import { FormFilledControl } from './common/FormFilledControl';
import { IFormProps } from './common/IFormProps';
import { useFilledInputStyles } from './common/useFilledInputStyles';

interface IFormTextFieldProps<TModel> extends IFormProps<TModel> {
	placeholder?: string;
	multiline?: boolean;
	rows?: number;
}

export const FormTextField = <TModel extends unknown>({ multiline = false, rows, ...props }: IFormTextFieldProps<TModel>) => {
	const [field, meta, helpers] = useField<string>(nameOf(props.name));
	const [isHovered, enter, leave] = useStateBoolean(false);
	const classes = useFilledInputStyles();

	return (
		<FormFilledControl
			meta={meta}
			enter={enter}
			leave={leave}
			{...props}>
			<FilledInput
				rows={rows}
				multiline={multiline}
				placeholder={props.placeholder}
				{...field}
				className={classes.filledInput}
				endAdornment={
					<ClearEndAdornment
						isHovered={isHovered}
						field={field}
						helpers={helpers}
						disabled={props.disabled ?? false}
						defaultValue={''}
					/>
				}
			/>
		</FormFilledControl>
	);
};
