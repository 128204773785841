import { Button, ButtonProps, Radio } from '@material-ui/core';
import React from 'react';

interface IProps extends ButtonProps {
	children: React.ReactNode;
	color?: 'primary' | 'secondary';
	isSelected: boolean;
}

export const RadioIsSelectedButton = ({ color = 'primary', style, isSelected, ...rest }: IProps) => {
	return (
		<div className='df-row-ac fg1'>
			<Radio
				style={{ padding: 0 }}
				checked={isSelected}
				color={color}
				size='small'
			/>
			<Button
				variant='text'
				size='small'
				color={isSelected ? color : 'inherit'}
				style={{ flexGrow: 1, justifyContent: 'flex-start', ...style }}
				{...rest}
			/>
		</div>
	);
};
