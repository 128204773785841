import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { stockMovementsQuery_single } from 'gen/ApiClient';
import React from 'react';
import { useParams } from 'react-router-dom';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { StockMovementDetailPageHeader } from './StockMovementDetailPageHeader';
import { StockMovementDetailPageSidebar } from './StockMovementDetailPageSidebar';
import { StockMovementDetailContentsCard } from './contents/StockMovementDetailContentsCard';

export const StockMovementDetailPage = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [trigger, reload] = useTrigger();
	const [item, reloadDetail] = useApiEffect(stockMovementsQuery_single, id);

	useLazyEffect(() => {
		reloadDetail();
	}, [trigger]);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='StockMovements'
			stickyHeader={
				<StockMovementDetailPageHeader
					item={item}
					reload={reload}
				/>
			}
			stickySidebar={<StockMovementDetailPageSidebar item={item} />}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
					maxWidth: 1280,
				}}>
				<div className='df-row-wrap gap-16'>
					<StockMovementDetailContentsCard
						item={item}
						style={{ flexGrow: 2 }}
						className='h-fc'
					/>
				</div>
			</div>
		</RestrictedPageX>
	);
};
