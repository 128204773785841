import React from 'react';
import { createHeader } from 'framework/table/createHeader';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISalesLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	lines: ISalesLine[];
}

export const SalesLinesTable = ({ lines }: IProps) => {
	const strings = useLocalization();

	const headers = [
		createHeader<ISalesLine>('#', t => t.indexNumber.toString()),
		createHeader<ISalesLine>(strings.description, t => t.description),
		createHeader<ISalesLine>(strings.quantity, t => t.quantity.toString()),
		createHeader<ISalesLine>(`${strings.vatPercentage}`, t => `${t.vatPercentage} %`),
		createHeader<ISalesLine>(`${strings.discount} (%)`, t => `${t.discountPercentage.toFixed(2)} %`),
		createHeader<ISalesLine>(`${strings.price} (${strings.inVatShort})`, t => formatCurrency(t.linePriceInVat)),
	];

	return (
		<TableWithHeadersAndValues<ISalesLine>
			values={lines}
			headers={headers}
			getKey={t => t.id}
			withPaperContainer
		/>
	);
};
