import { IStrings } from 'localization/IStrings';
import { ProductCatalogItemExportProp } from 'gen/ApiClient';

export const createExportRecord = (strings: IStrings): Record<ProductCatalogItemExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		Category: strings.category,
		Identifier: strings.name,
		HasBatteries: strings.somethingQuestionmark(strings.batteries),
		HasSerialNumber: strings.somethingQuestionmark(strings.serialNumber),
		IsElectronics: strings.somethingQuestionmark(strings.electronics),
		Manufacturer: strings.manufacturer,
		Model: strings.model,
		Subcategory: strings.subcategory,
		VatPercentage: strings.vatPercentage,
		InternalReference: strings.internalReference,
		ManufacturerReferencesCsv: strings.manufacturerReference,
		SalesListPrice: strings.recommendedSalesprice,
		Name: strings.name,
		SupplierIdentifierCsv: strings.suppliers,
		Type: strings.type,
		PurchaseListPrice: strings.purchaseListPrice,
		RizivHearingAidDefinitionV2IdentificationCodesCsv: strings.RIZIVCode,
		LabelsCsv: strings.labels,
	};
};
