import { DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { FullScreenDialog } from 'framework/dialogs/FullScreenDialog';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IAddOrderLinesRequest, IObjectWithIdAndIdentifier, IOrderV2, ordersCommand_addLines } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import * as yup from 'yup';
import { IOrderLineModel } from '../register/IOrderLineModel';
import { SearchAndSelectProductsForOrder } from '../register/SearchAndSelectProductsForOrder';
import { SearchProductCatalogItemsForOrderProvider } from '../register/SearchProductCatalogItemsForOrderProvider';
import { mapOrderLineModelToAddOrderLineRequest } from './mapOrderLineModelToAddOrderLineRequest';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IAddOrderLinesRequest>({
			lines: yup.mixed(),
			orderId: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	previous?: VoidFunction;
	item: IOrderV2;
	patient?: IObjectWithIdAndIdentifier<string>;
}

export const AddOrderLinesRequestForm = ({ confirm, cancel, previous, item, patient, ...rest }: IProps) => {
	const strings = useLocalization();
	const [lines, setLines] = useState<IOrderLineModel[]>([]);
	const [patch, isSubmitting] = useFormSubmit(ordersCommand_addLines);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IAddOrderLinesRequest, helpers: FormikHelpers<IAddOrderLinesRequest>) => {
		if (lines.length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await patch({ ...values, lines: lines.map(t => mapOrderLineModelToAddOrderLineRequest(t)) });
			if (handleFormResponse(r, helpers)) {
				confirm();
			}
		}
	};

	return (
		<Formik<IAddOrderLinesRequest>
			validateOnMount
			initialValues={{
				orderId: item.id,
				lines: [],
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<SearchProductCatalogItemsForOrderProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						cancel={cancel}
						lines={lines}
						setLines={setLines}
						patient={patient ?? item.singlePatient}
						previous={previous}
					/>
				</SearchProductCatalogItemsForOrderProvider>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	lines: IOrderLineModel[];
	setLines: React.Dispatch<React.SetStateAction<IOrderLineModel[]>>;
	isSubmitting: boolean;
	patient: IObjectWithIdAndIdentifier<string> | undefined;
	previous: VoidFunction | undefined;
}

const InnerDialog = ({ cancel, lines, setLines, isSubmitting, patient, previous, ...rest }: IInnerProps) => {
	const strings = useLocalization();

	return (
		<FullScreenDialog {...rest}>
			<DialogTitle>{strings.addLines}</DialogTitle>
			<DialogContent
				dividers
				className='df-col fg1'>
				<SearchAndSelectProductsForOrder
					lines={lines}
					setLines={setLines}
					patient={patient}
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions<IAddOrderLinesRequest>
				cancel={cancel}
				submitText={strings.addLines}
				isSubmitting={isSubmitting}
				previous={previous}
			/>
		</FullScreenDialog>
	);
};
