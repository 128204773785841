import { Card, CardContent, Chip } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { ISalesAnalysisData } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import BasicAreaChart from './charts/area/BasicAreaChart';

interface IProps {
	queryResult: ISalesAnalysisData;
}

const SalesAnalysisTimeSeriesChartCard = ({ queryResult }: IProps) => {
	const strings = useLocalization();
	const [mode, setMode] = useState<'perMonth' | 'perWeek'>('perMonth');
	const titleAndSeriesName = useMemo(() => (mode === 'perMonth' ? strings.revenueExVatPerMonth : strings.revenueExVatPerWeek), [mode, strings]);
	const kvps = useMemo(() => (mode === 'perMonth' ? queryResult.revenuesPerMonth : queryResult.revenuesPerWeek), [mode, queryResult]);

	return (
		<Card>
			<CardTitleTypography
				title={titleAndSeriesName}
				withPadding
				action={
					<Chip
						label={mode === 'perMonth' ? strings.month : strings.week}
						color='primary'
						size='small'
						style={{ fontSize: '10px', cursor: 'pointer' }}
						onClick={() => setMode(mode === 'perMonth' ? 'perWeek' : 'perMonth')}
						variant={mode === 'perMonth' ? 'default' : 'outlined'}
					/>
				}
			/>
			{kvps.length === 0 && <NoRecordsFound withPaddingTop>{strings.noDataAvailable}</NoRecordsFound>}
			<CardContent>
				<BasicAreaChart
					data={kvps}
					seriesName={titleAndSeriesName}
				/>
			</CardContent>
		</Card>
	);
};

export default SalesAnalysisTimeSeriesChartCard;
