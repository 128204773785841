import { Card, CardContent, CardProps } from '@material-ui/core';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import { DocumentListItemXDefault } from 'documents/DocumentListItemXDefault';
import { DocumentUploadForm } from 'documents/DocumentUploadForm';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { isLast } from 'framework/utils/array/isLast';
import { documentsQuery_byIdAndContextNotDeleted, IDelivery, IDocumentsByContextAndIdParams, LinkViewContextType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';

const DeliveryLinkViewContextType: LinkViewContextType = 'Delivery';

interface IProps extends CardProps, IReloadTriggerProps {
	item: IDelivery;
	reload: VoidFunction;
}
export const DeliveryDetailDocumentsCard = ({ item, reload, reloadTrigger, ...rest }: IProps) => {
	const strings = useLocalization();
	const params = useMemo<IDocumentsByContextAndIdParams>(() => ({ linkViewContextId: item.id, linkViewContextType: DeliveryLinkViewContextType }), [item]);
	const [documents, reloadDocuments] = useApiEffect(documentsQuery_byIdAndContextNotDeleted, params);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadDocuments();
	}, [reloadTrigger]);

	const onNew = () => {
		open(
			<DocumentUploadForm
				title={strings.deliveryNote}
				contextId={item.id}
				contextType='Delivery'
				confirm={confirm}
				cancel={cancel}
				open
			/>
		);
	};

	if (documents === undefined) {
		return <></>;
	}

	return (
		<Card {...rest}>
			<CardContent>
				<CardTitleTypography
					title={strings.documents}
					style={{ marginBottom: 8 }}
				/>
				<TextLinkButton
					startIcon={<PostAddOutlinedIcon />}
					onClick={onNew}
					color='primary'>
					{strings.deliveryNote}
				</TextLinkButton>
				<DividerWithMargin />
				{documents.map(t => (
					<DocumentListItemXDefault
						key={t.id}
						item={t}
						reload={reloadDocuments}
						withoutBorder={isLast(t, documents)}
					/>
				))}
				{documents.length === 0 && <em>{strings.noDocumentsAddedYet}</em>}
			</CardContent>
		</Card>
	);
};
