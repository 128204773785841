import React, { useCallback, useState } from 'react';
import { useApi } from '../hooks/useApi';
import { useLazyEffect } from '../hooks/useLazyEffect';
import { Try } from '../Try';
import { IConcurrencyData } from '../types/IConcurrencyData';
import { IPreloadCacheContext } from './IPreloadCacheContext';

interface IProps<TResult> {
	children: any;
	concurrencyF: () => Promise<Try<number>>;
	loadF: () => Promise<Try<IConcurrencyData<TResult>>>;
	context: React.Context<IPreloadCacheContext<TResult>>;
	initialData: IConcurrencyData<TResult>;
	language?: string;
}

export const PreloadCacheProvider = <TResult extends unknown>({ children, concurrencyF, loadF, context, language, initialData }: IProps<TResult>) => {
	const [data, setData] = useState<TResult>(initialData.value);
	const [setLoad, clearLoad, handleError] = useApi();
	const concurrencyToken = React.useRef<number>(initialData.concurrencyToken);

	useLazyEffect(() => {
		if (language !== undefined) {
			concurrencyToken.current = -1;
			check();
		}
		// eslint-disable-next-line
	}, [language]);

	const load = useCallback(async () => {
		console.log(`(requested) load on CacheProvider ${loadF.name}`);
		const loadId = setLoad();
		const r = await loadF();
		if (r.isSuccess) {
			setData(r.result.value);
			concurrencyToken.current = r.result.concurrencyToken;
		} else {
			handleError(r.error);
		}
		clearLoad(loadId);
		return new Try<TResult>(r.isSuccess ? r.result.value : (undefined as any), r.isFailure ? r.error : undefined);
		// eslint-disable-next-line
	}, []);

	const check = useCallback(async () => {
		console.log(`executing check on ${loadF.name}`);
		const loadId = setLoad();
		const r = await concurrencyF();
		clearLoad(loadId);
		if (r.isFailure) {
			handleError(r.error);
		} else if (r.result > concurrencyToken.current || concurrencyToken.current === -1) {
			// data is updated
			await load();
		}
		// eslint-disable-next-line
	}, []);

	return (
		<context.Provider
			value={{
				data: data,
				concurrencyToken: concurrencyToken.current,
				load: load,
				init: check,
			}}>
			{children}
		</context.Provider>
	);
};
