import { nlFrameworkStrings } from 'framework/localization/nlFrameworkStrings';
import { IStrings } from './IStrings';

export const nlStrings: IStrings = {
    ...nlFrameworkStrings,
    acceptFittingContent: `Ga verder naar facturatie of beëindig deze flow`,
    accessToFunctions: `Toegang tot functies`,
    accessToPages: `Toegang tot pagina's`,
    accountNumber: `Account nummer`,
    accountPayable: `Leverancierrekening`,
    accountReceivable: `Klantenrekening`,
    accountReceivableSingularOrPlural: `Klantenrekening(-en)`,
    accountsPayable: `Leveranciersrekeningen`,
    accountsPayableShort: `Leveranciersrek.`,
    accountsReceivable: `Klantenrekeningen`,
    accountsReceivableShort: `Afrekeningsv.`,
    action: `Actie`,
    actionsWillBeDeletedFromThisList: `De acties worden verwijderd uit deze lijst`,
    actionsWillBeVisibleAgainInThisList: `De acties worden terug zichtbaar in deze lijst`,
    activated: `Geactiveerd`,
    activateExactOnlineIntegration: `Activeer Exact Online koppeling`,
    activateIntegration: `Integratie activeren`,
    activateVerb: `Activeer`,
    active: `Actief`,
    activeAndExpired: `Actief & verlopen`,
    activeNow: `Nu actief`,
    activeTreatments: `Lopende behandelingen`,
    actual: `Actueel`,
    actualDeliveryDate: `Actuele leverdatum`,
    actualEndDate: `Actuele einddatum`,
    actualEquipment: `Actuele uitrusting`,
    add: `Toevoegen`,
    addALine: `Voeg een lijn toe`,
    addALineForExampleDiscount: `Voeg een lijn toe (bv. korting)`,
    addALineWithDifference: `Voeg een lijn toe met het verschil`,
    addAnEmailAdress: `Voeg een e-mailadres toe`,
    addArticlesFromProformaHandover: `Voeg artikelen van proforma bijlage 12 toe`,
    addAttachment: `Voeg een bijlage toe`,
    addContactInfo: `Voeg contact info toe`,
    addContactPerson: `Voeg contactpersoon toe`,
    addDefineInventoryItem: `Voorraadartikel toevoegen (definiëren)`,
    addDevicesFromNoah: `Voeg toestellen van Noah toe`,
    addedWhat: (what: string | number) => `${what} aangemaakt`,
    addEmailAddress: `Voeg e-mail adres toe`,
    addImperative: `Voeg toe`,
    addItemForRepair: `Item toevoegen aan herstelling`,
    addItems: `Voeg items toe`,
    additionalNomenclatureCodes: `Bijkomende nomenclatuurcode(s)`,
    additionalNomenclatures: `Bijkomende nomenclaturen`,
    addLines: `Lijnen toevoegen`,
    addLinesToExistingOrder: `Lijnen toevoegen aan bestaande bestelling`,
    addLinesToThisOrder: `Voeg lijnen toe aan deze bestelling`,
    addLocation: `Locatie toevoegen`,
    addNeeds: `Behoeften toevoegen`,
    addOffice: `Praktijk toevoegen`,
    addProcess: `Voeg proces toe`,
    addRemark: `Voeg opmerking toe`,
    addReservedArticles: `Voeg gereserveerde artikelen toe`,
    address: `Adres`,
    addressData: `Adresgegevens`,
    addressLine: `Straat en nummer`,
    addressUpdated: `Adres gewijzigd`,
    addToExistingRepair: `Voeg toe aan een bestaande herstelling`,
    addToExistingRepairCaption: `Artikel wordt toegevoegd aan bestaande (nog niet verzonden) herstelling`,
    addToSettlementProposal: `Voeg toe aan afrekeningsvoorstel`,
    adjustWhat: (what: string | number) => `Pas ${what.toString().toLowerCase()} aan`,
    administratively: `Administratief`,
    advance: `Voorschot`,
    advancedFilters: `Geavanceerde filters`,
    advancedInventoryFilters: `Geavanceerde voorraad filters`,
    advanceInvoice: `Voorschotfactuur`,
    advanceInvoices: `Voorschotfacturen`,
    advisoryPhysician: `Adviserend geneesheer`,
    after: `Na`,
    afterAccents: `Ná`,
    afterDate: `Na datum`,
    afterTreatment: `Nabehandeling`,
    age: `Leeftijd`,
    agenda: `Agenda`,
    aHearingAidCreatefromLimitativeListNoahOrManual: `Een hoortoestel (alle types)\nMaak vanuit de limitatieve lijst, Noah of manueel`,
    all: `Alles`,
    allAppointments: `Alle afspraken`,
    allArticlesLinked: `Alle artikelen gelinkt`,
    allCommissions: `Alle commissies`,
    allCustomersLinked: `Alle klanten gelinkt`,
    allDevicesFromNoah: `Alle toestellen van Noah`,
    allFields: `Alle velden`,
    allForwarded: `Alles doorgestuurd`,
    allocateArticlesFromStock: `Artikelen toewijzen vanuit de stock`,
    allPayouts: `Alle uitbetalingen`,
    allRefundsAreCollectedOnASettlementProposal: `Alle terugbetalingen staan op een verzamelstaat`,
    allSales: `Alle verkopen`,
    allSalesSynced: `Alle verkopen gesynchroniseerd`,
    allUsers: `Alle gebruikers`,
    allWhat: (what: string | number) => `Alle ${what.toString().toLowerCase()}`,
    allWorkscheduleEntries: `Alle ingaves`,
    almostAlways: `Bijna altijd`,
    almostNever: `Bijna nooit`,
    alreadyPaid: `Reeds betaald`,
    alreadyProformaAttachmentPresentDeleteFirstQuestion: `Er is reeds een proforma bijlage 12 aangemaakt.\n\nWil je deze data eerst verwijderen of wil je enkel het document opnieuw genereren?`,
    alsoChangeAdvisoryPhysicianAddress: `Wijzig ook het adres van de adviserend geneesheer`,
    alsoChangeThirdPayerArrangementAddress: `Wijzig ook het adres van de derdebetalersregeling`,
    alsoChangeWithPatient: `Pas ook aan bij patiënt?`,
    alsoCreatePatientInNoah: `Patiënt ook aanmaken in Noah?`,
    alsoExistsInNoah: `Bestaat ook in Noah`,
    alsoSelectACharger: `Selecteer ook een oplader`,
    alsoSelectAnEarmould: `Selecteer ook een oorstukje`,
    alsoSelectAReceiver: `Selecteer ook een receiver`,
    alsoShowDeleted: `Toon ook verwijderde`,
    alsoShowRemarksOfPatient: `Toon ook opmerkingen van patiënt`,
    alsoUpdateOnProcesses: `Pas ook aan bij processen`,
    alternativeAddress: `Alternatief adres`,
    alternativeLogo: `Alternatief logo`,
    alwaysImport: `Altijd importeren`,
    ambulatory: `Ambulant`,
    amountAsValue: `Bedrag`,
    amountFromSale: `Bedrag van verkoop`,
    amountMinusContributions: `Bedrag minus tegemoetkomingen`,
    amountPaid: `Bedrag betaald`,
    amountToPay: `Bedrag te betalen`,
    analysis: `Analyze`,
    anamnesis: `Anamnese`,
    anExample: `Een voorbeeld`,
    anItem: `Een item`,
    annotation: `Aantekening`,
    annotations: `Aantekeningen`,
    applicable: `Van toepassing`,
    apply: `Pas toe`,
    applyCodeForAllArticlesOfWhatQuestion: (what: string | number) => `Code toepassen voor alle artikelen van '${what.toString().toLowerCase()}'?`,
    applyOn: `Toepassen op`,
    appNotYetRegistered: `App nog niet geregistreerd`,
    appointment: `Afspraak`,
    appointmentPlanned: `Afspraak ingepland`,
    appointments: `Afspraken`,
    appRegistered: `App geregistreerd`,
    approval: `Goedkeuring`,
    approvalAdvisoryPhysician: `Goedkeuring adviserend geneesheerd`,
    approvalAdvisoryPhysicianCaption: `Goedkeuring adviserend geneesheer ontvangen en ondertekend`,
    approvalAdvisoryPhysicianReceived: `Goedkeuring adv. geneesheer ontvangen`,
    approvalDate: `Datum goedkeuring`,
    approvalEntPhysicianCaption: `Medisch voorschrift ontvangen en ondertekend door NKO arts`,
    approved: `Goedgekeurd`,
    approvedByAdvisoryPhysicianShort: `Goedgekeurd adv. g.`,
    aRemoteDevice: `Een toestel op afstand (remote)`,
    areYouSureYouWantToCancelThisOrderQuestion: `Ben je zeker dat je deze bestelling wil annuleren?`,
    areYouSureYouWantToDeleteThisAppointmentQuestion: `Ben je zeker dat je deze afspraak wil verwijderen?`,
    areYouSureYouWantToDeleteThisEntPhysician: `Ben je zeker dat je deze NKO arts wil verwijderen?`,
    article: `Artikel`,
    articleFromProductCatalogFoundWithReference: (whatX: string | number) => `Artikel uit product cataloog gevonden (ref. leverancier: ${whatX})`,
    articleIsRegisteredAsDemo: `Artikel is geregistreerd als een DEMO toestel`,
    articleMayNotHaveASerialNumberRemoveFromAllowances: `Product mag geen serienummer bevatten, verwijder dit uit de toeslagen`,
    articleNotFoundInProductCatalogWithReference: (whatX: string | number) => `Artikel niet gevonden in product cataloog (ref. leverancier: ${whatX})`,
    articleNumber: `Artikel #`,
    articleReservedForWho: (who: string | number) => `Artikel gereserveerd voor ${who}`,
    articles: `Artikelen`,
    articlesInquiries: `Artikelen-verzoeken`,
    articlesInquiry: `Artikelen-verzoek`,
    articlesInquiryToAssignSingularOrPlural: `Toe te wijzen artikelen-verzoek(-en)`,
    articlesRegistered: `Artikelen geregistreerd`,
    askEveryTime: `Elke keer opnieuw vragen`,
    askEveryTimeHandoverModeCaption: `Bij elke aflevering terug opnieuw vragen`,
    assigned: `Toegewezen`,
    assignFromStock: `Wijs toe uit voorraad`,
    atPatient: `Bij patiënt`,
    atSupplier: `Bij leverancier`,
    atSupplierForRepair: `Bij leverancier voor herstelling`,
    attachment: `Bijlage`,
    attachment12AndInvoice: `Bijlage 12 en factuur`,
    attachments: `Bijlagen`,
    attendingAudiologistWillAlsoBeChangedOnOtherProcesses: `De behandelende audioloog wordt OOK gewijzigd bij de lopende processen`,
    attendingAudiologistWillAlsoBeChangedWithPatient: `De behandelende audioloog wordt OOK gewijzigd bij de patiënt`,
    attendingAudiologistWillNotBeChangedWithPatient: `De behandelende audioloog wordt NIET gewijzigd bij de patiënt`,
    attendingAudiologistWillOnlyBeChangedWithThisPatientAndNotOnProcesses: `De behandelende audioloog wordt enkel gewijzigd bij de patiënt en niet bij de processen`,
    attendingDoctors: `Behandelende artsen`,
    audiogram: `Audiogram`,
    audiograms: `Audiogrammen`,
    audiologist: `Audioloog`,
    authorization: `Authorisatie`,
    autoGenerateSerialNumberQuestion: `Serienummer automatisch genereren?`,
    autoPatientNumbering: `Automatisch patiënten nummeren`,
    autoReloadInXSeconds: (count: string | number) => `Automatisch herladen in ${count.toString().toLowerCase()} second(-en)`,
    autoSelectIndexNumberQuestion: `Automatisch volgnummer kiezen?`,
    autoSelectPaymentReferenceQuestion: `Automatisch betaalreferentie kiezen?`,
    autoSetOrderNumber: `Bestelnummer automatisch instellen`,
    available: `Beschikbaar`,
    availableForWhat: (what: string | number) => `Beschikbaar voor ${what.toString().toLowerCase()}`,
    availableHifsAutomaticallySyncedWithRizivData: `De beschikbare verzekeringsinstellingen worden automatisch overgenomen van de gegevens op de website van het RIZIV`,
    availableInCalendar: `Beschikbaar in kalender`,
    availableInCalendarCaption: `Op deze locatie kunnen afspraken worden ingepland.`,
    average: `Gemiddeld`,
    averageNumberOfHoursPerDayWearingHearingAids: `Gemiddelde draagtijd`,
    averagePurchasePrice: `Gemiddelde aankoopprijs`,
    b10Caption: `Geel | 5,8x3,6mm | Mini RITE of CIC`,
    b13Caption: `Rood | 7,9x5,4mm | BTE of ITE`,
    b312Caption: `Bruin | 7,9x3,6mm | Mini BTE of RITE of ITC`,
    b675Caption: `Blauw | 11,6x5,4mm | Power BTE`,
    balance: `Saldo`,
    bancontact: `Bancontact`,
    bankData: `Bankgegevens`,
    bankTransfer: `Overschrijving`,
    base: `Basis`,
    basedOn: `Gebaseerd op`,
    baseInformation: `Basis informatie`,
    batteries: `Batterijen`,
    batteryType: `Type batterij`,
    bebatRate: `Tarief Bebat`,
    before: `Voor`,
    beforeAccents: `Vóór`,
    behindTheEar: `Achter het oor`,
    beIDMiddlewareIsNotInstalled: `De BeID middleware is niet geïnstalleerd`,
    better: `Beter`,
    birthDateUnknown: `Geboortedatum ongekend`,
    block: `Blokkeer`,
    blocked: `Geblokkeerd`,
    blockUser: `Blokkeer gebruiker`,
    blockUserContent: `Een geblokkeerde gebruiker kan niet meer aanmelden.`,
    blockUserQuestion: `Gebruiker blokkeren?`,
    boneAnchorage: `Botverankering`,
    brand: `Merk`,
    brandAndModel: `Merk en model`,
    brandName: `Merknaam`,
    brands: `Merken`,
    broken: `Defect`,
    bteCaptionTypes: `Achter het oor (RITE, Power, Open Ear, Open Fit, …)`,
    bulkImport: `Importeren in bulk`,
    bulkRepair: `Bulk herstelling`,
    calculation: `Berekening`,
    calculations: `Berekeningen`,
    calendar: `Kalender`,
    canBeUsedForWhat: (what: string | number) => `Kan gebruikt worden voor ${what.toString().toLowerCase()}`,
    cancelArticlesInquiry: `Annuleer artikelen-verzoek`,
    cancellationReason: `Reden van annulatie`,
    cancelled: `Geannuleerd`,
    cancelledAfterPartlyDelivery: `Geannuleerd na deellevering`,
    cancelledAfterShipment: `Geannuleerd (na verzending)`,
    cancelledBeforeShipment: `Geannuleerd (voor verzending)`,
    cancelledWhat: (what: string | number) => `${what} geannuleerd`,
    cancelLoan: `Annuleer uitlening`,
    cancelOrder: `Bestelling annuleren`,
    cancelSomething: (something: string | number) => `Annuleer ${something.toString().toLowerCase()}`,
    cancelWhat: (what: string | number) => `Annuleer ${what.toString().toLowerCase()}`,
    cannotChangeRepititionToDoThisDeleteEntireSequence: `Kan herhaling niet wijzigen, verwijder hiervoor de volledige reeks`,
    cannotReachServerNoInternetConnection: `Kan de server niet bereiken, geen internetverbinding`,
    canReturn: `Kan worden beëindigd`,
    canViewInAppOrBrowser: `In browser (in app) te bekijken`,
    cash: `Cash`,
    cashDesk: `Kassa`,
    cashDeskLocation: `Gebruikt kassa`,
    cashRegisterAsLedger: `Kasboek`,
    cashSale: `Contante verkoop`,
    cashSaleForPatient: `Contante verkoop voor patiënt`,
    cashSales: `Contante verkopen`,
    cashSalesAreNotLinkedToAnAccountReceivableGoToCashRegister: `Contante verkopen worden niet toegekend aan een klantenrekening\nGa hiervoor naar kassa-module`,
    cashTransaction: `Cash transactie`,
    cashTransactions: `Cash transacties`,
    catalogNumber: `Cataloog nummer`,
    categories: `Categorieën`,
    category: `Categorie`,
    change: `Wijzig`,
    changed: `Gewijzigd`,
    changeDateOrNomenCode: `Wijzig datum of wijzig nomenclatuurcodes`,
    changeDefaultDeliveryTime: `Wijzig standaard levertermijn`,
    changeDetailsEntPhysician: `Wijzig gegevens NKO arts`,
    changedWhat: (what: string | number) => `${what} gewijzigd`,
    changeEmail: `Wijzig e-mailadres`,
    changeName: `Wijzig naam`,
    changeOnlyAdvisoryPhysicianAddress: `Wijzig alleen het adres van de adviserend geneesheer`,
    changeOnlyThirdPayerArrangementAddress: `Wijzig alleen het adres van de derdebetalersregeling`,
    changeOrientation: `Wijzig oriëntatie`,
    changePassword: `Wijzig wachtwoord`,
    changePhone: `Wijzig telefoonnummer`,
    changePrice: `Wijzig prijs`,
    changePrices: `Pas prijzen aan`,
    changeProcessSteps: `Wijzig de te volgen stappen`,
    changeProductCatalog: `Wijzig product cataloog`,
    changeRemark: `Wijzig opmerking`,
    changeRIZIVNumber: `Wijzig RIZIV nummer`,
    changeSomething: (something: string | number) => `Wijzig ${something.toString().toLowerCase()}`,
    changeStartModule: `Pas aan (start module)`,
    changesToDetailScreen: `Wijziging detail scherm`,
    changesToDetailScreenAlertDescription: `Binnenkort wordt het detailscherm van een patiënt vervangen door een verbeterde weergave.\nDit scherm is de nieuwe, verbeterde weergave.`,
    changeSupplierContactPerson: `Wijzig contactpersoon leverancier`,
    changeWhat: (what: string | number) => `Wijzig ${what.toString().toLowerCase()}`,
    charger: `Oplader`,
    chargerNewArticleCaption: `Oplader (voor heroplaadbare hoortoestellen)`,
    checkAgain: `Controleer opnieuw`,
    checkDocuments: `Controle van documenten`,
    checkoutVerb: `Afrekenen`,
    circularLetters: `Omzendbrieven`,
    city: `Stad/Plaats`,
    clearFilter: `Verwijder filter`,
    clearLink: `Verwijder link`,
    clearRIZIVContent: `De gebruiker wordt gemarkeerd als 'geen' audioloog`,
    clearRIZIVQuestion: `RIZIV link verwijderen?`,
    click: `Klik`,
    clickHere: `Klik hier`,
    clickHereForMoreInformationAboutROSA: `Klik hier voor meer informatie over ROSA`,
    clickHereToNavigateToNewView: `Klik hier om te navigeren naar de nieuwe weergave.`,
    clickHereToViewDocumentation: `Klik hier om documentatie te bekijken`,
    clickToAdd: `Klik om toe te voegen`,
    clickToViewDetails: `Klik om details te bekijken`,
    clickToViewHamsPatient: `Klik hier om HAMS patiënt te bekijken`,
    clickToViewItemWithSerialNumber: `Klik om item met dit serienummer te bekijken`,
    clickToViewMore: `Klik om meer te bekijken`,
    clickToViewProductCatalog: `Klik om product cataloog te bekijken`,
    close: `Sluiten`,
    closeSearch: `Zoeken afsluiten`,
    closeThisWindow: `Sluit dit venster`,
    cltTest: `CLT test`,
    code: `Code`,
    codesLinkedToRequest: (codes: string | number) => `${codes} gelinkt aan aanvraag`,
    codesSingularOrPlural: `Code(s)`,
    color: `Kleur`,
    colorScheme: `Kleurschema`,
    column: `Kolom`,
    combinationOfBrandAndModelExists: `Deze combinatie van merk en model bestaat reeds.`,
    commission: `Commissie`,
    commissions: `Commissies`,
    companyData: `Bedrijfsgegevens`,
    companyName: `Bedrijfsnaam`,
    comparedWithSamePeriodLastYearBracketsWhat: (what: string | number) => `Vergeleken met dezelfde periode vorig jaar (${what.toString().toLowerCase()})`,
    comparisonAll: `Vergelijking alles`,
    comparisonStereo: `Vergelijking stereo`,
    compensation: `Tegemoetkoming`,
    completed: `Afgewerkt`,
    completelyDelivered: `Volledig geleverd`,
    configuration: `Configuratie`,
    configurationCorrect: `Configuratie correct`,
    confirm: `Bevestigen`,
    confirmation: `Bevestiging`,
    confirmChoice: `Bevestig keuze`,
    confirmDelivery: `Bevestig levering`,
    confirmed: `Bevestigd`,
    confirmNewPassword: `Bevestig nieuw wachtwoord`,
    confirmPassword: `Bevestig wachtwoord`,
    confirmUserHeader: `Vul een wachtwoord in`,
    connection: `Connectie`,
    connectionStatus: `Connectiestatus`,
    connectReader: `Connecteer een kaartlezer`,
    consignment: `Consignatie`,
    consignmentData: `Consignatie data`,
    consignmentExpired: `Consignatie verlopen`,
    consignmentExpiresWithinAWeek: `Consignatie datum verloopt binnen één week`,
    consignmentWarning: `Consignatie waarschuwing`,
    contact: `Contact`,
    contactAHamsAdminUserForMoreInformation: `Contacteer een HAMS admin gebruiker voor meer informatie`,
    contactData: `Contactgegevens`,
    contactHAMSForValidLicense: `Contacteer HAMS voor een geldige licentie`,
    contactInfoAdded: `Contact info toegevoegd`,
    contactInfoUpdated: `Contact info gewijzigd`,
    contactPerson: `Contactpersoon`,
    contacts: `Contacten`,
    contactWho: (who: string | number) => `Contacteer ${who}`,
    contents: `Inhoud`,
    continue: `Ga verder`,
    contraLateralEquipment: `Contralateraal`,
    contribution: `Tegemoetkoming`,
    contributionCodeForPatient: (code: string | number, who: string | number) => `Tegemoetkoming ${code.toString().toLowerCase()} voor ${who}`,
    contributions: `Tegemoetkomingen`,
    conventionedEqualsPrecededByOne: `Geconventioneerd = voorafgegaan door '1'`,
    conventionedRIZIVNumber: `Geconventioneerd RIZIV nummer`,
    conventionedRIZIVNumberShort: `Geconv. RIZIV #`,
    convertedToSales: `Geconverteerd naar verkoop`,
    copyVatDataFromPatient: `Neem data over van Patiënt`,
    cosiNeed: `COSI behoefte`,
    cosiQuestionnaire: `COSI vragenlijst`,
    countArticlesNotLinked: (count: string | number) => `${count} artikelen niet gelinkt`,
    countCustomersNotLinked: (count: string | number) => `${count} klantent niet gelinkt`,
    countDeliveries: `Aantal leveringen`,
    countEventsAreLinkedToThisLocationDoYouWantToContinueQuestion: (count: string | number) => `${count} afspraken zijn gelinkt met deze locatie.
Als je verdergaat wordt deze locatie verwijderd van deze afspraken.
Ben je zeker dat je wil verdergaan?`,
    countEventsAreLinkedToThisUserDoYouWantToContinueQuestion: (count: string | number) => `${count} afspraken zijn gelinkt met deze gebruiker.
Als je verdergaat wordt deze gebruiker verwijderd van deze afspraken.
Ben je zeker dat je wil verdergaan?`,
    countFilesInBuffer: `# bestanden in buffer`,
    countInvoicesNotForwarded: (count: string | number) => `${count} facturen niet doorgestuurd`,
    countOrders: `Aantal bestellingen`,
    countOrdersToPlaceWithSupplier: (count: string | number) => `# ${count.toString().toLowerCase()} bestellingen te plaatsen bij leverancier`,
    countPatientRecordsFromRosaNotImported: (count: string | number) => `${count} patiënt(-en) van ROSA niet geïmporteerd.`,
    countPatientsImported: (count: string | number) => `${count} patiënt(-en) geïmporteerd`,
    countPatientsNotImported: (count: string | number) => `${count} patiënt(-en) niet geïmporteerd`,
    countPurchases: `Aantal aankopen`,
    countRosaUsersNotLinked: (count: string | number) => `${count} ROSA gebruiker(s) niet gelinkt`,
    country: `Land`,
    countSalesNotSynced: (count: string | number) => `${count} verkopen niet gesynchroniseerd`,
    countUsersLinked: (count: string | number) => `${count} gebruikers gelinkt`,
    countUsersNotLinked: (count: string | number) => `${count} gebruikers niet gelinkt`,
    create: `Creëer`,
    createADocumentRightAwayQuestion: `Creëer meteen een document?`,
    createANewPatientInHAMS: `Maak een nieuwe patiënt in HAMS`,
    createANewSupplier: `Creëer een nieuwe leverancier`,
    createAnOrderForThisNeed: `Maak een nieuwe bestelling voor deze behoefte`,
    created: `Aangemaakt`,
    createdAt: `Gemaakt op`,
    createdBracketsHow: (how: string | number) => `Aangemaakt (${how.toString().toLowerCase()})`,
    createdByUser: `Aangemaakt door gebruiker`,
    createdFromUploadWithSignature: `Aangemaakt (upload) mét handtekening`,
    createDocument: `Maak document`,
    createDocumentFromPersonalisedVersion: `Creëer een document van een gepersonaliseerde versie`,
    createDocumentFromStandardisedVersion: `Creëer een document van de gestandardiseerde versie`,
    createDocumentsRightAwayQuestion: `Creëer meteen documenten?`,
    createInHamsAndNoah: `Creëer in HAMS en Noah`,
    createNewCRMAccountInExactOnline: `Maak nieuw CRM account in Exact Online`,
    createNewEvent: `Maak een nieuw event`,
    createNewPatient: `Maak nieuwe patiënt aan`,
    createNewRule: `Creëer nieuwe regel`,
    createOnlyInHams: `Creëer enkel in HAMS, NIET in Noah`,
    createPatientFromFormCaption: `Vul handmatig alle gegevens in`,
    createPatientFromIdentityCardCaption: `Creëer een nieuwe patiënt op basis van de eID-kaart`,
    createPatientFromNhaxCaption: `Importeer een patiënt vanuit een andere Noah installatie\n(.nhax of .enhax)`,
    createPatientInNoah: `Creëer patiënt in Noah`,
    createPatientInRosa: `Patiënt aanmaken in ROSA`,
    createProformaAttachment12Content: `Via deze weg kan je een bijlage 12 aanmaken VÓÓR de definitieve aflevering van de toerusting.\n\nDeze bijlage 12 is NIET de definitieve bijlage, de definitieve wordt aangemaakt bij de aflevering.\n\nDit document wordt NIET verwijderd bij de definitieve aflevering\n\nWil je verdergaan?`,
    createServiceOrGoods: `Maak een nieuw goed of dienst aan`,
    createSlashSyncSaleInExactOnline: `Verkoop aanmaken/syncen in Exact Online`,
    createSomething: (something: string | number) => `Creëer ${something.toString().toLowerCase()}`,
    createStrCommaWhat: (str: string | number, what: string | number) => `Creëer ${str.toString().toLowerCase()}, ${what.toString().toLowerCase()}`,
    createTenderAndOrderForm: `Offerte (en bestelbon) opstellen`,
    createWhat: (what: string | number) => `Creëer ${what.toString().toLowerCase()}`,
    createWhatInProductCatalog: (what: string | number) => `Creëer '${what.toString().toLowerCase()}' in product cataloog`,
    creditNote: `Kredietnota`,
    creditNoteDate: `Datum kredietnota`,
    creditNoteLine: `Kredietnota lijn`,
    creditNotePurchase: `Kredietnota aankoop`,
    creditNoteSale: `Kredietnota verkoop`,
    creditPurchaseVerb: `Aankoop krediteren`,
    creditStatus: `Kredietstatus`,
    creditVerb: `Crediteren`,
    crmAccountOrCustomerDoesNotExistYetInExactOnline: `CRM account (klant) bestaat nog niet in Exact Online`,
    currentFeePersonalShare: `Huidig persoonlijk aandeel`,
    currentFeeValue: `Huidige tegemoetkoming`,
    currentLocation: `Huidige locatie`,
    currentlyLoggedInOnDevices: (count: string | number) => `Momenteel aangemeld op ${count.toString().toLowerCase()} toestellen`,
    currentlyLoggedInOnOneDevice: `Momenteel aangemeld op 1 toestel`,
    currentlyNotLoggedIn: `Momenteel niet aangemeld`,
    currentPrice: `Huidige prijs`,
    customer: `Klant`,
    customerAccountIsNotLinkedInExactOnline: `Klantenrekening is niet gelinkt met Exact Online`,
    customerIsAPatient: `Klant is een patiënt`,
    customerIsNotAPatient: `Klant is géén patiënt`,
    customerSingularOrPlural: `Klant(-en)`,
    customWorkflows: `Custom workflows`,
    dailyReceipt: `Dagontvangst`,
    dailyReceipts: `Dagontvangsten`,
    dashboard: `Dashboard`,
    data: `Gegevens`,
    dataNotReadViaeID: `Gegevens niet uitgelezen via eID-kaart`,
    dataPaging: (fc: string | number, ufc: string | number, what: string | number, index: string | number, total: string | number) => `${fc}/${ufc.toString().toLowerCase()} ${what.toString().toLowerCase()}. Pagina ${index.toString().toLowerCase()} van ${total.toString().toLowerCase()}`,
    dataReadViaEID: `Gegevens uitgelezen via eID`,
    date: `Datum`,
    dateApprovalAdvisoryPhysician: `Datum goedkeuring adviserend geneesheer`,
    dateBinFormat: `BIN standaard datum notatie (jaar-maand-dag)`,
    dateCreated: `Datum aangemaakt`,
    dateDayMonthYear: `Datum (dag/maand/jaar)`,
    dateLastAudiogram: `Datum laatste audiogram`,
    dateLastChangedInNoah: `Datum laatst aangepast in Noah`,
    dateLastHandover: `Datum laatste aflevering`,
    dateLastOrder: `Datum laatste bestelling`,
    dateLastSelection: `Datum laatste selectie`,
    dateOfBirth: `Geboortedatum`,
    dateOfBirthShort: `Geboortedat.`,
    dateOfDispatch: `Datum van verzending`,
    datePlusTimeDayMonthYearHourMinutes: `Datum + tijdstip (dag/maand/jaar uur:minuten)`,
    dateReturnBefore: `Datum retour vóór`,
    dateStartLoan: `Datum start van uitlenen`,
    dateUploaded: `Datum opgeladen`,
    dBAgainstVocalIndex: `dB tegen vocale index`,
    deactivated: `Gedeactiveerd`,
    deactivateExactOnlineIntegration: `Exact Online koppeling deactiveren`,
    deactivateIntegration: `Integratie de-activeren`,
    deactivateVerb: `Deactiveer`,
    deactivePaymentMethod: `Betaalmethode uitschakelen?`,
    deactivePaymentMethodContent: `Deze betaalmethode is vervolgens niet meer beschikbaar`,
    deactivePaymentMethodForWhatContent: (what: string | number) => `Deze betaalmethode is vervolgens niet meer beschikbaar voor ${what.toString().toLowerCase()}`,
    deactivePaymentMethodForWhatQuestion: (what: string | number) => `Betaalmethode uitschakelen voor ${what.toString().toLowerCase()}?`,
    deceased: `Overleden`,
    defaultBlockDuration: `Standaard duurtijd één blok`,
    defaultDeliveryTime: `Standaard levertijd`,
    defaultDuration: `Standaard duurtijd`,
    defaultFailedTrialVatPercentage: `Standaard BTW percentage forfaitaire tegemoetkoming`,
    defaultInvoiceProcessSteps: `Standaard facturatiestappen`,
    defaultOffice: `Standaard praktijk`,
    defaultRepairTime: `Standaard hersteltijd`,
    defaultSteps: `Standaard stappen`,
    defaultView: `Standaard VIEW`,
    defaultWarranty: `Standaard garantie`,
    defaultWhat: (what: string | number) => `Standaard ${what.toString().toLowerCase()}`,
    defineAndOrderOrSelectNeedsFromStock: `Definieer and bestel/selecteer behoeften`,
    defineAndOrderOrSelectNeedsFromStockCaption: `Artikelen in een uitlening, aanvraag tot VT, offerte, factuur, ... starten vanuit deze behoeften`,
    defineNeeds: `Definieer behoeften`,
    defineNewInventoryItem: `Nieuw voorraadartikel definiëren`,
    degreeOfChange: `Mate van verandering`,
    deleteAddressAdvisoryPhysician: `Verwijder adres adviserend geneesheer`,
    deleteAddressThirdPartyPayer: `Verwijder adres derde betalersregeling`,
    deleteAndOrAddLines: `Lijnen toevoegen en/of verwijderen`,
    deleteAppointmentQuestion: `Afspraak verwijderen?`,
    deleteAttachment17: `Verwijder bijlage 17`,
    deleteContactInfo: `Contact info verwijderen`,
    deleted: `Verwijderd`,
    deleteData: `Verwijder de data`,
    deletedWhat: (what: string | number) => `${what} verwijderd`,
    deleteEmailAddress: `E-mail adres verwijderen`,
    deleteEntPhysician: `Verwijder NKO arts`,
    deleteEntPhysicianQuestion: `NKO arts verwijderen?`,
    deleteFromRequest: `Verwijder uit aanvraag`,
    deleteGeneralPractitioner: `Verwijder huisarts`,
    deleteGeneralPractitionerQuestion: `Huisarts verwijderen?`,
    deleteInventoryItemFromArticleNeedQuestion: `De link met deze behoefte wordt verwijderd 
alsook de reservatie op dit voorraadartikel.
Verdergaan?`,
    deleteItemFromProductCatalog: `Item verwijderen uit de product cataloog`,
    deleteItemFromProductCatalogContent: `Door het product te verwijderen is het niet langer selecteerbaar\nbij een uitlening, proef, verkoop, aankoop, ...\n\nWil je verdergaan?`,
    deleteLine: `Lijn verwijderen`,
    deleteLink: `Link verwijderen`,
    deleteLinkImperative: `Verwijder link`,
    deleteLinkWithInventoryItem: `Verwijder link met voorraadartikel`,
    deleteLocation: `Locatie verwijderen`,
    deleteLocationQuestion: `Locatie verwijderen?`,
    deleteManualAdjustment: `Manuele aanpassing verwijderen`,
    deleteNeed: `Behoefte verwijderen`,
    deleteNeedAndPropertiesQuestion: `Selectie artikel en eigenschappen verwijderen?`,
    deleteOffice: `Praktijk verwijderen`,
    deletePermanently: `Permanent Verwijderen`,
    deleteProduct: `Verwijder product`,
    deleteProductQuestion: `Ben je zeker dat je dit product wil verwijderen?`,
    deleteScanHistory: `Scan historiek verwijderen`,
    deleteSettlementProposalWithReferenceQuestion: (reference: string | number) => `Afrekeningsvoorstel ${reference.toString().toLowerCase()} verwijderen?`,
    deleteSupplier: `Leverancier verwijderen`,
    deleteUblFromBuffer: `UBL verwijderen uit buffer`,
    deleteUblFromBufferQuestionConfirmation: `Wil je deze UBL uit de bufferlijst verwijderen?`,
    deleteUser: `Verwijder gebruiker`,
    deleteUserContent: `Deze actie is onomkeerbaar. Alle data verbonden aan deze gebruiker wordt verwijderd.`,
    deleteUserQuestion: `Verwijder gebruiker?`,
    deleteWhat: (what: string | number) => `Verwijder ${what.toString().toLowerCase()}`,
    deleteWhatQuestion: (what: string | number) => `${what} verwijderen?`,
    delivered: `Geleverd`,
    deliveredToPatient: `Afgeleverd aan patiënt`,
    deliveries: `Leveringen`,
    deliverToPatient: `Afleveren aan patiënt`,
    delivery: `Levering`,
    deliveryDate: `Datum levering`,
    deliveryHelperText: `De locatie waar de levering is ontvangen`,
    deliveryNote: `Levernota`,
    deliveryOverDue: `Levering overtijd`,
    deliveryRegistered: `Levering geregistreerd`,
    deliverySingularOrPlural: `Levering(-en)`,
    deliveryTime: `Levertermijn`,
    deltaFinancialAndPhysical: `Verschil financieel en fysiek`,
    demanded: `Gevraagd`,
    demo: `Demo`,
    department: `Departement`,
    departmentSlashLocation: `Afdeling/locatie`,
    description: `Omschrijving`,
    descriptionOfSupplier: `Omschrijving van de leverancier`,
    descriptions: `Omschrijvingen`,
    detail: `Detail`,
    detailsOfEntPhysician: `Details van NKO arts`,
    detailsOfGeneralPractitioner: `Details van huisarts`,
    detailsOfHif: `Details van verzekeringsinstelling`,
    detailsOfSupplier: `Details van leverancier`,
    detailsOfVatCustomer: `Details van BTW klant`,
    detailsSlashHistorySlashMoreActions: `Details/historiek/meer acties`,
    determineOrientation: `Bepaal oriëntatie`,
    devicesFromNoah: `Toestellen van Noah`,
    differenceWithNorm: `Verschil met norm`,
    differentLabelPerLanguage: `Verschillende label per taal`,
    differentReadOuts: `Verschillende uitlezingen`,
    directedToContactPerson: `Gericht aan de contactpersoon`,
    directedToPatient: `Gericht aan de patiënt`,
    directedToPatientAndOrVatCustomer: `Gericht aan een patiënt en/of een BTW klant`,
    directedToVatCustomer: `Gericht aan een BTW klant`,
    disableCashDeskResource: `Kassa module uitschakelen`,
    disableCashDeskResourceQuestion: `Kassa module uitschakelen voor deze locatie?`,
    disableExternalLogin: `Externe login service uitschakelen`,
    disableExternalLoginContent: `Gebruiker zal niet langer kunnen gebruik maken van google services om aan te melden.`,
    disableExternalLoginQuestion: `Externe login service uitschakelen?`,
    disablePlannable: `Niet meer planbaar`,
    disablePlannableQuestion: `Niet meer planbaar maken?`,
    disableStockManagementResource: `Stockbeheer uitschakelen`,
    disableStockManagementResourceQuestion: `Stockbeheer inschakelen voor deze locatie?`,
    disApprovalAdvisoryPhysicianCaption: `Feedback ontvangen zonder goedkeuring`,
    disApprovalEntPhysicianCaption: `Feedback ontvangen zonder goedkeuring`,
    disApproved: `Afgekeurd`,
    discount: `Korting`,
    discountCannotBeGreaterThanListPrice: `Korting kan niet grotere zijn dan de aanbevolen prijs`,
    discountCaption: `Eenmalige (absolute) korting`,
    discountsCanBeAddedAfterwards: `Kortingen kunnen nadien worden toegekend`,
    dispatchDate: `Datum verzending`,
    dispatchInfo: `Verzendgegevens`,
    dispatchReference: `Verzendnota`,
    displayName: `Weergegeven naam`,
    distortion: `Distortie`,
    distributedBrands: `Verdeelde merken`,
    distributor: `Verdeler`,
    doctorWithRIZIVNumberExists: `Er bestaat al een arts met dit RIZIV nummer.`,
    document: `Document`,
    documents: `Documenten`,
    documentsForWho: (who: string | number) => `Documenten voor ${who}`,
    doesNotExistsInNoah: `Bestaat niet in Noah`,
    doesNotHaveRequestForInsuranceAllowance: `Heeft geen aanvraag tot verzekeringstegemoetkoming`,
    dome: `Dome`,
    domeNewArticleCaption: `Click, open, mini, ...`,
    doNotAddLinesToAnExistingOrder: `Voeg geen lijnen toe aan een bestaande bestelling`,
    doNotChangeWithPatient: `Pas niet aan bij patiënt`,
    doNotMention: `Niet vermelden`,
    doNotMentionCaption: `Deze lijn wordt NIET vermeld op bijlage 12`,
    doNotMentionOnAttachment12: `Niet vermelden op bijlage 12`,
    doNotRegisterPaymentsInHAMS: `Betalingen niet registreren in HAMS`,
    doNotUpdateOnProcesses: `Pas niet aan bij processen`,
    dottedLine: `Stippellijn`,
    doubleClickNoahInHamsOnDesktop: `Dubbelklik 'Noah in HAMS' op het bureaublad`,
    download: `Download`,
    downloadAndInstallHamsNoahClientSoftware: `Download en installeer HAMS-Noah client software`,
    downloadDocument: `Download document`,
    downloadIsStartedWaitUntiflFileIsVisible: `Download is gestart, wacht totdat file zichtbaar is\nKlik op file om de installatie te starten`,
    downloadMiddleware: `Download middleware`,
    downloadNHAX: `Download NHAX`,
    downloadWhat: (what: string | number) => `${what} ophalen`,
    doYouWantToActivateExactOnlineIntegrationQuestion: `Wil je de Exact Online koppeling activeren?`,
    doYouWantToActivateThisIntegrationQuestion: `Wil je deze integratie activeren?`,
    doYouWantToCancelThisArticlesInquiryQuestion: `Wil je deze artikelen-aanvraag annuleren?`,
    doYouWantToCreateANewPatientBasedOnRosaPatientRecordData: `Wil je een nieuwe patiënt in HAMS aanmaken op basis van deze ROSA gegevens?`,
    doYouWantToCreateATenderWithoutContributionsQuestion: `Wil je een offerte creëeren zonder tegemoetkomingen?`,
    doYouWantToCreateSlashSyncSaleInExactOnlineQuestion: `Wil je een deze verkoop aanmaken/syncen in Exact Online?`,
    doYouWantToCreateThisPatientInRosaQuestion: `Wil je deze patiënt in ROSA aanmaken?`,
    doYouWantToDeactivateExactOnlineIntegrationQuestion: `Wil je de Exact Online koppeling deactiveren?`,
    doYouWantToDeactivateThisIntegrationQuestion: `Wil je deze integratie de-activeren?`,
    doYouWantToDeleteAttachment17: `Wil je bijlage 17 verwijderen?`,
    doYouWantToDeleteExistingLinkWithCRMAccountQuestion: `Wil je de bestaande link met een bestaande CRM account verwijderen?`,
    doYouWantToDeleteExistingLinkWithGLAccountQuestion: `Wil je de bestaande link met GL account verwijderen?`,
    doYouWantToDeleteRuleForWhatQuestion: (what: string | number) => `Wil je de regel: ${what.toString().toLowerCase()} verwijderen?`,
    doYouWantToDeleteScanHistory: `Wil je deze scan historiek verwijderen?`,
    doYouWantToDeleteThisContactInfoQuestion: `Wil je deze contact info verwijderen?`,
    doYouWantToDeleteThisEmailAddressQuestion: `Wil je dit e-mailadres verwijderen?`,
    doYouWantToDeleteThisLineFromThisArticlesInquiryQuestion: `Wil je deze lijn uit dit artikelen-verzoek verwijderen?`,
    doYouWantToDeleteThisLineFromThisOrderQuestion: `Wil je deze lijn uit de bestelling verwijderen?`,
    doYouWantToForwardInvoiceQuestion: `Wil je deze factuur doorsturen?`,
    doYouWantToHideThisArticleFromListOfActualEquipmentQuestion: `Wil je dit artikel verbergen uit de lijst van actuele uitrusting?`,
    doYouWantToIgnoreSaleReferenceQuestion: (reference: string | number) => `Wil je de verkoop ${reference.toString().toLowerCase()} negeren?`,
    doYouWantToLinkTheRosaUsersNowQuestion: `Wil je nu de ROSA gebruikers linken?`,
    doYouWantToLinkThisRosaPatientWithAnExistingPatientFromHAMS: `Wil je deze ROSA patiënt linken met een bestaande patiënt van HAMS?`,
    doYouWantToMakeThisEntPhysicianAvailableAgainQuestion: `Wil je deze NKO arts terug beschikbaar maken?`,
    doYouWantToMakeThisGPAvailableAgainQuestion: `Wil je deze huisarts terug beschikbaar maken?`,
    doYouWantToMakeThisSupplierAvailableAgainQuestion: `Wil je deze leverancier terug beschikbaar maken?`,
    doYouWantToPushPatientDataToRosaQuestion: `Wil je de HAMS gegevens van deze patiënt overzetten naar ROSA?`,
    doYouWantToRemoveManualWarrantyEndDateQuestion: `Wil je de manueel ingestelde datum einde garantie verwijderen?`,
    doYouWantToRepairRuleForWhatQuestion: (what: string | number) => `Wil je de regel: ${what.toString().toLowerCase()} herstellen?`,
    doYouWantToRepairTemplateWithName: (template: string | number) => `Wil je de template: ${template.toString().toLowerCase()} herstellen?`,
    doYouWantToRevertIgnoreSaleReferenceQuestion: (reference: string | number) => `Wil je de verkoop ${reference.toString().toLowerCase()} niet langer negeren?`,
    doYouWantToShowThisArticleAgainInListOfActualEquipment: `Wil je dit artikel terug tonen in de lijst van actuele uitrusting?`,
    draft: `Opstellen`,
    draftCosiCaption: `Vul enkel de specifieke behoeftes in, evalueer later`,
    dutch: `Nederlands`,
    earCanalLength: `Lengte gehoorgang`,
    earmould: `Oorstukje`,
    earMouldNewArticleCaption: `Op maat gemaakt oorstukje (van hoortoestel)`,
    earmoulds: `Oorstukjes`,
    edit: `Editeer`,
    editable: `Editeerbaar`,
    editAddress: `Wijzig adres`,
    editConfirmAddress: `Editeer/bevestig adres`,
    editFieldToFill: `Editeer in te vullen veld`,
    editName: `Editeer naam`,
    editProperties: `Eigenschappen editeren`,
    editSlashView: `Editeer/bekijk`,
    editVerb: `Editeren`,
    editWhat: (what: string | number) => `Editeer ${what.toString().toLowerCase()}`,
    eIDData: `eID gegevens`,
    eIDDataLastReadDate: `Datum laatste uitlezing eID`,
    eIDDataRead: `eID gegevens uitgelezen`,
    eIDDataUpdatedToPatient: `eID gegevens uitgelezen, patiënt geupdated`,
    eIDReadInfo: `De gegevens van de patiënt worden uitgelezen via de eID-kaart.

Het rijksregisternummer van de kaart wordt vergeleken met de rijksregisternummers van bestaande patiënten, 
indien er een match is, worden de gegevens van de patiënt geüpdatet met de gegevens van de eID-kaart. 

Indien er geen match is, krijg je nadien een popup scherm ter bevestiging vooraleer een nieuwe patiënt wordt aangemaakt.

Ben je zeker dat de patiënt reeds is aangemaakt, maar is het rijksregisternummer niet ingevuld: 
sluit dit venster, zoek de patiënt en update de eID gegevens vanuit het detailscherm.`,
    electronics: `Elektronisch`,
    elementsBelowWillNotBeShown: `Elementen hieronder worden niet getoond`,
    email: `E-mail`,
    eMailAddressess: `E-mail adressen`,
    emailAddressWhereToInvoicedWillBeSent: `E-mail adressen (waarnaar facturen worden doorgestuurd)`,
    emptyDocument: `Leeg document`,
    enableCashDeskResource: `Kassa module activeren`,
    enableCashDeskResourceQuestion: `Kassa module inschakelen voor deze locatie?`,
    enableExternalLogin: `Externe login service inschakelen`,
    enableExternalLoginContent: `Gebruiker zal gebruik kunnen maken van google services om aan te melden`,
    enableExternalLoginQuestion: `Externe login service inschakelen?`,
    enablePlannable: `Planbaar maken`,
    enablePlannableQuestion: `Locatie planbaar maken?`,
    enableStockManagementResource: `Stockbeheer activeren`,
    enableStockManagementResourceQuestion: `Stockbeheer uitschakelen voor deze locatie?`,
    end: `Beëindig`,
    endAfterTreatment: `Beëindig nabehandeling`,
    endAfterTreatmentContentQuestion: `Ben je zeker deze nabehandeling te beëindigen?`,
    endAllSessions: `Beëindig alle sessies`,
    endAllSessionsForUser: `Beëindig alle sessies voor gebruiker`,
    endDate: `Einddatum`,
    ended: `Beëindigd`,
    endLoan: `Uitlening beëindigen`,
    endResult: `Eindresultaat`,
    endSession: `Beëindig sessie`,
    endSessionForUser: `Beëindig sessie voor gebruiker`,
    endThisSession: `Beëindig deze sessie`,
    endTrial: `Beëindig proef`,
    endTrialAdministratively: `Einde proef (administratief)`,
    endTrialAdministrativelyCaption: `Beëndig de proef administratief\nVul een datum voor het administratieve einde in`,
    endTrialCaption: `Beëndig de uitlening\nToestellen komen terug in de voorraad, deze stap is niet noodzakelijk, de proef beëindigd automatisch bij verkoop`,
    endTrialPositively: `Proef beëindigen (positief)`,
    endVerb: `Beëindigen`,
    english: `Engels`,
    enterDiscountAbsolute: `Vul korting in euro in`,
    enterDiscountInPercentage: `Vul % korting in`,
    enterpriseNumber: `Ondernemingsnummer`,
    enterSerialNumber: `Vul serienummer in`,
    entPhysician: `Neus Keel Oor Arts`,
    entPhysicianCreated: `Nieuwe NKO arts aangemaakt`,
    entPhysicians: `NKO artsen`,
    entPhysicianShort: `NKO arts`,
    entPhysicianUpdated: `NKO arts aangepast`,
    entryDate: `Datum ingave`,
    equation: `Vergelijking`,
    equipmentTestTitle: `Toerusting uittesten (proef)`,
    error: `Fout`,
    errorCannotStartExternalModules: `FOUT, kan geen externe modules starten`,
    errorDownloadingConfiguration: `Fout configuratie ophalen`,
    errorViewLogs: `Fout, bekijk logs`,
    evaluateCosi: `Evalueer COSI`,
    evaluateCosiCaption: `Vul specifieke behoeftes in en evalueer meteen`,
    evaluatedNegatively: `Negatief geëvalueerd`,
    evaluateVerb: `Evalueren`,
    evaluation: `Evaluatie`,
    evaluationDate: `Datum evaluatie`,
    everythingCapital: `Alles hoofdletter`,
    everythingLower: `Alles kleine letter`,
    everythingSeemsAllright: `Alles lijkt te kloppen`,
    evolution: `Evolutie`,
    exact: `Exact`,
    exactlyOne: `Exact één`,
    exactOnlineActivatedForThisEnvironment: `Exact Online geactiveerd voor deze omgeving`,
    exactOnlineCRMAccountCode: `Exact Online CRM account code`,
    exactOnlineGLAccountCode: `Exact Online GL account code`,
    exactOnlineIntegration: `Integratie Exact Online`,
    exactOnlineNotActivatedNotification: `Exact Online is niet geactiveerd voor deze omgeving, contacteer support@hams.be om deze integratie te activeren.`,
    exactOnlineUpToDate: `Exact Online up to date`,
    example: `Voorbeeld`,
    existingProcess: `Bestaand proces`,
    expected: `Verwacht`,
    expectedDeliveryDate: `Verwachte leverdatum`,
    expectedEndDate: `Verwachtte einddatum`,
    expectedHandoverDate: `Vermoedelijke datum aflevering`,
    expectedPaymentBefore: `Verwachte betaling vóór`,
    expectedWhat: (what: string | number) => `Verwachtte ${what.toString().toLowerCase()}`,
    expectedWhatExpired: (what: string | number) => `Verwachtte ${what.toString().toLowerCase()} verstreken`,
    expense: `Uitgave`,
    expenseOrIncome: `Uitgave of inkomst`,
    expired: `Vervallen`,
    exportAllCountNonCashSalesToZipFile: (count: string | number) => `Exporteer alle (${count.toString().toLowerCase()}) niet contante verkopen in .zip bestand`,
    exportCountToZip: (count: string | number) => `Exporteer (${count.toString().toLowerCase()}) in .zip bestand`,
    exportDailyReceiptsFormattedAnWithTotals: `Exporteer dagontvangsten geformatteerd en mét totalen`,
    exportFinancialStockFormatted: `Exporteer financiele stock geformatteerd`,
    exportJournalFormatted: `Exporteer dagboek geformatteerd`,
    exportLinesAllResultsInFormat: (format: string | number) => `Exporteer lijnen (alle resultaten) in ${format.toString().toLowerCase()} formaat`,
    exportLinesFilteredResultsInFormat: (format: string | number) => `Exporteer lijnen (gefilterde resultaten) in ${format.toString().toLowerCase()} formaat`,
    exportPatientToNhaxFile: `Exporteer patient naar NHAX-file`,
    exportToUbl: `Exporteer naar UBL`,
    exportWhat: (what: string | number) => `Exporteer ${what.toString().toLowerCase()}`,
    extension: `Extensie`,
    external: `Extern`,
    externalAuthentication: `Externe authenticatie`,
    externalLoginDisabled: `Gebruiker kan niet via google services aanmelden`,
    externalLoginEnabled: `Gebruiker kan via google services aanmelden`,
    externalReference: `Externe referentie`,
    extraLine: `Extra lijn`,
    extraLineCaption: `Te verkopen item waarvoor geen artikel in de product cataloog bestaat`,
    exVatPerPiece: `excl. BTW per stuk`,
    exVatShort: `ex. BTW`,
    failed: `Mislukt`,
    failedTrial: `Mislukte proef`,
    failedTrialCaption: `Beëndig de proef negatief\nPatiënt is niet tevreden, de toestellen komen terug in de stock`,
    failedTrials: `Mislukte proeven`,
    failedTrialsThisYearBracketsYTD: `Mislukte proeven dit jaar (YTD)`,
    failureVatPercentageHelperText: `BTW percentage forfaitaire tegemoetkoming`,
    fault: `Fout`,
    faultInConfiguration: `Fout in configuratie`,
    faultSingularOrPlural: `Fout(-en)`,
    faultStartNoahModules: `Fout opstarten Noah modules`,
    feHearingProtectionChargerEarmouldEtc: `Bvb. gehoorbescherming, oorstukje, oplader, …`,
    female: `Vrouwelijk`,
    fieldsToFill: `In te vullen velden`,
    file: `Bestand`,
    fileName: `Bestandsnaam`,
    filledInWhat: (what: string | number) => `${what} ingevuld`,
    fillInLRSeparately: `Vul L/R afzonderlijk in`,
    fillInMissingData: `Vul ontbrekende data in`,
    fillInMissingDataTitle: `Ontbrekende informatie invullen`,
    fillInMono: `Vul mono in`,
    fillMfrModelInYourself: `Zelf merk/model invullen`,
    fillWhat: (what: string | number) => `Vul ${what.toString().toLowerCase()} in`,
    filteredResults: `Gefilterde resultaten`,
    financial: `Financieel`,
    financialQuantity: `Op voorraad (financieel)`,
    finished: `Afgerond`,
    finishedProcesses: `Afgeronde processen`,
    finishedTreatments: `Afgeronde behandelingen`,
    firstDeliveryDate: `Datum van 1ste levering`,
    firstLetterOfFriday: `V`,
    firstLetterOfMonday: `M`,
    firstLetterOfSaturday: `Z`,
    firstLetterOfSunday: `Z`,
    firstLetterOfThursday: `D`,
    firstLetterOfTuesday: `D`,
    firstLetterOfWednesday: `W`,
    firstName: `Voornaam`,
    firstUsageDate: `Datum eerste gebruik`,
    fitted: `Aangepast`,
    fitting: `Aanpassing`,
    fittingDate: `Datum aanpassing`,
    followingSalesHaveManualAdditionsToTheDocument: `Van volgende verkopen is het document manueel aangepast`,
    followingSettlementProposalsHaveManualAdditionsToTheDocument: `Van volgende afrekeningsvoorstellen is het document manueel aangepast`,
    followInstallationInstructionsOnScreenCloseScreenAfterInstallation: `Volg de installatie instructies op het scherm\nSluit dit venster na de installatie`,
    followUpVerb: `Opvolgen`,
    forAPatientQuestion: `Voor een patiënt?`,
    forASinglePatient: `Voor exact één patiënt`,
    forExampleHearingAid: `bv. hoortoestel`,
    forExampleShort: `bv.`,
    forgotPasswordHeaderText: `Vul hieronder je e-mail adres in. \nNadien krijg je een e-mail met instructies om je wachtwoord te wijzigen.`,
    forgotPasswordQuestion: `Wachtwoord vergeten?`,
    form: `Formulier`,
    formatAsSize: `Formaat`,
    formattingOptions: `Opmaak mogelijkheden`,
    forMultipleArticles: `Voor meerdere artikelen`,
    forMultiplePatients: `Voor meerdere patiënten`,
    forNoneOneOrMultiplePatients: `Voor geen, één of meerdere patiënten`,
    forPatient: `Voor patiënt`,
    forward: `Doorsturen`,
    forwarded: `Doorgestuurd`,
    forwardInvoiceAfterDocumentIsCreated: `Stuur factuur pas door na het aanmaken van een document`,
    forwardInvoiceOnceItsCreatedEvenWithoutDocument: `Stuur factuur onmiddellijk door (zonder document)`,
    forwardViaEmail: `Stuur door via e-mail`,
    forWho: (who: string | number) => `Voor ${who}`,
    foundAtInventoryItems: `Gevonden bij de voorraadartikelen`,
    foundInReadOutsFromNoah: `Gevonden in uitlezingen van Noah`,
    foundPatientsOnName: `Gevonden patiënten op naam`,
    foundPatientsOnSsNr: `Gevonden patiënten op rijksregisternummer`,
    freeInputField: `Vrijveld`,
    french: `Frans`,
    frequencyRange: `Frequentieband`,
    fromArrowTo: `Van -> naar`,
    fromCompleteList: `Van volledige lijst`,
    fromLocation: `Van locatie`,
    fromNeeds: `Van behoeften`,
    fromPreviousReadOutsFromNoah: `Vanuit eerder uitgelezen toestellen van Noah`,
    fromProductCatalog: `Uit product cataloog`,
    fromStock: `Vanuit de voorraad`,
    fromStockShort: `Uit voorraad`,
    fromSupplier: `Van leverancier`,
    fromTemplate: `Van template`,
    fromTo: `Van / Naar`,
    fullDay: `Volledige dag`,
    fullyCredited: `Volledig gecrediteerd`,
    fullyPaid: `Volledig betaald`,
    function: `Functie`,
    functions: `Functies`,
    futureAppointments: `Toekomstige afspraken`,
    gender: `Geslacht`,
    general: `Algemeen`,
    generalData: `Algemene gegevens`,
    generalLedger: `Grootboekrekening`,
    generalLedgerAccountAllocationMethod: `Methode toewijzen grootboekrekening`,
    generalPractitioner: `Huisarts`,
    generalPractitionerCreated: `Nieuwe huisarts aangemaakt`,
    generalPractitioners: `Huisartsen`,
    generalPractitionerUpdated: `Huisarts aangepast`,
    generalSettings: `Algemene instellingen`,
    goBack: `Ga terug`,
    graph: `Grafiek`,
    graphs: `Grafieken`,
    groupedByMfrModel: `Gegroepeerd (per merk/model)`,
    groupedPayments: `Gegroepeerde betalingen`,
    groupOnThis: `Groepeer hierop`,
    groupPayment: `Groep betaling`,
    halfOfTime: `Helft van de tijd`,
    HAMSCollabWithRosa: `HAMS & Rosa Calender gaan samen werken, lees meer`,
    hamsIsNotRegisteredAsMgmtSoftware: `HAMS is niet geregistreerd als de 'management' software voor Noah.`,
    hamsNoahClientIsInstalled: `HAMS-Noah client software is geïnstalleerd`,
    hamsNoahClientIsNotInstalledOrNotActive: `HAMS-Noah client software is niet geïnstalleerd of niet actief`,
    hamsWorksTogetherWithROSAforCalendar: `HAMS werkt voor de kalendar/agenda module samen met`,
    handedOver: `Afgeleverd`,
    handedOverAndPaid: `Afgeleverd en betaald`,
    handedOverBeforeApprovals: `Afgeleverd vóór goedkeuringen`,
    handedOverThisYearBracketsYTD: `Afgeleverd dit jaar (YTD)`,
    handedOverWithoutApprovals: `Afgeleverd zónder goedkeuringen`,
    handled: `Behandeld`,
    handover: `Aflevering`,
    handoverBeforeFirstOfAugustWillBeRegisteredWithNumber: (code: string | number) => `Afleveringen voor 01/08/2023 worden geregistreerd met nummer: ${code.toString().toLowerCase()}`,
    handoverBeforeFirstOfAugustWillUseOldCode: `Afleveringen voor 01/08/2023 worden geregistreerd met oude nummer`,
    handoverCertificate: `Getuigschrift aflevering`,
    handoverDate: `Datum aflevering`,
    handoverOfEquipment: `Aflevering van toerusting`,
    handoverOverDue: `Aflevering overtijd`,
    handoverRegistered: `Aflevering geregistreerd`,
    handovers: `Afleveringen`,
    handoverToPatient: `Overhandig aan patiënt`,
    hardware: `Goed of hardware`,
    hardwareExplanation: `Iets tastbaars (bv. een oorstukje, hoorapparaat of gehoorbescherming`,
    hasBatteries: `Heeft batterijen`,
    hasBatteriesBebatApplicable: `Heeft/is batterijen (Bebat van toepassing)`,
    hasBatteriesExplanation: `Dit toestel bevat batterijen, Bebat is van toepassing`,
    hasDeliveries: `Heeft leveringen`,
    hasDeltaFinancialAndPhysical: `Heeft verchil tss. Financieel en fysiek?`,
    hasNoBatteries: `Heeft geen batterijen`,
    hasNoDeliveries: `Heeft geen leveringen`,
    hasNoOrders: `Heeft geen bestellingen`,
    hasNoPurchases: `Heeft geen aankopen`,
    hasNoSerialNumber: `Heeft geen serienummer`,
    hasOrders: `Heeft bestellingen`,
    hasPurchases: `Heeft aankopen`,
    hasRechargeableBatteriesBebatApplicable: `Heeft heroplaadbare batterij (Bebat van toepassing)`,
    hasRequestForInsuranceAllowance: `Heeft aanvraag tot verzekeringstegemoetkoming`,
    hasSerialNumber: `Heeft een serienummer`,
    hasVaryingPrice: `Heeft een variabele prijs`,
    heading: `Hoofding`,
    headPhones: `Hoofdtelefoon`,
    headPhonesLeftRight: `Hoofdtelefoon L/R`,
    headPhonesShort: `KT`,
    healthInsuranceFund: `Ziekenfonds`,
    healthInsuranceFunds: `Ziekenfondsen`,
    healthInsuranceFundShort: `Ziekenf.`,
    hearingAid: `Hoortoestel`,
    hearingAids: `Hoortoestellen`,
    hearingAidsShort: `HT`,
    hearingCentre: `Hoorcentrum`,
    hearingImprovementInWhat: (what: string | number) => `Gehoorwinst in ${what.toString().toLowerCase()}`,
    hearingLossLessOrMoreThan40dB: `Gehoorverlies meer/minder dan 40dB`,
    hearingLossLessThen40dB: `Gehoorverlies < 40dB`,
    hearingLossLessThen40dBWithAverages: `Gehoorverlies minder dan 40dB (gem. 1000/2000/4000Hz)`,
    hearingLossMoreThan40dB: `Gehoorverlies ≥ 40dB`,
    hearingLossMoreThan40dBWithAverages: `Gehoorverlies van minstens 40dB (gem. 1000/2000/4000Hz) of uitzondering (2.1.2)`,
    hearingProtection: `Gehoorbescherming`,
    hearingProtectionNewArticleCaption: `Wel/niet op maat gemaakt\n Zwemdop, slaapdop, muziekdop, ...`,
    here: `Hier`,
    hidden: `Verborgen`,
    hideAnamnesis: `Verberg anamnese`,
    hideArticle: `Verberg artikel`,
    hideProperties: `Verberg eigenschappen`,
    hideSplitUpPerVatPercentage: `Verberg opsplitsing per BTW%`,
    hideThisArticle: `Verberg dit artikel`,
    hideWhat: (what: string | number) => `Verberg ${what.toString().toLowerCase()}`,
    hifWhatDoesNotExistAnymore: (what: string | number) => `Ziekenfonds ${what.toString().toLowerCase()} bestaat niet meer`,
    highToLow: `Hoog -> laag`,
    historicalSale: `(Historische) verkoop`,
    history: `Historiek`,
    home: `Thuis`,
    homePhone: `Tel. thuis`,
    hospitalized: `Gehospitaliseerd`,
    hoursAgo: (hours: string | number) => `${hours} uur geleden`,
    hoursPerDay: `uur/dag`,
    howDoeseIDReadWorks: `Hoe werkt het uitlezen van de eID kaart?`,
    icoHifsPrefixIsCodeHif: `Igv ziekenfondsen is de prefix automatisch de code van deze ziekenfonds`,
    id: `ID`,
    identityCard: `Identiteitskaart`,
    ifNoPatientSelectedItsObligatedToSelectAVatCustomer: `Indien géén patiënt geselecteerd is het verplicht een BTW klant te selecteren.`,
    ignore: `Negeer`,
    ignoreAllowanceOrLinkWithArticleFromProductCatalog: `Negeer toeslag of link met een artikel uit de product cataloog`,
    ignored: `Genegeerd`,
    ignoreOrLinkWithAnArticleFromProductCatalog: `Negeer of link met een artikel uit de product cataloog`,
    ignoreSale: `Verkoop negeren`,
    ignoreSaleImperative: `Negeer verkoop`,
    ignoreThisDevice: `Negeer dit toestel`,
    ignoreThisReadOut: `Negeer deze uitlezing`,
    ignoreVerb: `Negeren`,
    ignoreWhatQuestion: (what: string | number) => `${what} negeren?`,
    image: `Afbeelding`,
    imageFormattingHelperText: (key: string | number) => `Een afbeelding kan optioneel nog voorzien worden van afmetingen.\nGebruik hiervoor {{${key.toString().toLowerCase()}:BxH}} met B = breedte, H = hoogte (in pixels)\nAlternatief kan deze key gebruikt worden in een tabel. De afmetingen van de cel gelden dan als maximale afmetingen.`,
    import: `Importeer`,
    importCountDevices: (count: string | number) => `Importeer ${count.toString().toLowerCase()} toestellen`,
    importDeviceInStock: `Toestel opnemen in de voorraad`,
    imported: `Geïmporteerd`,
    importedFromNoah: `Geïmporteerd van Noah`,
    importIfNewerAction: `Importeer als nieuwe actie`,
    importMultipleDevicesToStock: `Meerdere toestellen importeren in de voorraad`,
    importMultipleDevicesToStockContentCount: (count: string | number) => `Op basis van de ingestelde filter zijn er ${count.toString().toLowerCase()} toestellen gevonden\ndie volgens de vooraf ingestelde regels kunnen geïmporteerd worden.\n\nWil je deze allemaal importeren?`,
    importRosaPatient: `Importeer ROSA patiënt`,
    importThisDeviceInStock: `Neem dit toestel op in de voorraad`,
    importVerb: `Importeren`,
    importWhat: (what: string | number) => `Importeer ${what.toString().toLowerCase()}`,
    improvement: `Verbetering`,
    inactive: `Inactief`,
    inAfterTreatment: `In nabehandeling`,
    inBackOrder: `Backorder`,
    inBrowser: `In browser`,
    income: `Inkomst`,
    inComparisonWithPreviousPeriod: `In vergelijking met de vorige periode`,
    inComparisonWithPreviousTransaction: `In vergelijking met de vorige transactie`,
    inConsulation: `In consultatie`,
    incorrectFormat: `Ongeldig formaat`,
    incorrectlyLinked: `Verkeerd gelinkt`,
    indexNumber: `Volgnummer`,
    individualPerSerialNumber: `Individueel (per serienummer)`,
    individualReadOutDevice: `Individueel uitgelezen toestel`,
    infoNotFilledIn: (info: string | number) => `${info} niet ingevuld`,
    information: `Informatie`,
    inForRepair: `Binnen voor reparatie`,
    initials: `Initialen`,
    inLoan: `In uitlening`,
    inOrder: `In bestelling`,
    inRepair: `In herstelling`,
    insertCard: `Plaats een geldige eID kaart`,
    installation: `Installatie`,
    installationAndConfiguration: `Installatie & configuratie`,
    installMiddleware: `Installeer middleware`,
    installWhat: (what: string | number) => `Installeer ${what.toString().toLowerCase()}`,
    inStepsOf: `In stappen van`,
    inStock: `Op voorraad`,
    inStockOnALocation: `In voorraad op een locatie`,
    insufficientInformation: `Onvoldoende informatie`,
    insurance: `Verzekering`,
    insurancedAndProlongedBetweenBracketsWarranties: `Verzekeringen & (verlengde) garanties`,
    insurancesAndProlongedWarranties: `Verzekeringen & verlengde garanties`,
    integrations: `Integraties`,
    integrationStatus: `Integratie status`,
    intensity: `Intensiteit`,
    internal: `Intern`,
    internalReference: `Interne referentie`,
    inTest: `Op proef`,
    inTheEar: `In het oor`,
    intraCommCaption: `Intracommunautaire leveringen o.b.v. artikel 138 EU BTW richtlijnen 2006/112/EC`,
    inTreatment: `In behandeling`,
    invalidAction: `Ongeldige actie`,
    invalidCombinationEmailPassword: `E-mail/wachtwoord combinatie incorrect`,
    invalidLicense: `Ongeldige licentie`,
    invalidLicenseContactHams: `Ongeldige licentie, contacteer HAMS`,
    invalidSocialSecurityNumber: `Ongeldig rijksregisternummer`,
    inVatShort: `in. BTW`,
    inventoryItem: `Voorraadartikel`,
    inventoryItemNotInStock: `Artikel niet op voorraad`,
    inventoryItems: `Voorraadartikelen`,
    inventoryManagement: `Voorraadbeheer`,
    inventoryManagementShortened: `Voorraadbeheer`,
    invoice: `Factuur`,
    invoiceAdvance: `Voorschot factureren`,
    invoiceAndCash: `Factuur en contant`,
    invoiceCustomerIsAPatientCaption: `De factuur wordt gelinkt aan de patiënt\nOptioneel kan factuur gericht worden aan een BTW klant`,
    invoiceCustomerIsNotAPatientCaption: `Factuur wordt gericht aan een BTW klant`,
    invoiced: `Gefactureerd`,
    invoiceForwarding: `Facturen doorsturen`,
    invoiceForwardingIntegration: `Facturen doorsturen integratie`,
    invoiceNumber: `Factuurnummer`,
    invoicePriorToApprovals: `Factureren vóór de goedkeuringen`,
    invoiceProcess: `Facturatieproces`,
    invoices: `Facturen`,
    invoiceVerb: `Factureren`,
    inWaitingRoom: `In wachtruimte`,
    isAlsoPurchased: `Wordt ook aangekocht`,
    isElectronics: `Is elektronisch`,
    isElectronicsExplanation: `Dit toestel bevat elektronica, er moeten dus taxen verrekend worden.`,
    isNotElectronics: `Geen elektronica`,
    isNotTailorMade: `Niet op maat`,
    isRecalculatingNowDataWillBeUpdated: `Aan het herbereken, data wordt binnenkort geüpdated`,
    isSoftwareAlreadyInstalledQuestion: `Is de software reeds geïnstalleerd?`,
    isTailorMade: `Op maat gemaakt`,
    iteCaptionTypes: `In het oor (IIC, CIC, ITC, HS, FS, …)`,
    item: `Item`,
    itemAddedToRepair: `Item toegevoegd aan herstelling`,
    itemAlreadyInList: `Item staat reeds in de lijst`,
    itemFromProductCatalog: `Item van productcataloog`,
    itemNotFoundQuestionClickHereToDefineNewProductCatalogItem: `Item niet gevonden?\nKlik hier om een nieuw item in de product cataloog te definiëren.`,
    itemNotFoundQuestionClickHereToDefineNewSerialNumber: `Item niet gevonden?\nKlik hier om een nieuw serienummer te definiëren.`,
    itemReserved: `Item gereserveerd`,
    items: `Artikelen`,
    itemsReturned: `Items teruggebracht`,
    itemsToDeliver: `Te leveren items`,
    itemsToLoan: `Uit te lenen items`,
    itemsToOrder: `Items te bestellen`,
    kpiCashDesk: `Gebruiken kassa module`,
    kpiPlannable: `Planbaar`,
    kpiStockResource: `Gebruiken voorraad-module`,
    label: `Label`,
    labels: `Labels`,
    language: `Taal`,
    lastActivity: `Laatste activiteit`,
    lastHandover: `Laatste aflevering`,
    lastLinkWas: `De laatste keer was dit toestel gelinkt aan:`,
    lastLoggedIn: `Laatste keer ingelogd`,
    lastLoggedInDate: `Laatste aanmelddatum`,
    lastModifiedAt: `Laatst aangepast op`,
    lastModifiedDate: `Datum laatst aangepast`,
    lastName: `Achternaam`,
    lastNameCommaFirstName: `Achternaam, voornaam`,
    lastPaymentDate: `Datum (laatste) betaling`,
    lastPayments: `Laatste betalingen`,
    lastReadOut: `Laatste uitlezing`,
    lastReadOutDate: `Datum laatste uitlezing`,
    lastReadOutDevicesFromNoah: `Laatst uitgelezen toestellen (vanuit Noah)`,
    lastSpsMetaRefHeader: `Laatste afr. voorstel meta-ref`,
    lastSpsRefHeader: `Laatste afr. voorstel ref.`,
    lastSynced: `Laatst gesynchroniseerd`,
    lastUpdated: `Laatst aangepast`,
    lastUsageDate: `Datum laatste gebruik`,
    lastWhat: (what: string | number) => `Laatste ${what.toString().toLowerCase()}`,
    lastWhatDays: (what: string | number) => `Laatste ${what.toString().toLowerCase()} dagen`,
    latest: `Laatste`,
    latest10: `Laatste 10`,
    latest10Notifications: `Laatste 10 meldingen`,
    latestTreatment: `Laatste behandeling`,
    layoutOverviewScreenOfAPatient: `Layout van het overzichtsscherm van een patiënt`,
    left: `Links`,
    leftAndRight: `Links en rechts`,
    leftColumn: `Linkse kolom`,
    leftEar: `Linkeroor`,
    legend: `Legende`,
    lent: `Uitgeleend`,
    less: `Minder`,
    letter: `Brief`,
    letterToAdvisoryPhysician: `Brief aan adviserend geneesheer`,
    letterToEntPhysician: `Brief aan NKO arts`,
    license: `Licentie`,
    line: `Lijn`,
    lines: `Lijnen`,
    linesDeletedAndOrAddedAfterDocumentCreation: `Lijnen toegevoegd en/of verwijderd na het aanmaken van document`,
    link: `Link`,
    linkAnExistingProcess: `Link een bestaand proces`,
    linked: `Gelinkt`,
    linkedToAnotherPatient: `Gelinkt aan een andere patiënt`,
    linkedToMultiple: `Gelinkt aan meerdere`,
    linkExactOnlineCRMAccountForWho: (who: string | number) => `Link Exact Online CRM account voor ${who}`,
    linkExactOnlineGLAccountForFallbackVatPercentage: (vatPercentage: string | number) => `Link Exact Online GL Account voor fallback ${vatPercentage.toString().toLowerCase()} BTW %`,
    linkExactOnlineGLAccountForWhat: (what: string | number) => `Link Exact Online GL account voor ${what.toString().toLowerCase()}`,
    linkGLAccount: `Link GL Account`,
    linkHealthInsuranceFund: `Link ziekenfonds`,
    linkItemWithThisWhat: (what: string | number) => `Link een item met deze ${what.toString().toLowerCase()}`,
    linkRIZIVFromProductCatalogCaption: `Voor dit item zijn er reeds meerdere mogelijkheden gedefinieerd,\nmaak een keuze uit deze verkorte lijst`,
    linkRosaPatient: `ROSA patiënt linken`,
    linkRosaUsersWithHamsUsers: `Link ROSA gebruiker(s) met HAMS gebruiker(s)`,
    links: `Links`,
    linkWithAHealthInsuranceFund: `Link met een ziekenfonds`,
    linkWithAnExactOnlineCRMAccount: `Link met een Exact Online CRM account`,
    linkWithAnInventoryItem: `Link met een voorraadartikel`,
    linkWithAnotherArticleFromProductCatalog: `Link met een ander artikel uit de product cataloog`,
    linkWithAnotherHealthInsuranceFund: `Link met een ander ziekenfonds`,
    linkWithPatient: `Link met een patiënt`,
    linkWithSameItemFromProductCatalogAsLastTime: `Link met hetzelfde item uit de product cataloog als de vorige keer`,
    list: `Lijst`,
    listPrice: `Aanbevolen prijs`,
    loadingEllipsis: `Laden...`,
    loadingStatus: `Status ophalen`,
    loan: `Uitlening`,
    loanedOutItems: `Uitgeleende items`,
    loanEnded: `Uitlening beëindigd`,
    loans: `Uitleningen`,
    loanSlip: `Uitleenbon`,
    loanStarted: `Uitlening gestart`,
    location: `Locatie`,
    locationCashAndStockResourcesBothNeedToBeEnabled: `Een locatie waar de kassamodule gebruikt wordt, moet ook een stock locatie zijn.`,
    locationCashDesk: `Kassa locatie`,
    locationCashDeskResourceWillBeDisabled: `De kassamodule zal niet meer bruikbaar zijn op deze locatie`,
    locationCashDeskResourceWillBeEnabled: `De kassamodule zal bruikbaar worden op deze locatie`,
    locationCreated: `Locatie aangemaakt`,
    locationDetails: `Details van een locatie`,
    locationPlannable: `Planbaar`,
    locationPlannableWillBeDisabled: `Deze locatie kan niet meer gebruikt worden om afspraken op te plannen.`,
    locationPlannableWillBeEnabled: `Deze locatie kan gebruikt worden om afspraken op te plannen.`,
    locations: `Locaties`,
    locationsCurrentlyOnStock: `Locatie(s) momenteel in voorraad`,
    locationSlashRoom: `Locatie/Ruimte`,
    locationStockManagement: `Stock locatie`,
    locationStockManagementResourceWillBeDisabled: `De stockbeheer module zal niet meer bruikbaar zijn op deze locatie`,
    locationStockManagementResourceWillBeEnabled: `De stockbeheer module zal bruikbaar worden op deze locatie`,
    locationUpdated: `Locatie gëupdatet`,
    locationWhereProductsWillBeReceived: `De locatie waar de producten in ontvangst worden genomen.`,
    locationWhereSold: `Locatie waar verkocht`,
    locationWillAlsoBeChangedOnOtherProcesses: `De locatie wordt OOK gewijzigd bij de lopende processen`,
    locationWillAlsoBeChangedWithPatient: `De locatie wordt OOK gewijzigd bij de patiënt`,
    locationWillBeDeleted: `Deze locatie zal permanent uit het systeem worden verwijdered.`,
    locationWillNotBeChangedWithPatient: `De locatie wordt NIET gewijzigd bij de patiënt`,
    locationWillOnlyBeChangedWithThisPatientAndNotOnProcesses: `De locatie wordt enkel gewijzigd bij de patiënt en niet bij de processen`,
    locationWithAddressExists: `Er bestaat reeds een locatie met dit adres.`,
    locationWithNameExists: `Er bestaat reeds een locatie met dezelfde naam`,
    loggedIn: `Aangemeld`,
    loggedInDevices: `Aangemelde toestellen`,
    login: `Aanmelden`,
    loginHeaderText: `Meld je aan met je e-mail en wachtwoord`,
    logo: `Logo`,
    logout: `Afmelden`,
    logoutAfter20MinutesOfInactivity: `Automatisch afmelden na 20 min. inactiviteit`,
    logoutAllSessions: `Logout alle sessies`,
    lost: `Verloren`,
    lowerCase: `Eerste letter klein`,
    lowToHigh: `Laag -> hoog`,
    lumpSum: `Forfaitair`,
    lumpSumContribution: `Forfaitaire tegemoetkoming`,
    mainNomenclatureCode: `Hoofd nomenclatuurcode`,
    mainUser: `Hoofdgebruiker`,
    makeAChoice: `Maak een keuze`,
    makeAChoiceFromCompleteList: `Maak een keuze uit de volledige lijst`,
    makeDefault: `Maak standaard`,
    makeDefaultOffice: `Maak dit de standaard praktijk`,
    makePrimary: `Maak primair`,
    makeUserNoLongerPlannableContent: `Hierdoor is het niet langer mogelijk afspraken in te plannen voor deze gebruiker`,
    makeUserPlannableAgainContent: `Hierdoor is het terug mogelijk afspraken in te plannen voor deze gebruiker`,
    male: `Mannelijk`,
    manual: `Manueel`,
    manualAdditions: `Manuele toevoegingen`,
    manualHandoverDateEntryContent: `Manueel ingevoerde datum van de laatste aflevering.\n(*indien deze aflevering niet via HAMS is geregistreerd)`,
    manuallyAssignedLabels: `Manueel toegekende labels`,
    manuallyDeactivated: `Manueel gedeactiveerd`,
    manuallySetWarrantyEndDate: `Manueel ingestelde datum einde garantie`,
    manualPatientNumbering: `Manueel patiënten nummeren`,
    manualSelectADate: `Selecteer zelf een datum`,
    manufacturer: `Fabrikant`,
    manufacturerReference: `Referentie van de fabrikant`,
    manufacturers: `Fabrikanten`,
    margin: `Marge`,
    marginTooltip: `Marge = Winst / Verkoopsprijs`,
    markAsLost: `Markeren als verloren`,
    markAsRead: `Markeer als gelezen`,
    markItemAsDemoQuestion: `Item markeren als DEMO?`,
    markItemAsNonDemoQuestion: `Item markeren als NIET DEMO?`,
    markup: `Toeslag`,
    markupTooltip: `Toeslag = Winst / Aankoopprijs`,
    material: `Materiaal`,
    measured: `Opgemeten`,
    measureVerb: `Opmeten`,
    medicalPrescription: `Medisch voorschrift`,
    medicalPrescriptionApprovedTitle: `Medisch voorschrift ontvangen (goedkeuring NKO)`,
    medicalPrescriptionDisApprovedTitle: `Medisch voorschrift (NIET goedgekeurd door NKO-arts)`,
    medicalPrescriptionReceived: `Medisch voorschrift ontvangen`,
    medicalPrescriptionReceivedNotSentToAdvPhys: `Medisch voorschrift ontvangen, nog niet verstuurd naar adv. gen.`,
    medicalTreatmentNomenclature: `Nomenclatuur`,
    method: `Methode`,
    minimalRequiredDocumentsAccordingToRIZIV: `Minimaal benodigde documenten (RIZIV)`,
    minutesAgo: (minutes: string | number) => `${minutes} minuten geleden`,
    missingDocuments: `Ontbrekende documenten`,
    mobile: `Mobiel`,
    mobilePhone: `Tel. mobiel`,
    mode: `Mode`,
    modeGenerateInvoiceAtHandover: `Mode genereren factuur bij aflevering`,
    model: `Model`,
    modules: `Modules`,
    moduleStarted: `Module gestart`,
    mono: `Mono`,
    monoPhonic: `Monofonisch`,
    monthDateOfBirth: `Maand geboortedatum`,
    monthHandover: `Maand van aflevering`,
    more: `Meer`,
    moreActions: `Meer acties`,
    moreInformationAboutWhatHasChangedQuestionClickHereForMoreInformation: `Meer informatie wat er precies veranderd? Klik hier voor meer uitleg.`,
    moreThanOneDayAgo: `> 1 dag geleden`,
    mostOfTime: `Meestal`,
    mostRecentTonalAudiogramOfDate: `Meest recente tonale audiogram van`,
    moveAppointmentQuestion: `Afspraak verplaatsen?`,
    movedToAnotherLocation: `Verplaatst naar een andere locatie`,
    muchBetter: `Veel beter`,
    multiple: `Meerdere`,
    multipleArticlesFound: `Meerdere artikelen gevonden`,
    multipleCustomerAccounts: `Meerdere klantenrekeningen`,
    multipleLines: `Meerdere lijnen`,
    multipleModulesCanOpenThisActionSelectOne: `Meerdere modules kunnen deze actie openen, selecteer er één`,
    multipleNomenclaturesSelectedThisIsNotPossible: `Meerdere nomenclaturen geselecteerd, dit is niet mogelijk`,
    multipleProductsNotSpecificForAPatient: `Meerdere producten, niet specifiek voor een patiënt`,
    multiplePurchases: `Meerdere aankopen`,
    multipleRooms: `Meerdere ruimtes`,
    multipleSales: `Meerdere verkopen`,
    mustBeLargerThanWhat: (what: string | number) => `Moet groter zijn dan ${what.toString().toLowerCase()}`,
    mustUpdateText: `Een update is beschikbaar,\nklik hieronder om de applicatie te vernieuwen`,
    myCalendars: `Mijn agenda's`,
    myCommissions: `Mijn commissies`,
    myPayouts: `Mijn uitbetalingen`,
    myProfile: `Mijn profiel`,
    myWhat: (what: string | number) => `Mijn ${what.toString().toLowerCase()}`,
    name: `Naam`,
    nameArticleFromProductCatalog: `Naam artikel uit de product cataloog`,
    navigateToDetailsOfSale: `Navigeer naar details van verkoop`,
    navigateToDetailsOfTreatment: `Navigeer naar details van behandeling`,
    navigateToDetailsPage: `Navigeer naar het detailscherm`,
    navigateToWhat: (what: string | number) => `Navigeer naar ${what.toString().toLowerCase()}`,
    navigation: `Navigatie`,
    need: `Behoefte`,
    needed: `Nodig`,
    needs: `Behoeften`,
    needsASignature: `Heeft een handtekening nodig`,
    needsASignatureQuestion: `Heeft een handtekening nodig?`,
    negative: `Negatief`,
    negativeEvaluationTrial: `Negatieve evaluatieproef`,
    neverImport: `Nooit importeren`,
    neverLogoutAuto: `Nooit automatisch afmelden`,
    newAnnotation: `Nieuwe aantekening`,
    newArticlesInquiry: `Nieuw artikelen-verzoek`,
    newArticlesInquiryForWho: (who: string | number) => `Nieuw artikelen-verzoek voor ${who}`,
    newCharger: `Nieuwe oplader`,
    newDocument: `Nieuw document`,
    newEarmould: `Nieuw oorstukje`,
    newEntPhysician: `Nieuwe NKO arts`,
    newestFirst: `Nieuwste eerst`,
    newFieldToFill: `Nieuw in te vullen veld`,
    newGeneralPractitioner: `Nieuwe huisarts`,
    newHardware: `Nieuw hardware type 'andere'`,
    newHearingAid: `Nieuw hoortoestel`,
    newInvoiceForWhoQuestion: `Nieuwe factuur: voor wie?`,
    newItem: `Nieuw artikel`,
    newItemChoice: `Nieuw artikel: maak een keuze`,
    newLoan: `Nieuwe uitlening`,
    newLocation: `Nieuwe locatie`,
    newNotifications: `Nieuwe meldingen`,
    newOffice: `Nieuwe praktijk`,
    newOrder: `Nieuwe bestelling`,
    newOrderWithWho: (who: string | number) => `Nieuwe bestelling bij ${who}`,
    newPassword: `Nieuw wachtwoord`,
    newPatient: `Nieuwe patiënt`,
    newProcess: `Nieuw proces`,
    newProduct: `Nieuw product`,
    newRemark: `Nieuwe opmerking`,
    newRequestForInsuranceAllowance: `Nieuwe aanvraag tot verzekeringstegemoetkoming`,
    newRfiaShort: `Nieuwe aanvraag tot VT`,
    newSerialNumber: `Nieuw serienummer`,
    newSerialNumberShort: `Nieuw sn.`,
    newService: `Nieuwe dienst`,
    newSupplier: `Nieuwe leverancier`,
    newTailorMadeProduct: `Nieuw product op maat`,
    newTailorMadeProductProcess: `Nieuw proces op maat gemaakt product`,
    newTangibleProduct: `Nieuw tastbaar product`,
    newTender: `Nieuwe offerte`,
    newTreatment: `Nieuwe behandeling`,
    newWhat: (what: string | number) => `Nieuwe ${what.toString().toLowerCase()}`,
    nextAppointments: `Eerstvolgende afspraken`,
    nhaxDataReadAndAddedToThisPatient: `Gegevens van nhax ingelezen en toegevoegd aan deze patiënt`,
    nickName: `Bijnaam`,
    no: `Nee`,
    noAccess: `Geen toegang`,
    noAccessToAllPages: `Geen toegang tot alle pagina's`,
    noAccessToThisPageContactHamsAdmin: `Geen toegang tot deze pagina\nContacteer een HAMS admin gebruiker voor meer informatie.\n\nBen je zelf een admin gebruiker?\nBekijk dan je profiel en wijzig de 'toegang tot pagina's' onder de tab 'restricties'`,
    noAddressInformation: `Geen adresinformatie`,
    NoahActions: `Noah acties`,
    NoahActionSingularOrPlural: `Noah actie(s)`,
    NoahData: `Noah gegevens`,
    noahDeviceReadOutsLinkedToPcsHeader: `Gelinkt aan artikelen van\nproduct cataloog (regels)`,
    NoahExternalModules: `Noah externe modules`,
    NoahImportAlwaysCaption: `Importeer alle toestellen automatisch in de voorraad.\nHet minste manueel werk, maar ook oude toestellen komen in de voorraad terecht.`,
    NoahImportIfNewerActionCaption: `Importeer enkel toestellen als het een 'nieuwe' Noah actie is.\nOude toestellen worden niet geïmporteerd in de voorraad`,
    NoahNeverImportCaption: `Importeer nooit automatisch toestellen in de voorraad.\nDeze optie geeft de meeste controle over de voorraad.\nToestellen importeren gebeurt manueel.`,
    NoahSessions: `Noah sessies`,
    noAlternativeLogoPresent: `Geen alternatief logo aanwezig`,
    noAppointmentsFound: `Geen afspraken gevonden`,
    noAppointmentsPlanned: `Geen afspraken ingepland`,
    noArticlesInquiriesDefinedYet: `Nog geen artikels-verzoeken gedefinieerd`,
    noBeIDMiddlewareInstalled: `Geen BeID middleware geïnstalleerd`,
    noCardReaderAttached: `Geen kaartlezer verbonden`,
    noCloseThisScreen: `Neen, sluit dit venster`,
    noCodesLinkedYet: `Nog geen codes gelinkt`,
    noCommaCloseWithoutSaving: `Neen, sluit zonder op te slaan`,
    noCommaGoBack: `Nee, ga terug`,
    noCommaWhat: (what: string | number) => `Nee, ${what.toString().toLowerCase()}`,
    noConnectionCouldBeMadeToeIDSoftware: `Er kan geen connectie worden gemaakt met de eID software\nAls je zeker bent dat deze geïnstalleerd is start deze dan eerst op`,
    noConnectionToClientSoftware: `Geen verbinding met Hams.Client software`,
    noConnectionToClientSoftwareText: `Er kan geen connectie worden gemaakt met de client software\nAls je zeker bent dat deze geïnstalleerd is start deze dan eerst op`,
    noContactInfo: `Geen contact info`,
    noContactPerson: `Geen contactpersoon`,
    noDataAvailable: `Geen data beschikbaar`,
    noDataInHams: `Nog geen data in HAMS`,
    noDataReadViaEIDYet: `Nog geen gegevens via eID uitgelezen`,
    noDataToCompare: `Geen data om te vergelijken`,
    noDecimals: `Geen cijfers na de komma`,
    noDefaultOfficeSelected: `Er is nog geen standaard praktijk aangeduid.`,
    noDeliveriesRegisteredYet: `Nog geen leveringen geregistreerd`,
    noDemoRegular: `Niet demo (regulier)`,
    noDevicesReadOutByNoahYet: `Nog geen toestellen uitgelezen door Noah`,
    noDifference: `Geen verschil`,
    noDocumentHasBeenCreatedForFollowingSales: `Van volgende verkopen is nog geen document gecreëerd`,
    noDocumentHasBeenCreatedForFollowingSettlementProposals: `Van volgende afrekeningsvoorstellen is nog geen document gecreëerd`,
    noDocumentsAddedYet: `Nog geen documenten toegevoegd`,
    noEarmouldsDefined: `Geen oorstukjes gevonden, klik op '+' om een nieuwe aan te maken`,
    noeIDCardInserted: `Geen eID-kaart gevonden`,
    noEmail: `Geen e-mail`,
    noEmailAddressesDefinedYet: `Nog geen e-mail adressen ingegeven`,
    noEndDate: `Geen einddatum`,
    noEquipmentAssignedYet: `Nog geen toerusting toegekend`,
    noExtraDataViewTransactionsOrRemarks: `Geen extra data hier, bekijk transacties of opmerkingen`,
    noFieldsDefined: `Geen velden gedefinieerd`,
    noFillInLater: `Neen, vul later in`,
    noFollowUpProcesses: `Geen op te volgen processen`,
    noGeneralPractitionersDefined: `Geen huisartsen aangemaakt, klik op '+' om een nieuwe aan te maken`,
    noInventoryDefined: `Geen voorraad gedefinieerd`,
    noInventoryItemsFound: `Geen voorraadartikelen gevonden`,
    noInventoryItemsFoundChangeSearch: `Geen voorraadartikelen gevonden, wijzig zoekopdracht`,
    noItDoesntFit: `Nee, het past niet`,
    noItemsFound: `Geen items gevonden`,
    noItemsFoundForSupplier: (supplier: string | number) => `Geen items gevonden voor leverancier ${supplier.toString().toLowerCase()}`,
    noItemsInStockContent: `Geen items in de stock, voeg een bestelling, levering, verkoop toe om items te zien in deze lijst`,
    noLabelsAssignedYet: `Nog geen labels toegekend`,
    noLinesDefinedYet: `Nog geen lijnen toegevoegd`,
    noLinesSelectedYet: `Nog geen lijnen geselecteerd`,
    noLinkedAccountsReceivableForCashSales: `Geen gekoppelde klantenrekeningen voor een contante verkoop`,
    noLinkWithHealthInsuranceFund: `Ziekenfonds niet gelinkt`,
    noLoansRegisteredYet: `Nog geen uitleningen geregistreerd`,
    noLocationsDefined: `Geen locaties gevonden, klik op '+' om een nieuwe aan te maken`,
    noLogoPresent: `Geen logo aanwezig`,
    noLongerActive: `Niet (langer) actief`,
    nomenclature: `Nomenclatuur`,
    nomenclatureCode: `Nomenclatuurcode`,
    nomenclatureSingularOrPlural: `Nomenclatu(u)r(-en)`,
    noneActive: `Geen actieve`,
    noneOfAbove: `Geen van bovenstaande`,
    noNoahDataAvailable: `Geen gegevens uit Noah beschikbaar`,
    noNomenclaturesSelected: `Geen nomenclaturen geselecteerd`,
    noNotifications: `Geen meldingen`,
    noOffices: `Géén praktijken`,
    noOfficesDefined: `Er zijn nog geen praktijken aangemaakt`,
    noOnlyForThis: `Neen, enkel voor dit`,
    noOrdersRegisteredYet: `Nog geen bestellingen geregistreerd`,
    noPatientFoundWithThisSsnrCreateNewQuestion: `Geen patiënt gevonden met dit rijksregisternummer\nNieuwe patiënt aanmaken?`,
    noPatientsDefined: `Geen patiënten gevonden, klik op '+' om een nieuwe aan te maken.`,
    noPatientSelected: `Geen patiënt geselecteerd`,
    noPaymentsReceivedYet: `Nog geen betalingen ontvangen`,
    noPaymentsRegisteredYet: `Nog geen betalingen geregistreerd`,
    noPhoneNumber: `Geen telefoonnummer`,
    noPreviousSoldLines: `Geen eerder verkochtte lijnen`,
    noProcessesDefinedForThisPatientYet: `Nog geen processen aangemaakt voor deze patiënt`,
    noProductsDefinedClickPlusToCreateNew: `Geen producten gedefinieerd, druk op '+' om een nieuw product aan te maken`,
    noPseudoNomenclatureSelected: `Geen pseudo-nomenclatuur geselecteerd`,
    noPurchasesRegisteredYet: `Nog geen aankopen geregistreerd`,
    noRateDefined: `Geen tarief ingesteld`,
    noRepairsRegisteredYet: `Nog geen herstellingen geregistreerd`,
    noResults: `Geen resultaten`,
    noResultsCommaChangeFilterParams: `Geen resultaten, pas zoekopdracht aan`,
    noRfiaDefinedText: `Geen aanvragen geregistreerd, ga naar patiënt om een nieuwe aan te maken`,
    noRIZIVNumberLinkAction: `Klik hier om te linken`,
    noRIZIVNumberLinkedText: `Geen RIZIV-nummer gelinkt aan deze gebruiker`,
    normal: `Normaal`,
    normalOrder: `Gewone bestelling`,
    normalOrderCaption: `Selecteer handmatig artikelen uit de product cataloog`,
    normScore: `Norm score`,
    normValue: `Normwaarde`,
    normValueForThisList: (list: string | number) => `Normaalwaarde voor de lijst: ${list.toString().toLowerCase()}`,
    noSalesRegisteredYet: `Nog geen verkopen geregistreerd`,
    noShow: `No-Show`,
    noSignatureIsNeededForThisDocument: `Dit document moet nooit worden ondertekend`,
    noSignatureNeeded: `Geen handtekening nodig`,
    noSocialSecurityNumberDefined: `Geen rijksregisternummer ingegeven`,
    noSpeechTestRecordedCreateOneFirst: `Er is geen spraaktest afgenomen.\nCreëer eerst een spraaktest via een Noah module.`,
    noSubProcess: `Géén subproces`,
    noSuppliersDefined: `Geen leveranciers gevonden, klik op '+' om een nieuwe aan te maken`,
    noSupplierWithPurchasesRegisteredYet: `Nog geen leveranciers (met verkopen) geregistreerd`,
    notACashDeskLocation: `Gebruikt geen kassa`,
    notActive: `Niet actief`,
    notActived: `Niet geactiveerd`,
    notAllFallbackGLAccountsAreDefined: `Niet alle 'fallback grootboekrekening'-codes zijn gedefinieerd`,
    notAllFieldsAreFilledInForThisPatient: `Niet alle nodige velden zijn ingevuld voor deze patiënt.\nWil je deze nu invullen?`,
    notAllNomenclaturesAreValidOnThisDate: `Niet alle geselecteerde nomenclaturen zijn geldig op deze datum!\nIn aanvraag`,
    notAllRIZIVCodesAreSelectedDoYouWantToProceed: `Niet alle RIZIV-codes zijn geselecteerd,\nben je zeker dat je wil verdergaan?`,
    notAllUserVisibleHelperText: `Niet alle gebruikers zichtbaar? Enkel audiologen worden getoond.\nEen audioloog is een gebruiker met een RIZIV nummer`,
    notApplicable: `Niet van toepassing`,
    notASingleDeviceRegisteredInProductCatalog: `Geen enkel toestel geregistreerd in de product cataloog`,
    notAStockLocation: `Locatie zonder voorraad`,
    notAuthorizedCommaMandatoryToRegisterAgain: `Niet geauthorizeerd, verplicht opnieuw te registreren`,
    notAvailable: `Niet beschikbaar`,
    notAvailableForWhat: (what: string | number) => `Niet beschikbaar voor ${what.toString().toLowerCase()}`,
    notAvailableInCalendar: `Niet beschikbaar in kalender`,
    notAvailableInCalendarCaption: `Op deze locatie is het niet mogelijk afspraken in te plannen.`,
    notCompleted: `Niet afgerond`,
    notConfirmed: `Niet bevestigd`,
    notConfirmedTooltip: `Gebruiker heeft e-mail nog niet geopend en nog geen profiel aangemaakt.`,
    notDeleted: `Niet verwijderd`,
    notDeliveredOrdersSingularOrPlural: `Niet gel. Bestellingen`,
    notDeliveredYet: `Nog niet geleverd`,
    notDirectedToVatCustomer: `Niet gericht aan een BTW klant`,
    note: `Bon`,
    notEditable: `Niet editeerbaar`,
    noTemplatesForThisTypeOrSubject: `Geen templates voor dit type/onderwerp`,
    noTendersRegisteredYet: `Nog geen offertes geregistreerd`,
    notFilledIn: `Niet ingevuld`,
    notForAPatient: `Niet voor een patiënt`,
    notForPatient: `Niet voor patiënt`,
    notFoundAtInventoryItems: `Niet gevonden bij de voorraadartikelen`,
    notFoundInReadOutsFromNoah: `Niet gevonden in uitlezingen van Noah`,
    notHandedOverNorFailed: `Niet afgeleverd en niet negatief`,
    nothingCredited: `Niets gecrediteerd`,
    nothingFoundCommaChangeSearchParams: `Niets gevonden, wijzig de zoekparameters`,
    nothingPaid: `Niet betaald`,
    notifications: `Meldingen`,
    notImported: `Niet geïmporteerd`,
    notImportedNotIgnored: `Niet geïmporteerd en niet genegeerd`,
    notInventoried: `Niet geïnventariseerd`,
    notKnownYet: `Nog niet gekend`,
    notLinked: `Niet gelinkt`,
    notLinkedInNoah: `Niet gekoppeld in Noah`,
    notLinkedNotIgnored: `Niet gelinkt, niet genegeerd`,
    notPaidAndOverdue: `Niet betaald en achterstallig`,
    notPlannable: `Niet planbaar`,
    notPossibleToMoveARecurringAppointment: `Niet mogelijk een herhalende afspraak te verschuiven`,
    noTransactionsForThisItem: `Geen transacties voor dit item`,
    noTreatmentsStarted: `Nog geen behandelingen opgestart`,
    notRefundable: `Niet vergoedbaar`,
    notRefundableCaption: `Prestatie is niet vergoedbaar`,
    noTrialStartedYet: `Nog geen proefperiode opgestart`,
    notSendToSupplierYet: `Nog niet verzonden naar leverancier`,
    notSet: `Niet ingesteld`,
    notSigned: `Niet gehandtekend`,
    notSynced: `Niet gesynchroniseerd`,
    notTangible: `Niet tastbaar`,
    notTangibleCaption: `Een niet tastbaar product\nEen dienst, verzekering of verlengde garantie`,
    notUpToDate: `Niet up-to-date`,
    notViewableInApp: `Niet te bekijken/editeren in app`,
    notWhat: (what: string | number) => `Niet ${what.toString().toLowerCase()}`,
    notYetRepaired: `Nog niet hersteld`,
    notYetSynchronized: `Nog niet gesynchroniseerd`,
    noTypesAnnotationDefined: `Nog geen types van aantekeningen/opmerkingen gedefineerd`,
    noTypesAppointmentsDefined: `Nog geen afspraak types gedefinieerd`,
    noVatCustomer: `Geen BTW klant`,
    noVatNumber: `Geen BTW-nummer gevonden`,
    noWhat: (what: string | number) => `Geen ${what.toString().toLowerCase()}`,
    noWhatDefined: (what: string | number) => `Geen ${what.toString().toLowerCase()} gedefinieerd`,
    noWhatFound: (what: string | number) => `Geen ${what.toString().toLowerCase()} gevonden`,
    noWhatFoundClickPlusToCreateNew: (what: string | number) => `Geen ${what.toString().toLowerCase()} gevonden, klik op '+' om een nieuwe te maken`,
    noWhatRegistered: (what: string | number) => `Geen ${what.toString().toLowerCase()} geregistreerd`,
    noWhatSelected: (what: string | number) => `Nog geen ${what.toString().toLowerCase()} geselecteerd`,
    nowNotWhat: (what: string | number) => `Nu niet ${what.toString().toLowerCase()}`,
    nowWhat: (what: string | number) => `Nu ${what.toString().toLowerCase()}`,
    number: `Nummer`,
    numbering: `Nummering`,
    numberingWhat: (what: string | number) => `Nummering ${what.toString().toLowerCase()}`,
    office: `Praktijk`,
    offices: `Praktijken`,
    ofPreviousStep: `Van de vorige stap`,
    ofThePatient: `Van de patiënt`,
    ofWhichCountInAfterTreatment: (count: string | number) => `Waarvan ${count.toString().toLowerCase()} in nabehandeling`,
    oldestFirst: `Oudste eerst`,
    oldPassword: `Oud wachtwoord`,
    onConsignment: `Op consignatie`,
    oneDecimal: `1 cijfer na de komma`,
    oneOrMoreArticlesHasNoGLAccountLinked: `Één of meerdere artikelen heeft geen grootboekrekening code`,
    oneOrMoreOffices: `Eén of meerdere praktijken`,
    oneOrMoreRosaUsersOrLocationsUnlinked: `Eén of meerdere gebruikers/locaties niet gelinkt`,
    oneOrMultipleNextLinesPossibleValues: `Eén of meerdere\nwaardes mogelijk`,
    onePurchase: `Eén aankoop`,
    oneSale: `Eén verkoop`,
    onlyActive: `Enkel actieve`,
    onlyAdminUserCanPerformThisAction: `Alleen admin gebruikers kunnen deze actie uitvoeren`,
    onlyAnArticleForWhatOrientation: (orientation: string | number) => `Artikel enkel voor ${orientation.toString().toLowerCase()}`,
    onlyForThisArticle: `Alleen voor dit artikel`,
    onlyForWhat: (what: string | number) => `Alleen voor ${what.toString().toLowerCase()}`,
    onlyHifPaid: `Alleen V.I. betaald`,
    onlyInCaseOfNegativeEvaluation: `Enkel in geval van negatieve proef`,
    onlyInFuture: `Enkel in de toekomst`,
    onlyInPast: `Enkel in het verleden`,
    onlyPatientPaid: `Alleen patiënt betaald`,
    onlyTangibleItemsWithoutSerialNumberCanBeSelected: `Alleen tastbare artikelen zonder serienummer kunnen worden geselecteerd`,
    onlyWIACompatibleScannersWillBeShown: `Alleen WIA compatible scanners worden getoond`,
    onRemote: `Op afstand (remote)`,
    onThisPageItsPossibleToDefineDifferentAddresses: `Op deze pagina is het mogelijk afwijkende adressen in te geven.`,
    open: `Open`,
    openConfigurationScreen: `Open configuratie scherm`,
    optional: `Optioneel`,
    options: `Opties`,
    order: `Bestelling`,
    orderArticles: `Artikelen bestellen`,
    orderCancelled: `Bestelling geannuleerd`,
    orderDate: `Datum bestelling`,
    ordered: `Besteld`,
    orderForASinglePatientContent: `Bestelling is bestemd voor exact één patiënt`,
    orderForm: `Bestelbon`,
    orderFormToWho: (who: string | number) => `Bestelbon voor ${who}`,
    orderForNoneOneOrMultiplePatientsContent: `Gegroepeerde bestelling voor geen, één of meerdere patiënten`,
    orderFromNeedsCaption: `Maak een bestelling vertrekkende vanuit de behoeften`,
    orderIsForASupplierWhoIsNotRegisteredYet: `Bestelling is voor een leverancier die nog niet is geregistreerd`,
    orderNumber: `Bestelnummer`,
    orderOrSelectFromStock: `Bestel of selecteer uit voorraad`,
    orderOrSelectNeeds: `Bestel of selecteer behoeften`,
    orderPlaced: `Bestelling geplaatst`,
    orders: `Bestellingen`,
    orderSlashAllocate: `Bestellen/toewijzen`,
    orientation: `Oriëntatie`,
    orientationTest: `Oriëntatietest`,
    origin: `Oorsprong/herkomst`,
    original: `Origineel`,
    originalIdentificationCode: `Originele identificatiecode`,
    other: `Andere`,
    otherActiveTreatmentForPatientDoYouWantToContinueQuestion: (whatX: string | number) => `Er zijn nog andere actieve behandelingen voor '${whatX}'\nBen je zeker dat je een nieuwe behandeling wil opstarten?`,
    otherArticles: `Andere artikelen`,
    otherCalendars: `Andere agenda's`,
    otherData: `Andere gegevens`,
    otherDocuments: `Overige documenten`,
    otherExplanation: `Met/zonder  serienummer (bv. Oplader, …)`,
    otherItemFromProductCatalog: `Ander item uit de product cataloog`,
    otherNewArticleCaption: `Met/zonder serienummer\nWel/niet op maat gemaakt`,
    otherPatientWithThisSsNumberAlreadyExists: `Er bestaat reeds een patiënt met dit rijksregisternummer`,
    otherRunningRfiaProcessesForPatientDoYouWantToContinueQuestion: (whatX: string | number) => `Er zijn nog andere lopende aanvragen voor '${whatX}'\nBen je zeker dat je een nieuwe aanvraag wil starten?`,
    otherSettings: `Overige instellingen`,
    otherTailorMade: `Andere (op maat)`,
    otherTreatments: `Andere behandelingen`,
    otherWithoutSerialNumber: `Andere (zonder serienummer)`,
    otherWithSerialNumber: `Andere (met serienummer)`,
    outstanding: `Openstaand`,
    outstandingBalance: `Openstaand saldo`,
    outstandingBalanceNotOnSps: `Openstaand saldo niet op afr. voorstel`,
    outstandingTenders: `Openstaande offertes`,
    overdue: `Achterstallig`,
    overDue: `Te laat`,
    overview: `Overzicht`,
    overviewDeliveredToDeliver: `Overzicht geleverd/te leveren`,
    overwrite: `Overschrijven`,
    overwriteColumnsOfWhat: (what: string | number) => `Overschrijf kolommen van ${what.toString().toLowerCase()}`,
    ownProperty: `Eigen bezit`,
    padNumbers: `Aantal volgnummers`,
    padNumbersExplanation: `bv. Om 0001 te bekomen, selecteer dan 4. Om 001 te bekomen, selecteer 3`,
    page: `Pagina`,
    pageColonIndexOfCount: (index: string | number, count: string | number) => `Pagina: ${index.toString().toLowerCase()} van ${count.toString().toLowerCase()}`,
    paid: `Betaald`,
    paidBeforeApprovals: `Betaald vóór goedkeuringen`,
    paidOut: `Uitbetaald`,
    paidOutDate: `Datum uitbetaling`,
    paperSize: `Papier formaat`,
    partialAssigned: `Deels toegewezen`,
    partialDelivered: `Deels geleverd`,
    partialDelivery: `Deellevering`,
    partlyDelivered: `Deels geleverd`,
    partlyPaid: `Deels betaald`,
    partOfRfiaClickToNavigate: `Onderdeel van aanvraag tot verzekeringstegemoetkoming, klik om hiernaar te navigeren`,
    partOfTmpClickToNavigate: `Onderdeel van een op maat gemaakt product, klik om hiernaar te navigeren`,
    partPatient: `Deel patiënt`,
    passportPhotograph: `Pasfoto`,
    password: `Wachtwoord`,
    passwordChanged: `Wachtwoord gewijzigd`,
    passwordComplexityRule: `Wachtwoord moet bestaan uit minstens 6 tekens waarvan één hoofdletter [A-Z], één kleine letter [a-z], een getal [0-9] en een complex symbool [!@#$%]`,
    passwordDoesNotMatch: `Wachtwoorden komen niet overeen`,
    passwordIncorrect: `Incorrect wachtwoord`,
    patient: `Patiënt`,
    patientActiveCaption: `Patiënt is een actieve patiënt`,
    patientAlsoExistsInNoah: `Patiënt bestaat ook in Noah`,
    patientCannotChangeIfeIDRead: `Aangezien data van eID kaart is uitgelezen, \nis het niet mogelijk naam/geslacht/geboortedatum te wijzigen`,
    patientCreated: `Patiënt aangemaakt`,
    patientData: `Patiëntgegevens`,
    patientDeceasedCaption: `Patiënt is overleden`,
    patientDetailOverviewLayoutPreferencesDescription: `Sleep hieronder de elementen naar de 'linkse' of 'rechtse' kolom om ze in het overzichtsscherm van de patiënt zichtbaar te maken.`,
    patientInactiveCaption: `Patiënt wordt niet langer als 'actief' beschouwd`,
    patientPermanentlyDeleted: `Patiënt definitief verwijderd`,
    patients: `Patiënten`,
    patientUpdated: `Patiënt gewijzigd`,
    payBefore: `Betalen voor`,
    payedByHifShort: `Betaald door ziekenf.`,
    payedByPatient: `Betaald door patiënt`,
    payment: `Betaling`,
    paymentDate: `Datum van betaling`,
    paymentDetails: `Betaalgegevens`,
    paymentMethod: `Betaalmethode`,
    paymentMethods: `Betaalmethodes`,
    paymentMultiplePurchasesCaption: `Betaling heeft betrekking tot meerdere aankopen\nDeelbetaling is niet mogelijk`,
    paymentMultipleSalesCaption: `Betaling heeft betrekking tot meerdere verkopen\nDeelbetaling is niet mogelijk`,
    paymentOnePurchaseCaption: `Betaling heeft betrekking tot slechts één aankoop\nMogelijks zelfs een deelbetaling`,
    paymentOneSaleCaption: `Betaling heeft betrekking tot slechts één verkoop\nMogelijks zelfs een deelbetaling`,
    paymentReference: `Betaalreferentie`,
    payments: `Betalingen`,
    paymentsNotRegisteredInHAMS: `Betaling(-en) niet geregistreerd in HAMS`,
    paymentSpsCaption: `Betaling heeft betrekking tot een eerder gemaakt afrekeningsvoorstel`,
    paymentStatus: `Betaalstatus`,
    payout: `Uitbetaling`,
    payouts: `Uitbetalingen`,
    payVerb: `Betalen`,
    pending: `In afwachting`,
    percentage: `Percentage`,
    personalData: `Persoonsgegevens`,
    personalInformationData: `Persoonlijke informatie`,
    personalisedVersion: `Gepersonaliseerde versie`,
    personalShare: `Persoonlijk aandeel`,
    phone: `Telefoonnummer`,
    phoneNumberShort: `Tel. nr.`,
    physicalQuantity: `Op voorraad (fysiek)`,
    place: `Plaats`,
    placed: `Geplaatst`,
    placeDigitalSignature: `Plaats digitale handtekening`,
    placedWithSupplier: `Geplaatst bij leverancier`,
    placeOrder: `Plaats bestelling`,
    placeOrderImmediatelyWithSupplier: `Plaats bestelling onmiddellijk bij de leverancier`,
    placeOrderImmediatelyWithSupplierContent: `Bestelling is volledig en mag doorgestuurd worden naar de leverancier`,
    placeOrderWithSupplier: `Plaats bestelling bij leverancier`,
    placeOrderWithSupplierLater: `Plaats bestelling pas later bij de leverancier`,
    placeOrderWithSupplierLaterContent: `Voeg op een later tijdstip nog lijnen toe aan deze bestelling`,
    placeWithSupplier: `Plaats bij leverancier`,
    planAppointment: `Afspraak inplannen`,
    plannable: `Planbaar`,
    plannableLocations: `Planbare locaties`,
    plannableUsers: `Planbare gebruikers`,
    plannedAppointmentIsAfterExpectedRedeliveryDate: `De ingeplande afspraak valt NA de verwachtte herleverdatum!`,
    pleaseAddOffice: `Gelieve eerst een praktijk toe te voegen voor deze arts.`,
    point: `Punt`,
    points: `Punten`,
    portal: `Portaal`,
    positive: `Positief`,
    predefined: `Voorafgedefinieerd`,
    predefinedType: `Voorafgedefinieerd type`,
    preferences: `Voorkeuren`,
    preferredLocation: `Voorkeurslocatie`,
    preferredLocationFor: `Voorkeurslocatie voor`,
    prefix: `Prefix`,
    prescription: `Voorschrift`,
    prescriptionAttachment17: `Voorschrift (bijlage 17)`,
    prescriptionDate: `Datum voorschrift`,
    prescriptionForTrial: `Voorschrift voor testen`,
    prescriptionForTrialDate: `Datum voorschrift voor testen`,
    prescriptionForTrialReceived: `Voorschrift voor testen ontvangen`,
    prescriptionReceived: `Voorschrift ontvangen`,
    prescriptionStep1Caption: `Voorschrift voor de testen van de toerusting ter correctie van het gehoor\nIngevuld door NKO arts`,
    prescriptionStep2Caption: `Aanvraag door audicien\nIngevuld door de Audicien`,
    prescriptionStep3Caption: `Voorschrift van de toerusting\nIngevuld door NKO arts`,
    prescriptionStep4Caption: `Goedkeuring adviserend geneesheer\nIngevuld door het ziekenfonds`,
    pressEnterToSearch: `Druk enter om te zoeken`,
    previouslyPurchasedItem: `Reeds aangekocht item`,
    previousSale: `Vorige verkoop`,
    previousTrial: `Vorige proef`,
    price: `Prijs`,
    priceExVat: `Prijs (excl. BTW)`,
    priceForPatient: `Prijs voor de patiënt`,
    priceIncludesAllContributionsRecupelAndBebat: `Prijs is inclusief alle bijdrages (Recupel & Bebat)`,
    priceInVat: `Prijs (incl. BTW)`,
    priceMustBeLowerThanListPrice: `Bedrag moet lager zijn dan de aanbevolen prijs`,
    prices: `Prijzen`,
    pricing: `Prijszetting`,
    primaryContactInfosAreUsedForCommunicatingWithThePatient: `Primaire contact infos worden gebruikt om te communiceren met de patiënt (bvb. SMS of e-mail)`,
    print: `Print`,
    printTicket: `Print ticket`,
    priority: `Prioriteit`,
    privateLabel: `Private label`,
    privateLabelData: `Private labelgegevens`,
    proceed: `Ga verder`,
    procentSpeechImprovementAccordingToBIAPMethod: `% spraakverstaan volgens BIAP methode`,
    procentSpeechImprovementAccordingToICAMethod: `% spraakverstaan volgens I.C.A. methode`,
    procentSpeechImprovementAtSrtPoint: `% spraakverstaan op het SRT punt`,
    process: `Proces`,
    processAdvanceInvoice: `Verwerk voorschotfactuur`,
    processes: `Processen`,
    product: `Product`,
    productCatalog: `Product cataloog`,
    productCatalogItem: `Product cataloog item`,
    productCreated: `Product aangemaakt`,
    productInfo: `Product informatie`,
    productName: `Productnaam`,
    productUpdated: `Product aangepast`,
    productWithoutSerialNumber: `Product zonder serienummer`,
    productWithSerialNumber: `Product mét serienummer`,
    productWithThisIntRefExists: `Er bestaat al een product met deze referentie.`,
    productWithThisManRefExists: `Er bestaat al een product met deze referentie van de fabrikant.`,
    productWithThisNameExists: `Er bestaat al een product met deze naam.`,
    profile: `Profiel`,
    profit: `Winst`,
    proforma: `Pro forma`,
    proformaAttachment12: `Pro forma bijlage 12`,
    prolongedWarranty: `Verlengde garantie`,
    properties: `Eigenschappen`,
    propertiesDoNotMatch: `Eigenschappen komen niet overeen`,
    propertiesOfHearingAid: `Eigenschappen van hoortoestel`,
    propertiesOfTemplateNotRecursiveOnDocuments: `De eigenschappen van eerder gemaakte documenten worden NIET aangepast.\nDe aanpassing heeft enkel betrekking op toekomstige documenten`,
    propertiesOfWhat: (what: string | number) => `Eigenschappen van ${what.toString().toLowerCase()}`,
    propertiesSingleOrPlural: `Eigenschap(-pen)`,
    property: `Eigenschap`,
    propertyIsNotEqual: (property: string | number) => `'${property.toString().toLowerCase()}' komt niet overeen`,
    propertyOf: `Eigendom van`,
    providePassword: `Geef wachtwoord`,
    pseudoNomenclatureCode: `Pseudo nomenclatuurcode`,
    purchase: `Aankoop`,
    purchaseCreditDate: `Datum kredietnota aankoop`,
    purchaseCredited: `Aankoop gecrediteerd`,
    purchaseDate: `Datum aankoop`,
    purchaseListPrice: `Aanbevolen aankoopprijs`,
    purchaseListPriceShort: `Aanbev. Aankoopprijs`,
    purchaseOrderCustomer: `Bestelbon klant`,
    purchaseOrderSupplier: `Bestelbon leverancier`,
    purchasePrice: `Aankoopprijs`,
    purchasePrices: `Aankoopprijzen`,
    purchaseReference: `Referentie aankoop`,
    purchases: `Aankopen`,
    purchaseStatus: `Aankoop status`,
    purchaseVerb: `Aankopen`,
    pushDataToRosa: `PUSH gegevens naar ROSA`,
    pushPatientDataToRosa: `PUSH patiëntengegevens naar ROSA`,
    pushSlashSyncWithExactOnline: `Push/Sync met Exact Online`,
    qtyMoreThanAvailableAtLocation: (available: string | number, location: string | number) => `Aantal meer dan aantal beschikbaar (${available.toString().toLowerCase()}) op locatie ${location.toString().toLowerCase()}, (stock wordt negatief op deze locatie)`,
    qualificationCodes: `Kwalificatiecode(s)`,
    quantity: `Aantal`,
    quantityInStock: `Aantal in stock`,
    rate: `Tarief`,
    reactivatePaymentMethod: `Betaalmethode terug activeren?`,
    reactivatePaymentMethodContent: `Deze betaalmethode is vervolgens terug beschikbaar`,
    reactivatePaymentMethodForWhatContent: (what: string | number) => `Deze betaalmethode is vervolgens terug beschikbaar voor ${what.toString().toLowerCase()}`,
    reactivatePaymentMethodForWhatQuestion: (what: string | number) => `Betaalmethode terug activeren voor ${what.toString().toLowerCase()}?`,
    read: `Lees`,
    readData: `Lees gegevens`,
    readDataFromeID: `Lees gegevens van eID kaart`,
    readeID: `Lees eID`,
    readEIDData: `eID gegevens uitlezen`,
    readOn: `Gelezen op`,
    readOutAs: `Uitgelezen als`,
    readOutFromUbl: `Uitgelezen van UBL`,
    reason: `Reden`,
    reasons: `Redenen`,
    reasonsAppointment: `Redenen afspraak`,
    rebuildFromTemplate: `Opnieuw aangemaakt (van template)`,
    recalculating: `Herberekenen`,
    receive: `Ontvangen`,
    receiver: `Receiver`,
    receiverNewArticleCaption: `Luidspreker, receiver (voor RITE HT)`,
    reception: `Ontvangst`,
    receptionDate: `Datum ontvangst`,
    receptionDateOfDocuments: `Datum ontvangst documenten`,
    receptionFeedbackAdvisoryPhysician: `Ontvangst feedback adv. Geneesheer`,
    receptionTermOfDocuments: `Termijn ontvangst documenten`,
    rechargeable: `Heroplaadbaar`,
    rechargeableBatteries: `Heroplaadbare batterijen`,
    recipient: `Ontvanger`,
    recommended: `Aanbevolen`,
    recommendedSalesprice: `Aanbevolen verkoopsprijs`,
    recupelRate: `Tarief Recupel`,
    recurringAppointment: `Terugkerende afspraak`,
    redelivered: `Herleverd`,
    redeliverVerb: `Herleveren`,
    redelivery: `Herlevering`,
    redeliveryDate: `Datum herlevering`,
    reference: `Referentie`,
    referenceDate: `Referentiedatum`,
    referenceFromSupplier: `Referentie van de leverancier`,
    referenceIsNotANumber: `Referentie is geen nummer`,
    referenceMustNotContainAYear: `De referentie moet geen jaartal bevatten`,
    referencePriceShort: `Ref. prijs`,
    references: `Referenties`,
    referenceShort: `Ref.`,
    refund: `Terugbetaling`,
    refundable: `Vergoedbaar`,
    refundableCaption: `Prestatie is vergoedbaar`,
    refundAmount: `Terug te betalen bedrag`,
    refunds: `Terugbetalingen`,
    refundsToProcess: `Te behandelen terugbetalingen`,
    regenerateDocumentFromTemplate: `Document opnieuw genereren van template`,
    regenerateDocumentFromTemplateQuestion: `Document opnieuw genereren van template?`,
    regenerateTheDocument: `Maak het document opnieuw`,
    register: `Registreer`,
    registerAgain: `Opnieuw registreren`,
    registerANewOrder: `Registreer een nieuwe bestelling`,
    registerANewRepair: `Registreer een nieuwe herstelling`,
    registerANewRepairCaption: `Voeg niet toe aan bestaande herstelling, creëer een nieuwe`,
    registerArticles: `Artikelen registreren`,
    registerAsBroken: `Registreer als defect`,
    registerAsSold: `Registreer een historische verkoop`,
    registerDelivery: `Registreer levering`,
    registered: `Geregistreerd`,
    registeredAsBroken: `Geregistreerd als defect`,
    registeredAsPurchasedScript: `Aankoop (script)`,
    registeredAsSold: `Geregistreerd als verkocht`,
    registeredWhat: (what: string | number) => `${what} geregistreerd`,
    RegisterForWebinar: `Registreer je voor de webinar`,
    registerGroupPaymentForSupplier: (supplier: string | number) => `Registreer groep betaling voor leverancier: ${supplier.toString().toLowerCase()}`,
    registerHandover: `Registreer aflevering`,
    registerHandoverEquipment: `Registreer aflevering toerusting`,
    registerInventoryItemAsSold: `Voorraadartikel registreren als verkocht`,
    registerLetterDispatch: `Registreer verzending documenten`,
    registerLumpSumContribution: `Forfaitaire tegemoetkoming registreren`,
    registerMultipleSales: `Registreer twee verkopen`,
    registerMultipleSalesCaption: `Voor de patiënt wordt een verkoop geregistreerd.\nDe terugbetaling wordt via een aparte verkoop aangerekend aan het ziekenfonds.\nOok via deze methode is het mogelijk alsnog een verzamelstaat op te stellen.`,
    registerNewPurchase: `Registreer nieuwe aankoop`,
    registerNewSale: `Registreer nieuwe verkoop`,
    registerNewUser: `Registreer een nieuwe gebruiker`,
    registerOneSale: `Registreer één verkoop`,
    registerOneSaleCaption: `Er wordt 1 verkoop geregistreerd.\nDe terugbetaling wordt aangerekend aan het ziekenfonds op basis van deze verkoop.`,
    registerPayment: `Registreer betaling`,
    registerPaymentsInHAMS: `Betalingen registreren in HAMS`,
    registerReceptionMedicalPrescription: `Ontvangst medisch voorschrift registreren`,
    registerRefund: `Registreer terugbetaling`,
    registerThisComputer: `Registreer deze computer`,
    registerWhat: (what: string | number) => `Registreer ${what.toString().toLowerCase()}`,
    registration: `Registratie`,
    registrationReceptionPrescription: `Registratie ontvangst voorschrift`,
    reimbursementProcedure: `Terugbetalings-procedure/proces`,
    rejectFittingContent: `Producten worden teruggestuurd naar leverancier, deze flow wordt beëindigd`,
    releaseNote: `Release note`,
    remark: `Opmerking`,
    remarks: `Opmerkingen`,
    remote: `Remote`,
    remoteDevice: `Remote toestel`,
    removedFromATreatment: `Verwijderd van een behandeling`,
    removeEidData: `eID gegevens verwijderen`,
    removeLink: `Verwijder link`,
    removeLinkBetweenRosaAndHamsPatient: `Verwijder link tussen ROSA en HAMS patiënt`,
    removeLinkFromWithRosaPatientQuestion: `Wil je de link tussen ROSA en HAMS patiënt verwijderen?`,
    removeLinkWithPatient: `Verwijder link met patiënt`,
    removeRepairLinkWithPatientContent: `Deze herstelling is niet langer gelinkt met een patiënt.\nWil je verdergaan?`,
    removeThis: `Verwijder deze`,
    renewal: `Hernieuwing`,
    renewalDate: `Hernieuwingsdatum`,
    renewRegistrationToken: `Registratie token vernieuwen`,
    repair: `Herstelling`,
    repairCancelContent: `Door een herstelling te annuleren is het niet mogelijk verdere stappen uit te voeren`,
    repaired: `Hersteld`,
    repairFormToWho: (who: string | number) => `Herstelbon voor ${who}`,
    repairIsSpecificForAPatient: `Herstelling is specifiek voor een patiënt`,
    repairs: `Herstellingen`,
    repairSentToSupplier: `Herstelling verzonden naar leverancier`,
    repairUndoDelete: `Herstel (verwijderen ongedaan maken)`,
    repairVerb: `Herstel`,
    repairWhatQuestion: (what: string | number) => `${what} herstellen?`,
    repeat: `Herhaling`,
    replacedBy: `Vervangen door`,
    replacementDevices: `Vervangtoestellen`,
    replacementDevicesSelected: `Vervangtoestellen geselecteerd`,
    replaceWhat: (what: string | number) => `Vervang ${what.toString().toLowerCase()}`,
    reports: `Verslagen`,
    request: `Aanvraag`,
    requestConfiguration: `Configuratie opvragen`,
    requested: `Gevraagde`,
    requestForInsuranceAllowance: `Aanvraag tot verzekeringstegemoetkoming`,
    requestForInsuranceAllowances: `Aanvragen tot verzekeringstegemoetkoming`,
    requestForInsuranceAllowanceShort: `Aanvraag tot VT`,
    requestForInsuranceAllowancesShortened: `Aanvragen tot VT`,
    requestNew: `Vraag nieuwe aan`,
    requiredFieldsToFillInForAPatient: `Verplicht in te vullen velden van een patiënt`,
    requiredPatientFieldsDescription: `Aangeduide velden hieronder zijn verplicht in te vullen voor elke patiënt\nHAMS toont een popup-scherm als er één of meerdere van deze velden niet zijn ingevuld`,
    requiredProperties: `Verplicht in te vullen velden`,
    reReadDataFromEIDCard: `Gegevens opnieuw uitlezen van eID kaart`,
    resendRegistrationTokenQuestion: `Registratie token opnieuw verzenden?`,
    reservation: `Reservatie`,
    reservationCleared: `Reservatie verwijderd`,
    reserved: `Gereserveerd`,
    reservedArticles: `Gereserveerde artikelen`,
    reservedForAPatient: `Gereserveerd voor een patiënt`,
    reservedForWhat: (whatX: string | number) => `Gereserveerd voor ${whatX}`,
    reservedShort: `Geres.`,
    reservedToAnotherPatient: `Gereserveerd voor een andere patiënt`,
    reset: `Reset`,
    resetPassword: `Stel wachtwoord opnieuw in`,
    resetPasswordMailSent: `Mail verstuurd met instructies om wachtwoord opnieuw in te stellen, check mailbox`,
    restart: `Herstart`,
    restartAfterTreatment: `Nabehandeling terug opstarten`,
    restartAfterTreatmentContentQuestion: `Ben je zeker deze nabehandeling terug op te starten?`,
    restoreVerb: `Terugzetten`,
    results: `Resultaten`,
    resultsHearingImprovementIn: `Resultaten gehoorwinst in`,
    retailPrice: `Retail prijs`,
    retry: `Probeer opnieuw`,
    return: `Retour`,
    returnDate: `Datum retour`,
    returnedToSupplier: `Teruggebracht naar leverancier`,
    returns: `Retours`,
    returnSlip: `Retourbon`,
    returnToSupplier: `Retour naar leverancier`,
    returnToSupplierDate: `Datum retour naar leverancier`,
    revenueExVatPerCategory: `Omzet (ex. BTW) per categorie`,
    revenueExVatPerLocation: `Omzet (ex. BTW) per locatie`,
    revenueExVatPerMonth: `Omzet (ex. BTW) per maand`,
    revenueExVatPerWeek: `Omzet (ex. BTW) per week`,
    revenues: `Ontvangsten`,
    revenueThisYear: `Omzet dit jaar`,
    revertIgnoreSale: `Verkoop negeren ongedaan maken`,
    right: `Rechts`,
    rightColumn: `Rechtse kolom`,
    rightEar: `Rechteroor`,
    rightForRenewal: `Recht op vernieuwing`,
    rightForRenewalTermInfoContent: `Recht op vernieuwing na 'x' jaar\n- >18 jaar bij aflevering dan 5 jaar\n- <18 jaar bij aflevering dan 3 jaar`,
    RIZIV: `RIZIV`,
    RIZIVCode: `RIZIV code`,
    RIZIVCoding: `RIZIV codering`,
    RIZIVNumber: `RIZIV nummer`,
    RIZIVRefundNomenclatureCode: (code: string | number) => `RIZIV terugbetaling nomenclatuur ${code.toString().toLowerCase()}`,
    role: `Rol`,
    room: `Ruimte`,
    rooms: `Ruimtes`,
    roomsAreOnlyUsedInCalendar: `Ruimtes worden enkel gebruikt in de agenda.`,
    rosaHAMSLinkActive: `ROSA - HAMS link actief`,
    rosaHAMSLinkNotActive: `ROSA - HAMS link niet actief`,
    rosaIntegration: `ROSA integratie`,
    rosaLinkedPatientRecord: `Gelinkte patiënt in ROSA`,
    rosaLinkUsersAndSitesPageTitle: `ROSA link gebruikers en locaties`,
    rosaNotActivatedNotification: `Rosa is niet geactiveerd voor deze omgeving, contacteer support@hams.be om deze integratie te activeren.`,
    rosaNotConfiguredYet: `ROSA is nog niet geconfigureerd`,
    rosaPatients: `Patiënten in ROSA`,
    rosaRecordNotFound: `ROSA patiënt niet gevonden`,
    rosaRecordNotFoundErrorDescription: `De opgevraagde link is niet gevonden in HAMS
Gelieve later opnieuw te proberen.`,
    rosaSettings: `ROSA instellingen`,
    rule: `Regel`,
    ruleForWhatDelete: (what: string | number) => `Regel voor ${what.toString().toLowerCase()} verwijderen`,
    ruleForWhatRepair: (what: string | number) => `Regel voor ${what.toString().toLowerCase()} herstellen`,
    rules: `Regels`,
    rulesForReadOuts: `Regels voor het uitlezen`,
    rulesForReadOutsSuggestionCaption: `Als een toestel wordt uitgelezen door NOAH volgens onderstaande benaming(-en), wordt dit artikel uit de product cataloog getoond als suggestie`,
    runningLoanSingularOrPlural: `Lopende uitlening(-en)`,
    runningProcesses: `Lopende processen`,
    runningRepairSingularOrPlural: `Lopende herstelling(-en)`,
    runningRequestSingularOrPlural: `Lopende aanvra(a)g(-en)`,
    saldoBetween: `Saldo tussen`,
    saldoSettlementProposalsShort: `Saldo afr. voorstellen`,
    sale: `Verkoop`,
    saleHasMultipleCustomerAccounts: `Verkoop heeft meerdere klantenrekeningen`,
    saleHasNoCustomerAccounts: `Verkoop heeft géén klantenrekening`,
    saleIsACashSale: `Verkoop is een contante verkoop`,
    sales: `Verkopen`,
    salesCredited: `Verkoop gecrediteerd`,
    salesData: `Verkoopsgegevens`,
    salesDate: `Datum verkoop`,
    salesInvoicesForwarding: `Verkoopfacturen doorsturen`,
    salesListPrice: `Aanbevolen verkoopsprijs`,
    salesListPriceShort: `Aanbev. verkoopsprijs`,
    salesPrice: `Verkoopsprijs`,
    salesReference: `Verkoopsreferentie`,
    salesStatus: `Verkoopstatus`,
    salesType: `Type verkoop`,
    salutation: `Aanhef`,
    sameArticleForBothLeftAndRight: `Zelfde artikel links en rechts`,
    sameLabelForEveryLanguage: `Dezelfde label voor elke taal`,
    save: `Opslaan`,
    saveAsNew: `Sla op als nieuw`,
    saveChanges: `Sla wijzigingen op`,
    saveChangesToDocumentQuestion: `Wil je de huidige wijzigingen aan dit document opslaan?`,
    saveChangesToTemplateQuestion: `Wil je de huidige wijzigingen aan deze template opslaan?`,
    savedColumnSelection: `Opgeslagen kolom selectie`,
    savedSelection: `Opgeslagen selectie`,
    saveThisSelection: `Deze selectie opslaan`,
    scan: `Scan`,
    scanned: `Ingescand`,
    scannedDocumentIsWithoutSignature: `Het ingescande document heeft GEEN handtekening`,
    scannedDocumentIsWithSignature: `Het ingescande document heeft WEL een handtekening`,
    scannedOrUploaded: `Ingescand of geüpload`,
    scanner: `Scanner`,
    scanOrUploadDocument: `Scan of upload document`,
    scanSignatureLater: `Later handtekening inscannen`,
    scanSoftware: `Scan software`,
    schedule: `Agenda`,
    scope: `Toepassingsgebied`,
    score: `Score`,
    scoreWith: `Score met`,
    scoreWithEquipment: `Score met toerusting`,
    scoreWithEquipmentAtSNROfSRT: `Score met toerusting op SNR van SRT`,
    scoreWithout: `Score zonder`,
    scoreWithoutEquipment: `Score zonder toerusting`,
    search: `Zoek`,
    searchAnAddress: `Zoek een adres`,
    searchAndSelectSerialNumberFor: `Zoek en selecteer een serienummer voor`,
    searchAppointmentsAndEvents: `Zoek afspraken & events`,
    searchASerialNumberFor: `Zoek een serienummer voor`,
    searchASerialNumberFromNoahReadOuts: `Zoek een serienummer van uitgelezen toestellen uit Noah`,
    searchFromProductCatalog: `Zoek in product cataloog`,
    searchInProductCatalogOrCreateNewItem: `Zoek in de product cataloog of maak een nieuw item aan`,
    searchOnName: `Zoek op naam`,
    searchOnNameOrExactOnlineCode: `Zoeken op naam of Exact Online code`,
    searchOnNameSlashCode: `Zoeken op naam/code`,
    searchOnReference: `Zoeken op referentie`,
    searchOnRIZIVNumber: `Zoek op RIZIV-nummer`,
    searchOnSerialNumber: `Zoek op serienummer`,
    searchOnWeb: `Zoek op het web`,
    searchOnWhat: (what: string | number) => `Zoek op ${what.toString().toLowerCase()}`,
    searchOtherArticlesBelow: `Zoek hieronder andere artikelen`,
    searchPatients: `Zoek op naam, adres, geboortedatum, tel. # ...`,
    searchSaleForCommission: `Zoek verkoop voor commissie`,
    searchSerialNumbersInInventoryItemsAndNoahReadouts: `Zoeken op serienummer in de voorraadartikelen én in de uitgelezen toestellen door Noah`,
    searchTimeSlot: `Tijdstip zoeken`,
    searchUsers: `Zoek op naam, e-mail …`,
    security: `Beveiliging`,
    securityToken: `Beveiligingstoken`,
    securityTokenIsExpired: `Beveiligingstoken is vervallen`,
    seen: `Gezien`,
    selectADeviceFromRIZIVLimitativeList: `Selecteer zelf een toestel van de RIZIV limitatieve lijst`,
    selectAModule: `Selecteer een module`,
    selectAnArticleFromProductCatalog: `Selecteer een artikel uit de product cataloog`,
    selectAnExistingOrderOrRegisterANew: `Selecteer een bestaande bestelling of registreer een nieuwe`,
    selectAnExistingSerialNumber: `Selecteer een bestaand serienummer`,
    selectAPatient: `Selecteer een patiënt`,
    selectAPreviouslyPurchasedItem: `Selecteer een reeds aangekocht item`,
    selectASupplierForAnOrder: `Selecteer een leverancier voor bestelling`,
    selectATestWithAndWithoutHearingAids: `Selecteer een test mét hoortoestellen en een test zonder hoortoestellen`,
    selectATestWithSpeechAudiograms: `Selecteer een test mét spraakaudiogrammen`,
    selectAtLeastOneWhat: (what: string | number) => `Selecteer minstens 1 ${what.toString().toLowerCase()}`,
    selectCodeFor: (what: string | number) => `Selecteer code voor: ${what.toString().toLowerCase()}`,
    selectCodesFirst: `Selecteer eerst codes`,
    selectDefaultDeliveryTimeFromSupplier: `Selecteer standaard levertermijn van leverancier`,
    selectDeliveryLocationHelperText: `Selecteer de locatie waar de levering zal worden ontvangen`,
    selectedArticle: `Geselecteerd artikel`,
    selectedInATreatment: `Geselecteerd in een behandeling`,
    selectedProduct: `Geselecteerd product`,
    selectedTreatment: `Geselecteerde behandeling`,
    selectedWhat: (what: string | number) => `${what} geselecteerd`,
    selectExistingInventoryItemFromStock: `Selecteer een bestaand artikel uit de voorraad`,
    selectExportableFieldsBelow: `Selecteer hieronder de te exporteren velden`,
    selectFromStock: `Selecteer vanuit de voorraad`,
    selectFromWhat: (what: string | number) => `Selecteer van ${what.toString().toLowerCase()}`,
    selectHealthInsuranceFund: `Selecteer een ziekenfonds`,
    selection: `Selectie`,
    selectItemsToAssure: `Selecteer te verzekeren items`,
    selectItemWithSerialNumberClickHereToLink: `Selecteer een item met serienummer, klik hier om te linken`,
    selectLineFromPreviousSale: `Selecteer een lijn uit een eerdere verkoop`,
    selectLineFromThisPurchase: `Selecteer een lijn uit deze aankoop`,
    selectLineFromThisSale: `Selecteer een lijn uit deze verkoop`,
    selectLocationAtWhereDeliveryWillBeReceived: `Selecteer de locatie waar de levering zal worden ontvangen`,
    selectLocationAtWhereYouAreCurrentlyWorking: `Selecteer de locatie vanwaar je momenteel werkt`,
    selectNeeds: `Selecteer behoeften`,
    selectNhaxOrEnhaxFile: `Selecteer nhax of enhax file`,
    selectNhaxOrEnhaxFileCaption: `Selecteer een .nhax of .enhax bestand\nAls de patiënt reeds bestaat worden de sessies en acties geïmporteerd\nAls de patiënt nog niet bestaat wordt deze aangemaakt`,
    selectNomenclatureCodeFirst: `Selecteer eerst een nomenclatuurcode`,
    selectNomenclatureCodeFirstYouCanDeleteItAfterwards: `Selecteer eerst een nomenclatuurcode\nJe kan deze nadien terug verwijderen`,
    selectOneOrMultipleSuppliers: `Selecteer één of meerdere leveranciers`,
    selectOnlyNecessaryFields: `Selecteer enkel noodzakelijke velden`,
    selectOnlyWho: (who: string | number) => `Selecteer enkel ${who}`,
    selectOtherProductQuestion: `Selecteer een ander product?`,
    selectPseudoNomenclatureCodeFirst: `Selecteer eerst een pseudo-nomenclatuurcode`,
    selectSerialNumbersForSelectedProducts: `Selecteer serienummers voor geselecteerde producten`,
    selectStep: (what: string | number) => `Selecteer ${what.toString().toLowerCase()}`,
    selectSuppliers: `Selecteer leveranciers`,
    selectThis: `Selecteer deze`,
    selectThisArticle: `Selecteer dit artikel`,
    selectVerb: `Selecteer`,
    selectWhat: (what: string | number) => `Selecteer ${what.toString().toLowerCase()}`,
    selectWhatAndCreate: (what: string | number) => `Selecteer ${what.toString().toLowerCase()} en creëer`,
    send: `Opsturen`,
    sendRepairToSupplier: `Verstuur herstelling naar leverancier`,
    sendToSupplier: `Verzenden naar leverancier`,
    sentToAdvisoryPhysicianShort: `Opgestuurd adv. Gen.`,
    sentToEntPhysicianShort: `Opgestuurd NKO`,
    sentToSupplier: `Verzonden naar leverancier`,
    sentToSupplierForRepair: `Opgestuurd naar leverancier ter herstelling`,
    separator: `Scheidingsteken`,
    separatorExamples: `bv. '/' of '-' of '.'`,
    serialNumber: `Serienummer`,
    serialNumberNotUnique: `Serienummer niet uniek`,
    serialNumbers: `Serienummers`,
    serialNumberShort: `Serienr.`,
    service: `Dienst`,
    serviceExplanation: `Een dienst die aan je klanten aangeboden wordt (bv. Gehoormeting)`,
    serviceName: `Naam van de dienst`,
    session: `Sessie`,
    sessions: `Sessies`,
    setAManualDate: `Stel manueel een datum in`,
    setInvoicesAutoToPaid: `Verkopen automatisch op betaald zetten`,
    setInvoicesToPaidViaPayments: `Betaalstatus verkopen opvolgens via betalingen`,
    setLocationAsNotPlannable: `Locatie instellen als niet planbaar`,
    setManualWarrantyEndDate: `Datum einde garantie manuel instellen`,
    setPassword: `Stel wachtwoord in`,
    setStatusPatientToStatuses: `Status patiënt instellen (overleden of niet langer actief)`,
    settings: `Instellingen`,
    settingsAndPreferences: `Instellingen & voorkeuren`,
    settlementProposal: `Afrekeningsvoorstel`,
    settlementProposals: `Afrekeningsvoorstellen`,
    setUserAsNotPlannable: `Gebruiker instellen als niet planbaar`,
    shiftVat: `BTW verleggen`,
    shipment: `Verzending`,
    shortExternalReference: `Ext. ref.`,
    shortInternalReference: `Int. Ref.`,
    shortManufacturerReference: `Fabr. Ref.`,
    showAll: `Toon alles`,
    showAllContents: `Toon alle inhoud`,
    showAllDaysInclWeekend: `Toon alle dagen (incl. ZA/ZO)`,
    showAllWhat: (what: string | number) => `Toon alle ${what.toString().toLowerCase()}`,
    showAnamnesis: `Toon anamnese`,
    showArticleAgain: `Artikel terug tonen`,
    showColon: `Toon:`,
    showHoursFrom: `Toon uren vanaf`,
    showHoursTo: `Toon uren tot`,
    showMore: `Toon meer`,
    showOnlyActive: `Toon enkel actieve`,
    showOnlyNotPaid: `Toon alleen niet betaalde`,
    showOnlyOutstanding: `Toon alleen openstaande`,
    showOnlyWeekdays: `Toon enkel weekdagen`,
    showOnlyWhat: (what: string | number) => `Toon enkel ${what.toString().toLowerCase()}`,
    showProperties: `Toon eigenschappen`,
    showStockPerLocation: `Toon stock per locatie`,
    showThisArticle: `Toon dit artikel`,
    showWhat: (what: string | number) => `Toon ${what.toString().toLowerCase()}`,
    signature: `Handtekening`,
    signatureNeeded: `Handtekening nodig`,
    signatureScanned: `Handtekening ingescand`,
    signatureScannedOrUploaded: `Handtekening ingescand of geüpload`,
    signDigitally: `Digitaal ondertekenen`,
    signed: `Gehandtekend`,
    signVerb: `Handtekenen`,
    singleLine: `Eén enkele lijn`,
    singlePatient: `Exclusieve patiënt`,
    singlePayments: `Enkelvoudige betalingen`,
    singleReadout: `Exact één uitlezing`,
    skipFollowUpProcessesContentQuestion: `Er zijn geen processen op te volgen in deze behandeling.\nBen je zeker dat je deze stap wil overslaan?`,
    skipThisStep: `Sla deze stap over`,
    slashCannotBeUsedAsASerialNumber: `'/' of '|' mag niet voorkomen in een serienummer`,
    slightlyBetter: `Iets beter`,
    slip: `Bon`,
    small: `Klein`,
    socialSecurityNumber: `Rijksregisternummer`,
    socialSecurityNumberAbbreviation: `rrn`,
    software: `Software`,
    softwareNotActiveOnThisComputer: `Software is niet actief op deze computer`,
    solveProblems: `Problemen oplossen`,
    someFunctionsAreNotAccessible: `Sommige functies zijn niet toegankelijk`,
    somethingOrSomething: (first: string | number, second: string | number) => `${first} of ${second.toString().toLowerCase()}`,
    somethingQuestionmark: (something: string | number) => `${something}?`,
    sometimes: `Soms`,
    sortOnWhat: (what: string | number) => `Sorteer op: ${what.toString().toLowerCase()}`,
    source: `Bron`,
    speakerBracketsReceiver: `Luidspreker (receiver)`,
    speechAudiograms: `Spraakaudiogrammen`,
    speechAudiometries: `Spraakaudiometrieën`,
    speechAudiometryNotPossible: `Spraakaudiometrie niet mogelijk`,
    speechAudiometryPossible: `Spraakaudiometrie mogelijk`,
    speechAudiometryPossibleOrNotPossible: `Spraakaudiometrie wel/niet mogelijk`,
    speechAudiometryPossibleWithException: `Spraakaudiometrie mogelijk of uitzondering (2.2.2)`,
    speechGraphs: `Spraakgrafieken`,
    speechInNoise: `Spraak in ruis`,
    speechIntelligibilityImprovement: `Verbetering spraakverstaanbaarheid`,
    speechTest: `Spraaktest`,
    splitUpAttachment12: `Opsplitsing bijlage 12`,
    ssNumbersDoNotMatch: `Rijksregisternummers matchen niet!`,
    standardDocumentWarning: (what: string | number) => `${what} is een gestandaardiseerd document\nmaar HAMS detecteert een gepersonaliseerde versie\nMaak hieronder een keuze`,
    standardisedVersion: `Gestandardiseerde versie`,
    standardWhat: (what: string | number) => `Standaard ${what.toString().toLowerCase()}`,
    startComparisonTrial: `Start vergelijkende proef`,
    startComparisonTrialCaption: `De huidig uitgeleende toestellen blijven bij de patiënt\nNieuwe hoortoestellen worden toegevoegd aan de proefperiode`,
    startDate: `Startdatum`,
    started: `Gestart`,
    startedThisYearBracketsYTD: `Gestart dit jaar (YTD)`,
    startFromAnExample: `Start van een voorbeeld`,
    startFromATemplate: `Start van een template`,
    startLoan: `Uitlening starten`,
    startNewTrial: `Start nieuwe proef`,
    startNewTrialCaption: `De huidig uitgeleende toestellen komen terug in stock\nEen nieuwe proef wordt gestart met andere hoortoestellen`,
    startRepair: `Herstelling opstarten`,
    startSoftware: `Start software`,
    startup: `Opstart`,
    startVerb: `Starten`,
    status: `Status`,
    statusCredited: `Gecrediteerd`,
    statusNotCredited: `Niet gecrediteerd`,
    statusNotPurchased: `Niet aangekocht`,
    statusNotReserved: `Niet gereserveerd`,
    statusNotSold: `Niet verkocht`,
    statusPartlyCredited: `Deels gecrediteerd`,
    statusPurchased: `Aangekocht`,
    statusReserved: `Gereserveerd`,
    statusSold: `Verkocht`,
    step: `Stap`,
    steps: `Stappen`,
    stepsChanged: `Stappen aangepast`,
    stereo: `Stereo`,
    stereoPhonic: `Stereofonisch`,
    stereoWith: `Stereo mét`,
    stereoWithout: `Stereo zonder`,
    stillCountToDeliver: (count: string | number) => `Nog ${count.toString().toLowerCase()} te leveren`,
    stock: `Voorraad`,
    stockInfo: `Stock info`,
    stockInfoNotUpToDateClickToRecalculate: `Stock informatie niet up-to-date, klik hier om opnieuw te berekenen`,
    stockLocation: `Locatie met voorraad`,
    stockMovement: `Stockbeweging`,
    stockMovements: `Stockbewegingen`,
    stockReplentishment: `Stockaanvulling`,
    stockTransactions: `Stock transacties`,
    stockValue: `Stockwaarde`,
    stop: `Stop`,
    stopDate: `Stopdatum`,
    structuredReference: `Gestructureerde mededeling`,
    style: `Stijl`,
    subArticle: `Subartikel`,
    subcategories: `Sub-categorieën`,
    subcategory: `Sub-categorie`,
    subject: `Onderwerp`,
    subProcess: `Subproces`,
    subProcesses: `Sub-processen`,
    subtotal: `Subtotaal`,
    successful: `Geslaagd`,
    summary: `Samenvatting`,
    supplement: `Supplement`,
    supplements: `Toeslagen`,
    supplier: `Leverancier`,
    supplierDetails: `Details van een leverancier`,
    supplierIsNotRegisteredOnThisProduct: (supplier: string | number) => `Leverancier ${supplier.toString().toLowerCase()} is niet geregistreerd op dit product`,
    supplierName: `Naam leverancier`,
    supplierNameIsTaken: `Er bestaat al een leverancier met deze naam`,
    suppliers: `Leveranciers`,
    supplierSingularOrPlural: `Leverancier(s)`,
    supplierUpdated: `Leverancier gëupdatet`,
    supplierWhoHasCountOrdersToAddLinesTo: (who: string | number, count: string | number) => `Leverancier ${who} heeft nog ${count.toString().toLowerCase()} bestelling(-en) waar nog lijnen aan kunnen toegevoegd worden.`,
    supplierWillBeDeleted: `Deze leverancier zal permanent uit het systeem worden verwijdered.`,
    synced: `Gesynchroniseerd`,
    tableFormattingHelperText: `Creëer een tabel zoals in onderstaand voorbeeld\nOnder de hoofding in de eerste kolom wordt de key ingevuld gevolgd door de 'tabel-key'`,
    tailorMadeFor: `Op maat gemaakt voor`,
    tailorMadeHearingAid: `Hoortoestel (op maat)`,
    tailorMadeHearingProtection: `Gehoorbescherming (op maat)`,
    tailorMadeProduct: `Product op maat`,
    tailorMadeProductExplanation: `Wordt op maat gemaakt voor één van je klanten (bv. Oorstukje, gehoorbescherming op maat`,
    tailorMadeProducts: `Producten op maat`,
    tailorMadeProductType: `Type op maat gemaakt product`,
    tailorMadeProductValidated: `Product op maat gevalideerd`,
    tailorMadeShort: `Op maat`,
    tangible: `Tastbaar`,
    tangibleArticle: `Tastbaar artikel`,
    tangibleArticleWithoutSerialNumber: `Tastbaar artikel zonder een serienummer`,
    tangibleArticleWithSerialNumber: `Tastbaar artikel met een serienummer`,
    tangibleCaption: `Een tastbaar product\nbv. een oorstukje, hoorapparaat, gehoorbescherming, …`,
    technicalData: `Technische gegevens`,
    template: `Template`,
    templateCanEditCaption: `Dit document kan aangepast worden`,
    templateCannotEditCaption: `Dit document kan niet meer worden aangepast`,
    templateNoSignatureNeededCaption: `Een document gecreëerd van deze template moet nooit worden ondertekend`,
    templateNotViewableInAppCaption: `Dit document is niet geschikt voor online te bekijken\n(niet alle Word features worden ondersteund in de online editor)`,
    templatePredefinedTypeCaption: `Creëer een template op basis van een voorafgedefinieerd type\nbv. factuur, uitleenbon, bijlage 12, ...`,
    templates: `Templates`,
    templateScanSignatureLaterCaption: `Een document gecreëerd van deze template moet later vervangen worden\ndoor een exemplaar met handtekening`,
    templateStartFromEmptyCaption: `Start van een leeg document`,
    templateStartFromExampleCaption: `Start van een voorbeeld`,
    templateStartFromUploadCaption: `Start van een document op de computer`,
    templateSubjectCaption: `Creëer een template op basis van een onderwerp\nbv. patiënt, aanvraag tot VT, …`,
    templateViewableInAppCaption: `Dit document kan online worden bekeken`,
    tender: `Offerte`,
    tenderCustomerIsAPatientCaption: `Optioneel kan offerte gericht worden aan een BTW klant\nDe offerte wordt gelinkt aan de patiënt`,
    tenderCustomerIsNotAPatientCaption: `Gegevens van de ontvanger worden manueel ingevuld\nOptioneel kan offerte gericht worden aan een BTW klant`,
    tendered: `Offerte opgesteld`,
    tenderLines: `Offertelijnen`,
    tenders: `Offertes`,
    term: `Termijn`,
    termForHandoverAfterApproval: `Termijn aflevering na goedkeuring adv. gen.`,
    termRenewal: `Termijn vernieuwing`,
    terms: `Termijnen`,
    test: `Test`,
    testEquipmentTitle: `Toerusting uittesten (proef)`,
    tests: `Testen`,
    testsAddedOrChangedAfterCreationOfDocument: `Testen toegevoegd of aangepast na aanmaak document!`,
    thanksForYourTimeAndHonestFeedback: `Alvast bedankt voor jullie tijd & eerlijke feedback`,
    theHAMSFeedbackFormIsAvailableAt: `Het HAMS Feedback Formulier is beschikbaar via`,
    thereIsAnUpdateForWhatSoftware: (what: string | number) => `Er is een update voor ${what.toString().toLowerCase()} software`,
    thereIsNoTestWithAsWelALeftAsARightTonalAudiogramCreateFirst: `Er is geen test afgenomen met zowel links als rechts een tonale audiogram\nCreëer eerst deze testen via een Noah module.`,
    thereIsNoTypeTonalAudiogramCreateFirst: (type: string | number) => `Er is geen test afgenomen met een ${type.toString().toLowerCase()} tonale audiogram.\nCreëer eerst deze test via een Noah module.`,
    thermalPrinter: `Thermische printer`,
    thermalPrinters: `Thermische printers`,
    thermalPrinterSoftware: `Thermische printer software`,
    thirdPayerArrangement: `Derdebetalersregeling`,
    thisAndNextAppointments: `Deze en volgende afspraken`,
    thisAndNextWorkscheduleEntries: `Vanaf deze ingave en daaropvolgend`,
    thisAppointment: `Deze afspraak`,
    thisArticleWillBeAvailableForSaleWithNextSale: `Dit artikel is vervolgens terug beschikbaar voor verkoop.`,
    thisDateWillBeUsedToCalculateNomenclaturePrices: `Deze datum wordt gebruikt om de prijzen van nomenclaturen te selecteren`,
    thisDeliveryIsLinkedToAnOrder: `Deze levering is gelinkt met een bestelling`,
    thisDeliveryIsLinkedToARepair: `Deze levering is gelinkt met een herstelling`,
    thisDeliveryIsLinkedToATailorMadeProduct: `Deze levering is gelinkt met een product op maat`,
    thisDocumentContainsASignature: `Dit document bevat een handtekening`,
    thisDocumentMustBeReplacedWithAScanWithSignature: `Dit document moet later vervangen worden door een exemplaar met handtekening`,
    thisFileIsPasswordProtected: `Deze file is beveiligd met een wachtwoord`,
    thisHifDoesNotExistAnymore: `Dit ziekenfonds bestaat niet meer`,
    thisInfoByDefaultOnTop: `Deze info staat standaard bovenaan`,
    thisLineWillBeIgnored: `Deze lijn wordt genegeerd`,
    thisLink: `Deze link`,
    thisOrderIsCancelled: `Deze bestelling is geannuleerd!`,
    thisOrderShouldAlreadyBeDelivered: `Deze bestelling zou al moeten geleverd zijn`,
    thisPatientAlreadyHasALinkWithAROSAPatient: `Deze HAMS patiënt heeft reeds een link met een ROSA patiënt`,
    thisPatientDoesNotExistInHAMSDoYouWantToImportNow: `Deze patiënt bestaat nog niet in HAMS
Wil je deze nu importeren?`,
    thisPatientIsMergedToAnotherPatient: `De data van deze patiënt is samengevoegd naar een andere patiënt`,
    thisProcesIsPartOfARfia: `Dit proces is onderdeel van een aanvraag tot verzekeringstegemoetkoming.`,
    thisProcessIsCancelled: `Dit proces is geannuleerd!`,
    thisPurchase: `Deze aankoop`,
    thisRepairShouldAlreadyBeRedelivered: `Deze herstelling zou al moeten herleverd zijn!`,
    thisSale: `Deze verkoop`,
    thisScreenWillSoonBeReplacedByAnImprovedView: `Dit scherm wordt binnenkort vervangen door een verbeterde weergave.`,
    thisTransactionCannotBeUndoneText: `Opgelet, deze transactie is definitief!\nNiet mogelijk deze nadien te verwijderen\nControleer hieronder de gegevens alvorens te bevestigen`,
    thisWhatWillAlsoBePurchasedFromASupplier: (what: string | number) => `Deze ${what.toString().toLowerCase()} wordt ook aangekocht bij een leverancier`,
    thisWhatWillNotBePurchasedFromASupplier: (what: string | number) => `Deze ${what.toString().toLowerCase()} wordt niet aangekocht bij een leverancier`,
    thisWorkscheduleEntry: `Enkel op deze ingave`,
    timeline: `Tijdslijn`,
    timeScale: `Tijdsschaal`,
    timetable: `Uurrooster`,
    title: `Titel`,
    toAnotherLocation: `Naar andere locatie`,
    toAssign: `Toe te wijzen`,
    toCreateNewProduct: `Om een nieuw product aan te maken`,
    toHandoverToPatient: `Te overhandigen aan patiënt`,
    toLocation: `Naar locatie`,
    tonalAudiogram: `Tonale audiogram`,
    tonalAudiogramOfDate: `Tonale audiogram van`,
    tooltipIsACashDeskResource: `Kan gebruikt worden in de kassamodule`,
    tooltipIsAPlannableResource: `Kan gebruikt worden om afspraken in te plannen`,
    tooltipIsAStockManagementResource: `Kan gebruikt worden voor stockage`,
    tooltipIsNotACashDeskResource: `Kan NIET gebruikt worden in de kassa module`,
    tooltipIsNotAPlannableResource: `Kan NIET gebruikt worden om afspraken in te plannen`,
    tooltipIsNotAStockManagementResource: `Kan NIET gebruikt worden voor stockage`,
    toPayBefore: `Te betalen voor`,
    toPayBeforeDate: `Te betalen voor datum`,
    toPayByPatient: `Te betalen door patiënt`,
    toPlaceWithSupplier: `Te plaatsen bij leverancier`,
    toProcess: `Te verwerken`,
    total: `Totaal`,
    totalAmount: `Totaalbedrag`,
    totalExVat: `Totaal excl. BTW`,
    totalInVat: `Totaal incl. BTW`,
    totalOfContributions: `Totaal tegemoetkomingen`,
    totalPriceDevicesExVat: `Totale prijs toestellen (ex. BTW)`,
    totals: `Totalen`,
    totalValueOfDevices: `Totale waarde van toestellen`,
    totalValueOfSale: `Totale waarde van verkoop`,
    transactions: `Transacties`,
    transactionType: `Type transactie`,
    treatment: `Behandeling`,
    treatmentInvoiceCaption: `Afzonderlijke facturatie bvb. verkoop hoortoestellen zonder aanvraag tot VT`,
    treatmentPermanentlyDeleted: `Behandeling definitief verwijderd`,
    treatments: `Behandelingen`,
    trial: `Proef`,
    trialAdministrativeEndDate: `Administratieve einddatum proef`,
    trialDuration: `Duurtijd test`,
    trialEnded: `Proef beëindigd`,
    trialReport: `Testverslag`,
    trialRunning: `Proef bezig`,
    trialsExpiredNoDocumentsSentForMedicalPrescription: `Proefperiodes verlopen, nog geen documenten verstuurd naar NKO arts`,
    troubleShoot: `Troubleshoot`,
    twoDecimals: `2 cijfers na de komma`,
    type: `Type`,
    typeAnnotation: `Type aanmerking`,
    typeAppointment: `Afspraak type`,
    typeHearingAid: `Type hoortoestel`,
    typePayer: `Type betaler`,
    types: `Types`,
    typesAnnotation: `Types opmerking/aanmerking`,
    typesAppointments: `Types afspraken`,
    typicalForHardwareWithWithoutSerialNumber: `Typisch voor hardware (met/zonder serienummer)`,
    ublFilesToHandle: `Te behandelen UBL's`,
    unassigned: `Niet toegewezen`,
    unblock: `Deblokkeer`,
    unBlockUser: `Deblokkeer gebruiker`,
    unblockUserContent: `Deze gebruiker zal terug kunnen aanmelden`,
    unBlockUserQuestion: `Deblokkeer gebruiker?`,
    undefined: `Niet gedefinieerd`,
    understood: `Begrepen`,
    undoCancellation: `Annulatie ongedaan maken`,
    undoDelete: `Verwijderen ongedaan maken`,
    undoDeleteAnnotationQuestion: `Verwijderen opmerking ongedaan maken?`,
    undoDeleteAttachmentQuestion: `Verwijderen bijlage ongedaan maken?`,
    undoFailedTrail: `Mislukte proef ongedaan maken`,
    undoFailedTrailContent: `Rechtzetting fout; deze aanvraag tot verzekeringstegemoetkoming is niet mislukt.\nWil je verdergaan?`,
    undoFailedTrailShort: `Misl. proef ongedaan maken`,
    undoIgnore: `Negeren ongedaan maken`,
    undoPlaceWithSupplier: `Plaatsen bij leverancier ongedaan maken`,
    undoPlaceWithSupplierQuestion: `Wil je de plaatsing bij de leverancier intrekken?`,
    undoSkipThisStep: `Stap overslaan ongedaan maken`,
    undoSkipThisStepContentQuestion: `Ben je zeker het overslaan van deze stap ongedaan te maken?`,
    unhandled: `Niet behandeld`,
    uniqueReference: `Unieke referentie`,
    unitDiscount: `Eenheidskorting`,
    unitListPrice: `Eenheidsaanbevolen prijs`,
    unitPrice: `Eenheidsprijs`,
    unitVatValue: `BTW per eenheid`,
    unknown: `Ongekend`,
    unknownDateFormat: `Ongekend data formaat`,
    unlimited: `Ongelimiteerd`,
    unlinked: `Niet gelinkte`,
    unpaid: `Niet betaald`,
    unPaidSales: `Niet betaalde verkopen`,
    unsavedChanges: `Niet opgeslagen wijzigingen`,
    updateContactInfo: `Contact info wijzigen`,
    updated: `Aangepast`,
    updateRIZIVNumber: `Update RIZIVnummer`,
    upload: `Upload`,
    uploadDocument: `Document opladen`,
    uploadNHAX: `Upload NHAX`,
    uploadReplaceDocument: `Upload/vervang document`,
    uploadWhat: (what: string | number) => `Opladen ${what.toString().toLowerCase()}`,
    uploadWrittenOrSignedDocument: `Upload een (handgeschreven en/of ondertekend) document`,
    upperCase: `Eerste letter in hoofdletter`,
    useAPrefix: `Gebruik een prefix`,
    useCodeFromProductCatalog: `Gebruik codes van product cataloog`,
    usedFrequencies: `Gebruikte frequenties`,
    usedList: `Gebruikte lijst`,
    useFallbackCodes: `Gebruik fallback values`,
    usefulLinks: `Nuttige links`,
    useLatestDocument: `Gebruik het laatste document`,
    useOriginalDocument: `Gebruik het originele document`,
    user: `Gebruiker`,
    userDefined: `Gebruiker gedefinieerd`,
    userRegisteredCheckEmail: (email: string | number) => `Gebruiker ${email} geregistreerd, controleer e-mail`,
    users: `Gebruikers`,
    userUpdated: `Gebruiker gewijzigd`,
    userWillBeLoggedOutOfAllSessions: `Gebruiker wordt afgemeld uit alle toestellen en browsers`,
    userWillBeLoggedOutOfSpecificSession: `Gebruiker wordt afgemeld uit dit toestel of browser`,
    userWithEmailCreated: (email: string | number) => `Gebruiker met e-mail: ${email} aangemaakt`,
    useThisColorScheme: `Gebruik dit kleurschema`,
    useYear: `Jaartal gebruiken`,
    validated: `Gevalideerd`,
    validateData: `Valideer gegevens`,
    validatedNegativeResult: `Negatief geëvalueerd`,
    validateVerb: `Valideren`,
    validation: `Validatie`,
    validFrom: `Geldig van`,
    validityFrom: `Geldigheid van`,
    validityTo: `Geldigheid tot`,
    validPhoneNumberFormat: `Geldig formaat: +32xxxxxxxxx`,
    validTo: `Geldig tot`,
    vat: `BTW`,
    vatAmount: `BTW bedrag`,
    vatCustomer: `BTW klant`,
    vatCustomers: `BTW klanten`,
    vatNumber: `BTW-Nummer`,
    vatPercentage: `BTW %`,
    ventDiameterInMm: `Ventilatieopening diameter (in mm)`,
    verzamelstaat: `Verzamelstaat`,
    verzamelstaten: `Verzamelstaten`,
    view: `Bekijken`,
    viewableInApp: `Te bekijken in app`,
    viewAccountReceivable: `Bekijk klantenrekening`,
    viewAll: `Bekijk alles`,
    viewAllCashSales: `Bekijk alle contante verkopen`,
    viewAllCountAppointments: (count: string | number) => `Toon alle ${count.toString().toLowerCase()} afspraken`,
    viewAllDetails: `Bekijk alle details`,
    viewArticleFromProductCatalog: `Bekijk artikel van product cataloog`,
    viewCustomerAccount: `Bekijk klantenrekening`,
    viewDetailsOfThisArticle: `Bekijk details van dit artikel`,
    viewDocument: `Bekijk document`,
    viewDocumentation: `Bekijk documentatie`,
    viewEditDocument: `Bekijk/editeer document`,
    viewEditWhat: (what: string | number) => `Bekijk/editeer ${what.toString().toLowerCase()}`,
    viewHAMSPatient: `Bekijk HAMS patiënt`,
    viewHereHowToIntegrateWithROSA: `Bekijk hier hoe je een ROSA integratie kan aanvragen`,
    viewInRosa: `Bekijk in ROSA`,
    viewInventoryItem: `Bekijk voorraadartikel`,
    viewLinkedCustomerAccount: `Bekijk gekoppelde klantenrekening`,
    viewLinkedInventoryItem: `Bekijk gelinkt voorraadartikel`,
    viewLinkedOrder: `Bekijk gelinkte bestelling`,
    viewLinkedRepair: `Bekijk gelinkte herstelling`,
    viewNoahData: `Bekijk NOAH gegevens`,
    viewPreferences: `Weergave voorkeuren`,
    viewRIZIVDocumentation: `Bekijk documentatie van RIZIV`,
    viewRosaHamsUsersLink: `Bekijk ROSA - HAMS gebruikers link`,
    viewSale: `Bekijk verkoop`,
    viewSaleInHAMS: `Bekijk verkoop in HAMS`,
    viewSplitUpPerVatPercentage: `Toon opsplitsing per BTW%`,
    viewTarifs: `Bekijk tarieven`,
    viewTheOldVersionForNowQuestionClickHereToNavigate: `Voorlopig toch de oude weergave zien? Klik hier om naar de naar de oude weergave te gaan.`,
    viewWhatInOtherTab: (what: string | number) => `Bekijk ${what.toString().toLowerCase()} in ander tabblad`,
    viewWhatInThisTab: (what: string | number) => `Bekijk ${what.toString().toLowerCase()} in dit tabblad`,
    waitOnDocumentCreation: `Wachten op aanmaak document`,
    waitOnPaymentReferenceCreation: `Wachten op aanmaak betaalreferentie`,
    wantToMoveAppointmenToAnotherTimeUserLocationQuestion: `Wil je deze afspraak verplaatsen naar een ander tijdstip/gebruiker/locatie?`,
    warning: `Waarschuwing`,
    warningHamsNoahClientVersion: `Opgelet!\n* HAMS-Noah client software werkt enkel met Noah versie 4.12 of later\n* HAMS-Noah client software vereist een geldige Noah licentie`,
    warningOnlyRegistrationOfSerialNumber: `Opgepast, enkel registratie serienummer`,
    warningOnlyRegistrationOfSerialNumberCaption: `Via deze manier is het mogelijk een voorraadartikel met serienummer te definiëren\nDe voorraadstatus is initieel 'niet geïnventariseerd' totdat er transacties worden toegevoegd.\n(transactie = levering, proef, verkoop ...)`,
    warningOnlyThingsLinkedToAttachment12CanBeAltered: `Opgelet; enkel zaken mbt bijlage 12 kunnen worden aangepast (vergoedbaar & RIZIV-codering)`,
    warningOnlyUnlinkedAndNotIgnoredDevicesAreShownHere: `Opgelet: Enkel de NIET gelinkte en NIET genegeerde toestellen worden hier getoond`,
    warningRegistrationHandoverBeforeApprovals: `Opgepast, registratie aflevering vóór goedkeuringen`,
    warningRegistrationHandoverBeforeApprovalsCaption: `Via deze methode is het mogelijk de aflevering te registreren (en een factuur op te maken) vóór de definitieve goedkeuring.\n(Dit is niet conform de RIZIV richtlijnen)`,
    warningSingularOrPlural: `Waarschuwing(-en)`,
    warningThisPatientAlreadyHasALinkWithAnotherRosaRecod: (who: string | number) => `Opgelet: patiënt '${who}' heeft reeds een link met een andere ROSA patiënt`,
    warrantyEndDate: `Garantie tot datum`,
    wasOverDue: `Was te laat`,
    website: `Website`,
    welcomeBack: `Welkom terug`,
    whatAdded: (what: string | number) => `${what} toegevoegd`,
    whatForUnArticleFromAPreviousPurchase: (what: string | number) => `${what} voor een artikel uit een vorige aankoop`,
    whatForUnArticleFromAPreviousSale: (what: string | number) => `${what} voor een artikel uit een vorige verkoop`,
    whatSoftwareNotInstalled: (what: string | number) => `${what} software niet geïnstalleerd`,
    who: `Wie`,
    WIACompatibleScanners: `WIA compatibele scanners`,
    willBeDefaultOffice: `Dit zal de standaard praktijk worden`,
    willBeRepeatedQuestion: `Wordt herhaald?`,
    willNotBePurchased: `Wordt niet aangekocht`,
    with: `Mét`,
    withOrWithoutTerm: `Met/zonder termijn`,
    withOrWithoutWhat: (what: string | number) => `Met/zonder ${what.toString().toLowerCase()}`,
    without: `Zonder`,
    withoutPrescriptionForTrialCaption: `Patiënt heeft nog géén voorschrift voor testen ontvangen`,
    withoutSeparateEarmould: `Zonder apart oorstukje`,
    withoutSeparateEarmouldCaption: `Zonder apart oorstukje (bvb. OpenFit, OpenEar, ...)`,
    withoutSeparateReceiver: `Zonder aparte receiver`,
    withoutSeparateReceiverCaption: `Zonder aparte receiver (bvb. Power BTE)`,
    withOutstandingBalance: `Met openstaand saldo`,
    withOutstandingBalanceNotOnSettlementProposals: `Met openstaand saldo niet verwerkt op een afrekeningsvoorstel`,
    withoutTerm: `Zonder termijn`,
    withoutThirdPayerArrangementCaption: `Patiënt betaalt het volledige bedrag.`,
    withoutWhat: (what: string | number) => `Zonder ${what.toString().toLowerCase()}`,
    withPrescriptionForTrialCaption: `Bijlage 17 bij de hand, vul datum voorschrift testen in en selecteer NKO arts`,
    withRespectToShort: `Tav`,
    withSeparateEarmould: `Met apart oorstukje`,
    withSeparateEarmouldCaption: `Mét apart oorstukje (bvb. Power BTE)`,
    withSeparateReceiver: `Met aparte receiver`,
    withSeparateReceiverCaption: `Mét aparte receiver, receiver in het oor (bvb. RITE, RIC)`,
    withSerialNumber: `Mét serienummer`,
    withThirdPayerArrangementCaption: `Patiënt betaalt enkel toeslag.`,
    withWhat: (what: string | number) => `Mét ${what.toString().toLowerCase()}`,
    wizardAndHelp: `Wizard & hulp`,
    work: `Werk`,
    workflow: `Workflow`,
    workPhone: `Tel. werk`,
    workWeek: `Werkweek`,
    worse: `Slechter`,
    writeAnAddressHere: `Type een adres hier`,
    wrongDataQuestion: `Verkeerde gegevens?`,
    wrongRIZIVNumberFormat: `Verkeerd formaat: geef een RIZIV nummer in het formaat 'x-xxxx-xx-xxx'`,
    yearComesAfterPrefixAndBeforeNumber: `Het jaartal komt na de 'prefix' en voor het 'volgnummer'`,
    yes: `Ja`,
    yesCommaActivate: `Ja, activeer!`,
    yesCommaContinue: `Ja, ga verder`,
    yesCommaCreateNewPatient: `Ja, creëer nieuwe patiënt`,
    yesCommaDeactivate: `Ja, deactiveer!`,
    yesCommaDelete: `Ja, verwijder`,
    yesCommaSave: `Ja, sla op`,
    yesFillInNow: `Ja, vul nu in`,
    yesForAll: `Ja, voor alles`,
    yesItFits: `Ja, het past`,
    yesWhatExclamation: (what: string | number) => `Ja, ${what.toString().toLowerCase()}!`,
    zip: `Postcode`,
}
