import React from 'react';
import { ViewProvider as PaymentsViewProvider } from '../detail/payments/context/ViewProvider';
import { ViewProvider as LinesViewProvider } from '../detail/sales/context/ViewProvider';
import { ViewProvider as SettlementProposalsViewProvider } from '../detail/settlementProposals/context/ViewProvider';
import { NavigationProvider } from './NavigationProvider';
import { QueryProvider } from './QueryProvider';
import { ViewProvider } from './ViewProvider';

export const CustomerAccountsProvider = ({ children }: any) => {
	return (
		<QueryProvider>
			<ViewProvider>
				<LinesViewProvider>
					<SettlementProposalsViewProvider>
						<PaymentsViewProvider>
							<NavigationProvider>{children}</NavigationProvider>
						</PaymentsViewProvider>
					</SettlementProposalsViewProvider>
				</LinesViewProvider>
			</ViewProvider>
		</QueryProvider>
	);
};
