import { DialogProps, DialogTitle, Fab } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormMaskedTextField } from 'framework/forms/FormMaskedTextField';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { entPhysiciansCommand_new, IRegisterEntPhysicianRequest, IWebPhysician } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { FormGenderSelectField } from 'shared/forms/FormGenderSelectField';
import { ConventionedRizivNumberTextFieldWithDocumentation } from 'shared/rizivNumber/ConventionedRizivNumberTextFieldWithDocumentation';
import { RizivNumberMask } from 'shared/RizivNumberMask';
import { SearchPhysicianDialog } from 'shared/SearchPhysicianDialog';

const createSchema = (strings: IStrings) => {
	return yup.object<IRegisterEntPhysicianRequest>({
		salutation: yup.string(),
		firstName: yup.string().required(strings.formRequired(strings.firstName)),
		lastName: yup.string().required(strings.formRequired(strings.lastName)),
		rizivCode: yup.string().required(strings.formRequired(strings.RIZIVNumber)),
		gender: yup.string().required(strings.formRequired(strings.gender)),
		isImportedFromWeb: yup.bool().defined(),
	});
};

const emptyValues: IRegisterEntPhysicianRequest = {
	firstName: '',
	lastName: '',
	salutation: 'Dr.', // TODO translations??
	rizivCode: '',
	gender: undefined as any,
	isImportedFromWeb: false,
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

const toModel = (web: IWebPhysician): IRegisterEntPhysicianRequest => {
	return {
		firstName: web.firstName,
		lastName: web.lastName,
		gender: web.gender,
		rizivCode: web.rizivNumberWithQualificationFormatted.trim(),
		isImportedFromWeb: true,
		salutation: 'Dr.',
	};
};

export const RegisterEntPhysicianForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(entPhysiciansCommand_new);

	const handleSubmit = async (values: IRegisterEntPhysicianRequest, helpers: FormikHelpers<IRegisterEntPhysicianRequest>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.entPhysicianCreated);
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<IRegisterEntPhysicianRequest>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					cancel={cancel}
					isSubmitting={isSubmitting}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
}

const InnerDialog = ({ cancel, isSubmitting, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IRegisterEntPhysicianRequest>();
	const strings = useLocalization();
	const [isInSearchMode, setIsInSearchMode] = useState<boolean>(false);

	return (
		<>
			{isInSearchMode && (
				<SearchPhysicianDialog
					searchForWhatTitle={strings.entPhysicians}
					qualicationCodes={['041', '410', '414']}
					confirm={values => {
						props.setValues({
							...props.values,
							...toModel(values),
						});
						setIsInSearchMode(false);
					}}
					cancel={() => setIsInSearchMode(false)}
					open
				/>
			)}
			<OverflowDialog
				{...rest}
				open={rest.open && isInSearchMode === false}>
				<DialogTitle>
					<div className='df-row-ac jc-sb'>
						<div style={{ marginRight: '36px' }}>{strings.newEntPhysician}</div>
						<Fab
							color='primary'
							variant='extended'
							onClick={() => setIsInSearchMode(true)}>
							<SearchIcon style={{ marginRight: '4px' }} />
							<div>{strings.searchOnWeb}</div>
						</Fab>
					</div>
				</DialogTitle>
				<OverflowDialogContent>
					<FormTextField<IRegisterEntPhysicianRequest>
						name='salutation'
						label={strings.salutation}
					/>
					<FormTextField<IRegisterEntPhysicianRequest>
						name='firstName'
						label={strings.firstName}
						required
					/>
					<FormTextField<IRegisterEntPhysicianRequest>
						name='lastName'
						label={strings.lastName}
						required
					/>
					<FormGenderSelectField<IRegisterEntPhysicianRequest>
						name='gender'
						label={strings.gender}
						required
					/>
					<FormMaskedTextField<IRegisterEntPhysicianRequest>
						name='rizivCode'
						label={strings.RIZIVNumber}
						mask={RizivNumberMask}
						required
					/>
					<ConventionedRizivNumberTextFieldWithDocumentation rizivNumber={props.values.rizivCode ?? ''} />
				</OverflowDialogContent>
				<CancelSubmitFormDialogActions
					cancel={cancel}
					submitText={strings.create}
					isSubmitting={isSubmitting}
				/>
			</OverflowDialog>
		</>
	);
};
