import { Collapse, Divider, TableRow, useTheme } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { calcPatientDetailRoute } from 'app/main/patients/calcPatientDetailRoute';
import { ExpandLessOrMoreIcon } from 'framework/components/ExpandLessOrMoreIcon';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { TableCellX } from 'framework/table/TableCellX';
import { TableDetailsRowX } from 'framework/table/TableDetailsRowX';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ITender } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { calcTenderDetailRoute } from '../../routes';
import { TenderStatusChipX } from '../TenderStatusChipX';
import { TenderListItemStatusIcon } from './TenderListItemStatusIcon';
import { mapTenderToColorX } from './utils';

const MinorFontSize = 13;

interface IProps {
	item: ITender;
	isExpanded: boolean;
	toggle: VoidFunction;
}

export const TenderTableRow = ({ item, isExpanded, toggle }: IProps) => {
	const strings = useLocalization();
	const color = useMemo(() => mapTenderToColorX(item), [item]);
	const tColor = useThemeColor(color);
	const theme = useTheme();
	const { push } = useHistory();
	const [isHovered, enter, leave] = useStateBoolean(false);

	const onViewDetails = () => push(calcTenderDetailRoute(item.id));

	return (
		<React.Fragment>
			<TableRow
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<TableCellX>
					<div className='df-row gap-4'>
						<TenderListItemStatusIcon
							item={item}
							style={{ fontSize: 22, color: tColor }}
						/>
						<div className='df-col gap-2'>
							<TextLinkButton
								underline
								style={{ fontSize: 14 }}
								onClick={onViewDetails}>
								{item.reference}
							</TextLinkButton>
							<div className='df-row-ac gap-4'>
								<TenderStatusChipX item={item} />
							</div>
						</div>
					</div>
				</TableCellX>
				<TableCellX>
					<div className='df-col gap-2'>
						<span>{formatDate(item.created)}</span>
						<span style={{ fontSize: MinorFontSize, color: theme.palette.text.secondary }}>{`${strings.validTo}: ${formatDate(item.validUntil)}`}</span>
					</div>
				</TableCellX>
				<TableCellX>
					{item.isForPatient && item.forPatientId && item.forPatient && (
						<TextLinkButton
							style={{ fontSize: MinorFontSize }}
							onClick={() => push(calcPatientDetailRoute(item.forPatientId!))}>
							{item.forPatient.identifier}
						</TextLinkButton>
					)}
				</TableCellX>
				<TableCellX align='right'>
					<span>{formatCurrency(item.priceInVat)}</span>
				</TableCellX>
				<TableCellX align='right'>
					<div className='df-row-ac jc-e'>
						<ColorOnHoverIconButton
							edge='end'
							size='small'
							color='primary'
							onClick={toggle}>
							<ExpandLessOrMoreIcon isExpanded={isExpanded} />
						</ColorOnHoverIconButton>
						<ColorOnHoverIconButton
							size='small'
							edge='end'
							color='primary'
							onClick={onViewDetails}>
							<VisibilityOutlinedIcon />
						</ColorOnHoverIconButton>
					</div>
				</TableCellX>
			</TableRow>
			<TableDetailsRowX
				colSpan={5}
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<Collapse in={isExpanded}>
					<div
						className='df-col'
						style={{ paddingBottom: 12 }}>
						<Divider
							variant='inset'
							style={{ marginTop: 8, marginBottom: 4 }}
						/>
						{item.lines.map(line => (
							<div
								className='df-row-ab'
								key={line.id}
								style={{ fontSize: MinorFontSize, gap: 4 }}>
								<span>{`${line.quantity.toString()} x`}</span>
								{line.hasProductCatalogItem === true && (
									<TextLinkButton
										underline
										style={{ fontSize: MinorFontSize }}
										onClick={() => push(calcProductCatalogDetailRoute(line.productCatalogItemId!))}>
										{line.description}
									</TextLinkButton>
								)}
								{line.hasProductCatalogItem === false && <span style={{ fontSize: MinorFontSize }}>{line.description}</span>}
							</div>
						))}
					</div>
				</Collapse>
			</TableDetailsRowX>
		</React.Fragment>
	);
};
