import { IStrings } from 'localization/IStrings';
import { GeneralPractitionerExportProp } from 'gen/ApiClient';

export const createGeneralPractitionersExportRecord = (strings: IStrings): Record<GeneralPractitionerExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		FirstName: strings.firstName,
		LastName: strings.lastName,
		Gender: strings.gender,
		OfficesCsv: strings.offices,
		Salutation: strings.salutation,
		RizivCode: strings.RIZIVNumber,
	};
};
