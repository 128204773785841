import { Card, CardMedia, CardProps, Divider, useTheme } from '@material-ui/core';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { combineClassNames } from 'framework/utils/combineClassNames';
import { createType } from 'framework/utils/createType';
import {
	IPayment,
	IPaymentsForSupplierAccountFilterParams,
	IPaymentsForSupplierAccountQueryParams,
	ISupplier,
	PaymentExportProp,
	PaymentExportProps,
	paymentsQuery_exportForSupplierAccount,
	paymentsQuery_queryForSupplierAccount,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RecordContext } from 'shared/records/RecordContext';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsHeader } from 'shared/table/createDetailsHeader';
import { PaymentDialog } from '../../../payments/PaymentDialog';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { SelectableColumn } from './SelectableColumn';

const DefaultParams: IPaymentsForSupplierAccountQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	amountPaidLessThan: undefined,
	amountPaidMoreThan: undefined,
	createdAfter: undefined,
	createdBefore: undefined,
	paymentDateAfter: undefined,
	paymentDateBefore: undefined,
	paymentMethodIds: [],
	isMultipleLines: undefined,
	isSingleLine: undefined,
	expenseOrIncomeTypes: [],
	hasComment: undefined,
};

const QuickFilters = createType('all', 'grouped', 'single');
export type QuickFilter = typeof QuickFilters[number];

interface IProps extends IReloadTriggerProps, CardProps {
	supplier: ISupplier;
	reload: VoidFunction;
}

// TODO watch out for the reload!
export const PaymentsCard = ({ supplier, reloadTrigger, reload, ...rest }: IProps) => {
	const strings = useLocalization();
	const [params, setParams, queryResult] = useApiEffectWithParams(
		pars => paymentsQuery_queryForSupplierAccount(supplier.id, pars),
		DefaultParams,
		reloadTrigger
	);
	const theme = useTheme();
	const { columns, setColumns, columnsRecord, headers, quickFilter, setQuickFilter, quickFilterRecord } = useContext(ViewContext);
	const { paymentExportRecord } = useContext(RecordContext);
	const { open, confirm } = useDialogsContext(reload);

	useEffect(() => {
		setParams(params =>
			onResetToPage1({
				...params,
				isMultipleLines: quickFilter === 'grouped' ? true : undefined,
				isSingleLine: quickFilter === 'single' ? true : undefined,
			})
		);
		// eslint-disable-next-line
	}, [quickFilter]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onViewDetail = (id: string) => {
		open(
			<PaymentDialog
				open
				close={confirm}
				id={id}
			/>
		);
	};

	// const onRegisterPayment = () => {
	//     open(<RegisterPaymentForCustomerAccountForm
	//         open
	//         customerAccountId={customerAccount.id}
	//         customerAccountIdentifier={customerAccount.identifier}
	//         confirm={confirm}
	//         cancel={cancel} />)
	// }

	return (
		<Card
			{...rest}
			className={combineClassNames('fg1', rest.className)}>
			<div
				className='df-row-ac jc-sb'
				style={{ paddingRight: theme.spacing(1.5) }}>
				<CardTitleTypography
					withPadding
					title={strings.payments}
				/>
				{/* <Button
                    variant="text"
                    color="primary"
                    size="small"
                    startIcon={<CashPlusIcon />}
                    style={{ marginRight: theme.spacing(1.5) }}
                    onClick={onRegisterPayment}
                    disabled={customerAccount.hasOutstandingBalance === false}>
                    Registreer betaling
                    </Button> */}
			</div>
			<Divider />
			{queryResult.unfilteredCount > 0 && (
				<>
					<div
						className='df-row-ac jc-sb'
						style={{ padding: theme.spacing(1) }}>
						<ButtonGroupWithSelection<QuickFilter>
							options={QuickFilters}
							selected={quickFilter}
							setSelected={setQuickFilter}
							renderOption={t => quickFilterRecord[t]}
						/>
						<div>
							<ExportButtonMenuAndDialog<IPaymentsForSupplierAccountFilterParams, PaymentExportProp>
								params={params}
								filteredCount={queryResult.filteredCount}
								unfilteredCount={queryResult.unfilteredCount}
								allProps={PaymentExportProps}
								downloadF={(...pars) => paymentsQuery_exportForSupplierAccount(supplier.id, ...pars)}
								fileName='Betalingen_voor_leveranciersrekening'
								record={paymentExportRecord}
								view='payments'
							/>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
								view='supplierAccountsPayments'
							/>
						</div>
					</div>
					<Divider />
					<SearchbarWithParams
						params={params}
						setParams={setParams}
						placeholder={strings.searchVerb}
						style={{ width: '400px' }}
						variant='embedded'
					/>
					<Divider />
					<div className='df-row jc-sb'>
						<Filter
							params={params}
							onFilter={params => setParams(onResetToPage1(params))}
							paddingLeft={theme.spacing(1.5)}
						/>
					</div>
					<Divider />
					<CardMedia>
						<PageableTableWithColumnSelection<IPayment, SelectableColumn>
							queryResult={queryResult}
							headers={headers}
							sortParams={params.sortParams}
							pageParams={params.pageParams}
							setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
							getKey={inst => inst.id}
							selectedColumns={columns}
							appendColumn={createDetailsHeader<IPayment>(t => onViewDetail(t.id))}
						/>
					</CardMedia>
				</>
			)}
			{queryResult.unfilteredCount === 0 && (
				<NoRecordsFound style={{ padding: theme.spacing(1.5) }}>{strings.noWhatRegistered(strings.payments)}</NoRecordsFound>
			)}
		</Card>
	);
};
