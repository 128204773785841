import { Chip } from '@material-ui/core';
import { FadedRectangularChip } from 'framework/components/chips/FadedRectangularChip';
import { IDashboardKpi } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { QuickStatCard } from 'shared/quickStatCards/QuickStatCard';
import { KpiSelector } from '../KpiSelector';

interface IProps {
	data: IDashboardKpi;
	description: string;
	title: string;
	selector?: KpiSelector;
}

export const KpiCard = ({ data, description, title, selector = 'last7' }: IProps) => {
	const strings = useLocalization();
	const kpi = useMemo(() => (selector === 'last30' ? data.last30Days : data.last7Days), [data, selector]);

	return (
		<QuickStatCard
			title={title}
			description={description}
			value={kpi.current}
			secondaryTitle={
				<Chip
					label={selector === 'last30' ? `30 ${strings.days}` : `7 ${strings.days}`}
					color='primary'
					size='small'
					style={{ fontSize: '10px', height: '20px' }}
					variant='outlined'
				/>
			}
			secondaryValue={
				kpi.isPreviousZero === false ? (
					<FadedRectangularChip
						tooltip={strings.inComparisonWithPreviousPeriod}
						label={`${kpi.relativeChange >= 0 ? '+' : ''}${(kpi.relativeChange * 100).toFixed(0)}%`}
						color={kpi.relativeChange >= 0 ? 'success' : 'error'}
						style={{ marginLeft: 8 }}
					/>
				) : undefined
			}
			style={{ minWidth: '200px' }}
			withoutMargin
		/>
	);
};
