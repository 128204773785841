import { PaginationX } from 'framework/components/pagination/PaginationX';
import { IPageParams } from 'gen/ApiClient';
import React from 'react';

interface IQR {
	pageIndex: number;
	pageSize: number;
	totalPages: number;
	filteredCount: number;
}

interface IProps {
	queryResult: IQR;
	setPageParams: (params: IPageParams) => void;
	pageSizes?: number[];
	style?: React.CSSProperties;
	flexEnd?: boolean;
}

export const QueryResultPaginationX = ({ queryResult, setPageParams, pageSizes, style, flexEnd }: IProps) => {
	return (
		<PaginationX
			pageIndex={queryResult.pageIndex}
			pageSize={queryResult.pageSize}
			countPages={queryResult.totalPages}
			countRecords={queryResult.filteredCount}
			pageSizes={pageSizes ?? [5, 10, 20, 50, 100]}
			setPageIndex={index => setPageParams({ index: index, size: queryResult.pageSize })}
			setPageSize={size => setPageParams({ index: 1, size: size })}
			style={{
				justifyContent: flexEnd === true ? 'flex-end' : 'unset',
				...style,
			}}
		/>
	);
};
