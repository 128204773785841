import { Table, TableContainer, useTheme } from '@material-ui/core';
import React from 'react';
import { createEmptyHeader } from './createEmptyHeader';
import { DragAndDropTableValues } from './DragAndDropTableValues';
import { IHeader } from './IHeader';
import { PaperOutlined } from './PaperOutlined';
import { TableHeader } from './TableHeader';

interface IProps<T> {
	ordered: T[];
	setOrdered: (val: T[]) => void;
	headers: IHeader<T>[];
	getKey: (inst: T) => string;
	withPaperContainer?: boolean;
	padding?: number;
	keepDndLeftPadding?: boolean;
	expandableContent?: (value: T) => JSX.Element;
}

export const DragAndDropTable = <T extends unknown>({
	ordered,
	setOrdered,
	headers,
	withPaperContainer,
	padding,
	getKey,
	keepDndLeftPadding = false,
	expandableContent,
}: IProps<T>) => {
	const theme = useTheme();

	return (
		<TableContainer component={withPaperContainer ? PaperOutlined : ('div' as any)}>
			<Table stickyHeader>
				<TableHeader
					headers={[...headers, createEmptyHeader<T>('dnd', t => '', 'right')]}
					padding={padding ?? theme.spacing(1.5)}
				/>
				<DragAndDropTableValues<T>
					ordered={ordered}
					setOrdered={setOrdered}
					headers={headers}
					getKey={getKey}
					padding={padding ?? theme.spacing(1.5)}
					keepDndLeftPadding={keepDndLeftPadding}
					expandableContent={expandableContent}
				/>
			</Table>
		</TableContainer>
	);
};
