import { PreloadCacheProvider as FrameworkCacheProvider } from 'framework/context/PreloadCacheProvider';
import { calendarResourcesQuery_concurrencyToken, calendarResourcesQuery_data, ICalendarResourcesDto } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { PreloadContext } from 'shared/context/PreloadContext';
import { CalendarResourcesPreloadCacheContext } from './CalendarResourcesPreloadCacheContext';

export const CalendarResourcesPreloadCacheProvider = ({ children }: any) => {
	const { calendarResources } = useContext(PreloadContext);

	return (
		<FrameworkCacheProvider<ICalendarResourcesDto>
			concurrencyF={calendarResourcesQuery_concurrencyToken}
			loadF={calendarResourcesQuery_data}
			context={CalendarResourcesPreloadCacheContext}
			initialData={calendarResources}>
			{children}
		</FrameworkCacheProvider>
	);
};
