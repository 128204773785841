import { Card, CardMedia, CardProps, Divider, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim';
import { calcInventoryItemDetailRoute } from 'app/main/inventoryManagement/routes';
import { DefaultInventoryItemsQueryParams } from 'app/main/inventoryManagement/inventoryItems/context/DefaultInventoryItemsQueryParams';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { createHeader } from 'framework/table/createHeader';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { combineClassNames } from 'framework/utils/combineClassNames';
import {
	IInventoryItem,
	IInventoryItemFilterParams,
	InventoryItemExportProp,
	InventoryItemExportProps,
	inventoryItemsQuery_exportByProductCatalog,
	inventoryItemsQuery_queryForProductCatalog,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { RecordContext } from 'shared/records/RecordContext';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createDetailsNavigateHeader } from 'shared/table/createDetailsNavigateHeader';
import { InventoryItemChips } from '../../../inventoryItems/components/InventoryItemChips';
import { InventoryItemsFilterDataContext } from '../../../inventoryItems/context/InventoryItemsFilterDataContext';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { SelectableColumn } from './SelectableColumn';
import { InventoryItemForm } from 'app/main/inventoryManagement/inventoryItems/forms/InventoryItemForm';

interface IProps extends IReloadTriggerProps, CardProps {
	productCatalogId: string;
	reload: VoidFunction;
}

export const InventoryItemsCard = ({ productCatalogId, reloadTrigger, reload, ...rest }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const [params, setParams, queryResult] = useApiEffectWithParams(
		pars => inventoryItemsQuery_queryForProductCatalog(productCatalogId, pars),
		DefaultInventoryItemsQueryParams,
		reloadTrigger
	);
	const { columns, setColumns, columnsRecord, headers } = useContext(ViewContext);
	const { inventoryItemExportRecord } = useContext(RecordContext);
	const [searchData] = useCacheContext(InventoryItemsFilterDataContext);
	const hasVeppClaim = useLoggedInUserIsSuperUserOrHasVeppFunctionalClaim();
	const exportProps = useMemo(
		() => (hasVeppClaim ? InventoryItemExportProps : InventoryItemExportProps.filter(t => t !== 'PurchasePrice' && t !== 'PurchaseListPrice')),
		[hasVeppClaim]
	);
	const exclusions: SelectableColumn[] = useMemo(() => (hasVeppClaim ? [] : ['purchasePrice']), [hasVeppClaim]);
	const { open, confirm, cancel } = useDialogsContext(reload);

	if (queryResult === undefined || searchData === undefined) {
		return <div></div>;
	}

	const onDefine = () => {
		open(
			<InventoryItemForm
				productCatalogItemId={productCatalogId}
				confirm={confirm}
				cancel={cancel}
				goToDetails
			/>
		);
	};

	return (
		<Card
			{...rest}
			className={combineClassNames('fg1', rest.className)}>
			<div
				className='df-row-ac jc-sb'
				style={{ paddingRight: theme.spacing(1.5) }}>
				<CardTitleTypography
					withPadding
					title={strings.inventoryItems}
				/>
				<SmallPrimaryTextButton
					variant='contained'
					startIcon={<AddIcon />}
					onClick={onDefine}>
					{strings.addDefineInventoryItem}
				</SmallPrimaryTextButton>
			</div>
			<Divider />
			{queryResult.unfilteredCount > 0 && (
				<>
					<div className='df-row-ac jc-sb'>
						<SearchbarWithParams
							params={params}
							setParams={setParams}
							placeholder={strings.searchVerb}
							style={{ width: '400px' }}
							variant='embedded'
						/>
						<div>
							<ExportButtonMenuAndDialog<IInventoryItemFilterParams, InventoryItemExportProp>
								params={params}
								filteredCount={queryResult.filteredCount}
								unfilteredCount={queryResult.unfilteredCount}
								allProps={exportProps}
								downloadF={(...pars) => inventoryItemsQuery_exportByProductCatalog(productCatalogId, ...pars)}
								fileName={strings.serialNumbers}
								record={inventoryItemExportRecord}
								view='inventoryItems'
							/>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
								exclusions={exclusions}
								view='productCatalogInventoryItems'
							/>
						</div>
					</div>
					<Divider />
					<div className='df-row jc-sb'>
						<Filter
							data={searchData}
							params={params}
							onFilter={params => setParams(onResetToPage1(params))}
							paddingLeft={theme.spacing(1.5)}
						/>
					</div>
					<Divider />
					<CardMedia>
						<PageableTableWithColumnSelection<IInventoryItem, SelectableColumn>
							queryResult={queryResult}
							headers={headers}
							sortParams={params.sortParams}
							pageParams={params.pageParams}
							setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
							getKey={inst => inst.id}
							selectedColumns={columns}
							prependColumn={createHeader<IInventoryItem>(strings.status, t => (
								<InventoryItemChips item={t} />
							))}
							appendColumn={createDetailsNavigateHeader<IInventoryItem>(calcInventoryItemDetailRoute)}
						/>
					</CardMedia>
				</>
			)}
			{queryResult.unfilteredCount === 0 && (
				<NoRecordsFound style={{ padding: theme.spacing(1.5) }}>{strings.noWhatRegistered(strings.inventoryItems)}</NoRecordsFound>
			)}
		</Card>
	);
};
