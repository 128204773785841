import { StockMovementExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createStockMovementsExportRecord = (strings: IStrings): Record<StockMovementExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		CountItems: `# ${strings.items}`,
		FromLocationIdentifier: strings.fromLocation,
		Reference: strings.reference,
		ToLocationIdentifier: strings.toLocation,
	};
};
