import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import BrightnessAutoOutlinedIcon from '@material-ui/icons/BrightnessAutoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { patientSettingsCommand_pathReferenceSettings, patientsQuery_settings } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReferenceSettingsForm } from 'shared/ReferenceSettingsForm';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { calculateReference } from 'shared/utils/calculateReference';
import { PatientsBreadcrumb } from '../PatientsBreadcrumb';
import { PatchPatientSettingsAutoFillReferenceRequestForm } from './PatchPatientSettingsAutoFillReferenceRequestForm';
import { PatientDetailOverviewLayoutViewPreferencesComponent } from './PatientDetailOverviewLayoutViewPreferencesComponent';
import { PatientRequiredPropertiesComponent } from './PatientRequiredPropertiesComponent';

type AccordionType = 'overview-layout' | 'fields-to-fill-in' | 'reference-settings';

export const PatientSettingsAndViewPreferencesPage = () => {
	const strings = useLocalization();
	const [trigger, reload] = useTrigger();
	const [settings, reloadSettings] = useApiEffect(patientsQuery_settings);
	const [expandedAccordion, setExpandedAccordion] = useAccordionState<AccordionType>();
	const { goBack } = useHistory();
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadSettings();
	}, [trigger]);

	if (settings === undefined) {
		return <div></div>;
	}

	const onReferenceSettings = () => {
		open(
			<ReferenceSettingsForm
				cancel={cancel}
				confirm={confirm}
				open
				value={settings.referenceSettings}
				title={strings.changeWhat(strings.numberingWhat(strings.patients))}
				submitFunction={values => patientSettingsCommand_pathReferenceSettings(values)}
			/>
		);
	};

	const onPatchAutoFillReference = () => {
		open(
			<PatchPatientSettingsAutoFillReferenceRequestForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='Patients'
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<PatientsBreadcrumb />
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={strings.settingsAndPreferences} />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<Button
								size='small'
								variant='outlined'
								startIcon={<CloseIcon />}
								onClick={() => goBack()}>
								{strings.closeThisWindow}
							</Button>
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
				</div>
			}>
			<div
				className='df-col'
				style={{
					padding: 16,
					maxWidth: 1280,
				}}>
				<Accordion
					expanded={expandedAccordion === 'overview-layout'}
					onChange={() => setExpandedAccordion('overview-layout')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{strings.layoutOverviewScreenOfAPatient}</span>
					</AccordionSummary>
					<AccordionDetails>
						<PatientDetailOverviewLayoutViewPreferencesComponent />
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expandedAccordion === 'fields-to-fill-in'}
					onChange={() => setExpandedAccordion('fields-to-fill-in')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{strings.requiredFieldsToFillInForAPatient}</span>
					</AccordionSummary>
					<AccordionDetails>
						<PatientRequiredPropertiesComponent />
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expandedAccordion === 'reference-settings'}
					onChange={() => setExpandedAccordion('reference-settings')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{strings.numbering}</span>
					</AccordionSummary>
					<AccordionDetails>
						<div className='df-col gap-4'>
							<LabelledPropertyX
								icon={<BrightnessAutoOutlinedIcon />}
								edit={onPatchAutoFillReference}>
								{settings.autoFillReference ? strings.autoPatientNumbering : strings.manualPatientNumbering}
							</LabelledPropertyX>
							<LabelledPropertyX
								icon={<FormatListNumberedIcon />}
								edit={settings.autoFillReference === false ? undefined : onReferenceSettings}>
								{settings.autoFillReference === false ? strings.notApplicableAbbreviation : calculateReference(settings.referenceSettings)}
							</LabelledPropertyX>
						</div>
					</AccordionDetails>
				</Accordion>
			</div>
		</RestrictedPageX>
	);
};
