import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import ShopTwoOutlinedIcon from '@material-ui/icons/ShopTwoOutlined';
import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { useAccordionState } from 'framework/utils/useAccordionState';
import {
	DeliveryTimeUnits,
	orderSettingsCommand_patchDefaultDeliveryTime,
	orderSettingsCommand_patchReferenceSettings,
	ordersQuery_settings,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReferenceSettingsForm } from 'shared/ReferenceSettingsForm';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PageReloadButton } from 'shared/layout/PageReloadButton';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { RecordContext } from 'shared/records/RecordContext';
import { TimeModelForm } from 'shared/timeUnit/TimeModelForm';
import { formatTimeModel } from 'shared/timeUnit/formatTimeModel';
import { calculateReference } from 'shared/utils/calculateReference';
import { OrdersRoute } from '../ProcessesRoutes';

type AccordionType = 'default-delivery-time' | 'reference-settings';

export const OrdersSettingsPage = () => {
	const strings = useLocalization();
	const [trigger, reload] = useTrigger();
	const [settings, reloadSettings] = useApiEffect(ordersQuery_settings);
	const [expandedAccordion, setExpandedAccordion] = useAccordionState<AccordionType>();
	const { goBack } = useHistory();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { timeUnitRecord } = React.useContext(RecordContext);

	useLazyEffect(() => {
		reloadSettings();
	}, [trigger]);

	if (settings === undefined) {
		return <div></div>;
	}

	const onReferenceSettings = () => {
		open(
			<ReferenceSettingsForm
				cancel={cancel}
				confirm={confirm}
				open
				value={settings.referenceSettings}
				title={strings.changeWhat(strings.numberingWhat(strings.orders))}
				submitFunction={model => orderSettingsCommand_patchReferenceSettings(model)}
			/>
		);
	};

	const onDefaultDeliveryTime = () => {
		open(
			<TimeModelForm
				open
				title={strings.changeWhat(strings.defaultDeliveryTime)}
				model={settings.defaultDeliveryTime}
				defaultUnit='Weeks'
				label={strings.defaultDeliveryTime}
				options={DeliveryTimeUnits}
				confirm={confirm}
				submitFunction={model => orderSettingsCommand_patchDefaultDeliveryTime({ defaultDeliveryTime: model })}
				cancel={cancel}
			/>
		);
	};

	return (
		<RestrictedPageX
			pageClaim='Orders'
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<PageBreadcrumb
							text={strings.orders}
							route={OrdersRoute}
							icon={<ShopTwoOutlinedIcon />}
						/>
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={strings.settings} />
						<div className='fg1'></div>
						<Button
							size='small'
							variant='outlined'
							startIcon={<CloseIcon />}
							onClick={() => goBack()}>
							{strings.closeThisWindow}
						</Button>
						<PageReloadButton reload={reload} />
					</div>
				</div>
			}>
			<div
				className='df-col'
				style={{
					padding: 16,
					maxWidth: 1280,
				}}>
				<Accordion
					expanded={expandedAccordion === 'default-delivery-time'}
					onChange={() => setExpandedAccordion('default-delivery-time')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{strings.defaultDeliveryTime}</span>
					</AccordionSummary>
					<AccordionDetails>
						<LabelledPropertyX
							icon={<LocalShippingOutlinedIcon />}
							edit={onDefaultDeliveryTime}>
							{formatTimeModel(settings.defaultDeliveryTime, timeUnitRecord)}
						</LabelledPropertyX>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expandedAccordion === 'reference-settings'}
					onChange={() => setExpandedAccordion('reference-settings')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{strings.numbering}</span>
					</AccordionSummary>
					<AccordionDetails>
						<LabelledPropertyX
							icon={<FormatListNumberedIcon />}
							edit={onReferenceSettings}>
							{calculateReference(settings.referenceSettings)}
						</LabelledPropertyX>
					</AccordionDetails>
				</Accordion>
			</div>
		</RestrictedPageX>
	);
};
