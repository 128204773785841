import { Dialog, DialogActions, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormActions } from 'framework/forms/PageableFormActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { commissionPayoutsCommand_new, commissionPayoutsQuery_nextReference, ICommissionPayoutModel } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { CommissionLineTable } from './CommissionLineTable';
import { ICommissionWithSelection } from './ICommissionWithSelection';
import { TotalsTable } from './TotalsTable';

const createSchema = (strings: IStrings) => {
	return yup.object<ICommissionPayoutModel>({
		userId: yup.string().required(),
		reference: yup.string().required(),
		commissionIds: yup.mixed(),
		isAutoSelectReference: yup.bool().defined(),
		payoutDate: yup.date().required(),
	});
};

const stepsRecord: Record<number, (keyof ICommissionPayoutModel)[]> = {
	0: ['reference', 'isAutoSelectReference', 'payoutDate'],
	1: ['payoutDate'],
	2: ['commissionIds'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	userId: string;
}

export const CommissionPayoutModelForm = ({ confirm, cancel, userId, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(commissionPayoutsCommand_new);
	const [lines, setLines] = useState<ICommissionWithSelection[]>([]);
	const [step, setStep] = useState<number>(0);
	const now = useNow();

	const handleSubmit = async (values: ICommissionPayoutModel, helpers: FormikHelpers<ICommissionPayoutModel>) => {
		const r = await submit({ ...values, commissionIds: lines.filter(t => t.isSelected).map(t => t.id) });
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			confirm(r.result.objectId);
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<ICommissionPayoutModel>
			validateOnMount
			initialValues={{
				userId: userId,
				commissionIds: [],
				isAutoSelectReference: true,
				payoutDate: now,
				reference: '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					cancel={cancel}
					isSubmitting={isSubmitting}
					lines={lines}
					setLines={setLines}
					step={step}
					setStep={setStep}
					userId={userId}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	lines: ICommissionWithSelection[];
	setLines: React.Dispatch<React.SetStateAction<ICommissionWithSelection[]>>;
	step: number;
	setStep: (step: number) => void;
	userId: string;
}

const InnerDialog = ({ lines, setLines, cancel, isSubmitting, step, setStep, userId, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const props = useFormikContext<ICommissionPayoutModel>();
	useNextReferenceInterceptor(commissionPayoutsQuery_nextReference, props);

	useEffect(() => {
		setLines([]);
		// eslint-disable-next-line
	}, [props.values.userId]);

	return (
		<Dialog
			fullWidth={step === 2}
			scroll='paper'
			maxWidth='lg'
			{...rest}>
			<DialogTitleWithFormStepper
				step={step}
				title={strings.registerWhat(strings.payout)}
				labels={[strings.reference, strings.date, strings.lines]}
			/>
			<DialogContent
				className='df-col'
				dividers
				style={{ padding: step === 2 ? 0 : '8px 24px' }}>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<ICommissionPayoutModel>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<ICommissionPayoutModel>
							name='reference'
							label={strings.reference}
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormDatePicker<ICommissionPayoutModel>
							name='payoutDate'
							label={strings.paidOutDate}
						/>
					</>
				)}
				{step === 2 && props.values.userId && (
					<CommissionLineTable
						lines={lines}
						setLines={setLines}
						userId={userId}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<div className='df-col'>
					{step === 2 && (
						<TotalsTable
							lines={lines}
							style={{ marginBottom: 8 }}
						/>
					)}
					<PageableFormActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.register}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
					/>
				</div>
			</DialogActions>
		</Dialog>
	);
};
