import React, { useState } from 'react';
import { INavigationContext } from './INavigationContext';

interface IProps<TNavLink extends string> {
	children: any;
	context: React.Context<INavigationContext<TNavLink>>;
	fallback: TNavLink;
}

const NavigationProvider = <TNavLink extends string>({ children, context, fallback }: IProps<TNavLink>) => {
	const [link, setLink] = useState<TNavLink>(fallback);

	return (
		<context.Provider
			value={{
				link: link,
				setLink: setLink,
			}}>
			{children}
		</context.Provider>
	);
};

export default NavigationProvider;
