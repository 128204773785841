import { useTheme } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { CardX } from 'framework/components/cards/CardX';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	onClick: VoidFunction;
	style?: React.CSSProperties;
}

export const NewOrderCardX = ({ style, onClick }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const [hovered, enter, leave] = useStateBoolean(false);

	return (
		<CardX
			className='df-row gap-8 w100'
			raised={hovered}
			style={{
				cursor: 'pointer',
				borderColor: theme.palette.primary.main,
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.getContrastText(theme.palette.primary.main),
				...style,
			}}
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={onClick}>
			<div className='df-col'>
				<AddCircleOutlineIcon style={{ padding: 0 }} />
			</div>
			<div className='df-col'>
				<span style={{ fontSize: MajorFontSize }}>{strings.registerANewOrder}</span>
				<span style={{ fontSize: MinorFontSize }}>{strings.doNotAddLinesToAnExistingOrder}</span>
			</div>
		</CardX>
	);
};
