import { IconButton, ListItem } from '@material-ui/core';
import ColorLensOutlinedIcon from '@material-ui/icons/ColorLensOutlined';
import VerticalSplitOutlinedIcon from '@material-ui/icons/VerticalSplitOutlined';
import { ArrowDropDownOrUpIcon } from 'framework/components/ArrowDropDownOrUpIcon';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	title: string;
	isExpanded: boolean;
	onClick: VoidFunction;
	isColorSchemeSelected: boolean;
	selectColorScheme: VoidFunction;
	isGroupSelected: boolean;
	selectGroup: VoidFunction;
	hideGroup?: boolean;
}

export const CalendarFilterListItemHeaderComponent = ({
	title,
	isExpanded,
	onClick,
	selectColorScheme,
	isColorSchemeSelected,
	isGroupSelected,
	selectGroup,
	hideGroup,
}: IProps) => {
	const strings = useLocalization();

	return (
		<ListItem
			button
			onClick={onClick}
			dense
			disableGutters
			style={{ paddingLeft: 16, paddingRight: 16 }}>
			<div className='df-row-ac fg1'>
				<div style={{ fontWeight: 500 }}>{title}</div>
				<div className='fg1'></div>
				{hideGroup !== true && (
					<TooltipWithSpan title={strings.groupOnThis}>
						<IconButton
							size='small'
							color={isGroupSelected ? 'secondary' : 'primary'}
							onClick={e => {
								e.stopPropagation();
								selectGroup();
							}}>
							<VerticalSplitOutlinedIcon fontSize='small' />
						</IconButton>
					</TooltipWithSpan>
				)}
				<TooltipWithSpan title={strings.useThisColorScheme}>
					<IconButton
						size='small'
						color={isColorSchemeSelected ? 'secondary' : 'primary'}
						onClick={e => {
							e.stopPropagation();
							selectColorScheme();
						}}>
						<ColorLensOutlinedIcon fontSize='small' />
					</IconButton>
				</TooltipWithSpan>
				<ArrowDropDownOrUpIcon isUp={isExpanded} />
			</div>
		</ListItem>
	);
};
