import React, { useState } from 'react';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useTrigger } from 'framework/hooks/useTrigger';
import { Try } from 'framework/Try';
import { DocumentExportFormat, documentsQuery_content, documentsQuery_historyContent, documentsQuery_single, IDocumentContent } from 'gen/ApiClient';
import { DocumentEditorDialog } from 'documents/DocumentEditorDialog';
import { DocumentEditorDialogContext } from './DocumentEditorDialogContext';

export const DocumentEditorDialogProvider = ({ children }: any) => {
	const [trigger, hitTrigger] = useTrigger();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [canEdit, setCanEdit] = useState<boolean>(false);
	const loadDocument = useApiCall(documentsQuery_single);
	const loadContent = useApiCall(documentsQuery_content);
	const loadHistoryContent = useApiCall(documentsQuery_historyContent);
	const [content, setContent] = useState<IDocumentContent>();
	const [hasChanges, setHasChanges] = useState<boolean>(false);

	const onOpen = async (documentId: string, historyId?: string) => {
		const r = await loadDocument(documentId);
		if (r.isSuccess) {
			setCanEdit(historyId === undefined && r.result.canEdit);
			if (historyId) {
				handleRContent(await loadHistoryContent(documentId, historyId, 'Native' as DocumentExportFormat));
			} else {
				handleRContent(await loadContent(documentId, 'Native' as DocumentExportFormat));
			}
		}
	};

	const handleRContent = (rContent: Try<IDocumentContent>) => {
		if (rContent.isSuccess) {
			setContent(rContent.result);
			setIsOpen(true);
			setHasChanges(false);
		}
	};

	const confirm = () => {
		hitTrigger();
		setIsOpen(false);
	};

	return (
		<DocumentEditorDialogContext.Provider
			value={{
				isOpen: isOpen,
				open: onOpen,
				trigger: trigger,
			}}>
			<>
				<DocumentEditorDialog
					open={isOpen}
					document={content}
					close={confirm}
					isReadOnly={canEdit === false}
					hasChanges={hasChanges}
					setHasChanges={setHasChanges}
				/>
				{children}
			</>
		</DocumentEditorDialogContext.Provider>
	);
};
