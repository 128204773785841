import { Card, Divider, useTheme } from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { SearchbarWithParams } from 'framework/components/search/SearchbarWithParams';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { useApiCall } from 'framework/hooks/useApiCall';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IInventoryItem, inventoryItemsQuery_single, IQueryResult } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SelectColumnsButtonAndDialog } from 'shared/selectColumns/SelectColumnsButtonAndDialog';
import { createAddHeader } from 'shared/table/createAddHeader';
import { createRemoveHeader } from 'shared/table/createRemoveHeader';
import { createSelectHeader } from 'shared/table/createSelectHeader';
import { InventoryItemForm } from '../forms/InventoryItemForm';
import { ViewContext } from './context/ViewContext';
import { Filter } from './Filter';
import { ICommonParams } from './ICommonParams';
import { SelectableColumn } from './SelectableColumn';

interface IProps<TParams extends ICommonParams> {
	onAdd: (item: IInventoryItem) => void;
	onCredit?: (item: IInventoryItem) => void;
	style?: React.CSSProperties;
	queryResult: IQueryResult<IInventoryItem> | undefined;
	params: TParams;
	setParams: React.Dispatch<React.SetStateAction<TParams>>;
	selectedIds: string[];
	canSelectF: (item: IInventoryItem) => boolean;
	canCreditF?: (item: IInventoryItem) => boolean;
	mode?: 'add' | 'select';
	defaultSelectableColumns?: SelectableColumn[];
}

export const SearchInventoryItem = <TParams extends ICommonParams>({
	onAdd,
	queryResult,
	params,
	setParams,
	selectedIds,
	canSelectF,
	style,
	mode,
	defaultSelectableColumns,
	onCredit,
	canCreditF,
}: IProps<TParams>) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { columns, columnsRecord, setColumns, headers } = useContext(ViewContext);
	const selectedMode = useMemo(() => (mode === 'select' ? 'select' : 'add'), [mode]);
	const addOrSelectHeader = useMemo(
		() =>
			selectedMode === 'add'
				? createAddHeader<IInventoryItem>(
						t => onAdd(t),
						t => canSelectF(t) === false || selectedIds.indexOf(t.id) > -1
				  )
				: createSelectHeader<IInventoryItem>(
						t => onAdd(t),
						t => canSelectF(t) === false || selectedIds.indexOf(t.id) > -1
				  ),
		[selectedMode, onAdd, canSelectF, selectedIds]
	);
	const { open, confirm, cancel } = useDialogsContext();
	const load = useApiCall(inventoryItemsQuery_single);

	useEffect(() => {
		if (defaultSelectableColumns !== undefined) {
			setColumns(defaultSelectableColumns);
		} else {
			setColumns(['serialNumber', 'manufacturerAndModel', 'status']);
		}
		// eslint-disable-next-line
	}, [defaultSelectableColumns]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onFilter = (params: TParams) => {
		setParams(onResetToPage1(params));
	};

	const onNew = () => {
		open(
			<InventoryItemForm
				confirm={onConfirm}
				cancel={cancel}
			/>
		);
	};

	const onConfirm = async (id: string) => {
		const r = await load(id);
		if (r.isSuccess) {
			onAdd(r.result);
		}
		confirm();
	};

	return (
		<div
			className='df-col h100'
			style={style}>
			<SearchbarWithParams
				params={params}
				setParams={setParams}
				placeholder={strings.searchVerb}
				variant='paper'
				style={{ margin: '1px', marginBottom: theme.spacing(2) }}
			/>
			<Card
				variant='outlined'
				className='df-col'>
				<div className='df-row-ac jc-sb'>
					<Filter
						params={params}
						setParams={setParams}
					/>
					<div className='df-row-ac'>
						<FormControlLabelSwitch
							isChecked={params.isShowAll}
							toggle={() => onFilter({ ...params, isShowAll: !params.isShowAll })}
							label={strings.viewAll}
						/>
						<TooltipWithSpan title={strings.itemNotFoundQuestionClickHereToDefineNewSerialNumber}>
							<SmallPrimaryTextButton
								startIcon={<PlaylistAddIcon />}
								color={queryResult.filteredCount === 0 ? 'secondary' : 'primary'}
								onClick={onNew}>
								{strings.new}
							</SmallPrimaryTextButton>
						</TooltipWithSpan>
						<SelectColumnsButtonAndDialog
							selectedColumns={columns}
							setSelectedColumns={setColumns}
							record={columnsRecord}
							view='searchInventoryItems'
						/>
					</div>
				</div>
				<Divider />
				<PageableTableWithColumnSelection<IInventoryItem, SelectableColumn>
					padding={theme.spacing(1)}
					headers={headers}
					selectedColumns={columns}
					getKey={t => t.id}
					pageParams={params.pageParams}
					sortParams={params.sortParams}
					queryResult={queryResult}
					setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
					appendColumn={
						onCredit
							? [
									createAddHeader<IInventoryItem>(
										t => onAdd(t),
										t => canSelectF(t) === false || selectedIds.indexOf(t.id) > -1,
										undefined,
										{ paddingRight: 0 }
									),
									createRemoveHeader<IInventoryItem>(
										t => onCredit(t),
										t => (canCreditF === undefined ? false : canCreditF(t) === false) || selectedIds.indexOf(t.id) > -1,
										undefined,
										{ paddingLeft: 0 }
									),
							  ]
							: addOrSelectHeader
					}
				/>
			</Card>
		</div>
	);
};
