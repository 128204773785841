import React from 'react';
import { IInventoryItem, IInventoryItemsQueryParamsForLoan, IProductCatalogItem, IQueryResult, IStockItemsQueryParamsForLoan } from 'gen/ApiClient';

export interface IContext {
	pcParams: IStockItemsQueryParamsForLoan;
	setPcParams: React.Dispatch<React.SetStateAction<IStockItemsQueryParamsForLoan>>;
	pcQueryResult: IQueryResult<IProductCatalogItem> | undefined;
	iiParams: IInventoryItemsQueryParamsForLoan;
	setIIParams: React.Dispatch<React.SetStateAction<IInventoryItemsQueryParamsForLoan>>;
	iiQueryResult: IQueryResult<IInventoryItem> | undefined;
}

export const SearchContext = React.createContext<IContext>(undefined as any);
