import { useFormikContext } from 'formik';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { FormAutocompleteFieldWithAdd } from 'framework/forms/FormAutocompleteFieldWithAdd';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { entPhysiciansCommand_addOffice, IOfficeSummary } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { OfficeForm } from 'shared/forms/OfficeForm';
import { EntPhysiciansCacheContext } from '../EntPhysiciansCacheContext';

interface IProps<TModel> extends IFormProps<TModel> {
	entPhysicianId: string;
}

export const FormSelectOrCreateEntPhysicianOfficeField = <TModel extends unknown>({ entPhysicianId, name, label, ...rest }: IProps<TModel>) => {
	const props = useFormikContext<TModel>();
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext();
	const [data, load] = usePreloadCacheContext(EntPhysiciansCacheContext);

	if (data === undefined) {
		return <div></div>;
	}

	const onConfirm = async (id: string) => {
		await load();
		setFormValue<TModel>(name, id, props);
		confirm();
	};

	const onNew = () => {
		open(
			<OfficeForm
				open
				confirm={onConfirm}
				cancel={cancel}
				submitFunction={model => entPhysiciansCommand_addOffice({ entPhysicianId: entPhysicianId, office: model })}
			/>
		);
	};

	return (
		<FormAutocompleteFieldWithAdd<TModel, IOfficeSummary>
			name={name}
			label={label}
			options={data.find(t => t.id === entPhysicianId)?.offices ?? []}
			onCreateNew={onNew}
			renderCreate={str => strings.createStrCommaWhat(str, strings.newOffice)}
			{...rest}
		/>
	);
};
