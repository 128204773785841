import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { SelectField } from 'framework/components/select/SelectField';
import { ISettlementProposal } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	proposals: ISettlementProposal[];
	confirm: (purchaseId: string) => void;
	cancel: VoidFunction;
}

export const SelectSettlementProposalForm = ({ confirm, cancel, proposals, ...rest }: IProps) => {
	const [settlementProposal, setSettlementProposal] = useState<ISettlementProposal>();
	const strings = useLocalization();

	return (
		<Dialog
			{...rest}
			scroll='paper'>
			<DialogTitle>{strings.selectWhat(strings.settlementProposal)}</DialogTitle>
			<DialogContent dividers>
				<SelectField<ISettlementProposal>
					getKey={t => t.id}
					renderValue={t => t.reference ?? ''}
					label={strings.settlementProposal}
					value={settlementProposal}
					onChange={setSettlementProposal}
					options={proposals}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button disabled>{strings.previous}</Button>
				<Button
					onClick={settlementProposal ? () => confirm(settlementProposal.id) : undefined}
					disabled={settlementProposal === undefined}
					color='primary'>
					{strings.select}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
