import { DragAndDropContainer } from 'framework/components/dnd/DragAndDropContainer';
import { LeftRightDividedContentScrollYRight } from 'framework/components/LeftRightDividedContentScrollYRight';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { addOrUpdateQuantity } from 'framework/utils/array/addOrUpdateQuantity';
import { updateArray } from 'framework/utils/array/updateArray';
import {
	IInventoryItem,
	IInventoryItemsQueryParamsForReturn,
	inventoryItemsQuery_queryForReturn,
	IProductCatalogItem,
	IProductCatalogQueryParamsForReturn,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { DefaultInventoryItemsQueryParamsForReturn } from 'shared/searchAndSelect/DefaultInventoryItemsQueryParamsForReturn';
import { SearchStockProductCatalogInventoryItemTabs } from 'shared/searchAndSelect/SearchStockProductCatalogInventoryItemTabs';
import { SearchContext } from './context/SearchContext';
import { ILine } from './ILine';
import { ReturnLineComponent } from './ReturnLineComponent';
import { inventoryItemToLine } from './utils/inventoryItemToLine';
import { productCatalogItemToLine } from './utils/productCatalogItemToLine';

interface IProps {
	lines: ILine[];
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>;
}

export const SearchAndSelectProductsForReturn = ({ lines, setLines }: IProps) => {
	const notify = useSnackbarNotify();
	const strings = useLocalization();
	const { ...allSearchParams } = useContext(SearchContext);

	const onAddPc = (item: IProductCatalogItem) => {
		setLines(addOrUpdateQuantity(item, lines, () => productCatalogItemToLine(item)));
	};

	const onAddII = (item: IInventoryItem, pc: IProductCatalogItem) => {
		if (lines.findIndex(t => t.inventoryItemId === item.id) > -1) {
			notify(strings.itemAlreadyInList, 'warning');
		} else {
			setLines([...lines, inventoryItemToLine(item, pc)]);
		}
	};

	return (
		<LeftRightDividedContentScrollYRight
			leftContent={
				<SearchStockProductCatalogInventoryItemTabs<ILine, IInventoryItemsQueryParamsForReturn, IProductCatalogQueryParamsForReturn>
					lines={lines}
					{...allSearchParams}
					queryInventoryItemF={inventoryItemsQuery_queryForReturn}
					defaultIIParams={DefaultInventoryItemsQueryParamsForReturn}
					onAddII={onAddII}
					onAddPc={onAddPc}
					canSelectInventoryItemF={i => i.canSelectForReturn}
				/>
			}
			rightContent={
				<DragAndDropContainer<ILine>
					lines={lines}
					setLines={setLines}
					getKey={t => t.id}
					render={(line, index, props) => (
						<ReturnLineComponent
							line={line}
							index={index + 1}
							dragHandleProps={props}
							setLine={val => setLines(updateArray(line, lines, val))}
							onDelete={() => setLines(lines.filter(t => t !== line))}
						/>
					)}
				/>
			}
		/>
	);
};
