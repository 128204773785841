import { CardProps, Divider } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { IRosaUsersLinkDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { RosaUserToHamsUserLinkListItem } from './RosaUserToHamsUserLinkListItem';

interface IProps extends CardProps {
	data: IRosaUsersLinkDto;
	reload: VoidFunction;
}

export const RosaUserToHamsUserLinkListCardX = ({ data, reload, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<CardX
			className='w100'
			{...rest}>
			<div
				className='df-row'
				style={{ gap: 8 }}>
				<Typography16pxBold>{strings.results}</Typography16pxBold>
				<div className='fg1'></div>
			</div>
			<Divider style={{ marginTop: 8 }} />
			{data.users.map(t => (
				<RosaUserToHamsUserLinkListItem
					key={t.rosaId}
					item={t}
					reload={reload}
				/>
			))}
		</CardX>
	);
};
