import { Button } from '@material-ui/core';
import PlaylistAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { ProcessCfDialog } from 'app/main/customFeatures/ProcessCfDialog';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { FunctionVariantIcon } from 'framework/components/icons/FunctionVariantIcon';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { IInventoryItem, IInventoryItemsQueryParams, IQueryResult } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { InventoryItemsBreadcrumbs } from './InventoryItemsBreadcrumbs';
import { InventoryItemsScriptsDialog } from './scripts/InventoryItemsScriptsDialog';

interface IProps {
	reload: VoidFunction;
	queryResult: IQueryResult<IInventoryItem>;
	params: IInventoryItemsQueryParams;
}

export const InventoryItemsListPageHeader = ({ queryResult, params, reload }: IProps) => {
	const userIsSuperUser = useLoggedInUserIsSuperUser();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const onScript = () => {
		open(
			<InventoryItemsScriptsDialog
				open
				confirm={confirm}
				cancel={cancel}
				queryResult={queryResult}
				params={params}
			/>
		);
	};

	const onProcessConsignationFile = () => {
		open(<ProcessCfDialog close={cancel} />);
	};

	return (
		<div
			className='df-row-ac'
			style={{ paddingBottom: 16 }}>
			<InventoryItemsBreadcrumbs />
			<div className='fg1'></div>
			<PageXHeaderActions>
				<Button
					size='small'
					variant='outlined'
					startIcon={<PlaylistAddOutlinedIcon />}
					onClick={onProcessConsignationFile}>
					{`Behandel consignatie file (.xlsx)`}
				</Button>
				{userIsSuperUser && (
					<TooltipWithSpan title={`Execute custom scripts`}>
						<ColorOnHoverIconButton
							onClick={onScript}
							size='small'
							edge='end'>
							<FunctionVariantIcon />
						</ColorOnHoverIconButton>
					</TooltipWithSpan>
				)}
				<PageReloadButtonX reload={reload} />
			</PageXHeaderActions>
		</div>
	);
};
