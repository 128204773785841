import React, { useContext, useEffect, useState } from 'react';
import { IObjectWithIdAndIdentifier } from 'gen/ApiClient';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { HealthInsuranceFundsCacheContext } from './HealthInsuranceFundsCacheContext';
import { LocalizedHealthInsuranceFundsCacheContext } from './LocalizedHealthInsuranceFundsCacheContext';

export const LocalizedHealthInsuranceFundsCacheProvider = ({ children }: any) => {
	const language = useCurrentLanguage();
	const [range, setRange] = useState<IObjectWithIdAndIdentifier<string>[] | undefined>(undefined);
	const { data, init } = useContext(HealthInsuranceFundsCacheContext);

	useEffect(() => {
		const range = language === 'nl' ? data?.nl : language === 'fr' ? data?.fr : data?.en;
		setRange(range);
	}, [data, language]);

	return (
		<LocalizedHealthInsuranceFundsCacheContext.Provider
			value={{
				data: range,
				init: init,
			}}>
			{children}
		</LocalizedHealthInsuranceFundsCacheContext.Provider>
	);
};
