import React from 'react';
import { InventoryItemsProviders } from './inventoryItems/context/InventoryItemsProviders';
import { NoahDevicesWrapperProviders } from './noahDevices/NoahDevicesWrapperProviders';
import { ProductCatalogProviders } from './productCatalog/context/ProductCatalogProviders';
import { SearchSerialNumbersProviders } from './searchSerialNumbers/context/SearchSerialNumbersProviders';
import { StockItemsProviders } from './stock/context/StockItemsProviders';
import { StockEntriesProviders } from './stockEntries/context/StockEntriesProviders';
import { StockTransactionsProviders } from './stockTransactions/StockTransactionsProviders';
import { ReturnsProviders } from './stockTransactions/returns/ReturnsProviders';
import { StockMovementProviders } from './stockTransactions/stockMovements/StockMovementProviders';

export const InventoryManagementProviders = ({ children }: any) => {
	return (
		<ProductCatalogProviders>
			<InventoryItemsProviders>
				<StockItemsProviders>
					<NoahDevicesWrapperProviders>
						<StockTransactionsProviders>
							<ReturnsProviders>
								<StockMovementProviders>
									<SearchSerialNumbersProviders>
										<StockEntriesProviders>{children}</StockEntriesProviders>
									</SearchSerialNumbersProviders>
								</StockMovementProviders>
							</ReturnsProviders>
						</StockTransactionsProviders>
					</NoahDevicesWrapperProviders>
				</StockItemsProviders>
			</InventoryItemsProviders>
		</ProductCatalogProviders>
	);
};
