import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { SuperUserRole } from 'gen/SuperUserRole';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { DashboardRoute } from 'routes';
import { IPageProps } from './IPageProps';
import { Page } from './Page';

export const SuperUserRestrictedPage = ({ ...props }: IPageProps) => {
	const user = useLoggedInUser();
	const { push } = useHistory();
	const hasAccess = useMemo(() => user?.role === SuperUserRole, [user]);

	useEffect(() => {
		if (hasAccess === false) {
			push(DashboardRoute);
		}
		// eslint-disable-next-line
	}, [hasAccess]);

	return hasAccess ? <Page {...props} /> : <></>;
};
