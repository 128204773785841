import { Chip, useTheme } from '@material-ui/core';
import React from 'react';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import {
	IRequestForInsuranceAllowance,
	rfiasCommand_clearMainMedicalTreatment,
	rfiasCommand_clearPseudoMedicalTreatment,
	rfiasCommand_clearSecondaryMedicalTreatment,
} from 'gen/ApiClient';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { MedicalTreatmentNomenclatureDialog } from 'shared/medicalTreatmentNomenclatures/MedicalTreatmentNomenclatureDialog';

type Type = 'main' | 'secondary' | 'pseudo';

interface IProps {
	code: string;
	type?: Type;
	rfia?: IRequestForInsuranceAllowance;
	reload?: VoidFunction;
}

export const MedicalTreatmentNomenclatureChip = ({ code, type, rfia, reload }: IProps) => {
	const [clearMain] = useFormSubmit(rfiasCommand_clearMainMedicalTreatment);
	const [clearSecondary] = useFormSubmit(rfiasCommand_clearSecondaryMedicalTreatment);
	const [clearPseudo] = useFormSubmit(rfiasCommand_clearPseudoMedicalTreatment);
	const { open, confirm, cancel } = useDialogsContext(reload !== undefined ? reload : () => {});

	const theme = useTheme();

	const onClear = async () => {
		if (type && rfia) {
			const r = type === 'main' ? await clearMain(rfia.id) : type === 'secondary' ? await clearSecondary(rfia.id, code) : await clearPseudo(rfia.id);
			if (r.isSuccess) {
				confirm();
			}
		}
	};

	const onViewDetail = () => {
		open(
			<MedicalTreatmentNomenclatureDialog
				code={code}
				cancel={cancel}
				open
				clear={rfia && rfia.isHandedOver === false ? onClear : undefined}
			/>
		);
	};

	return (
		<Chip
			size='small'
			label={code}
			variant={type && type === 'pseudo' ? 'outlined' : 'default'}
			onClick={onViewDetail}
			style={{ marginRight: theme.spacing(0.5) }}
		/>
	);
};
