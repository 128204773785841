import SyncIcon from '@material-ui/icons/Sync';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { documentsCommand_rebuildFromTemplate, IDocument } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DocumentActions } from './DocumentActions';

interface IProps {
	document: IDocument;
	reload: VoidFunction;
	prependActions?: JSX.Element;
	appendActions?: JSX.Element;
	close: VoidFunction;
	isDocumentInSync: boolean;
}

export const DocumentWithRebuildActions = ({ document, reload, close, prependActions, appendActions, isDocumentInSync }: IProps) => {
	const strings = useLocalization();
	const { open, cancel, confirm } = useDialogsContext(reload);

	if (document === undefined) {
		return <div></div>;
	}

	const onRebuildFromTemplate = async () => {
		if (document) {
			open(
				<AlertDialog
					open
					content={strings.regenerateDocumentFromTemplateQuestion}
					acceptText={strings.yes}
					rejectText={strings.noCommaGoBack}
					reject={cancel}
					acceptF={() => documentsCommand_rebuildFromTemplate(document.id)}
					accept={() => confirm()}
				/>
			);
		}
	};

	return (
		<DocumentActions
			document={document}
			reload={reload}
			close={close}
			prependActions={prependActions}
			appendActions={
				<>
					{appendActions && appendActions}
					<SmallPrimaryTextButton
						color={isDocumentInSync ? 'primary' : 'secondary'}
						startIcon={<SyncIcon />}
						onClick={onRebuildFromTemplate}>
						{strings.regenerateDocumentFromTemplate}
					</SmallPrimaryTextButton>
				</>
			}
		/>
	);
};
