import { ITimeModel, TimeUnit } from 'gen/ApiClient';
import { ITimeUnitTranslation } from './ITimeUnitTranslation';

export const formatTimeModel = (timeModel: ITimeModel, record: Record<TimeUnit, ITimeUnitTranslation>): string | undefined => {
	if (timeModel === undefined || timeModel === null || timeModel.unit === undefined || timeModel.unit === null) {
		return undefined;
	}
	const recordIndex = record[getTimeUnit(timeModel.unit as any)];
	return `${timeModel.value} ${
		timeModel.value <= 0 ? recordIndex.unknown : timeModel.value === 1 ? recordIndex.singular.toLowerCase() : recordIndex.plural.toLowerCase()
	}`;
};

const getTimeUnit = (key: TimeUnit): TimeUnit => {
	return key as any;
};
