import { Chip, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useContext, useMemo } from 'react';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { PatientSearchMode } from 'gen/ApiClient';
import { RecordContext } from '../records/RecordContext';

interface IProps {
	mode: PatientSearchMode;
	setMode: (val: PatientSearchMode) => void;
	style?: React.CSSProperties;
}

export const SelectPatientSearchMode = ({ mode, setMode, style }: IProps) => {
	const [anchor, open, close] = useAnchorElement();
	const { patientSearchModeRecord } = useContext(RecordContext);
	const options = useMemo(() => Object.keys(patientSearchModeRecord) as PatientSearchMode[], [patientSearchModeRecord]);

	return (
		<>
			<Chip
				color='primary'
				size='small'
				variant='outlined'
				label={
					<div className='df-row-ac jc-sb'>
						<div>{patientSearchModeRecord[mode]}</div>
						<ArrowDropDownIcon
							fontSize='small'
							style={{ marginRight: -8 }}
						/>
					</div>
				}
				clickable
				onClick={open}
				style={style}
			/>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={close}
				keepMounted>
				{options.map(t => (
					<MenuItem
						key={t}
						onClick={() => {
							setMode(t);
							close();
						}}
						value={t}>
						{patientSearchModeRecord[t]}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
