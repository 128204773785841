import { CardProps, Divider } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { IOption } from 'framework/IOption';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ILoansQueryParams, LoanProcessStatuses } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { LoansListPrefilter } from './LoansListPrefilter';

interface IProps extends CardProps {
	params: ILoansQueryParams;
	setParams: React.Dispatch<React.SetStateAction<ILoansQueryParams>>;
}

export const LoansListFilterCard = ({ params, setParams, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const { loanStatusRecord } = React.useContext(RecordContext);
	const isFilteredX = useMemo(() => isFiltered(params, []), [params]);
	const onFilter = (pars: ILoansQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params, [])));

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: '500' }}>{strings.filter}</span>
			</div>
			<LoansListPrefilter
				selectedPrefilter={params.prefilter}
				setSelectedPrefilter={key => onFilter({ ...params, prefilter: key })}
			/>
			<Divider />
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<ListFilterComponent<string>
					options={LoanProcessStatuses.map<IOption<string>>(t => ({ id: t, identifier: loanStatusRecord[t] }))}
					selected={params.statuses}
					setSelected={vals => onFilter({ ...params, statuses: vals })}
					icon={<HelpOutlineIcon />}
					label={strings.status}
				/>
				<DateFilterItem
					label={strings.start}
					after={params.createdAfter}
					before={params.createdBefore}
					setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
				/>
				<DateFilterItem
					label={strings.stop}
					after={params.stopDateAfter}
					before={params.stopDateBefore}
					setSelected={(after, before) => onFilter({ ...params, stopDateAfter: after, stopDateBefore: before })}
				/>
			</div>
		</CardX>
	);
};
