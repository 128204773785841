import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { MoneyRangeFilterComponent } from 'framework/filter/MoneyRangeFilterComponent';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { IInventoryItemFilterParams, IInventoryItemSearchData } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { VatPercentagesListFilterItem } from 'shared/VatPercentagesListFilterItem';
import { SuppliersCacheContext } from '../../contacts/suppliers/SuppliersCacheContext';

interface IProps<TParams extends IInventoryItemFilterParams> extends DialogProps {
	params: TParams;
	onFilter: (pars: TParams) => void;
	cancel: VoidFunction;
	data: IInventoryItemSearchData;
	forProductCatalog?: boolean;
}

export const InventoryItemsFilterMoreDialog = <TParams extends IInventoryItemFilterParams>({
	id,
	cancel,
	params: initialParams,
	data,
	onFilter,
	forProductCatalog = false,
	...rest
}: IProps<TParams>) => {
	const strings = useLocalization();
	const [params, setParams] = useState<TParams>(initialParams);
	const [suppliers] = usePreloadCacheContext(SuppliersCacheContext);

	return (
		<Dialog
			{...rest}
			scroll='paper'
			fullWidth>
			<DialogTitle>{strings.advancedInventoryFilters}</DialogTitle>
			<DialogContent dividers>
				<DividerWithCaption caption={strings.properties} />
				<div className='df-col-as'>
					{forProductCatalog === false && (
						<>
							<BooleanRadioFilterComponent
								label={strings.somethingQuestionmark(strings.tailorMadeShort)}
								trueText={strings.isTailorMade}
								falseText={strings.isNotTailorMade}
								selected={params.isTailorMade}
								setSelected={value => onFilter({ ...params, isTailorMade: value })}
								icon={<HelpOutlineIcon />}
							/>

							{data.categories.length > 0 && (
								<StringListFilterComponent
									label={strings.category}
									selected={params.categories}
									options={data.categories}
									icon={<HelpOutlineIcon />}
									setSelected={value => setParams({ ...params, categories: value })}
								/>
							)}
							{data.subcategories.length > 0 && (
								<StringListFilterComponent
									label={strings.subcategory}
									selected={params.subcategories}
									options={data.subcategories}
									icon={<HelpOutlineIcon />}
									setSelected={value => setParams({ ...params, subcategories: value })}
								/>
							)}
							{data.manufacturers.length > 0 && (
								<StringListFilterComponent
									label={strings.manufacturers}
									selected={params.manufacturers}
									options={data.manufacturers}
									icon={<HelpOutlineIcon />}
									setSelected={value => setParams({ ...params, manufacturers: value })}
								/>
							)}
							<VatPercentagesListFilterItem
								selected={params.vatPercentages as any}
								setSelected={vals => onFilter({ ...params, vatPercentages: vals })}
							/>
							<MoneyRangeFilterComponent
								label={strings.retailPrice}
								selected={{ min: params.listPriceMoreThan, max: params.listPriceLessThan }}
								setSelected={val => onFilter({ ...params, listPriceMoreThan: val.min, listPriceLessThan: val.max })}
								icon={<HelpOutlineIcon />}
							/>
						</>
					)}
					<MoneyRangeFilterComponent
						label={strings.salesPrice}
						selected={{ min: params.salesPriceMoreThan, max: params.salesPriceLessThan }}
						setSelected={val => onFilter({ ...params, salesPriceMoreThan: val.min, salesPriceLessThan: val.max })}
						icon={<HelpOutlineIcon />}
					/>
					<ListFilterComponent<string>
						label={strings.suppliers}
						selected={params.supplierIds}
						options={suppliers}
						setSelected={value => onFilter({ ...params, supplierIds: value })}
						icon={<HelpOutlineIcon />}
					/>
					<BooleanRadioFilterComponent
						label={strings.hasBatteriesBebatApplicable}
						trueText={strings.applicable}
						falseText={strings.notApplicable}
						selected={params.hasBebat}
						setSelected={value => onFilter({ ...params, hasBebat: value })}
						icon={<HelpOutlineIcon />}
					/>
				</div>
				<DividerWithCaption caption={strings.purchaseStatus} />
				<div className='df-col-as'>
					<BooleanRadioFilterComponent
						trueText={strings.statusPurchased}
						falseText={strings.somethingOrSomething(strings.statusNotPurchased, strings.statusNotCredited)}
						selected={params.isPurchased}
						setSelected={val => setParams({ ...params, isPurchased: val })}
						label={strings.somethingQuestionmark(strings.statusPurchased)}
						icon={<HelpOutlineIcon />}
					/>
					<DateFilterItem
						label={strings.purchaseDate}
						before={params.purchasedBefore}
						after={params.purchasedAfter}
						setSelected={(after, before) => setParams({ ...params, purchasedAfter: after, purchasedBefore: before })}
					/>
					<BooleanRadioFilterComponent
						trueText={strings.statusCredited}
						falseText={strings.statusNotCredited}
						selected={params.isPurchaseCredited}
						setSelected={val => setParams({ ...params, isPurchaseCredited: val })}
						label={strings.somethingQuestionmark(strings.statusCredited)}
						icon={<HelpOutlineIcon />}
					/>
					<DateFilterItem
						label={`${strings.date} - ${strings.creditNotePurchase}`}
						before={params.purchaseCreditDateBefore}
						after={params.purchaseCreditDateAfter}
						setSelected={(after, before) => setParams({ ...params, purchaseCreditDateAfter: after, purchaseCreditDateBefore: before })}
					/>
				</div>
				<DividerWithCaption caption={strings.salesStatus} />
				<div className='df-col-as'>
					<BooleanRadioFilterComponent
						trueText={strings.statusSold}
						falseText={strings.somethingOrSomething(strings.statusNotSold, strings.statusNotCredited)}
						selected={params.isSold}
						setSelected={val => setParams({ ...params, isSold: val })}
						label={strings.somethingQuestionmark(strings.statusSold)}
						icon={<HelpOutlineIcon />}
					/>
					<DateFilterItem
						label={strings.salesDate}
						before={params.soldBefore}
						after={params.soldAfter}
						setSelected={(after, before) => setParams({ ...params, soldAfter: after, soldBefore: before })}
					/>
					<BooleanRadioFilterComponent
						trueText={strings.statusCredited}
						falseText={strings.statusNotCredited}
						selected={params.isSalesCredited}
						setSelected={val => setParams({ ...params, isSalesCredited: val })}
						label={strings.somethingQuestionmark(strings.statusCredited)}
						icon={<HelpOutlineIcon />}
					/>
					<DateFilterItem
						label={`${strings.date} - ${strings.creditNoteSale}`}
						before={params.salesCreditDateBefore}
						after={params.salesCreditDateAfter}
						setSelected={(after, before) => setParams({ ...params, salesCreditDateAfter: after, salesCreditDateBefore: before })}
					/>
				</div>
				<DividerWithCaption caption={strings.consignment} />
				<div className='df-col-as'>
					<BooleanRadioFilterComponent
						label={`${strings.onConsignment}?`}
						trueText={strings.yes}
						falseText={strings.no}
						selected={params.isOnConsignment}
						setSelected={val => setParams({ ...params, isOnConsignment: val })}
						icon={<HelpOutlineIcon />}
					/>
					<BooleanRadioFilterComponent
						label={`${strings.consignmentExpired}?`}
						trueText={strings.yes}
						falseText={strings.no}
						selected={params.isConsignmentExpired}
						setSelected={val => setParams({ ...params, isConsignmentExpired: val })}
						icon={<HelpOutlineIcon />}
					/>
					<BooleanRadioFilterComponent
						label={`${strings.consignmentExpiresWithinAWeek}?`}
						trueText={strings.yes}
						falseText={strings.no}
						selected={params.consignmentExpiresWithinAWeek}
						setSelected={val => setParams({ ...params, consignmentExpiresWithinAWeek: val })}
						icon={<HelpOutlineIcon />}
					/>
					<DateFilterItem
						label={strings.dateReturnBefore}
						before={params.consignmentReturnBeforeDateBefore}
						after={params.consignmentReturnBeforeDateAfter}
						setSelected={(after, before) => setParams({ ...params, consignmentReturnBeforeDateAfter: after, consignmentReturnBeforeDateBefore: before })}
					/>
				</div>
				<DividerWithCaption caption={strings.insurancesAndProlongedWarranties} />
				<div className='df-col-as'>
					<BooleanRadioFilterComponent
						label={`${strings.insurance} ${strings.statusSold}?`}
						trueText={strings.yes}
						falseText={strings.no}
						selected={params.hasSoldInsurance}
						setSelected={val => setParams({ ...params, hasSoldInsurance: val })}
						icon={<HelpOutlineIcon />}
					/>
					<BooleanRadioFilterComponent
						label={`${strings.prolongedWarranty} ${strings.statusSold}?`}
						trueText={strings.yes}
						falseText={strings.no}
						selected={params.hasSoldProlongedWarranty}
						setSelected={val => setParams({ ...params, hasSoldProlongedWarranty: val })}
						icon={<HelpOutlineIcon />}
					/>
					<BooleanRadioFilterComponent
						label={`${strings.insurance} ${strings.statusPurchased}?`}
						trueText={strings.yes}
						falseText={strings.no}
						selected={params.hasPurchasedInsurance}
						setSelected={val => setParams({ ...params, hasPurchasedInsurance: val })}
						icon={<HelpOutlineIcon />}
					/>
					<BooleanRadioFilterComponent
						label={`${strings.prolongedWarranty} ${strings.statusPurchased}?`}
						trueText={strings.yes}
						falseText={strings.no}
						selected={params.hasPurchasedProlongedWarranty}
						setSelected={val => setParams({ ...params, hasPurchasedProlongedWarranty: val })}
						icon={<HelpOutlineIcon />}
					/>
				</div>
				<DividerWithCaption caption={strings.other} />
				<div className='df-col-as'>
					<DateFilterItem
						label={strings.returnDate}
						before={params.returnedBefore}
						after={params.returnedAfter}
						setSelected={(after, before) => setParams({ ...params, returnedAfter: after, returnedBefore: before })}
					/>
				</div>
			</DialogContent>
			<CancelSubmitDialogActions
				submitText={strings.filter}
				cancel={cancel}
				isSubmitting={false}
				onSubmit={() => onFilter(params)}
			/>
		</Dialog>
	);
};
