import DeleteIcon from '@material-ui/icons/Delete';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { IndexAvatar } from './IndexAvatar';

interface IProps {
	index: number;
	isDiscount: boolean;
	dragHandleProps: DraggableProvidedDragHandleProps | undefined;
	onDelete: VoidFunction;
	style?: React.CSSProperties;
}

const LineIndexDragAndDelete = ({ index, dragHandleProps, onDelete, style, isDiscount }: IProps) => {
	return (
		<div
			className='df-col-ac'
			style={style}>
			<IndexAvatar
				index={index}
				color={isDiscount ? 'error' : 'normal'}
			/>
			<div
				className='df-row-ac'
				{...dragHandleProps}>
				<DragHandleIcon color='action' />
			</div>
			<ColorOnHoverIconButton
				color='secondary'
				size='small'
				onClick={onDelete}>
				<DeleteIcon />
			</ColorOnHoverIconButton>
		</div>
	);
};

export default LineIndexDragAndDelete;
