import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	Divider,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import React from 'react';
import { isLast } from 'framework/utils/array/isLast';
import { formatDate } from 'framework/utils/date/formatDate';
import { IRepair } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	repairs: IRepair[];
	confirm: (repairId: string) => void;
	cancel: VoidFunction;
}

export const SelectRepairForm = ({ repairs, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<Dialog
			{...rest}
			fullWidth
			maxWidth='sm'>
			<DialogTitle>{strings.selectWhat(strings.repair)}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'
				style={{ paddingTop: 0, paddingBottom: 0 }}>
				<List
					dense
					style={{ overflow: 'auto', marginLeft: '-24px', marginRight: '-24px' }}>
					{repairs.map(t => (
						<div
							className='df-col'
							key={t.id}>
							<ListItem
								button
								style={{ paddingLeft: '29px' }}>
								<ListItemText
									primary={`${t.reference}, ${formatDate(t.created)}`}
									secondary={`${strings.supplier}: ${t.supplierIdentifier}, ${strings.patient}: ${
										t.forPatientIdentifier ? t.forPatientIdentifier : strings.notApplicableAbbreviation
									}`}
									style={{ paddingRight: '40px' }}
								/>
								<ListItemSecondaryAction>
									<Button
										variant='text'
										size='small'
										color='primary'
										onClick={() => confirm(t.id)}>
										{strings.select}
									</Button>
								</ListItemSecondaryAction>
							</ListItem>
							{isLast(t, repairs) === false && <Divider />}
						</div>
					))}
				</List>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
