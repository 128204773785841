import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { ILoan, ILoansQueryParams, IQueryResult, loansQuery_concurrencyToken, loansQuery_query } from 'gen/ApiClient';
import React from 'react';
import { loansQueryParamsLsKey } from '../../../../localStorageKeys';
import { LoansQueryContext } from './LoansQueryContext';

const DefaultParams: ILoansQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	forPatientId: undefined,
	statuses: [],
	stopDateAfter: undefined,
	stopDateBefore: undefined,
	prefilter: 'All',
};

export const LoansQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<ILoan>, ILoansQueryParams>
			defaultParams={DefaultParams}
			queryF={loansQuery_query}
			concurrencyF={loansQuery_concurrencyToken}
			localStorageKey={loansQueryParamsLsKey}
			context={LoansQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
