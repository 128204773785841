import { Card, CardProps } from '@material-ui/core';
import React from 'react';

export interface IFixedWidthCardProps extends CardProps {
	width: number;
	removeBottomMargin?: boolean;
	removeRightMargin?: boolean;
}

export const FixedWidthCard = ({ width, style, removeBottomMargin = false, removeRightMargin = false, ...rest }: IFixedWidthCardProps) => {
	return (
		<Card
			style={{ width: `${width}px`, marginBottom: removeBottomMargin ? 0 : 16, marginRight: removeRightMargin ? 0 : 16, ...style }}
			{...rest}
		/>
	);
};
