import { Dialog, DialogContent, DialogProps, TextField } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { FormControlLabelCheckbox } from 'framework/components/FormControlLabelCheckbox';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffectWithDefer } from 'framework/hooks/useApiEffectWithDefer';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { Try } from 'framework/Try';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import {
	ICashSaleModel,
	ICreateResponse,
	IPatientSearch,
	patientsQuery_singleSearch,
	salesCommand_newCashSale,
	salesQuery_nextCashSaleReference,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { FormSelectPatientField } from 'shared/patientSearch/FormSelectPatientField';
import { PatientSearchProvider } from 'shared/patientSearch/PatientSearchProvider';
import * as yup from 'yup';
import { IInvoiceLine } from '../financial/sales/forms/invoice/IInvoiceLine';
import { invoiceLineToSalesLineModel } from '../financial/sales/forms/invoice/utils/invoiceLineToSalesLineModel';
import { FormSelectOrCreatePaymentMethodField } from '../settings/paymentMethods/forms/FormSelectOrCreatePaymentMethodField';

interface IModel {
	patientId: string | undefined;
	reference: string;
	isAutoSelectReference: boolean;
	methodId: string;
	value: number;
}

const createSchema = (strings: IStrings, withPayment: boolean) => {
	return yup
		.object<IModel>({
			isAutoSelectReference: yup.boolean().defined(),
			reference: yup.string(),
			patientId: yup.string(),
			methodId: withPayment ? yup.string().required() : yup.string(),
			value: withPayment ? yup.number().defined() : yup.number(),
		})
		.defined();
};

const emptyValues: IModel = {
	isAutoSelectReference: true,
	reference: '',
	patientId: undefined,
	methodId: '',
	value: 0,
};

const stepsRecordWithoutPayment: Record<number, (keyof IModel)[]> = {
	0: ['isAutoSelectReference', 'reference'],
	1: ['patientId'],
	2: [],
};

const stepsRecordWithPayment: Record<number, (keyof IModel)[]> = {
	0: ['isAutoSelectReference', 'reference'],
	1: ['patientId'],
	2: ['methodId', 'value'],
	3: [],
};

interface IProps extends DialogProps {
	lines: IInvoiceLine[];
	confirm: (id: string, isPrintTicket: boolean) => void;
	cancel: VoidFunction;
	onLineErrors: (r: Try<ICreateResponse<ICashSaleModel>>) => void;
	patientId?: string;
}

export const CheckoutForm = ({ lines, confirm, cancel, onLineErrors, patientId, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(salesCommand_newCashSale);
	const [step, setStep] = useState<number>(0);
	const amountToPay = useMemo(() => _.sum(lines.map(t => t.unitPrice * t.quantity)), [lines]);
	const withPayment = useMemo(() => amountToPay !== 0, [amountToPay]);
	const stepsRecord = useMemo(() => (withPayment ? stepsRecordWithPayment : stepsRecordWithoutPayment), [withPayment]);
	const [isPrintTicket, setIsPrintTicket] = useState<boolean>(true);
	const schema = useMemo(() => createSchema(strings, withPayment), [strings, withPayment]);
	const [patient] = useApiEffectWithDefer(patientsQuery_singleSearch, patientId!, val => isNotNullNorUndefined(val));

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await create({
			...values,
			lines: lines.map((t, index) => invoiceLineToSalesLineModel(t, index, false)),
			payments: withPayment ? [{ methodId: values.methodId, value: amountToPay }] : [],
			patientId: patientId !== undefined ? patientId : values.patientId,
		});
		if (r.isSuccess) {
			if (r.result.isSuccess) {
				notify(strings.addedWhat(strings.cashSale));
				confirm(r.result.objectId, isPrintTicket);
			} else if (r.result.errors.find(t => t.property === 'lines') !== undefined) {
				onLineErrors(r);
			} else {
				handleFormResponse(r, helpers, stepsRecord, setStep);
			}
		}
		// if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
		//     // navigate to detail?
		//     notify(strings.addedWhat(strings.cashSale));
		//     confirm(r.result.objectId, isPrintTicket);
		// }
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{ ...emptyValues, value: amountToPay }}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<PatientSearchProvider>
					<InnerDialog
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						isPrintTicket={isPrintTicket}
						setIsPrintTicket={setIsPrintTicket}
						stepsRecord={stepsRecord}
						withPayment={withPayment}
						schema={schema}
						patient={patient}
						{...rest}
					/>
				</PatientSearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	isPrintTicket: boolean;
	setIsPrintTicket: React.Dispatch<React.SetStateAction<boolean>>;
	stepsRecord: Record<number, (keyof IModel)[]>;
	withPayment: boolean;
	schema: yup.ObjectSchema<IModel, object>;
	patient: IPatientSearch | undefined;
}

const InnerDialog = ({
	step,
	setStep,
	cancel,
	isSubmitting,
	isPrintTicket,
	setIsPrintTicket,
	stepsRecord,
	withPayment,
	schema,
	patient,
	...rest
}: IInnerDialogProps) => {
	const props = useFormikContext<IModel>();
	const strings = useLocalization();
	const [isForPatient, toggle] = useStateToggleBoolean(patient !== undefined ? true : false);
	const stepsWithPayment = useMemo(() => [strings.reference, `${strings.patient}?`, strings.payment, 'Print'], [strings]);
	const stepsWithoutPayment = useMemo(() => [strings.reference, `${strings.patient}?`, 'Print'], [strings]);
	const printStep = useMemo(() => (withPayment ? 3 : 2), [withPayment]);
	const paymentStep = useMemo(() => (withPayment ? 2 : undefined), [withPayment]);
	useNextReferenceInterceptor(salesQuery_nextCashSaleReference, props);

	useEffect(() => {
		if (isForPatient === false) {
			setFormValue<IModel>('patientId', undefined, props);
		}
		// eslint-disable-next-line
	}, [isForPatient]);

	return (
		<Dialog {...rest}>
			<DialogTitleWithFormStepper
				title={strings.cashSale}
				step={step}
				labels={withPayment ? stepsWithPayment : stepsWithoutPayment}
			/>
			<DialogContent
				dividers
				className='df-col'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IModel>
							name='isAutoSelectReference'
							label={strings.autoSelectPaymentReferenceQuestion}
						/>
						<FormTextField<IModel>
							name='reference'
							label={strings.paymentReference}
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						{patient === undefined && (
							<>
								<FormControlLabelCheckbox
									isChecked={isForPatient}
									toggle={toggle}
									label={`${strings.forAPatientQuestion} (${strings.optional})`}
								/>
								<FormSelectPatientField<IModel>
									name='patientId'
									label={strings.patient}
									disabled={isForPatient === false}
								/>
							</>
						)}
						{patient !== undefined && (
							<TextField
								label={strings.patient}
								value={patient.lastNameCommaFirstNameBracketsNickName}
								disabled
								variant='filled'
								style={{ marginBottom: 8, width: '100%' }}
							/>
						)}
					</>
				)}
				{step === paymentStep && (
					<>
						<FormSelectOrCreatePaymentMethodField<IModel>
							name='methodId'
							label={strings.paymentMethod}
							mode='cashSale'
						/>
						<FormMoneyField<IModel>
							name='value'
							label={strings.amount}
							disabled
						/>
					</>
				)}
				{step === printStep && (
					<ExtendedRadioGroup<boolean>
						selected={isPrintTicket}
						setSelected={setIsPrintTicket}
						options={[
							{ value: true, label: 'Print ticket', caption: 'Registreer verkoop, print een ticket via een thermische printer' },
							{ value: false, label: 'Geen ticket printen', caption: 'Registreer verkoop zonder ticket te printen' },
						]}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
