import { IconButton, InputBase, Paper } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { useEnterKeyDown } from '../../hooks/useEnterKeyDown';

interface IProps {
	value: string;
	onSearch: (value: string) => void;
	placeholder: string;
	style?: React.CSSProperties;
	children?: any;
}

export const SearchbarX = ({ value, onSearch, placeholder, style, children }: IProps) => {
	const [text, setText] = useState<string>(value);
	const handleKeyDown = useEnterKeyDown(() => onSearch(text));
	const isFiltered = Boolean(text !== undefined && text !== null && text !== '');
	const isTextSet = Boolean(text === value);
	const initializeRef = React.useRef<boolean>(false);

	useEffect(() => {
		if (text !== value) {
			setText(value);
		}
		// eslint-disable-next-line
	}, [value]);

	useEffect(() => {
		if (initializeRef.current) {
			const timeoutId = setTimeout(() => onSearch(text), text === '' ? 0 : 500);
			return () => clearTimeout(timeoutId);
		} else {
			initializeRef.current = true;
		}
		// eslint-disable-next-line
	}, [text]);

	return (
		<Paper
			className='df-row-ac'
			style={{ borderRadius: 12, ...style }}>
			<IconButton
				type='submit'
				onClick={() => onSearch(text)}>
				<SearchIcon color={isTextSet ? 'action' : 'error'} />
			</IconButton>
			<InputBase
				className='fg1'
				placeholder={placeholder}
				value={text}
				onChange={e => setText(e.target.value)}
				onKeyDown={handleKeyDown}
			/>
			{children && children}
			{isFiltered && (
				<IconButton onClick={() => setText('')}>
					<ClearIcon />
				</IconButton>
			)}
		</Paper>
	);
};
