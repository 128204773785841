import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IProductCatalogItem, IProductCatalogQueryParams, IQueryResult, productCatalogQuery_concurrencyToken, productCatalogQuery_query } from 'gen/ApiClient';
import { productCatalogQueryParamsLsKey } from '../../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: IProductCatalogQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	manufacturers: [],
	categories: [],
	subcategories: [],
	createdAfter: undefined,
	createdBefore: undefined,
	listPriceMoreThan: undefined,
	listPriceLessThan: undefined,
	vatPercentages: [],
	isElectronics: undefined,
	hasBatteries: undefined,
	hasSerialNumber: undefined,
	lastUpdatedAfter: undefined,
	lastUpdatedBefore: undefined,
	suppliers: [],
	types: [],
	rizivHearingAidDefinitionV2LinkStatuses: [],
	alsoIncludeDeleted: false,
	keyFilterType: undefined,
	isTangible: undefined,
	isDeleted: undefined,
	isTailorMade: undefined,
	labels: [],
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IProductCatalogItem>, IProductCatalogQueryParams>
			defaultParams={DefaultParams}
			queryF={productCatalogQuery_query}
			concurrencyF={productCatalogQuery_concurrencyToken}
			context={QueryContext}
			localStorageKey={productCatalogQueryParamsLsKey}>
			{children}
		</FrameworkQueryProvider>
	);
};
