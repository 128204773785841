import { ListItem, ListItemIcon, Typography, useTheme } from '@material-ui/core';
import { FileDocumentOutlineIcon } from 'framework/components/icons/FileDocumentOutlineIcon';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

export interface IPlaceholderDocumentListItemProps {
	title: string;
	withWarning?: boolean;
	onNew?: VoidFunction;
	createText?: 'create' | 'scan';
	disabled?: boolean;
}

export const EmptyDocumentListItem = ({ title, withWarning, disabled = false, onNew, createText = 'create' }: IPlaceholderDocumentListItemProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [isHovered, enter, leave] = useStateBoolean(false);

	return (
		<div
			onMouseEnter={enter}
			onMouseLeave={leave}>
			<ListItem
				disableGutters
				button
				disabled={disabled}
				onClick={onNew}>
				<ListItemIcon style={{ minWidth: '35px' }}>{withWarning ? <StatusIcon status='warning' /> : <FileDocumentOutlineIcon />}</ListItemIcon>
				<div className='df-row-ac'>
					<Typography variant='body2'>
						<em>{title}</em>
					</Typography>
					{isHovered && disabled === false && (
						<Typography
							variant='body2'
							style={{ marginLeft: theme.spacing(0.5) }}
							color='primary'>
							<em>(+ {createText === 'create' ? strings.create : strings.scan})</em>
						</Typography>
					)}
				</div>
			</ListItem>
		</div>
	);
};
