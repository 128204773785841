import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatchPatientLanguageRequest, IPatient, patientsCommand_patchLanguage } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { FormSelectLanguageField } from 'shared/forms/FormSelectLanguageField';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IPatchPatientLanguageRequest>({
		language: yup.string().required(strings.formRequired(strings.language)),
		patientId: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	patient: IPatient;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchPatientLanguageRequestForm = ({ patient, confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(patientsCommand_patchLanguage);
	const currentLanguage = useCurrentLanguage();

	const handleSubmit = async (values: IPatchPatientLanguageRequest, helpers: FormikHelpers<IPatchPatientLanguageRequest>) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.patientUpdated);
			confirm();
		}
	};

	return (
		<Formik<IPatchPatientLanguageRequest>
			validateOnMount
			validationSchema={createSchema(strings)}
			initialValues={{
				language: patient.language ?? currentLanguage,
				patientId: patient.id,
			}}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.language}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'
						style={{ overflow: 'visible' }}>
						<FormSelectLanguageField<IPatchPatientLanguageRequest>
							label={strings.language}
							name='language'
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.update}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
