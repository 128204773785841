import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { companiesCommand_patchEmail, companiesCommand_patchName, companiesCommand_patchPhone, companiesCommand_patchVatNumber, ICompany } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PatchCompanyAddressForm } from './forms/PatchCompanyAddressForm';
import { PatchCompanyContactForm } from './forms/PatchCompanyContactForm';

interface IProps {
	item: ICompany;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const CompanyDetailsContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onPatchName = () => {
		open(
			<StringModelForm
				open
				confirm={confirm}
				cancel={cancel}
				fullWidth
				label={`${strings.name} ${strings.vatCustomer}`}
				formTitle={strings.changeWhat(`${strings.name} ${strings.vatCustomer}`)}
				notifyMessage={strings.changedWhat(`${strings.name} ${strings.vatCustomer}`)}
				submitText={strings.update}
				submitFunction={val => companiesCommand_patchName({ vatCustomerId: item.id, name: val })}
				initialValue={item.name ?? ''}
			/>
		);
	};

	const onPatchVatNumber = () => {
		open(
			<StringModelForm
				open
				confirm={confirm}
				cancel={cancel}
				fullWidth
				label={strings.vatNumber}
				formTitle={strings.changeWhat(strings.vatNumber)}
				notifyMessage={strings.changedWhat(strings.vatNumber)}
				submitText={strings.update}
				submitFunction={val => companiesCommand_patchVatNumber({ vatCustomerId: item.id, vatNumber: val })}
				initialValue={item.vatNumber ?? ''}
			/>
		);
	};

	const onPatchContact = () => {
		open(
			<PatchCompanyContactForm
				cancel={cancel}
				confirm={confirm}
				open
				company={item}
			/>
		);
	};

	const onPatchAddress = () => {
		open(
			<PatchCompanyAddressForm
				confirm={confirm}
				cancel={cancel}
				company={item}
			/>
		);
	};

	const onPatchPhone = () => {
		open(
			<StringModelForm
				open
				confirm={confirm}
				cancel={cancel}
				fullWidth
				label={strings.phone}
				formTitle={strings.changeWhat(strings.phone)}
				notifyMessage={strings.changedWhat(strings.phone)}
				submitText={strings.update}
				submitFunction={val => companiesCommand_patchPhone({ vatCustomerId: item.id, phone: val })}
				initialValue={item.phone ?? ''}
			/>
		);
	};

	const onPatchEmail = () => {
		open(
			<StringModelForm
				open
				confirm={confirm}
				cancel={cancel}
				fullWidth
				label={strings.email}
				formTitle={strings.changeWhat(strings.email)}
				notifyMessage={strings.changedWhat(strings.email)}
				submitText={strings.update}
				submitFunction={val => companiesCommand_patchEmail({ vatCustomerId: item.id, email: val })}
				initialValue={item.email ?? ''}
			/>
		);
	};

	return (
		<div
			className='df-col gap-8'
			style={style}>
			<CaptionVerticalLabelledPropertyX
				label={strings.name}
				edit={onPatchName}>
				{item.name}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.vatNumber}
				edit={onPatchVatNumber}>
				<StringValueOrNotApplicable>{item.vatNumber}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.contactPerson}
				edit={onPatchContact}>
				<StringValueOrNotApplicable>{item.contact}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.address}
				edit={onPatchAddress}>
				<StringValueOrNotApplicable>{item.address}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.phone}
				edit={onPatchPhone}>
				<StringValueOrNotApplicable>{item.phone}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.email}
				edit={onPatchEmail}>
				<StringValueOrNotApplicable>{item.email}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
		</div>
	);
};
