import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { CompaniesPage } from './companies/CompaniesPage';
import { EntPhysiciansPage } from './earNoseThroatPhysicians/EntPhysiciansPage';
import { GeneralPractitionersPage } from './generalPractitioners/GeneralPractitionersPage';
import { HifsPage } from './hifs/HifsPage';
import * as routes from './routes';
import { SuppliersPage } from './suppliers/SuppliersPage';

export const ContactsSwitch = () => {
	return (
		<Switch>
			<Route
				exact
				path={routes.EntPhysiciansRoute}
				component={EntPhysiciansPage}
			/>
			<Route
				exact
				path={routes.GeneralPractitionersRoute}
				component={GeneralPractitionersPage}
			/>
			<Route
				exact
				path={routes.SuppliersRoute}
				component={SuppliersPage}
			/>
			<Route
				exact
				path={routes.VatCustomersRoute}
				component={CompaniesPage}
			/>
			<Route
				exact
				path={routes.HifsRoute}
				component={HifsPage}
			/>
			<Redirect to={routes.EntPhysiciansRoute} />
		</Switch>
	);
};
