import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { FileSearchOutlineIcon } from 'framework/components/icons/FileSearchOutlineIcon';
import { NumberFormatTextField } from 'framework/components/textFields/NumberFormatTextField';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormNumberField } from 'framework/forms/FormNumberField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { ICltModel, ICltTest, trialReportsCommand_addClt } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { useViewDocumentation } from 'shared/utils/useViewDocumentation';

const calculateMono = (monoLeft: number | undefined, monoRight: number | undefined): number | undefined => {
	if (isNotNullNorUndefined(monoLeft) && isNotNullNorUndefined(monoRight)) {
		return (Math.abs(monoLeft!) + Math.abs(monoRight!)) / 2;
	} else if (isNotNullNorUndefined(monoLeft)) {
		return Math.abs(monoLeft!);
	} else if (isNotNullNorUndefined(monoRight)) {
		return Math.abs(monoRight!);
	} else {
		return undefined;
	}
};

const createSchema = (strings: IStrings, isLrMode: boolean) => {
	return yup.object<ICltModel>({
		monoLeft: isLrMode ? yup.number().required() : yup.number(),
		monoRight: isLrMode ? yup.number().required() : yup.number(),
		mono: isLrMode ? yup.number() : yup.number().required(),
		stereo: yup.number().required(),
	});
};

interface IProps extends DialogProps {
	trialId: string;
	test: ICltTest;
	cancel: VoidFunction;
	confirm: VoidFunction;
}

export const CltTestForm = ({ trialId, test, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(trialReportsCommand_addClt);
	const notify = useSnackbarNotify();
	const [isLrEntryMode, setIsLrEntryMode] = useState<boolean>(isNotNullNorUndefined(test?.monoLeft ?? undefined) ? true : false);

	const handleSubmit = async (values: ICltModel, helpers: FormikHelpers<ICltModel>) => {
		const r = await submit(trialId, isLrEntryMode ? { ...values, mono: calculateMono(values.monoLeft, values.monoRight)! } : values);
		if (handleFormResponse(r, helpers)) {
			notify(strings.addedWhat('CLT'));
			confirm();
		}
	};

	return (
		<Formik<ICltModel>
			validateOnMount
			initialValues={{
				monoLeft: test?.monoLeft ?? undefined,
				monoRight: test?.monoRight ?? undefined,
				mono: test?.mono ?? undefined,
				stereo: test?.stereo ?? undefined,
			}}
			validationSchema={createSchema(strings, isLrEntryMode)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					isSubmitting={isSubmitting}
					test={test}
					isLrMode={isLrEntryMode}
					setIsLrMode={setIsLrEntryMode}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	test?: ICltTest;
	isLrMode: boolean;
	setIsLrMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const InnerDialog = ({ cancel, isSubmitting, title, test, isLrMode, setIsLrMode, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const props = useFormikContext<ICltModel>();
	const calculatedMono = useMemo(() => calculateMono(props.values.monoLeft, props.values.monoRight), [props.values]);
	const improvement = useMemo(
		() => (isLrMode ? Math.abs(calculatedMono ?? 0) : Math.abs(props.values.mono)) - Math.abs(props.values.stereo),
		[props.values, calculatedMono, isLrMode]
	);
	const onViewCltRizivInfo = useViewDocumentation('clt-riziv-info');

	return (
		<Dialog
			fullWidth
			{...rest}>
			<DialogTitle>
				<div>CLT</div>
				<div>
					<SmallPrimaryTextButton
						startIcon={<FileSearchOutlineIcon />}
						onClick={onViewCltRizivInfo}>
						{strings.viewDocumentation}
					</SmallPrimaryTextButton>
				</div>
			</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<ExtendedRadioGroup<boolean>
					selected={isLrMode}
					setSelected={setIsLrMode}
					options={[
						{ value: true, label: strings.fillInLRSeparately },
						{ value: false, label: strings.fillInMono },
					]}
				/>
				<DividerWithCaption caption={strings.form} />
				{isLrMode === false && (
					<FormNumberField<ICltModel>
						name='mono'
						label={strings.mono}
						suffix={` °`}
						allowNegative
						decimalScale={0}
					/>
				)}
				{isLrMode === true && (
					<>
						<FormNumberField<ICltModel>
							name='monoLeft'
							label={strings.left}
							suffix={` °`}
							allowNegative
							decimalScale={0}
						/>
						<FormNumberField<ICltModel>
							name='monoRight'
							label={strings.right}
							suffix={` °`}
							allowNegative
							decimalScale={0}
						/>
					</>
				)}
				<FormNumberField<ICltModel>
					name='stereo'
					label={strings.stereo}
					suffix={` °`}
					allowNegative
					decimalScale={0}
				/>
				<DividerWithCaption caption={strings.calculation} />
				{isLrMode && (
					<NumberFormatTextField
						style={{ marginBottom: 8 }}
						variant='filled'
						disabled
						label={strings.mono}
						value={calculatedMono}
						suffix=' °'
						decimalScale={1}
					/>
				)}
				<NumberFormatTextField
					variant='filled'
					disabled
					label={strings.improvement}
					value={improvement}
					suffix=' °'
					decimalScale={1}
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions
				cancel={cancel}
				submitText={test ? strings.update : strings.create}
				isSubmitting={isSubmitting}
			/>
		</Dialog>
	);
};
