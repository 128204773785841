import React from 'react';
import { ILabelledPropertyProps } from './LabelledProperty';
import { VariantLabelledProperty } from './VariantLabelledProperty';

interface IProps extends ILabelledPropertyProps {
	info: string;
	style?: React.CSSProperties;
}

export const InfoLabelledProperty = ({ info, ...rest }: IProps) => {
	return (
		<VariantLabelledProperty
			variantX='info'
			property={info}
			{...rest}
		/>
	);
};
