import React, { useContext } from 'react';
import { PreloadCacheProvider as FrameworkCacheProvider } from 'framework/context/PreloadCacheProvider';
import { ISupplierSummary, suppliersQuery_all, suppliersQuery_concurrencyToken } from 'gen/ApiClient';
import { SuppliersCacheContext } from './SuppliersCacheContext';
import { PreloadContext } from 'shared/context/PreloadContext';

export const SuppliersCacheProvider = ({ children }: any) => {
	const { suppliers } = useContext(PreloadContext);

	return (
		<FrameworkCacheProvider<ISupplierSummary[]>
			initialData={suppliers}
			concurrencyF={suppliersQuery_concurrencyToken}
			loadF={suppliersQuery_all}
			context={SuppliersCacheContext}>
			{children}
		</FrameworkCacheProvider>
	);
};
