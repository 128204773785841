import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { IRequestForInsuranceAllowance } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { RfiaTenderForm } from './RfiaTenderForm';

type State = 'warning' | 'model';

interface IProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	rfia: IRequestForInsuranceAllowance;
}

export const RfiaTenderWrapperForm = ({ rfia, confirm, cancel }: IProps) => {
	const strings = useLocalization();

	if (rfia.hasMainMedicalTreatmentNomenclatureDefined === false) {
		return (
			<NotificationDialog
				open
				fullWidth
				title={strings.noNomenclaturesSelected}
				content={strings.selectNomenclatureCodeFirst}
				close={cancel}
				closeText={strings.understood}
			/>
		);
	} else {
		return (
			<RfiaTenderForm
				open
				confirm={confirm}
				cancel={cancel}
				rfia={rfia}
			/>
		);
	}
};
