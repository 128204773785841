import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from '../../dialogs/useDialogsContext';
import { TypesList } from './TypesList';

interface IProps extends DialogProps {
	close: VoidFunction;
	isFollowUp: boolean;
}

export const AnnotationSettingsDialog = ({ close, isFollowUp, ...rest }: IProps) => {
	const strings = useLocalization();
	const { isStacked } = useDialogsContext();

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			fullWidth>
			<CloseButtonOnAbsolutePosition onClick={close} />
			<DialogTitle>
				{strings.settings} {strings.annotations.toLowerCase()}
			</DialogTitle>
			<DialogContent dividers>
				<TypesList isFollowUp={isFollowUp} />
			</DialogContent>
		</Dialog>
	);
};
