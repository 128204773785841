import { Collapse, Divider } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import { ArticlePropertiesLabelledPropertiesX } from 'app/main/inventoryManagement/inventoryItems/detail/articleProperties/ArticlePropertiesLabelledPropertiesX';
import { useLocalizedArticlePropertyKeys } from 'app/main/inventoryManagement/productCatalog/articlePropertyKeys/context/useLocalizedArticlePropertyKeys';
import { calcInventoryItemDetailRoute, calcProductCatalogDetailRoute } from 'app/main/inventoryManagement/routes';
import { calcOrderDetailRoute } from 'app/main/processes/ProcessesRoutes';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { TextLinkButtonWithExpandIcon } from 'framework/components/buttons/TextLinkButtonWithExpandIcon';
import { ChipX } from 'framework/components/chips/ChipX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { articlesInquiriesCommand_deleteLine, ArticlesInquiryLineStatusType, IArticlesInquiryDto, IArticlesInquiryLineDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { AssignArticlesInquiryLineFromStock } from '../../forms/assignFromStock/AssignArticlesInquiryLineFromStock';
import { ArticlesInquiryLineStatusIcon } from './ArticlesInquiryLineStatusIcon';
import { ArticlesInquiryLineStatusToColorXRecord } from './ArticlesInquiryLineStatusToColorXRecord';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	line: IArticlesInquiryLineDto;
	articlesInquiry: IArticlesInquiryDto;
	withoutBorder?: boolean;
	reload: VoidFunction;
}

export const ArticlesInquiryLineListItem = ({ line, articlesInquiry, withoutBorder, reload }: IProps) => {
	const strings = useLocalization();
	const { articlesInquiryLineStatusTypeRecord } = useContext(RecordContext);
	const { push } = useHistory();
	const color = useMemo(() => ArticlesInquiryLineStatusToColorXRecord[line.status as ArticlesInquiryLineStatusType], [line]);
	const tColor = useThemeColor(color);
	const localizedKeys = useLocalizedArticlePropertyKeys();
	const [isExpanded, toggleExpanded] = useStateToggleBoolean(false);
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title={strings.deleteLine}
				content={strings.doYouWantToDeleteThisLineFromThisArticlesInquiryQuestion}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => articlesInquiriesCommand_deleteLine({ articlesInquiryId: articlesInquiry.id, articlesInquiryLineId: line.id })}
				accept={confirm}
			/>
		);
	};

	const onAssignFromStock = () => {
		open(
			<AssignArticlesInquiryLineFromStock
				line={line}
				articlesInquiryId={articlesInquiry.id}
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<ArticlesInquiryLineStatusIcon
					item={line}
					style={{ fontSize: 22, color: tColor }}
				/>
			</div>
			<div className='df-col fg1'>
				<div className='df-row'>
					<TextLinkButton
						onClick={() => {
							if (line.inventoryItemId) {
								push(calcInventoryItemDetailRoute(line.inventoryItemId));
							} else {
								push(calcProductCatalogDetailRoute(line.productCatalogItemId));
							}
						}}
						style={{ fontSize: MajorFontSize }}>
						{`${line.quantity} x ${line.description}`}
					</TextLinkButton>
					<div className='fg1'></div>
				</div>
				<ChipX
					label={articlesInquiryLineStatusTypeRecord[line.status as ArticlesInquiryLineStatusType]}
					color={color}
					style={{ marginRight: 'auto', marginTop: 4, marginBottom: 4 }}
				/>
				<span style={{ fontSize: MinorFontSize }}>
					{`# ${line.quantity} ${strings.requested.toLowerCase()}, # ${line.assignedQuantity} ${strings.assigned.toLowerCase()}`}
				</span>
				{line.hasOrder && (
					<div className='df-row-ac gap-4'>
						<span style={{ fontSize: MinorFontSize }}>{`# ${strings.ordered.toLowerCase()}: ${line.order.quantity}`}</span>
						<TextLinkButton
							underline
							style={{ fontSize: MinorFontSize }}
							onClick={() => push(calcOrderDetailRoute(line.order.id))}>
							{`${strings.order}: ${line.order.reference}`}
						</TextLinkButton>
					</div>
				)}
				{line.hasInventoryItem === false && line.articlePropertiesX?.length > 0 && (
					<>
						<TextLinkButtonWithExpandIcon
							toggle={toggleExpanded}
							isExpanded={isExpanded}>
							<span style={{ fontSize: MinorFontSize, fontWeight: 'unset' }}>{`# ${line.articlePropertiesX.length.toString()} ${
								line.articlePropertiesX.length > 1 ? strings.properties : strings.property
							}`}</span>
						</TextLinkButtonWithExpandIcon>
						<Collapse in={isExpanded}>
							<div className='df-col'>
								<Divider
									variant='inset'
									style={{
										marginTop: 8,
										marginBottom: 4,
										width: '50%',
									}}
								/>
								<ArticlePropertiesLabelledPropertiesX
									aps={line.articlePropertiesX}
									localizedKeys={localizedKeys}
								/>
							</div>
						</Collapse>
					</>
				)}
			</div>
			<div
				className='df-col gap-4'
				style={{ alignItems: 'flex-end' }}>
				<TextLinkButton
					hoverColor='secondary'
					style={{ fontSize: MinorFontSize }}
					disabled={line.canDelete === false || articlesInquiry.isCancelled}
					startIcon={<DeleteOutlineIcon />}
					onClick={onDelete}>
					{strings.delete}
				</TextLinkButton>
				<TextLinkButton
					hoverColor='secondary'
					style={{ fontSize: MinorFontSize }}
					disabled={line.canAssignFromStock === false || articlesInquiry.isCancelled}
					startIcon={<StorefrontOutlinedIcon />}
					onClick={onAssignFromStock}>
					{strings.assignFromStock}
				</TextLinkButton>
			</div>
		</div>
	);
};
