import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { isLast } from 'framework/utils/array/isLast';
import { ICustomerAccountLineData, ISale } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { SalesDetailAccountReceivableCardX } from './SalesDetailAccountReceivableCardX';
import { SalesDetailAccountReceivablePaymentListItem } from './SalesDetailAccountReceivablePaymentListItem';

interface IProps {
	item: ISale;
	style?: React.CSSProperties;
}

export const SalesDetailsAccountsReceivableAndPaymentsContent = ({ item, style }: IProps) => {
	const strings = useLocalization();
	const [expandedAccountReceivable, setExpandedAccountReceivable] = React.useState<ICustomerAccountLineData>();

	React.useEffect(() => {
		if (item.customerAccountLines.length > 0) {
			setExpandedAccountReceivable(item.customerAccountLines[0]);
		}
	}, [item]);

	return (
		<div
			className='df-row gap-16'
			style={style}>
			<div className='df-col gap-8 fg1 h-fc'>
				{item.customerAccountLines.map(t => (
					<SalesDetailAccountReceivableCardX
						key={t.id}
						sale={item}
						item={t}
						isSelected={expandedAccountReceivable?.id === t.id}
						toggle={() => setExpandedAccountReceivable(t)}
					/>
				))}
			</div>
			<div style={{ minWidth: 300 }}>
				{expandedAccountReceivable && (
					<CardX className='h-fc gap-8'>
						<Typography16pxBold>{strings.payments}</Typography16pxBold>
						{expandedAccountReceivable.hasPayments === true && (
							<div className='df-col gap-4'>
								{expandedAccountReceivable.payments.map(t => (
									<SalesDetailAccountReceivablePaymentListItem
										key={t.paymentId}
										line={t}
										withoutBorder={isLast(t, expandedAccountReceivable.payments)}
									/>
								))}
							</div>
						)}
						{expandedAccountReceivable.hasPayments === false && <NoRecordsFound variant='caption'>{strings.noPaymentsReceivedYet}</NoRecordsFound>}
					</CardX>
				)}
			</div>
		</div>
	);
};
