import { Dialog, DialogContent, DialogProps, DialogTitle, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { IFile } from 'framework/utils/IFile';
import { readFile } from 'framework/utils/readFile';
import { useLocalization } from 'localization/useLocalization';
import { FileType } from './FileType';
import { UploadFile } from './UploadFile';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';

interface IProps extends DialogProps {
	title: string;
	confirm: (file: IFile) => void;
	confirmF?: (file: IFile) => Promise<void>;
	cancel: VoidFunction;
	isUploading: boolean;
	caption?: string;
	accept: FileType;
	previous?: VoidFunction;
}

export const UploadFileDialog = ({ title, confirm, confirmF, cancel, isUploading, caption, accept, previous, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const notify = useSnackbarNotify();

	const onUpload = async (files: File[]) => {
		if (files.length > 1) {
			notify('Maximaal 1 file selecteren', 'error');
			return;
		}

		const read = await readFile(files[0]);
		if (confirmF) {
			setIsSubmitting(true);
			await confirmF(read);
			setIsSubmitting(false);
		}
		confirm(read);
	};

	return (
		<Dialog
			fullWidth
			{...rest}>
			<DialogTitle>{title}</DialogTitle>
			{caption && (
				<Typography
					variant='caption'
					style={{ whiteSpace: 'pre-line', paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3), paddingBottom: theme.spacing(2) }}>
					{caption}
				</Typography>
			)}
			<DialogContent dividers>
				<UploadFile
					isUploading={isUploading || isSubmitting}
					onFilesSelected={onUpload}
					accept={accept}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				previous={previous}
				cancel={cancel}
				submitText={strings.upload}
				isValid={false}
				onSubmit={() => {}}
				isSubmitting={isUploading}
				hideSubmitButton
			/>
		</Dialog>
	);
};
