import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import React, { useCallback } from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { SignatureFreehandIcon } from 'framework/components/icons/SignatureFreehandIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { formatDate } from 'framework/utils/date/formatDate';
import { cosiQuestionnaireQuery_single, documentsQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DocumentHistory } from 'documents/DocumentHistory';
import { DocumentWithRebuildActions } from 'documents/DocumentWithRebuildActions';
import { CosiStatusFibers } from './CosiStatusFibers';
import { EvaluateCosiQuestionnaireForm } from './EvaluateCosiQuestionnaireForm';

interface IProps extends DialogProps {
	close: VoidFunction;
	id: string;
	rfiaId: string;
}

export const CosiDocumentDetailDialog = ({ id, close, rfiaId, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [item, reloadCosi] = useApiEffect(cosiQuestionnaireQuery_single, rfiaId);
	const [document, reloadDocument] = useApiEffect(documentsQuery_single, id);
	const reload = useCallback(() => {
		reloadDocument();
		reloadCosi();
	}, [reloadDocument, reloadCosi]);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);

	if (document === undefined || item === undefined) {
		return <div></div>;
	}

	const onEvaluate = () => {
		open(
			<EvaluateCosiQuestionnaireForm
				open
				confirm={onEvaluated}
				cancel={cancel}
				rfiaId={rfiaId}
			/>
		);
	};

	const onEvaluated = () => {
		confirm();
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			maxWidth='sm'
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<DescriptionIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{strings.cosiQuestionnaire}
					</Typography>
					<LabelledProperty
						label={strings.extension}
						property={document.extension}
						withoutMargin
					/>
					{item.evaluationDate && (
						<LabelledProperty
							label={strings.evaluationDate}
							property={formatDate(item.evaluationDate)}
						/>
					)}
					<CosiStatusFibers
						cosiQuestionnaire={item}
						hasSignature={document.hasSignature}
					/>
					{document.hasSignature && (
						<LabelledProperty
							property={strings.signatureScanned}
							icon={<SignatureFreehandIcon style={{ color: theme.palette.success.main }} />}
							color='success'
						/>
					)}
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<DocumentWithRebuildActions
							document={document}
							reload={reload}
							close={close}
							isDocumentInSync={item.isDocumentInSync}
							prependActions={
								document.hasSignature === false ? (
									<SmallPrimaryTextButton
										startIcon={<ThumbUpAltOutlinedIcon />}
										onClick={onEvaluate}>
										{`${strings.addWhat(strings.cosiNeed)}/${strings.evaluateVerb}`}
									</SmallPrimaryTextButton>
								) : undefined
							}
						/>
					}
				/>
				<DocumentHistory document={document} />
			</DialogContentGrid>
		</Dialog>
	);
};
