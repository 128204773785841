import { Typography } from '@material-ui/core';
import { DragAndDropContainer } from 'framework/components/dnd/DragAndDropContainer';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { LeftRightDividedContentScrollYRight } from 'framework/components/LeftRightDividedContentScrollYRight';
import { useApiEffectWithDefer } from 'framework/hooks/useApiEffectWithDefer';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { updateArray } from 'framework/utils/array/updateArray';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import {
	IInventoryItem,
	IInventoryItemWithProductCatalogItemDto,
	ILocationSummary,
	inventoryItemsQuery_reservedByPatientIds,
	IProductCatalogItem,
	ISalesLineForAssuranceDto,
	IStockAtLocation,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AddLineForm } from 'shared/searchAndSelect/AddLineForm';
import { DefaultInventoryItemsQueryParamsForSale } from 'shared/searchAndSelect/DefaultInventoryItemsQueryParamsForSale';
import { INewLineModel } from 'shared/searchAndSelect/INewLineModel';
import { SearchProductCatalogOrInventoryItemForSaleTabs } from 'shared/searchAndSelect/SearchProductCatalogOrInventoryItemForSaleTabs';
import { TotalsInVatTable } from 'shared/searchAndSelect/TotalsInVatTable';
import { DiscountWizardButton } from 'shared/searchAndSelect/wizard/DiscountWizardButton';
import { ImportFromNoahWizardButton } from 'shared/searchAndSelect/wizard/ImportFromNoahWizardButton';
import { ReservedWizardButton } from 'shared/searchAndSelect/wizard/ReservedWizardButton';
import { WizardDivider } from 'shared/searchAndSelect/wizard/WizardDivider';
import { ImportNoahDevicesByPatientForm } from 'shared/searchAndSelect/wizardImportNoahDevices/ImportNoahDevicesByPatientForm';
import { SelectedReservedArticles } from 'shared/searchAndSelect/wizardImportReserved/SelectedReservedArticles';
import { SearchContext } from './context/SearchContext';
import { IInvoiceLine } from './IInvoiceLine';
import { SalesLineComponent } from './SalesLineComponent';
import { assuranceToLine } from './utils/assuranceToLine';
import { inventoryItemToLine } from './utils/inventoryItemToLine';
import { newLineToLine } from './utils/newLineToLine';
import { productCatalogItemToLine } from './utils/productCatalogItemToLine';

interface IProps {
	lines: IInvoiceLine[];
	setLines: React.Dispatch<React.SetStateAction<IInvoiceLine[]>>;
	location: ILocationSummary;
	customerAccountId: string | undefined;
	patientId: string | undefined;
	withoutNegativeMargin?: boolean;
	setIsShiftVat?: React.Dispatch<React.SetStateAction<boolean>>;
	isShiftVat: boolean;
	canCredit: boolean;
}

export const SearchAndSelectProductsForSale = ({
	customerAccountId,
	lines,
	setLines,
	location,
	patientId,
	withoutNegativeMargin,
	isShiftVat,
	setIsShiftVat,
	canCredit,
}: IProps) => {
	const strings = useLocalization();
	const { ...allSearchParams } = useContext(SearchContext);
	const notify = useSnackbarNotify();
	const { open, confirm, cancel } = useDialogsContext();
	const [reservedIds] = useApiEffectWithDefer(inventoryItemsQuery_reservedByPatientIds, patientId ?? '', isNotNullNorUndefined);
	const countReserved = useMemo(() => reservedIds?.filter(t => lines.findIndex(x => x.inventoryItemId === t) === -1).length ?? 0, [reservedIds, lines]);

	const onAddOrCreditPc = (item: IProductCatalogItem, stockAtLocations: IStockAtLocation[], isCredit: boolean) => {
		const f = lines.find(t => t.productCatalogItemId === item.id && t.isCredit === isCredit);
		if (f === undefined) {
			setLines([...lines, productCatalogItemToLine(item, stockAtLocations, isCredit)]);
		} else {
			setLines(updateArray(f, lines, { ...f, quantity: f.quantity + (isCredit ? -1 : 1) }));
		}
	};
	const onAddAssuredLines = (assurance: IProductCatalogItem, linesToAssure: (IInvoiceLine | ISalesLineForAssuranceDto)[]) => {
		setLines([...lines, ...linesToAssure.map(t => assuranceToLine(assurance, t))]);
	};

	const onAddOrCreditIi = (item: IInventoryItem, pc: IProductCatalogItem, isCredit: boolean) => {
		if (lines.findIndex(t => t.inventoryItemId === item.id) > -1) {
			notify(strings.itemAlreadyInList, 'warning');
		} else {
			setLines([...lines, inventoryItemToLine(item, pc, isCredit)]);
		}
	};

	const onAddLine = () => {
		open(
			<AddLineForm
				open
				confirm={line => {
					confirm();
					onLineAdded(line);
				}}
				cancel={cancel}
			/>
		);
	};

	const onLineAdded = (line: INewLineModel) => {
		setLines([...lines, newLineToLine(line)]);
	};

	const onAddIIs = (items: IInventoryItemWithProductCatalogItemDto[]) => {
		let x = [...lines];
		items.forEach(item => {
			if (x.findIndex(t => t.inventoryItemId === item.inventoryItem.id) === -1) {
				x = [...x, inventoryItemToLine(item.inventoryItem, item.productCatalogItem, false)];
			}
		});
		setLines(x);
	};

	const onAddNoahDevices = (patientId: string) => {
		open(
			<ImportNoahDevicesByPatientForm
				open
				confirm={items => {
					confirm();
					onAddIIs(items);
				}}
				cancel={cancel}
				patientId={patientId}
			/>
		);
	};

	const onAddReservedArticles = (patientId: string) => {
		open(
			<SelectedReservedArticles
				open
				confirm={items => {
					confirm();
					onAddIIs(items);
				}}
				cancel={cancel}
				patientId={patientId}
				canSelect={t => t.canSelectForSale && lines.findIndex(x => x.id === t.id) === -1}
			/>
		);
	};

	return (
		<LeftRightDividedContentScrollYRight
			withoutNegativeMargin={withoutNegativeMargin}
			leftContent={
				<SearchProductCatalogOrInventoryItemForSaleTabs<IInvoiceLine>
					lines={lines}
					{...allSearchParams}
					defaultIIParams={DefaultInventoryItemsQueryParamsForSale}
					onAddAssuredLines={onAddAssuredLines}
					onAddPc={(item, sls) => onAddOrCreditPc(item, sls, false)}
					onCreditPc={canCredit === true ? (item, sls) => onAddOrCreditPc(item, sls, true) : undefined}
					onAddII={(item, pc) => onAddOrCreditIi(item, pc, false)}
					onCreditIi={canCredit === true ? (item, pc) => onAddOrCreditIi(item, pc, true) : undefined}
					customerAccountId={customerAccountId}
					canSelectInventoryItemF={i => i.canSelectForSale && i.isInLoan === false}
					wizardContent={
						<div className='df-col'>
							<DiscountWizardButton onClick={onAddLine} />
							{patientId && (
								<>
									<WizardDivider />
									<ImportFromNoahWizardButton onClick={() => onAddNoahDevices(patientId)} />
									{countReserved !== undefined && (
										<>
											<WizardDivider />
											<ReservedWizardButton
												onClick={() => onAddReservedArticles(patientId)}
												count={countReserved}
											/>
										</>
									)}
								</>
							)}
						</div>
					}
				/>
			}
			rightContent={
				<DragAndDropContainer<IInvoiceLine>
					lines={lines}
					setLines={setLines}
					getKey={t => t.id}
					render={(line, index, props) => (
						<SalesLineComponent
							line={line}
							index={index + 1}
							dragHandleProps={props}
							setLine={val => setLines(updateArray(line, lines, val))}
							onDelete={() => setLines(lines.filter(x => x !== line && x.assuredLineId !== line.id))}
							location={location}
							lines={lines}
							customerAccountId={customerAccountId}
							setLines={setLines}
						/>
					)}
				/>
			}
			rightBottomFixedContent={
				<div className='df-col-ae'>
					<TotalsInVatTable
						lines={lines}
						isShiftVat={isShiftVat}
					/>
					{setIsShiftVat && (
						<>
							<FormControlLabelSwitch
								isChecked={isShiftVat}
								toggle={() => setIsShiftVat(!isShiftVat)}
								label={strings.shiftVat}
								style={{ marginTop: 16 }}
							/>
							<Typography variant='caption'>{strings.intraCommCaption}</Typography>
						</>
					)}
				</div>
			}
		/>
	);
};
