import { StatusIcon } from 'framework/components/icons/StatusIcon';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useMemo } from 'react';
import { IAllowanceChargeFormLine } from './IAllowanceChargeFormLine';
import { PurchaseAllowanceChargeLineLinkStatusPopoverContent } from './PurchaseAllowanceChargeLineLinkStatusPopoverContent';
import { calculateAllowanceChargePurchaseUblLinkStatus } from './utils';

interface IProps {
	line: IAllowanceChargeFormLine;
	setLine: (val: IAllowanceChargeFormLine) => void;
	onLink: VoidFunction;
	onIgnore: VoidFunction;
}

export const PurchaseAllowanceChargeLineLinkStatusComponent = ({ line, setLine, onLink, onIgnore }: IProps) => {
	const popupState = usePopupState({ variant: 'popover', popupId: 'allowanceCharge' });
	const linkStatus = useMemo(() => calculateAllowanceChargePurchaseUblLinkStatus(line), [line]);

	const onUndoIgnore = () => {
		setLine({ ...line, isIgnored: false });
	};

	const onRemoveLink = () => {
		setLine({ ...line, productCatalogItem: undefined });
	};

	if (line.isMappedFromUbl === false) {
		return <></>;
	}

	return (
		<>
			<HoverPopover
				elevation={1}
				{...bindPopover(popupState)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}>
				<PurchaseAllowanceChargeLineLinkStatusPopoverContent
					onLink={onLink}
					onIgnore={onIgnore}
					onRemoveLink={onRemoveLink}
					onUndoIgnore={onUndoIgnore}
					linkStatus={linkStatus}
					line={line}
				/>
			</HoverPopover>
			<StatusIcon
				status={linkStatus === 'linked' ? 'success' : linkStatus === 'ignored' ? 'warning' : 'error'}
				fontSize='small'
				{...bindHover(popupState)}
			/>
		</>
	);
};
