import React, { useContext, useEffect, useMemo, useState } from 'react';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';
import { QuickFilter } from '../InventoryItemsList';
import { SelectableColumn } from '../SelectableColumn';
import { createColumnRecord } from '../utils/createColumnRecord';
import { createHeaders } from '../utils/createHeaders';
import { createQfRecord } from '../utils/createQfRecord';
import { QueryContext } from './QueryContext';
import { ViewContext } from './ViewContext';
import { ProductCatalogItemType } from 'gen/ApiClient';
import { useParamsFromQueryContext } from 'framework/hooks/useParamsFromQueryContext';

// enkel hier nodig
const DefaultColumns: SelectableColumn[] = [
	'serialNumber',
	'manufacturer',
	'model',
	'type',
	'salesListPrice',
	'vatPercentage',
	'patientIdentifier',
	'supplierIdentifier',
];

const quickFilterToTypeRecord: Record<QuickFilter, ProductCatalogItemType | undefined> = {
	all: undefined,
	hearingInstrument: 'HearingInstrument',
};

export const ViewProvider = ({ children }: any) => {
	const strings = useLocalization();
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);
	const colRecord = useMemo(() => createColumnRecord(strings), [strings]);
	const { productCatalogItemTypeRecord, propertyOfTypeRecord } = useContext(RecordContext);
	const headers = useMemo(
		() => createHeaders(colRecord, strings, productCatalogItemTypeRecord, propertyOfTypeRecord),
		[colRecord, productCatalogItemTypeRecord, propertyOfTypeRecord, strings]
	);
	const quickFilterRecord = useMemo(() => createQfRecord(strings), [strings]);

	const [qf, setQf] = useState<QuickFilter>('all');
	const { setParams } = useParamsFromQueryContext(QueryContext);

	useEffect(() => {
		setParams(params =>
			onResetToPage1({
				...params,
				keyFilterType: quickFilterToTypeRecord[qf],
				types: qf === 'all' ? params.types : [],
			})
		);
		// eslint-disable-next-line
	}, [qf]);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: colRecord,
				headers: headers,
				setColumns: setColumns,
				quickFilter: qf,
				setQuickFilter: setQf,
				quickFilterRecord: quickFilterRecord,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
