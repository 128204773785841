import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IStockEntriesQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	params: IStockEntriesQueryParams;
	onFilter: (params: IStockEntriesQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof IStockEntriesQueryParams)[] = [];

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.referenceDate}
				after={params.afterDate}
				before={params.beforeDate}
				setSelected={(after, before) => onFilter({ ...params, afterDate: after, beforeDate: before })}
			/>
		</FilterBar>
	);
};
