import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { RecordContext } from './RecordContext';
import { translateDocumentType } from './translateDocumentType';
import { translatePaymentMethodOrDefault } from './translatePaymentMethodOrDefault';
import {
	createArticlesInquiriesPrefilterKeyTypeRecord,
	createArticlesInquiryLineStatusTypeRecord,
	createArticlesInquiryStatusTypeRecord,
	createAscSortPropertyRecord,
	createBatteryTypeCaptionRecord,
	createBatteryTypeRecord,
	createCalendarEventRequiredPropertiesRecord,
	createCommissionRuleMethodRecord,
	createContactInfoTypeRecord,
	createCreditedStatusRecord,
	createCurrentLocationTypeRecord,
	createCustomerAccountTypeRecord,
	createDescSortPropertyRecord,
	createDocumentActionRecord,
	createDocumentPredefinedTypeRecord,
	createDocumentationTypeRecord,
	createExactOnlineCustomerAccountPrefilterRecord,
	createExactOnlineProductCatalogPrefilterRecord,
	createExactOnlineSalesPrefilterRecord,
	createExpenseOrIncomeTypeRecord,
	createFunctionalClaimRecord,
	createGenderRecord,
	createHearingAidTypeRecord,
	createInventoryItemExportRecord,
	createInventoryStatusRecord,
	createLeftRightOrStereoRecord,
	createLinkViewContextTypeRecord,
	createLoanStatusRecord,
	createLoansPrefilterKeyTypeRecord,
	createLocalizedLanguageRecord,
	createLogoutModeRecord,
	createLumpSumRecord,
	createNoahDeviceTabTypeRecord,
	createNoahDeviceTypeRecord,
	createNotificationTypeRecord,
	createOrderLineStatusRecord,
	createOrderPrefilterRecord,
	createOrderStatusRecord,
	createOrientationRecord,
	createPageClaimRecord,
	createPatientDetailOverviewLayoutElementTypeRecord,
	createPatientDetailTabTypeTitleRecord,
	createPatientRequiredPropertiesRecord,
	createPatientSearchModeRecord,
	createPatientSearchModeToLabelRecord,
	createPatientStatusRecord,
	createPaymentExportRecord,
	createPaymentStatusRecord,
	createPredefinedPaymentMethodRecord,
	createProductCatalogItemTypeRecord,
	createPropertyOfTypeRecord,
	createPurchaseExportRecord,
	createPurchaseTypeRecord,
	createPurchasesPrefilterRecord,
	createRepairPrefilterRecord,
	createRepairProcessStepRecord,
	createRepairStatusRecord,
	createRepairWarningRecord,
	createRfiaHandoverSaleGenerationModeRecord,
	createRfiaInvoiceProcessStepRecord,
	createRfiaStatusRecord,
	createRfiaWarningRecord,
	createRizivRefundableHearingInstrumentLinkStatusRecord,
	createRosaCalendarViewTypeRecord,
	createRosaEventStatusRecord,
	createRruleFrequencyRecord,
	createSaleTypeRecord,
	createSalesPrefilterRecord,
	createSalesSearchModeRecord,
	createScanErrorRecord,
	createScanSourceRecord,
	createSpeechImageTypeRecord,
	createStereoMonoContraLateralRecord,
	createStockTransactionTabTitleRecord,
	createTemplateMainContextRecord,
	createTemplateTypeRecord,
	createTenderProcessStatusRecord,
	createTendersPrefilterRecord,
	createTimeUnitRecord,
	createTransactionTypeRecord,
	createUserRoleRecord,
	createWorkflowTypeRecord,
	createWorkflowTypeRecordPlural,
} from './utils';

export const RecordProvider = ({ children }: any) => {
	const strings = useLocalization();
	const timeUnitRecord = useMemo(() => createTimeUnitRecord(strings), [strings]);
	const genderRecord = useMemo(() => createGenderRecord(strings), [strings]);
	const paymentStatusRecord = useMemo(() => createPaymentStatusRecord(strings), [strings]);
	const saleTypeRecord = useMemo(() => createSaleTypeRecord(strings), [strings]);
	const customerAccountTypeRecord = useMemo(() => createCustomerAccountTypeRecord(strings), [strings]);
	const predefinedPaymentMethodRecord = useMemo(() => createPredefinedPaymentMethodRecord(strings), [strings]);
	const paymentExportRecord = useMemo(() => createPaymentExportRecord(strings), [strings]);
	const productCatalogItemTypeRecord = useMemo(() => createProductCatalogItemTypeRecord(strings), [strings]);
	const noahDeviceTypeRecord = useMemo(() => createNoahDeviceTypeRecord(strings), [strings]);
	const templateTypeRecord = useMemo(() => createTemplateTypeRecord(strings), [strings]);
	const documentPredefinedTypeRecord = useMemo(() => createDocumentPredefinedTypeRecord(strings, templateTypeRecord), [strings, templateTypeRecord]);
	const documentActionRecord = useMemo(() => createDocumentActionRecord(strings), [strings]);
	const workflowTypeRecord = useMemo(() => createWorkflowTypeRecord(strings), [strings]);
	const workflowTypeRecordPlural = useMemo(() => createWorkflowTypeRecordPlural(strings), [strings]);
	const rfiaStatusRecord = useMemo(() => createRfiaStatusRecord(strings), [strings]);
	const orderStatusRecord = useMemo(() => createOrderStatusRecord(strings), [strings]);
	const repairStatusRecord = useMemo(() => createRepairStatusRecord(strings), [strings]);
	const loanStatusRecord = useMemo(() => createLoanStatusRecord(strings), [strings]);
	const transactionTypeRecord = useMemo(() => createTransactionTypeRecord(strings), [strings]);
	const inventoryStatusRecord = useMemo(() => createInventoryStatusRecord(strings), [strings]);
	const inventoryItemExportRecord = useMemo(() => createInventoryItemExportRecord(strings), [strings]);
	const currentLocationTypeRecord = useMemo(() => createCurrentLocationTypeRecord(strings), [strings]);
	const purchaseExportRecord = useMemo(() => createPurchaseExportRecord(strings), [strings]);
	const purchaseTypeRecord = useMemo(() => createPurchaseTypeRecord(strings), [strings]);
	const expenseOrIncomeTypeRecord = useMemo(() => createExpenseOrIncomeTypeRecord(strings), [strings]);
	const creditedStatusRecord = useMemo(() => createCreditedStatusRecord(strings), [strings]);
	const propertyOfTypeRecord = useMemo(() => createPropertyOfTypeRecord(strings), [strings]);
	const patientStatusRecord = useMemo(() => createPatientStatusRecord(strings), [strings]);
	const documentationTypeRecord = useMemo(() => createDocumentationTypeRecord(strings), [strings]);
	const localizedLanguageRecord = useMemo(() => createLocalizedLanguageRecord(strings), [strings]);
	const templateMainContextRecord = useMemo(() => createTemplateMainContextRecord(strings), [strings]);
	const linkViewContextTypeRecord = useMemo(() => createLinkViewContextTypeRecord(strings, templateMainContextRecord), [strings, templateMainContextRecord]);
	const leftRightOrStereoRecord = useMemo(() => createLeftRightOrStereoRecord(strings), [strings]);
	const speechImageTypeRecord = useMemo(() => createSpeechImageTypeRecord(strings), [strings]);
	const pageClaimRecord = useMemo(() => createPageClaimRecord(strings), [strings]);
	const userRoleRecord = useMemo(() => createUserRoleRecord(strings), [strings]);
	const functionalClaimRecord = useMemo(() => createFunctionalClaimRecord(strings), [strings]);
	const rfiaWarningRecord = useMemo(() => createRfiaWarningRecord(strings), [strings]);
	const repairWarningRecord = useMemo(() => createRepairWarningRecord(strings), [strings]);
	const scanSourceRecord = useMemo(() => createScanSourceRecord(strings), [strings]);
	const scanErrorRecord = useMemo(() => createScanErrorRecord(strings), [strings]);
	const commissionRuleMethodRecord = useMemo(() => createCommissionRuleMethodRecord(strings), [strings]);
	const patientRequiredPropertiesRecord = useMemo(() => createPatientRequiredPropertiesRecord(strings), [strings]);
	const rruleFrequencyRecord = useMemo(() => createRruleFrequencyRecord(strings), [strings]);
	const patientSearchModeRecord = useMemo(() => createPatientSearchModeRecord(strings), [strings]);
	const patientSearchModeToLabelRecord = useMemo(() => createPatientSearchModeToLabelRecord(strings), [strings]);
	const calendarEventRequiredPropertiesRecord = useMemo(() => createCalendarEventRequiredPropertiesRecord(strings), [strings]);
	const notificationTypeRecord = useMemo(() => createNotificationTypeRecord(strings), [strings]);
	const rizivRefundableHearingInstrumentLinkStatusRecord = useMemo(() => createRizivRefundableHearingInstrumentLinkStatusRecord(strings), [strings]);
	const lumSumpRecord = useMemo(() => createLumpSumRecord(strings), [strings]);
	const stereoMonoContralateralRecord = useMemo(() => createStereoMonoContraLateralRecord(strings), [strings]);
	const salesSearchModeRecord = useMemo(() => createSalesSearchModeRecord(strings), [strings]);
	const batteryTypeCaptionRecord = useMemo(() => createBatteryTypeCaptionRecord(strings), [strings]);
	const batteryTypeRecord = useMemo(() => createBatteryTypeRecord(strings), [strings]);
	const hearingAidTypeRecord = useMemo(() => createHearingAidTypeRecord(strings), [strings]);
	const orientationRecord = useMemo(() => createOrientationRecord(strings), [strings]);
	const contactInfoTypeRecord = useMemo(() => createContactInfoTypeRecord(strings), [strings]);
	const rosaCalendarViewTypeRecord = useMemo(() => createRosaCalendarViewTypeRecord(strings), [strings]);
	const rosaEventStatusRecord = useMemo(() => createRosaEventStatusRecord(strings), [strings]);
	const patientDetailTabTitleRecord = useMemo(() => createPatientDetailTabTypeTitleRecord(strings), [strings]);
	const patientDetailOverviewLayoutElementTypeRecord = useMemo(() => createPatientDetailOverviewLayoutElementTypeRecord(strings), [strings]);
	const orderLineStatusRecord = useMemo(() => createOrderLineStatusRecord(strings), [strings]);
	const stockTransactionTabTitleRecord = useMemo(() => createStockTransactionTabTitleRecord(strings), [strings]);
	const articlesInquiryLineStatusTypeRecord = useMemo(() => createArticlesInquiryLineStatusTypeRecord(strings), [strings]);
	const articlesInquiryStatusTypeRecord = useMemo(() => createArticlesInquiryStatusTypeRecord(strings), [strings]);
	const orderPrefilterKeyTypeRecord = useMemo(() => createOrderPrefilterRecord(strings), [strings]);
	const loansPrefilterKeyTypeRecord = useMemo(() => createLoansPrefilterKeyTypeRecord(strings), [strings]);
	const articlesInquiriesPrefilterKeyTypeRecord = useMemo(() => createArticlesInquiriesPrefilterKeyTypeRecord(strings), [strings]);
	const rfiaInvoiceProcessStepRecord = useMemo(() => createRfiaInvoiceProcessStepRecord(strings), [strings]);
	const rfiaHandoverSaleGenerationModeRecord = useMemo(() => createRfiaHandoverSaleGenerationModeRecord(strings), [strings]);
	const purchasesPrefilterRecord = useMemo(() => createPurchasesPrefilterRecord(strings), [strings]);
	const salesPrefilterRecord = useMemo(() => createSalesPrefilterRecord(strings), [strings]);
	const ascSortPropertyRecord = useMemo(() => createAscSortPropertyRecord(strings), [strings]);
	const descSortPropertyRecord = useMemo(() => createDescSortPropertyRecord(strings), [strings]);
	const tenderProcessStatusRecord = useMemo(() => createTenderProcessStatusRecord(strings), [strings]);
	const tendersPrefilterRecord = useMemo(() => createTendersPrefilterRecord(strings), [strings]);
	const exactOnlineSalesPrefilterRecord = useMemo(() => createExactOnlineSalesPrefilterRecord(strings), [strings]);
	const exactOnlineProductCatalogPrefilterRecord = useMemo(() => createExactOnlineProductCatalogPrefilterRecord(strings), [strings]);
	const exactOnlineCustomerAccountPrefilterRecord = useMemo(() => createExactOnlineCustomerAccountPrefilterRecord(strings), [strings]);
	const repairProcessStepRecord = useMemo(() => createRepairProcessStepRecord(strings), [strings]);
	const repairPrefilterRecord = useMemo(() => createRepairPrefilterRecord(strings), [strings]);
	const noahDevicesTabTypeRecord = useMemo(() => createNoahDeviceTabTypeRecord(strings), [strings]);
	const logoutModeRecord = useMemo(() => createLogoutModeRecord(strings), [strings]);

	return (
		<RecordContext.Provider
			value={{
				timeUnitRecord: timeUnitRecord,
				genderRecord: genderRecord,
				paymentStatusRecord: paymentStatusRecord,
				saleTypeRecord: saleTypeRecord,
				customerAccountTypeRecord: customerAccountTypeRecord,
				predefinedPaymentMethodRecord: predefinedPaymentMethodRecord,
				translatePaymentMethod: method => translatePaymentMethodOrDefault(method, predefinedPaymentMethodRecord),
				translateDocumentType: doc => translateDocumentType(doc, documentPredefinedTypeRecord),
				paymentExportRecord: paymentExportRecord,
				productCatalogItemTypeRecord: productCatalogItemTypeRecord,
				noahDeviceTypeRecord: noahDeviceTypeRecord,
				templateTypeRecord: templateTypeRecord,
				documentPredefinedTypeRecord: documentPredefinedTypeRecord,
				documentActionRecord: documentActionRecord,
				workflowTypeRecord: workflowTypeRecord,
				rfiaStatusRecord: rfiaStatusRecord,
				orderStatusRecord: orderStatusRecord,
				repairProcessStatusRecord: repairStatusRecord,
				loanStatusRecord: loanStatusRecord,
				transactionTypeRecord: transactionTypeRecord,
				inventoryStatusRecord: inventoryStatusRecord,
				inventoryItemExportRecord: inventoryItemExportRecord,
				currentLocationTypeRecord: currentLocationTypeRecord,
				purchaseExportRecord: purchaseExportRecord,
				purchaseTypeRecord: purchaseTypeRecord,
				expenseOrIncomeTypeRecord: expenseOrIncomeTypeRecord,
				creditedStatusRecord: creditedStatusRecord,
				propertyOfTypeRecord: propertyOfTypeRecord,
				patientStatusRecord: patientStatusRecord,
				documentationTypeRecord: documentationTypeRecord,
				workflowTypeRecordPlural: workflowTypeRecordPlural,
				localizedLanguageRecord: localizedLanguageRecord,
				templateMainContextRecord: templateMainContextRecord,
				linkViewContextTypeRecord: linkViewContextTypeRecord,
				leftRightOrStereoRecord: leftRightOrStereoRecord,
				speechImageTypeRecord: speechImageTypeRecord,
				pageClaimRecord: pageClaimRecord,
				userRoleRecord: userRoleRecord,
				functionalClaimRecord: functionalClaimRecord,
				rfiaWarningRecord: rfiaWarningRecord,
				repairWarningRecord: repairWarningRecord,
				scanSourceRecord: scanSourceRecord,
				scanErrorRecord: scanErrorRecord,
				commissionRuleMethodRecord: commissionRuleMethodRecord,
				patientRequiredPropertiesRecord: patientRequiredPropertiesRecord,
				rruleFrequencyRecord: rruleFrequencyRecord,
				patientSearchModeRecord: patientSearchModeRecord,
				patientSearchModeToLabelRecord: patientSearchModeToLabelRecord,
				calendarEventRequiredPropertiesRecord: calendarEventRequiredPropertiesRecord,
				notificationTypeRecord: notificationTypeRecord,
				rizivRefundableHearingInstrumentLinkStatusRecord: rizivRefundableHearingInstrumentLinkStatusRecord,
				lumSumpRecord: lumSumpRecord,
				stereoMonoContralateralRecord: stereoMonoContralateralRecord,
				salesSearchModeRecord: salesSearchModeRecord,
				batteryTypeCaptionRecord: batteryTypeCaptionRecord,
				batteryTypeRecord: batteryTypeRecord,
				hearingAidTypeRecord: hearingAidTypeRecord,
				orientationRecord: orientationRecord,
				contactInfoTypeRecord: contactInfoTypeRecord,
				rosaCalendarViewTypeRecord: rosaCalendarViewTypeRecord,
				rosaEventStatusRecord: rosaEventStatusRecord,
				patientDetailTabTitleRecord: patientDetailTabTitleRecord,
				patientDetailOverviewLayoutElementTypeRecord: patientDetailOverviewLayoutElementTypeRecord,
				orderLineStatusRecord: orderLineStatusRecord,
				stockTransactionTabTitleRecord: stockTransactionTabTitleRecord,
				articlesInquiryLineStatusTypeRecord: articlesInquiryLineStatusTypeRecord,
				articlesInquiryStatusTypeRecord: articlesInquiryStatusTypeRecord,
				orderPrefilterRecord: orderPrefilterKeyTypeRecord,
				loansPrefilterKeyTypeRecord: loansPrefilterKeyTypeRecord,
				articlesInquiriesPrefilterRecord: articlesInquiriesPrefilterKeyTypeRecord,
				rfiaInvoiceProcessStepRecord: rfiaInvoiceProcessStepRecord,
				rfiaHandoverSaleGenerationModeRecord: rfiaHandoverSaleGenerationModeRecord,
				purchasesPrefilterRecord: purchasesPrefilterRecord,
				ascSortPropertyRecord: ascSortPropertyRecord,
				descSortPropertyRecord: descSortPropertyRecord,
				salesPrefilterRecord: salesPrefilterRecord,
				tenderProcessStatusRecord: tenderProcessStatusRecord,
				tendersPrefilterRecord: tendersPrefilterRecord,
				exactOnlineCustomerAccountPrefilterRecord: exactOnlineCustomerAccountPrefilterRecord,
				exactOnlineProductCatalogPrefilterRecord: exactOnlineProductCatalogPrefilterRecord,
				exactOnlineSalesPrefilterRecord: exactOnlineSalesPrefilterRecord,
				repairProcessStepRecord: repairProcessStepRecord,
				repairPrefilterRecord: repairPrefilterRecord,
				noahDevicesTabTypeRecord: noahDevicesTabTypeRecord,
				logoutModeRecord: logoutModeRecord,
			}}>
			{children}
		</RecordContext.Provider>
	);
};
