import { useTheme } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { useStateBoolean } from '../hooks/useStateBool';

interface IProps {
	onClick: VoidFunction;
	textColor?: 'primary' | 'text';
	children: any;
}

export const TextWithOpenInNew = ({ children, textColor = 'primary', onClick }: IProps) => {
	const [isHover, enter, leave] = useStateBoolean(false);
	const theme = useTheme();
	return (
		<div
			className='df-row-ac'
			style={{ cursor: 'pointer' }}
			onMouseEnter={enter}
			onMouseLeave={leave}
			onClick={onClick}>
			<div style={{ color: textColor === 'primary' ? theme.palette.primary.main : 'inherit' }}>{children}</div>
			<OpenInNewIcon
				fontSize='small'
				style={{ color: theme.palette.primary.main, visibility: isHover ? 'inherit' : 'hidden', marginLeft: theme.spacing(0.5) }}
			/>
		</div>
	);
};
