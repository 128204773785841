import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { SelectField } from 'framework/components/select/SelectField';
import { ICustomerAccountLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps extends DialogProps {
	lines: ICustomerAccountLine[];
	confirm: (customerAccountLineId: string) => void;
	cancel: VoidFunction;
}

export const SelectCustomerAccountLineForm = ({ confirm, cancel, lines, ...rest }: IProps) => {
	const [customerAccountLine, setCustomerAccountLine] = useState<ICustomerAccountLine>();
	const strings = useLocalization();

	return (
		<Dialog
			{...rest}
			scroll='paper'>
			<DialogTitle>{strings.selectWhat(strings.sale)}</DialogTitle>
			<DialogContent dividers>
				<SelectField<ICustomerAccountLine>
					options={lines}
					value={customerAccountLine}
					onChange={setCustomerAccountLine}
					label={strings.sale}
					renderValue={t => t.salesReference}
					getKey={t => t.id}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button disabled>{strings.previous}</Button>
				<Button
					onClick={customerAccountLine ? () => confirm(customerAccountLine.id) : undefined}
					disabled={customerAccountLine === undefined}
					color='primary'>
					{strings.select}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
