import { QuickFilter } from './ProductCatalogList';
import { SelectableColumn } from './SelectableColumn';

export const SelectableColumns: Record<QuickFilter, SelectableColumn[]> = {
	all: [
		'id',
		'created',
		'lastUpdated',
		'category',
		'subcategory',
		'salesListPrice',
		'vatPercentage',
		'isTangible',
		'isHearingInstrument',
		'isTailorMade',
		'isElectronics',
		'hasBatteries',
		'hasSerialNumber',
		'identifier',
		'supplierIdentifierCsv',
		'type',
		'purchaseListPrice',
		'internalReference',
		'manufacturerReferencesCsv',
		'labels',
	],
	intangible: [
		'id',
		'created',
		'lastUpdated',
		'name',
		'category',
		'subcategory',
		'salesListPrice',
		'vatPercentage',
		'internalReference',
		'manufacturerReferencesCsv',
		'labels',
	],
	hearingInstruments: [
		'id',
		'created',
		'lastUpdated',
		'manufacturer',
		'model',
		'category',
		'subcategory',
		'salesListPrice',
		'vatPercentage',
		'internalReference',
		'manufacturerReferencesCsv',
		'hasBatteries',
		'supplierIdentifierCsv',
		'identifier',
		'purchaseListPrice',
		'rizivHearingAidDefinitionV2IdentificationCodesCsv',
		'labels',
	],
};
