import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BugReportOutlinedIcon from '@material-ui/icons/BugReportOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import RemoveShoppingCartOutlinedIcon from '@material-ui/icons/RemoveShoppingCartOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { CreditPurchaseFromInventoryItemForm } from 'app/main/financial/purchases/forms/creditNote/CreditPurchaseFromInventoryItemForm';
import { PurchaseFromInventoryItemForm } from 'app/main/financial/purchases/forms/register/PurchaseFromInventoryItemForm';
import { RepairFromInventoryItemForm } from 'app/main/processes/repairs/forms/RepairFromInventoryItemForm';
import { calcRepairDetailRoute } from 'app/main/processes/ProcessesRoutes';
import { ActionCardButton } from 'framework/components/buttons/ActionCardButton';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IInventoryItem, inventoryItemsCommand_registerAsBroken, inventoryItemsCommand_undoRegisterAsBroken } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router';
import { DefaultActionsCard } from 'shared/DefaultActionsCard';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RegisterDeliveryFromInventoryItemRequestForm } from '../../stockTransactions/deliveries/forms/RegisterDeliveryFromInventoryItemRequestForm';
import { ReturnFromInventoryItemForm } from '../../stockTransactions/returns/forms/ReturnFromInventoryItemForm';
import { StockMovementFromInventoryItemForm } from '../../stockTransactions/stockMovements/forms/StockMovementFromInventoryItemForm';
import { InventoryItemRegisterAsSoldForm } from '../forms/InventoryItemRegisterAsSoldForm';
import { InventoryItemUndoRegisterAsSoldForm } from '../forms/InventoryItemUndoRegisterAsSoldForm';
import UndoIcon from '@material-ui/icons/Undo';

interface IProps {
	item: IInventoryItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

// TODO translate
export const TransactionActionsCard = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();

	const onStockMovement = () => {
		open(
			<StockMovementFromInventoryItemForm
				open
				inventoryItemId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onPurchase = () => {
		open(
			<PurchaseFromInventoryItemForm
				open
				inventoryItemId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDelivery = () => {
		open(
			<RegisterDeliveryFromInventoryItemRequestForm
				open
				inventoryItemId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onCreditPurchase = () => {
		open(
			<CreditPurchaseFromInventoryItemForm
				open
				inventoryItemId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onReturn = () => {
		open(
			<ReturnFromInventoryItemForm
				open
				inventoryItemId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onRepair = () => {
		open(
			<RepairFromInventoryItemForm
				item={item}
				confirm={id => {
					confirm();
					push(calcRepairDetailRoute(id));
				}}
				cancel={cancel}
			/>
		);
	};

	const onRegisterAsSold = () => {
		open(
			<InventoryItemRegisterAsSoldForm
				open
				inventoryItemId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onUndoRegisterAsSold = () => {
		open(
			<InventoryItemUndoRegisterAsSoldForm
				open
				inventoryItemId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onRegisterAsBroken = () => {
		open(
			<AlertDialog
				open
				content={strings.registerAsBroken}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => inventoryItemsCommand_registerAsBroken({ inventoryItemId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onUndoRegisterAsBroken = () => {
		open(
			<AlertDialog
				open
				content={`Registreren als defect ongedaan maken?`}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => inventoryItemsCommand_undoRegisterAsBroken({ inventoryItemId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<DefaultActionsCard
			title={strings.actions}
			style={style}>
			<ActionCardButton
				startIcon={<PinDropOutlinedIcon />}
				onClick={onStockMovement}
				disabled={item.canSelectForStockMovement === false}>
				{strings.toAnotherLocation}
			</ActionCardButton>
			<ActionCardButton
				startIcon={<ShoppingCartOutlinedIcon />}
				onClick={onPurchase}
				disabled={item.canSelectForPurchase === false}>
				{strings.purchaseVerb}
			</ActionCardButton>
			<ActionCardButton
				startIcon={<RemoveShoppingCartOutlinedIcon />}
				onClick={onCreditPurchase}
				disabled={item.canSelectForCreditPurchase === false}>
				{strings.creditPurchaseVerb}
			</ActionCardButton>
			<ActionCardButton
				startIcon={<KeyboardReturnIcon />}
				onClick={onReturn}
				disabled={item.canSelectForReturn === false}>
				{strings.returnToSupplier}
			</ActionCardButton>
			<ActionCardButton
				startIcon={<BuildOutlinedIcon />}
				onClick={onRepair}
				disabled={item.canSelectForRepair === false}>
				{strings.startRepair}
			</ActionCardButton>
			<ActionCardButton
				startIcon={<AttachMoneyIcon />}
				onClick={onRegisterAsSold}
				disabled={item.canRegisterAsSold === false}>
				{strings.registerAsSold}
			</ActionCardButton>
			<ActionCardButton
				startIcon={<UndoIcon />}
				onClick={onUndoRegisterAsSold}
				disabled={item.canUndolRegisterAsSold === false}>
				{`(Historische) verkoop ongedaan maken`}
			</ActionCardButton>
			<ActionCardButton
				startIcon={<BugReportOutlinedIcon />}
				onClick={onRegisterAsBroken}
				disabled={item.canRegisterAsBroken === false}>
				{strings.registerAsBroken}
			</ActionCardButton>
			<ActionCardButton
				startIcon={<BugReportOutlinedIcon />}
				onClick={onUndoRegisterAsBroken}
				disabled={item.canUndoRegisterAsBroken === false}>
				{`Registreren als defect ongedaan maken`}
			</ActionCardButton>
			<ActionCardButton
				startIcon={<LocalShippingOutlinedIcon />}
				onClick={onDelivery}
				disabled={item.isTailorMade || item.isDelivered}>
				{strings.registerDelivery}
			</ActionCardButton>
		</DefaultActionsCard>
	);
};
