import React from 'react';
import {
	IInventoryItem,
	IInventoryItemsQueryParamsForSale,
	inventoryItemsQuery_queryForSale,
	IProductCatalogItem,
	IProductCatalogQueryParamsForSale,
	IQueryResult,
	ISalesLineForAssuranceDto,
	IStockAtLocation,
} from 'gen/ApiClient';
import { useDialogsContext } from '../dialogs/useDialogsContext';
import { ISearchAndSelectLineWithAssurance } from './assurance/ISearchAndSelectLineWithAssurance';
import { SelectProductsToAssureDialog } from './assurance/SelectProductsToAssureDialog';
import { SearchWithTabsContainer } from './SearchWithTabsContainer';

interface IProps<TLine extends ISearchAndSelectLineWithAssurance> {
	lines: TLine[];
	wizardContent: JSX.Element;
	canSelectInventoryItemF: (item: IInventoryItem) => boolean;
	iiParams: IInventoryItemsQueryParamsForSale;
	setIIParams: React.Dispatch<React.SetStateAction<IInventoryItemsQueryParamsForSale>>;
	iiQueryResult: IQueryResult<IInventoryItem> | undefined;
	pcParams: IProductCatalogQueryParamsForSale;
	setPcParams: React.Dispatch<React.SetStateAction<IProductCatalogQueryParamsForSale>>;
	pcQueryResult: IQueryResult<IProductCatalogItem> | undefined;
	defaultIIParams: IInventoryItemsQueryParamsForSale;
	onAddPc: (item: IProductCatalogItem, stockAtLocations: IStockAtLocation[]) => void;
	onAddAssuredLines?: (item: IProductCatalogItem, line: (TLine | ISalesLineForAssuranceDto)[]) => void;
	onAddII: (item: IInventoryItem, pc: IProductCatalogItem) => void;
	customerAccountId: string | undefined;
	onCreditPc?: (item: IProductCatalogItem, stockAtLocations: IStockAtLocation[]) => void;
	onCreditIi?: (item: IInventoryItem, pc: IProductCatalogItem) => void;
}

export const SearchProductCatalogOrInventoryItemForSaleTabs = <TLine extends ISearchAndSelectLineWithAssurance>({
	customerAccountId,
	onAddAssuredLines,
	...props
}: IProps<TLine>) => {
	const { open, confirm, cancel } = useDialogsContext();

	const onAddAssurance = (item: IProductCatalogItem) => {
		open(
			<SelectProductsToAssureDialog<TLine>
				open
				assurance={item}
				cancel={cancel}
				lines={props.lines}
				customerAccountId={customerAccountId}
				onAdd={lines => {
					confirm();
					onAddAssuredLines!(item, lines);
				}}
			/>
		);
	};

	return (
		<SearchWithTabsContainer<TLine, IInventoryItemsQueryParamsForSale, IProductCatalogQueryParamsForSale>
			{...props}
			queryInventoryItemF={inventoryItemsQuery_queryForSale}
			productCatalogFilterType='all'
			onAddAssurance={onAddAssuredLines === undefined ? undefined : onAddAssurance}
		/>
	);
};
