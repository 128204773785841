import { formatCurrency } from 'framework/utils/formatCurrency';

export const createDonutChartOptions = (colors: string[], keys: string[], total: number): ApexCharts.ApexOptions => {
	return {
		// responsive: [{
		//     breakpoint: 400,
		//     options: {
		//         chart: {
		//             width: 200
		//         },
		//         legend: {
		//             position: 'bottom'
		//         }
		//     }
		// }],
		legend: {
			horizontalAlign: 'right',
			show: true,
			itemMargin: {
				horizontal: 0,
			},
			position: 'right',
			fontFamily: 'inherit',
			labels: {
				useSeriesColors: false,
			},
			formatter: function (seriesName, opts) {
				return [seriesName, ` - `, formatCurrency(opts.w.globals.series[opts.seriesIndex])] as any;
			},
			onItemClick: {
				toggleDataSeries: true,
			},
			width: 300,
		},
		colors: colors,
		labels: keys,
		dataLabels: {
			enabled: false,
		},
		plotOptions: {
			pie: {
				expandOnClick: true,
				donut: {
					size: '90%',
					labels: {
						show: true,
						value: {
							fontFamily: 'inherit',
							fontWeight: 'bold',
							formatter: val => formatCurrency(Number(val)),
						},
						total: {
							show: true,
							showAlways: false,
							label: 'Total',
							fontFamily: 'inherit',
							color: '#637381',
							fontSize: '14px',
							fontWeight: 600,
							formatter: _ => formatCurrency(total),
						},
					},
				},
			},
		},
		tooltip: {
			enabled: false,
			y: {
				formatter: (val, opts) => `${formatCurrency(val)} (${Number(opts.globals.seriesPercent[opts.dataPointIndex]).toFixed(2)} %)`,
			},
			fillSeriesColor: false,
			marker: { show: true },
			theme: 'light',
		},
	};
};
