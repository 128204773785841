import { Checkbox, Dialog, DialogContent, DialogProps, DialogTitle, Divider, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { ChipWithAvatar } from 'framework/components/ChipWithAvatar';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { toggleSelect } from 'framework/utils/array/toggleSelect';
import { IRizivHearingAidDefinitionV2Dto, rizivHearingAidDefinitionsV2Query_multiple } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useApiEffect } from 'framework/hooks/useApiEffect';

interface IProps extends DialogProps {
	confirm: (items: IRizivHearingAidDefinitionV2Dto[]) => Promise<void>;
	cancel: VoidFunction;
	identificationCodes: number[];
}

export const SelectCodesDialog = ({ confirm, cancel, identificationCodes, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [selected, setSelected] = useState<IRizivHearingAidDefinitionV2Dto[]>([]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [options] = useApiEffect(rizivHearingAidDefinitionsV2Query_multiple, identificationCodes);

	React.useEffect(() => {
		if (options) {
			setSelected(options);
		}
	}, [options]);

	const onConfirm = async () => {
		setIsSubmitting(true);
		await confirm(selected);
		setIsSubmitting(false);
	};

	if (options === undefined) {
		return <></>;
	}

	return (
		<Dialog
			{...rest}
			scroll='paper'
			fullWidth>
			<DialogTitle>
				<div>{strings.selectOneOrMultipleSuppliers}</div>
			</DialogTitle>
			<DialogContent
				dividers
				style={{ padding: '0px' }}>
				<ListItem
					dense
					button
					onClick={() => (selected.length === options.length ? setSelected([]) : setSelected(options))}>
					<ListItemIcon>
						<Checkbox
							indeterminate={selected.length > 0 && selected.length < options.length}
							checked={selected.length === options.length}
						/>
					</ListItemIcon>
					<ListItemText primary={selected.length === options.length ? strings.selectNone : strings.selectAll} />
				</ListItem>
				<Divider variant='middle' />
				<List dense>
					{options.map(t => (
						<ListItem
							key={t.identificationCode.toString()}
							button
							onClick={() => setSelected(toggleSelect(t, selected))}>
							<ListItemIcon>
								<Checkbox checked={selected.indexOf(t) !== -1} />
							</ListItemIcon>
							<ListItemText
								primary={
									<div className='df-row-ac'>
										<div style={{ marginRight: theme.spacing(1) }}>
											<b>{t.identificationCode}</b> {t.manufacturer} {t.name}
										</div>
										<ChipWithAvatar label={t.distributor ?? ''} />
									</div>
								}
							/>
						</ListItem>
					))}
				</List>
			</DialogContent>
			<CancelSubmitDialogActions
				cancel={cancel}
				onSubmit={onConfirm}
				isValid={selected.length > 0}
				submitText={strings.selectWhat(selected.length.toString())}
				isSubmitting={isSubmitting}
				variantSubmitButton='outlined'
			/>
		</Dialog>
	);
};
