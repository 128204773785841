import { Dialog, DialogProps, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import React, { useContext } from 'react';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { PaperOutlined } from 'framework/table/PaperOutlined';
import { DocumentKeyType, IDocumentKeyDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { FormattingGridComponent } from './FormattingGridComponent';
import { KeysFormattingContext } from './KeysFormattingContext';

interface IProps extends DialogProps {
	documentKey: IDocumentKeyDto;
	close: VoidFunction;
}

const labelGridSize = 6;

export const DocumentKeyDialog = ({ documentKey, close, ...rest }: IProps) => {
	const strings = useLocalization();
	const { stringFormattingRecord, numberFormattingRecord, dateFormattingRecord } = useContext(KeysFormattingContext);

	return (
		<Dialog
			{...rest}
			onClose={close}
			fullWidth>
			<DialogContentGrid close={close}>
				<PropertyInGrid
					labelGridSize={labelGridSize}
					label={strings.description}
					value={documentKey.description}
				/>
				<PropertyInGrid
					labelGridSize={labelGridSize}
					label='Key'
					value={documentKey.keyWithBrackets}
					customAction={() => navigator.clipboard.writeText(documentKey.keyWithBrackets)}
					customActionIcon={<FileCopyOutlinedIcon />}
				/>
				<PropertyInGrid
					labelGridSize={labelGridSize}
					label={strings.type}
					value={documentKey.type}
				/>
				{documentKey.isList === false && documentKey.isImage === false && <GridDividerWithCaption caption={strings.formattingOptions} />}
				{documentKey.type === ('String' as DocumentKeyType) && (
					<FormattingGridComponent
						documentKey={documentKey}
						labelGridSize={labelGridSize}
						record={stringFormattingRecord}
					/>
				)}
				{documentKey.type === ('Number' as DocumentKeyType) && (
					<FormattingGridComponent
						documentKey={documentKey}
						labelGridSize={labelGridSize}
						record={numberFormattingRecord}
					/>
				)}
				{documentKey.type === ('Date' as DocumentKeyType) && (
					<FormattingGridComponent
						documentKey={documentKey}
						labelGridSize={labelGridSize}
						record={dateFormattingRecord}
					/>
				)}
				{documentKey.type === ('Image' as DocumentKeyType) && (
					<>
						<GridDividerWithCaption caption={strings.image} />
						<Grid
							item
							xs={12}>
							<LightBulbNotification style={{ whiteSpace: 'pre-line' }}>{strings.imageFormattingHelperText(documentKey.key)}</LightBulbNotification>
						</Grid>
					</>
				)}
				{documentKey.type === ('List' as DocumentKeyType) && documentKey.tableKeys.length > 0 && (
					<>
						<GridDividerWithCaption caption='Tabel' />
						<Grid
							item
							xs={12}>
							<LightBulbNotification style={{ whiteSpace: 'pre-line' }}>{strings.tableFormattingHelperText}</LightBulbNotification>
						</Grid>
						<Grid
							item
							xs={12}>
							<TableContainer component={PaperOutlined}>
								<Table size='small'>
									<TableBody>
										<TableRow>
											<TableCell style={{ borderRight: '1px solid rgb(224, 224, 224)' }}>{`${strings.heading} ${strings.column} 1`}</TableCell>
											<TableCell style={{ borderRight: '1px solid rgb(224, 224, 224)' }}>{`${strings.heading} ${strings.column} 2`}</TableCell>
											<TableCell style={{ borderRight: '1px solid rgb(224, 224, 224)' }}>...</TableCell>
											<TableCell>{`${strings.heading} ${strings.column} n`}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												style={{
													borderBottom: 'none',
													borderRight: '1px solid rgb(224, 224, 224)',
												}}>{`${documentKey.keyWithBrackets}${documentKey.tableKeys[0].keyWithBrackets}`}</TableCell>
											<TableCell style={{ borderBottom: 'none', borderRight: '1px solid rgb(224, 224, 224)' }}>
												{documentKey.tableKeys[0].keyWithBrackets}
											</TableCell>
											<TableCell style={{ borderBottom: 'none', borderRight: '1px solid rgb(224, 224, 224)' }}>
												{documentKey.tableKeys[0].keyWithBrackets}
											</TableCell>
											<TableCell style={{ borderBottom: 'none' }}>{documentKey.tableKeys[0].keyWithBrackets}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						<GridDividerWithCaption caption='Keys' />
						{documentKey.tableKeys.map(t => (
							<PropertyInGrid
								labelGridSize={labelGridSize}
								label={t.description}
								value={t.keyWithBrackets}
								customAction={() => navigator.clipboard.writeText(t.keyWithBrackets)}
								customActionIcon={<FileCopyOutlinedIcon />}
							/>
						))}
					</>
				)}
			</DialogContentGrid>
		</Dialog>
	);
};
