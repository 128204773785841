import { useContext, useEffect } from 'react';
import { RosaCalendarContext } from './RosaCalendarContext';

export function useRosaCalendarContext() {
	const { init, ...rest } = useContext(RosaCalendarContext);

	useEffect(() => {
		console.log('init');
		init();
	}, [init]);

	return { ...rest };
}
