import { DialogProps } from '@material-ui/core';
import React from 'react';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import {
	IProductCatalogItem,
	IRizivHearingAidDefinitionV2IdAndDistributorDto,
	IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto,
	productCatalogCommand_patchRizivHearingAidDefinitionV2IdentificationCodes,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RizivRefundableHearingInstrumentDialog } from 'shared/rizivRefundableHearingInstruments/RizivRefundableHearingInstrumentDialog';
import { SelectCodesDialog } from 'shared/rizivRefundableHearingInstruments/SelectCodesDialog';
import { SelectRizivRefundableHearingInstrumentForm } from 'shared/rizivRefundableHearingInstruments/SelectRizivRefundableHearingInstrumentForm';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	item: IProductCatalogItem;
}

export const LinkRizivRefundableHearingInstrumentForm = ({ item, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const { open, confirm: confirmX, cancel: cancelX, isStacked } = useDialogsContext(confirm);
	const [link, isSubmitting] = useFormSubmit(productCatalogCommand_patchRizivHearingAidDefinitionV2IdentificationCodes);

	const onLink = async (codes: number[]): Promise<void> => {
		const r = await link({ productCatalogItemId: item.id, rizivHearingAidDefinitionV2IdentificationCodes: codes });
		if (r.isSuccess) {
			confirmX();
		}
	};

	const onSelectDefinition = (group: IRizivHearingAidDefinitionV2ManufacturerAndNameGroupDto) => {
		if (group.hasMultipleDistributors) {
			open(
				<SelectCodesDialog
					{...rest}
					cancel={cancelX}
					confirm={items => onLink(items.map(t => t.identificationCode))}
					identificationCodes={group.identificationCodes}
				/>
			);
		} else {
			onLink(group.identificationCodes);
		}
	};

	const onClickDetail = (item: IRizivHearingAidDefinitionV2IdAndDistributorDto) => {
		open(
			<RizivRefundableHearingInstrumentDialog
				{...rest}
				identificationCode={item.identificationCode}
				cancel={cancelX}
				select={() => onLink([item.identificationCode])}
			/>
		);
	};

	return (
		<SelectRizivRefundableHearingInstrumentForm
			{...rest}
			open={rest.open && isStacked === false}
			isSubmitting={isSubmitting}
			cancel={cancel}
			jxsTitle={<div>{strings.selectCodeFor(item.manufacturerCommaModel ?? '')}</div>}
			initialSearch={item.manufacturerCommaModel}
			onClickSelect={onSelectDefinition}
			onClickDetail={onClickDetail}
		/>
	);
};
