import { DialogProps } from '@material-ui/core';
import { IRequestForInsuranceAllowance } from 'gen/ApiClient';
import React, { useState } from 'react';
import { MainMedicalTreatmentWizardForm } from './MainMedicalTreatmentWizardForm';
import { PseudoMedicalTreatmentWizardForm } from './PseudoMedicalTreatmentWizardForm';

interface IProps extends DialogProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const MainAndPseudoMedicalTreatmentWizardForm = ({ confirm, ...props }: IProps) => {
	const [state, setState] = useState<'main' | 'pseudo'>('main');

	if (state === 'main') {
		return (
			<MainMedicalTreatmentWizardForm
				{...props}
				confirm={() => setState('pseudo')}
			/>
		);
	} else if (state === 'pseudo') {
		return (
			<PseudoMedicalTreatmentWizardForm
				{...props}
				confirm={confirm}
				cancel={confirm}
			/>
		);
	} else {
		return <div></div>;
	}
};
