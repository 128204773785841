import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormIntegerFieldWithUnit } from 'framework/forms/FormIntegerFieldWithUnit';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { ITimeModel, TimeUnit } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import { ISharedDialogProps } from '../ISharedDialogProps';
import { useRenderTimeUnit } from './useRenderTimeUnit';

const createSchema = (strings: IStrings): yup.ObjectSchema<ITimeModel> => {
	return yup
		.object<ITimeModel>({
			unit: yup.string().required(strings.formRequired(strings.unit)),
			value: yup.number().required(strings.formRequired(strings.amount)),
		})
		.defined();
};

interface IProps extends ISharedDialogProps<ITimeModel> {
	options: TimeUnit[];
	title: string;
	label: string;
	defaultUnit: TimeUnit;
}

export const TimeModelForm = ({ title, label, defaultUnit, model, submitFunction, cancel, confirm, options, successMessage, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(submitFunction);
	const renderTimeUnit = useRenderTimeUnit();

	const handleSubmit = async (values: ITimeModel, helpers: FormikHelpers<ITimeModel>) => {
		const r = await update(values);
		if (handleFormResponse(r, helpers)) {
			if (successMessage) {
				notify(successMessage);
			}
			confirm();
		}
	};

	return (
		<Formik<ITimeModel>
			initialValues={model ?? { unit: undefined as any, value: undefined as any }}
			onSubmit={handleSubmit}
			validationSchema={createSchema(strings)}>
			<Form>
				<Dialog
					fullWidth
					maxWidth='xs'
					{...rest}>
					<DialogTitle>{title}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormIntegerFieldWithUnit<ITimeModel, TimeUnit>
							name='value'
							unitName='unit'
							units={options}
							label={label}
							defaultUnit={defaultUnit}
							renderUnit={(val, unit) => renderTimeUnit(val, unit)}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={strings.update}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
