import { DragAndDropContainer } from 'framework/components/dnd/DragAndDropContainer';
import { LeftRightDividedContentScrollYRight } from 'framework/components/LeftRightDividedContentScrollYRight';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { addOrUpdateQuantity } from 'framework/utils/array/addOrUpdateQuantity';
import { updateArray } from 'framework/utils/array/updateArray';
import {
	IInventoryItem,
	IInventoryItemWithProductCatalogItemDto,
	ILocationSummary,
	inventoryItemsQuery_reservedByPatientIds,
	IProductCatalogItem,
	IStockAtLocation,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SearchProductsForLoanOrTrialTabs } from 'shared/searchAndSelect/SearchProductsForLoanOrTrialTabs';
import { ImportFromNoahWizardButton } from 'shared/searchAndSelect/wizard/ImportFromNoahWizardButton';
import { ReservedWizardButton } from 'shared/searchAndSelect/wizard/ReservedWizardButton';
import { WizardDivider } from 'shared/searchAndSelect/wizard/WizardDivider';
import { ImportNoahDevicesByPatientForm } from 'shared/searchAndSelect/wizardImportNoahDevices/ImportNoahDevicesByPatientForm';
import { SelectedReservedArticles } from 'shared/searchAndSelect/wizardImportReserved/SelectedReservedArticles';
import { SearchContext } from './context/SearchContext';
import { ILine } from './ILine';
import { TrialLineComponent } from './TrialLineComponent';
import { inventoryItemToLine } from './utils/inventoryItemToLine';
import { productCatalogItemToLine } from './utils/productCatalogItemToLine';

interface IProps {
	patientId: string;
	lines: ILine[];
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>;
	location: ILocationSummary;
}

export const SearchAndSelectProductsForTrial = ({ patientId, lines, setLines, location }: IProps) => {
	const notify = useSnackbarNotify();
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext();
	const { ...allSearchParams } = useContext(SearchContext);
	const [reservedIds] = useApiEffect(inventoryItemsQuery_reservedByPatientIds, patientId);
	const countReserved = useMemo(() => reservedIds?.filter(t => lines.findIndex(x => x.inventoryItemId === t) === -1).length ?? 0, [reservedIds, lines]);

	const onAddPc = (item: IProductCatalogItem, stockAtLocations: IStockAtLocation[]) => {
		if (item.isTangible === false) {
			return; // dit kan niet
		} else {
			setLines(addOrUpdateQuantity(item, lines, () => productCatalogItemToLine(item, stockAtLocations)));
		}
	};

	const onAddII = (item: IInventoryItem) => {
		if (lines.findIndex(t => t.inventoryItemId === item.id) > -1) {
			notify(strings.itemAlreadyInList, 'warning');
		} else {
			setLines([...lines, inventoryItemToLine(item)]);
		}
	};

	const onAddIIs = (items: IInventoryItemWithProductCatalogItemDto[]) => {
		let x = [...lines];
		items.forEach(item => {
			if (x.findIndex(t => t.inventoryItemId === item.inventoryItem.id) === -1) {
				x = [...x, inventoryItemToLine(item.inventoryItem)];
			}
		});
		setLines(x);
	};

	const onAddNoahDevices = () => {
		open(
			<ImportNoahDevicesByPatientForm
				open
				confirm={items => {
					confirm();
					onAddIIs(items);
				}}
				cancel={cancel}
				patientId={patientId}
			/>
		);
	};

	const onAddReservedArticles = () => {
		open(
			<SelectedReservedArticles
				open
				confirm={items => {
					confirm();
					onAddIIs(items);
				}}
				cancel={cancel}
				patientId={patientId}
				canSelect={t => t.canSelectForSale && lines.findIndex(x => x.id === t.id) === -1}
			/>
		);
	};

	return (
		<LeftRightDividedContentScrollYRight
			leftContent={
				<SearchProductsForLoanOrTrialTabs<ILine>
					lines={lines}
					{...allSearchParams}
					onAddII={onAddII}
					onAddPc={onAddPc}
					wizardContent={
						<div className='df-col'>
							<ImportFromNoahWizardButton onClick={onAddNoahDevices} />
							{countReserved !== undefined && (
								<>
									<WizardDivider />
									<ReservedWizardButton
										onClick={onAddReservedArticles}
										count={countReserved}
									/>
								</>
							)}
						</div>
					}
				/>
			}
			rightContent={
				<DragAndDropContainer<ILine>
					lines={lines}
					setLines={setLines}
					getKey={t => t.id}
					render={(line, index, props) => (
						<TrialLineComponent
							line={line}
							index={index + 1}
							dragHandleProps={props}
							setLine={val => setLines(updateArray(line, lines, val))}
							onDelete={() => setLines(lines.filter(t => t !== line))}
							location={location}
						/>
					)}
				/>
			}
		/>
	);
};
