import React from 'react';
import { ActionCardButton } from 'framework/components/buttons/ActionCardButton';
import { CashPlusIcon } from 'framework/components/icons/CashPlusIcon';
import { ISupplier } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DefaultActionsCard } from 'shared/DefaultActionsCard';
import { RegisterPaymentForSupplierForm } from '../../payments/forms/purchases/RegisterPaymentForSupplierForm';

interface IProps {
	item: ISupplier;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const ActionsCard = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onPayment = () => {
		open(
			<RegisterPaymentForSupplierForm
				supplierId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<DefaultActionsCard style={style}>
			<ActionCardButton
				startIcon={<CashPlusIcon />}
				disabled={item.hasOutstandingBalance === false}
				onClick={onPayment}>
				{strings.registerPayment}
			</ActionCardButton>
		</DefaultActionsCard>
	);
};
