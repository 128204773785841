import { Eventcalendar, localeEn, localeFr, localeNl, MbscCalendarEventData, MbscEventcalendarView } from '@mobiscroll/react';
import { IRosaEventDto, IRosaViewPreferences, RosaCalendarViewType } from 'gen/ApiClient';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';
import React, { useMemo } from 'react';
import { IRosaCalendarWithIsSelected } from '../IRosaCalendarWithIsSelected';
import { mapRosaCalendarsToMobiscrollResource } from './mapRosaCalendarsToMobiscrollResource';
import { mapRosaCalendarViewTypeToMobiscrollView } from './mapRosaCalendarViewTypeToMobiscrollView';
import { mapRosaEventToMobiscrollCalendarEvent } from './mapRosaEventToMobiscrollCalendarEvent';
import { ScheduleEventContent } from './ScheduleEventContent';
import './styling.scss';

interface IProps {
	date: Date;
	onClickEvent: (event: IRosaEventDto, target: any) => void;
	view: RosaCalendarViewType;
	viewPreferences: IRosaViewPreferences;
	events: IRosaEventDto[];
	calendars: IRosaCalendarWithIsSelected[];
	// onNew: (date: Date) => void;
}

export const RosaMobiscrollEventCalendar = ({ date, onClickEvent, view, viewPreferences, events, calendars }: IProps) => {
	const language = useCurrentLanguage();
	const mobiscrollLocale = useMemo(() => (language === 'nl' ? localeNl : language === 'fr' ? localeFr : localeEn), [language]);
	const mobiscrollAgendaView = useMemo<MbscEventcalendarView>(() => mapRosaCalendarViewTypeToMobiscrollView(view, viewPreferences), [view, viewPreferences]);
	// only grouping certain views
	const canGroup = useMemo(() => view === 'Day' || view === 'Week' || view === 'Timeline', [view]);
	const mappedEvents = useMemo(() => events.map(t => mapRosaEventToMobiscrollCalendarEvent(t, canGroup)), [events, canGroup]);
	const mappedResources = useMemo(() => mapRosaCalendarsToMobiscrollResource(canGroup, calendars), [canGroup, calendars]);

	const renderScheduleEvent = React.useCallback<(data: MbscCalendarEventData) => any>(
		(data: MbscCalendarEventData) => (
			<ScheduleEventContent
				data={data}
				rEvent={events.find(t => t.id === data.id)!}
			/>
		),
		[events]
	);

	return (
		<Eventcalendar
			data={mappedEvents}
			showControls={false} // <- do this to remove all the controls
			showEventTooltip={false}
			locale={mobiscrollLocale}
			selectedDate={date}
			theme='material'
			themeVariant='light'
			clickToCreate={false}
			dragToCreate={false}
			dragToMove={false}
			dragToResize={false}
			eventDelete={false}
			resources={mappedResources}
			groupBy='date'
			// onCellDoubleClick={(args, inst) => {
			// 	onNew(args.date);
			// }}
			// onEventDragEnd={args => {
			// 	if (args.event.data) {
			// 		onEventDragged(args.event, args.event.data as any, defaultedGroupScheme);
			// 	} else {
			// 		onNew(
			// 			args.event.start as any,
			// 			args.event.end as any,
			// 			false,
			// 			defaultedGroupScheme === 'users' ? (args.resource as any) : undefined,
			// 			defaultedGroupScheme === 'rooms' ? (args.resource as any) : undefined
			// 		);
			// 	}
			// }}
			onEventClick={args => {
				onClickEvent(args.event.data as IRosaEventDto, args.domEvent.target);
			}}
			// renderEvent={data => <div>hello world</div>}
			// renderScheduleEvent={data => <div>hello world</div>}
			// renderScheduleEventContent={data => <div>hello world</div>}
			// renderScheduleEvent={data => (
			// 	<div className='df-row-ac'>
			// 		<div style={{ background: 'black' }}>{data.title}</div>
			// 	</div>
			// )}
			// renderScheduleEventContent={data => <div>{data.title}</div>}
			// renderScheduleEventContent={data => {
			// 	return (
			// 		<div
			// 			className='df-row'
			// 			style={{ marginLeft: -4 }}>
			// 			<div
			// 				style={{
			// 					borderRadius: '4px',
			// 					backgroundColor: events.find(t => t.id === data.id)?.calendarHexColor ?? 'white',
			// 					height: '14px',
			// 					width: '4px',
			// 					marginRight: 4,
			// 				}}></div>
			// 			<div style={{ width: 'calc(100% - 8px)' }}>{data.title}</div>
			// 		</div>
			// 	);
			// }}
			renderScheduleEvent={renderScheduleEvent}
			view={mobiscrollAgendaView}
			// colors={mappedSchedules}
		/>
	);
};
