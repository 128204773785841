import { Dialog, DialogProps, Grid, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import {
	trialReportsCommand_deleteBothToneThreshold,
	trialReportsCommand_deleteLeftToneThreshold,
	trialReportsCommand_deleteRightToneThreshold,
	trialReportsQuery_singleTone,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AudiogramImageOrEmpty } from 'shared/audiograms/AudiogramImageOrEmpty';
import { ToneThresholdImageType } from 'shared/audiograms/ToneThresholdImageType';
import { MonoToneThresholdAudiogramTestForm } from './MonoToneThresholdAudiogramTestForm';
import { StereoToneThresholdAudiogramTestForm } from './StereoToneThresholdAudiogramTestForm';

interface IProps extends DialogProps {
	id: string;
	patientId: string;
	close: VoidFunction;
	type: ToneThresholdImageType;
}

export const ToneThresholdAudiogramTestDialog = ({ id, patientId, close, type, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [item, reload] = useApiEffect(trialReportsQuery_singleTone, id);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);

	if (item === undefined) {
		return <div></div>;
	}

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.test)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() =>
					type === 'left'
						? trialReportsCommand_deleteLeftToneThreshold(id)
						: type === 'right'
						? trialReportsCommand_deleteRightToneThreshold(id)
						: trialReportsCommand_deleteBothToneThreshold(id)
				}
				accept={close}
			/>
		);
	};

	const onEdit = () => {
		if (type === 'both') {
			open(
				<StereoToneThresholdAudiogramTestForm
					open
					patientId={patientId}
					trialId={id}
					cancel={cancel}
					confirm={confirm}
					test={item}
				/>
			);
		} else {
			open(
				<MonoToneThresholdAudiogramTestForm
					open
					patientId={patientId}
					trialId={id}
					cancel={cancel}
					confirm={confirm}
					// test={item}
					type={type}
				/>
			);
		}
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			maxWidth='sm'
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<GroupWorkIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>{`${strings.tonalAudiogram} (+PTA)`}</Typography>
				</DialogGridTitle>
				{item.left && (type === 'left' || type === 'both') && (
					<>
						<GridDividerWithCaption caption={`${strings.results} (${strings.left})`} />
						<PropertyInGrid
							label={`dB HL ${strings.equation}`}
							value={item.left.equation}
						/>
						<PropertyInGrid
							label='db HL'
							value={item.left.pureToneAverage + ' db HL'}
						/>
						<PropertyInGrid
							label={strings.usedFrequencies}
							value={item.left.orderedPointsForPta.map(t => `${t} Hz`).join(' - ')}
						/>
					</>
				)}
				{item.right && (type === 'right' || type === 'both') && (
					<>
						<GridDividerWithCaption caption={`${strings.results} (${strings.right})`} />
						<PropertyInGrid
							label={`dB HL ${strings.equation}`}
							value={item.right.equation}
						/>
						<PropertyInGrid
							label='db HL'
							value={item.right.pureToneAverage + ' db HL'}
						/>
						<PropertyInGrid
							label={strings.usedFrequencies}
							value={item.right.orderedPointsForPta.map(t => `${t} Hz`).join(' - ')}
						/>
					</>
				)}
				{item.leftImage && type === 'left' && (
					<>
						<GridDividerWithCaption caption={strings.image} />
						<Grid
							item
							xs={12}>
							<AudiogramImageOrEmpty
								imageData={item.leftImage}
								style={{ width: 450 }}
							/>
						</Grid>
					</>
				)}
				{item.rightImage && type === 'right' && (
					<>
						<GridDividerWithCaption caption={strings.image} />
						<Grid
							item
							xs={12}>
							<AudiogramImageOrEmpty
								imageData={item.rightImage}
								style={{ width: 450 }}
							/>
						</Grid>
					</>
				)}
				{item.bothImage && type === 'both' && (
					<>
						<GridDividerWithCaption caption={strings.image} />
						<Grid
							item
							xs={12}>
							<AudiogramImageOrEmpty
								imageData={item.bothImage}
								style={{ width: 450 }}
							/>
						</Grid>
					</>
				)}
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								onClick={onEdit}
								startIcon={<EditIcon />}>
								{strings.edit}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={onDelete}
								color='secondary'
								startIcon={<DeleteOutlineIcon />}>
								{strings.delete}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
