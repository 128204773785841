import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	generalPractitionersQuery_concurrencyToken,
	generalPractitionersQuery_query,
	IGeneralPractitioner,
	IGeneralPractitionersQueryParams,
	IQueryResult,
} from 'gen/ApiClient';
import React from 'react';
import { generalPractitionersQueryParamsLsKey } from '../../../localStorageKeys';
import { GeneralPractitionersQueryContext } from './GeneralPractitionersQueryContext';

const DefaultParams: IGeneralPractitionersQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	zips: [],
	gender: undefined,
	hasOffices: undefined,
	createdBefore: undefined,
	createdAfter: undefined,
	showDeleted: false,
};

export const GeneralPractitionersQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IGeneralPractitioner>, IGeneralPractitionersQueryParams>
			defaultParams={DefaultParams}
			queryF={generalPractitionersQuery_query}
			concurrencyF={generalPractitionersQuery_concurrencyToken}
			localStorageKey={generalPractitionersQueryParamsLsKey}
			context={GeneralPractitionersQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
