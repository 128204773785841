import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { IOption } from 'framework/IOption';
import { isFiltered } from 'framework/utils/isFiltered';
import { ISettlementProposalsForCustomerAccountQueryParams, PaymentStatuses } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	params: ISettlementProposalsForCustomerAccountQueryParams;
	onFilter: (params: ISettlementProposalsForCustomerAccountQueryParams) => void;
	paddingLeft: number;
}

const exclusions: (keyof ISettlementProposalsForCustomerAccountQueryParams)[] = ['isHandled'];

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const { paymentStatusRecord } = useContext(RecordContext);
	const strings = useLocalization();

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}
			hideLabel>
			<DateFilterItem
				label={strings.date}
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<ListFilterComponent<string>
				options={PaymentStatuses.map<IOption<string>>(t => ({ id: t, identifier: paymentStatusRecord[t] }))}
				selected={params.paymentStatuses}
				setSelected={vals => onFilter({ ...params, paymentStatuses: vals })}
				icon={<HelpOutlineIcon />}
				label={strings.paymentStatus}
			/>
		</FilterBar>
	);
};
