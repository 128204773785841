import { Button, ButtonGroup } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DateRangeIcon from '@material-ui/icons/DateRange';
import React, { useMemo } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { BrowsableDateRangeType } from './BrowsableDateRangeType';
import { calculateNextRange } from './calculateNextRange';
import { calculatePreviousRange } from './calculatePreviousRange';
import { DateRangeRenderDisplayRecord } from './DateRangeRenderDisplayRecord';
import { IDateRange } from './IDateRange';
import { useCurrentLanguage } from 'localization/useCurrentLanguage';

interface IProps {
	after: Date;
	before: Date;
	setSelected: (after: Date, before: Date) => void;
	dateRangeType: BrowsableDateRangeType;
	icon?: JSX.Element;
	style?: React.CSSProperties;
}

export const BrowsableDateFilterItem = ({ after, before, dateRangeType, setSelected, icon = <DateRangeIcon />, style }: IProps) => {
	// performance wise avoiding re-renders
	const range = useMemo<IDateRange>(() => ({ after: after, before: before }), [after, before]);
	const strings = useLocalization();
	const language = useCurrentLanguage();
	const renderedDateDisplay = useMemo(
		() => DateRangeRenderDisplayRecord[dateRangeType]({ after: after, before: before }, strings, language),
		[after, before, dateRangeType, strings, language]
	);

	const onPrevious = () => {
		const r = calculatePreviousRange(range, dateRangeType);
		setSelected(r.after, r.before);
	};

	const onNext = () => {
		const r = calculateNextRange(range, dateRangeType);
		setSelected(r.after, r.before);
	};

	return (
		<ButtonGroup style={style}>
			<Button
				onClick={onPrevious}
				size='small'>
				<ChevronLeftIcon />
			</Button>
			<Button
				startIcon={icon}
				style={{ color: grey[500], cursor: 'initial' }}>
				{renderedDateDisplay}
			</Button>
			<Button
				onClick={onNext}
				size='small'>
				<ChevronRightIcon />
			</Button>
		</ButtonGroup>
	);
};
