import { Divider } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IPurchaseUbl, IRegisterPurchaseFromUblRequest } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { boolToYesNo } from 'shared/utils/boolToString';
import { IPurchaseFromUblFormLine } from './IPurchaseFromUblFormLine';
import { calculateLinePrice } from './utils';
import { v1 as uuid } from 'uuid';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';

interface IProps {
	item: IPurchaseUbl;
	lines: IPurchaseFromUblFormLine[];
	onSetDifferenceLine: (line: IPurchaseFromUblFormLine) => void;
	isShiftVat: boolean;
}

const calculateDifferenceLineVatPercentage = (differenceExVat: number, differenceInVat: number) => {
	if (formatCurrency(differenceExVat * 1.06) === formatCurrency(differenceInVat)) {
		return 6;
	} else if (formatCurrency(differenceExVat * 1.21) === formatCurrency(differenceInVat)) {
		return 21;
	} else {
		return 0;
	}
};

export const PurchaseFromUblFormConfirmation = ({ item, lines, isShiftVat, onSetDifferenceLine }: IProps) => {
	const strings = useLocalization();
	const exVatTotal = useMemo(() => _.sum(lines.map(t => calculateLinePrice(t))), [lines]);
	const inVatTotal = useMemo(() => _.sum(lines.map(t => calculateLinePrice(t) * (isShiftVat ? 1 : 1 + t.vatPercentage / 100))), [lines, isShiftVat]);
	const props = useFormikContext<IRegisterPurchaseFromUblRequest>();
	const isDifferenceSumAndUblExVat = useMemo(() => formatCurrency(exVatTotal) !== formatCurrency(item.amountExVat), [exVatTotal, item]);
	const isDifferenceSumAndUblInVat = useMemo(() => formatCurrency(inVatTotal) !== formatCurrency(item.amountInVat), [inVatTotal, item]);
	const isDifferenceSumAndUblVatAmount = useMemo(
		() => formatCurrency(inVatTotal - exVatTotal) !== formatCurrency(item.amountInVat - item.amountExVat),
		[inVatTotal, exVatTotal, item]
	);

	const onAddDifference = () => {
		const differenceExVat = item.amountExVat - exVatTotal;
		const differenceInVat = item.amountInVat - inVatTotal;

		onSetDifferenceLine({
			id: uuid(),
			allowanceCharges: [],
			description: `Verschil som lijnen en UBL`,
			inventoryItemId: undefined,
			isCreditLine: false,
			productCatalogItem: undefined,
			quantity: 1,
			serialNumber: '',
			ublData: undefined,
			unitPrice: differenceExVat,
			vatPercentage: calculateDifferenceLineVatPercentage(differenceExVat, differenceInVat),
		});
	};

	return (
		<div className='df-col gap-4'>
			<LightBulbNotification className='jc-c'>{strings.thisTransactionCannotBeUndoneText}</LightBulbNotification>
			<DividerWithMargin />
			<CaptionVerticalLabelledPropertyX label={strings.reference}>{props.values.reference}</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.externalReference}>{props.values.externalReference}</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={`# ${strings.lines}`}>{lines.length.toString()}</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX label={strings.shiftVat}>{boolToYesNo(isShiftVat, strings)}</CaptionVerticalLabelledPropertyX>
			<DividerWithCaption
				caption={strings.totals}
				spacingBottom={0}
				spacingTop={0}
			/>
			<CaptionVerticalLabelledPropertyX
				label={`${strings.totalExVat} (Σ ${strings.lines})`}
				endIcon={<StatusIcon status={isDifferenceSumAndUblExVat ? 'error' : 'success'} />}>
				{formatCurrency(exVatTotal)}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={`${strings.totalExVat} (${strings.readOutFromUbl})`}
				endIcon={<StatusIcon status={isDifferenceSumAndUblExVat ? 'error' : 'success'} />}>
				{formatCurrency(item.amountExVat)}
			</CaptionVerticalLabelledPropertyX>
			<TextLinkButton
				color='primary'
				onClick={onAddDifference}
				disabled={isDifferenceSumAndUblExVat === false}
				startIcon={<AddCircleOutlineOutlinedIcon />}>
				{strings.addALineWithDifference}
			</TextLinkButton>
			<Divider variant='inset' />
			<CaptionVerticalLabelledPropertyX
				label={`${strings.totalInVat} (Σ ${strings.lines})`}
				endIcon={<StatusIcon status={isDifferenceSumAndUblInVat ? 'error' : 'success'} />}>
				{formatCurrency(inVatTotal)}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={`${strings.totalInVat} (${strings.readOutFromUbl})`}
				endIcon={<StatusIcon status={isDifferenceSumAndUblInVat ? 'error' : 'success'} />}>
				{formatCurrency(item.amountInVat)}
			</CaptionVerticalLabelledPropertyX>
			<Divider variant='inset' />
			<CaptionVerticalLabelledPropertyX
				label={`${strings.vatAmount} (Σ ${strings.lines})`}
				endIcon={<StatusIcon status={isDifferenceSumAndUblVatAmount ? 'error' : 'success'} />}>
				{formatCurrency(inVatTotal - exVatTotal)}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={`${strings.vatAmount} (${strings.readOutFromUbl})`}
				endIcon={<StatusIcon status={isDifferenceSumAndUblVatAmount ? 'error' : 'success'} />}>
				{formatCurrency(item.amountInVat - item.amountExVat)}
			</CaptionVerticalLabelledPropertyX>
		</div>
	);
};
