import React, { useState } from 'react';
import { IRequestForInsuranceAllowance, rfiaDocumentsCommand_createCosi } from 'gen/ApiClient';
import { SelectTemplateOrStandardDocumentFromPredefinedTypeForm } from 'documents/SelectTemplateOrStandardDocumentFromPredefinedTypeForm';
import { CosiDocumentDetailDialog } from './CosiDocumentDetailDialog';
import { CosiQuestionnaireForm } from './CosiQuestionnaireForm';

interface IProps {
	rfia: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
	reload: VoidFunction;
}

type State = 'questionnaire' | 'document' | 'detail';

export const CosiQuestionnaireOrDocumentForm = ({ cancel, confirm, rfia, reload }: IProps) => {
	const [state, setState] = useState<State>(rfia.hasCosiQuestionnaire === false ? 'questionnaire' : 'document');
	const [documentId, setDocumentId] = useState<string>();

	const confirmQuestionnaire = () => {
		setState('document');
		reload();
	};

	const onConfirmDocument = (documentId: string) => {
		setDocumentId(documentId);
		setState('detail');
	};

	if (state === 'questionnaire') {
		return (
			<CosiQuestionnaireForm
				open
				rfiaId={rfia.id}
				confirm={confirmQuestionnaire}
				cancel={cancel}
			/>
		);
	} else if (state === 'document') {
		return (
			<SelectTemplateOrStandardDocumentFromPredefinedTypeForm
				open
				cancel={cancel}
				submitF={values => rfiaDocumentsCommand_createCosi(rfia.id, values)}
				confirm={onConfirmDocument}
				type='RfiaCosi'
				deferOpenEditor
			/>
		);
	} else if (state === 'detail' && documentId) {
		return (
			<CosiDocumentDetailDialog
				open
				id={documentId}
				close={confirm}
				rfiaId={rfia.id}
			/>
		);
	} else {
		return <div></div>;
	}
};
