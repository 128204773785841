import { IArticlePropertyKey, LanguageOption } from 'gen/ApiClient';

export const localizeApkLabel = (apk: IArticlePropertyKey, lang: LanguageOption): string => {
	// TODO optimize this!
	if (lang === 'nl') {
		return apk.labelNl ?? '';
	} else if (lang === 'fr') {
		return apk.labelFr ?? '';
	} else {
		return apk.labelEn ?? '';
	}
};
