import React from 'react';
import { SuppliersCacheProvider } from './SuppliersCacheProvider';
import { SuppliersFilterDataProvider } from './SuppliersFilterDataProvider';
import { SuppliersQueryProvider } from './SuppliersQueryProvider';

export const SuppliersProviders = ({ children }: any) => {
	return (
		<SuppliersCacheProvider>
			<SuppliersFilterDataProvider>
				<SuppliersQueryProvider>{children}</SuppliersQueryProvider>
			</SuppliersFilterDataProvider>
		</SuppliersCacheProvider>
	);
};
