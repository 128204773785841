import { Dialog, DialogProps, Grid, Typography, useTheme } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DetailDialogGridComponent } from 'framework/components/detailDialog/DetailDialogGridComponent';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { DocumentActionsLeftContent } from 'framework/components/detailDialog/DocumentActionsLeftContent';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { FileDocumentOutlineIcon } from 'framework/components/icons/FileDocumentOutlineIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useApiEffectWithDefer } from 'framework/hooks/useApiEffectWithDefer';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { documentsQuery_single, salesQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { calcSalesDetailRoute } from 'app/main/financial/routes';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { DocumentActions } from 'documents/DocumentActions';
import { DocumentFromTemplateForm } from 'documents/DocumentFromTemplateForm';
import { DocumentHistory } from 'documents/DocumentHistory';
import { RecordContext } from 'shared/records/RecordContext';
import { SalesChips } from './SalesChips';
import { CustomerAccountLinesTable } from './CustomerAccountLinesTable';
import { SalesLinesTable } from './SalesLinesTable';

interface IProps extends DialogProps {
	id: string;
	close: VoidFunction;
	withCreate?: boolean;
}

export const SalesDetailDialog = ({ id, close, withCreate = false, ...rest }: IProps) => {
	const strings = useLocalization();
	const [sale, reloadSale] = useApiEffect(salesQuery_single, id);
	const { saleTypeRecord } = useContext(RecordContext);
	const [document, reloadDocument] = useApiEffectWithDefer(documentsQuery_single, sale?.document?.id ?? '', isNotNullNorUndefined);
	const reload = useCallback(() => {
		reloadSale();
		reloadDocument();
	}, [reloadSale, reloadDocument]);
	const { push } = useHistory();
	const theme = useTheme();
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);

	useEffect(() => {
		if (withCreate === true) {
			open(
				<DocumentFromTemplateForm
					open
					context='Sale'
					contextId={id}
					templatePredefinedType='Invoice'
					confirm={confirm}
					cancel={cancel}
					title={`${strings.createADocumentRightAwayQuestion} (${saleTypeRecord['Invoice']})`}
					submitText={strings.selectWhatAndCreate(strings.template)}
					cancelText={strings.closeThisWindow}
				/>
			);
		}
		// eslint-disable-next-line
	}, [withCreate]);

	if (sale === undefined) {
		return <div></div>;
	}

	const onViewDetail = () => {
		close();
		push(calcSalesDetailRoute(sale.id));
	};

	const onCreateDocument = () => {
		open(
			<DocumentFromTemplateForm
				open
				context='Sale'
				contextId={sale.id}
				templatePredefinedType='Invoice'
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			scroll='paper'
			fullWidth
			maxWidth='md'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<ReceiptOutlinedIcon />}>
					<Typography variant='h1'>{sale.reference}</Typography>
					<SalesChips
						item={sale}
						style={{ marginTop: theme.spacing(0.5), marginBottom: theme.spacing(0.5) }}
					/>
					<LabelledProperty
						label={strings.date}
						property={formatDate(sale.created)}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.totalAmount}
						property={formatCurrency(sale.priceInVat)}
						withoutMargin
					/>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								startIcon={<OpenInNewIcon />}
								onClick={onViewDetail}>
								{strings.navigateToDetailsPage}
							</SmallPrimaryTextButton>
							{document === undefined && (
								<SmallPrimaryTextButton
									startIcon={<FileDocumentOutlineIcon />}
									onClick={onCreateDocument}>
									{strings.createWhat(strings.document)}
								</SmallPrimaryTextButton>
							)}
						</div>
					}
				/>
				<GridDividerWithCaption caption={strings.recipient} />
				<PropertyInGrid
					label={strings.name}
					value={sale.recipientName}
				/>
				<PropertyInGrid
					label={strings.addressLine}
					value={sale.recipient.addressLine}
				/>
				<PropertyInGrid
					label={strings.zip}
					value={`${sale.recipient.zip} ${sale.recipient.city}`}
				/>
				{sale.isCashSale === false && (
					<>
						<GridDividerWithCaption caption={strings.accountsReceivable} />
						<Grid
							item
							xs={12}>
							<CustomerAccountLinesTable lines={sale.customerAccountLines} />
						</Grid>
					</>
				)}
				<GridDividerWithCaption caption={strings.contents} />
				<Grid
					item
					xs={12}>
					<SalesLinesTable lines={sale.lines} />
				</Grid>
				{document && (
					<>
						<GridDividerWithCaption caption={strings.document} />
						<DetailDialogGridComponent
							leftContent={<DocumentActionsLeftContent />}
							rightContent={
								<div className='df-col-as'>
									<DocumentActions
										document={document}
										reload={reloadDocument}
										close={close}
									/>
								</div>
							}
						/>
						<DocumentHistory document={document} />
					</>
				)}
			</DialogContentGrid>
		</Dialog>
	);
};
