import React from 'react';
import { TendersListQueryProvider } from './list/TendersListQueryProvider';
import { TendersListViewProvider } from './list/TendersListViewProvider';

export const TendersProviders = ({ children }: any) => {
	return (
		<TendersListQueryProvider>
			<TendersListViewProvider>{children}</TendersListViewProvider>
		</TendersListQueryProvider>
	);
};
