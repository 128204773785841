import { Button, Checkbox, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { TableCardX } from 'framework/components/cards/TableCardX';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { IRepairFilterParams, RepairExportProp, RepairExportProps, repairsQuery_export } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TextLinkExportButtonMenuAndDialog } from 'shared/export/TextLinkExportButtonMenuAndDialog';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { RepairsSettingsRoute, calcRepairDetailRoute } from '../../ProcessesRoutes';
import { RepairsPageBreadcrumbs } from '../RepairsPageBreadcrumbs';
import { RegisterRepair } from '../forms/RegisterRepair';
import { RepairsListFilterCard } from './RepairsListFilterCard';
import { RepairsQueryContext } from './RepairsQueryContext';
import { RepairsTable } from './RepairsTable';
import { createRepairExportRecord } from './utils';

export const RepairsListPage = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(RepairsQueryContext);
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [expandedId, setExpandedId] = useAccordionState<string>();
	const [expandAll, toggleExpandAll] = useStateToggleBoolean(false);
	const exportRecord = useMemo(() => createRepairExportRecord(strings), [strings]);
	const { push } = useHistory();

	const onRegister = () => {
		open(
			<RegisterRepair
				cancel={cancel}
				confirm={id => {
					push(calcRepairDetailRoute(id));
					confirm();
				}}
			/>
		);
	};

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='Repairs'
			maxWidth={1280}
			stickyHeader={
				<div
					className='df-col gap-8 fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<RepairsPageBreadcrumbs />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageSettingsButtonX route={RepairsSettingsRoute} />
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
				</div>
			}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<Button
						variant='outlined'
						color='primary'
						startIcon={<AddIcon />}
						onClick={onRegister}
						style={{ borderRadius: 12 }}>
						{strings.registerANewRepair}
					</Button>
					<RepairsListFilterCard
						params={params}
						setParams={setParams}
						metadata={queryResult.metadata}
					/>
					<div className='fg1'></div>
					<Divider />
					<TextLinkExportButtonMenuAndDialog<IRepairFilterParams, RepairExportProp>
						params={params}
						filteredCount={queryResult.filteredCount}
						unfilteredCount={queryResult.unfilteredCount}
						downloadF={repairsQuery_export}
						fileName={strings.repairs}
						record={exportRecord}
						allProps={RepairExportProps}
						view='repairs'
						style={{ paddingBottom: 0 }}
					/>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				<SearchbarX
					placeholder={strings.searchVerb}
					value={params.searchString}
					onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
				/>
				<TableCardX
					header={
						<div className='df-col-ae'>
							<Button
								size='small'
								endIcon={
									<Checkbox
										checked={expandAll}
										style={{ padding: 0 }}
									/>
								}
								style={{ marginRight: -2 }}
								onClick={toggleExpandAll}>
								{strings.showAllContents}
							</Button>
							{/* <RepairsListOrderBySelect
								sortParams={params.sortParams}
								onFilter={sortParams => setParams(onResetToPage1({ ...params, sortParams: sortParams }))}
							/> */}
						</div>
					}
					table={
						<RepairsTable
							queryResult={queryResult}
							sortParams={params.sortParams}
							onFilter={sp => setParams(onResetToPage1({ ...params, sortParams: sp }))}
							expandAll={expandAll}
							expandedId={expandedId}
							toggle={setExpandedId}
						/>
					}
					footer={
						<QueryResultPaginationX
							queryResult={queryResult}
							setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
							flexEnd
						/>
					}
				/>
			</div>
		</RestrictedPageX>
	);
};
