import { localizeApkLabel } from 'app/main/inventoryManagement/productCatalog/articlePropertyKeys/utils/localizeApkLabel';
import { createHeader } from 'framework/table/createHeader';
import { IHeader } from 'framework/table/IHeader';
import { updateArray } from 'framework/utils/array/updateArray';
import { LanguageOption } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import React from 'react';
import { IArticlePropertyLine } from '../IArticlePropertyLine';
import { PropertyField } from '../PropertyField';

export const createHeaders = (
	lines: IArticlePropertyLine[],
	setLines: React.Dispatch<React.SetStateAction<IArticlePropertyLine[]>>,
	strings: IStrings,
	lang: LanguageOption
): IHeader<IArticlePropertyLine>[] => {
	return [
		createHeader<IArticlePropertyLine>(strings.name, t => `${localizeApkLabel(t.apk, lang)} [${t.apk.key}]`, undefined, { paddingLeft: 16 }),
		createHeader<IArticlePropertyLine>('Property', t => (
			<PropertyField
				line={t}
				setLine={updatedLine => setLines(updateArray(t, lines, updatedLine))}
				style={{ width: 300 }}
			/>
		)),
	];
};
