import { Typography } from '@material-ui/core';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { Logo } from 'app/logo/Logo';

export const PreloadWall = () => {
	const strings = useLocalization();

	return (
		<div className='df-col-ac stretch-ver jc-c'>
			<Logo
				width={400}
				style={{ marginBottom: '20px' }}
			/>
			<Typography variant='h5'>{strings.loadingEllipsis}</Typography>
		</div>
	);
};
