import { Checkbox, List, ListItem, ListItemIcon } from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import React from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DropResult } from 'react-beautiful-dnd';
import { reorder } from '../../utils/array/reorder';
import { toggleSelect } from '../../utils/array/toggleSelect';

interface IProps<T> {
	ordered: T[];
	setOrdered: (val: T[]) => void;
	selected: T[];
	setSelected: (val: T[]) => void;
	getKey: (val: T) => string;
	getValue: (val: T) => string;
}

export const DragAndDropList = <T extends unknown>({ ordered, setOrdered, selected, setSelected, getKey, getValue }: IProps<T>) => {
	const onDragEndHandler = ({ destination, source }: DropResult) => {
		// dropped outside the list
		if (destination !== undefined) {
			const items = reorder(ordered, source.index, destination.index);
			setOrdered(items);
		}
	};

	return (
		<DragDropContext onDragEnd={onDragEndHandler}>
			<Droppable droppableId='list'>
				{(provided: any) => (
					<div
						ref={provided.innerRef}
						{...provided.droppableProps}>
						<List dense>
							{ordered.map((t, index) => (
								<Draggable
									draggableId={getKey(t)}
									key={getKey(t)}
									index={index}>
									{(provided: DraggableProvided) => (
										<ListItem
											button
											onClick={() => setSelected(toggleSelect(t, selected))}
											ref={provided.innerRef}
											{...provided.draggableProps}>
											<ListItemIcon>
												<Checkbox checked={selected.indexOf(t) !== -1} />
											</ListItemIcon>
											<div className='df-row-ac jc-sb w100'>
												<div>{getValue(t)}</div>
												<div
													className='df-row-ac'
													{...provided.dragHandleProps}>
													<DragHandleIcon color='action' />
												</div>
											</div>
										</ListItem>
									)}
								</Draggable>
							))}
						</List>
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};
