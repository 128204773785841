import { DialogProps } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { agendaCommand_update, agendaQuery_single, ICalendarEventDto, ICalendarEventModel } from 'gen/ApiClient';
import { CalendarEventForm } from './CalendarEventForm';
import { UpdateRecurringEventDialog } from './UpdateRecurringEventDialog';

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	event: ICalendarEventDto;
	instanceStartDate: Date;
	instanceEndDate: Date;
}

export const UpdateCalendarEventForm = ({ event, instanceStartDate, instanceEndDate, confirm, cancel, ...rest }: IProps) => {
	const [originalEvent] = useApiEffect(agendaQuery_single, event.id);
	const [update] = useFormSubmit(agendaCommand_update);
	const [values, setValues] = useState<ICalendarEventModel>();
	const notify = useSnackbarNotify();

	const onModel = async (originalEvent: ICalendarEventDto, values: ICalendarEventModel) => {
		if (values.isRecurring === false && originalEvent.isRecurring === true) {
			notify('Kan herhaling niet wijzigen, verwijder hiervoor de volledige reeks');
		} else if (values.isRecurring === false || (originalEvent.isRecurring === false && values.isRecurring === true)) {
			const r = await update(event.id, values);
			if (r.isSuccess) {
				confirm();
			}
		} else {
			setValues(values);
		}
	};

	if (originalEvent === undefined) {
		return <div></div>;
	} else if (values === undefined) {
		return (
			<CalendarEventForm
				{...rest}
				calendarEvent={event}
				confirm={values => onModel(originalEvent, values)}
				isSubmitting={false}
				cancel={cancel}
				startDate={instanceStartDate}
				endDate={instanceEndDate}
				isAllDay={event.isAllDay}
			/>
		);
	} else {
		return (
			<UpdateRecurringEventDialog
				confirm={confirm}
				cancel={cancel}
				originalEvent={originalEvent}
				values={values}
				instanceDate={instanceStartDate}
			/>
		);
	}
};
