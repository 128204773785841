import { IconButton, Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import RestoreIcon from '@material-ui/icons/Restore';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { formatDate } from 'framework/utils/date/formatDate';
import { annotationsCommand_delete, annotationsCommand_restore, IAnnotation, IAnnotationType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from '../dialogs/useDialogsContext';
import { AnnotationAttachmentsComponent } from './AnnotationAttachmentsComponent';
import { AnnotationTypesCacheContext } from './context/AnnotationTypesCacheContext';
import { UpdateAnnotationForm } from './UpdateAnnotationForm';
import { UploadAnnotationAttachmentForm } from './UploadAnnotationAttachmentForm';

const toColor = (annotation: IAnnotation, types: IAnnotationType[]): string => {
	const f = types.find(t => t.id === annotation.annotationTypeId);
	return f?.color ?? '';
};

const toTooltip = (annotation: IAnnotation, types: IAnnotationType[]): string => {
	const f = types.find(t => t.id === annotation.annotationTypeId);
	return f?.name ?? '';
};

interface IProps {
	annotations: IAnnotation[];
	reload: VoidFunction;
	style?: React.CSSProperties;
	alsoIncludeDeleted: boolean;
}

export const AnnotationsComponent = ({ annotations, reload, style, alsoIncludeDeleted }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [types] = usePreloadCacheContext(AnnotationTypesCacheContext);

	const onRestore = (id: string) => {
		open(
			<AlertDialog
				open
				content={strings.undoDeleteAnnotationQuestion}
				acceptText={strings.yes}
				rejectText={strings.no}
				reject={cancel}
				acceptF={() => annotationsCommand_restore(id)}
				accept={confirm}
			/>
		);
	};

	const onEdit = (id: string) => {
		open(
			<UpdateAnnotationForm
				open
				id={id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDelete = (id: string) => {
		open(
			<AlertDialog
				open
				title={strings.delete}
				content={strings.deleteWhatQuestion(strings.remark)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => annotationsCommand_delete(id)}
				accept={confirm}
			/>
		);
	};

	const onAddAttachment = (id: string) => {
		open(
			<UploadAnnotationAttachmentForm
				id={id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	if (types === undefined) {
		return <div></div>;
	}

	return (
		<Timeline style={{ padding: 0, margin: 0, ...style }}>
			{annotations.map(t => (
				<TimelineItem key={t.id}>
					<TimelineOppositeContent
						className='df-col'
						style={{ maxWidth: '230px' }}>
						{t.isDeleted && (
							<Typography
								variant='caption'
								style={{ width: '200px' }}>
								{strings.deleted}
							</Typography>
						)}
						<Typography
							color='textSecondary'
							variant='caption'
							style={{ width: '200px' }}>{`${strings.created.toLowerCase()}: ${formatDate(t.created, 'date-and-time')}`}</Typography>
						<Typography
							color='textSecondary'
							variant='caption'
							style={{ width: '200px' }}>{`${strings.changed.toLowerCase()}: ${formatDate(t.lastUpdated, 'date-and-time')}`}</Typography>
						<div
							className='df-row jc-e'
							style={{ marginRight: '-7px' }}>
							{t.isDeleted === false && (
								<>
									<IconButton
										size='small'
										onClick={() => onEdit(t.id)}>
										<EditIcon fontSize='small' />
									</IconButton>
									<IconButton
										size='small'
										onClick={() => onDelete(t.id)}>
										<DeleteOutlineIcon fontSize='small' />
									</IconButton>
									<IconButton
										size='small'
										onClick={() => onAddAttachment(t.id)}>
										<AttachFileIcon fontSize='small' />
									</IconButton>
								</>
							)}
							{t.isDeleted === true && (
								<IconButton
									size='small'
									onClick={() => onRestore(t.id)}>
									<RestoreIcon fontSize='small' />
								</IconButton>
							)}
						</div>
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TooltipWithSpan title={toTooltip(t, types)}>
							<TimelineDot
								variant={t.hasType ? 'default' : 'outlined'}
								style={{ backgroundColor: t.hasType ? toColor(t, types) : '#bdbdbd' }}
							/>
						</TooltipWithSpan>
						<TimelineConnector style={{ backgroundColor: t.hasType ? toColor(t, types) : '#bdbdbd' }} />
					</TimelineSeparator>
					<TimelineContent>
						<div
							className='df-col'
							style={{ marginTop: '-3px' }}>
							<Typography
								variant='subtitle2'
								style={{
									whiteSpace: 'pre-wrap',
									textDecoration: t.isDeleted ? 'line-through' : 'inherit',
								}}>
								{t.content}
							</Typography>
							<AnnotationAttachmentsComponent
								key={t.id}
								annotation={t}
								alsoIncludeDeleted={alsoIncludeDeleted}
								reload={reload}
							/>
						</div>
					</TimelineContent>
				</TimelineItem>
			))}
		</Timeline>
	);
};
