import { Dialog, DialogContent, DialogProps, DialogTitle, Divider, useTheme } from '@material-ui/core';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { EventDatePicker } from 'framework/components/datePickers/EventDatePicker';
import { SlideUpTransition } from 'framework/components/transitions/SlideUpTransition';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFieldError } from 'framework/forms/utils/setFieldError';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useHeightResizeObserver } from 'framework/hooks/useHeightResizeObserver';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { Try } from 'framework/Try';
import { addUnitsToDate } from 'framework/utils/date/addUnitsToDate';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import {
	CalendarEventRequiredProperty,
	agendaQuery_settings,
	ICalendarEventDto,
	ICalendarEventModel,
	ICalendarEventType,
	ICalendarResourceDto,
	IObjectWithIdAndIdentifier,
	IPatientSearch,
	IUpdateResponse,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RRule } from 'rrule';
import { PatientSearchProvider } from 'shared/patientSearch/PatientSearchProvider';
import { SwipeableViewsWithTabs } from 'shared/swipeableViews/SwipeableViewsWithTabs';
import { TabPanel } from 'shared/swipeableViews/TabPanel';
import * as yup from 'yup';
import { useCurrentLocation } from '../../settings/locations/useCurrentLocation';
import { CalendarUsersFilterListComponent } from '../CalendarUsersFilterListComponent';
import { ICalendarResource } from '../ICalendarResource';
import { CalendarEventTypesCacheContext } from '../context/CalendarEventTypesCacheContext';
import { CalendarResourcesPreloadCacheContext } from '../context/CalendarResourcesPreloadCacheContext';
import { EmptyGroupKey } from '../EmptyGroupKey';
import { CalendarEventDetailsTab } from './CalendarEventDetailsTab';
import { CalendarEventRecurrenceTab } from './CalendarEventRecurrenceTab';
import { FindTimeComponent } from './FindTimeComponent';
import { FormFieldWithIcon } from './FormFieldWithIcon';
import { appendRrule } from './utils/appendRrule';
import { createInitialRRule } from './utils/createInitialRRule';

const createSchema = (strings: IStrings, requiredProps: CalendarEventRequiredProperty[]) => {
	return yup.object<ICalendarEventModel>({
		description: requiredProps.indexOf('Description') > -1 ? yup.string().required() : yup.string(),
		endDate: yup.date().required().min(yup.ref('startDate'), 'Einddatum moet later zijn dan start datum'),
		startDate: yup.date().required(),
		eventTypeId: requiredProps.indexOf('EventType') > -1 ? yup.string().required() : yup.string(),
		isAllDay: yup.bool().defined(),
		patientId: yup.string(),
		roomId: requiredProps.indexOf('Room') > -1 ? yup.string().required() : yup.string(),
		title: requiredProps.indexOf('Title') > -1 ? yup.string().required() : yup.string(),
		userIds: yup.mixed(),
		rEndDate: yup.date(),
		rRule: yup.string(),
		isRecurring: yup.boolean(),
		contact: requiredProps.indexOf('Contact') > -1 ? yup.string().required() : yup.string(),
	});
};

interface IProps extends DialogProps {
	confirm: (values: ICalendarEventModel) => void;
	cancel: VoidFunction;
	startDate: Date;
	endDate: Date;
	isAllDay: boolean;
	patient?: IObjectWithIdAndIdentifier<string>;
	roomGroupKey?: string;
	userGroupKey?: string;
	calendarEvent?: ICalendarEventDto;
	submitF?: (values: ICalendarEventModel) => Promise<Try<IUpdateResponse<ICalendarEventModel>>>;
	isSubmitting: boolean;
}

const createInitialValues = (
	startDate: Date,
	endDate: Date,
	isAllDay: boolean,
	event: ICalendarEventDto | undefined,
	patient: IObjectWithIdAndIdentifier<string> | undefined
): ICalendarEventModel => {
	return {
		startDate: startDate,
		endDate: endDate,
		userIds: event?.userIds ?? [],
		roomId: event?.roomId ?? undefined,
		patientId: event?.patientId ?? patient?.id ?? '',
		isAllDay: event?.isAllDay ?? isAllDay,
		title: event?.formTitle ?? '',
		description: event?.description ?? '',
		eventTypeId: event?.eventTypeId ?? '',
		// rEndDate: event?.rEndDate ?? undefined,
		rRule: event?.rRule ?? '',
		rEndDate: undefined,
		isRecurring: event?.isRecurring ?? false,
		contact: event?.contact ?? '',
	};
};

export const CalendarEventForm = ({
	confirm,
	cancel,
	startDate,
	endDate,
	isAllDay = false,
	patient,
	roomGroupKey,
	userGroupKey,
	calendarEvent,
	submitF,
	isSubmitting,
	...rest
}: IProps) => {
	const initialValues = useMemo(
		() => createInitialValues(startDate, endDate, isAllDay, calendarEvent, patient),
		[startDate, endDate, isAllDay, calendarEvent, patient]
	);
	const notify = useSnackbarNotify();
	const strings = useLocalization();
	const [calendarResources] = usePreloadCacheContext(CalendarResourcesPreloadCacheContext);
	const user = useLoggedInUser();
	const [selectedUserResourceKeys, setSelectedUserResourceKeys] = useState<string[]>([]);

	const [types] = usePreloadCacheContext(CalendarEventTypesCacheContext);

	const [rrule, setRrule] = useState<RRule>(createInitialRRule(calendarEvent));
	const [tab, setTab] = useState<number>(0);
	const [settings] = useApiEffect(agendaQuery_settings);
	const schema = useMemo(() => createSchema(strings, settings?.requiredProperties.map(t => t as CalendarEventRequiredProperty) ?? []), [strings, settings]);

	useEffect(() => {
		const userKeys = calendarResources.plannableUsers.map(t => t.key);
		if (calendarEvent !== undefined) {
			setSelectedUserResourceKeys(userKeys.filter(t => calendarEvent.userIds.indexOf(t) !== -1));
		} else if (userGroupKey === undefined && user !== undefined && userKeys.findIndex(t => t === user.id) !== -1) {
			setSelectedUserResourceKeys(userKeys.filter(t => t === user.id));
		} else if (userGroupKey !== undefined && userGroupKey !== EmptyGroupKey) {
			setSelectedUserResourceKeys(userKeys.filter(t => t === userGroupKey));
		} else {
			setSelectedUserResourceKeys(userKeys);
		}
	}, [user, userGroupKey, calendarEvent, calendarResources]);

	const handleSubmit = async (values: ICalendarEventModel, helpers: FormikHelpers<ICalendarEventModel>) => {
		setTab(0);
		if (isNullOrUndefined(values.eventTypeId) && isNullOrUndefined(values.patientId) && isNullOrUndefined(values.title)) {
			setFieldError<ICalendarEventModel>('title', strings.formRequired(strings.title), helpers);
		} else {
			values = appendRrule(values, rrule);
			values = { ...values, userIds: selectedUserResourceKeys };
			if (submitF) {
				const r = await submitF(values);
				if (handleFormResponse(r, helpers)) {
					notify(calendarEvent ? strings.changedWhat(strings.appointment) : strings.addedWhat(strings.appointment));
					confirm(values);
				}
			} else {
				confirm(values);
			}
		}
	};

	if (settings === undefined) {
		return <Dialog open></Dialog>;
	}

	return (
		<Formik<ICalendarEventModel>
			validateOnMount
			initialValues={initialValues}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<PatientSearchProvider>
					<InnerDialog
						{...rest}
						isSubmitting={isSubmitting}
						rooms={calendarResources.plannableRooms}
						userResources={calendarResources.plannableUsers}
						selectedUserResourceKeys={selectedUserResourceKeys}
						setSelectedUserResourceKeys={setSelectedUserResourceKeys}
						types={types}
						cancel={cancel}
						patient={patient}
						userGroupKey={userGroupKey}
						roomGroupKey={roomGroupKey}
						calendarEvent={calendarEvent}
						rrule={rrule}
						setRrule={setRrule}
						tab={tab}
						setTab={setTab}
					/>
				</PatientSearchProvider>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	rooms: ICalendarResourceDto[];
	userResources: ICalendarResource[];
	selectedUserResourceKeys: string[];
	setSelectedUserResourceKeys: React.Dispatch<React.SetStateAction<string[]>>;
	types: ICalendarEventType[];
	patient: IObjectWithIdAndIdentifier<string> | undefined;
	roomGroupKey: string | undefined;
	userGroupKey: string | undefined;
	calendarEvent: ICalendarEventDto | undefined;
	rrule: RRule;
	setRrule: React.Dispatch<React.SetStateAction<RRule>>;
	tab: number;
	setTab: React.Dispatch<React.SetStateAction<number>>;
}

const InnerDialog = ({
	cancel,
	isSubmitting,
	userResources,
	selectedUserResourceKeys,
	setSelectedUserResourceKeys,
	rooms,
	types,
	patient,
	roomGroupKey,
	calendarEvent,
	rrule,
	setRrule,
	tab,
	setTab,
}: IInnerDialogProps) => {
	const props = useFormikContext<ICalendarEventModel>();
	const strings = useLocalization();
	const theme = useTheme();
	const currentLocation = useCurrentLocation();

	const setStartDate = useCallback(
		(val: Date) => setFormValue<ICalendarEventModel>('startDate', val, props),
		// eslint-disable-next-line
		[]
	);
	const setEndDate = useCallback(
		(val: Date) => setFormValue<ICalendarEventModel>('endDate', val, props),
		// eslint-disable-next-line
		[]
	);
	const [ref, height] = useHeightResizeObserver();

	useLazyEffect(() => {
		if (props.values.isRecurring) {
			setTab(2);
		} else {
			setTab(0);
		}
	}, [props.values.isRecurring]);

	useLazyEffect(() => {
		// this means it's changed!
		const f = types.find(t => t.id === props.values.eventTypeId);
		if (f !== undefined) {
			setEndDate(addUnitsToDate(props.values.startDate, f.defaultDuration.value, f.defaultDuration.unit as any));
		}
	}, [props.values.eventTypeId, props.values.startDate]);

	useEffect(() => {
		if (calendarEvent !== undefined) {
			return;
		}
		if (currentLocation !== undefined && roomGroupKey === undefined && rooms.findIndex(t => t.id === currentLocation.id) !== -1) {
			setFormValue<ICalendarEventModel>('roomId', currentLocation.id, props);
		} else if (roomGroupKey !== undefined && roomGroupKey !== EmptyGroupKey) {
			setFormValue<ICalendarEventModel>('roomId', roomGroupKey, props);
		}
		// eslint-disable-next-line
	}, [currentLocation, roomGroupKey, calendarEvent]);

	const onSelectPatient = (val: IPatientSearch | undefined) => {
		if (val) {
			setFormValue<ICalendarEventModel>('title', val.lastNameCommaFirstNameBracketsNickName ?? '', props);
			setFormValue<ICalendarEventModel>('contact', val.phoneNumber ?? '', props);
		}
	};

	return (
		<Dialog
			open
			fullScreen
			TransitionComponent={SlideUpTransition}>
			<DialogTitle>{calendarEvent === undefined ? strings.newWhat(strings.appointment) : strings.editWhat(strings.appointment)}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<div
					className='df-col'
					ref={ref}>
					<FormFieldWithIcon
						icon={<ScheduleOutlinedIcon />}
						field={
							<EventDatePicker
								startDate={props.values.startDate}
								endDate={props.values.endDate}
								setStartDate={setStartDate}
								setEndDate={setEndDate}
								isAllDay={props.values.isAllDay}
							/>
						}
					/>
					<FormFieldWithIcon
						style={{ marginTop: 8 }}
						field={
							<FormSingleCheckboxField<ICalendarEventModel>
								name='isAllDay'
								label={`${strings.fullDay}?`}
							/>
						}
					/>
					<FormFieldWithIcon
						style={{ marginTop: 8 }}
						field={
							<FormSingleCheckboxField<ICalendarEventModel>
								name='isRecurring'
								label={strings.willBeRepeatedQuestion}
							/>
						}
					/>
				</div>
				<div
					className='df-row'
					style={{ height: `calc(100% - ${height}px)` }}>
					<div
						className='df-col'
						style={{ width: tab === 1 ? '75%' : '50%' }}>
						<SwipeableViewsWithTabs
							tab={tab}
							setTab={setTab}
							tabs={props.values.isRecurring ? [strings.details, strings.searchTimeSlot, strings.repeat] : [strings.details, strings.searchTimeSlot]}
							textColor='secondary'
							indicatorColor='secondary'
							tabsStyle={{ marginLeft: 36 }}
							style={{ height: '100%' }}>
							<TabPanel
								value={tab}
								index={0}>
								<CalendarEventDetailsTab
									rooms={rooms}
									patient={patient}
									types={types}
									calendarEvent={calendarEvent}
									onSelectPatient={onSelectPatient}
								/>
							</TabPanel>
							<TabPanel
								value={tab}
								index={1}
								unmountOnExit>
								<FindTimeComponent
									userResources={userResources}
									selectedUserResourceKeys={selectedUserResourceKeys}
								/>
							</TabPanel>
							<TabPanel
								value={tab}
								index={2}>
								<CalendarEventRecurrenceTab
									rrule={rrule}
									setRrule={setRrule}
									style={{ marginLeft: 30 }}
								/>
							</TabPanel>
						</SwipeableViewsWithTabs>
					</div>
					<Divider
						flexItem
						orientation='vertical'
						style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}
					/>
					<div
						className='df-col'
						style={{ width: tab === 1 ? '25%' : '50%' }}>
						<SwipeableViewsWithTabs
							tab={0}
							setTab={() => {}}
							tabs={[strings.users]}
							withoutDivider
							textColor='secondary'
							indicatorColor='secondary'>
							<TabPanel
								value={0}
								index={0}>
								<CalendarUsersFilterListComponent
									data={userResources}
									selectedKeys={selectedUserResourceKeys}
									setSelectedKeys={setSelectedUserResourceKeys}
									isColorSchemeSelected={false}
									selectColorScheme={() => {}}
									isGroupSelected={false}
									selectGroup={() => {}}
									canGroup={false}
									showSecondaryColors={false}
									isDefaultExpanded={true}
								/>
							</TabPanel>
						</SwipeableViewsWithTabs>
					</div>
				</div>
			</DialogContent>
			<CancelSubmitFormDialogActions
				submitText={calendarEvent ? strings.update : strings.create}
				isSubmitting={isSubmitting}
				cancel={cancel}
				forceEnableSubmit
			/>
		</Dialog>
	);
};
