import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	IMedicalTreatmentFeeDto,
	IRegisterRfiaFailureHandoverRequest,
	IRequestForInsuranceAllowance,
	rfiasHandoverCommand_registerHandoverFailure,
	rfiasQuery_settings,
	salesQuery_nextInvoiceReference,
	salesQuery_settings,
	ValidUntilTimeUnits,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { FormSelectVatPercentageField } from 'shared/forms/FormSelectVatPercentageField';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { useCurrentLocation } from '../../../../settings/locations/useCurrentLocation';
import { FeesTable } from './FeesTable';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterRfiaFailureHandoverRequest>({
			validUntil: yup.date().required(strings.formRequired(strings.validTo)),
			reference: yup.string().required(strings.formRequired(strings.reference)),
			isAutoSelectReference: yup.boolean().defined(),
			handoverDate: yup.date().required(strings.formRequired(strings.handoverDate)),
			fee: yup.mixed(),
			vatPercentage: yup.number().defined(),
			rfiaId: yup.string().required(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IRegisterRfiaFailureHandoverRequest)[]> = {
	0: ['handoverDate', 'fee'],
	1: ['reference', 'isAutoSelectReference', 'validUntil'],
	2: ['vatPercentage'],
};

interface IProps extends DialogProps {
	confirm: (salesId: string, handoverDate: Date) => void;
	cancel: VoidFunction;
	rfia: IRequestForInsuranceAllowance;
}

export const FailureHandoverForm = ({ confirm, cancel, rfia, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(rfiasHandoverCommand_registerHandoverFailure);
	const [step, setStep] = useState<number>(0);
	const [fees, setFees] = useState<IMedicalTreatmentFeeDto[]>([]);
	const schema = useMemo(() => createSchema(strings), [strings]);
	const [rfiaSettings] = useApiEffect(rfiasQuery_settings);
	const now = useNow();

	const handleSubmit = async (values: IRegisterRfiaFailureHandoverRequest, helpers: FormikHelpers<IRegisterRfiaFailureHandoverRequest>) => {
		// TODO meer controleren! -> max. value of stockItems will be max value available at location!
		if (fees.length > 1) {
			notify(strings.multipleNomenclaturesSelectedThisIsNotPossible, 'error');
		} else {
			const r = await create({ ...values, fee: fees[0] });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify(strings.handoverRegistered);
				confirm(r.result.salesId, values.handoverDate);
			}
		}
	};

	if (now === undefined || rfiaSettings === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterRfiaFailureHandoverRequest>
			validateOnMount
			initialValues={{
				reference: '',
				isAutoSelectReference: true,
				validUntil: undefined as any,
				fee: undefined as any,
				rfiaId: rfia.id,
				handoverDate: now,
				vatPercentage: rfiaSettings.defaultFailedTrialVatPercentage,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					schema={schema}
					rfia={rfia}
					fees={fees}
					setFees={setFees}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	isSubmitting: boolean;
	cancel: VoidFunction;
	schema: yup.ObjectSchema<IRegisterRfiaFailureHandoverRequest>;
	rfia: IRequestForInsuranceAllowance;
	fees: IMedicalTreatmentFeeDto[];
	setFees: (fees: IMedicalTreatmentFeeDto[]) => void;
}

const InnerDialog = ({ rfia, step, setStep, isSubmitting, cancel, schema, fees, setFees, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IRegisterRfiaFailureHandoverRequest>();
	const strings = useLocalization();
	const [settings] = useApiEffect(salesQuery_settings);
	const location = useCurrentLocation();
	useNextReferenceInterceptor(salesQuery_nextInvoiceReference, props);

	if (location === undefined || settings === undefined) {
		return <div></div>;
	}

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='md'>
			<DialogTitleWithFormStepper
				title={strings.registerLumpSumContribution}
				step={step}
				labels={[strings.date, strings.referenceShort, strings.vatPercentage]}
			/>
			<DialogContent
				dividers
				className='df-col fg1'>
				{step === 0 && (
					<>
						<FormDatePicker<IRegisterRfiaFailureHandoverRequest>
							name='handoverDate'
							label={strings.handoverDate}
							helperText={strings.thisDateWillBeUsedToCalculateNomenclaturePrices}
						/>
						{props.values.handoverDate !== undefined && (
							<>
								<DividerWithCaption caption={strings.refunds} />
								<FeesTable
									rfia={rfia}
									handOverDate={props.values.handoverDate}
									fees={fees}
									setFees={setFees}
								/>
							</>
						)}
					</>
				)}
				{step === 1 && (
					<>
						<FormSingleCheckboxField<IRegisterRfiaFailureHandoverRequest>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IRegisterRfiaFailureHandoverRequest>
							name='reference'
							label={strings.invoiceNumber}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormDatePickerWithUnit<IRegisterRfiaFailureHandoverRequest>
							name='validUntil'
							label={strings.validTo}
							required
							units={ValidUntilTimeUnits}
							defaultUnit='Months'
							defaultValue={settings.defaultValidUntil}
						/>
					</>
				)}
				{step === 2 && (
					<FormSelectVatPercentageField<IRegisterRfiaFailureHandoverRequest>
						name='vatPercentage'
						label={strings.vatPercentage}
						helperText={strings.failureVatPercentageHelperText}
						required
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={schema}
				stepsRecord={stepsRecord}
				validateMore={() => (step === 0 ? fees.length === rfia.allMedicalTreatmentNomenclatureCodes.length : true)}
			/>
		</Dialog>
	);
};
