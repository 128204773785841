import { Button, Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { notificationsCommand_markAllNotificationsAsReadForAllUsers, notificationsQuery_countUnreadForAllUsers } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';

export const NotificationsTroubleshootContent = ({ reloadTrigger }: IReloadTriggerProps) => {
	const [count, reload] = useApiEffect(notificationsQuery_countUnreadForAllUsers);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	const onImport = () => {
		open(
			<AlertDialog
				open
				title={`Mark all notifications as read`}
				content={`Mark ${count?.toString()} notifications as read?`}
				acceptText={`Yes, proceed`}
				rejectText={`No, go back`}
				reject={cancel}
				acceptF={() => notificationsCommand_markAllNotificationsAsReadForAllUsers()}
				accept={confirm}
			/>
		);
	};

	return (
		<div className='fg1'>
			<Card>
				<CardTitleTypography
					withPadding
					title='Notifications'
				/>
				<CardContent>
					<div className='df-row jc-e'>
						<Button
							variant='contained'
							color='primary'
							onClick={onImport}>{`Mark ${count?.toString()} unread notifications as read`}</Button>
					</div>
				</CardContent>
			</Card>
		</div>
	);
};
