import { Button, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { DialogX } from 'framework/dialogs/DialogX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { ISupplierSummaryWithOrderStats, suppliersQuery_allWithOrderStats } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { NewSupplierCardX } from './NewSupplierCardX';
import { SupplierWithOrderStatsListItemCardX } from './SupplierWithOrderStatsListItemCardX';

const filterSuppliers = (suppliers: ISupplierSummaryWithOrderStats[], search: string | undefined) => {
	if (search === undefined) {
		return suppliers;
	} else {
		return suppliers.filter(t => (t.name ?? '').toLowerCase().includes(search.toLowerCase()));
	}
};

interface IProps extends DialogProps {
	cancel: VoidFunction;
	confirm: (supplier: ISupplierSummaryWithOrderStats) => void;
	onNew: VoidFunction;
}

export const SelectSupplierForOrderDialog = ({ cancel, confirm, onNew, ...rest }: IProps) => {
	const strings = useLocalization();
	const [suppliers] = useApiEffect(suppliersQuery_allWithOrderStats);
	const [searchString, setSearchString] = useState<string>('');
	const fSuppliers = useMemo(() => filterSuppliers(suppliers ?? [], searchString), [suppliers, searchString]);
	const [selectedSupplier, setSelectedSupplier] = useAccordionState<ISupplierSummaryWithOrderStats>();

	return (
		<DialogX
			{...rest}
			open={rest.open}
			fullHeight>
			<DialogTitle>
				<div className='df-col gap-8'>
					<span>{strings.selectASupplierForAnOrder}</span>
					<SearchbarX
						placeholder={strings.searchVerb}
						onSearch={setSearchString}
						value={searchString}
					/>
				</div>
			</DialogTitle>
			<DialogContent
				className='df-col gap-8'
				dividers>
				<NewSupplierCardX onClick={onNew} />
				{fSuppliers.map(t => (
					<SupplierWithOrderStatsListItemCardX
						key={t.id}
						item={t}
						onSelect={() => setSelectedSupplier(t)}
						isSelected={t.id === selectedSupplier?.id}
					/>
				))}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button
					onClick={() => confirm(selectedSupplier!)}
					color='primary'
					disabled={selectedSupplier === undefined}>
					{strings.proceed}
				</Button>
			</DialogActions>
		</DialogX>
	);
};
