import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { FinancialsPageBreadcrumbTitle } from '../FinancialsPageBreadcrumbTitle';
import { CommissionsTabType, CommissionsTabTypes } from './CommissionsTabType';
import { CommissionSettingsDialog } from './settings/CommissionSettingsDialog';

const TabTypeIconRecord: Record<CommissionsTabType, React.ReactNode> = {
	myCommissions: (
		<div className='df-row-ac'>
			<PersonOutlinedIcon />
			<LocalAtmOutlinedIcon />
		</div>
	),
	myPayouts: (
		<div className='df-row-ac'>
			<PersonOutlinedIcon />
			<AccountBalanceOutlinedIcon />
		</div>
	),
	allCommissions: <LocalAtmOutlinedIcon />,
	allPayouts: <AccountBalanceOutlinedIcon />,
};

const createTabTypeRecord = (strings: IStrings): Record<CommissionsTabType, string> => {
	return {
		allCommissions: strings.allCommissions,
		allPayouts: strings.allPayouts,
		myCommissions: strings.myCommissions,
		myPayouts: strings.myPayouts,
	};
};

interface IProps {
	reload: VoidFunction;
	tab: CommissionsTabType;
	setTab: (tab: CommissionsTabType) => void;
}

export const CommissionsPageHeader = ({ reload, tab, setTab }: IProps) => {
	const strings = useLocalization();
	const { open, confirm } = useDialogsContext(reload);
	const tabTypeRecord = useMemo(() => createTabTypeRecord(strings), [strings]);

	const onSettings = () => {
		open(
			<CommissionSettingsDialog
				open
				close={confirm}
			/>
		);
	};

	return (
		<div className='df-col fg1'>
			<div className='df-row-ac'>
				<FinancialsPageBreadcrumbTitle />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={strings.commissions} />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle
					title={tabTypeRecord[tab]}
					icon={TabTypeIconRecord[tab]}
				/>
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageSettingsButtonX onClick={onSettings} />
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<HorizontalTabsX
				tabs={CommissionsTabTypes}
				selectedTab={tab}
				setSelectedTab={setTab}
				startIconRecord={TabTypeIconRecord}
				titleRecord={tabTypeRecord}
				style={{
					marginTop: 16,
					marginLeft: -4,
				}}
			/>
		</div>
	);
};
