import { Grid } from '@material-ui/core';
import React from 'react';

interface IProps {
	labelGridSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	leftContent?: JSX.Element;
	rightContent: JSX.Element;
}

export const DetailDialogGridComponent = ({ labelGridSize = 4, leftContent, rightContent }: IProps) => {
	return (
		<>
			<Grid
				item
				xs={labelGridSize}>
				{leftContent && leftContent}
			</Grid>
			<Grid
				item
				xs={(12 - labelGridSize) as any}
				className='df-row-ac'>
				{rightContent}
			</Grid>
		</>
	);
};
