import { TableRow, TableRowProps } from '@material-ui/core';
import React from 'react';
import { TableCellX } from './TableCellX';

interface IProps extends TableRowProps {
	colSpan: number;
	tableCellStyle?: React.CSSProperties;
}

export const TableDetailsRowX = ({ colSpan, style, children, tableCellStyle, ...rest }: IProps) => {
	return (
		<TableRow
			{...rest}
			style={{
				paddingBottom: 0,
				paddingTop: 0,
				...style,
			}}>
			<TableCellX
				colSpan={colSpan}
				style={{
					borderTop: 'none',
					paddingTop: 0,
					paddingBottom: 0,
					paddingLeft: 40,
					borderBottom: 'none',
					...tableCellStyle,
				}}>
				{children}
			</TableCellX>
		</TableRow>
	);
};
