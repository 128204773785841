import { useField } from 'formik';
import React from 'react';
import { ExtendedRadioGroup } from '../components/ExtendedRadioGroup';
import { ILabelCaptionValue } from '../components/ILabelCaptionValue';
import { nameOf } from '../utils/nameOf';
import { IFormProps } from './common/IFormProps';

interface IProps<TModel, TOption> extends IFormProps<TModel> {
	options: ILabelCaptionValue<TOption>[];
	disabled?: boolean;
	style?: React.CSSProperties;
}

export const FormExtendedRadioGroupField = <TModel extends unknown, TOption extends unknown>({ options, disabled, ...props }: IProps<TModel, TOption>) => {
	// eslint-disable-next-line
	const [field, meta, helpers] = useField<TOption>(nameOf(props.name));

	return (
		<ExtendedRadioGroup<TOption>
			selected={field.value}
			setSelected={val => helpers.setValue(val)}
			options={options}
			disabled={disabled}
		/>
	);
};
