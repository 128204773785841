import { IInventoryItem, IProductCatalogItem } from 'gen/ApiClient';
import { ILine } from '../ILine';

export const inventoryItemToLine = (ii: IInventoryItem, pc: IProductCatalogItem): ILine => {
	return {
		id: ii.id,
		description: ii.identifier,
		inventoryItemId: ii.id,
		productCatalogItemId: ii.productCatalogId,
		quantity: 1,
		productCatalogItem: pc,
		inventoryItem: ii,
	};
};
