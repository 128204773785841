import { FormControl } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { AutocompleteMultipleFreeSolo } from '../components/autocomplete/AutocompleteMultipleFreeSolo';
import { FormErrorHelper } from './common/FormErrorHelper';
import { IFormProps } from './common/IFormProps';
import { nameOf } from '../utils/nameOf';

interface IProps<TModel> extends IFormProps<TModel> {
	options: string[];
}

export const FormAutocompleteMultipleFreeSolo = <TModel extends unknown>({ name, options, label, ...props }: IProps<TModel>) => {
	const [field, meta, helpers] = useField<string[]>(nameOf(name));

	return (
		<FormControl
			variant='filled'
			error={meta.error && meta.touched ? true : false}
			fullWidth
			{...props}>
			<AutocompleteMultipleFreeSolo
				options={options}
				label={label}
				selected={field.value}
				setSelected={val => helpers.setValue(val)}
			/>
			<FormErrorHelper meta={meta} />
		</FormControl>
	);
};

// FOR LATER USE

// export const FormAutocompleteMultipleFreeSolo = <TModel extends unknown, TValue>({ name, options, label, add, selector, ...props }: IProps<TModel, TValue>) => {
//     const [field, meta, helpers] = useField<TValue[]>(nameOf(name));

//         <FormControl
//             variant="filled"
//             error={meta.error && meta.touched ? true : false}
//             fullWidth
//             {...props}>
//             <AutocompleteMultipleFreeSolo<TValue>
//                 options={options}
//                 label={label}
//                 selected={field.value}
//                 setSelected={(val) => helpers.setValue(val)}
//                 add={add}
//                 selector={selector} />
//             <FormErrorHelper meta={meta} />
//         </FormControl>
//     );
// }
