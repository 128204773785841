import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React, { useMemo } from 'react';
import { PaperOutlined } from 'framework/table/PaperOutlined';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ICustomerAccountLineWithSelection } from './ICustomerAccountLineWithSelection';

interface IProps {
	lines: ICustomerAccountLineWithSelection[];
	style?: React.CSSProperties;
}

export const TotalsTable = ({ lines, style }: IProps) => {
	const total = useMemo(
		() =>
			lines
				.filter(t => t.isSelected)
				.map(t => t.outstandingBalance)
				.reduce((sum, current) => sum + current, 0),
		[lines]
	);

	return (
		<TableContainer
			style={style}
			component={PaperOutlined}>
			<Table size='small'>
				<TableBody>
					<TableRow>
						<TableCell>Aantal</TableCell>
						<TableCell style={{ textAlign: 'right' }}>{lines.filter(t => t.isSelected).length}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell style={{ borderBottom: 'none' }}>Totaal bedrag</TableCell>
						<TableCell style={{ borderBottom: 'none', textAlign: 'right' }}>{formatCurrency(total)}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};
