import { ListItem, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { formatDate } from 'framework/utils/date/formatDate';
import { IAssuranceLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	item: IAssuranceLine;
	type: 'sale' | 'purchase';
	onViewDetail: VoidFunction;
}

export const AssuranceLineListItem = ({ item, type, onViewDetail }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();

	return (
		<ListItem
			disableGutters
			button
			onClick={onViewDetail}>
			<div
				className='df-row-ac w100 jc-sb'
				style={{ textDecoration: item.isCredited ? 'line-through' : 'inherit' }}>
				<div className='df-col'>
					<Typography
						variant='body2'
						noWrap
						display='block'>
						{item.productCatalogItemName}
					</Typography>
					<Typography
						variant='caption'
						color='textSecondary'>{`${strings.to}: ${item.isUnlimitedTerm ? strings.withoutTerm : formatDate(item.endDate)}`}</Typography>
				</div>
				<RectangularChip
					style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(1) }}
					label={type === 'sale' ? strings.sale : strings.purchase}
					size='extra-small'
				/>
			</div>
		</ListItem>
	);
};
