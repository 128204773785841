import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { IReturn } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { ReturnsSelectableColumn } from './ReturnsSelectableColumn';

export const createReturnsHeaders = (
	colRecord: Record<ReturnsSelectableColumn, string>,
	strings: IStrings
): Record<ReturnsSelectableColumn, IHeader<IReturn>> => {
	return {
		countItems: createSortableHeaderWithKey<IReturn, ReturnsSelectableColumn>(colRecord, 'countItems', s => s.countItems.toString()),
		created: createSortableHeaderWithKey<IReturn, ReturnsSelectableColumn>(colRecord, 'created', s => formatDate(s.created)),
		returnDate: createSortableHeaderWithKey<IReturn, ReturnsSelectableColumn>(colRecord, 'returnDate', s => formatDate(s.returnDate)),
		reference: createSortableHeaderWithKey<IReturn, ReturnsSelectableColumn>(colRecord, 'reference', s => s.reference),
		externalReference: createSortableHeaderWithKey<IReturn, ReturnsSelectableColumn>(colRecord, 'externalReference', s => s.externalReference),
		toSupplierIdentifier: createSortableHeaderWithKey<IReturn, ReturnsSelectableColumn>(colRecord, 'toSupplierIdentifier', s => s.toSupplierIdentifier),
		lastUpdated: createSortableHeaderWithKey<IReturn, ReturnsSelectableColumn>(colRecord, 'lastUpdated', p => formatDate(p.lastUpdated)),
		fromLocationIdentifier: createSortableHeaderWithKey<IReturn, ReturnsSelectableColumn>(colRecord, 'fromLocationIdentifier', s => s.fromLocationIdentifier),
	};
};
