import { Popover } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RestoreIcon from '@material-ui/icons/Restore';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import { FabExtraSmallMoreButton } from 'framework/components/buttons/FabExtraSmallMoreButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { generalPractitionersCommand_deleteOffice, generalPractitionersCommand_makeOfficeDefault, IOfficeData } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

interface IProps {
	item: IOfficeData;
	generalPractitionerId: string;
	style?: React.CSSProperties;
	reload: VoidFunction;
}

export const GeneralPractitionerOfficeFab = ({ item, generalPractitionerId, style, reload }: IProps) => {
	const strings = useLocalization();
	const [anchor, openMenu, closeMenu] = useAnchorElement();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [makeDefault] = useFormSubmit(generalPractitionersCommand_makeOfficeDefault);

	const onRestore = () => {
		closeMenu();
	};

	const onMakeDefault = async () => {
		closeMenu();
		const r = await makeDefault({ generalPractitionerId: generalPractitionerId, officeId: item.id });
		if (r.isSuccess) {
			reload();
		}
	};

	const onDelete = () => {
		closeMenu();
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.office)}
				acceptText={strings.yesCommaDelete}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={() => generalPractitionersCommand_deleteOffice({ generalPractitionerId: generalPractitionerId, officeId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<>
			<FabExtraSmallMoreButton
				style={style}
				onClick={openMenu}
			/>
			{Boolean(anchor) && (
				<Popover
					open={Boolean(anchor)}
					anchorEl={anchor}
					onClose={closeMenu}>
					<div
						className='df-col'
						style={{ padding: 12 }}>
						<TextLinkButton
							startIcon={<StarBorderOutlinedIcon />}
							onClick={onMakeDefault}
							disabled={item.isDefault}>
							{strings.makeDefaultOffice}
						</TextLinkButton>
						{item.isDeleted === false && (
							<TextLinkButton
								startIcon={<DeleteOutlineIcon />}
								hoverColor='secondary'
								onClick={onDelete}>
								{strings.delete}
							</TextLinkButton>
						)}
						{item.isDeleted === true && (
							<TextLinkButton
								startIcon={<RestoreIcon />}
								hoverColor='secondary'
								onClick={onRestore}>
								{strings.repairVerb}
							</TextLinkButton>
						)}
					</div>
				</Popover>
			)}
		</>
	);
};
