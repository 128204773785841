import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	exactOnlineCustomerAccountsQuery_concurrencyToken,
	exactOnlineCustomerAccountsQuery_query,
	IExactOnlineCustomerAccountDto,
	IExactOnlineCustomerAccountQueryParams,
	IQueryResult,
} from 'gen/ApiClient';
import React from 'react';
import { ExactOnlineCustomerAccountsQueryParamsLsKey } from '../../../../localStorageKeys';
import { ExactOnlineCustomerAccountsQueryContext } from './ExactOnlineCustomerAccountsQueryContext';

const DefaultParams: IExactOnlineCustomerAccountQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'name' },
	pageParams: { index: 1, size: 10 },
	prefilter: 'NotLinked',
};

export const ExactOnlineCustomerAccountsQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IExactOnlineCustomerAccountDto>, IExactOnlineCustomerAccountQueryParams>
			defaultParams={DefaultParams}
			queryF={exactOnlineCustomerAccountsQuery_query}
			concurrencyF={exactOnlineCustomerAccountsQuery_concurrencyToken}
			localStorageKey={ExactOnlineCustomerAccountsQueryParamsLsKey}
			context={ExactOnlineCustomerAccountsQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
