import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IRegisterInventoryItemAsSoldRequest, inventoryItemsCommand_registerAsSold } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { FormSelectPatientField } from 'shared/patientSearch/FormSelectPatientField';
import { PatientSearchProvider } from 'shared/patientSearch/PatientSearchProvider';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<IRegisterInventoryItemAsSoldRequest>({
		reference: yup.string().required(),
		referenceDate: yup.date().required(),
		patientId: yup.string().required(),
		inventoryItemId: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	inventoryItemId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const InventoryItemRegisterAsSoldForm = ({ confirm, cancel, inventoryItemId, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(inventoryItemsCommand_registerAsSold);
	const now = useNow();

	const handleSubmit = async (values: IRegisterInventoryItemAsSoldRequest, helpers: FormikHelpers<IRegisterInventoryItemAsSoldRequest>) => {
		const r = await submit(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterInventoryItemAsSoldRequest>
			validateOnMount
			initialValues={{
				reference: '',
				referenceDate: now,
				patientId: '',
				inventoryItemId: inventoryItemId,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<PatientSearchProvider>
					<Dialog
						fullWidth
						{...rest}>
						<DialogTitle>{strings.registerInventoryItemAsSold}</DialogTitle>
						<DialogContent
							className='df-col'
							dividers>
							<FormSelectPatientField<IRegisterInventoryItemAsSoldRequest>
								name='patientId'
								label={strings.patient}
							/>
							<FormTextField<IRegisterInventoryItemAsSoldRequest>
								name='reference'
								label={strings.reference}
							/>
							<FormDatePicker<IRegisterInventoryItemAsSoldRequest>
								name='referenceDate'
								label={strings.referenceDate}
							/>
						</DialogContent>
						<CancelSubmitFormDialogActions
							cancel={cancel}
							isSubmitting={isSubmitting}
							submitText={strings.add}
						/>
					</Dialog>
				</PatientSearchProvider>
			</Form>
		</Formik>
	);
};
