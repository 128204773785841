import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useLocalization } from 'localization/useLocalization';
import { Mode } from './Mode';

interface IProps extends DialogProps {
	confirm: (value: Mode) => void;
	cancel: VoidFunction;
}

export const ModeDialog = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [value, setValue] = useState<Mode>('same');

	return (
		<Dialog
			scroll='paper'
			fullWidth
			{...rest}>
			<DialogTitle>{strings.selectWhat(strings.mode)}</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				<ExtendedRadioGroup<Mode>
					selected={value}
					setSelected={setValue}
					options={[
						{ value: 'same', label: strings.thisPurchase, caption: strings.selectLineFromThisPurchase },
						{ value: 'other', label: strings.previouslyPurchasedItem, caption: strings.selectAPreviouslyPurchasedItem },
					]}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				onSubmit={() => confirm(value)}
				cancel={cancel}
				isSubmitting={false}
				submitText={strings.create}
			/>
		</Dialog>
	);
};
