import { Color } from '@material-ui/lab';
import React, { useContext } from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { IRequestForInsuranceAllowance, RfiaInvoiceProcessStatus } from 'gen/ApiClient';
import { ViewContext } from './context/ViewContext';

const colorRecord: Record<RfiaInvoiceProcessStatus, Color> = {
	Delivered: 'info',
	Invoiced: 'success',
	IsFullyPaid: 'success',
	NotApplicable: 'info',
	Ordered: 'info',
	Tendered: 'info',
};

const fillRecord: Record<RfiaInvoiceProcessStatus, boolean> = {
	Delivered: false,
	Invoiced: false,
	IsFullyPaid: true,
	NotApplicable: false,
	Ordered: false,
	Tendered: false,
};

interface IProps {
	item: IRequestForInsuranceAllowance;
	style?: React.CSSProperties;
}

export const RfiaInvoiceProcessStatusChip = ({ item, style }: IProps) => {
	const { invoiceProcessStatusRecord } = useContext(ViewContext);

	return (
		<RectangularChip
			style={style}
			label={invoiceProcessStatusRecord[item.invoiceProcessStatus as RfiaInvoiceProcessStatus]}
			fill={fillRecord[item.invoiceProcessStatus as RfiaInvoiceProcessStatus]}
			color={colorRecord[item.invoiceProcessStatus as RfiaInvoiceProcessStatus]}
		/>
	);
};
