import { IProductCatalogItem, IProductCatalogQueryParamsForArticlesInquiry, IQueryResult } from 'gen/ApiClient';
import React from 'react';

export interface IContext {
	params: IProductCatalogQueryParamsForArticlesInquiry;
	setParams: React.Dispatch<React.SetStateAction<IProductCatalogQueryParamsForArticlesInquiry>>;
	queryResult: IQueryResult<IProductCatalogItem> | undefined;
}

export const SearchProductCatalogItemsForArticlesInquiryContext = React.createContext<IContext>(undefined as any);
