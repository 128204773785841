import { Collapse, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, useTheme } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { ArrowDropDownOrUpIcon } from 'framework/components/ArrowDropDownOrUpIcon';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { IDocumentKeyDto } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from '../useDialogsContext';
import { DocumentKeyDialog } from './DocumentKeyDialog';

interface IProps {
	contextDescription: string;
	keys: IDocumentKeyDto[];
	isExpanded: boolean;
	onClick: VoidFunction;
}

export const DocumentContextListComponent = ({ keys, isExpanded, contextDescription, onClick }: IProps) => {
	const theme = useTheme();
	const { open, cancel } = useDialogsContext();

	const onDetail = (documentKey: IDocumentKeyDto) => {
		open(
			<DocumentKeyDialog
				open
				documentKey={documentKey}
				close={cancel}
			/>
		);
	};

	return (
		<>
			<ListItem
				button
				onClick={onClick}>
				<ListItemText
					primary={
						<TooltipWithSpan title={contextDescription}>
							<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{contextDescription}</div>
						</TooltipWithSpan>
					}
				/>
				<ArrowDropDownOrUpIcon
					isUp={isExpanded}
					style={{ color: theme.palette.text.secondary }}
				/>
			</ListItem>
			<Collapse in={isExpanded}>
				<List dense>
					{keys.map(t => (
						<ListItem
							key={t.key}
							button
							style={{ paddingLeft: 24, paddingTop: 0, paddingBottom: 0 }}
							onClick={() => onDetail(t)}>
							<ListItemText
								primary={
									<TooltipWithSpan title={t.description ?? ''}>
										<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{t.description}</div>
									</TooltipWithSpan>
								}
								secondary={t.keyWithBrackets}
							/>
							<ListItemSecondaryAction>
								<IconButton
									edge='end'
									onClick={() => navigator.clipboard.writeText(t.keyWithBrackets)}>
									<FileCopyOutlinedIcon fontSize='small' />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>
			</Collapse>
		</>
	);
};
