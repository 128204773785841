import { ColorX } from 'framework/components/color/ColorX';
import { IPurchase } from 'gen/ApiClient';

export const mapPurchaseToColorX = (item: IPurchase): ColorX => {
	if (item.isFullyPaid) {
		return 'success';
	} else if (item.isOverDue) {
		return 'warning';
	} else {
		return 'default';
	}
};
