import { IPatientDetail, PatientDetailOverviewLayoutElementType } from 'gen/ApiClient';
import React from 'react';
import { TonalAudiogramCard } from 'shared/audiograms/TonalAudiogramCard';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { PatientUpcomingEventsCardSwitch } from '../appointments/PatientUpcomingEventsCardSwitch';
import { PatientAssignedOrInquiredArticlesCard } from '../assignedOrInquiredArticles/PatientAssignedOrInquiredArticlesCard';
import { PatientAnnotationsCard } from '../PatientAnnotationsCard';
import { SalesByPatientOverviewCard } from '../sales/SalesByPatientOverviewCard';
import { WorkflowsByPatientOverviewCard } from '../workflows/WorkflowsByPatientOverviewCard';

interface IProps extends IReloadTriggerProps {
	type: PatientDetailOverviewLayoutElementType;
	item: IPatientDetail;
	reload: VoidFunction;
}

export const PatientDetailOverviewLayoutElementSwitch = ({ type, item, reload, reloadTrigger }: IProps) => {
	if (type === 'AssignedArticles') {
		return (
			<PatientAssignedOrInquiredArticlesCard
				patientId={item.patient.id}
				reloadTrigger={reloadTrigger}
			/>
		);
	} else if (type === 'Comments') {
		return (
			<PatientAnnotationsCard
				patientId={item.patient.id}
				reloadTrigger={reloadTrigger}
			/>
		);
	} else if (type === 'LastAppointments') {
		return (
			<PatientUpcomingEventsCardSwitch
				item={item.patient}
				reload={reload}
				reloadTrigger={reloadTrigger}
			/>
		);
	} else if (type === 'Workflows') {
		return (
			<WorkflowsByPatientOverviewCard
				item={item}
				reload={reload}
				reloadTrigger={reloadTrigger}
			/>
		);
	} else if (type === 'Sales') {
		return (
			<SalesByPatientOverviewCard
				item={item}
				reload={reload}
				reloadTrigger={reloadTrigger}
			/>
		);
	} else if (type === 'TonalAudiogram') {
		return (
			<TonalAudiogramCard
				patientId={item.patient.id}
				reloadTrigger={reloadTrigger}
			/>
		);
	} else {
		return <></>;
	}
};
