import { Button, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { DialogX } from 'framework/dialogs/DialogX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { useAccordionState } from 'framework/utils/useAccordionState';
import {
	IExactOnlineCrmAccountDto,
	IExactOnlineCustomerAccountDto,
	exactOnlineCrmAccountsQuery_all,
	exactOnlineCustomerAccountsCommand_link,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useMemo, useState } from 'react';
import { CrmAccountListItemCardX } from './CrmAccountListItemCardX';
import { NewCrmAccountCardX } from './NewCrmAccountCardX';

const filterCrmAccounts = (crmAccounts: IExactOnlineCrmAccountDto[], search: string | undefined) => {
	if (search === undefined) {
		return crmAccounts;
	} else {
		return crmAccounts.filter(t => `${t.code ?? ''} ${t.name ?? ''}`.toLowerCase().includes(search.toLowerCase()));
	}
};

interface IProps extends DialogProps {
	item: IExactOnlineCustomerAccountDto;
	cancel: VoidFunction;
	confirm: () => void;
	onNew: VoidFunction;
}

export const LinkExistingExactOnlineCrmAccountDialog = ({ item, cancel, confirm, onNew, ...rest }: IProps) => {
	const strings = useLocalization();
	const [crmAccounts] = useApiEffect(exactOnlineCrmAccountsQuery_all);
	const [searchString, setSearchString] = useState<string>('');
	const fCrmAccounts = useMemo(() => filterCrmAccounts(crmAccounts ?? [], searchString), [crmAccounts, searchString]);
	const [selectedCrmAccount, setSelectedCrmAccount] = useAccordionState<IExactOnlineCrmAccountDto | undefined>();
	const [submit] = useFormSubmit(exactOnlineCustomerAccountsCommand_link);
	const notify = useSnackbarNotify();

	useEffect(() => {
		setSelectedCrmAccount(undefined);
		// eslint-disable-next-line
	}, [searchString]);

	const onSubmit = async () => {
		if (selectedCrmAccount === undefined) {
			return;
		}

		const r = await submit({ hamsId: item.hamsId, exactOnlineAccountId: selectedCrmAccount.id });
		if (r.isFailure) {
			notify(r.error);
		} else {
			confirm();
		}
	};

	return (
		<DialogX
			{...rest}
			open={rest.open}
			fullHeight>
			<DialogTitle>
				<div className='df-col gap-8'>
					<span>{strings.linkExactOnlineCRMAccountForWho(item.name!)}</span>
					<SearchbarX
						placeholder={`${strings.searchOnNameSlashCode}...`}
						onSearch={setSearchString}
						value={searchString}
					/>
				</div>
			</DialogTitle>
			<DialogContent
				className='df-col gap-8'
				dividers>
				<NewCrmAccountCardX onClick={onNew} />
				{fCrmAccounts.map(t => (
					<CrmAccountListItemCardX
						key={t.id}
						item={t}
						onSelect={() => setSelectedCrmAccount(t)}
						isSelected={t.id === selectedCrmAccount?.id}
					/>
				))}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button
					onClick={onSubmit}
					color='primary'
					disabled={selectedCrmAccount === undefined}>
					{strings.proceed}
				</Button>
			</DialogActions>
		</DialogX>
	);
};
