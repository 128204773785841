import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IStrings } from 'localization/IStrings';
import { ITenderLineWithSelection } from '../../../../../../financial/tenders/ITenderLineWithSelection';

export const createHeaders = (
	lines: ITenderLineWithSelection[],
	setLines: React.Dispatch<React.SetStateAction<ITenderLineWithSelection[]>>,
	strings: IStrings
): IHeader<ITenderLineWithSelection>[] => {
	return [
		createSelectionHeader<ITenderLineWithSelection>(lines, setLines),
		createSortableHeader<ITenderLineWithSelection>(strings.description, 'description', t => t.description),
		createSortableHeader<ITenderLineWithSelection>(strings.quantity, 'quantity', t => t.quantity.toString()),
		createSortableHeader<ITenderLineWithSelection>(`${strings.listPrice}\n${strings.inVatShort}`, 'unitListPriceInVat', t =>
			formatCurrency(t.unitListPriceInVat)
		),
		createSortableHeader<ITenderLineWithSelection>(`${strings.salesPrice}\n${strings.inVatShort}`, 'unitPriceInVat', t => formatCurrency(t.unitPriceInVat)),
	];
};
