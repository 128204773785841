import { Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DragAndDropContainer } from 'framework/components/dnd/DragAndDropContainer';
import { LeftRightDividedContentScrollYRight } from 'framework/components/LeftRightDividedContentScrollYRight';
import { updateArray } from 'framework/utils/array/updateArray';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AddLineForm } from 'shared/searchAndSelect/AddLineForm';
import { INewLineModel } from 'shared/searchAndSelect/INewLineModel';
import { TotalsInVatTable } from 'shared/searchAndSelect/TotalsInVatTable';
import { VatPercentage } from 'shared/VatPercentages';
import { AdvanceInvoiceLineComponent } from './AdvanceInvoiceLineComponent';
import { ILine } from './ILine';
import { newLineToLine } from './utils/newLineToLine';

interface IProps {
	lines: ILine[];
	setLines: React.Dispatch<React.SetStateAction<ILine[]>>;
	vatPercentage?: VatPercentage;
}

export const SearchAndSelectProductsForAdvanceInvoice = ({ lines, setLines, vatPercentage }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext();

	const onAddLine = () => {
		open(
			<AddLineForm
				open
				confirm={onLineAdded}
				cancel={cancel}
				vatPercentage={vatPercentage}
			/>
		);
	};

	const onLineAdded = (line: INewLineModel) => {
		confirm();
		setLines([...lines, newLineToLine(line)]);
	};

	return (
		<LeftRightDividedContentScrollYRight
			leftContent={
				<div
					className='df-col'
					style={{ width: '424px' }}>
					<Typography style={{ fontSize: '18px', marginBottom: theme.spacing(1) }}>{strings.actions}</Typography>
					<div className='df-col-as'>
						<SmallPrimaryTextButton
							startIcon={<AddIcon />}
							onClick={onAddLine}>
							{strings.addALine}
						</SmallPrimaryTextButton>
					</div>
				</div>
			}
			rightContent={
				<DragAndDropContainer<ILine>
					lines={lines}
					setLines={setLines}
					getKey={t => t.id}
					render={(line, index, props) => (
						<AdvanceInvoiceLineComponent
							line={line}
							index={index + 1}
							dragHandleProps={props}
							setLine={val => setLines(updateArray(line, lines, val))}
							onDelete={() => setLines(lines.filter(t => t !== line))}
						/>
					)}
				/>
			}
			rightBottomFixedContent={
				<div className='df-col-ae'>
					<TotalsInVatTable<ILine>
						lines={lines}
						isShiftVat={false}
					/>
				</div>
			}
		/>
	);
};
