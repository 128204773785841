import { Dialog, DialogActions, DialogContent, DialogProps, useTheme } from '@material-ui/core';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormActions } from 'framework/forms/PageableFormActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { dateOrStringToDate } from 'framework/utils/date/dateOrStringToDate';
import { mapToIso } from 'framework/utils/date/mapToIso';
import {
	customerAccountLinesQuery_allForCustomerAccountNoSpsWithOutstandingBalance,
	customerAccountsQuery_isHifAndIsDeleted,
	customerAccountsQuery_single,
	customerAccountsQuery_singleRecipientAndAddress,
	ICustomerAccount,
	ISettlementProposalModel,
	ITimeModel,
	settlementProposalsCommand_new,
	settlementProposalsQuery_nextReference,
	ValidUntilTimeUnit,
	ValidUntilTimeUnits,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useCallback, useEffect, useState } from 'react';
import { AddressInput } from 'shared/address/AddressInput';
import { FormRecipientNameField } from 'shared/forms/FormRecipientNameField';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { CustomerAccountLinesTable } from '../../payments/forms/customerAccountLines/CustomerAccountLinesTable';
import { ICustomerAccountLineWithSelection } from '../../payments/forms/customerAccountLines/ICustomerAccountLineWithSelection';
import { TotalsTable } from '../../payments/forms/customerAccountLines/TotalsTable';

const defaultValidUntil: ITimeModel = {
	value: 2,
	unit: 'Months' as ValidUntilTimeUnit,
};

const createSchema = (strings: IStrings) => {
	return yup
		.object<ISettlementProposalModel>({
			customerAccountId: yup.string().required(),
			isAutoSelectReference: yup.boolean().defined(),
			reference: yup.string(),
			customerAccountLineIds: yup.mixed(),
			zip: yup.string(),
			addressLine: yup.string(),
			city: yup.string(),
			country: yup.string(),
			recipientName: yup.string().required(),
			referenceDate: yup.date().required(),
			validUntil: yup.date().required(),
		})
		.defined();
};

const EmptyValues: ISettlementProposalModel = {
	customerAccountId: '',
	isAutoSelectReference: true,
	reference: '',
	customerAccountLineIds: [],
	addressLine: '',
	zip: '',
	country: '',
	recipientName: '',
	city: '',
	referenceDate: undefined as any,
	validUntil: undefined as any,
};

const stepsRecord: Record<number, (keyof ISettlementProposalModel)[]> = {
	0: ['isAutoSelectReference', 'reference', 'referenceDate', 'validUntil'],
	1: ['recipientName', 'addressLine', 'city', 'zip', 'country'],
	2: ['customerAccountLineIds'],
};

interface IProps extends DialogProps {
	customerAccountId: string;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const SettlementProposalModelForm = ({ confirm, cancel, customerAccountId, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(settlementProposalsCommand_new);
	const [step, setStep] = useState<number>(0);
	const [customerAccount] = useApiEffect(customerAccountsQuery_single, customerAccountId);
	const [allLines] = useApiEffect(customerAccountLinesQuery_allForCustomerAccountNoSpsWithOutstandingBalance, customerAccountId);
	const [lines, setLines] = useState<ICustomerAccountLineWithSelection[]>([]);
	const [recipient] = useApiEffect(customerAccountsQuery_singleRecipientAndAddress, customerAccountId);
	const [isHifAndDeleted] = useApiEffect(customerAccountsQuery_isHifAndIsDeleted, customerAccountId);
	const now = useNow();

	useEffect(() => {
		if (allLines !== undefined) {
			setLines(allLines.map<ICustomerAccountLineWithSelection>(t => ({ isSelected: false, ...t })));
		}
	}, [allLines]);

	const handleSubmit = async (values: ISettlementProposalModel, helpers: FormikHelpers<ISettlementProposalModel>) => {
		if (lines.filter(t => t.isSelected).length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const r = await create({ ...values, customerAccountLineIds: lines.filter(t => t.isSelected).map(t => t.id) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				// navigate to detail?
				notify(strings.addedWhat(strings.settlementProposal));
				confirm(r.result.objectId);
			}
		}
	};

	if (
		lines === undefined ||
		allLines === undefined ||
		customerAccount === undefined ||
		recipient === undefined ||
		now === undefined ||
		isHifAndDeleted === undefined
	) {
		return <div></div>;
	}

	return (
		<Formik<ISettlementProposalModel>
			validateOnMount
			initialValues={{
				...EmptyValues,
				recipientName: recipient.recipient ?? '',
				addressLine: recipient.addressLine ?? '',
				city: recipient.city ?? '',
				country: recipient.country ?? '',
				zip: recipient.zip ?? '',
				customerAccountId: customerAccountId,
				referenceDate: now,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					step={step}
					setStep={setStep}
					cancel={cancel}
					isSubmitting={isSubmitting}
					customerAccount={customerAccount}
					lines={lines}
					setLines={setLines}
					isHifAndDeleted={isHifAndDeleted}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	customerAccount: ICustomerAccount;
	lines: ICustomerAccountLineWithSelection[];
	setLines: React.Dispatch<React.SetStateAction<ICustomerAccountLineWithSelection[]>>;
	isHifAndDeleted: boolean;
}

const InnerDialog = ({ step, setStep, cancel, isSubmitting, customerAccount, lines, setLines, isHifAndDeleted, ...rest }: IInnerDialogProps) => {
	const theme = useTheme();
	const props = useFormikContext<ISettlementProposalModel>();
	const cb = useCallback(() => settlementProposalsQuery_nextReference(customerAccount.id), [customerAccount.id]);
	useNextReferenceInterceptor(cb, props);
	const strings = useLocalization();

	return (
		<Dialog
			scroll='paper'
			fullWidth={step === 2}
			maxWidth='md'
			{...rest}>
			<DialogTitleWithFormStepper
				title={`${strings.settlementProposal}: ${customerAccount.identifier}`}
				labels={[strings.reference, strings.withRespectToShort, strings.lines]}
				step={step}>
				{isHifAndDeleted && <WarningLabelledProperty warning={strings.hifWhatDoesNotExistAnymore(customerAccount.link.idAsString!)} />}
			</DialogTitleWithFormStepper>
			<DialogContent
				className='df-col'
				dividers
				style={{ padding: step === 2 ? '0px' : '8px 24px' }}>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<ISettlementProposalModel>
							name='isAutoSelectReference'
							label={strings.autoSelectPaymentReferenceQuestion}
						/>
						<FormTextField<ISettlementProposalModel>
							name='reference'
							label={strings.paymentReference}
							disabled={props.values.isAutoSelectReference}
						/>
						{customerAccount.isHif && (
							<FormDatePicker<ISettlementProposalModel>
								name='referenceDate'
								label={strings.referenceDate}
								helperText={strings.monthHandover}
								openTo='month'
								views={['year', 'month']}
								format='MM/yyyy'
							/>
						)}
						<FormDatePickerWithUnit<ISettlementProposalModel>
							name='validUntil'
							label={strings.expectedPaymentBefore}
							required
							units={ValidUntilTimeUnits}
							defaultUnit='Months'
							defaultValue={defaultValidUntil}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormRecipientNameField<ISettlementProposalModel> />
						<AddressInput<ISettlementProposalModel> optional />
					</>
				)}
				{step === 2 && (
					<CustomerAccountLinesTable
						lines={lines}
						setLines={setLines}
						initFilter={{
							after: mapToIso(startOfMonth(dateOrStringToDate(props.values.referenceDate))),
							before: mapToIso(endOfMonth(dateOrStringToDate(props.values.referenceDate))),
						}}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<div className='df-col'>
					{step === 2 && (
						<TotalsTable
							lines={lines}
							style={{ marginBottom: theme.spacing(1) }}
						/>
					)}
					<PageableFormActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.create}
						schema={createSchema(strings)}
						stepsRecord={stepsRecord}
					/>
				</div>
			</DialogActions>
		</Dialog>
	);
};
