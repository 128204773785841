import { CircularProgress, IconButton, IconButtonProps } from '@material-ui/core';
import React from 'react';

interface IProps extends IconButtonProps {
	isLoading: boolean;
}

export const LoaderIconButton = ({ isLoading, children, disabled = false, ...rest }: IProps) => {
	return (
		<IconButton
			{...rest}
			disabled={disabled || isLoading}>
			{isLoading ? (
				<CircularProgress
					style={{ color: 'inherit' }}
					size={18}
				/>
			) : (
				children
			)}
		</IconButton>
	);
};
