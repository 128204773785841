import { Card } from '@material-ui/core';
import { StethoscopeIcon } from 'framework/components/icons/StethoscopeIcon';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { RosaCalendarCollapseListFilterComponent } from './RosaCalendarCollapseListFilterComponent';
import { RosaCalendarContext } from './RosaCalendarContext';
import { RosaCalendarDatePickerComponent } from './RosaCalendarDatePickerComponent';
import { RosaCalendarOrMotiveListItemComponent } from './RosaCalendarOrMotiveListItemComponent';

export const RosaCalendarDateCalendarsAndMotivesCard = () => {
	const strings = useLocalization();
	const { myCalendars, date, setDate, motives, otherCalendars, toggleCalendar, toggleMotive, hps, toggleHp } = useContext(RosaCalendarContext);

	return (
		<Card
			className='df-col'
			style={{ paddingBottom: 8, marginBottom: 1 }}>
			<RosaCalendarDatePickerComponent
				date={date}
				setDate={setDate}
			/>
			<div style={{ paddingLeft: 16, paddingRight: 16 }}>
				<RosaCalendarCollapseListFilterComponent
					title={strings.myCalendars}
					style={{ marginBottom: 4 }}>
					{myCalendars.map(t => (
						<RosaCalendarOrMotiveListItemComponent
							key={t.id!}
							onClick={() => toggleCalendar(t.id!)}
							item={t}
						/>
					))}
				</RosaCalendarCollapseListFilterComponent>
				<RosaCalendarCollapseListFilterComponent
					title={strings.otherCalendars}
					style={{ marginBottom: 4, marginTop: 16 }}>
					{otherCalendars.map(t => (
						<RosaCalendarOrMotiveListItemComponent
							key={t.id!}
							onClick={() => toggleCalendar(t.id!)}
							item={t}
						/>
					))}
				</RosaCalendarCollapseListFilterComponent>
				<RosaCalendarCollapseListFilterComponent
					title={
						<div className='df-row-ac'>
							<StethoscopeIcon
								style={{ marginRight: 4 }}
								fontSize='small'
								color='action'
							/>
							<div>{strings.reasonsAppointment}</div>
						</div>
					}
					style={{ marginBottom: 4, marginTop: 16 }}>
					{motives.map(t => (
						<RosaCalendarOrMotiveListItemComponent
							key={t.id!}
							onClick={() => toggleMotive(t.id!)}
							item={t}
						/>
					))}
				</RosaCalendarCollapseListFilterComponent>
				<RosaCalendarCollapseListFilterComponent
					title={`${strings.users} (ROSA)`}
					style={{ marginBottom: 4, marginTop: 16 }}>
					{hps.map(t => (
						<RosaCalendarOrMotiveListItemComponent
							key={t.id!}
							onClick={() => toggleHp(t.id!)}
							item={t}
						/>
					))}
				</RosaCalendarCollapseListFilterComponent>
			</div>
		</Card>
	);
};
