import { reorder } from 'framework/utils/array/reorder';
import React, { useMemo } from 'react';
import { DragDropContext, Draggable, DraggableProvided, DraggableProvidedDragHandleProps, Droppable, DropResult } from 'react-beautiful-dnd';

interface IProps<T> {
	lines: T[];
	setLines: (lines: T[]) => void;
	marginBottom?: number;
	getKey: (val: T) => string;
	render: (line: T, index: number, props: DraggableProvidedDragHandleProps | undefined) => JSX.Element;
	style?: React.CSSProperties;
}

export const DragAndDropContainer = <T extends unknown>({ lines, setLines, marginBottom, getKey, render, style }: IProps<T>) => {
	const memoizedMarginBottom = useMemo(() => (marginBottom ? marginBottom : 16), [marginBottom]);

	const onDragEndHandler = ({ destination, source }: DropResult) => {
		if (destination !== undefined) {
			setLines(reorder(lines, source.index, destination.index));
		} else {
			// dropped outside the list
		}
	};

	return (
		<DragDropContext onDragEnd={onDragEndHandler}>
			<Droppable droppableId='list'>
				{(provided: any) => (
					<div
						ref={provided.innerRef}
						{...provided.droppableProps}
						className={`fg1${provided.droppableProps.className ? ` ${provided.droppableProps.className}` : ''}`}
						style={{ ...provided.droppableProps.style, ...style }}>
						{lines.map((t, index) => (
							<Draggable
								draggableId={getKey(t)}
								key={getKey(t)}
								index={index}>
								{(provided: DraggableProvided) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										style={{ ...provided.draggableProps.style, marginBottom: memoizedMarginBottom }}>
										{render(t, index, provided.dragHandleProps)}
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};
