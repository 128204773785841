import React from 'react';
import { createHeader } from 'framework/table/createHeader';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ITenderLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	lines: ITenderLine[];
}

export const TenderLinesTable = ({ lines }: IProps) => {
	const strings = useLocalization();
	const headers = [
		createHeader<ITenderLine>('#', t => t.indexNumber.toString()),
		createHeader<ITenderLine>(strings.description, t => t.description),
		createHeader<ITenderLine>(strings.quantity, t => t.quantity.toString()),
		createHeader<ITenderLine>(`${strings.vat} (%)`, t => `${t.vatPercentage} %`),
		createHeader<ITenderLine>(`${strings.discount} (%)`, t => `${t.discountPercentage.toFixed(2)} %`),
		createHeader<ITenderLine>(strings.priceInVat, t => formatCurrency(t.linePriceInVat)),
	];

	return (
		<TableWithHeadersAndValues<ITenderLine>
			values={lines}
			headers={headers}
			getKey={t => t.id}
			withPaperContainer
		/>
	);
};
