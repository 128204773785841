import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import ReplayIcon from '@material-ui/icons/Replay';
import { IPurchaseLine } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: IPurchaseLine;
	style?: React.CSSProperties;
}

export const PurchaseLineStatusIcon = ({ item, style }: IProps) => {
	if (item.isFullyCredited || item.isPartlyCredited) {
		return <ReplayIcon style={style} />;
	} else {
		return <BlurCircularIcon style={style} />;
	}
};
