import { Card, CardContent, CardProps, Typography, useTheme } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { formatDate } from 'framework/utils/date/formatDate';
import { INoahDeviceUsageData, noahDataQuery_actualDevicesForPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps extends CardProps {
	reloadTrigger: Trigger;
	patientId: string;
}

export const LastNoahDevicesCard = ({ reloadTrigger, patientId, ...rest }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [meta, reload] = useApiEffect(noahDataQuery_actualDevicesForPatient, patientId);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	if (meta === undefined) {
		return <></>;
	}

	return (
		<Card {...rest}>
			<CardTitleTypography
				withPadding
				title={strings.lastReadOutDevicesFromNoah}
			/>
			<CardContent style={{ paddingTop: 0 }}>
				<div className='df-col'>
					<div
						className='df-row'
						style={{ marginBottom: theme.spacing(2) }}>
						<SingleUsage
							title={strings.rightEar}
							device={meta.rightHearingInstrument}
							style={{ marginRight: theme.spacing(8) }}
						/>
						<SingleUsage
							title={strings.leftEar}
							device={meta.leftHearingInstrument}
						/>
					</div>
					<SingleUsage
						title={strings.onRemote}
						device={meta.remoteControl}
					/>
				</div>
			</CardContent>
		</Card>
	);
};

interface ISingleUsageProps {
	title: string;
	device: INoahDeviceUsageData;
	style?: React.CSSProperties;
}

const SingleUsage = ({ title, device: item, style }: ISingleUsageProps) => {
	const strings = useLocalization();

	return (
		<div
			className='df-col'
			style={style}>
			<Typography variant='caption'>{title}</Typography>
			{!item && <NoRecordsFound>{strings.notApplicableAbbreviation}</NoRecordsFound>}
			{item && (
				<>
					<LabelledProperty
						label={strings.manufacturer}
						property={item.manufacturer}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.model}
						property={item.instrumentTypeName}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.serialNumberShort}
						property={item.serialNumber}
						withoutMargin
					/>
					<LabelledProperty
						label={strings.date}
						property={formatDate(item.actionCreatedDateFromNoah)}
						withoutMargin
					/>
				</>
			)}
		</div>
	);
};
