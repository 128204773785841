import { Card } from '@material-ui/core';
import React from 'react';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DefaultCardContent } from 'framework/components/DefaultCardContent';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { formatDate } from 'framework/utils/date/formatDate';
import { IInventoryItem } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { InventoryItemDispatchInfoForm } from '../forms/InventoryItemDispatchInfoForm';

interface IProps {
	item: IInventoryItem;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const DispatchInfoCard = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onUpdate = () => {
		open(
			<InventoryItemDispatchInfoForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Card
			style={style}
			className='df-col'>
			<DefaultCardContent>
				<CardTitleTypography
					title={`${strings.dispatchInfo}${item.dispatchInfo.isManualReference ? ` ${strings.manual}` : ''}`}
					edit={onUpdate}
				/>
				<LabelledProperty
					label={strings.dispatchReference}
					property={item.dispatchReference}
				/>
				<LabelledProperty
					label={strings.dispatchDate}
					property={formatDate(item.dispatchDate)}
				/>
			</DefaultCardContent>
		</Card>
	);
};
