import { DialogContent, DialogProps, DialogTitle, Divider, IconButton } from '@material-ui/core';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import CloseIcon from '@material-ui/icons/Close';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import { useLoggedInUserIsSuperUser } from 'app/auth/useLoggedInUserIsSuperUser';
import { HamsClientApplicationContext } from 'app/externalApps/client/HamsClientApplicationContext';
import { useAutoFixCredentials } from 'app/externalApps/client/useAutoFixCredentials';
import { CodeBlock } from 'app/main/test/CodeBlock';
import { CollapseX } from 'framework/components/CollapseX';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { TextLinkLoaderButton } from 'framework/components/buttons/TextLinkLoaderButton';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { DialogX } from 'framework/dialogs/DialogX';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { noahLicense_check } from 'gen/ApiClient';
import { CredentialsError, controls_Restart, status_ExtendedStatus, status_FixAssembly } from 'gen/client/HamsClient';
import { useHandleClientReponse } from 'gen/client/useHandleClientReponse';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useState } from 'react';
import { PreloadContext } from 'shared/context/PreloadContext';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { NoConnectionToClientSoftwareComponent } from './NoConnectionToClientSoftwareComponent';
import { NoahLicenseDownloadDialog } from './NoahLicenseDownloadDialog';

interface IProps extends DialogProps {
	close: VoidFunction;
}

export const HamsClientConfigurationDialog = ({ close, ...rest }: IProps) => {
	const strings = useLocalization();
	const { isInstalled, check, isChecking } = useContext(HamsClientApplicationContext);
	const { requestUrl } = useContext(PreloadContext);
	const [status, reloadStatus] = useApiEffect(status_ExtendedStatus, requestUrl);
	const [canDownloadLicense] = useApiEffect(noahLicense_check);
	const handle = useHandleClientReponse();
	const requestOrRenewCredentials = useAutoFixCredentials();
	const fixAssembly = useApiCall(status_FixAssembly);
	const restart = useApiCall(controls_Restart);
	const notify = useSnackbarNotify();
	const [isFixingAssembly, setIsFixingAssembly] = useState<boolean>(false);
	const { open, confirm, isStacked } = useDialogsContext(check);
	const isSuperUser = useLoggedInUserIsSuperUser();

	useEffect(() => {
		// initial call to check if installed
		check();
		// eslint-disable-next-line
	}, []);

	const onRestart = async () => {
		const r = await restart();
		if (r.isSuccess) {
			reloadStatus();
		}
	};

	const onRefresh = async () => {
		if (isInstalled) {
			await reloadStatus();
		} else {
			await check();
		}
		setIsFixingAssembly(false);
	};

	const onAutoFix = async () => {
		console.log('enters here');
		if (status === undefined) {
			notify('reload status first');
		} else if (status.checkCredentials!.hasError) {
			const error = status.checkCredentials!.error as CredentialsError;
			if (error === 'NoRequestUrlProvided' || error === 'CannotReachServer') {
				notify('Cannot fix this error, contact HAMS', 'warning');
			} else {
				const r = await requestOrRenewCredentials();
				if (r !== undefined) {
					handle(r, 'Fixed!');
					reloadStatus();
				}
			}
		} else if (status.checkAssembly!.hasError) {
			setIsFixingAssembly(true);
			const r = await fixAssembly();
			if (r.isSuccess) {
				handle(r.result, undefined);
			} else {
				setIsFixingAssembly(false);
			}
		}
	};

	const onDownloadNoahLicense = () => {
		open(
			<NoahLicenseDownloadDialog
				open
				close={confirm}
			/>
		);
	};

	return (
		<DialogX
			{...rest}
			open={rest.open && isStacked === false}>
			<div
				className='df-row'
				style={{ position: 'absolute', right: '10px', top: '8px' }}>
				<IconButton onClick={onRefresh}>
					<RefreshOutlinedIcon />
				</IconButton>
				<IconButton onClick={close}>
					<CloseIcon />
				</IconButton>
			</div>
			<DialogTitle>{`${strings.configuration} HAMS-Noah Client`}</DialogTitle>
			<DialogContent
				className='df-col gap-8'
				dividers>
				<VariantLabelledPropertyX variant={isInstalled ? 'success' : 'error'}>
					{isInstalled ? strings.hamsNoahClientIsInstalled : strings.hamsNoahClientIsNotInstalledOrNotActive}
				</VariantLabelledPropertyX>
				{isInstalled === false && (
					<React.Fragment>
						<NoConnectionToClientSoftwareComponent
							check={check}
							isChecking={isChecking}
						/>
					</React.Fragment>
				)}
				{isInstalled === true && (
					<>
						<VariantLabelledPropertyX
							variant={status === undefined ? 'warning' : status.checkAssembly === undefined || status.checkAssembly.hasError ? 'error' : 'success'}>
							{status === undefined
								? `${strings.loadingStatus}...`
								: status.checkAssembly === undefined || status.checkAssembly.hasError
								? strings.faultInConfiguration
								: strings.configurationCorrect}
						</VariantLabelledPropertyX>
						{status === undefined && (
							<TextLinkButton
								startIcon={<RefreshOutlinedIcon />}
								onClick={reloadStatus}>
								{strings.retry}
							</TextLinkButton>
						)}
						{status !== undefined && status.checkAssembly !== undefined && status.checkAssembly.hasError === true && (
							<>
								<VariantLabelledPropertyX variant='error'>{strings.hamsIsNotRegisteredAsMgmtSoftware}</VariantLabelledPropertyX>
								<TextLinkLoaderButton
									isLoading={isFixingAssembly}
									startIcon={<BuildOutlinedIcon />}
									onClick={() => onAutoFix()}>
									{strings.repairVerb}
								</TextLinkLoaderButton>
							</>
						)}
						{status !== undefined && status.checkAssembly !== undefined && status.checkAssembly.hasError === false && (
							<>
								<VariantLabelledPropertyX variant={status.checkNoahLicense !== undefined && status.checkNoahLicense.hasError === false ? 'success' : 'error'}>
									{status.checkNoahLicense !== undefined && status.checkNoahLicense.hasError === false ? strings.license : strings.invalidLicense}
								</VariantLabelledPropertyX>
								{status.checkNoahLicense?.hasError !== false && canDownloadLicense === false && (
									<VariantLabelledPropertyX variant='warning'>{strings.contactHAMSForValidLicense}</VariantLabelledPropertyX>
								)}
								{status.checkNoahLicense?.hasError !== false && canDownloadLicense === true && (
									<TextLinkButton
										startIcon={<GetAppOutlinedIcon />}
										onClick={onDownloadNoahLicense}>
										{strings.download}
									</TextLinkButton>
								)}
							</>
						)}
						<Divider />
						<TextLinkButton
							onClick={onRestart}
							color='secondary'
							hoverColor='secondary'
							startIcon={<RefreshOutlinedIcon />}
							disabled={status?.hasError === false}>
							{strings.restart}
						</TextLinkButton>
					</>
				)}
				{isSuperUser && (
					<>
						<Divider />
						<CollapseX
							collapseAfter
							expandByDefault>
							<CodeBlock
								value={status}
								style={{ margin: 0 }}
							/>
						</CollapseX>
					</>
				)}
			</DialogContent>
		</DialogX>
	);
};
