import React, { useContext } from 'react';
import { createHeader } from 'framework/table/createHeader';
import { PageableTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { ICommission, ICommissionsQueryParams, IQueryResult } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { createDetailsHeader } from 'shared/table/createDetailsHeader';
import { SalesSummaryPaymentStatusAndCreditChip } from '../../sales/components/SalesSummaryPaymentStatusAndCreditChip';
import { CommissionDetailDialog } from './CommissionDetailDialog';
import { CommissionPaidOutStatusChip } from './CommissionPaidOutStatusChip';
import { ViewContext } from './context/ViewContext';
import { SelectableColumn } from './SelectableColumn';

interface IProps {
	queryResult: IQueryResult<ICommission>;
	params: ICommissionsQueryParams;
	setParams: React.Dispatch<React.SetStateAction<ICommissionsQueryParams>>;
	reload: VoidFunction;
}

export const CommissionsTable = ({ queryResult, params, setParams, reload }: IProps) => {
	const strings = useLocalization();
	const { columns, headers } = useContext(ViewContext);
	const { open, confirm } = useDialogsContext(reload);

	const onViewDetail = (id: string) => {
		open(
			<CommissionDetailDialog
				open
				close={confirm}
				id={id}
			/>
		);
	};

	return (
		<PageableTableWithColumnSelection<ICommission, SelectableColumn>
			queryResult={queryResult}
			headers={headers}
			sortParams={params.sortParams}
			pageParams={params.pageParams}
			setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
			getKey={inst => inst.id}
			selectedColumns={columns}
			prependColumn={[
				createHeader<ICommission>(strings.status, t => <CommissionPaidOutStatusChip item={t} />),
				createHeader<ICommission>(`${strings.paymentStatus} (${strings.sale})`, t => <SalesSummaryPaymentStatusAndCreditChip item={t.sale} />),
			]}
			appendColumn={createDetailsHeader<ICommission>(t => onViewDetail(t.id))}
		/>
	);
};
