import { PreloadCacheProvider as FrameworkCacheProvider } from 'framework/context/PreloadCacheProvider';
import { articlePropertyKeysQuery_all, articlePropertyKeysQuery_concurrencyToken, IArticlePropertyKey } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { PreloadContext } from 'shared/context/PreloadContext';
import { ArticlePropertyKeysCacheContext } from './ArticlePropertyKeysCacheContext';

export const ArticlePropertyKeysCacheProvider = ({ children }: any) => {
	const { articlePropertyKeys } = useContext(PreloadContext);

	return (
		<FrameworkCacheProvider<IArticlePropertyKey[]>
			concurrencyF={articlePropertyKeysQuery_concurrencyToken}
			loadF={articlePropertyKeysQuery_all}
			context={ArticlePropertyKeysCacheContext}
			initialData={articlePropertyKeys}>
			{children}
		</FrameworkCacheProvider>
	);
};
