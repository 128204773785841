import { Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { CollapseXTextAndIcon } from 'framework/components/CollapseXTextAndIcon';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { NumberFormatTextField } from 'framework/components/textFields/NumberFormatTextField';
import { FormHelperTextX } from 'framework/forms/common/FormHelperTextX';
import { FormSelectFieldWithOption } from 'framework/forms/FormSelectFieldWithOption';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { IOption } from 'framework/IOption';
import { toggleSelect } from 'framework/utils/array/toggleSelect';
import { ITonePoint, IToneThresholdAudiogram } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

interface IProps<TModel> {
	nameData: keyof TModel;
	toneThresholdAudiograms: IToneThresholdAudiogram[];
	selectedToneThresholdAudiogram: IToneThresholdAudiogram | undefined;
	selectedPoints: ITonePoint[];
	setSelectedPoints: (points: ITonePoint[]) => void;
}

export const ToneThresholdAudiogramPtaComponent = <TModel extends unknown>({
	nameData,
	toneThresholdAudiograms,
	selectedToneThresholdAudiogram,
	selectedPoints,
	setSelectedPoints,
}: IProps<TModel>) => {
	const [isViewAll, toggleIsViewAll] = useStateToggleBoolean(false);
	const strings = useLocalization();
	const [equation, setEquation] = useState<string>('');
	const [average, setAverage] = useState<number>(0);

	useEffect(() => {
		setAverage(_.sum(selectedPoints.map(t => t.stimulusLevel)) / selectedPoints.length);
		setEquation(
			`(${_.orderBy(selectedPoints, t => t.stimulusFrequency)
				.map(t => `${t.stimulusLevel} db HL`)
				.join(' + ')}) / ${selectedPoints.length}`
		);
		// eslint-disable-next-line
	}, [selectedPoints]);

	useEffect(() => {
		if (selectedToneThresholdAudiogram) {
			setSelectedPoints(
				selectedToneThresholdAudiogram.points.filter(t => t.stimulusFrequency === 1000 || t.stimulusFrequency === 2000 || t.stimulusFrequency === 4000)
			);
		} else {
			setSelectedPoints([]);
		}
		// eslint-disable-next-line
	}, [selectedToneThresholdAudiogram]);

	return (
		<>
			<FormSelectFieldWithOption<TModel, IToneThresholdAudiogram>
				name={nameData}
				label={strings.tonalAudiogram}
				options={toneThresholdAudiograms.map<IOption<IToneThresholdAudiogram>>((t, index) => ({
					id: t,
					identifier: `${index + 1} - ${t.hearingInstrumentConditionTypeDisplay} - ${t.signalOutputTypeDisplay}`,
				}))}
				helperText={`NOAH ${strings.data}: Hearing Instrument Condition Type - SignalOutputType`}
			/>
			{selectedToneThresholdAudiogram && (
				<>
					<DividerWithCaption caption={`PTA ${strings.selectWhat(strings.points)}`} />
					<List dense>
						{(isViewAll ? selectedToneThresholdAudiogram.points : _.orderBy(selectedPoints, t => t.stimulusFrequency)).map(t => (
							<ListItem
								key={t.stimulusFrequency.toString()}
								button
								disableGutters
								onClick={() => setSelectedPoints(toggleSelect(t, selectedPoints))}>
								<ListItemIcon>
									<Checkbox checked={selectedPoints.indexOf(t) !== -1} />
								</ListItemIcon>
								<ListItemText primary={`${t.stimulusFrequency}Hz - ${t.stimulusLevel}dB`} />
							</ListItem>
						))}
					</List>
					<CollapseXTextAndIcon
						style={{ marginLeft: 6 }}
						isExpanded={isViewAll}
						toggleIsExpanded={toggleIsViewAll}
					/>
					<DividerWithCaption caption={`PTA ${strings.calculation}`} />
					<NumberFormatTextField
						variant='filled'
						disabled
						label={`PTA`}
						value={average}
						suffix=' db HL'
						decimalScale={0}
					/>
					<FormHelperTextX>{`${strings.calculation}: ${equation}`}</FormHelperTextX>
				</>
			)}
		</>
	);
};
