import { EndLoanForm } from 'app/main/processes/loans/forms/EndLoanForm';
import { IRequestForInsuranceAllowance, ITrialStepDto } from 'gen/ApiClient';
import React, { useState } from 'react';
import { EndAndStartNewTrialForm } from './EndAndStartNewTrialForm';
import { EndFailureTrialForm } from './EndFailureTrialForm';
import { EndTrialAdministrativelyForm } from './EndTrialAdministrativelyForm';
import { EndTrialMode } from './EndTrialMode';
import { EndTrialModeForm } from './EndTrialModeForm';
import { RegisterRfiaTrialForm } from './RegisterRfiaTrialForm';

type State = 'mode' | EndTrialMode;

interface IProps {
	rfia: IRequestForInsuranceAllowance;
	trial: ITrialStepDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const EndRfiaTrial = ({ rfia, trial, cancel, confirm }: IProps) => {
	const [state, setState] = useState<State>('mode');

	if (state === 'mode') {
		return (
			<EndTrialModeForm
				open
				confirm={mode => setState(mode)}
				cancel={cancel}
			/>
		);
	} else if (state === 'EndAdministrative') {
		return (
			<EndTrialAdministrativelyForm
				open
				confirm={confirm}
				cancel={cancel}
				rfiaId={rfia.id}
				loanId={trial.id}
				loanAdministrativeEndDate={trial.onlyAdministrativeTrialEndDate}
			/>
		);
	} else if (state === 'EndPositive') {
		return (
			<EndLoanForm
				open
				confirm={confirm}
				cancel={cancel}
				loanId={trial.id}
			/>
		);
	} else if (state === 'NegativeResult') {
		return (
			<EndFailureTrialForm
				open
				confirm={confirm}
				cancel={cancel}
				rfiaId={rfia.id}
			/>
		);
	} else if (state === 'StartCompareNew') {
		return (
			<RegisterRfiaTrialForm
				open
				confirm={confirm}
				cancel={cancel}
				rfia={rfia}
			/>
		);
	} else {
		return (
			<EndAndStartNewTrialForm
				confirm={confirm}
				cancel={cancel}
				rfia={rfia}
			/>
		);
	}
};
