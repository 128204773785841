import { FilledInput, InputAdornment } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { NumberFormatProps } from '../components/textFields/NumberFormatProps';
import { NumberFormatX } from '../components/textFields/NumberFormatX';
import { useStateBoolean } from '../hooks/useStateBool';
import { matchNullOrEmptyToUndefined } from '../utils/matchNullOrEmptyToUndefined';
import { nameOf } from '../utils/nameOf';
import { ClearEndAdornment } from './common/ClearEndAdornment';
import { FormFilledControl } from './common/FormFilledControl';
import { IFormProps } from './common/IFormProps';
import { useFilledInputStyles } from './common/useFilledInputStyles';

interface IProps<TModel> extends IFormProps<TModel>, NumberFormatProps {
	startAdornment?: JSX.Element;
	endAdornment?: JSX.Element;
}

export const FormNumberField = <TModel extends unknown>({ startAdornment, endAdornment, ...props }: IProps<TModel>) => {
	const [field, meta, helpers] = useField<number>(nameOf(props.name));
	const [isHovered, enter, leave] = useStateBoolean(false);
	const [isFocused, focus, blur] = useStateBoolean(false);
	const classes = useFilledInputStyles();

	return (
		<FormFilledControl
			meta={meta}
			enter={enter}
			leave={leave}
			{...props}>
			<FilledInput
				{...field}
				onChange={e => {
					helpers.setTouched(true);
					helpers.setValue(matchNullOrEmptyToUndefined<number>(e.target.value) as any);
				}}
				onFocus={focus}
				onBlur={blur}
				className={classes.filledInput}
				startAdornment={(isFocused || field.value !== undefined) && startAdornment && <InputAdornment position='start'>{startAdornment}</InputAdornment>}
				endAdornment={
					<>
						{endAdornment && isHovered && endAdornment}
						<ClearEndAdornment
							isHovered={isHovered}
							field={field}
							helpers={helpers}
							disabled={props.disabled ?? false}
							defaultValue={null as any}
						/>
					</>
				}
				inputComponent={NumberFormatX as any}
				inputProps={props}
			/>
		</FormFilledControl>
	);
};
