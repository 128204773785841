import { Theme } from '@material-ui/core';
import { Color } from '@material-ui/lab';

export const colorToThemeColor = (color: Color | 'primary', theme: Theme, fallBack: string = 'inherit'): string => {
	if (color === 'success') {
		return theme.palette.success.main;
	} else if (color === 'warning') {
		return theme.palette.warning.main;
	} else if (color === 'error') {
		return theme.palette.error.main;
	} else if (color === 'primary') {
		return theme.palette.primary.main;
	} else {
		return fallBack;
	}
};
