import { Avatar, useTheme } from '@material-ui/core';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	setSelected: (val: number[]) => void;
	selected: number[];
}

export const ByWeekdaySelection = ({ selected, setSelected }: IProps) => {
	const strings = useLocalization();

	const onClick = (val: number) => {
		if (selected.indexOf(val) > -1) {
			setSelected(selected.filter(t => t !== val));
		} else {
			setSelected([...selected, val]);
		}
	};

	return (
		<div className='df-row-ac'>
			<WeekDayAvatar
				isSelected={selected.indexOf(0) > -1}
				onClick={() => onClick(0)}>
				{strings.firstLetterOfMonday}
			</WeekDayAvatar>
			<WeekDayAvatar
				isSelected={selected.indexOf(1) > -1}
				onClick={() => onClick(1)}>
				{strings.firstLetterOfTuesday}
			</WeekDayAvatar>
			<WeekDayAvatar
				isSelected={selected.indexOf(2) > -1}
				onClick={() => onClick(2)}>
				{strings.firstLetterOfWednesday}
			</WeekDayAvatar>
			<WeekDayAvatar
				isSelected={selected.indexOf(3) > -1}
				onClick={() => onClick(3)}>
				{strings.firstLetterOfThursday}
			</WeekDayAvatar>
			<WeekDayAvatar
				isSelected={selected.indexOf(4) > -1}
				onClick={() => onClick(4)}>
				{strings.firstLetterOfFriday}
			</WeekDayAvatar>
			<WeekDayAvatar
				isSelected={selected.indexOf(5) > -1}
				onClick={() => onClick(5)}>
				{strings.firstLetterOfSaturday}
			</WeekDayAvatar>
			<WeekDayAvatar
				isSelected={selected.indexOf(6) > -1}
				onClick={() => onClick(6)}>
				{strings.firstLetterOfSunday}
			</WeekDayAvatar>
		</div>
	);
};

interface IWeekDayAvatarProps {
	isSelected: boolean;
	onClick: VoidFunction;
	children: any;
}

const WeekDayAvatar = ({ isSelected, onClick, children }: IWeekDayAvatarProps) => {
	const theme = useTheme();

	return (
		<Avatar
			onClick={onClick}
			style={{
				backgroundColor: isSelected ? theme.palette.primary.main : '#bdbdbd',
				width: '20px',
				height: '20px',
				fontSize: '12px',
				cursor: 'pointer',
				marginRight: 10,
			}}>
			{children}
		</Avatar>
	);
};
