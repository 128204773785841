import { FilledInput, InputAdornment, Menu, MenuItem } from '@material-ui/core';
import { useField } from 'formik';
import React, { useEffect } from 'react';
import { useStateBoolean } from '../hooks/useStateBool';
import { FormFilledControl } from './common/FormFilledControl';
import { IFormProps } from './common/IFormProps';
import { useFilledInputStyles } from './common/useFilledInputStyles';
import { ClearEndAdornment } from './common/ClearEndAdornment';
import { makeANumber } from '../utils/makeANumber';
import { nameOf } from '../utils/nameOf';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { theme } from '../../theme';
import { useAnchorElement } from '../hooks/useAnchorElement';

interface IProps<TModel, TUnit> extends IFormProps<TModel> {
	unitName: keyof TModel;
	units: TUnit[];
	defaultUnit: TUnit;
	renderUnit: (val: number, unit: TUnit) => string;
}

export const FormIntegerFieldWithUnit = <TModel extends unknown, TUnit extends string>({
	unitName,
	units,
	renderUnit,
	defaultUnit,
	...props
}: IProps<TModel, TUnit>) => {
	const [field, meta, helpers] = useField<number>(nameOf(props.name));
	// eslint-disable-next-line
	const [unitField, _, unitHelpers] = useField<TUnit>(nameOf(unitName));
	const [isHovered, enter, leave] = useStateBoolean(false);
	const classes = useFilledInputStyles();
	const [anchor, openMenu, closeMenu] = useAnchorElement();

	useEffect(() => {
		if (unitField.value === undefined || unitField.value === null) {
			unitHelpers.setValue(defaultUnit);
		}
		// eslint-disable-next-line
	}, [unitField.value]);

	return (
		<FormFilledControl
			meta={meta}
			enter={enter}
			leave={leave}
			{...props}>
			<FilledInput
				{...field}
				fullWidth
				onChange={e => helpers.setValue(makeANumber(e.target.value))}
				className={classes.filledInput}
				type='text'
				inputProps={{ pattern: '[0-9]*' }}
				endAdornment={
					<>
						<ClearEndAdornment
							isHovered={isHovered}
							field={field}
							helpers={helpers}
							disabled={props.disabled ?? false}
							defaultValue={0}
						/>
						<InputAdornment position='end'>
							<Menu
								anchorEl={anchor}
								open={Boolean(anchor)}
								onClose={closeMenu}>
								{units.map(t => (
									<MenuItem
										key={t}
										value={t}
										onClick={() => {
											unitHelpers.setValue(t);
											closeMenu();
										}}>
										{renderUnit(field.value, t)}
									</MenuItem>
								))}
							</Menu>
							<div
								className='df-row-ac'
								style={{ color: theme.palette.text.secondary, cursor: 'pointer' }}
								onClick={openMenu}>
								<div style={{ fontSize: '14px' }}>{renderUnit(field.value, unitField.value)}</div>
								<ArrowDropDownIcon />
							</div>
						</InputAdornment>
					</>
				}
			/>
		</FormFilledControl>
	);
};
