import { RRule, Frequency, rrulestr } from 'rrule';
import { ICalendarEventDto } from 'gen/ApiClient';

export const createInitialRRule = (event: ICalendarEventDto | undefined): RRule => {
	if (event !== undefined && event.isRecurring && event.rRule) {
		return rrulestr(event.rRule!);
	} else {
		return new RRule({ freq: Frequency.DAILY, interval: 1 });
	}
};
