import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { ClientClaim, applicationSettingsQuery_appSettings } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { NoahDeviceReadOutModeForm } from './forms/NoahDeviceReadOutModeForm';
import { PatchClientClaimsRequestForm } from './forms/PatchClientClaimsRequestForm';
import { ClientClaimRecord } from './ClientClaimRecord';

export const ApplicationSettingsContent = ({ reloadTrigger }: IReloadTriggerProps) => {
	const [data, reload] = useApiEffect(applicationSettingsQuery_appSettings);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	const onPatchNoahDeviceReadOutMode = () => {
		open(
			<NoahDeviceReadOutModeForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onPatchClientClaims = () => {
		open(
			<PatchClientClaimsRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				clientClaims={data?.clientClaims.map<ClientClaim>(t => t as ClientClaim) ?? []}
			/>
		);
	};

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<div className='df-col gap-6'>
			<Typography16pxBold>{`App settings`}</Typography16pxBold>
			<CaptionVerticalLabelledPropertyX
				label={`Noah device read out mode`}
				edit={onPatchNoahDeviceReadOutMode}>
				{data.noahDeviceReadOutMode}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={`Client Claims`}
				edit={onPatchClientClaims}>
				<div className='df-col'>
					{data.clientClaims.map(t => (
						<span key={t}>{ClientClaimRecord[t as ClientClaim]}</span>
					))}
				</div>
			</CaptionVerticalLabelledPropertyX>
		</div>
	);
};
