import { makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
	iconStyle: {
		'& > svg': {
			fontSize: '1.25rem',
			color: theme.palette.text.secondary,
			height: '1.25rem',
		},
	},
}));

interface IProps {
	text: string;
	route?: string;
	icon?: React.ReactNode;
	slashBefore?: boolean;
}

export const Breadcrumb = ({ text, route, icon, slashBefore }: IProps) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const { push } = useHistory();

	return (
		<div className='df-row-ac'>
			{slashBefore === true && (
				<Typography
					variant='h2'
					style={{ margin: '0px 4px' }}>
					/
				</Typography>
			)}
			{icon && (
				<div
					className={styles.iconStyle}
					style={{ height: '1.25rem', marginRight: '2px' }}>
					{icon}
				</div>
			)}
			<Typography
				variant='h2'
				style={{ cursor: route ? 'pointer' : 'inherit' }}
				onClick={() => (route ? push(route) : {})}>
				{text}
			</Typography>
			{slashBefore !== true && (
				<Typography
					variant='h2'
					style={{ margin: '0px 4px' }}>
					/
				</Typography>
			)}
		</div>
	);
};
