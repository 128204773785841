import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { dailyReceiptsQuery_concurrencyToken, dailyReceiptsQuery_query, IDailyReceipt, IDailyReceiptsQueryParams, IQueryResult } from 'gen/ApiClient';
import { dailyReceiptsQueryParamsLsKey } from '../../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: IDailyReceiptsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	isGlobal: false,
	locationIds: [],
	referenceDateAfter: undefined,
	referenceDateBefore: undefined,
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IDailyReceipt>, IDailyReceiptsQueryParams>
			defaultParams={DefaultParams}
			queryF={dailyReceiptsQuery_query}
			concurrencyF={dailyReceiptsQuery_concurrencyToken}
			localStorageKey={dailyReceiptsQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
