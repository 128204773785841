import { EntPhysicianExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createEntPhysiciansExportRecord = (strings: IStrings): Record<EntPhysicianExportProp, string> => {
	return {
		Id: strings.uniqueReference,
		Created: strings.createdAt,
		LastUpdated: strings.lastUpdated,
		FirstName: strings.firstName,
		LastName: strings.lastName,
		Gender: strings.gender,
		OfficesCsv: strings.offices,
		Salutation: strings.salutation,
		RizivCode: strings.RIZIVNumber,
		CountRequestsToInsuranceAllowance: strings.requestForInsuranceAllowancesShortened,
	};
};
