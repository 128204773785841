import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { inventoryItemsQuery_detail } from 'gen/ApiClient';
import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AnnotationsCard } from 'shared/annotations/AnnotationsCard';
import { RestrictedPageX } from 'shared/layout/RestrictedPageX';
import { NavigationContext } from '../context/NavigationContext';
import { InventoryItemDetailPageHeader } from './InventoryItemDetailPageHeader';
import { InventoryItemDetailPageSidebar } from './InventoryItemDetailPageSidebar';
import { OverviewPage } from './OverviewPage';
import { TransactionsPage } from './TransactionsPage';

export const InventoryItemDetail = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [trigger, hitTrigger] = useTrigger();
	const [detail, reload] = useApiEffect(inventoryItemsQuery_detail, id);
	const item = useMemo(() => detail?.data, [detail]);
	const { link, setLink } = useContext(NavigationContext);

	useLazyEffect(() => {
		reload();
	}, [trigger]);

	if (item === undefined || detail === undefined) {
		return <div></div>;
	}

	return (
		<RestrictedPageX
			pageClaim='InventoryItems'
			maxWidth={1280}
			stickyHeader={
				<InventoryItemDetailPageHeader
					tab={link}
					setTab={setLink}
					item={item}
					detail={detail}
					reload={hitTrigger}
				/>
			}
			stickySidebar={
				<InventoryItemDetailPageSidebar
					item={item}
					reload={reload}
					style={{ minWidth: 250 }}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				{link === 'overview' && (
					<OverviewPage
						detail={detail}
						reload={hitTrigger}
					/>
				)}
				{link === 'transactions' && (
					<TransactionsPage
						item={item}
						reload={hitTrigger}
					/>
				)}
				{link === 'comments' && (
					<AnnotationsCard
						contextId={item.id}
						linkViewContextType='InventoryItem'
						reload={hitTrigger}
						reloadTrigger={trigger}
					/>
				)}
			</div>
		</RestrictedPageX>
	);
};
