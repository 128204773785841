import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IPatchRosaHamsSyncRecordRequest, rosaSyncCommand_patchSyncRecord } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { getNow } from 'shared/utils/getNow';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<IPatchRosaHamsSyncRecordRequest>({
			eventsLastUpdatedSince: yup.date().required(),
			patientLastUpdatedSince: yup.date().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: () => void;
	cancel: () => void;
}

export const PatchRosaHamsSyncRecordForm = ({ confirm, cancel, ...rest }: IProps) => {
	const [submit, isSubmitting] = useFormSubmit(rosaSyncCommand_patchSyncRecord);
	const now = useMemo(() => getNow(), []);

	const handleSubmit = async (values: IPatchRosaHamsSyncRecordRequest, helpers: FormikHelpers<IPatchRosaHamsSyncRecordRequest>) => {
		const r = await submit(values);
		if (handleValidateResponse(r, helpers, 'eventsLastUpdatedSince')) {
			confirm();
		}
	};

	return (
		<Formik<IPatchRosaHamsSyncRecordRequest>
			validateOnMount
			initialValues={{
				eventsLastUpdatedSince: now,
				patientLastUpdatedSince: now,
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{`Patch sync record`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormDatePicker<IPatchRosaHamsSyncRecordRequest>
							name='patientLastUpdatedSince'
							label='Patients last updated since'
							required
						/>
						<FormDatePicker<IPatchRosaHamsSyncRecordRequest>
							name='eventsLastUpdatedSince'
							label='Events last updated since'
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<IPatchRosaHamsSyncRecordRequest>
						cancel={cancel}
						submitText='Submit'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
