import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { IFile } from 'framework/utils/IFile';
import { formatDate } from 'framework/utils/date/formatDate';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { businessDataCommand_uploadAlternativeLogo, businessDataCommand_uploadLogo, businessDataQuery_details } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { UploadFileDialog } from 'shared/dialogs/upload/UploadFileDialog';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IReloadTriggerProps } from 'shared/layout/IReloadTriggerProps';
import { useFindUser } from '../users/useFindUser';
import { BusinessDataAddressForm } from './forms/BusinessDataAddressForm';
import { BusinessDataAlternativeAddressForm } from './forms/BusinessDataAlternativeAddressForm';
import { BusinessDataBankForm } from './forms/BusinessDataBankForm';
import { BusinessDataMainUserForm } from './forms/BusinessDataMainUserForm';
import { PatchBusinessDataGeneralInformationForm } from './forms/PatchBusinessDataGeneralInformationForm';

export const BusinessDataContent = ({ reloadTrigger }: IReloadTriggerProps) => {
	const strings = useLocalization();
	const [data, reload] = useApiEffect(businessDataQuery_details);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const mainUser = useFindUser(data?.mainUserId);
	const [uploadLogo, isUploadingLogo] = useFormSubmit(businessDataCommand_uploadLogo);
	const [uploadAlternativeLogo, isUploadingAlternativeLogo] = useFormSubmit(businessDataCommand_uploadAlternativeLogo);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	const onChangeLogo = (primary: boolean) => {
		open(
			<UploadFileDialog
				open
				title={strings.selectWhat(primary ? strings.logo : strings.alternativeLogo)}
				confirm={() => {}}
				confirmF={file => onFileSelected(file, primary)}
				isUploading={isUploadingLogo || isUploadingAlternativeLogo}
				cancel={cancel}
				accept='images'
			/>
		);
	};

	const onFileSelected = async (file: IFile, primary: boolean) => {
		const r = primary
			? await uploadLogo({ contentAsBase64: file.contentAsBase64, fileName: file.fileName })
			: await uploadAlternativeLogo({ contentAsBase64: file.contentAsBase64, fileName: file.fileName });
		if (r.isSuccess) {
			confirm();
		}
	};

	if (data === undefined) {
		return <div></div>;
	}

	const onEditGeneral = () => {
		open(
			<PatchBusinessDataGeneralInformationForm
				data={data}
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onEditAddress = () => {
		open(
			<BusinessDataAddressForm
				open
				confirm={confirm}
				cancel={cancel}
				data={data.address}
			/>
		);
	};
	const onEditAlternativeAddress = () => {
		open(
			<BusinessDataAlternativeAddressForm
				open
				confirm={confirm}
				cancel={cancel}
				data={data.alternativeAddress}
			/>
		);
	};

	const onEditBank = () => {
		open(
			<BusinessDataBankForm
				open
				confirm={confirm}
				cancel={cancel}
				data={data}
			/>
		);
	};

	const onChangeMainUser = () => {
		open(
			<BusinessDataMainUserForm
				open
				confirm={confirm}
				cancel={cancel}
				initialId={data.mainUserId}
			/>
		);
	};

	return (
		<div
			className='df-row-wrap'
			style={{ gap: 48 }}>
			<div className='df-col gap-6'>
				<Typography16pxBold>{strings.general}</Typography16pxBold>
				<CaptionVerticalLabelledPropertyX
					label={strings.name}
					edit={onEditGeneral}>
					<StringValueOrNotApplicable>{data.companyName}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.lastUpdated}>{formatDate(data.lastUpdated, 'date-and-time')}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.address}
					edit={onEditAddress}>
					<StringValueOrNotApplicable style={{ whiteSpace: 'pre' }}>{data.formattedAddressMultipleLines}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.alternativeAddress}
					edit={onEditAlternativeAddress}>
					<StringValueOrNotApplicable style={{ whiteSpace: 'pre' }}>{data.formattedAlternativeAddressMultipleLines}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.phone}
					edit={onEditGeneral}>
					<StringValueOrNotApplicable>{data.phone}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.website}
					edit={onEditGeneral}>
					<StringValueOrNotApplicable>{data.website}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.email}
					edit={onEditGeneral}>
					<StringValueOrNotApplicable>{data.email}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.enterpriseNumber}
					edit={onEditGeneral}>
					<StringValueOrNotApplicable>{data.enterpriseNumber}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.vatNumber}
					edit={onEditGeneral}>
					<StringValueOrNotApplicable>{data.vatNumber}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
			</div>
			<div className='df-col gap-6'>
				<Typography16pxBold>{strings.bankData}</Typography16pxBold>
				<CaptionVerticalLabelledPropertyX
					label='IBAN'
					edit={onEditBank}>
					<StringValueOrNotApplicable>{data.ibanBankAccount}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label='BIC'
					edit={onEditBank}>
					<StringValueOrNotApplicable>{data.bicBankAccount}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<Typography16pxBold>{strings.user}</Typography16pxBold>
				<CaptionVerticalLabelledPropertyX
					label={strings.mainUser}
					edit={onChangeMainUser}>
					<StringValueOrNotApplicable>{mainUser?.identifier}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
			</div>
			<div className='df-col gap-6'>
				<Typography16pxBold>{`Logo's`}</Typography16pxBold>
				<CaptionVerticalLabelledPropertyX
					label={strings.logo}
					edit={() => onChangeLogo(true)}>
					{isNullOrUndefined(data.logoImageData) === false ? (
						<img
							src={`data:image/png;base64,${data.logoImageData}`}
							alt='logo'
							style={{ maxWidth: '100px', maxHeight: '100px' }}
						/>
					) : (
						<NoRecordsFound variant='caption'>{strings.noLogoPresent}</NoRecordsFound>
					)}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={strings.alternativeLogo}
					edit={() => onChangeLogo(false)}>
					{isNullOrUndefined(data.alternativeLogoImageData) === false ? (
						<img
							src={`data:image/png;base64,${data.alternativeLogoImageData}`}
							alt='logo'
							style={{ maxWidth: '100px', maxHeight: '100px' }}
						/>
					) : (
						<NoRecordsFound variant='caption'>{strings.noLogoPresent}</NoRecordsFound>
					)}
				</CaptionVerticalLabelledPropertyX>
			</div>
		</div>
	);
};
