import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext } from 'react';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { INotificationsQueryParams, NotificationType, NotificationTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { RecordContext } from 'shared/records/RecordContext';

const exclusions: (keyof INotificationsQueryParams)[] = [];

interface IProps {
	params: INotificationsQueryParams;
	onFilter: (params: INotificationsQueryParams) => void;
	paddingLeft: number;
}

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const { notificationTypeRecord } = useContext(RecordContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterItem
				label={strings.date}
				after={params.after}
				before={params.before}
				setSelected={(after, before) => onFilter({ ...params, after: after, before: before })}
			/>
			<RecordListFilterComponent<NotificationType>
				options={NotificationTypes}
				record={notificationTypeRecord}
				selected={params.types as any}
				label={strings.type}
				icon={<HelpOutlineIcon />}
				setSelected={vals => onFilter({ ...params, types: vals })}
			/>
		</FilterBar>
	);
};
