import React from 'react';
import { SettingsPageViewProvider } from './SettingsPageViewProvider';
import { LocationsProviders } from './locations/LocationsProviders';
import { PaymentMethodsProviders } from './paymentMethods/PaymentMethodsProviders';
import { RoomsProviders } from './rooms/context/RoomsProviders';
import { TemplatesProviders } from './templates/TemplatesProviders';
import { UsersProviders } from './users/UsersProviders';

export const SettingsProviders = ({ children }: any) => {
	return (
		<SettingsPageViewProvider>
			<LocationsProviders>
				<RoomsProviders>
					<TemplatesProviders>
						<UsersProviders>
							<PaymentMethodsProviders>{children}</PaymentMethodsProviders>
						</UsersProviders>
					</TemplatesProviders>
				</RoomsProviders>
			</LocationsProviders>
		</SettingsPageViewProvider>
	);
};
