import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createColumnRecord = (strings: IStrings): Record<SelectableColumn, string> => {
	return {
		countInBackOrderChange: `# ${strings.inBackOrder}`,
		countInRepairChange: `# ${strings.repair}`,
		countInStockChange: `# ${strings.inStock}`,
		countInTestOrLoanChange: `# ${strings.inTest}`,
		date: strings.referenceDate,
		stockValueChange: strings.stockValue,
	};
};
