import { Dialog, DialogContent, DialogProps, useTheme } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setLineErrors } from 'framework/forms/utils/setLineErrors';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { TableWithHeadersAndValues } from 'framework/table/TableWithHeadersAndValues';
import {
	IRegisterRepairDeliveryRequest,
	IRepair,
	IRepairDeliveryLineModel,
	IRepairLine,
	ITimeModel,
	TimeUnit,
	deliveriesQuery_nextReference,
	repairsCommand_registerDelivery,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo, useState } from 'react';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { LineErrorComponent } from 'shared/searchAndSelect/LineErrorComponent';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { FormSelectLocationField } from '../../../settings/locations/forms/FormSelectLocationField';
import { createHeaders } from './utils/createHeaders';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterRepairDeliveryRequest>({
			date: yup.date().required(strings.formRequired(strings.date)),
			lines: yup.mixed(),
			toLocationId: yup.string().required(strings.formRequired(strings.toLocation)),
			expectedHandoverDate: yup.date().required(strings.formRequired(strings.expectedHandoverDate)),
			repairId: yup.string().required(),
			reference: yup.string().required(),
			isAutoSelectReference: yup.bool(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IRegisterRepairDeliveryRequest)[]> = {
	0: ['date', 'toLocationId', 'expectedHandoverDate'],
	1: ['lines'],
};

export interface IDeliveryLineWithSelection {
	id: string;
	isSelected: boolean;
	repairLine: IRepairLine;
	hasNewSerialNumber: boolean;
	newSerialNumber: string | undefined;
	quantity: number;
	error?: string | undefined;
}

const toLine = (t: IRepairLine): IDeliveryLineWithSelection => {
	return { id: t.id, isSelected: true, repairLine: t, hasNewSerialNumber: false, newSerialNumber: undefined, quantity: t.quantity };
};

const toLineModel = (t: IDeliveryLineWithSelection, index: number): IRepairDeliveryLineModel => {
	return {
		repairLineId: t.repairLine.id,
		quantity: t.quantity,
		newSerialNumber: t.newSerialNumber,
		hasNewSerialNumber: t.hasNewSerialNumber,
		zeroBasedIndexNumber: index,
	};
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	repair: IRepair;
}

const calculateHasError = (lines: IDeliveryLineWithSelection[]) => {
	if (lines.filter(t => t.isSelected && t.hasNewSerialNumber && !t.newSerialNumber).length > 0) {
		return true;
	} else if (lines.filter(t => t.isSelected && t.repairLine.quantity < t.quantity).length > 0) {
		return true;
	} else if (lines.filter(t => t.isSelected).length === 0) {
		return true;
	} else {
		return false;
	}
};

export const RegisterRepairDeliveryRequestForm = ({ confirm, cancel, repair, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [update, isSubmitting] = useFormSubmit(repairsCommand_registerDelivery);
	const [step, setStep] = useState<number>(0);
	const [lines, setLines] = useState<IDeliveryLineWithSelection[]>(repair.notRedeliveredLines.map(t => toLine(t)));
	const [hasTouchedSubmit, setHasTouchedSubmit] = useState<boolean>(false);
	const now = useNow();

	const handleSubmit = async (values: IRegisterRepairDeliveryRequest, helpers: FormikHelpers<IRegisterRepairDeliveryRequest>) => {
		// TODO validate here
		if (calculateHasError(lines)) {
			setHasTouchedSubmit(true);
		} else {
			const r = await update({ ...values, lines: lines.filter(t => t.isSelected).map((t, index) => toLineModel(t, index)) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				notify(strings.addedWhat(strings.delivery));
				confirm();
			} else {
				setLineErrors('lines', r, lines, setLines);
			}
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterRepairDeliveryRequest>
			validateOnMount
			initialValues={{
				lines: [],
				date: now,
				toLocationId: '',
				expectedHandoverDate: now,
				repairId: repair.id,
				reference: '',
				isAutoSelectReference: true,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					lines={lines}
					setLines={setLines}
					hasTouchedSubmit={hasTouchedSubmit}
					cancel={cancel}
					isSubmitting={isSubmitting}
					repair={repair}
					now={now}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	lines: IDeliveryLineWithSelection[];
	setLines: (lines: IDeliveryLineWithSelection[]) => void;
	hasTouchedSubmit: boolean;
	cancel: VoidFunction;
	isSubmitting: boolean;
	repair: IRepair;
	now: Date;
}

const InnerDialog = ({ step, setStep, lines, setLines, hasTouchedSubmit, cancel, isSubmitting, repair, now, ...rest }: IInnerDialogProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const props = useFormikContext<IRegisterRepairDeliveryRequest>();
	const headers = useMemo(() => createHeaders(lines, setLines, hasTouchedSubmit, strings), [lines, setLines, hasTouchedSubmit, strings]);
	const forPatientAndStep = useMemo(() => repair.handoverToPatientStepIncluded && repair.isForPatient, [repair]);
	const units = useMemo(() => ['Days', 'Weeks'] as TimeUnit[], []);
	const defaultTimeModel = useMemo(() => ({ unit: 'Days', value: 10 } as ITimeModel), []);
	useNextReferenceInterceptor(deliveriesQuery_nextReference, props);

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='lg'>
			<DialogTitleWithFormStepper
				title={strings.registerWhat(strings.delivery)}
				step={step}
				labels={[strings.date, strings.items]}
			/>
			<DialogContent
				dividers
				style={{ padding: step === 1 ? '0px' : '8px 24px' }}>
				<div className='df-col'>
					{step === 0 && (
						<>
							<FormSelectLocationField<IRegisterRepairDeliveryRequest>
								name='toLocationId'
								label={strings.toLocation}
								helperText={strings.deliveryHelperText}
								required
							/>
							<FormDatePicker<IRegisterRepairDeliveryRequest>
								name='date'
								label={strings.deliveryDate}
								required
							/>
							<FormSingleCheckboxField<IRegisterRepairDeliveryRequest>
								name='isAutoSelectReference'
								label={strings.autoSelectIndexNumberQuestion}
							/>
							<FormTextField<IRegisterRepairDeliveryRequest>
								name='reference'
								label={strings.reference}
								disabled={props.values.isAutoSelectReference}
							/>
							{forPatientAndStep && (
								<FormDatePickerWithUnit<IRegisterRepairDeliveryRequest>
									name='expectedHandoverDate'
									fromDate={now}
									label={strings.expectedWhat(strings.handoverDate)}
									required
									units={units}
									defaultUnit='Days'
									defaultValue={defaultTimeModel}
								/>
							)}
						</>
					)}
					{step === 1 && (
						<>
							<TableWithHeadersAndValues<IDeliveryLineWithSelection>
								padding={theme.spacing(1)}
								headers={headers}
								getKey={t => t.id}
								values={lines}
								expandableContent={t => (
									<LineErrorComponent
										line={t}
										style={{ marginLeft: '10px' }}
									/>
								)}
								expandAll={true}
							/>
						</>
					)}
				</div>
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
