import { DialogProps } from '@material-ui/core';
import { companiesCommand_patchContact, ICompany, IContactModel } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { ContactForm } from 'shared/ContactForm';

const toModel = (supplier: ICompany): IContactModel => {
	return {
		firstName: supplier.firstName,
		lastName: supplier.lastName,
		salutation: supplier.salutation,
	};
};

interface IProps extends DialogProps {
	company: ICompany;
	cancel: VoidFunction;
	confirm: VoidFunction;
}

export const PatchCompanyContactForm = ({ company, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<ContactForm
			fullWidth
			title={strings.changeSupplierContactPerson}
			model={toModel(company)}
			confirm={confirm}
			submitFunction={model => companiesCommand_patchContact({ vatCustomerId: company.id, contactInfo: model })}
			cancel={cancel}
			{...rest}
		/>
	);
};
