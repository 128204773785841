import { ChipX } from 'framework/components/chips/ChipX';
import { ColorX } from 'framework/components/color/ColorX';
import { IPurchase, PaymentStatus } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { RecordContext } from 'shared/records/RecordContext';

interface IProps {
	item: IPurchase;
	color: ColorX;
	style?: React.CSSProperties;
}

export const PurchasePaymentStatusChip = ({ item, color, style }: IProps) => {
	const { paymentStatusRecord } = useContext(RecordContext);

	return (
		<ChipX
			label={paymentStatusRecord[item.paymentStatus as PaymentStatus]}
			color={color}
			style={style}
		/>
	);
};
