import { Button, LinearProgress, Typography, useTheme } from '@material-ui/core';
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import TouchAppOutlinedIcon from '@material-ui/icons/TouchAppOutlined';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { useLocalization } from 'localization/useLocalization';
import { FileType } from './FileType';

const dropZoneStyle: React.CSSProperties = {
	width: '100%',
	border: 'dashed',
	cursor: 'pointer',
	overflow: 'hidden',
	// minHeight: '250px',
	borderColor: 'rgba(0,0,0,0.12)',
	borderRadius: '4px',
	backgroundColor: '#fff',
	outline: 'none',
};

const acceptImages: string = '.jpg,.jpeg,.png,image/gif,image/jpeg,image/png';
const acceptExcel: string = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const acceptWord: string = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword';
const acceptPdf: string = 'application/pdf,.pdf';
const acceptXml: string = 'application/xml,.xml';
const acceptExe: string = '.exe';

const acceptFileTypeRecord: Record<FileType, string> = {
	nhaxOrEnhax: '.nhax,.enhax',
	images: acceptImages,
	excel: acceptExcel,
	word: acceptWord,
	pdf: acceptPdf,
	scanOrUpload: `${acceptImages},${acceptPdf},${acceptWord}`,
	annotation: `${acceptImages},${acceptExcel},${acceptWord},${acceptPdf},text/csv,text/plain`,
	xml: acceptXml,
	exe: acceptExe,
};

interface IProps {
	isUploading: boolean;
	onFilesSelected: (files: File[]) => void;
	accept: FileType;
}

// https://github.com/react-dropzone/react-dropzone
export const UploadFile = ({ isUploading, onFilesSelected, accept }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();

	const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
		if (acceptedFiles !== undefined && acceptedFiles !== null && acceptedFiles.length > 0) {
			onFilesSelected(acceptedFiles as File[]);
		} else if (rejectedFiles !== undefined && rejectedFiles !== null && rejectedFiles.length > 0) {
			notify(strings.thisFileExtensionIsNotSupported, 'warning');
		}
		// eslint-disable-next-line
	}, []);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: acceptFileTypeRecord[accept] });

	return (
		<React.Fragment>
			{isUploading === false && (
				<div
					{...getRootProps()}
					style={{ ...dropZoneStyle }}>
					<input {...getInputProps()} />
					<Inner
						isDragActive={isDragActive}
						isUploading={isUploading}
					/>
				</div>
			)}
			{isUploading === true && (
				<div style={{ ...dropZoneStyle }}>
					<Inner
						isDragActive={isDragActive}
						isUploading={isUploading}
					/>
				</div>
			)}
		</React.Fragment>
	);
};

interface IInnerProps {
	isDragActive: boolean;
	isUploading: boolean;
}

const Inner = ({ isDragActive, isUploading }: IInnerProps) => {
	const theme = useTheme();
	const strings = useLocalization();

	return (
		<div
			className='df-col-ac'
			style={{ padding: theme.spacing(6), backgroundColor: isDragActive ? theme.palette.background.paper : theme.palette.background.default }}>
			<Typography style={{ fontSize: '32px' }}>{isDragActive ? strings.dropDocumentHere : strings.uploadADocument}</Typography>
			<Button
				variant='contained'
				color='primary'
				disabled={isDragActive || isUploading}
				startIcon={<DesktopWindowsOutlinedIcon />}
				style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}>
				{strings.selectFromComputer}
			</Button>
			<div className='df-col-ac'>
				<div className='df-row-ac'>
					<TouchAppOutlinedIcon style={{ color: theme.palette.text.secondary }} />
					<Typography variant='body2'>
						{isUploading ? strings.busyUploadingDocument : isDragActive ? strings.releaseLeftMouseButtonToUploadFile : strings.youCanAlsoDragAFileToHere}
					</Typography>
				</div>
				<LinearProgress style={{ width: '100%', marginTop: theme.spacing(1), visibility: isUploading ? 'visible' : 'collapse' }} />
			</div>
		</div>
	);
};
