import { Divider } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ChipWithAvatarList } from 'framework/components/ChipWithAvatarList';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { ISupplier, suppliersCommand_delete, suppliersCommand_restore } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RecordContext } from 'shared/records/RecordContext';
import { formatTimeModel } from 'shared/timeUnit/formatTimeModel';
import { SupplierAccountNumberForm } from './forms/SupplierAccountNumberForm';
import { SupplierAddressForm } from './forms/SupplierAddressForm';
import { SupplierBrandsForm } from './forms/SupplierBrandsForm';
import { SupplierContactPersonForm } from './forms/SupplierContactPersonForm';
import { SupplierDefaultDeliveryTimeForm } from './forms/SupplierDefaultDeliveryTimeForm';
import { SupplierEmailForm } from './forms/SupplierEmailForm';
import { SupplierNameForm } from './forms/SupplierNameForm';
import { SupplierVatNumberForm } from './forms/SupplierVatNumberForm';

interface IProps {
	reload: VoidFunction;
	item: ISupplier;
	style?: React.CSSProperties;
}

export const SupplierDetailsContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { timeUnitRecord } = useContext(RecordContext);

	const onPatchName = () => {
		open(
			<SupplierNameForm
				open
				confirm={confirm}
				cancel={cancel}
				supplier={item}
			/>
		);
	};

	const onPatchEmail = () => {
		open(
			<SupplierEmailForm
				open
				confirm={confirm}
				cancel={cancel}
				supplier={item}
			/>
		);
	};

	const onPatchAccountNumber = () => {
		open(
			<SupplierAccountNumberForm
				open
				confirm={confirm}
				cancel={cancel}
				supplier={item}
			/>
		);
	};

	const onPatchVatNumber = () => {
		open(
			<SupplierVatNumberForm
				open
				confirm={confirm}
				cancel={cancel}
				supplier={item}
			/>
		);
	};

	const onPatchAddress = () => {
		open(
			<SupplierAddressForm
				confirm={confirm}
				cancel={cancel}
				supplier={item}
			/>
		);
	};

	const onPatchContactPerson = () => {
		open(
			<SupplierContactPersonForm
				cancel={cancel}
				confirm={confirm}
				open
				supplier={item}
				id={item.id}
			/>
		);
	};

	const onPatchBrands = () => {
		open(
			<SupplierBrandsForm
				cancel={cancel}
				confirm={confirm}
				open
				supplier={item}
			/>
		);
	};

	const onPatchDefaultDeliveryTime = () => {
		open(
			<SupplierDefaultDeliveryTimeForm
				cancel={cancel}
				confirm={confirm}
				open
				supplier={item}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title={strings.deleteSupplier}
				content={strings.supplierWillBeDeleted}
				acceptText={strings.yesWhatExclamation(strings.deleteSupplier)}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => suppliersCommand_delete({ supplierId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onRestore = () => {
		open(
			<AlertDialog
				open
				title={strings.undoDelete}
				content={strings.doYouWantToMakeThisSupplierAvailableAgainQuestion}
				acceptText={strings.yesCommaContinue}
				rejectText={strings.cancel}
				reject={cancel}
				acceptF={() => suppliersCommand_restore({ supplierId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<div
			className='df-col gap-8'
			style={style}>
			<CaptionVerticalLabelledPropertyX
				label={strings.name}
				edit={onPatchName}>
				{item.name}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.contactPerson}
				edit={onPatchContactPerson}>
				<StringValueOrNotApplicable>{item.contactFormatted}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.accountNumber}
				edit={onPatchAccountNumber}>
				<StringValueOrNotApplicable>{item.accountNumber}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.email}
				edit={onPatchEmail}>
				<StringValueOrNotApplicable>{item.email}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.address}
				edit={onPatchAddress}>
				<StringValueOrNotApplicable>{item.addressFormatted}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.vatNumber}
				edit={onPatchVatNumber}>
				<StringValueOrNotApplicable>{item.vatNumber}</StringValueOrNotApplicable>
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.defaultDeliveryTime}
				edit={onPatchDefaultDeliveryTime}>
				{item.hasDefaultDeliveryTime ? formatTimeModel(item.defaultDeliveryTime, timeUnitRecord) : <em>{strings.notApplicableAbbreviation}</em>}
			</CaptionVerticalLabelledPropertyX>
			<CaptionVerticalLabelledPropertyX
				label={strings.distributedBrands}
				edit={onPatchBrands}>
				{item.brands.length === 0 && <em>{strings.notApplicableAbbreviation}</em>}
				<ChipWithAvatarList items={item.brands} />
			</CaptionVerticalLabelledPropertyX>
			<div className='fg1'></div>
			<Divider />
			<div className='df-col'>
				{/* {item.countOrders > 0 && (
					<TextLinkButton
						underline
						onClick={() => {}}
						startIcon={<OpenInBrowserOutlinedIcon />}>
						{`${item.countOrders} ${strings.orders}`}
					</TextLinkButton>
				)}
				{item.countPurchases > 0 && (
					<TextLinkButton
						underline
						onClick={() => {}}
						startIcon={<OpenInBrowserOutlinedIcon />}>
						{`${item.countPurchases} ${strings.purchases}`}
					</TextLinkButton>
				)}
				{item.countDeliveries > 0 && (
					<TextLinkButton
						underline
						onClick={() => {}}
						startIcon={<OpenInBrowserOutlinedIcon />}>
						{`${item.countDeliveries} ${strings.deliveries}`}
					</TextLinkButton>
				)} */}
				{item.isDeleted === false && (
					<TextLinkButton
						startIcon={<DeleteOutlineOutlinedIcon />}
						hoverColor='secondary'
						onClick={onDelete}>
						{strings.delete}
					</TextLinkButton>
				)}
				{item.isDeleted && (
					<TextLinkButton
						startIcon={<SettingsBackupRestoreOutlinedIcon />}
						color='secondary'
						hoverColor='secondary'
						onClick={onRestore}>
						{strings.restoreVerb}
					</TextLinkButton>
				)}
			</div>
		</div>
	);
};
