import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { NumericIcon } from 'framework/components/icons/NumericIcon';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { IPatient } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PatchPatientReferenceRequestForm } from '../../forms/PatchPatientReferenceRequestForm';

interface IProps {
	item: IPatient;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const PatientNumberingCardContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onPatch = () => {
		open(
			<PatchPatientReferenceRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				patient={item}
			/>
		);
	};

	return (
		<div
			className='df-col gap-8'
			style={style}>
			<CardTitleTypography title={strings.numbering} />
			<LabelledPropertyX
				icon={<NumericIcon />}
				edit={onPatch}>
				{item.reference}
			</LabelledPropertyX>
		</div>
	);
};
