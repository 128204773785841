import { DialogProps } from '@material-ui/core';
import React from 'react';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IRequestForInsuranceAllowance, rfiasCommand_setEntPhysician } from 'gen/ApiClient';
import { SelectEntPhysicianWithOfficeForm } from '../../../contacts/earNoseThroatPhysicians/forms/SelectEntPhysicianWithOfficeForm';

interface IProps extends DialogProps {
	item: IRequestForInsuranceAllowance;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const LinkEntPhysicianWithRfiaForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [link, isSubmitting] = useFormSubmit(rfiasCommand_setEntPhysician);

	const onConfirm = async (id: string, officeId: string | undefined, setError: (error: string) => void) => {
		handleValidateResponseBase(await link(item.id, { entPhysicianId: id, officeId: officeId }), setError, confirm);
	};

	return (
		<SelectEntPhysicianWithOfficeForm
			{...rest}
			confirm={onConfirm}
			cancel={cancel}
			initialEntId={item.entPhysicianId}
			initialOfficeId={item.entPhysicianOfficeId}
			isSubmitting={isSubmitting}
			withSelectOffice
		/>
	);
};
