import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { IPrimaryWorkflowsByPatientResponse } from 'gen/ApiClient';
import React from 'react';
import { WorkflowFilterTabType } from './WorkflowFilterTabType';

export const createWorkflowFilterTabTypeStartIconRecord = (item: IPrimaryWorkflowsByPatientResponse): Record<WorkflowFilterTabType, React.ReactNode> => {
	return {
		active: item.countActive > 0 && (
			<BadgeChipX
				value={item.countActive}
				color='primary'
			/>
		),
		all: item.workflows.length > 0 && (
			<BadgeChipX
				value={item.workflows.length}
				color='default'
			/>
		),
		error: item.countHasError > 0 && (
			<BadgeChipX
				value={item.countHasError}
				color='error'
			/>
		),
		warning: item.countHasWarning > 0 && (
			<BadgeChipX
				value={item.countHasWarning}
				color='warning'
			/>
		),
	};
};
