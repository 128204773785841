import { IFormProps } from 'framework/forms/common/IFormProps';
import { FormAutocompleteMultipleFreeSolo } from 'framework/forms/FormAutocompleteMultipleFreeSolo';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import React from 'react';
import { RepairReasonsCacheContext } from '../RepairReasonsCacheContext';

export const FormRepairReasonsField = <TModel extends unknown>({ name, label, ...rest }: IFormProps<TModel>) => {
	const [reasons] = useCacheContext(RepairReasonsCacheContext);

	if (reasons === undefined) {
		return <div></div>;
	}

	return (
		<FormAutocompleteMultipleFreeSolo<TModel>
			{...rest}
			options={reasons}
			name={name}
			label={label}
		/>
	);
};
