import React from 'react';
import { ColorX } from './color/ColorX';
import { useThemeColorOrHex } from './color/useThemeColorOrHex';

interface IProps {
	color: ColorX | string;
	size: number;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	style?: React.CSSProperties;
	variant?: 'filled' | 'outlined';
}

export const RoundedSquareX = ({ color, size, onClick, style, variant = 'filled' }: IProps) => {
	const tColor = useThemeColorOrHex(color);

	return (
		<div
			style={{
				width: size,
				display: 'flex',
				cursor: onClick ? 'pointer' : 'unset',
				...style,
			}}
			onClick={onClick}>
			<div
				style={{
					backgroundColor: variant === 'filled' ? tColor : 'unset',
					border: `1px solid ${tColor}`,
					borderRadius: '4px',
					height: size,
					width: size,
				}}></div>
		</div>
	);
};
