import { useTheme } from '@material-ui/core';
import { addDays, addMinutes, differenceInMinutes, endOfDay, startOfDay } from 'date-fns';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { formatDateX } from '../../utils/date/formatDateX';
import { DatePickerX } from './DatePickerX';
import { TimePickerX } from './TimePickerX';
import { generateDurationOptions } from './utils/generateDurationOptions';
import { generateTimeOptions } from './utils/generateTimeOptions';

interface IProps {
	startDate: Date;
	setStartDate: (val: Date) => void;
	endDate: Date;
	setEndDate: (val: Date) => void;
	isAllDay: boolean;
	style?: React.CSSProperties;
}

export const EventDatePicker = ({ startDate, setStartDate, endDate, setEndDate, style, isAllDay }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const startOptions = useMemo(() => generateTimeOptions(), []);
	const endOptions = useMemo(() => generateDurationOptions(startDate), [startDate]);

	useEffect(() => {
		if (isAllDay === true && formatDateX(startDate, 'dd/MM/yyyy HH:mm') !== formatDateX(startOfDay(startDate), 'dd/MM/yyyy HH:mm')) {
			setStartDate(startOfDay(startDate));
		}
		// eslint-disable-next-line
	}, [isAllDay, startDate]);

	useEffect(() => {
		if (isAllDay === true && formatDateX(endDate, 'dd/MM/yyyy HH:mm') !== formatDateX(endOfDay(endDate), 'dd/MM/yyyy HH:mm')) {
			setEndDate(endOfDay(endDate));
		}
		// eslint-disable-next-line
	}, [isAllDay, endDate]);

	const onSetStartDate = useCallback(
		(val: Date) => {
			const diff = differenceInMinutes(endDate, startDate);
			setEndDate(addMinutes(val, diff <= 0 ? 60 : diff));
			setStartDate(val);
		},
		// eslint-disable-next-line
		[startDate, endDate]
	);

	const onSetEndDate = useCallback(
		(val: Date) => {
			const diff = differenceInMinutes(val, startDate);
			setEndDate(diff < 0 ? addDays(val, 1) : val);
		},
		// eslint-disable-next-line
		[startDate]
	);

	return (
		<div
			className='df-row-ac'
			style={style}>
			<DatePickerX
				value={startDate}
				setValue={onSetStartDate}
				label={strings.from}
			/>
			{isAllDay === false && (
				<TimePickerX
					value={startDate}
					setValue={onSetStartDate}
					options={startOptions}
					label={strings.from}
					style={{ marginLeft: theme.spacing(1) }}
				/>
			)}
			<div style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), marginTop: '18px' }}>Tot</div>
			{isAllDay === false && (
				<TimePickerX
					value={endDate}
					setValue={onSetEndDate}
					options={endOptions}
					label={strings.to}
					style={{ marginRight: theme.spacing(1) }}
				/>
			)}
			<DatePickerX
				value={endDate}
				setValue={onSetEndDate}
				label={strings.to}
			/>
		</div>
	);
};
