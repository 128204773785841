import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponseWithLines } from 'framework/forms/utils/handleFormResponseWithLines';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { updateArray } from 'framework/utils/array/updateArray';
import {
	deliveriesQuery_nextReference,
	IOrderV2,
	IRegisterOrderDeliveryRequest,
	ordersCommand_registerDelivery,
	ordersQuery_nonDeliveredLines,
} from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { useNextReferenceInterceptor } from 'shared/interceptors/useNextReferenceInterceptor';
import { useNow } from 'shared/utils/useNow';
import * as yup from 'yup';
import { FormSelectLocationField } from '../../../../settings/locations/forms/FormSelectLocationField';
import { IRegisterOrderDeliveryLineModel } from './IRegisterOrderDeliveryLineModel';
import { mapToRegisterOrderDeliveryLineRequest } from './mapToRegisterOrderDeliveryLineRequest';
import { mapToRegisterOrderDeliveryRequest } from './mapToRegisterOrderDeliveryRequest';
import { mapToRegisterOrderLineModel } from './mapToRegisterOrderLineModel';
import { OrderDeliveryLineComponent } from './OrderDeliveryLineComponent';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IRegisterOrderDeliveryRequest>({
			locationId: yup.string().required(strings.formRequired(strings.location)),
			date: yup.date().required(strings.formRequired(strings.date)),
			lines: yup.mixed(),
			dispatchReference: yup.string(),
			dispatchDate: yup.date(),
			orderId: yup.string().required(),
			reference: yup.string().required(),
			isAutoSelectReference: yup.bool(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof IRegisterOrderDeliveryRequest)[]> = {
	0: ['locationId', 'date', 'reference', 'isAutoSelectReference'],
	1: ['dispatchDate', 'dispatchReference'],
	2: ['lines'],
};

// const calculateHasError = (lines: IRegisterOrderDeliveryLineModel[]) => {
// 	if (lines.filter(t => t.isSelected && t.orderLine.isUniquelyIdentifiable && !t.serialNumber).length > 0) {
// 		return true;
// 	} else if (lines.filter(t => t.isSelected && t.orderLine.quantity < t.quantity).length > 0) {
// 		return true;
// 	} else if (lines.filter(t => t.isSelected).length === 0) {
// 		return true;
// 	} else {
// 		return false;
// 	}
// };

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
	item: IOrderV2;
}

export const RegisterOrderDeliveryRequestForm = ({ confirm, cancel, item, ...rest }: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [registerOrder, isSubmitting] = useFormSubmit(ordersCommand_registerDelivery);
	const [step, setStep] = useState<number>(0);
	const [notDeliveredLines] = useApiEffect(ordersQuery_nonDeliveredLines, item.id);
	const [lines, setLines] = useState<IRegisterOrderDeliveryLineModel[]>([]);

	const now = useNow();

	useEffect(() => {
		if (notDeliveredLines) {
			setLines(notDeliveredLines.map(mapToRegisterOrderLineModel));
		}
		// eslint-disable-next-line
	}, [notDeliveredLines]);

	const handleSubmit = async (values: IRegisterOrderDeliveryRequest, helpers: FormikHelpers<IRegisterOrderDeliveryRequest>) => {
		if (lines.filter(t => t.isSelected).length === 0) {
			notify(strings.selectAtLeastOneWhat(strings.line), 'error');
		} else {
			const rLines = lines.filter(t => t.isSelected).map((t, index) => mapToRegisterOrderDeliveryLineRequest(t, index));
			const r = await registerOrder({ ...values, lines: rLines });
			if (handleFormResponseWithLines(r, helpers, stepsRecord, setStep, 'lines', lines, setLines)) {
				notify(strings.deliveryRegistered);
				confirm();
			}
		}
	};

	if (now === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IRegisterOrderDeliveryRequest>
			validateOnMount
			initialValues={mapToRegisterOrderDeliveryRequest(item, now)}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					cancel={cancel}
					isSubmitting={isSubmitting}
					lines={lines}
					setLines={setLines}
					step={step}
					setStep={setStep}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	cancel: VoidFunction;
	lines: IRegisterOrderDeliveryLineModel[];
	setLines: React.Dispatch<React.SetStateAction<IRegisterOrderDeliveryLineModel[]>>;
	isSubmitting: boolean;
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
}

const InnerDialog = ({ step, setStep, cancel, isSubmitting, lines, setLines, ...rest }: IInnerProps) => {
	const strings = useLocalization();
	const { isStacked } = useDialogsContext();
	const props = useFormikContext<IRegisterOrderDeliveryRequest>();
	// const theme = useTheme();
	// const headers = useMemo(() => createRegisterOrderDeliveryLineHeaders(lines, setLines, strings), [lines, setLines, strings]);
	useNextReferenceInterceptor(deliveriesQuery_nextReference, props);

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='lg'
			open={rest.open && isStacked === false}>
			<DialogTitleWithFormStepper
				title={strings.registerDelivery}
				step={step}
				labels={[strings.date, strings.shipment, strings.items]}
			/>
			<DialogContent
				dividers
				// style={{ padding: step === 2 ? '0px' : '8px 24px' }}
				className='df-col'>
				{step === 0 && (
					<>
						<FormSelectLocationField<IRegisterOrderDeliveryRequest>
							name='locationId'
							label={strings.location}
							helperText={strings.deliveryHelperText}
							required
						/>
						<FormDatePicker<IRegisterOrderDeliveryRequest>
							name='date'
							label={strings.deliveryDate}
							required
						/>
						<FormSingleCheckboxField<IRegisterOrderDeliveryRequest>
							name='isAutoSelectReference'
							label={strings.autoSelectIndexNumberQuestion}
						/>
						<FormTextField<IRegisterOrderDeliveryRequest>
							name='reference'
							label={strings.reference}
							disabled={props.values.isAutoSelectReference}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormTextField<IRegisterOrderDeliveryRequest>
							name='dispatchReference'
							label={`${strings.dispatchReference} (${strings.optional})`}
						/>
						<FormDatePicker<IRegisterOrderDeliveryRequest>
							name='dispatchDate'
							label={`${strings.dispatchDate} (${strings.optional})`}
						/>
					</>
				)}
				{step === 2 && (
					<div className='df-col gap-16'>
						{lines.map((t, index) => (
							<OrderDeliveryLineComponent
								key={t.orderLine.id}
								line={t}
								index={index}
								setLine={line => setLines(updateArray(t, lines, line))}
							/>
						))}
					</div>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
