import { Card, CardContent, CardProps } from '@material-ui/core';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { isLast } from 'framework/utils/array/isLast';
import { IOrderV2 } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { RegisterOrderDeliveryRequestForm } from '../../forms/delivery/RegisterOrderDeliveryRequestForm';
import { OrderDeliveryListItem } from './OrderDeliveryListItem';

interface IProps extends CardProps {
	item: IOrderV2;
	reload: VoidFunction;
}

export const OrderDetailDeliveriesCard = ({ item, reload, ...rest }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onRegisterDelivery = () => {
		open(
			<RegisterOrderDeliveryRequestForm
				open
				cancel={cancel}
				confirm={confirm}
				item={item}
			/>
		);
	};

	return (
		<Card {...rest}>
			<CardContent className='df-col gap-8'>
				<CardTitleTypography title={strings.deliveries} />
				<TextLinkButton
					startIcon={<LocalShippingOutlinedIcon />}
					onClick={onRegisterDelivery}
					color='primary'
					disabled={item.canRegisterDelivery === false}>
					{strings.registerDelivery}
				</TextLinkButton>
				<DividerWithMargin />
				{item.deliveries.map(t => (
					<OrderDeliveryListItem
						item={t}
						key={t.id}
						withoutBorder={isLast(t, item.deliveries)}
					/>
				))}
				{item.deliveries.length === 0 && <em>{strings.noDeliveriesRegisteredYet}</em>}
			</CardContent>
		</Card>
	);
};
