import React, { useState } from 'react';

export function useLocalStorage<S>(key: string, intitialState: S | (() => S)): [S, React.Dispatch<React.SetStateAction<S>>];
export function useLocalStorage<S = undefined>(key: string): [S | undefined, React.Dispatch<React.SetStateAction<S | undefined>>];

//Implement the function
export function useLocalStorage<S>(key: string, initialState?: S | (() => S)): [S, React.Dispatch<React.SetStateAction<S>>] {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState<S>(() => {
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(key);
			// Parse stored json or if none return initialValue
			return item ? JSON.parse(item) : initialState;
			// return item ? (typeof item === "string" ? item : JSON.parse(item)) : initialState;
		} catch (error) {
			// If error also return initialValue
			console.log(error);
			return initialState;
		}
	});

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value: S | ((prevState: S) => S)) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			// Save state
			setStoredValue(valueToStore);
			// Save to local storage
			if (valueToStore !== undefined && valueToStore !== null) {
				window.localStorage.setItem(key, JSON.stringify(valueToStore));
			} else {
				window.localStorage.removeItem(key);
			}
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error);
		}
	};

	return [storedValue, setValue];
}
