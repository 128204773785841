import React from 'react';
import { PatientDetailPageViewProvider } from './detailX/PatientDetailPageViewProvider';
import { PatientsListViewProvider } from './list/PatientsListViewProvider';
import { PatientsQueryProvider } from './list/PatientsQueryProvider';
import { PatientsFilterDataProvider } from './PatientsFilterDataProvider';

export const PatientsProviders = ({ children }: any) => {
	return (
		<PatientsQueryProvider>
			<PatientsListViewProvider>
				<PatientDetailPageViewProvider>
					<PatientsFilterDataProvider>{children}</PatientsFilterDataProvider>
				</PatientDetailPageViewProvider>
			</PatientsListViewProvider>
		</PatientsQueryProvider>
	);
};
