import { ISpeechAudiogram, LeftRightOrStereoType } from 'gen/ApiClient';
import React from 'react';
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

const colorRecord: Record<LeftRightOrStereoType, string> = {
	Left: 'blue',
	Right: 'red',
	Stereo: 'black',
};

interface IProps {
	audiogram: ISpeechAudiogram;
	lrs: LeftRightOrStereoType;
	minHeight?: string | number;
	minWidth?: string | number;
	width?: string | number;
	withoutLabels?: boolean;
	hideAxes?: boolean;
}

export const SpeechChart = ({ audiogram, lrs, minHeight, minWidth, width, withoutLabels = false, hideAxes = false }: IProps) => {
	return (
		<ResponsiveContainer
			minHeight={minHeight}
			minWidth={minWidth}
			width={width}>
			<LineChart>
				<XAxis
					tick={withoutLabels ? false : undefined}
					dataKey='intensity'
					type='number'
					scale='linear'
					domain={[0, 120]}
					unit='dB'
					ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120]}
					height={withoutLabels ? 1 : undefined}
					hide={hideAxes}
				/>
				<YAxis
					tick={withoutLabels ? false : undefined}
					type='number'
					scale='linear'
					domain={[0, 100]}
					unit='%'
					width={withoutLabels ? 1 : 40}
					hide={hideAxes}
				/>
				<Line
					isAnimationActive={false}
					type='monotoneY'
					data={audiogram.orderedPoints}
					dataKey='scorePercentage'
					stroke={colorRecord[lrs]}
					dot={false}
					strokeWidth={2}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};
