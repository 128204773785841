import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { ArticlesInquiriesPrefilter, ArticlesInquiriesPrefilters, IArticlesInquiriesMetadata } from 'gen/ApiClient';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { createArticlesInquiriesPrefilterBadgeChipXRecord } from './createArticlesInquiriesPrefilterBadgeChipXRecord';

interface IProps {
	selectedPrefilter: ArticlesInquiriesPrefilter;
	setSelectedPrefilter: (key: ArticlesInquiriesPrefilter) => void;
	metadata: IArticlesInquiriesMetadata;
	style?: React.CSSProperties;
}

export const ArticlesInquiriesListPrefilter = ({ selectedPrefilter, setSelectedPrefilter, metadata, style }: IProps) => {
	const endIconRecord = useMemo(() => createArticlesInquiriesPrefilterBadgeChipXRecord(metadata), [metadata]);
	const { articlesInquiriesPrefilterRecord: articlesInquiriesPrefilterKeyTypeRecord } = useContext(RecordContext);

	return (
		<div
			className='df-col'
			style={style}>
			{ArticlesInquiriesPrefilters.map(prefilter => (
				<RadioIsSelectedButton
					key={prefilter}
					isSelected={prefilter === selectedPrefilter}
					onClick={() => setSelectedPrefilter(prefilter)}
					endIcon={endIconRecord[prefilter]}>
					{articlesInquiriesPrefilterKeyTypeRecord[prefilter]}
				</RadioIsSelectedButton>
			))}
		</div>
	);
};
