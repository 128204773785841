import React from 'react';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { ICommission } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';

interface IProps {
	item: ICommission;
	style?: React.CSSProperties;
}

export const CommissionPaidOutStatusChip = ({ item, style }: IProps) => {
	const strings = useLocalization();

	return (
		<RectangularChip
			style={style}
			label={item.isPaidOut ? strings.paidOut : strings.notWhat(strings.paidOut)}
			fill={false}
			color={item.isPaidOut ? 'success' : 'info'}
		/>
	);
};
