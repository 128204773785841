import { ITonalAudiogramImage } from 'gen/ApiClient';
import { ToneThresholdImageType } from './ToneThresholdImageType';

export const selectImageData = (imageType: ToneThresholdImageType, audiogram: ITonalAudiogramImage | undefined): string | undefined => {
	if (imageType === 'left') {
		return audiogram?.leftEarImage ?? undefined;
	} else if (imageType === 'right') {
		return audiogram?.rightEarImage ?? undefined;
	} else {
		return audiogram?.bothEarImage ?? undefined;
	}
};
