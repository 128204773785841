import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	customerAccountLinesQuery_concurrencyToken,
	customerAccountLinesQuery_queryUnhandled,
	ICustomerAccountLine,
	ICustomerAccountLineQueryMetadata,
	IQueryResultWithMetadata,
	IUnhandledCustomerAccountLinesQueryParams,
} from 'gen/ApiClient';
import { unhandledCustomerAccountLinesQueryParamsLsKey } from '../../../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: IUnhandledCustomerAccountLinesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	customerAccountIds: [],
	salesDateBefore: undefined,
	salesDateCreatedAfter: undefined,
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResultWithMetadata<ICustomerAccountLine, ICustomerAccountLineQueryMetadata>, IUnhandledCustomerAccountLinesQueryParams>
			defaultParams={DefaultParams}
			queryF={customerAccountLinesQuery_queryUnhandled}
			concurrencyF={customerAccountLinesQuery_concurrencyToken}
			localStorageKey={unhandledCustomerAccountLinesQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
