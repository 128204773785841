import { Dialog, DialogProps } from '@material-ui/core';
import React from 'react';

export const OverflowDialog = (props: DialogProps) => {
	return (
		<Dialog
			PaperProps={{ style: { overflow: 'visible' } }}
			{...props}
		/>
	);
};
