import { Dialog, DialogProps, Typography } from '@material-ui/core';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IAssuranceLine, IInventoryItem, ProductCatalogItemType, TimeUnit } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { calcPurchaseDetailRoute, calcSalesDetailRoute } from 'app/main/financial/routes';
import { useRenderTimeUnit } from 'shared/timeUnit/useRenderTimeUnit';

interface IProps extends DialogProps {
	item: IAssuranceLine;
	iiItem: IInventoryItem;
	type: 'sale' | 'purchase';
	close: VoidFunction;
}

export const AssuranceLineDialog = ({ item, iiItem, type, close, ...rest }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();
	const renderTimeUnit = useRenderTimeUnit();

	const onNavigateToTransaction = () => {
		close();
		if (type === 'sale') {
			push(calcSalesDetailRoute(item.transactionId));
		} else {
			push(calcPurchaseDetailRoute(item.transactionId));
		}
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			scroll='paper'
			fullWidth
			maxWidth='sm'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<ReceiptOutlinedIcon />}>
					<Typography variant='h1'>{item.productCatalogItemName}</Typography>
					<LabelledProperty
						label={strings.item}
						property={iiItem.identifier}
					/>
					<RectangularChip
						label={type === 'sale' ? strings.sale : strings.purchase}
						size='extra-small'
					/>
				</DialogGridTitle>
				<GridDividerWithCaption caption={strings.details} />
				<PropertyInGrid
					label={item.productCatalogItemType === ('insurance' as ProductCatalogItemType) ? strings.insurance : strings.prolongedWarranty}
					value={item.productCatalogItemName}
				/>
				<PropertyInGrid
					label={strings.term}
					value={item.isUnlimitedTerm ? strings.unlimited : `${item.term.value} ${renderTimeUnit(item.term.value, item.term.unit as TimeUnit)}`}
				/>
				{item.isUnlimitedTerm === false && (
					<PropertyInGrid
						label={strings.from}
						value={formatDate(item.transactionDate)}
					/>
				)}
				{item.isUnlimitedTerm === false && (
					<PropertyInGrid
						label={strings.to}
						value={formatDate(item.endDate)}
					/>
				)}
				<GridDividerWithCaption caption={type === 'sale' ? strings.sale : strings.purchase} />
				<PropertyInGrid
					label={strings.reference}
					value={item.transactionReference}
					navigate={onNavigateToTransaction}
				/>
				<PropertyInGrid
					label={strings.date}
					value={formatDate(item.transactionDate)}
				/>
				<PropertyInGrid
					label={strings.price}
					value={formatCurrency(item.unitPrice)}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
