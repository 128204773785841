import { Collapse } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { CashPlusIcon } from 'framework/components/icons/CashPlusIcon';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageStatsButtonX } from 'framework/components/layout/PageStatsButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { ISupplier, ISupplierAccountOverview } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { SupplierAccountStatusChip } from '../SupplierAccountStatusChip';
import { SupplierAccountsPageBreadcrumbs } from '../SupplierAccountsPageBreadcrumbs';
import { SupplierAccountDetailQuickStats } from './SupplierAccountDetailQuickStats';
import { SupplierAccountDetailTabType, SupplierAccountDetailTabTypes } from './SupplierAccountDetailTabType';

const TabTypeIconRecord: Record<SupplierAccountDetailTabType, React.ReactNode> = {
	payments: <CashPlusIcon />,
	purchases: <HelpOutlineIcon />,
};

const createTabRecord = (strings: IStrings): Record<SupplierAccountDetailTabType, string> => {
	return {
		payments: strings.payments,
		purchases: strings.purchases,
	};
};

interface IProps {
	item: ISupplier;
	metadata: ISupplierAccountOverview;
	reload: VoidFunction;
	tab: SupplierAccountDetailTabType;
	setTab: (tab: SupplierAccountDetailTabType) => void;
	style?: React.CSSProperties;
}

export const SupplierAccountDetailPageHeader = ({ item, metadata, tab, setTab, reload, style }: IProps) => {
	const strings = useLocalization();
	const tabRecord = useMemo(() => createTabRecord(strings), [strings]);
	const [showStats, toggleShowStats] = useStateToggleBoolean(true);

	return (
		<div
			className='df-col fg1'
			style={style}>
			<div className='df-row-ac'>
				<SupplierAccountsPageBreadcrumbs />
				<PageBreadcrumbDivider />
				<PageBreadcrumbTitle title={item.identifier} />
				<SupplierAccountStatusChip
					item={item}
					style={{ marginLeft: 4 }}
				/>
				<div className='fg1'></div>
				<PageXHeaderActions>
					<PageStatsButtonX
						showStats={showStats}
						toggleShowStats={toggleShowStats}
					/>
					<PageReloadButtonX reload={reload} />
				</PageXHeaderActions>
			</div>
			<Collapse in={showStats}>
				<SupplierAccountDetailQuickStats
					metadata={metadata}
					setTab={setTab}
					style={{ paddingTop: 16 }}
				/>
			</Collapse>
			<HorizontalTabsX
				tabs={SupplierAccountDetailTabTypes}
				selectedTab={tab}
				setSelectedTab={setTab}
				titleRecord={tabRecord}
				startIconRecord={TabTypeIconRecord}
				// endIconRecord={endIconRecord}
				style={{
					marginTop: 16,
					marginLeft: -4,
				}}
			/>
		</div>
	);
};
