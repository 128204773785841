import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	IQueryResultWithMetadata,
	ISettlementProposal,
	ISettlementProposalQueryMetadata,
	ISettlementProposalsQueryParams,
	settlementProposalsQuery_concurrencyToken,
	settlementProposalsQuery_query,
} from 'gen/ApiClient';
import React from 'react';
import { settlementProposalsQueryParamsLsKey } from '../../../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: ISettlementProposalsQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	createdAfter: undefined,
	createdBefore: undefined,
	isHandled: undefined,
	paymentStatuses: [],
	labels: [],
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResultWithMetadata<ISettlementProposal, ISettlementProposalQueryMetadata>, ISettlementProposalsQueryParams>
			defaultParams={DefaultParams}
			queryF={settlementProposalsQuery_query}
			concurrencyF={settlementProposalsQuery_concurrencyToken}
			localStorageKey={settlementProposalsQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
