import { DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialog } from 'framework/dialogs/OverflowDialog';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormAutocompleteFreeSoloTextField } from 'framework/forms/FormAutocompleteFreeSoloTextField';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormSelectBooleanField } from 'framework/forms/FormSelectBooleanField';
import { FormSelectFieldWithCaption } from 'framework/forms/FormSelectFieldWithCaption';
import { FormSelectFieldWithCaptionFromRecord } from 'framework/forms/FormSelectFieldWithCaptionFromRecord';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { BatteryType, HearingAidType, IHearingDeviceCatalogModel, IProductCatalogSearchData, productCatalogCommand_newHearingDevice } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useEffect, useState } from 'react';
import { RecupelDefaultCacheContext } from 'shared/context/RecupelDefaultCacheContext';
import { FormBebatField } from 'shared/forms/FormBebatField';
import { FormSelectRecupelField } from 'shared/forms/FormSelectRecupelField';
import { FormSelectVatPercentageField } from 'shared/forms/FormSelectVatPercentageField';
import { RecordContext } from 'shared/records/RecordContext';
import * as yup from 'yup';
import { ProductCatalogFilterDataContext } from '../../context/ProductCatalogFilterDataContext';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IHearingDeviceCatalogModel>({
			manufacturer: yup.string().required(strings.formRequired(strings.manufacturer)),
			model: yup.string().required(strings.formRequired(strings.model)),
			listPrice: yup.number().required(strings.formRequired(strings.recommendedSalesprice)),
			vatPercentage: yup.number().required(strings.formRequired(strings.vatPercentage)).defined(),
			category: yup.string(),
			subcategory: yup.string(),
			hasBatteries: yup.boolean().defined(),
			isHearingInstrument: yup.boolean().defined(),
			recupelCode: yup.string(),
			bebatUnitPriceInVat: yup.number(),
			hearingAidType: yup.string(),
			batteryType: yup.string(),
			hasSeparateEarmould: yup.bool(),
			hasSeparateReceiver: yup.bool(),
			rizivHearingAidDefinitionV2ManufacturerAndNameGroupId: yup.string(),
		})
		.defined();
};

const emptyValues: IHearingDeviceCatalogModel = {
	manufacturer: '',
	model: '',
	category: '',
	subcategory: '',
	listPrice: undefined as any,
	vatPercentage: 6,
	hasBatteries: false,
	isHearingInstrument: true,
	recupelCode: '',
	bebatUnitPriceInVat: 0,
	hearingAidType: '',
	batteryType: '',
	hasSeparateEarmould: false,
	hasSeparateReceiver: false,
	rizivHearingAidDefinitionV2ManufacturerAndNameGroupId: undefined as any,
};

const stepsRecord: Record<number, (keyof IHearingDeviceCatalogModel)[]> = {
	0: ['manufacturer', 'model'],
	1: ['category', 'subcategory'],
	2: ['hearingAidType', 'batteryType', 'hasSeparateEarmould', 'hasSeparateReceiver'],
	3: ['hasBatteries', 'recupelCode', 'bebatUnitPriceInVat'],
	4: ['listPrice', 'vatPercentage'],
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
	isHearingInstrument: boolean;
	manufacturer?: string;
	model?: string;
	previous?: VoidFunction;
	rizivHearingAidDefinitionV2ManufacturerAndNameGroupId?: string;
}

export const HearingInstrumentForm = ({
	confirm,
	isHearingInstrument,
	manufacturer,
	model,
	rizivHearingAidDefinitionV2ManufacturerAndNameGroupId,
	previous,
	...rest
}: IProps) => {
	const strings = useLocalization();
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(productCatalogCommand_newHearingDevice);
	const [step, setStep] = useState<number>(0);
	const [data] = useCacheContext(ProductCatalogFilterDataContext);
	const [defaultRecupel] = usePreloadCacheContext(RecupelDefaultCacheContext);

	const handleSubmit = async (values: IHearingDeviceCatalogModel, helpers: FormikHelpers<IHearingDeviceCatalogModel>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			// navigate to detail?
			notify(strings.productCreated);
			confirm(r.result.objectId);
		}
	};

	if (data === undefined || defaultRecupel === undefined) {
		return <div></div>;
	}

	return (
		<Formik<IHearingDeviceCatalogModel>
			validateOnMount
			initialValues={{
				...emptyValues,
				isHearingInstrument: isHearingInstrument,
				manufacturer: manufacturer ?? '',
				model: model ?? '',
				recupelCode: defaultRecupel,
				rizivHearingAidDefinitionV2ManufacturerAndNameGroupId: rizivHearingAidDefinitionV2ManufacturerAndNameGroupId,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					searchData={data}
					isSubmitting={isSubmitting}
					rizivHearingAidDefinitionV2ManufacturerAndNameGroup={rizivHearingAidDefinitionV2ManufacturerAndNameGroupId !== undefined}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	step: number;
	setStep: React.Dispatch<React.SetStateAction<number>>;
	cancel: VoidFunction;
	isSubmitting: boolean;
	searchData: IProductCatalogSearchData;
	previous?: VoidFunction;
	rizivHearingAidDefinitionV2ManufacturerAndNameGroup: boolean;
}

const InnerDialog = ({
	step,
	setStep,
	cancel,
	isSubmitting,
	searchData,
	previous,
	rizivHearingAidDefinitionV2ManufacturerAndNameGroup,
	...rest
}: IInnerDialogProps) => {
	const props = useFormikContext<IHearingDeviceCatalogModel>();
	const strings = useLocalization();
	const { batteryTypeRecord, batteryTypeCaptionRecord } = useContext(RecordContext);

	useEffect(() => {
		if (props.values.hasBatteries === false) {
			setFormValue<IHearingDeviceCatalogModel>('bebatUnitPriceInVat', 0, props);
		} else if (props.values.hasBatteries === true) {
			setFormValue<IHearingDeviceCatalogModel>('bebatUnitPriceInVat', 0.057, props);
		}
		// eslint-disable-next-line
	}, [props.values.hasBatteries]);

	useEffect(() => {
		if (isNotNullNorUndefined(props.values.batteryType) && (props.values.batteryType as BatteryType) === 'R') {
			setFormValue<IHearingDeviceCatalogModel>('hasBatteries', true, props);
		} else {
			setFormValue<IHearingDeviceCatalogModel>('hasBatteries', false, props);
		}
		// eslint-disable-next-line
	}, [props.values.batteryType]);

	return (
		<OverflowDialog {...rest}>
			<DialogTitleWithFormStepper
				title={strings.newHearingAid}
				step={step}
				labels={[strings.name, strings.category, strings.properties, strings.other, strings.price]}
			/>
			<OverflowDialogContent>
				{step === 0 && (
					<>
						<FormAutocompleteFreeSoloTextField<IHearingDeviceCatalogModel>
							name='manufacturer'
							label={strings.manufacturer}
							options={searchData.manufacturers}
							required
							disabled={rizivHearingAidDefinitionV2ManufacturerAndNameGroup}
						/>
						<FormTextField<IHearingDeviceCatalogModel>
							name='model'
							label={strings.model}
							required
							disabled={rizivHearingAidDefinitionV2ManufacturerAndNameGroup}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormAutocompleteFreeSoloTextField<IHearingDeviceCatalogModel>
							name='category'
							label={strings.category}
							options={searchData.categories}
						/>
						<FormAutocompleteFreeSoloTextField<IHearingDeviceCatalogModel>
							name='subcategory'
							label={strings.subcategory}
							options={searchData.subcategories}
						/>
					</>
				)}
				{step === 2 && (
					<>
						<FormSelectFieldWithCaptionFromRecord<IHearingDeviceCatalogModel, BatteryType>
							name='batteryType'
							label={strings.batteryType}
							record={batteryTypeRecord}
							captionRecord={batteryTypeCaptionRecord}
						/>
						<FormSelectFieldWithCaption<IHearingDeviceCatalogModel, HearingAidType>
							name='hearingAidType'
							label={strings.type}
							options={[
								{ value: 'BTE', label: `${strings.behindTheEar} (BTE)`, caption: strings.bteCaptionTypes },
								{ value: 'ITE', label: `${strings.inTheEar} (ITE)`, caption: strings.iteCaptionTypes },
							]}
							getKey={t => t}
						/>
						{props.values.hearingAidType === 'BTE' && (
							<>
								<DividerWithCaption
									caption={`${strings.properties} (BTE)`}
									style={{ marginTop: 16, marginBottom: 16 }}
								/>
								<FormSelectBooleanField<IHearingDeviceCatalogModel>
									label={strings.earmould}
									name='hasSeparateEarmould'
									trueText={strings.withSeparateEarmouldCaption}
									falseText={strings.withoutSeparateEarmouldCaption}
								/>
								<FormSelectBooleanField<IHearingDeviceCatalogModel>
									label={strings.receiver}
									name='hasSeparateReceiver'
									trueText={strings.withSeparateReceiverCaption}
									falseText={strings.withoutSeparateReceiverCaption}
								/>
							</>
						)}
					</>
				)}
				{step === 3 && (
					<>
						<FormSelectRecupelField<IHearingDeviceCatalogModel>
							name='recupelCode'
							label='Recupel'
						/>
						<FormSingleCheckboxField<IHearingDeviceCatalogModel>
							name='hasBatteries'
							label={strings.hasRechargeableBatteriesBebatApplicable}
						/>
						{props.values.hasBatteries && (
							<>
								<FormBebatField<IHearingDeviceCatalogModel>
									name='bebatUnitPriceInVat'
									label={`Bebat (${strings.inVatShort})`}
								/>
							</>
						)}
					</>
				)}
				{step === 4 && (
					<>
						<FormSelectVatPercentageField<IHearingDeviceCatalogModel>
							name='vatPercentage'
							label={strings.vatPercentage}
						/>
						<FormMoneyField<IHearingDeviceCatalogModel>
							name='listPrice'
							label={strings.salesPrice}
							helperText={strings.priceIncludesAllContributionsRecupelAndBebat}
						/>
					</>
				)}
			</OverflowDialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={strings.create}
				schema={createSchema(strings)}
				stepsRecord={stepsRecord}
				previous={previous}
			/>
		</OverflowDialog>
	);
};
