import { Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CalendarRoute } from 'routes';

interface IProps {
	className?: string;
	style?: React.CSSProperties;
}

export const RosaTodayEventsNotConfiguredYetCard = ({ className, style }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();

	return (
		<Card
			className={className}
			style={style}>
			<CardContent className='df-col gap-8'>
				<CardTitleTypography title={`${strings.appointments} ${strings.today.toLowerCase()}`} />
				<VariantLabelledPropertyX
					link={() => push(CalendarRoute)}
					variant='warning'>
					{strings.rosaNotConfiguredYet}
				</VariantLabelledPropertyX>
			</CardContent>
		</Card>
	);
};
