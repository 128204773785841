import { Dialog, DialogProps } from '@material-ui/core';
import React from 'react';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { documentsQuery_single } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { boolToYesNo } from 'shared/utils/boolToString';
import { DocumentActions } from './DocumentActions';
import { DocumentDialogGridTitle } from './DocumentDialogGridTitle';
import { DocumentHistory } from './DocumentHistory';
import { DocumentMustSignForm } from './DocumentMustSignForm';

interface IProps extends DialogProps {
	close: VoidFunction;
	id: string;
}

export const DocumentDetailDialog = ({ id, close, ...rest }: IProps) => {
	const strings = useLocalization();
	const [document, reload] = useApiEffect(documentsQuery_single, id);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);

	if (document === undefined) {
		return <div></div>;
	}

	const onToggleMustSign = () => {
		open(
			<DocumentMustSignForm
				open
				document={document}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			open={rest.open && isStacked === false}
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DocumentDialogGridTitle
					document={document}
					reload={reload}
				/>
				<GridDividerWithCaption caption={strings.properties} />
				<PropertyInGrid
					label={`${strings.signVerb}?`}
					value={boolToYesNo(document.mustSign, strings)}
					edit={onToggleMustSign}
				/>
				<GridDividerWithCaption caption={strings.actions} />
				<PropertyInGrid
					value={
						<DocumentActions
							document={document}
							reload={reload}
							close={close}
						/>
					}
				/>
				<DocumentHistory document={document} />
			</DialogContentGrid>
		</Dialog>
	);
};
