import React from 'react';
import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { IStrings } from 'localization/IStrings';
import { ISalesLineForAssuranceDtoWithSelection } from './ISalesLineForAssuranceDtoWithSelection';

export const createHeaders = (
	lines: ISalesLineForAssuranceDtoWithSelection[],
	setLines: React.Dispatch<React.SetStateAction<ISalesLineForAssuranceDtoWithSelection[]>>,
	strings: IStrings
): IHeader<ISalesLineForAssuranceDtoWithSelection>[] => {
	return [
		createSelectionHeader<ISalesLineForAssuranceDtoWithSelection>(lines, setLines),
		createSortableHeader<ISalesLineForAssuranceDtoWithSelection>(strings.description, 'description', t => `${t.description} (# ${t.nonCreditedQuantity})`),
		createSortableHeader<ISalesLineForAssuranceDtoWithSelection>(strings.salesDate, 'salesDate', t => formatDate(t.salesDate)),
		createSortableHeader<ISalesLineForAssuranceDtoWithSelection>(strings.salesReference, 'salesReference', t => t.salesReference),
	];
};
