export const isValidBelgianSocialSecurityNumber = (text: string): boolean => {
	if (text.length !== 11) {
		return false;
	}

	const num = parseInt(text);
	if (Number.isNaN(num)) {
		return false;
	}

	const firstPart = text.slice(0, 9);
	const firstPartAsNumber = parseInt(firstPart);
	const checkDigit = text.slice(9, 11);
	const calculatedCheckDigit1 = calculatedCheckDigit(firstPartAsNumber);
	const calculatedCheckDigit2 = calculatedCheckDigit(firstPartAsNumber + 2000000000);
	return checkDigit === calculatedCheckDigit1 || checkDigit === calculatedCheckDigit2;
};

const calculatedCheckDigit = (first9Numbers: number): string => {
	const x = first9Numbers % 97;
	const y = 97 - x;
	const r = y.toString().padStart(2, '0');
	return r;
};
