import React, { useContext } from 'react';
import { PreloadCacheProvider } from 'framework/context/PreloadCacheProvider';
import { IUsersCache, usersQuery_all, usersQuery_concurrencyToken } from 'gen/ApiClient';
import { PreloadContext } from 'shared/context/PreloadContext';
import { UsersCacheContext } from './UsersCacheContext';

export const UsersCacheProvider = ({ children }: any) => {
	const { usersCache } = useContext(PreloadContext);

	return (
		<PreloadCacheProvider<IUsersCache>
			concurrencyF={usersQuery_concurrencyToken}
			loadF={usersQuery_all}
			context={UsersCacheContext}
			initialData={usersCache}>
			{children}
		</PreloadCacheProvider>
	);
};
