import { IReqisterPurchaseLineRequest } from 'gen/ApiClient';
import { IPurchaseFormLine } from './IPurchaseFormLine';

export const mapToRegisterPurchaseLineRequest = (
	line: IPurchaseFormLine,
	index: number,
	lines: IPurchaseFormLine[],
	isShiftVat: boolean
): IReqisterPurchaseLineRequest => {
	return {
		zeroBasedIndexNumber: index,
		productCatalogItemId: line.productCatalogItemId,
		quantity: line.quantity,
		unitListPriceExVat: line.unitListPrice,
		unitPriceExVat: line.unitPrice,
		vatPercentage: isShiftVat ? 0 : line.vatPercentage,
		description: line.description,
		serialNumber: line.serialNumber,
		assuredProductCatalogItemId: line.assuredProductCatalogItemId,
		assuredSerialNumber: findAssuredSerialNumber(line, lines),
		creditAssuranceLineId: line.creditedAssuranceLineId,
	};
};

const findAssuredSerialNumber = (line: IPurchaseFormLine, lines: IPurchaseFormLine[]): string | undefined => {
	if (line.assuredProductCatalogItemId) {
		return lines.find(x => x.id === line.assuredLineId)?.serialNumber ?? line.assuredSerialNumber;
	} else {
		return undefined;
	}
};
