import React, { useContext, useState } from 'react';
import { PreloadContext } from '../context/PreloadContext';
import { DefaultColumnSelectionsContext } from './DefaultColumnSelectionsContext';
import { ViewColumnSelectionType } from './ViewColumnSelectionType';

export const DefaultColumnSelectionsProvider = ({ children }: any) => {
	const { defaults } = useContext(PreloadContext);
	const [mounts, setMounts] = useState<ViewColumnSelectionType[]>([]);

	if (defaults === undefined) {
		return children;
	}

	return (
		<DefaultColumnSelectionsContext.Provider
			value={{
				defaults: defaults,
				isMounted: val => mounts.indexOf(val) > -1,
				setMounted: val => setMounts(prev => [...prev, val]),
			}}>
			{children}
		</DefaultColumnSelectionsContext.Provider>
	);
};
