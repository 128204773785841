import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { useApiCall } from 'framework/hooks/useApiCall';
import { patientsQuery_singleSearch } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';
import { FormSelectPatientField } from './FormSelectPatientField';
import { PatientSearchProvider } from './PatientSearchProvider';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			id: yup.string().required(strings.formRequired(strings.patient)),
		})
		.defined();
};

interface IModel {
	id: string;
}

interface IProps extends DialogProps {
	confirm: (patientId: string) => void;
	cancel: VoidFunction;
	previous?: VoidFunction;
	isSubmitting?: boolean;
}

export const SelectPatientByIdForm = ({ cancel, confirm, previous, isSubmitting = false, ...rest }: IProps) => {
	const strings = useLocalization();
	const loadSearch = useApiCall(patientsQuery_singleSearch);

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await loadSearch(values.id);
		if (r.isSuccess) {
			confirm(values.id);
		}
	};

	return (
		<Formik<IModel>
			validateOnMount
			validationSchema={createSchema(strings)}
			initialValues={{ id: '' }}
			onSubmit={handleSubmit}>
			<Form>
				<PatientSearchProvider>
					<Dialog
						fullWidth
						{...rest}>
						<DialogTitle>{strings.selectWhat(strings.patient)}</DialogTitle>
						<DialogContent
							className='df-col'
							dividers
							style={{ overflow: 'visible' }}>
							<FormSelectPatientField<IModel>
								name='id'
								label={strings.patient}
							/>
						</DialogContent>
						<CancelSubmitFormDialogActions
							submitText={strings.select}
							isSubmitting={isSubmitting}
							cancel={cancel}
							previous={previous}
						/>
					</Dialog>
				</PatientSearchProvider>
			</Form>
		</Formik>
	);
};
