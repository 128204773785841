import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormNumberField } from 'framework/forms/FormNumberField';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { trialReportsCommand_setAverageHours } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';

interface IModel {
	value: number;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		value: yup.number().defined(),
	});
};

interface IProps extends DialogProps {
	trialId: string;
	initialValue?: number;
	cancel: VoidFunction;
	confirm: VoidFunction;
}

export const AverageWearingHoursForm = ({ trialId, initialValue, cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(trialReportsCommand_setAverageHours);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		const r = await submit(trialId, values.value);
		if (handleValidateResponse(r, helpers, 'value')) {
			notify(strings.changedWhat(strings.averageNumberOfHoursPerDayWearingHearingAids));
			confirm();
		}
	};

	return (
		<Formik<IModel>
			validateOnMount
			initialValues={{
				value: initialValue ?? 0,
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.averageNumberOfHoursPerDayWearingHearingAids}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormNumberField<IModel>
							name='value'
							label={strings.averageNumberOfHoursPerDayWearingHearingAids}
							suffix={` ${strings.hoursPerDay}`}
							allowNegative={false}
							decimalScale={1}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						submitText={initialValue ? strings.update : strings.create}
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
