import { Button, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import { useLoggedInUserIsSuperUserOrHasClientClaim } from 'app/auth/useLoggedInUserIsSuperUserOrHasClientClaim';
import { useUserHasPageClaim } from 'claims/useUserHasPageClaim';
import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { CardX } from 'framework/components/cards/CardX';
import { PageX } from 'framework/components/layout/PageX';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { isLast } from 'framework/utils/array/isLast';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { NoAccessPage } from 'shared/layout/NoAccessPage';
import { PageReloadButton } from 'shared/layout/PageReloadButton';
import { PurchasesRoute } from '../../routes';
import { AddPurchaseUblToBufferDialog } from './AddPurchaseUblToBufferDialog';
import { PurchaseUblBufferListItem } from './PurchaseUblBufferListItem';
import { PurchasesUblBufferListFilterCard } from './PurchasesUblBufferListFilterCard';
import { PurchasesUblBufferQueryContext } from './PurchasesUblBufferQueryContext';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

export const PurchasesUblBufferListPage = () => {
	const strings = useLocalization();
	const { reload, queryResult, params, setParams } = useQueryContext(PurchasesUblBufferQueryContext);
	const { goBack } = useHistory();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const hasAccess1 = useLoggedInUserIsSuperUserOrHasClientClaim('CanImportUblPurchase');
	const hasAccess2 = useUserHasPageClaim('Purchases');

	const onAddToBuffer = () => {
		open(
			<AddPurchaseUblToBufferDialog
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	if (hasAccess1 === false || hasAccess2 === false) {
		return <NoAccessPage />;
	}

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<PageX
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<PageBreadcrumbTitle
							title={strings.financial}
							icon={<AttachMoneyIcon />}
						/>
						<PageBreadcrumbDivider />
						<PageBreadcrumb
							text={strings.purchases}
							route={PurchasesRoute}
						/>
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={`UBL buffer`} />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<Button
								size='small'
								variant='outlined'
								startIcon={<CloseIcon />}
								onClick={() => goBack()}>
								{strings.closeThisWindow}
							</Button>
							<PageReloadButton reload={reload} />
						</PageXHeaderActions>
					</div>
					<SearchbarX
						placeholder={strings.searchVerb}
						value={params.searchString}
						onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
						style={{ width: 500 }}
					/>
				</div>
			}
			stickySidebar={
				<div
					className='df-col gap-16 mh100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<div className='df-col gap-8'>
						<Button
							variant='outlined'
							color='primary'
							startIcon={<AddIcon />}
							onClick={onAddToBuffer}
							style={{ borderRadius: 12 }}>
							{`Voeg toe aan buffer...`}
						</Button>
					</div>
					<PurchasesUblBufferListFilterCard
						params={params}
						setParams={setParams}
					/>
					<div className='fg1'></div>
					<Divider />
				</div>
			}>
			<div
				className='df-col'
				style={{
					padding: 16,
					maxWidth: 1280,
				}}>
				<CardX className='w100'>
					<div
						className='df-row'
						style={{ gap: 8 }}>
						<Typography16pxBold>{strings.results}</Typography16pxBold>
						<div className='fg1'></div>
						<div
							className='df-col'
							style={{ alignItems: 'end' }}>
							{/* <PurchasesListOrderBySelect
								sortParams={params.sortParams}
								onFilter={sp => setParams(onResetToPage1({ ...params, sortParams: sp }))}
							/> */}
						</div>
					</div>
					{queryResult.values.map(t => (
						<PurchaseUblBufferListItem
							key={t.id}
							item={t}
							withoutBorder={isLast(t, queryResult.values)}
							reload={reload}
						/>
					))}
					<Divider style={{ marginTop: 8 }} />
					<QueryResultPaginationX
						queryResult={queryResult}
						setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
						style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
					/>
				</CardX>
			</div>
		</PageX>
	);
};
