import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISettlementProposal } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { SelectableColumn } from '../SelectableColumn';

export const createHeaders = (colRecord: Record<SelectableColumn, string>, strings: IStrings): Record<SelectableColumn, IHeader<ISettlementProposal>> => {
	return {
		amountPaid: createSortableHeaderWithKey<ISettlementProposal, SelectableColumn>(colRecord, 'amountPaid', t => formatCurrency(t.amountPaid), 'right'),
		created: createSortableHeaderWithKey<ISettlementProposal, SelectableColumn>(colRecord, 'created', t => formatDate(t.created)),
		paymentReference: createSortableHeaderWithKey<ISettlementProposal, SelectableColumn>(colRecord, 'paymentReference', t => t.paymentReference),
		belgianStructuredCreditorReference: createSortableHeaderWithKey<ISettlementProposal, SelectableColumn>(
			colRecord,
			'belgianStructuredCreditorReference',
			t => t.belgianStructuredCreditorReference
		),
		reference: createSortableHeaderWithKey<ISettlementProposal, SelectableColumn>(colRecord, 'reference', t => t.reference),
		totalValue: createSortableHeaderWithKey<ISettlementProposal, SelectableColumn>(colRecord, 'totalValue', t => formatCurrency(t.totalValue), 'right'),
	};
};
