import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { ICopyAllNoahUserSetupsRequest, noahUserSetupsCommand_copyAll } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import * as yup from 'yup';

const createSchema = (strings: IStrings) => {
	return yup.object<ICopyAllNoahUserSetupsRequest>({
		fromClientId: yup.string().required(),
		toClientId: yup.string().required(),
	});
};

interface IProps extends DialogProps {
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const NoahUserSetupsCopyAllRequestForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();
	const [copy, isSubmitting] = useFormSubmit(noahUserSetupsCommand_copyAll);

	const handleSubmit = async (values: ICopyAllNoahUserSetupsRequest, helpers: FormikHelpers<ICopyAllNoahUserSetupsRequest>) => {
		const r = await copy(values);
		if (handleValidateResponse(r, helpers, 'fromClientId')) {
			confirm();
		}
	};

	return (
		<Formik<ICopyAllNoahUserSetupsRequest>
			validateOnMount
			initialValues={{
				fromClientId: '',
				toClientId: '',
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{strings.selectWhat(strings.location)}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<FormTextField<ICopyAllNoahUserSetupsRequest>
							name='fromClientId'
							label={`From client Id`}
							required
						/>
						<FormTextField<ICopyAllNoahUserSetupsRequest>
							name='toClientId'
							label={`To client Id`}
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={strings.link}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
