import { RfiaPaymentStatus } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createPaymentStatusRecord = (strings: IStrings): Record<RfiaPaymentStatus, string> => {
	return {
		FullyPaid: strings.fullyPaid,
		NotApplicable: strings.notApplicableAbbreviation,
		NotPaid: strings.nothingPaid,
		OnlyHifFullyPaid: strings.onlyHifPaid,
		OnlyPatientFullyPaid: strings.onlyPatientPaid,
		PartlyPaid: strings.partlyPaid,
	};
};
