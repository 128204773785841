import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { CalendarResourcesPreloadCacheContext } from 'app/main/calendar/context/CalendarResourcesPreloadCacheContext';
import { FilterBar } from 'framework/filter/FilterBar';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ICalendarEventsByPatientQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const exclusions: (keyof ICalendarEventsByPatientQueryParams)[] = ['patientId', 'afterDate', 'beforeDate'];

interface IProps {
	params: ICalendarEventsByPatientQueryParams;
	onFilter: (params: ICalendarEventsByPatientQueryParams) => void;
	paddingLeft: number;
}

export const HamsPatientEventsFilterBar = ({ params, onFilter, paddingLeft }: IProps) => {
	const strings = useLocalization();
	const [data] = usePreloadCacheContext(CalendarResourcesPreloadCacheContext);

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			{data.rooms && (
				<ListFilterComponent<string>
					options={data.rooms}
					selected={params.roomGroupKeys}
					setSelected={vals => onFilter({ ...params, roomGroupKeys: vals })}
					label={strings.room}
					icon={<HelpOutlineIcon />}
				/>
			)}
			{data.users && (
				<ListFilterComponent<string>
					options={data.users}
					selected={params.userGroupKeys}
					setSelected={vals => onFilter({ ...params, userGroupKeys: vals })}
					label={strings.user}
					icon={<HelpOutlineIcon />}
				/>
			)}
			{data.eventTypes && (
				<ListFilterComponent<string>
					options={data.eventTypes}
					selected={params.eventTypeIds}
					setSelected={vals => onFilter({ ...params, eventTypeIds: vals })}
					label={strings.type}
					icon={<HelpOutlineIcon />}
				/>
			)}
		</FilterBar>
	);
};
