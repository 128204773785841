import { IUserInfo, PageClaim } from 'gen/ApiClient';
import { SuperUserRole } from 'gen/SuperUserRole';

export const calculateHasAccess = (neededClaim: PageClaim, user: IUserInfo): boolean => {
	if (user.role === SuperUserRole) {
		return true;
	} else if (user.pageClaims === undefined || user.pageClaims === null || user.pageClaims === []) {
		return false;
	} else {
		return user.pageClaims.indexOf(neededClaim!) > -1;
	}
};
