import { ILocalizedArticlePropertyKey } from 'app/main/inventoryManagement/productCatalog/articlePropertyKeys/context/ILocalizedArticlePropertyKey';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { IArticleProperty, IArticlePropertyModel } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { ArticlePropertyContent } from './ArticlePropertyContent';

interface IProps {
	item: IArticlePropertyModel | IArticleProperty;
	localizedKeys: ILocalizedArticlePropertyKey[];
	edit?: VoidFunction;
}

export const ArticlePropertyLabelledPropertyX = ({ item, localizedKeys, edit }: IProps) => {
	const label = useMemo(() => localizedKeys.find(t => t.key === item.key)?.label ?? '', [item.key, localizedKeys]);

	return (
		<LabelledPropertyX
			label={`${label} [${item.key}]`}
			edit={edit}>
			<ArticlePropertyContent values={item.values} />
		</LabelledPropertyX>
	);
};
