import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { useLocalization } from 'localization/useLocalization';
import { SaleType } from './SaleType';

interface IProps extends DialogProps {
	cancel: VoidFunction;
	confirm: (type: SaleType) => void;
}

export const SaleTypeForm = ({ cancel, confirm, ...rest }: IProps) => {
	const strings = useLocalization();
	const [type, setType] = useState<SaleType>('invoice');

	return (
		<Dialog {...rest}>
			<DialogTitle>{strings.selectWhat(strings.salesType)}</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<SaleType>
					selected={type}
					setSelected={setType}
					options={[
						{ label: strings.invoice, value: 'invoice', caption: strings.directedToPatientAndOrVatCustomer },
						{ label: strings.advanceInvoice, value: 'advanceInvoice', caption: strings.directedToPatientAndOrVatCustomer },
						{ label: strings.cashSale, value: 'cashSale', caption: strings.cashSalesAreNotLinkedToAnAccountReceivableGoToCashRegister },
					]}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{strings.cancel}
				</Button>
				<Button disabled>{strings.previous}</Button>
				<Button
					onClick={() => confirm(type)}
					color='primary'>
					{strings.next}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
