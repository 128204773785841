import { Fab, FabProps } from '@material-ui/core';
import React from 'react';

export const FabExtraSmall = ({ color, style, ...props }: FabProps) => {
	return (
		<Fab
			{...props}
			color={color}
			style={{ minHeight: 0, minWidth: 0, width: 22, height: 22, ...style }}
		/>
	);
};
