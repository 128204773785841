import { Card, CardContent, CardProps } from '@material-ui/core';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { FixedWidthCard } from 'framework/components/FixedWidthCard';
import { VariantLabelledPropertyX } from 'framework/components/labelledProperty/VariantLabelledPropertyX';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CalendarRoute } from 'routes';

interface IProps extends CardProps {
	width?: number;
}

export const RosaPatientUpcomingEventsNotConfiguredYetCard = ({ width, ...rest }: IProps) => {
	if (width === undefined) {
		return (
			<Card {...rest}>
				<InnerContent />
			</Card>
		);
	} else {
		return (
			<FixedWidthCard
				{...rest}
				width={width}>
				<InnerContent />
			</FixedWidthCard>
		);
	}
};

const InnerContent = () => {
	const strings = useLocalization();
	const { push } = useHistory();

	return (
		<CardContent>
			<CardTitleTypography
				startIcon={<TodayOutlinedIcon style={{ marginRight: 4, opacity: 0.8 }} />}
				title={strings.nextAppointments}
				style={{ marginBottom: 8 }}
			/>
			<VariantLabelledPropertyX
				variant='warning'
				link={() => push(CalendarRoute)}>
				{strings.rosaNotConfiguredYet}
			</VariantLabelledPropertyX>
		</CardContent>
	);
};
