import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

export const ProcessesPageBreadcrumbTitle = () => {
	const strings = useLocalization();

	return (
		<PageBreadcrumbTitle
			title={strings.processes}
			icon={<DoubleArrowOutlinedIcon />}
		/>
	);
};
