import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { ITemplate } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { FormSelectTemplateField } from './FormSelectTemplateField';

interface IProps<TModel> extends DialogProps {
	name: keyof TModel;
	title: string;
	cancel: VoidFunction;
	isSubmitting: boolean;
	templates: ITemplate[];
	submitText?: string;
	cancelText?: string;
}

export const SelectTemplateInnerDialog = <TModel extends object>({
	name,
	title,
	submitText,
	cancelText,
	cancel,
	isSubmitting,
	templates,
	...rest
}: IProps<TModel>) => {
	const props = useFormikContext<TModel>();
	const strings = useLocalization();

	useEffect(() => {
		if (templates.length === 1) {
			setFormValue<TModel>(name, templates[0].id, props);
		}
		// eslint-disable-next-line
	}, [templates]);

	if (templates === undefined) {
		return <div></div>;
	}

	return (
		<Dialog
			fullWidth
			{...rest}>
			<DialogTitle>
				<div>{title}</div>
			</DialogTitle>
			<DialogContent
				className='df-col'
				dividers>
				{templates.length > 0 && (
					<FormSelectTemplateField<TModel>
						name={name}
						label={strings.template}
						templates={templates}
					/>
				)}
				{templates.length === 0 && <NoRecordsFound>{strings.noTemplatesForThisTypeOrSubject}</NoRecordsFound>}
			</DialogContent>
			<CancelSubmitFormDialogActions<TModel>
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={submitText ?? strings.select}
				cancelText={cancelText ?? strings.cancel}
			/>
		</Dialog>
	);
};
