import FrameworkNavigationProvider from 'framework/context/NavigationProvider';
import React from 'react';
import { RfiaDetailTabType } from '../detail/RfiaDetailTabType';
import { NavigationContext } from './NavigationContext';

export const NavigationProvider = ({ children }: any) => {
	return (
		<FrameworkNavigationProvider<RfiaDetailTabType>
			context={NavigationContext}
			fallback='process'>
			{children}
		</FrameworkNavigationProvider>
	);
};
