import { CalendarEventExportProp } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';

export const createExportRecord = (strings: IStrings): Record<CalendarEventExportProp, string> => {
	return {
		AllDay: strings.fullDay,
		Contact: strings.contact,
		Description: strings.description,
		EndDate: strings.endDate,
		EventTypeIdentifier: strings.type,
		FormTitle: strings.title,
		PatientIdentifier: strings.patient,
		RoomIdentifier: strings.room,
		StartDate: strings.startDate,
		UserIdentifiersCsv: strings.users,
	};
};
