import { usePreloadCacheContext } from 'framework/hooks/usePreloadCacheContext';
import { useTrigger } from 'framework/hooks/useTrigger';
import { useWrapApiCallWithLoad } from 'framework/hooks/useWrapApiCallWithLoad';
import { meta_build, meta_ping } from 'gen/thermalPrinter/thermalPrinterClient';
import React, { useEffect, useMemo, useState } from 'react';
import { useDownloadApplicationInstaller } from 'shared/utils/useDownloadApplicationInstaller';
import { LatestBuildContext } from './LatestBuildContext';
import { LatestBuildProvider } from './LatestBuildProvider';
import { ThermalPrinterApplicationContext } from './ThermalPrinterApplicationContext';

export const ApplicationProvider = ({ children }: any) => {
	return (
		<LatestBuildProvider>
			<Inner>{children}</Inner>
		</LatestBuildProvider>
	);
};

const Inner = ({ children }: any) => {
	const [ping, isPinging] = useWrapApiCallWithLoad(meta_ping);
	const [loadBuild, isLoadBuild] = useWrapApiCallWithLoad(meta_build);
	const [data] = usePreloadCacheContext(LatestBuildContext);
	const [trigger, hitTrigger] = useTrigger();
	const [isInstalled, setIsInstalled] = useState<boolean>();
	const [installedBuild, setInstalledBuild] = useState<number>(0);
	const hasLatest = useMemo(() => (isInstalled === true && data !== undefined ? data <= installedBuild : true), [isInstalled, data, installedBuild]);
	const isInitialized = useMemo(() => isInstalled !== undefined && data !== undefined, [isInstalled, data]);
	const [download, isDownloading] = useDownloadApplicationInstaller('ThermalPrinter');

	useEffect(() => {
		const load = async () => {
			const r = await ping();
			setIsInstalled(r.isSuccess);
			if (r.isSuccess) {
				const rB = await loadBuild();
				if (rB.isSuccess) {
					setInstalledBuild(rB.result);
				}
			}
		};
		load();
		// eslint-disable-next-line
	}, [trigger]);

	return (
		<ThermalPrinterApplicationContext.Provider
			value={{
				isInstalled: isInstalled ?? true,
				check: hitTrigger,
				hasLatest: hasLatest,
				allChecked: isInstalled === true && hasLatest === true,
				installedBuild: installedBuild,
				latestBuild: data,
				isInitialized: isInitialized,
				isChecking: isPinging || isLoadBuild,
				download: download,
				isDownloading: isDownloading,
			}}>
			{children}
		</ThermalPrinterApplicationContext.Provider>
	);
};
