import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { IActiveWorkflowStats, WorkflowType } from 'gen/ApiClient';
import * as routes from 'app/main/processes/ProcessesRoutes';
import { RecordContext } from 'shared/records/RecordContext';
import { DashboardWorkflowCard } from './DashboardWorkflowCard';

const navRecord: Record<WorkflowType, string> = {
	Loan: routes.LoansRoute,
	Order: routes.OrdersRoute,
	Repair: routes.RepairsRoute,
	Rfia: routes.RequestsForInsuranceAllowanceRoute,
	ArticlesInquiry: routes.ArticlesInquiriesRoute,
};

interface IProps {
	data: IActiveWorkflowStats;
	width?: number;
	chartSize?: number;
	style?: React.CSSProperties;
	formatErrors?: (count: number) => string;
	formatWarnings?: (count: number) => string;
	formatTotal?: (count: number) => string;
}

export const WorkflowSummaryDataCard = ({ data, ...rest }: IProps) => {
	const { workflowTypeRecordPlural } = useContext(RecordContext);
	const { push } = useHistory();

	const onNavigate = (type: WorkflowType) => {
		push(navRecord[type]);
	};

	return (
		<DashboardWorkflowCard
			{...rest}
			data={data}
			title={workflowTypeRecordPlural[data.workflowType as WorkflowType]}
			onNavigate={() => onNavigate(data.workflowType as WorkflowType)}
		/>
	);
};
