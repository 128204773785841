import LinkIcon from '@material-ui/icons/Link';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { InfoLabelledProperty } from 'framework/components/labelledProperty/InfoLabelledProperty';
import { ProductCatalogItemType } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import { IInvoiceLine } from './IInvoiceLine';

interface IProps {
	line: IInvoiceLine;
	lines: IInvoiceLine[];
	onLink: VoidFunction;
	style?: React.CSSProperties;
}

export const AssuranceLineLinkComponent = ({ line, lines, onLink, style }: IProps) => {
	const strings = useLocalization();
	const { productCatalogItemTypeRecord } = useContext(RecordContext);
	const linkedLine = useMemo(() => lines.find(t => t.id === line.assuredLineId), [line, lines]);
	const isLinked = useMemo(() => line.assuredLineId !== undefined, [line]);

	if (isLinked) {
		return (
			<InfoLabelledProperty
				info={
					linkedLine
						? `${productCatalogItemTypeRecord[line.productCatalogItem!.type as ProductCatalogItemType]}: ${linkedLine.description}`
						: strings.whatForUnArticleFromAPreviousSale(productCatalogItemTypeRecord[line.productCatalogItem!.type as ProductCatalogItemType])
				}
				style={style}
				withoutMargin
			/>
		);
	} else {
		return (
			<ErrorLabelledProperty
				error={strings.linkItemWithThisWhat(`${line.productCatalogItem?.isInsurance ? strings.insurance : strings.prolongedWarranty}`)}
				edit={onLink}
				editIcon={<LinkIcon />}
				style={style}
				withoutMargin
			/>
		);
	}
};
